import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { appsettings } from "../../app-settings";

import { Observable } from "rxjs";

import {
  Alarmdetails,
  Notificationdetails,
  Escalationdetails,
  NotificationForCreationResponse,
  getRoleIndividualList,
  selectedEscalations,
} from "./notification";
import {
  NotificationErrorHandler,
  HandleError,
} from "./http-error-handler.service";
import {
  Campus,
  Building,
  Floor,
} from "../technologyoverlay/technologyoverlay";

@Injectable()
export class notificationService {
  getAlarmListUrl = appsettings.API_ENDPOINT + "notifications/getAlarmList";
  getNotificationUrl =
    appsettings.API_ENDPOINT + "notifications/getNotification";
  getNotificationRecipientUrl =
    appsettings.API_ENDPOINT + "notifications/getNotificationRecipient";
  getRoleOrIndividualListUrl =
    appsettings.API_ENDPOINT + "notifications/getRoleOrIndividualList";

  updateNotificationUrl =
    appsettings.API_ENDPOINT + "notifications/updateNotification";
  deleteNotificationUrl =
    appsettings.API_ENDPOINT + "notifications/deleteNotification";

  addNotificationUrl =
    appsettings.API_ENDPOINT + "notifications/addNotification";

  getNotificationLevelsUrl =
    appsettings.API_ENDPOINT + "notifications/getNotificationLevels";
  checkAlertForCampusUrl =
    appsettings.API_ENDPOINT + "notifications/checkAlertForCampus";
    
  getNotifyEscalateLevelsUrl = appsettings.API_ENDPOINT+'notifications/getNotifyEscalationLevels';  
  UpdatecontinuousNotificationUrl = appsettings.API_ENDPOINT+'notifications/updateContinuousNotify'; 
  UpdateNotificationmsgUrl = appsettings.API_ENDPOINT+'notifications/updateNotificationClearMessage'; 
  getNotificationmsgUrl = appsettings.API_ENDPOINT+'notifications/getNotificationClearMessage';

  getCampusDetailsForNotificationUrl =
    appsettings.API_ENDPOINT + "notifications/getCampusDetailsForNotification"; // URL to web api
  getBuildingDetailsForNotificationUrl =
    appsettings.API_ENDPOINT +
    "notifications/getBuildingDetailsForNotification";
  getFloorDetailsForNotificationUrl =
    appsettings.API_ENDPOINT + "notifications/getFloorDetailsForNotification";
  getSubZoneDetailsForNotificationUrl = appsettings.API_ENDPOINT + 'notifications/getSubZoneDetailsForNotification';
  getNotificationCountUrl =
    appsettings.API_ENDPOINT + "notifications/getNotificationCount";

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: NotificationErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      "notificationService"
    );
  }

  /** GET Alarmlist from the server */
  getAlarmList(notifyItem): Observable<Alarmdetails[]> {
    return this.http.post<Alarmdetails[]>(this.getAlarmListUrl, notifyItem);
  }

  /** GET Campuslist from the server */
  getCampusDetailsForNotification(campusdata): Observable<Campus[]> {
    return this.http.post<Campus[]>(
      this.getCampusDetailsForNotificationUrl,
      campusdata
    );
  }

  /** GET Buildinglist from the server */
  getBuildingDetailsForNotification(CampusId): Observable<Building[]> {
    return this.http.post<Building[]>(
      this.getBuildingDetailsForNotificationUrl,
      CampusId
    );
  }

  /** GET Floorlist from the server */
  getFloorDetailsForNotification(BuildId): Observable<Floor[]> {
    return this.http.post<Floor[]>(
      this.getFloorDetailsForNotificationUrl,
      BuildId
    );
  }
  
  /** GET SubZonelist from the server */
  getSubZoneDetailsForNotification(FloorId): Observable<Floor[]> {      
    return this.http.post<Floor[]>(this.getSubZoneDetailsForNotificationUrl, FloorId)        
  }

  getNotificationCount(
    count_details
  ): Observable<NotificationForCreationResponse[]> {
    return this.http.post<NotificationForCreationResponse[]>(
      this.getNotificationCountUrl,
      count_details
    );
  }

  /** GET Notification Details from the server */
  getNotification(NotificationDet): Observable<Notificationdetails[]> {
    return this.http.post<Notificationdetails[]>(
      this.getNotificationUrl,
      NotificationDet
    );
  }

  /** GET Escalation Details from the server */
  getNotificationRecipient(EscalationDet): Observable<Escalationdetails[]> {
    return this.http.post<Escalationdetails[]>(
      this.getNotificationRecipientUrl,
      EscalationDet
    );
  }

  /** Get Role/Individual list**/
  getRoleOrIndividualList(notifytype): Observable<getRoleIndividualList[]> {
    return this.http.post<getRoleIndividualList[]>(
      this.getRoleOrIndividualListUrl,
      notifytype
    );
  }

  addNotification(
    notificationForCreation
  ): Observable<NotificationForCreationResponse[]> {
    return this.http.post<NotificationForCreationResponse[]>(
      this.addNotificationUrl,
      notificationForCreation
    );
  }

  /** Update Notification**/
  updateNotification(
    notificationeditForCreation
  ): Observable<NotificationForCreationResponse[]> {
    return this.http.post<NotificationForCreationResponse[]>(
      this.updateNotificationUrl,
      notificationeditForCreation
    );
  }

  /** Delete Notification**/
  deleteNotification(
    notificationdelete
  ): Observable<NotificationForCreationResponse[]> {
    return this.http.post<NotificationForCreationResponse[]>(
      this.deleteNotificationUrl,
      notificationdelete
    );
  }

  /****selected notify time***/
  getNotificationLevels(timeId): Observable<selectedEscalations[]> {
    return this.http.post<selectedEscalations[]>(
      this.getNotificationLevelsUrl,
      timeId
    );
  }

  checkAlertForCampus(campusitem): Observable<selectedEscalations[]> {
    return this.http.post<selectedEscalations[]>(
      this.checkAlertForCampusUrl,
      campusitem
    );
  }
  
  getNotifyRepeatEscalateLevels(timeId):Observable<selectedEscalations[]>{   		
    return this.http.post<selectedEscalations[]>(this.getNotifyEscalateLevelsUrl,timeId) 
   }

   updateContinuousNotification(notification): Observable<NotificationForCreationResponse[]> {   
    return this.http.post<NotificationForCreationResponse[]>(this.UpdatecontinuousNotificationUrl,notification) 
    }
 
  updateNotificationClearMessage(notificationmsg): Observable<NotificationForCreationResponse[]> {   
    return this.http.post<NotificationForCreationResponse[]>(this.UpdateNotificationmsgUrl,notificationmsg) 
    }  
    
  getNotificationClearMessage(notificationmsg): Observable<NotificationForCreationResponse[]> {   
    return this.http.post<NotificationForCreationResponse[]>(this.getNotificationmsgUrl,notificationmsg) 
    }   
}
