import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import { addBuilding } from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../../../../../api.service";
import { ConfirmDialogComponent } from "../../../../technologyoverlay/device/confirm-dialog/confirm-dialog.component";
import { getCampus } from "../../../../../api";
import { enumEnableDisable,appsettings } from "../../../../../app-settings";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';


@Component({
  selector: "app-add-building",
  templateUrl: "./add-building.component.html",
  providers: [TechnologyoverlayService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-building.component.scss"],
})
export class AddBuildingComponent implements OnInit, OnDestroy {
  public addBuildingForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 3;
  private _onDestroy: Subject<void> = new Subject<void>();
  addbuildingbool = true;
  Campusdetails;
  selectedCampusId;
  chkCampusvalue = true;
  chkCustomvalue = false;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddBuildingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    private apiService: ApiService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.selectedCampusId = data.CampusId;
      this.breakpoint$.subscribe(() => this.breakpointChanged());         
    } catch (err) {
      throw new Error(
        "add-building.component.ts" + " - constructor() - " + err.message
      );
    }
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.addBuildingForm = new UntypedFormGroup({
        campus: new UntypedFormControl("", [Validators.required]),
        campusID: new UntypedFormControl(""),
        buildingname: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(32),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        description: new UntypedFormControl("", [
          Validators.pattern(appsettings.Name_Pattern),
        ]),
      });
      // this.addBuildingForm.get('location').disable();
      this.getCampusDetails();
    } catch (err) {
      throw new Error(
        "add-building.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  getCampusDetails() {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-building.component.ts" + " - getCampusDetails() - " + err.message
      );
    }
  }

  changecampus_addbuilding(campusid) {
    this.selectedCampusId = campusid;
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.addBuildingForm.controls).forEach((field) => {
        // {1}
        const control = this.addBuildingForm.get(field); // {2}
        const addBuildFormId = document.getElementById(event.target.id);
        if (addBuildFormId != null) {
          if (field == addBuildFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-building.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    this.addBuildingForm.controls[controlName].hasError(errorName);
  };

  newaddBuilding(addBuildingFormValue) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.addBuildingForm.valid && this.addbuildingbool) {
        this.addbuildingbool = false;
        // let saveclick = document.getElementById('btnsavebuildaddclick');
        // saveclick.className = 'clsoverlaybutton mat-raised-button disablebtn';
        this.buildingAddEnableDisable(enumEnableDisable.Disable);
        if (this.chkCampusvalue == false && this.chkCustomvalue == false) {
          generateAlertMessage(this.dialog, "Please Select Notifications");
          // let saveclick = document.getElementById('btnsavebuildaddclick');
          //     saveclick.className = 'clsoverlaybutton mat-raised-button';
          this.buildingAddEnableDisable(enumEnableDisable.Enable);
          this.addbuildingbool = true;
          return;
        } else this.onSaveClicked(addBuildingFormValue);
      }
    } catch (err) {
      throw new Error(
        "add-building.component.ts" + " - newaddBuilding() - " + err.message
      );
    }
  }

  private onSaveClicked = (addBuildingFormValue) => {
    try {
      // const selectcampusId;
      let notificationvalue = 0;
      let _Description = "";
      if (addBuildingFormValue.description != "")
        _Description = addBuildingFormValue.description
          .replace(/\s+/g, " ")
          .trim();
      else _Description = "";

      if (this.chkCampusvalue == true) notificationvalue = 0;
      else notificationvalue = 1;

      let MessageDetail;

      let message = "";

      message =
        'By selecting "Custom", you must now configure Buildings for this Campus. Truview will now build out the Building Notifications for each alert type.';

      if (notificationvalue == 1) {
        MessageDetail = { Message: message, Type: 0, CustomType: 1 };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
          width: "650px",
          height: "250px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: MessageDetail,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result == "Confirm") {
            this.addBuilding(
              addBuildingFormValue,
              _Description,
              notificationvalue
            );
          } else {
            this.addbuildingbool = true;
            // let saveclick = document.getElementById('btnsavebuildaddclick');
            // saveclick.className = 'clsoverlaybutton mat-raised-button';
            this.buildingAddEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
      } else
        this.addBuilding(addBuildingFormValue, _Description, notificationvalue);
    } catch (err) {
      throw new Error(
        "add-building.component.ts" + " - onSaveClicked() - " + err.message
      );
    }
  };
  //Add Building  is used to add a new building for the campus. In Add Building, we can give Building Name, Description and Notification settings
  addBuilding(
    addBuildingFormValue,
    _Description: string,
    notificationvalue: number
  ) {
    try {
      const buildingdetails: addBuilding = {
        authKey: this.authkey,
        campusId: parseInt(this.selectedCampusId),
        buildingName: addBuildingFormValue.buildingname
          .replace(/\s+/g, " ")
          .trim(),
        description: _Description, //addBuildingFormValue.description.replace(/\s+/g, ' ').trim()
        notifications: notificationvalue,
      };
      this.technologyoverlayService
        .addBuilding(buildingdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["BuildId"] > 0) {
            this.apiService.reloadmapconfig();

            this.thisDialogRef.close(apiresponse["BuildId"]);
          } else if (apiresponse["apiReturn"] == "Fail") {
            this.addbuildingbool = true;
            generateAlertMessage(this.dialog, "Building added failed.");
            this.thisDialogRef.close("Confirm");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else if (apiresponse["apiReturn"] == "buildingName") {
            this.addbuildingbool = true;
            generateAlertMessage(this.dialog, "Please enter Building Name");
            // let saveclick = document.getElementById('btnsavebuildaddclick');
            // saveclick.className = 'clsoverlaybutton mat-raised-button';
            this.buildingAddEnableDisable(enumEnableDisable.Enable);
            return;
          } else {
            this.addbuildingbool = true;
            generateAlertMessage(
              this.dialog,
              "Building name '" +
                apiresponse["BuildingList"][0].BuildingName +
                "' already exists."
            );
            // let saveclick = document.getElementById('btnsavebuildaddclick');
            // saveclick.className = 'clsoverlaybutton mat-raised-button';
            this.buildingAddEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "add-building.component.ts" + " - addBuilding() - " + err.message
      );
    }
  }

  toggleCamVisibility(event) {
    if (event.checked == true) {
      this.chkCampusvalue = true;
      this.chkCustomvalue = false;
    } else {
      this.chkCustomvalue = false;
      this.chkCampusvalue = false;
    }
  }

  toggleCusVisibility(event) {
    if (event.checked == true) {
      this.chkCustomvalue = true;
      this.chkCampusvalue = false;
    } else {
      this.chkCampusvalue = false;
      this.chkCustomvalue = false;
    }
  }

  buildingAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsavebuildaddclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsoverlaybutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsoverlaybutton mat-raised-button disablebtn";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
