import {
  Component,
  Inject,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatOption } from "@angular/material/core";
import { MatSort } from "@angular/material/sort";
import { MatSelect } from "@angular/material/select";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TechnologyoverlayService } from "../technologyoverlay.service";
import {
  getCampusById,  
  DevicesType,
  DeviceStatus,
  addHRexflag,
  getDeviceDetailsReq,
  getSiteAuthKeyReuest,
} from "../technologyoverlay";
import {
  appsettings,
  DeviceTypeDesc,
  NewDeviceTypeDesc,
  DeviceType,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../../app-settings";
import {
  SessionCheck,
  SessionCheckwithStaff,
  set_SharedDetails,
  checkStaffData,
  generateAlertMessage,
  getFeatureNeedtoShow,
  getAddEditColumn,
  AuthFailedMessage,
} from "../../../app-general";
import {
  SharedData,
  DeviceDetails,
  getUnit,
  MapSyncProcess,
  getDeviceById,
} from "../../../api";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { DeviceAdvancefilterComponent } from "../device-advancefilter/device-advancefilter.component";
import { UntypedFormControl } from "@angular/forms";
import "ol/ol.css";
import "ol-popup/src/ol-popup.css";
import OlMap from "ol/Map";
import { DOCUMENT } from "@angular/common";
import { getCampusFulldetail } from "../../../api";
import { ApiService } from "../../../api.service";
import { AlertSharedService } from "../../../_services/index";
import { AddDeviceComponent } from "./add-device/add-device.component";
import { EditDeviceComponent } from "./edit-device/edit-device.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { DownloadDeviceReportComponent } from "../device/download-device-report/download-device-report.component";

@Component({
  selector: "app-device",
  templateUrl: "./device.component.html",
  providers: [TechnologyoverlayService],
  styleUrls: ["./device.component.scss"],
})
export class DeviceComponent implements OnInit, OnDestroy {
  SharedDetails: SharedData;
  public show = false;
  bAddEdit = false;
  bDelete = false;
  CampusById: getCampusById[];  
  CampusFulldetails: getCampusFulldetail[];
  DeviceType: DevicesType[] = [];
  // [
  //   { DeviceType: "Clear Gateway", DeviceTypeId: "Gateway" },
  //   { DeviceType: "Clear Repeater", DeviceTypeId: "Clear Repeater" },
  //   { DeviceType: "Clear Router", DeviceTypeId: "Clear Router" },    
  //   { DeviceType: "Intercom", DeviceTypeId: "Intercom" },
  //   { DeviceType: "LF", DeviceTypeId: "LF" },
  //   { DeviceType: "Monitor", DeviceTypeId: "Monitor" },
  //   { DeviceType: "Pull Cord", DeviceTypeId: "Pull Cord" },
  //   { DeviceType: "Security Controller", DeviceTypeId: "Security Controller" },
  //   { DeviceType: "TruLocator", DeviceTypeId: "TruLocator" }, //{ DeviceType: 'Universal Monitor', DeviceTypeId: 'Universal Monitor' },
  //   { DeviceType: "UT", DeviceTypeId: "UT" },
  //   { DeviceType: "VW", DeviceTypeId: "VW" },
  //   { DeviceType: "Zone", DeviceTypeId: "Zone" },
  // ];
  DeviceStatus: DeviceStatus[] = [
    { DeviceStatusName: "Active", DeviceStatusId: 1 },
    { DeviceStatusName: "Inactive", DeviceStatusId: 0 },
  ];
  InOutdoordata: any[] = [
    { InOutdoorName: "Indoor", Value: 1 },
    { InOutdoorName: "Outdoor", Value: 2 },
  ];
  DeviceDetails: DeviceDetails[];
  DeviceDetailed: DeviceDetails[];
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  lgcurrentScreenWidth = "lg";
  displayedColumns: string[] = [
    "NewDeviceName",
    "DeviceInfo",
    "LforIr",
    "CampusName",
    "BuildingName",
    "FloorName",
    "UnitLocation",
    "Description",
    "DeviceId",
    "Status",
    "Edit",
  ];
  dataSource;
  downloadDeviceSource;
  dataSourcecnt;
  editimageheight;
  editimagewidth;
  CampusImageURL = "";
  BuildingImageURL = "";
  FloorImageURL = "";
  ImageUrl = "";
  ImageWidth = "";
  ImageHeight = "";
  SvgImageUrl = "";
  SvgInfo = null;
  FloorInfo = null;
  map = new OlMap();
  Map_image_path = "assets/images/";
  Unitnameforfloor;
  Unitidforfloor;
  campusName = "";
  getdeviceforadd;
  MCampusId = 0;
  MBuildingId = 0;
  MFloorId = 0;
  Mchange = 0;
  FloorId = 0;
  MMapDataDetails = "0_0_0";
  Map_array_length = 3;
  onmapclickdata;
  Device = [] as any[];
  addfloorimageheight = 0;
  selectDisabled;
  device_list_all = "3";
  devicefiltervalue = "";
  devicefiltervalue_mapdetail = "";
  BackgroundIMG = "Centrak";
  CZoomLevel;
  CRotateLevel;
  Unitinfo = [] as any[];
  searchedList: any;
  addHRexflag: addHRexflag[];
  loadMapfloorId = 0;
  searchflag = 0;
  MapSyncProcess;
  deleteFlag = 0;
  DeleteDeviceName;
  modeselectdevice;
  screenWidth;
  filterDeviceData = [] as any[];
  filterdevicedetails;
  TranslateX;
  TranslateY;
  MaxRexvalue;
  public _NewDeviceTypeDesc = NewDeviceTypeDesc;
  getselectedmapdetails;

  getbuildingdata;

  selectedBuildingdetails = {};
  selectedFloordetails = {};

  Campus_key = "Campusinfo";
  Building_key = "Buildinginfo";
  Floor_key = "Floorinfo";
  selectedCampus = 0;
  selectedBuilding = 0;
  selectedFloor = 0;
  selectedunit = 0;

  chkdeviceflag = 0;
  device_Details;
  deviceStatus = [] as any[];
  Status;
  InOutDoorvalue = [] as any[];
  DeviceInfo_all = [] as any[];
  device_editdialogopen = 0;
  HRexMacId;
  Gatewaymacid;
  // allSelected=true;
  // allDeviceSelected = true;
  // allStatusSelected = true;
  allSelectedtext = 0;
  matselectvalue = "Select All";

  selectedInOutdoor = [-1];
  selectedDevices = [-1];
  selectedStatus = [-1];

  Inoutdoordeselect = 0;
  DeviceTypedeselect = 0;
  DeviceStatusdeselect = 0;

  toppings = new UntypedFormControl();
  deviceloading = false;
  authkey;
  voiceLicense;
  clearRouterDetails;
  selectRouterMacId;
  selectRouterId;
  addUpdateMode = 0;
  hrexCount = 0;
  hrexMaxCount;
  devicesortedData = [];
  public watcher: Observable<boolean>;

  @ViewChild("select") select: MatSelect;
  @ViewChild("devicesort") devicesort: MatSort;
  @ViewChild("selectDevices") selectDevices: MatSelect;
  @ViewChild("selectstatus") selectstatus: MatSelect;

  @ViewChild("allSelectedInOutdoor") private allSelectedInOutdoor: MatOption;
  @ViewChild("allSelectedDeviceType") private allSelectedDeviceType: MatOption;
  @ViewChild("allSelectedStatus") private allSelectedStatus: MatOption;

  private _onDestroy: Subject<void> = new Subject<void>();
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      "(min-width: 500px)",
    ])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private alertSharedService: AlertSharedService,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar,
    public mainLayoutService: MainLayoutService,
    private technologyService: TechnologyoverlayService,
    public thisDialogRef: MatDialogRef<DeviceComponent>,
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      const get_CurrentNavigation = this.router.getCurrentNavigation();
      
      this.voiceLicense = localStorage.getItem("voice_license"); 
                        
      this.DeviceType.push({ DeviceType: 'Clear Gateway', DeviceTypeId: "Gateway" }); 
      this.DeviceType.push({ DeviceType: 'Clear Repeater', DeviceTypeId: 'Clear Repeater' }); 
      this.DeviceType.push({ DeviceType: 'Clear Router', DeviceTypeId: 'Clear Router' }); 
      if(this.voiceLicense == 1)
        this.DeviceType.push({ DeviceType: 'Intercom', DeviceTypeId: 'Intercom'}); 
      this.DeviceType.push({ DeviceType: 'LF', DeviceTypeId: 'LF' }); 
      this.DeviceType.push({ DeviceType: 'Monitor', DeviceTypeId: 'Monitor' }); 
      this.DeviceType.push({ DeviceType: 'Pull Cord', DeviceTypeId: 'Pull Cord' }); 
      this.DeviceType.push({ DeviceType: 'Security Controller', DeviceTypeId: 'Security Controller' }); 
      this.DeviceType.push({ DeviceType: 'TruLocator', DeviceTypeId: 'TruLocator' }); 
      this.DeviceType.push({ DeviceType: 'UT', DeviceTypeId: 'UT' }); 
      this.DeviceType.push({ DeviceType: 'VW', DeviceTypeId: 'VW'}); 
      this.DeviceType.push({ DeviceType: NewDeviceTypeDesc.Approx, DeviceTypeId: NewDeviceTypeDesc.Approx });
      
      if (get_CurrentNavigation != null)
        this.getbuildingdata = get_CurrentNavigation.extras.state;

      if (this.getbuildingdata == undefined) this.chkdeviceflag = 1;
      else {
        this.chkdeviceflag = 0;
      }
      this.onResize();
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - constructor() - " + err.message
      );
    }
    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }

  breakpointChanged() {
    if (this.selectDevices != undefined) this.selectDevices.close();
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_List_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = false;
    }
    if (this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      this.setupFullTable();
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupTableformdwidth();
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupTableforsmallerwidth();
    } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1700) this.setupFullTable();
    const isFirefox = navigator.userAgent.toLowerCase();
    if (isFirefox.indexOf("firefox") > -1) {
      if (this.screenWidth > 1280) {
        this.setupFullTable();
      } else if (this.screenWidth > 960 && this.screenWidth <= 1280) {
        this.setupTableformdwidth();
      } else if (this.screenWidth > 600 && this.screenWidth <= 960) {
        this.setupTableforsmallerwidth();
      } else if (this.screenWidth < 600) {
        this.setupTableforXtrasmallerwidth();
      }
    }
  }
  // set table width and columns
  setupTableforXtrasmallerwidth() {
    try {
      this.displayedColumns = ["DeviceInfo", "Edit"];
      this.displayedColumns = getAddEditColumn(
        this.displayedColumns,
        this.bAddEdit
      );
    } catch (err) {
      throw new Error(
        "device.component.ts" +
          " - setupTableforXtrasmallerwidth() - " +
          err.message
      );
    }
  }

  setupTableforsmallerwidth() {
    try {
      this.displayedColumns = ["NewDeviceName", "DeviceInfo", "Edit"];
      this.displayedColumns = getAddEditColumn(
        this.displayedColumns,
        this.bAddEdit
      );
    } catch (err) {
      throw new Error(
        "device.component.ts" +
          " - setupTableforsmallerwidth() - " +
          err.message
      );
    }
  }

  setupTableformdwidth() {
    try {
      this.displayedColumns = ["NewDeviceName", "DeviceInfo", "Edit"];
      this.displayedColumns = getAddEditColumn(
        this.displayedColumns,
        this.bAddEdit
      );
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - setupTableformdwidth() - " + err.message
      );
    }
  }

  setupFullTable() {
    try {
      this.displayedColumns = [
        "NewDeviceName",
        "DeviceInfo",
        "LforIr",
        "CampusName",
        "BuildingName",
        "FloorName",
        "UnitLocation",
        "Description",
        "DeviceId",
        "Status",
        "Edit",
      ];
      this.displayedColumns = getAddEditColumn(
        this.displayedColumns,
        this.bAddEdit
      );
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - setupFullTable() - " + err.message
      );
    }
  }

  ngOnInit() {
    try {
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }
      this.screenWidth = window.innerWidth;
      if (this.screenWidth > 1700) this.setupFullTable();
      this.Gatewaymacid = localStorage.getItem("applianceSN");

      this.authkey = localStorage.getItem("Authkey") ?? "";

      this.alertSharedService
        .currentShared()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedDetails: SharedData) => {
          this.SharedDetails = SharedDetails;
        });

      this.alertSharedService
        .currentSharedHomeMapdetails()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ShareMapDetails) => {
          this.getselectedmapdetails = ShareMapDetails;
        });
      if (
        this.SharedDetails == undefined &&
        this.getselectedmapdetails != undefined
      ) {
        set_SharedDetails(
          this.getselectedmapdetails.sel_CampusId,
          this.getselectedmapdetails.sel_BuildingId,
          this.getselectedmapdetails.sel_FloorId,
          0,
          "",
          "",
          "",
          this.alertSharedService
        );
      }
      this.getStaffById();
      // this.getHRexdetails();
      this.getDeviceDetails();
      this.clkmatselectdata();
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - setupFullTable() - " + err.message
      );
    }
  }

  getHrexflag() {
    if (this.MFloorId != 0) {
      const hrexItem: getSiteAuthKeyReuest = {
        authKey: this.authkey,
      };
      this.technologyoverlayService
        .checkHREXfound(hrexItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((addHRexflag: any[]) => {
          this.addHRexflag = addHRexflag;
        });
    }
  }

  getMaxRexdevicedetail() {
    const rexItem: getSiteAuthKeyReuest = {
      authKey: this.authkey,
    };
    this.technologyoverlayService
      .getRexdevicedetails(rexItem)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((maxrexvalue: any[]) => {
        this.MaxRexvalue = maxrexvalue[0].DeviceId;
      });
  }

  getStaffById() {
    checkStaffData(this.apiService, this.alertSharedService);
  }

  getHRexdetails() {
    try {
      const hrexdetails: getSiteAuthKeyReuest = {
        authKey: this.authkey,
      };
      this.technologyService
        .getHRexdetails(hrexdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((HRexDetails: any[]) => {
          if (HRexDetails != null && HRexDetails != undefined) {
            if(HRexDetails.length == undefined || HRexDetails[0].HrexCount == undefined)          
            {
              this.HRexMacId = HRexDetails;
              // this.HRexMacId =HRexDetails[0].MacId;
              this.DeviceInfo_all.forEach(function (e) {
                if (e.DeviceName == DeviceTypeDesc.HRex) {
                  e.HRexMacId = e.MacId;
                }
              })
            }
            else
            {
              this.clearRouterDetails = HRexDetails;
              this.selectRouterId = this.clearRouterDetails[0].HRexId;
              this.selectRouterMacId = this.clearRouterDetails[0].MacId;
              this.hrexCount = this.clearRouterDetails[0].RouterCount;
              this.hrexMaxCount = this.clearRouterDetails[0].checkRouter;     
              
              this.DeviceInfo_all.forEach(function (e) {
                if (e.DeviceName == DeviceTypeDesc.HRex) {
                  e.HRexMacId = e.MacId;
                }
              })
              
            }
          }
        });
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - getHRexdetails() - " + err.message
      );
    }
  }

  getDeviceDetails() {
    try {
      this.deviceloading = true;
      const DeviceDetails_info = [] as any[];
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.MCampusId,
        floorId: this.MFloorId,
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: 0,
        isIndoor: 1,
        isNeedRoomonly: 0,
        isNeedIntercom:parseInt(this.voiceLicense)
      };
      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          DeviceDetails["apiReturn"].forEach(function (data) {
            if (data.FilterDeviceTypeId != DeviceType.Room) {
              DeviceDetails_info.push(data);
            }
          });

          this.device_Details = DeviceDetails_info;
          if (DeviceDetails_info != null && DeviceDetails_info != undefined) {
            if (DeviceDetails["condition"] == "Authfalse") {
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            DeviceDetails_info.forEach(function (e) {
              if (e.DeviceName == DeviceTypeDesc.Emitter) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
              } else if (e.DeviceName == DeviceTypeDesc.HRex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
              } else if (e.DeviceName == DeviceTypeDesc.Rex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
              } 
              else if (e.DeviceName == DeviceTypeDesc.LF) {
                if (e.SubType==DeviceType.DripTray)
                {
                  e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
                }
                else
                e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
              }
              else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
              } else if (
                e.DeviceName == DeviceTypeDesc.DC ||
                e.DeviceName == DeviceTypeDesc.NewDC
              ) {
                e["NewDeviceName"] = NewDeviceTypeDesc.NewDC;
              } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
              } else if (e.DeviceName == DeviceTypeDesc.VW) {
                e["NewDeviceName"] = NewDeviceTypeDesc.VW;
              } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
              } else if (e.DeviceName == DeviceTypeDesc.UT) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UT;
              } else if (e.DeviceName == DeviceTypeDesc.UM) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UM;
              } else if (e.DeviceName == DeviceTypeDesc.Intercom) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Intercom;
              } 
              else if(e.FilterDeviceTypeId == DeviceType.Zone)
              {
                e['NewDeviceName'] = e.FilterDeviceType;
              }
              else {
                e["NewDeviceName"] = NewDeviceTypeDesc.Zone;
              }
            });
            this.DeviceInfo_all = DeviceDetails_info;
            this.getdevicelist_outdoor();
          } else {
            this.deviceloading = false;
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - getDeviceDetails() - " + err.message
      );
    }
  }

  getdevicelist_outdoor() {
    try {
      const DeviceDetails_outdoorinfo = [] as any[];
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.MCampusId,
        floorId: 0,
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: 0,
        isIndoor: 0,
        isNeedRoomonly: 0,
        isNeedIntercom:parseInt(this.voiceLicense)
      };

      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          DeviceDetails["apiReturn"].forEach(function (data) {
            if (data.FilterDeviceTypeId != DeviceType.Room) {
              DeviceDetails_outdoorinfo.push(data);
            }
          });

          this.device_Details = DeviceDetails_outdoorinfo;
          if (
            DeviceDetails_outdoorinfo != null &&
            DeviceDetails_outdoorinfo != undefined
          ) {
            if (DeviceDetails["condition"] == "Authfalse") {
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            DeviceDetails_outdoorinfo.forEach(function (e) {
              if (e.DeviceName == DeviceTypeDesc.Emitter) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
              } else if (e.DeviceName == DeviceTypeDesc.HRex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
              } else if (e.DeviceName == DeviceTypeDesc.Rex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
              } 
              else if (e.DeviceName == DeviceTypeDesc.LF) {
                if (e.SubType==DeviceType.DripTray)
                {
                  e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
                }
                else
                {
                  e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
                }
              }
              else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
              } else if (
                e.DeviceName == DeviceTypeDesc.DC ||
                e.DeviceName == DeviceTypeDesc.NewDC
              ) {
                e["NewDeviceName"] = NewDeviceTypeDesc.DC;
              } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
              } else if (e.DeviceName == DeviceTypeDesc.VW) {
                e["NewDeviceName"] = NewDeviceTypeDesc.VW;
              } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
              } else if (e.DeviceName == DeviceTypeDesc.UT) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UT;
              } else if (e.DeviceName == DeviceTypeDesc.UM) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UM;
              } else if (e.DeviceName == DeviceTypeDesc.Intercom) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Intercom;
              }
              else if(e.FilterDeviceTypeId == DeviceType.Zone)
              {
                e['NewDeviceName'] = e.FilterDeviceType;
              }
              else {
                e["NewDeviceName"] = NewDeviceTypeDesc.Zone;
              }
            });

            this.DeviceInfo_all = this.DeviceInfo_all.concat(
              DeviceDetails_outdoorinfo
            );
            this.filterdevicedetails = this.DeviceInfo_all;
            this.dataSource = new MatTableDataSource(this.DeviceInfo_all);
            this.downloadDeviceSource = new MatTableDataSource(this.DeviceInfo_all);
            this.dataSource.data = this.DeviceInfo_all;
            this.downloadDeviceSource.data = this.DeviceInfo_all;
            this.dataSourcecnt = this.DeviceInfo_all.length;

            setTimeout(() => {
              this.devicesortedData = [];
              this.dataSource.sort = this.devicesort;
              this.sortingDatalist_Device(this.dataSource);
              this.downloadDeviceSource.sort = this.devicesort; 
              this.sortingDatalist_Device(this.downloadDeviceSource); 
              this.downloadDeviceSource.connect().subscribe(data => this.devicesortedData = data);  
            }, 0);
            this.getHRexdetails();
            this.getFilterDeviceDetails();
            this.DeviceDetails = this.DeviceInfo_all;
            this.FloorInfo = this.DeviceInfo_all;
            this.getdeviceforadd = this.DeviceDetails;
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - getdevicelist_outdoor() - " + err.message
      );
    }
  }

  getFilterDeviceDetails() {
    let _searchfilterData = [] as any[];
    let filterSearch;
    let _Device;
    let _filterdeviceData = [] as any[];

    this.InOutDoorvalue = this.selectedInOutdoor;

    this.Device = this.selectedDevices;
    this.deviceStatus = this.selectedStatus;

    let filterdata = [] as any[];

    this.dataSource.filter = this.devicefiltervalue;
    const Campus_Id = this.selectedCampus;
    const Building_Id = this.selectedBuilding;
    const Floor_Id = this.selectedFloor;
    const Unit_Id = this.selectedunit;
    // const _InOutdoorvalue = this.InOutDoorvalue;
    _filterdeviceData = this.filterdevicedetails;
    let getresetfilterId = "";
    //Device Status
    if (this.deviceStatus.length == 0 || this.deviceStatus.includes(-1)) {
      _filterdeviceData = this.filterdevicedetails;
    } else {
      for (
        let i = 0;
        i < this.deviceStatus.length;
        i++ // for acts as a foreach
      ) {
        const _devicestatus = this.deviceStatus[i];
        _filterdeviceData.forEach(function (data) {
          if (data.Status === _devicestatus) {
            filterdata.push(data);
          }
        });
      }
      _filterdeviceData = filterdata;
    }

    //Advanced Filter
    if (Campus_Id == 0 && Building_Id == 0 && Floor_Id == 0 && Unit_Id == 0) {
      if (this.deviceStatus.length == 0 || this.deviceStatus.includes(-1)) {
        _filterdeviceData = this.filterdevicedetails;
      } else _filterdeviceData = filterdata;
    } else {
      filterdata = [];
      _filterdeviceData.forEach(function (data) {
        getresetfilterId = localStorage.getItem("ResetFilter") ?? "";
        const get_resetfilterId = document.getElementById(getresetfilterId);
        if (get_resetfilterId != null) get_resetfilterId.style.color = "red";
        if (
          Campus_Id != 0 &&
          Building_Id != 0 &&
          Floor_Id != 0 &&
          Unit_Id != 0
        ) {
          if (
            data.CampusId === Campus_Id &&
            data.BuildingId == Building_Id &&
            data.FloorId == Floor_Id &&
            data.UnitId == Unit_Id
          ) {
            filterdata.push(data);
          }
        } else if (
          Campus_Id != 0 &&
          Building_Id == 0 &&
          Floor_Id == 0 &&
          Unit_Id != 0
        ) {
          if (data.CampusId == Campus_Id && data.UnitId == Unit_Id) {
            filterdata.push(data);
          }
        } else if (Campus_Id != 0 && Building_Id != 0 && Floor_Id != 0) {
          if (
            data.CampusId === Campus_Id &&
            data.BuildingId == Building_Id &&
            data.FloorId == Floor_Id
          ) {
            filterdata.push(data);
          }
        } else if (Campus_Id != 0 && Building_Id != 0) {
          if (data.CampusId === Campus_Id && data.BuildingId == Building_Id) {
            filterdata.push(data);
          }
        } else if (Campus_Id != 0) {
          if (data.CampusId === Campus_Id) {
            filterdata.push(data);
          }
        }
      });

      _filterdeviceData = filterdata;
    }

    //Indoor Outdoor
    if (this.InOutDoorvalue.length == 0 || this.InOutDoorvalue.includes(-1)) {
      if (
        (this.deviceStatus.length == 0 || this.deviceStatus.includes(-1)) &&
        Campus_Id == 0
      )
        _filterdeviceData = this.filterdevicedetails;
      else _filterdeviceData = filterdata;
    } else {
      filterdata = [];
      for (
        let j = 0;
        j < this.InOutDoorvalue.length;
        j++ // for acts as a foreach
      ) {
        const _inoutdoordata = this.InOutDoorvalue[j];
        _filterdeviceData.forEach(function (data) {        
          if (_inoutdoordata == 0) {
            _filterdeviceData = this.filterdevicedetails;
          }
          if (_inoutdoordata == 1) {
            if (data.FilterDeviceTypeId != 10) filterdata.push(data);
          } else if (_inoutdoordata == 2) {
            if (data.FilterDeviceTypeId == 10)
              filterdata.push(data);
          }
        });
      }
      _filterdeviceData = filterdata;
    }

    //Device Type
    if (this.Device.length > 0 && !this.Device.includes(-1)) {
      _Device = this.Device;
      _filterdeviceData = _filterdeviceData.filter(function (e1) {
        return _Device.includes(e1.FilterDeviceType);
      });
    } else {
      if (
        (this.deviceStatus.length == 0 || this.deviceStatus.includes(-1)) &&
        Campus_Id == 0 &&
        (this.InOutDoorvalue.length == 0 || this.InOutDoorvalue.includes(-1))
      )
        _filterdeviceData = this.filterdevicedetails;
      else _filterdeviceData = filterdata;
    }

    //Search value
    if (this.devicefiltervalue) {
      filterSearch = this.devicefiltervalue;
      let search_MacId = "";
      let search_dualutId = "";
      let search_DCMacId = "";
      let search_Campus = "";
      let search_BuildingName = "";
      let search_FloorName = "";
      let search_DeviceInfo = "";
      let search_LForIR = "";    
      // const search_status = "";

      _searchfilterData = _filterdeviceData.filter(function (data) {
        if (data.MacId != undefined) {
          search_MacId = data.MacId.toLowerCase().includes(
            filterSearch.toLowerCase()
          );
        } else search_MacId = "";

        if (data.dualutId != undefined) {
          search_dualutId = data.dualutId.toString().includes(filterSearch);
        } else search_dualutId = "";

        if (data.DCMacId != undefined) {
          search_DCMacId = data.DCMacId.toLowerCase().includes(
            filterSearch.toLowerCase()
          );
        } else search_DCMacId = "";

        if (data.CampusName != undefined) {
          search_Campus = data.CampusName.toLowerCase().includes(
            filterSearch.toLowerCase()
          );
        } else search_Campus = "";

        if (data.BuildingName != undefined) {
          search_BuildingName = data.BuildingName.toLowerCase().includes(
            filterSearch.toLowerCase()
          );
        } else search_BuildingName = "";

        if (data.FloorName != undefined) {
          search_FloorName = data.FloorName.toLowerCase().includes(
            filterSearch.toLowerCase()
          );
        } else search_FloorName = "";
        
        if(data.DeviceInfo != undefined)
        {
          search_DeviceInfo = data.DeviceInfo.toString().includes(filterSearch);
        }
        else
          search_DeviceInfo = "";
          
        if(data.LforIr != undefined)
        {
          search_LForIR = data.LforIr.toString().includes(filterSearch);
        }
        else
          search_LForIR = "";

        return (
          data.UnitLocation.toLowerCase().includes(
            filterSearch.toLowerCase()
          ) ||
          data.Description.toLowerCase().includes(filterSearch.toLowerCase()) ||
          data.NewDeviceName.toLowerCase().includes(filterSearch.toLowerCase()) ||
          search_DeviceInfo ||
          search_LForIR ||
          search_MacId ||
          search_dualutId ||
          search_DCMacId ||
          search_Campus ||
          search_BuildingName ||
          search_FloorName || 
          data.RoomName.toLowerCase().includes(filterSearch.toLowerCase())
        );
      });
    } else {
      _searchfilterData = _filterdeviceData;
    }
    if (
      this.Inoutdoordeselect == 1 ||
      this.DeviceTypedeselect == 1 ||
      this.DeviceStatusdeselect == 1
    )
      _searchfilterData = [];

    this.dataSource.data = _searchfilterData;
    this.dataSourcecnt = _searchfilterData.length;
    this.downloadDeviceSource.data = _searchfilterData;
    setTimeout(() => {
      this.devicesortedData = [];
      this.dataSource.sort = this.devicesort;
      this.sortingDatalist_Device(this.dataSource);
      this.downloadDeviceSource.sort = this.devicesort;
      this.sortingDatalist_Device(this.downloadDeviceSource);
      this.downloadDeviceSource.connect().subscribe(data => this.devicesortedData = data); 
    }, 0);
    this.deviceloading = false;
  }
  searchDevicedetails(filterValue: any) {
    try {
      this.devicefiltervalue = filterValue;
      this.getFilterDeviceDetails();
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - searchDevicedetails() - " + err.message
      );
    }
  }

  /*********Open add dialogwindow*******/
  addNewDevice(CampusId, campusName) {
    try {
      const deviceaddelement = document.getElementById("divAddDevice");
      if (deviceaddelement != null) deviceaddelement.className = "disable";
      const devicelistelement = document.getElementById("divDeviceheader");
      if (devicelistelement != null) devicelistelement.className = "disable";
      if (!SessionCheckwithStaff(this.router)) {
        return;
      }
      this.getHrexflag();
      this.getMaxRexdevicedetail();
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            const deviceaddelement = document.getElementById("divAddDevice");
            if (deviceaddelement != null) deviceaddelement.className = "enable";
            const devicelistelement =
              document.getElementById("divDeviceheader");
            if (devicelistelement != null)
              devicelistelement.className = "enable";
            return false;
          } else {
            let floorId = CampusId;
            let isIndoor = 0;
            if (this.MFloorId != 0) {
              floorId = this.MFloorId;
              isIndoor = 1;
            }
            const getUnitByFloor: getUnit = {
              authKey: this.authkey,
              floorId: floorId,
              isIndoor: isIndoor,
              isNeedIntercom :parseInt(this.voiceLicense),
              isSubZone : 0   
            };

            this.apiService
              .getUnitDetails(getUnitByFloor)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((Unitsinfo: any[]) => {
                if (Unitsinfo != null && Unitsinfo != undefined) {
                  this.Unitinfo = Unitsinfo["apiReturn"];
                  this.addNewdevicedetails(Unitsinfo["apiReturn"], CampusId, campusName);
                }
              });
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - addNewDevice() - " + err.message
      );
    }
  }

  addNewdevicedetails(UnitInfo, CampusId, CampusName) {
    try {
      const _ShareddialogcloseDetails = {
        SharedreturncloseVal: 1,
      };
      this.alertSharedService.changeclosedialog(_ShareddialogcloseDetails);
      if (this.MFloorId != 0) {
        if (UnitInfo.length == 0) {
          generateAlertMessage(
            this.dialog,
            "Please add the Space in the zone."
          );
          return;
        } else {
          this.Unitnameforfloor = UnitInfo[0].UnitName;
        }
      } else {
        this.Unitnameforfloor = "";
      }

      let HRexFlag = 0;
      let GatewayFlag = 0;
      if (this.MFloorId != 0) {
        if (this.addHRexflag.toString() == "H-rex found") {
          HRexFlag = 1;
          GatewayFlag = 0;
        } else if (this.addHRexflag.toString() == "Gateway found") {
          HRexFlag = 0;
          GatewayFlag = 1;
        } else if (this.addHRexflag.toString() == "both are found") {
          HRexFlag = 1;
          GatewayFlag = 1;
        } else if (this.addHRexflag.toString() == "false") {
          HRexFlag = 0;
          GatewayFlag = 0;
        }
      }

      const CampusDetail = {
        CampusId: CampusId,
        CampusName: CampusName,
        MapDetails: this.map,
        BuildingId: this.MBuildingId,
        UnitInfo: UnitInfo,
        UnitName: this.Unitnameforfloor,
        FloorId: this.MFloorId,
        ImageHeight: this.addfloorimageheight,
        ImageWidth: this.ImageWidth,
        HRexvalue: HRexFlag,
        Gatewayvalue: GatewayFlag,
        MaxRexValue: this.MaxRexvalue,
      };
      const devicedataforadd = [] as any[];
      devicedataforadd.push(this.getdeviceforadd, CampusDetail);
      let AddDeviceComponentHeight = "calc(100vh - 48vh)";
      
      if (this.MFloorId == 0) {
        AddDeviceComponentHeight = "calc(100vh - 60vh)";
      }

      AddDeviceComponentHeight = "460px";

      const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
        window.navigator.userAgent
      );
      if (isIEOrEdge == true) {
        if (this.MFloorId == 0) {
          AddDeviceComponentHeight = "calc(100vh - 60vh)";
        } else AddDeviceComponentHeight = "calc(100vh - 50vh)";
      }

      const dialogRef = this.dialog.open(AddDeviceComponent, {
        disableClose: true,
        width: "1000px",
        height: AddDeviceComponentHeight,
        panelClass: "device-no-padding-dialog",
        position: { bottom: "2%", left: "35%" },
        hasBackdrop: false,
        autoFocus: false,
        data: CampusDetail,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "floorclose") {
          // this.Device = "";
          // this.modeselectdevice=[];
          this.getDeviceDetails();
        } else if (result == "buildingclose") {
          this.Mchange = 1;
        }
        const devicelistelement = document.getElementById("divDeviceheader");
        if (devicelistelement != null) devicelistelement.className = "enable";
        const deviceaddelement = document.getElementById("divAddDevice");
        if (deviceaddelement != null) deviceaddelement.className = "enable";
      });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - addNewdevicedetails() - " + err.message
      );
    }
  }

  @Output() onMapChanged = new EventEmitter<any>();

  sortingDatalist_Device(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (
        sortHeaderId == "UnitLocation" ||
        sortHeaderId == "DeviceName" ||
        sortHeaderId == "Campus" ||
        sortHeaderId == "Building" ||
        sortHeaderId == "Zone" ||
        sortHeaderId == "Space" ||
        sortHeaderId == "Location"
      ) {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }

  gotoCampus() {
    try {
      this.BackgroundIMG = "Centrak";
      this.MBuildingId = 0;
      this.MFloorId = 0;
      this.Mchange = 1;
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - gotoCampus() - " + err.message
      );
    }
  }
  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  editDevicedetails(deviceinfo) {
    try {
      if (!SessionCheckwithStaff(this.router)) {
        return;
      }

      const _dialog = this.dialog;
      let _IsIndoor = 0;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            return false;
          } else {
            if (deviceinfo.DataId > 0) {
              if (deviceinfo.BuildingId == 0 && deviceinfo.FloorId == 0)
                _IsIndoor = 0;
              else _IsIndoor = 1;

              const getDeviceById: getDeviceById = {
                authKey: this.authkey,
                dataId: deviceinfo.DataId,
                deviceType: deviceinfo.DeviceName,
                floorId: 0,
                isIndoor: _IsIndoor,
              };
              this.technologyoverlayService
                .getDeviceDetailsbyId(getDeviceById)
                .pipe(takeUntil(this._onDestroy))
                .subscribe((Devicedetailsinfo: any[]) => {
                  if (
                    Devicedetailsinfo != null &&
                    Devicedetailsinfo != undefined
                  ) {
                    this.edit_DeviceDetails(Devicedetailsinfo["apiReturn"][0]);
                  }
                });
            } else {
              this.edit_DeviceDetails(deviceinfo);
            }
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - editDevicedetails() - " + err.message
      );
    }
  }

  edit_DeviceDetails(deviceinfo) {
    let width = "37em";
    let height = "580px";

    if (deviceinfo.DeviceName == "UT") {
      width = "89rem";
      height = "740px";
    }
    const Devicedetails_info = { DeviceData: deviceinfo, AddDeviceflag: 0 };
    if (this.device_editdialogopen == 0) {
      const dialogRef = this.dialog.open(EditDeviceComponent, {
        disableClose: true,
        width: width,
        height: height,
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: Devicedetails_info,
      });
      this.device_editdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.device_editdialogopen = 0;
        if (result == "flooreditclose") this.getDeviceDetails();
      });
    }
  }

  OnMapclick() {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      const dialogRef = this.dialog.open(DeviceAdvancefilterComponent, {
        disableClose: true,
        width: "75em",
        height: "200px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: "",
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result != "Cancel") {
          this.selectedCampus = result.CampusId;
          this.selectedBuilding = result.BuildingId;
          this.selectedFloor = result.FloorId;
          this.selectedunit = result.UnitId;
          this.getFilterDeviceDetails();
        }
      });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - OnMapclick() - " + err.message
      );
    }
  }

  resetDeviceFilters() {
    this.devicefiltervalue = "";
    this.selectedCampus = 0;
    this.selectedBuilding = 0;
    this.selectedFloor = 0;
    this.selectedunit = 0;

    this.getFilterDeviceDetails();
  }

  clkmatselectdata() {
    if(this.allSelectedInOutdoor != undefined)
    {
      if (this.allSelectedInOutdoor.selected) {
        this.select.options.forEach((item: MatOption) => item.select());
        this.allSelectedInOutdoor.select();
        const testContainer = this.document.querySelector("#selected_InOutdoor");
        const parentElement = testContainer.querySelector(
          ".mat-select-value-text"
        );
        parentElement.children[0].innerHTML = "Select All";
      }
    }
  }

  clkmatdeviceselectdata() {
    if (this.allSelectedDeviceType.selected) {
      this.selectDevices.options.forEach((item: MatOption) => item.select());
      this.allSelectedDeviceType.select();
      const testContainer = this.document.querySelector("#selected_Devices");
      const parentElement = testContainer.querySelector(
        ".mat-select-value-text"
      );
      parentElement.children[0].innerHTML = "Select All";
    }
  }

  clkmatstatusselectdata() {
    if (this.allSelectedStatus.selected) {
      this.selectstatus.options.forEach((item: MatOption) => item.select());
      this.allSelectedStatus.select();
      const testContainer = this.document.querySelector("#selected_Status");
      const parentElement = testContainer.querySelector(
        ".mat-select-value-text"
      );
      parentElement.children[0].innerHTML = "Select All";
    }
    this.getFilterDeviceDetails();
  }

  togglePerOneInOutdoor(value) {
    if (this.allSelectedInOutdoor.selected) {
      this.allSelectedInOutdoor.deselect();
    }
    const selected_data = [] as any[];
    this.Inoutdoordeselect = 0;
    this.select.options.forEach((item: MatOption) =>
      item.selected ? (selected_data[selected_data.length] = item) : null
    );
    if (selected_data.length == this.InOutdoordata.length) {
      this.allSelectedInOutdoor.select();

      const testContainer = this.document.querySelector("#selected_InOutdoor");
      const parentElement = testContainer.querySelector(
        ".mat-select-value-text"
      );
      parentElement.children[0].innerHTML = "Select All";
    } else {
      let strData = "";
      for (let indx = 0; indx < selected_data.length; indx++) {
        strData += selected_data[indx].viewValue + ",";
        if (this.selectedInOutdoor.indexOf(selected_data[indx].value) == -1) {
          this.selectedInOutdoor[this.selectedInOutdoor.length] =
            selected_data[indx].value;
        }
      }
      if (strData.length > 1) {
        strData = strData.slice(0, strData.length - 1);
        const testContainer = this.document.querySelector(
          "#selected_InOutdoor"
        );
        const parentElement = testContainer.querySelector(
          ".mat-select-value-text"
        );
        if (parentElement != null)
          parentElement.children[0].innerHTML = strData;
      } else {
        this.Inoutdoordeselect = 1;
        this.selectedInOutdoor = [];
      }
    }
    this.InOutDoorvalue = value;
    this.getFilterDeviceDetails();
  }

  toggleAllSelectionInOutdoor() {
    if (this.allSelectedInOutdoor.selected) {
      this.select.options.forEach((item: MatOption) => item.select());
      const selected_data = [] as any[];
      this.Inoutdoordeselect = 0;
      this.select.options.forEach((item: MatOption) =>
        item.selected ? (selected_data[selected_data.length] = item) : null
      );
      if (selected_data.length - 1 == this.InOutdoordata.length) {
        this.allSelectedInOutdoor.select();
        setTimeout(() => {
          const testContainer = this.document.querySelector(
            "#selected_InOutdoor"
          );
          const parentElement = testContainer.querySelector(
            ".mat-select-value-text"
          );
          parentElement.children[0].innerHTML = "Select All";
        }, 100);
      }
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this.selectedInOutdoor = [];
      this.Inoutdoordeselect = 1;
    }
    this.getFilterDeviceDetails();
  }

  togglePerOneDeviceType() {
    if (this.allSelectedDeviceType.selected) {
      this.allSelectedDeviceType.deselect();
    }
    const selected_data = [] as any[];
    this.DeviceTypedeselect = 0;
    this.selectDevices.options.forEach((item: MatOption) =>
      item.selected ? (selected_data[selected_data.length] = item) : null
    );
    if (selected_data.length == this.DeviceType.length) {
      this.allSelectedDeviceType.select();
      const testContainer = this.document.querySelector("#selected_Devices");
      const parentElement = testContainer.querySelector(
        ".mat-select-value-text"
      );
      parentElement.children[0].innerHTML = "Select All";
    } else {
      let strData = "";
      for (let indx = 0; indx < selected_data.length; indx++) {
        strData += selected_data[indx].viewValue + ",";
        if (this.selectedDevices.indexOf(selected_data[indx].value) == -1) {
          this.selectedDevices[this.selectedDevices.length] =
            selected_data[indx].value;
        }
      }

      if (strData.length > 1) {
        strData = strData.slice(0, strData.length - 1);
        const testContainer = this.document.querySelector("#selected_Devices");
        const parentElement = testContainer.querySelector(
          ".mat-select-value-text"
        );
        if (parentElement != null)
          parentElement.children[0].innerHTML = strData;
      } else {
        this.selectedDevices = [];
        this.DeviceTypedeselect = 1;
      }
    }
    this.getFilterDeviceDetails();
  }

  toggleAllSelectionDeviceType() {
    if (this.allSelectedDeviceType.selected) {
      this.selectDevices.options.forEach((item: MatOption) => item.select());
      const selected_data = [] as any[];
      this.DeviceTypedeselect = 0;
      this.selectDevices.options.forEach((item: MatOption) =>
        item.selected ? (selected_data[selected_data.length] = item) : null
      );
      if (selected_data.length - 1 == this.DeviceType.length) {
        this.allSelectedDeviceType.select();
        setTimeout(() => {
          const testContainer =
            this.document.querySelector("#selected_Devices");
          const parentElement = testContainer.querySelector(
            ".mat-select-value-text"
          );
          parentElement.children[0].innerHTML = "Select All";
        }, 100);
      }
    } else {
      this.selectDevices.options.forEach((item: MatOption) => item.deselect());
      this.selectedDevices = [];
      this.DeviceTypedeselect = 1;
    }
    this.getFilterDeviceDetails();
  }

  togglePerOneStatus() {
    if (this.allSelectedStatus.selected) {
      this.allSelectedStatus.deselect();
    }
    const selected_data = [] as any[];
    this.DeviceStatusdeselect = 0;
    this.selectstatus.options.forEach((item: MatOption) =>
      item.selected ? (selected_data[selected_data.length] = item) : null
    );

    if (selected_data.length == this.DeviceStatus.length) {
      this.allSelectedStatus.select();
      const testContainer = this.document.querySelector("#selected_Status");
      const parentElement = testContainer.querySelector(
        ".mat-select-value-text"
      );
      parentElement.children[0].innerHTML = "Select All";
    } else {
      let strData = "";
      for (let indx = 0; indx < selected_data.length; indx++) {
        strData += selected_data[indx].viewValue + ",";
        if (this.selectedStatus.indexOf(selected_data[indx].value) == -1) {
          this.selectedStatus[this.selectedStatus.length] =
            selected_data[indx].value;
        }
      }
      if (strData.length > 1) {
        strData = strData.slice(0, strData.length - 1);
        const testContainer = this.document.querySelector("#selected_Status");
        const parentElement = testContainer.querySelector(
          ".mat-select-value-text"
        );
        if (parentElement != null)
          parentElement.children[0].innerHTML = strData;
      } else {
        this.selectedStatus = [];
        this.DeviceStatusdeselect = 1;
      }
    }
    this.getFilterDeviceDetails();
  }

  toggleAllSelectionStatus() {
    if (this.allSelectedStatus.selected) {
      this.selectstatus.options.forEach((item: MatOption) => item.select());
      const selected_data = [] as any[];
      this.DeviceStatusdeselect = 0;
      this.selectstatus.options.forEach((item: MatOption) =>
        item.selected ? (selected_data[selected_data.length] = item) : null
      );

      if (selected_data.length - 1 == this.DeviceStatus.length) {
        this.allSelectedStatus.select();
        setTimeout(() => {
          const testContainer = this.document.querySelector("#selected_Status");
          const parentElement = testContainer.querySelector(
            ".mat-select-value-text"
          );
          parentElement.children[0].innerHTML = "Select All";
        }, 100);
      }
    } else {
      this.selectstatus.options.forEach((item: MatOption) => item.deselect());
      this.selectedStatus = [];
      this.DeviceStatusdeselect = 1;
    }
    this.getFilterDeviceDetails();
  }
  
  exportDevicedata()
  {  
    if(!SessionCheckwithStaff(this.router))
    {
        return;
    }    
    if(this.dataSource.filter != '')
    {
      this.downloadDeviceSource.data = this.dataSource.filteredData;
      setTimeout(() => {        
        this.dataSource.sort = this.devicesort; 
        //this.downloadResidentSource.sort = this.sort;         
        this.downloadDeviceSource.connect().subscribe(data => this.devicesortedData = data);   
    }, 0);      
    }    
  
    const devicedata = this.devicesortedData;  
    const dialogRef = this.dialog.open(DownloadDeviceReportComponent, {
      disableClose: true,
      width: '388px',
      height: '165px',
      panelClass: 'myapp-no-padding-dialog',
      autoFocus: false,
      data: devicedata
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      const element1 = document.getElementById('downloadresdata');
      if(element1 != null)
        element1.className = 'clsDeviceExportbutton mat-raised-button mat-primary ng-star-inserted'; 
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
