import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import {
  report_leftmenu,
  enumgetResidentData,
  enumChronoLogical,
} from "../../../app-settings";
import {
  SessionCheckwithStaff,
  generateAlertMessage,
} from "../../../app-general";
import { ReportService } from "../report.service";
import { getLocationData, getAllLocationHistory } from "../report";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { DownloadLocationhistoryReportComponent } from "../download-locationhistory-report/download-locationhistory-report.component";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { ReportHeaderComponent } from "../report-header/report-header.component";
import { ApiService } from "../../../api.service";
import { AlertSharedService } from "../../../_services/index";
import {
  getResidentRequest,
  SharedCheckIsAlertReportData,
  getStaffData,
} from "../../../api";

@Component({
  selector: "app-locationhistory",
  templateUrl: "./locationhistory.component.html",
  providers: [ReportService, DatePipe],
  styleUrls: ["./locationhistory.component.scss"],
})
export class LocationhistoryComponent implements OnInit {
  ReportType = report_leftmenu.locationhistoryReport;
  private _onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    public dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe,
    public mainLayoutService: MainLayoutService
  ) {}

  displayedLocationColumns: string[] = [
    "ResidentId",
    "ResidentName",
    "RoomId",
    "RoomName",
    "UpdatedOn",
  ];
  IsRefreshNeed = 1;
  locationloading = true;
  LocationHistory = 0;
  LocationHistoryReport;
  LocationdataSource;
  todaydate;
  Todaydate;
  downloadLocationdata;
  selectedResident;
  selectedType;
  Residentdata = [] as any[];
  Staffdata = [] as any[];
  resoptions = [] as any[];
  LocationSelectType = "Resident";
  P_SelectType = "Select Resident";
  LocationTypeId = "Resident Id";
  LocationTypeName = "Resident Name";
  getResidentLocationdata;
  MinDate;
  MaxDate;
  checkLocHistory = 0;
  checkIsFiltered = 0;
  selectedValue = "Resident";
  events: string[] = [];
  locationreportdData = [];
  authkey;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild("LocHistsort") LocHistsort: MatSort;

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.MinDate = "1900-01-01T12:00:00.000Z";
    this.MaxDate = new Date();
    this.selectedResident = "All";
    if (this.IsRefreshNeed == 1) {
      let ChkIsAlertReportChanged = true;

      this.alertSharedService
        .currentSharedCheckIsAlertReport()
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
          (SharedCheckIsAlertReportDetails: SharedCheckIsAlertReportData) => {
            const CheckIsAlertReportDataChanged =
              SharedCheckIsAlertReportDetails;
            if (CheckIsAlertReportDataChanged != undefined) {
              if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 1 &&
                ChkIsAlertReportChanged
              ) {
                ChkIsAlertReportChanged = false;

                if (this.checkIsFiltered == 0) {
                  this.selectReportType(this.selectedValue);
                  this.getAllLocationHistory();
                } else {
                  this.getLocationHistoryListById();
                }

                CheckIsAlertReportDataChanged.IsAlertReportChanged = 0;
                this.alertSharedService.changeSharedCheckIsAlertReport(
                  CheckIsAlertReportDataChanged
                );
              } else if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 0
              ) {
                ChkIsAlertReportChanged = true;
              }
            }
          }
        );
    }

    this.getAllLocationHistory();
    this.getResidentData();
  }

  getAllLocationHistory(): void {
    try {
      this.locationloading = true;
      const LocationTypedata = [] as any[];
      const dateformat = localStorage.getItem("dateFormat") ?? "";
      const locationitem: getAllLocationHistory = {
        authKey: this.authkey,
        dateFormat: dateformat,
      };
      let selected_Type;
      this.reportService
        .getAllLocationHistory(locationitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((LocationHistoryReport: any[]) => {
          this.locationloading = false;
          this.LocationHistoryReport = LocationHistoryReport;
          selected_Type = this.selectedType;

          this.LocationHistoryReport.forEach(function (e) {
            if (selected_Type == "Resident" || selected_Type == undefined) {
              if (e.IsResident == 1) {
                LocationTypedata.push(e);
              }
            } else if (selected_Type == "Staff") {
              if (e.IsResident == 0) LocationTypedata.push(e);
            }
          });
          this.LocationdataSource = new MatTableDataSource(LocationTypedata);
          this.downloadLocationdata = new MatTableDataSource(LocationTypedata);
          this.LocationdataSource.data = LocationTypedata;
          this.downloadLocationdata.data = LocationTypedata;
          setTimeout(() => {
            this.LocationdataSource.sort = this.LocHistsort;
            this.sortingDatalist_LocationHistoryReport(this.LocationdataSource);
            this.downloadLocationdata.sort = this.LocHistsort;
            this.sortingDatalist_LocationHistoryReport(
              this.downloadLocationdata
            );
            this.downloadLocationdata
              .connect()
              .subscribe((data) => (this.locationreportdData = data));
          }, 0);
          if (
            this.selectedType == "Resident" ||
            this.selectedType == undefined
          ) {
            this.LocationdataSource.paginator = this.paginator.toArray()[0];
            this.selectedType = "Resident";
            this.LocationSelectType = "Resident";
            this.P_SelectType = "Select Resident";
          } else if (this.selectedType == "Staff") {
            this.LocationdataSource.paginator = this.paginator.toArray()[0];
            this.selectedType = "Staff";
            this.LocationSelectType = "Staff";
            this.P_SelectType = "Select Staff";
          }
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getAllLocationHistory() - " + err.message
      );
    }
  }

  getResidentData() {
    const residentdata: getResidentRequest = {
      authKey: this.authkey,
      residentType: enumgetResidentData.ActiveData,
      isChronoLogical: enumChronoLogical.alphanumeric,
    };

    this.apiService
      .getResidentData(residentdata)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((Residentdetails: any[]) => {
        this.Residentdata = Residentdetails["apiReturn"];
        this.resoptions = this.Residentdata;
      });
  }

  selectReportType(selectType) {
    const locationresdata = [];
    const LocationTypedata = [] as any[];
    this.selectedValue = selectType;

    if (selectType == "Resident") {
      this.selectedResident = "All";
      this.LocationSelectType = "Resident";
      this.P_SelectType = "Select Resident";
      this.LocationTypeId = "Resident Id";
      this.LocationTypeName = "Resident Name";
      this.LocationHistoryReport.forEach(function (e) {
        if (e.IsResident == 1) LocationTypedata.push(e);
      });
      this.getResidentData();
    } else if (selectType == "Staff") {
      this.selectedResident = "All";
      this.LocationSelectType = "Staff";
      this.P_SelectType = "Select Staff";
      this.LocationTypeId = "Staff Id";
      this.LocationTypeName = "Staff Name";
      const _Staffdetails = [] as any[];
      const staffdata: getStaffData = { authKey: this.authkey };
      this.apiService
        .getStaffData(staffdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Staffdetails: any[]) => {
          this.Staffdata = Staffdetails["apiReturn"];
          this.Staffdata.forEach(function (data) {
            if (data.TagId > 0) _Staffdetails.push(data);
          });
          this.resoptions = _Staffdetails;
        });

      this.LocationHistoryReport.forEach(function (e) {
        if (e.IsResident == 0) LocationTypedata.push(e);
      });
    }
    this.LocationdataSource = new MatTableDataSource(LocationTypedata);
    this.downloadLocationdata = new MatTableDataSource(LocationTypedata);
    this.LocationdataSource.data = LocationTypedata;
    this.downloadLocationdata.data = LocationTypedata;
    setTimeout(() => {
      this.LocationdataSource.sort = this.LocHistsort;
      this.sortingDatalist_LocationHistoryReport(this.LocationdataSource);
      this.downloadLocationdata.sort = this.LocHistsort;
      this.sortingDatalist_LocationHistoryReport(this.downloadLocationdata);
      this.downloadLocationdata
        .connect()
        .subscribe((data) => (this.locationreportdData = data));
    }, 0);
    this.LocationdataSource.paginator = this.paginator.toArray()[0];
    this.resoptions = locationresdata;
  }

  selectReportResident(selectresident) {
    this.selectedResident = selectresident;

    this.getLocationHistoryListById();
    const saveclick = document.getElementById("locationdownload");
    if (saveclick != null)
      saveclick.className =
        "clsLoctionDownloadbutton mat-raised-button mat-primary disablebtn";
  }

  getLocationHistoryListById() {
    this.checkIsFiltered = 0;
    let selected_ResStaffId = 0;
    if (this.selectedResident == 0 && this.LocationSelectType == "Resident") {
      generateAlertMessage(this.dialog, "Please select Resident");
      this.selectedResident = "All";
      return;
    } else if (
      this.selectedResident == 0 &&
      this.LocationSelectType == "Staff"
    ) {
      generateAlertMessage(this.dialog, "Please select Staff");
      this.selectedResident = "All";
      return;
    } else {
      if (this.selectedResident == "All") selected_ResStaffId = 0;
      else selected_ResStaffId = this.selectedResident;

      this.checkIsFiltered = 1;
      const dateformat = localStorage.getItem("dateFormat") ?? "";
      const getlocationdata: getLocationData = {
        authKey: this.authkey,
        residentId: selected_ResStaffId,
        type: this.LocationSelectType,
        dateFormat: dateformat,
      };
      this.reportService
        .getLocationHistoryListById(getlocationdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Residentdetailsbyfilter: any[]) => {
          this.getResidentLocationdata = Residentdetailsbyfilter;
          this.LocationdataSource = new MatTableDataSource(
            this.getResidentLocationdata
          );
          this.downloadLocationdata = new MatTableDataSource(
            this.getResidentLocationdata
          );
          this.LocationdataSource.data = this.getResidentLocationdata;
          this.downloadLocationdata.data = this.getResidentLocationdata;
          setTimeout(() => {
            this.LocationdataSource.sort = this.LocHistsort;
            this.sortingDatalist_LocationHistoryReport(this.LocationdataSource);
            this.downloadLocationdata.sort = this.LocHistsort;
            this.sortingDatalist_LocationHistoryReport(
              this.downloadLocationdata
            );
            this.downloadLocationdata
              .connect()
              .subscribe((data) => (this.locationreportdData = data));
          }, 0);
          this.LocationdataSource.paginator = this.paginator.toArray()[0];

          const saveclick = document.getElementById("locationdownload");
          if (saveclick != null)
            saveclick.className =
              "clsLoctionDownloadbutton mat-raised-button mat-primary";
        });
    }
  }

  getErrorMessage(pickerInput: string): string {
    if (!pickerInput || pickerInput === "") {
      return "Please select date";
    } else if (!moment(pickerInput).isValid()) {
      return "Please enter valid date format MM/DD/YYYY";
    } else {
      return "Please enter current date format MM/DD/YYYY";
    }
  }

  locationHistoryReportdata() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    if (this.selectedResident == 0) {
      this.locHisDownload_data();
    } else if (this.selectedResident != 0 && this.checkLocHistory == 0) {
      this.locHisDownload_data();
    }
  }

  locHisDownload_data() {
    const downloadLocData = {
      Data: this.locationreportdData,
      filterValue: this.selectedResident,
    };

    const dialogRef = this.dialog.open(DownloadLocationhistoryReportComponent, {
      disableClose: true,
      width: "420px",
      height: "180px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: downloadLocData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  sortingDatalist_LocationHistoryReport(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (sortHeaderId == "ResidentName" || sortHeaderId == "RoomName") {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
    if (ReportHeaderComponent.isReportMenuClosed == false) {
      this.reportService.toggleReportMenuNav.emit();
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
