import { Component, OnInit,OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import { enumUIFeatureView, enumUIAction, enumUIView } from '../../../app-settings';
import { getFeatureNeedtoShow } from '../../../app-general';
import { SidenavComponent } from '../../../main-layout/sidenav/sidenav.component';
import { MainLayoutService } from '../../../main-layout/main-layout.service';
import { InfrastructureAlertsComponent } from '../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component';

@Component({
  selector: 'app-staff-header',
  templateUrl: './staff-header.component.html',
  styleUrls: ['./staff-header.component.scss']
})
export class StaffHeaderComponent implements OnInit,OnDestroy {

  constructor(private router: Router,public mainLayoutService: MainLayoutService) { }
  showResident = false;
  showStaff = false;
  showWander = false;
  showAsset = false;
  selectedIndex;
  UserName;
  
  ngOnInit() {
    
    // this.UserName = JSON.parse(localStorage.getItem("username"));
    this.UserName = localStorage.getItem("username") != null ? localStorage.getItem("username") : "";
    if (getFeatureNeedtoShow(enumUIFeatureView.Resident, enumUIAction.Resident_View) == enumUIView.Show) {
      this.showResident = true;
    }
    if (getFeatureNeedtoShow(enumUIFeatureView.Staff, enumUIAction.Staff_View) == enumUIView.Show) {
      this.showStaff = true;
    }
    if (localStorage.getItem("wander_config") == '1') {
      if (getFeatureNeedtoShow(enumUIFeatureView.Roaming, enumUIAction.Roaming_View) == enumUIView.Show) {
        this.showWander = true;
      }
    } 
    if(localStorage.getItem("asset_config") == "1")
    {
      if (getFeatureNeedtoShow(enumUIFeatureView.Asset, enumUIAction.Asset_View) == enumUIView.Show) {
        this.showAsset= true;     
      }
    } 
      
    if( this.showResident == true && this.showStaff == true )
      this.selectedIndex = 1;
    else if(this.showResident == false && this.showStaff == true)
      this.selectedIndex = 0;
   
  }
  resident_staff($event) : void {
    if ($event.tab.textLabel=="Resident")
    {
      this.selectedIndex = $event.index
     this.router.navigate(['../../resident']);  
     this.CloseRightSidenav();   
    }
    else if($event.tab.textLabel == 'Staff' &&  this.showStaff==true)
    {
      this.selectedIndex = $event.index
      this.router.navigate(['../../staff']);
      this.CloseRightSidenav();
    }
    else if($event.tab.textLabel == 'Roaming' &&  this.showWander==true)
    { 
      this.selectedIndex = $event.index     
      this.router.navigate(['../../wanderprevention']);
      this.CloseRightSidenav();
    }
    else if ($event.tab.textLabel == 'Asset' &&  this.showAsset==true)
    {
      this.selectedIndex = $event.index
      this.router.navigate(['../../asset']);
      this.CloseRightSidenav();
    }
}

CloseRightSidenav()
{
  if(SidenavComponent.isClosed == false)
  {
      this.mainLayoutService.toggleSideNavRight.emit();
  }
  if(InfrastructureAlertsComponent.isinfraClosed == false)
  {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
  }
}

ngOnDestroy(){
  console.log("staff-header.component - ngOnDestroy");
}
}
