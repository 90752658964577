import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Inject,
  OnDestroy,
} from "@angular/core";
import {
  Alarmdetails,
  Notificationdetails,
  getAlarmList,
} from "../notification";
import { notificationService } from "../notification.service";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheckwithStaff } from "../../../app-general";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
  selector: "app-notification-leftbar",
  templateUrl: "./notification-leftbar.component.html",
  providers: [notificationService],
  styleUrls: ["./notification-leftbar.component.scss"],
})
export class NotificationLeftbarComponent implements OnInit, OnDestroy {
  Alarmdetail: Alarmdetails[];
  Notificationdetail: Notificationdetails[];
  selecteditem = 1;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();
  notificationImageURL = "assets/images/Notification/";
  constructor(
    private router: Router,
    private notificationService: notificationService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.getAlarmList();
    this.selecteditem = 1;
  }

  getAlarmList(): void {
    try {
      const notifyItem: getAlarmList = {
        authKey: this.authkey,
      };
      this.notificationService
        .getAlarmList(notifyItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Alarminfo: any[]) => {
          this.Alarmdetail = Alarminfo;
        });
    } catch (err) {
      throw new Error(
        "notification-leftbar.component.ts" +
          " - getAlarmList() - " +
          err.message
      );
    }
  }

  @Output() onNameChanged = new EventEmitter<string>();

  onItemSelected(alarmname, alarmid): void {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    this.selecteditem = alarmid;
    this.onNameChanged.emit(alarmid);
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
