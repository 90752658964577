import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import {
  getDeviceDetailsReq,
  getSpaceCategory,
  duplicateSpace,
} from "../../../technologyoverlay";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import { enumEnableDisable,appsettings } from "../../../../../app-settings";
import { Router } from "@angular/router";
import { ApiService } from "../../../../../api.service";
import { enumSpaceCategory, NewDeviceTypeDesc, DeviceType } from "../../../../../app-settings";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-duplicate-unit",
  templateUrl: "./duplicate-unit.component.html",
  styleUrls: ["./duplicate-unit.component.scss"],
})
export class DuplicateUnitComponent implements OnInit {
  public unitDuplicateForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  selectedUnitId;
  FloorInfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  SpaceInfo;
  columns = 4;
  firstcolumns = 2;
  minheight = "350px";
  rowHeight = "79px";
  checkfloorinfo = false;
  select_DeviceIds = [] as any[];
  DevicesInfo;
  selectedFloorName;
  selectFloorId;
  DevicesInfowithoutZone = [] as any[];
  isCheckedAll = false;
  checkResOffLimits = false;
  Spacecategory_details;
  selectCategoryId;
  duplicatecheckSpaceCategory = 0;
  authkey;
  selectCampusId;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<DuplicateUnitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.selectedUnitId = data.UnitId;
    this.selectedFloorName = data.FloorName;
    this.selectFloorId = data.FloorId;
    this.selectCampusId = data.CampusId;
    if (data.SpaceCategoryId > 0) {
      this.selectCategoryId = data.SpaceCategoryId;
      if (
        this.selectCategoryId == enumSpaceCategory.StaffArea ||
        this.selectCategoryId == enumSpaceCategory.ActivityArea ||
        this.selectCategoryId == enumSpaceCategory.DiningArea ||
        this.selectCategoryId == enumSpaceCategory.CommonArea
      ) {
        this.duplicatecheckSpaceCategory = 1;
      }
    }

    if (data.isResidentOffLimit == 1) this.checkResOffLimits = true;
    else this.checkResOffLimits = false;
    this.breakpoint$.subscribe(() => this.breakpointChanged());           
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }    
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "350px";
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.unitDuplicateForm = new UntypedFormGroup({
      unitName: new UntypedFormControl(""),
      campusID: new UntypedFormControl(""),
      buildID: new UntypedFormControl(""),
      floorID: new UntypedFormControl(""),
      UnitID: new UntypedFormControl(""),
      newunitname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]), // ^[a-zA-Z0-9!@#$%^& ]*$
      description: new UntypedFormControl("", [
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      roomcategory: new UntypedFormControl("", [Validators.required]),
    });
    
    const formUnitName = this.unitDuplicateForm.get("unitName");
    if(formUnitName != null)
      formUnitName.disable();

    this.isCheckedAll = true;
    this.getDeviceDetails();
    this.getSpaceCategory();
  }

  getDeviceDetails() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.selectCampusId,
        floorId: parseInt(this.selectFloorId),
        status: "3",
        devicetype: 0,
        device: 0,
        spaceId: parseInt(this.selectedUnitId),
        isIndoor: 1,
        isNeedRoomonly: 1,
        isNeedIntercom: parseInt(voiceLicense)
      };

      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          this.DevicesInfo = DeviceDetails["apiReturn"];
          const Device_infos = [] as any[];
          DeviceDetails["apiReturn"].forEach(function (data) {
            if (data.FilterDeviceType != "Zone") {
              Device_infos.push(data);
            }
            if(data.FilterDeviceType == NewDeviceTypeDesc.LF)
            {
              if (data.SubType==DeviceType.DripTray)
              {
                data.FilterDeviceType =NewDeviceTypeDesc.DripTray
              }
              else
              {
              data.FilterDeviceType =NewDeviceTypeDesc.LFExiter
              }
            }
          });
          this.DevicesInfowithoutZone = Device_infos;
          this.toggleAll(this.isCheckedAll);
        });
    } catch (err) {
      throw new Error(
        "duplicate-unit.component.ts" + " - getDeviceDetails() - " + err.message
      );
    }
  }

  getSpaceCategory() {
    try {
      const spaceitem: getSpaceCategory = {
        authKey: this.authkey,
        categoryId: 0,
      };
      this.technologyoverlayService
        .getSpaceCategory(spaceitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((spacedetails: any[]) => {
          this.Spacecategory_details = spacedetails;
        });
    } catch (err) {
      throw new Error(
        "duplicate-unit.component.ts" + " - getSpaceCategory() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("close");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  toggleAll(event: any) {
    console.log(event);
    if (event.checked || event == true) {
      this.select_DeviceIds = [];
      this.DevicesInfo.forEach((row) => {
        if (row.FilterDeviceType != "Zone") {
          const checkedid = row.DataId;
          this.select_DeviceIds.push(checkedid);
        }
      });
    } else {
      this.select_DeviceIds = [];
      this.select_DeviceIds.length = 0;
    }
    this.isCheckedAll = this.isChecked();
  }

  toggledeviceVisibility(checkedid, event: MatCheckboxChange) {
    if (event.checked) {
      const index = this.select_DeviceIds.indexOf(checkedid);
      if (index == -1) {
        this.select_DeviceIds.push(checkedid);
      }
    } else {
      const index = this.select_DeviceIds.indexOf(checkedid);
      if (index >= 0) {
        this.select_DeviceIds.splice(index, 1);
      }
    }
    this.isCheckedAll = this.isChecked();
  }

  exists(checkedid) {
    return this.select_DeviceIds.indexOf(checkedid) > -1;
  }

  isChecked() {
    return this.select_DeviceIds.length === this.DevicesInfowithoutZone.length;
  }
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.unitDuplicateForm.controls).forEach((field) => {
        // {1}
        const control = this.unitDuplicateForm.get(field); // {2}
        const formUnitTagetId = document.getElementById(event.target.id);
        if (formUnitTagetId != null) {
          if (field == formUnitTagetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  selectSpaceCategory(categoryId) {
    if (
      categoryId == enumSpaceCategory.ResidentRoom ||
      categoryId == enumSpaceCategory.Egress ||
      categoryId == enumSpaceCategory.Outdoor
    )
      this.duplicatecheckSpaceCategory = 0;
    else this.duplicatecheckSpaceCategory = 1;

    this.selectCategoryId = categoryId;
  }

  public duplicateunitDetails = (duplicateunitformvalue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.unitDuplicateForm.valid) {
         this.duplicateSpace(duplicateunitformvalue);
      }
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" +
          " - templatefloorDetails() - " +
          err.message
      );
    }
  };

  duplicateSpace(duplicateunitformvalue) {
    try {
      let resofflimits = 0;      
      if (this.select_DeviceIds.length == 0 && this.DevicesInfo.length > 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Device to create as a new Space."
        );        
        this.duplicateUnitEnableDisable(enumEnableDisable.Enable);
        return;
      }
      if (this.select_DeviceIds.length == 0 && this.DevicesInfo.length == 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Device to duplicate or create as a Template."
        );        
        this.duplicateUnitEnableDisable(enumEnableDisable.Enable);
        return;
      }      
      this.duplicateUnitEnableDisable(enumEnableDisable.Disable);

      let _Description = "";
      if (duplicateunitformvalue.description != "")
        _Description = duplicateunitformvalue.description
          .replace(/\s+/g, " ")
          .trim();
      else _Description = "";

      if (this.checkResOffLimits == true) resofflimits = 1;
      else resofflimits = 0;

      const duplicateSpaceinfo: duplicateSpace = {
        authKey: this.authkey,
        campusId: parseInt(duplicateunitformvalue.campusID),
        buildingId: parseInt(duplicateunitformvalue.buildID),
        zoneId: parseInt(duplicateunitformvalue.floorID),
        spaceId: parseInt(duplicateunitformvalue.UnitID),
        newSpaceName: duplicateunitformvalue.newunitname
          .replace(/\s+/g, " ")
          .trim(),
        newSpaceDescription: _Description, //duplicateunitformvalue.description,
        deviceIds: this.select_DeviceIds.toString(),
        residentOffLimits: resofflimits,
        spaceCategoryId: parseInt(this.selectCategoryId),
      };
      this.technologyoverlayService
        .duplicateSpace(duplicateSpaceinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["NewSpaceId"] > 0) {
            generateAlertMessage(
              this.dialog,
              "Duplicate Space added successfully."
            );            
            this.duplicateUnitEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close(apiresponse);
            this.select_DeviceIds = [];
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Duplicate Space added failed.");            
            this.duplicateUnitEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close("close");
            this.select_DeviceIds = [];
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else if (apiresponse["condition"] == "Duplicate Gateway") {
            generateAlertMessage(
              this.dialog,
              "Clear Gateway already added.Please select any other devices"
            );            
            this.duplicateUnitEnableDisable(enumEnableDisable.Enable);
            return;
          } else if (apiresponse["condition"] == "Duplicate Clear Router") {
            generateAlertMessage(
              this.dialog,
              "Clear Router already added.Please select any other devices"
            );            
            this.duplicateUnitEnableDisable(enumEnableDisable.Enable);
            return;
          } else {
            generateAlertMessage(
              this.dialog,
              "Space name '" + apiresponse[0].UnitName + "' already exists."
            );            
            this.duplicateUnitEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "duplicate-unit.component.ts" + " - duplicateSpace() - " + err.message
      );
    }
  }

  duplicateUnitEnableDisable(checkflag) {
    const elementcontent = document.getElementById(
      "btnspaceduplicatesaveclick"
    );
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsunitdupsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsunitdupsavebutton mat-raised-button disablebtn";
  }

  toggler_ResOffLimits(e) {
    if (e.checked == true) {
      this.checkResOffLimits = true;
    } else {
      this.checkResOffLimits = false;
    }
  }
}
