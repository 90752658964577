import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import { enumEnableDisable,appsettings } from "../../../../../app-settings";
import { Router } from "@angular/router";
import { ApiService } from "../../../../../api.service";
import {
  ZoneByID,
  getTemplates,
  addTemplateZone,
} from "../../../technologyoverlay";
import { getCampus, getBuilding } from "../../../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-add-template",
  templateUrl: "./add-template.component.html",
  styleUrls: ["./add-template.component.scss"],
})
export class AddTemplateComponent implements OnInit {
  public flooraddTempateForm: UntypedFormGroup;
  public floorTempateForm: UntypedFormGroup;
  selectedTempId;
  FloorInfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  SpaceInfo;
  public watcher: Observable<boolean>;
  firstcolumns = 2;
  rowHeight = "79px";
  checkfloorinfo = false;
  Templatedetails;
  Tempflag = 0;
  Campusdetails;
  Buildingdetails;
  selectedCampusId;
  selectBuildId;
  selTemp_Id;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    if (data.Tempflag == 1) {
      this.Tempflag = 1;
      this.selTemp_Id = data.SelectTempId;
      this.selectedCampusId = data.CampusId;
      this.selectBuildId = data.BuildingId;
    } else {
      this.selectedCampusId = data.CampusId;
      this.selectBuildId = data.BuildId;
    }    
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.firstcolumns = 2;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.firstcolumns = 1;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.floorTempateForm = new UntypedFormGroup({
      template: new UntypedFormControl("", [Validators.required]),
    });

    this.flooraddTempateForm = new UntypedFormGroup({
      campus: new UntypedFormControl("", [Validators.required]),
      building: new UntypedFormControl("", [Validators.required]),
      floorname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });

    if (this.Tempflag == 0) this.getTemplates();
    else if (this.Tempflag == 1) this.getCampusDetails();
  }

  public onCloseCancel = () => {
    try {
      this.thisDialogRef.close("Cancel");
      if (!SessionCheck(this.router)) {
        return;
      }
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - onCloseCancel() - " + err.message
      );
    }
  };
  //Use Template will show the list of templates available. We can choose a template from drop down.
  getTemplates() {
    try {
      const tempdata: getTemplates = {
        authKey: this.authkey,
      };
      const template_data = [] as any[];
      this.technologyoverlayService
        .getTemplates(tempdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((TempDetails: any[]) => {
          TempDetails.forEach(function (e) {
            if (e.TemplateCategoryId == 1) {
              template_data.push(e);
            }
          });
          this.Templatedetails = template_data;
        });
    } catch (err) {
      throw new Error(
        "add-template.component.ts" + " - getTemplates() - " + err.message
      );
    }
  }

  selecttemplatefloorDetails(floortempformvalue) {
    console.log(floortempformvalue);
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.floorTempateForm.valid) {
        this.loadTemplatedetails();
      }
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" +
          " - duplicatefloorDetails() - " +
          err.message
      );
    }
  }

  loadTemplatedetails() {
    const saveclick = document.getElementById("btnsavenextclick");
    if (saveclick != null)
      saveclick.className = "clstempsavebutton mat-raised-button disablebtn";
    this.Tempflag = 1;
    const tempdata = {
      Tempflag: this.Tempflag,
      SelectTempId: this.selectedTempId,
      CampusId: this.selectedCampusId,
      BuildingId: this.selectBuildId,
    };

    this.thisDialogRef.close("Cancel");
    const dialogRef = this.dialog.open(AddTemplateComponent, {
      disableClose: true,
      width: "32em",
      height: "400px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: tempdata,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != "" && result != "Cancel") {
        this.getFloorDetailsbyId(result);
      }
    });
  }

  changeTemplate(tempid) {
    this.selectedTempId = tempid;
  }

  getCampusDetails() {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
          this.getBuildingDetails(this.selectedCampusId);
        });
    } catch (err) {
      throw new Error(
        "add-template.component.ts" + " - getCampusDetails() - " + err.message
      );
    }
  }

  getBuildingDetails(campusid) {
    try {
      const buildid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(campusid),
      };
      this.apiService
        .getBuildingDetails(buildid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          this.Buildingdetails = BuildingDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-template.component.ts" + " - getBuildingDetails() - " + err.message
      );
    }
  }

  changecampus_Template(campusid) {
    this.selectedCampusId = campusid;
    this.selectBuildId = 0;
    this.flooraddTempateForm.controls["building"].setValue("");
    this.getBuildingDetails(this.selectedCampusId);
  }

  changebuilding_Template(buildingid) {
    this.selectBuildId = buildingid;
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.flooraddTempateForm.controls).forEach((field) => {
        // {1}
        const control = this.flooraddTempateForm.get(field); // {2}
        const formFloorTargetId = document.getElementById(event.target.id);
        if (formFloorTargetId != null) {
          if (field == formFloorTargetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-template.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public addtemplatefloorDetails = (addfloortempformvalue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.flooraddTempateForm.valid) {
        this.addTemplateZone(addfloortempformvalue);
      }
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" +
          " - duplicatefloorDetails() - " +
          err.message
      );
    }
  };
  //Add zone is used to add a new zone in the selected building. When will click the Add new zone it will show the popup window with the following option
  //2. Use Template
  addTemplateZone(addfloortempform) {
    try {
      this.templateAddEnableDisable(enumEnableDisable.Disable);

      const templatefloor: addTemplateZone = {
        authKey: this.authkey,
        templateId: parseInt(this.selTemp_Id),
        campusId: parseInt(addfloortempform.campus),
        buildingId: parseInt(addfloortempform.building),
        newZoneName: addfloortempform.floorname.toString(),
        newZoneDescription: addfloortempform.floorname.toString(),
      };

      this.technologyoverlayService
        .addTemplateZone(templatefloor)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["FloorId"] > 0) {
            this.thisDialogRef.close(apiresponse["FloorId"]);
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Zone Template added failed.");
            this.templateAddEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close("close");
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(
              this.dialog,
              "Zone name '" + apiresponse[0].FloorName + "' already exists."
            );
            this.templateAddEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "add-template.component.ts" + " - addTemplateZone() - " + err.message
      );
    }
  }

  getFloorDetailsbyId(floorid) {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(floorid),
    };

    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.router.navigate(["../../mapfloor"], { state: FloorByid["apiReturn"][0] });
      });
  }

  templateAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsavetemplateaddclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clstempsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clstempsavebutton mat-raised-button disablebtn";
  }
}
