<div id="divConfirmMessage" class="clsConfirmMessage">
  <div class="ConfirmMessage-title">
    <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="ConfirmMessageHeader" style="margin: 0 0 10px 11px;">{{Confirm_header}}
      <span><img class="clscloseimage" src="assets/images/close.png" (click)="onCloseCancel()"></span></h3>
    <mat-divider></mat-divider>
  </div>
  <div class="clsConfirmMessagecontent">
    <div [innerHtml]="ConfirmMessage"></div>
    <mat-dialog-actions class="clsConfirmMessagebuttoncontrol">
      <button class="clsaccbutton_Cancel" id='btnaccessenter' mat-raised-button (click)="onCloseConfirmMessage()">Yes</button>
      <button class="clsaccbutton_Cancel" id='btnaccessenter' mat-raised-button (click)="onCloseCancel()">No</button>
    </mat-dialog-actions>
  </div>
</div>