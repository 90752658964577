import { Component, OnInit } from '@angular/core';
import { technology_overlay_leftmenu } from '../../../app-settings';
import { TechnologyoverlayService } from '../technologyoverlay.service';
import { leftmenu } from '../technologyoverlay';
import { enumUIFeatureView, enumUIAction, enumUIView } from '../../../app-settings';
import {TooltipPosition} from '@angular/material/tooltip';
import { UntypedFormControl} from '@angular/forms';
import { getFeatureNeedtoShow } from '../../../app-general';
import { SidenavComponent } from '../../../main-layout/sidenav/sidenav.component';
import { MainLayoutService } from '../../../main-layout/main-layout.service';
import { InfrastructureAlertsComponent } from '../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component';
import { TechnologyoverlayHeaderComponent } from '../technologyoverlay-header/technologyoverlay-header.component';
@Component({
  selector: 'app-technologyoverlay-leftbar',
  templateUrl: './technologyoverlay-leftbar.component.html',
  styleUrls: ['./technologyoverlay-leftbar.component.scss']
})
export class TechnologyoverlayLeftbarComponent implements OnInit {  
  tooltipflag = 0;
  technology_overlay_leftmenu=technology_overlay_leftmenu;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[3]); 
  constructor(private technologyoverlayService: TechnologyoverlayService,public mainLayoutService: MainLayoutService) {     
   }
  leftmenu: leftmenu[]=[];  
  ngOnInit() 
  {   
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Device_Map_View) == enumUIView.Show) {
      this.leftmenu[this.leftmenu.length] = {id:'leftmenu1',routerLink: '../floorplans', src: 'assets/images/Technology Overlay-Floorplans.png',description:'Device Maps',subitem:'Add/Edit/Delete'};
    }
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Templates_View) == enumUIView.Show) {
      this.leftmenu[this.leftmenu.length] = {id:'leftmenu2',routerLink: '../templates', src: 'assets/images/Templates.png',description:'Templates',subitem:'Edit/Delete'};
    }
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Device_List_View) == enumUIView.Show) {
      this.leftmenu[this.leftmenu.length] = {id:'leftmenu3',routerLink: '../device', src: 'assets/images/Technology Overlay-Device Overview.png',description:'Device List',subitem:'View/Edit'};
    } 
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Outdoor_Locating_View) == enumUIView.Show) {
      if (localStorage.getItem("outdoor_Locating") == '1') {
        this.leftmenu[this.leftmenu.length] = {id:'leftmenu4',routerLink: '../approximatelocating', src: 'assets/images/Notifications-Campus-Icon-Blue.png',description:'Approximate Locating',subitem:'Add/Edit/Delete'};
      }
    } 
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Hardwired_Configration_View) == enumUIView.Show) 
    {
      if (localStorage.getItem("hardwired_configuration") =="1")
          this.leftmenu[this.leftmenu.length] = {id:'leftmenu5',routerLink: '../hardwiredconfiguration', src: 'assets/images/hardwiredConfig.png',description:'Hardwired Configuration',subitem:'Add/Edit/Delete'};
      else if (localStorage.getItem("hardwired_configuration") =="2")
          this.leftmenu[this.leftmenu.length] = {id:'leftmenu5',routerLink: '../tektone', src: 'assets/images/hardwiredConfig.png',description:'TekTone',subitem:'Add/Edit/Delete'};
    } 

    if(SidenavComponent.isClosed == false)
    {
        this.mainLayoutService.toggleSideNavRight.emit();
    }  
    if(InfrastructureAlertsComponent.isinfraClosed == false)
    {
        this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }   
  }
  sideMenuNavcontent()
  {            
      if(TechnologyoverlayHeaderComponent.isMenuClosed == false)
      {
        this.technologyoverlayService.toggleTechMenuNav.emit();
      }      
  }
}
