
<perfect-scrollbar style="max-width: 980px; max-height: 480px;" >
    <div class="clsasset">
        <div class="assetadd-title">
          <h3 class="assetAddHeader" style="margin: 0 0 10px 11px;">Color Code Configuration
          <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseAsset()"></span></h3>
          <mat-divider ></mat-divider> 
        </div>
        <div class="clsaddcontent">
         
              <form  [formGroup]="dataForm" style="padding:20px;" autocomplete="off" novalidate>
              <div *ngFor="let c of locationtype; let i = index;" style="padding:10px;" formArrayName="key">               
                    <span class="dot" [ngStyle]="{'background-color': c.TypeColor}"></span>                            
                    <input placeholder="Placeholder" class="clstxtcolorconfig" [(ngModel)]="c.TypeName" [formControlName]="i" maxlength="20">
              </div>  
                <mat-dialog-actions class="clsassetbuttoncontrol">                            
                        <button type="button" class="clsbtnColorconfigCancel" mat-raised-button (click)="onCloseAsset()">Cancel</button>
                        <button id="btnsaveclick"  class="clsbtnColorconfigSave" mat-raised-button (click)="updateColorConfigType()">Save</button>&nbsp;
                </mat-dialog-actions> 
              </form>
        </div>
     
    </div>
    
    </perfect-scrollbar>
    