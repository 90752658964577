
<perfect-scrollbar style="max-width: 980px; max-height: 350px;" >
  <div class="clsreportedit">
      <div class="assetedit-title">
        <h3 class="assetEditHeader" style="margin: 0 0 10px 11px;">Edit Asset Name
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseAsset()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>
      <div class="clsaddcontent">
       
            <form  [formGroup]="EditassetNameForm" style="padding:20px;" autocomplete="off" novalidate (ngSubmit)="EditReportAssetName(EditassetNameForm.value)" >
            <!-- <div style="padding:10px;">  
              <span>Asset Name</span>                                                      
                  <input placeholder="Asset Name" class="clstxtcolorconfig" [formControlName]="AssetName" [(ngModel)]="data.GeoName" maxlength="30">                                                        
            </div>   -->

            <mat-grid-list [cols]="columns" rowHeight="80px" style="align-items: baseline;">

              <mat-grid-tile>
                <label  class="clstxtcontrol">Asset Name</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput formControlName="assetname"  [(ngModel)]="ReportAssetName" maxlength="30" (keyup)="handleInput($event)" required>
                      <input type="hidden" formControlName="assetId" [(ngModel)]="data.AssetId">
                      <mat-error style="font-size: 11px" *ngIf="EditassetNameForm.controls.assetname.hasError('required')">
                          Please enter Asset Name
                      </mat-error>
                      <mat-error *ngIf="EditassetNameForm.controls.assetname.hasError('pattern')">
                          Please enter valid Asset Name
                      </mat-error>     
                      <mat-error *ngIf="EditassetNameForm.controls.assetname.hasError('maxlength')">
                        Max length 30 characters only
                    </mat-error>                 
                    </mat-form-field>
                  </div>
              </mat-grid-tile>

            </mat-grid-list>

              <mat-dialog-actions class="clsassetbuttoncontrol">    
                      <button id="btnsaveclick"  class="clsassetsavebutton" mat-raised-button>Save</button>&nbsp;
                      <button type="button" class="clsassetaddbutton" mat-raised-button (click)="onCloseAsset()">Cancel</button>
              </mat-dialog-actions> 
            </form>
      </div>
   
  </div>
  
  </perfect-scrollbar>
  