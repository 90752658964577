import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { ApiService } from "../../../api.service";
import {
  getSafeZoneID,
  getCampusdetails,
  ResGroup,
  Resident,
  Staff,
  getResidentRequest,
  getGroupData,
} from "../../../api";
import {
  ResidentGroup,
  WanterforCreationResponse,
  addWanderConfig,
} from "../wanderconfig";
import { WanderService } from "../wanderconfig.service";
import {
  enumgetResidentData,
  enumResEgress_Type,
  enumChronoLogical,
} from "../../../app-settings";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../app-general";
import { Router } from "@angular/router";
import {
  TreeviewItem,
  DownlineTreeviewItem,
  TreeviewEventParser,
  TreeviewConfig,
  OrderDownlineTreeviewEventParser,
  TreeviewI18n,
  TreeviewI18nDefault,
  TreeviewHelper,
} from "ngx-treeview";
import { isNil, reverse } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TooltipPosition } from "@angular/material/tooltip";

@Component({
  selector: "app-add-wanderconfig",
  templateUrl: "./add-wanderconfig.component.html",
  providers: [
    WanderService,
    {
      provide: TreeviewEventParser,
      useClass: OrderDownlineTreeviewEventParser,
    },
    { provide: TreeviewConfig, useClass: WanderService },
    {
      provide: TreeviewI18n,
      useValue: Object.assign(new TreeviewI18nDefault(), {
        getFilterPlaceholder(): string {
          return "Search";
        },
      }),
    },
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-wanderconfig.component.scss"],
})
export class AddWanderconfigComponent implements OnInit, OnDestroy {
  public wanderConfigForm: UntypedFormGroup;
  Residentdetails: Resident[];
  Residentgroupdetails: ResGroup[];
  Staffgroupdetails: ResidentGroup[];
  staffdetails: Staff[];
  Campusdetails: getCampusdetails[];

  checkrestype = 0;
  checkstafftype = 0;
  selresident;
  selresidentgroup;

  Residentindividual = [];
  Residentgroups = [] as any[];

  checkresidentid = 0;
  checkresdata;

  arrallCampusid = [] as any[];
  campusidList = [];
  items: TreeviewItem[];
  sel_CampusId = [] as any[];
  sel_BuildingId = [] as any[];
  sel_FloorId = [] as any[];
  sel_RoomId = [] as any[];
  WrongRoom_flag;

  editresloading = true;
  residentRoomSelect = "";
  egressRoomSelect = "";
  checkWrongRoom = true;

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 160,
  });
  wanderbool = true;
  private _onDestroy: Subject<void> = new Subject<void>();
  matTooltipAdvSettings =
    "Select/De-Select Zone(s)/Space(s). Selecting a Zone/Space indicates an unsafe zone/space for this resident.";
  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = new UntypedFormControl(this.positionOptions[3]);
  authkey;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public thisDialogRef: MatDialogRef<AddWanderconfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    private wanderService: WanderService
  ) {}

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.checkrestype = 0;
    this.checkstafftype = 0;
    this.getResidentUnsafeZones();
    this.wanderConfigForm = new UntypedFormGroup({
      residenttype: new UntypedFormControl(""),
      resident: new UntypedFormControl(""),
      stafftype: new UntypedFormControl(""),
      staff: new UntypedFormControl(""),
    });
  }
  selectresident(SelectedResidentId) {
    let ResidentInfo = [] as any[];
    ResidentInfo = this.Residentdetails.filter(function (e1) {
      return e1.ResidentID == SelectedResidentId;
    });

    this.WrongRoom_flag = 0;
    if (
      ResidentInfo[0].WrongRoom == "true" &&
      ResidentInfo[0].AllEgress == "true"
    ) {
      this.WrongRoom_flag = 3;
    } else if (ResidentInfo[0].AllEgress == "true") {
      this.WrongRoom_flag = 2;
    } else if (ResidentInfo[0].WrongRoom == "true") {
      this.WrongRoom_flag = 1;
    }
    this.tickanduntickSpaces();
  }

  tickanduntickSpaces() {
    if (this.WrongRoom_flag == 0) {
      this.onSelectRoomdetails(" ", this.checkWrongRoom);
    } else if (this.WrongRoom_flag == 1) {
      this.residentRoomSelect = enumResEgress_Type.ResidentRoom;
      this.onSelectRoomdetails(this.residentRoomSelect, this.checkWrongRoom);
    } else if (this.WrongRoom_flag == 2) {
      this.residentRoomSelect = enumResEgress_Type.EgressRoom;
      this.onSelectRoomdetails(this.residentRoomSelect, this.checkWrongRoom);
    } else if (this.WrongRoom_flag == 3) {
      this.onSelectBothRoomdetails(
        enumResEgress_Type.ResidentRoom,
        enumResEgress_Type.EgressRoom,
        this.checkWrongRoom
      );
    }
  }

  onSelectRoomdetails(checkselectitem: string, checkedvalue) {
    this.editresloading = true;
    const campusid_List = localStorage.getItem("RoamingRomeList");
    if (campusid_List != null) this.campusidList = JSON.parse(campusid_List);
    for (let ncampus = 0; ncampus < this.arrallCampusid.length; ncampus++) {
      for (
        let nbuild = 0;
        nbuild < this.arrallCampusid[ncampus].children.length;
        nbuild++
      ) {
        for (
          let nfloor = 0;
          nfloor <
          this.arrallCampusid[ncampus].children[nbuild].children.length;
          nfloor++
        ) {
          for (
            let nroom = 0;
            nroom <
            this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
              .children.length;
            nroom++
          ) {
            if (
              this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                .children[nroom].Category != "L"
            )
              TreeviewHelper.findItemInList(
                this.arrallCampusid,
                this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                  .children[nroom].value
              ).disabled = false;
            if (
              this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                .children[nroom].Category == checkselectitem
            ) {
              if (checkedvalue == true) {
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).checked = true;
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).disabled = true;
              } else if (checkedvalue == false) {
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).checked = false;
              }
            } else {
              TreeviewHelper.findItemInList(
                this.arrallCampusid,
                this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                  .children[nroom].value
              ).checked = false;
            }
          }
        }
      }
    }
    this.items = this.getProducts();
  }

  onSelectBothRoomdetails(checkselectitem1, checkselectitem2, checkedvalue) {
    this.editresloading = true;
    const campusid_List = localStorage.getItem("RoamingRomeList");
    if (campusid_List != null) this.campusidList = JSON.parse(campusid_List);
    for (let ncampus = 0; ncampus < this.arrallCampusid.length; ncampus++) {
      for (
        let nbuild = 0;
        nbuild < this.arrallCampusid[ncampus].children.length;
        nbuild++
      ) {
        for (
          let nfloor = 0;
          nfloor <
          this.arrallCampusid[ncampus].children[nbuild].children.length;
          nfloor++
        ) {
          for (
            let nroom = 0;
            nroom <
            this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
              .children.length;
            nroom++
          ) {
            if (
              this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                .children[nroom].Category == checkselectitem1 ||
              this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                .children[nroom].Category == checkselectitem2
            ) {
              if (
                this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                  .children[nroom].Category != "L"
              )
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).disabled = false;
              if (checkedvalue == true) {
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).checked = true;
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).disabled = true;
              } else if (checkedvalue == false) {
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).checked = false;
              }
            } else {
              TreeviewHelper.findItemInList(
                this.arrallCampusid,
                this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                  .children[nroom].value
              ).checked = false;
            }
          }
        }
      }
    }
    this.items = this.getProducts();
  }

  onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
    this.sel_CampusId = [];
    this.sel_BuildingId = [];
    this.sel_FloorId = [];
    this.sel_RoomId = [];

    downlineItems.forEach((downlineItem) => {
      const item = downlineItem.item;
      const value = item.value;
      const texts = [] as any[];
      let parent = downlineItem.parent;
      while (!isNil(parent)) {
        texts.push(parent.item.value); //texts.push(parent.item.text+":"+parent.item.value);
        parent = parent.parent;
      }
      const reverseTexts = reverse(texts);
      const row = `${reverseTexts.join(":")}:${value}`;
      const arr_row = row.split(":");

      if (arr_row.length > 0 && !this.sel_CampusId.includes(arr_row[0]))
        this.sel_CampusId[this.sel_CampusId.length] = arr_row[0];

      if (arr_row.length > 1 && !this.sel_BuildingId.includes(arr_row[1]))
        this.sel_BuildingId[this.sel_BuildingId.length] = arr_row[1];

      if (arr_row.length > 2 && !this.sel_FloorId.includes(arr_row[2]))
        this.sel_FloorId[this.sel_FloorId.length] = arr_row[2];

      if (
        arr_row.length > 3 &&
        !this.sel_RoomId.includes(arr_row[3]) &&
        item.disabled == false
      )
        this.sel_RoomId[this.sel_RoomId.length] = arr_row[3];
    });
  }

  getProducts(): TreeviewItem[] {
    const Tree_arrallCampusid = [] as any[];
    try {
      for (const key in this.arrallCampusid) {
        const data = this.arrallCampusid[key];
        if (this.arrallCampusid[key].children.length > 0) {
          const TreeData = new TreeviewItem(data);
          Tree_arrallCampusid[Tree_arrallCampusid.length] = TreeData;
        }
      }
    } catch (err) {
      throw new Error(
        "add-wanderconfig.component.ts" + " - getProducts() - " + err.message
      );
    }
    return Tree_arrallCampusid;
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  onResidentChange(resvalue) {
    if (resvalue == 0) {
      this.WrongRoom_flag = 0;
      this.tickanduntickSpaces();
      this.checkrestype = resvalue;
      this.selresident = "Select Resident";
      this.selresidentgroup = "";
      this.getResidentData();
    } else if (resvalue == 1) {
      this.WrongRoom_flag = 0;
      this.tickanduntickSpaces();
      this.checkrestype = resvalue;
      this.selresidentgroup = "Select Group";
      this.selresident = "";
      this.getResidentGroup();
    }
  }

  onChange(mrChange) {
    this.onResidentChange(mrChange.value);
  }

  getResidentData(): void {
    try {
      const residentdata: getResidentRequest = {
        authKey: this.authkey,
        residentType: enumgetResidentData.ActiveData,
        isChronoLogical: enumChronoLogical.alphanumeric,
      };

      this.apiService
        .getResidentData(residentdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Residents: any[]) => {
          if (Residents["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Residentdetails = Residents["apiReturn"];
          this.Residentindividual = [];
        });
    } catch (err) {
      throw new Error(
        "add-wanderconfig.component.ts" +
          " - getResidentData() - " +
          err.message
      );
    }
  }

  getResidentGroup(): void {
    try {
      const grouptype: getGroupData = {
        authKey: this.authkey,
      };
      this.apiService
        .getResidentGroup(grouptype)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ResidentGroups: any[]) => {
          if (ResidentGroups["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Residentgroupdetails = ResidentGroups["apiReturn"];
          this.Residentgroups = [];
          for (let i = 0; i < this.Residentgroupdetails.length; i++) {
            if (this.Residentgroupdetails[i].ResidentName) {
              this.Residentgroups.push(this.Residentgroupdetails[i]);
            }
          }
        });
    } catch (err) {
      throw new Error(
        "add-wanderconfig.component.ts" +
          " - getResidentGroup() - " +
          err.message
      );
    }
  }

  getResidentUnsafeZones() {
    try {
      const safezone: getSafeZoneID = {
        authKey: this.authkey,
        safeZoneId: 0,
      };
      this.wanderService
        .getResidentUnsafeZones(safezone)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails;
          if (CampusDetails != null && CampusDetails != undefined) {
            this.arrallCampusid = CampusDetails["Campusdetails"];
            this.items = this.getProducts();
          }
        });
    } catch (err) {
      throw new Error(
        "add-wanderconfig.component.ts" +
          " - getResidentUnsafeZones() - " +
          err.message
      );
    }
  }

  public createWanderConfig = (wanderconfigFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      this.addSafeZone(wanderconfigFormValue);
    } catch (err) {
      throw new Error(
        "add-wanderconfig.component.ts" +
          " - createWanderConfig() - " +
          err.message
      );
    }
  };

  private addSafeZone = (wanderConfigFormValue) => {
    try {
      // let campusid;
      let buildingid;
      // let floorid;
      let roomid;

      if (wanderConfigFormValue.residenttype == "") {
        generateAlertMessage(this.dialog, "Please select the Resident Type");
        return false;
      }

      if (wanderConfigFormValue.resident == "Select Resident") {
        generateAlertMessage(this.dialog, "Please select the Resident");
        return false;
      } else if (wanderConfigFormValue.resident == "Select Group") {
        generateAlertMessage(this.dialog, "Please select the Resident Group");
        return false;
      }
      if (this.sel_RoomId.length == 0) {
        generateAlertMessage(this.dialog, "Please select the Location");
        return false;
      }

      if (this.wanderbool) {
        this.wanderbool = false;

        // campusid = this.sel_CampusId;
        buildingid = this.sel_BuildingId;
        // floorid = this.sel_FloorId;
        roomid = this.sel_RoomId;
        const buildingIdInf = buildingid;
        for (let nidx = 0; nidx < buildingIdInf.length; nidx++) {
          if (buildingIdInf[nidx].toString().split("_").length > 1) {
            roomid[roomid.length] = buildingIdInf[nidx]
              .toString()
              .split("_")[0];
            buildingid.slice(nidx, 0);
          }
        }
        const wanderConfig: addWanderConfig = {
          authKey: this.authkey,
          residentType: parseInt(wanderConfigFormValue.residenttype),
          residentId: parseInt(wanderConfigFormValue.resident),
          roomId: roomid.toString(),
        };
        const residentType = wanderConfigFormValue.residenttype;
        this.wanderService
          .addSafeZone(wanderConfig)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: WanterforCreationResponse[]) => {
            if (apiresponse["apiReturn"] == "Success") {
              this.apiService.reloadlive();
              generateAlertMessage(
                this.dialog,
                "Roaming Rule added successfully."
              );
            } else if (apiresponse["apiReturn"] == "Fail") {
              this.wanderbool = true;
              generateAlertMessage(this.dialog, "Roaming Rule added failed.");
            } else if (apiresponse["apiReturn"] == "Resident already have Roaming Rule") {
              this.wanderbool = true;

              if (residentType == 0) {
                generateAlertMessage(
                  this.dialog,
                  "Already this resident have Roaming Rule."
                );
                return false;
              } else if (residentType == 1) {
                generateAlertMessage(
                  this.dialog,
                  "Already this resident group have Roaming Rule."
                );
                return false;
              }
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            this.thisDialogRef.close("Confirm");
          });
      }
    } catch (err) {
      throw new Error(
        "add-wanderconfig.component.ts" + " - addSafeZone() - " + err.message
      );
    }
  };

  ///////////END///////////

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
