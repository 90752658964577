import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { StaffService } from "../../staff.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  StaffGroupForCreation,
  StaffGroupForCreationResponse,
} from "../../staff";
import { ApiService } from "../../../../api.service";
import { GroupNamefoundResponse, checkGroupName } from "../../../../api";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {
  appsettings
} from "../../../../app-settings";

@Component({
  selector: "app-add-staff-group",
  templateUrl: "./add-staff-group.component.html",
  providers: [StaffService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-staff-group.component.scss"],
})
export class AddStaffGroupComponent implements OnInit, OnDestroy {
  public staffGroupCreationForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 3;
  staffgroupbool = true;
  private _onDestroy: Subject<void> = new Subject<void>();
  grouprowHeight = "67px";
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddStaffGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Inject(RollbarService) private rollbar: Rollbar,
    private staffService: StaffService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
      
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";

    this.onResize();
    this.staffGroupCreationForm = new UntypedFormGroup({
      groupname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      description: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(150),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
  }
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.staffGroupCreationForm.controls).forEach((field) => {
        // {1}
        const control = this.staffGroupCreationForm.get(field); // {2}
        const staffForm_Id = document.getElementById(event.target.id);
        if (staffForm_Id != null) {
          if (field == staffForm_Id.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff-group.component.ts" + " - handleInput() - " + err.message
      );
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      this.grouprowHeight = "72.5px";
    }
  }
  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  public hasError = (controlName: string, errorName: string) => {
    return this.staffGroupCreationForm.controls[controlName].hasError(
      errorName
    );
  };
  public createStaffGroup = (StaffgroupFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.staffGroupCreationForm.valid && this.staffgroupbool) {
        this.staffgroupbool = false;
        const groupnamefoundItem: checkGroupName = {
          authKey: this.authkey,
          groupType: 2,
          newGroupName: StaffgroupFormValue.groupname.toString(),
          groupId: 0,
        };
        this.apiService
          .checkGroupName(groupnamefoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: GroupNamefoundResponse[]) => {
            if (apiresponse.toString() == "not found") {
              this.addStaffGroup(StaffgroupFormValue);
            } else {
              this.staffgroupbool = true;
              generateAlertMessage(
                this.dialog,
                "Staff Group name '" + apiresponse[0].name + "' already exists"
              );
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "add-staff-group.component.ts" +
          " - createStaffGroup() - " +
          err.message
      );
    }
  };

  // Add Staff Group
  private addStaffGroup = (StaffgroupFormValue) => {
    try {
      const addStaffgroup: StaffGroupForCreation = {
        authKey: this.authkey,
        groupname: StaffgroupFormValue.groupname.replace(/\s+/g, " ").trim(),
        description: StaffgroupFormValue.description
          .replace(/\s+/g, " ")
          .trim(),
      };
      this.staffService
        .addStaffGroup(addStaffgroup)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: StaffGroupForCreationResponse[]) => {
          if (apiresponse != null && apiresponse != undefined) {
            if (apiresponse["apiReturn"] == "Success") {
              this.staffgroupbool = false;
              generateAlertMessage(
                this.dialog,
                "Staff group added successfully."
              );
            } else if (apiresponse["apiReturn"] == "Fail") {
              this.staffgroupbool = true;
              generateAlertMessage(this.dialog, "Staff group added failed.");
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
          } else {
            this.staffgroupbool = true;
            generateAlertMessage(this.dialog, "Staff group added failed.");
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "add-staff-group.component.ts" + " - addStaffGroup() - " + err.message
      );
    }
  };

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
