<div id="clsAccess">
    <div class="access-title">
      <h3 class="accessHeader" style="margin: 0 0 10px 11px;">Access Code
      <span><img class="clscloseimage" src="assets/images/close.png" (click)="onCloseAccesscode()"></span></h3>
      <mat-divider ></mat-divider>
    </div>
    <div class="clsaccesscodecontent">
      <div><span>Must enter access code before zooming in on individual's location.</span></div>
      <span id="accessmsg" class="clsaccesscodeMsg">{{lblaccessMsg}}</span>
      <div fxLayoutAlign="center center" id="actionDiv" class="enable">
        <mat-form-field appearance="outline" floatLabel="auto">
          <input  matInput id="txtaccessCode" class="clstxt" placeholder="Access Code" [(ngModel)]="password" type="password" name="password"  autocomplete="new-password" (input)="onKeyPress()"  (keyup.enter)="onEnterClicked()" required [attr.disabled]="txtaccessCodedisabled">  
        </mat-form-field>
      </div>
      <span id="accessmsgcnt" class="clsaccesscode">{{lblattempt}}</span>
      <!-- show Delete/cancel button -->
      <mat-dialog-actions class="clsaccessbuttoncontrol">            
        <button class="clsaccbutton_Enter mat-raised-button disable" id='btnaccessenter' mat-raised-button (click)="onEnterClicked()">Enter</button>&nbsp;
        <button type="button" class="clsaccbutton_Cancel" mat-raised-button (click)="onCloseAccesscode()">Cancel</button>
      </mat-dialog-actions>
</div>
