import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { alert } from "../../../api";
import {
  enumAlertType,
  enumAlertSubType,
  enumAlertAckStatus,
  alert_deviceType,
  Alert_Event_Type,
} from "../../../app-settings";
import { AlertSharedService } from "../../../_services/index";
import {
  SharedData,
  SharedFloorAlertData,
  SharedAlertData,
  SharedCheckAlertData,
  ShareInfraopenMsg,
  getCampus,
} from "../../../api";
import {
  alert_raisedon,
  enumAlertCheckCancel,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../../app-settings";
import { MainLayoutService } from "../../main-layout.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import {
  redirecttohome,
  set_SharedDetails,
  set_SharedFloorDetails,
  set_alertChangeorClick,
  SessionCheck,
  getFeatureNeedtoShow,
} from "../../../app-general";
import { ShareRightSideNav } from "../../../api";
import { ApiService } from "../../../api.service";
import { TooltipPosition } from "@angular/material/tooltip";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  closealertpushSocketConForUpdates,
  CloseLocationChangeSocket,
} from "../../../app.component";
import { SidenavComponent } from "../../sidenav/sidenav.component";
@Component({
  selector: "app-infrastructure-alerts",
  templateUrl: "./infrastructure-alerts.component.html",
  styleUrls: ["./infrastructure-alerts.component.scss"],
})
export class InfrastructureAlertsComponent implements OnInit, OnDestroy {
  SharedDetails: SharedData;
  SharedFloorAlertDetails: SharedFloorAlertData;
  SharedAlertDetails: SharedAlertData[];
  public Alert_raisedon = alert_raisedon;
  alertinfo: alert[];
  EscLevelImgUrl = "assets/images/";
  Infracount = 0;
  Alertcount = 0;
  ackalertstatus = 0;
  public EnumAlertType = enumAlertType;
  public EnumAlertSubType = enumAlertSubType;
  public EnumAlertAckStatus = enumAlertAckStatus;
  public EnumDeviceType = alert_deviceType;
  public EnumAlertEventType = Alert_Event_Type;
  CheckAlertData: SharedCheckAlertData;
  ShareInfraopenDetails: ShareInfraopenMsg;
  Isinfra = 0;
  getalertnotifytime = [] as any[];
  alerttimer;
  notifiedalerttime;
  getalertTimedata;
  alertminutes;
  alertseconds;
  alertflag = 0;
  alerti = 0;
  ShareSideNavDetails: ShareRightSideNav;
  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = new UntypedFormControl(this.positionOptions[3]);
  interval;
  Chk_CancelKey;
  ackCancel = enumAlertCheckCancel.Disable;
  SharedLocationDetails: any;
  checklivestaffdata = 0;
  checkliveresidentdata = 0;
  bLocationAlertinfra = false;
  bLocationAlert = false;
  @ViewChild("leftMenu") public leftmenu: MatSidenav;

  public static isinfraClosed = true;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,    
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    public mainLayoutService: MainLayoutService,
    public dialog: MatDialog        
  ) {
    try {
      this.mainLayoutService.toggleinfrastructureLeft.subscribe((e1) => {
        console.log(e1);
        this.leftMenu.toggle();
        if (this.leftmenu._animationState == "open") {
          InfrastructureAlertsComponent.isinfraClosed = false;
        } else if (this.leftmenu._animationState == "void") {
          InfrastructureAlertsComponent.isinfraClosed = true;
        }
        this.alertSharedService.changedSideNav(this.ShareSideNavDetails);
        console.log(
          "alertinfo : " + JSON.stringify(this.alertinfo),
          this.alertinfo
        );
        if (this.alertinfo) {
          //this.alertSharedService.changeSharedAlert(this.alertinfo);
          this.alertSharedService
            .currentSharedCheckAlert()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((SharedCheckAlertDetails: SharedCheckAlertData) => {
              const CheckAlertDataChanged = SharedCheckAlertDetails;
              let CheckAlertData;
              let Chk_IsAlertClicked = false;
              if (CheckAlertDataChanged != undefined) {
                if (
                  CheckAlertDataChanged.IsAlertClicked != undefined ||
                  CheckAlertDataChanged.IsAlertClicked != 0
                ) {
                  Chk_IsAlertClicked = true;
                }
              }
              if (Chk_IsAlertClicked == true) {
                CheckAlertData = {
                  IsAlertChanged: 1,
                  IsAlertClicked: CheckAlertDataChanged.IsAlertClicked,
                };
              } else {
                CheckAlertData = {
                  IsAlertChanged: 1,
                  IsAlertClicked: 0,
                };
              }
              this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
            });
        }
        this.getAlertData(enumAlertCheckCancel.Disable);
      });
    } catch (err) {
      throw new Error(
        "infrastructure-alerts.component.ts" +
          " - constructor() - " +
          err.message
      );
    }
  }

  @ViewChild("leftMenu") public leftMenu: MatSidenav;

  ngOnInit() {
    let ChkAlert = true;

    this.alertSharedService
      .currentSharedAlert()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((SharedAlertDetails: SharedAlertData[]) => {
        this.alertinfo = SharedAlertDetails;
        ChkAlert = true;
        if (ChkAlert && this.alertinfo) {
          ChkAlert = false;
          this.getAlertData(enumAlertCheckCancel.Disable);
        }
      });
    this.alertSharedService
      .currentsharedInfraAlert()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((ShareInfraopenDetails: any) => {
        this.ShareInfraopenDetails = ShareInfraopenDetails;
        if (this.ShareInfraopenDetails != undefined) {
          this.Isinfra = this.ShareInfraopenDetails.IsfraOpen;
        }
      });

    this.bLocationAlert = true; //default move into floor

    this.alertSharedService
      .currentShared()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((SharedDetails: SharedData) => {
        this.SharedDetails = SharedDetails;
      });
    this.alertSharedService
      .currentSharedLocation()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((SharedLocationDetails: any) => {
        this.SharedLocationDetails = SharedLocationDetails;
        if (this.SharedLocationDetails != undefined) {
          if (
            getFeatureNeedtoShow(
              enumUIFeatureView.Location,
              enumUIAction.Location_RTLS_Staff
            ) == enumUIView.Show
          ) {
            this.checklivestaffdata = 1;
          } else {
            this.checklivestaffdata = 0;
          }
          if (
            getFeatureNeedtoShow(
              enumUIFeatureView.Location,
              enumUIAction.Location_RTLS_Resident
            ) == enumUIView.Show
          ) {
            this.checkliveresidentdata = 1;
          } else {
            this.checkliveresidentdata = 0;
          }
        }
      });
  }

  getAlertData(chkCancel): void {
    try {
      let infracount = 0;
      let alertcount = 0;
      let cancelTime;
      console.log(chkCancel);
       if (this.alertinfo != undefined) {
        if (this.alerttimer > 0) {
          clearInterval(this.alerttimer);
          this.alerttimer = 0;
          this.alerti = 0;
        }

        this.setTimerforAlert(this.alertinfo);
        this.alertinfo.filter(function (e1) {
          if (
            e1.AckStatus != enumAlertAckStatus.Cancel &&
            e1.AckStatus != enumAlertAckStatus.Complete
          ) {
            //cancel Or Complete alert not counted on infracount
            if (
              e1.AlertType == enumAlertType.Infrastructure ||
              e1.AlertType == enumAlertType.systemAlarm ||
              e1.SubTypeId == enumAlertSubType.Environmental
            )
              infracount++;
            else alertcount++;
          }
        });
        this.getalertnotifytime = [];
        for (const key in this.alertinfo) {
          if (
            this.alertinfo[parseInt(key)].AckStatus ==
            enumAlertAckStatus.Notified
          ) {
            this.getalertTimedata = this.getnotifiedtimebydate(
              this.alertinfo[parseInt(key)].AlertTimeTile,
              0
            );
            this.getalertnotifytime[this.alertinfo[parseInt(key)].AlertId] =
              this.getalertTimedata;
          } else
            this.getalertnotifytime[this.alertinfo[parseInt(key)].AlertId] =
              this.alertinfo[parseInt(key)].AlertTime;

          this.Infracount = infracount;
          this.Alertcount = alertcount;
          let chkInfraalert = false;
          if (
            this.alertinfo[parseInt(key)].AlertType ==
              enumAlertType.Infrastructure ||
            this.alertinfo[parseInt(key)].AlertType ==
              enumAlertType.systemAlarm ||
            this.alertinfo[parseInt(key)].SubTypeId ==
              enumAlertSubType.Environmental
          ) {
            chkInfraalert = true;
          }
          if (
            cancelTime == undefined &&
            chkInfraalert == true &&
            (this.alertinfo[parseInt(key)].AckStatus ==
              enumAlertAckStatus.Complete ||
              this.alertinfo[parseInt(key)].AckStatus ==
                enumAlertAckStatus.Cancel)
          ) {
            cancelTime = this.alertinfo[parseInt(key)].AckSeconds;
            
          } else if (
            chkInfraalert == true &&
            (this.alertinfo[parseInt(key)].AckStatus ==
              enumAlertAckStatus.Complete ||
              this.alertinfo[parseInt(key)].AckStatus ==
                enumAlertAckStatus.Cancel)
          ) {
            if (cancelTime > this.alertinfo[parseInt(key)].AckSeconds) {
              cancelTime = this.alertinfo[parseInt(key)].AckSeconds;
             
            }
          }
        }
      }
    } catch (err) {
      throw new Error(
        "infrastructure-alerts.component.ts" +
          " - getAlertData() - " +
          err.message
      );
    }
  }

  public toggleInfrastructureMenu() {
    try {
      if (this.Isinfra == 1) {
        if (!SessionCheck(this.router)) {
          return;
        }
        const _ShareInfraopenDetails = {
          IsfraOpen: 0,
        };
        this.alertSharedService.changedInfraAlert(_ShareInfraopenDetails);
        const imgLogo = document.getElementById("imglogo");
        if(imgLogo != null)
          imgLogo.style.cursor = "pointer";
        this.mainLayoutService.toggleinfrastructureLeft.emit("close");

        const screenWidth = window.innerWidth;
        if (screenWidth <= 500) {
          const elementAlert = document.getElementsByClassName(
            "card-container-left"
          );
          const alertstyle = getComputedStyle(elementAlert[0]);
          if (alertstyle.display == "none") {
            elementAlert[0]["style"].display = "block";
            elementAlert[0]["style"].width = "100%";
          }

          const elementhome = document.getElementsByClassName(
            "card-container-right"
          );
          const style = getComputedStyle(elementhome[0]);
          if (style.display == "block") {
            elementhome[0]["style"].display = "none";
            elementhome[0]["style"].width = "0%";
          }
        }
      }
    } catch (err) {
      throw new Error(
        "infrastructure-alerts.component.ts" +
          " - toggleInfrastructureMenu() - " +
          err.message
      );
    }
  }

  onClickAlert(AlertDetails, IsFloor) {
    try {
      //need to check home page rights then only move to home page.
      if (
        getFeatureNeedtoShow(enumUIFeatureView.Home, enumUIAction.Home_View) ==
        enumUIView.Show
      ) {
        if (!SessionCheck(this.router)) {
          return;
        }
        this.CloseRightSidenav();
        if (AlertDetails.DeviceType == alert_deviceType.Pager_Communication) {
          this.AlertClicked(AlertDetails, IsFloor);
        } else {
          this.AlertClicked(AlertDetails, IsFloor);
        }
      }
    } catch (err) {
      throw new Error(
        "infrastructure-alerts.component.ts" +
          " - onClickAlert() - " +
          err.message
      );
    }
  }

  CloseRightSidenav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
  }

  AlertClicked(AlertDetails, IsFloor) {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      let ChkFloorId = 0;
      if (IsFloor == 1) {
        if (AlertDetails.FloorId != null) ChkFloorId = AlertDetails.FloorId;
      }
      if (IsFloor != 1) {
        if (
          AlertDetails.FloorId != null &&
          AlertDetails.Building_SVGID.length == 0 &&
          AlertDetails.FloorId == this.SharedDetails.SharedFloorId
        )
          ChkFloorId = AlertDetails.FloorId;
        this.alertSharedService.changesharedFloorAlert(undefined);
      }

      if (AlertDetails.CampusId != null) {
        if (
          this.router.url == "/main" &&
          this.SharedDetails != undefined &&
          this.SharedDetails.SharedCampusId ==
            parseInt(AlertDetails.CampusId) &&
          this.SharedDetails.SharedFloorId == 0 &&
          ChkFloorId == 0
        ) {
          if (AlertDetails.Building_SVGID.length > 0) {
            this.SharedDetails = set_SharedDetails(
              parseInt(AlertDetails.CampusId),
              parseInt(AlertDetails.BuildId),
              ChkFloorId,
              AlertDetails.SubZoneId,
              AlertDetails.AlertId,
              this.Alert_raisedon.leftside_alertselect,
              "",
              this.alertSharedService
            );
            this.CheckAlertData = set_alertChangeorClick(
              0,
              1,
              this.alertSharedService
            );
          } else {
            set_SharedDetails(
              parseInt(AlertDetails.CampusId),
              parseInt(AlertDetails.BuildId),
              AlertDetails.FloorId,
              AlertDetails.SubZoneId,
              AlertDetails.AlertId,
              this.Alert_raisedon.leftside_alertselect,
              "",
              this.alertSharedService
            );
            set_SharedFloorDetails(
              AlertDetails,
              this.Alert_raisedon.leftside_alertselect,
              this.alertSharedService
            );
            redirecttohome(this.router);
          }
        } else {
          if (AlertDetails.Building_SVGID.length > 0 && ChkFloorId == 0) {
            set_SharedDetails(
              parseInt(AlertDetails.CampusId),
              parseInt(AlertDetails.BuildId),
              ChkFloorId,
              AlertDetails.SubZoneId,
              AlertDetails.AlertId,
              this.Alert_raisedon.leftside_alertselect,
              "",
              this.alertSharedService
            );
            redirecttohome(this.router);
          } else {
            if (
              this.router.url == "/main" &&
              this.SharedDetails != undefined &&
              ChkFloorId > 0 &&
              AlertDetails.FloorId == this.SharedDetails.SharedFloorId
            ) {
              if (AlertDetails.AlertId != this.SharedDetails.SharedAlertId) {
                set_SharedDetails(
                  parseInt(AlertDetails.CampusId),
                  parseInt(AlertDetails.BuildId),
                  AlertDetails.FloorId,
                  AlertDetails.SubZoneId,
                  AlertDetails.AlertId,
                  this.Alert_raisedon.leftside_alertselect,
                  "",
                  this.alertSharedService
                );
                set_SharedFloorDetails(
                  AlertDetails,
                  this.Alert_raisedon.leftside_alertselect,
                  this.alertSharedService
                );
                set_alertChangeorClick(
                  0,
                  1,
                  this.alertSharedService
                );
              }
            } else {
              set_SharedDetails(
                parseInt(AlertDetails.CampusId),
                parseInt(AlertDetails.BuildId),
                AlertDetails.FloorId,
                AlertDetails.SubZoneId,
                AlertDetails.AlertId,
                this.Alert_raisedon.leftside_alertselect,
                "",
                this.alertSharedService
              );
              set_SharedFloorDetails(
                AlertDetails,
                this.Alert_raisedon.leftside_alertselect,
                this.alertSharedService
              );
              redirecttohome(this.router);
            }
          }
        }
      } else {
        const map_sharedService = this.alertSharedService;
        
        
        const map_Alert_raisedon = this.Alert_raisedon;
        const map_router = this.router;
        if (
          this.router.url != "/main" ||
          this.SharedDetails == undefined ||
          this.SharedDetails.SharedFloorId != 0
        ) {
          const authkey = localStorage.getItem("Authkey") ?? "";
          if (this.SharedDetails == undefined) {
            const reqCampusInfo: getCampus = {
              authKey: authkey,
            };
            this.apiService
              .getCampusDetails(reqCampusInfo)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((CampusDetails: any[]) => {
                const CampusId = CampusDetails["apiReturn"][0].CampusId;
                set_SharedDetails(
                  CampusId,
                  0,
                  0,
                  0,
                  AlertDetails.AlertId,
                  map_Alert_raisedon.leftside_alertselect,
                  "",
                  map_sharedService
                );
                redirecttohome(map_router);
              });
          } else {
            set_SharedDetails(
              this.SharedDetails.SharedCampusId,
              0,
              0,
              0,
              AlertDetails.AlertId,
              this.Alert_raisedon.leftside_alertselect,
              "",
              this.alertSharedService
            );
            this.alertSharedService.changesharedFloorAlert(undefined);
            redirecttohome(this.router);
          }
        } else {
          set_SharedDetails(
            this.SharedDetails.SharedCampusId,
            0,
            0,
            0,
            AlertDetails.AlertId,
            this.Alert_raisedon.leftside_alertselect,
            "",
            this.alertSharedService
          );
          set_alertChangeorClick(
            0,
            1,
            this.alertSharedService
          );
        }
      }
    } catch (err) {
      throw new Error(
        "infrastructure-alerts.component.ts" +
          " - AlertClicked() - " +
          err.message
      );
    }
  }

  setTimerforAlert(AlertData) {
    try {
      if (this.alerttimer == undefined) this.alertflag = 1;
      else {
        this.alerti = 0;
        this.alertflag = 0;
      }

      this.alerttimer = setInterval(
        () => this.setIntervalNotifyTime(AlertData),
        1000 * 60
      ); //1000 * 60
    } catch (err) {
      throw new Error(
        "infrastructure-alerts.component.ts" +
          " - setTimerforAlert() - " +
          err.message
      );
    }
  }

  setIntervalNotifyTime(alertdata) {
    try {
      this.getalertnotifytime = [];

      if (this.alertflag == 0) {
        this.alerti += 1;
      }
      if (this.alertinfo != undefined) {
        for (const key in alertdata) {
          this.notifiedalerttime = alertdata[key].AlertTimeTile;
          if (
            this.alertinfo[parseInt(key)].AckStatus != undefined &&
            this.alertinfo[parseInt(key)].AckStatus ==
              enumAlertAckStatus.Notified
          )
            this.getalertTimedata = this.getnotifiedtimebydate(
              this.notifiedalerttime,
              1
            );
          else this.getalertTimedata = this.alertinfo[parseInt(key)].AlertTime;
          this.getalertnotifytime[alertdata[key].AlertId] =
            this.getalertTimedata;
        }
      }
    } catch (err) {
      throw new Error(
        "infrastructure-alerts.component.ts" +
          " - setIntervalNotifyTime() - " +
          err.message
      );
    }
  }

  getnotifiedtimebydate(notifydate, type) {
    try {
      let alerttime = "";
      let _alertMinutes = 0;

      if (notifydate.day == undefined) {
        alerttime = " ";
        return alerttime;
      }

      if (type == 1 && this.alertflag == 0) {
        _alertMinutes = notifydate.minute + this.alerti;
        this.alertseconds = notifydate.second;
      } else _alertMinutes = notifydate.minute;

      if (notifydate.day == 0 && notifydate.hour == 0) {
        if (notifydate.minute == 0 && this.alerti == 0) {
          alerttime = notifydate.second + " sec ago";
        } else if (_alertMinutes >= 60 && notifydate.hour == 0) {
          alerttime = notifydate.hour + 1 + " hrs ago";
        } else alerttime = _alertMinutes + " mins ago";
      } else {
        if (notifydate.day == 0) alerttime = notifydate.hour + " hrs ago";
        else alerttime = notifydate.day + " days ago";
      }
      this.alertseconds += 1;
      return alerttime;
    } catch (err) {
      throw new Error(
        "infrastructure-alerts.component.ts" +
          " - getnotifiedtimebydate() - " +
          err.message
      );
    }
  }

  trackById(index: number, item: alert) {
    return item.AlertId;
  }

  Logout(): void {
    const _ShareddialogcloseDetails = {
      SharedreturncloseVal: 0,
    };
    this.alertSharedService.changeclosedialog(_ShareddialogcloseDetails);
    localStorage.clear();
    closealertpushSocketConForUpdates();
    CloseLocationChangeSocket();
    this.router.navigate(["/login"]);
  }

  ngOnDestroy() {
    clearInterval(this.alerttimer);
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
