import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  appsettings,
  enumFileNamelength,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../../app-settings";
import {
  ResidentForCreationResponse,
  ResidentMedHistory,
  UpdateResidentMedHistory,
  ShowEMRFileReport,
  ResidentByID,
  getResidentbyid,
  ReportById,
  ReportbyName,
  checkEmrFileData,
} from "../resident";
import { ResidentService } from "../resident.service";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { HostListener } from "@angular/core";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
  getFeatureNeedtoShow,
} from "../../../app-general";
import { Router } from "@angular/router";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { MatTableDataSource } from "@angular/material/table";

const URL = appsettings.URLinfo + "uploadEMRFile";

@Component({
  selector: "app-medical-history",
  templateUrl: "./medical-history.component.html",
  providers: [ResidentService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./medical-history.component.scss"],
})
export class MedicalHistoryComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 3;
  textcolumns = 2;
  uploadfilename = "";
  resmedfileUrl = "";
  medEMRfilename = "";
  addEMRFileName = "";
  keycode = 13;
  meddate;
  dataSource;

  dataSource_Exist = [] as any[];
  dataSource_ExistLength = 0;
  EMRdataSource;
  btnvalue;
  clkaddbutton = 0;
  medhistoryResidentid;
  medhistoryid;
  medhistorynotes;
  medhistoryallergies;
  medhistorycontname;
  medhistorycontnumber;
  medhistoryemail;
  medhistoryEMR;
  medhistoryaddtype;
  updateflag = 0;
  uploademrfilevalue = 0;
  selectedaddmad = 0;
  medproceduredate = "";
  medsource = "";
  medsavebutton;
  medEMRfilesave = true;
  medHistoryaddbool = true;
  Residentbyid: getResidentbyid[];
  uploadfilepath: any[] = [];
  displayedColumns: string[] = [
    "FileName",
    "ProcedureDate",
    "Source",
    "UploadDate",
  ];
  EMRreportdisplayedColumns: string[] = [
    "FileName",
    "ProcedureDate",
    "Source",
    "UploadDate",
    "EMR",
    "Delete",
  ];
  public medicalhistoryaddForm: UntypedFormGroup;
  startDate;
  medrowHeight = "72px";
  screenWidth;
  checkemrflag = 0;
  uploadFileflag = 0;
  bResidentAddEdit = false;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<MedicalHistoryComponent>,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private residentService: ResidentService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.btnvalue = "Choose File";

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Resident,
        enumUIAction.Resident_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bResidentAddEdit = true;
    }

    if (data.AddType != 1) {
      this.updateflag = 1;
      this.medhistoryResidentid = data[0].ResidentId;
      this.medhistoryid = data[0].MedicalHistoryId;
      this.medhistorynotes = data[0].Notes;
      this.medhistoryallergies = data[0].Allergies;
      this.medhistorycontname = data[0].EmergContactName;
      this.medhistorycontnumber = data[0].EmergContactNo;
      this.medhistoryemail = data[0].EmergContactEmail;
      this.medEMRfilename = "";
      this.addEMRFileName = "";
      this.getEMRReport(this.medhistoryResidentid);
    } else {
      this.updateflag = 0;
      this.medhistoryaddtype = data.AddType;
      this.medhistoryResidentid = data.ResidentId;
      this.medhistoryid = "";
      this.medhistorynotes = "";
      this.medhistoryallergies = "";
      this.medhistorycontname = "";
      this.medhistorycontnumber = "";
      this.medhistoryemail = "";
      this.medEMRfilename = "";
      this.addEMRFileName = "";
    }

    this.breakpoint$.subscribe(() => this.breakpointChanged());       
  }
  
  private breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) || 
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
      this.setupFullTable();
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.setupTableforXtrasmallerwidth();
    }
  }

  setupTableforXtrasmallerwidth() {
    this.EMRreportdisplayedColumns = ["FileName", "ProcedureDate", "Source"];
  }

  setupTableforsmallerwidth() {
    this.EMRreportdisplayedColumns = [
      "FileName",
      "ProcedureDate",
      "Source",
      "UploadDate",
    ];
  }

  setupFullTable() {
    this.EMRreportdisplayedColumns = [
      "FileName",
      "ProcedureDate",
      "Source",
      "UploadDate",
      "EMR",
      "Delete",
    ];
  }
  @ViewChild("clkimageInput") clkimageInput: any;
  public onChangeEMR(fileList: FileList) {
    if (fileList.length == 0) {
      this.clkaddbutton = 0;
    }
    const file = fileList[0];
    if (file.name.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      this.clkimageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
      file.name;
    const fileTypesAllowed = ["pdf"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Resident EMR as pdf file"
      );
      this.clkimageInput.nativeElement.value = "";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0]);
      this.clkimageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (self[0].includes("%PDF")) {
            checkvalidIMG = 1;
            resolve(checkvalidIMG);
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Resident EMR file which you tried to upload is not valid file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  uploadfiletoPath(data, file) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        this.medEMRfilename = res.filename;
        this.resmedfileUrl = appsettings.RESIDENT_MEDICALHISTORY + res.filename;
        this.uploademrfilevalue = 1;
        this.addEMRFileName = res.filename;
        this.addEMRReport(this.medEMRfilename);
      });
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.onResize();
    if (this.updateflag == 0) this.medsavebutton = true;

    this.startDate = "1900-01-01T12:00:00.000Z";

    this.medicalhistoryaddForm = new UntypedFormGroup({
      notes: new UntypedFormControl(""),
      allergies: new UntypedFormControl(""),
      emrcontactname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      emrcontactnumber: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(12),
        Validators.minLength(11),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
      emrcontactemail: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(60),
        Validators.email,
        Validators.pattern(
          "^[a-zA-Zs][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]),
      emportemr: new UntypedFormControl(""),
      ProcedureDate: new UntypedFormControl(""),
    });

    const medForm_emportemr = this.medicalhistoryaddForm.get("emportemr");
    if (medForm_emportemr != null) medForm_emportemr.disable();
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.medicalhistoryaddForm.controls).forEach((field) => {
        // {1}
        const control = this.medicalhistoryaddForm.get(field); // {2}
        const medFormId = document.getElementById(event.target.id);
        if (medFormId != null) {
          if (field == medFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    this.screenWidth = window.innerWidth;
    if (isIEOrEdge == true) {
      this.columns = 3;
      this.medrowHeight = "73px";
    }

    if (isIEOrEdge == false && this.screenWidth < 520) {
      this.columns = 2;
      this.medrowHeight = "87px";
    } else if (isIEOrEdge == true && this.screenWidth < 520) {
      this.columns = 2;
      this.medrowHeight = "90px";
    } else if (isIEOrEdge == false) {
      this.columns = 3;
      this.medrowHeight = "72px";
    }
  }
  public checkEMRFile = () => {
    if (!SessionCheck(this.router)) {
      return;
    }

    if (this.checkemrflag == 1 && this.updateflag == 0) {
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to discard the changes?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          const checkEMRfiledata: checkEmrFileData = {
            authKey: this.authkey,
            residentId: parseInt(this.medhistoryResidentid),
          };
          this.residentService
            .checkEMRFile(checkEMRfiledata)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: any[]) => {
              if (apiresponse) {
                this.thisDialogRef.close("Cancel");
              }
            });
        } else {
          return false;
        }
      });
    } else {
      this.thisDialogRef.close("Cancel");
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.medicalhistoryaddForm.controls[controlName].hasError(errorName);
  };

  public newMedHistoryAdd = (resMedicalFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (
        this.clkaddbutton == 0 ||
        (this.clkaddbutton == 1 && this.medEMRfilename == "")
      ) {
        if (this.medicalhistoryaddForm.valid && this.medHistoryaddbool) {
          this.medHistoryaddbool = false;
          this.updateMedicalHistory(resMedicalFormValue);
        }
      }
    } catch (err) {
      throw new Error(
        "medical-history.component.ts" +
          " - newMedHistoryAdd() - " +
          err.message
      );
    }
  };

  private updateMedicalHistory = (residentFormValue) => {
    try {
      let empotemr = "";
      if (residentFormValue.empotemr != undefined) {
        empotemr = residentFormValue.emportemr.toString();
      }
      if (this.updateflag == 0) {
          const residentmedhistory: ResidentMedHistory = {
          authKey: this.authkey,
          notes: residentFormValue.notes.replace(/\s+/g, " ").trim(),
          allergies: residentFormValue.allergies.replace(/\s+/g, " ").trim(),
          emergContactName: residentFormValue.emrcontactname
            .replace(/\s+/g, " ")
            .trim(),
          emergContactNo: residentFormValue.emrcontactnumber.toString(),
          emergContactEmail: residentFormValue.emrcontactemail.toString(),
          emrReportStatus: empotemr,
          residentId: parseInt(this.medhistoryResidentid),
        };
        this.residentService
          .addMedicalHistory(residentmedhistory)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: ResidentForCreationResponse[]) => {
            if (apiresponse["apiReturn"] == "Success") {
              this.medHistoryaddbool = false;
              generateAlertMessage(
                this.dialog,
                "Medical history added successfully."
              );
            } else if (apiresponse["apiReturn"] == "Fail") {
              this.medHistoryaddbool = true;
              generateAlertMessage(
                this.dialog,
                "Medical history added failed."
              );
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("Confirm");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            this.thisDialogRef.close("Confirm");
          });
      } else if (this.updateflag == 1) {
        const residentmedhistory: UpdateResidentMedHistory = {
          authKey: this.authkey,
          notes: residentFormValue.notes.replace(/\s+/g, " ").trim(),
          allergies: residentFormValue.allergies.replace(/\s+/g, " ").trim(),
          emergContactName: residentFormValue.emrcontactname
            .replace(/\s+/g, " ")
            .trim(),
          emergContactNo: residentFormValue.emrcontactnumber.toString(),
          emergContactEmail: residentFormValue.emrcontactemail.toString(),
          emrReportStatus: empotemr,
          medHistoryId: parseInt(this.medhistoryid),
          residentId: parseInt(this.medhistoryResidentid),
        };
        this.residentService
          .updateMedicalHistory(residentmedhistory)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: ResidentForCreationResponse[]) => {
            if (apiresponse["apiReturn"] == "Success") {
              this.medHistoryaddbool = false;
              generateAlertMessage(
                this.dialog,
                "Medical history edited successfully."
              );
            } 
            else if (apiresponse["apiReturn"] == "Fail") {
              this.medHistoryaddbool = true;
              generateAlertMessage(
                this.dialog,
                "Medical history edited failed."
              );
            } 
            else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("Confirm");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            this.thisDialogRef.close("Confirm");
          });
      }
    } catch (err) {
      throw new Error(
        "medical-history.component.ts" +
          " - updateMedicalHistory() - " +
          err.message
      );
    }
  };

  changebuttonvalue(event) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (event.target.textContent != "Add File") {
        this.clkaddbutton = 1;
        this.medsavebutton = true;
        this.medEMRfilesave = true;
        this.medproceduredate = "";
        this.medsource = "";
        const clkFileUpload = document.getElementById("clkfileupload");
        if (clkFileUpload != null) clkFileUpload.click();
      } else {
        const prodatepicker = document.getElementById("Prodatepicker");
        if (this.medEMRfilesave == true) {
          const todaydate = new Date();
          const proceduredate = new Date(this.medproceduredate);
          const formattedDt = moment(proceduredate).format("MM/DD/YYYY");
          const statedate = moment(this.startDate).format("MM/DD/YYYY");
          const _statedate = new Date(statedate);
          if (proceduredate < _statedate) {
            if (prodatepicker != null) prodatepicker.focus();
            generateAlertMessage(
              this.dialog,
              "Procedure date should be more than or equal to '01/01/1900'."
            );
            return;
          }
          const getmedprocedureDate = this.getErrorMessage(
            this.medproceduredate
          );
          if (this.medproceduredate == "") {
            if (prodatepicker != null) prodatepicker.focus();
            generateAlertMessage(
              this.dialog,
              "Please enter the procedure date."
            );
            return;
          } else if (getmedprocedureDate != undefined) {
            generateAlertMessage(this.dialog, getmedprocedureDate);
            return;
          } else if (this.medproceduredate != "" && proceduredate > todaydate) {
            if (prodatepicker != null) prodatepicker.focus();
            generateAlertMessage(
              this.dialog,
              "Procedure date should be less than or equal to Today date"
            );
            return;
          } else if (this.medsource == "") {
            const myInput = document.getElementById("myInput");
            if (myInput != null) myInput.focus();
            generateAlertMessage(this.dialog, "Please enter the source.");
            return;
          } else {
            this.medEMRfilesave = false;
            const filename = this.addEMRFileName.split(".");
            const addemrreport: ShowEMRFileReport = {
              authKey: this.authkey,
              residentId: parseInt(this.medhistoryResidentid),
              fileName: filename[0].toString(),
              procedureDate: formattedDt,
              source: this.medsource,
              uploadDate: this.meddate.toString(),
            };

            this.residentService
              .addEMRReport(addemrreport)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse: ResidentForCreationResponse[]) => {
                if (apiresponse.toString() == "true") {
                  this.checkemrflag = 1;
                  this.medsavebutton = false;
                  this.clkaddbutton = 0;
                  this.uploademrfilevalue = 0;
                  this.medEMRfilename = "";
                  this.addEMRFileName = "";
                  this.btnvalue = "Choose File";
                  this.getEMRReport(this.medhistoryResidentid);
                } else if (apiresponse["condition"] == "Authfalse") {
                  this.thisDialogRef.close("close");
                  AuthFailedMessage(this.dialog, this.router);
                  return;
                } else {
                  this.medEMRfilesave = true;
                }
              });
          }
        }
      }
    } catch (err) {
      throw new Error(
        "medical-history.component.ts" +
          " - changebuttonvalue() - " +
          err.message
      );
    }
  }

  getToday(): string {
    return new Date().toISOString().split("T")[0];
  }
  changeValue(clkevent, flag) {
    if (flag == 0) this.medproceduredate = clkevent.targetElement.value;
    else {
      this.medsource = clkevent.target.value;
      this.medsource = this.medsource.trim();
      const element = document.getElementById("myInput");
      if (element != null) element.className = "enable";
    }
  }

  setfocusforsource() {
    if (this.medproceduredate) {
      const get_myInput = document.getElementById("myInput");
      if (get_myInput != null) get_myInput.focus();
      const element = document.getElementById("myInput");
      if (element != null) element.className = "enable";
    }
  }

  addEMRReport(filename) {
    try {
      this.btnvalue = "Add File";
      this.medsavebutton = false;
      const getaddfiledata = [] as any[];
      this.meddate = new Date();
      this.meddate =
        ("0" + (this.meddate.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + this.meddate.getDate()).slice(-2) +
        "/" +
        this.meddate.getFullYear().toString().substr(-2);

      const getfilename = filename.split(".");
      this.authkey = localStorage.getItem("Authkey") ?? "";
      const showEMRfilelist: ShowEMRFileReport = {
        authKey: this.authkey,
        residentId: this.medhistoryResidentid,
        fileName: getfilename[0],
        procedureDate: "",
        source: "",
        uploadDate: this.meddate,
      };
      console.log("uploademrfilevalue : " + this.uploademrfilevalue)
      getaddfiledata.push(showEMRfilelist);
      // this.dataSource = getaddfiledata;
      this.dataSource = new MatTableDataSource(getaddfiledata);
      this.dataSource.data = getaddfiledata;
    } catch (err) {
      throw new Error(
        "medical-history.component.ts" + " - addEMRReport() - " + err.message
      );
    }
  }

  getEMRReport(residentId) {
    try {
      this.medsavebutton = true;
      this.authkey = localStorage.getItem("Authkey") ?? "";
      const residentbyid: ResidentByID = {
        authKey: this.authkey,
        residentId: parseInt(residentId),
      };
      this.residentService
        .getEMRReport(residentbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ResidentbyId: any[]) => {
          this.Residentbyid = ResidentbyId;
          this.dataSource_Exist = this.Residentbyid;
          this.dataSource_ExistLength = this.dataSource_Exist.length;
        });
    } catch (err) {
      throw new Error(
        "medical-history.component.ts" + " - getEMRReport() - " + err.message
      );
    }
  }

  deleteEMRFile(reportId, residentid, filename) {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      this.clkaddbutton = 1;
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to delete the report?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          const fileName: ReportbyName = {
            authKey: this.authkey,
            fileName: filename.toString(),
          };
          this.residentService
            .deleteEMRFile(fileName)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: ResidentForCreationResponse[]) => {
              if (apiresponse.toString() == "true") {
                this.deleteEMRReport(reportId, residentid);
              } else if (apiresponse.toString() == "false") {
                generateAlertMessage(this.dialog, "EMR file deleted failed.");
              } else if (apiresponse["condition"] == "Authfalse") {
                this.thisDialogRef.close("close");
                AuthFailedMessage(this.dialog, this.router);
                return;
              }
            });
        } else {
          this.clkaddbutton = 0;
          return false;
        }
      });
    } catch (err) {
      throw new Error(
        "medical-history.component.ts" + " - deleteEMRFile() - " + err.message
      );
    }
  }

  deleteEMRReport(reportId, residentid) {
    try {
      const reportbyid: ReportById = {
        authKey: this.authkey,
        reportId: parseInt(reportId),
      };

      this.residentService
        .deleteEMRReport(reportbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: ResidentForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            this.clkaddbutton = 0;
            generateAlertMessage(this.dialog, "EMR file deleted successfully.");
            this.getEMRReport(residentid);
          } else if (apiresponse.length == 0) {
            generateAlertMessage(this.dialog, "EMR file deleted failed.");
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "medical-history.component.ts" + " - deleteEMRReport() - " + err.message
      );
    }
  }

  onOpenEMRReport(fileName) {
    window.open(
      appsettings.RESIDENT_MEDICALHISTORY + fileName + ".pdf",
      "_blank"
    );
  }

  getErrorMessage(pickerInput: string): string {
    if (!moment(pickerInput).isValid()) {
      return "Please enter valid date format MM/DD/YYYY";
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
