<div>
    <div class="add-title">
      <h2 class="ylet-primary-500">Acknowledge
      <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h2>
    </div>
    <div>
      <!-- <form [formGroup]="ackalertForm" autocomplete="off" novalidate> -->

          <mat-grid-list cols="2" rowHeight="40.5px">  
              <mat-grid-tile>
                  <label>Resident Name</label>
              </mat-grid-tile>
              <mat-grid-tile>
                  <div class="text-inside">
                    <input type="text" [(ngModel)]="data.FirstName" readonly="readonly">                   
                </div>
              </mat-grid-tile>

              <mat-grid-tile>
                <label>Room Id</label>
              </mat-grid-tile>
              <mat-grid-tile>
                  <div class="text-inside">
                    <input type="text" *ngIf="data.RoomId > 0" [(ngModel)]="data.RoomId" readonly="readonly">                   
                </div>
              </mat-grid-tile>

              <mat-grid-tile>
                <label>Room Name</label>
              </mat-grid-tile>
              <mat-grid-tile>
                  <div class="text-inside">
                    <input type="text" [(ngModel)]="data.RoomName" readonly="readonly">                   
                </div>
              </mat-grid-tile>

              <mat-grid-tile>
                <label class="clstxtcontrol">Authorized By</label>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="text-inside">
                    <mat-form-field appearance="outline" floatLabel="auto">                    
                        <mat-select placeholder="Select Authorizer" (selectionChange)="onChange($event)">                           
                          <mat-option *ngFor="let Authorize of GetStaffdetails" [value]="Authorize.StaffID">
                            {{Authorize.LastName}}
                          </mat-option>
                      </mat-select>     
                                
                    </mat-form-field>
                  </div>
              </mat-grid-tile>

              </mat-grid-list>

          <mat-dialog-actions class="clsbuttoncontrol">    
            <button class="clsaddbutton" mat-raised-button (click)="clkAcknowledgeAlert(data.AlertId)">Acknowledge</button>
          </mat-dialog-actions>
      <!-- </form> -->
    </div>
</div>
