import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MediaObserver } from "@angular/flex-layout";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../app-general";
import {
  EditUnitForm,
  TechnologyForCreationResponse,
  deleteUnitById,
} from "../../technologyoverlay";
import { ApiService } from "../../../../api.service";
import { appsettings, enumEnableDisable } from "../../../../app-settings";
import { MapSyncProcess, UnitById } from "../../../../api";
import { GeneralMessageComponent } from "../../../../general-message/general-message.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { TooltipPosition } from "@angular/material/tooltip";

@Component({
  selector: "app-edit-outdoorspace",
  templateUrl: "./edit-outdoorspace.component.html",
  styleUrls: ["./edit-outdoorspace.component.scss"],
})
export class EditOutdoorspaceComponent implements OnInit {
  public editoutdoorspaceForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 3;
  selectedCampus;
  campusinfo;
  newCampusInfo;
  addoutdoorbool = true;
  SvgPoints;
  private _onDestroy: Subject<void> = new Subject<void>();
  editunitbool = true;
  deleteUnitId;
  Outdoor_DeviceData;
  deviceEditflag;
  CampusName;
  BuildingName;
  FloorName;
  Description;  
  Accuracy;
  MapSyncProcess;
  editoutdoordialogopen = 0;
  selectedUnitName;
  device_Count = 0;
  authkey;
  maxSpaceId;
  matTooltipSpaceId = "The Space ID is an auto-number field that starts at (1) when the Approximate Space is first created. This field is editable.";
  matTooltipLocAccuracy = "Location Accuracy will be populated when available on signature server. This field is not editable.";
  positionOptions: TooltipPosition[] = [
  "after",
  "before",
  "above",
  "below",
  "left",
  "right",
  ];
  position = new UntypedFormControl(this.positionOptions[3]);
  Breakpoints = Breakpoints;
  checkCampusorZone = 0;
  checkApproximate = false;
  approxDescription = "";
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    mediaObserver: MediaObserver,
    public thisDialogRef: MatDialogRef<EditOutdoorspaceComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private apiService: ApiService,
    public thiseditDialogRef: MatDialogRef<EditOutdoorspaceComponent>,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.Outdoor_DeviceData = data.devicedata;
    this.SvgPoints = this.Outdoor_DeviceData.PolyPoints;
    this.deleteUnitId = this.Outdoor_DeviceData.UnitId;
    this.deviceEditflag = data.DeviceEdit;
    this.CampusName = this.Outdoor_DeviceData.CampusName;
    this.BuildingName = this.Outdoor_DeviceData.BuildingName;
    this.FloorName = this.Outdoor_DeviceData.FloorName;
    this.Description = this.Outdoor_DeviceData.Description;
    if(this.Outdoor_DeviceData.Accuracy != "")
      this.Accuracy = this.Outdoor_DeviceData.Accuracy+"%";    
    else
      this.Accuracy = this.Outdoor_DeviceData.Accuracy;    
    this.selectedUnitName = this.Outdoor_DeviceData.UnitName;
    this.device_Count = this.Outdoor_DeviceData.devicecount;  
    this.maxSpaceId = this.Outdoor_DeviceData.SpaceId;  
    if(this.Description == "")
    {
      if(this.BuildingName == null)
        this.approxDescription = this.CampusName;
      else if(this.FloorName == null)
        this.approxDescription = this.BuildingName + "," + this.CampusName;
      else
        this.approxDescription = this.FloorName + "," + this.BuildingName + "," + this.CampusName;  
    }
    else
      this.approxDescription = this.Description;
    if(this.Outdoor_DeviceData.BuildId == 0)
      this.checkApproximate = false;
    else
      this.checkApproximate = true;
        
    this.breakpoint$.subscribe(() => this.breakpointChanged());        
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;      
    }        
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;      
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.editoutdoorspaceForm = new UntypedFormGroup({
      campus: new UntypedFormControl(""),
      campusId: new UntypedFormControl(""),
      buildId: new UntypedFormControl(""),
      floorId: new UntypedFormControl(""),
      name: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern("[a-zA-Z0-9s][a-zA-Z0-9!@#$%^& ]*"),
      ]),
      description: new UntypedFormControl("", [
        Validators.pattern("[a-zA-Z0-9s][a-zA-Z0-9!@#$%^,'& ]*"),
      ]),
      spaceId: new UntypedFormControl("", [ Validators.required, Validators.pattern("^[0-9-%]*")]),
      accuracy: new UntypedFormControl(""),
    });
    const editOutdoorFormCampus = this.editoutdoorspaceForm.get("campus");
    if(editOutdoorFormCampus != null)
      editOutdoorFormCampus.disable();
      
    const editOutdoorFormAccuracy = this.editoutdoorspaceForm.get("accuracy");
    if(editOutdoorFormAccuracy != null)
      editOutdoorFormAccuracy.disable();
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    this.editoutdoorspaceForm.controls[controlName].hasError(errorName);
  };
  
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.editoutdoorspaceForm.controls).forEach((field) => {
        // {1}
        const control = this.editoutdoorspaceForm.get(field); // {2}
        const outdoorFormId = document.getElementById(event.target.id);
        if (outdoorFormId != null) {
          if (field == outdoorFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  editOutdoorSpacedata(editoutdoorform) {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }

    if (this.editoutdoorspaceForm.valid) {
      this.updateUnit(editoutdoorform);
    }
  }

  openOutdoorEditdialog() {
    try {
      const _dialog = this.dialog;
      const outdoordata = this.Outdoor_DeviceData;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      const editoutdoordata = JSON.stringify(outdoordata);
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            return false;
          } else {
            const outdoorDevicedata = {
              DeviceEdit: 1,
              devicedata: JSON.parse(editoutdoordata),
            };
            if (this.editoutdoordialogopen == 0) {
              const dialogRef = this.dialog.open(EditOutdoorspaceComponent, {
                disableClose: true,
                width: "38em",
                height: "640px",
                panelClass: "myapp-no-padding-dialog",
                autoFocus: false,
                data: outdoorDevicedata,
              });
              this.editoutdoordialogopen = 1;
              dialogRef.afterClosed().subscribe((result) => {
                this.editoutdoordialogopen = 0;

                if (result != "Cancel" && result != "outdoordelete")
                  this.getoutdoordetails(result);
                else if (result == "outdoordelete") {
                  this.thiseditDialogRef.close();
                }
              });
            }
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" +
          " - openOutdoorEditdialog() - " +
          err.message
      );
    }
  }

  getoutdoordetails(unitId) {
    const unitbyid: UnitById = {
      authKey: this.authkey,
      unitId: parseInt(unitId),
    };
    this.technologyoverlayService
      .getUnitById(unitbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((UnitByid: any[]) => {
        this.CampusName = UnitByid["apiReturn"][0].CampusName;
        this.Description = UnitByid["apiReturn"][0].Description;
        this.Accuracy = UnitByid["apiReturn"][0].Accuracy;
        this.selectedUnitName = UnitByid["apiReturn"][0].UnitName;
        this.Outdoor_DeviceData = UnitByid["apiReturn"][0];
        this.maxSpaceId = UnitByid["apiReturn"][0].SpaceId;
      });
  }

  private updateUnit = (editUnitFormValue) => {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();

      let _Description = "";
      if (editUnitFormValue.description != "")
        _Description = editUnitFormValue.description
          .replace(/\s+/g, " ")
          .trim();
      else _Description = "";

      if (this.editoutdoorspaceForm.valid) {       
        this.outdoorEditEnableDisable(enumEnableDisable.Disable);
        const editunitdetails: EditUnitForm = {
          authKey: this.authkey,
          unitId: parseInt(this.deleteUnitId),
          campusId: parseInt(editUnitFormValue.campusId),
          buildId: parseInt(editUnitFormValue.buildId),
          floorId: parseInt(editUnitFormValue.floorId),
          unitName: editUnitFormValue.name.replace(/\s+/g, " ").trim(),
          description: _Description, //editUnitFormValue.description.replace(/\s+/g, ' ').trim(),
          spaceCategoryId: 0,
          accuracy: '',//editUnitFormValue.accuracy.toString(),
          resOffLimits: 0,
          spaceId: parseInt(editUnitFormValue.spaceId),
          chkAllday : 0,
          resStartHr : 0,
          resStartMi : 0,
          resEndHr : 0,
          resEndMi : 0
        };

        this.technologyoverlayService
          .updateUnit(editunitdetails)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse["apiReturn"] == "Success") {
              generateAlertMessage(
                this.dialog,
                "Outdoor Space edited successfully."
              );             
              this.outdoorEditEnableDisable(enumEnableDisable.Enable);
              this.thisDialogRef.close(this.deleteUnitId);
            } else if (apiresponse["apiReturn"] == "Fail") {
              generateAlertMessage(this.dialog, "Outdoor Space edited failed.");              
              this.outdoorEditEnableDisable(enumEnableDisable.Enable);
              this.thisDialogRef.close();
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");              
              this.outdoorEditEnableDisable(enumEnableDisable.Enable);
              AuthFailedMessage(this.dialog, this.router);
              return;
            } else if (apiresponse["apiReturn"] == "unitName") {
              generateAlertMessage(this.dialog, "Please enter Space Name");             
              this.outdoorEditEnableDisable(enumEnableDisable.Enable);
              return;
            } 
            else if (apiresponse["apiReturn"] == "spaceId") {                            
              generateAlertMessage(this.dialog, "Space Id already exists, Please try with different Id.");       
              this.outdoorEditEnableDisable(enumEnableDisable.Enable);
              return;
            }
            else {
              generateAlertMessage(this.dialog,"Space name '" + apiresponse["apiReturn"][0].UnitName + "' already exists.");              
              this.outdoorEditEnableDisable(enumEnableDisable.Enable);
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - updateUnit() - " + err.message
      );
    }
  };

  openDeletedialog() {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      this.deleteUnit(this.deleteUnitId);
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - openDeletedialog() - " + err.message
      );
    }
  }

  deleteUnit(deleteUnitId) {
    try {
      // let Device_Count = "";
      // let message = "";
      // const _deviceCount = localStorage.getItem("DeviceCount") ?? "";

      // if (this.device_Count > 0) {
      //   message = "It contains ";
      //   Device_Count = this.device_Count + " Devices.";
      // } else if (this.device_Count == undefined) {
      //   const DeviceCount = parseInt(_deviceCount);
      //   console.log("DeviceCount : " + DeviceCount);
      //   if (DeviceCount > 0) {
      //     message = "It contains ";
      //     Device_Count = DeviceCount + " Devices.";
      //   }
      // }

      const GeneralMessageData = {
        General_header: "Delete Space ",
        GeneralMessage:
          "Are you sure you would like to delete this Space?<br> " +
          // message +
          // " <b>" +
          // Device_Count +
          " </b>",
        General_first: "Yes ",
        General_second: "No",
      };
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.DeleteUnitbyId(deleteUnitId);
        } else {
          dialogRef.close("Cancel");
          localStorage.setItem("UnitId", "0");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "delete-resident-group.ts" + " - deleteUnit() - " + err.message
      );
    }
  }

  DeleteUnitbyId(deleteUnitId) {
    const deleteunitById: deleteUnitById = {
      authKey: this.authkey,
      unitId: parseInt(deleteUnitId),
    };

    this.technologyoverlayService
      .deleteUnit(deleteunitById)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
        if (apiresponse["apiReturn"] == "Success") {
          generateAlertMessage(this.dialog, "Space deleted successfully.");
          this.thisDialogRef.close("outdoordelete");
        } else if (apiresponse["apiReturn"] == "Fail") {
          generateAlertMessage(this.dialog, "Space deleted failed ");
        } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
          this.thisDialogRef.close("close");
          AuthFailedMessage(this.dialog, this.router);
          return;
        } else {
          generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
        }
        // this.thisDialogRef.close();
      });
  }
  
  outdoorEditEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsaveoutdooreditclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clseditoutdoorbutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clseditoutdoorbutton mat-raised-button disablebtn";
  }
}
