import { Component, OnInit,OnDestroy } from '@angular/core';
import { TechnologyoverlayService } from './technologyoverlay.service';


@Component({
  selector: 'app-technologyoverlay',
  templateUrl: './technologyoverlay.component.html',
  providers: [TechnologyoverlayService],
  styleUrls: ['./technologyoverlay.component.scss']
})
export class TechnologyoverlayComponent implements OnInit,OnDestroy {
  constructor(private technologyoverlayService: TechnologyoverlayService) {
    
  }

  ngOnInit() {
    console.log('technologyoverlay.component - ngOnInit');
  }
  
    ngOnDestroy() {
      console.log('technologyoverlay.component - ngOnDestroy');
    }
}
