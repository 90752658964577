import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { HostListener } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { ReportService } from "../report.service";
import { Label, MultiDataSet } from "ng2-charts";
import { ChartType } from "chart.js";
import {
  SessionCheckwithStaff,
  getAddEditDeleteColumn,
} from "../../../app-general";
import {
  AlertFilterinfo,
  Chartvalue,
  singleChartvalue,
  DateDetail,
  EsclationCountValue,
  AlertCountValue,
  EventNamedetails,
  AlertFilterdate,
  BarCharValue,
  getAlertTypes,
  getAlertLocations,
} from "../report";
import {
  report_leftmenu,
  alert_deviceType,
  NewDeviceTypeDesc,
  enumAlertSubType,
  enumAlertType,
  enumAlertStatus,
  Report_Type,
} from "../../../app-settings";
import { ExportReportComponent } from "../export-report/export-report.component";
import { AlertSharedService } from "../../../_services/index";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DatePipe, DOCUMENT } from "@angular/common";
import { SharedCheckIsAlertReportData } from "../../../api";
import * as moment from "moment";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { ReportHeaderComponent } from "../report-header/report-header.component";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

declare const Chart: any;

@Component({
  selector: "app-selectdate",
  templateUrl: "./selectdate.component.html",
  providers: [ReportService, DatePipe],
  styleUrls: ["./selectdate.component.scss"],
})
export class SelectdateComponent implements OnInit, AfterViewInit {
  ReportType = report_leftmenu.campusdashboard;
  private _onDestroy: Subject<void> = new Subject<void>();
  IsRefreshNeed = 1;

  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private reportService: ReportService,
    private router: Router,
    public dialog: MatDialog,
    private alertSharedService: AlertSharedService,
    private datePipe: DatePipe,
    public mainLayoutService: MainLayoutService,    
    @Inject(DOCUMENT) private document: any,
    @Inject(DOCUMENT) private obj_document: any,
    private breakpointObserver: BreakpointObserver
  ) {    
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.setupFullTable();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupTableformdwidth();
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }
  
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  screenWidth;
  flag = 0;
  flag1 = 0;
  flag3 = 0;
  select = 0;
  fromdate;
  todate;
  exportEventName = report_leftmenu.campusdashboard;
  ExportFromdate;
  ExportTodate;
  IsLoaded = 0;
  IsFilter = 1;
  IsFilterData = "";  
  reportselectloading = true;
  reportloading = true;
  element;
  barchartvalue1;
  barchartvalue2;
  barchartvalue3;
  doughnutvalue1;
  doughnutvalue2;
  minutes1;
  minutes2;
  seconds1;
  seconds2;
  value1;
  value2;
  value3;
  day;
  hour;
  min;
  alertvalue1;
  alertvalue2;
  alertvalue3;
  alertcount1;
  alertcount2;
  alertcount3;
  CountAlert = [] as any[];
  doughnutlableNames = [];
  totalAlertCount;
  daterange;
  MinDate;
  MaxDate;
  Reportdetails;
  loaddateDetails = [];
  events: string[] = [];

  dateDetail: DateDetail[];
  barchart: Chartvalue[];
  doughnutchart: singleChartvalue[];
  EsclationCount: EsclationCountValue[];
  AlertCount: AlertCountValue[];
  BarChartinfo: BarCharValue[];

  centervalue;
  todaydate;
  Todaydate;
  selectedAlertType = "0";
  selectedLocationId = 0;
  Alertdetails;
  selectedLocation;
  Locationdetails;

  dataSource;

  avgreslength = 3;

  bAddEdit = true;
  bDelete = true;

  oldFrom_date;
  oldTo_date;
  selectedFromDateTime;
  selectedToDateTime;
  DatepickerShow = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(DaterangepickerDirective) picker: DaterangepickerDirective;
  selected: { startDate: moment.Moment; endDate: moment.Moment };
  name = "Angular";

  openDatepicker() {
    if (this.DatepickerShow == false) {
      this.DatepickerShow = true;
      this.picker.open();
    } else {
      this.DatepickerShow = false;
      this.picker.hide();
    }
  }
  hideDatePicker() {
    this.picker.hide();
  }
  displayedColumns: string[] = [
    "Alert",
    "ResidentName",
    "Resident_RoomName",
    "RoomInfo",
    "AlertTime",
    "ResponseTimeSort",
    "PresenceOn",
    "AckedName",
  ];

  public EnumAlertType = alert_deviceType;
  public EnumNewDeviceType = NewDeviceTypeDesc;
  public EnumAlertSubType = enumAlertSubType;
  public EnumDeviceAlertType = enumAlertType;
  public EnumAlertStatus = enumAlertStatus;
  public EnumReport_Type = Report_Type;

  public AverageResponseTime = "";
  public AverageResponseTimeFromIniMin = localStorage.getItem(
    "average_response_time_min"
  );
  public AverageResponseTimeFromIniSec = localStorage.getItem(
    "average_response_time_sec"
  );
  public AverageResponseThresholdFromIniMin = localStorage.getItem(
    "average_response_Threshold_min"
  );
  public AverageResponseThresholdFromIniSec = localStorage.getItem(
    "average_response_Threshold_sec"
  );
  DisplayTimeInfo;
  GreenValueUnder = 0;
  GreenValueWithin = 0;
  RedValue = 0;
  getTimeInfo;
  checkresinfo = false;
  public checked_resinfo = false;
  selectedDate: any = { startDate: null, endDate: null };
  authkey;

  // Doughnut Location
  public doughnutLocationType: ChartType = "doughnut";
  public doughnutLocationColors = [] as any[];
  public doughnutLocationLabels: Label[];
  public doughnutLocationDataSet: MultiDataSet = [];
  public doughnutLocatrionOptions = {};

  // Doughnut Esclation
  public doughnutEscaltionType: ChartType = "doughnut";
  public doughnutEscaltionColors = [] as any[];
  public doughnutEscaltionLabels: Label[];
  public doughnutEscaltiondatasets: MultiDataSet = [];
  public doughnutEscaltionoptions = {};

  getMaxDate(): any {
    return moment();
  }

  ngAfterViewInit() {
    const rightCalendar = document.getElementsByClassName("calendar right");
    if (rightCalendar.item(0)) {
      const rightCalendar_item = rightCalendar.item(0);
      if (rightCalendar_item != null) rightCalendar_item.remove();
    }
  }

  ranges: any = {
    Today: [moment(), moment()],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.select = 1;
    this.reportselectloading = false;

    if (this.IsRefreshNeed == 1) {
      this.todaydate = new Date();
      this.Todaydate =
        this.monthNames[this.todaydate.getMonth()] +
        " " +
        ("0" + this.todaydate.getDate()).slice(-2) +
        ", " +
        this.todaydate.getFullYear();      

      this.MinDate = "1900-01-01T12:00:00.000Z";
      this.MaxDate = new Date();
      if (localStorage.getItem("rep_From_Date") != null)
        this.fromdate = localStorage.getItem("rep_From_Date");
      if (localStorage.getItem("rep_To_Date") != null)
        this.todate = localStorage.getItem("rep_To_Date");

      this.selectedFromDateTime = this.fromdate;
      this.selectedToDateTime = this.todate;
      this.oldFrom_date = this.fromdate;
      this.oldTo_date = this.todate;

      const report_AlertType = localStorage.getItem("rep_AlertType");
      const report_LocationId = localStorage.getItem("rep_LocationId");

      if (report_AlertType != null) this.selectedAlertType = report_AlertType;
      if (report_LocationId != null)
        this.selectedLocationId = parseInt(report_LocationId);

      this.selectedDate.startDate = new Date(this.fromdate);
      this.selectedDate.endDate = new Date(this.todate);
      
      let ChkIsAlertReportChanged = true;
      this.alertSharedService
        .currentSharedCheckIsAlertReport()
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
          (SharedCheckIsAlertReportDetails: SharedCheckIsAlertReportData) => {
            const CheckIsAlertReportDataChanged =
              SharedCheckIsAlertReportDetails;
            if (CheckIsAlertReportDataChanged != undefined) {
              if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 1 &&
                ChkIsAlertReportChanged
              ) {
                ChkIsAlertReportChanged = false;
                this.onClicksave();  
                CheckIsAlertReportDataChanged.IsAlertReportChanged = 0;
                this.alertSharedService.changeSharedCheckIsAlertReport(
                  CheckIsAlertReportDataChanged
                );
              } else if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 0
              ) {
                ChkIsAlertReportChanged = true;
              }
            }
          }
        );

      this.getAlertTypes();
      this.getAlertLocations();
      this.enableSearchbtn();
      this.onClicksave();
    }

    this.onResize();
  }

  setupTableforXtrasmallerwidth() {
    this.displayedColumns = ["Alert", "ResidentName", "AckedName"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableforsmallerwidth() {
    this.displayedColumns = ["Alert", "ResidentName", "AckedName"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableformdwidth() {
    this.displayedColumns = ["Alert", "ResidentName", "RoomInfo", "AckedName"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupFullTable() {
    this.displayedColumns = [
      "Alert",
      "ResidentName",
      "Resident_RoomName",
      "RoomInfo",
      "AlertTime",
      "ResponseTimeSort",
      "PresenceOn",
      "AckedName",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
     /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );//todo
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1280 && this.screenWidth < 1640) {
      if (this.flag1 == 0) {
        const element = document.getElementById("divselectdates");
        if (element != null) element.className = "clsdivselectdates";
      }
    } else {
      const element = document.getElementById("divselectdates");
      if (element != null) element.className = "clsSelectdateheader";
    }
  }

  daterangechange(event) {
    if(event.startDate != null && event.endDate != null)
    {
      this.fromdate = event.startDate._d;
      this.todate = event.endDate._d;
      this.fromdate = new Date(this.fromdate);
      this.todate = new Date(this.todate);

      this.DatepickerShow = false;
      if (this.fromdate != "" && this.todate != "") {
        if (this.oldFrom_date != this.fromdate) {
          this.hideExportbtn();
        }
        this.enableSearchbtn();
      } else {
        this.hideSearchbtn();
      }
    }
  }

  public onClicksave = () => {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }

    this.checked_resinfo = false;
    //this.paginator.pageIndex = 0;
    this.IsFilter = 1;
    this.IsFilterData = "";    

    this.selectedFromDateTime = this.fromdate;
    this.selectedToDateTime = this.todate;
    localStorage.setItem("rep_From_Date", this.selectedFromDateTime);
    localStorage.setItem("rep_To_Date", this.selectedToDateTime);
    localStorage.setItem("rep_AlertType", this.selectedAlertType);
    if (this.selectedLocationId >= 0)
      localStorage.setItem(
        "rep_LocationId",
        this.selectedLocationId.toString()
      );
    // this.hideSearchbtn();
    this.GetselecteddateforGraph();
    this.flag3 = 1;
  };

  GetselecteddateforGraph() {
    try {
      this.reportselectloading = true;
      const fromdatePass = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
      const todatePass = this.datePipe.transform(this.todate, "yyyy-MM-dd");
      let _fromDatePass = "";
      let _toDatePass = "";
      if (fromdatePass != null) _fromDatePass = fromdatePass.toString();

      if (todatePass != null) _toDatePass = todatePass.toString();

      this.flag = 1;
      this.IsLoaded = 1;

      const chartRequest: AlertFilterdate = {
        authKey: this.authkey,
        fromDate: _fromDatePass,
        toDate: _toDatePass,
        alertType: this.selectedAlertType.toString(),
        location: this.selectedLocationId,
        patientId: 0,
        reportType: parseInt(Report_Type.Campus_Dashboard.toString()),
      };

      this.generateAverageResponseTime(chartRequest);
      this.generateEscaltionChart(chartRequest);
      this.generateAlertLocationReport(chartRequest);
      this.showDateRange();
      this.flag1 = 1;     
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - GetselecteddateforGraph() - " + err.message
      );
    }
  }

  generateAverageResponseTime(chartRequest) {
    try {
      let TotalSec = 0;
      let TotalThreshold = 0;
      let TimeInfo;
      this.reportService
        .generateAverageResponseTime(chartRequest)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((value: any[]) => {
          this.doughnutchart = value;
          this.doughnutvalue1 = this.doughnutchart[0][0].Average1;
          if (
            this.AverageResponseTimeFromIniMin != null &&
            this.AverageResponseTimeFromIniSec != null
          )
            TotalSec =
              parseInt(this.AverageResponseTimeFromIniMin) * 60 +
              parseInt(this.AverageResponseTimeFromIniSec);
          if (
            this.AverageResponseThresholdFromIniMin != null &&
            this.AverageResponseThresholdFromIniSec != null
          )
            TotalThreshold =
              parseInt(this.AverageResponseThresholdFromIniMin) * 60 +
              parseInt(this.AverageResponseThresholdFromIniSec);
          this.AverageResponseTime = "";
          this.DisplayTimeInfo = "";
          this.RedValue = 0;
          this.GreenValueUnder = 0;
          this.GreenValueWithin = 0;

          if (this.doughnutvalue1 > 0) {
            if (this.doughnutvalue1 > TotalSec + TotalThreshold) {
              this.RedValue = Math.round(
                this.doughnutvalue1 - (TotalSec + TotalThreshold)
              );
              this.DisplayTimeInfo =
                "+" + this.ConvertMinutes(this.RedValue, "1");
            } else if (TotalSec + TotalThreshold >= this.doughnutvalue1) {
              if (this.doughnutvalue1 > TotalSec) {
                this.GreenValueWithin = Math.round(
                  this.doughnutvalue1 - TotalSec
                );
                this.DisplayTimeInfo =
                  "+" + this.ConvertMinutes(this.GreenValueWithin, "1");
              } else {
                const Totalvalue = TotalSec - TotalThreshold;
                if (Totalvalue >= this.doughnutvalue1) {
                  this.GreenValueUnder = Math.round(
                    Totalvalue - this.doughnutvalue1
                  );
                  this.DisplayTimeInfo =
                    "-" + this.ConvertMinutes(this.GreenValueUnder, "1");
                } else {
                  this.GreenValueUnder = Math.round(
                    this.doughnutvalue1 - Totalvalue
                  );
                  this.DisplayTimeInfo =
                    "+" + this.ConvertMinutes(this.GreenValueUnder, "1");
                }
              }
            } else if (TotalSec >= this.doughnutvalue1) {
              const Totalvalue = TotalSec - TotalThreshold;
              this.GreenValueUnder = Math.round(
                this.doughnutvalue1 - Totalvalue
              );
              this.DisplayTimeInfo =
                "-" + this.ConvertMinutes(this.GreenValueUnder, "1");
            }

            this.AverageResponseTime = this.ConvertMinutes(
              this.doughnutvalue1,
              "1"
            );
            TimeInfo = this.AverageResponseTime.split(":");
            if (TimeInfo[0].includes("D")) {
              this.getTimeInfo = 4;
            } else if (TimeInfo.length > 0) {
              this.getTimeInfo = TimeInfo.length - 1;
            }
          }
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" +
          " - generateAverageResponseTime() - " +
          err.message
      );
    }
  }

  generateEscaltionChart(chartRequest) {
    try {
      this.reportService
        .generateEscaltionChart(chartRequest)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((value: any[]) => {
          this.EsclationCount = value;

          let total_AlertCount = 0;
          this.CountAlert = [];
          let sLabels = "";
          for (
            let nalertInfo = 0;
            nalertInfo < this.EsclationCount.length;
            nalertInfo++
          ) {
            total_AlertCount =
              total_AlertCount + this.EsclationCount[nalertInfo].Count;
            this.CountAlert.push(this.EsclationCount[nalertInfo].Count);
            sLabels += this.EsclationCount[nalertInfo].Level + ",";
          }

          sLabels = sLabels.slice(0, -1);
          // const scount = this.CountAlert;
          Chart.defaults.global.legend.labels.usePointStyle = true;

          this.doughnutEscaltiondatasets = [this.CountAlert];

          this.doughnutEscaltionColors = [
            {
              backgroundColor: ["#005594", "#21a2dc", "#f6921d"],
              borderWidth: 0,
            },
          ];

          this.doughnutEscaltionLabels = sLabels.split(",");
          const theHelp = Chart.helpers;

          this.doughnutEscaltionoptions = {
            tooltips: {
              mode: "label",
              callbacks: {
                label: function (tooltipItem, data) {
                  const indice = tooltipItem.index;
                  const label1 =
                    (
                      (data.datasets[0].data[indice] / total_AlertCount) *
                      100
                    ).toFixed(1) + "% ";
                  const label2 = data.labels[indice];
                  return [label1, label2];
                },
              },
              boxWidth: 10,
              padding: 8,
              backgroundColor: "#FFF",
              titleFontSize: 16,
              titleFontColor: "#0066ff",
              bodyFontColor: "#000",
              bodyFontSize: 14,
              displayColors: false,
            },

            legend: {
              display: true,
              position: "right",
              labels: {
                generateLabels: function (chart) {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map(function (label, i) {
                      const meta = chart.getDatasetMeta(0);
                      const ds = data.datasets[0];
                      const arc = meta.data[i];
                      const custom = (arc && arc.custom) || {};
                      const getValueAtIndexOrDefault =
                        theHelp.getValueAtIndexOrDefault;
                      const arcOpts = chart.options.elements.arc;
                      const fill = custom.backgroundColor
                        ? custom.backgroundColor
                        : getValueAtIndexOrDefault(
                            ds.backgroundColor,
                            i,
                            arcOpts.backgroundColor
                          );
                      const stroke = custom.borderColor
                        ? custom.borderColor
                        : getValueAtIndexOrDefault(
                            ds.borderColor,
                            i,
                            arcOpts.borderColor
                          );
                      const bw = custom.borderWidth
                        ? custom.borderWidth
                        : getValueAtIndexOrDefault(
                            ds.borderWidth,
                            i,
                            arcOpts.borderWidth
                          );
                      let lbinfo = 0;
                      if (ds.data[i] === 0) {
                        lbinfo = 0;
                      } else {
                        lbinfo = ds.data[i] / total_AlertCount;
                      }

                      return {
                        // And finally :
                        text: (lbinfo * 100).toFixed(1) + "% - " + label,
                        fillStyle: fill,
                        strokeStyle: stroke,
                        lineWidth: bw,
                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                        index: i,
                      };
                    });
                  }
                  return [];
                },
              },
              onClick: function (e) {
                e.stopPropagation();
              },
            },
          };
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - generateEscaltionChart() - " + err.message
      );
    }
  }

  generateAlertLocationReport(chartRequest) {
    try {
      this.reportService
        .generateAlertLocationReport(chartRequest)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Value: any[]) => {
          this.reportloading = false;
          this.reportselectloading = false;

          this.AlertCount = Value;
          this.CountAlert = [];
          let total_AlertCount = 0;
          let sLabels = "";
          for (
            let nalertInfo = 0;
            nalertInfo < this.AlertCount.length;
            nalertInfo++
          ) {
            total_AlertCount =
              total_AlertCount + this.AlertCount[nalertInfo].AlertCount;
            this.CountAlert.push(this.AlertCount[nalertInfo].AlertCount);
            sLabels += this.AlertCount[nalertInfo].SpaceCategoryName + ",";
          }
          sLabels = sLabels.slice(0, -1);
          Chart.defaults.global.legend.labels.usePointStyle = true;

          this.doughnutLocationDataSet = [this.CountAlert];

          this.doughnutLocationColors = [
            {
              backgroundColor: [
                "#f6921d",
                "#049da7",
                "#005594",
                "#21a2dc",
                "#5a5a5a",
                "#c8c8c8",
                "rgba(129, 199, 111, 1)",
              ],
              borderWidth: 0,
            },
          ];

          this.doughnutLocationLabels = sLabels.split(",");
          const theHelp = Chart.helpers;

          const sTotalCnt = total_AlertCount;
          this.doughnutLocatrionOptions = {
            tooltips: {
              mode: "label",
              callbacks: {
                label: function (tooltipItem, data) {
                  const indice = tooltipItem.index;
                  const label1 =
                    ((data.datasets[0].data[indice] / sTotalCnt) * 100).toFixed(
                      1
                    ) + "% ";
                  const label2 = data.labels[indice];
                  return [label1, label2];
                },
              },
              boxWidth: 10,
              padding: 8,
              backgroundColor: "#FFF",
              titleFontSize: 16,
              titleFontColor: "#0066ff",
              bodyFontColor: "#000",
              bodyFontSize: 14,
              displayColors: false,
            },

            legend: {
              display: true,
              position: "right",
              labels: {
                generateLabels: function (chart) {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map(function (label, i) {
                      const meta = chart.getDatasetMeta(0);
                      const ds = data.datasets[0];
                      const arc = meta.data[i];
                      const custom = (arc && arc.custom) || {};
                      const getValueAtIndexOrDefault =
                        theHelp.getValueAtIndexOrDefault;
                      const arcOpts = chart.options.elements.arc;
                      const fill = custom.backgroundColor
                        ? custom.backgroundColor
                        : getValueAtIndexOrDefault(
                            ds.backgroundColor,
                            i,
                            arcOpts.backgroundColor
                          );
                      const stroke = custom.borderColor
                        ? custom.borderColor
                        : getValueAtIndexOrDefault(
                            ds.borderColor,
                            i,
                            arcOpts.borderColor
                          );
                      const bw = custom.borderWidth
                        ? custom.borderWidth
                        : getValueAtIndexOrDefault(
                            ds.borderWidth,
                            i,
                            arcOpts.borderWidth
                          );
                      let lbinfo = 0;
                      if (ds.data[i] === 0) {
                        lbinfo = 0;
                      } else {
                        lbinfo = ds.data[i] / sTotalCnt;
                      }

                      return {
                        // And finally :
                        text: (lbinfo * 100).toFixed(1) + "% - " + label,
                        fillStyle: fill,
                        strokeStyle: stroke,
                        lineWidth: bw,
                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                        index: i,
                      };
                    });
                  }
                  return [];
                },
              },
              onClick: function (e) {
                e.stopPropagation();
              },
            },
          };
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" +
          " - generateAlertLocationReport() - " +
          err.message
      );
    }
  }

  showDateRange() {
    try {
      const fromdatePass = this.datePipe.transform(this.fromdate, "MMMM d,y");
      const todatePass = this.datePipe.transform(this.todate, "MMMM d,y");
      this.daterange = fromdatePass + "-" + todatePass;

      this.showdaterangeReport(this.daterange);
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - showDateRange() - " + err.message
      );
    }
  }

  showdaterangeReport(daterange): void {
    this.dateDetail = daterange;
    this.generateAlertReportListByFilter();
  }

  exportReportdata(data) {
    try {
      if (!SessionCheckwithStaff(this.router)) {
        return;
      }

      if (isNaN(this.selectedLocationId)) {
        this.selectedLocationId = 0;
      }
      if (this.selectedAlertType == "null") {
        this.selectedAlertType = "0";
      }
      this.ExportFromdate = new Date(this.fromdate);
      this.ExportTodate = new Date(this.todate);
      const eventname: EventNamedetails = {
        EventName: this.exportEventName,
        FromDate: this.ExportFromdate,
        ToDate: this.ExportTodate,
        SelectDate: data,
        AlertType: this.selectedAlertType.toString(),
        Location: this.selectedLocationId,
        PatientId: 0,
        ReportType: Report_Type.Campus_Dashboard,
        ExportData: this.Reportdetails,
      };

      const dialogRef = this.dialog.open(ExportReportComponent, {
        disableClose: true,
        width: "388px",
        height: "165px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: eventname,
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
      });
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - exportReportdata() - " + err.message
      );
    }
  }

  right(str, chr) {
    return str.slice(str.length - chr, str.length);
  }
  ConvertMinutes(num, bNeedColon) {
    this.day = Math.floor(num / 86400); // 60 * 60*24
    num = num - this.day * 86400;
    this.hour = Math.floor(num / 3600);
    num = num - this.hour * 3600;
    this.min = Math.floor(num / 60);
    num = num - this.min * 60;
    num = Math.round(num);
    this.day = this.right("0" + this.day.toString(), 2);
    this.hour = this.right("0" + this.hour.toString(), 2);
    this.min = this.right("0" + this.min.toString(), 2);
    num = this.right("0" + num.toString(), 2);

    if (this.day > 0) {
      if (bNeedColon == "1")
        return this.day + " D " + this.hour + ":" + this.min + ":" + num;
      else
        return (
          this.day + " D, " + this.hour + " H, " + this.min + " M " + num + " S"
        );
    } else if (this.hour > 0) {
      if (bNeedColon == "1") return this.hour + ":" + this.min + ":" + num;
      else return this.hour + " H, " + this.min + " M " + num + " S";
    } else {
      if (bNeedColon == "1") return this.min + ":" + num;
      else return this.min + " M, " + num + " S";
    }
  }

  getErrorMessage(pickerInput: string): string {
    this.hideSearchbtn();
    if (!pickerInput || pickerInput === "") {
      return "Please select date";
    } else if (!moment(pickerInput).isValid()) {
      return "Please enter valid date format MM/DD/YYYY";
    } else {
      return "Please enter current date format MM/DD/YYYY";
    }
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
    if (ReportHeaderComponent.isReportMenuClosed == false) {
      this.reportService.toggleReportMenuNav.emit();
    }
  }

  getAlertTypes() {
    try {
      const alertType: getAlertTypes = {
        authKey: this.authkey,
        reportType: Report_Type.Campus_Dashboard,
      };
      this.reportService
        .getAlertTypes(alertType)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((alertdetails: any[]) => {
          this.Alertdetails = alertdetails;
        });
    } catch (err) {
      throw new Error(
        "selectdate.component.ts" + " - getAlertTypes() - " + err.message
      );
    }
  }

  getAlertLocations() {
    try {
      const alertType: getAlertLocations = {
        authKey: this.authkey,
      };
      this.reportService
        .getAlertLocations(alertType)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((locationdetails: any[]) => {
          this.Locationdetails = locationdetails;
        });
    } catch (err) {
      throw new Error(
        "selectdate.component.ts" + " - getAlertLocations() - " + err.message
      );
    }
  }

  generateAlertReportListByFilter() {
    const fromdatePass = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    const todatePass = this.datePipe.transform(this.todate, "yyyy-MM-dd");
    const dateformat = localStorage.getItem("dateFormat") ?? "";
    let _fromDatePass = "";
    let _toDatePass = "";
    if (fromdatePass != null) _fromDatePass = fromdatePass.toString();

    if (todatePass != null) _toDatePass = todatePass.toString();

    if (isNaN(this.selectedLocationId)) {
      this.selectedLocationId = 0;
    }
    if (this.selectedAlertType == "null") {
      this.selectedAlertType = "0";
    }
    const eventname: AlertFilterinfo = {
      authKey: this.authkey,
      fromDate: _fromDatePass,
      toDate: _toDatePass,
      dateFormat: dateformat,
      alertType: this.selectedAlertType.toString(),
      location: this.selectedLocationId,
      patientId: 0,
      reportType: Report_Type.Campus_Dashboard,
    };
    this.totalAlertCount = 0;
    let First_name = [];
    let Last_name = [];
    let Response_Time;
    let Days, Hours, Minutes, Seconds;

    this.reportService
      .generateAlertReportListByFilter(eventname)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((Reportdetails: any[]) => {
        if (Reportdetails != null && Reportdetails != undefined) {
          Reportdetails.forEach(function (e) {
            if (e.RoomInfo == null) {
              e.RoomInfo = "-";
            }
            if (e.FirstName.includes(",")) {
              First_name = e.FirstName;
              Last_name = e.LastName;
              First_name = e.FirstName.toString().split(",");
              Last_name = e.LastName.toString().split(",");

              e["ResidentName"] = 0;
              for (let i = 0; i < First_name.length; i++) {
                e["ResidentName"] += First_name[i] + " " + Last_name[i] + ",";
                e["toggleFirstName"] += First_name[i] + ",";
                e["toggleLastName"] += Last_name[i] + ",";
              }

              e["ResidentName"] = e["ResidentName"].substring(1);
              e["ResidentName"] = e["ResidentName"].slice(0, -1);

              e["toggleFirstName"] = e["toggleFirstName"].substring(1);
              e["toggleFirstName"] = e["toggleFirstName"].slice(0, -1);

              e["toggleLastName"] = e["toggleLastName"].substring(1);
              e["toggleLastName"] = e["toggleLastName"].slice(0, -1);

              e["toggleResidentName"] = e["ResidentName"];
            } else {
              e["ResidentName"] = e.FirstName + " " + e.LastName;
              e["toggleFirstName"] = e.FirstName;
              e["toggleLastName"] = e.LastName;
              e["toggleResidentName"] = e["ResidentName"];
            }

            if (e.AlertType == enumAlertType.UT) {
              e["ResidentName"] = NewDeviceTypeDesc.UT;
            } else if (
              e.AlertType == enumAlertType.Infrastructure &&
              e.DeviceType == alert_deviceType.UT
            ) {
              e["ResidentName"] = NewDeviceTypeDesc.UT;
            } else if (
              e.DeviceType == alert_deviceType.REX &&
              e.AlertType != enumAlertType.UT &&
              e.AlertType != enumAlertType.thirdparty
            ) {
              e["ResidentName"] = NewDeviceTypeDesc.Rex + "(" + e.TagId +")"
            } else if (
              e.DeviceType == alert_deviceType.HREX &&
              e.AlertType != enumAlertType.UT &&
              e.AlertType != enumAlertType.thirdparty
            ) {
              e["ResidentName"] = NewDeviceTypeDesc.HRex + "(" + e.TagId +")"
            } else if (
              e.DeviceType == alert_deviceType.DC &&
              e.AlertSubType != enumAlertSubType.Tag_In_Field &&
              e.AlertType != enumAlertType.UT &&
              e.AlertType != enumAlertType.thirdparty
            ) {
              e["ResidentName"] = NewDeviceTypeDesc.DC;
            } else if (
              e.DeviceType == alert_deviceType.Pull_Cord &&
              e.AlertType != enumAlertType.UT &&
              e.AlertType != enumAlertType.thirdparty
            ) {
              e["ResidentName"] = NewDeviceTypeDesc.Pullcord;
            } else if (
              e.DeviceType == alert_deviceType.Emitter &&
              e.AlertType != enumAlertType.UT &&
              e.AlertType != enumAlertType.thirdparty
            ) {
              e["ResidentName"] = NewDeviceTypeDesc.Emitter;
            } else if (
              e.DeviceType == alert_deviceType.ADT_LFExciter &&
              e.AlertType != enumAlertType.UT &&
              e.AlertType != enumAlertType.thirdparty
            ) {
              e["ResidentName"] = NewDeviceTypeDesc.LFExiter;
            } else if (
              e.DeviceType == alert_deviceType.DripTray &&
              e.AlertType != enumAlertType.UT &&
              e.AlertType != enumAlertType.thirdparty
            ) {
              e["ResidentName"] = NewDeviceTypeDesc.DripTray;
            }

            e["HideInfoflag"] = 0;

            e["AckedByName"] = e.AckedFirstName + " " + e.AckedLastName;

            if (e.AckedMethod == "Self") e["AckedName"] = "Self";

            if (e.Alert == "Wandering" && e.AlertSubType != 10)
              e["Alert"] = "Roaming";

            if (e.Alert == "DC Not Reporting") e["Alert"] = "SC Not Reporting";

            if (e.ResponseTime == "") e["ResponseTimeSort"] = "";
            else {
              Response_Time = e.ResponseTime.split(":");
              if (Response_Time.length == 4) {
                e["ResponseTime"] =
                  Response_Time[0] +
                  " " +
                  Response_Time[1] +
                  ":" +
                  Response_Time[2] +
                  ":" +
                  Response_Time[3];
                Days = Response_Time[0].split("D");
                Days = Days[0] * 24 * 60 * 60;
                Hours = Response_Time[1] * 60 * 60;
                Minutes = Response_Time[2] * 60;
                Seconds = Days + Hours + Minutes + parseInt(Response_Time[3]);
              } else {
                Hours = Response_Time[0] * 60 * 60;
                Minutes = Response_Time[1] * 60;
                Seconds = Hours + Minutes + parseInt(Response_Time[2]);
              }
              e["ResponseTimeSort"] = Seconds;
            }
          });
        }
        const exportclick =
          this.obj_document.getElementById("btndashboardexport");
        exportclick.className = "clsExportbutton mat-raised-button";
        this.totalAlertCount = Reportdetails.length;
        this.Reportdetails = Reportdetails;
        this.dataSource = new MatTableDataSource(this.Reportdetails);
        this.dataSource.data = this.Reportdetails;
        this.dataSource.paginator = this.paginator;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.sortingDatalist(this.dataSource);
        }, 0);
        this.reportselectloading = false;
      });
  }

  sortingDatalist(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (
        sortHeaderId != "AlertTime" &&
        sortHeaderId != "ResponseTimeSort" &&
        sortHeaderId != "PresenceOn"
      ) {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }

  changeAlertType(alertType) {
    this.enableSearchbtn();
    this.hideExportbtn();
    this.selectedAlertType = alertType;
  }

  changeLocation(RoomId) {
    this.enableSearchbtn();
    this.hideExportbtn();
    this.selectedLocationId = RoomId;
  }
  enableSearchbtn() {
    const saveclick = this.obj_document.getElementById("btndashboardsearch");
    saveclick.className = "clsSearchbutton mat-raised-button";
  }
  hideSearchbtn() {
    const saveclick = this.obj_document.getElementById("btndashboardsearch");
    saveclick.className = "clsSearchbutton mat-raised-button disablebtn";
    const exportclick = this.obj_document.getElementById("btndashboardexport");
    exportclick.className = "clsExportbutton mat-raised-button disablebtn";
  }

  hideExportbtn() {
    const exportclick = this.obj_document.getElementById("btndashboardexport");
    exportclick.className = "clsExportbutton mat-raised-button disablebtn";
  }

  toggleresinfoVisibility(event) {
    let Report_Details;
    Report_Details = this.Reportdetails;
    if (event.checked == true) {
      if (Report_Details != null && Report_Details != undefined) {
        Report_Details.forEach(function (e) {
          e["FirstName"] = "-";
          e["LastName"] = " ";
          e["ResidentName"] = "-";
          e["Name"] = "-";
          e["HideInfoflag"] = 1;
        });
      }
      this.dataSource = new MatTableDataSource(Report_Details);
      this.dataSource.data = Report_Details;
      this.Reportdetails = Report_Details;
      this.dataSource.paginator = this.paginator;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.sortingDatalist(this.dataSource);
      }, 0);
      this.reportselectloading = false;
    } else {
      Report_Details = this.Reportdetails;
      if (Report_Details != null && Report_Details != undefined) {
        Report_Details.forEach(function (e) {
          e["FirstName"] = e.toggleFirstName;
          e["LastName"] = e.toggleLastName;
          e["ResidentName"] = e.toggleResidentName;
          e["Name"] = e.toggleResidentName;
          e["HideInfoflag"] = 0;

          if (e.AlertType == enumAlertType.UT) {
            e["ResidentName"] = NewDeviceTypeDesc.UT;
          } else if (
            e.AlertType == enumAlertType.Infrastructure &&
            e.DeviceType == alert_deviceType.UT
          ) {
            e["ResidentName"] = NewDeviceTypeDesc.UT;
          } else if (
            e.DeviceType == alert_deviceType.REX &&
            e.AlertType != enumAlertType.UT &&
            e.AlertType != enumAlertType.thirdparty
          ) {
            e["ResidentName"] = NewDeviceTypeDesc.Rex;
          } else if (
            e.DeviceType == alert_deviceType.HREX &&
            e.AlertType != enumAlertType.UT &&
            e.AlertType != enumAlertType.thirdparty
          ) {
            e["ResidentName"] = NewDeviceTypeDesc.HRex;
          } else if (
            e.DeviceType == alert_deviceType.DC &&
            e.AlertSubType != enumAlertSubType.Tag_In_Field &&
            e.AlertType != enumAlertType.UT &&
            e.AlertType != enumAlertType.thirdparty
          ) {
            e["ResidentName"] = NewDeviceTypeDesc.DC;
          } else if (
            e.DeviceType == alert_deviceType.Pull_Cord &&
            e.AlertType != enumAlertType.UT &&
            e.AlertType != enumAlertType.thirdparty
          ) {
            e["ResidentName"] = NewDeviceTypeDesc.Pullcord;
          } else if (
            e.DeviceType == alert_deviceType.Emitter &&
            e.AlertType != enumAlertType.UT &&
            e.AlertType != enumAlertType.thirdparty
          ) {
            e["ResidentName"] = NewDeviceTypeDesc.Emitter;
          }
          else if (
            e.DeviceType == alert_deviceType.ADT_LFExciter &&
            e.AlertType != enumAlertType.UT &&
            e.AlertType != enumAlertType.thirdparty
          ) {
            e["ResidentName"] = NewDeviceTypeDesc.LFExiter;
          }
           else if (
            e.DeviceType == alert_deviceType.DripTray &&
            e.AlertType != enumAlertType.UT &&
            e.AlertType != enumAlertType.thirdparty
          ) {
            e["ResidentName"] = NewDeviceTypeDesc.DripTray;
          }
        });
      }
      this.dataSource = new MatTableDataSource(Report_Details);
      this.dataSource.data = Report_Details;
      this.dataSource.paginator = this.paginator;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.sortingDatalist(this.dataSource);
      }, 0);
      this.reportselectloading = false;
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
