import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { ApiService } from "../../../../api.service";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import {
  getDeviceDetailsReq,
  getSiteAuthKeyReuest,
} from "../../technologyoverlay";
import { DeviceTypeDesc } from "../../../../app-settings";
import { SessionCheck, generateAlertMessage } from "./../../../../app-general";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import OlMap from "ol/Map";
import OlVectorLayer from "ol/layer/Vector";
import Draw from "ol/interaction/Draw";
import { Vector as VectorSource } from "ol/source";
import { HostListener } from "@angular/core";
import { AlertSharedService } from "../../../../_services/index";
import { ShareddialogcloseData, getBuilding } from "../../../../api";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-add-device",
  templateUrl: "./add-device.component.html",
  providers: [TechnologyoverlayService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-device.component.scss"],
})
export class AddDeviceComponent implements OnInit, OnDestroy {
  map = new OlMap();
  Buildingdetails: any[];
  CampusId = 0;
  BuildingId = 0;
  CampusName;
  selectedBuilding = 0;
  oldBuilding = 0;
  Buildingsvgid = 0;
  strRoomDescription: string;
  floorId = 0;
  oldemiterId;
  fromYpos;
  imageHeight;
  imageWidth;
  imageExtent;
  unitInfo = [];
  roompolygonPoints;
  deviceTypeName;
  imageDeviceTypeName;
  addimageheight;
  addimagecampusheight;
  image_path = "assets/images/NewDevice/";
  selectedUnit = 0;
  removecursor = 0;
  snaptogridvalue = 0;
  snapGridLayer;
  snapControl;
  device_list_all = "3";
  Device = 0;
  DeviceDetails = [] as any[];
  isSnapGrid = false;
  firstcolumns = 2;
  colsbuild = 9;
  public watcher: Observable<boolean>;
  columns = 5;
  DCTColums = 3;
  minheight = "285px";
  rowHeight = "64px";
  devicerowHeight = "77px";
  screenWidth;
  checkdrawroomflag = 0;
  HReximgDisabled;
  gatewayimgDisabled;
  checkHRexvalue;
  checkgateway;
  checkroomdrawend = false;
  HRexId;
  GatewayId;
  HRexMacId;
  GatewayMacId;
  getHRexMacId;
  GatewayDetails;
  getgatewaymacid;
  getRexvalue;
  getRexMaxvalue;
  selectdefaultMode = "0";
  selectdefaultDCTMode = "0";

  GatewayUrl = this.image_path + "GatewayEnclosure.png";
  ClearRouterImgUrl = this.image_path + "ClearRouter.png";
  ClearRepeaterImgUrl = this.image_path + "ClearRepeater.png";
  DCImgUrl = this.image_path + "SecurityController.png";
  TrulocatorImgUrl = this.image_path + "TruLocator.png";
  MonitorUrl = this.image_path + "CommonArea Locator.png";
  VWUrl = this.image_path + "VirtualWallLocator.png";
  LFImgUrl = this.image_path + "LFAntenna.png";
  pullcordImgUrl = this.image_path + "PullCord.png";
  UTImgUrl = this.image_path + "UniversalTransmitter.png";
  UMImgUrl = this.image_path + "CommonArea Locator.png";
  IntercomUrl = this.image_path + "Intercom.png";

  strLocationName: string;
  strRoomName: string;
  strDeviceId: number;
  strEmitterId: number;

  strMacId: string;
  strLFId: number;
  strLocation: string;
  strDCMacId: string;

  public deviceaddForm: UntypedFormGroup;

  strDeviceDualId: number;

  strRexDeviceId: number;

  checkDCTag = false;
  checkDCLotiering = false;
  checkDCUnauthorizedEgress = false;

  disableDCLotiering = true;
  disableDCUnauthorizedEgress = true;

  checkPCRemovalDetection = false;
  checkPCButtonPress = false;
  checkPullCord = false;
  checkPCMobilityMode = false;

  checkDCT_Tag1 = false;
  checkDCT_Tag2 = false;
  checkDCT_Tag3 = false;

  checkDCT_TagEnable1 = 0;
  checkDCT_TagEnable2 = 0;
  checkDCT_TagEnable3 = 0;

  checkDCT_Ambient1 = false;
  checkDCT_Ambient2 = false;

  selectedAlertState1 = 0;
  selectedAlertState2 = 0;
  selectedAlertState3 = 0;

  disableAlertmessage1 = 0;

  overTempflag = 0;

  probeoverTempflag = 0;
  humidityoverTempflag = 0;

  checkDCT_Probe1 = false;
  checkDCT_Probe2 = false;

  checkDCT_Humidity1 = false;
  checkDCT_Humidity2 = false;

  drawSpaceWithoutDevice = true;
  //Draw feature for Room
  vector = new OlVectorLayer();

  source = new VectorSource({ wrapX: false });

  vectorLayer = new OlVectorLayer({
    source: this.source,
  });
  draw = new Draw({
    source: this.source,
    type: "Polygon",
  });

  //Draw feature for Devices
  tempVectorSource = new VectorSource();

  tempVectorLayer = new OlVectorLayer({
    source: this.tempVectorSource,
  });

  tempgridSource = new VectorSource();

  tempGridLayer = new OlVectorLayer({
    source: this.tempgridSource,
  });
  public showCampus = true;
  public showFloor = false;
  public showTrulocator = false;
  public showLF = false;
  public showRepeater = false;
  public showRouter = false;
  public showpullcord = false;
  public showDC = false;
  public showMonitor = false;
  public showVW = false;
  public showGateway = false;
  public isSaveFloorDevice = false;
  public showUT = false;
  public showUM = false;
  public showIntercom = false;

  public headertext = " Building ";
  ShareddialogcloseDetails: ShareddialogcloseData;
  private _onDestroy: Subject<void> = new Subject<void>();
  DCTEnableInputvalue = [];
  select = null;
  modify = null;
  translate = null;
  select_Feature;
  selectName_Feature;
  selectedFeature;
  selectedNameFeature;
  drawroomselect;
  authkey;
  Breakpoints = Breakpoints;
  voiceLicense;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
  constructor(
    private router: Router,
    private apiService: ApiService,    
    public thisDialogRef: MatDialogRef<AddDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    private alertSharedService: AlertSharedService,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.CampusId = data.CampusId;
      this.CampusName = data.CampusName;
      this.vector = data.VectorDetails;
      this.BuildingId = data.BuildingId;
      this.floorId = data.FloorId;
      this.strLocationName = data.UnitName;
      this.imageHeight = data.ImageHeight;
      this.imageWidth = data.ImageWidth;
      this.checkHRexvalue = data.HRexvalue;
      this.checkgateway = data.Gatewayvalue;
      if (data.MaxRexValue == null) this.getRexvalue = 2;
      else if (data.MaxRexValue < 200) this.getRexvalue = data.MaxRexValue + 1;
      else this.getRexvalue = "";
      const imgwidth = parseFloat(this.imageWidth);
      const imgheight = parseFloat(this.imageHeight);
      this.unitInfo = data.UnitInfo;
      this.addimageheight = Math.round(imgheight);

      this.addimagecampusheight = Math.round(imgheight);
      this.imageHeight = imgheight;
      this.imageWidth = imgwidth;
      this.selectedFeature = data.SelectedFeature;

      this.breakpoint$.subscribe(() => this.breakpointChanged());      
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - constructor() - " + err.message
      );
    }        
  }
  
  breakpointChanged() {    
    const isFirefox = navigator.userAgent.toLowerCase();
    if (isFirefox.indexOf("firefox") > -1) {
      if (window.innerWidth > 1280) {
        this.columns = 3;        
      } else if (
        window.innerWidth > 960 &&
        window.innerWidth <= 1280
      ) {
        this.columns = 5;        
      } else if (this.screenWidth > 600 && this.screenWidth <= 960) {
        this.columns = 2;        
      } else if (this.screenWidth < 600) {
        this.columns = 2;        
      }
    }
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 5;         
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small) || this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 1;      
    }        
  }

  ngOnInit() {
    this.voiceLicense = localStorage.getItem("voice_license"); 
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.GatewayMacId = localStorage.getItem("applianceSN");
    this.drawroomselect = 0;
    this.onResize();
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.deviceaddForm.controls).forEach((field) => {
        // {1}
        const control = this.deviceaddForm.get(field); // {2}
        const deviceAddFormId = document.getElementById(event.target.id);
        if (deviceAddFormId != null) {
          if (field == deviceAddFormId.getAttribute("formControlName")) {
            if (field == "temperature1DCT") this.overTempflag = 1;
            else if (field == "temperature2DCT") this.overTempflag = 2;

            if (field == "probetemperature1DCT") this.probeoverTempflag = 1;
            else if (field == "probetemperature2DCT")
              this.probeoverTempflag = 2;

            if (field == "Humiditytemperature1DCT")
              this.humidityoverTempflag = 1;
            else if (field == "Humiditytemperature2DCT")
              this.humidityoverTempflag = 2;
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    if (window.innerWidth > 960 && window.innerWidth <= 1280) {
      this.columns = 5;
      this.firstcolumns = 2;
      this.DCTColums = 3;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 960) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.DCTColums = 1;
    }
    this.screenWidth = window.innerWidth;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      this.devicerowHeight = "72.5px";
    }
    if (this.screenWidth <= 425) {
      this.devicerowHeight = "90px";
    } else if (isIEOrEdge == false) {
      this.devicerowHeight = "67px";
    }
  }

  // close add device Dialog
  public onCloseDeviceDialog = () => {
    try {
      this.drawroomselect = 0;
      this.thisDialogRef.close("Cancel");

      if (!SessionCheck(this.router)) {
        return;
      }
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - onCloseDeviceDialog() - " + err.message
      );
    }
  };

  getDeviceDetails() {
    try {
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.CampusId,
        floorId: this.floorId,
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: 0,
        isIndoor: 1,
        isNeedRoomonly: 0,
        isNeedIntercom: parseInt(this.voiceLicense)
      };
      // const _dialog = this.dialog;
      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          if (DeviceDetails != null && DeviceDetails != undefined) {
            this.DeviceDetails = DeviceDetails["apiReturn"];
          }
        });
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - getDeviceDetails() - " + err.message
      );
    }
  }

  getHRexdetails() {
    try {
      const hrexdetails: getSiteAuthKeyReuest = {
        authKey: this.authkey,
      };
      this.technologyoverlayService
        .getHRexdetails(hrexdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((HRexDetails: any[]) => {
          if (HRexDetails != null && HRexDetails != undefined) {
            this.getHRexMacId = HRexDetails;
          }
        });
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - getHRexdetails() - " + err.message
      );
    }
  }

  //load Building list
  getBuildingdetails() {
    try {
      const CampusId = this.CampusId;

      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: CampusId,
      };

      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          if (BuildingDetails != null && BuildingDetails != undefined) {
            this.Buildingdetails = BuildingDetails["apiReturn"];
            this.selectedBuilding = this.Buildingdetails[0].BuildId;
            this.Buildingsvgid = this.Buildingdetails[0].svgId;
            this.strRoomDescription = this.Buildingdetails[0].Description;
          }
        });
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - getBuildingdetails() - " + err.message
      );
    }
  }

  //load Building list
  selectUnit(value) {
    try {
      this.selectedUnit = value;
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - selectUnit() - " + err.message
      );
    }
  }

  CheckRoomDrawn() {
    let roomDrwan = false;
    if (this.vector.getSource() != null) {
      if (this.vector.getSource().getFeatures().length == 0) {
        roomDrwan = true;
      } else {
        // Get the array of features
        const features = this.vector.getSource().getFeatures();
        let vertices;
        let polyLine = [];

        if (features) {
          // Go through this array and get coordinates of their geometry.
          features.forEach(function (feature) {
            vertices = feature.getGeometry().getCoordinates();
          });
        }

        vertices[0].pop();
        polyLine = vertices[0];

        for (let i = 0; i < polyLine.length; i++) {
          const xi = polyLine[i][0],
            yi = polyLine[i][1];
          if (
            xi < 0 ||
            yi < 0 ||
            xi > this.imageExtent[2] ||
            yi > this.imageExtent[3]
          ) {
            roomDrwan = true;
            break;
          }
        }
      }
    }
    return roomDrwan;
  }

  CheckPointInMap() {
    let roomDrwan = false;
    if (this.tempVectorLayer.getSource() != null) {
      if (this.tempVectorLayer.getSource().getFeatures().length == 0) {
        roomDrwan = true;
      } else {
        // Get the array of features
        const features = this.tempVectorLayer.getSource().getFeatures();
        let vertices;

        if (features) {
          // Go through this array and get coordinates of their geometry.
          features.forEach(function (feature) {
            vertices = feature.getGeometry().getCoordinates();
          });
        }
        if (vertices.length > 0) {
          if (
            vertices[0] < 0 ||
            vertices[1] < 0 ||
            vertices[0] > this.imageExtent[2] ||
            vertices[1] > this.imageExtent[3]
          ) {
            roomDrwan = true;
            this.tempVectorLayer.getSource().clear();
          }
        } else {
          roomDrwan = true;
        }
      }
    }
    return roomDrwan;
  }

  DrawDCOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = true;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.DC;
      this.imageDeviceTypeName = "SecurityController@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawDC() - " + err.message
      );
    }
  }

  DrawTruLocator() {
    try {
      this.showTrulocator = true;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.Emitter;
      this.imageDeviceTypeName = "TruLocator@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawTruLocator() - " + err.message
      );
    }
  }

  DrawGateWayOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = true;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.Gateway;
      this.imageDeviceTypeName = "GatewayEnclosure@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawGateWayOpt() - " + err.message
      );
    }
  }

  DrawClearRouterOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = true;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.HRex;
      this.imageDeviceTypeName = "ClearRouter@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawClearRouterOpt() - " + err.message
      );
    }
  }

  DrawClearRepeaterOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = true;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.Rex;
      this.imageDeviceTypeName = "ClearRepeater@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawClearRepeaterOpt() - " + err.message
      );
    }
  }
  DrawMonitorOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = true;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.Monitor;
      this.imageDeviceTypeName = "CommonArea Locator@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawMonitorOpt() - " + err.message
      );
    }
  }

  DrawVWOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = true;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.VW;
      this.imageDeviceTypeName = "VirtualWallLocator@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawVWOpt() - " + err.message
      );
    }
  }

  DrawLFOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = true;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.LF;
      this.imageDeviceTypeName = "LFAntenna@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawLFOpt() - " + err.message
      );
    }
  }

  DrawPullcordOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = true;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.Pullcord;
      this.imageDeviceTypeName = "PullCord@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawPullcordOpt() - " + err.message
      );
    }
  }

  DrawUTOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = true;
      this.showUM = false;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.UT;
      this.imageDeviceTypeName = "UniversalTransmitter@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawUTOpt() - " + err.message
      );
    }
  }

  DrawUMOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = true;
      this.showIntercom = false;

      this.deviceTypeName = DeviceTypeDesc.UM;
      this.imageDeviceTypeName = "CommonArea Locator@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawUM() - " + err.message
      );
    }
  }
  DrawIntercomOpt() {
    try {
      this.showTrulocator = false;
      this.showRepeater = false;
      this.showLF = false;
      this.showRouter = false;
      this.showpullcord = false;
      this.showDC = false;
      this.showMonitor = false;
      this.showVW = false;
      this.showGateway = false;
      this.showUT = false;
      this.showUM = false;
      this.showIntercom = true;

      this.deviceTypeName = DeviceTypeDesc.Intercom;
      this.imageDeviceTypeName = "Intercom@2x.png";
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawUM() - " + err.message
      );
    }
  }
  clkAddDevicetoMap() {
    if (this.deviceTypeName == undefined) {
      generateAlertMessage(this.dialog, "Please Select Device");
      return false;
    } else {
      const selectDevicedetails = {
        selectImageDeviceTypeName: this.imageDeviceTypeName,
        selectDeviceType: this.deviceTypeName,
      };
      this.thisDialogRef.close(selectDevicedetails);
    }
  }

  onKeyPress(): void {
    try {
      if (
        this.deviceaddForm.value.devicedualid == undefined ||
        this.deviceaddForm.value.devicedualid == ""
      ) {
        this.checkDCLotiering = false;
        this.checkDCUnauthorizedEgress = false;
        this.disableDCLotiering = true;
        this.disableDCUnauthorizedEgress = true;
      } else {
        this.disableDCLotiering = false;
        this.disableDCUnauthorizedEgress = false;
      }
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - onKeyPress() - " + err.message
      );
    }
  }

  togglerDCTag(e) {
    if (e.checked == true) {
      this.checkDCTag = true;
    } else {
      this.checkDCTag = false;
    }
  }

  togglerDCLotiering(e) {
    if (e.checked == true) {
      this.checkDCLotiering = true;
    } else {
      this.checkDCLotiering = false;
    }
  }

  togglerDCUnauthorizedEgress(e) {
    if (e.checked == true) {
      this.checkDCUnauthorizedEgress = true;
    } else {
      this.checkDCUnauthorizedEgress = false;
    }
  }

  togglerPCJackRemovalDetection(e) {
    if (e.checked == true) {
      this.checkPCRemovalDetection = true;
    } else {
      this.checkPCRemovalDetection = false;
    }
  }

  togglerPCJackButtonPress(e) {
    if (e.checked == true) {
      this.checkPCButtonPress = true;
    } else {
      this.checkPCButtonPress = false;
    }
  }

  togglerPullCord(e) {
    if (e.checked == true) {
      this.checkPullCord = true;
    } else {
      this.checkPullCord = false;
    }
  }

  selectPCMode(event) {
    this.selectdefaultMode = event;
  }

  togglerDCT_Tag(id, e) {
    if (e.checked == true && id == 1) {
      this.checkDCT_Tag1 = true;
    } else if (e.checked == true && id == 2) {
      this.checkDCT_Tag2 = true;
    } else if (e.checked == true && id == 3) {
      this.checkDCT_Tag3 = true;
    } else if (e.checked == false && id == 1) {
      this.checkDCT_Tag1 = false;
      this.selectedAlertState1 = 0;
      this.deviceaddForm.controls["alertmessage1DCT"].reset("");
    } else if (e.checked == false && id == 2) {
      this.checkDCT_Tag2 = false;
      this.selectedAlertState2 = 0;
      this.deviceaddForm.controls["alertmessage2DCT"].reset("");
    } else if (e.checked == false && id == 3) {
      this.checkDCT_Tag3 = false;
      this.selectedAlertState3 = 0;
      this.deviceaddForm.controls["alertmessage3DCT"].reset("");
    }
  }

  togglerDCT_Ambient(id, event) {
    if (event.checked == true && id == 1) {
      this.checkDCT_Ambient1 = true;
    } else if (event.checked == true && id == 2) {
      this.checkDCT_Ambient2 = true;
    } else if (event.checked == false && id == 1) {
      this.checkDCT_Ambient1 = false;
      this.deviceaddForm.controls["temperature1DCT"].reset("");
      this.deviceaddForm.controls["alertmessage4DCT"].reset("");
    } else if (event.checked == false && id == 2) {
      this.checkDCT_Ambient2 = false;
      this.deviceaddForm.controls["temperature2DCT"].reset("");
      this.deviceaddForm.controls["alertmessage5DCT"].reset("");
    }
  }

  selectDCTAlertState(ID, Event) {
    if (ID == 1) this.selectedAlertState1 = Event;
    else if (ID == 2) this.selectedAlertState2 = Event;
    else if (ID == 3) this.selectedAlertState3 = Event;
  }

  togglerDCT_Probe(id, event) {
    if (event.checked == true && id == 1) {
      this.checkDCT_Probe1 = true;
    } else if (event.checked == true && id == 2) {
      this.checkDCT_Probe2 = true;
    } else if (event.checked == false && id == 1) {
      this.checkDCT_Probe1 = false;
      this.deviceaddForm.controls["probetemperature1DCT"].reset("");
      this.deviceaddForm.controls["alertmessage6DCT"].reset("");
    } else if (event.checked == false && id == 2) {
      this.checkDCT_Probe2 = false;
      this.deviceaddForm.controls["probetemperature2DCT"].reset("");
      this.deviceaddForm.controls["alertmessage7DCT"].reset("");
    }
  }

  togglerDCT_Humidity(id, event) {
    if (event.checked == true && id == 1) {
      this.checkDCT_Humidity1 = true;
    } else if (event.checked == true && id == 2) {
      this.checkDCT_Humidity2 = true;
    } else if (event.checked == false && id == 1) {
      this.checkDCT_Humidity1 = false;
      this.deviceaddForm.controls["Humiditytemperature1DCT"].reset("");
      this.deviceaddForm.controls["alertmessage8DCT"].reset("");
    } else if (event.checked == false && id == 2) {
      this.checkDCT_Humidity2 = false;
      this.deviceaddForm.controls["Humiditytemperature2DCT"].reset("");
      this.deviceaddForm.controls["alertmessage9DCT"].reset("");
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
