import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import { getSpaceCategory, updateTemplateSpace } from "../../technologyoverlay";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../app-general";
import { enumSpaceCategory, enumEnableDisable,appsettings } from "../../../../app-settings";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-floorspace",
  templateUrl: "./edit-floorspace.component.html",
  styleUrls: ["./edit-floorspace.component.scss"],
})
export class EditFloorspaceComponent implements OnInit {
  public spaceeditTempateForm: UntypedFormGroup;
  public floorTempateForm: UntypedFormGroup;
  selectedTempId;
  FloorInfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  SpaceInfo;
  public watcher: Observable<boolean>;
  firstcolumns = 2;
  rowHeight = "79px";
  checkfloorinfo = false;
  Templatedetails;
  Tempflag = 0;
  Campusdetails;
  Buildingdetails;
  selectedCampusId;
  selectBuildId;
  selTemp_Id;
  Spacecategory_details;
  selectCategoryId = 0;
  CampusId;
  BuildingId;
  FloorId;
  UnitId;
  checkResOffLimits = false;
  tempcheckSpaceCategory = 0;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditFloorspaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,    
    private breakpointObserver: BreakpointObserver  
  ) {
    this.selectCategoryId = data.SpaceCategoryId;
    this.CampusId = data.CampusId;
    this.BuildingId = data.BuildId;
    this.FloorId = data.TempFloorId;
    this.UnitId = data.TempUnitId;

    if (data.isResidentOffLimit == 1) this.checkResOffLimits = true;
    else this.checkResOffLimits = false;

    if (
      this.selectCategoryId == enumSpaceCategory.StaffArea ||
      this.selectCategoryId == enumSpaceCategory.ActivityArea ||
      this.selectCategoryId == enumSpaceCategory.DiningArea ||
      this.selectCategoryId == enumSpaceCategory.CommonArea
    ) {
      this.tempcheckSpaceCategory = 1;
    }

    if (data.Description == "null") {
      data.Description = "";
    }
    this.breakpoint$.subscribe(() => this.breakpointChanged());   
  }
  
  breakpointChanged() {        
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {      
      this.firstcolumns = 2;      
    }       
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {      
      this.firstcolumns = 2;      
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {      
      this.firstcolumns = 1;      
    }     
  }  

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.spaceeditTempateForm = new UntypedFormGroup({
      floorname: new UntypedFormControl(""),
      tempID: new UntypedFormControl(""),
      unitID: new UntypedFormControl(""),
      unitname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      category: new UntypedFormControl("", [Validators.required]),
      description: new UntypedFormControl("", [
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
    const getFormFloorName = this.spaceeditTempateForm.get("floorname");
    if(getFormFloorName != null)
      getFormFloorName.disable();
    this.getSpaceCategory();
  }

  getSpaceCategory() {
    try {
      const spaceitem: getSpaceCategory = {
        authKey: this.authkey,
        categoryId: 0,
      };
      this.technologyoverlayService
        .getSpaceCategory(spaceitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((spacedetails: any[]) => {
          this.Spacecategory_details = spacedetails;
        });
    } catch (err) {
      throw new Error(
        "edit-floorspace.component.ts" +
          " - getSpaceCategory() - " +
          err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.spaceeditTempateForm.controls).forEach((field) => {
        // {1}
        const control = this.spaceeditTempateForm.get(field); // {2}
        const getTargetId = document.getElementById(event.target.id);
        if(getTargetId != null)
        {
          if (field == getTargetId.getAttribute("formControlName")) {
              if(control != null)
              {
                control.setValue(changedValue);
                control.markAsTouched({ onlySelf: true }); // {3}
              }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-floorspace.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  selectSpaceCategory(categoryId) {    
    this.selectCategoryId = categoryId;
  }

  public updateTemplateSpaceInfo = (spaceFormValue) => {
    try {
      let resofflimits = 0;

      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();

      let _Description = "";
      if (spaceFormValue.description != "")
        _Description = spaceFormValue.description.replace(/\s+/g, " ").trim();
      else _Description = "";

      if (this.checkResOffLimits == true) resofflimits = 1;
      else resofflimits = 0;

      if (this.spaceeditTempateForm.valid) {       
        this.editFloorSpaceEnableDisable(enumEnableDisable.Disable);
          const updateTemplateSpaceInfo: updateTemplateSpace = {
            authKey: this.authkey,
            templateId: parseInt(spaceFormValue.tempID),
            spaceId: parseInt(spaceFormValue.unitID),
            spaceName: spaceFormValue.unitname.replace(/\s+/g, " ").trim(),
            spaceDescription: _Description, //spaceFormValue.description.replace(/\s+/g, ' ').trim(),
            spaceCategoryId: this.selectCategoryId,
            residentOffLimits: resofflimits,
          };

          this.technologyoverlayService
            .updateTemplateSpaceInfo(updateTemplateSpaceInfo)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: any[]) => {
              if (apiresponse["apiReturn"] == "Success") {
                generateAlertMessage(this.dialog, "Space edited successfully.");
                this.thisDialogRef.close();
              } else if (apiresponse["apiReturn"] == "Fail") {
                generateAlertMessage(this.dialog, "Space edited failed.");
                this.thisDialogRef.close();
              } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
                this.thisDialogRef.close("close");
                AuthFailedMessage(this.dialog, this.router);
                return;
              } else if (apiresponse["apiReturn"] == "spacename") {
                generateAlertMessage(
                  this.dialog,
                  "Please enter Zone Space Name"
                );                
                this.editFloorSpaceEnableDisable(enumEnableDisable.Enable);
                return;
              } else {
                generateAlertMessage(
                  this.dialog,
                  "Zone Space name '" +
                    apiresponse["apiReturn"][0].UnitName +
                    "' already exists."
                );                
                this.editFloorSpaceEnableDisable(enumEnableDisable.Enable);
              }
            });        
      }
    } catch (err) {
      throw new Error(
        "edit-floorspace.component.ts" +
          " - updateTemplateSpaceInfo() - " +
          err.message
      );
    }
  };
  
  editFloorSpaceEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnfloorspacesaveclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsfloorspacesavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsfloorspacesavebutton mat-raised-button disablebtn";
  }

  toggler_ResOffLimits(e) {
    if (e.checked == true) {
      this.checkResOffLimits = true;
    } else {
      this.checkResOffLimits = false;
    }
  }
}
