<perfect-scrollbar style="max-width: 1200px; max-height: 230px;">
  <div class="clsaddfilter">
    <div class="addfilter-title">
      <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addfilterHeader"
        style="margin: 0 0 10px 11px;">Advanced Filters
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span>
      </h3>
      <mat-divider></mat-divider>
    </div>

    <div>
      <div style="padding:24px 0px 0px 30px;">
        <label>Filters must be selected in order to begin search.</label>
      </div>

      <form style="padding-left:24px" [formGroup]="deviceFilterForm" autocomplete="off" novalidate
        (ngSubmit)="FilterDevicedetails(deviceFilterForm.value)">
        <div class="clssearch" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="15px">

          <mat-form-field appearance="outline" floatLabel="auto" class="clsfiltermatformfield">
            <mat-select formControlName="campus" placeholder="Select Campus"
              (selectionChange)="getBuildingDetails($event.value)" style="height: 25px;">
              <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId"
                (click)="changecampus(Campus.CampusId);">
                {{Campus.CampusName}}
              </mat-option>
            </mat-select>
            <mat-error>Please select Campus</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="auto" class="clsfiltermatformfield">
            <mat-select formControlName="building" placeholder="Select Building"
              (selectionChange)="getFloorDetails($event.value)" style="height: 25px;">
              <mat-option *ngFor="let Building of Buildingdetails" [value]="Building.BuildId"
                (click)="changebuild(Building.BuildId)">
                {{Building.BuildingName}}
              </mat-option>
            </mat-select>
            <mat-error>
              Please select Building
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="auto" class="clsfiltermatformfield">
            <mat-select formControlName="floor" placeholder="Select Zone"
              (selectionChange)="getUnitDetails($event.value)" style="height: 25px;">
              <mat-option *ngFor="let Floor of Floordetails" [value]="Floor.ZoneId"
                (click)="changefloor(Floor.ZoneId)">
                {{Floor.ZoneName}}
              </mat-option>
            </mat-select>
            <mat-error>Please select Zone</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="auto" class="clsfiltermatformfield">
            <mat-select placeholder="Select Space" formControlName="unit" style="height: 25px;">
              <mat-option *ngFor="let Unit of Unitdetails" [value]="Unit.UnitId" (click)="changeunit(Unit)">
                {{Unit.UnitName}}
              </mat-option>
            </mat-select>
            <mat-error>Please select Space</mat-error>
          </mat-form-field>

          <mat-dialog-actions class="clsbtndevicefilter">
            <button class="clsdevicesearch" mat-raised-button tabindex="15">Search</button>
          </mat-dialog-actions>

        </div>

      </form>
    </div>

  </div>
</perfect-scrollbar>