import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import Popup from "ol-popup";
import { click } from "ol/events/condition.js";
import Select from "ol/interaction/Select.js";
import {
  alertinfo,
  infoForFloor,
  getNotifybyAlert,
  getNearestStaff,
} from "./home";
import { HomeService } from "./home.service";
import {
  appsettings,
  enumAlertStatus,
  enumAlertAckStatus,
  enumRequestFrom,
  alert_raisedon,
  enumCheckResidenStaff,
  enumAlertType,
  fillcolorPolygon,
  NewDeviceTypeDesc,DeviceTypeDesc 
} from "../../app-settings";
import { AlertSharedService } from "../../_services/index";
import {
  SharedAlertData,
  SharedFloorAlertData,
  SharedData,
  SharedAlertLoadData,
  SharedCheckAlertData,
  ShareInfraopenMsg,
  getAlertData,
  cancelAlert,
} from "../../api";
import { AlertnotificationComponent } from "./alertnotification/alertnotification.component";
import { MapnotificationComponent } from "./mapnotification/mapnotification.component";
import { AccesscodeComponent } from "../../accesscode/accesscode.component";
import { ApiService } from "../../api.service";
import {
  alerticonstyle,
  searchalerticonstyle,
  alerticonred,
  label_Polygon,    
} from "../../map-general";
import { getDeviceDetailsReq } from "../technologyoverlay/technologyoverlay";
import {
  get_geojsonObject_campus,
  get_geojsonObject_floor,
  map_creation,
  fillcolor_style_Home,
  fillcolor_style_Home_Space
} from "../../map-general";
import {
  LoadBuildingSvg,
  maponclicked,
  AddBuildingLayer,
  ShowPopupManual,
  getCheckInExtent,
  CheckAlertByDeviceTypeId,
  getboxFromYpos,getDevicetype_image,calculatexycoordinates,
  AuthFailedMessage
} from "../../app-general";
import {
  map_widthandheight,
  LoadFloorSvg,
  AddFloorLayer,
  ShowFloorPopupManual,
  groupBy,
  set_SharedDetails,
  SessionCheck,
  RefreshIconDetails,
  checkStaffData,
  generateAlertMessage,
} from "../../app-general";
import { RollbarService } from "../../rollbar";
import * as Rollbar from "rollbar";
import { MainLayoutService } from "../../main-layout/main-layout.service";
import { SidenavComponent } from "../../main-layout/sidenav/sidenav.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmMessageComponent } from "../../confirm-message/confirm-message.component";
import { HomeHeaderComponent } from "../home/home-header/home-header.component";
import { CloseLocationChangeSocket } from "../../app.component";
import OlPoint from 'ol/geom/Point'
import OlFeature from 'ol/Feature';
import OlVectorLayer from 'ol/layer/Vector';
import OlVectorSource from 'ol/source/Vector';
import Polygon from 'ol/geom/Polygon';


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  SharedFloorAlertDetails: SharedFloorAlertData;
  SharedDetails: SharedData;
  SharedAlertLoadDetails: SharedAlertLoadData;
  CheckAlertData: SharedCheckAlertData;
  ShareInfraopenDetails: ShareInfraopenMsg;
  Isinfra = 0;

  CampusImageURL = "";
  BuildingImageURL = "";
  FloorImageURL = "";
  ImageUrl = "";
  ImageWidth = "";
  ImageHeight = "";
  SvgImageUrl = "";
  SvgInfo = null;
  SpaceInfo = null;
  FloorInfo = null;
  public MCampusId = 0;
  public MBuildingId = 0;
  public MFloorId = 0;
  public MSubZoneId = 0;
  AlertId = "";
  ResidentId = "";
  AlertStatus = 0;
  StaffAlert = 0;
  InfraAlert = 0;
  MMapDataDetails = "0_0_0_0_0_0_0_0";
  Map_array_length = 7;
  Map_image_path = "assets/images/map/";
  CampusFulldetails = null;
  imgheight = 0;
  imgwidth = 0;

  alertData: alertinfo[];
  infoForFloorData: infoForFloor[];
  alertinfofromshared: SharedAlertData[];
  Imgurl: string = appsettings.IMAGE_FOLDER;
  residentImgUrl: string = appsettings.RESIDENT_FOLDER;
  staffImgUrl: string = appsettings.STAFF_FOLDER;
  alertinfo;
  Residents;
  notifiedinfo;
  CZoomLevel;
  CRotateLevel;
  pollingData: any;
  TranslateX;
  TranslateY;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private homeService: HomeService,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public mainLayoutService: MainLayoutService,
    public thisDialogRef: MatDialogRef<HomeComponent>
  ) {
    try {
      CloseLocationChangeSocket();
      homeService.SubscribeforLocationChangeData();
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - constructor() - " + err.message
      );
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.alertSharedService
        .currentSharedAlert()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedAlertDetails: SharedAlertData[]) => {
          this.alertinfofromshared = SharedAlertDetails;
          this.alertinfo = SharedAlertDetails;
        });
      this.alertSharedService
        .currentsharedInfraAlert()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ShareInfraopenDetails: any) => {
          this.ShareInfraopenDetails = ShareInfraopenDetails;
          if (this.ShareInfraopenDetails != undefined) {
            this.Isinfra = this.ShareInfraopenDetails.IsfraOpen;
          }
        });
      this.alertSharedService
        .currentShared()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedDetails: SharedData) => {
          this.SharedDetails = SharedDetails;
        });
      this.alertSharedService
        .currentsharedFloorAlert()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedFloorAlertDetails: SharedFloorAlertData) => {
          this.SharedFloorAlertDetails = SharedFloorAlertDetails;
        });

      this.getStaffById();
    } catch (err) {
      throw new Error("home.component.ts" + " - ngOnInit() - " + err.message);
    }
  }

  getStaffById() {
    checkStaffData(this.apiService, this.alertSharedService);
  }

  selectFloorInfoFull(floorInfo,bNeedBuildingOutline,bNeedApprOutline) {
    try {
      this.apiService
        .getDeviceDetails(floorInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((infoForFloordetails: any[]) => {
          this.infoForFloorData = infoForFloordetails["apiReturn"];
          this.FloorInfo = this.infoForFloorData;
          this.DrawMapCall(bNeedBuildingOutline,bNeedApprOutline);
        });
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - selectFloorInfoFull() - " + err.message
      );
    }
  }
  Chk_alertnotification = 0;
  showalertnotification() {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      const sel_CampusId =
        this.document.getElementById("spn_CampusId").innerHTML;
      const sel_BuildingId =
        this.document.getElementById("spn_BuildingId").innerHTML;
      let sel_FloorId = this.document.getElementById("spn_FloorId").innerHTML;
      let sel_RoomId = this.document.getElementById("spn_RoomId").innerHTML;
      const sel_AlertId = this.document.getElementById("spn_AlertId").innerHTML;
      let sel_AlertType =
        this.document.getElementById("spn_AlertType").innerHTML;
      let sel_AlertResStaff =
        this.document.getElementById("spn_AlertResStaff").innerHTML;
      let sel_DeviceType =
        this.document.getElementById("spn_Devicetype").innerHTML;
      let sel_DeviceSubType =
        this.document.getElementById("spn_DeviceSubtype").innerHTML;
      let sel_LocationType =
        this.document.getElementById("spn_Locationtype").innerHTML;
      let sel_Staffdata;
      let sel_NearestStaffdata;

      this.notifiedinfo = this.alertinfo.filter(function (e) {
        return e.AlertId == parseInt(sel_AlertId);
      });

      if (sel_FloorId != "null") sel_FloorId = parseInt(sel_FloorId);
      else sel_FloorId = 0;

      if (sel_RoomId != "null") sel_RoomId = parseInt(sel_RoomId);
      else sel_RoomId = 0;
      
      if (sel_AlertType != "") sel_AlertType = parseInt(sel_AlertType);
      else sel_AlertType = 0;
      
      if (sel_AlertResStaff != "") sel_AlertResStaff = parseInt(sel_AlertResStaff);
      else sel_AlertResStaff = 0;
      
      if (sel_DeviceType != "") sel_DeviceType = parseInt(sel_DeviceType);
      else sel_DeviceType = 0;
      
      if (sel_DeviceSubType != "") sel_DeviceSubType = parseInt(sel_DeviceSubType);
      else sel_DeviceSubType = 0;
      
      if (sel_LocationType != "") sel_LocationType = parseInt(sel_LocationType);
      else sel_LocationType = 0;
      
      if (this.Chk_alertnotification == 0) {
        this.Chk_alertnotification = 1;        
        const Notifyitem: getNotifybyAlert = {
          authKey: this.authkey,
          alertType: parseInt(sel_AlertType),
          alertId: parseInt(sel_AlertId),
          isResident: parseInt(sel_AlertResStaff),
          deviceSubType: parseInt(sel_DeviceSubType),
          deviceType: parseInt(sel_DeviceType),
          nLocationZone: parseInt(sel_LocationType),
        };
        this.homeService
          .getNotificationByAlert(Notifyitem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((Staffdata: any[]) => {
            sel_Staffdata = Staffdata["apiReturn"];
            const NearestStaffitem: getNearestStaff = {
              authKey: this.authkey,
              roomId: sel_RoomId,
              floorId: sel_FloorId,
            };
            this.homeService
              .getNearestStaffForAlert(NearestStaffitem)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((NearestStaffdata: any[]) => {
                sel_NearestStaffdata = NearestStaffdata;
                const NotifyData = {
                  AlertData: this.notifiedinfo,
                  CampusId: parseInt(sel_CampusId),
                  BuildingId: parseInt(sel_BuildingId),
                  FloorId: parseInt(sel_FloorId),
                  RoomId: parseInt(sel_RoomId),
                  AlertId: parseInt(sel_AlertId),
                  AlertType: parseInt(sel_AlertType),
                  AlertResStaff: parseInt(sel_AlertResStaff),
                  DeviceType: parseInt(sel_DeviceType),
                  DeviceSubType: parseInt(sel_DeviceSubType),
                  LocationType: parseInt(sel_LocationType),
                  Staffdata: sel_Staffdata,
                  NearestStaffdata: sel_NearestStaffdata,
                };

                const dialogRef = this.dialog.open(AlertnotificationComponent, {
                  disableClose: true,
                  width: "450px",
                  height: "auto",
                  panelClass: "myapp-no-padding-dialog",
                  autoFocus: false,
                  data: NotifyData,
                });

                dialogRef.afterClosed().subscribe((result) => {
                  if (result == "CanceledAlert") {
                    console.log(result);
                  }
                  this.Chk_alertnotification = 0;
                });
              });
          });
      }
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - showalertnotification() - " + err.message
      );
    }
  }
  Chk_mapnotification = 0;
  public showmapnotification() {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      const sel_CampusId =
        this.document.getElementById("spn_MapCampusId").innerHTML;
      const sel_BuildingId =
        this.document.getElementById("spn_MapBuildingId").innerHTML;
      let sel_FloorId =
        this.document.getElementById("spn_MapFloorId").innerHTML;
      let sel_RoomId = this.document.getElementById("spn_MapRoomId").innerHTML;
      const sel_AlertId =
        this.document.getElementById("spn_MapAlertId").innerHTML;
      let sel_AlertType =
        this.document.getElementById("spn_MapAlertType").innerHTML;
        let sel_AlertResStaff = this.document.getElementById(
        "spn_MapAlertResStaff"
      ).innerHTML;
      let sel_DeviceType =
        this.document.getElementById("spn_MapDevicetype").innerHTML;
      let sel_DeviceSubType = this.document.getElementById(
        "spn_MapDeviceSubtype"
      ).innerHTML;
      let sel_LocationType = this.document.getElementById(
        "spn_MapLocationtype"
      ).innerHTML;

      let sel_Staffdata;
      let sel_NearestStaffdata;
      const width = window.innerWidth;

      this.notifiedinfo = this.alertinfo.filter(function (e) {
        return e.AlertId == parseInt(sel_AlertId);
      });

      if (sel_FloorId != "null") sel_FloorId = parseInt(sel_FloorId);
      else sel_FloorId = 0;

      if (sel_RoomId != "null") sel_RoomId = parseInt(sel_RoomId);
      else sel_RoomId = 0;
      if (sel_AlertType != "") sel_AlertType = parseInt(sel_AlertType);
      else sel_AlertType = 0;
      
      if (sel_AlertResStaff != "") sel_AlertResStaff = parseInt(sel_AlertResStaff);
      else sel_AlertResStaff = 0;
      
      if (sel_DeviceType != "") sel_DeviceType = parseInt(sel_DeviceType);
      else sel_DeviceType = 0;
      
      if (sel_DeviceSubType != "") sel_DeviceSubType = parseInt(sel_DeviceSubType);
      else sel_DeviceSubType = 0;
      
      if (sel_LocationType != "") sel_LocationType = parseInt(sel_LocationType);
      else sel_LocationType = 0;

      if (width <= 500 && this.Chk_mapnotification == 0 && sel_AlertId != "") {
        this.Chk_mapnotification = 1;
        const Notifyitem: getNotifybyAlert = {
          authKey: this.authkey,
          alertType: parseInt(sel_AlertType),
          alertId: parseInt(sel_AlertId),
          isResident: parseInt(sel_AlertResStaff),
          deviceSubType: parseInt(sel_DeviceSubType),
          deviceType: parseInt(sel_DeviceType),
          nLocationZone: parseInt(sel_LocationType),
        };
        this.homeService
          .getNotificationByAlert(Notifyitem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((Staffdata: any[]) => {
            sel_Staffdata = Staffdata["apiReturn"];
            const NearestStaffitem: getNearestStaff = {
              authKey: this.authkey,
              roomId: sel_RoomId,
              floorId: sel_FloorId,
            };
            this.homeService
              .getNearestStaffForAlert(NearestStaffitem)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((NearestStaffdata: any[]) => {
                sel_NearestStaffdata = NearestStaffdata;
                const NotifyData = {
                  AlertData: this.notifiedinfo,
                  CampusId: parseInt(sel_CampusId),
                  BuildingId: parseInt(sel_BuildingId),
                  FloorId: parseInt(sel_FloorId),
                  RoomId: parseInt(sel_RoomId),
                  AlertId: parseInt(sel_AlertId),
                  AlertType: parseInt(sel_AlertType),
                  AlertResStaff: parseInt(sel_AlertResStaff),
                  DeviceType: parseInt(sel_DeviceType),
                  DeviceSubType: parseInt(sel_DeviceSubType),
                  LocationType: parseInt(sel_LocationType),
                  Staffdata: sel_Staffdata,
                  NearestStaffdata: sel_NearestStaffdata,
                };

                const dialogRefMap = this.dialog.open(
                  MapnotificationComponent,
                  {
                    disableClose: true,
                    width: "450px",
                    height: "340px",
                    panelClass: "myapp-no-padding-dialog",
                    autoFocus: false,
                    data: NotifyData,
                  }
                );

                dialogRefMap.afterClosed().subscribe(() => {
                  this.Chk_mapnotification = 0;
                });
              });
          });
      }
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - showmapnotification() - " + err.message
      );
    }
  }

  showAccessCode() {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      const sel_CampusId =
        this.document.getElementById("spn_CampusId").innerHTML;
      const sel_BuildingId =
        this.document.getElementById("spn_BuildingId").innerHTML;
      const sel_FloorId = this.document.getElementById("spn_FloorId").innerHTML;
      const sel_RoomId = this.document.getElementById("spn_RoomId").innerHTML;
      const sel_AlertId = this.document.getElementById("spn_AlertId").innerHTML;
      const sel_ResidentId =
        this.document.getElementById("spn_ResidentId").innerHTML;

      this.ResidentId = this.SharedDetails.SharedResidentId;
      const AccessCodeData = {
        AccessCodeType: "SerachResident",
        AlertData: this.alertinfofromshared,
        CampusId: parseInt(sel_CampusId),
        BuildingId: parseInt(sel_BuildingId),
        FloorId: parseInt(sel_FloorId),
        RoomId: parseInt(sel_RoomId),
        AlertId: parseInt(sel_AlertId),
      };

      const dialogRef = this.dialog.open(AccesscodeComponent, {
        disableClose: true,
        width: "450px",
        height: "280px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: AccessCodeData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Confirm") {
          if (this.SharedFloorAlertDetails != undefined) {
            this.MCampusId = this.SharedFloorAlertDetails.SharedFloorCampusId;
            this.MBuildingId =
              this.SharedFloorAlertDetails.SharedFloorBuildingId;
            this.MFloorId = this.SharedFloorAlertDetails.SharedFloorFloorId;
            this.MSubZoneId = this.SharedFloorAlertDetails.SharedFloorSubZoneId;
            this.AlertId = this.SharedFloorAlertDetails.SharedFloorAlertId;
            this.AlertStatus =
              this.SharedFloorAlertDetails.SharedFloorAlertStatus;

            set_SharedDetails(
              this.MCampusId,
              this.MBuildingId,
              this.MFloorId,
              this.MSubZoneId,
              this.AlertId,
              this.AlertStatus,
              this.ResidentId + "-" + sel_ResidentId,
              this.alertSharedService
            );
          }
        }
      });
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - showAccessCode() - " + err.message
      );
    }
  }

  MapDetailsChanged() {
    // when floor popup slected.
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      const element_selected = this.document.getElementById("mapselected");
      this.MMapDataDetails = element_selected.innerHTML;
      if (this.MMapDataDetails != null) {
        const arrMapDetails = this.MMapDataDetails.split("_");
        if (arrMapDetails.length == this.Map_array_length) {
          this.MCampusId = parseInt(arrMapDetails[0]);
          this.MBuildingId = parseInt(arrMapDetails[1]);
          this.MFloorId = parseInt(arrMapDetails[2]);
          this.MSubZoneId = parseInt(arrMapDetails[3]);
          this.AlertId = arrMapDetails[4];
          this.AlertStatus = parseInt(arrMapDetails[5]);
          this.StaffAlert = parseInt(arrMapDetails[6]);
          this.InfraAlert = parseInt(arrMapDetails[7]);

          set_SharedDetails(
            this.MCampusId,
            this.MBuildingId,
            this.MFloorId,
            this.MSubZoneId,
            this.AlertId,
            this.AlertStatus,
            this.ResidentId,
            this.alertSharedService
          );
        }
      }
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - MapDetailsChanged() - " + err.message
      );
    }
  }
  CheckResidenStaff = 0;
  OnMapclick(MapData) {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      if (MapData == enumCheckResidenStaff.Resident) {
        this.CheckResidenStaff = enumCheckResidenStaff.Resident;
        RefreshIconDetails(
          this.alertSharedService,
          enumCheckResidenStaff.Resident
        );
      } else if (MapData == enumCheckResidenStaff.Staff) {
        this.CheckResidenStaff = enumCheckResidenStaff.Staff;
        RefreshIconDetails(
          this.alertSharedService,
          enumCheckResidenStaff.Staff
        );
      } else if (MapData == enumCheckResidenStaff.All) {
        this.CheckResidenStaff = enumCheckResidenStaff.All;
        RefreshIconDetails(this.alertSharedService, enumCheckResidenStaff.All);
      } else if (MapData == enumCheckResidenStaff.None) {
        this.CheckResidenStaff = enumCheckResidenStaff.None;
        RefreshIconDetails(this.alertSharedService, enumCheckResidenStaff.None);
      } 
      else if (MapData == enumCheckResidenStaff.BuildingOutline) {
      
        RefreshIconDetails(this.alertSharedService,enumCheckResidenStaff.BuildingOutline)
      }
      else if (MapData == enumCheckResidenStaff.ApproximateOutline) {
      
        RefreshIconDetails(this.alertSharedService,enumCheckResidenStaff.ApproximateOutline)
      }
      else if (MapData == enumCheckResidenStaff.BothOutline) {
      
        RefreshIconDetails(this.alertSharedService,enumCheckResidenStaff.BothOutline)
      }
      else {
        const item = { MapDetails: MapData };
        this.getMapDetails(item);
      }
    } catch (err) {
      throw new Error("home.component.ts" + " - OnMapclick() - " + err.message);
    }
  }

  getMapDetails(MapDetails): void {
    try {
      if (MapDetails.MapDetails.SelectedMapInfo.length > 0) {
        let arrMapDetails = [];
        arrMapDetails = MapDetails.MapDetails.SelectedMapInfo;
        this.CampusFulldetails = MapDetails.MapDetails;
        this.MCampusId = parseInt(arrMapDetails[0]);
        this.MBuildingId = parseInt(arrMapDetails[1]);
        this.MFloorId = parseInt(arrMapDetails[2]);
        this.AlertId = arrMapDetails[3];
        this.AlertStatus = parseInt(arrMapDetails[4]);
        this.ResidentId = arrMapDetails[5];
        const bNeedBuildingOutline=arrMapDetails[6];
        const bNeedApprOutline=arrMapDetails[7];
        this.MSubZoneId = arrMapDetails[8];

        set_SharedDetails(
          this.MCampusId,
          this.MBuildingId,
          this.MFloorId,
          this.MSubZoneId,
          this.AlertId,
          this.AlertStatus,
          this.ResidentId,
          this.alertSharedService
        );
        
        if (arrMapDetails[2] == "0") {
          const CampusId = arrMapDetails[0];
          const Campusinfo = groupBy(
            this.CampusFulldetails["Campusinfo"],
            "CampusId"
          );

          this.CampusImageURL = Campusinfo[CampusId][0]["FloorPlanImage"];
          this.ImageWidth = Campusinfo[CampusId][0]["ImageWidth"];
          this.ImageHeight = Campusinfo[CampusId][0]["ImageHeight"];
          this.SvgImageUrl =
            appsettings.CAMPUS_IMAGE_FOLDER +
            "/" +
            Campusinfo[CampusId][0]["svgFile"];
          this.ImageUrl =
            appsettings.CAMPUS_IMAGE_FOLDER + "/" + this.CampusImageURL;
          this.SvgInfo = Campusinfo[CampusId][0]["Campusdetail"];
          this.SpaceInfo = Campusinfo[CampusId][0]["Spacedetail"];
          this.CZoomLevel = Campusinfo[CampusId][0]["ZoomLevel"];
          this.CRotateLevel = Campusinfo[CampusId][0]["RotateLevel"];
          this.TranslateX = Campusinfo[CampusId][0]["TransformX"];
          this.TranslateY = Campusinfo[CampusId][0]["TransformY"];
          this.FloorInfo = null;

          this.DrawMapCall(bNeedBuildingOutline,bNeedApprOutline);
        } else {
          ///Floor details for map
          const CampusId = arrMapDetails[0];
          const BulidingId = arrMapDetails[1];
          const FloorId = arrMapDetails[2];
          this.AlertId = "";
          this.AlertStatus = 0;
          const Campusinfo = groupBy(
            this.CampusFulldetails["Campusinfo"],
            "CampusId"
          );
          const buildinginfo = groupBy(
            Campusinfo[CampusId][0]["Campusdetail"],
            "buildingid"
          );
          const floorinfo = groupBy(
            buildinginfo[BulidingId][0]["floors"],
            "floorid"
          );

          this.FloorImageURL = floorinfo[FloorId][0].FloorPlanImage;
          this.ImageWidth = floorinfo[FloorId][0].ImageWidth;
          this.ImageHeight = floorinfo[FloorId][0].ImageHeight;
          this.CZoomLevel = floorinfo[FloorId][0].FZoomLevel;
          this.CRotateLevel = floorinfo[FloorId][0].FRotateLevel;
          this.ImageUrl =
            appsettings.FLOOR_IMAGE_FOLDER + "/" + this.FloorImageURL;
          this.SvgInfo = null;
          this.SpaceInfo = null;
          this.FloorInfo = null;
          this.TranslateX = floorinfo[FloorId][0].FTranslateX;
          this.TranslateY = floorinfo[FloorId][0].FTranslateY;
          const voiceLicense = localStorage.getItem("voice_license") ?? "";

          const data: getDeviceDetailsReq = {
            authKey: this.authkey,
            campusId:parseInt(CampusId),
            floorId: parseInt(FloorId),
            status: "3",
            devicetype: 0,
            device: 0,
            spaceId: 0,
            isIndoor: 1,
            isNeedRoomonly: 0,
            isNeedIntercom: parseInt(voiceLicense)
          };

          this.selectFloorInfoFull(data,bNeedBuildingOutline,bNeedApprOutline);
        }
      }
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - getMapDetails() - " + err.message
      );
    }
  }

  //load the image for map whether Campus or floor image
  LoadMapImage(ImageUrl) {
    try {
      ///Map width and height
      const imgwh = map_widthandheight(this.ImageWidth, this.ImageHeight);
      const imgwidth = imgwh.ImageWidth;
      const imgheight = imgwh.ImageHeight;
      this.imgheight = imgheight;
      this.imgwidth = imgwidth;
      const element = this.document.getElementById("map");
      const BackgroundIMG = "Centrak";
      const map = map_creation(
        ImageUrl,
        imgwidth,
        imgheight,
        false,
        BackgroundIMG,
        this.CZoomLevel,
        this.CRotateLevel,
        element.offsetWidth,
        element.offsetHeight,
        this.TranslateX,
        this.TranslateY,
        "map",
        [],
        0
      );

      let translateX = this.TranslateX;
      let translateY = this.TranslateY;

      translateX = translateX / 130;
      translateY = translateY / 135;

      translateX = translateX * imgwidth;
      translateY = translateY * imgheight;

      const MapCenter = map.getView().getCenter();

      MapCenter[0] -= translateX;
      MapCenter[1] += translateY;
      map.on("moveend", function (e) {
        getCheckInExtent(e, map, imgwidth, imgheight, MapCenter);
      });
      return [map];
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - LoadMapImage() - " + err.message
      );
    }
  }

  DrawMapCall(bNeedBuildingOutline,bNeedApprOutline) {
    try {
      //Map Empty
      const element = this.document.getElementById("map");
      element.innerHTML = "";
      const map_SharedDetails = this.SharedDetails;
      const map_sharedService = this.alertSharedService;
      const map_CheckAlertData = this.CheckAlertData;

      const arrBuildingId = [] as any[];
      const arrAlertBuildingId = [] as any[];
      const arrRoomId = [];
      const arrAlertRoomId = [];
      
      let SharedResidentId ="";

      if (map_SharedDetails!=undefined && map_SharedDetails.SharedResidentId !=undefined)
          SharedResidentId = map_SharedDetails.SharedResidentId.toString();
          
      let selectedResidentId;
      if (SharedResidentId != null) {
        selectedResidentId = SharedResidentId.split("-");
      }

      let SharedSubZoneId=0;
      if (map_SharedDetails!=undefined && map_SharedDetails.SharedSubZoneId !=undefined)
        SharedSubZoneId =map_SharedDetails.SharedSubZoneId;

      this.SharedDetails.SharedResidentId = selectedResidentId[0];
      this.ResidentId = selectedResidentId[0];
      const ImagePath = this.Map_image_path;
      //Search Alert Icon style
      const searchalerticonStyledesc = searchalerticonstyle(
        1,
        this.Map_image_path
      );

      // Polygons style
      const labelStyle = label_Polygon(1);
      const fillcolorStyle = fillcolor_style_Home(fillcolorPolygon.Incomplete);
      const style = [labelStyle];

      let map;
      [map] = this.LoadMapImage(this.ImageUrl);

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////               POP UP              /////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const popup = new Popup({ insertFirst: false });
      popup.autoPan = true;
      popup.autoPanAnimation = { duration: 250 };
      const mapHeight = this.imgheight;
      const map_length = this.Map_array_length;
      const image_path = this.Map_image_path;
      //Popup on click over feature
      const alertdatainfo = this.alertinfo;
      const residentimgurl = this.residentImgUrl;
      const staffImgUrl = this.staffImgUrl;
      const MCampusId = this.MCampusId;
      const MFloorId = this.MFloorId;
      const MSubZoneId = this.MSubZoneId;
      const AlertId = this.AlertId;
      // Add a click event handler to the popup DOM element which we will use to
      // capture the user clicking on a link within the popup with an "action" data attribute
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////               Select option            /////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      let select = null; // ref to currently selected interaction

      let selectstyle = [];
      let AlertIconDetails;

      if (this.AlertStatus != alert_raisedon.resident_search) {
        if (JSON.stringify(alertdatainfo) != undefined) {
          if (alertdatainfo.length > 0) {
            if (map_SharedDetails != undefined) {
              AlertIconDetails = alertdatainfo.filter(function (e1) {
                return e1.AlertId == map_SharedDetails.SharedAlertId;
              });
            }
            if (AlertIconDetails != undefined) {
              if (AlertIconDetails.length > 0) {
                selectstyle = [
                  alerticonstyle(1, ImagePath),
                  alerticonred(1, ImagePath, AlertIconDetails[0].iconStyleImg),
                ];
              }
            }
          }
        }
      } else {
        selectstyle = [searchalerticonStyledesc];
      }

      // select interaction working on "click"
      const selectClick = new Select({
        condition: click,
        style: selectstyle,
      });

      const changeInteraction = function () {
        if (select !== null) {
          map.removeInteraction(select);
        }

        select = selectClick;
        if (select !== null) {
          map.addInteraction(select);
          select.on("select", function (e) {
            console.log(e);
            select.getFeatures().clear();
          });
        }
      };

      changeInteraction();

      maponclicked(
        map,
        popup,
        select,
        map_sharedService,
        map_length,
        alertdatainfo,
        residentimgurl,
        staffImgUrl,
        image_path,
        this.router,
        this.dialog
      );

      /// marker array ///
      const arrmarker = [];
      const arrmarkerName = [];
      const arrdevicemarker = [];

      let vectorLayer;
      let geojsonObject;
      let geoFloorjsonObject;


       //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////               Map Space Json load              /////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      if (this.SpaceInfo != null && bNeedApprOutline ==1) {
        /// Geo Feature Collection ///
        geojsonObject = get_geojsonObject_campus(enumRequestFrom.home_page);
        geojsonObject.type = "FeatureCollection";
        
        geojsonObject.features.length = 0;

        if (this.SpaceInfo.length > 0) {
          const arrSpaceInfo = this.SpaceInfo;
          for (let nSpace = 0; nSpace <= arrSpaceInfo.length - 1; nSpace++) {
            arrBuildingId[arrBuildingId.length] =
              arrSpaceInfo[nSpace].spaceId + "_1";
            LoadBuildingSvg(
              map_sharedService,
              arrSpaceInfo[nSpace],
              MCampusId,
              alertdatainfo,
              mapHeight,
              geojsonObject,
              arrmarker,
              arrmarkerName,
              1
            );
          }
          const _map = map;
          this.alertSharedService.changedMapDetails(_map);

          const space_fillcolorStyle = fillcolor_style_Home(
            fillcolorPolygon.Completed
          );
          AddBuildingLayer(
            map,
            geojsonObject,
            space_fillcolorStyle,
            arrmarker,
            ImagePath,
            arrmarkerName,
            labelStyle,
            style,
            map_sharedService,
            this.MCampusId,
            this.pollingData,
            map_SharedDetails,
            map_CheckAlertData,
            alertdatainfo,
            this.residentImgUrl,
            this.staffImgUrl,
            popup,
            select,
            arrSpaceInfo,
            searchalerticonStyledesc,
            mapHeight,
            map_length,
            arrBuildingId,
            arrAlertBuildingId,
            this.router
          );

          map_sharedService
            .currentsharedMapDetails()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ShareMapDetails) => {
              map = ShareMapDetails;
            });
          map_sharedService
            .currentsharedMapVectorLayer()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ShareMapVectorLayer) => {
              vectorLayer = ShareMapVectorLayer;
            });

          ShowPopupManual(
            map_sharedService,
            this.AlertId,
            this.AlertStatus,
            this.residentImgUrl,
            this.staffImgUrl,
            map,
            select,
            vectorLayer,
            alertdatainfo,
            arrSpaceInfo,
            searchalerticonStyledesc,
            mapHeight,
            map_length,
            ImagePath,
            map_SharedDetails.SharedResidentId,
            map_SharedDetails.SharedBuildingId,
            this.router
          );

          map_sharedService
            .currentsharedMapDetails()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ShareMapDetails) => {
              map = ShareMapDetails;
            });
          map_sharedService
            .currentsharedMapVectorLayer()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ShareMapVectorLayer) => {
              vectorLayer = ShareMapVectorLayer;
            });
        }
      }

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////               Map Svg Json load              /////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      if (this.SvgInfo != null && bNeedBuildingOutline ==1) {
        /// Geo Feature Collection ///
        geojsonObject = get_geojsonObject_campus(enumRequestFrom.home_page);
        geojsonObject.type = "FeatureCollection";
        
        geojsonObject.features.length = 0;

        if (this.SvgInfo.length > 0) {
          const arrSvgInfo = this.SvgInfo;
          for (let nsvg = 0; nsvg <= arrSvgInfo.length - 1; nsvg++) {
            arrBuildingId[arrBuildingId.length] = arrSvgInfo[nsvg].buildingid;
            if (arrSvgInfo[nsvg].AlertBuildingcnt > 0)
              arrAlertBuildingId[arrAlertBuildingId.length] =
                arrSvgInfo[nsvg].buildingid;
            LoadBuildingSvg(
              map_sharedService,
              arrSvgInfo[nsvg],
              MCampusId,
              alertdatainfo,
              mapHeight,
              geojsonObject,
              arrmarker,
              arrmarkerName,
              0
            );
          }
          const _map = map;
          this.alertSharedService.changedMapDetails(_map);

          AddBuildingLayer(
            map,
            geojsonObject,
            fillcolorStyle,
            arrmarker,
            ImagePath,
            arrmarkerName,
            labelStyle,
            style,
            map_sharedService,
            this.MCampusId,
            this.pollingData,
            map_SharedDetails,
            map_CheckAlertData,
            alertdatainfo,
            this.residentImgUrl,
            this.staffImgUrl,
            popup,
            select,
            arrSvgInfo,
            searchalerticonStyledesc,
            mapHeight,
            map_length,
            arrBuildingId,
            arrAlertBuildingId,
            this.router
          );

          map_sharedService
            .currentsharedMapDetails()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ShareMapDetails) => {
              map = ShareMapDetails;
            });
          map_sharedService
            .currentsharedMapVectorLayer()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ShareMapVectorLayer) => {
              vectorLayer = ShareMapVectorLayer;
            });

          ShowPopupManual(
            map_sharedService,
            this.AlertId,
            this.AlertStatus,
            this.residentImgUrl,
            this.staffImgUrl,
            map,
            select,
            vectorLayer,
            alertdatainfo,
            arrSvgInfo,
            searchalerticonStyledesc,
            mapHeight,
            map_length,
            ImagePath,
            map_SharedDetails.SharedResidentId,
            map_SharedDetails.SharedBuildingId,
            this.router
          );

          map_sharedService
            .currentsharedMapDetails()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ShareMapDetails) => {
              map = ShareMapDetails;
            });
          map_sharedService
            .currentsharedMapVectorLayer()
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ShareMapVectorLayer) => {
              vectorLayer = ShareMapVectorLayer;
            });
        }
      }

     

      //https://openlayers.org/en/latest/examples/geojson.html
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////               Floor Svg Json load              /////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      if (this.FloorInfo != null) {
        /// Geo Feature Collection ///
        geoFloorjsonObject = get_geojsonObject_floor(enumRequestFrom.home_page);
        geoFloorjsonObject.type = "FeatureCollection";
        
        geoFloorjsonObject.features.length = 0;

        //if (this.FloorInfo.length > 0) {
        const arrFloorInfo = this.FloorInfo;        
        
        //Load the polyline Start
        
        let arr_arrpolypoint = new Array();
        let arr_arrIsDuplicate = new Array();
        let arr_arrunitid = new Array();
        const arr_arrpolypointData = new Array();
    
        for (let ninfo = 0; ninfo <= arrFloorInfo.length - 1; ninfo++) {
          if (arrFloorInfo[ninfo].polyline != undefined) {
            const indexval = arr_arrpolypoint.indexOf(arrFloorInfo[ninfo].polyline);
            const unitIdindexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
            if (indexval==-1 || unitIdindexval==-1) {
              const len = arr_arrpolypoint.length;
              arr_arrpolypointData[len] = { 'multiUnitId':'','multiDataId': '', 'multiDeviceId': '', 'multiFilterDeviceTypeId': '', 'multiFilterDeviceType': '' };
              arr_arrpolypointData[len].multiUnitId = arrFloorInfo[ninfo].UnitId;
              arr_arrpolypointData[len].multiDataId = arrFloorInfo[ninfo].DataId;
              arr_arrpolypointData[len].multiDeviceId = arrFloorInfo[ninfo].DeviceId;
              arr_arrpolypointData[len].multiFilterDeviceTypeId = arrFloorInfo[ninfo].FilterDeviceTypeId;
              arr_arrpolypointData[len].multiFilterDeviceType = arrFloorInfo[ninfo].FilterDeviceType;
              arr_arrpolypoint[len] = arrFloorInfo[ninfo].polyline;
              arr_arrIsDuplicate[len] = arrFloorInfo[ninfo].isDuplicate;
              arr_arrunitid[len] = arrFloorInfo[ninfo].UnitId;
            }
            else if(indexval!=-1){
              const unitIdindexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
              arr_arrpolypointData[unitIdindexval].multiUnitId += "," + arrFloorInfo[ninfo].UnitId;
              arr_arrpolypointData[unitIdindexval].multiDataId += "," + arrFloorInfo[ninfo].DataId;
              arr_arrpolypointData[unitIdindexval].multiDeviceId += "," + arrFloorInfo[ninfo].DeviceId;
              arr_arrpolypointData[unitIdindexval].multiFilterDeviceTypeId += "," + arrFloorInfo[ninfo].FilterDeviceTypeId;
              arr_arrpolypointData[unitIdindexval].multiFilterDeviceType += "," + arrFloorInfo[ninfo].FilterDeviceType;          
            }
            else if(unitIdindexval!=-1){
              const unitIdindexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
              arr_arrpolypointData[unitIdindexval].multiUnitId += "," + arrFloorInfo[ninfo].UnitId;
              arr_arrpolypointData[unitIdindexval].multiDataId += "," + arrFloorInfo[ninfo].DataId;
              arr_arrpolypointData[unitIdindexval].multiDeviceId += "," + arrFloorInfo[ninfo].DeviceId;
              arr_arrpolypointData[unitIdindexval].multiFilterDeviceTypeId += "," + arrFloorInfo[ninfo].FilterDeviceTypeId;
              arr_arrpolypointData[unitIdindexval].multiFilterDeviceType += "," + arrFloorInfo[ninfo].FilterDeviceType;          
            }
          }
        }
        arr_arrpolypoint = new Array();
        arr_arrIsDuplicate = new Array();
        arr_arrunitid = new Array();
       
        for (let ninfo = 0; ninfo <= arrFloorInfo.length - 1; ninfo++) {
        
            const UnitId = arrFloorInfo[ninfo].UnitId;
            const DataId = arrFloorInfo[ninfo].DataId;
            const DeviceId = arrFloorInfo[ninfo].DeviceId;
            const DeviceName = arrFloorInfo[ninfo].DeviceName;
            const RoomName = arrFloorInfo[ninfo].RoomName;
            const DeviceX = arrFloorInfo[ninfo].DeviceX;
            const DeviceY = arrFloorInfo[ninfo].DeviceY;
            const MacId = arrFloorInfo[ninfo].MacId;
            const unitName = arrFloorInfo[ninfo].UnitLocation;
            const RoomCategory = arrFloorInfo[ninfo].ResRoomCategory;
        
            const DeviceType = arrFloorInfo[ninfo].FilterDeviceTypeId;
            const DeviceTypeName = arrFloorInfo[ninfo].FilterDeviceType;
            const SubZoneId= arrFloorInfo[ninfo].SubZoneId;
            let _DeviceId = DeviceId;
            const FloorId = arrFloorInfo[ninfo].FloorId;
        
              if (RoomCategory != "" && DeviceId>0) {
                _DeviceId = RoomCategory;
              }   
              console.log(_DeviceId)
              let polyline = new Array();
            if (arrFloorInfo[ninfo].polyline != undefined) {
              polyline = arrFloorInfo[ninfo].polyline.split(' ');

              
              const arrpolypoint = new Array();
              /// Floor poly line ///
              for (let npoly = 0; npoly <= polyline.length - 1; npoly++) {
                arrpolypoint[npoly] = polyline[npoly].split(',');
                arrpolypoint[npoly][0] = (parseFloat(arrpolypoint[npoly][0]));
                arrpolypoint[npoly][1] = getboxFromYpos(parseFloat(arrpolypoint[npoly][1]), mapHeight);
              }
              
              const markerpoint = calculatexycoordinates(polyline, mapHeight); // default inside posstion it col          

              const marker = new OlFeature({
                // Added fromLonLat
                geometry: new OlPoint(markerpoint)
              });

              let imgName = "";
              let imgScale = 0.3;

              [imgName, imgScale] = getDevicetype_image(DeviceName);


              if (DeviceX == undefined || DeviceY == undefined) //if undefine we have no image shown
              {
                imgScale = 0.0;
              }
              else if (DeviceX == "" || DeviceY == "") {
                imgScale = 0.0;
              }
              console.log(imgScale,imgName)

              const indexval = arr_arrpolypoint.indexOf(arrFloorInfo[ninfo].polyline);
              let multi_indexval = -1;        
              const unitIdindexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
              if (indexval==-1 || unitIdindexval==-1) {
                arr_arrIsDuplicate[arr_arrpolypoint.length] = arrFloorInfo[ninfo].isDuplicate;
                arr_arrunitid[arr_arrpolypoint.length] = arrFloorInfo[ninfo].UnitId;
                multi_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
                arr_arrpolypoint[arr_arrpolypoint.length] = arrFloorInfo[ninfo].polyline;
              }
              else {
                multi_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
              }

              marker.set("UnitId", UnitId);
              marker.set("DataId", DataId);
              marker.set("FloorId", FloorId);
              marker.set("DeviceId", DeviceId);
              marker.set("DeviceTypeName", DeviceTypeName);
              marker.set("DeviceType", DeviceType);
              marker.set("DeviceName", DeviceName);
              marker.set("UnitName", unitName);
              marker.set("DeviceTypeId",  arrFloorInfo[ninfo].FilterDeviceTypeId);
              marker.set("MacId",  MacId);
              marker.set("RoomCategory",  RoomCategory);
              marker.set("SubZoneId",  SubZoneId);

              marker.set("MultiDataId", arr_arrpolypointData[multi_indexval].multiDataId);
              marker.set("MultiDeviceId", arr_arrpolypointData[multi_indexval].multiDeviceId);
              marker.set("MultiFilterDeviceTypeId", arr_arrpolypointData[multi_indexval].multiFilterDeviceTypeId);
              marker.set("MultiFilterDeviceType", arr_arrpolypointData[multi_indexval].multiFilterDeviceType);
              const arrdevicemarker1=[];
              arrdevicemarker1.push(marker);
              

              let bNeedtoShow=false;

              if (SharedSubZoneId>0)
              {
                if (SharedSubZoneId==SubZoneId)
                  bNeedtoShow=true;
              }
              else
                bNeedtoShow=true;

        
                if ((indexval==-1 || unitIdindexval==-1) && bNeedtoShow) {
                const unit_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
                const _multiDataId = arr_arrpolypointData[unit_indexval].multiDataId;
                const _multiDeviceId = arr_arrpolypointData[unit_indexval].multiDeviceId;
                const _multiFilterDeviceTypeId = arr_arrpolypointData[unit_indexval].multiFilterDeviceTypeId;
                const _multiFilterDeviceType = arr_arrpolypointData[unit_indexval].multiFilterDeviceType;

                // const arr_multiDataId = _multiDataId != null ? _multiDataId.toString().split(',') : [];
                const arr_multiDeviceId = _multiDeviceId != null ? _multiDeviceId.toString().split(',') : [];
                // const arr_multiFilterDeviceTypeId = _multiFilterDeviceTypeId != null ? _multiFilterDeviceTypeId.toString().split(',') : [];
                const arr_multiFilterDeviceType = _multiFilterDeviceType != null ?  _multiFilterDeviceType.toString().split(',') : [];

                const geoFloorjsonObject = new OlFeature({
                  geometry: new Polygon([arrpolypoint]),
                  FloorId: FloorId,
                  UnitId: UnitId.toString(),
                  RoomName: RoomName,
                  DataId: DataId,
                  MultiDataId: _multiDataId,
                  MultiDeviceId: _multiDeviceId,
                  MultiFilterDeviceTypeId: _multiFilterDeviceTypeId,
                  MultiFilterDeviceType: _multiFilterDeviceType,
                  UnitName:unitName,
                  SubZoneId:SubZoneId
                });
                
                      
                  let IsDeviceEmpty=0;
                  for (let nidx=0;nidx<=arr_multiDeviceId.length;nidx++)
                  {
                      if (arr_multiDeviceId[nidx] =="0" &&
                      arr_multiFilterDeviceType[nidx] != DeviceTypeDesc.Gateway && 
                      arr_multiFilterDeviceType[nidx] != NewDeviceTypeDesc.Room)
                      {
                        IsDeviceEmpty = 1;
                      }
                  }
                
                
                if (arr_multiFilterDeviceType.length == 0) {
                  IsDeviceEmpty = -1;
                }

                if (arr_multiFilterDeviceType.indexOf(NewDeviceTypeDesc.Room) != -1  && arr_multiFilterDeviceType.length == 1) {
                  IsDeviceEmpty = -1;
                }
               
                let subzonecolor=0;
                if (SharedSubZoneId ==0 && SubZoneId>0) // if currently Zone is select we need to different subzone have different color based on order.
                  subzonecolor=arrFloorInfo[ninfo].SubZoneDisplayOrder

              const deviceStyle = fillcolor_style_Home_Space(subzonecolor);

              const vectorSource_geo = new OlVectorSource({ features: [geoFloorjsonObject], wrapX: false });
              const vectorLayer_geo = new OlVectorLayer({
                  source: vectorSource_geo,
                  FloorId: "Floor_" + FloorId,
                  name: "Room_" + UnitId,
                  style: deviceStyle,
                  declutter: true
                });
                map.addLayer(vectorLayer_geo);

              }
            }
        }
      //Load the polyline End
      


        for (let ninfo = 0; ninfo <= arrFloorInfo.length - 1; ninfo++) {
          arrFloorInfo.TranslateX = this.TranslateX;
          arrFloorInfo.TranslateY = this.TranslateY;

          arrFloorInfo.ImageWidth = this.ImageWidth;
          arrFloorInfo.ImageHeight = this.ImageHeight;
          const ChkFloorInfo = arrFloorInfo[ninfo];
          if (ChkFloorInfo.RoomId != undefined) {
            arrRoomId[arrRoomId.length] =
              ChkFloorInfo.RoomId + "_" + ChkFloorInfo.FilterDeviceTypeId;
            // if (ChkFloorInfo.LastAlertId > 0 || ChkFloorInfo.AlertId > 0)
            //   arrAlertRoomId[arrAlertRoomId.length] = ChkFloorInfo.RoomId;

            alertdatainfo.filter(function (e1) {
              if ((e1.AlertType == enumAlertType.Pull_Cord ||
                  e1.AlertType == enumAlertType.UT) &&
                e1.ismobilitymode == 1) {
                if (e1.FloorId == MFloorId &&
                  e1.RoomId == ChkFloorInfo.RoomId &&
                  arrAlertRoomId.indexOf(
                    ChkFloorInfo.RoomId + "_" + ChkFloorInfo.FilterDeviceTypeId
                  ) == -1
                ) {
                  arrAlertRoomId[arrAlertRoomId.length] =
                    ChkFloorInfo.RoomId + "_" + ChkFloorInfo.FilterDeviceTypeId;
                  return e1.RoomId;
                }
              } else if ((e1.AlertType == enumAlertType.Pull_Cord ||
                  e1.AlertType == enumAlertType.UT) &&
                e1.ismobilitymode == 0) {
                if (e1.FloorId == MFloorId &&
                  e1.TagId == ChkFloorInfo.RoomId &&
                  arrAlertRoomId.indexOf(
                    ChkFloorInfo.RoomId + "_" + ChkFloorInfo.FilterDeviceTypeId
                  ) == -1) {
                  arrAlertRoomId[arrAlertRoomId.length] =
                    ChkFloorInfo.RoomId + "_" + ChkFloorInfo.FilterDeviceTypeId;
                  return e1.TagId;
                }
              } else {
                let _RoomId = e1.RoomId;
                if (_RoomId == null || _RoomId == null || _RoomId == 0) {
                  _RoomId = e1.LastRoomId;
                }
                if (e1.FloorId == MFloorId &&
                  e1.RoomId == ChkFloorInfo.RoomId &&
                  arrAlertRoomId.indexOf(
                    ChkFloorInfo.RoomId + "_" + ChkFloorInfo.FilterDeviceTypeId
                  ) == -1 &&
                  CheckAlertByDeviceTypeId(
                    e1.LocationType,
                    e1.DeviceType,
                    ChkFloorInfo.FilterDeviceTypeId
                  )
                ) {
                  arrAlertRoomId[arrAlertRoomId.length] = ChkFloorInfo.RoomId + "_" + ChkFloorInfo.FilterDeviceTypeId;
                  return e1.RoomId;
                }
              }
            });
          }
          LoadFloorSvg(
            ChkFloorInfo,
            MCampusId,
            alertdatainfo,
            mapHeight,
            arrdevicemarker,
            map_sharedService,
            MSubZoneId
          );
        }
        const _map = map;
        this.alertSharedService.changedMapDetails(_map);

        AddFloorLayer(
          map,
          arrdevicemarker,
          ImagePath,
          map_sharedService,
          MFloorId,
          this.pollingData,
          map_SharedDetails,
          map_CheckAlertData,
          alertdatainfo,
          this.residentImgUrl,
          this.staffImgUrl,
          popup,
          select,
          arrFloorInfo,
          searchalerticonStyledesc,
          mapHeight,
          map_length,
          MCampusId,
          arrRoomId,
          arrAlertRoomId,
          this.router
        );

        map_sharedService
          .currentsharedMapDetails()
          .pipe(takeUntil(this._onDestroy))
          .subscribe((ShareMapDetails) => {
            map = ShareMapDetails;
          });
        map_sharedService
          .currentsharedMapVectorLayer()
          .pipe(takeUntil(this._onDestroy))
          .subscribe((ShareMapVectorLayer) => {
            vectorLayer = ShareMapVectorLayer;
          });

        ShowFloorPopupManual(
          this.SharedFloorAlertDetails,
          this.residentImgUrl,
          this.staffImgUrl,
          map,
          select,
          vectorLayer,
          alertdatainfo,
          arrFloorInfo,
          searchalerticonStyledesc,
          AlertId,
          map_SharedDetails,
          map_sharedService,
          popup,
          map_length,
          selectedResidentId[1],
          this.router
        );

        map_sharedService
          .currentsharedMapDetails()
          .pipe(takeUntil(this._onDestroy))
          .subscribe((ShareMapDetails) => {
            map = ShareMapDetails;
          });
        map_sharedService
          .currentsharedMapVectorLayer()
          .pipe(takeUntil(this._onDestroy))
          .subscribe((ShareMapVectorLayer) => {
            vectorLayer = ShareMapVectorLayer;
          });

        RefreshIconDetails(this.alertSharedService, this.CheckResidenStaff);
      }
      if (this.SvgInfo == null &&
        this.FloorInfo == null &&
        this.ImageUrl.length == 0
      ) {
        document.getElementById("map").innerHTML =
          "<span style='padding-top: 20%; padding-left: 45%;display:block;font-size:16px;font-weight:bold;'> Map Not Found.</span>";
      }
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - DrawMapCall() - " + err.message
      );
    }
  }
  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (HomeHeaderComponent.isHomeMenuClosed == false) {
      this.homeService.toggleHomeMenuNav.emit();
    }
  }

  clkAccepteComplete() {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      const AcceptedAlertId = this.document.getElementById(
        "spn_AcceptCompleteId"
      ).innerHTML;
      const Staffid = localStorage.getItem("userid") ?? "";
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to Complete this Alert?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
 
      dialogRef.afterClosed().subscribe((result) => {
        const _alertinfo = this.alertinfo;
        const AcceptedAlertDetails = _alertinfo.filter(function (e1) {
          return (
            e1.AlertId == AcceptedAlertId &&
            (e1.AckStatus == enumAlertAckStatus.Accepted ||
              e1.AckStatus == enumAlertAckStatus.Presence ||
              e1.AckStatus == enumAlertAckStatus.Caregiver_Presence ||
              e1.AckStatus == enumAlertAckStatus.Caregiver_Alert_Presence)
          );
        });

        if (result == "Yes" && AcceptedAlertDetails.length > 0) {
          if (<HTMLInputElement>this.document.getElementById("complete") != null) {
            (<HTMLInputElement>(
              this.document.getElementById("complete")
            )).disabled = true;
          }
          const cancelItem: cancelAlert = {
            authKey: this.authkey,
            staffId: parseInt(Staffid),
            alertId: parseInt(AcceptedAlertId),
            status: enumAlertStatus.Complete,
          };
          this.apiService
            .cancelAlert(cancelItem)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((canceledResponse: any[]) => {
              if (canceledResponse["apiReturn"] == "Success") {
                generateAlertMessage(this.dialog,"Alert completed successfully.");
                this.thisDialogRef.close("Success");
              }
              else if (canceledResponse["condition"] == "Authfalse") {
                this.thisDialogRef.close("close");
                AuthFailedMessage(this.dialog, this.router);
                return;
              }
              const dateformat = localStorage.getItem("dateFormat") ?? "";
              const cancelorcompletesec = localStorage.getItem("PAST_ALERT_LINGER") ?? "";
              const item: getAlertData = {
                authKey: this.authkey,
                dateFormat: dateformat.toString(),
                cancelOrCompleteSec: cancelorcompletesec,
              };
              this.mainLayoutService.fetchAlertData(item);
            });
        }
      });
    } catch (err) {
      throw new Error(
        "home.component.ts" + " - clkAccepteComplete() - " + err.message
      );
    }
  }
  ngOnDestroy() {
    CloseLocationChangeSocket();
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

