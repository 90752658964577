import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from "../../../api.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  SessionCheckwithStaff,
  generateAlertMessage,
  getFeatureNeedtoShow,
} from "../../../app-general";
import {
  enumUIAction,
  enumUIFeatureView,
  enumUIView,
} from "../../../app-settings";
import { TechnologyoverlayService } from "../technologyoverlay.service";
import { DOCUMENT } from "@angular/common";
import { AlertSharedService } from "./../../../_services/index";
import { Router } from "@angular/router";
import {
  getBoardInfoData,
  getBoardSetupData,
  getConfigSettings,
} from "../technologyoverlay";
import { UploadDialogComponent } from "./upload-dialog/upload-dialog.component";
import { EditConfigComponent } from "./edit-config/edit-config.component";
import * as XLSX from "xlsx";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ConfirmMessageComponent } from '../../../confirm-message/confirm-message.component';

type AOA = any[][];

@Component({
  selector: "app-hardwiredconfiguration",
  templateUrl: "./hardwiredconfiguration.component.html",
  styleUrls: ["./hardwiredconfiguration.component.scss"],
})
export class HardwiredConfigurationComponent implements OnInit {
  MapSyncProcess;
  getdata;
  displayedColumns: string[] = [
    "BoardName",
    "IPAddress",
    "UDPPort",
    "Campus",
    "Building",
    "Edit",
  ];
  dataSource;
  dataSourcecnt;
  hardwireddata;
  editconfigdialogopen = 0;
  fileName = "NurseCallIntegration.xlsx";
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  lgcurrentScreenWidth = "lg";
  private _onDestroy: Subject<void> = new Subject<void>();
  public watcher: Observable<boolean>;
  bEditiconGroup = false;
  authkey;
  stateSymbolsData: AOA = [["State Symbol", "State Assignment"]];
  boardInfoData: AOA = [["Board Name", "IP Address", "UDP Port", "Points"]];
  boardSetupData: AOA = [
    [
      "Board Name",
      "Relay",
      "BedA",
      "BedB",
      "Bath",
      "Smoke Alarm",
      "Staff Emergency",
      "HiPriority",
      "Point Name",
    ],
  ];
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    public dialog: MatDialog,    
    private apiService: ApiService,
    private technologyoverlayService: TechnologyoverlayService,
    @Inject(DOCUMENT) private document: any,
    private alertSharedService: AlertSharedService,
    private router: Router,
    public thisDialogRef: MatDialogRef<HardwiredConfigurationComponent>,    
    private breakpointObserver: BreakpointObserver
  ) {
    const routerCurrentNavigate = this.router.getCurrentNavigation();
    if(routerCurrentNavigate != null)
      this.getdata = routerCurrentNavigate.extras.state;
    this.breakpoint$.subscribe(() => this.breakpointChanged());        
  }
  
  breakpointChanged() {    
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Hardwired_Configration_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bEditiconGroup = true;
      this.displayedColumns = [
        "BoardName",
        "IPAddress",
        "UDPPort",
        "Campus",
        "Building",
        "Edit",
      ];
    } else {
      this.displayedColumns = [
        "BoardName",
        "IPAddress",
        "UDPPort",
        "Campus",
        "Building",
      ];
    }
  }
  //@ViewChild('configSort') configSort: MatSort;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Hardwired_Configration_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bEditiconGroup = true;
      this.displayedColumns = [
        "BoardName",
        "IPAddress",
        "UDPPort",
        "Campus",
        "Building",
        "Edit",
      ];
    } else {
      this.displayedColumns = [
        "BoardName",
        "IPAddress",
        "UDPPort",
        "Campus",
        "Building",
      ];
    }

    this.getConfigurationSettings();
  }

  getConfigurationSettings() {
    const apiParam: getConfigSettings = {
      authKey: this.authkey,
    };
    this.technologyoverlayService
      .getConfigurationSettings(apiParam)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any[]) => {
        if (result) {
          this.hardwireddata = result;
          this.dataSource = new MatTableDataSource(this.hardwireddata);
          this.dataSource.data = this.hardwireddata;
          this.dataSourcecnt = this.hardwireddata.length;
          this.dataSource.sort = this.sort;
          const sortState: Sort = { active: "BoardName", direction: "asc" };
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);
        }
      });
  }

  editConfigurationdetails(configInfo) {
    try {
      if (!SessionCheckwithStaff(this.router)) {
        return;
      }
      const configdetails: any = {
        FileName: configInfo.FileName,
        BoardName: configInfo.BoardName,
        IPAddress: configInfo.IPAddress,
        UDPPort: configInfo.UDPPort,
        Building: configInfo.Building,
        BuildingId: configInfo.BuildingId,
        Campus: configInfo.Campus,
        CampusId: configInfo.CampusId,
        Description: configInfo.Description,
        setupDataRowCount: configInfo.Rowcount,
        BoardId: configInfo.ConfigrationSettingsId,
      };
      if (this.editconfigdialogopen == 0) {
        const dialogRef = this.dialog.open(EditConfigComponent, {
          disableClose: true,
          width: "1100px",
          height: "700px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: configdetails,
        });

        this.editconfigdialogopen = 1;

        dialogRef.afterClosed().subscribe(() => {
          this.editconfigdialogopen = 0;
          this.getConfigurationSettings();
        });
      }
    } catch (err) {
      console.log(err);
      throw new Error(
        "hardwiredconfiguration.component.ts" +
          " - editConfigurationdetails() - " +
          err.message
      );
    }
  }

  CloseRightNav() {
    // if(SidenavComponent.isClosed == false)
    // {
    //     this.mainLayoutService.toggleSideNavRight.emit();
    // }
    // if(InfrastructureAlertsComponent.isinfraClosed == false)
    // {
    //     this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    // }
  }
  uploadNewConfiguration() {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      disableClose: true,
      width: "450px",
      height: "200px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: "",
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getConfigurationSettings();
    });
  }

  downloadExcelTemplate() {
    const apiParam: getBoardInfoData = {
      authKey: this.authkey,
    };
    this.technologyoverlayService
      .getBoardInfoData(apiParam)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any[]) => {
        if (result) {
          this.boardInfoData = [
            ["Board Name", "IP Address", "UDP Port", "Points"],
          ];

          if (result.length > 0) {
            for (let nIdx = 0; nIdx < result.length; nIdx++) {
              const arr = [
                result[nIdx].BoardName,
                result[nIdx].IPAddress,
                result[nIdx].UDPPort,
                result[nIdx].Points,
              ];

              this.boardInfoData.push(arr);
            }
          }
        }
        const apiParam1: getBoardSetupData = {
          authKey: this.authkey,
          boardName: "",
        };
        this.technologyoverlayService
          .getBoardSetupDetail(apiParam1)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((setupData: any[]) => {
            if (setupData) {
              this.boardSetupData = [
                [
                  "Board Name",
                  "Relay",
                  "BedA",
                  "BedB",
                  "Bath",
                  "Smoke Alarm",
                  "Staff Emergency",
                  "HiPriority",
                  "Point Name",
                ],
              ];
              if (setupData.length > 0) {
                for (let nIdx = 0; nIdx < setupData.length; nIdx++) {
                  setupData[nIdx].BedA =
                    setupData[nIdx].BedA.toUpperCase() === "NULL"
                      ? "No"
                      : setupData[nIdx].BedA;
                  setupData[nIdx].BedB =
                    setupData[nIdx].BedB.toUpperCase() === "NULL"
                      ? "No"
                      : setupData[nIdx].BedB;
                  setupData[nIdx].Bath =
                    setupData[nIdx].Bath.toUpperCase() === "NULL"
                      ? "No"
                      : setupData[nIdx].Bath;
                  setupData[nIdx].SmokeAlarm =
                    setupData[nIdx].SmokeAlarm.toUpperCase() === "NULL"
                      ? "No"
                      : setupData[nIdx].SmokeAlarm;
                  setupData[nIdx].StaffEmergency =
                    setupData[nIdx].StaffEmergency.toUpperCase() === "NULL"
                      ? "No"
                      : setupData[nIdx].StaffEmergency;
                  setupData[nIdx].HiPriority =
                    setupData[nIdx].HiPriority.toUpperCase() === "NULL"
                      ? "No"
                      : setupData[nIdx].HiPriority;
                    const arrSetup = [
                    setupData[nIdx].BoardName,
                    setupData[nIdx].Relay,
                    setupData[nIdx].BedA,
                    setupData[nIdx].BedB,
                    setupData[nIdx].Bath,
                    setupData[nIdx].SmokeAlarm,
                    setupData[nIdx].StaffEmergency,
                    setupData[nIdx].HiPriority,
                    setupData[nIdx].PointName,
                  ];
                  this.boardSetupData.push(arrSetup);
                }
              }
            }

            this.technologyoverlayService
              .getStateSymbolsData(apiParam)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((stateData: any[]) => {
                if (stateData) {
                  this.stateSymbolsData = [
                    ["State Symbol", "State Assignment"],
                  ];

                  if (stateData.length > 0) {
                    for (let nIdx = 0; nIdx < stateData.length; nIdx++) {
                      const arrstateData = [
                        stateData[nIdx].StateSymbol,
                        stateData[nIdx].StateAssignment,
                      ];

                      this.stateSymbolsData.push(arrstateData);
                    }
                  }
                }
                this.downloadExcelFile(
                  this.stateSymbolsData,
                  this.boardInfoData,
                  this.boardSetupData
                );
              });
          });
      });
  }
  applyBoardConfiguarion() {
    
    const ConfirmMessageData = {
      ConfirmMessage:  "Existing Hardwired Configurations should be clear. Do you want to change it?"
    }     
    const dialogRef = this.dialog.open(ConfirmMessageComponent, {
      disableClose: true,
      width: 'auto',
      height: '220px',
      panelClass: 'myapp-no-padding-dialog',
      autoFocus: false,
      data: ConfirmMessageData
    });     
    dialogRef.afterClosed().subscribe(result => {     
      if(result == "Yes")
      {                     
        if(this.hardwireddata.length > 0)
        {
          this.apiService.reloadBoardConfiguration();
          generateAlertMessage(this.dialog, 'Apply Board Configuration successfully completed');
        }
        else
        {
          generateAlertMessage(this.dialog, 'Devices not available to apply configuration');
          return;
        }
      }
      else
      {        
          dialogRef.close('Cancel');          
          return;
      }
    });         
  }
  downloadExcelFile(stateSymbolsData, boardInfoData, boardSetupData) {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(stateSymbolsData);
    const ws1: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(boardInfoData);
    const ws2: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(boardSetupData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "State Symbols");
    XLSX.utils.book_append_sheet(wb, ws1, "Board Info");
    XLSX.utils.book_append_sheet(wb, ws2, "Board Setup");
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}
