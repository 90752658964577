<div id="devicedownload"> 
    <div class="download-title">
      <h3 class="devicedownloadHeader" style="margin: 0 0 10px 11px;">Device Download
      <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
      <mat-divider ></mat-divider> 
    </div>
  
   <div>  
  
    <button class="btnExcel" color="primary" mat-raised-button (click)="downloadDeviceExcel(data)" >Download CSV</button> 
    <button id="pdf1" class="btnPdf" color="primary" mat-raised-button (click)="downloadDevicePdf(data)" >Download pdf</button> 
   </div>
  
   <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%;">       
    <div class="loaddevicespinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
  </div> 
  
  </div>
