<perfect-scrollbar style="max-width: 980px; max-height: 650px;" >
  <div class="clsconformDevice">
      <div class="conform-title">
        <h3 class="conformHeader" style="margin: 0 0 10px 11px;">{{Type}}
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onImageCloseClicked()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>
      <div class="clsdeletecontent">
        <span [ngClass]="data.CustomType == 0 ? 'clsdeleteMsg' : 'clsCustomMsg'">{{message}}</span>
        <!-- show Delete/cancel button -->
        <mat-dialog-actions [ngClass]="data.CustomType == 0 ? 'clsnotifydeletebuttoncontrol' : 'clscustombuttoncontrol'">    
          <button [ngClass]="data.CustomType == 0 ? 'clsdeletebutton' : 'clsCusCancelbutton'" mat-raised-button (click)="onUpdateClicked()">{{ButtonValue1}}</button>&nbsp;
          <button type="button" [ngClass]="data.CustomType == 0 ? 'clsdeletebutton' : 'clsCusBuildbutton'" mat-raised-button (click)="onCloseClicked()">{{ButtonValue2}}</button>
        </mat-dialog-actions>
      </div>
  </div>
