import { DatePipe, DOCUMENT } from "@angular/common";
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { StaffData, alertinfo, reNotifyAlert, getRenotityData } from "../home";

import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  appsettings,
  enumAlertType,
  enumAlertStatus,
  enumAlertAckStatus,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  Alert_Event_Type,
  enumEnableDisable,
} from "../../../app-settings";
import {
  SharedAlertLoadData,
  SharedAlertData,
  NearestStaffdata,
  cancelAlert,
} from "../../../api";
import { HomeService } from "../home.service";
import { AlertSharedService } from "../../../_services/index";
import {
  notification_line1,
  notification_line2,
  notification_line3,
  notification_ackinfo,
  SessionCheck,
  AuthFailedMessage
} from "../../../app-general";
import {  
  notification_buttons,
  notification_staffinfomration,
  generateAlertMessage,
  getFeatureNeedtoShow,
} from "../../../app-general";
import { RollbarService } from "../../../rollbar";
import { AppComponent } from "../../../app.component";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { HttpClient } from "@angular/common/http";
import * as Rollbar from "rollbar";
import { ApiService } from "../../../api.service";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
//import { NgxSpinnerService } from 'ngx-spinner';
// import * as _ from "lodash";

@Component({
  selector: "app-alertnotification",
  templateUrl: "./alertnotification.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./alertnotification.component.scss"],
})
export class AlertnotificationComponent implements OnInit, OnDestroy {
  // public static isnotifyinfraClosed = true;
  public watcher: Observable<boolean>;
  columns = 4;
  htmlToAdd;
  htmlToAddload;
  Imgurl: string = appsettings.IMAGE_FOLDER;
  residentImgUrl: string = appsettings.RESIDENT_FOLDER;
  staffImgUrl: string = appsettings.STAFF_FOLDER;
  Staffdata: StaffData[];
  NearestStaffdata: NearestStaffdata[];

  checknetwork;
  AlertID;
  AlertType;
  AlertData;
  AlertInfo;
  RoomID;
  FloorID;
  SharedAlertLoadDetails: SharedAlertLoadData;
  alertinfofromshared: SharedAlertData[];
  alertdata: alertinfo[];
  notify_alive = true;
  pollingData: any;
  getalertinfo;
  notify_status = false;

  notificationTime1;
  notificationTime2;
  notificationTime3;
  intervalId;
  notifycurrentTime1: string;
  notifycurrentTime2: string;
  notifycurrentTime3: string;
  htmlcontent;
  alertnotifytime;
  _alertMinutes;
  chkalertType;
  getclassname = "";
  checkdayforall = 0;
  checktimeforalert = 0;
  getcurrentseconds;

  updatehours1;
  updateminutes1;
  updateseconds1;
  updatehours2;
  updateminutes2;
  updateseconds2;

  updatehours3;
  updateminutes3;
  updateseconds3;
  loading = false;
  AlertResStaff;
  DeviceSubType;
  DeviceType;
  CountNotifyFirstTest = 0;
  alertreturnVal = 0;
  LocationType;
  AlertApproxlocation;
  getNotificationdetails;
  cancel_TagId;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  private ReloadOpenAlertURL = appsettings.URLinfo + "reloadOpenAlerts";
  public reload_open_Alerts;
  constructor(
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private homeService: HomeService,
    private alertSharedService: AlertSharedService,
    @Inject(DOCUMENT) private document: any,    
    @Inject(RollbarService) private rollbar: Rollbar,
    public thisDialogRef: MatDialogRef<AlertnotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private http: HttpClient,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.AlertID = data.AlertId;
      this.AlertType = data.AlertType;
      this.RoomID = data.RoomId;
      this.FloorID = data.FloorId;
      this.AlertData = data.AlertData;
      this.AlertResStaff = data.AlertResStaff;
      this.DeviceSubType = data.DeviceSubType;
      this.DeviceType = data.DeviceType;
      this.Staffdata = data.Staffdata;
      this.LocationType = data.LocationType;

      this.NearestStaffdata = data.NearestStaffdata;
      this.breakpoint$.subscribe(() => this.breakpointChanged());          
    } catch (err) {
      throw new Error(
        "alertnotification.component.ts" + " - constructor() - " + err.message
      );
    }
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.Small) || this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 1;
    }
  }
  
  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.alertSharedService
        .currentSharedAlert()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedAlertDetails: SharedAlertData[]) => {
          this.alertinfofromshared = SharedAlertDetails;
          if (
            this.alertinfofromshared != undefined &&
            this.AlertID != undefined
          ) {
            const _AlertID = this.AlertID;
            this.AlertData = this.alertinfofromshared.filter(function (e) {
              return e.AlertId == _AlertID;
            });

            this.htmlToAdd = this.shownotificationDetails(
              this.NearestStaffdata,
              this.Staffdata,
              this.AlertData
            );
          }
        });

      this.alertSharedService
        .currentsharedAlertload()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedAlertLoadDetails: SharedAlertLoadData) => {
          this.SharedAlertLoadDetails = SharedAlertLoadDetails;
        });
      this.htmlToAdd = this.shownotificationDetails(
        this.NearestStaffdata,
        this.Staffdata,
        this.AlertData
      );
    } catch (err) {
      throw new Error(
        "alertnotification.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  getRenotifyData() {
    const dateformat = localStorage.getItem("dateFormat") ?? "";
    const cancelOrCompleteSec = localStorage.getItem("PAST_ALERT_LINGER") ?? "";

    const clicknotifyItem: getRenotityData = {
      authKey: this.authkey,
      alertId: parseInt(this.AlertID),
      dateFormat: dateformat,
      cancelOrCompleteSec: cancelOrCompleteSec,
    };
    this.homeService
      .getRenotifyData(clicknotifyItem)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((alertdata: any[]) => {
        this.alertdata = alertdata;
        this.getNotifyData();
      });
  }
  getNotifyData(): void {
    this.htmlToAdd = this.shownotificationDetails(
      this.NearestStaffdata,
      this.Staffdata,
      this.alertdata
    );
  }

  // close alert Notification Dialog
  public onCloseNotifyAlert = () => {
    this.notify_alive = false;
    this.notify_status = false;
    this.intervalId = 0;
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  shownotificationDetails(NearestStaffDetails, staffdetails, AlertData) {
    let strNotification = "";
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (this.notify_status == true) {
        AlertData = [];
        //AlertData = this.alertSharedService;
        AlertData = this.alertdata;
      }

      const AlertNotify = AlertData || [];

      this.notify_alive = true;
      this.loading = false;
      // const elementcontent = document.getElementById("divnotifycontent");
      // elementcontent.className = "divNotify enablecontent";
      this.alertNotifyAddEnableDisable(enumEnableDisable.Enable);

      let alertlevelImg = "";
      for (const AlertNotifyData of AlertNotify) {
        alertlevelImg = "assets/images/" + AlertNotifyData.LevelPhoto;
        const Staffphoto = this.staffImgUrl + AlertNotifyData.StaffPhoto;
        const Alert_TimeofAlert = AlertNotifyData.AlertUpdatedTime;

        //line 1
        if (
          AlertNotifyData.DeviceDetails != "Pager Communication" &&
          AlertNotifyData.TruViewEventType !=
            Alert_Event_Type.Appliance_UPS_IN_Battery &&
          AlertNotifyData.TruViewEventType !=
            Alert_Event_Type.Appliance_UPS_OUT_Battery &&
          AlertNotifyData.TruViewEventType !=
            Alert_Event_Type.Appliance_UPS_Unplug &&
          AlertNotifyData.TruViewEventType !=
            Alert_Event_Type.Appliance_LostInternet
        )
          strNotification += notification_line1(
            AlertNotifyData.Fontcolor,
            AlertNotifyData.AlertNotifiedBy,
            alertlevelImg,
            AlertNotifyData.AlertTyp,
            AlertNotifyData.DeviceType,
            AlertNotifyData.AlertDescript
          );
        //line 2
        strNotification += notification_line2(
          Alert_TimeofAlert,
          AlertNotifyData.Fontcolor,
          AlertNotifyData.AlertTime,
          1,
          AlertNotifyData.AlertId,
          AlertNotifyData.StrAckedData,
          AlertNotifyData.StatusDec
        );
        //line 3
        strNotification += notification_line3(
          AlertNotifyData.AlertSubName,
          AlertNotifyData.AlertType,
          AlertNotifyData.Fontcolor,
          AlertNotifyData.EventName,
          AlertNotifyData.TagId,
          AlertNotifyData.DeviceType,
          AlertNotifyData.SubTypeName,
          AlertNotifyData.AlertFulltypeName,
          AlertNotifyData.AlerttypeName,
          AlertNotifyData.TruViewEventType
        );

        if (
          AlertNotifyData.AckStatus == enumAlertAckStatus.Accepted ||
          AlertNotifyData.AckStatus == enumAlertAckStatus.Presence ||
          AlertNotifyData.AckStatus == enumAlertAckStatus.Caregiver_Presence ||
          AlertNotifyData.AckStatus ==
            enumAlertAckStatus.Caregiver_Alert_Presence ||
          AlertNotifyData.AckStatus == enumAlertAckStatus.Complete ||
          AlertNotifyData.AckStatus == enumAlertAckStatus.Cancel
        ) {
          //acked information
          strNotification += notification_ackinfo(
            Staffphoto,
            AlertNotifyData.AckedName,
            AlertNotifyData.StatusDec,
            AlertNotifyData.StrAckedData,
            AlertNotifyData.AckedMethod
          );
        }

        if (
          staffdetails &&
          AlertNotifyData.AckStatus == enumAlertAckStatus.Notified &&
          AlertNotifyData.DeviceDetails != "Pager Communication" &&
          AlertNotifyData.TruViewEventType !=
            Alert_Event_Type.Appliance_UPS_IN_Battery &&
          AlertNotifyData.TruViewEventType !=
            Alert_Event_Type.Appliance_UPS_OUT_Battery &&
          AlertNotifyData.TruViewEventType !=
            Alert_Event_Type.Appliance_UPS_Unplug &&
          AlertNotifyData.TruViewEventType !=
            Alert_Event_Type.Appliance_LostInternet
        ) {
          const notifystaffdetails = staffdetails.filter(function (e1) {
            return e1.AlarmSubTypeId == AlertNotifyData.SubTypeId; //&& AlertNotifyData.AckedTime == null
          });
          this.getNotificationdetails = notifystaffdetails;
          this.cancel_TagId = AlertData[0].TagId;

          if (notifystaffdetails.length > 0) {
            for (
              let nnotify = 0;
              nnotify < notifystaffdetails.length;
              nnotify++
            ) {
              const alertnotifytime = "";
              const format = /[ !@#$%^&*()_+\-=\]{};':"\\|,.<>?]/;

              //////////staff details//////////
              strNotification += '<div class="row">';

              if (AlertNotifyData.AlertLevel == nnotify + 1) {
                strNotification += '<div class="clsstaffinfo">';
                strNotification += '<table><tr><td class="clsalignleft">';
                if (AlertNotifyData.AlertNotifiedBy == "Notify") {
                  strNotification +=
                    '<div><img src="assets/images/Alerts-NotificationPopup-Escalation' +
                    AlertNotifyData.AlertLevel +
                    '-Gray.png">\t\t';
                } else
                  strNotification +=
                    '<div><img src="assets/images/Alerts-NotificationPopup-Escalation' +
                    AlertNotifyData.AlertLevel +
                    '-Red.png">\t\t';

                if (
                  notifystaffdetails[nnotify].StaffName != null &&
                  notifystaffdetails[nnotify].StaffName != ""
                ) {
                  if (
                    format.test(notifystaffdetails[nnotify].StaffName) == true
                  ) {
                    if (notifystaffdetails[nnotify].StaffName != null) {
                      if (notifystaffdetails[nnotify].StaffName.length > 0) {
                        const SName =
                          notifystaffdetails[nnotify].StaffName.split(",");
                        let staffFullName;
                        if (SName.length > 0) {
                          for (let s = 0; s < SName.length; s++) {
                            staffFullName = SName[s];
                            if (s == 0) {
                              if (SName[s].length > 40) {
                                SName[s] = SName[s].slice(0, 20) + "..";
                                strNotification +=
                                  '<div class="tooltip notfirstname">' +
                                  SName[s] +
                                  '<span class="tooltiptext">' +
                                  staffFullName +
                                  "</span></div>";
                                strNotification += "<br>";
                              } else {
                                strNotification +=
                                  '<span class="notpopupfontSize notfirstname">' +
                                  SName[s] +
                                  "</span>";
                                strNotification += "<br>";
                              }
                            } else {
                              if (SName[s].length > 40) {
                                SName[s] = SName[s].slice(0, 20) + "..";
                                strNotification +=
                                  '<div class="tooltip notpopupfontSize clsnotifyname">' +
                                  SName[s] +
                                  '<span class="tooltiptext">' +
                                  staffFullName +
                                  "</span></div>";
                                strNotification += "<br>";
                              } else {
                                strNotification +=
                                  '<span class="alignstaffroll notpopupfontSize">' +
                                  SName[s] +
                                  "</span>";
                                strNotification += "<br>";
                              }
                            }
                          }
                        }
                      }
                    }
                  } else {
                    strNotification += notification_staffinfomration(
                      notifystaffdetails[nnotify].StaffName,
                      notifystaffdetails[nnotify].ContactNumber
                    );
                  }
                }
                if (
                  notifystaffdetails[nnotify].Designation != null &&
                  notifystaffdetails[nnotify].Designation.trim() != ""
                ) {
                  if (
                    format.test(notifystaffdetails[nnotify].Designation) == true
                  ) {
                    const Sdesignation =
                      notifystaffdetails[nnotify].Designation.split(",");
                    if (Sdesignation.length > 0) {
                      for (let d = 0; d < Sdesignation.length; d++) {
                        if (d == 0) {
                          strNotification +=
                            '<span class="notpopupfontSize notfirstname">' +
                            Sdesignation[d] +
                            "</span>";
                          strNotification += "<br>";
                        } else {
                          strNotification +=
                            '<span class="alignstaffroll notpopupfontSize">' +
                            Sdesignation[d] +
                            "</span>";
                          strNotification += "<br>";
                        }
                      }
                    }
                  } else {
                    strNotification +=
                      '<span class="notpopupfontSize notfirstname">' +
                      notifystaffdetails[nnotify].Designation +
                      "</span>";
                    strNotification += "<br>";
                  }
                }
                if (notifystaffdetails[nnotify].GroupName != "") {
                  if (
                    format.test(notifystaffdetails[nnotify].GroupName) == true
                  ) {
                    const RSGroupName =
                      notifystaffdetails[nnotify].GroupName.split(",");
                    if (RSGroupName.length > 0) {
                      let groupName;
                      let groupFullName;
                      for (let d = 0; d < RSGroupName.length; d++) {
                        if (d == 0) {
                          groupName = RSGroupName[d];
                          if (groupName.length >= 30) {
                            groupFullName = groupName;
                            groupName = groupName.slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notfirstname">' +
                              groupName +
                              '<span class="tooltiptext">' +
                              groupFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else {
                            strNotification +=
                              '<span class="notpopupfontSize notfirstname">' +
                              groupName +
                              "</span>";
                            strNotification += "<br>";
                          }
                        } else {
                          groupName = RSGroupName[d];
                          if (groupName.length >= 30) {
                            groupFullName = groupName;
                            groupName = groupName.slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notfirstname">' +
                              groupName +
                              '<span class="tooltiptext">' +
                              groupFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else
                            strNotification +=
                              '<span class="alignstaffroll notpopupfontSize">' +
                              groupName +
                              "</span>";
                          strNotification += "<br>";
                        }
                      }
                    }
                  } else {
                    let groupName;
                    let groupFullName;
                    groupName = notifystaffdetails[nnotify].GroupName;

                    if (groupName.length >= 30) {
                      groupFullName = groupName;
                      groupName = groupName.slice(0, 20) + "..";
                      strNotification +=
                        '<div class="tooltip notfirstname">' +
                        groupName +
                        '<span class="tooltiptext">' +
                        groupFullName +
                        "</span></div>";
                      strNotification += "<br>";
                    } else {
                      strNotification +=
                        '<span class="notpopupfontSize notfirstname">' +
                        groupName +
                        "</span>";
                      strNotification += "<br>";
                    }
                  }
                }
                strNotification += "</div>";
                strNotification += "</td>";
                strNotification += notification_buttons(
                  AlertNotifyData.notifiedBtn1,
                  AlertNotifyData.notifiedVal1,
                  AlertNotifyData.notifiedBtn2,
                  AlertNotifyData.notifiedVal2,
                  AlertNotifyData.notifiedBtn3,
                  AlertNotifyData.notifiedVal3,
                  alertnotifytime,
                  nnotify
                );
                strNotification += "</div>";
                strNotification += '<hr class="clsline">';
              } else if (AlertNotifyData.AlertLevel > nnotify + 1) {
                strNotification += '<div class="clsstaffinfo">';
                strNotification += '<table><tr><td class="clsalignleft">';
                if (AlertNotifyData.AlertNotifiedBy == "Notify") {
                  strNotification +=
                    '<div><img src="assets/images/Alerts-NotificationPopup-Escalation' +
                    (nnotify + 1) +
                    '-Gray.png">\t\t';
                } else
                  strNotification +=
                    '<div><img src="assets/images/Alerts-NotificationPopup-Escalation' +
                    (nnotify + 1) +
                    '-Red.png">\t\t';
                if (
                  notifystaffdetails[nnotify].StaffName != null &&
                  notifystaffdetails[nnotify].StaffName != ""
                ) {
                  if (
                    format.test(notifystaffdetails[nnotify].StaffName) == true
                  ) {
                    const SName =
                      notifystaffdetails[nnotify].StaffName.split(",");
                    let staffFullName;
                    if (SName.length > 0) {
                      for (let s = 0; s < SName.length; s++) {
                        staffFullName = SName[s];
                        if (s == 0) {
                          if (SName[s].length > 40) {
                            SName[s] = SName[s].slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notfirstname">' +
                              SName[s] +
                              '<span class="tooltiptext">' +
                              staffFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else {
                            strNotification +=
                              '<span class="notpopupfontSize notfirstname">' +
                              SName[s] +
                              "</span>";
                            strNotification += "<br>";
                          }
                        } else {
                          if (SName[s].length > 40) {
                            SName[s] = SName[s].slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notpopupfontSize clsnotifyname">' +
                              SName[s] +
                              '<span class="tooltiptext">' +
                              staffFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else {
                            strNotification +=
                              '<span class="alignstaffroll notpopupfontSize">' +
                              SName[s] +
                              "</span>";
                            strNotification += "<br>";
                          }
                        }
                      }
                    }
                  } else {
                    strNotification += notification_staffinfomration(
                      notifystaffdetails[nnotify].StaffName,
                      notifystaffdetails[nnotify].ContactNumber
                    );
                  }
                }
                if (
                  notifystaffdetails[nnotify].Designation != null &&
                  notifystaffdetails[nnotify].Designation.trim() != ""
                ) {
                  if (
                    format.test(notifystaffdetails[nnotify].Designation) == true
                  ) {
                    const Sdesignation =
                      notifystaffdetails[nnotify].Designation.split(",");
                    if (Sdesignation.length > 0) {
                      for (let d = 0; d < Sdesignation.length; d++) {
                        if (d == 0) {
                          strNotification +=
                            '<span class="notpopupfontSize notfirstname">' +
                            Sdesignation[d] +
                            "</span>";
                          strNotification += "<br>";
                        } else {
                          strNotification +=
                            '<span class="alignstaffroll notpopupfontSize">' +
                            Sdesignation[d] +
                            "</span>";
                          strNotification += "<br>";
                        }
                      }
                    }
                  } else {
                    strNotification +=
                      '<span class="notpopupfontSize notfirstname">' +
                      notifystaffdetails[nnotify].Designation +
                      "</span>";
                    strNotification += "<br>";
                  }
                }
                if (notifystaffdetails[nnotify].GroupName != "") {
                  if (
                    format.test(notifystaffdetails[nnotify].GroupName) == true
                  ) {
                    const RSGroupName =
                      notifystaffdetails[nnotify].GroupName.split(",");
                    if (RSGroupName.length > 0) {
                      let groupName;
                      let groupFullName;
                      for (let d = 0; d < RSGroupName.length; d++) {
                        if (d == 0) {
                          groupName = RSGroupName[d];
                          if (groupName.length >= 30) {
                            groupFullName = groupName;
                            groupName = groupName.slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notfirstname">' +
                              groupName +
                              '<span class="tooltiptext">' +
                              groupFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else {
                            strNotification +=
                              '<span class="notpopupfontSize notfirstname">' +
                              groupName +
                              "</span>";
                            strNotification += "<br>";
                          }
                        } else {
                          groupName = RSGroupName[d];
                          if (groupName.length >= 30) {
                            groupFullName = groupName;
                            groupName = groupName.slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notfirstname">' +
                              groupName +
                              '<span class="tooltiptext">' +
                              groupFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else
                            strNotification +=
                              '<span class="alignstaffroll notpopupfontSize">' +
                              groupName +
                              "</span>";
                          strNotification += "<br>";
                        }
                      }
                    }
                  } else {
                    let groupName;
                    let groupFullName;
                    groupName = notifystaffdetails[nnotify].GroupName;

                    if (groupName.length >= 30) {
                      groupFullName = groupName;
                      groupName = groupName.slice(0, 20) + "..";
                      strNotification +=
                        '<div class="tooltip notfirstname">' +
                        groupName +
                        '<span class="tooltiptext">' +
                        groupFullName +
                        "</span></div>";
                      strNotification += "<br>";
                    } else {
                      strNotification +=
                        '<span class="notpopupfontSize notfirstname">' +
                        groupName +
                        "</span>";
                      strNotification += "<br>";
                    }
                  }
                }
                strNotification += "</div>";
                strNotification += "</td>";
                strNotification += notification_buttons(
                  AlertNotifyData.notifiedBtn1,
                  AlertNotifyData.notifiedVal1,
                  AlertNotifyData.notifiedBtn2,
                  AlertNotifyData.notifiedVal2,
                  AlertNotifyData.notifiedBtn3,
                  AlertNotifyData.notifiedVal3,
                  alertnotifytime,
                  nnotify
                );
                strNotification += "</div>";
                strNotification += '<hr class="clsline">';
              } else {
                strNotification += '<div class="clsstaffinfo">';
                strNotification += '<table><tr><td class="clsalignleft">';
                strNotification +=
                  '<div><img src="assets/images/Alerts-NotificationPopup-Escalation' +
                  (nnotify + 1) +
                  '-Gray.png">\t\t';
                if (
                  notifystaffdetails[nnotify].StaffName != null &&
                  notifystaffdetails[nnotify].StaffName != ""
                ) {
                  if (
                    format.test(notifystaffdetails[nnotify].StaffName) == true
                  ) {
                    const SName =
                      notifystaffdetails[nnotify].StaffName.split(",");
                    let staffFullName;
                    if (SName.length > 0) {
                      for (let s = 0; s < SName.length; s++) {
                        staffFullName = SName[s];
                        if (s == 0) {
                          if (SName[s].length > 40) {
                            SName[s] = SName[s].slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notfirstname">' +
                              SName[s] +
                              '<span class="tooltiptext">' +
                              staffFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else {
                            strNotification +=
                              '<span class="notpopupfontSize notfirstname">' +
                              SName[s] +
                              "</span>";
                            strNotification += "<br>";
                          }
                        } else {
                          if (SName[s].length > 40) {
                            SName[s] = SName[s].slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notpopupfontSize clsnotifyname">' +
                              SName[s] +
                              '<span class="tooltiptext">' +
                              staffFullName +
                              "</span></div>";
                          } else {
                            // strNotification += '<br>'
                            strNotification +=
                              '<span class="alignstaffroll notpopupfontSize">' +
                              SName[s] +
                              "</span>";
                            strNotification += "<br>";
                          }
                        }
                      }
                    }
                  } else {
                    strNotification += notification_staffinfomration(
                      notifystaffdetails[nnotify].StaffName,
                      notifystaffdetails[nnotify].ContactNumber
                    );
                  }
                }
                if (
                  notifystaffdetails[nnotify].Designation != null &&
                  notifystaffdetails[nnotify].Designation.trim() != ""
                ) {
                  if (
                    format.test(notifystaffdetails[nnotify].Designation) == true
                  ) {
                    const Sdesignation =
                      notifystaffdetails[nnotify].Designation.split(",");
                    if (Sdesignation.length > 0) {
                      for (let d = 0; d < Sdesignation.length; d++) {
                        if (d == 0) {
                          strNotification +=
                            '<span class="notpopupfontSize notfirstname">' +
                            Sdesignation[d] +
                            "</span>";
                          strNotification += "<br>";
                        } else {
                          strNotification +=
                            '<span class="alignstaffroll notpopupfontSize">' +
                            Sdesignation[d] +
                            "</span>";
                          strNotification += "<br>";
                        }
                      }
                    }
                  } else {
                    strNotification +=
                      '<span class="notpopupfontSize notfirstname">' +
                      notifystaffdetails[nnotify].Designation +
                      "</span>";
                    strNotification += "<br>";
                  }
                }
                if (notifystaffdetails[nnotify].GroupName != "") {
                  if (
                    format.test(notifystaffdetails[nnotify].GroupName) == true
                  ) {
                    const RSGroupName =
                      notifystaffdetails[nnotify].GroupName.split(",");
                    if (RSGroupName.length > 0) {
                      let groupName;
                      let groupFullName;
                      for (let d = 0; d < RSGroupName.length; d++) {
                        if (d == 0) {
                          groupName = RSGroupName[d];
                          if (groupName.length >= 30) {
                            groupFullName = groupName;
                            groupName = groupName.slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notfirstname">' +
                              groupName +
                              '<span class="tooltiptext">' +
                              groupFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else {
                            strNotification +=
                              '<span class="notpopupfontSize notfirstname">' +
                              groupName +
                              "</span>";
                            strNotification += "<br>";
                          }
                        } else {
                          groupName = RSGroupName[d];
                          if (groupName.length >= 30) {
                            groupFullName = groupName;
                            groupName = groupName.slice(0, 20) + "..";
                            strNotification +=
                              '<div class="tooltip notfirstname">' +
                              groupName +
                              '<span class="tooltiptext">' +
                              groupFullName +
                              "</span></div>";
                            strNotification += "<br>";
                          } else
                            strNotification +=
                              '<span class="alignstaffroll notpopupfontSize">' +
                              groupName +
                              "</span>";
                          strNotification += "<br>";
                        }
                      }
                    }
                  } else {
                    let groupName;
                    let groupFullName;
                    groupName = notifystaffdetails[nnotify].GroupName;

                    if (groupName.length >= 30) {
                      groupFullName = groupName;
                      groupName = groupName.slice(0, 20) + "..";
                      strNotification +=
                        '<div class="tooltip notfirstname">' +
                        groupName +
                        '<span class="tooltiptext">' +
                        groupFullName +
                        "</span></div>";
                      strNotification += "<br>";
                    } else {
                      strNotification +=
                        '<span class="notpopupfontSize notfirstname">' +
                        groupName +
                        "</span>";
                      strNotification += "<br>";
                    }
                  }
                }
                strNotification += "</div>";
                strNotification += "</td>";
                strNotification += notification_buttons(
                  AlertNotifyData.notifiedBtn1,
                  AlertNotifyData.notifiedVal1,
                  AlertNotifyData.notifiedBtn2,
                  AlertNotifyData.notifiedVal2,
                  AlertNotifyData.notifiedBtn3,
                  AlertNotifyData.notifiedVal3,
                  alertnotifytime,
                  nnotify
                );
                strNotification += "</div>";
                strNotification += '<hr class="clsline">';
              }
              strNotification += "</div>";
            }

            ////////////////////////////Nearest Staff/////////////////
            // let nstaffdetails = notifystaffdetails;
            // let NearestStaff = [] as any[];

            // if (NearestStaffDetails) {
            //   NearestStaff = NearestStaffDetails;
            //   if (NearestStaff.length > 0) {
            //     //neares staff heading
            //     strNotification += notification_neareststaff_heading();
            //     for (let s = 0; s < NearestStaff.length; s++) {
            //       //neares staff details
            //       strNotification += notification_neareststaff_details(
            //         NearestStaff[s].FirstName,
            //         NearestStaff[s].ContactNumber
            //       );
            //     }
            //   }
            //   NearestStaff = [];
            //   NearestStaffDetails = [];
            // }
          }
        }
        //////////////////////////////END////////////////////////
        let bNeedShow = true;
        if (
          (AlertNotifyData.AlertType == enumAlertType.Pendant ||
            AlertNotifyData.AlertType == enumAlertType.Pull_Cord) &&
          localStorage.getItem("ENABLE_UL2560") == "1"
        ) {
          bNeedShow = false;
        }

        if (bNeedShow == true) {
          //thisis check ENABLE_UL2560 is true then don't cancel the alert for Geo Pedant and Pullcord alerts.
          if (
            getFeatureNeedtoShow(
              enumUIFeatureView.Home,
              enumUIAction.Alert_Cancel
            ) == enumUIView.Show &&
            AlertNotifyData.AckStatus != enumAlertAckStatus.Cancel
          ) {
            strNotification += '<div class="row clsclearDiv1">';
            strNotification +=
              '<span class="notSeconddiv notClearAlert" id="divClearAlert" data-CancelId=' +
              AlertNotifyData.AlertId +
              ">";
            strNotification += "Cancel Alert";
            strNotification += "</span>";
            strNotification += "</div>";
          } else {
            strNotification += '<div class="row clsclearDiv1">';
            strNotification += "<span>&nbsp;</span>";
            strNotification += "</div>";
          }
        } else {
          strNotification += '<div class="row clsclearDiv1">';
          strNotification += "<span>&nbsp;</span>";
          strNotification += "</div>";
        }
        strNotification += "</div>";
        strNotification += "</div>";
      }
      return strNotification;
    } catch (err) {
      throw new Error(
        "alertnotification.component.ts" +
          " - shownotificationDetails() - " +
          err.message
      );
    }
  }

  CallaccessCode(e) {
    try {
      e = e || window.event;
      const target = e.target || e.srcElement;

      if (target.className == "notSeconddiv notClearAlert") {
        const notClearAlert =
          this.document.getElementsByClassName("notClearAlert");
        notClearAlert[0].className =
          "notClearAlert notSeconddiv DisableClearAlert";

        if (
          this.getNotificationdetails == undefined ||
          this.getNotificationdetails.length > 0
        )
          this.cancelnotifypopup();
        else {
          this.cancelnotifypopup();
          this.ReloadOpenAlert();
        }
      } else if (
        target.className == "notReNotify_1" ||
        target.className == "notReNotify_2" ||
        target.className == "notReNotify_3" ||
        target.className == "notNotified_1" ||
        target.className == "notNotified_2" ||
        target.className == "notNotified_3" ||
        target.className == "notNotify_2" ||
        target.className == "notNotify_3"
      ) {
        let stafflevel = target.className;
        stafflevel = stafflevel.split("_");
        let UpdateLevel = 0;

        if (stafflevel[1] == 1) this.getclassname = target.className;
        else if (stafflevel[1] == 2) this.getclassname = target.className;
        else if (stafflevel[1] == 3) this.getclassname = target.className;

        if (
          target.className == "notNotify_2" ||
          target.className == "notNotify_3"
        ) {
          UpdateLevel = 1;
        }
        const notifiedItem: reNotifyAlert = {
          authKey: this.authkey,
          alertId: this.AlertID,
          level: parseInt(stafflevel[1]),
          updateLevel: UpdateLevel,
          alertType: this.AlertType,
        };
        this.renotifyAlert(notifiedItem);
      }
    } catch (err) {
      throw new Error(
        "alertnotification.component.ts" +
          " - CallaccessCode() - " +
          err.message
      );
    }
  }

  cancelnotifypopup() {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    if (localStorage.getItem("userid") == null) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }
    const getcancelalertdata = this.AlertData;
    const ConfirmMessageData = {
      ConfirmMessage: "Are you sure you want to Cancel this Alert?",
    };
    const dialogRef = this.dialog.open(ConfirmMessageComponent, {
      disableClose: true,
      width: "auto",
      height: "auto",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: ConfirmMessageData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Yes") {
        try {
          if (!SessionCheck(this.router)) {
            this.thisDialogRef.close("Cancel");
            return;
          }
          this.alertreturnVal = 1;
          const staffId = localStorage.getItem("userid") ?? "";
          const cancelItem: cancelAlert = {
            authKey: this.authkey,
            staffId: parseInt(staffId),
            alertId: parseInt(this.AlertID),
            status: enumAlertStatus.Canceled.toString(),
          };
          this.apiService
            .cancelAlert(cancelItem)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((canceledResponse: any[]) => {
              if (canceledResponse["apiReturn"] == "Success")
                generateAlertMessage(
                  this.dialog,
                  "Alert cancelled successfully."
                );
              if (getcancelalertdata[0].DeviceDetails == "Pager Communication")
                this.apiService.reloadPagerAlert();
              this.notify_alive = false;
              this.thisDialogRef.close("CanceledAlert");
            });

          const _SharedAlertLoadDetails = {
            SharedreturnVal: this.alertreturnVal,
            SharedNotifiedVal: 9,
            AlertId: this.AlertID,
          };

          this.alertSharedService.changecanceledAlert(_SharedAlertLoadDetails);
        } catch (err) {
          throw new Error(
            "clearalert.component.ts - onClickedYes() - " + err.message
          );
        }
      } else {
        const notClearAlert =
          this.document.getElementsByClassName("notClearAlert");
        notClearAlert[0].className = "notSeconddiv notClearAlert";
        return false;
      }
    });
  }

  renotifyAlert(notifiedItem) {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    try {
      this.loading = true;
      // const elementcontent = document.getElementById("divnotifycontent");
      // elementcontent.className = "divNotify disablecontent";
      this.alertNotifyAddEnableDisable(enumEnableDisable.Disable);

      this.notify_status = true;
      this.notify_alive = false;
      this.checkdayforall = 0;
      this.checktimeforalert = 0;

      //check the internet connection found or not.

      this.checknetwork = AppComponent.SharedInternetConnection;
      if (this.checknetwork != undefined) {
        if (this.checknetwork == 0) {
          generateAlertMessage(
            this.dialog,
            "Notification failed, due to no Internet connectivity."
          );
          this.loading = false;
          // elementcontent.className = "divNotify enablecontent";
          this.alertNotifyAddEnableDisable(enumEnableDisable.Enable);
        } else {
          this.homeService
            .renotifyAlert(notifiedItem)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((NotifiedResponse: any[]) => {
              if (NotifiedResponse["apiReturn"] == "Success") {
                this.getRenotifyData();
              } 
              else if (NotifiedResponse["apiReturn"] == "Invalid Auth Key") {
                this.thisDialogRef.close("Cancel");
                AuthFailedMessage(this.dialog, this.router);
                return;
              }
              else {
                this.loading = false;
                // elementcontent.className = "divNotify enablecontent";
                this.alertNotifyAddEnableDisable(enumEnableDisable.Enable);
                generateAlertMessage(
                  this.dialog,
                  "Notification failed, please retry."
                );
              }
            });
        }
      }
    } catch (err) {
      throw new Error(
        "alertnotification.component.ts" + " - renotifyAlert() - " + err.message
      );
    }
  }

  ReloadOpenAlert() {
    try {
      const AlertData = { AlertId: this.AlertID, TagId: this.cancel_TagId };
      this.http
        .get(this.ReloadOpenAlertURL, { params: AlertData })
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          console.log(res);
        });
    } catch (err) {
      throw new Error(
        "Notification.service.ts" + " - reloadlive() - " + err.message
      );
    }
  }

  alertNotifyAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("divnotifycontent");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "divNotify enablecontent";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "divNotify disablecontent";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
