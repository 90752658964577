import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Inject,
  OnDestroy,
} from "@angular/core";
import { report_leftmenu } from "../../../app-settings";
import { leftmenu } from "../report";
import { ReportService } from "../report.service";
import { AlertSharedService } from "../../../_services/index";
import { SharedEventData } from "../../../api";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheckwithStaff } from "../../../app-general";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
@Component({
  selector: "app-report-leftbar",
  templateUrl: "./report-leftbar.component.html",
  providers: [ReportService],
  styleUrls: ["./report-leftbar.component.scss"],
})
export class ReportLeftbarComponent implements OnInit, OnDestroy {
  selecteditem = 1;
  reporteventName;
  SharedEventDetails: SharedEventData[];
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private reportService: ReportService,
    private alertSharedService: AlertSharedService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public mainLayoutService: MainLayoutService
  ) {
    if (router.url == "/campusdashboard") {
      this.selecteditem = report_leftmenu.campusdashboard;
    } else if (router.url == "/residentassessment") {
      this.selecteditem = report_leftmenu.residentassessment;
    } else if (router.url == "/alertreport") {
      this.selecteditem = report_leftmenu.alertReport;
    } else if (router.url == "/infrastructurereport") {
      this.selecteditem = report_leftmenu.infrastructureReport;
    } else if (router.url == "/locationhistory") {
      this.selecteditem = report_leftmenu.locationhistoryReport;
    } else if (router.url == "/customizelocators") {
      this.selecteditem = report_leftmenu.customizeLocatorsReport;
    } else if (router.url == "/liveview") {
      this.selecteditem = report_leftmenu.liveviewReport;
    } else if (router.url == "/customizegeo") {
      this.selecteditem = report_leftmenu.customizegeoReport;
    }
  }
  leftmenu: leftmenu[];
  ngOnInit() {
    this.leftmenu = this.reportService.getleftmenu();

    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }

    this.alertSharedService
      .currentSharedEvent()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((SharedEventDetails: SharedEventData[]) => {
        this.SharedEventDetails = SharedEventDetails;
      });
  }

  @Output() onNameChanged = new EventEmitter<string>();

  onItemSelected(reporttype): void {
    try {
      if (!SessionCheckwithStaff(this.router)) {
        return;
      }
      this.selecteditem = reporttype;
      this.reporteventName = reporttype;
      const _SharedEventDetails = [
        {
          EventName: this.reporteventName,
        },
      ];

      this.alertSharedService.changeSharedEvent(_SharedEventDetails);
      this.onNameChanged.emit(this.reporteventName);
    } catch (err) {
      throw new Error(
        "report-header.component.ts" + " - onItemSelected() - " + err.message
      );
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
