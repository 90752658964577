<perfect-scrollbar style="max-width: 1050px; max-height: 780px;">
  <div class="clsaddresident">
    <div class="add-title">
      <!-- <h3 class="addHeader" style="margin: 0 0 10px 11px;">Add Resident Details -->
      <h3 class="addHeader" style="margin:0 0 0 11px;display: flex;white-space: nowrap;">
        <img class="clsaddimage" src="assets/images/residentedit.png">Add Resident Details
        <mat-tab-group mat-align-tabs="center" (selectedTabChange)="addresident_roaming($event)"
          [(selectedIndex)]="selectedIndex" style="width:890px;">
          <mat-tab label="Info" id="tblResident" [disabled]="!addRes_Info" matRipple></mat-tab>
          <mat-tab label="Restricted Space" id="tblRoaming" [disabled]="!addRestricted_Space" matRipple></mat-tab>
        </mat-tab-group>

        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span>
      </h3>
      <mat-divider></mat-divider>
    </div>
    <!-------------------------Resident Start--------------------------------->
    <div *ngIf="showResident == true">
      <form style="padding:24px" [formGroup]="residentCreationForm" autocomplete="off" novalidate
        (ngSubmit)="createResident(residentCreationForm.value)">

        <mat-grid-list [cols]="columns" [rowHeight]="resrowHeight" style="align-items: baseline;">

          <mat-grid-tile>
            <label>First Name</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="First Name" formControlName="firstname" maxlength="30" tabindex="1"
                  (keyup)="handleInput($event)" required>
                <mat-error *ngIf="residentCreationForm.controls.firstname.hasError('required')">
                  Please enter First Name
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.firstname.hasError('pattern')">
                  Please enter valid First Name
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.firstname.hasError('maxlength')">
                  Max length 30 characters only
                </mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label class="clstxtcontrol">Provider</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="Provider" formControlName="facility" [(ngModel)]="FacilityName"
                  tabindex="9" required>
                <mat-error *ngIf="residentCreationForm.controls.facility.hasError('required')">
                  Please enter Provider
                </mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label>Last Name</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="Last Name" formControlName="lastname" maxlength="30" tabindex="2"
                  (keyup)="handleInput($event)" required>
                <mat-error *ngIf="residentCreationForm.controls.lastname.hasError('required')">
                  Please enter Last Name
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.lastname.hasError('pattern')">
                  Please enter valid Last Name
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.lastname.hasError('maxlength')">
                  Max length 30 characters only
                </mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label class="clstxtcontrol">Campus</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <mat-select formControlName="campus" placeholder="Select Campus" tabindex="10"
                  (selectionChange)="getBuildingdetails($event.value)">
                  <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId"
                    (click)="changecampus(Campus.CampusId);">
                    {{Campus.CampusName}}
                  </mat-option>
                </mat-select>
                <mat-error>Please select Campus</mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label>Spouse Name</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="Spouse Name" formControlName="spousename" maxlength="30"
                  (keyup)="handleInput($event)" tabindex="3">
                <mat-error *ngIf="residentCreationForm.controls.spousename.hasError('pattern')">
                  Please enter valid Spouse Name
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.spousename.hasError('maxlength')">
                  Max length 30 characters only
                </mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label class="clstxtcontrol">Building</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <mat-select formControlName="building" placeholder="Select Building" tabindex="11"
                  (selectionChange)="getFloorDetails($event.value)">
                  <mat-option *ngFor="let Building of Buildingdetails" [value]="Building.BuildId"
                    (click)="changebuild(Building.BuildId)">
                    {{Building.BuildingName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Please select Building
                </mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label>Date Of Birth</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;height: 49px;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;height:33.5px;">
                <input type="text" matInput [min]="startDate" [max]="CurrentDate" [matDatepicker]="picker1"
                  placeholder="MM/DD/YYYY" #pickerInput formControlName="dateofbirth" tabindex="4">
                <mat-datepicker-toggle class="clsdatepicker" [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error>Please enter valid date</mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>


          <mat-grid-tile>
            <label class="clstxtcontrol">Zone</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">

                <mat-select formControlName="floor" placeholder="Select Zone" tabindex="12"
                  (selectionChange)="getUnitDetails($event.value)">
                  <mat-option *ngFor="let Floor of Floordetails" [value]="Floor.ZoneId"
                    (click)="changefloor(Floor.ZoneId)">
                    {{Floor.ZoneName}}
                  </mat-option>
                </mat-select>
                <mat-error>Please select Zone</mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label>Gender</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside">
              <mat-radio-group class="gender" formControlName="gender" name="gender" required tabindex="5"
                (change)="radioChange($event)">
                <mat-radio-button style="margin:4px 4px 0px 15px;" value="Male">Male</mat-radio-button>
                <mat-radio-button style="margin:4px 0px 0px 24px;" value="Female">Female</mat-radio-button>
                <mat-radio-button style="margin:4px 0px 0px 24px;" value="Other">Other</mat-radio-button>  
                <mat-error *ngIf="residentCreationForm.controls.gender.hasError('required') && gender == 1"
                  class="clsmatgender">Please select Gender</mat-error>
              </mat-radio-group>

            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label class="clstxtcontrol">Space</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <mat-select placeholder="Select Space" formControlName="unit" tabindex="13">
                  <mat-option *ngFor="let Unit of Unitdetails" [value]="Unit.UnitId" (click)="changeunit(Unit.UnitId)">
                    {{Unit.UnitName}}
                  </mat-option>
                </mat-select>
                <mat-error>Please select Space</mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label>Photo</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="padding:0px 0px 0px 15px;">
              <img class="clsresidentimg" [src]="residentimageUrl" (click)='clkimageInput.click()' />
              <input type="file" class="clschoosebutton text-inside-grid" placeholder="Photo" formControlName="photo"
                #clkimageInput (change)="onChange($event.target.files)" accept=".png, .jpg, .bmp" />
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label class="clstxtcontrol">Phone</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="Phone Number" formControlName="phone" maxlength="10" tabindex="14"
                  (keyup)="handleInput($event)">
                <mat-error *ngIf="residentCreationForm.controls.phone.hasError('pattern')">
                  Please enter valid Phone Number
                </mat-error>
                <mat-error
                  *ngIf="residentCreationForm.controls.phone.hasError('maxlength') || residentCreationForm.controls.phone.hasError('minlength')">
                  Phone number should be 10 digits
                </mat-error>
                <mat-icon matSuffix>phone</mat-icon>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label>Tag Id</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="Tag Id" id="restagid" formControlName="tagid" maxlength="10" tabindex="6"
                  (keyup)="handleInput($event)" required>
                <mat-error *ngIf="residentCreationForm.controls.tagid.hasError('required')">
                  Please enter Tag Id
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.tagid.hasError('pattern')">
                  Please enter the valid Tag Id
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.tagid.hasError('maxlength')">
                  Tag Id between 1 and 1073741823
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.tagid.hasError('max')">
                  Tag Id between 1 and 1073741823
                </mat-error>
                <mat-error *ngIf="residentCreationForm.controls.tagid.hasError('min')">
                  Tag Id between 1 and 1073741823
                </mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

              <!-- <mat-grid-tile>
                <label class="clstxtcontrol">Pager</label>
               </mat-grid-tile>
               <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">           
                    <input matInput placeholder="Pager" id="respager" formControlName="pager" maxlength="20" tabindex="15" (keyup)="handleInput($event)">
                    <mat-error *ngIf="residentCreationForm.controls.pager.hasError('pattern')">
                      Please enter valid Pager Number
                      </mat-error>
                  </mat-form-field>
                </div>                   
                </mat-grid-tile> -->
                
                <mat-grid-tile>
                  <label class="clstxtcontrol">Group(s)</label>
                 </mat-grid-tile>
                 <mat-grid-tile colspan="3">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">           
                              <mat-select placeholder="{{Placeholder}}" formControlName="group" tabindex="16" multiple>                               
                                  <mat-option *ngFor="let Group of Groupdetails" [value]="Group.GroupId">
                                      {{Group.GroupName}}
                                  </mat-option>  
                              </mat-select>     
    
                          </mat-form-field>
                      </div>                   
                  </mat-grid-tile>

                <mat-grid-tile>
                  <label class="">Email</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="Email address" formControlName="email" maxlength="60" tabindex="7" (keyup)="handleInput($event)">                                          
                      <mat-error *ngIf="residentCreationForm.controls.email.hasError('email') || residentCreationForm.controls.email.hasError('pattern')">
                        Please enter valid Email Id
                      </mat-error>
                      <mat-icon matSuffix>email</mat-icon>
                    </mat-form-field>
                  </div>
              </mat-grid-tile>                                                    
              
              <mat-grid-tile>
                <label class="clstxtcontrol">Subscribe to Check-In</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="3">
              <div class="text-inside">
                <mat-checkbox id="residentchkcheckin" tabindex="8" (change)="toggleCheckInVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                </div>
            </mat-grid-tile>
            
            <mat-grid-tile>
              <label class="">Disable Geo<br>Duress Button<br>in Wander Mode</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;display: flex;">
              <div style="cursor: pointer;" [matTooltip]="matTooltip" matTooltipClass="tooltip" #tooltip="matTooltip"
                  (click)="tooltip.show()"><img class="clsresWanderAlert" src="assets/images/Alerts-ErrorAlert Icon-Popup.png"></div>
              <!-- <img class="clsresWanderAlert" src="assets/images/Alerts-ErrorAlert Icon-Popup.png" style="cursor:pointer;" (click)="deleteresidentimage(data[0].ResidentID)"> -->
              <mat-checkbox id="residentchkwander" tabindex="8" (change)="toggleAddWanderVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
              </div>
          </mat-grid-tile>

          <mat-grid-tile class="clsresvoiceprompt" *ngIf="voiceLicense == 1">
            <label class="clstxtcontrol">Voice Prompt</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3" class="clsresvoicepromptText" *ngIf="voiceLicense == 1">
            <div class="text-inside" style="width:100%;">
              <mat-checkbox id="residentchkvoice" tabindex="17" (change)="toggleVoiceVisibility($event)"
                style="font-size: 14px;margin-left: .5em;">
              </mat-checkbox>
            </div>
          </mat-grid-tile>

        </mat-grid-list>

        <mat-dialog-actions class="clsbuttoncontrol">
          <button type="button" class="clsresaddbutton" mat-raised-button (click)="onCloseCancel()" tabindex="18">Cancel</button>
          <button class="clsresSavebutton" mat-raised-button tabindex="19">Save</button>&nbsp;
        </mat-dialog-actions>

      </form>
    </div>
    <!-------------------------Resident End--------------------------------->

    <!-------------------------Restricted Start--------------------------------->
    <div *ngIf="showRoaming == true">
      <div>
        <!-- <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
          let-onCheckedChange="onCheckedChange">
          <div class="form-inline row-item" style=" display: flex;">
            <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa"
              [class.fa-caret-right]="item.collapsed" [class.fa-caret-down]="!item.collapsed"
              style='margin-right: 0em;'></i>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked"
                (ngModelChange)="onCheckedChange()" [disabled]="item.disabled" [indeterminate]="item.indeterminate"
                style="margin: 0px;" />
              <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
                {{item.text}}
              </label>
              <label class="form-check-label">
                <i class="fa fa-trash" aria-hidden="true" title="Remove" (click)="removeItem(item)"></i>
              </label>
            </div>
          </div>
        </ng-template> -->
        <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand" let-onCheckedChange="onCheckedChange" let i = "index">
        
          <span *ngIf="i == 0" class="wanderdot" [ngStyle]="{'background-color': 'red'}">Egress</span>
          
          <div class="form-inline row-item" style=" display: flex;">                    
            
            <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa" [class.fa-caret-right]="item.collapsed"
              [class.fa-caret-down]="!item.collapsed" style='margin-right: 0em;'></i>
              
            <div class="form-check">            
              <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
                [disabled]="item.disabled" [indeterminate]="item.indeterminate" style="margin: 0px;" />
                <!-- <div> -->
                  
              <span class="wanderdot" *ngIf="item.text.split('|')[1] == 'R'" [ngStyle]="{'background-color': '#6f6c6c'}"></span>
              <span class="wanderdot" *ngIf="item.text.split('|')[1] == 'E'" [ngStyle]="{'background-color': '#f7d613'}"></span>
              <span class="wanderdot" *ngIf="item.text.split('|')[1] == 'L'" [ngStyle]="{'background-color': '#ff0000'}"></span>
              <span class="wanderdot" *ngIf="item.text.split('|')[1] == 'O' || item.text.split('|')[1] == 'S' || item.text.split('|')[1] == 'A' ||
                 item.text.split('|')[1] == 'D' || item.text.split('|')[1] == 'C' || item.text.split('|')[1] == 'N'" [ngStyle]="{'background-color': '#f77521'}"></span>
                <span class="wanderdot" *ngIf="item.text.split('|')[1] != 'R' && item.text.split('|')[1] != 'E' && item.text.split('|')[1] != 'L' && 
                item.text.split('|')[1] != 'O' && item.text.split('|')[1] != 'S' && item.text.split('|')[1] != 'A' &&
                item.text.split('|')[1] != 'D' && item.text.split('|')[1] != 'C' && item.text.split('|')[1] != 'N'" [ngStyle]="{'background-color': '#fffffff'}"></span>
              
              <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
                {{item.text.split('|')[0]}}
              </label>
            <!-- </div> -->
              <label class="form-check-label">
                <i class="fa fa-trash" aria-hidden="true" title="Remove" (click)="removeItem(item)"></i>
              </label>         
            </div>
          </div>
        </ng-template>
      </div>
      <form style="padding:24px;" [formGroup]="restrictedSpaceForm" autocomplete="off" novalidate
        (ngSubmit)="addRestrictedSpace(restrictedSpaceForm.value)">

        <div>
    <div fxFlex="65" fxFlex.md="43" > 
      <mat-grid-list cols="4" rowHeight="65px">

              <mat-grid-tile>
                <label>Resident Name</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                <div style="width:100%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:90%;">
                    <input matInput placeholder="Resident Name" formControlName="residentname"
                      [(ngModel)]="addresident_name" (keyup)="handleInput($event)">
                  </mat-form-field>
                </div>
              </mat-grid-tile>

        <mat-grid-tile colspan="4">
                <div [matTooltip]="matTooltipWrong" matTooltipClass="tooltipStyle" #tooltip="matTooltip"
                  matTooltipPosition="left" (click)="tooltip.show()">
                  <mat-icon class="material-icons_color_gray">info icon</mat-icon>
                </div>
          <div style="margin:0 0 0.3em 1em;">
            <label (click)="showSelectedRoom(1)" style="cursor: pointer;">Wrong Room</label>  
            <mat-slide-toggle [checked]="checkWrongRoom" (change)="toggleWrongroom($event)" formControlName="wrongroom" style="padding-left:2.5em;"></mat-slide-toggle>
          </div>              
        </mat-grid-tile>   
        
        <mat-grid-tile colspan="4">
          <div style="margin-left:2.7em">                                  
              <label>Curfew Settings</label>                                                    
          </div>    
          <div style="margin-left: auto;margin-right: 0em;" [matTooltip]="matTooltipWrongRoomExpireTime" matTooltipClass="tooltip" #wrongRoomtooltip="matTooltip"
                matTooltipPosition="above" (click)="wrongRoomtooltip.show()">                                  
              <mat-icon class="material-icons_color_gray">info icon</mat-icon>                                                                                                    
          </div>     
          <label>Expiration Time (Days, 0 = No Expiration)</label>                    
        </mat-grid-tile>  

        <mat-grid-tile colspan="4">
          <div style="margin-left:2.7em;width:160px;">                                  
            <mat-form-field appearance="outline" floatLabel="auto" style="width:67%;">              
                    <mat-select #selWRStart placeholder="Start" formControlName="wr_start"
                      [(ngModel)]="data_wrstartTime" [disabled]="!_roomenablehourmin">
                      <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value"
                        (click)="changeRoomStart(matOption)">
                        {{data.Time}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select Start Time</mat-error>
                  </mat-form-field>
                  <label style="margin-left:1.5em">to</label>
                </div>

          <div style="display: flex;">                  
            <mat-form-field appearance="outline" floatLabel="auto" style="width:22%;">              
                    <mat-select #selWREnd placeholder="End" formControlName="wr_end" [(ngModel)]="data_wrendTime"
                      [disabled]="!_roomenablehourmin">
                      <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value"
                        (click)="changeRoomEnd(matOption)">
                        {{data.Time}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select End Time</mat-error>
                  </mat-form-field>

                  <mat-checkbox class="clslivelocation clsenable" id="chkresidentday"
                    (change)="toggleresdayinfoVisibility($event)" [disabled]="!_roomenableroomallday"
                    [checked]="checkresroominfo" style="font-size: 14px;margin-left: 1em;margin-top: 1em;"></mat-checkbox>
                  <span style="margin:1em 0.5em 0px 0.5em;" class="Hide-Personal-Info">All Day</span>
              <mat-form-field appearance="outline" floatLabel="auto" style="width:15%;margin-left: 2em;">
                <input matInput id="txtaddwrExpireTime" [disabled]="!_roomenableroomallday" (keyup)="handleInput($event)">                  
              </mat-form-field>
            </div>                           
          
        </mat-grid-tile> 
        
        <mat-grid-tile colspan="4">
                <div [matTooltip]="matTooltipAllEgress" matTooltipClass="tooltipStyle" #egresstooltip="matTooltip"
                  matTooltipPosition="left" (click)="egresstooltip.show()">
                  <mat-icon class="material-icons_color_gray">info icon</mat-icon>
                </div>
          <div style="margin:0 0 0.3em 1em;">
            <label (click)="showSelectedRoom(2)" style="cursor: pointer;">All Egress</label>  
            <mat-slide-toggle [checked]="checkAllEgress" (change)="toggleAllEgress($event)" formControlName="allegress" style="padding-left:2.5em;"></mat-slide-toggle>
          </div>              
        </mat-grid-tile>   
        
        <mat-grid-tile colspan="4">
          <div style="margin-left:2.7em">                                  
              <label>Curfew Settings</label>                                                    
          </div>    
          <div style="margin-left: auto;margin-right: 0em;" [matTooltip]="matTooltipAllEgressExpireTime" matTooltipClass="tooltip" #allEgresstooltip="matTooltip"
                matTooltipPosition="above" (click)="allEgresstooltip.show()">                                  
              <mat-icon class="material-icons_color_gray">info icon</mat-icon>                                                                                                    
          </div>     
          <label>Expiration Time (Days, 0 = No Expiration)</label>                    
        </mat-grid-tile>  
        
        <mat-grid-tile colspan="4">
          <div style="margin-left:2.7em;width:160px;">                                  
            <mat-form-field appearance="outline" floatLabel="auto" style="width:67%;">              
                    <mat-select #selAEStart placeholder="Start" formControlName="ae_start"
                      [(ngModel)]="data_aestartTime" [disabled]="!_egressenablehourmin">
                      <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value"
                        (click)="changeEgressStart(matOption)">
                        {{data.Time}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select Start Time</mat-error>
                  </mat-form-field>
                  <label style="margin-left:1.5em">to</label>
                </div>

                <div>
            <mat-form-field appearance="outline" floatLabel="auto" style="width:29%;">              
                    <mat-select #selAEEnd placeholder="End" formControlName="ae_end" [(ngModel)]="data_aeendTime"
                      [disabled]="!_egressenablehourmin">
                      <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value"
                        (click)="changeEgressEnd(matOption)">
                        {{data.Time}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select End Time</mat-error>
                  </mat-form-field>

                  <mat-checkbox class="clslivelocation clsenable" id="chkresidentallday"
                    (change)="toggleresalldayinfoVisibility($event)" [disabled]="!_roomenableEgressallday"
                    [checked]="checkresallinfo" style="font-size: 14px;margin-left: 1em;margin-top: 1em;"></mat-checkbox>
              <span style="margin:1em 0.5em 0px 0.5em;" class="Hide-Personal-Info">All Day</span>
              
              <mat-form-field appearance="outline" floatLabel="auto" style="width:19%;margin-left: 2em;">
                <input matInput id="txtaddaeExpireTime" [disabled]="!_roomenableEgressallday" (keyup)="handleInput($event)">                  
              </mat-form-field>
          </div>                       
        </mat-grid-tile> 


            </mat-grid-list>

          </div>
    <div fxLayout="row" fxLayoutAlign="space-around center" style="margin-top:10%">
      <div class="loadspinner" *ngIf="editresloading">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
      </div>
    </div>
    
    
    <div fxFlex="35" fxFlex.md="55" style="margin-left: 1em;" [ngStyle]="{'display': restrictionsHeader == '' ? 'none' : 'block'}">         
      <div style="margin-bottom: 0.5em;">{{restrictionsHeader}}</div>
      <mat-grid-list cols="4">
      <mat-grid-tile colspan="2" rowspan="6" style="border: 1px solid lightgray; min-width:calc(((43.3333% - 0.666667px) * 2) + -2px); max-width:calc(((43.3333% - 0.666667px) * 2) + -2px);">                               
        <figure class="mat-figure" style="align-items: baseline; margin-top: 5px; margin-left: 5px;height: 500px;">              
        <div style="width:100%;">
          <!-- <span class="wanderdot" style="background-color:#6f6c6c"></span><span class="clsSpanText">Resident Room</span>
          <span class="wanderdot" style="background-color:#f7d613"></span><span class="clsSpanText">Egress</span><br>
          <span class="wanderdot" style="background-color:#ff0000"></span><span class="clsSpanText">Resident Off Limits</span>
          <span class="wanderdot" style="background-color:#f77521"></span><span class="clsSpanText">Other</span> -->
          <ngx-treeview [items]="items" [config]="config" [itemTemplate]="itemTemplate" (selectedChange)="onSelectedChange($event)">
          </ngx-treeview>        
        </div>
        </figure>   
                  
      </mat-grid-tile>          
      </mat-grid-list>
    </div>
                    
    </div>                   

        <mat-dialog-actions class="clsbuttoncontrol">
          <button type="button" class="clsbtncancelbutton" mat-raised-button
            (click)="onCloseCancel_ResSpace()">Cancel</button>
          <button class="clsbtnsavebutton" mat-raised-button >Save</button>&nbsp;
        </mat-dialog-actions>

      </form>

    </div>
    <!-------------------------Restricted End----------------------------------->

  </div>

</perfect-scrollbar>