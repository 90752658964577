import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumFileNamelength,
  enumEnableDisable,
} from "../../../../../app-settings";
import {
  EditCampusForm,
  TechnologyForCreationResponse,
  deleteCampusById,
} from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
const URL = appsettings.URLinfo + "uploadMapImage";
import { HostListener } from "@angular/core";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
  AuthFailedMessage,
} from "../../../../../app-general";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../../../../../api.service";
import { takeUntil } from "rxjs/operators";
import { ConfirmMessageComponent } from "../../../../../confirm-message/confirm-message.component";
import { AlertSharedService } from "../../../../../_services/index";
import { backupPhoto } from "../../../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-campus",
  templateUrl: "./edit-campus.component.html",
  providers: [TechnologyoverlayService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-campus.component.scss"],
})
export class EditCampusComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 4;
  firstcolumns = 2;
  public campuseditForm: UntypedFormGroup;
  locationName;
  campusimagename;
  campusaddImgUrl;
  campusaddsvgUrl;
  campimagename: any;
  oldimagename;
  campusImgUrl: string = appsettings.CAMPUS_IMAGE_FOLDER;
  deletecampusid;
  zoomval = 0.5;
  imgWidth = 190;
  height = 190;
  top = 0;
  left = 0;
  rotationAmount = 0;
  E_RotateLevel = 0;
  E_ZoomLevel = 0;
  E_svgZoomLevel;
  E_svgRotateLevel; 
  E_pngZoomLevel;
  E_pngRotateLevel;
  update_zoomlevel;
  update_rotatelevel;
  CampusSVGimage;
  downIcon = 0;

  old_campusaddImgUrl = "assets/images/defaultimg.png";
  old_E_pngZoomLevel = 1;
  old_E_pngRotateLevel = 0;
  old_left_rightval = 0;
  old_top_bottomval = 0;

  flag = 0;
  updownArrowIcon = "keyboard_arrow_down";
  minheight = "230px";
  resetcampusname: string;
  resetdescription: string;
  BuildingDetails = [];
  bildingdetailsResponse = [];
  Facilityname;
  validcampusimgurl = true;
  private _onDestroy: Subject<void> = new Subject<void>();
  rowHeight = "79px";
  screenWidth;
  isundo = 0;
  zoominvalue = 0.5;
  bAddEdit = false;
  bDelete = false;
  left_rightval = 0;
  top_bottomval = 0;
  undooperation = true;
  undopane = 0;
  uploadcampusimage;

  error: string;
  dragAreaClass: string;
  clscolspan = 3;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditCampusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.Facilityname = data.Facilityname;
      this.onResize();
      if (data.CampusPlanImage != "") this.campimagename = data.CampusPlanImage;
      else this.campimagename = data.CampusPlansvgImage;

      this.deletecampusid = data.CampusId;
      this.CampusSVGimage = data.CampusPlansvgImage;
      if (data.CampusPlanImage != "") {
        this.campusaddImgUrl = this.campusImgUrl + data.CampusPlanImage;
        this.old_campusaddImgUrl = this.campusImgUrl + data.CampusPlanImage;
      } else this.campusaddImgUrl = "assets/images/defaultimg.png";

      if (data.CampusPlansvgImage != "")
        this.campusaddsvgUrl = this.campusImgUrl + data.CampusPlansvgImage;
      else this.campusaddsvgUrl = "assets/images/defaultsvg.png";

      this.resetcampusname = data.CampusName;
      this.resetdescription = data.Description;

      if (data.CampusPlanImage != "") {
        if (data.RotateLevel != null) {
          this.E_pngRotateLevel = data.RotateLevel;
          this.update_rotatelevel = data.RotateLevel;
          this.rotationAmount = data.RotateLevel;
          this.E_RotateLevel = data.RotateLevel;
          this.old_E_pngRotateLevel = data.RotateLevel;
        } else {
          this.E_pngRotateLevel = 0;
          this.update_rotatelevel = 0;
          this.rotationAmount = 0;
          this.E_RotateLevel = 0;
        }

        if (data.ZoomLevel != null) {
          this.E_pngZoomLevel = data.ZoomLevel;
          this.update_zoomlevel = data.ZoomLevel;
          this.zoomval = data.ZoomLevel;
          this.E_ZoomLevel = data.ZoomLevel;
          this.old_E_pngZoomLevel = data.ZoomLevel;
        } else {
          this.E_pngZoomLevel = 1;
          this.update_zoomlevel = 1;
          this.zoomval = 1;
          this.E_ZoomLevel = 1;
        }

        if (data.TransformX != null) {
          this.left_rightval = data.TransformX;
          this.old_left_rightval = data.TransformX;
        }
        if (data.TransformY != null) {
          this.top_bottomval = data.TransformY;
          this.old_top_bottomval = data.TransformY;
        }
      }
      if (data.CampusPlansvgImage != "") {
        if (data.RotateLevel != null) {
          this.E_svgRotateLevel = data.RotateLevel;
          this.update_rotatelevel = data.RotateLevel;
          this.rotationAmount = data.RotateLevel;
          this.E_RotateLevel = data.RotateLevel;
        } else {
          this.E_svgRotateLevel = 0;
          this.update_rotatelevel = 0;
          this.rotationAmount = 0;
          this.E_RotateLevel = 0;
        }

        if (data.ZoomLevel != null) {
          this.E_svgZoomLevel = data.ZoomLevel;
          this.update_zoomlevel = data.ZoomLevel;
          this.zoomval = data.ZoomLevel;
          this.E_ZoomLevel = data.ZoomLevel;
        } else {
          this.E_svgZoomLevel = 1;
          this.update_zoomlevel = 1;
          this.zoomval = 1;
          this.E_ZoomLevel = 1;
        }
      }
      this.breakpoint$.subscribe(() => this.breakpointChanged());           
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - constructor() - " + err.message
      );
    }
  }
  
  breakpointChanged() {    
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Device_Map_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
      this.clscolspan = 3;
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
      this.clscolspan = 2;
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
      this.clscolspan = 2;
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
      this.clscolspan = 2;
    }     
  }
  
  @ViewChild("imageInput") imageInput: any;
  public onChangecampus_Edit(fileList: FileList) {
    this.saveFiles(fileList);
  }

  saveFiles(fileList: FileList) {
    if (fileList.length > 1) {
      generateAlertMessage(this.dialog, "Only one file at time allowed");
      this.imageInput.nativeElement.value = "";
      return;
    }
    const file = fileList[0];    
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(removedSpaces_filename);
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      this.imageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      this.imageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Campus Photo as image file."
      );
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 1);
      this.imageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 2) {
              // 1024 is 1MB
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 2 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Campus Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  @ViewChild("imagesvgInput") imagesvgInput: any;
  public onChangecampussvg_Edit(fileList: FileList) {
    const file = fileList[0];
    const itemType =
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
      file.name;
    const fileTypesAllowed = ["svg"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(this.dialog, "Please upload .svg file type");
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 2);
      this.imagesvgInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (self[0].includes("xml")) {
            checkvalidIMG = 1;
            resolve(checkvalidIMG);
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(_dialog, "Please upload valid file");
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  uploadfiletoPath(data, file, type) {
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const File_Name = this.deletecampusid + "+" + _filename;
    formData.append("file", file, File_Name);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        this.campusimagename = res.filename;
        this.validcampusimgurl = true;
        if (type == 1) {
          this.flag = 1;
          this.campusaddImgUrl = this.campusImgUrl + res.filename;
        } else if (type == 2) {
          this.flag = 1;
          this.campusaddsvgUrl = this.campusImgUrl + res.filename;
        }
      });
  }

  backupPhoto(filename) {
    try {
      const fileitem: backupPhoto = {
        authKey: this.authkey,
        fileName: filename.toString(),
        isPath: "Campus",
      };
      this.apiService
        .backupPhoto(fileitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe();
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - backupPhoto() - " + err.message
      );
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }
      this.locationName = this.Facilityname;
      this.oldimagename = this.campimagename;
      this.campuseditForm = new UntypedFormGroup({
        location: new UntypedFormControl(""),
        campusId: new UntypedFormControl(""),
        campusname: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(32),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        campusimage: new UntypedFormControl(""),
        description: new UntypedFormControl("", [
          Validators.pattern(appsettings.Name_Pattern),
        ]),
      });

      const campusEditLocation = this.campuseditForm.get("location");
      if(campusEditLocation != null)
        campusEditLocation.disable();

      this.dragAreaClass = "dragarea";
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.campuseditForm.controls).forEach((field) => {
        // {1}
        const control = this.campuseditForm.get(field); // {2}
        const getFromTargetId = document.getElementById(event.target.id);
        if(getFromTargetId != null)
        {
          if (field == getFromTargetId.getAttribute("formControlName")) 
          {
            if(control != null)
            {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1280) {
      this.updownArrowIcon = "keyboard_arrow_down";
      this.downIcon = 0;
      this.thisDialogRef.updateSize("75em", "400px");
      this.minheight = "230px";
      this.columns = 4;
      this.firstcolumns = 2;
    }
    if (this.screenWidth <= 768) {
      this.rowHeight = "80px";
    } else if (this.screenWidth == 1097) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
      this.rowHeight = "79px";
    } else if (this.screenWidth == 1536) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    }
  }
  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    this.campuseditForm.controls[controlName].hasError(errorName);
  };

  public editCampusdetails = (campusFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (this.campuseditForm.valid) {
        this.updateCampus(campusFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - editCampusdetails() - " + err.message
      );
    }
  };

  private updateCampus = (campusFormValue) => {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();
      let _Description = "";
      if (campusFormValue.description != "")
        _Description = campusFormValue.description.replace(/\s+/g, " ").trim();
      else _Description = "";

      if (this.campuseditForm.valid) {        
        this.campusEditEnableDisable(enumEnableDisable.Disable);
        if (this.campusimagename == "" || this.campusimagename == undefined)
          this.campusimagename = this.oldimagename;
        const updatecampus: EditCampusForm = {
          authKey: this.authkey,
          location: this.locationName.replace(/\s+/g, " ").trim(),
          campusName: campusFormValue.campusname.replace(/\s+/g, " ").trim(),
          description: _Description, //campusFormValue.description.replace(/\s+/g, ' ').trim(),
          campusImage: this.campusimagename.toString(),
          campusId: parseInt(campusFormValue.campusId),
          zoomlevel: this.E_ZoomLevel,
          rotatelevel: this.E_RotateLevel,
          transformX: this.left_rightval,
          transformY: this.top_bottomval,
        };
        this.technologyoverlayService
          .updateCampus(updatecampus)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse["apiReturn"] == "Success") {
              if (this.campusimagename != this.campimagename)
                this.backupPhoto(this.campimagename);

              generateAlertMessage(this.dialog, "Campus edited successfully.");
              this.thisDialogRef.close();
            } else if (apiresponse["apiReturn"] == "Fail") {
              generateAlertMessage(this.dialog, "Campus edited failed.");
              this.thisDialogRef.close();
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            } else if (apiresponse["apiReturn"] == "Campus Image not exists") {
              generateAlertMessage(this.dialog, "Please upload campus image.");              
              this.campusEditEnableDisable(enumEnableDisable.Enable);
              return;
            } else if (apiresponse["apiReturn"] == "campusName") {
              generateAlertMessage(this.dialog, "Please enter Campus ");              
              this.campusEditEnableDisable(enumEnableDisable.Enable);
              return;
            } else {
              generateAlertMessage(
                this.dialog,
                "Campus '" + apiresponse["CampuseditList"][0].CampusName + "' already exists."
              );              
              this.campusEditEnableDisable(enumEnableDisable.Enable);
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - updateCampus() - " + err.message
      );
    }
  };
  opendeletedialog() {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to delete Campus?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          this.deleteCampus(this.deletecampusid);
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - opendeletedialog() - " + err.message
      );
    }
  }

  //Delete campus is used to delete the selected campus for the site.
  deleteCampus(campusid) {
    try {
      const deletecampusbyid: deleteCampusById = {
        authKey: this.authkey,
        campusId: parseInt(campusid),
      };
      this.technologyoverlayService
        .deleteCampus(deletecampusbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            let CheckAlertData = {
              IsAlertChanged: 0,
              IsAlertClicked: 0,
            };
            this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
            CheckAlertData = {
              IsAlertChanged: 1,
              IsAlertClicked: 0,
            };
            this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
            this.apiService.reloadmapconfig();
            generateAlertMessage(this.dialog, "Campus deleted successfully.");
            this.apiService.reloadlive();
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Campus deleted failed.");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
          }
          this.thisDialogRef.close("delete");
        });
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - deleteCampus() - " + err.message
      );
    }
  }

  //Rotate option is used to rotate the map image in clockwise direction.
  rotateImage(direction: string) {
    try {
      this.undooperation = true;
      this.isundo = 1;
      if (direction === "left") {
        const flag = this.imgWidth;
        this.imgWidth = this.height;
        this.height = flag;
        this.rotationAmount = this.rotationAmount + -90;
        if (this.rotationAmount == -360) {
          this.rotationAmount = 0;
        }

        if (this.rotationAmount === -180 || this.rotationAmount === 0) {
          this.top = 0;
          this.left = 0;
        } else {
          this.top = ((this.imgWidth - this.height) / 2) * -1;
          this.left = (this.imgWidth - this.height) / 2;
        }
      } else if (direction === "right") {
        this.rotationAmount = this.rotationAmount + 90;
        if (this.rotationAmount == 360) {
          this.rotationAmount = 0;
        }
      }

      if (this.downIcon == 1) this.E_svgRotateLevel = this.rotationAmount;
      else this.E_pngRotateLevel = this.rotationAmount;

      this.E_RotateLevel = this.rotationAmount;
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - rotateImage() - " + err.message
      );
    }
  }
  //Zoom option is used to zoom out the uploaded campus image.
  zoomoutImage() {
    try {
      this.undooperation = true;
      this.isundo = 2;
      if (this.zoomval > 1) this.zoomval = this.zoomval - 0.5;
      if (this.downIcon == 1) {
        this.E_svgZoomLevel = this.zoomval;
      } else this.E_pngZoomLevel = this.zoomval;

      this.E_ZoomLevel = this.zoomval;
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - zoomoutImage() - " + err.message
      );
    }
  }

  //Zoom option is used to zoom in the uploaded campus image.
  zoomInImage() {
    try {
      this.undooperation = true;
      this.isundo = 3;
      if (this.zoomval < 5) this.zoomval = this.zoomval + 0.5;

      if (this.downIcon == 1) {
        this.E_svgZoomLevel = this.zoomval;
      } else this.E_pngZoomLevel = this.zoomval;

      this.zoominvalue = this.zoomval;
      this.E_ZoomLevel = this.zoomval;
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - zoomInImage() - " + err.message
      );
    }
  }

  //Move option is used to move the map mage to left, right, top and bottom directions.
  leftImage() {
    if (this.E_pngRotateLevel == "0") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "180") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.E_pngRotateLevel == "90") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "270") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.isundo = 4;
    this.undopane = 1;
  }
  rightImage() {
    if (this.E_pngRotateLevel == "0") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.E_pngRotateLevel == "90") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "180") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "270") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.isundo = 5;
    this.undopane = 2;
  }
  bottomImage() {
    if (this.E_pngRotateLevel == "0") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "90") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "180") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "270") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.isundo = 6;
    this.undopane = 3;
  }
  topImage() {
    if (this.E_pngRotateLevel == "0") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "90") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "180") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngRotateLevel == "270") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.isundo = 7;
    this.undopane = 4;
  }

  panAlign(IsAdd, IsTopBottom) {
    let IsChk = false;
    if (IsAdd == panIsAdd.Add && IsTopBottom == panIsTopBottom.TopBottom) {
      if (this.top_bottomval < 70) {
        this.top_bottomval = this.top_bottomval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.TopBottom
    ) {
      if (this.top_bottomval > -70) {
        this.top_bottomval = this.top_bottomval - 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Add &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval < 70) {
        this.left_rightval = this.left_rightval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval > -70) {
        this.left_rightval = this.left_rightval - 10;
        IsChk = true;
      }
    }
    return IsChk;
  }

  //Remove option is used to delete the uploaded campus image.
  removeImage() {
    try {
      this.isundo = 8;
      this.undooperation = true;
      this.uploadcampusimage = this.campusaddImgUrl;
      this.campusaddImgUrl = "assets/images/defaultimg.png";
      this.campusaddsvgUrl = "assets/images/defaultsvg.png";
      this.validcampusimgurl = false;
      this.rotationAmount = 0;
      this.left_rightval = 0;
      this.top_bottomval = 0;
      this.E_pngZoomLevel = 1;
      this.zoomval = 1;
      if (this.downIcon == 1) this.E_svgRotateLevel = this.rotationAmount;
      else this.E_pngRotateLevel = this.rotationAmount;
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - removeImage() - " + err.message
      );
    }
  }

  //Reset option reset back image to original state.
  resetformtext() {
    try {
      this.undooperation = false;
      if (this.downIcon == 1) this.E_svgRotateLevel = 0;
      else this.E_pngRotateLevel = 0;

      this.rotationAmount = 0;
      this.E_pngZoomLevel = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
      this.E_pngRotateLevel = 0;
      this.E_RotateLevel = 0;
      this.E_ZoomLevel = 1;
      this.zoomval = 1;
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - resetformtext() - " + err.message
      );
    }
  }

  //Undo will reset the action to one step backward.
  undoimage() {
    try {
      if (this.undooperation == true) {
        if (this.isundo == 1) {
          if (this.E_pngRotateLevel != 0)
            this.E_pngRotateLevel = this.E_pngRotateLevel - 90;
          else this.E_pngRotateLevel = 270;

          this.rotationAmount = this.E_pngRotateLevel;
          this.E_RotateLevel = this.rotationAmount;
        } else if (this.isundo == 2) {
          if (this.E_pngZoomLevel < this.zoominvalue)
            this.E_pngZoomLevel = this.E_pngZoomLevel + 0.5;
        } else if (this.isundo == 3) {
          if (this.E_pngZoomLevel > 1)
            this.E_pngZoomLevel = this.E_pngZoomLevel - 0.5;
        } else if (this.isundo == 4) {
          if (this.undopane == 0) {
            if (this.left_rightval != 0)
              this.left_rightval = this.left_rightval - 10;
          } else {
            if (this.E_pngRotateLevel == "90")
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.E_pngRotateLevel == "180")
              this.left_rightval = this.left_rightval + 10;
            else if (this.E_pngRotateLevel == "270")
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.E_pngRotateLevel == "0")
              this.left_rightval = this.left_rightval - 10;
          }
        } else if (this.isundo == 5) {
          if (this.undopane == 0) {
            if (this.left_rightval != 0)
              this.left_rightval = this.left_rightval + 10;
          } else {
            if (this.E_pngRotateLevel == "90")
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.E_pngRotateLevel == "180")
              this.left_rightval = this.left_rightval - 10;
            else if (this.E_pngRotateLevel == "270")
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.E_pngRotateLevel == "0")
              this.left_rightval = this.left_rightval + 10;
          }
        } else if (this.isundo == 6) {
          if (this.undopane == 0) {
            if (this.top_bottomval != 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else {
            if (this.E_pngRotateLevel == "90")
              this.left_rightval = this.left_rightval + 10;
            else if (this.E_pngRotateLevel == "180")
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.E_pngRotateLevel == "270")
              this.left_rightval = this.left_rightval - 10;
            else if (this.E_pngRotateLevel == "0")
              this.top_bottomval = this.top_bottomval + 10;
          }
        } else if (this.isundo == 7) {
          if (this.undopane == 0) {
            if (this.top_bottomval != 0)
              this.top_bottomval = this.top_bottomval - 10;
          } else {
            if (this.E_pngRotateLevel == "90")
              this.left_rightval = this.left_rightval - 10;
            else if (this.E_pngRotateLevel == "180")
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.E_pngRotateLevel == "270")
              this.left_rightval = this.left_rightval + 10;
            else if (this.E_pngRotateLevel == "0")
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 8) {
          if (this.campusimagename == "" || this.campusimagename == undefined)
            this.campusimagename = this.oldimagename;
          this.campusaddImgUrl = this.campusImgUrl + this.campusimagename;
          this.E_pngRotateLevel = this.E_RotateLevel;
          this.validcampusimgurl = true;
        }
      }
      this.undooperation = false;
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - undoimage() - " + err.message
      );
    }
  }

  campusEditEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsavecampuseditclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clscampuseditbutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clscampuseditbutton mat-raised-button disablebtn";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

enum panIsAdd {
  Add = 0,
  Sub = 1,
}
enum panIsTopBottom {
  TopBottom = 0,
  LeftRignt = 1,
}
