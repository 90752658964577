<mat-toolbar color="primary" class="mobilebottom-toolbar" (click)="homeToggle($event)">
    <mat-grid-list cols="3" rowHeight="2:1">
        <mat-grid-tile style="margin-left:3em;">
            <button mat-icon-button>
                <mat-icon aria-hidden="true" id="btnAlertIcon">notifications</mat-icon>
            </button>
        </mat-grid-tile>
        <mat-grid-tile style="margin-left:3em;">
            <button mat-icon-button>
                <mat-icon aria-hidden="true"></mat-icon>
            </button>
        </mat-grid-tile>      
        <mat-grid-tile style="margin-left:3em;">
            <button mat-icon-button>
                <mat-icon aria-hidden="true" id="btnHomeIcon">home</mat-icon>
            </button>
        </mat-grid-tile>         
  </mat-grid-list>
</mat-toolbar>
