<app-report-header  [ReportType]="ReportType"></app-report-header>
<mat-card class="report-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-report-leftbar ></app-report-leftbar>
    </div>

    <div fxLayout="column" style="width:100%;">

      <div class="table-container">
        <div>
          <div class="landing-title-bar"> 
            <div fxLayout="row" fxLayoutAlign="space-around left" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayoutGap="5px" fxLayoutWrap>          
              <div fxFlex="70" fxFlex.md="40" fxFlex.sm="30" fxFlex.xs="20"> 
              <mat-card-header>
                <mat-card-title style="font-size: 20px;margin-top:15px;margin-left:1em;" fxFlex.md="100" fxFlex.sm="50" fxFlex.xs="20">                
                  <span class="clsTitle">{{Todaydate}}</span>
                  <div style="font-size:15px;margin-top:5px;">
                    <span class="clsTitle">Total Number of Inventory: {{TotalNumofLiveView}}</span>
                  </div>                
                </mat-card-title>
              </mat-card-header>  
            </div>
  
            <div fxFlex="35" fxFlex.md="40" fxFlex.xs="20">
              <mat-card-actions fxFlex="35" fxFlex.md="40" fxFlex.xs="20">                
                <button color="primary" mat-raised-button class="clsLiveExportbutton" (click)="exportLiveViewReportdata()" style="background-color:#ffa500;">Export</button>                
              </mat-card-actions>
            </div>
          </div>

          </div>

          <perfect-scrollbar class="chartscrollbarLiveview">
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutWrap fxLayoutGap="15%" fxLayoutAlign="flex start">
             
                <div fxFlex="40%">
                    <div >
                      <div id="chart-container" class="liveviewresizedoughnutchart" style="display: block;">
                      <canvas baseChart [data]="LiveViewdatasets" [labels]="LiveViewdoughnutChartLabels1" [chartType]="LiveViewdoughnutChartType1"
                          [options]="LiveViewoptions1" [colors]="LiveViewdonutColors1">
                      </canvas>
                      </div>
                      <span class="ChartLabel2">Current on-hand Inventory & State</span>
                      </div>
                  </div>  
              
              <div fxFlex="40%">
                <div>
                  <div id="chart-container" class="clsliveviewdoughnutchart" style="display: block;margin-left:2em;">
                      <perfect-scrollbar style="max-height: 320px;min-width: 300px;" >
                          <div>
                            <div class="Poptablechart">
                              <span style="vertical-align: middle;">Popular Location<br /><label class="clspoplocation">{{LiveViewReportCount}}</label></span>
                            </div>
                          <div class="Poptablecontent" *ngFor="let el of Pop_LocationData; let index=index; let odd=odd; let even=even;" [ngClass]="{ Popodd: odd, Popeven: even }">

                              <div class="PopCellright">
                                <span *ngIf="el.Type.length < 12">{{el.Type}}</span>
                                <span *ngIf="el.Type.length >= 12" matTooltipClass="liveviewtooltip" matTooltip={{el.FullType}} #tooltip="matTooltip" matTooltipPosition="above">{{el.Type}}</span>
                              </div>
                              <div class="PopCellcenter">
                                <span>{{el.Value1}}</span>
                              </div>              
                              <div class="PopCellleft">
                                <span>{{el.Value2}}</span>
                              </div>
                                      
                          </div>
                        </div>
                        </perfect-scrollbar>
                  </div>                  
                  </div>                    
              </div> 

          </div>

          <div class="liveviewsearch" fxLayout.md="column" fxLayout.sm="column">
            <mat-form-field appearance="outline" floatLabel="left" class="clslivematformfield" style="margin-bottom:0.3em;">
              <input matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchLiveViewReport($event.target.value)"
                autocomplete="off" placeholder="Finder" #searchLiveViewInput>
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>          
            
            <div class="clsdivlivematformfield" fxLayout.md="column" fxLayout.sm="column">   
          
                <mat-form-field appearance="outline" floatLabel="auto" class="clslivematformfield">
                    <mat-select placeholder="Color Config" [(ngModel)]="selectedLocationType" (selectionChange)="cusloc_selectLocationType($event.value)">
                      <mat-option value="All">All</mat-option>
                      <mat-option *ngFor="let location of LocationType" [value]="location.LocationTypeId" title="{{ location.TypeName.length >= 15 ? location.TypeName : ''}}">{{location.TypeName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
      
                  <mat-form-field appearance="outline" floatLabel="auto" class="clslivematformfield">
                    <mat-select placeholder="State Changed By" [(ngModel)]="cusloc_selectStateBy" (selectionChange)="CusLoc_selectStateby($event.value)">
                      <mat-option value="All">All</mat-option>                                                             
                      <mat-option value="LF">{{EnumNewDeviceType.LF}}</mat-option>
                      <mat-option value="Monitor">{{EnumNewDeviceType.Monitor}}</mat-option>                     
                      <mat-option value="Security Controller">{{EnumNewDeviceType.DC}}</mat-option>  
                      <mat-option value="TruLocator">{{EnumNewDeviceType.Emitter}}</mat-option>                                        
                      <mat-option value="Universal Monitor">{{EnumNewDeviceType.UM}}</mat-option>
                      <mat-option value="VW">{{EnumNewDeviceType.VW}}</mat-option>
                      <mat-option *ngFor="let Staff of cusloc_selstatechange" [value]="Staff.Name" title="{{ Staff.Name.length >= 40 ? Staff.Name : ''}}">{{Staff.Name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                              
            </div>
            
      </div>

          <perfect-scrollbar style="max-height: 350px;"> 
            <div *ngIf="LiveViewSource else noLiveviewRecord">
              <table mat-table [dataSource]="LiveViewSource" matSort (matSortChange)="sortData($event)" #LiveViewsort="matSort" matSortDisableClear=true>                 
    
                <!-- GeoName Column -->
                <ng-container matColumnDef="GeoName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Asset Name
                    <mat-icon style="color:#717070" class="liveviewmaticon" matTooltip="Friendly name of the device">error_outline</mat-icon>              
                  </th>                  
                  <td mat-cell *matCellDef="let element">                   
                      <span *ngIf="element.GeoName.length < 12" >{{element.GeoName}}</span>
                      <span *ngIf="element.GeoName.length >= 12" matTooltipClass="liveviewtooltip" matTooltip={{element.FullGeoName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.GeoName}}</span>                    
                  </td>
                </ng-container>
    
                <!-- TagId Column -->
                <ng-container matColumnDef="R_TagId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Tag Id
                    <mat-icon style="color:#717070" class="liveviewmaticon" matTooltip="The Serial ID of the CenTrak device">error_outline</mat-icon></th>
                  <td mat-cell *matCellDef="let element"> {{element.R_TagId}} </td>
                </ng-container>
    
                <!-- TruLocationName Column -->
                <ng-container matColumnDef="TruLocation">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Location Name
                    <mat-icon style="color:#717070" class="liveviewmaticon" matTooltip="Last seen Location">error_outline</mat-icon></th>
                  <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="element.TruLocation.length < 12" >{{element.TruLocation}}</span>
                    <span *ngIf="element.TruLocation.length >= 12" matTooltipClass="liveviewtooltip" matTooltip={{element.FullTruLocation}} #tooltip="matTooltip" matTooltipPosition="above">{{element.TruLocation}}</span>
                  </td>
                </ng-container>
    
                <!-- LastTimeStamp Column -->
                <ng-container matColumnDef="Updatedon">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Last Time Stamp 
                    <mat-icon style="color:#717070" class="liveviewmaticon" matTooltip="Shows last Location Change time, or State Change">error_outline</mat-icon></th>
                  <td mat-cell *matCellDef="let element"> {{element.Updatedon}} </td>
                </ng-container>                     
    
                <!-- CurrentState Column -->
                <ng-container matColumnDef="TypeName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell" style="width:200px;">Current State
                    <mat-icon style="color:#717070;" class="liveviewmaticon" matTooltip="Live State of Green, Yellow, Red or Grey">error_outline</mat-icon></th>
                  <td mat-cell *matCellDef="let element">                             
                    <div *ngFor="let location of LocationType">
                    <div *ngIf="element.roomcolor == location.LocationTypeId" class="liveviewdotlist" [ngStyle]="{'background-color': location.TypeColor}">
                      <span class="clscolorspan" *ngIf="element.FullTypeName.length < 12" >{{element.TypeName}}</span>
                    <span class="clscolorspan" *ngIf="element.FullTypeName.length >= 12" matTooltipClass="liveviewtooltip" matTooltip={{element.FullTypeName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.TypeName}}</span></div>
                    </div>
                  </td>
                </ng-container> 
                
                <!-- StateChanged Column -->
                <ng-container matColumnDef="stateChangebyName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">State Changed By
                      <mat-icon style="color:#717070" class="liveviewmaticon" matTooltip="Shows which user changed the Current State, or which TruLocator changed the Current State">error_outline</mat-icon>
                  </th>
                  <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.stateChangebyName.length < 12" >{{element.stateChangebyName}}</span>
                      <span *ngIf="element.stateChangebyName.length >= 12" matTooltipClass="liveviewtooltip" matTooltip={{element.stateChangebyName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.stateChangebyName.slice(0,15) + ".."}}</span>
                  </td>
                </ng-container> 
    
                <!-- ChangeState Column -->           
                  <ng-container matColumnDef="roomcolor">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Change State
                        <mat-icon style="color:#717070" class="liveviewmaticon" matTooltip="Change Current State by Clicking Below">error_outline</mat-icon></th>
                  <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.roomcolor == EnumReportState.Clean">
                    <div class="dotlist" style="background-color:#F7FE2E;" [id]="element.AssetId+'_'+EnumReportState.InUse" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.InUse)"></div>               
                    <div class="dotlist" style="background-color:#797777;" [id]="element.AssetId+'_'+EnumReportState.Unknown" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.Unknown)"></div>
                    </div>
                    <div *ngIf="element.roomcolor == EnumReportState.InUse">
                        <div class="dotlist" style="background-color:#FF0000;" [id]="element.AssetId+'_'+EnumReportState.Dirty" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.Dirty)"></div>
                        <div class="dotlist" style="background-color:#797777;" [id]="element.AssetId+'_'+EnumReportState.Unknown" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.Unknown)"></div>
                    </div>
                    <div *ngIf="element.roomcolor == EnumReportState.Dirty">
                        <div class="dotlist" style="background-color:#3ADF00;" [id]="element.AssetId+'_'+EnumReportState.Clean" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.Clean)"></div>
                        <div class="dotlist" style="background-color:#797777;" [id]="element.AssetId+'_'+EnumReportState.Unknown" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.Unknown)"></div>
                    </div>
                    <div *ngIf="element.roomcolor == EnumReportState.Unknown">
                      <div class="dotlist" style="background-color:#3ADF00;" [id]="element.AssetId+'_'+EnumReportState.Clean" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.Clean)"></div>
                      <div class="dotlist" style="background-color:#F7FE2E;" [id]="element.AssetId+'_'+EnumReportState.InUse" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.InUse)"></div>
                      <div class="dotlist" style="background-color:#FF0000;" [id]="element.AssetId+'_'+EnumReportState.Dirty" (click)="getliveviewdivclickelement(element.AssetId,element.RoomId,EnumReportState.Dirty)"></div>
                  </div>
                  </td>
                </ng-container>
                            
                <tr mat-header-row *matHeaderRowDef="displayedLiveViewColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns:displayedLiveViewColumns;"></tr>
              </table>

              <h3 *ngIf="LiveViewSource.data.length == 0 && !liveviewloading" style="text-align: center;margin-top:1em;"> No Record found </h3> 

            </div>
    
            <ng-template #noLiveviewRecord>
              <!-- <h3 *ngIf="!liveviewloading" style="text-align: center;margin-top:1em;"> No Record found </h3>-->
                <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%">       
                    <div class="loadspinner" *ngIf="liveviewloading">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>            
          </ng-template> 
    
          </perfect-scrollbar>  
          
          </perfect-scrollbar>

        </div>
      </div>        
    
    </div>   
  </div>
</mat-card>