<perfect-scrollbar style="max-width: 1200px; max-height: 920px;" >
  <div class="clseditFloor">
     
              <div class="editFloor-title">
                <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editFloorHeader" style="margin: 0 0 10px 11px;">Zone Information
                <span><img class="clseditfloorcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
                <mat-divider ></mat-divider>
              </div>
        
              
              
              <form  style="padding:24px;"  [formGroup]="flooreditForm" autocomplete="off" novalidate (ngSubmit)="oneditFloordetails(flooreditForm.value)">     
          
                <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
                    
                  <div fxFlex="55" fxFlex.md="25"> 
                        
                    <mat-grid-list [cols]="columns" class="clsimagematgridlist_floor">
                      <div class="clseditfloorimagedivlist">
                        <img class="clseditfloorDragplanimg" type="file" formControlName="floorimage" [src]= "flooreditImgUrl" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" draggable="false" (click)='FloorMapView()'/>
                        <input type="file" class="clschoosebutton text-inside-grid" placeholder="Photo" formControlName="floorimage" id='imagefloorInput' onclick="this.value = null"  (change)="onChangefloor_Edit($event.target.files)" accept=".png, .jpg, .bmp"/>
                        <button type="button" class="clsfloordltbutton" style="width:6rem;" mat-raised-button (click)="CropMap()">Edit Map</button>
                        <button type="button" class="clsfloordltbutton" style="width:7rem;" mat-raised-button (click)="ReplaceMap()">Replace Map</button>
                      </div>                              
                    </mat-grid-list>
                                        
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="floorrowHeight">                             
                      <mat-grid-tile>
                          <label>Campus</label>
                      </mat-grid-tile>
                      <mat-grid-tile class="clsmatgridtxt">
                              <div class="text-inside" style="width:100%;">
                                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                                  <input matInput placeholder="Campus Name" formControlName="campusname" maxlength="32" [(ngModel)]="data.CampusName" (keyup)="handleInput($event)"/>                                      
                              </mat-form-field>
                            </div>                     
                      </mat-grid-tile>                                 
      
                      <mat-grid-tile>
                          <label>Structure </label>
                      </mat-grid-tile>
                      <mat-grid-tile class="clsmatgridtxt">
                              <div class="text-inside" style="width:100%;">
                                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                                 <input matInput placeholder="Building Name" formControlName="buildingname" maxlength="32" [(ngModel)]="data.BuildingName" (keyup)="handleInput($event)"/>                                 
                              </mat-form-field>
                            </div>
                      </mat-grid-tile>  
                      
                      <mat-grid-tile>
                        <label>Floor</label>
                    </mat-grid-tile>
                    <mat-grid-tile class="clsmatgridtxt">                      
                          <div class="text-inside" style="width:100%;" id="diveditFloorDropdown"> 
                            <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">                              
                              <mat-select formControlName="floorname" placeholder="Select Floor" [(ngModel)]="selectedFloorId" *ngIf="floorOriginalId > 0">    
                                <mat-option *ngFor="let Floor of Floordetails" [value]="Floor.FloorId" (click)="changeFloor_Building(Floor.FloorId);">
                                  {{Floor.FloorName}}
                                </mat-option>
                              </mat-select>
                              
                                <mat-select formControlName="floorname" placeholder="Select Floor" *ngIf="floorOriginalId == 0" disableOptionCentering> 
                                <mat-optgroup *ngFor="let group of floorGroups" [label]="group.campus" [disabled]="group.disabled">
                                <mat-option [value]="group.building" disabled="true">{{group.building}}</mat-option>
                                <mat-option *ngFor="let floors of group.floors" [value]="floors.value" style="margin-left:1em;">{{floors.viewValue}}</mat-option>
                              </mat-optgroup>                              
                              </mat-select> 
                              
                              <mat-error>Please select Floor</mat-error>                                                   
                          </mat-form-field>
                        </div>
                        
                        <div class="text-inside" id="diveditFloortxt" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                            <input matInput placeholder="Floor Name" maxlength="32" formControlName="name" [(ngModel)]="data.FloorName" (keyup)="handleInput($event)" required>
                            <mat-error *ngIf="flooreditForm.controls.name.hasError('required')">
                                Please enter Floor Name
                              </mat-error>
                              <mat-error *ngIf="flooreditForm.controls.name.hasError('pattern')">
                                  Please enter valid Floor Name
                               </mat-error>
                               <mat-error *ngIf="flooreditForm.controls.name.hasError('maxlength')">
                                  Max length 32 characters only
                               </mat-error>
                          </mat-form-field>
                        </div>
                        
                    </mat-grid-tile>
                    
                    <mat-grid-tile class="clsfloormatgrid">
                      <label>Zone is Floor</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsfloormatgridtxt">                         
                    <mat-slide-toggle id="chkZoneFloor" (change)="zoneisFloorEvent($event)" [checked]="checkZoneisFloor"></mat-slide-toggle>
                  </mat-grid-tile>
                      
                      <mat-grid-tile>
                        <label>Zone Name </label>
                    </mat-grid-tile>
                    <mat-grid-tile class="clsmatgridtxt">
                            <div class="text-inside" style="width:100%;">
                                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                               <input matInput placeholder="Zone Name" formControlName="zoneName" maxlength="32" [disabled]="!disableZoneisFloor" [(ngModel)]="selectedZoneName" (keyup)="handleInput($event)" required/>
                               <mat-error *ngIf="flooreditForm.controls.zoneName.hasError('required')">Please enter Zone Name</mat-error>
                               <mat-error *ngIf="flooreditForm.controls.zoneName.hasError('pattern')">Please enter valid Zone Name</mat-error>
                               <mat-error *ngIf="flooreditForm.controls.zoneName.hasError('maxlength')">Max length 32 characters only</mat-error>
                            </mat-form-field>
                          </div>
                    </mat-grid-tile> 
      
                    <mat-grid-tile>
                      <label>Description</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsmatgridtxt">
                          <div class="text-inside" style="width:100%;">
                              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                            <input matInput placeholder="Description" maxlength="32" formControlName="description" [(ngModel)]="data.Description" (keyup)="handleInput($event)"/>      
                            <!-- <mat-error *ngIf="flooreditForm.controls.description.hasError('required')">Please enter Description</mat-error> -->
                            <mat-error *ngIf="flooreditForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>                                           
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>
                  
                  <mat-grid-tile class="clsfloormatgrid">
                    <label>Notifications</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsmatgridtxt">
                    <div class="text-inside clsdivfloornotification">                   
                          <div style="padding: 8px 10px;">
                          <label>Campus (Default)</label>
                          <mat-checkbox id="floorchkcampus" [checked]="F_chkCampusvalue" (change)="togglefloorCamVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                        </div>
                         <div style="margin-left:.5em;padding:8px 0px;">
                          <label>Custom</label>
                          <mat-checkbox id="floorchkcustom" [checked]="F_chkCustomvalue" (change)="togglefloorCusVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                        </div>                  
                    </div>
                  </mat-grid-tile> 
                  
                  <mat-grid-tile class="clsfloormatgrid">
                    <label>Turn on Check-In for All Residents</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsfloormatgridtxt">
                    <div class="text-inside">                   
                      <mat-checkbox id="floorchkcheckin" [checked]="editCheck_Invalue" (change)="toggleEditCheckInVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>                 
                    </div>
                  </mat-grid-tile> 
      
                      </mat-grid-list>           
                  
                  </div>
                  
                  
                  <div fxFlex="55" fxFlex.md="45" style="margin-top:1em;" class="clsdivactivefloors">
                    <label>Active Spaces and Devices in the Zone:</label>
                      <mat-grid-list [cols]="columns" class="clsmatfloorgridlist">
                          <!-- <mat-grid-tile> -->
                            <div style="height: 650px;">
                            <perfect-scrollbar style="max-width: 1200px; max-height: 680px;" >   
                              
                              <div style="margin:1em;" [ngStyle]="{'display': checkZoneisFloor == false ? 'none' : 'flex'}">
                                <label style="font-size: medium;">Sub-Zones</label>
                              </div>  
                              <div class="text-inside" style="margin:1em;display: flex;" *ngFor="let SubZone of SubZonedetails;" [ngStyle]="{'display': checkZoneisFloor == false ? 'none' : 'flex'}">                                                                            
                                <img src="assets/images/Edit_Icon.png" class="clsflooreditimg" (click)="addEditSubZoneDetails(SubZone,1)" [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}"/>
                                <img src="assets/images/deletebuilding.png" style="margin:0em 1em;" class="clsflooreditimg" (click)="opensubzonedeletedialog(SubZone)" [ngStyle]="{'display': bDelete == false ? 'none' : 'block'}"/>
                               <div *ngIf="SubZone.SubZoneDesc.length < 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}"> {{SubZone.SubZoneDesc}}-{{SubZone.SpaceCount}} Spaces, {{SubZone.SpaceCount}} Devices </div>
                               <div *ngIf="SubZone.SubZoneDesc.length >= 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}" matTooltipClass="devicetooltip" matTooltip={{SubZone.SubZoneDesc}} #tooltip="matTooltip" matTooltipPosition="above"> 
                                {{SubZone.SubZoneDesc.slice(0,15) + ".."}}-{{SubZone.SpaceCount}} Spaces, {{SubZone.SpaceCount}} Devices</div>
                              </div> 
                              
                              <div style="margin:1em;">
                                <label style="font-size: medium;">Spaces</label>
                              </div>  
                              <div *ngFor="let Unit of UnitInfo;">
                                <div class="text-inside" style="margin:1em;display: flex;" *ngIf="Unit.Zonedevicecount == 0">
                                  <img src="assets/images/Edit_Icon.png" class="clsflooreditimg" (click)="editSpacedetails(Unit)" [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}"/>
                                  <img src="assets/images/deletebuilding.png" style="margin:0em 1em;" class="clsflooreditimg" (click)="opendeletedialog(Unit)" [ngStyle]="{'display': bDelete == false ? 'none' : 'block'}"/>
                                <div *ngIf="Unit.UnitName.length < 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}"> {{Unit.UnitName}}, {{Unit.DeviceZoneCount}} Devices</div>
                                <div *ngIf="Unit.UnitName.length >= 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}" matTooltipClass="devicetooltip" matTooltip={{Unit.UnitName}} #tooltip="matTooltip" matTooltipPosition="above">
                                  {{Unit.UnitName.slice(0,15) + ".."}}, {{Unit.DeviceZoneCount}} Devices</div>
                                </div>
                              </div>
                              <div style="margin:1em;">
                                <label style="font-size: medium;">Devices</label>
                              </div>  
                              <div *ngFor="let Device of DeviceInfo;">
                              <div class="text-inside" style="margin:1em;display: flex;" *ngIf="Device.FilterDeviceType != _NewDeviceTypeDesc.Approx">
                                <img src="assets/images/Edit_Icon.png" class="clsflooreditimg" (click)="editDevice_details(Device)" [ngStyle]="{'opacity': Device.FilterDeviceType == 'Zone' ? '0.5' : 'none','display': bAddEdit == false ? 'none' : 'block'}"/>
                                <img src="assets/images/deletebuilding.png" style="margin:0em 1em;" class="clsflooreditimg" (click)="opendeletedevicedialog(Device)" [ngStyle]="{'opacity': Device.FilterDeviceType == 'Zone' ? '0.5' : 'none','display': bDelete == false ? 'none' : 'block'}"/>
                               <div *ngIf="Device.UnitLocation.length < 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}"> {{Device.NewDeviceName}} - {{Device.UnitLocation}}</div>
                               <div *ngIf="Device.UnitLocation.length >= 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}" matTooltipClass="devicetooltip" matTooltip={{Device.UnitLocation}} #tooltip="matTooltip" matTooltipPosition="above"> 
                                {{Device.NewDeviceName}} - {{Device.UnitLocation.slice(0,15) + ".."}}</div>
                              </div> 
                              </div>
                              
                        </perfect-scrollbar>
                      </div>
                        </mat-grid-list>
                  </div>
                  
                </div>
                <mat-dialog-actions style="float:left;">              
                  <button type="button" class="clsfloordltbutton" mat-raised-button (click)="opendeletefloordialog()" *ngIf="bDelete==true">Delete</button> 
                  </mat-dialog-actions>                  
                
                  <mat-dialog-actions class="clsmatdialogopenzone">                    
                    <button type="button" class="clsfloordltbutton" color="primary" style="width:8rem;" mat-raised-button [ngStyle]="{'display': checkZoneisFloor == false ? 'none' : 'block'}" (click)="addEditSubZoneDetails('',0)">Add Sub-Zones</button>
                    <button type="button" class="clsfloordltbutton" color="primary" style="width:6rem;margin-left: 2em;" mat-raised-button (click)="FloorMapView()">Open Zone</button>                    
                  </mat-dialog-actions>
                  
                <mat-dialog-actions class="clsflooreditbuttoncontrol">                 
                  <button type="button" class="clsfloordltbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
                  <button id="btnsaveflooreditclick" class="clsflooreditbutton" mat-raised-button [disabled]="!flooreditForm.valid"  *ngIf="bAddEdit == true">Save</button>
                </mat-dialog-actions>
                  
              </form>
              
                                          
    </div>
</perfect-scrollbar>