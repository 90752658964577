import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { ReportService } from "../report.service";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { AlertReportFilter } from "../report";
import {
  enumAlertStatus,
  EnumAlerttype_report,
  enumAlertType,
  alert_deviceType,
  NewDeviceTypeDesc,
  enumAlertSubType,
} from "../../../app-settings";
import "jspdf-autotable";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { Router } from "@angular/router";
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import * as moment from "moment";
import { Subject } from "rxjs";
import { HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");
@Component({
  selector: "app-download-alert-report",
  templateUrl: "./download-alert-report.component.html",
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./download-alert-report.component.scss"],
})
export class DownloadAlertReportComponent implements OnInit, OnDestroy {
  Filterexcel: AlertReportFilter[];
  public EnumAlertStatus = enumAlertStatus;
  events: string[] = [];
  public alertReportForm: UntypedFormGroup;
  fromdate;
  todate;
  time;
  downloadfromdate;
  downloadtodate;
  Acked;
  ReportFilter;
  todaydate;
  Todaydate;
  ErrorMsg;
  startDate;
  columns = 3;
  public watcher: Observable<boolean>;
  alertrowHeight = "70px";
  screenWidth;
  AlertFromdateFormControl = new UntypedFormControl("", [Validators.required]);
  AlertTodateFormControl = new UntypedFormControl("", [Validators.required]);
  private _onDestroy: Subject<void> = new Subject<void>();
  downloadAlertStatus;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  public isExcelDownload = false;
  public isPDFDownload = false;
  constructor(
    private router: Router,
    private reportService: ReportService,
    private datePipe: DatePipe,
    public thisDialogRef: MatDialogRef<DownloadAlertReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,    
    private breakpointObserver: BreakpointObserver
  ) {    
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) || 
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }

  ngOnInit() {
    this.onResize();
    this.alertReportForm = new UntypedFormGroup({
      alertTime: new UntypedFormControl("", [Validators.required]),
      alertstatus: new UntypedFormControl("", [Validators.required]),
    });

    this.startDate = "1900-01-01T12:00:00.000Z";
    this.todaydate = new Date();
    this.Todaydate = this.datePipe.transform(this.todaydate, "yyyy-MM-dd");

    const loaderelement = document.getElementsByClassName(
      "loadalertreportspinner"
    );
    loaderelement[0]["style"].display = "none";
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    this.screenWidth = window.innerWidth;
    if (isIEOrEdge == true) {
      this.columns = 3;
      this.alertrowHeight = "72px";
    }

    if (isIEOrEdge == false && this.screenWidth < 540) {
      this.columns = 2;
      this.alertrowHeight = "87px";
    } else if (isIEOrEdge == true && this.screenWidth <= 549) {
      this.columns = 2;
      this.alertrowHeight = "95px";
    } else if (isIEOrEdge == false) {
      this.columns = 3;
      this.alertrowHeight = "70px";
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    try {
      this.events.push(`${type}: ${event.value}`);
      this.fromdate = ` ${event.value}`;
      this.fromdate = this.datePipe.transform(this.fromdate);
      this.downloadfromdate = this.fromdate;
    } catch (err) {
      throw new Error(
        "download-alert-report.component.ts" + " - addEvent() - " + err.message
      );
    }
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    try {
      this.events.push(`${type}: ${event.value}`);
      this.todate = `${event.value}`;
      this.todate = this.datePipe.transform(this.todate);
      this.downloadtodate = this.todate;
    } catch (err) {
      throw new Error(
        "download-alert-report.component.ts" + " - addEvent1() - " + err.message
      );
    }
  }
  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  selectTimes(data) {
    this.time = data;
  }
  selectAcked(data) {
    this.Acked = data;
  }

  exportAlertReportExcel(csvdata) {
    if (
      this.alertReportForm.valid &&
      this.AlertFromdateFormControl.valid &&
      this.AlertTodateFormControl.valid
    ) {
      const loaderelement = document.getElementsByClassName(
        "loadalertreportspinner"
      );
      loaderelement[0]["style"].display = "block";
    }
    setTimeout(() => {
      this.export_AlertReportExcel(csvdata);
    }, 3);
  }

  export_AlertReportExcel(data) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (
        this.alertReportForm.valid &&
        this.AlertFromdateFormControl.valid &&
        this.AlertTodateFormControl.valid
      ) {
        if (!this.isExcelDownload) {
          this.isExcelDownload = true;
          const selectFromdate = new Date(this.downloadfromdate);
          selectFromdate.setHours(0, 0, 0, 0);
          const selectTodate = new Date(this.downloadtodate);
          selectTodate.setHours(0, 0, 0, 0);
          if (selectFromdate > selectTodate) {
            const loaderelement = document.getElementsByClassName(
              "loadalertreportspinner"
            );
            loaderelement[0]["style"].display = "none";
            generateAlertMessage(
              this.dialog,
              "From date should be less than or equal to To date"
            );
            this.isExcelDownload = false;
            return;
          }
          this.todaydate = new Date();
          this.Todaydate = this.datePipe.transform(
            this.todaydate,
            "yyyy-MM-dd"
          );

          let Filterexcel;

          if (this.Acked == "All") this.downloadAlertStatus = "All";
          else if (this.Acked == 0)
            this.downloadAlertStatus = enumAlertStatus.Notified;
          else if (this.Acked == 1)
            this.downloadAlertStatus = enumAlertStatus.Accepted;
          else if (this.Acked == 2)
            this.downloadAlertStatus = enumAlertStatus.Presence;
          else if (this.Acked == 3)
            this.downloadAlertStatus = enumAlertStatus.Complete;
          else if (this.Acked == 4)
            this.downloadAlertStatus = enumAlertStatus.Canceled;
          else if (this.Acked == 5)
            this.downloadAlertStatus = enumAlertStatus.Notify;
          if (data.length > 0) {
            if (this.time == "AlertTime") {
              data.forEach(function (e) {
                const newalerttime = new Date(e.AlertTime);
                newalerttime.setHours(0, 0, 0, 0);
                e["NewTime"] = newalerttime;
              });
            } else if (this.time == "AckedTime") {
              data.forEach(function (e) {
                const newackedtime = new Date(e.AckedTime);
                newackedtime.setHours(0, 0, 0, 0);
                e["NewTime"] = newackedtime;
              });
            }

            if (this.Acked != "All") {
              const AlertStatus = this.downloadAlertStatus;

              Filterexcel = data.filter(function (e1) {
                return (
                  e1.NewTime >= selectFromdate &&
                  e1.NewTime <= selectTodate &&
                  e1.AckStatus == AlertStatus
                );
              });
            } else {
              Filterexcel = data.filter(function (e1) {
                return (
                  e1.NewTime >= selectFromdate && e1.NewTime <= selectTodate
                );
              });
            }
            this.exportAlertReporttoExcel(Filterexcel, this.Todaydate);
          } else {
            const loaderelement = document.getElementsByClassName(
              "loadalertreportspinner"
            );
            loaderelement[0]["style"].display = "none";
            if (this.downloadAlertStatus == "All")
              generateAlertMessage(this.dialog, "No alerts found");
            else if (this.downloadAlertStatus != undefined)
              generateAlertMessage(
                this.dialog,
                "No alerts found for " + this.downloadAlertStatus + " type"
              );
            this.isExcelDownload = false;
          }
        }
      }
    } catch (err) {
      throw new Error(
        "download-alert-report.component.ts" +
          " - export_AlertReportExcel() - " +
          err.message
      );
    }
  }

  exportAlertReporttoExcel(ReportFilter, date) {
    try {
      const Report = {
        Data: [] as any[],
      };
      let Acknowledge_By = " ";
      let Description = "";
      let AckedMethod = "";
      //let RoomName = "";
      let AckedTime = "";
      //let RoomId = "";

      if (ReportFilter.length > 0) {
        for (let j = 0; j < ReportFilter.length; j++) {
          let AlertType = ReportFilter[j].AlertType;

          AlertType = this.getAlertType(AlertType);

          // if (ReportFilter[j].RoomName) {
          //   RoomName = ReportFilter[j].RoomName;
          // }

          // if (ReportFilter[j].RoomId) {
          //   RoomId = ReportFilter[j].RoomId;
          // }

          if (ReportFilter[j].AckedTime) {
            AckedTime = ReportFilter[j].AckedTime;
          }

          const AlertLevel = ReportFilter[j].AlertLevel;
          const AckStatus = ReportFilter[j].AckStatus;
          const item = ReportFilter[j];

          if (item.AlarmSubTypeName == null || item.AlarmSubTypeName == "") {
            Description = item.AlarmName;
          } else {
            if (item.Alert == "Unauthorized Egress")
              Description = item.AlarmName + " (Wandering)";
            else {
              Description = item.AlarmName + " (" + item.Alert + ")";
            }
          }

          if (item.AlarmSubTypeName == null || item.AlarmSubTypeName == "") {
            Description = item.AlarmName;
          }

          let Name;
          if (item.AlertType == enumAlertType.thirdparty) {
            // no change
            Name = item.FirstName + " " + item.LastName;
          } else if (
            (item.DeviceType == alert_deviceType.Tag ||
              item.DeviceType == alert_deviceType.Pager_Communication ||
              item.DeviceType == alert_deviceType.Battery ||
              (item.DeviceType == alert_deviceType.DC &&
                item.AlertSubType == enumAlertSubType.Tag_In_Field)) &&
            item.AlertType != enumAlertType.UT
          )
            Name = item.FirstName + " " + item.LastName;
          else if (
            item.DeviceType == alert_deviceType.REX &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.Rex;
          else if (
            item.DeviceType == alert_deviceType.HREX &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.HRex;
          else if (
            item.DeviceType == alert_deviceType.DC &&
            item.AlertSubType != enumAlertSubType.Tag_In_Field &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.DC;
          else if (
            item.DeviceType == alert_deviceType.Emitter &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.Emitter;
          else if (
              item.DeviceType == alert_deviceType.ADT_LFExciter &&
              item.AlertType != enumAlertType.UT
            )
              Name = NewDeviceTypeDesc.LFExiter;    
          else if (
                item.DeviceType == alert_deviceType.DripTray &&
                item.AlertType != enumAlertType.UT
              )
                Name = NewDeviceTypeDesc.DripTray;                          
          else if (
            item.DeviceType == alert_deviceType.Pull_Cord &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.Pullcord;
          else if (
            item.AlertType == enumAlertType.UT ||
            item.DeviceType == alert_deviceType.UT
          )
            Name = NewDeviceTypeDesc.UT;

          const Room_Info = item.RoomInfo;

          if (item.AckedMethod == null) {
            AckedMethod = "";
          } else {
            AckedMethod = item.AckedMethod;
          }
          if (item.AckedMethod == "Self") Acknowledge_By = "Self";
          else if (item.AckedFirstName == null && item.AckedLastName == null)
            Acknowledge_By = " ";
          else if (item.AckedFirstName == null)
            Acknowledge_By = item.AckedLastName;
          else if (item.AckedLastName == null)
            Acknowledge_By = item.AckedFirstName;
          else Acknowledge_By = item.AckedFirstName + " " + item.AckedLastName;

          if (item.AckedTime == null) {
            AckedTime = "";
          } else {
            if (
              item.AckStatus == enumAlertStatus.Canceled ||
              item.AckStatus == enumAlertStatus.Complete
            )
              AckedTime = ('"' + item.AckedTime + '"').replace(/"/g, " ");
            else AckedTime = "";
          }

          let _AlertTime = item.AlertTime;
          if (_AlertTime)
            _AlertTime = ('"' + _AlertTime + '"').replace(/"/g, " ");
          else _AlertTime = "";

          if (item.NotifiedTime)
            item.NotifiedTime = ('"' + item.NotifiedTime + '"').replace(
              /"/g,
              " "
            );
          else item.NotifiedTime = "";

          let ResponseTime;
          if (item.ResponseTime == null) {
            ResponseTime = "";
          } else {
            if (
              item.AckStatus == enumAlertStatus.Canceled ||
              item.AckStatus == enumAlertStatus.Complete
            )
              ResponseTime = item.ResponseTime;
            else ResponseTime = "";
          }

          let PresenceOn = "";
          if (item.PresenceOn != null) {
            PresenceOn = ('"' + item.PresenceOn + '"').replace(/"/g, " ");
          }

          Report.Data.push({
            "Alert Description": Description,
            "Resident Name": Name,
            "Device Id": item.TagId,
            "Room Info.": Room_Info,
            "Alert Time": _AlertTime,
            "Notified Time": item.NotifiedTime,
            "Notified Level": AlertLevel,
            "Acked Time": AckedTime,
            "Acked Method": AckedMethod,
            "Status": AckStatus,
            "Acked By": Acknowledge_By,
            "Presence On": PresenceOn,
            "Response Time": ResponseTime,
          });
        }

        this.JSONToCSVConvertor(Report.Data, date, true);
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadalertreportspinner"
        );
        loaderelement[0]["style"].display = "none";
        if (this.downloadAlertStatus == "All")
          generateAlertMessage(this.dialog, "No alerts found");
        else if (this.downloadAlertStatus != undefined)
          generateAlertMessage(
            this.dialog,
            "No alerts found for " + this.downloadAlertStatus + " type"
          );
        this.isExcelDownload = false;
      }
    } catch (err) {
      throw new Error(
        "download-alert-report.component.ts" +
          " - exportAlertReporttoExcel() - " +
          err.message
      );
    }
  }

  JSONToCSVConvertor(JSONData, date, ShowLabel) {
    try {
      const arrData =
        typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
      let CSV = "";
      //This condition will generate the Label/Header
      if (ShowLabel) {
        let row = "";

        //This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);
        //append Label row with line break
        CSV += row + "\r\n";
      }

      if (arrData.length > 0) {
        //1st loop is to extract each row
        for (let i = 0; i < arrData.length; i++) {
          let row = "";

          //2nd loop will extract each column and convert it in string comma-seprated
          for (const index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }

          row.slice(0, row.length - 1);

          //add a line break after each row
          CSV += row + "\r\n";
        }
        if (CSV == "") {
          const loaderelement = document.getElementsByClassName(
            "loadalertreportspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Invalid data");
          this.isExcelDownload = false;
          return;
        }

        //Generate a file name
        let fileName = "";
        fileName = "Alert_Report_Data";
        const myBlob = new Blob([CSV], { type: "text/html" });
        const url = window.URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        const link = document.body.appendChild(a);
        document.body.appendChild(link);
        link.href = url;
        link.download = fileName + "_" + date + ".csv";
        link.click();
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadalertreportspinner"
        );
        loaderelement[0]["style"].display = "none";
        if (this.downloadAlertStatus == "All")
          generateAlertMessage(this.dialog, "No alerts found");
        else if (this.downloadAlertStatus != undefined)
          generateAlertMessage(
            this.dialog,
            "No alerts found for " + this.downloadAlertStatus + " type"
          );
        this.isExcelDownload = false;
      }
      const loaderelement = document.getElementsByClassName(
        "loadalertreportspinner"
      );
      loaderelement[0]["style"].display = "none";
      this.isExcelDownload = false;
    } catch (err) {
      throw new Error(
        "download-alert-report.component.ts" +
          " - JSONToCSVConvertor() - " +
          err.message
      );
    }
  }

  getAlertType(type) {
    if (type == 1) return EnumAlerttype_report.buttonpress;
    else if (type == 2) return EnumAlerttype_report.infrafailure;
    else if (type == 3) return EnumAlerttype_report.checkin;
    else if (type == 4) return EnumAlerttype_report.usafezone;
    else return "";
  }

  exportAlertReportPdf(pdfdata) {
    if (
      this.alertReportForm.valid &&
      this.AlertFromdateFormControl.valid &&
      this.AlertTodateFormControl.valid
    ) {
      const loaderelement = document.getElementsByClassName(
        "loadalertreportspinner"
      );
      loaderelement[0]["style"].display = "block";
    }
    setTimeout(() => {
      this.export_AlertReportPdf(pdfdata);
    }, 3);
  }

  export_AlertReportPdf(PDFdata) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (
        this.alertReportForm.valid &&
        this.AlertFromdateFormControl.valid &&
        this.AlertTodateFormControl.valid
      ) {
        if (!this.isPDFDownload) {
          this.isPDFDownload = true;
          const selectFromdate = new Date(this.downloadfromdate);
          selectFromdate.setHours(0, 0, 0, 0);
          const selectTodate = new Date(this.downloadtodate);
          selectTodate.setHours(0, 0, 0, 0);
          if (selectFromdate > selectTodate) {
            const loaderelement = document.getElementsByClassName(
              "loadalertreportspinner"
            );
            loaderelement[0]["style"].display = "none";
            generateAlertMessage(
              this.dialog,
              "From date should be less than or equal to To date"
            );
            this.isPDFDownload = false;
            return;
          }

          this.todaydate = new Date();
          this.Todaydate = this.datePipe.transform(
            this.todaydate,
            "yyyy-MM-dd"
          );

          let FilterPDF;

          if (this.Acked == "All") this.downloadAlertStatus = "All";
          else if (this.Acked == 0)
            this.downloadAlertStatus = enumAlertStatus.Notified;
          else if (this.Acked == 1)
            this.downloadAlertStatus = enumAlertStatus.Accepted;
          else if (this.Acked == 2)
            this.downloadAlertStatus = enumAlertStatus.Presence;
          else if (this.Acked == 3)
            this.downloadAlertStatus = enumAlertStatus.Complete;
          else if (this.Acked == 4)
            this.downloadAlertStatus = enumAlertStatus.Canceled;
          else if (this.Acked == 5)
            this.downloadAlertStatus = enumAlertStatus.Notify;

          if (PDFdata.length > 0) {
            if (this.time == "AlertTime") {
              PDFdata.forEach(function (e) {
                const newalerttime = new Date(e.AlertTime);
                newalerttime.setHours(0, 0, 0, 0);
                e["NewTime"] = newalerttime;
              });
            } else if (this.time == "AckedTime") {
              PDFdata.forEach(function (e) {
                const newackedtime = new Date(e.AckedTime);
                newackedtime.setHours(0, 0, 0, 0);
                e["NewTime"] = newackedtime;
              });
            }

            if (this.Acked != "All") {
              const AlertStatus = this.downloadAlertStatus;
              FilterPDF = PDFdata.filter(function (e1) {
                return (
                  e1.NewTime >= selectFromdate &&
                  e1.NewTime <= selectTodate &&
                  e1.AckStatus == AlertStatus
                );
              });
            } else {
              FilterPDF = PDFdata.filter(function (e1) {
                return (
                  e1.NewTime >= selectFromdate && e1.NewTime <= selectTodate
                );
              });
            }
            this.exportAlertReporttoPDF(FilterPDF, this.Todaydate);
          } else {
            const loaderelement = document.getElementsByClassName(
              "loadalertreportspinner"
            );
            loaderelement[0]["style"].display = "none";
            if (this.downloadAlertStatus == "All")
              generateAlertMessage(this.dialog, "No alerts found");
            else if (this.downloadAlertStatus != undefined)
              generateAlertMessage(
                this.dialog,
                "No alerts found for " + this.downloadAlertStatus + " type"
              );
            this.isPDFDownload = false;
          }
        }
      }
    } catch (err) {
      throw new Error(
        "download-alert-report.component.ts" +
          " - export_AlertReportPdf() - " +
          err.message
      );
    }
  }

  exportAlertReporttoPDF(ReportFilter, date) {
    try {
      const resData = [] as any[];
      let Acknowledge_By = "";
      let Description = "";
      let Name;
      let TagId = "";
      let AlertTime = "";
      let NotifiedTime = "";
      let AckedTime = "";
      let AckedMethod = "";
      let Room_Info = "";
      let ResponseTime;
      let PresenceOn;
      // let RoomName = "";
      // let RoomId = "";

      const columns = [
        { title: "Alert\nDescription", dataKey: "Description" },
        { title: "Resident\nName", dataKey: "Name" },
        { title: "Device\nId", dataKey: "TagId" },
        { title: "Room\nInfo.", dataKey: "Room_Info" },
        { title: "Alert\nTime", dataKey: "AlertTime" },
        { title: "Notified\nTime", dataKey: "NotifiedTime" },
        { title: "Notified\nLevel", dataKey: "AlertLevel" },
        { title: "Acked\nTime", dataKey: "AckedTime" },
        { title: "Acked\nMethod", dataKey: "AckedMethod" },
        { title: "Status", dataKey: "AckStatus" },
        { title: "Acked\nBy", dataKey: "Acknowledge_By" },
        { title: "Presence\nOn", dataKey: "PresenceOn" },
        { title: "Response\nTime", dataKey: "ResponseTime" },
      ];

      if (ReportFilter.length > 0) {
        for (let j = 0; j < ReportFilter.length; j++) {
          let AlertType = ReportFilter[j].AlertType;

          AlertType = this.getAlertType(AlertType);

          // if (ReportFilter[j].RoomName) {
          //   RoomName = ReportFilter[j].RoomName;
          // }

          // if (ReportFilter[j].RoomId) {
          //   RoomId = ReportFilter[j].RoomId;
          // }

          const AlertLevel = ReportFilter[j].AlertLevel;
          const AckStatus = ReportFilter[j].AckStatus;

          const item = ReportFilter[j];

          if (item.AlarmSubTypeName == null || item.AlarmSubTypeName == "") {
            Description = item.AlarmName;
          } else {
            if (item.Alert == "Unauthorized Egress")
              Description = item.AlarmName + " (Wandering)";
            else {
              Description = item.AlarmName + " (" + item.Alert + ")";
            }
          }

          if (
            (item.DeviceType == alert_deviceType.Tag ||
              item.DeviceType == alert_deviceType.Pager_Communication ||
              item.DeviceType == alert_deviceType.Battery ||
              (item.DeviceType == alert_deviceType.DC &&
                item.AlertSubType == enumAlertSubType.Tag_In_Field)) &&
            item.AlertType != enumAlertType.UT
          )
            Name = item.FirstName + " " + item.LastName;
          else if (
            item.DeviceType == alert_deviceType.REX &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.Rex;
          else if (
            item.DeviceType == alert_deviceType.HREX &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.HRex;
          else if (
            item.DeviceType == alert_deviceType.DC &&
            item.AlertSubType != enumAlertSubType.Tag_In_Field &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.DC;
          else if (
            item.DeviceType == alert_deviceType.Emitter &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.Emitter;
          else if (
              item.DeviceType == alert_deviceType.ADT_LFExciter &&
              item.AlertType != enumAlertType.UT
            )
              Name = NewDeviceTypeDesc.LFExiter;     
          else if (
                item.DeviceType == alert_deviceType.DripTray &&
                item.AlertType != enumAlertType.UT
              )
                Name = NewDeviceTypeDesc.DripTray;                        
          else if (
            item.DeviceType == alert_deviceType.Pull_Cord &&
            item.AlertType != enumAlertType.UT
          )
            Name = NewDeviceTypeDesc.Pullcord;
          else if (
            item.AlertType == enumAlertType.UT ||
            item.DeviceType == alert_deviceType.UT
          )
            Name = NewDeviceTypeDesc.UT;

          TagId = item.TagId;
          AlertTime = item.AlertTime;
          NotifiedTime = item.NotifiedTime;
          AckedTime = "";

          if (item.AckedTime == null) {
            AckedTime = "";
          } else {
            if (
              item.AckStatus == enumAlertStatus.Canceled ||
              item.AckStatus == enumAlertStatus.Complete
            )
              AckedTime = item.AckedTime;
            else AckedTime = "";
          }

          if (item.AckedMethod == null) {
            AckedMethod = "";
          } else {
            AckedMethod = item.AckedMethod;
          }

          Room_Info = item.RoomInfo;
          if (item.AckedMethod == "Self") Acknowledge_By = "Self";
          else if (item.AckedFirstName == null && item.AckedLastName != null)
            Acknowledge_By = item.AckedLastName;
          else if (item.AckedFirstName != null && item.AckedLastName == null)
            Acknowledge_By = item.AckedFirstName;
          else if (item.AckedFirstName == null && item.AckedLastName == null) {
            Acknowledge_By = "";
          } else {
            Acknowledge_By = item.AckedFirstName + " " + item.AckedLastName;
          }

          if (item.ResponseTime == null) {
            ResponseTime = "";
          } else {
            if (
              item.AckStatus == enumAlertStatus.Canceled ||
              item.AckStatus == enumAlertStatus.Complete
            )
              ResponseTime = item.ResponseTime;
            else ResponseTime = "";
          }

          if (item.PresenceOn != null) PresenceOn = item.PresenceOn;
          else PresenceOn = "";

          resData.push({
            Description,
            Name,
            TagId,
            Room_Info,
            AlertTime,
            NotifiedTime,
            AlertLevel,
            AckedTime,
            AckedMethod,
            AckStatus,
            Acknowledge_By,
            PresenceOn,
            ResponseTime,
          });
        }
        const pdf = new jsPDF("l"); // A4 size page of PDF
        let PdfFileName = "Alert_Report_Data";

        const pageWidth =
          pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        pdf.text("Alert Report", pageWidth / 2, 10, "center");

        const options = {
          theme: "grid",
          bodyStyles: { lineColor: [0, 0, 0] },
            tableWidth: 'auto',
            columnWidth: 'wrap',
            showHeader: 'everyPage',
          headStyles: {
            lineWidth: 0.2,
            lineColor: [0, 0, 0],
            textColor: [0, 0, 0],
            fillColor: [169, 169, 169],
            valign: "middle",
            halign: "center",
          },
          columnStyles: {
            Name: { cellWidth: 30, minCellHeight: 24 },
            TagId: { cellWidth: 17, minCellHeight: 24 },
            Room_Info: { cellWidth: 22, minCellHeight: 24 },
            AlertTime: { cellWidth: 20, minCellHeight: 24 },
            NotifiedTime: { cellWidth: 20, minCellHeight: 24 },
            AlertLevel: { cellWidth: 16, minCellHeight: 24 },
            AckedTime: { cellWidth: 20, minCellHeight: 24 },
            AckedMethod: { cellWidth: 20, minCellHeight: 24 },
            AckStatus: { cellWidth: 20, minCellHeight: 24 },
            Acknowledge_By: { cellWidth: 18, minCellHeight: 24 },
            PresenceOn: { cellWidth: 20, minCellHeight: 24 },
            ResponseTime: { cellWidth: 20, minCellHeight: 24 },
          },
        };

        pdf.autoTable(columns, resData, options);
        PdfFileName = PdfFileName + "_" + date + ".pdf";
        pdf.save(PdfFileName);
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadalertreportspinner"
        );
        loaderelement[0]["style"].display = "none";
        if (this.downloadAlertStatus == "All")
          generateAlertMessage(this.dialog, "No alerts found");
        else if (this.downloadAlertStatus != undefined)
          generateAlertMessage(
            this.dialog,
            "No alerts found for " + this.downloadAlertStatus + " type"
          );
        this.isPDFDownload = false;
      }
      const loaderelement = document.getElementsByClassName(
        "loadalertreportspinner"
      );
      loaderelement[0]["style"].display = "none";
      this.isPDFDownload = false;
    } catch (err) {
      throw new Error(
        "download-alert-report.component.ts" +
          " - exportAlertReporttoPDF() - " +
          err.message
      );
    }
  }
  getErrorMessage(pickerInput: string): any {
    if (pickerInput == "") {
      return "Please select date";
    } else if (!moment(pickerInput).isValid()) {
      return "Please enter valid date format MM/DD/YYYY";
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
