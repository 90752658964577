<perfect-scrollbar style="max-width: 1200px; max-height: 780px;" >
  <div class="clsaddeditSubzone">
      <div class="subZone-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="subZoneHeader" style="margin: 0 0 10px 11px;">{{subZoneHeaderText}}
        <span><img class="clsSubZonecloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="addeditSubZoneForm" autocomplete="off" novalidate (ngSubmit)="saveSubZoneInfo(addeditSubZoneForm.value)">
      <div class="content" fxLayout="row" fxLayout.md="column" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div fxFlex="65" fxFlex.md="25">                                                
                
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">                                       
                  
                  <mat-grid-tile class="clssubzonematgrid">
                    <label>Selected<br>Zone</label>
                </mat-grid-tile>
                <mat-grid-tile class="clssubzonematgridtxt">
                        <div class="clssubzonetext-inside"> 
                          <mat-form-field appearance="outline" floatLabel="auto" class="clssubzonetxt">
                            <input matInput placeholder="Zone Name" formControlName="zoneName" maxlength="65" [(ngModel)]="selectedZoneName" readonly/>                                                        
                         </mat-form-field>
                      </div>
                </mat-grid-tile> 
                
                <mat-grid-tile class="clssubzonematgrid">
                  <label>Sub-Zone<br>Name</label>
                </mat-grid-tile>
                <mat-grid-tile class="clssubzonematgridtxt">
                        <div class="clssubzonetext-inside"> 
                          <mat-form-field appearance="outline" floatLabel="auto" class="clssubzonetxt">
                            <input matInput placeholder="Sub-Zone Name" formControlName="subzoneName" [(ngModel)]="SubZoneName" (keyup)="handleInput($event)" maxlength="32"/>  
                            <mat-error *ngIf="addeditSubZoneForm.controls.subzoneName.hasError('required')">Please enter Sub-Zone Name</mat-error>
                            <mat-error *ngIf="addeditSubZoneForm.controls.subzoneName.hasError('pattern')">Please enter valid Sub-Zone Name</mat-error>                                                                                                         
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

                   </mat-grid-list>   
                   
                   <mat-grid-list [cols]="columns">
                    <div class="clsimagedivlist">
                       <div id="map" class="clssubzoneDragimg map" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" draggable="false">
                      </div> 
                    </div>
                  </mat-grid-list>
                  
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                    <mat-grid-tile class="clssubzonematgrid">
                      <label>Notifications</label>
                    </mat-grid-tile>
                    <mat-grid-tile class="clssubzonematgridtxt">
                      <div class="text-inside clsdiveditsubzone">                   
                            <div style="padding: 8px 10px;">
                            <label>Campus (Default)</label>
                            <mat-checkbox class="clslivelocation clsenable" id="chkcampus" [checked]="chkCampusvalue" (change)="toggleCusVisibility($event,1)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                          </div>
                           <div style="margin-left:.5em;padding:8px 0px;">
                            <label>Custom</label>
                            <mat-checkbox class="clslivelocation clsenable" id="chkcustom" [checked]="chkCustomvalue" (change)="toggleCusVisibility($event,2)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                          </div>                  
                      </div>
                    </mat-grid-tile> 
                  </mat-grid-list> 
              </div>
         
              <div fxFlex="45" fxFlex.md="45" style="margin-top:1em;">
                <label>Active Spaces and Devices in this Zone:</label>
                  <mat-grid-list [cols]="columns" class="clssubZonematgridlist">
                    
                        <perfect-scrollbar style="max-width: 1200px; max-height: 400px;" >                          
                          <div style="margin:1em;">
                            <label style="font-size: medium;">Spaces</label>
                          </div>  
                          
                          <div  class="clsunitTemptext-inside" style="margin:1em;" *ngFor="let Space of Spacedetails">                          
                           <div class="text-inside" style="margin-top:1em;" *ngIf="Space.Zonedevicecount == 0">                                                                            
                            <mat-checkbox class="clssubzonecheckbox clsenable" [checked]="Space.isChecked" [id]="'chkfloor_'+Space.UnitId" (click)="$event.stopPropagation()"
                            (change)="$event ? togglefloorVisibility(Space.UnitId,$event) : null" style="font-size: 14px;" [disabled]="Space.SubZoneId > 0 && !Space.isChecked">
                            {{Space.UnitName}}, {{Space.devicecount}} Devices</mat-checkbox>
                          </div>                                                                                  
                          </div>                                                                                                       
                    
                    </perfect-scrollbar>
                    </mat-grid-list>
              </div>          
        
    </div>
 
      <mat-dialog-actions style="float:left;">              
        <button type="button" class="clssubzonedltbutton" [ngStyle]="{'display': SubZoneId == 0 ? 'none' : 'block'}" (click)="openSubZoneDeletedialog()" mat-raised-button>Delete</button>
      </mat-dialog-actions>
        
      <mat-dialog-actions class="clssubzonebuttoncontrol">      
        <button type="button" class="clssubzonecancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
        <button id="btnzonetempsaveclick" class="clssubzonesavebutton" mat-raised-button>Save</button>
      </mat-dialog-actions>
  </form>
      
    </div>
</perfect-scrollbar>