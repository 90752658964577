import { Injectable, Inject } from "@angular/core";
import { HttpClient  } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map  } from "rxjs/operators";
import { appsettings } from "./app-settings";
import { RollbarService } from "./rollbar";
import * as Rollbar from "rollbar";
import { AlertSharedService } from "./_services/index";
import {
  SharedCheckAlertData,
  ErrorMessgeData,
  staffresidentdata,
  InfrastructureReportDetails,
  ResGroup,
  GetAlertsId,
  getCampusFulldetail,
  getCampusdetails,
  getBuildingdetails,
  getFloordetails,
  getUnitdetails,
  TagIdfoundResponse,
  GroupNamefoundResponse,
  AlertfoundtagidResponse,
  Resident,
  StaffByID,
  NearestStaffdata,
  Facility,
  DeviceDetails,
  Staff,
} from "./api";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  headers: Headers = new Headers();

  constructor(
    private http: HttpClient,
    private alertSharedService: AlertSharedService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  getResidentDataUrl = appsettings.API_ENDPOINT + "residents/getResidentData"; // URL to web api
  checkTagIdUrl = appsettings.API_ENDPOINT + "residents/checkTagId";
  checkAlertForTagUrl = appsettings.API_ENDPOINT + "residents/checkAlertForTag";
  checkMapLocationUrl = appsettings.API_ENDPOINT + "residents/checkMapLocation";
  checkAlertForCampusUrl =
    appsettings.API_ENDPOINT + "residents/checkAlertForCampus";
  getResidentGroupUrl = appsettings.API_ENDPOINT + "residents/getResidentGroup";
  checkEmailIdUrl = appsettings.API_ENDPOINT + "residents/checkEmailId";
  checkPhoneNumberUrl = appsettings.API_ENDPOINT + "residents/checkPhoneNumber";
  checkGroupNameUrl = appsettings.API_ENDPOINT + "residents/checkGroupName";
  backupPhotoUrl = appsettings.API_ENDPOINT + "residents/backupPhoto";
  getGroupNotificationInfoUrl =
    appsettings.API_ENDPOINT + "residents/getGroupNotificationInfo";

  getStaffDataByIdUrl = appsettings.API_ENDPOINT + "staffs/getStaffDataById";
  checkMapSyncUrl = appsettings.API_ENDPOINT + "staffs/checkMapSync";
  getStaffDataUrl = appsettings.API_ENDPOINT + "staffs/getStaffData";

  deleteNotificationByLocationUrl =
    appsettings.API_ENDPOINT + "notifications/deleteNotificationByLocation";
  checkGroupNotificationUrl =
    appsettings.API_ENDPOINT + "notifications/checkGroupNotification";
  checkGroupNotificationlevelUrl =
    appsettings.API_ENDPOINT + "notifications/checkGroupNotificationlevel";

  getCampusbyCampusIdUrl =
    appsettings.API_ENDPOINT + "maps/getCampusbyCampusId";
  getGlobalAlertsUrl = appsettings.API_ENDPOINT + "maps/getGlobalAlerts";
  getStaffResidentCurrentLocationUrl =
    appsettings.API_ENDPOINT + "maps/getStaffResidentCurrentLocation";
  getAllRoomsUrl = appsettings.API_ENDPOINT + "maps/getAllRooms";
  cancelAlertUrl = appsettings.API_ENDPOINT + "maps/cancelAlert";

  getCampusDetailsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getAllCampus";
  getBuildingDetailsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getBuildingByCampusId";
  getFloorDetailsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getAllFloor";
    
  getFloorwithZoneDetailsUrl = appsettings.API_ENDPOINT+'technologyoverlays/getFloorwithZoneDetails';
  getZoneDetailsUrl = appsettings.API_ENDPOINT+'technologyoverlays/getZoneDetails';
    
  getUnitDetailsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getAllUnit";
  getFacilityInfoUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getFacilityInfo";
  getDeviceDetailsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getDeviceDetails";

  // getDeviceDetailsbyIdUrl =
  //   appsettings.API_ENDPOINT + "technologyoverlays/getDeviceDetailsById";

  public reloadactivetag_result;
  private DCURL = appsettings.URLinfo + "reloadDCiniltc";
  private URL = appsettings.URLinfo + "reloadActiveTags";
  private DrawRoomURL = appsettings.URLinfo + "reloadDrawRoom";
  private reloadLiveURL = appsettings.URLinfo + "reloadlive";
  private reloadDCTDeviceURL = appsettings.URLinfo + "reloadUTiniltc";
  private reloadMapConfigURL = appsettings.URLinfo + "reloadmapconfig";
  private reloadstaffURL = appsettings.URLinfo + "reloadstaff";
  private reloadPageralertURL = appsettings.URLinfo + "reloadclearpageralert";
  private sendReloadBoardConfigurationURL =
    appsettings.URLinfo + "sendReloadBoardConfiguration";

  private reloadNotificationURL = appsettings.URLinfo + "reloadNotification";
  public reloadNotification_result;

  CheckAlertData: SharedCheckAlertData;

  public reloadINI_result_DC;
  public reload_result_DrawRoom;
  public reloadlive_result;
  public reloadINI_DCT_result;
  public reloadINI_MapConfig_result;
  public reloadINI_Staff_result;
  public reloadINI_Pager_result;
  public reloadBoardConfiguration_result;

  public login(username: string, password: string, ipaddress: string) {
    return this.http
      .post<any>(appsettings.API_ENDPOINT + "staffs/userAuthentication", {
        userName: username,
        password: password,
        ipAddress: ipaddress,
      })
      .pipe(
        map((user) => {
          // login successful if there's a user in the response
          
          
          return user;
        })
      );
  }

  /*************reload_DC_Config***************/
  reload_DC_Config() {
    try {
      this.http.get(this.DCURL).subscribe((result) => {
        this.reloadINI_result_DC = result;
      });
    } catch (err) {
      throw new Error(
        "Notification.service.ts" + " - reload_DC_Config() - " + err.message
      );
    }
  }

  /*************reload_DrawRoom***************/
  reload_DrawRoom() {
    try {
      this.http.get(this.DrawRoomURL).subscribe((result) => {
        this.reload_result_DrawRoom = result;
      });
    } catch (err) {
      throw new Error(
        "Notification.service.ts" + " - reload_DrawRoom() - " + err.message
      );
    }
  }

  reloadactivetag() {
    try {
      this.http.get(this.URL).subscribe(
        (result) => {
          this.reloadactivetag_result = result;
        },
        (error) => console.error(error)
      );
    } catch (err) {
      throw new Error(
        "Notification.service.ts" + " - reload_DrawRoom() - " + err.message
      );
    }
  }

  reloadlive() {
    try {
      this.http.get(this.reloadLiveURL).subscribe((result) => {
        this.reloadlive_result = result;
      });
    } catch (err) {
      throw new Error(
        "wanderconfig.service.ts" + " - reloadlive() - " + err.message
      );
    }
  }

  checkfile_name(filename) {
    if (/^[a-zA-Z0-9- ._]*$/.test(filename) == false) {
      return false;
    } else return true;
  }

  reloadDCTdevice() {
    try {
      this.http.get(this.reloadDCTDeviceURL).subscribe((result) => {
        this.reloadINI_DCT_result = result;
      });
    } catch (err) {
      throw new Error(
        "wanderconfig.service.ts" + " - reloadDCTdevice() - " + err.message
      );
    }
  }

  reloadmapconfig() {
    try {
      this.http.get(this.reloadMapConfigURL).subscribe((result) => {
        this.reloadINI_MapConfig_result = result;
      });
    } catch (err) {
      throw new Error(
        "api.service.ts" + " - reloadmapconfig() - " + err.message
      );
    }
  }

  reloadStaff() {
    try {
      this.http.get(this.reloadstaffURL).subscribe((result) => {
        this.reloadINI_Staff_result = result;
      });
    } catch (err) {
      throw new Error("api.service.ts" + " - reloadStaff() - " + err.message);
    }
  }

  reloadPagerAlert() {
    try {
      this.http.get(this.reloadPageralertURL).subscribe((result) => {
        this.reloadINI_Pager_result = result;
      });
    } catch (err) {
      throw new Error(
        "api.service.ts" + " - reloadPagerAlert() - " + err.message
      );
    }
  }

  reloadBoardConfiguration() {
    try {
      this.http
        .get(this.sendReloadBoardConfigurationURL)
        .subscribe((result) => {
          this.reloadBoardConfiguration_result = result;
        });
    } catch (err) {
      throw new Error(
        "api.service.ts" + " - reloadBoardConfiguration() - " + err.message
      );
    }
  }

  reloadNotification() {
    try {
      this.http.get(this.reloadNotificationURL).subscribe((result) => {
        this.reloadNotification_result = result;
      });
    } catch (err) {
      throw new Error(
        "api.service.ts" + " - reloadNotification() - " + err.message
      );
    }
  }

  /*************Get Campus,Building,Floor,Unit details***************/
  getResidentData(residentdata): Observable<Resident[]> {
    return this.http.post<Resident[]>(this.getResidentDataUrl, residentdata);
  }

  getResidentGroup(ResidentId): Observable<ResGroup[]> {
    return this.http.post<ResGroup[]>(this.getResidentGroupUrl, ResidentId);
  }

  getCampusbyCampusId(campusdetails): Observable<getCampusFulldetail[]> {
    return this.http.post<getCampusFulldetail[]>(
      this.getCampusbyCampusIdUrl,
      campusdetails
    );
  }

  getCampusDetails(reqCampusInfo): Observable<getCampusdetails[]> {
    return this.http.post<getCampusdetails[]>(this.getCampusDetailsUrl, reqCampusInfo);
  }

  getBuildingDetails(campusdetails): Observable<getBuildingdetails[]> {
    return this.http.post<getBuildingdetails[]>(
      this.getBuildingDetailsUrl,
      campusdetails
    );
  }
  
  getFloorwithZoneDetails(buildingdetails): Observable<getFloordetails[]> {    
    return this.http.post<getFloordetails[]>(this.getFloorwithZoneDetailsUrl,buildingdetails) 
  }

   getFloorDetails(buildingdetails): Observable<getFloordetails[]> {
     return this.http.post<getFloordetails[]>(
       this.getFloorDetailsUrl,
       buildingdetails
     );
   }
  
  getZoneDetails(buildingdetails): Observable<getFloordetails[]> {    
    return this.http.post<getFloordetails[]>(this.getZoneDetailsUrl,buildingdetails) 
  }

  getUnitDetails(floordetails): Observable<getUnitdetails[]> {
    return this.http.post<getUnitdetails[]>(
      this.getUnitDetailsUrl,
      floordetails
    );
  }

  checkTagId(tagidfoundItem): Observable<TagIdfoundResponse[]> {
    return this.http.post<TagIdfoundResponse[]>(
      this.checkTagIdUrl,
      tagidfoundItem
    );
  }

  checkMapLocation(campusfoundItem): Observable<TagIdfoundResponse[]> {
    return this.http.post<TagIdfoundResponse[]>(
      this.checkMapLocationUrl,
      campusfoundItem
    );
  }

  checkEmailId(emailidfoundItem): Observable<TagIdfoundResponse[]> {
    return this.http.post<TagIdfoundResponse[]>(
      this.checkEmailIdUrl,
      emailidfoundItem
    );
  }

  checkPhoneNumber(phonenumberfoundItem): Observable<TagIdfoundResponse[]> {
    return this.http.post<TagIdfoundResponse[]>(
      this.checkPhoneNumberUrl,
      phonenumberfoundItem
    );
  }

  checkGroupName(groupnamefoundItem): Observable<GroupNamefoundResponse[]> {
    return this.http.post<GroupNamefoundResponse[]>(
      this.checkGroupNameUrl,
      groupnamefoundItem
    );
  }

  checkAlertForTag(alerttagidfoundItem): Observable<AlertfoundtagidResponse[]> {
    return this.http.post<AlertfoundtagidResponse[]>(
      this.checkAlertForTagUrl,
      alerttagidfoundItem
    );
  }

  checkAlertForCampus(
    alertcampusfoundItem
  ): Observable<AlertfoundtagidResponse[]> {
    return this.http.post<AlertfoundtagidResponse[]>(
      this.checkAlertForCampusUrl,
      alertcampusfoundItem
    );
  }

  getStaffDataById(ResidentId): Observable<StaffByID[]> {
    return this.http.post<StaffByID[]>(this.getStaffDataByIdUrl, ResidentId);
  }

  backupPhoto(selectFilename): Observable<AlertfoundtagidResponse[]> {
    return this.http.post<AlertfoundtagidResponse[]>(
      this.backupPhotoUrl,
      selectFilename
    );
  }

  getGroupNotificationInfo(selectedgrpId): Observable<GetAlertsId[]> {
    return this.http.post<GetAlertsId[]>(
      this.getGroupNotificationInfoUrl,
      selectedgrpId
    );
  }

  getGlobalAlerts(alertNotifydata): Observable<ErrorMessgeData[]> {
    return this.http.post<ErrorMessgeData[]>(
      this.getGlobalAlertsUrl,
      alertNotifydata
    );
  }

  /** GET MapSyncProcess from the server */
  checkMapSync(mapsyncinfo): Observable<any[]> {
    return this.http.post<any[]>(this.checkMapSyncUrl, mapsyncinfo);
  }

  getStaffResidentCurrentLocation(item): Observable<staffresidentdata[]> {
    return this.http.post<staffresidentdata[]>(
      this.getStaffResidentCurrentLocationUrl,
      item
    );
  }

  getAllRooms(item): Observable<any[]> {
    return this.http.post<any[]>(this.getAllRoomsUrl, item);
  }

  checkGroupNotification(groupitem): Observable<any[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.checkGroupNotificationUrl,
      groupitem
    );
  }

  deleteNotificationByLocation(deleteitem): Observable<any[]> {
    return this.http.post<any[]>(
      this.deleteNotificationByLocationUrl,
      deleteitem
    );
  }

  checkGroupNotificationlevel(groupitem): Observable<any[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.checkGroupNotificationlevelUrl,
      groupitem
    );
  }

  getStaffData(staffitem): Observable<Staff[]> {
    return this.http.post<Staff[]>(this.getStaffDataUrl, staffitem);
  }

  cancelAlert(item): Observable<NearestStaffdata[]> {
    return this.http.post<NearestStaffdata[]>(this.cancelAlertUrl, item);
  }

  getFacilityInfo(facilityData): Observable<Facility[]> {
    return this.http.post<Facility[]>(this.getFacilityInfoUrl, facilityData);
  }

  getDeviceDetails(data): Observable<DeviceDetails[]> {
    return this.http.post<DeviceDetails[]>(this.getDeviceDetailsUrl, data);
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      throw new Error(
        "api.service.ts - " + operation + "() - " + error.message
      );
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  fetchStaffData(data): void {
    try {
      this.getStaffDataById(data).subscribe((staffinfo: any[]) => {
        this.alertSharedService.changesharedStaffData(staffinfo);
      });
    } catch (err) {
      throw new Error(
        "Throw" + "staff.service.ts" + " - fetchStaffData() - " + err.message
      );
    }
  }
}
