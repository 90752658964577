import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import {
  getDeviceDetailsReq,
  saveTemplateSpace,
} from "../../../technologyoverlay";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import { enumEnableDisable,NewDeviceTypeDesc,DeviceType,appsettings } from "../../.././../../app-settings";
import { Router } from "@angular/router";
import { ApiService } from "../../../../../api.service";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-template-unit",
  templateUrl: "./template-unit.component.html",
  styleUrls: ["./template-unit.component.scss"],
})
export class TemplateUnitComponent implements OnInit {
  public unitTempateForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  selectedUnitId;
  selectedFloorId;
  FloorInfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  DevicesInfo;
  columns = 4;
  firstcolumns = 2;
  device_list_all = "3";
  minheight = "350px";
  rowHeight = "79px";
  checkspacetempselectall = false;
  checkspacetempinfo = false;
  select_DeviceIds = [] as any[];
  selectedFloorName;
  spacetempcountchecked_id = 0;
  SpaceImageName;
  DeviceInfowithoutZone = [];
  authkey;
  selectCampusId;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<TemplateUnitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.selectedUnitId = data.UnitId;
    this.selectedFloorId = data.FloorId;
    this.selectCampusId = data.CampusId;
    this.selectedFloorName = data.FloorName;
    this.SpaceImageName = data.FloorPlanImage;
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }    
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.unitTempateForm = new UntypedFormGroup({
      unitname: new UntypedFormControl(""),
      campusID: new UntypedFormControl(""),
      buildID: new UntypedFormControl(""),
      floorID: new UntypedFormControl(""),
      unitID: new UntypedFormControl(""),
      newtempname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]), // ^[a-zA-Z0-9!@#$%^& ]*$
      description: new UntypedFormControl("", [
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
    const formUnitName = this.unitTempateForm.get("unitname");
    if (formUnitName != null) formUnitName.disable();

    this.getDeviceDetails();
  }

  getDeviceDetails() {
    try {
      const device_infos = [] as any;
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.selectCampusId,
        floorId: parseInt(this.selectedFloorId),
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: parseInt(this.selectedUnitId),
        isIndoor: 1,
        isNeedRoomonly: 1,
        isNeedIntercom: parseInt(voiceLicense)
      };

      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          this.DevicesInfo = DeviceDetails;
          DeviceDetails["apiReturn"].forEach(function (data) {
            if (data.FilterDeviceType != "Zone") {
              device_infos.push(data);
            }
            if(data.FilterDeviceType == NewDeviceTypeDesc.LF)
            {
              if (data.SubType==DeviceType.DripTray)
              {
                data.FilterDeviceType =NewDeviceTypeDesc.DripTray
              }
              else
              {
               data.FilterDeviceType =NewDeviceTypeDesc.LFExiter
              }
            }
          });
          this.DeviceInfowithoutZone = device_infos;
        });
    } catch (err) {
      throw new Error(
        "template-unit.component.ts" + " - getDeviceDetails() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  isCheckedAll = false;
  toggleAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.select_DeviceIds = [];
      this.DevicesInfo.forEach((row) => {
        if (row.FilterDeviceType != "Zone") {
          const checkedid = row.DataId;
          this.select_DeviceIds.push(checkedid);
        }
      });
    } else {
      this.select_DeviceIds = [];
      this.select_DeviceIds.length = 0;
    }
    this.isCheckedAll = this.isChecked();
  }

  toggleUnitVisibility(checkedid, event: MatCheckboxChange) {
    if (event.checked) {
      const index = this.select_DeviceIds.indexOf(checkedid);
      if (index == -1) {
        this.select_DeviceIds.push(checkedid);
      }
    } else {
      const index = this.select_DeviceIds.indexOf(checkedid);
      if (index >= 0) {
        this.select_DeviceIds.splice(index, 1);
      }
    }
    this.isCheckedAll = this.isChecked();
  }

  exists(checkedid) {
    return this.select_DeviceIds.indexOf(checkedid) > -1;
  }

  isChecked() {
    return this.select_DeviceIds.length === this.DeviceInfowithoutZone.length;
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.unitTempateForm.controls).forEach((field) => {
        // {1}
        const control = this.unitTempateForm.get(field); // {2}
        const formUnitTagetId = document.getElementById(event.target.id);
        if (formUnitTagetId != null) {
          if (field == formUnitTagetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public templateunitDetails = (templateunitformvalue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.unitTempateForm.valid) {
        this.saveAsTemplateForSpace(templateunitformvalue);
      }
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" +
          " - templatefloorDetails() - " +
          err.message
      );
    }
  };

  saveAsTemplateForSpace(templateunitformvalue) {
    try {
      if (this.select_DeviceIds.length == 0 && this.DevicesInfo.length > 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Device to create as a new Space Template."
        );
        this.templateUnitEnableDisable(enumEnableDisable.Enable);
        return;
      }
      if (this.select_DeviceIds.length == 0 && this.DevicesInfo.length == 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Device to duplicate or create as a Template."
        );
        this.templateUnitEnableDisable(enumEnableDisable.Enable);
        return;
      }

      this.templateUnitEnableDisable(enumEnableDisable.Disable);
      let _Description = "";
      if (templateunitformvalue.description != "")
        _Description = templateunitformvalue.description
          .replace(/\s+/g, " ")
          .trim();
      else _Description = "";

      const templateunit: saveTemplateSpace = {
        authKey: this.authkey,
        campusId: parseInt(templateunitformvalue.campusID),
        buildingId: parseInt(templateunitformvalue.buildID),
        floorId: parseInt(templateunitformvalue.floorID),
        spaceId: parseInt(templateunitformvalue.unitID),
        tempImageName: this.SpaceImageName.toString(),
        templateName: templateunitformvalue.newtempname
          .replace(/\s+/g, " ")
          .trim(),
        templateDescription: _Description, //templateunitformvalue.description,
        deviceIds: this.select_DeviceIds.toString(),
      };

      const resultData = {
        FloorId: this.selectedFloorId,
        FloorName: this.selectedFloorName,
      };
      this.technologyoverlayService
        .saveAsTemplateForSpace(templateunit)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "Space Template added successfully."
            );
            this.templateUnitEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close(resultData);
            this.select_DeviceIds = [];
          }
          if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Space Template added failed.");
            this.templateUnitEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close("Cancel");
            this.select_DeviceIds = [];
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else if (apiresponse["condition"] == "Duplicate Gateway") {
            generateAlertMessage(
              this.dialog,
              "Clear Gateway already added.Please select any other devices"
            );
            this.templateUnitEnableDisable(enumEnableDisable.Enable);
            return;
          } else if (apiresponse["condition"] == "Duplicate Clear Router") {
            generateAlertMessage(
              this.dialog,
              "Clear Router already added.Please select any other devices"
            );
            this.templateUnitEnableDisable(enumEnableDisable.Enable);
            return;
          } else if (apiresponse["TemplateNameinfo"].length > 0) {
            generateAlertMessage(
              this.dialog,
              "Space Template name ' " +
                apiresponse["TemplateNameinfo"] +
                " ' already exists"
            );
            this.templateUnitEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" +
          " - saveAsTemplateForSpace() - " +
          err.message
      );
    }
  }

  templateUnitEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnspacetempsaveclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clstempsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clstempsavebutton mat-raised-button disablebtn";
  }
}
