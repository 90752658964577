
<app-report-header  [ReportType]="ReportType"></app-report-header>
<mat-card class="report-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-report-leftbar></app-report-leftbar>
    </div>
      
    <div fxLayout="column" style="width:100%;">
    <!--Customize Locators Report-->
    <div class="table-container">
     
        <div class="main-table"> 
        <div class="landing-title-bar">
          <div fxFlex="70" fxFlex.md="40" fxFlex.sm="30" fxFlex.xs="20">
            <mat-card-header>
              <mat-card-title style="font-size: 20px;margin-top:15px;">
                <span class="clsTitle">Customize View</span>
              </mat-card-title>
            </mat-card-header>
          </div>
        </div> 
        
        <perfect-scrollbar class="chartscrollbarCusLocators"> 

        <div fxLayout.md="column" fxLayout.sm="column">

        <div class="flex-container wrap" fxLayout.md="column" fxLayout.sm="column">                    
          <button id="colorconfigtbtn" class="clsImportbutton" color="primary" mat-raised-button (click)="colorconfig()">Color Config</button>                    
            <div class="flex-container wrap">
              <ul *ngFor="let location of LocationType" class="clscolor_ul">
                  <li class="dot" [ngStyle]="{'background-color': location.TypeColor}"></li>
                <li  class="clsspantext">{{location.TypeName}}</li>           
              </ul>
            </div>
        </div>  
        
        <div class="customsearch" fxLayout.md="column" fxLayout.sm="column">
            <mat-form-field appearance="outline" floatLabel="left" class="clscusmatformfield">
              <input matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchCusLocatorsReport($event.target.value)"
                autocomplete="off" placeholder="Finder" #searchCusLocatorsInput>
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        

        <div class="clscuslocfilter" fxLayout.md="column" fxLayout.sm="column">
            <mat-form-field id="selectWeek"  appearance="outline" floatLabel="auto" class="clscusmatformfield">
              <mat-select placeholder="Color Config" [(ngModel)]="selectedLocationType" (selectionChange)="cusloc_selectLocationType($event.value)">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let location of LocationType" [value]="location.LocationTypeId" title="{{ location.TypeName.length >= 15 ? location.TypeName : ''}}">{{location.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field id="selectWeek"  appearance="outline" floatLabel="auto" class="clscusmatformfield">
              <mat-select placeholder="State Changed By" [(ngModel)]="cusloc_selectStateBy" (selectionChange)="CusLoc_selectStateby($event.value)">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let Staff of cusloc_selstatechange" [value]="Staff.StaffID" title="{{ Staff.Name.length >= 15 ? Staff.Name : ''}}">{{Staff.Name}}</mat-option>
              </mat-select>
            </mat-form-field>
            
            <button class="clsCustomSavebutton" color="primary" mat-raised-button (click)="saveCutomizeLocatorsdata() " style="background-color:#ffa500;">Save</button>
            <button class="clsImportbutton" color="primary" mat-raised-button (click)="clkfileInput.click()">Import</button>
            <input type="file" class="clschoosebutton text-inside-grid" #clkfileInput (change)="onChangeImportFile($event.target.files)" required accept=".csv">     
            <a href="#customizelocators" (click)="downloadCusLocators_Report()" style="white-space: nowrap;">Sample File</a>         
          </div> 
                   

        </div>
        <!-- <div class="customsearch" fxLayout.md="column" fxLayout.sm="column">
          <div  style="float:right;margin-right:30px;">
            <a href="#report" (click)="downloadCusLocators_Report()">Sample File</a>
         </div>
        </div> -->
     </div>

        <div *ngIf="CustomizeLocatorsSource else noCustomRecord">
            <table mat-table [dataSource]="CustomizeLocatorsSource" matSort (matSortChange)="sortData($event)" #CusLocsort="matSort" matSortDisableClear=true>                 

                <!-- TruLocatorID Column -->
                <ng-container matColumnDef="EmitterID">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Location ID</th>                  
                  <td mat-cell *matCellDef="let element"> {{element.EmitterID}} </td>
                </ng-container>

              <!-- TruLocationName Column -->
              <ng-container matColumnDef="Tru_RoomName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Location Name</th>
                <td mat-cell *matCellDef="let element"> 

                    <span *ngIf="element.Tru_RoomName.length < 15" >{{element.Tru_RoomName}}</span>
                    <span *ngIf="element.Tru_RoomName.length >= 15" matTooltipClass="customzietooltip" matTooltip={{element.Tru_RoomName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.Tru_RoomName.slice(0,15) + ".."}}</span>

                </td>
              </ng-container>

              <!-- LocationType Column -->
              <ng-container matColumnDef="DeviceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Location Type</th>
                <td mat-cell *matCellDef="let element"> {{element.NewDeviceName}} </td>
              </ng-container>
           
               <!-- EnableStateChange Column -->
               <ng-container matColumnDef="stateChange">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Enable State Change</th>
                <td mat-cell *matCellDef="let element let i=index;"><mat-slide-toggle [(ngModel)]="element.stateChange" (change)="clkStatechanged($event.checked,element)" style="padding-left:2.5em;"></mat-slide-toggle></td>
              </ng-container>

              <!-- Auto-State Column -->
              <ng-container matColumnDef="roomcolor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left:2.5em;" class="clsmatheadercell">Auto-State</th>
                <td mat-cell *matCellDef="let element">                 
                <div [id]="'tdautostate'+element.DeviceId" [ngClass]="element.stateChange >= 1 ? 'enablecontent' : 'disablecontent'" *ngFor="let location of LocationType" style="display: inline-block;">
                  <div class="dotlist" [id]="element.DeviceId+'_'+location.LocationTypeId" *ngIf="element.roomcolor != 0" [ngStyle]="{'background-color': location.TypeColor}" [ngClass]="{'dotselectedlist':element.roomcolor == location.LocationTypeId}" (click)="getdivclickelement(element,element.DeviceId,location.LocationTypeId)"></div>
                  <div class="dotlist" [id]="element.DeviceId+'_'+location.LocationTypeId" *ngIf="element.roomcolor == 0" [ngStyle]="{'background-color': location.TypeColor}" [ngClass]="location.LocationTypeId==2 ? 'dotselectedlist':'dotlist'" (click)="getdivclickelement(element,element.DeviceId,location.LocationTypeId)"></div>
                </div>
                </td>
              </ng-container> 
              
              <!-- StateChanged Column -->
              <ng-container matColumnDef="stateChangebyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">State Changed By</th>                               
                  <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.stateChangeby == 0" >{{element.stateChangebyName}}</span>
                      <span *ngIf="element.stateChangeby != 0 && element.stateChangebyName.length < 15" >{{element.stateChangebyName}}</span>
                      <span *ngIf="element.stateChangeby != 0 && element.stateChangebyName.length >= 15" matTooltipClass="tooltip" matTooltip={{element.stateChangebyName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.stateChangebyName.slice(0,15) + ".."}}</span>
                  </td>                 
              </ng-container> 
                          
              <tr mat-header-row *matHeaderRowDef="displayedCustomLocatorsColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns:displayedCustomLocatorsColumns;"></tr>
            </table>

            <h3 *ngIf="CustomizeLocatorsSource.data.length == 0 && !customizelocatorsloading" style="text-align: center;margin-top:1em;"> No Record found </h3>
          </div>

          <ng-template #noCustomRecord>
              <!-- <h3 *ngIf="!customizelocatorsloading" style="text-align: center;margin-top:1em;"> No Record found </h3> -->
                <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%" *ngIf="customizelocatorsloading">       
                    <div class="loadspinner" *ngIf="customizelocatorsloading">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>            
          </ng-template>          

          <mat-paginator #cus_paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>

        </perfect-scrollbar>

       </div>
      
      </div>  
    </div>
    
      </div>
    </mat-card>