import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { ResidentService } from "../../resident.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  ResidentGroupForCreation,
  ResidentGroupForCreationResponse,
} from "../../resident";
import { ApiService } from "../../../../api.service";
import { GroupNamefoundResponse, checkGroupName } from "../../../../api";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {
   appsettings
} from "../../../../app-settings";

@Component({
  selector: "app-add-resident-group",
  templateUrl: "./add-resident-group.component.html",
  providers: [ResidentService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-resident-group.component.scss"],
})
export class AddResidentGroupComponent implements OnInit, OnDestroy {
  public residentGroupCreationForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 3;
  groupdetails;
  groupresrowHeight = "67px";
  residentgroupbool = true;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddResidentGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private residentService: ResidentService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());       
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.onResize();
    this.residentGroupCreationForm = new UntypedFormGroup({
      groupname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      description: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(150),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.residentGroupCreationForm.controls).forEach((field) => {
        // {1}
        const control = this.residentGroupCreationForm.get(field); // {2}
        const resGroupFromId = document.getElementById(event.target.id);
        if (resGroupFromId != null) {
          if (field == resGroupFromId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      this.groupresrowHeight = "72.5px";
    }
  }
  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  public hasError = (controlName: string, errorName: string) => {
    return this.residentGroupCreationForm.controls[controlName].hasError(
      errorName
    );
  };
  public createResidentGroup = (ResidentgroupFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.residentGroupCreationForm.valid && this.residentgroupbool) {
        this.residentgroupbool = false;
        const groupnamefoundItem: checkGroupName = {
          authKey: this.authkey,
          groupType: 1,
          newGroupName: ResidentgroupFormValue.groupname.toString(),
          groupId: 0,
        };
        this.apiService
          .checkGroupName(groupnamefoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: GroupNamefoundResponse[]) => {
            if (apiresponse.toString() == "not found") {
              this.addResidentGroup(ResidentgroupFormValue);
            } else {
              this.residentgroupbool = true;
              generateAlertMessage(
                this.dialog,
                "Resident Group name '" +
                  apiresponse[0].name +
                  "' already exists"
              );
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "add-resident-group.component.ts" +
          " - createResidentGroup() - " +
          err.message
      );
    }
  };

  // Add Resident Group
  private addResidentGroup = (ResidentgroupFormValue) => {
    try {
      const addResidentgroup: ResidentGroupForCreation = {
        authKey: this.authkey,
        groupname: ResidentgroupFormValue.groupname.replace(/\s+/g, " ").trim(),
        description: ResidentgroupFormValue.description
          .replace(/\s+/g, " ")
          .trim(),
      };
      this.residentService
        .addResidentGroup(addResidentgroup)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: ResidentGroupForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            this.residentgroupbool = false;
            generateAlertMessage(
              this.dialog,
              "Resident group added successfully."
            );
          } else if (apiresponse["apiReturn"] == "Fail") {
            this.residentgroupbool = true;
            generateAlertMessage(this.dialog, "Resident group added failed.");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "add-resident-group.component.ts" +
          " - addResidentGroup() - " +
          err.message
      );
    }
  };

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
