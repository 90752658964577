<perfect-scrollbar style="max-width: 1200px; max-height: 600px;" >  
<div class="clsaddDevice">
  <div class="addDevice-title">
    <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addDeviceHeader" style="margin: 0 0 10px 11px;">Choose a Device
      <span><img class="clsdeviceaddcloseimage" src="assets/images/Close_1.png" (click)="onCloseDeviceDialog()"></span></h3>
      <mat-divider ></mat-divider> 
  </div>
  
  
  <div>
    
    <form>
      
      
      <div class="clsdevicesaddlist">                
        
        <mat-grid-list [cols]="columns" [rowHeight]="devicerowHeight" class="clsshowdevicesadd" fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true">          
            <mat-grid-tile style="margin-top:5px;margin-left:10px;" class="clsaddcleardevice">
                <div [ngClass]="showGateway == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                    <mat-icon (click)="DrawGateWayOpt()" [class.addimagedisabled]="gatewayimgDisabled" class="clsdeviceaddmaticons"><img mat-line [src]="GatewayUrl" class="clsdeviceaddimage"></mat-icon>
                    <h4 class="clsaddheadertext">Gateway<br>Enclosure</h4>
                </div>
            </mat-grid-tile>
            
            <mat-grid-tile style="margin-top:5px;margin-left:10px;" class="clsaddcleardevice">
              <div [ngClass]="showRouter == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawClearRouterOpt()" [class.addimagedisabled]="HReximgDisabled" class="clsdeviceaddmaticons"><img mat-line [src]="ClearRouterImgUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext">Clear<br>Router</h4>
              </div>
            </mat-grid-tile>
          
            <mat-grid-tile style="margin-top:5px;margin-left:10px;" class="clsaddcleardevice">
              <div [ngClass]="showRepeater == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawClearRepeaterOpt()" class="clsdeviceaddmaticons"><img mat-line [src]="ClearRepeaterImgUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext">Clear<br>Repeater</h4>
              </div>
            </mat-grid-tile>
            
            <mat-grid-tile style="margin-top:5px;margin-left:10px;" class="clsaddcleardevice">
              <div [ngClass]="showDC == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawDCOpt()" class="clsdeviceaddmaticons"><img mat-line [src]="DCImgUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext">Security<br> Controller</h4>
              </div>
            </mat-grid-tile>
            
            <mat-grid-tile style="margin-top:5px;margin-left:10px;" class="clsaddcleardevice">
              <div [ngClass]="showTrulocator == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawTruLocator()" class="clsdeviceaddmaticons"><img mat-line [src]="TrulocatorImgUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext" style="margin-bottom: 2.5em;">TruLocator</h4>
              </div>
            </mat-grid-tile>
         
            <mat-grid-tile style="margin-left:10px;" class="clsaddcleardevice clsmatgriddevice">
              <div [ngClass]="showMonitor == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawMonitorOpt()" class="clsdeviceaddmaticons"><img mat-line [src]="MonitorUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext">Cmn. Area<br>Locator</h4>
              </div>
            </mat-grid-tile>
            
            <mat-grid-tile class="clsaddcleardevice clsmatgriddevice">
              <div [ngClass]="showVW == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawVWOpt()" class="clsdeviceaddmaticons"><img mat-line [src]="VWUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext">Virtual Wall<br>Locator</h4>
              </div>
            </mat-grid-tile>
            
            <mat-grid-tile style="margin-left:10px;" class="clsaddcleardevice clsmatgriddevice">
              <div [ngClass]="showLF == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawLFOpt()" class="clsdeviceaddmaticons"><img mat-line [src]="LFImgUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext">LF<br>Exciter</h4>
              </div>
            </mat-grid-tile>
            
            <mat-grid-tile style="margin-left:10px;" class="clsaddcleardevice clsmatgriddevice">
              <div [ngClass]="showpullcord == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawPullcordOpt()" class="clsdeviceaddmaticons"><img mat-line [src]="pullcordImgUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext" style="margin-bottom: 2.5em;">Pull Cord</h4>
              </div>
            </mat-grid-tile>
            
            <mat-grid-tile style="margin-left:10px;" class="clsaddcleardevice clsmatgriddevice">
              <div [ngClass]="showUT == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                  <mat-icon (click)="DrawUTOpt()" class="clsdeviceaddmaticons"><img mat-line [src]="UTImgUrl" class="clsdeviceaddimage"></mat-icon>
                  <h4 class="clsaddheadertext">Universal<br>Transmitter</h4>
              </div>
            </mat-grid-tile>            
           
            <mat-grid-tile style="margin-left:10px;" class="clsaddcleardevice clsmatgriddevicenew" *ngIf="voiceLicense == 1">
              <div [ngClass]="showIntercom == false ? 'clsdivaddDevice' : 'clsSelectdeviceadd'">
                <mat-icon (click)="DrawIntercomOpt()" class="clsdeviceaddmaticons"><img mat-line [src]="IntercomUrl"
                    class="clsdeviceaddimage"></mat-icon>
                <h4 class="clsaddheadertext">Intercom</h4>
              </div>
            </mat-grid-tile>
            </mat-grid-list>            

              <mat-dialog-actions class="clsdeviceaddbtncontrol">                         
                  <button class="clsdeviceaddcancelbtn" mat-raised-button (click)="onCloseDeviceDialog()">Cancel</button>
                  <button id="btnsaveclick" class="clsdeviceaddbutton" mat-raised-button (click)="clkAddDevicetoMap()">Add</button>
              </mat-dialog-actions>
              
            </div>                        
           
    </form>
    
  </div>

</div>
</perfect-scrollbar> 
