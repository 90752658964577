
    <div>
        <div class="clsgroupresident">
            <div class="Group-title">
              <h3 class="groupAddHeader" style="margin: 0 0 10px 11px;">Resident Group Info
              <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
              <mat-divider ></mat-divider> 
            </div>
        </div>
        <div>  
          <div class="blocks">              
              <button color="primary"  mat-raised-button (click)="AddResidentGroup()" *ngIf="bAddEdit == true">Add</button>      
          </div>                  
            
                <div class="mat-elevation-z0" style="margin-top:10px;">
                    <perfect-scrollbar style="max-width: 700px; max-height: 250px;">
                    <div *ngIf="dataSourcecount; else noRecord">
                        <table mat-table [dataSource]="dataSource">
                          <!-- Group Name Column -->
                          <ng-container matColumnDef="GroupName">
                              <th mat-header-cell *matHeaderCellDef > Group Name </th>
                              <td mat-cell *matCellDef="let element"> 
                              <span *ngIf="element.GroupName.length < 12" >{{element.GroupName}}</span>
                              <span *ngIf="element.GroupName.length >= 12" matTooltipClass="devicetooltip" matTooltip={{element.GroupName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.GroupName.slice(0,15) + ".."}}</span>
                              </td>
                            </ng-container>  
                          
                            <!-- Description -->
                            <ng-container matColumnDef="Description">
                              <th mat-header-cell *matHeaderCellDef> Description </th>
                              <td mat-cell *matCellDef="let element">
                              <span *ngIf="element.Description.length < 12" >{{element.Description}}</span>
                              <span *ngIf="element.Description.length >= 12" matTooltipClass="devicetooltip" matTooltip={{element.Description}} #tooltip="matTooltip" matTooltipPosition="above">{{element.Description.slice(0,15) + ".."}}</span>
                              </td>
                            </ng-container>
                          
                            <!-- Resident Name Column -->
                            <ng-container matColumnDef="Residents">
                              <th mat-header-cell *matHeaderCellDef style="width:230px;"> Residents </th>
                              <td mat-cell *matCellDef="let element">
                              <span *ngIf="element.ResidentName.length < 12" >{{element.ResidentName}}</span>
                              <span *ngIf="element.ResidentName.length >= 12" matTooltipClass="devicetooltip" matTooltip={{element.ResidentName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.ResidentName.slice(0,15) + ".."}}</span>
                              </td>
                            </ng-container>

                             <!-- Count Column -->
                             <ng-container matColumnDef="Count">
                              <th mat-header-cell *matHeaderCellDef style="width:50px;"> Count </th>
                              <td mat-cell *matCellDef="let element"> {{element.Count}} </td>
                            </ng-container>

                             <!-- Symbol Column -->
                            <ng-container matColumnDef="Edit">
                                <th mat-header-cell *matHeaderCellDef style="width:50px;"> Edit </th>                                    
                                <td mat-cell *matCellDef="let element"><img src="assets/images/Edit_Icon.png" style="cursor:pointer;" (click)="editResidentGroupdata(element.GroupId,element.GroupName,element.Description)"></td>                              
                            </ng-container>
                             
                              <!-- Symbol Column -->
                            <ng-container matColumnDef="Delete">
                              <th mat-header-cell *matHeaderCellDef style="width:30px;"> Delete </th>  
                              <ng-container *matCellDef="let element">      
                              <td mat-cell *ngIf="element.ResidentName == ''"> <img src="assets/images/Release-Delete-Icon.png" style="margin-left:1em;cursor:pointer;" (click)="deleteResidentGroupdata(element.GroupId)"> </td>
                              <td mat-cell *ngIf="element.ResidentName != ''"> <label style="margin-left:1.2em;">--</label></td>                                                            
                            </ng-container> </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                  </div>
                    <ng-template #noRecord>  
                      <h3 style="text-align: center;"> No Record found </h3>                  
                    </ng-template>
                  </perfect-scrollbar>
                </div>                    
        </div>
      
    </div>


 
