<meta name="format-detection" content="telephone=no">
<app-staff-header></app-staff-header>
<mat-card class="staff-mat-card" (click)="CloseRightNav()">
<div class="mat-elevation-z0" style="margin-top:0px;" id="divstaffdetails">

<div class="landing-title-bar">
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutGap="5px" fxLayoutWrap >
      <div fxFlex="25" fxFlex.md="20" class="blocks" style="padding-top:5px;"> 
          <span class="clsTitle">Total Staff : </span>
          <span class="clsTitle1" id="staffcount">{{dataSourcecount}}</span>   
       </div>      
       <div fxFlex="30" fxFlex.xs="20"> 
          <mat-form-field appearance="outline" floatLabel="auto">
              <input style="height:20px;" matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchStaff($event.target.value)" autocomplete="off" placeholder="Search">
              <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
       </div>
       <div fxFlex="55" class="blocks" fxLayout.md="row" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutWrap>                            
            <button id="staffgroup" color="primary" class="clsbuttonStaff" mat-raised-button (click)="ClickStaffGroup()" *ngIf="bGroupView == true">Staff Group</button>
            <button id="addstaff" color="primary" class="clsbuttonStaff" mat-raised-button (click)="addStaffdata()" *ngIf="bAddEdit == true">Add</button> 
            <button id="downloadstaffbtn" color="primary" class="clsbuttonStaff" mat-raised-button (click)="downloadStaffdata()">Download</button>
       </div>
    </div>
    </div>

<perfect-scrollbar class="scrollbar">
<div *ngIf="dataSourcecount; else noRecord">
<table mat-table [dataSource]="dataSource" matSort matSortDisableClear=true>

 
  <!-- Position Column -->
  <ng-container matColumnDef="photo">
      <th mat-header-cell *matHeaderCellDef > Photo </th>
      <td mat-cell *matCellDef="let element"> <img style="width:25px; height:25px; flex-shrink: 0; -o-object-fit: contain; object-fit: contain;" [src]= "staffImgUrl + element.photo"/> </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:300px;"> Staff Name </th>
      <td mat-cell *matCellDef="let element">
      <span *ngIf="element.Name.length < 30">{{element.Name}}</span>
      <span *ngIf="element.Name.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.Name}} #tooltip="matTooltip" matTooltipPosition="above">
        {{element.Name.slice(0,20) + ".."}}</span>
      </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="TagId">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Tag ID </th>
      <td mat-cell *matCellDef="let element"> {{element.TagId}} </td>
    </ng-container>
    
     <!-- Symbol Column -->
    <ng-container matColumnDef="Designation">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Role </th>
      <td mat-cell *matCellDef="let element"> {{element.Designation}} </td>
    </ng-container>
    
     <!-- Symbol Column -->
    <ng-container matColumnDef="Phone" >
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Phone </th>
      <td mat-cell *matCellDef="let element"><p x-ms-format-detection="none">{{element.Phone}}</p></td>
    </ng-container>

     <!-- Symbol Column -->
     <ng-container matColumnDef="Email" >
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.Email.length < 20">{{element.Email}}</span>
          <span *ngIf="element.Email.length >= 20" matTooltipClass="devicetooltip" matTooltip={{element.Email}} #tooltip="matTooltip" matTooltipPosition="above">
            {{element.Email.slice(0,15) + ".."}}
          </span>
          </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="GroupName" >
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Group Name </th>
      <td mat-cell *matCellDef="let element"> 
        <span *ngIf="element.GroupName.length < 12" >{{element.GroupName}}</span>
        <span *ngIf="element.GroupName.length >= 12" matTooltipClass="devicetooltip" matTooltip={{element.GroupName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.GroupName.slice(0,15) + ".."}}</span>
      </td>
    </ng-container>

     <!-- Symbol Column -->
     <ng-container matColumnDef="RoomName" >
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Room </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.RoomName.length < 15">{{element.RoomName}}</span>
          <span *ngIf="element.RoomName.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.RoomName}} #tooltip="matTooltip" matTooltipPosition="above">
            {{element.RoomName.slice(0,15) + ".."}}
          </span>
        </td>
    </ng-container>             
   
     <!-- Symbol Column -->
     
    <ng-container matColumnDef="edit" >
      <th mat-header-cell *matHeaderCellDef style="width:50px;">Edit </th>
      <ng-container *matCellDef="let element">
      <td mat-cell *ngIf="(element.StaffID!=element.adminuser && element.DesignationId >= Logged_RoleID) "> <img src="assets/images/Edit_Icon.png" style="cursor:pointer;" (click)="editStaffdata(element.StaffID)"></td>
      <td mat-cell *ngIf="(element.StaffID!=element.adminuser && element.DesignationId < Logged_RoleID)" style="padding-left:1em;">-</td>
      <td mat-cell *ngIf="(element.StaffID==element.adminuser && StaffId==element.StaffID)"> <img src="assets/images/Edit_Icon.png" style="cursor:pointer;" (click)="editStaffdata(element.StaffID)"></td>
      <td mat-cell *ngIf="(element.StaffID==element.adminuser && StaffId !=element.StaffID)" style="padding-left:1em;">-</td>
    </ng-container>
    </ng-container>
    
     <!-- Symbol Column -->
    <ng-container matColumnDef="delete" >
      <th mat-header-cell *matHeaderCellDef style="width:30px;">Delete </th>
      <ng-container *matCellDef="let element">
      <td mat-cell *ngIf="element.StaffID!=StaffId && element.StaffID!=element.adminuser && element.DesignationId >= Logged_RoleID"> <img src="assets/images/Release-Delete-Icon.png" style="cursor:pointer;margin-left:1em;" (click)="deleteStaffdata(element.StaffID)"></td>
      <td mat-cell *ngIf="!(element.StaffID!=StaffId && element.StaffID!=element.adminuser && element.DesignationId >= Logged_RoleID)" style="padding-left:1em;">-</td>
    </ng-container>
    </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

</div>
</perfect-scrollbar>
<ng-template #noRecord>  
  <mat-card>   
   <h3 *ngIf="!staffloading" style="text-align: center;"> No Record found </h3>    
    <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%">       
        <div class="loadspinner" *ngIf="staffloading">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
  </mat-card>
</ng-template>
<mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>

</div>
</mat-card>

