import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { appsettings } from "../../app-settings";
import { Observable } from "rxjs";
import {
  StaffDesignationList,
  StaffForCreationResponse,
  StaffGroup,
  StaffGroupForCreationResponse,
} from "./staff";
import { HttpErrorHandler, HandleError } from "./http-error-handler.service";
import { TagIdfoundResponse, GetAlertsId } from "../../api";

@Injectable()
export class StaffService {
  //Staff
  getStaffDesignationListUrl =
    appsettings.API_ENDPOINT + "staffs/getStaffDesignationList"; // URL to web api

  addStaffDataUrl = appsettings.API_ENDPOINT + "staffs/addStaffData";
  updateStaffDataUrl = appsettings.API_ENDPOINT + "staffs/updateStaffData";
  deleteStaffDataUrl = appsettings.API_ENDPOINT + "staffs/deleteStaffData";
  //Staff Group
  getStaffGroupUrl = appsettings.API_ENDPOINT + "staffs/getStaffGroup";
  addStaffGroupUrl = appsettings.API_ENDPOINT + "staffs/addStaffGroup";
  updateStaffGroupUrl = appsettings.API_ENDPOINT + "staffs/updateStaffGroup";
  deleteStaffGroupUrl = appsettings.API_ENDPOINT + "staffs/deleteStaffGroup";
  getGroupInfoByStaffUrl =
    appsettings.API_ENDPOINT + "staffs/getGroupInfoByStaff";

  getAcceptAndPresenceAlertsByStaffIdUrl =
    appsettings.API_ENDPOINT + "maps/getAcceptAndPresenceAlertsByStaffId";
  getStaffRoleUrl = appsettings.API_ENDPOINT + "staffs/getStaffRole";
  //SMS
  registerPhoneNumberForOTPUrl =
    appsettings.API_ENDPOINT + "staffs/registerPhoneNumberForOTP";
  verifyPhoneNumberOTPUrl =
    appsettings.API_ENDPOINT + "staffs/verifyPhoneNumberOTP";
  //Email
  registerEmailForOTPUrl =
    appsettings.API_ENDPOINT + "staffs/registerEmailForOTP";
  verifyEmailOTPUrl = appsettings.API_ENDPOINT + "staffs/verifyEmailOTP";

  //PagerNumber
  checkPagerNumberUrl = appsettings.API_ENDPOINT + "staffs/checkPagerNumber";
  getMapDetailsUrl = appsettings.API_ENDPOINT+'technologyoverlays/getMapDetails';

  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("StaffsService");
  }

  /** GET Staffs from the server */

  getStaffDesignationList(satffdata): Observable<StaffDesignationList[]> {
    return this.http.post<StaffDesignationList[]>(
      this.getStaffDesignationListUrl,
      satffdata
    );
  }

  addStaffData(staffForCreation): Observable<StaffForCreationResponse[]> {
    return this.http.post<StaffForCreationResponse[]>(
      this.addStaffDataUrl,
      staffForCreation
    );
  }

  updateStaffData(
    staffEditForCreation
  ): Observable<StaffForCreationResponse[]> {
    return this.http.post<StaffForCreationResponse[]>(
      this.updateStaffDataUrl,
      staffEditForCreation
    );
  }

  deleteStaffData(staffForDelete): Observable<StaffForCreationResponse[]> {
    return this.http.post<StaffForCreationResponse[]>(
      this.deleteStaffDataUrl,
      staffForDelete
    );
  }

  getStaffGroup(StaffId): Observable<StaffGroup[]> {
    return this.http.post<StaffGroup[]>(this.getStaffGroupUrl, StaffId);
  }

  addStaffGroup(StaffGroupdata): Observable<StaffGroupForCreationResponse[]> {
    return this.http.post<StaffGroupForCreationResponse[]>(
      this.addStaffGroupUrl,
      StaffGroupdata
    );
  }

  updateStaffGroup(
    EditStaffGroup
  ): Observable<StaffGroupForCreationResponse[]> {
    return this.http.post<StaffGroupForCreationResponse[]>(
      this.updateStaffGroupUrl,
      EditStaffGroup
    );
  }

  deleteStaffGroup(groupid): Observable<StaffGroupForCreationResponse[]> {
    return this.http.post<StaffGroupForCreationResponse[]>(
      this.deleteStaffGroupUrl,
      groupid
    );
  }

  getAcceptAndPresenceAlertsByStaffId(item): Observable<GetAlertsId[]> {
    return this.http.post<GetAlertsId[]>(
      this.getAcceptAndPresenceAlertsByStaffIdUrl,
      item
    );
  }

  registerPhoneNumberForOTP(
    staffverifydata
  ): Observable<StaffForCreationResponse[]> {
    return this.http.post<StaffForCreationResponse[]>(
      this.registerPhoneNumberForOTPUrl,
      staffverifydata
    );
  }

  verifyPhoneNumberOTP(staffnum): Observable<StaffForCreationResponse[]> {
    return this.http.post<StaffForCreationResponse[]>(
      this.verifyPhoneNumberOTPUrl,
      staffnum
    );
  }

  getStaffRole(staffdetails): Observable<TagIdfoundResponse[]> {
    return this.http.post<TagIdfoundResponse[]>(
      this.getStaffRoleUrl,
      staffdetails
    );
  }

  registerEmailForOTP(staffnum): Observable<StaffForCreationResponse[]> {
    return this.http.post<StaffForCreationResponse[]>(
      this.registerEmailForOTPUrl,
      staffnum
    );
  }

  verifyEmailOTP(staffnum): Observable<StaffForCreationResponse[]> {
    return this.http.post<StaffForCreationResponse[]>(
      this.verifyEmailOTPUrl,
      staffnum
    );
  }

  checkPagerNumber(pagerdetails): Observable<any[]> {
    return this.http.post<any[]>(this.checkPagerNumberUrl, pagerdetails);
  }

  getGroupInfoByStaff(selectedGroupId): Observable<StaffGroup[]> {
    return this.http.post<StaffGroup[]>(
      this.getGroupInfoByStaffUrl,
      selectedGroupId
    );
  }
  getMapDetails(mapdata): Observable<StaffGroup[]> { 
  return this.http.post<StaffGroup[]>(this.getMapDetailsUrl,mapdata) 
}
}
