import { Component, OnInit, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MediaObserver } from '@angular/flex-layout';
import { Router } from "@angular/router";
import { Validators,FormGroup, FormControl } from "@angular/forms";
import { SessionCheck, map_widthandheight, getCheckInExtent,LoadTechnologyFloorSvg, generateAlertMessage, AuthFailedMessage, getFeatureNeedtoShow } from "../../../../app-general";
import { map_creation, fillcolor_style, get_geojsonObject_floor,label_Polygon } from '../../../../map-general';
import {getDeviceDetailsReq} from '../../technologyoverlay';
import { appsettings, fillcolorPolygon, enumRequestFrom, enumUIFeatureView, enumUIAction, enumUIView,NewDeviceTypeDesc,DeviceType,DeviceTypeDesc } from '../../../../app-settings';
import { getUnit, getSubZoneById } from '../../../../api';
import { TechnologyoverlayService } from '../../technologyoverlay.service';
import { AddEditSubZone, DeleteSubZone } from '../../technologyoverlay';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../api.service';
import { Subject } from "rxjs";
import { Style, Fill, Stroke, Text } from 'ol/style';
import { GeneralMessageComponent } from '../../../../general-message/general-message.component';
import OlMap from 'ol/Map';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { DOCUMENT } from "@angular/common";
import { ConfirmDialogComponent } from '../../../technologyoverlay/device/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-managesub-zone',
  templateUrl: './managesub-zone.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./managesub-zone.component.scss']
})
export class ManagesubZoneComponent implements OnInit, OnDestroy {

  public addeditSubZoneForm: FormGroup;
  private _onDestroy: Subject<void> = new Subject<void>();
  selectedZoneName = "";  
  columns = 3;
  firstcolumns = 2;
  minheight = "350px";
  rowHeight = "79px";
  SubZoneInfo = [] as any[];
  selectedCampusId;
  selectedFloorId;
  SiteId = "1";
  Spacedetails;
  select_UnitIds = [] as any[];
  selectedData;
  selectZoneImageUrl;
  SubZonedetails;
  SubZoneName;
  SubZoneId = 0;
  selected_SpaceIds = [] as any[];
  bAddEdit =false;
  bDelete =false;
  device_list_all = "3";
  FloorInfo;
  mapinfo = new OlMap();
  Check_MapCenter;
  chkCampusvalue = true;
  chkCustomvalue = false;
  BuildNotifydefaultValue = 0;
  authkey;
  subZoneHeaderText = "Create Sub-Zone";
  addfloorimageheight = 0;
  floorImageWidth=0;
  floorImageHeight=0;
  Check_ZoomLevel=0;
  CZoomLevel;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      "(min-width: 500px)",
    ])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
  constructor(private router: Router, mediaObserver: MediaObserver, public thisDialogRef: MatDialogRef<ManagesubZoneComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  private technologyoverlayService: TechnologyoverlayService,private apiService: ApiService, public dialog: MatDialog,
  private breakpointObserver: BreakpointObserver,@Inject(DOCUMENT) private document: any,) {     
    this.selectedCampusId = data.subZonedata.CampusId;
    this.selectedFloorId = data.subZonedata.FloorId;
    this.selectedZoneName = data.subZonedata.FloorName + "," + data.buildName + "," + data.campusName;      
    this.SubZoneId = data.subZoneId;
    if(this.SubZoneId > 0)
    {
      this.subZoneHeaderText = "Edit/Delete Sub-Zone";
    }
    this.SubZoneName = data.subZoneName;        
    this.selectedData = data.subZonedata;
    this.floorImageWidth = data.floorImageWidth;
    this.floorImageHeight = data.floorImageHeight;
    this.selectZoneImageUrl = appsettings.FLOOR_IMAGE_FOLDER  + data.subZonedata.FloorPlanImage; 
    
    if(data.Notifications == 0)
    {
      this.chkCampusvalue = true;
      this.chkCustomvalue = false;
    }
    else if(data.Notifications == 1)
    {
      this.chkCustomvalue = true;
      this.chkCampusvalue = false;
    }
    
  } 
  
  breakpointChanged() {     
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Device_Map_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }  
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 3;  
      this.firstcolumns = 2;
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 3;  
      this.firstcolumns = 2; 
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2; 
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1; 
    }     
  }
  
  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.addeditSubZoneForm = new FormGroup({
      zoneName: new FormControl(""),
      subzoneName: new FormControl("", [Validators.required,Validators.maxLength(32),Validators.pattern(appsettings.Name_Pattern)]),      
    });
    this.getDeviceDetails();
    this.getSubZonedetailsById();
    
  }
  
  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  
  getSubZonedetailsById()
  {
    try
    {            
      const subZonedata: getSubZoneById = {        
        authKey: this.authkey,
        subZoneId: this.SubZoneId,       
      }
      this.technologyoverlayService.getSubZoneDetailsById(subZonedata).pipe(takeUntil(this._onDestroy))
          .subscribe((SubZonesinfo: any[]) => {  
          this.SubZonedetails = SubZonesinfo["apiReturn"];  
          if(this.SubZonedetails[0].Notifications == 0)
          {
            this.chkCampusvalue = true;
            this.chkCustomvalue = false;
          }
          else if(this.SubZonedetails[0].Notifications == 1)
          {
            this.chkCustomvalue = true;
            this.chkCampusvalue = false;
          }
        }); 
    }
    catch (err) 
    {
      throw new Error("managesub-zone.component.ts" +" - getSubZonedetails() - " +err.message);
    }
  }
  
  getSpaceDetailsById()
  {
    try
    {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const unitid: getUnit = {       
        authKey: this.authkey,
        floorId: this.selectedFloorId,
        isIndoor:1,
        isNeedIntercom:parseInt(voiceLicense),
        isSubZone : 0
      }
      this.apiService.getUnitDetails(unitid).pipe(takeUntil(this._onDestroy))
          .subscribe((Unitsinfo: any[]) => {  
          this.Spacedetails = Unitsinfo["apiReturn"];      
          const floorId=this.selectedFloorId;
          const arr_layer = [] as any[];
          this.mapinfo.getLayers().forEach(function (Floorlayer) {
            if (Floorlayer.get('FloorId') != undefined && Floorlayer.get('FloorId') == "Floor_" +floorId ) {
              arr_layer[arr_layer.length] = Floorlayer;
            }
          });
      
          
          Unitsinfo["apiReturn"].forEach(row => {      
            if(row.SubZoneId > 0 && row.SubZoneId == this.SubZoneId)
            {
              row['isChecked'] = true;
              this.select_UnitIds.push(row.UnitId)

              for (let indx = 0; indx < arr_layer.length; indx++) {
                const room_layer = arr_layer[indx].values_.name
                if (room_layer=='Room_' +row.UnitId) {
                    arr_layer[indx].setVisible(true);
                  }
             }

            }
             else
              row['isChecked'] = false;
          });
                                   
        }); 



    }
    catch (err) 
    {
      throw new Error("managesub-zone.component.ts" +" - getSpaceDetailsById() - " +err.message);
    }
  }
  
handleInput(event) 
{
    try
    {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      }
      else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        }
        else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.addeditSubZoneForm.controls).forEach(field => { // {1}
        const control = this.addeditSubZoneForm.get(field); // {2}
        const getFormTargetId = document.getElementById(event.target.id);
        if(getFormTargetId != null)
        {
          if (field == getFormTargetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); 
            }      // {3}
          }
        }
      });
    }
    catch(err) 
    {
      throw new Error('managesub-zone.component.ts' + ' - handleInput() - ' + err.message);
    }
}
  


getDeviceDetails() {

  try {
    const voiceLicense = localStorage.getItem("voice_license") ?? "";
    const devicedata:getDeviceDetailsReq = 
    {       
      authKey: this.authkey,
      campusId:parseInt(this.selectedCampusId),
      floorId: parseInt(this.selectedFloorId),
      status:  this.device_list_all,
      devicetype: 0,
      device: 0, 
      spaceId: 0,
      isIndoor:1,
      isNeedRoomonly:1,
      isNeedIntercom : parseInt(voiceLicense)
    };

    this.apiService.getDeviceDetails(devicedata).pipe(takeUntil(this._onDestroy))
      .subscribe((DeviceDetails: any[]) => {
        
        if (DeviceDetails != null && DeviceDetails != undefined) {
          if (DeviceDetails["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          
          DeviceDetails["apiReturn"].forEach(function (e) {
            if (e.DeviceName == DeviceTypeDesc.Emitter) {
              e['NewDeviceName'] = NewDeviceTypeDesc.Emitter;
            }
            else if (e.DeviceName == DeviceTypeDesc.HRex) {
              e['NewDeviceName'] = NewDeviceTypeDesc.HRex;
            }
            else if (e.DeviceName == DeviceTypeDesc.Rex) {
              e['NewDeviceName'] = NewDeviceTypeDesc.Rex;
            }
            else if (e.DeviceName == DeviceTypeDesc.LF) {
              if (e.SubType==DeviceType.DripTray)
               {
                 e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
               }
               else
               {
              e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
              }
            }
            else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
              e['NewDeviceName'] = NewDeviceTypeDesc.Pullcord;
            }
            else if (e.DeviceName == DeviceTypeDesc.DC  || e.DeviceName == DeviceTypeDesc.NewDC) {
              e['NewDeviceName'] = NewDeviceTypeDesc.DC;
            }
            else if (e.DeviceName == DeviceTypeDesc.Monitor) {
              e['NewDeviceName'] = NewDeviceTypeDesc.Monitor;
            }
            else if (e.DeviceName == DeviceTypeDesc.VW) {
              e['NewDeviceName'] = NewDeviceTypeDesc.VW;
            }
            else if (e.DeviceName == DeviceTypeDesc.Gateway) {
              e['NewDeviceName'] = NewDeviceTypeDesc.Gateway;
            }
            else if (e.DeviceName == DeviceTypeDesc.UT) {
              e['NewDeviceName'] = NewDeviceTypeDesc.UT;
            }
            else if (e.DeviceName == DeviceTypeDesc.UM) {
              e['NewDeviceName'] = NewDeviceTypeDesc.UM;
            }
            else if (e.DeviceName == NewDeviceTypeDesc.Room) {
              e['NewDeviceName'] = NewDeviceTypeDesc.Room;
            }
            else if (e.DeviceName == DeviceTypeDesc.Intercom) {
              e['NewDeviceName'] = DeviceTypeDesc.Intercom;
            }
            else {
              e['NewDeviceName'] = NewDeviceTypeDesc.Zone;
            }
          })
          
          const DeviceList_array = [] as any[];
          DeviceDetails["apiReturn"].forEach(function (data) {
            if (data.FilterDeviceType != NewDeviceTypeDesc.Room) {
              const newData = {};
              newData['DeviceId'] = data.DeviceId;
              newData['DeviceTypeId'] = data.DeviceTypeId;
              newData['NewDeviceName'] = data.NewDeviceName;
              newData['UnitLocation'] = data.UnitLocation;
              newData['DataId'] = data.DataId;
              newData['UnitId'] = data.UnitId;
              newData['Description'] = data.Description;
              if (data.DeviceId > 0 || data.DeviceName == DeviceTypeDesc.Gateway)
                newData['Status'] = 'Active';
              else if (data.DeviceId == 0)
                newData['Status'] = 'Inactive';
              DeviceList_array.push(newData);
            }
          })
       
          this.FloorInfo = DeviceDetails["apiReturn"];            
          this.DrawFloorMapCall();    
          this.getSpaceDetailsById();        
        }
      });
  }
  catch (err) {
    throw new Error('device.component.ts' + ' - getDeviceDetails() - ' + err.message);
  }
}


  DrawFloorMapCall() {
    try {      
      const selectedfloor = this.selectedFloorId;
      const ImageWidth = this.floorImageWidth;
      const ImageHeight = this.floorImageHeight;
      let CZoomLevel = 0;
      const CRotateLevel = 0;
      const TranslateX = 0;
      const TranslateY = 0;
      const FloorImageURL = this.selectedData.FloorPlanImage;

      const ImageUrl = appsettings.FLOOR_IMAGE_FOLDER + "/" + FloorImageURL;

      const BackgroundIMG = "";
      const Map_image_path = 'assets/images/';

      
      const mapId = "map";
      //Map Empty
      const element = this.document.getElementById(mapId);
      let IsZoomChanged = 0;
      
      if(element.innerHTML != "" && (this.Check_ZoomLevel || this.Check_ZoomLevel == 0))
      {
        this.CZoomLevel = this.Check_ZoomLevel;
        IsZoomChanged = 1;
      }
      else{
        this.Check_MapCenter = [];
      }
      element.innerHTML = '';

      const imgwh = map_widthandheight(ImageWidth, ImageHeight);
      const imgwidth = imgwh.ImageWidth;
      const imgheight = imgwh.ImageHeight;
      this.addfloorimageheight = imgwh.ImageHeight;

      // Polygons style
       const labelStyle = label_Polygon(0);
       const fillcolorStyle = fillcolor_style(fillcolorPolygon.Incomplete);
       const style = [fillcolorStyle, labelStyle];

      const devicelabelStyle = new Style({

        text: new Text({
          font: '10px Poppins, sans-serif',
          overflow: true,
          fill: new Fill({
            color: '#000'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 5
          }),
          offsetX: 0,
          offsetY: 25.5
        })
      });


      const deviceStyle = fillcolor_style(fillcolorPolygon.Completed);

      const fillStyle = [deviceStyle, devicelabelStyle];
      CZoomLevel=.2;
      //var map = mapBuildTemplate_creation(ImageUrl, imgwidth, imgheight, true, BackgroundIMG, CZoomLevel, CRotateLevel, element.offsetWidth, element.offsetHeight, TranslateX, TranslateY, mapId);
      const map = map_creation(ImageUrl, imgwidth, imgheight, true, BackgroundIMG, CZoomLevel, CRotateLevel, element.offsetWidth, element.offsetHeight, TranslateX, TranslateY, 'map', this.Check_MapCenter, IsZoomChanged);

      map.getControls().removeAt(0)
      let translateX = TranslateX;
      let translateY = TranslateY;

      translateX = translateX / 122;
      translateY = translateY / 127;

      translateX = translateX * imgwidth;
      translateY = translateY * imgheight;

      const _map = map;
   
      const MapCenter = _map.getView().getCenter();

      MapCenter[0] -= translateX;
      MapCenter[1] += translateY;

      _map.on('moveend', function (e) {
        getCheckInExtent(e, _map, imgwidth, imgheight, MapCenter);
      });

      //////////Floor Devices Start////////////

      const arrdevicemarker = [];
      const arrdevicemarkernoPoly = [];
      if (this.FloorInfo != null) {
        /// Geo Feature Collection ///
        const geoFloorjsonObject = get_geojsonObject_floor(enumRequestFrom.floorplan_page);
        geoFloorjsonObject.type = "FeatureCollection";

        // const arrgeoFloorjsonObject = geoFloorjsonObject.features; // empty Object
        geoFloorjsonObject.features.length = 0;

        /// marker array ///
        // const arrmarker = [];
        if (this.FloorInfo.length > 0) {
          const arrFloorInfo = this.FloorInfo;
          LoadTechnologyFloorSvg(map, arrFloorInfo, imgheight, imgwidth, arrdevicemarker, geoFloorjsonObject, arrdevicemarkernoPoly, devicelabelStyle, fillStyle, Map_image_path, selectedfloor,0);
          this.mapinfo=map;
        }
        else if (FloorImageURL == null) {
          const _map = document.getElementById("map");
          if (_map != null)
            _map.innerHTML = "<span style='margin-top: 15%; padding-left: 45%;display:block;font-size:15px;font-weight:bold;'> Map Not Found.</span>";
        }
      }
      //////////Floor Devices End////////////

    }
    catch (err) {
      throw new Error('managesub-zone.component.ts - DrawFloorMapCall() - ' + err.message);
    }

  }
  
  togglefloorVisibility(checkedid, event: MatCheckboxChange) {
    
    const floorId=this.selectedFloorId;
    const arr_layer = [] as any [];
    this.mapinfo.getLayers().forEach(function (Floorlayer) {
      if (Floorlayer.get('FloorId') != undefined && Floorlayer.get('FloorId') == "Floor_" + floorId) {
        arr_layer[arr_layer.length] = Floorlayer;
      }
    });


    if (event.checked) {
      const index = this.select_UnitIds.indexOf(checkedid);
      if (index == -1) {
        this.select_UnitIds.push(checkedid);
      }
    } else {
      const index = this.select_UnitIds.indexOf(checkedid);
      if (index >= 0) {
        this.select_UnitIds.splice(index, 1);
      }
    }    

    for (let indx = 0; indx < arr_layer.length; indx++) {
      const room_layer = arr_layer[indx].values_.name
      if (room_layer=='Room_' +checkedid) {
        if (event.checked == false)
        {
          arr_layer[indx].setVisible(false);
        }
        else
        {
          arr_layer[indx].setVisible(true);
        }
      }
    }
           
  }
    
  public saveSubZoneInfo = (subzoneFormValue) => {
    try 
    {
      let notificationvalue = 0;   
      if(this.addeditSubZoneForm.valid)
      {
        const saveclick = this.document.getElementById('btnzonetempsaveclick');
        saveclick.className = 'clssubzonesavebutton mat-raised-button disablebtn';  
        
        if(this.chkCampusvalue == false && this.chkCustomvalue == false)
        {
          generateAlertMessage(this.dialog, "Please Select Notifications");
          saveclick.className = 'clssubzonesavebutton mat-raised-button';                    
          return;
        }  
        
        if (this.select_UnitIds.length==0)
        {
          generateAlertMessage(this.dialog, 'Must add at least one Space to create as a new Sub-Zone'); 
          saveclick.className = 'clssubzonesavebutton mat-raised-button';  
          return ;
        }
            
        if(this.chkCampusvalue == true)  
            notificationvalue = 0;
        else
            notificationvalue = 1;
            
        let MessageDetail;                  
        
        const message = 'By selecting "Custom", you must now configure Subzones for this Zone. Truview will now build out the Subzone Notifications for each alert type.'                                                  
        
          if(notificationvalue == 1) 
          {
            MessageDetail = { Message: message, Type: 0, CustomType: 1 }
    
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              disableClose: true,
              width: '650px',
              height: '250px',
              panelClass: 'myapp-no-padding-dialog',
              autoFocus: false,
              data: MessageDetail
            });
            dialogRef.afterClosed().subscribe(result => {
                
              if (result == 'Confirm') {                                          
                this.updateSubZone(subzoneFormValue,notificationvalue);
              }
              else {                                      
                saveclick.className = 'clssubzonesavebutton mat-raised-button';  
                return;
              }
            })  
        }  
        else
        {
          this.updateSubZone(subzoneFormValue,notificationvalue);
        }
        
      }
      
    }
    catch (err) {
      throw new Error("managesub-zone.component.ts" +" - saveSubZoneInfo() - " +err.message);
    }
  }
  
  updateSubZone(subzoneFormValue,notificationvalue)
  {
    const saveclick = document.getElementById('btnzonetempsaveclick');
    
    const subzonedetails: AddEditSubZone = {          
      authKey: this.authkey,
      campusId: this.selectedData.CampusId,
      buildId: this.selectedData.BuildId,
      zoneId: this.selectedFloorId,
      subZoneId: this.SubZoneId,
      subZoneName:subzoneFormValue.subzoneName.replace(/\s+/g, ' ').trim(),
      unitIds : this.select_UnitIds.toString(),
      notifications:parseInt(notificationvalue)                 
    }
    
    this.technologyoverlayService.addSubZoneDetails(subzonedetails).pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {  
        if(apiresponse["apiReturn"] == "Success" && this.SubZoneId == 0)               
        {
          generateAlertMessage(this.dialog, 'Sub-Zone added successfully.');
          this.thisDialogRef.close(this.selectedFloorId);
        }
        if(apiresponse["apiReturn"] == "Success" && this.SubZoneId > 0)               
        {
          generateAlertMessage(this.dialog, 'Sub-Zone edited successfully.');
          this.thisDialogRef.close(this.selectedFloorId);
        }
        else if(apiresponse["apiReturn"] == "Fail")
        { 
          generateAlertMessage(this.dialog, 'Sub-Zone added failed.');	
          if(saveclick != null) 
            saveclick.className = 'clseditunitbutton mat-raised-button';                 
          this.thisDialogRef.close();
        }              
        else if(apiresponse["apiReturn"] == "Invalid Auth Key")
        {    
            this.thisDialogRef.close('close');              
            AuthFailedMessage(this.dialog,this.router);  
            return;       
        }
        else if(apiresponse["condition"] == "subZoneName")
        {                
          generateAlertMessage(this.dialog, 'Please enter Space Name');
          if(saveclick != null)                   
            saveclick.className = 'clssubzonesavebutton mat-raised-button'; 
          return; 
        }
        else
        { 
          generateAlertMessage(this.dialog, "Sub-Zone name '" + apiresponse["SubZoneList"][0].SubZoneDesc + "' already exists.");
          if(saveclick != null) 
            saveclick.className = 'clssubzonesavebutton mat-raised-button';
        }
    });
  }
  
  openSubZoneDeletedialog() {     
    try
    {   
      if(!SessionCheck(this.router))
      {
        this.thisDialogRef.close('Cancel');
          return;
      }        
      this.deleteSafeZonedetails(this.SubZoneId)
    }
    catch (err) {
          throw new Error('managesub-zone.component.ts' + ' - openSubZoneDeletedialog() - ' + err.message);
      }           
  }
  
  deleteSafeZonedetails(deleteSubZoneId)
  {
    try
    {    
      console.log("DeviceInfo : " + this.Spacedetails.length)      
      
      // let Space_Count="";
      // let message="";      
          
      // if(this.Spacedetails.length > 0)   
      // {
      //     message = "It contains ";
      //     Space_Count = this.select_UnitIds.length + " Spaces.";
      // }
      
      const GeneralMessageData = {
        General_header:  "Delete Space ",                
        GeneralMessage:  "Are you sure you would like to delete this Sub-Zone?",//<br> " + message + " <b>" + Space_Count + " </b>
        General_first:  "Yes ",
        General_second:  "No"
      }  
      
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: 'auto',
        height: '220px',
        panelClass: 'myapp-no-padding-dialog',
        autoFocus: false,
        data: GeneralMessageData
      });     
      
      dialogRef.afterClosed().subscribe(result => {     
        if(result == "First")
        {                     
            this.DeleteSpacebyId(deleteSubZoneId)         
        }
        else
        {                
          dialogRef.close('Cancel');          
          return;
        }
      });            
    }
    catch(err)
    {
        throw new Error('delete-resident-group.ts' + ' - deleteUnit() - ' + err.message);
    } 
  }
  
  DeleteSpacebyId(subzoneid)
  {
    const deleteunitById :DeleteSubZone = {      
      authKey: this.authkey,
      subZoneId: parseInt(subzoneid)
    }
    this.technologyoverlayService.deleteSubZone(deleteunitById).pipe(takeUntil(this._onDestroy))
    .subscribe((apiresponse : any[])=>{           
        if(apiresponse["apiReturn"] == "Success")
        { 	
          generateAlertMessage(this.dialog, 'Sub-Zone deleted successfully.');                          
        } 
        else if(apiresponse["apiReturn"] == "Fail")
        {
          generateAlertMessage(this.dialog, 'Sub-Zone deleted failed ');                    
        }
        else if(apiresponse["apiReturn"] == "Invalid Auth Key")
        {    
            this.thisDialogRef.close('close');              
            AuthFailedMessage(this.dialog,this.router);  
            return;       
        }
        else
        {
          generateAlertMessage(this.dialog, apiresponse["apiReturn"]);                       
        } 
          this.thisDialogRef.close(subzoneid);          
      });
  }
  
  toggleCusVisibility(event,notifyType)
  {
    this.BuildNotifydefaultValue = 0;
    if(notifyType == 1)
    {
      if(event.checked == true)  
      {
        this.chkCustomvalue = false;
        this.chkCampusvalue = true;
      }
      else  
      {
        this.chkCampusvalue = false;
        this.chkCustomvalue = false;
      }
    }
    else if(notifyType == 2)
    {
      if(event.checked == true)  
      {
        this.chkCustomvalue = true;
        this.chkCampusvalue = false;
      }
      else  
      {
        this.chkCampusvalue = false;
        this.chkCustomvalue = false;
      }
    }
  }
  
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
