import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { report_leftmenu } from "../../../app-settings";
import { SessionCheckwithStaff } from "../../../app-general";
import { DownloadInfrastructureReportComponent } from "../download-infrastructure-report/download-infrastructure-report.component";
import { ReportService } from "../report.service";
import { getInfraAlert } from "../report";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { ReportHeaderComponent } from "../report-header/report-header.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-infrastructurereport",
  templateUrl: "./infrastructurereport.component.html",
  styleUrls: ["./infrastructurereport.component.scss"],
})
export class InfrastructurereportComponent implements AfterViewInit {
  ReportType = report_leftmenu.infrastructureReport;
  private _onDestroy: Subject<void> = new Subject<void>();
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private reportService: ReportService,    
    public dialog: MatDialog,
    private router: Router,
    public mainLayoutService: MainLayoutService,
    private breakpointObserver: BreakpointObserver
  ) {    
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.setupFullTable();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupTableformidwidth();
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }

  displayedInfraReportColumns: string[] = [
    "DeviceType",
    "DeviceId",
    "LForIrid",
    "MacId",
    "DualUTId",
    "CampusName",
    "BuildingName",
    "FloorName",
    "RoomName",
  ];

  InfrastructureReport;
  InfradataSource;
  downloadinfradata;
  infraloading = true;
  infrareportedData = [];
  infradatasourceCount;
  authkey;
  @ViewChild("searchInfraInput") searchInfraInput: ElementRef;
  @ViewChild("Infrasort") Infrasort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  // Infrastructure Report//
  setupTableforXtrasmallerwidth() {
    this.displayedInfraReportColumns = ["DeviceType", "DeviceId", "RoomName"];
  }

  setupTableforsmallerwidth() {
    this.displayedInfraReportColumns = [
      "DeviceType",
      "DeviceId",
      "LForIrid",
      "RoomName",
    ];
  }
  setupTableformidwidth() {
    this.displayedInfraReportColumns = [
      "DeviceType",
      "DeviceId",
      "LForIrid",
      "MacId",
      "DualUTId",
      "CampusName",
      "RoomName",
    ];
  }
  setupFullTable() {
    this.displayedInfraReportColumns = [
      "DeviceType",
      "DeviceId",
      "LForIrid",
      "MacId",
      "DualUTId",
      "CampusName",
      "BuildingName",
      "FloorName",
      "RoomName",
    ];
  }

  ngAfterViewInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.getInfrastructureReport();
  }

  getInfrastructureReport(): void {
    try {
      this.infraloading = true;
      this.searchInfraInput.nativeElement.value = "";
    const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const infraitem: getInfraAlert = {
        authKey: this.authkey,
      isNeedIntercom: parseInt(voiceLicense)
      };
      this.reportService
        .getInfrastructureReport(infraitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((InfrastructureReport: any[]) => {
          this.infraloading = false;
          this.InfrastructureReport = InfrastructureReport;
          this.InfradataSource = new MatTableDataSource(
            this.InfrastructureReport
          );
          this.downloadinfradata = new MatTableDataSource(
            this.InfrastructureReport
          );
          this.InfradataSource.data = this.InfrastructureReport;
          this.downloadinfradata.data = this.InfrastructureReport;
          setTimeout(() => {
            this.infrareportedData = [];
            this.InfradataSource.sort = this.Infrasort;
            this.sortingDatalist_InfraReport(this.InfradataSource);
            this.downloadinfradata.sort = this.Infrasort;
            this.sortingDatalist_InfraReport(this.downloadinfradata);
            this.downloadinfradata
              .connect()
              .subscribe((data) => (this.infrareportedData = data));
          }, 0);
          this.infradatasourceCount = this.InfradataSource.data.length;
          this.InfradataSource.paginator = this.paginator.toArray()[0];
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getInfrastructureReport() - " + err.message
      );
    }
  }

  searchInfrastructureReport(filterValue: string) {
    try {
      if (filterValue != null) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.InfradataSource.filter = filterValue;
        this.searchinfrafilter();
        this.infrareportedData = this.InfradataSource.filteredData;
        this.infradatasourceCount = this.infrareportedData.length;
      }
    } catch (err) {
      throw new Error(
        "report.component.ts" +
          " - searchInfrastructureReport() - " +
          err.message
      );
    }
  }

  searchinfrafilter() {
    let search_MacId = "";
    let search_dualutId = "";
    let search_LForIrid = "";

    this.InfradataSource.filterPredicate = function (
      data,
      filter: string
    ): boolean {
      if (data.MacId != null) {
        search_MacId = data.MacId.toLowerCase().includes(filter.toLowerCase());
      } else search_MacId = "";

      if (data.DualUTId != null) {
        search_dualutId = data.DualUTId.toString().includes(filter);
      } else search_dualutId = "";

      if (data.LForIrid != "" || data.LForIrid != null) {
        search_LForIrid = data.LForIrid.toString().includes(filter);
      } else search_LForIrid = "";

      return (
        data.RoomFullName.toLowerCase().includes(filter) ||
        data.DeviceType.toLowerCase().includes(filter) ||
        data.DeviceId.toString().includes(filter) ||
        data.CampusFullName.toLowerCase().includes(filter) ||
        data.BuildingFullName.toLowerCase().includes(filter) ||
        data.FloorFullName.toLowerCase().includes(filter) ||
        search_LForIrid ||
        search_MacId ||
        search_dualutId
      );
    };
  }

  infrastructureReportdata() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    if (this.InfradataSource.filter != "") {
      this.downloadinfradata.data = this.InfradataSource.filteredData;
      setTimeout(() => {
        this.InfradataSource.sort = this.Infrasort;
        this.sortingDatalist_InfraReport(this.InfradataSource);
        this.InfradataSource.connect().subscribe(
          (data) => (this.infrareportedData = data)
        );
      }, 0);
    }
    const dialogRef = this.dialog.open(DownloadInfrastructureReportComponent, {
      disableClose: true,
      width: "420px",
      height: "200px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: this.infrareportedData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  sortingDatalist_InfraReport(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (
        sortHeaderId == "DeviceId" ||
        sortHeaderId == "LForIrid" ||
        sortHeaderId == "DualUTId" ||
        sortHeaderId == "MacId"
      ) {
        return data[sortHeaderId];
      } else {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      }
    };
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
    if (ReportHeaderComponent.isReportMenuClosed == false) {
      this.reportService.toggleReportMenuNav.emit();
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
