<perfect-scrollbar style="max-width: 980px; max-height: 650px;" >
  <form [formGroup]="UploadConfigurationForm" autocomplete="off" novalidate >
  <div class="clsuploadDevice">
      <div class="upload-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="uploadHeader" style="margin: 0 0 10px 11px;"> Upload Configuration
        <span><img class="clsuploadcloseimage" src="assets/images/Close_1.png" (click)="onCloseClicked()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>
      <div class="clsuploadcontent">
         <mat-dialog-actions class="clsuploadcontrol"> 
          <div style="padding-bottom:10px;"><span>Select a file to upload</span></div>
          <!-- <div id="fakeDiv" style= "display:flex;">
            <input type="file" id="selectedFile" onclick="this.value = null" (change)="onFileChange($event)" multiple="false" style="padding-left: 25px;" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
            <button class="clsuploadbutton" style="margin-left:auto;" mat-raised-button (click)="onUploadClicked()">Upload</button>&nbsp; 
          </div> -->
          <div class="text-inside" style= "display:flex;">
            <mat-form-field appearance="outline" floatLabel="auto" style="width:250px;">
            <input matInput placeholder="Select File" formControlName="uploadConfig" [(ngModel)]="uploadedFileName" readonly/>                                              
            </mat-form-field>                                               
            <button mat-raised-button color="primary" (click)="onUploadClicked($event)" id="btnConfigUpload" class="clsuploadbutton">{{btnvalue}}</button>
            <input type="file" class="clschoosebutton text-inside-grid" placeholder="Select File" id="clkfileupload" #clkimageInput (change)="onFileChange($event)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />                           
          </div>
         </mat-dialog-actions>
      </div>
     
  </div>
  <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%" *ngIf="uploadloading">       
    <div class="loadspinner" *ngIf="uploadloading">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
  </div>  
</form>   
</perfect-scrollbar> 
