<perfect-scrollbar style="max-width: 1200px; max-height: 530px;" >
  <div class="clsaddunitdup">
      <div class="addunitdup-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addCampusHeader" style="margin: 0 0 10px 11px;">Duplicate Space
        <span><img class="clsunitdupcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="unitDuplicateForm" autocomplete="off" novalidate [style.height]="minheight" (ngSubmit)="duplicateunitDetails(unitDuplicateForm.value)">
      <div class="content" fxLayout="row" fxLayout.md="column" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div fxFlex="55" fxFlex.md="25">   
                
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                    
                    <mat-grid-tile class="clsunitdupmatgrid">
                      <label>Selected Space</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunitdupmatgridtxt">
                          <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdupaddtxt">
                             <input matInput placeholder="Zone Name" formControlName="unitName" maxlength="32" [(ngModel)]="data.UnitName" (keyup)="handleInput($event)" required/>                            
                             <input type="hidden" formControlName="campusID" [(ngModel)]="data.CampusId">   
                             <input type="hidden" formControlName="buildID" [(ngModel)]="data.BuildId">
                             <input type="hidden" formControlName="floorID" [(ngModel)]="data.FloorId">
                             <input type="hidden" formControlName="UnitID" [(ngModel)]="data.UnitId">                             
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>                       

                    <mat-grid-tile class="clsunitdupmatgrid">
                      <label>New Space Name</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunitdupmatgridtxt">
                        <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdupaddtxt">
                            <input matInput placeholder="Space Name" maxlength="30" formControlName="newunitname" (keyup)="handleInput($event)">
                            <mat-error *ngIf="unitDuplicateForm.controls.newunitname.hasError('required')">Please enter New Space Name</mat-error>
                            <mat-error *ngIf="unitDuplicateForm.controls.newunitname.hasError('pattern')">Please enter valid New Space Name</mat-error>
                            <mat-error *ngIf="unitDuplicateForm.controls.newunitname.hasError('maxlength')">Max length 30 characters only</mat-error>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>   
                  
                  <mat-grid-tile class="clsunitdupmatgrid">
                    <label>New Space Description</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsunitdupmatgridtxt">
                      <div class="text-inside">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdupaddtxt">
                           <input matInput placeholder="Description" maxlength="30" formControlName="description" (keyup)="handleInput($event)">
                           <mat-error *ngIf="unitDuplicateForm.controls.description.hasError('required')">Please enter Description</mat-error>
                           <mat-error *ngIf="unitDuplicateForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                           <mat-error *ngIf="unitDuplicateForm.controls.description.hasError('maxlength')">Max length 30 characters only</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>    
                
                <mat-grid-tile class="clsunitdupmatgrid">
                  <label>Space Category</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsunitdupmatgridtxt">
                    <div class="text-inside">
                        <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdupaddtxt">
                          <mat-select formControlName="roomcategory" placeholder="Select Space Category" [(ngModel)]="selectCategoryId">
                            <mat-option *ngFor="let Space of Spacecategory_details" [value]="Space.SpaceCategoryId" (click)="selectSpaceCategory(Space.SpaceCategoryId)">
                              {{Space.Description}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="unitDuplicateForm.controls.roomcategory.hasError('required')">Please select Space Category</mat-error>                                    
                        </mat-form-field>
                    </div>               
                </mat-grid-tile> 
                
                <mat-grid-tile>
                  <label>Resident Off<br>Limits</label>
              </mat-grid-tile>
              <mat-grid-tile class="clsunitdupmatgridtxt">
                      <div class="text-inside" style="width:100%;">
                        <mat-slide-toggle id="chkResOffLimits" (change)="toggler_ResOffLimits($event)" [checked]="checkResOffLimits"></mat-slide-toggle>
                    </div>
              </mat-grid-tile> 

                   </mat-grid-list>                              
              </div>
         
              <div fxFlex="55" fxFlex.md="45" class="clsdivunitduplicate">
                <label>Select the devices you'd like to duplicate in this Space:</label>
                  <mat-grid-list [cols]="columns" class="clsunitdupmatgridlist">
                    
                        <perfect-scrollbar style="max-width: 1200px; max-height: 230px;" >
                          <div class="text-inside" style="margin-top:1em;" *ngIf="DevicesInfo != undefined && DevicesInfo.length > 0">                                                                            
                            <mat-checkbox aria-label="Select All" 
                            class="clscheckboxunit clsenable" id="chkresident" 
                            [checked]="isCheckedAll"                                           
                            (change)="$event ? toggleAll($event) : null" style="font-size: 14px;">Select All</mat-checkbox>
                          </div> 
                          <div style="margin:1em;">
                            <label style="font-size: medium;">Devices</label>
                          </div>  
                          <div *ngFor="let Device of DevicesInfo;">
                          <div class="text-inside" style="margin-top:1em;" *ngIf="Device.ResRoomCategory.length < 15">                                                                            
                            <mat-checkbox class="clscheckboxunit clsenable" [disabled]="Device.FilterDeviceType == 'Zone'"
                            [checked]="exists(Device.DataId)" [id]="'chkunit_'+Device.DataId" [ngStyle]="{'opacity': Device.FilterDeviceType == 'Zone' ? '0.5' : 'none'}"
                            (change)="$event ? toggledeviceVisibility(Device.DataId,$event) : null" style="font-size: 14px;margin-right: 5px;"></mat-checkbox>
                            {{Device.FilterDeviceType}} - {{Device.ResRoomCategory}}
                          </div> 
                          
                          <div class="text-inside" style="margin-top:1em;" *ngIf="Device.ResRoomCategory.length >= 15">                                                                            
                            <mat-checkbox class="clscheckboxunit clsenable" [checked]="exists(Device.DataId)" [id]="'chkunit_'+Device.DataId"
                            (change)="$event ? toggleUnitVisibility(Device.DataId,$event) : null" style="font-size: 14px;margin-right: 5px;"
                            [ngStyle]="{'opacity': Device.FilterDeviceType == 'Zone' ? '0.5' : 'none'}" [disabled]="Device.FilterDeviceType == 'Zone'"                            
                            matTooltipClass="devicetooltip" matTooltip={{Device.ResRoomCategory}} #tooltip="matTooltip" matTooltipPosition="above">
                            {{Device.FilterDeviceType}} - {{Device.ResRoomCategory.slice(0,15) + ".."}} </mat-checkbox>
                          </div>  
                          
                          </div>                   
                    
                    </perfect-scrollbar>
                    </mat-grid-list>
              </div>
          
        
 </div>
 <mat-dialog-actions class="clsunitdupbuttoncontrol">      
  <button type="button" class="clsunitdupcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnspaceduplicatesaveclick" class="clsunitdupsavebutton" mat-raised-button >Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>