import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { ReportService } from "../report.service";
import { AlertFilterValue, AlertFilterinfo } from "../report";
import { DatePipe } from "@angular/common";
import {
  report_leftmenu,
  enumAlertType,
  alert_deviceType,
  NewDeviceTypeDesc,
  enumAlertSubType,
  enumAlertStatus,
  DeviceTypeDesc,
} from "../../../app-settings";
import "jspdf-autotable";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

@Component({
  selector: "app-export-report",
  templateUrl: "./export-report.component.html",
  providers: [ReportService, DatePipe],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./export-report.component.scss"],
})
export class ExportReportComponent implements OnInit, OnDestroy {
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  Typetext: "Today";
  todaydate;
  Todaydate;
  monthFromDate;
  yearFromDate;
  fromdate;
  todate;
  typetext;
  date;
  Reportdetails;
  arrData;
  row;
  CSV;
  index;
  item;
  ActionBy;
  RoomName;
  AckedTime;
  Filterdate: AlertFilterValue[];
  Acknowledge_By;
  Description;
  Name;
  private _onDestroy: Subject<void> = new Subject<void>();
  download_Data;
  authkey;
  dateformat;
  public isExcelDownload = false;
  public isPDFDownload = false;

  constructor(
    private router: Router,
    public thisDialogRef: MatDialogRef<ExportReportComponent>,
    private reportService: ReportService,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.download_Data = data.ExportData;
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.dateformat = localStorage.getItem("dateFormat") ?? "";

    this.todaydate = new Date();
    this.Todaydate = this.datePipe.transform(this.todaydate, "yyyy-MM-dd");

    const loaderelement = document.getElementsByClassName("loadexportspinner");
    loaderelement[0]["style"].display = "none";
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  exportReportExcel(exportData) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      let fileName = "AlertReport";
      this.typetext = "Alert Report";
      if (!this.isExcelDownload) {
        this.isExcelDownload = true;
        if (exportData.EventName === undefined) {
          this.todaydate = new Date();
          this.Todaydate = this.datePipe.transform(
            this.todaydate,
            "yyyy-MM-dd"
          );
          this.typetext = "today";
          const Filterdate: AlertFilterinfo = {
            authKey: this.authkey,
            fromDate: this.Todaydate,
            toDate: this.Todaydate,
            dateFormat: this.dateformat,
            alertType: exportData.AlertType,
            location: exportData.Location,
            patientId: exportData.PatientId,
            reportType: exportData.ReportType,
          };
          const loaderelement =
            document.getElementsByClassName("loadexportspinner");
          loaderelement[0]["style"].display = "block";
          setTimeout(() => {
            this.exporttoExcel(
              Filterdate,
              this.typetext,
              this.Todaydate,
              fileName
            );
          }, 3);
        } else if (
          exportData.EventName === report_leftmenu.campusdashboard ||
          exportData.EventName === report_leftmenu.residentassessment
        ) {
          if (exportData.EventName === report_leftmenu.campusdashboard) {
            fileName = "CampusDashboard";
            this.typetext = "Campus Dashboard";
          } else if (
            exportData.EventName === report_leftmenu.residentassessment
          ) {
            fileName = "ResidentAssessment";
            this.typetext = "Resident Assessment";
          }
          this.todaydate = new Date();
          this.Todaydate = this.datePipe.transform(
            this.todaydate,
            "yyyy-MM-dd"
          );
          let selectdate = "";
          selectdate = exportData.SelectDate.split("-");
          this.fromdate = this.datePipe.transform(selectdate[0], "yyyy-MM-dd");
          this.todate = this.datePipe.transform(selectdate[1], "yyyy-MM-dd");

          const Filterdate: AlertFilterinfo = {
            authKey: this.authkey,
            fromDate: this.fromdate,
            toDate: this.todate,
            dateFormat: this.dateformat,
            alertType: exportData.AlertType,
            location: exportData.Location,
            patientId: exportData.PatientId,
            reportType: exportData.ReportType,
          };
          const loaderelement =
            document.getElementsByClassName("loadexportspinner");
          loaderelement[0]["style"].display = "block";
          setTimeout(() => {
            this.exporttoExcel(
              Filterdate,
              this.typetext,
              this.Todaydate,
              fileName
            );
          }, 3);
        }
      }
    } catch (err) {
      throw new Error(
        "export-report.component.ts" + " - exportReportExcel() - " + err.message
      );
    }
  }

  exportReportPdf(exportData) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      let fileName = "AlertReport";
      this.typetext = "Alert Report";
      if (!this.isPDFDownload) {
        this.isPDFDownload = true;
        if (exportData.EventName === undefined) {
          this.todaydate = new Date();
          this.Todaydate = this.datePipe.transform(
            this.todaydate,
            "yyyy-MM-dd"
          );
          this.typetext = "today";
          const Filterdate: AlertFilterinfo = {
            authKey: this.authkey,
            fromDate: this.Todaydate,
            toDate: this.Todaydate,
            dateFormat: this.dateformat,
            alertType: exportData.AlertType,
            location: exportData.Location,
            patientId: exportData.PatientId,
            reportType: exportData.ReportType,
          };
          const loaderelement =
            document.getElementsByClassName("loadexportspinner");
          loaderelement[0]["style"].display = "block";
          setTimeout(() => {
            this.exporttoPdf(
              Filterdate,
              this.typetext,
              this.Todaydate,
              fileName
            );
          }, 3);
        } else if (
          exportData.EventName === report_leftmenu.campusdashboard ||
          exportData.EventName === report_leftmenu.residentassessment
        ) {
          if (exportData.EventName === report_leftmenu.campusdashboard) {
            fileName = "CampusDashboard";
            this.typetext = "Campus Dashboard";
          } else if (
            exportData.EventName === report_leftmenu.residentassessment
          ) {
            fileName = "ResidentAssessment";
            this.typetext = "Resident Assessment";
          }
          this.todaydate = new Date();
          this.Todaydate = this.datePipe.transform(
            this.todaydate,
            "yyyy-MM-dd"
          );
          let selectdate = "";
          selectdate = exportData.SelectDate.split("-");
          this.fromdate = this.datePipe.transform(selectdate[0], "yyyy-MM-dd");
          this.todate = this.datePipe.transform(selectdate[1], "yyyy-MM-dd");

          const Filterdate: AlertFilterinfo = {
            authKey: this.authkey,
            fromDate: this.fromdate,
            toDate: this.todate,
            dateFormat: this.dateformat,
            alertType: exportData.AlertType,
            location: exportData.Location,
            patientId: exportData.PatientId,
            reportType: exportData.ReportType,
          };
          const loaderelement =
            document.getElementsByClassName("loadexportspinner");
          loaderelement[0]["style"].display = "block";
          setTimeout(() => {
            this.exporttoPdf(
              Filterdate,
              this.typetext,
              this.Todaydate,
              fileName
            );
          }, 3);
        }
      }
    } catch (err) {
      throw new Error(
        "export-report.component.ts" + " - exportReportPdf() - " + err.message
      );
    }
  }

  exporttoExcel(data, typetext, Todaydate, fileName) {
    try {
      //let RoomId = 0;
      let ResponseTime = "";
      let PresenceOn = "";
      this.Reportdetails = this.download_Data;
      const downloadResident = {
        Data: [] as any[],
      };

      for (let i = 0; i < this.Reportdetails.length; i++) {
        this.item = this.Reportdetails[i];
        if (this.item.RoomName) {
          this.RoomName = this.item.RoomName;
        } else {
          this.RoomName = " ";
        }

        //if (this.item.RoomId) RoomId = this.item.RoomId;
        if (this.item.AckedTime) {
          this.AckedTime = this.item.AckedTime;
        } else {
          this.AckedTime = " ";
        }

        if (this.Reportdetails[i].AckedMethod == null) {
          this.ActionBy = "";
        } else {
          this.ActionBy = this.Reportdetails[i].AckedMethod;
        }

        if (this.item.AlertTime)
          this.item.AlertTime = ('"' + this.item.AlertTime + '"').replace(
            /"/g,
            " "
          );
        else this.item.AlertTime = "";

        if (this.item.NotifiedTime)
          this.item.NotifiedTime = ('"' + this.item.NotifiedTime + '"').replace(
            /"/g,
            " "
          );
        else this.item.NotifiedTime = "";

        if (this.item.AckedTime) {
          if (
            this.item.AckStatus == enumAlertStatus.Canceled ||
            this.item.AckStatus == enumAlertStatus.Complete
          )
            this.item.AckedTime = ('"' + this.item.AckedTime + '"').replace(
              /"/g,
              " "
            );
          else this.item.AckedTime = "";
        } else this.item.AckedTime = "";

        if (this.item.ResponseTime != null) {
          if (
            this.item.AckStatus == enumAlertStatus.Canceled ||
            this.item.AckStatus == enumAlertStatus.Complete
          )
            ResponseTime = this.item.ResponseTime;
          else ResponseTime = "";
        } else ResponseTime = "";

        if (this.Reportdetails[i].Alert == DeviceTypeDesc.Gateway) {
          this.Description = this.Reportdetails[i].Alert;
        } else if (
          this.Reportdetails[i].AlarmSubTypeName == null ||
          this.Reportdetails[i].AlarmSubTypeName == ""
        ) {
          this.Description = this.Reportdetails[i].AlarmName;
        } else {
          if (this.Reportdetails[i].Alert == "Unauthorized Egress")
            this.Description = this.Reportdetails[i].AlarmName + " (Wandering)";
          else {
            this.Description =
              this.Reportdetails[i].AlarmName +
              " (" +
              this.Reportdetails[i].Alert +
              ")";
          }
        }

        if (this.Reportdetails[i].HideInfoflag == 0) {
          if (this.Reportdetails[i].AlertType == enumAlertType.thirdparty) {
            // no change
            this.Name = this.Reportdetails[i].ResidentName;
          } else if (
            this.Reportdetails[i].AlertType == enumAlertType.UT ||
            this.Reportdetails[i].DeviceType == alert_deviceType.UT
          )
            this.Name = NewDeviceTypeDesc.UT;
          else if (
            this.Reportdetails[i].DeviceType == alert_deviceType.Tag ||
            this.Reportdetails[i].DeviceType ==
              alert_deviceType.Pager_Communication ||
            this.Reportdetails[i].DeviceType == alert_deviceType.Battery ||
            (this.Reportdetails[i].DeviceType == alert_deviceType.DC &&
              this.Reportdetails[i].AlertSubType ==
                enumAlertSubType.Tag_In_Field)
          )
            this.Name = this.Reportdetails[i].ResidentName;
          else if (this.Reportdetails[i].DeviceType == alert_deviceType.REX)
            this.Name = NewDeviceTypeDesc.Rex;
          else if (this.Reportdetails[i].DeviceType == alert_deviceType.HREX)
            this.Name = NewDeviceTypeDesc.HRex;
          else if (
            this.Reportdetails[i].DeviceType == alert_deviceType.DC &&
            this.Reportdetails[i].AlertSubType != enumAlertSubType.Tag_In_Field
          )
            this.Name = NewDeviceTypeDesc.DC;
          else if (this.Reportdetails[i].DeviceType == alert_deviceType.Emitter)
            this.Name = NewDeviceTypeDesc.Emitter;
          else if (
            this.Reportdetails[i].DeviceType == alert_deviceType.Pull_Cord
          )
            this.Name = NewDeviceTypeDesc.Pullcord;
          else this.Name = this.Reportdetails[i].ResidentName;
        } else this.Name = "-";

        if (this.Reportdetails[i].AckedMethod == "Self")
          this.Acknowledge_By = "Self";
        else if (
          this.Reportdetails[i].AckedFirstName == null &&
          this.Reportdetails[i].AckedLastName == null
        )
          this.Acknowledge_By = " ";
        else if (this.Reportdetails[i].AckedFirstName == null)
          this.Acknowledge_By = this.Reportdetails[i].AckedLastName;
        else if (this.Reportdetails[i].AckedLastName == null)
          this.Acknowledge_By = this.Reportdetails[i].AckedFirstName;
        else
          this.Acknowledge_By =
            this.Reportdetails[i].AckedFirstName +
            " " +
            this.Reportdetails[i].AckedLastName;

        if (this.Reportdetails[i].PresenceOn != null) {
          PresenceOn = ('"' + this.Reportdetails[i].PresenceOn + '"').replace(
            /"/g,
            " "
          );
        }

        downloadResident.Data.push({
          AlertId: this.item.AlertId,
          "Alert Description": this.Description,
          "Resident Name": this.Name,
          DeviceId: this.item.TagId,
          "Room Info": this.item.RoomInfo,
          "Alert Time": this.item.AlertTime,
          "Notified Time": this.item.NotifiedTime, //this.datePipe.transform(this.item.NotifiedTime,'yyyy-MM-dd'),
          "Notified Level": this.item.AlertLevel,
          "Acked Time": this.item.AckedTime,
          "Acked Method": this.ActionBy,
          Status: this.item.AckStatus,
          "Acked By": this.Acknowledge_By,
          "Presence On": PresenceOn,
          "Response Time": ResponseTime,
        });
      }
      this.JSONToCSVConvertor(
        downloadResident.Data,
        typetext,
        true,
        Todaydate,
        fileName
      );

      // });
    } catch (err) {
      throw new Error(
        "export-report.component.ts" + " - exporttoExcel() - " + err.message
      );
    }
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel, Todaydate, fileName) {
    try {
      // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      const arrData =
        typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

      let CSV = "";
      CSV += ReportTitle + "\r\n";
      if (ShowLabel) {
        let row = "";

        for (const index in arrData[0]) {
          row += index + ",";
        }
        row = row.slice(0, -1);

        CSV += row + "\r\n";
      }
      if (arrData.length > 0) {
        for (let i = 0; i < arrData.length; i++) {
          let row = "";
          for (const index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }

          row.slice(0, row.length - 1);

          // add a line break after each row
          CSV += row + "\r\n";
        }

        if (CSV === "") {
          const loaderelement =
            document.getElementsByClassName("loadexportspinner");
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Invalid data");
          this.isExcelDownload = false;
          return;
        }

        //fileName += ReportTitle.replace(/ /g, '_');
        const myBlob = new Blob([CSV], { type: "text/html" });
        const url = window.URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        const link = document.body.appendChild(a);
        document.body.appendChild(link);
        link.href = url;
        link.download = fileName + "-" + Todaydate + ".csv";
        link.click();
      } else {
        const loaderelement =
          document.getElementsByClassName("loadexportspinner");
        loaderelement[0]["style"].display = "none";
        generateAlertMessage(this.dialog, "No data available.");
        this.isExcelDownload = false;
      }
      const loaderelement =
        document.getElementsByClassName("loadexportspinner");
      loaderelement[0]["style"].display = "none";
      this.isExcelDownload = false;
    } catch (err) {
      throw new Error(
        "export-report.component.ts" +
          " - JSONToCSVConvertor() - " +
          err.message
      );
    }
  }

  exporttoPdf(data, typetext, Todaydate, fileName) {
    try {
      const loaderelement =
        document.getElementsByClassName("loadexportspinner");
      loaderelement[0]["style"].display = "block";

      this.Reportdetails = this.download_Data;
      const resData = [] as any[];
      const columns = [
        { title: "Alert\nDescription", dataKey: "Description" },
        { title: "Resident\nName", dataKey: "ResidentName" },
        { title: "Device\nId", dataKey: "TagId" },
        { title: "Room\nInfo.", dataKey: "RoomName" },
        { title: "Alert\nTime", dataKey: "AlertTime" },
        { title: "Notified\nTime", dataKey: "NotifiedTime" },
        { title: "Notified\nLevel", dataKey: "AlertLevel" },
        { title: "Acked\nTime", dataKey: "AckedTime" },
        { title: "Acked\nMethod", dataKey: "AckedMethod" },
        { title: "Status", dataKey: "AckStatus" },
        { title: "Acked\nBy", dataKey: "Acknowledge_By" },
        { title: "Presence\nOn", dataKey: "PresenceOn" },
        { title: "Response\nTime", dataKey: "ResponseTime" },
      ];

      if (this.Reportdetails.length > 0) {
        for (let i = 0; i < this.Reportdetails.length; i++) {
          let RoomName = "";
          //let RoomID = 0;
          let Description = "";
          let ResidentName;
          let Acknowledge_By = "";
          let AckedMethod = "";
          let AckedTime = "";
          let ResponseTime;
          let PresenceOn;

          const TagId = this.Reportdetails[i].TagId;
          const AlertLevel = this.Reportdetails[i].AlertLevel;
          const AlertTime = this.Reportdetails[i].AlertTime;
          const NotifiedTime = this.Reportdetails[i].NotifiedTime;
          const AckStatus = this.Reportdetails[i].AckStatus;

          if (this.Reportdetails[i].Alert == DeviceTypeDesc.Gateway) {
            Description = this.Reportdetails[i].Alert;
          } else if (
            this.Reportdetails[i].AlarmSubTypeName == null ||
            this.Reportdetails[i].AlarmSubTypeName == ""
          ) {
            Description = this.Reportdetails[i].AlarmName;
          } else {
            if (this.Reportdetails[i].Alert == "Unauthorized Egress")
              Description = this.Reportdetails[i].AlarmName + " (Wandering)";
            else {
              Description =
                this.Reportdetails[i].AlarmName +
                " (" +
                this.Reportdetails[i].Alert +
                ")";
            }
          }

          if (this.Reportdetails[i].HideInfoflag == 0) {
            if (this.Reportdetails[i].AlertType == enumAlertType.thirdparty) {
              // no change
              ResidentName = this.Reportdetails[i].ResidentName;
            } else if (
              this.Reportdetails[i].AlertType == enumAlertType.UT ||
              this.Reportdetails[i].DeviceType == alert_deviceType.UT
            )
              ResidentName = NewDeviceTypeDesc.UT;
            else if (
              this.Reportdetails[i].DeviceType == alert_deviceType.Tag ||
              this.Reportdetails[i].DeviceType ==
                alert_deviceType.Pager_Communication ||
              this.Reportdetails[i].DeviceType == alert_deviceType.Battery ||
              (this.Reportdetails[i].DeviceType == alert_deviceType.DC &&
                this.Reportdetails[i].AlertSubType ==
                  enumAlertSubType.Tag_In_Field)
            )
              ResidentName = this.Reportdetails[i].ResidentName;
            else if (this.Reportdetails[i].DeviceType == alert_deviceType.REX)
              ResidentName = NewDeviceTypeDesc.Rex;
            else if (this.Reportdetails[i].DeviceType == alert_deviceType.HREX)
              ResidentName = NewDeviceTypeDesc.HRex;
            else if (
              this.Reportdetails[i].DeviceType == alert_deviceType.DC &&
              this.Reportdetails[i].AlertSubType !=
                enumAlertSubType.Tag_In_Field
            )
              ResidentName = NewDeviceTypeDesc.DC;
            else if (
              this.Reportdetails[i].DeviceType == alert_deviceType.Emitter
            )
              ResidentName = NewDeviceTypeDesc.Emitter;
            else if (
              this.Reportdetails[i].DeviceType == alert_deviceType.Pull_Cord
            )
              ResidentName = NewDeviceTypeDesc.Pullcord;
            else ResidentName = this.Reportdetails[i].ResidentName;
          } else ResidentName = "-";

          if (this.Reportdetails[i].RoomInfo) {
            RoomName = this.Reportdetails[i].RoomInfo;
          }
          // if (this.Reportdetails[i].RoomId) {
          //   RoomID = this.Reportdetails[i].RoomId;
          // }

          if (this.Reportdetails[i].AckedTime) {
            AckedTime = this.Reportdetails[i].AckedTime;
          }

          if (this.Reportdetails[i].AckedTime) {
            if (
              this.Reportdetails[i].AckStatus == enumAlertStatus.Canceled ||
              this.Reportdetails[i].AckStatus == enumAlertStatus.Complete
            )
              AckedTime = this.Reportdetails[i].AckedTime;
            else AckedTime = "";
          } else AckedTime = "";

          if (this.Reportdetails[i].ResponseTime != null) {
            if (
              this.Reportdetails[i].AckStatus == enumAlertStatus.Canceled ||
              this.Reportdetails[i].AckStatus == enumAlertStatus.Complete
            )
              ResponseTime = this.Reportdetails[i].ResponseTime;
            else ResponseTime = "";
          } else ResponseTime = "";

          if (this.Reportdetails[i].AckedMethod == null) {
            AckedMethod = "";
          } else {
            AckedMethod = this.Reportdetails[i].AckedMethod;
          }

          if (this.Reportdetails[i].AckedMethod == "Self")
            Acknowledge_By = "Self";
          else if (
            this.Reportdetails[i].AckedFirstName == null &&
            this.Reportdetails[i].AckedLastName == null
          )
            Acknowledge_By = " ";
          else if (this.Reportdetails[i].AckedFirstName == null)
            Acknowledge_By = this.Reportdetails[i].AckedLastName;
          else if (this.Reportdetails[i].AckedLastName == null)
            Acknowledge_By = this.Reportdetails[i].AckedFirstName;
          else
            Acknowledge_By =
              this.Reportdetails[i].AckedFirstName +
              " " +
              this.Reportdetails[i].AckedLastName;

          if (this.Reportdetails[i].PresenceOn != null)
            PresenceOn = this.Reportdetails[i].PresenceOn;
          else PresenceOn = "";

          resData.push({
            Description,
            ResidentName,
            TagId,
            RoomName,
            AlertTime,
            NotifiedTime,
            AlertLevel,
            AckedTime,
            AckedMethod,
            AckStatus,
            Acknowledge_By,
            PresenceOn,
            ResponseTime,
          });
        }

        const pdf = new jsPDF("l"); // A4 size page of PDF
        let PdfFileName = fileName;
        const pageWidth =
          pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        pdf.text(typetext, pageWidth / 2, 10, "center");

        const options = {
          theme: "grid",
          bodyStyles: { lineColor: [0, 0, 0] },
          headStyles: {
            lineWidth: 0.2,
            lineColor: [0, 0, 0],
            textColor: [0, 0, 0],
            fillColor: [169, 169, 169],
            valign: "middle",
            halign: "center",
          },

          columnStyles: {
            ResidentName: { cellWidth: 32, minCellHeight: 34 },
            TagId: { cellWidth: 17, minCellHeight: 24 },
            RoomName: { cellWidth: 22, minCellHeight: 24 },
            AlertTime: { cellWidth: 20, minCellHeight: 24 },
            NotifiedTime: { cellWidth: 20, minCellHeight: 24 },
            AlertLevel: { cellWidth: 16, minCellHeight: 24 },
            AckedTime: { cellWidth: 20, minCellHeight: 24 },
            AckedMethod: { cellWidth: 20, minCellHeight: 24 },
            AckStatus: { cellWidth: 20, minCellHeight: 24 },
            Acknowledge_By: { cellWidth: 18, minCellHeight: 24 },
            PresenceOn: { cellWidth: 20, minCellHeight: 24 },
            ResponseTime: { cellWidth: 20, minCellHeight: 24 },
          },
        };

        pdf.autoTable(columns, resData, options);
        PdfFileName = PdfFileName + "-" + Todaydate + ".pdf";
        pdf.save(PdfFileName);
        const loaderelement =
          document.getElementsByClassName("loadexportspinner");
        loaderelement[0]["style"].display = "none";
        this.isPDFDownload = false;
      } else {
        const loaderelement =
          document.getElementsByClassName("loadexportspinner");
        loaderelement[0]["style"].display = "none";
        generateAlertMessage(this.dialog, "No data available.");
        this.isPDFDownload = false;
      }
      this.isPDFDownload = false;
      // });
    } catch (err) {
      throw new Error(
        "export-report.component.ts" + " - exporttoPdf() - " + err.message
      );
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
