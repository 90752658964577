import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { ReportService } from "../report.service";
import {
  getCustomizedReport,
  saveCustomizeReport,
  getLocationType,
} from "../report";
import {
  report_leftmenu,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  DeviceTypeDesc,
  NewDeviceTypeDesc,
  enumReportState,
  appsettings,
  DeviceType,
} from "../../../app-settings";
import {
  SessionCheckwithStaff,
  getFeatureNeedtoShow,
  AuthFailedMessage,
  generateAlertMessage,
} from "../../../app-general";
import { SharedEventData, getStaffData } from "../../../api";
import { AlertSharedService } from "../../../_services/index";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { ReportColorconfigComponent } from "../report-colorconfig/report-colorconfig.component";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { HttpClient } from "@angular/common/http";
import { ReportHeaderComponent } from "../report-header/report-header.component";
import { ApiService } from "../../../api.service";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

const URL = appsettings.URLinfo + "uploadReportFile";

@Component({
  selector: "app-customizelocators",
  templateUrl: "./customizelocators.component.html",
  styleUrls: ["./customizelocators.component.scss"],
})
export class CustomizelocatorsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  ReportType = report_leftmenu.customizeLocatorsReport;
  private _onDestroy: Subject<void> = new Subject<void>();
  public EnumReportState = enumReportState;
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  select = 0;
  flag = 0;
  SharedEventDetails: SharedEventData[];
  customizelocatorsloading = true;
  CustomizeLocatorsSource;
  CustomizeLocatorReport;
  LocationType;
  cusLocatorevent;
  cusLocselectitem;
  getCusEnableStateData = [] as any[];
  cusLocEnableSelectdata = [] as any[];
  cusLocDisableSelectdata = [] as any[];
  getCusAutoStateData = [] as any[];
  cusloc_selstatechange = [] as any[];
  cusloc_selectStateBy;
  selectedLocationType;
  ClickEnableState = 0;
  CusLocfilteredArray = [] as any[];
  chkCusLocsearchvalue;
  updateflag = 0;
  authkey;
  displayedCustomLocatorsColumns: string[] = [
    "EmitterID",
    "Tru_RoomName",
    "DeviceName",
    "stateChange",
    "roomcolor",
    "stateChangebyName",
  ];
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private reportService: ReportService,
    private router: Router,
    private alertSharedService: AlertSharedService,
    public dialog: MatDialog,
    public http: HttpClient,
    public mainLayoutService: MainLayoutService,    
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {    
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.setupcuslocFullTable();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupcuslocTableformidwidth();
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupcuslocTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupcuslocTableforXtrasmallerwidth();
    }
  }
  

  @ViewChild("CusLocsort") CusLocsort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild("cus_paginator") cus_paginator: MatPaginator;
  @ViewChild("clkfileInput") clkfileInput: any;
  @ViewChild("searchCusLocatorsInput") searchCusLocatorsInput: ElementRef;
  //CustomizeLocators Report///
  setupcuslocTableforXtrasmallerwidth() {
    this.displayedCustomLocatorsColumns = [
      "EmitterID",
      "Tru_RoomName",
      "stateChangebyName",
    ];
  }
  setupcuslocTableforsmallerwidth() {
    this.displayedCustomLocatorsColumns = [
      "EmitterID",
      "Tru_RoomName",
      "stateChange",
      "roomcolor",
    ];
  }
  setupcuslocTableformidwidth() {
    this.displayedCustomLocatorsColumns = [
      "EmitterID",
      "Tru_RoomName",
      "stateChange",
      "roomcolor",
    ];
  }
  setupcuslocFullTable() {
    this.displayedCustomLocatorsColumns = [
      "EmitterID",
      "Tru_RoomName",
      "DeviceName",
      "stateChange",
      "roomcolor",
      "stateChangebyName",
    ];
  }

  ngOnInit() {
    if (
      getFeatureNeedtoShow(enumUIFeatureView.Asset, enumUIAction.Asset_View) !=
      enumUIView.Show
    ) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }

    this.select = 0;
    this.flag = 0;

    const _SharedEventDetails = [
      {
        EventName: report_leftmenu.customizeLocatorsReport,
      },
    ];

    this.alertSharedService.changeSharedEvent(_SharedEventDetails);
    this.alertSharedService
      .currentSharedEvent()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((SharedEventDetails: SharedEventData[]) => {
        this.SharedEventDetails = SharedEventDetails;
      });
  }

  ngAfterViewInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.getLocationType();
    this.getStaffData();
  }

  getLocationType() {
    try {
      this.customizelocatorsloading = true;
      const locationtypeitem: getLocationType = {
        authKey: this.authkey,
      };
      this.reportService
        .getLocationType(locationtypeitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((LocationTypeReport: any[]) => {
          this.LocationType = LocationTypeReport;
        });
      if (this.searchCusLocatorsInput.nativeElement.value == "")
        this.getCustomizedLocatorsReport();
      else this.getfilterCusLocatorsdetails();
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - getLocationType() - " +
          err.message
      );
    }
  }

  getStaffData() {
    try {
      const staffdata: getStaffData = { authKey: this.authkey };
      this.apiService
        .getStaffData(staffdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Staffdetails: any[]) => {
          this.cusloc_selstatechange = Staffdetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" + " - getStaffData() - " + err.message
      );
    }
  }

  getCustomizedLocatorsReport() {
    try {
      this.customizelocatorsloading = true;
      this.searchCusLocatorsInput.nativeElement.value = "";
      this.selectedLocationType = "";
      this.cusloc_selectStateBy = "";
      const customitem: getCustomizedReport = {
        authKey: this.authkey,
      };
      this.reportService
        .getCustomizedLocatorsReport(customitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CustomizeLocatorReport: any[]) => {
          this.customizelocatorsloading = false;
          this.CustomizeLocatorReport = CustomizeLocatorReport;
          this.CustomizeLocatorsSource = new MatTableDataSource(
            CustomizeLocatorReport
          );
          CustomizeLocatorReport.forEach(function (e) {
            if (e.DeviceName == DeviceTypeDesc.Emitter) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
            } 
            else if (e.DeviceName == DeviceTypeDesc.LF) {
              e["NewDeviceName"] = NewDeviceTypeDesc.LFExiter;
            } 
            else if (e.DeviceName ==  NewDeviceTypeDesc.DripTray) {
              e['NewDeviceName'] = e.DeviceName;
            } 
            else if (
              e.DeviceName == DeviceTypeDesc.DC ||
              e.DeviceName == DeviceTypeDesc.NewDC
            ) {
              e["NewDeviceName"] = NewDeviceTypeDesc.DC;
            } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
            } else if (e.DeviceName == DeviceTypeDesc.VW) {
              e["NewDeviceName"] = NewDeviceTypeDesc.VW;
            } else if (e.DeviceName == DeviceTypeDesc.UM) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UM;
            }
          });
          this.CustomizeLocatorsSource.data = CustomizeLocatorReport;
          setTimeout(() => {
            this.CustomizeLocatorsSource.sort = this.CusLocsort;
            this.sortingDatalist_CustomizeLocatorReport(
              this.CustomizeLocatorsSource
            );
          }, 0);
          this.CustomizeLocatorsSource.paginator = this.paginator.toArray()[0];
          this.cus_paginator.pageIndex = 0;
        });
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - getCustomizedLocatorsReport() - " +
          err.message
      );
    }
  }

  clkStatechanged(event, DeviceData) {
    try {
      let sel_data;
      const customLocatorData = this.CustomizeLocatorReport;
      let classname;
      let autostateselectvalue;
      let campusid = 0;
      let buildingid = 0;
      let floorid = 0;
      let unitid = 0;
      let devicetypeid = 0;

      if (event == false) this.cusLocatorevent = 0;
      else this.cusLocatorevent = 1;

      const DeviceId = DeviceData.DeviceId;
      const LocationId = DeviceData.EmitterID;
      campusid = DeviceData.CampusId;
      buildingid = DeviceData.BuildId;
      floorid = DeviceData.FloorId;
      unitid = DeviceData.UnitId;
      devicetypeid = DeviceData.DeviceTypeId;

      for (let i = 0; i < customLocatorData.length; i++) {
        if (customLocatorData[i].DeviceId == DeviceId) {
          const autostatecontent1 = document.getElementById(
            DeviceId + "_" + enumReportState.InUse
          );
          if (autostatecontent1 != null)
            autostateselectvalue = autostatecontent1.className;

          const _elementcontent = document.getElementById(
            "tdautostate" + DeviceId
          );
          if (_elementcontent != null) classname = _elementcontent.className;
          sel_data = this.getCusEnableStateData.filter((x) =>
            x.includes(DeviceId)
          );
          if (classname != "enablecontent ng-star-inserted") {
            if (sel_data.length == 0)
              this.getCusEnableStateData.push([
                this.cusLocatorevent,
                DeviceId,
                LocationId,
                campusid,
                buildingid,
                floorid,
                unitid,
                devicetypeid,
              ]);
            else {
              const index = this.getCusEnableStateData.findIndex(
                (user) => user[1] === DeviceId
              );
              if (index != -1) this.getCusEnableStateData.splice(index, 1);
              this.getCusEnableStateData.push([
                this.cusLocatorevent,
                DeviceId,
                LocationId,
                campusid,
                buildingid,
                floorid,
                unitid,
                devicetypeid,
              ]);
            }
          }

          if (
            this.cusLocatorevent == 0 &&
            this.getCusAutoStateData.length > 0 &&
            (classname == "enablecontent ng-star-inserted" ||
              classname == "enablecontent")
          ) {
            const index = this.getCusAutoStateData.findIndex(
              (user) => user[1] === DeviceId
            );
            if (index != -1) this.getCusAutoStateData.splice(index, 1);
            this.getCusAutoStateData.push([0, 0]);
          }

          if (classname == "enablecontent ng-star-inserted") {
            this.ClickEnableState = 1;
            const index = this.getCusEnableStateData.findIndex(
              (user) => user[1] === DeviceId
            );
            if (index != -1) this.getCusEnableStateData.splice(index, 1);
            this.getCusEnableStateData.push([
              this.cusLocatorevent,
              DeviceId,
              LocationId,
              campusid,
              buildingid,
              floorid,
              unitid,
              devicetypeid,
            ]);
          }

          if (
            this.cusLocatorevent == 1 &&
            (autostateselectvalue ==
              "dotlist dotselectedlist ng-star-inserted" ||
              autostateselectvalue == "dotselectedlist")
          ) {
            const index = this.getCusEnableStateData.findIndex(
              (user) => user[1] === DeviceId
            );
            if (index != -1) this.getCusEnableStateData.splice(index, 1);
            this.getCusEnableStateData.push([
              this.cusLocatorevent,
              DeviceId,
              LocationId,
              campusid,
              buildingid,
              floorid,
              unitid,
              devicetypeid,
            ]);
            this.getCusAutoStateData.push([enumReportState.InUse, DeviceId]);
          }

          if (event == false) {
            const elementcontent = document.getElementById(
              "tdautostate" + DeviceId
            );
            if (elementcontent != null)
              elementcontent.className = "disablecontent";

            const autostatecontent1 = document.getElementById(
              DeviceId + "_" + enumReportState.Clean
            );
            if (autostatecontent1 != null)
              autostatecontent1.className = "dotlist";

            const autostatecontent2 = document.getElementById(
              DeviceId + "_" + enumReportState.InUse
            );
            if (autostatecontent2 != null)
              autostatecontent2.className = "dotselectedlist";

            const autostatecontent3 = document.getElementById(
              DeviceId + "_" + enumReportState.Dirty
            );
            if (autostatecontent3 != null)
              autostatecontent3.className = "dotlist";

            const autostatecontent4 = document.getElementById(
              DeviceId + "_" + enumReportState.Unknown
            );
            if (autostatecontent4 != null)
              autostatecontent4.className = "dotlist";
          } else if (event == true) {
            const elementcontent = document.getElementById(
              "tdautostate" + DeviceId
            );
            if (elementcontent != null)
              elementcontent.className = "enablecontent";
          }
        }
      }
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - clkStatechanged() - " +
          err.message
      );
    }
  }

  getdivclickelement(DeviceData, DeviceId, clickedId) {
    try {
      let customLocator_Data = [] as any[];
      let _data;
      let campusid = 0;
      let buildingid = 0;
      let floorid = 0;
      let unitid = 0;
      let devicetypeid = 0;

      const LocationId = DeviceData.EmitterID;
      campusid = DeviceData.CampusId;
      buildingid = DeviceData.BuildId;
      floorid = DeviceData.FloorId;
      unitid = DeviceData.UnitId;
      devicetypeid = DeviceData.DeviceTypeId;
      customLocator_Data = this.CustomizeLocatorReport;

      this.getCusAutoStateData.push([
        clickedId,
        DeviceId,
        LocationId,
        campusid,
        buildingid,
        floorid,
        unitid,
        devicetypeid,
      ]);

      customLocator_Data.forEach(function (data) {
        _data = data.DeviceId;
        if (_data == DeviceId && clickedId == enumReportState.Clean) {
          const autostatecontent1 = document.getElementById(
            DeviceId + "_" + clickedId
          );
          if (autostatecontent1 != null)
            autostatecontent1.className = "dotselectedlist";

          const autostatecontent2 = document.getElementById(
            DeviceId + "_" + enumReportState.InUse
          );
          if (autostatecontent2 != null)
            autostatecontent2.className = "dotlist";

          const autostatecontent3 = document.getElementById(
            DeviceId + "_" + enumReportState.Dirty
          );
          if (autostatecontent3 != null)
            autostatecontent3.className = "dotlist";

          const autostatecontent4 = document.getElementById(
            DeviceId + "_" + enumReportState.Unknown
          );
          if (autostatecontent4 != null)
            autostatecontent4.className = "dotlist";
        } else if (_data == DeviceId && clickedId == enumReportState.InUse) {
          const autostatecontent1 = document.getElementById(
            DeviceId + "_" + enumReportState.Clean
          );
          if (autostatecontent1 != null)
            autostatecontent1.className = "dotlist";

          const autostatecontent2 = document.getElementById(
            DeviceId + "_" + clickedId
          );
          if (autostatecontent2 != null)
            autostatecontent2.className = "dotselectedlist";

          const autostatecontent3 = document.getElementById(
            DeviceId + "_" + enumReportState.Dirty
          );
          if (autostatecontent3 != null)
            autostatecontent3.className = "dotlist";

          const autostatecontent4 = document.getElementById(
            DeviceId + "_" + enumReportState.Unknown
          );
          if (autostatecontent4 != null)
            autostatecontent4.className = "dotlist";
        } else if (_data == DeviceId && clickedId == enumReportState.Dirty) {
          const autostatecontent1 = document.getElementById(
            DeviceId + "_" + enumReportState.Clean
          );
          if (autostatecontent1 != null)
            autostatecontent1.className = "dotlist";

          const autostatecontent2 = document.getElementById(
            DeviceId + "_" + enumReportState.InUse
          );
          if (autostatecontent2 != null)
            autostatecontent2.className = "dotlist";

          const autostatecontent3 = document.getElementById(
            DeviceId + "_" + clickedId
          );
          if (autostatecontent3 != null)
            autostatecontent3.className = "dotselectedlist";

          const autostatecontent4 = document.getElementById(
            DeviceId + "_" + enumReportState.Unknown
          );
          if (autostatecontent4 != null)
            autostatecontent4.className = "dotlist";
        } else if (_data == DeviceId && clickedId == enumReportState.Unknown) {
          const autostatecontent1 = document.getElementById(
            DeviceId + "_" + enumReportState.Clean
          );
          if (autostatecontent1 != null)
            autostatecontent1.className = "dotlist";

          const autostatecontent2 = document.getElementById(
            DeviceId + "_" + enumReportState.InUse
          );
          if (autostatecontent2 != null)
            autostatecontent2.className = "dotlist";

          const autostatecontent3 = document.getElementById(
            DeviceId + "_" + enumReportState.Dirty
          );
          if (autostatecontent3 != null)
            autostatecontent3.className = "dotlist";

          const autostatecontent4 = document.getElementById(
            DeviceId + "_" + clickedId
          );
          if (autostatecontent4 != null)
            autostatecontent4.className = "dotselectedlist";
        }
      });
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - getdivclickelement() - " +
          err.message
      );
    }
  }

  saveCutomizeLocatorsdata() {
    try {
      let arrEnableState_Data = [] as any[];
      let arrAutoState_Data = [] as any[];
      let enablestatevalue;
      let autostatevalue;
      let DeviceId;
      let LocationId;
      let dialogdata = [] as any[];
      let CampusId = 0;
      let BuildingId = 0;
      let FloorId = 0;
      let UnitId = 0;
      let DeviceTypeId = 0;
    
      arrEnableState_Data = this.getCusEnableStateData;
      arrAutoState_Data = this.getCusAutoStateData;

      const select_StateBy = localStorage.getItem("userid");

      if (arrEnableState_Data.length > 0 && arrAutoState_Data.length > 0) {
        for (let i = 0; i < arrEnableState_Data.length; i++) {
          DeviceId = 0;

          for (let j = 0; j < arrAutoState_Data.length; j++) {
            if (arrEnableState_Data[i][1] == arrAutoState_Data[j][1]) {
              DeviceId = arrEnableState_Data[i][1];
              enablestatevalue = arrEnableState_Data[i][0];
              autostatevalue = arrAutoState_Data[j][0];
              CampusId = arrEnableState_Data[i][3];
              BuildingId = arrEnableState_Data[i][4];
              FloorId = arrEnableState_Data[i][5];
              UnitId = arrEnableState_Data[i][6];
              DeviceTypeId = arrEnableState_Data[i][7];
            
              dialogdata = this.removecusdata(dialogdata, DeviceId);
              dialogdata.push({
                DeviceId: DeviceId,
                EnableStateValue: enablestatevalue,
                StateBy: select_StateBy,
                AutoStateValue: autostatevalue,
                CampusId: CampusId,
                BuildingId: BuildingId,
                FloorId: FloorId,
                UnitId: UnitId,
                DeviceTypeId: DeviceTypeId,
              });
            } else {
              if (arrAutoState_Data[j].length == 8) {
                enablestatevalue = 1;
                autostatevalue = arrAutoState_Data[j][0];
                DeviceId = arrAutoState_Data[j][1];
                LocationId = arrAutoState_Data[j][2];
                CampusId = arrAutoState_Data[j][3];
                BuildingId = arrAutoState_Data[j][4];
                FloorId = arrAutoState_Data[j][5];
                UnitId = arrAutoState_Data[j][6];
                DeviceTypeId = arrAutoState_Data[j][7];
               
                dialogdata = this.removecusdata(dialogdata, DeviceId);
                dialogdata.push({
                  DeviceId: DeviceId,
                  EnableStateValue: enablestatevalue,
                  StateBy: select_StateBy,
                  AutoStateValue: autostatevalue,
                  CampusId: CampusId,
                  BuildingId: BuildingId,
                  FloorId: FloorId,
                  UnitId: UnitId,
                  DeviceTypeId: DeviceTypeId,
                });
              }
              if (arrEnableState_Data[i][0] == 0) {
                enablestatevalue = arrEnableState_Data[i][0];
                autostatevalue = 0;
                DeviceId = arrEnableState_Data[i][1];
                LocationId = arrEnableState_Data[i][2];
                CampusId = arrEnableState_Data[i][3];
                BuildingId = arrEnableState_Data[i][4];
                FloorId = arrEnableState_Data[i][5];
                UnitId = arrEnableState_Data[i][6];
                DeviceTypeId = arrEnableState_Data[i][7];
               
                dialogdata = this.removecusdata(dialogdata, DeviceId);
                dialogdata.push({
                  DeviceId: DeviceId,
                  EnableStateValue: enablestatevalue,
                  StateBy: select_StateBy,
                  AutoStateValue: autostatevalue,
                  CampusId: CampusId,
                  BuildingId: BuildingId,
                  FloorId: FloorId,
                  UnitId: UnitId,
                  DeviceTypeId: DeviceTypeId,
                });
              }
            }
            LocationId = arrAutoState_Data[i][2];
          }
          if (DeviceId == undefined || DeviceId == 0) {
            generateAlertMessage(
              this.dialog,
              "Please Select Auto-State for the Location ID [" +
                LocationId +
                "]"
            );
            return;
          }
          dialogdata = this.removecusdata(dialogdata, DeviceId);
          dialogdata.push({
            DeviceId: DeviceId,
            EnableStateValue: enablestatevalue,
            StateBy: select_StateBy,
            AutoStateValue: autostatevalue,
            CampusId: CampusId,
            BuildingId: BuildingId,
            FloorId: FloorId,
            UnitId: UnitId,
            DeviceTypeId: DeviceTypeId,
          });
        }
      } else if (arrAutoState_Data.length == 0) {
        //disable the state changes
        for (let i = 0; i < arrEnableState_Data.length; i++) {
          DeviceId = arrEnableState_Data[i][1];
          enablestatevalue = arrEnableState_Data[i][0];
          LocationId = arrEnableState_Data[i][2];
          CampusId = arrEnableState_Data[i][3];
          BuildingId = arrEnableState_Data[i][4];
          FloorId = arrEnableState_Data[i][5];
          UnitId = arrEnableState_Data[i][6];
          DeviceTypeId = arrEnableState_Data[i][7];
          if (enablestatevalue == 0 && this.ClickEnableState == 1) {
            dialogdata = this.removecusdata(dialogdata, DeviceId);
            dialogdata.push({
              DeviceId: DeviceId,
              EnableStateValue: enablestatevalue,
              StateBy: select_StateBy,
              AutoStateValue: 0,
              CampusId: CampusId,
              BuildingId: BuildingId,
              FloorId: FloorId,
              UnitId: UnitId,
              DeviceTypeId: DeviceTypeId,
            });
          } else if (enablestatevalue == 1) {
            generateAlertMessage(
              this.dialog,
              "Please Select Auto-State for the Location ID [" +
                LocationId +
                "]"
            );
            return;
          }
        }
      } else if (arrEnableState_Data.length == 0) {
        //change the autostate value
        for (let i = 0; i < arrAutoState_Data.length; i++) {
          autostatevalue = arrAutoState_Data[i][0];
          DeviceId = arrAutoState_Data[i][1];
          LocationId = arrAutoState_Data[i][2];
          CampusId = arrAutoState_Data[i][3];
          BuildingId = arrAutoState_Data[i][4];
          FloorId = arrAutoState_Data[i][5];
          UnitId = arrAutoState_Data[i][6];
          DeviceTypeId = arrAutoState_Data[i][7];

          dialogdata = this.removecusdata(dialogdata, DeviceId);
          dialogdata.push({
            DeviceId: DeviceId,
            EnableStateValue: 1,
            StateBy: select_StateBy,
            AutoStateValue: autostatevalue,
            CampusId: CampusId,
            BuildingId: BuildingId,
            FloorId: FloorId,
            UnitId: UnitId,
            DeviceTypeId: DeviceTypeId,
          });
        }
      }
      if (
        this.ClickEnableState == 0 &&
        arrAutoState_Data.length == 0 &&
        arrEnableState_Data.length == 0
      ) {
        generateAlertMessage(this.dialog, "No changes are being made to save");
        return;
      }
      if (dialogdata.length > 0) {
        const ConfirmMessageData = {
          ConfirmMessage:
            "Are you sure you want to manually adjust the <br> TruLocator State Settings?",
        };

        const header = "Enable State Change";
        const data = { ConfirmMessageData, header };
        const dialogRef = this.dialog.open(ConfirmMessageComponent, {
          disableClose: true,
          width: "auto",
          height: "200px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result == "Yes") {
            this.saveCustomizeLocatorsReport(dialogdata);
          } else {
            dialogRef.close("Cancel");
            this.getCusEnableStateData = [];
            this.getCusAutoStateData = [];
            this.cusLocEnableSelectdata = [];
            this.ClickEnableState = 0;
            this.getCustomizedLocatorsReport();
          }
        });
      }
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - saveCutomizeLocatorsdata() - " +
          err.message
      );
    }
  }

  saveCustomizeLocatorsReport(savecuslocdialogdata) {
    try {
      this.updateflag = 0;
      const cusLocatorsdata: saveCustomizeReport = {
        authKey: this.authkey,
        cusLocData: JSON.stringify(savecuslocdialogdata),
        updateFlag: this.updateflag,
      };
      this.reportService
        .saveCustomizeLocatorsReport(cusLocatorsdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          this.getCusAutoStateData = [];
          this.getCusEnableStateData = [];
          this.cusLocEnableSelectdata = [];
          this.ClickEnableState = 0;
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "TruLocator State Settings were edited successfully"
            );
            this.chkCusLocsearchvalue = "";
            this.getCustomizedLocatorsReport();
          } else if (apiresponse["condition"] == "Authfalse") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(
              this.dialog,
              "TruLocator State Settings were edited failed"
            );
          }
        });
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - saveCustomizeLocatorsReport() - " +
          err.message
      );
    }
  }

  searchCusLocatorsReport(filterValue: string) {
    try {
      if (filterValue != null) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.CustomizeLocatorsSource.filter = filterValue;
        this.chkCusLocsearchvalue = filterValue;
        this.getfilterCusLocatorsdetails();
      }
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - searchCusLocatorsReport() - " +
          err.message
      );
    }
  }

  searchCustomizeLocatorfilter() {
    let search_statechangeby = "";
    this.CustomizeLocatorsSource.filterPredicate = function (
      data,
      filter: string
    ): boolean {
      if (data.stateChangebyName != null) {
        search_statechangeby = data.stateChangebyName
          .toLowerCase()
          .includes(filter);
      } else search_statechangeby = "";
      return (
        data.EmitterID.toString().includes(filter) ||
        data.Tru_RoomName.toLowerCase().includes(filter) ||
        search_statechangeby ||
        data.NewDeviceName.toLowerCase().includes(filter)
      );
    };
  }

  cusloc_selectLocationType(selectLocationType) {
    try {
      this.selectedLocationType = selectLocationType;
      this.getfilterCusLocatorsdetails();
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - cusloc_selectLocationType() - " +
          err.message
      );
    }
  }

  CusLoc_selectStateby(selectdata) {
    try {
      this.cusloc_selectStateBy = selectdata;
      this.getfilterCusLocatorsdetails();
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - CusLoc_selectStateby() - " +
          err.message
      );
    }
  }

  getfilterCusLocatorsdetails() {
    let _searchfilterData = "";
    let filtersearch;
    const _filterlocationtype = this.selectedLocationType;
    const _filterstatechangedby = this.cusloc_selectStateBy;
    let _filterCusLocdata;
    const CusLocatorfilteredArray = [] as any[];
    _filterCusLocdata = this.CustomizeLocatorReport;
    let searchFilterCusLocData;

    //////LocationTypefilter////////
    if (_filterlocationtype == "All")
      _filterCusLocdata = this.CustomizeLocatorReport;
    else if (_filterlocationtype != undefined && _filterlocationtype != "") {
      this.CusLocfilteredArray = [];
      const list = this.CustomizeLocatorReport.filter(
        (x) => x.roomcolor == _filterlocationtype
      );
      list.forEach((x) => {
        this.CusLocfilteredArray.push(x);
      });
      _filterCusLocdata = this.CusLocfilteredArray;
    } else {
      _filterCusLocdata = this.CustomizeLocatorReport;
    }

    //////StateChangedbyfilter////////
    if (_filterstatechangedby != undefined && _filterstatechangedby != "") {
      if (_filterstatechangedby == "All") {
        searchFilterCusLocData = _filterCusLocdata;
        _filterCusLocdata = searchFilterCusLocData;
      } else if (_filterstatechangedby != 0) {
        const list = _filterCusLocdata.filter(
          (x) => x.stateChangeby == this.cusloc_selectStateBy
        );
        list.forEach((x) => {
          CusLocatorfilteredArray.push(x);
        });
        _filterCusLocdata = CusLocatorfilteredArray;
      } else {
        searchFilterCusLocData = _filterCusLocdata;
        _filterCusLocdata = searchFilterCusLocData;
      }
    }

    //////Searchfilter////////
    if (this.chkCusLocsearchvalue) {
      _searchfilterData = "";

      filtersearch = this.chkCusLocsearchvalue;

      let search_statechangeby = "";
      _searchfilterData = _filterCusLocdata.filter(function (data) {
        if (data.stateChangebyName != null) {
          search_statechangeby = data.stateChangebyName
            .toLowerCase()
            .includes(filtersearch);
        } else search_statechangeby = "";

        return (
          data.EmitterID.toString().includes(filtersearch) ||
          data.Tru_RoomName.toLowerCase().includes(filtersearch) ||
          search_statechangeby ||
          data.NewDeviceName.toLowerCase().includes(filtersearch)
        );
      });
    } else {
      _searchfilterData = _filterCusLocdata;
    }
    this.CustomizeLocatorsSource.data = _searchfilterData;

    if (this.CustomizeLocatorsSource.data.length == 0)
      this.customizelocatorsloading = false;
    setTimeout(() => {
      this.CustomizeLocatorsSource.sort = this.CusLocsort;
      this.sortingDatalist_CustomizeLocatorReport(this.CustomizeLocatorsSource);
    }, 0);
  }

  downloadCusLocators_Report() {
    const DownloadURL =
      appsettings.REPORT_DOWNLOAD_FOLDER + "Customize_Locators_Color_Code.csv";

    const savefile = document.createElement("a");
    savefile.href = DownloadURL;
    savefile.download = "Customize_Locators_Color_Code.csv";
    savefile.click();
    const event = document.createEvent("MouseEvents");
    event.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    savefile.dispatchEvent(event);
  }

  public onChangeImportFile(fileList: FileList) {
    const file = fileList[0];
    const data = "";
    const itemType =
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
      file.name;
    const fileTypesAllowed = ["csv"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(this.dialog, "Please upload csv file type");
      return;
    }
    this.uploadfiletoPath(data, fileList[0]);
    this.clkfileInput.nativeElement.value = "";
  }

  uploadfiletoPath(data, file) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (!JSON.stringify(res).includes("LocationId")) {
          generateAlertMessage(
            this.dialog,
            "The Trufinder file which you tried to upload is not valid file"
          );
          return;
        } else {
          this.getImportfileData(res);
        }
      });
  }

  getImportfileData(filedata) {
    const fileuploadData = [] as any[];
    let autostateData;
    let LocationTypeData = [] as any[];
    let autostateValue = 0;
    let DeviceTypeId = 0;
    let _DeviceType;
    const DCDevietype = "Doorcontroller";
    LocationTypeData = this.LocationType;

    for (let i = 0; i < filedata.length; i++) {
      if (
        filedata[i].LocationId != "" &&
        filedata[i].Color != "" &&
        filedata[i].DeviceType != ""
      ) {
        autostateData = filedata[i].Color.toUpperCase();
        _DeviceType = filedata[i].DeviceType.toUpperCase();
        DeviceTypeId = 0;
        if (_DeviceType == NewDeviceTypeDesc.Emitter.toUpperCase())
          DeviceTypeId = DeviceType.Emitter;
        else if (_DeviceType == NewDeviceTypeDesc.LF.toUpperCase())
          DeviceTypeId = DeviceType.LF;
        else if (
          _DeviceType == NewDeviceTypeDesc.DC.toUpperCase() ||
          _DeviceType == DeviceTypeDesc.DC.toUpperCase() ||
          _DeviceType == DCDevietype.toUpperCase()
        )
          DeviceTypeId = DeviceType.DC;
        else if (_DeviceType == NewDeviceTypeDesc.Monitor.toUpperCase())
          DeviceTypeId = DeviceType.Monitor;
        else if (_DeviceType == NewDeviceTypeDesc.VW.toUpperCase())
          DeviceTypeId = DeviceType.VW;
        else if (_DeviceType == NewDeviceTypeDesc.UM.toUpperCase())
          DeviceTypeId = DeviceType.UM;

        LocationTypeData.forEach(function (locationdata) {
          if (locationdata.ColorName == autostateData) {
            autostateValue = locationdata.LocationTypeId;
            fileuploadData.push({
              DeviceId: filedata[i].LocationId,
              DeviceTypeId: DeviceTypeId,
              EnableStateValue: 1,
              StateBy: 0,
              AutoStateValue: autostateValue,
            });
          }
        });
        // fileuploadData.push({'DeviceId' : filedata[i].TruLocatorId, 'EnableStateValue' : 1, 'StateBy' : 0, 'AutoStateValue' : autostateValue})
      }
    }
    this.updateflag = 1;
    const cusLocatorsdata: saveCustomizeReport = {
      authKey: this.authkey,
      cusLocData: JSON.stringify(fileuploadData),
      updateFlag: this.updateflag,
    };
    this.reportService
      .saveCustomizeLocatorsReport(cusLocatorsdata)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: any[]) => {
        if (apiresponse.toString() == "true") {
          generateAlertMessage(
            this.dialog,
            "Customize Locators setting edited successfully"
          );
          this.getCustomizedLocatorsReport();
        } else if (apiresponse["condition"] == "Authfalse") {
          AuthFailedMessage(this.dialog, this.router);
          return;
        } else if (apiresponse.toString() == "false") {
          generateAlertMessage(
            this.dialog,
            "Customize Locators setting edited failed"
          );
        }
      });
  }

  colorconfig() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    const dialogRef = this.dialog.open(ReportColorconfigComponent, {
      disableClose: true,
      width: "400px",
      height: "360px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      // data:this.dataSource.data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Confirm") {
        const element = document.getElementById("colorconfigtbtn");
        if (element != null)
          element.className =
            "clsImportbutton mat-raised-button mat-primary ng-star-inserted";
        this.getLocationType();
      } else {
        const element = document.getElementById("colorconfigtbtn");
        if (element != null)
          element.className =
            "clsImportbutton mat-raised-button mat-primary ng-star-inserted";
      }
    });
  }

  sortingDatalist_CustomizeLocatorReport(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (sortHeaderId == "Tru_RoomName" || sortHeaderId == "DeviceName") {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
    if (ReportHeaderComponent.isReportMenuClosed == false) {
      this.reportService.toggleReportMenuNav.emit();
    }
  }

  removecusdata(dialogData, Deviceid) {
    const _dialogData = dialogData;
    for (let k = 0; k < _dialogData.length; k++) {
      if (_dialogData[k].DeviceId == Deviceid) {
        dialogData.splice(k, 1);
      }
    }
    return dialogData;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
