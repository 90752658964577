import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class LeftmenuService {
  getleftmenu() {
          return [
            {routerLink: 'resident', src: 'assets/images/More Menu-Info-Icon@2x.png',line1desc:"Resident/Staff Information",line2desc:"Edit/Add/Discharge"},
            {routerLink: 'notification', src: 'assets/images/More Menu-Notifications-Icon@2x.png',line1desc:"Notifications",line2desc:"Add/Edit escalations"},
            {routerLink: 'technologyoverlay', src: 'assets/images/More Menu-Tech Overlay-Icon@2x.png',line1desc:"Technology Overlay",line2desc:"Edit/Setup floorplans and devices"},
            {routerLink: 'report', src: 'assets/images/More Menu-Report-Icon@2x.png',line1desc:"Report",line2desc:"View facility details"}
          ];
    }
} 