import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  TechnologyForCreationResponse,
  EditUnitForm,
  deleteUnitById,
  getDeviceDetailsReq,
  DeleteDevice,  
  checkDeviceAlert,
  checkLocationAlert,
  getSpaceCategory,
  getRoamingDataById,
  ZoneByID,
} from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
  AuthFailedMessage,
  SessionCheckwithStaff,
  map_widthandheight,
  getCheckInExtent,
  LoadTechnologyFloorSvg,
} from "../../../../../app-general";
import {
  fillcolor_style,
  get_geojsonObject_floor,
  mapBuildTemplate_creation,
} from "../../../../../map-general";
import { Style, Fill, Stroke, Text } from "ol/style";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ConfirmMessageComponent } from "../../../../../confirm-message/confirm-message.component";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  DeviceTypeDesc,
  NewDeviceTypeDesc,
  DeviceType,
  fillcolorPolygon,
  enumRequestFrom,
  enumSpaceCategory,
  enumEnableDisable,
} from "../../../../../app-settings";
import { EditDeviceComponent } from "../../../device/edit-device/edit-device.component";
import { ApiService } from "../../../../../api.service";
import { MapSyncProcess, getDeviceById } from "../../../../../api";
import { AlertSharedService } from "../../../../../_services/index";
import { GeneralMessageComponent } from "../../../../../general-message/general-message.component";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-unit",
  templateUrl: "./edit-unit.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-unit.component.scss"],
})
export class EditUnitComponent implements OnInit, OnDestroy {
  public editUnitForm: UntypedFormGroup;
  public _NewDeviceTypeDesc = NewDeviceTypeDesc;
  public watcher: Observable<boolean>;
  columns = 4;
  firstcolumns = 2;
  deleteUnitId;
  bAddEdit = false;
  bDelete = false;
  private _onDestroy: Subject<void> = new Subject<void>();
  selUnitinfo;
  selFloorinfo;
  floorrowHeight = "79px";
  UniteditImgUrl;
  DeviceInfo;
  selectedData;
  device_list_all = "3";
  RoomCategorydetails;
  selectCategoryId;
  Spacecategory_details;
  MapSyncProcess;
  deleteFlag;
  editdevicedialogopen = 0;
  deletedevicedialogopen = 0;
  selectfloorId;
  floorimagename;
  FloorImageFlag = 0;
  editcheckResOffLimits = false;
  editDeleteFlag = 0;
  editcheckSpaceCategory = 0;
  authkey;
  selectCampusId;
  subZoneId = 0;
  _roomenablehourmin = false;
  _roomenableroomallday = false;
  checkresroominfo = false;
  public Curfew_data = [] as any[];
  data_RstartTime;
  data_RendTime;  
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      "(min-width: 500px)",
    ])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    public http: HttpClient,
    private router: Router,
    public thisDialogRef: MatDialogRef<EditUnitComponent>,
    private alertSharedService: AlertSharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    private apiService: ApiService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.getCurfew_data();
    this.UniteditImgUrl = appsettings.FLOOR_IMAGE_FOLDER + data.FloorPlanImage;
    this.selectedData = data;
    this.selectfloorId = data.FloorId;
    this.selectCampusId = data.CampusId;
    this.subZoneId = data.SubZoneId;
    console.log(data);
    if (data.SpaceCategoryId > 0) {
      this.selectCategoryId = data.SpaceCategoryId;
      if (
        this.selectCategoryId == enumSpaceCategory.StaffArea ||
        this.selectCategoryId == enumSpaceCategory.ActivityArea ||
        this.selectCategoryId == enumSpaceCategory.DiningArea ||
        this.selectCategoryId == enumSpaceCategory.CommonArea
      ) {
        this.editcheckSpaceCategory = 1;
      }
    }

    this.floorimagename = data.FloorPlanImage;

    if (data.isResidentOffLimit == 1) {
      this.editcheckResOffLimits = true;
      this._roomenableroomallday = true;
    } else {
      this.editcheckResOffLimits = false;
    }
    
    if (parseInt(data.RStartHr) >= 0)
      this.data_RstartTime = data.RStartHr.toString() + ":" + data.RStartMi.toString();
    else
      this.data_RstartTime = "";

    if (parseInt(data.REndHr) >= 0)
      this.data_RendTime = data.REndHr.toString() + ":" + data.REndMi.toString();
    else
      this.data_RendTime = "";
      
    if(data.RchkAllday == 1)
    {      
      this._roomenablehourmin = false;
      this.checkresroominfo = true;
    }
    else if(data.RchkAllday == 0 && this.editcheckResOffLimits ==true)
    {      
      this._roomenablehourmin = true;
      this.checkresroominfo = false;
    }

    this.deleteUnitId = data.UnitId;
    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }
  
  getCurfew_data()
  {
    let _curfewdata={};
    const _curfew_data_arr = [] as any[];
    let hourtext = "";
    let _hour = "";
    let _min="";
    let min_inc=30;
    for(let hour = 0; hour <= 24; hour++) 
    {  
      if(hour < 12)
      {
        _hour = "0" + hour.toString();
        hourtext = " am";
      }
      else if(hour == 24)
      {
          _hour =  (hour-12).toString();
          hourtext = " am";
          min_inc=60;
      }   
      else if(hour > 12)
      {
        _hour = "0" + (hour-12).toString();
          hourtext = " pm";
      }    
      else if(hour == 12)
      {
          _hour = hour.toString();
          hourtext = " pm";
      }   
      
      _hour= _hour.slice(-2);
      for(let min = 0; min < 60; min=min+min_inc)
      {     
        _min = "0" + min.toString();  
        _min= _min.slice(-2);               
        _curfewdata = {Time:_hour + ":" + _min + hourtext, Value: hour + ":" + min }         
        _curfew_data_arr.push(_curfewdata);         
      }
      
    }   
        this.Curfew_data=_curfew_data_arr;     
  }

  breakpointChanged() {
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.firstcolumns = 2;
      this.columns = 4;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.firstcolumns = 1;
      this.columns = 2;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.firstcolumns = 2;
      this.columns = 2;
    } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.firstcolumns = 1;
      this.columns = 2;
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.editUnitForm = new UntypedFormGroup({
        campusID: new UntypedFormControl(""),
        buildID: new UntypedFormControl(""),
        floorID: new UntypedFormControl(""),
        unitID: new UntypedFormControl(""),
        campusname: new UntypedFormControl(""),
        buildingname: new UntypedFormControl(""),
        zonename: new UntypedFormControl(""),
        unitname: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        roomcategory: new UntypedFormControl("", [Validators.required]),
        description: new UntypedFormControl("", [
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        res_start: new UntypedFormControl(''),   
        res_end: new UntypedFormControl(''), 
      });

      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }

      const formCampusName = this.editUnitForm.get("campusname");
      const formbuildingname = this.editUnitForm.get("buildingname");
      const formzonename = this.editUnitForm.get("zonename");
      if (formCampusName != null) formCampusName.disable();
      if (formbuildingname != null) formbuildingname.disable();
      if (formzonename != null) formzonename.disable();

      this.getDeviceDetails();
      this.getSpaceCategory();
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  getDeviceDetails() {
    try {
      const DeviceDetails_info = [] as any[];
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.selectCampusId,
        floorId: parseInt(this.selectfloorId),
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: parseInt(this.selectedData.UnitId),
        isIndoor: 1,
        isNeedRoomonly: 0,
        isNeedIntercom: parseInt(voiceLicense)
      };

      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          DeviceDetails["apiReturn"].forEach(function (data) {
            if (data.FilterDeviceTypeId != DeviceType.Room) {
              DeviceDetails_info.push(data);
            }
          });
          DeviceDetails_info.forEach(function (e) {
            if (e.DeviceName == DeviceTypeDesc.Emitter) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
              e["DeviceType"] = DeviceType.Emitter;
            } else if (e.DeviceName == DeviceTypeDesc.HRex) {
              e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
              e["DeviceType"] = DeviceType.HRex;
            } else if (e.DeviceName == DeviceTypeDesc.Rex) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
              e["DeviceType"] = DeviceType.Rex;
            } 
            else if (e.DeviceName == DeviceTypeDesc.LF) {
              if (e.SubType==DeviceType.DripTray)
              {
                e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
              }
              else
              {
             e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
             }
              e['DeviceType'] = DeviceType.LF;
            }
            else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
              e["DeviceType"] = DeviceType.Pullcord;
            } else if (
              e.DeviceName == DeviceTypeDesc.DC ||
              e.DeviceName == DeviceTypeDesc.NewDC
            ) {
              e["NewDeviceName"] = NewDeviceTypeDesc.DC;
              e["DeviceType"] = DeviceType.DC;
            } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
              e["DeviceType"] = DeviceType.Monitor;
            } else if (e.DeviceName == DeviceTypeDesc.VW) {
              e["NewDeviceName"] = NewDeviceTypeDesc.VW;
              e["DeviceType"] = DeviceType.VW;
            } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
              e["DeviceType"] = DeviceType.Gateway;
            } else if (e.DeviceName == DeviceTypeDesc.UT) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UT;
              e["DeviceType"] = DeviceType.UT;
            } else if (e.DeviceName == DeviceTypeDesc.UM) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UM;
              e["DeviceType"] = DeviceType.UM;
            } 
            else if (e.DeviceName == DeviceTypeDesc.Intercom) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Intercom;
              e["DeviceType"] = DeviceType.IC;
            }
            else if (e.DeviceName == NewDeviceTypeDesc.Room) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Room;
              e["DeviceType"] = DeviceType.Room;
            }
            else {
              e["NewDeviceName"] = NewDeviceTypeDesc.Zone;
              e["DeviceType"] = DeviceType.Zone;
            }
          });
          this.DeviceInfo = DeviceDetails_info;
          this.DrawFloorMapCall();
        });
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - getDeviceDetails() - " + err.message
      );
    }
  }

  getSpaceCategory() {
    try {
      const spaceitem: getSpaceCategory = {
        authKey: this.authkey,
        categoryId: 0,
      };
      this.technologyoverlayService
        .getSpaceCategory(spaceitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((spacedetails: any[]) => {
          this.Spacecategory_details = spacedetails;
        });
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - getSpaceCategory() - " + err.message
      );
    }
  }

  selectSpaceCategory(categoryId) {    
    this.selectCategoryId = categoryId;
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.editUnitForm.controls).forEach((field) => {
        // {1}
        const control = this.editUnitForm.get(field); // {2}
        const formUnitTagetId = document.getElementById(event.target.id);
        if (formUnitTagetId != null) {
          if (field == formUnitTagetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    if (this.editDeleteFlag == 1) {
      this.thisDialogRef.close(this.deleteUnitId);
    } else this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.editUnitForm.controls[controlName].hasError(errorName);
  };

  editUnitformdetails(editUnitFormValue) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      if (this.editUnitForm.valid) {
        this.getRoamingDataById(editUnitFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - editUnitformdetails() - " + err.message
      );
    }
  }

  private getRoamingDataById = (editUnitFormValue) => {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();

      if (this.editUnitForm.valid) {
        this.editUnitEnableDisable(enumEnableDisable.Disable);
        const spaceitem: getRoamingDataById = {
          authKey: this.authkey,
          roomId: this.deleteUnitId.toString(),
        };
        this.technologyoverlayService
          .getRoamingDataById(spaceitem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((roamingdetails: any[]) => {
            if (roamingdetails.length > 0) {
              generateAlertMessage(
                this.dialog,
                "Space associated with Roaming Rule"
              );
              this.editUnitEnableDisable(enumEnableDisable.Enable);
              return;
            } else this.updateUnit(editUnitFormValue);
          });
      }
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - getRoamingDataById() - " + err.message
      );
    }
  };

  updateUnit(editUnitFormValue) {
    try {
      
      let check_Room_AllDay;        
      let resstarttime, resendtime;        
      let res_Start_Hr = 0, res_Start_Min = 0;
      let res_End_Hr = 0, res_End_Min = 0;
      // let res_Start_Timezone, res_End_Timezone;      
        
      this.editUnitEnableDisable(enumEnableDisable.Disable);
      let unitname = "";
      unitname = editUnitFormValue.unitname;
      let description = editUnitFormValue.description;
      if (description == null) {
        description = "";
      }
      if (unitname.length > 0)
        unitname = editUnitFormValue.unitname.replace(/\s+/g, " ").trim();
      if (description.length > 0)
        description = description.replace(/\s+/g, " ").trim();

      let resofflimits = 0;
      if (this.editcheckResOffLimits == true) resofflimits = 1;
      else resofflimits = 0;
      
      if(editUnitFormValue.res_start != undefined && editUnitFormValue.res_start != 0)
        {
          resstarttime = editUnitFormValue.res_start.split(":");
          res_Start_Hr = parseInt(resstarttime[0]);        
          res_Start_Min = resstarttime[1].split(" ");        
          res_Start_Min = parseInt(res_Start_Min[0]);
          // res_Start_Timezone = res_Start_Min[1];
        }
        if(editUnitFormValue.res_end != undefined && editUnitFormValue.res_end != 0)
        {
          resendtime = editUnitFormValue.res_end.split(":");        
          res_End_Hr = parseInt(resendtime[0]);
          res_End_Min = resendtime[1].split(" ");        
          res_End_Min = parseInt(res_End_Min[0]);
          // res_End_Timezone = res_End_Min[1];
        }
        
        if(this.checkresroominfo == true)        
          check_Room_AllDay = 1;
        else
          check_Room_AllDay = 0;
          
        if(resofflimits == 1)  
        {
          if ((editUnitFormValue.res_start == "" || editUnitFormValue.res_start == undefined) && (editUnitFormValue.res_end == "" ||
            editUnitFormValue.res_end == undefined) && (this.checkresroominfo == false || this.checkresroominfo == undefined)) 
          {              
            generateAlertMessage(this.dialog,"Please select Resident Time info or All");
            this.editUnitEnableDisable(enumEnableDisable.Enable); 
            return false;
          }

          if (editUnitFormValue.res_start != "" && editUnitFormValue.res_start != undefined) 
          {
            if (editUnitFormValue.res_end == "" || editUnitFormValue.res_end == undefined) 
            {                
              generateAlertMessage(this.dialog,"Please select Resident End time");
              this.editUnitEnableDisable(enumEnableDisable.Enable);    
              return false;
            }
          }
          
          if (editUnitFormValue.res_end != "" && editUnitFormValue.res_end != undefined) 
          {
            if (editUnitFormValue.res_start == "" || editUnitFormValue.res_start == undefined) 
            {                
              generateAlertMessage(this.dialog,"Please select Resident Start time");
              this.editUnitEnableDisable(enumEnableDisable.Enable);  
              return false;
            }
          }
        }
        
        if(resofflimits == 1 && check_Room_AllDay == 0 && res_Start_Hr >= 0 && res_End_Hr <= res_Start_Hr && res_End_Hr < 12)  
        {          
          if((res_Start_Hr == res_End_Hr && res_Start_Min >= res_End_Min) || (res_Start_Hr > res_End_Hr && res_Start_Min <= res_End_Min))
          {
            generateAlertMessage(this.dialog,"Start time should be lesser than the End time");
            this.editUnitEnableDisable(enumEnableDisable.Enable);  
            return false;
          }
        }

      const editunitdetails: EditUnitForm = {
        authKey: this.authkey,
        unitId: parseInt(editUnitFormValue.unitID),
        campusId: parseInt(editUnitFormValue.campusID),
        buildId: parseInt(editUnitFormValue.buildID),
        floorId: parseInt(editUnitFormValue.floorID),
        unitName: unitname,
        description: description.toString(),
        spaceCategoryId: parseInt(this.selectCategoryId),
        accuracy: "",
        resOffLimits: resofflimits,
        spaceId:0,
        chkAllday : check_Room_AllDay,
        resStartHr : res_Start_Hr,
        resStartMi : res_Start_Min,
        resEndHr : res_End_Hr,
        resEndMi : res_End_Min, 
      };

      this.technologyoverlayService
        .updateUnit(editunitdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            //need to call the RELOAD_DRAW_ROOM
            this.apiService.reload_DrawRoom();
            generateAlertMessage(this.dialog, "Space edited successfully.");
            this.thisDialogRef.close(this.subZoneId);
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Space edited failed.");
            this.editUnitEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close();
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else if (apiresponse["apiReturn"] == "unitName") {
            generateAlertMessage(this.dialog, "Please enter Space Name");
            this.editUnitEnableDisable(enumEnableDisable.Enable);
            return;
          } else {
            generateAlertMessage(
              this.dialog,
              "Space name '" + apiresponse["UniteditList"][0].UnitName + "' already exists."
            );
            this.editUnitEnableDisable(enumEnableDisable.Enable);
          }
        });
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - updateUnit() - " + err.message
      );
    }
  }

  openDeletedialog() {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      this.deleteUnit(this.deleteUnitId);
    } catch (err) {
      throw new Error(
        "edit-unit.component.ts" + " - openDeletedialog() - " + err.message
      );
    }
  }
  deleteUnit(deleteUnitId) {
    try {
      console.log("DeviceInfo : " + this.DeviceInfo.length);

      let Device_Count = "";
      let message = "";

      if (this.DeviceInfo.length > 0) {
        message = "It contains ";
        Device_Count = this.DeviceInfo.length + " Devices.";
      }

      const GeneralMessageData = {
        General_header: "Delete Space ",
        GeneralMessage:
          "Are you sure you would like to delete this Space?<br> " +
          message +
          " <b>" +
          Device_Count +
          " </b>",
        General_first: "Yes ",
        General_second: "No",
      };

      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.DeleteUnitbyId(deleteUnitId);
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "delete-resident-group.ts" + " - deleteUnit() - " + err.message
      );
    }
  }

  DeleteUnitbyId(deleteUnitId) {
    const deleteunitById: deleteUnitById = {
      authKey: this.authkey,
      unitId: parseInt(deleteUnitId),
    };

    this.technologyoverlayService
      .deleteUnit(deleteunitById)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
        if (apiresponse["apiReturn"] == "Success") {
          generateAlertMessage(this.dialog, "Space deleted successfully.");
          this.thisDialogRef.close(this.subZoneId);
          return;
        } else if (apiresponse["apiReturn"] == "Fail") {
          generateAlertMessage(this.dialog, "Space deleted failed ");
        } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
          this.thisDialogRef.close("close");
          AuthFailedMessage(this.dialog, this.router);
          return;
        } else {
          generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
          this.thisDialogRef.close("close");
          return;
        }
        this.thisDialogRef.close();
      });
  }

  edit_Devicedetails(deviceinfo) {
    try {
      if (!SessionCheckwithStaff(this.router)) {
        return;
      }
      if (deviceinfo.FilterDeviceType != "Zone") {
        const _dialog = this.dialog;
        const mapsyncinfo: MapSyncProcess = {
          authKey: this.authkey,
        };
        this.apiService
          .checkMapSync(mapsyncinfo)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((MapSyncinfo: any[]) => {
            this.MapSyncProcess = MapSyncinfo;
            if (this.MapSyncProcess == 1) {
              generateAlertMessage(_dialog, appsettings.MapSyncMsg);
              return false;
            } else {
              if (deviceinfo.DeviceId > 0) {
                const getDeviceById: getDeviceById = {
                  authKey: this.authkey,
                  dataId: parseInt(deviceinfo.DataId),
                  deviceType: deviceinfo.DeviceName.toString(),
                  floorId: 0,
                  isIndoor: 1,
                };

                this.technologyoverlayService
                  .getDeviceDetailsbyId(getDeviceById)
                  .pipe(takeUntil(this._onDestroy))
                  .subscribe((Devicedetailsinfo: any[]) => {
                    if (
                      Devicedetailsinfo != null &&
                      Devicedetailsinfo != undefined
                    ) {
                      this.editDevicedialogopen(Devicedetailsinfo["apiReturn"][0]);
                    }
                  });
              } else {
                this.editDevicedialogopen(deviceinfo);
              }
            }
          });
      }
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - edit_Devicedetails() - " + err.message
      );
    }
  }

  editDevicedialogopen(deviceinfo) {
    let width = "37em";
    let height = "580px";

    if (deviceinfo.DeviceName == "UT") {
      width = "87rem";
      height = "740px";
    }
    const Devicedetails_info = { DeviceData: deviceinfo, AddDeviceflag: 0 };
    if (this.editdevicedialogopen == 0) {
      const dialogRef = this.dialog.open(EditDeviceComponent, {
        disableClose: true,
        width: width,
        height: height,
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: Devicedetails_info,
      });
      this.editdevicedialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.editdevicedialogopen = 0;
        if (result == "flooreditclose") {
          this.editDeleteFlag = 1;
          this.getDeviceDetails();
        }
      });
    }
  }

  deleteSpaceDevicedetails(Devicedata) {
    try {
      if (Devicedata.FilterDeviceType != "Zone") {
        const _dialog = this.dialog;
        const mapsyncinfo: MapSyncProcess = {
          authKey: this.authkey,
        };
        this.apiService
          .checkMapSync(mapsyncinfo)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((MapSyncinfo: any[]) => {
            this.MapSyncProcess = MapSyncinfo;
            if (this.MapSyncProcess == 1) {
              generateAlertMessage(_dialog, appsettings.MapSyncMsg);
              return false;
            } else {
              this.deleteconfirmation(Devicedata);
            }
          });
      }
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - DeleteTechDevice() - " + err.message
      );
    }
  }

  deleteconfirmation(Devicedata) {
    try {      
      let deviceId_delete;
      if (Devicedata.DeviceName == "Gateway")
        deviceId_delete = Devicedata.MacId;
      else deviceId_delete = Devicedata.DeviceInfo;

     const ConfirmMessageData = {
        ConfirmMessage:
          "Are you sure you want to delete " +
          Devicedata.NewDeviceName +
          " [" +
          deviceId_delete +
          "]?",
      };
      this.deleteFlag = 0;
      if (this.deletedevicedialogopen == 0) {
        const dialogRef = this.dialog.open(ConfirmMessageComponent, {
          disableClose: true,
          width: "auto",
          height: "200px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: ConfirmMessageData,
        });
        this.deletedevicedialogopen = 1;
        dialogRef.afterClosed().subscribe((result) => {
          this.deletedevicedialogopen = 0;
          if (result == "Yes") {
            this.deleteDevicedetails(Devicedata);
          } else {
            dialogRef.close("Cancel");
            this.deletedevicedialogopen = 0;
            const devicelistelement = document.getElementById("divDeviceheader");
            if(devicelistelement != null)
              devicelistelement.className = "enable";
            const deviceaddelement = document.getElementById("divAddDevice");
            if(deviceaddelement != null)
              deviceaddelement.className = "enable";
            return;
          }
        });
      }
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - confirmation() - " + err.message
      );
    }
  }

  deleteDevicedetails(Data) {
    try {
      let devicetype;
      let alert_deviceId;
      let DCdualutid = 0;
      alert_deviceId = Data.DeviceId;
      if (Data.DeviceName == DeviceTypeDesc.Emitter) {
        devicetype = DeviceType.Emitter;
        alert_deviceId = Data.DeviceTypeId;
      } else if (Data.DeviceName == DeviceTypeDesc.HRex) {
        devicetype = DeviceType.HRex;
        alert_deviceId = Data.DeviceInfo;
      } else if (Data.DeviceName == DeviceTypeDesc.Rex)
        devicetype = DeviceType.Rex;
      else if (Data.DeviceName == DeviceTypeDesc.Room)
        devicetype = DeviceType.Room;
      else if (Data.DeviceName == DeviceTypeDesc.Pin)
        devicetype = DeviceType.Pin;
      else if (Data.DeviceName == DeviceTypeDesc.LF) devicetype = DeviceType.LF;
      else if (Data.DeviceName == DeviceTypeDesc.FloorPlanNotes)
        devicetype = DeviceType.FloorPlanNotes;
      else if (Data.DeviceName == DeviceTypeDesc.Pullcord)
        devicetype = DeviceType.Pullcord;
      else if (
        Data.DeviceName == DeviceTypeDesc.DC ||
        Data.DeviceName == DeviceTypeDesc.NewDC
      ) {
        devicetype = DeviceType.DC;
        DCdualutid = Data.dualutId;
      } else if (Data.DeviceName == DeviceTypeDesc.Monitor)
        devicetype = DeviceType.Monitor;
      else if (Data.DeviceName == DeviceTypeDesc.VW) devicetype = DeviceType.VW;
      else if (Data.DeviceName == DeviceTypeDesc.Gateway)
        devicetype = DeviceType.Gateway;
      else if (Data.DeviceName == DeviceTypeDesc.UT) devicetype = DeviceType.UT;
      else if (Data.DeviceName == DeviceTypeDesc.UM) devicetype = DeviceType.UM;
      else if (Data.DeviceName == DeviceTypeDesc.Intercom) devicetype = DeviceType.IC;

      const deletedevicedetails: DeleteDevice = {
        authKey: this.authkey,
        campusId: parseInt(Data.CampusId),
        buildingId: parseInt(Data.BuildingId),
        floorId: parseInt(Data.FloorId),
        monitorId: parseInt(Data.DeviceId),
        oldDeviceId: parseInt(Data.DeviceId),
        svgId: parseInt(Data.SvgId),
        svgDeviceType: parseInt(devicetype),
        updateMode: 3,
        location: Data.UnitLocation.toString(),
        emitterID: Data.DeviceTypeId.toString(),
        monitorType: Data.DeviceName.toString(),
        vmDirection: devicetype.toString(),
        unitId: parseInt(Data.UnitId),
        deviceX: -1,
        deviceY: -1,
        polygonPoints: "",
        oldEmiterId: "",
        dcDualId: 0,
        oldDcDualId: 0,
        dcMacId: "",
        oldDCMacId: "",
        isDCTag: 0,
        isDCLotiering: 0,
        isDCUnauthorized: 0,
        ispcRemoveDetection: 0,
        ispcButtonPress: 0,
        isPullCord: 0,
        ispcMobilityMode: 0,
        enableInput1: 0,
        alertState1: 0,
        alertMessage1: "",
        enableInput2: 0,
        alertState2: 0,
        alertMessage2: "",
        enableInput3: 0,
        alertState3: 0,
        alertMessage3: "",
        ambientOverTemp: 0,
        ambientOverDesc: "",
        ambientOverMessage: "",
        ambientUnderTemp: 0,
        ambientUnderDesc: "",
        ambientUnderMessage: "",
        probeOverTemp: 0,
        probeOverDesc: "",
        probeOverMessage: "",
        probeUnderTemp: 0,
        probeUnderDesc: "",
        probeUnderMessage: "",
        humidityOverTemp: 0,
        humidityOverDesc: "",
        humidityOverMessage: "",
        humidityUnderTemp: 0,
        humidityUnderDesc: "",
        humidityUnderMessage: "",
        resRoomCategory: parseInt(this.selectCategoryId),
        dataId: parseInt(Data.DataId),
        isIndoor: 1,
        input1StartHr: 0,
        input1StartMin: 0,
        input1EndHr: 0,
        input1EndMin: 0,
        input1checkAllDay : 0,
        input2StartHr: 0,
        input2StartMin: 0,
        input2EndHr: 0,
        input2EndMin: 0,
        input2checkAllDay : 0,
        input3StartHr: 0,
        input3StartMin: 0,
        input3EndHr: 0,
        input3EndMin: 0,
        input3checkAllDay : 0
      };

      const deviceDetails: checkDeviceAlert = {
        authKey: this.authkey,
        deviceId: parseInt(alert_deviceId),
        deviceType: devicetype.toString(),
        dualFlag: DCdualutid,
        isDelete: 1,
      };
      this.technologyoverlayService
        .checkDeviceInAlert(deviceDetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "This device has open alerts, please complete or cancel those alerts and try again."
            );
            const devicelistelement = document.getElementById("divDeviceheader");
            if(devicelistelement != null)
              devicelistelement.className = "enable";
            const deviceaddelement = document.getElementById("divAddDevice");
            if(deviceaddelement != null)
              deviceaddelement.className = "enable";
            return;
          } else {
            const LocationDetails: checkLocationAlert = {
              authKey: this.authkey,
              irOrRFId: Data.DeviceId.toString(),
              deviceType: devicetype.toString(),
            };
            this.technologyoverlayService
              .checkLocationInAlert(LocationDetails)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse: any[]) => {
                if (apiresponse.toString() == "true") {
                  generateAlertMessage(
                    this.dialog,
                    "This Location has open alerts, please complete or cancel those alerts and try again."
                  );
                  const devicelistelement = document.getElementById("divDeviceheader");
                  if(devicelistelement != null)
                    devicelistelement.className = "enable";
                  const deviceaddelement = document.getElementById("divAddDevice");
                  if(deviceaddelement != null)
                    deviceaddelement.className = "enable";
                  return;
                } else {
                  this.technologyoverlayService
                    .deleteDevicesforFloor(deletedevicedetails)
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(
                      (apiresponse: TechnologyForCreationResponse[]) => {
                        this.editDeleteFlag = 1;
                        if (apiresponse != null && apiresponse != undefined) {
                          if (apiresponse["apiReturn"] == "Success") {
                            if (
                              Data.DeviceName == DeviceTypeDesc.Rex ||
                              Data.DeviceName == DeviceTypeDesc.HRex
                            ) {
                              this.technologyoverlayService.reloadiniltc();
                              this.technologyoverlayService.systemreset_ltc();
                            }
                            if (Data.DeviceName == DeviceTypeDesc.Pullcord) {
                              this.technologyoverlayService.reloadPullCordConfig_ltc();
                            } else if (
                              Data.DeviceName == DeviceTypeDesc.DC ||
                              Data.DeviceName == DeviceTypeDesc.NewDC
                            ) {
                              this.apiService.reload_DC_Config();
                            } else if (Data.DeviceName == DeviceTypeDesc.UT) {
                              this.apiService.reloadDCTdevice();
                            } else if (
                              Data.DeviceName != DeviceTypeDesc.Rex &&
                              Data.DeviceName != DeviceTypeDesc.HRex &&
                              Data.DeviceName != DeviceTypeDesc.Gateway
                            ) {
                              this.apiService.reload_DrawRoom();
                            }

                            if (
                              Data.DeviceName == DeviceTypeDesc.Rex ||
                              Data.DeviceName == DeviceTypeDesc.HRex
                            ) {
                              generateAlertMessage(
                                this.dialog,
                                "Device details deleted successfully. <br> It will take 400 seconds for the settings to take effect."
                              );
                            } else {
                              generateAlertMessage(
                                this.dialog,
                                "Device details deleted successfully."
                              );
                            }

                            if (
                              Data.DeviceName == DeviceTypeDesc.HRex ||
                              Data.DeviceName == DeviceTypeDesc.UT ||
                              Data.DeviceName == DeviceTypeDesc.DC ||
                              Data.DeviceName == DeviceTypeDesc.NewDC ||
                              Data.DeviceName == DeviceTypeDesc.Pullcord
                            ) {
                              let CheckAlertData = {
                                IsAlertChanged: 0,
                                IsAlertClicked: 0,
                              };
                              this.alertSharedService.changeSharedCheckAlert(
                                CheckAlertData
                              );
                              CheckAlertData = {
                                IsAlertChanged: 1,
                                IsAlertClicked: 0,
                              };
                              this.alertSharedService.changeSharedCheckAlert(
                                CheckAlertData
                              );
                            }
                            // this.thisDialogRef.close('flooreditclose');
                            this.getDeviceDetails();
                          } else if (apiresponse["apiReturn"] == "Fail") {
                            generateAlertMessage(
                              this.dialog,
                              "Device details deleted failed."
                            );
                          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
                            this.thisDialogRef.close("close");
                            AuthFailedMessage(this.dialog, this.router);
                            return;
                          }
                        } else {
                          generateAlertMessage(
                            this.dialog,
                            "Device details deleted failed."
                          );
                        }
                      }
                    );
                }
              });
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - deleteDevicedetails() - " + err.message
      );
    }
  }

  imgFloorMapView() {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(this.selectedData.FloorId),
    };
    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.thisDialogRef.close(FloorByid[0]);
        this.router.navigate(["../../mapfloor"], { state: FloorByid["apiReturn"][0] });
      });
  }

  DrawFloorMapCall() {
    try {
      const UnitId = this.selectedData.UnitId;
      const selectedfloor = this.selectedData.FloorId;
      const ImageWidth = this.selectedData.bgImageWidth;
      const ImageHeight = this.selectedData.bgImageHeight;
      const CZoomLevel = this.selectedData.FZoomLevel;
      const CRotateLevel = this.selectedData.FRotateLevel;
      const TranslateX = this.selectedData.FTransformX;
      const TranslateY = this.selectedData.FTransformY;
      const FloorImageURL = this.selectedData.FloorPlanImage;

      const ImageUrl = appsettings.FLOOR_IMAGE_FOLDER + "/" + FloorImageURL;

      const BackgroundIMG = "";
      const Map_image_path = "assets/images/";

      const Space_FloorInfo = [] as any[];
      this.DeviceInfo.forEach(function (data) {
        if (data["UnitId"] == UnitId) {
          Space_FloorInfo[Space_FloorInfo.length] = data;
          return false;
        } else {
          return true;
        }
      });

      const mapId = "map1";
      //Map Empty
      const element = document.getElementById(mapId);
      if(element != null)
        element.innerHTML = "";

      const imgwh = map_widthandheight(ImageWidth, ImageHeight);
      const imgwidth = imgwh.ImageWidth;
      const imgheight = imgwh.ImageHeight;

      const devicelabelStyle = new Style({
        text: new Text({
          font: "10px Poppins, sans-serif",
          overflow: true,
          fill: new Fill({
            color: "#000",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 5,
          }),
          offsetX: 0,
          offsetY: 25.5,
        }),
      });

      const deviceStyle = fillcolor_style(fillcolorPolygon.Completed);
      const fillStyle = [deviceStyle, devicelabelStyle];
      const _offsetWidth = element?.offsetWidth;
      const _offsetHeight = element?.offsetHeight

      const map = mapBuildTemplate_creation(
        ImageUrl,
        imgwidth,
        imgheight,
        true,
        BackgroundIMG,
        CZoomLevel,
        CRotateLevel,
        _offsetWidth,
        _offsetHeight,
        TranslateX,
        TranslateY,
        mapId
      );
      map.getControls().removeAt(0);
      let translateX = TranslateX;
      let translateY = TranslateY;

      translateX = translateX / 122;
      translateY = translateY / 127;

      translateX = translateX * imgwidth;
      translateY = translateY * imgheight;

      const _map = map;
      const MapCenter = _map.getView().getCenter();

      MapCenter[0] -= translateX;
      MapCenter[1] += translateY;

      _map.on("moveend", function (e) {
        getCheckInExtent(e, _map, imgwidth, imgheight, MapCenter);
      });

      //////////Floor Devices Start////////////

      const arrdevicemarker = [];
      const arrdevicemarkernoPoly = [];
      if (Space_FloorInfo != null) {
        /// Geo Feature Collection ///
        const geoFloorjsonObject = get_geojsonObject_floor(
          enumRequestFrom.floorplan_page
        );
        geoFloorjsonObject.type = "FeatureCollection";

        
        geoFloorjsonObject.features.length = 0;

        /// marker array ///
        
        if (Space_FloorInfo.length > 0) {
          const arrFloorInfo = Space_FloorInfo;
          LoadTechnologyFloorSvg(
            map,
            arrFloorInfo,
            imgheight,
            imgwidth,
            arrdevicemarker,
            geoFloorjsonObject,
            arrdevicemarkernoPoly,
            devicelabelStyle,
            fillStyle,
            Map_image_path,
            selectedfloor,
            1
          );
        } else if (FloorImageURL == null) {
          const _map = document.getElementById("map");
          if(_map != null)
            _map.innerHTML = "<span style='margin-top: 15%; padding-left: 45%;display:block;font-size:15px;font-weight:bold;'> Map Not Found.</span>";
        }
      }
      //////////Floor Devices End////////////
    } catch (err) {
      throw new Error(
        "floorplans.component.ts - DrawFloorMapCall() - " + err.message
      );
    }
  }

  toggler_ResOffLimits(e) {    
    if (e.checked == true) {
      this.editcheckResOffLimits=true;  
      this._roomenableroomallday = true; 
      this.checkresroominfo = true;
      if(this.checkresroominfo == true)
        this._roomenablehourmin = false;     
      else
        this._roomenablehourmin = true;     
    }
    else{
      this.editcheckResOffLimits=false;   
      this._roomenableroomallday = false;
      this._roomenablehourmin = false;  
      this.data_RstartTime = 0;
      this.data_RendTime = 0;
      this.checkresroominfo = false;
    }  
    
  }
  
  toggleresdayinfoVisibility(event)
  {
    if (event.checked == true) {
      this._roomenablehourmin = false;     
      this.checkresroominfo = true;
      this.data_RstartTime = "";
      this.data_RendTime = "";
    }
    else{
      this._roomenablehourmin = true;            
      this.data_RstartTime = 0;
      this.data_RendTime = 0;
      this.checkresroominfo = false;
    }
  }

  editUnitEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsaveuniteditclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clseditunitbutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clseditunitbutton mat-raised-button disablebtn";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
