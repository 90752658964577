<perfect-scrollbar style="max-width: 1200px; max-height: 700px;" >
  <div class="clseditconfig">
     
              <div class="editConfig-title">
                <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editConfigHeader" style="margin: 0 0 10px 11px;">Configuration Settings
                <span><img class="clseditconfigcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
                <mat-divider ></mat-divider>
              </div>
                     
              <form  style="padding:24px;"  [formGroup]="configeditForm" autocomplete="off" novalidate (ngSubmit)="oneditConfigdetails(configeditForm.value)">     
          
                <div class="content" fxLayout="row" fxLayout.md="column" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
                    
                  <div fxFlex="65" fxFlex.md="25"> 
              
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="editConfigrowHeight">                             
                      <mat-grid-tile>
                          <label>File Name</label>
                      </mat-grid-tile>
                      <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                              <div class="text-inside" style="width:100%;">
                                <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                                  <input matInput placeholder="File Name" formControlName="filename" maxlength="32" [(ngModel)]="data.FileName" (keyup)="handleInput($event)"/>                                      
                              </mat-form-field>
                            </div>                     
                      </mat-grid-tile>                                 
      
                      <mat-grid-tile>
                          <label>Board Name </label>
                      </mat-grid-tile>
                      <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                              <div class="text-inside" style="width:100%;">
                                  <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                                 <input matInput placeholder="Board Name" formControlName="boardname" maxlength="32" [(ngModel)]="data.BoardName" (keyup)="handleInput($event)"/>                                 
                              </mat-form-field>
                            </div>
                      </mat-grid-tile>  
                      
                      <mat-grid-tile>
                        <label>IP Address </label>
                    </mat-grid-tile>
                    <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                            <div class="text-inside" style="width:100%;">
                               <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                               <input matInput placeholder="IP Address" formControlName="ipaddress" maxlength="32" [(ngModel)]="data.IPAddress" (keyup)="handleInput($event)" required/>
                               </mat-form-field>
                          </div>
                    </mat-grid-tile> 
                  <mat-grid-tile>
                      <label>UDP Port</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                          <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                            <input matInput placeholder="UDP Port" maxlength="32" formControlName="udpport" [(ngModel)]="data.UDPPort" (keyup)="handleInput($event)" required/>      
                          </mat-form-field>
                        </div>
                  </mat-grid-tile> 
                  
                <mat-grid-tile>
                   <label>Campus Location</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                        <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                            <mat-select formControlName="campus" placeholder="Select Campus" [(ngModel)]="selectedCampusId" tabindex="1" (ngModelChange)="selectbuilding($event)" required>    
                              <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId" (click)="changecampus(Campus.CampusId,Campus.CampusName);">
                                {{Campus.CampusName}}
                              </mat-option>
                            </mat-select> 
                            <mat-error *ngIf="configeditForm.controls.campus.hasError('required')">Please select Campus</mat-error>
                          </mat-form-field>
                      </div>                     
                </mat-grid-tile>                                 

                <mat-grid-tile>
                    <label>Building</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                        <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">              
                            <mat-select #selBuilding placeholder="Select Building" formControlName="building" [(ngModel)]="buildingId" tabindex="2" (ngModelChange)="callgetSpacedetails($event)" required>    
                            <mat-option *ngFor="let Building of Buildingdetails" [value]="Building.BuildId" (click)="changebuilding(Building.BuildId,Building.BuildingName);">
                              {{Building.BuildingName}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="configeditForm.controls.building.hasError('required')" >Please select Building</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>  
                <mat-grid-tile>
                    <label>Description</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                        <div class="text-inside" style="width:100%;">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                          <input matInput placeholder="Description" formControlName="description" maxlength="30" [(ngModel)]="data.Description" tabindex="3" (keyup)="handleInput($event)" required/>   
                          <mat-error *ngIf="configeditForm.controls.description.hasError('required')">Please enter description</mat-error>                              
                        </mat-form-field>
                      </div>
                </mat-grid-tile>  
                  </mat-grid-list> 
                  <input type="hidden" formControlName="hdnFileName" [(ngModel)]="data.FileName">   
                  <input type="hidden" formControlName="hdnBoardName" [(ngModel)]="data.BoardName">
                  <input type="hidden" formControlName="hdnIpAddress" [(ngModel)]="data.IPAddress">
                  <input type="hidden" formControlName="hdnUdpPort" [(ngModel)]="data.UDPPort">          
                </div>
                                 
                <div fxFlex="45" fxFlex.md="45" style="margin-top:1em;">
                  <mat-grid-list [cols]="columns" class="clsmatconfiggridlist">
                      <perfect-scrollbar style="max-width: 800px; max-height: 500px;" >                        
                        <div class="text-inside" style=" margin-right:1em; display: flex;">  
                          <table mat-table [dataSource]="dataSource">   
                            <ng-container matColumnDef="Point">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [ngClass]="'headercell'" > Point </th>
                              <td mat-cell *matCellDef="let element"> {{element.Point}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Device">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [ngClass]="'headercell'"> Device </th>
                              <td mat-cell *matCellDef="let element"> {{element.Device}} </td>
                            </ng-container>
                            <ng-container matColumnDef="PointName">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [ngClass]="'headercell'"> Point Name </th>
                              <td mat-cell *matCellDef="let element"> {{element.PointName}} </td>
                            </ng-container>
                                                        
                            <ng-container matColumnDef="SpaceName">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [ngClass]="'headercell'"> Space Name </th>
                              <td mat-cell *matCellDef="let element"> 
                                <mat-form-field appearance="outline" style="width:120px !important;">
                                  <mat-select id="mat_select_{{element.Index}}" formControlName="mat_select_{{element.Index}}" tabindex="4" (selectionChange)="setboardsetupdetails($event.value,element)">   
                                  <mat-option *ngFor="let _element of element.Spaces" [value]="_element.SpaceId">
                                    {{_element.SpaceName}}
                                  </mat-option>
                                </mat-select> 
                              </mat-form-field>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="Index">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [style.display]="'none'" [ngClass]="'headercell'" > Index </th>
                              <td [style.display]="'none'" mat-cell *matCellDef="let element"> {{element.Index}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>                                                                          
                        </div>   
                      </perfect-scrollbar>
                  </mat-grid-list>
                </div>
                  
                </div>
                <mat-dialog-actions style="float:left;">              
                  <button type="button" class="clsconfigdltbutton" mat-raised-button (click)="opendeleteconfigdialog()" tabindex="7">Delete</button> 
                </mat-dialog-actions>
                <mat-dialog-actions class="clsconfigeditbuttoncontrol">                 
                  <button type="button" class="clsconfigdltbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()" tabindex="6">Cancel</button>&nbsp;
                  <button id="btnsaveclick" class="clsconfigeditbutton" mat-raised-button [disabled]="!configeditForm.valid" tabindex="5">Save</button>
                </mat-dialog-actions>
              </form>
  </div>
</perfect-scrollbar>