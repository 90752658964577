<div class="clscontentsidebar">
    <mat-list class="clsleftcontent">
        <mat-nav-list *ngFor="let left of leftmenu">
               <a mat-list-item id="sel_leftbar" [ngStyle]="{'background-color': selecteditem === left.id ? '#B5B5B6' : '#E6E6E6'}" routerLink={{left.routerLink}} 
                    routerLinkActive="active" (click)="onItemSelected(left.id)">
                    <mat-list-item style="height:70px;" >
                    <h4  fxShow.sm="false" fxShow.xs="false" fxShow.md="true" fxShow="true"  mat-line class="clsleftheader">{{left.description}}</h4>
                    
                    <mat-icon fxShow.sm="true" fxShow.xs="true" fxShow.md="false" fxShow="false" mat-list-avatar color="primary">{{left.src}}</mat-icon>
                    
                </mat-list-item>
                <mat-divider ></mat-divider>
                </a>
        </mat-nav-list>
    </mat-list>
</div>
