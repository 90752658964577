<perfect-scrollbar style="max-width: 1200px; max-height: 640px;" >
  <div class="clsaddUnitTemp">
      <div class="addUnitTemp-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addunitTempHeader" style="margin: 0 0 10px 11px;">Save Space as Template
        <span><img class="clsUnitTempcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="unitTempateForm" autocomplete="off" novalidate [style.height]="minheight" (ngSubmit)="templateunitDetails(unitTempateForm.value)">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div>   
                
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                    
                    <mat-grid-tile class="clsunittempmatgrid">
                      <label>Selected Space</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunittempmatgridtxt">
                          <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                             <input matInput placeholder="Space Name" formControlName="unitname" maxlength="32" [(ngModel)]="data.FloorName" (keyup)="handleInput($event)" required/>                            
                             <input type="hidden" formControlName="campusID" [(ngModel)]="data.CampusId">   
                             <input type="hidden" formControlName="buildID" [(ngModel)]="data.BuildId">
                             <input type="hidden" formControlName="floorID" [(ngModel)]="data.FloorId">
                             <input type="hidden" formControlName="unitID" [(ngModel)]="data.UnitId">
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>                       

                    <mat-grid-tile class="clsunittempmatgrid">
                      <label>New Template<br>Name</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunittempmatgridtxt">
                        <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                            <input matInput placeholder="Template Name" maxlength="32" formControlName="newtempname" (keyup)="handleInput($event)">
                            <mat-error *ngIf="unitTempateForm.controls.newtempname.hasError('required')">Please enter New Template Name</mat-error>
                            <mat-error *ngIf="unitTempateForm.controls.newtempname.hasError('pattern')">Please enter valid New Template Name</mat-error>
                            <mat-error *ngIf="unitTempateForm.controls.newtempname.hasError('maxlength')">Max length 32 characters only</mat-error>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>   
                  
                  <mat-grid-tile class="clsunittempmatgrid">
                    <label>Description</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsunittempmatgridtxt">
                      <div class="text-inside">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                           <input matInput placeholder="Description" maxlength="32" formControlName="description" (keyup)="handleInput($event)">
                           <mat-error *ngIf="unitTempateForm.controls.description.hasError('required')">Please enter Description</mat-error>
                           <mat-error *ngIf="unitTempateForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                           <mat-error *ngIf="unitTempateForm.controls.description.hasError('maxlength')">Max length 32 characters only</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>                                  

                </mat-grid-list>                                  
                
                <div style="margin-top:1em;">
                  <label>Select the devices you'd like to save in this Space:</label>
                    <mat-grid-list [cols]="columns" [rowHeight]="rowHeight" class="clstempmatgridlist">
                      
                          <perfect-scrollbar style="max-width: 1200px; max-height: 200px;" >
                            <div class="text-inside" style="margin-top:1em;" *ngIf="DevicesInfo.length > 0">                                                                            
                              <mat-checkbox aria-label="Select All" 
                              class="clscheckboxunit clsenable" id="chkresident" 
                              [checked]="isCheckedAll" 
                              (change)="$event ? toggleAll($event) : null" style="font-size: 14px;">Select All</mat-checkbox>
                            </div> 
                            <div style="margin:1em;">
                              <label style="font-size: medium;">Devices</label>
                            </div>  
                            <div *ngFor="let Device of DevicesInfo;">                              
                              <div class="text-inside" style="margin-top:1em;" *ngIf="Device.ResRoomCategory.length < 15">                                                                            
                                <mat-checkbox class="clscheckboxunit clsenable" [checked]="exists(Device.DataId)" [id]="'chkdevice_'+Device.DataId" (click)="$event.stopPropagation()"
                                (change)="$event ? toggleUnitVisibility(Device.DataId,$event) : null" style="font-size: 14px;margin-right: 5px;"
                                [ngStyle]="{'opacity': Device.FilterDeviceType == 'Zone' ? '0.5' : 'none'}" [disabled]="Device.FilterDeviceType == 'Zone'"></mat-checkbox>
                                {{Device.FilterDeviceType}} - {{Device.ResRoomCategory}} 
                              </div>   
                              
                              <div class="text-inside" style="margin-top:1em;" *ngIf="Device.ResRoomCategory.length >= 15">                                                                            
                                <mat-checkbox class="clscheckboxunit clsenable" [checked]="exists(Device.DataId)" [id]="'chkdevice_'+Device.DataId" (click)="$event.stopPropagation()"
                                (change)="$event ? toggleUnitVisibility(Device.DataId,$event) : null" style="font-size: 14px;margin-right: 5px;"
                                [ngStyle]="{'opacity': Device.FilterDeviceType == 'Zone' ? '0.5' : 'none'}" [disabled]="Device.FilterDeviceType == 'Zone'"
                                matTooltipClass="devicetooltip" matTooltip={{Device.ResRoomCategory}} #tooltip="matTooltip" matTooltipPosition="above">
                                {{Device.FilterDeviceType}} - {{Device.ResRoomCategory.slice(0,15) + ".."}} </mat-checkbox>
                              </div>                                                               
                              
                            </div>               
                      
                      </perfect-scrollbar>
                      </mat-grid-list>
                </div>
                
              </div>            
          
        
 </div>
 <mat-dialog-actions class="clstempbuttoncontrol">      
  <button type="button" class="clstempcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnspacetempsaveclick" class="clstempsavebutton" mat-raised-button >Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>