import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { StaffService } from "../staff.service";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import {
  StaffGroup,
  StaffGroupForCreationResponse,
  getStaffGroup,
  deleteStaffGroup,
} from "../staff";
import { ApiService } from "../../../api.service";
import { getGroupNotification } from "../../../api";
import { AddStaffGroupComponent } from "./add-staff-group/add-staff-group.component";
import { EditStaffGroupComponent } from "./edit-staff-group/edit-staff-group.component";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
  getAddEditDeleteColumn,
  AuthFailedMessage
} from "../../../app-general";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../../app-settings";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-show-staff-group",
  templateUrl: "./show-staff-group.component.html",
  providers: [StaffService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./show-staff-group.component.scss"],
})
export class ShowStaffGroupComponent implements OnInit, OnDestroy {
  StaffGroup: StaffGroup[];
  dataSource;
  dataSourcecount = 1;
  displayedColumns: string[] = [
    "GroupName",
    "Description",
    "Staffs",
    "Edit",
    "Delete",
  ];
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  bAddEdit = false;
  bDelete = false;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  public watcher: Observable<boolean>;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<ShowStaffGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Inject(RollbarService) private rollbar: Rollbar,
    private staffService: StaffService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged());             
  }
  
  breakpointChanged() {    
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Staff_Group,
        enumUIAction.Staff_Group_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupFullTable();
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Staff_Group,
        enumUIAction.Staff_Group_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    this.getStaffGroup();
  }

  setupTableforXtrasmallerwidth() {
    this.displayedColumns = ["GroupName", "Count", "Edit", "Delete"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableforsmallerwidth() {
    this.displayedColumns = ["GroupName", "Staffs", "Count", "Edit", "Delete"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupFullTable() {
    this.displayedColumns = [
      "GroupName",
      "Description",
      "Staffs",
      "Count",
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  getStaffGroup(): void {
    try {
      const staffgroupitem: getStaffGroup = {
        authKey: this.authkey,
      };
      this.staffService
        .getStaffGroup(staffgroupitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((StaffGroup: any[]) => {
          this.StaffGroup = StaffGroup["apiReturn"];
          this.dataSource = new MatTableDataSource(this.StaffGroup);
          this.dataSource.data = this.StaffGroup;
          this.dataSourcecount = this.dataSource.data.length;
        });
    } catch (err) {
      throw new Error(
        "show-staff-group.component.ts" + " - getStaffGroup() - " + err.message
      );
    }
  }

  /*********Add Staff group dialogwindow*******/
  AddStaffGroup() {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    const dialogRef = this.dialog.open(AddStaffGroupComponent, {
      disableClose: true,
      width: "550px",
      height: "300px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Confirm") this.getStaffGroup();
      else {
        if (!SessionCheck(this.router)) {
          this.thisDialogRef.close("Cancel");
          return;
        }
      }
    });
  }

  /////////////////*********Edit Staff group******/
  editStaffGroupdata(Groupid: number, Groupname: string, description: string) {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    const StaffGroupDetail = {
      GroupId: Groupid,
      Groupname: Groupname,
      Description: description,
    };
    const dialogRef = this.dialog.open(EditStaffGroupComponent, {
      disableClose: true,
      width: "550px",
      height: "300px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: StaffGroupDetail,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Confirm") this.getStaffGroup();
      else {
        if (!SessionCheck(this.router)) {
          this.thisDialogRef.close("Cancel");
          return;
        }
      }
    });
  }

  ////////////////////Delete Resident Group////////////

  deleteStaffGroupdata(Groupid: number) {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    this.getGroupNotificationInfo(Groupid);
  }
  getGroupNotificationInfo(Groupid) {
    try {
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to delete this Group?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          const DeleteStaffgroup: getGroupNotification = {
            authKey: this.authkey,
            groupId: parseInt(Groupid),
            groupType: 1,
          };
          this.apiService
            .getGroupNotificationInfo(DeleteStaffgroup)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apigroupresponse: any[]) => {
              if (apigroupresponse.toString() == "Success") {
                this.deleteStaffGroup(Groupid);
              } else {
                generateAlertMessage(
                  this.dialog,
                  "This '" +
                    apigroupresponse[0].GroupName +
                    "' is in escalation level, so please remove this group from escalation and try again."
                );
              }
            });
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "delete-staff-group.ts" +
          " - getGroupNotificationInfo() - " +
          err.message
      );
    }
  }

  deleteStaffGroup(Groupid) {
    const DeleteStaffgroup: deleteStaffGroup = {
      authKey: this.authkey,
      groupId: Groupid.toString(),
    };
    this.staffService
      .deleteStaffGroup(DeleteStaffgroup)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: StaffGroupForCreationResponse[]) => {
        if (apiresponse["apiReturn"] == "Success") {
          generateAlertMessage(
            this.dialog,
            "Staff group deleted successfully."
          );
        } else if (apiresponse["apiReturn"] == "Fail") {
          generateAlertMessage(this.dialog, "Staff group deleted failed.");
        }
        else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
          this.thisDialogRef.close("close");
          AuthFailedMessage(this.dialog, this.router);
          return;
        }
         else {
          generateAlertMessage(this.dialog, apiresponse.toString());
        }
        this.getStaffGroup();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
