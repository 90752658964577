<app-technologyoverlay-header (ongetcampus)="getCampusdetails($event)"></app-technologyoverlay-header>
  <!-- <mat-card> -->
    <div *ngIf="floorloading" style="display: table;
    position: absolute;
    height:100%; 
    width:100%;  
    z-index: 1000000;
    text-align: center;
">
<div style="display: table-cell;
    vertical-align: middle;">
    <div style="margin-left: calc(50% - 41px);
    
    color: White;">
       <div class="loadspinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
</div>
</div>
  <!-- </mat-card> -->
<mat-card class="technologyoverlay-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-technologyoverlay-leftbar></app-technologyoverlay-leftbar>
    </div>

    <div fxLayout="column" fxFlex="100" id="divfloorplandetails" *ngIf="chkbuildmapflag == 0">

      <perfect-scrollbar class="clsScrollbar">

        <div class="clsfacilitycard">
          <mat-card-title style="font-size: 20px;margin-top:15px;margin-left: 0.8em;">Provider</mat-card-title>
          <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
          <mat-nav-list *ngFor="let Facility of Facility" class="clsimgnext" layout="row flex">
          <mat-list-item>
          <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true"
            class="clsediticon">
            <div id="container">
              <img mat-line [src]="FacilityImgurl+Facility.FacilityImg"
                style="width: 130px; border: 1px solid #d2d4d8; height: 135px;">
              <div class="campuseditIcon">
                <a href="#floorplans" style="display: inline-grid;">
                  <div style="display:flex;"><img src="assets/images/Edit_Tech.png" class="clseditimg"
                      (click)="editFacilitydetails(Facility.FacilityId,Facility.FacilityName,Facility.FacilityImg)" />
                    <label class="clseditimg"
                      (click)="editFacilitydetails(Facility.FacilityId,Facility.FacilityName,Facility.FacilityImg)">Edit</label>
                  </div>
                  <!-- <p class="hover-text">Hover text here</p> -->
                  <!-- <img src="assets/images/Duplicate_Tech.png" class="clseditimg" style="margin-top:5px;" (click)="duplicateCampusdetails(Campus.CampusId,FacilityName)"/> -->
                </a>
              </div>
              <div class="campusedit" style="z-index: 1000; cursor: auto;"></div>
            </div>
            <!-- <h4 class="label">{{Facility.FacilityName}}</h4>     -->
            <h4 class="label" *ngIf="Facility.FacilityName.length < 20">{{Facility.FacilityName}}</h4>
            <h4 class="label" *ngIf="Facility.FacilityName.length >= 20">
              <div class="floortooltip">{{Facility.FacilityName.slice(0,20) + ".."}}<span
                  class="floortooltiptext">{{Facility.FacilityName}}</span></div>
            </h4>
          </mat-icon>
          </mat-list-item>
        </mat-nav-list>
        </mat-list>
        </div>        
        
        <div>
        <div *ngIf="Campus && Campus.length > 0">
        <div class="clscampuscard" id="Divmatcontent" *ngFor="let Campus1 of Campus; let i = index;">
          
          <div fxLayout="row" style="border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">
          
            <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">
              
              <mat-card-actions class="clsmatcardaction">
                <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">Campus ({{CampusCnt}})  
                               
                <button class="clsdeselectbutton" id="camp_btndeselect" [style.display]="camp_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(1)">Select</button>
                </mat-card-title>
              </mat-card-actions>
              
              <mat-card-content *ngIf="checkicon == i ? arrowiconT : arrowiconF">
                
                <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover to choose Campus and view associated buildings or edit current selection. Click Add Campus to continue building your facility.</span></div>
                
                <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
                  <mat-nav-list class="clsimgnext" *ngIf="bAddEdit == true">
                    <mat-list-item>
                      <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">                   
                            <div id="container" style="border:2px solid #d2d4d8" (click)="addCampus(FacilityName)"><img class="clsimage" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" />                        
                            </div>  
                            <div class="add"><img src="assets/images/AddNew.png" (click)="addCampus(FacilityName)"/></div>                                          
                          <h4 class="label">Add Campus</h4>               
                      </mat-icon>
                    </mat-list-item>
                  </mat-nav-list>  
                  
                  <mat-nav-list *ngFor="let Campus of Campus;" class="clsimgnext">
                    <mat-list-item>
                    <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">
                      <div id="container"
                        [ngStyle]="{'border': selectedcampus === Campus.CampusId ? '2px solid #F49542' : '2px solid #d2d4d8'}">
                        <img class="clsimage" [src]="campusImgUrl + Campus.FloorPlanImage"
                          [ngStyle]="{'transform': 'scale('+Campus.ZoomLevel+') rotate('+ Campus.RotateLevel +'deg) translateX('+ Campus.TransformX +'px) translateY(' + Campus.TransformY +'px)'}" />
                        <div class="campuseditIcon">
                          <a href="#floorplans" style="display: inline-grid;">
                            <div style="display:flex;"><img src="assets/images/Edit_Tech.png" class="clseditimg"
                                (click)="editCampusdetails(Campus.CampusId,FacilityName)" />
                              <label class="clseditimg" (click)="editCampusdetails(Campus.CampusId,FacilityName)">Edit/<br>Delete</label>
                            </div>
                            <!-- <p class="hover-text">Hover text here</p> -->
                            <!-- <img src="assets/images/Duplicate_Tech.png" class="clseditimg" style="margin-top:5px;" (click)="duplicateCampusdetails(Campus.CampusId,FacilityName)"/> -->
                          </a>
                        </div>
                        <div class="campusedit" (click)="getBuildingDetails(Campus.CampusId, Campus.CampusName)" style="z-index: 1000;"></div>
                        <!-- <div class="edit"><a href="#floorplans"><img src="assets/images/Duplicate_Tech.png" class="clseditimg" (click)="duplicateCampusdetails(Campus.CampusId,FacilityName)"/></a></div> -->
                      </div>
                       
                       <h4 class="label" *ngIf="Campus.CampusName.length < 20">{{Campus.CampusName}}</h4>                   
                       <h4 class="label" *ngIf="Campus.CampusName.length >= 20"><div class="floortooltip">{{Campus.CampusName.slice(0,20) + ".."}}<span class="floortooltiptext">{{Campus.CampusName}}</span></div></h4>                 
                    </mat-icon>
                  </mat-list-item>
                  </mat-nav-list>
                  
                </mat-list>                              
                
              </mat-card-content>
            </div>
            
          </div>
          
        </div>
      </div>
      
      <div *ngIf="Campus && Campus.length == 0">
        <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;border-bottom: 2px solid #d5d4d4;">
              
          <mat-card-actions class="clsmatcardaction">
            <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" >Campus ({{CampusCnt}})  
            
            <!--<button mat-button style="float:right;">
              <mat-icon >{{collapsedIcon1}}</mat-icon>
              <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon}}</mat-icon> 
            </button>     -->
            
            <button class="clsdeselectbutton" id="camp_btndeselect" [style.display]="camp_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(1)">Select</button>
            </mat-card-title>
          </mat-card-actions>
          
          <mat-card-content>
            
            <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover to choose Campus and view associated buildings or edit current selection. Click Add Campus to continue building your facility.</span></div>
            
            <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
              <mat-nav-list class="clsimgnext" *ngIf="bAddEdit == true">
                <mat-list-item>
                  <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">                   
                        <div id="container" style="border:2px solid #d2d4d8" (click)="addCampus(FacilityName)"><img class="clsimage" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" />                        
                        </div>  
                        <div class="add"><img src="assets/images/AddNew.png" (click)="addCampus(FacilityName)"/></div>                                          
                      <h4 class="label">Add Campus</h4>               
                  </mat-icon>
                </mat-list-item>
              </mat-nav-list>                                           
            </mat-list>                              
            
          </mat-card-content>
        </div>
      </div>
      
        </div>
        
        <!----------------------BUILDING------------------------->
        <div *ngIf="selectedcampus > 0">
        
        <div *ngIf="Building && Building.length > 0">
        <div class="clscampuscard" id="DivBuildingmatcontent" *ngFor="let Building1 of Building; let i = index;">
          
          <div fxLayout="row" style="border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">
          
            <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">
              
              <mat-card-actions class="clsmatcardaction">
               
                <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">
                  <div style="width:50%;display: inline-block;">
                  <div *ngIf="buildingcampusname.length == 0" class="floortooltip">Buildings ({{BuildingCnt}})</div>
                  <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length < 15" class="floortooltip">Buildings ({{BuildingCnt}}) - {{buildingcampusname}}</div>
                  <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length >= 15" class="floortooltip">Buildings ({{BuildingCnt}}) - {{buildingcampusname}}<span class="floortooltiptext">{{buildingcampusfullname}}</span></div>          
                </div>
                <div style="width: 15%;float:right;display: contents;">
                  <!--<button mat-button style="float:right;">
                    <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_build}}</mat-icon>
                    <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_build}}</mat-icon>
                  </button>  -->
                  <button class="clsdeselectbutton" id="build_btndeselect" [style.display]="build_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(2)">Select</button>
                </div>
                </mat-card-title>
                
              </mat-card-actions>
              
              <mat-card-content *ngIf="checkicon == i ? build_arrowiconT : build_arrowiconF">
                
                <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover to choose Building and view associated floors or edit current selection. Click Add Building to continue building your facility.</span></div>
                
                <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
                  <mat-nav-list class="clsimgnext" *ngIf="bAddEdit == true">
                    <mat-list-item>
                      <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">                   
                            <div id="container" style="border:2px solid #d2d4d8" (click)="addBuilding()"><img class="clsimage" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" />                        
                            </div>  
                            <div class="add"><img src="assets/images/AddNew.png" (click)="addBuilding()"/></div>                                          
                          <h4 class="label">Add Building</h4>               
                      </mat-icon>
                    </mat-list-item>
                  </mat-nav-list>  
                  
                  <mat-nav-list *ngFor="let Building of Building" class="clsimgnext">              
                    <mat-list-item>
                        <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">
                            <div id="container" [ngStyle]="{'border': selectedbuilding === Building.BuildId ? '2px solid #F49542' : '2px solid #d2d4d8'}"  class="clsBuildingsplanimg">
                              <!-- <div id="mapBuild_{{Building.BuildId}}" class="clsimage map" style="margin:0px; width:100%; height: 100%;">
                              </div> -->
                              <img class="clsimage" [src]="ImageUrl"
                          [ngStyle]="{'transform': 'scale('+CZoomLevel+') rotate('+ CRotateLevel +'deg) translateX('+ TranslateX +'px) translateY(' + TranslateY +'px)'}" />
                              <!-- <div class="buildedit"><a href="#floorplans"><img src="assets/images/Edit-Popups-White.png" class="clseditimg" (click)="editBuildingdetails(Building.BuildId)"/> </a></div> -->
                              
                              <div class="buildeditIcon">
                                <a href="#floorplans" style="display: inline-grid;">
                                  <div style="display:flex;">
                                    <img src="assets/images/Edit_Tech.png" class="clseditimg" title="Edit"
                                      (click)="editBuildingdetails(Building.BuildId)" />
                                    <label class="clseditimg"
                                      (click)="editBuildingdetails(Building.BuildId)">Edit/<br>Delete</label>
                                  </div>
                                  <div style="display:flex;" *ngIf="bAddEdit == true">
                                    <img src="assets/images/Duplicate_Tech.png" class="clseditimg"
                                      style="margin-top:5px;" (click)="duplicateBuildingdetails(Building.BuildId,Building.CampusId)" />
                                    <label class="clseditimg"
                                      (click)="duplicateBuildingdetails(Building.BuildId,Building.CampusId)">Duplicate</label>
                                  </div>
                                </a>
                              </div>
                              <div class="buildedit" (click)="getZonedetails(Building.BuildId, Building.BuildingName)"></div>
                            </div>                     
                            
                          <h4 class="label" *ngIf="Building.BuildingName.length < 20">{{Building.BuildingName}}</h4>                   
                         <h4 class="label" *ngIf="Building.BuildingName.length >= 20"><div class="floortooltip">{{Building.BuildingName.slice(0,20) + ".."}}<span class="floortooltiptext">{{Building.BuildingName}}</span></div></h4>
                        </mat-icon>               
                    </mat-list-item>
                  </mat-nav-list>
                  
                </mat-list>                              
                
              </mat-card-content>
            </div>
            
          </div>
          
        </div>
      </div>
        
        <div *ngIf="Building && Building.length == 0">
          <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;border-bottom: 2px solid #d5d4d4;">
              
            <mat-card-actions class="clsmatcardaction">
             
              <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
                <div style="width:50%;display: inline-block;">
                <div *ngIf="buildingcampusname.length == 0" class="floortooltip">Buildings ({{BuildingCnt}})</div>
                <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length < 15" class="floortooltip">Buildings ({{BuildingCnt}}) - {{buildingcampusname}}</div>
                <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length >= 15" class="floortooltip">Buildings ({{BuildingCnt}}) - {{buildingcampusname}}<span class="floortooltiptext">{{buildingcampusfullname}}</span></div>          
              </div>
              <div style="width: 15%;float:right;display: contents;">
                <!--<button mat-button style="float:right;">
                  <mat-icon >{{collapsedIcon1_build}}</mat-icon>
                   <mat-icon >{{collapsedIcon_build}}</mat-icon> 
                </button>  -->
                <button class="clsdeselectbutton" id="build_btndeselect1" [style.display]="build_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(2)">Select</button>
              </div>
              </mat-card-title>
              
            </mat-card-actions>
            
            <mat-card-content >
              
              <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover to choose Building and view associated floors or edit current selection. Click Add Building to continue building your facility.</span></div>
              
              <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
                <mat-nav-list class="clsimgnext" *ngIf="bAddEdit == true">
                  <mat-list-item>
                    <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">                   
                          <div id="container" style="border:2px solid #d2d4d8" (click)="addBuilding()"><img class="clsimage" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" />                        
                          </div>  
                          <div class="add"><img src="assets/images/AddNew.png" (click)="addBuilding()"/></div>                                          
                        <h4 class="label">Add Building</h4>               
                    </mat-icon>
                  </mat-list-item>
                </mat-nav-list>                                  
                
              </mat-list>                              
              
            </mat-card-content>
          </div>
        </div>
        
      </div>
      
      <div *ngIf="selectedcampus == 0" class="clscampuscard" style="width:100%;border-bottom: 2px solid #d5d4d4;">
        
        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
          <div style="width:90%;display: inline-block;">
          <div *ngIf="buildingcampusname.length == 0" class="floortooltip">Buildings ({{BuildingCnt}})</div>
          <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length < 15" class="floortooltip">Buildings ({{BuildingCnt}}) - {{buildingcampusname}}</div>
          <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length >= 15" class="floortooltip">Buildings ({{BuildingCnt}}) - {{buildingcampusname}}<span class="floortooltiptext">{{buildingcampusfullname}}</span></div>          
          </div>
          <div style="width: 10%;float:right;">
          <!--<button mat-button style="float:right;">
            <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_build}}</mat-icon>
            <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_build}}</mat-icon>
          </button>  -->
        </div>
        </mat-card-title>                  
      
      </div>
      <!----------------------BUILDING END------------------------->
      
      <!----------------------FLOOR------------------------->
      
      <div *ngIf="selectedbuilding > 0">
        <div *ngIf="Floor && Floor.length > 0">
        <div class="clscampuscard" id="DivFloormatcontent" *ngFor="let Floor1 of Floor; let i = index;">
          
          <div fxLayout="row" style="width: 100%;border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">
          
            <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">
              
              <mat-card-actions class="clsmatcardaction">
               
                <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">
                  <div style="width:50%;display: inline-flex;">
                  <div *ngIf="floorbuildingname.length == 0" class="floortooltip">Zones ({{FloorCnt}})</div>
                  <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length < 15" class="floortooltip">Zones  ({{FloorCnt}}) - {{floorbuildingname}} , </div>
                  <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length >= 15" class="floortooltip">Zones  ({{FloorCnt}}) - {{floorbuildingname}} ,<span class="floortooltiptext">{{floorbuildingfullname}}</span></div>          
                  
                  <div *ngIf="floorbuildingname.length > 0 && buildingcampusname.length < 15" class="floortooltip"> {{buildingcampusname}}  </div>
                  <div *ngIf="floorbuildingname.length > 0 && buildingcampusname.length >= 15" class="floortooltip"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
                </div>
                <div style="width: 15%;float:right;display: contents;">
                  <!--<button mat-button style="float:right;">
                    <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_floor}}</mat-icon>
                    <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_floor}}</mat-icon>
                  </button>-->
                  <button class="clsdeselectbutton" id="floor_btndeselect" [style.display]="floor_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(3)">Select</button>
                </div>
                </mat-card-title>
                
              </mat-card-actions>
              
              <mat-card-content *ngIf="checkicon == i ? floor_arrowiconT : floor_arrowiconF">
                
                <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Click Add Zone to create a zone within a building. Hover/Click Edit/Delete to edit or delete a zone. Hover/Click Create Sub-Zone to create a sub-zone.</span></div>
                
                <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">                 
	                      
                  <!-- <div fxLayout="row"> -->
                    <div #rowLayout cdkDropListGroup fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">                     
                      
                      <mat-nav-list fxLayout="row" *ngFor="let FloorDetails of getItemsTable(3) let i = index" cdkDropList 
                      cdkDropListOrientation="horizontal" [cdkDropListData]="FloorDetails" class="example-list" (cdkDropListDropped)="reorderDroppedItem($event,3)">
                      
                      
                      <mat-list-item class="example-box-add" *ngIf="bAddEdit == true && i == 0">
                        <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">                   
                              <div id="container" style="border:2px solid #d2d4d8" (click)="addFloor()"><img class="clsimage" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" />                        
                              </div>  
                              <div class="add"><img src="assets/images/AddNew.png" (click)="addFloor()"/></div>                                          
                            <h4 class="label">Add Zone</h4>               
                        </mat-icon>
                      </mat-list-item>
                      
                        <mat-list-item class="example-box" *ngFor="let Floor of FloorDetails let i = index;" cdkDrag>
                                                  
                          <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">                                                         
                              
                              <div id="container" class="clsdivcontainer" [ngStyle]="{'border': selectedfloor === Floor.ZoneId ? '2px solid #F49542' : '2px solid #d2d4d8'}">
                                <img class="clsimage" [src]="floorImgUrl + Floor.FloorPlanImage" (click)="getUnitorSubZoneDetails(Floor)"
                                  [ngStyle]="{'transform': 'scale('+Floor.FZoomLevel+') rotate('+ Floor.FRotateLevel +'deg) translateX('+ Floor.FTransformX +'px) translateY(' + Floor.FTransformY +'px)'}" />
                                <div class="flooreditIcon"><a href="#floorplans" style="display: inline-grid;">
                                    <div style="display:flex;">
                                      <img src="assets/images/Edit_Tech.png" class="clseditimg" (click)="editFloordetails(Floor.ZoneId)" />
                                      <label class="clseditimg" (click)="editFloordetails(Floor.ZoneId)">Edit/<br>Delete </label>
                                    </div>
                                    
                                    <div style="display:flex;" *ngIf="bAddEdit == true && Floor.isZoneFloor == 1">
                                      <img src="assets/images/Edit_Tech.png" class="clseditimg" (click)="addEditSubZonedetails(Floor.ZoneId,0,'',1)" style="margin-top: 0.2em;"/>
                                      <label class="clseditimg" (click)="addEditSubZonedetails(Floor.ZoneId,0,'',1)">Create<br>Sub-Zone</label>
                                    </div>
                                    
                                    <div style="display:flex;" *ngIf="bAddEdit == true && Floor.isZoneFloor == 0">
                                      <img src="assets/images/Duplicate_Tech.png" class="clseditimg" style="margin-top:5px;"
                                        (click)="clkduplicateFloordetails(Floor.ZoneId,Floor.ZoneName)" />
                                      <label class="clseditimg" (click)="clkduplicateFloordetails(Floor.ZoneId,Floor.ZoneName)">Duplicate</label>
                                    </div>
                                    <div style="display:flex;" *ngIf="bAddEdit == false && bAddEditTemplate == true">
                                      <img src="assets/images/Duplicate_Tech.png" class="clseditimg" style="margin-top:5px;"
                                        (click)="clkduplicateFloordetails(Floor.ZoneId,Floor.ZoneName)" />
                                      <label class="clseditimg" (click)="clkduplicateFloordetails(Floor.ZoneId,Floor.ZoneName)">Template</label>
                                    </div>
                                  </a></div>
                                  <div class="flooredit" (click)="getUnitorSubZoneDetails(Floor)" style="z-index: 1000;"></div>
                              </div>
                              
                              <h4 class="label" *ngIf="Floor.ZoneName.length < 20">{{Floor.ZoneName}}</h4>
                              <h4 class="label" *ngIf="Floor.ZoneName.length >= 20"><div class="floortooltip">{{Floor.ZoneName}}<span class="floortooltiptext">{{Floor.ZoneName}}</span></div></h4>
                              <h2 class="subzone" *ngIf="Floor.isZoneFloor == 1">({{Floor.subzonecount}}) Sub-Zones</h2>
                          </mat-icon>
                        </mat-list-item>
                                              
                        
                      </mat-nav-list>    
                
                  </div>
                <!-- </div> -->
                  
                </mat-list>                              
                
              </mat-card-content>
            </div>
            
          </div>
          
        </div>
      </div>
      
      <div *ngIf="Floor && Floor.length == 0">
        <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;border-bottom: 2px solid #d5d4d4;">
              
          <mat-card-actions class="clsmatcardaction">
           
            <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
              <div style="width:50%;display: inline-flex;">
              <div *ngIf="floorbuildingname.length == 0" class="floortooltip">Zones ({{FloorCnt}})</div>
              <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length < 15" class="floortooltip">Zones  ({{FloorCnt}}) - {{floorbuildingname}} , </div>
              <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length >= 15" class="floortooltip">Zones  ({{FloorCnt}}) - {{floorbuildingname}} ,<span class="floortooltiptext">{{floorbuildingfullname}}</span></div>          
              
              <div *ngIf="floorbuildingname.length > 0 && buildingcampusname.length < 15" class="floortooltip"> {{buildingcampusname}}  </div>
              <div *ngIf="floorbuildingname.length > 0 && buildingcampusname.length >= 15" class="floortooltip"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
            </div>
            <div style="width: 15%;float:right;display: contents;">
              <!--<button mat-button style="float:right;">
                <mat-icon>{{collapsedIcon1_floor}}</mat-icon>
                 <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_floor}}</mat-icon> 
              </button>  -->
              <button class="clsdeselectbutton" id="floor_btndeselect" [style.display]="floor_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(3)">Select</button>
            </div>
            </mat-card-title>
            
          </mat-card-actions>
          
          <mat-card-content>
            
            <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Click Add Zone to create a zone within a building. Hover/Click Edit/Delete to edit or delete a zone. Hover/Click Create Sub-Zone to create a sub-zone.</span></div>
            
            <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
              <mat-nav-list class="clsimgnext" *ngIf="bAddEdit == true">
                <mat-list-item>
                  <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">
                    <div id="container" style="border:2px solid #d2d4d8" (click)="addFloor()"><img class="clsimage" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}"/> </div> 
                    <div class="add"><img src="assets/images/AddNew.png" (click)="addFloor()"/></div> 
                      <h4 class="label">Add Zone</h4>               
                  </mat-icon>
                </mat-list-item>
              </mat-nav-list>                           
              
            </mat-list>                              
            
          </mat-card-content>
        </div>
      </div>
      
      </div>
      
      <div *ngIf="selectedbuilding == 0" class="clscampuscard" style="width:100%;border-bottom: 2px solid #d5d4d4;">
        
        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
          <div style="width:90%;display: inline-block;">
          <div *ngIf="floorbuildingname.length == 0" class="floortooltip">Zones ({{FloorCnt}})</div>
          <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length < 15" class="floortooltip">Zones  ({{FloorCnt}}) - {{floorbuildingname}} , </div>
          <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length >= 15" class="floortooltip">Zones  ({{FloorCnt}}) - {{floorbuildingname}} ,<span class="floortooltiptext">{{floorbuildingfullname}} , </span></div>          
        
          <div *ngIf="floorbuildingname.length > 0 && buildingcampusname.length < 15" class="floortooltip"> {{buildingcampusname}}  </div>
          <div *ngIf="floorbuildingname.length > 0 && buildingcampusname.length >= 15" class="floortooltip"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
        
        </div>
          <div style="width: 10%;float:right;">
          <!--<button mat-button style="float:right;">
            <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_floor}}</mat-icon>
            <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_floor}}</mat-icon>
          </button>  -->
        </div>
        </mat-card-title>                  
      
      </div>
      
      <!----------------------FLOOR END------------------------------>  
      
      
      <!--------------------------SUBZONE----------------------------------------->      
      <div *ngIf="selectedfloor > 0 && isZoneFloor == 1">
        <div *ngIf="SubZones.length > 0">
        <div class="clscampuscard" id="DivFloormatcontent" *ngFor="let SubZone1 of SubZones; let i = index;">
          
          <div fxLayout="row" style="width: 100%;border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">
          
            <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">
              
              <mat-card-actions class="clsmatcardaction">
               
                <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">
                  <div style="width:50%;display: inline-flex;">
                  <div *ngIf="unitfloorname.length == 0" class="floortooltip">Sub-Zones ({{SubZoneCnt}})</div>
                  <div *ngIf="unitfloorname.length > 0 && unitfloorname.length < 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{unitfloorname}} </div>
                  <div *ngIf="unitfloorname.length > 0 && unitfloorname.length >= 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{unitfloorname}}<span class="floortooltiptext">{{unitfloorfullname}}</span></div>          
                  
                  <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length < 15" class="floortooltip clsmapname"> ,{{floorbuildingname}} ,  </div>
                  <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length >= 15" class="floortooltip clsmapname"> ,{{floorbuildingname}} , <span class="floortooltiptext">{{floorbuildingfullname}}</span></div>
                  
                  <div *ngIf="floorbuildingname.length > 0 && buildingcampusname.length < 15" class="floortooltip clsmapname"> {{buildingcampusname}}  </div>
                  <div *ngIf="floorbuildingname.length > 0 && buildingcampusname.length >= 15" class="floortooltip clsmapname"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
                </div>
                <div style="width: 15%;float:right;display: contents;">
                  <!--<button mat-button style="float:right;">
                    <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_floor}}</mat-icon>
                    <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_floor}}</mat-icon>
                  </button>-->
                  <button class="clsdeselectbutton" id="subzone_btndeselect" [style.display]="subzone_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(4)">Select</button>
                </div>
                </mat-card-title>
                
              </mat-card-actions>
              
              <mat-card-content *ngIf="checkicon == i ? subzone_arrowiconT : subzone_arrowiconF">
                
                <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover/Click Edit Sub-Zone to edit. Hover/click larger box to create/edit Spaces. Click Add Sub-Zone to create a Sub-Zone within a Zone.</span></div>
                
                <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">                 
	                      
                  <!-- <div fxLayout="row"> -->
                    <div #rowLayout cdkDropListGroup fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">                     
                      
                      <mat-nav-list fxLayout="row" *ngFor="let SubZoneDetails of getSubZoneItemsTable(4) let i = index" cdkDropList 
                      cdkDropListOrientation="horizontal" [cdkDropListData]="SubZoneDetails" class="example-list" (cdkDropListDropped)="reorderDroppedItem($event,4)">
                      
                      
                      <mat-list-item class="example-box-add" *ngIf="bAddEdit == true && i == 0">
                        <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">
                          <div id="container" style="border:2px solid #d2d4d8" (click)="addEditSubZonedetails(0,0,'',2)"><img class="clsimage" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}"/> </div> 
                          <div class="add"><img src="assets/images/AddNew.png" (click)="addEditSubZonedetails(0,0,'',2)"/></div> 
                            <h4 class="label">Add sub-Zone</h4>               
                        </mat-icon>
                      </mat-list-item>
                      
                        <mat-list-item class="example-box" *ngFor="let SubZone of SubZoneDetails let i = index;" cdkDrag>
                                                  
                          <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">                                                         
                              
                            <div id="container" class="clsdivcontainer" [ngStyle]="{'border': selectedsubzone === SubZone.SubZoneId ? '2px solid #F49542' : '2px solid #d2d4d8'}">
                              <img class="clsimage" [src]="ImageUrl"
                                [ngStyle]="{'transform': 'scale('+Floor.FZoomLevel+') rotate('+ Floor.FRotateLevel +'deg) translateX('+ Floor.FTransformX +'px) translateY(' + Floor.FTransformY +'px)'}" />
                              <div class="flooreditIcon"><a href="#floorplans" style="display: inline-grid;">
                                  <div style="display:flex;">
                                    <img src="assets/images/Edit_Tech.png" class="clseditimg" (click)="addEditSubZonedetails(SubZone.ZoneId,SubZone.SubZoneId,SubZone.SubZoneDesc,2)" />
                                    <label class="clseditimg" (click)="addEditSubZonedetails(SubZone.ZoneId,SubZone.SubZoneId,SubZone.SubZoneDesc,2)">Edit/<br>Delete</label>
                                  </div>                                                            
                                </a></div>
                                
                                <div class="flooredit" (click)="getSubZoneUnitDetails(SubZone)" style="z-index: 1000;"></div>
                            </div>
                              
                            <h4 class="label" *ngIf="SubZone.SubZoneDesc.length < 20">{{SubZone.SubZoneDesc}}</h4>                   
                            <h4 class="label" *ngIf="SubZone.SubZoneDesc.length >= 20"><div class="floortooltip">{{SubZone.SubZoneDesc.slice(0,20) + ".."}}<span class="floortooltiptext">{{SubZone.SubZoneDesc}}</span></div></h4>
                          </mat-icon>
                        </mat-list-item>
                                              
                        
                      </mat-nav-list>    
                
                  </div>
                <!-- </div> -->
                  
                </mat-list> 
                
                
              </mat-card-content>
            </div>
            
          </div>
          
        </div>
      </div>
      
      <div *ngIf="SubZones.length == 0 || SubZones.length == undefined">
        <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;border-bottom: 2px solid #d5d4d4;">
              
          <mat-card-actions class="clsmatcardaction">
           
            <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
              <div style="width:50%;display: inline-flex;">
              <div *ngIf="unitfloorname.length == 0" class="floortooltip">Sub-Zones ({{SubZoneCnt}})</div>
              <div *ngIf="unitfloorname.length > 0 && unitfloorname.length < 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{unitfloorname}} </div>
              <div *ngIf="unitfloorname.length > 0 && unitfloorname.length >= 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{unitfloorname}}<span class="floortooltiptext">{{unitfloorfullname}}</span></div>          
              
              <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length < 15" class="floortooltip clsmapname">,{{floorbuildingname}} , </div>
              <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length >= 15" class="floortooltip clsmapname">,{{floorbuildingname}} , <span class="floortooltiptext">{{floorbuildingfullname}} ,</span></div>
              
              <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length < 15" class="floortooltip clsmapname"> {{buildingcampusname}}  </div>
              <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length >= 15" class="floortooltip clsmapname"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
            </div>
            <div style="width: 15%;float:right;display: contents;">
              <!--<button mat-button style="float:right;">
                <mat-icon>{{collapsedIcon1_floor}}</mat-icon>
                 <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_floor}}</mat-icon> 
              </button>  -->
              <button class="clsdeselectbutton" id="subzone_btndeselect" [style.display]="subzone_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(4)">Select</button>
            </div>
            </mat-card-title>
            
          </mat-card-actions>
          
          <mat-card-content>
            
            <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover/Click Edit Sub-Zone to edit. Hover/click larger box to create/edit Spaces. Click Add Sub-Zone to create a Sub-Zone within a Zone.</span></div>
            
            <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
              <mat-nav-list class="clsimgnext" *ngIf="bAddEdit == true">
                <mat-list-item>
                  <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">
                    <div id="container" style="border:2px solid #d2d4d8" (click)="addEditSubZonedetails(0,0,'',2)"><img class="clsimage" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}"/> </div> 
                    <div class="add"><img src="assets/images/AddNew.png" (click)="addEditSubZonedetails(0,0,'',2)"/></div> 
                      <h4 class="label">Add Sub-Zone</h4>               
                  </mat-icon>
                </mat-list-item>
              </mat-nav-list>                           
              
            </mat-list>                              
            
          </mat-card-content>
        </div>
      </div>
      
      </div>
      
      <div *ngIf="selectedfloor == 0" class="clscampuscard" style="width:100%;border-bottom: 2px solid #d5d4d4;">
        
        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
          <div style="width:90%;display: inline-block;">
          <div *ngIf="unitfloorname.length == 0" class="floortooltip">Sub-Zones ({{SubZoneCnt}})</div>
          <div *ngIf="unitfloorname.length > 0 && unitfloorname.length < 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{unitfloorname}} </div>
          <div *ngIf="unitfloorname.length > 0 && unitfloorname.length >= 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{unitfloorname},<span class="floortooltiptext">{{unitfloorfullname}} , </span></div>          
        
          <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length < 15" class="floortooltip clsmapname">,{{floorbuildingname}}, </div>
          <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length >= 15" class="floortooltip clsmapname">,{{floorbuildingname}}, <span class="floortooltiptext">{{floorbuildingfullname}} ,</span></div>
          
          <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length < 15" class="floortooltip clsmapname"> {{buildingcampusname}}  </div>
          <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length >= 15" class="floortooltip clsmapname"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
        
        </div>
          <div style="width: 10%;float:right;">
          <!--<button mat-button style="float:right;">
            <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_floor}}</mat-icon>
            <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_floor}}</mat-icon>
          </button>  -->
        </div>
        </mat-card-title>                  
      
      </div>
      
      <div *ngIf="isZoneFloor == 1 && SubZones.length > 0 && UnitInfo.length == 0" class="clscampuscard" style="width:100%;border-bottom: 2px solid #d5d4d4;">
        
        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
          <div style="width:90%;display: inline-flex;">
          <div class="floortooltip">Spaces ({{UnitCnt}})</div>          
        </div>
          <div style="width: 10%;float:right;">         
        </div>
        </mat-card-title>                  
      
      </div>
      
      <!--------------------------SUBZONE END------------------------------------->

       <!----------------------UNIT------------------------->
      
      <div *ngIf="selectedfloor > 0">
        <div *ngIf="UnitInfo && UnitInfo.length > 0">
        <div class="clscampuscard" id="DivUnitmatcontent" *ngFor="let Unit1 of UnitInfo; let i = index;">
          
          
          <div fxLayout="row" style="border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0 && isZoneFloor == 0">
            <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;"> 
            <mat-card-actions class="clsmatcardaction">               
              <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">
                <div style="width:50%;display: inline-flex;">
                <div class="floortooltip">Sub-Zones ({{SubZoneCnt}})</div>                
              </div>             
              </mat-card-title>              
            </mat-card-actions>
            </div>
          </div>
          
          <div fxLayout="row" style="border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">
          
            <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">                                          
              
              <mat-card-actions class="clsmatcardaction">
               
                <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">
                  <div style="width:50%;display: inline-flex;">
                  <div *ngIf="(unitfloorname.length == 0 && unitsubzonename == '') || (unitsubzonename.length == 0 && unitsubzonename != '')" class="floortooltip">Spaces ({{UnitCnt}})</div>
                  <!-- <div *ngIf="unitsubzonename.length == 0 && unitsubzonename != ''" class="floortooltip">Spaces ({{UnitCnt}})</div> -->
                  <!-- <div *ngIf="unitfloorname.length > 0 && unitfloorname.length < 15" class="floortooltip">Spaces ({{UnitCnt}}) - {{unitfloorname}} , </div>
                  <div *ngIf="unitfloorname.length > 0 && unitfloorname.length >= 15" class="floortooltip">Spaces ({{UnitCnt}}) - {{unitfloorname}} , <span class="floortooltiptext">{{unitfloorfullname}}</span></div>           -->
                  
                  <div *ngIf="unitsubzonename.length > 0 && unitsubzonename.length < 15 && unitsubzonename != ''" class="floortooltip">Spaces  ({{UnitCnt}}) - {{unitsubzonename}}, </div>
                  <div *ngIf="unitsubzonename.length > 0 && unitsubzonename.length >= 15 && unitsubzonename != ''" class="floortooltip">Spaces  ({{UnitCnt}}) - {{unitsubzonename}} ,<span class="floortooltiptext">{{unitsubzonefullname}}</span></div>          

                  <div *ngIf="unitfloorname.length > 0 && unitfloorname.length < 15 && unitsubzonename != ''" class="floortooltip">{{unitfloorname}} , </div>
                  <div *ngIf="unitfloorname.length > 0 && unitfloorname.length >= 15 && unitsubzonename != ''" class="floortooltip">{{unitfloorname}} ,<span class="floortooltiptext">{{unitfloorfullname}}</span></div> 
                  
                  <div *ngIf="unitfloorname.length > 0 && unitfloorname.length < 15 && unitsubzonename == ''" class="floortooltip clsmapname">Spaces  ({{UnitCnt}}) - {{unitfloorname}} , </div>
                  <div *ngIf="unitfloorname.length > 0 && unitfloorname.length >= 15 && unitsubzonename == ''" class="floortooltip clsmapname">Spaces  ({{UnitCnt}}) - {{unitfloorname}} ,<span class="floortooltiptext">{{unitfloorfullname}}</span></div>                                                               
                  
                  <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length < 15" class="floortooltip clsmapname">{{floorbuildingname}} , </div>
                  <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length >= 15" class="floortooltip clsmapname">{{floorbuildingname}} , <span class="floortooltiptext">{{floorbuildingfullname}} ,</span></div>
                  
                  <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length < 15" class="floortooltip clsmapname"> {{buildingcampusname}}  </div>
                  <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length >= 15" class="floortooltip clsmapname"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
                </div>
                <div style="width: 15%;float:right;display: contents;">
                  <!--<button mat-button style="float:right;">
                    <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_unit}}</mat-icon>
                    <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_unit}}</mat-icon>
                  </button>                    -->
                </div>
                </mat-card-title>
                
              </mat-card-actions>
              
              <mat-card-content *ngIf="checkicon == i ? unit_arrowiconT : unit_arrowiconF">
                
                <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover to choose Space or edit current selection. Click Add Space to continue build areas such as units, common rooms, dining halls, etc.</span></div>
                
                <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
                  
                  <mat-nav-list *ngFor="let Units of UnitInfo" class="clsimgnext">
                    <mat-list-item>              
                      <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">
                          <div id="container" [ngStyle]="{'border': selectedunit === Units.UnitId ? '2px solid #F49542' : '2px solid #d2d4d8'}" class="clsBuildingsplanimg"> <!--(click)="getRooms(Units.UnitId, Units.UnitName)"-->
                          <!-- <div id="mapSpace_{{Units.UnitId}}" class="clsimage map" style="margin:0px; width:100%; height: 100%;">
                          </div> -->
                          <img class="clsimage" [src]="ImageUrl"
                          [ngStyle]="{'transform': 'scale('+CZoomLevel+') rotate('+ CRotateLevel +'deg) translateX('+ TranslateX +'px) translateY(' + TranslateY +'px)'}" />                            
                          <div class="buildeditIcon">
                            <a href="#floorplans" style="display: inline-grid;">
                              <div style="display:flex;">
                                <img src="assets/images/Edit_Tech.png" class="clseditimg"
                                  (click)="editUnitdetails(Units.UnitId)" />
                                <label class="clseditimg" (click)="editUnitdetails(Units.UnitId)">Edit/<br>Delete</label>
                              </div>
                              <div style="display:flex;" *ngIf="bAddEditTemplate == true">
                                <img src="assets/images/Duplicate_Tech.png" class="clseditimg" style="margin-top:5px;"
                                  (click)="templateUnitdetails(Units.UnitId)" />
                                <label class="clseditimg" (click)="templateUnitdetails(Units.UnitId)">Template</label>
                              </div>
                            </a>
                          </div>
                          <div class="buildedit"></div>
                          </div>                                        
                          <h4 class="label" *ngIf="Units.UnitName.length < 20">{{Units.UnitName}}</h4>                   
                         <h4 class="label" *ngIf="Units.UnitName.length >= 20"><div class="floortooltip">{{Units.UnitName.slice(0,20) + ".."}}<span class="floortooltiptext">{{Units.UnitName}}</span></div></h4>
                      </mat-icon>
                    </mat-list-item>
                  </mat-nav-list>
                  
                </mat-list>                              
                
              </mat-card-content>
            </div>
            
          </div>
          
        </div>
      </div>
      
      <div *ngIf="UnitInfo && UnitInfo.length == 0 && isZoneFloor == 0" id="divAddUnit">
        <div fxLayout="row" style="border-bottom: 2px solid #d5d4d4;">
          <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;"> 
          <mat-card-actions class="clsmatcardaction">               
            <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
              <div style="width:50%;display: inline-flex;">
              <div class="floortooltip">Sub-Zones ({{SubZoneCnt}})</div>                
            </div>             
            </mat-card-title>              
          </mat-card-actions>
          </div>
        </div>
        
        <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;border-bottom: 2px solid #d5d4d4;">                        
          <mat-card-actions class="clsmatcardaction">           
            <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" >
              <div style="width:50%;display: inline-flex;">
              <div *ngIf="unitfloorname.length == 0" class="floortooltip">Spaces ({{UnitCnt}})</div>
              <div *ngIf="unitfloorname.length > 0 && unitfloorname.length < 15" class="floortooltip">Spaces ({{UnitCnt}}) - {{unitfloorname}} , </div>
              <div *ngIf="unitfloorname.length > 0 && unitfloorname.length >= 15" class="floortooltip">Spaces ({{UnitCnt}}) - {{unitfloorname}} , <span class="floortooltiptext">{{unitfloorfullname}}</span></div>          
              
              <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length < 15" class="floortooltip clsmapname">{{floorbuildingname}} , </div>
              <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length >= 15" class="floortooltip clsmapname">{{floorbuildingname}} , <span class="floortooltiptext">{{floorbuildingfullname}} ,</span></div>
              
              <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length < 15" class="floortooltip clsmapname"> {{buildingcampusname}}  </div>
              <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length >= 15" class="floortooltip clsmapname"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
            </div>
            
            </mat-card-title>
            <div style="width: 15%;float:right;display: contents;">    
              <span class="clsSpanSpacedata">No spaces available. Add one to the selected zone to have them shown here.</span>                    
            </div>
          </mat-card-actions>                          
        </div>
        
      </div>
      
      </div>
      
      <div *ngIf="selectedfloor == 0 && isZoneFloor == 0" class="clscampuscard" style="width:100%;border-bottom: 2px solid #d5d4d4;">
        
        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
          <div style="width:90%;display: inline-flex;">
          <div *ngIf="unitfloorname.length == 0" class="floortooltip">Spaces ({{UnitCnt}})</div>
          <div *ngIf="unitfloorname.length > 0 && unitfloorname.length < 15" class="floortooltip">Spaces ({{UnitCnt}}) - {{unitfloorname}} , </div>
          <div *ngIf="unitfloorname.length > 0 && unitfloorname.length >= 15" class="floortooltip">Spaces ({{UnitCnt}}) - {{unitfloorname}} <span class="floortooltiptext">{{unitfloorfullname}} , </span></div>          
          
          <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length < 15" class="floortooltip">{{floorbuildingname}} , </div>
          <div *ngIf="unitfloorname.length > 0 && floorbuildingname.length >= 15" class="floortooltip">{{floorbuildingname}} <span class="floortooltiptext">{{floorbuildingfullname}} ,</span></div>
        
          <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length < 15" class="floortooltip"> {{buildingcampusname}}  </div>
          <div *ngIf="unitfloorname.length > 0 && buildingcampusname.length >= 15" class="floortooltip"> {{buildingcampusname}}  <span class="floortooltiptext">{{buildingcampusfullname}}</span></div>
        
        </div>
          <div style="width: 10%;float:right;">
         <!-- <button mat-button style="float:right;">
            <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_unit}}</mat-icon>
            <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_unit}}</mat-icon>
          </button>  -->
        </div>
        </mat-card-title>                  
      
      </div>
      
      <div *ngIf="isZoneFloor == 1 && SubZones.length == 0" class="clscampuscard" style="width:100%;border-bottom: 2px solid #d5d4d4;">
        
        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
          <div style="width:90%;display: inline-flex;">
          <div class="floortooltip">Spaces ({{UnitCnt}})</div>          
        </div>
          <div style="width: 10%;float:right;">         
        </div>
        </mat-card-title>                  
      
      </div>
      
      <!----------------------UNIT END------------------------------>                      

      </perfect-scrollbar>
    </div>                

  </div>
</mat-card>
