<app-notification-header></app-notification-header>
<div *ngIf="notifyloading" style="display: table;
position: absolute;
height:100%; 
width:50%;  
z-index: 1000000;
text-align: center;
">
  <div style="display: table-cell; vertical-align: middle;">
    <div style="margin-left: calc(50% - 41px); color: White;">
      <div class="loadspinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
</div>
<mat-card class="notification-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-notification-leftbar (onNameChanged)="Onalarmclick($event)"></app-notification-leftbar>
    </div>
    <div fxLayout="column" fxFlex="100">
      <perfect-scrollbar class="clsScrollbar">
        <div>
          <div *ngIf="Campus.length > 0">

            <div class="clscampuscard" id="Divmatcontent" *ngFor="let Campus1 of Campus; let i = index;">

              <div fxLayout="row" style="border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">

                <div fxLayout="column" fxLayoutAlign="end" style="width: 100%;">

                  <mat-card-actions class="clsmatcardaction">
                    <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">Campus
                      ({{CampusCnt}})

                      <!--<button mat-button style="float:right;">
                          <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1}}</mat-icon>
                          <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon}}</mat-icon>
                        </button>     -->

                      <button class="clsdeselectbutton" id="camp_btndeselect"
                        [style.display]="camp_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button
                        (click)="clkdeselectmapitem(1)">Select</button>
                    </mat-card-title>
                  </mat-card-actions>

                  <mat-card-content *ngIf="checkicon == 0 ? arrowiconT : arrowiconF">

                    <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover over the campus image and select
                        edit to add or change notification levels. Click on the campus image to view and edit
                        notifications for it's buildings with custom notifications.</span></div>

                    <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">

                      <mat-nav-list *ngFor="let Campus of Campus;" class="clsimgnext" layout="row flex">
                        <mat-list-item>
                          <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true"
                            class="clsediticon">
                            <div id="container"
                              [ngStyle]="{'border': selectedcampus === Campus.CampusId ? '2px solid #F49542' : '2px solid #d2d4d8'}">
                              <img class="clsimage" [src]="campusImgUrl + Campus.FloorPlanImage"
                                [ngStyle]="{'transform': 'scale('+Campus.ZoomLevel+') rotate('+ Campus.RotateLevel +'deg) translateX('+ Campus.TransformX +'px) translateY(' + Campus.TransformY +'px)'}" />
                              <div class="campuseditIcon">
                                <a href="javascript:void(0);" style="display: inline-grid;">
                                  <div style="display:flex;"><img src="assets/images/Edit_Tech.png" class="clseditimg"
                                      (click)="editNotifications(Campus.CampusId,0,0)" />
                                    <label class="clseditimg"
                                      (click)="editNotifications(Campus.CampusId,0,0)">Edit<br>Notifications</label>
                                  </div>
                                  <!-- <p class="hover-text">Hover text here</p> -->
                                  <!-- <img src="assets/images/Duplicate_Tech.png" class="clseditimg" style="margin-top:5px;" (click)="duplicateCampusdetails(Campus.CampusId,FacilityName)"/> -->
                                </a>
                              </div>
                              <div class="campusedit"
                                [ngStyle]="{'pointer-events': Campus.NotifyCnt > 0 ? 'block' : 'none'}"
                                (click)="getBuildingDetailsForNotification(Campus.CampusId, Campus.CampusName,campusImgUrl + Campus.FloorPlanImage)"
                                style="z-index: 1000;"></div>
                              <!-- <div class="edit"><a href="#floorplans"><img src="assets/images/Duplicate_Tech.png" class="clseditimg" (click)="duplicateCampusdetails(Campus.CampusId,FacilityName)"/></a></div> -->
                            </div>
                            <h4 class="label" style="margin-bottom: .01em;margin-top: 0px;"
                              *ngIf="Campus.CampusName.length < 20">{{Campus.CampusName}}</h4>
                            <h4 class="label" style="margin-bottom: .01em;margin-top: 0px;"
                              *ngIf="Campus.CampusName.length >= 20">
                              <div class="floortooltip">{{Campus.CampusName.slice(0,20) + ".."}}<span
                                  class="floortooltiptext">{{Campus.CampusName}}</span></div>
                            </h4>
                            <div style="display:flex;">
                              <div style="float:left;">
                                <h4 class="clsmatcardsubtitle" fxShow.sm="false" fxShow.xs="false"
                                  *ngIf="Campus.NotifyCnt < 2">
                                  {{Campus.NotifyCnt}} Notification Level</h4>
                                <h4 class="clsmatcardsubtitle" fxShow.sm="false" fxShow.xs="false"
                                  *ngIf="Campus.NotifyCnt >= 2">
                                  {{Campus.NotifyCnt}} Notification Levels</h4>
                              </div>
                              <div style="float:right;">
                                <div [style.display]="Campus.NotifyCnt == 0 ? 'block' : 'none'" style="cursor: pointer;"
                                  [matTooltip]="matTooltip" matTooltipClass="tooltip" style="width:27px; height:35px;"
                                  #tooltip="matTooltip" (click)="tooltip.show()"><img style="width:27px;"
                                    src="assets/images/Alerts-ErrorAlert Icon-Popup.png"></div>
                              </div>
                            </div>

                          </mat-icon>
                        </mat-list-item>
                      </mat-nav-list>

                    </mat-list>

                  </mat-card-content>
                </div>

              </div>

            </div>


          </div>
          <div *ngIf="Campus.length ==0">
            <div fxLayout="row" style="border-bottom: 2px solid #d5d4d4;">
              <div fxLayout="column" fxLayoutAlign="end" style="width: 100%;">
                <mat-card-actions class="clsmatcardaction">
                  <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">Campus (0)
                  </mat-card-title>
                </mat-card-actions>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="IsBuildingFloor == 1 || IsFloor == 1 || isSubZone == 1">

          <!----------------------BUILDING------------------------->
          <div *ngIf="selectedcampus > 0">

            <div *ngIf="Building.length > 0">
              <div class="clscampuscard" id="DivBuildingmatcontent" *ngFor="let Building1 of Building; let i = index;">

                <div fxLayout="row" style="border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">

                  <div fxLayout="column" fxLayoutAlign="end" style="width: 100%;">

                    <mat-card-actions class="clsmatcardaction">

                      <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">
                        <div style="width:50%;display: inline-block;">
                          <div *ngIf="buildingcampusname.length == 0" class="floortooltip">Buildings ({{BuildingCnt}})
                          </div>
                          <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length < 15"
                            class="floortooltip">Buildings ({{BuildingCnt}}) - {{buildingcampusname}}</div>
                          <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length >= 15"
                            class="floortooltip">Buildings ({{BuildingCnt}}) - {{buildingcampusname}}<span
                              class="floortooltiptext">{{buildingcampusfullname}}</span></div>
                        </div>
                        <div style="width: 15%;float:right;display: contents;">
                          <!--<button mat-button style="float:right;">
                            <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_build}}</mat-icon>
                            <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_build}}</mat-icon>
                          </button>  -->
                          <button class="clsdeselectbutton" id="build_btndeselect"
                            [style.display]="build_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button
                            (click)="clkdeselectmapitem(2)">Select</button>
                        </div>
                      </mat-card-title>

                    </mat-card-actions>

                    <mat-card-content *ngIf="checkicon == 1 ? build_arrowiconT : build_arrowiconF">

                      <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover over the building image and
                          select edit to add or change notification levels. Click on the building image to view and edit
                          notifications for it's zones with custom notifications.</span></div>

                      <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
                        <div *ngFor="let Building of Building">
                          <mat-nav-list
                            [style.display]="Building.Notifications > 0 || Building.NeedToshow > 0 ? 'block' : 'none'"
                            class="clsimgnext">
                            <mat-list-item>
                              <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true"
                                class="clsediticon">
                                <div id="container"
                                  [ngStyle]="{'border': selectedbuilding === Building.BuildId ? '2px solid #F49542' : '2px solid #d2d4d8'}"
                                  class="clsBuildingsplanimg">
                                  <!-- <div id="mapBuild_{{Building.BuildId}}" class="clsimage map" style="margin:0px; width:100%; height: 100%;">
                                                          </div> -->
                                  <img class="clsimage" [src]="ImageUrl"
                                    [ngStyle]="{'transform': 'scale('+CZoomLevel+') rotate('+ CRotateLevel +'deg) translateX('+ TranslateX +'px) translateY(' + TranslateY +'px)'}" />
                                  <!-- <div class="buildedit"><a href="#floorplans"><img src="assets/images/Edit-Popups-White.png" class="clseditimg" (click)="editBuildingdetails(Building.BuildId)"/> </a></div> -->

                                  <div class="buildeditIcon"
                                    [style.display]="Building.Notifications > 0 ? 'block' : 'none'">
                                    <a href="javascript:void(0);" style="display: inline-grid;">
                                      <div style="display:flex;">
                                        <img src="assets/images/Edit_Tech.png" class="clseditimg" title="Edit"
                                          (click)="editNotifications(Building.CampusId,Building.BuildId,0)" />
                                        <label class="clseditimg"
                                          (click)="editNotifications(Building.CampusId,Building.BuildId,0)">Edit<br>Notifications</label>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="buildeditIcon"
                                    [style.display]="Building.Notifications == 0 ? 'block' : 'none'">
                                    <a href="javascript:void(0);" style="display: inline-grid;">
                                      <div style="display:flex;">
                                        <img src="assets/images/Edit_Tech.png" class="clseditimg" title="Edit"
                                          (click)="NoEditNotifications(1)" />
                                        <label class="clseditimg"
                                          (click)="NoEditNotifications(1)">Edit<br>Notifications</label>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="buildedit"
                                    (click)="getFloorDetailsForNotification(Building.BuildId, Building.BuildingName)">
                                  </div>
                                </div>
                                <h4 class="label" style="margin-bottom: 0.01em;margin-top: 0px;"
                                  *ngIf="Building.BuildingName.length < 20">
                                  {{Building.BuildingName}}</h4>
                                <h4 class="label" style="margin-bottom: 0.01em;margin-top: 0px;"
                                  *ngIf="Building.BuildingName.length >= 20">
                                  <div class="floortooltip">{{Building.BuildingName.slice(0,20) + ".."}}<span
                                      class="floortooltiptext">{{Building.BuildingName}}</span></div>
                                </h4>
                                <h4 class="clsmatcardsubtitle" fxShow.sm="false" fxShow.xs="false"
                                  [style.display]="Building.Notifications > 0 ? 'block' : 'none'"
                                  *ngIf="Building.NotifyCnt < 2">
                                  {{Building.NotifyCnt}} Notification Level</h4>
                                <h4 class="clsmatcardsubtitle" fxShow.sm="false" fxShow.xs="false"
                                  [style.display]="Building.Notifications > 0 ? 'block' : 'none'"
                                  *ngIf="Building.NotifyCnt >= 2">
                                  {{Building.NotifyCnt}} Notification Levels</h4>
                              </mat-icon>
                            </mat-list-item>
                          </mat-nav-list>
                        </div>
                      </mat-list>

                    </mat-card-content>
                  </div>

                </div>

              </div>
            </div>

          </div>

          <div *ngIf="selectedcampus == 0" class="clscampuscard" style="width:100%;border-bottom: 2px solid #d5d4d4;">

            <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
              <div style="width:90%;display: inline-block;">
                <div *ngIf="buildingcampusname.length == 0" class="floortooltip">Buildings ({{BuildingCnt}})</div>
                <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length < 15" class="floortooltip">
                  Buildings ({{BuildingCnt}}) - {{buildingcampusname}}</div>
                <div *ngIf="buildingcampusname.length > 0 && buildingcampusname.length >= 15" class="floortooltip">
                  Buildings ({{BuildingCnt}}) - {{buildingcampusname}}<span
                    class="floortooltiptext">{{buildingcampusfullname}}</span></div>
              </div>
              <div style="width: 10%;float:right;">
                <!--<button mat-button style="float:right;">
                    <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_build}}</mat-icon>
                    <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_build}}</mat-icon>
                  </button>  -->
              </div>
            </mat-card-title>
          </div>
          </div>
          <!----------------------BUILDING END------------------------->
          <div *ngIf="IsBuildingFloor == 1  && IsFloor == 1">
            <!----------------------FLOOR------------------------->

            <div *ngIf="selectedbuilding > 0">
              <div *ngIf="Floor.length > 0">
                <div class="clscampuscard" id="DivFloormatcontent" *ngFor="let Floor1 of Floor; let i = index;">

                  <div fxLayout="row" style="width: 100%;border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">

                    <div fxLayout="column" fxLayoutAlign="end" style="width: 100%;">

                      <mat-card-actions class="clsmatcardaction">

                        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">
                          <div style="width:50%;display: inline-flex;">
                            <div *ngIf="floorbuildingname.length == 0" class="floortooltip">Zones ({{FloorCnt}})</div>
                            <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length < 15"
                              class="floortooltip">Zones ({{FloorCnt}}) - {{floorbuildingname}} , </div>
                            <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length >= 15"
                              class="floortooltip">Zones ({{FloorCnt}}) - {{floorbuildingname}}<span
                                class="floortooltiptext">{{floorbuildingfullname}}</span></div>

                            <div *ngIf="floorcampusname.length > 0 && floorcampusname.length < 15" class="floortooltip">
                              {{floorcampusname}} </div>
                            <div *ngIf="floorcampusname.length > 0 && floorcampusname.length >= 15" class="floortooltip">
                              {{floorcampusname}} <span class="floortooltiptext">{{floorcampusfullname}}</span></div>
                          </div>
                          <div style="width: 15%;float:right;display: contents;">
                            <!--<button mat-button style="float:right;">
                            <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_floor}}</mat-icon>
                            <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_floor}}</mat-icon>
                          </button>-->
                            <button class="clsdeselectbutton" id="floor_btndeselect"
                              [style.display]="floor_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button
                              (click)="clkdeselectmapitem(3)">Select</button>
                          </div>
                        </mat-card-title>

                      </mat-card-actions>

                      <mat-card-content *ngIf="checkicon == 2 ? floor_arrowiconT : floor_arrowiconF">

                        <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover over the zone image and select edit to add or change notification levels.
                          Click on the zone image to view and edit notifications for it's sub-zones with custom notifications.
                        </span></div>

                        <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap
                          fxLayoutGap="0.5%">
                          <div *ngFor="let Floor of Floor">
                            <mat-nav-list [style.display]="Floor.Notifications > 0 ? 'block' : 'none'"
                              class="clsimgnext">
                              <mat-list-item>
                                <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true"
                                  fxShow="true" class="clsediticon">
                                  <div id="container"
                                    [ngStyle]="{'border': selectedfloor === Floor.FloorId ? '2px solid #F49542' : '2px solid #d2d4d8'}">
                                    <img class="clsimage" [src]="floorImgUrl + Floor.FloorPlanImage"
                                      [ngStyle]="{'transform': 'scale('+Floor.FZoomLevel+') rotate('+ Floor.FRotateLevel +'deg) translateX('+ Floor.FTransformX +'px) translateY(' + Floor.FTransformY +'px)'}" />
                                    <div class="flooreditIcon"><a href="javascript:void(0);"
                                        style="display: inline-grid;">
                                        <div style="display:flex;">
                                          <img src="assets/images/Edit_Tech.png" class="clseditimg"
                                            (click)="editNotifications(Floor.CampusId,Floor.BuildId,Floor.FloorId)" />
                                          <label class="clseditimg"
                                            (click)="editNotifications(Floor.CampusId,Floor.BuildId,Floor.FloorId)">Edit<br>Notifications</label>
                                        </div>
                                      </a></div>

                                      <div class="flooreditIcon" [style.display]="Floor.Notifications == 0 ? 'block' : 'none'">
                                        <a href="javascript:void(0);" style="display: inline-grid;">
                                          <div style="display:flex;">
                                            <img src="assets/images/Edit_Tech.png" class="clseditimg" title="Edit"
                                              (click)="NoEditNotifications(2)" />
                                            <label class="clseditimg"
                                              (click)="NoEditNotifications(2)">Edit<br>Notifications</label>
                                          </div>
                                        </a>
                                      </div>
                                      

                                    <div *ngIf="Floor.isZoneFloor == 0" class="flooredit" style="z-index: 1000;"></div>
                                    <div *ngIf="Floor.isZoneFloor == 1" class="flooredit" style="z-index: 1000;" (click)="getSubZoneDetailsForNotification(Floor.FloorId, Floor.FloorName)"></div>
                                  </div>
                                  <h4 class="label" style="margin-bottom: .01em;margin-top: 0px;"
                                    *ngIf="Floor.FloorName.length < 20">{{Floor.FloorName}}</h4>
                                  <h4 class="label" style="margin-bottom: .01em;margin-top: 0px;"
                                    *ngIf="Floor.FloorName.length >= 20">
                                    <div class="floortooltip">{{Floor.FloorName.slice(0,20) + ".."}}<span
                                        class="floortooltiptext">{{Floor.FloorName}}</span></div>
                                  </h4>
                                  <h4 class="clsmatcardsubtitle" fxShow.sm="false" fxShow.xs="false"
                                    *ngIf="Floor.NotifyCnt < 2">
                                    {{Floor.NotifyCnt}} Notification Level</h4>
                                  <h4 class="clsmatcardsubtitle" fxShow.sm="false" fxShow.xs="false"
                                    *ngIf="Floor.NotifyCnt >= 2">
                                    {{Floor.NotifyCnt}} Notification Levels</h4>
                                </mat-icon>
                              </mat-list-item>
                            </mat-nav-list>
                          </div>
                        </mat-list>

                      </mat-card-content>
                    </div>

                  </div>

                </div>
              </div>

            </div>

            <div *ngIf="selectedbuilding == 0" class="clscampuscard"
              style="width:100%;border-bottom: 2px solid #d5d4d4;">

              <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
                <div style="width:90%;display: inline-block;">
                  <div *ngIf="floorbuildingname.length == 0" class="floortooltip">Zones ({{FloorCnt}})</div>
                  <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length < 15" class="floortooltip">Zones
                    ({{FloorCnt}}) - {{floorbuildingname}} , </div>
                  <div *ngIf="floorbuildingname.length > 0 && floorbuildingname.length >= 15" class="floortooltip">Zones
                    ({{FloorCnt}}) - {{floorbuildingname}}<span class="floortooltiptext">{{floorbuildingfullname}} ,
                    </span></div>

                  <div *ngIf="floorcampusname.length > 0 && floorcampusname.length < 15" class="floortooltip">
                    {{floorcampusname}} </div>
                  <div *ngIf="floorcampusname.length > 0 && floorcampusname.length >= 15" class="floortooltip">
                    {{floorcampusname}} <span class="floortooltiptext">{{floorcampusfullname}}</span></div>

                </div>
                <div style="width: 10%;float:right;">
                  <!--<button mat-button style="float:right;">
                    <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1_floor}}</mat-icon>
                    <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon_floor}}</mat-icon>
                  </button>  -->
                </div>
              </mat-card-title>

            </div>
          </div>
          <!----------------------FLOOR END------------------------------>
          
          <div *ngIf="IsBuildingFloor == 1  && IsFloor == 1">
            <!----------------------SUB-ZONE------------------------->
            
            <div *ngIf="selectedfloor > 0">
              <div *ngIf="SubZone.length > 0">
              <div class="clscampuscard" id="DivFloormatcontent" *ngFor="let SubZone1 of SubZone; let i = index;">
                
                <div fxLayout="row" style="width: 100%;border-bottom: 2px solid #d5d4d4;" *ngIf="i == 0">
                
                  <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">
                    
                    <mat-card-actions class="clsmatcardaction">
                    
                      <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">
                        <div style="width:50%;display: inline-flex;">
                        <div *ngIf="subzonefloorname.length == 0" class="floortooltip">Sub-Zones ({{FloorCnt}})</div>
                        <div *ngIf="subzonefloorname.length > 0 && subzonefloorname.length < 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{subzonefloorname}} , </div>
                        <div *ngIf="subzonefloorname.length > 0 && subzonefloorname.length >= 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{subzonefloorname}}<span class="floortooltiptext">{{subzonefloorfullname}}</span></div>          
                        
                        <div *ngIf="subzonebuildingname.length > 0 && subzonebuildingname.length < 15" class="floortooltip"> {{subzonebuildingname}} , </div>
                        <div *ngIf="subzonebuildingname.length > 0 && subzonebuildingname.length >= 15" class="floortooltip"> {{subzonebuildingname}} , <span class="floortooltiptext">{{subzonebuildingfullname}}</span></div>
                        
                        <div *ngIf="subzonecampusname.length > 0 && subzonecampusname.length < 15" class="floortooltip"> {{subzonecampusname}}  </div>
                        <div *ngIf="subzonecampusname.length > 0 && subzonecampusname.length >= 15" class="floortooltip"> {{subzonecampusname}}  <span class="floortooltiptext">{{subzonecampusfullname}}</span></div>
                      </div>
                      <div style="width: 15%;float:right;display: contents;">                          
                        <button class="clsdeselectbutton" id="subzone_btndeselect" [style.display]="subzone_isbuttonshow == 1 ? 'block': 'none'" mat-raised-button (click)="clkdeselectmapitem(4)">Select</button>
                      </div>
                      </mat-card-title>
                      
                    </mat-card-actions>
                    
                    <mat-card-content *ngIf="checkicon == 3 ? subzone_arrowiconT : subzone_arrowiconF">
                      
                      <div style="margin-bottom: 15px;margin-left: 1.2em;"><span>Hover over the sub-zone image and select edit to add or change notification levels.</span></div>
                      
                      <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">                    
                        <div *ngFor="let Subzone of SubZone">
                        <mat-nav-list [style.display]="Subzone.Notifications > 0 ? 'block' : 'none'" class="clsimgnext">
                          <mat-list-item>
                            <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">
                              <div id="container" [ngStyle]="{'border': selectedsubzone === Subzone.SubZoneId ? '2px solid #F49542' : '2px solid #d2d4d8'}">
                                <img class="clsimage" [src]="floorImgUrl + Subzone.FloorPlanImage" 
                                  [ngStyle]="{'transform': 'scale('+Subzone.FZoomLevel+') rotate('+ Subzone.FRotateLevel +'deg) translateX('+ Subzone.FTransformX +'px) translateY(' + Floor.FTransformY +'px)'}" />
                                <div class="flooreditIcon"><a href="javascript:void(0);" style="display: inline-grid;">
                                    <div style="display:flex;">
                                      <img src="assets/images/Edit_Tech.png" class="clseditimg" (click)="editNotifications(Subzone.CampusId,Subzone.BuildId,Subzone.FloorId,Subzone.SubZoneId)" />
                                      <label class="clseditimg" (click)="editNotifications(Subzone.CampusId,Subzone.BuildId,Subzone.FloorId,Subzone.SubZoneId)">Edit<br>Notifications</label>
                                    </div>                                
                                  </a></div>
                                  <div class="flooredit"  style="z-index: 1000;"></div>
                              </div>
                              <h4 class="label" style="margin-bottom: .01em;margin-top: 0px;" *ngIf="Subzone.SubZoneDesc.length < 20">{{Floor.SubZoneDesc}}</h4>
                              <h4 class="label" style="margin-bottom: .01em;margin-top: 0px;" *ngIf="Subzone.SubZoneDesc.length >= 20">
                                <div class="floortooltip">{{Subzone.SubZoneDesc.slice(0,20) + ".."}}<span
                                    class="floortooltiptext">{{Subzone.SubZoneDesc}}</span></div>
                              </h4>
                                <h4 class="clsmatcardsubtitle" fxShow.sm="false" fxShow.xs="false" *ngIf="Subzone.NotifyCnt < 2">
                                  {{Subzone.NotifyCnt}} Notification Level</h4>
                                  <h4 class="clsmatcardsubtitle" fxShow.sm="false" fxShow.xs="false" *ngIf="Subzone.NotifyCnt >= 2">
                                  {{Subzone.NotifyCnt}} Notification Levels</h4>
                              </mat-icon>
                          </mat-list-item>
                        </mat-nav-list>
                      </div> 
                      </mat-list>                              
                      
                    </mat-card-content>
                  </div>
                  
                </div>
                
              </div>
            </div>
                    
            </div>
            
            <div *ngIf="selectedfloor == 0 && isSubZoneShow == 0" class="clscampuscard" style="width:100%;border-bottom: 2px solid #d5d4d4;">
              
              <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;">
                <div style="width:90%;display: inline-block;">
                <div *ngIf="subzonefloorname.length == 0" class="floortooltip">Sub-Zones ({{SubZoneCnt}})</div>
                <div *ngIf="subzonefloorname.length > 0 && subzonefloorname.length < 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{subzonefloorname}} , </div>
                <div *ngIf="subzonefloorname.length > 0 && subzonefloorname.length >= 15" class="floortooltip">Sub-Zones  ({{SubZoneCnt}}) - {{subzonefloorname}}<span class="floortooltiptext">{{subzonefloorfullname}} , </span></div>          
              
                <div *ngIf="subzonebuildingname.length > 0 && subzonebuildingname.length < 15" class="floortooltip"> {{subzonebuildingname}}  </div>
                <div *ngIf="subzonebuildingname.length > 0 && subzonebuildingname.length >= 15" class="floortooltip"> {{subzonebuildingname}}  <span class="floortooltiptext">{{subzonebuildingfullname}}</span></div>
                        
                <div *ngIf="subzonecampusname.length > 0 && subzonecampusname.length < 15" class="floortooltip"> {{subzonecampusname}}  </div>
                <div *ngIf="subzonecampusname.length > 0 && subzonecampusname.length >= 15" class="floortooltip"> {{subzonecampusname}}  <span class="floortooltiptext">{{subzonecampusfullname}}</span></div>
              
              </div>
                <div style="width: 10%;float:right;">                   
              </div>
              </mat-card-title>                  
            
            </div>
            </div>
            <!----------------------SUB-ZONE END------------------------------>
                  
        <div *ngIf="IsBuildingFloor == 0 && IsFloor == 0 && isSubZone == 0">
          <div style="width: 15%;float:right;display: contents;">  
            <span class="clsSpanSpacedata">There are no buildings or zones on this campus with custom notifications enabled.</span>
          </div>                  
        </div>
        <div *ngIf="IsBuildingFloor == 1 && IsFloor == 0 && isSubZone == 0">
          <div style="width: 15%;float:right;display: contents;">  
            <span class="clsSpanSpacedata">There are no zones on this building with custom notifications enabled.</span>
          </div>                  
        </div>
        
        <div *ngIf="isSubZone == 0 && isSubZoneShow == 1">
          <div style="width: 15%;float:right;display: contents;">  
            <span class="clsSpanSpacedata">There are no sub-zones on this zone with custom notifications enabled.</span>
          </div>                  
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</mat-card>
