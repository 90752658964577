import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,  
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import {
  AddNewZoneForm,
  FloorGroup
} from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import {
  appsettings,
  enumFileNamelength,
  enumEnableDisable,
} from "../../../../../app-settings";
import { Subject } from "rxjs";
import { HostListener } from "@angular/core";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../../../../../api.service";
import { ConfirmDialogComponent } from "../../../../technologyoverlay/device/confirm-dialog/confirm-dialog.component";
import { getCampus, getBuilding, getFloor } from "../../../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

const URL = appsettings.URLinfo + "uploadFloorImage";
@Component({
  selector: "app-add-zone",
  templateUrl: "./add-zone.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-zone.component.scss"],
})

export class AddZoneComponent implements OnInit, OnDestroy {
  public flooraddForm: UntypedFormGroup;
  public newzoneaddForm: UntypedFormGroup;
  public message: string;
  public imagePath;
  public watcher: Observable<boolean>;
  imgURL: any;
  columns = 4;
  firstcolumns = 2;
  zoomval = 1;
  imgWidth = 200;
  height = 190;
  top = 0;
  left = 0;
  rotationAmount = 0;
  styles = "";
  downIcon = 0;
  updownArrowIcon = "keyboard_arrow_down";
  minheight = "230px";
  flooraddImgUrl = "";
  flooraddsvgUrl;
  newflooraddImgUrl;
  floorimagename;
  countofFloor;
  floorflag = 0;
  uploadflag = 0;
  IsHidden = true;
  IsbuttonHidden = true;
  FloorName = [];
  FloorImage = [];
  Description = [];
  floorCampusId;
  floorBuildId;
  validimgurl = false;
  private _onDestroy: Subject<void> = new Subject<void>();
  floorrowHeight = "69px";
  screenWidth;
  addfloorbool = true;
  FloorLength;
  isundo = 0;
  left_rightval = 0;
  top_bottomval = 0;
  zoominvalue = 1;
  undooperation = true;
  undopane = 0;
  uploadfloorimage;
  pngrotateamnt;
  selectedBuildingName;
  selectedCampusName;
  Campusdetails;
  selectedBuildingId;
  selectedCampusId;
  Buildingdetails;

  error: string;
  dragAreaClass: string;

  F_chkCampusvalue = true;
  F_chkCustomvalue = false;

  flooraddCheck_Invalue = false;
  authkey;
  Floordetails;
  selectedFloorId=0;
  selectedFloorName;
  zoneImageflag;
  checkZoneisFloor = false;
  disableZoneisFloor = true; 
  floorGroups; 
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddZoneComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.selectedCampusId = data.CampusId;
      this.selectedBuildingId = data.BuildId;
      this.onResize();
      this.breakpoint$.subscribe(() => this.breakpointChanged());           

      this.floorCampusId = data.CampusId;
      this.floorBuildId = data.BuildId;
      this.selectedBuildingName = data.BuildingName;
      this.selectedCampusName = data.CampusName;
      if(data.floorvalue > 0)
      {
        this.selectedFloorId = parseInt(data.floorvalue);
        this.selectedFloorName = data.FloorName;                  
      }
      
      this.FloorLength = data.Floorlength.MaxId + 1;
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - constructor() - " + err.message
      );
    }
  }
  
  breakpointChanged() {         
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }     
  }

  @ViewChild("imageInput") imageInput: any;
  public onChangefloor(fileList: FileList) {
    this.saveFiles(fileList);
  }

  saveFiles(fileList: FileList) {
    if (fileList.length > 1) {
      generateAlertMessage(this.dialog, "Only one file at time allowed");
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    }
    const file = fileList[0];    
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(removedSpaces_filename);
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Zone Photo as image file."
      );
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 1);
      else this.newflooraddImgUrl = "assets/images/defaultimg.png";

      this.imageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 2) {
              // 1024 is 1MB
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 2 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Zone Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  uploadfiletoPath(data, file, type) {
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const FileName = this.FloorLength + "+" + _filename;
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        this.floorimagename = res.filename;
        this.validimgurl = true;
        if (type == 1) {
          this.flooraddImgUrl = appsettings.FLOOR_IMAGE_FOLDER + res.filename;
          this.newflooraddImgUrl =
            appsettings.FLOOR_IMAGE_FOLDER + res.filename;
          this.updatesrcimageInput(this.flooraddImgUrl);
        } else {
          this.flooraddsvgUrl = appsettings.FLOOR_IMAGE_FOLDER + res.filename;
        }
        this.zoneImageflag = 1;
      });
  }
  @ViewChild("imagesvgInput") imagesvgInput: any;
  public onChangefloorsvg(fileList: FileList) {
    const file = fileList[0];
    const itemType =
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
      file.name;
      const fileTypesAllowed = ["svg"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Zone Photo as svg file"
      );
      this.flooraddsvgUrl = "assets/images/defaultsvg.png";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 2);
      else this.flooraddsvgUrl = "assets/images/defaultsvg.png";

      this.imagesvgInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (self[0].includes("xml")) {
            checkvalidIMG = 1;
            resolve(checkvalidIMG);
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(_dialog, "Please upload valid file");
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.flooraddsvgUrl = "assets/images/defaultsvg.png";
      // this.countofFloor = 3;
      this.validimgurl = false;
      this.flooraddForm = new UntypedFormGroup({
        name: new UntypedFormControl(""),
        floorimage: new UntypedFormControl(""),
        campusID: new UntypedFormControl(""),
        buildID: new UntypedFormControl(""),
        description: new UntypedFormControl(""),
        mapimage: new UntypedFormControl(""),
      });
      const map_image = this.flooraddForm.get("mapimage");
      if (map_image != null) map_image.disable();

      this.newzoneaddForm = new UntypedFormGroup({
        campusID: new UntypedFormControl(""),
        campus: new UntypedFormControl(""),
        buildID: new UntypedFormControl(""),
        building: new UntypedFormControl("", [Validators.required]),
        floorname: new UntypedFormControl(""),   
        name: new UntypedFormControl("", [         
          Validators.maxLength(32),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        zonename: new UntypedFormControl('', [Validators.maxLength(32), Validators.pattern('[a-zA-Z0-9s][a-zA-Z0-9!-@#$%^& ]*')]),
        description: new UntypedFormControl("", [
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        // newfloorimage: new FormControl('')
      });

      this.getCampusDetails();
      this.dragAreaClass = "dragarea";
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  getCampusDetails() {
    try {
      const campusid: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
          this.getBuildingdetails(this.selectedCampusId);
        });
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - getCampusDetails() - " + err.message
      );
    }
  }

  changecampus_addFloor(campusid, campusname) {
    this.selectedCampusId = campusid;
    this.selectedCampusName = campusname;
    this.selectedBuildingId = "";
    this.getBuildingdetails(this.selectedCampusId);
  }

  getBuildingdetails(CampusId) {
    try {
      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
      };

      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          this.Buildingdetails = BuildingDetails["apiReturn"];
          this.getFloorDetails(this.selectedBuildingId)
        });
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - getBuildingdetails() - " + err.message
      );
    }
  }

  changebuilding_addFloor(buildingid, buildname) {
    this.selectedBuildingId = buildingid;
    this.selectedBuildingName = buildname;
    this.getFloorDetails(this.selectedBuildingId)
  }
  
  getFloorDetails(BuildingId)
  {
    try {         
      const floorbyid: getFloor = {        
        authKey: this.authkey,
        campusId: this.selectedCampusId,
        buildId: BuildingId,    
      };
      this.apiService.getFloorDetails(floorbyid).pipe(takeUntil(this._onDestroy))
      .subscribe((floorInfo: any[]) => {
          this.Floordetails = floorInfo["apiReturn"];                                 
          // let formfloorgroup : FloorGroup[];
          let floors = "[";       
          for(let i = 0; i < this.Floordetails.length; i++)
          {        
            floors += "{ \"value\": \""+this.Floordetails[i].FloorId+"\",\"viewValue\": \""+this.Floordetails[i].FloorName+"\"}";  
            if(i != this.Floordetails.length - 1)
              floors += ",";             
          }
          floors += "]";       
          const _floorsinfo = JSON.parse(floors);             
            const formfloorgroup : FloorGroup []= [
              {
                campus: this.Floordetails[0].CampusName,
                building: this.Floordetails[0].BuildingName,
                floors : _floorsinfo              
              },
            ]
            const _floorgroups = formfloorgroup;
                  
          this.floorGroups = _floorgroups;                
          if(this.selectedFloorId != undefined) 
            this.changefloor_addZone(this.selectedFloorId,this.selectedFloorName)                
        });
    } 
    catch (err) {
      throw new Error("add-zone.component.ts" + " - getFloorDetails() - " + err.message);
    }
  }

  changefloor_addZone(floorId,floorName)
  {
    this.selectedFloorId = parseInt(floorId);
    this.selectedFloorName = floorName;
    let fileImageName = ""; 
    let imageName = "";
    this.Floordetails.forEach(function(e)
    {       
      if(floorId == e.FloorId)    
      {
        imageName = e.FloorPlanImage;  
        fileImageName = appsettings.FLOOR_IMAGE_FOLDER + e.FloorPlanImage;              
      }
    })
    this.validimgurl = true;
    this.floorimagename = imageName;
    this.newflooraddImgUrl = fileImageName;
    this.zoneImageflag = 0;
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.newzoneaddForm.controls).forEach((field) => {
        // {1}
        const control = this.newzoneaddForm.get(field); // {2}
        const formFloorTargetId = document.getElementById(event.target.id);
        if (formFloorTargetId != null) {
          if (field == formFloorTargetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1280) {
      this.updownArrowIcon = "keyboard_arrow_down";
      this.downIcon = 0;
      this.thisDialogRef.updateSize("75em", "700px");
      this.minheight = "230px";
      this.columns = 4;
      this.firstcolumns = 2;
    }
    if (this.screenWidth <= 768) {
      this.floorrowHeight = "80px";
    } else if (this.screenWidth == 1097) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
      this.floorrowHeight = "79px";
    } else if (this.screenWidth == 1536) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    }
  }
  public onCloseCancel = () => {
    try {
      this.thisDialogRef.close("Cancel");
      if (!SessionCheck(this.router)) {
        return;
      }
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - onCloseCancel() - " + err.message
      );
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    try {
      return this.flooraddForm.controls[controlName].hasError(errorName);
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - hasError() - " + err.message
      );
    }
  };

  newAddZone(addFloorFormValue) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      if (this.newzoneaddForm.valid) {
        let _Description = "";
        if (addFloorFormValue.description != "")
          _Description = addFloorFormValue.description
            .replace(/\s+/g, " ")
            .trim();
        else _Description = "";

        if (this.F_chkCampusvalue == false && this.F_chkCustomvalue == false) {
          generateAlertMessage(this.dialog, "Please Select Notifications");
          this.floorAddEnableDisable(enumEnableDisable.Enable);
          return;
        } else {
          let notificationvalue = 0;
          if (this.F_chkCampusvalue == true) notificationvalue = 0;
          else notificationvalue = 1;

          let MessageDetail;          

          const message = 'By selecting "Custom", you must now configure Zones for this building. Truview will now build out the Zone Notifications for each alert type.';

          if (notificationvalue == 1) {
            MessageDetail = { Message: message, Type: 1, CustomType: 1 };

            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              disableClose: true,
              width: "650px",
              height: "250px",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: MessageDetail,
            });

            dialogRef.afterClosed().subscribe((result) => {
              if (result == "Confirm") {
                this.onSaveAddZoneDetails(
                  addFloorFormValue,
                  _Description,
                  notificationvalue
                );
              } else {
                this.floorAddEnableDisable(enumEnableDisable.Enable);
                return;
              }
            });
          } else {
            this.onSaveAddZoneDetails(
              addFloorFormValue,
              _Description,
              notificationvalue
            );
          }
        }
      }
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - newAddZone() - " + err.message
      );
    }
  }

  onSaveAddZoneDetails(addFloorFormValue, _Description, notificationvalue) {
    let _addCheckinvalue = 0;
    let ZoneName = "";
    if (this.flooraddCheck_Invalue == true) _addCheckinvalue = 1;
    else if (this.flooraddCheck_Invalue == false) _addCheckinvalue = 0;
    
    let isZoneFloor = 0;
    if(this.checkZoneisFloor == true)    
    {
      isZoneFloor = 1;
      ZoneName = addFloorFormValue.name.replace(/\s+/g, ' ').trim();
    }
    else
    {
      isZoneFloor = 0;
      ZoneName = addFloorFormValue.zonename.replace(/\s+/g, ' ').trim();
    }

    const floordetails: AddNewZoneForm = {
      authKey: this.authkey,
      campusId: parseInt(this.selectedCampusId),
      buildId: parseInt(this.selectedBuildingId),
      floorOriginalId:parseInt(this.selectedFloorId.toString()),
      zoneName: ZoneName,//addFloorFormValue.zonename.replace(/\s+/g, " ").trim(),
      description: _Description.toString(), //addFloorFormValue.description.replace(/\s+/g, ' ').trim(),
      zoneImage: this.floorimagename.toString(),
      widthft: "",
      lengthft: "",
      zoomLevel: this.zoomval,
      rotateLevel: this.rotationAmount,
      fTransformX: this.left_rightval,
      fTransformY: this.top_bottomval,
      notifications: notificationvalue,
      zoneCheckInValue: _addCheckinvalue,
      imageflag : this.zoneImageflag,
      isZoneFloor : isZoneFloor
    };

    this.addZone(floordetails);
  }

  //Add zone is used to add a new zone in the selected building. When will click the Add new zone it will show the popup window with the following option
  //1. Scratch
  addZone = (addfloordetails) => {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();

      if (this.newzoneaddForm.valid && this.addfloorbool) {
        this.addfloorbool = false;
        this.floorAddEnableDisable(enumEnableDisable.Disable);
        this.technologyoverlayService
          .addZone(addfloordetails)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse["ZoneId"] > 0) {
              this.apiService.reloadmapconfig();
              // generateAlertMessage(this.dialog, 'Zone added successfully.');
              this.thisDialogRef.close(apiresponse["ZoneId"]);
            } else if (apiresponse["apiReturn"] == "Fail") {
              this.addfloorbool = true;
              generateAlertMessage(this.dialog, "Zone added failed.");
              this.thisDialogRef.close("Confirm");
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("Cancel");
              AuthFailedMessage(this.dialog, this.router);
              return;
            } else if (apiresponse["apiReturn"] == "floorName") {
              this.addfloorbool = true;
              generateAlertMessage(this.dialog, "Please enter Zone Name");
              this.floorAddEnableDisable(enumEnableDisable.Enable);
              return;
            } else {
              this.addfloorbool = true;
              generateAlertMessage(
                this.dialog,
                "Zone name '" + apiresponse["floorList"][0].FloorName + "' already exists."
              );
              this.floorAddEnableDisable(enumEnableDisable.Enable);
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - addFloor() - " + err.message
      );
    }
  };

  rotateImage(direction: string) {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 1;
      if (direction === "left") {
        const flag = this.imgWidth;
        this.imgWidth = this.height;
        this.height = flag;
        this.rotationAmount = this.rotationAmount + -90;
        if (this.rotationAmount == -360) {
          this.rotationAmount = 0;
        }

        if (this.rotationAmount === -180 || this.rotationAmount === 0) {
          this.top = 0;
          this.left = 0;
        } else {
          this.top = ((this.imgWidth - this.height) / 2) * -1;
          this.left = (this.imgWidth - this.height) / 2;
        }
      } else if (direction === "right") {
        this.rotationAmount = this.rotationAmount + 90;
        if (this.rotationAmount == 360) {
          this.rotationAmount = 0;
        }
      }
      this.pngrotateamnt = this.rotationAmount;
      //this.styles = 'rotate(' + this.rotationAmount + 'deg)';
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - rotateImage() - " + err.message
      );
    }
  }

  removeImage() {
    try {
      this.undooperation = true;
      this.isundo = 8;
      this.uploadfloorimage = this.newflooraddImgUrl;
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.flooraddsvgUrl = "assets/images/defaultsvg.png";
      this.validimgurl = false;
      this.rotationAmount = 0;
      this.zoomval = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
      // this.styles =  'rotate(' + this.rotationAmount + 'deg)';
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - removeImage() - " + err.message
      );
    }
  }

  zoomoutImage() {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 2;
      if (this.zoomval > 1) this.zoomval = this.zoomval - 0.5;
      // this.styles = 'scale(' + this.zoomval + ')';
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - zoomoutImage() - " + err.message
      );
    }
  }

  zoomInImage() {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 3;
      if (this.zoomval < 5) this.zoomval = this.zoomval + 0.5;
      this.zoominvalue = this.zoomval;
      //this.styles = 'scale(' + this.zoomval + ')';
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - zoomInImage() - " + err.message
      );
    }
  }

  resetformtext() {
    try {
      this.undooperation = false;
      this.rotationAmount = 0;
      //this.styles =  'rotate(' + this.rotationAmount + 'deg)';
      this.zoomval = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - resetformtext() - " + err.message
      );
    }
  }

  undoimage() {
    try {
      if (this.undooperation == true) {
        if (this.isundo == 1) {
          if (this.undopane == 0) {
            if (this.rotationAmount != 0)
              this.rotationAmount = this.rotationAmount - 90;
            else this.rotationAmount = 270;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 2) {
          if (this.undopane == 0) {
            if (this.zoomval < this.zoominvalue)
              this.zoomval = this.zoomval + 0.5;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 3) {
          if (this.undopane == 0) {
            if (this.zoomval > 1) this.zoomval = this.zoomval - 0.5;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 4) {
          if (this.left_rightval != 0)
            this.left_rightval = this.left_rightval - 10;
        } else if (this.isundo == 5) {
          if (this.left_rightval != 0)
            this.left_rightval = this.left_rightval + 10;
        } else if (this.isundo == 6) {
          if (this.top_bottomval != 0)
            this.top_bottomval = this.top_bottomval + 10;
        } else if (this.isundo == 7) {
          if (this.top_bottomval != 0)
            this.top_bottomval = this.top_bottomval - 10;
        } else if (this.isundo == 8) {
          this.newflooraddImgUrl = this.uploadfloorimage;
          this.rotationAmount = this.pngrotateamnt;
          this.validimgurl = true;
        }
      }

      this.undooperation = false;
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - undoimage() - " + err.message
      );
    }
  }

  leftImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 1;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 4;
    }
  }
  rightImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 2;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 5;
    }
  }
  bottomImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 3;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 6;
    }
  }
  topImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 4;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 7;
    }
  }

  panAlign(IsAdd, IsTopBottom) {
    let IsChk = false;
    if (IsAdd == panIsAdd.Add && IsTopBottom == panIsTopBottom.TopBottom) {
      if (this.top_bottomval < 70) {
        this.top_bottomval = this.top_bottomval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.TopBottom
    ) {
      if (this.top_bottomval > -70) {
        this.top_bottomval = this.top_bottomval - 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Add &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval < 70) {
        this.left_rightval = this.left_rightval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval > -70) {
        this.left_rightval = this.left_rightval - 10;
        IsChk = true;
      }
    }
    return IsChk;
  }

  selectFloor(Event) {
    try {
      this.countofFloor = parseInt(Event);
      this.IsHidden = false;
      this.IsbuttonHidden = false;
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - selectFloor() - " + err.message
      );
    }
  }

  clsimageInput(value) {
    try {
      this.floorflag = value;
      const element = document.getElementById("imageInput") as HTMLInputElement;
      element.click();
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - clsimageInput() - " + err.message
      );
    }
  }

  updatesrcimageInput(floorName) {
    try {
      for (let i = 0; i < this.countofFloor; i++) {
        if (this.floorflag == i + 1) {
          const result = document.getElementById("imageurl" + [i + 1]);
          const MapImageName = document.getElementById( "floormapimage" + [i + 1]) as HTMLInputElement;
          MapImageName.value = this.floorimagename;
          if(result != null)
            result.setAttribute("src", floorName);
        }
      }
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - updatesrcimageInput() - " + err.message
      );
    }
  }

  togglefloorCamVisibility(event) {
    if (event.checked == true) {
      this.F_chkCampusvalue = true;
      this.F_chkCustomvalue = false;
    } else {
      this.F_chkCustomvalue = false;
      this.F_chkCampusvalue = false;
    }
  }

  togglefloorCusVisibility(event) {
    if (event.checked == true) {
      this.F_chkCustomvalue = true;
      this.F_chkCampusvalue = false;
    } else {
      this.F_chkCampusvalue = false;
      this.F_chkCustomvalue = false;
    }
  }

  toggleAddCheckInVisibility(event) {
    if (event.checked == true) {
      this.flooraddCheck_Invalue = true;
    } else {
      this.flooraddCheck_Invalue = false;
    }
  }

  floorAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsaveflooraddclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsFlooraddbutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsFlooraddbutton mat-raised-button disablebtn";
  }
  
  toggleZoneisFloor(event)
  {
    const divaddFloortxt = document.getElementById('divaddFloortxt');
    const divaddFloorDropdown = document.getElementById('divaddFloorDropdown');
    const zonename = this.newzoneaddForm.get('zonename');
    if(event.checked == true)
    {          
      this.checkZoneisFloor = true;  
      if(divaddFloortxt != null)
        divaddFloortxt.style.display = "block";           
      if(divaddFloorDropdown != null)
        divaddFloorDropdown.style.display = "none";          
      if(zonename != null)
        zonename.disable();              
    }
    else
    {
      this.checkZoneisFloor = false;
      if(divaddFloortxt != null)
        divaddFloortxt.style.display = "none";           
      if(divaddFloorDropdown != null)
        divaddFloorDropdown.style.display = "block";          
      if(zonename != null)
        zonename.enable();                   
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

enum panIsAdd {
  Add = 0,
  Sub = 1,
}
enum panIsTopBottom {
  TopBottom = 0,
  LeftRignt = 1,
}
