import { Component, OnInit, Inject,OnDestroy,ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RollbarService } from '../../../../rollbar';
import * as Rollbar from 'rollbar';
import { SessionCheck } from '../../../../app-general';
import {Router} from '@angular/router';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit,OnDestroy {

  message = "";
  Type = "";
  ButtonValue1 = "";
  ButtonValue2 = "";
  constructor(private router: Router,public thisDialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,@Inject(RollbarService) private rollbar: Rollbar) 
  {

    try
    {
      this.message = data.Message;  
      if(data.CustomType == 0)
      {            
        if(data.Type == 0)      
          this.Type = "Draw Building";      
        else if(data.Type == 1)
          this.Type = "Draw Room";    
        
        this.ButtonValue1 = "Update";
        this.ButtonValue2 = "Cancel";
      }  
      else if(data.CustomType ==1)
      {
        if(data.Type == 0)
          this.Type = "Building";      
        else if(data.Type == 1)
          this.Type = "Zone";
          
        this.ButtonValue1 = "Cancel";
        this.ButtonValue2 = "Build";
      }
    }
    catch (err) {
      throw new Error('confirm-dialog.component.ts' + ' - constructor() - ' + err.message);
    }

  }

  ngOnInit() {
    console.log("confirm-dialog.component - ngOnInit");
  }

  onUpdateClicked()
  {
      try
      {
        if(!SessionCheck(this.router))
        {
           this.thisDialogRef.close('Cancel');
            return;
        }   
        if(this.ButtonValue1 == "Update")
          this.thisDialogRef.close('Confirm');
        else
          this.thisDialogRef.close('Cancel');
          
      }
      catch (err) {
        throw new Error('confirm-dialog.component.ts' + ' - onUpdateClicked() - ' + err.message);
      }

  }
  
  onCloseClicked = () => 
  {
      try
      {
        if(this.ButtonValue2 == "Cancel")
          this.thisDialogRef.close('Cancel');
        else
          this.thisDialogRef.close('Confirm');
        if(!SessionCheck(this.router))
        {
            return;
        }  
      }
      catch (err) {
        throw new Error('confirm-dialog.component.ts' + ' - onCloseClicked() - ' + err.message);
      }
  }
  onImageCloseClicked = () => 
  {
    this.thisDialogRef.close('Cancel');
  }
  ngOnDestroy(){
    console.log("confirm-dialog.component - ngOnDestroy");
  }
}
