
 
  
  <!-- <mat-divider [vertical]="true" class="toolbar-separator"></mat-divider>
        <div class="clsheadermenu" fxShow fxHide.xs="true"></div>
    <button mat-icon-button (click)="this.toggleRightNav()"  >
        <mat-icon aria-hidden="true">menu</mat-icon> -->
        
        <!-- <button id="btnsaveclick" class="clsbtnAssetaddSave" (click)="this.toggleRightNav()" mat-raised-button>Menu</button> -->
        
        <div fxflex="0 1 auto" fxlayout="row" fxlayoutalign="start center" class="clsuserheader" style="flex-direction: row; box-sizing: border-box; display: flex; 
        place-content: center flex-start; align-items: center; flex: 0 1 auto;">  
        <div fxlayout="row" style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-start; align-items: center; flex: 0 1 auto;">
          <!-- <label id="lblConnectionStatus"  class="lblConnectionStatus">{{lblConnectionStatustxt}}</label>
                <div id="divConnectionStatus" [class]="chkConnectionStatus" matTooltipClass="tooltip" matTooltip="{{chkdivConnectionStatus == 0 ? 'Not Connected to backend,new data not coming in.' :  'Connected to backend'}}" #tooltip="matTooltip" matTooltipPosition="above"></div> -->
              
            <button aria-haspopup="true" style="height:65px;padding-right: 0px !important;padding-left: 5px !important;" mat-button="" [matMenuTriggerFor]="menu">
              <span class="mat-button-wrapper">
              <div fxlayout="row" fxlayoutalign="center center" style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center; align-items: center;">              
                        
                 
                <h3 class="mr-12" *ngIf="userfullname.length <= 12" fxShow fxHide.xs="true" class='clsheaderstaffdetails'>{{username}}</h3>
                <h3 class="mr-12" *ngIf="userfullname.length > 12" fxShow fxHide.xs="true" class='clsheaderstaffdetails' matTooltipClass="cusgeotooltip" matTooltip={{userfullname}} #tooltip="matTooltip" matTooltipPosition="above">{{username}}</h3>
                <mat-icon class="s-16 mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true" fxShow fxHide.xs="true">keyboard_arrow_down</mat-icon>
                <img class="avatar mr-0 mr-sm-16"   [src]= "staffImgUrl">
                <div matTooltipClass="tooltip" matTooltip="{{chkdivConnectionStatus == 0 ? 'Disconnected' :  'Connected'}}" #tooltip="matTooltip" matTooltipPosition="above">
                    <img class="avatar clsConnectionStatus" [src]="chkImageConnectionStatus">
                </div> 
              </div>  
                                              
                </span>
                </button>
              
                <mat-menu #menu="matMenu" xPosition="before" >
                    <div class="mat-menu-content"  >
                      <button class="mat-menu-item" (click)="Logout()" mat-menu-item="" role="menuitem" tabindex="0" aria-disabled="false">
                      <mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">exit_to_app</mat-icon>
                      <span  >Logout</span><div class="mat-menu-ripple mat-ripple" matripple=""></div>
                      </button>
                    </div>
                </mat-menu>
        </div>
        
        <mat-divider [vertical]="true" class="toolbar-separator"></mat-divider>
        <div class="clsheadermenu" fxShow fxHide.xs="true"></div>
        <button mat-icon-button (click)="this.toggleRightNav()"  >
          <mat-icon aria-hidden="true">menu</mat-icon></button>
        </div>