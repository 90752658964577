<div id="AlertInfo" style="display: none; position: absolute; left: 100px; top: 100px; border: 1px solid gray;">
    <div id="spn_MapNotifyCampusId">{{CampusId}}</div>
    <div id="spn_MapNotifyBuildingId">{{BuildingId}}</div>
    <div id="spn_MapNotifyFloorId">{{FloorId}}</div>
    <div id="spn_MapNotifyRoomId">{{RoomId}}</div>
    <div id="spn_MapNotifyAlertId">{{AlertId}}</div>
    <div id="spn_MapNotifyAlertStatus">{{CampusId}}</div>
    <div id="spn_MapNotifyAlertResStaff">{{AlertResStaff}}</div>
</div>

<div id="spn_mapnotification" class="mapnotify" style="position:relative; " (click)="myClickFunction($event)">    
</div>