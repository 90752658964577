import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ViewEncapsulation,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { Subject } from "rxjs";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { StaffService } from "../staff.service";
import {
  StaffForCreationResponse,
  checkPhoneNumberOtp,
  checkEmailOtp,
} from "../staff";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { appsettings } from "../../../app-settings";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-verify-staff",
  templateUrl: "./verify-staff.component.html",
  providers: [StaffService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./verify-staff.component.scss"],
})
export class VerifyStaffComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 3;
  public staffVerificationForm: UntypedFormGroup;
  countrycode;
  countrycodetext;
  staffphonenumber;
  staffotpvalue = 0;
  verifystaffcode = true;
  private staffURL = appsettings.URLinfo + "request_OTP_Verification";
  staffverifyotpresult;
  checkStaffVerifyflag;
  verifyStaffId;
  verifyOTPNumber;
  boolstaffphoneotpverify = true;
  boolstaffemailotpverify = true;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,    
    private staffService: StaffService,
    public thisDialogRef: MatDialogRef<VerifyStaffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());     

    if (data.StaffFlag == 0) {
      this.staffphonenumber = data.StaffVerify;
      if (this.staffphonenumber.length == 10) {
        this.staffphonenumber = data.StaffVerify;
        this.countrycode = localStorage.getItem("country_code");
      }
    } else {
      this.staffphonenumber = data.StaffVerify;
    }

    this.checkStaffVerifyflag = data.StaffFlag;
    this.verifyStaffId = data.StaffId;
    this.verifyOTPNumber = data.OTPNumber;
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.staffVerificationForm = new UntypedFormGroup({
      contrycode: new UntypedFormControl(""),
      staffphonenumber: new UntypedFormControl(""),
      otpnumber: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(4),
      ]),
    });

    const staffForm_contrycode = this.staffVerificationForm.get("contrycode");
    const staffForm_staffphonenumber =
      this.staffVerificationForm.get("staffphonenumber");
    if (staffForm_contrycode != null) staffForm_contrycode.disable();
    if (staffForm_staffphonenumber != null)
      staffForm_staffphonenumber.disable();
  }

  public onCloseCancel = () => {
    if (!SessionCheck(this.router)) {
      return;
    }
    this.thisDialogRef.close("Cancel");
  };

  public VerifyStaffNumber = (staffVerificationForm) => {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (
        this.staffVerificationForm.valid &&
        (this.boolstaffphoneotpverify || this.boolstaffemailotpverify)
      ) {
        if (this.boolstaffphoneotpverify) this.boolstaffphoneotpverify = false;
        if (this.boolstaffemailotpverify) this.boolstaffemailotpverify = false;
        this.verifyPhoneNumberEmailOTP(staffVerificationForm);
      }
    } catch (err) {
      throw new Error(
        "verify-staff.component.ts" + " - VerifyStaffNumber() - " + err.message
      );
    }
  };

  verifyPhoneNumberEmailOTP(staffVerificationForm) {
    try {
      if (this.checkStaffVerifyflag == 0) {
        const staffverifyotpnumber: checkPhoneNumberOtp = {
          authKey: this.authkey,
          phoneNumber: this.staffphonenumber.toString(),
          countryCode: this.countrycode.toString(),
          otpNumber: staffVerificationForm.otpnumber.toString(),
        };
        this.staffService
          .verifyPhoneNumberOTP(staffverifyotpnumber)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: StaffForCreationResponse[]) => {
            if (apiresponse.toString() == "true") {
              this.staffotpvalue = 1;
              this.boolstaffphoneotpverify = false;
              generateAlertMessage(
                this.dialog,
                "Your mobile number Enrolled successfully."
              );
              this.thisDialogRef.close(this.staffotpvalue);
            } else if (apiresponse.toString() == "false") {
              this.boolstaffphoneotpverify = true;
              this.staffotpvalue = 0;
              generateAlertMessage(
                this.dialog,
                "Verification code is incorrect"
              );
            }
          });
      } else if (this.checkStaffVerifyflag == 1) {
        if (this.verifyOTPNumber > 0) {
          if (staffVerificationForm.otpnumber == this.verifyOTPNumber) {
            this.staffotpvalue = 1;
            this.boolstaffemailotpverify = false;
            generateAlertMessage(
              this.dialog,
              "Your email Id Enrolled successfully."
            );
            this.thisDialogRef.close(this.staffotpvalue);
          } else if (staffVerificationForm.otpnumber != this.verifyOTPNumber) {
            this.boolstaffemailotpverify = true;
            this.staffotpvalue = 0;
            generateAlertMessage(this.dialog, "Verification code is incorrect");
          }
        } else {
          const staffverifyotpnumber: checkEmailOtp = {
            authKey: this.authkey,
            staffId: this.verifyStaffId.toString(),
            otpNumber: staffVerificationForm.otpnumber.toString(),
          };
          this.staffService
            .verifyEmailOTP(staffverifyotpnumber)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: StaffForCreationResponse[]) => {
              if (apiresponse.toString() == "true") {
                this.staffotpvalue = 1;
                this.boolstaffemailotpverify = false;
                generateAlertMessage(
                  this.dialog,
                  "Your email Id Enrolled successfully."
                );
                this.thisDialogRef.close(this.staffotpvalue);
              } else if (apiresponse.toString() == "false") {
                this.boolstaffemailotpverify = true;
                this.staffotpvalue = 0;
                generateAlertMessage(
                  this.dialog,
                  "Verification code is incorrect"
                );
              }
            });
        }
      }
    } catch (err) {
      throw new Error(
        "verify-staff.component.ts" +
          " - verifyPhoneNumberEmailOTP() - " +
          err.message
      );
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
