import { Component, OnInit, Inject, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import {
  Escalationdetails,
  GetBuildNotify,
  GetFloorNotify,
  getNotification,
  getNotificationById,
  getCampusForNotification,
  getNotificationCount,
  GetSubZoneNotify
} from "./notification";
import { notificationService } from "./notification.service";
import { NotificationLeftbarComponent } from "../notification/notification-leftbar/notification-leftbar.component";
import { ViewNotificationComponent } from "./view-notification/view-notification.component";
import {
  appsettings,
  enumAlertSubType,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumEnableDisable,
} from "../../app-settings";
import { MainLayoutService } from "../../main-layout/main-layout.service";
import { SidenavComponent } from "../../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { RollbarService } from "../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheckwithStaff,
  checkStaffData,
  generateAlertMessage,
  getFeatureNeedtoShow,
  getAddEditDeleteColumn,
  AuthFailedMessage,
} from "../../app-general";
import { ApiService } from "../../api.service";
import { AlertSharedService } from "../../_services/index";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UntypedFormControl } from "@angular/forms";
import { TooltipPosition } from "@angular/material/tooltip";
import {
  Campus,
  Building,
  Floor,
  SubZones
} from "../technologyoverlay/technologyoverlay";
import { TechnologyoverlayService } from "../technologyoverlay/technologyoverlay.service";
import { PerfectScrollbarComponent } from "ngx-perfect-scrollbar";
import { Observable } from "rxjs";

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  providers: [notificationService, NotificationLeftbarComponent],
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit, OnDestroy {
  bAddEdit = false;
  bDelete = false;
  checkicon = 0;
  escalationData;
  arrowiconT = true;
  arrowiconF = false;
  preId = -1;
  collapsedIcon1 = "keyboard_arrow_down";
  collapsedIcon = "keyboard_arrow_down";
  Notificationdetails: any[];
  Escalationdetail: Escalationdetails[];
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  authkey = localStorage.getItem("Authkey") ?? "";
  displayedColumns: string[] = [
    "Level",
    "AlarmNameId",
    "TimetoEscalate",
    "RepeatCycle",
    "Notify",
    "CampusName",
    "BuildingName",
    "FloorName",
    "Edit",
    "Delete",
  ];
  dataSource;
  item1 = {
    authKey: this.authkey,
    alertId: 1,
    isOldUI: "0",
  };
  LevelImgURL = "assets/images/";
  escalation;
  AlarmId = 1;
  esclationcount = -1;
  esclationCampus = -1;
  esclationBuilding = -1;
  esclationFloor = -1;
  esclationsubtypeid = -1;
  deleteMSG;
  notifyloading = false;
  Building_Id;
  Building_Name;
  Floor_Id;
  Floor_Name;
  columns = 4;
  Campus: Campus[];
  Building: Building[];
  Floor: Floor[];
  SubZone: SubZones[];

  buildingcampusname = "";
  buildingcampusfullname = "";
  floorcampusname = "";
  floorcampusfullname = "";
  floorbuildingname = "";
  floorbuildingfullname = "";
  subzonecampusname = '';
  subzonecampusfullname = '';
  subzonebuildingname = '';
  subzonebuildingfullname = '';
  subzonefloorname = ''; 
  subzonefloorfullname = '';

  selectedcampusName = "";
  selectedcampus = 0;
  selectedCampusImage = "";
  selectedbuilding = 0;
  selectedfloor = 0;
  selectedsubzone = 0; 
  selectedunit = 0;

  CampusCnt = 0;
  BuildingCnt = 0;
  FloorCnt = 0;
  SubZoneCnt = 0;
  UnitCnt = 0;
  IsBuildingFloor = 1;
  IsFloor = 1;
  isSubZone = 1;
  
  getbuildinginfo;
  getselectedCampus;

  addcampusid = 0;
  addbuildingid = 0;
  addfloorid = 0;

  build_arrowiconT = true;
  build_arrowiconF = false;

  floor_arrowiconT = true;
  floor_arrowiconF = false;
  
  subzone_arrowiconT = true;
  subzone_arrowiconF = false;

  unit_arrowiconT = true;
  unit_arrowiconF = false;

  camp_isbuttonshow = 0;
  build_isbuttonshow = 0;
  floor_isbuttonshow = 0;
  subzone_isbuttonshow = 0;
  isSubZoneShow = 0;

  collapsedIcon1_build = "keyboard_arrow_up";
  collapsedIcon_build = "keyboard_arrow_up";

  collapsedIcon1_floor = "keyboard_arrow_up";
  collapsedIcon_floor = "keyboard_arrow_up";
  
  collapsedIcon1_subzone = "keyboard_arrow_up";
  collapsedIcon_subzone = "keyboard_arrow_up";

  collapsedIcon1_unit = "keyboard_arrow_up";
  collapsedIcon_unit = "keyboard_arrow_up";

  campusImgUrl: string = appsettings.CAMPUS_IMAGE_FOLDER;
  floorImgUrl: string = appsettings.FLOOR_IMAGE_FOLDER;
  ImageUrl = "";
  public watcher: Observable<boolean>;

  matTooltip =
    "No Campus Notification Level set, campus must have at least 1 notification level before custom notification levels can be created.";
  matTooltip_NoBuildingFloor =
    "There are no buildings or zones on this campus with custom notifications enabled.";
  matTooltip_NoBuilding =
    "This building does not have custom notification levels selected, one or more of it's zones do. Click to building to see relevant zones.";
  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = new UntypedFormControl(this.positionOptions[3]);  
  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private notificationService: notificationService,
    private alarmtype: NotificationLeftbarComponent,    
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar,
    public mainLayoutService: MainLayoutService,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if (getFeatureNeedtoShow(enumUIFeatureView.Notification,enumUIAction.Notification_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      this.escalation = "Create";
      this.columns = 2;
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium))
    {
      this.escalation = "Create";
      this.columns = 2;
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.escalation = "Create";
      this.columns = 2;
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.escalation = "Create";
      this.columns = 1;
    }
  }

  // set table width and columns
  setupTableforXtrasmallerwidth() {
    this.displayedColumns = [
      "CampusName",
      "BuildingName",
      "FloorName",
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableforsmallerwidth() {
    this.displayedColumns = [
      "Level",
      "CampusName",
      "BuildingName",
      "FloorName",
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableformdwidth() {
    this.displayedColumns = [
      "Level",
      "Notify",
      "CampusName",
      "BuildingName",
      "FloorName",
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupFullTable() {
    this.displayedColumns = [
      "Level",
      "AlarmNameId",
      "TimetoEscalate",
      "RepeatCycle",
      "Notify",
      "CampusName",
      "BuildingName",
      "FloorName",
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Notification,
          enumUIAction.Notification_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }

      this.getNotification(this.item1);
      this.getCampusDetailsForNotification();
      this.getStaffById();
      this.getselectmapcount(0, 0, 0, 0);
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }
  getStaffById() {
    checkStaffData(this.apiService, this.alertSharedService);
  }
  Onalarmclick(EventName) {
    try {
      this.AlarmId = parseInt(EventName);
      this.arrowiconT = true;
      this.arrowiconF = false;
      this.esclationsubtypeid = -1;
      this.esclationcount = -1;
      this.esclationCampus = -1;
      this.esclationBuilding = -1;
      this.esclationFloor = -1;
      this.collapsedIcon1 = "keyboard_arrow_down";
      this.checkicon = 0;
      this.camp_isbuttonshow = 0;
      this.build_isbuttonshow = 0;
      this.floor_isbuttonshow = 0;
      this.selectedcampus = 0;
      this.selectedCampusImage = "";
      this.selectedbuilding = 0;
      this.selectedfloor = 0;
      this.selectedsubzone = 0;
      this.selectedunit = 0;
      this.selectedcampusName = "";
      this.buildingcampusname = "";
      this.buildingcampusfullname = "";
      this.floorcampusname = "";
      this.floorcampusfullname = "";
      this.floorbuildingname = "";
      this.floorbuildingfullname = "";
      this.subzonecampusname = '';
      this.subzonecampusfullname = '';
      this.subzonebuildingname = '';
      this.subzonebuildingfullname = '';
      this.subzonefloorname = ''; 
      this.subzonefloorfullname = ''; 
      this.isSubZoneShow = 0;
     
      const notificationRequest: getNotification = {
        authKey: this.authkey,
        alertId: this.AlarmId,
      };
      this.getNotification(notificationRequest);
      this.getCampusDetailsForNotification();
      this.getselectmapcount(0, 0, 0, 0);
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - Onalarmclick() - " + err.message
      );
    }
  }

  //get campus details
  getNotification(notificationRequest): void {
    try {
      this.notificationService
        .getNotification(notificationRequest)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Notificationdetails: any[]) => {
          if (Notificationdetails != null && Notificationdetails != undefined) {
            if (Notificationdetails["apiReturn"] == "Invalid Auth Key") {
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            this.Notificationdetails = Notificationdetails["apiReturn"];

            this.Notificationdetails.forEach(function (e) {
              if (e.AlarmSubTypeId == enumAlertSubType.Low_Batteries)
                e["SubName"] = "LB";
              else if (e.AlarmSubTypeId == enumAlertSubType.Lost_Communication)
                e["SubName"] = "LC";
              else if (e.AlarmSubTypeId == enumAlertSubType.Failure)
                e["SubName"] = "Failure";
              else if (
                e.AlarmSubTypeId ==
                enumAlertSubType.Check_In_Report_Notification
              )
                e["SubName"] = "CIN";
              else if (e.AlarmSubTypeId == enumAlertSubType.ADL_Alarm)
                e["SubName"] = "ADL";
              else if (e.AlarmSubTypeId == enumAlertSubType.Fire_Alarm)
                e["SubName"] = "Fire";
              else if (e.AlarmSubTypeId == enumAlertSubType.Nurse_Call_Alarm)
                e["SubName"] = "NurseCall";
              else if (e.AlarmSubTypeId == enumAlertSubType.Environmental)
                e["SubName"] = "EM";
              else if (e.AlarmSubTypeId == enumAlertSubType.Tag_In_Field)
                e["SubName"] = "TIF";
              else if (e.AlarmSubTypeId == enumAlertSubType.Unauthorized)
                e["SubName"] = "UE";
              else if (e.AlarmSubTypeId == enumAlertSubType.Lotiering)
                e["SubName"] = "Loitering";

              if (e.Buildingname != "") {
                e["NewBuildingName"] = e.Buildingname.trim();
              } else e["NewBuildingName"] = "";

              if (e.FloorName != "") {
                e["NewFloorName"] = e.FloorName.trim();
              } else e["NewFloorName"] = "";
            });
          }
        });
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - getNotification() - " + err.message
      );
    }
  }

  //show escalation details
  clickcollapse(val, data, CampusId, AlarmSubTypeId, BuildId, FloorId, SubzoneId) {
    try {
      this.NotifyEnableDisable(enumEnableDisable.Disable);
      this.dataSource = "";
      this.checkicon = val;
      this.esclationcount = -1;
      this.esclationCampus = -1;
      this.esclationBuilding = -1;
      this.esclationFloor = -1;
      this.esclationsubtypeid = -1;

      const campusdetails: getNotificationById = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
        alarmId: this.AlarmId,
        buildId: parseInt(BuildId),
        floorId: parseInt(FloorId),
        subZoneId:parseInt(SubzoneId)
      };
      this.getNotificationRecipient(campusdetails, AlarmSubTypeId);
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - clickcollapse() - " + err.message
      );
    }
  }

  NoEditNotifications(type) {
    if(type == 1)
        generateAlertMessage(this.dialog, "This building does not have custom notification levels selected, one or more of it's zone do. Click to building to see relevant zones.");
    else
        generateAlertMessage(this.dialog, "This zone does not have custom notification levels selected, one or more of it's sub-zone do. Click to zone to see relevant sub-zones.");
  }


  editNotifications(CampusId, BuildId, FloorId, SubZoneId) {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }

    const Escdetails: any = {
      CampusId: CampusId,
      BuildId: BuildId,
      FloorId: FloorId,
      SubZoneId: SubZoneId,
      AlarmId: this.AlarmId,
    };

    const dialogRef = this.dialog.open(ViewNotificationComponent, {
      disableClose: true,
      width: "900px",
      height: "600px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: Escdetails,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Cancel") {
        const item = {
          authKey: this.authkey,
          alertId: this.AlarmId,
          isOldUI: "0",
        };
        this.getNotification(item);
        this.getselectmapcount(
          this.selectedcampus,
          this.selectedbuilding,
          this.selectedfloor,
          this.selectedsubzone
        );
        if(SubZoneId != 0 && SubZoneId != undefined)
        {
          this.getSubZoneDetailsForNotification(this.selectedfloor,this.subzonefloorname);
        }
        else if (FloorId != 0) {
          this.getFloorDetailsForNotification(
            this.selectedbuilding,
            this.floorbuildingname
          );
        } else if (BuildId != 0) {
          this.getBuildingDetailsForNotification(
            this.selectedcampus,
            this.selectedcampusName,
            this.ImageUrl
          );
        } else if (CampusId != 0) {
          this.getCampusDetailsForNotification();
        }
      }
    });
  }

  getNotificationRecipient(notificationRequest, AlarmSubTypeId) {
    this.notificationService
      .getNotificationRecipient(notificationRequest)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((Escalationdetail: any[]) => {
        if (
          Escalationdetail != null &&
          Escalationdetail != undefined &&
          Escalationdetail.length > 0
        ) {
          this.Escalationdetail = Escalationdetail;
          const NotifyLevel = this.Escalationdetail.filter(function (e1) {
            return e1; //.AlarmSubTypeId == AlarmSubTypeId;
          });
          this.esclationcount = NotifyLevel.length;
          this.esclationCampus = notificationRequest.campusId;
          this.esclationsubtypeid = AlarmSubTypeId;
          this.Building_Id = Escalationdetail[0].BuildId;
          this.esclationBuilding = notificationRequest.buildId;
          this.Building_Name = Escalationdetail[0].BuildingName;
          this.Floor_Id = Escalationdetail[0].FloorId;
          this.esclationFloor = notificationRequest.floorId;
          this.Floor_Name = Escalationdetail[0].FloorName;
          this.dataSource = new MatTableDataSource(NotifyLevel);
          this.dataSource.data = NotifyLevel;

          this.NotifyEnableDisable(enumEnableDisable.Enable);
        } else {
          this.esclationcount = Escalationdetail.length;
          this.NotifyEnableDisable(enumEnableDisable.Enable);
        }
        const _matTargetId = document.getElementById("matTargetId");
        if (_matTargetId != null) _matTargetId.setAttribute("id", "");

        this.notifyloading = false;
        const selectedrow = document.getElementsByClassName("mat-header-row");
        selectedrow[0].setAttribute("id", "targetId");
        document
          .getElementById("targetId")
          .parentElement.parentElement.parentElement.parentElement.setAttribute(
            "id",
            "matTargetId"
          );
        if (_matTargetId != null) _matTargetId.scrollIntoView();
      });
  }

  trackBycount(index: number, Notificationdetails: any): string {
    return Notificationdetails.totcnt;
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  getCampusDetailsForNotification(): void {
    try {
      const campusdata: getCampusForNotification = {
        authKey: this.authkey,
        eventId: this.AlarmId,
      };
      this.notificationService
        .getCampusDetailsForNotification(campusdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Campusinfo: any[]) => {
          this.Campus = Campusinfo;
          this.CampusCnt = Campusinfo.length;
          if (this.componentRef != undefined)
            this.componentRef["interaction"] = true;
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" +
          " - getCampusDetailsForNotification() - " +
          err.message
      );
    }
  }

  getBuildingDetailsForNotification(
    campusId: number,
    campusName: string,
    ImageUrl: string
  ) {
    try {
      this.notifyloading = true;
      const campusbyid: GetBuildNotify = {
        authKey: this.authkey,
        campusId: campusId,
        eventId: this.AlarmId,
      };

      this.getselectedCampus = campusId;
      if (this.getbuildinginfo != undefined) {
        this.getbuildinginfo.CampusId = campusId;
        this.getbuildinginfo.CampusName = campusName;
      }
      this.addcampusid = campusId;
      this.selectedcampus = campusId;
      this.selectedcampusName = campusName;
      this.getselectmapcount(this.selectedcampus, 0, 0, 0);
      this.selectedCampusImage = ImageUrl;
      this.ImageUrl = ImageUrl;
      this.selectedbuilding = 0;
      this.selectedfloor = 0;
      this.selectedsubzone = 0;
      this.selectedunit = 0;
      this.Floor = [];
      this.floorcampusfullname = "";
      this.floorcampusname = "";
      this.floorbuildingfullname = "";
      this.floorbuildingname = "";
      this.subzonecampusname = "";
      this.subzonecampusfullname = "";
      this.subzonebuildingname = "";
      this.subzonebuildingfullname = "";
      this.subzonefloorname = ""; 
      this.subzonefloorfullname = ""; 

      if (campusName.length > 15) {
        this.buildingcampusfullname = campusName;
        this.buildingcampusname = campusName.slice(0, 15) + "..";
      } else {
        this.buildingcampusfullname = "";
        this.buildingcampusname = campusName;
      }
      this.preId = 0;

      this.notificationService
        .getBuildingDetailsForNotification(campusbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Buildinginfo: any[]) => {
          const _this = eval('this');//todo
          _this.Building = [];
          Buildinginfo.forEach(function (e) {
            if (e.Notifications > 0 || e.NeedToshow > 0)
              _this.Building[_this.Building.length] = e;
          });
          if (this.componentRef != undefined)
            this.componentRef["interaction"] = true;
        });

      this.clickcollapse_mapdetails(0, 2);
      this.clickcollapse(
        1,
        [],
        this.selectedcampus,
        "",
        this.selectedbuilding,
        this.selectedfloor,
        this.selectedsubzone
      );
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" +
          " - getBuildingDetailsForNotification() - " +
          err.message
      );
    }
  }

  getFloorDetailsForNotification(buildId: number, buildingName: string) {
    try {
      this.notifyloading = true;
      let Campus_Id;
      let campusName;
      const buildbyid: GetFloorNotify = {
        authKey: this.authkey,
        buildId: buildId,
        eventId: this.AlarmId,
      };

      if (this.getbuildinginfo != undefined) {
        this.getbuildinginfo.BuildId = buildId;
        this.getbuildinginfo.BuildingName = buildingName;
      }

      this.addbuildingid = buildId;
      this.selectedbuilding = buildId;
      this.SubZone = [];
      this.subzonecampusname = "";
      this.subzonecampusfullname = "";
      this.subzonebuildingname = "";
      this.subzonebuildingfullname = "";
      this.subzonefloorname = ""; 
      this.subzonefloorfullname = ""; 
      this.getselectmapcount(this.selectedcampus, this.selectedbuilding, 0, 0);
      // this.floorbuildingname = buildingName;

      this.floorcampusfullname = this.buildingcampusfullname;
      this.floorcampusname = this.buildingcampusname;

      if (this.getbuildinginfo == undefined) Campus_Id = this.selectedcampus;
      else {
        Campus_Id = this.getbuildinginfo.CampusId;
        campusName = this.getbuildinginfo.CampusName;
        if (campusName.length > 15) {
          this.floorcampusfullname = campusName;
          this.floorcampusname = campusName.slice(0, 15) + "..";
        } else {
          this.floorcampusfullname = "";
          this.floorcampusname = campusName;
        }
        // this.build_isbuttonshow = 1;
      }
      console.log(Campus_Id)
      if (buildingName.length > 15) {
        this.floorbuildingfullname = buildingName;
        this.floorbuildingname = buildingName.slice(0, 15) + "..";
      } else {
        this.floorbuildingfullname = "";
        this.floorbuildingname = buildingName;
      }

      this.selectedfloor = 0;
      this.selectedunit = 0;
      this.selectedsubzone = 0;
      this.notificationService
        .getFloorDetailsForNotification(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Floorinfo: any[]) => {
          const _this = eval('this');  //todo
          _this.Floor = [];
          Floorinfo.forEach(function (e) {
            if (e.Notifications > 0) _this.Floor[_this.Floor.length] = e;
          });
          if (this.componentRef != undefined)
            this.componentRef["interaction"] = true;
        });

      this.preId = 0;
      this.clickcollapse_mapdetails(0, 3);
      this.clickcollapse(
        2,
        [],
        this.selectedcampus,
        "",
        this.selectedbuilding,
        this.selectedfloor,
        this.selectedsubzone
      );
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" +
          " - getFloorDetailsForNotification() - " +
          err.message
      );
    }
  }
  
getSubZoneDetailsForNotification(FloorId,FloorName)
{
  try
  {
    this.isSubZoneShow = 1;
    this.notifyloading = true;
    let Campus_Id;
    let campusName;
    let build_id;
    let buildingName;
    const floorbyid : GetSubZoneNotify = {        
        authKey: this.authkey,
        floorId: FloorId,   
        eventId: this.AlarmId,     
      }
      // let buildingbyname: BuildingByName = {
      //   BuildingName: buildingName
      // }

      if(this.getbuildinginfo != undefined)
      {
        this.getbuildinginfo.FloorId = FloorId;
        this.getbuildinginfo.FloorName = FloorName;
      }

      this.selectedsubzone = 0;
      this.selectedunit = 0;
      this.addfloorid = FloorId;
      this.selectedfloor = FloorId;
      this.getselectmapcount(this.selectedcampus,this.selectedbuilding,this.selectedfloor,0);
      // this.floorbuildingname = buildingName;

      this.subzonecampusfullname = this.buildingcampusfullname;
      this.subzonecampusname = this.buildingcampusname; 
      
      this.subzonebuildingfullname = this.floorbuildingfullname;
      this.subzonebuildingname = this.floorbuildingname; 

      if(this.getbuildinginfo == undefined)
        Campus_Id = this.selectedcampus;
      else
      {
        Campus_Id = this.getbuildinginfo.CampusId;
        campusName = this.getbuildinginfo.CampusName;
        console.log(Campus_Id);
        if(campusName.length > 15)
        {
          this.subzonecampusfullname = campusName;
          this.subzonecampusname = campusName.slice(0,15) + "..";
        }
        else
        {
          this.subzonecampusfullname = '';
          this.subzonecampusname = campusName;
        }
        // this.build_isbuttonshow = 1;
      }
      
      if(this.getbuildinginfo == undefined)
        build_id = this.selectedbuilding;
      else
      {
        build_id = this.getbuildinginfo.BuildId;
        buildingName = this.getbuildinginfo.BuildingName;
        console.log(build_id);
        if(buildingName.length > 15)
        {
          this.subzonebuildingfullname = buildingName;
          this.subzonebuildingname = buildingName.slice(0,15) + "..";
        }
        else
        {
          this.subzonebuildingfullname = "";
          this.subzonebuildingname = buildingName;
        }
      }
              
      if(FloorName.length > 15)
      {
        this.subzonefloorfullname = FloorName;
        this.subzonefloorname = FloorName.slice(0,15) + "..";
      }
      else
      {
        this.subzonefloorfullname = "";
        this.subzonefloorname = FloorName;
      }      
          
      // this.selectedfloor = 0;
      this.selectedunit = 0;
      this.notificationService.getSubZoneDetailsForNotification(floorbyid).pipe(takeUntil(this._onDestroy))
        .subscribe((SubZoneinfo: any[]) => {
          
          const _this = eval('this');
          _this.SubZone = [];
          SubZoneinfo.forEach(function (e) {
            if (e.Notifications > 0)
              _this.SubZone[_this.SubZone.length] = e;
          });
          if(this.componentRef != undefined)
            this.componentRef["interaction"]=true;
          
        });
        
        this.preId = 0;
        this.clickcollapse_mapdetails(0,4);
        this.clickcollapse(3,[],this.selectedcampus,"",this.selectedbuilding,this.selectedfloor,this.selectedsubzone)
  }
  catch (err) {
        throw new Error('floorplans.component.ts' + ' - getFloorDetailsForNotification() - ' + err.message);
      }
}

  getselectmapcount(CampusId, BuildId, FloorId, SubZoneId) {
    const notificationRequest: getNotificationCount = {
      authKey: this.authkey,
      campusId: CampusId,
      buildingId: BuildId,
      zoneId: FloorId,
      subzoneId: SubZoneId
    };

    this.notificationService
      .getNotificationCount(notificationRequest)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((Mapcountinfo: any[]) => {
        this.CampusCnt = Mapcountinfo[0].CampusCnt;
        this.BuildingCnt =
          Mapcountinfo[0].BuildingCnt + Mapcountinfo[0].BuildingViewCnt;
        this.FloorCnt = Mapcountinfo[0].FloorCnt;
        this.SubZoneCnt = Mapcountinfo[0].SubZoneCnt;

        if (
          this.selectedcampus != 0 &&
          this.BuildingCnt == 0 &&
          this.FloorCnt == 0 && this.SubZoneCnt == 0
        ) {
          this.IsBuildingFloor = 0;
          this.IsFloor = 0;
          this.isSubZone = 0;
          this.checkicon = 0;
          this.selectedbuilding = 0;
          this.selectedfloor = 0;
          this.selectedsubzone = 0;
          this.camp_isbuttonshow = 0;
          this.clickcollapse_mapdetails(0, 1);
          this.clickcollapse(0, [], 0, "", 0, 0, 0);
        } else if (this.selectedbuilding != 0 && this.FloorCnt == 0 && this.SubZoneCnt == 0) {
          this.IsBuildingFloor = 1;
          this.IsFloor = 0;
          this.isSubZone = 0;
          this.checkicon = 1;
          this.selectedfloor = 0;
          this.selectedsubzone = 0;
          this.build_isbuttonshow = 0;
          this.clickcollapse_mapdetails(0, 2);
          this.clickcollapse(1, [], 0, "", 0, 0, 0);
        } 
        else if (this.selectedbuilding != 0 && this.FloorCnt != 0 && this.SubZoneCnt == 0) {
          this.IsBuildingFloor = 1;
          this.IsFloor = 1;
          this.isSubZone = 0;
          this.checkicon = 1;
          this.selectedfloor = 0;
          this.selectedsubzone = 0;
          this.build_isbuttonshow = 0;
          this.clickcollapse_mapdetails(0,3);
          this.clickcollapse(2,[],0,"",0,0,0)
        }
        else {
          this.IsBuildingFloor = 1;
          this.IsFloor = 1;
          this.isSubZone = 1;
        }
      });
  }

  clickcollapse_mapdetails(val, Type) {
    this.checkicon = val;
    if (Type == 1) {
      if (this.preId == 0) {
        this.collapsedIcon1 = "keyboard_arrow_down";
        this.arrowiconT = true;
        this.build_isbuttonshow = 0;
        this.floor_isbuttonshow = 0;
      }
    } else if (Type == 2) {
      if (this.preId == 0) {
        this.collapsedIcon1_build = "keyboard_arrow_down";
        this.build_arrowiconT = true;
        this.build_isbuttonshow = 0;
        this.floor_isbuttonshow = 0;

        this.camp_isbuttonshow = 1;
        this.collapsedIcon1 = "keyboard_arrow_up";
        this.arrowiconT = false;
      }
    } else if (Type == 3) {
      if (this.preId == 0) {
        this.camp_isbuttonshow = 1;
        this.collapsedIcon1 = "keyboard_arrow_up";
        this.arrowiconT = false;

        this.build_isbuttonshow = 1;
        this.collapsedIcon1_build = "keyboard_arrow_up";
        this.build_arrowiconT = false;

        this.collapsedIcon1_floor = "keyboard_arrow_down";
        this.floor_arrowiconT = true;
        this.floor_isbuttonshow = 0;
        
        this.collapsedIcon1_subzone = "keyboard_arrow_up";
        this.subzone_arrowiconT = true;
        this.subzone_isbuttonshow = 0;
      }
    } 
    else if(Type == 4)
    {
      if(this.preId == 0)
      {                
        this.camp_isbuttonshow = 1;        
        this.collapsedIcon1 = "keyboard_arrow_up";
        this.arrowiconT = false;
        
        this.build_isbuttonshow = 1;
        this.collapsedIcon1_build = "keyboard_arrow_up";
        this.build_arrowiconT = false;
        
        this.floor_isbuttonshow = 1;
        this.collapsedIcon1_floor = "keyboard_arrow_up";
        this.floor_arrowiconT = false;
        
        this.collapsedIcon1_subzone = "keyboard_arrow_down";
        this.subzone_arrowiconT = true;
        this.subzone_isbuttonshow = 0;
        
        this.collapsedIcon1_unit = "keyboard_arrow_up";
        this.unit_arrowiconT = false;        
      } 
    }
    else if (Type == 5) {
      if (this.preId == 0) {
        this.camp_isbuttonshow = 1;
        this.collapsedIcon1 = "keyboard_arrow_up";
        this.arrowiconT = false;

        this.build_isbuttonshow = 1;
        this.collapsedIcon1_build = "keyboard_arrow_up";
        this.build_arrowiconT = false;

        this.floor_isbuttonshow = 1;
        this.collapsedIcon1_floor = "keyboard_arrow_up";
        this.floor_arrowiconT = false;
        
        this.collapsedIcon1_subzone = "keyboard_arrow_down";
        this.subzone_arrowiconT = false;
        this.subzone_isbuttonshow = 0;

        this.collapsedIcon1_unit = "keyboard_arrow_down";
        this.unit_arrowiconT = true;
      }
    }
  }

  clkdeselectmapitem(selType) {
    if (selType == 1) {
      this.collapsedIcon1 = "keyboard_arrow_down";
      this.arrowiconT = true;
      this.isSubZoneShow = 0;
      this.collapsedIcon1_build = "keyboard_arrow_up";
      this.build_arrowiconT = false;
      this.buildingcampusname = "";
      this.camp_isbuttonshow = 0;
      this.build_isbuttonshow = 0;
      this.selectedbuilding = 0;
      this.floorcampusname = "";
      this.floorcampusfullname = "";
      this.collapsedIcon1_floor = "keyboard_arrow_up";
      this.floor_arrowiconT = false;
      this.floorbuildingname = "";
      this.floor_isbuttonshow = 0;
      this.subzonecampusname = '';
      this.subzonebuildingname = '';
      this.subzonefloorname = '';
      this.subzone_isbuttonshow = 0;

      this.collapsedIcon1_unit = "keyboard_arrow_up";
      this.unit_arrowiconT = false;
      this.selectedcampusName = "";
      this.selectedcampus = 0;
      this.selectedfloor = 0;
      this.selectedsubzone = 0;
      this.checkicon = 0;
      this.getCampusDetailsForNotification();
      this.getselectmapcount(0, 0, 0, 0);
    } else if (selType == 2) {
      this.collapsedIcon1 = "keyboard_arrow_up";
      this.arrowiconT = false;
      this.isSubZoneShow = 0;
      this.collapsedIcon1_build = "keyboard_arrow_down";
      this.build_arrowiconT = true;
      this.build_isbuttonshow = 0;

      this.collapsedIcon1_floor = "keyboard_arrow_up";
      this.floor_arrowiconT = false;
      this.floorbuildingname = "";
      this.floor_isbuttonshow = 0;
      this.selectedbuilding = 0;
      this.subzonefloorname = '';
      this.subzonecampusname = '';
      this.subzonebuildingname = '';
      this.subzone_isbuttonshow = 0;

      this.collapsedIcon1_unit = "keyboard_arrow_up";
      this.unit_arrowiconT = false;
      this.selectedfloor = 0;
      this.selectedsubzone = 0;
      this.checkicon = 1;
      this.getBuildingDetailsForNotification(
        this.selectedcampus,
        this.selectedcampusName,
        this.selectedCampusImage
      );
      this.getselectmapcount(this.selectedcampus, this.selectedbuilding, 0, 0);
    } else if (selType == 3) {
      this.collapsedIcon1 = "keyboard_arrow_up";
      this.arrowiconT = false;
      this.isSubZoneShow = 0;
      this.collapsedIcon1_build = "keyboard_arrow_up";
      this.build_arrowiconT = false;

      this.collapsedIcon1_floor = "keyboard_arrow_down";
      this.floor_arrowiconT = true;
      this.floor_isbuttonshow = 0;

      this.collapsedIcon1_unit = "keyboard_arrow_up";
      this.unit_arrowiconT = false;
      this.selectedfloor = 0;
      this.selectedsubzone = 0;
      this.getselectmapcount(
        this.selectedcampus,
        this.selectedbuilding,
        this.selectedfloor,
        0
      );
      this.checkicon = 2;
      this.getFloorDetailsForNotification(
        this.addbuildingid,
        this.floorbuildingname
      );
    } 
    else if(selType == 4)
    {
      this.collapsedIcon1 = "keyboard_arrow_up";
      this.arrowiconT = false;
      this.buildingcampusname = '';
      
      this.collapsedIcon1_build = "keyboard_arrow_up";
      this.build_arrowiconT = false;    
      this.floorbuildingname = '';
      
      this.collapsedIcon1_floor = "keyboard_arrow_up";
      this.floor_arrowiconT = false;
      
      this.collapsedIcon1_subzone = "keyboard_arrow_down";
      this.subzone_arrowiconT = true;  
      this.subzone_isbuttonshow = 0;
      
      this.collapsedIcon1_unit = "keyboard_arrow_up";
      this.unit_arrowiconT = false;
      this.checkicon = 3;    
      this.getSubZoneDetailsForNotification(this.selectedfloor,this.subzonefloorname)
    }
    else if (selType == 5) {
      this.collapsedIcon1 = "keyboard_arrow_up";
      this.arrowiconT = false;
      this.buildingcampusname = "";
      this.subzonecampusname = '';

      this.collapsedIcon1_build = "keyboard_arrow_up";
      this.build_arrowiconT = false;
      this.floorbuildingname = "";
      this.subzonebuildingname = '';

      this.collapsedIcon1_floor = "keyboard_arrow_up";
      this.floor_arrowiconT = false;
      this.subzonefloorname = '';
      
      this.collapsedIcon1_subzone = "keyboard_arrow_up";
      this.subzone_arrowiconT = false; 

      this.collapsedIcon1_unit = "keyboard_arrow_down";
      this.unit_arrowiconT = true;
      this.checkicon = 3;
      //this.getfullmapcountdetails();
    }
  }

  NotifyEnableDisable(checkflag) {
    const elementcontent = document.getElementById("Divmatcontent");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "mat-content enablecontent";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "disablecontent";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
