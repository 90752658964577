<app-report-header [ReportType]="ReportType"></app-report-header>
<mat-card class="report-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-report-leftbar ></app-report-leftbar>
    </div>
      
    <div fxLayout="column" style="width:100%;">
    <!--Infrastructure Report-->
    <div class="table-container">     
        <div class="main-table"> 
        <div class="landing-title-bar">
          <div class="blocks" fxLayout="column" fxLayoutAlign="left">
            <mat-card-header>
              <mat-card-title style="font-size: 20px;margin-top:15px;">
                <span class="clsTitle">Alert Report</span>
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>

        <perfect-scrollbar class="chartscrollbarAlertReport">

        <div class="clsAlertdownloadbutton" fxLayout.sm="column">
          <button color="primary" mat-raised-button (click)="alertReportdata()">Download</button> 
        </div>
     
        <div class="search" fxLayout.sm="column">
            <mat-form-field  appearance="outline" floatLabel="auto" class="clsalertmatfield">
                <input matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchReport($event.target.value)" autocomplete="off" placeholder="Search" #searchInput>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
                   
        <div *ngIf="alertdataSource  else noalertRecord"> 
        <table mat-table [dataSource]="alertdataSource" matSort  #Alertsort="matSort" matSortDisableClear=true>           
            <!-- Weight Column -->
            <ng-container matColumnDef="AlarmName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Alert Description </th>
              <ng-container *matCellDef="let element">
              <!-- <td mat-cell *ngIf="element.AlarmSubTypeName != '' && element.Alert!='Tag Not Reporting' && element.Alert!='DC Not Reporting'"> {{element.AlarmName}} ({{element.Alert}}) </td>
              <td mat-cell *ngIf="element.AlarmSubTypeName != '' && element.Alert=='Tag Not Reporting'"> {{element.AlarmName}} ({{element.Alert}}) </td>
              <td mat-cell *ngIf="element.AlarmSubTypeName != '' && element.Alert=='DC Not Reporting'"> {{element.AlarmName}} ({{element.Alert}}) </td>
              <td mat-cell *ngIf="element.AlarmSubTypeName == '' && element.Alert!='Tag Not Reporting'"> {{element.AlarmName}} ({{element.Alert}}) </td> -->
              
              <td mat-cell *ngIf="element.AlarmSubTypeName == '' || element.AlarmSubTypeName == null">{{element.AlarmName}}</td>
              <td mat-cell *ngIf="element.Alert == 'Unauthorized Egress'"> {{element.AlarmName}} ({{Wander}}) </td>
              <td mat-cell *ngIf="element.AlarmSubTypeName != '' && element.AlarmSubTypeName != null && element.Alert != 'Unauthorized Egress'"> {{element.AlarmName}} ({{element.Alert}}) </td>
              </ng-container>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="LastName">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> Resident Name </th> 
              <td mat-cell *matCellDef="let element"> 
                <span *ngIf="(element.DeviceType == EnumAlertType.Tag || element.AlertType == EnumDeviceAlertType.thirdparty || element.DeviceType == EnumAlertType.Pager_Communication || 
                element.DeviceType == EnumAlertType.Battery || (element.DeviceType == EnumAlertType.DC && element.AlertSubType == EnumAlertSubType.Tag_In_Field))
               && element.AlertType != EnumDeviceAlertType.UT && element.Name.length >= 20" matTooltipClass="devicetooltip" matTooltip={{element.Name}} #tooltip="matTooltip" matTooltipPosition="above">
               {{element.Name.slice(0,20) + ".."}} </span>
               <span *ngIf="(element.DeviceType == EnumAlertType.Tag || element.AlertType == EnumDeviceAlertType.thirdparty || element.DeviceType == EnumAlertType.Pager_Communication || 
               element.DeviceType == EnumAlertType.Battery || (element.DeviceType == EnumAlertType.DC && element.AlertSubType == EnumAlertSubType.Tag_In_Field))
               && element.AlertType != EnumDeviceAlertType.UT && element.Name.length < 20">
               {{element.FirstName}} {{element.LastName}} </span>              
              <span mat-cell *ngIf="element.DeviceType == EnumAlertType.REX && element.AlertType != EnumDeviceAlertType.UT && element.AlertType != EnumDeviceAlertType.thirdparty">{{EnumNewDeviceType.Rex}}</span>
              <span mat-cell *ngIf="element.DeviceType == EnumAlertType.HREX && element.AlertType != EnumDeviceAlertType.UT  && element.AlertType != EnumDeviceAlertType.thirdparty">{{EnumNewDeviceType.HRex}}</span>
              <span mat-cell *ngIf="element.DeviceType == EnumAlertType.DC && element.AlertSubType != EnumAlertSubType.Tag_In_Field && element.AlertType != EnumDeviceAlertType.UT  && element.AlertType != EnumDeviceAlertType.thirdparty">{{EnumNewDeviceType.DC}}</span>
              <span mat-cell *ngIf="element.DeviceType == EnumAlertType.Pull_Cord && element.AlertType != EnumDeviceAlertType.UT  && element.AlertType != EnumDeviceAlertType.thirdparty">{{EnumNewDeviceType.Pullcord}}</span>
              <span mat-cell *ngIf="element.DeviceType == EnumAlertType.Emitter && element.AlertType != EnumDeviceAlertType.UT  && element.AlertType != EnumDeviceAlertType.thirdparty">{{EnumNewDeviceType.Emitter}}</span>
              <span mat-cell *ngIf="element.DeviceType == EnumAlertType.ADT_LFExciter && element.AlertType != EnumDeviceAlertType.UT  && element.AlertType != EnumDeviceAlertType.thirdparty">{{EnumNewDeviceType.LFExiter}}</span>
              <span mat-cell *ngIf="element.DeviceType == EnumAlertType.DripTray && element.AlertType != EnumDeviceAlertType.UT  && element.AlertType != EnumDeviceAlertType.thirdparty">{{EnumNewDeviceType.DripTray}}</span>
              <span mat-cell *ngIf="element.AlertType == EnumDeviceAlertType.UT">{{EnumNewDeviceType.UT}}</span>
              <span mat-cell *ngIf="element.AlertType == EnumDeviceAlertType.Infrastructure && element.DeviceType == EnumAlertType.UT">{{EnumNewDeviceType.UT}}</span>  
              <span mat-cell *ngIf="element.AlertType == EnumDeviceAlertType.Infrastructure && element.DeviceType==0"> {{element.FirstName}}</span>  
            </td>
            </ng-container>
            
             <!-- Symbol Column -->
            <ng-container matColumnDef="TagId">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> Device Id </th>
              <td mat-cell *matCellDef="let element">{{element.TagId}} </td>
            </ng-container>
            
             <!-- Symbol Column -->
            <ng-container matColumnDef="RoomName" >
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> Room Info</th>
              <td mat-cell *matCellDef="let element">                 
              <span *ngIf="element.RoomInfo.length < 30">{{element.RoomInfo}}</span>
              <span *ngIf="element.RoomInfo.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.RoomInfo}} #tooltip="matTooltip" matTooltipPosition="above">
                {{element.RoomInfo.slice(0,20) + ".."}}</span>
              </td>                               
            </ng-container>
  
                <!-- Symbol Column -->
            <ng-container matColumnDef="AlertTime" >
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> Alert Time</th>
              <td mat-cell *matCellDef="let element">  {{element.AlertTime}} </td>
            </ng-container>
            
              <!-- Symbol Column -->
              <ng-container matColumnDef="NotifiedTime" >
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> Notified Time</th>
                <td mat-cell *matCellDef="let element"> {{element.NotifiedTime}} </td>
              </ng-container>
            
                <!-- Symbol Column -->
                <ng-container matColumnDef="AlertLevel" >
                  <th mat-header-cell *matHeaderCellDef  mat-sort-header>Notified Level</th>
                  <td mat-cell *matCellDef="let element"> {{element.AlertLevel}} </td>                   
                </ng-container>
            
              <!-- Symbol Column -->
              <ng-container matColumnDef="AckedTime" >
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> Accepted Time</th>
                <td mat-cell *matCellDef="let element"> 
                  <span *ngIf="element.AckStatus == EnumAlertStatus.Canceled || element.AckStatus == EnumAlertStatus.Complete">{{element.AckedTime}}</span>
                   </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="AckedMethod" >
                <th mat-header-cell *matHeaderCellDef  mat-sort-header>Acked Method</th>
                <td mat-cell *matCellDef="let element"> {{element.AckedMethod}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="AckStatus"  class="clsstatus">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">{{element.AckStatus}}</td>
              </ng-container>

               <!-- Symbol Column -->
               <ng-container matColumnDef="PresenceOn"  class="clsstatus">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header>PresenceOn</th>
                <td mat-cell *matCellDef="let element">{{element.PresenceOn}}</td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="AckedBy">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header>Acked By</th>                
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.AckedFirstName=='' && element.AckedLastName=='' && element.AckedMethod != 'Self'">-</span>
                  <span *ngIf="element.AckedMethod == 'Self'">Self</span>
                  <span *ngIf="(element.AckedFirstName!='' || element.AckedLastName!='') && element.AckedMethod != 'Self' && element.AckedName.length >= 30" 
                    matTooltipClass="devicetooltip" matTooltip={{element.AckedName}} #tooltip="matTooltip" matTooltipPosition="above">
                    {{element.AckedName.slice(0,10) + ".."}}
                  </span>
                  <span *ngIf="(element.AckedFirstName!='' || element.AckedLastName!='') && element.AckedMethod != 'Self' && element.AckedName.length < 30">
                    {{element.AckedFirstName}} {{element.AckedLastName}}
                  </span>
                </td>
              </ng-container>
                          
               <!-- Symbol Column -->
              <ng-container matColumnDef="ResponseTimeSort">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header>Response Time</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.AckStatus == EnumAlertStatus.Canceled || element.AckStatus == EnumAlertStatus.Complete">{{element.ResponseTime}}</span>
                </td>
              </ng-container>

              
          <tr mat-header-row *matHeaderRowDef="displayedAlertReportColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedAlertReportColumns;"></tr>
        </table>

        <h3 *ngIf="dataSourcecount == 0 && !alertloading" style="text-align: center;margin-top:1em;"> No Record found </h3>   
      </div>

      <ng-template #noalertRecord>   
                     
              <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%">       
                  <div class="loadspinner" *ngIf="alertloading">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                  </div>
              </div>            
        </ng-template>          
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator> 
    </perfect-scrollbar>
        </div>
    </div>    
    </div>
    
  </div>
</mat-card>