import { Component, OnInit, ViewChild, Inject, OnDestroy } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { AddWanderconfigComponent } from "./add-wanderconfig/add-wanderconfig.component";
import { EditWanderconfigComponent } from "./edit-wanderconfig/edit-wanderconfig.component";
import { WanderConfig, WanderConfigById, getSafeZone } from "./wanderconfig";
import { WanderService } from "./wanderconfig.service";
import { MainLayoutService } from "../../main-layout/main-layout.service";
import { RollbarService } from "../../rollbar";
import * as Rollbar from "rollbar";
import { AlertSharedService } from "../../_services/index";
import { ShareRightSideNav } from "../../api";
import { SidenavComponent } from "../../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import {
  SessionCheck,
  checkStaffData,
  generateAlertMessage,
  getFeatureNeedtoShow,
  getAddEditDeleteColumn,
  AuthFailedMessage,
} from "../../app-general";
import { Router } from "@angular/router";
import { ApiService } from "../../api.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumEnableDisable,
} from "../../app-settings";
import { ConfirmMessageComponent } from "../../confirm-message/confirm-message.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-wanderconfig",
  templateUrl: "./wanderconfig.component.html",
  providers: [WanderService],
  styleUrls: ["./wanderconfig.component.scss"],
})
export class WanderconfigComponent implements OnInit, OnDestroy {
  Wanderconfigdetails: WanderConfig[];
  dataSource;
  dataSourcecount;
  displayedColumns: string[] = [
    "ResidentGroupName",
    "ResidentType",
    "RoomName",
    "edit",
    "delete",
  ];
  ShareSideNavDetails: ShareRightSideNav;
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  bAddEdit = false;
  bDelete = false;
  chkwandersearchvalue;
  @ViewChild("rightNav") public rightNav: MatSidenav;
  wanderloading = true;
  wanderShow = true;
  private _onDestroy: Subject<void> = new Subject<void>();
  authkey;
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private wanderService: WanderService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public mainLayoutService: MainLayoutService,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {    
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if (getFeatureNeedtoShow(enumUIFeatureView.Roaming,enumUIAction.Roaming_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.XLarge) 
    || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupFullTable();
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small) || this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }     
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Roaming,
        enumUIAction.Roaming_View
      ) != enumUIView.Show
    ) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Roaming,
        enumUIAction.Roaming_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }

    this.getStaffById();
    this.getSafeZone();
  }
  getStaffById() {
    checkStaffData(this.apiService, this.alertSharedService);
  }

  setupTableforXtrasmallerwidth() {
    this.displayedColumns = ["ResidentGroupName", "RoomName", "edit", "delete"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupFullTable() {
    this.displayedColumns = [
      "ResidentGroupName",
      "ResidentType",
      "RoomName",
      "edit",
      "delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  getSafeZone(): void {
    try {
      this.wanderloading = true;

      const wanderitem: getSafeZone = {
        authKey: this.authkey,
      };
      this.wanderService
        .getSafeZone(wanderitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((WanderConfig: any[]) => {
          if (WanderConfig["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.wanderloading = false;

          this.Wanderconfigdetails = WanderConfig["apiReturn"];
          this.dataSource = new MatTableDataSource(this.Wanderconfigdetails);
          this.dataSource.data = this.Wanderconfigdetails;

          if (this.chkwandersearchvalue == undefined) {
            this.dataSource.data = this.Wanderconfigdetails;
          } else {
            this.searchWanderPrevention(this.chkwandersearchvalue);
          }
          setTimeout(() => {
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
              data[sortHeaderId].toString().toLocaleLowerCase().trim("");
          }, 0);
          this.dataSource.paginator = this.paginator;
          if (this.chkwandersearchvalue == undefined)
            this.dataSourcecount = this.dataSource.data.length;
          else this.dataSourcecount = this.dataSource.filteredData.length;
        });
    } catch (err) {
      throw new Error(
        "wanderconfig.component.ts" + " - getSafeZone() - " + err.message
      );
    }
  }

  searchWanderPrevention(filterValue) {
    if (filterValue != null) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      this.chkwandersearchvalue = filterValue;

      this.searchfilter();
      this.dataSourcecount = this.dataSource.filteredData.length;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
      }, 0);
    }
  }

  searchfilter() {
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      const filtersearch = filter.toLowerCase();
      return (
        data.ResidentGroupName.toLowerCase().includes(filtersearch) ||
        data.ResidentType.toLowerCase().includes(filtersearch) ||
        data.RoomName.toLowerCase().includes(filtersearch)
      );
    };
  }

  /*********Opendialogwindow*******/
  addWanderpreventiondata() {
    if (!SessionCheck(this.router)) {
      return;
    }
    this.wanderconfigEnableDisable(enumEnableDisable.Disable);
    const dialogRef = this.dialog.open(AddWanderconfigComponent, {
      disableClose: true,
      width: "780px",
      height: "780px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: this.dataSource.data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.wanderconfigEnableDisable(enumEnableDisable.Enable);
      if (result == "Confirm") {
        const element = document.getElementById("btnaddwander");
        if (element != null)
          element.className =
            "clswanderaddbutton mat-raised-button mat-primary ng-star-inserted";
        this.getSafeZone();
      } else {
        const element = document.getElementById("btnaddwander");
        if (element != null)
          element.className =
            "clswanderaddbutton mat-raised-button mat-primary ng-star-inserted";
      }
    });
  }

  editWanderdata(zoneId) {
    this.wanderconfigEnableDisable(enumEnableDisable.Disable);
    if (!SessionCheck(this.router)) {
      return;
    }
    const zonebyid: WanderConfigById = {
      authKey: this.authkey,
      safeZoneId: zoneId,
    };
    this.wanderService
      .checkSafeZone(zonebyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((ZonesbyId: any[]) => {
        if (ZonesbyId["condition"] == "Authfalse") {
          AuthFailedMessage(this.dialog, this.router);
          return;
        }
        this.Wanderconfigdetails = ZonesbyId;
        this.editopendialog(this.Wanderconfigdetails);
      });
  }

  editopendialog(zonedata) {
    const dialogRef = this.dialog.open(EditWanderconfigComponent, {
      disableClose: true,
      width: "780px",
      height: "780px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: zonedata,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.wanderconfigEnableDisable(enumEnableDisable.Enable);
      if (result == "Confirm") {
        this.getSafeZone();
      }
    });
  }

  deleteWanderdata(zoneId) {
    this.wanderconfigEnableDisable(enumEnableDisable.Disable);
    if (!SessionCheck(this.router)) {
      return;
    }
    this.deleteSafeZone(zoneId);
  }
  deleteSafeZone(zoneId) {
    try {
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to delete Roaming Rule?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "200px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          const deletezonesbyid: WanderConfigById = {
            authKey: this.authkey,
            safeZoneId: zoneId,
          };
          this.wanderService
            .deleteSafeZone(deletezonesbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: any[]) => {
              if (apiresponse["apiReturn"] == "Success") {
                this.apiService.reloadlive();
                generateAlertMessage(
                  this.dialog,
                  "Roaming Rule deleted successfully."
                );
                this.getSafeZone();
              }
              else if (apiresponse["apiReturn"] == "Fail") {
                generateAlertMessage(this.dialog, "Roaming Rule deleted failed.");                
              }
              else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
                AuthFailedMessage(this.dialog, this.router);
                return;
              } else {
                generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
              }
              this.wanderconfigEnableDisable(enumEnableDisable.Enable);
            });
        } else {
          dialogRef.close("Cancel");
          this.wanderconfigEnableDisable(enumEnableDisable.Enable);
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "delete-resident-group.ts" + " - deleteSafeZone() - " + err.message
      );
    }
  }
  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  showRoamingRules(checkflag) {
    if (checkflag == 1) this.wanderShow = false;
    else if (checkflag == 2) this.wanderShow = true;
  }

  wanderconfigEnableDisable(checkflag) {
    const elementcontent = document.getElementById("divwanderdetails");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsenable";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsdisable";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
