import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { AddOutdoorspaceComponent } from "../outdoorlocating/add-outdoorspace/add-outdoorspace.component";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from "../../../api.service";
import { MapSyncProcess, getCampus, getUnit, getBuilding, getZone } from "../../../api";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import {
  map_widthandheight,
  generateAlertMessage,
  SessionCheck,
  AuthFailedMessage,
  LoadTechnologyCampusSvg,
  convertToSvgEcllipseXCoordinate,
  convertToSvgEcllipseYCoordinate,
  getNewFeature_Campus,
  get_selectedfeature_Campus,
  Checkpolygon_Intersect_Campus,
  getCheckInExtent,
  maponclicked_Tech_Campus,
  calculateCenter,
  IsCheckRoomDrawn,
  IsDevice_In_RoomDrawn,
  ClearStorageeData,
  getSelected,
  getFeatureNeedtoShow,
  getAddEditDeleteColumn,
} from "../../../app-general";
import {
  appsettings,
  DeviceTypeDesc,
  enumUIAction,
  enumUIFeatureView,
  enumUIView,
  fillcolorPolygon,
  NewDeviceTypeDesc,
  enumRequestFrom,
  enumTech_Outdoor_rightmenu,
  DeviceType
} from "../../../app-settings";
import { TechnologyoverlayService } from "../technologyoverlay.service";
import {
  map_creation,
  fillcolor_style,
  get_geojsonObject_floor,
  fillcolor_styleSelect,
  getDeviceTypeNew,
  label_Polygon,
} from "./../../../map-general";
import { DOCUMENT } from "@angular/common";
import OlMap from "ol/Map";
import {
  Circle as CircleStyle,
  Icon,
  Style,
  Fill,
  Stroke,
  Text,
} from "ol/style";
import { AlertSharedService } from "./../../../_services/index";
import { Router } from "@angular/router";
import { AddDeviceComponent } from "../device/add-device/add-device.component";
import { EditDeviceComponent } from "../device/edit-device/edit-device.component";
import {
  addHRexflag,
  TechnologyForCreationResponse,
  deleteUnitById,
  getDeviceDetailsReq,
  getSiteAuthKeyReuest,
  CampusByID,
  getPolylineRequest,
} from "../technologyoverlay";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { EditOutdoorspaceComponent } from "./edit-outdoorspace/edit-outdoorspace.component";
import { GeneralMessageComponent } from "../../../general-message/general-message.component";
import Draw, { createBox, createRegularPolygon } from "ol/interaction/Draw";

import {
  Modify,
  Select,
  Translate
} from "ol/interaction";
import { MultiPoint, Point } from "ol/geom";
import "ol-popup/src/ol-popup.css";
import OlVectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";
import {
  never,
  platformModifierKeyOnly,
  primaryAction,
  shiftKeyOnly,
  singleClick,
} from "ol/events/condition";
import { style } from "@angular/animations";
@Component({
  selector: "app-outdoorlocating",
  templateUrl: "./outdoorlocating.component.html",
  styleUrls: ["./outdoorlocating.component.scss"],
})
export class OutdoorlocatingComponent implements OnInit {
  bAddEdit = false;
  bDelete = false;
  Campusdetails: any[];
  Buildingdetails: any[];
  Zonedetails: any[];
  MapSyncProcess;
  outdoorSpaceinfo;
  OutdoorSpaceCnt;
  map = new OlMap();
  ImageWidth = "";
  ImageHeight = "";
  addoutdoorimageheight = 0;
  ImageUrl = "";
  BackgroundIMG = "Centrak";
  Check_ZoomLevel;
  Check_MapCenter;
  CZoomLevel;
  CRotateLevel;
  TranslateX;
  TranslateY;
  MCampusId;
  MBuildingId;
  MFloorId;
  CampusFulldetails: any[];
  selectedCampus;
  selectedBuilding;
  selectedFloor;

  selectout_CampusId = 0;
  selectout_Campusname = "";
  selectout_BuildId = 0;
  selectout_Buildname = "";
  selectout_FloorId = 0;
  selectout_Floorname = "";
  SvgImageUrl = "";
  campusName;
  SvgInfo;
  FloorInfo;
  outdoorInfo;
  listviewflag = 0;
  mapviewflag = 0;
  dataSource;
  dataSourcecount;
  buttontext;
  checkIsSpace = false;
  imageExtent;
  checkdrawroomflag = 0;
  drawroomselect;
  checkroomdrawend = false;
  rotateIsInside = 1;

  outdoorloading = true;
  Selected_DeviceTypename;
  Selected_ImageDeviceTypename;
  CheckIsAdd = false;
  checkIsEdit = false;
  checkIsEditOpen = false;
  addbuildingid = 0;
  addfloorid = 0;
  filterdevicedetails;
  Device_Info;
  device_list_all = "3";
  editimageheight;
  editimagewidth;
  Map_image_path = "assets/images/";
  displayedColumns: string[] = ["UnitName", "CampusName", "BuildName", "FloorName", "Accuracy", "Status", "edit"];

  Unitinfo = [] as any[];
  Unitnameforfloor;
  addHRexflag: addHRexflag[];
  getdeviceforadd;
  MaxRexvalue;
  deviceTypeName;

  strLocationName: string;
  strRoomName: string;
  strDeviceId: number;
  strEmitterId: number;

  strMacId: string;
  strLFId: number;
  strLocation: string;
  strDCMacId: string;

  selectedUnit = 0;

  public deviceaddForm: UntypedFormGroup;

  strDeviceDualId: number;

  strRexDeviceId: number;
  checkDCTag = false;
  checkDCLotiering = false;
  checkDCUnauthorizedEgress = false;

  disableDCLotiering = true;
  disableDCUnauthorizedEgress = true;

  checkPCRemovalDetection = false;
  checkPCButtonPress = false;
  checkPullCord = false;
  checkPCMobilityMode = false;

  checkDCT_Tag1 = false;
  checkDCT_Tag2 = false;
  checkDCT_Tag3 = false;

  checkDCT_TagEnable1 = 0;
  checkDCT_TagEnable2 = 0;
  checkDCT_TagEnable3 = 0;

  checkDCT_Ambient1 = false;
  checkDCT_Ambient2 = false;

  selectedAlertState1 = 0;
  selectedAlertState2 = 0;
  selectedAlertState3 = 0;

  disableAlertmessage1 = 0;

  overTempflag = 0;

  probeoverTempflag = 0;
  humidityoverTempflag = 0;

  checkDCT_Probe1 = false;
  checkDCT_Probe2 = false;

  checkDCT_Humidity1 = false;
  checkDCT_Humidity2 = false;

  HRexId;
  GatewayId;
  HRexMacId;
  GatewayMacId;
  getHRexMacId;
  GatewayDetails;
  getgatewaymacid;
  getRexvalue;
  getRexMaxvalue;
  selectdefaultMode = "0";
  selectdefaultDCTMode = "0";
  addnewDeviceflag = 0;
  changeCampusflag = 0;

  selectedCampusName = "";
  _translateend = 0;

  getfloordata;

  outdoorEdit_pointsbool = true;

  public showCampus = true;
  public showFloor = false;
  public showEmitter = false;
  public showLF = false;
  public showMac = false;
  public showHrex = false;
  public showpullcord = false;
  public showDC = false;
  public showMonitor = false;
  public showVW = false;
  public showGateway = false;
  public isSaveFloorDevice = false;
  public showDCT = false;
  public showUM = false;

  select = null;
  modify = null;
  translate = null;

  //Draw feature for Room
  vector = new OlVectorLayer();
  source = new VectorSource({ wrapX: false });
  vectorLayer = new OlVectorLayer({
    source: this.source,
  });

  tempVectorSource = new VectorSource();
  tempVectorLayer = new OlVectorLayer({
    source: this.tempVectorSource,
  });

  draw = new Draw({
    source: this.source,
    type: "Polygon",
  });

  private _onDestroy: Subject<void> = new Subject<void>();

  CampusImageURL: string = appsettings.CAMPUS_IMAGE_FOLDER;
  FloorImageURL : string = appsettings.FLOOR_IMAGE_FOLDER;
  editoutdoordialogopen = 0;
  addoutdoordialogopen = 0;
  IsDuplicateAdded = 0;

  Tech_Move_Outdoor = enumTech_Outdoor_rightmenu.Move;
  Tech_Create_Space_Outdoor = enumTech_Outdoor_rightmenu.Create_Space;
  Tech_Edit_Space_Outdoor = enumTech_Outdoor_rightmenu.Edit_Space;
  Tech_Add_Device_Outdoor = enumTech_Outdoor_rightmenu.Add_Device;
  Tech_Delete_Space_Outdoor = enumTech_Outdoor_rightmenu.Delete_Space;

  Tech_Rectangle_Space_Outdoor = enumTech_Outdoor_rightmenu.Rectangle_Space;
  Ellipse_Space_Outdoor = enumTech_Outdoor_rightmenu.Ellipse_Space;
  FreeHand_Space_Outdoor = enumTech_Outdoor_rightmenu.FreeHand_Space;
  Edit_Point_Outdoor = enumTech_Outdoor_rightmenu.Edit_Point;
  Rotate_Space_Outdoor = enumTech_Outdoor_rightmenu.Rotate_Space;
  Reflect_Space_Outdoor = enumTech_Outdoor_rightmenu.Reflect_Space;
  authkey;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private technologyoverlayService: TechnologyoverlayService,
    @Inject(DOCUMENT) private document: any,
    private alertSharedService: AlertSharedService,
    private router: Router,
    public thisDialogRef: MatDialogRef<OutdoorlocatingComponent>
  ) {
    const getRouterNavigate = this.router.getCurrentNavigation();
    if (getRouterNavigate != null)
      this.getfloordata = getRouterNavigate.extras.state;
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Outdoor_Locating_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = false;
      this.displayedColumns = ["UnitName", "CampusName", "BuildingName", "FloorName", "Accuracy", "Status", "edit"];
    } else {
      this.displayedColumns = ["UnitName", "CampusName", "BuildingName", "FloorName", "Accuracy", "Status",];
      this.DisableBtn();
    }

    if (this.bAddEdit == true) {
      const saveBtn = document.getElementById("btnsaveclickmapview");
      if (saveBtn != null) {
        saveBtn.className = "clsdeviceaddbutton mat-raised-button";
        saveBtn.innerHTML = "Save";
        this.buttontext = "Save";
      }
    }
    const applianceSN = localStorage.getItem("applianceSN") ?? "";
    localStorage.setItem("CreateNew", "0");
    this.getCampusDetails();
    // this.getHRexdetails();
    this.getgatewaymacid = applianceSN;
    const getmatpaginator = document.getElementsByClassName("clsmatpaginator");
    getmatpaginator[0]["style"].display = "none";

    if (this.getgatewaymacid.indexOf("_") == -1) {
      this.getgatewaymacid = this.getgatewaymacid
        .replace(/(.{2})/g, "$1_")
        .slice(0, -1);
    }
  }

  // getHRexdetails() {
  //   try {
  //     const hrexdetails: getSiteAuthKeyReuest = {
  //       authKey: this.authkey,
  //     };
  //     this.technologyoverlayService
  //       .getHRexdetails(hrexdetails)
  //       .pipe(takeUntil(this._onDestroy))
  //       .subscribe((HRexDetails: any[]) => {
  //         if (HRexDetails != null && HRexDetails != undefined) {
  //           // this.getHRexMacId =HRexDetails[0].MacId;
  //           this.getHRexMacId = HRexDetails;
  //         }
  //       });
  //   } catch (err) {
  //     throw new Error(
  //       "mapview-floor.component.ts" + " - getHRexdetails() - " + err.message
  //     );
  //   }
  // }

  setupTableforXtrasmallerwidth() {
    this.displayedColumns = ["UnitName", "CampusName", "edit"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }
  setupTableforsmallerwidth() {
    this.displayedColumns = ["UnitName", "CampusName", "Accuracy", "edit"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }
  setupFullTable() {
    this.displayedColumns = [
      "UnitName",
      "CampusName",
      "BuildingName", 
      "FloorName",
      "Accuracy",
      "AssetType",
      "Status",
      "edit",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }
  
  clkHomeIcon()
  {
    this.selectout_CampusId = this.MCampusId;
    this.selectout_BuildId = 0;
    this.selectout_FloorId = 0;
    this.getCampusDetails();
    if(this.listviewflag == 1)
    {
      this.getoutdoorSpace();
    }
  }

  getCampusDetails(): void {
    try {
      const campusdata: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(campusdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Campusinfo: any[]) => {
          this.Campusdetails = Campusinfo["apiReturn"];
          console.log("this.MCampusId; : " + this.MCampusId)
          if (this.mapviewflag == 0 && this.MCampusId == undefined) {
            this.selectout_CampusId = this.Campusdetails[0].CampusId;
            this.selectout_Campusname = this.Campusdetails[0].CampusName;
          }
          /*else
          {
            this.selectout_CampusId = this.selectout_CampusId;
            // this.selectout_Campusname = this.Campusdetails[0].CampusName;
          }*/
          this.selectCampusFull();
          this.getBuildingDetails(this.selectout_CampusId);
        });
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" +
          " - getCampusDetails() - " +
          err.message
      );
    }
  }
  
  getBuildingDetails(CampusId): void {
    try {
      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
      };
      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Buildinfo: any[]) => {
          this.Buildingdetails = Buildinfo["apiReturn"]; 
          this.getZoneDetails(this.selectout_BuildId)                 
        });
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" +
          " - getCampusDetails() - " +
          err.message
      );
    }
  }
  
  changebuilding(buildInfo)
  {
    const selectOutdoordata = [] as any[];
    this.selectout_BuildId = buildInfo.BuildId;  
    this.selectout_Buildname= buildInfo.BuildingName;  
    this.outdoorSpaceinfo.forEach(function (data) {
      if(data.BuildId == buildInfo.BuildId)
      {
        selectOutdoordata.push(data);
      }            
    })    
    this.loadOutdoorData(selectOutdoordata);
  }
  
  getZoneDetails(BuildingId): void {
    try {
      const floorid: getZone = {
        authKey: this.authkey,
        campusId:this.selectout_CampusId,
        buildId: BuildingId,
        floorId : 0
      };
      console.log("floorid getZoneDetails : " + floorid)
      console.log(floorid)
      this.apiService
        .getZoneDetails(floorid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Zoneinfo: any[]) => {
          this.Zonedetails = Zoneinfo["apiReturn"];                  
        });
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" +
          " - getCampusDetails() - " +
          err.message
      );
    }
  }
  
  changefloor(floorInfo)
  {
    const selectOutdoordata = [] as any[];
    this.selectout_FloorId = floorInfo.ZoneId;    
    this.selectout_Floorname= floorInfo.ZoneName;      
    this.outdoorSpaceinfo.forEach(function (data) {
      if(data.FloorId == floorInfo.ZoneId)
      {
        selectOutdoordata.push(data);
      }            
    })    
    this.loadOutdoorData(selectOutdoordata);
  }

  selectCampusFull() {
    try {
      const campusid: CampusByID = {
        authKey: this.authkey,
        campusId: this.selectout_CampusId,
      };
      let item;
      this.MCampusId = this.selectout_CampusId;
      this.apiService
        .getCampusbyCampusId(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusFulldetails: any[]) => {
          // need to latest campus from sysc need to show the global alert start
          let CheckAlertData = {
            IsAlertChanged: 0,
            IsAlertClicked: 0,
          };
          this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
          CheckAlertData = {
            IsAlertChanged: 1,
            IsAlertClicked: 0,
          };
          this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
          // need to latest campus from sysc need to show the global alert end
          this.CampusFulldetails = CampusFulldetails;

          this.selectedCampus = this.selectout_CampusId;
          this.selectedBuilding = this.selectout_BuildId;
          this.selectedFloor = this.selectout_FloorId;

          const _SharedHomeMapDetails = {
            sel_CampusId: this.selectedCampus,
            sel_BuildingId: this.selectedBuilding,
            sel_FloorId: this.selectedFloor,
          };

          this.alertSharedService.changeSharedHomeMapdetails(
            _SharedHomeMapDetails
          );

          const BuildingById = this.selectout_BuildId;
          const FloorId = this.selectout_FloorId;
          const mapdetail = this.MCampusId + "_" + BuildingById + "_" + FloorId;

          this.CampusFulldetails["SelectedMapInfo"] = mapdetail;
          item = { MapDetails: this.CampusFulldetails };

          this.getMapDetails(item);
        });
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts - selectCampusFull() - " + err.message
      );
    }
  }

  getMapDetails(MapDetails): void {
    let arrMapDetails = "";
    let BulidingId;
    let FloorId;
    let CampusId;
    
    if (MapDetails.MapDetails.SelectedMapInfo != "0_0_0") {
      arrMapDetails = MapDetails.MapDetails.SelectedMapInfo.split("_");
      this.CampusFulldetails = MapDetails.MapDetails;
      this.MCampusId = parseInt(arrMapDetails[0]);
      this.MBuildingId = parseInt(arrMapDetails[1]);
      this.MFloorId = parseInt(arrMapDetails[2]);
      this.CampusFulldetails = MapDetails.MapDetails;

      if (arrMapDetails[2] == "0") {
        const CampusId = arrMapDetails[0];

        for (
          let info = 0;
          info <= this.CampusFulldetails["Campusinfo"].length - 1;
          info++
        ) {
          if (
            this.CampusFulldetails["Campusinfo"][info]["CampusId"] == CampusId
          ) {
            this.CampusImageURL =
              this.CampusFulldetails["Campusinfo"][info]["FloorPlanImage"];
            this.ImageWidth =
              this.CampusFulldetails["Campusinfo"][info]["ImageWidth"];
            this.ImageHeight =
              this.CampusFulldetails["Campusinfo"][info]["ImageHeight"];
            this.SvgImageUrl =
              appsettings.CAMPUS_IMAGE_FOLDER +
              "/" +
              this.CampusFulldetails["Campusinfo"][info]["svgFile"];
            this.ImageUrl =
              appsettings.CAMPUS_IMAGE_FOLDER + "/" + this.CampusImageURL;
            this.SvgInfo =
              this.CampusFulldetails["Campusinfo"][info]["Campusdetail"];
            this.campusName =
              this.CampusFulldetails["Campusinfo"][info]["CampusName"];
            this.CZoomLevel =
              this.CampusFulldetails["Campusinfo"][info]["ZoomLevel"];
            this.CRotateLevel =
              this.CampusFulldetails["Campusinfo"][info]["RotateLevel"];
            this.TranslateX =
              this.CampusFulldetails["Campusinfo"][info]["TransformX"];
            this.TranslateY =
              this.CampusFulldetails["Campusinfo"][info]["TransformY"];
            this.outdoorInfo = null;
            this.getDeviceDetails();
            break;
          }
        }

        this.BackgroundIMG = "Centrak";
      }
      else {
        CampusId = arrMapDetails[0];
        BulidingId = arrMapDetails[1];
        FloorId = parseInt(arrMapDetails[2]);

        if (this.CampusFulldetails["Campusinfo"].length == 0) {
          // this.floorloading = false;
        }

        for (
          let info = 0;
          info <= this.CampusFulldetails["Campusinfo"].length - 1;
          info++
        ) {
          if (
            this.CampusFulldetails["Campusinfo"][info]["CampusId"] == CampusId
          ) {
            for (
              let build = 0;
              build <=
              this.CampusFulldetails["Campusinfo"][info]["Campusdetail"]
                .length -
                1;
              build++
            ) {
              if (
                this.CampusFulldetails["Campusinfo"][info].Campusdetail[build]
                  .buildingid == BulidingId
              ) {
                for (
                  let floor = 0;
                  floor <=
                  this.CampusFulldetails["Campusinfo"][info].Campusdetail[build]
                    .floors.length -
                    1;
                  floor++
                ) {
                  if (
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].floorid == FloorId
                  ) {
                    this.FloorImageURL =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FloorPlanImage;
                    this.ImageWidth =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].ImageWidth;
                    this.ImageHeight =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].ImageHeight;
                    this.CZoomLevel =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FZoomLevel;
                    this.CRotateLevel =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FRotateLevel;
                    this.TranslateX =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FTranslateX;
                    this.TranslateY =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FTranslateY;
                    this.ImageUrl =
                      appsettings.FLOOR_IMAGE_FOLDER + "/" + this.FloorImageURL;
                    this.SvgInfo = null;
                    this.FloorInfo = null;
                    this.getDeviceDetails();
                    break;
                  }
                }
                break;
              }
            }
            // this.floorloading = false;
            break;
          }
        }
      }
    }
  }

  DrawMapCall() {
    try {
      //Map Empty
      const element = this.document.getElementById("map");
      let IsZoomChanged = 0;
      if (
        element.innerHTML != "" &&
        (this.Check_ZoomLevel || this.Check_ZoomLevel == 0)
      ) {
        this.CZoomLevel = this.Check_ZoomLevel;
        IsZoomChanged = 1;
      } else {
        this.Check_MapCenter = [];
      }
      element.innerHTML = "";
      const leftmenu = document.getElementById("leftmenu4");
      if (leftmenu != null) leftmenu.classList.add("active");

      ///Map width and height
      const imgwh = map_widthandheight(this.ImageWidth, this.ImageHeight);
      const imgwidth = imgwh.ImageWidth;
      const imgheight = imgwh.ImageHeight;
      this.addoutdoorimageheight = imgwh.ImageHeight;
      const labelStyle = label_Polygon(1);
      const style = [labelStyle];
      const devicelabelStyle = new Style({
        text: new Text({
          font: "10px Poppins, sans-serif",
          overflow: true,
          fill: new Fill({
            color: "#000",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 5,
          }),
          offsetX: 0,
          offsetY: 25.5,
        }),
      });

      const deviceStyle = fillcolor_style(fillcolorPolygon.Completed);

      const fillStyle = [deviceStyle, devicelabelStyle];
      this.map = map_creation(
        this.ImageUrl,
        imgwidth,
        imgheight,
        true,
        this.BackgroundIMG,
        this.CZoomLevel,
        this.CRotateLevel,
        element.offsetWidth,
        element.offsetHeight,
        this.TranslateX,
        this.TranslateY,
        "map",
        this.Check_MapCenter,
        IsZoomChanged
      );

      let translateX = this.TranslateX;
      let translateY = this.TranslateY;

      translateX = translateX / 122;
      translateY = translateY / 127;

      translateX = translateX * imgwidth;
      translateY = translateY * imgheight;

      const _map = this.map;
      const MapCenter = _map.getView().getCenter();

      MapCenter[0] -= translateX;
      MapCenter[1] += translateY;

      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Outdoor_Locating_AddEditDelete
        ) == enumUIView.Show
      ) {
        maponclicked_Tech_Campus(
          this.map,
          this.select,
          this.selectout_CampusId,
          this.technologyoverlayService,
          this._onDestroy,
          this.edit_DeviceDetails,
          this.dialog,
          this,
          this.editOutdoordetails
        );
      }

      const _this = eval('this');
      let currZoom = _this.CZoomLevel;
      _map.on("moveend", function (e) {
        if (IsZoomChanged == 0)
          getCheckInExtent(e, _map, imgwidth, imgheight, MapCenter);
        const newZoom = _map.getView().getZoom();
        if (currZoom != newZoom) {
          currZoom = newZoom;
          _this.CZoomLevel = newZoom;
          _this.Check_ZoomLevel = newZoom;
          _this.Check_MapCenter = _map.getView().getCenter();
        }
      });

      _map.on("pointermove", function () {
        _this.Check_MapCenter = _map.getView().getCenter();
      });
      //////////Campus Space start////////////
      const arrdevicemarker = [];
      const arrdevicemarkernoPoly = [];
      console.log("outdoorInfo 830 : " )
      console.log(this.outdoorInfo)
      if (this.outdoorInfo != null) {
        /// Geo Feature Collection ///
        const geoOutdoorjsonObject = get_geojsonObject_floor(
          enumRequestFrom.floorplan_page
        );
        geoOutdoorjsonObject.type = "FeatureCollection";
        geoOutdoorjsonObject.features.length = 0;

        /// marker array ///
        
        if (this.outdoorInfo.length > 0) {
          const arrOutdoorInfo = this.outdoorInfo;
          LoadTechnologyCampusSvg(
            this.map,
            arrOutdoorInfo,
            imgheight,
            imgwidth,
            arrdevicemarker,
            geoOutdoorjsonObject,
            arrdevicemarkernoPoly,
            devicelabelStyle,
            fillStyle,
            this.Map_image_path,
            this.MCampusId,labelStyle,style
          );
          this.editimageheight = imgheight;
          this.editimagewidth = imgwidth;
        } else if (this.CampusImageURL == null) {
          const _map = document.getElementById("map");
          if (_map != null)
            _map.innerHTML =
              "<span style='margin-top: 15%; padding-left: 45%;display:block;font-size:15px;font-weight:bold;'> Map Not Found.</span>";
        }

        if (this.IsDuplicateAdded == -1) {
          _this.select_outdoormove(this.IsDuplicateAdded);
        }
        else if (this.IsDuplicateAdded != 0) {
          const _this = eval('this');

          const _UnitId = this.IsDuplicateAdded.toString();
          const _addCampusId = this.selectout_CampusId;
          const arr_layer = [] as any[];
          this.map.getLayers().forEach(function (Floorlayer) {
            if (
              Floorlayer.get("OutdoorId") != undefined &&
              Floorlayer.get("OutdoorId") == "Outdoor_" + _addCampusId
            ) {
              arr_layer[arr_layer.length] = Floorlayer;
            }
          });
          const arrduplicate_feature = [] as any[];
          for (let indx = 0; indx < arr_layer.length; indx++) {
            const features_device = arr_layer[indx].getSource().getFeatures();
            if (features_device) {
              // Go through this array and get coordinates of their geometry.
              features_device.forEach(function (feature) {
                if (
                  parseInt(feature.getProperties().UnitId) ==
                    parseInt(_UnitId) &&
                  feature.getProperties().geometry.flatCoordinates.length !=
                    2 &&
                  parseInt(feature.getProperties().DeviceType) != 4
                ) {
                  arrduplicate_feature[arrduplicate_feature.length] = feature;
                } else if (
                  parseInt(feature.getProperties().UnitId) ==
                    parseInt(_UnitId) &&
                  feature.getProperties().geometry.flatCoordinates.length ==
                    2 &&
                  parseInt(feature.getProperties().DeviceType) != 4
                ) {
                  arrduplicate_feature[arrduplicate_feature.length] = feature;
                }
              });
            }
          }

          _this.select_outdoormove(arrduplicate_feature);
        }
      }
      //////////Campus Space End////////////
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts - DrawMapCall() - " + err.message
      );
    }
  }

  getDeviceDetails() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      this.outdoorInfo = "";
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:parseInt(this.MCampusId),
        floorId: this.selectout_FloorId,
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: 0,
        isIndoor: 0,
        isNeedRoomonly: 1,
        isNeedIntercom: parseInt(voiceLicense)
      };

      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          if (DeviceDetails != null && DeviceDetails != undefined) {
            if (DeviceDetails["apiReturn"] == "Invalid Auth Key") {
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            this.filterdevicedetails = DeviceDetails["apiReturn"];
            DeviceDetails["apiReturn"].forEach(function (e) {
              if (e.DeviceName == DeviceTypeDesc.Emitter) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
              } else if (e.DeviceName == DeviceTypeDesc.HRex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
              } else if (e.DeviceName == DeviceTypeDesc.Rex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
              } 
              else if (e.DeviceName == DeviceTypeDesc.LF) {
                if (e.SubType==DeviceType.DripTray)
                {
                  e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
                }
                else
                {
                  e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
                }
              }
              else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
              } else if (
                e.DeviceName == DeviceTypeDesc.DC ||
                e.DeviceName == DeviceTypeDesc.NewDC
              ) {
                e["NewDeviceName"] = NewDeviceTypeDesc.DC;
              } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
              } else if (e.DeviceName == DeviceTypeDesc.VW) {
                e["NewDeviceName"] = NewDeviceTypeDesc.VW;
              } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
              } else if (e.DeviceName == DeviceTypeDesc.UT) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UT;
              } else if (e.DeviceName == DeviceTypeDesc.UM) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UM;
              } else {
                e["NewDeviceName"] = NewDeviceTypeDesc.Zone;
              }
            });
            this.Device_Info = DeviceDetails["apiReturn"];
            this.outdoorInfo = DeviceDetails["apiReturn"];
            this.DrawMapCall();
          }
        });
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" +
          " - getDeviceDetails() - " +
          err.message
      );
    }
  }

  changecampus(Campusdata) {
    this.selectout_CampusId = Campusdata.CampusId;
    this.selectout_Campusname = Campusdata.CampusName;
    this.Check_ZoomLevel = undefined;
    this.selectout_BuildId = 0;
    this.selectout_FloorId = 0;    
    this.getoutdoorSpace();
    this.changeCampusflag = 1;
    // document.getElementById('divrightsidebar').style.display = "none";
    if (this.bAddEdit == true) {
      // document.getElementById("container_SelectMove").className = "enablebtn";
      // document.getElementById("container_CreateSpace").className = "enablebtn";
      // document.getElementById("container_EditSpace").className = "enablebtn";
      // document.getElementById("container_AddDevice").className = "enablebtn";
      // document.getElementById("container_DeleteSpace").className = "enablebtn";
      this.EnableButton();
    } else {
      this.DisableBtn();
    }
  }

  getoutdoorSpace() {
    this.outdoorloading = true;
    let selectedOutdoordetails = [] as any[];
    const select_campusid = this.selectout_CampusId;
    const voiceLicense = localStorage.getItem("voice_license") ?? "";
    console.log("voiceLicense : " + voiceLicense)
    const outdoorinfo: getUnit = {
      authKey: this.authkey,
      // campusId: select_campusid,
      // buildId: this.selectout_BuildId,
      floorId: select_campusid,
      isIndoor: 0,
      isNeedIntercom: parseInt(voiceLicense),
      isSubZone: 0 
    };

    this.apiService
      .getUnitDetails(outdoorinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((OutdoorSpaceinfo: any[]) => {
        
        OutdoorSpaceinfo["apiReturn"].forEach(function (data) {
          if(data.BuildingName == null || data.FloorName == null)
          {
            data["BuildingName"] = "";
            data["FloorName"] = "";
          }            
            
        });
        
        if (OutdoorSpaceinfo["apiReturn"].length > 0)
          this.ImageUrl =
            appsettings.CAMPUS_IMAGE_FOLDER +
            "/" +
            OutdoorSpaceinfo["apiReturn"][0].FloorPlanImage;
        this.outdoorloading = false;
        selectedOutdoordetails = OutdoorSpaceinfo["apiReturn"];
                
        if(this.selectout_BuildId != 0)
        {
          selectedOutdoordetails = [];
          const buildingId = this.selectout_BuildId;
          OutdoorSpaceinfo["apiReturn"].forEach(function (data) {
            if(data.BuildId == buildingId)
            {
              selectedOutdoordetails.push(data);
            }            
          })   
        }
        
        if(this.selectout_FloorId != 0)
        {
          selectedOutdoordetails = [];
          const floorId = this.selectout_FloorId;
          OutdoorSpaceinfo["apiReturn"].forEach(function (data) {
            if(data.FloorId == floorId)
            {
              selectedOutdoordetails.push(data);
            }            
          })   
        }
        this.outdoorSpaceinfo = selectedOutdoordetails;  
        selectedOutdoordetails.forEach(function (e) {                            
          if (e.Accuracy == "") {
            e['Status'] = 0;
          }
          else if(e.Accuracy != "")
          {
            e['Status'] = 1;          
          }
        })
        this.loadOutdoorData(selectedOutdoordetails);  
      });
  }
  
  loadOutdoorData(selectOutdoordetails)
  {
    this.OutdoorSpaceCnt = selectOutdoordetails.length;
    this.dataSource = new MatTableDataSource(selectOutdoordetails);
    this.dataSourcecount = this.dataSource.data.length;
    this.dataSource.data = selectOutdoordetails;

    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.sort.sort({
        id: "", // The column name specified for matColumnDef attribute
        start: null, // Can be 'asc', 'desc' or null,
        disableClear: false,
      });
      this.sortingDatalist(this.dataSource);
    }, 0);
    this.dataSource.paginator = this.paginator;

    this.selectCampusFull();
  }

  /*********Opendialogwindow*******/
  addOutdoorSpace(polypoints,roomX,roomY) {
    try {
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            return false;
          } else {
            if (this.addoutdoordialogopen == 0) {
              const outdoordetails = {
                CampusId: this.selectout_CampusId,
                CampusName: this.selectout_Campusname,
                BuildId: this.selectout_BuildId,
                BuildingName:this.selectout_Buildname,
                FloorId : this.selectout_FloorId,
                FloorName:this.selectout_Floorname,
                PolyPoints: polypoints,
                roomX:roomX,
                roomY:roomY
              };
              const dialogRef = this.dialog.open(AddOutdoorspaceComponent, {
                disableClose: true,
                width: "38em",
                height: "610px",
                panelClass: "myapp-no-padding-dialog",
                autoFocus: false,
                data: outdoordetails,
              });
              this.addoutdoordialogopen = 1;
              dialogRef.afterClosed().subscribe((result) => {
                this.addoutdoordialogopen = 0;
                if (result != "Cancel") {                  
                  this.enableCreateSpace();
                  localStorage.setItem("CreateNew", "0");
                  this.ClearInteraction();
                  const floorplanslistelement =
                    document.getElementById("divoutdoordetails");
                  if (floorplanslistelement != null)
                    floorplanslistelement.className = "clsenable";
                  const saveBtn = document.getElementById(
                    "btnsaveclickmapview"
                  );
                  if (saveBtn != null) {
                    if (saveBtn.innerHTML == "Done") saveBtn.innerHTML = "Save";
                    this.checkIsSpace = false;
                  }
                  this.selectCampusFull();
                }
              });
            }
          }
        });
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" + " - addOutdoorSpace() - " + err.message
      );
    }
  }

  Onnavbarclick(data) {
    const divoutdoormapview = document.getElementById("divoutdoormapview");
    const divoutdoorlistview = document.getElementById("divoutdoorlistview");
    const divoutdoorcount = document.getElementById("divoutdoorcount");
    const divoutdoorcampusName = document.getElementById("divoutdoorcampusName");
    const divbtnoutdoormap = document.getElementById("divbtnoutdoormap");
    
    if (data == "Map View") {
      this.mapviewflag = 1;
      if(divoutdoormapview != null)
        divoutdoormapview.style.display = "flex";        
      if(divoutdoorlistview != null)
        divoutdoorlistview.style.display = "none";                
      if(divoutdoorcount != null)
        divoutdoorcount.style.display = "none";        
      if(divoutdoorcampusName != null)
        divoutdoorcampusName.style.display = "block";        
      if(divbtnoutdoormap != null)
        divbtnoutdoormap.style.display = "block";
      
      const getmatpaginator = document.getElementsByClassName("clsmatpaginator");
      getmatpaginator[0]["style"].display = "none";
      this.getCampusDetails();
    } else if (data == "List View") {
      this.listviewflag = 1;
      
      if(divoutdoormapview != null)
        divoutdoormapview.style.display = "none";        
      if(divoutdoorlistview != null)
        divoutdoorlistview.style.display = "block";                
      if(divoutdoorcount != null)
        divoutdoorcount.style.display = "block";        
      if(divoutdoorcampusName != null)
        divoutdoorcampusName.style.display = "none";        
      if(divbtnoutdoormap != null)
        divbtnoutdoormap.style.display = "none";
            
      const getmatpaginator = document.getElementsByClassName("clsmatpaginator");
      getmatpaginator[0]["style"].display = "block";
      this.getoutdoorSpace();
    }
  }

  sortingDatalist(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (sortHeaderId != "Accuracy" && data[sortHeaderId] != "") {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }

  create_outdoorspace() {
    const elem = document.getElementById("container_DeleteSpace");
    this.changeCampusflag = 0;
    if (elem != null && elem.classList.contains("enablebtn")) {
      localStorage.setItem("CreateNew", "0");
      this.checkIsSpace = true;
      this.checkIsEdit = false;
      const saveBtn = document.getElementById("btnsaveclickmapview");
      if (saveBtn != null) saveBtn.innerHTML = "Done";
      this.DisableButton("container_CreateSpace");
      const divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
      if(divCreateSpaceMenu != null)
      {
        if (divCreateSpaceMenu.style.display == "none") 
        {
          divCreateSpaceMenu.style.display = "block";
        } 
        else {
          divCreateSpaceMenu.style.display = "none";
        }
      }
    } else {
      this.ResetButton();
    }
    this.ClearInteraction();
  }

  edit_outdoorspace() {
    const elem = document.getElementById("container_DeleteSpace");
    this.changeCampusflag = 0;
    const divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
    if (elem != null && elem.classList.contains("enablebtn")) {
      if(divEditSpaceMenu != null)
      {
        if (divEditSpaceMenu.style.display == "none") {
          divEditSpaceMenu.style.display = "block";
        } else {
          divEditSpaceMenu.style.display = "none";
        }
      }
      const saveBtn = document.getElementById("btnsaveclickmapview");
      // if (saveBtn.innerHTML == "Done") saveBtn.innerHTML = "Save";
      // else
      if (saveBtn != null) saveBtn.innerHTML = "Done";
      this.DisableButton("container_EditSpace");
    } else {
      this.ResetButton();
    }
    this.ClearInteraction();
  }

  draw_outdoorrectangle() {
    const divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    if(divCreateSpaceMenu != null)
      divCreateSpaceMenu.style.display = "none";
    const geometryFunction = createBox();
    this.DrawFeature("Circle", geometryFunction);
  }

  draw_outdoorellipse() {
    const divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    if(divCreateSpaceMenu != null)
      divCreateSpaceMenu.style.display = "none";
    const geometryFunction = createRegularPolygon(20);
    this.DrawFeature("Circle", geometryFunction);
  }

  draw_outdoorfreehand() {
    const divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    if(divCreateSpaceMenu != null)
      divCreateSpaceMenu.style.display = "none";
    this.DrawFeature("Polygon", null);
  }

  //Draw feature for Devices
  DrawFeature(DrawFeature_Type, geometryFunction) {
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;

    const fillcolorStyle = fillcolor_styleSelect();

    this.map.removeInteraction(this.draw);
    let drawRoomSource = this.source;
    function onDrawStart(event) {
      //remove everything drawn previously
      drawRoomSource.clear();
      event.feature.setStyle(fillcolorStyle);
    }

    if (geometryFunction != null) {
      this.draw = new Draw({
        source: this.source,
        type: DrawFeature_Type,
        geometryFunction: geometryFunction,
      });
    } else {
      this.draw = new Draw({
        source: this.source,
        type: DrawFeature_Type,
      });
    }
    this.draw.on("drawstart", onDrawStart);
    drawRoomSource = this.source;

    this.vector = new OlVectorLayer({
      source: this.source,
    });

    this.vector.setStyle(fillcolorStyle);
    this.vector.set("extent", this.imageExtent);

    this.map.addInteraction(this.draw);
    this.map.addLayer(this.vector);

    // Get the array of features
    const features = this.vector.getSource().getFeatures();
    if (features != undefined) {
      // Go through this array and get coordinates of their geometry.
      //features.forEach(function (feature) {});
    }

    let checkdraw;
    const _this = eval('this');
    const _addoutdoorimageheight = this.addoutdoorimageheight;
    this.draw.on("drawend", function (e) {
      checkdraw = IsCheckRoomDrawn(e.feature, _this, 0);
      if (checkdraw == true) {
        Checkpolygon_Intersect_Campus(
          _this.map,
          _this.dialog,
          _this,
          e.feature,
          _this.selectout_CampusId,
          _addoutdoorimageheight
        );
      }
    });
    this.checkroomdrawend = checkdraw;
  }

  Edit_outdoorPoints() {
    if (!SessionCheck(this.router)) {
      return;
    }
    this.checkIsEdit = true;
    this.checkIsSpace = false;

    localStorage.setItem("CreateNew", "3");
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
    const divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
    if (divEditSpaceMenu != null) divEditSpaceMenu.style.display = "none";

    this.DisableButton("container_EditSpace");

    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        interaction.getFeatures().clear();
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
    });

    if (!SessionCheck(this.router)) {
      return;
    }

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;
    this.tempVectorSource.clear();

    const fillcolorStyle = fillcolor_styleSelect();

    
    const arr_layer = [] as any[];
    const _outdoorId = this.selectout_CampusId;
    this.map.getLayers().forEach(function (Outdoorlayer) {
      if (
        Outdoorlayer.get("OutdoorId") != undefined &&
        Outdoorlayer.get("OutdoorId") == "Outdoor_" + _outdoorId
      ) {
        arr_layer[arr_layer.length] = Outdoorlayer;
      }
    });

    this.select = new Select({
      multi: true,
      style: fillcolorStyle,
    });
    this.map.addInteraction(this.select);

    const _select = this.select;
    
    
    this.selectedFeatures = this.select.getFeatures();

    this.map.on("pointermove", (evt) => {
      if (!evt.dragging) {
        const pixel = this.map.getEventPixel(evt.originalEvent);
        const hit = this.map.forEachFeatureAtPixel(
          pixel,
          function (feature, layer) {
            console.log(feature);
            console.log(layer);
            return true;
          }
        );
        if (hit) {
          let circlehit = false;
          const features = _select.getFeatures();
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = evt.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  circlehit = true;
                }
              }
            }
          });
          if (circlehit) {
            this.map.getTargetElement().style.cursor = this.map.hasFeatureAtPixel(this.map.getEventPixel(evt.originalEvent)) ? 'crosshair' : '';
          } else {
            this.map.getTargetElement().style.cursor = "";
          }
        } else {
          this.map.getTargetElement().style.cursor = "";
        }
        
      }
    });
    const ref = eval('this');
    this.modify = new Modify({
      features: this.selectedFeatures,
      deleteCondition: function (e) {
        if (shiftKeyOnly(e) && singleClick(e)) {
          const features = ref.selectedFeatures;
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = e.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  coord.splice(k, 1);
                  ref.updateGeometry(feature, coord);
                  if (IsDevice_In_RoomDrawn(ref.selectedFeatures, ref, ref.addoutdoorimageheight,1,arr_layer) == true)
                    {
                      localStorage.setItem("OutdoorId",feature.values_.OutdoorId);
                      localStorage.setItem("DataId", feature.values_.DataId);
                      localStorage.setItem("DeviceName",NewDeviceTypeDesc.Room);
                      localStorage.setItem("MultiDataId", feature.values_.MultiDataId);
                      localStorage.setItem("MultiDeviceId", feature.values_.MultiDeviceId);
                      localStorage.setItem("MultiFilterDeviceTypeId", feature.values_.MultiFilterDeviceTypeId);
                      localStorage.setItem("UnitId", feature.values_.UnitId);
                      localStorage.setItem("IsDevice", "0");
                      localStorage.setItem("DeviceX", "0");
                      localStorage.setItem("DeviceY", "0");       
                      localStorage.setItem("RoomName", feature.values_.UnitName);   
                    }
                    else
                    {
                      localStorage.setItem("UnitId", "0");
                      localStorage.setItem("DataId", "0");
                    }
                  return;
                }
              }
            }
          });
        } else {
          return;
        }
      },
    });
    // const _modify = this.modify;

    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        this.map.removeInteraction(interaction);
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
    });

    _select.on('select', function (e) {
      if (e.selected) {
       if (_this.select.getFeatures().getLength() == 1) {
        if (e.selected[0] == undefined) {
          getSelected(_this.select.getFeatures().getArray()[0], _this, arr_layer, 1);
        }
        else {
          getSelected(e.selected[0], _this, arr_layer, 1);
        }
      }
      else {
        _this.select.getFeatures().remove(_this.select.getFeatures().getArray()[_this.select.getFeatures().getLength() - 1]);
        getSelected(_this.select.getFeatures().getArray()[0], _this, arr_layer, 1);
      }
    }
    });

    const _addoutdoorimageheight = this.addoutdoorimageheight;
    let _sel_feature = null;
    let _sel_polygon = null;
    const _objMapviewFloorComponent = eval('this');

    const _this = eval('this');
    this.modify.on(
      "modifyend",
      function (e) {
        _sel_feature = e.features;
        if (
          IsCheckRoomDrawn(
            _sel_feature.getArray()[0],
            _objMapviewFloorComponent,
            1
          ) == true &&
          IsDevice_In_RoomDrawn(
            _sel_feature,
            _objMapviewFloorComponent,
            _addoutdoorimageheight,
            1,arr_layer
          ) == true
        ) {
          for (let indx = 0; indx < e.features.getArray().length; indx++) {
            const vertices = e.features.getArray()[indx].getGeometry()
              .flatCoordinates.length;
            if (vertices != 2) {
              _sel_polygon = e.features.getArray()[indx];
              Checkpolygon_Intersect_Campus(
                _objMapviewFloorComponent.map,
                _objMapviewFloorComponent.dialog,
                _sel_feature,
                _sel_polygon,
                _objMapviewFloorComponent.selectout_CampusId,
                _addoutdoorimageheight
              );
            }
          }
        } else {
          ClearStorageeData();
        }
      },
      this.selectedFeatures
    );
    this.map.getInteractions().extend([this.select, this.modify]);
  }

  pointsDist(p0, p1) {
    const dx = p0[0] - p1[0];
    const dy = p0[1] - p1[1];
    return Math.sqrt(dx * dx + dy * dy);
  }

  updateGeometry(feature, coord) {
    feature.getGeometry().setCoordinates([coord]);
  }

  selectedFeatures;
  select_outdoormove(duplicate_feature) {
    this.IsDuplicateAdded = 0;
    const elem = document.getElementById("container_DeleteSpace");
    this.changeCampusflag = 0;
    if (elem != null && elem.classList.contains("enablebtn") ||
      (duplicate_feature != undefined &&
        duplicate_feature.length > 0 &&
        this._translateend == 0) || duplicate_feature == -1
    ) {
      this.checkIsEdit = true;
      this.checkIsSpace = false;
      localStorage.setItem("CreateNew", "2");
      const arr_Layers = this.map.getLayers();
      this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
      const divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
      if (divEditSpaceMenu != null) divEditSpaceMenu.style.display = "none";

      const saveBtn = document.getElementById("btnsaveclickmapview");
      if (saveBtn != null && saveBtn.innerHTML == "Done")
        saveBtn.innerHTML = "Save";

      this.DisableButton("container_SelectMove");

      this.tempVectorSource.clear();
      this.map.removeLayer(this.tempVectorLayer);

      this.map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Translate) {
          interaction.getFeatures().clear();
        }
        if (interaction instanceof Select) {
          interaction.getFeatures().clear();
        }
        if (interaction instanceof Draw) {
          this.map.removeInteraction(interaction);
        }
        if (interaction instanceof Modify) {
          this.map.removeInteraction(interaction);
        }
      });

      if (!SessionCheck(this.router)) {
        return;
      }

      this.checkdrawroomflag = 1;
      this.drawroomselect = 1;
      this.tempVectorSource.clear();

      const fillcolorStyle = fillcolor_styleSelect();

      
      const arr_layer = [] as any[];
      const _addCampusId = this.selectout_CampusId;
      this.map.getLayers().forEach(function (Floorlayer) {
        if (
          Floorlayer.get("OutdoorId") != undefined &&
          Floorlayer.get("OutdoorId") == "Outdoor_" + _addCampusId
        ) {
          arr_layer[arr_layer.length] = Floorlayer;
        }
      });

      if (duplicate_feature != undefined && duplicate_feature !=-1 && duplicate_feature.length > 0) {
        this.select = new Select({
          features: duplicate_feature,
          style: fillcolorStyle,
        });
      } else {
        this.select = new Select({
          style: fillcolorStyle,
        });
      }

      this.map.addInteraction(this.select);
      this.selectedFeatures = this.select.getFeatures();
      this.translate = new Translate({
        features: this.selectedFeatures,
        style: fillcolorStyle,
      });
      const _this = eval('this');
      _this.select.on("select", function (e) {
        if (e.selected) {
          if (_this.select.getFeatures().getLength() == 1) {
            if (e.selected[0] == undefined) {
              getSelected(
                _this.select.getFeatures().getArray()[0],
                _this,
                arr_layer,
                0
              );
            } else {
              getSelected(e.selected[0], _this, arr_layer, 0);
            }
          } else {
            _this.select
              .getFeatures()
              .remove(
                _this.select.getFeatures().getArray()[
                  _this.select.getFeatures().getLength() - 1
                ]
              );
            getSelected(
              _this.select.getFeatures().getArray()[0],
              _this,
              arr_layer,
              0
            );
          }
        }
      });

      const _addfloorimageheight = this.addoutdoorimageheight;
      let _sel_feature = null;
      let _sel_polygon = null;
      const _objMapviewFloorComponent = eval('this');

      this.translate.on(
        "translateend",
        function (e) {
          _this._translateend = 1;
          if (e.features.getArray().length == 1) {
            _sel_feature = e.features.getArray()[0];
            //if (IsCheckRoomDrawn(_sel_feature, _objMapviewFloorComponent, 1) == true) {
            get_selectedfeature_Campus(
              _objMapviewFloorComponent.map,
              _objMapviewFloorComponent.dialog,
              _sel_feature,
              _objMapviewFloorComponent.selectout_CampusId,
              _addfloorimageheight
            );
            //}
            //else{
            //  ClearStorageeData();
            //}
          } else {
            _sel_feature = e.features;
            if (
              IsCheckRoomDrawn(
                _sel_feature.getArray()[0],
                _objMapviewFloorComponent,
                1
              ) == true
            ) {
              for (let indx = 0; indx < e.features.getArray().length; indx++) {
                const vertices = e.features.getArray()[indx].getGeometry()
                  .flatCoordinates.length;
                if (vertices != 2) {
                  _sel_polygon = e.features.getArray()[indx];
                  Checkpolygon_Intersect_Campus(
                    _objMapviewFloorComponent.map,
                    _objMapviewFloorComponent.dialog,
                    _sel_feature,
                    _sel_polygon,
                    _objMapviewFloorComponent.selectout_CampusId,
                    _addfloorimageheight
                  );
                }
              }
            } else {
              ClearStorageeData();
            }
          }
        },
        this.selectedFeatures
      );
      this.map.getInteractions().extend([this.select, this.translate]);
    } else if (this._translateend == 1) {
      this._translateend = 0;
      const ConfirmMessageData = {
        ConfirmMessage: "Do you want to save the modification?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          // dialogRef.close('Cancel');
          // return;
          localStorage.setItem("IsModifyYes", "1");
          this.onSaveCampusSpace();
        } else {
          this.ResetButton();
          this.ClearDataRefresh();
        }
      });
    } else {
      this.ResetButton();
      this.ClearDataRefresh();
    }
  }

  delete_outdoorspace() {
    const elem = document.getElementById("container_DeleteSpace");
    this.changeCampusflag = 0;
    if (elem != null && elem.classList.contains("enablebtn")) {
      const saveBtn = document.getElementById("btnsaveclickmapview");
      if (saveBtn != null && saveBtn.innerHTML == "Done")
        saveBtn.innerHTML = "Save";
      this.DisableButton("container_DeleteSpace");
    }
    const unitId = localStorage.getItem("UnitId") ?? "";
    const selectedUnitId = parseInt(unitId);
    if (selectedUnitId > 0) {
      this.deleteUnit(selectedUnitId);
    } else {
      generateAlertMessage(this.dialog, appsettings.DeleteSpace);
      this.EnableButton();
      return;
    }
    this.EnableButton();
  }

  getHrexflag() {
    if (this.selectout_CampusId != 0) {
      const hrexItem: getSiteAuthKeyReuest = {
        authKey: this.authkey,
      };
      this.technologyoverlayService
        .checkHREXfound(hrexItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((addHRexflag: any[]) => {
          this.addHRexflag = addHRexflag;
        });
    }
  }

  editOutdoordetails(outdoordata) {    
    const editoutdoordata = JSON.stringify(outdoordata);
    const outdoorDevicedata = {
      DeviceEdit: 0,
      devicedata: JSON.parse(editoutdoordata),
    };
    if (this.editoutdoordialogopen == 0) {
      const dialogRef = this.dialog.open(EditOutdoorspaceComponent, {
        disableClose: true,
        width: "28em",
        height: "340px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: outdoorDevicedata,
      });
      this.editoutdoordialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.editoutdoordialogopen = 0;

        if (result != "Cancel") this.selectCampusFull();
      });
    }
  }

  editOutdoorLocatingdata(outdoordata) {
    try {
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      const editoutdoordata = JSON.stringify(outdoordata);
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            return false;
          } else {
            const outdooreditDevicedata = {
              DeviceEdit: 1,
              devicedata: JSON.parse(editoutdoordata),
            };
            if (this.editoutdoordialogopen == 0) {
              const dialogRef = this.dialog.open(EditOutdoorspaceComponent, {
                disableClose: true,
                width: "38em",
                height: "610px",
                panelClass: "myapp-no-padding-dialog",
                autoFocus: false,
                data: outdooreditDevicedata,
              });
              this.editoutdoordialogopen = 1;
              dialogRef.afterClosed().subscribe((result) => {
                this.editoutdoordialogopen = 0;
                if (result != "Cancel") this.getoutdoorSpace();
              });
            }
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" +
          " - editOutdoorLocatingdata() - " +
          err.message
      );
    }
  }

  getMaxRexdevicedetail() {
    const rexItem: getSiteAuthKeyReuest = {
      authKey: this.authkey,
    };
    this.technologyoverlayService
      .getRexdevicedetails(rexItem)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((maxrexvalue: any[]) => {
        this.MaxRexvalue = maxrexvalue[0].DeviceId;
      });
  }

  add_outdoorNewDevice(selectout_CampusId, selectout_Campusname) {
    const elem = document.getElementById("container_DeleteSpace");
    const container_AddDevice = document.getElementById("container_AddDevice");
    this.changeCampusflag = 0;
    if (elem != null && elem.classList.contains("enablebtn")) {
      if (container_AddDevice != null)
        container_AddDevice.style.pointerEvents = "none";
      localStorage.setItem("CreateNew", "1");
      this.checkIsSpace = false;
      this.checkIsEdit = false;
      const saveBtn = document.getElementById("btnsaveclickmapview");
      if (saveBtn != null) saveBtn.innerHTML = "Done";
      this.DisableButton("container_AddDevice");
      if (container_AddDevice != null)
        container_AddDevice.style.pointerEvents = "none";
      try {
        const deviceaddelement = document.getElementById("divAddDevice");
        const devicelistelement = document.getElementById("divDeviceheader");
        if (deviceaddelement != null) deviceaddelement.className = "disable";

        if (devicelistelement != null) devicelistelement.className = "disable";
        if (!SessionCheck(this.router)) {
          return;
        }

        this.getHrexflag();
        this.getMaxRexdevicedetail();
        const _dialog = this.dialog;
        const mapsyncinfo: MapSyncProcess = {
          authKey: this.authkey,
        };
        this.apiService
          .checkMapSync(mapsyncinfo)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((MapSyncinfo: any[]) => {
            this.MapSyncProcess = MapSyncinfo;
            if (this.MapSyncProcess == 1) {
              generateAlertMessage(_dialog, appsettings.MapSyncMsg);
              const deviceaddelement = document.getElementById("divAddDevice");
              if (deviceaddelement != null)
                deviceaddelement.className = "enable";
              const devicelistelement =
                document.getElementById("divDeviceheader");
              if (devicelistelement != null)
                devicelistelement.className = "enable";
                const containerAddDevice = document.getElementById("container_AddDevice");
                if(containerAddDevice != null)
                  containerAddDevice.style.pointerEvents = "";
              this.ResetButton();
              return false;
            } else {
              if (this.addnewDeviceflag == 0) {
                const voiceLicense = localStorage.getItem("voice_license") ?? "";
                const floordetails: getUnit = {
                  authKey: this.authkey,
                  floorId: this.selectout_CampusId,
                  isIndoor: 0,
                  isNeedIntercom: parseInt(voiceLicense),
                  isSubZone: 0 
                };
                this.apiService
                  .getUnitDetails(floordetails)
                  .pipe(takeUntil(this._onDestroy))
                  .subscribe((Unitsinfo: any[]) => {
                    if (Unitsinfo != null && Unitsinfo != undefined) {
                      this.Unitinfo = Unitsinfo["apiReturn"];
                      this.addnewDeviceflag = 1;
                      this.addNewdevicedetails(
                        Unitsinfo["apiReturn"],
                        selectout_CampusId,
                        selectout_Campusname
                      );
                    }
                  });
              }
            }
          });
      } catch (err) {
        const containerAddDevice = document.getElementById("container_AddDevice");
        if(containerAddDevice != null)
          containerAddDevice.style.pointerEvents = "";
        throw new Error(
          "device.component.ts" + " - addNewDevice() - " + err.message
        );
      }
    } else {
      this.ResetButton();
    }

    this.ClearInteraction();
  }

  addNewdevicedetails(UnitInfo, CampusId, CampusName) {
    try {
      const _ShareddialogcloseDetails = {
        SharedreturncloseVal: 1,
      };
      this.alertSharedService.changeclosedialog(_ShareddialogcloseDetails);
      if (this.selectout_CampusId != 0) {
        if (UnitInfo.length == 0) {
          this.addnewDeviceflag = 0;
          generateAlertMessage(
            this.dialog,
            "Please add the space in the campus."
          );
          const containerAddDevice = document.getElementById("container_AddDevice");
          if(containerAddDevice != null)
            containerAddDevice.style.pointerEvents = "";
          this.ResetButton();
          return;
        } else {
          this.Unitnameforfloor = UnitInfo[0].UnitName;
        }
      } else {
        this.Unitnameforfloor = "";
      }

      const CampusDetail = {
        CampusId: CampusId,
        CampusName: CampusName,
        MapDetails: this.map,
        VectorDetails: this.vector,
        BuildingId: this.MBuildingId,
        UnitInfo: "",
        UnitName: "",
        FloorId: 0,
        ImageHeight: this.addoutdoorimageheight,
        ImageWidth: this.ImageWidth,
        HRexvalue: "",
        Gatewayvalue: "",
        MaxRexValue: "",
        SelectedFeature: "",
      };
      const devicedataforadd = [] as any[];
      devicedataforadd.push(this.getdeviceforadd, CampusDetail);

      const dialogRef = this.dialog.open(AddDeviceComponent, {
        disableClose: true,
        width: "55em",
        height: "550px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: CampusDetail,
      });
      dialogRef.afterClosed().subscribe((result) => {
        const containerAddDevice = document.getElementById("container_AddDevice");
        if(containerAddDevice != null)
          containerAddDevice.style.pointerEvents = "";
        this.addnewDeviceflag = 0;
        if (result != "Cancel") {
          this.Selected_ImageDeviceTypename = result.selectImageDeviceTypeName;
          this.Selected_DeviceTypename = result.selectDeviceType;
          this.deviceTypeName = result.selectDeviceType;
          //this.ClearInteraction();
          this.closeDrawFeature();
          this.tempVectorSource.clear();
          this.DrawDeviceFeature(this.Selected_ImageDeviceTypename);
        } else {
          this.ResetButton();
          this.ClearInteraction();
        }

        const devicelistelement = document.getElementById("divDeviceheader");
        const deviceaddelement = document.getElementById("divAddDevice");
        if(devicelistelement != null)
          devicelistelement.className = "enable";        
        if(deviceaddelement != null)
          deviceaddelement.className = "enable";
        this.EnableButton();
      });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - addNewdevicedetails() - " + err.message
      );
    }
  }

  public onSaveCampusSpace = () => {
    this.deviceTypeName = this.Selected_DeviceTypename;
    this.CheckIsAdd = true;
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      (this.document.activeElement as HTMLTextAreaElement).blur();
      if (
        this.CheckIsAdd == true &&
        this.checkIsEdit == true &&
        this.outdoorEdit_pointsbool
      ) {
        let verticesinfo = null;
        const imageheight = this.addoutdoorimageheight;
        this.outdoorEdit_pointsbool = false;

        const DataId = localStorage.getItem("DataId");
        const OutdoorId = localStorage.getItem("OutdoorId");
        this.deviceTypeName = localStorage.getItem("DeviceName");
        const _this = eval('this');
        let vertices;
        let polypoints;
        verticesinfo = localStorage.getItem("Polypoints");
        if (verticesinfo == null || verticesinfo == undefined) {
          let keepGoing = true;
          this.map.getLayers().forEach(function (selRoomlayer) {
            if (keepGoing) {
              if (
                selRoomlayer.get("OutdoorId") != undefined &&
                selRoomlayer.get("OutdoorId") === "Outdoor_" + OutdoorId
              ) {
                const arrFeature = selRoomlayer.getSource().getFeatures();
                if (arrFeature) {
                  // Go through this array and get coordinates of their geometry.
                  arrFeature.forEach(function (selRoomfeature) {
                    const SelectedRoomDataId = selRoomfeature
                      .getProperties()
                      .MultiDataId.toString();
                    if (
                      SelectedRoomDataId.split(",").indexOf(
                        parseInt(DataId).toString()
                      ) != -1 &&
                      selRoomfeature.getGeometry().flatCoordinates.length !=
                        2 &&
                      _this.rotateIsInside == 1
                    ) {
                      vertices = selRoomfeature.getGeometry().getCoordinates();
                      keepGoing = false;
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
              }
            }
          });

          if (vertices == undefined) {
            let _DeviceTypeNew = localStorage.getItem("DeviceName");
            if (_this.rotateIsInside == 0) {
              generateAlertMessage(
                _this.dialog,
                "Please change the room drawn as it able to have all its devices in it."
              );
            } else if (_DeviceTypeNew != undefined && _DeviceTypeNew !=NewDeviceTypeDesc.Room) {
              if (this.changeCampusflag == 0) {
                _DeviceTypeNew = getDeviceTypeNew(_DeviceTypeNew);
                generateAlertMessage(
                  this.dialog,
                  "Please place a " + _DeviceTypeNew + " in the room drawn"
                );
              }
            }
          } else {
            verticesinfo = "";
            if (vertices.length > 0) {
              for (let j = 0; j < vertices[0].length; j++) {
                polypoints = imageheight - parseFloat(vertices[0][j][1]);
                vertices[0][j][0] = Math.floor(vertices[0][j][0]);
                vertices[0][j][1] = Math.floor(polypoints);
                if (verticesinfo.length == 0) {
                  verticesinfo += vertices[0][j][0] + "," + vertices[0][j][1];
                } else {
                  verticesinfo =
                    verticesinfo +
                    " " +
                    vertices[0][j][0] +
                    "," +
                    vertices[0][j][1];
                }
              }
            }
          }
          if (verticesinfo != null)
            localStorage.setItem("Polypoints", verticesinfo);
          localStorage.setItem("OldUnitId", "0");
          //localStorage.setItem("IsDevice", "1");
        }
        const _UnitId = localStorage.getItem("UnitId");

        if (
          _UnitId != "0" &&
          this.changeCampusflag == 0 &&
          verticesinfo != null
        ) {
          this.onupdateOutdoorRoom();
        } else {
          this.outdoorEdit_pointsbool = true;
          this.EditSaveExists();
        }
      } else {
        try {
          if (this.selectout_CampusId == 0) {
            generateAlertMessage(this.dialog, appsettings.CampusRequire);
            return false;
          }
          // Get the array of features
          if (this.CheckIsAdd != true || this.checkIsSpace == true) {
            if (this.vector.getSource() == null) {
              generateAlertMessage(this.dialog, "Please draw the room");
              return false;
            }
            const features = this.vector.getSource().getFeatures();
            if (features.length == 0 && this.selectout_CampusId == 0) {
              generateAlertMessage(this.dialog, appsettings.CampusRequire);
              return false;
            } else if (features.length == 0 && this.selectout_CampusId != 0) {
              if (
                this.deviceTypeName == DeviceTypeDesc.Emitter ||
                this.deviceTypeName == DeviceTypeDesc.Monitor ||
                this.deviceTypeName == DeviceTypeDesc.VW ||
                this.deviceTypeName == DeviceTypeDesc.UM
              ) {
                generateAlertMessage(this.dialog, "Please draw the room");
                return false;
              } else if (this.deviceTypeName == undefined) {
                generateAlertMessage(this.dialog, "Please draw the room");
                return false;
              }
            }
          }
        } catch {
          if (
            this.deviceTypeName == DeviceTypeDesc.Emitter ||
            this.deviceTypeName == DeviceTypeDesc.Monitor ||
            this.deviceTypeName == DeviceTypeDesc.VW ||
            this.deviceTypeName == DeviceTypeDesc.UM
          ) {
            generateAlertMessage(this.dialog, "Please draw the room");
            return false;
          }
        }
        //this.isSaveFloorDevice = false;

        let vertices;
        let verticesinfo = "";
        let roomX;
        let roomY;
        let polypoints;
        let featurespoly;
        const imageheight = this.addoutdoorimageheight;
        // Get the array of features
        if (this.CheckIsAdd != true || this.checkIsSpace == true) {
          const features = this.vector.getSource().getFeatures();
          if (features != undefined) {            
            // Go through this array and get coordinates of their geometry.
            features.forEach(function (feature) {
              featurespoly = feature.getGeometry().getCoordinates();
              featurespoly[0].pop();
              for (let j = 0; j < featurespoly[0].length; j++) {
                polypoints = imageheight - parseFloat(featurespoly[0][j][1]);
                featurespoly[0][j][0] = Math.floor(featurespoly[0][j][0]);
                featurespoly[0][j][1] = Math.floor(polypoints);
                if (verticesinfo.length == 0) {
                  verticesinfo +=
                    featurespoly[0][j][0] + "," + featurespoly[0][j][1];
                } else {
                  verticesinfo =
                    verticesinfo +
                    " " +
                    featurespoly[0][j][0] +
                    "," +
                    featurespoly[0][j][1];
                }
              }
              roomX = convertToSvgEcllipseXCoordinate(parseFloat(featurespoly[0][0]), true);
              roomY = convertToSvgEcllipseYCoordinate(parseFloat(featurespoly[0][1]),imageheight); 
            });
          }
        } else if (localStorage.getItem("CreateNew") == "1") {
          const DataId = localStorage.getItem("DataId");
          const OutdoorId = localStorage.getItem("OutdoorId");
          let keepGoing = true;
          this.map.getLayers().forEach(function (selRoomlayer) {
            if (keepGoing) {
              if (
                selRoomlayer.get("OutdoorId") != undefined &&
                selRoomlayer.get("OutdoorId") === OutdoorId
              ) {
                const arrFeature = selRoomlayer.getSource().getFeatures();
                if (arrFeature) {
                  // Go through this array and get coordinates of their geometry.
                  arrFeature.forEach(function (selRoomfeature) {
                    const SelectedRoomDataId =
                      selRoomfeature.getProperties().DataId;
                    if (parseInt(SelectedRoomDataId) == parseInt(DataId)) {
                      vertices = selRoomfeature.getGeometry().getCoordinates();
                      keepGoing = false;
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
              }
            }
          });

          if (vertices == undefined) {
            let _DeviceTypeNew = localStorage.getItem("DeviceTypeName");
            if (_DeviceTypeNew != undefined && _DeviceTypeNew !=NewDeviceTypeDesc.Room) {
              _DeviceTypeNew = getDeviceTypeNew(_DeviceTypeNew);
              generateAlertMessage(
                this.dialog,
                "Please place a " + _DeviceTypeNew + " in the room drawn"
              );
            }
          } else {
            if (vertices.length > 0) {
              for (let j = 0; j < vertices[0].length; j++) {
                polypoints = imageheight - parseFloat(vertices[0][j][1]);
                vertices[0][j][0] = Math.floor(vertices[0][j][0]);
                vertices[0][j][1] = Math.floor(polypoints);
                if (verticesinfo.length == 0) {
                  verticesinfo += vertices[0][j][0] + "," + vertices[0][j][1];
                } else {
                  verticesinfo =
                    verticesinfo +
                    " " +
                    vertices[0][j][0] +
                    "," +
                    vertices[0][j][1];
                }
              }
               roomX = convertToSvgEcllipseXCoordinate(parseFloat(vertices[0]), true);
               roomY = convertToSvgEcllipseYCoordinate(parseFloat(vertices[1]), imageheight); 
            }
          }
        }

        const _DataId = localStorage.getItem("DataId");
        const _UnitId = localStorage.getItem("UnitId");
        const _RoomName = localStorage.getItem("RoomName");
        if (this.checkIsSpace == true) {
          this.onSaveOutdoorSpace(verticesinfo,roomX,roomY);
        } else if (_DataId != null && _UnitId != null && _RoomName != null) {
          this.onsaveOutdoorRoom(
            verticesinfo,
            this.deviceTypeName,
            _DataId,
            _UnitId,
            _RoomName
          );
        }
      }
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" +
          " - onSaveCampusRoom() - " +
          err.message
      );
    }
  };

  onupdateOutdoorRoom() {
    try {
      const _Polypoints = localStorage.getItem("Polypoints") ?? "";
      const _UnitId = localStorage.getItem("UnitId") ?? "";
      const Old_UnitId = localStorage.getItem("OldUnitId") ?? "";
      const _DeviceX = localStorage.getItem("DeviceX") ?? "";
      const _DeviceY = localStorage.getItem("DeviceY") ?? "";
      const _DataId = localStorage.getItem("DataId") ?? "";
      const _MultiDataId = localStorage.getItem("MultiDataId") ?? "";            
      const _IsDevice = localStorage.getItem("IsDevice");
      this.deviceTypeName = localStorage.getItem("DeviceName");
      const arr_DeviceX = _DeviceX.toString().split(",");
      const arr_DeviceY = _DeviceY.toString().split(",");
      
      let adddevicedetails: getPolylineRequest;
      if (_IsDevice == "1") {
        adddevicedetails = {
          authKey: this.authkey,
          dataId: _DataId,
          unitId: parseInt(_UnitId),
          polypoints: _Polypoints,
          deviceX: arr_DeviceX[0].toString(),
          deviceY: arr_DeviceY[0].toString(),
          unitIdOld: parseInt(Old_UnitId),
          isOutdoorLocation:1,
        };
      } else {
        adddevicedetails = {
          authKey: this.authkey,
          dataId: _MultiDataId,
          unitId: parseInt(_UnitId),
          polypoints: _Polypoints,
          deviceX: _DeviceX,
          deviceY: _DeviceY,
          unitIdOld: parseInt(Old_UnitId),
          isOutdoorLocation:1,
        };
      }

      this.technologyoverlayService
        .updatePolylineFloor(adddevicedetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            this.EditSaveCall();
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, apiresponse.toString());
            const saveclick = document.getElementById("btnsaveclickmapview");
            if(saveclick != null)
              saveclick.className = "clsdeviceaddbutton mat-raised-button";
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(this.dialog, apiresponse.toString());
            const saveclick = document.getElementById("btnsaveclickmapview");
            if(saveclick != null)
              saveclick.className = "clsdeviceaddbutton mat-raised-button mat-primary";
          }
        });
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" +
          " - onupdateOutdoorRoom() - " +
          err.message
      );
    }
  }

  onSaveOutdoorSpace(ploypoints,roomX,roomY) {
    this.addOutdoorSpace(ploypoints,roomX,roomY);
  }

  onsaveOutdoorRoom(polypoints, devicename, _DataId, _UnitId, _RoomName) {
    try {
     
     
      let vertices;
      this.strDeviceId = 0;
      this.selectedUnit = 5;
      this.showHrex = true;
      this.showGateway = true;
      this.strRoomName = "testdevicenewtech";
      const Device_Type = this.deviceTypeName;

      // Get the array of features
      const features = this.tempVectorLayer.getSource().getFeatures();
      // Go through this array and get coordinates of their geometry.
      features.forEach(function (feature) {
        vertices = feature.getGeometry().getCoordinates();
      });

      const deviceX = convertToSvgEcllipseXCoordinate(
        parseFloat(vertices[0]),
        true
      );
      const deviceY = convertToSvgEcllipseYCoordinate(
        parseFloat(vertices[1]),
        this.addoutdoorimageheight
      );

      const Device_info = {
        DeviceName: Device_Type,
        CampusId: this.MCampusId,
        BuildId: 0,
        FloorId: 0,
        UnitId: _UnitId,
        UnitName: _RoomName,
        CampusName: this.campusName,
        PolyPoints: polypoints,
        DeviceX: deviceX,
        DeviceY: deviceY,
        MaxRexValue: this.MaxRexvalue,
        DataId: _DataId,
      };
      this.edit_DeviceDetails(Device_info, this.dialog, 1, this);
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" + " - onsaveFloorRoom() - " + err.message
      );
    }
  }

  edit_DeviceDetails(deviceinfo, dialog, addflag, _MapviewFloorComponent) {
    if (_MapviewFloorComponent.checkIsEditOpen == false) {
      _MapviewFloorComponent.checkIsEditOpen = true;
      let width = "37em";
      let height = "580px";

      localStorage.setItem("DeviceName", deviceinfo.DeviceName);
      _MapviewFloorComponent.deviceTypeName =
        localStorage.getItem("DeviceName");
      if (deviceinfo.DeviceName == "UT") {
        width = "89rem";
        height = "740px";
      }
      
      let HRexFlag = 0;
      let GatewayFlag = 0;
      if(addflag == 1)
      {
        // if (this.addfloorid != 0) {
          if (this.addHRexflag.toString() == "H-rex found") {
            HRexFlag = 1;
            GatewayFlag = 0;
          } else if (this.addHRexflag.toString() == "Gateway found") {
            HRexFlag = 0;
            GatewayFlag = 1;
          } else if (this.addHRexflag.toString() == "both are found") {
            HRexFlag = 1;
            GatewayFlag = 1;
          } else if (this.addHRexflag.toString() == "false") {
            HRexFlag = 0;
            GatewayFlag = 0;
          }
        // }
      }

      const Devicedetails_info = {
        DeviceData: deviceinfo,
        AddDeviceflag: addflag,
        HRexvalue : HRexFlag,
        Gatewayvalue : GatewayFlag
      };
      const dialogRef = dialog.open(EditDeviceComponent, {
        disableClose: true,
        width: width,
        height: height,
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: Devicedetails_info,
      });
      dialogRef.afterClosed().subscribe((result) => {
        _MapviewFloorComponent.checkIsEditOpen = false;
        if (result != "Cancel") {
          const _btnEditSaveExists = document.getElementById("btnEditSaveExists");
          if (_btnEditSaveExists != null) _btnEditSaveExists.click();
        }
      });
    }
  }

  EditSaveCall() {
    this.deviceTypeName = localStorage.getItem("DeviceName");
    const devicename = localStorage.getItem("DeviceName");
    if (devicename == DeviceTypeDesc.Rex || devicename == DeviceTypeDesc.HRex) {
      this.technologyoverlayService.reloadiniltc();
      this.technologyoverlayService.systemreset_ltc();
    }
    if (devicename == DeviceTypeDesc.Pullcord) {
      this.technologyoverlayService.reloadPullCordConfig_ltc();
    } else if (
      devicename == DeviceTypeDesc.DC ||
      devicename == DeviceTypeDesc.NewDC
    ) {
      this.apiService.reload_DC_Config();
    } else if (devicename == DeviceTypeDesc.UT) {
      this.apiService.reloadDCTdevice();
    } else if (
      devicename != DeviceTypeDesc.Rex &&
      devicename != DeviceTypeDesc.HRex &&
      devicename != DeviceTypeDesc.Gateway
    ) {
      this.apiService.reload_DrawRoom();
    }

    if (devicename == "Room") {
      if (localStorage.getItem("IsModifyYes") != "1")
        generateAlertMessage(this.dialog, appsettings.SaveSpace);
      this.outdoorEdit_pointsbool = true;
    } else if (devicename != null) {
      this.outdoorEdit_pointsbool = true;
      if (
        devicename == DeviceTypeDesc.Rex ||
        devicename == DeviceTypeDesc.HRex
      ) {
        if (localStorage.getItem("IsModifyYes") != "1")
          generateAlertMessage(this.dialog, appsettings.SaveDevice);
      } else {
        if (localStorage.getItem("IsModifyYes") != "1")
          generateAlertMessage(this.dialog, appsettings.SaveDevice);
      }
    }
    if (localStorage.getItem("IsModifyYes") != "1") {
      localStorage.removeItem("IsModifyYes");
      this.EditSaveExists();
    } else {
      localStorage.removeItem("IsModifyYes");
      //const unitId = localStorage.getItem("UnitId") ?? "";
      //this.IsDuplicateAdded = parseInt(unitId);
      this.IsDuplicateAdded = -1;
      this.ClearStoredData();
      this.getDeviceDetails();
    }
  }

  EditSaveExists() {
    this.ResetButton();
    this.ClearDataRefresh();
  }

  ClearInteraction() {
    const arr_Interactions = [] as any[];

    try {
      this.map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Translate) {
          //interaction.setActive(false);
          arr_Interactions[arr_Interactions.length] = interaction;
        }
        if (interaction instanceof Modify) {
          //interaction.setActive(false);
          arr_Interactions[arr_Interactions.length] = interaction;
        }
        if (interaction instanceof Select) {
          //interaction.setActive(false);
          arr_Interactions[arr_Interactions.length] = interaction;
        }
        if (interaction instanceof Draw) {
          //interaction.setActive(false);
          arr_Interactions[arr_Interactions.length] = interaction;
        }
      });

      for (let idx_int = 0; idx_int < arr_Interactions.length; idx_int++) {
        this.map.removeInteraction(arr_Interactions[idx_int]);
      }
      this.source.clear();
      this.map.removeLayer(this.vector);
      this.tempVectorSource.clear();
      this.map.removeLayer(this.tempVectorLayer);
    } catch (e) {
      console.log("ClearInteraction Error");
    }

    this.ClearDataRefresh();
  }

  DisableBtn() {
    
    const container_SelectMove = document.getElementById("container_SelectMove");
    const container_CreateSpace = document.getElementById("container_CreateSpace");
    const container_EditSpace = document.getElementById("container_EditSpace");
    const container_AddDevice = document.getElementById("container_AddDevice");
    const container_DeleteSpace = document.getElementById("container_DeleteSpace");
    
    if(container_SelectMove != null)
      container_SelectMove.className = "disablebtn";    
    if(container_CreateSpace != null)
      container_CreateSpace.className = "disablebtn";      
    if(container_EditSpace != null)
      container_EditSpace.className = "disablebtn";    
    if(container_AddDevice != null)
      container_AddDevice.className = "disablebtn";      
    if(container_DeleteSpace != null)
      container_DeleteSpace.className = "disablebtn";      
  }

  DisableButton(Enable_Element) {
    
    const container_SelectMove = document.getElementById("container_SelectMove");
    const container_CreateSpace = document.getElementById("container_CreateSpace");
    const container_EditSpace = document.getElementById("container_EditSpace");
    const container_AddDevice = document.getElementById("container_AddDevice");
    const container_DeleteSpace = document.getElementById("container_DeleteSpace");
    const enableElement = document.getElementById(Enable_Element);
    
    if(container_SelectMove != null)
      container_SelectMove.className = "disablebtn";    
    if(container_CreateSpace != null)
      container_CreateSpace.className = "disablebtn";      
    if(container_EditSpace != null)
      container_EditSpace.className = "disablebtn";    
    if(container_AddDevice != null)
      container_AddDevice.className = "disablebtn";      
    if(container_DeleteSpace != null)
      container_DeleteSpace.className = "disablebtn";
    if(enableElement != null)  
      enableElement.className = "enablebtn";          

    if (Enable_Element != "container_DeleteSpace") {
      this.ClearStoredData();
    }
  }

  EnableButton() {
    const container_SelectMove = document.getElementById("container_SelectMove");
    const container_CreateSpace = document.getElementById("container_CreateSpace");
    const container_EditSpace = document.getElementById("container_EditSpace");
    // const container_AddDevice = document.getElementById("container_AddDevice");
    const container_DeleteSpace = document.getElementById("container_DeleteSpace");
    
    if(container_SelectMove != null)
      container_SelectMove.className = "enablebtn";    
    if(container_CreateSpace != null)
      container_CreateSpace.className = "enablebtn";      
    if(container_EditSpace != null)
      container_EditSpace.className = "enablebtn";    
    // if(container_AddDevice != null)
    //   container_AddDevice.className = "enablebtn";      
    if(container_DeleteSpace != null)
      container_DeleteSpace.className = "enablebtn";          
  }
  
  enableCreateSpace()
  {    
    const container_SelectMove = document.getElementById("container_SelectMove");    
    const container_EditSpace = document.getElementById("container_EditSpace");
    // const container_AddDevice = document.getElementById("container_AddDevice");
    const container_DeleteSpace = document.getElementById("container_DeleteSpace");
    
    if(container_SelectMove != null)
      container_SelectMove.className = "enablebtn";              
    if(container_EditSpace != null)
      container_EditSpace.className = "enablebtn";    
    // if(container_AddDevice != null)
    //   container_AddDevice.className = "enablebtn";      
    if(container_DeleteSpace != null)
      container_DeleteSpace.className = "enablebtn";       
  }

  ResetButton() {
    this.checkIsSpace = false;
    this.CheckIsAdd = false;
    this.checkIsEdit = false;
    this.checkIsEditOpen = false;
    this._translateend = 0;
    localStorage.setItem("CreateNew", "0");

    this.EnableButton();

    const saveclick = document.getElementById("btnsaveclickmapview");
    if (saveclick != null)
      saveclick.className = "clsdeviceaddbutton mat-raised-button mat-primary";
    if (saveclick != null && saveclick.innerHTML == "Done")
      saveclick.innerHTML = "Save";

    const divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
    const divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");

    if (divEditSpaceMenu != null) divEditSpaceMenu.style.display = "none";
    if (divCreateSpaceMenu != null) divCreateSpaceMenu.style.display = "none";
  }

  ClearStoredData() {
    localStorage.removeItem("SpaceName");
    localStorage.removeItem("SpaceId");
    localStorage.removeItem("UnitId");
    localStorage.removeItem("DataId");
    localStorage.removeItem("RoomName");

    localStorage.removeItem("Polypoints");
    localStorage.removeItem("DeviceX");
    localStorage.removeItem("DeviceY");
    localStorage.removeItem("DeviceName");
    localStorage.removeItem("MultiDataId");
    localStorage.removeItem("MultiDeviceId");
    localStorage.removeItem("MultiFilterDeviceTypeId");
    localStorage.removeItem("IsDevice");
    localStorage.removeItem("DeviceTypeName");
    localStorage.removeItem("DeviceCount");
  }

  ClearDataRefresh() {
    this.getDeviceDetails();
    this.ClearStoredData();
  }

  closeDrawFeature() {
    try {
      this.map.removeInteraction(this.draw);
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts - closeDrawFeature() - " + err.message
      );
    }
  }

  //Draw feature for Devices
  DrawDeviceFeature(ImageDrawFeature_Type) {
    localStorage.setItem("DeviceTypeName", this.deviceTypeName);
    const DrawFeature_Type = "Point";
    const _addOutdoorId = this.selectout_CampusId;
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;
    const imagescale = 0.25;
    this.tempVectorSource.clear();
    const fillcolorStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ {
          anchor: [15, 15],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          src: "assets/images/" + ImageDrawFeature_Type,
          scale: imagescale,
        }
      ),
    });

    this.map.removeInteraction(this.draw);
    let drawSource = this.source;
  
    function onDrawStart(event) {
      //remove everything drawn previously
      console.log(event);
      drawSource.clear();      
    }

    this.draw = new Draw({
      source: this.tempVectorSource,
      type: DrawFeature_Type,
      style: fillcolorStyle,
    });

    this.draw.on("drawstart", onDrawStart);

    drawSource = this.tempVectorSource;

    this.tempVectorLayer = new OlVectorLayer({
      source: this.tempVectorSource,
      style: fillcolorStyle,
    });

    this.tempVectorLayer.setStyle(fillcolorStyle);
    this.tempVectorLayer.set("extent", this.imageExtent);

    

    this.map.addInteraction(this.draw);
    this.map.addLayer(this.tempVectorLayer);

    let checkdraw;
    const _this = eval('this');
    this.draw.on("drawend", function (e) {
      checkdraw = true;

      const IsPlaced = getNewFeature_Campus(
        _this.map,
        _this.dialog,
        e.feature,
        _addOutdoorId
      );
      if (IsPlaced == 0) {
        drawSource.clear();
        e.feature.values_.geometry.flatCoordinates = "";
      } else {
        e.feature.setStyle(fillcolorStyle);
        _this.map.removeInteraction(_this.draw);
      }
    });

    this.checkroomdrawend = checkdraw;
  }

  deleteUnit(deleteUnitId) {
    try {
      // const deviceCount = localStorage.getItem("DeviceCount") ?? "";
      // const DeviceCount = parseInt(deviceCount);

      // let Device_Count = "";
      // let message = "";

      // if (DeviceCount > 0) {
      //   message = "It contains ";
      //   Device_Count = DeviceCount + " Devices.";
      // }

      const GeneralMessageData = {
        General_header: "Delete Space ",
        GeneralMessage:
          "Are you sure you would like to delete this Space?<br> " +
          // message +
          // " <b>" +
          // Device_Count +
          " </b>",
        General_first: "Yes ",
        General_second: "No",
      };

      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.DeleteUnitbyId(deleteUnitId);
        } else {
          dialogRef.close("Cancel");
          localStorage.setItem("UnitId", "0");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "outdoorlocating.component.ts" + " - deleteUnit - " + err.message
      );
    }
  }

  DeleteUnitbyId(deleteUnitId) {
    const deleteunitById: deleteUnitById = {
      authKey: this.authkey,
      unitId: parseInt(deleteUnitId),
    };
    this.ClearStoredData();

    this.technologyoverlayService
      .deleteUnit(deleteunitById)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
        if (apiresponse.toString() == "true") {
          generateAlertMessage(this.dialog, "Space deleted successfully.");
          this.enableCreateSpace();          
          localStorage.setItem("CreateNew", "0");
          this.ClearInteraction();
          const floorplanslistelement =
            document.getElementById("divoutdoordetails");
          if (floorplanslistelement != null)
            floorplanslistelement.className = "clsenable";
          this.selectCampusFull();
        } else if (apiresponse.toString() == "false") {
          generateAlertMessage(this.dialog, "Space deleted failed ");
        } else if (apiresponse["condition"] == "Authfalse") {
          this.thisDialogRef.close("close");
          AuthFailedMessage(this.dialog, this.router);
          return;
        } else {
          generateAlertMessage(this.dialog, apiresponse.toString());
        }
        this.thisDialogRef.close();
      });
  }

  Rotate_Points(isScale) {
    console.log("Rotate_Points ");
    if (!SessionCheck(this.router)) {
      return;
    }
    const divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
    if (divEditSpaceMenu != null) divEditSpaceMenu.style.display = "none";
    this.checkIsEdit = true;
    this.checkIsSpace = false;

    localStorage.setItem("CreateNew", "3");
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
    if (divEditSpaceMenu != null) divEditSpaceMenu.style.display = "none";

    this.DisableButton("container_EditSpace");

    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        interaction.getFeatures().clear();
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
    });

    if (!SessionCheck(this.router)) {
      return;
    }

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;
    this.tempVectorSource.clear();

    const fillcolorStyle = fillcolor_styleSelect();

    
    const arr_layer = [] as any[];

    const _outdoorId = this.selectout_CampusId;
    this.map.getLayers().forEach(function (Outdoorlayer) {
      if (
        Outdoorlayer.get("OutdoorId") != undefined &&
        Outdoorlayer.get("OutdoorId") == "Outdoor_" + _outdoorId
      ) {
        arr_layer[arr_layer.length] = Outdoorlayer;
      }
    });

    const pstyle = new Style({
      geometry: function (feature) {
        const modifyGeometry = feature.get("modifyGeometry");
        return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
      },
      fill: new Fill({
        color: "rgba(122, 199, 234,0.35)",
      }),
      stroke: new Stroke({
        color: "rgba(122, 199, 234,1)",
        width: 4,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: "rgba(122, 199, 234,1)",
          width: 4,
        }),
        fill: new Fill({
          color: "#ffffff",
        }),
      }),
    });
    this.select = new Select({
      multi: true,
      style: function (feature) {
        const styles = [pstyle];
        const modifyGeometry = feature.get("modifyGeometry");
        const geometry = modifyGeometry
          ? modifyGeometry.geometry
          : feature.getGeometry();
        const result = calculateCenter(geometry);
        const center = result.center;
        if (center) {
          styles.push(
            new Style({
              geometry: new Point(center),
              image: new CircleStyle({
                radius: 1,
                fill: new Fill({
                  color: "rgba(247, 53, 53, 0.1)",
                }),
              }),
            })
          );
          const coordinates = result.coordinates;
          if (coordinates) {
            const minRadius = result.minRadius;
            const sqDistances = result.sqDistances;
            const rsq = minRadius * minRadius;
            const points = coordinates.filter(function (coordinate, index) {
              return sqDistances[index] > rsq;
            });
            styles.push(
              new Style({
                geometry: new MultiPoint(points),
                image: new CircleStyle({
                  radius: 5,
                  stroke: new Stroke({
                    color: "rgba(122, 199, 234,1)",
                    width: 4,
                  }),
                  fill: new Fill({
                    color: "#ffffff",
                  }),
                }),
              })
            );
          }
        }
        return styles;
      },
    });

    const _select = this.select;
    
    this.selectedFeatures = this.select.getFeatures();

    this.map.on("pointermove", (evt) => {
      if (!evt.dragging) {
        const pixel = this.map.getEventPixel(evt.originalEvent);
        const hit = this.map.forEachFeatureAtPixel(
          pixel,
          function (feature, layer) {
            console.log(feature);
            console.log(layer);
            return true;
          }
        );
        if (hit) {
          let circlehit = false;
          const features = _select.getFeatures();
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = evt.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  circlehit = true;
                }
              }
            }
          });
          if (circlehit) {
            this.map.getTargetElement().style.cursor = "crosshair";
          } else {
            this.map.getTargetElement().style.cursor = "";
          }
        } else {
          this.map.getTargetElement().style.cursor = "";
        }
      }
    });

    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        this.map.removeInteraction(interaction);
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
    });

    _select.on("select", function (e) {
      console.log("selected on triggered");
      if (e.selected) {                
        const _DataId = e.selected[0].getProperties().DataId;
        const _MultiDataId = e.selected[0].getProperties().MultiDataId;
        const geometry = e.selected[0].getProperties().geometry;

        if (_DataId) {
          _select.getFeatures().clear();
          // _modify.features_.array_ = []
          _select.multi_ = true;
          _select.style_ = fillcolorStyle;
          for (let indx = 0; indx < arr_layer.length; indx++) {
            const features_device = arr_layer[indx].getSource().getFeatures();
            if (features_device) {
              // Go through this array and get coordinates of their geometry.
              features_device.forEach(function (feature) {
                if (
                  _MultiDataId
                    .toString()
                    .split(",")
                    .indexOf(
                      parseInt(feature.getProperties().DataId).toString()
                    ) != -1 &&
                  geometry.flatCoordinates.length != 2
                ) {
                  
                  _select.getFeatures().push(feature);
                }
              });
            }
          }
        }
      }
    });

    const _addoutdoorimageheight = this.addoutdoorimageheight;
    let _sel_feature = null;
    let _sel_polygon = null;
    const _objMapviewFloorComponent = eval('this');
    const ref = eval('this');
    this.modify = new Modify({
      features: ref.selectedFeatures,
      condition: function (event) {
        return primaryAction(event) && !platformModifierKeyOnly(event);
      },
      deleteCondition: function (e) {
        if (shiftKeyOnly(e) && singleClick(e)) {
          const features = ref.selectedFeatures;
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = e.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  coord.splice(k, 1);
                  ref.updateGeometry(feature, coord);
                  return;
                }
              }
            }
          });
        } else {
          return;
        }
      },
      insertVertexCondition: never,
      style: function (feature) {
        //feature.get('features').forEach(function (modifyFeature) {
        const features = ref.selectedFeatures;
        features.forEach(function (modifyFeature) {
          const modifyGeometry = modifyFeature.get("modifyGeometry");
          console.log("modifyGeometry style " + modifyGeometry);
          if (modifyGeometry) {
            const point = feature.getGeometry().getCoordinates();
            let modifyPoint = modifyGeometry.point;
            console.log("modifyGeometry modifyPoint " + modifyPoint);
            if (!modifyPoint) {
              // save the initial geometry and vertex position
              modifyPoint = point;
              modifyGeometry.point = modifyPoint;
              modifyGeometry.geometry0 = modifyGeometry.geometry;
              // get anchor and minimum radius of vertices to be used
              const result = calculateCenter(modifyGeometry.geometry0);
              modifyGeometry.center = result.center;
              modifyGeometry.minRadius = result.minRadius;
            }

            const center = modifyGeometry.center;
            const minRadius = modifyGeometry.minRadius;
            let dx, dy;
            dx = modifyPoint[0] - center[0];
            dy = modifyPoint[1] - center[1];
            const initialRadius = Math.sqrt(dx * dx + dy * dy);
            if (initialRadius > minRadius) {
              const initialAngle = Math.atan2(dy, dx);
              dx = point[0] - center[0];
              dy = point[1] - center[1];
              const currentRadius = Math.sqrt(dx * dx + dy * dy);
              if (currentRadius > 0) {
                const currentAngle = Math.atan2(dy, dx);
                const geometry = modifyGeometry.geometry0.clone();
                if (isScale == 1) {
                  geometry.scale(
                    currentRadius / initialRadius,
                    undefined,
                    center
                  );
                } else {
                  geometry.rotate(currentAngle - initialAngle, center);
                }

                modifyGeometry.geometry = geometry;
              }
            }
          }
        });
        return style(feature);
      },
    });

    this.modify.on("modifystart", function (event) {
      console.log("modifystart");
      event.features.forEach(function (feature) {
        console.log(" modifystart feature each");
        feature.set(
          "modifyGeometry",
          { geometry: feature.getGeometry().clone() },
          true
        );
      });
    });

    const _this = eval('this');

    this.modify.on("modifyend", function (e) {
      console.log("modifyend");
      e.features.forEach(function (feature) {
        const modifyGeometry = feature.get("modifyGeometry");
        if (modifyGeometry) {
          feature.setGeometry(modifyGeometry.geometry);
          feature.unset("modifyGeometry", true);
        }
      });

      _sel_feature = e.features;
      if (
        IsDevice_In_RoomDrawn(
          _sel_feature,
          _objMapviewFloorComponent,
          _addoutdoorimageheight,
          0,arr_layer
        ) == false
      ) {
        _this.rotateIsInside = 0;
      }
      if (
        IsCheckRoomDrawn(
          _sel_feature.getArray()[0],
          _objMapviewFloorComponent,
          1
        ) == true
      ) {
        for (let indx = 0; indx < e.features.getArray().length; indx++) {
          const vertices = e.features.getArray()[indx].getGeometry()
            .flatCoordinates.length;
          if (vertices != 2) {
            _sel_polygon = e.features.getArray()[indx];
            Checkpolygon_Intersect_Campus(
              _objMapviewFloorComponent.map,
              _objMapviewFloorComponent.dialog,
              _sel_feature,
              _sel_polygon,
              _objMapviewFloorComponent.selectout_CampusId,
              _addoutdoorimageheight
            );
          }
        }
      } else {
        ClearStorageeData();
      }
    });

    this.map.getInteractions().extend([this.select, this.modify]);
    console.log("rotate end");
  }

  mouseoverrightsidemenu(rightmenu) {
    const width = window.innerWidth;
    const outdoormouseovertext = document.getElementById(
      "outdoormouseovertext"
    );
    if (outdoormouseovertext != null) {
      if (width > 770) {
        if (rightmenu == enumTech_Outdoor_rightmenu.Move)
          outdoormouseovertext.innerHTML = appsettings.Tech_Move_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Create_Space)
          outdoormouseovertext.innerHTML =
            appsettings.Tech_Create_Space_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Edit_Space)
          outdoormouseovertext.innerHTML = appsettings.Tech_Edit_Space_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Add_Device)
          outdoormouseovertext.innerHTML = appsettings.Tech_Add_Device_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Delete_Space)
          outdoormouseovertext.innerHTML =
            appsettings.Tech_Delete_Space_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Rectangle_Space)
          outdoormouseovertext.innerHTML =
            appsettings.Tech_Rectangle_Space_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Ellipse_Space)
          outdoormouseovertext.innerHTML =
            appsettings.Tech_Ellipse_Space_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.FreeHand_Space)
          outdoormouseovertext.innerHTML =
            appsettings.Tech_FreeHand_Space_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Edit_Point)
          outdoormouseovertext.innerHTML = appsettings.Tech_Edit_Point_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Rotate_Space)
          outdoormouseovertext.innerHTML =
            appsettings.Tech_Rotate_Space_Outdoor;
        else if (rightmenu == enumTech_Outdoor_rightmenu.Reflect_Space)
          outdoormouseovertext.innerHTML =
            appsettings.Tech_Reflect_Space_Outdoor;
      } else {
        outdoormouseovertext.innerHTML = " ";
      }
    }
  }

  mouseleaverightsidemenu(value) {
    console.log(value);
    const outdoormouseovertext = document.getElementById(
      "outdoormouseovertext"
    );
    if (outdoormouseovertext != null) outdoormouseovertext.innerHTML = " ";
  }
  
  loadSelectMapDetails()
  {
    this.selectCampusFull();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
