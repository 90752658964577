import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import {
  report_leftmenu,
  alert_deviceType,
  NewDeviceTypeDesc,
  enumAlertSubType,
  enumAlertType,
  enumAlertStatus,
} from "../../../app-settings";
import { SessionCheckwithStaff } from "../../../app-general";
import { DownloadAlertReportComponent } from "../download-alert-report/download-alert-report.component";
import { ReportService } from "../report.service";
import { GetAlertReport } from "../report";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AlertSharedService } from "../../../_services/index";
import { SharedCheckIsAlertReportData } from "../../../api";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { ReportHeaderComponent } from "../report-header/report-header.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-alertreport",
  templateUrl: "./alertreport.component.html",
  styleUrls: ["./alertreport.component.scss"],
})
export class AlertreportComponent implements AfterViewInit {
  ReportType = report_leftmenu.alertReport;
  private _onDestroy: Subject<void> = new Subject<void>();
  public EnumAlertType = alert_deviceType;
  public EnumNewDeviceType = NewDeviceTypeDesc;
  public EnumAlertSubType = enumAlertSubType;
  public EnumDeviceAlertType = enumAlertType;
  public EnumAlertStatus = enumAlertStatus;
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private reportService: ReportService,    
    public dialog: MatDialog,
    private router: Router,
    private alertSharedService: AlertSharedService,
    public mainLayoutService: MainLayoutService,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.setupFullTable();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupTableformidwidth();
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }

  displayedAlertReportColumns: string[] = [
    "AlarmName",
    "LastName",
    "TagId",
    "RoomName",
    "AlertTime",
    "NotifiedTime",
    "AlertLevel",
    "AckedTime",
    "AckedMethod",
    "AckStatus",
    "AckedBy",
    "PresenceOn",
    "ResponseTimeSort",
  ];
  IsRefreshNeed = 1;
  leftbarclickevent = 0;
  IsLoaded = 0;
  IsFilter = 0;
  IsFilterData = "";
  alertdataSource;
  alertreporteddata = [];
  alertloading = true;
  AlertReport;
  downloadalertdata;
  AlertDetails;
  Action;
  dataSourcecount;
  authkey;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild("Alertsort") Alertsort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;

  setupTableforXtrasmallerwidth() {
    this.displayedAlertReportColumns = ["AlarmName", "LastName", "TagId"];
  }

  setupTableforsmallerwidth() {
    this.displayedAlertReportColumns = [
      "AlarmName",
      "LastName",
      "TagId",
      "RoomName",
    ];
  }

  setupTableformidwidth() {
    this.displayedAlertReportColumns = [
      "AlarmName",
      "LastName",
      "TagId",
      "RoomName",
      "AlertTime",
      "NotifiedTime",
    ];
  }

  setupFullTable() {
    this.displayedAlertReportColumns = [
      "AlarmName",
      "LastName",
      "TagId",
      "RoomName",
      "AlertTime",
      "NotifiedTime",
      "AlertLevel",
      "AckedTime",
      "AckedMethod",
      "AckStatus",
      "AckedBy",
      "PresenceOn",
      "ResponseTimeSort",
    ];
  }

  ngAfterViewInit() {
    if (this.IsRefreshNeed == 1) {
      let ChkIsAlertReportChanged = true;

      this.alertSharedService
        .currentSharedCheckIsAlertReport()
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
          (SharedCheckIsAlertReportDetails: SharedCheckIsAlertReportData) => {
            const CheckIsAlertReportDataChanged =
              SharedCheckIsAlertReportDetails;
            if (CheckIsAlertReportDataChanged != undefined) {
              if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 1 &&
                ChkIsAlertReportChanged
              ) {
                ChkIsAlertReportChanged = false;
                if (this.IsFilter == 0) {
                  this.getAlertReport("");
                } else {
                  this.getAlertReport(this.IsFilterData);
                }

                CheckIsAlertReportDataChanged.IsAlertReportChanged = 0;
                this.alertSharedService.changeSharedCheckIsAlertReport(
                  CheckIsAlertReportDataChanged
                );
              } else if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 0
              ) {
                ChkIsAlertReportChanged = true;
              }
            }
          }
        );
    }
    this.getAlertReport("");
  }

  getAlertReport(filterValue): void {
    try {
      this.alertloading = true;
      let Response_Time;
      let Days;
      let Hours, Minutes, Seconds;
      let dateFormat = "";
      if (
        filterValue != undefined &&
        filterValue != null &&
        filterValue != ""
      ) {
        this.searchInput.nativeElement.value = filterValue;
      } else {
        this.searchInput.nativeElement.value = "";
      }

      this.authkey = localStorage.getItem("Authkey") ?? "";
      dateFormat = localStorage.getItem("dateFormat") ?? "";
      const alertitem: GetAlertReport = {
        authKey: this.authkey,
        dateFormat: dateFormat,
      };
      let First_name = [];
    let Last_name = [];
      this.reportService
        .getAlertReport(alertitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Report: any[]) => {
          Report.forEach(function (e) {
            if (e.ResponseTime == "") e["ResponseTimeSort"] = "";
            else {
              Response_Time = e.ResponseTime.split(":");
              if (Response_Time.length == 4) {
                e["ResponseTime"] =
                  Response_Time[0] +
                  " " +
                  Response_Time[1] +
                  ":" +
                  Response_Time[2] +
                  ":" +
                  Response_Time[3];
                Days = Response_Time[0].split("D");
                Days = Days[0] * 24 * 60 * 60;
                Hours = Response_Time[1] * 60 * 60;
                Minutes = Response_Time[2] * 60;
                Seconds = Days + Hours + Minutes + parseInt(Response_Time[3]);
              } else {
                Hours = Response_Time[0] * 60 * 60;
                Minutes = Response_Time[1] * 60;
                Seconds = Hours + Minutes + parseInt(Response_Time[2]);
              }
              e["ResponseTimeSort"] = Seconds;
            }

            if (e.FirstName.includes(",")) {
              First_name = e.FirstName;
              Last_name = e.LastName;
              First_name = e.FirstName.toString().split(",");
              Last_name = e.LastName.toString().split(",");

              e["Name"] = 0;
              for (let i = 0; i < First_name.length; i++) {
                e["Name"] += First_name[i] + " " + Last_name[i] + ",";
           
              }

              e["Name"] = e["Name"].substring(1);
              e["Name"] = e["Name"].slice(0, -1);
            } else {
              e["Name"] = e.FirstName + " " + e.LastName;
            }
          });

          this.alertloading = false;
          this.AlertReport = Report;
          this.alertdataSource = new MatTableDataSource(this.AlertReport);
          this.downloadalertdata = new MatTableDataSource(this.AlertReport);
          this.alertdataSource.data = this.AlertReport;
          this.downloadalertdata.data = this.AlertReport;
          setTimeout(() => {
            this.alertreporteddata = [];
            this.alertdataSource.sort = this.Alertsort;
            this.sortingDatalist_AlertReport(this.alertdataSource);
            this.downloadalertdata.sort = this.Alertsort;
            this.sortingDatalist_AlertReport(this.downloadalertdata);
            this.downloadalertdata
              .connect()
              .subscribe((data) => (this.alertreporteddata = data));
          }, 0);
          this.alertdataSource.paginator = this.paginator.toArray()[0];
          this.dataSourcecount = this.alertdataSource.data.length;
          this.getClearTable(this.alertdataSource.data);

          if (
            filterValue != undefined &&
            filterValue != null &&
            filterValue != ""
          ) {
            // if filter value then we referesh new alert need to retain the filter.
            this.searchReport(this.IsFilterData);
          }
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getAlertReport() - " + err.message
      );
    }
  }

  searchReport(filterValue: string) {
    try {
      if (filterValue != null) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTablealertalertdataSource defaults to lowercase matches
        this.alertdataSource.filter = filterValue;
        this.IsFilter = 1;
        this.IsFilterData = filterValue;
        this.searchalertfilter();
        this.alertreporteddata = this.alertdataSource.filteredData;
        this.dataSourcecount = this.alertreporteddata.length;
      }
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - searchReport() - " + err.message
      );
    }
  }

  searchalertfilter() {
    let search_AckedName;
    let search_Description;
    let search_RoomInfo;
    let search_ResidentName;
    this.alertdataSource.filterPredicate = function (
      data,
      filter: string
    ): boolean {
      if (data.AckedFirstName != null) {
        search_AckedName = data.AckedFirstName + " " + data.AckedLastName;
        search_AckedName = search_AckedName
          .toLowerCase()
          .includes(filter.toLowerCase());
      } else search_AckedName = "";

      if (data.Alert != null) {
        search_Description = data.AlarmName + " (" + data.Alert + ")";
        search_Description = search_Description
          .toLowerCase()
          .includes(filter.toLowerCase());
      } else search_Description = "";

      if (data.LastName != null) {
        search_ResidentName = data.FirstName + " " + data.LastName;
        search_ResidentName = search_ResidentName
          .toLowerCase()
          .includes(filter.toLowerCase());
      } else search_ResidentName = "";

      if (data.RoomInfo != null) {
        search_RoomInfo = data.RoomInfo.toLowerCase().includes(
          filter.toLowerCase()
        );
      } else search_RoomInfo = "";

      return (
        data.AlarmName.toLowerCase().includes(filter) ||
        data.Alert.toLowerCase().includes(filter) ||
        search_Description ||
        search_ResidentName ||
        data.Name.toLowerCase().includes(filter) ||
        data.NewDeviceTypeName.toLowerCase().includes(filter) ||
        search_RoomInfo ||
        data.AlertTime.toLowerCase().includes(filter) ||
        data.NotifiedTime.toLowerCase().includes(filter) ||
        data.TagId == filter ||
        data.AckedTime.toLowerCase().includes(filter) ||
        data.AckedMethod.toLowerCase().includes(filter) ||
        data.AckedFirstName.toLowerCase().includes(filter) ||
        data.AckedLastName.toLowerCase().includes(filter) ||
        search_AckedName ||
        data.AckStatus.toLowerCase().includes(filter) ||
        data.AlertLevel.toLowerCase().includes(filter) ||
        data.PresenceOn.toLowerCase().includes(filter) ||
        data.ResponseTime.toLowerCase().includes(filter)
      );
    };
  }

  alertReportdata() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    if (this.alertdataSource.filter != "") {
      this.downloadalertdata.data = this.alertdataSource.filteredData;
      setTimeout(() => {
        this.alertdataSource.sort = this.Alertsort;
        this.sortingDatalist_AlertReport(this.alertdataSource);
        this.alertdataSource
          .connect()
          .subscribe((data) => (this.alertreporteddata = data));
      }, 0);
    }
    const dialogRef = this.dialog.open(DownloadAlertReportComponent, {
      disableClose: true,
      width: "500px",
      height: "430px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: this.alertreporteddata,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  getClearTable(Data) {
    try {
      const type = "";
      for (let i = 0; i < Data.length - 1; i++) {
        if (Data[i].AlarmSubTypeName === null) {
          this.AlertDetails = Data[i].AlarmName + type;
        } else {
          this.AlertDetails = Data[i].AlarmName + Data[i].AlarmSubTypeName;
        }

        if (Data[i].AlertLevel === 0) {
          this.Action = "Alert Closed";
        } else if (
          Data[i].AckedFirstName != null ||
          Data[i].AckedLastName != null
        ) {
          this.Action =
            "Acked By" +
            " " +
            Data[i].AckedFirstName +
            " " +
            Data[i].AckedLastName;
        } else {
          this.Action = "Pending";
        }
      }
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getClearTable() - " + err.message
      );
    }
  }

  sortingDatalist_AlertReport(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (
        sortHeaderId == "TagId" ||
        sortHeaderId == "AckStatus" ||
        sortHeaderId == "AckedBy" ||
        sortHeaderId == "ResponseTimeSort"
      ) {
        return data[sortHeaderId];
      } else {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      }
    };
  }
  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
    if (ReportHeaderComponent.isReportMenuClosed == false) {
      this.reportService.toggleReportMenuNav.emit();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
