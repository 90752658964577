import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { enumAlertType,enumAlertSubType,enumAlertAckStatus } from '../app-settings';
import {
    SharedData, SharedAlertBasedData, SharedBuildBasedData, SharedFloorBasedData, SharedAlertData, SharedFloorAlertData, SharedAlertLoadData,
    SharedCheckAlertData, SharedCheckAlertReportData, SharedCheckIsAlertReportData, SharedCheckIsLocationReportData, ShareInfraopenMsg, ShareRightSideNav, ShareUserdetails, ShareddialogcloseData, staffresidentdata} from '../api';

@Injectable({ providedIn: 'root' })
export class AlertSharedService {
    SharedAlertDetails: SharedAlertData[]=[];
    private SharedAlertSource = new BehaviorSubject(this.SharedAlertDetails);
    ShareInfraDetails: any=[];
    private SharedInfraSource = new BehaviorSubject(this.ShareInfraDetails);

    SharedAlertResidentBasedDetails: SharedAlertData[]=[];
    private SharedAlertResidentBasedSource = new BehaviorSubject(this.SharedAlertResidentBasedDetails);
    SharedAlertBasedDetails: SharedAlertBasedData[]=[];
    private SharedAlertBasedSource = new BehaviorSubject(this.SharedAlertBasedDetails);
    SharedBuildBasedDetails: SharedBuildBasedData[]=[];
    private SharedBuildBasedSource = new BehaviorSubject(this.SharedBuildBasedDetails);
    SharedFloorBasedDetails: SharedFloorBasedData[]=[];
    private SharedFloorBasedSource = new BehaviorSubject(this.SharedFloorBasedDetails);
    ShareInfraopenDetails={"IsfraOpen":0};
    _ShareInfraopenDetails={"IsfraOpen":0};
    private SharedInfraAlertSource = new BehaviorSubject(this.ShareInfraopenDetails);


    SharedDetails={} as SharedData;
    private SharedSource = new BehaviorSubject(this.SharedDetails);
    SharedResidentStaffBasedDetails: staffresidentdata[]=[];
    private SharedResidentStaffBasedSource = new BehaviorSubject(this.SharedResidentStaffBasedDetails);
    SharedRoomBasedDetails: any[]=[];
    private SharedRoomBasedSource = new BehaviorSubject(this.SharedRoomBasedDetails);
    SharedEventDetails: any[]=[];
    private SharedEventSource = new BehaviorSubject(this.SharedEventDetails);
    SharedFloorAlertDetails={} as  SharedFloorAlertData;
    private SharedFloorAlertSource = new BehaviorSubject(this.SharedFloorAlertDetails);
    SharedAlertLoadDetails={} as  SharedAlertLoadData;
    private SharedAlertLoadsource = new BehaviorSubject(this.SharedAlertLoadDetails);
    SharedCheckAlertDetails={} as  SharedCheckAlertData;
    private SharedCheckAlertSource = new BehaviorSubject(this.SharedCheckAlertDetails);
    SharedCheckAlertReportDetails={} as  SharedCheckAlertReportData;
    private SharedCheckAlertReportSource = new BehaviorSubject(this.SharedCheckAlertReportDetails);
    SharedCheckIsAlertReportDetails={} as  SharedCheckIsAlertReportData;
    private SharedCheckIsAlertReportSource = new BehaviorSubject(this.SharedCheckIsAlertReportDetails);
    SharedCheckIsLocationReportDetails={} as  SharedCheckIsLocationReportData;
    private SharedCheckIsLocationReportSource = new BehaviorSubject(this.SharedCheckIsLocationReportDetails);
    ShareMapDetails=[];
    private SharedMapDetailsSource = new BehaviorSubject(this.ShareMapDetails);
    ShareMapVectorLayer=[];
    private SharedMapVectorLayerSource = new BehaviorSubject(this.ShareMapVectorLayer);
    ShareMapVectorFloorLayer=[];
    private SharedMapVectorFloorLayerSource = new BehaviorSubject(this.ShareMapVectorFloorLayer);
    ShareSideNavDetails: ShareRightSideNav={ "IscheckSideNav": 0};
    private SharedSideNavSource = new BehaviorSubject(this.ShareSideNavDetails);
    ShareUserdetails={ "IssetUserInfo": 0} as ShareUserdetails ;
    private SharedUserNavSource = new BehaviorSubject(this.ShareUserdetails);
    ShareddialogcloseDetails={} as  ShareddialogcloseData;
    private Shareddialogclosesource = new BehaviorSubject(this.ShareddialogcloseDetails);
    SharedResidentStaffDetails=[];
    private SharedResidentStaffsource = new BehaviorSubject(this.SharedResidentStaffDetails);
    SharedLocationDetails: any=[];
    private SharedLocationSource = new BehaviorSubject(this.SharedLocationDetails);

    SharedStaffDetails: any=[];
    private SharedStaffdatasource = new BehaviorSubject(this.SharedStaffDetails);

    SharedInternetConnection: any=[];
    private SharedInternetConnectiondatasource = new BehaviorSubject(this.SharedInternetConnection);
    
    SharedHomeMapDetails: any=[];
    private SharedHomeMapdetailSource = new BehaviorSubject(this.SharedHomeMapDetails);
    
    //SharedAlertDetails
    changeSharedAlert(Chk_SharedAlertDetails: SharedAlertData[]) {       
        if (Chk_SharedAlertDetails != undefined && Chk_SharedAlertDetails.length > 0) {
            const Chk_SharedAlertDetailsAfter = JSON.parse(JSON.stringify(Chk_SharedAlertDetails));
            let groupedByAlertId;
            let groupedByBuildId;
            let groupedByFloorId;

            if (Chk_SharedAlertDetails != undefined  && Chk_SharedAlertDetails.length > 0) {
                groupedByAlertId = this.groupBy(Chk_SharedAlertDetailsAfter, 'AlertId');
                groupedByBuildId = this.groupBy(Chk_SharedAlertDetailsAfter, 'BuildId');
                groupedByFloorId = this.groupBy(Chk_SharedAlertDetailsAfter, 'FloorId');

                this.changeSharedAlertBased(groupedByAlertId);
                this.changeSharedBuildBased(groupedByBuildId);
                this.changeSharedFloorBased(groupedByFloorId);
            }

            this.changeSharedAlertResidentBased(this.getsearchAlertData(Chk_SharedAlertDetailsAfter));
            let infracount=0;
            let alertcount=0;
            
            Chk_SharedAlertDetailsAfter.filter(function (e1) {
                if(e1.AckStatus != enumAlertAckStatus.Cancel && e1.AckStatus != enumAlertAckStatus.Complete && e1.isSingleAlert=="1") //cancel Or Complete alert not counted on infracount
                {
                    
                    if (e1.AlertType == enumAlertType.Infrastructure || e1.AlertType == enumAlertType.systemAlarm  || e1.SubTypeId == enumAlertSubType.Environmental || e1.SubTypeId == enumAlertSubType.Lost_Communication) {                  
                        infracount++;
                      }
                      else
                      {
                        alertcount++;
                      }
                }               
            });
            
            const Chk_ShareInfraDetails={"infracount":infracount,"alertcount":alertcount};           
            this.changeSharedInfraBased(Chk_ShareInfraDetails);           
            this.SharedAlertSource.next(Chk_SharedAlertDetails);
        }
        
    }

    currentSharedAlert(): Observable<any> {
        return this.SharedAlertSource.asObservable();
    }

    currentSharedInfraBased(): Observable<any> {
        return this.SharedInfraSource.asObservable();
    }

    changeSharedInfraBased(Chk_ShareInfraDetails:any) {
        this.SharedInfraSource.next(Chk_ShareInfraDetails);
    }

    changeSharedAlertResidentBased(Chk_SharedAlertResidentBasedDetails: SharedAlertData[]) {
        this.SharedAlertResidentBasedSource.next(Chk_SharedAlertResidentBasedDetails);
    }

    currentSharedAlertResidentBased(): Observable<any> {
        return this.SharedAlertResidentBasedSource.asObservable();
    }

    changeSharedAlertBased(Chk_SharedAlertBasedDetails: SharedAlertBasedData[]) {
        this.SharedAlertBasedSource.next(Chk_SharedAlertBasedDetails);
    }

    currentSharedAlertBased(): Observable<any> {
        return this.SharedAlertBasedSource.asObservable();
    }

    changeSharedBuildBased(Chk_SharedBuildBasedDetails: SharedBuildBasedData[]) {
        this.SharedBuildBasedSource.next(Chk_SharedBuildBasedDetails);
    }

    currentSharedBuildBased(): Observable<any> {
        return this.SharedBuildBasedSource.asObservable();
    }

    changeSharedFloorBased(Chk_SharedFloorBasedDetails: SharedFloorBasedData[]) {
        this.SharedFloorBasedSource.next(Chk_SharedFloorBasedDetails);
    }
    currentSharedFloorBased(): Observable<any> {
        return this.SharedFloorBasedSource.asObservable();
    }

    changedInfraAlert(Chk_ShareInfraopenDetails: ShareInfraopenMsg) {
        this._ShareInfraopenDetails = Chk_ShareInfraopenDetails;
        this.SharedInfraAlertSource.next(Chk_ShareInfraopenDetails);
    }
    currentsharedInfraAlert(): Observable<any> {
        return this.SharedInfraAlertSource.asObservable();
    }

    groupBy(xs, key) {
        try {                      
                return xs.reduce(function (rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;

                }, {});          
        }
        catch (err) {
            //console.log('Alertshared.service.ts' + ' - groupBy() - ' + err.message);
        }
    }

    getsearchAlertData(alertinfo): any {
        try {
            let arrTagIds = [] as any[];
            let alertsearchdata = [] as any[];
            const FilterName = [] as any[];
            if (alertinfo != undefined && alertinfo.length > 0) {
                //alert("count")
                arrTagIds = [];
                alertsearchdata = [];
                alertinfo.forEach(function (e) {
                    if (!arrTagIds.includes(e.TagId) && e.RoomName != "Non Mapped Room" && e.ResidentId != 0 && e.AlertType != enumAlertType.Pull_Cord && e.AlertType != enumAlertType.Infrastructure && e.Alertresstaff == 1) {
                        arrTagIds.push(e.TagId)
                        alertsearchdata.push(e)
                    }
                });
            }

            if (alertsearchdata != undefined && alertsearchdata.length > 0) {
                alertsearchdata.forEach(function (e) {
                    if (e.RoomName != "Non Mapped Room" && e.ResidentId != 0 && e.AlertType != enumAlertType.Pull_Cord) {
                        const ResidentfirstName = e.FirstName;
                        let ResidentName = e.ResidentName;
                        if (ResidentfirstName != "")
                            ResidentName = ResidentfirstName + " " + ResidentName;
                        const fdata = { ResidentName: ResidentName, AlertId: e.AlertId, CampusId: e.CampusId, BuildId: e.BuildId, FloorId: e.FloorId, ResidentId: e.ResidentId }
                        FilterName.push(fdata);
                    }
                })
            }            
            return FilterName;
        }
        catch (err) {
            console.log("Throw"+'Alertshared.service.ts - getsearchAlertData() - ' + err.message);
        }
    }
    
    changeShared(SharedDetails: SharedData) {
        this.SharedSource.next(SharedDetails);
    }

    currentShared(): Observable<SharedData> {
        return this.SharedSource.asObservable();
    }
    
    changeSharedResidentStaffBased(SharedResidentStaffBasedDetails: staffresidentdata[]) {
        this.SharedResidentStaffBasedSource.next(SharedResidentStaffBasedDetails);
    }

    currentSharedResidentStaffBased(): Observable<any> {
        return this.SharedResidentStaffBasedSource.asObservable();
    }

    changeSharedRoomDetailsBased(SharedRoomBasedDetails: any[]) {
        this.SharedRoomBasedSource.next(SharedRoomBasedDetails);
    }

    currentSharedRoomDetailsBased(): Observable<any> {
        return this.SharedRoomBasedSource.asObservable();
    }
    
    changeSharedEvent(SharedEventDetails: any[]) {
        this.SharedEventSource.next(SharedEventDetails);
    }

    currentSharedEvent(): Observable<any> {
        return this.SharedEventSource.asObservable();
    }
    
    public changesharedFloorAlert(SharedFloorAlertDetails: SharedFloorAlertData) {
        this.SharedFloorAlertSource.next(SharedFloorAlertDetails);
    }

    currentsharedFloorAlert(): Observable<SharedFloorAlertData> {
        return this.SharedFloorAlertSource.asObservable();
    }
    
    changecanceledAlert(SharedAlertLoadDetails: SharedAlertLoadData) {
        this.SharedAlertLoadsource.next(SharedAlertLoadDetails);
    }

    currentsharedAlertload(): Observable<any> {
        return this.SharedAlertLoadsource.asObservable();
    }
    
    changeSharedCheckAlert(SharedCheckAlertDetails: SharedCheckAlertData) {
        this.SharedCheckAlertSource.next(SharedCheckAlertDetails);
    }

    currentSharedCheckAlert(): Observable<any> {
        return this.SharedCheckAlertSource.asObservable();
    }

    changeSharedCheckAlertReport(SharedCheckAlertReportDetails: SharedCheckAlertReportData) {
        this.SharedCheckAlertReportSource.next(SharedCheckAlertReportDetails);
    }

    currentSharedCheckAlertReport(): Observable<any> {
        return this.SharedCheckAlertReportSource.asObservable();
    }

    changeSharedCheckIsAlertReport(SharedCheckIsAlertReportDetails: SharedCheckIsAlertReportData) {
        this.SharedCheckIsAlertReportSource.next(SharedCheckIsAlertReportDetails);
    }

    currentSharedCheckIsAlertReport(): Observable<any> {
        return this.SharedCheckIsAlertReportSource.asObservable();
    }

    changeSharedCheckIsLocationReport(SharedCheckIsLocationReportDetails: SharedCheckIsLocationReportData) {
        this.SharedCheckIsLocationReportSource.next(SharedCheckIsLocationReportDetails);
    }

    currentSharedCheckIsLocationReport(): Observable<any> {
        return this.SharedCheckIsLocationReportSource.asObservable();
    }
    
    changedMapDetails(ShareMapDetails) {
        this.SharedMapDetailsSource.next(ShareMapDetails);
    }

    currentsharedMapDetails(): Observable<any> {
        return this.SharedMapDetailsSource.asObservable();
    }
    
    changedMapVectorLayer(ShareMapVectorLayer) {
        this.SharedMapVectorLayerSource.next(ShareMapVectorLayer);
    }

    currentsharedMapVectorLayer(): Observable<any> {
        return this.SharedMapVectorLayerSource.asObservable();
    }
    
    changedMapVectorFloorLayer(ShareMapVectorFloorLayer) {
        this.SharedMapVectorFloorLayerSource.next(ShareMapVectorFloorLayer);
    }

    currentsharedMapVectorFloorLayer(): Observable<any> {
        return this.SharedMapVectorFloorLayerSource.asObservable();
    }
    
    changedSideNav(ShareSideNavDetails: ShareRightSideNav) {
        this.SharedSideNavSource.next(ShareSideNavDetails);
    }

    currentsharedsideNav(): Observable<any> {
        return this.SharedSideNavSource.asObservable();
    }
    
    changedUserInfo(ShareUserdetails: ShareUserdetails) {       
        this.SharedUserNavSource.next(ShareUserdetails);
    }

    currentsharedUserNav(): Observable<any> {
        return this.SharedUserNavSource.asObservable();
    }

    changeclosedialog(ShareddialogcloseDetails: ShareddialogcloseData) {
        this.Shareddialogclosesource.next(ShareddialogcloseDetails);
    }
    //
    currentshareddialogclose(): Observable<any> {
        return this.Shareddialogclosesource.asObservable();
    }
    //
    changeResidentStaff(SharedResidentStaffDetails) {
        this.SharedResidentStaffsource.next(SharedResidentStaffDetails);
    }
    //
    currentsharedResidentStaff(): Observable<any> {
        return this.SharedResidentStaffsource.asObservable();
    }
    //
    changeSharedLocation(SharedLocationDetails: any) {
        this.SharedLocationSource.next(SharedLocationDetails);
    }
    //
    currentSharedLocation(): Observable<any> {
        return this.SharedLocationSource.asObservable();
    }

    changesharedStaffData(SharedStaffDetails: any) {       
        this.SharedStaffdatasource.next(SharedStaffDetails);
    }

    currentsharedStaffData(): Observable<any> {
        return this.SharedStaffdatasource.asObservable();
    }

    changesharedInternetConnection(SharedInternetConnection: any) {       
        this.SharedInternetConnectiondatasource.next(SharedInternetConnection);
    }

    currentsharedInternetConnection(): Observable<any> {
        return this.SharedInternetConnectiondatasource
        .asObservable();
    }
    changeSharedHomeMapdetails(SharedHomeMapDetails: any) {
        this.SharedHomeMapdetailSource.next(SharedHomeMapDetails);
    }
    currentSharedHomeMapdetails(): Observable<any> {
        return this.SharedHomeMapdetailSource.asObservable();
    }
}