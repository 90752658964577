import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild
 } from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatSelect } from "@angular/material/select";
import { Subject } from "rxjs";
import {
  TechnologyForCreationResponse,
  SetConfigBoard,
  GetSpaceDetailsBoardSetup,
  DeleteConfiguration,
  GetAlertConfigBoard,
  GetAlertConfigSpace,
  saveConfigurationSettings,
} from "../../technologyoverlay";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import { getBuilding, getCampus } from "../../../../api";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../../app-general";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../../../../api.service";
import { takeUntil } from "rxjs/operators";
import { AlertSharedService } from "../../../../_services/index";
import { GeneralMessageComponent } from "../../../../general-message/general-message.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {
  appsettings
} from "../../../../app-settings";

@Component({
  selector: "app-edit-config",
  templateUrl: "./edit-config.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-config.component.scss"],
})
export class EditConfigComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 4;
  firstcolumns = 2;
  downIcon = 0;

  minheight = "230px";
  location;
  deleteConfigId;
  flag = 0;
  private _onDestroy: Subject<void> = new Subject<void>();
  editConfigrowHeight = "79px";
  screenWidth;
  bAddEdit=false;
  bDelete=false;
  isundo = 0;
  undooperation = true;
  undopane = 0;
  Campusdetails;
  Buildingdetails;
  Spacedetails;
  selectedCampusId = "";
  selectedCampusName;
  //selectedBuildingId = 0;
  buildingId = "";
  selectedBuildingName;
  dataSource;
  dataSourcecnt;
  boardSetupData = [] as any[];
  configuredSetupData = [] as any[];
  displayedColumns: string[] = [
    "Point",
    "Device",
    "PointName",
    "SpaceName",
    "Index",
  ];
  //displayedColumns: string[]= ["SpaceName","Index"];
  public configeditForm: UntypedFormGroup;
  selectedSpaceId;
  selectedBoardName = "";
  existingData = [];
  boardlength = 0;
  boardId;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  @ViewChild("selBuilding") selBuilding: MatSelect;

  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    private alertSharedService: AlertSharedService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.selectedBoardName = data.BoardName;
      this.buildingId = data.BuildingId;
      this.boardlength = data.setupDataRowCount;
      this.selectedBuildingName = data.Building;
      this.selectedCampusName = data.Campus;
      this.boardId = data.BoardId;

      if (data.CampusId == 0) this.selectedCampusId = "";
      else this.selectedCampusId = data.CampusId;
      this.breakpoint$.subscribe(() => this.breakpointChanged());       
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" + " - constructor() - " + err.message
      );
    }
       
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    }    
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }
  }
  
  

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.configeditForm = new UntypedFormGroup({
        filename: new UntypedFormControl(""),
        boardname: new UntypedFormControl(""),
        ipaddress: new UntypedFormControl(""),
        udpport: new UntypedFormControl(""),
        campus: new UntypedFormControl("", [Validators.required]),
        building: new UntypedFormControl("", [Validators.required]),
        description: new UntypedFormControl("", [
          Validators.required,
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        hdnFileName: new UntypedFormControl(""),
        hdnBoardName: new UntypedFormControl(""),
        hdnIpAddress: new UntypedFormControl(""),
        hdnUdpPort: new UntypedFormControl(""),
      });

      for (let i = 1; i <= this.boardlength; i++) {
        this.configeditForm.controls["mat_select_" + i] =
          new UntypedFormControl("");
      }

      const configFileName = this.configeditForm.get("filename");
      const configboardname = this.configeditForm.get("boardname");
      const configipaddress = this.configeditForm.get("ipaddress");
      const configudpport = this.configeditForm.get("udpport");
      
      if(configFileName != null)
        configFileName.disable();
      if(configboardname != null)
        configboardname.disable();
      if(configipaddress != null)  
        configipaddress.disable();
      if(configudpport != null)
        configudpport.disable();
      this.getCampusDetails();
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.configeditForm.controls).forEach((field) => {
        // {1}
        const control = this.configeditForm.get(field); // {2}
        const formEditConfigId = document.getElementById(event.target.id);
        if (formEditConfigId != null) {
          if (field == formEditConfigId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  getCampusDetails() {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" + " - getCampusDetails() - " + err.message
      );
    }
  }

  getSpaceDetailsWithBoardSetupData(selCampusId, selBuildId) {
    try {
      let campus_Id = selCampusId;
      let building_Id = selBuildId;
      if (campus_Id != "") campus_Id = parseInt(campus_Id);
      else campus_Id = 0;

      if (building_Id != "") building_Id = parseInt(building_Id);
      else building_Id = 0;
      const getSpaceInfo: GetSpaceDetailsBoardSetup = {
        authKey: this.authkey,
        campusId: campus_Id,
        buildId: building_Id,
        boardName: this.selectedBoardName,
      };
      this.technologyoverlayService
        .getSpaceDetailsWithBoardSetupData(getSpaceInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SpaceDetails: any[]) => {
          this.boardSetupData = SpaceDetails;
          this.dataSource = new MatTableDataSource(SpaceDetails);
          this.dataSource.data = SpaceDetails;
          this.dataSourcecnt = SpaceDetails.length;

          SpaceDetails.forEach((element) => {
            const matselectForm = this.configeditForm.get("mat_select_" + element.Index);
            if(matselectForm != null)
              matselectForm.setValue(element.SpaceName);
          });
        });
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" +
          " - getSpaceDetailsWithBoardSetupData() - " +
          err.message
      );
    }
  }

  callgetSpacedetails(buildId) {
    this.getSpaceDetailsWithBoardSetupData(
      this.selectedCampusId,
      this.buildingId
    );
    const obj = this.Buildingdetails.find((o) => o.BuildId === buildId);
    if (obj) {
      this.buildingId = obj.BuildId;
      this.selectedBuildingName = obj.BuildingName;
    }
  }

  changecampus(campusid, campusName) {
    this.selectedCampusId = campusid;
    this.selectedCampusName = campusName;
    this.buildingId = "";
  }

  changebuilding(buildingid, buildingName) {
    this.buildingId = buildingid;
    this.selectedBuildingName = buildingName;
  }

  setboardsetupdetails(spaceId, setupData) {
    try {
      const filteredRow = this.boardSetupData.filter(
        (x) => x.Index == setupData["Index"]
      );

      const checkAlertBySpaceId: GetAlertConfigSpace = {
        authKey: this.authkey,
        boardId: parseInt(this.boardId),
        spaceId: filteredRow[0].SpaceName.toString(),
      };

      this.technologyoverlayService
        .getAlertForConfiguredSpace(checkAlertBySpaceId)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "yes") {
            generateAlertMessage(
              this.dialog,
              "Selected Space has open alerts, please complete or cancel those alerts and try again."
            );
            const matselectForm = this.configeditForm.get("mat_select_" + setupData["Index"]);
            if(matselectForm != null)
              matselectForm.setValue(filteredRow[0].SpaceName);
            return;
          } else {
            const checkRelay = this.boardSetupData.find(
              (o) => o.SpaceName === spaceId
            );
            if (checkRelay) {
              if (checkRelay.Point == setupData["Point"]) {
                const item = this.boardSetupData.find(
                  (x) => x.Index == setupData["Index"]
                );
                if (item) {
                  item.SpaceName = spaceId;
                }
              } else {
                const spaceRecords = this.boardSetupData.filter(
                  (x) => x.Index == setupData["Index"]
                );

                generateAlertMessage(
                  this.dialog,
                  "Selected Space already assigned for point " +
                    checkRelay.Point
                );
                if (spaceRecords.length > 0) {
                  const matselectForm = this.configeditForm.get("mat_select_" + setupData["Index"]);
                  if(matselectForm != null)
                    matselectForm.setValue(spaceRecords[0].SpaceName);
                } else {
                  const matselectForm = this.configeditForm.get("mat_select_" + setupData["Index"]);
                  if(matselectForm != null)
                    matselectForm.setValue(0);
                }
                return;
              }
            } else {
              const pointList = [] as any[];
              const setupPointList = this.boardSetupData.filter(
                (o) => o.SpaceName != "" && o.Point == setupData["Point"]
              );
              if (setupPointList.length > 0) {
                for (let i = 0; i < setupPointList.length; i++) {
                  if (
                    setupPointList[i].Spaces.some(
                      (item) => item.SpaceId === setupPointList[i].SpaceName
                    )
                  ) {
                    pointList.push(setupPointList[i]);
                  }
                }
                //alert(pointList);
                if (pointList.length == 0) {
                  const item = this.boardSetupData.find(
                    (x) => x.Index == setupData["Index"]
                  );
                  if (item) {
                    item.SpaceName = spaceId;
                  }
                } else {
                  if (pointList.length == 1) {
                    if (pointList[0].Index == setupData["Index"]) {
                      const item = this.boardSetupData.find(
                        (x) => x.Index == setupData["Index"]
                      );
                      if (item) {
                        item.SpaceName = spaceId;
                      }
                    } else {
                      if (pointList[0].SpaceName != spaceId) {
                        const spaceRecords = this.boardSetupData.filter(
                          (x) => x.Index == setupData["Index"]
                        );
                        if (spaceRecords.length > 0) {
                          if (spaceRecords[0].SpaceName > 0) {
                            generateAlertMessage(
                              this.dialog,
                              "Not able to select the different space for same relay"
                            );
                            const matselectForm = this.configeditForm.get("mat_select_" + setupData["Index"]);
                            if(matselectForm != null)
                              matselectForm.setValue(spaceRecords[0].SpaceName);
                            return;
                          } else {
                            generateAlertMessage(
                              this.dialog,
                              "Not able to select the different space for same relay"
                            );
                            const matselectForm = this.configeditForm.get("mat_select_" + setupData["Index"]);
                            if(matselectForm != null)                              
                              matselectForm.setValue(0);
                            return;
                          }
                        }
                      } else {
                        const item = this.boardSetupData.find(
                          (x) => x.Index == setupData["Index"]
                        );
                        if (item) {
                          item.SpaceName = spaceId;
                        }
                      }
                    }
                  } else {
                    if (pointList[0].SpaceName != spaceId) {
                      const spaceRecords = this.boardSetupData.filter(
                        (x) => x.Index == setupData["Index"]
                      );
                      if (spaceRecords.length > 0) {
                        if (spaceRecords[0].SpaceName > 0) {
                          generateAlertMessage(
                            this.dialog,
                            "Not able to select the different space for same relay"
                          );
                          const matselectForm = this.configeditForm.get("mat_select_" + setupData["Index"]);
                          if(matselectForm != null)
                            matselectForm.setValue(spaceRecords[0].SpaceName);
                          return;
                        } else {
                          generateAlertMessage(
                            this.dialog,
                            "Not able to select the different space for same relay"
                          );
                          const matselectForm = this.configeditForm.get("mat_select_" + setupData["Index"]);
                          if(matselectForm != null)
                            matselectForm.setValue(0);
                          return;
                        }
                      }
                    } else {
                      const item = this.boardSetupData.find(
                        (x) => x.Index == setupData["Index"]
                      );
                      if (item) {
                        item.SpaceName = spaceId;
                      }
                    }
                  }
                }
              } else {
                const item = this.boardSetupData.find(
                  (x) => x.Index == setupData["Index"]
                );
                if (item) {
                  item.SpaceName = spaceId;
                }
              }
            }
          }
        });
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" +
          " - setboardsetupdetails() - " +
          err.message
      );
    }
  }

  public onCloseCancel = () => {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      this.thisDialogRef.close("Cancel");
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" + " - onCloseCancel() - " + err.message
      );
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    try {
      return this.configeditForm.controls[controlName].hasError(errorName);
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" + " - hasError() - " + err.message
      );
    }
  };

  public oneditConfigdetails = (ConfigFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      if (this.configeditForm.valid) {
        this.saveConfigurationSettings(ConfigFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" + " - oneditConfigdetails() - " + err.message
      );
    }
  };

  private saveConfigurationSettings = (ConfigFormValue) => {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.configeditForm.valid) {
        const saveclick = document.getElementById("btnsaveclick");
        if (saveclick != null)
          saveclick.className =
            "clsconfigeditbutton mat-raised-button disablebtn";
        const SaveConfig: saveConfigurationSettings = {
          authKey: this.authkey,
          fileName: ConfigFormValue.hdnFileName.toString(),
          boardName: ConfigFormValue.hdnBoardName.toString(),
          ipAddress: ConfigFormValue.hdnIpAddress.toString(),
          udpPort: ConfigFormValue.hdnUdpPort.toString(),
          campusId: parseInt(this.selectedCampusId),
          campusName: this.selectedCampusName.toString(),
          buildingId: parseInt(this.buildingId),
          buildingName: this.selectedBuildingName.toString(),
          description: ConfigFormValue.description.toString(),
        };
        this.technologyoverlayService
          .saveConfigurationSettings(SaveConfig)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((configSettingsId: any[]) => {
            const _arrSaveBoardSetupDetails = [] as any[];

            for (let i = 0; i < this.boardSetupData.length; i++) {
              const _SaveBoardSetupDetails = {};
              _SaveBoardSetupDetails["Point"] = this.boardSetupData[i].Point;
              _SaveBoardSetupDetails["Device"] = this.boardSetupData[i].Device;
              _SaveBoardSetupDetails["PointName"] =
                this.boardSetupData[i].PointName;
              _SaveBoardSetupDetails["SpaceName"] =
                this.boardSetupData[i].SpaceName;

              _arrSaveBoardSetupDetails.push(_SaveBoardSetupDetails);
            }

            const SaveboardSettings: SetConfigBoard = {
              authKey: this.authkey,
              jsonData: JSON.stringify(_arrSaveBoardSetupDetails),
              configurationSettingsId: configSettingsId,
            };

            this.technologyoverlayService
              .saveConfigurationBoard(SaveboardSettings)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse: any[]) => {
                console.log(apiresponse);
                if (apiresponse.toString() == "true") {
                  generateAlertMessage(
                    this.dialog,
                    "Board Configured Successfully."
                  );
                  this.thisDialogRef.close();
                }
              });
          });
      }
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" +
          " - saveConfigurationSettings() - " +
          err.message
      );
    }
  };

  opendeleteconfigdialog() {
    try {
      const GeneralMessageData = {
        General_header: "Delete Board",
        GeneralMessage: "Are you sure you would like to delete this Board?",
        General_first: "Yes",
        General_second: "No",
      };

      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.deleteConfigurationSettings(this.selectedBoardName);
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" + " - opendeletedialog() - " + err.message
      );
    }
  }

  selectbuilding(CampusId) {
    try {
      let check_BuildId;
      const select_Buildid = this.buildingId;
      if (CampusId > 0) {
        const buildingid: getBuilding = {
          authKey: this.authkey,
          campusId: CampusId,
        };

        this.apiService
          .getBuildingDetails(buildingid)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((BuildingDetails: any[]) => {
            this.Buildingdetails = BuildingDetails["apiReturn"];
            if (this.Buildingdetails.length == 0) {
              this.buildingId = "";
            }
            this.Buildingdetails.forEach(function (data) {
              if (data.BuildId == select_Buildid) {
                check_BuildId = data.BuildId;
                return false;
              } else {
                return true;
              }
            });
            this.buildingId = check_BuildId;
            if (this.buildingId == undefined) this.buildingId = "";
            const obj = this.Campusdetails.find((o) => o.CampusId === CampusId);
            if (obj) {
              this.selectedCampusId = obj.CampusId;
              this.selectedCampusName = obj.CampusName;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" + " - selectbuilding() - " + err.message
      );
    }
  }

  deleteConfigurationSettings(boardName) {
    try {
      const checkAlertByBoardId: GetAlertConfigBoard = {
        authKey: this.authkey,
        boardId: this.boardId,
      };
      this.technologyoverlayService
        .getAlertForConfiguredBoard(checkAlertByBoardId)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "yes") {
            generateAlertMessage(
              this.dialog,
              "Board has open alerts, please complete or cancel those alerts and try again."
            );
          } else {
            const deleteConfigByBoardName: DeleteConfiguration = {
              authKey: this.authkey,
              boardName: boardName,
            };

            this.technologyoverlayService
              .deleteConfigurationSettings(deleteConfigByBoardName)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
                if (apiresponse.toString() == "true") {
                  generateAlertMessage(
                    this.dialog,
                    "Board Configuration deleted successfully."
                  );
                } else if (apiresponse.toString() == "false") {
                  generateAlertMessage(
                    this.dialog,
                    "Board Configuration deleted failed."
                  );
                }
                this.thisDialogRef.close();
              });
          }
        });
    } catch (err) {
      throw new Error(
        "edit-config.component.ts" +
          " - deleteConfigurationSettings() - " +
          err.message
      );
    }
  }
  
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
