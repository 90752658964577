<perfect-scrollbar style="max-width: 1050px; max-height: 700px;">
  <div class="clsaddFloor">
    <div class="addFloor-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <h3 mat-dialog-title class="addFloorHeader" style="margin: 0 0 -34px 7px;">Zone Information</h3>
      <div class="clsSpnfloortext">Previously known as Floor, Zone represents a group of Spaces within a Building, <br>
        such as a “neighborhood”, “wing”, “unit” or “hall”.</div>
      <span><img class="clscloseimagefloor" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span>
      <mat-divider class="clsdividerFloor"></mat-divider>
    </div>

    <form style="padding:24px;" [formGroup]="newzoneaddForm" autocomplete="off" novalidate
      (ngSubmit)="newAddZone(newzoneaddForm.value)" [style.height]="minheight">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill>

        <div fxFlex="55" fxFlex.md="40" class="clsfloormaindiv">

          <mat-grid-list [cols]="firstcolumns" [rowHeight]="floorrowHeight">

            <mat-grid-tile class="clsfloormatgrid">
              <label>Campus</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsfloormatgridtxt">
              <div class="text-inside">

                <mat-form-field appearance="outline" floatLabel="auto" class="clsflooraddtxt">
                  <mat-select formControlName="campus" placeholder="Select Campus" [(ngModel)]="selectedCampusId">
                    <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId"
                      (click)="changecampus_addFloor(Campus.CampusId,Campus.CampusName);">
                      {{Campus.CampusName}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Please select Campus</mat-error>
                  <input type="hidden" formControlName="campusID" [(ngModel)]="data.CampusId">
                  <input type="hidden" formControlName="buildID" [(ngModel)]="data.BuildId">
                </mat-form-field>

              </div>
            </mat-grid-tile>

            <mat-grid-tile class="clsfloormatgrid">
              <label>Building</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsfloormatgridtxt">
              <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsflooraddtxt">
                  <mat-select formControlName="building" placeholder="Select Building" [(ngModel)]="selectedBuildingId">
                    <mat-option *ngFor="let Building of Buildingdetails" [value]="Building.BuildId"
                      (click)="changebuilding_addFloor(Building.BuildId,Building.BuildingName);">
                      {{Building.BuildingName}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Please select Building</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            
            <mat-grid-tile class="clsfloormatgrid">
              <label>Floor</label>
          </mat-grid-tile>
          <mat-grid-tile class="clsfloormatgridtxt">                      
            <div class="text-inside" id="divaddFloorDropdown"> 
              <mat-form-field appearance="outline" floatLabel="auto" class="clsflooraddtxt">                              
                <mat-select formControlName="floorname" placeholder="Select Floor" [(ngModel)]="selectedFloorId" disableOptionCentering>                    
                  
                <mat-optgroup *ngFor="let group of floorGroups" [label]="group.campus" [disabled]="group.disabled">
                <mat-option [value]="group.building" disabled="true">{{group.building}}</mat-option>
                <mat-option *ngFor="let floors of group.floors" [value]="floors.value" style="margin-left:1em;">{{floors.viewValue}}</mat-option>
                </mat-optgroup>
                  
                </mat-select> 
                <mat-error>Please select Floor</mat-error>                                                   
            </mat-form-field>
          </div>
          <div class="text-inside" id="divaddFloortxt">
            <mat-form-field appearance="outline" floatLabel="auto" class="clsflooraddtxt">
              <input matInput placeholder="Floor Name" maxlength="32" formControlName="name" (keyup)="handleInput($event)">
              <mat-error *ngIf="newzoneaddForm.controls.name.hasError('required')">
                  Please enter Floor Name
                </mat-error>
                <mat-error *ngIf="newzoneaddForm.controls.name.hasError('pattern')">
                    Please enter valid Floor Name
                  </mat-error>
                  <mat-error *ngIf="newzoneaddForm.controls.name.hasError('maxlength')">
                    Max length 32 characters only
                  </mat-error>
            </mat-form-field>
          </div>
          
      </mat-grid-tile>
                      
                      
          <mat-grid-tile class="clsfloormatgrid">
            <label>Zone is Floor</label>
        </mat-grid-tile>
        <mat-grid-tile class="clsfloormatgridtxt">                         
          <mat-slide-toggle id="chkZoneFloor" (change)="toggleZoneisFloor($event)" [checked]="checkZoneisFloor"></mat-slide-toggle>
        </mat-grid-tile>

        <mat-grid-tile class="clsfloormatgrid">
          <label>Zone Name</label>
        </mat-grid-tile>
        <mat-grid-tile class="clsfloormatgridtxt">
          <div class="text-inside">
            <mat-form-field appearance="outline" floatLabel="auto" class="clsflooraddtxt">
            <input matInput placeholder="Zone Name" maxlength="32" formControlName="zonename" [disabled]="!disableZoneisFloor" (keyup)="handleInput($event)">
              <!-- <mat-error *ngIf="newzoneaddForm.controls.name.hasError('required')">
                Please enter Zone Name
              </mat-error> -->
              <mat-error *ngIf="newzoneaddForm.controls.name.hasError('pattern')">
                Please enter valid Zone Name
              </mat-error>
              <mat-error *ngIf="newzoneaddForm.controls.name.hasError('maxlength')">
                Max length 32 characters only
              </mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>

        <mat-grid-tile class="clsfloormatgrid">
          <label>Description</label>
        </mat-grid-tile>
        <mat-grid-tile class="clsfloormatgridtxt">
          <div class="text-inside">
            <mat-form-field appearance="outline" floatLabel="auto" class="clsflooraddtxt">
              <input matInput placeholder="Description" maxlength="32" formControlName="description"
                (keyup)="handleInput($event)">
              <mat-error *ngIf="newzoneaddForm.controls.description.hasError('pattern')">
                Please enter valid Description
              </mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>

        <mat-grid-tile class="clsfloormatgrid">
          <label>Notifications</label>
        </mat-grid-tile>
        <mat-grid-tile class="clsfloormatgridtxt">
          <div class="text-inside clsdivfloornotification">
            <div style="padding: 8px 10px;">
              <label>Campus (Default)</label>
              <mat-checkbox id="chkfloorcampus" [checked]="F_chkCampusvalue"
                (change)="togglefloorCamVisibility($event)" style="font-size: 14px;margin-left: .5em;">
              </mat-checkbox>
            </div>
            <div style="margin-left:.5em;padding:8px 0px;">
              <label>Custom</label>
              <mat-checkbox id="chkfloorcustom" [checked]="F_chkCustomvalue"
                (change)="togglefloorCusVisibility($event)" style="font-size: 14px;margin-left: .5em;">
              </mat-checkbox>
            </div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile class="clsfloormatgrid">
          <label>Turn on Check-In for All Residents</label>
        </mat-grid-tile>
        <mat-grid-tile class="clsfloormatgridtxt">
          <div class="text-inside">
            <mat-checkbox id="floorchkcheckin" (change)="toggleAddCheckInVisibility($event)"
              style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
          </div>
        </mat-grid-tile>

      </mat-grid-list>
    </div>
                
    <div fxFlex="27" fxFlex.md="40" class="clsdivaddfloorimage">
      <mat-grid-list cols="1">
        <mat-grid-tile>
          <div>
            <div id="floorcontainer3" class="text-inside">
                <img class="clszoneaddDragplanimg" [src]="newflooraddImgUrl"
                [ngStyle]="{'transform': 'scale('+zoomval+') rotate('+ rotationAmount +'deg) translateX('+left_rightval+'px) translateY('+top_bottomval+'px)'}"
                (click)='imageInput.click()' draggable="false" />
              <div draggable="true" ngClass="{{dragAreaClass}}">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <a href="javascript:void(0)" (click)="imageInput.click()"></a>
                    <input type="file" class="clschoosebutton text-inside-grid" placeholder="Photo" #imageInput
                      onclick="this.value = null" (change)="onChangefloor($event.target.files)"
                      accept=".png, .jpg, .bmp" />
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-left: 30px;"><label>Drag and Drop Map Image or <br> Click to Upload and
                Crop<br>Supported file types: bmp, jpg, png</label></div>
          </div>
        </mat-grid-tile>

      </mat-grid-list>
    </div>

      </div>
      <mat-dialog-actions class="clsfloorbuttoncontrol">
        <button type="button" class="clsFloorcancelbutton" mat-dialog-close mat-raised-button
          (click)="onCloseCancel()">Cancel</button>&nbsp;
        <button id="btnsaveflooraddclick" class="clsFlooraddbutton" mat-raised-button
          [disabled]="!newzoneaddForm.valid || !validimgurl">Save</button>
      </mat-dialog-actions>
    </form>

  </div>

</perfect-scrollbar>