import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import { ApiService } from "../../../../api.service";
import {
  MapSyncProcess,
  UnitById,
  getDeviceById,
  getUnit,
} from "../../../../api";
import { AlertSharedService } from "../../../../_services/index";
import {
  appsettings,
  DeviceTypeDesc,
  enumRequestFrom,
  NewDeviceTypeDesc,
  enumTech_rightmenu_Tool,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  fillcolorPolygon,
  DeviceType,
} from "../../../../app-settings";
import {  
  addHRexflag,
  TechnologyForCreationResponse,
  deleteUnitById,
  getDeviceDetailsReq,
  getPolylineRequest,
  getSiteAuthKeyReuest,
  CampusByID,
  getTemplates,
  ZoneByID,
} from "../../technologyoverlay";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import { DOCUMENT } from "@angular/common";
import OlMap from "ol/Map";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
  AuthFailedMessage,
  map_widthandheight,
  getCheckInExtent,
  LoadTechnologyFloorSvg,
  convertToSvgEcllipseXCoordinate,
  convertToSvgEcllipseYCoordinate,
  maponclicked_Tech,
  Checkpolygon_Intersect,
  get_selectedfeature,
  getNewFeature,
  mouseOnFeature,
  calculateCenter,
  IsCheckRoomDrawn,
  IsDevice_In_RoomDrawn,
  ClearStorageSpaceData,
  getSelected,
} from "../../../../app-general";
import {
  map_creation,
  fillcolor_style,
  
  get_geojsonObject_floor,
  fillcolor_styleSelect,
  getDeviceTypeNew,
} from "../../../../map-general";
import {
  Circle as CircleStyle,
  Icon,
  Style,
  Fill,
  Stroke,
  Text,
} from "ol/style";


import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmMessageComponent } from "../../../../confirm-message/confirm-message.component";
import { AddDeviceComponent } from "../../device/add-device/add-device.component";
import { UntypedFormGroup } from "@angular/forms";
import { AddUnitComponent } from "../unit/add-unit/add-unit.component";
import { EditDeviceComponent } from "../../device/edit-device/edit-device.component";
import { InsertTemplateComponent } from "../mapview-floor/insert-template/insert-template.component";
import { DuplicateUnitComponent } from "../unit/duplicate-unit/duplicate-unit.component";
import { TemplateUnitComponent } from "../unit/template-unit/template-unit.component";
import { TemplateZoneComponent } from '../zone/template-zone/template-zone.component';
import { EditZoneComponent } from '../zone/edit-zone/edit-zone.component';
import { EditUnitComponent } from "../unit/edit-unit/edit-unit.component";
import { GeneralMessageComponent } from "./../../../../general-message/general-message.component";
import { MultiPoint, Point } from "ol/geom";

import Draw, { createBox, createRegularPolygon } from "ol/interaction/Draw";

import {
  Modify,
  Select,
  Translate
} from "ol/interaction";
import {
  never,
  platformModifierKeyOnly,
  primaryAction,
  shiftKeyOnly,
  singleClick,
} from "ol/events/condition";
import "ol-popup/src/ol-popup.css";
import OlVectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";
import { style } from "@angular/animations";

@Component({
  selector: "app-mapview-floor",
  templateUrl: "./mapview-floor.component.html",
  styleUrls: ["./mapview-floor.component.scss"],
})
export class MapviewFloorComponent implements OnInit {
  bAddEdit = false;
  bDelete = false;
  bAddEditTemplate = false;
  imageExtent;
  checkroomdrawend = false;

  buildingcampusname = "";
  buildingcampusfullname = "";
  floorbuildingname = "";
  floorbuildingfullname = "";
  unitfloorname = "";
  unitfloorfullname = "";
  roomunitname = "";
  roomunitfullname = "";

  selectedcampus = 0;
  selectedbuilding = 0;
  selectedfloor = 0;
  selectedunit = 0;

  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  dialogResult = "";
  displayedColumnsRoom: string[] = [
    "DeviceType",
    "DeviceId",
    "LforIr",
    "MacId",
    "DeviceName",
  ];
  dataSourceRoom;

  campusImgUrl: string = appsettings.CAMPUS_IMAGE_FOLDER;
  floorImgUrl: string = appsettings.FLOOR_IMAGE_FOLDER;

  watcher: Subscription;
  columns = 4;

  buildflag = 0;
  editflag = 0;
  formCampusId;
  addcampusid;
  addbuildingid;
  addfloorid;
  svgimageurl = 0;

  FacilityImgurl: string = appsettings.Facility_IMAGE_FOLDER;
  FacilityName;
  campuseditdialogopen = 0;
  buildeditdialogopen = 0;
  flooreditdialogopen = 0;
  uniteditdialogopen = 0;
  IsDuplicateAdded = 0;
  MapSyncProcess;
  editdialogfacilityopen = 0;
  campusimagename;
  campusaddImgUrl;
  validcampusimgurl;
  CampusCnt = 0;
  BuildingCnt = 0;
  FloorCnt = 0;
  UnitCnt = 0;
  Importflag = 0;
  importHeaders;
  getselectedmapdetails;

  imgSrc;
  clseditimg;

  getfloordata;
  MCampusId;
  MBuildingId;
  MFloorId;
  CampusFulldetails: any[];
  selectedCampus;
  selectedBuilding;
  selectedFloor;

  CampusImageURL = "";
  BuildingImageURL = "";
  FloorImageURL = "";
  SvgImageUrl = "";
  campusName;
  FloorInfo;
  buildDuplicatedialogopen = 0;
  ImageWidth = "";
  ImageHeight = "";
  addfloorimageheight = 0;
  ImageUrl = "";
  BackgroundIMG = "Centrak";
  Check_ZoomLevel;
  Check_MapCenter;
  CZoomLevel;
  CRotateLevel;
  TranslateX;
  TranslateY;
  SvgInfo = null;
  map = new OlMap();
  Floor_Name;
  Building_Name;
  Campus_Name;
  UnitByIddetails;
  buttontext;
  checkIsSpace = false;
  CheckIsAdd = false;
  checkIsEdit = false;
  checkIsEditOpen = false;
  adddeviceflag = 0;
  rotateIsInside = 1;

  collapsedIcon1 = "keyboard_arrow_down";
  collapsedIcon = "keyboard_arrow_down";

  collapsedIcon1_build = "keyboard_arrow_up";
  collapsedIcon_build = "keyboard_arrow_up";

  collapsedIcon1_floor = "keyboard_arrow_up";
  collapsedIcon_floor = "keyboard_arrow_up";

  collapsedIcon1_unit = "keyboard_arrow_up";
  collapsedIcon_unit = "keyboard_arrow_up";

  checkicon;
  preId = -1;
  arrowiconT = true;
  arrowiconF = false;

  build_arrowiconT = true;
  build_arrowiconF = false;

  floor_arrowiconT = true;
  floor_arrowiconF = false;

  unit_arrowiconT = true;
  unit_arrowiconF = false;

  camp_isbuttonshow = 0;
  build_isbuttonshow = 0;
  floor_isbuttonshow = 0;

  selectedBuildingdetails = {};
  selectedFloordetails = {};

  Campus_key = "Campusinfo";
  Building_key = "Buildinginfo";
  Floor_key = "Floorinfo";
  chkbuildmapflag = 0;
  devicefiltervalue = "";
  devicefiltervalue_mapdetail;
  Map_array_length = 3;
  device_list_all = "3";

  selected_floorId = 0;
  selected_buildId = 0;
  selected_campusId = 0;
  getMap_flag = 0;

  editimageheight;
  editimagewidth;
  Map_image_path = "assets/images/";
  filterdevicedetails;

  Device_Info;
  Total_DeviceInfo;
  Selected_DeviceTypename;
  Selected_ImageDeviceTypename;
  SelDeviceInfo;

  Edit_FloorById;
  authkey;
  _divCreateSpaceMenu;
  _divEditSpaceMenu;

  //Draw feature for Room
  vector = new OlVectorLayer();
  source = new VectorSource({ wrapX: false });
  vectorLayer = new OlVectorLayer({
    source: this.source,
  });

  draw = new Draw({
    source: this.source,
    type: "Polygon",
  });
  getDeviceList_info;
  checksortflag = 0;
  adddevicedialogopen = 0;
  Device_Details;
  selectedsortType = 0;
  Tech_Devicelist = enumTech_rightmenu_Tool.DeviceList;
  Tech_Move = enumTech_rightmenu_Tool.Move;
  Tech_Create_Space = enumTech_rightmenu_Tool.Create_Space;
  Tech_Edit_Space = enumTech_rightmenu_Tool.Edit_Space;
  Tech_Add_Device = enumTech_rightmenu_Tool.Add_Device;
  Tech_Duplicate_Space = enumTech_rightmenu_Tool.Duplicate_Space;
  Tech_Insert_Template = enumTech_rightmenu_Tool.Insert_Template;
  Tech_Delete_Space = enumTech_rightmenu_Tool.Delete_Space;

  Tech_Rectangle_Space = enumTech_rightmenu_Tool.Rectangle_Space;
  Ellipse_Space = enumTech_rightmenu_Tool.Ellipse_Space;
  FreeHand_Space = enumTech_rightmenu_Tool.FreeHand_Space;
  Edit_Point = enumTech_rightmenu_Tool.Edit_Point;
  Rotate_Space = enumTech_rightmenu_Tool.Rotate_Space;
  Reflect_Space = enumTech_rightmenu_Tool.Reflect_Space;

  private _onDestroy: Subject<void> = new Subject<void>();
  public _NewDeviceTypeDesc = NewDeviceTypeDesc;

  Edit_pointsbool = true;
  insertTemplatebool = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    @Inject(DOCUMENT) private document: any,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<MapviewFloorComponent>,
    private technologyoverlayService: TechnologyoverlayService,
    private dialogRef: MatDialog
  ) {
    const getRouterNavigate = this.router.getCurrentNavigation();
    if (getRouterNavigate != null) {
      if (getRouterNavigate.extras.state != undefined)
        localStorage.setItem(
          "MapviewFloorComponent",
          JSON.stringify(getRouterNavigate.extras.state)
        );
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
  }

  DeviceSorting: any[] = [
    { Name: "Complete", Value: "1" },
    { Name: "Incomplete", Value: "2" },
    { Name: "Space ascending", Value: "3" },
    { Name: "Space descending", Value: "4" },
  ];

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    const mapviewFloorComponent =
      localStorage.getItem("MapviewFloorComponent") ?? "";
    this.dialogRef.closeAll();
    if (mapviewFloorComponent != null)
      this.getfloordata = JSON.parse(mapviewFloorComponent);

    localStorage.setItem("CreateNew", "0");
    if (this.bAddEdit == true) {
      const saveBtn = document.getElementById("btnsaveclickmapview");
      if (saveBtn != null) {
        saveBtn.className = "clsdeviceaddbutton mat-raised-button";
        saveBtn.innerHTML = "Save";
        this.buttontext = "Save";
      }
    }
    this.Campus_Name = this.getfloordata.CampusName;
    this.Building_Name = this.getfloordata.BuildingName;
    this.Floor_Name = this.getfloordata.FloorName;
    const spnfloorName = document.getElementById("spnfloorName");
    const hdFloorName = document.getElementById("hdFloorName");
    if (spnfloorName != null)
      spnfloorName.innerHTML = this.getfloordata.FloorName;
    if (hdFloorName != null)
      hdFloorName.innerHTML = this.getfloordata.FloorName;

    this.addcampusid = this.getfloordata.CampusId;
    this.addbuildingid = this.getfloordata.BuildId;
    this.addfloorid = this.getfloordata.FloorId;
    this.getDeviceDetails();
    this.selectCampusFull();
    // this.getHRexdetails();
    localStorage.setItem("Spaceedit", "0");
    localStorage.setItem("SpaceId", "0");
    localStorage.setItem("SpaceName", "");
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    } else {
      this.DisableBtn();
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Templates_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEditTemplate = true;
    }
    this.getgatewaymacid = localStorage.getItem("applianceSN") ?? "";

    if (this.getgatewaymacid.indexOf("_") == -1) {
      this.getgatewaymacid = this.getgatewaymacid
        .replace(/(.{2})/g, "$1_")
        .slice(0, -1);
    }
  }

  // getHRexdetails() {
  //   try {
  //     const hrexdetails: getSiteAuthKeyReuest = {
  //       authKey: this.authkey,
  //     };
  //     this.technologyoverlayService
  //       .getHRexdetails(hrexdetails)
  //       .pipe(takeUntil(this._onDestroy))
  //       .subscribe((HRexDetails: any[]) => {
  //         if (HRexDetails != null && HRexDetails != undefined) {
  //           // this.getHRexMacId =HRexDetails[0].MacId;
  //           this.getHRexMacId = HRexDetails;
  //         }
  //       });
  //   } catch (err) {
  //     throw new Error(
  //       "mapview-floor.component.ts" + " - getHRexdetails() - " + err.message
  //     );
  //   }
  // }

  getHrexflag() {
    if (this.addfloorid != 0) {
      const hrexItem: getSiteAuthKeyReuest = {
        authKey: this.authkey,
      };
      this.technologyoverlayService
        .checkHREXfound(hrexItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((addHRexflag: any[]) => {
          this.addHRexflag = addHRexflag;
        });
    }
  }

  getMaxRexdevicedetail() {
    const rexItem: getSiteAuthKeyReuest = {
      authKey: this.authkey,
    };
    this.technologyoverlayService
      .getRexdevicedetails(rexItem)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((maxrexvalue: any[]) => {
        this.MaxRexvalue = maxrexvalue[0].DeviceId;
      });
  }

  selectCampusFull() {
    try {
      const campusid: CampusByID = {
        authKey: this.authkey,
        campusId: parseInt(this.addcampusid),
      };

      let item;

      this.apiService
        .getCampusbyCampusId(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusFulldetails: any[]) => {
          // need to latest campus from sysc need to show the global alert start
          let CheckAlertData = {
            IsAlertChanged: 0,
            IsAlertClicked: 0,
          };
          this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
          CheckAlertData = {
            IsAlertChanged: 1,
            IsAlertClicked: 0,
          };
          this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
          // need to latest campus from sysc need to show the global alert end

          this.CampusFulldetails = CampusFulldetails;

          const _SharedHomeMapDetails = {
            sel_CampusId: this.addcampusid,
            sel_BuildingId: this.addbuildingid,
            sel_FloorId: this.addfloorid,
          };

          this.alertSharedService.changeSharedHomeMapdetails(
            _SharedHomeMapDetails
          );

          const mapdetail =
            this.addcampusid + "_" + this.addbuildingid + "_" + this.addfloorid;

          this.CampusFulldetails["SelectedMapInfo"] = mapdetail;
          item = {
            SiteId: this.addcampusid,
            MapDetails: this.CampusFulldetails,
          };
          this.getMapDetails(item);
        });
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts - selectCampusFull() - " + err.message
      );
    }
  }

  getMapDetails(MapDetails): void {
    let arrMapDetails = "";
    let BulidingId;
    let FloorId;
    let CampusId;
    if (MapDetails.MapDetails.SelectedMapInfo != "0_0_0") {
      arrMapDetails = MapDetails.MapDetails.SelectedMapInfo.split("_");
      this.CampusFulldetails = MapDetails.MapDetails;

      this.CampusFulldetails = MapDetails.MapDetails;
      CampusId = arrMapDetails[0];
      BulidingId = arrMapDetails[1];
      FloorId = parseInt(arrMapDetails[2]);

      for (
        let info = 0;
        info <= this.CampusFulldetails["Campusinfo"].length - 1;
        info++
      ) {
        if (
          this.CampusFulldetails["Campusinfo"][info]["CampusId"] == CampusId
        ) {
          for (
            let build = 0;
            build <=
            this.CampusFulldetails["Campusinfo"][info]["Campusdetail"].length -
              1;
            build++
          ) {
            if (
              this.CampusFulldetails["Campusinfo"][info].Campusdetail[build]
                .buildingid == BulidingId
            ) {
              for (
                let floor = 0;
                floor <=
                this.CampusFulldetails["Campusinfo"][info].Campusdetail[build]
                  .floors.length -
                  1;
                floor++
              ) {
                if (
                  this.CampusFulldetails["Campusinfo"][info].Campusdetail[build]
                    .floors[floor].floorid == FloorId
                ) {
                  this.FloorImageURL =
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].FloorPlanImage;
                  this.ImageWidth =
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].ImageWidth;
                  this.ImageHeight =
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].ImageHeight;
                  this.CZoomLevel =
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].FZoomLevel;
                  this.CRotateLevel =
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].FRotateLevel;
                  this.TranslateX =
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].FTranslateX;
                  this.TranslateY =
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].FTranslateY;
                  this.ImageUrl =
                    appsettings.FLOOR_IMAGE_FOLDER + "/" + this.FloorImageURL;
                  this.SvgInfo = null;
                  this.FloorInfo = null;
                  this.getDeviceDetails();
                  // this.getHrexflag();
                  this.DrawMapCall();
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    }
  }

  DrawMapCall() {
    try {
      //Map Empty
      const element = this.document.getElementById("map");
      let IsZoomChanged = 0;
      if (
        element.innerHTML != "" &&
        (this.Check_ZoomLevel || this.Check_ZoomLevel == 0)
      ) {
        this.CZoomLevel = this.Check_ZoomLevel;
        IsZoomChanged = 1;
      } else {
        this.Check_MapCenter = [];
      }
      element.innerHTML = "";
      const leftmenu = document.getElementById("leftmenu1");
      if (leftmenu != null) leftmenu.classList.add("active");
      ///Map width and height
      const imgwh = map_widthandheight(this.ImageWidth, this.ImageHeight);
      const imgwidth = imgwh.ImageWidth;
      const imgheight = imgwh.ImageHeight;
      this.addfloorimageheight = imgwh.ImageHeight;

      // Polygons style      
      const devicelabelStyle = new Style({
        text: new Text({
          font: "10px Poppins, sans-serif",
          overflow: true,
          fill: new Fill({
            color: "#000",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 5,
          }),
          offsetX: 0,
          offsetY: 25.5,
        }),
      });

      const deviceStyle = fillcolor_style(fillcolorPolygon.Completed);

      const fillStyle = [deviceStyle, devicelabelStyle];
      this.map = map_creation(
        this.ImageUrl,
        imgwidth,
        imgheight,
        true,
        this.BackgroundIMG,
        this.CZoomLevel,
        this.CRotateLevel,
        element.offsetWidth,
        element.offsetHeight,
        this.TranslateX,
        this.TranslateY,
        "map",
        this.Check_MapCenter,
        IsZoomChanged
      );

      let translateX = this.TranslateX;
      let translateY = this.TranslateY;

      translateX = translateX / 122;
      translateY = translateY / 127;

      translateX = translateX * imgwidth;
      translateY = translateY * imgheight;

      const _map = this.map;
      const MapCenter = _map.getView().getCenter();

      MapCenter[0] -= translateX;
      MapCenter[1] += translateY;

      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        maponclicked_Tech(
          this.map,
          this.select,
          this.addfloorid,
          this.technologyoverlayService,
          this._onDestroy,
          this.edit_DeviceDetails,
          this.dialog,
          this,
          this.editunitopendialog
        );
      }

      const _this = eval('this');
      let currZoom = _this.CZoomLevel;
      _map.on("moveend", function (e) {
        if (IsZoomChanged == 0)
          getCheckInExtent(e, _map, imgwidth, imgheight, MapCenter);
        const newZoom = _map.getView().getZoom();
        if (currZoom != newZoom) {
          currZoom = newZoom;
          _this.CZoomLevel = newZoom;
          _this.Check_ZoomLevel = newZoom;
          _this.Check_MapCenter = _map.getView().getCenter();
        }
      });

      _map.on("pointermove", function () {
        _this.Check_MapCenter = _map.getView().getCenter();
      });

      //////////Floor Devices Start////////////

      const arrdevicemarker = [] as any[];
      const arrdevicemarkernoPoly = [] as any[];
      if (this.FloorInfo != null) {
        /// Geo Feature Collection ///
        const geoFloorjsonObject = get_geojsonObject_floor(
          enumRequestFrom.floorplan_page
        );
        geoFloorjsonObject.type = "FeatureCollection";

        
        geoFloorjsonObject.features.length = 0;

        /// marker array ///
        
        if (this.FloorInfo.length > 0) {
          const arrFloorInfo = this.FloorInfo;
          LoadTechnologyFloorSvg(
            this.map,
            arrFloorInfo,
            imgheight,
            imgwidth,
            arrdevicemarker,
            geoFloorjsonObject,
            arrdevicemarkernoPoly,
            devicelabelStyle,
            fillStyle,
            this.Map_image_path,
            this.addfloorid,
            1
          );
          this.editimageheight = imgheight;
          this.editimagewidth = imgwidth;
        } else if (this.FloorImageURL == null) {
          const _map = document.getElementById("map");
          if (_map != null)
            _map.innerHTML =
              "<span style='margin-top: 15%; padding-left: 45%;display:block;font-size:15px;font-weight:bold;'> Map Not Found.</span>";
        }

        if (this.IsDuplicateAdded == -1) {
          _this.select_move(this.IsDuplicateAdded);
        }
        else if (this.IsDuplicateAdded != 0) {
          // const _this = eval('this');

          const _UnitId = this.IsDuplicateAdded.toString();
          const _addfloorid = this.addfloorid;
          const arr_layer = [] as any[];
          this.map.getLayers().forEach(function (Floorlayer) {
            if (
              Floorlayer.get("FloorId") != undefined &&
              Floorlayer.get("FloorId") == "Floor_" + _addfloorid
            ) {
              arr_layer[arr_layer.length] = Floorlayer;
            }
          });
          const arrduplicate_feature = [] as any[];
          for (let indx = 0; indx < arr_layer.length; indx++) {
            const features_device = arr_layer[indx].getSource().getFeatures();
            if (features_device) {
              // Go through this array and get coordinates of their geometry.
              features_device.forEach(function (feature) {
                if (
                  parseInt(feature.getProperties().UnitId) ==
                    parseInt(_UnitId) &&
                  feature.getProperties().geometry.flatCoordinates.length != 2
                ) {
                  arrduplicate_feature[arrduplicate_feature.length] = feature;
                } else if (
                  parseInt(feature.getProperties().UnitId) ==
                    parseInt(_UnitId) &&
                  feature.getProperties().geometry.flatCoordinates.length == 2
                ) {
                  arrduplicate_feature[arrduplicate_feature.length] = feature;
                }
              });
            }
          }

          _this.select_move(arrduplicate_feature);
        }
      }
      //////////Floor Devices End////////////
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts - DrawMapCall() - " + err.message
      );
    }
  }

  getDeviceDetails() {
    try {      
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.MCampusId,
        floorId: parseInt(this.addfloorid),
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: 0,
        isIndoor: 1,
        isNeedRoomonly: 1,
        isNeedIntercom: parseInt(voiceLicense)
      };

      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          if (DeviceDetails != null && DeviceDetails != undefined) {
            if (DeviceDetails["apiReturn"] == "Invalid Auth Key") {
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            this.filterdevicedetails = DeviceDetails["apiReturn"];
            //this.dataSource = new MatTableDataSource(DeviceDetails);
            DeviceDetails["apiReturn"].forEach(function (e) {
              if (e.DeviceName == DeviceTypeDesc.Emitter) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
              } else if (e.DeviceName == DeviceTypeDesc.HRex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
              } else if (e.DeviceName == DeviceTypeDesc.Rex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
              } 
              else if (e.DeviceName == DeviceTypeDesc.LF) {
                if (e.SubType==DeviceType.DripTray)
                 {
                   e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
                 }
                 else
                 {
                e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
                }
              }
              else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
              } else if (
                e.DeviceName == DeviceTypeDesc.DC ||
                e.DeviceName == DeviceTypeDesc.NewDC
              ) {
                e["NewDeviceName"] = NewDeviceTypeDesc.DC;
              } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
              } else if (e.DeviceName == DeviceTypeDesc.VW) {
                e["NewDeviceName"] = NewDeviceTypeDesc.VW;
              } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
              } else if (e.DeviceName == DeviceTypeDesc.UT) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UT;
              } else if (e.DeviceName == DeviceTypeDesc.UM) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UM;
              } else if (e.DeviceName == DeviceTypeDesc.Intercom) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Intercom;
              } else if (e.DeviceName == DeviceTypeDesc.Room) {
                e["NewDeviceName"] = DeviceTypeDesc.Room;
              } else {
                e["NewDeviceName"] = NewDeviceTypeDesc.Zone;
              }
            });
            this.Device_Info = DeviceDetails["apiReturn"];
            const DeviceList_array = [] as any[];
            DeviceDetails["apiReturn"].forEach(function (data) {
              if (data.FilterDeviceType != "Room") {
                const newData = {};
                newData["DeviceId"] = data.DeviceId;
                newData["DeviceTypeId"] = data.DeviceTypeId;
                newData["NewDeviceName"] = data.NewDeviceName;
                newData["UnitLocation"] = data.UnitLocation;
                newData["DataId"] = data.DataId;
                newData["UnitId"] = data.UnitId;
                newData["Description"] = data.Description;
                if (
                  data.DeviceId > 0 ||
                  data.DeviceName == DeviceTypeDesc.Gateway
                )
                  newData["Status"] = "Active";
                else if (data.DeviceId == 0) newData["Status"] = "Inactive";
                DeviceList_array.push(newData);
              }
            });
            this.getDeviceList_info = DeviceList_array;
            this.Total_DeviceInfo = this.getDeviceList_info.length;
            this.Device_Details = DeviceDetails["apiReturn"];
            if (this.selectedsortType != 0) {
              this.selectDeviceSort(this.selectedsortType);
            }

            this.FloorInfo = DeviceDetails["apiReturn"];
            this.DrawMapCall();
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - getDeviceDetails() - " + err.message
      );
    }
  }

  //When we click the device list it will show the list of devices available in the Floor.
  device_list() {
    const elem = document.getElementById("container_CreateSpace");
    const divDeviceList = document.getElementById("divDeviceList");
    const mouseovertext = document.getElementById("mouseovertext");
    if (mouseovertext != null)
      mouseovertext.innerHTML = appsettings.Tech_DeviceList;
    if (elem != null) {
      if (elem.classList.contains("enablebtn")) {
        this.DisableButton("container_DeviceList");
        if (divDeviceList != null) {
          if (divDeviceList.style.display == "none") {
            divDeviceList.style.display = "block";
          } else {
            divDeviceList.style.display = "none";
          }
        }
      } else {
        this.ResetButton();
      }
    }
    this.ClearInteraction();
  }

  //When we click the Add space it will open up the option to draw. We have 3 options to draw the polygon .
  create_space() {
    const elem = document.getElementById("container_DeviceList");
    const mouseovertext = document.getElementById("mouseovertext");
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    if (mouseovertext != null)
      mouseovertext.innerHTML = appsettings.Tech_Create_Space;
    if (elem != null) {
      if (elem.classList.contains("enablebtn")) {
        localStorage.setItem("CreateNew", "0");
        this.checkIsSpace = true;
        this.checkIsEdit = false;
        const saveBtn = document.getElementById("btnsaveclickmapview");
        if (saveBtn != null) saveBtn.innerHTML = "Done";
        this.DisableButton("container_CreateSpace");

        if (this._divCreateSpaceMenu.style.display == "none") {
          this._divCreateSpaceMenu.style.display = "block";
        } else {
          this._divCreateSpaceMenu.style.display = "none";
        }
      } else {
        // document.getElementById("mouseovertext").innerHTML = " ";
        this.ResetButton();
      }
    }
    this.ClearInteraction();
  }

  //Edit space will open the option to edit the space point or we can redraw the space by adding more points.
  edit_space() {
    const elem = document.getElementById("container_DeviceList");
    const mouseovertext = document.getElementById("mouseovertext");
    this._divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
    if (mouseovertext != null)
      mouseovertext.innerHTML = appsettings.Tech_Edit_Space;
    if (elem != null) {
      if (elem.classList.contains("enablebtn")) {
        if (this._divEditSpaceMenu.style.display == "none") {
          this._divEditSpaceMenu.style.display = "block";
        } else {
          this._divEditSpaceMenu.style.display = "none";
        }
        const saveBtn = document.getElementById("btnsaveclickmapview");
        // if (saveBtn.innerHTML == "Done") saveBtn.innerHTML = "Save";
        if (saveBtn != null) saveBtn.innerHTML = "Done";
        this.DisableButton("container_EditSpace");
      } else {
        this.ResetButton();
      }
    }
    this.ClearInteraction();
  }

  //Click and drag to draw your Rectangle space, then move it on the map.
  draw_rectangle() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    if (this._divCreateSpaceMenu != null)
      this._divCreateSpaceMenu.style.display = "none";
    const geometryFunction = createBox();
    this.DrawFeature("Circle", geometryFunction);
  }

  draw_ellipse() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    if (this._divCreateSpaceMenu != null)
      this._divCreateSpaceMenu.style.display = "none";
    const geometryFunction = createRegularPolygon(20);
    this.DrawFeature("Circle", geometryFunction);
  }

  //Click and drag to draw your Free handed line drawing, then move it on the map.
  draw_freehand() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    if (this._divCreateSpaceMenu != null)
      this._divCreateSpaceMenu.style.display = "none";
    this.DrawFeature("Polygon", null);
  }

  checkdrawroomflag = 0;
  drawroomselect;

  Buildingsvgid = 0;
  Buildingdetails: any[];

  select = null;
  modify = null;
  translate = null;
  _translateend = 0;
  select_Feature;
  selectName_Feature;
  selectedFeature;
  selectedNameFeature;
  //Draw feature for Devices Polygon
  DrawFeature(DrawFeature_Type, geometryFunction) {
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;

    const fillcolorStyle = fillcolor_styleSelect();
    this.tempVectorSource.clear();
    this.map.removeInteraction(this.draw);
    let drawRoomSource = this.source;
    function onDrawStart(event) {
      //remove everything drawn previously
      drawRoomSource.clear();
      event.feature.setStyle(fillcolorStyle);
    }

    if (geometryFunction != null) {
      this.draw = new Draw({
        source: this.source,
        type: DrawFeature_Type,
        geometryFunction: geometryFunction,
      });
    } else {
      this.draw = new Draw({
        source: this.source,
        type: DrawFeature_Type,
      });
    }
    this.draw.on("drawstart", onDrawStart);

    drawRoomSource = this.source;

    this.vector = new OlVectorLayer({
      source: this.source,
    });

    this.vector.setStyle(fillcolorStyle);
    this.vector.set("extent", this.imageExtent);

    this.map.addInteraction(this.draw);
    this.map.addLayer(this.vector);

    // Get the array of features
    const features = this.vector.getSource().getFeatures();
    if (features != undefined) {
      // Go through this array and get coordinates of th  eir geometry.
      //features.forEach(function (feature) {});
    }

    let checkdraw;
    const _this = eval('this');
    const _addfloorimageheight = this.addfloorimageheight;

    this.draw.on("drawend", function (e) {
      checkdraw = IsCheckRoomDrawn(e.feature, _this, 0);
      if (checkdraw == true) {
        Checkpolygon_Intersect(
          _this.map,
          _this.dialog,
          _this,
          e.feature,
          _this.addfloorid,
          _addfloorimageheight
        );
      }
    });
    this.checkroomdrawend = checkdraw;
  }

  Edit_Points() {
    this.checkIsEdit = true;
    this.checkIsSpace = false;
    this._divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
    localStorage.setItem("Spaceedit", "0");
    localStorage.setItem("CreateNew", "3");
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
    this._divEditSpaceMenu.style.display = "none";

    this.DisableButton("container_EditSpace");

    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        interaction.getFeatures().clear();
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
      if (interaction instanceof Draw) {
        this.map.removeInteraction(interaction);
      }
      if (interaction instanceof Modify) {
        this.map.removeInteraction(interaction);
      }
    });

    if (!SessionCheck(this.router)) {
      return;
    }

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;
    this.tempVectorSource.clear();

    const fillcolorStyle = fillcolor_styleSelect();

    const arr_layer = [] as any[];
    const _addfloorid = this.addfloorid;
    this.map.getLayers().forEach(function (Floorlayer) {
      if (
        Floorlayer.get("FloorId") != undefined &&
        Floorlayer.get("FloorId") == "Floor_" + _addfloorid
      ) {
        arr_layer[arr_layer.length] = Floorlayer;
      }
    });

    this.select = new Select({
      multi: true,
      style: fillcolorStyle,
    });
    this.map.addInteraction(this.select);

    const _select = this.select;
        
    this.selectedFeatures = this.select.getFeatures();

    this.map.on("pointermove", (evt) => {
      if (!evt.dragging) {
        const pixel = this.map.getEventPixel(evt.originalEvent);
        const hit = this.map.forEachFeatureAtPixel(
          pixel,
          function (feature, layer) {
            console.log(feature);
            console.log(layer);
            return true;
          }
        );
        if (hit) {
          let circlehit = false;
          const features = _select.getFeatures();
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = evt.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  circlehit = true;
                }
              }
            }
          });
          if (circlehit) {
            this.map.getTargetElement().style.cursor = this.map.hasFeatureAtPixel(this.map.getEventPixel(evt.originalEvent)) ? 'crosshair' : '';
          } else {
            this.map.getTargetElement().style.cursor = "";
          }
        } else {
          this.map.getTargetElement().style.cursor = "";
        }
        
      }
    });
    const ref = eval('this');
    this.modify = new Modify({
      features: ref.selectedFeatures,
      deleteCondition: function (e) {
        if (shiftKeyOnly(e) && singleClick(e)) {
          const features = ref.selectedFeatures;
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = e.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  coord.splice(k, 1);
                  ref.updateGeometry(feature, coord);
                  if (IsDevice_In_RoomDrawn(ref.selectedFeatures, ref, ref.addfloorimageheight,1,arr_layer) == true)
                    {
                      localStorage.setItem("FloorId",feature.values_.FloorId);
                      localStorage.setItem("DataId", feature.values_.DataId);
                      localStorage.setItem("DeviceName",NewDeviceTypeDesc.Room);
                      localStorage.setItem("MultiDataId", feature.values_.MultiDataId);
                      localStorage.setItem("MultiDeviceId", feature.values_.MultiDeviceId);
                      localStorage.setItem("MultiFilterDeviceTypeId", feature.values_.MultiFilterDeviceTypeId);
                      localStorage.setItem("UnitId", feature.values_.UnitId);
                      localStorage.setItem("IsDevice", "0");
                      localStorage.setItem("DeviceX", "0");
                      localStorage.setItem("DeviceY", "0");       
                      localStorage.setItem("RoomName", feature.values_.UnitName);   
                      
                    }
                    else
                    {
                      localStorage.setItem("UnitId", "0");
                      localStorage.setItem("DataId", "0");
                    }
                  return;
                }
              }
            }
          });
        } else {
          return;
        }
      },     
    });
  
    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        this.map.removeInteraction(interaction);
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
    });

    _select.on("select", function (e) {
      if (e.selected) {
        if (_this.select.getFeatures().getLength() == 1) {
          if (e.selected[0] == undefined) {
            getSelected(
              _this.select.getFeatures().getArray()[0],
              _this,
              arr_layer,
              1
            );
          }         
        } else {
          _this.select
            .getFeatures()
            .remove(
              _this.select.getFeatures().getArray()[
                _this.select.getFeatures().getLength() - 1
              ]
            );
          getSelected(
            _this.select.getFeatures().getArray()[0],
            _this,
            arr_layer,
            1
          );
        }
      }
    });

    const _addfloorimageheight = this.addfloorimageheight;
    let _sel_feature = null;
    let _sel_polygon = null;
    const _objMapviewFloorComponent = eval('this');

    const _this = eval('this');
    this.modify.on(
      "modifyend",
      function (e) {
        _sel_feature = e.features;
        if (
          IsCheckRoomDrawn(_sel_feature.getArray()[0], _this, 1) == true &&
          IsDevice_In_RoomDrawn(_sel_feature, _this, _addfloorimageheight, 1,arr_layer) ==
            true
        ) {
          for (let indx = 0; indx < e.features.getArray().length; indx++) {
            const vertices = e.features.getArray()[indx].getGeometry()
              .flatCoordinates.length;
            if (vertices != 2) {
              _sel_polygon = e.features.getArray()[indx];
              Checkpolygon_Intersect(
                _objMapviewFloorComponent.map,
                _objMapviewFloorComponent.dialog,
                _sel_feature,
                _sel_polygon,
                _addfloorid,
                _addfloorimageheight
              );
            }
          }
        } else {
          ClearStorageSpaceData();
        }
      },
      this.selectedFeatures
    );
    this.map.getInteractions().extend([this.select, this.modify]);
  }

  Rotate_Scale_Points(isScale) {
    if (!SessionCheck(this.router)) {
      return;
    }
    this._divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
    this._divEditSpaceMenu.style.display = "none";
    this.checkIsEdit = true;
    this.checkIsSpace = false;

    localStorage.setItem("CreateNew", "3");
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
    this._divEditSpaceMenu.style.display = "none";

    this.DisableButton("container_EditSpace");

    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        interaction.getFeatures().clear();
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
    });

    if (!SessionCheck(this.router)) {
      return;
    }

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;
    this.tempVectorSource.clear();
    const arr_layer = [] as any[];
    const _addfloorid = this.addfloorid;
    this.map.getLayers().forEach(function (Floorlayer) {
      if (
        Floorlayer.get("FloorId") != undefined &&
        Floorlayer.get("FloorId") == "Floor_" + _addfloorid
      ) {
        arr_layer[arr_layer.length] = Floorlayer;
      }
    });
    const pstyle = new Style({
      geometry: function (feature) {
        const modifyGeometry = feature.get("modifyGeometry");
        return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
      },
      fill: new Fill({
        color: "rgba(122, 199, 234,0.35)",
      }),
      stroke: new Stroke({
        color: "rgba(122, 199, 234,1)",
        width: 4,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: "rgba(122, 199, 234,1)",
          width: 4,
        }),
        fill: new Fill({
          color: "#ffffff",
        }),
      }),
    });
    this.select = new Select({
      multi: true,
      style: function (feature) {
        const styles = [pstyle];
        const modifyGeometry = feature.get("modifyGeometry");
        const geometry = modifyGeometry
          ? modifyGeometry.geometry
          : feature.getGeometry();
        const result = calculateCenter(geometry);
        const center = result.center;
        if (center) {
          styles.push(
            new Style({
              geometry: new Point(center),
              image: new CircleStyle({
                radius: 1,
                fill: new Fill({
                  color: "rgba(247, 53, 53, 0.1)",
                }),
              }),
            })
          );
          const coordinates = result.coordinates;
          if (coordinates) {
            const minRadius = result.minRadius;
            const sqDistances = result.sqDistances;
            const rsq = minRadius * minRadius;
            const points = coordinates.filter(function (coordinate, index) {
              return sqDistances[index] > rsq;
            });
            styles.push(
              new Style({
                geometry: new MultiPoint(points),
                image: new CircleStyle({
                  radius: 5,
                  stroke: new Stroke({
                    color: "rgba(122, 199, 234,1)",
                    width: 4,
                  }),
                  fill: new Fill({
                    color: "#ffffff",
                  }),
                }),
              })
            );
          }
        }
        return styles;
      },
    });

    const _select = this.select;
      
    this.selectedFeatures = this.select.getFeatures();

    this.map.on("pointermove", (evt) => {
      if (!evt.dragging) {
        const pixel = this.map.getEventPixel(evt.originalEvent);
        const hit = this.map.forEachFeatureAtPixel(
          pixel,
          function (feature, layer) {
            console.log(feature);
            console.log(layer);
            return true;
          }
        );
        if (hit) {
          let circlehit = false;
          const features = _select.getFeatures();
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = evt.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  circlehit = true;
                }
              }
            }
          });
          if (circlehit) {
            this.map.getTargetElement().style.cursor = "crosshair";
          } else {
            this.map.getTargetElement().style.cursor = "";
          }
        } else {
          this.map.getTargetElement().style.cursor = "";
        }
        //this.map.getTargetElement().style.cursor = this.map.hasFeatureAtPixel(this.map.getEventPixel(evt.originalEvent)) ? 'crosshair' : '';
      }
    });

    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        this.map.removeInteraction(interaction);
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
    });

    _select.on("select", function (e) {
      if (e.selected) {
        if (_this.select.getFeatures().getLength() == 1) {
          if (e.selected[0] == undefined) {
            getSelected(
              _this.select.getFeatures().getArray()[0],
              _this,
              arr_layer,
              1
            );
          } else {
            getSelected(e.selected[0], _this, arr_layer, 1);
          }
        } else {
          _this.select
            .getFeatures()
            .remove(
              _this.select.getFeatures().getArray()[
                _this.select.getFeatures().getLength() - 1
              ]
            );
          getSelected(
            _this.select.getFeatures().getArray()[0],
            _this,
            arr_layer,
            1
          );
        }
      }
    });

    const _addfloorimageheight = this.addfloorimageheight;
    let _sel_feature = null;
    let _sel_polygon = null;
    const _objMapviewFloorComponent = eval('this');

    const ref = eval('this');
    this.modify = new Modify({
      features: ref.selectedFeatures,
      condition: function (event) {
        return primaryAction(event) && !platformModifierKeyOnly(event);
      },
      deleteCondition: function (e) {
        if (shiftKeyOnly(e) && singleClick(e)) {
          const features = ref.selectedFeatures;
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = e.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  coord.splice(k, 1);
                  ref.updateGeometry(feature, coord);
                  return;
                }
              }
            }
          });
        } else {
          return;
        }
      },
      insertVertexCondition: never,
      style: function (feature) {
        //feature.get('features').forEach(function (modifyFeature) {
        const features = ref.selectedFeatures;
        features.forEach(function (modifyFeature) {
          const modifyGeometry = modifyFeature.get("modifyGeometry");

          if (modifyGeometry) {
            const point = feature.getGeometry().getCoordinates();
            let modifyPoint = modifyGeometry.point;

            if (!modifyPoint) {
              // save the initial geometry and vertex position
              modifyPoint = point;
              modifyGeometry.point = modifyPoint;
              modifyGeometry.geometry0 = modifyGeometry.geometry;
              // get anchor and minimum radius of vertices to be used
              const result = calculateCenter(modifyGeometry.geometry0);
              modifyGeometry.center = result.center;
              modifyGeometry.minRadius = result.minRadius;
            }

            const center = modifyGeometry.center;
            const minRadius = modifyGeometry.minRadius;
            let dx, dy;
            dx = modifyPoint[0] - center[0];
            dy = modifyPoint[1] - center[1];
            const initialRadius = Math.sqrt(dx * dx + dy * dy);
            if (initialRadius > minRadius) {
              const initialAngle = Math.atan2(dy, dx);
              dx = point[0] - center[0];
              dy = point[1] - center[1];
              const currentRadius = Math.sqrt(dx * dx + dy * dy);
              if (currentRadius > 0) {
                const currentAngle = Math.atan2(dy, dx);
                const geometry = modifyGeometry.geometry0.clone();
                if (isScale == 1) {
                  geometry.scale(
                    currentRadius / initialRadius,
                    undefined,
                    center
                  );
                } else {
                  geometry.rotate(currentAngle - initialAngle, center);
                }

                modifyGeometry.geometry = geometry;
              }
            }
          }
        });
        return style(feature);
      },
    });

    this.modify.on("modifystart", function (event) {
      event.features.forEach(function (feature) {
        feature.set(
          "modifyGeometry",
          { geometry: feature.getGeometry().clone() },
          true
        );
      });
    });

    const _this = eval('this');    

    this.modify.on("modifyend", function (e) {
      _sel_feature = e.features;
      e.features.forEach(function (feature) {
        const modifyGeometry = feature.get("modifyGeometry");
        if (modifyGeometry) {
          feature.setGeometry(modifyGeometry.geometry);
          feature.unset("modifyGeometry", true);
        }
      });
      if (
        IsDevice_In_RoomDrawn(_sel_feature, _this, _addfloorimageheight, 0,arr_layer) ==
        false
      ) {
        _this.rotateIsInside = 0;
      }
      if (IsCheckRoomDrawn(_sel_feature.getArray()[0], _this, 1) == true) {
        _sel_feature = e.features;
        for (let indx = 0; indx < e.features.getArray().length; indx++) {
          const vertices = e.features.getArray()[indx].getGeometry()
            .flatCoordinates.length;
          if (vertices != 2) {
            _sel_polygon = e.features.getArray()[indx];
            Checkpolygon_Intersect(
              _objMapviewFloorComponent.map,
              _objMapviewFloorComponent.dialog,
              _sel_feature,
              _sel_polygon,
              _addfloorid,
              _addfloorimageheight
            );
          }
        }
      } else {
        ClearStorageSpaceData();
      }
    });

    this.map.getInteractions().extend([this.select, this.modify]);
  }

  pointsDist(p0, p1) {
    const dx = p0[0] - p1[0];
    const dy = p0[1] - p1[1];
    return Math.sqrt(dx * dx + dy * dy);
  }

  updateGeometry(feature, coord) {
    feature.getGeometry().setCoordinates([coord]);
  }

  //Move Option will help to move the devices from one space to another space

  selectedFeatures;
  select_move(duplicate_feature) {

    try {
        this.IsDuplicateAdded = 0;
        const mouseovertext = document.getElementById("mouseovertext");
        if (mouseovertext != null) mouseovertext.innerHTML = appsettings.Tech_Move;
        const elem = document.getElementById("container_DeviceList");
        this._divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
        if (elem != null && elem.classList.contains("enablebtn") ||
          (duplicate_feature != undefined &&
            duplicate_feature.length > 0 &&
            this._translateend == 0) || duplicate_feature == -1
        ) {
          this.checkIsEdit = true;
          this.checkIsSpace = false;
          localStorage.setItem("CreateNew", "2");
          const arr_Layers = this.map.getLayers();
          this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
          this._divEditSpaceMenu.style.display = "none";

          const saveBtn = document.getElementById("btnsaveclickmapview");
          if (saveBtn != null) {
            if (saveBtn.innerHTML == "Done") saveBtn.innerHTML = "Save";
          }

          this.DisableButton("container_SelectMove");

          this.tempVectorSource.clear();
          this.map.removeLayer(this.tempVectorLayer);

          const arr_Interactions = [] as any[];
          this.map.getInteractions().forEach((interaction) => {
            if (interaction instanceof Translate) {
              arr_Interactions[arr_Interactions.length] = interaction;
           }
           if (interaction instanceof Select) {
             arr_Interactions[arr_Interactions.length] = interaction;
           }
        });
      
          for (let idx_int = 0; idx_int < arr_Interactions.length; idx_int++) {
            this.map.removeInteraction(arr_Interactions[idx_int]);
          }

         if (!SessionCheck(this.router)) {
            return;
          }

          this.checkdrawroomflag = 1;
          this.drawroomselect = 1;
          this.tempVectorSource.clear();

          const fillcolorStyle = fillcolor_styleSelect();        


          const arr_layer = [] as any[];
          const _addfloorid = this.addfloorid;
          this.map.getLayers().forEach(function (Floorlayer) {
            if (
              Floorlayer.get("FloorId") != undefined &&
              Floorlayer.get("FloorId") == "Floor_" + _addfloorid
            ) {
              arr_layer[arr_layer.length] = Floorlayer;
            }
          });


          let sel_feature;
          
          if (duplicate_feature != undefined && duplicate_feature !=-1 && duplicate_feature.length > 0) {
            for (const feature in duplicate_feature) {
              if (duplicate_feature[feature].getGeometry().getType().toUpperCase() =="POLYGON")
              {
                sel_feature=duplicate_feature[feature];
                sel_feature.setStyle(fillcolorStyle)
              }
            }
          }
            
            this.select = new Select({
              style: fillcolorStyle,
            });
            

            if (this.select !== null) 
            {
                this.map.addInteraction(this.select);         
            }
            let _this = eval('this');

            this.selectedFeatures = this.select.getFeatures();
 
            this.translate = new Translate({
              features: this.selectedFeatures,
              style: fillcolorStyle
            });

            /*if (duplicate_feature != undefined && duplicate_feature.length > 0) {
              _this.select.getFeatures().clear();
              _this.translate.features_.array_ = [];
              _this.select.multi_ = true;
              _this.select.style_ = fillcolorStyle;
              _this.select.getFeatures().push(sel_feature);
            }*/
        

          
        
          _this.select.on("select", function (e) {
            try
            {
              if (e.selected) {
                if (_this.select.getFeatures().getLength() == 1) {
                  if (e.selected[0] == undefined) {
                    getSelected(
                      _this.select.getFeatures().getArray()[0],
                      _this,
                      arr_layer,
                      0
                    );
                  } else {
                    getSelected(e.selected[0], _this, arr_layer, 0);
                  }
                } else {
                  _this.select
                    .getFeatures()
                    .remove(
                      _this.select.getFeatures().getArray()[
                        _this.select.getFeatures().getLength() - 1
                      ]
                    );
                  getSelected(
                    _this.select.getFeatures().getArray()[0],
                    _this,
                    arr_layer,
                    0
                  );
                }
              }
            }
           catch (error) {
            throw new Error(
              "mapview-floor.component.ts - select_move() - " + error.message
            );
           }
          });
        

        const _addfloorimageheight = this.addfloorimageheight;
          let _sel_feature = null;
          let _sel_polygon = null;
          const _objMapviewFloorComponent = eval('this');
          _this = eval('this');
          this.translate.on(
            "translateend",
            function (e) {
              _this._translateend = 1;
              if (e.features.getArray().length == 1) {
                _sel_feature = e.features.getArray()[0];
                get_selectedfeature(
                  _objMapviewFloorComponent.map,
                  _objMapviewFloorComponent.dialog,
                  _sel_feature,
                  _addfloorid,
                  _addfloorimageheight
                );
              } else {
                _sel_feature = e.features;
                if (
                  IsCheckRoomDrawn(_sel_feature.getArray()[0], _this, 1) == true
                ) {
                  for (let indx = 0; indx < e.features.getArray().length; indx++) {
                      const vertices = e.features.getArray()[indx].getGeometry()
                      .flatCoordinates.length;
                    if (vertices != 2) {
                      _sel_polygon = e.features.getArray()[indx];
                      Checkpolygon_Intersect(
                        _objMapviewFloorComponent.map,
                        _objMapviewFloorComponent.dialog,
                        _sel_feature,
                        _sel_polygon,
                        _addfloorid,
                        _addfloorimageheight
                      );
                    }
                  }
                } else {
                  ClearStorageSpaceData();
                }
              }
            },
            this.selectedFeatures
          );
          this.map.getInteractions().extend([this.select, this.translate]);
          
        } else if (this._translateend == 1) {
          this._translateend = 0;
          const ConfirmMessageData = {
            ConfirmMessage: "Do you want to save the modification?",
          };
          const dialogRef = this.dialog.open(ConfirmMessageComponent, {
            disableClose: true,
            width: "auto",
            height: "220px",
            panelClass: "myapp-no-padding-dialog",
            autoFocus: false,
            data: ConfirmMessageData,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result == "Yes") {
              localStorage.setItem("IsModifyYes", "1");
              this.onSaveCampusRoom();
            } else {
              this.ResetButton();
              this.ClearDataRefresh();
            }
          });
        } else {
          this.ResetButton();
          this.ClearDataRefresh();
        }
      } catch (error) {
        throw new Error(
          "mapview-floor.component.ts - select_move() - " + error.message
        );
      }
  }

  Unitinfo = [] as any[];
  Unitnameforfloor;
  addHRexflag: addHRexflag[];
  getdeviceforadd;
  MaxRexvalue;
  deviceTypeName;

  strLocationName: string;
  strRoomName: string;
  strDeviceId: number;
  strEmitterId: number;

  strMacId: string;
  strLFId: number;
  strLocation: string;
  strDCMacId: string;

  selectedUnit = 0;

  public deviceaddForm: UntypedFormGroup;

  strDeviceDualId: number;

  strRexDeviceId: number;
  checkDCTag = false;
  checkDCLotiering = false;
  checkDCUnauthorizedEgress = false;

  disableDCLotiering = true;
  disableDCUnauthorizedEgress = true;

  checkPCRemovalDetection = false;
  checkPCButtonPress = false;
  checkPullCord = false;
  checkPCMobilityMode = false;

  checkDCT_Tag1 = false;
  checkDCT_Tag2 = false;
  checkDCT_Tag3 = false;

  checkDCT_TagEnable1 = 0;
  checkDCT_TagEnable2 = 0;
  checkDCT_TagEnable3 = 0;

  checkDCT_Ambient1 = false;
  checkDCT_Ambient2 = false;

  selectedAlertState1 = 0;
  selectedAlertState2 = 0;
  selectedAlertState3 = 0;

  disableAlertmessage1 = 0;

  overTempflag = 0;

  probeoverTempflag = 0;
  humidityoverTempflag = 0;

  checkDCT_Probe1 = false;
  checkDCT_Probe2 = false;

  checkDCT_Humidity1 = false;
  checkDCT_Humidity2 = false;

  HRexId;
  GatewayId;
  HRexMacId;
  GatewayMacId;
  getHRexMacId;
  GatewayDetails;
  getgatewaymacid;
  getRexvalue;
  getRexMaxvalue;
  selectdefaultMode = "0";
  selectdefaultDCTMode = "0";
  addnewDeviceflag = 0;

  //Draw feature for Devices
  tempVectorSource = new VectorSource();
  tempVectorLayer = new OlVectorLayer({
    source: this.tempVectorSource,
  });

  tempgridSource = new VectorSource();
  tempGridLayer = new OlVectorLayer({
    source: this.tempgridSource,
  });
  public showCampus = true;
  public showFloor = false;
  public showEmitter = false;
  public showLF = false;
  public showMac = false;
  public showHrex = false;
  public showpullcord = false;
  public showDC = false;
  public showMonitor = false;
  public showVW = false;
  public showGateway = false;
  public isSaveFloorDevice = false;
  public showDCT = false;
  public showUM = false;
  //Add devices will open the window to select the device to place in the space.

  addNewDevice(CampusId, campusName) {
    const elem = document.getElementById("container_DeviceList");
    const mouseOvertext = document.getElementById("mouseovertext");
    const containerAddDevice = document.getElementById("container_AddDevice");
    if (mouseOvertext != null)
      mouseOvertext.innerHTML = appsettings.Tech_Add_Device;

    if (elem != null && elem.classList.contains("enablebtn")) {
      if (containerAddDevice != null)
        containerAddDevice.style.pointerEvents = "none";
      localStorage.setItem("CreateNew", "1");
      this.checkIsSpace = false;
      this.checkIsEdit = false;
      const saveBtn = document.getElementById("btnsaveclickmapview");
      if (saveBtn != null) saveBtn.innerHTML = "Done";
      this.DisableButton("container_AddDevice");
      if (containerAddDevice != null)
        containerAddDevice.style.pointerEvents = "none";
      try {
        const deviceaddelement = document.getElementById("divAddDevice");
        if (deviceaddelement != null) deviceaddelement.className = "disable";
        const devicelistelement = document.getElementById("divDeviceheader");
        if (devicelistelement != null) devicelistelement.className = "disable";
        if (!SessionCheck(this.router)) {
          return;
        }

        this.getHrexflag();
        this.getMaxRexdevicedetail();
        const _dialog = this.dialog;
        const mapsyncinfo: MapSyncProcess = {
          authKey: this.authkey,
        };
        this.apiService
          .checkMapSync(mapsyncinfo)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((MapSyncinfo: any[]) => {
            this.MapSyncProcess = MapSyncinfo;
            if (this.MapSyncProcess == 1) {
              generateAlertMessage(_dialog, appsettings.MapSyncMsg);
              const deviceaddelement = document.getElementById("divAddDevice");
              if (deviceaddelement != null)
                deviceaddelement.className = "enable";
              const devicelistelement =
                document.getElementById("divDeviceheader");
              if (devicelistelement != null)
                devicelistelement.className = "enable";
              if (containerAddDevice != null)
                containerAddDevice.style.pointerEvents = "";
              this.ResetButton();
              return false;
            } else {
              const voiceLicense = localStorage.getItem("voice_license") ?? "";
              if (this.addnewDeviceflag == 0) {
                const floordetails: getUnit = {
                  authKey: this.authkey,
                  floorId: this.addfloorid,
                  isIndoor: 1,
                  isNeedIntercom: parseInt(voiceLicense),
                  isSubZone: 0 
                };
                this.apiService
                  .getUnitDetails(floordetails)
                  .pipe(takeUntil(this._onDestroy))
                  .subscribe((Unitsinfo: any[]) => {
                    if (Unitsinfo != null && Unitsinfo != undefined) {
                      this.Unitinfo = Unitsinfo["apiReturn"];
                      this.addnewDeviceflag = 1;
                      this.addNewdevicedetails(Unitsinfo["apiReturn"], CampusId, campusName);
                    }
                  });
              }
            }
          });
      } catch (err) {
        if (containerAddDevice != null)
          containerAddDevice.style.pointerEvents = "";
        throw new Error(
          "device.component.ts" + " - addNewDevice() - " + err.message
        );
      }
    } else {
      this.ResetButton();
    }

    this.ClearInteraction();
  }

  addNewdevicedetails(UnitInfo, CampusId, CampusName) {
    try {
      const _ShareddialogcloseDetails = {
        SharedreturncloseVal: 1,
      };
      const containerAddDevice = document.getElementById("container_AddDevice");
      this.alertSharedService.changeclosedialog(_ShareddialogcloseDetails);
      if (this.addfloorid != 0) {
        if (UnitInfo.length == 0) {
          this.addnewDeviceflag = 0;
          generateAlertMessage(
            this.dialog,
            "Please add the space in the zone."
          );
          if (containerAddDevice != null)
            containerAddDevice.style.pointerEvents = "";
          this.ResetButton();
          return;
        } else {
          this.Unitnameforfloor = UnitInfo[0].UnitName;
        }
      } else {
        this.Unitnameforfloor = "";
      }

      let HRexFlag = 0;
      let GatewayFlag = 0;
      if (this.addfloorid != 0) {
        if (this.addHRexflag.toString() == "H-rex found") {
          HRexFlag = 1;
          GatewayFlag = 0;
        } else if (this.addHRexflag.toString() == "Gateway found") {
          HRexFlag = 0;
          GatewayFlag = 1;
        } else if (this.addHRexflag.toString() == "both are found") {
          HRexFlag = 1;
          GatewayFlag = 1;
        } else if (this.addHRexflag.toString() == "false") {
          HRexFlag = 0;
          GatewayFlag = 0;
        }
      }
      //alert(HRexFlag);
      const CampusDetail = {
        CampusId: CampusId,
        CampusName: CampusName,
        MapDetails: this.map,
        VectorDetails: this.vector,
        BuildingId: this.addbuildingid,
        UnitInfo: UnitInfo,
        UnitName: this.Unitnameforfloor,
        FloorId: this.addfloorid,
        ImageHeight: this.addfloorimageheight,
        ImageWidth: this.ImageWidth,
        HRexvalue: HRexFlag,
        Gatewayvalue: GatewayFlag,
        MaxRexValue: this.MaxRexvalue,
        SelectedFeature: this.selectedFeature,
      };
      const devicedataforadd = [] as any[];
      devicedataforadd.push(this.getdeviceforadd, CampusDetail);

      if (this.adddevicedialogopen == 0) {
        const dialogRef = this.dialog.open(AddDeviceComponent, {
          disableClose: true,
          width: "55em",
          height: "550px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: CampusDetail,
        });
        this.adddevicedialogopen = 1;
        dialogRef.afterClosed().subscribe((result) => {
          if (containerAddDevice != null)
            containerAddDevice.style.pointerEvents = "";
          this.addnewDeviceflag = 0;
          this.adddevicedialogopen = 0;
          if (result != "Cancel") {
            this.Selected_ImageDeviceTypename =
              result.selectImageDeviceTypeName;
            this.Selected_DeviceTypename = result.selectDeviceType;
            this.deviceTypeName = result.selectDeviceType;
            //this.ClearInteraction();
            this.closeDrawFeature();
            this.tempVectorSource.clear();
            this.DrawDeviceFeature(this.Selected_ImageDeviceTypename);
          } else {
            this.ResetButton();
            this.ClearInteraction();
          }

          const devicelistelement = document.getElementById("divDeviceheader");
          if (devicelistelement != null) devicelistelement.className = "enable";
          const deviceaddelement = document.getElementById("divAddDevice");
          if (deviceaddelement != null) deviceaddelement.className = "enable";

          this.EnableButton();
        });
      }
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - addNewdevicedetails() - " + err.message
      );
    }
  }

  public onSaveCampusRoom = () => {
    this.deviceTypeName = this.Selected_DeviceTypename;
    this.CheckIsAdd = true;
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (this.document.activeElement as HTMLTextAreaElement).blur();
      if (
        this.CheckIsAdd == true &&
        this.checkIsEdit == true &&
        this.Edit_pointsbool
      ) {
        let verticesinfo = "";
        const imageheight = this.addfloorimageheight;
        this.Edit_pointsbool = false;
        const DataId = localStorage.getItem("DataId") ?? "";
        const FloorId = localStorage.getItem("FloorId") ?? "";
        this.deviceTypeName = localStorage.getItem("DeviceName") ?? "";
        const _this = eval('this');
        let vertices;
        let polypoints;
        verticesinfo = localStorage.getItem("Polypoints") ?? "";
        if (verticesinfo == "") {
          let keepGoing = true;
          this.map.getLayers().forEach(function (selRoomlayer) {
            if (keepGoing) {
              if (
                selRoomlayer.get("FloorId") != undefined &&
                selRoomlayer.get("FloorId") === "Floor_" + FloorId
              ) {
                const arrFeature = selRoomlayer.getSource().getFeatures();
                if (arrFeature) {
                  // Go through this array and get coordinates of their geometry.
                  arrFeature.forEach(function (selRoomfeature) {
                    const SelectedRoomDataId = selRoomfeature
                      .getProperties()
                      .MultiDataId.toString();
                    if (
                      SelectedRoomDataId.split(",").indexOf(
                        parseInt(DataId).toString()
                      ) != -1 &&
                      selRoomfeature.getGeometry().flatCoordinates.length !=
                        2 &&
                      _this.rotateIsInside == 1
                    ) {
                      vertices = selRoomfeature.getGeometry().getCoordinates();
                      keepGoing = false;
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
              }
            }
          });

          if (vertices == undefined) {
            let _DeviceTypeNew = localStorage.getItem("DeviceName") ?? "";
            if (_this.rotateIsInside == 0) {
              generateAlertMessage(
                _this.dialog,
                "Please change the room drawn as it able to have all its devices in it."
              );
            } else if (_DeviceTypeNew != "" && _DeviceTypeNew !=NewDeviceTypeDesc.Room) {
              _DeviceTypeNew = getDeviceTypeNew(_DeviceTypeNew);
              generateAlertMessage(
                this.dialog,
                "Please place a " + _DeviceTypeNew + " in the room drawn"
              );
            }
          } else {
            verticesinfo = "";
            if (vertices.length > 0) {
              for (let j = 0; j < vertices[0].length; j++) {
                polypoints = imageheight - parseFloat(vertices[0][j][1]);
                vertices[0][j][0] = Math.floor(vertices[0][j][0]);
                vertices[0][j][1] = Math.floor(polypoints);
                if (verticesinfo.length == 0) {
                  verticesinfo += vertices[0][j][0] + "," + vertices[0][j][1];
                } else {
                  verticesinfo =
                    verticesinfo +
                    " " +
                    vertices[0][j][0] +
                    "," +
                    vertices[0][j][1];
                }
              }
            }
          }
          if (verticesinfo != null)
            localStorage.setItem("Polypoints", verticesinfo);
          localStorage.setItem("OldUnitId", "0");
        }
        const _UnitId = localStorage.getItem("UnitId");
        if (_UnitId != null && _UnitId != "0" && verticesinfo != null) {
          this.onupdateFloorRoom();
        } else {
          this.Edit_pointsbool = true;
          this.EditSaveExists();
        }
      } else {
        try {
          if (this.checkdrawroomflag == 0 && this.addfloorid == 0) {
            generateAlertMessage(this.dialog, "Please draw the Building");
            return false;
          } else if (this.selectedBuilding == 0) {
            generateAlertMessage(this.dialog, "Please select the Building");
            return false;
          }
          // Get the array of features
          if (this.CheckIsAdd != true || this.checkIsSpace == true) {
            if (this.vector.getSource() == null) {
              generateAlertMessage(this.dialog, "Please draw the room");
              return false;
            }
            const features = this.vector.getSource().getFeatures();
            if (features.length == 0 && this.addfloorid == 0) {
              generateAlertMessage(this.dialog, "Please draw the Building");
              return false;
            } else if (features.length == 0 && this.addfloorid != 0) {
              if (
                this.deviceTypeName == DeviceTypeDesc.Emitter ||
                this.deviceTypeName == DeviceTypeDesc.Monitor ||
                this.deviceTypeName == DeviceTypeDesc.VW ||
                this.deviceTypeName == DeviceTypeDesc.UM
              ) {
                generateAlertMessage(this.dialog, "Please draw the room");
                return false;
              } else if (this.deviceTypeName == undefined) {
                generateAlertMessage(this.dialog, "Please draw the room");
                return false;
              }
            }
          }
        } catch {
          if (
            this.deviceTypeName == DeviceTypeDesc.Emitter ||
            this.deviceTypeName == DeviceTypeDesc.Monitor ||
            this.deviceTypeName == DeviceTypeDesc.VW ||
            this.deviceTypeName == DeviceTypeDesc.UM
          ) {
            generateAlertMessage(this.dialog, "Please draw the room");
            return false;
          }
        }
        //this.isSaveFloorDevice = false;

        let vertices;
        let verticesinfo = "";
        let polypoints;
        let featurespoly;
        const imageheight = this.addfloorimageheight;
        // Get the array of features
        if (this.CheckIsAdd != true || this.checkIsSpace == true) {
          const features = this.vector.getSource().getFeatures();
          if (features != undefined) {            
            // Go through this array and get coordinates of their geometry.
            features.forEach(function (feature) {
              featurespoly = feature.getGeometry().getCoordinates();
              featurespoly[0].pop();
              for (let j = 0; j < featurespoly[0].length; j++) {
                polypoints = imageheight - parseFloat(featurespoly[0][j][1]);
                featurespoly[0][j][0] = Math.floor(featurespoly[0][j][0]);
                featurespoly[0][j][1] = Math.floor(polypoints);
                if (verticesinfo.length == 0) {
                  verticesinfo +=
                    featurespoly[0][j][0] + "," + featurespoly[0][j][1];
                } else {
                  verticesinfo =
                    verticesinfo +
                    " " +
                    featurespoly[0][j][0] +
                    "," +
                    featurespoly[0][j][1];
                }
              }
            });
          }
        } else if (localStorage.getItem("CreateNew") == "1") {
          const DataId = localStorage.getItem("DataId") ?? "";
          const FloorId = localStorage.getItem("FloorId") ?? "";
          let keepGoing = true;
          this.map.getLayers().forEach(function (selRoomlayer) {
            if (keepGoing) {
              if (
                selRoomlayer.get("FloorId") != undefined &&
                selRoomlayer.get("FloorId") === FloorId
              ) {
                const arrFeature = selRoomlayer.getSource().getFeatures();
                if (arrFeature) {
                  // Go through this array and get coordinates of their geometry.
                  arrFeature.forEach(function (selRoomfeature) {
                    const SelectedRoomDataId =
                      selRoomfeature.getProperties().DataId;
                    if (parseInt(SelectedRoomDataId) == parseInt(DataId)) {
                      vertices = selRoomfeature.getGeometry().getCoordinates();
                      keepGoing = false;
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
              }
            }
          });

          if (vertices == undefined) {
            let _DeviceTypeNew = localStorage.getItem("DeviceTypeName") ?? "";
            if (_DeviceTypeNew != "" && _DeviceTypeNew !=NewDeviceTypeDesc.Room) {
              _DeviceTypeNew = getDeviceTypeNew(_DeviceTypeNew);
              generateAlertMessage(
                this.dialog,
                "Please place a " + _DeviceTypeNew + " in the room drawn"
              );
            }
          } else {
            if (vertices.length > 0) {
              for (let j = 0; j < vertices[0].length; j++) {
                polypoints = imageheight - parseFloat(vertices[0][j][1]);
                vertices[0][j][0] = Math.floor(vertices[0][j][0]);
                vertices[0][j][1] = Math.floor(polypoints);
                if (verticesinfo.length == 0) {
                  verticesinfo += vertices[0][j][0] + "," + vertices[0][j][1];
                } else {
                  verticesinfo =
                    verticesinfo +
                    " " +
                    vertices[0][j][0] +
                    "," +
                    vertices[0][j][1];
                }
              }
            }
          }
        }

        const _DataId = localStorage.getItem("DataId") ?? "";
        const _UnitId = localStorage.getItem("UnitId");
        const _RoomName = localStorage.getItem("RoomName") ?? "";

        if (this.checkIsSpace == true) {
          this.onSaveFloorSpace(verticesinfo);
        } else if (
          _DataId != null &&
          _UnitId != null &&
          _UnitId != "0" &&
          _RoomName != null
        ) {
          this.onsaveFloorRoom(
            verticesinfo,
            this.deviceTypeName,
            _DataId,
            _UnitId,
            _RoomName
          );
        } else {
          //no action
          generateAlertMessage(
            this.dialog,
            "All changes already saved. It is safe to exit editing mode."
          );
        }
      }
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts" + " - onSaveCampusRoom() - " + err.message
      );
    }
  };

  onSaveFloorSpace(polypoints) {
    this.add_space(polypoints);
  }

  onsaveFloorRoom(polypoints, devicename, _DataId, _UnitId, _RoomName) {
    try {
      let vertices;
      this.strDeviceId = 0;
      this.selectedUnit = 5;
      this.showHrex = true;
      this.showGateway = true;
      this.strRoomName = "testdevicenewtech";

      // Get the array of features
      const features = this.tempVectorLayer.getSource().getFeatures();
      // Go through this array and get coordinates of their geometry.
      features.forEach(function (feature) {
        vertices = feature.getGeometry().getCoordinates();
      });

      const deviceX = convertToSvgEcllipseXCoordinate(
        parseFloat(vertices[0]),
        true
      );
      const deviceY = convertToSvgEcllipseYCoordinate(
        parseFloat(vertices[1]),
        this.addfloorimageheight
      );

      this.adddeviceflag = 1;

      this.editDevicedetails(
        0,
        this.deviceTypeName,
        _UnitId,
        _RoomName,
        polypoints,
        deviceX,
        deviceY,
        _DataId
      );
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts" + " - onsaveFloorRoom() - " + err.message
      );
    }
  }

  onupdateFloorRoom() {
    try {
      const _Polypoints = localStorage.getItem("Polypoints") ?? "";
      const _UnitId = localStorage.getItem("UnitId") ?? "";
      const Old_UnitId = localStorage.getItem("OldUnitId") ?? "";
      const _DeviceX = localStorage.getItem("DeviceX") ?? "";
      const _DeviceY = localStorage.getItem("DeviceY") ?? "";
      const _DataId = localStorage.getItem("DataId") ?? "";
      let _MultiDataId = localStorage.getItem("MultiDataId") ?? "";            
      const _IsDevice = localStorage.getItem("IsDevice") ?? "";
      this.deviceTypeName = localStorage.getItem("DeviceName") ?? "";
      // const devicename = localStorage.getItem("DeviceName") ?? "";

      let arr_DeviceX = [] as any[];
      let arr_DeviceY = [] as any[];
      
      if (_DeviceX != null) arr_DeviceX = _DeviceX.toString().split(",");
      if (_DeviceY != null) arr_DeviceY = _DeviceY.toString().split(",");
      
      if (localStorage.getItem("MultiDataId") == "") {
        _MultiDataId = _DataId;
        
      }
      let _oldUnitId = 0;
      if (Old_UnitId != "") _oldUnitId = parseInt(Old_UnitId);
      let adddevicedetails: getPolylineRequest;
      if (_IsDevice == "1") {
        adddevicedetails = {
          authKey: this.authkey,
          dataId: _DataId,
          unitId: parseInt(_UnitId),
          polypoints: _Polypoints,
          deviceX: arr_DeviceX[0],
          deviceY: arr_DeviceY[0],
          unitIdOld: _oldUnitId,
          isOutdoorLocation:0,
        };
      } else {
        adddevicedetails = {
          authKey: this.authkey,
          dataId: _MultiDataId,
          unitId: parseInt(_UnitId),
          polypoints: _Polypoints,
          deviceX: _DeviceX,
          deviceY: _DeviceY,
          unitIdOld: _oldUnitId,
          isOutdoorLocation:0,
        };
      }
      const saveclick = document.getElementById("btnsaveclickmapview");
      this.technologyoverlayService
        .updatePolylineFloor(adddevicedetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            this.EditSaveCall();
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, apiresponse.toString());
            if (saveclick != null)
              saveclick.className = "clsdeviceaddbutton mat-raised-button";
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(this.dialog, apiresponse.toString());
            if (saveclick != null)
              saveclick.className =
                "clsdeviceaddbutton mat-raised-button mat-primary";
          }
        });
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts" + " - onupdateFloorRoom() - " + err.message
      );
    }
  }

  CheckRoomDrawn() {
    let roomDrwan = false;
    if (this.vector.getSource() != null) {
      if (this.vector.getSource().getFeatures().length == 0) {
        roomDrwan = true;
      } else {
        // Get the array of features
        const features = this.vector.getSource().getFeatures();
        let vertices;
        let polyLine = [];

        if (features) {
          // Go through this array and get coordinates of their geometry.
          features.forEach(function (feature) {
            vertices = feature.getGeometry().getCoordinates();
          });
        }

        vertices[0].pop();
        polyLine = vertices[0];

        for (let i = 0; i < polyLine.length; i++) {
          const xi = polyLine[i][0],
            yi = polyLine[i][1];
          if (
            xi < 0 ||
            yi < 0 ||
            xi > this.imageExtent[2] ||
            yi > this.imageExtent[3]
          ) {
            roomDrwan = true;
            break;
          }
        }
      }
    }
    return roomDrwan;
  }

  CheckPointInMap() {
    let roomDrwan = false;
    if (this.tempVectorLayer.getSource() != null) {
      if (this.tempVectorLayer.getSource().getFeatures().length == 0) {
        roomDrwan = true;
      } else {
        // Get the array of features
        const features = this.tempVectorLayer.getSource().getFeatures();
        let vertices;

        if (features) {
          // Go through this array and get coordinates of their geometry.
          features.forEach(function (feature) {
            vertices = feature.getGeometry().getCoordinates();
          });
        }
        if (vertices.length > 0) {
          if (
            vertices[0] < 0 ||
            vertices[1] < 0 ||
            vertices[0] > this.imageExtent[2] ||
            vertices[1] > this.imageExtent[3]
          ) {
            roomDrwan = true;
            this.tempVectorLayer.getSource().clear();
          }
        } else {
          roomDrwan = true;
        }
      }
    }
    return roomDrwan;
  }

  duplicate() {
    try {
      const _UnitId = localStorage.getItem("UnitId");
      const mouseovertext = document.getElementById("mouseovertext");
      if (mouseovertext != null)
        mouseovertext.innerHTML = appsettings.Tech_Duplicate_Space;
      if (_UnitId != undefined && parseInt(_UnitId) != 0) {
        const saveBtn = document.getElementById("btnsaveclickmapview");
        if (saveBtn != null)
          if (saveBtn.innerHTML == "Done") saveBtn.innerHTML = "Save";
        const elem = document.getElementById("container_DeviceList");
        if (elem != null) {
          if (elem.classList.contains("enablebtn")) {
            this.DisableButton("container_Duplicate");
          }
        }

        if (!SessionCheck(this.router)) {
          return;
        }

        const _dialog = this.dialog;
        const mapsyncinfo: MapSyncProcess = {
          authKey: this.authkey,
        };
        this.apiService
          .checkMapSync(mapsyncinfo)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((MapSyncinfo: any[]) => {
            this.MapSyncProcess = MapSyncinfo;
            if (this.MapSyncProcess == 1) {
              generateAlertMessage(_dialog, appsettings.MapSyncMsg);
              return false;
            } else {
              const unitbyid: UnitById = {
                authKey: this.authkey,
                unitId: parseInt(_UnitId),
              };

              this.technologyoverlayService
                .getUnitById(unitbyid)
                .pipe(takeUntil(this._onDestroy))
                .subscribe((UnitByIddetails: any[]) => {
                  this.UnitByIddetails = UnitByIddetails["apiReturn"][0];
                  this.duplicateunitopendialog(this.UnitByIddetails);
                });
            }
          });
      } else {
        generateAlertMessage(
          this.dialog,
          "Please select the space to duplicate."
        );
        return;
      }
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - duplicateUnitdetails() - " + err.message
      );
    }
  }

  duplicateunitopendialog(UnitById) {
    if (this.uniteditdialogopen == 0) {
      const dialogRef = this.dialog.open(DuplicateUnitComponent, {
        disableClose: true,
        width: "75em",
        height: "500px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: UnitById,
      });
      this.uniteditdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "close") {
          this.uniteditdialogopen = 0;

          const floorplanslistelement = document.getElementById(
            "divfloorplandetails"
          );
          if (floorplanslistelement != null)
            floorplanslistelement.className = "clsenable";
        } 
        else if (result["NewSpaceId"] > 0) //result.hasOwnProperty.call("NewSpaceId")
        {  
          this.uniteditdialogopen = 0;
          this.ResetButton();
          this.IsDuplicateAdded = result["NewSpaceId"];
          this.ClearDataRefresh();
          const floorplanslistelement = document.getElementById(
            "divfloorplandetails"
          );
          if (floorplanslistelement != null)
            floorplanslistelement.className = "clsenable";
        }
      });
    }

    this.EnableButton();
  }

  insert_template() {
    const saveBtn = document.getElementById("btnsaveclickmapview");
    const mouseOvertext = document.getElementById("mouseovertext");
    if (mouseOvertext != null)
      mouseOvertext.innerHTML = appsettings.Tech_Insert_Template;
    if (saveBtn != null && saveBtn.innerHTML == "Done")
      saveBtn.innerHTML = "Save";
    const elem = document.getElementById("container_DeviceList");
    if (elem != null && elem.classList.contains("enablebtn")) {
      this.DisableButton("container_InsertTemplate");
    }
    const tempdata: getTemplates = {
      authKey: this.authkey,
    };
    const template_data = [] as any[];
    this.technologyoverlayService
      .getTemplates(tempdata)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((TempDetails: any[]) => {
        TempDetails.forEach(function (e) {
          if (e.TemplateCategoryId == 2) {
            template_data.push(e);
          }
        });
        if (template_data.length == 0) {
          generateAlertMessage(this.dialog, "No Space Template available.");
          this.EnableButton();
          return;
        } else {
          if(this.insertTemplatebool == false)
          {
            this.insertTemplatebool = true;
            const dialogRef = this.dialog.open(InsertTemplateComponent, {
              disableClose: true,
              width: "75em",
              height: "760px",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: this.getfloordata,
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.insertTemplatebool = false;
              if (result == "Cancel" || result == null || result == "") {
                this.ResetButton();
                this.ClearDataRefresh();
                return;
              }

              if (result != "close") {
                this.ResetButton();
                this.IsDuplicateAdded = result["UnitId"];
                this.ClearDataRefresh();
                const floorplanslistelement = document.getElementById(
                  "divfloorplandetails"
                );
                if (floorplanslistelement != null)
                  floorplanslistelement.className = "clsenable";
              }
            });
          }
        }
      });
  }

  add_space(polypoints) {
    const SpaceData = { FloorData: this.getfloordata, Polypoints: polypoints };
    const dialogRef = this.dialog.open(AddUnitComponent, {
      disableClose: true,
      width: "45em",
      height: "650px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: SpaceData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != "Cancel") {
        this.ResetButton();
        this.ClearDataRefresh();
      }
    });
  }

  btnclkfloormapinfohide() {
    this.ClearStoredData();
    this.router.navigate(["../../floorplans"], { state: this.getfloordata });
  }

  deleteinfo() {
    const elem = document.getElementById("container_DeviceList");
    const mouseOvertext = document.getElementById("mouseovertext");
    if (mouseOvertext != null)
      mouseOvertext.innerHTML = appsettings.Tech_Delete_Space;
    if (elem != null && elem.classList.contains("enablebtn")) {
      const saveBtn = document.getElementById("btnsaveclickmapview");
      if (saveBtn != null && saveBtn.innerHTML == "Done")
        saveBtn.innerHTML = "Save";
      this.DisableButton("container_DeleteSpace");
    }

    const selectedUnitId = parseInt(localStorage.getItem("UnitId") ?? "");
    if (selectedUnitId > 0) {
      this.deleteUnit(selectedUnitId);
    } else {
      generateAlertMessage(this.dialog, appsettings.DeleteSpace);
      this.EnableButton();
      return;
    }
    this.EnableButton();
  }

  editDevicedetails(
    DeviceDataid,
    deviceTypeName,
    UnitId,
    UnitName,
    verticesinfo,
    deviceX,
    deviceY,
    _DataId
  ) {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      let deviceinfo;
      const _dialog = this.dialog;
      let Data_Id;
      let Device_Type = deviceTypeName;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            return false;
          } else {
            if (DeviceDataid > 0) {
              this.Device_Details.forEach(function (data) {
                if (data.DataId == DeviceDataid) {
                  deviceinfo = data;
                  Data_Id = deviceinfo.DataId;
                  Device_Type = deviceinfo.DeviceName;
                } else {
                  Data_Id = DeviceDataid;
                }
              });
              const getDeviceById: getDeviceById = {
                authKey: this.authkey,
                dataId: parseInt(Data_Id),
                deviceType: Device_Type.toString(),
                floorId: 0,
                isIndoor: 1,
              };
              this.technologyoverlayService
                .getDeviceDetailsbyId(getDeviceById)
                .pipe(takeUntil(this._onDestroy))
                .subscribe((Devicedetailsinfo: any[]) => {
                  if (
                    Devicedetailsinfo != null &&
                    Devicedetailsinfo != undefined
                  ) {
                    this.edit_DeviceDetails(
                      Devicedetailsinfo["apiReturn"][0],
                      this.dialog,
                      0,
                      this
                    );
                  }
                });
            } else {
              if (this.adddeviceflag == 1) {
                const Device_info = {
                  DeviceName: Device_Type,
                  CampusId: this.addcampusid,
                  BuildId: this.addbuildingid,
                  FloorId: this.addfloorid,
                  UnitId: UnitId,
                  UnitName: UnitName,
                  CampusName: this.Campus_Name,
                  BuildingName: this.Building_Name,
                  FloorName: this.Floor_Name,
                  PolyPoints: verticesinfo,
                  DeviceX: deviceX,
                  DeviceY: deviceY,
                  MaxRexValue: this.MaxRexvalue,
                  DataId: _DataId,
                };
                this.edit_DeviceDetails(Device_info, this.dialog, 1, this);
              } else this.edit_DeviceDetails(deviceinfo, this.dialog, 0, this);
            }
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - edit_Devicedetails() - " + err.message
      );
    }
  }

  edit_DeviceDetails(deviceinfo, dialog, addflag, _MapviewFloorComponent) {
    if (_MapviewFloorComponent.checkIsEditOpen == false) {
      _MapviewFloorComponent.checkIsEditOpen = true;
      let width = "37em";
      let height = "580px";

      localStorage.setItem("DeviceName", deviceinfo.DeviceName);
      _MapviewFloorComponent.deviceTypeName =
        localStorage.getItem("DeviceName");
      if (deviceinfo.DeviceName == "UT") {
        width = "89rem";
        height = "740px";
      }
      
      let HRexFlag = 0;
      let GatewayFlag = 0;
      if(addflag == 1)
      {
        if (this.addfloorid != 0) {
          if (this.addHRexflag.toString() == "H-rex found") {
            HRexFlag = 1;
            GatewayFlag = 0;
          } else if (this.addHRexflag.toString() == "Gateway found") {
            HRexFlag = 0;
            GatewayFlag = 1;
          } else if (this.addHRexflag.toString() == "both are found") {
            HRexFlag = 1;
            GatewayFlag = 1;
          } else if (this.addHRexflag.toString() == "false") {
            HRexFlag = 0;
            GatewayFlag = 0;
          }
        }
      }

      const Devicedetails_info = {
        DeviceData: deviceinfo,
        AddDeviceflag: addflag,
        HRexvalue : HRexFlag,
        Gatewayvalue : GatewayFlag
      };
      const dialogRef = dialog.open(EditDeviceComponent, {
        disableClose: true,
        width: width,
        height: height,
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: Devicedetails_info,
      });
      dialogRef.afterClosed().subscribe((result) => {
        _MapviewFloorComponent.checkIsEditOpen = false;
        if (result != "Cancel" && result != "addDevice") {
          const _btnEditSaveExists = document.getElementById("btnEditSaveExists");
          if (_btnEditSaveExists != null) _btnEditSaveExists.click();
        } else if (result == "addDevice") {
          this.ResetButton();
          this.ClearDataRefresh();
        } else if (result == "Cancel") {
          const saveclick = document.getElementById("btnsaveclickmapview");
          if (saveclick != null)
            saveclick.className = "clsdeviceaddbutton mat-raised-button";
        }
      });
    }
  }

  EditSaveCall() {
    this.deviceTypeName = localStorage.getItem("DeviceName") ?? "";
    const devicename = localStorage.getItem("DeviceName") ?? "";
    if (devicename == DeviceTypeDesc.Rex || devicename == DeviceTypeDesc.HRex) {
      this.technologyoverlayService.reloadiniltc();
      this.technologyoverlayService.systemreset_ltc();
    }
    if (devicename == DeviceTypeDesc.Pullcord) {
      this.technologyoverlayService.reloadPullCordConfig_ltc();
    } else if (
      devicename == DeviceTypeDesc.DC ||
      devicename == DeviceTypeDesc.NewDC
    ) {
      this.apiService.reload_DC_Config();
    } else if (devicename == DeviceTypeDesc.UT) {
      this.apiService.reloadDCTdevice();
    } else if (
      devicename != DeviceTypeDesc.Rex &&
      devicename != DeviceTypeDesc.HRex &&
      devicename != DeviceTypeDesc.Gateway
    ) {
      this.apiService.reload_DrawRoom();
    }
    if (devicename == "Room") {
      if (localStorage.getItem("IsModifyYes") != "1")
        generateAlertMessage(this.dialog, appsettings.SaveSpace);
      this.Edit_pointsbool = true;
    } else if (devicename != null) {
      this.Edit_pointsbool = true;
      if (
        devicename == DeviceTypeDesc.Rex ||
        devicename == DeviceTypeDesc.HRex
      ) {
        if (localStorage.getItem("IsModifyYes") != "1")
          generateAlertMessage(this.dialog, appsettings.SaveDevice);
      } else {
        if (localStorage.getItem("IsModifyYes") != "1")
          generateAlertMessage(this.dialog, appsettings.SaveDevice);
      }
    }
    if (localStorage.getItem("IsModifyYes") != "1") {
      localStorage.removeItem("IsModifyYes");
      this.EditSaveExists();
    } else {
      localStorage.removeItem("IsModifyYes");
      this.IsDuplicateAdded = -1;
      this.ClearStoredData();
      this.getDeviceDetails();
    }
  }

  EditSaveExists() {
    this.ResetButton();
    this.ClearDataRefresh();
  }

  ShowTemplateDetails() {
    const spaceId = parseInt(localStorage.getItem("SpaceId") ?? "");
    const SpaceName = localStorage.getItem("SpaceName") ?? "";
    const UnitId = parseInt(localStorage.getItem("UnitId") ?? "");
    const floorId = this.addfloorid;
    const campusId = this.getfloordata.CampusId;
    const buildingId = this.getfloordata.BuildId;
    const floorName = this.getfloordata.FloorName;
    const floorPlanImage = this.getfloordata.FloorPlanImage;
    if (spaceId > 0) {
      const Spacedata = {
        AuthKey: this.authkey,
        CampusId: campusId,
        BuildId: buildingId,
        FloorId: floorId,
        FloorName: SpaceName,
        UnitId: UnitId,
        mapfloorview: 1,
        FloorPlanImage: floorPlanImage,
      };
      if (this.uniteditdialogopen == 0) {
        const dialogRef = this.dialog.open(TemplateUnitComponent, {
          disableClose: true,
          width: "35em",
          height: "640px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: Spacedata,
        });
        this.uniteditdialogopen = 1;
        dialogRef.afterClosed().subscribe(() => {
          this.uniteditdialogopen = 0;
          const floorplanslistelement = document.getElementById(
            "divfloorplandetails"
          );
          if (floorplanslistelement != null)
            floorplanslistelement.className = "clsenable";
        });
      }
    } else {
      const Floordata = {
        CampusId: campusId,
        BuildId: buildingId,
        FloorId: floorId,
        FloorName: floorName,
        FloorPlanImage: floorPlanImage,
      };
      const dialogRef = this.dialog.open(TemplateZoneComponent, {
        disableClose: true,
        width: "35em",
        height: "640px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: Floordata,
      });
      dialogRef.afterClosed().subscribe(() => {
        const floorplanslistelement = document.getElementById(
          "divfloorplandetails"
        );
        if (floorplanslistelement != null)
          floorplanslistelement.className = "clsenable";
      });
    }
  }

  openFloorEditdialog() {
    if (!SessionCheck(this.router)) {
      return;
    }
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.MapSyncProcess = MapSyncinfo;
        if (this.MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          const floorplanslistelement = document.getElementById(
            "divfloorplandetails"
          );
          if (floorplanslistelement != null)
            floorplanslistelement.className = "clsenable";
          return false;
        } else {
          this.formCampusId = this.getfloordata.FloorId;
          const floorbyid: ZoneByID = {
            authKey: this.authkey,
            zoneId: parseInt(this.getfloordata.FloorId),
          };

          this.technologyoverlayService
            .getZoneById(floorbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((FloorByid: any[]) => {
              this.Edit_FloorById = FloorByid["apiReturn"];
              this.editflooropendialog(this.Edit_FloorById);
            });
        }
      });
  }

  editspaceClick() {
    const editSpaceMenu = document.getElementById("divEditSpaceMenu");
    if (editSpaceMenu != null) editSpaceMenu.style.display = "none";
    localStorage.setItem("Spaceedit", "1");
  }

  editflooropendialog(FloorById) {
    try {
      const floorimage = FloorById[0].FloorPlanImage.split(".");
      let _floorsvgImage;
      let _floorimage;
      if (floorimage[1] == "svg" || floorimage[1] == "csv") {
        _floorsvgImage = FloorById[0].FloorPlanImage;
        _floorimage = "";
      } else {
        _floorimage = FloorById[0].FloorPlanImage;
        _floorsvgImage = "";
      }
      const floordetails: any = {
        CampusId: FloorById[0].CampusId,
        CampusName: FloorById[0].CampusName,
        BuildingName: FloorById[0].BuildingName,
        BuildId: FloorById[0].BuildId,
        FloorPlanImage: _floorimage,
        FloorPlansvgImage: _floorsvgImage,
        Description: FloorById[0].Description,
        FloorId: FloorById[0].FloorId,
        FloorName: FloorById[0].FloorName,
        FZoomLevel: FloorById[0].FZoomLevel,
        FRotateLevel: FloorById[0].FRotateLevel,
        FTransformX: FloorById[0].FTransformX,
        FTransformY: FloorById[0].FTransformY,
        Notifications: FloorById[0].Notifications,
        isResidentCheckin: FloorById[0].isResidentCheckin,
        bgImageWidth: FloorById[0].bgImageWidth,
        bgImageHeight: FloorById[0].bgImageHeight,
        OriginalFloorId : FloorById[0].OriginalFloorId,
        isZoneFloor : FloorById[0].isZoneFloor 
      };
      if (this.flooreditdialogopen == 0) {
        const dialogRef = this.dialog.open(EditZoneComponent, {
          disableClose: true,
          width: "75em",
          height: "850px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: floordetails,
        });
        this.flooreditdialogopen = 1;
        dialogRef.afterClosed().subscribe((result) => {
          this.flooreditdialogopen = 0;

          if (result != "Cancel") this.FloorMapView(result.FloorId);
        });
      }
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts" +
          " - editflooropendialog() - " +
          err.message
      );
    }
  }

  FloorMapView(result) {
    console.log(result);
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(this.addfloorid),
    };
    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        console.log(FloorByid);
        this.ResetButton();
        this.ClearDataRefresh();
        if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Technology,
            enumUIAction.Device_Map_AddEditDelete
          ) == enumUIView.Hide
        ) {
          this.DisableBtn();
        }
      });
  }

  editunitopendialog(UnitByIddetails, dialog, _MapviewFloorComponent) {
    console.log("UnitByIddetails : " + JSON.stringify(UnitByIddetails),UnitByIddetails)
    if (_MapviewFloorComponent.uniteditdialogopen == 0) {
      const dialogRef = dialog.open(EditUnitComponent, {
        disableClose: true,
        width: "87em",
        height: "700px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: UnitByIddetails,
      });
      _MapviewFloorComponent.uniteditdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        _MapviewFloorComponent.uniteditdialogopen = 0;
        if (result != "Cancel") {
          _MapviewFloorComponent.SpaceMapView(result);
        }
      });
    }
  }

  SpaceMapView(result) {
    const floorbyid: UnitById = {
      authKey: this.authkey,
      unitId: parseInt(result),
    };
    this.technologyoverlayService
      .getUnitById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((UnitByid: any[]) => {
        console.log(UnitByid["apiReturn"]);
        this.ResetButton();
        this.ClearDataRefresh();
      });
  }

  ClearInteraction() {
    const arr_Interactions = [] as any[];

    try {
      this.map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Translate) {
          //interaction.setActive(false);
          arr_Interactions[arr_Interactions.length] = interaction;
        }
        if (interaction instanceof Modify) {
          //interaction.setActive(false);
          arr_Interactions[arr_Interactions.length] = interaction;
        }
        if (interaction instanceof Select) {
          //interaction.setActive(false);
          arr_Interactions[arr_Interactions.length] = interaction;
        }
        if (interaction instanceof Draw) {
          //interaction.setActive(false);
          arr_Interactions[arr_Interactions.length] = interaction;
        }
      });

      for (let idx_int = 0; idx_int < arr_Interactions.length; idx_int++) {
        this.map.removeInteraction(arr_Interactions[idx_int]);
      }
      this.source.clear();
      this.map.removeLayer(this.vector);
      this.tempVectorSource.clear();
      this.map.removeLayer(this.tempVectorLayer);
    } catch (e) {
      console.log("ClearInteraction Error");
    }

    this.ClearDataRefresh();
  }

  DisableBtn() {    
    const container_DeviceList = document.getElementById("container_DeviceList");
    const container_SelectMove = document.getElementById("container_SelectMove");
    const container_CreateSpace = document.getElementById("container_CreateSpace");
    const container_EditSpace = document.getElementById("container_EditSpace");
    const container_AddDevice = document.getElementById("container_AddDevice");
    const container_Duplicate = document.getElementById("container_Duplicate");
    const container_InsertTemplate = document.getElementById("container_InsertTemplate");    
    const container_DeleteSpace = document.getElementById("container_DeleteSpace");    
    
    if(container_DeviceList != null)
      container_DeviceList.className = "disablebtn";
    if(container_SelectMove != null)
      container_SelectMove.className = "disablebtn";
    if(container_CreateSpace != null)
      container_CreateSpace.className = "disablebtn";
    if(container_EditSpace != null)
      container_EditSpace.className = "disablebtn";
    if(container_AddDevice != null)
      container_AddDevice.className = "disablebtn";
    if(container_Duplicate != null)
      container_Duplicate.className = "disablebtn";
    if(container_InsertTemplate != null)
      container_InsertTemplate.className = "disablebtn";
    if(container_DeleteSpace != null)
      container_DeleteSpace.className = "disablebtn";
    }

  DisableButton(Enable_Element) {
    
    const container_DeviceList = document.getElementById("container_DeviceList");
    const container_SelectMove = document.getElementById("container_SelectMove");
    const container_CreateSpace = document.getElementById("container_CreateSpace");
    const container_EditSpace = document.getElementById("container_EditSpace");
    const container_AddDevice = document.getElementById("container_AddDevice");
    const container_Duplicate = document.getElementById("container_Duplicate");
    const container_InsertTemplate = document.getElementById("container_InsertTemplate");    
    const container_DeleteSpace = document.getElementById("container_DeleteSpace");
    const enableElement = document.getElementById(Enable_Element);
    
    if(container_DeviceList != null)
      container_DeviceList.className = "disablebtn";
    if(container_SelectMove != null)
      container_SelectMove.className = "disablebtn";
    if(container_CreateSpace != null)
      container_CreateSpace.className = "disablebtn";
    if(container_EditSpace != null)
      container_EditSpace.className = "disablebtn";
    if(container_AddDevice != null)
      container_AddDevice.className = "disablebtn";
    if(container_Duplicate != null)
      container_Duplicate.className = "disablebtn";
    if(container_InsertTemplate != null)
      container_InsertTemplate.className = "disablebtn";
    if(container_DeleteSpace != null)
      container_DeleteSpace.className = "disablebtn";
    if(enableElement != null)
      enableElement.className = "enablebtn";

    if (
      Enable_Element != "container_Duplicate" &&
      Enable_Element != "container_DeleteSpace"
    ) {
      this.ClearStoredData();
      this.uniteditdialogopen = 0;
      const btnShowTemplate = document.getElementById("btnShowTemplate");
      const spnfloorName = document.getElementById("spnfloorName");
      const hdFloorName = document.getElementById("hdFloorName");
            
      if (btnShowTemplate != null)
        btnShowTemplate.innerHTML = "Save Zone As Template";
      if(spnfloorName != null && hdFloorName != null)
        spnfloorName.innerHTML = hdFloorName.innerHTML;
    }
  }

  EnableButton() {
    
    const container_DeviceList = document.getElementById("container_DeviceList");
    const container_SelectMove = document.getElementById("container_SelectMove");
    const container_CreateSpace = document.getElementById("container_CreateSpace");
    const container_EditSpace = document.getElementById("container_EditSpace");
    const container_AddDevice = document.getElementById("container_AddDevice");
    const container_Duplicate = document.getElementById("container_Duplicate");
    const container_InsertTemplate = document.getElementById("container_InsertTemplate");    
    const container_DeleteSpace = document.getElementById("container_DeleteSpace");  
    
    if(container_DeviceList != null)
      container_DeviceList.className = "enablebtn";
    if(container_SelectMove != null)
      container_SelectMove.className = "enablebtn";
    if(container_CreateSpace != null)
      container_CreateSpace.className = "enablebtn";
    if(container_EditSpace != null)
      container_EditSpace.className = "enablebtn";
    if(container_AddDevice != null)
      container_AddDevice.className = "enablebtn";
    if(container_Duplicate != null)
      container_Duplicate.className = "enablebtn";
    if(container_InsertTemplate != null)
      container_InsertTemplate.className = "enablebtn";
    if(container_DeleteSpace != null)
      container_DeleteSpace.className = "enablebtn";
  }

  ResetButton() {
    this.addcampusid = this.getfloordata.CampusId;
    this.addbuildingid = this.getfloordata.BuildId;
    this.addfloorid = this.getfloordata.FloorId;
    this.checkIsSpace = false;
    this.CheckIsAdd = false;
    this.checkIsEdit = false;
    this.checkIsEditOpen = false;
    this._translateend = 0;
    localStorage.setItem("CreateNew", "0");
    this.rotateIsInside = 1;
    this.EnableButton();

    const saveclick = document.getElementById("btnsaveclickmapview");
    if (saveclick != null) {
      saveclick.className = "clsdeviceaddbutton mat-raised-button mat-primary";
      if (saveclick.innerHTML == "Done") saveclick.innerHTML = "Save";
    }    
    const divEditSpaceMenu = document.getElementById("divEditSpaceMenu");
    const divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    const divDeviceList = document.getElementById("divDeviceList");

    if (divEditSpaceMenu != null)
      divEditSpaceMenu.style.display = "none";
    if (divCreateSpaceMenu != null)
      divCreateSpaceMenu.style.display = "none";
    if (divDeviceList != null)
      divDeviceList.style.display = "none";
  }

  ClearStoredData() {
    localStorage.removeItem("SpaceName");
    localStorage.removeItem("SpaceId");
    localStorage.removeItem("UnitId");
    localStorage.removeItem("DataId");
    localStorage.removeItem("RoomName");
    localStorage.removeItem("Polypoints");
    localStorage.removeItem("DeviceX");
    localStorage.removeItem("DeviceY");
    localStorage.removeItem("DeviceName");
    localStorage.removeItem("MultiDataId");
    localStorage.removeItem("MultiDeviceId");
    localStorage.removeItem("MultiFilterDeviceTypeId");
    localStorage.removeItem("IsDevice");
    localStorage.removeItem("DeviceTypeName");
    localStorage.removeItem("sel_DataId");
    localStorage.removeItem("Spaceedit");
    localStorage.removeItem("DeviceCount");
  }

  ClearDataRefresh() {
    this.getDeviceDetails();
    this.ClearStoredData();
    this.uniteditdialogopen = 0;
    const btnShowTemplate = document.getElementById("btnShowTemplate");
    const spnfloorName = document.getElementById("spnfloorName");
    const hdFloorName = document.getElementById("hdFloorName");

    if (btnShowTemplate != null)
      btnShowTemplate.innerHTML = "Save Zone As Template";

    if (spnfloorName != null && hdFloorName != null)
      spnfloorName.innerHTML = hdFloorName.innerHTML;
  }



  closeDrawFeature() {
    try {
      this.map.removeInteraction(this.draw);
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts - closeDrawFeature() - " + err.message
      );
    }
  }

  //Draw feature for Devices
  DrawDeviceFeature(ImageDrawFeature_Type) {
    localStorage.setItem("DeviceTypeName", this.deviceTypeName);
    const DrawFeature_Type = "Point";
    const _addfloorid = this.addfloorid;
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;
    const imagescale = 0.25;
    this.tempVectorSource.clear();
    const fillcolorStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ {
          anchor: [15, 15],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          src: "assets/images/" + ImageDrawFeature_Type,
          scale: imagescale,
        }
      ),
    });

    this.map.removeInteraction(this.draw);
    let drawSource = this.source;
    
    function onDrawStart(event) {
      console.log(event);
      //remove everything drawn previously
      drawSource.clear();
    }

    this.draw = new Draw({
      source: this.tempVectorSource,
      type: DrawFeature_Type,
      style: fillcolorStyle,
    });

    this.draw.on("drawstart", onDrawStart);
    drawSource = this.tempVectorSource;

    this.tempVectorLayer = new OlVectorLayer({
      source: this.tempVectorSource,
      style: fillcolorStyle,
    });

    this.tempVectorLayer.setStyle(fillcolorStyle);
    this.tempVectorLayer.set("extent", this.imageExtent);
    this.map.addInteraction(this.draw);
    this.map.addLayer(this.tempVectorLayer);

    let checkdraw;
    const _this = eval('this');
    this.draw.on("drawend", function (e) {
      checkdraw = true;

      const IsPlaced = getNewFeature(
        _this.map,
        _this.dialog,
        e.feature,
        _addfloorid
      );
      if (IsPlaced == 0) {
        drawSource.clear();
        e.feature.values_.geometry.flatCoordinates = "";
      } else {
        e.feature.setStyle(fillcolorStyle);
        _this.map.removeInteraction(_this.draw);
      }
    });

    this.checkroomdrawend = checkdraw;
  }

  deleteUnit(deleteUnitId) {
    try {
      const DeviceCount = parseInt(localStorage.getItem("DeviceCount") ?? "");
      let Device_Count = "";
      let message = "";

      if (DeviceCount > 0) {
        message = "It contains ";
        Device_Count = DeviceCount + " Devices.";
      }

      const GeneralMessageData = {
        General_header: "Delete Space ",
        GeneralMessage:
          "Are you sure you would like to delete this Space?<br> " +
          message +
          " <b>" +
          Device_Count +
          " </b>",
        General_first: "Yes ",
        General_second: "No",
      };
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.DeleteUnitbyId(deleteUnitId);
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts" + " - deleteUnit - " + err.message
      );
    }
  }

  DeleteUnitbyId(deleteUnitId) {
    const deleteunitById: deleteUnitById = {
      authKey: this.authkey,
      unitId: parseInt(deleteUnitId),
    };
    this.ClearStoredData();
    this.uniteditdialogopen = 0;

    const btnShowTemplate = document.getElementById("btnShowTemplate");
    const spnfloorName = document.getElementById("spnfloorName");
    const hdFloorName = document.getElementById("hdFloorName");

    if (btnShowTemplate != null)
      btnShowTemplate.innerHTML = "Save Zone As Template";

    if (spnfloorName != null && hdFloorName != null)
      spnfloorName.innerHTML = hdFloorName.innerHTML;

    this.technologyoverlayService
      .deleteUnit(deleteunitById)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
        if (apiresponse["apiReturn"] == "Success") {
          generateAlertMessage(this.dialog, "Space deleted successfully.");
          this.ResetButton();
          this.ClearDataRefresh();
        } else if (apiresponse["apiReturn"] == "Fail") {
          generateAlertMessage(this.dialog, "Space deleted failed ");
        } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
          this.thisDialogRef.close("close");
          AuthFailedMessage(this.dialog, this.router);
          return;
        } else {
          generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
        }
        this.thisDialogRef.close();
      });
  }

  selectDeviceSort(sortType) {
    this.selectedsortType = sortType;
    if (sortType == 1) this.getDeviceList_info.sort(this.sortByIRIDascending);
    else if (sortType == 2)
      this.getDeviceList_info.sort(this.sortByIRIDdescending);
    else if (sortType == 3)
      this.getDeviceList_info.sort(this.sortByRoomNameascending);
    else if (sortType == 4)
      this.getDeviceList_info.sort(this.sortByRoomNamedescending);

    this.Device_Info = this.getDeviceList_info;
    this.Total_DeviceInfo = this.getDeviceList_info.length;
    
  }

  sortByIRIDascending(a: any, b: any) {
    if (a.Status > b.Status) return 1;
    else if (a.Status === b.Status) return 0;
    else return -1;
  }

  sortByIRIDdescending(a: any, b: any) {
    if (a.Status < b.Status) return 1;
    else if (a.Status === b.Status) return 0;
    else return -1;
  }

  sortByRoomNameascending(a: any, b: any) {
    if (a.UnitLocation.toLowerCase() > b.UnitLocation.toLowerCase()) return 1;
    else if (a.UnitLocation.toLowerCase() === b.UnitLocation.toLowerCase())
      return 0;
    else return -1;
  }

  sortByRoomNamedescending(a: any, b: any) {
    if (a.UnitLocation.toLowerCase() < b.UnitLocation.toLowerCase()) return 1;
    else if (a.UnitLocation.toLowerCase() === b.UnitLocation.toLowerCase())
      return 0;
    else return -1;
  }

  prevHoverfeature = null;
  mouseoverDevicedetails(deviceinfo) {
    const _this = eval('this');

    const _DataId = deviceinfo.DataId;
    const _addfloorid = this.addfloorid;
    const arr_layer: any[] = [];
    this.map.getLayers().forEach(function (Floorlayer) {
      if (
        Floorlayer.get("FloorId") != undefined &&
        Floorlayer.get("FloorId") == "Floor_" + _addfloorid
      ) {
        arr_layer[arr_layer.length] = Floorlayer;
      }
    });
    for (let indx = 0; indx < arr_layer.length; indx++) {
      const features_device = arr_layer[indx].getSource().getFeatures();
      if (features_device) {
        // Go through this array and get coordinates of their geometry.
        features_device.forEach(function (feature) {
          if (
            parseInt(feature.getProperties().DataId) == parseInt(_DataId) &&
            feature.getProperties().geometry.flatCoordinates.length == 2 &&
            parseInt(feature.getProperties().DeviceType) != 4
          ) {
            if (_this.prevHoverfeature != null)
              mouseOnFeature(_this.prevHoverfeature, 0);

            mouseOnFeature(feature, 1);

            if (_this.prevHoverfeature != feature) {
              _this.prevHoverfeature = feature;
            }
          }
        });
      }
    }
    
    if (_DataId != null && _DataId != "0") {
      const space_deviceinfo = document.getElementsByClassName('deviceinfo_' + _DataId);
      const testDivs = Array.prototype.filter.call(space_deviceinfo, function (deviceElement) {
        deviceElement.style.background = "#d3d3d35e";
        return deviceElement;
      });
      console.log(testDivs);
    }
    
  }

  mouseleaveDevicedetails(deviceinfo) {
    const _this = eval('this');
    mouseOnFeature(_this.prevHoverfeature, 0);
    
    if (deviceinfo.DataId != null && deviceinfo.DataId != "0") {
      const space_deviceinfo = document.getElementsByClassName('deviceinfo_' + deviceinfo.DataId);
      const testDivs = Array.prototype.filter.call(space_deviceinfo, function (deviceElement) {
        deviceElement.style.background = "#ffffff";
        return deviceElement;
      });
      console.log(testDivs);
    }
  }

  mouseoverrightsidemenu(rightmenu) {
    const width = window.innerWidth;
    const mouseOvertext = document.getElementById("mouseovertext");
    if (mouseOvertext != null) {
      if (width > 640) {
        if (rightmenu == enumTech_rightmenu_Tool.DeviceList)
          mouseOvertext.innerHTML = appsettings.Tech_DeviceList;
        else if (rightmenu == enumTech_rightmenu_Tool.Move)
          mouseOvertext.innerHTML = appsettings.Tech_Move;
        else if (rightmenu == enumTech_rightmenu_Tool.Create_Space)
          mouseOvertext.innerHTML = appsettings.Tech_Create_Space;
        else if (rightmenu == enumTech_rightmenu_Tool.Edit_Space)
          mouseOvertext.innerHTML = appsettings.Tech_Edit_Space;
        else if (rightmenu == enumTech_rightmenu_Tool.Add_Device)
          mouseOvertext.innerHTML = appsettings.Tech_Add_Device;
        else if (rightmenu == enumTech_rightmenu_Tool.Duplicate_Space)
          mouseOvertext.innerHTML = appsettings.Tech_Duplicate_Space;
        else if (rightmenu == enumTech_rightmenu_Tool.Insert_Template)
          mouseOvertext.innerHTML = appsettings.Tech_Insert_Template;
        else if (rightmenu == enumTech_rightmenu_Tool.Delete_Space)
          mouseOvertext.innerHTML = appsettings.Tech_Delete_Space;
        else if (rightmenu == enumTech_rightmenu_Tool.Rectangle_Space)
          mouseOvertext.innerHTML = appsettings.Tech_Rectangle_Space;
        else if (rightmenu == enumTech_rightmenu_Tool.Ellipse_Space)
          mouseOvertext.innerHTML = appsettings.Tech_Ellipse_Space;
        else if (rightmenu == enumTech_rightmenu_Tool.FreeHand_Space)
          mouseOvertext.innerHTML = appsettings.Tech_FreeHand_Space;
        else if (rightmenu == enumTech_rightmenu_Tool.Edit_Point)
          mouseOvertext.innerHTML = appsettings.Tech_Edit_Point;
        else if (rightmenu == enumTech_rightmenu_Tool.Rotate_Space)
          mouseOvertext.innerHTML = appsettings.Tech_Rotate_Space;
        else if (rightmenu == enumTech_rightmenu_Tool.Reflect_Space)
          mouseOvertext.innerHTML = appsettings.Tech_Reflect_Space;
      } else {
        mouseOvertext.innerHTML = " ";
      }
    }
  }

  mouseleaverightsidemenu(value) {
    console.log(value);
    const mouseOvertext = document.getElementById("mouseovertext");
    if (mouseOvertext != null) mouseOvertext.innerHTML = " ";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
