import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import {
  BuildByID,
  TechnologyForCreationResponse,
  deleteBuildingById,
  deleteZoneById,
  CampusByID,
  updateBuilding,  
  getCampusdetailsById,
  deleteNotifyByLocation,
  Building,
  ZoneByID
} from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { Subject } from "rxjs";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  fillcolorPolygon,
  enumEnableDisable,
} from "../../../../../app-settings";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
  AuthFailedMessage,
  map_widthandheight,
} from "../../../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { AlertSharedService } from "../../../../../_services/index";
import { ApiService } from "../../../../../api.service";
import { appsettings, enumRequestFrom } from "../../../../../app-settings";
import { MapSyncProcess, getCampus, getBuilding, getZone } from "../../../../../api";
import { HttpClient } from "@angular/common/http";
import OlMap from "ol/Map";

import { GeneralMessageComponent } from "../../../../../general-message/general-message.component";
import { EditZoneComponent } from '../../zone/edit-zone/edit-zone.component';
import { ConfirmDialogComponent } from "../../../../technologyoverlay/device/confirm-dialog/confirm-dialog.component";
import {
  fillcolor_style,
  label_Polygon,
  get_geojsonObject_campus,
  mapBuildTemplate_creation,
} from "../../../../../map-general";
import {
  getCheckInExtent,
  LoadTechnologyBuildingSvg,
} from "../../../../../app-general";
import { AddFloorComponent } from "../../floor/add-floor/add-floor.component";
import { AddZoneComponent } from '../../zone/add-zone/add-zone.component';

import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-building",
  templateUrl: "./edit-building.component.html",
  providers: [TechnologyoverlayService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-building.component.scss"],
})
export class EditBuildingComponent implements OnInit, OnDestroy {
  public editBuildingForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 3;
  deletebuildingid;
  EditCampusId;
  bAddEdit = false;
  bDelete = false;
  buildingData;
  firstcolumns = 2;
  minheight = "350px";
  rowHeight = "79px";
  Floorinfo;
  buildingImgUrl;

  campusinfo;

  E_pngZoomLevel = 1;
  E_pngRotateLevel = 0;
  left_rightval = 0;
  top_bottomval = 0;
  getselectedmapdetails;
  MapSyncProcess;
  formCampusId;
  FloorById;
  flooreditdialogopen = 0;
  campusImgUrl: string = appsettings.CAMPUS_IMAGE_FOLDER;
  campusimagename;
  validcampusimgurl = true;
  flag = 0;
  CampusImageFlag = 0;
  Campusdetails;
  selectedCampusId;
  selectedCampusName;

  chkCampusvalue = true;
  chkCustomvalue = false;
  BuildNotifydefaultValue = 0;
  oldCampusId = 0;
  btnBuildingText = "Next";
  buildFloorCount = false;
  buildbtnFloorEdit =  false;
  getFloorCount;
  buildingName;
  Building: Building[];
  selected_floorId = 0;
  selected_buildId = 0;
  selected_campusId = 0;
  authkey;
  showBuildingDetails = true;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  @ViewChild("fileInput") imagecampusInput: any;

  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditBuildingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,
    public thisDialogRefFloor: MatDialogRef<EditZoneComponent>,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }

      this.buildingData = data;
      this.deletebuildingid = this.buildingData.BuildId;

      this.selectedCampusId = this.buildingData.CampusId;
      this.oldCampusId = this.buildingData.CampusId;
      this.selectedCampusName = this.buildingData.CampusName;
      this.buildingName = this.buildingData.BuildingName;
      
      if(data.FloorCount > 0)
        this.getFloorCount = data.FloorCount;

      if (this.buildingData.Notifications == 0) {
        this.chkCampusvalue = true;
        this.chkCustomvalue = false;
      } else if (this.buildingData.Notifications == 1) {
        this.chkCustomvalue = true;
        this.chkCampusvalue = false;
      }
      
      if(data.isOpenBuildDisable == 1)
        this.showBuildingDetails = false;
      
      // this.campusinfo = data.CampusInfo;
      this.buildingImgUrl =
        appsettings.CAMPUS_IMAGE_FOLDER + this.buildingData.FloorPlanImage;
      this.campusimagename = this.buildingData.FloorPlanImage;
      this.breakpoint$.subscribe(() => this.breakpointChanged()); 
      
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - constructor() - " + err.message
      );
    }
       
  }
  
  breakpointChanged() {        
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "350px";
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }     
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }

      this.editBuildingForm = new UntypedFormGroup({
        campusname: new UntypedFormControl(""),
        CampusId: new UntypedFormControl(""),
        campusimage: new UntypedFormControl(""),
        BuildId: new UntypedFormControl(""),
        BuildingName: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(32),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        description: new UntypedFormControl("", [
          Validators.pattern(appsettings.Name_Pattern),
        ]),        
        floorcount: new UntypedFormControl(""),        
      });
      // this.editBuildingForm.get('campusname').disable();
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - ngOnInit() - " + err.message
      );
    }
    this.selectCampusFull();
    this.getCampusDetails();
    this.getZonedetails();
  }

  getZonedetails() {
    try {
      let floorexist = false;
      const buildbyid: getZone = {
        authKey: this.authkey,
        campusId:this.selectedCampusId,
        buildId: this.deletebuildingid,
        floorId : 0 
      };
      this.apiService
        .getFloorwithZoneDetails(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((floorinfo: any[]) => {
          this.Floorinfo = floorinfo;
          
          this.Floorinfo.forEach(function (data)
          { 
            if(data.IsFloor  == 1 && data.FloorName.length > 0)
            {
              floorexist = true;
            }          
          })
          
          if(floorexist == true)
          {
            this.btnBuildingText = "Save";
            this.buildbtnFloorEdit = false;
            this.buildFloorCount = true;
          }
          else
          {
            this.buildbtnFloorEdit = true;
            this.buildFloorCount = false;
          } 
        
        });
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - getZonedetails() - " + err.message
      );
    }
  }

  getCampusDetails() {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - getCampusDetails() - " + err.message
      );
    }
  }

  hangecampus_Template(campusid) {
    this.selectedCampusId = campusid;
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.editBuildingForm.controls).forEach((field) => {
        // {1}
        const control = this.editBuildingForm.get(field); // {2}
        const editBuildFormId = document.getElementById(event.target.id);
        if (editBuildFormId != null) {
          if (field == editBuildFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    this.editBuildingForm.controls[controlName].hasError(errorName);
  };

  editBuildingdetails(editBuildingFormValue) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      
      if(this.btnBuildingText == "Next")
      {
        if (editBuildingFormValue.floorcount == null || editBuildingFormValue.floorcount == 0 || editBuildingFormValue.floorcount.replace(/\s+/g, " ").trim() == "") 
        {
          generateAlertMessage(this.dialog,"Please Enter the number of Floors");
          return false;
        }
        if (!/^(?:\.?\d+|\d+\.?\d+)$/.test(editBuildingFormValue.floorcount)) 
        {
          generateAlertMessage(this.dialog,"Please Enter the valid number of Floors");
          return false;
        }
        else
        {
          this.clkEditBuildingFloor(editBuildingFormValue,editBuildingFormValue.floorcount);
        }
      }
      else if(this.btnBuildingText == "Save")
      {
      
        if (this.editBuildingForm.valid) {
          if (this.chkCampusvalue == false && this.chkCustomvalue == false) {
            generateAlertMessage(this.dialog, "Please Select Notifications");
            // let saveclick = document.getElementById("btnsavebuildeditclick");
            // saveclick.className = "clsmapsaddbutton mat-raised-button";
            this.buildingEditEnableDisable(enumEnableDisable.Enable);
            return;
          } else {
            // let saveclick = document.getElementById("btnsavebuildeditclick");
            // saveclick.className = "clsmapsaddbutton mat-raised-button disablebtn";
            this.buildingEditEnableDisable(enumEnableDisable.Disable);
            if (this.oldCampusId != this.selectedCampusId)
              this.getCampusdetailsById(editBuildingFormValue);
            else this.onSaveClicked(editBuildingFormValue);
          }
        }
      }
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - editBuilding() - " + err.message
      );
    }
  }

  getCampusdetailsById(Buildforvalue) {
    try {
      const cancelCompleteSec = localStorage.getItem("PAST_ALERT_LINGER") ?? "";
      const campusdetails: getCampusdetailsById = {
        authKey: this.authkey,
        campusId: this.oldCampusId,
        buildingId: parseInt(this.deletebuildingid),
        cancelOrCompleteSec: parseInt(cancelCompleteSec),
      };

      this.technologyoverlayService
        .getCampusdetailsById(campusdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "Not Found") {
            this.onSaveClicked(Buildforvalue);
          } else if (apiresponse["Resident"].length > 0) {
            generateAlertMessage(
              this.dialog,
              "Resident '" +
                apiresponse["Resident"] +
                "' found to associate this campus and building , once change and update the campus"
            );
            // let saveclick = document.getElementById("btnsavebuildeditclick");
            // saveclick.className = "clsmapsaddbutton mat-raised-button";
            this.buildingEditEnableDisable(enumEnableDisable.Enable);
            return;
          } else if (apiresponse.toString() == "Alert Found") {
            generateAlertMessage(
              this.dialog,
              "Alert found for this campus and building once complete or cancel the alert and change the campus"
            );
            // let saveclick = document.getElementById("btnsavebuildeditclick");
            // saveclick.className = "clsmapsaddbutton mat-raised-button";
            this.buildingEditEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" +
          " - getCampusdetailsById() - " +
          err.message
      );
    }
  }

  private onSaveClicked = (editBuildingFormValue) => {
    try {
      let _Description = "";
      let notificationvalue = 0;
      if (editBuildingFormValue.description != "")
        _Description = editBuildingFormValue.description
          .replace(/\s+/g, " ")
          .trim();
      else _Description = "";

      if (this.chkCampusvalue == true) notificationvalue = 0;
      else notificationvalue = 1;

      let MessageDetail;

      let message = "";

      message =
        'By selecting "Custom", you must now configure Buildings for this Campus. Truview will now build out the Building Notifications for each alert type.';

      if (notificationvalue == 1) {
        MessageDetail = { Message: message, Type: 0, CustomType: 1 };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
          width: "650px",
          height: "250px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: MessageDetail,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result == "Confirm") {
            this.updateBuilding(
              editBuildingFormValue,
              _Description,
              notificationvalue
            );
          } else {
            // let saveclick = document.getElementById("btnsavebuildeditclick");
            // saveclick.className = "clsmapsaddbutton mat-raised-button";
            this.buildingEditEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
      } else {
        this.updateBuilding(
          editBuildingFormValue,
          _Description,
          notificationvalue
        );
      }
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - onSaveClicked() - " + err.message
      );
    }
  };

  //Edit building is used to edit the building details. It also has an option to change the campus of the building if no resident is associated with the building or no open alerts are there for building.
  updateBuilding(editBuildingFormValue, _Description, notificationvalue) {
    try {
      const editbuildingdetails: updateBuilding = {
        authKey: this.authkey,
        campusId: parseInt(this.selectedCampusId),
        oldCampusId: this.oldCampusId,
        buildId: parseInt(editBuildingFormValue.BuildId),
        buildingName: editBuildingFormValue.BuildingName
          .replace(/\s+/g, " ")
          .trim(),
        description: _Description.toString(),
        notifications: parseInt(notificationvalue),
      };

      this.technologyoverlayService
        .updateBuilding(editbuildingdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            generateAlertMessage(this.dialog, "Building edited successfully.");          
            this.buildingEditEnableDisable(enumEnableDisable.Enable);
            if (this.BuildNotifydefaultValue == 1) {
              const deleteNotify: deleteNotifyByLocation = {
                authKey: this.authkey,
                campusId: this.selectedCampusId,
                buildingId: editBuildingFormValue.buildId,
                floorId: 0,
              };
              this.apiService
                .deleteNotificationByLocation(deleteNotify)
                .pipe(takeUntil(this._onDestroy))
                .subscribe();
            }
            if (this.router.url == "/floorplans") {
              this.thisDialogRef.close(this.deletebuildingid);
            } else {
              this.BuildingMapView(1);
            }
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Building edited failed.");           
            this.buildingEditEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close();
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else if (apiresponse["apiReturn"] == "buildingName") {
            generateAlertMessage(this.dialog, "Please enter Building Name");           
            this.buildingEditEnableDisable(enumEnableDisable.Enable);
            return;
          } else {
            generateAlertMessage(
              this.dialog,
              "Building name '" +
                apiresponse["BuildingeditList"][0].BuildingName +
                "' already exists."
            );           
            this.buildingEditEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - updateBuilding() - " + err.message
      );
    }
  }

  openOverlay_details() {
    this.thisDialogRef.close(this.buildingData);
  }

  //Click the Open Building button to locate the building in the campus view
  BuildingMapView(checkvalue) {
    if(this.router.url == '/mapbuilding' && checkvalue == 0)
    {
      return;
    }
    const buildbyid: BuildByID = {
      authKey: this.authkey,
      buildId: parseInt(this.buildingData.BuildId),
    };

    this.technologyoverlayService
      .getBuildingById(buildbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((BuildingbyId: any[]) => {
        this.thisDialogRef.close(BuildingbyId["apiReturn"][0]);        
        this.router.navigate(["../../mapbuilding"], { state: BuildingbyId["apiReturn"][0] });
      });
  }

  clkdeleteconfirmdialog(BuildInfo) {
    try {
      let Zone_Count;
      let Space_Count = "";
      let Device_Count = "";
      let message = "";
      if (BuildInfo.ZoneCount >= 0) {
        message = "It contains ";
        Zone_Count = BuildInfo.ZoneCount + " Zones, ";
      }

      if (BuildInfo.SpaceCount >= 0)
        Space_Count = BuildInfo.SpaceCount + " Spaces and ";

      if (BuildInfo.DeviceCount >= 0)
        Device_Count = BuildInfo.DeviceCount + " Devices.";

      const GeneralMessageData = {
        General_header: "Delete Building ",
        GeneralMessage:
          "Are you sure you would like to delete this Building?<br> " +
          message +
          " <b>" +
          Zone_Count +
          Space_Count +
          Device_Count +
          " </b>",
        General_first: "Yes ",
        General_second: "No",
      };
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.deleteBuilding(this.deletebuildingid);
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" +
          " - clkdeleteconfirmdialog() - " +
          err.message
      );
    }
  }

  //Delete building is used to delete the existing building.
  deleteBuilding(deletebuildingid) {
    try {
      const deletecampusbyid: deleteBuildingById = {
        authKey: this.authkey,
        buildId: parseInt(deletebuildingid),
      };

      this.technologyoverlayService
        .deleteBuilding(deletecampusbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            this.apiService.reloadmapconfig();
            generateAlertMessage(this.dialog, "Building deleted successfully.");
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Building deleted failed.");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
          }
          this.thisDialogRef.close("Delete");
        });
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - deleteBuilding() - " + err.message
      );
    }
  }

  deleteZonedetails(floorinfo) {
    try {
      let Space_Count = "";
      let Device_Count = "";
      let message = "";

      if (floorinfo.spacecount >= 0) {
        message = "It contains ";
        Space_Count = floorinfo.spacecount + " Spaces and ";
        Device_Count = floorinfo.devicecount + " Devices.";
      }

      const GeneralMessageData = {
        General_header: "Delete Zone",
        GeneralMessage:
          "Are you sure you would like to delete this Zone?<br> " +
          message +
          " <b>" +
          Space_Count +
          Device_Count +
          " </b>",
        General_first: "Yes ",
        General_second: "No",
      };
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.deleteZone(floorinfo);
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "edit-building.component.ts" + " - deleteZonedetails() - " + err.message
      );
    }
  }

  deleteZone(deletefloor) {
    try {
      const deleteZoneById: deleteZoneById = {
        authKey: this.authkey,
        zoneId: parseInt(deletefloor.FloorId),
      };
      const campusid = deletefloor.CampusId;
      const BuildId = deletefloor.BuildId;
      this.technologyoverlayService
        .deleteZone(deleteZoneById)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            //need check start
            this.alertSharedService
              .currentSharedHomeMapdetails()
              .pipe(takeUntil(this._onDestroy))
              .subscribe((ShareMapDetails) => {
                this.getselectedmapdetails = ShareMapDetails;
              });

            if (this.getselectedmapdetails != undefined) {
              if (
                campusid > 0 &&
                BuildId > 0 &&
                this.getselectedmapdetails.sel_CampusId == campusid &&
                this.getselectedmapdetails.sel_BuildingId == BuildId &&
                this.getselectedmapdetails.sel_FloorId == deletefloor.FloorId
              ) {
                const _SharedHomeMapDetails = {
                  sel_CampusId: campusid,
                  sel_BuildingId: BuildId,
                  sel_FloorId: 0,
                };
                this.alertSharedService.changeSharedHomeMapdetails(
                  _SharedHomeMapDetails
                );
              }
            }
            //need check send

            this.apiService.reloadmapconfig();
            generateAlertMessage(this.dialog, "Zone deleted successfully.");
            this.getZonedetails();
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Zone deleted failed.");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
            this.thisDialogRef.close("Cancel");
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - deleteZone() - " + err.message
      );
    }
  }

  editZonedetails(Floordata) {
    if (!SessionCheck(this.router)) {
      return;
    }
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.MapSyncProcess = MapSyncinfo;
        if (this.MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          const floorplanslistelement = document.getElementById(
            "divfloorplandetails"
          );
          if (floorplanslistelement != null)
            floorplanslistelement.className = "clsenable";
          return false;
        } else {
          this.formCampusId = Floordata.FloorId;
          const floorbyid: ZoneByID = {
            authKey: this.authkey,
            zoneId: parseInt(Floordata.FloorId),
          };

          this.technologyoverlayService
            .getZoneById(floorbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((FloorByid: any[]) => {
              this.FloorById = FloorByid["apiReturn"];
              this.editflooropendialog(this.FloorById);
            });
        }
      });
  }

  editflooropendialog(FloorById) {
    try {
      const floorimage = FloorById[0].FloorPlanImage.split(".");
      let _floorsvgImage;
      let _floorimage;
      if (floorimage[1] == "svg" || floorimage[1] == "csv") {
        _floorsvgImage = FloorById[0].FloorPlanImage;
        _floorimage = "";
      } else {
        _floorimage = FloorById[0].FloorPlanImage;
        _floorsvgImage = "";
      }
      const floordetails: any = {
        CampusId: FloorById[0].CampusId,
        CampusName: FloorById[0].CampusName,
        BuildingName: FloorById[0].BuildingName,
        BuildId: FloorById[0].BuildId,
        FloorPlanImage: _floorimage,
        FloorPlansvgImage: _floorsvgImage,
        Description: FloorById[0].Description,
        FloorId: FloorById[0].FloorId,
        FloorName: FloorById[0].FloorName,
        FZoomLevel: FloorById[0].FZoomLevel,
        FRotateLevel: FloorById[0].FRotateLevel,
        FTransformX: FloorById[0].FTransformX,
        FTransformY: FloorById[0].FTransformY,
        Notifications: FloorById[0].Notifications,
        isResidentCheckin: FloorById[0].isResidentCheckin,
        bgImageWidth: FloorById[0].bgImageWidth,
        bgImageHeight: FloorById[0].bgImageHeight,
        OriginalFloorId : FloorById[0].OriginalFloorId,
        isZoneFloor : FloorById[0].isZoneFloor 
      };
      if (this.flooreditdialogopen == 0) {
        this.thisDialogRefFloor = this.dialog.open(EditZoneComponent, {
          disableClose: true,
          width: "75em",
          height: "850px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: floordetails,
        });
        this.flooreditdialogopen = 1;
        this.thisDialogRefFloor.afterClosed().subscribe((result) => {
          console.log(result);
          this.flooreditdialogopen = 0;
          this.getZonedetails();
        });
      }
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - editflooropendialog() - " + err.message
      );
    }
  }

  toggleCamVisibility(event) {
    if (event.checked == true) {
      this.BuildNotifydefaultValue = 1;
      this.chkCampusvalue = true;
      this.chkCustomvalue = false;
    } else {
      this.BuildNotifydefaultValue = 0;
      this.chkCustomvalue = false;
      this.chkCampusvalue = false;
    }
  }

  toggleCusVisibility(event) {
    this.BuildNotifydefaultValue = 0;
    if (event.checked == true) {
      this.chkCustomvalue = true;
      this.chkCampusvalue = false;
    } else {
      this.chkCampusvalue = false;
      this.chkCustomvalue = false;
    }
  }

  CampusFulldetails: any[];
  ImageWidth = "";
  ImageHeight = "";
  CampusImageURL = "";
  ImageUrl = "";
  BackgroundIMG = "";
  CZoomLevel;
  CRotateLevel;
  TranslateX;
  TranslateY;
  SvgInfo = null;
  map = new OlMap();

  selectCampusFull() {
    try {
      const campusid: CampusByID = {
        authKey: this.authkey,
        campusId: parseInt(this.buildingData.CampusId),
      };

      let item;
      this.apiService
        .getCampusbyCampusId(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusFulldetails: any[]) => {
          this.CampusFulldetails = CampusFulldetails;
          const mapdetail =
            this.buildingData.CampusId +
            "_" +
            this.buildingData.BuildId +
            "_" +
            0;
          this.CampusFulldetails["SelectedMapInfo"] = mapdetail;
          item = { MapDetails: this.CampusFulldetails };
          this.getMapDetails(item);
        });
    } catch (err) {
      throw new Error(
        "mapview.component.ts - selectCampusFull() - " + err.message
      );
    }
  }

  getMapDetails(MapDetails): void {
    let arrMapDetails = "";
    // let BulidingId;
    // let FloorId;
    if (MapDetails.MapDetails.SelectedMapInfo != "0_0_0") {
      arrMapDetails = MapDetails.MapDetails.SelectedMapInfo.split("_");

      // this.Mchange = 0;
      this.CampusFulldetails = MapDetails.MapDetails;
      if (arrMapDetails[2] == "0") {
        const CampusId = arrMapDetails[0];
        // this.selectDisabled = true;
        for (
          let info = 0;
          info <= this.CampusFulldetails["Campusinfo"].length - 1;
          info++
        ) {
          if (
            this.CampusFulldetails["Campusinfo"][info]["CampusId"] == CampusId
          ) {
            this.CampusImageURL =
              this.CampusFulldetails["Campusinfo"][info]["FloorPlanImage"];
            this.ImageWidth =
              this.CampusFulldetails["Campusinfo"][info]["ImageWidth"];
            this.ImageHeight =
              this.CampusFulldetails["Campusinfo"][info]["ImageHeight"];

            this.ImageUrl =
              appsettings.CAMPUS_IMAGE_FOLDER + "/" + this.CampusImageURL;
            this.SvgInfo =
              this.CampusFulldetails["Campusinfo"][info]["Campusdetail"];
            this.CZoomLevel =
              this.CampusFulldetails["Campusinfo"][info]["ZoomLevel"];
            this.CRotateLevel =
              this.CampusFulldetails["Campusinfo"][info]["RotateLevel"];
            this.TranslateX =
              this.CampusFulldetails["Campusinfo"][info]["TransformX"];
            this.TranslateY =
              this.CampusFulldetails["Campusinfo"][info]["TransformY"];
            this.DrawMapCall();
            break;
          }
        }
        this.BackgroundIMG = "";
      }
    }
  }

  DrawMapCall() {
    try {
      //Map Empty
      const mapId = "map2";
      const element = document.getElementById(mapId);
      if (element != null) element.innerHTML = "";
      const leftmenu = document.getElementById("leftmenu1");
      if (leftmenu != null) leftmenu.classList.add("active");
      ///Map width and height
      const imgwh = map_widthandheight(this.ImageWidth, this.ImageHeight);
      const imgwidth = imgwh.ImageWidth;
      const imgheight = imgwh.ImageHeight;

      // Polygons style
      const labelStyle = label_Polygon(0);
      const fillcolorStyle = fillcolor_style(fillcolorPolygon.Incomplete);
      const style = [fillcolorStyle, labelStyle];
      let offSetWidth = 0;
      let offSetHeight = 0;

     

      

      if (element != null) {
        offSetWidth = element.offsetWidth;
        offSetHeight = element.offsetHeight;
      }

      this.map = mapBuildTemplate_creation(
        this.ImageUrl,
        imgwidth,
        imgheight,
        true,
        this.BackgroundIMG,
        this.CZoomLevel,
        this.CRotateLevel,
        offSetWidth,
        offSetHeight,
        this.TranslateX,
        this.TranslateY,
        mapId
      );
      this.map.getControls().removeAt(0);
      let translateX = this.TranslateX;
      let translateY = this.TranslateY;

      translateX = translateX / 122;
      translateY = translateY / 127;

      translateX = translateX * imgwidth;
      translateY = translateY * imgheight;

      const _map = this.map;
      const MapCenter = _map.getView().getCenter();

      MapCenter[0] -= translateX;
      MapCenter[1] += translateY;

      _map.on("moveend", function (e) {
        getCheckInExtent(e, _map, imgwidth, imgheight, MapCenter);
      });
      const arrmarkerName = [];
      if (this.SvgInfo != null) {
        const geojsonObject = get_geojsonObject_campus(
          enumRequestFrom.floorplan_page
        );
        geojsonObject.type = "FeatureCollection";

        
        geojsonObject.features.length = 0;
        if (this.SvgInfo.length > 0) {
          const arrSvgInfo = this.SvgInfo;
          LoadTechnologyBuildingSvg(
            this.map,
            this.buildingData.CampusId,
            arrSvgInfo,
            imgheight,
            arrmarkerName,
            geojsonObject,
            fillcolorStyle,
            labelStyle,
            style,
            this.buildingData.BuildId
          );
        }
      }
    } catch (err) {
      throw new Error("mapview.component.ts - DrawMapCall() - " + err.message);
    }
  }

  buildingEditEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsavebuildeditclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsmapsaddbutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsmapsaddbutton mat-raised-button disablebtn";
  }
  
  clkEditBuildingFloor(buildData,floorCount)
  {
    const buildingData: any = {
      campusId: buildData.CampusId,
      buildId: buildData.BuildId,
      selectCampusName:this.selectedCampusName,
      buildingname:buildData.BuildingName,
      floorcount : floorCount
    };
    this.floorAddopendialog(buildingData)
  } 
  
  floorAddopendialog(buildingForm)
  {
    this.thisDialogRef.close("Cancel");
    const dialogRef = this.dialog.open(AddFloorComponent, {
      disableClose: true,
      width: "75em",
      height: "700px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: buildingForm,
    });
    
    dialogRef.afterClosed().subscribe((result) => {  
      console.log(result)   
    });
    
  }
  
  addZonedetails(floorId,floorName)
  {
    if(floorName.trim().length > 0)
    {
      this.thisDialogRef.close("Cancel");
      const getBuildingdata : getBuilding = {        
        authKey: this.authkey,
        campusId: this.selectedCampusId
      }
      this.apiService.getBuildingDetails(getBuildingdata).pipe(takeUntil(this._onDestroy))
      .subscribe((Buildinginfo: any[]) => {                
        this.Building = Buildinginfo["apiReturn"];                   
      
        const addbuildfloorid: any = {
          CampusId: this.selectedCampusId,
          BuildId: this.deletebuildingid,
          CampusName: this.selectedCampusName,
          BuildingName:this.buildingName,
          floorvalue:floorId,
          Floorlength:this.Building,
          FloorName : floorName
        }
        
        console.log("floorId : " + floorId)
        const dialogRef = this.dialog.open(AddZoneComponent, {
          disableClose: true,
          width: "75em",
          height: "650px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: addbuildfloorid,
        });
        
        dialogRef.afterClosed().subscribe((result) => {  
          if(result != "Cancel")
          {
            this.getFloorDetailsbyId(result)      
          }   
        });
      });
    }
  }
  
  getFloorDetailsbyId(zoneid)
  {  
    const floorbyid: ZoneByID = {      
      authKey: this.authkey,
      zoneId:parseInt(zoneid)
  }

  this.technologyoverlayService.getZoneById(floorbyid).pipe(takeUntil(this._onDestroy))
    .subscribe((FloorByid : any[])=>{    
      
      this.selected_campusId = FloorByid[0].CampusId;
      this.selected_buildId = FloorByid[0].BuildId;
      this.selected_floorId = FloorByid[0].FloorId;         
      this.router.navigate(['../../mapview-crop'], { state: FloorByid[0] });
    });
    
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
