<perfect-scrollbar style="max-width: 650px; max-height: 650px;" >
  <div class="clsaddUnit">
      <div class="addUnit-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addUnitHeader" style="margin: 0 0 10px 11px;">Add Space Details
        <span><img class="clscloseimageaddunit" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>
      <div>
        <form style="padding:24px;" [formGroup]="addUnitForm" autocomplete="off" novalidate (ngSubmit)="newaddUnit(addUnitForm.value)">
            <!-- <label>Step One-Add Building</label> -->          
            <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill > 
              <div fxFlex="100" fxFlex.md="25">
            <mat-grid-list [cols]="columns" rowHeight="72px">  
              
              
              <mat-grid-tile>
                <label>Campus</label>
            </mat-grid-tile>                                              
            <mat-grid-tile class="clsmatunitgridtxt">
              <div class="text-inside" style="width:100%;">
                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                  <input matInput placeholder="Campus Name" formControlName="campusname" maxlength="32" [(ngModel)]="UnitData.CampusName" (keyup)="handleInput($event)"/>                                      
              </mat-form-field>
            </div>                     
            </mat-grid-tile>                                 

            <mat-grid-tile>
                <label>Structure </label>
            </mat-grid-tile>
            <mat-grid-tile class="clsmatunitgridtxt">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="Building Name" formControlName="buildingname" maxlength="32" [(ngModel)]="UnitData.BuildingName" (keyup)="handleInput($event)"/>                                 
                    </mat-form-field>
                  </div>
            </mat-grid-tile>
            
            <mat-grid-tile>
              <label>Zone </label>
            </mat-grid-tile>
            <mat-grid-tile class="clsmatunitgridtxt">
                  <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                    <input matInput placeholder="Zone Name" formControlName="zonename" maxlength="32" [(ngModel)]="UnitData.FloorName" (keyup)="handleInput($event)"/>                                 
                    <input type="hidden" formControlName="campusID" [(ngModel)]="UnitData.CampusId">   
                    <input type="hidden" formControlName="buildID" [(ngModel)]="UnitData.BuildId">  
                    <input type="hidden" formControlName="floorID" [(ngModel)]="UnitData.FloorId"> 
                  </mat-form-field>
                </div>
            </mat-grid-tile>                            

                <mat-grid-tile>
                    <label>Space Name</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsmatunitgridtxt">
                        <div class="text-inside" style="width:100%;">
                            <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                           <input matInput placeholder="Space Name" maxlength="30" formControlName="unitname" (keyup)="handleInput($event)">
                           <mat-error *ngIf="addUnitForm.controls.unitname.hasError('required')">Please enter Space Name</mat-error>
                           <mat-error *ngIf="addUnitForm.controls.unitname.hasError('pattern')">Please enter valid Space Name</mat-error>
                           <mat-error *ngIf="addUnitForm.controls.unitname.hasError('maxlength')">Max length 30 characters only</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>     
                
                <mat-grid-tile>
                  <label>Space Category</label>
              </mat-grid-tile>
              <mat-grid-tile class="clsmatunitgridtxt">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                            <mat-select formControlName="category" placeholder="Select Space Category">    
                              <mat-option *ngFor="let Space of Space_details" [value]="Space.SpaceCategoryId" (click)="selectSpaceCategory(Space.SpaceCategoryId)">
                                {{Space.Description}}
                              </mat-option>
                            </mat-select>
                            <mat-error>Please select Space Category</mat-error>
                      </mat-form-field>
                    </div>
              </mat-grid-tile>  

              <mat-grid-tile>
                <label>Description</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsmatunitgridtxt">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="Description" maxlength="30" formControlName="description" (keyup)="handleInput($event)">
                      <!-- <mat-error *ngIf="addUnitForm.controls.description.hasError('required')">Please enter Description</mat-error> -->
                      <mat-error *ngIf="addUnitForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                    </mat-form-field>
                  </div>
            </mat-grid-tile>   
            
            <mat-grid-tile>
              <label>Resident Off<br>Limits/Schedule</label>
          </mat-grid-tile>
          <mat-grid-tile class="clsmatunitgridtxt">
                  <div class="text-inside">
                    <mat-slide-toggle id="chkResOffLimits" (change)="toggler_ResOffLimits($event)" [checked]="checkResOffLimits"></mat-slide-toggle>
                </div>
                
                <div style="margin-left:1.7em;width:180px;">                                  
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:65%;">              
                    <mat-select #selWRStart placeholder="Start" formControlName="res_start" [(ngModel)]="data_RstartTime" [disabled]="!_roomenablehourmin">    
                      <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value" (click)="changeRoomStart(matOption)">
                        {{data.Time}}
                    </mat-option>  
                    </mat-select>     
                    <mat-error>Please select Start Time</mat-error>             
                  </mat-form-field>                  
                  <label style="margin-left:1.5em">to</label>                
                </div>
                
                <div>                  
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:55%;">              
                    <mat-select #selWREnd placeholder="End" formControlName="res_end" [(ngModel)]="data_RendTime" [disabled]="!_roomenablehourmin">    
                      <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value" (click)="changeRoomEnd(matOption)">
                        {{data.Time}}
                    </mat-option>  
                    </mat-select>   
                    <mat-error>Please select End Time</mat-error>
                  </mat-form-field>  
                  
                  <mat-checkbox class="clslivelocation clsenable" id="chkresidentday" (change)="toggleresdayinfoVisibility($event)" [disabled]="!_roomenableroomallday" [checked]="checkresroominfo" 
                    style="font-size: 14px;margin-left: 1em;"></mat-checkbox> 
                    <span style="margin:1em 0.5em 0px 0.5em;" class="Hide-Personal-Info">All Day</span>
                </div> 
                
          </mat-grid-tile>                                                              

            </mat-grid-list>
          </div>
          </div>
            <mat-dialog-actions class="clsunitbuttoncontrol">                                               
              <button type="button" class="clsunitCancelbutton" mat-raised-button (click)="onCloseCancel()">Cancel</button>
              <button id="btnsaveunitaddclick" class="clsaddunitbutton" mat-raised-button [disabled]="!addUnitForm.valid">Save</button>&nbsp;
            </mat-dialog-actions>
          
        </form>
      </div>
  </div>
  </perfect-scrollbar>
