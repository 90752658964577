import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MediaObserver } from "@angular/flex-layout";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { Subject } from "rxjs";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../app-general";
import { enumEnableDisable,appsettings } from "../../../../app-settings";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import { AddNewUnitForm } from "../../technologyoverlay";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { TooltipPosition } from "@angular/material/tooltip";

@Component({
  selector: "app-add-outdoorspace",
  templateUrl: "./add-outdoorspace.component.html",
  styleUrls: ["./add-outdoorspace.component.scss"],
})
export class AddOutdoorspaceComponent implements OnInit {
  public addoutdoorspaceForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 3;
  selectedCampus;
  campusinfo;
  newCampusInfo;
  addoutdoorbool = true;
  SvgPoints;
  roomX=0;
  roomY=0;
  private _onDestroy: Subject<void> = new Subject<void>();
  authkey;
  maxSpaceId;  
  matTooltipSpaceId = "The Space ID is an auto-number field that starts at (1) when the Approximate Space is first created. This field is editable.";
  matTooltipLocAccuracy = "Location Accuracy will be populated when available on signature server. This field is not editable.";
  positionOptions: TooltipPosition[] = [
  "after",
  "before",
  "above",
  "below",
  "left",
  "right",
  ];
  position = new UntypedFormControl(this.positionOptions[3]);
  Breakpoints = Breakpoints;
  checkCampusorZone = 0;
  checkApproximate = false;
  approxDescription = "";
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    mediaObserver: MediaObserver,
    public thisDialogRef: MatDialogRef<AddOutdoorspaceComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.SvgPoints = data.PolyPoints;
    this.roomX = data.roomX;
    this.roomY = data.roomY;
    if(data.BuildingName == "")
      this.approxDescription = data.CampusName;
    else if(data.FloorName == "")
      this.approxDescription = data.BuildingName + "," + data.CampusName;
    else
      this.approxDescription = data.FloorName + "," + data.BuildingName + "," + data.CampusName; 
      
    if(data.BuildId == 0)
      this.checkApproximate = false;
    else
      this.checkApproximate = true;
    this.breakpoint$.subscribe(() => this.breakpointChanged());        
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;      
    }        
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;      
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.addoutdoorspaceForm = new UntypedFormGroup({
      campus: new UntypedFormControl(""),
      campusId: new UntypedFormControl(""),
      buildId: new UntypedFormControl(""),
      floorId: new UntypedFormControl(""),
      name: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      description: new UntypedFormControl("", [
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      spaceId: new UntypedFormControl("", [ Validators.required, Validators.pattern("^[0-9-%]*")]),
      accuracy: new UntypedFormControl(""),
    });
    // this.getcampusinfo();
      this.getOutdoorSpaceId();
    const addOutdoorFormCampus = this.addoutdoorspaceForm.get("campus");
    if(addOutdoorFormCampus != null)
      addOutdoorFormCampus.disable();
      
    const addOutdoorFormAccuracy = this.addoutdoorspaceForm.get("accuracy");
    if(addOutdoorFormAccuracy != null)
      addOutdoorFormAccuracy.disable();      
  }

  getcampusinfo() {
    const uniqueArray = this.removeDuplicates(this.campusinfo, "CampusName");
    this.newCampusInfo = uniqueArray;
  }
  
  getOutdoorSpaceId()
  {
    const spaceinfo: any = {
      authKey: this.authkey
    }
    this.technologyoverlayService
    .getSpaceId(spaceinfo)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((OutdoorSpaceinfo: any[]) => {
      this.maxSpaceId = OutdoorSpaceinfo[0].SpaceId;
    })
  }

  removeDuplicates(originalArray, prop) {
    const newArray = [] as any[];
    const lookupObject = {};

    for (const i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (const i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    this.addoutdoorspaceForm.controls[controlName].hasError(errorName);
  };
  
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.addoutdoorspaceForm.controls).forEach((field) => {
        // {1}
        const control = this.addoutdoorspaceForm.get(field); // {2}
        const outdoorFormId = document.getElementById(event.target.id);
        if (outdoorFormId != null) {
          if (field == outdoorFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  outdoor_selectCampus(selectcampus) {
    try {
      this.selectedCampus = selectcampus;
      // this.getfilterCusLocatorsdetails();
    } catch (err) {
      throw new Error(
        "customizelocators.component.ts" +
          " - cusloc_selectLocationType() - " +
          err.message
      );
    }
  }

  newaddOutdoor(addOutdoorFormValue) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();

      if (this.addoutdoorspaceForm.valid && this.addoutdoorbool) {
        this.addoutdoorbool = false;        
        this.outdoorAddEnableDisable(enumEnableDisable.Disable);
        this.onDrawOverlayClicked(addOutdoorFormValue);
      }
    } catch (err) {
      throw new Error(
        "add-building.component.ts" + " - newaddBuilding() - " + err.message
      );
    }
  }

  onDrawOverlayClicked(addOutdoorFormValue) {    
    this.outdoorAddEnableDisable(enumEnableDisable.Disable);

    let _Description = "";
    if (addOutdoorFormValue.description != "")
      _Description = addOutdoorFormValue.description
        .replace(/\s+/g, " ")
        .trim();
    else _Description = "";

    const unitdetails: AddNewUnitForm = {
      authKey: this.authkey,
      campusId: parseInt(addOutdoorFormValue.campusId),
      buildId: parseInt(addOutdoorFormValue.buildId),
      floorId: parseInt(addOutdoorFormValue.floorId),
      unitName: addOutdoorFormValue.name.replace(/\s+/g, " ").trim(),
      description: _Description, //addOutdoorFormValue.description.replace(/\s+/g, ' ').trim(),
      spaceCategoryId: 0,
      svgpoints: this.SvgPoints.toString(),
      isIndoor: 0,
      accuracy: '',//addOutdoorFormValue.accuracy.toString(),
      resOffLimits: 0,
      spaceId: parseInt(addOutdoorFormValue.spaceId),
      chkAllday : 0,
      resStartHr : 0,
      resStartMi : 0,
      resEndHr : 0,
      resEndMi : 0,
      roomX:this.roomX,
      roomY:this.roomY,
    };

    this.technologyoverlayService
      .addSpaceWithRoom(unitdetails)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: any[]) => {
        if (apiresponse["apiReturn"] == "Success") {
          generateAlertMessage(this.dialog, "Space added successfully.");
          this.thisDialogRef.close("Confirm");
        } else if (apiresponse["apiReturn"] == "Fail") {
          this.addoutdoorbool = true;
          generateAlertMessage(this.dialog, "Space added failed.");
          this.thisDialogRef.close("Confirm");
        } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
          this.thisDialogRef.close("close");
          AuthFailedMessage(this.dialog, this.router);
          return;
        } else if (apiresponse["apiReturn"] == "unitName") {
          this.addoutdoorbool = true;
          generateAlertMessage(this.dialog, "Please enter Space Name");          
          this.outdoorAddEnableDisable(enumEnableDisable.Enable);
          return;
        } 
        else if (apiresponse["apiReturn"] == "spaceId") {
          this.addoutdoorbool = true;
          generateAlertMessage(this.dialog, "Space Id already exists, Please try with different Id.");          
          this.outdoorAddEnableDisable(enumEnableDisable.Enable);
          return;
        }
        else {
          this.addoutdoorbool = true;
          generateAlertMessage(this.dialog,"Space name '" + apiresponse["apiReturn"][0].UnitName + "' already exists.");          
          this.outdoorAddEnableDisable(enumEnableDisable.Enable);
          return;
        }

        const Doneclick = document.getElementById("divrightsidebar");
        if(Doneclick != null)
          Doneclick.className = "clsrightside_outicon";
      });
  }
  
  outdoorAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsaveoutdooraddclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsaddoutsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsaddoutsavebutton mat-raised-button disablebtn";
  }
  
}
