<app-report-header [ReportType]="ReportType"></app-report-header>
<mat-card class="report-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-report-leftbar ></app-report-leftbar>
    </div>
      
    <div fxLayout="column" style="width:100%;">
      <div class="table-container">     
          <div class="main-table"> 
            <div fxLayout="column" style="width:100%;">
            <mat-card class="clsSelectDates" [ngStyle]="{'display': select == 0 ? 'none' : 'block'}">
              <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutWrap id="divselectdates" class="clsSelectdateheader">                          
                    <div class="clsdivDatealertcontrol" fxLayout="row" fxLayoutAlign="space-around left" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutGap="5px" fxLayoutWrap>
    
                    <!-- <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px" class="clsdivDatecontrol">     -->
                      
                      <div class="text-inside">
                        <mat-form-field appearance="outline" floatLabel="auto" class="clsdivdropdowncontrol">              
                          <mat-select #selAlert placeholder="All Residents" [(ngModel)]="selectedResidentId" >  
                            <mat-option value="0" (click)="changeResident(0)">All Residents</mat-option>  
                          <mat-option *ngFor="let Resident of Residentdetails" [value]="Resident.ResidentID" (click)="changeResident(Resident.ResidentID)">
                            {{Resident.Name}}
                          </mat-option>
                          </mat-select>
                          <mat-error>Please select Resident</mat-error>
                        </mat-form-field>
                      </div>
                      
                    <div [ngClass]="flag == 0 ? 'fromdatepicker1' : 'leftfromdatepicker1'" class="text-inside" style="white-space: nowrap;">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsdivDatePicker">              
                          <input type="text" matInput name="daterange" [locale]="{format: 'MM/DD/YYYY'}"  readonly (focuse)="hideDatePicker()" (click)="hideDatePicker()"
                          ngxDaterangepickerMd [(ngModel)]="selectedResDate" class="form-control" (change)="daterangechange($event)" 
                           [maxDate]="getMaxDate()" [alwaysShowCalendars]="true" [linkedCalendars]="false"/>                          
                          <input type="image" id="image" alt="Login" class="ngx-daterangepicker-action date-range-picker-icon" (click)="openDatepicker()"
                          src="assets/images/Calendar.png">
                      </mat-form-field>
                    </div>
    
                  <!-- </div>                     -->
                    
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsdivdropdowncontrol">              
                        <mat-select #selAlert placeholder="All Alerts" [(ngModel)]="selectedAlertType" >  
                          <mat-option value="0" (click)="changeAlertType(0)">All Alerts</mat-option>  
                        <mat-option *ngFor="let Alert of Alertdetails" [value]="Alert.ReportId" (click)="changeAlertType(Alert.ReportId)">
                          {{Alert.Description}}
                        </mat-option>
                        </mat-select>
                        <mat-error>Please select Alert</mat-error>
                      </mat-form-field>
                    </div>
                    
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsdivdropdowncontrol">              
                        <mat-select #selAlert placeholder="All Locations" [(ngModel)]="selectedLocationId" > 
                          <mat-option value="0" (click)="changeLocation(0)">All Locations</mat-option>   
                        <mat-option *ngFor="let Location of Locationdetails" [value]="Location.SpaceCategoryId" (click)="changeLocation(Location.SpaceCategoryId)">
                          {{Location.Description}}
                        </mat-option>
                        </mat-select>
                        <mat-error>Please select Location</mat-error>
                      </mat-form-field>
                    </div>                                       
                    
                    <div class="clsdivalignright">                                        
                      <div class="clsdivalertCount">
                        <span class="clsTitle" [ngStyle]="{'display': flag == 0 ? 'none' : 'block'}">Total Number of Alerts: {{totalAlertCount}}</span>
                      </div>
                      <div class="clsdivbtnSearch">
                        <button id="btnresAssesearch" color="primary" class="clsResSearchbutton" mat-raised-button (click)="onClicksave()">Search</button>
                      </div>
                    </div> 
                    
                </div>
              </div>
            </mat-card>
  
            <!-- <perfect-scrollbar class="chartscrollbarselectdates"> -->
                        
              <div fxLayout="column">
                
              <div class="container clsdivrepchart" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutWrap fxLayoutGap="9%" fxLayoutAlign="flex start">
                
                  <div fxFlex="30%" class="container">
                    <div [ngStyle]="{'display': flag == 0 ? 'none' : 'block'}">
                      <span class="ChartLabel1">Average Response Time</span>
                      <div id="chart-container" class="resizedoughnutchart1" style="display: flex;;white-space: nowrap;">
                        <div [ngStyle]="{'margin-top': getTimeInfo == 4 ? '-2.5em' : '0em'}">
                        <span *ngIf="getTimeInfo == 1" style="font-size: 5em;line-height: 1;" class="ChartLabel2">{{AverageResponseTime}}</span><br>
                          <span *ngIf="getTimeInfo == 1" style="font-size:16px;margin-left: 3em" class="clsSpanavgrestime">Target {{AverageResponseTimeFromIniMin}}:{{AverageResponseTimeFromIniSec}} (+/- {{AverageResponseThresholdFromIniMin}}:{{AverageResponseThresholdFromIniSec}})</span>
                        <span *ngIf="getTimeInfo == 2" style="font-size: 3em" class="ChartLabel2">{{AverageResponseTime}}</span><br>
                          <span *ngIf="getTimeInfo == 2" style="font-size:14px;margin-left: 2em" class="clsSpanavgrestime">Target {{AverageResponseTimeFromIniMin}}:{{AverageResponseTimeFromIniSec}} (+/- {{AverageResponseThresholdFromIniMin}}:{{AverageResponseThresholdFromIniSec}})</span>
                        <span *ngIf="getTimeInfo == 3" style="font-size: 2.5em" class="ChartLabel2">{{AverageResponseTime}}</span><br>
                          <span *ngIf="getTimeInfo == 3" style="font-size:14px;margin-left: 2em" class="clsSpanavgrestime">Target {{AverageResponseTimeFromIniMin}}:{{AverageResponseTimeFromIniSec}} (+/- {{AverageResponseThresholdFromIniMin}}:{{AverageResponseThresholdFromIniSec}})</span>                    
                        <span *ngIf="getTimeInfo == 4" style="font-size: 2em" class="ChartLabel2">{{AverageResponseTime}}</span><br>
                          <span *ngIf="getTimeInfo == 4" style="font-size:14px;margin-left: 2em" class="clsSpanavgrestime">Target {{AverageResponseTimeFromIniMin}}:{{AverageResponseTimeFromIniSec}} (+/- {{AverageResponseThresholdFromIniMin}}:{{AverageResponseThresholdFromIniSec}})</span>
                      </div>    
                      <div [ngStyle]="{'margin-top': getTimeInfo == 3 ? '0em' : '-1em'}">
                        <span *ngIf="RedValue > 0" class="clsSpanredTimeinfo">{{DisplayTimeInfo}}<br><label style="font-size:15px;">over target</label></span>
                        <span *ngIf="GreenValueWithin > 0" class="clsSpangreenTimeinfo">{{DisplayTimeInfo}}<br><label style="font-size:15px;">within target</label></span>
                        <span *ngIf="GreenValueUnder > 0" class="clsSpangreenTimeinfo">{{DisplayTimeInfo}}<br><label style="font-size:15px;">under target</label></span>
                      </div>  
                    </div>                          
                    </div>
                  </div>
                  
                  <div class="clsdivdoughnutchart">
                  <div fxFlex="50%" class="container">
                    <div [ngStyle]="{'display': flag == 0 ? 'none' : 'block'}">
                      <span class="ChartLabel1">Escalation Level Frequency</span>
                      <div id="chart-container" class="resizedoughnutchart2 clsdivEscChart" style="display: block;">
                      <canvas baseChart [chartType]="doughnutEscaltionType" [colors]="doughnutEscaltionColors" 
                         [data]="doughnutEscaltiondatasets" [labels]="doughnutEscaltionLabels"  [options]="doughnutEscaltionoptions" >
                      </canvas>
                      </div>                    
                      </div>                
                  </div> 
                  
                  <div fxFlex="50%" class="container">
                    <div [ngStyle]="{'display': flag == 0 ? 'none' : 'block'}">
                      <span class="ChartLabel1" style="margin-left: 3em;">Alarm Location</span>
                      <div id="chart-container" class="resizedoughnutchart2" style="display: block;height: 258px;">
                          <canvas baseChart [chartType]="doughnutLocationType" [colors]="doughnutLocationColors"
                          [data]="doughnutLocationDataSet" [labels]="doughnutLocationLabels"
                           [options]="doughnutLocatrionOptions"></canvas> 
                      </div>
                    </div>                    
                  </div> 
                </div>  
                        
              </div>                  
              
             <div class="container" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutWrap>                                                           
                
                 <div [ngStyle]="{'display': flag == 0 ? 'none' : 'flex'}">
                  <mat-card-header>
                  <mat-card-title style="font-size: 17px;margin-top:15px;" fxFlex.md="100" fxFlex.sm="70" fxFlex.xs="20">
                    <span class="ReportSummary">Report Summary</span>                   
                  </mat-card-title>  
                </mat-card-header>
                
                <span style="margin:1em 0.5em 0px 0.5em;" class="Hide-Personal-Info">Hide Personal Info</span>
                <mat-checkbox class="clslivelocation clsenable" id="chkresident" [(ngModel)]="checked_resinfo" (change)="toggleresinfoVisibility($event)" [checked]="checkresinfo" 
                  style="font-size: 14px;margin-top: 1em;"></mat-checkbox>
                </div>                                                  
                
                <div style="width:97%;border:1px solid #e4dede;height: 300px;margin-top: 0.5em;margin-left: 1em;"  [ngStyle]="{'display': flag == 0 ? 'none' : 'block'}">
                  <perfect-scrollbar class="scrollbar">
                  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear=true>
                    
                    <ng-container matColumnDef="Alert">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="clslistheader" style="width:12%;background-color: #ffffff;padding-left: 0px;">Description</th>
                      <td mat-cell *matCellDef="let element" class="clstdlistmatcell" style="padding-left: 0px;"> 
                        <span *ngIf="element.Alert.length < 30">{{element.Alert}}</span>
                        <span *ngIf="element.Alert.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.Alert}} #tooltip="matTooltip" matTooltipPosition="above">
                           {{element.Alert.slice(0,15) + ".."}}
                        </span>       
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="ResidentName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="clslistheader" style="width:15%;background-color: #ffffff;">Resident</th>
                      <td mat-cell *matCellDef="let element" class="clstdlistmatcell"> 
                        <span *ngIf="element.ResidentName.length >= 20" matTooltipClass="devicetooltip" matTooltip={{element.ResidentName}} #tooltip="matTooltip" matTooltipPosition="above">
                       {{element.ResidentName.slice(0,15) + ".."}} </span>
                       <span *ngIf="element.ResidentName.length < 20">
                       {{element.ResidentName}}</span>                                                  
                                            
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="Resident_RoomName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="clslistheader" style="width:15%;background-color: #ffffff;">Assigned Room</th>
                      <td mat-cell *matCellDef="let element" class="clstdlistmatcell"> 
                        <span *ngIf="element.Resident_RoomName == ' '">-</span>
                        <span *ngIf="element.Resident_RoomName != ' ' && element.Resident_RoomName != null && element.Resident_RoomName.length < 30">{{element.Resident_RoomName}}</span>
                        <span *ngIf="element.Resident_RoomName != ' ' && element.Resident_RoomName != null && element.Resident_RoomName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.Resident_RoomName}} #tooltip="matTooltip" matTooltipPosition="above">
                          {{element.Resident_RoomName.slice(0,15) + ".."}}
                       </span>       
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="RoomInfo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="clslistheader" style="width:13%;background-color: #ffffff;">TruLocation</th>
                      <td mat-cell *matCellDef="let element" class="clstdlistmatcell"> 
                        <span *ngIf="element.RoomInfo != null && element.RoomInfo.length < 30">{{element.RoomInfo}}</span>
                        <span *ngIf="element.RoomInfo != null && element.RoomInfo.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.RoomInfo}} #tooltip="matTooltip" matTooltipPosition="above">
                          {{element.RoomInfo.slice(0,15) + ".."}}
                       </span>       
                      </td>
                    </ng-container>

                   
                    <ng-container matColumnDef="AlertTime">
                      <th mat-header-cell *matHeaderCellDef  mat-sort-header class="clslistheader" style="width:13%;background-color: #ffffff;">Alert Time </th>
                      <td mat-cell *matCellDef="let element" class="clstdlistmatcell"> {{element.AlertTime}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="ResponseTimeSort">
                      <th mat-header-cell *matHeaderCellDef  mat-sort-header class="clslistheader" style="width:12%;background-color: #ffffff;white-space: nowrap;">Total Response Time </th>
                      <td mat-cell *matCellDef="let element" class="clstdlistmatcell"> {{element.ResponseTime}} </td>
                    </ng-container>
                    
                     <ng-container matColumnDef="PresenceOn">
                      <th mat-header-cell *matHeaderCellDef  mat-sort-header class="clslistheader" style="width:12%;background-color: #ffffff;">Validation </th>
                      <td mat-cell *matCellDef="let element" class="clstdlistmatcell"><mat-icon style="transform: scale(1.3);color:#5eaa46;" *ngIf="element.PresenceOn != ''">done</mat-icon> 
                      <span *ngIf="element.PresenceOn == ''">-</span>
                      </td>
                     </ng-container> 
                    
                     <ng-container matColumnDef="AckedName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="clslistheader" style="width:10%;background-color: #ffffff;white-space: nowrap;">Acked By</th>
                    <td mat-cell *matCellDef="let element" class="clstdlistmatcell" style="padding: right 0px;">
                      <span *ngIf="element.AckedFirstName=='' && element.AckedLastName=='' && element.AckedMethod != 'Self'">-</span>
                      <span *ngIf="element.AckedMethod == 'Self'">{{element.AckedName}}</span>
                      <span *ngIf="(element.AckedFirstName!='' || element.AckedLastName!='') && element.AckedMethod != 'Self' && element.AckedName.length >= 30" 
                        matTooltipClass="devicetooltip" matTooltip={{element.AckedName}} #tooltip="matTooltip" matTooltipPosition="above">
                        {{element.AckedName.slice(0,10) + ".."}}
                      </span>
                      <span *ngIf="(element.AckedFirstName!='' || element.AckedLastName!='') && element.AckedMethod != 'Self' && element.AckedName.length < 30">
                        {{element.AckedName}}
                      </span>
                    </td>
                  </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
                  </table>
                </perfect-scrollbar>
                </div> 
              
                <mat-paginator [ngStyle]="{'display': flag == 0 ? 'none' : 'block'}" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
              
              <div style="width: 98%;float:right;">
                <button id="btnresiassexport" [ngStyle]="{'display': flag1 == 0 ? 'none' : 'block'}" color="primary" class="clsExportbutton mat-raised-button" (click)="exportReportdata(dateDetail)">Export</button>
              </div>                         
                
            </div>                 
              
              </div>
              
              <div fxLayout="row" fxLayoutAlign="space-around center">
                  <div class="loadspinner" *ngIf="reportselectloading" style="top:30%;">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
              </div>
          <!-- </perfect-scrollbar> -->
          </div>
  
  
          </div>
      </div>    
    </div>  
  
  
  </div>

</mat-card>