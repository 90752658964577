
<div id="alertnotification">
    <div class="alert-title">
      <h2 class="alertHeader" style="margin: 0 0 10px 11px;">Notifications
      <span><img id="imgCloseAlertNotification" class="clscloseimage" src="assets/images/close.png" (click)="onCloseNotifyAlert()"></span></h2>
      <mat-divider ></mat-divider> 
    </div>
    <div id="divnotifycontent" class="divNotify" (click)="CallaccessCode($event)" [innerHtml]="htmlToAdd"></div>
    <div id="AlertInfo" style="display: none; position: absolute; left: 100px; top: 100px; border: 1px solid gray;">
      <div id="spn_AlertId"></div>
      <div id="spn_RoomId"></div>
      <div id="spn_FloorId"></div>
    </div>
  <div class="spinner" *ngIf="loading">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
  </div>
  <div style="display: none;" class="clsAlertId"></div>
</div>