<app-technologyoverlay-header></app-technologyoverlay-header>
<mat-card class="technologyoverlay-mat-card">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-technologyoverlay-leftbar></app-technologyoverlay-leftbar>
    </div>

    <div fxLayout="column" fxFlex="100">
      <div style="margin:2em;">
       <!-- <img class="clsmapeditimage" style="cursor:pointer;" src="assets/images/Edit_Icon.png" (click)="openFloor_Editdialog()"/> -->
        <label style="margin-left:1em;"><b> {{Floor_Name}} </b> -{{Building_Name}} - {{Campus_Name}} </label>   
        <span id="FloorCropovertext" style="margin-left:3em;"></span> 
        <button class="btnuserguide" mat-raised-button>User Guide</button>
      </div>

      <div style='height: 76vh;width:100%;display: flex;border: 1px solid #999999;'>
          <image-cropper 
          *ngIf="isCropperShown"
          [(ngModel)]="isRoundCrop"
          [imageChangedEvent]="imageChangedEvent" 
          [maintainAspectRatio]="false" 
          [imageBase64]= "imageBase64"
          format="png" 
          [roundCropper]="false"
          (imageCropped)="imageCropped($event)" 
          (imageLoaded)="imageLoaded()"
          style="width:90%;"
          (cropperReady)="cropperReady()" 
          (loadImageFailed)="loadImageFailed()"></image-cropper>


          <img id="imgCropped" style=' object-fit: contain;width:90%;border: 1px solid #999999;'  *ngIf="isPreviewShown" [src]="croppedImage" />
        
          <div id="divrightsidebar" class="clsrightside_sideicon">
            <div id="container_DeviceList" class="enablebtn"
              style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;" (click)="edit_crop()" (mouseenter)="mouseoverrightsidemenu(1)" (mouseleave)="mouseleaverightsidemenu(1)">
              <img class="clsmapimage" src="assets/images/crop.png" />
              <p style="text-align: center;">Crop</p>
            </div>
            <div id="container_DeviceList" class="enablebtn"
              style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;" (click)="preview_crop()" (mouseenter)="mouseoverrightsidemenu(2)" (mouseleave)="mouseleaverightsidemenu(2)">
              <img class="clsmapimage" src="assets/images/crop.png" />
              <p style="text-align: center;">Preview Crop</p>
            </div>
          </div>
      </div>
      <div id="divDeviceheader" style='height: 50px;'>
        <mat-card class="tech-mat-card">
          <div id="divAddDevice" fxLayout="row" fxLayout.sm="column" fxLayoutGap="0px"
            fxLayoutAlign="space-between left" style="float:right;">
            <div class="clsaligndiv" fxShow.xs="false" fxShow.sm="false">
              <button style="width: 100px;margin:0.5em;" class="clsbtnmapfloorcancel" mat-raised-button
                (click)="btnclkfloormapinfohide()">Exit</button>
              <button style="width: 100px;" id="btnsaveclickmapview" class="clsdeviceaddbutton" mat-raised-button
                (click)="onUploadFloorImage()" *ngIf="bAddEdit==true">Save</button>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card>