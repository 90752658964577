
<perfect-scrollbar style="max-width: 980px; max-height: 480px;" >
  <div class="clsasset">
      <div class="assetedit-title">
        <h3 class="assetEditHeader" style="margin: 0 0 10px 11px;">Edit Asset details
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseAsset()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>

       <div class="clseditcontent">
        <form style="padding:24px;" [formGroup]="EditassetCreationForm" autocomplete="off" novalidate (ngSubmit)="EditcreateAsset(EditassetCreationForm.value)">

            <mat-grid-list [cols]="columns" rowHeight="80px" style="align-items: baseline;">

             
                <mat-grid-tile>
                    <label>Tag Id</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="3">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput formControlName="tagid" [(ngModel)]="data.TagId" maxlength="10"/>
                          <mat-error style="font-size: 11px" *ngIf="EditassetCreationForm.controls.tagid.hasError('required')">
                            Please enter Tag Id
                            </mat-error>
                          <mat-error *ngIf="EditassetCreationForm.controls.tagid.hasError('pattern')">
                            Please enter the valid Tag Id
                          </mat-error>
                          <mat-error *ngIf="EditassetCreationForm.controls.tagid.hasError('maxlength')">
                            Tag Id should be between 1 and 1073741823
                          </mat-error>
                          <mat-error *ngIf="EditassetCreationForm.controls.tagid.hasError('max')">
                            Tag Id should be between 1 and 1073741823
                          </mat-error>
                          <mat-error *ngIf="EditassetCreationForm.controls.tagid.hasError('min')">
                            Tag Id should be between 1 and 1073741823
                          </mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

             
              <mat-grid-tile>
                <label  class="clstxtcontrol">Asset Name</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput formControlName="assetname" [(ngModel)]="data.AssetName" tabindex="1" (keyup)="handleInput($event)" maxlength="30" required>
                      <mat-error style="font-size: 11px" *ngIf="EditassetCreationForm.controls.assetname.hasError('required')">
                          Please enter Asset Name
                      </mat-error>
                      <mat-error *ngIf="EditassetCreationForm.controls.assetname.hasError('pattern')">
                          Please enter valid Asset Name
                      </mat-error>
                      <mat-error *ngIf="EditassetCreationForm.controls.assetname.hasError('maxlength')">
                          Max length 30 characters only
                      </mat-error>
                    </mat-form-field>
                  </div>
              </mat-grid-tile>

              
               <mat-grid-tile>
                <label>Asset Name Alias</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput formControlName="assetnamealias" [(ngModel)]="data.AssetNameAlias" tabindex="2" (keyup)="handleInput($event)" maxlength="30" required>
                      <mat-error style="font-size: 11px" *ngIf="EditassetCreationForm.controls.assetnamealias.hasError('required')">
                          Please enter Asset Name Alias
                      </mat-error>
                      <mat-error *ngIf="EditassetCreationForm.controls.assetnamealias.hasError('pattern')">
                          Please enter valid Asset Name Alias
                      </mat-error>
                      <mat-error *ngIf="EditassetCreationForm.controls.assetnamealias.hasError('maxlength')">
                          Max length 30 characters only
                      </mat-error>
                    </mat-form-field>
                  </div>
              </mat-grid-tile>               

              <mat-grid-tile>
                <label class="clstxtcontrol">Asset Type</label>
               </mat-grid-tile>
                <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput formControlName="assettype" [(ngModel)]="data.AssetType" tabindex="3" (keyup)="handleInput($event)" maxlength="30" required>
                      <mat-error style="font-size: 11px" *ngIf="EditassetCreationForm.controls.assettype.hasError('required')">
                          Please Enter Asset Type
                      </mat-error>
                      <mat-error *ngIf="EditassetCreationForm.controls.assettype.hasError('pattern')">
                        Please enter valid Asset Type
                    </mat-error>
                    <mat-error *ngIf="EditassetCreationForm.controls.assettype.hasError('maxlength')">
                        Max length 30 characters only
                    </mat-error>
                    </mat-form-field>
                  </div>
              </mat-grid-tile> 

                       
                             
            </mat-grid-list>

          
            <mat-dialog-actions class="clsassetbuttoncontrol">                  
              <button type="button" class="clsbtnAsseteditCancel" mat-raised-button (click)="onCloseAsset()">Cancel</button>
              <button id="btnsaveclick" class="clsbtnAsseteditSave" mat-raised-button>Save</button>&nbsp;
            </mat-dialog-actions>

        </form>
      </div> 
   
  </div>
  
  </perfect-scrollbar>
  