<mat-toolbar style="padding:0 0 0 16px">
  <!-- Design for Title icon-->
  <div  fxShow.sm="ture" fxShow.xs="true"  fxShow="true" style="width:10%;" class="alert-main-div">
        <img  style="width:25px;height:25px;margin-right:6px;" mat-line src="assets/images/Notifications-Event Type-Icon-Blue@2x.png">
        <span fxShow.md="false" fxShow.sm="false" fxShow.xs="false" mat-line class="clstitle">Notifications</span>
  </div>

  <div>    
    <mat-slide-toggle [checked]="checkMessageNotification" (change)="toggleMessageNotifications($event)" formControlName="allegress" 
    style="padding-left:2.5em;"></mat-slide-toggle>
    <label  class="clsnotifyHeader">Enable CLEAR messages</label>  
  </div>

<span></span>
<app-header  [ngClass]="UserName.length <= 15 ? 'clsusernameappheader' : 'clsappheader'"></app-header>
</mat-toolbar>