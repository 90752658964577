import { Component, OnInit, Inject,OnDestroy,ViewEncapsulation} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA,  MatDialog} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription, Subject } from 'rxjs';
import { TechnologyForCreationResponse, SetBoardAssociationTektone, GetSpaceDetailsBoardSetupTektone, DeleteConfigurationTektone,GetAlertConfigBoard } from '../../technologyoverlay';
import { TechnologyoverlayService } from '../../technologyoverlay.service';
import { SessionCheck, generateAlertMessage } from '../../../../app-general';
import {Router} from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { takeUntil } from 'rxjs/operators';
import { GeneralMessageComponent } from '../../../../general-message/general-message.component';
@Component({
 
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./edit-config.component.scss']
})
export class TekToneEditConfigComponent implements OnInit,OnDestroy {

  watcher: Subscription;
  columns = 4;
  firstcolumns = 2;
  downIcon = 0;
 
  minheight = "230px";
  location;
  deleteConfigId;
  flag = 0;
  SiteId = "1";
  private _onDestroy: Subject<void> = new Subject<void>();
  editConfigrowHeight = "79px";
  screenWidth;
  bAddEdit = false;
  bDelete = false;
  isundo = 0;
  undooperation = true;
  undopane = 0
  Spacedetails;  
  dataSource;
  dataSourcecnt;
  boardSetupData = [] as any[];
  configuredSetupData = [];
  displayedColumns: string[]= ["RoomId","Event","SpaceName","Index"];  
  public configeditForm : FormGroup;
  selectedSpaceId;
  selectedBoardName = "";
  existingData = [];
  boardlength = 0;
  boardId;
  spaceId = 0;  
  authkey;
 
  constructor(public http: HttpClient,private router: Router,public thisDialogRef: MatDialogRef<TekToneEditConfigComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
          private technologyoverlayService: TechnologyoverlayService, public dialog: MatDialog) 
  {
    try
      {

          this.selectedBoardName = data.BoardName;          
          this.boardlength = data.setupDataRowCount;
        
          this.boardId = data.BoardId;
      }
      catch(err)
      {
        throw new Error('edit-config.component.ts' + ' - constructor() - ' + err.message);
      }
  }

  ngOnInit() 
  {  
    try
    {
      this.authkey = localStorage.getItem("Authkey") ?? "";
       this.configeditForm = new FormGroup({                                
            boardname:new FormControl(''),
            ipaddress:new FormControl(''),                                 
            udpport:new FormControl(''),           
            hdnFileName: new FormControl(''),
            hdnBoardName: new FormControl(''),
            hdnIpAddress: new FormControl(''),
            hdnUdpPort: new FormControl(''),
        });

        for (let i = 1; i <= this.boardlength; i++) 
        {
          this.configeditForm.controls['mat_select_' + i] =  new FormControl('');
        }
      
        const configForm_boardname = this.configeditForm.get('boardname');        
        const configForm_ipaddress = this.configeditForm.get('ipaddress');        
        const configForm_udpport = this.configeditForm.get('udpport');
        
        if(configForm_boardname != null)
          configForm_boardname.disable();
        if(configForm_ipaddress != null) 
         configForm_ipaddress.disable();
        if(configForm_udpport != null)
         configForm_udpport.disable();        
        this.getSpaceDetailsWithBoardSetupData();
    }
    catch(err)
    {
      throw new Error('edit-config.component.ts' + ' - ngOnInit() - ' + err.message);
    }
  }

  handleInput(event) 
  { 
    try
    {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      }
      else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        }
        else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.configeditForm.controls).forEach(field => { // {1}
        const control = this.configeditForm.get(field); // {2}
        const getTargetId = document.getElementById(event.target.id);
        if(getTargetId != null)
        {
          if (field == getTargetId.getAttribute("formControlName")) {
            if(control != null)
            {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true });       // {3}
            }
          }
        }
      });
    }
    catch(err) 
    {
      throw new Error('edit-config.component.ts' + ' - handleInput() - ' + err.message);
    }
  }  

  getSpaceDetailsWithBoardSetupData()
  {
    try
    {
      const getSpaceInfo: GetSpaceDetailsBoardSetupTektone = {
           authKey: this.authkey
        }
        this.technologyoverlayService.getSpaceDetailsWithBoardSetupDataTekTone(getSpaceInfo).pipe(takeUntil(this._onDestroy))
        .subscribe((SpaceDetails: any[])=>{
          this.boardSetupData = SpaceDetails;
          this.dataSource = new MatTableDataSource( SpaceDetails);
          this.dataSource.data =  SpaceDetails;
          this.dataSourcecnt =  SpaceDetails.length;
        });
    }
    catch(err)
    {
      throw new Error('edit-config.component.ts' + ' - getSpaceDetailsWithBoardSetupData() - ' + err.message);
    }
  }

  public onCloseCancel = () => 
  {   
    try
    {
      if(!SessionCheck(this.router))
      {
          return;
      }
      this.thisDialogRef.close('Cancel');
    }
    catch(err)
    {
      throw new Error('edit-config.component.ts' + ' - onCloseCancel() - ' + err.message);
    }
  }

  public hasError = (controlName: string, errorName: string) =>
  {
    try
    {
      return this.configeditForm.controls[controlName].hasError(errorName);
    }
    catch(err)
    {
      throw new Error('edit-config.component.ts' + ' - hasError() - ' + err.message);
    }
  }

  public oneditConfigdetails = (ConfigFormValue) => 
  {  
    try
    {
      if(!SessionCheck(this.router))
      {
        this.thisDialogRef.close('Cancel');
        return;
      } 
      
      if (ConfigFormValue.valid) {
         this.saveConfigurationSettings();
      }
    }
    catch(err)
    {
      throw new Error('edit-config.component.ts' + ' - oneditConfigdetails() - ' + err.message);
    }
  }

  private saveConfigurationSettings = () => 
  {
    try
    {
      (document.activeElement as HTMLTextAreaElement).blur();
       if (this.configeditForm.valid) 
       {
          const saveclick = document.getElementById('btnsaveclick');
          if(saveclick != null)
            saveclick.className = 'clsconfigeditbutton mat-raised-button disablebtn';                       
            const _arrSaveBoardSetupDetails = [] as any[];

              for(let i=0;i<this.boardSetupData.length;i++)
              {
                const _SaveBoardSetupDetails = {};
                _SaveBoardSetupDetails['RoomId'] = this.boardSetupData[i].RoomId;
                _SaveBoardSetupDetails['Event'] = this.boardSetupData[i].Event;
                _SaveBoardSetupDetails['SpaceId'] = this.boardSetupData[i].SpaceName;
                _arrSaveBoardSetupDetails.push(_SaveBoardSetupDetails);
              }


              const saveboardAssociation : SetBoardAssociationTektone= {
                authKey: this.authkey,
                jsonData:JSON.stringify(_arrSaveBoardSetupDetails),
              }

              this.technologyoverlayService.saveBoardAssociationTekTone(saveboardAssociation).pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse : any[])=>{
                console.log(apiresponse)
                if(apiresponse.toString() == "Success")
                { 	      
                  generateAlertMessage(this.dialog, 'Board Configured Successfully.')
                  this.thisDialogRef.close();
                }  
              });            
         }
    }
    catch(err)
    {
      throw new Error('edit-config.component.ts' + ' - saveConfigurationSettings() - ' + err.message);
    }
 }
 
  opendeleteconfigdialog() 
  { 
    try
    {
      const GeneralMessageData = {
        General_header: "Delete Board",                
        GeneralMessage: "Are you sure you would like to delete this Board?",
        General_first: "Yes",
        General_second: "No"
      }   

      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: 'auto',
        height: '220px',
        panelClass: 'myapp-no-padding-dialog',
        autoFocus: false,
        data: GeneralMessageData
      }); 

      dialogRef.afterClosed().subscribe(result => {     
        if(result == "First")
        {                     
          this.deleteConfigurationSettings();               
        }
        else
        {                
          dialogRef.close('Cancel');          
          return;
        }            
      });        
    }
    catch(err)
    {
      throw new Error('edit-config.component.ts' + ' - opendeletedialog() - ' + err.message);
    }
  }  
 
deleteConfigurationSettings()
 {
    try
    {
       const checkAlertByBoardId : GetAlertConfigBoard = {
         authKey: localStorage.getItem("Authkey"),
         boardId: this.boardId
      }
     
      this.technologyoverlayService.getAlertForConfiguredBoard(checkAlertByBoardId).pipe(takeUntil(this._onDestroy))
       .subscribe((apiresponse : TechnologyForCreationResponse[])=>{         
           if(apiresponse.toString() == "yes")
           {
             generateAlertMessage(this.dialog, 'Board has open alerts, please complete or cancel those alerts and try again.');
           }
           else
           {
              const deleteConfigByBoardName : DeleteConfigurationTektone = { authKey: this.authkey }    
              this.technologyoverlayService.deleteConfigurationSettingsTekTone(deleteConfigByBoardName).pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse : TechnologyForCreationResponse[])=>{         
              if(apiresponse.toString() == "true")
              { 	      
                generateAlertMessage(this.dialog, 'Board Configuration deleted successfully.')
              }    
              else if(apiresponse.toString() == "false")
              {          
                generateAlertMessage(this.dialog, 'Board Configuration deleted failed.')
              }
              this.thisDialogRef.close();  
              });
          }
      });
    }    
    catch(err)
    {
        throw new Error('edit-config.component.ts' + ' - deleteConfigurationSettings() - ' + err.message);
    }   
  } 
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
