<mat-sidenav #rightNav position="end" class="mat-elevation-z0"  [opened]="false" mode="side" style="margin-top:64px;background-color: #005493;color: white;z-index:999;border:none;">
    <mat-list dense>
        <mat-nav-list *ngFor="let left of sidenav">
                <mat-list-item style="height:70px;" (click)="roterlink()">
                        <a mat-list-item routerLink={{left.routerLink}} routerLinkActive="active" (click)="this.toggleRightNav()">
                            <img  mat-list-avatar src={{left.src}} alt="left menu" [ngStyle]="{'height': left.routerLink == 'technologyoverlay' || left.routerLink == 'templates'
                            || left.routerLink == 'device' || left.routerLink == 'outdoorlocating' || left.routerLink == 'hardwiredconfiguration' || left.routerLink == 'tektone' ? 'auto' : '36px'}" style="border-radius: unset;"/>                   
                            <p fxShow.sm="false" fxShow.xs="false" fxShow.md="true" fxShow="true"  mat-line ><span class="menu-title">{{left.line1desc}}</span></p>
                            <p fxShow.sm="false"  fxShow.xs="false" fxShow.md="true" fxShow="true"  mat-line ><span class="menu-subtitle">{{left.line2desc}}</span></p> 
                        </a>
                        <mat-divider></mat-divider>               
                    </mat-list-item>
          </mat-nav-list> 
    </mat-list>
</mat-sidenav>

  