import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { TechnologyoverlayService } from "../landing/technologyoverlay/technologyoverlay.service";
import { getTemplates } from "../landing/technologyoverlay/technologyoverlay";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-general-message",
  templateUrl: "./general-message.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./general-message.component.scss"],
})
export class GeneralMessageComponent implements OnInit {
  GeneralMessage = "";
  General_header = "General Message";
  General_first = "Yes";
  General_second = "No";
  Second_disable = "0";
  private _onDestroy: Subject<void> = new Subject<void>();

  constructor(    
    private technologyoverlayService: TechnologyoverlayService,public thisDialogRef: MatDialogRef<GeneralMessageComponent>,@Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.General_header != undefined) {
      this.General_header = data.General_header;
    }
    if (data.GeneralMessage != undefined) {
      this.GeneralMessage = data.GeneralMessage;
    }

    if (data.General_first != undefined) {
      this.General_first = data.General_first;
    }
    if (data.General_second != undefined) {
      this.General_second = data.General_second;
    }
    if (data.Second_disable != undefined) {
      this.Second_disable = data.Second_disable;
    }
  }

  ngOnInit() {
    if (this.General_header == "Add Zone " || this.Second_disable == "1") {
      this.getTemplates();
    }
  }

  getTemplates() {
    try {
      const template_data = [] as any[];
      const authkey = localStorage.getItem("Authkey") ?? "";
      const tempdata: getTemplates = {
        authKey: authkey,
      };
      this.technologyoverlayService
        .getTemplates(tempdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((TempDetails: any[]) => {
          TempDetails.forEach(function (e) {
            if (e.TemplateCategoryId == 1) {
              template_data.push(e);
            }
          });
          const saveclick = document.getElementById("btnsecondaccessenter");
          if (template_data.length == 0 || this.Second_disable == "1")
          {
            if(saveclick != null)
              saveclick.className = "clsgenbutton_Cancel mat-raised-button disablebtn";
          }
          else 
          {
            if(saveclick != null)
              saveclick.className = "clsgenbutton_Cancel mat-raised-button";
          }
        });
    } catch (err) {
      throw new Error(
        "general-message.component.ts" + " - getTemplates() - " + err.message
      );
    }
  }

  // close General Notification Dialog
  public onClosefirst = () => {
    this.thisDialogRef.close("First");
  };
  public onCloseSecond = () => {
    this.thisDialogRef.close("Second");
  };
  public onCloseCancel = () => {
    this.thisDialogRef.close("No");
  };
}
