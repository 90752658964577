import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import {
  Campus,
  Building,
  Floor,
  Units,
  Room,
  CampusByID,
  SubZones,
  BuildByID,  
  getCampusById,
  getBuildingById,
  getFloorById,
  addBuildingForm,
  AddUnitbyFloor,
  getUnitById,
  Facility,
  getDeviceDetailsReq,
  getSiteAuthKeyReuest,
  getTechCount,  
  ZoneByID
} from "../technologyoverlay";
import {
  ShareddialogcloseData,
  SharedData,
  getUnit,
  MapSyncProcess,
  UnitById,
  getCampus,
  getBuilding,  
  getFacility,
  getZone,
  getSubZone
} from "../../../api";
import { TechnologyoverlayService } from "../technologyoverlay.service";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumRequestFrom,
  fillcolorPolygon,
  DeviceTypeDesc,
  NewDeviceTypeDesc,
  enumEnableDisable,
  DeviceType
} from "../../../app-settings";
import { AddCampusComponent } from "./campus/add-campus/add-campus.component";
import { Subject } from "rxjs";
import { EditCampusComponent } from "./campus/edit-campus/edit-campus.component";
import { getCampusdetsilsById } from "../technologyoverlay";
import { AddBuildingComponent } from "./building/add-building/add-building.component";
import { EditBuildingComponent } from "./building/edit-building/edit-building.component";
import { AddZoneComponent } from './zone/add-zone/add-zone.component';
import { EditZoneComponent } from './zone/edit-zone/edit-zone.component';
import { AddUnitComponent } from "./unit/add-unit/add-unit.component";
import { EditUnitComponent } from "./unit/edit-unit/edit-unit.component";
import { EditFacilityComponent } from "./facility/edit-facility/edit-facility.component";
import { AlertSharedService } from "../../../_services/index";
import { RollbarService } from "../../../rollbar";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  checkStaffData,
  generateAlertMessage,
  getFeatureNeedtoShow,
  AuthFailedMessage,
  set_SharedDetails,
  map_widthandheight,
  getCheckInExtent,
  LoadTechnologyBuildingSvg,
  LoadTechnologyFloorSvg,
} from "../../../app-general";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../../../api.service";
import { PerfectScrollbarComponent } from "ngx-perfect-scrollbar";
import { DOCUMENT } from "@angular/common";
import {
  fillcolor_style,
  label_Polygon,
  get_geojsonObject_floor,
  mapBuildTemplate_creation,
  get_geojsonObject_campus,
} from "../../../map-general";
import { Style, Fill, Stroke, Text } from "ol/style";
import { GeneralMessageComponent } from "../../../general-message/general-message.component";
import { AddTemplateComponent } from './zone/add-template/add-template.component';
import Draw from "ol/interaction/Draw";

import { HttpClient } from "@angular/common/http";

import "ol-popup/src/ol-popup.css";
import OlMap from "ol/Map";
import OlVectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";
import { DuplicateBuildingComponent } from "./building/duplicate-building/duplicate-building.component";
import { DuplicateZoneComponent } from './zone/duplicate-zone/duplicate-zone.component';
import { TemplateZoneComponent } from './zone/template-zone/template-zone.component';
import { TemplateUnitComponent } from "./unit/template-unit/template-unit.component";
import { DuplicateUnitComponent } from "./unit/duplicate-unit/duplicate-unit.component";
import { ManagesubZoneComponent } from './managesub-zone/managesub-zone.component';
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: "app-floorplans",
  templateUrl: "./floorplans.component.html",
  providers: [TechnologyoverlayService],
  styleUrls: ["./floorplans.component.scss"],
})
export class FloorplansComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  SharedDetails: SharedData;
  bAddEdit = false;
  bDelete = false;
  bAddEditTemplate = false;
  imageExtent;
  checkroomdrawend = false;
  Campus: Campus[];
  Building: Building[];
  Floor: Floor[];
  UnitInfo: Units[];
  SubZones: SubZones[];
  Room: Room[];
  Facility: Facility[];
  CampusById: getCampusById[];
  BuilddetailsById: getBuildingById[];
  FloorById: getFloorById[];
  UnitById: getUnitById[];

  buildingcampusname = "";
  buildingcampusfullname = "";
  floorbuildingname = "";
  floorbuildingfullname = "";
  unitfloorname = "";
  unitfloorfullname = "";
  unitsubzonename = '';
  unitsubzonefullname = '';
  roomunitname = "";
  roomunitfullname = "";

  selectedcampusName = "";
  selectedcampus = 0;
  selectedbuilding = 0;
  selectedfloor = 0;
  selectedsubzone = 0;
  selectedunit = 0;

  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  dialogResult = "";
  displayedColumnsRoom: string[] = [
    "DeviceType",
    "DeviceId",
    "LforIr",
    "MacId",
    "DeviceName",
  ];
  dataSourceRoom;

  campusImgUrl: string = appsettings.CAMPUS_IMAGE_FOLDER;
  floorImgUrl: string = appsettings.FLOOR_IMAGE_FOLDER;

  public watcher: Observable<boolean>;
  columns = 4;
  buildflag = 0;
  editflag = 0;
  formCampusId;
  addcampusid = 0;
  addbuildingid = 0;
  addfloorid = 0;
  svgimageurl = 0;
  ShareddialogcloseDetails: ShareddialogcloseData;
  FacilityImgurl: string = appsettings.Facility_IMAGE_FOLDER;
  FacilityName;
  campuseditdialogopen = 0;
  buildeditdialogopen = 0;
  flooreditdialogopen = 0;
  subzoneaddeditdialogopen = 0;
  uniteditdialogopen = 0;
  MapSyncProcess;
  editdialogfacilityopen = 0;

  campusimagename;
  campusaddImgUrl;
  validcampusimgurl;
  CampusCnt = 0;
  BuildingCnt = 0;
  FloorCnt = 0;
  SubZoneCnt = 0;
  UnitCnt = 0;
  Importflag = 0;
  importHeaders;
  getselectedmapdetails;

  imgSrc;
  clseditimg;

  collapsedIcon1 = "keyboard_arrow_down";
  collapsedIcon = "keyboard_arrow_down";

  collapsedIcon1_build = "keyboard_arrow_up";
  collapsedIcon_build = "keyboard_arrow_up";

  collapsedIcon1_floor = "keyboard_arrow_up";
  collapsedIcon_floor = "keyboard_arrow_up";

  collapsedIcon1_subzone = "keyboard_arrow_up";
  collapsedIcon_subzone = "keyboard_arrow_up";
  
  collapsedIcon1_unit = "keyboard_arrow_up";
  collapsedIcon_unit = "keyboard_arrow_up";

  checkicon;
  preId = -1;
  arrowiconT = true;
  arrowiconF = false;

  build_arrowiconT = true;
  build_arrowiconF = false;

  floor_arrowiconT = true;
  floor_arrowiconF = false;

  subzone_arrowiconT = true;
  subzone_arrowiconF = false;

  unit_arrowiconT = true;
  unit_arrowiconF = false;

  camp_isbuttonshow = 0;
  build_isbuttonshow = 0;
  floor_isbuttonshow = 0;
  subzone_isbuttonshow = 0;

  Building_Name;
  Campus_Name;
  Floor_Name;
  CampusFulldetails: any[];
  selectedBuildingdetails = {};
  selectedFloordetails = {};
  MCampusId = 0;
  Campus_key = "Campusinfo";
  Building_key = "Buildinginfo";
  Floor_key = "Floorinfo";
  selectedCampus = 0;
  selectedBuilding = 0;
  selectedFloor = 0;
  chkbuildmapflag = 0;
  devicefiltervalue_mapdetail;
  Map_array_length = 3;
  MBuildingId = 0;
  MFloorId = 0;

  ImageWidth = "";
  ImageHeight = "";
  addfloorimageheight = 0;
  ImageUrl = "";
  BackgroundIMG = "Centrak";
  CZoomLevel;
  CRotateLevel;
  TranslateX;
  TranslateY;
  SvgInfo = null;
  device_list_all = "3";
  CampusImageURL = "";
  BuildingImageURL = "";
  FloorImageURL = "";
  SvgImageUrl = "";
  campusName;
  FloorInfo;
  buildDuplicatedialogopen = 0;
  selected_floorId = 0;
  selected_buildId = 0;
  selected_campusId = 0;
  getMap_flag = 0;
  map = new OlMap();

  getbuildinginfo;
  getselectedCampus;
  FloorInfobyId;

  //Draw feature for Room
  vector = new OlVectorLayer();
  source = new VectorSource({ wrapX: false });
  vectorLayer = new OlVectorLayer({
    source: this.source,
  });
  draw = new Draw({
    source: this.source,
    type: "Polygon",
  });
  Map_image_path = "assets/images/";
  floorloading = false;
  authkey;
  _divCreateSpaceMenu;
  _divEditBuildingMenu;
  isZoneFloor = 0;
  addSubZoneData;
  selectedFloorId = 0;
  imageURL = appsettings.CAMPUS_IMAGE_FOLDER;
  
  @ViewChild(PerfectScrollbarComponent)
  componentRef : PerfectScrollbarComponent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("clkUnitfileInput") clkUnitfileInput: any;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
     // one dimensional input model
  items: Array<number> = Array.from({ length: 20 }, (v, k) => k + 1);
  // two dimensional table matrix representing view model
  itemsTable;

  // fix column width as defined in CSS (150px + 5px margin)
  boxWidth = 155;
  // calculated based on dynamic row width
  columnSize: number;
  columnSizeSubZone: number;
  columnreSize = 5;
  subzoneColumnreSize = 5;
  reorderSelectType = 0;
  
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(    
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    public http: HttpClient,
    private alertSharedService: AlertSharedService,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    @Inject(RollbarService) private rollbar: Rollbar,
    public mainLayoutService: MainLayoutService,
    private apiService: ApiService,
    public thisDialogRef: MatDialogRef<FloorplansComponent>,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      const _router = this.router.getCurrentNavigation();
      if(_router != null)
        this.getbuildinginfo = _router.extras.state;
      console.log("getbuildinginfo : ");
      console.log(this.getbuildinginfo);
      if (this.getbuildinginfo != undefined) {
        if (this.getbuildinginfo.FloorId == undefined)
          this.getBuildingDetails(
            this.getbuildinginfo.CampusId,
            this.getbuildinginfo.CampusName
          );
        else {
          this.getselectedCampus = this.getbuildinginfo.CampusId;
          this.getBuildingDetails(
            this.getbuildinginfo.CampusId,
            this.getbuildinginfo.CampusName
          );
          this.getZonedetails(
            this.getbuildinginfo.BuildId,
            this.getbuildinginfo.BuildingName
          );
        }
      }
      
      this.breakpoint$.subscribe(() => this.breakpointChanged());         
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - constructor() - " + err.message
      );
    }
  }
  
  breakpointChanged() {    
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Device_Map_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Templates_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEditTemplate = true;
    }
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      this.columns = 5;
      this.columnreSize = 5;  
      this.subzoneColumnreSize = 5;   
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium) || this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.columnreSize = 2;
      this.subzoneColumnreSize = 2;
    }       
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 1;
      this.columnreSize = 1;
      this.subzoneColumnreSize = 1;
    }     
  }
  
  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.clseditimg = "clseditimgout";
      this.chkbuildmapflag = 0;
      this.alertSharedService
        .currentSharedHomeMapdetails()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ShareMapDetails) => {
          this.getselectedmapdetails = ShareMapDetails;
        });
      if (
        this.SharedDetails == undefined &&
        this.getselectedmapdetails != undefined
      ) {
        set_SharedDetails(
          this.getselectedmapdetails.sel_CampusId,
          this.getselectedmapdetails.sel_BuildingId,
          this.getselectedmapdetails.sel_FloorId,
          this.getselectedmapdetails.sel_SubZoneId,
          "",
          "",
          "",
          this.alertSharedService
        );
      }

      this.ShareddialogcloseDetails = {
        SharedreturncloseVal: 0,
      };
      this.alertSharedService.changeclosedialog(this.ShareddialogcloseDetails);

      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Templates_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEditTemplate = true;
      }
      if (this.getbuildinginfo == undefined) {
        this.getTechCount(0, 0, 0, 0);
        this.preId = 0;
        this.clickcollapse_mapdetails(0, 1);
      }

      this.getFacilityInfo();
      this.getCampusDetails();

      this.getStaffById();
      this.scrollToBottom();
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }
  ngAfterViewChecked() {
    if (this.componentRef["interaction"] == true) {
      this.scrollToBottom();
    }
  }

  getTechView() {
    try {
      const countdata: getSiteAuthKeyReuest = {
        authKey: this.authkey,
      };
      this.technologyoverlayService
        .getTechView(countdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((countInfo: any[]) => {
          this.BuildingCnt = countInfo[1].CampusCount;
          this.FloorCnt = countInfo[2].CampusCount;
          this.UnitCnt = countInfo[3].CampusCount;
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - getTechView() - " + err.message
      );
    }
  }

  scrollToBottom() {
    try {
      if(this.componentRef.directiveRef != undefined)
      this.componentRef.directiveRef.scrollToBottom();
      this.componentRef["interaction"] = false;
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - scrollToBottom() - " + err.message
      );
    }
  }

  getStaffById() {
    checkStaffData(this.apiService, this.alertSharedService);
  }

  getFacilityInfo(): void {
    try {
      const facilitydata: getFacility = {
        authKey: this.authkey,
      };
      this.apiService
        .getFacilityInfo(facilitydata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((facilityInfo: any[]) => {
          if (facilityInfo != null) {
            if (facilityInfo["condition"] == "Authfalse") {
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
          }
          this.Facility = facilityInfo;
          this.FacilityName = this.Facility[0].FacilityName;
          this.componentRef["interaction"] = true;
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - getFacilityInfo() - " + err.message
      );
    }
  }

  getCampusDetails(): void {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Campusinfo: any[]) => {
          this.Campus = Campusinfo["apiReturn"];
          this.CampusCnt = this.Campus.length;
          this.componentRef["interaction"] = true;
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - getCampusDetails() - " + err.message
      );
    }
  }

  getBuildingDetails(campusId: number, campusName: string) {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      const getBuildingdata: getBuilding = {
        authKey: this.authkey,
        campusId: campusId,
      };
      
      this.getselectedCampus = campusId;
      if (this.getbuildinginfo != undefined) {
        this.getbuildinginfo.CampusId = campusId;
        this.getbuildinginfo.CampusName = campusName;
      }
      this.addcampusid = campusId;
      this.selectedcampus = campusId;
      this.selectedcampusName = campusName;
      this.selectedbuilding = 0;
      this.selectedfloor = 0;
      this.selectedunit = 0;

      this.getTechCount(this.selectedcampus, 0, 0, 0);
      this.selectCampusFull();

      if (campusName.length >= 15) {
        this.buildingcampusfullname = campusName;
        this.buildingcampusname = campusName.slice(0, 13) + "..";
      } else {
        this.buildingcampusfullname = "";
        this.buildingcampusname = campusName;
      }
      this.preId = 0;

      this.apiService
        .getBuildingDetails(getBuildingdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Buildinginfo: any[]) => {
          this.Building = Buildinginfo["apiReturn"];
          this.BuildingCnt = Buildinginfo["apiReturn"].length;
          this.componentRef["interaction"] = true;
        });

      this.clickcollapse_mapdetails(0, 2);
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - getBuildingDetails() - " + err.message
      );
    }
  }

  getZonedetails(buildId: number, buildingName: string) {
    try
    {
      let Campus_Id;
      let campusName;
        const buildbyid: getZone = {          
          authKey: this.authkey,
          campusId:this.addcampusid,
          buildId: buildId,
          floorId : 0
        }
        // let buildingbyname: BuildingByName = {
        //   BuildingName: buildingName
        // }
	
        if(this.getbuildinginfo != undefined)
        {
          this.getbuildinginfo.BuildId = buildId;
          this.getbuildinginfo.BuildingName = buildingName;
        }

        this.addbuildingid = buildId;
        this.selectedbuilding = buildId;                 
        
        if(this.getbuildinginfo == undefined)
          Campus_Id = this.selectedcampus;
        else
        {
          Campus_Id = this.getbuildinginfo.CampusId;
          campusName = this.getbuildinginfo.CampusName;
          if(campusName.length >= 15)
          {
            this.buildingcampusfullname = campusName;
            this.buildingcampusname = campusName.slice(0,13) + "..";
          }
          else
          {
            this.buildingcampusfullname = '';
            this.buildingcampusname = campusName;
          }          
        }
          
        this.getTechCount(parseInt(Campus_Id),this.selectedbuilding,0, 0);     
        
        if(buildingName.length >= 15)
        {
          this.floorbuildingfullname = buildingName;
          this.floorbuildingname = buildingName.slice(0,13) + "..";
        }
        else
        {
          this.floorbuildingfullname = "";
          this.floorbuildingname = buildingName;
        }
                
        this.selectedfloor = 0;
        this.selectedunit = 0;
        // this.selectedsubzone = 0;
        this.apiService.getZoneDetails(buildbyid).pipe(takeUntil(this._onDestroy))
          .subscribe((Floorinfo: any[]) => {            
            this.Floor = Floorinfo["apiReturn"];
            this.FloorCnt=Floorinfo["apiReturn"].length;            
            this.componentRef["interaction"]=true;
            this.columnreSize = 5;
            this.columnSize = 1; 
            this.getItemsTable(3);
          });
          
          this.preId = 0;
          this.clickcollapse_mapdetails(0,3);
    }
    catch (err) {
          throw new Error('floorplans.component.ts' + ' - getZonedetails() - ' + err.message);
        }           
  }
  
  getUnitorSubZoneDetails(floorData)
  {
    this.addSubZoneData = floorData;
    this.isZoneFloor = floorData.isZoneFloor;
    this.selectedFloorId = floorData.ZoneId;
    this.UnitInfo = [];
    if(floorData.isZoneFloor == 0)
    {
      this.getUnitDetails(floorData.ZoneId, floorData.ZoneName)
    }
    else
      this.getSubZonedetails(floorData.ZoneId, floorData.ZoneName)
  }
  
  getSubZonedetails(floorId: number, floorName: string) {
    try
    {
      const subzoneid: getSubZone = {        
        authKey: this.authkey,
        zoneId: floorId        
      }   
      if(this.getbuildinginfo != undefined)
      {
        this.getbuildinginfo.FloorId = floorId;
        this.getbuildinginfo.FloorName = floorName;
      }                
      this.selectedfloor = floorId;     
      this.addfloorid = floorId;  
      this.unitfloorname = floorName; 
      this.selected_floorId = floorId;   
          
      if(floorName.length >= 15)
      {
        this.unitfloorfullname = floorName;
        this.unitfloorname = floorName.slice(0,13) + "..";
      }
      else
      {
        this.unitfloorfullname = "";
        this.unitfloorname = floorName;
      }
      
      this.getTechCount(this.selectedcampus,this.selectedbuilding,this.selectedfloor,0);
      
      this.selectCampusFull();
      
      this.selectedsubzone = 0;
      this.selectedunit =0;
      this.technologyoverlayService.getSubZoneDetails(subzoneid).pipe(takeUntil(this._onDestroy))
        .subscribe((SubZonesinfo: any[]) => {
          this.SubZones = SubZonesinfo["apiReturn"];
          this.SubZoneCnt = SubZonesinfo["apiReturn"].length;
          this.componentRef["interaction"]=true;
          this.subzoneColumnreSize = 5;
          this.columnSizeSubZone = 1;
          this.getSubZoneItemsTable(4);
        });
        this.preId = 0;
        this.clickcollapse_mapdetails(0,4);
    }
    catch (err) {
      throw new Error('floorplans.component.ts' + ' - getSubZoneDetails() - ' + err.message);
    }  
  }

  getUnitDetails(floorId: number, floorName: string) {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const unitid: getUnit = {
        authKey: this.authkey,
        floorId: floorId,
        isIndoor: 1,
        isNeedIntercom :parseInt(voiceLicense),
        isSubZone : 0
      };

      if (this.getbuildinginfo != undefined) {
        this.getbuildinginfo.FloorId = floorId;
        this.getbuildinginfo.FloorName = floorName;
      }
      this.selectedfloor = floorId;
      this.addfloorid = floorId;
      this.unitfloorname = floorName;
      if (floorName.length >= 15) {
        this.unitfloorfullname = floorName;
        this.unitfloorname = floorName.slice(0, 13) + "..";
      } else {
        this.unitfloorfullname = "";
        this.unitfloorname = floorName;
      }

      this.getTechCount(
        this.selectedcampus,
        this.selectedbuilding,
        this.selectedfloor,
        this.selectedsubzone
      );
      this.selectCampusFull();

      this.selectedunit = 0;
      this.apiService
        .getUnitDetails(unitid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Unitsinfo: any[]) => {
          this.UnitInfo = Unitsinfo["apiReturn"];
          this.UnitCnt = Unitsinfo["apiReturn"].length;
          this.componentRef["interaction"] = true;
        });

      this.preId = 0;
      this.clickcollapse_mapdetails(0, 5);
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - getUnitDetails() - " + err.message
      );
    }
  }
  
  getSubZoneUnitDetails(subzonedata)
  {
    try
    {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const floorId = subzonedata.ZoneId;
      const floorName = subzonedata.ZoneName;
      const unitid: getUnit = {        
        authKey: this.authkey,
        floorId: subzonedata.SubZoneId,
        isIndoor:1,
        isNeedIntercom:parseInt(voiceLicense),
        isSubZone : 1
      }   
      if(this.getbuildinginfo != undefined)
      {
        this.getbuildinginfo.FloorId = floorId;
        this.getbuildinginfo.FloorName = floorName;
      }                
      this.selectedfloor = floorId;     
      this.addfloorid = floorId;  
      this.unitfloorname = floorName;   
      this.selectedsubzone = subzonedata.SubZoneId;     
      
      if(floorName.length >= 15)
      {
        this.unitfloorfullname = floorName;
        this.unitfloorname = floorName.slice(0,13) + "..";
      }
      else
      {
        this.unitfloorfullname = "";
        this.unitfloorname = floorName;
      }
      
      if(subzonedata.SubZoneDesc.length >= 15)
      {
        this.unitsubzonefullname = subzonedata.SubZoneDesc;
        this.unitsubzonename = subzonedata.SubZoneDesc.slice(0,13) + "..";
      }
      else
      {
        this.unitsubzonefullname = "";
        this.unitsubzonename = subzonedata.SubZoneDesc;
      }  
      
      this.getTechCount(this.selectedcampus,this.selectedbuilding,this.selectedfloor,this.selectedsubzone);
            
      this.selectedunit =0;
      this.apiService.getUnitDetails(unitid).pipe(takeUntil(this._onDestroy))
        .subscribe((Unitsinfo: any[]) => {
          this.UnitInfo = Unitsinfo["apiReturn"];
          this.UnitCnt = Unitsinfo.length["apiReturn"];
          this.componentRef["interaction"]=true;
        });
        
        this.preId = 0;
        this.clickcollapse_mapdetails(0,5);
    }
    catch (err) {
      throw new Error('floorplans.component.ts' + ' - getSubZoneUnitDetails() - ' + err.message);
    }  
  }

  getTechCount(CampusId: number, BuildId: number, FloorId: number, SubZoneId:number) {
    try {
      const mapcountdetails: getTechCount = {
        authKey: this.authkey,
        campusId: CampusId,
        buildingId: BuildId,
        zoneId: FloorId,
        subzoneId:SubZoneId
      };
      this.technologyoverlayService
        .getTechCount(mapcountdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Mapcountinfo: any[]) => {
          this.CampusCnt = Mapcountinfo[0].CampusCnt;
          this.BuildingCnt = Mapcountinfo[0].BuildingCnt;
          this.FloorCnt = Mapcountinfo[0].FloorCnt;
          this.UnitCnt = Mapcountinfo[0].UnitCnt;
          this.SubZoneCnt = Mapcountinfo[0].SubZoneCnt;
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - getTechCount() - " + err.message
      );
    }
  }

  /*********Opendialogwindow*******/
  addCampus(FacilityName) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const facilityname = {
              FacilityName: FacilityName,
              Campus: this.Campus,
            };
            const dialogRef = this.dialog.open(AddCampusComponent, {
              disableClose: true,
              width: "75em",
              height: "425px",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: facilityname,
            });

            dialogRef.afterClosed().subscribe(() => {
              this.floorplansEnableDisable(enumEnableDisable.Enable);
              this.getCampusDetails();
            });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - addCampus() - " + err.message
      );
    }
  }
  editFacilitydetails(
    FacilityId: number,
    FacilityName: string,
    FacilityImg: string
  ) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const Facilitydetails = {
              FacilityId: FacilityId,
              FacilityName: FacilityName,
              FacilityImg: FacilityImg,
            };
            const dialogRef = this.dialog.open(EditFacilityComponent, {
              disableClose: true,
              width: "800px",
              height: "370px",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: Facilitydetails,
            });
            dialogRef.afterClosed().subscribe(() => {
              this.floorplansEnableDisable(enumEnableDisable.Enable);
              this.getFacilityInfo();
            });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - editFacilitydetails() - " + err.message
      );
    }
  }

  editCampusdetails(CampusId, FacilityName) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            this.formCampusId = CampusId;
            const campusbyid: CampusByID = {
              authKey: this.authkey,
              campusId: parseInt(CampusId),
            };
            this.technologyoverlayService
              .getCampusById(campusbyid)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((CampusbyId: any[]) => {
                this.CampusById = CampusbyId["apiReturn"];
                this.editopendialog(this.CampusById, FacilityName);
              });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - editCampusdetails() - " + err.message
      );
    }
  }

  editopendialog(CampusByid, FacilityName) {
    try {
      const campusimage = CampusByid[0].FloorPlanImage.split(".");
      let _campussvgImage;
      let _campusimage;
      if (campusimage[1] == "svg" || campusimage[1] == "csv") {
        _campussvgImage = CampusByid[0].FloorPlanImage;
        _campusimage = "";
      } else {
        _campusimage = CampusByid[0].FloorPlanImage;
        _campussvgImage = "";
      }
      const campusdetails: getCampusdetsilsById = {
        CampusId: this.formCampusId,
        Location: CampusByid[0].Location,
        CampusName: CampusByid[0].CampusName,
        CampusPlanImage: _campusimage,
        CampusPlansvgImage: _campussvgImage,
        Description: CampusByid[0].Description,
        ZoomLevel: CampusByid[0].ZoomLevel,
        RotateLevel: CampusByid[0].RotateLevel,
        TransformX: CampusByid[0].TransformX,
        TransformY: CampusByid[0].TransformY,
        Facilityname: FacilityName,
      };
      const dialogRef = this.dialog.open(EditCampusComponent, {
        disableClose: true,
        width: "75em",
        height: "410px",
        panelClass: "EditCampusdialog",
        autoFocus: false,
        data: campusdetails,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.floorplansEnableDisable(enumEnableDisable.Enable);
        if (result == "delete") {
          this.selectedcampusName = "";
          this.selectedcampus = 0;
          this.selectedbuilding = 0;
          this.selectedfloor = 0;
          this.selectedunit = 0;
        }
        this.getCampusDetails();
      });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - editopendialog() - " + err.message
      );
    }
  }

  addBuilding() {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const addbuildingid: addBuildingForm = {
              CampusId: this.addcampusid,
              CampusName: this.buildingcampusname,
            };

            const dialogRef = this.dialog.open(AddBuildingComponent, {
              disableClose: true,
              width: "40em",
              height: "430px",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: addbuildingid,
            });

            dialogRef.afterClosed().subscribe((result) => {
              this.floorplansEnableDisable(enumEnableDisable.Enable);
              if (result != "Cancel") {
                const buildbyid: BuildByID = {
                  authKey: this.authkey,
                  buildId: parseInt(result),
                };

                this.technologyoverlayService
                  .getBuildingById(buildbyid)
                  .pipe(takeUntil(this._onDestroy))
                  .subscribe((BuildingbyId: any[]) => {
                    this.router.navigate(["../../mapbuilding"], {
                      state: BuildingbyId["apiReturn"][0],
                    });
                  });
              }
            });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - addBuilding() - " + err.message
      );
    }
  }

  editBuildingdetails(BuildingId) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }

      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const buildbyid: BuildByID = {
              authKey: this.authkey,
              buildId: parseInt(BuildingId),
            };

            this.technologyoverlayService
              .getBuildingById(buildbyid)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((BuildingbyId: any[]) => {
                this.BuilddetailsById = BuildingbyId["apiReturn"];
                this.buildeditopendialog(this.BuilddetailsById[0]);
              });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - editBuildingdetails() - " + err.message
      );
    }
  }

  buildeditopendialog(BuilddetailsById) {
    if (this.buildeditdialogopen == 0) {
      const CampusId = BuilddetailsById.CampusId;
      const CampusName = BuilddetailsById.CampusName;
      const dialogRef = this.dialog.open(EditBuildingComponent, {
        disableClose: true,
        width: "75em",
        height: "750px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: BuilddetailsById,
      });
      this.buildeditdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.buildeditdialogopen = 0;
        if (result != "Cancel") {
          this.floorplansEnableDisable(enumEnableDisable.Enable);
          this.getBuildingDetails(CampusId, CampusName);
        }
        this.floorplansEnableDisable(enumEnableDisable.Enable);
      });
    }
  }

  addFloor() {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }

      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const GeneralMessageData = {
              General_header: "Add Zone ",
              GeneralMessage: "I would like to start from:",
              General_first: "Scratch ",
              General_second: "Use Template",
            };
            const dialogRef = this.dialog.open(GeneralMessageComponent, {
              disableClose: true,
              width: "auto",
              height: "220px",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: GeneralMessageData,
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result == "First") {
                this.openaddFloordialog();
              }
              if (result == "Second") {
                this.openaddTemplatedialog();
              } else {
                dialogRef.close("Cancel");
                this.floorplansEnableDisable(enumEnableDisable.Enable);
                return;
              }
            });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - addFloor() - " + err.message
      );
    }
  }

  openaddFloordialog() {
    const addbuildfloorid: any = {
      CampusId: this.addcampusid,
      BuildId: this.addbuildingid,
      CampusName: this.buildingcampusname,
      BuildingName: this.floorbuildingname,
      floorvalue: 0,
      Floorlength: this.Building,
      FloorName : ""
    };

    const dialogRef = this.dialog.open(AddZoneComponent, {
      disableClose: true,
      width: "75em",
      height: "700px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: addbuildfloorid,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.floorplansEnableDisable(enumEnableDisable.Enable);
      this.getZonedetails(this.addbuildingid, this.floorbuildingname);

      if (result != "Cancel") {
        this.getFloorDetailsbyId(result);
      }
    });
  }

  getFloorDetailsbyId(floorid) {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(floorid),
    };

    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.selected_campusId = FloorByid["apiReturn"][0].CampusId;
        this.selected_buildId = FloorByid["apiReturn"][0].BuildId;
        this.selected_floorId = FloorByid["apiReturn"][0].FloorId;
        this.router.navigate(["../../mapview-crop"], { state: FloorByid["apiReturn"][0] });
      });
  }

  openaddTemplatedialog() {
    const addbuildfloorid: any = {
      CampusId: this.addcampusid,
      BuildId: this.addbuildingid,
      CampusName: this.buildingcampusname,
      BuildingName: this.floorbuildingname,
      floorvalue: 0,
      Floorlength: this.Building,
    };

    const dialogRef = this.dialog.open(AddTemplateComponent, {
      disableClose: true,
      width: "32em",
      height: "250px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: addbuildfloorid,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.floorplansEnableDisable(enumEnableDisable.Enable);
    });
  }

  editFloordetails(FloorId) {
    this.floorplansEnableDisable(enumEnableDisable.Disable);
    if (!SessionCheck(this.router)) {
      return;
    }

    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.MapSyncProcess = MapSyncinfo;
        if (this.MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          this.floorplansEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          this.formCampusId = FloorId;
          const floorbyid: ZoneByID = {
            authKey: this.authkey,
            zoneId: parseInt(FloorId),
          };

          this.technologyoverlayService
            .getZoneById(floorbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((FloorByid: any[]) => {
              this.FloorById = FloorByid["apiReturn"];
              this.editflooropendialog(this.FloorById);
            });
        }
      });
  }

  editflooropendialog(FloorById) {
    try {
      const floorimage = FloorById[0].FloorPlanImage.split(".");
      let _floorsvgImage;
      let _floorimage;
      if (floorimage[1] == "svg" || floorimage[1] == "csv") {
        _floorsvgImage = FloorById[0].FloorPlanImage;
        _floorimage = "";
      } else {
        _floorimage = FloorById[0].FloorPlanImage;
        _floorsvgImage = "";
      }
      const floordetails: any = {
        CampusId: FloorById[0].CampusId,
        CampusName: FloorById[0].CampusName,
        BuildingName: FloorById[0].BuildingName,
        BuildId: FloorById[0].BuildId,
        FloorPlanImage: _floorimage,
        FloorPlansvgImage: _floorsvgImage,
        Description: FloorById[0].Description,
        FloorId: FloorById[0].FloorId,
        FloorName: FloorById[0].FloorName,
        FZoomLevel: FloorById[0].FZoomLevel,
        FRotateLevel: FloorById[0].FRotateLevel,
        FTransformX: FloorById[0].FTransformX,
        FTransformY: FloorById[0].FTransformY,
        Notifications: FloorById[0].Notifications,
        isResidentCheckin: FloorById[0].isResidentCheckin,
        bgImageWidth: FloorById[0].bgImageWidth,
        bgImageHeight: FloorById[0].bgImageHeight,
        OriginalFloorId : FloorById[0].OriginalFloorId,
        isZoneFloor : FloorById[0].isZoneFloor  
      };
      if (this.flooreditdialogopen == 0) {
        const dialogRef = this.dialog.open(EditZoneComponent, {
          disableClose: true,
          width: "75em",
          height: "850px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: floordetails,
        });
        this.flooreditdialogopen = 1;
        dialogRef.afterClosed().subscribe(() => {
          this.flooreditdialogopen = 0;
          this.floorplansEnableDisable(enumEnableDisable.Enable);
          this.getZonedetails(this.addbuildingid, this.floorbuildingname);
        });
      }
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - editflooropendialog() - " + err.message
      );
    }
  }

  clkduplicateFloordetails(FloorId, FloorName) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }

      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const floorbyid: ZoneByID = {
              authKey: this.authkey,
              zoneId: parseInt(FloorId)
            };
            this.technologyoverlayService
              .getZoneById(floorbyid)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((FloorByid: any[]) => {
                this.FloorInfobyId = FloorByid["apiReturn"][0];

                if (this.bAddEdit == true) {
                  let GeneralMessageData = {
                    General_header: FloorName,
                    GeneralMessage: "I would like to:",
                    General_first: "Duplicate ",
                    General_second: "Save as Template",
                    Second_disable: "0",
                  };

                  if (this.bAddEditTemplate == false) {
                    GeneralMessageData = {
                      General_header: FloorName,
                      GeneralMessage: "I would like to:",
                      General_first: "Duplicate ",
                      General_second: "Save as Template",
                      Second_disable: "1",
                    };
                  }
                  const dialogRef = this.dialog.open(GeneralMessageComponent, {
                    disableClose: true,
                    width: "auto",
                    height: "220px",
                    panelClass: "myapp-no-padding-dialog",
                    autoFocus: false,
                    data: GeneralMessageData,
                  });
                  dialogRef.afterClosed().subscribe((result) => {
                    if (result == "First") {
                      this.openduplicateFloordialog(this.FloorInfobyId);
                    } else if (result == "Second") {
                      this.openTemplateFloordialog(this.FloorInfobyId);
                    } else {
                      dialogRef.close("Cancel");
                      this.floorplansEnableDisable(enumEnableDisable.Enable);
                      return;
                    }
                  });
                } else {
                  this.openTemplateFloordialog(this.FloorInfobyId);
                }
              });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" +
          " - clkduplicateFloordetails() - " +
          err.message
      );
    }
  }

  getfloorinfobyId(Floorid) {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(Floorid)
    };

    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.FloorInfobyId = FloorByid["apiReturn"][0];
      });
  }

  openduplicateFloordialog(Floordata) {
    try {
      const dialogRef = this.dialog.open(DuplicateZoneComponent, {
        disableClose: true,
        width: "75em",
        height: "500px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: Floordata,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.floorplansEnableDisable(enumEnableDisable.Enable);
        if (result > 0) {
          const floorbyid: ZoneByID = {
            authKey: this.authkey,
            zoneId: parseInt(result),
          };

          this.technologyoverlayService
            .getZoneById(floorbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((FloorByid: any[]) => {
              this.router.navigate(["../../mapfloor"], { state: FloorByid["apiReturn"][0] });
            });
        }
      });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" +
          " - openduplicateFloordialog() - " +
          err.message
      );
    }
  }

  openTemplateFloordialog(Floordata) {
    try {
      const dialogRef = this.dialog.open(TemplateZoneComponent, {
        disableClose: true,
        width: "35em",
        height: "640px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: Floordata,
      });
      dialogRef.afterClosed().subscribe(() => {
        this.floorplansEnableDisable(enumEnableDisable.Enable);
      });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" +
          " - openTemplateFloordialog() - " +
          err.message
      );
    }
  }

  addUnit() {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }

      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const addbuildfloorid: AddUnitbyFloor = {
              CampusId: this.addcampusid,
              BuildId: this.addbuildingid,
              FloorId: this.addfloorid,
              FloorName: this.unitfloorname,
            };

            const dialogRef = this.dialog.open(AddUnitComponent, {
              disableClose: true,
              width: "35em",
              height: "360px",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: addbuildfloorid,
            });

            dialogRef.afterClosed().subscribe(() => {
              this.floorplansEnableDisable(enumEnableDisable.Enable);
              this.getUnitDetails(this.addfloorid, this.unitfloorname);
            });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - addUnit() - " + err.message
      );
    }
  }

  editUnitdetails(UnitId) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }

      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const unitbyid: UnitById = {
              authKey: this.authkey,
              unitId: parseInt(UnitId),
            };

            this.technologyoverlayService
              .getUnitById(unitbyid)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((UnitByid: any[]) => {
                this.UnitById = UnitByid["apiReturn"][0];

                this.editunitopendialog(this.UnitById);
              });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - editUnitdetails() - " + err.message
      );
    }
  }

  editunitopendialog(UnitById) {
    if (this.uniteditdialogopen == 0) {
      const dialogRef = this.dialog.open(EditUnitComponent, {
        disableClose: true,
        width: "87em",
        height: "700px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: UnitById,
      });
      this.uniteditdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.uniteditdialogopen = 0;
        console.log("result editunitopendialog : " + result)
        this.floorplansEnableDisable(enumEnableDisable.Enable);
        if(result != 'Cancel')
        {
          if(result == 0)
            this.getUnitDetails(this.addfloorid, this.unitfloorname);
          else
            this.getUnitforSubZone(result);
        }
      });
    }
  }
  
  getUnitforSubZone(subZoneId)
  {
    const voiceLicense = localStorage.getItem("voice_license") ?? "";
    const unitid: getUnit = {        
      authKey: this.authkey,
      floorId: subZoneId,
      isIndoor:1,
      isNeedIntercom:parseInt(voiceLicense),
      isSubZone : 1
    }   
    this.apiService.getUnitDetails(unitid).pipe(takeUntil(this._onDestroy))
        .subscribe((Unitsinfo: any[]) => {
          this.UnitInfo = Unitsinfo["apiReturn"];
          this.UnitCnt = Unitsinfo.length["apiReturn"];
          this.componentRef["interaction"]=true;
        });
  }

  templateUnitdetails(unitId) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }

      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const unitbyid: UnitById = {
              authKey: this.authkey,
              unitId: parseInt(unitId),
            };

            this.technologyoverlayService
              .getUnitById(unitbyid)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((UnitByid: any[]) => {
                this.UnitById = UnitByid[0]["apiReturn"];
                this.templateunitopendialog(this.UnitById);
              });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - templateUnitdetails() - " + err.message
      );
    }
  }

  templateunitopendialog(unitinfo) {
    if (this.uniteditdialogopen == 0) {
      const dialogRef = this.dialog.open(TemplateUnitComponent, {
        disableClose: true,
        width: "35em",
        height: "640px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: unitinfo,
      });
      this.uniteditdialogopen = 1;
      dialogRef.afterClosed().subscribe(() => {
        this.uniteditdialogopen = 0;
        this.floorplansEnableDisable(enumEnableDisable.Enable);
      });
    }
  }

  duplicateUnitdetails(UnitId) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }

      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const unitbyid: UnitById = {
              authKey: this.authkey,
              unitId: parseInt(UnitId),
            };

            this.technologyoverlayService
              .getUnitById(unitbyid)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((UnitByid: any[]) => {
                this.UnitById = UnitByid["apiReturn"][0];
                this.duplicateunitopendialog(this.UnitById);
              });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - duplicateUnitdetails() - " + err.message
      );
    }
  }

  duplicateunitopendialog(UnitById) {
    if (this.uniteditdialogopen == 0) {
      const dialogRef = this.dialog.open(DuplicateUnitComponent, {
        disableClose: true,
        width: "75em",
        height: "500px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: UnitById,
      });
      this.uniteditdialogopen = 1;
      dialogRef.afterClosed().subscribe(() => {
        this.uniteditdialogopen = 0;
        this.floorplansEnableDisable(enumEnableDisable.Enable);
      });
    }
  }

  getCampusdetails(result: number) {
    console.log(result);
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  clickcollapse_mapdetails(val, Type) 
  {        
    this.checkicon = val;  
      if(Type == 1)
      {
        if(this.preId == 0)
        {
          this.collapsedIcon1 = "keyboard_arrow_down";
          this.arrowiconT = true;
          this.build_isbuttonshow = 0;
          this.floor_isbuttonshow = 0;
          this.subzone_isbuttonshow = 0;
        }
      }
      else if(Type == 2)
      {
        if(this.preId == 0)
        {
          this.collapsedIcon1_build = "keyboard_arrow_down";
          this.build_arrowiconT = true;
          this.build_isbuttonshow = 0;
          this.floor_isbuttonshow = 0;
          this.subzone_isbuttonshow = 0;
          
          this.camp_isbuttonshow = 1;
          this.collapsedIcon1 = "keyboard_arrow_up";
          this.arrowiconT = false;
        }  
      }
      else if(Type == 3)
      {
        if(this.preId == 0)
        {                
          this.camp_isbuttonshow = 1;        
          this.collapsedIcon1 = "keyboard_arrow_up";
          this.arrowiconT = false;
          
          this.build_isbuttonshow = 1;
          this.collapsedIcon1_build = "keyboard_arrow_up";
          this.build_arrowiconT = false;
          
          this.collapsedIcon1_floor = "keyboard_arrow_down";
          this.floor_arrowiconT = true;
          this.floor_isbuttonshow = 0;
          this.subzone_isbuttonshow = 0;
                
        } 
      }  
      else if(Type == 4)
      {
        if(this.preId == 0)
        {                
          this.camp_isbuttonshow = 1;        
          this.collapsedIcon1 = "keyboard_arrow_up";
          this.arrowiconT = false;
          
          this.build_isbuttonshow = 1;
          this.collapsedIcon1_build = "keyboard_arrow_up";
          this.build_arrowiconT = false;
          
          this.floor_isbuttonshow = 1;
          this.collapsedIcon1_floor = "keyboard_arrow_up";
          this.floor_arrowiconT = false;        
          
          this.collapsedIcon1_subzone = "keyboard_arrow_down";
          this.subzone_arrowiconT = true;    
          this.subzone_isbuttonshow = 0;                    
        } 
      }
      else if(Type == 5)
      {
        if(this.preId == 0)
        {                
          this.camp_isbuttonshow = 1;        
          this.collapsedIcon1 = "keyboard_arrow_up";
          this.arrowiconT = false;
          
          this.build_isbuttonshow = 1;
          this.collapsedIcon1_build = "keyboard_arrow_up";
          this.build_arrowiconT = false;
          
          this.floor_isbuttonshow = 1;
          this.collapsedIcon1_floor = "keyboard_arrow_up";
          this.floor_arrowiconT = false;
          
          this.subzone_isbuttonshow = 1;
          this.collapsedIcon1_subzone = "keyboard_arrow_up";
          this.subzone_arrowiconT = false;
                                 
          this.collapsedIcon1_unit = "keyboard_arrow_down";
          this.unit_arrowiconT = true;                
        } 
      }
  }

  clkdeselectmapitem(selType) 
  {
    if(selType == 1)
    {
      this.collapsedIcon1 = "keyboard_arrow_down";
      this.arrowiconT = true;
      
      this.collapsedIcon1_build = "keyboard_arrow_up";
      this.build_arrowiconT = false;
      this.buildingcampusname = '';
      this.camp_isbuttonshow = 0;
      this.build_isbuttonshow = 0;
      this.selectedbuilding = 0;
      
      this.collapsedIcon1_floor = "keyboard_arrow_up";
      this.floor_arrowiconT = false;
      this.floorbuildingname = '';
      this.floor_isbuttonshow = 0;
      
      this.collapsedIcon1_subzone = "keyboard_arrow_up";
      this.subzone_arrowiconT = false;
      this.unitfloorname = '';
      this.subzone_isbuttonshow = 0;
      
      this.collapsedIcon1_unit = "keyboard_arrow_up";
      this.unit_arrowiconT = false;
      this.unitfloorname = '';
      this.selectedcampusName = '';
      this.selectedcampus = 0;        
      this.selectedfloor = 0;
      this.selectedsubzone = 0;
      this.getCampusDetails();
      this.getTechCount(0,0,0,0)
      
    }
    else if(selType == 2)
    {
      this.collapsedIcon1 = "keyboard_arrow_up";
      this.arrowiconT = false;
      
      this.collapsedIcon1_build = "keyboard_arrow_down";
      this.build_arrowiconT = true;
      this.build_isbuttonshow = 0;
      
      this.collapsedIcon1_floor = "keyboard_arrow_up";
      this.floor_arrowiconT = false;
      this.floorbuildingname = '';
      this.floor_isbuttonshow = 0;
      this.subzone_isbuttonshow = 0;
      this.selectedbuilding = 0;
      
      this.collapsedIcon1_subzone = "keyboard_arrow_up";
      this.subzone_arrowiconT = false;
      this.unitfloorname = '';
      this.selectedfloor = 0; 
      this.selectedsubzone = 0;
      
      this.collapsedIcon1_unit = "keyboard_arrow_up";
      this.unit_arrowiconT = false;
      this.unitfloorname = '';
      this.selectedfloor = 0; 
      this.selectedsubzone = 0;
      this.getBuildingDetails(this.selectedcampus,this.selectedcampusName);         
    }
    else if(selType == 3)
    {
      this.collapsedIcon1 = "keyboard_arrow_up";
      this.arrowiconT = false;
      
      this.collapsedIcon1_build = "keyboard_arrow_up";
      this.build_arrowiconT = false;    
      
      this.collapsedIcon1_floor = "keyboard_arrow_down";
      this.floor_arrowiconT = true;  
      this.floor_isbuttonshow = 0; 
      this.subzone_isbuttonshow = 0;
      this.unitfloorname = '';
      
      this.collapsedIcon1_subzone = "keyboard_arrow_up";
      this.subzone_arrowiconT = false;
      this.unitfloorname = '';
      this.selectedfloor = 0; 
      this.selectedsubzone = 0;
      
      this.collapsedIcon1_unit = "keyboard_arrow_up";    
      this.unit_arrowiconT = false;
      this.selectedfloor = 0;   
      this.selectedsubzone = 0;  
      this.columnSize = 1;   
      this.columnSizeSubZone = 1;   
      this.getZonedetails(this.addbuildingid,this.floorbuildingname)
    }
    else if(selType == 4)
    {                    
      this.collapsedIcon1 = "keyboard_arrow_up";
      this.arrowiconT = false;
      
      this.collapsedIcon1_build = "keyboard_arrow_up";
      this.build_arrowiconT = false;    
      
      this.collapsedIcon1_floor = "keyboard_arrow_up";
      this.floor_arrowiconT = false;     
      
      this.collapsedIcon1_subzone = "keyboard_arrow_down";
      this.subzone_arrowiconT = true;
      // this.unitfloorname = '';    
      this.selectedsubzone = 0;
      
      this.collapsedIcon1_unit = "keyboard_arrow_up";    
      this.unit_arrowiconT = false;
      this.selectedfloor = 0;   
      this.selectedsubzone = 0; 
      this.columnSizeSubZone = 1;        
      this.getSubZonedetails(this.selected_floorId,this.unitfloorname)
      
    }
    else if(selType == 5)
    {
      this.collapsedIcon1 = "keyboard_arrow_up";
      this.arrowiconT = false;
      this.buildingcampusname = '';
      
      this.collapsedIcon1_build = "keyboard_arrow_up";
      this.build_arrowiconT = false;    
      this.floorbuildingname = '';
      
      this.collapsedIcon1_floor = "keyboard_arrow_up";
      this.floor_arrowiconT = false;    
      this.unitfloorname = '';
      
      this.collapsedIcon1_subzone = "keyboard_arrow_up";
      this.subzone_arrowiconT = false;
      this.unitfloorname = '';
      this.selectedfloor = 0; 
      this.selectedsubzone = 0;
      
      this.collapsedIcon1_unit = "keyboard_arrow_down";
      this.unit_arrowiconT = true;
      
      this.getTechView();
    }
  }

  duplicateBuildingdetails(BuildingId) {
    try {
      this.floorplansEnableDisable(enumEnableDisable.Disable);
      if (!SessionCheck(this.router)) {
        return;
      }
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            this.floorplansEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            const buildbyid: BuildByID = {
              authKey: this.authkey,
              buildId: parseInt(BuildingId),
            };

            this.technologyoverlayService
              .getBuildingById(buildbyid)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((BuildingbyId: any[]) => {
                this.BuilddetailsById = BuildingbyId["apiReturn"];
                this.buildDuplicateopendialog(this.BuilddetailsById);
              });
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - editBuildingdetails() - " + err.message
      );
    }
  }

  buildDuplicateopendialog(BuildingData) {
    const duplicateData = { BuildingData: BuildingData, CampusInfo: this.Campus };
    if (this.buildDuplicatedialogopen == 0) {
      const dialogRef = this.dialog.open(DuplicateBuildingComponent, {
        disableClose: true,
        width: "75em",
        height: "500px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: duplicateData,
      });
      this.buildDuplicatedialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.buildDuplicatedialogopen = 0;
        this.floorplansEnableDisable(enumEnableDisable.Enable);
        if (result > 0) {
          const buildbyid: BuildByID = {
            authKey: this.authkey,
            buildId: parseInt(result),
          };

          this.technologyoverlayService
            .getBuildingById(buildbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((BuildingbyId: any[]) => {
              this.router.navigate(["../../mapbuilding"], {
                state: BuildingbyId["apiReturn"][0],
              });
            });
        }
      });
    }
  }  

  checkdrawroomflag = 0;
  drawroomselect;
  Buildingsvgid = 0;
  Buildingdetails: any[];

  select = null;
  modify = null;
  translate = null;
  select_Feature;
  selectName_Feature;
  selectedFeature;
  selectedNameFeature;
  //Draw feature for Devices
  tempVectorSource = new VectorSource();

  DrawFeature(DrawFeature_Type, geometryFunction) {
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;

    this.map.removeInteraction(this.draw);
    let drawRoomSource = this.source;
    function onDrawStart() {
      //remove everything drawn previously
      drawRoomSource.clear();
    }

    if (geometryFunction != null) {
      this.draw = new Draw({
        source: this.source,
        type: DrawFeature_Type,
        geometryFunction: geometryFunction,
        //freehand: true,
      });
    } else {
      this.draw = new Draw({
        source: this.source,
        type: DrawFeature_Type,
        geometryFunction: geometryFunction,
        freehand: true,
      });
    }

    this.draw.on("drawstart", onDrawStart);

    drawRoomSource = this.source;

    this.vector = new OlVectorLayer({
      source: this.source,
    });
    this.vector.set("extent", this.imageExtent);

    this.map.addInteraction(this.draw);
    this.map.addLayer(this.vector);

    // Get the array of features
    const features = this.vector.getSource().getFeatures();
    if (features != undefined) {
      // Go through this array and get coordinates of their geometry.
      //features.forEach();
    }

    let checkdraw;
    this.draw.on("drawend", function () {
      checkdraw = true;
    });
    this.checkroomdrawend = checkdraw;
  }

  //load Building list
  selectbuilding() {
    try {
      const CampusId = this.addcampusid;
      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: CampusId,
      };
      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          if (BuildingDetails != null && BuildingDetails != undefined) {
            this.Buildingdetails = BuildingDetails["apiReturn"];
            this.selectedBuilding = this.Buildingdetails[0].BuildId;
            this.Buildingsvgid = this.Buildingdetails[0].svgId;
          }
        });
    } catch (err) {
      throw new Error(
        "floorplans.component.ts" + " - selectbuilding() - " + err.message
      );
    }
  }

  selectCampusFull() {
    try {
      this.floorloading = true;
      const campusid: CampusByID = {
        authKey: this.authkey,
        campusId: this.selectedcampus,
      };

      let item;
      this.apiService
        .getCampusbyCampusId(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusFulldetails: any[]) => {
          this.CampusFulldetails = CampusFulldetails;
          const mapdetail =
            this.selectedcampus +
            "_" +
            this.selectedbuilding +
            "_" +
            this.selectedfloor;
          this.CampusFulldetails["SelectedMapInfo"] = mapdetail;
          item = { MapDetails: this.CampusFulldetails };
          this.getMapDetails(item);
        });
    } catch (err) {
      throw new Error(
        "mapview.component.ts - selectCampusFull() - " + err.message
      );
    }
  }

  getMapDetails(MapDetails): void {
    let arrMapDetails = "";
    let BulidingId;
    let FloorId;
    let CampusId;
    if (MapDetails.MapDetails.SelectedMapInfo != "0_0_0") {
      arrMapDetails = MapDetails.MapDetails.SelectedMapInfo.split("_");
      this.CampusFulldetails = MapDetails.MapDetails;
      if (arrMapDetails[2] == "0") {
        CampusId = arrMapDetails[0];
        if (this.CampusFulldetails["Campusinfo"].length == 0) {
          this.floorloading = false;
        }
        for (
          let info = 0;
          info <= this.CampusFulldetails["Campusinfo"].length - 1;
          info++
        ) {
          if (
            this.CampusFulldetails["Campusinfo"][info]["CampusId"] == CampusId
          ) {
            this.CampusImageURL =
              this.CampusFulldetails["Campusinfo"][info]["FloorPlanImage"];
            this.ImageWidth =
              this.CampusFulldetails["Campusinfo"][info]["ImageWidth"];
            this.ImageHeight =
              this.CampusFulldetails["Campusinfo"][info]["ImageHeight"];

            this.ImageUrl =
              appsettings.CAMPUS_IMAGE_FOLDER + "/" + this.CampusImageURL;
            this.SvgInfo =
              this.CampusFulldetails["Campusinfo"][info]["Campusdetail"];
            this.CZoomLevel =
              this.CampusFulldetails["Campusinfo"][info]["ZoomLevel"];
            this.CRotateLevel =
              this.CampusFulldetails["Campusinfo"][info]["RotateLevel"];
            this.TranslateX =
              this.CampusFulldetails["Campusinfo"][info]["TransformX"];
            this.TranslateY =
              this.CampusFulldetails["Campusinfo"][info]["TransformY"];
            this.BackgroundIMG = "";
            this.floorloading = false;
            break;
          }
        }
      } else {
        CampusId = arrMapDetails[0];
        BulidingId = arrMapDetails[1];
        FloorId = parseInt(arrMapDetails[2]);

        if (this.CampusFulldetails["Campusinfo"].length == 0) {
          this.floorloading = false;
        }

        for (
          let info = 0;
          info <= this.CampusFulldetails["Campusinfo"].length - 1;
          info++
        ) {
          if (
            this.CampusFulldetails["Campusinfo"][info]["CampusId"] == CampusId
          ) {
            for (
              let build = 0;
              build <=
              this.CampusFulldetails["Campusinfo"][info]["Campusdetail"]
                .length -
                1;
              build++
            ) {
              if (
                this.CampusFulldetails["Campusinfo"][info].Campusdetail[build]
                  .buildingid == BulidingId
              ) {
                for (
                  let floor = 0;
                  floor <=
                  this.CampusFulldetails["Campusinfo"][info].Campusdetail[build]
                    .floors.length -
                    1;
                  floor++
                ) {
                  if (
                    this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                      build
                    ].floors[floor].floorid == FloorId
                  ) {
                    this.FloorImageURL =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FloorPlanImage;
                    this.ImageWidth =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].ImageWidth;
                    this.ImageHeight =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].ImageHeight;
                    this.CZoomLevel =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FZoomLevel;
                    this.CRotateLevel =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FRotateLevel;
                    this.TranslateX =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FTranslateX;
                    this.TranslateY =
                      this.CampusFulldetails["Campusinfo"][info].Campusdetail[
                        build
                      ].floors[floor].FTranslateY;
                    this.ImageUrl =
                      appsettings.FLOOR_IMAGE_FOLDER + "/" + this.FloorImageURL;
                    this.SvgInfo = null;
                    this.FloorInfo = null;
                    this.getDeviceDetails();
                    break;
                  }
                }
                break;
              }
            }
            this.floorloading = false;
            break;
          }
        }
      }
    }
  }

  getDeviceDetails() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.MCampusId,
        floorId: this.selectedfloor,
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: 0,
        isIndoor: 1,
        isNeedRoomonly: 0,
        isNeedIntercom: parseInt(voiceLicense)
      };

      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          if (DeviceDetails != null && DeviceDetails != undefined) {
            if (DeviceDetails["apiReturn"] == "Invalid Auth Key") {
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            DeviceDetails["apiReturn"].forEach(function (e) {
              if (e.DeviceName == DeviceTypeDesc.Emitter) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
              } else if (e.DeviceName == DeviceTypeDesc.HRex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
              } else if (e.DeviceName == DeviceTypeDesc.Rex) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
              } 
              else if (e.DeviceName == DeviceTypeDesc.LF) {
                if (e.SubType==DeviceType.DripTray)
                 {
                   e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
                 }
                 else
                 {
                e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
                }
              }
              else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
              } else if (
                e.DeviceName == DeviceTypeDesc.DC ||
                e.DeviceName == DeviceTypeDesc.NewDC
              ) {
                e["NewDeviceName"] = NewDeviceTypeDesc.DC;
              } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
              } else if (e.DeviceName == DeviceTypeDesc.VW) {
                e["NewDeviceName"] = NewDeviceTypeDesc.VW;
              } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
                e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
              } else if (e.DeviceName == DeviceTypeDesc.UT) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UT;
              } else if (e.DeviceName == DeviceTypeDesc.UM) {
                e["NewDeviceName"] = NewDeviceTypeDesc.UM;
              } else {
                e["NewDeviceName"] = NewDeviceTypeDesc.Zone;
              }
            });

            this.FloorInfo = DeviceDetails["apiReturn"];
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - getDeviceDetails() - " + err.message
      );
    }
  }

  DrawMapCall() {
    try {      
      for (let idx_SvgInfo = 0;idx_SvgInfo <= this.SvgInfo.length - 1;idx_SvgInfo++) {
        const buildingid = this.SvgInfo[idx_SvgInfo].buildingid;
        //Map Empty
        const mapId = "mapBuild_" + buildingid;
        //Map Empty
        const element = document.getElementById(mapId);
        if(element != null)
          element.innerHTML = "";

        const imgwh = map_widthandheight(this.ImageWidth, this.ImageHeight);
        const imgwidth = imgwh.ImageWidth;
        const imgheight = imgwh.ImageHeight;

        // Polygons style
        const labelStyle = label_Polygon(0);
        const fillcolorStyle = fillcolor_style(fillcolorPolygon.Incomplete);
        const style = [fillcolorStyle, labelStyle];
        
        const _offsetWidth = element?.offsetWidth;
        const _offsetHeight = element?.offsetHeight;

        this.map = mapBuildTemplate_creation(
          this.ImageUrl,
          imgwidth,
          imgheight,
          true,
          this.BackgroundIMG,
          this.CZoomLevel,
          this.CRotateLevel,
          _offsetWidth,
          _offsetHeight,
          this.TranslateX,
          this.TranslateY,
          mapId
        );
        this.map.getControls().removeAt(0);
        let translateX = this.TranslateX;
        let translateY = this.TranslateY;

        translateX = translateX / 122;
        translateY = translateY / 127;

        translateX = translateX * imgwidth;
        translateY = translateY * imgheight;

        const _map = this.map;
        const MapCenter = _map.getView().getCenter();

        MapCenter[0] -= translateX;
        MapCenter[1] += translateY;

        _map.on("moveend", function (e) {
          getCheckInExtent(e, _map, imgwidth, imgheight, MapCenter);
        });
        const arrmarkerName = [];
        if (this.SvgInfo != null) {
          const geojsonObject = get_geojsonObject_campus(
            enumRequestFrom.floorplan_page
          );
          geojsonObject.type = "FeatureCollection";

          
          geojsonObject.features.length = 0;
          if (this.SvgInfo.length > 0) {
            const arrSvgInfo = this.SvgInfo;
            LoadTechnologyBuildingSvg(
              this.map,
              this.selectedcampus,
              arrSvgInfo,
              imgheight,
              arrmarkerName,
              geojsonObject,
              fillcolorStyle,
              labelStyle,
              style,
              buildingid
            );
          } else {
            this.map.getView().setZoom(0);
          }
        }
      }    
    } catch (err) {
      throw new Error("mapview.component.ts - DrawMapCall() - " + err.message);
    }
  }

  DrawFloorMapCall() {
    try {
      for (
        let idx_FloorInfo = 0;
        idx_FloorInfo <= this.UnitInfo.length - 1;
        idx_FloorInfo++
      ) {
        const UnitId = this.UnitInfo[idx_FloorInfo].UnitId;
        let Space_FloorInfo = [] as any[];
        this.FloorInfo.forEach(function (data) {
          if (data["UnitId"] == UnitId) {
            Space_FloorInfo = [data];
            return false;
          } else {
            return true;
          }
        });

        const mapId = "mapSpace_" + UnitId;
        //Map Empty
        const element = document.getElementById(mapId);
        if(element != null)
          element.innerHTML = "";
        const imgwh = map_widthandheight(this.ImageWidth, this.ImageHeight);
        const imgwidth = imgwh.ImageWidth;
        const imgheight = imgwh.ImageHeight;

        // Polygons style        
        const devicelabelStyle = new Style({
          text: new Text({
            font: "10px Poppins, sans-serif",
            overflow: true,
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 5,
            }),
            offsetX: 0,
            offsetY: 25.5,
          }),
        });

        const deviceStyle = fillcolor_style(fillcolorPolygon.Completed);
        const fillStyle = [deviceStyle, devicelabelStyle];
        const _offsetWidth = element?.offsetWidth;
        const _offsetHeight = element?.offsetHeight;

        this.map = mapBuildTemplate_creation(
          this.ImageUrl,
          imgwidth,
          imgheight,
          true,
          this.BackgroundIMG,
          this.CZoomLevel,
          this.CRotateLevel,
          _offsetWidth,
          _offsetHeight,
          this.TranslateX,
          this.TranslateY,
          mapId
        );
        this.map.getControls().removeAt(0);
        let translateX = this.TranslateX;
        let translateY = this.TranslateY;

        translateX = translateX / 122;
        translateY = translateY / 127;

        translateX = translateX * imgwidth;
        translateY = translateY * imgheight;

        const _map = this.map;
        const MapCenter = _map.getView().getCenter();

        MapCenter[0] -= translateX;
        MapCenter[1] += translateY;

        _map.on("moveend", function (e) {
          getCheckInExtent(e, _map, imgwidth, imgheight, MapCenter);
        });

        //////////Floor Devices Start////////////

        const arrdevicemarker = [];
        const arrdevicemarkernoPoly = [];
        if (Space_FloorInfo != null) {
          /// Geo Feature Collection ///
          const geoFloorjsonObject = get_geojsonObject_floor(
            enumRequestFrom.floorplan_page
          );
          geoFloorjsonObject.type = "FeatureCollection";

          
          geoFloorjsonObject.features.length = 0;

          /// marker array ///
          
          if (Space_FloorInfo.length > 0) {
            const arrFloorInfo = Space_FloorInfo;
            LoadTechnologyFloorSvg(
              this.map,
              arrFloorInfo,
              imgheight,
              imgwidth,
              arrdevicemarker,
              geoFloorjsonObject,
              arrdevicemarkernoPoly,
              devicelabelStyle,
              fillStyle,
              this.Map_image_path,
              this.selectedfloor,
              1
            );
          } else if (this.FloorImageURL == null) {
            const _map = document.getElementById("map");
            if (_map != null)
              _map.innerHTML =
                "<span style='margin-top: 15%; padding-left: 45%;display:block;font-size:15px;font-weight:bold;'> Map Not Found.</span>";
          } else {
            this.map.getView().setZoom(0);
          }
        }
        //////////Floor Devices End////////////
      }
    } catch (err) {
      throw new Error(
        "floorplans.component.ts - DrawFloorMapCall() - " + err.message
      );
    }
  }

  floorplansEnableDisable(checkflag) {
    const elementcontent = document.getElementById("divfloorplandetails");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsenable";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsdisable";
  }
  
  addEditSubZonedetails(FloorId,subZoneid,subZonename,type)
  {   
    try
    {    
      const floorplanslistelement = document.getElementById('divfloorplandetails');
      if(floorplanslistelement != null)
          floorplanslistelement.className = 'clsdisable';  
      if(!SessionCheck(this.router))
      {
          return;
      }
      const _dialog = this.dialog;
      const mapsyncinfo : MapSyncProcess = 
      {        
        authKey: this.authkey
      };
      this.apiService.checkMapSync(mapsyncinfo).pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => { 
        this.MapSyncProcess = MapSyncinfo  
        if(this.MapSyncProcess == 1)
        {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg); 
          if(floorplanslistelement != null)          
            floorplanslistelement.className = 'clsenable'; 
          return false;
        }          
        else
        {      
          if(FloorId == 0)
            FloorId = this.selectedFloorId;
            
            this.formCampusId = FloorId;
            const floorbyid: ZoneByID = {              
              authKey: this.authkey,
              zoneId: FloorId
            }

            this.technologyoverlayService.getZoneById(floorbyid).pipe(takeUntil(this._onDestroy))
              .subscribe((FloorByid : any[])=>{    
                this.FloorById = FloorByid["apiReturn"][0];   
                const zonedata: any = {          
                  buildName : this.floorbuildingname,
                  campusName : this.buildingcampusname,
                  subZonedata : this.FloorById,                 
                  subZoneId : subZoneid,
                  subZoneName : subZonename,
                  floorImageWidth:FloorByid["apiReturn"][0].bgImageWidth,
                  floorImageHeight: FloorByid["apiReturn"][0].bgImageHeight,
                }  
                this.addEditSubZoneopendialog(zonedata,type)
            });                      
        }
      })
    }
    catch (err) {
      throw new Error('floorplans.component.ts' + ' - addEditSubZonedetails() - ' + err.message);
    } 
    
  }
  
  addEditSubZoneopendialog(zoneData,type)
  {
    if(this.subzoneaddeditdialogopen == 0)
    {
      const dialogRef = this.dialog.open(ManagesubZoneComponent, {
        disableClose: true,
        width: '75em',
        height:'780px',
        panelClass: 'myapp-no-padding-dialog',
        autoFocus: false,
        data:zoneData
      });
      this.subzoneaddeditdialogopen = 1;
      dialogRef.afterClosed().subscribe(result => {    
        this.subzoneaddeditdialogopen = 0;
        const floorplanslistelement = document.getElementById('divfloorplandetails');
        if(floorplanslistelement != null)
            floorplanslistelement.className = 'clsenable'; 
          if(result != "Cancel" && result != undefined && type == 1)
            this.getZonedetails(this.addbuildingid,this.floorbuildingname);
          else if(result != "Cancel" && result != undefined && type == 2)
            this.getSubZonedetails(this.addfloorid,this.unitfloorname);
      })
    }
  }
  
  getItemsTable(Type): string[][] {
    // calculate column size per row
    // const { width } = rowLayout.getBoundingClientRect();
    let columnSizeFloor = this.columnreSize;//Math.round(width / this.boxWidth);
    // view has been resized? => update table with new column size
    this.reorderSelectType = Type;
    if (columnSizeFloor != this.columnSize) {
      this.columnSize = columnSizeFloor;
      this.initTable();
    }
    else if(Type == 1)
      this.initTable();
    columnSizeFloor = 2;     
    return this.itemsTable;
  }
  
  getSubZoneItemsTable(Type): string[][] {
    // calculate column size per row
    // const { width } = rowLayout.getBoundingClientRect();
    let columnSizeSub = this.subzoneColumnreSize;//Math.round(width / this.boxWidth);
    // view has been resized? => update table with new column size
    this.reorderSelectType = Type;
    if (columnSizeSub != this.columnSizeSubZone) {
      this.columnSizeSubZone = columnSizeSub;
      this.initTable();
    }
    else if(Type == 1)
      this.initTable();
    columnSizeSub = 2;  
    return this.itemsTable;
  }
  
  initTable() {
    // create table rows based on input list
    // example: [1,2,3,4,5,6] => [ [1,2,3], [4,5,6] ]
    let getSelectData;
    if(this.reorderSelectType == 3)
      getSelectData = this.Floor;
    else if(this.reorderSelectType == 4)
      getSelectData = this.SubZones;
     
      this.itemsTable=[];
      const nTot=(getSelectData.length /this.columnSize );
       
     for (let rowIndex=0;rowIndex<=nTot;rowIndex++)      
     {      
      if (rowIndex == 0)
      {
        this.itemsTable.push(getSelectData.slice(0,(this.columnSize - 1)))
      }
      else 
      {
        this.itemsTable.push(
          getSelectData.slice(
          (rowIndex * this.columnSize)- 1, // ... row start and
          (rowIndex * this.columnSize + this.columnSize ) - 1// ...row end
         ));
      }      
     }      
  }
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }


  reorderDroppedItem(event: CdkDragDrop<string[]>,type) {   
     // same row/container? => move item in same row
     if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      // different rows? => transfer item from one to another list
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    let data;
    if (type == 3)
      data = this.Floor;
    if(type == 4)  
      data = this.SubZones;
    // update items after drop: flatten matrix into list
    // example: [ [1,2,3], [4,5,6] ] => [1,2,3,4,5,6]
    data = this.itemsTable.reduce(
      (previous, current) => previous.concat(current),
      []
    );
      
    if (type == 3)
      this.Floor = data;
    if(type == 4)  
      this.SubZones = data;
      
    this.initTable();
    this.saveReorderDroppedItem(data,type);
  }
  
  
  saveReorderDroppedItem(dropedData,type)
  {
    const reorderData = [] as any[];
    let dataId;
    let displayOrder;
    let campusId;
    let buildingId;
    for(let nidx = 0; nidx < dropedData.length; nidx++)
    {
      const order = (nidx) + 1;
      if(this.reorderSelectType == 3)
        dataId = dropedData[nidx].ZoneId;
      else if(this.reorderSelectType == 4)
        dataId = dropedData[nidx].SubZoneId;
      displayOrder = order;
      campusId = dropedData[nidx].CampusId;
      buildingId = dropedData[nidx].BuildId;
      console.log(campusId,buildingId)
      reorderData.push({'dataId' : dataId, 'DisplayOrder' : displayOrder });      
    }
    console.log(reorderData)
    
    const reordersdata : any = 
        {           
          authKey: localStorage.getItem("Authkey"), 
          reorderData: reorderData,
          ordertype:type
        }; 
        this.technologyoverlayService.saveReorderDropData(reordersdata).pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {    
          console.log(apiresponse)      
        })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
