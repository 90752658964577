import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ApiService } from "../api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertSharedService } from "../_services/index";
import { RollbarService } from "../rollbar";
import * as Rollbar from "rollbar";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MainLayoutService } from "../main-layout/main-layout.service";
import {
  AppComponent,
  changedHTTPRequest,
  closealertpushSocketConForUpdates,
} from "../app.component";
import { getFeatureNeedtoShow } from "../app-general";
import { enumUIFeatureView, enumUIAction, enumUIView } from "../app-settings";
import { SidenavComponent } from "../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { DatePipe } from "@angular/common";
import { UserIdleService } from "angular-user-idle";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  userinfo: any = [];
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    public Api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private alertSharedService: AlertSharedService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public mainLayoutService: MainLayoutService,
    private userIdle: UserIdleService,
    private datePipe: DatePipe
  ) {}

  username: string;
  password: string;
  errorflag = false;
  emptyflag = false;
  flagUsername = false;
  flagPassword = false;
  visible: boolean;
  passwordIcon;
  From_Date;
  To_Date;

  ngOnInit() {
    const elementhome = document.getElementsByClassName("card-container-right");
    const style = getComputedStyle(elementhome[0]);
    if (style.display == "none") {
      elementhome[0]["style"].display = "block";
      elementhome[0]["style"].width = "100%";
    }
    this.visible = false;
    this.passwordIcon = "assets/images/hideeye.png";
  }
  checkuser(): void {
    try {
      this.errorflag = false;
      this.emptyflag = false;
      this.flagUsername = false;
      this.flagPassword = false;
      if (this.username != "" && this.password != "") {
        this.login();
      } else if (this.username == "") this.flagUsername = true;
      else if (this.password == "") this.flagPassword = true;
      else {
        this.emptyflag = true;
      }
    } catch (err) {
      throw new Error("login.component.ts" + " - checkuser() - " + err.message);
    }
  }
  keyupevent() {
    this.errorflag = false;
    this.emptyflag = false;
    this.flagUsername = false;
    this.flagPassword = false;
  }
  /////////Show password////////
  clickloginpassword() {
    this.visible = !this.visible;
    if (this.visible) {
      this.passwordIcon = "assets/images/showeye.png";
    } else {
      this.passwordIcon = "assets/images/hideeye.png";
    }
  }



  login(): void {
    try {
      this.CloseRightNav();
      this.userinfo = [];
      const url = window.location.href.split("/")[2];
      const ip = url.split(":")[0];
      //clear the value
      const SharedDetails = undefined;
      this.alertSharedService.changeSharedHomeMapdetails(SharedDetails);
     // this.calculate();
      if (this.username != undefined && this.password != undefined) {
        this.Api.login(this.username.trim(), this.password.trim(), ip)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((data: any) => {
            this.userinfo = data;
            if (this.userinfo.length > 0) {
              this.errorflag = false;
              const staffName =
                this.userinfo[0].FirstName + " " + this.userinfo[0].LastName;

              localStorage.setItem(
                "IdleSessionTimeout",
                (parseInt(this.userinfo[0].sessiontimeout) * 60).toString()
              );
                const idleSessionTimeout = localStorage.getItem("IdleSessionTimeout") ?? "";
              AppComponent.sessiontimeout = parseInt(
                idleSessionTimeout
              );
              AppComponent.IsidleState = 0;
              this.userIdle.stopWatching();
              this.userIdle.setConfigValues({
                idle:
                  AppComponent.sessiontimeout - AppComponent.messageshowtime,
                timeout: AppComponent.messageshowtime,
                ping: 0,
              });
              // Start watching for user inactivity.
              this.userIdle.startWatching();

              this.From_Date = new Date();
              this.From_Date.setDate(this.From_Date.getDate() - 6);
              this.From_Date = this.datePipe.transform(
                this.From_Date,
                "yyyy-MM-dd"
              );

              this.To_Date = new Date();
              this.To_Date = this.datePipe.transform(
                this.To_Date,
                "yyyy-MM-dd"
              );

              localStorage.setItem("username", JSON.stringify(staffName));
              localStorage.setItem(
                "userauth",
                JSON.stringify(this.userinfo[0].user)
              );
              localStorage.setItem("userid", this.userinfo[0].StaffID);
              localStorage.setItem("Photo", this.userinfo[0].photo);
              localStorage.setItem("role", this.userinfo[0].DesignationId);
              localStorage.setItem("applianceSN", this.userinfo[0].applianceSN);
              localStorage.setItem(
                "new_ui_version",
                this.userinfo[0].new_ui_version
              );
              localStorage.setItem(
                "wander_config",
                this.userinfo[0].wander_config
              );
              localStorage.setItem(
                "asset_config",
                this.userinfo[0].asset_config
              );

              localStorage.setItem(
                "showuiversion",
                this.userinfo[0].showuiversion
              );
              sessionStorage.setItem("userid", this.userinfo[0].StaffID); //this is for browser close check
              sessionStorage.setItem("infraOpen", "0"); //default it's closed

              localStorage.setItem(
                "dateFormat",
                this.userinfo[0].dateandtime_format
              );
              localStorage.setItem(
                "ENABLE_UL2560",
                this.userinfo[0].enable_ul2560
              );
              localStorage.setItem(
                "PAST_ALERT_LINGER",
                this.userinfo[0].past_alert_linger
              );
              localStorage.setItem("Authkey", this.userinfo[0].authkey);
              localStorage.setItem("StaffUserRoleView", "");
              localStorage.setItem(
                "outdoor_Locating",
                this.userinfo[0].outdoor_Locating
              );
              localStorage.setItem(
                "show_live_location_resident",
                this.userinfo[0].show_live_location_resident
              );
              localStorage.setItem(
                "show_live_location_staff",
                this.userinfo[0].show_live_location_staff
              );
              localStorage.setItem(
                "average_response_time_min",
                this.userinfo[0].average_response_time_min
              );
              localStorage.setItem(
                "average_response_time_sec",
                this.userinfo[0].average_response_time_sec
              );
              localStorage.setItem(
                "average_response_Threshold_min",
                this.userinfo[0].average_response_Threshold_min
              );
              localStorage.setItem(
                "average_response_Threshold_sec",
                this.userinfo[0].average_response_Threshold_sec
              );
              localStorage.setItem(
                "country_code",
                this.userinfo[0].country_code
              );
             localStorage.setItem("hardwired_configuration",this.userinfo[0].hardwired_configuration);

              localStorage.setItem("rep_From_Date", this.From_Date);
              localStorage.setItem("rep_To_Date", this.To_Date);
              localStorage.setItem("voice_license",this.userinfo[0].voice_license);
              localStorage.setItem("voice_all_residents",this.userinfo[0].voice_all_residents);

              const SharedDetails = undefined;
              this.alertSharedService.changeShared(SharedDetails);
              this.alertSharedService.changeSharedAlert(SharedDetails);
              this.alertSharedService.changeSharedEvent(SharedDetails);
              this.alertSharedService.changesharedFloorAlert(SharedDetails);
              this.alertSharedService.changecanceledAlert(SharedDetails);
              this.alertSharedService.changeSharedCheckAlert(SharedDetails);
              this.alertSharedService.changeSharedLocation(SharedDetails);
              const _ShareInfraopenDetails = {
                "IsfraOpen": 0,
              };
              this.alertSharedService.changedInfraAlert(_ShareInfraopenDetails);
              const ShareHTTPRequestDetails = {
                IsError: 0,
              };
              changedHTTPRequest(ShareHTTPRequestDetails);
              closealertpushSocketConForUpdates();
              this.mainLayoutService.subscribeforalertpushevents();
              this.mainLayoutService.CheckInternetConnection();
              
              const authkey = localStorage.getItem("Authkey") ?? "";
              const roleId = localStorage.getItem("role") ?? "";
              const roledetails = {
                authKey: authkey,
                roleId: parseInt(roleId),
              };
              this.getUserRole(roledetails);

              console.log("closealertpushSocketConForUpdates Login");

              AppComponent.IsContinuous = 0;
              AppComponent.AudioAlertTime = 5;
            } else {
              if (this.username == "" && this.password == "") {
                this.emptyflag = true;
              } else if (this.username == "") this.flagUsername = true;
              else if (this.password == "") this.flagPassword = true;
              else {
                this.errorflag = true;
              }
            }
          });
      } else {
        this.errorflag = false;
        if (this.username == undefined && this.password == undefined)
          this.emptyflag = true;
        else if (this.username == undefined) this.flagUsername = true;
        else if (this.password == undefined) this.flagPassword = true;
      }
    } catch (err) {
      throw new Error("login.component.ts" + " - login() - " + err.message);
    }
  }

  getUserRole(data): void {
    this.mainLayoutService
      .getUserRole(data)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((StaffUserRoleView: []) => {
        AppComponent.StaffUserRoleView = StaffUserRoleView;
        localStorage.setItem(
          "StaffUserRoleView",
          JSON.stringify(AppComponent.StaffUserRoleView)
        );
        if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Home,
            enumUIAction.Home_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/main"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Resident,
            enumUIAction.Resident_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/resident"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Staff,
            enumUIAction.Staff_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/staff"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Asset,
            enumUIAction.Asset_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/asset"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Notification,
            enumUIAction.Notification_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/notification"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Technology,
            enumUIAction.Device_Map_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/floorplans"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Technology,
            enumUIAction.Templates_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/templates"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Technology,
            enumUIAction.Device_List_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/device"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Technology,
            enumUIAction.Outdoor_Locating_View
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/approximatelocating"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Technology,
            enumUIAction.Hardwired_Configration_View
          ) == enumUIView.Show
        ) {
        if (localStorage.getItem("hardwired_configuration") =="1")
            this.router.navigate(['/hardwiredconfiguration']);
        else if (localStorage.getItem("hardwired_configuration") =="2")
            this.router.navigate(['/tektone']);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Report,
            enumUIAction.Report_Main_Dashboard
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/report"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Report,
            enumUIAction.Report_Alert_Report
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/alertreport"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Report,
            enumUIAction.Report_Infrastructure_Report
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/infrastructurereport"]);
        } else if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Report,
            enumUIAction.Report_Location_History_Report
          ) == enumUIView.Show
        ) {
          this.router.navigate(["/locationhistory"]);
        }
      });
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
