import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ErrorHandler } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule  } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientXsrfModule } from '@angular/common/http';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule,Routes  } from "@angular/router";
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from './imports/material.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChartsModule } from 'ng2-charts';
import {DatePipe} from '@angular/common';
import { TreeviewModule } from 'ngx-treeview';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { UserIdleModule } from 'angular-user-idle';
import { AppComponent } from './app.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LoginComponent } from './login/login.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';



import { HomeComponent } from './landing/home/home.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HeaderComponent } from './main-layout/header/header.component';
import { MainLayoutService } from './main-layout/main-layout.service';
import { SidenavComponent } from './main-layout/sidenav/sidenav.component';
import { FooterComponent } from './main-layout/footer/footer.component';
import { AlertnotificationComponent } from './landing/home/alertnotification/alertnotification.component';
import { AccesscodeComponent } from './accesscode/accesscode.component';
import { ClearalertComponent } from './landing/home/alertnotification/clearalert/clearalert.component';

import { HomeHeaderComponent } from './landing/home/home-header/home-header.component';
import { MobileBottombarComponent } from './main-layout/mobile-bottombar/mobile-bottombar.component';
import { AlertsComponent } from './main-layout/alerts/alerts.component';
import { AlertErrorHandler }     from './main-layout/alerts/http-error-handler.service';
import { AlertMessageService }       from './main-layout/alerts/message.service';
import { InfrastructureAlertsComponent } from './main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component';
import { AckAlertComponent } from './main-layout/alerts/ack-alert/ack-alert.component';

import { StaffHeaderComponent } from './landing/staff/staff-header/staff-header.component';
import { StaffComponent } from './landing/staff/staff.component';
import { AddStaffComponent } from './landing/staff/add-staff/add-staff.component';
import { EditStaffComponent } from './landing/staff/edit-staff/edit-staff.component';
import { DeleteStaffComponent } from './landing/staff/delete-staff/delete-staff.component';
import { HttpErrorHandler }     from './landing/staff/http-error-handler.service';
import { MessageService }       from './landing/staff/message.service';
import { DownloadStaffComponent } from './landing/staff/download-staff/download-staff.component';
import { VerifyStaffComponent } from './landing/staff/verify-staff/verify-staff.component';

import { ResidentComponent } from './landing/resident/resident.component';
import { ResidentHeaderComponent } from './landing/resident/resident-header/resident-header.component';
import { AddResidentComponent } from './landing/resident/add-resident/add-resident.component';
import { EditResidentComponent } from './landing/resident/edit-resident/edit-resident.component';
import { ReleaseResidentComponent } from './landing/resident/release-resident/release-resident.component';
import { ResidentHttpErrorHandler }     from './landing/resident/http-error-handler.service';
import { ResidentMessageService }       from './landing/resident/message.service';
import { MedicalHistoryComponent } from './landing/resident/medical-history/medical-history.component';
import { DownloadResidentComponent } from './landing/resident/download-resident/download-resident.component';

import { WanderconfigComponent } from './landing/wanderconfig/wanderconfig.component';
import { WanderHeaderComponent } from './landing/wanderconfig/wander-header/wander-header.component';
import { AddWanderconfigComponent } from './landing/wanderconfig/add-wanderconfig/add-wanderconfig.component';
import { EditWanderconfigComponent } from './landing/wanderconfig/edit-wanderconfig/edit-wanderconfig.component';
import { WanderHttpErrorHandler }     from './landing/wanderconfig/http-error-handler.service';
import { WanderMessageService }       from './landing/wanderconfig/message.service';
import { NotificationComponent } from './landing/notification/notification.component';
import { NotificationHeaderComponent } from './landing/notification/notification-header/notification-header.component';
import { NotificationLeftbarComponent } from './landing/notification/notification-leftbar/notification-leftbar.component';
import { AddNotificationComponent } from './landing/notification/add-notification/add-notification.component';
import { EditNotificationComponent } from './landing/notification/edit-notification/edit-notification.component';
import { NotificationErrorHandler }     from './landing/notification/http-error-handler.service';
import { NotificationMessageService }       from './landing/notification/message.service';

import { TechnologyoverlayComponent } from './landing/technologyoverlay/technologyoverlay.component';
import { TechnologyoverlayHeaderComponent } from './landing/technologyoverlay/technologyoverlay-header/technologyoverlay-header.component';
import { TechnologyoverlayLeftbarComponent } from './landing/technologyoverlay/technologyoverlay-leftbar/technologyoverlay-leftbar.component';
import { DeviceComponent } from './landing/technologyoverlay/device/device.component';
import { AddDeviceComponent } from './landing/technologyoverlay/device/add-device/add-device.component';
import { EditDeviceComponent } from './landing/technologyoverlay/device/edit-device/edit-device.component';
import { ConfirmDialogComponent } from './landing/technologyoverlay/device/confirm-dialog/confirm-dialog.component';
import { FloorplansComponent } from './landing/technologyoverlay/floorplans/floorplans.component';
import { TechnologyoverlayHttpErrorHandler }     from './landing/technologyoverlay/http-error-handler.service';
import { TechnologyoverlayMessageService }       from './landing/technologyoverlay/message.service';
import { AddCampusComponent } from './landing/technologyoverlay/floorplans/campus/add-campus/add-campus.component';
import { EditCampusComponent } from './landing/technologyoverlay/floorplans/campus/edit-campus/edit-campus.component';
import { AddBuildingComponent } from './landing/technologyoverlay/floorplans/building/add-building/add-building.component';
import { EditBuildingComponent } from './landing/technologyoverlay/floorplans/building/edit-building/edit-building.component';
import { AddFloorComponent } from './landing/technologyoverlay/floorplans/floor/add-floor/add-floor.component';
import { AddZoneComponent } from './landing/technologyoverlay/floorplans/zone/add-zone/add-zone.component';
import { EditZoneComponent } from './landing/technologyoverlay/floorplans/zone/edit-zone/edit-zone.component';
import { AddUnitComponent } from './landing/technologyoverlay/floorplans/unit/add-unit/add-unit.component';
import { EditUnitComponent } from './landing/technologyoverlay/floorplans/unit/edit-unit/edit-unit.component';
import { UploadDialogComponent } from './landing/technologyoverlay/hardwiredconfiguration/upload-dialog/upload-dialog.component';
import { EditConfigComponent } from './landing/technologyoverlay/hardwiredconfiguration/edit-config/edit-config.component';

import { ReportComponent } from './landing/report/report.component';
import { ReportHeaderComponent } from './landing/report/report-header/report-header.component';
import { ReportLeftbarComponent } from './landing/report/report-leftbar/report-leftbar.component';
import { ExportReportComponent } from './landing/report/export-report/export-report.component';


import { ReportHttpErrorHandler } from './landing/report/http-error-handler.service';
import { ReportMessageService } from './landing/report/message.service';

import { DownloadAlertReportComponent } from './landing/report/download-alert-report/download-alert-report.component';
import { DownloadInfrastructureReportComponent } from './landing/report/download-infrastructure-report/download-infrastructure-report.component';
import { showResidentGroupComponent } from './landing/resident/show-resident-group/show-resident-group.component';
import { AddResidentGroupComponent } from './landing/resident/show-resident-group/add-resident-group/add-resident-group.component';
import { EditresidentgroupComponent } from './landing/resident/show-resident-group/edit-resident-group/edit-resident-group.component';
import { ShowStaffGroupComponent } from './landing/staff/show-staff-group/show-staff-group.component';
import { AddStaffGroupComponent } from './landing/staff/show-staff-group/add-staff-group/add-staff-group.component';
import { EditStaffGroupComponent } from './landing/staff/show-staff-group/edit-staff-group/edit-staff-group.component';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';
import { EditFacilityComponent } from './landing/technologyoverlay/floorplans/facility/edit-facility/edit-facility.component';
import { MainErrorHandler }     from './main-layout/http-error-handler.service';
import { MainMessageService }       from './main-layout/message.service';
import { MapnotificationComponent } from './landing/home/mapnotification/mapnotification.component';
import { InterceptorService } from './_services/interceptor.service';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { ConfirmMessageComponent } from './confirm-message/confirm-message.component';
import { GeneralMessageComponent } from './general-message/general-message.component';
import { DownloadLocationhistoryReportComponent } from './landing/report/download-locationhistory-report/download-locationhistory-report.component';
import { DownloadLiveviewReportComponent } from './landing/report/download-liveview-report/download-liveview-report.component';
import { ReportColorconfigComponent } from './landing/report/report-colorconfig/report-colorconfig.component';

import { AssetComponent } from './landing/asset/asset.component';
import { AssetHttpErrorHandler } from './landing/asset/http-error-handler.service';
import { AssetMessageService } from './landing/asset/message.service';
import { AssetHeaderComponent } from './landing/asset/asset-header/asset-header.component';
import { AddAssetComponent } from './landing/asset/add-asset/add-asset.component';
import { EditAssetComponent } from './landing/asset/edit-asset/edit-asset.component';
import { DownnloadAssetComponent } from './landing/asset/downnload-asset/downnload-asset.component';
import { ReportEditassetComponent } from './landing/report/report-editasset/report-editasset.component';
import { CustomizelocatorsComponent } from './landing/report/customizelocators/customizelocators.component';
import { LiveviewComponent } from './landing/report/liveview/liveview.component';
import { CustomizegeoComponent } from './landing/report/customizegeo/customizegeo.component';
import { LocationhistoryComponent } from './landing/report/locationhistory/locationhistory.component';
import { InfrastructurereportComponent } from './landing/report/infrastructurereport/infrastructurereport.component';
import { AlertreportComponent } from './landing/report/alertreport/alertreport.component';
import { SelectdateComponent } from './landing/report/selectdate/selectdate.component';
import { ResidentassessmentComponent } from './landing/report/residentassessment/residentassessment.component';

import { OutdoorlocatingComponent } from './landing/technologyoverlay/outdoorlocating/outdoorlocating.component';
import { AddOutdoorspaceComponent } from './landing/technologyoverlay/outdoorlocating/add-outdoorspace/add-outdoorspace.component';
import { TemplatesComponent } from './landing/technologyoverlay/templates/templates.component';
import { DuplicateBuildingComponent } from './landing/technologyoverlay/floorplans/building/duplicate-building/duplicate-building.component';
import { MapviewComponent } from './landing/technologyoverlay/floorplans/mapview/mapview.component';
import { MapviewFloorComponent } from './landing/technologyoverlay/floorplans/mapview-floor/mapview-floor.component';
import { DuplicateZoneComponent } from './landing/technologyoverlay/floorplans/zone/duplicate-zone/duplicate-zone.component';
import { TemplateZoneComponent } from './landing/technologyoverlay/floorplans/zone/template-zone/template-zone.component';
import { TemplateUnitComponent } from './landing/technologyoverlay/floorplans/unit/template-unit/template-unit.component';
import { DuplicateUnitComponent } from './landing/technologyoverlay/floorplans/unit/duplicate-unit/duplicate-unit.component';
import { EditTemplatesComponent } from './landing/technologyoverlay/templates/edit-templates/edit-templates.component';
import { EditSpacestemplateComponent } from './landing/technologyoverlay/templates/edit-spacestemplate/edit-spacestemplate.component';
import { AddTemplateComponent } from './landing/technologyoverlay/floorplans/zone/add-template/add-template.component';
import { EditFloordeviceComponent } from './landing/technologyoverlay/templates/edit-floordevice/edit-floordevice.component';
import { EditSpacedeviceComponent } from './landing/technologyoverlay/templates/edit-spacedevice/edit-spacedevice.component';
import { EditFloorspaceComponent } from './landing/technologyoverlay/templates/edit-floorspace/edit-floorspace.component';
import { InsertTemplateComponent } from './landing/technologyoverlay/floorplans/mapview-floor/insert-template/insert-template.component';
import { DeviceAdvancefilterComponent } from './landing/technologyoverlay/device-advancefilter/device-advancefilter.component';
import { EditOutdoorspaceComponent } from './landing/technologyoverlay/outdoorlocating/edit-outdoorspace/edit-outdoorspace.component';
import { HardwiredConfigurationComponent } from './landing/technologyoverlay/hardwiredconfiguration/hardwiredconfiguration.component';
import { MapviewCropComponent } from './landing/technologyoverlay/floorplans/mapview-crop/mapview-crop.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ViewNotificationComponent } from './landing/notification/view-notification/view-notification.component';
import { TekToneComponent } from './landing/technologyoverlay/tektone/tektone.component'
import { TekToneUploadDialogComponent } from './landing/technologyoverlay/tektone/upload-dialog/upload-dialog.component';
import { TekToneEditConfigComponent } from './landing/technologyoverlay/tektone/edit-config/edit-config.component';
import { ManagesubZoneComponent } from './landing/technologyoverlay/floorplans/managesub-zone/managesub-zone.component';

import {MatTreeModule} from '@angular/material/tree';
import { DownloadDeviceReportComponent } from './landing/technologyoverlay/device/download-device-report/download-device-report.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// { path: 'report', component: ReportComponent, canActivate:[AuthGuardService],data: {title: 'Report'}},

const appRoutes: Routes = [
   
  { path: '', redirectTo: 'login', data: { title: 'login' }, pathMatch: 'full' },
  {
    path: 'login', component: LoginLayoutComponent, data: {title: 'login'},
    children: [
      {path: '', component: LoginComponent, data: {title: 'login'}}
    ]
  },
  { path: 'main', component: HomeLayoutComponent},
  { path: 'resident', component: ResidentComponent, data: { title: 'Resident' } },
  { path: 'staff', component: StaffComponent, data: { title: 'staff' } },
  { path: 'wanderprevention', component: WanderconfigComponent, data: { title: 'wanderprevention' } },
  { path: 'asset', component: AssetComponent, data: { title: 'Asset' } },
  { path: 'notification', component: NotificationComponent, data: { title: 'Notification' } },
  { path: 'technologyoverlay', redirectTo: '/floorplans',   pathMatch: 'full'},
  { path: 'device', component: DeviceComponent, data: { title: 'Technology overlay' }},
  { path: 'templates', component: TemplatesComponent, data: { title: 'Technology overlay' }},
  { path: 'floorplans', component: FloorplansComponent, data: { title: 'Technology overlay' }},
  { path: 'approximatelocating', component: OutdoorlocatingComponent, data: { title: 'Technology overlay' }},  
  { path: 'mapbuilding', component: MapviewComponent, data: { title: 'Technology overlay' }},  
  { path: 'mapfloor', component: MapviewFloorComponent, data: { title: 'Technology overlay' }},
  { path: 'report', component: SelectdateComponent, data: {title: 'Report'}},
  { path: 'campusdashboard', component: SelectdateComponent, data: { title: 'Report' }},
  { path: 'residentassessment', component: ResidentassessmentComponent, data: { title: 'Report' }},
  { path: 'alertreport', component: AlertreportComponent, data: { title: 'Report' }},
  { path: 'infrastructurereport', component: InfrastructurereportComponent, data: { title: 'Report' }},
  { path: 'locationhistory', component: LocationhistoryComponent, data: { title: 'Report' }},
  { path: 'customizelocators', component: CustomizelocatorsComponent, data: { title: 'Report' }},
  { path: 'liveview', component: LiveviewComponent, data: { title: 'Report' }},
  { path: 'customizegeo', component: CustomizegeoComponent, data: { title: 'Report' }},  
  { path: 'hardwiredconfiguration', component: HardwiredConfigurationComponent, data: { title: 'Hardwired configuration' }},
  { path: 'mapview-crop', component: MapviewCropComponent, data: { title: 'MapView Crop' }},
  { path: 'tektone', component: TekToneComponent, data: { title: 'TekTone' }},
  { path: '404',   component: HomeComponent },
  { path: '**',   redirectTo: '404'  },
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LoginLayoutComponent,
        HomeLayoutComponent,
        MainLayoutComponent,
        HeaderComponent,
        HomeHeaderComponent,
        MobileBottombarComponent,
        FooterComponent,
        SidenavComponent,
        AlertsComponent,
        HomeComponent,
        AlertnotificationComponent,
        AccesscodeComponent,
        StaffHeaderComponent,
        StaffComponent,
        AddStaffComponent,
        EditStaffComponent,
        ResidentComponent,
        ResidentHeaderComponent,
        AddResidentComponent,
        EditResidentComponent,
        ReleaseResidentComponent,
        NotificationComponent,
        NotificationHeaderComponent,
        NotificationLeftbarComponent,
        AddNotificationComponent,
        EditNotificationComponent,
        TechnologyoverlayComponent,
        TechnologyoverlayHeaderComponent,
        TechnologyoverlayLeftbarComponent,
        ReportComponent,
        ReportHeaderComponent,
        ReportLeftbarComponent,
        ExportReportComponent,
        DeviceComponent,
        DeleteStaffComponent,
        FloorplansComponent,
        AddCampusComponent,
        EditCampusComponent,
        AddDeviceComponent,
        EditDeviceComponent,
        AddBuildingComponent,
        AddFloorComponent,
        AddZoneComponent,
        EditBuildingComponent,
        EditZoneComponent,
        ConfirmDialogComponent,
        AddUnitComponent,
        EditUnitComponent,
        ClearalertComponent,
        MedicalHistoryComponent,
        InfrastructureAlertsComponent,
        AckAlertComponent,
        DownloadResidentComponent,
        DownloadStaffComponent,
        DownloadAlertReportComponent,
        DownloadInfrastructureReportComponent,
        WanderconfigComponent,
        WanderHeaderComponent,
        AddWanderconfigComponent,
        EditWanderconfigComponent,
        showResidentGroupComponent,
        AddResidentGroupComponent,
        EditresidentgroupComponent,
        ShowStaffGroupComponent,
        AddStaffGroupComponent,
        EditStaffGroupComponent,
        EditFacilityComponent,
        VerifyStaffComponent,
        MapnotificationComponent,
        AlertMessageComponent,
        ConfirmMessageComponent,
        DownloadLocationhistoryReportComponent,
        AssetComponent,
        AssetHeaderComponent,
        AddAssetComponent,
        EditAssetComponent,
        DownnloadAssetComponent,
        DownloadLiveviewReportComponent,
        ReportColorconfigComponent,
        ReportEditassetComponent,
        CustomizelocatorsComponent,
        LiveviewComponent,
        CustomizegeoComponent,
        LocationhistoryComponent,
        InfrastructurereportComponent,
        AlertreportComponent,
        SelectdateComponent,
        ResidentassessmentComponent,
        OutdoorlocatingComponent,
        AddOutdoorspaceComponent,
        TemplatesComponent,
        DuplicateBuildingComponent,
        GeneralMessageComponent,
        MapviewComponent,
        MapviewFloorComponent,
        DuplicateZoneComponent,    
        TemplateZoneComponent,           
        TemplateUnitComponent,
        DuplicateUnitComponent,
        EditTemplatesComponent,
        EditSpacestemplateComponent,
        AddTemplateComponent,
        EditFloordeviceComponent,
        EditSpacedeviceComponent,
        EditFloorspaceComponent,
        InsertTemplateComponent,
        DeviceAdvancefilterComponent,
        EditOutdoorspaceComponent,
        HardwiredConfigurationComponent,
        UploadDialogComponent,
        EditConfigComponent,
        MapviewCropComponent,
    ViewNotificationComponent,
    TekToneComponent,
    TekToneUploadDialogComponent,
    TekToneEditConfigComponent,
    ManagesubZoneComponent,
    DownloadDeviceReportComponent
    ],
    imports: [
        PerfectScrollbarModule,
        BrowserModule,
        MatSortModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatCardModule,
        MatSnackBarModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatDialogModule,
        HttpClientModule,
        MatRadioModule,
        FileUploadModule,
        ChartsModule,
        HttpClientModule,
        MatSlideToggleModule,
        DragDropModule,
        NgxDaterangepickerMd.forRoot(),
        TreeviewModule.forRoot(),
        UserIdleModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'My-Xsrf-Cookie',
            headerName: 'My-Xsrf-Header',
        }),
        RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' }),
        BrowserAnimationsModule,
        FlexLayoutModule,
        MaterialModule,
        MatBadgeModule,
        MatTabsModule,
        ImageCropperModule,
        MatTreeModule
    ],
    providers: [{
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: InterceptorService,
          multi: true
        },
        { provide: MatDialogRef, useValue: {} },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        {
            provide: MAT_DIALOG_DATA,
            useValue: {} // Add any data you wish to test if it is passed/used correctly
        },
        DatePipe,
        MainLayoutService,
        HttpErrorHandler,
        MessageService,
        MainErrorHandler,
        MainMessageService,
        AlertErrorHandler,
        AlertMessageService,
        NotificationErrorHandler,
        NotificationMessageService,
        ResidentHttpErrorHandler,
        ResidentMessageService,
        TechnologyoverlayHttpErrorHandler,
        TechnologyoverlayMessageService,
        ReportHttpErrorHandler,
        ReportMessageService,
        WanderHttpErrorHandler,
        WanderMessageService,
        AssetHttpErrorHandler,
        AssetMessageService
    ],
    bootstrap: [AppComponent],
    exports: [
        MatDatepickerModule,
        MatNativeDateModule,
        MatOptionModule,
        MatSelectModule
    ],    
})
export class AppModule { }
