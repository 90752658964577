
    <div>
      <div class="clsshowstaff">
          <div class="show-title">
            <h3 class="showHeader" style="margin: 0 0 10px 11px;">Staff Group Info
            <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
            <mat-divider ></mat-divider> 
          </div>
      </div>
      <div>  
        <div class="blocks">              
            <button color="primary"  mat-raised-button (click)="AddStaffGroup()" *ngIf="bAddEdit == true">Add</button>      
        </div>         
       
              <div class="mat-elevation-z0" style="margin-top:10px;">
                    <perfect-scrollbar style="max-width: 700px; max-height: 250px;">
                  <div *ngIf="dataSourcecount; else noRecord">
                      <table mat-table [dataSource]="dataSource">
                        <!-- Group Name Column -->
                        <ng-container matColumnDef="GroupName">
                            <th mat-header-cell *matHeaderCellDef > Group Name </th>
                            <td mat-cell *matCellDef="let element"> 
                            <span *ngIf="element.GroupName.length < 12" >{{element.GroupName}}</span>
                            <span *ngIf="element.GroupName.length >= 12" matTooltipClass="devicetooltip" matTooltip={{element.GroupName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.GroupName.slice(0,15) + ".."}}</span>
                          </td>
                          </ng-container>  
                        
                          <!-- Description -->
                          <ng-container matColumnDef="Description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td mat-cell *matCellDef="let element"> 
                            <span *ngIf="element.Description.length < 12" >{{element.Description}}</span>
                            <span *ngIf="element.Description.length >= 12" matTooltipClass="devicetooltip" matTooltip={{element.Description}} #tooltip="matTooltip" matTooltipPosition="above">{{element.Description.slice(0,15) + ".."}}</span>
                          </td>
                          </ng-container>
                        
                          <!-- Staff Name Column -->
                          <ng-container matColumnDef="Staffs">
                            <th mat-header-cell *matHeaderCellDef style="width:230px;"> Staffs </th>
                            <td mat-cell *matCellDef="let element"> 
                            <span *ngIf="element.StaffName.length < 12" >{{element.StaffName}}</span>
                            <span *ngIf="element.StaffName.length >= 12" matTooltipClass="devicetooltip" matTooltip={{element.StaffName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.StaffName.slice(0,15) + ".."}}</span>
                          </td>
                          </ng-container>

                           <!-- Count Column -->
                           <ng-container matColumnDef="Count">
                            <th mat-header-cell *matHeaderCellDef style="width:50px;"> Count </th>
                            <td mat-cell *matCellDef="let element"> {{element.Count}} </td>
                          </ng-container>

                           <!-- Symbol Column -->
                          <ng-container matColumnDef="Edit">
                              <th mat-header-cell *matHeaderCellDef style="width:50px;"> Edit </th>                                    
                              <td mat-cell *matCellDef="let element"><img src="assets/images/Edit_Icon.png" style="cursor:pointer;" (click)="editStaffGroupdata(element.GroupId,element.GroupName,element.Description)"></td>                              
                          </ng-container>
                           
                            <!-- Symbol Column -->
                          <ng-container matColumnDef="Delete">
                            <th mat-header-cell *matHeaderCellDef style="width:30px;"> Delete </th>  
                            <ng-container *matCellDef="let element">      
                            <td mat-cell *ngIf="element.StaffName == ''"> <img src="assets/images/Release-Delete-Icon.png" style="margin-left:1em;cursor:pointer;" (click)="deleteStaffGroupdata(element.GroupId)"> </td>                             
                            <td mat-cell *ngIf="element.StaffName != ''"> <label style="margin-left:1.2em;">--</label></td>                                                         
                          </ng-container> </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
               </div>
                <ng-template #noRecord>  
                  <h3 style="text-align: center;"> No Record found </h3>                  
                </ng-template>
                </perfect-scrollbar>
              </div>             
      </div>
    
  </div>



