import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { StaffService } from "../../staff.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  StaffGroupForCreationResponse,
  StaffEditGroupForCreation,
} from "../../staff";
import { ApiService } from "../../../../api.service";
import {
  GroupNamefoundResponse,
  checkGroupName,
  checkGroupNotifyLevel,
} from "../../../../api";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../app-general";
import { enumGroup_Type,appsettings } from "../../../../app-settings";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-staff-group",
  templateUrl: "./edit-staff-group.component.html",
  providers: [StaffService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-staff-group.component.scss"],
})
export class EditStaffGroupComponent implements OnInit, OnDestroy {
  public staffGroupEditCreationForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 3;
  EditGroupId;
  oldgroupname;
  staffeditgroupbool = true;
  private _onDestroy: Subject<void> = new Subject<void>();
  grouprowHeight = "67px";
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditStaffGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    private staffService: StaffService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
    this.EditGroupId = data.GroupId;
    this.oldgroupname = data.Groupname;
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.onResize();
    this.staffGroupEditCreationForm = new UntypedFormGroup({
      groupname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      description: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(150),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
  }
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.staffGroupEditCreationForm.controls).forEach((field) => {
        // {1}
        const control = this.staffGroupEditCreationForm.get(field); // {2}
        const staffForm_Id = document.getElementById(event.target.id);
        if (staffForm_Id != null) {
          if (field == staffForm_Id.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-staff-group.component.ts" + " - handleInput() - " + err.message
      );
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      this.grouprowHeight = "72.5px";
    }
  }
  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  public hasError = (controlName: string, errorName: string) => {
    return this.staffGroupEditCreationForm.controls[controlName].hasError(
      errorName
    );
  };
  public createStaffGroup = (StaffgroupFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.staffGroupEditCreationForm.valid && this.staffeditgroupbool) {
        this.staffeditgroupbool = false;
        const groupnamefoundItem: checkGroupName = {
          authKey: this.authkey,
          groupType: 2,
          newGroupName: StaffgroupFormValue.groupname.toString(),
          groupId: parseInt(this.EditGroupId),
        };
        this.apiService
          .checkGroupName(groupnamefoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: GroupNamefoundResponse[]) => {
            if (apiresponse.toString() == "not found") {
              this.checkGroupNotificationlevel(StaffgroupFormValue);
            } else {
              this.staffeditgroupbool = true;
              generateAlertMessage(
                this.dialog,
                "Staff Group name '" + apiresponse[0].name + "' already exists"
              );
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-staff-group.component.ts" +
          " - createStaffGroup() - " +
          err.message
      );
    }
  };

  checkGroupNotificationlevel(StaffgroupFormValue) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (
        this.oldgroupname !=
        StaffgroupFormValue.groupname.replace(/\s+/g, " ").trim()
      ) {
        const groupfoundItem: checkGroupNotifyLevel = {
          authKey: this.authkey,
          resStaffType: parseInt(enumGroup_Type.Staff.toString()),
          resStaffGroupId: this.EditGroupId.toString(),
        };
        this.apiService
          .checkGroupNotificationlevel(groupfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse.length == 0) {
              this.updateStaffGroup(StaffgroupFormValue);
            } else {
              this.staffeditgroupbool = true;
              generateAlertMessage(
                this.dialog,
                "Staff Group name '" +
                  apiresponse[0].GroupName +
                  "' is in escalation level,so please remove this group from escalation and try again."
              );
              return;
            }
          });
      } else {
        this.updateStaffGroup(StaffgroupFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-staff-group.component.ts" +
          " - checkGroupNotificationlevel() - " +
          err.message
      );
    }
  }

  // edit Staff Group
  private updateStaffGroup = (StaffgroupFormValue) => {
    try {
      const EditResidentgroup: StaffEditGroupForCreation = {
        authKey: this.authkey,
        groupname: StaffgroupFormValue.groupname.replace(/\s+/g, " ").trim(),
        description: StaffgroupFormValue.description
          .replace(/\s+/g, " ")
          .trim(),
        groupId: this.EditGroupId.toString(),
      };
      this.staffService
        .updateStaffGroup(EditResidentgroup)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: StaffGroupForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            this.staffeditgroupbool = false;
            generateAlertMessage(
              this.dialog,
              "Staff group edited successfully."
            );
          } else if (apiresponse["apiReturn"] == "Fail") {
            this.staffeditgroupbool = true;
            generateAlertMessage(this.dialog, "Staff group edited failed.");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "edit-staff-group.component.ts" +
          " - updateStaffGroup() - " +
          err.message
      );
    }
  };
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
