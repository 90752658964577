import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { ReportService } from "../report.service";
import { DatePipe } from "@angular/common";
import { NewDeviceTypeDesc } from "../../../app-settings";
import "jspdf-autotable";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { InfraDevicesType } from '../report';

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");
@Component({
  selector: "app-download-infrastructure-report",
  templateUrl: "./download-infrastructure-report.component.html",
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./download-infrastructure-report.component.scss"],
})
export class DownloadInfrastructureReportComponent
  implements OnInit, OnDestroy
{
  DeviceType;
  InfrastructureReportFilter;
  todaydate;
  Todaydate;
  public NewDeviceTypeDesc = NewDeviceTypeDesc;
  public isExcelDownload = false;
  public isPDFDownload = false;
  voiceLicense;
  InfraDeviceType: InfraDevicesType[] = [];
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private reportService: ReportService,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<DownloadInfrastructureReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RollbarService) private rollbar: Rollbar
  ) 
  {
    this.voiceLicense = localStorage.getItem("voice_license"); 
                        
    this.InfraDeviceType.push({ DeviceType: 'All', DeviceTypeId: "All" }); 
    this.InfraDeviceType.push({ DeviceType: 'Clear Gateway', DeviceTypeId: "Gateway" }); 
    this.InfraDeviceType.push({ DeviceType: 'Clear Repeater', DeviceTypeId: 'Clear Repeater' }); 
    this.InfraDeviceType.push({ DeviceType: 'Clear Router', DeviceTypeId: 'Clear Router' }); 
    if(this.voiceLicense == 1)
      this.InfraDeviceType.push({ DeviceType: 'Intercom', DeviceTypeId: 'Intercom'}); 
    this.InfraDeviceType.push({ DeviceType: 'LF', DeviceTypeId: 'LF' }); 
    this.InfraDeviceType.push({ DeviceType: 'Monitor', DeviceTypeId: 'Monitor' }); 
    this.InfraDeviceType.push({ DeviceType: 'Pull Cord', DeviceTypeId: 'Pull Cord' }); 
    this.InfraDeviceType.push({ DeviceType: 'Security Controller', DeviceTypeId: 'Security Controller' }); 
    this.InfraDeviceType.push({ DeviceType: 'TruLocator', DeviceTypeId: 'TruLocator' }); 
    this.InfraDeviceType.push({ DeviceType: 'Universal Monitor', DeviceTypeId: 'Universal Monitor' }); 
    this.InfraDeviceType.push({ DeviceType: 'UT', DeviceTypeId: 'UT' }); 
    this.InfraDeviceType.push({ DeviceType: 'VW', DeviceTypeId: 'VW'}); 
    this.InfraDeviceType.push({ DeviceType: NewDeviceTypeDesc.Approx, DeviceTypeId: NewDeviceTypeDesc.Approx }); 
  }

  ngOnInit() {
    const loaderelement = document.getElementsByClassName(
      "loadInfraAlertspinner"
    );
    loaderelement[0]["style"].display = "none";
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  selectDeviceType(data) {
    this.DeviceType = data;
  }

  exportInfrastructureReportExcel(exceldata) {
    const loaderelement = document.getElementsByClassName(
      "loadInfraAlertspinner"
    );
    loaderelement[0]["style"].display = "block";

    setTimeout(() => {
      this.exportInfrastructure_ReportExcel(exceldata);
    }, 3);
  }

  exportInfrastructure_ReportExcel(data) {
    try {
      let filteredinfraData = "";
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isExcelDownload) {
        this.isExcelDownload = true;
        this.todaydate = new Date();
        this.Todaydate = this.datePipe.transform(this.todaydate, "yyyy-MM-dd");
        if (this.DeviceType == undefined) {
          const loaderelement = document.getElementsByClassName(
            "loadInfraAlertspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Please select Device Type");
          this.isExcelDownload = false;
        } else if (data.length > 0) {
          if (this.DeviceType != "All") {
            const devicetype = this.DeviceType;
            filteredinfraData = data.filter(function (e1) {
              if (devicetype !=NewDeviceTypeDesc.LF)
                return e1.DeviceType == devicetype;
              else
                return (e1.DeviceType == NewDeviceTypeDesc.LFExiter || e1.DeviceType == NewDeviceTypeDesc.DripTray)
            });
          } else filteredinfraData = data;

          this.exportInfrastructureReporttoExcel(
            filteredinfraData,
            this.Todaydate
          );
        } else {
          const loaderelement = document.getElementsByClassName(
            "loadInfraAlertspinner"
          );
          loaderelement[0]["style"].display = "none";
          if (this.DeviceType == "All")
            generateAlertMessage(this.dialog, "No devices found");
          else
            generateAlertMessage(
              this.dialog,
              "No devices found of " + this.DeviceType + "type"
            );
          this.isExcelDownload = false;
        }
      }
    } catch (err) {
      throw new Error(
        "download-infrastructure-report.component.ts" +
          " - exportInfrastructureReportExcel() - " +
          err.message
      );
    }
  }

  exportInfrastructureReportPdf(pdfdata) {
    const loaderelement = document.getElementsByClassName(
      "loadInfraAlertspinner"
    );
    loaderelement[0]["style"].display = "block";

    setTimeout(() => {
      this.exportInfrastructure_ReportPdf(pdfdata);
    }, 3);
  }

  exportInfrastructure_ReportPdf(data) {
    try {
      let InfrastructureFilterpdf = "";
      if (!this.isPDFDownload) {
        this.isPDFDownload = true;
        this.todaydate = new Date();
        this.Todaydate = this.datePipe.transform(this.todaydate, "yyyy-MM-dd");
        if (this.DeviceType == undefined) {
          const loaderelement = document.getElementsByClassName(
            "loadInfraAlertspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Please select Device Type");
          this.isPDFDownload = false;
        } else if (data.length > 0) {
          if (this.DeviceType != "All") {
            const devicetype = this.DeviceType;
            InfrastructureFilterpdf = data.filter(function (e1) {
              if (devicetype !=NewDeviceTypeDesc.LF)
              return e1.DeviceType == devicetype;
            else
              return (e1.DeviceType == NewDeviceTypeDesc.LFExiter || e1.DeviceType == NewDeviceTypeDesc.DripTray)
            });
          } else InfrastructureFilterpdf = data;

          this.exportInfrastructureReporttopdf(
            InfrastructureFilterpdf,
            this.Todaydate
          );
        } else {
          const loaderelement = document.getElementsByClassName(
            "loadInfraAlertspinner"
          );
          loaderelement[0]["style"].display = "none";
          if (this.DeviceType == "All")
            generateAlertMessage(this.dialog, "No devices found");
          else
            generateAlertMessage(
              this.dialog,
              "No devices found of " + this.DeviceType + " type"
            );
          this.isPDFDownload = false;
        }
      }
    } catch (err) {
      throw new Error(
        "download-infrastructure-report.component.ts" +
          " - exportInfrastructureReportPdf() - " +
          err.message
      );
    }
  }

  exportInfrastructureReporttoExcel(InfrastructureReportFilter, date) {
    try {
      const downloadInfra = {
        Data: [] as any[],
      };
      if (InfrastructureReportFilter.length > 0) {
        for (let i = 0; i < InfrastructureReportFilter.length; i++) {
          const item = InfrastructureReportFilter[i];
          let LForIrid = InfrastructureReportFilter[i].LForIrid;
          let DualUTId = InfrastructureReportFilter[i].DualUTId;
          let MacId = InfrastructureReportFilter[i].MacId;
          if (LForIrid == null) {
            LForIrid = "";
          } else {
            LForIrid = InfrastructureReportFilter[i].LForIrid;
          }
          if (DualUTId == null) DualUTId = "";
          if (MacId == null) MacId = "";

          downloadInfra.Data.push({
            "Device Type": item.DeviceType,
            "Device Id": item.DeviceId,
            "LF / IR Id": LForIrid,
            "MacId": MacId,
            "UT Id": DualUTId,
            "Campus Name": item.CampusFullName,
            "Building Name": item.BuildingFullName,
            "Zone Name": item.FloorFullName,
            "Space": item.RoomName == null ? "" : item.RoomFullName,
          });
        }
        this.JSONToCSVConvertor(downloadInfra.Data, date, true);
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadInfraAlertspinner"
        );
        loaderelement[0]["style"].display = "none";
        if (this.DeviceType == "All")
          generateAlertMessage(this.dialog, "No devices found");
        else
          generateAlertMessage(
            this.dialog,
            "No devices found of " + this.DeviceType + " type"
          );
        this.isExcelDownload = false;
      }
    } catch (err) {
      throw new Error(
        "download-infrastructure-report.component.ts" +
          " - exportInfrastructureReporttoExcel() - " +
          err.message
      );
    }
  }

  JSONToCSVConvertor(JSONData, date, ShowLabel) {
    try {
      const arrData =
        typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      let CSV = "";

      //This condition will generate the Label/Header
      if (ShowLabel) {
        let row = "";

        //This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);
        //append Label row with line break
        CSV += row + "\r\n";
      }
      if (arrData.length > 0) {
        //1st loop is to extract each row
        for (let i = 0; i < arrData.length; i++) {
          let row = "";

          //2nd loop will extract each column and convert it in string comma-seprated
          for (const index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }

          row.slice(0, row.length - 1);

          //add a line break after each row
          CSV += row + "\r\n";
        }

        if (CSV == "") {
          const loaderelement = document.getElementsByClassName(
            "loadInfraAlertspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Invalid data");
          this.isExcelDownload = false;
          return;
        }

        //Generate a file name
        const fileName = "Infrastructure Report Data";
        const myBlob = new Blob([CSV], { type: "text/html" });
        const url = window.URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        const link = document.body.appendChild(a);
        document.body.appendChild(link);
        link.href = url;
        link.download = fileName + "_" + date + ".csv";
        link.click();
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadInfraAlertspinner"
        );
        loaderelement[0]["style"].display = "none";
        if (this.DeviceType == "All")
          generateAlertMessage(this.dialog, "No devices found");
        else
          generateAlertMessage(
            this.dialog,
            "No devices found of " + this.DeviceType + " type"
          );
        this.isExcelDownload = false;
      }
      const loaderelement = document.getElementsByClassName(
        "loadInfraAlertspinner"
      );
      loaderelement[0]["style"].display = "none";
      this.isExcelDownload = false;
    } catch (err) {
      throw new Error(
        "download-infrastructure-report.component.ts" +
          " - JSONToCSVConvertor() - " +
          err.message
      );
    }
  }

  exportInfrastructureReporttopdf(InfrastructureReportFilter, date) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      const infraData = [] as any[];
      const columns = [
        { title: "Device Type", dataKey: "DeviceType" },
        { title: "Device Id", dataKey: "DeviceId" },
        { title: "LF / IR Id", dataKey: "LForIrid" },
        { title: "Mac Id", dataKey: "MacId" },
        { title: "UT Id", dataKey: "DualUTId" },
        { title: "Campus Name", dataKey: "CampusName" },
        { title: "Building Name", dataKey: "BuildingName" },
        { title: "Zone Name", dataKey: "FloorName" },
        { title: "Space", dataKey: "DeviceName" },
      ];

      if (InfrastructureReportFilter.length > 0) {
        for (let i = 0; i < InfrastructureReportFilter.length; i++) {
          const DeviceId = InfrastructureReportFilter[i].DeviceId;
          const LForIrid = InfrastructureReportFilter[i].LForIrid;
          // const DeviceName = InfrastructureReportFilter[i].DeviceName;
          const DeviceType = InfrastructureReportFilter[i].DeviceType;
          const RoomName =
            InfrastructureReportFilter[i].RoomName == null
              ? ""
              : InfrastructureReportFilter[i].RoomFullName;
          const FloorName = InfrastructureReportFilter[i].FloorFullName;
          const BuildingName = InfrastructureReportFilter[i].BuildingFullName;
          const CampusName = InfrastructureReportFilter[i].CampusFullName;
          let MacId = InfrastructureReportFilter[i].MacId;
          let DualUTId = InfrastructureReportFilter[i].DualUTId;

          if (DualUTId == null) DualUTId = "";
          if (MacId == null) MacId = "";

          infraData.push({
            DeviceType: DeviceType,
            DeviceId: DeviceId,
            LForIrid: LForIrid,
            MacId: MacId,
            DualUTId: DualUTId,
            CampusName: CampusName,
            FloorName: FloorName,
            BuildingName: BuildingName,
            DeviceName: RoomName,
          });
        }
        const pdf = new jsPDF("l"); // A4 size page of PDF
        let PdfFileName = "Infrastructure_Report_Data";

        const pageWidth =
          pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        pdf.text("Infrastructure Report", pageWidth / 2, 10, "center");

        const options = {
          theme: "grid",
          bodyStyles: { lineColor: [0, 0, 0] },
            tableWidth: 'auto',
            columnWidth: 'wrap',
            showHeader: 'everyPage',
          headStyles: {
            lineWidth: 0.2,
            lineColor: [0, 0, 0],
            textColor: [0, 0, 0],
            fillColor: [169, 169, 169],
          },
          columnStyles: {
            DeviceType: { cellWidth: 17, minCellHeight: 15 },
            DeviceId: { cellWidth: 17, minCellHeight: 15 },
            LForIrid: { cellWidth: 17, minCellHeight: 15 },
            MacId: { cellWidth: 25, minCellHeight: 15 },
            DualUTId: { cellWidth: 22, minCellHeight: 15 },
            CampusName: { cellWidth: 22, minCellHeight: 15 },
            BuildingName: { cellWidth: 22, minCellHeight: 15 },
            FloorName: { cellWidth: 22, minCellHeight: 15 },
            DeviceName: { cellWidth: 22, minCellHeight: 15 },
          },
        };

        pdf.autoTable(columns, infraData, options);
        PdfFileName = PdfFileName + "_" + date + ".pdf";
        pdf.save(PdfFileName);
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadInfraAlertspinner"
        );
        loaderelement[0]["style"].display = "none";
        if (this.DeviceType == "All")
          generateAlertMessage(this.dialog, "No devices found");
        else
          generateAlertMessage(
            this.dialog,
            "No devices found of " + this.DeviceType + " type"
          );
        this.isPDFDownload = false;
      }
      const loaderelement = document.getElementsByClassName(
        "loadInfraAlertspinner"
      );
      loaderelement[0]["style"].display = "none";
      this.isPDFDownload = false;
    } catch (err) {
      throw new Error(
        "download-infrastructure-report.component.ts" +
          " - exportInfrastructureReporttopdf() - " +
          err.message
      );
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
