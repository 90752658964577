import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  SimpleChange,
  Inject,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { AlertSharedService } from "../../../_services/index";
import { SharedEventData } from "../../../api";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ReportService } from "../report.service";
import { MatSidenav } from "@angular/material/sidenav";
import { MatDialog } from "@angular/material/dialog";
import { generateAlertMessage } from "../../../app-general";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
export interface reportDetails {
  name: string;
  value: number;
}
@Component({
  selector: "app-report-header",
  templateUrl: "./report-header.component.html",
  styleUrls: ["./report-header.component.scss"],
})
export class ReportHeaderComponent implements OnInit, OnDestroy {
  public href = "";
  url = "";
  selectMonth;
  router_url;
  firstDate;
  _month;
  Cmonth;
  week1;
  lastDate;
  numDays;
  start = 0;
  end = 0;
  month = [];
  week = [];
  selectleftitem;
  w_fromdate;
  w_todate;
  week_fromdate;
  week_todate;
  firstDay;
  lastDay;
  firstDayMonth;
  lastDayMonth;
  month_fromdate;
  month_todate;
  year_firstdate;
  year_lastdate;
  date1;
  endyears = [] as any[];
  Year = 0;
  selectedYear = 0;
  selectedMonth = 0;
  selectedWeek = 0;
  SharedEventDetails: SharedEventData[];
  private _onDestroy: Subject<void> = new Subject<void>();
  loadReport = "";
  viewbuttondisable = false;
  public static selectYear;
  public static selectMonth;
  public static selectWeek;
  UserName;
  @ViewChild("sideReportnav") public sideReportMenuNav: MatSidenav;
  public static isReportMenuClosed = true;
  constructor(
    private router: Router,
    private alertSharedService: AlertSharedService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private reportService: ReportService,
    public dialog: MatDialog,
    public mainLayoutService: MainLayoutService
  ) {
    this.router_url = router.url;

    this.router.events.subscribe((event) => {
      if (event.constructor.name === "NavigationStart") {
        this.href = (event as any).url.split("/").slice(-1)[0];

        this.url = this.href;
      }
    });

    this.reportService.toggleReportMenuNav.subscribe(() => {
      this.sideReportMenuNav.toggle();

      if (this.sideReportMenuNav._animationState == "void") {
        ReportHeaderComponent.isReportMenuClosed = true;
      } else if (this.sideReportMenuNav._animationState == "open") {
        ReportHeaderComponent.isReportMenuClosed = false;
      }
    });
  }

  @Input() ReportType: number;
  changeLog: string[] = [];
  @Output() onNameChanged = new EventEmitter<string>();
  @Output() onEventChanged = new EventEmitter<string>();

  ngOnInit() {
    const userName = localStorage.getItem("username") ?? "";
    this.UserName = JSON.parse(userName);
    this.alertSharedService
      .currentSharedEvent()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((SharedEventDetails: SharedEventData[]) => {
        this.SharedEventDetails = SharedEventDetails;
      });
      if(this.SharedEventDetails.length > 0)
        this.ReportType = this.SharedEventDetails[0].EventName;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    const log: string[] = [];
    for (const propName in changes) {
      const changedProp = changes[propName];
      const to = JSON.stringify(changedProp.currentValue);
      if (changedProp.isFirstChange()) {
        log.push(`Initial value of ${propName} set to ${to}`);
      } else {
        const from = JSON.stringify(changedProp.previousValue);
        log.push(`${propName} changed from ${from} to ${to}`);
      }
      this.changeLog.push(log.join(", "));
      this.loadYear();
      this.selectedYear = 0;
      this.selectedMonth = 0;
      this.selectedWeek = 0;
      ReportHeaderComponent.selectYear = [];
      ReportHeaderComponent.selectMonth = [];
      ReportHeaderComponent.selectWeek = [];
    }
  }

  selectReportWeek(week, selectedMonth, selectYear) {
    try {
      this.selectedWeek = week;
      ReportHeaderComponent.selectWeek = week;
      if (selectYear == "") {
        generateAlertMessage(this.dialog, "Please select Year,Month");
        return;
      } else if (selectedMonth == 0) {
        generateAlertMessage(this.dialog, "Please select Month");
        return;
      } else {
        if (week.start < 9) {
          this.w_fromdate = "0" + week.start;
        } else {
          this.w_fromdate = week.start;
        }
        if (week.end < 9) {
          this.w_todate = "0" + week.end;
        } else {
          this.w_todate = week.end;
        }
        this.week_fromdate =
          selectYear + "-" + selectedMonth.value + "-" + this.w_fromdate;
        this.week_todate =
          selectYear + "-" + selectedMonth.value + "-" + this.w_todate;
        this.loadReport = this.week_fromdate + "," + this.week_todate;
        this.viewbuttondisable = true;
      }
    } catch (err) {
      throw new Error(
        "report-header.component.ts" + " - selectReportWeek() - " + err.message
      );
    }
  }

  getWeeksInMonth(selectMonth, selectYear) {
    try {
      this.week1 = [];
      this.firstDate = new Date(selectYear, selectMonth - 1, 1);
      this._month = selectMonth;
      this.Cmonth = selectMonth + 1;
      this.lastDate = new Date(selectYear, selectMonth, 0);
      this.numDays = this.lastDate.getDate();
      this.start = 1;
      this.end = 7 - this.firstDate.getDay();
      while (this.start <= this.numDays) {
        this.week1.push({ start: this.start, end: this.end });
        this.start = this.end + 1;
        this.end = this.end + 7;
        if (this.end > this.numDays) {
          this.end = this.numDays;
        }
      }
      return this.week1;
    } catch (err) {
      throw new Error(
        "report-header.component.ts" + " - getWeeksInMonth() - " + err.message
      );
    }
  }

  @Output() onReportweek = new EventEmitter<any>();
  getReportWeek(values) {
    this.onReportweek.emit(values);
  }

  loadYear() {
    try {
      const year = new Date().getFullYear();
      const startyear = 2012;
      const endyear = year;
      const range = [] as any[];
      for (let i = endyear; i >= startyear; i--) {
        range.push(i);
      }
      this.endyears = range;
    } catch (err) {
      throw new Error(
        "report-header.component.ts" + " - loadYear() - " + err.message
      );
    }
  }
  clkReportMenusidenav() {
    this.reportService.toggleReportMenuNav.emit();
  }

  closereportSidebarnav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
