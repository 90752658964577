import { Component, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http';
// import {appsettings} from '../../../app-settings';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { notificationService } from './../notification.service';



@Component({
  selector: "app-notification-header",
  templateUrl: "./notification-header.component.html",
  styleUrls: ["./notification-header.component.scss"],
})
export class NotificationHeaderComponent implements OnInit {
  UserName;
  checkMessageNotification = false;
  private _onDestroy: Subject<void> = new Subject<void>();
  
  constructor(private http: HttpClient, private notificationService: notificationService,) { }

  ngOnInit() {
    const userName = localStorage.getItem("username");
    if (userName != null) this.UserName = JSON.parse(userName);
    this.getNotificationMessage();
  }
  
  toggleMessageNotifications(event)
  {      
    let checkmsgNotify = 0; 
    try
    {      
      if(event.checked == true)
      {
        this.checkMessageNotification = true;
        checkmsgNotify = 1;
      }
      else
      {
        this.checkMessageNotification = false;
        checkmsgNotify = 0;
      }   
      
      const updatenotifymsg : any ={
        authKey: localStorage.getItem("Authkey"),
        clearmessage:checkmsgNotify,
      }
          
      this.notificationService.updateNotificationClearMessage(updatenotifymsg).pipe(takeUntil(this._onDestroy))
    .subscribe((apiresponse : any[])=>{     
        if (apiresponse !=null && apiresponse !=undefined)
        {  
            console.log(apiresponse)   
        }
    })
      
    }
    catch (err) {
      throw new Error('Notification.service.ts' + ' - reload_DC_Config() - ' + err.message);
    } 
  }
  
  getNotificationMessage()
  {
    const updatenotifymsg : any ={
      authKey: localStorage.getItem("Authkey"),      
    }
    let getnotification;
    this.notificationService.getNotificationClearMessage(updatenotifymsg).pipe(takeUntil(this._onDestroy))
    .subscribe((apiresponse : any[])=>{     
        if (apiresponse !=null && apiresponse !=undefined)
        {            
           
          getnotification = apiresponse;
          if(getnotification == 1)
            this.checkMessageNotification = true;
          else
            this.checkMessageNotification = false;
        }
    })
  }

}
