<div id="divAlertMessage" class="clsAlertMessage">
  <div class="AlertMessage-title">
    <h3 class="AlertMessageHeader" style="margin: 0 0 10px 11px;">Alert Message
      <span><img class="clscloseimage" src="assets/images/close.png" (click)="onCloseAlertMessage()"></span></h3>
    <mat-divider></mat-divider>
  </div>
  <div class="clsAlertMessagecontent">
    <div [innerHtml]="AlertMessage" id="divAlertMessage_Data"></div>
    <mat-dialog-actions class="clsAlertMessagebuttoncontrol">
      <button class="clsaccbutton_Cancel" id='btnaccessenter' mat-raised-button (click)="onCloseAlertMessage()">OK</button>
    </mat-dialog-actions>
  </div>
</div>