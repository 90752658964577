
<perfect-scrollbar style="max-width: 980px; max-height: 480px;" >
  <div class="clsasset">
      <div class="assetadd-title">
        <h3 class="assetAddHeader" style="margin: 0 0 10px 11px;">Add Asset details
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseAsset()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>

       <div class="clsaddcontent">
        <form style="padding:24px;" [formGroup]="assetCreationForm" autocomplete="off" novalidate (ngSubmit)="createAsset(assetCreationForm.value)">

            <mat-grid-list [cols]="columns" rowHeight="80px" style="align-items: baseline;">

             
                <mat-grid-tile>
                    <label>Tag Id</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="3">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput formControlName="tagid" maxlength="10"/>
                            <mat-error style="font-size: 11px" *ngIf="assetCreationForm.controls.tagid.hasError('required')">
                            Please enter Tag Id
                            </mat-error>
                          <mat-error *ngIf="assetCreationForm.controls.tagid.hasError('pattern')">
                            Please enter the valid Tag Id
                          </mat-error>
                          <mat-error *ngIf="assetCreationForm.controls.tagid.hasError('maxlength')">
                            Tag Id should be between 1 and 1073741823
                          </mat-error>
                          <mat-error *ngIf="assetCreationForm.controls.tagid.hasError('max')">
                            Tag Id should be between 1 and 1073741823
                          </mat-error>
                          <mat-error *ngIf="assetCreationForm.controls.tagid.hasError('min')">
                            Tag Id should be between 1 and 1073741823
                          </mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

             
              <mat-grid-tile>
                <label  class="clstxtcontrol">Asset Name</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput formControlName="assetname" tabindex="1" (keyup)="handleInput($event)" maxlength="30" required>
                      <mat-error style="font-size: 11px" *ngIf="assetCreationForm.controls.assetname.hasError('required')">
                          Please enter Asset Name
                      </mat-error>
                      <mat-error *ngIf="assetCreationForm.controls.assetname.hasError('pattern')">
                          Please enter valid Asset Name
                      </mat-error>
                      <mat-error *ngIf="assetCreationForm.controls.assetname.hasError('maxlength')">
                          Max length 30 characters only
                      </mat-error>
                    </mat-form-field>
                  </div>
              </mat-grid-tile>

              
               <mat-grid-tile>
                <label>Asset Name Alias</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput formControlName="assetnamealias" tabindex="2" (keyup)="handleInput($event)" maxlength="30" required>  
                      <mat-error style="font-size: 11px" *ngIf="assetCreationForm.controls.assetnamealias.hasError('required')">
                        Please enter Asset Name Alias
                    </mat-error>
                    <mat-error *ngIf="assetCreationForm.controls.assetnamealias.hasError('pattern')">
                        Please enter valid Asset Name Alias
                    </mat-error>
                    <mat-error *ngIf="assetCreationForm.controls.assetnamealias.hasError('maxlength')">
                        Max length 30 characters only
                    </mat-error>                   
                    </mat-form-field>
                  </div>
              </mat-grid-tile>               


              <mat-grid-tile>
                <label class="clstxtcontrol">Asset Type</label>
               </mat-grid-tile>
                <mat-grid-tile colspan="3">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput formControlName="assettype" tabindex="3" (keyup)="handleInput($event)" maxlength="30" required>
                      <mat-error style="font-size: 11px" *ngIf="assetCreationForm.controls.assettype.hasError('required')">
                          Please Enter Asset Type
                      </mat-error>
                      <mat-error *ngIf="assetCreationForm.controls.assettype.hasError('pattern')">
                        Please enter valid Asset Type
                    </mat-error>
                    <mat-error *ngIf="assetCreationForm.controls.assettype.hasError('maxlength')">
                        Max length 30 characters only
                    </mat-error>
                    </mat-form-field>
                  </div>
              </mat-grid-tile> 
                             
            </mat-grid-list>

          
            <mat-dialog-actions class="clsassetbuttoncontrol">                  
              <button type="button" class="clsbtnAssetaddCancel" mat-raised-button (click)="onCloseAsset()">Cancel</button>
              <button id="btnsaveclick" class="clsbtnAssetaddSave" mat-raised-button>Save</button>&nbsp;
            </mat-dialog-actions>

        </form>
      </div> 
   
  </div>
  
  </perfect-scrollbar>
  