import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { AssetService } from "./assetservice";
import { EditAsset, getAssetData, deleteAssetData } from "./asset";
import { Router } from "@angular/router";
import { RollbarService } from "../../rollbar";
import * as Rollbar from "rollbar";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SessionCheck, generateAlertMessage, AuthFailedMessage } from "../../app-general";
import { MapSyncProcess } from "../../api";
import { MainLayoutService } from "../../main-layout/main-layout.service";
import { SidenavComponent } from "../../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { AddAssetComponent } from "./add-asset/add-asset.component";
import { EditAssetComponent } from "./edit-asset/edit-asset.component";
import { DownnloadAssetComponent } from "./downnload-asset/downnload-asset.component";
import { ConfirmMessageComponent } from "../../confirm-message/confirm-message.component";
import { ApiService } from "../../api.service";
import { appsettings, enumEnableDisable } from "../../app-settings";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-asset",
  templateUrl: "./asset.component.html",
  providers: [AssetService],
  styleUrls: ["./asset.component.scss"],
})
export class AssetComponent implements OnInit {
  public watcher: Observable<boolean>;
  dataSource;
  dataSourcecount;
  displayedColumns: string[] = [
    "TagId",
    "AssetName",
    "AssetNameAlias",
    "AssetType",
    "edit",
    "delete",
  ];
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  bAddEdit = true;
  bDelete = true;
  assetloading = true;
  downloadAssetSource;
  assetsortedData = [];
  asset_MapSyncProcess;
  authkey;
  assets : any[];
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
  @ViewChild("rightNav") public rightNav: MatSidenav;
  chksearchvalue;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    public mainLayoutService: MainLayoutService,    
    private assetService: AssetService,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.XLarge) 
    || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupFullTable();
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.getassetdata();
  }

  setupTableforXtrasmallerwidth() {
    this.displayedColumns = ["TagId", "AssetName", "edit", "delete"];
  }
  setupTableforsmallerwidth() {
    this.displayedColumns = [
      "TagId",
      "AssetName",
      "AssetType",
      "edit",
      "delete",
    ];
  }
  setupFullTable() {
    this.displayedColumns = [
      "TagId",
      "AssetName",
      "AssetNameAlias",
      "AssetType",
      "edit",
      "delete",
    ];
  }

  getassetdata(): void {
    try {
      this.assetloading = true;
      const residentdata: getAssetData = {
        authKey: this.authkey,
      };
      this.assetService
        .getAsset(residentdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Asset: any[]) => {
          this.assetloading = false;
          this.assets = Asset["apiReturn"];
          this.dataSource = new MatTableDataSource(this.assets);
          this.downloadAssetSource = new MatTableDataSource(this.assets);
          if (this.chksearchvalue == undefined) {
            this.dataSource.data = this.assets;
            this.dataSourcecount = this.dataSource.data.length;
            this.downloadAssetSource.data = this.assets;
          } else {
            this.searchAsset(this.chksearchvalue);
            this.dataSourcecount = this.dataSource.filteredData.length;
          }
          setTimeout(() => {
            this.assetsortedData = [];
            this.dataSource.sort = this.sort;
            this.sortingDatalist(this.dataSource);
            this.downloadAssetSource.sort = this.sort;
            this.sortingDatalist(this.downloadAssetSource);
            this.downloadAssetSource
              .connect()
              .subscribe((data) => (this.assetsortedData = data));
          }, 0);

          this.dataSource.paginator = this.paginator;
        });
    } catch (err) {
      throw new Error(
        "asset.component.ts" + " - getassetdata() - " + err.message
      );
    }
  }

  searchAsset(filterValue: string) {
    if (filterValue != null) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.chksearchvalue = filterValue;
      this.dataSource.filter = filterValue;
      this.searchfilter();
      this.assetsortedData = this.dataSource.filteredData;
      this.dataSourcecount = this.dataSource.filteredData.length;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.downloadAssetSource.sort = this.sort;
        this.downloadAssetSource.connect().subscribe((data) => (this.assetsortedData = data));
      }, 0);
    }
  }

  searchfilter() {
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      const filterinfo = filter.toLowerCase();
      return (
        data.AssetName.toLowerCase().includes(filterinfo) ||
        data.AssetNameAlias.toLowerCase().includes(filterinfo) ||
        data.TagId.toString().includes(filterinfo) ||
        data.AssetType.toLowerCase().includes(filterinfo)
      );
    };
  }

  /*********Openadddialogwindow*******/
  downloadAssetdata() {
    if (!SessionCheck(this.router)) {
      return;
    }
    if (this.dataSource.filter != "") {
      this.downloadAssetSource.data = this.dataSource.filteredData;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.dataSource
          .connect()
          .subscribe((data) => (this.assetsortedData = data));
      }, 0);
    }
    const assetdata = this.assetsortedData;
    const dialogRef = this.dialog.open(DownnloadAssetComponent, {
      disableClose: true,
      width: "388px",
      height: "165px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: assetdata,
    });
    dialogRef.afterClosed().subscribe(() => {
      const element1 = document.getElementById("downloadbtn");
      if (element1 != null)
        element1.className =
          "clsassetaddbutton mat-raised-button mat-primary ng-star-inserted";
    });
  }
  addAssetdata() {
    if (!SessionCheck(this.router)) {
      return;
    }
    this.assetEnableDisable(enumEnableDisable.Disable);
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.asset_MapSyncProcess = MapSyncinfo;
        if (this.asset_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          this.assetEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          const dialogRef = this.dialog.open(AddAssetComponent, {
            disableClose: true,
            width: "900px",
            height: "350px",
            panelClass: "myapp-no-padding-dialog",
            autoFocus: false,
            data: this.dataSource.data,
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.assetEnableDisable(enumEnableDisable.Enable);
            if (result == "Confirm") {
              const element = document.getElementById("btnaddasset");
              if (element != null)
                element.className = "clsassetaddbutton mat-raised-button mat-primary ng-star-inserted";
              this.getassetdata();
            } else {
              const element = document.getElementById("btnaddasset");
              if (element != null)
                element.className = "clsassetaddbutton mat-raised-button mat-primary ng-star-inserted";
            }
          });
        }
      });
  }

  editAssetdata(data) {
    this.assetEnableDisable(enumEnableDisable.Disable);
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.asset_MapSyncProcess = MapSyncinfo;
        if (this.asset_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          this.assetEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          const editdata: EditAsset = {
            TagId: data.TagId,
            AssetName: data.AssetName,
            AssetNameAlias: data.AssetNameAlias,
            AssetType: data.AssetType,
            AssetId: data.AssetId,
          };

          const dialogRef = this.dialog.open(EditAssetComponent, {
            disableClose: true,
            width: "900px",
            height: "350px",
            panelClass: "myapp-no-padding-dialog",
            autoFocus: false,
            data: editdata,
          });

          dialogRef.afterClosed().subscribe((result) => {
            this.assetEnableDisable(enumEnableDisable.Enable);
            if (result == "Confirm") {
              this.getassetdata();
            }
          });
        }
      });
  }

  deleteAssetdata(data) {
    this.assetEnableDisable(enumEnableDisable.Disable);
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.asset_MapSyncProcess = MapSyncinfo;
        if (this.asset_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          this.assetEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          const deleteasset: deleteAssetData = {
            authKey: this.authkey,
            assetId: data.AssetId,
          };
          this.confirmation(deleteasset, data);
        }
      });
  }
  confirmation(deleteasset, data) {
    try {
      const ConfirmMessageData = {
        ConfirmMessage:
          "Are you sure you want to delete this asset " +
          data.AssetName +
          " ( " +
          data.TagId +
          " ) details? ",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          this.deleteAsset(deleteasset);
        } else {
          this.assetEnableDisable(enumEnableDisable.Enable);
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "delete-device.component.ts" + " - confirmation() - " + err.message
      );
    }
  }

  deleteAsset(deleteasset) {
    this.assetService
      .deleteAsset(deleteasset)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: any[]) => {
        this.assetEnableDisable(enumEnableDisable.Enable);
        if (apiresponse != null && apiresponse != undefined) {
          if (apiresponse["apiReturn"] == "Success") {
            generateAlertMessage(this.dialog, "Asset details deleted successfully.");
          } 
          else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Asset details deleted failed.");
          }
          else if (apiresponse["apiReturn"] == "Invalid Auth Key") {           
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.getassetdata();
        } 
        else {
          generateAlertMessage(this.dialog, "Asset details deleted failed.");
        }
      });
  }
  sortingDatalist(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (sortHeaderId != "TagId") {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }
  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  assetEnableDisable(checkflag) {
    const elementcontent = document.getElementById("divassetdetails");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsenable";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsdisable";
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
