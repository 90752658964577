import { Component, OnInit, NgZone, ViewChild, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { MainLayoutService } from "../main-layout.service";
import { MatSidenav } from "@angular/material/sidenav";
import { LeftmenuService } from "./sidenav.service";
import { sidenav } from "./sidenav";
import {
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../app-settings";
import { AlertSharedService } from "../../_services/index";
import {
  ShareRightSideNav,
  ShareddialogcloseData,
  ShareInfraopenMsg,
} from "../../api";
import { getFeatureNeedtoShow } from "../../app-general";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppComponent } from "../../app.component";
@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit, OnDestroy {
  public static isClosed = true;
  //Sidenav responsive
  width;
  height;
  mode = "side";
  open = "true";
  title = "LTC";
  ShareSideNavDetails: ShareRightSideNav;
  ShareddialogcloseDetails: ShareddialogcloseData;
  @ViewChild("leftMenu") public leftmenu: MatSidenav;
  @ViewChild("rightNav") public rightNav: MatSidenav;
  private _onDestroy: Subject<void> = new Subject<void>();
  ShareInfraopenDetails: ShareInfraopenMsg;
  Isinfra = 0;
  constructor(
    public ngZone: NgZone,
    public route: Router,
    public mainLayoutService: MainLayoutService,
    private leftmenuservice: LeftmenuService,
    private alertSharedService: AlertSharedService
  ) {
    this.mainLayoutService.toggleSideNavLeft.subscribe(() => {
      this.leftmenu.toggle();
    });

    this.mainLayoutService.toggleSideNavRight.subscribe(() => {
      this.rightNav.toggle();
      let _ShareSideNavDetails;
      if (this.rightNav._animationState == "open") {
        SidenavComponent.isClosed = false;
        _ShareSideNavDetails = {
          IscheckSideNav: 1,
        };
      } else if (this.rightNav._animationState == "void") {
        SidenavComponent.isClosed = true;
        _ShareSideNavDetails = {
          IscheckSideNav: 0,
        };
      }
      this.alertSharedService.changedSideNav(_ShareSideNavDetails);
    });

    this.changeMode();
    window.onresize = (e) => {
      console.log(e);  
      ngZone.run(() => {
        this.changeMode();
      });
    };
  }
  sidenav: sidenav[] = [];
  ngOnInit() {
    if (
      localStorage.getItem("role") != null &&
      AppComponent.StaffUserRoleView.length > 0
    ) {
      const StaffUserRoleView = JSON.parse(
        JSON.stringify(AppComponent.StaffUserRoleView)
      );
      if (localStorage.getItem("role") == StaffUserRoleView[0]["RoleId"]) {
        this.getSideNavList();
      } else {
        this.getUserRole();
      }
    } else if (localStorage.getItem("role") != null) {
      this.getUserRole();
    }

    this.alertSharedService
      .currentsharedInfraAlert()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((ShareInfraopenDetails: any) => {
        this.ShareInfraopenDetails = ShareInfraopenDetails;
        if (this.ShareInfraopenDetails != undefined) {
          this.Isinfra = this.ShareInfraopenDetails.IsfraOpen;
        }
      });
  }

  public toggleRightNav() {
    if (this.Isinfra == 1) {
      const _ShareInfraopenDetails = {
        IsfraOpen: 0,
      };
      this.alertSharedService.changedInfraAlert(_ShareInfraopenDetails);
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
    this.mainLayoutService.toggleSideNavRight.emit();
  }

  changeMode() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    if (this.width <= 800) {
      this.mode = "over";
      this.open = "false";
    }
    if (this.width > 800) {
      this.mode = "side";
      this.open = "true";
    }
  }
  public roterlink() {
    const _ShareddialogcloseDetails = {
      SharedreturncloseVal: 0,
    };
    this.alertSharedService.changeclosedialog(_ShareddialogcloseDetails);
  }

  getUserRole(): void {
    const authkey = localStorage.getItem("Authkey") ?? "";
    const roleId = localStorage.getItem("role") ?? "";
    const roledetails = {
      authKey: authkey,
      roleId: parseInt(roleId),
    };
    this.mainLayoutService
      .getUserRole(roledetails)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((StaffUserRoleView: []) => {
        AppComponent.StaffUserRoleView = StaffUserRoleView;
        localStorage.setItem(
          "StaffUserRoleView",
          JSON.stringify(AppComponent.StaffUserRoleView)
        );
        this.getSideNavList();
      });
  }

  getSideNavList() {
    let ShowResident = 0;
    let ShowStaff = 0;
    let ShowAsset = 0;

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Resident,
        enumUIAction.Resident_View
      ) == enumUIView.Show
    ) {
      ShowResident = 1;
    }
    if (
      getFeatureNeedtoShow(enumUIFeatureView.Staff, enumUIAction.Staff_View) ==
      enumUIView.Show
    ) {
      ShowStaff = 1;
    }
    if (localStorage.getItem("asset_config") == "1") {
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Asset,
          enumUIAction.Asset_View
        ) == enumUIView.Show
      ) {
        ShowAsset = 1;
      }
    }

    if (ShowResident == 1 && ShowStaff == 1 && ShowAsset == 1) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "resident",
        src: "assets/images/More Menu-Info-Icon@2x.png",
        line1desc: "Resident/Staff Information",
        line2desc: "Edit/Add/Discharge",
      };
    } else if (ShowResident == 1 && ShowStaff == 1 && ShowAsset == 0) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "resident",
        src: "assets/images/More Menu-Info-Icon@2x.png",
        line1desc: "Resident/Staff Information",
        line2desc: "Edit/Add/Discharge",
      };
    } else if (ShowResident == 0 && ShowStaff == 1 && ShowAsset == 1) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "staff",
        src: "assets/images/More Menu-Info-Icon@2x.png",
        line1desc: "Staff/Asset Information",
        line2desc: "Edit/Add/Delete",
      };
    } else if (ShowResident == 1 && ShowStaff == 0 && ShowAsset == 1) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "resident",
        src: "assets/images/More Menu-Info-Icon@2x.png",
        line1desc: "Resident Information",
        line2desc: "Edit/Add/Discharge",
      };
    } else if (ShowResident == 1 && ShowStaff == 0 && ShowAsset == 0) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "resident",
        src: "assets/images/More Menu-Info-Icon@2x.png",
        line1desc: "Resident Information",
        line2desc: "Edit/Add/Discharge",
      };
    } else if (ShowResident == 0 && ShowStaff == 1 && ShowAsset == 0) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "staff",
        src: "assets/images/More Menu-Info-Icon@2x.png",
        line1desc: "Staff Information",
        line2desc: "Edit/Add/Discharge",
      };
    } else if (ShowResident == 0 && ShowStaff == 0 && ShowAsset == 1) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "asset",
        src: "assets/images/More Menu-Info-Icon@2x.png",
        line1desc: "TruFinder Information",
        line2desc: "TruFinder Configuration",
      };
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Notification,
        enumUIAction.Notification_View
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "notification",
        src: "assets/images/More Menu-Notifications-Icon@2x.png",
        line1desc: "Notifications",
        line2desc: "Add/Edit escalations",
      };
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_View
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "technologyoverlay",
        src: "assets/images/More Menu-Tech Overlay-Icon@2x.png",
        line1desc: "Technology Overlay",
        line2desc: "Edit/Setup floorplans and devices",
      };
    } else if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Templates_View
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "templates",
        src: "assets/images/More Menu-Tech Overlay-Icon@2x.png",
        line1desc: "Technology Overlay",
        line2desc: "Edit/Setup floorplans and devices",
      };
    } else if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_List_View
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "device",
        src: "assets/images/More Menu-Tech Overlay-Icon@2x.png",
        line1desc: "Technology Overlay",
        line2desc: "Edit/Setup floorplans and devices",
      };
    } else if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Outdoor_Locating_View
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "outdoorlocating",
        src: "assets/images/More Menu-Tech Overlay-Icon@2x.png",
        line1desc: "Technology Overlay",
        line2desc: "Edit/Setup floorplans and devices",
      };
    } else if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Hardwired_Configration_View
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "hardwiredconfiguration",
        src: "assets/images/More Menu-Tech Overlay-Icon@2x.png",
        line1desc: "Technology Overlay",
        line2desc: "Edit/Setup floorplans and devices",
      };
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Main_Dashboard
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "report",
        src: "assets/images/More Menu-Report-Icon@2x.png",
        line1desc: "Reports",
        line2desc: "View Report details",
      };
    } else if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Alert_Report
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "alertreport",
        src: "assets/images/More Menu-Report-Icon@2x.png",
        line1desc: "Reports",
        line2desc: "View Report details",
      };
    } else if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Infrastructure_Report
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "infrastructurereport",
        src: "assets/images/More Menu-Report-Icon@2x.png",
        line1desc: "Reports",
        line2desc: "View Report details",
      };
    } else if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Location_History_Report
      ) == enumUIView.Show
    ) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "locationhistory",
        src: "assets/images/More Menu-Report-Icon@2x.png",
        line1desc: "Reports",
        line2desc: "View Report details",
      };
    } else if (ShowAsset == 1) {
      this.sidenav[this.sidenav.length] = {
        routerLink: "customizelocators",
        src: "assets/images/More Menu-Report-Icon@2x.png",
        line1desc: "Reports",
        line2desc: "View Report details",
      };
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
