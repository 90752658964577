export interface getResidentbyid {
  ResidentID: number;
  LastName: string;
  SpouseName: string;
  DateofBirth: string;
  Email: string;
  Phone: string;
  photo: string;
  TagId: number;
  UpdatedOn: string;
  Role: number;
  FloorId: number;
  BuildId: number;
  CampusId: number;
  svgId: number;
  IsCampus: number;
  RoomName: number;
  RoomId: number;
}

export interface ResidentForCreation {
  authKey: string;
  firstName: string;
  lastName: string;
  spouseName: string;
  dateOfBirth: string;
  gender: string;
  photo: string;
  tagId: number;
  facility: string;
  campusId: number;
  buildingId: number;
  floorId: number;
  unitId: number;
  phone: string;
  pager: string;  
  email: string;
  groupId: string;
  residentCheckIn : number;
  isNeedVoice : number;
  isWanderMode : number;
}

export interface ResidentEditForCreation {
  authKey: string;
  residentId: number;
  firstName: string;
  lastName: string;
  spouseName: string;
  dateOfBirth: string;
  gender: string;
  photo: string;
  tagId: number;
  facility: string;
  campusId: number;
  buildingId: number;
  floorId: number;
  unitId: number;
  phone: string;
  pager: string;
  email: string;
  groupId: string;
  deleteImage:number;
  residentCheckIn : number;
  isNeedVoice : number;
  isWanderMode  : number;
}
export interface ResidentForCreationResponse {
  condition: string;
}
export interface ResidentByID {
  authKey: string;
  residentId: number;
}

export interface ReleAuthorize {
  StaffId: number;
  StaffName: string;
}

export interface ReleaseResident {
  authKey: string;
  residentId: number;
  status: number;
  reason: string;
  authorizedBy: string;
  tagId: number;
}

export interface residentrelease {
  ResidentbyId: string;
  Reltype: number;
}

export enum Residentreleasetype {
  Release = 1,
  Reallocate = 2,
}

export interface ResidentMedHistory {
  authKey: string;
  notes: string;
  allergies: string;
  emergContactName: string;
  emergContactNo: string;
  emergContactEmail: string;
  emrReportStatus: string;
  residentId: number;
}

export interface ResidentMedicalHistorydetails {
  ResidentId: number;
  AddType: number;
}

export interface UpdateResidentMedHistory {
  authKey: string;
  notes: string;
  allergies: string;
  emergContactName: string;
  emergContactNo: string;
  emergContactEmail: string;
  emrReportStatus: string;
  residentId: number;
  medHistoryId: number;
}

export interface ShowEMRFileReport {
  authKey: string;
  residentId: number;
  fileName: string;
  procedureDate: string;
  source: string;
  uploadDate: Date;
}

export interface ReportById {
  authKey: string;
  reportId: number;
}

export interface ReportbyName {
  authKey: string;
  fileName: string;
}

export interface ResidentGroupForCreation {
  authKey: string;
  groupname: string;
  description: string;
}
export interface ResidentGroupForCreationResponse {
  condition: string;
}
export interface ResidentEditGroupForCreation {
  authKey: string;
  groupname: string;
  description: string;
  groupId: string;
}

export interface AddEditRoaming {
  authKey: string;
  residentId: number;
  wrongRoom: string;
  wStartHr: number;
  wStartMi: number;
  wEndHr: number;
  wEndMi: number;
  wchkAllday: number;
  allEgress: string;
  aStartHr: number;
  aStartMi: number;
  aEndHr: number;
  aEndMi: number;
  achkAllday : number,
  wrongRoomId : string,
  egressRoomId : string,
  wrongExpireDate : number;
  egressExpireDate : number;
}

export interface checkEmrFileData {
  authKey: string;
  residentId: number;
}

export interface getGroupByResident {
  authKey: string;
  selectedGroup: string;
}

export interface deleteResidentGroup {
  authKey: string;
  groupId: number;
}

export interface getAlertByTagId {
  authKey: string;
  tagId: number;
}

export interface checkAlertForCampus {
  authKey: string;
  oldTagId: number;
  resStaffId: number;
  campusId: number;
  buildId: number;
  floorId: number;
  unitId: number;
}

export interface getCampusRestrictedSpace {
  authKey: string;
  residentId: number;
  spaceCategory: number;
}