import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { MainLayoutService } from '../main-layout.service';
import { Router } from '@angular/router';
import { AlertSharedService } from '../../_services/index';
import { ShareUserdetails,ShareddialogcloseData } from '../../api';
import {appsettings} from '../../app-settings';
import { ApiService } from '../../api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionCheck } from '../../app-general';
import { HomeService } from '../../landing/home/home.service';

import { currentsharedHTTPRequest,closealertpushSocketConForUpdates,CloseLocationChangeSocket } from '../../app.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit,OnDestroy {
  width;
  height;
  username;
  userfullname;
  photo;
  staffImgUrl;
  ShareUserDetails={} as ShareUserdetails;
  ShareddialogcloseDetails: ShareddialogcloseData;
  chkConnectionStatus = 'divConnectedStatus';
  chkImageConnectionStatus = appsettings.HomeConnect;
  chkdivConnectionStatus = 0;
  lblConnectionStatustxt;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(public mainLayoutService: MainLayoutService,private homeService: HomeService,
    public ngZone: NgZone, public router: Router, private alertSharedService: AlertSharedService,private apiService: ApiService) {      
      this.alertSharedService.currentsharedUserNav().pipe(takeUntil(this._onDestroy)).subscribe((ShareUserdetails: ShareUserdetails) => {     
        
        this.ShareUserDetails = ShareUserdetails
        if(this.ShareUserDetails.IssetUserInfo == 1)
        {            
          this.username = JSON.parse(localStorage.getItem("username"));
          this.photo = localStorage.getItem("Photo");
        }
      else
      {    
        this.username = JSON.parse(localStorage.getItem("username"));
        this.photo = localStorage.getItem("Photo");
      }
      
      if(this.username.length > 15)
      {
        this.userfullname = this.username;
        this.username = this.username.slice(0, 10) + "..";               
      }
      else
      {
        this.userfullname = this.username;
      }
        
    this.staffImgUrl = appsettings.STAFF_FOLDER + this.photo;
    this.changeMode();
    window.onresize = (e) => { 
      console.log(e);    
      ngZone.run(() => {
        this.changeMode();
      });
    };
    
      if(window.innerWidth <= 750)
      {       
        this.lblConnectionStatustxt = "";
      } 
      else if(window.innerWidth <= 880)
      {
        this.lblConnectionStatustxt = "Status:";
      }       
      else
        this.lblConnectionStatustxt = "Connection Status:";
  });

  }
  ShareHTTPRequestDetails = {
    "IsError": 0
  };
  ngOnInit() {
    SessionCheck(this.router);
    this.lblConnectionStatustxt = "Connection Status:";
    currentsharedHTTPRequest().pipe(takeUntil(this._onDestroy)).subscribe((ShareHTTPRequestDetails: any) => {
      this.ShareHTTPRequestDetails = ShareHTTPRequestDetails;  
      
      if( this.ShareHTTPRequestDetails.IsError == 1)
      {
        this.chkConnectionStatus = 'divNotConnectedStatus';
        this.chkImageConnectionStatus = appsettings.HomeDisConnect;
        this.chkdivConnectionStatus = 0;
      }
      else{
        this.chkConnectionStatus = 'divConnectedStatus';
        this.chkImageConnectionStatus = appsettings.HomeConnect;
        this.chkdivConnectionStatus = 1;
      }
    });
    
    window.onresize = (e) => {    
      console.log(e);    
      if(window.innerWidth <= 750)
      {       
        this.lblConnectionStatustxt = "";
      } 
      else if(window.innerWidth <= 880)
      {
        this.lblConnectionStatustxt = "Status:";
      }       
      else
        this.lblConnectionStatustxt = "Connection Status:";
    }

  }

  public toggleLeftMenu() {
    this.mainLayoutService.toggleSideNavLeft.emit();
  }

  public toggleRightNav() {
  
    this.mainLayoutService.toggleSideNavRight.emit();
  }

  Logout(): void {
    const _ShareddialogcloseDetails = {
      "SharedreturncloseVal":  0     
    };
    this.alertSharedService.changeclosedialog(_ShareddialogcloseDetails);    
    localStorage.clear();
    closealertpushSocketConForUpdates();
    CloseLocationChangeSocket();
    this.router.navigate(['/login']);
  }
  profile(): void {
    //alert("profile");
  }
  changeMode() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
