<app-report-header  [ReportType]="ReportType"></app-report-header>
<mat-card class="report-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-report-leftbar ></app-report-leftbar>
    </div>
      
    <div fxLayout="column" style="width:100%;">
    <!--Customize Geo Report-->
    <div class="table-container">
     
        <div class="main-table"> 
        <div class="landing-title-bar">
          <div fxFlex="70" fxFlex.md="40" fxFlex.sm="30" fxFlex.xs="20">
            <mat-card-header>
              <mat-card-title style="font-size: 20px;margin-top:15px;">
                <span class="clsTitle">Customize Naming</span>
              </mat-card-title>
            </mat-card-header>
          </div>
        </div> 

        <perfect-scrollbar class="chartscrollbarCusGeo">
        <div>
          <div fxLayout.md="column" fxLayout.sm="column">                    
            <!-- <button id="btnCustomizeGeoEnable" class="clsCustomgeobtndisable" color="primary" mat-raised-button (click)="getcustomizeGeoenable()">Enable</button> -->             
            <div *ngFor="let location of LocationType" style="display: inline-block;margin-top:10px;margin-left:-2.5em;">
              <span class="dot" [ngStyle]="{'background-color': location.TypeColor}"></span><span class="clsspantext">{{location.TypeName}}</span>
            </div>
          </div>  
          
          <div class="customsearch" fxLayout.md="column" fxLayout.sm="column">
              <mat-form-field appearance="outline" floatLabel="left" class="clslivematformfield">
                <input matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchCustomGeoReport($event.target.value)"
                  autocomplete="off" placeholder="Finder" #searchCusGeoInput>
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
              
          <div  style="float:right;">
            <mat-form-field appearance="outline" floatLabel="auto" class="clslivematformfield">
              <mat-select placeholder="Color Config" [(ngModel)]="selectedLocationType" (selectionChange)="cusloc_selectLocationType($event.value)">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let location of LocationType" [value]="location.LocationTypeId" title="{{ location.TypeName.length >= 15 ? location.TypeName : ''}}">{{location.TypeName}}</mat-option>
              </mat-select>
            </mat-form-field>              
              
              <button class="clsImportbutton" color="primary" mat-raised-button (click)="addAssetdetails()">Add Device</button>
          </div>
        </div>    
          </div>

          <div *ngIf="CustomizeGeoSource else noCusGeoRecord">
            <table mat-table [dataSource]="CustomizeGeoSource" matSort (matSortChange)="sortData($event)" #CusGeosort="matSort" matSortDisableClear=true>                 

                <!-- GeoName Column -->
                <ng-container matColumnDef="G_GeoName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Asset Name</th>                  
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.G_GeoName.length < 12" >{{element.G_GeoName}}</span>
                    <span *ngIf="element.G_GeoName.length >= 12" matTooltipClass="cusgeotooltip" matTooltip={{element.FullGeoName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.G_GeoName}}</span>                    
                  </td>                   
                </ng-container>

                <ng-container matColumnDef="G_Edit">
                <th mat-header-cell *matHeaderCellDef class="clsmatheadercell">Edit</th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon (click)="CusGeo_editAssetName(element)" style="cursor: pointer;">edit</mat-icon>                   
                </td>                  
                </ng-container>

                <!-- TagId Column -->
                <ng-container matColumnDef="R_TagId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Tag Id</th>
                  <td mat-cell *matCellDef="let element"> {{element.R_TagId}} </td>
                </ng-container>

                <!-- TruLocation Column -->
              <ng-container matColumnDef="G_TruLocation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Location Name</th>
                <td mat-cell *matCellDef="let element"> 
                  <span *ngIf="element.G_TruLocation.length < 12" >{{element.TruLocation}}</span>
                  <span *ngIf="element.G_TruLocation.length >= 12" matTooltipClass="cusgeotooltip" matTooltip={{element.FullTruLocation}} #tooltip="matTooltip" matTooltipPosition="above">{{element.G_TruLocation}}</span>
                </td>
              </ng-container>
           
               <!-- LastTimeStamp Column -->
               <ng-container matColumnDef="G_Updatedon">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">Last Time Stamp</th>
                <td mat-cell *matCellDef="let element"> {{element.G_Updatedon}} </td>
              </ng-container>

              <!-- State Column -->
              <ng-container matColumnDef="G_roomcolor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left:2.5em;" class="clsmatheadercell">State</th>
                <td mat-cell *matCellDef="let element">                  
                <div id="divCustomGeo" *ngFor="let location of LocationType" style="display: inline-block;">
                  <div class="dotlist" [id]="element.AssetId+'_'+location.LocationTypeId" [ngStyle]="{'background-color': location.TypeColor}" [ngClass]="element.G_roomcolor == location.LocationTypeId ?'dotselectedlist':'dotlist'" (click)="getdivclickelement(element.DeviceId,location.LocationTypeId)"></div>
                </div>
                </td>
              </ng-container> 
              
              <!-- StateChanged Column -->
              <!-- <ng-container matColumnDef="stateChangebyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="clsmatheadercell">State Changed By</th>
                <td mat-cell *matCellDef="let element">{{element.stateChangebyName}}</td>
              </ng-container>  -->
                          
              <tr mat-header-row *matHeaderRowDef="displayedCustomizeGeoColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns:displayedCustomizeGeoColumns;"></tr>
            </table>

            <h3 *ngIf="CustomizeGeoSource.data.length == 0 && !customizegeoloading" style="text-align: center;margin-top:1em;"> No Record found </h3>     

          </div>

          <ng-template #noCusGeoRecord>
                     
              <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%">       
                  <div class="loadspinner" *ngIf="customizegeoloading">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                  </div>
              </div>            
        </ng-template> 

          <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
        </perfect-scrollbar>
        </div>
        </div>
    </div>

  </div>
</mat-card>
   
