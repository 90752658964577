import { Component, OnInit, ViewChild, Inject, OnDestroy } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import {
  StaffDesignationList,
  StaffGroupList,
  StaffByIDparam,
  getStaffbyid,
} from "./staff";
import { StaffService } from "./staff.service";
import { HomeService } from "../home/home.service";
import { appsettings, enumEnableDisable } from "../../app-settings";
import { AddStaffComponent } from "./add-staff/add-staff.component";
import { EditStaffComponent } from "./edit-staff/edit-staff.component";
import { DeleteStaffComponent } from "./delete-staff/delete-staff.component";
import { DownloadStaffComponent } from "./download-staff/download-staff.component";
import {
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../app-settings";
import { ShowStaffGroupComponent } from "./show-staff-group/show-staff-group.component";
import { MainLayoutService } from "../../main-layout/main-layout.service";
import { SidenavComponent } from "../../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { RollbarService } from "../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheckwithStaff,
  checkStaffData,
  getFeatureNeedtoShow,
  getAddEditDeleteColumn,
  AuthFailedMessage,
  generateAlertMessage,
} from "../../app-general";
import { Router } from "@angular/router";
import { ApiService } from "../../api.service";
import {
  SharedCheckIsAlertReportData,
  Staff,
  MapSyncProcess,
  getStaffData,
} from "../../api";
import { AlertSharedService } from "../../_services/index";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-staff",
  templateUrl: "./staff.component.html",
  providers: [StaffService],
  styleUrls: ["./staff.component.scss"],
})
export class StaffComponent implements OnInit, OnDestroy {
  Staffs: Staff[];
  staffDesignationList: StaffDesignationList[];
  staffGroupList: StaffGroupList[];
  Staffbyid: getStaffbyid[];
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  bGroupView = false;
  bAddEdit = false;
  bDelete = false;
  displayedColumns: string[] = [
    "photo",
    "Name",
    "TagId",
    "Designation",
    "Phone",
    "Email",
    "GroupName",
    "RoomName",
    "edit",
    "delete",
  ];
  dataSource;
  downloadstaffSource;
  dataSourcecount;
  IsRefreshNeed = 1;

  staffImgUrl: string = appsettings.STAFF_FOLDER;
  chksearchvalue;
  StaffId;
  staffsortedData = [];
  staffloading = true;
  Stafflength;
  checklivestaffdata;
  Logged_RoleID = 0;
  staff_MapSyncProcess;
  interval_staff;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
  private _onDestroy: Subject<void> = new Subject<void>();
  public watcher: Observable<boolean>;
  constructor(
    private router: Router,
    private staffService: StaffService,    
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog,
    public mainLayoutService: MainLayoutService,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    private homeService: HomeService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Staff,
        enumUIAction.Staff_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Staff_Group,
        enumUIAction.Staff_Group_View
      ) == enumUIView.Show
    ) {
      this.bGroupView = true;
    }
    if(this.breakpointObserver.isMatched(Breakpoints.Large) 
    || this.breakpointObserver.isMatched(Breakpoints.XLarge) 
    || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupFullTable();
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.Small))
    {
      this.setupTableforsmallerwidth();
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(enumUIFeatureView.Staff, enumUIAction.Staff_View) !=
      enumUIView.Show
    ) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Staff,
        enumUIAction.Staff_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Staff_Group,
        enumUIAction.Staff_Group_View
      ) == enumUIView.Show
    ) {
      this.bGroupView = true;
    }

    if (this.IsRefreshNeed == 1) {
      let ChkIsAlertReportChanged = true;

      this.alertSharedService
        .currentSharedCheckIsAlertReport()
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
          (SharedCheckIsAlertReportDetails: SharedCheckIsAlertReportData) => {
            const CheckIsAlertReportDataChanged =
              SharedCheckIsAlertReportDetails;
            if (CheckIsAlertReportDataChanged != undefined) {
              if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 1 &&
                ChkIsAlertReportChanged
              ) {
                ChkIsAlertReportChanged = false;

                setTimeout(() => {
                  this.readLiveData();
                  this.checkStaffById();
                  this.getStaffData();
                }, 1000 * 60 * 1);

                CheckIsAlertReportDataChanged.IsAlertReportChanged = 0;
                this.alertSharedService.changeSharedCheckIsAlertReport(
                  CheckIsAlertReportDataChanged
                );
              } else if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 0
              ) {
                ChkIsAlertReportChanged = true;
              }
            }
          }
        );
    }

    this.readLiveData();
    this.checkStaffById();
    this.getStaffData();

    const getStaff_Role = localStorage.getItem("role");
    this.StaffId = localStorage.getItem("userid");
    if (getStaff_Role != null) this.Logged_RoleID = parseInt(getStaff_Role);
  }

  readLiveData() {
    this.viewRoomData();
  }

  viewRoomData() {
    const sReturn = getFeatureNeedtoShow(
      enumUIFeatureView.Location,
      enumUIAction.Location_RTLS_Staff
    );
    if (sReturn.toString() == enumUIView.Show.toString()) {
      this.checklivestaffdata = "1";
    } else {
      this.checklivestaffdata = "0";
    }

    const RoomIndex = this.displayedColumns.indexOf("RoomName");
    if (this.checklivestaffdata != undefined) {
      if (this.checklivestaffdata != "1") {
        if (RoomIndex >= 0) {
          this.displayedColumns.splice(RoomIndex, 1);
        }
      }
    }
  }

  checkStaffById() {
    checkStaffData(this.apiService, this.alertSharedService);
  }

  setupTableforXtrasmallerwidth() {
    this.displayedColumns = ["Name", "Designation", "edit", "delete"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableforsmallerwidth() {
    this.displayedColumns = ["photo", "Name", "Designation", "edit", "delete"];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupFullTable() {
    this.displayedColumns = [
      "photo",
      "Name",
      "TagId",
      "Designation",
      "Phone",
      "Email",
      "GroupName",
      "RoomName",
      "edit",
      "delete",
    ];
    this.viewRoomData();
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  getStaffData(): void {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.staffloading = true;
      const staffItem: getStaffData = {
        authKey: this.authkey,
      };
      this.apiService
        .getStaffData(staffItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Staffs: any[]) => {
          if (Staffs["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.staffloading = false;
          this.Staffs = Staffs["apiReturn"];
          this.Stafflength = Staffs["apiReturn"][0].MaxId;

          this.dataSource = new MatTableDataSource(this.Staffs);
          this.downloadstaffSource = new MatTableDataSource(this.Staffs);
          if (this.chksearchvalue == undefined) {
            this.dataSource.data = this.Staffs;
            this.downloadstaffSource.data = this.Staffs;
          } else {
            this.searchStaff(this.chksearchvalue);
          }

          setTimeout(() => {
            this.staffsortedData = [];
            this.dataSource.sort = this.sort;
            this.sortingDatalist(this.dataSource);
            this.downloadstaffSource.sort = this.sort;
            this.sortingDatalist(this.downloadstaffSource);
            this.downloadstaffSource
              .connect()
              .subscribe((data) => (this.staffsortedData = data));
          }, 0);
          this.dataSource.paginator = this.paginator;
          if (this.chksearchvalue == undefined)
            this.dataSourcecount = this.dataSource.data.length;
          else this.dataSourcecount = this.dataSource.filteredData.length;
        });
    } catch (err) {
      throw new Error(
        "staff.component.ts" + " - getStaffData() - " + err.message
      );
    }
  }

  searchStaff(filterValue: string) {
    if (filterValue != null) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.chksearchvalue = filterValue;
      this.dataSource.filter = filterValue;
      this.searchfilter();
      this.staffsortedData = this.dataSource.filteredData;
      this.dataSourcecount = this.dataSource.filteredData.length;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.sortingDatalist(this.dataSource);
        this.downloadstaffSource.sort = this.sort;
        this.sortingDatalist(this.downloadstaffSource);
        this.downloadstaffSource
          .connect()
          .subscribe((data) => (this.staffsortedData = data));
      }, 0);
    }
  }

  searchfilter() {
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      const filterinfo = filter.toLowerCase();
      return (
        data.Name.toLowerCase().includes(filterinfo) ||
        data.Email.toLowerCase().includes(filterinfo) ||
        data.TagId.toString().includes(filterinfo) ||
        data.RoomName.toLowerCase().includes(filterinfo) ||
        data.GroupName.toLowerCase().includes(filterinfo) ||
        data.Designation.toLowerCase().includes(filterinfo) ||
        data.Phone.toString().includes(filterinfo)
      );
    };
  }

  /*********Opendialogwindow*******/
  addStaffdata() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    this.staffAddEnableDisable(enumEnableDisable.Disable);
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.staff_MapSyncProcess = MapSyncinfo;
        if (this.staff_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          this.staffAddEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          let Height;
          const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
            window.navigator.userAgent
          );
          if (isIEOrEdge == true) {
            Height = "795px";
          } else {
            Height = "790px";
          }
          const dialogRef = this.dialog.open(AddStaffComponent, {
            disableClose: true,
            width: "1200px",
            height: Height,
            panelClass: "custom-modalbox",
            autoFocus: false,
            data: this.Stafflength,
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.staffAddEnableDisable(enumEnableDisable.Enable);
            if (result == "Confirm") {
              const element1 = document.getElementById("addstaff");
              if (element1 != null)
                element1.className =
                  "clsbuttonStaff mat-raised-button mat-primary ng-star-inserted";
              this.getStaffData();
            } else {
              const element1 = document.getElementById("addstaff");
              if (element1 != null)
                element1.className =
                  "clsbuttonStaff mat-raised-button mat-primary ng-star-inserted";
            }
          });
        }
      });
  }

  editStaffdata(staffId: number) {
    this.staffAddEnableDisable(enumEnableDisable.Disable);
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.staff_MapSyncProcess = MapSyncinfo;
        if (this.staff_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          this.staffAddEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          const staffbyid: StaffByIDparam = {
            authKey: this.authkey,
            staffId: staffId,
          };
          this.apiService
            .getStaffDataById(staffbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((StaffbyId: any[]) => {
              this.Staffbyid = StaffbyId["apiReturn"];
              this.editopendialog(this.Staffbyid);
            });
        }
      });
  }

  editopendialog(Staffbyid) {
    let Height;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      Height = "795px";
    } else {
      Height = "790px";
    }
    const dialogRef = this.dialog.open(EditStaffComponent, {
      disableClose: true,
      width: "1200px",
      height: Height,
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: Staffbyid,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.staffAddEnableDisable(enumEnableDisable.Enable);
      if (result != "Cancel") {
        this.getStaffData();
      }
    });
  }

  deleteStaffdata(staffId: number) {
    this.staffAddEnableDisable(enumEnableDisable.Disable);
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.staff_MapSyncProcess = MapSyncinfo;
        if (this.staff_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          this.staffAddEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          const staffbyid: StaffByIDparam = {
            authKey: this.authkey,
            staffId: staffId,
          };
          this.apiService
            .getStaffDataById(staffbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((StaffbyId: any[]) => {
              this.Staffbyid = StaffbyId["apiReturn"];
              this.deleteopendialog(this.Staffbyid);
            });
        }
      });
  }

  deleteopendialog(Staffbyid) {
    const dialogRef = this.dialog.open(DeleteStaffComponent, {
      disableClose: true,
      width: "550px",
      height: "380px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: Staffbyid,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.staffAddEnableDisable(enumEnableDisable.Enable);
      if (result == "Confirm") {
        this.getStaffData();
      }
    });
  }

  downloadStaffdata() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    if (this.dataSource.filter != "") {
      this.downloadstaffSource.data = this.dataSource.filteredData;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.sortingDatalist(this.dataSource);
        this.dataSource
          .connect()
          .subscribe((data) => (this.staffsortedData = data));
      }, 0);
    }
    const staffdata = this.staffsortedData;
    const dialogRef = this.dialog.open(DownloadStaffComponent, {
      disableClose: true,
      width: "388px",
      height: "165px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: staffdata,
    });
    dialogRef.afterClosed().subscribe(() => {
      const element1 = document.getElementById("downloadbtn");
      if (element1 != null)
        element1.className =
          "clsbuttonStaff mat-raised-button mat-primary ng-star-inserted";
    });
  }
  ///////////////ADD staff Group////////////
  ClickStaffGroup() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    const dialogRef = this.dialog.open(ShowStaffGroupComponent, {
      disableClose: true,
      width: "700px",
      height: "390px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Cancel") {
        const element1 = document.getElementById("staffgroup");
        if (element1 != null)
          element1.className =
            "clsbuttonStaff mat-raised-button mat-primary ng-star-inserted";
      } else {
        const element1 = document.getElementById("staffgroup");
        if (element1 != null)
          element1.className =
            "clsbuttonStaff mat-raised-button mat-primary ng-star-inserted";
        this.getStaffData();
      }
    });
  }

  sortingDatalist(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (sortHeaderId != "TagId") {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  staffAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("divstaffdetails");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsenable";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsdisable";
  }

  ngOnDestroy() {
    clearInterval(this.interval_staff);
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
