
<div class="main-div">
    <div fxLayout="column" fxLayoutAlign="center center" style="padding: 50px;">
      <mat-card>
        
            <div fxLayout="row" >
                  <span><img src="assets/images/TruView.png"></span>
            </div>

            <div fxLayout="row">
                <label for="username">
                    <b>User Name</b></label>
            </div>                    
            <div fxLayout="row" style="padding-bottom:10px;">               
                <span>
                    <input style="width: 12.933em; padding-right: 1.85em;" type="text" id="username" class="form-control" placeholder="Enter User Name" value="" [(ngModel)]="username" name="username" (keyup.enter)="checkuser()" (input)="keyupevent()"
                        required="" />
                </span>
            </div>
            
            <div fxLayout="row">
                <label for="password">
                    <b>Password</b></label>
            </div>                    
            <div fxLayout="row" style="padding-bottom:10px;"> 
                <span>
                    <input  style="width: 12.933em; padding-right: 1.85em;" [type]="visible ? 'text' : 'password'"  class="form-control" placeholder="Enter Password" [(ngModel)]="password" autocomplete="new-password" value="" required=""
                     name="password" (keyup.enter)="checkuser()" (input)="keyupevent()" ondrop="return false;" onpaste="return false;">  
                    <img style="margin-top:-1.9em;margin-right: 0.2em;float:right;position:relative;width:22px;height:22px;" id="showhidepassword" (click)="clickloginpassword()" src={{passwordIcon}}/>
                </span> 	   
            </div>
            <mat-error style="text-align:center" *ngIf = "errorflag">
                Invalid Username or Password
            </mat-error>
            <mat-error style="text-align:center" *ngIf = "emptyflag">
                Please enter Username and Password
            </mat-error>
            <mat-error style="text-align:center" *ngIf = "flagUsername">
                Please enter Username 
            </mat-error>
            <mat-error style="text-align:center" *ngIf = "flagPassword">
                Please enter Password
            </mat-error>
            
            <div fxLayout="row" style="line-height: 0.5;">
                <label >&nbsp;
                    </label>
            </div>   
        <div fxLayout="row" fxLayoutAlign="center center">
            <span style="width:15.733em;">
                <button type="button" (keyup.enter)="checkuser()"  (click)="login()" autofocus class="form-control btn btn-primary btn-block z-depth-2">Login</button>
             </span>   
        </div>
      </mat-card>
    </div>
  </div>



