<mat-toolbar style="padding:0 0 0 16px">
  <!-- Design for Title icon-->
  <div fxShow.sm="true" fxShow.xs="true" fxShow="true" class="alert-main-div">
    <img style="width:25px;height:25px;margin-right:6px;" mat-line
      src="assets/images/Technology Overlay-Icon-Blue.png">
    <span *ngIf="screenflag" fxShow.md="false" fxShow.sm="false" fxShow.xs="false" mat-line class="clsTechtitle">Technology Overlay</span>
  </div>

  
  <div *ngIf="router_url ==='/floorplans'">
  </div>

  <div fxShow="true" fxHide.lt-lg="false" fxHide.gt-md="true" id="divmenu" class="clsmenu">
      <button mat-icon-button (click)="clkMenusideTechnav()" fxShow="true" fxHide.lt-lg="false" fxHide.gt-md="true" *ngIf="router_url === '/device'">
        <mat-icon>menu</mat-icon>
      </button>   
   </div>

  <div class="clssearchfilter" (click)="closeTechSidebarnav()" fxLayout="row" fxLayoutGap="15px" *ngIf="router_url === '/device'">  
    <mat-form-field appearance="outline" class="clsmatformfield">      
      <input matInput placeholder="Search Device" [formControl]="search" maxlength="30" (input)="searchDevice($event.target.value)" #searchdeviceInput>      
    </mat-form-field>
    
    <button color="primary" mat-raised-button class="clsadvancedfilter" (click)="onDeviceFilterdetails()">Advanced Filters</button>
    
    <div style="margin:0px 10px;display:inline-flex">
      <span id="spanresetFilter" style="color:#837f7f;font-size: 14px;padding-top:5px;cursor: pointer;" (click)="imgresetfilterdata()">Reset Filters</span>
      <!-- <img class="clsresetimage" src="assets/images/Reset.png" (click)="imgresetfilterdata()"/> -->
    </div>

  </div>

    <div *ngIf="router_url === '/approximatelocating'" style="width: 100%;">
      <mat-tab-group mat-align-tabs="center" (selectedTabChange)="outdoornavchange($event)" [(selectedIndex)]="selectedIndex">
        <mat-tab label="Map View" *ngIf="showMapview" id="tblmapview" matRipple matRippleDisabled="disabled"></mat-tab>
        <mat-tab label="List View" *ngIf="showListview" id="tbllistview" matRipple matRippleDisabled="disabled"></mat-tab>        
      </mat-tab-group> 
    </div>
  <span></span>
  <app-header [ngClass]="UserName.length <= 15 ? 'clsusernameappheader' : 'clsappheader'"></app-header>
</mat-toolbar>

<mat-sidenav #sidenav style="margin-top:4.6em;width:190px;" [autoFocus]="false" fxShow="true" fxHide.lt-lg="false" fxHide.gt-md="true">
    <div class="clssearch" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="15px" *ngIf="router_url === '/device-overview' || router_url === '/device'">
      <mat-form-field appearance="outline" floatLabel="auto" class="clsmatformfield">
        <mat-select [(ngModel)]="selectedCampus"  placeholder="Select Campus"   (ngModelChange)="selectbuilding()">
          <mat-option *ngFor="let Campus of Campusdetails['Campusinfo']" [value]="Campus.CampusId">
            {{Campus.CampusName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
      <mat-form-field appearance="outline" floatLabel="auto" class="clsmatformfield">
        <mat-select placeholder="Select Building" [(ngModel)]="selectedBuilding" (ngModelChange)="selectfloor()">
          <mat-option *ngFor="let Building of selectedBuildingdetails['Buildinginfo']" [value]="Building.BuildingId">
            {{Building.BuildingName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
      <mat-form-field appearance="outline" floatLabel="auto" class="clsmatformfield">
        <mat-select placeholder="Select Zone" [(ngModel)]="selectedFloor" (ngModelChange)="selectunit()">
          <mat-option *ngFor="let Floor of selectedFloordetails['Floorinfo']" [value]="Floor.FloorId">
            {{Floor.FloorName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
        <mat-form-field appearance="outline" class="clsmatformfield">
          <input matInput placeholder="Search Device" [formControl]="search" (input)="searchDevice($event.target.value)">
          <!-- <mat-icon matSuffix style="cursor:pointer;" >search</mat-icon> -->
        </mat-form-field>
    
    </div>
  </mat-sidenav>
