<perfect-scrollbar style="max-width: 1050px; max-height: 650px;">
  <div class="clsaddFloor">
    <div class="addFloor-title">           
      <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addFloorHeader" style="margin: 0 0 10px 11px;">Floor Information
        <span><img class="clscloseimagefloor" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>        
      <mat-divider></mat-divider>
    </div>

    <form style="padding:24px;" [formGroup]="newflooraddForm" autocomplete="off" novalidate
      [style.height]="minheight">
      <div><label class="clslabelheadername">{{floorCampusBuildName}}</label></div>
      
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill>
              
        <div fxFlex="60" fxFlex.md="40" class="clsaddfloormaindiv">
          
          <div id="divAddDeviceheader" class="clsFloortablecontent">
            
            <perfect-scrollbar style="max-width: 600px; max-height: 450px;">
            <table mat-table [dataSource]="dataSource" sort>
              
              <ng-container matColumnDef="FloorName">
                <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" class="clsFloortableHeader" style="width:270px;"> Floor Name </th>                  
              <td mat-cell *matCellDef="let element" contenteditable="false">
                <mat-form-field floatLabel="never" appearance="outline" floatLabel="auto" style="width:80%;">
                  <input type="text" matInput placeholder="Floor Name" [value]="element.FloorName" [(ngModel)]="element.FloorName" maxlength="30" 
                  (input)="changeFloorName($event,element.Id)" (click)="onclickFloorName(element.Id,element.FloorId)" (focusout)="focusOutFloorName($event,element.Id)">
                </mat-form-field>
              </td>
              </ng-container>
              
              <ng-container matColumnDef="UploadMap">
                <th mat-header-cell *matHeaderCellDef class="clsFloortableHeader" style="width:100px;"> Upload Map </th>
                <ng-container *matCellDef="let element">      
                <td mat-cell style="width: 130px;"><img src="assets/images/uploadfile.png" style="cursor:pointer;margin-left:1.5em;" (click)="clkimageInput.click()">
                  <input type="file" placeholder="Photo" formControlName="photo" #clkimageInput 
                  (change)="onChangefloor($event.target.files,element)" accept=".png, .jpg, .bmp" /></td>               
              </ng-container>
              </ng-container>
              
              <ng-container matColumnDef="MapUploaded">
                <th mat-header-cell *matHeaderCellDef class="clsFloortableHeader" style="width:130px;"> Map Uploaded </th>  
                <ng-container *matCellDef="let element">                                                        
                  <td mat-cell style="width: 130px;white-space: nowrap;">
                    <img *ngIf="element.FileUpload == 0" src="assets/images/Release-Delete-Icon.png" style="cursor:pointer;width: 25px;margin-left:1.7em;">
                    <mat-icon *ngIf="element.FileUpload == 1" style="transform: scale(1.3);color:#5eaa46;margin-left:1em;">done</mat-icon>
                    <button type="button" id="btnFloorDelete" class="clsFloorDeletebutton" *ngIf="element.Id != element.MinValue" mat-raised-button 
                    (click)="deleteselectFloorDetails(element.Id)">Delete</button>                   
                  </td>
                </ng-container>        
              </ng-container>
        
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>              
            </table>
          </perfect-scrollbar>
          </div>
          
          
        </div>   
        
        <div fxFlex="43" fxFlex.md="40" class="clsaddfloormaindiv">
          <mat-grid-list [cols]="firstcolumns" [rowHeight]="floorrowHeight" class="clsfloormatgridlist">
            <img class="clsflooraddDragplanimg" [src]="newflooraddImgUrl" draggable="false"/>
          </mat-grid-list>
        </div>

      </div>
      
      <mat-dialog-actions class="clsnewfloorbtncontrol" style="float: left;">
        <button type="button" class="clsFloorcancelbutton" mat-raised-button (click)="addFloorDetails()" [disabled]="btnEnableDisableAdd">Add</button>&nbsp;        
      </mat-dialog-actions>
      
      <mat-dialog-actions class="clsnewfloorbtncontrol">
        <button type="button" class="clsFloorcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
        <button type="button" id="btnsaveflooraddclick" class="clsFlooraddbutton" mat-raised-button (click)="newAddFloor()">Save</button>
      </mat-dialog-actions>
    </form>

  </div>

</perfect-scrollbar>