<div id="Infradownload"> 
  <div class="add-title">
    <h3 class="InfraHeader" style="margin: 0 0 10px 11px;">Infrastructure Report
    <span><img class="clscloseInfrastructureimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
    <mat-divider ></mat-divider> 
  </div>
  <div>
    
      <div>
          <label style="margin-left: 50px;">Device Type :</label> 
          <mat-form-field appearance="outline" floatLabel="auto" style="min-height: 49px; margin-left:10px;width:228px;">
              <mat-select  placeholder="Select DeviceType"  (selectionChange)="selectDeviceType($event.value)">    
                  <!-- <mat-option value="All">All</mat-option>
                  <mat-option value="Clear Gateway">{{NewDeviceTypeDesc.Gateway}}</mat-option>
                  <mat-option value="Clear Repeater">{{NewDeviceTypeDesc.Rex}}</mat-option>
                  <mat-option value="Clear Router">{{NewDeviceTypeDesc.HRex}}</mat-option>                                                        
                  <mat-option value="LF">{{NewDeviceTypeDesc.LF}}</mat-option>
                  <mat-option value="Monitor">{{NewDeviceTypeDesc.Monitor}}</mat-option>
                  <mat-option value="Pull Cord">{{NewDeviceTypeDesc.Pullcord}}</mat-option>
                  <mat-option value="Security Controller">{{NewDeviceTypeDesc.DC}}</mat-option> 
                  <mat-option value="TruLocator">{{NewDeviceTypeDesc.Emitter}}</mat-option> 
                  <mat-option value="Universal Monitor">{{NewDeviceTypeDesc.UM}}</mat-option>   
                  <mat-option value="UT">{{NewDeviceTypeDesc.UT}}</mat-option>                
                  <mat-option value="VW">{{NewDeviceTypeDesc.VW}}</mat-option>
                  <mat-option value="Zone">{{NewDeviceTypeDesc.Zone}}</mat-option> -->
                  <mat-option *ngFor="let Type of InfraDeviceType" [value]="Type.DeviceTypeId">
                    {{Type.DeviceType}}
                  </mat-option>
            </mat-select> 
            <mat-error>Please select Alert Status</mat-error>                   
          </mat-form-field>
       </div>

       <button style="margin-top:28px;margin-left: 75px;margin-right:28px; " class="btnInfraExcel" color="primary" mat-raised-button (click)="exportInfrastructureReportExcel(data)" >Download CSV</button> 
      <button id="pdf1" class="btnInfraPdf" color="primary" mat-raised-button (click)="exportInfrastructureReportPdf(data)" >Download pdf</button> 
   
      <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%;">       
        <div class="loadInfraAlertspinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
       </div> 

  </div>
</div>
