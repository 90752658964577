<perfect-scrollbar style="max-width: 1200px; max-height: 600px;" >
  <div class="clsaddCampus">
      <div class="editTemplate-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editTempHeader" style="margin: 0 0 10px 11px;">Zone Template Information
        <span><img class="clseditTempcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="flooreditTemplateForm" autocomplete="off" novalidate (ngSubmit)="updateTemplateInfo(flooreditTemplateForm.value)">
      <div class="content" fxLayout="row" fxLayout.md="column" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div fxFlex="55" fxFlex.md="25">   
                
                <!-- <div style="display: flex;margin-top:2em;">
                  <img style="width: 30px;height: 30px;" src="assets/images/AlertsIcon-Gray.png">
                  <label style="margin-left:0.5em;">Edits to this template can be applied to all Zones<br>using it as a foundation.</label>
                </div> -->
                
                <div style="margin-top:1em;">                  
                  <label style="margin-left:0em;">Zones created with this Template : {{ZoneTempCount}}</label>
                </div>
                
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">                                       
                  
                  <mat-grid-tile class="clseditTempmatgrid">
                    <label>Template<br>Name</label>
                </mat-grid-tile>
                <mat-grid-tile class="clseditTempmatgridtxt">
                        <div class="clseditTemptext-inside"> 
                          <mat-form-field appearance="outline" floatLabel="auto" class="clseditTemptxt">
                            <input matInput placeholder="Template Name" formControlName="tempname" maxlength="65" [(ngModel)]="selectTemplateName" />                            
                            <input type="hidden" formControlName="tempID" [(ngModel)]="data.TemplateId">  
                            <mat-error *ngIf="flooreditTemplateForm.controls.tempname.hasError('required')">Please enter Template Name</mat-error>
                            <mat-error *ngIf="flooreditTemplateForm.controls.tempname.hasError('pattern')">Please enter valid Template Name</mat-error>                            
                         </mat-form-field>
                      </div>
                </mat-grid-tile>                                    

                   </mat-grid-list>   
                   
                   <mat-grid-list [cols]="columns" class="clseditTempimgmatgridlist">
                    <div class="clsimagedivlist">
                      <div [id]="selectTemplateId" class="clseditTempDragimg map" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" draggable="false">
                      </div>
                    </div>                              
                  </mat-grid-list>
              </div>
         
              <div fxFlex="55" fxFlex.md="45" style="margin-top:1em;">
                <label>Active Spaces and Devices in this Zone:</label>
                  <mat-grid-list [cols]="columns" class="clseditTempmatgridlist">
                    
                        <perfect-scrollbar style="max-width: 1200px; max-height: 400px;" >                          
                          <div style="margin:1em;">
                            <label style="font-size: medium;">Spaces</label>
                          </div>  
                          
                          <div  class="clsunitTemptext-inside" style="margin:1em;" *ngFor="let Space of SpaceInfo;">                                                                            
                            <div style="width: 100%;display: inline-flex;" *ngIf="Space.UnitName.length > 0">                                                                                                    
                              <img src="assets/images/Edit_Icon.png" class="clsTempeditimg" (click)="editfloorSpacedetails(Space)" [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}"/>
                              <img src="assets/images/deletebuilding.png" style="margin:0em 1em;" class="clsTempeditimg" (click)="deleteSpacedetails(Space)" [ngStyle]="{'display': bDelete == false ? 'none' : 'block'}"/>
                              <div class="clseditunittempdivDevice" *ngIf="Space.UnitName.length < 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}"> {{Space.UnitName}},{{Space.DeviceCount}} Devices</div>
                              <div class="clseditunittempdivDevice" *ngIf="Space.UnitName.length >= 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}" matTooltipClass="devicetooltip" matTooltip={{Space.UnitName}} #tooltip="matTooltip" matTooltipPosition="above">
                                 {{Space.UnitName.slice(0,15) + ".."}},{{Space.DeviceCount}} Devices</div>
                           </div>   
                          </div>                                                   
                          
                          <div style="margin:1em;">
                            <label style="font-size: medium;">Devices</label>
                          </div> 
                         
                          <div  class="clsunitTemptext-inside" style="margin:1em;" *ngFor="let Device of DeviceInfo;">    
                            <div style="width: 100%;display: inline-flex;" *ngIf="Device.UnitName.length > 0">                                                                                                    
                            <img src="assets/images/Edit_Icon.png" class="clsTempeditimg" (click)="editDevicedetails(Device)" [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}"/>
                            <img src="assets/images/deletebuilding.png" style="margin:0em 1em;" class="clsTempeditimg" (click)="delete_Devicedetails(Device)" [ngStyle]="{'display': bDelete == false ? 'none' : 'block'}"/>
                           <div class="clseditunittempdivDevice" *ngIf="Device.UnitName.length < 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}"> {{Device.NewDeviceName}} - {{Device.UnitName}}</div>
                           <div class="clseditunittempdivDevice" *ngIf="Device.UnitName.length >= 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}" matTooltipClass="devicetooltip" matTooltip={{Device.UnitName}} #tooltip="matTooltip" matTooltipPosition="above">
                            {{Device.NewDeviceName}} - {{Device.UnitName.slice(0,15) + ".."}}</div>
                          </div>
                          </div> 
                    
                    </perfect-scrollbar>
                    </mat-grid-list>
              </div>
          
        
 </div>
 
 <mat-dialog-actions style="float:left;">              
  <button type="button" class="clseditTempdltbutton" mat-raised-button (click)="clkdltFloorconfirmdialog(TempFloordata)" *ngIf="bDelete==true">Delete</button> 
 </mat-dialog-actions>
  
 <mat-dialog-actions class="clseditTempbuttoncontrol">      
  <button type="button" class="clseditTempcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnzonetempsaveclick" class="clseditTempsavebutton" mat-raised-button *ngIf="bAddEdit==true">Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>