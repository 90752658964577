import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ReportService } from "./report.service";
import { getLocationType } from "./report";
import { getFeatureNeedtoShow } from "../../app-general";
import {
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../app-settings";
import { MainLayoutService } from "./../../main-layout/main-layout.service";
import { AlertSharedService } from "./../../_services/index";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  providers: [ReportService, DatePipe],
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  infrareportedData = [];
  alertreporteddata = [];
  liveviewinterval;
  LiveviewData = [];
  infraloading = true;
  alertloading = true;
  reportloading = true;
  reportselectloading = true;
  locationloading = true;
  customizelocatorsloading = true;
  IsLoaded = 0;
  IsFilter = 0;
  IsFilterData = "";
  constructor(
    private reportService: ReportService,
    private router: Router,
    public dialog: MatDialog,
    private alertSharedService: AlertSharedService,
    public mainLayoutService: MainLayoutService
  ) {
    const authkey = localStorage.getItem("Authkey") ?? "";
    const locationtypeitem: getLocationType = {
      authKey: authkey,
    };
    this.reportService
      .getLocationType(locationtypeitem)
      .subscribe((LocationTypeReport: any[]) => {
        localStorage.setItem("Clean", LocationTypeReport[0].TypeName);
        localStorage.setItem("InUse", LocationTypeReport[1].TypeName);
        localStorage.setItem("Dirty", LocationTypeReport[2].TypeName);
        localStorage.setItem("Unknown", LocationTypeReport[3].TypeName);
      });

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Main_Dashboard
      ) != enumUIView.Show &&
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Alert_Report
      ) != enumUIView.Show &&
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Infrastructure_Report
      ) != enumUIView.Show &&
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Location_History_Report
      ) != enumUIView.Show
    ) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }

    this.router.navigate(["selectdate"]);
  }

  ngOnInit() {
    console.log("report");
  }
}
