import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  AlertMessage = "";
  AlertDiv;
  constructor(private router: Router,public thisDialogRef: MatDialogRef<AlertMessageComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    this.AlertMessage = data.AlertMessage;
    setTimeout(() => { 
      if(data.AlertMessage == "Authentication Failed"){
        const element = document.getElementById("divAlertMessage_Data");
        if(element != null)
          element.innerHTML = data.AlertMessage;
      }
    },100);
  }

  ngOnInit() {
    console.log("alert-message.component - ngOnInit");
  }
  // close alert Notification Dialog
  public onCloseAlertMessage = () => {
    const element = document.getElementById("divAlertMessage_Data");
    if(element != null && element.innerHTML == "Authentication Failed"){
      this.thisDialogRef.close('Ok');
      localStorage.clear();  
      this.router.navigate(['/login']);
      return false;
    }
    else{
      this.thisDialogRef.close('Ok');
    }
  }
}

