import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import "jspdf-autotable";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

@Component({
  selector: "app-download-staff",
  templateUrl: "./download-staff.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./download-staff.component.scss"],
})
export class DownloadStaffComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 2;
  staffitem;
  RoomName;
  Shifts;
  CurrentTime: any;

  public isExcelDownload = false;
  public isPDFDownload = false;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<DownloadStaffComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RollbarService) private rollbar: Rollbar,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 1;
    }
  }

  ngOnInit() {
    const loaderelement = document.getElementsByClassName("loadstaffspinner");
    loaderelement[0]["style"].display = "none";
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  downloadStaffExcel(resdata) {
    const loaderelement = document.getElementsByClassName("loadstaffspinner");
    loaderelement[0]["style"].display = "block";
    setTimeout(() => {
      this.downloadStaff_Excel(resdata);
    }, 3);
  }

  downloadStaff_Excel(staffData) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isExcelDownload) {
        this.isExcelDownload = true;
        const downloadStaff = {
          Data: [] as any[],
        };

        for (let i = 0; i < staffData.length; i++) {
          this.staffitem = staffData[i];
          const staffname =
            this.staffitem.FirstName + " " + this.staffitem.LastName;
          if (this.staffitem.RoomName) {
            this.RoomName = this.staffitem.RoomName;
          } else {
            this.RoomName = " ";
          }

          // let staffZipcode = "";
          // if (this.staffitem.Zip == 0) staffZipcode = "";
          // else staffZipcode = this.staffitem.Zip;

          downloadStaff.Data.push({
            "Staff Name": staffname,
            Role: this.staffitem.Designation,
            Gender: this.staffitem.Gender,
            Email: this.staffitem.Email,
            Phone: this.staffitem.Phone,
            Pager: this.staffitem.PagerNo,
            // Address: this.staffitem.Address,
            // City: this.staffitem.City,
            // State: this.staffitem.State,
            // Country: this.staffitem.Country,
            // Zip: staffZipcode,
            TagId: this.staffitem.TagId,
          });
        }

        this.JSONToCSVConvertor(downloadStaff.Data, "", true);
      }
    } catch (err) {
      throw new Error(
        "download-staff.component.ts" +
          " - downloadStaffExcel() - " +
          err.message
      );
    }
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    try {
      const arrData =
        typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      let CSV = "";

      if (ShowLabel) {
        let row = "";

        for (const index in arrData[0]) {
          row += index + ",";
        }

        row = row.slice(0, -1);
        CSV += row + "\r\n";
      }

      if (arrData.length > 0) {
        for (let i = 0; i < arrData.length; i++) {
          let row = "";
          for (const index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }
          row.slice(0, row.length - 1);
          CSV += row + "\r\n";
        }
        if (CSV == "") {
          const loaderelement =
            document.getElementsByClassName("loadstaffspinner");
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Invalid data");
          this.isExcelDownload = false;
          return;
        }
        let fileName = "StaffReport";
        fileName += ReportTitle.replace(/ /g, "_");

        const myBlob = new Blob([CSV], { type: "text/html" });
        const url = window.URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        const link = document.body.appendChild(a);
        document.body.appendChild(link);
        link.href = url;
        this.CurrentTime =
          new Date().getFullYear() +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          ("0" + new Date().getDate()).slice(-2) +
          "_" +
          new Date().getHours() +
          "_" +
          new Date().getMinutes() +
          "_" +
          new Date().getSeconds();
        link.download = fileName + "_" + this.CurrentTime + ".csv";
        link.click();
      } else {
        const loaderelement =
          document.getElementsByClassName("loadstaffspinner");
        loaderelement[0]["style"].display = "none";
        generateAlertMessage(this.dialog, "No data available");
        this.isExcelDownload = false;
      }
      const loaderelement = document.getElementsByClassName("loadstaffspinner");
      loaderelement[0]["style"].display = "none";
      this.isExcelDownload = false;
    } catch (err) {
      throw new Error(
        "download-staff.component.ts" +
          " - JSONToCSVConvertor() - " +
          err.message
      );
    }
  }

  downloadStaffPdf(resdata) {
    const loaderelement = document.getElementsByClassName("loadstaffspinner");
    loaderelement[0]["style"].display = "block";
    setTimeout(() => {
      this.downloadStaff_Pdf(resdata);
    }, 3);
  }

  downloadStaff_Pdf(staffData) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isPDFDownload) {
        this.isPDFDownload = true;
        const resData = [] as any[];
        
        this.CurrentTime =
          new Date().getFullYear() +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          ("0" + new Date().getDate()).slice(-2) +
          "_" +
          new Date().getHours() +
          "_" +
          new Date().getMinutes() +
          "_" +
          new Date().getSeconds();
        const columns = [
          { title: "Staff Name", dataKey: "StaffName" },
          { title: "Role", dataKey: "Designation" },
          { title: "Email", dataKey: "Email" },
          { title: "Phone", dataKey: "Phone" },
          { title: "Pager", dataKey: "PagerNo" },
          // { title: "Address", dataKey: "Address" },
          { title: "Tag Id", dataKey: "TagId" },
        ];
        let StaffName = "";
        // let Add = "";
        // let Address = "";
        if (staffData.length > 0) {
          for (let i = 0; i < staffData.length; i++) {
            if (staffData[i].Gender != null)
              StaffName =
                staffData[i].FirstName +
                " " +
                staffData[i].LastName +
                " (" +
                staffData[i].Gender.substring(0, 1) +
                ")";
            else
              StaffName = staffData[i].FirstName + " " + staffData[i].LastName;
            const Designation = staffData[i].Designation;
            const Email = staffData[i].Email;
            const Phone = staffData[i].Phone;
            const PagerNo = staffData[i].PagerNo;
            const TagId = staffData[i].TagId;
            // Add = "";
            // if (staffData[i].Address != null) {
            //   Add += staffData[i].Address + "\n";
            // }
            // if (staffData[i].City != null) {
            //   Add += staffData[i].City + "\n";
            // }
            // if (staffData[i].State != null) {
            //   Add += staffData[i].State + "\n";
            // }
            // if (staffData[i].Country != null) {
            //   Add += staffData[i].Country + "\n";
            // }
            // if (staffData[i].Zip != null) {
            //   if (staffData[i].Zip == 0) Add += "";
            //   else Add += staffData[i].Zip;
            // }
            // Address = Add;
            
            resData.push({
              StaffName,
              Designation,
              Email,
              Phone,
              PagerNo,
              // Address,
              TagId,
            });
          }

          const pdf = new jsPDF("l"); // A4 size page of PDF
          let PdfFileName = "StaffReport";
          const pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
            pdf.setFontSize(10);
          pdf.text("Staff Report", pageWidth / 2, 10, "center");
          const options = {
            theme: "grid",
            bodyStyles: { lineColor: [0, 0, 0] },
            tableWidth: "auto",
            columnWidth: "wrap",
            showHeader: "everyPage",
            headStyles: {
              lineWidth: 0.2,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
              fillColor: [169, 169, 169],
              valign: "middle",
              halign: "center",
            },
            columnStyles: {
              StaffName: { cellWidth: 50, minCellHeight: 24 },
              Designation: { cellWidth: 25, minCellHeight: 24 },
              Email: { cellWidth: 35, minCellHeight: 24 },
              Phone: { cellWidth: 30, minCellHeight: 24 },
              PagerNo: { cellWidth: 25, minCellHeight: 24 },
              // Address: { cellWidth: 30, minCellHeight: 24 },
              TagId: { cellWidth: 25, minCellHeight: 24 },
            },
            rowPageBreak: "avoid",
          };
          pdf.autoTable(columns, resData, options);
          PdfFileName = PdfFileName + "_" + this.CurrentTime + ".pdf";
          pdf.save(PdfFileName);
        } else {
          const loaderelement =
            document.getElementsByClassName("loadstaffspinner");
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "No data available");
          this.isPDFDownload = false;
        }
        const loaderelement =
          document.getElementsByClassName("loadstaffspinner");
        loaderelement[0]["style"].display = "none";
        this.isPDFDownload = false;
      }
    } catch (err) {
      throw new Error(
        "download-staff.component.ts" + " - downloadStaffPdf() - " + err.message
      );
    }
  }
  ngOnDestroy() {
    console.log("download-staff.component - ngOnDestroy");
  }
}
