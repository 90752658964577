<perfect-scrollbar style="max-width: 650px; max-height: 430px;" >
    <div class="clsaddBuilding">
        <div class="addBuilding-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
          <h3 mat-dialog-title  class="addBuildingHeader" style="margin: 0 0 -34px 7px;">Building Information</h3>  
            <div class="clsSpnbuildtext">Enter new Building details and click “Draw Overlay” <br> to outline the 
              new Building on the Campus map.</div>
          <span><img class="clscloseimgaddBuilding" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span>
          <mat-divider ></mat-divider>
        </div>
        <div>
          <form style="padding:24px 60px;" [formGroup]="addBuildingForm" autocomplete="off" novalidate (ngSubmit)="newaddBuilding(addBuildingForm.value)">                         
              <mat-grid-list [cols]="columns" rowHeight="72px">  
                  <mat-grid-tile>
                      <label>Campus</label>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="2">
                        <div class="text-inside" style="width:100%;">
                            
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:90%;">                            
                            <mat-select formControlName="campus" placeholder="Select Campus" [(ngModel)]="selectedCampusId">    
                              <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId" (click)="changecampus_addbuilding(Campus.CampusId);">
                                {{Campus.CampusName}}
                              </mat-option>
                            </mat-select> 
                            <mat-error>Please select Campus</mat-error>                               
                            <input type="hidden" formControlName="campusID" [(ngModel)]="data.CampusId">  
                        </mat-form-field>
                          
                        </div>
                  </mat-grid-tile>
  
                  <mat-grid-tile>
                      <label>Building </label>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="2">
                        <div class="text-inside" style="width:100%;">
                            <mat-form-field appearance="outline" floatLabel="auto" style="width:90%;">
                             <input matInput placeholder="Building" maxlength="32" formControlName="buildingname" (keyup)="handleInput($event)">
                             <mat-error *ngIf="addBuildingForm.controls.buildingname.hasError('required')">Please enter Building</mat-error>
                             <mat-error *ngIf="addBuildingForm.controls.buildingname.hasError('pattern')">Please enter valid Building</mat-error>
                             <mat-error *ngIf="addBuildingForm.controls.buildingname.hasError('maxlength')">Max length 32 characters only</mat-error>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>                 
  
                <mat-grid-tile>
                  <label>Description</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:90%;">
                        <input matInput placeholder="Description" maxlength="32" formControlName="description" (keyup)="handleInput($event)">
                        <mat-error *ngIf="addBuildingForm.controls.description.hasError('required')">Please enter Description</mat-error>
                        <mat-error *ngIf="addBuildingForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>                       
                      </mat-form-field>
                    </div>
              </mat-grid-tile>  
              
              <mat-grid-tile>
                <label>Notifications</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                <div class="text-inside clsdivBuildnotification">                   
                      <div style="padding: 8px 10px;">
                      <label>Campus (Default)</label>
                      <mat-checkbox class="clslivelocation clsenable" id="chkcampus" [checked]="chkCampusvalue" (change)="toggleCamVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                    </div>
                     <div style="margin-left:.7em;padding:8px 0px;">
                      <label>Custom</label>
                      <mat-checkbox class="clslivelocation clsenable" id="chkcustom" [checked]="chkCustomvalue" (change)="toggleCusVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                    </div>                  
                </div>
              </mat-grid-tile>  
  
                  </mat-grid-list>
  
              <mat-dialog-actions class="clsbuildbuttoncontrol">                                     
                <button type="button" class="clsbuildcancelbutton" mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
                <button id="btnsavebuildaddclick" class="clsoverlaybutton" mat-raised-button>Draw Overlay</button>

              </mat-dialog-actions>
          </form>
        </div>
    </div>
    </perfect-scrollbar>
