<div class="clscontentsidebar">
        <perfect-scrollbar>
    <mat-list class="clsleftcontent">
         <mat-nav-list *ngFor="let Alarm of Alarmdetail">
            <mat-list-item [ngStyle]="{'background-color': selecteditem === Alarm.AlarmId ? '#B5B5B6' : '#E6E6E6'}" (click)="onItemSelected(Alarm.AlarmNameId,Alarm.AlarmId)"
                matTooltipPosition="above" matTooltipClass="notifylefttooltip" matTooltip={{Alarm.AlarmName}} #tooltip="matTooltip" (click)="tooltip.show()">                                                        
                <img [ngClass]="Alarm.AlarmId != 7 ? 'clsAlarmImages' : 'clsWanderAlarmImages'" [src]="notificationImageURL + Alarm.Imagedesc"> 
                <h4  fxShow.sm="false" fxShow.xs="false" fxShow.md="true" fxShow="true"  mat-line class="clsleftheader">{{Alarm.AlarmName}}</h4>
               <!-- <mat-icon fxShow.sm="false" fxShow.xs="false" fxShow.md="true" fxShow="true" mat-list-avatar color="primary">{{Alarm.Imagedesc}}</mat-icon>

               <mat-icon fxShow.sm="true" fxShow.xs="true" fxShow.md="false" fxShow="false" color="primary" mat-list-avatar matTooltipPosition="above" matTooltipClass="tooltip" matTooltip={{Alarm.AlarmName}} 
               #tooltip="matTooltip" (click)="tooltip.show()">{{Alarm.Imagedesc}}</mat-icon> -->

                <mat-divider ></mat-divider>        
            </mat-list-item>           
         </mat-nav-list> 
     </mat-list>
    </perfect-scrollbar>
</div>

