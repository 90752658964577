import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";

import { MatSelect } from "@angular/material/select";
import { Subject } from "rxjs";
import {
  appsettings,
  enumFileNamelength,
  enumGroup_Type,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumResEgress_Type,
  enumTagType,
  enumSpaceCategory
} from "../../../app-settings";
import {
  ResidentEditForCreation,
  ResidentForCreationResponse,
  ResidentByID,
  AddEditRoaming,
  getGroupByResident,
  checkAlertForCampus,
  getCampusRestrictedSpace
} from "../resident";
import {
  ResGroup,
  getGroupData,
  backupPhoto,
  checkGroupNotify,
  getFacility,
  getZone,
} from "../../../api";
import { ResidentService } from "../resident.service";
import {
  getCampus,
  getCampusdetails,
  getBuilding,
  getBuildingdetails,  
  getFloordetails,
  getUnit,
  getUnitdetails,
  TagIdfoundResponse,
  Facility,
  checkTagId,
  checkAlertForTag,
} from "../../../api";
import { ApiService } from "../../../api.service";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
  getFeatureNeedtoShow,
} from "../../../app-general";
import { Router } from "@angular/router";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { AlertSharedService } from "../../../_services/index";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {TooltipPosition} from '@angular/material/tooltip';
import {TreeviewItem,DownlineTreeviewItem, TreeviewEventParser,TreeviewConfig,OrderDownlineTreeviewEventParser,TreeviewI18n,TreeviewI18nDefault, TreeviewHelper} from 'ngx-treeview';
import { isNil, reverse } from 'lodash';

const URL = appsettings.URLinfo + "uploadImage_Resident";
export interface mapdetails {
  name: string;
  value: number;
}

@Component({
  selector: "app-edit-resident",
  templateUrl: "./edit-resident.component.html",
  providers: [ ResidentService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: ResidentService },
    { provide: TreeviewI18n , useValue: Object.assign(new TreeviewI18nDefault(), 
      {
        getFilterPlaceholder(): string {
          return 'Search';
      }
    
     }) }
   ],
  styleUrls: ["./edit-resident.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditResidentComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 8;
  public residenteditForm: UntypedFormGroup;
  public wanderConfigForm: UntypedFormGroup;

  Campusdetails: getCampusdetails[];
  Buildingdetails: getBuildingdetails[];
  Floordetails: getFloordetails[];
  Unitdetails: getUnitdetails[];
  uploadimage;
  imagename;
  dob: any;
  resimagename: any;
  residenteditimageUrl;
  dateofbirth: any;
  oldTagId;
  oldEmailId;
  Groupdetails: ResGroup[];
  Placeholder = "Group(s)";
  selectedGroupId;
  selectedGroup;
  selectedResGroupId;
  campusId;
  buildingId ="";
  oldbuildingId = "";
  floorId = "";
  oldfloorId = "";
  unitId = "";
  oldunitId = "";
  FacilityName;
  Facility: Facility[];
  selebuilding = true;
  selefloor = true;
  seleunit = true;
  startDate;
  CurrentDate;
  residentbooledit = true;
  editgender = 0;
  private _onDestroy: Subject<void> = new Subject<void>();
  screenWidth;
  resrowHeight = "67px";
  oldcampus;
  resdefaultimage = 0;
  oldFirstName = "";
  oldLastName = "";
  resdeleteimageflag = 0;
  editResidentId;
  checkresdefaultimg = 0;
  oldGroupId;
  selected_GroupId = [] as any[];
  resident_name;

  showResident = false;
  showRoaming = false;
  selectedIndex = 0;
  addbuildingId = true;
  addfloorId = true;
  addunitId = true;
  arrallCampusid = [] as any[];
  sel_CampusId = [] as any[];
  sel_BuildingId = [] as any[];
  sel_FloorId = [] as any[];
  sel_RoomId = [] as any[];
  wrongSel_RoomId=[] as any[];
  egressSel_RoomId=[] as any[];
  bWanderView = "0";
    SafeZone_Id = 0;
  getRoamingData;

  checkWrongRoom = false;
  checked_WrongRoom = false;
  checkAllEgress = false;
  checked_AllEgress = false;

  _roomenablehourmin = false;
  _egressenablehourmin = false;

  _roomenableroomallday = false;
  _roomenableEgressallday = false;
  _txtenableroomallday = false;

  checkresroominfo = false;
  checkresallinfo = false;

  data_wrongroom;
  data_wrstartTime;
  data_wrendTime;

  data_allegress;
  data_aestartTime;
  data_aeendTime;

  editresloading = true;
  residentRoomSelect = "";
  egressRoomSelect = "";
  countrycode;

  public Curfew_data = [] as any[];

  editwanderbool = true;
  bAddEdit = false;
  editCheck_Invalue = true;
  authkey;
  matTooltipWrong;
  matTooltipAllEgress;
  editVoicevalue = false;
  voiceLicense;  
  editResWandervalue = false;
  matTooltipWrongRoomExpireTime;
  matTooltipAllEgressExpireTime;
  items: TreeviewItem[];
  restrictionsHeader = ""; 
  wrongroomtextvalue;
  egresstextvalue;
  WrongRoom_flag;
  WrongRoom;
  WchkAllday;
  AllEgress;
  AchkAllday;
  residentRoomCount = 0;
  egressRoomCount = 0;  
  
  
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 160,
  });
  
  Breakpoints = Breakpoints;  
  readonly breakpoint$ = this.breakpointObserver
    .observe([
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      "(min-width: 500px)",
    ])
    .pipe(
      tap((value) => console.log(value)),
      distinctUntilChanged()
    );

  matTooltip="WARNING: Checking this box will disable the duress button if the Geo worn by this resident is in Wander mode."; 
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[3]);
  
  constructor(
    public http: HttpClient,
    private router: Router,
    public thisDialogRef: MatDialogRef<EditResidentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RollbarService) private rollbar: Rollbar,
    private alertSharedService: AlertSharedService,
    private residentService: ResidentService,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.getCurfew_data();
    this.bWanderView = "1";
    
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Resident,
        enumUIAction.Resident_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.showResident = true;
      
    } else {
      this.showResident = false;
      if (this.bWanderView == "1") {
        this.showRoaming = true;
      }
    }


    if (data[0].DateofBirth != null && data[0].DateofBirth != "") {
      let dob = new Date(data[0].DateofBirth);
      if (data[0].DateofBirth.substring(0, 4) < 1900) {
        dob = new Date("1900/01/01");
      }
      this.dob = new UntypedFormControl(dob.toISOString());
    } else {
      this.dob = 0;
    }
    this.resimagename = data[0].photo;
    this.residenteditimageUrl = appsettings.RESIDENT_FOLDER + data[0].photo;
    this.oldTagId = data[0].TagId;
    this.oldEmailId = data[0].Email;
    this.selectedGroupId = data[0].GroupId;
    this.oldFirstName = data[0].FirstName;
    this.oldLastName = data[0].LastName;
    this.oldGroupId = data[0].GroupId;
    this.resident_name = data[0].FirstName + " " + data[0].LastName;
    if (data[0].CampusId != 0 && data[0].CampusId != null) {
      this.campusId = data[0].CampusId;
      this.oldcampus = data[0].CampusId;
    }

    if (data[0].BuildingId != 0 && data[0].BuildingId != null) {
      this.buildingId = data[0].BuildingId;
      this.oldbuildingId = data[0].BuildingId;
      //alert(this.buildingId);
    }
    if (data[0].FloorId != 0 && data[0].FloorId != null) {
      this.floorId = data[0].FloorId;
      this.oldfloorId = data[0].FloorId;
    }
    if (data[0].UnitId != 0 && data[0].UnitId != null) {
      this.unitId = data[0].UnitId;
      this.oldunitId = data[0].UnitId;
    }
    this.editResidentId = data[0].ResidentID;
    this.editCheck_Invalue = data[0].resCheckInValue;
    this.editVoicevalue = data[0].isNeedVoice;
    if(data[0].isWanderMode == 1)
      this.editResWandervalue = true;
    else
      this.editResWandervalue = false; 
    if (
      this.resimagename == "Default/resident-male.png" ||
      this.resimagename == "Default/resident-female.png" ||
      this.resimagename == "Default/person.png"
    ) {
      this.resdefaultimage = 1;
      this.checkresdefaultimg = 1;
    } else this.resdefaultimage = 0;

    this.onResize();

    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }

  breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(Breakpoints.Large) ||
      this.breakpointObserver.isMatched(Breakpoints.Medium)
    ) {
      this.columns = 8;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
    } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 3;
    }
  }

  @ViewChild("clkimageInput") clkimageInput: any;
  public onChangeedit(fileList: FileList) {
    const file = fileList[0];
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(
      removedSpaces_filename
    );
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      if (this.resdefaultimage == 1 && this.checkresdefaultimg == 0)
        this.residenteditimageUrl = "assets/images/admin-avatar.png";
      else
        this.residenteditimageUrl =
          appsettings.RESIDENT_FOLDER + this.imagename;

      this.clkimageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      if (this.resdefaultimage == 1 && this.checkresdefaultimg == 0)
        this.residenteditimageUrl = "assets/images/admin-avatar.png";
      else
        this.residenteditimageUrl =
          appsettings.RESIDENT_FOLDER + this.imagename;

      this.clkimageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Resident Photo as image file"
      );
      if (this.resdefaultimage == 1 && this.checkresdefaultimg == 0)
        this.residenteditimageUrl = "assets/images/admin-avatar.png";
      else
        this.residenteditimageUrl =
          appsettings.RESIDENT_FOLDER + this.imagename;

      this.clkimageInput.nativeElement.value = "";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0]);
      else
        this.residenteditimageUrl =
          appsettings.RESIDENT_FOLDER + this.imagename;
      this.clkimageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 3) {
              checkvalidIMG = 2;
              //generateAlertMessage(_dialog, "Please upload the image less than 100 KB");
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 3 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Resident Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  uploadfiletoPath(data, file) {
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const FileName = this.editResidentId + "+" + _filename;
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        this.uploadimage = res.filename;
        this.imagename = res.filename;
        this.residenteditimageUrl = appsettings.RESIDENT_FOLDER + res.filename;
      });
  }

  backupPhoto(filename) {
    try {
      const fileitem: backupPhoto = {
        authKey: this.authkey,
        fileName: filename.toString(),
        isPath: "Resident",
      };
      this.apiService
        .backupPhoto(fileitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Response: any[]) => {
          console.log(Response);
        });
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" + " - backupPhoto() - " + err.message
      );
    }
  }

  getCurfew_data() {
    let _curfewdata = {};
    const _curfew_data_arr = [] as any[];
    let hourtext = "";
    let _hour = "";
    let _min = "";
    let min_inc = 30;
    for (let hour = 0; hour <= 24; hour++) {
      if (hour < 12) {
        _hour = "0" + hour.toString();
        hourtext = " am";
      } else if (hour == 24) {
        _hour = (hour - 12).toString();
        hourtext = " am";
        min_inc = 60;
      } else if (hour > 12) {
        _hour = "0" + (hour - 12).toString();
        hourtext = " pm";
      } else if (hour == 12) {
        _hour = hour.toString();
        hourtext = " pm";
      }

      _hour = _hour.slice(-2);
      for (let min = 0; min < 60; min = min + min_inc) {
        _min = "0" + min.toString();
        _min = _min.slice(-2);
        _curfewdata = {
          Time: _hour + ":" + _min + hourtext,
          Value: hour + ":" + min,
        };
        _curfew_data_arr.push(_curfewdata);
      }
    }
    this.Curfew_data = _curfew_data_arr;
  }

  ngOnInit() {
    this.matTooltipWrong =
      "Checking this box will create an alert if a Resident is seen in a Resident room other than their own room for a considerable amount of time. Alert will auto-\nclear if Staff Geo is present." +
      "\n\nRules:\n" +
      ' \u2800\u2022\u2800 Rule 1: If Resident detected in a Resident "Space" that is not associated to this Resident Record for >180 Seconds, then a Roaming Alert will be posted.\n' +
      " \u2800\u2022\u2800 Rule 2: If Staff is in same space within this same time, the Alert will not post or will auto-clear\n" +
      " \u2800\u2022\u2800 Rule 3: If Resident is detected in different Space at any time within 180 seconds, do not post the alert\n" +
      " \u2800\u2022\u2800 Rule 4: If Resident is detected in different Space at any time after 180 seconds, auto-clear the alarm";

    this.matTooltipAllEgress =
      "Checking this box will create a Roaming Alert if a resident seen in any Egress Area. Alert will auto-clear if Staff Geo is present.\n Note - if this resident is wearing a Wander Geo, then this is an additional alert and unnecessary if you use the Loiter feature." +
      "\n\nRules:\n" +
      ' \u2800\u2022\u2800 Rule 1: If Resident is seen in any "Egress" Space Category ever [no delay or wait outside Rule #2], then post Roaming Alert\n' +
      " \u2800\u2022\u2800 Rule 2: If Staff is in same space within this same time, the Alert will not post OR will auto-clear\n " +
      " \u2800\u2022\u2800 Rule 3: If Resident is detected in a different Space at any time after 60 seconds, auto-clear the alarm";
    
    this.matTooltipWrongRoomExpireTime = "Enter non-zero # days for Wrong Room to stay Enabled. Upon expiration, Wrong Room will be disabled and Expiration Time reset to 0."
    this.matTooltipAllEgressExpireTime = "Enter non-zero # days for All Egress to stay Enabled. Upon expiration, All Egress will be disabled and Expiration Time reset to 0."
    
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.bWanderView = "1";
       
   
    this.countrycode = localStorage.getItem("country_code");
    this.voiceLicense = localStorage.getItem("voice_license"); 
    if (this.showResident == true) {
      
      if (this.selectedGroupId.toString().length > 0)
        this.getGroupInfoByResident(this.selectedGroupId);
      this.dateofbirth = this.dob.value;
      this.imagename = this.resimagename;
      this.startDate = "1900-01-01T12:00:00.000Z";
      this.CurrentDate = new Date();
      this.residenteditForm = new UntypedFormGroup({
        firstname: new UntypedFormControl("", [Validators.required,Validators.maxLength(30),Validators.pattern(appsettings.Name_Pattern)]),
        lastname: new UntypedFormControl("", [Validators.required,Validators.maxLength(30),Validators.pattern(appsettings.Name_Pattern)]),
        residentId: new UntypedFormControl(""),
        spousename: new UntypedFormControl("", [Validators.maxLength(30),Validators.pattern(appsettings.Name_Pattern)]),
        dateofbirth: new UntypedFormControl(""),
        gender: new UntypedFormControl("", [Validators.required]),
        photo: new UntypedFormControl(""),
        tagid: new UntypedFormControl("", [Validators.required,Validators.maxLength(10),Validators.max(1073741823),Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]),
        facility: new UntypedFormControl("", [Validators.required]),
        campus: new UntypedFormControl("", [Validators.required]),
        building: new UntypedFormControl("", [Validators.required]),
        floor: new UntypedFormControl("", [Validators.required]),
        unit: new UntypedFormControl("", [Validators.required]),
        phone: new UntypedFormControl("", [Validators.maxLength(10),Validators.minLength(10),Validators.pattern(appsettings.Number_Pattern)]),
        email: new UntypedFormControl("", [Validators.email,Validators.pattern(appsettings.Email_Pattern)]),
        group: new UntypedFormControl(""),
        pager: new UntypedFormControl('', [Validators.pattern(appsettings.Number_Pattern)]) 
      });

      // this.residenteditForm.get('email').disable();
      const resForm_Facility = this.residenteditForm.get("facility");
      if (resForm_Facility != null) resForm_Facility.disable();
      this.getFacilityInfo();
      this.getCampusdetails();
      this.getResidentGroup();
    }

    if (this.bWanderView == "1") {
      this.wanderConfigForm = new UntypedFormGroup({
        residentname: new UntypedFormControl(""),
        wrongroom: new UntypedFormControl(""),
        wr_start: new UntypedFormControl(""),
        wr_end: new UntypedFormControl(""),
        allegress: new UntypedFormControl(""),
        ae_start: new UntypedFormControl(""),
        ae_end: new UntypedFormControl(""),
        wr_time: new UntypedFormControl(""),
        ae_time: new UntypedFormControl(""),
      });
      const resForm_ResidentName = this.wanderConfigForm.get("residentname");
      if (resForm_ResidentName != null) resForm_ResidentName.disable();
      this.getRoaming();
    }

   
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.residenteditForm.controls).forEach((field) => {
        // {1}
        const control = this.residenteditForm.get(field); // {2}
        const residentFormId = document.getElementById(event.target.id);
        if (residentFormId != null) {
          if (field == residentFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    this.screenWidth = window.innerWidth;
    if (isIEOrEdge == true) {
      this.resrowHeight = "72px";
    }
    if (isIEOrEdge == false && this.screenWidth < 400) {
      this.columns = 3;
      this.resrowHeight = "85px";
    } else if (isIEOrEdge == true && this.screenWidth < 400) {
      this.columns = 3;
      this.resrowHeight = "88px";
    } else if (this.screenWidth >= 1097 && isIEOrEdge == false) {
      this.columns = 8;
      this.resrowHeight = "67px";
    }
  }
  @ViewChild("selBuilding") selBuilding: MatSelect;
  @ViewChild("selFloor") selFloor: MatSelect;
  @ViewChild("selUnit") selUnit: MatSelect;

  getFacilityInfo() {
    const facilitydata: getFacility = {
      authKey: this.authkey,
    };
    this.apiService
      .getFacilityInfo(facilitydata)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((facilityInfo: any[]) => {
        this.Facility = facilityInfo;
        this.FacilityName = this.Facility[0].FacilityName;
      });
  }

  getCampusdetails() {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" + " - getCampusdetails() - " + err.message
      );
    }
  }

  getBuildingdetails(CampusId) {
    try {
      if (parseInt(CampusId) > 0) {
        const buildingid: getBuilding = {
          authKey: this.authkey,
          campusId: CampusId,
        };
        this.apiService
          .getBuildingDetails(buildingid)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((BuildingDetails: any[]) => {
            this.Buildingdetails = BuildingDetails["apiReturn"];
          });
      }
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" +
          " - getBuildingdetails() - " +
          err.message
      );
    }
  }

  getFloorDetails(BuildingId) {
    try {
      if (parseInt(BuildingId) > 0) {
        const floorid: getZone = {
          authKey: this.authkey,
          campusId:parseInt(this.campusId),
          buildId: BuildingId,
          floorId:0
        };
        this.apiService
          .getZoneDetails(floorid)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((FloorDetails: any[]) => {
            this.Floordetails = FloorDetails["apiReturn"];
          });
      }
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" + " - getFloorDetails() - " + err.message
      );
    }
  }

  getUnitDetails(FloorId) {
    try {
      if (parseInt(FloorId) > 0) {
        const unitid: getUnit = {
          authKey: this.authkey,
          floorId: FloorId,
          isIndoor: 1,
          isNeedIntercom :parseInt(this.voiceLicense),
          isSubZone : 0
        };
        this.apiService
          .getUnitDetails(unitid)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((UnitDetails: any[]) => {
            this.Unitdetails = UnitDetails["apiReturn"];
          });
      }
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" + " - getUnitDetails() - " + err.message
      );
    }
  }
  changecampus(campusid) {
    if (parseInt(campusid) > 0 && campusid != this.oldcampus) {
      this.Buildingdetails = [];
      this.Floordetails = [];
      this.Unitdetails = [];
      this.residenteditForm.controls["building"].setValue("");
      this.residenteditForm.controls["floor"].setValue("");
      this.residenteditForm.controls["unit"].setValue("");

      this.oldcampus = campusid;
    } else this.selebuilding = true;
  }
  changebuild(buildid) {
    if (buildid > 0 && buildid != this.oldbuildingId) {
      this.selebuilding = true;
      this.selefloor = false;
      this.Floordetails = [];
      this.Unitdetails = [];
      this.residenteditForm.controls["floor"].setValue("");
      this.residenteditForm.controls["unit"].setValue("");

      this.oldbuildingId = buildid;
    } else this.selefloor = true;
  }
  changefloor(floorid) {
    if (floorid > 0 && floorid != this.oldfloorId) {
      this.selefloor = true;
      this.seleunit = false;
      this.Unitdetails = [];
      this.residenteditForm.controls["unit"].setValue("");

      this.oldfloorId = floorid;
    } else this.seleunit = true;
  }
  changeunit(unitId) {
    if (unitId > 0) this.seleunit = true;
  }

  getResidentGroup() {
    try {
      const groupitem: getGroupData = { authKey: this.authkey };
      this.apiService
        .getResidentGroup(groupitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Groupdetails: any[]) => {
          if (Groupdetails["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Groupdetails = Groupdetails["apiReturn"];
          if (this.Groupdetails.length > 0) this.Placeholder = "Group(s)";
          else this.Placeholder = "No Records";
        });
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" + " - getResidentGroup() - " + err.message
      );
    }
  }

  getGroupInfoByResident(getselectedGroup) {
    try {
      const selectedgroupitem: getGroupByResident = {
        authKey: this.authkey,
        selectedGroup: getselectedGroup.toString(),
      };
      this.residentService
        .getGroupInfoByResident(selectedgroupitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Groupdetails: any[]) => {
          this.selectedGroup = Groupdetails;
          this.selectedResGroupId = this.selectedGroup[0].GroupIdkeys;
        });
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" +
          " - getGroupInfoByResident() - " +
          err.message
      );
    }
  }

  changeResidentGroup(data: any) {
    if (data.selected == false) {
      this.selected_GroupId.push(data.value);
    } else {
      this.selected_GroupId.forEach((element, index) => {
        if (element == data.value) this.selected_GroupId.splice(index, 1);
      });
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.residenteditForm.controls[controlName].hasError(errorName);
  };

  public createResidentbyId = (residenteditFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      this.editgender = 1;
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.residenteditForm.valid && this.residentbooledit) {
        this.residentbooledit = false;
        const alerttagidfoundItem: checkAlertForTag = {
          authKey: this.authkey,
          oldTagId: parseInt(this.oldTagId),
          isResStaff: 1,
        };
        this.apiService
          .checkAlertForTag(alerttagidfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse.toString() == "not found") {
              this.checkTagId(residenteditFormValue);
            } else {
              this.residentbooledit = true;
              generateAlertMessage(
                this.dialog,
                "The tagid " +
                  this.oldTagId +
                  " associated to resident " +
                  residenteditFormValue.firstname +
                  " " +
                  residenteditFormValue.lastname +
                  " has open alerts, please close all alerts and try to change the resident."
              );
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" +
          " - createResidentbyId() - " +
          err.message
      );
    }
  };

  checkTagId(residenteditFormValue) {
    try {
      if (this.residenteditForm.valid) {
        if (this.oldTagId != residenteditFormValue.tagid) {
          const tagidfoundItem: checkTagId = {
            authKey: this.authkey,
            newTagId: parseInt(residenteditFormValue.tagid),
            resStaffId: parseInt(residenteditFormValue.residentId),
            tagType:enumTagType.Resident
          };
          this.apiService
            .checkTagId(tagidfoundItem)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: TagIdfoundResponse[]) => {
              if (apiresponse.toString() == "not found") {
                if (
                  this.oldcampus != residenteditFormValue.campus ||
                  this.oldbuildingId != residenteditFormValue.building ||
                  this.oldfloorId != residenteditFormValue.floor ||
                  this.oldunitId != residenteditFormValue.unit
                )
                  this.checkAlertForCampus(residenteditFormValue);
                else this.updateResidentData(residenteditFormValue);
              } else {
                this.residentbooledit = true;
                if (
                  apiresponse[0].desc == "Resident" ||
                  apiresponse[0].desc == "Staff" ||
                  apiresponse[0].desc == "Asset"
                )
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate tagid found, the " +
                      apiresponse[0].desc +
                      "  '" +
                      apiresponse[0].name +
                      "'  already has this tagid.<br> Please try again with different tagid"
                  );
                else
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate tagid found, the " +
                      apiresponse[0].name +
                      " already has this tagid on " +
                      apiresponse[0].desc +
                      ".<br> Please try again with different tagid"
                  );
                return;
              }
            });
        } else {
          if (
            this.oldcampus != residenteditFormValue.campus ||
            this.oldbuildingId != residenteditFormValue.building ||
            this.oldfloorId != residenteditFormValue.floor ||
            this.oldunitId != residenteditFormValue.unit
          )
            this.checkAlertForCampus(residenteditFormValue);
          else {
            this.checkGroupNotification(residenteditFormValue);
          }
        }
      }
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" + " - checkTagId() - " + err.message
      );
    }
  }

  checkAlertForCampus(residenteditFormValue) {
    try {
      if (this.residenteditForm.valid) {
        const campusfoundItem: checkAlertForCampus = {
          authKey: this.authkey,
          oldTagId: parseInt(this.oldTagId),
          resStaffId: parseInt(residenteditFormValue.residentId),
          campusId: parseInt(residenteditFormValue.campus),
          buildId: parseInt(residenteditFormValue.building),
          floorId: parseInt(residenteditFormValue.floor),
          unitId: parseInt(residenteditFormValue.unit),
        };
        this.apiService
          .checkAlertForCampus(campusfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse.toString() == "not found") {
              this.checkGroupNotification(residenteditFormValue);
            } else {
              this.residentbooledit = true;
              generateAlertMessage(
                this.dialog,
                apiresponse.toString() +
                  " has open alerts, please close alerts and try to change"
              );
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" +
          " - checkAlertForCampus() - " +
          err.message
      );
    }
  }

  checkGroupNotification(residenteditFormValue) {
    if (this.selected_GroupId.indexOf(this.oldGroupId) > -1) {
      const groupNotificationItem: checkGroupNotify = {
        authKey: this.authkey,
        resStaffType: parseInt(enumGroup_Type.Resident.toString()),
        resStaffGroupId: this.selected_GroupId.toString(),
      };
      this.apiService
        .checkGroupNotification(groupNotificationItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.length == 0) {
            this.updateResidentData(residenteditFormValue);
          } else if (apiresponse[0].GroupId > 0) {
            this.residentbooledit = true;
            generateAlertMessage(
              this.dialog,
              'Group "' +
                apiresponse[0].GroupName +
                '" has added in Notification, so please remove this group from notification and try again.'
            );
            return;
          }
        });
    } else if (this.oldGroupId.length != undefined) {
      if (this.oldGroupId.indexOf(this.selected_GroupId) >= -1) {
        const groupNotificationItem: checkGroupNotify = {
          authKey: this.authkey,
          resStaffType: parseInt(enumGroup_Type.Resident.toString()),
          resStaffGroupId: this.selected_GroupId.toString(),
        };
        this.apiService
          .checkGroupNotification(groupNotificationItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse.length == 0) {
              this.updateResidentData(residenteditFormValue);
            } else if (apiresponse[0].GroupId > 0) {
              this.residentbooledit = true;
              generateAlertMessage(
                this.dialog,
                'Group "' +
                  apiresponse[0].GroupName +
                  '" has added in Notification, so please remove this group from notification and try again.'
              );
              return;
            }
          });
      } else {
        this.updateResidentData(residenteditFormValue);
      }
    } else {
      this.updateResidentData(residenteditFormValue);
    }
  }

  private updateResidentData = (residenteditFormValue) => {
    try {
      const dateofbirth = new DatePipe("en-US");
      let DateofBirth = dateofbirth.transform(
        residenteditFormValue.dateofbirth,
        "yyyy/MM/dd"
      );

      if (
        this.uploadimage == "" ||
        this.uploadimage == undefined ||
        this.uploadimage == "Default/person.png"
      ) {
        //this.uploadimage = this.imagename;
        if (residenteditFormValue.gender == "Male" || residenteditFormValue.gender == "Other") {
          if (
            this.imagename == "Default/resident-male.png" ||
            this.imagename == "Default/person.png" ||
            this.imagename == "Default/resident-female.png"
          )
            this.uploadimage = "Default/resident-male.png";
          else this.uploadimage = this.imagename;
        } else if (residenteditFormValue.gender == "Female") {
          if (
            this.imagename == "Default/resident-female.png" ||
            this.imagename == "Default/person.png" ||
            this.imagename == "Default/resident-male.png"
          )
            this.uploadimage = "Default/resident-female.png";
          else this.uploadimage = this.imagename;
        }
      }

      this.addbuildingId = true;
      this.addfloorId = true;
      this.addunitId = true;

      if (this.selebuilding == false) {
        this.addbuildingId = false;
        this.addfloorId = false;
        this.addunitId = false;
        this.Buildingdetails = [];
        this.Floordetails = [];
        this.Unitdetails = [];
      } else if (this.selefloor == false) {
        this.addfloorId = false;
        this.addunitId = false;
        this.Floordetails = [];
        this.Unitdetails = [];
      } else if (this.seleunit == false) {
        this.addunitId = false;
        this.Unitdetails = [];
      }
      if (this.addbuildingId == false) {
        this.residenteditForm.controls["building"].setErrors({
          incorrect: true,
        });
      }

      if (this.addfloorId == false) {
        this.residenteditForm.controls["floor"].setErrors({ incorrect: true });
      }

      if (this.addunitId == false) {
        this.residenteditForm.controls["unit"].setErrors({ incorrect: true });
      }

      if (
        this.addbuildingId == false ||
        this.addfloorId == false ||
        this.addunitId == false
      ) {
        this.residentbooledit = true;
        return false;
      }

      let _phoneNumber = "";
      if (residenteditFormValue.phone != "")
        _phoneNumber = this.countrycode + residenteditFormValue.phone;

      let _editcheckinvalue = 0;
      if (this.editCheck_Invalue == true) _editcheckinvalue = 1;
      else if (this.editCheck_Invalue == false) _editcheckinvalue = 0;

      if (DateofBirth == null || DateofBirth == undefined) DateofBirth = "";

      let groupdid = "";
      if (residenteditFormValue.group != undefined)
        groupdid = residenteditFormValue.group.toString();
      let _editvoicevalue = 0;
      if (this.editVoicevalue == true) _editvoicevalue = 1;
      else if (this.editVoicevalue == false) _editvoicevalue = 0;
      
      if(this.voiceLicense == 0)  
        _editvoicevalue = 0;
      let resEditPager = "";    
        if(residenteditFormValue.pager != '' || residenteditFormValue.pager != undefined || residenteditFormValue.pager != null)            
          resEditPager = residenteditFormValue.pager;      
        else
          resEditPager = ""; 
          
      let _editwandervalue = 0;      
      if(this.editResWandervalue == true)      
        _editwandervalue = 1;
      else if(this.editResWandervalue == false)      
        _editwandervalue = 0;

      const resident: ResidentEditForCreation = {
        authKey: this.authkey,
        residentId: residenteditFormValue.residentId,
        firstName: residenteditFormValue.firstname.replace(/\s+/g, " ").trim(),
        lastName: residenteditFormValue.lastname.replace(/\s+/g, " ").trim(),
        spouseName: residenteditFormValue.spousename.replace(/\s+/g, " ").trim(),
        dateOfBirth: DateofBirth,
        gender: residenteditFormValue.gender.toString(),
        photo: this.uploadimage.toString(),
        tagId: parseInt(residenteditFormValue.tagid),
        facility: this.FacilityName.replace(/\s+/g, " ").trim(),
        campusId: parseInt(residenteditFormValue.campus),
        buildingId: parseInt(residenteditFormValue.building),
        floorId: parseInt(residenteditFormValue.floor),
        unitId: parseInt(residenteditFormValue.unit),
        phone: _phoneNumber,
        pager : resEditPager,
        email: residenteditFormValue.email.toString(),
        groupId: groupdid,
        deleteImage: this.resdeleteimageflag,
        residentCheckIn: _editcheckinvalue,
        isNeedVoice: _editvoicevalue,
        isWanderMode : _editwandervalue
      };

      this.residentService
        .updateResidentData(resident)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: ResidentForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            this.apiService.reloadactivetag();
            this.residentbooledit = false;
            if (this.uploadimage != this.resimagename)
              this.backupPhoto(this.resimagename);

            const _SharedAlertLoadDetails = {
              SharedreturnVal: 1,
              SharedNotifiedVal: 0,
            };
            this.alertSharedService.changecanceledAlert(
              _SharedAlertLoadDetails
            );
            generateAlertMessage(
              this.dialog,
              "Resident data edited successfully."
            );
          } 
          else if (apiresponse["apiReturn"] == "Fail") {
            this.residentbooledit = true;
            generateAlertMessage(this.dialog, "Resident data edited failed.");
          } 
          else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("Confirm");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } 
          else if (apiresponse["apiReturn"] == "Resident Image not exists") {
            this.residentbooledit = true;
            generateAlertMessage(this.dialog, "Please upload resident image.");
            return;
          }
          else
          {
            this.residentbooledit = true;
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);            
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" +
          " - updateResidentData() - " +
          err.message
      );
    }
  };

  radioChange(event) {
    console.log(event);
    const resTag_Id = document.getElementById("restagid");
    if (resTag_Id != null) resTag_Id.focus();
  }
  getErrorMessage(pickerInput: string): string {
    if (!pickerInput || pickerInput === "") {
      return "Please select Date Of Birth";
    } else if (!moment(pickerInput).isValid()) {
      return "Please enter valid date format MM/DD/YYYY";
    } else {
      return "Please enter current date format MM/DD/YYYY";
    }
  }
  deleteresidentimage() {
    try {
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to delete resident photo?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          try {
            this.resdefaultimage = 1;
            this.resdeleteimageflag = 1;
            this.residenteditimageUrl = "assets/images/admin-avatar.png";
            this.uploadimage = "Default/resident-female.png";
          } catch (err) {
            throw new Error(
              "edit-resident.component.ts - onClickedYes() - " + err.message
            );
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" +
          " - deleteresidentimage() - " +
          err.message
      );
    }
  }

  resident_roaming($event): void {
    this.selectedIndex = $event.index;
    if (this.selectedIndex == 0) {
      this.showResident = true;
      this.showRoaming = false;
      this.ngOnInit();
    } else if (this.selectedIndex == 1) {
      this.selectedIndex = $event.index;
      this.showResident = false;
      this.showRoaming = true;
      // this.selectedCampus(enumSpaceCategory.ResidentRoom);
      this.getRoaming();
      this.showWrongEgressRoom();
    }
  }
  
  selectedCampus(spaceCategoryid) { 
    try
    {
      this.editresloading = true;
      const campusid: getCampusRestrictedSpace = {        
        authKey: this.authkey,
        residentId : this.editResidentId,
        spaceCategory:spaceCategoryid
      }
      this.residentService.getCampusAllData(campusid).pipe(takeUntil(this._onDestroy))
      .subscribe((CampusDetails : any[])=>{
        this.Campusdetails = CampusDetails;
        if (CampusDetails != null && CampusDetails != undefined) {
          this.arrallCampusid = CampusDetails["Campusdetails"];
          this.items = this.getProducts();
        }              
        
      });  
    }
    catch (err) {
      throw new Error('edit-resident.component.ts' + ' - selectCampus() - ' + err.message);
    }
  }

  getRoaming() {
    const roamingdata: ResidentByID = {
      authKey: this.authkey,
      residentId: this.editResidentId,
    };
    this.residentService
      .getRoaming(roamingdata)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((RoamingDetails: any[]) => {
        this.getRoamingData = RoamingDetails;
        if (this.getRoamingData.length>0)
        {
          if(this.getRoamingData[0].CampusId != undefined)
            this.sel_CampusId = this.getRoamingData[0].CampusId//.split(",");
          if(this.getRoamingData[0].BuildId != undefined) 
            this.sel_BuildingId = this.getRoamingData[0].BuildId//.split(",");
          if(this.getRoamingData[0].FloorId != undefined) 
            this.sel_FloorId = this.getRoamingData[0].FloorId//.split(",");  
          if(this.getRoamingData[0].RoomIds != undefined)
            this.sel_RoomId = this.getRoamingData[0].RoomIds.split(",");
          
          this.data_wrongroom = this.getRoamingData[0].WrongRoom;   
          this.residentRoomCount =  this.getRoamingData[0].ResidentRoomCount;   
          this.egressRoomCount =  this.getRoamingData[0].EgressRoomCount;  
          if(this.getRoamingData[0].wrongRoomIds != "" && (this.getRoamingData[0].wrongRoomIds != undefined || this.getRoamingData[0].wrongRoomIds != null))
            this.wrongSel_RoomId = this.getRoamingData[0].wrongRoomIds.split(",");
          if(this.wrongSel_RoomId.length > 0)
            this.restrictionsHeader = "Wrong Room Space Restrictions"  
          if(this.data_wrongroom == "true")
          {
            this.data_wrongroom = true;
            this.checkWrongRoom = true;
            this._roomenablehourmin = true;
            this._roomenableroomallday = true;
            this._txtenableroomallday = true;
            // this.restrictionsHeader = "Wrong Room Space Restrictions"  
            this.residentRoomSelect = enumResEgress_Type.ResidentRoom;          
            const element = document.getElementById('txtwrExpireTime') as HTMLInputElement; 
            if(element != null)  
              element.value = this.getRoamingData[0].wrongExpireTime;     
            // this.onSelectRoomdetails(this.wrongSel_RoomId,this.checkWrongRoom);
          }
          else
          {
            this.data_wrongroom = false;
            this.checkWrongRoom = false;
            this._roomenablehourmin = false;
            this._roomenableroomallday = false;
            this._txtenableroomallday = false;
          }

          this.data_wrstartTime = this.getRoamingData[0].WStartTime;
          this.data_wrendTime = this.getRoamingData[0].WEndTime;
          
          this.data_allegress = this.getRoamingData[0].AllEgress;  
          
          if(this.getRoamingData[0].allEgressRoomIds != "" && (this.getRoamingData[0].allEgressRoomIds != undefined || this.getRoamingData[0].allEgressRoomIds != null))
            this.egressSel_RoomId = this.getRoamingData[0].allEgressRoomIds.split(",");
          if(this.egressSel_RoomId.length > 0)
            this.restrictionsHeader = "All Egress Space Restrictions"    
          if(this.data_allegress == "true")
          {
            this.data_allegress = true;
            this.checkAllEgress = true;
            this._egressenablehourmin = true;
            this._roomenableEgressallday = true;      
            const egresselement = document.getElementById('txtaeExpireTime') as HTMLInputElement;   
            egresselement.value = this.getRoamingData[0].allEgressExpireTime;
            
            // this.onSelectRoomdetails(this.egressSel_RoomId,this.checkWrongRoom);
          }
          else
          {
            this.data_allegress = false;
            this.checkAllEgress = false;
            this._egressenablehourmin = false;
            this._roomenableEgressallday = false;
          }

          // if (this.getRoamingData[0].AllEgress == "true") {
            this.data_aestartTime = this.getRoamingData[0].AStartTime;
            this.data_aeendTime = this.getRoamingData[0].AEndTime;
          // }

          if (this.getRoamingData[0].WchkAllday == 1) {
            this._roomenablehourmin = false;
            this.checkresroominfo = true;
          } else if (
            this.getRoamingData[0].WchkAllday == 0 &&
            this.data_wrongroom == true
          ) {
            this._roomenablehourmin = true;
            this.checkresroominfo = false;
          }

          if (this.getRoamingData[0].AchkAllday == 1) {
            this._egressenablehourmin = false;
            this.checkresallinfo = true;
          } else if (
            this.getRoamingData[0].AchkAllday == 0 &&
            this.data_allegress == true
          ) {
            this._egressenablehourmin = true;
            this.checkresallinfo = false;
          }
        }
      });
  }

showWrongEgressRoom()
{
  if(this.checkWrongRoom == true && this.checkAllEgress == false)      
    this.showSelectedRoom(1);
  else if(this.checkAllEgress == true)
    this.showSelectedRoom(2);
  else
    this.editresloading = false;
}

toggleWrongroom(e) {    
  if (e.checked == true) {
    // this.wanderConfigForm.get('wr_expirationtime').enable(); 
    this.checkWrongRoom=true;  
    this._roomenableroomallday = true; 
    this._txtenableroomallday = true;
    this.checkresroominfo = true;
    if(this.checkresroominfo == true)
      this._roomenablehourmin = false;     
    else
      this._roomenablehourmin = true;   
      
      this.showSelectedRoom(1);
  }
  else{
    // this.wanderConfigForm.get('wr_expirationtime').disable(); 
    this.checkWrongRoom=false;   
    this._roomenableroomallday = false;
    this._txtenableroomallday = false;
    this._roomenablehourmin = false;  
    this.data_wrstartTime = 0;
    this.data_wrendTime = 0;
    this.checkresroominfo = false;   
    const element = document.getElementById('txtwrExpireTime') as HTMLInputElement;   
      element.value = ""; 
    if(this.checkAllEgress == true)    
      this.showSelectedRoom(2);    
    else
      this.restrictionsHeader = ""; 
  }      
}

toggleAllEgress(e) {  
  if (e.checked == true) {
    this.checkAllEgress=true;   
    this._roomenableEgressallday = true; 
    this.checkresallinfo = true;
    if(this.checkresallinfo == true)
      this._egressenablehourmin = false;
    else
      this._egressenablehourmin = true;    
      this.showSelectedRoom(2)       
  }
  else{
    this.checkAllEgress=false;
    this._roomenableEgressallday = false;
    this._egressenablehourmin = false;     
    this.data_aestartTime = 0;
    this.data_aeendTime = 0;
    this.checkresallinfo = false;
    const element = document.getElementById('txtaeExpireTime') as HTMLInputElement;   
      element.value = ""; 
    if(this.checkWrongRoom == true)    
      this.showSelectedRoom(1);    
    else
      this.restrictionsHeader = "";    
  }   
}
  
showSelectedRoom(type)
{
  if(type == 1)
  {
    this.restrictionsHeader = "Wrong Room Space Restrictions"  
    this.residentRoomSelect = enumResEgress_Type.ResidentRoom;
    this.selectedCampus(enumSpaceCategory.ResidentRoom);
    this.onSelectRoomdetails(this.residentRoomSelect);
  }
  else if(type == 2)
  {
    this.restrictionsHeader = "All Egress Space Restrictions"
    this.egressRoomSelect = enumResEgress_Type.EgressRoom; 
    this.selectedCampus(enumSpaceCategory.Egress);
    this.onSelectRoomdetails(this.egressRoomSelect);
  }
}

onSelectRoomdetails(checkselectitem)
{    
  try
  {
    this.editresloading = true;
    console.log("arrallCampusid : " + this.arrallCampusid)
    for(let ncampus = 0; ncampus < this.arrallCampusid.length; ncampus++)
    {        
      for(let nbuild = 0; nbuild < this.arrallCampusid[ncampus].children.length; nbuild++)
      {      
        for(let nfloor = 0; nfloor <  this.arrallCampusid[ncampus].children[nbuild].children.length; nfloor++)
        {     
          for(let nroom = 0; nroom < this.arrallCampusid[ncampus].children[nbuild].children[nfloor].children.length; nroom++)
          {       
            if(this.arrallCampusid[ncampus].children[nbuild].children[nfloor].children[nroom].Category == checkselectitem)
            {              
                TreeviewHelper.findItemInList(this.arrallCampusid, this.arrallCampusid[ncampus].children[nbuild].children[nfloor].children[nroom].value).checked = true;                           
            }            
          }
        }
        
      }
      
    }
    this.items = this.getProducts();
  }  
  catch (err) {
    throw new Error('edit-resident.component.ts' + ' - onSelectRoomdetails() - ' + err.message);
  }
}

getProducts(): TreeviewItem[] {
  const Tree_arrallCampusid = [] as any;
  try {
    for (const key in this.arrallCampusid) {
      const data = this.arrallCampusid[key];
      if (this.arrallCampusid[key].children.length > 0) {
        const TreeData = new TreeviewItem(data);
        Tree_arrallCampusid[Tree_arrallCampusid.length] = TreeData;
      }
    }
  }
  catch (err) {
    throw new Error('add-wanderconfig.component.ts' + ' - getProducts() - ' + err.message);
  }
  this.editresloading = false;
  return Tree_arrallCampusid;
}

onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
  try {
    this.sel_CampusId = [];        
    this.sel_BuildingId = [];
    this.sel_FloorId = [];
    this.sel_RoomId = [];    
    if(this.restrictionsHeader == "Wrong Room Space Restrictions")
      this.wrongSel_RoomId = [];    
    if(this.restrictionsHeader != "Wrong Room Space Restrictions") 
      this.egressSel_RoomId = []; 
    downlineItems.forEach(downlineItem => {
        const item = downlineItem.item;
        const value = item.value;
        const texts = [] as any[];
        let parent = downlineItem.parent;
        while (!isNil(parent)) {
          texts.push(parent.item.value);//texts.push(parent.item.text+":"+parent.item.value);
          parent = parent.parent;
        }
        const reverseTexts = reverse(texts);
        const row = `${reverseTexts.join(':')}:${value}`;
        const arr_row = row.split(':')               
        
      if (arr_row.length > 0 && !this.sel_CampusId.includes(arr_row[0]))
        this.sel_CampusId[this.sel_CampusId.length] = arr_row[0];

      if (arr_row.length > 1 && !this.sel_BuildingId.includes(arr_row[1]))
        this.sel_BuildingId[this.sel_BuildingId.length] = arr_row[1];

      if (arr_row.length > 2 && !this.sel_FloorId.includes(arr_row[2]))
        this.sel_FloorId[this.sel_FloorId.length] = arr_row[2];

      if (arr_row.length > 3 && !this.sel_RoomId.includes(arr_row[3]))
        this.sel_RoomId[this.sel_RoomId.length] = arr_row[3];
        
      if (arr_row.length > 3 && !this.wrongSel_RoomId.includes(arr_row[3]) && this.restrictionsHeader != "" && this.restrictionsHeader == "Wrong Room Space Restrictions")              
        this.wrongSel_RoomId[this.wrongSel_RoomId.length] = arr_row[3];      
        
      if (arr_row.length > 3 && !this.egressSel_RoomId.includes(arr_row[3]) && this.restrictionsHeader != "" && this.restrictionsHeader != "Wrong Room Space Restrictions")              
        this.egressSel_RoomId[this.egressSel_RoomId.length] = arr_row[3];      

    });    
  }
  catch (err) {
    throw new Error('edit-resident.component.ts' + ' - onSelectedChange() - ' + err.message);
  }
}

  public editWanderConfig = (editwanderconfigFormValue) => {
    try {
      let roomid;
      let campusid;
      let buildingid;
      let floorid;      
      let W_roomid;
      let A_roomid;
      let check_Room_AllDay = 0;
      let check_Egres_AllDay = 0;

      let W_Start_Hr = 0;
      let W_Start_Min = 0;
      let W_End_Hr = 0;
      let W_End_Min = 0;

      let A_Start_Hr = 0;
      let A_Start_Min = 0;
      let A_End_Hr = 0;
      let A_End_Min = 0;
      let wrongRoom = "";
      let allEgress = "";

      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (this.editwanderbool) {
        this.editwanderbool = false;

        if (
          editwanderconfigFormValue.wrongroom == true ||
          editwanderconfigFormValue.wrongroom == "true"
        ) {
          if (
            (editwanderconfigFormValue.wr_start == "" ||
              editwanderconfigFormValue.wr_start == undefined) &&
            (editwanderconfigFormValue.wr_end == "" ||
              editwanderconfigFormValue.wr_end == undefined) &&
            (this.checkresroominfo == false ||
              this.checkresroominfo == undefined)
          ) {
            this.editwanderbool = true;
            generateAlertMessage(
              this.dialog,
              "Please select Wrong Room Time info or All"
            );
            return false;
          }
        }

        if (
          editwanderconfigFormValue.wr_start != "" &&
          editwanderconfigFormValue.wr_start != undefined
        ) {
          if (
            editwanderconfigFormValue.wr_end == "" ||
            editwanderconfigFormValue.wr_end == undefined
          ) {
            this.editwanderbool = true;
            generateAlertMessage(
              this.dialog,
              "Please select Wrong Room End time"
            );
            return false;
          }
        }

        if (
          editwanderconfigFormValue.wr_end != "" &&
          editwanderconfigFormValue.wr_end != undefined
        ) {
          if (
            editwanderconfigFormValue.wr_start == "" ||
            editwanderconfigFormValue.wr_start == undefined
          ) {
            this.editwanderbool = true;
            generateAlertMessage(
              this.dialog,
              "Please select Wrong Room Start time"
            );
            return false;
          }
        }

        if (
          editwanderconfigFormValue.allegress == true ||
          editwanderconfigFormValue.allegress == "true"
        ) {
          if (
            (editwanderconfigFormValue.ae_start == "" ||
              editwanderconfigFormValue.ae_start == undefined) &&
            (editwanderconfigFormValue.ae_end == "" ||
              editwanderconfigFormValue.ae_end == undefined) &&
            (this.checkresallinfo == false || this.checkresallinfo == undefined)
          ) {
            this.editwanderbool = true;
            generateAlertMessage(
              this.dialog,
              "Please select All Egress Time info or All"
            );
            return false;
          }
        }

        if (
          editwanderconfigFormValue.ae_start != "" &&
          editwanderconfigFormValue.ae_start != undefined
        ) {
          if (
            editwanderconfigFormValue.ae_end == "" ||
            editwanderconfigFormValue.ae_end == undefined
          ) {
            this.editwanderbool = true;
            generateAlertMessage(
              this.dialog,
              "Please select All Egress End time"
            );
            return false;
          }
        }

        if (
          editwanderconfigFormValue.ae_end != "" &&
          editwanderconfigFormValue.ae_end != undefined
        ) {
          if (
            editwanderconfigFormValue.ae_start == "" ||
            editwanderconfigFormValue.ae_start == undefined
          ) {
            this.editwanderbool = true;
            generateAlertMessage(
              this.dialog,
              "Please select All Egress Start time"
            );
            return false;
          }
        }               

        let wstarttime = 0;
        let wendtime = 0;
        let astarttime = 0;
        let aendtime = 0;
        if (
          editwanderconfigFormValue.wr_start != undefined &&
          editwanderconfigFormValue.wr_start != 0
        ) {
          wstarttime = editwanderconfigFormValue.wr_start.split(":");
          W_Start_Hr = parseInt(wstarttime[0]);
          W_Start_Min = wstarttime[1].split(" ");
          W_Start_Min = parseInt(W_Start_Min[0]);
        }
        if (
          editwanderconfigFormValue.wr_end != undefined &&
          editwanderconfigFormValue.wr_end != 0
        ) {
          wendtime = editwanderconfigFormValue.wr_end.split(":");
          W_End_Hr = parseInt(wendtime[0]);
          W_End_Min = wendtime[1].split(" ");
          W_End_Min = parseInt(W_End_Min[0]);
        }
        if (
          editwanderconfigFormValue.ae_start != undefined &&
          editwanderconfigFormValue.ae_start != ""
        ) {
          astarttime = editwanderconfigFormValue.ae_start.split(":");
          A_Start_Hr = parseInt(astarttime[0]);
          A_Start_Min = astarttime[1].split(" ");
          A_Start_Min = parseInt(A_Start_Min[0]);
        }

        if (
          editwanderconfigFormValue.ae_end != undefined &&
          editwanderconfigFormValue.ae_end != ""
        ) {
          aendtime = editwanderconfigFormValue.ae_end.split(":");
          A_End_Hr = parseInt(aendtime[0]);
          A_End_Min = aendtime[1].split(" ");
          A_End_Min = parseInt(A_End_Min[0]);
        }                

        if (this.checkresroominfo == true) check_Room_AllDay = 1;
        else check_Room_AllDay = 0;

        if (this.checkresallinfo == true) check_Egres_AllDay = 1;
        else check_Egres_AllDay = 0;
        
        campusid = this.sel_CampusId;    
        buildingid = this.sel_BuildingId;
        floorid = this.sel_FloorId;   
        roomid = this.sel_RoomId;  
        W_roomid = this.wrongSel_RoomId; 
        A_roomid =  this.egressSel_RoomId;
        
        if(editwanderconfigFormValue.wrongroom != undefined)        
          wrongRoom = editwanderconfigFormValue.wrongroom.toString();        
        else
          wrongRoom = "";  
          
        if(editwanderconfigFormValue.allegress != undefined)        
          allEgress = editwanderconfigFormValue.allegress.toString();        
        else
          allEgress = "";
          
        const buildingIdInf=buildingid;        
        for (let nidx=0;nidx<buildingIdInf.length;nidx++)
        {
          if (buildingIdInf[nidx].toString().split("_").length>1)
          {
            roomid[roomid.length] =buildingIdInf[nidx].toString().split("_")[0];
            buildingid.slice(nidx,0);
          }
        } 
        
        if(wrongRoom == "true" && check_Room_AllDay == 0 && W_Start_Hr >= 0 && W_End_Hr <= W_Start_Hr && W_End_Hr < 12)  
        {
          if((W_Start_Hr == W_End_Hr && W_Start_Min >= W_End_Min) || (W_Start_Hr > W_End_Hr && W_Start_Min <= W_End_Min) || (W_Start_Hr >= 0 && W_End_Hr == 0 && W_End_Min == 0))
          {                       
            this.editwanderbool = true;
            generateAlertMessage(this.dialog,"Wrong Room Start time should be lesser than the End time");
            return false;
          }
        }
        
        if(allEgress == "true" && check_Egres_AllDay == 0 && A_Start_Hr >= 0 && A_End_Hr <= A_Start_Hr && A_End_Hr < 12)  
        {
          if((A_Start_Hr == A_End_Hr && A_Start_Min >= A_End_Min) || (A_Start_Hr > A_End_Hr && A_Start_Min <= A_End_Min) || (A_Start_Hr >= 0 && A_End_Hr == 0 && A_End_Min == 0))
          {                       
            this.editwanderbool = true;
            generateAlertMessage(this.dialog,"All Egress Start time should be lesser than the End time");
            return false;
          }
        }
        
        if(this.wrongSel_RoomId.length == 0 && editwanderconfigFormValue.wrongroom == "true" && this.residentRoomCount > 0)
        {
          this.editwanderbool = true;
          generateAlertMessage(this.dialog,"Must add at least one Space for Wrong Room");
          return false;
        }
        
        if(this.egressSel_RoomId.length == 0 && editwanderconfigFormValue.allegress == "true" && this.egressRoomCount > 0)
        {
          this.editwanderbool = true;
          generateAlertMessage(this.dialog,"Must add at least one Space for All Egress");
          return false;
        }
        
        const wr_ExpireTime = document.getElementById("txtwrExpireTime") as HTMLInputElement;  
        const ae_ExpireTime = document.getElementById("txtaeExpireTime") as HTMLInputElement;
        let wrongExpireTime = 0;
        let egressExpireTime = 0;
        
        if(parseInt(wr_ExpireTime.value) < 0)
        {
          this.editwanderbool = true;
          generateAlertMessage(this.dialog,"Please Enter valid Expiration Time for Wrong Room");
          return false;
        }                 
        if(wr_ExpireTime.value != "" && !/^[0-9]+$/.test(wr_ExpireTime.value))
        {
          this.editwanderbool = true;
          generateAlertMessage(this.dialog,"Please Enter valid Expiration Time for Wrong Room");
          return false;
        }   
        
        if(parseInt(ae_ExpireTime.value) < 0)
        {
          this.editwanderbool = true;
          generateAlertMessage(this.dialog,"Please Enter valid Expiration Time for All Egress");
          return false;
        }
        if(ae_ExpireTime.value != "" && !/^[0-9]+$/.test(ae_ExpireTime.value))
        {
          this.editwanderbool = true;
          generateAlertMessage(this.dialog,"Please Enter valid Expiration Time for All Egress");
          return false;
        }
        
        if(wr_ExpireTime.value != "")
          wrongExpireTime = parseInt(wr_ExpireTime.value);
          
        if(ae_ExpireTime.value != "")
          egressExpireTime = parseInt(ae_ExpireTime.value);
          
        const editroamingConfig: AddEditRoaming = {
          authKey: this.authkey,
          residentId: this.editResidentId,
          wrongRoom: wrongRoom,
          wStartHr: W_Start_Hr,
          wStartMi: W_Start_Min,
          wEndHr: W_End_Hr,
          wEndMi: W_End_Min,
          wchkAllday: check_Room_AllDay,
          allEgress: allEgress,
          aStartHr: A_Start_Hr,
          aStartMi: A_Start_Min,
          aEndHr: A_End_Hr,
          aEndMi: A_End_Min,
          // roomId: roomid,
          achkAllday: check_Egres_AllDay,
          wrongRoomId : W_roomid.toString(),
          egressRoomId : A_roomid.toString(),
          wrongExpireDate : wrongExpireTime,//editwanderconfigFormValue.wrongroomexpirationtime,
          egressExpireDate :egressExpireTime,// editwanderconfigFormValue.egressexpirationtime
        };
        if (
          this.getRoamingData.length == undefined ||
          this.getRoamingData.length == 0
        ) {
          this.residentService
            .addRoaming(editroamingConfig)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: any[]) => {
              if (apiresponse.toString() == "true") {
                this.editwanderbool = false;
                this.apiService.reloadlive();
                generateAlertMessage(
                  this.dialog,
                  "Restricted Space added successfully."
                );
              } else if (apiresponse.toString() == "false") {
                this.editwanderbool = true;
                generateAlertMessage(
                  this.dialog,
                  "Restricted Space added failed."
                );
              } else if (apiresponse["condition"] == "Authfalse") {
                this.thisDialogRef.close("close");
                AuthFailedMessage(this.dialog, this.router);
                return;
              } else if (apiresponse.toString() == "found") {
                this.editwanderbool = true;
                generateAlertMessage(
                  this.dialog,
                  "Already this resident have Restricted Space"
                );
                return;
              }
              this.thisDialogRef.close("Confirm");
            });
        } else {
          this.residentService
            .updateRoaming(editroamingConfig)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: any[]) => {
              if (apiresponse.toString() == "true") {
                this.editwanderbool = false;
                this.apiService.reloadlive();
                generateAlertMessage(
                  this.dialog,
                  "Restricted Space edited successfully."
                );
              } else if (apiresponse.toString() == "false") {
                this.editwanderbool = true;
                generateAlertMessage(
                  this.dialog,
                  "Restricted Space edited failed."
                );
              } else if (apiresponse["condition"] == "Authfalse") {
                this.thisDialogRef.close("close");
                AuthFailedMessage(this.dialog, this.router);
                return;
              }
              this.thisDialogRef.close("Confirm");
            });
        }
      }
    } catch (err) {
      this.editwanderbool = true;
      throw new Error(
        "edit-resident.component.ts" + " - updateRoaming() - " + err.message
      );
    }
  };

  toggleresdayinfoVisibility(event) {
    if (event.checked == true) {
      this._roomenablehourmin = false;
      this.checkresroominfo = true;
      this.data_wrstartTime = 0;
      this.data_wrendTime = 0;
    } else {
      this._roomenablehourmin = true;
      this.data_wrstartTime = 0;
      this.data_wrendTime = 0;
      this.checkresroominfo = false;
    }
  }

  toggleresalldayinfoVisibility(event) {
    if (event.checked == true) {
      this._egressenablehourmin = false;
      this.checkresallinfo = true;
      this.data_aestartTime = 0;
      this.data_aeendTime = 0;
    } else {
      this._egressenablehourmin = true;
      this.data_aestartTime = 0;
      this.data_aeendTime = 0;
      this.checkresallinfo = false;
    }
  }

  toggleEditCheckInVisibility(event) {
    if (event.checked == true) {
      this.editCheck_Invalue = true;
    } else {
      this.editCheck_Invalue = false;
    }
  }

  toggleEditVoiceVisibility(event) {
    if (event.checked == true) {
      this.editVoicevalue = true;
    } else {
      this.editVoicevalue = false;
    }
  }
  
  toggleEditWanderVisibility(event)
  {
      if(event.checked == true)
      {
        this.editResWandervalue = true;       
      }
      else
      {      
        this.editResWandervalue = false;         
      }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
