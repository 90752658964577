
<div style="height:100%;" *ngIf="deviceEditflag == 0">
  <perfect-scrollbar style="max-width: 650px; max-height: 610px;" >
    <div class="clseditoutdoor">
        <div class="editoutdoor-title">
          <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editoutdoorHeader" style="margin: 0 0 10px 11px;" *ngIf="selectedUnitName.length < 15">{{selectedUnitName}}          
          <span><img class="clseditoutcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
          
          <h3 class="editoutdoorHeader" style="margin: 0 0 10px 11px;" *ngIf="selectedUnitName.length >= 15"
            matTooltipClass="devicetooltip" matTooltip={{selectedUnitName}} #tooltip="matTooltip" matTooltipPosition="above">
            {{selectedUnitName.slice(0,15) + ".."}}
            <span><img class="clseditoutcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
          <mat-divider ></mat-divider>
        </div>
    </div>
    <div>
      
      <div>
        
        <div >                                                                            
          <div class="text-inside" style="margin:1em 0em;display: flex;">  
            <label style="margin-left:2em;">Campus : </label>          
            <div class="clsdivoutdoortext" class="clsdivoutdoortext" *ngIf="CampusName.length < 15"> {{CampusName}}</div>
            <div class="clsdivoutdoortext" class="clsdivoutdoortext" *ngIf="CampusName.length >= 15" matTooltipClass="devicetooltip" matTooltip={{CampusName}} #tooltip="matTooltip" matTooltipPosition="above"> 
              {{CampusName.slice(0,15) + ".."}}</div>
         </div>
         
         <div class="text-inside" class="clseditdivtextcontent">  
          <label style="margin-left:2em;">Description : </label>          
          <div class="clsdivoutdoortext" *ngIf="Description.length < 15"> {{Description}}</div>
          <div class="clsdivoutdoortext" *ngIf="Description.length >= 15" matTooltipClass="devicetooltip" matTooltip={{Description}} #tooltip="matTooltip" matTooltipPosition="above">
             {{Description.slice(0,15) + ".."}}</div>
         </div>
         
         <div class="text-inside" class="clseditdivtextcontent">  
          <label style="margin-left:2em;">Space ID : </label>          
          <div class="clsdivoutdoortext"> {{maxSpaceId}}</div>          
         </div>
         
         <div class="text-inside" class="clseditdivtextcontent">  
          <label style="margin-left:2em;">Locating Accuracy : </label>          
          <div class="clsdivoutdoortext"> {{Accuracy}}</div>
         </div>
         
         <mat-dialog-actions class="clseditoutbuttoncontrol" style="margin-right:2em;">                                           
          <button id="btnsaveEditclick" class="clseditoutdoorbutton" mat-raised-button (click)="openOutdoorEditdialog()">Edit</button>
        </mat-dialog-actions>
        </div>  
                
      </div>
      
     
    </div>
  </perfect-scrollbar>
</div>


<div style="height:100%;" *ngIf="deviceEditflag == 1">
<perfect-scrollbar style="max-width: 650px; max-height: 610px;" >
  <div class="clseditoutdoor">
      <div class="editoutdoor-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editoutdoorHeader" style="margin: 0 0 10px 11px;">Outdoor Space Information
        <span><img class="clseditoutcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>
      <div>
        <form style="padding:24px;" [formGroup]="editoutdoorspaceForm" autocomplete="off" novalidate (ngSubmit)="editOutdoorSpacedata(editoutdoorspaceForm.value)">                         
            <mat-grid-list [cols]="columns" rowHeight="72px">  
                <mat-grid-tile>
                    <label>Campus</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                            <input matInput placeholder="Campus Name" maxlength="32" formControlName="campus" [(ngModel)]="Outdoor_DeviceData.CampusName">
                            <input type="hidden" formControlName="campusId" [(ngModel)]="Outdoor_DeviceData.CampusId" required>                              
                            <input type="hidden" formControlName="buildId" [(ngModel)]="Outdoor_DeviceData.BuildId" required> 
                            <input type="hidden" formControlName="floorId" [(ngModel)]="Outdoor_DeviceData.FloorId" required> 
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

                <mat-grid-tile>
                  <label>Approximate <br>Space Name</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:62%;">
                           <input matInput placeholder="Approximate Space Name" maxlength="32" formControlName="name" [(ngModel)]="Outdoor_DeviceData.UnitName" (keyup)="handleInput($event)">
                           <mat-error *ngIf="editoutdoorspaceForm.controls.name.hasError('required')">Please enter Approximate Name</mat-error>
                           <mat-error *ngIf="editoutdoorspaceForm.controls.name.hasError('pattern')">Please enter valid Approximate Name</mat-error>
                           <mat-error *ngIf="editoutdoorspaceForm.controls.name.hasError('maxlength')">Max length 32 characters only</mat-error>
                        </mat-form-field>
                        
                        <label style="padding-left:2em;">Indoor</label>
                        <mat-slide-toggle [checked]="checkApproximate" [disabled]="checkCampusorZone == 0" style="padding-left:1em;" ></mat-slide-toggle>
                      </div>
                </mat-grid-tile>  
                
                <mat-grid-tile>
                  <label>Description</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="Description" maxlength="32" formControlName="description" [(ngModel)]="approxDescription" (keyup)="handleInput($event)">
                          <!-- <mat-error *ngIf="editoutdoorspaceForm.controls.description.hasError('required')">Please enter Description</mat-error> -->
                          <mat-error *ngIf="editoutdoorspaceForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                          <!-- <mat-error *ngIf="editoutdoorspaceForm.controls.description.hasError('maxlength')">Max length 32 characters only</mat-error> -->
                        </mat-form-field>
                      </div>
                </mat-grid-tile> 
                
                <mat-grid-tile>
                  <label>Space ID</label>
                  <div style="margin-left: 1.5em;" [matTooltip]="matTooltipSpaceId" matTooltipClass="tooltip" #tooltip="matTooltip" (click)="tooltip.show()">                
                    <mat-icon style="color: #8e8a8a;margin-top:0.4em;">info icon</mat-icon>                                                  
                  </div>  
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="Space Id" maxlength="10" formControlName="spaceId" [(ngModel)]="maxSpaceId" (keyup)="handleInput($event)">
                          <mat-error *ngIf="editoutdoorspaceForm.controls.spaceId.hasError('required')">Please enter Space ID</mat-error>
                          <mat-error *ngIf="editoutdoorspaceForm.controls.spaceId.hasError('pattern')">Please enter valid Space ID</mat-error>                          
                        </mat-form-field>
                      </div>
                </mat-grid-tile> 
              
              <mat-grid-tile>
                <label>Location<br>Accuracy</label>
                <div style="margin-left: 1.5em;" [matTooltip]="matTooltipLocAccuracy" matTooltipClass="tooltip" #tooltip="matTooltip" (click)="tooltip.show()">                
                  <mat-icon style="color: #8e8a8a;margin-top:0.2em;">info icon</mat-icon>                                                  
                </div> 
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                        <input matInput placeholder="Location Accuracy" maxlength="10" formControlName="accuracy" [(ngModel)]="Accuracy">
                        <mat-error *ngIf="editoutdoorspaceForm.controls.accuracy.hasError('required')">Please enter Location Accuracy</mat-error>
                        <mat-error *ngIf="editoutdoorspaceForm.controls.accuracy.hasError('pattern')">Please enter valid Location Accuracy</mat-error>
                        <!-- <mat-error *ngIf="editoutdoorspaceForm.controls.accuracy.hasError('maxlength')">Max length 32 characters only</mat-error> -->
                      </mat-form-field>
                    </div>
              </mat-grid-tile> 
              <mat-grid-tile colspan="3">
                <label>Note: The Location Accuracy value shown above is automatically populated from the signature server.</label>
               </mat-grid-tile>
               </mat-grid-list>
                
            <mat-dialog-actions style="float:left;margin-top: 2em;">              
              <button type="button" class="clseditoutcancelbutton" mat-raised-button (click)="openDeletedialog()">Delete</button> 
            </mat-dialog-actions>

            <mat-dialog-actions class="clseditoutbuttoncontrol">                                   
              <button type="button" class="clseditoutcancelbutton" mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
              <button id="btnsaveoutdooreditclick" class="clseditoutdoorbutton" mat-raised-button >Save</button>
            </mat-dialog-actions>
            
        </form>
      </div>
  </div>
  </perfect-scrollbar>
</div>
