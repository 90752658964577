import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { saveTemplateFloor } from "../../../technologyoverlay";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import {  enumEnableDisable,appsettings } from "../../../../../app-settings";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../../../../../api.service";
import { Observable } from "rxjs";
import { getUnit } from "../../../../../api";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-template-zone",
  templateUrl: "./template-zone.component.html",
  styleUrls: ["./template-zone.component.scss"],
})
export class TemplateZoneComponent implements OnInit {
  public floorTempateForm: UntypedFormGroup;
  selectedFloorId;
  FloorInfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  SpaceInfo;
  public watcher: Observable<boolean>;
  columns = 4;
  firstcolumns = 2;
  minheight = "350px";
  rowHeight = "79px";
  checkfloortempinfo = false;
  checkfloortempselectall = false;
  select_SpaceIds = [] as any[];
  floortempcountchecked_id = 0;
  ZoneImageName = "";
  TempaddImgUrl;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<TemplateZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    public http: HttpClient,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.selectedFloorId = data.FloorId;
    this.ZoneImageName = data.FloorPlanImage;
    this.breakpoint$.subscribe(() => this.breakpointChanged());        
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }    
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.floorTempateForm = new UntypedFormGroup({
      floorname: new UntypedFormControl(""),
      campusID: new UntypedFormControl(""),
      buildID: new UntypedFormControl(""),
      floorID: new UntypedFormControl(""),
      newtempname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]), // ^[a-zA-Z0-9!@#$%^& ]*$
      description: new UntypedFormControl("", [
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });

    const tempFormFloorName = this.floorTempateForm.get("floorname");
    if (tempFormFloorName != null) tempFormFloorName.disable();

    this.getUnitDetails();
  }

  getUnitDetails() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const buildbyid: getUnit = {
        authKey: this.authkey,
        floorId: this.selectedFloorId,
        isIndoor: 1,
        isNeedIntercom: parseInt(voiceLicense),
        isSubZone: 0 
      };
      this.apiService
        .getUnitDetails(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((spaceinfo: any[]) => {
          this.SpaceInfo = spaceinfo["apiReturn"];
          this.SpaceInfo.forEach(function (data) {
            data["DeviceZoneCount"] = data.devicecount + data.Zonedevicecount;
          });
        });
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" + " - getUnitDetails() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  isCheckedAll = false;
  toggleAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.select_SpaceIds = [];
      this.SpaceInfo.forEach((row) => {
        const checkedid = row.UnitId;
        this.select_SpaceIds.push(checkedid);
      });
    } else {
      this.select_SpaceIds = [];
      this.select_SpaceIds.length = 0;
    }
    this.isCheckedAll = this.isChecked();
  }

  toggleresVisibility(checkedid, event: MatCheckboxChange) {
    if (event.checked) {
      const index = this.select_SpaceIds.indexOf(checkedid);
      if (index == -1) {
        this.select_SpaceIds.push(checkedid);
      }
    } else {
      const index = this.select_SpaceIds.indexOf(checkedid);
      if (index >= 0) {
        this.select_SpaceIds.splice(index, 1);
      }
    }
    this.isCheckedAll = this.isChecked();
  }

  exists(checkedid) {
    return this.select_SpaceIds.indexOf(checkedid) > -1;
  }

  isChecked() {
    return this.select_SpaceIds.length === this.SpaceInfo.length;
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.floorTempateForm.controls).forEach((field) => {
        // {1}
        const control = this.floorTempateForm.get(field); // {2}
        const formFloorTargetId = document.getElementById(event.target.id);
        if (formFloorTargetId != null) {
          if (field == formFloorTargetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public templatefloorDetails = (templatefloorformvalue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.floorTempateForm.valid) {
        this.saveAsTemplateForFloor(templatefloorformvalue);
      }
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" +
          " - templatefloorDetails() - " +
          err.message
      );
    }
  };
  //When we click the Save as Template, a window will open and it will have the selected zone information and it spaces information. We can choose the spaces.
  saveAsTemplateForFloor(templatefloorformvalue) {
    try {
      if (this.select_SpaceIds.length == 0 && this.SpaceInfo.length > 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Space to create as a new Zone Template."
        );
        this.templateFloorEnableDisable(enumEnableDisable.Enable);
        return;
      }
      if (this.select_SpaceIds.length == 0 && this.SpaceInfo.length == 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Space to duplicate or create as a Template."
        );
        this.templateFloorEnableDisable(enumEnableDisable.Enable);
        return;
      }

      this.templateFloorEnableDisable(enumEnableDisable.Disable);
      const templatefloor: saveTemplateFloor = {
        authKey: this.authkey,
        campusId: parseInt(templatefloorformvalue.campusID),
        buildingId: parseInt(templatefloorformvalue.buildID),
        floorId: parseInt(templatefloorformvalue.floorID),
        tempImageName: this.ZoneImageName,
        templateName: templatefloorformvalue.newtempname.toString(),
        templateDescription: templatefloorformvalue.description.toString(),
        spaceIds: this.select_SpaceIds.toString(),
      };

      this.technologyoverlayService
        .saveAsTemplateForFloor(templatefloor)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "Zone Template added successfully."
            );
            this.templateFloorEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close(this.selectedFloorId);
            return;
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Zone Template added failed.");
            this.templateFloorEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close("Cancel");
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else if (apiresponse["TemplateNamereturn"].length > 0) {
            generateAlertMessage(
              this.dialog,
              "Zone Template name ' " +
                apiresponse["TemplateNamereturn"] +
                " ' already exists."
            );
            this.templateFloorEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" +
          " - saveAsTemplateForFloor() - " +
          err.message
      );
    }
  }

  templateFloorEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnzonetempsaveclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clstempsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clstempsavebutton mat-raised-button disablebtn";
  }
}
