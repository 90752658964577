<app-report-header [ReportType]="ReportType"></app-report-header>
<mat-card class="report-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-report-leftbar ></app-report-leftbar>
    </div>
      
    <div fxLayout="column" style="width:100%;">
    <!--Infrastructure Report-->
    <div class="table-container">     
        <div class="main-table"> 
        <div class="landing-title-bar">
          <div class="blocks" fxLayout="column" fxLayoutAlign="left">
            <mat-card-header>
              <mat-card-title style="font-size: 20px;margin-top:15px;">
                <span class="clsTitle">Infrastructure Report</span>
              </mat-card-title>
            </mat-card-header>
          </div>
        </div> 

        <perfect-scrollbar class="chartscrollbarInfraReport">
        <div  class="clsInfradownloadbutton" fxLayout.sm="column">
          <button color="primary" mat-raised-button (click)="infrastructureReportdata()">Download</button>
        </div>

        <div class="search" fxLayout.sm="column">
          <mat-form-field appearance="outline" floatLabel="auto" class="clsalertmatfield">
            <input matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchInfrastructureReport($event.target.value)"
              autocomplete="off" placeholder="Search" #searchInfraInput>
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
       
          <div *ngIf="InfradataSource  else noRecord">
            <table mat-table [dataSource]="InfradataSource" matSort (matSortChange)="sortData($event)" #Infrasort="matSort" matSortDisableClear=true>    
              <!-- DeviceId Column -->

                <!-- DeviceType Column -->
                <ng-container matColumnDef="DeviceType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Device Type</th>
                  <td mat-cell *matCellDef="let element" style="width:100px;">  
                  <span *ngIf="element.DeviceType != 'UT'" >{{element.DeviceType}}</span>
                  <span *ngIf="element.DeviceType == 'UT'" >UT</span></td>
                </ng-container>

              <ng-container matColumnDef="DeviceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Device Id</th>
                <td mat-cell *matCellDef="let element"> {{element.DeviceId}} </td>
              </ng-container>
           
               <!-- LF / IR Column -->
               <ng-container matColumnDef="LForIrid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>LF / IR Id </th>
                <td mat-cell *matCellDef="let element"> {{element.LForIrid}} </td>
              </ng-container>

              <!-- MacId Column -->
              <ng-container matColumnDef="MacId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mac Id </th>
                <td mat-cell *matCellDef="let element" style="width:150px;"> {{element.MacId}} </td>
              </ng-container>

              <!-- DualUTId Column -->
              <ng-container matColumnDef="DualUTId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> UT Id </th>
                <td mat-cell *matCellDef="let element"> {{element.DualUTId}} </td>
              </ng-container>

                <!-- CampusName Column -->
                <ng-container matColumnDef="CampusName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Campus Name</th>
                  <td mat-cell *matCellDef="let element" style="word-break: break-word;"> 
                  <span *ngIf="element.CampusName.length < 10" >{{element.CampusName}}</span>
                  <span *ngIf="element.CampusName.length >= 10" matTooltipClass="infratooltip" matTooltip={{element.CampusFullName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.CampusName}}</span>
                  </td>
                </ng-container>

                  <!-- BuildingName Column -->
                <ng-container matColumnDef="BuildingName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Building Name</th>
                  <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.BuildingName.length < 10" >{{element.BuildingName}}</span>
                  <span *ngIf="element.BuildingName.length >= 10" matTooltipClass="infratooltip" matTooltip={{element.BuildingFullName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.BuildingName}}</span>
                  </td>
                </ng-container>
                
                <!-- FloorName Column -->
                <ng-container matColumnDef="FloorName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Zone Name</th>
                  <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.FloorName.length < 10" >{{element.FloorName}}</span>
                  <span *ngIf="element.FloorName.length >= 10" matTooltipClass="infratooltip" matTooltip={{element.FloorFullName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.FloorName}}</span>
                  </td>
                </ng-container>

                
             <!-- DeviceName Column -->
             <ng-container matColumnDef="RoomName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Space</th>
              <td mat-cell *matCellDef="let element" style="word-break: break-word;">
              <span *ngIf="element.RoomName.length < 10" >{{element.RoomName}}</span>
              <span *ngIf="element.RoomName.length >= 10" matTooltipClass="infratooltip" matTooltip={{element.RoomFullName}} #tooltip="matTooltip" matTooltipPosition="above">{{element.RoomName}}</span>
              </td>
            </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedInfraReportColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns:displayedInfraReportColumns;"></tr>
            </table>

            <h3 *ngIf="infradatasourceCount == 0 && !infraloading" style="text-align: center;margin-top:1em;"> No Record found </h3> 
          </div>

          <ng-template #noRecord>                           
                <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%">       
                    <div class="loadspinner" *ngIf="infraloading">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>            
          </ng-template>

          <mat-paginator   [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
        </perfect-scrollbar>
        </div>
    </div>
    </div>


  </div>
</mat-card>