import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  UntypedFormGroup,
  UntypedFormControl,
  } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { GetStaffdetails, cancelAlert } from "../../../api";
import { MainLayoutService } from "../../main-layout.service";
import { appsettings, enumAlertStatus } from "../../../app-settings";
import { SharedAlertLoadData } from "../../../api";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { generateAlertMessage } from "../../../app-general";
import { ApiService } from "../../../api.service";

@Component({
  selector: "app-ack-alert",
  templateUrl: "./ack-alert.component.html",
  providers: [MainLayoutService],
  styleUrls: ["./ack-alert.component.scss"],
})
export class AckAlertComponent implements OnInit, OnDestroy {
  SharedAlertLoadDetails: SharedAlertLoadData;
  private URL = appsettings.URLinfo + "getAuthorizer";
  public GetStaffdetails: GetStaffdetails[];
  strDeviceId: number;
  public ackalertForm: UntypedFormGroup;
  staffid = 0;
  alertreturnVal = 0;
  alertstaffname = "";
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<AckAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    public snackBar: MatSnackBar,    
    private apiService: ApiService    
  ) {
    if (data.Alertresstaff == 0) this.alertstaffname = data.ResidentName;
  }

  ngOnInit() {
    try {
      this.getAuthorizedList();
      this.ackalertForm = new UntypedFormGroup({
        authorizedby: new UntypedFormControl(""),
      });
    } catch (err) {
      throw new Error(
        "ack-alert.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
  };

  getAuthorizedList() {
    try {
      this.http
        .get(this.URL)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
          (result) => {
            this.GetStaffdetails = result as GetStaffdetails[];
            const selectedstaffname = this.alertstaffname;
            for (const order of this.GetStaffdetails) {
              if (selectedstaffname === order.LastName) {
                this.GetStaffdetails.splice(
                  this.GetStaffdetails.indexOf(order),
                  1
                );
                break;
              }
            }
          },
          (error) => console.error(error)
        );
    } catch (err) {
      throw new Error(
        "ack-alert.component.ts" + " - getAuthorizedList() - " + err.message
      );
    }
  }

  onChange(args) {
    this.staffid = args.value;
  }

  clkAcknowledgeAlert(alertid) {
    try {
      this.alertreturnVal = 1;
      if (this.staffid == 0) {
        generateAlertMessage(
          this.dialog,
          "Please select authorizing staff details"
        );
        return false;
      }
      const authkey = localStorage.getItem("Authkey") ?? "";
      const ackAlerts: cancelAlert = {
        authKey: authkey,
        staffId: this.staffid,
        alertId: parseInt(alertid),
        status: enumAlertStatus.Complete,
      };
      this.apiService
        .cancelAlert(ackAlerts)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["apiReturn"] == "Success")
            this.openSnackBar(
              "Alert Acknowledged Successfully.",
              "ok",
              "success"
            );
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "ack-alert.component.ts" + " - clkAcknowledgeAlert() - " + err.message
      );
    }
  }

  openSnackBar(message: string, action: string, isSuccess: string) {
    if (isSuccess == "success") {
      this.snackBar.open(message, "", {
        duration: 4000,
        panelClass: ["snackbarsuccess"],
      });
    } else {
      this.snackBar.open(message, "", {
        duration: 4000,
        panelClass: ["snackbarfail"],
      });
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
