
<div id="exportLiveview"> 
  <div class="add-title">
    <h3 class="exportHeader" style="margin: 0 0 10px 11px;">Liveview Download
    <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
    <mat-divider ></mat-divider> 
  </div>


 <div>
  <button class="btnExcel" color="primary" mat-raised-button (click)="exportLiveviewReportExcel(data)" >Download CSV</button> 
  <button id="pdf1" class="btnPdf" color="primary" mat-raised-button (click)="exportLiveviewReportPdf(data)" >Download pdf</button> 
 </div>

 <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%;">       
  <div class="loadLiveviewreportspinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
  </div>
</div> 

</div>