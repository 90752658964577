
<perfect-scrollbar style="max-width: 650px; max-height: 470px;" >
  <div class="clsreleaseresident" *ngIf="data.Reltype == Release; else residentReallocate">
      <div class="release-title">
        <h3 class="releaseHeader" style="margin: 0 0 10px 11px;">Release Resident
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>
      <div>
        <form  style="padding:24px" [formGroup]="residentreleaseForm" autocomplete="off" novalidate (ngSubmit)="releaseResidentbyId(residentreleaseForm.value)">
  
            <mat-grid-list [cols]="columns" rowHeight="63px">  
                <mat-grid-tile>
                    <label>Resident Name</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                  <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="First Name" formControlName="residentname" [(ngModel)]="residentname">
                          <input type="hidden" formControlName="residentId" [(ngModel)]="data.ResidentbyId[0].ResidentID">                                                
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

                <mat-grid-tile>
                    <label>Date</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                  <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                           <input matInput formControlName="date" [ngModel] ="currentDate | date:'yyyy-MM-dd'">
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

                <mat-grid-tile>
                  <label>Status</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <mat-select placeholder="Select Resident Status" formControlName="residentstatus">    
                            <mat-option value="2">Deceased </mat-option>
                            <mat-option value="3">Released</mat-option>
                        </mat-select>
                        <mat-error>Please select Status</mat-error>
                      </mat-form-field>
                    </div>
              </mat-grid-tile>

              <mat-grid-tile>
                <label>Reason</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <div class="text-inside" style="width:100%;margin-bottom:0.5em;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="Reason" formControlName="reason" maxlength="30" (keyup)="handleInput($event)">
                      <mat-error *ngIf="residentreleaseForm.controls.reason.hasError('required')">
                        Please enter Reason
                      </mat-error>
                      <mat-error *ngIf="residentreleaseForm.controls.reason.hasError('pattern')">
                        Please enter valid Reason
                      </mat-error>
                    </mat-form-field>
                  </div>
            </mat-grid-tile>   
            
            <mat-grid-tile>
              <label>Authorized By</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
            <div class="text-inside" style="width:100%;">
                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="First Name" formControlName="authorizedby" [(ngModel)]="authorizedName">
                    <mat-error>Please select Authorizer</mat-error>
                  </mat-form-field>
                </div>
          </mat-grid-tile>

                </mat-grid-list>

            <mat-dialog-actions class="clsbuttoncontrol">                  
              <button type="button" class="clsbtnResCancel" mat-raised-button (click)="onCloseCancel()">Cancel</button>
              <button id="btnresidentrelease" class="clsbtnResRelease" mat-raised-button>Release</button>&nbsp;
            </mat-dialog-actions>
        </form>
      </div>
  </div>

  <ng-template #residentReallocate> 
  <div>
    <div class="release-title">
      <h2 class="releaseHeader" style="margin: 0 0 10px 11px;">Change Resident Status
      <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h2>
      <mat-divider ></mat-divider> 
    </div>
    <div>
      <form style="padding:24px" [formGroup]="residentreallocateForm" autocomplete="off" novalidate (ngSubmit)="reallocateResidentbyId(residentreallocateForm.value)">

          <mat-grid-list [cols]="columns" rowHeight="72px">  
              
              <mat-grid-tile>
                <label class="clstxtcontrol">Reallocate</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <div class="text-inside" style="width:100%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                        <input type="hidden" formControlName="residentId" [(ngModel)]="data.ResidentbyId[0].ResidentID">
                        <mat-select placeholder="Select Resident Status" formControlName="reallocatestatus" [(ngModel)]="selectedvalue">
                          <mat-option value="1">Current </mat-option>
                      </mat-select>
                      <mat-error>Please select Status</mat-error>
                    </mat-form-field>
                  </div>
            </mat-grid-tile>

            <mat-grid-tile>
              <label class="clstxtcontrol">TagId</label>
            </mat-grid-tile>
          <mat-grid-tile colspan="2">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                  <input matInput placeholder="TagId" formControlName="tagid" maxlength="10" [(ngModel)]="data.ResidentbyId[0].TagId" (keyup)="handleInput($event)">
                  <mat-error *ngIf="residentreallocateForm.controls.tagid.hasError('required')">Please Enter TagId</mat-error>
                  <mat-error *ngIf="residentreallocateForm.controls.tagid.hasError('pattern')">
                    Please enter the valid Tag Id
                  </mat-error>
                  <mat-error *ngIf="residentreallocateForm.controls.tagid.hasError('maxlength')">
                     between 1 and 1073741823
                  </mat-error>
                  <mat-error *ngIf="residentreallocateForm.controls.tagid.hasError('max') || residentreallocateForm.controls.tagid.hasError('min')">
                     between 1 and 1073741823
                  </mat-error>                 
              </mat-form-field>
            </div>
          </mat-grid-tile>

              </mat-grid-list>

          <mat-dialog-actions class="clsbuttoncontrol">                
            <button type="button" class="clsbtnResCancel" mat-raised-button (click)="onCloseCancel()">Cancel</button>
            <button id="btnresidentsave" class="clsbtnResRelease" mat-raised-button>OK</button>&nbsp;
          </mat-dialog-actions>
      </form>
    </div>
</div>
  </ng-template>
</perfect-scrollbar>
