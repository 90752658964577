import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { duplicateZone } from "../../../technologyoverlay";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import { enumEnableDisable,appsettings } from "../../../../../app-settings";
import { Router } from "@angular/router";
import { ApiService } from "../../../../../api.service";
import { getCampus, getBuilding, getUnit } from "../../../../../api";
import { Observable } from "rxjs";

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-duplicate-zone",
  templateUrl: "./duplicate-zone.component.html",
  styleUrls: ["./duplicate-zone.component.scss"],
})
export class DuplicateZoneComponent implements OnInit {
  public floorDuplicateForm: UntypedFormGroup;
  selectedFloorId;
  FloorInfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  SpaceInfo;
  public watcher: Observable<boolean>;
  columns = 4;
  firstcolumns = 2;
  minheight = "350px";
  rowHeight = "79px";
  checkspaceinfo = false;
  checkspaceselectall = false;
  select_SpaceIds = [] as any[];
  Campusdetails;
  Buildingdetails;
  selectedCampusId;
  selectedBuildingId;
  countchecked_id = 0;
  isCheckedAll = false;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<DuplicateZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.selectedFloorId = data.FloorId;
    
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }    
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "350px";
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.floorDuplicateForm = new UntypedFormGroup({
      floorname: new UntypedFormControl(""),
      campusID: new UntypedFormControl(""),
      buildID: new UntypedFormControl(""),
      floorID: new UntypedFormControl(""),
      campus: new UntypedFormControl("", [Validators.required]),
      building: new UntypedFormControl("", [Validators.required]),
      newfloorname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]), // ^[a-zA-Z0-9!@#$%^& ]*$
      description: new UntypedFormControl("", [
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
    const floorName_form = this.floorDuplicateForm.get("floorname");
    if (floorName_form != null) floorName_form.disable();
    // this.floorDuplicateForm.get('buildingname').disable();
    // this.floorDuplicateForm.get('campusname').disable();

    this.getCampusDetails();
    this.isCheckedAll = true;
    this.getUnitDetails();
  }

  getCampusDetails() {
    try {
      const campusid: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" +
          " - getCampusDetails() - " +
          err.message
      );
    }
  }

  getBuildingDetails(campusid) {
    try {
      const buildid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(campusid),
      };
      this.apiService
        .getBuildingDetails(buildid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          this.Buildingdetails = BuildingDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" +
          " - getBuildingDetails() - " +
          err.message
      );
    }
  }

  changecampus_duplicate(campusid) {
    this.selectedCampusId = campusid;
  }

  changebuilding_duplicate(buildingid) {
    this.selectedBuildingId = buildingid;
  }

  getUnitDetails() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const buildbyid: getUnit = {
        authKey: this.authkey,
        floorId: this.selectedFloorId,
        isIndoor: 1,
        isNeedIntercom: parseInt(voiceLicense),
        isSubZone: 0 
      };
      this.apiService
        .getUnitDetails(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((spaceinfo: any[]) => {
          this.SpaceInfo = spaceinfo["apiReturn"];
          this.SpaceInfo.forEach(function (data) {
            data["DeviceZoneCount"] = data.devicecount + data.Zonedevicecount;
          });
          this.toggleAll(this.isCheckedAll);
        });
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" + " - getUnitDetails() - " + err.message
      );
    }
  }

  toggleAll(event: any) {
    if (event.checked || event == true) {
      this.select_SpaceIds = [];
      this.SpaceInfo.forEach((row) => {
        const checkedid = row.UnitId;
        this.select_SpaceIds.push(checkedid);
      });
    } else {
      this.select_SpaceIds = [];
      this.select_SpaceIds.length = 0;
    }
    this.isCheckedAll = this.isChecked();
  }

  toggleresVisibility(checkedid, event: MatCheckboxChange) {
    if (event.checked) {
      const index = this.select_SpaceIds.indexOf(checkedid);
      if (index == -1) {
        this.select_SpaceIds.push(checkedid);
      }
    } else {
      const index = this.select_SpaceIds.indexOf(checkedid);
      if (index >= 0) {
        this.select_SpaceIds.splice(index, 1);
      }
    }
    this.isCheckedAll = this.isChecked();
  }

  exists(checkedid) {
    return this.select_SpaceIds.indexOf(checkedid) > -1;
  }

  isChecked() {
    return this.select_SpaceIds.length === this.SpaceInfo.length;
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.floorDuplicateForm.controls).forEach((field) => {
        // {1}
        const control = this.floorDuplicateForm.get(field); // {2}
        const formFloorTargetId = document.getElementById(event.target.id);
        if (formFloorTargetId != null) {
          if (field == formFloorTargetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public duplicatefloorDetails = (duplicatefloorformvalue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.floorDuplicateForm.valid) {
        this.duplicateZone(duplicatefloorformvalue);
      }
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" +
          " - duplicatefloorDetails() - " +
          err.message
      );
    }
  };
  //When we click the Duplicate in zone a popup window , it will show two options. To Duplicate the zone or Save as Template.
  duplicateZone(duplicatefloorformvalue) {
    try {
      if (this.select_SpaceIds.length == 0 && this.SpaceInfo.length > 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Space to create as a new Zone."
        );
        this.duplicateFloorEnableDisable(enumEnableDisable.Enable);
        return;
      }
      if (this.select_SpaceIds.length == 0 && this.SpaceInfo.length == 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Space to duplicate or create as a Template."
        );
        this.duplicateFloorEnableDisable(enumEnableDisable.Enable);
        return;
      }

      this.duplicateFloorEnableDisable(enumEnableDisable.Disable);

      let _Description = "";
      if (duplicatefloorformvalue.description != "")
        _Description = duplicatefloorformvalue.description
          .replace(/\s+/g, " ")
          .trim();
      else _Description = "";

      const duplicatefloor: duplicateZone = {
        authKey: this.authkey,
        campusId: parseInt(duplicatefloorformvalue.campusID),
        buildingId: parseInt(duplicatefloorformvalue.buildID),
        zoneId: parseInt(duplicatefloorformvalue.floorID),
        newCampusId: parseInt(this.selectedCampusId),
        newBuildingId: parseInt(this.selectedBuildingId),
        newZoneName: duplicatefloorformvalue.newfloorname
          .replace(/\s+/g, " ")
          .trim(),
        newZoneDescription: _Description, //duplicatefloorformvalue.description,
        spaceIds: this.select_SpaceIds.toString(),
      };
      this.technologyoverlayService
        .duplicateZone(duplicatefloor)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["FloorId"] > 0) {
            this.duplicateFloorEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close(apiresponse["FloorId"]);
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Zone Duplicate added failed.");
            this.duplicateFloorEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close("close");
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(
              this.dialog,
              "Zone name '" + apiresponse[0].FloorName + "' already exists."
            );
            this.duplicateFloorEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "duplicate-floor.component.ts" + " - duplicateZone() - " + err.message
      );
    }
  }

  duplicateFloorEnableDisable(checkflag) {
    const elementcontent = document.getElementById(
      "btnsavefloorduplicateclick"
    );
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsfloordupsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsfloordupsavebutton mat-raised-button disablebtn";
  }
}
