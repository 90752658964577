<div class="clseditunitDevice">
  <div class="uniteditDevice-title">
    <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="unitdeveditHeader"
      style="margin: 0 0 10px 11px;">Edit Device
      <span><img class="clsunitdevcloseimage" src="assets/images/Close_1.png" (click)="onCloseDeviceDialog()"></span>
    </h3>
    <mat-divider></mat-divider>
  </div>
  <!-- <perfect-scrollbar style="max-width: 1050px; max-height: 580px;"> -->
  <div class="clseditunitdevcontent">
    <form style="padding-top:0px;padding-left:24px;padding-right:24px;" [formGroup]="deviceTempeditForm"
      autocomplete="off" novalidate (ngSubmit)="editSpaceDevicedetails(deviceTempeditForm.value)">

      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill
        *ngIf="new_data[0].DeviceName != 'UT'">
        <mat-grid-list [cols]="firstcolumns" [rowHeight]="devicerowHeight">
          <mat-grid-tile class="clsunitdevmatgrid">
            <label>Device</label>
          </mat-grid-tile>
          <mat-grid-tile class="clsunitdevicematgridtxt">
            <div class="text-inside">
              <div class="clsunitdeviceinfo">
                <img formControlName="deviceimg" class="clsunitdeviceimg" [src]="DeviceImageURL" />
                <label style="margin-left:2em;">{{select_DeviceType}}</label>
                <!-- <span style="margin-left:8em;color:#16a2e7">Swap Device</span> -->
              </div>
            </div>
          </mat-grid-tile>

          <mat-grid-tile class="clsunitdevmatgrid">
            <label>Device Location</label>
          </mat-grid-tile>
          <mat-grid-tile class="clsunitdevicematgridtxt">
            <div class="text-inside">
              <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                <input matInput placeholder="Device Location" formControlName="devicelocation"
                  [(ngModel)]="editDeviceLocationName">
                <input type="hidden" formControlName="floorID" [(ngModel)]="new_data[0].FloorId">
                <input type="hidden" formControlName="unitID" [(ngModel)]="new_data[0].UnitId">
                <input type="hidden" formControlName="devicename" [(ngModel)]="new_data[0].DeviceName">
                <input type="hidden" formControlName="svgId" [(ngModel)]="new_data[0].SvgId">
                <input type="hidden" formControlName="dataId" [(ngModel)]="new_data[0].DataId">
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile class="clsunitdevmatgrid">
            <label>Space Category</label>
          </mat-grid-tile>
          <mat-grid-tile class="clsunitdevicematgridtxt">
            <div class="text-inside">
              <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                <mat-select formControlName="spaceroomcategory" placeholder="Select Space Category"
                  [(ngModel)]="selectCategoryId">
                  <mat-option *ngFor="let Roomcategory of RoomCategorydetails" [value]="Roomcategory.ResidentCategoryId"
                    (click)="selectRoomCategory(Roomcategory.ResidentCategoryId)" required>
                    {{Roomcategory.Description}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="deviceTempeditForm.controls.spaceroomcategory.hasError('required')">Please select
                  Space Category</mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile class="clsunitdevmatgrid">
            <label>Alert / Location <br> Description</label>
          </mat-grid-tile>
          <mat-grid-tile class="clsunitdevicematgridtxt">
            <div class="text-inside">
              <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                <input matInput placeholder="Description" formControlName="description" maxlength="30"
                  [(ngModel)]="deviceLocation" (keyup)="handleInput($event)" required>
                <mat-error *ngIf="deviceTempeditForm.controls.description.hasError('required')">Please enter Description
                </mat-error>
                <mat-error *ngIf="deviceTempeditForm.controls.description.hasError('pattern')">Please enter valid
                  Description</mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <div *ngIf="new_data[0].DeviceName == 'DC'">

            <mat-grid-tile class="clsunitdevicematgrid">
              <label>Security Controller Id</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                  <input matInput placeholder="Security Controller Id" formControlName="deviceid" maxlength="4"
                    [(ngModel)]="new_data[0].DeviceId" (keyup)="handleInput($event)" required>
                  <mat-error *ngIf="deviceTempeditForm.controls.deviceid.hasError('required')">
                    Please enter Security Controller Id
                  </mat-error>
                  <mat-error *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern')">
                    Please enter valid Security Controller Id
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>

            <mat-grid-tile class="clsunitdevicematgrid">
              <label>UT Id</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                  <input matInput placeholder="UT Id" formControlName="devicedualid" maxlength="10"
                    (input)="onKeyPress()" [(ngModel)]="new_data[0].dualutId" (keyup)="handleInput($event)">
                  <mat-error *ngIf="deviceTempeditForm.controls.devicedualid.hasError('pattern')">
                    Please enter valid UT Id
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </div>
          
          <div *ngIf="new_data[0].DeviceName == 'LF'">
            <mat-grid-tile class="clsunitdevicematgrid">
              <label>Type</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
                <div class="text-inside">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                      <mat-select formControlName="lfdevicetype" placeholder="Select LF Type" [(ngModel)]="selectedLFDeviceType" [disabled]="checkLFDeviceType">    
                        <mat-option *ngFor="let LFType of LFDeviceType" [value]="LFType.Value" (click)="selectTempLFDeviceType(LFType.Value)" required>
                          {{LFType.Type}}
                        </mat-option>
                      </mat-select>
                      <!-- <mat-error *ngIf="deviceaddForm.controls.lfdevicetype.hasError('required')">Please select Type</mat-error>-->
                    </mat-form-field>
                </div>               
            </mat-grid-tile> 
          </div>

          <mat-grid-tile class="clsunitdevmatgrid">
            <label *ngIf="new_data[0].DeviceName == 'Emitter'">TruLocator ID</label>
            <label *ngIf="new_data[0].DeviceName == 'LF' && selectedLFDeviceType == 1">LF Exciter ID</label>
            <label *ngIf="new_data[0].DeviceName == 'LF' && selectedLFDeviceType == 2">Device ID</label>
            <label *ngIf="new_data[0].DeviceName == 'DC'">LF ID</label>
            <label *ngIf="new_data[0].DeviceName == 'Pullcord'">PullCord ID</label>
            <label *ngIf="new_data[0].DeviceName == 'Rex'">Clear Repeater ID</label>
            <label *ngIf="new_data[0].DeviceName == 'H-Rex'">Clear Router ID</label>
            <label *ngIf="new_data[0].DeviceName == 'Gateway'">Clear Gateway ID</label>
            <label *ngIf="new_data[0].DeviceName == 'Monitor'">Monitor ID</label>
            <label *ngIf="new_data[0].DeviceName == 'VW'">VW ID</label>
            <!-- <label *ngIf="new_data[0].DeviceName == 'UT'">UT ID</label> -->
            <label *ngIf="new_data[0].DeviceName == 'Universal Monitor'">Universal Monitor ID</label>
            <label *ngIf="new_data[0].DeviceName == 'Intercom'">Device ID</label>
          </mat-grid-tile>
          <mat-grid-tile class="clsunitdevicematgridtxt">
            <div class="text-inside">
              <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                <input matInput *ngIf="new_data[0].DeviceName == 'H-Rex'" placeholder="Clear Router ID" maxlength="1"
                  formControlName="deviceid" [(ngModel)]='new_data[0].DeviceId' readonly (keyup)="handleInput($event)"
                  required>
                <input matInput *ngIf="new_data[0].DeviceName == 'Gateway'" placeholder="Gateway ID" maxlength="1"
                  formControlName="deviceid" [(ngModel)]='new_data[0].DeviceId' readonly (keyup)="handleInput($event)"
                  required>
                <input matInput *ngIf="new_data[0].DeviceName == 'Rex'" placeholder="Clear Repeater ID" maxlength="3"
                  formControlName="deviceid" [(ngModel)]='new_data[0].DeviceId' (keyup)="handleInput($event)" required>
                <input matInput *ngIf="new_data[0].DeviceName == 'Pullcord'" placeholder="PullCord ID" maxlength="10"
                  formControlName="deviceid" [(ngModel)]='new_data[0].DeviceId' (keyup)="handleInput($event)" required>
                <input matInput *ngIf="new_data[0].DeviceName == 'Emitter'" placeholder="TruLocator ID" maxlength="10"
                  formControlName="deviceid" [(ngModel)]='new_data[0].DeviceId' (keyup)="handleInput($event)" required>
                <input matInput *ngIf= "new_data[0].DeviceName == 'LF' && selectedLFDeviceType == 1" placeholder="LF Exciter ID" maxlength="10" 
                  formControlName="deviceid" [(ngModel)]= 'new_data[0].EmitterID' (keyup)="handleInput($event)" required>
                <input matInput *ngIf= "new_data[0].DeviceName == 'LF' && selectedLFDeviceType == 2" placeholder="Device ID" maxlength="10" 
                  formControlName="deviceid" [(ngModel)]= 'new_data[0].EmitterID' (keyup)="handleInput($event)" required>
                <!-- <input matInput *ngIf= " new_data[0].DeviceName == 'UT'" placeholder="UT Id" maxlength="10" formControlName="deviceid" [(ngModel)]= 'new_data[0].DeviceId' (keyup)="handleInput($event)" required> -->
                <input matInput *ngIf=" new_data[0].DeviceName == 'Universal Monitor'"
                  placeholder="Universal Monitor ID" formControlName="deviceid" maxlength="10"
                  [(ngModel)]="new_data[0].EmitterID" (keyup)="handleInput($event)" required>
                <!-- <input matInput *ngIf= " new_data[0].DeviceName == 'DC'" placeholder="LF Id" formControlName="devicetypeid" maxlength="4" [(ngModel)]="new_data[0].DeviceId" (keyup)="handleInput($event)" required> -->
                <input matInput *ngIf=" new_data[0].DeviceName == 'Monitor'" placeholder="Monitor ID"
                  formControlName="deviceid" maxlength="10" [(ngModel)]="new_data[0].EmitterID"
                  (keyup)="handleInput($event)" required>
                <input matInput *ngIf=" new_data[0].DeviceName == 'VW'" placeholder="VW ID" formControlName="deviceid"
                  maxlength="10" [(ngModel)]="new_data[0].EmitterID" (keyup)="handleInput($event)" required>
                <input matInput *ngIf="new_data[0].DeviceName == 'Intercom'"
                  placeholder="Enter Intercom's Unique Identifier (3-7 numbers)" maxlength="10"
                  formControlName="deviceid" [(ngModel)]='new_data[0].DeviceId' (keyup)="handleInput($event)" required>

                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') && new_data[0].DeviceName == 'H-Rex'">
                  Please enter Clear Router ID
                </mat-error>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'H-Rex'">
                  Please enter valid Clear Router ID
                </mat-error>

                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') && new_data[0].DeviceName == 'Gateway'">
                  Please enter Clear Gateway ID
                </mat-error>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'Gateway'">
                  Please enter valid Clear Gateway ID
                </mat-error>

                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') && new_data[0].DeviceName == 'Emitter'">
                  Please enter TruLocator ID
                </mat-error>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'Emitter'">
                  Please enter valid TruLocator ID
                </mat-error>

                <mat-error *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') && new_data[0].DeviceName == 'LF' && selectedLFDeviceType == 1">
                  Please enter LF Exciter ID
                </mat-error>
                <mat-error *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'LF' && selectedLFDeviceType == 1">
                  Please enter valid LF Exciter ID
                </mat-error>
                
                <mat-error *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') && new_data[0].DeviceName == 'LF' && selectedLFDeviceType == 2">
                  Please enter LF Exciter ID
                </mat-error>
                <mat-error *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'LF' && selectedLFDeviceType == 2">
                  Please enter valid LF Exciter ID
                </mat-error>

                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') && new_data[0].DeviceName == 'Pullcord'">
                  Please enter PullCord ID
                </mat-error>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'Pullcord'">
                  Please enter valid PullCord ID
                </mat-error>

                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') && new_data[0].DeviceName == 'Rex'">
                  Please enter Clear Repeater ID
                </mat-error>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'Rex'">
                  Please enter valid Clear Repeater ID
                </mat-error>

                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') &&  new_data[0].DeviceName == 'UT'">
                  Please enter UT ID
                </mat-error>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'UT'">
                  Please enter valid UT ID
                </mat-error>


                <input matInput *ngIf="new_data[0].DeviceName == 'DC'" placeholder="LF Id"
                  formControlName="devicetypeid" [(ngModel)]="new_data[0].LFId" (keyup)="handleInput($event)" required>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('required') && new_data[0].DeviceName == 'DC'">
                  Please enter LF ID
                </mat-error>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('pattern') && new_data[0].DeviceName == 'DC'">
                  Please enter valid LF ID
                </mat-error>

                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('required') && new_data[0].DeviceName == 'Intercom'">
                  Please enter Intercom ID
                </mat-error>
                <mat-error
                  *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern') && new_data[0].DeviceName == 'Intercom'">
                  Please enter valid Intercom ID
                </mat-error>

              </mat-form-field>
            </div>
          </mat-grid-tile>

          <div *ngIf="new_data[0].DeviceName == 'Emitter' || new_data[0].DeviceName == 'Rex' ||  new_data[0].DeviceName == 'LF' ||  new_data[0].DeviceName == 'Monitor' 
          ||  new_data[0].DeviceName == 'VW' || new_data[0].DeviceName == 'Universal Monitor'">
            <mat-grid-tile class="clsunitdevmatgrid">
              <label>{{DeviceTypeName}}</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                  <input matInput *ngIf="new_data[0].DeviceName == 'Emitter' || new_data[0].DeviceName == 'Monitor' 
                    || new_data[0].DeviceName == 'VW' || new_data[0].DeviceName == 'Universal Monitor'" maxlength="4"
                    placeholder="IR Id" formControlName="devicetypeid" [(ngModel)]="new_data[0].DeviceId"
                    (keyup)="handleInput($event)" required>
                  <input matInput *ngIf="new_data[0].DeviceName == 'LF'" maxlength="4" placeholder="LF Id"
                    formControlName="devicetypeid" [(ngModel)]="new_data[0].DeviceId" (keyup)="handleInput($event)"
                    required>
                  <input matInput *ngIf="new_data[0].DeviceName == 'Rex'" formControlName="devicetypeid" maxlength="17"
                    placeholder="Mac Id" [(ngModel)]="new_data[0].EmitterID" (keyup)="handleInput($event)" required>
                  <mat-error
                    *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('required') && (new_data[0].DeviceName == 'Emitter' || new_data[0].DeviceName == 'Monitor' || new_data[0].DeviceName == 'VW' || new_data[0].DeviceName == 'Universal Monitor')">
                    Please enter IR Id
                  </mat-error>
                  <mat-error
                    *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('required') && new_data[0].DeviceName == 'Rex'">
                    Please enter MAC Id
                  </mat-error>
                  <mat-error
                    *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('required') && new_data[0].DeviceName == 'LF'">
                    Please enter LF Id
                  </mat-error>
                  <mat-error
                    *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('pattern') && (new_data[0].DeviceName == 'Emitter' || new_data[0].DeviceName == 'Monitor' || new_data[0].DeviceName == 'VW' || new_data[0].DeviceName == 'Universal Monitor')">
                    Please enter valid IR Id
                  </mat-error>
                  <mat-error
                    *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('pattern') && new_data[0].DeviceName == 'LF'">
                    Please enter valid LF Id
                  </mat-error>
                  <mat-error
                    *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('pattern') && new_data[0].DeviceName == 'Rex'">
                    Please enter valid Mac Id
                  </mat-error>
                  <mat-error
                    *ngIf="deviceTempeditForm.controls.devicetypeid.hasError('pattern') && new_data[0].DeviceName != 'Emitter' 
                               && new_data[0].DeviceName != 'LF' && new_data[0].DeviceName != 'Rex' && new_data[0].DeviceName != 'Monitor' && new_data[0].DeviceName != 'VW' && new_data[0].DeviceName != 'Universal Monitor'">
                    Please enter valid Device Id
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </div>

          <div *ngIf="new_data[0].DeviceName == 'H-Rex'">
            <mat-grid-tile class="clsunitdevicematgrid">
              <label>Mac Id</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                  <input matInput placeholder="Mac Id" formControlName="hrexmacid" [(ngModel)]='HRexMacId' readonly>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </div>

          <div *ngIf="new_data[0].DeviceName == 'Pullcord'">
            <mat-grid-tile class="clsunitdevicematgrid">
              <label>Mode</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                  <mat-select [(value)]="checkPCMobilityMode" placeholder="Select Mode"
                    (selectionChange)="selectPCMode($event.value)">
                    <mat-option value="0"> Stationary </mat-option>
                    <mat-option value="1"> Mobility Mode </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-grid-tile>

            <mat-grid-tile class="clsunitdevicematgrid">
              <!-- <label>Mode</label> -->
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-slide-toggle id="chkPCRemoveDetection" (change)="togglerPCJackRemovalDetection($event)"
                  [checked]="checkPCRemovalDetection">Jack Removal Detection</mat-slide-toggle>
              </div>
            </mat-grid-tile>

            <mat-grid-tile class="clsunitdevicematgrid">
              <!-- <label>Mode</label> -->
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-slide-toggle id="chkPCButtonPress" (change)="togglerPCJackButtonPress($event)"
                  [checked]="checkPCButtonPress">Jack Button Press</mat-slide-toggle>
              </div>
            </mat-grid-tile>

            <mat-grid-tile class="clsunitdevicematgrid">
              <!-- <label>Mode</label> -->
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-slide-toggle id="chkPullCord" (change)="togglerPullCord($event)" [checked]="checkPullCord">
                  Pull-cord</mat-slide-toggle>
              </div>
            </mat-grid-tile>

          </div>

          <div *ngIf="new_data[0].DeviceName == 'DC'">
            <mat-grid-tile class="clsunitdevicematgrid">
              <label>Mac Id</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                  <input matInput placeholder="Mac Id" formControlName="dcmacid" [(ngModel)]="new_data[0].DCMacId"
                    maxlength="17" (keyup)="handleInput($event)" required>
                  <mat-error *ngIf="deviceTempeditForm.controls.dcmacid.hasError('required')">
                    Please enter Mac Id
                  </mat-error>
                  <mat-error *ngIf="deviceTempeditForm.controls.dcmacid.hasError('pattern')">
                    Please enter valid Mac Id
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>

            <mat-grid-tile class="clsunitdevicematgrid">
              <label>Security Controller Name</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsunitdevicematgridtxt">
              <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                  <input matInput placeholder="Security Controller Name" formControlName="roomname" maxlength="30"
                    [(ngModel)]="new_data[0].Description" (keyup)="handleInput($event)" required>
                  <mat-error *ngIf="deviceTempeditForm.controls.roomname.hasError('required')">
                    Please enter Security Controller Name
                  </mat-error>
                  <mat-error *ngIf="deviceTempeditForm.controls.roomname.hasError('pattern')">
                    Please enter valid Security Controller Name
                  </mat-error>
                  <mat-error *ngIf="deviceTempeditForm.controls.roomname.hasError('maxlength')">
                    Max length 30 characters only
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>

          </div>

        </mat-grid-list>

      </div>

      <!-- <div style="float:left; margin-top: 10px;" *ngIf="new_data[0].DeviceName == 'Pullcord'">            
        <mat-slide-toggle id="chkPCRemoveDetection" (change)="togglerPCJackRemovalDetection($event)" [checked]="checkPCRemovalDetection">Jack Removal Detection</mat-slide-toggle>
        <mat-slide-toggle id="chkPCButtonPress" style=" margin-left:5px;" (change)="togglerPCJackButtonPress($event)" [checked]="checkPCButtonPress">Jack Button Press</mat-slide-toggle>
        <mat-slide-toggle id="chkPullCord" style=" margin-left:5px;" (change)="togglerPullCord($event)" [checked]="checkPullCord">Pull-cord</mat-slide-toggle>        
      </div>     -->

      <div style="float:left;margin-top:10px;" *ngIf="new_data[0].DeviceName == 'DC'">
        <mat-slide-toggle id="chkDCTag" (change)="togglerDCTag($event)" [checked]="checkDCTag">Tag In Field
        </mat-slide-toggle>
        <mat-slide-toggle id="chkDCLotiering" style=" margin-left:5px;" (change)="togglerDCLotiering($event)"
          [checked]="checkDCLotiering" [disabled]="disableDCLotiering">Loitering</mat-slide-toggle>
        <mat-slide-toggle id="chkDCUnauthorizedEgress" style=" margin-left:5px;"
          (change)="togglerDCUnauthorizedEgress($event)" [checked]="checkDCUnauthorizedEgress"
          [disabled]="disableDCUnauthorizedEgress">Wandering</mat-slide-toggle>
      </div>

      <perfect-scrollbar style="max-width: 1600px; max-height: 720px;">
        <div class="content clsUTmaindiv" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill *ngIf="new_data[0].DeviceName == 'UT'">
          
          <mat-card>
            <div fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column">
              <!---------------------------Device------------------------------------>
              <div fxFlex="35">
                <mat-grid-list [cols]="firstcolumns" [rowHeight]="devicerowHeight">
                  <mat-grid-tile class="clsunitdevmatgrid">
                    <label><b>Device</b></label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunitdevicematgridtxt">
                    <div class="text-inside">
                      <div class="clsunitdeviceinfo">
                        <img formControlName="deviceimg" class="clsunitdeviceimg" [src]="DeviceImageURL" />
                        <label style="margin-left:2em;">{{select_DeviceType}}</label>                        
                      </div>
                    </div>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clsunitdevmatgrid">
                    <label>Device Location</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunitdevicematgridtxt">
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                        <input matInput placeholder="Device Location" formControlName="devicelocation" [(ngModel)]="editDeviceLocationName">
                        <input type="hidden" formControlName="campusID" [(ngModel)]="new_data[0].CampusId">
                        <input type="hidden" formControlName="buildID" [(ngModel)]="new_data[0].BuildId">
                        <input type="hidden" formControlName="floorID" [(ngModel)]="new_data[0].FloorId">
                        <input type="hidden" formControlName="unitID" [(ngModel)]="new_data[0].UnitId">
                        <input type="hidden" formControlName="devicename" [(ngModel)]="new_data[0].DeviceName">
                        <input type="hidden" formControlName="svgId" [(ngModel)]="new_data[0].SvgId">
                        <input type="hidden" formControlName="dataId" [(ngModel)]="new_data[0].DataId">
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
                  
                  <mat-grid-tile class="clsunitdevmatgrid">
                    <label>Space Category</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunitdevicematgridtxt">
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                        <mat-select formControlName="spaceroomcategory" placeholder="Select Space Category" [(ngModel)]="selectresCategoryId">
                          <mat-option *ngFor="let Roomcategory of RoomCategorydetails" [value]="Roomcategory.ResidentCategoryId" (click)="selectRoomCategory(Roomcategory.ResidentCategoryId)" required>
                            {{Roomcategory.Description}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="deviceTempeditForm.controls.spaceroomcategory.hasError('required')">Please select Space Category</mat-error>
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clsunitdevmatgrid">
                    <label>Alert / Location <br> Description</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunitdevicematgridtxt">
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                        <input matInput placeholder="Description" formControlName="description" maxlength="30"
                          [(ngModel)]="deviceLocation" (keyup)="handleInput($event)" required>
                        <mat-error *ngIf="deviceTempeditForm.controls.description.hasError('required')">Please enter Description</mat-error>
                        <mat-error *ngIf="deviceTempeditForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clsunitdevmatgrid">
                    <label *ngIf="new_data[0].DeviceName == 'UT'">UT ID</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsunitdevicematgridtxt">
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitdeviceaddtxt">
                        <input matInput *ngIf=" new_data[0].DeviceName == 'UT'" placeholder="UT ID" maxlength="10"
                          formControlName="deviceid" [(ngModel)]='new_data[0].DeviceId' (keyup)="handleInput($event)" required>
                        <mat-error *ngIf="deviceTempeditForm.controls.deviceid.hasError('required')">Please enter UT ID</mat-error>
                        <mat-error *ngIf="deviceTempeditForm.controls.deviceid.hasError('pattern')">Please enter valid UT ID</mat-error>
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
                  
                </mat-grid-list>
              </div>
              
              <!---------------------------Enable Input------------------------------------>
              <div fxFlex="40" fxFlexOffset="3">
                <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                  <mat-grid-tile>
                    <label>Enable Input</label>
                  </mat-grid-tile>                                  
                </mat-grid-list>
                
                <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                  <mat-grid-tile class="clsunitdevDCTEnableInput">
                    <label>Input 1</label>
                    <mat-slide-toggle id="chkDCT_Tag1" (change)="togglerDCT_Tag(1,$event)" [checked]="checkDCT_Tag1" style="margin-left:1em;"></mat-slide-toggle>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clsunitDCTdeviceAlert">
                    <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                        <mat-select [(ngModel)]="edit_selectedAlertState1" formControlName="enableinput1"
                          placeholder="Alert State" (selectionChange)="selectDCTAlertState(1,$event.value)" [disabled]="!checkDCT_Tag1">
                          <mat-option value="1"> Low </mat-option>
                          <mat-option value="2"> High </mat-option>
                          <mat-option value="3"> High Latching</mat-option>
                          <mat-option value="4"> Low Latching</mat-option>
                        </mat-select>
    
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clseditunitDCTdeviceAlertMessage">
                    <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                        <input matInput placeholder="Alert Message" [(ngModel)]="alertMessage_input1" maxlength="30"
                          formControlName="alertmessage1DCT" [readonly]="!checkDCT_Tag1">
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
                
                <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                  <mat-grid-tile class="clsunitdevDCTEnableInput">
                    <label>Input 2</label>
                    <mat-slide-toggle id="chkDCT_Tag2" (change)="togglerDCT_Tag(2,$event)" [checked]="checkDCT_Tag2"
                      style="margin-left:1em;"></mat-slide-toggle>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clsunitDCTdeviceAlert">
                    <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                        <mat-select [(ngModel)]="edit_selectedAlertState2" formControlName="enableinput2"
                          placeholder="Alert State" (selectionChange)="selectDCTAlertState(2,$event.value)"
                          [disabled]="!checkDCT_Tag2">
                          <mat-option value="1"> Low </mat-option>
                          <mat-option value="2"> High </mat-option>
                          <mat-option value="3"> High Latching</mat-option>
                          <mat-option value="4"> Low Latching</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clseditunitDCTdeviceAlertMessage">
                    <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                        <input matInput placeholder="Alert Message" [(ngModel)]="alertMessage_input2" maxlength="30"
                          formControlName="alertmessage2DCT" [readonly]="!checkDCT_Tag2">
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
    
                <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                  <mat-grid-tile class="clsunitdevDCTEnableInput">
                    <label>Input 3</label>
                    <mat-slide-toggle id="chkDCT_Tag3" (change)="togglerDCT_Tag(3,$event)" [checked]="checkDCT_Tag3"
                      style="margin-left:1em;"></mat-slide-toggle>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clsunitDCTdeviceAlert">
                    <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                        <mat-select [(ngModel)]="edit_selectedAlertState3" formControlName="enableinput3"
                          placeholder="Alert State" (selectionChange)="selectDCTAlertState(3,$event.value,2)"
                          [disabled]="!checkDCT_Tag3">
                          <mat-option value="1"> Low </mat-option>
                          <mat-option value="2"> High </mat-option>
                          <mat-option value="3"> High Latching</mat-option>
                          <mat-option value="4"> Low Latching</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
    
                  <mat-grid-tile class="clseditunitDCTdeviceAlertMessage">
                    <div class="text-inside" style="width:100%;">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                        <input matInput placeholder="Alert Message" [(ngModel)]="alertMessage_input3" maxlength="30"
                          formControlName="alertmessage3DCT" [readonly]="!checkDCT_Tag3">
                      </mat-form-field>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>                            
              </div>
            
              <!---------------------------Input Active Alert Time------------------------------------>
              <div fxFlex="40" fxFlexOffset="3">
                <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                  <mat-grid-tile>
                    <label>Input Active Alert Time</label>
                  </mat-grid-tile>                                  
                </mat-grid-list>                               
                <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile colspan="3">
              <div style="margin-left:0em;width:180px;">
                <mat-form-field appearance="outline" floatLabel="auto" style="width:65%;">
                  <mat-select #selWRStart placeholder="Start" formControlName="wr_start1" [disabled]="!_egressenablehourmin1" [(ngModel)]="data_startTime1">
                    <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value">
                      {{data.Time}}
                    </mat-option>
                  </mat-select>
                  <mat-error>Please select Start Time</mat-error>
                </mat-form-field>
                <label style="margin-left:1.5em">to</label>
              </div>

                <div>
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:50%;">
                    <mat-select #selWREnd placeholder="End" formControlName="wr_end1" [disabled]="!_egressenablehourmin1" [(ngModel)]="data_endTime1">
                      <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value">
                        {{data.Time}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select End Time</mat-error>
                  </mat-form-field>

                  <mat-checkbox class="clslivelocation clsenable" id="chkresidentday1" [disabled]="!checkDCT_Tag1"
                    (change)="toggleinfoVisibility($event,1)" style="font-size: 14px;margin-left: 1em;" [checked]="checkallinfo1"></mat-checkbox>
                  <span style="margin:1em 0.5em 0px 0.5em;" class="Hide-Personal-Info">All Day</span>
                </div>

              </mat-grid-tile>     
              </mat-grid-list>  
              
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile colspan="3">
                  <div style="margin-left:0em;width:180px;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:65%;">
                      <mat-select #selWRStart placeholder="Start" formControlName="wr_start2" [disabled]="!_egressenablehourmin2" [(ngModel)]="data_startTime2">
                        <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value">
                          {{data.Time}}
                        </mat-option>
                      </mat-select>
                      <mat-error>Please select Start Time</mat-error>
                    </mat-form-field>
                    <label style="margin-left:1.5em">to</label>
                  </div>
  
                  <div>
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:50%;">
                      <mat-select #selWREnd placeholder="End" formControlName="wr_end2" [disabled]="!_egressenablehourmin2" [(ngModel)]="data_endTime2">
                        <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value">
                          {{data.Time}}
                        </mat-option>
                      </mat-select>
                      <mat-error>Please select End Time</mat-error>
                    </mat-form-field>
  
                    <mat-checkbox class="clslivelocation clsenable" id="chkresidentday2" [disabled]="!checkDCT_Tag2"
                      (change)="toggleinfoVisibility($event,2)" style="font-size: 14px;margin-left: 1em;" [checked]="checkallinfo2"></mat-checkbox>
                    <span style="margin:1em 0.5em 0px 0.5em;" class="Hide-Personal-Info">All Day</span>
                  </div>
  
                </mat-grid-tile>     
                </mat-grid-list> 
                
                <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                  <mat-grid-tile colspan="3">
                    <div style="margin-left:0em;width:180px;">
                      <mat-form-field appearance="outline" floatLabel="auto" style="width:65%;">
                        <mat-select #selWRStart placeholder="Start" formControlName="wr_start3" [disabled]="!_egressenablehourmin3" [(ngModel)]="data_startTime3">
                          <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value">
                            {{data.Time}}
                          </mat-option>
                        </mat-select>
                        <mat-error>Please select Start Time</mat-error>
                      </mat-form-field>
                      <label style="margin-left:1.5em">to</label>
                    </div>
    
                    <div>
                      <mat-form-field appearance="outline" floatLabel="auto" style="width:50%;">
                        <mat-select #selWREnd placeholder="End" formControlName="wr_end3" [disabled]="!_egressenablehourmin3" [(ngModel)]="data_endTime3">
                          <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value">
                            {{data.Time}}
                          </mat-option>
                        </mat-select>
                        <mat-error>Please select End Time</mat-error>
                      </mat-form-field>
    
                      <mat-checkbox class="clslivelocation clsenable" id="chkresidentday3" [disabled]="!checkDCT_Tag3"
                        (change)="toggleinfoVisibility($event,3)" style="font-size: 14px;margin-left: 1em;" [checked]="checkallinfo3"></mat-checkbox>
                      <span style="margin:1em 0.5em 0px 0.5em;" class="Hide-Personal-Info">All Day</span>
                    </div>
    
                  </mat-grid-tile>     
                  </mat-grid-list>                  
            </div>         
          </div>
          
            <!---------------------------Ambient Temperature------------------------------------>
            <div fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column">
              <div fxFlex="35">
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile class="clsAmbientTemp">
                  <label>Ambient Temperature (&deg;{{ambientTemperatureText}})</label>
                </mat-grid-tile>
              </mat-grid-list>
  
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile class="clsunitdevDCTEnableInput">
                  <label>Over<br>Temp</label>
                  <mat-slide-toggle id="chkDCT_Amb1" (change)="togglerDCT_Ambient(1,$event)" [checked]="checkDCT_Ambient1"
                    style="margin-left:2em;"></mat-slide-toggle>
                </mat-grid-tile>
  
                <mat-grid-tile class="clsunitDCTdeviceAlert">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTAmbinput">
                      <input matInput placeholder="Temperature" [(ngModel)]="ambientOverDesc" maxlength="6"
                        formControlName="temperature1DCT" [readonly]="!checkDCT_Ambient1" (keyup)="handleInput($event)">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
  
                <mat-grid-tile class="clseditunitDCTdeviceAlertMessage">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                      <input matInput placeholder="Alert Message" [(ngModel)]="ambientOverMessage" maxlength="30"
                        formControlName="alertmessage4DCT" [readonly]="!checkDCT_Ambient1">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
  
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile class="clsunitdevDCTEnableInput">
                  <label>Under<br>Temp</label>
                  <mat-slide-toggle id="chkDCT_Amb2" (change)="togglerDCT_Ambient(2,$event)" [checked]="checkDCT_Ambient2"
                    style="margin-left:2em;"></mat-slide-toggle>
                </mat-grid-tile>
  
                <mat-grid-tile class="clsunitDCTdeviceAlert">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTAmbinput">
                      <input matInput placeholder="Temperature" [(ngModel)]="ambientUnderDesc" maxlength="6"
                        formControlName="temperature2DCT" [readonly]="!checkDCT_Ambient2" (keyup)="handleInput($event)">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
  
                <mat-grid-tile class="clseditunitDCTdeviceAlertMessage">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                      <input matInput placeholder="Alert Message" [(ngModel)]="ambientUnderMessage" maxlength="30"
                        formControlName="alertmessage5DCT" [readonly]="!checkDCT_Ambient2">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
  
              </mat-grid-list>              
            </div>                     
            
            <!---------------------------Probe------------------------------------>
            <div fxFlex="40" fxFlexOffset="3">
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile class="clsAmbientTemp">
                  <label>Probe (&deg;{{ambientTemperatureText}})</label>
                </mat-grid-tile>
              </mat-grid-list>
  
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight" *ngIf="new_data[0].DeviceName == 'UT'">
                <mat-grid-tile class="clsunitdevDCTEnableInput">
                  <label>Over<br>Temp</label>
                  <mat-slide-toggle id="chkDCT_Probe1" (change)="togglerDCT_Probe(1,$event)" [checked]="checkDCT_Probe1"
                    style="margin-left:2em;"></mat-slide-toggle>
                </mat-grid-tile>
  
                <mat-grid-tile class="clsunitDCTdeviceAlert">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTAmbinput">
                      <input matInput placeholder="Temperature" [(ngModel)]="probeOverDesc" maxlength="6"
                        formControlName="probetemperature1DCT" [readonly]="!checkDCT_Probe1"
                        (keyup)="handleInput($event)">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
  
                <mat-grid-tile class="clseditunitDCTdeviceAlertMessage">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                      <input matInput placeholder="Alert Message" [(ngModel)]="probeOverMessage" maxlength="30"
                        formControlName="alertmessage6DCT" [readonly]="!checkDCT_Probe1">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
  
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight" *ngIf=" new_data[0].DeviceName == 'UT'">
                <mat-grid-tile class="clsunitdevDCTEnableInput">
                  <label>Under<br>Temp</label>
                  <mat-slide-toggle id="chkDCT_Probe2" (change)="togglerDCT_Probe(2,$event)" [checked]="checkDCT_Probe2"
                    style="margin-left:2em;"></mat-slide-toggle>
                </mat-grid-tile>
  
                <mat-grid-tile class="clsunitDCTdeviceAlert">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTAmbinput">
                      <input matInput placeholder="Temperature" [(ngModel)]="probeUnderDesc" maxlength="6"
                        formControlName="probetemperature2DCT" [readonly]="!checkDCT_Probe2"
                        (keyup)="handleInput($event)">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
  
                <mat-grid-tile class="clseditunitDCTdeviceAlertMessage">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                      <input matInput placeholder="Alert Message" [(ngModel)]="probeUnderMessage" maxlength="30"
                        formControlName="alertmessage7DCT" [readonly]="!checkDCT_Probe2">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>  
            </div>
            
            <!---------------------------Ambient Humidity------------------------------------>
            <div fxFlex="40" fxFlexOffset="3">
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile>
                  <label>Ambient Humidity</label>
                </mat-grid-tile>
              </mat-grid-list>
  
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile class="clsunitdevDCTEnableInput">
                  <label>Over<br>Humidity</label>
                  <mat-slide-toggle id="chkDCT_Humidity1" (change)="togglerDCT_Humidity(1,$event)"
                    [checked]="checkDCT_Humidity1" style="margin-left:1em;"></mat-slide-toggle>
                </mat-grid-tile>
  
                <mat-grid-tile class="clsunitDCTdeviceAlert">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTAmbinput">
                      <input matInput placeholder="Humidity" [(ngModel)]="humidityOverDesc" maxlength="6"
                        formControlName="humiditytemperature1DCT" [readonly]="!checkDCT_Humidity1"
                        (keyup)="handleInput($event)">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
  
                <mat-grid-tile class="clseditunitDCTdeviceAlertMessage">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                      <input matInput placeholder="Alert Message" [(ngModel)]="humidityOverMessage" maxlength="30"
                        formControlName="alertmessage8DCT" [readonly]="!checkDCT_Humidity1">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
  
              <mat-grid-list [cols]="DCTColums" [rowHeight]="devicerowHeight">
                <mat-grid-tile class="clsunitdevDCTEnableInput">
                  <label>Under<br>Humidity</label>
                  <mat-slide-toggle id="chkDCT_Humidity2" (change)="togglerDCT_Humidity(2,$event)"
                    [checked]="checkDCT_Humidity2" style="margin-left:1em;"></mat-slide-toggle>
                </mat-grid-tile>
  
                <mat-grid-tile class="clsunitDCTdeviceAlert">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTAmbinput">
                      <input matInput placeholder="Humidity" [(ngModel)]="humidityUnderDesc" maxlength="6"
                        formControlName="humiditytemperature2DCT" [readonly]="!checkDCT_Humidity2"
                        (keyup)="handleInput($event)">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
  
                <mat-grid-tile class="clstemp_editDCTAlertMessage">
                  <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" class="clsunitmatformDCTinput">
                      <input matInput placeholder="Alert Message" [(ngModel)]="humidityUnderMessage" maxlength="30"
                        formControlName="alertmessage9DCT" [readonly]="!checkDCT_Humidity2">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
              
            </div>
          
        </mat-card>
          
      </div>     
    </perfect-scrollbar> 

      <mat-dialog-actions class="clsuniteditbuttoncontrol">
        <button type="button" class="clsunitdevicedltbutton" mat-dialog-close mat-raised-button
          (click)="onCloseDeviceDialog()">Cancel</button>&nbsp;
        <button id="btneditclick" class="clsunitdeviceeditbutton" mat-raised-button>Save</button>
      </mat-dialog-actions>

      <div id="spn_RedrawDeviceXY" style="display:none;"></div>

    </form>
  </div>

  <!-- </perfect-scrollbar>   -->

</div>