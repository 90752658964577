<app-technologyoverlay-header></app-technologyoverlay-header>
<mat-card class="technologyoverlay-mat-card">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-technologyoverlay-leftbar></app-technologyoverlay-leftbar>
    </div>

    <div fxLayout="column" fxFlex="100">
      <div style="margin:2em;">
        <img class="clsmapeditimage" src="assets/images/Edit_Icon.png" (click)="openBuildingEditdialog()" /> <label
          style="margin-left:1em;">{{Building_Name}} - {{Campus_Name}}</label>
        <span id="buildingmouseovertext" style="margin-left:3em;"></span>
        <button class="btnuserguide" mat-raised-button>User Guide</button>
      </div>

      <div style='width:100%;height: 90%;display: flex;'>
        <div id="map" class="map"></div>
        <div id="divCreateSpaceMenu" class="clsrightside_icon_popup"
          style="display:none; position: absolute; z-index: 9999999; background-color: #EFECEC; top: 196px; right: 8.4%; border: 1px solid #999999;">
          <div id="container_drawRect" style="border:2px solid #d2d4d8;height: 100px;display:grid;"
            (click)="draw_rectangle()" (mouseenter)="mouseoverrightsidemenu(this.Tech_Rectangle_Building)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Rectangle_Building)">
            <img class="clsmapimage" src="assets/images/RectangleSpace.png" />
            <p style="text-align: center;">Rectangle</p>
          </div>
          <div id="container_drawEllipse" style="border:2px solid #d2d4d8;height: 100px;display:grid;"
            (click)="draw_ellipse()" (mouseenter)="mouseoverrightsidemenu(this.Ellipse_Building)"
            (mouseleave)="mouseleaverightsidemenu(this.Ellipse_Building)">
            <img class="clsmapimage" src="assets/images/EllipseBuilding.png" />
            <p style="text-align: center;">Ellipse</p>
          </div>
          <div id="container_drawFreehand" style="border:2px solid #d2d4d8;height: 100px;display:grid;"
            (click)="draw_freehand()" (mouseenter)="mouseoverrightsidemenu(this.FreeHand_Building)"
            (mouseleave)="mouseleaverightsidemenu(this.FreeHand_Building)">
            <img class="clsmapimage" src="assets/images/FreehandBuilding.png" />
            <p style="text-align: center;">Freehand</p>
          </div>
        </div>
        <div id="divEditBuildingeMenu" class="clsrightside_icon_popup"
          style="display:none; position: absolute; z-index: 9999999; background-color: #EFECEC; top: 300px; right: 8.4%; border: 1px solid #999999;">
          <div id="container_EditPoint" style="border:2px solid #d2d4d8;height: 100px;display:grid;"
            (click)="Edit_Points()" (mouseenter)="mouseoverrightsidemenu(this.Edit_Point_Building)"
            (mouseleave)="mouseleaverightsidemenu(this.Edit_Point_Building)">
            <img class="clsmapimage" src="assets/images/editpoints.png" />
            <p style="text-align: center;">EditPoints</p>
          </div>
          <div id="container_Rotate" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor:pointer;"
            (click)="Rotate_Points(0)" (mouseenter)="mouseoverrightsidemenu(this.Rotate_Building)"
            (mouseleave)="mouseleaverightsidemenu(this.Rotate_Building)" class="disablebtn">
            <img class="clsmapimage" src="assets/images/RotateBuilding.png" />
            <p style="text-align: center;">Rotate</p>
          </div>
          <!--<div id="container_Refelect" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor:pointer;" (click)="Rotate_Points(1)" (mouseenter)="mouseoverrightsidemenu(this.Reflect_Building)" (mouseleave)="mouseleaverightsidemenu(this.Reflect_Building)">
        <img class="clsmapimage" src="assets/images/reflect.png" /> <p style="text-align: center;">Reflect</p>                       
      </div>-->
        </div>
        <div class="clsrightside_icon">
          <div id="container_SelectMove" style="border:2px solid #d2d4d8;height: 100px;display:grid;"
            (click)="select_move()" (mouseenter)="mouseoverrightsidemenu(this.Tech_Building_Move)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Building_Move)">
            <img class="clsmapimage" src="assets/images/selectmove.png" />
            <p style="text-align: center;">Move</p>
          </div>
          <div id="container_CreateSpace" style="border:2px solid #d2d4d8;height: 100px;display:grid;"
            (click)="create_space()" (mouseenter)="mouseoverrightsidemenu(this.Tech_Create_Building)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Create_Building)">
            <img class="clsmapimage" src="assets/images/createspace.png" />
            <p style="text-align: center;">Create Building</p>
          </div>
          <div id="container_EditBuilding" style="border:2px solid #d2d4d8;height: 100px;display:grid;"
            (click)="edit_building()" (mouseenter)="mouseoverrightsidemenu(this.Tech_Edit_Building)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Edit_Building)">
            <img class="clsmapimage" src="assets/images/editbuilding.png" />
            <p style="text-align: center;">Edit Building</p>
          </div>
          <div id="container_DeleteBuilding" style="border:2px solid #d2d4d8;height: 100px;display:grid;"
            (click)="delete_building()" (mouseenter)="mouseoverrightsidemenu(this.Tech_Delete_Building)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Delete_Building)">
            <img class="clsmapimage" src="assets/images/deletebuilding.png" />
            <p style="text-align: center;">Delete</p>
          </div>
        </div>

      </div>
      <!--New added div-->
      <div id="info"></div>
      <div id="popup" class="ol-popup">
        <a href="#" id="popup-closer" class="ol-popup-closer"></a>
        <div id="popup-content"></div>
      </div>
      <div id="marker" style="display: none;">
        <div id="marker1" class="marker"></div>
        <div id="marker2" class="marker"></div>
        <div id="marker3" class="marker"></div>
        <div id="marker4" class="marker"></div>
        <div id="mapselected"></div>
        <button id="btn_MapDetailsChanged">Map Details Changed</button>
        <button id="btn_gotoCampus"></button>
      </div>
      <!--end-->


      <div id="divDeviceheader" style='height: 50px;'>
        <mat-card class="tech-mat-card">
          <div id="divAddDevice" fxLayout="row" fxLayout.sm="column" fxLayoutGap="0px"
            fxLayoutAlign="space-between left" style="float:right;">
            <div class="clsaligndiv" fxShow.xs="false" fxShow.sm="false">
              <button style="width: 100px;margin:0.5em;" class="clsbtnbuildcancel" mat-raised-button
                (click)="btnclickmapinfohide()">Exit</button>
              <button id="btnbuildsaveclick" style="width: 100px;" class="clsbtnfloorsave" mat-raised-button
                (click)="onSaveCampusRoom()" *ngIf="bAddEdit==true">Save</button>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-card>
      </div>

    </div>

  </div>
</mat-card>