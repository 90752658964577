import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { HomeService } from "../../home.service";
import { AlertSharedService } from "../../../../_services/index";
import {
  SharedAlertData,
  SharedAlertLoadData,
  cancelAlert,
} from "../../../../api";
import { enumAlertStatus  } from "../../../../app-settings";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";

import { SessionCheck, generateAlertMessage } from "../../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../../../../api.service";

@Component({
  selector: "app-clearalert",
  templateUrl: "./clearalert.component.html",
  providers: [HomeService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./clearalert.component.scss"],
})
export class ClearalertComponent implements OnInit, OnDestroy {
  SharedAlertLoadDetails: SharedAlertLoadData;
  staffid;
  alertid;
  canceledResponse = [];
  alertreturnVal = 0;
  SharedAlertDetails: SharedAlertData[];
  alertinfo;
  cancelAlertType;
  cancelAlertSubType;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private homeService: HomeService,
    private alertSharedService: AlertSharedService,
    @Inject(RollbarService) private rollbar: Rollbar,    
    public thisDialogRef: MatDialogRef<ClearalertComponent>,
    @Inject(DOCUMENT) private document: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private apiService: ApiService
  ) {
    this.staffid = data.StaffId;
    this.alertid = data.AlertId;
    this.cancelAlertType = data.AlertType;
    this.cancelAlertSubType = data.AlertSubType;
  }

  ngOnInit() {
    this.alertSharedService
      .currentsharedAlertload()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((SharedAlertLoadDetails: SharedAlertLoadData) => {
        this.SharedAlertLoadDetails = SharedAlertLoadDetails;
      });
  }

  // close alert Notification Dialog
  public onCloseAlert = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  onClickedYes(): void {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      this.alertreturnVal = 1;
      // let cancelAlertflag;
      // if (this.cancelAlertType == enumAlertType.Infrastructure) {
      //   //if(this.cancelAlertSubType == null || this.cancelAlertSubType == enumAlertSubType.Fire_Alarm)
      //   cancelAlertflag = 1;
      // } else {
      //   cancelAlertflag = 0;
      // }
      const authkey = localStorage.getItem("Authkey") ?? "";
      const cancelItem: cancelAlert = {
        authKey: authkey,
        staffId: parseInt(this.staffid),
        alertId: parseInt(this.alertid),
        status: enumAlertStatus.Canceled.toString(),
      };
      this.document.getElementById("btn_Cancel").disabled = true;
      this.document.getElementById("btn_YesCancel").disabled = true;

      this.apiService
        .cancelAlert(cancelItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((canceledResponse: any[]) => {
          if (canceledResponse["apiReturn"] == "Success")
            generateAlertMessage(this.dialog, "Alert cancelled successfully.");
          this.thisDialogRef.close("Success");
        });

      const _SharedAlertLoadDetails = {
        SharedreturnVal: this.alertreturnVal,
        SharedNotifiedVal: 9,
        AlertId: this.alertid,
      };

      this.alertSharedService.changecanceledAlert(_SharedAlertLoadDetails);
    } catch (err) {
      throw new Error(
        "clearalert.component.ts - onClickedYes() - " + err.message
      );
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
