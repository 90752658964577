<perfect-scrollbar style="max-width: 1200px; max-height: 600px;" >
  <div class="clseditunitTemp">
      <div class="editunitTemp-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editunitTempHeader" style="margin: 0 0 10px 11px;">Space Template Information
        <span><img class="clseditunitTempcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="uniteditTemplateForm" autocomplete="off" novalidate (ngSubmit)="updateTemplateInfo(uniteditTemplateForm.value)">
      <div class="content" fxLayout="row" fxLayout.md="column" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div fxFlex="55" fxFlex.md="25">   
                
                
                <div style="margin-top:1em;">                  
                  <label style="margin-left:0em;">Spaces created with this Template : {{SpaceTempCount}} </label>
                </div>
                
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">                                       
                  
                  <mat-grid-tile class="clseditunitTempmatgrid">
                    <label>Template<br>Name</label>
                </mat-grid-tile>
                <mat-grid-tile class="clseditunitTempmatgridtxt">
                        <div class="clsunitTemptext-inside"> 
                          <mat-form-field appearance="outline" floatLabel="auto" class="clseditUnitTempaddtxt">
                            <input matInput placeholder="Template Name" formControlName="tempname" maxlength="65" [(ngModel)]="selectTemplateName" required/>                            
                            <input type="hidden" formControlName="tempID" [(ngModel)]="data.TemplateId">     
                            <mat-error *ngIf="uniteditTemplateForm.controls.tempname.hasError('required')">Please enter Template Name</mat-error>
                            <mat-error *ngIf="uniteditTemplateForm.controls.tempname.hasError('pattern')">Please enter valid Template Name</mat-error>                          
                         </mat-form-field>
                      </div>
                </mat-grid-tile>                                    

                   </mat-grid-list>   
                   
                   <mat-grid-list [cols]="columns" class="clsimagematgridlist">
                    <div class="clsunitimagedivlist">
                      <div [id]="selectTemplateId" class="clseditunitTempDragimg map" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" draggable="false">
                      </div>
                    </div>                              
                  </mat-grid-list>
              </div>
         
              <div fxFlex="55" fxFlex.md="45" style="margin-top:1em;">
                <label>Devices in this Space:</label>
                  <mat-grid-list [cols]="columns" class="clseditUnitTempmatgridlist">
                    
                        <perfect-scrollbar style="max-width: 1200px; max-height: 400px;" >                          
                          <div style="margin:1em;">
                            <label style="font-size: medium;">Devices</label>
                          </div>                             
                          
                          <div class="clsunitTemptext-inside" style="margin:1em;" *ngFor="let Device of DeviceInfo;">   
                            <div style="width: 100%;display: inline-flex;">                                                                       
                                <img *ngIf="Device.UnitName.length > 0 && Device.DeviceTypeId != 4" src="assets/images/Edit_Icon.png" class="clsunitTempeditimg" (click)="edit_Spacedetails(Device)" [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}"/>
                                <img *ngIf="Device.UnitName.length > 0" src="assets/images/deletebuilding.png" style="margin:0em 1em;" class="clsunitTempeditimg" (click)="deleteSpaceDevicedetails(Device)"  [ngStyle]="{'display': bDelete == false ? 'none' : 'block'}"/>                            
                                <div class="clseditunittempdivDevice" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}">   
                                  <img *ngIf="Device.DeviceId > 0" src="assets/images/Device_Green.png" class="clsedit_deviceEditimg" (click)="editDevicedetails()"  [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}"/>
                                  <img *ngIf="Device.DeviceId == 0 || Device.DeviceId == null" src="assets/images/Device_Yellow.png" class="clsedit_deviceEditimg" (click)="editDevicedetails()"  [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}"/>                             
                                  <div style="margin-left:1em;" *ngIf="Device.UnitName.length > 0 && Device.Description.length == 0">{{Device.UnitName}}</div>  
                                  <div style="margin-left:1em;" *ngIf="Device.UnitName.length > 0 && Device.Description.length < 15">{{Device.Description}}</div>  
                                  <div style="margin-left:1em;" *ngIf="Device.UnitName.length > 0 && Device.Description.length >= 15" matTooltipClass="devicetooltip" matTooltip={{Device.Description}} #tooltip="matTooltip" matTooltipPosition="above">
                                    {{Device.Description.slice(0,10) + ".."}}</div> 
                                  <div style="margin-left:1em;" *ngIf="Device.UnitName.length == 0 && Device.Description.length < 15">{{Device.Description}}</div>  
                                  <div style="margin-left:1em;" *ngIf="Device.UnitName.length == 0 && Device.Description.length >= 15" matTooltipClass="devicetooltip" matTooltip={{Device.Description}} #tooltip="matTooltip" matTooltipPosition="above">
                                  {{Device.Description.slice(0,10) + ".."}}</div>  
                                  
                                </div>  
                                <div *ngIf="Device.UnitName.length > 0" class="clseditunittempdivDevice">               
                                  <div style="margin-left:1em;">Device: <b>{{Device.NewDeviceName}}</b></div>  
                                </div>                                                
                            </div>
                            <div *ngIf="Device.UnitName.length > 0" style="width: 100%;display: inline-flex;">  

                              <div *ngIf="Device.DeviceTypeId == 4" class="clseditunittempdivDeviceid_Room" style="margin-top:0.5em;">                                
                                <div style="margin-left:1em;">Device ID: <b>{{Device.EmitterID}}</b></div>  
                              </div>
                              
                              <div *ngIf="Device.DeviceTypeId != 4" [ngClass]="bAddEdit == true ? 'clseditunittempdivDeviceid' : 'clseditunittempdivDeviceidhide'" style="margin-top:0.5em;">                                
                                <div style="margin-left:1em;">Device ID: <b>{{Device.EmitterID}}</b></div>  
                              </div>  
                              <div class="clseditunittempdivDevice" style="margin-left:0.5em;margin-top:0.5em;">               
                                <div [ngClass]="bAddEdit == true ? 'clseditDeviceId' : 'clseditDeviceIdhide'">IR ID: <b>{{Device.DeviceId}}</b></div>  
                              </div>                    
                            </div>
                          </div> 
                    
                    </perfect-scrollbar>
                    </mat-grid-list>
              </div>
          
        
 </div>
 
 <mat-dialog-actions style="float:left;">              
  <button type="button" class="clseditUnitTempdltbutton" mat-raised-button (click)="clkdltSpaceconfirmdialog(Space_Info)" *ngIf="bDelete==true">Delete</button> 
 </mat-dialog-actions>
  
 <mat-dialog-actions class="clseditUnitTempbuttoncontrol">      
  <button type="button" class="clseditUnitTempcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnspacetempsaveclick" class="clseditUnitTempsavebutton" mat-raised-button  *ngIf="bAddEdit==true">Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>