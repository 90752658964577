<div class="clscontentsidebar" (click)="sideMenuNavcontent()">
    <mat-list class="clsleftcontent">
        <mat-nav-list *ngFor="let left of leftmenu">
               <a mat-list-item routerLink={{left.routerLink}} routerLinkActive="active" id={{left.id}} >
                    <mat-list-item style="height:70px;" >
                    <h4  fxShow.sm="false" fxShow.xs="false" fxShow.md="false" fxShow="true"  mat-line class="clsleftheader">{{left.description}}<br/>{{left.subitem}}</h4>
                    
                    <mat-icon mat-list-avatar ><img mat-line src={{left.src}} ></mat-icon>
                    
                </mat-list-item>
                <mat-divider ></mat-divider>
                </a>
        </mat-nav-list>
    </mat-list>
</div>
