<app-home-header (onMapChanged)="OnMapclick($event)" [MCampusId]="MCampusId" [MBuildingId]="MBuildingId" [MFloorId]="MFloorId" [MSubZoneId]="MSubZoneId"></app-home-header>
<div class="mat-elevation-z0">
  <!-- <mat-card> -->
    <div id="divliveviewloading" style="display: table;
    position: absolute;
    height:100%; 
    width:100%;  
    z-index: 1000000;
    text-align: center;
">
<div style="display: table-cell;
    vertical-align: middle;">
    <div style="margin-left: calc(50% - 41px);
    
    color: White;">
       <div class="loadspinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
</div>
</div>
  <!-- </mat-card> -->
  <mat-card style="padding:5px;">
  </mat-card>
    <mat-card class="home-mat-card" (click)="CloseRightNav()">
        <div id="map" class="map"></div>
        <div id="marker" style="display: none;">
            <div id="marker1" class="marker"></div>
            <div id="marker2" class="marker"></div>
            <div id="marker3" class="marker"></div>
            <div id="marker4" class="marker"></div>
            <div id="mapselected"></div>
            <button id="btn_MapDetailsChanged" (click)="MapDetailsChanged()">Map Details Changed</button>
            <button id="btn_AlertCall" (click)="showalertnotification()">Map Details Changed</button>
            <button id="btn_AlertMapCall" (click)="showmapnotification()">Map Details Changed</button>
            <button id="btn_SearchCall" (click)="showAccessCode()">Map Details Changed</button>
            <button id="btn_AcceptCompleteCall" (click)="clkAccepteComplete()">Map Details Changed</button>
        </div>
        <div id="AlertInfo" style="display: none; position: absolute; left: 100px; top: 100px; border: 1px solid gray;">            
            <div id="spn_CampusId"></div>
            <div id="spn_BuildingId"></div>            
            <div id="spn_FloorId"></div>
            <div id="spn_RoomId"></div>
            <div id="spn_AlertId"></div>
            <div id="spn_AlertStatus"></div>
            <div id="spn_AlertType"></div>
            <div id="spn_ResidentId"></div>
            <div id="spn_AcceptCompleteId"></div>
            <div id="spn_AlertResStaff"></div>
            <div id="spn_Devicetype"></div>
            <div id="spn_DeviceSubtype"></div>
            <div id="spn_Locationtype"></div>
        </div>
        <div id="AlertMapInfo" style="display: none; position: absolute; left: 100px; top: 100px; border: 1px solid gray;">            
                <div id="spn_MapCampusId"></div>
                <div id="spn_MapBuildingId"></div>            
                <div id="spn_MapFloorId"></div>
                <div id="spn_MapRoomId"></div>
                <div id="spn_MapAlertId"></div>
                <div id="spn_MapAlertStatus"></div>
                <div id="spn_MapAlertType"></div>
                <div id="spn_MapResidentId"></div>
                <div id="spn_MapAcceptCompleteId"></div>
                <div id="spn_MapAlertResStaff"></div>
                <div id="spn_MapDevicetype"></div>
                <div id="spn_MapDeviceSubtype"></div>
                <div id="spn_MapLocationtype"></div>
            </div>
    </mat-card>
</div>

