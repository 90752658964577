import { Component, OnInit, Inject } from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ReportService } from "../report.service";
import { UpdateAssetName } from "../report";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage, AuthFailedMessage } from "../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import {appsettings} from '../../../app-settings';

@Component({
  selector: "app-report-editasset",
  templateUrl: "./report-editasset.component.html",
  styleUrls: ["./report-editasset.component.scss"],
})
export class ReportEditassetComponent implements OnInit {
  columns = 4;
  public EditassetNameForm: UntypedFormGroup;
  ReportAssetName;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<ReportEditassetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ReportService: ReportService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog
  ) {
    this.ReportAssetName = data.FullGeoName;
  }

  ngOnInit() {
    this.EditassetNameForm = new UntypedFormGroup({
      assetname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      assetId: new UntypedFormControl(""),
    });
  }

  public onCloseAsset = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.EditassetNameForm.controls[controlName].hasError(errorName);
  };

  public EditReportAssetName = (EditassetNameForm) => {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    (document.activeElement as HTMLTextAreaElement).blur();
    if (this.EditassetNameForm.valid) {
      this.updateAssetName(EditassetNameForm);
    }
  };

  updateAssetName(editassetform) {
    try {
      const saveclick = document.getElementById("btnsaveclick");
      if (saveclick != null)
        saveclick.className = "clsassetsavebutton mat-raised-button disablebtn";
      this.authkey = localStorage.getItem("Authkey") ?? "";
      const e_asset: UpdateAssetName = {
        authKey: this.authkey,
        assetId: parseInt(editassetform.assetId),
        assetName: editassetform.assetname.replace(/\s+/g, " ").trim(),
      };

      this.ReportService.updateAssetName(e_asset)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse != null && apiresponse != undefined) {
            if (apiresponse.toString() == "true") {
              generateAlertMessage(
                this.dialog,
                "Asset details edited successfully."
              );
            } 
            else if (apiresponse.toString() == "false") {
              generateAlertMessage(this.dialog, "Asset details edited failed.");
            }
            else if (apiresponse["condition"] == "Authfalse") {
              this.thisDialogRef.close("");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
          }          
          else {
            generateAlertMessage(this.dialog, "Asset details edited failed.");
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "report-editasset.component.ts" +
          " - updateAssetName() - " +
          err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.EditassetNameForm.controls).forEach((field) => {
        // {1}
        const control = this.EditassetNameForm.get(field); // {2}
        const assetEditFormId = document.getElementById(event.target.id);
        if (assetEditFormId != null) {
          if (field == assetEditFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
