import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { appsettings, enumFileNamelength, enumEnableDisable } from "../../../../../app-settings";
import {
  AddNewCampusForm,
} from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { AlertSharedService } from "../../../../../_services/index";
import { ApiService } from "../../../../../api.service";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

const URL = appsettings.URLinfo + "uploadMapImage";
@Component({
  selector: "app-add-campus",
  templateUrl: "./add-campus.component.html",
  providers: [TechnologyoverlayService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-campus.component.scss"],
})
export class AddCampusComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  locationName;
  campusimagename;
  campusaddImgUrl;
  campusaddsvgUrl;
  columns = 4;
  firstcolumns = 2;
  zoomval = 1;
  imgWidth = 200;
  height = 190;
  top = 0;
  left = 0;
  rotationAmount = 0;
  styles = "";
  downIcon = 0;
  updownArrowIcon = "keyboard_arrow_down";
  minheight = "230px";
  ErrorMsg;
  public campusaddForm: UntypedFormGroup;
  Facilityname;
  validcampusimgurl = false;
  private _onDestroy: Subject<void> = new Subject<void>();
  screenWidth;
  rowHeight = "79px";
  addcampusbool = true;
  CampusLength;
  isundo = 0;
  zoominvalue = 1;
  left_rightval = 0;
  top_bottomval = 0;
  undooperation = true;
  undopane = 0;
  uploadcampusimage;
  pngrotateamnt;

  error: string;
  dragAreaClass: string;

  clscolspan = 3;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddCampusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.Facilityname = data.FacilityName;
      if (data.Campus != "") {
        this.CampusLength = data.Campus[0].MaxId + 1;
      } else {
        this.CampusLength = 1;
      }

      this.onResize();
      this.breakpoint$.subscribe(() => this.breakpointChanged());      
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - constructor() - " + err.message
      );
    }
  }
  
  breakpointChanged() {        
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
      this.clscolspan = 3;
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
      this.clscolspan = 2;
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
      this.clscolspan = 2;
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
      this.clscolspan = 2;
    }     
  }
  
  @ViewChild("imageInput") imageInput: any;
  public onChangecampus(fileList: FileList) {
    this.saveFiles(fileList);
  }

  saveFiles(fileList: FileList) {
    if (fileList.length > 1) {
      generateAlertMessage(this.dialog, "Only one file at time allowed");
      this.campusaddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    }
    const file = fileList[0];    
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(removedSpaces_filename);
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );  
      this.campusaddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      this.campusaddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
      const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Campus Photo as image file."
      );
      this.campusaddImgUrl = "assets/images/defaultimg.png";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 1);
      else this.campusaddImgUrl = "assets/images/defaultimg.png";

      this.imageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 2) {
              // 1024 is 1MB
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 2 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Campus Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  uploadfiletoPath(data, file, type) {
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const FileName =  _filename;
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        this.campusimagename = res.filename;
        this.validcampusimgurl = true;
        if (type == 1) {
          this.campusaddImgUrl = appsettings.CAMPUS_IMAGE_FOLDER + res.filename;
        } else {
          this.campusaddsvgUrl = appsettings.CAMPUS_IMAGE_FOLDER + res.filename;
        }
      });
  }
  @ViewChild("imagesvgInput") imagesvgInput: any;
  public onChangecampussvg(fileList: FileList) {
    const file = fileList[0];
    const itemType = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) || file.name;
     const fileTypesAllowed = ["svg"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(this.dialog, "Please upload .svg file type");
      this.campusaddsvgUrl = "assets/images/defaultsvg.png";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 2);
      else this.campusaddsvgUrl = "assets/images/defaultsvg.png";
      this.imagesvgInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (self[0].includes("xml")) {
            checkvalidIMG = 1;
            resolve(checkvalidIMG);
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(_dialog, "Please upload valid file");
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.campusaddImgUrl = "assets/images/defaultimg.png";
      this.campusaddsvgUrl = "assets/images/defaultsvg.png";
      this.locationName = this.Facilityname;
      this.imgWidth = 200;
      this.validcampusimgurl = false;

      this.campusaddForm = new UntypedFormGroup({
        location: new UntypedFormControl("", [Validators.required]),
        campusname: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(32),
          Validators.pattern(appsettings.Name_Pattern),
        ]), // ^[a-zA-Z0-9!@#$%^& ]*$
        description: new UntypedFormControl("", [
          Validators.pattern(appsettings.Name_Pattern),
        ]),
      });

      const campusFormLocation = this.campusaddForm.get("location");
      if(campusFormLocation != null)
        campusFormLocation.disable();
      this.dragAreaClass = "dragarea";
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.campusaddForm.controls).forEach((field) => {
        // {1}
        const control = this.campusaddForm.get(field); // {2}
        const getTargetId = document.getElementById(event.target.id);
        if(getTargetId != null)
        {
          if (field == getTargetId.getAttribute("formControlName")) 
          {
            if(control != null)
            {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1280) {
      this.updownArrowIcon = "keyboard_arrow_down";
      this.downIcon = 0;
      this.thisDialogRef.updateSize("75em", "400px");
      this.minheight = "230px";
      this.columns = 4;
      this.firstcolumns = 2;
    }
    if (this.screenWidth <= 768) {
      this.rowHeight = "80px";
    } else if (this.screenWidth == 1097) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
      this.rowHeight = "79px";
    } else if (this.screenWidth == 1536) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    }
  }
  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    this.campusaddForm.controls[controlName].hasError(errorName);
  };

  public newCampusAdd = (addCampusFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      if (this.campusaddForm.valid) {
        this.addCampus(addCampusFormValue);
      }
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - newCampusAdd() - " + err.message
      );
    }
  };

  private addCampus = (addCampusFormValue) => {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.campusaddForm.valid && this.addcampusbool) {
        this.addcampusbool = false;        
        
        this.campusAddEnableDisable(enumEnableDisable.Disable);

        let _Description = "";
        if (addCampusFormValue.description != "")
          _Description = addCampusFormValue.description
            .replace(/\s+/g, " ")
            .trim();
        else _Description = "";
        //Add Campus is used to add a new campus for the site. In Add Campus we can give Campus Name, Description and Campus Image.

        const campusdetails: AddNewCampusForm = {
          authKey: this.authkey,
          location: this.locationName.replace(/\s+/g, " ").trim(),
          campusName: addCampusFormValue.campusname.replace(/\s+/g, " ").trim(),
          campusImage: this.campusimagename.toString(),
          description: _Description, //addCampusFormValue.description.replace(/\s+/g, ' ').trim(),
          zoomlevel: this.zoomval,
          rotatelevel: this.rotationAmount,
          transformX: this.left_rightval,
          transformY: this.top_bottomval,
        };
        this.technologyoverlayService
          .addCampus(campusdetails)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse["apiReturn"] == "Success") {
              let CheckAlertData = {
                IsAlertChanged: 0,
                IsAlertClicked: 0,
              };
              this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
              CheckAlertData = {
                IsAlertChanged: 1,
                IsAlertClicked: 0,
              };
              this.apiService.reloadmapconfig();
              this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
              generateAlertMessage(this.dialog, "Campus added successfully.");
              this.thisDialogRef.close("Confirm");
            } 
            else if (apiresponse["apiReturn"] == "Fail") {
              this.addcampusbool = true;
              generateAlertMessage(this.dialog, "Campus added failed.");
              this.thisDialogRef.close("Confirm");
            } 
            else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            } 
            else if (apiresponse["apiReturn"] == "campusName") {
              this.addcampusbool = true;
              generateAlertMessage(this.dialog, "Please enter Campus ");              
              this.campusAddEnableDisable(enumEnableDisable.Enable);
              return;
            } 
            else {
              this.addcampusbool = true;
              generateAlertMessage(
                this.dialog,
                "Campus  '" + apiresponse["CampusList"][0].CampusName + "' already exists."
              );              
              this.campusAddEnableDisable(enumEnableDisable.Enable);
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - addCampus() - " + err.message
      );
    }
  };

  rotateImage(direction: string) {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 1;
      if (direction === "left") {
        const flag = this.imgWidth;
        this.imgWidth = this.height;
        this.height = flag;
        this.rotationAmount = this.rotationAmount + -90;
        if (this.rotationAmount == -360) {
          this.rotationAmount = 0;
        }

        if (this.rotationAmount === -180 || this.rotationAmount === 0) {
          this.top = 0;
          this.left = 0;
        } else {
          this.top = ((this.imgWidth - this.height) / 2) * -1;
          this.left = (this.imgWidth - this.height) / 2;
        }
      } else if (direction === "right") {
        this.rotationAmount = this.rotationAmount + 90;
        if (this.rotationAmount == 360) {
          this.rotationAmount = 0;
        }
      }
      this.pngrotateamnt = this.rotationAmount;
      //this.styles =  'rotate(' + this.rotationAmount + 'deg)';
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - rotateImage() - " + err.message
      );
    }
  }

  removeImage() {
    try {
      this.undooperation = true;
      this.isundo = 8;
      this.uploadcampusimage = this.campusaddImgUrl;
      this.campusaddImgUrl = "assets/images/defaultimg.png";
      this.campusaddsvgUrl = "assets/images/defaultsvg.png";
      this.validcampusimgurl = false;
      this.rotationAmount = 0;
      this.zoomval = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
      //this.styles =  'rotate(' + this.rotationAmount + 'deg)';
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - removeImage() - " + err.message
      );
    }
  }

  zoomoutImage() {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 2;
      if (this.zoomval > 1) this.zoomval = this.zoomval - 0.5;
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - zoomoutImage() - " + err.message
      );
    }
  }

  zoomInImage() {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 3;
      if (this.zoomval < 5) this.zoomval = this.zoomval + 0.5;
      this.zoominvalue = this.zoomval;
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - zoomInImage() - " + err.message
      );
    }
  }

  resetformtext() {
    try {
      this.undooperation = false;
      this.rotationAmount = 0;
      this.styles = "rotate(" + this.rotationAmount + "deg)";
      this.zoomval = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - resetformtext() - " + err.message
      );
    }
  }

  undoimage() {
    try {
      if (this.undooperation == true) {
        if (this.isundo == 1) {
          if (this.undopane == 0) {
            if (this.rotationAmount != 0)
              this.rotationAmount = this.rotationAmount - 90;
            else this.rotationAmount = 270;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 2) {
          if (this.undopane == 0) {
            if (this.zoomval < this.zoominvalue)
              this.zoomval = this.zoomval + 0.5;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 3) {
          if (this.undopane == 0) {
            if (this.zoomval > 1) this.zoomval = this.zoomval - 0.5;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 4) {
          if (this.left_rightval != 0)
            this.left_rightval = this.left_rightval - 10;
        } else if (this.isundo == 5) {
          if (this.left_rightval != 0)
            this.left_rightval = this.left_rightval + 10;
        } else if (this.isundo == 6) {
          if (this.top_bottomval != 0)
            this.top_bottomval = this.top_bottomval + 10;
        } else if (this.isundo == 7) {
          if (this.top_bottomval != 0)
            this.top_bottomval = this.top_bottomval - 10;
        } else if (this.isundo == 8) {
          this.campusaddImgUrl = this.uploadcampusimage;
          this.rotationAmount = this.pngrotateamnt;
          this.validcampusimgurl = true;
        }
      }

      this.undooperation = false;
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - undoimage() - " + err.message
      );
    }
  }

  leftImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 1;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 4;
    }
  }
  rightImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 2;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 5;
    }
  }
  bottomImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 3;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 6;
    }
  }
  topImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 4;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 7;
    }
  }

  panAlign(IsAdd, IsTopBottom) {
    let IsChk = false;
    if (IsAdd == panIsAdd.Add && IsTopBottom == panIsTopBottom.TopBottom) {
      if (this.top_bottomval < 70) {
        this.top_bottomval = this.top_bottomval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.TopBottom
    ) {
      if (this.top_bottomval > -70) {
        this.top_bottomval = this.top_bottomval - 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Add &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval < 70) {
        this.left_rightval = this.left_rightval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval > -70) {
        this.left_rightval = this.left_rightval - 10;
        IsChk = true;
      }
    }
    return IsChk;
  }
  
  campusAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsavecampusaddclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clscampussavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clscampussavebutton mat-raised-button disablebtn";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

enum panIsAdd {
  Add = 0,
  Sub = 1,
}
enum panIsTopBottom {
  TopBottom = 0,
  LeftRignt = 1,
}
