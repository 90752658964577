<perfect-scrollbar style="max-width: 1200px; max-height: 750px;" >
  <div class="clseditBuilding">
      <div class="editBuilding-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editBuildingHeader" style="margin: 0 0 10px 11px;">Building Information
        <span><img class="clscloseimageBuilding" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>
      <div>
        <form  style="padding:24px;"  [formGroup]="editBuildingForm" autocomplete="off" novalidate (ngSubmit)="editBuildingdetails(editBuildingForm.value)">     
          
          <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
              
            <div fxFlex="55" fxFlex.md="25"> 
                  
              <mat-grid-list [cols]="columns" class="clsbuildimagematgridlist">
                <div class="clsBuildimagedivlist">
                  <div id="map2" class="clseditbuildDragplanimg map" [ngStyle]="{'transform': 'scale(1) rotate(0deg) translateX(0px) translateY(0px)'}" (click)='BuildingMapView(0)' draggable="false">
                  </div>                
                </div>   
              <!--Click the Open Building button to locate the building in the campus view.-->
              <button type="button" class="clsbuildmapbutton" color="primary" mat-raised-button (click)="BuildingMapView(1)" [disabled]="!showBuildingDetails">Open Building</button> 
              </mat-grid-list>
              
            <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">                             
                <mat-grid-tile>
                    <label>Campus</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsmatgridtxt">                      
                      <div class="text-inside" style="width:100%;"> 
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">                              
                          <mat-select formControlName="campusname" placeholder="Select Campus" [(ngModel)]="selectedCampusId">    
                            <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId" (click)="changecampus_Building(Campus.CampusId);">
                              {{Campus.CampusName}}
                            </mat-option>
                          </mat-select> 
                          <mat-error>Please select Campus</mat-error>
                          <input type="hidden" formControlName="CampusId" [(ngModel)]="buildingData.CampusId" required>
                            <input type="hidden" formControlName="BuildId" [(ngModel)]="buildingData.BuildId" required>
                      </mat-form-field>
                    </div>
                </mat-grid-tile>                                 

                <mat-grid-tile>
                    <label>Building </label>
                </mat-grid-tile>
                <mat-grid-tile class="clsmatgridtxt">
                        <div class="text-inside" style="width:100%;">
                            <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                           <input matInput placeholder="Building" formControlName="BuildingName" maxlength="32" [(ngModel)]="buildingData.BuildingName" (keyup)="handleInput($event)" required/>
                           <mat-error *ngIf="editBuildingForm.controls.BuildingName.hasError('required')">Please enter Building </mat-error>
                           <mat-error *ngIf="editBuildingForm.controls.BuildingName.hasError('pattern')">Please enter valid Building </mat-error>
                           <mat-error *ngIf="editBuildingForm.controls.BuildingName.hasError('maxlength')">Max length 32 characters only</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>                 

              <mat-grid-tile>
                <label>Description</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsmatgridtxt">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="Description" maxlength="32" formControlName="description" [(ngModel)]="buildingData.Description" (keyup)="handleInput($event)"/>      
                      <!-- <mat-error *ngIf="editBuildingForm.controls.description.hasError('required')">Please enter Description</mat-error> -->
                      <mat-error *ngIf="editBuildingForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>                                           
                    </mat-form-field>
                  </div>
            </mat-grid-tile>    
            
            <mat-grid-tile>
                <label>Notifications</label>
              </mat-grid-tile>
              <mat-grid-tile class="clsmatgridtxt">
                <div class="text-inside clsdiveditbuilding">                   
                      <div style="padding: 8px 10px;">
                      <label>Campus (Default)</label>
                      <mat-checkbox class="clslivelocation clsenable" id="chkcampus" [checked]="chkCampusvalue" (change)="toggleCamVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                    </div>
                     <div style="margin-left:.5em;padding:8px 0px;">
                      <label>Custom</label>
                      <mat-checkbox class="clslivelocation clsenable" id="chkcustom" [checked]="chkCustomvalue" (change)="toggleCusVisibility($event)" style="font-size: 14px;margin-left: .5em;"></mat-checkbox>
                    </div>                  
                </div>
              </mat-grid-tile>   
              
              <mat-grid-tile>
                <label>Floors</label>
              </mat-grid-tile>
              <mat-grid-tile class="clsmatgridtxt">
                <div class="text-inside">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100px;">
                    <input matInput formControlName="floorcount" (keyup)="handleInput($event)" [(ngModel)]="getFloorCount"  maxlength="2" [readonly]="buildFloorCount" />                
                  </mat-form-field>                                   
                </div>
            
                <div class="text-inside">
                  <button type="button" class="clsbtnEditFloor" mat-raised-button (click)="clkEditBuildingFloor(buildingData,0)" [disabled]="buildbtnFloorEdit">Edit Floors</button> 
                </div>
              </mat-grid-tile>

                </mat-grid-list>           
            
            </div>
            
            
            <div fxFlex="55" fxFlex.md="45" style="margin-top:2em;" class="clsdivbuildactivezones">
              <label>Active Zones, Spaces and Devices in this Building:</label>
                <mat-grid-list [cols]="columns" class="clsBuildmatgridlist">
                    <!-- <mat-grid-tile> -->
                      <perfect-scrollbar style="max-width: 1200px; max-height: 430px;" >                        
                        <div style="margin-top: 1em; margin-right: 1em;margin-bottom: 1em;margin-left: 3em">
                          <label style="font-size: medium;">Floors/Zones</label>
                        </div>  
                         <div  *ngFor="let Floor of Floorinfo;">
                          <div *ngIf="Floor.IsFloor == 1" class="text-inside" style="margin:1em;display: flex;">
                              <img src="assets/images/AddNew.png"  style="margin-top: 0em; margin-right: .5em;margin-bottom: 0em;margin-left: 0em" class="clsBuildeditimg" (click)="addZonedetails(Floor.FloorId,Floor.FloorName);" [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}" />
                              <div *ngIf="Floor.FloorName.trim().length ==0" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}">DEFAULT-{{Floor.spacecount}} Spaces,{{Floor.devicecount}} Devices</div>
                              <div *ngIf="Floor.FloorName.length < 15 && Floor.FloorName.length>0" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}"> {{Floor.FloorName}}-{{Floor.spacecount}} Spaces,{{Floor.devicecount}} Devices</div>
                              <div *ngIf="Floor.FloorName.length >= 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}" matTooltipClass="devicetooltip" matTooltip={{Floor.FloorName}} #tooltip="matTooltip" matTooltipPosition="above"> 
                              {{Floor.FloorName.slice(0,15) + ".."}}-{{Floor.spacecount}} Spaces,{{Floor.devicecount}} Devices</div>
                          </div>             
                         <div *ngIf="Floor.IsFloor == 0 && Floor.FloorName!=null" class="text-inside" style="margin:1em;display: flex;">
                                <img src="assets/images/Edit_Icon.png" style="margin-top: 0em; margin-right: 0em;margin-bottom: 0em;margin-left: 2em"class="clsBuildeditimg" (click)="editZonedetails(Floor)" [ngStyle]="{'display': bAddEdit == false ? 'none' : 'block'}" />
                                <img src="assets/images/deletebuilding.png" style="margin:0em 1em;" class="clsBuildeditimg" [ngStyle]="{'display': bDelete == false ? 'none' : 'block'}" (click)="deleteZonedetails(Floor)" />
                                <div *ngIf="Floor.FloorName.length < 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}"> {{Floor.FloorName}}-{{Floor.spacecount}} Spaces,{{Floor.devicecount}} Devices</div>
                                <div *ngIf="Floor.FloorName.length >= 15" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}" matTooltipClass="devicetooltip" matTooltip={{Floor.FloorName}} #tooltip="matTooltip" matTooltipPosition="above"> 
                                {{Floor.FloorName.slice(0,15) + ".."}}-{{Floor.spacecount}} Spaces,{{Floor.devicecount}} Devices</div>
                          </div> 
                        </div>                             
                    <!-- </mat-grid-tile>-->
                  </perfect-scrollbar>
                  </mat-grid-list>
            </div>
            
          </div>
          <mat-dialog-actions style="float:left;">              
            <button type="button" class="clsBuildingdltbutton" mat-raised-button (click)="clkdeleteconfirmdialog(buildingData)" *ngIf="bDelete==true">Delete</button> 
            </mat-dialog-actions>
            
            <!-- <mat-dialog-actions style="float:left;margin-left: 28%;">                    
              <button type="button" class="clsfloordltbutton" color="primary" style="width:8rem;" mat-raised-button (click)="BuildingMapView()">Open Building</button>                    
          </mat-dialog-actions> -->
            
          <mat-dialog-actions class="clsBuildbuttoncontrol">                 
            <button type="button" class="clsmapscancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
            <button id="btnsavebuildeditclick" class="clsmapsaddbutton" mat-raised-button [disabled]="!editBuildingForm.valid" *ngIf="bAddEdit==true">{{btnBuildingText}}</button>
          </mat-dialog-actions>
            
        </form>
      </div>
  </div>
  </perfect-scrollbar>
  
  