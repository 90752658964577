<perfect-scrollbar style="max-width: 1200px; max-height: 530px;" >
  <div class="clsaddBuildDuplicate">
      <div class="duplicatebuild-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addduplicateBuildHeader" style="margin: 0 0 10px 11px;">Duplicate Building
        <span><img class="clsbuildDupcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="buildDuplicateForm" autocomplete="off" novalidate [style.height]="minheight" (ngSubmit)="duplicatebuildDetails(buildDuplicateForm.value)">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div fxFlex="55" fxFlex.md="25">   
                
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                    
                    <mat-grid-tile class="clsdupbuildmatgrid">
                      <label>Selected Building</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsdupbuildmatgridtxt">
                          <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsdupbuildaddtxt">
                             <input matInput placeholder="Building Name" formControlName="buildingname" maxlength="32" [(ngModel)]="builidngInfo[0].BuildingName" (keyup)="handleInput($event)" required/>                             
                             <input type="hidden" formControlName="campusID" [(ngModel)]="builidngInfo[0].CampusId">   
                             <input type="hidden" formControlName="buildID" [(ngModel)]="builidngInfo[0].BuildId">
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>   

                    <mat-grid-tile class="clsdupbuildmatgrid">
                      <label>Campus Location</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsdupbuildmatgridtxt">
                          <div class="text-inside"> 
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsdupbuildaddtxt">
                              <!-- <input matInput placeholder="Campus Location" formControlName="campusname" maxlength="32" [(ngModel)]="builidngInfo[0].CampusName" (keyup)="handleInput($event)" required/> -->                              
                              <mat-select formControlName="campus" placeholder="Select Campus" tabindex="9">    
                                <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId" (click)="changecampus_duplicate(Campus.CampusId);">
                                  {{Campus.CampusName}}
                                </mat-option>
                              </mat-select> 
                              <mat-error>Please select Campus</mat-error>   
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>   

                    <mat-grid-tile class="clsdupbuildmatgrid">
                      <label>New Building Name</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsdupbuildmatgridtxt">
                        <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsdupbuildaddtxt">
                            <input matInput placeholder="Building Name" maxlength="32" formControlName="newbuildingname" (keyup)="handleInput($event)">
                            <mat-error *ngIf="buildDuplicateForm.controls.newbuildingname.hasError('required')">Please enter New Building Name</mat-error>
                            <mat-error *ngIf="buildDuplicateForm.controls.newbuildingname.hasError('pattern')">Please enter valid New Building Name</mat-error>
                            <mat-error *ngIf="buildDuplicateForm.controls.newbuildingname.hasError('maxlength')">Max length 32 characters only</mat-error>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>   
                  
                  <mat-grid-tile class="clsdupbuildmatgrid">
                    <label>New Building Description</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsdupbuildmatgridtxt">
                      <div class="text-inside">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsdupbuildaddtxt">
                           <input matInput placeholder="Description" maxlength="32" formControlName="description" (keyup)="handleInput($event)">
                           <mat-error *ngIf="buildDuplicateForm.controls.description.hasError('required')">Please enter Description</mat-error>
                           <mat-error *ngIf="buildDuplicateForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                           <mat-error *ngIf="buildDuplicateForm.controls.description.hasError('maxlength')">Max length 32 characters only</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>                                                  

                   </mat-grid-list>                              
              </div>
         
              <div fxFlex="55" fxFlex.md="45" style="margin-top:2em;" class="clsdivactiveduplicate">
                <label>Select the Zones you'd like to duplicate with this Building:</label>
                  <mat-grid-list [cols]="columns" class="clsmatgridlist">
                      <!-- <mat-grid-tile> -->
                        <perfect-scrollbar style="max-width: 1200px; max-height: 240px;height: 240px;" >
                          <div class="text-inside" style="margin-top:1em;" *ngIf="Zoneinfo.length > 0">                                                                            
                            <mat-checkbox aria-label="Select All"
                             class="clsdupbuildcheckbox clsenable" id="chkresident"
                             [checked]="isCheckedAll"                                           
                             (change)="$event ? toggleAll($event) : null" style="font-size: 14px;">Select All</mat-checkbox>
                          </div> 
                          <div style="margin:1em;">
                            <label style="font-size: medium;">Zones</label>
                          </div>  
                          <div *ngFor="let Zone of Zoneinfo;">
                            <div class="text-inside" style="margin-top:1em;" *ngIf="Zone.ZoneName.length < 15">                                                                            
                              <mat-checkbox class="clsdupbuildcheckbox clsenable" [checked]="exists(Zone.ZoneId)" [id]="'chkfloor_'+Zone.ZoneId" (click)="$event.stopPropagation()"
                              (change)="$event ? togglefloorVisibility(Zone.ZoneId,$event) : null" style="font-size: 14px;">
                              {{Zone.ZoneName}} | {{Zone.spacecount}} Spaces, {{Zone.devicecount}} Devices</mat-checkbox>
                            </div>                             
                            <div class="text-inside" style="margin-top:1em;" *ngIf="Zone.ZoneName.length >= 15">                                                                            
                              <mat-checkbox class="clsdupbuildcheckbox clsenable" [checked]="exists(Zone.ZoneId)" [id]="'chkfloor_'+Zone.ZoneId" (click)="$event.stopPropagation()"
                              (change)="$event ? togglefloorVisibility(Zone.ZoneId,$event) : null" style="font-size: 14px;"
                              matTooltipClass="devicetooltip" matTooltip={{Zone.ZoneName}} #tooltip="matTooltip" matTooltipPosition="above">
                              {{Zone.ZoneName.slice(0,15) + ".."}} | {{Zone.spacecount}} Spaces, {{Zone.devicecount}} Devices</mat-checkbox>
                            </div> 
                          </div>                   
                      <!-- </mat-grid-tile>-->
                    </perfect-scrollbar>
                    </mat-grid-list>
              </div>
          
        
 </div>
 <mat-dialog-actions class="clsdupbuildbuttoncontrol">      
  <button type="button" class="clsdupbuildcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnduplicatesaveclick" class="clsdupbuildsavebutton" mat-raised-button  *ngIf="bAddEdit == true">Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>