import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { Subject } from "rxjs";
import {
  enumAlertType,
  enumNotifyTypeItem,
  enumNotifyTypeText,
  appsettings
} from "../../../app-settings";
import { notificationService } from "../notification.service";
import {
  NotificationForCreationResponse,
  NotifyType,
  getRoleIndividualList,
  AddNotification,
  notifyTypeItem,
  checkAlertItem,
} from "../notification";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { AlertSharedService } from "../../../_services/index";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { ApiService } from "../../../api.service";
import { DOCUMENT } from "@angular/common";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-add-notification",
  templateUrl: "./add-notification.component.html",
  providers: [notificationService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-notification.component.scss"],
})
export class AddNotificationComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 8;
  notifytype = "";
  notifytypeItem;
  CampusId;
  AlarmId;
  AlarmSubTypeId = 0;
  Escalationlevel;
  public notificationCreationForm: UntypedFormGroup;
  RoleIndividualList: getRoleIndividualList[];
  NotifyType: NotifyType[] = [
    { Notifytype: "Individual", NotifytypeId: 1 },
    { Notifytype: "Role (Global)", NotifytypeId: 0 },
    { Notifytype: "Staff Group", NotifytypeId: 2 },
    { Notifytype: "Resident Group", NotifytypeId: 3 },
  ];
  campusName;
  addNotifyflag = true;
  BuildingId;
  FloorId;
  SubZoneId;
  selectedBuildingName;
  selectedFloorName;
  selectedSubZoneName;
  chkEscalateyesvalue = true;
  chkEscalatenovalue = false;
  AlarmSubType_Name;
  checkEscalate = false;
  checkRepeatLevel = false;
  Escalatevalue;
  RepeatLevelvalue;
  TimeEscalatevalue;
  RepeatCyclevalue;
  RepeatNumbervalue;  
  eventName;
  public EnumAlertType = enumAlertType;
  showNotifyWarningImage = false;
  AlertId;
  private _onDestroy: Subject<void> = new Subject<void>();
  EscalateInfoTooltip = "When Repeat Level Disabled, the Time to Escalate value can be manually entered once Escalate is enabled.\n\n When Repeat Level Enabled, " +
                        "Time to Escalate is auto-calculated with the value equal to (Repeat Number * Repeat Cycle) + Repeat Cycle, once Escalate is enabled. ";
  NotificationLevelTooltip = "WARNING: Previous notification level(s) has/have not been setup to escalate.For this level to execute,edit previous level.";
  authkey;
  Breakpoints = Breakpoints;
  notify_RepeatLevelNumber;
  notify_RepeatLevelCycle;
  notify_TimeToEscalate;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: notificationService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private breakpointObserver: BreakpointObserver
  ) {
    this.CampusId = data.CampusId;
    this.AlarmId = data.AlarmId;
    this.Escalationlevel = data.EscalationLevel;
    if (data.AlarmSubTypeId > 0) this.AlarmSubTypeId = data.AlarmSubTypeId;
    this.campusName = data.CampusName;
    this.BuildingId = data.BuildId;
    this.FloorId = data.FloorId;
    this.SubZoneId = data.SubZoneId;
    this.AlertId = data.AlertId;
    this.AlarmSubType_Name = data.AlarmSubTypeName;
    if (this.BuildingId > 0 || this.BuildingId != null)
      this.selectedBuildingName = data.BuildingName;
    else this.selectedBuildingName = "";

    if (this.FloorId > 0 || this.FloorId != null)
      this.selectedFloorName = data.FloorName;
    else this.selectedFloorName = "";
    
    if(this.SubZoneId > 0 || this.SubZoneId != null)  
      this.selectedSubZoneName = data.SubZoneName;
    else
      this.selectedSubZoneName = ""; 
      this.eventName = data.AlarmNameId;
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 8;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 3;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.notificationCreationForm = new UntypedFormGroup({
      escalation: new UntypedFormControl("", []),
      // campus: new UntypedFormControl("", []),
      // building: new UntypedFormControl("", []),
      // floor: new UntypedFormControl("", []),
      // subzone: new UntypedFormControl('', []),
      RepeatLevelNumber: new UntypedFormControl('', [Validators.required,Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]),   
      RepeatLevelCycle: new UntypedFormControl('', [Validators.required,Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]), 
      // EventName: new UntypedFormControl("", []),
      TimeToEscalate: new UntypedFormControl("", [
        Validators.required,
        Validators.min(1),
        Validators.pattern(appsettings.Number_Pattern),
      ]),     
      notify: new UntypedFormControl("", [Validators.required]),
      NotifyList: new UntypedFormControl("", [Validators.required]),
    });
    
    this.notify_RepeatLevelNumber = this.notificationCreationForm.get("RepeatLevelNumber");
    this.notify_RepeatLevelCycle = this.notificationCreationForm.get("RepeatLevelCycle");
    this.notify_TimeToEscalate = this.notificationCreationForm.get("TimeToEscalate");
    if (this.notify_RepeatLevelNumber != null)
      this.notify_RepeatLevelNumber.disable();
      
    if (this.notify_RepeatLevelCycle != null)
      this.notify_RepeatLevelCycle.disable();
      
    if (this.notify_TimeToEscalate != null)
      this.notify_TimeToEscalate.disable();
      
    if(this.Escalationlevel > 1)
      this.getNotifyRepeatEscalateLevel()
  }

  getNotifyRepeatEscalateLevel()
  {
    try
    {
      const notifyType_Item : any =
      {        
        authKey: this.authkey,
        level:this.Escalationlevel,
        alertId:this.AlertId
      }   
      this.notificationService.getNotifyRepeatEscalateLevels(notifyType_Item).pipe(takeUntil(this._onDestroy))
      .subscribe((EscalationsLevel : any[])=>{        
         console.log(EscalationsLevel)
         if(EscalationsLevel[0].NeedRepeat == 1 && EscalationsLevel[0].NeedEscalate == 0)
          this.showNotifyWarningImage = true;
      });
    }
    catch (err) {
      throw new Error('edit-notification.component.ts' + ' - getNotificationLevels() - ' + err.message);
    }
  }
  
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.notificationCreationForm.controls).forEach((field) => {
        // {1}
        const control = this.notificationCreationForm.get(field); // {2}
        const notifyAddFormId = document.getElementById(event.target.id);
        if (notifyAddFormId != null)
          if (field == notifyAddFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
      });
    } catch (err) {
      throw new Error(
        "add-notification.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  // On change Notify
  @ViewChild("roleindivi") SelRoleIndivi: MatSelect;
  onChangeNotify(event) {
    try {
      this.notifytype = event;
      let notifyType = 0;
      this.notificationCreationForm.controls['NotifyList'].reset();
      if (this.notifytype == enumNotifyTypeText.Individual)
        notifyType = enumNotifyTypeItem.Individual;
      else if (this.notifytype == enumNotifyTypeText.Role)
        notifyType = enumNotifyTypeItem.Role;
      else if (this.notifytype == enumNotifyTypeText.StaffGroup)
        notifyType = enumNotifyTypeItem.StaffGroup;
      else if (this.notifytype == enumNotifyTypeText.ResidentGroup)
        notifyType = enumNotifyTypeItem.ResidentGroup;

      const notifyType_Item: notifyTypeItem = {
        authKey: this.authkey,
        nType: notifyType,
      };

      this.getRoleOrIndividualList(notifyType_Item);
      //this.SelRoleIndivi.options.forEach( (item : MatOption) => {item.deselect()});
    } catch (err) {
      throw new Error(
        "add-notification.component.ts" + " - onChangeNotify() - " + err.message
      );
    }
  }

  // get Role/Invidual Data
  getRoleOrIndividualList(notifytype): void {
    try {
      this.RoleIndividualList = [];      
      this.notificationService
        .getRoleOrIndividualList(notifytype)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((getRoleIndividualList: any[]) => {
          if (
            getRoleIndividualList != null &&
            getRoleIndividualList != undefined
          ) {
            this.RoleIndividualList = getRoleIndividualList;
          }
        });
    } catch (err) {
      throw new Error(
        "add-notification.component.ts" +
          " - getRoleOrIndividualList() - " +
          err.message
      );
    }
  }

  // close Notification Dialog
  public onCloseNotification = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.notificationCreationForm.controls[controlName].hasError(
      errorName
    );
  };

  public createNotification = (notificationFormValue) => {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    if (this.notificationCreationForm.valid && this.addNotifyflag) {
      this.addNotifyflag = false;      

      if (this.AlarmSubType_Name == "Low Batteries" || this.AlarmSubType_Name == "Lost Communication") {
        // "Maintenance"
        
        this.notificationCreationForm.controls["RepeatLevelNumber"].setValidators([Validators.required,Validators.max(30),Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]);
        this.notificationCreationForm.controls["RepeatLevelNumber"].updateValueAndValidity();
        this.addNotifyflag = true;
        if(!this.notificationCreationForm.valid)
            return false; 
            
        this.notificationCreationForm.controls[
          "RepeatLevelCycle"
        ].setValidators([
          Validators.required,
          Validators.max(72),
          Validators.min(1),
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.notificationCreationForm.controls[
          "RepeatLevelCycle"
        ].updateValueAndValidity();
        this.addNotifyflag = true;
        if (!this.notificationCreationForm.valid) return false;
      } 
      else if (this.AlarmSubType_Name != "Low Batteries" && this.AlarmSubType_Name != "Lost Communication") {
        
        this.notificationCreationForm.controls["RepeatLevelNumber"].setValidators([Validators.required,Validators.max(30),Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]);
            this.notificationCreationForm.controls["RepeatLevelNumber"].updateValueAndValidity();
            this.addNotifyflag = true;
            if(!this.notificationCreationForm.valid)
                return false;
                
        this.notificationCreationForm.controls[
          "RepeatLevelCycle"
        ].setValidators([
          Validators.required,
          Validators.max(60),
          Validators.min(1),
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.notificationCreationForm.controls[
          "RepeatLevelCycle"
        ].updateValueAndValidity();
        this.addNotifyflag = true;
        if (!this.notificationCreationForm.valid) return false;
      }
      
      if(this.AlarmSubType_Name == "Low Batteries" || this.AlarmSubType_Name == "Lost Communication")
      {
        this.notificationCreationForm.controls["TimeToEscalate"].setValidators([
          Validators.required,
          Validators.max(72),
          Validators.min(1),
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.notificationCreationForm.controls[
          "TimeToEscalate"
        ].updateValueAndValidity();
        this.addNotifyflag = true;
        if (!this.notificationCreationForm.valid) return false;
      } 
      else if(this.AlarmSubType_Name != "Low Batteries" && this.AlarmSubType_Name != "Lost Communication")
      {
        this.notificationCreationForm.controls["TimeToEscalate"].setValidators([
          Validators.required,
          Validators.max(60),
          Validators.min(1),
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.notificationCreationForm.controls[
          "TimeToEscalate"
        ].updateValueAndValidity();
        this.addNotifyflag = true;
        if (!this.notificationCreationForm.valid) return false;
      }

      this.checkAlertForCampus(notificationFormValue);
    }
  };

  checkAlertForCampus(notificationFormValue) {
    this.addNotifyflag = false;
    const checkalertItem: checkAlertItem = {
      authKey: this.authkey,
      campusId: parseInt(this.CampusId),
      eventId: parseInt(this.AlarmId),
      subTypeId: this.AlarmSubTypeId,
      buildId: parseInt(this.BuildingId),
      floorId: parseInt(this.FloorId),
      subzoneId:parseInt(this.SubZoneId)
    };
    this.notificationService
      .checkAlertForCampus(checkalertItem)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: any[]) => {
        if (apiresponse.toString() == "true") {
          this.confirmation(notificationFormValue);
        } else {
          this.onSaveClicked(notificationFormValue);
        }
      });
  }
  confirmation(notificationFormValue) {
    try {
      const ConfirmMessageData = {
        ConfirmMessage:
          "Already escalated alerts won't be notified to this staff even though the staff will be added to the escalation.<br> Do you want to continue ?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          this.onSaveClicked(notificationFormValue);
        } else {
          this.addNotifyflag = true;
          this.thisDialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "add-notification.component.ts" + " - confirmation() - " + err.message
      );
    }
  }

  // Add Notification
  private onSaveClicked = (notificationFormValue) => {
    try {
      this.addNotifyflag = false;
        let Escalatevalue;
        let TimetoEscalatevalue = 0;                              
        let RepeatLevelvalue;        
        let RepeatCyclevalue = 0;
        let RepeatNumbervalue = 0;
     
      if(this.checkEscalate == true)
        {
          Escalatevalue = 1;
          TimetoEscalatevalue = parseInt(notificationFormValue.TimeToEscalate);
        }
        else
          Escalatevalue = 0;
          
        if(this.checkRepeatLevel == true)  
        {
          RepeatLevelvalue = 1;
          RepeatCyclevalue = parseInt(notificationFormValue.RepeatLevelCycle);
          RepeatNumbervalue = parseInt(notificationFormValue.RepeatLevelNumber);
          if(this.checkEscalate)
            TimetoEscalatevalue = parseInt(this.TimeEscalatevalue);
        }
        else
          RepeatLevelvalue = 0;
          
        // if(this.AlarmSubType_Name == "Low Batteries" || this.AlarmSubType_Name == "Lost Communication")
        // {
        //   if(TimetoEscalatevalue < 1 || TimetoEscalatevalue > 72 && this.checkEscalate == true && this.checkRepeatLevel == false)
        //   {
        //     generateAlertMessage(this.dialog, "Please Enter Time To Escalation between 1 and 72");
        //     this.addNotifyflag = true;
        //     return false;
        //   }
        // }
        // else if(this.AlarmSubType_Name != "Low Batteries" && this.AlarmSubType_Name != "Lost Communication")
        // {
        //   if(TimetoEscalatevalue < 1 || TimetoEscalatevalue > 60 && this.checkEscalate == true && this.checkRepeatLevel == false)
        //   {
        //     generateAlertMessage(this.dialog, "Please Enter Time To Escalation between 1 and 60");
        //     this.addNotifyflag = true;
        //     return false;
        //   }
        // } 

      const notification: AddNotification = {
        authKey: this.authkey,
        escalation: parseInt(this.Escalationlevel),
        eventType: parseInt(this.AlarmId),
        eventName: this.eventName,//notificationFormValue.EventName.toString(),
        timeToEscalate: TimetoEscalatevalue,
        notify: notificationFormValue.notify.toString(),
        notifyList: notificationFormValue.NotifyList.toString(),
        alarmSubTypeId: this.AlarmSubTypeId,
        campusId: parseInt(this.CampusId),
        campusName: this.campusName,
        buildingId: parseInt(this.BuildingId),
        floorId: parseInt(this.FloorId),
        subzoneId:parseInt(this.SubZoneId),
        needEscalate: parseInt(Escalatevalue),
        needRepeat: RepeatLevelvalue,
        repeatCycle: RepeatCyclevalue,
        repeatNumber: RepeatNumbervalue,
      };

      this.notificationService
        .addNotification(notification)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: NotificationForCreationResponse[]) => {
          if (apiresponse != null && apiresponse != undefined) {
            if (apiresponse["apiReturn"] == "Success") {
              let CheckAlertData = {
                IsAlertChanged: 0,
                IsAlertClicked: 0,
              };
              this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
              CheckAlertData = {
                IsAlertChanged: 1,
                IsAlertClicked: 0,
              };
              this.apiService.reloadNotification();
              this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
              generateAlertMessage(
                this.dialog,
                "Escalation data added successfully."
              );
            } 
            else if (apiresponse["apiReturn"] == "Fail") {
              this.addNotifyflag = true;
              generateAlertMessage(
                this.dialog,
                "Escalation data added failed."
              );
            } 
            else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
          } 
          else {
            this.addNotifyflag = true;
            generateAlertMessage(this.dialog, "Escalation data added failed.");
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "add-notification.component.ts" + " - onSaveClicked() - " + err.message
      );
    }
  };

toggleRepeatLevel(event)
{
  this.notify_RepeatLevelNumber = this.notificationCreationForm.get("RepeatLevelNumber");
  this.notify_RepeatLevelCycle = this.notificationCreationForm.get("RepeatLevelCycle");
  this.notify_TimeToEscalate = this.notificationCreationForm.get("TimeToEscalate");
  if(event.checked == true)
  {
    this.checkRepeatLevel = true;   
    
    if (this.notify_RepeatLevelNumber != null)
      this.notify_RepeatLevelNumber.enable();
      
    if (this.notify_RepeatLevelCycle != null)
      this.notify_RepeatLevelCycle.enable();   
      
    if(this.checkEscalate == true)
    {
      if(this.notify_TimeToEscalate != null)
        this.notify_TimeToEscalate.disable(); 
    }
  }
  else if(event.checked == false)
  {
    this.checkRepeatLevel = false;
    this.RepeatCyclevalue = "";
    this.RepeatNumbervalue = "";
    if (this.notify_RepeatLevelNumber != null)
      this.notify_RepeatLevelNumber.disable();
      
    if (this.notify_RepeatLevelCycle != null)
      this.notify_RepeatLevelCycle.disable();    
      
    if(this.checkEscalate == true)
    {
      if(this.notify_TimeToEscalate != null)
        this.notify_TimeToEscalate.enable();
    }
  }
}

toggleEscalate(event)
{
  this.notify_TimeToEscalate = this.notificationCreationForm.get("TimeToEscalate");
  if(event.checked == true)
  {
    this.checkEscalate = true;
    if (this.notify_TimeToEscalate != null)
      this.notify_TimeToEscalate.enable();  
    
    if(this.checkRepeatLevel == true)
    {
      if (this.notify_TimeToEscalate != null)
        this.notify_TimeToEscalate.disable();  
      this.calculateTimeEscalate();
    }
  }
  else if(event.checked == false)
  {
    this.checkEscalate = false;
    this.TimeEscalatevalue = "";
    if (this.notify_TimeToEscalate != null)
      this.notify_TimeToEscalate.disable();      
  }
}

mouseleaveRepeatCycle()
{
  if(this.checkEscalate == true && this.checkRepeatLevel == true)
    this.calculateTimeEscalate();
}

calculateTimeEscalate()
{
  const repeatNumbervalue = this.notificationCreationForm.value.RepeatLevelNumber;
  const repeatCyclevalue = this.notificationCreationForm.value.RepeatLevelCycle;
  if(repeatNumbervalue != "" && repeatCyclevalue != "" && /^[0-9]*$/.test(repeatCyclevalue) && /^[0-9]*$/.test(repeatNumbervalue))
    this.TimeEscalatevalue = (repeatNumbervalue * repeatCyclevalue) + parseInt(repeatCyclevalue); 
}

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
