import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {  
  UntypedFormGroup,  
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
  AddNewFloorForm,
} from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import {
  appsettings,
  enumFileNamelength,
  enumEnableDisable,
} from "../../../../../app-settings";
import { Subject } from "rxjs";
import { HostListener } from "@angular/core";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../../../../../api.service";
import { getCampus, getBuilding, getFloor } from "../../../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { GeneralMessageComponent } from '../../../../../general-message/general-message.component';

const URL = appsettings.URLinfo + 'uploadFloorInfoImage';
@Component({
  selector: "app-add-floor",
  templateUrl: "./add-floor.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-floor.component.scss"],
})
export class AddFloorComponent implements OnInit, OnDestroy {
  public flooraddForm: UntypedFormGroup;
  public newflooraddForm: UntypedFormGroup;
  public message: string;
  public imagePath;
  public watcher: Observable<boolean>;
  imgURL: any;  
  columns = 4;
  firstcolumns = 2;
  zoomval = 1;
  imgWidth = 200;
  height = 190;
  top = 0;
  left = 0;
  rotationAmount = 0;
  styles = '';
  downIcon = 0;
  updownArrowIcon = "keyboard_arrow_down";
  minheight = "230px";
  flooraddImgUrl = "";
  flooraddsvgUrl;
  newflooraddImgUrl;
  floorimagename;
  countofFloor;
  floorflag = 0;
  uploadflag = 0;
  IsHidden= true;
  IsbuttonHidden = true;  
  FloorName = [];
  FloorImage = [];
  Description = [];
  floorCampusId;
  floorBuildId;
  validimgurl = false;
  private _onDestroy: Subject<void> = new Subject<void>();
  floorrowHeight = "79px";
  screenWidth;
  SiteId = "1";
  addfloorbool = true;  
  FloorLength;
  isundo = 0;
  left_rightval = 0;
  top_bottomval = 0;
  zoominvalue = 1;
  undooperation = true;
  undopane = 0;
  uploadfloorimage;
  pngrotateamnt;
  selectedBuildingName;
  selectedCampusName;
  Campusdetails;
  selectedBuildingId;
  selectedCampusId;
  Buildingdetails;
  error: string;
  dragAreaClass: string;
  F_chkCampusvalue = true;
  F_chkCustomvalue = false;
  flooraddCheck_Invalue = false;
  floorCount = 0;
  floorCampusBuildName;
  floorData;
  floorDetails : any = [];
  dataSource;
  displayedColumns: string[] = [
    "FloorName",
    "UploadMap",
    "MapUploaded",    
  ];
  floorMapUploaded = 0;
  btnEnableDisableAdd = true;
  floorName;
  selectedFloorId = 0;
  addFloorflag = false;
  deleteFloorflag = false; 
  getselectedFloor; 
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddFloorComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.selectedCampusId = data.CampusId;
      this.selectedBuildingId = data.BuildId;
      this.onResize();
      this.breakpoint$.subscribe(() => this.breakpointChanged());           

      this.floorCampusId = data.campusId;
      this.floorBuildId = data.buildId; 
      this.selectedBuildingName = data.buildingname;   
      this.selectedCampusName = data.CampusName;                          
      this.selectedCampusName = data.selectCampusName;
      this.floorCount = data.floorcount;
      this.floorCampusBuildName = this.selectedBuildingName + "-" + this.selectedCampusName;
      this.floorData = data;
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - constructor() - " + err.message
      );
    }
  }
  
  breakpointChanged() {         
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }     
  }

  @ViewChild("imageInput") imageInput: any;
  public onChangefloor(fileList: FileList, element) {
    this.getselectedFloor = element.Id;
    this.saveFiles(fileList, element.Id);
  }

  saveFiles(fileList: FileList, selectedFloor) {
    if (fileList.length > 1) {
      generateAlertMessage(this.dialog, "Only one file at time allowed");
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    }
    const file = fileList[0];    
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(removedSpaces_filename);
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.imageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Zone Photo as image file."
      );
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 1, selectedFloor);
      else this.newflooraddImgUrl = "assets/images/defaultimg.png";

      this.imageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 2) {
              // 1024 is 1MB
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 2 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Zone Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: FileList = event.dataTransfer.files;
      this.saveFiles(files,this.getselectedFloor);
    }
  }

  uploadfiletoPath(data, file, type, selectedFloor) {
    this.floorMapUploaded = selectedFloor;
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const FileName = this.floorBuildId + "_" + selectedFloor + "+" + _filename;
    const campusId = this.floorCampusId;
    const buildId = this.floorBuildId;
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        this.floorimagename = res.filename;
        this.validimgurl = true;
        if (type == 1) {
          this.flooraddImgUrl = appsettings.FLOORINFO_IMAGE_FOLDER + res.filename;
          this.newflooraddImgUrl =
            appsettings.FLOORINFO_IMAGE_FOLDER + res.filename;
          this.updatesrcimageInput(this.flooraddImgUrl);
        } else {
          this.flooraddsvgUrl = appsettings.FLOORINFO_IMAGE_FOLDER + res.filename;
        }
        this.floorDetails.forEach(function (e) {
          if(e.Id == selectedFloor)
          {
            e["FileUpload"] = 1;
            e["FloorImage"] = res.filename;
            e["CampusId"] = campusId;
            e["BuildId"] = buildId;
          }
        });
        this.checkFloorData(); 
      });
  }
  
checkFloorData()
{
  let checkAddButton = false;
  const count = parseInt(this.floorDetails.length);    
  this.floorDetails.forEach(function (e) {
    e["MaxValue"] = count;
    if(e.FileUpload == 0)
      checkAddButton = true;
  });
  
  if(checkAddButton == false)
  {
    this.btnEnableDisableAdd = false;
    // this.floorAddEnableDisable(enumEnableDisable.Enable);
    const saveclick = document.getElementById('btnsaveflooraddclick');  
    if(saveclick != null)              
      saveclick.className = 'clsFlooraddbutton mat-raised-button';
  }
  if(this.deleteFloorflag == true)
  {
    this.newflooraddImgUrl = appsettings.FLOORINFO_IMAGE_FOLDER +  this.floorDetails[count-1].FloorImage; 
  }
  this.dataSource = new MatTableDataSource(this.floorDetails);
  this.dataSource.data = this.floorDetails;  
}

  @ViewChild("imagesvgInput") imagesvgInput: any;
  // public onChangefloorsvg(fileList: FileList) {
  //   const file = fileList[0];
  //   const itemType =
  //     file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
  //     file.name;
  //     const fileTypesAllowed = ["svg"];
  //   if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
  //     generateAlertMessage(
  //       this.dialog,
  //       "The file format is not supported, Please upload Zone Photo as svg file"
  //     );
  //     this.flooraddsvgUrl = "assets/images/defaultsvg.png";
  //     return;
  //   }
  //   pFileReader(fileList[0], this.dialog).then((data) => {
  //     if (data == 1) this.uploadfiletoPath(data, fileList[0], 2, );
  //     else this.flooraddsvgUrl = "assets/images/defaultsvg.png";

  //     this.imagesvgInput.nativeElement.value = "";
  //   });
  //   let self;
  //   function pFileReader(file, _dialog: MatDialog) {
  //     let checkvalidIMG = 0;
  //     return new Promise((resolve, reject) => {
  //       console.log(reject);
  //       const fileReader: FileReader = new FileReader();
  //       fileReader.onload = function (x) {
  //         console.log(x);
  //         self = fileReader.result;
  //         self = self.split("\n");
  //         if (self[0].includes("xml")) {
  //           checkvalidIMG = 1;
  //           resolve(checkvalidIMG);
  //         } else {
  //           checkvalidIMG = 2;
  //           generateAlertMessage(_dialog, "Please upload valid file");
  //           resolve(checkvalidIMG);
  //         }
  //       };

  //       fileReader.readAsText(file);
  //     });
  //   }
  // }

  ngOnInit() {
    try
    {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.flooraddsvgUrl = "assets/images/defaultsvg.png";   
      // this.countofFloor = 3;   
      this.validimgurl = false;
      const saveclick = document.getElementById('btnsaveflooraddclick');   
      if(saveclick != null)             
        saveclick.className = 'clsFlooraddbutton mat-raised-button disablebtn';
      if(this.floorCount > 0)
        this.getNewFloorDetails();
      else
        this.getFloorDetails();                           
    }
    catch(err)
    {
      throw new Error('add-floor.component.ts' + ' - ngOnInit() - ' + err.message);
    }
  }
  
getNewFloorDetails()
{       
  try 
  {
    let geojsonObject;       
    if(this.addFloorflag == false)
    {
      for(let i = 1; i <= this.floorData.floorcount; i++)
      {      
        geojsonObject = {
          FloorName: "Floor (" + i + ")",
          Id : i,
          FileUpload : 0,
          FloorId : 0,
          MinValue : 1,
          MaxValue : this.floorData.floorcount
        }
        this.floorDetails.push(geojsonObject);
      }
    }
    else
    {             
      const count = parseInt(this.floorDetails.length) + 1;        
        this.floorDetails.forEach(function(e)
        {           
          e["MaxValue"] = count;         
        })        
        geojsonObject = {
          FloorName: "Floor (" + count + ")",
          Id : count,
          FileUpload : 0,
          FloorId : 0,
          MinValue : 1,
          MaxValue : count
        }
        this.floorDetails.push(geojsonObject);
        this.btnEnableDisableAdd = true;
        const saveclick = document.getElementById('btnsaveflooraddclick');
        if(saveclick != null)
          saveclick.className = 'clsFlooraddbutton mat-raised-button disablebtn';
        this.newflooraddImgUrl = "assets/images/defaultimg.png";        
    }      
    this.dataSource = new MatTableDataSource(this.floorDetails);
    this.dataSource.data = this.floorDetails;      
  }
  catch (err) {
    throw new Error("add-floor.component.ts" + " - getNewFloorDetails() - " + err.message);
  }
}

getFloorDetails()
{
  try
  {
    const campusid: getFloor = {      
      authKey: this.authkey,
      campusId:this.floorCampusId,
      buildId:this.floorBuildId
    };
    let floorId = 0;
    let minValue = 0;
    let maxValue = 0;
    this.apiService.getFloorDetails(campusid).pipe(takeUntil(this._onDestroy)).subscribe((FloorDetails: any[]) => 
    {
      this.floorDetails = FloorDetails["apiReturn"];            
      this.floorDetails.forEach(function(e)
      { 
        floorId = e.FloorId;
        minValue = e.MinValue;
        maxValue = e.MaxValue;
        
        e["Id"] = e.FloorId;              
        e["FileUpload"] = 1;
        e["FloorId"] = floorId;
        e["FloorImage"] = e.FloorPlanImage;                 
        e["MinValue"] = minValue; 
        e["MaxValue"] = maxValue;         
      })
      this.floorCount = this.floorDetails.length; 
      this.btnEnableDisableAdd = false;      
      const saveclick = document.getElementById('btnsaveflooraddclick');
      if(saveclick != null)
        saveclick.className = 'clsFlooraddbutton mat-raised-button';       
      this.newflooraddImgUrl = appsettings.FLOORINFO_IMAGE_FOLDER + this.floorDetails[0].FloorPlanImage;
      this.dataSource = new MatTableDataSource(this.floorDetails);
      this.dataSource.data = this.floorDetails;
      console.log(this.dataSource.data)          
    });
  }
  catch (err) {
    throw new Error("add-floor.component.ts" + " - getFloorDetails() - " + err.message);
  }
} 

  getCampusDetails() {
    try {
      const campusid: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
          this.getBuildingdetails(this.selectedCampusId);
        });
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - getCampusDetails() - " + err.message
      );
    }
  }

  changecampus_addFloor(campusid, campusname) {
    this.selectedCampusId = campusid;
    this.selectedCampusName = campusname;
    this.selectedBuildingId = "";
    this.getBuildingdetails(this.selectedCampusId);
  }

  getBuildingdetails(CampusId) {
    try {
      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
      };

      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          this.Buildingdetails = BuildingDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - getBuildingdetails() - " + err.message
      );
    }
  }

  changebuilding_addFloor(buildingid, buildname) {
    this.selectedBuildingId = buildingid;
    this.selectedBuildingName = buildname;
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.newflooraddForm.controls).forEach((field) => {
        // {1}
        const control = this.newflooraddForm.get(field); // {2}
        const formFloorTargetId = document.getElementById(event.target.id);
        if (formFloorTargetId != null) {
          if (field == formFloorTargetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1280) {
      this.updownArrowIcon = "keyboard_arrow_down";
      this.downIcon = 0;
      this.thisDialogRef.updateSize("75em", "650px");
      this.minheight = "230px";
      this.columns = 4;
      this.firstcolumns = 2;
    }
    if (this.screenWidth <= 768) {
      this.floorrowHeight = "80px";
    } else if (this.screenWidth == 1097) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
      this.floorrowHeight = "79px";
    } else if (this.screenWidth == 1536) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    }
  }
  public onCloseCancel = () => {
    try {
      this.thisDialogRef.close("Cancel");
      if (!SessionCheck(this.router)) {
        return;
      }
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - onCloseCancel() - " + err.message
      );
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    try {
      return this.flooraddForm.controls[controlName].hasError(errorName);
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - hasError() - " + err.message
      );
    }
  };

  newAddFloor() {
    try 
    {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      const saveclick = document.getElementById('btnsaveflooraddclick');
      if(this.floorName == "" && this.floorName != undefined)
      {
        generateAlertMessage(this.dialog, "Please Enter Floor Name for Floor " + this.selectedFloorId);              
        if(saveclick != null)
            saveclick.className = 'clsFlooraddbutton mat-raised-button disablebtn';
        return;
      }
      else if (!/^[a-zA-Z0-9\s][a-zA-Z0-9!-@#$%^& ]+$/.test(this.floorName) && this.floorName != undefined)     
      {
        generateAlertMessage(this.dialog, "Please Enter valid Floor Name for Floor " + this.selectedFloorId); 
        if(saveclick != null)             
          saveclick.className = 'clsFlooraddbutton mat-raised-button disablebtn';
        return;
      }
      else 
      {       
        if(saveclick != null) 
          saveclick.className = 'clsFlooraddbutton mat-raised-button';
        this.onSaveAddFloorDetails();
      }
      
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - newAddFloor() - " + err.message
      );
    }
  }

  onSaveAddFloorDetails() {     

    const isDuplicate = this.hasDuplicate(this.floorDetails,"FloorName");
    if (isDuplicate)
        generateAlertMessage(this.dialog, "Floor name '" + this.floorName + "' already exists.");
    else
    {
      const floordetails: AddNewFloorForm = {        
        authKey: this.authkey,
        floorJsondata : JSON.stringify(this.floorDetails)
      };
      this.addFloor(floordetails);
    }
  }

  //Add zone is used to add a new zone in the selected building. When will click the Add new zone it will show the popup window with the following option
  //1. Scratch
  addFloor = (addfloordetails) => 
  {

        try
        {
          (document.activeElement as HTMLTextAreaElement).blur();
          
          // if (this.newflooraddForm.valid  && this.addfloorbool) 
          // {
              this.addfloorbool = false;
              this.floorAddEnableDisable(enumEnableDisable.Disable);
              this.technologyoverlayService.addFloor(addfloordetails).pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse: any[]) => {                        
                if (apiresponse["apiReturn"] == "Success") {
                  this.addfloorbool = true;
                  generateAlertMessage(this.dialog, "Floor added successfully.");
                  this.thisDialogRef.close("Confirm");
                } 
                if (apiresponse["FloorId"] > 0) {
                  this.addfloorbool = true;
                  generateAlertMessage(this.dialog, "Floor edited successfully.");
                  this.thisDialogRef.close("Confirm");
                } 
                else if(apiresponse["apiReturn"] == "Fail")
                {
                  this.addfloorbool = true;  
                  generateAlertMessage(this.dialog, 'Zone added failed.');               
                  this.thisDialogRef.close('Confirm');
                }              
                else if(apiresponse["apiReturn"] == "Invalid Auth Key")
                {    
                    this.thisDialogRef.close('Cancel');              
                    AuthFailedMessage(this.dialog,this.router);  
                    return;       
                }
                // else if(apiresponse["condition"] == "floorName")
                // {
                //   this.addfloorbool = true;  
                //   generateAlertMessage(this.dialog, 'Please enter Zone Name');                  
                //   saveclick.className = 'clsFlooraddbutton mat-raised-button'; 
                //   return; 
                // }
                else
                {
                  this.addfloorbool = true;  
                  generateAlertMessage(this.dialog, "Floor name '" + apiresponse["floorList"][0].FloorName + "' already exists.");
                  this.floorAddEnableDisable(enumEnableDisable.Enable);   
                  return;
                }
              });
          // } 
        }
        catch(err)
        {
            throw new Error('add-floor.component.ts' + ' - addFloor() - ' + err.message);
        }

  }

  rotateImage(direction: string) {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 1;
      if (direction === "left") {
        const flag = this.imgWidth;
        this.imgWidth = this.height;
        this.height = flag;
        this.rotationAmount = this.rotationAmount + -90;
        if (this.rotationAmount == -360) {
          this.rotationAmount = 0;
        }

        if (this.rotationAmount === -180 || this.rotationAmount === 0) {
          this.top = 0;
          this.left = 0;
        } else {
          this.top = ((this.imgWidth - this.height) / 2) * -1;
          this.left = (this.imgWidth - this.height) / 2;
        }
      } else if (direction === "right") {
        this.rotationAmount = this.rotationAmount + 90;
        if (this.rotationAmount == 360) {
          this.rotationAmount = 0;
        }
      }
      this.pngrotateamnt = this.rotationAmount;
      //this.styles = 'rotate(' + this.rotationAmount + 'deg)';
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - rotateImage() - " + err.message
      );
    }
  }

  removeImage() {
    try {
      this.undooperation = true;
      this.isundo = 8;
      this.uploadfloorimage = this.newflooraddImgUrl;
      this.newflooraddImgUrl = "assets/images/defaultimg.png";
      this.flooraddsvgUrl = "assets/images/defaultsvg.png";
      this.validimgurl = false;
      this.rotationAmount = 0;
      this.zoomval = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
      // this.styles =  'rotate(' + this.rotationAmount + 'deg)';
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - removeImage() - " + err.message
      );
    }
  }

  zoomoutImage() {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 2;
      if (this.zoomval > 1) this.zoomval = this.zoomval - 0.5;
      // this.styles = 'scale(' + this.zoomval + ')';
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - zoomoutImage() - " + err.message
      );
    }
  }

  zoomInImage() {
    try {
      this.undooperation = true;
      this.undopane = 0;
      this.isundo = 3;
      if (this.zoomval < 5) this.zoomval = this.zoomval + 0.5;
      this.zoominvalue = this.zoomval;
      //this.styles = 'scale(' + this.zoomval + ')';
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - zoomInImage() - " + err.message
      );
    }
  }

  resetformtext() {
    try {
      this.undooperation = false;
      this.rotationAmount = 0;
      //this.styles =  'rotate(' + this.rotationAmount + 'deg)';
      this.zoomval = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - resetformtext() - " + err.message
      );
    }
  }

  undoimage() {
    try {
      if (this.undooperation == true) {
        if (this.isundo == 1) {
          if (this.undopane == 0) {
            if (this.rotationAmount != 0)
              this.rotationAmount = this.rotationAmount - 90;
            else this.rotationAmount = 270;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 2) {
          if (this.undopane == 0) {
            if (this.zoomval < this.zoominvalue)
              this.zoomval = this.zoomval + 0.5;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 3) {
          if (this.undopane == 0) {
            if (this.zoomval > 1) this.zoomval = this.zoomval - 0.5;
          } else if (this.undopane == 1) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval - 10;
          } else if (this.undopane == 2) {
            if (this.rotationAmount == 90)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 180)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 270)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 0)
              this.left_rightval = this.left_rightval + 10;
          } else if (this.undopane == 3) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else if (this.undopane == 4) {
            if (this.rotationAmount == 90)
              this.left_rightval = this.left_rightval - 10;
            else if (this.rotationAmount == 180)
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.rotationAmount == 270)
              this.left_rightval = this.left_rightval + 10;
            else if (this.rotationAmount == 0)
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 4) {
          if (this.left_rightval != 0)
            this.left_rightval = this.left_rightval - 10;
        } else if (this.isundo == 5) {
          if (this.left_rightval != 0)
            this.left_rightval = this.left_rightval + 10;
        } else if (this.isundo == 6) {
          if (this.top_bottomval != 0)
            this.top_bottomval = this.top_bottomval + 10;
        } else if (this.isundo == 7) {
          if (this.top_bottomval != 0)
            this.top_bottomval = this.top_bottomval - 10;
        } else if (this.isundo == 8) {
          this.newflooraddImgUrl = this.uploadfloorimage;
          this.rotationAmount = this.pngrotateamnt;
          this.validimgurl = true;
        }
      }

      this.undooperation = false;
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - undoimage() - " + err.message
      );
    }
  }

  leftImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 1;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 4;
    }
  }
  rightImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 2;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 5;
    }
  }
  bottomImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 3;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 6;
    }
  }
  topImage() {
    if (this.rotationAmount == 0) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 90) {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.rotationAmount == 180) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.rotationAmount == 270) {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.undopane = 4;
    if (this.isundo != 1 && this.isundo != 2 && this.isundo != 3) {
      this.isundo = 7;
    }
  }

  panAlign(IsAdd, IsTopBottom) {
    let IsChk = false;
    if (IsAdd == panIsAdd.Add && IsTopBottom == panIsTopBottom.TopBottom) {
      if (this.top_bottomval < 70) {
        this.top_bottomval = this.top_bottomval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.TopBottom
    ) {
      if (this.top_bottomval > -70) {
        this.top_bottomval = this.top_bottomval - 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Add &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval < 70) {
        this.left_rightval = this.left_rightval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval > -70) {
        this.left_rightval = this.left_rightval - 10;
        IsChk = true;
      }
    }
    return IsChk;
  }

  selectFloor(Event) {
    try {
      this.countofFloor = parseInt(Event);
      this.IsHidden = false;
      this.IsbuttonHidden = false;
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - selectFloor() - " + err.message
      );
    }
  }

  clsimageInput(value) {
    try {
      this.floorflag = value;
      const element = document.getElementById("imageInput") as HTMLInputElement;
      element.click();
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - clsimageInput() - " + err.message
      );
    }
  }

  updatesrcimageInput(floorName) {
    try {
      for (let i = 0; i < this.countofFloor; i++) {
        if (this.floorflag == i + 1) {
          const result = document.getElementById("imageurl" + [i + 1]);
          const MapImageName = document.getElementById( "floormapimage" + [i + 1]) as HTMLInputElement;
          MapImageName.value = this.floorimagename;
          if(result != null)
            result.setAttribute("src", floorName);
        }
      }
    } catch (err) {
      throw new Error(
        "add-floor.component.ts" + " - updatesrcimageInput() - " + err.message
      );
    }
  }

  togglefloorCamVisibility(event) {
    if (event.checked == true) {
      this.F_chkCampusvalue = true;
      this.F_chkCustomvalue = false;
    } else {
      this.F_chkCustomvalue = false;
      this.F_chkCampusvalue = false;
    }
  }

  togglefloorCusVisibility(event) {
    if (event.checked == true) {
      this.F_chkCustomvalue = true;
      this.F_chkCampusvalue = false;
    } else {
      this.F_chkCampusvalue = false;
      this.F_chkCustomvalue = false;
    }
  }

  toggleAddCheckInVisibility(event) {
    if (event.checked == true) {
      this.flooraddCheck_Invalue = true;
    } else {
      this.flooraddCheck_Invalue = false;
    }
  }
  
  changeFloorName(clkevent, Id) {     
    this.floorName = clkevent.target.value;
    this.floorName = this.floorName.trim();  
    this.selectedFloorId = Id;              
  }
  
  focusOutFloorName(clkevent,eventId)
  {    
    let floorName = clkevent.target.value;
        floorName = floorName.trim();         
    if (!/^[a-zA-Z0-9\s][a-zA-Z0-9!-@#$%^& ]+$/.test(floorName)) 
    {
      generateAlertMessage(this.dialog,"Please Enter valid Floor Name");      
      return false;
    }     
    
    this.floorDetails.forEach(function(e)
    {       
      if((eventId == e.FloorId && e.FloorId > 0) || (e.FloorId == 0 && eventId == e.Id))      
        e["FloorName"] = floorName;               
    })          
  }

  floorAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsaveflooraddclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsFlooraddbutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsFlooraddbutton mat-raised-button disablebtn";
  }
  
  onclickFloorName(Id,FloorId)
  {
    let fileImageName = ""; 
    this.floorDetails.forEach(function(e)
    {       
      if((FloorId == e.FloorId && e.FloorId > 0)|| (Id == e.Id && e.FloorId == 0 && e.FileUpload == 1))      
        fileImageName = appsettings.FLOORINFO_IMAGE_FOLDER + e.FloorImage;         
      else if(Id == e.Id && e.FloorId == 0 && e.FileUpload == 0)
        fileImageName = "assets/images/defaultimg.png";         
    })
    this.newflooraddImgUrl = fileImageName;
  }
  
  addFloorDetails()
  {    
    this.addFloorflag = true;
    this.floorData.floorcount = parseInt(this.floorData.floorcount) + 1;
    this.floorCount = this.floorData.floorcount;
    this.getNewFloorDetails();
  }
  
  hasDuplicate(arrayObj, colName)
  {
    const hash = Object.create(null);
    return arrayObj.some((arr) => {
      return arr[colName] && (hash[arr[colName]] || !(hash[arr[colName]] = true));
    })
  }
  
  deleteselectFloorDetails(selectFloorId) {
    try 
    {    
      const GeneralMessageData = {
        General_header:  "Delete Floor ",                
        GeneralMessage:  "Are you sure you want to delete this floor?",      
        General_first:  "Yes ",
        General_second:  "No"
      }     
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: 'auto',
        height: '220px',
        panelClass: 'myapp-no-padding-dialog',
        autoFocus: false,
        data: GeneralMessageData
      });    
      
      dialogRef.afterClosed().subscribe((result) => {
        if(result == "First")
        {                                               
          const getFloorData = [] as any[];
          this.floorDetails.forEach(function (e) {
            if(e.Id != selectFloorId)
            {
              getFloorData.push(e);                   
            }
          });
          this.floorDetails = getFloorData;
          this.floorCount = this.floorDetails.length;
          this.deleteFloorflag = true;
          this.checkFloorData();
          // this.dataSource = new MatTableDataSource(this.floorDetails);
          // this.dataSource.data = this.floorDetails;  
        } 
        else 
        {
          dialogRef.close("Cancel");
          return;
        }
      });
    } 
    catch (err) {
      throw new Error("add-floor-component.ts" + " - deleteFloorDetails() - " + err.message
      );
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

enum panIsAdd {
  Add = 0,
  Sub = 1,
}
enum panIsTopBottom {
  TopBottom = 0,
  LeftRignt = 1,
}
