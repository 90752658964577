<app-wander-header></app-wander-header>
<mat-card class="home-mat-card" (click)="CloseRightNav()">
<div class="mat-elevation-z0" style="margin-top:0px;" id="divwanderdetails" *ngIf="wanderShow">

<div  class="landing-title-bar">
   <!-- <div fxLayout="column" fxLayoutGap="0px" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" class="wandercontent" fxLayoutWrap>
        <div class="blocks">                           
            <button id="btnaddwander" color="primary" class="clswanderaddbutton" style="margin-right:7em;float:right;" mat-raised-button (click)="addWanderpreventiondata()" *ngIf="bAddEdit == true">Add</button> 
        </div>
   </div>        -->
   
   <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutGap="5px" fxLayoutWrap >
    <div fxFlex="15" fxFlex.md="20" class="blocks" style="padding-top:5px;"> 
        <span class="clsTitle">Total Roaming : </span>
        <span class="clsTitle1" id="residentcount">{{dataSourcecount}}</span>   
     </div>    
     <div fxFlex="20"> 
        <mat-form-field appearance="outline" floatLabel="auto">
            <input style="height:20px;" matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchWanderPrevention($event.target.value)" autocomplete="off" placeholder="Search">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>    
     </div>
     <div fxFlex="55" class="blocks" fxLayout.md="row" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutWrap>              
        <button id="btnaddwander" class="clswanderaddbutton" color="primary" mat-raised-button (click)="addWanderpreventiondata()" *ngIf="bAddEdit == true">Add</button>        
        <button id="btnaddwander" class="clswanderRulesbutton" color="primary" mat-raised-button (click)="showRoamingRules(1)"><mat-icon style="margin:0px 5px 3px 0px;">info icon</mat-icon>Roaming Rules</button>
     </div>
  </div>
   
</div>

<perfect-scrollbar class="scrollbar">
<div *ngIf="dataSourcecount; else noRecord">
  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear=true>    
    
      <!-- Weight Column -->
      <ng-container matColumnDef="ResidentGroupName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Resident/Group Name </th>
        <td mat-cell *matCellDef="let element"> 
        <span *ngIf="element.ResidentGroupName.length < 30">{{element.ResidentGroupName}}</span>
        <span *ngIf="element.ResidentGroupName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.ResidentGroupName}} #tooltip="matTooltip" matTooltipPosition="above">
           {{element.ResidentGroupName.slice(0,20) + ".."}}
        </span> 
          </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="ResidentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.ResidentType}} </td>
      </ng-container>
      
       <!-- Symbol Column -->
      <ng-container matColumnDef="RoomName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Un-safe Zones/Spaces</th>       
           <td mat-cell *matCellDef="let element" style="word-break: break-all;"> 
             <!-- <span *ngIf="element.RoomName.length < 30">{{element.RoomName.slice(0,20) + ".."}} </span>
             <span *ngIf="element.RoomName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.RoomName}} #tooltip="matTooltip" matTooltipPosition="above"> -->
              {{element.RoomName}}
           <!-- </span> -->
          </td>
      </ng-container>
      
      <!-- Symbol Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef style="width:50px;"> Edit </th>
        <ng-container *matCellDef="let element">      
        <td mat-cell><img src="assets/images/Edit_Icon.png" style="cursor:pointer;" (click)="editWanderdata(element.SafeZoneId)"></td> <!----[routerLink]="['/editresident']"-->        
      </ng-container>
      </ng-container>
    
     <!-- Symbol Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef  style="width:30px;"> Delete </th>
        <ng-container *matCellDef="let element">    
        <td mat-cell> <img src="assets/images/Release-Delete-Icon.png" style="margin-left:1em;cursor:pointer;" (click)="deleteWanderdata(element.SafeZoneId)"> </td>       
      </ng-container></ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
</perfect-scrollbar>
<ng-template #noRecord>  
  <mat-card>
      <h3 *ngIf="!wanderloading" style="text-align: center;"> No Record found </h3> 
      <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%">       
          <div class="loadspinner" *ngIf="wanderloading">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
          </div>
      </div>
  </mat-card>
</ng-template>

<mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
</div>

<!------------------------Roaming Rules-------------------------------->
<div *ngIf="!wanderShow">
  <div class="clsDivRules">
    <table>
    <tr style="background-color: #ffffff;">
      <td class="clsRulesHeader">Rules:</td>           
    </tr><br>      
    
    <tr style="background-color: #ffffff;">
      <td class="clsRulesHeader">All Spaces Categorized as "Resident Room" (Wrong Room)</td>
    </tr>
    
    <tr style="background-color: #ffffff;">    
      <td class="clsRulestext">(i) Rule 1: If Resident detected in a Resident "Space" that is not associated to this Resident Record for > 180 Seconds, then, post "Roaming Alert"<br>      
        (ii) Rule 2: If Staff is in same space within this same time, the Alert will not post or will auto-clear.<br>
        (iii) Rule 3: If Resident is detected in a different Space at any time within 180 seconds, do not post the alert<br>
        (iv) Rule 4: If Resident is detected in a different Space at any time after 180 seconds, auto-clear the alarm.
      </td>
    </tr><br>
    
    <tr style="background-color: #ffffff;">
      <td class="clsRulesHeader">All Spaces Categorized as "Egress"</td>
    </tr>
    
    <tr style="background-color: #ffffff;">    
      <td class="clsRulestext">(i) Rule 1: If Resident is seen in any "Egress" Space Category ever [no delay or wait outside Rule #2], then post Roaming Alert.<br>      
        (ii) Rule 2: If Staff is in same space within this same time, the Alert will not post OR will auto-clear.<br>
        (iii) Rule 3: If Resident is detected in a different Space at any time after 60 seconds, auto-clear the alarm<br>      
      </td>
    </tr><br>
    
    <tr style="background-color: #ffffff;">
      <td class="clsRulesHeader">Spaces designated as "Resident Off Limits"</td>
    </tr>
    
    <tr style="background-color: #ffffff;">    
      <td class="clsRulestext">(i) These spaces cannot be de-selected within this screen, User must go to Edit the Space within Technology Overlay.<br>      
        (ii) Rule 2: If Resident is seen in any "Resident Off Limits" Space Category ever, then post Roaming Alert without delay.<br>      
      </td>
    </tr><br>
    
    <tr style="background-color: #ffffff;">
      <td class="clsRulesHeader">All Other Spaces Categories Not Designated as "Resident Off Limits"</td>
    </tr>
    
    <tr style="background-color: #ffffff;">    
      <td class="clsRulestext">(i) Rule 1: If Resident detected in any of these spaces for >60 Seconds, then post "Roaming Alert."<br>      
        (ii) Rule 2: If Staff is in same space within this same time, the Alert will not post OR will auto-clear.<br>
        (iii) Rule 3: If Resident is detected in a different Space at any time within 60 Seconds, do not post the alert<br>
        (iv) Rule 4: If Resident is detected in a different Space at any time after 60 seconds, auto-clear the alarm.
      </td>
    </tr><br>
    </table>
  </div>

  <div class="blocks" fxLayout.md="row" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutWrap style="margin-top:5em;">                      
    <button id="btnaddwander" class="clswanderaddbutton" color="primary" mat-raised-button (click)="showRoamingRules(2)">Exit</button>          
  </div>
</div>
<!---------------------------End----------------------------->

</mat-card>