import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./confirm-message.component.scss']
})
export class ConfirmMessageComponent implements OnInit {
  ConfirmMessage = "";
  Confirm_header = "";
  constructor(public thisDialogRef: MatDialogRef<ConfirmMessageComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {   
    if(data.ConfirmMessage != undefined)
    {
      this.Confirm_header = 'Confirm Message';
      this.ConfirmMessage = data.ConfirmMessage;
    }
      else
      {
        this.Confirm_header = data.header;
        this.ConfirmMessage = data.ConfirmMessageData.ConfirmMessage;
      }
  }

  ngOnInit() {
    console.log("confirm-message.component - ngOnInit");
  }
  // close Confirm Notification Dialog
  public onCloseConfirmMessage = () => {
    this.thisDialogRef.close('Yes');
  }
  public onCloseCancel = () => {     
    this.thisDialogRef.close('No');   
  }
}

