import { Injectable, Inject, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../app-settings";
import { alertinfo, StaffData, showLiveLocation } from "./home";
import {
  SharedCheckAlertData,
  staffresidentdata,
  NearestStaffdata,
  getResStaffCurrentLocation,
} from "../../api";
import {
  DrawLocation,
  groupBy,
  set_IsLocationReportChange,
  getFeatureNeedtoShow,
} from "../../app-general";
import { RollbarService } from "../../rollbar";
import * as Rollbar from "rollbar";
import * as socketIo from "socket.io-client";
import { AlertSharedService } from "../../_services/index";
import { AppComponent } from "../../app.component";
import { ApiService } from "../../api.service";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  public toggleHomeMenuNav: EventEmitter<any> = new EventEmitter();

  showLiveLocationUrl = appsettings.API_ENDPOINT + "staffs/showLiveLocation";

  getNotificationByAlertUrl =
    appsettings.API_ENDPOINT + "maps/getNotificationByAlert";
  renotifyAlertUrl = appsettings.API_ENDPOINT + "maps/renotifyAlert";
  getRenotifyDataUrl = appsettings.API_ENDPOINT + "maps/getRenotifyData";
  getNearestStaffForAlertUrl =
    appsettings.API_ENDPOINT + "maps/getNearestStaffForAlert";
    
  getMapDetailsUrl = appsettings.API_ENDPOINT+'technologyoverlays/getHomeMapDetails';
  getOutdoorSpacesUrl=  appsettings.API_ENDPOINT+'technologyoverlays/getOutdoorSpaces';

  
  alertinfo: alertinfo[];
  CheckAlertData: SharedCheckAlertData;
  _staffresidentinfo;  
  constructor(
    private http: HttpClient,
    public alertSharedService: AlertSharedService,
    public apiService: ApiService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  getRenotifyData(data): Observable<StaffData[]> {
    return this.http.post<StaffData[]>(this.getRenotifyDataUrl, data);
  }

  getNotificationByAlert(data): Observable<StaffData[]> {
    return this.http.post<StaffData[]>(this.getNotificationByAlertUrl, data);
  }

  getNearestStaffForAlert(item): Observable<NearestStaffdata[]> {
    return this.http.post<NearestStaffdata[]>(
      this.getNearestStaffForAlertUrl,
      item
    );
  }

  renotifyAlert(item): Observable<NearestStaffdata[]> {
    return this.http.post<NearestStaffdata[]>(this.renotifyAlertUrl, item);
  }

  showLiveLocation(LiveItem): Observable<staffresidentdata[]> {
    return this.http.post<staffresidentdata[]>(
      this.showLiveLocationUrl,
      LiveItem
    );
  }
  
  getMapDetails(mapdata): Observable<StaffData[]> { 
    return this.http.post<StaffData[]>(this.getMapDetailsUrl,mapdata) 
  }

  getOutdoorSpaces(data): Observable<StaffData[]> {    
    return this.http.post<StaffData[]>(this.getOutdoorSpacesUrl, data)
  }  

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      throw new Error(
        "home.service.ts - " + operation + "() - " + error.message
      );
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // Map Live
  isMapLivetpushSocketDownloaded = 0;

  SubscribeforLocationChangeData() {
    try {
      const _apiService = this.apiService;
      const authkey = localStorage.getItem("Authkey") ?? "";
      const liveitem: showLiveLocation = {
        authKey: authkey,
      };
      this.showLiveLocation(liveitem).subscribe((LiveresponseInfo: any[]) => {
        console.log(LiveresponseInfo);
        if (getFeatureNeedtoShow(
            enumUIFeatureView.Location,
            enumUIAction.Location_RTLS_Staff
          ) == enumUIView.Show ||
          getFeatureNeedtoShow(
            enumUIFeatureView.Location,
            enumUIAction.Location_RTLS_Resident
          ) == enumUIView.Show
        ) {
          const _data: getResStaffCurrentLocation = {
            authKey: authkey,
          };
          _apiService
            .getStaffResidentCurrentLocation(_data)
            .subscribe((staffresidentinfo: staffresidentdata[]) => {
              _apiService
                .getAllRooms(_data)
                .subscribe((roomdetailsdatainfo: any[]) => {
                  const RoomDetailsinfo = groupBy(
                    roomdetailsdatainfo,
                    "RoomId"
                  );
                  this.alertSharedService.changeSharedRoomDetailsBased(
                    RoomDetailsinfo
                  );

                  this.alertSharedService.changeSharedResidentStaffBased(
                    staffresidentinfo
                  );

                  const ResidentsStaff = groupBy(staffresidentinfo, "RoomId");
                  this.alertSharedService.changeResidentStaff(ResidentsStaff);

                  this.alertSharedService
                    .currentSharedResidentStaffBased()
                    .subscribe((SharedResidentStaffBasedDetails) => {
                      this._staffresidentinfo = SharedResidentStaffBasedDetails;
                    });

                  if (this.isMapLivetpushSocketDownloaded == 0) {
                    this.ConnectLocationChangeSocket();
                    this.isMapLivetpushSocketDownloaded = 1;
                  } else {
                    this.ConnectLocationChangeSocket();
                  }
                });
            }); // end
        }
      });
    } catch (err) {
      throw new Error(
        "home.service.ts" +
          " - SubscribeforLocationChangeData() - " +
          err.message
      );
    }
  }

  /** connect to socket */
  ConnectLocationChangeSocket() {
    try {
      const userChannel = "LTCEventData";
      const usersiteid = 2222;      

      /** connect to socket url with option */
      //this.mapsocket = socketIo('http://' + appsettings.SOCKET_IP + ':3001/', { secure: true, 'forceNew': true, query: "registrationKey=3A3CD974A53E49ba&siteid=" + usersiteid + "&userChannel=" + userChannel });
      console.log("socketUrl home :" + AppComponent.socketUrl);
      AppComponent.mapsocket = socketIo(AppComponent.socketUrl + "/", {
        secure: true,
        forceNew: true,
        query:
          "registrationKey=3A3CD974A53E49ba&siteid=" +
          usersiteid +
          "&userChannel=" +
          userChannel,
      });

      /** connect to socket */
      AppComponent.mapsocket.on("connect", (data) => {
        console.log(data + " data");
        console.log(userChannel + " Connected");
        AppComponent.mapsocket.emit("subscribe", { channel: userChannel });
      });

      /** reconnect to socket */
      AppComponent.mapsocket.on("reconnecting", function (data) {
        console.log(data + " data");
        console.log("reconnecting");
      });

      /** Referesh the alertlist when socket emit */
      AppComponent.mapsocket.on("message", onLocationChangeEmit);
    } catch (err) {
      throw new Error(
        "home.service.ts" + " - ConnectLocationChangeSocket() - " + err.message
      );
    }

    /** Refresh the alertlist when socket emit */
    function onLocationChangeEmit(data) {
      try {
               
        set_IsLocationReportChange(1, this.alertSharedService);

        let Residents;
        Residents = AppComponent.SharedResidentStaffDetails;

        let RoomDetails;
        RoomDetails = AppComponent.SharedRoomBasedDetails;

        let _gStaffResidentData = AppComponent.SharedResidentStaffBasedDetails;

        if (data != null) {
          if (data != "") {
            const jObj = JSON.parse(data);

            if (jObj != null) {
              const tagId = jObj["Value"]["TagId"];

              const objstaffresident = _gStaffResidentData.filter(function (
                e1
              ) {
                return e1.TagId == tagId;
              });
              const authkey = localStorage.getItem("Authkey") ?? "";
              if (objstaffresident.length == 0) {
                const _data: getResStaffCurrentLocation = {
                  authKey: authkey,
                };
                this.apiService
                  .getStaffResidentCurrentLocation(_data)
                  .subscribe((staffresidentinfo: staffresidentdata[]) => {
                    this.apiService
                      .getAllRooms(_data)
                      .subscribe((roomdetailsdatainfo: any[]) => {
                        const RoomDetailsinfo = groupBy(
                          roomdetailsdatainfo,
                          "RoomId"
                        );
                        this.alertSharedService.changeSharedRoomDetailsBased(
                          RoomDetailsinfo
                        );

                        this.alertSharedService.changeSharedResidentStaffBased(
                          staffresidentinfo
                        );

                        const ResidentsStaff = groupBy(
                          staffresidentinfo,
                          "RoomId"
                        );
                        this.alertSharedService.changeResidentStaff(ResidentsStaff);

                        Residents = AppComponent.SharedResidentStaffDetails;
                        RoomDetails = AppComponent.SharedRoomBasedDetails;
                        _gStaffResidentData =
                          AppComponent.SharedResidentStaffBasedDetails;

                        loadliveData(
                          data,
                          this.alertSharedService,
                          Residents,
                          RoomDetails,
                          _gStaffResidentData
                        );
                      });
                  }); // end
              } else {
                loadliveData(
                  data,
                  this.alertSharedService,
                  Residents,
                  RoomDetails,
                  _gStaffResidentData
                );
              }
            }
          }
        }
      } catch (err) {
        throw new Error(
          "home.service.ts" + " - onLocationChangeEmit() -" + err.message
        );
      }
    }
  }
}

function loadliveData(
  data,
  _sharedService,
  Residents,
  RoomDetails,
  _gStaffResidentData
) {
  const jObj = JSON.parse(data);
  if (jObj != null) {
    const roomId = jObj["Value"]["RoomId"];
    let lastRoomId = jObj["Value"]["LastRoomId"];
    const tagId = jObj["Value"]["TagId"];
    const lastRoomUpdatedOn = jObj["Value"]["LastRoomUpdatedOn"];
    const updatedOn = jObj["Value"]["UpdatedOn"];
    let lastRoomName = "";

    let lastRoomDeviceType = "";
    if (RoomDetails[lastRoomId] != null) {
      lastRoomName = RoomDetails[lastRoomId][0].RoomName;
      lastRoomDeviceType = RoomDetails[lastRoomId][0].DeviceTypeId;
    }

    let preRoomName = "";
    let RoomDeviceTypeId = 1;
    if (RoomDetails[roomId] != null) {
      preRoomName = RoomDetails[roomId][0].RoomName;
      RoomDeviceTypeId = RoomDetails[roomId][0].DeviceTypeId;
    }

    if (lastRoomName == "") {
      lastRoomName = preRoomName;
    }

    if (lastRoomId == null || lastRoomId == 0) lastRoomId = roomId;
    let objstaffresident;
    let _objstaffresident;

    
    if (_gStaffResidentData != null) {
      _gStaffResidentData = _gStaffResidentData.filter(function (e1) {
        if (e1.TagId == tagId) {
          e1.RoomId = roomId + "_" + RoomDeviceTypeId;
          e1.LastRoomId = lastRoomId;
          e1.UpdatedTime = updatedOn;
          e1.LastRoomName = lastRoomName;
          e1.RoomName = preRoomName;
    
        }
        return e1.TagId;
      });
      objstaffresident = _gStaffResidentData.filter(function (e1) {
        return e1.TagId == tagId;
      });

      _sharedService.changeSharedResidentStaffBased(_gStaffResidentData);

      const ResidentsStaff = groupBy(_gStaffResidentData, "RoomId");

      if (ResidentsStaff[lastRoomId + "_" + lastRoomDeviceType] != null) {
        for (
          let x = 0;
          x < ResidentsStaff[lastRoomId + "_" + lastRoomDeviceType].length;
          x++
        ) {
          if (
            ResidentsStaff[lastRoomId + "_" + lastRoomDeviceType][x].TagId ==
            tagId
          ) {
            ResidentsStaff[lastRoomId + "_" + lastRoomDeviceType][x].splice(
              x,
              1
            );
            break;
          }
        }
      }
      _sharedService.changeResidentStaff(ResidentsStaff);

      _objstaffresident = JSON.parse(JSON.stringify(objstaffresident));
      /*if (objstaffresident != null) {
        if (objstaffresident.length > 0) {
          Photo = objstaffresident[0].Photo;
          Type = objstaffresident[0].Type;
        }
      }*/

      _objstaffresident[0].PreRoomId = parseInt(roomId);
      _objstaffresident[0].LastRoomId = parseInt(lastRoomId);
      _objstaffresident[0].LastRoomUpdatedOn = lastRoomUpdatedOn;
      _objstaffresident[0].DeviceType = RoomDeviceTypeId;

      DrawLocation(_sharedService, _objstaffresident[0]); //lastRoomId, roomId, photo , tagId, lastRoomUpdatedOn
    }
  }
}
