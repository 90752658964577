<perfect-scrollbar style="max-width: 1200px; max-height: 640px;" >
  <div class="clsaddCampus">
      <div class="addfloortemp-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addfloortempHeader" style="margin: 0 0 10px 11px;">Save Zone as Template
        <span><img class="clsfloortempcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="floorTempateForm" autocomplete="off" novalidate [style.height]="minheight" (ngSubmit)="templatefloorDetails(floorTempateForm.value)">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div>   
                
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                    
                    <mat-grid-tile class="clstempmatgrid">
                      <label>Selected Zone</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clstempmatgridtxt">
                          <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                             <input matInput placeholder="Zone Name" formControlName="floorname" maxlength="32" [(ngModel)]="data.FloorName" (keyup)="handleInput($event)" required/>                            
                             <input type="hidden" formControlName="campusID" [(ngModel)]="data.CampusId">   
                             <input type="hidden" formControlName="buildID" [(ngModel)]="data.BuildId">
                             <input type="hidden" formControlName="floorID" [(ngModel)]="data.FloorId">
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>                       

                    <mat-grid-tile class="clstempmatgrid">
                      <label>New Template<br>Name</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clstempmatgridtxt">
                        <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                            <input matInput placeholder="Template Name" maxlength="32" formControlName="newtempname" (keyup)="handleInput($event)">
                            <mat-error *ngIf="floorTempateForm.controls.newtempname.hasError('required')">Please enter New Template Name</mat-error>
                            <mat-error *ngIf="floorTempateForm.controls.newtempname.hasError('pattern')">Please enter valid New Template Name</mat-error>
                            <mat-error *ngIf="floorTempateForm.controls.newtempname.hasError('maxlength')">Max length 32 characters only</mat-error>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>   
                  
                  <mat-grid-tile class="clstempmatgrid">
                    <label>Description</label>
                </mat-grid-tile>
                <mat-grid-tile class="clstempmatgridtxt">
                      <div class="text-inside">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                           <input matInput placeholder="Description" maxlength="32" formControlName="description" (keyup)="handleInput($event)">
                           <mat-error *ngIf="floorTempateForm.controls.description.hasError('required')">Please enter Description</mat-error>
                           <mat-error *ngIf="floorTempateForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                           <mat-error *ngIf="floorTempateForm.controls.description.hasError('maxlength')">Max length 32 characters only</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>                                  

                </mat-grid-list>                                  
                
                <div style="margin-top:1em;">
                  <label>Select the spaces and devices you'd like to save in this Zone:</label>
                    <mat-grid-list [cols]="columns" [rowHeight]="rowHeight" class="clstempmatgridlist">
                      
                          <perfect-scrollbar style="max-width: 1200px; max-height: 200px;" >
                            <div class="text-inside" style="margin-top:1em;" *ngIf="SpaceInfo.length > 0">                                                                            
                              <mat-checkbox aria-label="Select All" 
                              class="clscheckbox clsenable" id="chkresident" 
                              [checked]="isCheckedAll" 
                              (change)="$event ? toggleAll($event) : null" style="font-size: 14px;">Select All</mat-checkbox>
                            </div> 
                            <div style="margin:1em;">
                              <label style="font-size: medium;">Spaces</label>
                            </div>  
                            <div *ngFor="let Space of SpaceInfo;">
                              <div class="text-inside" style="margin-top:1em;" *ngIf="Space.UnitName.length < 15">                                                                            
                                <mat-checkbox class="clscheckbox clsenable" [checked]="exists(Space.UnitId)" [id]="'chkunit_'+Space.UnitId" (click)="$event.stopPropagation()"
                                (change)="$event ? toggleresVisibility(Space.UnitId,$event) : null" style="font-size: 14px;">
                                {{Space.UnitName}}, {{Space.DeviceZoneCount}} Devices</mat-checkbox>
                              </div>                                
                              <div class="text-inside" style="margin-top:1em;" *ngIf="Space.UnitName.length >= 15">                                                                            
                                <mat-checkbox class="clscheckbox clsenable" [checked]="exists(Space.UnitId)" [id]="'chkunit_'+Space.UnitId" (click)="$event.stopPropagation()"
                                (change)="$event ? toggleresVisibility(Space.UnitId,$event) : null" style="font-size: 14px;"
                                matTooltipClass="devicetooltip" matTooltip={{Space.UnitName}} #tooltip="matTooltip" matTooltipPosition="above">
                                {{Space.UnitName.slice(0,15) + ".."}}, {{Space.DeviceZoneCount}} Devices</mat-checkbox>
                              </div>  
                            </div>                    
                      
                      </perfect-scrollbar>
                      </mat-grid-list>
                </div>
                
              </div>            
          
        
 </div>
 <mat-dialog-actions class="clstempbuttoncontrol">      
  <button type="button" class="clstempcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnzonetempsaveclick" class="clstempsavebutton" mat-raised-button >Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>