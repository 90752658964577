
<perfect-scrollbar style="max-width: 780px; max-height: 780px;" >
  <div class="clsWander">
      <div class="add-title">
        <h3 class="wanderAddHeader" style="margin: 0 0 10px 11px;">Add Roaming Details
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider> 
    </div>
    <div>
      <!-- <span class="wanderdot" [ngStyle]="{'background-color': 'red'}">Egress</span> -->
      <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand" let-onCheckedChange="onCheckedChange" let i = "index">
        
        <span *ngIf="i == 0" class="wanderdot" [ngStyle]="{'background-color': 'red'}">Egress</span>
        
        <div class="form-inline row-item" style=" display: flex;">                    
          
          <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa" [class.fa-caret-right]="item.collapsed"
            [class.fa-caret-down]="!item.collapsed" style='margin-right: 0em;'></i>
            
          <div class="form-check">            
            <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
              [disabled]="item.disabled" [indeterminate]="item.indeterminate" style="margin: 0px;" />
              <!-- <div> -->
                
            <span class="wanderdot" *ngIf="item.text.split('|')[1] == 'R'" [ngStyle]="{'background-color': '#6f6c6c'}"></span>
            <span class="wanderdot" *ngIf="item.text.split('|')[1] == 'E'" [ngStyle]="{'background-color': '#f7d613'}"></span>
            <span class="wanderdot" *ngIf="item.text.split('|')[1] == 'L'" [ngStyle]="{'background-color': '#ff0000'}"></span>
            <span class="wanderdot" *ngIf="item.text.split('|')[1] == 'O' || item.text.split('|')[1] == 'S' || item.text.split('|')[1] == 'A' ||
               item.text.split('|')[1] == 'D' || item.text.split('|')[1] == 'C' || item.text.split('|')[1] == 'N'" [ngStyle]="{'background-color': '#f77521'}"></span>
              <span class="wanderdot" *ngIf="item.text.split('|')[1] != 'R' && item.text.split('|')[1] != 'E' && item.text.split('|')[1] != 'L' && 
              item.text.split('|')[1] != 'O' && item.text.split('|')[1] != 'S' && item.text.split('|')[1] != 'A' &&
              item.text.split('|')[1] != 'D' && item.text.split('|')[1] != 'C' && item.text.split('|')[1] != 'N'" [ngStyle]="{'background-color': '#fffffff'}"></span>
            
            <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
              {{item.text.split('|')[0]}}
            </label>
          <!-- </div> -->
            <label class="form-check-label">
              <i class="fa fa-trash" aria-hidden="true" title="Remove" (click)="removeItem(item)"></i>
            </label>         
          </div>
        </div>
      </ng-template>
    </div>
    <div>
      <form  style="padding:24px;" [formGroup]="wanderConfigForm" autocomplete="off" novalidate (ngSubmit)="createWanderConfig(wanderConfigForm.value)">

        <mat-grid-list cols="3" rowHeight="85px">

          <mat-grid-tile>
            <label>Type</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
            <div>
              <mat-radio-group class="gender" (change)="onChange($event)" formControlName="residenttype" required>
                <mat-radio-button style="margin:4px 4px 0px 5px;" value="0">Individual</mat-radio-button>
                <mat-radio-button style="margin:4px 0px 0px 24px;" value="1">Group</mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <label>Resident/Group Name</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
            <div *ngIf="checkrestype != 1" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <mat-select formControlName="resident" placeholder="Select Resident" [(ngModel)]="selresident" (selectionChange)="selectresident($event.value)">
                  <mat-option *ngFor="let resident of Residentdetails" [value]="resident.ResidentID" title="{{ resident.Name.length >= 40 ? resident.Name : ''}}">
                    {{resident.FirstName}} {{resident.LastName}}
                  </mat-option>
                </mat-select>               
              </mat-form-field>
            </div>

            <div *ngIf="checkrestype == 1" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <mat-select formControlName="resident" placeholder="Select Group" [(ngModel)]="selresidentgroup">
                  <mat-option *ngFor="let resident of Residentgroups" [value]="resident.GroupId">
                    {{resident.GroupName}}
                  </mat-option>
                </mat-select>             
              </mat-form-field>
            </div>
          </mat-grid-tile>                                      
          
          <mat-grid-tile rowspan="4" class="clsgridtileUnsafeSpaces" style="top:0px;">
              <figure class="mat-figure" style="align-items: baseline; margin: 10px 0px;">
            <label style="position:absolute;text-align: center;">Select Un-safe Zones/Spaces</label>
            <div style="margin-left: 14.5em;" [matTooltip]="matTooltipAdvSettings" matTooltipClass="tooltip" #tooltip="matTooltip" (click)="tooltip.show()">                
              <mat-icon class="material-icons_color_gray">info icon</mat-icon>                                                  
            </div>  
          </figure>          
          </mat-grid-tile>     
               
          <mat-grid-tile colspan="2" rowspan="4" class="clsgridUnsafeSpaces" style="border: 1px solid lightgray; min-width:calc(((33.3333% - 0.666667px) * 2) + -2px); max-width:calc(((33.3333% - 0.666667px) * 2) + -2px);">            
              <figure class="mat-figure" style="align-items: baseline; margin-top: 5px; margin-left: 5px;height: 440px;">                           
              <div style="width:110%;margin-top:10px;">                
                <span class="wanderdot" style="background-color:#6f6c6c"></span><span class="clsSpanText">Resident Room</span>
                <span class="wanderdot" style="background-color:#f7d613"></span><span class="clsSpanText">Egress</span>
                <span class="wanderdot" style="background-color:#ff0000"></span><span class="clsSpanText">Resident Off Limits</span>
                <span class="wanderdot" style="background-color:#f77521"></span><span class="clsSpanText">Other</span>                  
                <ngx-treeview [items]="items" [config]="config" [itemTemplate]="itemTemplate" (selectedChange)="onSelectedChange($event)">
                </ngx-treeview>
              </div>
              </figure>             
          </mat-grid-tile>
         
        </mat-grid-list>

        <mat-dialog-actions class="clswanderbuttoncontrol">          
          <button type="button" class="clsbtnWanderCancel" mat-raised-button (click)="onCloseCancel()">Cancel</button>
          <button class="clsbtnWanderSave" mat-raised-button>Save</button>&nbsp;
        </mat-dialog-actions>
      </form>
    </div>
  </div>

</perfect-scrollbar>