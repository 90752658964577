<perfect-scrollbar style="max-width: 1200px; max-height: 550px;">
  <div class="clseditfloorspace">
      <div class="editfloorspace-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editfloorspaceHeader" style="margin: 0 0 10px 11px;">Space Edit
        <span><img class="clsfloorspacecloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="spaceeditTempateForm" autocomplete="off" novalidate (ngSubmit)="updateTemplateSpaceInfo(spaceeditTempateForm.value)">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >                          
              
              <div>                                                
              <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                
                <mat-grid-tile class="clsfloorspacematgrid">
                  <label>Selected Zone</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsfloorspacematgridtxt">
                      <div class="text-inside">
                        <mat-form-field appearance="outline" floatLabel="auto" class="clsfloorspacetxt">
                         <input matInput placeholder="Zone Name" formControlName="floorname" maxlength="32" [(ngModel)]="data.FloorName" (keyup)="handleInput($event)" required/>                                          
                         <input type="hidden" formControlName="tempID" [(ngModel)]="data.TemplateId">  
                         <input type="hidden" formControlName="unitID" [(ngModel)]="data.TempUnitId">
                      </mat-form-field>
                    </div>
                </mat-grid-tile>  
                
              <mat-grid-tile class="clsfloorspacematgrid">
                <label>Space Name</label>
              </mat-grid-tile>
              <mat-grid-tile class="clsfloorspacematgridtxt">
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsfloorspacetxt">
                       <input matInput placeholder="Space Name" formControlName="unitname" maxlength="32" [(ngModel)]="data.UnitName" (keyup)="handleInput($event)" required/>
                       <mat-error *ngIf="spaceeditTempateForm.controls.unitname.hasError('required')">Please enter Space Name</mat-error>  
                       <mat-error *ngIf="spaceeditTempateForm.controls.unitname.hasError('pattern')">Please enter valid Space Name</mat-error>
                      <mat-error *ngIf="spaceeditTempateForm.controls.unitname.hasError('maxlength')">Max length 32 characters only</mat-error>                   
                    </mat-form-field>
                  </div>
              </mat-grid-tile>  
              
              <mat-grid-tile class="clsfloorspacematgrid">
                <label>Space Category</label>
            </mat-grid-tile>
            <mat-grid-tile class="clsfloorspacematgridtxt">
                    <div class="text-inside">
                        <mat-form-field appearance="outline" floatLabel="auto" class="clsfloorspacetxt">
                          <mat-select formControlName="category" placeholder="Select Space Category" [(ngModel)]="selectCategoryId">    
                            <mat-option *ngFor="let Space of Spacecategory_details" [value]="Space.SpaceCategoryId" (click)="selectSpaceCategory(Space.SpaceCategoryId)">
                              {{Space.Description}}
                            </mat-option>
                          </mat-select>
                          <mat-error>Please select Space Category</mat-error>
                    </mat-form-field>
                  </div>
            </mat-grid-tile> 
              
              <mat-grid-tile class="clsfloorspacematgrid">
                <label>Description</label>
              </mat-grid-tile>
              <mat-grid-tile class="clsfloorspacematgridtxt">
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsfloorspacetxt">
                       <input matInput placeholder="Description" formControlName="description" maxlength="32" [(ngModel)]="data.Description" (keyup)="handleInput($event)"/>
                       <!-- <mat-error *ngIf="spaceeditTempateForm.controls.description.hasError('required')">Please enter Description</mat-error>   -->
                       <mat-error *ngIf="spaceeditTempateForm.controls.description.hasError('pattern')">Please enter valid Description Name</mat-error>
                      <mat-error *ngIf="spaceeditTempateForm.controls.description.hasError('maxlength')">Max length 32 characters only</mat-error>                   
                    </mat-form-field>
                  </div>
              </mat-grid-tile>  
              
              <mat-grid-tile class="clsfloorspacematgrid">
                <label>Resident Off<br>Limits</label>
              </mat-grid-tile>
              <mat-grid-tile class="clsfloorspacematgridtxt">
                      <div class="text-inside" style="width:100%;">
                        <mat-slide-toggle id="chkResOffLimits" (change)="toggler_ResOffLimits($event)" [checked]="checkResOffLimits"></mat-slide-toggle>
                    </div>
              </mat-grid-tile> 
              
                </mat-grid-list>
              </div>
          
        
 </div>
 <mat-dialog-actions class="clsfloorspacebuttoncontrol">      
  <button type="button" class="clsfloorspacecancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnfloorspacesaveclick" class="clsfloorspacesavebutton" mat-raised-button>Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>