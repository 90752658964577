import { click } from "ol/events/condition.js";
import Select from "ol/interaction/Select.js";
import {
  enumAlertSubType,
  enumAlertStatus,
  DeviceTypeDesc,
  enumAlertType,
  alert_raisedon,
  enumAlertAckStatus,
  enumCheckResidenStaff,
  alert_deviceType,
  fillcolorPolygon,
  enumRequestFrom,
  Alert_Event_Type,
} from "./app-settings";
import {
  Getalerticon,
  alerticonstyle,
  alerticonred,
  alerticonstaff,
  load_floorpopup_content,
  load_alertpopup_style,
  get_VectorSource,
  popup_overlay,
  map_marker_iconstylewithtext,
  fillcolor_style,
  get_geojsonObject_floor,
  getDeviceTypeNew,
  fillcolor_styleSelect,
  fillcolor_style_Home
} from "./map-general";
import { Style } from "ol/style";
import OlPoint from "ol/geom/Point";
import OlFeature from "ol/Feature";
import OlVectorLayer from "ol/layer/Vector";
import OlVectorSource from "ol/source/Vector";
import Popup from "ol-popup";
import { interval } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  DeviceType,
  NewDeviceTypeDesc,
} from "./app-settings";
import { StaffByIDparam } from "./landing/staff/staff";
import { AppComponent } from "./app.component";
import { containsCoordinate } from "ol/extent";
import { AlertMessageComponent } from "./alert-message/alert-message.component";
import { Validators } from "@angular/forms";
import { enumStaffRoll } from "../app/app-settings";
import Polygon from "ol/geom/Polygon";
import { takeUntil } from "rxjs/operators";
import { getCenter, getHeight, getWidth } from "ol/extent";
import { UnitById, getDeviceById } from "./api";

let alertids = [] as any[];
let alertidflag = 0;
let alertnewtime = [] as any[];
let cancelalertinfo = [] as any[];
let alertlength = 0;
let checkdialogopen = 0;
let alertdialog;

let staffSharedservice;
function msgbox(message) {
  alert(message);
}

function userinfo(username: string, password: string) {
  return username + " " + password;
}

function notification_line1(
  Fontcolor,
  StatusDec,
  alertlevelImg,
  AlertType,
  AlertDeviceType,
  AlertDescript
) {
  let strNotification = "";
  try {
    strNotification += '<div class="clsnotifydiv">';
    strNotification +=
      '<div class="clstdleft"><label class=' +
      Fontcolor +
      ">" +
      StatusDec +
      "</label></div>";
    if (
      AlertDeviceType == alert_deviceType.Battery &&
      AlertDescript != "Battery Removal"
    )
      strNotification +=
        '<div class="clstdright"><img class="clslevelImg" src="' +
        alertlevelImg +
        '"></div>';
    if (AlertDeviceType != alert_deviceType.Battery)
      strNotification +=
        '<div class="clstdright"><img class="clslevelImg" src="' +
        alertlevelImg +
        '"></div>';
    strNotification += "</div>";
    strNotification += '<hr class="clsline">';
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - notification_line1() - " + err.message
    );
  }
  return strNotification;
}

function notification_line2(
  currenttime,
  Fontcolor,
  time,
  type,
  AlertId,
  Ackedtime,
  Ackstatus
) {
  let strNotification = "";
  try {
    if (type == 1) {
      let notifytime;
      alertnewtime.forEach(function (data) {
        if (data.AlertId == AlertId) {
          notifytime = data.AlertTime;
          return false;
        } else {
          return true;
        }
      });
      if (notifytime == undefined || notifytime == "") {
        notifytime = time;
      }

      strNotification += '<div class="clsnotifydiv">';
      strNotification += '<div class="clstdleft">';
      strNotification +=
        '<label>Time of Alert: <span class="clscurrenttime">' +
        currenttime +
        "</span></label></div>";
      strNotification += '<div class="clstdright">';
      if (
        Ackstatus != enumAlertStatus.Notified &&
        Ackstatus != enumAlertStatus.Notify
      )
        strNotification +=
          "<label class=" +
          Fontcolor +
          "1> " +
          Ackstatus +
          " on  " +
          Ackedtime +
          "</label>";
      else
        strNotification +=
          "<label class=" + Fontcolor + "1>" + notifytime + "</label>";
      strNotification += "</div>";
      strNotification += "</div>";
      strNotification += '<hr class="clsline">';
    } else {
      strNotification += "<label class=" + Fontcolor + "1>" + time + "</label>";
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - notification_line2() - " + err.message
    );
  }
  return strNotification;
}

function notification_line3(
  AlertSubName,
  AlertType,
  Fontcolor,
  EventName,
  TagId,
  DeviceType,
  SubTypeName,
  AlertFullTypeName,
  AlertTypeName,
  TruViewEventType
) {
  let strNotification = "";
  try {
    strNotification += '<div class="clsnotifydiv">';
    strNotification += '<div class="clstdleft">';
    if (
      (DeviceType == alert_deviceType.REX &&
        AlertType == enumAlertType.Infrastructure) ||
      (DeviceType == alert_deviceType.HREX &&
        AlertType == enumAlertType.Infrastructure) ||
      AlertSubName == "Tag Not Reporting" ||
      AlertSubName == "Low Battery" ||
      (DeviceType == alert_deviceType.DC &&
        AlertSubName == "SC Not Reporting") ||
      (DeviceType == alert_deviceType.UT && SubTypeName == "Lost Communication")
    )
      strNotification +=
        "<div>" + AlertSubName + " ( " + TagId + " ) </div></div>";
    else strNotification += "<div>" + AlertSubName + "</div></div>";

    if (
      TruViewEventType == Alert_Event_Type.Appliance_UPS_IN_Battery ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_OUT_Battery ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_Unplug ||
      TruViewEventType == Alert_Event_Type.Appliance_LostInternet
    ) {
      strNotification +=
        '<div class="clstdright"><label class=' +
        Fontcolor +
        ">" +
        AlertTypeName +
        "</label></div>";
    }
    if (EventName.length <= 15) {
      strNotification +=
        '<div class="clstdright"><label class=' +
        Fontcolor +
        ">" +
        EventName +
        "</label></div>";
    } else {
      strNotification +=
        '<div class="clstdright_notify tooltip ' +
        Fontcolor +
        '">' +
        AlertFullTypeName +
        '<span class="tooltiptext clsnotifypopup">' +
        EventName +
        "</span></div></td>";
    }

    strNotification += "</div>";
    strNotification += '<hr class="clsline">';
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - notification_line3() - " + err.message
    );
  }
  return strNotification;
}

function notification_ackinfo(
  Staffphoto,
  NotifyName,
  StatusDec,
  StrAckedData,
  AckedMethod
) {
  let strNotification = "";
  let fullNotifyName;
  try {
    fullNotifyName = NotifyName;
    strNotification += '<div class="clsstaffDiv">';
    strNotification += "<table>";
    strNotification += "<tr>";
    if (AckedMethod != "Self") {
      strNotification += '<td class="clsstaffphoto">';
      strNotification +=
        ' <img src="' + Staffphoto + '" class="rounded-circle" >';
      strNotification += "</td>";
      strNotification += '<td class="clscompletedat">';
      if (NotifyName.length <= 15)
        strNotification +=
          '<label class="clslabel">' + NotifyName + "</label><br>";
      else {
        NotifyName = NotifyName.slice(0, 15) + "..";
        strNotification +=
          "<div class='tooltip' style='width:auto';><span>" +
          NotifyName +
          "</span><span class='tooltiptext'>" +
          fullNotifyName +
          "</span></div>";
      }
      strNotification += '<label class="clslabel">' + StatusDec + " on</label>";
      strNotification += "</td>";
    } else {
      strNotification += '<td class="clsstaffphoto"></td>';
      strNotification += '<td class="clscompletedat">';
      strNotification += '<label class="clslabel">Self</label><br>';
      strNotification += '<label class="clslabel">' + StatusDec + " on</label>";
      strNotification += "</td>";
    }

    strNotification += '<td class="clscompletedtime">';
    strNotification += '<label class="clslabel">' + StrAckedData + "</label>";
    strNotification += "</td>";

    strNotification += "</tr>";
    strNotification += "</table>";
    strNotification += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - notification_ackinfo() - " + err.message
    );
  }
  return strNotification;
}

function notification_neareststaff_heading() {
  let strNotification = "";
  try {    
    strNotification += '<div class="clsnotifydiv">';
    strNotification += '<div class="clstdleft clsalignNstaff">';
    strNotification += '<label class="clslblNstaff">Nearest Staff</label>';
    strNotification += "</div>";
    strNotification += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" +
        " - notification_neareststaff_heading() - " +
        err.message
    );
  }
  return strNotification;
}

function notification_buttons(
  notifiedBtn1,
  notifiedVal1,
  notifiedBtn2,
  notifiedVal2,
  notifiedBtn3,
  notifiedVal3,
  alertnotifytime,
  nstaffidx
) {
  let strNotification = "";
  try {
    strNotification += '<td class="clsalignright">';
    strNotification += "<div>";
    if (
      nstaffidx == 0 &&
      getFeatureNeedtoShow(
        enumUIFeatureView.Home,
        enumUIAction.Alert_Renotify
      ) == enumUIView.Show
    )
      strNotification +=
        "<div class=" + notifiedBtn1 + ">" + notifiedVal1 + "</div>";
    else if (
      nstaffidx == 1 &&
      getFeatureNeedtoShow(
        enumUIFeatureView.Home,
        enumUIAction.Alert_Renotify
      ) == enumUIView.Show
    )
      strNotification +=
        "<div class=" + notifiedBtn2 + ">" + notifiedVal2 + "</div>";
    else if (
      nstaffidx == 2 &&
      getFeatureNeedtoShow(
        enumUIFeatureView.Home,
        enumUIAction.Alert_Renotify
      ) == enumUIView.Show
    )
      strNotification +=
        "<div class=" + notifiedBtn3 + ">" + notifiedVal3 + "</div>";

    strNotification += "</td>";
    strNotification += "</tr>";
    strNotification += "</table>";
    strNotification += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - notification_buttons() - " + err.message
    );
  }
  return strNotification;
}

function notification_staffinfomration(StaffName, ContactNumber) {
  let strNotification = "";
  try {    
    if (StaffName.length > 0) {
      strNotification +=
        '<label class="notlabelbottom notfontSize">' + StaffName + "</label>";
      strNotification += "<br>";
    }
    if (ContactNumber.length > 0) {
      strNotification +=
        '<p class="notparagraphpadding">' + ContactNumber + "</p>";
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - notification_staffinfomration() - " + err.message
    );
  }
  return strNotification;
}
function notification_neareststaff_details(FirstName, ContactNumber) {
  let strNotification = "";
  try {
    strNotification += '<div class="row">';
    strNotification += '<div class="clstdleft clsalignNstaff">';
    strNotification +=
      '<label class="clslabel notlabelbottom notfontSize">' +
      FirstName +
      "</label>";
    strNotification += "<br>";
    strNotification +=
      '<p class="notparagraphpadding">' + ContactNumber + "</p>";
    strNotification += "</div>";
    strNotification += '<div class="clstdright">';
    strNotification +=
      '<input type="button"  class="notNotify" value="Notify">';
    strNotification += "</div>";
    strNotification += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" +
        " - notification_neareststaff_details() - " +
        err.message
    );
  }
  return strNotification;
}
function alert_data_floor(
  AresImg,
  FirstName,
  ResidentName,
  cordiX,
  cordiY,
  residentimgurl,
  staffImgUrl,
  FloorName,
  FullFloorName,
  UnitName,
  FullUnitName,
  SharedCampusId,
  SharedBuildingId,
  SharedFloorId,
  SharedAlertId,
  SharedAlertStatus,
  popupwidth,
  Building_SVGID
) {
  let content = "";
  try {
    content += '<div class="clssearchDiv">';

    content +=
      '<div style="background-color:white;float:left;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:14px;min-width:85px;">';
    content += "<img style='height:40px;' src='" + AresImg + "'><br>";
    content += "</div>";

    content +=
      '<div style="margin:15px 0px 2px 0px;background-color: white;font-weight: normal;white-space: nowrap;font-size: 14px;">';
    content +=
      "<div style='color: black;font-weight:normal;font-size:16px;padding-bottom:2px;'>" +
      FirstName +
      "</div>";
    content +=
      "<div style='color: black;font-weight:normal;font-size:16px;padding-bottom:2px;margin-left:5.2em;'>" +
      ResidentName +
      "</div>";
    if (FloorName.length < 15) {
      content += "<div style='cursor:pointer;margin-left:6em;'>";
      content +=
        "<span data-cordiX=" +
        cordiX +
        " data-cordiY=" +
        cordiY +
        " data-LocationstaffImg=" +
        staffImgUrl +
        " data-LocationResImg=" +
        residentimgurl +
        " data-LocationAlertId=" +
        SharedAlertId +
        " data-popupwidth=" +
        popupwidth +
        ">" +
        FloorName +
        "</span></div>";
    } else {
      content +=
        "<div style='cursor:pointer;margin-left:6em;' class='tooltip'>";
      content +=
        "<span data-cordiX=" +
        cordiX +
        " data-cordiY=" +
        cordiY +
        " data-LocationstaffImg=" +
        staffImgUrl +
        " data-LocationResImg=" +
        residentimgurl +
        " data-LocationAlertId=" +
        SharedAlertId +
        " data-popupwidth=" +
        popupwidth +
        ">" +
        FloorName +
        "</span><span class='tooltiptext'>" +
        FullFloorName +
        "</span></div>";
    }
    content += "<div style='cursor:pointer;margin-left:6em;'>";
    if (UnitName.length < 15)
      content +=
        "<div style='float:left;min-width:172px;' data-cordiX=" +
        cordiX +
        " data-cordiY=" +
        cordiY +
        "  data-LocationstaffImg=" +
        staffImgUrl +
        " data-LocationResImg=" +
        residentimgurl +
        " data-LocationAlertId=" +
        SharedAlertId +
        " data-popupwidth=" +
        popupwidth +
        ">" +
        UnitName +
        "</div>";
    else
      content +=
        "<div class='tooltip' style='float:left;min-width:172px;' data-cordiX=" +
        cordiX +
        " data-cordiY=" +
        cordiY +
        "  data-LocationstaffImg=" +
        staffImgUrl +
        " data-LocationResImg=" +
        residentimgurl +
        " data-LocationAlertId=" +
        SharedAlertId +
        " data-popupwidth=" +
        popupwidth +
        ">" +
        UnitName +
        "<span class='tooltiptext'>" +
        FullUnitName +
        "</span></div>";
    if (Building_SVGID.length > 0) {
      content += "<div>";
      content +=
        "<img style='width:18px;height:18px;cursor:pointer;' src='assets/images/Alert-Location-Zoom Out.png' data-SearchCampusId=" +
        SharedCampusId +
        " data-SearchBuildingId=" +
        SharedBuildingId +
        " data-SearchFloorId= 0  data-SearchAlertId=" +
        SharedAlertId +
        " data-SearchAlertStatus=" +
        SharedAlertStatus +
        " ></div></div>";
      content += "</div>";
    }

    content += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_data_floor() - " + err.message
    );
  }
  return content;
}
function alert_data_search(
  AresImg,
  FirstName,
  ResidentId,
  ResidentName,
  cordiX,
  cordiY,
  residentimgurl,
  staffImgUrl,
  AlertId,
  FloorName,
  FullFloorName,
  UnitName,
  FullUnitName,
  CampusId,
  BuildId,
  FloorId,
  RoomId,
  popupwidth
) {
  let content = "";
  try {
    content += '<div class="clssearchDiv">';

    content +=
      '<div style="background-color:white;float:left;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:14px;min-width:85px;">';
    content += "<img style='height:40px;' src='" + AresImg + "'>";
    content += "</div>";

    content +=
      '<div style="margin:15px 0px 2px 0px;background-color: white;font-weight: normal;white-space: nowrap;font-size: 14px;">';
    content +=
      "<div style='color: black;font-weight:normal;font-size:16px;padding-bottom:2px;'>" +
      FirstName +
      "</div>";
    content +=
      "<div style='color: black;font-weight:normal;font-size:16px;padding-bottom:2px;margin-left:5.2em;'>" +
      ResidentName +
      "</div>";
    if (FloorName.length < 15) {
      content += "<div style='cursor:pointer;margin-left:6em;'>";
      content +=
        "<span data-cordiX=" +
        cordiX +
        " data-cordiY=" +
        cordiY +
        "  data-LocationstaffImg=" +
        staffImgUrl +
        " data-LocationResImg=" +
        residentimgurl +
        " data-LocationAlertId=" +
        AlertId +
        " data-popupwidth=" +
        popupwidth +
        ">" +
        FloorName +
        "</span></div>";
    } else {
      content +=
        "<div style='cursor:pointer;margin-left:6em;' class='tooltip'>";
      content +=
        "<span data-cordiX=" +
        cordiX +
        " data-cordiY=" +
        cordiY +
        "  data-LocationstaffImg=" +
        staffImgUrl +
        " data-LocationResImg=" +
        residentimgurl +
        " data-LocationAlertId=" +
        AlertId +
        " data-popupwidth=" +
        popupwidth +
        ">" +
        FloorName +
        "</span><span class='tooltiptext'>" +
        FullFloorName +
        "</span></div>";
    }
    content += "<div style='cursor:pointer;margin-left:6em;'>";
    if (UnitName.length < 15)
      content +=
        "<div style='float:left;min-width:172px;' data-cordiX=" +
        cordiX +
        " data-cordiY=" +
        cordiY +
        " data-LocationstaffImg=" +
        staffImgUrl +
        "  data-LocationResImg=" +
        residentimgurl +
        " data-LocationAlertId=" +
        AlertId +
        " data-popupwidth=" +
        popupwidth +
        ">" +
        UnitName +
        "</div>";
    else
      content +=
        "<div class='tooltip' style='float:left;min-width:172px;' data-cordiX=" +
        cordiX +
        " data-cordiY=" +
        cordiY +
        " data-LocationstaffImg=" +
        staffImgUrl +
        "  data-LocationResImg=" +
        residentimgurl +
        " data-LocationAlertId=" +
        AlertId +
        " data-popupwidth=" +
        popupwidth +
        ">" +
        UnitName +
        "<span class='tooltiptext'>" +
        FullUnitName +
        "</span></div>";
    content += "<div>";
    content +=
      "<img style='width:18px;height:18px;cursor:pointer;' src='assets/images/Alert-Location-Zoom In.png' data-SearchCampusId=" +
      CampusId +
      " data-SearchBuildingId=" +
      BuildId +
      " data-SearchFloorId=" +
      FloorId +
      " data-SearchRoomId=" +
      RoomId +
      "  data-SearchAlertId=" +
      AlertId +
      " data-SearchResidentId=" +
      ResidentId +
      "></div></div>";
    content += "</div>";

    content += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_data_search() - " + err.message
    );
  }
  return content;
}
function alert_location_data_detail(
  FirstName,
  FullFirstName,
  ResidentName,
  FullResidentName,
  Age,
  UnitName,
  FullUnitName,
  ResCondition,
  ResImg,
  CampusName,
  FullCampusName,
  BuildingName,
  FullBuildingName,
  FloorName,
  FullFloorName,
  ResFullCondition,
  ResConditionflag
) {
  let content = "";
  try {
    content += '<div style="height:35px;margin:0px;background-color:#E6E6E6">';
    content +=
      '<div style="padding-top:5px;padding-left:5px;white-space: nowrap;">';
    content +=
      '<img style="padding-right:10px;width:20px;height:20px;" src="assets/images/map/Alerts-Map-Location-Icon.png">';
    content +=
      '<label style=" color:#005493;font-size:18px;font-weight:normal">Location</label></div>';
    content += "</div>";

    content += '<div class="clsLocationpopup">';
    content += "<table><tr>";

    content += '<td style="background-color:white;">';
    content +=
      '<div style="color:black;background-color:white;float:left;font-weight:normal;margin-bottom:0px;min-width:150px;white-space: nowrap;font-size:14px;">';
    const styleinfo = "font-size:16px;font-weight:normal;padding-bottom:7px;";
    content += alert_residnetinfo(
      FirstName,
      FullFirstName,
      ResidentName,
      FullResidentName,
      Age,
      UnitName,
      FullUnitName,
      ResCondition,
      ResFullCondition,
      styleinfo,
      ResConditionflag
    );
    content += "</div>";
    content += "</td>";

    content += '<td style="background-color:white;">';
    content +=
      '<div style="color:#005493;background-color:white;font-weight:normal;margin-right:20px;margin-bottom:0px;white-space: nowrap;font-size:14px;">';
    content +=
      "<img style='width:120;height:40px;padding-bottom:15px;' src='" +
      ResImg +
      "'><br>";
    if (CampusName.length < 15)
      content += "<div><span>" + CampusName + "</span></div>";
    else
      content +=
        "<div class='tooltip' style='width:auto';><span>" +
        CampusName +
        "</span><span class='tooltiptext'>" +
        FullCampusName +
        "</span></div>";
    if (BuildingName.length < 15)
      content += "<div><span>" + BuildingName + "</span></div>";
    else {
      BuildingName = BuildingName.slice(0, 15) + "..";
      content +=
        "<div class='tooltip' style='width:auto';><span>" +
        BuildingName +
        "</span><span class='tooltiptext'>" +
        FullBuildingName +
        "</span></div>";
    }
    if (FloorName.length < 15)
      content += "<div><span>" + FloorName + "</span></div>";
    else
      content +=
        "<div class='tooltip' style='width:auto';><span>" +
        FloorName +
        "</span><span class='tooltiptext'>" +
        FullFloorName +
        "</span></div>";

    content += "</div>";
    content += "</td>";

    content += "</tr</table>";
    content += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_location_data_detail() - " + err.message
    );
  }
  return content;
}

function alert_residnetinfo(
  FirstName,
  FullFirstName,
  ResidentName,
  FullResidentName,
  Age,
  UnitName,
  FullUnitName,
  ResCondition,
  ResFullCondition,
  styleinfo,
  ResConditionflag
) {
  let content = "";
  try {
    if (FirstName == null || FirstName == undefined) FirstName = "";
    if (FullFirstName == null || FullFirstName == undefined) FullFirstName = "";
    if (ResidentName == null || ResidentName == undefined) ResidentName = "";
    if (FullResidentName == null || FullResidentName == undefined)
      FullResidentName = "";
    if (ResFullCondition == null || ResFullCondition == undefined)
      ResFullCondition = "";
    if (Age == null || Age == undefined) Age = "";
    if (styleinfo != "") {
      if (FullFirstName.length < 15)
        content +=
          "<div class='tooltip' style=" +
          styleinfo +
          "><span>" +
          FirstName +
          "</span></div>";
      else
        content +=
          "<div class='tooltip' style=" +
          styleinfo +
          "><span>" +
          FirstName +
          "</span><span class='tooltiptext'>" +
          FullFirstName +
          "</span></div>";

      if (FullResidentName.length < 15)
        content +=
          "<div class='tooltip' style=" +
          styleinfo +
          "><span>" +
          ResidentName +
          "</span></div>";
      else
        content +=
          "<div class='tooltip' style=" +
          styleinfo +
          "><span>" +
          ResidentName +
          "</span><span class='tooltiptext'>" +
          FullResidentName +
          "</span></div>";
    } else {
      if (FullFirstName.length < 15)
        content += "<div class='tooltip'><span>" + FirstName + "</span></div>";
      else
        content +=
          "<div class='tooltip'><span>" +
          FirstName +
          "</span><span class='tooltiptext'>" +
          FullFirstName +
          "</span></div>";

      if (FullResidentName.length < 15)
        content +=
          "<div class='tooltip'><span>" + ResidentName + "</span></div>";
      else
        content +=
          "<div class='tooltip'><span>" +
          ResidentName +
          "</span><span class='tooltiptext'>" +
          FullResidentName +
          "</span></div>";
    }
    content += "<div>Age: <span>" + Age + "</span></div>";
    content += "<div>Allergies: ";
    if (ResCondition) {
      if (ResConditionflag == 1) {
        for (let c = 0; c < ResCondition.length; c++) {
          if (ResFullCondition[c].length < 15)
            content += "<span>" + ResCondition[c] + "</span>";
          else if (c == 0) {
            if (ResFullCondition[c].length < 200)
              content +=
                "<div style='display:inline-block;' class='tooltip'><span>" +
                ResCondition[c] +
                "</span><span style='white-space:pre-wrap'; class='tooltiptext'>" +
                ResFullCondition[c] +
                "</span></div>";
            else if (ResFullCondition[c].length >= 200)
              content +=
                "<div style='display:inline-block;' class='tooltip'><span>" +
                ResCondition[c] +
                "</span><span style='white-space:pre-wrap'; class='newtooltiptext'>" +
                ResFullCondition[c] +
                "</span></div>";
          } else {
            if (ResFullCondition[c].length < 200)
              content +=
                "<div class='tooltip'><span>" +
                ResCondition[c] +
                "</span><span style='white-space:pre-wrap'; class='tooltiptext'>" +
                ResFullCondition[c] +
                "</span></div>";
            else if (ResFullCondition[c].length >= 200)
              content +=
                "<div class='tooltip'><span>" +
                ResCondition[c] +
                "</span><span style='white-space:pre-wrap'; class='newtooltiptext'>" +
                ResFullCondition[c] +
                "</span></div>";
          }
        }
      } else {
        content += "<span>" + ResCondition + "</span>";
      }
    }
    content += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_residnetinfo() - " + err.message
    );
  }
  return content;
}

function alert_residnetdetail(
  alertcolor,
  FirstName,
  FullFirstName,
  ResidentName,
  FullLastName,
  Designation,
  Phone,
  Email
) {
  let content = "";
  let FullEmail = "";
  try {
    if (FullFirstName == undefined) FullFirstName = "";
    if (FullLastName == undefined) FullLastName = "";

    if (Email.length > 15) FullEmail = Email;

    if (FullFirstName.length < 15)
      content += "<div class='tooltip'>" + FirstName + "</div>";
    else
      content +=
        "<div class='tooltip'>" +
        FirstName +
        "<span class='tooltiptext'>" +
        FullFirstName +
        "</span></div>";

    if (FullLastName.length < 15)
      content += "<div class='tooltip'>" + ResidentName + "</div>";
    else
      content +=
        "<div class='tooltip'>" +
        ResidentName +
        "<span class='tooltiptext'>" +
        FullLastName +
        "</span></div>";

    content += "<div>Role: <span>" + Designation + "</span></div>";
    content += "<div>Contact: </div>";
    content += "<div><span>" + Phone + "</span></div>";
    if (Email.length <= 15)
      content += "<div class='tooltip'>" + Email + "</div>";
    else {
      Email = Email.slice(0, 12) + "..";
      content +=
        "<div class='tooltip'>" +
        Email +
        "<span class='tooltiptext'>" +
        FullEmail +
        "</span></div>";
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_residnetdetail() - " + err.message
    );
  }
  return content;
}

function alert_description(
  AlertDescript,
  alertcolor,
  AlertNotify,
  alertlevelImg,
  AlertType,
  AlertSubName,
  AlertDeviceType,
  SubTypeId,
  TruViewEventType
) {
  if (alertidflag == 0) alertids = [];
  let content = "";
  try {
    content += '<div style="height:30px;margin:0px;background-color:#E6E6E6">';
    content +=
      '<div style="padding-left:10px;white-space: nowrap;width:380px;">';
    content +=
      '<img style="padding-top:3px;padding-right:10px;width:18px;height:18px;" src="assets/images/Alerts-Alert Icon-Popup.png">';
    if (SubTypeId == enumAlertSubType.Nurse_Call_Alarm)
      content +=
        '<label style=" padding-left:15px;color:#005493;padding-top:2px;font-size: 15px;">Alarm</label></div>';
    else if (
      TruViewEventType == Alert_Event_Type.Appliance_UPS_IN_Battery ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_OUT_Battery ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_Unplug ||
      TruViewEventType == Alert_Event_Type.Appliance_LostInternet ||
      (AlertSubName == "Tag Not Reporting" &&
        SubTypeId != enumAlertSubType.Nurse_Call_Alarm)
    )
      content +=
        '<label style=" padding-left:15px;color:#005493;padding-top:2px;font-size: 15px;">' +
        AlertSubName +
        "</label></div>";
    else
      content +=
        '<label style=" padding-left:15px;color:#005493;padding-top:2px;font-size: 15px;">' +
        AlertDescript +
        "</label></div>";
    content += "</div>";

    content +=
      '<div style="height:18px;padding-top:5px; padding-bottom:0px;padding-left:10px;">';
    content += "<table><tr>";
    content +=
      '<td style="background-color:white;min-width:10em;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:14px;color:' +
      alertcolor +
      '"><label>' +
      AlertNotify +
      "</label></td>";
    if (
      AlertDeviceType == alert_deviceType.Battery &&
      AlertDescript != "Battery Removal"
    ) {
      content +=
        '<td style="background-color:white;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;"><img align="right" style="width:65px;margin-right:15px;" src="' +
        alertlevelImg +
        '"></td>';
    } else if (AlertDeviceType != alert_deviceType.Battery)
      content +=
        '<td style="background-color:white;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;"><img align="right" style="width:65px;margin-right:15px;" src="' +
        alertlevelImg +
        '"></td>';
    content += "</tr></table>";
    content += "</div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_description() - " + err.message
    );
  }
  return content;
}

function alert_campusinfo(
  alertcolor,
  CampusName,
  FullCampusName,
  BuildingName,
  FullBuildingName,
  FloorName,
  FullFloorName,
  Locationname,
  DeviceType,
  AlertType,
  TruViewEventType,
  isOutdoor,
  AlertCampusName
) {
  let content = "";
  if (CampusName == null || CampusName == undefined) CampusName = "";
  if (BuildingName == null || BuildingName == undefined) BuildingName = "";
  if (FloorName == null || FloorName == undefined) FloorName = "";

  if (FullCampusName == undefined || FullCampusName == null)
    FullCampusName = "";
  if (FullBuildingName == undefined || FullBuildingName == null)
    FullBuildingName = "";
  if (FullFloorName == undefined || FullFloorName == null) FullFloorName = "";
  try {
    if (
      (DeviceType == alert_deviceType.Pager_Communication ||
        TruViewEventType == Alert_Event_Type.Appliance_UPS_IN_Battery ||
        TruViewEventType == Alert_Event_Type.Appliance_UPS_OUT_Battery ||
        TruViewEventType == Alert_Event_Type.Appliance_UPS_Unplug ||
        TruViewEventType == Alert_Event_Type.Appliance_LostInternet) &&
      AlertType == enumAlertType.Infrastructure
    )
      content +=
        "<div class='tooltip' style='color:" + alertcolor + "'>Zone: NA</div>";
    else {
      if (isOutdoor == 0) {
        if (FullCampusName.length < 15)
          content +=
            "<div class='tooltip' style='color:" +
            alertcolor +
            "'>" +
            CampusName +
            "</div>";
        else
          content +=
            "<div class='tooltip' style='color:" +
            alertcolor +
            "'>" +
            CampusName +
            "<span class='tooltiptext'>" +
            FullCampusName +
            "</span></div>";
      }

      if (isOutdoor == 1) {
        if (FullCampusName.length < 15)
          content +=
            "<div class='tooltip' style='color:" +
            alertcolor +
            "'>" +
            AlertCampusName +
            "</div>";
        else
          content +=
            "<div class='tooltip' style='color:" +
            alertcolor +
            "'>" +
            AlertCampusName +
            "<span class='tooltiptext'>" +
            FullCampusName +
            "</span></div>";
      }

      if (FullBuildingName.length < 15)
        content +=
          "<div class='tooltip' style='color:" +
          alertcolor +
          "'>" +
          BuildingName +
          "</div>";
      else
        content +=
          "<div class='tooltip' style='color:" +
          alertcolor +
          "'>" +
          BuildingName +
          "<span class='tooltiptext'>" +
          FullBuildingName +
          "</span></div>";
      if (isOutdoor == 0) {
        if (FullFloorName.length < 15)
          content +=
            "<div class='tooltip' style='color:" +
            alertcolor +
            "'>" +
            FloorName +
            "</div>";
        else
          content +=
            "<div class='tooltip' style='color:" +
            alertcolor +
            "'>" +
            FloorName +
            "<span class='tooltiptext'>" +
            FullFloorName +
            "</span></div>";
      }
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_campusinfo() - " + err.message
    );
  }
  return content;
}

function alert_notification_button(
  AlertType,
  RoomId,
  AlertId,
  FloorId,
  AckStatus,
  Alertresstaff,
  Devicetype,
  DeviceSubType,
  LocationType
) {
  let content = "";
  try {
    let bNeedtoShow = true;
    if (
      Devicetype == alert_deviceType.Battery ||
      Devicetype == alert_deviceType.Pager_Communication
    ) {
      //battery inserted only to click and cancel for admin and service manager.
      if (
        localStorage.getItem("role") == enumStaffRoll.Admin.toString() ||
        localStorage.getItem("role") == enumStaffRoll.Service_Manager.toString()
      ) {
        bNeedtoShow = true;
      } else {
        bNeedtoShow = false;
      }
    }
    if (
      AckStatus == enumAlertAckStatus.Notified &&
      bNeedtoShow == true &&
      (getFeatureNeedtoShow(
        enumUIFeatureView.Home,
        enumUIAction.Alert_Cancel
      ) == enumUIView.Show ||
        getFeatureNeedtoShow(
          enumUIFeatureView.Home,
          enumUIAction.Alert_Renotify
        ) == enumUIView.Show)
    ) {
      content += '<div align="center">';
      content +=
        '<input id="btnalertNotifications" type="button" style="width:100px;height:35px;color:#fff;background-color:#126EA7;cursor:pointer;margin-top:10px;" data-Alerttype =' +
        AlertType +
        " data-RoomId=" +
        RoomId +
        " data-AlertId=" +
        AlertId +
        " data-FloorId=" +
        FloorId +
        " data-ResStaff=" +
        Alertresstaff +
        " data-DeviceType=" +
        Devicetype +
        " data-DeviceSubType=" +
        DeviceSubType +
        " data-LocationType=" +
        LocationType +
        ' value="Notifications" />';
      content += "</div>";
    } else if (
      (AckStatus == enumAlertAckStatus.Accepted ||
        AckStatus == enumAlertAckStatus.Presence ||
        AckStatus == enumAlertAckStatus.Caregiver_Presence ||
        AckStatus == enumAlertAckStatus.Caregiver_Alert_Presence) &&
      getFeatureNeedtoShow(
        enumUIFeatureView.Home,
        enumUIAction.Alert_CompleteOrPresence
      ) == enumUIView.Show
    ) {
      content += '<div style="margin: 0px 43px 0px 10em;">';
      content +=
        '<input id="btnalertCompleteNotifications"  type="button" style="width:100px;height:35px;color:#fff;background-color:#126EA7;cursor:pointer;"  data-AcceptAlertId =' +
        AlertId +
        ' id="complete" value="Complete" />';
      content += "</div>";
    } else {
      content += '<div style="margin: 10px 43px 15px; 0;">';
      content += "</div>";
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_notification_button() - " + err.message
    );
  }
  return content;
}

function alert_pull_cord(alertcolor, UnitName, pullcordImg) {
  let content = "";
  try {
    content += "<div style='color:" + alertcolor + "'>" + UnitName + "</div>";
    content +=
      '<td align="right" style="background-color:white;font-weight:bold;margin-bottom:0px;white-space: nowrap;font-size:12px;">';
    content +=
      "<img style=' padding-top:20px;width:65px;height:80px;margin-right:15px;' src='" +
      pullcordImg +
      "'><br></td>";
  } catch (err) {
    throw new Error("app-general.ts" + " - alert_pull_cord() - " + err.message);
  }
  return content;
}
function alert_DC(alertcolor, UnitName, FullUnitName, DCImage) {
  let content = "";
  try {
    if (FullUnitName.length < 15)
      content +=
        "<div class='tooltip' style='color:" +
        alertcolor +
        "'>" +
        UnitName +
        "</div>";
    else
      content +=
        "<div class='tooltip' style='color:" +
        alertcolor +
        "'>" +
        UnitName +
        "<span class='tooltiptext'>" +
        FullUnitName +
        "</span></div>";

    content +=
      '<td align="right" style="background-color:white;font-weight:bold;margin-bottom:0px;white-space: nowrap;font-size:12px;">';
    content +=
      "<img style=' padding-top:20px;width:50px;height:50px;margin-right:15px;' src='" +
      DCImage +
      "'><br></td>";
  } catch (err) {
    throw new Error("app-general.ts" + " - alert_DC() - " + err.message);
  }
  return content;
}
function alert_DCT(alertcolor, UnitName, FullUnitName, dctImg) {
  let content = "";
  try {
    if (FullUnitName.length < 15)
      content +=
        "<div class='tooltip' style='color:" +
        alertcolor +
        "'>" +
        UnitName +
        "</div>";
    else
      content +=
        "<div class='tooltip' style='color:" +
        alertcolor +
        "'>" +
        UnitName +
        "<span class='tooltiptext'>" +
        FullUnitName +
        "</span></div>";

    content +=
      '<td align="right" style="background-color:white;font-weight:bold;margin-bottom:0px;white-space: nowrap;font-size:12px;">';
    content +=
      "<img style=' padding-top:20px;width:65px;height:80px;margin-right:15px;' src='" +
      dctImg +
      "'><br></td>";
  } catch (err) {
    throw new Error("app-general.ts" + " - alert_DCT() - " + err.message);
  }
  return content;
}

function alert_subname(
  AlertSubName,
  AlertType,
  alertcolor,
  AlerttypeName,
  AlertTypeName,
  AlertFulltypeName,
  TagId,
  DeviceType,
  SubTypeName,
  SubTypeId,
  Alert,
  TruViewEventType
) {
  let content = "";
  
  try {
    content += '<div style="margin: 5px 15px 0 10px;height:18px;">';
    content += '<table><tr style="background-color:white;">';
    content +=
      '<td style="background-color:white;min-width:17em;font-weight:normal;margin-bottom:0px;white-space:nowrap;font-size:12px;">';
    if (
      (DeviceType == alert_deviceType.REX &&
        AlertType == enumAlertType.Infrastructure) ||
      (DeviceType == alert_deviceType.HREX &&
        AlertType == enumAlertType.Infrastructure) ||
      AlertSubName == "Tag Not Reporting" ||
      AlertSubName == "Low Battery" ||
      (DeviceType == alert_deviceType.DC &&
        AlertSubName == "SC Not Reporting") ||
      (DeviceType == alert_deviceType.Pull_Cord &&
        AlertType == enumAlertType.Pull_Cord) ||
      (DeviceType == alert_deviceType.UT && SubTypeName == "Lost Communication")
    )
      content += "<div>" + AlertSubName + " ( " + TagId + " ) </div></td>";
    else if (SubTypeId == enumAlertSubType.Nurse_Call_Alarm)
      content += "<div>Event Type</div></td>";
    else content += "<div>" + AlertSubName + " </div></td>";

    if (SubTypeId == enumAlertSubType.Nurse_Call_Alarm) {
      content +=
        '<td style="background-color:white;right:0px;font-weight:normal;margin-bottom:0px;white-space:nowrap;font-size:12px;float:right;">';
      content +=
        '<div class="tooltip" style="color:' +
        alertcolor +
        '">' +
        Alert +
        "</div></td>";
    } else if (
      TruViewEventType == Alert_Event_Type.Appliance_UPS_IN_Battery ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_OUT_Battery ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_Unplug ||
      TruViewEventType == Alert_Event_Type.Appliance_LostInternet
    ) {
      content +=
        '<td style="background-color:white;right:0px;font-weight:normal;margin-bottom:0px;white-space:nowrap;font-size:12px;float:right;">';
      content +=
        '<div class="tooltip" style="color:' +
        alertcolor +
        '">' +
        AlertTypeName +
        "</div></td>";
    } else if (
      AlerttypeName.length <= 15 &&
      SubTypeId != enumAlertSubType.Nurse_Call_Alarm
    ) {
      content +=
        '<td style="background-color:white;right:0px;font-weight:normal;margin-bottom:0px;white-space:nowrap;font-size:12px;float:right;">';
      content +=
        '<div class="tooltip" style="color:' +
        alertcolor +
        '">' +
        AlerttypeName +
        "</div></td>";
    } else {
      content +=
        '<td style="background-color:white;right:0px;font-weight:normal;margin-bottom:0px;font-size:12px;float:right;">';
      content +=
        '<div class="tooltip" style="color:' +
        alertcolor +
        '">' +
        AlertFulltypeName +
        '<span class="tooltiptext">' +
        AlerttypeName +
        "</span></div></td>";
    }
    content += "</tr></table>";
    content += "</div>";
  } catch (err) {
    throw new Error("app-general.ts" + " - alert_subname() - " + err.message);
  }
  return content;
}

function alert_unitinfo(
  alertcolor,
  UnitName,
  FullUnitName,
  subTypeIMg,
  DeviceType,
  TruViewEventType
  
) {
  let content = "";
  try {
    if (UnitName == undefined || UnitName == null) UnitName = "";
    if (FullUnitName == undefined || FullUnitName == null) FullUnitName = "";

    if (
      DeviceType != alert_deviceType.Pager_Communication &&
      TruViewEventType != Alert_Event_Type.Appliance_UPS_IN_Battery &&
      TruViewEventType != Alert_Event_Type.Appliance_UPS_OUT_Battery &&
      TruViewEventType != Alert_Event_Type.Appliance_UPS_Unplug &&
      TruViewEventType != Alert_Event_Type.Appliance_LostInternet
    ) {
      if (FullUnitName.length < 15)
        content +=
          "<div class='tooltip' style='color:" +
          alertcolor +
          "'>" +
          UnitName +
          "</div>";
      else
        content +=
          "<div class='tooltip' style='color:" +
          alertcolor +
          "'>" +
          UnitName +
          "<span class='tooltiptext'>" +
          FullUnitName +
          "</span></div>";
    } else if (
      DeviceType == alert_deviceType.Pager_Communication ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_IN_Battery ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_OUT_Battery ||
      TruViewEventType == Alert_Event_Type.Appliance_UPS_Unplug ||
      TruViewEventType == Alert_Event_Type.Appliance_LostInternet
    )
      content +=
        "<div class='tooltip' style='color:" + alertcolor + "'>Space: NA</div>";
    content +=
      '<td align="right" style="background-color:white;font-weight:bold;margin-bottom:0px;white-space: nowrap;font-size:12px;">';
    content +=
      "<img style=' padding-top:20px;width:65px;height:65px;margin-right:15px;' src='" +
      subTypeIMg +
      "'><br></td>";
  } catch (err) {
    throw new Error("app-general.ts" + " - alert_unitinfo() - " + err.message);
  }
  return content;
}

function alert_resStaffunitinfo(alertcolor, UnitName, FullUnitName) {
  let content = "";
  try {
    if (FullUnitName == undefined || FullUnitName == null) FullUnitName = "";
    if (FullUnitName.length < 15)
      content +=
        "<div class='tooltip' style='color:" +
        alertcolor +
        "'>" +
        UnitName +
        "</div>";
    else
      content +=
        "<div class='tooltip' style='color:" +
        alertcolor +
        "'>" +
        UnitName +
        "<span class='tooltiptext'>" +
        FullUnitName +
        "</span></div>";
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - alert_resStaffunitinfo() - " + err.message
    );
  }
  return content;
}

function alert_ackstatus(
  StatusDec,
  CampusId,
  BuildingId,
  FloorId,
  RoomId,
  AlertId,
  alertcolor,
  AckedName,
  AckedMethod
) {
  let content = "";
  let ackedName = "";
  try {
    if (AckedMethod == "Self") AckedName = "Self";

    content += '<div style="margin: 5px 43px 0 12px;height:18px;">';
    content += "<table><tr>";
    content +=
      '<td style="background-color:white;min-width:10em;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;"><label>' +
      StatusDec +
      " by: </label></td>";
    content +=
      '<td style="background-color:white;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;">';

    if (StatusDec == enumAlertStatus.Complete || AckedMethod == "Self") {
      if (AckedName.length < 30)
        content +=
          '<label class="clslabel notfontSize" style="color:' +
          alertcolor +
          ' margin-right:-27px;text-align:right;">' +
          AckedName +
          "</label></td>";
      else if (AckedName.length > 30) {
        ackedName = AckedName.slice(0, 30) + "..";
        content +=
          '<div class="tooltip clslabel notfontSize" style="color:' +
          alertcolor +
          ' margin-right:-27px;text-align:right;">' +
          ackedName +
          '<span class="tooltiptext" style="left:-12%;white-space:pre-wrap;">' +
          AckedName +
          "</span></div></td>";
      }
    } else {
      if (AckedName.length < 30)
        content +=
          '<label class="clslabel notfontSize" style="color:' +
          alertcolor +
          ' cursor: pointer;margin-right:-27px;text-align:right;" data-CampusId=' +
          CampusId +
          " data-BuildingId=" +
          BuildingId +
          " data-FloorId=" +
          FloorId +
          " data-RoomId=" +
          RoomId +
          " data-AlertId=" +
          AlertId +
          ">" +
          AckedName +
          "</label></td>";
      else if (AckedName.length > 30) {
        ackedName = AckedName.slice(0, 30) + "..";
        content +=
          '<div class="tooltip clslabel notfontSize" style="color:' +
          alertcolor +
          ' cursor: pointer;margin-right:-27px;text-align:right;" data-CampusId=' +
          CampusId +
          " data-BuildingId=" +
          BuildingId +
          " data-FloorId=" +
          FloorId +
          " data-RoomId=" +
          RoomId +
          " data-AlertId=" +
          AlertId +
          ">" +
          ackedName +
          '<span class="tooltiptext" style="left:-12%;white-space:pre-wrap;">' +
          AckedName +
          "</span></div></td>";
      }
    }
    content += "</tr></table>";
    content += "</div>";
  } catch (err) {
    throw new Error("app-general.ts" + " - alert_ackstatus() - " + err.message);
  }
  return content;
}

function alert_timeinfo(
  Acurrenttime,
  AckedTime,
  StatusDec,
  alertcolor,
  Atime,
  type,
  alertid,
  alertflagempty,
  StrAckedData
) {
  
  let content = "";
  try {
    if (alertflagempty == 1) {
      alertnewtime = [];
    }
    let alertTime = "";
    if (type == 1) {
      alertids.push(alertid);
      alertnewtime.forEach(function (data) {
        if (data.AlertId == alertid) {
          alertTime = data.AlertTime;
        }
      });

      if (alertTime == "") alertTime = Atime;

      content += '<div style="margin: 5px 15px 0 10px;height:18px;">';
      content += '<table><tr style="background-color:white;">';
      content +=
        '<td style="background-color:white;min-width:15em;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;">';
      content +=
        "<label>Time of Alert: <span>" + Acurrenttime + "</span></label></td>";
      content +=
        '<td style="background-color:white;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;float:right;">';
      if (
        StatusDec != enumAlertStatus.Notified &&
        StatusDec != enumAlertStatus.Notify
      )
        content +=
          '<label style="color:' +
          alertcolor +
          '">' +
          StatusDec +
          " on " +
          StrAckedData +
          "</label>";
      else
        content +=
          '<label class="clstime" style="color:' +
          alertcolor +
          '">' +
          alertTime +
          "</label>";
      content += "</td>";
      content += "</tr></table>";
      content += "</div>";
    } else {
      alertnewtime.push({ AlertTime: Atime, AlertId: alertid });
      return cancelalertinfo;
    }
    if (alertlength == 1) {
      alertlength = 0;
      return content;
    } else {
      return cancelalertinfo;
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - alert_timeinfo() - " + err.message);
  }
}
function alert_newline() {
  let content = "";
  try {
    content += '<hr style="border-width:0.5px;">';
  } catch (err) {
    throw new Error("app-general.ts" + " - alert_newline() - " + err.message);
  }
  return content;
}

/** Map Section **/
function getboxFromYpos(ypos, imageHeight) {
  let fromYpos;
  try {
    if (imageHeight > ypos) {
      //if height is greater value for ypos then only possitive value other wise neagive
      fromYpos = imageHeight - ypos;
    } else {
      fromYpos = ypos;
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - getboxFromYpos() - " + err.message);
  }
  return fromYpos;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////                CO ORDINATE CALCULATIONS               /////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function calculatexycoordinates(roomcoordinatepoints, imgheight) {
  let Source = "";
  const pointList = [] as any[];
  const xypoint = [] as any[];
  let point;
  try {
    Source = roomcoordinatepoints;

    for (let k = 0; k < Source.length; k++) {
      const poloygonCoord = Source[k].split(",");
      if (poloygonCoord[0] != "" && poloygonCoord[1] != "") {
        if (imgheight == 0) {
          //this is for popup calculated the points on search
          point = new OlPoint([
            parseFloat(poloygonCoord[0]),
            parseFloat(poloygonCoord[1]),
          ]);
        } else {
          point = point = new OlPoint([
            parseFloat(poloygonCoord[0]),
            getboxFromYpos(parseFloat(poloygonCoord[1]), imgheight),
          ]);
        }
        pointList.push(point);
      }
    }
    let pointx = 0;
    let pointy = 0;

    pointList.forEach(function (d) {
      pointx = pointx + d.flatCoordinates[0];
      pointy = pointy + d.flatCoordinates[1];
    });

    pointx = pointx / pointList.length;
    pointy = pointy / pointList.length;

    xypoint.push(pointx, pointy);
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - calculatexycoordinates() - " + err.message
    );
  }
  return xypoint;
}

function map_widthandheight(ImageWidth, ImageHeight): any {
  let imgwidth = 0;
  let imgheight = 0;
  try {
    ImageWidth = ImageWidth != null ? parseFloat(ImageWidth) : 900;
    ImageHeight = ImageHeight != null ? parseFloat(ImageHeight) : 550;

    imgwidth = parseFloat(ImageWidth);
    imgheight = parseFloat(ImageHeight);

    if (imgwidth < 900) {
      imgwidth = Math.round(imgwidth);
      imgheight = Math.round(imgheight);
    } else {
      imgwidth = 900;
      if (imgheight > 0) {
        imgheight = parseInt(ImageHeight);
        imgheight *= imgwidth / parseInt(ImageWidth);
        imgheight = Math.round(imgheight);
      } else {
        imgheight = 0;
      }
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - map_widthandheight() - " + err.message
    );
  }
  return { ImageWidth: imgwidth, ImageHeight: imgheight };
}

//////////for Notification Alert//////////////

function secondsToHms(seconds) {
  // alert(seconds);
  let obj;
  try {
    const days = Math.floor(seconds / (24 * 60 * 60));
    seconds -= days * (24 * 60 * 60);
    const hours = Math.floor(seconds / (60 * 60));
    seconds -= hours * (60 * 60);
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    obj = {
      m: minutes,
      s: seconds,
    };
  } catch (err) {
    throw new Error("app-general.ts" + " - secondsToHms() - " + err.message);
  }
  return obj;
}
/** Map Section  End **/
function convertToSvgEcllipseXCoordinate(xpos, IsAdd) {
  try {
    if (IsAdd) {
      xpos = xpos - 7.5;
    } else {
      xpos = xpos + 7.5;
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - convertToSvgEcllipseXCoordinate() - " + err.message
    );
  }
  return xpos;
}

function convertToSvgEcllipseYCoordinate(ypos, imgheight) {
  try {
    ypos = imgheight - ypos;
    ypos = ypos - 7.5;
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - convertToSvgEcllipseYCoordinate() - " + err.message
    );
  }
  return ypos;
}

function getSelectedDevicetype_image(DeviceName) {
  let imgName, imgScale;
  if (DeviceName == DeviceTypeDesc.Emitter) {
    imgName = "mapMonitorSel.png";
    imgScale = 1;
  } else if (DeviceName == DeviceTypeDesc.HRex) {
    imgName = "mapStarsSel.png";
    imgScale = 1;
  } else if (DeviceName == DeviceTypeDesc.Rex) {
    imgName = "mapAccessPointSel.png";
    imgScale = 1;
  } else if (DeviceName == DeviceTypeDesc.LF ) {
    imgName = "Technology Overlay-Device-LF-Orange.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.Pullcord) {
    imgName = "Alerts-Pull Cord_Red.png";
    imgScale = 0.5;
  } else if (
    DeviceName == DeviceTypeDesc.DC ||
    DeviceName == DeviceTypeDesc.NewDC
  ) {
    imgName = "Technology Overlay-Device-DC-Orange.png";
    imgScale = 1;
  } else if (DeviceName == DeviceTypeDesc.Monitor) {
    imgName = "Monitor_Selected.png";
    imgScale = 1;
  } else if (DeviceName == DeviceTypeDesc.VW) {
    imgName = "virtualWall.png";
    imgScale = 1;
  } else if (DeviceName == DeviceTypeDesc.Gateway) {
    imgName = "ClearGateway_Yellow.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.UT) {
    imgName = "Technology Overlay-Device-DCT-Orange.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.UM) {
    imgName = "Monitor_Selected.png";
  } else if (DeviceName == DeviceTypeDesc.Intercom) {
    imgName = "Intercom_Selected.png";
    imgScale = 1;
  }

  return [imgName, imgScale];
}

function getDevicetype_image(DeviceName) {
  let imgName, imgScale;
  const voiceLicense = localStorage.getItem("voice_license") ?? "";
  if (DeviceName == DeviceTypeDesc.Emitter) {
    imgName = "TruLocator.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.HRex) {
    imgName = "ClearRouter.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.Rex) {
    imgName = "ClearRepeater.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.LF) {
    imgName = "LFAntenna.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.Pullcord) {
    imgName = "PullCord.png";
    imgScale = 0.5;
  } else if (
    DeviceName == DeviceTypeDesc.DC ||
    DeviceName == DeviceTypeDesc.NewDC
  ) {
    imgName = "SecurityController.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.Monitor) {
    imgName = "CommonArea Locator.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.VW) {
    imgName = "VirtualWallLocator.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.Gateway) {
    imgName = "GatewayEnclosure.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.UT) {
    imgName = "UniversalTransmitter.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.UM) {
    imgName = "CommonArea Locator.png";
    imgScale = 0.5;
  } else if (DeviceName == DeviceTypeDesc.Intercom && voiceLicense == "1") {
    imgName = "Intercom.png";
    imgScale = 0.5;
  }
  return [imgName, imgScale];
}

function get_alert_data(alertinfo, residentimgurl, staffImgUrl) {
  let content = "";
  let alertlevelImg;
  let AresImg;
  let pullcordImg;
  
  let alertcolor;
  const assetimage = "assets/images/";
  let subTypeIMg;
  cancelalertinfo = [];
  const map_SharedDetails = AppComponent.SharedDetails;
  let DCTImage;
  try {
    for (const AlertDetails of alertinfo) {
      alertlevelImg = assetimage + AlertDetails.LevelPhoto;
      if (AlertDetails.Alertresstaff != 0)
        if (
          AlertDetails.ResidentId == 0 &&
          AlertDetails.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
        ) {
          // resident
          AresImg = AlertDetails.Photo;
        } else {
          AresImg = residentimgurl + AlertDetails.Photo;
        }
      //staff
      else AresImg = staffImgUrl + AlertDetails.Photo;

      pullcordImg = assetimage + AlertDetails.PullCordImg;
      subTypeIMg = assetimage + AlertDetails.subtypeImg;
      alertcolor = AlertDetails.Alerttimecolor;
      
      cancelalertinfo.push(AlertDetails.AlertId);
      alertlength = 1;
      DCTImage = assetimage + AlertDetails.AlertDCTImage;

      if (AlertDetails.AlertType == enumAlertType.Pendant)
        content += '<div style="min-height:331px;">';
      else content += '<div style="min-height:301px;">';
      const TimeofAlert_Date = AlertDetails.AlertUpdatedTime;

      content += alert_description(
        AlertDetails.AlertDescript,
        alertcolor,
        AlertDetails.AlertNotifiedBy,
        alertlevelImg,
        AlertDetails.AlertType,
        AlertDetails.AlertSubName,
        AlertDetails.DeviceType,
        AlertDetails.SubTypeId,
        AlertDetails.TruViewEventType
      );
      content += alert_newline();
      content += alert_timeinfo(
        TimeofAlert_Date,
        AlertDetails.AckedTime,
        AlertDetails.StatusDec,
        alertcolor,
        AlertDetails.AlertTime,
        1,
        AlertDetails.AlertId,
        0,
        AlertDetails.StrAckedData
      );
      content += alert_newline();
      if (
        map_SharedDetails.SharedAlertId != "" &&
        map_SharedDetails.SharedAlertId == AlertDetails.AlertId
      ) {
        document.getElementById("spn_MapCampusId").textContent = AlertDetails.CampusId;
        document.getElementById("spn_MapBuildingId").textContent = AlertDetails.BuildId;
        document.getElementById("spn_MapFloorId").textContent = AlertDetails.FloorId;
        document.getElementById("spn_MapRoomId").textContent = AlertDetails.RoomId;
        document.getElementById("spn_MapAlertId").textContent = AlertDetails.AlertId;
        document.getElementById("spn_MapAlertType").textContent = AlertDetails.AlertType;
        document.getElementById("spn_MapAlertResStaff").textContent = AlertDetails.Alertresstaff;
        document.getElementById("spn_MapDevicetype").textContent = AlertDetails.DeviceType;
        document.getElementById("spn_MapDeviceSubtype").textContent = AlertDetails.SubTypeId;
        document.getElementById("spn_MapLocationtype").textContent = AlertDetails.LocationType;
      }
      if (
        AlertDetails.AlertType == enumAlertType.Pendant ||
        AlertDetails.AlertType == enumAlertType.Wander ||
        AlertDetails.AlertType == enumAlertType.Pull_Cord ||
        AlertDetails.AlertType == enumAlertType.DC ||
        AlertDetails.AlertType == enumAlertType.UT ||
        AlertDetails.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
      ) {
        content += alert_subname(
          AlertDetails.AlertSubName,
          AlertDetails.AlertType,
          alertcolor,
          AlertDetails.EventName,
          AlertDetails.AlerttypeName,
          AlertDetails.AlertFulltypeName,
          AlertDetails.TagId,
          AlertDetails.DeviceType,
          AlertDetails.SubTypeName,
          AlertDetails.SubTypeId,
          AlertDetails.Alert,
          AlertDetails.TruViewEventType
        );
        if (
          AlertDetails.AckStatus == enumAlertAckStatus.Accepted ||
          AlertDetails.AckStatus == enumAlertAckStatus.Presence ||
          AlertDetails.AckStatus == enumAlertAckStatus.Complete ||
          AlertDetails.AckStatus == enumAlertAckStatus.Cancel ||
          AlertDetails.AckStatus == enumAlertAckStatus.Caregiver_Alert_Presence
        ) {
          content += alert_newline();
          content += alert_ackstatus(
            AlertDetails.StatusDec,
            AlertDetails.CampusId,
            AlertDetails.BuildId,
            AlertDetails.FloorId,
            AlertDetails.RoomId,
            AlertDetails.AlertId,
            alertcolor,
            AlertDetails.AckedName,
            AlertDetails.AckedMethod
          );
        }
        
        ///////////////////////Location Probability///////////////////////////       
        console.log("AlertDetails : ")
        console.log(AlertDetails)
        if(AlertDetails.LocationType == 1)
        {
          content += alert_newline();  
          content += '<div style="margin: 5px 15px 0 10px;">'
          content += '<label>Location Probability</label>'
          content += '<table>'
          content += '<tr style="background-color:white;">'       
          content += '<td style="background-color:white;min-width:15em;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:14px;color:#DD1D34;">'
          content += '<label>' + AlertDetails.AlertLocation1 + '</label></td>'
          content += '<td style="background-color:white;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;float:right;color:#DD1D34;">'  
          content += '<label>' + AlertDetails.AlertLocation1Prob + '</label></td>'
          content += '</td>'
          content += '</tr>'
          
          content += '<tr style="background-color:white;">'       
          content += '<td style="background-color:white;min-width:15em;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:14px;color:#6d6b6b;">'
          content += '<label>' + AlertDetails.AlertLocation2 + '</label></td>'
          content += '<td style="background-color:white;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;float:right;color:#6d6b6b;">'  
          content += '<label>' + AlertDetails.AlertLocation2Prob + '</label></td>'
          content += '</td>'
          content += '</tr>'
          
          content += '<tr style="background-color:white;">'       
          content += '<td style="background-color:white;min-width:15em;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:14px;color:#6d6b6b;">'
          content += '<label>' + AlertDetails.AlertLocation3 + '</label></td>'
          content += '<td style="background-color:white;font-weight:normal;margin-bottom:0px;white-space: nowrap;font-size:12px;float:right;color:#6d6b6b;">'  
          content += '<label>' + AlertDetails.AlertLocation3Prob + '</label></td>'
          content += '</td>'
          content += '</tr>'
          
          content += '</table>'
          content += '</div>' 
        }                              
        ///////////////////////End Location Probability///////////////////////////
        
        content += alert_newline();
        if (
          AlertDetails.AlertType == enumAlertType.Pendant ||
          AlertDetails.AlertType == enumAlertType.Wander ||
          AlertDetails.AlertType == enumAlertType.DC ||
          AlertDetails.AlertType == enumAlertType.UT ||
          AlertDetails.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
        ) {
          if (AlertDetails.Alertresstaff != 0) {
            if (AlertDetails.AlertType == enumAlertType.UT) AresImg = DCTImage;
            content += '<table><tr style="background-color:white;">';

            content += '<td style="background-color:white;display:block;">';
            content += '<div class="clstdleft_info">';
            const styleinfo = ""; //no style
            content += alert_residnetinfo(
              AlertDetails.FirstName,
              AlertDetails.ResidentFirstFullName,
              AlertDetails.ResidentName,
              AlertDetails.ResidentLastFullName,
              AlertDetails.Age,
              AlertDetails.UnitName,
              AlertDetails.FullUnitName,
              AlertDetails.ResCondition,
              AlertDetails.ResFullCondition,
              styleinfo,
              AlertDetails.ResConditionflag
            );
            content += "</div>";
            content += "</td>";

            content += '<td style="background-color:white">';
            content += "<div>";
            content += '<div style="margin-right:15px;" align="right">';
            content +=
              "<img style='width:40;height:40px;' src='" + AresImg + "'><br>";
            content += alert_campusinfo(
              alertcolor,
              AlertDetails.CampusName,
              AlertDetails.FullCampusName,
              AlertDetails.BuildingName,
              AlertDetails.FullBuildingName,
              AlertDetails.FloorName,
              AlertDetails.FullFloorName,
              AlertDetails.location,
              AlertDetails.DeviceType,
              AlertDetails.AlertType,
              AlertDetails.TruViewEventType,
              AlertDetails.isOutdoor,
              AlertDetails.AlertCampusName
            );
            content += alert_resStaffunitinfo(
              alertcolor,
              AlertDetails.UnitName,
              AlertDetails.FullUnitName
            );
            content += "</div>";
            content += "</div>";
            content += "</td>";

            content += "</tr>";

            content += "<tr>";
            content += "<table>";

            content +=
              "<div class='tooltip' style='color:" +
              alertcolor +
              ";margin:2px 0px 2px 18px;width:190px;word-break:break-all;'>" +
              AlertDetails.location +
              "</div>";

            content += "</table>";
            content += "</tr>";

            content += "</table>";
          }
          if (AlertDetails.Alertresstaff == 0) {
            subTypeIMg = "";
            content += "<table><tr>";
            content += '<td style="background-color:white;width:110px;">';
            content += '<div class="clstdleft_info">';
            content += alert_residnetdetail(
              alertcolor,
              AlertDetails.FirstName,
              AlertDetails.ResidentFirstFullName,
              AlertDetails.ResidentName,
              AlertDetails.ResidentLastFullName,
              AlertDetails.Designation,
              AlertDetails.Phone,
              AlertDetails.Email
            );
            content += "</div>";
            content += "</td>";

            content +=
              '<td style="background-color:white;width:48px;height:55px;background-image: url(assets/images/Alerts-GrayCross-Pic-SideBar.png);background-repeat: no-repeat;">';
            content += "</td>";

            content += '<td style="background-color:white">';
            content += "<div>";
            content += '<div align="right"; style="margin-right:10px;">';
            content +=
              "<img style='width:40;height:40px;' src='" + AresImg + "'><br>";
            content += alert_campusinfo(
              alertcolor,
              AlertDetails.CampusName,
              AlertDetails.FullCampusName,
              AlertDetails.BuildingName,
              AlertDetails.FullBuildingName,
              AlertDetails.FloorName,
              AlertDetails.FullFloorName,
              AlertDetails.location,
              AlertDetails.DeviceType,
              AlertDetails.AlertType,
              AlertDetails.TruViewEventType,
              AlertDetails.isOutdoor,
              AlertDetails.AlertCampusName
            );
            content += alert_resStaffunitinfo(
              alertcolor,
              AlertDetails.UnitName,
              AlertDetails.FullUnitName
            );
            content += "</div>";
            content += "</div>";
            content += '<td style="background-color:white">';

            content += "</tr>";

            content += "<tr>";
            content += "<table>";
            content +=
              "<div class='tooltip' style='color:" +
              alertcolor +
              ";margin:2px 0px 2px 18px;width:190px;word-break:break-all;'>" +
              AlertDetails.location +
              "</div>";

            content += "</table>";
            content += "</tr>";
            content += "</table>";
          }
        } else if (
          AlertDetails.AlertType == enumAlertType.Pull_Cord ||
          AlertDetails.AlertType == enumAlertType.UT
        ) {
          content += '<div style="padding-left:15px;">';
          content += "<table><tr>";
          content +=
            '<td style="background-color:white;min-width:10em;font-weight:bold;margin-bottom:0px;white-space: nowrap;font-size:12px;">';
          content += alert_campusinfo(
            alertcolor,
            AlertDetails.CampusName,
            AlertDetails.FullCampusName,
            AlertDetails.BuildingName,
            AlertDetails.FullBuildingName,
            AlertDetails.FloorName,
            AlertDetails.FullFloorName,
            AlertDetails.location,
            AlertDetails.DeviceType,
            AlertDetails.AlertType,
            AlertDetails.TruViewEventType,
            AlertDetails.isOutdoor,
            AlertDetails.AlertCampusName
          );
          if (AlertDetails.AlertType == enumAlertType.Pull_Cord)
            content += alert_pull_cord(
              alertcolor,
              AlertDetails.UnitName,
              pullcordImg
            );
          else if (AlertDetails.AlertType == enumAlertType.UT)
            content += alert_DCT(
              alertcolor,
              AlertDetails.UnitName,
              AlertDetails.FullUnitName,
              DCTImage
            );
          content += "</tr>";

          content += "<tr>";
          content += "<table>";

          if (AlertDetails.location.length <= 15)
            content +=
              "<div class='tooltip' style='color:" +
              alertcolor +
              ";margin:2px 0px 2px 2px;'>" +
              AlertDetails.location +
              "</div>";
          else
            content +=
              "<div class='tooltip' style='color:" +
              alertcolor +
              ";margin:2px 0px 2px 2px;word-break:break-all;width:250px;display:inline-block;'> " +
              AlertDetails.location.slice(0, 20) +
              ".." +
              " <span class='tooltiptext'>" +
              AlertDetails.location +
              "</div>";

          content += "</table>";
          content += "</tr>";

          content += "</table>";

          content += "</div>";
        } 
      } else {
        //infra strcture alerts
        content += alert_subname(
          AlertDetails.AlertSubName,
          AlertDetails.AlertType,
          alertcolor,
          AlertDetails.EventName,
          AlertDetails.AlerttypeName,
          AlertDetails.AlertFulltypeName,
          AlertDetails.TagId,
          AlertDetails.DeviceType,
          AlertDetails.SubTypeName,
          AlertDetails.SubTypeId,
          AlertDetails.Alert,
          AlertDetails.TruViewEventType
        );
        if (
          AlertDetails.AckStatus == enumAlertAckStatus.Accepted ||
          AlertDetails.AckStatus == enumAlertAckStatus.Presence ||
          AlertDetails.AckStatus == enumAlertAckStatus.Complete ||
          AlertDetails.AckStatus == enumAlertAckStatus.Cancel ||
          AlertDetails.AckStatus == enumAlertAckStatus.Caregiver_Alert_Presence
        ) {
          content += alert_newline();
          content += alert_ackstatus(
            AlertDetails.StatusDec,
            AlertDetails.CampusId,
            AlertDetails.BuildId,
            AlertDetails.FloorId,
            AlertDetails.RoomId,
            AlertDetails.AlertId,
            alertcolor,
            AlertDetails.AckedName,
            AlertDetails.AckedMethod
          );
        }
        content += alert_newline();
        content += '<div style="margin: 0px 0px 0px 15px;">';
        content += "<table><tr>";
        content +=
          '<td style="background-color:white;min-width:8em;font-weight:bold;margin-bottom:0px;white-space: nowrap;font-size:12px;">';
        content += alert_campusinfo(
          alertcolor,
          AlertDetails.CampusName,
          AlertDetails.FullCampusName,
          AlertDetails.BuildingName,
          AlertDetails.FullBuildingName,
          AlertDetails.FloorName,
          AlertDetails.FullFloorName,
          AlertDetails.location,
          AlertDetails.DeviceType,
          AlertDetails.AlertType,
          AlertDetails.TruViewEventType,
          AlertDetails.isOutdoor,
          AlertDetails.AlertCampusName
        );
        content += alert_unitinfo(
          alertcolor,
          AlertDetails.UnitName,
          AlertDetails.FullUnitName,
          subTypeIMg,
          AlertDetails.DeviceType,
          AlertDetails.TruViewEventType
          
        );
        content += "</tr>";

        content += "<tr>";
        content += "<table>";
        content +=
          "<div style='color:" +
          alertcolor +
          ";font-size:12px;margin:0px 0px 2px 2px;background-color:white;'>";

        if (
          AlertDetails.DeviceType != alert_deviceType.Pager_Communication &&
          AlertDetails.TruViewEventType !=
            Alert_Event_Type.Appliance_UPS_IN_Battery
        ) {
          if (AlertDetails.location.length <= 25)
            content +=
              "<td style='background-color:white;color:" +
              alertcolor +
              ";width:190px !important;'><span>" +
              AlertDetails.location +
              "</span></td>";
          else
            content +=
              "<td style='background-color:white;color:" +
              alertcolor +
              ";width:190px !important;word-break:break-word;'><span>" +
              AlertDetails.location +
              "</span></td>";
        } else if (
          AlertDetails.TruViewEventType ==
            Alert_Event_Type.Appliance_UPS_IN_Battery ||
          AlertDetails.TruViewEventType ==
            Alert_Event_Type.Appliance_UPS_OUT_Battery
        ) {
          if (AlertDetails.location.length <= 25)
            content +=
              "<td style='background-color:white;color:" +
              alertcolor +
              ";width:190px !important;'><span>" +
              AlertDetails.location +
              "</span></td>";
          else
            content +=
              "<td style='background-color:white;color:" +
              alertcolor +
              ";width:190px !important;word-break:break-word;'><span>" +
              AlertDetails.location +
              "</span></td>";
        } else if (
          AlertDetails.DeviceType == alert_deviceType.Pager_Communication ||
          AlertDetails.TruViewEventType ==
            Alert_Event_Type.Appliance_UPS_Unplug ||
          AlertDetails.TruViewEventType ==
            Alert_Event_Type.Appliance_LostInternet
        )
          content +=
            "<td style='background-color:white;color:" +
            alertcolor +
            ";width:190px !important;'><span>Location: NA</span></td>";

        if (
          AlertDetails.TruViewEventType ==
            Alert_Event_Type.Appliance_UPS_IN_Battery ||
          AlertDetails.TruViewEventType ==
            Alert_Event_Type.Appliance_UPS_OUT_Battery ||
          AlertDetails.TruViewEventType ==
            Alert_Event_Type.Appliance_UPS_Unplug ||
          AlertDetails.TruViewEventType ==
            Alert_Event_Type.Appliance_LostInternet
        ) {
          content +=
            "<td align='right' style='background-color:white;color:" +
            alertcolor +
            ";'><span style='margin-right:15px;'>" +
            AlertDetails.Alert +
            "</span></td>";
        } else {
          if (AlertDetails.ResFirstFlag == 0 && AlertDetails.ResLastFlag == 0)
            content +=
              "<td align='right' style='background-color:white;color:" +
              alertcolor +
              ";'><span style='margin-right:15px;'>" +
              AlertDetails.FirstName +
              "  " +
              AlertDetails.ResidentName +
              "</span></td>";
          else {
            content +=
              "<td align='right' style='background-color:white;color:" +
              alertcolor +
              ";'> ";
            if (AlertDetails.ResidentFirstFullName.length < 15)
              content +=
                "<div class='tooltip' style='margin-right:15px;'>" +
                AlertDetails.FirstName +
                "</div> ";
            else
              content +=
                "<div class='tooltip' style='margin-right:15px;'>" +
                AlertDetails.FirstName +
                "<span class='tooltiptext'>" +
                AlertDetails.ResidentFirstFullName +
                "</span></div> ";
            if (AlertDetails.ResidentLastFullName.length < 15)
              content +=
                "<div class='tooltip' style='margin-right:15px;'>" +
                AlertDetails.ResidentName +
                "</div></td>";
            else if (
              AlertDetails.ResidentLastFullName.length > 15 &&
              (AlertDetails.DeviceType == alert_deviceType.DC ||
                AlertDetails.DeviceType == alert_deviceType.UT)
            )
              content +=
                "<div class='tooltip' style='margin-right:15px;'>" +
                AlertDetails.ResidentLastFullName +
                "</div></td>";
            else
              content +=
                "<div class='tooltip' style='margin-right:15px;'>" +
                AlertDetails.ResidentName +
                "<span class='tooltiptext'>" +
                AlertDetails.ResidentLastFullName +
                "</span></div> ";
          }
        }
        content += "</div>";
        content += "</table>";
        content += "</tr>";

        content += "</table>";
        content += "</div>";
      }
      // 'Notifications button at the bottom'
      content += alert_notification_button(
        AlertDetails.AlertType,
        AlertDetails.RoomId,
        AlertDetails.AlertId,
        AlertDetails.FloorId,
        AlertDetails.AckStatus,
        AlertDetails.Alertresstaff,
        AlertDetails.DeviceType,
        AlertDetails.SubTypeId,
        AlertDetails.LocationType
      );
      content += "</div>";
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - get_alert_data() - " + err.message);
  }
  return content;
}

function get_alert_data_search(
  alertinfo,
  residentimgurl,
  staffImgUrl,
  cordiX,
  cordiY
) {
  let content = "";
  let AresImg;
  try {
    for (const AlertDetails of alertinfo) {
      if (AlertDetails.Alertresstaff != 0)
        AresImg = residentimgurl + AlertDetails.Photo;
      else AresImg = staffImgUrl + AlertDetails.Photo;
      content += alert_data_search(
        AresImg,
        AlertDetails.FirstName,
        AlertDetails.ResidentId,
        AlertDetails.ResidentName,
        cordiX,
        cordiY,
        residentimgurl,
        staffImgUrl,
        AlertDetails.AlertId,
        AlertDetails.FloorName,
        AlertDetails.FullFloorName,
        AlertDetails.UnitName,
        AlertDetails.FullUnitName,
        AlertDetails.CampusId,
        AlertDetails.BuildId,
        AlertDetails.FloorId,
        AlertDetails.RoomId,
        AlertDetails.locationpopupWidth
      );
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - get_alert_data_search() - " + err.message
    );
  }
  return content;
}

function get_alert_data_floor_search(
  alertinfo,
  residentimgurl,
  staffImgUrl,
  cordiX,
  cordiY,
  map_SharedDetails
) {
  let content = "";
  let AresImg;
  try {
    for (const AlertDetails of alertinfo) {
      if (AlertDetails.Alertresstaff != 0)
        AresImg = residentimgurl + AlertDetails.Photo;
      else AresImg = staffImgUrl + AlertDetails.Photo;

      content += alert_data_floor(
        AresImg,
        AlertDetails.FirstName,
        AlertDetails.ResidentName,
        cordiX,
        cordiY,
        residentimgurl,
        staffImgUrl,
        AlertDetails.FloorName,
        AlertDetails.FullFloorName,
        AlertDetails.UnitName,
        AlertDetails.FullUnitName,
        map_SharedDetails.SharedCampusId,
        map_SharedDetails.SharedBuildingId,
        map_SharedDetails.SharedFloorId,
        AlertDetails.AlertId,
        map_SharedDetails.SharedAlertStatus,
        AlertDetails.locationpopupWidth,
        AlertDetails.Building_SVGID
      );
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - get_alert_data_floor_search() - " + err.message
    );
  }
  return content;
}

function getlocation_data(alertinfo, residentimgurl, staffImgUrl) {
  let content = "";
  let ResImg;

  try {
    for (const AlertDetails of alertinfo) {
      if (AlertDetails.Alertresstaff != 0)
        ResImg = residentimgurl + AlertDetails.Photo;
      else ResImg = staffImgUrl + AlertDetails.Photo;

      content += alert_location_data_detail(
        AlertDetails.FirstName,
        AlertDetails.ResidentFirstFullName,
        AlertDetails.ResidentName,
        AlertDetails.ResidentLastFullName,
        AlertDetails.Age,
        AlertDetails.UnitName,
        AlertDetails.FullUnitName,
        AlertDetails.ResCondition,
        ResImg,
        AlertDetails.CampusName,
        AlertDetails.FullCampusName,
        AlertDetails.BuildingName,
        AlertDetails.FullBuildingName,
        AlertDetails.FloorName,
        AlertDetails.FullFloorName,
        AlertDetails.ResFullCondition,
        AlertDetails.ResConditionflag
      );

    
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - getlocation_data() - " + err.message
    );
  }
  return content;
}

function maponclicked(
  map,
  popup,
  select,
  map_sharedService,
  map_length,
  alertdatainfo,
  residentimgurl,
  staffImgUrl,
  image_path,
  Router,
  _dialog
) {
  map.on("click", function (evt) {
    try {
      if (!SessionCheck(Router)) {
        return;
      }
      alertidflag = 1;
      const pixel = evt.pixel;
      const corrdinates = evt.coordinate;
      let buildingid = 0;
      const feature = map.forEachFeatureAtPixel(pixel, function (feature, layer) {
        console.log(layer);
        return feature;
      });

      if (feature) {
        let featureid;
        if (feature.id_ != undefined) featureid = feature.id_;
        //alert("featureid :"+featureid);
        if (featureid != undefined && featureid == "StaffLocation") {
          map.getOverlays().clear();
          const coord = corrdinates;
          popup = new Popup({
            insertFirst: false,
            name: "StaffLocation-" + feature.get("TagId"),
            type: feature.get("type"),
          });
          popup.autoPan = true;
          popup.autoPanAnimation = { duration: 250 };
          map.addOverlay(popup);
          let content = "";
          content +=
            '<div style="height:35px;margin:0px;background-color:#E6E6E6">';
          content +=
            '<div style="padding-top:5px;padding-left:5px;white-space: nowrap;">';
          content +=
            '<img style="padding-right:10px;width:20px;height:20px;" src="assets/images/map/Alerts-Map-Location-Icon.png">';
          content +=
            '<label style=" color:#005493;font-size:18px;font-weight:bold">Live Updates</label></div>';
          content += "</div>";
          content += '<div class="clsLocationpopup" style="min-height:50px">';
          content += "<div>";
          content +=
            "<label>" +
            feature.get("TagId") +
            " entered '" +
            feature.get("LastRoomName") +
            "' from '" +
            feature.get("RoomName") +
            "' at " +
            feature.get("LastRoomUpdatedOn");
          content += "</div>";
          content += "</div>";
          const floornameinfo = undefined;
          const el = load_alertpopup_style(floornameinfo, content, 3, "250px");
          popup.show(coord, el);
        } else if (
          featureid != undefined &&
          feature.get("Type") != undefined &&
          featureid.substring(0, 7) == "roomid-"
        ) {
          //alert(featureid);          
          const Residents = AppComponent.SharedResidentStaffDetails;
          let roomId = featureid;
          roomId = roomId.replace("roomid-", "");
          roomId = roomId.split("-")[0];
          let content = "";
          const coord = corrdinates;
          if (roomId in Residents) {
            map.getOverlays().clear();            
            popup = new Popup({
              insertFirst: false,
              name: "StaffLocation-" + feature.get("TagId"),
              type: feature.get("Type"),
            });
            popup.autoPan = true;
            popup.autoPanAnimation = { duration: 250 };
            map.addOverlay(popup);
            console.log(coord);
            content +=
              '<div style="height:35px;margin:0px;background-color:#E6E6E6">';
            content +=
              '<div style="padding-top:5px;padding-left:5px;white-space: nowrap;">';
            content +=
              '<img style="padding-right:10px;width:20px;height:20px;" src="assets/images/map/Alerts-Map-Location-Icon.png">';
            content +=
              '<label style=" color:#005493;font-size:18px;font-weight:bold">Information</label></div>';
            content += "</div>";

            const arrMarkerResident = Residents[roomId];
            for (let idx = 0; idx < arrMarkerResident.length; idx++) {
              let Photo = "";
              if (feature.get("Type") == enumCheckResidenStaff.Resident) {
                Photo =
                  appsettings.RESIDENT_FOLDER + arrMarkerResident[idx].Photo;
              } else if (feature.get("Type") == enumCheckResidenStaff.Staff) {
                Photo = appsettings.STAFF_FOLDER + arrMarkerResident[idx].Photo;
              }
              if (feature.get("Type") == arrMarkerResident[idx].Type) {
                let roomdata = "";
                let prevoiusLocationName;
                if (arrMarkerResident[idx].LastRoomName != "") {
                  prevoiusLocationName = arrMarkerResident[idx].LastRoomName;
                  if (prevoiusLocationName.length <= 20)
                    roomdata =
                      '<div class="tooltip"><span> since ' +
                      arrMarkerResident[idx].UpdatedTime +
                      '</span></div><div class="tooltip"><span> Previous Location: ' +
                      arrMarkerResident[idx].LastRoomName +
                      "</span></div>";
                  else
                    roomdata =
                      '<div class="tooltip"><span> since ' +
                      arrMarkerResident[idx].UpdatedTime +
                      '</span></div><div class="tooltip"><span> Previous Location: ' +
                      prevoiusLocationName.slice(0, 15) +
                      ".." +
                      '</span><span class="tooltiptext">' +
                      prevoiusLocationName +
                      "</span></div>";
                }

                const ResidentFirstName = arrMarkerResident[idx].FirstName;
                const ResidentLastName = arrMarkerResident[idx].LastName;
                const ResidentFullName =
                  arrMarkerResident[idx].FirstName +
                  " " +
                  arrMarkerResident[idx].LastName;
                let strResidentName;                
                let strCurrentLocationName;                
                let strResidentEmail;
                let clsResidentName;
                // arrMarkerResident[idx].FirstName + '&nbsp;' + arrMarkerResident[idx].LastName
                if (
                  ResidentFirstName.length >= 15 &&
                  ResidentLastName.length < 15
                ) {
                  clsResidentName = "margin-bottom:-8em;";
                  strResidentName =
                    '<div class="tooltip" style="width:auto;font-size:16px;padding-bottom:7px;";><span>' +
                    ResidentFirstName.slice(0, 15) +
                    ".." +
                    "&nbsp;" +
                    ResidentLastName +
                    "</span> " +
                    '<span class="tooltiptext" style= ' +
                    clsResidentName +
                    ">" +
                    ResidentFirstName +
                    "</span></div>";
                }
                if (
                  ResidentFirstName.length < 15 &&
                  ResidentLastName.length >= 15
                ) {
                  clsResidentName = "margin-bottom:-8em;";
                  strResidentName =
                    '<div class="tooltip" style="width:auto;font-size:16px;padding-bottom:7px;";><span>' +
                    ResidentFirstName +
                    "&nbsp;" +
                    ResidentLastName.slice(0, 15) +
                    ".." +
                    "</span> " +
                    '<span class="tooltiptext" style= ' +
                    clsResidentName +
                    ">" +
                    ResidentLastName +
                    "</span></div>";
                } else if (
                  ResidentFirstName.length >= 15 &&
                  ResidentLastName.length >= 15
                ) {
                  clsResidentName = "margin-bottom:-8em;";
                  strResidentName =
                    '<div class="tooltip" style="width:auto;font-size:16px;padding-bottom:7px;";><span>' +
                    ResidentFirstName.slice(0, 10) +
                    ".." +
                    "&nbsp;" +
                    ResidentLastName.slice(0, 10) +
                    ".." +
                    "</span> " +
                    '<span class="tooltiptext" style= ' +
                    clsResidentName +
                    ">" +
                    ResidentFullName +
                    "</span></div>";
                } else if (
                  ResidentFirstName.length < 15 &&
                  ResidentLastName.length < 15
                )
                  strResidentName =
                    '<div style="font-size:16px;padding-bottom:7px;">' +
                    arrMarkerResident[idx].FirstName +
                    "&nbsp;" +
                    arrMarkerResident[idx].LastName +
                    "</div>";

                const CurrentLocationName = arrMarkerResident[idx].RoomName;

                if (CurrentLocationName.length >= 20) {
                  strCurrentLocationName =
                    '<div class="tooltip"><span> Current Location: ' +
                    CurrentLocationName.slice(0, 15) +
                    ".." +
                    "</span>" +
                    '<span class="tooltiptext">' +
                    CurrentLocationName +
                    "</span></div>" +
                    roomdata;
                } else
                  strCurrentLocationName =
                    '<div class="tooltip"><span> Current Location: ' +
                    arrMarkerResident[idx].RoomName +
                    "</span></div>" +
                    roomdata;

                const ResidentEmail = arrMarkerResident[idx].Email;
                if (ResidentEmail.length >= 20) {
                  strResidentEmail =
                    '<div class="tooltip" style="width:auto"><span>' +
                    ResidentEmail.slice(0, 13) +
                    ".." +
                    "</span>" +
                    '<span class="tooltiptext">' +
                    ResidentEmail +
                    "</span></div></div>";
                } else
                  strResidentEmail =
                    '<div class="tooltip" style="width:auto"><span>' +
                    arrMarkerResident[idx].Email +
                    "</span></div></div>";

                content +=
                  '<div class="clsLocationpopup" style="margin:5px; min-height: 100px;">' +
                  "<table><tbody>" +
                  '<tr><td style="background-color:white;">' +
                  '<div style="color:black;background-color:white;float:left;;margin-bottom:0px;min-width:150px;font-size:14px;width:315px;">' +
                  strResidentName +
                  "<div>TagId: <span>" +
                  arrMarkerResident[idx].TagId +
                  "</span></div>" +
                  strCurrentLocationName +
                  "</div></td>" +
                  '<td style="background-color:white;">' +
                  '<div style="color:#005493;background-color:white;margin-right:20px;margin-bottom:0px;font-size:14px;width:150px;text-align:right;">' +
                  '<div><img style="width:120;height:40px;padding-bottom:15px;" src="' +
                  Photo +
                  '"></div>' +
                  '<div class="tooltip" style="width:auto"><span>' +
                  arrMarkerResident[idx].Gender +
                  "</span></div>" +
                  // '<div class="tooltip" style="width:auto"><span>' + arrMarkerResident[idx].Email + '</span></div></div>' +
                  strResidentEmail +
                  "</td></tr>" +
                  "</tbody></table>" +
                  "<hr></div>";
              }
            }
            content = content.replace(new RegExp("<hr></div>" + "$"), "</div>");
            content += "</div>";
          }
          if (content != "") {
            const floornameinfo = undefined;
            const el = load_alertpopup_style(floornameinfo, content, 1, 0);
            popup.show(coord, el);
          }
        } else {
          map.getOverlays().clear();                    
          const coord = corrdinates;
          let content = "";
          if (feature.get("SearchAlertId") == undefined) {
            if (feature.get("floorid") != undefined)
              popup = new Popup({
                insertFirst: false,
                name: "floorid-" + feature.get("floorid"),
                devicename: "roomalertid-" + feature.get("DeviceAlertId"),
              });
            else
              popup = new Popup({
                insertFirst: false,
                name: "buildingid-" + feature.get("buildingid"),
              });
            popup.autoPan = true;
            popup.autoPanAnimation = { duration: 250 };
            map.addOverlay(popup);
          }
          

          alertdatainfo = AppComponent.SharedAlertDetails;
          const alertbaseddatainfo = AppComponent.SharedAlertBasedDetails;
          const buildbaseddatainfo = AppComponent.SharedBuildBasedDetails;          
          const map_SharedDetails = AppComponent.SharedDetails;
          
          if (feature.get("DeviceAlertId") != undefined) {
            let DeviceRoomId;            
            let AlertDetails;
            if (feature.get("DeviceRoomId") != undefined)
              DeviceRoomId = feature.get("DeviceRoomId");
            else DeviceRoomId = feature.get("DeviceId");
            const DeviceFloorId = feature.get("floorid");
            const DeviceTypeId = feature.get("DeviceTypeId");
            if (JSON.stringify(alertdatainfo) != undefined) {
              if (DeviceRoomId != 0) {
                AlertDetails = alertdatainfo.filter(function (e1) {
                  if (
                    e1.AlertType == enumAlertType.Pull_Cord &&
                    e1.ismobilitymode == 1 &&
                    e1.DeviceType != enumDeviceType.HREX &&
                    e1.DeviceType != enumDeviceType.REX
                  ) {
                    return (
                      e1.RoomId == DeviceRoomId &&
                      e1.FloorId == DeviceFloorId &&
                      e1.DeviceType == DeviceTypeId
                    );
                  } else if (
                    e1.AlertType == enumAlertType.Pull_Cord &&
                    e1.ismobilitymode == 0 &&
                    e1.DeviceType != enumDeviceType.HREX &&
                    e1.DeviceType != enumDeviceType.REX
                  ) {
                    return (
                      e1.TagId == DeviceRoomId && e1.FloorId == DeviceFloorId
                    );
                  } else if (
                    e1.AlertType == enumAlertType.UT &&
                    e1.ismobilitymode == 1
                  ) {
                    return (
                      e1.RoomId == DeviceRoomId && e1.FloorId == DeviceFloorId
                    );
                  } else if (
                    e1.AlertType == enumAlertType.UT &&
                    e1.ismobilitymode == 0
                  ) {
                    return (
                      e1.TagId == DeviceRoomId && e1.FloorId == DeviceFloorId
                    );
                  } else {
                    let _RoomId = e1.RoomId;
                    if (_RoomId == null || _RoomId == null || _RoomId == 0) {
                      _RoomId = e1.LastRoomId;
                    }
                    if (DeviceTypeId == enumDeviceType.ROOM.toString()) {
                      return (
                        e1.UnitId == DeviceRoomId &&
                        e1.FloorId == DeviceFloorId &&
                        e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
                      );
                    } else {
                      return (
                        _RoomId == DeviceRoomId &&
                        e1.FloorId == DeviceFloorId &&
                        (CheckAlertByDeviceTypeId(
                          e1.LocationType,
                          e1.DeviceType,
                          DeviceTypeId
                        ) ||
                          e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm)
                      );
                    }
                  }
                });

                if (feature.get("isInfraFloor") == 0) {
                  if (AlertDetails) {
                    let Chk_AlertDetails;
                    Chk_AlertDetails = AlertDetails.filter(function (e1) {
                      return (
                        e1.SubTypeId == 0 ||
                        e1.SubTypeId == enumAlertSubType.Fire_Alarm ||
                        e1.AlertType == enumAlertType.DC ||
                        e1.AlertType == enumAlertType.Pull_Cord ||
                        e1.AlertType == enumAlertType.UT || e1.AlertType == enumAlertType.Pendant
                      );
                    });
                    if (
                      Chk_AlertDetails == undefined ||
                      Chk_AlertDetails.length == 0
                    ) {
                      Chk_AlertDetails = AlertDetails.filter(function (e1) {
                        return e1.AlertType == enumAlertType.DC;
                      });
                    }
                    if (
                      Chk_AlertDetails == undefined ||
                      Chk_AlertDetails.length == 0
                    ) {
                      Chk_AlertDetails = AlertDetails.filter(function (e1) {
                        return e1.AlertType == enumAlertType.Pull_Cord;
                      });
                    }

                    if (
                      Chk_AlertDetails == undefined ||
                      Chk_AlertDetails.length == 0
                    ) {
                      Chk_AlertDetails = AlertDetails.filter(function (e1) {
                        return (
                          e1.AlertType == enumAlertType.UT ||
                          e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
                        );
                      });
                    }
                    AlertDetails = Chk_AlertDetails;
                  }
                }
              }
              //floor image click to show the alert popup info
              content = get_alert_data(
                AlertDetails,
                residentimgurl,
                staffImgUrl
              );
            }
          } else if (feature.get("SearchAlertId") != undefined) {
            const SearchAlertId = feature.get("SearchAlertId");
            const DeviceAlertId = feature.get("DeviceAlertId");
            if (JSON.stringify(alertbaseddatainfo) != undefined) {
              let AlertDetails;
              if (DeviceAlertId != 0) {
                if (SearchAlertId in alertbaseddatainfo) {
                  AlertDetails = alertbaseddatainfo[SearchAlertId];
                }
              }
              //once closed resident search to be click to show the popup.
              if (AlertDetails != undefined) {
                if (feature.get("SearchType") == "Building") {
                  content = get_alert_data_search(
                    AlertDetails,
                    residentimgurl,
                    staffImgUrl,
                    coord[0],
                    coord[1]
                  );
                } else {
                  content = get_alert_data_floor_search(
                    AlertDetails,
                    residentimgurl,
                    staffImgUrl,
                    coord[0],
                    coord[1],
                    map_SharedDetails
                  );
                }
                popup["type"] = "SearchPopup";
              }
            }
          } else if (feature.get("iconStyleCall") != undefined || feature.get('buildingid').toString().split('_').length ==2) {
            buildingid = feature.get("buildingid");
            // AlertnotificationComponent.isnotifyinfraClosed = true;
            if (JSON.stringify(buildbaseddatainfo) != undefined) {
              let AlertDetails;
              if (buildingid != 0 && buildingid != undefined) {
                if (buildingid in buildbaseddatainfo) {
                  AlertDetails = buildbaseddatainfo[buildingid];
                }
              }
              if (feature.get("Isinfra") == 0) {
                let Chk_AlertDetails;
                if(AlertDetails != undefined) {
                Chk_AlertDetails = AlertDetails.filter(function (e1) {
                  return (
                    e1.SubTypeId == 0 ||
                    e1.SubTypeId == enumAlertSubType.Fire_Alarm ||
                    e1.AlertType == enumAlertType.DC ||
                    e1.AlertType == enumAlertType.Pull_Cord ||
                    e1.AlertType == enumAlertType.UT ||
                    e1.AlertType == enumAlertType.Pendant
                  );
                });
              }
                if (
                  Chk_AlertDetails == undefined ||
                  Chk_AlertDetails.length == 0
                ) {
                  Chk_AlertDetails = AlertDetails.filter(function (e1) {
                    return e1.AlertType == enumAlertType.DC;
                  });
                }
                if (
                  Chk_AlertDetails == undefined ||
                  Chk_AlertDetails.length == 0
                ) {
                  Chk_AlertDetails = AlertDetails.filter(function (e1) {
                    return e1.AlertType == enumAlertType.Pull_Cord;
                  });
                }
                if (
                  Chk_AlertDetails == undefined ||
                  Chk_AlertDetails.length == 0
                ) {
                  Chk_AlertDetails = AlertDetails.filter(function (e1) {
                    return (
                      e1.AlertType == enumAlertType.UT ||
                      e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
                    );
                  });
                }

                AlertDetails = Chk_AlertDetails;
              }
              //campus image click to show the alert popup info
              if (AlertDetails != undefined)
                content = get_alert_data(
                  AlertDetails,
                  residentimgurl,
                  staffImgUrl
                );
            }
          } else if (
            feature.get("buildingid").toString().split("_").length <= 1
          ) {
            // floor info drop down for this else case
            if (feature.get("FloorName") == null) {
              generateAlertMessage(
                _dialog,
                "Zone not found for " + feature.get("buildingname")
              );
            } else {
              const arrFloor = feature.get("FloorName").split(",");
              const arrFloorId = feature.get("FloorID").split(",");  
              const arrSubZone = feature.get('SubZoneName').split(',')
              const arrSubZoneId = feature.get('SubZoneID').split(',')            
              const floorbaseddatainfo = AppComponent.SharedFloorBasedDetails;
              content = load_floorpopup_content(
                arrFloor,
                arrFloorId,
                arrSubZone, 
                arrSubZoneId,
                map_length,
                image_path,
                floorbaseddatainfo
              );
            }
          }

          if (content != "") {
            let width = 2;
            if (feature.get("FloorName") == undefined) {
              width = 1;
            }
            const el = load_alertpopup_style(
              feature.get("FloorName"),
              content,
              width,
              0
            ); //need height information for alert popup

            if (coord.length == 2) {
              if (feature.get("SearchAlertId") == undefined) {
                popup.show(coord, el);
              } else {
                popup = new Popup({ insertFirst: false });
                popup.autoPan = true;
                popup.autoPanAnimation = { duration: 250 };
                map.addOverlay(popup);
                popup.show(coord, el);
              }
            } else {
              const arrcord = [] as any[];
              for (let ncoord = 0; ncoord <= coord[0].length - 1; ncoord++) {
                arrcord[ncoord] =
                  coord[0][ncoord][0] + "," + coord[0][ncoord][1];
              }
              const coords = calculatexycoordinates(arrcord, 0); //this for search popup
              popup = new Popup({ insertFirst: false });
              popup.autoPan = true;
              popup.autoPanAnimation = { duration: 250 };
              map.addOverlay(popup);
              popup.show(coords, el);
            }
            
            popupclick_invoke(
              popup,
              map,
              select,
              map_sharedService,
              map_length,
              alertdatainfo,
              Router
            );
          }
        }
      }
    } catch (err) {
      throw new Error("app-general.ts" + " - maponclicked() - " + err.message);
    }
  });
    
  map.on('pointermove', function(evt) {
    const pixel = evt.pixel;
    const corrdinates = evt.coordinate;  
    let featureid="";  
    let featurepointid="";
    const feature = map.forEachFeatureAtPixel(pixel,
      function (feature, layer) {
        console.log(layer)
        return feature;
      });
      
      if (feature)
      {
        featureid = feature.values_['RoomName'];      
        featurepointid=feature.id_;
      }
      
      //remove the existing overlay   
      const popupfeatures = map.getOverlays();
      const arrpopupfeatures = popupfeatures.array_;
      if (arrpopupfeatures != undefined && arrpopupfeatures.length > 0) 
      {
        for (let popidx = 0; popidx < arrpopupfeatures.length; popidx++) 
        {          
          const popname = arrpopupfeatures[popidx]["options"]['name'];
          if (  popname == 'SpaceInformation')
            map.removeOverlay(arrpopupfeatures[popidx]);
        
        }
      }
      
       //check the feature and show the toolbar on the space over.
      // if (feature != undefined && featureid!= undefined && (featurepointid =="" || featurepointid ==undefined) && map.overlays_.array_[0] ==undefined ) 
      if (feature != undefined && featureid!= undefined && (featurepointid =="" || featurepointid ==undefined)) 
      {             
        // map.getOverlays().clear();
        const content =  featureid ;
        popup = new Popup({ insertFirst: false, name: "SpaceInformation" });
        popup.autoPan = true;
        popup.autoPanAnimation={duration: 250};
        const popcontent=popup.container;
        popcontent.removeChild(popcontent.firstChild);
        popcontent.style.padding="0px";
        popcontent.className="ol-tooltip1";
        
        map.addOverlay(popup);
        const el = document.createElement("div");
        el.style.backgroundColor = "#6E6C6C";
        el.style.color = "#FFF";
        el.style.paddingLeft="10px";
        el.style.paddingRight="10px";
        el.style.paddingTop="5px";
        el.style.cursor = "auto";        
        el.style.width ="auto";
        el.style.borderRadius = "6px";
        el.style.wordBreak = "break-word";
        const content_p = document.createElement("p");
        content_p.style.overflowY = "auto";
        content_p.style.overflowX = "hidden";
        content_p.style.height ="30px";
        content_p.innerHTML = content;
        el.appendChild(content_p);
        
        popup.show(corrdinates, el);

      }
      // else
      // {
      //   map.getOverlays().clear();
      // }   
  });
  
}

function popupclick_invoke(
  popup,
  map,
  select,
  map_sharedService,
  map_length,
  alertdatainfo,
  Router
) {
  const elementAlert = document.getElementsByClassName("card-container-left");
  const alertstyle = getComputedStyle(elementAlert[0]);
  const elementHome = document.getElementsByClassName("card-container-right");
  const homestyle = getComputedStyle(elementHome[0]);
  const infraopen = AppComponent.ShareInfraopenDetails;
  const screenwidth = window.innerWidth;
  if (
    (alertstyle.display == "block" &&
      infraopen.IsfraOpen == 0 &&
      screenwidth <= 500) ||
    (homestyle.display == "block" &&
      infraopen.IsfraOpen == 1 &&
      screenwidth <= 500)
  ) {
    const btnAcceptCompleteCall = document.getElementById("btn_AlertMapCall");
    if(btnAcceptCompleteCall != null)
      btnAcceptCompleteCall.click();
  }
  popup.getElement().addEventListener(
    "click",
    function (e) {
      try {
        if (!SessionCheck(Router)) {
          return;
        }
        let action = e.target.getAttribute("data-action");
        const dataAlertId = e.target.getAttribute("data-AlertId");
        const SearechAlertId = e.target.getAttribute("data-SearchAlertId");
        const LocationAlertId = e.target.getAttribute("data-LocationAlertId");
        const SearchAlertStatus = e.target.getAttribute("data-SearchAlertStatus");
        const AlertAcceptedId = e.target.getAttribute("data-AcceptAlertId");        
        const alertbaseddatainfo = AppComponent.SharedAlertBasedDetails;

        if (e.target.className == "ol-popup-closer") {
          //  AlertnotificationComponent.isnotifyinfraClosed = false;          
          const map_SharedDetails = AppComponent.SharedDetails;
          map_SharedDetails.SharedAlertId = "";
          map_sharedService.changeShared(map_SharedDetails);
          alertids = [];
          select.getFeatures().clear();
          map.removeOverlay(popup);
        }
        if (action) {
          //floor popup click
          if (action != "yes") {
            action = action.substr(0, action.lastIndexOf("_"));
            action = action + "_0_0"; // 8
            const arrMapDetails = action.split("_");
            if (arrMapDetails.length == map_length) {
              const element_selected = document.getElementById("mapselected");
              const btn_MapDetailsChanged = document.getElementById("btn_MapDetailsChanged");
              if (element_selected != null) element_selected.innerHTML = action;              
              if(btn_MapDetailsChanged != null) btn_MapDetailsChanged.click();              
            }
          }
          e.preventDefault();
        } else if (SearchAlertStatus) {
          //search resident from floor

          const search_CampusId = e.target.getAttribute("data-SearchCampusId");
          const search_BuildingId = e.target.getAttribute("data-SearchBuildingId");
          const search_FloorId = e.target.getAttribute("data-SearchFloorId");
          const search_AlertId = e.target.getAttribute("data-SearchAlertId");
          action =
            search_CampusId +
            "_" +
            search_BuildingId +
            "_" +
            search_FloorId +
            "_" +
            search_AlertId +
            "_" +
            SearchAlertStatus +
            "_0_0"; //7 items
          const arrMapDetails = action.split("_");

          //alert('arrMapDetails.length :' + arrMapDetails.length);
          if (arrMapDetails.length == map_length) {
            const element_selected = document.getElementById("mapselected");
            const btn_MapDetailsChanged = document.getElementById("btn_MapDetailsChanged");
            if(element_selected != null) element_selected.innerHTML = action;
            if(btn_MapDetailsChanged != null) btn_MapDetailsChanged.click();
          }
        } else if (dataAlertId) {
          const action_CampusId = e.target.getAttribute("data-CampusId");
          const action_BuildingId = e.target.getAttribute("data-BuildingId");
          const action_FloorId = e.target.getAttribute("data-FloorId");
          const action_RoomId = e.target.getAttribute("data-RoomId");
          const action_AlertId = e.target.getAttribute("data-AlertId");
          const action_Alerttype = e.target.getAttribute("data-Alerttype");
          const action_AlerResStaff = e.target.getAttribute("data-ResStaff");
          const action_DeviceType = e.target.getAttribute("data-DeviceType");
          const action_DeviceSubType = e.target.getAttribute("data-DeviceSubType");
          const action_LocationType = e.target.getAttribute("data-LocationType");

          document.getElementById("spn_CampusId").textContent = action_CampusId;
          document.getElementById("spn_BuildingId").textContent = action_BuildingId;
          document.getElementById("spn_FloorId").textContent = action_FloorId;
          document.getElementById("spn_RoomId").textContent = action_RoomId;
          document.getElementById("spn_AlertId").textContent = action_AlertId;
          document.getElementById("spn_AlertType").textContent = action_Alerttype;
          document.getElementById("spn_AlertResStaff").textContent = action_AlerResStaff;
          document.getElementById("spn_Devicetype").textContent = action_DeviceType;
          document.getElementById("spn_DeviceSubtype").textContent = action_DeviceSubType;
          document.getElementById("spn_Locationtype").textContent = action_LocationType;
          document.getElementById("btn_AlertCall").click();
        } else if (SearechAlertId) {
          const search_CampusId = e.target.getAttribute("data-SearchCampusId");
          const search_BuildingId = e.target.getAttribute(
            "data-SearchBuildingId"
          );
          const search_FloorId = e.target.getAttribute("data-SearchFloorId");
          const search_RoomId = e.target.getAttribute("data-SearchRoomId");
          const search_AlertId = e.target.getAttribute("data-SearchAlertId");
          const SearchResidentId = e.target.getAttribute("data-SearchResidentId");

          document.getElementById("spn_CampusId").textContent = search_CampusId;
          document.getElementById("spn_BuildingId").textContent = search_BuildingId;
          document.getElementById("spn_FloorId").textContent = search_FloorId;
          document.getElementById("spn_RoomId").textContent = search_RoomId;
          document.getElementById("spn_AlertId").textContent = search_AlertId;
          document.getElementById("spn_ResidentId").textContent = SearchResidentId;
          document.getElementById("btn_SearchCall").click();
        } else if (LocationAlertId) {
          //this is for location info when press the location on the resident search
          const cordiX = e.target.getAttribute("data-cordiX");
          const cordiY = e.target.getAttribute("data-cordiY");
          const LocationResImg = e.target.getAttribute("data-LocationResImg");
          const LocationstaffImg = e.target.getAttribute("data-LocationstaffImg");
          const Locationpopupwidth = e.target.getAttribute("data-popupwidth");

          popup = new Popup({ insertFirst: false });
          popup.autoPan = true;
          popup.autoPanAnimation = { duration: 250 };
          map.addOverlay(popup);

          let content = "";
          let LocationInfo;
          if (LocationAlertId in alertbaseddatainfo) {
            LocationInfo = alertbaseddatainfo[LocationAlertId];
          }

          if (LocationInfo != undefined) {
            content = getlocation_data(
              LocationInfo,
              LocationResImg,
              LocationstaffImg
            );
            const floornameinfo = undefined;
            const el = load_alertpopup_style(
              floornameinfo,
              content,
              0,
              Locationpopupwidth
            ); // height no need for location information for alert popup
            popup.show([parseFloat(cordiX), parseFloat(cordiY)], el);
          }
        } else if (AlertAcceptedId) {
          const AcceptedAlertid = e.target.getAttribute("data-AcceptAlertId");
          const spnAcceptCompleteId = document.getElementById(
            "spn_AcceptCompleteId"
          );
          const spnAcceptCompleteCall = document.getElementById(
            "btn_AcceptCompleteCall"
          );
          if (spnAcceptCompleteId != null)
            spnAcceptCompleteId.textContent = AcceptedAlertid;
          if (spnAcceptCompleteCall != null) spnAcceptCompleteCall.click();
        }
      } catch (err) {
        throw new Error(
          "app-general.ts" + " - popupclick_invoke() - " + err.message
        );
      }
    },
    false
  );
}

// load the Data to load the building polyline and building name
function LoadBuildingSvg(
  map_sharedService,
  svginfo,
  MCampusId,
  alertdatainfo,
  mapHeight,
  geojsonObject,
  arrmarker,
  arrmarkerName,
  IsOutdoor
) {
  try {
    let buildingid, buildingname, AlertBuildingcnt, DeviceCount;
    if (IsOutdoor == 1) {
      buildingid = svginfo.spaceId + "_1";
      buildingname = svginfo.spaceName;
      AlertBuildingcnt = 0;
      DeviceCount = svginfo.devicecount;
      svginfo.floors = [];
    } else {
      buildingid = svginfo.buildingid;
      buildingname = svginfo.buildingname;
      AlertBuildingcnt = svginfo.AlertBuildingcnt;
      DeviceCount = 0;
    }
    
    const buildbaseddatainfo = AppComponent.SharedBuildBasedDetails;
    let buildalertdata;
    if (buildbaseddatainfo != undefined && buildingid in buildbaseddatainfo) {      
      buildalertdata = buildbaseddatainfo[buildingid];

      if (buildalertdata != undefined) {
        AlertBuildingcnt = buildalertdata.length;
      }
    }

    /// Building details ///
    let polyline = [] as any[];
    let iconStyleCall = "";
    let alertimgdata;
    let floorid = "",
    floorname = "";
    let subzoneid = "", subzonename = '';
    const arrpolypoint = [] as any[];
    if (svginfo.polyline != undefined && svginfo.polyline !="") {
      polyline = svginfo.polyline.split(" ");
      const arrfloors = svginfo.floors;
      
      /// Floor details ///
      for (let nflo = 0; nflo <= arrfloors.length - 1; nflo++) {
        if (floorid == "") {
          floorid =
            MCampusId +
            "_" +
            svginfo.buildingid +
            "_" +
            arrfloors[nflo].floorid +
            "_" +
            arrfloors[nflo].AlertFloorcnt +
            "_" +
            arrfloors[nflo].AlertFloorStaffcnt +
            "_" +
            arrfloors[nflo].AlertForInfracnt;
          floorname = arrfloors[nflo].floorname;
        } else {
          floorid =
            floorid +
            "," +
            MCampusId +
            "_" +
            svginfo.buildingid +
            "_" +
            arrfloors[nflo].floorid +
            "_" +
            arrfloors[nflo].AlertFloorcnt +
            "_" +
            arrfloors[nflo].AlertFloorStaffcnt +
            "_" +
            arrfloors[nflo].AlertForInfracnt;
          floorname = floorname + "," + arrfloors[nflo].floorname;
        }
        
        if(arrfloors[nflo].subzones.length > 0)
        {
          for(let nsub = 0; nsub <= arrfloors[nflo].subzones.length - 1; nsub++)
          {
            if (subzoneid == "") {
              subzoneid = MCampusId + "_" + svginfo.buildingid + "_" + arrfloors[nflo].floorid + "_" + arrfloors[nflo].subzones[nsub].subzoneid + "_" + arrfloors[nflo].AlertFloorcnt + "_" + arrfloors[nflo].AlertFloorStaffcnt + "_" + arrfloors[nflo].AlertForInfracnt;
              subzonename = arrfloors[nflo].subzones[nsub].subzonename;
            }
            else {
    
              subzoneid = subzoneid + "," + MCampusId + "_" + svginfo.buildingid + "_" + arrfloors[nflo].floorid + "_" + arrfloors[nflo].subzones[nsub].subzoneid + "_" + arrfloors[nflo].AlertFloorcnt + "_" + arrfloors[nflo].AlertFloorStaffcnt + "_" + arrfloors[nflo].AlertForInfracnt;
              subzonename = subzonename + "," + arrfloors[nflo].subzones[nsub].subzonename;
            }
          }
        }
      }
      ////////////alertIcon///////////////////////////////////////////////////////////////////////////////////            

      if (buildbaseddatainfo != undefined && buildingid in buildbaseddatainfo)
        alertimgdata = buildbaseddatainfo[buildingid];

      if (alertimgdata != undefined && alertimgdata.length > 0)
        iconStyleCall = Getalerticon(alertimgdata);
      ///////////END/////////////////////////////////////////////////////////////////////////////////////////////

      /// Building poly line ///

      for (let npoly = 0; npoly <= polyline.length - 1; npoly++) {
        arrpolypoint[npoly] = polyline[npoly].split(",");
        arrpolypoint[npoly][0] = parseFloat(arrpolypoint[npoly][0]);
        arrpolypoint[npoly][1] = getboxFromYpos(
          parseFloat(arrpolypoint[npoly][1]),
          mapHeight
        );
      }
    }
    AddBuildingData(
      alertimgdata,
      buildingid,
      buildingname,
      floorid,      
      floorname,
      subzoneid,
      subzonename,
      AlertBuildingcnt,
      DeviceCount,
      arrpolypoint,
      iconStyleCall,
      geojsonObject,
      polyline,
      mapHeight,
      arrmarker,
      arrmarkerName,
      map_sharedService
    );
  } catch (err) {
    throw new Error("app-general.ts" + " - LoadBuildingSvg() - " + err.message);
  }
}

// load the SVG to load the building polyline and building name
function AddBuildingData(
  alertimgdata,
  buildingid,
  buildingname,
  floorid,  
  floorname,
  subzoneid,
  subzonename,
  AlertBuildingcnt,
  DeviceCount,
  arrpolypoint,
  iconStyleCall,
  geojsonObject,
  polyline,
  mapHeight,
  arrmarker,
  arrmarkerName,
  map_sharedService
) {
  try {
    let arrgeojsonObjectdata =
      geojsonObject.features[geojsonObject.features.length - 1];

    arrgeojsonObjectdata = {
      type: "Feature",
      id: buildingid,
      properties: {
        buildingid: buildingid,
        buildingname: buildingname,
        AlertBuildingcnt: AlertBuildingcnt,
        DeviceCount: DeviceCount,
        FloorID: floorid,
        FloorName: floorname,
        SubZoneID:subzoneid,
        SubZoneName: subzonename
      },
      geometry: {
        type: "Polygon",
        coordinates: [arrpolypoint],
      },
    };
    if (arrpolypoint != null && arrpolypoint.length>0) {
      geojsonObject.features.push(arrgeojsonObjectdata);
      LoadIcononBuilding(
        alertimgdata,
        buildingid,
        buildingname,
        floorid,
        floorname,
        subzoneid, 
        subzonename,
        AlertBuildingcnt,
        iconStyleCall,
        polyline,
        mapHeight,
        arrmarker,
        arrmarkerName,
        map_sharedService
      );
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - AddBuildingData() - " + err.message);
  }
}

// load the icon on Builing
function LoadIcononBuilding(
  alertimgdata,
  buildingid,
  buildingname,
  floorid,
  floorname,
  subzoneid, 
  subzonename,
  AlertBuildingcnt,
  iconStyleCall,
  polyline,
  mapHeight,
  arrmarker,
  arrmarkerName,
  map_sharedService
) {
  try {
    /// Add marker for Building ///
    const markerpoint = calculatexycoordinates(polyline, mapHeight);
    const marker = addAlertMarkerforBuilding(
      markerpoint,
      buildingid,
      map_sharedService,
      alertimgdata,
      AlertBuildingcnt
    );
    arrmarker.push(marker);

    // Add building name on polyline
    const markername = new OlFeature({
      geometry: new OlPoint(markerpoint),
    });
    markername.set("buildingid", buildingid);
    markername.set("buildingname", buildingname);
    markername.set("FloorID", floorid);
    markername.set("FloorName", floorname);
    markername.set("SubZoneID", subzoneid);
    markername.set("SubZoneName", subzonename);
    arrmarkerName.push(markername);
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - LoadIcononBuilding() - " + err.message
    );
  }
}

function AddBuildingLayer(
  map,
  geojsonObject,
  fillcolorStyle,
  arrmarker,
  ImagePath,
  arrmarkerName,
  labelStyle,
  style,
  map_sharedService,
  MCampusId,
  pollingData,
  map_SharedDetails,
  map_CheckAlertData,
  alertdatainfo,
  residentImgUrl,
  staffImgUrl,
  popup,
  select,
  arrSvgInfo,
  searchalerticonStyledesc,
  mapHeight,
  map_length,
  arrBuildingId,
  arrAlertBuildingId,
  Router
) {
  try {
    ///Building geo feature///

    map = AppComponent.ShareMapDetails;
    let vectorLayer_geo = new OlVectorLayer();
    vectorLayer_geo = createVectorLayer(
      geojsonObject,
      1,
      "Building" + MCampusId
    );
    if (fillcolorStyle != null) {
      vectorLayer_geo.setStyle(fillcolorStyle);
    } else {
      const arrfeaturesFloor = vectorLayer_geo.getSource().getFeatures();
      arrfeaturesFloor.forEach(function (feature) {
        const space_fillcolorStyle = fillcolor_style_Home(fillcolorPolygon.Completed);
        feature.setStyle(space_fillcolorStyle);
      });
    }

    vectorLayer_geo.setProperties("declutter", true);

    map.addLayer(vectorLayer_geo);

    ///Building Marker///
    const vectorLayerStyle = function (feature, resolution) {
      if (feature.get("iconStyleCall")) {
        return [
          alerticonstyle(resolution, ImagePath),
          alerticonred(resolution, ImagePath, feature.get("iconStyleCall")),
        ];
      }
    };
    let vectorLayer = new OlVectorLayer();
    vectorLayer = createVectorLayer(arrmarker, 0, "BuildingMarker" + MCampusId);
    vectorLayer.setStyle(vectorLayerStyle);
    vectorLayer.set("arrBuildingId", arrBuildingId);
    vectorLayer.set("arrAlertBuildingId", arrAlertBuildingId);
    map.addLayer(vectorLayer);

    ///Building Name Marker///
    const vectorLayerNameStyle = function (feature) {
      labelStyle.getText().setText(feature.get("buildingname"));
      return style;
    };
    let vectorLayerName = new OlVectorLayer();
    vectorLayerName = createVectorLayer(
      arrmarkerName,
      0,
      "BuildingName" + MCampusId
    );
    vectorLayerName.setStyle(vectorLayerNameStyle);
    map.addLayer(vectorLayerName);

    map_sharedService.changedMapDetails(map);
    map_sharedService.changedMapVectorLayer(vectorLayer);

    if (pollingData != undefined) pollingData.unsubscribe();
    pollingData = interval(1000) // 1 sec
      .pipe(
        startWith(0),
        switchMap(() =>
          CheckAlertDataChanged(
            pollingData,
            map,
            map_sharedService,
            map_SharedDetails,
            map_CheckAlertData,
            alertdatainfo,
            residentImgUrl,
            staffImgUrl,
            popup,
            select,
            vectorLayer,
            arrSvgInfo,
            searchalerticonStyledesc,
            mapHeight,
            map_length,
            ImagePath,
            MCampusId,
            0,
            Router
          )
        )
      )
      .subscribe();
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - AddBuildingLayer() - " + err.message
    );
  }
}

function ShowPopupManual(
  map_sharedService,
  AlertIds,
  AlertStatus,
  residentImgUrl,
  staffImgUrl,
  map,
  select,
  vectorLayer,
  alertdatainfo,
  arrSvgInfo,
  searchalerticonStyle,
  mapHeight,
  map_length,
  ImagePath,
  ResidentId,
  BuildingId,
  Router
) {
  try {
    let selectstyle;
    
    alertidflag = 0;
    let popup = new Popup({ insertFirst: false });
    if (AlertIds != "" && BuildingId != 0) {
      popup = new Popup({
        insertFirst: false,
        name: "buildingid-" + BuildingId,
      });
    }
    popup.autoPan = true;
    popup.autoPanAnimation = { duration: 250 };    

    map = AppComponent.ShareMapDetails;
    vectorLayer = AppComponent.ShareMapVectorLayer;
    const alertbaseddatainfo = AppComponent.SharedAlertBasedDetails;

    if (AlertStatus != alert_raisedon.resident_search) {
      let AlertIconDetails;
      if (JSON.stringify(alertbaseddatainfo) != undefined) {
        if (AlertIds in alertbaseddatainfo) {
          AlertIconDetails = alertbaseddatainfo[AlertIds];
        }

        if (AlertIconDetails != undefined) {
          if (AlertIconDetails.length > 0) {
            selectstyle = [
              alerticonstyle(1, ImagePath),
              alerticonred(1, ImagePath, AlertIconDetails[0].iconStyleImg),
            ];
          }
        }
      }
    } else {
      selectstyle = [searchalerticonStyle];
      AlertIds = getSearchResidentAlertId(ResidentId, alertdatainfo, 0, 0);
    }

    // select interaction working on "click"
    const selectClick = new Select({
      condition: click,
      style: selectstyle,
    });

    select = selectClick;

    /// Show pop up uisnfg select feature ///
    select.getFeatures().clear();

    const source = vectorLayer.getSource();
    const features = source.getFeatures();
    const emit_AlertId = AlertIds;
    let AlertDetails;
    let arremit_AlertId:string[] = [];
    if (emit_AlertId != undefined) {
      arremit_AlertId = emit_AlertId.toString().split(",");
    }
    if (alertdatainfo) {
      AlertDetails = alertdatainfo.filter(function (e1) {
        if (arremit_AlertId.includes(e1.AlertId.toString())) {
          return e1.AlertId;
        }
      });
    }

    if (
      arremit_AlertId.length == 1 &&
      AlertDetails != undefined &&
      AlertDetails.length > 0 &&
      AlertDetails[0].BuildId == null
    ) {
      const coord = map.getView().getCenter();

      PopopShow(
        map,
        popup,
        select,
        coord,
        map_sharedService,
        map_length,
        mapHeight,
        alertdatainfo,
        Router,
        AlertDetails,
        residentImgUrl,
        staffImgUrl,
        AlertStatus,
        BuildingId,
        0,
        null,
        0,
        []
      );
    } else {
      for (let fcnt = 0; fcnt < features.length; fcnt++) {
        const feature = features[fcnt];

        if (feature) {
          const geometry = feature.getGeometry();
          const coord = geometry.getCoordinates();                    
          if (feature.get("floorname") == undefined) {
            const buildingid = feature.get("buildingid");
            let buildingAlert = false;
            let chkAlertId = 0;
            
            if (AlertDetails != undefined && AlertDetails.length > 0) {
              AlertDetails.filter(function (e1) {
                if (
                  buildingid == e1.BuildId &&
                  arremit_AlertId.includes(e1.AlertId.toString())
                ) {
                  buildingAlert = true;
                  chkAlertId = e1.AlertId;
                  return e1.AlertId;
                }
              });
            }

            if (
              AlertStatus != 0 &&
              buildingAlert &&
              feature.get("AlertBuildingcnt") > 0
            ) {
              if (AlertStatus == alert_raisedon.resident_search) {
                select = addSearchMarker(
                  coord,
                  map,
                  select,
                  buildingid,
                  chkAlertId,
                  searchalerticonStyle,
                  0
                );
              } else {
                select.getFeatures().push(feature);
              }

              /////////////show alert Dialog//////////////
              PopopShow(
                map,
                popup,
                select,
                coord,
                map_sharedService,
                map_length,
                mapHeight,
                alertdatainfo,
                Router,
                AlertDetails,
                residentImgUrl,
                staffImgUrl,
                AlertStatus,
                buildingid,
                0,
                null,
                0,
                []
              );
            }
          }
        }
      }
    }

    map_sharedService.changedMapDetails(map);
    map_sharedService.changedMapVectorLayer(vectorLayer);
  } catch (err) {
    throw new Error("app-general.ts" + " - ShowPopupManual() - " + err.message);
  }
}

// load the SVG to load the building polyline and building name
function LoadFloorSvg(
  FloorInfo,
  MCampusId,
  alertdatainfo,
  mapHeight,
  arrdevicemarker,
  map_sharedService,
  SubZoneId
) {
  try {
    
    
    let DeviceAlertId = FloorInfo.AlertId;
    const DeviceTypeId = FloorInfo.FilterDeviceTypeId;

    if (FloorInfo.AlertId == 0) {
      DeviceAlertId = FloorInfo.LastAlertId;
    }
    if (DeviceAlertId == 0) {
      // staff alert
      DeviceAlertId = FloorInfo.Staff_AlertId;
    }

    if (DeviceAlertId == 0) {
      // infra struture alert
      DeviceAlertId = FloorInfo.Infra_AlertId;
    }

    if (DeviceAlertId == 0) {
      // Wander alert
      DeviceAlertId = FloorInfo.WanderAlertId;
    }

    if (FloorInfo.polyline != undefined || FloorInfo.DeviceX != undefined) {
      if (FloorInfo.DeviceX.toString() != "") {
        //////////////////////////////////////////////////For AlertIcon//////////////////////////////////////////////////////
        const F_CampusId = MCampusId;
        const iconStyleCall = "";
        let max = 0;
        let alertimgdata;
        if (alertdatainfo.length > 0) {
          alertimgdata = alertdatainfo.filter(function (e1) {
            if (
              e1.AlertType == enumAlertType.Pull_Cord &&
              e1.ismobilitymode == 1 &&
              e1.DeviceType != enumDeviceType.HREX &&
              e1.DeviceType != enumDeviceType.REX
            ) {
              
              if (SubZoneId>0)
              {
                  max = (max < e1.AlertId && e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && 
                        e1.RoomId == FloorInfo.RoomId && e1.SubZoneId ==SubZoneId) ? e1.AlertId : max;
                  return e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && e1.RoomId == FloorInfo.RoomId 
                        &&  e1.DeviceType == FloorInfo.FilterDeviceTypeId && e1.SubZoneId ==SubZoneId;
              }
              else
              {
                max =
                  max < e1.AlertId &&
                  e1.CampusId == F_CampusId &&
                  e1.FloorId == FloorInfo.FloorId &&
                  e1.UnitId == FloorInfo.UnitId &&
                  e1.RoomId == FloorInfo.RoomId
                    ? e1.AlertId
                    : max;
                return (
                  e1.CampusId == F_CampusId &&
                  e1.FloorId == FloorInfo.FloorId &&
                  e1.UnitId == FloorInfo.UnitId &&
                  e1.RoomId == FloorInfo.RoomId &&
                  e1.DeviceType == FloorInfo.FilterDeviceTypeId
                );
              }
            } else if (
              e1.AlertType == enumAlertType.Pull_Cord &&
              e1.ismobilitymode == 0 &&
              e1.DeviceType != enumDeviceType.HREX &&
              e1.DeviceType != enumDeviceType.REX
            ) {
              if (SubZoneId>0)
              {
                max = (max < e1.AlertId && e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && 
                    e1.TagId == FloorInfo.RoomId && e1.SubZoneId ==SubZoneId) ? e1.AlertId : max;
                return e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && e1.TagId == FloorInfo.RoomId && e1.SubZoneId ==SubZoneId ;
              }
              else
              {
                max =
                  max < e1.AlertId &&
                  e1.CampusId == F_CampusId &&
                  e1.FloorId == FloorInfo.FloorId &&
                  e1.UnitId == FloorInfo.UnitId &&
                  e1.TagId == FloorInfo.RoomId
                    ? e1.AlertId
                    : max;
                return (
                  e1.CampusId == F_CampusId &&
                  e1.FloorId == FloorInfo.FloorId &&
                  e1.UnitId == FloorInfo.UnitId &&
                  e1.TagId == FloorInfo.RoomId
                );
              }
            } else if (
              e1.AlertType == enumAlertType.UT &&
              e1.ismobilitymode == 1
            ) {
              if (SubZoneId>0)
              {
                  max = (max < e1.AlertId && e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && 
                    e1.RoomId == FloorInfo.RoomId && e1.SubZoneId ==SubZoneId) ? e1.AlertId : max;
                  return e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && e1.RoomId == FloorInfo.RoomId && e1.SubZoneId ==SubZoneId;
              }
              else
              {
                max =
                  max < e1.AlertId &&
                  e1.CampusId == F_CampusId &&
                  e1.FloorId == FloorInfo.FloorId &&
                  e1.UnitId == FloorInfo.UnitId &&
                  e1.RoomId == FloorInfo.RoomId
                    ? e1.AlertId
                    : max;
                return (
                  e1.CampusId == F_CampusId &&
                  e1.FloorId == FloorInfo.FloorId &&
                  e1.UnitId == FloorInfo.UnitId &&
                  e1.RoomId == FloorInfo.RoomId
                );
              }
            } else if (
              e1.AlertType == enumAlertType.UT &&
              e1.ismobilitymode == 0
            ) {
              if (SubZoneId>0)
              {
              max = (max < e1.AlertId && e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && 
                    e1.TagId == FloorInfo.RoomId && e1.SubZoneId ==SubZoneId) ? e1.AlertId : max;
              return e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && e1.TagId == FloorInfo.RoomId && e1.SubZoneId ==SubZoneId;
              }
              else
              {
                max =
                  max < e1.AlertId &&
                  e1.CampusId == F_CampusId &&
                  e1.FloorId == FloorInfo.FloorId &&
                  e1.UnitId == FloorInfo.UnitId &&
                  e1.TagId == FloorInfo.RoomId
                    ? e1.AlertId
                    : max;
                return (
                  e1.CampusId == F_CampusId &&
                  e1.FloorId == FloorInfo.FloorId &&
                  e1.UnitId == FloorInfo.UnitId &&
                  e1.TagId == FloorInfo.RoomId
                );
              }
            } else {
              let _RoomId = e1.RoomId;
              if (_RoomId == null || _RoomId == null || _RoomId == 0) {
                _RoomId = e1.LastRoomId;
              }
              if (DeviceTypeId == enumDeviceType.ROOM.toString()) {
                if (SubZoneId>0)
                {
                  max = (max < e1.AlertId && e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId 
                        && e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm && e1.SubZoneId ==SubZoneId) ? e1.AlertId : max;
                  return e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
                         && e1.SubZoneId == SubZoneId ;
                }
                else
                {
                  max =
                    max < e1.AlertId &&
                    e1.CampusId == F_CampusId &&
                    e1.FloorId == FloorInfo.FloorId &&
                    e1.UnitId == FloorInfo.UnitId &&
                    e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
                      ? e1.AlertId
                      : max;
                  return (
                    e1.CampusId == F_CampusId &&
                    e1.FloorId == FloorInfo.FloorId &&
                    e1.UnitId == FloorInfo.UnitId
                  );
                }
              } else {
                if (SubZoneId>0)
                {
                  max = (max < e1.AlertId && e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId 
                        && _RoomId == FloorInfo.RoomId && e1.SubZoneId ==SubZoneId) ? e1.AlertId : max;
                  return e1.CampusId == F_CampusId && e1.FloorId == FloorInfo.FloorId && e1.UnitId == FloorInfo.UnitId && _RoomId == FloorInfo.RoomId && e1.SubZoneId == SubZoneId ;
                }
                else
                {
                  max =
                    max < e1.AlertId &&
                    e1.CampusId == F_CampusId &&
                    e1.FloorId == FloorInfo.FloorId &&
                    e1.UnitId == FloorInfo.UnitId &&
                    _RoomId == FloorInfo.RoomId
                      ? e1.AlertId
                      : max;
                  return (
                    e1.CampusId == F_CampusId &&
                    e1.FloorId == FloorInfo.FloorId &&
                    e1.UnitId == FloorInfo.UnitId &&
                    _RoomId == FloorInfo.RoomId
                  );
                }
              }
            }
          });
        }
        DeviceAlertId = max;
        // if (alertimgdata.length > 0)
        //   iconStyleCall = Getalerticon(alertimgdata);
        ////////////////////////////////////////////////////////////////////////////////////////////////////////
        LoadIcononFloor(
          FloorInfo,
          DeviceAlertId,
          DeviceTypeId,
          iconStyleCall,
          mapHeight,
          arrdevicemarker,
          alertimgdata,
          map_sharedService
        );
      }
    } //polyline end
  } catch (err) {
    throw new Error("app-general.ts" + " - LoadFloorSvg() - " + err.message);
  }
}

// load the icon on Floor
function LoadIcononFloor(
  FloorInfo,
  DeviceAlertId,
  DeviceTypeId,
  iconStyleCall,
  mapHeight,
  arrdevicemarker,
  alertimgdata,
  map_sharedService
) {
  try {
    const markerpoint = getmarkerpointforFloor(FloorInfo, mapHeight);    
    const StaffResidents = AppComponent.SharedResidentStaffDetails;

    ///////////////////show alert based on infra alert////////////
    if (FloorInfo.RoomId != undefined && FloorInfo.RoomId != "") {
      if (StaffResidents != null) {
        if (FloorInfo.RoomId + "_" + DeviceTypeId in StaffResidents) {
          const arrMarkerStaffResident =
            StaffResidents[FloorInfo.RoomId + "_" + DeviceTypeId];
          let arrMarkerResident = [] as any[];
          arrMarkerResident = arrMarkerStaffResident.filter(function (e1) {
            return e1.Type == enumCheckResidenStaff.Resident;
          });
          const resident_markerpoint = [] as any[];
          resident_markerpoint[0] = markerpoint[0]; // - 15;
          resident_markerpoint[1] = markerpoint[1]; // + 30;
          for (let idx = 0; idx < arrMarkerResident.length; idx++) {
            const marker = new OlFeature({
              // Added fromLonLat
              geometry: new OlPoint(markerpoint),
              id: "roomid-" + FloorInfo.RoomId,
            });
            marker.id_ =
              "roomid-" +
              FloorInfo.RoomId +
              "_" +
              DeviceTypeId +
              "-" +
              arrMarkerResident[idx].TagId;
            marker.set("DeviceRoomId", FloorInfo.RoomId);
            marker.set("DeviceRoomName", FloorInfo.RoomName);
            marker.set("DeviceId", FloorInfo.DeviceId);
            marker.set("DeviceTypeId", FloorInfo.FilterDeviceTypeId);
            marker.set("floorid", FloorInfo.FloorId);
            marker.set("Id", arrMarkerResident[idx].ResidentID);
            marker.set("Name", arrMarkerResident[idx].Name);
            marker.set("TagId", arrMarkerResident[idx].TagId);
            marker.set("iconStaff", arrMarkerResident[idx].Photo);
            marker.set("Type", enumCheckResidenStaff.Resident);
            marker.set("RoomName", arrMarkerResident[idx].RoomName);
            marker.set("FirstName", arrMarkerResident[idx].FirstName);
            marker.set("LastName", arrMarkerResident[idx].LastName);
            marker.set("Photo", arrMarkerResident[idx].Photo);
            marker.set("Gender", arrMarkerResident[idx].Gender);
            marker.set("Email", arrMarkerResident[idx].Email);
            if (arrMarkerResident.length > 0) {
              marker.set("Total", arrMarkerResident.length);
            }
            arrdevicemarker.push(marker);
          }
          let arrMarkerStaff = [] as any[];
          arrMarkerStaff = arrMarkerStaffResident.filter(function (e1) {
            return e1.Type == enumCheckResidenStaff.Staff;
          });
          const staff_markerpoint = [] as any[];
          staff_markerpoint[0] = markerpoint[0]; // + 15;
          staff_markerpoint[1] = markerpoint[1]; // + 30;
          for (let idx = 0; idx < arrMarkerStaff.length; idx++) {
            const marker = new OlFeature({
              // Added fromLonLat
              geometry: new OlPoint(markerpoint),
              id: "roomid-" + FloorInfo.RoomId,
            });
            marker.id_ =
              "roomid-" +
              FloorInfo.RoomId +
              "_" +
              DeviceTypeId +
              "-" +
              arrMarkerStaff[idx].TagId;
            marker.set("DeviceRoomId", FloorInfo.RoomId);
            marker.set("DeviceRoomName", FloorInfo.RoomName);
            marker.set("DeviceId", FloorInfo.DeviceId);
            marker.set("DeviceTypeId", FloorInfo.FilterDeviceTypeId);
            marker.set("floorid", FloorInfo.FloorId);
            marker.set("Id", arrMarkerStaff[idx].ID);
            marker.set(
              "Name",
              arrMarkerStaff[idx].FirstName + " " + arrMarkerStaff[idx].LastName
            );
            marker.set("TagId", arrMarkerStaff[idx].TagId);
            marker.set("iconStaff", arrMarkerStaff[idx].Photo);
            marker.set("Type", enumCheckResidenStaff.Staff);
            marker.set("RoomName", arrMarkerStaff[idx].RoomName);
            marker.set("FirstName", arrMarkerStaff[idx].FirstName);
            marker.set("LastName", arrMarkerStaff[idx].LastName);
            marker.set("Photo", arrMarkerStaff[idx].Photo);
            marker.set("Gender", arrMarkerStaff[idx].Gender);
            marker.set("Email", arrMarkerStaff[idx].Email);
            if (arrMarkerStaff.length > 0) {
              marker.set("Total", arrMarkerStaff.length);
            }
            arrdevicemarker.push(marker);
          }
        } else {
          if (
            FloorInfo.FilterDeviceTypeId == enumDeviceType.ROOM.toString() &&
            markerpoint != undefined
          ) {
            const marker = new OlFeature({
              geometry: new OlPoint(markerpoint),
              id: "roomid-" + FloorInfo.UnitId + "-0",
            });
            marker.id_ =
              "roomid-" + FloorInfo.UnitId + "_" + DeviceTypeId + "-0";
            marker.set("DeviceRoomId", FloorInfo.UnitId);
            marker.set("DeviceRoomName", FloorInfo.UnitName);
            marker.set("DeviceId", FloorInfo.DeviceId);
            marker.set("DeviceTypeId", FloorInfo.FilterDeviceTypeId);
            marker.set("floorid", FloorInfo.FloorId);
            marker.set("UnitId", FloorInfo.UnitId);
            marker.set("SubZoneId", FloorInfo.SubZoneId);
            arrdevicemarker.push(marker);
          } else if (markerpoint != undefined) {
            const marker = new OlFeature({
              geometry: new OlPoint(markerpoint),
              id: "roomid-" + FloorInfo.RoomId + "-0",
            });
            marker.id_ =
              "roomid-" + FloorInfo.RoomId + "_" + DeviceTypeId + "-0";
            marker.set("DeviceRoomId", FloorInfo.RoomId);
            marker.set("DeviceRoomName", FloorInfo.RoomName);
            marker.set("DeviceId", FloorInfo.DeviceId);
            marker.set("DeviceTypeId", FloorInfo.FilterDeviceTypeId);
            marker.set("floorid", FloorInfo.FloorId);
            marker.set("UnitId", FloorInfo.UnitId);
            marker.set("SubZoneId", FloorInfo.SubZoneId);
            arrdevicemarker.push(marker);
          }
        }
      }
    } else {
      const marker = new OlFeature({
        geometry: new OlPoint(markerpoint),
        id: "roomid-" + FloorInfo.RoomId,
      });
      marker.id_ = "roomid-" + FloorInfo.RoomId + "_" + DeviceTypeId;
      marker.set("DeviceRoomId", FloorInfo.RoomId);
      marker.set("DeviceRoomName", FloorInfo.RoomName);
      marker.set("DeviceId", FloorInfo.DeviceId);
      marker.set("DeviceTypeId", FloorInfo.FilterDeviceTypeId);
      marker.set("floorid", FloorInfo.FloorId);
      marker.set("UnitId", FloorInfo.UnitId);
      marker.set("SubZoneId", FloorInfo.SubZoneId);
      arrdevicemarker.push(marker);
    }
    if (DeviceAlertId > 0) {
      if (FloorInfo.FilterDeviceTypeId == enumDeviceType.ROOM.toString()) {
        const marker = addAlertMarkerforFloor(
          FloorInfo.UnitId,
          FloorInfo.UnitName,
          FloorInfo.DeviceId,
          FloorInfo.FloorId,
          markerpoint,
          DeviceAlertId,
          alertimgdata,
          map_sharedService,
          DeviceTypeId,
          FloorInfo.SubZoneId
        );
        arrdevicemarker.push(marker);
      } else {
        const marker = addAlertMarkerforFloor(
          FloorInfo.RoomId,
          FloorInfo.RoomName,
          FloorInfo.DeviceId,
          FloorInfo.FloorId,
          markerpoint,
          DeviceAlertId,
          alertimgdata,
          map_sharedService,
          DeviceTypeId,
          FloorInfo.SubZoneId
        );
        arrdevicemarker.push(marker);
      }
    }
    ////////////////////end////////////////
  } catch (err) {
    throw new Error("app-general.ts" + " - LoadIcononFloor() - " + err.message);
  }
}

function AddFloorLayer(
  map,
  arrdevicemarker,
  ImagePath,
  map_sharedService,
  FloorId,
  pollingData,
  map_SharedDetails,
  map_CheckAlertData,
  alertdatainfo,
  residentImgUrl,
  staffImgUrl,
  popup,
  select,
  arrSvgInfo,
  searchalerticonStyledesc,
  mapHeight,
  map_length,
  MCampusId,
  arrRoomId,
  arrAlertRoomId,
  Router
) {
  try {
    map = AppComponent.ShareMapDetails;

    let vectorLayer = new OlVectorLayer();
    vectorLayer = createVectorLayer(
      arrdevicemarker,
      0,
      "FloorMarker" + FloorId
    );
    map_sharedService.changedMapVectorFloorLayer(vectorLayer);

    let arrfeaturesFloor = [] as any[];
    let AlertIconDetails;
    arrfeaturesFloor = vectorLayer.getSource().getFeatures();

    arrfeaturesFloor.forEach(function (feature) {
      if (feature.get("AlertData")) {
        AlertIconDetails = feature.get("AlertData");
      } else {
        AlertIconDetails = undefined;
      }

      const arriconStyleCall = geticonStyle(map_sharedService, AlertIconDetails);

      feature.set("iconStyleCall", arriconStyleCall[0]);
      feature.set("isInfraFloor", arriconStyleCall[1].IsfraOpen);

      feature.setStyle(function (stylefeature, resolution) {
        if (stylefeature.get("iconStyleCall")) {
          return [
            alerticonstyle(resolution, ImagePath),
            alerticonred(
              resolution,
              ImagePath,
              stylefeature.get("iconStyleCall")
            ),
          ];
        } else {
          return new Style();
        }
      });
    });

    vectorLayer.set("arrRoomId", arrRoomId);
    vectorLayer.set("arrAlertRoomId", arrAlertRoomId);

    map.addLayer(vectorLayer);

    map_sharedService.changedMapDetails(map);
    map_sharedService.changedMapVectorLayer(vectorLayer);

    if (pollingData != undefined) pollingData.unsubscribe();
    pollingData = interval(1000) // 1 sec
      .pipe(
        startWith(0),
        switchMap(() =>
          CheckAlertDataChanged(
            pollingData,
            map,
            map_sharedService,
            map_SharedDetails,
            map_CheckAlertData,
            alertdatainfo,
            residentImgUrl,
            staffImgUrl,
            popup,
            select,
            vectorLayer,
            arrSvgInfo,
            searchalerticonStyledesc,
            mapHeight,
            map_length,
            ImagePath,
            MCampusId,
            FloorId,
            Router
          )
        )
      )
      .subscribe();
  } catch (err) {
    throw new Error("app-general.ts" + " - AddFloorLayer() - " + err.message);
  }
}

function DrawLocation(map_sharedService, obj_Location) {
  try {
    RemoveLocation(map_sharedService, obj_Location);
    let typeid = 0;
    if (ResidentStaffType == 3) {
      typeid = obj_Location.Type;
    } else {
      typeid = ResidentStaffType;
    }

    if (obj_Location.Type == typeid) {                  
      const map = AppComponent.ShareMapDetails;
      const map_SharedDetails = AppComponent.SharedDetails;      
      const RoomDetails = AppComponent.SharedRoomBasedDetails;
      const _MapHeight = map.getLayers().array_[0].getSource().imageExtent_[3];
      const currentRoomDetails = RoomDetails[obj_Location.PreRoomId];
      const arrFloorlayer = [] as any[];
      map.getLayers().forEach(function (layer) {
        if (
          layer.get("name") != undefined &&
          layer.get("name") ===
            "FloorMarker" + map_SharedDetails.SharedFloorId &&
          map_SharedDetails.SharedFloorId == currentRoomDetails[0].FloorId
        ) {
          arrFloorlayer.push(layer);
        }
        if (arrFloorlayer.length > 0) {
          const Floorlayer = arrFloorlayer[arrFloorlayer.length - 1];
          const arrfloorfeatures = Floorlayer.getSource().getFeatures();          
          let current_feature;
          let IsDrawm = 0;
          let indx = 0;
          arrfloorfeatures.forEach(function (feature) {
            let featureRoomId = feature.id_;
            featureRoomId = featureRoomId.replace("roomid-", "");
            featureRoomId = featureRoomId.split("-")[0];
            const _featureRoomId = featureRoomId.split("-")[0];
            const _featureDeviceTypeId = featureRoomId.split("_")[1];

            if (
              _featureRoomId == obj_Location.RoomId &&
              parseInt(feature.get("Type")) == parseInt(obj_Location.Type)
            ) {
              const total = getRoomDetails_liveview(
                map_sharedService,
                _featureRoomId,
                parseInt(obj_Location.Type)
              );
              feature.set("DeviceTypeId", obj_Location.DeviceType);
              if (total > 1) {
                feature.set("Total", total);
              } else {
                feature.set("Total", 0);
              }
              feature.setStyle(new Style());
            }

            if (feature.get("TagId") == obj_Location.TagId) {
              current_feature = feature;
            }

            if (
              currentRoomDetails.length > 0 &&
              current_feature != undefined &&
              IsDrawm == 0
            ) {
              IsDrawm = 1;

              const _newid = current_feature.id_.replace(
                "roomid-" +
                  obj_Location.LastRoomId +
                  "_" +
                  _featureDeviceTypeId,
                "roomid-" + obj_Location.RoomId
              );
              current_feature.id_ = _newid;
              current_feature.setId(_newid);

              const _markerpoint = [] as any[];
              let markerpoint = [] as any[];
              if (currentRoomDetails[0].svgPoints != null) {
                const polyline = currentRoomDetails[0].svgPoints.split(" ");
                /// Add marker for Floor ///
                markerpoint = calculatexycoordinates(polyline, _MapHeight);
              } else {
                markerpoint = [
                  convertToSvgEcllipseXCoordinate(
                    parseFloat(currentRoomDetails[0].X),
                    false
                  ),
                  convertToSvgEcllipseYCoordinate(
                    parseFloat(currentRoomDetails[0].Y),
                    _MapHeight
                  ),
                ];
              }

              if (parseInt(obj_Location.Type) == 2) {
                _markerpoint[0] = markerpoint[0]; // - 15;
                _markerpoint[1] = markerpoint[1]; // + 30;
              } else {
                _markerpoint[0] = markerpoint[0]; // + 15;
                _markerpoint[1] = markerpoint[1]; // + 30;
              }

              current_feature.getGeometry().setCoordinates(markerpoint);

              current_feature.set("DeviceRoomId", obj_Location.PreRoomId);
              current_feature.set(
                "DeviceRoomName",
                currentRoomDetails[0].RoomName
              );

              //SetStyleStaffResident(current_feature, parseInt(obj_Location.Type), Map_image_path);
              const total = getRoomDetails_liveview(
                map_sharedService,
                obj_Location.RoomId,
                parseInt(obj_Location.Type)
              );
              if (total > 1) {
                current_feature.set("Total", total);
              } else {
                current_feature.set("Total", 0);
              }

              if (total > 0) feature.setStyle(new Style()); //SetStyleStaffResident_liveview(current_feature, parseInt(obj_Location.Type), Map_image_path);
            }

            indx++;
            if (indx == arrfloorfeatures.length) {
              RefreshIconDetails(map_sharedService, ResidentStaffType);
            }
          });

          if (current_feature == undefined) {
            const _markerpoint = [] as any[];
            let markerpoint = [] as any[];
            
            if (currentRoomDetails[0].svgPoints != null) {
              const polyline = currentRoomDetails[0].svgPoints.split(" ");
              /// Add marker for Floor ///
              markerpoint = calculatexycoordinates(polyline, _MapHeight);
            } else {
              markerpoint = [
                convertToSvgEcllipseXCoordinate(
                  parseFloat(currentRoomDetails[0].X),
                  false
                ),
                convertToSvgEcllipseYCoordinate(
                  parseFloat(currentRoomDetails[0].Y),
                  _MapHeight
                ),
              ];
            }

            if (parseInt(obj_Location.Type) == 2) {
              _markerpoint[0] = markerpoint[0]; // - 15;
              _markerpoint[1] = markerpoint[1]; // + 30;
            } else {
              _markerpoint[0] = markerpoint[0]; // + 15;
              _markerpoint[1] = markerpoint[1]; // + 30;
            }
            const marker = new OlFeature({
              // Added fromLonLat
              geometry: new OlPoint(markerpoint),
              id: "roomid-" + obj_Location.RoomId,
            });
            marker.id_ =
              "roomid-" + obj_Location.RoomId + "-" + obj_Location.TagId;
            marker.set("DeviceRoomId", obj_Location.PreRoomId);
            marker.set("DeviceRoomName", obj_Location.RoomName);
            marker.set("DeviceId", obj_Location.PreRoomId);
            marker.set("DeviceTypeId", obj_Location.DeviceType);
            marker.set("floorid", obj_Location.FloorId);
            marker.set("Id", obj_Location.ResidentID);
            marker.set(
              "Name",
              obj_Location.FirstName + " " + obj_Location.LastName
            );
            marker.set("TagId", obj_Location.TagId);
            marker.set("iconStaff", obj_Location.Photo);
            if (parseInt(obj_Location.Type) == 2) {
              marker.set("Type", enumCheckResidenStaff.Resident);
            } else {
              marker.set("Type", enumCheckResidenStaff.Staff);
            }
            marker.set("RoomName", obj_Location.RoomName);
            marker.set("FirstName", obj_Location.FirstName);
            marker.set("LastName", obj_Location.LastName);
            marker.set("Photo", obj_Location.Photo);
            marker.set("Gender", obj_Location.Gender);
            marker.set("Email", obj_Location.Email);
            const total = getRoomDetails_liveview(
              map_sharedService,
              obj_Location.RoomId,
              parseInt(obj_Location.Type)
            );
            if (total > 0) {
              marker.set("Total", total);
            } else {
              marker.set("Total", 0);
            }
            marker.setStyle(new Style()); //SetStyleStaffResident_liveview(marker, parseInt(obj_Location.Type), Map_image_path);
            Floorlayer.getSource().addFeature(marker);

            RefreshIconDetails(map_sharedService, ResidentStaffType);
          }
        }
      });
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - DrawLocation() - " + err.message);
  }
}

function RemoveLocation(map_sharedService, obj_Location) {
  try {
    let typeid = 0;
    if (ResidentStaffType == 3) {
      typeid = obj_Location.Type;
    } else {
      typeid = ResidentStaffType;
    }
    if (obj_Location.Type == typeid) {            
      const map = AppComponent.ShareMapDetails;
      const map_SharedDetails = AppComponent.SharedDetails;      
      const RoomDetails = AppComponent.SharedRoomBasedDetails;      
      const LastRoomDetails = RoomDetails[obj_Location.LastRoomId];
      const CurrentRoomDetails = RoomDetails[obj_Location.PreRoomId];
      const arrFloorlayer = [] as any[];

      map.getLayers().forEach(function (layer) {
        if (
          layer.get("name") != undefined &&
          layer.get("name") ===
            "FloorMarker" + map_SharedDetails.SharedFloorId &&
          map_SharedDetails.SharedFloorId == LastRoomDetails[0].FloorId
        ) {
          arrFloorlayer.push(layer);
        }
        if (arrFloorlayer.length > 0) {
          const Floorlayer = arrFloorlayer[arrFloorlayer.length - 1];
          const arrfloorfeatures = Floorlayer.getSource().getFeatures();

          let indx = 0;
          arrfloorfeatures.forEach(function (feature) {
            let featureRoomId = feature.id_;
            featureRoomId = featureRoomId.replace("roomid-", "");
            featureRoomId = featureRoomId.split("-")[0];
            const _featureRoomId = featureRoomId.split("-")[0];
            const _featureDeviceTypeId = featureRoomId.split("_")[1];
            if (
              _featureRoomId ==
                parseInt(obj_Location.LastRoomId) +
                  "_" +
                  parseInt(_featureDeviceTypeId) &&
              parseInt(feature.get("Type")) == parseInt(obj_Location.Type)
            ) {
              const total = getRoomDetails_liveview(
                map_sharedService,
                _featureRoomId,
                parseInt(obj_Location.Type)
              );
              if (total > 1) {
                feature.set("Total", total);
              } else {
                feature.set("Total", 0);
              }
              if (feature.get("TagId") == obj_Location.TagId) {
                feature.setStyle(new Style());

                if (
                  CurrentRoomDetails.length > 0 &&
                  map_SharedDetails.SharedFloorId !=
                    CurrentRoomDetails[0].FloorId
                ) {
                  const _newid = feature.id_.replace(
                    "roomid-" +
                      obj_Location.LastRoomId +
                      "_" +
                      _featureDeviceTypeId,
                    "roomid-" + obj_Location.RoomId
                  );
                  feature.id_ = _newid;
                  feature.setId(_newid);
                  feature.set("DeviceRoomId", obj_Location.PreRoomId);
                  Floorlayer.getSource().removeFeature(feature);
                }
              } else if (total > 0) {
                feature.setStyle(new Style()); //SetStyleStaffResident_liveview(feature, parseInt(obj_Location.Type), Map_image_path);
              } else {
                feature.setStyle(new Style());
              }
            }
            indx++;
            if (indx == arrfloorfeatures.length) {
              RefreshIconDetails(map_sharedService, ResidentStaffType);
            }
          });
        }
      });
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - RemoveLocation() - " + err.message);
  }
}

let ResidentStaffType = 0;
function RefreshIconDetails(map_sharedService, Type) {
  try {
    ResidentStaffType = Type;    
    const Map_image_path = "assets/images/map/";
    const map = AppComponent.ShareMapDetails;
    const map_SharedDetails = AppComponent.SharedDetails;

    const arrPopup = [] as any[];
    if (map.getOverlays() != undefined)
    {
      map.getOverlays().forEach(function (feature) {
        if (enumCheckResidenStaff.None == Type) {
          if (
            feature.getOptions().type == enumCheckResidenStaff.Resident ||
            feature.getOptions().type == enumCheckResidenStaff.Staff
          ) {
            arrPopup.push(feature);
          }
        }
        if (enumCheckResidenStaff.Staff == Type) {
          if (feature.getOptions().type == enumCheckResidenStaff.Resident) {
            arrPopup.push(feature);
          }
        }
        if (enumCheckResidenStaff.Resident == Type) {
          if (feature.getOptions().type == enumCheckResidenStaff.Staff) {
            arrPopup.push(feature);
          }
        }
      });
    }

    const len = arrPopup.length;

    for (let i = 0; i < len; i++) {
      map.removeOverlay(arrPopup[i]);
    }

    const arrFloorlayer = [] as any[];
    map.getLayers().forEach(function (layer) {
      if (
        layer.get("name") != undefined &&
        layer.get("name") === "FloorMarker" + map_SharedDetails.SharedFloorId
      ) {
        arrFloorlayer.push(layer);
      }
      if (
        layer.get("name") != undefined &&
        layer.get("name").includes("LineLinear")
      ) {
        layer.setStyle(new Style());
      }
    });

    if (arrFloorlayer.length > 0) {
      const Floorlayer = arrFloorlayer[arrFloorlayer.length - 1];
      const arrfeaturesFloor = Floorlayer.getSource().getFeatures();
      
      if (arrfeaturesFloor.length > 0) {
        const arrStaffRoomId = [] as any[];
        const arrResidentRoomId = [] as any[];
        arrfeaturesFloor.forEach(function (feature) {
          let featureRoomId = feature.id_;
          featureRoomId = featureRoomId.replace("roomid-", "");
          featureRoomId = featureRoomId.split("-")[0];
          const _featureRoomId = featureRoomId.split("-")[0];
          if (
            !arrStaffRoomId.includes(_featureRoomId) &&
            parseInt(feature.get("Type")) == enumCheckResidenStaff.Staff
          ) {
            arrStaffRoomId[arrStaffRoomId.length] = _featureRoomId;
          }
          if (
            !arrResidentRoomId.includes(_featureRoomId) &&
            parseInt(feature.get("Type")) == enumCheckResidenStaff.Resident
          ) {
            arrResidentRoomId[arrResidentRoomId.length] = _featureRoomId;
          }
          if (
            feature.get("Type") != enumCheckResidenStaff.Staff &&
            feature.get("Type") != enumCheckResidenStaff.Resident
          ) {
            SetStyleStaffResident(feature, Type, Map_image_path);
          }
        });

        for (let  idxRoom = 0; idxRoom < arrStaffRoomId.length; idxRoom++) {
          const total = getRoomDetails_liveview(
            map_sharedService,
            arrStaffRoomId[idxRoom],
            enumCheckResidenStaff.Staff
          );
          const arrStafffeaturesFloor = arrfeaturesFloor.filter(function (
            feature
          ) {
            let featureRoomId = feature.id_;
            featureRoomId = featureRoomId.replace("roomid-", "");
            featureRoomId = featureRoomId.split("-")[0];
            const _featureRoomId = featureRoomId.split("-")[0];

            if (_featureRoomId == arrStaffRoomId[idxRoom]) {
              if (feature.get("Type") == enumCheckResidenStaff.Staff) {
                feature.setStyle(new Style());
                if (total > 1) {
                  feature.set("Total", total);
                } else {
                  feature.set("Total", 0);
                }
                if (
                  feature.get("Type") == Type ||
                  Type == enumCheckResidenStaff.All
                ) {
                  return feature;
                }
              }
            }
          });
          if (arrStafffeaturesFloor.length > 0) {
            if (
              arrStafffeaturesFloor[0].get("Type") == Type ||
              Type == enumCheckResidenStaff.All
            ) {
              SetStyleStaffResident(
                arrStafffeaturesFloor[0],
                Type,
                Map_image_path
              );
            }
          }
        }
        for (let idxRoom = 0; idxRoom < arrResidentRoomId.length; idxRoom++) {
          const total = getRoomDetails_liveview(
            map_sharedService,
            arrResidentRoomId[idxRoom],
            enumCheckResidenStaff.Resident
          );
          const arrResidentfeaturesFloor = arrfeaturesFloor.filter(function (
            feature
          ) {
            let featureRoomId = feature.id_;
            featureRoomId = featureRoomId.replace("roomid-", "");
            featureRoomId = featureRoomId.split("-")[0];
            const _featureRoomId = featureRoomId.split("-")[0];

            if (_featureRoomId == arrResidentRoomId[idxRoom]) {
              if (feature.get("Type") == enumCheckResidenStaff.Resident) {
                feature.setStyle(new Style());
                if (total > 1) {
                  feature.set("Total", total);
                } else {
                  feature.set("Total", 0);
                }
                if (
                  feature.get("Type") == Type ||
                  Type == enumCheckResidenStaff.All
                ) {
                  return feature;
                }
              }
            }
          });
          if (arrResidentfeaturesFloor.length > 0) {
            if (
              arrResidentfeaturesFloor[0].get("Type") == Type ||
              Type == enumCheckResidenStaff.All
            ) {
              SetStyleStaffResident(
                arrResidentfeaturesFloor[0],
                Type,
                Map_image_path
              );
            }
          }
        }
        resStaffEnableDisable();
      } else {
        resStaffEnableDisable();
      }
    } else {
      resStaffEnableDisable();
    }
  } catch (err) {
    resStaffEnableDisable();
    throw new Error(
      "app-general.ts" + " - RefreshIconDetails() - " + err.message
    );
  }
}

function resStaffEnableDisable() {
  const liveviewLoading = document.getElementById("divliveviewloading");
  const chkResident = document.getElementById("chkresident");
  const chkStaff = document.getElementById("chkstaff");

  if (liveviewLoading != null) liveviewLoading.style.display = "none";

  if (chkResident != null) {
    chkResident.classList.remove("clsdisable");
    chkResident.classList.add("clsenable");
  }

  if (chkStaff != null) {
    chkStaff.classList.remove("clsdisable");
    chkStaff.classList.add("clsenable");
  }
}

function getRoomDetails_liveview(map_sharedService, RoomId, Type) {  
  const StaffResidents = AppComponent.SharedResidentStaffDetails;

  let arrMarkerStaffResident = [] as any[];
  let arrMarker = [] as any[];
  if (RoomId in StaffResidents) {
    arrMarkerStaffResident = StaffResidents[RoomId];
    arrMarker = arrMarkerStaffResident.filter(function (e1) {
      return e1.Type == Type;
    });
  }
  return arrMarker.length;
}

function SetStyleStaffResident(_featureIDX, Type, Map_image_path) {
  try {
    let spath = "";
    let ChkType = enumCheckResidenStaff.Resident;
    if (Type == enumCheckResidenStaff.All) {
      ChkType = parseInt(_featureIDX.get("Type"));
    } else {
      ChkType = Type;
    }

    if (ChkType == enumCheckResidenStaff.Resident) {
      spath = appsettings.RESIDENT_FOLDER;
    } else if (ChkType == enumCheckResidenStaff.Staff) {
      spath = appsettings.STAFF_FOLDER;
    }
    if (_featureIDX.get("Type")) {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = 30;
        canvas.height = 30;
        if (context != null) {
          context.scale(30 / image.width, 30 / image.height);
          context.drawImage(image, 0, 0);
        }
        const _ImageURL = canvas.toDataURL();
        _featureIDX.setStyle(function (stylefeature, resolution) {
          if (stylefeature.get("Type")) {
            if (stylefeature.get("iconStaff")) {
              if (parseInt(stylefeature.get("Type")) == ChkType) {
                if (parseInt(stylefeature.get("Total")) > 1) {
                  return [
                    alerticonstaff(
                      _ImageURL,
                      stylefeature.get("Total"),
                      ChkType,
                      resolution
                    ),
                  ];
                } else {
                  return [alerticonstaff(_ImageURL, 0, ChkType, resolution)];
                }
              }
            }
          }
        });
      };
      image.src = spath + _featureIDX.get("iconStaff");
      image.crossOrigin = "Anonymous";
    } else {
      _featureIDX.setStyle(function (stylefeature, resolution) {
        if (stylefeature.get("iconStyleCall")) {
          return [
            alerticonstyle(resolution, Map_image_path),
            alerticonred(
              resolution,
              Map_image_path,
              stylefeature.get("iconStyleCall")
            ),
          ];
        }
      });
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - SetStyleStaffResident() - " + err.message
    );
  }
}

function inArray(value, array) {
  try {
    for (let i = 0; i < array.length; i++) {
      if (array[i] == value) {
        return true;
      }
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - inArray() - " + err.message);
  }
  return false;
}

function Add_to_select(
  map,
  select,
  alertdatainfo,
  searchalerticonStyle,
  ImagePath,
  SharedFloorAlertDetails
) {
  let selectstyle = [] as any[];
  let AlertIconDetails;

  if (
    SharedFloorAlertDetails.SharedFloorAlertStatus !=
    alert_raisedon.resident_search
  ) {
    if (JSON.stringify(alertdatainfo) != undefined) {
      if (alertdatainfo.length > 0) {
        if (SharedFloorAlertDetails != undefined) {
          AlertIconDetails = alertdatainfo.filter(function (e1) {
            return e1.AlertId == SharedFloorAlertDetails.SharedFloorAlertId;
          });
        }
        if (AlertIconDetails != undefined) {
          if (AlertIconDetails.length > 0) {
            selectstyle = [
              alerticonstyle(1, ImagePath),
              alerticonred(1, ImagePath, AlertIconDetails[0].iconStyleImg),
            ];
          }
        }
      }
    }
  } else {
    selectstyle = [searchalerticonStyle];
  }

  const selectClick = new Select({
    condition: click,
    style: selectstyle,
  });

  select = selectClick;

  return select;
}
function ShowFloorPopupManual(
  SharedFloorAlertDetails,
  residentImgUrl,
  staffImgUrl,
  map,
  select,
  vectorLayer,
  alertdatainfo,
  arrFloorInfo,
  searchalerticonStyle,
  AlertId,
  map_SharedDetails,
  map_sharedService,
  popup,
  map_length,
  ResidentId,
  Router
) {
  try {
    if (SharedFloorAlertDetails != undefined) {
      const emit_AlertStatus = SharedFloorAlertDetails.SharedFloorAlertStatus;
      if (emit_AlertStatus == alert_raisedon.resident_search) {
        const arrfloorfeatures = vectorLayer.getSource().getFeatures();
        arrfloorfeatures.forEach(function (feature) {
          if (feature) {
            let featureid;
            if (feature.id_ != undefined) featureid = feature.id_;

            const geometry = feature.getGeometry();
            const corrdinates = geometry.getCoordinates();
            if (
              featureid != undefined &&
              featureid.substring(0, 7) == "roomid-" &&
              feature.get("Type") != undefined
            ) {
              const TagID =
                SharedFloorAlertDetails.SharedFloorRoomId.split("_")[0];
              const Type =
                SharedFloorAlertDetails.SharedFloorRoomId.split("_")[1];
              if (
                featureid ==
                  "roomid-" +
                    SharedFloorAlertDetails.SharedFloorAlertId +
                    "-" +
                    TagID &&
                feature.get("Type") == Type
              ) {                
                const Residents = AppComponent.SharedResidentStaffDetails;
                let roomId = featureid;
                roomId = roomId.replace("roomid-", "");
                roomId = roomId.split("-")[0];
                let content = "";
                let coord;
                if (roomId in Residents) {
                  map.getOverlays().clear();
                  coord = corrdinates;
                  popup = new Popup({
                    insertFirst: false,
                    name: "StaffLocation-" + feature.get("TagId"),
                    type: feature.get("Type"),
                  });
                  popup.autoPan = true;
                  popup.autoPanAnimation = { duration: 250 };
                  map.addOverlay(popup);

                  content +=
                    '<div style="height:35px;margin:0px;background-color:#E6E6E6">';
                  content +=
                    '<div style="padding-top:5px;padding-left:5px;white-space: nowrap;">';
                  content +=
                    '<img style="padding-right:10px;width:20px;height:20px;" src="assets/images/map/Alerts-Map-Location-Icon.png">';
                  content +=
                    '<label style=" color:#005493;font-size:18px;font-weight:bold">Information</label></div>';
                  content += "</div>";

                  const arrMarkerResident = Residents[roomId];
                  for (let idx = 0; idx < arrMarkerResident.length; idx++) {
                    if (
                      arrMarkerResident[idx].ID ==
                      map_SharedDetails.SharedResidentId
                    ) {
                      let Photo = "";
                      if (feature.get("Type") == 2) {
                        Photo =
                          appsettings.RESIDENT_FOLDER +
                          arrMarkerResident[idx].Photo;
                      } else if (feature.get("Type") == 1) {
                        Photo =
                          appsettings.STAFF_FOLDER +
                          arrMarkerResident[idx].Photo;
                      }
                      if (feature.get("Type") == arrMarkerResident[idx].Type) {
                        let roomdata = "";
                        let prevoiusLocationName;
                        if (arrMarkerResident[idx].LastRoomName != "") {
                          prevoiusLocationName =
                            arrMarkerResident[idx].LastRoomName;
                          if (prevoiusLocationName.length <= 20)
                            roomdata =
                              '<div class="tooltip"><span> since ' +
                              arrMarkerResident[idx].UpdatedTime +
                              '</span></div><div class="tooltip"><span> Previous Location: ' +
                              arrMarkerResident[idx].LastRoomName +
                              "</span></div>";
                          else
                            roomdata =
                              '<div class="tooltip"><span> since ' +
                              arrMarkerResident[idx].UpdatedTime +
                              '</span></div><div class="tooltip"><span> Previous Location: ' +
                              prevoiusLocationName.slice(0, 15) +
                              ".." +
                              '</span><span class="tooltiptext">' +
                              prevoiusLocationName +
                              "</span></div>";
                        }

                        const StaffFirstName = arrMarkerResident[idx].FirstName;
                        const StaffLastName = arrMarkerResident[idx].LastName;
                        const StaffFullName =
                          arrMarkerResident[idx].FirstName +
                          " " +
                          arrMarkerResident[idx].LastName;
                        let strStaffName;                                                                       
                        let strStaffEmail;
                        let clsStaffName;

                        if (
                          StaffFirstName.length >= 15 &&
                          StaffLastName.length < 15
                        ) {
                          clsStaffName = "margin-bottom:-8em;";
                          strStaffName =
                            '<div class="tooltip" style="width:auto;font-size:16px;padding-bottom:7px;"><span>' +
                            StaffFirstName.slice(0, 15) +
                            ".." +
                            "&nbsp;" +
                            StaffLastName +
                            "</span> " +
                            '<span class="tooltiptext" style= ' +
                            clsStaffName +
                            ">" +
                            StaffFirstName +
                            "</span></div>";
                        }
                        if (
                          StaffFirstName.length < 15 &&
                          StaffLastName.length >= 15
                        ) {
                          clsStaffName = "margin-bottom:-8em;";
                          strStaffName =
                            '<div class="tooltip" style="width:auto;font-size:16px;padding-bottom:7px;";><span>' +
                            StaffFirstName +
                            "&nbsp;" +
                            StaffLastName.slice(0, 15) +
                            ".." +
                            "</span> " +
                            '<span class="tooltiptext" style= ' +
                            clsStaffName +
                            ">" +
                            StaffLastName +
                            "</span></div>";
                        } else if (
                          StaffFirstName.length >= 15 &&
                          StaffLastName.length >= 15
                        ) {
                          clsStaffName = "margin-bottom:-8em;";
                          strStaffName =
                            '<div class="tooltip" style="width:auto;font-size:16px;padding-bottom:7px;"><span>' +
                            StaffFirstName.slice(0, 10) +
                            ".." +
                            "&nbsp;" +
                            StaffLastName.slice(0, 10) +
                            ".." +
                            "</span> " +
                            '<span class="tooltiptext" style= ' +
                            clsStaffName +
                            ">" +
                            StaffFullName +
                            "</span></div>";
                        } else if (
                          StaffFirstName.length < 15 &&
                          StaffLastName.length < 15
                        )
                          strStaffName =
                            '<div style="font-size:16px;padding-bottom:7px;">' +
                            arrMarkerResident[idx].FirstName +
                            "&nbsp;" +
                            arrMarkerResident[idx].LastName +
                            "</div>";

                        const StaffEmail = arrMarkerResident[idx].Email;
                        if (StaffEmail.length >= 20) {
                          strStaffEmail =
                            '<div class="tooltip" style="width:auto"><span>' +
                            StaffEmail.slice(0, 13) +
                            ".." +
                            "</span>" +
                            '<span class="tooltiptext">' +
                            StaffEmail +
                            "</span></div></div>";
                        } else
                          strStaffEmail =
                            '<div class="tooltip" style="width:auto"><span>' +
                            arrMarkerResident[idx].Email +
                            "</span></div></div>";

                        content +=
                          '<div class="clsLocationpopup" style="margin:5px; min-height: 100px;">' +
                          "<table><tbody>" +
                          '<tr><td style="background-color:white;">' +
                          '<div style="color:black;background-color:white;float:left;margin-bottom:0px;min-width:150px;-size:14px;width:315px;">' +
                          strStaffName +
                          '<div style="font-weight:bold">TagId: <span>' +
                          arrMarkerResident[idx].TagId +
                          "</span></div>" +
                          '<div class="tooltip"><span> Current Location: ' +
                          arrMarkerResident[idx].RoomName +
                          "</span></div>" +
                          roomdata +
                          "</div></td>" +
                          '<td style="background-color:white;">' +
                          '<div style="color:#005493;background-color:white;margin-right:20px;margin-bottom:0px;font-size:14px;width:150px;text-align:right;">' +
                          '<img style="width:120;height:40px;padding-bottom:15px;" src="' +
                          Photo +
                          '"><br>' +
                          '<div class="tooltip" style="width:auto"><span>' +
                          arrMarkerResident[idx].Gender +
                          "</span></div>" +
                          strStaffEmail +
                          "</td></tr>" +
                          "</tbody></table>" +
                          "<hr></div>";
                      }
                      break;
                    }
                  }
                  content = content.replace(
                    new RegExp("<hr></div>" + "$"),
                    "</div>"
                  );
                  content += "</div>";
                }
                if (content != "") {
                  const floornameinfo = undefined;
                  popup = new Popup({ insertFirst: false });
                  popup.autoPan = true;
                  popup.autoPanAnimation = { duration: 250 };
                  map.addOverlay(popup);

                  const el = load_alertpopup_style(floornameinfo, content, 1, 0);
                  popup = popup_overlay(popup, map, coord, el, 1);
                }
              }
            }
          }
        });
      } else {
        /// Show pop up using select feature ///
        const ImagePath = "assets/images/map/";
        select = Add_to_select(
          map,
          select,
          alertdatainfo,
          searchalerticonStyle,
          ImagePath,
          SharedFloorAlertDetails
        );
        select.getFeatures().clear();
        const arrfloorfeatures = vectorLayer.getSource().getFeatures();
        map = AppComponent.ShareMapDetails;
        vectorLayer = AppComponent.ShareMapVectorLayer;
        let AlertIds = "";
        AlertIds = getSearchResidentAlertId(
          ResidentId,
          alertdatainfo,
          SharedFloorAlertDetails.SharedFloorAlertId,
          1
        );

        const emit_AlertId = AlertIds;
        const emit_AlertStatus = SharedFloorAlertDetails.SharedFloorAlertStatus;
        const arremit_AlertId = emit_AlertId.toString().split(",");
        const AlertDetails = alertdatainfo.filter(function (e1) {
          if (arremit_AlertId.includes(e1.AlertId.toString())) {
            return e1.AlertId;
          }
        });
        if (
          arremit_AlertId.length == 1 &&
          AlertDetails != undefined &&
          AlertDetails.length > 0 &&
          emit_AlertStatus == alert_raisedon.leftside_alertselect &&
          SharedFloorAlertDetails.SharedFloorRoomId == null
        ) {
          let translateX = arrFloorInfo.TranslateX;
          let translateY = arrFloorInfo.TranslateY;

          translateX = translateX / 130;
          translateY = translateY / 135;

          translateX = translateX * arrFloorInfo.ImageWidth;
          translateY = translateY * arrFloorInfo.ImageHeight;

          const translate = [] as any[];
          const coord = map.getView().getCenter();

          translate[0] = coord[0] + translateX;
          translate[1] = coord[1] - translateY;

          PopopShow(
            map,
            popup,
            select,
            coord,
            map_sharedService,
            map_length,
            0,
            alertdatainfo,
            Router,
            AlertDetails,
            residentImgUrl,
            staffImgUrl,
            emit_AlertStatus,
            map_SharedDetails.SharedFloorId,
            1,
            map_SharedDetails,
            SharedFloorAlertDetails.SharedFloorAlertId,
            translate
          );
        } else {
          const arrFloorRoomInfo = groupBy(arrFloorInfo, "RoomId");
          arrfloorfeatures.forEach(function (feature) {
            if (feature) {
              let _featureid = feature.id_.replace("roomid-", "");
              _featureid = _featureid.split("_")[0];
              if (
                _featureid in arrFloorRoomInfo ||
                feature.get("DeviceTypeId") == enumDeviceType.ROOM.toString()
              ) {
                const geometry = feature.getGeometry();
                const coord = geometry.getCoordinates();
                
                
                if (feature.get("floorname") == undefined) {
                  const DeviceAlertId = feature.get("DeviceAlertId");
                  let isAlert = false;
                  let SelectedAlertDetails = undefined;
                  if (emit_AlertStatus == alert_raisedon.resident_search) {
                    isAlert = inArray(DeviceAlertId, arremit_AlertId);
                    SelectedAlertDetails = AlertDetails.filter(function (e1) {
                      if (isAlert && DeviceAlertId == e1.AlertId) {
                        return e1.AlertId;
                      }
                    });
                  }
                  if (
                    SelectedAlertDetails == undefined ||
                    SelectedAlertDetails.length == 0
                  ) {
                    let DeviceRoomId = feature.id_.replace("roomid-", "");
                    DeviceRoomId = DeviceRoomId.split("_")[0];
                    let DeviceAlertId = feature.get("DeviceAlertId");
                    const DeviceTypeId = feature.get("DeviceTypeId");
                    isAlert = true;
                    if (DeviceRoomId == undefined) {
                      DeviceRoomId = 0;
                    }
                    if (DeviceAlertId == undefined) {
                      DeviceAlertId = 0;
                    }

                    SelectedAlertDetails = AlertDetails.filter(function (e1) {
                      if (
                        e1.AlertType == enumAlertType.Pull_Cord &&
                        e1.ismobilitymode == 1 &&
                        e1.DeviceType != enumDeviceType.HREX &&
                        e1.DeviceType != enumDeviceType.REX
                      ) {
                        if (
                          isAlert &&
                          (DeviceRoomId == e1.RoomId ||
                            DeviceAlertId == e1.AlertId) &&
                          DeviceTypeId == e1.DeviceType
                        ) {
                          return e1.AlertId;
                        }
                      } else if (
                        e1.AlertType == enumAlertType.Pull_Cord &&
                        e1.ismobilitymode == 0 &&
                        e1.DeviceType != enumDeviceType.HREX &&
                        e1.DeviceType != enumDeviceType.REX
                      ) {
                        if (
                          isAlert &&
                          (DeviceRoomId == e1.TagId ||
                            DeviceAlertId == e1.AlertId)
                        ) {
                          return e1.AlertId;
                        }
                      } else if (
                        e1.AlertType == enumAlertType.UT &&
                        e1.ismobilitymode == 1
                      ) {
                        if (
                          isAlert &&
                          (DeviceRoomId == e1.RoomId ||
                            DeviceAlertId == e1.AlertId)
                        ) {
                          return e1.AlertId;
                        }
                      } else if (
                        e1.AlertType == enumAlertType.UT &&
                        e1.ismobilitymode == 0
                      ) {
                        if (
                          isAlert &&
                          (DeviceRoomId == e1.RoomId ||
                            DeviceAlertId == e1.AlertId)
                        ) {
                          return e1.AlertId;
                        }
                      } else {
                        let _RoomId = e1.RoomId;
                        if (
                          _RoomId == null ||
                          _RoomId == null ||
                          _RoomId == 0
                        ) {
                          _RoomId = e1.LastRoomId;
                        }
                        if (DeviceTypeId == enumDeviceType.ROOM.toString()) {
                          if (
                            isAlert &&
                            (DeviceRoomId == e1.UnitId ||
                              DeviceAlertId == e1.AlertId) &&
                            e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
                          ) {
                            return e1.AlertId;
                          }
                        } else {
                          if (
                            isAlert &&
                            (DeviceRoomId == _RoomId ||
                              DeviceAlertId == e1.AlertId) &&
                            (CheckAlertByDeviceTypeId(
                              e1.LocationType,
                              e1.DeviceType,
                              DeviceTypeId
                            ) ||
                              e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm)
                          ) {
                            return e1.AlertId;
                          }
                        }
                      }
                    });
                  }

                  if (
                    SelectedAlertDetails != undefined &&
                    SelectedAlertDetails.length > 0
                  ) {
                    
                    if (
                      emit_AlertStatus == alert_raisedon.resident_search &&
                      isAlert
                    ) {
                      select = addSearchMarker(
                        coord,
                        map,
                        select,
                        0,
                        SelectedAlertDetails[0].AlertId,
                        searchalerticonStyle,
                        1
                      );
                    } else {
                      select.getFeatures().push(feature);
                    }
                    /////////////show alert Dialog//////////////
                    if (feature.get("DeviceAlertId") != undefined) {
                      PopopShow(
                        map,
                        popup,
                        select,
                        coord,
                        map_sharedService,
                        map_length,
                        0,
                        alertdatainfo,
                        Router,
                        AlertDetails,
                        residentImgUrl,
                        staffImgUrl,
                        emit_AlertStatus,
                        map_SharedDetails.SharedFloorId,
                        1,
                        map_SharedDetails,
                        feature.get("DeviceAlertId"),
                        []
                      );
                    }
                  }
                }
              }
            }
          });
        }

        map_sharedService.changedMapDetails(map);
        map_sharedService.changedMapVectorLayer(vectorLayer);
      }
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - ShowFloorPopupManual() - " + err.message
    );
  }
}

function addAlertMarker(
  map_sharedService,
  fcnt,
  mapHeight,
  floorbaseddatainfo,
  chkFloorId,
  floorinfo,
  ChkRoomId,
  vectorLayer,
  SubZoneId
) {
  const markerpoint = getmarkerpointforFloor(floorinfo[ChkRoomId][0], mapHeight);

  let getfilteredAlertData = floorbaseddatainfo[chkFloorId];
  const chkgetfilteredAlertData = getfilteredAlertData;
  getfilteredAlertData = [];
  getfilteredAlertData[0] = chkgetfilteredAlertData[fcnt];

  const marker = addAlertMarkerforFloor(
    ChkRoomId,
    floorinfo[ChkRoomId][0]["RoomName"],
    floorinfo[ChkRoomId][0]["DeviceId"],
    floorinfo[ChkRoomId][0]["FloorId"],
    markerpoint,
    floorbaseddatainfo[chkFloorId][fcnt].AlertId,
    getfilteredAlertData,
    map_sharedService,
    floorinfo[ChkRoomId][0]["FilterDeviceTypeId"],
    SubZoneId
  );
  vectorLayer.getSource().addFeature(marker);
  return vectorLayer;
}

function addAlertUnitMarker(
  mapHeight,
  Chk_polyline,
  Chk_AlertInfo,
  map_sharedService,
  chkFloorId,
  ChkRoomId,
  ChkDeviceTypeId,
  ChkName,
  vectorLayer,
  SubZoneId
) {
  const markerpoint: any[] = getmarkerpointforFloor(Chk_polyline, mapHeight);

  const getfilteredAlertData: any = [];
  getfilteredAlertData[0] = Chk_AlertInfo;

  const marker = addAlertMarkerforFloor(
    ChkRoomId,
    ChkName,
    "0",
    chkFloorId,
    markerpoint,
    Chk_AlertInfo.AlertId,
    getfilteredAlertData,
    map_sharedService,
    ChkDeviceTypeId,
    SubZoneId
  );
  vectorLayer.getSource().addFeature(marker);
  return vectorLayer;
}

function CheckAlertDataChanged(
  pollingData,
  map,
  map_sharedService,
  map_SharedDetails,
  map_CheckAlertData,
  alertdatainfo,
  residentImgUrl,
  staffImgUrl,
  popup,
  select,
  vectorLayer,
  arrSvgInfo,
  searchalerticonStyle,
  mapHeight,
  map_length,
  ImagePath,
  CampusId,
  FloorId,
  Router
) {
  const data = [];
  try {
    map_SharedDetails = AppComponent.SharedDetails;
    if (map_SharedDetails != null) {
      if (
        CampusId != map_SharedDetails.SharedCampusId ||
        FloorId != map_SharedDetails.SharedFloorId
      ) {
        if (pollingData != undefined) pollingData.unsubscribe();
      }
    }
    map_CheckAlertData = AppComponent.SharedCheckAlertDetails;
    let map_SharedFloorAlertDetails;
    if (map_CheckAlertData != null) {
      if (map_CheckAlertData != undefined) {
        map = AppComponent.ShareMapDetails;
        vectorLayer = AppComponent.ShareMapVectorLayer;
        if (map != null && vectorLayer != null) {
          if (map_CheckAlertData.IsAlertChanged == 1) {
            map_CheckAlertData.IsAlertChanged = 0;
            map_sharedService.changeSharedCheckAlert(map_CheckAlertData);
            alertdatainfo = AppComponent.SharedAlertDetails;

            //Alert            
            const alertbaseddatainfo = AppComponent.SharedAlertBasedDetails;
            //Building            
            const buildbaseddatainfo = AppComponent.SharedBuildBasedDetails;
            //Floor            
            const floorbaseddatainfo = AppComponent.SharedFloorBasedDetails;            
            map_SharedFloorAlertDetails = AppComponent.SharedFloorAlertDetails;

            if (
              alertdatainfo != null &&
              alertbaseddatainfo != null &&
              buildbaseddatainfo != null &&
              floorbaseddatainfo != null
            ) {
              const arrfeatures = [];
              // const mapChk_SharedDetails = map_SharedDetails;
              if (
                map_SharedDetails.SharedBuildingId == 0 &&  map_SharedDetails.SharedAlertId != undefined &&
                map_SharedDetails.SharedAlertId != ""
              ) {
                const getfilteredAlertData =
                  alertbaseddatainfo[map_SharedDetails.SharedAlertId];
                map.getOverlays().clear();
                const coord = map.getView().getCenter();
                PopopShow(
                  map,
                  popup,
                  select,
                  coord,
                  map_sharedService,
                  map_length,
                  mapHeight,
                  alertdatainfo,
                  Router,
                  getfilteredAlertData,
                  residentImgUrl,
                  staffImgUrl,
                  map_SharedDetails.SharedAlertStatus,
                  null,
                  0,
                  null,
                  0,
                  []
                );
              } else if (map_SharedFloorAlertDetails != undefined && map_SharedFloorAlertDetails.SharedFloorAlertId !=undefined) {
                if (
                  map_SharedFloorAlertDetails.SharedFloorAlertId
                    .SharedAlertId != "" &&
                  map_SharedFloorAlertDetails.SharedFloorAlertId
                    .SharedAlertId == map_SharedDetails.SharedAlertId &&
                  map_SharedFloorAlertDetails.SharedFloorAlertId != "" &&
                  map_SharedFloorAlertDetails.SharedFloorAlertStatus ==
                    alert_raisedon.leftside_alertselect &&
                  map_SharedFloorAlertDetails.SharedFloorRoomId == null
                ) {
                  map.getOverlays().clear();
                  ShowFloorPopupManual(
                    map_SharedFloorAlertDetails,
                    residentImgUrl,
                    staffImgUrl,
                    map,
                    select,
                    vectorLayer,
                    alertdatainfo,
                    arrSvgInfo,
                    searchalerticonStyle,
                    map_SharedFloorAlertDetails.AlertId,
                    map_SharedDetails,
                    map_sharedService,
                    popup,
                    map_length,
                    undefined,
                    Router
                  );
                } else {
                  AlertPopup(
                    map,
                    map_sharedService,
                    map_SharedDetails,
                    alertdatainfo,
                    residentImgUrl,
                    staffImgUrl,
                    popup,
                    select,
                    vectorLayer,
                    arrSvgInfo,
                    mapHeight,
                    map_length,
                    ImagePath,
                    alertbaseddatainfo,
                    buildbaseddatainfo,
                    floorbaseddatainfo,
                    arrfeatures,
                    Router
                  );
                }
              } else {
                AlertPopup(
                  map,
                  map_sharedService,
                  map_SharedDetails,
                  alertdatainfo,
                  residentImgUrl,
                  staffImgUrl,
                  popup,
                  select,
                  vectorLayer,
                  arrSvgInfo,
                  mapHeight,
                  map_length,
                  ImagePath,
                  alertbaseddatainfo,
                  buildbaseddatainfo,
                  floorbaseddatainfo,
                  arrfeatures,
                  Router
                );
              }
            }
          }
          if (map_CheckAlertData.IsAlertClicked > 0) {
            map_CheckAlertData.IsAlertClicked = 0;
            map_sharedService.changeSharedCheckAlert(map_CheckAlertData);
            map_SharedDetails = AppComponent.SharedDetails;
            alertdatainfo = AppComponent.SharedAlertDetails;

            if (map_SharedDetails != null && alertdatainfo != null) {
              const layersToRemove = [] as any[];
              map.getLayers().forEach(function (layer) {
                if (
                  layer.get("name") != undefined &&
                  layer.get("name") === "SearchIconBuilding"
                ) {
                  layersToRemove.push(layer);
                }
              });

              const len = layersToRemove.length;
              for (let nlayers = 0; nlayers < len; nlayers++) {
                map.removeLayer(layersToRemove[nlayers]);
              }

              map.getOverlays().clear();
              select = null;

              if (map_SharedDetails.SharedFloorId == 0) {                
                const buildbaseddatainfo = AppComponent.SharedBuildBasedDetails;
                if (buildbaseddatainfo != null) {
                  
                  let arrfeatures = [] as any[];
                  const mapChk_SharedDetails = map_SharedDetails;

                  arrfeatures = vectorLayer.getSource().getFeatures();
                  arrfeatures.forEach(function (feature) {
                    if (
                      feature.get("buildingid") ==
                      mapChk_SharedDetails.SharedBuildingId
                    ) {
                      let AlertIconDetails;
                      if (feature.get("buildingid") in buildbaseddatainfo) {
                        AlertIconDetails =
                          buildbaseddatainfo[feature.get("buildingid")];
                        const getAlertType = AlertIconDetails.filter(function (
                          e1
                        ) {
                          return e1.AckStatus == enumAlertAckStatus.Notified;
                        });
                        let getfilteredAlertData = AlertIconDetails;
                        if (
                          getAlertType != undefined &&
                          getAlertType.length == 0
                        ) {
                          getfilteredAlertData = AlertIconDetails.filter(
                            function (e1) {
                              return (
                                e1.AlertId == map_SharedDetails.SharedAlertId
                              );
                            }
                          );
                        }
                        AlertIconDetails = getfilteredAlertData;
                      }

                      if (
                        AlertIconDetails != undefined &&
                        AlertIconDetails.length > 0 &&
                        AlertIconDetails.length == 1
                      ) {
                        feature.set(
                          "iconStyleCall",
                          AlertIconDetails[0].iconStyleImg
                        );
                        feature.setStyle(function (feature, resolution) {
                          if (feature.get("iconStyleCall")) {
                            return [
                              alerticonstyle(resolution, ImagePath),
                              alerticonred(
                                resolution,
                                ImagePath,
                                feature.get("iconStyleCall")
                              ),
                            ];
                          }
                        });
                      }
                      return false;
                    } else {
                      return true;
                    }
                  });
                  ShowPopupManual(
                    map_sharedService,
                    map_SharedDetails.SharedAlertId,
                    map_SharedDetails.SharedAlertStatus,
                    residentImgUrl,
                    staffImgUrl,
                    map,
                    select,
                    vectorLayer,
                    alertdatainfo,
                    arrSvgInfo,
                    searchalerticonStyle,
                    mapHeight,
                    map_length,
                    ImagePath,
                    map_SharedDetails.SharedResidentId,
                    map_SharedDetails.SharedBuildingId,
                    Router
                  );
                }
              } else {                 
               const map_SharedFloorAlertDetails =
                  AppComponent.SharedFloorAlertDetails;
                ShowFloorPopupManual(
                  map_SharedFloorAlertDetails,
                  residentImgUrl,
                  staffImgUrl,
                  map,
                  select,
                  vectorLayer,
                  alertdatainfo,
                  arrSvgInfo,
                  searchalerticonStyle,
                  map_SharedFloorAlertDetails.SharedFloorAlertId,
                  map_SharedDetails,
                  map_sharedService,
                  popup,
                  map_length,
                  undefined,
                  Router
                );
              }

              map_sharedService.changedMapDetails(map);
              map_sharedService.changedMapVectorLayer(vectorLayer);
            }
          }
        }
      }
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - CheckAlertDataChanged() - " + err.message
    );
  }
  return data;
}

function AlertPopup(
  map,
  map_sharedService,
  map_SharedDetails,
  alertdatainfo,
  residentImgUrl,
  staffImgUrl,
  popup,
  select,
  vectorLayer,
  arrSvgInfo,
  mapHeight,
  map_length,
  ImagePath,
  alertbaseddatainfo,
  buildbaseddatainfo,
  floorbaseddatainfo,
  arrfeatures,
  Router
) {
  let arrCheckAlertBuildingId = [] as any[];
  let arrCheckAlertRoomId = [] as any[];
  if (map_SharedDetails.SharedFloorId == 0) {
    let arrBuildingId = [] as any[];
    let arrAlertBuildingId = [] as any[];
    
    arrAlertBuildingId = vectorLayer["values_"]["arrAlertBuildingId"];
    arrCheckAlertBuildingId = vectorLayer["values_"]["arrAlertBuildingId"];
    arrBuildingId = vectorLayer["values_"]["arrBuildingId"];
    const buildinginfo = groupBy(arrSvgInfo, "buildingid");
    const spaceinfo = groupBy(arrSvgInfo, "spaceId");

    for (let bcnt = 0; bcnt < arrBuildingId.length; bcnt++) {
      const ChkBuildingId = arrBuildingId[bcnt];
      if (buildbaseddatainfo != undefined) {
        if (ChkBuildingId in buildbaseddatainfo) {
          if (!arrAlertBuildingId.includes(ChkBuildingId)) {
            arrAlertBuildingId[arrAlertBuildingId.length] = ChkBuildingId;
            const getfilteredAlertData = buildbaseddatainfo[ChkBuildingId];
            let polyline = [];

            if (buildinginfo[ChkBuildingId] != undefined) {
              if (buildinginfo[ChkBuildingId][0]["polyline"] != undefined) {
                polyline =
                  buildinginfo[ChkBuildingId][0]["polyline"].split(" ");
              }
            } else if (spaceinfo[ChkBuildingId.split("_")[0]] != undefined) {
              if (
                spaceinfo[ChkBuildingId.split("_")[0]][0]["polyline"] !=
                undefined
              ) {
                polyline =
                  spaceinfo[ChkBuildingId.split("_")[0]][0]["polyline"].split(
                    " "
                  );
              }
            }

            if (polyline.length > 0) {
              const chk_arrfeatures = vectorLayer.getSource().getFeatures();
              if (
                chk_arrfeatures.length > 0 &&
                map_SharedDetails.SharedFloorId == 0 &&
                !arrCheckAlertBuildingId.includes(
                  chk_arrfeatures[0].get("buildingid")
                ) &&
                chk_arrfeatures[0].get("iconStyleCall") == undefined
              ) {
                vectorLayer.getSource().removeFeature(chk_arrfeatures[0]);
              }
              const markerpoint = calculatexycoordinates(polyline, mapHeight);
              const marker = addAlertMarkerforBuilding(
                markerpoint,
                ChkBuildingId,
                map_sharedService,
                getfilteredAlertData,
                1
              );
              vectorLayer.getSource().addFeature(marker);
            }
          }
        }
      }
    }

    for (let bcnt = 0; bcnt < arrAlertBuildingId.length; bcnt++) {
      if (!(arrAlertBuildingId[bcnt] in buildbaseddatainfo)) {
        arrCheckAlertBuildingId.splice(bcnt, 1);
      }
    }
    vectorLayer["values_"]["arrAlertBuildingId"] = arrCheckAlertBuildingId;
  } else {
    
    let arrAlertRoomId = [] as any[];    

    arrAlertRoomId = vectorLayer["values_"]["arrAlertRoomId"];
    arrCheckAlertRoomId = vectorLayer["values_"]["arrAlertRoomId"];
    
    const floorinfo = groupBy(arrSvgInfo, "RoomId");
    const floordeviceinfo = groupBy(arrSvgInfo, "DeviceId");
    let flooralertinfo = groupBy(arrSvgInfo, "AlertId");
    
    const chkFloorId = map_SharedDetails.SharedFloorId;
    

    if (chkFloorId in floorbaseddatainfo) {
      for (let fcnt = 0; fcnt < floorbaseddatainfo[chkFloorId].length; fcnt++) {
        flooralertinfo = floorbaseddatainfo[chkFloorId][fcnt];
        let ChkRoomId = flooralertinfo.RoomId;
        const ChkDeviceId = flooralertinfo.TagId;
        let ChkDeviceTypeId = 0;
        const SubZoneId=0;
        const arrChkRoomId = floorinfo[ChkRoomId];

        let ChkIsRoomId = false;

        if (arrChkRoomId != undefined) {
          if (arrChkRoomId.length > 0) {
            for (let rcnt = 0; rcnt < arrChkRoomId.length; rcnt++) {
              ChkRoomId = flooralertinfo.RoomId;
              ChkIsRoomId = false;
              ChkDeviceTypeId = 0;
              const Chk_AlertInfo = [] as any[];
              let ChkName = "";
              const Chk_polyline = { polyline: "" };
              if (
                flooralertinfo.AlertType == enumAlertType.Pull_Cord &&
                flooralertinfo.ismobilitymode == 1 &&
                arrChkRoomId[rcnt].FilterDeviceTypeId != enumDeviceType.HREX &&
                arrChkRoomId[rcnt].FilterDeviceTypeId != enumDeviceType.REX
              ) {
                ChkRoomId = flooralertinfo.RoomId;
                ChkDeviceTypeId = arrChkRoomId[rcnt].FilterDeviceTypeId;
                ChkIsRoomId = true;
              } else if (
                flooralertinfo.AlertType == enumAlertType.Pull_Cord &&
                flooralertinfo.ismobilitymode == 0 &&
                arrChkRoomId[rcnt].FilterDeviceTypeId != enumDeviceType.HREX &&
                arrChkRoomId[rcnt].FilterDeviceTypeId != enumDeviceType.REX
              ) {
                ChkRoomId = flooralertinfo.TagId;
                ChkDeviceTypeId = arrChkRoomId[rcnt].FilterDeviceTypeId;
                ChkIsRoomId = true;
              } else {
                let _RoomId = flooralertinfo.RoomId;
                if (_RoomId == null || _RoomId == null || _RoomId == 0) {
                  _RoomId = flooralertinfo.LastRoomId;
                }
                if (
                  arrChkRoomId[rcnt].FilterDeviceTypeId ==
                    enumDeviceType.ROOM.toString() &&
                  flooralertinfo.UnitId == arrChkRoomId[rcnt].UnitId &&
                  flooralertinfo.AlertId > 0 &&
                  flooralertinfo.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
                ) {
                  ChkRoomId = arrChkRoomId[rcnt].UnitId;
                  ChkDeviceTypeId = arrChkRoomId[rcnt].FilterDeviceTypeId;
                  ChkIsRoomId = true;
                  ChkName = arrChkRoomId[rcnt].UnitLocation;
                  Chk_polyline.polyline = arrChkRoomId[rcnt].polyline;
                  Chk_AlertInfo[0] = flooralertinfo;
                } else if (
                  arrChkRoomId[rcnt].FilterDeviceTypeId !=
                  enumDeviceType.ROOM.toString()
                ) {
                  if (
                    CheckAlertByDeviceTypeId(
                      flooralertinfo.LocationType,
                      flooralertinfo.DeviceType,
                      arrChkRoomId[rcnt].FilterDeviceTypeId
                    )
                  ) {
                    ChkRoomId = _RoomId;
                    ChkDeviceTypeId = arrChkRoomId[rcnt].FilterDeviceTypeId;
                    ChkIsRoomId = true;
                    ChkName = "";
                  }
                }
              }

              if (ChkIsRoomId) {
                if (
                  ChkDeviceTypeId == enumDeviceType.ROOM &&
                  Chk_AlertInfo.length > 0
                ) {
                  // const markerpoint: any[] = getmarkerpointforFloor(
                  //   Chk_polyline,
                  //   mapHeight
                  // );

                  const getfilteredAlertData: any = [];
                  getfilteredAlertData[0] = Chk_AlertInfo[0];
                  if (
                    !arrCheckAlertRoomId.includes(
                      ChkRoomId + "_" + ChkDeviceTypeId
                    )
                  ) {
                    arrCheckAlertRoomId[arrCheckAlertRoomId.length] =
                      ChkRoomId + "_" + ChkDeviceTypeId;
                    vectorLayer = addAlertUnitMarker(
                      mapHeight,
                      Chk_polyline,
                      getfilteredAlertData,
                      map_sharedService,
                      chkFloorId,
                      ChkRoomId,
                      ChkDeviceTypeId,
                      ChkName,
                      vectorLayer,
                      SubZoneId
                    );
                  }
                } else {
                  if (
                    !arrCheckAlertRoomId.includes(
                      ChkRoomId + "_" + ChkDeviceTypeId
                    ) &&
                    ChkRoomId in floorinfo
                  ) {
                    arrCheckAlertRoomId[arrCheckAlertRoomId.length] =
                      ChkRoomId + "_" + ChkDeviceTypeId;
                    vectorLayer = addAlertMarker(
                      map_sharedService,
                      fcnt,
                      mapHeight,
                      floorbaseddatainfo,
                      chkFloorId,
                      floorinfo,
                      ChkRoomId,
                      vectorLayer,
                      SubZoneId
                    );
                  } else if (
                    !arrCheckAlertRoomId.includes(
                      ChkRoomId + "_" + ChkDeviceTypeId
                    ) &&
                    ChkDeviceId in floordeviceinfo
                  ) {
                    arrCheckAlertRoomId[arrCheckAlertRoomId.length] =
                      ChkRoomId + "_" + ChkDeviceTypeId;
                    vectorLayer = addAlertMarker(
                      map_sharedService,
                      fcnt,
                      mapHeight,
                      floorbaseddatainfo,
                      chkFloorId,
                      floordeviceinfo,
                      ChkDeviceId,
                      vectorLayer,
                      SubZoneId
                    );
                  }
                }
              }
            }
          }
        }
      }
    }

    for (let bcnt = 0; bcnt < arrAlertRoomId.length; bcnt++) {
      const floor_RoomId = arrAlertRoomId[bcnt].split("_")[0];
      const floor_DeviceTypeId = arrAlertRoomId[bcnt].split("_")[1];
      const getfilteredAlertData = alertdatainfo.filter(function (e1) {
        if (
          e1.AlertType == enumAlertType.Pull_Cord &&
          e1.ismobilitymode == 1 &&
          e1.DeviceType != enumDeviceType.HREX &&
          e1.DeviceType != enumDeviceType.REX
        ) {
          if (
            e1.FloorId == chkFloorId &&
            e1.RoomId == floor_RoomId &&
            e1.DeviceType == floor_DeviceTypeId
          ) {
            return e1.RoomId;
          }
        } else if (
          e1.AlertType == enumAlertType.Pull_Cord &&
          e1.ismobilitymode == 0 &&
          e1.DeviceType != enumDeviceType.HREX &&
          e1.DeviceType != enumDeviceType.REX
        ) {
          if (e1.FloorId == chkFloorId && e1.TagId == floor_RoomId) {
            return e1.TagId;
          }
        } else if (e1.AlertType == enumAlertType.UT && e1.ismobilitymode == 1) {
          if (e1.FloorId == chkFloorId && e1.RoomId == floor_RoomId) {
            return e1.RoomId;
          }
        } else if (e1.AlertType == enumAlertType.UT && e1.ismobilitymode == 0) {
          if (e1.FloorId == chkFloorId && e1.TagId == floor_RoomId) {
            return e1.TagId;
          }
        } else {
          let _RoomId = e1.RoomId;
          if (_RoomId == null || _RoomId == null || _RoomId == 0) {
            _RoomId = e1.LastRoomId;
          }
          if (floor_DeviceTypeId == enumDeviceType.ROOM.toString()) {
            if (
              e1.FloorId == chkFloorId &&
              e1.UnitId == floor_RoomId &&
              e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
            ) {
              return e1.UnitId;
            }
          } else {
            if (
              e1.FloorId == chkFloorId &&
              e1.RoomId == floor_RoomId &&
              (CheckAlertByDeviceTypeId(
                e1.LocationType,
                e1.DeviceType,
                floor_DeviceTypeId
              ) ||
                e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm)
            ) {
              return e1.RoomId;
            }
          }
        }
      });
      if (getfilteredAlertData.length == 0) {
        arrCheckAlertRoomId.splice(bcnt, 1);
      }
    }
    vectorLayer["values_"]["arrAlertRoomId"] = arrCheckAlertRoomId;
  }

  arrfeatures = vectorLayer.getSource().getFeatures();
  let AlertIconDetails;
  arrfeatures.forEach(function (feature) {
    if (map_SharedDetails.SharedFloorId == 0) {
      if (!arrCheckAlertBuildingId.includes(feature.get("buildingid"))) {
        removePopup(map, feature);
      } else {
        
        if (feature.get("buildingid") != undefined) {
          if (feature.get("buildingid") in buildbaseddatainfo) {
            AlertIconDetails = buildbaseddatainfo[feature.get("buildingid")];
            const getAlertType = AlertIconDetails.filter(function (e1) {
              return e1.AckStatus == enumAlertAckStatus.Notified;
            });
            let getfilteredAlertData = AlertIconDetails;
            if (getAlertType != undefined && getAlertType.length == 0) {
              if (map_SharedDetails.SharedAlertId != "") {
                getfilteredAlertData =
                  alertbaseddatainfo[map_SharedDetails.SharedAlertId];
                if (
                  getfilteredAlertData != undefined &&
                  getfilteredAlertData.length > 0
                ) {
                  if (
                    getfilteredAlertData[0]["BuildId"] ==
                    feature.get("buildingid")
                  ) {
                    AlertIconDetails = getfilteredAlertData;
                  }
                }
              }
            }
          } else {
            removePopup(map, feature);
          }
        }
      }
    } else {
      const DeviceFloorid = feature.get("floorid");
      const DeviceRoomId = feature.get("DeviceRoomId");
      const DeviceTypeId = feature.get("DeviceTypeId");

      if (!arrCheckAlertRoomId.includes(DeviceRoomId + "_" + DeviceTypeId)) {
        removePopup(map, feature);
      } else {
        if (DeviceFloorid in floorbaseddatainfo) {
          const getfilteredAlertData = alertdatainfo.filter(function (e1) {
            if (
              e1.AlertType == enumAlertType.Pull_Cord &&
              e1.ismobilitymode == 1 &&
              e1.DeviceType != enumDeviceType.HREX &&
              e1.DeviceType != enumDeviceType.REX
            ) {
              return (
                e1.RoomId == DeviceRoomId &&
                e1.FloorId == DeviceFloorid &&
                e1.DeviceType == DeviceTypeId
              );
            } else if (
              e1.AlertType == enumAlertType.Pull_Cord &&
              e1.ismobilitymode == 0 &&
              e1.DeviceType != enumDeviceType.HREX &&
              e1.DeviceType != enumDeviceType.REX
            ) {
              return e1.TagId == DeviceRoomId && e1.FloorId == DeviceFloorid;
            } else if (
              e1.AlertType == enumAlertType.UT &&
              e1.ismobilitymode == 1
            ) {
              return e1.RoomId == DeviceRoomId && e1.FloorId == DeviceFloorid;
            } else if (
              e1.AlertType == enumAlertType.UT &&
              e1.ismobilitymode == 0
            ) {
              return e1.TagId == DeviceRoomId && e1.FloorId == DeviceFloorid;
            } else {
              let _RoomId = e1.RoomId;
              if (_RoomId == null || _RoomId == null || _RoomId == 0) {
                _RoomId = e1.LastRoomId;
              }
              if (DeviceTypeId == enumDeviceType.ROOM.toString()) {
                return (
                  e1.UnitId == DeviceRoomId &&
                  e1.FloorId == DeviceFloorid &&
                  e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
                );
              } else {
                return (
                  _RoomId == DeviceRoomId &&
                  e1.FloorId == DeviceFloorid &&
                  CheckAlertByDeviceTypeId(
                    e1.LocationType,
                    e1.DeviceType,
                    DeviceTypeId
                  )
                );
              }
            }
          });

          AlertIconDetails = getfilteredAlertData;
          if (AlertIconDetails == undefined) {
            removePopup(map, feature);
          }
        } else {
          removePopup(map, feature);
        }
      }
    }
    if (!feature.get("Type")) {
    
      let featureCheck = false;
      if (AlertIconDetails != undefined && AlertIconDetails.length > 0) {
        const arriconStyleCall = geticonStyle(
          map_sharedService,
          AlertIconDetails
        );

        feature.set("iconStyleCall", arriconStyleCall[0]);
        feature.set("Isinfra", arriconStyleCall[1].IsfraOpen);
        feature.set("isInfraFloor", arriconStyleCall[1].IsfraOpen);

        feature.setStyle(function (feature, resolution) {
          let featureId = feature.id_;
          featureId = featureId.split("-");
          if(featureId[0] == "buildingid")          
            featureCheck = (feature.get("buildingid") != undefined);
          if(featureId[0] == "roomid")          
            featureCheck = (feature.get("DeviceAlertId") != undefined);
          if (feature.get("iconStyleCall") && featureCheck) {           
            return [
              alerticonstyle(resolution, ImagePath),
              alerticonred(resolution, ImagePath, feature.get("iconStyleCall")),
            ];
          }
        });

        if (
          map_SharedDetails.SharedBuildingId == feature.get("buildingid") &&
          map_SharedDetails.SharedAlertId != ""
        ) {
          AlertIconDetails = AlertIconDetails.filter(function (e1) {
            if (alertbaseddatainfo[e1.AlertId]) {
              return e1.AlertId == map_SharedDetails.SharedAlertId;
            }
          });
        } else if (
          map_SharedDetails.SharedFloorId != 0 &&
          map_SharedDetails.SharedFloorId == feature.get("floorid") &&
          map_SharedDetails.SharedAlertId != ""
        ) {
          AlertIconDetails = AlertIconDetails.filter(function (e1) {
            if (alertbaseddatainfo[e1.AlertId]) {
              return e1.AlertId == map_SharedDetails.SharedAlertId;
            }
          });
        }

        const popupfeatures = map.getOverlays();
        const arrpopupfeatures = popupfeatures.array_;
        if (arrpopupfeatures != undefined && arrpopupfeatures.length > 0) {
          for (let popidx = 0; popidx < arrpopupfeatures.length; popidx++) {
            if (arrpopupfeatures[popidx]["type"] !== "SearchPopup") {
              const popid = arrpopupfeatures[popidx]["Id"];
              const popname = arrpopupfeatures[popidx]["options"]["name"];
              let markerid;
              let chkAlertId = true;
              if (feature.get("buildingid") != undefined) {
                markerid = "buildingid-" + feature.get("buildingid");
                chkAlertId = true;
              } else if (feature.get("floorid") != undefined) {
                markerid = "floorid-" + feature.get("floorid");
                if (
                  arrpopupfeatures[popidx]["options"]["devicename"] ==
                  "roomalertid-" + feature.get("DeviceAlertId")
                )
                  chkAlertId = true;
                else chkAlertId = false;
              }

              if ((popid == markerid || popname == markerid) && chkAlertId) {
                const content = get_alert_data(
                  AlertIconDetails,
                  residentImgUrl,
                  staffImgUrl
                );

                if (content != "") {
                  const el = load_alertpopup_style(undefined, content, 1, 0);
                  const coords = arrpopupfeatures[popidx].values_["position"];
                  map.getOverlays().clear();

                  if (feature.get("floorid") != undefined)
                    popup = new Popup({
                      insertFirst: false,
                      name: "floorid-" + feature.get("floorid"),
                      devicename: "roomalertid-" + feature.get("DeviceAlertId"),
                    });
                  else
                    popup = new Popup({
                      insertFirst: false,
                      name: "buildingid-" + feature.get("buildingid"),
                    });

                  popup.autoPan = true;
                  popup.autoPanAnimation = { duration: 250 };
                  map.addOverlay(popup);
                  popup.show(coords, el);
                  popupclick_invoke(
                    popup,
                    map,
                    select,
                    map_sharedService,
                    map_length,
                    alertdatainfo,
                    Router
                  );
                } else {
                  map.getOverlays().clear();
                }
                const spnAlertId = document.getElementById("spn_AlertId");
                const notify_AlertId = 0;
                if (spnAlertId != null) spnAlertId.innerHTML;
                const getalertcloseData = AlertIconDetails.filter(function (e1) {
                  return (
                    e1.AlertId == notify_AlertId &&
                    (e1.AckStatus == enumAlertAckStatus.Complete ||
                      e1.AckStatus == enumAlertAckStatus.Cancel ||
                      e1.AckStatus == enumAlertAckStatus.Presence ||
                      e1.AckStatus == enumAlertAckStatus.Caregiver_Presence ||
                      e1.AckStatus ==
                        enumAlertAckStatus.Caregiver_Alert_Presence ||
                      e1.AckStatus == enumAlertAckStatus.Accepted)
                  );
                });

                if (
                  getalertcloseData.length > 0 &&
                  document.getElementById("imgCloseAlertNotification")
                ) {
                  const imgCloseAlertNotity = document.getElementById(
                    "imgCloseAlertNotification"
                  );
                  if (imgCloseAlertNotity != null) imgCloseAlertNotity.click();
                }
              }
            }
          }
        }
      }
    }
  });
}

function removePopup(map, feature) {
  try {
    feature.set("iconStyleCall", undefined);
    const popupfeatures = map.getOverlays();
    const arrpopupfeatures = popupfeatures.array_;
    if (arrpopupfeatures != undefined && arrpopupfeatures.length > 0) {
      for (let popidx = 0; popidx < arrpopupfeatures.length; popidx++) {
        const popid = arrpopupfeatures[popidx]["Id"];
        let popname = arrpopupfeatures[popidx]["options"]["name"];
        let markerid;
        if (feature.get("buildingid") != undefined)
          markerid = "buildingid-" + feature.get("buildingid");
        else if (feature.get("floorid") != undefined) {
          popname = arrpopupfeatures[popidx]["options"]["devicename"];
          markerid = "roomalertid-" + feature.get("DeviceAlertId");
        }

        if (
          markerid != undefined &&
          (popid == markerid || popname == markerid)
        ) {
          map.removeOverlay(arrpopupfeatures[popidx]);
        }
      }
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - removePopup() - " + err.message);
  }
}
function LoadTechnologyBuildingSvg(
  map,
  MCampusId,
  arrSvgInfo,
  imgheight,
  arrmarkerName,
  geojsonObject,
  fillcolorStyle,
  labelStyle,
  style,
  MBuildingid
) {
  try {
    for (let i = 0; i <= arrSvgInfo.length - 1; i++) {
      const buildingid = arrSvgInfo[i].buildingid;
      if (MBuildingid == buildingid || MBuildingid == 0) {
        const buildingname = arrSvgInfo[i].buildingname;

        let polyline = [] as any[];
        if (arrSvgInfo[i].polyline != undefined) {
          polyline = arrSvgInfo[i].polyline.split(" ");

          const arrfloors = arrSvgInfo[i].floors;
          let floorid = "",
            floorname = "";
          let arrpolypoint = [] as any[];

          for (let f = 0; f <= arrfloors.length - 1; f++) {
            if (floorid == "") {
              floorid =
                MCampusId +
                "_" +
                arrSvgInfo[i].buildingid +
                "_" +
                arrfloors[f].floorid;
              floorname = arrfloors[f].floorname;
            } else {
              floorid =
                floorid +
                "," +
                MCampusId +
                "_" +
                arrSvgInfo[i].buildingid +
                "_" +
                arrfloors[f].floorid;
              floorname = floorname + "," + arrfloors[f].floorname;
            }
          }

          /////////////////////////// Building poly line /////////////////////////////
          for (let npoly = 0; npoly <= polyline.length - 1; npoly++) {
            arrpolypoint[npoly] = polyline[npoly].split(",");
            if (isNaN(parseFloat(arrpolypoint[npoly][0]))) {
              arrpolypoint = [];
            } else {
              arrpolypoint[npoly][0] = parseFloat(arrpolypoint[npoly][0]);
            }
            if (isNaN(parseFloat(arrpolypoint[npoly][1]))) {
              arrpolypoint = [];
            } else {
              arrpolypoint[npoly][1] = getboxFromYpos(
                parseFloat(arrpolypoint[npoly][1]),
                imgheight
              );
            }
          }

          /// Add marker for Building ///
          const markerpoint = calculatexycoordinates(polyline, imgheight);
          const markername = new OlFeature({
            geometry: new OlPoint(markerpoint),
          });
          markername.setId("BuildingName-" + buildingid);
          markername.set("buildingid", buildingid);
          markername.set("buildingname", buildingname);
          markername.set("floorid", floorid);
          markername.set("floorname", floorname);
          markername.set("BuildingMarker", 1);
          arrmarkerName.push(markername);
          //////////////////////////////////////////////////////////////////////////
          let arrgeojsonObjectdata =
            geojsonObject.features[geojsonObject.features.length - 1];

          arrgeojsonObjectdata = {
            type: "Feature",
            id: "Building-" + buildingid,
            properties: {
              buildingid: buildingid,
              buildingname: buildingname,
              floorid: floorid,
              floorname: floorname,
            },
            geometry: {
              type: "Polygon",
              coordinates: [arrpolypoint],
            },
          };
          geojsonObject.features.push(arrgeojsonObjectdata);
        }
      }
    }

    let vectorLayer_geo = new OlVectorLayer();
    vectorLayer_geo = createVectorLayer(geojsonObject, 1, "Building");
    vectorLayer_geo.setStyle(fillcolorStyle);
    vectorLayer_geo.setProperties("declutter", true);
    map.addLayer(vectorLayer_geo);

    ////////////////////////Building Name Marker/////////////////
    const vectorLayerNameStyle = function (feature) {
      labelStyle.getText().setText(feature.get("buildingname"));
      return style;
    };
    let vectorLayerName = new OlVectorLayer();
    vectorLayerName = createVectorLayer(arrmarkerName, 0, "BuildingName");
    vectorLayerName.setStyle(vectorLayerNameStyle);
    map.addLayer(vectorLayerName);
    //////////////////////////////////////////////////////////
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - LoadTechnologyBuildingSvg() - " + err.message
    );
  }
}

function LoadTechnologyFloorSvg(
  map,
  arrFloorInfo,
  imgheight,
  imgwidth,
  arrdevicemarker,
  geoFloorjsonObject,
  arrdevicemarkernoPoly,
  devicelabelStyle,
  fillStyle,
  Map_image_path,
  FloorId,
  bNeedtoShowDevice
) {
  try {
    let IsDeviceEmpty = 0;
    let arr_arrpolypoint = [] as any[];
    let arr_arrIsDuplicate = [] as any[];
    let arr_arrunitid = [] as any[];
    const arr_arrpolypointData = [] as any[];
    for (let ninfo = 0; ninfo <= arrFloorInfo.length - 1; ninfo++) {
      if (arrFloorInfo[ninfo].polyline != undefined) {
        const indexval = arr_arrpolypoint.indexOf(arrFloorInfo[ninfo].polyline);
        let unitIdindexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
        if (indexval == -1 || unitIdindexval == -1) {
          const len = arr_arrpolypoint.length;
          arr_arrpolypointData[len] = {
            multiUnitId: "",
            multiDataId: "",
            multiDeviceId: "",
            multiFilterDeviceTypeId: "",
            multiFilterDeviceType: "",
          };
          arr_arrpolypointData[len].multiUnitId = arrFloorInfo[ninfo].UnitId;
          arr_arrpolypointData[len].multiDataId = arrFloorInfo[ninfo].DataId;
          arr_arrpolypointData[len].multiDeviceId =
            arrFloorInfo[ninfo].DeviceId;
          arr_arrpolypointData[len].multiFilterDeviceTypeId =
            arrFloorInfo[ninfo].FilterDeviceTypeId;
          arr_arrpolypointData[len].multiFilterDeviceType =
            arrFloorInfo[ninfo].FilterDeviceType;
          arr_arrpolypoint[len] = arrFloorInfo[ninfo].polyline;
          arr_arrIsDuplicate[len] = arrFloorInfo[ninfo].isDuplicate;
          arr_arrunitid[len] = arrFloorInfo[ninfo].UnitId;
        } else if (indexval != -1) {
          unitIdindexval = arr_arrunitid.indexOf(
            arrFloorInfo[ninfo].UnitId
          );
          arr_arrpolypointData[unitIdindexval].multiUnitId +=
            "," + arrFloorInfo[ninfo].UnitId;
          arr_arrpolypointData[unitIdindexval].multiDataId +=
            "," + arrFloorInfo[ninfo].DataId;
          arr_arrpolypointData[unitIdindexval].multiDeviceId +=
            "," + arrFloorInfo[ninfo].DeviceId;
          arr_arrpolypointData[unitIdindexval].multiFilterDeviceTypeId +=
            "," + arrFloorInfo[ninfo].FilterDeviceTypeId;
          arr_arrpolypointData[unitIdindexval].multiFilterDeviceType +=
            "," + arrFloorInfo[ninfo].FilterDeviceType;
        } else if (unitIdindexval != -1) {
            unitIdindexval = arr_arrunitid.indexOf(
            arrFloorInfo[ninfo].UnitId
          );
          arr_arrpolypointData[unitIdindexval].multiUnitId +=
            "," + arrFloorInfo[ninfo].UnitId;
          arr_arrpolypointData[unitIdindexval].multiDataId +=
            "," + arrFloorInfo[ninfo].DataId;
          arr_arrpolypointData[unitIdindexval].multiDeviceId +=
            "," + arrFloorInfo[ninfo].DeviceId;
          arr_arrpolypointData[unitIdindexval].multiFilterDeviceTypeId +=
            "," + arrFloorInfo[ninfo].FilterDeviceTypeId;
          arr_arrpolypointData[unitIdindexval].multiFilterDeviceType +=
            "," + arrFloorInfo[ninfo].FilterDeviceType;
        }
      }
    }
    arr_arrpolypoint = [];
    arr_arrIsDuplicate = [];
    arr_arrunitid = [];
    for (let ninfo = 0; ninfo <= arrFloorInfo.length - 1; ninfo++) {
      const geoFloorjsonObject = get_geojsonObject_floor(
        enumRequestFrom.floorplan_page
      );
      geoFloorjsonObject.type = "FeatureCollection";

      
      geoFloorjsonObject.features.length = 0;
      const UnitId = arrFloorInfo[ninfo].UnitId;
      const DataId = arrFloorInfo[ninfo].DataId;
      const DeviceId = arrFloorInfo[ninfo].DeviceId;
      const DeviceName = arrFloorInfo[ninfo].DeviceName;
      const RoomName = arrFloorInfo[ninfo].RoomName;
      const DeviceX = arrFloorInfo[ninfo].DeviceX;
      const DeviceY = arrFloorInfo[ninfo].DeviceY;
      const MacId = arrFloorInfo[ninfo].MacId;
      const unitName = arrFloorInfo[ninfo].UnitLocation;
      const RoomCategory = arrFloorInfo[ninfo].ResRoomCategory;

      const DeviceType = arrFloorInfo[ninfo].FilterDeviceTypeId;
      const DeviceTypeName = arrFloorInfo[ninfo].FilterDeviceType;
      const SubZoneId= arrFloorInfo[ninfo].SubZoneId;
      let markerpoint: any[];
      let _DeviceId = DeviceId;
      const _MacId = MacId;

      if (RoomCategory != "" && DeviceId > 0) {
        _DeviceId = RoomCategory;
      }

      /// Floor details ///
      let polyline = [] as any[];
      if (arrFloorInfo[ninfo].polyline != undefined && DeviceType != enumDeviceType.Zone) {
        polyline = arrFloorInfo[ninfo].polyline.split(" ");

        
        const arrpolypoint = [] as any[];
        /// Floor poly line ///
        for (let npoly = 0; npoly <= polyline.length - 1; npoly++) {
          arrpolypoint[npoly] = polyline[npoly].split(",");
          arrpolypoint[npoly][0] = parseFloat(arrpolypoint[npoly][0]);
          arrpolypoint[npoly][1] = getboxFromYpos(
            parseFloat(arrpolypoint[npoly][1]),
            imgheight
          );
        }
        
        if (DeviceX == undefined || DeviceY == undefined) {
          markerpoint = calculatexycoordinates(polyline, imgheight); // default inside posstion it col
        } else {
          if (DeviceX == "" || DeviceY == "") {
            markerpoint = calculatexycoordinates(polyline, imgheight); // default inside posstion it col
          } else {
            markerpoint = [
              convertToSvgEcllipseXCoordinate(parseFloat(DeviceX), false),
              convertToSvgEcllipseYCoordinate(parseFloat(DeviceY), imgheight),
            ];
          }
        }

        const marker = new OlFeature({
          // Added fromLonLat
          geometry: new OlPoint(markerpoint),
        });

        let imgName = "";
        let imgScale = 0.3;

        [imgName, imgScale] = getDevicetype_image(DeviceName);

        if (DeviceX == undefined || DeviceY == undefined) {
          //if undefine we have no image shown
          imgScale = 0.0;
        } else if (DeviceX == "" || DeviceY == "") {
          imgScale = 0.0;
        }
        let iconStyle;
        //marker Icon style
        if (DeviceName == DeviceTypeDesc.Gateway)
          iconStyle = map_marker_iconstylewithtext(
            marker,
            Map_image_path + imgName,
            imgScale,
            _MacId,
            0
          );
        else
          iconStyle = map_marker_iconstylewithtext(
            marker,
            Map_image_path + imgName,
            imgScale,
            _DeviceId,
            0
          );
        console.log(iconStyle);
        const indexval = arr_arrpolypoint.indexOf(arrFloorInfo[ninfo].polyline);
        let multi_indexval = -1;
        const unitIdindexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
        if (indexval == -1 || unitIdindexval == -1) {
          arr_arrIsDuplicate[arr_arrpolypoint.length] =
            arrFloorInfo[ninfo].isDuplicate;
          arr_arrunitid[arr_arrpolypoint.length] = arrFloorInfo[ninfo].UnitId;
          multi_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
          arr_arrpolypoint[arr_arrpolypoint.length] =
            arrFloorInfo[ninfo].polyline;
        } else {
          multi_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
        }

        marker.set("UnitId", UnitId);
        marker.set("DataId", DataId);
        marker.set("FloorId", FloorId);
        marker.set("DeviceId", DeviceId);
        marker.set("DeviceTypeName", DeviceTypeName);
        marker.set("DeviceType", DeviceType);
        marker.set("DeviceName", DeviceName);
        marker.set("UnitName", unitName);
        marker.set("DeviceTypeId", arrFloorInfo[ninfo].FilterDeviceTypeId);
        marker.set("MacId", MacId);
        marker.set("RoomCategory", RoomCategory);
        marker.set("SubZoneId",  SubZoneId);

        marker.set(
          "MultiDataId",
          arr_arrpolypointData[multi_indexval].multiDataId
        );
        marker.set(
          "MultiDeviceId",
          arr_arrpolypointData[multi_indexval].multiDeviceId
        );
        marker.set(
          "MultiFilterDeviceTypeId",
          arr_arrpolypointData[multi_indexval].multiFilterDeviceTypeId
        );
        marker.set(
          "MultiFilterDeviceType",
          arr_arrpolypointData[multi_indexval].multiFilterDeviceType
        );

        arrdevicemarker.push(marker);

        if (indexval == -1 || unitIdindexval == -1) {
          const unit_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
          const _multiDataId = arr_arrpolypointData[unit_indexval].multiDataId;
          const _multiDeviceId =
            arr_arrpolypointData[unit_indexval].multiDeviceId;
          const _multiFilterDeviceTypeId =
            arr_arrpolypointData[unit_indexval].multiFilterDeviceTypeId;
          const _multiFilterDeviceType =
            arr_arrpolypointData[unit_indexval].multiFilterDeviceType;
          const arr_multiDeviceId =
            _multiDeviceId != null ? _multiDeviceId.toString().split(",") : [];         
          const arr_multiFilterDeviceType =
            _multiFilterDeviceType != null
              ? _multiFilterDeviceType.toString().split(",")
              : [];

          const geoFloorjsonObject = new OlFeature({
            geometry: new Polygon([arrpolypoint]),
            FloorId: FloorId,
            UnitId: UnitId.toString(),
            RoomName: RoomName,
            DataId: DataId,
            MultiDataId: _multiDataId,
            MultiDeviceId: _multiDeviceId,
            MultiFilterDeviceTypeId: _multiFilterDeviceTypeId,
            MultiFilterDeviceType: _multiFilterDeviceType,
            UnitName: unitName,
            SubZoneId:SubZoneId
          });

            
            IsDeviceEmpty = 0;
            for (let nidx = 0; nidx <= arr_multiDeviceId.length; nidx++) {
              if (
                arr_multiDeviceId[nidx] == "0" &&
                arr_multiFilterDeviceType[nidx] != DeviceTypeDesc.Gateway && 
                arr_multiFilterDeviceType[nidx] != NewDeviceTypeDesc.Room &&
                arr_multiFilterDeviceType[nidx] != NewDeviceTypeDesc.Zone
                ) {
                IsDeviceEmpty = 1;
              }
            }
        

          if (arr_multiFilterDeviceType.length == 0) {
            IsDeviceEmpty = -1;
          }

          if (arr_multiFilterDeviceType.indexOf(NewDeviceTypeDesc.Room) != -1 && arr_multiFilterDeviceType.length == 1) {
            IsDeviceEmpty = -1;
          }
          let deviceStyle = fillcolor_style(fillcolorPolygon.Completed);
          if (IsDeviceEmpty == 1) {
            deviceStyle = fillcolor_style(fillcolorPolygon.Duplicate);
          }
          if (IsDeviceEmpty == -1) {
            deviceStyle = fillcolor_style(fillcolorPolygon.Incomplete);
          }

          const vectorSource_geo = new OlVectorSource({
            features: [geoFloorjsonObject],
            wrapX: false,
          });

          const vectorLayer_geo = new OlVectorLayer({
            source: vectorSource_geo,
            FloorId: "Floor_" + FloorId,
            name: "Room_" + UnitId,
            style: deviceStyle,
            declutter: true,
          });

          map.addLayer(vectorLayer_geo);
        }
      } // polygon
      else if (
        arrFloorInfo[ninfo].polyline == undefined &&
        DeviceX != undefined
      ) {
        if (DeviceX != "") {
          markerpoint = [
            convertToSvgEcllipseXCoordinate(parseFloat(DeviceX), false),
            convertToSvgEcllipseYCoordinate(parseFloat(DeviceY), imgheight),
          ];
          const markernopoly = new OlFeature({
            geometry: new OlPoint(markerpoint),
          });

          let imgName = "";
          let imgScale = 0.3;
          let iconStyle;

          [imgName, imgScale] = getDevicetype_image(DeviceName);

          if (DeviceName == DeviceTypeDesc.Gateway)
            iconStyle = map_marker_iconstylewithtext(
              markernopoly,
              Map_image_path + imgName,
              imgScale,
              _MacId,
              0
            );
          else
            iconStyle = map_marker_iconstylewithtext(
              markernopoly,
              Map_image_path + imgName,
              imgScale,
              _DeviceId,
              0
            );
          console.log(iconStyle);
          markernopoly.set("UnitId", UnitId);
          markernopoly.set("DataId", DataId);
          markernopoly.set("FloorId", FloorId);
          markernopoly.set("DeviceId", DeviceId);
          markernopoly.set("DeviceTypeName", DeviceTypeName);
          markernopoly.set("DeviceType", DeviceType);
          markernopoly.set("DeviceName", DeviceName);
          markernopoly.set(
            "DeviceTypeId",
            arrFloorInfo[ninfo].FilterDeviceTypeId
          );
          markernopoly.set("MacId", MacId);
          markernopoly.set("RoomCategory", RoomCategory);
          markernopoly.set("SubZoneId",  SubZoneId);
          markernopoly.set("MultiDataId", DataId);
          markernopoly.set("MultiDeviceId", DeviceId);
          markernopoly.set(
            "MultiFilterDeviceTypeId",
            arrFloorInfo[ninfo].FilterDeviceTypeId
          );
          markernopoly.set(
            "MultiFilterDeviceType",
            arrFloorInfo[ninfo].aFilterDeviceType
          );

          arrdevicemarkernoPoly.push(markernopoly);
        }
      } //deviceX */
    }
    
    if (bNeedtoShowDevice==0) //added the space and hide all the layers when load
    {
      const arr_layer = [] as any[];
      map.getLayers().forEach(function (Floorlayer) {
        if (Floorlayer.get('FloorId') != undefined && Floorlayer.get('FloorId') == "Floor_" + FloorId) {
          arr_layer[arr_layer.length] = Floorlayer;
        }
      });

      for (let indx = 0; indx < arr_layer.length; indx++) {
        arr_layer[indx].setVisible(false);
      }
    }

    if (bNeedtoShowDevice==1) //hide the devices only show the spaces
    {
      ///Floor Marker///
      let vectorLayer = new OlVectorLayer();
      vectorLayer = createVectorLayer(arrdevicemarker, 0, "RoomMarker", FloorId);
      map.addLayer(vectorLayer);

      ///Floor Marker with out polyline///
      let vectorLayernopoly = new OlVectorLayer();
      vectorLayernopoly = createVectorLayer(
        arrdevicemarkernoPoly,
        0,
        "RoomMarkerWithoutPoly",
        FloorId
      );
      map.addLayer(vectorLayernopoly);
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - LoadTechnologyFloorSvg() - " + err.message
    );
  }
}

function LoadTechnologySpaceSvg(
  map,
  arrTemplateInfo,
  imgheight,
  imgwidth,
  arrdevicemarker,
  geoFloorjsonObject,
  arrdevicemarkernoPoly,
  devicelabelStyle,
  fillStyle,
  Map_image_path,
  TemplateId
) {
  try {
    const arrSpaceAllInfo = [] as any[];
    let IsDeviceEmpty = 0;
    let arr_arrpolypoint = [] as any[];
    
    
    const arr_arrpolypointData = [] as any[];

    for (let tinfo = 0; tinfo <= arrTemplateInfo.length - 1; tinfo++) {
      const _Polyline = arrTemplateInfo[tinfo].polyline;

      const arrSpaceInfo = arrTemplateInfo[tinfo].devices;

      for (let ninfo = 0; ninfo <= arrSpaceInfo.length - 1; ninfo++) {
        arrSpaceInfo[ninfo].polyline = _Polyline;
        const indexval = arr_arrpolypoint.indexOf(arrSpaceInfo[ninfo].polyline);
        if (indexval == -1) {
          const len = arr_arrpolypoint.length;
          arr_arrpolypointData[len] = {
            multiDataId: "",
            multiDeviceId: "",
            multiFilterDeviceTypeId: "",
            multiFilterDeviceType: "",
          };
          arr_arrpolypointData[len].multiDataId = arrSpaceInfo[ninfo].DataId;
          arr_arrpolypointData[len].multiDeviceId =
            arrSpaceInfo[ninfo].DeviceId;
          arr_arrpolypointData[len].multiFilterDeviceTypeId =
            arrSpaceInfo[ninfo].DeviceTypeId;
          arr_arrpolypointData[len].multiFilterDeviceType =
            arrSpaceInfo[ninfo].DeviceType;
          arr_arrpolypoint[len] = arrSpaceInfo[ninfo].polyline;
        } else if (indexval != -1) {
          arr_arrpolypointData[indexval].multiDataId +=
            "," + arrSpaceInfo[ninfo].DataId;
          arr_arrpolypointData[indexval].multiDeviceId +=
            "," + arrSpaceInfo[ninfo].DeviceId;
          arr_arrpolypointData[indexval].multiFilterDeviceTypeId +=
            "," + arrSpaceInfo[ninfo].DeviceTypeId;
          arr_arrpolypointData[indexval].multiFilterDeviceType +=
            "," + arrSpaceInfo[ninfo].DeviceType;
        }
        arrSpaceAllInfo[arrSpaceAllInfo.length] = arrSpaceInfo[ninfo];
      }
    }
    arr_arrpolypoint = [];


    for (let ninfo = 0; ninfo <= arrSpaceAllInfo.length - 1; ninfo++) {
      const geoFloorjsonObject = get_geojsonObject_floor(
        enumRequestFrom.floorplan_page
      );
      geoFloorjsonObject.type = "FeatureCollection";      
      geoFloorjsonObject.features.length = 0;
      const DataId = arrSpaceAllInfo[ninfo].DataId;
      const DeviceId = arrSpaceAllInfo[ninfo].DeviceId;
      const DeviceName = arrSpaceAllInfo[ninfo].DeviceType;
      const RoomName = arrSpaceAllInfo[ninfo].RoomName;
      const DeviceX = arrSpaceAllInfo[ninfo].DeviceX;
      const DeviceY = arrSpaceAllInfo[ninfo].DeviceY;
      const MacId = arrSpaceAllInfo[ninfo].MacId;
      const DeviceType = arrSpaceAllInfo[ninfo].DeviceTypeId;
      const DeviceTypeName = arrSpaceAllInfo[ninfo].DeviceType;
      const _DeviceId = DeviceId;
      const _MacId = MacId;
      let markerpoint: any[];
      
      /// Floor details ///
      let polyline = [] as any[];
      if (arrSpaceAllInfo[ninfo].polyline != undefined) {
        polyline = arrSpaceAllInfo[ninfo].polyline.split(" ");

        
        const arrpolypoint = [] as any[];
        /// Floor poly line ///
        for (let npoly = 0; npoly <= polyline.length - 1; npoly++) {
          arrpolypoint[npoly] = polyline[npoly].split(",");
          arrpolypoint[npoly][0] = parseFloat(arrpolypoint[npoly][0]);
          arrpolypoint[npoly][1] = getboxFromYpos(
            parseFloat(arrpolypoint[npoly][1]),
            imgheight
          );
        }
        
        if (DeviceX == undefined || DeviceY == undefined) {
          markerpoint = calculatexycoordinates(polyline, imgheight); // default inside posstion it col
        } else {
          if (DeviceX == "" || DeviceY == "") {
            markerpoint = calculatexycoordinates(polyline, imgheight); // default inside posstion it col
          } else {
            markerpoint = [
              convertToSvgEcllipseXCoordinate(parseFloat(DeviceX), false),
              convertToSvgEcllipseYCoordinate(parseFloat(DeviceY), imgheight),
            ];
          }
        }

        const marker = new OlFeature({
          geometry: new OlPoint(markerpoint),
        });

        let imgName = "";
        let imgScale = 0.3;
        let iconStyle;
        [imgName, imgScale] = getDevicetype_image(DeviceName);

        if (DeviceX == undefined || DeviceY == undefined) {
          //if undefine we have no image shown
          imgScale = 0.0;
        } else if (DeviceX == "" || DeviceY == "") {
          imgScale = 0.0;
        }

        //marker Icon style
        if (DeviceName == DeviceTypeDesc.Gateway)
          iconStyle = map_marker_iconstylewithtext(
            marker,
            Map_image_path + imgName,
            imgScale,
            _MacId,
            1
          );
        else
          iconStyle = map_marker_iconstylewithtext(
            marker,
            Map_image_path + imgName,
            imgScale,
            _DeviceId,
            1
          );
        console.log(iconStyle);
        const indexval = arr_arrpolypoint.indexOf(
          arrSpaceAllInfo[ninfo].polyline
        );
        let multi_indexval = -1;
        if (indexval == -1) {
          arr_arrpolypoint[arr_arrpolypoint.length] =
            arrSpaceAllInfo[ninfo].polyline;
          multi_indexval = arr_arrpolypoint.indexOf(
            arrSpaceAllInfo[ninfo].polyline
          );
        } else {
          multi_indexval = indexval;
        }

        marker.set("DataId", DataId);
        marker.set("TemplateId", TemplateId);
        marker.set("DeviceId", DeviceId);
        marker.set("DeviceTypeName", DeviceTypeName);
        marker.set("DeviceType", DeviceType);
        marker.set("DeviceName", DeviceName);
        marker.set("DeviceTypeId", arrSpaceAllInfo[ninfo].DeviceTypeId);
        marker.set("MacId", MacId);

        marker.set(
          "MultiDataId",
          arr_arrpolypointData[multi_indexval].multiDataId
        );
        marker.set(
          "MultiDeviceId",
          arr_arrpolypointData[multi_indexval].multiDeviceId
        );
        marker.set(
          "MultiDeviceTypeId",
          arr_arrpolypointData[multi_indexval].multiFilterDeviceTypeId
        );
        marker.set(
          "MultiDeviceType",
          arr_arrpolypointData[multi_indexval].multiFilterDeviceType
        );

        arrdevicemarker.push(marker);

        if (indexval == -1) {
          const _multiDataId = arr_arrpolypointData[multi_indexval].multiDataId;
          const _multiDeviceId =
            arr_arrpolypointData[multi_indexval].multiDeviceId;
          const _multiDeviceTypeId =
            arr_arrpolypointData[multi_indexval].multiFilterDeviceTypeId;
          const _multiDeviceType =
            arr_arrpolypointData[multi_indexval].multiFilterDeviceType;                    
          const arr_multiDeviceId =
            _multiDeviceId != null ? _multiDeviceId.toString().split(",") : [];          
          const arr_multiDeviceType =
            _multiDeviceType != null
              ? _multiDeviceType.toString().split(",")
              : [];

          const geoFloorjsonObject = new OlFeature({
            geometry: new Polygon([arrpolypoint]),
            TemplateId: TemplateId,
            RoomName: RoomName,
            DataId: DataId,
            MultiDataId: _multiDataId,
            MultiDeviceId: _multiDeviceId,
            multiDeviceTypeId: _multiDeviceTypeId,
            multiDeviceType: _multiDeviceType,
          });

          IsDeviceEmpty = 0;

       
            for (let nidx = 0; nidx <= arr_multiDeviceId.length; nidx++) {
              if (
                arr_multiDeviceId[nidx] == "0" &&
                arr_multiDeviceType[nidx] != DeviceTypeDesc.Gateway && 
                arr_multiDeviceType[nidx] != NewDeviceTypeDesc.Room 
                ) {
                IsDeviceEmpty = 1;
              }
            }
       

          if (arr_multiDeviceType.length == 0) {
            IsDeviceEmpty = -1;
          }
          if (arr_multiDeviceType.length > 0) {
            if(arr_multiDeviceId[0] == "null" && arr_multiDeviceId.length == 2)
            {              
              IsDeviceEmpty = -1;
            }
          }

          if (arr_multiDeviceType.indexOf(NewDeviceTypeDesc.Room) != -1 && arr_multiDeviceType.length == 1) {
            IsDeviceEmpty = -1;
          }
          let deviceStyle = fillcolor_style(fillcolorPolygon.Completed);
          if (IsDeviceEmpty == 1) {
            deviceStyle = fillcolor_style(fillcolorPolygon.Duplicate);
          }
          if (IsDeviceEmpty == -1) {
            deviceStyle = fillcolor_style(fillcolorPolygon.Incomplete);
          }

          const vectorSource_geo = new OlVectorSource({
            features: [geoFloorjsonObject],
            wrapX: false,
          });

          const vectorLayer_geo = new OlVectorLayer({
            source: vectorSource_geo,
            TemplateId: "Template_" + TemplateId,
            style: deviceStyle,
            declutter: true,
          });

          map.addLayer(vectorLayer_geo);
        }
      } else if (
        arrSpaceAllInfo[ninfo].polyline == undefined &&
        DeviceX != undefined
      ) {
        if (DeviceX != "") {
          markerpoint = [
            convertToSvgEcllipseXCoordinate(parseFloat(DeviceX), false),
            convertToSvgEcllipseYCoordinate(parseFloat(DeviceY), imgheight),
          ];
          const markernopoly = new OlFeature({
            geometry: new OlPoint(markerpoint),
          });

          let imgName = "";
          let imgScale = 0.3;
          let iconStyle;
          [imgName, imgScale] = getDevicetype_image(DeviceName);

          if (DeviceName == DeviceTypeDesc.Gateway)
             iconStyle = map_marker_iconstylewithtext(
              markernopoly,
              Map_image_path + imgName,
              imgScale,
              _MacId,
              1
            );
          else
             iconStyle = map_marker_iconstylewithtext(
              markernopoly,
              Map_image_path + imgName,
              imgScale,
              _DeviceId,
              1
            );
          console.log(iconStyle);
          markernopoly.set("DataId", DataId);
          markernopoly.set("TemplateId", TemplateId);
          markernopoly.set("DeviceId", DeviceId);
          markernopoly.set("DeviceTypeName", DeviceTypeName);
          markernopoly.set("DeviceType", DeviceType);
          markernopoly.set("DeviceName", DeviceName);
          markernopoly.set("DeviceTypeId", arrSpaceAllInfo[ninfo].DeviceTypeId);
          markernopoly.set("MacId", MacId);

          markernopoly.set("MultiDataId", DataId);
          markernopoly.set("MultiDeviceId", DeviceId);
          markernopoly.set(
            "MultiDeviceTypeId",
            arrSpaceAllInfo[ninfo].DeviceTypeId
          );
          markernopoly.set(
            "MultiDeviceType",
            arrSpaceAllInfo[ninfo].DeviceType
          );

          arrdevicemarkernoPoly.push(markernopoly);
        }
      } //deviceX */
    }

    ///Floor Marker///
    let vectorLayer = new OlVectorLayer();
    vectorLayer = createVectorLayer(
      arrdevicemarker,
      0,
      "RoomMarker",
      TemplateId
    );
    map.addLayer(vectorLayer);

    ///Floor Marker with out polyline///
    let vectorLayernopoly = new OlVectorLayer();
    vectorLayernopoly = createVectorLayer(
      arrdevicemarkernoPoly,
      0,
      "RoomMarkerWithoutPoly",
      TemplateId
    );
    map.addLayer(vectorLayernopoly);
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - LoadTechnologySpaceSvg() - " + err.message
    );
  }
}



function calculateCenter(geometry) {
  let center, coordinates, minRadius;
  const type = geometry.getType();
  if (type === "Polygon") {
    let x = 0;
    let y = 0;
    let i = 0;
    coordinates = geometry.getCoordinates()[0];
    coordinates.forEach(function (coordinate) {
      x += coordinate[0];
      y += coordinate[1];
      i++;
    });
    center = [x / i, y / i];
  } else if (type === "LineString") {
    center = geometry.getCoordinateAt(0.5);
    coordinates = geometry.getCoordinates();
  } else {
    center = getCenter(geometry.getExtent());
  }
  let sqDistances;
  if (coordinates) {
    sqDistances = coordinates.map(function (coordinate) {
      const dx = coordinate[0] - center[0];
      const dy = coordinate[1] - center[1];
      return dx * dx + dy * dy;
    });
    minRadius = Math.sqrt(Math.max.prototype.apply(Math, sqDistances)) / 3;
  } else {
    minRadius =
      Math.max(
        getWidth(geometry.getExtent()),
        getHeight(geometry.getExtent())
      ) / 3;
  }
  return {
    center: center,
    coordinates: coordinates,
    minRadius: minRadius,
    sqDistances: sqDistances,
  };
}
function redirecttohome(router) {
  try {
    if (router.url == "/main") {
      router
        .navigateByUrl("/", { skipLocationChange: true })
        .then(() => router.navigate(["/main"]));
    } else {
      router.navigate(["/main"]);
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - redirecttohome() - " + err.message);
  }
}

function groupBy(details, keyinfo) {
  let checkedgroupBy;
  try {
    const chkgroupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    checkedgroupBy = chkgroupBy(details, keyinfo);
  } catch (err) {
    throw new Error("app-general.ts" + " - groupBy() - " + err.message);
  }
  return checkedgroupBy;
}

function geticonStyle(map_sharedService, alertimgdata) {
  let infraopen;
  let iconStyleCall1;
  try {
    infraopen = AppComponent.ShareInfraopenDetails;

    if (infraopen.IsfraOpen == 0) {
      if (alertimgdata != undefined) {
        let getfilteredData;
        getfilteredData = alertimgdata.filter(function (e1) {
          return (
            e1.SubTypeId == 0 || e1.SubTypeId == enumAlertSubType.Fire_Alarm || e1.AlertType == enumAlertType.Pendant
          );
        });
        if (getfilteredData == undefined || getfilteredData.length == 0) {
          getfilteredData = alertimgdata.filter(function (e1) {
            return (
              e1.AlertType == enumAlertType.DC ||
              e1.AlertType == enumAlertType.Pull_Cord
            );
          });
        }

        if (getfilteredData == undefined || getfilteredData.length == 0) {
          getfilteredData = alertimgdata.filter(function (e1) {
            return (
              e1.AlertType == enumAlertType.UT ||
              e1.SubTypeId == enumAlertSubType.Nurse_Call_Alarm
            );
          });
        }
        if (getfilteredData == undefined || getfilteredData.length == 0) {
          getfilteredData = alertimgdata.filter(function (e1) {
            return e1.DeviceTypeId == alert_deviceType.Battery;
          });
        }
        if (getfilteredData != undefined && getfilteredData.length > 0)
          iconStyleCall1 = Getalerticon(getfilteredData);
      }
    } else if (infraopen.IsfraOpen == 1) {
      const getfilteredData = alertimgdata;
      if (getfilteredData != undefined && getfilteredData.length > 0)
        iconStyleCall1 = Getalerticon(getfilteredData);
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - geticonStyle() - " + err.message);
  }
  return [iconStyleCall1, infraopen];
}

function set_SharedDetails(
  CampusId,
  BuildingId,
  FloorId,
  SubZoneId,
  AlertId,
  AlertStatus,
  ResidentId,
  sharedService
) {
  let SharedDetails;
  try {
    SharedDetails = {
      SharedCampusId: CampusId,
      SharedBuildingId: BuildingId,
      SharedFloorId: FloorId,
      SharedSubZoneId : SubZoneId,
      SharedAlertId: AlertId,
      SharedAlertStatus: AlertStatus,
      SharedResidentId: ResidentId,
    };
    sharedService.changeShared(SharedDetails);
    SharedDetails = AppComponent.SharedDetails;
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - set_SharedDetails() - " + err.message
    );
  }
  return SharedDetails;
}

function set_SharedFloorDetails(AlertDetails, AlertStatus, sharedService) {
  let SharedFloorAlertDetails;
  try {
    SharedFloorAlertDetails = {
      SharedFloorCampusId: AlertDetails.CampusId,
      SharedFloorBuildingId: AlertDetails.BuildId,
      SharedFloorFloorId: AlertDetails.FloorId, //this is campus view so no floor info
      SharedFloorSubZoneId: AlertDetails.SubZoneId,
      SharedFloorAlertId: AlertDetails.AlertId,
      SharedFloorRoomId: AlertDetails.RoomId,
      SharedFloorAlertStatus: AlertStatus,
    };
    sharedService.changesharedFloorAlert(SharedFloorAlertDetails);
    SharedFloorAlertDetails = AppComponent.SharedFloorAlertDetails;
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - set_SharedFloorDetails() - " + err.message
    );
  }
  return SharedFloorAlertDetails;
}

function set_alertChangeorClick(AlertChanged, AlertClicked, sharedService) {
  let CheckAlertData;
  try {
    CheckAlertData = {
      IsAlertChanged: AlertChanged,
      IsAlertClicked: AlertClicked,
    };
    sharedService.changeSharedCheckAlert(CheckAlertData);
    CheckAlertData = AppComponent.SharedCheckAlertDetails;
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - set_alertChangeorClick() - " + err.message
    );
  }
  return CheckAlertData;
}

function set_AlertReportChange(AlertReportChanged, sharedService) {
  let CheckAlertData;
  try {
    CheckAlertData = {
      IsAlertReportChanged: AlertReportChanged,
    };
    sharedService.changeSharedCheckAlertReport(CheckAlertData);
    CheckAlertData = AppComponent.SharedCheckAlertReportDetails;
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - set_AlertReportChange() - " + err.message
    );
  }
  return CheckAlertData;
}

function set_IsAlertReportChange(AlertReportChanged, sharedService) {
  let CheckAlertData;
  try {
    CheckAlertData = {
      IsAlertReportChanged: AlertReportChanged,
    };
    sharedService.changeSharedCheckIsAlertReport(CheckAlertData);
    CheckAlertData = AppComponent.SharedCheckIsAlertReportDetails;
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - set_IsAlertReportChange() - " + err.message
    );
  }
  return CheckAlertData;
}

function set_IsLocationReportChange(LocationReportChanged, sharedService) {
  let CheckLocationData;
  try {
    CheckLocationData = {
      IsLocationReportChanged: LocationReportChanged,
    };
    sharedService.changeSharedCheckIsLocationReport(CheckLocationData);
    CheckLocationData = AppComponent.SharedCheckIsLocationReportDetails;
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - set_IsLocationReportChange() - " + err.message
    );
  }
  return CheckLocationData;
}

function SessionCheck(router) {
  const userid = localStorage.getItem("userid");
  if (userid == null) {
    localStorage.clear();
    router.navigate(["/login"]);
    return false;
  }
  return true;
}

function SessionCheckwithStaff(router) {
  let staffdata;
  
  const userid = localStorage.getItem("userid") ?? "";
  const authkey = localStorage.getItem("Authkey") ?? "";
  if (staffSharedservice != undefined) {
    const staffitem: StaffByIDparam = {
      authKey: authkey,
      staffId: parseInt(userid),
    };
    staffSharedservice.fetchStaffData(staffitem);
    if (AppComponent.SharedStaffDetails.length > 0) {
      staffdata = AppComponent.SharedStaffDetails;
    }
  }
  if (
    userid == null || 
    (staffdata != undefined && staffdata[0].Relieveddate != undefined)
    ) {
    localStorage.clear();
    router.navigate(["/login"]);
    return false;
  }
  return true;
}

function checkStaffData(staffSharedData, stafflaertsharedData) {
  staffSharedservice = staffSharedData;
  console.log(stafflaertsharedData);
}
function getSearchResidentAlertId(
  ResidentId,
  alertdatainfo,
  SharedFloorAlertId,
  IsFloor
) {
  let AlertIds = "";
  try {
    let arr_ResidentId;
    if (ResidentId != undefined) {
      arr_ResidentId = ResidentId.toString().split(",");
    }

    alertdatainfo.filter(function (e1) {
      if (arr_ResidentId != undefined) {
        if (arr_ResidentId.includes(e1.ResidentId.toString())) {
          if (
            e1.Alertresstaff == 1 &&
            (e1.AlertType == enumAlertType.Pendant ||
              e1.AlertType == enumAlertType.Wander ||
              e1.AlertType == enumAlertType.Pull_Cord)
          ) {
            if (AlertIds == "") AlertIds = e1.AlertId;
            else AlertIds += "," + e1.AlertId;
          }
        }
      } else if (IsFloor == 1) {
        if (e1.AlertId == SharedFloorAlertId) AlertIds = e1.AlertId;
      }
    });
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - getSearchResidentAlertId() - " + err.message
    );
  }
  return AlertIds;
}
function createVectorLayer(
  sourcejsonObject,
  bisJSON,
  vectorLayerName,
  FloorId?
) {
  let vectorLayer = new OlVectorLayer();
  try {
    const vectorSource = get_VectorSource(sourcejsonObject, bisJSON);
    if (FloorId) {
      vectorLayer = new OlVectorLayer({
        source: vectorSource,
        name: vectorLayerName,
        FloorId: "Floor_" + FloorId,
      });
    } else {
      vectorLayer = new OlVectorLayer({
        source: vectorSource,
        name: vectorLayerName,
      });
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - createVectorLayer() - " + err.message
    );
  }
  return vectorLayer;
}

function addSearchMarker(
  coord,
  map,
  select,
  buildingid,
  AlertId,
  searchalerticonStyle,
  IsFloor
) {
  try {
    const marker = new OlFeature({
      geometry: new OlPoint(coord),
      id: "searchalertId-" + AlertId,
    });
    marker.id_ = "SearchAlertId-" + AlertId;
    let searchvectorLayer = new OlVectorLayer();
    searchvectorLayer = createVectorLayer([marker], 0, "SearchIconBuilding");
    searchvectorLayer.setStyle([searchalerticonStyle]);
    searchvectorLayer.setZIndex(2);
    map.addLayer(searchvectorLayer);

    const searchsource = searchvectorLayer.getSource();
    const searchfeatures = searchsource.getFeatures();
    searchfeatures[0].set("SearchAlertId", AlertId);

    searchfeatures[0].set("SearchType", "Building");
    const searchgeometry = searchfeatures[0].getGeometry();
    const searchcoord = searchgeometry.getCoordinates();
    searchcoord[1] = searchcoord[1] + 30;
    coord[0] = searchcoord[0];
    coord[1] = searchcoord[1];

    if (IsFloor == 1) {
      searchfeatures[0].set("SearchType", "Floor");
    } else {
      searchfeatures[0].set("SearchType", "Building");
      searchfeatures[0].set("buildingid", buildingid);
    }

    searchfeatures[0]
      .getGeometry()
      .setCoordinates([searchcoord[0], searchcoord[1]]);
    select.getFeatures().push(searchfeatures[0]);
  } catch (err) {
    throw new Error("app-general.ts" + " - addSearchMarker() - " + err.message);
  }
  return select;
}
function getmarkerpointforFloor(FloorInfo, mapHeight) {
  let markerpoint;
  try {
    if (FloorInfo.polyline != undefined) {
      const polyline = FloorInfo.polyline.split(" ");
      markerpoint = calculatexycoordinates(polyline, mapHeight);
    } else if (FloorInfo.DeviceX != undefined) {
      if (FloorInfo.DeviceX != "") {
        const pointx = FloorInfo.DeviceX;
        const pointy = FloorInfo.DeviceY;
        markerpoint = [
          convertToSvgEcllipseXCoordinate(parseFloat(pointx), false),
          convertToSvgEcllipseYCoordinate(parseFloat(pointy), mapHeight),
        ];
      }
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - getmarkerpointforFloor() - " + err.message
    );
  }
  return markerpoint;
}

function addAlertMarkerforFloor(
  RoomId,
  RoomName,
  DeviceId,
  FloorId,
  markerpoint,
  DeviceAlertId,
  alertimgdata,
  map_sharedService,
  DeviceTypeId,
  SubZoneId
) {
  let marker = new OlFeature();
  try {
    const arriconStyleCall = geticonStyle(map_sharedService, alertimgdata);
    marker = new OlFeature({
      geometry: new OlPoint(markerpoint),
      id: "roomid-" + RoomId,
    });
    marker.id_ = "roomid-" + RoomId + "_" + DeviceTypeId;
    marker.set("DeviceAlertId", DeviceAlertId);
    marker.set("DeviceRoomId", RoomId);
    marker.set("DeviceRoomName", RoomName);
    marker.set("DeviceId", DeviceId);
    marker.set("DeviceTypeId", DeviceTypeId);
    marker.set("AlertData", alertimgdata);
    marker.set("floorid", FloorId);
    marker.set("SubZoneId", SubZoneId);
    marker.set("iconStyleCall", arriconStyleCall[0]);
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - addAlertMarkerforFloor() - " + err.message
    );
  }
  return marker;
}

function addAlertMarkerforBuilding(
  markerpoint,
  buildingid,
  map_sharedService,
  alertimgdata,
  AlertBuildingcnt
) {
  let marker = new OlFeature();
  try {
    const arriconStyleCall = geticonStyle(map_sharedService, alertimgdata);
    marker = new OlFeature({
      geometry: new OlPoint(markerpoint),
      id: "buildingid-" + buildingid,
    });
    marker.id_ = "buildingid-" + buildingid;
    if (AlertBuildingcnt > 0) {
      marker.set("AlertBuildingcnt", AlertBuildingcnt);
      marker.set("buildingid", buildingid);
      marker.set("iconStyleCall", arriconStyleCall[0]);
      marker.set("Isinfra", arriconStyleCall[1].IsfraOpen);
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - addAlertMarkerforBuilding() - " + err.message
    );
  }
  return marker;
}

function PopopShow(
  map,
  popup,
  select,
  coord,
  map_sharedService,
  map_length,
  mapHeight,
  alertdatainfo,
  Router,
  AlertDetails,
  residentImgUrl,
  staffImgUrl,
  AlertStatus,
  DataId,
  IsFloor,
  map_SharedDetails,
  DeviceAlertId,
  translate
) {
  try {
    let content = "";
    let contentinfo = "";
    const SelectedAlertDetails = AlertDetails;

    /////////////show alert Dialog//////////////
    if (SelectedAlertDetails != undefined) {
      if (SelectedAlertDetails.length > 0) {
        if (AlertStatus == alert_raisedon.leftside_alertselect) {
          //when click on left side alert list to show the alert information
          contentinfo = get_alert_data(
            SelectedAlertDetails,
            residentImgUrl,
            staffImgUrl
          );
        } else {
          // Resident search click on top to show the popup
          if (IsFloor == 1) {
            contentinfo = get_alert_data_floor_search(
              SelectedAlertDetails,
              residentImgUrl,
              staffImgUrl,
              coord[0],
              coord[1],
              map_SharedDetails
            );
          } else {
            contentinfo = get_alert_data_search(
              SelectedAlertDetails,
              residentImgUrl,
              staffImgUrl,
              coord[0],
              coord[1]
            );
          }
          popup["type"] = "SearchPopup";
        }
        content += contentinfo;
      }
      if (content != "") {
        const floornameinfo = undefined;
        const el = load_alertpopup_style(floornameinfo, content, 1, 0); // height no need for location information for alert popup
        if (coord.length == 2) {
          if (translate.length == 2) {
            popup = popup_overlay(popup, map, translate, el, 1);
          } else {
            popup = popup_overlay(popup, map, coord, el, 1);
          }
          const popupfeatures = map.getOverlays();
          const arrpopupfeatures = popupfeatures.array_;
          if (IsFloor == 1) {
            arrpopupfeatures[0]["Id"] = "floorid-" + DataId;
            arrpopupfeatures[0]["options"]["devicename"] =
              "roomalertid-" + DeviceAlertId;
            arrpopupfeatures[0]["options"]["name"] = "floorid-" + DataId;
          } else {
            arrpopupfeatures[0]["Id"] = "buildingid-" + DataId;
          }
        } else if (IsFloor == 0) {
          const arrcord = [] as any[];
          for (let ncoord = 0; ncoord <= coord[0].length - 1; ncoord++) {
            arrcord[ncoord] = coord[0][ncoord][0] + "," + coord[0][ncoord][1];
          }
          popup.show(calculatexycoordinates(arrcord, mapHeight), el);
        }
        popupclick_invoke(
          popup,
          map,
          select,
          map_sharedService,
          map_length,
          alertdatainfo,
          Router
        );
      }
    }
  } catch (err) {
    throw new Error("app-general.ts" + " - PopopShow() - " + err.message);
  }
}

function getCheckInExtent(e, map, imgwidth, imgheight, MapCenter) {
  if (e.target.focus_ != null && e.target.focus_ != undefined) {
    let ext = [0, 0, imgwidth, imgheight];
    let CheckInExtent = false;
    if (map.getLayers().array_.length > 0) {
      ext = map.getLayers().array_[0].getSource().imageExtent_;
    }
    const popupfeatures = map.getOverlays();
    const arrpopupfeatures = popupfeatures.array_;
    if (arrpopupfeatures != undefined && arrpopupfeatures.length > 0) {
      const chk_ext = [ext[0] - 72, ext[1] - 72, ext[2] + 300, ext[3] + 250];
      CheckInExtent = containsCoordinate(chk_ext, e.target.focus_);
    } else {
      CheckInExtent = containsCoordinate(ext, e.target.focus_);
    }
    if (CheckInExtent == false) map.getView().setCenter(MapCenter);
  }
}

function generateAlertMessage(
  dialog,
  AlertMessage,
  _router?,
  _getbuildingdata?
) {
  try {
    const AlertMessageData = {
      AlertMessage: AlertMessage,
    };
    const br_count = AlertMessage.split("<br>");
    let _height;
    if (br_count.length > 1) {
      _height = (br_count.length - 1) * 18;
      _height = parseInt(190 + _height) + "px";
    } else {
      _height = "auto";
    }
    checkdialogopen = 1;
    alertdialog = dialog.open(AlertMessageComponent, {
      disableClose: true,
      width: "auto",
      height: _height,
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: AlertMessageData,
    });

    alertdialog.afterClosed().subscribe((result) => {
      if (result == "Ok") {
        checkdialogopen = 0;
      }

      if (
        result == "Ok" &&
        window.location.href.split("/")[4] == "mapbuilding" &&        
          AlertMessage == "Building map drawn deleted successfully."
      ) {
        _router.navigate(["../../floorplans"], { state: _getbuildingdata });
      }
    });
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - generateAlertMessage() - " + err.message
    );
  }
}

function getFeatureNeedtoShow(FeatureMasterId, FeatureActionId) {
  let NeedtoShow = enumUIView.Hide;
  try {
    let StaffUserRoleView = AppComponent.StaffUserRoleView;

    if (StaffUserRoleView.length == 0) {
      const staffUser_Role = localStorage.getItem("StaffUserRoleView") ?? "";
      if (staffUser_Role.length>0)
        StaffUserRoleView = JSON.parse(staffUser_Role);
    }
    if (
      StaffUserRoleView.length > 0 &&
      StaffUserRoleView.length >= FeatureActionId
    ) {
      if (
        StaffUserRoleView[FeatureActionId - 1]["FeatureMasterId"] ==
          FeatureMasterId &&
        StaffUserRoleView[FeatureActionId - 1]["FeatureActionId"] ==
          FeatureActionId &&
        StaffUserRoleView[FeatureActionId - 1]["NeedtoShow"] == enumUIView.Show
      ) {
        NeedtoShow = enumUIView.Show;
      }
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - getFeatureNeedtoShow() - " + err.message
    );
  }
  return NeedtoShow;
}

function getAddEditDeleteColumn(displayedColumns, bAddEdit, bDelete) {
  try {
    if (bAddEdit == true && bDelete == false) {
      displayedColumns.splice(displayedColumns.length - 1, 1);
    } else if (bAddEdit == false && bDelete == true) {
      displayedColumns.splice(displayedColumns.length - 2, 1);
    } else if (bAddEdit == false && bDelete == false) {
      displayedColumns.splice(displayedColumns.length - 1, 1);
      displayedColumns.splice(displayedColumns.length - 1, 1);
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - getAddEditDeleteColumn() - " + err.message
    );
  }
  return displayedColumns;
}

function getAddEditColumn(displayedColumns, bAddEdit) {
  try {
    if (bAddEdit == false) {
      displayedColumns.splice(displayedColumns.length - 1, 1);
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - getAddEditDeleteColumn() - " + err.message
    );
  }
  return displayedColumns;
}

function CheckAlertByDeviceTypeId(LocationType, DeviceType, DeviceDeviceType) {
  const ChkDeviceType = false;
  try {
    if (LocationType == enumLocationType.LocationTypeZone) {
      if (DeviceDeviceType == enumDeviceType.Zone) {
        return true;
      }
    } else if (
      DeviceType == alert_deviceType.Tag ||
      DeviceType == alert_deviceType.Emitter ||
      DeviceType == alert_deviceType.Battery
    ) {
      if (
        DeviceDeviceType == enumDeviceType.TruLocator ||
        DeviceDeviceType == enumDeviceType.DC ||
        DeviceDeviceType == enumDeviceType.LF ||
        DeviceDeviceType == enumDeviceType.Monitor ||
        DeviceDeviceType == enumDeviceType.VW ||
        DeviceDeviceType == enumDeviceType.UM ||
        DeviceDeviceType == enumDeviceType.DripTray
      ) {
        return true;
      }
    } else if (DeviceType == alert_deviceType.REX) {
      if (DeviceDeviceType == enumDeviceType.REX) {
        return true;
      }
    } else if (DeviceType == alert_deviceType.HREX) {
      if (DeviceDeviceType == enumDeviceType.HREX) {
        return true;
      }
    } else if (DeviceType == alert_deviceType.DC) {
      if (DeviceDeviceType == enumDeviceType.DC) {
        return true;
      }
    } else if (LocationType != enumLocationType.LocationTypeZone) {
      if (DeviceDeviceType != enumDeviceType.Zone) {
        return true;
      }
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - CheckAlertByDeviceTypeId() - " + err.message
    );
  }
  return ChkDeviceType;
}

function AuthFailedMessage(dialog, Router) {
  try {
    const AlertMessage = "Authentication Failed";
    const AlertMessageData = {
      AlertMessage: AlertMessage,
    };
    const br_count = AlertMessage.split("<br>");
    let _height;
    if (br_count.length > 1) {
      _height = (br_count.length - 1) * 18;
      _height = parseInt(180 + _height) + "px";
    } else {
      _height = "auto";
    }
    const dialogRef = dialog.open(AlertMessageComponent, {
      disableClose: true,
      width: "auto",
      height: _height,
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: AlertMessageData,
    });
    if (checkdialogopen == 1) {
      alertdialog.close();
    }
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      localStorage.clear();
      Router.navigate(["/login"]);
      return false;
    });
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - AuthFailedMessage() - " + err.message
    );
  }
}

function ClearFormControlValue(FromControlName, DeviceType) {
  try {
    FromControlName.get("lfid").clearValidators();
    FromControlName.get("lfid").updateValueAndValidity();

    FromControlName.get("deviceid").clearValidators();
    FromControlName.get("deviceid").updateValueAndValidity();

    FromControlName.get("emitterid").clearValidators();
    FromControlName.get("emitterid").updateValueAndValidity();

    FromControlName.get("Location").clearValidators();
    FromControlName.get("Location").updateValueAndValidity();

    FromControlName.get("devicedualid").clearValidators();
    FromControlName.get("devicedualid").updateValueAndValidity();

    FromControlName.get("macid").clearValidators();
    FromControlName.get("macid").updateValueAndValidity();

    SetFormControlValue(FromControlName, DeviceType);
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - ClearFormControlValue() - " + err.message
    );
  }
}

function SetFormControlValue(FromControlName, deviceType) {
  try {
    if (deviceType == DeviceType.Emitter) {
      FromControlName.get("deviceid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("deviceid").updateValueAndValidity();

      FromControlName.get("emitterid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("emitterid").updateValueAndValidity();
    } else if (deviceType == DeviceType.Rex) {
      FromControlName.get("deviceid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("deviceid").updateValueAndValidity();

      FromControlName.get("macid").setValidators([
        Validators.required,
        Validators.pattern(
         appsettings.Mac_Pattern
        ),
      ]);
      FromControlName.get("macid").updateValueAndValidity();
    } else if (deviceType == DeviceType.LF) {
      FromControlName.get("deviceid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("deviceid").updateValueAndValidity();

      FromControlName.get("lfid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("lfid").updateValueAndValidity();
    } else if (deviceType == DeviceType.Pullcord) {
      FromControlName.get("deviceid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("deviceid").updateValueAndValidity();
    } else if (deviceType == DeviceType.DC) {
      FromControlName.get("deviceid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("deviceid").updateValueAndValidity();

      FromControlName.get("lfid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("lfid").updateValueAndValidity();

      FromControlName.get("devicedualid").setValidators([
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("devicedualid").updateValueAndValidity();
    } else if (deviceType == DeviceType.Monitor) {
      FromControlName.get("deviceid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("deviceid").updateValueAndValidity();

      FromControlName.get("emitterid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("emitterid").updateValueAndValidity();
    } else if (deviceType == DeviceType.VW) {
      FromControlName.get("deviceid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("deviceid").updateValueAndValidity();

      FromControlName.get("emitterid").setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      FromControlName.get("emitterid").updateValueAndValidity();
    }
  } catch (err) {
    throw new Error(
      "app-general.ts" + " - SetFormControlValue() - " + err.message
    );
  }
}

function maponclicked_Tech(
  map,
  select,
  addfloorid,
  technologyoverlayService,
  _ondestroy,
  edit_DeviceDetails,
  dialog,
  _MapviewFloorComponent,
  editunitopendialog
) 
{  
  // let _addfloorid = addfloorid;    
  let _select;
  const _technologyoverlayService = technologyoverlayService;
  const _onDestroy = _ondestroy;
  const _edit_DeviceDetails = edit_DeviceDetails;
  // const _dialog = dialog;
  const _editunitopendialog = editunitopendialog;

  map.on("click", function (e) {    
    // _addfloorid = addfloorid;
    try {
      const pixel = e.pixel;
      // const corrdinates = e.coordinate;
      // const buildingid = 0;
      const feature = map.forEachFeatureAtPixel(pixel, function (feature, layer) {
        console.log(layer);
        return feature;
      });

      if (feature != undefined) {
        if (feature.getProperties().DataId == undefined) {
          if (localStorage.getItem("CreateNew") == "1") {
            console.log(localStorage.getItem("CreateNew"));
          }
        } else if (
          localStorage.getItem("CreateNew") != "1" &&
          _MapviewFloorComponent.checkIsSpace != true
        ) {
          const unitId = feature.getProperties().UnitId;
          // const _DeviceId = feature.getProperties().DeviceId;
          const FloorId = feature.getProperties().FloorId;
          const _DataId = feature.getProperties().DataId;
          const Selected_DeviceTypename = feature.getProperties().DeviceTypeName;
          // const geometry = feature.getProperties().geometry;
          const spaceName = feature.getProperties().UnitName;
          let DeviceCount = 1;
          if (
            Number.isInteger(feature.getProperties().MultiDeviceId) == false
          ) {
            DeviceCount = feature
              .getProperties()
              .MultiDeviceId.split(",").length;
          } else if (feature.getProperties().MultiDeviceId == 0)
            DeviceCount = 0;
          const authkey = localStorage.getItem("Authkey") ?? "";
          const ApiParamValue: getDeviceById = {
            authKey: authkey,
            dataId: _DataId,
            deviceType: Selected_DeviceTypename,
            floorId: FloorId,
            isIndoor: 1,
          };
          if (
            _DataId > 0 &&
            feature.getGeometry().flatCoordinates.length == 2
          ) {
            //if select icon then only open the popup
            if (
              localStorage.getItem("CreateNew") == "2" ||
              localStorage.getItem("CreateNew") == "3"
            ) {
                console.log(localStorage.getItem("CreateNew"));
            } else {
              if (_select) map.removeInteraction(_select);
              _technologyoverlayService
                .getDeviceDetailsbyId(ApiParamValue)
                .pipe(takeUntil(_onDestroy))
                .subscribe((Devicedetailsinfo: any[]) => {
                  if (
                    Devicedetailsinfo != null &&
                    Devicedetailsinfo != undefined
                  ) {
                    _edit_DeviceDetails(
                      Devicedetailsinfo["apiReturn"][0],
                      dialog,
                      0,
                      _MapviewFloorComponent
                    );
                  }
                });
            }
          } else if (
            _DataId > 0 &&
            feature.getGeometry().flatCoordinates.length != 2
          ) {
            if (
              localStorage.getItem("CreateNew") == "2" ||
              localStorage.getItem("CreateNew") == "3"
            ) {
                console.log(localStorage.getItem("CreateNew"));
            } else {
              localStorage.setItem("SpaceId", _DataId);
              localStorage.setItem("SpaceName", spaceName);
              localStorage.setItem("DeviceCount", DeviceCount.toString());

              const btnShowTemplate =
                document.getElementById("btnShowTemplate");
              const spnfloorName = document.getElementById("spnfloorName");
              const divDeviceList = document.getElementById("divDeviceList");

              if (btnShowTemplate != null)
                btnShowTemplate.innerHTML = "Save Space As Template";
              if (spnfloorName != null) spnfloorName.innerHTML = spaceName;
              localStorage.setItem("UnitId", unitId);
              if (
                divDeviceList != null &&
                divDeviceList.style.display != "none"
              ) {
                const all_deviceinfo = document.querySelectorAll(
                  "[class*=deviceinfo_]"
                );
                const all_testDivs = Array.prototype.filter.call(
                  all_deviceinfo,
                  function (deviceElement) {
                    deviceElement.style.background = "#ffffff";
                    return deviceElement;
                  }
                );
                console.log(all_testDivs);
                // const space_deviceinfo = document.getElementsByClassName(
                //   "deviceinfo_" + unitId
                // );
                // const testDivs = Array.prototype.filter.call(
                //   space_deviceinfo,
                //   function (deviceElement) {
                //     deviceElement.style.background = "#d3d3d35e";
                //     return deviceElement;
                //   }
                // );
              } else {
                // When click the Space need to edit the space case
                if (_select) map.removeInteraction(_select);
                select = new Select();
                map.addInteraction(select);
                _select = select;

                if (
                  localStorage.getItem("Spaceedit") != null &&
                  localStorage.getItem("Spaceedit") == "1"
                ) {
                  const authkey = localStorage.getItem("Authkey") ?? "";
                  const apiunitParamValue: UnitById = {
                    authKey: authkey,
                    unitId: parseInt(unitId),
                  };
                  _technologyoverlayService
                    .getUnitById(apiunitParamValue)
                    .pipe(takeUntil(_onDestroy))
                    .subscribe((Unitdetailsinfo: any[]) => {
                      if (
                        Unitdetailsinfo["apiReturn"] != null &&
                        Unitdetailsinfo["apiReturn"] != undefined
                      ) {
                        _editunitopendialog(
                          Unitdetailsinfo["apiReturn"][0],
                          dialog,
                          _MapviewFloorComponent
                        );
                      }
                    });
                }
              }
            }
          }
        }
      } else if (
        localStorage.getItem("CreateNew") != "2" &&
        localStorage.getItem("CreateNew") != "3" &&
        localStorage.getItem("CreateNew") != "1"
      ) {
        localStorage.setItem("SpaceName", "");
        localStorage.setItem("SpaceId", "0");
        localStorage.setItem("UnitId", "0");
        const btnShowTemplate = document.getElementById("btnShowTemplate");
        const spnfloorName = document.getElementById("spnfloorName");
        const hdFloorName = document.getElementById("hdFloorName");

        if (btnShowTemplate != null)
          btnShowTemplate.innerHTML = "Save Zone As Template";
        if (spnfloorName != null) {
          if (hdFloorName != null)
            spnfloorName.innerHTML = hdFloorName.innerHTML;
        }
      }
    } catch (err) {
      throw new Error(
        "app-general.ts" + " - maponclicked_Tech() - " + err.message
      );
    }
  });
}


function inside(point, vs) {
  const x = point[0],
    y = point[1];

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1];
    const xj = vs[j][0],
      yj = vs[j][1];

    const intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

function get_selectedpolygon(sel_feature, _addfloorimageheight) {
  let _deviceX = "";
  let _deviceY = "";
  let _DataId = "";
  let _DeviceId = "";
  let _DeviceTypeId = "";
  for (let indx = 0; indx < sel_feature.getArray().length; indx++) {
      let vertices = sel_feature.getArray()[indx].getGeometry()
      .flatCoordinates.length;
    if (vertices != 2) {
      localStorage.setItem(
        "FloorId",
        sel_feature.getArray()[indx].get("FloorId")
      );
      localStorage.setItem(
        "UnitId",
        sel_feature.getArray()[indx].get("UnitId")
      );
      localStorage.setItem(
        "RoomName",
        sel_feature.getArray()[indx].get("RoomName")
      );
      localStorage.setItem(
        "DataId",
        sel_feature.getArray()[indx].get("DataId")
      );
      localStorage.setItem("DeviceName", NewDeviceTypeDesc.Room);
      localStorage.setItem(
        "MultiDataId",
        sel_feature.getArray()[indx].get("MultiDataId")
      );
      localStorage.setItem(
        "MultiDeviceId",
        sel_feature.getArray()[indx].get("MultiDeviceId")
      );
      localStorage.setItem(
        "MultiFilterDeviceTypeId",
        sel_feature.getArray()[indx].get("MultiFilterDeviceTypeId")
      );

      localStorage.setItem("IsDevice", "0");
      const sdeviceDataId = sel_feature.getArray()[indx].get("MultiDataId");
      _DataId += sdeviceDataId + ",";
    } else {
      vertices = sel_feature.getArray()[indx].getGeometry().getCoordinates();
      const deviceX = convertToSvgEcllipseXCoordinate(
        parseFloat(vertices[0]),
        true
      );
      const deviceY = convertToSvgEcllipseYCoordinate(
        parseFloat(vertices[1]),
        _addfloorimageheight
      );

      const sdeviceDataId = sel_feature.getArray()[indx].get("DataId");
      const sdeviceDeviceId = sel_feature.getArray()[indx].get("DeviceId");
      const sdevice_TypeId = sel_feature.getArray()[indx].get("DeviceTypeId");

      _DataId += sdeviceDataId + ",";
      _DeviceId += sdeviceDeviceId + ",";
      _DeviceTypeId += sdevice_TypeId + ",";
      _deviceX += deviceX + ",";
      _deviceY += deviceY + ",";
    }
  }
  localStorage.setItem("MultiDataId", "");
  localStorage.setItem("MultiDeviceId", "");
  localStorage.setItem("MultiFilterDeviceTypeId", "");
  localStorage.setItem("DeviceX", "");
  localStorage.setItem("DeviceY", "");

  if (_DataId != "" && _DataId != null)
    localStorage.setItem(
      "MultiDataId",
      _DataId
        .substring(0, _DataId.length - 1)
        .split(",")
        .reverse()
        .join(",")
    );

  if (_DeviceId != "" && _DeviceId != null)
    localStorage.setItem(
      "MultiDeviceId",
      _DeviceId
        .substring(0, _DeviceId.length - 1)
        .split(",")
        .reverse()
        .join(",")
    );

  if (_DeviceTypeId != "" && _DeviceTypeId != null)
    localStorage.setItem(
      "MultiFilterDeviceTypeId",
      _DeviceTypeId
        .substring(0, _DeviceTypeId.length - 1)
        .split(",")
        .reverse()
        .join(",")
    );

  if (_deviceX != "" && _deviceX != null)
    localStorage.setItem(
      "DeviceX",
      _deviceX
        .substring(0, _deviceX.length - 1)
        .split(",")
        .reverse()
        .join(",")
    );

  if (_deviceY != "" && _deviceY != null)
    localStorage.setItem(
      "DeviceY",
      _deviceY
        .substring(0, _deviceY.length - 1)
        .split(",")
        .reverse()
        .join(",")
    );
}

function Checkpolygon_Intersect(
  map,
  _dialog,
  sel_feature,
  sel_polygon,
  _addfloorid,
  _addfloorimageheight
) {
  const arr_layer = [] as any[];
  const DataId = sel_polygon.get("DataId");

  map.getLayers().forEach(function (Floorlayer) {
    if (
      Floorlayer.get("FloorId") != undefined &&
      Floorlayer.get("FloorId") == "Floor_" + _addfloorid
    ) {
      arr_layer[arr_layer.length] = Floorlayer;
    }
  });
  let PolygonFeature = null;
  
  let vertices;
  let polyLine = [];
  const polyLine_result = [];
  for (let indx = 0; indx < arr_layer.length; indx++) {
    const features_device = arr_layer[indx].getSource().getFeatures();
    if (features_device) {
      features_device.forEach(function (feature) {
        const _fDataId = feature.getProperties().DataId.toString();
        if (
          parseInt(DataId) != parseInt(_fDataId) &&
          feature.getGeometry().flatCoordinates.length != 2
        ) {
          PolygonFeature = feature;
          vertices = feature.getGeometry().getCoordinates();

          if (PolygonFeature != null && vertices.length == 1) {
            if (vertices[0][0][0] == "NaN" || vertices[0][0][1] == "NaN") {
              vertices[0].pop();
            }
            polyLine = vertices[0];
            const polygon = polyLine;
            const Checkpolygon = sel_polygon.getGeometry().getCoordinates()[0];
            for (let chkIdx = 0; chkIdx < Checkpolygon.length; chkIdx++) {
              if (inside(Checkpolygon[chkIdx], polygon)) {
                polyLine_result[polyLine_result.length] = PolygonFeature;
                break;
              }
            }
            if (polyLine_result.length == 0) {
              for (let chkIdx = 0; chkIdx < polygon.length; chkIdx++) {
                if (inside(polygon[chkIdx], Checkpolygon)) {
                  polyLine_result[polyLine_result.length] = PolygonFeature;
                  break;
                }
              }
            }
          }
        }
      });
    }
    if (indx == arr_layer.length - 1) {
      if (polyLine_result.length != 0) {
        if (localStorage.getItem("CreateNew") == "0") {
          sel_feature.source.clear();
          sel_polygon.values_.geometry.flatCoordinates = "";
        }
        generateAlertMessage(_dialog, appsettings.Tech_Overlapping_Space);
      } else if (localStorage.getItem("CreateNew") == "0") {
        console.log(localStorage.getItem("CreateNew"));
      } else {
        get_selectedpolygon(sel_feature, _addfloorimageheight);
      }
    }
  }
}

function IsCheckRoomDrawn(_sel_feature, _this, IsModify) {
  // return true;
  let roomDrwan = true;
  const vertices = _sel_feature.getGeometry().getCoordinates();
  let polyLine = [];
  vertices[0].pop();
  polyLine = vertices[0];
  for (let i = 0; i < polyLine.length; i++) {
    const xi = polyLine[i][0],
      yi = polyLine[i][1];
    if (
      xi < 0 ||
      yi < 0 ||
      xi > _this.imageExtent[2] ||
      yi > _this.imageExtent[3]
    ) {
      roomDrwan = false;
      break;
    }
  }
  if (roomDrwan == false) {
    if (IsModify == 0) {
      _this.source.clear();
      _sel_feature.values_.geometry.flatCoordinates = "";
    }
    generateAlertMessage(
      _this.dialog,
      "Please place the room within the map extent"
    );
  }
  return roomDrwan;
}

function IsDevice_In_RoomDrawn(
  sel_feature,
  _this,
  _addfloorimageheight,
  IsAdd,
  arr_layer
) {  
  let poly_vertices;
  let polyLine = [];
  let PolygonFeature = null;
  let chkIsDevice_In_RoomDrawn = true;
  const unitid=sel_feature.array_[0].values_.UnitId;
  
  
  for (let indx = 0; indx < arr_layer.length; indx++) {
    const features_device = arr_layer[indx].getSource().getFeatures();
    if (features_device) {
      // Go through this array and get coordinates of their geometry.
      features_device.forEach(function (feature) {
        if (parseInt(feature.getProperties().UnitId) == parseInt(unitid) && feature.getGeometry().getType().toUpperCase() !="POLYGON" )
        {
          //need to push
          sel_feature.push(feature);
          mouseOnFeature(feature, 0);
        }
      });
    }
  }



  for (let indx = 0; indx < sel_feature.getArray().length; indx++) {
    const vertices = sel_feature.getArray()[indx].getGeometry()
      .flatCoordinates.length;
    if (vertices != 2) {
      PolygonFeature = sel_feature.getArray()[indx];
      poly_vertices = sel_feature.getArray()[indx].getGeometry().getCoordinates();
    } else {
      if (PolygonFeature != null && poly_vertices.length == 1) {
        if (
          poly_vertices[0][0][0] == "NaN" ||
          poly_vertices[0][0][1] == "NaN"
        ) {
          poly_vertices[0].pop();
        }
        polyLine = poly_vertices[0];
        const polygon = polyLine;

        // true
        if (
          inside(
            sel_feature.getArray()[indx].getGeometry().getCoordinates(),
            polygon
          ) == false
        ) {
          chkIsDevice_In_RoomDrawn = false;
        }
      }
    }
  }
  if (chkIsDevice_In_RoomDrawn == false && IsAdd == 1) {
    generateAlertMessage(
      _this.dialog,
      "Please change the room drawn as it able to have all its devices in it."
    );
  }
  return chkIsDevice_In_RoomDrawn;
}

function get_selectedfeature(
  map,
  _dialog,
  sel_feature,
  _addfloorid,
  _addfloorimageheight
) {
  const arr_layer = [] as any[];
  const DataId = sel_feature.get("DataId");
  map.getLayers().forEach(function (Floorlayer) {
    if (
      Floorlayer.get("FloorId") != undefined &&
      Floorlayer.get("FloorId") == "Floor_" + _addfloorid
    ) {
      arr_layer[arr_layer.length] = Floorlayer;
    }
  });
  let PolygonFeature = null;
  let selected_PolygonFeature = null;
  let vertices;
  let polyLine = [];
  let DeviceTypeNew = sel_feature.get("DeviceName");

  if (DeviceTypeNew != undefined)
    DeviceTypeNew = getDeviceTypeNew(DeviceTypeNew);

  for (let indx = 0; indx < arr_layer.length; indx++) {
    const features_device = arr_layer[indx].getSource().getFeatures();
    if (features_device) {
      // Go through this array and get coordinates of their geometry.
      features_device.forEach(function (feature) {
        const SelectedRoomDataId = feature.getProperties().MultiDataId.toString();
        if (
          SelectedRoomDataId.split(",").indexOf(parseInt(DataId).toString()) !=
            -1 &&
          feature.getGeometry().flatCoordinates.length != 2
        ) {
          PolygonFeature = feature;
          vertices = feature.getGeometry().getCoordinates();

          if (PolygonFeature != null && vertices.length == 1) {
            if (vertices[0][0][0] == "NaN" || vertices[0][0][1] == "NaN") {
              vertices[0].pop();
            }
            polyLine = vertices[0];
            const polygon = polyLine;

            // true
            if (inside(sel_feature.getGeometry().getCoordinates(), polygon)) {
              selected_PolygonFeature = PolygonFeature;
              localStorage.setItem("OldUnitId", "0");
              localStorage.setItem("UnitId", sel_feature.get("UnitId"));
            }
          }
        } else if (feature.getGeometry().flatCoordinates.length != 2) {
          PolygonFeature = feature;
          vertices = feature.getGeometry().getCoordinates();

          if (PolygonFeature != null && vertices.length == 1) {
            if (vertices[0][0][0] == "NaN" || vertices[0][0][1] == "NaN") {
              vertices[0].pop();
            }
            polyLine = vertices[0];
            const polygon = polyLine;

            // true
            if (inside(sel_feature.getGeometry().getCoordinates(), polygon)) {
              selected_PolygonFeature = PolygonFeature;
              localStorage.setItem("OldUnitId", sel_feature.get("UnitId"));
              localStorage.setItem(
                "UnitId",
                selected_PolygonFeature.get("UnitId")
              );
            }
          }
        }
      });
    }
    if (indx == arr_layer.length - 1) {
      if (selected_PolygonFeature == null) {
        localStorage.setItem("DeviceName", sel_feature.get("DeviceName"));
        localStorage.removeItem("Polypoints");
        localStorage.removeItem("FloorId");
        generateAlertMessage(
          _dialog,
          "Please place a " + DeviceTypeNew + " in the room drawn"
        );
      } else {
        vertices = sel_feature.getGeometry().getCoordinates();
        const deviceX = convertToSvgEcllipseXCoordinate(
          parseFloat(vertices[0]),
          true
        );
        const deviceY = convertToSvgEcllipseYCoordinate(
          parseFloat(vertices[1]),
          _addfloorimageheight
        );

        vertices = selected_PolygonFeature.getGeometry().getCoordinates();
        let polypoints;
        let verticesinfo = "";

        if (vertices.length > 0) {
          for (let j = 0; j < vertices[0].length; j++) {
            polypoints = _addfloorimageheight - parseFloat(vertices[0][j][1]);
            vertices[0][j][0] = Math.floor(vertices[0][j][0]);
            vertices[0][j][1] = Math.floor(polypoints);
            if (verticesinfo.length == 0) {
              verticesinfo += vertices[0][j][0] + "," + vertices[0][j][1];
            } else {
              verticesinfo =
                verticesinfo +
                " " +
                vertices[0][j][0] +
                "," +
                vertices[0][j][1];
            }
          }
        }
        localStorage.setItem("Polypoints", verticesinfo);

        localStorage.setItem("FloorId", sel_feature.get("FloorId"));
        localStorage.setItem("DataId", sel_feature.get("DataId"));
        localStorage.setItem("DeviceName", sel_feature.get("DeviceName"));
        localStorage.setItem("MultiDataId", sel_feature.get("MultiDataId"));
        localStorage.setItem("MultiDeviceId", sel_feature.get("MultiDeviceId"));
        localStorage.setItem(
          "MultiFilterDeviceTypeId",
          sel_feature.get("MultiFilterDeviceTypeId")
        );
        localStorage.setItem("IsDevice", "1");
        localStorage.setItem("DeviceX", deviceX);
        localStorage.setItem("DeviceY", deviceY);
      }
    }
  }
}

function getNewFeature(map, _dialog, sel_feature, _addfloorid) {
  let IsPlaced = 0;
  const arr_layer = [] as any[];
  map.getLayers().forEach(function (Floorlayer) {
    if (
      Floorlayer.get("FloorId") != undefined &&
      Floorlayer.get("FloorId") == "Floor_" + _addfloorid
    ) {
      arr_layer[arr_layer.length] = Floorlayer;
    }
  });
  let PolygonFeature = null;
  let selected_PolygonFeature = null;
  let vertices;
  let polyLine = [];
  let DeviceTypeNew = localStorage.getItem("DeviceTypeName");

  if (DeviceTypeNew != undefined)
    DeviceTypeNew = getDeviceTypeNew(DeviceTypeNew);

  for (let indx = 0; indx < arr_layer.length; indx++) {
    const features_device = arr_layer[indx].getSource().getFeatures();
    if (features_device) {
      // Go through this array and get coordinates of their geometry.
      features_device.forEach(function (feature) {
        PolygonFeature = feature;
        vertices = feature.getGeometry().getCoordinates();
      });
      if (PolygonFeature != null && vertices.length == 1) {
        if (vertices[0][0][0] == "NaN" || vertices[0][0][1] == "NaN") {
          vertices[0].pop();
        }
        polyLine = vertices[0];
        const polygon = polyLine;

        // true
        if (inside(sel_feature.getGeometry().getCoordinates(), polygon)) {
          selected_PolygonFeature = PolygonFeature;
        }
      }
    }
    if (indx == arr_layer.length - 1) {
      if (selected_PolygonFeature == null) {
        IsPlaced = 0;
        localStorage.removeItem("DataId");
        localStorage.removeItem("FloorId");
        localStorage.removeItem("UnitId");
        localStorage.removeItem("RoomName");
        generateAlertMessage(
          _dialog,
          "Please place a " + DeviceTypeNew + " in the room drawn"
        );
      } else {
        IsPlaced = 1;
        localStorage.setItem(
          "DataId",
          selected_PolygonFeature.getProperties().DataId
        );
        localStorage.setItem("FloorId", "Floor_" + _addfloorid);
        localStorage.setItem(
          "UnitId",
          selected_PolygonFeature.getProperties().UnitId
        );
        localStorage.setItem(
          "RoomName",
          selected_PolygonFeature.getProperties().RoomName
        );
      }
    }
  }
  return IsPlaced;
}

function get_selectedpolygon_Campus(sel_feature, _addfloorimageheight) {
  let _deviceX = "";
  let _deviceY = "";
  let _DataId = "";
  let _DeviceId = "";
  let _DeviceTypeId = "";
  for (let indx = 0; indx < sel_feature.getArray().length; indx++) {
    let vertices = sel_feature.getArray()[indx].getGeometry()
      .flatCoordinates.length;
  //  if (vertices != 2) {
      localStorage.setItem(
        "OutdoorId",
        sel_feature.getArray()[indx].get("OutdoorId")
      );
      localStorage.setItem(
        "UnitId",
        sel_feature.getArray()[indx].get("UnitId")
      );
      localStorage.setItem(
        "RoomName",
        sel_feature.getArray()[indx].get("RoomName")
      );
      localStorage.setItem(
        "DataId",
        sel_feature.getArray()[indx].get("DataId")
      );
      localStorage.setItem("DeviceName", NewDeviceTypeDesc.Room);
      localStorage.setItem(
        "MultiDataId",
        sel_feature.getArray()[indx].get("MultiDataId")
      );
      localStorage.setItem(
        "MultiDeviceId",
        sel_feature.getArray()[indx].get("MultiDeviceId")
      );
      localStorage.setItem(
        "MultiFilterDeviceTypeId",
        sel_feature.getArray()[indx].get("MultiFilterDeviceTypeId")
      );
      localStorage.setItem("IsDevice", "1");
    /*} else {
        vertices = sel_feature.getArray()[indx].getGeometry().getCoordinates();
        const deviceX = convertToSvgEcllipseXCoordinate(
        parseFloat(vertices[0]),
        true
      );
      const deviceY = convertToSvgEcllipseYCoordinate(
        parseFloat(vertices[1]),
        _addfloorimageheight
      );

      const sdeviceDataId = sel_feature.getArray()[indx].get("DataId");
      const sdeviceDeviceId = sel_feature.getArray()[indx].get("DeviceId");
      const sdevice_TypeId = sel_feature.getArray()[indx].get("DeviceTypeId");

      _DataId += sdeviceDataId + ",";
      _DeviceId += sdeviceDeviceId + ",";
      _DeviceTypeId += sdevice_TypeId + ",";
      _deviceX += deviceX + ",";
      _deviceY += deviceY + ",";
    }*/
  }
  localStorage.setItem("MultiDataId", "");
  localStorage.setItem("MultiDeviceId", "");
  localStorage.setItem("MultiFilterDeviceTypeId", "");
  localStorage.setItem("DeviceX", "");
  localStorage.setItem("DeviceY", "");

  // if (_DataId != "" && _DataId != null)
  //   localStorage.setItem(
  //     "MultiDataId",
  //     _DataId
  //       .substring(0, _DataId.length - 1)
  //       .split(",")
  //       .reverse()
  //       .join(",")
  //   );

  // if (_DeviceId != "" && _DeviceId != null)
  //   localStorage.setItem(
  //     "MultiDeviceId",
  //     _DeviceId
  //       .substring(0, _DeviceId.length - 1)
  //       .split(",")
  //       .reverse()
  //       .join(",")
  //   );

  // if (_DeviceTypeId != "" && _DeviceTypeId != null)
  //   localStorage.setItem(
  //     "MultiFilterDeviceTypeId",
  //     _DeviceTypeId
  //       .substring(0, _DeviceTypeId.length - 1)
  //       .split(",")
  //       .reverse()
  //       .join(",")
  //   );

  // if (_deviceX != "" && _deviceX != null)
  //   localStorage.setItem(
  //     "DeviceX",
  //     _deviceX
  //       .substring(0, _deviceX.length - 1)
  //       .split(",")
  //       .reverse()
  //       .join(",")
  //   );

  // if (_deviceY != "" && _deviceY != null)
  //   localStorage.setItem(
  //     "DeviceY",
  //     _deviceY
  //       .substring(0, _deviceY.length - 1)
  //       .split(",")
  //       .reverse()
  //       .join(",")
  //   );
}

function Checkpolygon_Intersect_Campus(
  map,
  _dialog,
  sel_feature,
  sel_polygon,
  _addCampusId,
  _addfloorimageheight
) {
  const arr_layer = [] as any[];
  const DataId = sel_polygon.get("DataId");

  map.getLayers().forEach(function (Floorlayer) {
    if (
      Floorlayer.get("OutdoorId") != undefined &&
      Floorlayer.get("OutdoorId") == "Outdoor_" + _addCampusId
    ) {
      arr_layer[arr_layer.length] = Floorlayer;
    }
  });
  let PolygonFeature = null;
  
  let vertices;
  let polyLine = [];
  const polyLine_result = [];
  for (let indx = 0; indx < arr_layer.length; indx++) {
    const features_device = arr_layer[indx].getSource().getFeatures();
    if (features_device) {
      // Go through this array and get coordinates of their geometry.
      features_device.forEach(function (feature) {
        const _fDataId = feature.getProperties().DataId.toString();
        if (
          parseInt(DataId) != parseInt(_fDataId) &&
          feature.getGeometry().flatCoordinates.length != 2
        ) {
          PolygonFeature = feature;
          vertices = feature.getGeometry().getCoordinates();

          if (PolygonFeature != null && vertices.length == 1) {
            if (vertices[0][0][0] == "NaN" || vertices[0][0][1] == "NaN") {
              vertices[0].pop();
            }
            polyLine = vertices[0];
            const polygon = polyLine;
            const Checkpolygon = sel_polygon.getGeometry().getCoordinates()[0];
            for (let chkIdx = 0; chkIdx < Checkpolygon.length; chkIdx++) {
              if (inside(Checkpolygon[chkIdx], polygon)) {
                polyLine_result[polyLine_result.length] = PolygonFeature;
                break;
              }
            }
            if (polyLine_result.length == 0) {
              for (let chkIdx = 0; chkIdx < polygon.length; chkIdx++) {
                if (inside(polygon[chkIdx], Checkpolygon)) {
                  polyLine_result[polyLine_result.length] = PolygonFeature;
                  break;
                }
              }
            }
          }
        }
      });
    }
    if (indx == arr_layer.length - 1) {
      if (polyLine_result.length != 0) {
        if (localStorage.getItem("CreateNew") == "0") {
          sel_feature.source.clear();
          sel_polygon.values_.geometry.flatCoordinates = "";
        }
        generateAlertMessage(_dialog, appsettings.Tech_Overlapping_Space);
      } else if (localStorage.getItem("CreateNew") == "0") {
        console.log(localStorage.getItem("CreateNew"));
      } else {
        get_selectedpolygon_Campus(sel_feature, _addfloorimageheight);
      }
    }
  }
}

function get_selectedfeature_Campus(
  map,
  _dialog,
  sel_feature,
  _addCampusId,
  _addfloorimageheight
) {
  const arr_layer = [] as any[];
  const DataId = sel_feature.get("DataId");
  map.getLayers().forEach(function (Floorlayer) {
    if (
      Floorlayer.get("OutdoorId") != undefined &&
      Floorlayer.get("OutdoorId") == "Outdoor_" + _addCampusId
    ) {
      arr_layer[arr_layer.length] = Floorlayer;
    }
  });
  let PolygonFeature = null;
  let selected_PolygonFeature = null;
  let vertices;
  let polyLine = [];
  let DeviceTypeNew = sel_feature.get("DeviceName");

  if (DeviceTypeNew != undefined)
    DeviceTypeNew = getDeviceTypeNew(DeviceTypeNew);

  for (let indx = 0; indx < arr_layer.length; indx++) {
   /* const features_device = arr_layer[indx].getSource().getFeatures();
    if (features_device) {
      // Go through this array and get coordinates of their geometry.
      features_device.forEach(function (feature) {
        const SelectedRoomDataId = feature.getProperties().MultiDataId.toString();
        if (
          SelectedRoomDataId.split(",").indexOf(parseInt(DataId).toString()) !=
            -1 &&
          feature.getGeometry().flatCoordinates.length != 2
        ) {
          PolygonFeature = feature;
          vertices = feature.getGeometry().getCoordinates();

          if (PolygonFeature != null && vertices.length == 1) {
            if (vertices[0][0][0] == "NaN" || vertices[0][0][1] == "NaN") {
              vertices[0].pop();
            }
            polyLine = vertices[0];
            const polygon = polyLine;

            // true
            if (inside(sel_feature.getGeometry().getCoordinates(), polygon)) {
              selected_PolygonFeature = PolygonFeature;
              localStorage.setItem("OldUnitId", "0");
              localStorage.setItem("UnitId", sel_feature.get("UnitId"));
            }
          }
        } else if (feature.getGeometry().flatCoordinates.length != 2) {
          PolygonFeature = feature;
          vertices = feature.getGeometry().getCoordinates();

          if (PolygonFeature != null && vertices.length == 1) {
            if (vertices[0][0][0] == "NaN" || vertices[0][0][1] == "NaN") {
              vertices[0].pop();
            }
            polyLine = vertices[0];
            const polygon = polyLine;

            // true
            if (inside(sel_feature.getGeometry().getCoordinates(), polygon)) {
              selected_PolygonFeature = PolygonFeature;
              localStorage.setItem("OldUnitId", sel_feature.get("UnitId"));
              localStorage.setItem(
                "UnitId",
                selected_PolygonFeature.get("UnitId")
              );
            }
          }
        }
      });
    }
    if (indx == arr_layer.length - 1) {
      if (selected_PolygonFeature == null) {
        localStorage.setItem("DeviceName", sel_feature.get("DeviceName"));
        generateAlertMessage(
          _dialog,
          "Please place a " + DeviceTypeNew + " in the room drawn"
        );
      } else {*/
         vertices = sel_feature.getGeometry().getCoordinates();
        const deviceX = convertToSvgEcllipseXCoordinate(
          parseFloat(vertices[0]),
          true
        );
        const deviceY = convertToSvgEcllipseYCoordinate(
          parseFloat(vertices[1]),
          _addfloorimageheight
        );
        
        localStorage.setItem("OldUnitId", "0");
        localStorage.setItem("UnitId", sel_feature.get("UnitId"));
        //vertices = selected_PolygonFeature.getGeometry().getCoordinates();
        let polypoints;
        let verticesinfo = "";

        if (vertices.length > 0) {
          for (let j = 0; j < vertices[0].length; j++) {
            polypoints = _addfloorimageheight - parseFloat(vertices[0][j][1]);
            vertices[0][j][0] = Math.floor(vertices[0][j][0]);
            vertices[0][j][1] = Math.floor(polypoints);
            if (verticesinfo.length == 0) {
              verticesinfo += vertices[0][j][0] + "," + vertices[0][j][1];
            } else {
              verticesinfo =
                verticesinfo +
                " " +
                vertices[0][j][0] +
                "," +
                vertices[0][j][1];
            }
          }
        }
        localStorage.setItem("Polypoints", verticesinfo);

        localStorage.setItem("OutdoorId", sel_feature.get("OutdoorId"));
        localStorage.setItem("DataId", sel_feature.get("DataId"));
        localStorage.setItem("DeviceName", sel_feature.get("DeviceName"));
        localStorage.setItem("MultiDataId", sel_feature.get("MultiDataId"));
        localStorage.setItem("MultiDeviceId", sel_feature.get("MultiDeviceId"));
        localStorage.setItem(
          "MultiFilterDeviceTypeId",
          sel_feature.get("MultiFilterDeviceTypeId")
        );
        localStorage.setItem("IsDevice", "1");
        localStorage.setItem("DeviceX", deviceX);
        localStorage.setItem("DeviceY", deviceY);
     // }
   // }
  }
}

function getNewFeature_Campus(map, _dialog, sel_feature, _CampusId) {
  let IsPlaced = 0;
  const arr_layer = [] as any[];
  map.getLayers().forEach(function (Campuslayer) {
    if (
      Campuslayer.get("OutdoorId") != undefined &&
      Campuslayer.get("OutdoorId") == "Outdoor_" + _CampusId
    ) {
      arr_layer[arr_layer.length] = Campuslayer;
    }
  });
  let PolygonFeature = null;
  let selected_PolygonFeature = null;
  let vertices;
  let polyLine = [];
  let DeviceTypeNew = localStorage.getItem("DeviceTypeName");

  if (DeviceTypeNew != undefined)
    DeviceTypeNew = getDeviceTypeNew(DeviceTypeNew);

  for (let indx = 0; indx < arr_layer.length; indx++) {
    const features_device = arr_layer[indx].getSource().getFeatures();
    if (features_device) {
      // Go through this array and get coordinates of their geometry.
      features_device.forEach(function (feature) {
        PolygonFeature = feature;
        vertices = feature.getGeometry().getCoordinates();
      });
      if (PolygonFeature != null && vertices.length == 1) {
        if (vertices[0][0][0] == "NaN" || vertices[0][0][1] == "NaN") {
          vertices[0].pop();
        }
        polyLine = vertices[0];
        const polygon = polyLine;

        // true
        if (inside(sel_feature.getGeometry().getCoordinates(), polygon)) {
          selected_PolygonFeature = PolygonFeature;
        }
      }
    }
    if (indx == arr_layer.length - 1) {
      if (selected_PolygonFeature == null) {
        IsPlaced = 0;
        localStorage.removeItem("DataId");
        localStorage.removeItem("OutdoorId");
        localStorage.removeItem("UnitId");
        localStorage.removeItem("RoomName");
        generateAlertMessage(
          _dialog,
          "Please place a " + DeviceTypeNew + " in the room drawn"
        );
      } else {
        IsPlaced = 1;
        localStorage.setItem(
          "DataId",
          selected_PolygonFeature.getProperties().DataId
        );
        localStorage.setItem("OutdoorId", "Outdoor_" + _CampusId);
        localStorage.setItem(
          "UnitId",
          selected_PolygonFeature.getProperties().UnitId
        );
        localStorage.setItem(
          "RoomName",
          selected_PolygonFeature.getProperties().RoomName
        );
      }
    }
  }
  if (arr_layer.length == 0) {
    IsPlaced = 0;
    localStorage.removeItem("DataId");
    localStorage.removeItem("OutdoorId");
    localStorage.removeItem("UnitId");
    localStorage.removeItem("RoomName");
    generateAlertMessage(
      _dialog,
      "Please place a " + DeviceTypeNew + " in the room drawn"
    );
  }
  return IsPlaced;
}

function LoadTechnologyCampusSvg(
  map,
  arrFloorInfo,
  imgheight,
  imgwidth,
  arrdevicemarker,
  geoFloorjsonObject,
  arrdevicemarkernoPoly,
  devicelabelStyle,
  fillStyle,
  Map_image_path,
  CampusId,
  labelStyle,style
) {
  try {
    const IsDeviceEmpty = 0;
    let arr_arrpolypoint = [] as any[];
    let arr_arrIsDuplicate = [] as any[];
    let arr_arrunitid = [] as any[];
    const arr_arrpolypointData = [] as any[];
    const geoFloorjsonObject = get_geojsonObject_floor(
      enumRequestFrom.home_page
    );
    geoFloorjsonObject.type = "FeatureCollection";
    geoFloorjsonObject.features.length = 0;


    for (let ninfo = 0; ninfo <= arrFloorInfo.length - 1; ninfo++) {
      if (arrFloorInfo[ninfo].polyline != undefined) {
        const indexval = arr_arrpolypoint.indexOf(arrFloorInfo[ninfo].polyline);
        let unitIdindexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
        if (indexval == -1 || unitIdindexval == -1) {
          const len = arr_arrpolypoint.length;
          arr_arrpolypointData[len] = {
            multiUnitId: "",
            multiDataId: "",
            multiDeviceId: "",
            multiFilterDeviceTypeId: "",
            multiFilterDeviceType: "",
          };
          arr_arrpolypointData[len].multiUnitId = arrFloorInfo[ninfo].UnitId;
          arr_arrpolypointData[len].multiDataId = arrFloorInfo[ninfo].DataId;
          arr_arrpolypointData[len].multiDeviceId =
            arrFloorInfo[ninfo].DeviceId;
          arr_arrpolypointData[len].multiFilterDeviceTypeId =
            arrFloorInfo[ninfo].FilterDeviceTypeId;
          arr_arrpolypointData[len].multiFilterDeviceType =
            arrFloorInfo[ninfo].FilterDeviceType;
          arr_arrpolypoint[len] = arrFloorInfo[ninfo].polyline;
          arr_arrIsDuplicate[len] = arrFloorInfo[ninfo].isDuplicate;
          arr_arrunitid[len] = arrFloorInfo[ninfo].UnitId;
        } else if (indexval != -1) {
           unitIdindexval = arr_arrunitid.indexOf(
            arrFloorInfo[ninfo].UnitId
          );
          arr_arrpolypointData[unitIdindexval].multiUnitId +=
            "," + arrFloorInfo[ninfo].UnitId;
          arr_arrpolypointData[unitIdindexval].multiDataId +=
            "," + arrFloorInfo[ninfo].DataId;
          arr_arrpolypointData[unitIdindexval].multiDeviceId +=
            "," + arrFloorInfo[ninfo].DeviceId;
          arr_arrpolypointData[unitIdindexval].multiFilterDeviceTypeId +=
            "," + arrFloorInfo[ninfo].FilterDeviceTypeId;
          arr_arrpolypointData[unitIdindexval].multiFilterDeviceType +=
            "," + arrFloorInfo[ninfo].FilterDeviceType;
        } else if (unitIdindexval != -1) {
           unitIdindexval = arr_arrunitid.indexOf(
            arrFloorInfo[ninfo].UnitId
          );
          arr_arrpolypointData[unitIdindexval].multiUnitId +=
            "," + arrFloorInfo[ninfo].UnitId;
          arr_arrpolypointData[unitIdindexval].multiDataId +=
            "," + arrFloorInfo[ninfo].DataId;
          arr_arrpolypointData[unitIdindexval].multiDeviceId +=
            "," + arrFloorInfo[ninfo].DeviceId;
          arr_arrpolypointData[unitIdindexval].multiFilterDeviceTypeId +=
            "," + arrFloorInfo[ninfo].FilterDeviceTypeId;
          arr_arrpolypointData[unitIdindexval].multiFilterDeviceType +=
            "," + arrFloorInfo[ninfo].FilterDeviceType;
        }
      }
    }
    arr_arrpolypoint = [];
    arr_arrIsDuplicate = [];
    arr_arrunitid = [];
    for (let ninfo = 0; ninfo <= arrFloorInfo.length - 1; ninfo++) {
     
      const UnitId = arrFloorInfo[ninfo].UnitId;
      const DataId = arrFloorInfo[ninfo].DataId;
      const DeviceId = arrFloorInfo[ninfo].DeviceId;
      const DeviceName = arrFloorInfo[ninfo].DeviceName;
      const RoomName = arrFloorInfo[ninfo].RoomName;
      const DeviceX = arrFloorInfo[ninfo].DeviceX;
      const DeviceY = arrFloorInfo[ninfo].DeviceY;
      const MacId = arrFloorInfo[ninfo].MacId;
      const unitName = arrFloorInfo[ninfo].UnitLocation;
      const RoomCategory = arrFloorInfo[ninfo].ResRoomCategory;

      const DeviceTypeId = arrFloorInfo[ninfo].FilterDeviceTypeId;
      const DeviceTypeName = arrFloorInfo[ninfo].FilterDeviceType;

      let _DeviceId = DeviceId;
      const _MacId = MacId;
      let markerpoint: any[];
      if (RoomCategory != "" && DeviceId > 0) {
        _DeviceId = RoomCategory;
      }

      /// Floor details ///
      let polyline = [] as any[];
      if (arrFloorInfo[ninfo].polyline != undefined) {
        polyline = arrFloorInfo[ninfo].polyline.split(" ");        
        const arrpolypoint = [] as any[];
        /// Floor poly line ///
        for (let npoly = 0; npoly <= polyline.length - 1; npoly++) {
          arrpolypoint[npoly] = polyline[npoly].split(",");
          arrpolypoint[npoly][0] = parseFloat(arrpolypoint[npoly][0]);
          arrpolypoint[npoly][1] = getboxFromYpos(
            parseFloat(arrpolypoint[npoly][1]),
            imgheight
          );
        }
        
        /*if (DeviceX == undefined || DeviceY == undefined) {
          markerpoint = calculatexycoordinates(polyline, imgheight); // default inside posstion it col
        } else {
          if (DeviceX == "" || DeviceY == "") {
            markerpoint = calculatexycoordinates(polyline, imgheight); // default inside posstion it col
          } else {
            markerpoint = [
              convertToSvgEcllipseXCoordinate(parseFloat(DeviceX), false),
              convertToSvgEcllipseYCoordinate(parseFloat(DeviceY), imgheight),
            ];
          }
        }*/

        markerpoint = calculatexycoordinates(polyline, imgheight); // default inside posstion it col

        const marker = new OlFeature({
          // Added fromLonLat
          geometry: new OlPoint(markerpoint),
        });

        let imgName = "";
        let imgScale = 0.3;
        let iconStyle;
        [imgName, imgScale] = getDevicetype_image(DeviceName);

        if (DeviceX == undefined || DeviceY == undefined) {
          //if undefine we have no image shown
          imgScale = 0.0;
        } else if (DeviceX == "" || DeviceY == "") {
          imgScale = 0.0;
        }

        //marker Icon style
      /*  if (DeviceName == DeviceTypeDesc.Gateway)
           iconStyle = map_marker_iconstylewithtext(
            marker,
            Map_image_path + imgName,
            imgScale,
            _MacId,
            0
          );
        else
           iconStyle = map_marker_iconstylewithtext(
            marker,
            Map_image_path + imgName,
            imgScale,
            _DeviceId,
            0
          );
        console.log(iconStyle);*/

        const indexval = arr_arrpolypoint.indexOf(arrFloorInfo[ninfo].polyline);
        let multi_indexval = -1;
        const unitIdindexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
        if (indexval == -1 || unitIdindexval == -1) {
          arr_arrIsDuplicate[arr_arrpolypoint.length] =
            arrFloorInfo[ninfo].isDuplicate;
          arr_arrunitid[arr_arrpolypoint.length] = arrFloorInfo[ninfo].UnitId;
          multi_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
          arr_arrpolypoint[arr_arrpolypoint.length] =
            arrFloorInfo[ninfo].polyline;
        } else {
          multi_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
        }
        //marker.setId("UnitName-" + UnitId);
        marker.set("UnitId", UnitId);
        marker.set("DataId", DataId);
        marker.set("OutdoorId", CampusId);
        marker.set("DeviceId", DeviceId);
        marker.set("DeviceTypeName", DeviceTypeName);
        marker.set("DeviceType", DeviceTypeId);
        marker.set("DeviceName", DeviceName);
        marker.set("UnitName", unitName);
        marker.set("DeviceTypeId", arrFloorInfo[ninfo].FilterDeviceTypeId);
        marker.set("MacId", MacId);
        marker.set("RoomCategory", RoomCategory);

        marker.set(
          "MultiDataId",
          arr_arrpolypointData[multi_indexval].multiDataId
        );
        marker.set(
          "MultiDeviceId",
          arr_arrpolypointData[multi_indexval].multiDeviceId
        );
        marker.set(
          "MultiFilterDeviceTypeId",
          arr_arrpolypointData[multi_indexval].multiFilterDeviceTypeId
        );
        marker.set(
          "MultiFilterDeviceType",
          arr_arrpolypointData[multi_indexval].multiFilterDeviceType
        );

        arrdevicemarker.push(marker);

        if (indexval == -1 || unitIdindexval == -1) {
          const unit_indexval = arr_arrunitid.indexOf(arrFloorInfo[ninfo].UnitId);
          const _multiDataId = arr_arrpolypointData[unit_indexval].multiDataId;
          const _multiDeviceId =
            arr_arrpolypointData[unit_indexval].multiDeviceId;
          const _multiFilterDeviceTypeId =
            arr_arrpolypointData[unit_indexval].multiFilterDeviceTypeId;
          const _multiFilterDeviceType =
            arr_arrpolypointData[unit_indexval].multiFilterDeviceType;

          const arr_multiDeviceId =
            _multiDeviceId != null ? _multiDeviceId.toString().split(",") : [];
          
          const arr_multiFilterDeviceType =
            _multiFilterDeviceType != null
              ? _multiFilterDeviceType.toString().split(",")
              : [];

          const geoFloorjsonObject = new OlFeature({
            geometry: new Polygon([arrpolypoint]),
            OutdoorId: CampusId,
            UnitId: UnitId.toString(),
            RoomName: RoomName,
            DataId: DataId,
            MultiDataId: _multiDataId,
            MultiDeviceId: _multiDeviceId,
            MultiFilterDeviceTypeId: _multiFilterDeviceTypeId,
            MultiFilterDeviceType: _multiFilterDeviceType,
            UnitName: unitName,
          });

      
            //need check all the devices for that room
          //   IsDeviceEmpty = 0;
          //   for (let nidx = 0; nidx <= arr_multiDeviceId.length; nidx++) {
          //     if (
          //       arr_multiDeviceId[nidx] == "0" &&
          //       arr_multiFilterDeviceType[nidx] != DeviceTypeDesc.Gateway && 
          //       arr_multiFilterDeviceType[nidx] != NewDeviceTypeDesc.Room &&
          //       arr_multiFilterDeviceType[nidx] != NewDeviceTypeDesc.Approx
          //     ) {
          //       IsDeviceEmpty = 1;
          //     }
          //   }
      

          // if (arr_multiFilterDeviceType.length == 0) {
          //   IsDeviceEmpty = -1;
          // }         

          // if ((arr_multiFilterDeviceType.indexOf(NewDeviceTypeDesc.Room) != -1 || arr_multiFilterDeviceType.indexOf(NewDeviceTypeDesc.Approx) != -1) && 
          //     arr_multiFilterDeviceType.length == 1) {
          //   IsDeviceEmpty = -1;
          // }
          // let deviceStyle = fillcolor_style(fillcolorPolygon.Completed);
          // if (IsDeviceEmpty == 1) {
          //   deviceStyle = fillcolor_style(fillcolorPolygon.Duplicate);
          // }
          // if (IsDeviceEmpty == -1) {
          //   deviceStyle = fillcolor_style(fillcolorPolygon.Incomplete);
          //}

          let deviceStyle = fillcolor_style(fillcolorPolygon.Incomplete); //for Aproxmate location
          if (arrFloorInfo[ninfo].FilterDeviceTypeId ==DeviceType.Zone.toString() && arrFloorInfo[ninfo].Accuracy !='' && arrFloorInfo[ninfo].Accuracy !='0')
             deviceStyle = fillcolor_style(fillcolorPolygon.Completed);

          const vectorSource_geo = new OlVectorSource({
            features: [geoFloorjsonObject],
            wrapX: false,
          });

          const vectorLayer_geo = new OlVectorLayer({
            source: vectorSource_geo,
            OutdoorId: "Outdoor_" + CampusId,
            name: "Space_" + UnitId,
            style: deviceStyle,
            declutter: true,
          });

          map.addLayer(vectorLayer_geo);
        }
      } // polygon
      else if (
        arrFloorInfo[ninfo].polyline == undefined &&
        DeviceX != undefined
      ) {
        if (DeviceX != "") {
          markerpoint = [
            convertToSvgEcllipseXCoordinate(parseFloat(DeviceX), false),
            convertToSvgEcllipseYCoordinate(parseFloat(DeviceY), imgheight),
          ];
          const markernopoly = new OlFeature({
            // Added fromLonLat
            geometry: new OlPoint(markerpoint),
          });

          let imgName = "";
          let imgScale = 0.3;
          let iconStyle;
          [imgName, imgScale] = getDevicetype_image(DeviceName);

          if (DeviceName == DeviceTypeDesc.Gateway)
            iconStyle = map_marker_iconstylewithtext(
              markernopoly,
              Map_image_path + imgName,
              imgScale,
              _MacId,
              0
            );
          else
            iconStyle = map_marker_iconstylewithtext(
              markernopoly,
              Map_image_path + imgName,
              imgScale,
              _DeviceId,
              0
            );
          console.log(iconStyle);
          markernopoly.set("UnitId", UnitId);
          markernopoly.set("DataId", DataId);
          markernopoly.set("OutdoorId", CampusId);
          markernopoly.set("DeviceId", DeviceId);
          markernopoly.set("DeviceTypeName", DeviceTypeName);
          markernopoly.set("DeviceType", DeviceTypeId);
          markernopoly.set("DeviceName", DeviceName);
          markernopoly.set(
            "DeviceTypeId",
            arrFloorInfo[ninfo].FilterDeviceTypeId
          );
          markernopoly.set("MacId", MacId);
          markernopoly.set("RoomCategory", RoomCategory);

          markernopoly.set("MultiDataId", DataId);
          markernopoly.set("MultiDeviceId", DeviceId);
          markernopoly.set(
            "MultiFilterDeviceTypeId",
            arrFloorInfo[ninfo].FilterDeviceTypeId
          );
          markernopoly.set(
            "MultiFilterDeviceType",
            arrFloorInfo[ninfo].aFilterDeviceType
          );

          arrdevicemarkernoPoly.push(markernopoly);
        }
      }
    }
  } 
  catch (err) {
    throw new Error(
      "app-general.ts" + " - LoadTechnologyCampusSvg() - " + err.message
    );
  }
}

function maponclicked_Tech_Campus(
  map,
  select,
  addcampusid,
  technologyoverlayService,
  _ondestroy,
  edit_DeviceDetails,
  dialog,
  _MapviewFloorComponent,
  editOutdoordetails
) {
  
  console.log(editOutdoordetails);  
  let _select;
  const _technologyoverlayService = technologyoverlayService;
  const _onDestroy = _ondestroy;
  const _edit_DeviceDetails = edit_DeviceDetails;
  map.on("click", function (e) {
    try {
      const pixel = e.pixel;      
      const feature = map.forEachFeatureAtPixel(pixel, function (feature, layer) {
        console.log(layer);
        return feature;
      });

      if (feature != undefined) {
        if (feature.getProperties().DataId == undefined) {
          if (localStorage.getItem("CreateNew") == "1") {
            console.log(localStorage.getItem("CreateNew"))
          }
        } else if (
          localStorage.getItem("CreateNew") != "1" &&
          _MapviewFloorComponent.checkIsSpace != true
        ) {
          const unitId = feature.getProperties().UnitId;
          
          const OutdoorId = feature.getProperties().OutdoorId;
          const _DataId = feature.getProperties().DataId;
          const Selected_DeviceTypename = feature.getProperties().DeviceTypeName;
         
          const spaceName = feature.getProperties().UnitName;
          let DeviceCount = 1;
          if (
            Number.isInteger(feature.getProperties().MultiDeviceId) == false
          ) {
            DeviceCount = feature
              .getProperties()
              .MultiDeviceId.split(",").length;
          } else if (feature.getProperties().MultiDeviceId == 0)
            DeviceCount = 0;
          const authkey = localStorage.getItem("Authkey") ?? "";
          const ApiParamValue: getDeviceById = {
            authKey: authkey,
            dataId: _DataId,
            deviceType: Selected_DeviceTypename,
            floorId: OutdoorId,
            isIndoor: 0,
          };
          if (
            _DataId > 0 &&
            feature.getGeometry().flatCoordinates.length == 2
          ) {
            //if select icon then only open the popup
            if (
              localStorage.getItem("CreateNew") != "2" &&
              localStorage.getItem("CreateNew") != "3"
            ) {
              if (_select) map.removeInteraction(_select);
              _technologyoverlayService
                .getDeviceDetailsbyId(ApiParamValue)
                .pipe(takeUntil(_onDestroy))
                .subscribe((Devicedetailsinfo: any[]) => {
                  if (
                    Devicedetailsinfo != null &&
                    Devicedetailsinfo != undefined
                  ) {
                    _edit_DeviceDetails(
                      Devicedetailsinfo["apiReturn"][0],
                      dialog,
                      0,
                      _MapviewFloorComponent
                    );
                  }
                });
            }
          } else if (
            _DataId > 0 &&
            feature.getGeometry().flatCoordinates.length != 2
          ) {
            if (
              localStorage.getItem("CreateNew") != "2" &&
              localStorage.getItem("CreateNew") != "3"
            ) {
              if (_select) map.removeInteraction(_select);

              select = new Select();
              map.addInteraction(select);
              _select = select;

              const authkey = localStorage.getItem("Authkey") ?? "";
              const unitbyid: UnitById = {
                authKey: authkey,
                unitId: parseInt(unitId),
              };
              _technologyoverlayService
                .getUnitById(unitbyid)
                .pipe(takeUntil(_onDestroy))
                .subscribe((UnitByid: any[]) => {
                  _MapviewFloorComponent.UnitById = UnitByid["apiReturn"][0];
                  if (
                    _MapviewFloorComponent.UnitById != null &&
                    _MapviewFloorComponent.UnitById != undefined
                  )
                    _MapviewFloorComponent.editOutdoordetails(UnitByid["apiReturn"][0]);
                });

              localStorage.setItem("SpaceId", _DataId);
              localStorage.setItem("SpaceName", spaceName);
              localStorage.setItem("UnitId", unitId);
              localStorage.setItem("DeviceCount", DeviceCount.toString());
            }
          }
        }
      } else if (
        localStorage.getItem("CreateNew") != "2" &&
        localStorage.getItem("CreateNew") != "3" &&
        localStorage.getItem("CreateNew") != "1"
      ) {
        localStorage.setItem("SpaceName", "");
        localStorage.setItem("SpaceId", "0");
        localStorage.setItem("UnitId", "0");
      }
    } catch (err) {
      throw new Error(
        "app-general.ts" + " - maponclicked_Tech_Campus() - " + err.message
      );
    }
  });
}

function mouseOnFeature(feature, IsHover) {
  if (localStorage.getItem("CreateNew") != "1") {
    const hover_feature = feature;
    hover_feature.setStyle(null);

    const _DeviceName = hover_feature.get("DeviceName");
    const _MacId = hover_feature.get("MacId");
    const _DeviceId1 = hover_feature.get("DeviceId");
    const Map_image_path = "assets/images/";
    let imgName = "";
    let imgScale = 0.3;

    [imgName, imgScale] = getDevicetype_image(_DeviceName);

    if (IsHover == 1) imgScale = 0.75;

    if (
      localStorage.getItem("CreateNew") == "2" &&
      localStorage.getItem("sel_DataId") == feature.get("DataId")
    ) {
      imgScale = 0.65;
      console.log(imgScale)
      const arr_imgName = imgName.split(".");
      imgName = arr_imgName[0] + "_Select." + arr_imgName[1];
    }

    const _RoomCategory = hover_feature.get("RoomCategory");
    let _DeviceId = _DeviceId1;
    const MacId = _MacId;
    let iconStyle;
    if (_RoomCategory != "" && _DeviceId > 0) {
      _DeviceId = _RoomCategory;
    }
    console.log("mouseOnFeature :" + _DeviceId1);
    if (_DeviceName == DeviceTypeDesc.Gateway)
      iconStyle = map_marker_iconstylewithtext(
        hover_feature,
        Map_image_path + imgName,
        imgScale,
        MacId,
        0
      );
    else
      iconStyle = map_marker_iconstylewithtext(
        hover_feature,
        Map_image_path + imgName,
        imgScale,
        _DeviceId,
        0
      );
      console.log(iconStyle);
  }
}

function ClearStorageSpaceData() {
  localStorage.removeItem("FloorId");
  localStorage.removeItem("UnitId");
  localStorage.removeItem("DataId");
  localStorage.removeItem("DeviceName");
  localStorage.removeItem("MultiDataId");
  localStorage.removeItem("MultiDeviceId");
  localStorage.removeItem("MultiFilterDeviceTypeId");
  localStorage.removeItem("IsDevice");
  localStorage.removeItem("DeviceX");
  localStorage.removeItem("DeviceY");
}

function ClearStorageeData() {
  localStorage.removeItem("OutdoorId");
  localStorage.removeItem("UnitId");
  localStorage.removeItem("DataId");
  localStorage.removeItem("DeviceName");
  localStorage.removeItem("MultiDataId");
  localStorage.removeItem("MultiDeviceId");
  localStorage.removeItem("MultiFilterDeviceTypeId");
  localStorage.removeItem("IsDevice");
  localStorage.removeItem("DeviceX");
  localStorage.removeItem("DeviceY");
}

function getSelected(_selectedFeature, _this, arr_layer, IsModify) {
  localStorage.removeItem("sel_DataId");
  const fillcolorStyle = fillcolor_styleSelect();
  const selctedId = _selectedFeature.getProperties().UnitId;
  
  const _DataId = _selectedFeature.getProperties().DataId;
  const _MultiDataId = _selectedFeature.getProperties().MultiDataId;
  const geometry = _selectedFeature.getProperties().geometry;
  try
  {
 
    if (_DataId) {
      _this.select.getFeatures().clear();
      if (IsModify == 1) {
        _this.modify.features_.array_ = [];
      } else {
        _this.translate.features_.array_ = [];
      }
      
      _this.select.multi_ = true;
      _this.select.style_ = fillcolorStyle;

      
      for (let indx = 0; indx < arr_layer.length; indx++) {
        const features_device = arr_layer[indx].getSource().getFeatures();
        if (features_device) {
          // Go through this array and get coordinates of their geometry.
          features_device.forEach(function (feature) {
            if (
              parseInt(feature.getProperties().DataId) == parseInt(_DataId) &&
              geometry.flatCoordinates.length == 2 &&
              geometry.flatCoordinates.length ==
                feature.getProperties().geometry.flatCoordinates.length &&
              parseInt(feature.getProperties().DeviceType) != 4
            ) {
              const sel_DataId = feature.getProperties().DataId;
              localStorage.setItem("sel_DataId", sel_DataId);
              _this.select.getFeatures().push(feature);
              mouseOnFeature(feature, 0);
            } else if (
              _MultiDataId
                .toString()
                .split(",")
                .indexOf(parseInt(feature.getProperties().DataId).toString()) !=
                -1  && geometry.flatCoordinates.length != 2
            ) {
                _this.select.getFeatures().push(feature);
              if (feature.getProperties().geometry.flatCoordinates.length == 2 )
                mouseOnFeature(feature, 0);
            } else if ( parseInt(feature.getProperties().DataId) != parseInt(_DataId) && feature.getGeometry().getType().toUpperCase() =="POLYGON")
            {
              feature.setStyle(null);
            }
          
          });
        }
      }
    } else {
      _this.select.getFeatures().clear();
      if (IsModify == 1) {
        _this.modify.features_.array_ = [];
      } else {
        _this.translate.features_.array_ = [];
      }
      _this.select.multi_ = true;
      _this.select.style_ = fillcolorStyle;
      for (let indx = 0; indx < arr_layer.length; indx++) {
        const features_device = arr_layer[indx].getSource().getFeatures();
        if (features_device) {
          // Go through this array and get coordinates of their geometry.
          features_device.forEach(function (feature) {
            if (parseInt(feature.getProperties().UnitId) == parseInt(selctedId)) {
              console.log("getSelected-UnitIdDevice");
              
              _this.select.getFeatures().push(feature);
            }
          });
        }
      }
    }
  }
  catch (error) {
    throw new Error(
      "app.general.ts - getSelected() - " + error.message
    );
  }
  
}

enum enumLocationType {
  LocationTypeZone = 1,
}
enum enumDeviceType {
  TruLocator = 1,
  HREX = 2,
  REX = 3,
  ROOM = 4,
  LF = 6,
  Pullcord = 9,
  Zone = 10,
  DC = 11,
  Monitor = 12,
  VW = 13,
  Gateway = 14,
  UT = 15,
  UM = 16,
  InterCom=17,
  DripTray=18,
}

export {
  userinfo,
  msgbox,
  get_alert_data_floor_search,
  getlocation_data,
  notification_buttons,
  alert_pull_cord,
  alert_DC,
  alert_DCT,
};
export {
  notification_neareststaff_heading,
  notification_neareststaff_details,
  notification_staffinfomration,
};
export {
  notification_line1,
  notification_line2,
  notification_line3,
  notification_ackinfo,
  get_alert_data_search,
};
export {
  alert_description,
  alert_campusinfo,
  alert_residnetinfo,
  alert_residnetdetail,
  alert_notification_button,
  secondsToHms,
};
export {
  alert_subname,
  alert_unitinfo,
  alert_ackstatus,
  alert_newline,
  alert_timeinfo,
  alert_resStaffunitinfo,
};
export {
  calculatexycoordinates,
  getboxFromYpos,
  map_widthandheight,
  getDevicetype_image,
};
export {
  convertToSvgEcllipseXCoordinate,
  convertToSvgEcllipseYCoordinate,
  getSelectedDevicetype_image,
};
export {
  get_alert_data,
  alert_data_floor,
  alert_location_data_detail,
  alert_data_search,
  groupBy,
};
export {
  enumDeviceType,
  maponclicked,
  maponclicked_Tech,
  maponclicked_Tech_Campus,
  popupclick_invoke,
  CheckAlertDataChanged,
  set_SharedDetails,
  set_SharedFloorDetails,
  set_alertChangeorClick,
  set_AlertReportChange,
  set_IsAlertReportChange,
  set_IsLocationReportChange,
};
export {
  LoadBuildingSvg,
  AddBuildingData,
  LoadIcononBuilding,
  AddBuildingLayer,
  ShowPopupManual,
};
export {
  LoadFloorSvg,
  AddFloorLayer,
  LoadIcononFloor,
  ShowFloorPopupManual,
  DrawLocation,
  RefreshIconDetails,
};
export {
  LoadTechnologyBuildingSvg,
  LoadTechnologyFloorSvg,
  redirecttohome,
  checkStaffData,
  getCheckInExtent,
  generateAlertMessage,
  AuthFailedMessage,
  ClearFormControlValue,
  SetFormControlValue,
  LoadTechnologyCampusSvg,
};
export {
  SessionCheck,
  SessionCheckwithStaff,
  getFeatureNeedtoShow,
  getAddEditDeleteColumn,
  CheckAlertByDeviceTypeId,
};
export {
  getNewFeature_Campus,
  get_selectedfeature_Campus,
  get_selectedpolygon_Campus,
  Checkpolygon_Intersect_Campus,
};
export {
  getNewFeature,
  get_selectedfeature,
  get_selectedpolygon,
  Checkpolygon_Intersect,
  IsCheckRoomDrawn,
  IsDevice_In_RoomDrawn,
  ClearStorageSpaceData,
  ClearStorageeData,
  getSelected,
  mouseOnFeature,
  calculateCenter,
  
};
export { LoadTechnologySpaceSvg, getAddEditColumn };
