<div >
  <div class="clsaddstaff">
      <div class="add-title">
        <h3 class="addHeader" style="margin: 0 0 10px 11px;">Staff Group
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>      
  </div>
  <div>         
      <form style="padding:24px" [formGroup]="staffGroupCreationForm" autocomplete="off" novalidate (ngSubmit)="createStaffGroup(staffGroupCreationForm.value)">
          
                    <mat-grid-list [cols]="columns" [rowHeight]="grouprowHeight">
          
                        <mat-grid-tile>
                            <label>Group Name</label>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="2">
                            <div class="text-inside" style="width:100%;">
                                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                                  <input matInput formControlName="groupname" maxlength="30" (keyup)="handleInput($event)" required>
                                  <mat-error *ngIf="staffGroupCreationForm.controls.groupname.hasError('required')">
                                    Please enter Group Name
                                  </mat-error>
                                  <mat-error *ngIf="staffGroupCreationForm.controls.groupname.hasError('pattern')">
                                    Please enter valid Group Name
                                </mat-error>
                                <mat-error *ngIf="staffGroupCreationForm.controls.groupname.hasError('maxlength')">
                                  Max length 30 characters only
                                </mat-error>
                                </mat-form-field>
                              </div>
                        </mat-grid-tile>
          
                        <mat-grid-tile>
                            <label>Description</label>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="2">
                            <div class="text-inside" style="width:100%;">
                                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                                  <input matInput formControlName="description" maxlength="30" required>
                                  <mat-error *ngIf="staffGroupCreationForm.controls.description.hasError('required')">
                                    Please enter Description
                                  </mat-error>
                                  <mat-error *ngIf="staffGroupCreationForm.controls.description.hasError('pattern')">
                                    Please enter valid Description
                                </mat-error>
                                <mat-error *ngIf="staffGroupCreationForm.controls.description.hasError('maxlength')">
                                  Max length 150 characters only
                                </mat-error>
                                </mat-form-field>
                              </div>
                        </mat-grid-tile> 
                </mat-grid-list>

                <mat-dialog-actions class="clsaction">                        
                    <button type="button" class="clsbtnGroupCancel" mat-raised-button (click)="onCloseCancel()">Cancel</button>
                    <button class="clsbtnGroupSave" mat-raised-button>Save</button>&nbsp;
                </mat-dialog-actions>
      </form>        
  </div>
</div>
