import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import { HostListener } from "@angular/core";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumFileNamelength,
  enumEnableDisable,
} from "../../../../../app-settings";
import {
  EditFacilityForm,
  TechnologyForCreationResponse,
} from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
  AuthFailedMessage,
} from "../../../../../app-general";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../../../../../api.service";
import { backupPhoto } from "../../../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

const URL = appsettings.URLinfo + "uploadfacilityImage";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-facility",
  templateUrl: "./edit-facility.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-facility.component.scss"],
})
export class EditFacilityComponent implements OnInit, OnDestroy {
  public facilityditForm: UntypedFormGroup;
  facilityimgname;
  oldimagename;
  facilityname;
  public watcher: Observable<boolean>;
  columns = 4;
  firstcolumns = 5;
  firstcolspan = 4;
  firstrowHeight = "190px";
  minheight = "285px";
  Imgurl: string = appsettings.Facility_IMAGE_FOLDER;
  facilityid;
  FImage;
  bAddEdit = false;
  bDelete = false;

  error: string;
  dragAreaClass: string;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditFacilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
    this.facilityimgname = data.FacilityImg;
    this.facilityname = data.FacilityName;
    this.facilityid = data.FacilityId;
    this.FImage = data.FacilityImg;    
  }
  
  breakpointChanged() {    
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Device_Map_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 3;
      this.firstcolumns = 5;
      this.firstcolspan = 4;
      this.firstrowHeight = "190px";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 5;
      this.firstcolspan = 4;
      this.firstrowHeight = "190px";
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 5;
      this.firstcolspan = 4;
      this.firstrowHeight = "90px";
      this.minheight = "auto";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.firstcolspan = 1;
      this.firstrowHeight = "60px";
      this.minheight = "auto";
    }     
  }
  
  @ViewChild("imageInput") imageInput: any;
  public onChangeeditfacility(fileList: FileList) {
    this.saveFiles(fileList);
  }

  saveFiles(fileList: FileList) {
    if (fileList.length > 1) {
      generateAlertMessage(this.dialog, "Only one file at time allowed");
      this.imageInput.nativeElement.value = "";
      return;
    }
    const file = fileList[0];   
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(removedSpaces_filename);
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      this.imageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      this.imageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
      file.name;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Provider Photo as image file."
      );
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0]);
      

      this.imageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");

          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 2) {
              // 1024 is 1MB
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 2 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Provider Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  uploadfiletoPath(data, file) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        this.facilityimgname = res.filename;
      });
  }

  backupPhoto(filename) {
    try {
      const fileitem: backupPhoto = {
        authKey: this.authkey,
        fileName: filename.toString(),
        isPath: "Facility",
      };
      this.apiService
        .backupPhoto(fileitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Response: any[]) => {
          console.log(Response);
        });
    } catch (err) {
      throw new Error(
        "edit-facility.component.ts" + " - backupPhoto() - " + err.message
      );
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    this.facilityditForm = new UntypedFormGroup({
      facilityname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      facilityimage: new UntypedFormControl(""),
    });
    this.dragAreaClass = "dragarea";
  }
  public onclickCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  public hasError = (controlName: string, errorName: string) => {
    this.facilityditForm.controls[controlName].hasError(errorName);
  };

  public editfacilitydetails = (facilityFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.facilityditForm.valid) {
        this.facilityEditEnableDisable(enumEnableDisable.Disable);
        this.updateFacilityInfo(facilityFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-campus.component.ts" + " - editfacilitydetails() - " + err.message
      );
    }
  };
  //Provider is the name of the site and contains the image of the site/facility
  private updateFacilityInfo = (facilityFormValue) => {
    try {
      this.facilityEditEnableDisable(enumEnableDisable.Disable);
      const updateFacility: EditFacilityForm = {
        authKey: this.authkey,
        facilityName: facilityFormValue.facilityname
          .replace(/\s+/g, " ")
          .trim(),
        facilityImage: this.facilityimgname.toString(),
        facilityId: parseInt(this.facilityid),
      };
      this.technologyoverlayService
        .updateFacilityInfo(updateFacility)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            if (this.facilityimgname != this.FImage)
              this.backupPhoto(this.FImage);
            generateAlertMessage(this.dialog, "Provider edited successfully.");
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Provider edited failed.");
            this.facilityEditEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close("close");
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.thisDialogRef.close();
        });
    } catch (err) {
      throw new Error(
        "facility.component.ts" + " - updateFacilityInfo() - " + err.message
      );
    }
  };

  facilityEditEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsavefacilityeditclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsfacilitybutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsfacilitybutton mat-raised-button disablebtn";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
