import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { duplicateBuilding } from "../../../technologyoverlay";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
  getFeatureNeedtoShow,
} from "../../../../../app-general";
import {
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumEnableDisable,
  appsettings,
} from "../../../../../app-settings";
import { ApiService } from "../../../../../api.service";
import { getCampus, getZone } from "../../../../../api";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-duplicate-building",
  templateUrl: "./duplicate-building.component.html",
  styleUrls: ["./duplicate-building.component.scss"],
})
export class DuplicateBuildingComponent implements OnInit {
  public buildDuplicateForm: UntypedFormGroup;
  campusInfo;
  builidngInfo;
  selectedCampus;
  columns = 4;
  firstcolumns = 2;
  minheight = "350px";
  rowHeight = "79px";
  buildingid;
  Zoneinfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  selectedAll: any;
  checkfloorinfo = false;
  checkfloorselectall = false;
  select_FloorIds = [] as any[];
  Campusdetails;
  selectedCampusId;
  floorcountchecked_id = 0;
  bAddEdit = false;
  bDelete = false;
  isCheckedAll = false;
  authkey;
  Breakpoints = Breakpoints;
  campusId = 0;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  public watcher: Observable<boolean>;
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<DuplicateBuildingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
      this.builidngInfo = data.BuildingData;
      this.campusInfo = data.CampusInfo;
      this.buildingid = this.builidngInfo[0].BuildId;   
      this.campusId = this.builidngInfo[0].CampusId;
      
      this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {        
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "350px";
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }     
  }
  
  

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    this.buildDuplicateForm = new UntypedFormGroup({
      campusID: new UntypedFormControl(""),
      buildID: new UntypedFormControl(""),
      buildingname: new UntypedFormControl(""),
      campus: new UntypedFormControl("", [Validators.required]),
      newbuildingname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]), // ^[a-zA-Z0-9!@#$%^& ]*$
      description: new UntypedFormControl("", [
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });

    const dupForm_buildingname = this.buildDuplicateForm.get("buildingname");
    if (dupForm_buildingname != null) dupForm_buildingname.disable();
    // this.buildDuplicateForm.get('campusname').disable();
    this.getCampusDetails();
    this.isCheckedAll = true;
    this.getZoneDetails();
  }

  getCampusDetails() {
    try {
      const campusid: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "duplicate-building.component.ts" +
          " - getCampusDetails() - " +
          err.message
      );
    }
  }

  changecampus_duplicate(campusid) {
    this.selectedCampusId = campusid;
  }

  getZoneDetails() {
    try {
      const buildbyid: getZone = {
        authKey: this.authkey,
        campusId:this.campusId,
        buildId: parseInt(this.buildingid),
        floorId : 0
      };
      this.apiService
        .getZoneDetails(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((floorinfo: any[]) => {
          this.Zoneinfo = floorinfo["apiReturn"];
          this.toggleAll(this.isCheckedAll);
        });
    } catch (err) {
      throw new Error(
        "duplicate-building.component.ts" +
          " - getZoneDetails() - " +
          err.message
      );
    }
  }

  toggleAll(event: any) {
    if (event.checked || event == true) {
      this.select_FloorIds = [];
      this.Zoneinfo.forEach((row) => {
        const checkedid = row.FloorId;
        this.select_FloorIds.push(checkedid);
      });
    } else {
      this.select_FloorIds = [];
      this.select_FloorIds.length = 0;
    }
    this.isCheckedAll = this.isChecked();
  }

  togglefloorVisibility(checkedid, event: MatCheckboxChange) {
    if (event.checked) {
      const index = this.select_FloorIds.indexOf(checkedid);
      if (index == -1) {
        this.select_FloorIds.push(checkedid);
      }
    } else {
      const index = this.select_FloorIds.indexOf(checkedid);
      if (index >= 0) {
        this.select_FloorIds.splice(index, 1);
      }
    }
    this.isCheckedAll = this.isChecked();
  }

  exists(checkedid) {
    return this.select_FloorIds.indexOf(checkedid) > -1;
  }

  isChecked() {
    return this.select_FloorIds.length === this.Zoneinfo.length;
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.buildDuplicateForm.controls).forEach((field) => {
        // {1}
        const control = this.buildDuplicateForm.get(field); // {2}
        const duplicateFormId = document.getElementById(event.target.id);
        if (duplicateFormId != null) {
          if (field == duplicateFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-campus.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public duplicatebuildDetails = (duplicatebuildformvalue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.buildDuplicateForm.valid) {
        this.duplicateBuilding(duplicatebuildformvalue);
      }
    } catch (err) {
      throw new Error(
        "duplicate-building.component.ts" +
          " - duplicatebuildDetails() - " +
          err.message
      );
    }
  };

  //Duplicate building window will show the selected building name and have the option to select the Campus name, New Building name, New building description. On the right side we can choose the Zones we want to have for the duplicating.
  duplicateBuilding(duplicatebuildformvalue) {
    try {
      // let saveclick = document.getElementById("btnduplicatesaveclick");
      if (this.select_FloorIds.length == 0 && this.Zoneinfo.length > 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Zone to create as a new Building"
        );
        // saveclick.className = 'clsdupbuildsavebutton mat-raised-button';
        this.buildingDuplicateEnableDisable(enumEnableDisable.Enable);
        return;
      }
      if (this.select_FloorIds.length == 0 && this.Zoneinfo.length == 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Zone to duplicate or create as a Template."
        );
        // saveclick.className = 'clsdupbuildsavebutton mat-raised-button';
        this.buildingDuplicateEnableDisable(enumEnableDisable.Enable);
        return;
      }

      // saveclick.className = 'clsdupbuildsavebutton mat-raised-button disablebtn';
      this.buildingDuplicateEnableDisable(enumEnableDisable.Disable);
      const duplicatebuild: duplicateBuilding = {
        authKey: this.authkey,
        campusId: parseInt(duplicatebuildformvalue.campusID),
        buildingId: parseInt(duplicatebuildformvalue.buildID),
        newCampusId: parseInt(this.selectedCampusId),
        newBuildingName: duplicatebuildformvalue.newbuildingname.toString(),
        newBuildingDescription: duplicatebuildformvalue.description.toString(),
        floorIds: this.select_FloorIds.toString(),
      };

      this.technologyoverlayService
        .duplicateBuilding(duplicatebuild)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["BuildingId"] > 0) {
            // saveclick.className = 'clsdupbuildsavebutton mat-raised-button';
            this.buildingDuplicateEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close(apiresponse["BuildingId"]);
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(
              this.dialog,
              "Building Duplicate added failed."
            );
            // saveclick.className = 'clsdupbuildsavebutton mat-raised-button';
            this.buildingDuplicateEnableDisable(enumEnableDisable.Enable);
            this.thisDialogRef.close("close");
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(
              this.dialog,
              "Building name '" +
                apiresponse[0].BuildingName +
                "' already exists."
            );
            // saveclick.className = 'clsdupbuildsavebutton mat-raised-button';
            this.buildingDuplicateEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "duplicate-building.component.ts" +
          " - duplicateBuilding() - " +
          err.message
      );
    }
  }

  buildingDuplicateEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnduplicatesaveclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsdupbuildsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsdupbuildsavebutton mat-raised-button disablebtn";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
