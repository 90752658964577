<app-technologyoverlay-header (onMapChanged)="OnMapclick()" (onSearchdevice)="searchDevicedetails($event)" (onresetSearchdevice)="resetDeviceFilters()"></app-technologyoverlay-header>
  <!-- <mat-card> -->
    <div *ngIf="deviceloading" style="display: table;
    position: absolute;
    height:100%; 
    width:100%;  
    z-index: 1000000;
    text-align: center;
">
<div style="display: table-cell;
    vertical-align: middle;">
    <div style="margin-left: calc(50% - 41px);
    
    color: White;">
       <div class="loadspinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
</div>
</div>
  <!-- </mat-card> -->
<div fxLayout="row">

  <div fxLayout="column">
    <app-technologyoverlay-leftbar (click)="CloseRightNav()"></app-technologyoverlay-leftbar>
  </div>  
  
  <div fxLayout="column" fxFlex="100">
    <div class="landing-title-bar" style="border-bottom:2px solid #d2d4d8;">
      <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutGap="5px" fxLayoutWrap style="display: inline-block;">
        <div fxFlex="15" fxFlex.md="20" class="clsdivflex" style="padding-top:5px;margin:2em;"> 
            <div class="clsdeviceTitle"><b>Total Number of Devices : </b>{{dataSourcecnt}} </div>              
         </div>
         
         <div fxLayout="row" fxFlex="75" fxLayoutAlign="end start" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column">
         
          <div class="clsdivDeviceselect"> 
            <label class="clslabelDevicefilter">Location</label>
          <mat-form-field  appearance="outline" floatLabel="auto">
          <mat-select  #select id="selected_InOutdoor" [(ngModel)]="selectedInOutdoor" placeholder="Indoor/Outdoor" multiple (click)="clkmatselectdata()">          
              <mat-option #allSelectedInOutdoor (click)="toggleAllSelectionInOutdoor()" [value]="-1">Select All</mat-option>
              <mat-option *ngFor="let InOutdoor of InOutdoordata" [value]="InOutdoor.Value" (click)="togglePerOneInOutdoor(InOutdoor.Value)">
                {{InOutdoor.InOutdoorName}}
              </mat-option>

          </mat-select>
        </mat-form-field>                
          
         </div>
         <div class="clsdivDeviceselect"> 
          <label class="clslabelDevicefilter">Device</label>
          <mat-form-field  appearance="outline" floatLabel="auto">
            <mat-select #selectDevices id="selected_Devices" [(ngModel)]="selectedDevices" placeholder="View Devices"  multiple (click)="clkmatdeviceselectdata()">                 
                <mat-option #allSelectedDeviceType (click)="toggleAllSelectionDeviceType()" [value]="-1">Select All</mat-option>
                <mat-option *ngFor="let Type of DeviceType" [value]="Type.DeviceTypeId" (click)="togglePerOneDeviceType()">
                  {{Type.DeviceType}}
                </mat-option>
        </mat-select>
        </mat-form-field>    
       </div>
       <div class="clsdivDeviceselect"> 
        <label class="clslabelDevicefilter">Device Status</label>
        <mat-form-field  appearance="outline" floatLabel="auto">
          <mat-select #selectstatus id="selected_Status" [(ngModel)]="selectedStatus" placeholder="Device Status" multiple (click)="clkmatstatusselectdata()">           
              <mat-option #allSelectedStatus (click)="toggleAllSelectionStatus()" [value]="-1">Select All</mat-option>
              <mat-option *ngFor="let Status of DeviceStatus" [value]="Status.DeviceStatusId" (click)="togglePerOneStatus()">
                {{Status.DeviceStatusName}}
              </mat-option>              
          </mat-select>
        </mat-form-field>    
        </div>
    </div>     
    
      </div>
      </div>
      
      <perfect-scrollbar style="height:90%;">        
        <div *ngIf="this.dataSourcecnt; else noRecord" class="clstablecontent">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" #devicesort="matSort" matSortDisableClear=true>                              

                 <ng-container matColumnDef="NewDeviceName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'"> Device </th> 
                      <td mat-cell *matCellDef="let element"> {{element.NewDeviceName}} </td>                      
                   </ng-container>

                 <ng-container matColumnDef="DeviceInfo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'"> Device ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.DeviceInfo}} </td>
                 </ng-container>

                  <ng-container matColumnDef="LforIr">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'">IR/LF/MAC/UT ID </th>                      
                      <td mat-cell *matCellDef="let element" style="text-align: center;"> 
                      <span *ngIf="element.NewDeviceName != _NewDeviceTypeDesc.HRex && element.NewDeviceName != _NewDeviceTypeDesc.Rex && element.NewDeviceName != _NewDeviceTypeDesc.Gateway && element.NewDeviceName != _NewDeviceTypeDesc.DC" >{{element.LforIr}}</span>
                      <span *ngIf="(element.NewDeviceName == _NewDeviceTypeDesc.Rex || element.NewDeviceName == _NewDeviceTypeDesc.Gateway) && (element.NewDeviceName != _NewDeviceTypeDesc.DC && element.NewDeviceName != _NewDeviceTypeDesc.HRex) " >{{element.MacId}}</span>
                      <span *ngIf="element.NewDeviceName == _NewDeviceTypeDesc.DC" >{{element.LforIr}}/{{element.DCMacId}}/{{element.dualutId}}</span>
                      <span *ngIf="element.NewDeviceName == _NewDeviceTypeDesc.HRex" >{{element.MacId}}</span></td>
                      
                        <!-- {{element.LforIr}} </td> -->
                  </ng-container>  
                  
                  <ng-container matColumnDef="CampusName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'">Campus </th>                      
                    <td mat-cell *matCellDef="let element"> 
                      <span *ngIf="element.CampusName.length < 15">{{element.CampusName}} </span>
                      <span *ngIf="element.CampusName.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.CampusName}} #tooltip="matTooltip" matTooltipPosition="above">
                        {{element.CampusName.slice(0,8) + ".."}}
                      </span>
                    </td>
                  </ng-container> 
                  
                  <ng-container matColumnDef="BuildingName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'">Building </th>                      
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.BuildingName.length < 15">{{element.BuildingName}} </span>
                      <span *ngIf="element.BuildingName.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.BuildingName}} #tooltip="matTooltip" matTooltipPosition="above">
                        {{element.BuildingName.slice(0,8) + ".."}}
                      </span>
                    </td>
                  </ng-container> 
                  
                  <ng-container matColumnDef="FloorName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'">Zone </th>                      
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.FloorName.length < 15">{{element.FloorName}} </span>
                      <span *ngIf="element.FloorName.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.FloorName}} #tooltip="matTooltip" matTooltipPosition="above">
                        {{element.FloorName.slice(0,8) + ".."}}
                      </span>
                    </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="UnitLocation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'">Space </th>                      
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.UnitLocation.length < 15">{{element.UnitLocation}} </span>
                      <span *ngIf="element.UnitLocation.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.UnitLocation}} #tooltip="matTooltip" matTooltipPosition="above">
                        {{element.UnitLocation.slice(0,8) + ".."}}
                      </span>
                    </td>
                  </ng-container>   
                                                 
                  <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'">Location </th>                      
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.Description.length < 15">{{element.Description}} </span>
                      <span *ngIf="element.Description.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.Description}} #tooltip="matTooltip" matTooltipPosition="above">
                        {{element.Description.slice(0,8) + ".."}}
                      </span>
                    </td>
                  </ng-container> 
                  
                  <ng-container matColumnDef="DeviceId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'">Completion </th>                      
                    <ng-container *matCellDef="let element">
                      <td mat-cell><div *ngIf="element.NewDeviceName != _NewDeviceTypeDesc.Gateway"><div [ngClass]="element.DeviceId > 0 ? 'clsdeviceGreen' : 'clsdeviceYellow'"></div></div>
                        <div *ngIf="element.DeviceId == 0 && element.NewDeviceName == _NewDeviceTypeDesc.Gateway" class="clsdeviceGreen"></div></td>                     
                    </ng-container>  
                  </ng-container> 
                  
                  <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'">Activity </th>                        
                    <ng-container *matCellDef="let element">
                      <td mat-cell *ngIf="element.Status==2"></td>
                      <td mat-cell *ngIf="element.Status==1">Online</td>
                       <td mat-cell *ngIf="element.Status==0" [ngStyle]="{'color':'Red'}">Offline</td>                     
                    </ng-container>                                        
                  </ng-container> 

                <ng-container matColumnDef="Edit" >
                    <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="padding-left: 24px;padding-right: 0px;"> Edit </th>
                    <ng-container *matCellDef="let element">                              
                        <td mat-cell ><button mat-button (click)="editDevicedetails(element)" >
                          <mat-icon *ngIf="element.NewDeviceName != _NewDeviceTypeDesc.Approx && element.NewDeviceName != _NewDeviceTypeDesc.Zone">edit</mat-icon></button> </td>
                   </ng-container>
                 </ng-container>                                  

                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              </table>
          </div>
      </perfect-scrollbar>
      
        <div style="width: 98%; float: right;bottom: 4em;position: relative;">
          <button [ngStyle]="{'display': dataSourcecnt > 0 ? 'block' : 'none'}" id="btndashboardexport" color="primary" class="clsDeviceExportbutton mat-raised-button" style="display: block;" (click)="exportDevicedata()">Export</button>
        </div>
      
        <ng-template #noRecord>  
             <mat-card>
               <h3 *ngIf="!resloading" style="text-align: center;"> No Record found </h3>   
               <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%;">
                <!-- <mat-spinner diameter="50" strokeWidth="5"></mat-spinner> -->
                <div class="loadspinner" *ngIf="resloading">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
              </div>        
             </mat-card>
        </ng-template>
      
  </div>

</div>
