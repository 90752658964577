<app-technologyoverlay-header (onOutdoorNavbarview)="Onnavbarclick($event)"></app-technologyoverlay-header>
<mat-card class="technologyoverlay-mat-card">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-technologyoverlay-leftbar></app-technologyoverlay-leftbar>
    </div>
    
    <div fxLayout="column" fxFlex="100" id="divoutdoordetails">
            
        
        <div class="clscampusoutdoor" id="Divmatcontent">
          
          <div fxLayout="row" class="clsoutdoorcnt" >            
            <span class="clsspanoutdoorcnt" id="divoutdoorcount" style="display: none;">Total Number of Spaces : {{dataSourcecount}}</span>
            <!-- <span class="clsspanoutdoorname" id="divoutdoorcampusName">{{selectout_Campusname}}
            <span id="outdoormouseovertext" style="margin-left:10em;"></span> </span> -->
          </div>
          
          <div fxLayout="row">
            
          <div class="clsdivoutdoorheader"> <img style="width:23px;height:23px;margin-right:6px;cursor: pointer;" mat-line src="assets/images/Home-Icon-Blue.png" (click)="clkHomeIcon()"></div>
          <div class="clsdivoutdoorheader"> <label>Campus:</label></div>
            <div fxLayout="column"  fxLayoutAlign="end">
              <div class="text-inside" style="width:100%;">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsoutmatformfield">
                  <mat-select placeholder="Select Campus" [(ngModel)]="selectout_CampusId" (selectionChange)="getBuildingDetails($event.value)">   
                  <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId" (click)="changecampus(Campus);">
                    {{Campus.CampusName}}
                  </mat-option>
                  </mat-select>
                  <mat-error>Please select Campus</mat-error>
                </mat-form-field>
              </div>              
            </div>  
            
            <div class="clsdivoutdoorheader"> <label>Building:</label></div>
            <div fxLayout="column"  fxLayoutAlign="end">
              <div class="text-inside" style="width:100%;">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsoutmatformfield">
                  <mat-select placeholder="Select Building" [(ngModel)]="selectout_BuildId" (selectionChange)="getZoneDetails($event.value)">    
                  <mat-option *ngFor="let Building of Buildingdetails" [value]="Building.BuildId" (click)="changebuilding(Building);">
                    {{Building.BuildingName}}
                  </mat-option>
                  </mat-select>
                  <mat-error>Please select Building</mat-error>
                </mat-form-field>
              </div>              
            </div>
            
            <div class="clsdivoutdoorheader"> <label>Zone:</label></div>
            <div fxLayout="column"  fxLayoutAlign="end">
              <div class="text-inside" style="width:100%;">
                <mat-form-field appearance="outline" floatLabel="auto" class="clsoutmatformfield">
                  <mat-select placeholder="Select Zone" [(ngModel)]="selectout_FloorId" (selectionChange)="loadSelectMapDetails()">    
                  <mat-option *ngFor="let Zone of Zonedetails" [value]="Zone.ZoneId" (click)="changefloor(Zone);">
                    {{Zone.ZoneName}}
                  </mat-option>
                  </mat-select>
                  <mat-error>Please select Zone</mat-error>
                </mat-form-field>
              </div>              
            </div>
                        
          </div>
          
        </div>
        <!-- <div style='width:100%;height: 90%;display: flex;'>
          <div id="map" class="map"></div>
        </div> -->
        
        <div id="divoutdoormapview" class="clsOutdivmainmap">
          <div id="map" class="map"></div>
          <div id="divCreateSpaceMenu" class="clsrightside_icon_popup" style="display:none; position: absolute; z-index: 9999999; background-color: #EFECEC; top: 177px; right: 8.4%; border: 1px solid #999999;">
            <div id="container" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor: pointer;" (click)="draw_outdoorrectangle()"  (mouseenter)="mouseoverrightsidemenu(this.Tech_Rectangle_Space_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Tech_Rectangle_Space_Outdoor)">
                <img class="clsmapimage" src="assets/images/RectangleSpace.png" /> <p style="text-align: center;">Rectangle</p>                       
            </div>
            <div id="container" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor: pointer;" (click)="draw_outdoorellipse()" (mouseenter)="mouseoverrightsidemenu(this.Ellipse_Space_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Ellipse_Space_Outdoor)">
              <img class="clsmapimage" src="assets/images/EllipseBuilding.png" /> <p style="text-align: center;">Ellipse</p>                       
            </div>
            <div id="container" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor: pointer;" (click)="draw_outdoorfreehand()" (mouseenter)="mouseoverrightsidemenu(this.FreeHand_Space_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.FreeHand_Space_Outdoor)">
                <img class="clsmapimage" src="assets/images/FreehandBuilding.png" /> <p style="text-align: center;">Freehand</p>                       
            </div>     
          </div>
          <div id="divEditSpaceMenu" class="clsrightside_icon_popup" style="display:none; position: absolute; z-index: 9999999; background-color: #EFECEC; top: 281px; right: 8.4%; border: 1px solid #999999;">
            <div id="container" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor: pointer;" (click)="Edit_outdoorPoints()"  (mouseenter)="mouseoverrightsidemenu(this.Edit_Point_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Edit_Point_Outdoor)">
                <img class="clsmapimage" src="assets/images/editpoints.png" /> <p style="text-align: center;">Edit Points</p>                       
            </div>
            <div id="container" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor:pointer;" (click)="Rotate_Points(0)"  (mouseenter)="mouseoverrightsidemenu(this.Rotate_Space_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Rotate_Space_Outdoor)" class="disablebtn">
              <img class="clsmapimage" src="assets/images/RotateBuilding.png" /> <p style="text-align: center;">Rotate</p>                       
            </div>
          
          </div>
          <div class="clsrightside_outicon" id="divrightsidebar">
              <div id="container_SelectMove" class= "enablebtn" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor: pointer;" (click)="select_outdoormove(undefined)" (mouseenter)="mouseoverrightsidemenu(this.Tech_Move_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Tech_Move_Outdoor)">
                  <img class="clsmapimage" src="assets/images/selectmove.png" /> <p style="text-align: center;">Move</p>                       
              </div>
              <div id="container_CreateSpace" class= "enablebtn" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor: pointer;" (click)="create_outdoorspace()"  (mouseenter)="mouseoverrightsidemenu(this.Tech_Create_Space_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Tech_Create_Space_Outdoor)">
                <img class="clsmapimage" src="assets/images/createspace.png" /> <p style="text-align: center;">Create Space</p>                       
              </div>
              <div id="container_EditSpace" class= "enablebtn" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor: pointer;" (click)="edit_outdoorspace()"  (mouseenter)="mouseoverrightsidemenu(this.Tech_Edit_Space_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Tech_Edit_Space_Outdoor)">
                  <img class="clsmapimage" src="assets/images/editbuilding.png" /> <p style="text-align: center;">Edit Space</p>                       
              </div>
              <div id="container_AddDevice" class= "disablebtn" style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor: pointer;" (click)="add_outdoorNewDevice(selectout_CampusId, selectout_Campusname)"   (mouseenter)="mouseoverrightsidemenu(this.Tech_Add_Device_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Tech_Add_Device_Outdoor)">
                <img class="clsmapimage" src="assets/images/adddevices.png" /> <p style="text-align: center;">Add Devices</p>                       
              </div>  
              <div id="container_DeleteSpace" class= "enablebtn" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor: pointer;" (click)="delete_outdoorspace()" (mouseenter)="mouseoverrightsidemenu(this.Tech_Delete_Space_Outdoor)" (mouseleave)="mouseleaverightsidemenu(this.Tech_Delete_Space_Outdoor)">
                <img class="clsmapimage" src="assets/images/deletebuilding.png" /> <p style="text-align: center;">Delete</p>                       
            </div>
          </div>
          
          </div>
          
          <div style='width:100%;height: 90%;display: none;' id="divoutdoorlistview">
            <perfect-scrollbar class="scrollbar">
              <div *ngIf="dataSourcecount; else noRecord">
                <table mat-table [dataSource]="dataSource" matSort matSortDisableClear=true>    
                  
                    <!-- Weight Column -->
                    <ng-container matColumnDef="UnitName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'"> Space </th>
                      <td mat-cell *matCellDef="let element"> 
                        <span *ngIf="element.UnitName.length < 30">{{element.UnitName}}</span>
                        <span *ngIf="element.UnitName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.UnitName}} #tooltip="matTooltip" matTooltipPosition="above">          
                          {{element.UnitName.slice(0,20) + ".."}}
                        </span>
                      </td>
                    </ng-container>
                  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="CampusName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'"> Campus </th>
                      <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.CampusName.length < 30">{{element.CampusName}}</span>
                        <span *ngIf="element.CampusName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.CampusName}} #tooltip="matTooltip" matTooltipPosition="above">          
                          {{element.CampusName.slice(0,20) + ".."}}
                        </span>
                          </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="BuildingName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'"> Building </th>
                      <td mat-cell *matCellDef="let element">
                        <span mat-cell *ngIf="element.BuildingName==''">-</span> 
                        <span *ngIf="element.BuildingName!='' && element.BuildingName.length < 30">{{element.BuildingName}}</span>
                        <span *ngIf="element.BuildingName!='' && element.BuildingName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.BuildingName}} #tooltip="matTooltip" matTooltipPosition="above">          
                          {{element.BuildingName.slice(0,20) + ".."}}
                        </span>
                          </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="FloorName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'"> Floor </th>
                      <td mat-cell *matCellDef="let element">
                        <span mat-cell *ngIf="element.FloorName==''">-</span> 
                        <span *ngIf="element.FloorName!='' && element.FloorName.length < 30">{{element.FloorName}}</span>
                        <span *ngIf="element.FloorName!='' && element.FloorName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.FloorName}} #tooltip="matTooltip" matTooltipPosition="above">          
                          {{element.FloorName.slice(0,20) + ".."}}
                        </span>
                          </td>
                    </ng-container>
                         
                    <!-- Weight Column -->
                    <ng-container matColumnDef="Accuracy">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'"> Accuracy </th>
                      <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.Accuracy == ''"></span>
                      <span *ngIf="element.Accuracy != ''">{{element.Accuracy}}%</span></td>
                    </ng-container>      
                    
                    <ng-container matColumnDef="Status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'headercell'"> Signature Status </th>
                      <td mat-cell *matCellDef="let element" >
                        <span *ngIf="element.Status == 0" class="applocatingdot" style="background-color: rgb(255, 0, 0);"></span>
                        <span *ngIf="element.Status == 1" class="applocatingdot" style="background-color: rgb(58, 223, 0);"></span>
                      </td>
                    </ng-container>
                    
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="edit">
                      <th mat-header-cell *matHeaderCellDef style="width:50px;" [ngClass]="'headercell'"> Edit </th>
                      <ng-container *matCellDef="let element">      
                      <td mat-cell><img src="assets/images/Edit_Icon.png" (click)="editOutdoorLocatingdata(element)"></td> 
                    </ng-container>
                    </ng-container>                                    
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              </div>
              </perfect-scrollbar>
              
          </div>  
          <ng-template #noRecord>  
            <mat-card>
                <h3 *ngIf="!outdoorloading" style="text-align: center;"> No Record found </h3> 
                <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%;">       
                    <div class="loadspinner" *ngIf="outdoorloading">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>
            </mat-card>
          </ng-template>
          
          <mat-paginator class="clsmatpaginator" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>                 
          
          <div id="divDeviceheader" style='height: 50px;'>
            <mat-card class="tech-mat-card"> 
              
      
            <div id="divAddDevice" fxLayout="row" fxLayout.sm="column" fxLayoutGap="0px" fxLayoutAlign="space-between left" style="float:right;">                                                                                 
      
              <div class="clsaligndiv" id="divbtnoutdoormap">                                                 
                    
                    <button color="primary" style="width: 100px;" id="btnsaveclickmapview" class="clsdeviceaddbutton" mat-raised-button  (click)="onSaveCampusSpace()" *ngIf="bAddEdit==true">Save</button>

                    <button id="btnEditSaveExists" color="primary" style="width: 100px;margin:0.5em;display:none;" mat-raised-button (click)="EditSaveExists()">Cancel</button>
                </div>
            </div>
            <mat-divider></mat-divider>    
            </mat-card>
          </div>
              
    </div>
        
      
  </div>
  
</mat-card>
