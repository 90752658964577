import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import {
  addDeviceforFloor,
  TechnologyForCreationResponse,
  EditDeviceforFloor,
  DeleteDevice,
  getEmitterDetsils,
  getSiteAuthKeyReuest,
  checkDeviceAlert,
  checkLocationAlert,
  getRoomCategory,
} from "../../technologyoverlay";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import {
  DeviceTypeDesc,
  DeviceType,
  NewDeviceTypeDesc,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  appsettings,
  Device_action,
  IRIdRange,
  LFIdRange,
  SecurityControllerIdRange,
  isNumeric,
  SecurityController_LFIdRange,
} from "../../../../app-settings";
import {
  
  getboxFromYpos,
  convertToSvgEcllipseXCoordinate,
  convertToSvgEcllipseYCoordinate,
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
  getFeatureNeedtoShow,
} from "./../../../../app-general";
import {
  
  
  AddMarkers,
  get_VectorSource,
  getDeviceTypeNew,
} from "./../../../../map-general";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import { Router } from "@angular/router";
import OlPoint from "ol/geom/Point";
import OlMap from "ol/Map";
import OlVectorLayer from "ol/layer/Vector";
import OlFeature from "ol/Feature";
import { Draw, Modify } from "ol/interaction";
import { Vector as VectorSource } from "ol/source";
import { Style } from "ol/style";
import OlCollection from "ol/Collection";
import { AlertSharedService } from "../../../../_services/index";
import { ShareddialogcloseData, MapSyncProcess } from "../../../../api";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { ConfirmMessageComponent } from "../../../../confirm-message/confirm-message.component";
import { ApiService } from "../../../../api.service";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-device",
  templateUrl: "./edit-device.component.html",
  providers: [TechnologyoverlayService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-device.component.scss"],
})
export class EditDeviceComponent implements OnInit, OnDestroy {
  bAddEdit = false;
  bDelete = false;
  DeviceTypeName: string;
  olddeviceId;
  oldemiterId;
  selectdevice = 0;
  getpolypoints = 0;
  checkpolygonpoints = 1;
  roomdraw = 0;
  image_path = "assets/images/NewDevice/";
  isSnapGrid = false;
  firstcolumns = 2;
  getcolumnrow = "row";
  public watcher: Observable<boolean>;
  columns = 4;
  DCTColums = 3;
  minheight = "600px";
  devicerowHeight = "64px";
  deviceinputrowHeight = "75px";
  screenWidth;
  ShareddialogcloseDetails: ShareddialogcloseData;
  oldLocationName;

  emitterImgUrl_inactive =
    this.image_path + "Technology Overlay-Device-Emitter-Gray.png";
  HREXImgUrl_inactive =
    this.image_path + "Technology Overlay-Device-H-REX-Gray.png";
  REXImgUrl_inactive =
    this.image_path + "Technology Overlay-Device-REX-Gray.png";
  LFImgUrl_inactive = this.image_path + "Technology Overlay-Device-LF-Gray.png";
  RoomImgUrl_inactive =
    this.image_path + "Technology Overlay-Device-Drawroom-Gray.png";
  FloorPlanImgUrl_inactive =
    this.image_path + "Technology Overlay-Device-Floorplans-Undo-Gray.png";
  SnapGridImgUrl_inactive =
    this.image_path + "Technology Overlay-Device-Snap to Grid-Gray.png";
  Pullcord_inactive = this.image_path + "Alerts-Pull Cord_Gray.png";
  DCImgUrl_inactive = this.image_path + "Technology Overlay-Device-DC-Gray.png";
  MonitorImgUrl_inactive = this.image_path + "3x_MMMonitor.png";
  VWImgUrl_inactive = this.image_path + "3x_VW.png";
  GatewayImgUrl_inactive = this.image_path + "gateway.png";
  DCTImgUrl_inactive =
    this.image_path + "Technology Overlay-Device-DCT-Gray.png";
  UMImgUrl_inactive = this.image_path + "3x_MMMonitor.png";

  emitterImgUrl_active =
    this.image_path + "Technology Overlay-Device-Emitter-Lime Green.png";
  HREXImgUrl_active =
    this.image_path + "Technology Overlay-Device-H-REX-Purple.png";
  REXImgUrl_active = this.image_path + "Technology Overlay-Device-REX-Cyan.png";
  LFImgUrl_active = this.image_path + "Technology Overlay-Device-LF-Orange.png";
  RoomImgUrl_active =
    this.image_path + "Technology Overlay-Device-Drawroom-Teal.png";
  FloorPlanImgUrl_active =
    this.image_path + "Technology Overlay-Device-Undo-Teal.png";
  SnapGridImgUrl_active =
    this.image_path + "Technology Overlay-Device-Snap to Grid-Teal.png";
  Pullcord_active = this.image_path + "Alerts-Pull Cord_Red.png";
  DCImgUrl_active = this.image_path + "Technology Overlay-Device-DC-Orange.png";
  MonitorImgUrl_active = this.image_path + "Monitor_Selected.png";
  VWImgUrl_active = this.image_path + "virtualWall.png";
  GatewayImgUrl_active = this.image_path + "gateway_selected.png";
  DCTImgUrl_active =
    this.image_path + "Technology Overlay-Device-DCT-Orange.png";
  UMImgUrl_active = this.image_path + "Monitor_Selected.png";

  drawRoomImgUrl = this.RoomImgUrl_inactive;
  undoRoomImgUrl = this.FloorPlanImgUrl_inactive;
  snapRoomImgUrl = this.SnapGridImgUrl_inactive;
  emitterImgUrl = this.emitterImgUrl_inactive;
  HREXImgUrl = this.HREXImgUrl_inactive;
  REXImgUrl = this.REXImgUrl_inactive;
  LFImgUrl = this.LFImgUrl_inactive;
  RoomImgUrl = this.RoomImgUrl_inactive;
  FloorPlanImgUrl = this.FloorPlanImgUrl_inactive;
  pullcordImgUrl = this.Pullcord_inactive;
  DCImgUrl = this.DCImgUrl_inactive;
  MonitorUrl = this.MonitorImgUrl_inactive;
  VWUrl = this.VWImgUrl_inactive;
  GatewayUrl = this.GatewayImgUrl_inactive;
  DCTImgUrl = this.DCTImgUrl_inactive;
  UMImgUrl = this.UMImgUrl_inactive;

  public deviceeditForm: UntypedFormGroup;
  public deviceaddForm: UntypedFormGroup;
  UpdateMode = 0;
  Roomname;
  roomcoordinates;
  imageHeight;
  imageWidth;
  imageExtent;

  Map_image_path = "assets/images/";
  DeviceX;
  DeviceY;
  olddevicename;
  oldrexmacid;
  getdeviceTypename;
  public isRedrawRoom = false;
  public isPlaceDevice = false;
  public isRedrawDevice = false;
  unitInfo = [];
  editselectedunit;
  deviceTypeName;
  map = new OlMap();
  EmitterimgDisabled;
  HReximgDisabled;
  ReximgDisabled;
  LFimgDisabled;
  PullcordimgDisabled;
  DCimgDisabled;
  MonitorDisabled;
  VWDisabled;
  gatewayimgDisabled;
  DCTimgDisabled;
  HRexMacId;
  GatewayMacId;
  DCTId;
  checkDCTag = false;
  checkDCLotiering = false;
  checkDCUnauthorizedEgress = false;

  oldcheckDCTag;
  oldcheckDCLotiering;
  oldcheckDCUnauthorizedEgress;

  getgatewaymacid;
  disableDCLotiering = true;
  disableDCUnauthorizedEgress = true;
  oldDcDualid;
  oldDCMacid;
  getHRexMacId;
  NewRexMacId;
  NewDeviceId;
  NewDCMacId;
  oldSubType;
  oldUnitId;
  checkPCRemovalDetection = false;
  checkPCButtonPress = false;
  checkPullCord = false;
  checkPCMobilityMode = "0";

  oldcheckPCMobilityMode;

  oldcheckPCRemovalDetection;
  oldcheckPCButtonPress;
  oldcheckPullCord;

  checkDCT_Tag1 = false;
  checkDCT_Tag2 = false;
  checkDCT_Tag3 = false;

  oldcheckDCT_Tag1;
  oldcheckDCT_Tag2;
  oldcheckDCT_Tag3;

  edit_selectedAlertState1 = 0;
  edit_selectedAlertState2 = 0;
  edit_selectedAlertState3 = 0;

  oldedit_selectedAlertState1;
  oldedit_selectedAlertState2;
  oldedit_selectedAlertState3;

  checkDCT_Ambient1 = false;
  checkDCT_Ambient2 = false;

  oldcheckDCT_Ambient1;
  oldcheckDCT_Ambient2;

  selectdefaultDCTMode = "0";

  oldOverTemp;
  oldUnderTemp;

  oldalertMessage1;
  oldalertMessage2;
  oldalertMessage3;

  oldambientOverMessage;
  oldambientUnderMessage;

  checkDCT_Probe1 = false;
  checkDCT_Probe2 = false;

  oldcheckDCT_Probe1 = false;
  oldcheckDCT_Probe2 = false;

  checkDCT_Humidity1 = false;
  checkDCT_Humidity2 = false;

  oldcheckDCT_Humidity1 = false;
  oldcheckDCT_Humidity2 = false;

  oldProbeOverTemp;
  oldProbeUnderTemp;

  oldHumidityOverTemp;
  oldHumidityUnderTemp;

  oldprobeOverMessage;
  oldprobeUnderMessage;

  oldhumidityOverMessage;
  oldhumidityUnderMessage;

  DCT_Id;

  UMimgDisabled;
  selectCategoryId;
  addselectCategoryId;
  MapSyncProcess;
  IsIndoor = 0;

  //Draw feature for Room
  vector = new OlVectorLayer();

  source = new VectorSource({ wrapX: false });

  vectorLayer = new OlVectorLayer({
    source: this.source,
  });
  draw = new Draw({
    source: this.source,
    type: "Polygon",
  });

  //Draw feature for Devices
  tempVectorSource = new VectorSource();

  tempVectorLayer = new OlVectorLayer({
    source: this.tempVectorSource,
  });
  edit_DragVectorLayer;
  edit_DragInteraction;

  old_data = [] as any[];
  new_data = [] as any[];

  overTempflag = 0;
  probeoverTempflag = 0;
  humidityoverTempflag = 0;
  select_DeviceType;
  editDeviceLocationName;
  addDeviceLocationName;
  DeviceImageURL;
  RoomCategorydetails;
  deleteFlag;
  deviceLocation;

  deletedevicedialogopen = 0;
  getUnitName = "";

  AddDeviceFlag;

  strLocationName: string;
  strRoomName: string;
  strDeviceId: number;
  strEmitterId: number;
  selectedUnit = 0;
  HRexId;
  GatewayId;
  strLFId;
  strDeviceDualId;
  strDCMacId;
  strLocation;
  strMacId;
  addfloorimageheight = 0;

  selectdefaultMode = "0";
  addDeviceName;

  selectedAlertState1 = 0;
  selectedAlertState2 = 0;
  selectedAlertState3 = 0;
  Polypoints;
  Device_X;
  Device_Y;
  Device_text;
  addGatewayId = 0;
  addRouterId = 1;
  getRexvalue;
  addData_Id;
  addLocDescription;

  public showHrex = false;
  public showGateway = false;

  deviceAddboolvalue = true;

  toDisplay = false;
  edit_roomcategory;
  edit_devicelocation;
  authkey;
  alertMessage_input1;
  alertMessage_input2;
  alertMessage_input3;  
  ambientOverDesc;
  ambientOverMessage;
  ambientUnderDesc;
  ambientUnderMessage;
  probeOverDesc;
  probeOverMessage;
  probeUnderDesc;
  probeUnderMessage;
  humidityOverDesc;
  humidityOverMessage;
  humidityUnderDesc;
  humidityUnderMessage;
  clearRouterDetails;
  selectRouterMacId;
  selectRouterId;
  addUpdateMode = 0;
  hrexCount = 0;
  hrexMaxCount;
  checkHRexvalue;
  checkgateway;
  LFDeviceType: any[] = [{ Type: 'Exciter', Value: 1 }, { Type: 'Hand Washing Monitor - Drip Tray', Value: 2 }];
  selectedLFDeviceType = 1;
  temperatureScale;
  ambientTemperatureText = "";
  _egressenablehourmin1 = false;
  _egressenablehourmin2 = false;
  _egressenablehourmin3 = false;
  checkallinfo1 = false;
  checkallinfo2 = false;
  checkallinfo3 = false;
  data_startTime1;
  data_endTime1;
  data_startTime2;
  data_endTime2;
  data_startTime3;
  data_endTime3;
  checkLFDeviceType = false;
  public Curfew_data = [] as any[];
  checkRouterdata = 0;
  routerCount = 0;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      "(min-width: 500px)",
    ])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyService: TechnologyoverlayService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private obj_document: any,
    @Inject(RollbarService) private rollbar: Rollbar,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {      
      this.breakpoint$.subscribe(() => this.breakpointChanged());
      this.getCurfew_data();
      if (data.AddDeviceflag == 0) {
        this.AddDeviceFlag = data.AddDeviceflag;
        this.old_data[0] = data.DeviceData;
        this.checkLFDeviceType = true;
        this.new_data[0] = JSON.parse(JSON.stringify(data.DeviceData));
        if(this.new_data[0].DeviceName == DeviceTypeDesc.HRex)
        {
          if(this.new_data[0].DeviceTypeId != this.new_data[0].MacId)
            this.HRexMacId = this.new_data[0].DeviceTypeId;
          else
            this.HRexMacId = this.new_data[0].MacId;
        }
        this.Device_text = "Edit Device";
        if (this.new_data[0].DeviceName == DeviceTypeDesc.Emitter) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
          this.new_data[0]["DeviceType"] = DeviceType.Emitter;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.HRex) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.HRex;
          this.new_data[0]["DeviceType"] = DeviceType.HRex;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Rex) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Rex;
          this.new_data[0]["DeviceType"] = DeviceType.Rex;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.LF) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.LF;
          this.new_data[0]["DeviceType"] = DeviceType.LF;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Pullcord) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
          this.new_data[0]["DeviceType"] = DeviceType.Pullcord;
        } else if (
          this.new_data[0].DeviceName == DeviceTypeDesc.DC ||
          this.new_data[0].DeviceName == DeviceTypeDesc.NewDC
        ) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.DC;
          this.new_data[0]["DeviceType"] = DeviceType.DC;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Monitor) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
          this.new_data[0]["DeviceType"] = DeviceType.Monitor;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.VW) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.VW;
          this.new_data[0]["DeviceType"] = DeviceType.VW;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Gateway) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
          this.new_data[0]["DeviceType"] = DeviceType.Gateway;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.UT) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.UT;
          this.new_data[0]["DeviceType"] = DeviceType.UT;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.UM) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.UM;
          this.new_data[0]["DeviceType"] = DeviceType.UM;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Intercom) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Intercom;
          this.new_data[0]["DeviceType"] = DeviceType.IC;
        } else {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Zone;
          this.new_data[0]["DeviceType"] = DeviceType.Zone;
        }

        if (this.new_data[0].FloorId > 0) {
            this.deviceLocation = this.new_data[0].Description;
          this.getUnitName = this.new_data[0].UnitName;
          if (this.getUnitName == undefined)
            this.getUnitName = this.new_data[0].UnitLocation;

          this.editDeviceLocationName =
            this.getUnitName +
            "," +
            this.new_data[0].FloorName +
            "," +
            this.new_data[0].BuildingName +
            "," +
            this.new_data[0].CampusName;
          if (this.new_data[0].ResRoomCategoryId > 0)
            this.selectCategoryId = this.new_data[0].ResRoomCategoryId;

          if (this.new_data[0].residentRoomCategoryid > 0)
            this.selectCategoryId = this.new_data[0].residentRoomCategoryid;

          this.IsIndoor = 1;
        } else {
         
            this.deviceLocation = this.new_data[0].Description;
         
          if (this.new_data[0].ResRoomCategoryId > 0)
            this.selectCategoryId = this.new_data[0].ResRoomCategoryId;
          if (this.new_data[0].residentRoomCategoryid > 0)
            this.selectCategoryId = this.new_data[0].residentRoomCategoryid;

          this.getUnitName = this.new_data[0].UnitName;
          if (this.getUnitName == undefined)
            this.getUnitName = this.new_data[0].UnitLocation;

          this.editDeviceLocationName =
            this.getUnitName + "," + this.new_data[0].CampusName;
          this.IsIndoor = 0;
        }

        this.DeviceX = this.new_data[0].DeviceX;
        this.DeviceY = this.new_data[0].DeviceY;

        this.olddeviceId = this.new_data[0].DeviceId;
        this.oldDcDualid = this.new_data[0].dualutId;
        this.deviceTypeName = this.new_data[0].DeviceName; 
        this.olddevicename = this.new_data[0].DeviceName;
        this.oldLocationName = this.new_data[0].Location;
        this.oldSubType = this.new_data[0].SubType;
        this.oldUnitId = this.new_data[0].UnitId;

       if(this.new_data[0].DeviceName == DeviceTypeDesc.Rex)
        {
          if (this.new_data[0].DeviceId == 0) {               
            this.NewRexMacId = this.new_data[0].DeviceTypeId.trim();
            this.oldrexmacid = 0;
          } else {
            this.NewRexMacId = this.new_data[0].DeviceTypeId.trim();
            this.oldrexmacid = this.new_data[0].DeviceTypeId.trim();
          }
        }
        else if(this.new_data[0].DeviceTypeId == DeviceType.DripTray && this.new_data[0].DeviceName == DeviceTypeDesc.LF)
        {
          this.oldrexmacid = this.new_data[0].DeviceInfo;
        }
        else 
          this.oldrexmacid = this.new_data[0].DeviceTypeId;

        if (this.new_data[0].DeviceId == 0) {
          this.NewDCMacId = "";
          this.oldDCMacid = "";
        } else {
          this.NewDCMacId = this.new_data[0].DCMacId;
          this.oldDCMacid = this.new_data[0].DCMacId;
        }
        this.Roomname = this.new_data[0].Location;

        if (this.new_data[0].DeviceName == "UT") {
          this.select_DeviceType = "Universal Transmitter";
          this.minheight = "600px";
        } else {
          this.select_DeviceType = this.new_data[0].NewDeviceName;
          this.minheight = "450px";
        }

        this.DeviceImageURL = this.getDeviceImageURL(
          this.new_data[0].DeviceName
        );
        this.toDisplay = false;
        if (this.new_data[0].DeviceTypeId !== "") {
          if (
            this.new_data[0].DeviceName == "Emitter" ||
            this.new_data[0].DeviceName == "Monitor" ||
            this.new_data[0].DeviceName == "VW" ||
            this.new_data[0].DeviceName == "Universal Monitor"
          ) {
            this.DeviceTypeName = "IR Id";
            this.toDisplay = true;
          } else if (this.new_data[0].DeviceName == "Rex") {
            this.DeviceTypeName = "Mac Id";
          } 
          else if (this.new_data[0].DeviceName == "LF") {
            this.DeviceTypeName = "LF Id";
            this.toDisplay = true;
            this.select_DeviceType = NewDeviceTypeDesc.LFExiter;
            if(this.new_data[0].SubType == DeviceType.DripTray)
            {
              this.selectedLFDeviceType = 2;
              this.select_DeviceType = NewDeviceTypeDesc.DripTray;
            }
          } 
          else if (this.new_data[0].DeviceName == "DC") {
            this.DeviceTypeName = "Security Controller Id";
            this.deviceLocation = this.new_data[0].SubType;
          } else if (this.new_data[0].DeviceName == "Gateway") {
            this.GatewayMacId = this.new_data[0].MacId;
          }
        }
        if (this.new_data[0].DeviceName == "UT") {
          this.columns = 4;
          this.deviceLocation = this.new_data[0].Description;
        }
        if (
          this.new_data[0].DeviceName == DeviceTypeDesc.Pullcord ||
          this.new_data[0].DeviceName == DeviceTypeDesc.DC ||
          this.new_data[0].DeviceName == DeviceTypeDesc.NewDC ||
          this.new_data[0].DeviceName == DeviceTypeDesc.UT
        )
          this.setDeviceDetails_Edit();
      } 
      else if (data.AddDeviceflag == 1) {
        this.Device_text = "Add Device";
        this.AddDeviceFlag = data.AddDeviceflag;
        this.old_data[0] = data.DeviceData;
        this.new_data[0] = JSON.parse(JSON.stringify(data.DeviceData));

        this.DeviceImageURL = this.getDeviceImageURL(
          this.new_data[0].DeviceName
        );
        this.addLocDescription = this.new_data[0].UnitName;

        if (this.new_data[0].FloorId > 0) {
          this.addDeviceLocationName =
            this.new_data[0].UnitName +
            "," +
            this.new_data[0].FloorName +
            "," +
            this.new_data[0].BuildingName +
            "," +
            this.new_data[0].CampusName;
          this.IsIndoor = 1;
        } else {
          this.addDeviceLocationName =
            this.new_data[0].UnitName + "," + this.new_data[0].CampusName;
          this.IsIndoor = 0;
        }
        this.addDeviceName = this.new_data[0].DeviceName;
        this.deviceTypeName = this.new_data[0].DeviceName; 
        this.Polypoints = this.new_data[0].PolyPoints;
        this.Device_X = this.new_data[0].DeviceX;
        this.Device_Y = this.new_data[0].DeviceY;
        this.addData_Id = this.new_data[0].DataId;
        this.checkHRexvalue = data.HRexvalue;
        this.checkgateway = data.Gatewayvalue;

        if (this.new_data[0].MaxRexValue == null) this.getRexvalue = 2;
        else if (this.new_data[0].MaxRexValue < 200)
          this.getRexvalue = this.new_data[0].MaxRexValue + 1;
        else this.getRexvalue = "";

        this.toDisplay = false;
        if (this.new_data[0].DeviceName == DeviceTypeDesc.Emitter) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
          this.DeviceTypeName = "IR Id";
          this.toDisplay = true;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.HRex) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.HRex;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Rex) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Rex;
          this.DeviceTypeName = "Mac Id";
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.LF) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.LF;
          this.DeviceTypeName = "LF Id";
          this.toDisplay = true;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Pullcord) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
        } else if (
          this.new_data[0].DeviceName == DeviceTypeDesc.DC ||
          this.new_data[0].DeviceName == DeviceTypeDesc.NewDC
        ) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.DC;
          this.DeviceTypeName = "Security Controller Id";
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Monitor) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
          this.DeviceTypeName = "IR Id";
          this.toDisplay = true;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.VW) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.VW;
          this.DeviceTypeName = "IR Id";
          this.toDisplay = true;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Gateway) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
          this.GatewayMacId = this.new_data[0].MacId;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.UT) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.UT;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.UM) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.UM;
          this.DeviceTypeName = "IR Id";
          this.toDisplay = true;
        } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Intercom) {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Intercom;
        } else {
          this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Zone;
        }

        if (this.new_data[0].DeviceName == "UT") {
          this.select_DeviceType = "Universal Transmitter";
          this.minheight = "600px";
          this.temperatureScale = localStorage.getItem("temperatureScale");        
          if(this.temperatureScale == "1")      
            this.ambientTemperatureText = "F";
          else if(this.temperatureScale == "2")      
            this.ambientTemperatureText = "C"; 
        } 
        else if(this.new_data[0].DeviceName == "LF")
        {
          if(this.new_data[0].SubType == DeviceType.DripTray)
          {
            this.select_DeviceType = NewDeviceTypeDesc.DripTray
          }
          else
          {
            this.select_DeviceType = NewDeviceTypeDesc.LFExiter
          }
        }
        else {
          this.select_DeviceType = this.new_data[0].NewDeviceName;
          this.minheight = "450px";
        }
        // this.getHRexdetails();
      }
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - constructor() - " + err.message
      );
    }
  }

  breakpointChanged() {
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    if (
      this.breakpointObserver.isMatched(Breakpoints.Large) ||
      this.breakpointObserver.isMatched(Breakpoints.Medium)
    ) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.DCTColums = 3;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
      this.firstcolumns = 1;
      this.DCTColums = 1;
    } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 4;
      this.firstcolumns = 1;
      this.DCTColums = 1;
    }
  }
  
  getCurfew_data() {
    let _curfewdata = {};
    const _curfew_data_arr = [] as any[];
    let hourtext = "";
    let _hour = "";
    let _min = "";
    let min_inc = 30;
    for (let hour = 0; hour <= 24; hour++) {
      if (hour < 12) {
        _hour = "0" + hour.toString();
        hourtext = " am";
      } else if (hour == 24) {
        _hour = (hour - 12).toString();
        hourtext = " am";
        min_inc = 60;
      } else if (hour > 12) {
        _hour = "0" + (hour - 12).toString();
        hourtext = " pm";
      } else if (hour == 12) {
        _hour = hour.toString();
        hourtext = " pm";
      }
      console.log(min_inc)
      _hour = _hour.slice(-2);
      // for (let min = 0; min < 60; min = min + min_inc) {
      //   _min = "0" + min.toString();
      //   _min = _min.slice(-2);
      _min = "00";
        _curfewdata = {
          Time: _hour + ":" + _min + hourtext,
          Value: hour + ":" + _min,
        };
        _curfew_data_arr.push(_curfewdata);
      // }
    }
    this.Curfew_data = _curfew_data_arr;
  }

  getDeviceImageURL(deviceType) {
    let ImageURL;

    if (deviceType == DeviceTypeDesc.Gateway)
      ImageURL = this.image_path + "GatewayEnclosure.png";
    else if (deviceType == DeviceTypeDesc.HRex)
      ImageURL = this.image_path + "ClearRouter.png";
    else if (deviceType == DeviceTypeDesc.Rex)
      ImageURL = this.image_path + "ClearRepeater.png";
    else if (
      deviceType == DeviceTypeDesc.DC ||
      deviceType == DeviceTypeDesc.NewDC
    )
      ImageURL = this.image_path + "SecurityController.png";
    else if (deviceType == DeviceTypeDesc.Emitter)
      ImageURL = this.image_path + "TruLocator.png";
    else if (deviceType == DeviceTypeDesc.Monitor)
      ImageURL = this.image_path + "CommonArea Locator.png";
    else if (deviceType == DeviceTypeDesc.VW)
      ImageURL = this.image_path + "VirtualWallLocator.png";
    else if (deviceType == DeviceTypeDesc.LF)
      ImageURL = this.image_path + "LFAntenna.png";
    else if (deviceType == DeviceTypeDesc.Pullcord)
      ImageURL = this.image_path + "PullCord.png";
    else if (deviceType == DeviceTypeDesc.UT)
      ImageURL = this.image_path + "UniversalTransmitter.png";
    else if (deviceType == DeviceTypeDesc.UM)
      ImageURL = this.image_path + "CommonArea Locator.png";
    else if (deviceType == DeviceTypeDesc.Intercom)
      ImageURL = this.image_path + "Intercom.png";

    return ImageURL;
  }

  setDeviceDetails_Edit() {
    if (this.new_data[0].DeviceName == DeviceTypeDesc.Pullcord) {
      if (this.new_data[0].isremovaldetection == 1)
        this.checkPCRemovalDetection = true;
      else if (this.new_data[0].isremovaldetection == 0)
        this.checkPCRemovalDetection = false;

      if (this.new_data[0].isbuttonpress == 1) this.checkPCButtonPress = true;
      else if (this.new_data[0].isbuttonpress == 0)
        this.checkPCButtonPress = false;

      if (this.new_data[0].ispullcord == 1) this.checkPullCord = true;
      else if (this.new_data[0].ispullcord == 0) this.checkPullCord = false;

      this.oldcheckPCRemovalDetection = this.checkPCRemovalDetection;
      this.oldcheckPCButtonPress = this.checkPCButtonPress;
      this.oldcheckPullCord = this.checkPullCord;

      if (this.new_data[0].ismobilitymode != undefined)
        this.checkPCMobilityMode = this.new_data[0].ismobilitymode.toString();

      this.oldcheckPCMobilityMode = this.checkPCMobilityMode;
    } else if (
      this.new_data[0].DeviceName == DeviceTypeDesc.DC ||
      this.new_data[0].DeviceName == DeviceTypeDesc.NewDC
    ) {
      if (this.new_data[0].dualutId == 0) {
        this.new_data[0].dualutId = "";
      }

      if (this.new_data[0].dualutId == "") {
        this.checkDCLotiering = false;
        this.checkDCUnauthorizedEgress = false;
        this.disableDCLotiering = true;
        this.disableDCUnauthorizedEgress = true;
      } else {
        this.disableDCLotiering = false;
        this.disableDCUnauthorizedEgress = false;
      }

      if (this.new_data[0].istaginfield == 1) {
        this.checkDCTag = true;
      } else this.checkDCTag = false;

      if (this.new_data[0].islotiering == 1) {
        this.checkDCLotiering = true;
      } else this.checkDCLotiering = false;

      if (this.new_data[0].isunauthorizedegerss == 1) {
        this.checkDCUnauthorizedEgress = true;
      } else this.checkDCUnauthorizedEgress = false;

      this.oldcheckDCTag = this.checkDCTag;
      this.oldcheckDCLotiering = this.checkDCLotiering;
      this.oldcheckDCUnauthorizedEgress = this.checkDCUnauthorizedEgress;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.UT) {
      this.DCT_Id = this.new_data[0].Id;

      if (this.new_data[0].dinput1 == 1) 
      {
        this.checkDCT_Tag1 = true;
        this._egressenablehourmin1 = true;
      }
      else 
      {
        this.checkDCT_Tag1 = false;
        this._egressenablehourmin1 = false;
      }
      
      if (this.new_data[0].dinput2 == 1) 
      {
        this.checkDCT_Tag2 = true;
        this._egressenablehourmin2 = true;
      }
      else 
      {
        this.checkDCT_Tag2 = false;
        this._egressenablehourmin2 = false;
      }
      
      if (this.new_data[0].dinput3 == 1) 
      {
        this.checkDCT_Tag3 = true;
        this._egressenablehourmin3 = true;
      }
      else 
      {
        this.checkDCT_Tag3 = false;
        this._egressenablehourmin3 = false;
      }  

      if (this.new_data[0].ambientOverTemp == 1) this.checkDCT_Ambient1 = true;
      else this.checkDCT_Ambient1 = false;

      if (this.new_data[0].ambientUnderTemp == 1) this.checkDCT_Ambient2 = true;
      else this.checkDCT_Ambient2 = false;

      if (this.new_data[0].probeOverTemp == 1) this.checkDCT_Probe1 = true;
      else this.checkDCT_Probe1 = false;

      if (this.new_data[0].probeUnderTemp == 1) this.checkDCT_Probe2 = true;
      else this.checkDCT_Probe2 = false;

      if (this.new_data[0].humidityOverTemp == 1)
        this.checkDCT_Humidity1 = true;
      else this.checkDCT_Humidity1 = false;

      if (this.new_data[0].humidityUnderTemp == 1)
        this.checkDCT_Humidity2 = true;
      else this.checkDCT_Humidity2 = false;

      if (
        this.new_data[0].alertState1 != 0 &&
        this.new_data[0].alertState1 != null
      )
        this.edit_selectedAlertState1 = this.new_data[0].alertState1.toString();
      if (
        this.new_data[0].alertState2 != 0 &&
        this.new_data[0].alertState2 != null
      )
        this.edit_selectedAlertState2 = this.new_data[0].alertState2.toString();
      if (
        this.new_data[0].alertState3 != 0 &&
        this.new_data[0].alertState3 != null
      )
        this.edit_selectedAlertState3 = this.new_data[0].alertState3.toString();

      this.oldedit_selectedAlertState1 = this.edit_selectedAlertState1;
      this.oldedit_selectedAlertState2 = this.edit_selectedAlertState2;
      this.oldedit_selectedAlertState3 = this.edit_selectedAlertState3;

      this.oldcheckDCT_Tag1 = this.checkDCT_Tag1;
      this.oldcheckDCT_Tag2 = this.checkDCT_Tag2;
      this.oldcheckDCT_Tag3 = this.checkDCT_Tag3;

      this.oldalertMessage1 = this.new_data[0].alertMessage1;
      this.oldalertMessage2 = this.new_data[0].alertMessage2;
      this.oldalertMessage3 = this.new_data[0].alertMessage3;

      this.alertMessage_input1 = this.oldalertMessage1;
      this.alertMessage_input2 = this.oldalertMessage2;
      this.alertMessage_input3 = this.oldalertMessage3;
      
      /********************Input Active Alert Time***********************/       
      if (this.new_data[0].dinput1 == 1)
      {                    
        if (parseInt(this.new_data[0].Input1StartHr) >= 0)
          this.data_startTime1 = this.new_data[0].Input1StartHr.toString() + ":" + "00"//this.new_data[0].Input1StartMi.toString();
        else
          this.data_startTime1 = "";

        if (parseInt(this.new_data[0].Input1EndHr) >= 0)
          this.data_endTime1 = this.new_data[0].Input1EndHr.toString() + ":" + "00"//this.new_data[0].Input1EndMi.toString();
        else
          this.data_endTime1 = "";
          
        if(this.new_data[0].Input1chkAllday == 1)  
        {
          this.checkallinfo1 = true;
          this._egressenablehourmin1 = false;
          this.data_startTime1 = "";
          this.data_endTime1 = "";
        }        
      }
        
      if (this.new_data[0].dinput2 == 1)
      { 
        if (parseInt(this.new_data[0].Input2StartHr) >= 0)
          this.data_startTime2 = this.new_data[0].Input2StartHr.toString() + ":" + "00"//this.new_data[0].Input2StartMi.toString();
        else
          this.data_startTime2 = "";

        if (parseInt(this.new_data[0].Input2EndHr) >= 0)
          this.data_endTime2 = this.new_data[0].Input2EndHr.toString() + ":" + "00"//this.new_data[0].Input2EndMi.toString();
        else
          this.data_endTime2 = "";
          
        if(this.new_data[0].Input2chkAllday == 1)  
        {
          this.checkallinfo2 = true;
          this._egressenablehourmin2 = false;
          this.data_startTime2 = "";    
          this.data_endTime2 = "";      
        }        
      }
        
      if (this.new_data[0].dinput3 == 1)
      { 
        if (parseInt(this.new_data[0].Input3StartHr) >= 0)
          this.data_startTime3 = this.new_data[0].Input3StartHr.toString() + ":" + "00"//this.new_data[0].Input3StartMi.toString();
        else
          this.data_startTime3 = "";

        if (parseInt(this.new_data[0].Input3EndHr) >= 0)
          this.data_endTime3 = this.new_data[0].Input3EndHr.toString() + ":" + "00"//this.new_data[0].Input3EndMi.toString();
        else
          this.data_endTime3 = "";                             
        
        if(this.new_data[0].Input3chkAllday == 1)  
        {
          this.checkallinfo3 = true;
          this._egressenablehourmin3 = false;
          this.data_startTime3 = "";
          this.data_endTime3 = "";
        }        
      }

      this.oldambientOverMessage = this.new_data[0].ambientOverMessage;
      this.oldambientUnderMessage = this.new_data[0].ambientUnderMessage;

      this.ambientOverDesc = this.new_data[0].ambientOverDesc;
      this.ambientUnderDesc = this.new_data[0].ambientUnderDesc;

      this.ambientOverMessage = this.new_data[0].ambientOverMessage;
      this.ambientUnderMessage = this.new_data[0].ambientUnderMessage;

      this.oldOverTemp = this.new_data[0].ambientOverDesc;
      this.oldUnderTemp = this.new_data[0].ambientUnderDesc;

      this.oldcheckDCT_Ambient1 = this.checkDCT_Ambient1;
      this.oldcheckDCT_Ambient2 = this.checkDCT_Ambient2;

      this.oldcheckDCT_Probe1 = this.checkDCT_Probe1;
      this.oldcheckDCT_Probe2 = this.checkDCT_Probe2;

      this.oldprobeOverMessage = this.new_data[0].probeOverMessage;
      this.oldprobeUnderMessage = this.new_data[0].probeUnderMessage;

      this.oldProbeOverTemp = this.new_data[0].probeOverDesc;
      this.oldProbeUnderTemp = this.new_data[0].probeUnderDesc;

      this.probeOverDesc = this.new_data[0].probeOverDesc;
      this.probeUnderDesc = this.new_data[0].probeUnderDesc;

      this.probeOverMessage = this.new_data[0].probeOverMessage;
      this.probeUnderMessage = this.new_data[0].probeUnderMessage;

      this.oldcheckDCT_Humidity1 = this.checkDCT_Humidity1;
      this.oldcheckDCT_Humidity2 = this.checkDCT_Humidity2;

      this.oldhumidityOverMessage = this.new_data[0].humidityOverMessage;
      this.oldhumidityUnderMessage = this.new_data[0].humidityUnderMessage;

      this.oldHumidityOverTemp = this.new_data[0].humidityOverDesc;
      this.oldHumidityUnderTemp = this.new_data[0].humidityUnderDesc;

      this.humidityOverMessage = this.new_data[0].humidityOverMessage;
      this.humidityUnderMessage = this.new_data[0].humidityUnderMessage;

      this.humidityOverDesc = this.new_data[0].humidityOverDesc;
      this.humidityUnderDesc = this.new_data[0].humidityUnderDesc;
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.onResize();
      this.getResidentRoomCategory();

      this.getgatewaymacid = localStorage.getItem("applianceSN");

      this.deviceeditForm = new UntypedFormGroup({
        deviceimg: new UntypedFormControl(""),
        devicelocation: new UntypedFormControl(""),
        campusID: new UntypedFormControl(""),
        buildID: new UntypedFormControl(""),
        floorID: new UntypedFormControl(""),
        unitID: new UntypedFormControl(""),
        devicename: new UntypedFormControl(""),
        svgId: new UntypedFormControl(""),
        gatewaymacid: new UntypedFormControl(""),
        dataId: new UntypedFormControl(""),
        hrexmacid: new UntypedFormControl(""),
        roomcategory: new UntypedFormControl(""),
        roomname: new UntypedFormControl("", [
          Validators.maxLength(30),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        description: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        deviceid: new UntypedFormControl("", [Validators.pattern(appsettings.Number_Pattern)]),
        lfdevicetype: new UntypedFormControl(""),
        devicetypeid: new UntypedFormControl(""),
        devicedualid: new UntypedFormControl("", [
          Validators.pattern(appsettings.Number_Pattern),
        ]),
        dcmacid: new UntypedFormControl(""),
        enableinput1: new UntypedFormControl(""),
        enableinput2: new UntypedFormControl(""),
        enableinput3: new UntypedFormControl(""),
        alertmessage1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage3DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage4DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage5DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        alertmessage6DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage7DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage8DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage9DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        temperature1DCT: new UntypedFormControl("", [Validators.maxLength(30)]),
        temperature2DCT: new UntypedFormControl("", [Validators.maxLength(30)]),

        probetemperature1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        probetemperature2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        humiditytemperature1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        humiditytemperature2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        wr_start1: new UntypedFormControl(""),
        wr_end1: new UntypedFormControl(""),            
        wr_start2: new UntypedFormControl(""),
        wr_end2: new UntypedFormControl(""),            
        wr_start3: new UntypedFormControl(""),
        wr_end3: new UntypedFormControl(""),
      });

      if (localStorage.getItem("ENABLE_UL2560") == "1") {
        this.edit_roomcategory = this.deviceeditForm.get("roomcategory");
        if (this.edit_roomcategory != null) {
          this.edit_roomcategory.setValidators([Validators.required]);
          this.edit_roomcategory.updateValueAndValidity();
        }
      }
      this.edit_devicelocation = this.deviceeditForm.get("devicelocation");
      if (this.edit_devicelocation != null) this.edit_devicelocation.disable();

      this.getHRexdetails();

      this.deviceaddForm = new UntypedFormGroup({
        deviceimg: new UntypedFormControl(""),
        devicelocation: new UntypedFormControl(""),
        campusID: new UntypedFormControl(""),
        buildID: new UntypedFormControl(""),
        floorID: new UntypedFormControl(""),
        unitID: new UntypedFormControl(""),
        devicename: new UntypedFormControl(""),
        svgId: new UntypedFormControl(""),
        gatewaymacid: new UntypedFormControl(""),
        dataId: new UntypedFormControl(""),
        hrexmacid: new UntypedFormControl(""),
        addroomcategory: new UntypedFormControl(""),
        roomname: new UntypedFormControl("", [
          Validators.maxLength(30),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        description: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        deviceid: new UntypedFormControl("", [Validators.pattern(appsettings.Number_Pattern)]),
        devicetypeid: new UntypedFormControl(""),
        lfdevicetype: new UntypedFormControl(""),
        devicedualid: new UntypedFormControl("", [
          Validators.pattern(appsettings.Number_Pattern),
        ]),
        dcmacid: new UntypedFormControl(""),
        enableinput1: new UntypedFormControl(""),
        enableinput2: new UntypedFormControl(""),
        enableinput3: new UntypedFormControl(""),
        alertmessage1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage3DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage4DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage5DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        alertmessage6DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage7DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage8DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage9DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        temperature1DCT: new UntypedFormControl("", [Validators.maxLength(30)]),
        temperature2DCT: new UntypedFormControl("", [Validators.maxLength(30)]),

        probetemperature1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        probetemperature2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        humiditytemperature1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        humiditytemperature2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        wr_start1: new UntypedFormControl(""),
        wr_end1: new UntypedFormControl(""),            
        wr_start2: new UntypedFormControl(""),
        wr_end2: new UntypedFormControl(""),            
        wr_start3: new UntypedFormControl(""),
        wr_end3: new UntypedFormControl(""),
      });

      if (localStorage.getItem("ENABLE_UL2560") == "1") {
        const add_roomcategory = this.deviceaddForm.get("addroomcategory");
        if (add_roomcategory != null) {
          add_roomcategory.setValidators([Validators.required]);
          add_roomcategory.updateValueAndValidity();
        }
      }

      this.edit_devicelocation = this.deviceaddForm.get("devicelocation");
      if (this.edit_devicelocation != null) this.edit_devicelocation.disable();           
            
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - constructor() - " + err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      
      if(this.AddDeviceFlag == 0)
      {
        Object.keys(this.deviceeditForm.controls).forEach((field) => {
          // {1}
          const control = this.deviceeditForm.get(field); // {2}
          const deviceEditFormId = document.getElementById(event.target.id);
          if (deviceEditFormId != null) {
            if (field == deviceEditFormId.getAttribute("formControlName")) {
              if (field == "temperature1DCT") this.overTempflag = 1;
              else if (field == "temperature2DCT") this.overTempflag = 2;

              if (field == "probetemperature1DCT") this.probeoverTempflag = 1;
              else if (field == "probetemperature2DCT")
                this.probeoverTempflag = 2;

              if (field == "humiditytemperature1DCT")
                this.humidityoverTempflag = 1;
              else if (field == "humiditytemperature2DCT")
                this.humidityoverTempflag = 2;

              if (field == "devicedualid") {
                if (changedValue == "") {
                  this.checkDCLotiering = false;
                  this.checkDCUnauthorizedEgress = false;
                  this.disableDCLotiering = true;
                  this.disableDCUnauthorizedEgress = true;
                }
              }
              if (control != null) {
                control.setValue(changedValue);
                control.markAsTouched({ onlySelf: true }); // {3}
              }
            }
          }
        });
        if (this.deviceeditForm.valid) {
          this.enableSavebutton(Device_action.device_edit);
        }
      }
      else if(this.AddDeviceFlag == 1)
      {
        Object.keys(this.deviceaddForm.controls).forEach((field) => {
          // {1}
          const control = this.deviceaddForm.get(field); // {2}
          const resFormId = document.getElementById(event.target.id);
          if (resFormId != null) {
            if (field == resFormId.getAttribute("formControlName")) {
              if (control != null) {
                control.setValue(changedValue);
                control.markAsTouched({ onlySelf: true }); // {3}
              }
            }
          }
        });
      }
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  getResidentRoomCategory() {
    try {
      const roomitem: getRoomCategory = {
        authKey: this.authkey,
        categoryId: 0,
      };
      this.technologyService
        .getResidentRoomCategory(roomitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((roomdetails: any[]) => {
          this.RoomCategorydetails = roomdetails;
        });
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" +
          " - getResidentRoomCategory() - " +
          err.message
      );
    }
  }

  selectRoomCategory(categoryId) {
    this.selectCategoryId = categoryId;
  }

  addselectRoomCategory(categoryid) {
    this.addselectCategoryId = categoryid;
  }
  
selectLFDeviceType(typevalue)
{
  this.selectedLFDeviceType = typevalue;
  if (typevalue == 2)
  {
    this.select_DeviceType = NewDeviceTypeDesc.DripTray;
  }
  else
  {
    this.select_DeviceType = NewDeviceTypeDesc.LFExiter
  }
}

  @HostListener("window:resize", ["$event"])
  onResize() {
    if (window.innerWidth <= 1570) {
      this.getcolumnrow = "column";
    } else if (window.innerWidth > 1570) {
      this.getcolumnrow = "row";
    }
    if (window.innerWidth > 960 && window.innerWidth <= 1280) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.DCTColums = 3;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 960) {
      this.columns = 4;
      this.firstcolumns = 1;
      this.DCTColums = 1;
    } else if (window.innerWidth < 600) {
      this.columns = 4;
      this.firstcolumns = 1;
      this.DCTColums = 1;
    }
    this.screenWidth = window.innerWidth;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      this.devicerowHeight = "72.5px";
    }
    if (this.screenWidth <= 425) {
      this.devicerowHeight = "90px";
    } else if (isIEOrEdge == false) {
      this.devicerowHeight = "67px";
    }
  }
  // close edit device Dialog
  public onCloseDeviceDialog = () => {
    try {
      this.thisDialogRef.close("Cancel");

      this.edit_roomcategory = this.deviceeditForm.get("roomcategory");
      const edit_description = this.deviceeditForm.get("description");
      const edit_deviceId = this.deviceeditForm.get("deviceId");
      const edit_devicetypeid = this.deviceeditForm.get("devicetypeid");
      const edit_dcmacid = this.deviceeditForm.get("dcmacid");

      //clear the validation start
      if (this.edit_roomcategory != null) {
        this.edit_roomcategory.get("roomcategory").clearValidators();
        this.edit_roomcategory.get("roomcategory").updateValueAndValidity();
      }
      if (edit_description != null) {
        edit_description.clearValidators();
        edit_description.updateValueAndValidity();
      }
      if (edit_deviceId != null) {
        edit_deviceId.clearValidators();
        edit_deviceId.updateValueAndValidity();
      }
      if (edit_devicetypeid != null) {
        edit_devicetypeid.clearValidators();
        edit_devicetypeid.updateValueAndValidity();
      }
      if (edit_dcmacid != null) {
        edit_dcmacid.clearValidators();
        edit_dcmacid.updateValueAndValidity();
      }
      //clear the validation end

      this.undoDrawRoom();
      this.closeDrawFeature();

      if (!SessionCheck(this.router)) {
        return;
      }
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - onCloseDeviceDialog() - " + err.message
      );
    }
  };

  getHRexdetails() {
    try {
      let editRouterMacId = [] as any;//this.HRexMacId;
      const oldHrexId = this.olddeviceId;
      this.authkey = localStorage.getItem("Authkey") ?? "";
      const hrexdetails: getSiteAuthKeyReuest = {
        authKey: this.authkey,
      };
      this.technologyService
        .getHRexdetails(hrexdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((HRexDetails: any[]) => {
          if (HRexDetails != null && HRexDetails != undefined) {  
            if(HRexDetails.length == undefined || HRexDetails[0].HrexCount == undefined)          
            {
              // this.HRexMacId =HRexDetails[0].MacId;
              this.HRexMacId = HRexDetails;
              this.getHRexMacId = HRexDetails;
            }
            else
            {
              this.clearRouterDetails = HRexDetails;
              this.selectRouterId = this.clearRouterDetails[0].HRexId;
              this.selectRouterMacId = this.clearRouterDetails[0].MacId;  
              this.routerCount = this.clearRouterDetails[0].RouterCount;             
              this.hrexCount = parseInt(this.clearRouterDetails[0].HrexCount);
              if(this.hrexCount == 1)                  
                this.checkRouterdata = 1;                  
              else
                this.checkRouterdata = 0;
              this.hrexMaxCount = this.clearRouterDetails[0].checkRouter;
              if(this.hrexCount == 0 && this.new_data[0].DeviceName == DeviceTypeDesc.HRex)
              {
                const deviceaddDeviceId = this.deviceaddForm.get("deviceid");
                const deviceaddMacId = this.deviceaddForm.get("hrexmacid");
                if (deviceaddDeviceId != null) deviceaddDeviceId.disable();
                if (deviceaddMacId != null) deviceaddMacId.disable();
              }       
              this.addUpdateMode = 4;
              if(this.AddDeviceFlag == 1)   
                this.HRexMacId = this.selectRouterMacId;   
              
              this.getHRexMacId = this.selectRouterMacId;
              if(this.AddDeviceFlag == 0)
              {                                     
                  this.new_data.forEach((element) => {
                    editRouterMacId = HRexDetails.filter((element1) => {
                      return element1.HRexId === element.DeviceId && element1.MacId != element.MacId && oldHrexId == element1.HRexId;
                    })
                    })
                    
                  this.HRexMacId = editRouterMacId[0].MacId;                                     
              }                               
            }
          }
        });
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - getHRexdetails() - " + err.message
      );
    }
  }
  
  addSelectClearRouter(hrexId,hrexMacId)
  {
    this.addUpdateMode = 4;
    this.selectRouterMacId = hrexMacId;
  }

  editSelectUnit(value) {
    try {
      this.editselectedunit = value;
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - editSelectUnit() - " + err.message
      );
    }
  }

  CheckRoomDrawn() {
    let roomDrwan = false;
    // Get the array of features
    const features = this.vector.getSource().getFeatures();
    let vertices;
    let polyLine = [];

    if (features) {
      // Go through this array and get coordinates of their geometry.
      features.forEach(function (feature) {
        vertices = feature.getGeometry().getCoordinates();
      });
    }

    vertices[0].pop();
    polyLine = vertices[0];

    for (let i = 0; i < polyLine.length; i++) {
      const xi = polyLine[i][0],
        yi = polyLine[i][1];
      if (
        xi < 0 ||
        yi < 0 ||
        xi > this.imageExtent[2] ||
        yi > this.imageExtent[3]
      ) {
        roomDrwan = true;
        break;
      }
    }
    return roomDrwan;
  }
  CheckPointInMap() {
    let roomDrwan = false;
    if (this.tempVectorLayer.getSource() != null) {
      if (this.tempVectorLayer.getSource().getFeatures().length == 0) {
        roomDrwan = true;
      } else {
        // Get the array of features
        const features = this.tempVectorLayer.getSource().getFeatures();
        let vertices;

        if (features) {
          // Go through this array and get coordinates of their geometry.
          features.forEach(function (feature) {
            vertices = feature.getGeometry().getCoordinates();
          });
        }
        if (vertices.length > 0) {
          if (
            vertices[0] < 0 ||
            vertices[1] < 0 ||
            vertices[0] > this.imageExtent[2] ||
            vertices[1] > this.imageExtent[3]
          ) {
            roomDrwan = true;
            this.tempVectorLayer.getSource().clear();
          }
        } else {
          roomDrwan = true;
        }
      }
    }
    return roomDrwan;
  }

  GetVertices() {
    let vertices;
    // Get the array of features
    const features = this.tempVectorLayer.getSource().getFeatures();
    const drag_imageExtent = this.imageExtent;
    if (features) {
      // Go through this array and get coordinates of their geometry.
      features.forEach(function (feature) {
        const flatCoordinates = feature.getGeometry().getCoordinates();
        if (
          !(
            flatCoordinates[0] < 0 ||
            flatCoordinates[1] < 0 ||
            flatCoordinates[0] > drag_imageExtent[2] ||
            flatCoordinates[1] > drag_imageExtent[3]
          )
        ) {
          vertices = feature.getGeometry().getCoordinates();
        }
      });
      if (vertices != undefined) {
        vertices[0] = convertToSvgEcllipseXCoordinate(
          parseFloat(vertices[0]),
          true
        );
        vertices[1] = convertToSvgEcllipseYCoordinate(
          parseFloat(vertices[1]),
          parseFloat(this.imageHeight)
        );
      }
    }

    return vertices;
  }

  public editDevicedetails = (deviceFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      let DName = "";
      DName = getDeviceTypeNew(this.olddevicename);
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.deviceeditForm.valid) {
        if (
          this.deviceTypeName != this.olddevicename &&
          this.selectdevice == 1
        ) {
          generateAlertMessage(
            this.dialog,
            "Please place " + DName + " device"
          );
          return false;
        }

        if (this.isPlaceDevice) {
          try {
            // Get the array of features
            const features = this.vector.getSource().getFeatures();
            if (
              features.length == 0 &&
              (this.deviceTypeName == DeviceTypeDesc.Emitter ||
                this.deviceTypeName == DeviceTypeDesc.Monitor ||
                this.deviceTypeName == DeviceTypeDesc.VW)
            ) {
              generateAlertMessage(
                this.dialog,
                "Please draw the room for the " + DName
              );
              return false;
            } else if (features.length == 0) {
              this.isRedrawRoom = false;
            } else if (this.CheckRoomDrawn()) {
              this.isRedrawRoom = false;
            }
          } catch (err) {
            if (
              this.deviceTypeName == DeviceTypeDesc.Emitter ||
              this.deviceTypeName == DeviceTypeDesc.Monitor ||
              this.deviceTypeName == DeviceTypeDesc.VW
            ) {
              generateAlertMessage(
                this.dialog,
                "Please draw the room for the " + DName
              );
              return false;
            } else {
              this.isRedrawRoom = false;
            }
          }
        }

        if (this.isRedrawRoom && this.selectdevice == 1) {
          if (
            !this.isPlaceDevice &&
            (this.deviceTypeName == DeviceTypeDesc.Emitter ||
              this.deviceTypeName == DeviceTypeDesc.Monitor ||
              this.deviceTypeName == DeviceTypeDesc.VW)
          ) {
            generateAlertMessage(
              this.dialog,
              "Please place a Device in the room"
            );
            return false;
          }
          if (this.CheckPointInMap()) {
            generateAlertMessage(
              this.dialog,
              "Please place a Device in the room"
            );
            return false;
          }
          const polyPoint = this.getpolypoints;
          const MessageDetail = {
            Message: "Do you want to update room?",
            Type: 1,
            CustomType: 0,
          };

          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            width: "350px",
            height: "220px",
            panelClass: "myapp-no-padding-dialog",
            autoFocus: false,
            data: MessageDetail,
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result == "Confirm") {
              let verticesinfo = "";
              let polypoints;
              let featurespoly;
              const imageheight = this.imageHeight;

              if (polyPoint == 1) {
                // Get the array of features
                const features = this.vector.getSource().getFeatures();

                if (features) {
                  // Go through this array and get coordinates of their geometry.
                  features.forEach(function (feature) {
                    featurespoly = feature.getGeometry().getCoordinates();
                    featurespoly[0].pop();
                    for (let j = 0; j < featurespoly[0].length; j++) {
                      polypoints =
                        parseFloat(imageheight) -
                        parseFloat(featurespoly[0][j][1]);
                      featurespoly[0][j][0] = Math.floor(featurespoly[0][j][0]);
                      featurespoly[0][j][1] = Math.floor(polypoints);
                      if (verticesinfo.length == 0) {
                        verticesinfo +=
                          featurespoly[0][j][0] + "," + featurespoly[0][j][1];
                      } else {
                        verticesinfo =
                          verticesinfo +
                          " " +
                          featurespoly[0][j][0] +
                          "," +
                          featurespoly[0][j][1];
                      }
                    }
                  });
                }
              }
              this.checkDeviceIdinAlert(deviceFormValue, verticesinfo);
            } else {
              //this.isRedrawRoom = false;
              return;
            }
          });
        } else {
          this.checkDeviceIdinAlert(deviceFormValue, "");
        }
      }
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - editDevicedetails() - " + err.message
      );
    }
  };

  public ModIRIdOrLFId = (deviceFormValue,deviceType,deviceForm) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      let modValue;
      if (deviceFormValue.deviceid.toString().trim() != "") {
        const deviceid = parseInt(deviceFormValue.deviceid.toString().trim());
        if (deviceType == 'LF')
        {
          //modValue=deviceid % 1000.0;    //this is for old    
          var Hexval=parseInt("3FFFFFFFF",16); //17179869183;    //HEX2DEC("3FFFFFFFF")
          modValue =(deviceid + Hexval) % 2047;  //MOD(deviceid+HEX2DEC("3FFFFFFFF"),2047)
        }
        else
        {
          const const_bit = 4095; //0xFFF
          const calmodValue = (deviceid & const_bit);
          if (calmodValue > 2047) modValue = calmodValue - 2048;
          else modValue = calmodValue; 
        }
        if(deviceForm == "add")
          this.deviceaddForm.controls['devicetypeid'].setValue(modValue);  
        else          
          this.deviceeditForm.controls['devicetypeid'].setValue(modValue);  
      }
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - ModIRIdOrLFId() - " + err.message
      );
    }
  };

  checkDeviceIdinAlert(deviceFormValue, polypoints) {
    let devicetype;
    let alert_deviceId;
    this.disableSavebutton(Device_action.device_edit);
    let dualutidflag = 0;
    let deviceTypeflag = 0;
    alert_deviceId = deviceFormValue.deviceid;
    let oldDeviceId = this.olddeviceId;

    if (deviceFormValue.devicename == DeviceTypeDesc.Emitter) {
      devicetype = DeviceType.Emitter;
      deviceTypeflag = 1;
      if (deviceFormValue.devicetypeid == oldDeviceId) {
        alert_deviceId = deviceFormValue.deviceid;
        if (this.oldrexmacid!=undefined && (this.oldrexmacid.length>0))
            oldDeviceId = this.oldrexmacid;
        dualutidflag = 1;
      } else {
        alert_deviceId = deviceFormValue.devicetypeid;
        if (this.oldrexmacid!=undefined && (this.oldrexmacid.length>0))
          oldDeviceId = this.oldrexmacid;
      }
      if (
        deviceFormValue.deviceid == this.oldrexmacid &&
        deviceFormValue.devicetypeid == this.olddeviceId
      )
        deviceTypeflag = 0;
    } else if (deviceFormValue.devicename == DeviceTypeDesc.HRex)
      devicetype = DeviceType.HRex;
    else if (deviceFormValue.devicename == DeviceTypeDesc.Rex) {
      devicetype = DeviceType.Rex;
      deviceTypeflag = 1;
      if (
        deviceFormValue.deviceid == this.olddeviceId &&
        deviceFormValue.devicetypeid == this.oldrexmacid
      )
        deviceTypeflag = 0;
    } else if (deviceFormValue.devicename == DeviceTypeDesc.Room)
      devicetype = DeviceType.Room;
    else if (deviceFormValue.devicename == DeviceTypeDesc.Pin)
      devicetype = DeviceType.Pin;
    else if (deviceFormValue.devicename == DeviceTypeDesc.LF)
      devicetype = DeviceType.LF;
    else if (deviceFormValue.devicename == DeviceTypeDesc.FloorPlanNotes)
      devicetype = DeviceType.FloorPlanNotes;
    else if (deviceFormValue.devicename == DeviceTypeDesc.Pullcord)
      devicetype = DeviceType.Pullcord;
    else if (
      deviceFormValue.devicename == DeviceTypeDesc.DC ||
      deviceFormValue.devicename == DeviceTypeDesc.NewDC
    ) {
      devicetype = DeviceType.DC;
      deviceTypeflag = 1;
      if (deviceFormValue.devicedualid != this.oldDcDualid) {
        alert_deviceId = deviceFormValue.devicedualid;
        oldDeviceId = this.oldDcDualid;
        dualutidflag = 1;
      } else {
        alert_deviceId = deviceFormValue.devicetypeid;
      }
      if (
        deviceFormValue.deviceid == this.oldrexmacid &&
        deviceFormValue.devicetypeid == this.olddeviceId &&
        deviceFormValue.devicedualid == this.oldDcDualid
      )
        deviceTypeflag = 0;
    } else if (deviceFormValue.devicename == DeviceTypeDesc.Monitor)
      devicetype = DeviceType.Monitor;
    else if (deviceFormValue.devicename == DeviceTypeDesc.VW)
      devicetype = DeviceType.VW;
    else if (deviceFormValue.devicename == DeviceTypeDesc.Gateway)
      devicetype = DeviceType.Gateway;
    else if (deviceFormValue.devicename == DeviceTypeDesc.UT)
      devicetype = DeviceType.UT;
    else if (deviceFormValue.devicename == DeviceTypeDesc.UM)
      devicetype = DeviceType.UM;
    else if (deviceFormValue.devicename == DeviceTypeDesc.Intercom)
      devicetype = DeviceType.IC;

    if (
      deviceFormValue.roomcategory == 0 &&
      localStorage.getItem("ENABLE_UL2560") == "1"
    ) {
      this.deviceeditForm.controls["roomcategory"].setValidators([
        Validators.required,
      ]);
      this.deviceeditForm.controls["roomcategory"].updateValueAndValidity();
      if (!this.deviceeditForm.valid) return false;
    }

    if (!deviceFormValue.description.replace(/\s+/g, " ").trim()) {
      generateAlertMessage(this.dialog, "Please enter Description Name");
      this.enableSavebutton(Device_action.device_edit);
      return false;
    }

    if (
      alert_deviceId != oldDeviceId ||
      deviceTypeflag == 1 ||
      deviceFormValue.devicename == DeviceTypeDesc.Pullcord ||
      deviceFormValue.devicename == DeviceTypeDesc.UT ||
      deviceFormValue.devicename == DeviceTypeDesc.DC ||
      deviceFormValue.devicename == DeviceTypeDesc.NewDC ||
      deviceFormValue.devicename == DeviceTypeDesc.HRex ||
      deviceFormValue.devicename == DeviceTypeDesc.Rex
    ) {
      const deviceDetails: checkDeviceAlert = {
        authKey: this.authkey,
        deviceId: parseInt(oldDeviceId),
        deviceType: devicetype.toString(),
        dualFlag: dualutidflag,
        isDelete: 0,
      };
      this.technologyService
        .checkDeviceInAlert(deviceDetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "true") {
            if (
              deviceFormValue.devicename == DeviceTypeDesc.HRex ||
              deviceFormValue.devicename == DeviceTypeDesc.Rex
            ) {
              if (
                this.oldLocationName != deviceFormValue.roomname ||
                this.oldUnitId != this.editselectedunit
              ) {
                generateAlertMessage(
                  this.dialog,
                  "This device has open alerts, please complete or cancel those alerts and try again."
                );
                this.enableSavebutton(Device_action.device_edit);
                return;
              } else {
                this.checkLocationAlert(
                  deviceFormValue,
                  polypoints,
                  devicetype
                );
              }
            } else if (deviceFormValue.devicename == DeviceTypeDesc.Pullcord) {
              if (
                this.checkPCRemovalDetection.toString() !=
                  this.oldcheckPCRemovalDetection.toString() ||
                this.checkPCButtonPress.toString() !=
                  this.oldcheckPCButtonPress.toString() ||
                this.oldLocationName != deviceFormValue.roomname ||
                this.checkPullCord.toString() !=
                  this.oldcheckPullCord.toString() ||
                this.checkPCMobilityMode.toString() !=
                  this.oldcheckPCMobilityMode.toString() ||
                alert_deviceId != oldDeviceId ||
                this.oldUnitId != this.editselectedunit
              ) {
                generateAlertMessage(
                  this.dialog,
                  "This device has open alerts, please complete or cancel those alerts and try again."
                );
                this.enableSavebutton(Device_action.device_edit);
                return;
              } else {
                this.checkLocationAlert(
                  deviceFormValue,
                  polypoints,
                  devicetype
                );
              }
            } else if (deviceFormValue.devicename == DeviceTypeDesc.UT) {
              if (
                this.checkDCT_Tag1.toString() !=
                  this.oldcheckDCT_Tag1.toString() ||
                this.checkDCT_Tag2.toString() !=
                  this.oldcheckDCT_Tag2.toString() ||
                this.checkDCT_Tag3.toString() !=
                  this.oldcheckDCT_Tag3.toString() ||
                this.edit_selectedAlertState1.toString() !=
                  this.oldedit_selectedAlertState1.toString() ||
                this.edit_selectedAlertState2.toString() !=
                  this.oldedit_selectedAlertState2.toString() ||
                this.edit_selectedAlertState3.toString() !=
                  this.oldedit_selectedAlertState3.toString() ||
                deviceFormValue.alertmessage1DCT != this.oldalertMessage1 ||
                deviceFormValue.alertmessage2DCT != this.oldalertMessage2 ||
                deviceFormValue.alertmessage3DCT != this.oldalertMessage3 ||
                this.checkDCT_Ambient1.toString() !=
                  this.oldcheckDCT_Ambient1.toString() ||
                this.checkDCT_Ambient2.toString() !=
                  this.oldcheckDCT_Ambient2.toString() ||
                deviceFormValue.temperature1DCT != this.oldOverTemp ||
                deviceFormValue.temperature2DCT != this.oldUnderTemp ||
                deviceFormValue.alertmessage4DCT !=
                  this.oldambientOverMessage ||
                deviceFormValue.alertmessage5DCT !=
                  this.oldambientUnderMessage ||
                alert_deviceId != oldDeviceId ||
                this.oldUnitId != this.editselectedunit
              ) {
                generateAlertMessage(
                  this.dialog,
                  "This device has open alerts, please complete or cancel those alerts and try again."
                );
                this.enableSavebutton(Device_action.device_edit);
                return;
              } else {
                this.checkLocationAlert(
                  deviceFormValue,
                  polypoints,
                  devicetype
                );
              }
            } else if (
              deviceFormValue.devicename == DeviceTypeDesc.DC ||
              deviceFormValue.devicename == DeviceTypeDesc.NewDC
            ) {
              if (
                this.checkDCTag != this.oldcheckDCTag ||
                this.checkDCUnauthorizedEgress !=
                  this.oldcheckDCUnauthorizedEgress ||
                this.checkDCLotiering != this.oldcheckDCLotiering ||
                alert_deviceId != oldDeviceId ||
                this.oldUnitId != this.editselectedunit
              ) {
                generateAlertMessage(
                  this.dialog,
                  "This device has open alerts, please complete or cancel those alerts and try again."
                );
                this.enableSavebutton(Device_action.device_edit);
                return;
              } else {
                this.checkLocationAlert(
                  deviceFormValue,
                  polypoints,
                  devicetype
                );
              }
            } else {
              generateAlertMessage(
                this.dialog,
                "This device has open alerts, please complete or cancel those alerts and try again."
              );
              this.enableSavebutton(Device_action.device_edit);
              return;
            }
          } else if (apiresponse.toString() == "false") {
            // else
            this.checkLocationAlert(deviceFormValue, polypoints, devicetype);
          }
        });
    } else {
      this.checkLocationAlert(deviceFormValue, polypoints, devicetype);
    }
  }

  checkLocationAlert(deviceFormValue, polypoints, devicetype) {
    let oldDeciceTypeId;
    if (this.oldDCMacid == undefined) this.oldDCMacid = "";

    this.disableSavebutton(Device_action.device_edit);

    if (deviceFormValue.devicetypeid == "")
      oldDeciceTypeId = deviceFormValue.deviceid;
    else oldDeciceTypeId = deviceFormValue.devicetypeid;

    if (deviceFormValue.devicename == DeviceTypeDesc.Gateway) {
      // GetWay no need to check
      this.onSaveDeviceDialog(deviceFormValue, polypoints);
    } else if (
      this.olddeviceId != oldDeciceTypeId ||
      this.oldLocationName != deviceFormValue.roomname ||
      this.oldSubType != deviceFormValue.Location ||
      this.oldDCMacid != deviceFormValue.dcmacid ||
      this.oldUnitId != this.editselectedunit ||
      this.oldrexmacid != deviceFormValue.deviceid
    ) {
      const LocationDetails: checkLocationAlert = {
        authKey: this.authkey,
        irOrRFId: this.olddeviceId.toString(),
        deviceType: devicetype.toString(),
      };
      this.technologyService
        .checkLocationInAlert(LocationDetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "This Location has open alerts, please complete or cancel those alerts and try again."
            );
            this.enableSavebutton(Device_action.device_edit);
            return;
          } else {
            this.onSaveDeviceDialog(deviceFormValue, polypoints);
          }
        });
    } else this.onSaveDeviceDialog(deviceFormValue, polypoints);
  }

  onSaveDeviceDialog(deviceFormValue, polypoints) {
    try {      
      let vertices;
      const emitteridArr = [] as any[];
      const IridArr = [] as any[];
      const rexidArr = [] as any[];
      const macidArr = [] as any[];
      const e_EmitterId = 0;
      const e_IRID = 0;
      const rex_rexid = 0;
      // const rex_macid = 0;
      let deviceX = this.DeviceX;
      let deviceY = this.DeviceY;
      const e_LFidArr = [] as any[];
      const e_LFdevidArr = [] as any[];
      const e_LFId = 0;
      const e_LFdevID = 0;

      const e_DCidArr = [] as any[];
      const e_DCdevidArr = [] as any[];
      const e_DCdualidArr = [] as any[];
      const e_DCMacIdArr = [] as any[];
      const e_DCId = 0;
      const e_DCdevID = 0;
      const e_DCdualId = 0;
      const e_DCMacId = 0;

      const MonitoridArr = [] as any[];
      const MonitorIRidArr = [] as any[];
      const e_MonitorId = 0;
      const e_MonitorIRID = 0;

      const VWidArr = [] as any[];
      const VWIRidArr = [] as any[];
      const e_VWId = 0;
      const e_VWIRID = 0;

      const UMidArr = [] as any[];
      const UMIRidArr = [] as any[];
      const e_UMId = 0;
      const e_UMIRID = 0;
      
      const hrexidArr = [] as any[];
      const hrexmacidArr = [] as any[];      
      // const hrex_macid = 0;

      let devicetype;
      let DeviceId;
      let emitterId;
      let strDeviceDualId;
      let strLocation;
      let strdcMacId;

      if (polypoints.length == 0) {
        // if no new room draw then old value to be get it
        polypoints = this.roomcoordinates;
      }

      if (polypoints == undefined) polypoints = "";
      if (
        this.checkpolygonpoints == 0 &&
        this.deviceTypeName != DeviceTypeDesc.Emitter &&
        this.deviceTypeName != DeviceTypeDesc.Monitor &&
        this.deviceTypeName != DeviceTypeDesc.VW &&
        this.roomdraw == 0
      ) {
        polypoints = "";
      }
      if (
        this.isRedrawRoom &&
        (this.deviceTypeName == DeviceTypeDesc.Emitter ||
          this.deviceTypeName == DeviceTypeDesc.Monitor ||
          this.deviceTypeName == DeviceTypeDesc.VW)
      ) {
        //this is for new room on added when edit
        if (
          this.obj_document.getElementById("spn_RedrawDeviceXY").innerHTML ==
            "" &&
          this.isRedrawDevice
        ) {
          generateAlertMessage(
            this.dialog,
            "Please place a Device in the room"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
        vertices = this.GetVertices();
        if (vertices != undefined) {
          deviceX = vertices[0];
          deviceY = vertices[1];
        }
      } else if (
        this.obj_document.getElementById("spn_RedrawDeviceXY").innerHTML
          .length > 0
      ) {
        //only change device image on room
        vertices =
          this.obj_document.getElementById("spn_RedrawDeviceXY").innerHTML; //move the icon only
        vertices = vertices.split(",");
        deviceX = convertToSvgEcllipseXCoordinate(
          parseFloat(vertices[0]),
          true
        );
        deviceY = convertToSvgEcllipseYCoordinate(
          parseFloat(vertices[1]),
          parseFloat(this.imageHeight)
        );
      } else {
        vertices = this.GetVertices();
        if (vertices != undefined) {
          deviceX = vertices[0];
          deviceY = vertices[1];
        } else {
          deviceX = this.DeviceX;
          deviceY = this.DeviceY;
        }
      }

      this.deviceeditForm.controls["deviceid"].setValidators([
        Validators.required,
        Validators.pattern(appsettings.Number_Pattern),
      ]);
      this.deviceeditForm.controls["deviceid"].updateValueAndValidity();
      if (!this.deviceeditForm.valid) {
        this.enableSavebutton(Device_action.device_edit);
        return false;
      }

      this.disableSavebutton(Device_action.device_edit);

      this.NewDeviceId = deviceFormValue.devicetypeid;

      if (deviceFormValue.devicename == DeviceTypeDesc.Emitter) {
        devicetype = DeviceType.Emitter;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
        if (
          deviceFormValue.deviceid <= 0 ||
          deviceFormValue.deviceid > 1073741823
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, TruLocator Id should be in between 1 and 1073741823"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
        if (
          deviceFormValue.devicetypeid <= 0 ||
          deviceFormValue.devicetypeid > IRIdRange.maximum
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, IR Id should be in between 1 and " +
              IRIdRange.maximum.toString()
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        } else {
          this.deviceeditForm.controls["devicetypeid"].setValidators([
            Validators.required,
            Validators.pattern(appsettings.Number_Pattern),
          ]);
          this.deviceeditForm.controls["devicetypeid"].updateValueAndValidity();
          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.HRex) {
        devicetype = DeviceType.HRex;
        DeviceId = deviceFormValue.deviceid;
        if ((deviceFormValue.deviceid <= 0 || deviceFormValue.deviceid > 1) && this.clearRouterDetails == undefined) {
          generateAlertMessage(
            this.dialog,
            "Not allowed to change the Device Id of Clear Router,it should be 1"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
        this.NewDeviceId = DeviceId;
        emitterId = deviceFormValue.hrexmacid;
        if (this.getHRexMacId != this.HRexMacId && this.clearRouterDetails == undefined || this.checkRouterdata == 1) {
          generateAlertMessage(
            this.dialog,
            "Not allowed to change the Clear Router Mac Id"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Rex) {
        let rexMinId = 0;
        devicetype = DeviceType.Rex;
        DeviceId = deviceFormValue.deviceid;
        this.NewDeviceId = DeviceId;
        emitterId = deviceFormValue.devicetypeid;
        emitterId = emitterId.replace(/:/g, "_").replace(/-/g, "_");
        if(this.hrexMaxCount != undefined)
          this.hrexMaxCount = parseInt(this.hrexMaxCount);
        if(this.hrexMaxCount != undefined && this.hrexMaxCount > 0)
          rexMinId = 6;//parseInt(this.hrexMaxCount);
        else
          rexMinId = 2;
        if (DeviceId < rexMinId || DeviceId > 200) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_edit);
          if(this.clearRouterDetails == undefined || this.checkRouterdata == 1)
            generateAlertMessage(this.dialog,"Invalid Range, Clear Repeater Id should be in between 2 and 200");
          else
          {    
            //const rexmacId = parseInt(rexMinId.toString()) + 1      
            generateAlertMessage(this.dialog,"Invalid Range, Clear Repeater Id should be in between 6 and 200");
          }
          return false;          
        } else {
          if (emitterId.includes("_")) {
            const emitter_Id = emitterId.split("_");
            emitterId = "";
            for (let i = 0; i < emitter_Id.length; i++) {
              if (emitter_Id[i].length == 2) {
                emitterId += emitter_Id[i] + "_";
              } else {
                emitterId += emitter_Id[i].replace(/(.{2})/g, "$1_");
              }
            }
          } else emitterId = emitterId.replace(/(.{2})/g, "$1_");

          emitterId = emitterId.slice(0, -1);
          if (emitterId == "00_00_00_00_00_00") {
            generateAlertMessage(this.dialog, "Please enter valid Mac Id");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
          this.NewRexMacId = emitterId;
          this.deviceeditForm.controls["devicetypeid"].setValidators([
            Validators.required,
            Validators.pattern(
             appsettings.Mac_Pattern
            ),
          ]);
          this.deviceeditForm.controls["devicetypeid"].updateValueAndValidity();
          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }
      } 
      else if (deviceFormValue.devicename == DeviceTypeDesc.LF) {
        if(this.selectedLFDeviceType == 1)
          devicetype = DeviceType.LF;
        else if(this.selectedLFDeviceType == 2)
          devicetype = DeviceType.DripTray;
          
         console.log("devicetype : " + devicetype,this.selectedLFDeviceType) 
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
        if (
          deviceFormValue.deviceid <= 0 ||
          deviceFormValue.deviceid > 9999999
        ) 
        {
          if(this.selectedLFDeviceType == 1)
            generateAlertMessage(this.dialog, "Invalid Range, LF Exciter Id should be in between 1 and 9999999");
          else if(this.selectedLFDeviceType == 2)
            generateAlertMessage(this.dialog, "Invalid Range, Device Id should be in between 1 and 9999999");
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
        if (
          deviceFormValue.devicetypeid <= 0 ||
          deviceFormValue.devicetypeid > LFIdRange.maximum
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, LF Id should be in between 1 and " +
              LFIdRange.maximum.toString()
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        } else {
          this.deviceeditForm.controls["devicetypeid"].setValidators([
            Validators.required,
            Validators.pattern(appsettings.Number_Pattern),
          ]);
          this.deviceeditForm.controls["devicetypeid"].updateValueAndValidity();
          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Pullcord) {
        devicetype = DeviceType.Pullcord;
        DeviceId = deviceFormValue.deviceid;
        // deviceFormValue.devicetypeid = deviceFormValue.deviceid;
        if (
          deviceFormValue.deviceid <= 0 ||
          deviceFormValue.deviceid > 1073741823
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Pull Cord Id should be in between 1 and 1073741823"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
      } else if (
        deviceFormValue.devicename == DeviceTypeDesc.DC ||
        deviceFormValue.devicename == DeviceTypeDesc.NewDC
      ) {
        devicetype = DeviceType.DC;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
        strDeviceDualId = deviceFormValue.devicedualid;
        strLocation = deviceFormValue.description;
        strdcMacId = deviceFormValue.dcmacid;
        strdcMacId = strdcMacId.replace(/:/g, "_").replace(/-/g, "_");
        if (
          deviceFormValue.deviceid <= 0 ||
          deviceFormValue.deviceid > SecurityControllerIdRange.maximum
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Security Controller Id should be in between 1 and " +
              SecurityControllerIdRange.maximum.toString()
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        } else {
          if (
            deviceFormValue.devicetypeid <= 0 ||
            (deviceFormValue.devicetypeid < SecurityController_LFIdRange.minimum || deviceFormValue.devicetypeid > SecurityController_LFIdRange.maximum)
          ) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, LF Id should be in between " + SecurityController_LFIdRange.minimum.toString() + " and " + SecurityController_LFIdRange.maximum.toString()
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
          this.deviceeditForm.controls["devicetypeid"].setValidators([
            Validators.required,
            Validators.pattern(appsettings.Number_Pattern),
          ]);
          this.deviceeditForm.controls["devicetypeid"].updateValueAndValidity();
          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }

        if (strDeviceDualId && strDeviceDualId.toString() != "") {
          if (strDeviceDualId <= 0 || strDeviceDualId > 1073741823) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, UT Id should be in between 1 and 1073741823"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else {
            this.deviceeditForm.controls["devicedualid"].setValidators([
              Validators.pattern(appsettings.Number_Pattern),
            ]);
            this.deviceeditForm.controls[
              "devicedualid"
            ].updateValueAndValidity();
            if (!this.deviceeditForm.valid) {
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          }
        } else {
          strDeviceDualId = 0;
        }
        if (strdcMacId != "") {
          if (strdcMacId.includes("_")) {
            const emitter_Id = strdcMacId.split("_");
            strdcMacId = "";
            for (let i = 0; i < emitter_Id.length; i++) {
              if (emitter_Id[i].length == 2) {
                strdcMacId += emitter_Id[i] + "_";
              } else {
                strdcMacId += emitter_Id[i].replace(/(.{2})/g, "$1_");
              }
            }
          } else strdcMacId = strdcMacId.replace(/(.{2})/g, "$1_");

          strdcMacId = strdcMacId.slice(0, -1);
          if (strdcMacId == "00_00_00_00_00_00") {
            generateAlertMessage(this.dialog, "Please enter valid Mac Id");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
          this.NewDCMacId = "";
          this.NewDCMacId = strdcMacId;
          this.deviceeditForm.controls["dcmacid"].setValidators([
            Validators.required,
            Validators.pattern(
             appsettings.Mac_Pattern
            ),
          ]);
          this.deviceeditForm.controls["dcmacid"].updateValueAndValidity();

          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }
        if (!strLocation) {
          generateAlertMessage(
            this.dialog,
            "Please enter Security Controller Loaction"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        } else if (strLocation != "") {
          this.deviceeditForm.controls["description"].setValidators([
            Validators.required,
            Validators.pattern(appsettings.Name_Pattern),
          ]);
          this.deviceeditForm.controls["description"].updateValueAndValidity();
          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Monitor) {
        devicetype = DeviceType.Monitor;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
        if (
          deviceFormValue.deviceid <= 0 ||
          deviceFormValue.deviceid > 1073741823
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Monitor Id should be in between 1 and 1073741823"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
        if (
          deviceFormValue.devicetypeid <= 0 ||
          deviceFormValue.devicetypeid > IRIdRange.maximum
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, IR Id should be in between 1 and " +
              IRIdRange.maximum.toString()
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        } else {
          this.deviceeditForm.controls["devicetypeid"].setValidators([
            Validators.required,
            Validators.pattern(appsettings.Number_Pattern),
          ]);
          this.deviceeditForm.controls["devicetypeid"].updateValueAndValidity();
          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.VW) {
        devicetype = DeviceType.VW;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
        if (
          deviceFormValue.deviceid <= 0 ||
          deviceFormValue.deviceid > 1073741823
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, VW Id should be in between 1 and 1073741823"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
        if (
          deviceFormValue.devicetypeid <= 0 ||
          deviceFormValue.devicetypeid > IRIdRange.maximum
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, IR Id should be in between 1 and " +
              IRIdRange.maximum.toString()
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        } else {
          this.deviceeditForm.controls["devicetypeid"].setValidators([
            Validators.required,
            Validators.pattern(appsettings.Number_Pattern),
          ]);
          this.deviceeditForm.controls["devicetypeid"].updateValueAndValidity();
          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Gateway) {
        devicetype = DeviceType.Gateway;
        DeviceId = deviceFormValue.deviceid;
        if (DeviceId != 0) {
          generateAlertMessage(
            this.dialog,
            "Not allowed to change the Device Id of Clear Gateway,it should be 0"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
        emitterId = deviceFormValue.gatewaymacid;
      } else if (deviceFormValue.devicename == DeviceTypeDesc.UT) {
        devicetype = DeviceType.UT;
        DeviceId = deviceFormValue.deviceid;
        let overtempvalue;
        let undertempvalue;
        // let changedundertempvalue;
        if (
          deviceFormValue.deviceid <= 0 ||
          deviceFormValue.deviceid > 1073741823
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, UT Id should be in between 1 and 1073741823"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }

        if (this.checkDCT_Tag1 == true) {
          if (this.edit_selectedAlertState1 == 0) {
            generateAlertMessage(
              this.dialog,
              "Please select Alert State for Input1"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else if (this.edit_selectedAlertState1 != 0) {
            if (this.deviceeditForm.value.alertmessage1DCT == null) 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input1");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            } 
            else if(this.deviceeditForm.value.alertmessage1DCT.replace(/\s+/g, " ").trim() == "") 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input1");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            } 
            else if (
              !/^[a-zA-Z0-9 ]+$/.test(
                this.deviceeditForm.value.alertmessage1DCT
              )
            ) {
              generateAlertMessage(
                this.dialog,
                "Please Enter valid Alert Message for Input1"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          }
          
          if ((this.deviceeditForm.value.wr_start1 == "" || this.deviceeditForm.value.wr_start1 == undefined) && (this.deviceeditForm.value.wr_end1 == "" ||
            this.deviceeditForm.value.wr_end1 == undefined) && (this.checkallinfo1 == false || this.checkallinfo1 == undefined)) 
          {              
            generateAlertMessage(this.dialog,"Please select Input1 Time info or All");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }

          if (this.deviceeditForm.value.wr_start1 != "" && this.deviceeditForm.value.wr_start1 != undefined) 
          {
            if (this.deviceeditForm.value.wr_end1 == "" || this.deviceeditForm.value.wr_end1 == undefined) 
            {                
              generateAlertMessage(this.dialog,"Please select Input1 End time");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          }

          if (this.deviceeditForm.value.wr_end1 != "" && this.deviceeditForm.value.wr_end1 != undefined) 
          {
            if (this.deviceeditForm.value.wr_start1 == "" || this.deviceeditForm.value.wr_start1 == undefined) 
            {                
              generateAlertMessage(this.dialog,"Please select Input1 Start time");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          }
          
        }

        if (this.checkDCT_Tag2 == true) {
          if (this.edit_selectedAlertState2 == 0) {
            generateAlertMessage(
              this.dialog,
              "Please select Alert State for Input2"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (this.edit_selectedAlertState2 != 0) {
            
            if (this.deviceeditForm.value.alertmessage2DCT == null) 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input2");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }             
            else if (this.deviceeditForm.value.alertmessage2DCT.replace(/\s+/g, " ").trim() == "") 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input2");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            } 
            else if (
              !/^[a-zA-Z0-9 ]+$/.test(
                this.deviceeditForm.value.alertmessage2DCT
              )
            ) {
              generateAlertMessage(
                this.dialog,
                "Please Enter valid Alert Message for Input2"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          }
          if ((this.deviceeditForm.value.wr_start2 == "" || this.deviceeditForm.value.wr_start2 == undefined) && (this.deviceeditForm.value.wr_end2 == "" ||
            this.deviceeditForm.value.wr_end2 == undefined) && (this.checkallinfo2 == false || this.checkallinfo2 == undefined)) 
          {
            // this.addResSpacebool = true;
            generateAlertMessage(this.dialog,"Please select Input2 Time info or All");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }

          if (this.deviceeditForm.value.wr_start2 != "" && this.deviceeditForm.value.wr_start2 != undefined) 
          {
            if (this.deviceeditForm.value.wr_end2 == "" || this.deviceeditForm.value.wr_end2 == undefined) 
            {
              // this.addResSpacebool = true;
              generateAlertMessage(this.dialog,"Please select Input2 End time");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          }

          if (this.deviceeditForm.value.wr_end2 != "" && this.deviceeditForm.value.wr_end2 != undefined) 
          {
            if (this.deviceeditForm.value.wr_start2 == "" || this.deviceeditForm.value.wr_start2 == undefined) 
            {
              // this.addResSpacebool = true;
              generateAlertMessage(this.dialog,"Please select Input2 Start time");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          } 
        }

        if (this.checkDCT_Tag3 == true) {
          if (this.edit_selectedAlertState3 == 0) {
            generateAlertMessage(
              this.dialog,
              "Please select Alert State for Input3"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (this.edit_selectedAlertState3 != 0) {
            if (this.deviceeditForm.value.alertmessage3DCT == null) 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input3");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            } 
            else if (this.deviceeditForm.value.alertmessage3DCT.replace(/\s+/g, " ").trim() == "") 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input3");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            } 
            else if (
              !/^[a-zA-Z0-9 ]+$/.test(
                this.deviceeditForm.value.alertmessage3DCT
              )
            ) {
              generateAlertMessage(
                this.dialog,
                "Please Enter valid Alert Message for Input3"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          }
          if ((this.deviceeditForm.value.wr_start3 == "" || this.deviceeditForm.value.wr_start3 == undefined) && (this.deviceeditForm.value.wr_end3 == "" ||
            this.deviceeditForm.value.wr_end3 == undefined) && (this.checkallinfo3 == false || this.checkallinfo3 == undefined)) 
          {
            // this.addResSpacebool = true;
            generateAlertMessage(this.dialog,"Please select Input3 Time info or All");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }

          if (this.deviceeditForm.value.wr_start3 != "" && this.deviceeditForm.value.wr_start3 != undefined) 
          {
            if (this.deviceeditForm.value.wr_end3 == "" || this.deviceeditForm.value.wr_end3 == undefined) 
            {
              // this.addResSpacebool = true;
              generateAlertMessage(this.dialog,"Please select Input3 End time");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          }

          if (this.deviceeditForm.value.wr_end3 != "" && this.deviceeditForm.value.wr_end3 != undefined) 
          {
            if (this.deviceeditForm.value.wr_start3 == "" || this.deviceeditForm.value.wr_start3 == undefined) 
            {
              // this.addResSpacebool = true;
              generateAlertMessage(this.dialog,"Please select Input3 Start time");
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }
          } 
        }
        if (this.checkDCT_Ambient1 == true) {
          if (this.deviceeditForm.value.temperature1DCT == null) 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Over Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (this.deviceeditForm.value.temperature1DCT.replace(/\s+/g, " ").trim() == "") 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Over Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
              this.deviceeditForm.value.temperature1DCT
            )
          ) {
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Temperature for Over Temp"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else if (
            this.deviceeditForm.value.temperature1DCT < -45 ||
            this.deviceeditForm.value.temperature1DCT > 125
          ) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, Over Temperature should be in between -45 and 125"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else {
            overtempvalue = this.deviceeditForm.value.temperature1DCT;
            undertempvalue = this.deviceeditForm.value.temperature2DCT;

            if (
              parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              (this.overTempflag == 1 || this.overTempflag == 0)
            ) {
              generateAlertMessage(
                this.dialog,
                "Over Temp should be greater than the Under Temp"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }

            if (this.deviceeditForm.value.temperature1DCT.replace(/\s+/g, " ").trim() != "") {
              if (this.deviceeditForm.value.alertmessage4DCT == null) 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Over Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (this.deviceeditForm.value.alertmessage4DCT.replace(/\s+/g, " ").trim() == "") 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Over Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (
                !/^[a-zA-Z0-9 ]+$/.test(
                  this.deviceeditForm.value.alertmessage4DCT
                )
              ) {
                generateAlertMessage(
                  this.dialog,
                  "Please Enter valid Alert Message for Over Temp"
                );
                this.enableSavebutton(Device_action.device_edit);
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Ambient2 == true) {
          if (this.deviceeditForm.value.temperature2DCT == null) 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Under Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (this.deviceeditForm.value.temperature2DCT.replace(/\s+/g, " ").trim() == "") 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Under Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (!/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
              this.deviceeditForm.value.temperature2DCT
            )
          ) {
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Temperature for Under Temp"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else if (
            this.deviceeditForm.value.temperature2DCT < -45 ||
            this.deviceeditForm.value.temperature2DCT > 125
          ) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, Under Temperature should be in between -45 and 125"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else {
            overtempvalue = this.deviceeditForm.value.temperature1DCT;
            undertempvalue = this.deviceeditForm.value.temperature2DCT;
            this.oldUnderTemp = this.deviceeditForm.value.temperature2DCT;
            if (
              parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              this.overTempflag == 2
            ) {
              generateAlertMessage(
                this.dialog,
                "Under Temp should be lesser than the Over Temp"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            } 
            else if (this.deviceeditForm.value.temperature2DCT != "") {
              if (this.deviceeditForm.value.alertmessage5DCT == null) 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Under Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (this.deviceeditForm.value.alertmessage5DCT.replace(/\s+/g, " ").trim() == "") 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Under Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (
                !/^[a-zA-Z0-9 ]+$/.test(
                  this.deviceeditForm.value.alertmessage5DCT
                )
              ) {
                generateAlertMessage(
                  this.dialog,
                  "Please Enter valid Alert Message for Under Temp"
                );
                this.enableSavebutton(Device_action.device_edit);
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Probe1 == true) {
          if (this.deviceeditForm.value.probetemperature1DCT == null) 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Over Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (this.deviceeditForm.value.probetemperature1DCT.replace(/\s+/g, " ").trim() == "") 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Over Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (!/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
              this.deviceeditForm.value.probetemperature1DCT
            )
          ) {
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Temperature for Probe Over Temp"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else if (
            this.deviceeditForm.value.probetemperature1DCT < -45 ||
            this.deviceeditForm.value.probetemperature1DCT > 125
          ) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, Probe Over Temperature should be in between -45 and 125"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else {
            overtempvalue = this.deviceeditForm.value.probetemperature1DCT;
            undertempvalue = this.deviceeditForm.value.probetemperature2DCT;
            if (
              parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              (this.probeoverTempflag == 1 || this.overTempflag == 0)
            ) {
              generateAlertMessage(
                this.dialog,
                "Probe Over Temp should be greater than the Probe Under Temp"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }

            if (this.deviceeditForm.value.probetemperature1DCT.replace(/\s+/g, " ").trim() != "") 
            {
              if (this.deviceeditForm.value.alertmessage6DCT == null) 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Over Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (this.deviceeditForm.value.alertmessage6DCT.replace(/\s+/g, " ").trim() == "") 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Over Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (
                !/^[a-zA-Z0-9 ]+$/.test(
                  this.deviceeditForm.value.alertmessage6DCT
                )
              ) {
                generateAlertMessage(
                  this.dialog,
                  "Please Enter valid Alert Message for Probe Over Temp"
                );
                this.enableSavebutton(Device_action.device_edit);
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Probe2 == true) {
          if (this.deviceeditForm.value.probetemperature2DCT == null) 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Under Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (this.deviceeditForm.value.probetemperature2DCT.replace(/\s+/g, " ").trim() == "") 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Under Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
              this.deviceeditForm.value.probetemperature2DCT
            )
          ) {
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Temperature for Probe Under Temp"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else if (
            this.deviceeditForm.value.probetemperature2DCT < -45 ||
            this.deviceeditForm.value.probetemperature2DCT > 125
          ) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, Probe Under Temperature should be in between -45 and 125"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else {
            overtempvalue = this.deviceeditForm.value.probetemperature1DCT;
            undertempvalue = this.deviceeditForm.value.probetemperature2DCT;
            this.oldUnderTemp = this.deviceeditForm.value.probetemperature2DCT;
            if (
              parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              this.probeoverTempflag == 2
            ) {
              generateAlertMessage(
                this.dialog,
                "Probe Under Temp should be lesser than the Probe Over Temp"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            } 
            else if (this.deviceeditForm.value.probetemperature2DCT != "") {
              if (this.deviceeditForm.value.alertmessage7DCT == null) 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Under Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (this.deviceeditForm.value.alertmessage7DCT.replace(/\s+/g, " ").trim() == "") 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Under Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (
                !/^[a-zA-Z0-9 ]+$/.test(
                  this.deviceeditForm.value.alertmessage7DCT
                )
              ) {
                generateAlertMessage(
                  this.dialog,
                  "Please Enter valid Alert Message for Probe Under Temp"
                );
                this.enableSavebutton(Device_action.device_edit);
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Humidity1 == true) {
          if (this.deviceeditForm.value.humiditytemperature1DCT == null) 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Humidity Over Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (this.deviceeditForm.value.humiditytemperature1DCT.replace(/\s+/g, " ").trim() == "") 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Humidity Over Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
              this.deviceeditForm.value.humiditytemperature1DCT
            )
          ) {
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Temperature for Humidity Over Temp"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else if (
            this.deviceeditForm.value.humiditytemperature1DCT < 0 ||
            this.deviceeditForm.value.humiditytemperature1DCT > 100
          ) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, Humidity Over Temperature should be in between 0 and 100"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else {
            overtempvalue = this.deviceeditForm.value.humiditytemperature1DCT;
            undertempvalue = this.deviceeditForm.value.humiditytemperature2DCT;
            if (
              parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              (this.humidityoverTempflag == 1 || this.overTempflag == 0)
            ) {
              generateAlertMessage(
                this.dialog,
                "Humidity Over Temp should be greater than the Humidity Under Temp"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            }

            if (this.deviceeditForm.value.humiditytemperature1DCT.replace(/\s+/g, " ").trim() != "") 
            {
              if (this.deviceeditForm.value.alertmessage8DCT == null) 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Over Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (this.deviceeditForm.value.alertmessage8DCT.replace(/\s+/g, " ").trim() == "") 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Over Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (
                !/^[a-zA-Z0-9 ]+$/.test(
                  this.deviceeditForm.value.alertmessage8DCT
                )
              ) {
                generateAlertMessage(
                  this.dialog,
                  "Please Enter valid Alert Message for Humidity Over Temp"
                );
                this.enableSavebutton(Device_action.device_edit);
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Humidity2 == true) {
          if (this.deviceeditForm.value.humiditytemperature2DCT == null) 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Humidity Under Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (this.deviceeditForm.value.humiditytemperature2DCT.replace(/\s+/g, " ").trim() == "") 
          {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Humidity Under Temp");
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
              this.deviceeditForm.value.humiditytemperature2DCT
            )
          ) {
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Temperature for Humidity Under Temp"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else if (
            this.deviceeditForm.value.humiditytemperature2DCT < 0 ||
            this.deviceeditForm.value.humiditytemperature2DCT > 100
          ) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, Humidity Under Temperature should be in between 0 and 100"
            );
            this.enableSavebutton(Device_action.device_edit);
            return false;
          } else {
            overtempvalue = this.deviceeditForm.value.humiditytemperature1DCT;
            undertempvalue = this.deviceeditForm.value.humiditytemperature2DCT;
            this.oldUnderTemp =
              this.deviceeditForm.value.humiditytemperature2DCT;
            if (
              overtempvalue != 0 &&
              parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              this.humidityoverTempflag == 2
            ) {
              generateAlertMessage(
                this.dialog,
                "Humidity Under Temp should be lesser than the Humidity Over Temp"
              );
              this.enableSavebutton(Device_action.device_edit);
              return false;
            } 
            else if (
              this.deviceeditForm.value.humiditytemperature2DCT != ""
            ) 
            {
              if (this.deviceeditForm.value.alertmessage9DCT == null) 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Under Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (this.deviceeditForm.value.alertmessage9DCT.replace(/\s+/g, " ").trim() == "") 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Under Temp");
                this.enableSavebutton(Device_action.device_edit);
                return false;
              } 
              else if (
                !/^[a-zA-Z0-9 ]+$/.test(
                  this.deviceeditForm.value.alertmessage9DCT
                )
              ) {
                generateAlertMessage(
                  this.dialog,
                  "Please Enter valid Alert Message for Humidity Under Temp"
                );
                this.enableSavebutton(Device_action.device_edit);
                return false;
              }
            }
          }
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.UM) {
        devicetype = DeviceType.UM;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
        if (
          deviceFormValue.deviceid <= 0 ||
          deviceFormValue.deviceid > 1073741823
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Universal Monitor Id should be in between 1 and 1073741823"
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
        if (
          deviceFormValue.devicetypeid <= 0 ||
          deviceFormValue.devicetypeid > IRIdRange.maximum
        ) {
          generateAlertMessage(
            this.dialog,
            "Invalid Range, IR Id should be in between 1 and " +
              IRIdRange.maximum.toString()
          );
          this.enableSavebutton(Device_action.device_edit);
          return false;
        } else {
          this.deviceeditForm.controls["devicetypeid"].setValidators([
            Validators.required,
            Validators.pattern(appsettings.Number_Pattern),
          ]);
          this.deviceeditForm.controls["devicetypeid"].updateValueAndValidity();
          if (!this.deviceeditForm.valid) {
            this.enableSavebutton(Device_action.device_edit);
            return false;
          }
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Intercom) {
        devicetype = DeviceType.IC;
        DeviceId = deviceFormValue.deviceid;
        deviceFormValue.devicetypeid = deviceFormValue.deviceid;
        if(deviceFormValue.deviceid < 100 || deviceFormValue.deviceid > 9999999)
        {
          generateAlertMessage(this.dialog, "Invalid Range, Intercom Id should be in between 100 and 9999999");
          this.enableSavebutton(Device_action.device_edit);
          return false;
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Room)
        devicetype = DeviceType.Room;
      else if (deviceFormValue.devicename == DeviceTypeDesc.Pin)
        devicetype = DeviceType.Pin;
      else if (deviceFormValue.devicename == DeviceTypeDesc.FloorPlanNotes)
        devicetype = DeviceType.FloorPlanNotes;

      let Description = devicetype;
      if (
        (deviceFormValue.devicename == DeviceTypeDesc.DC ||
          deviceFormValue.devicename == DeviceTypeDesc.NewDC) &&
        deviceFormValue.description != undefined
      )
        Description = deviceFormValue.description;

      let DCTag = 0;
      if (this.checkDCTag == true) DCTag = 1;
      let DCLotiering = 0;
      if (this.checkDCLotiering == true) DCLotiering = 1;
      let DCUnauthorizedEgress = 0;
      if (this.checkDCUnauthorizedEgress == true) DCUnauthorizedEgress = 1;

      let PCRemovalDetection = 0;
      if (this.checkPCRemovalDetection == true) PCRemovalDetection = 1;

      let PCButtonPress = 0;
      if (this.checkPCButtonPress == true) PCButtonPress = 1;

      let PullCord = 0;
      if (this.checkPullCord == true) PullCord = 1;

      let DCT_EnableInput1 = 0;
      if (this.checkDCT_Tag1 == true) DCT_EnableInput1 = 1;

      let DCT_EnableInput2 = 0;
      if (this.checkDCT_Tag2 == true) DCT_EnableInput2 = 1;

      let DCT_EnableInput3 = 0;
      if (this.checkDCT_Tag3 == true) DCT_EnableInput3 = 1;

      let DCT_AmbientOverTemp1 = 0;
      if (this.checkDCT_Ambient1 == true) DCT_AmbientOverTemp1 = 1;

      let DCT_AmbientOverTemp2 = 0;
      if (this.checkDCT_Ambient2 == true) DCT_AmbientOverTemp2 = 1;

      let DCT_ProbeOverTemp = 0;
      if (this.checkDCT_Probe1 == true) DCT_ProbeOverTemp = 1;

      let DCT_ProbeUnderTemp = 0;
      if (this.checkDCT_Probe2 == true) DCT_ProbeUnderTemp = 1;

      let DCT_HumidityOverTemp = 0;
      if (this.checkDCT_Humidity1 == true) DCT_HumidityOverTemp = 1;

      let DCT_HumidityUnderTemp = 0;
      if (this.checkDCT_Humidity2 == true) DCT_HumidityUnderTemp = 1;

      let alertmessage1DCT = "";
      let alertmessage2DCT = "";
      let alertmessage3DCT = "";
      let alertmessage4DCT = "";
      let alertmessage5DCT = "";
      let alertmessage6DCT = "";
      let alertmessage7DCT = "";
      let alertmessage8DCT = "";
      let alertmessage9DCT = "";
      let temperature1DCT = "";
      let temperature2DCT = "";
      let probetemperature1DCT = "";
      let probetemperature2DCT = "";
      let humiditytemperature1DCT = "";
      let humiditytemperature2DCT = "";
      let rex_macid;
      let hrex_macid;
      
      let input1_StartHr = 0;
      let input1_StartMin = 0;      
      let input1_EndHr = 0;
      let input1_EndMin = 0;      
      let input1_checkAllDay = 0;                      
      let input2_StartHr = 0;
      let input2_StartMin = 0;      
      let input2_EndHr = 0;
      let input2_EndMin = 0;
      let input2_checkAllDay = 0;                
      let input3_StartHr = 0;
      let input3_StartMin = 0;
      let input3_EndHr = 0;
      let input3_EndMin = 0;
      let input3_checkAllDay = 0;                
      let starttime1 = 0;
      let endtime1 = 0;
      let starttime2 = 0;
      let endtime2 = 0;
      let starttime3 = 0;
      let endtime3 = 0;
      
      if (deviceFormValue.devicename == DeviceTypeDesc.UT) {
        if (deviceFormValue.alertmessage1DCT != null)
          alertmessage1DCT = deviceFormValue.alertmessage1DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.alertmessage2DCT != null)
          alertmessage2DCT = deviceFormValue.alertmessage2DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.alertmessage3DCT != null)
          alertmessage3DCT = deviceFormValue.alertmessage3DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.alertmessage4DCT != null)
          alertmessage4DCT = deviceFormValue.alertmessage4DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.alertmessage5DCT != null)
          alertmessage5DCT = deviceFormValue.alertmessage5DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.alertmessage6DCT != null)
          alertmessage6DCT = deviceFormValue.alertmessage6DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.alertmessage7DCT != null)
          alertmessage7DCT = deviceFormValue.alertmessage7DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.alertmessage8DCT != null)
          alertmessage8DCT = deviceFormValue.alertmessage8DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.alertmessage9DCT != null)
          alertmessage9DCT = deviceFormValue.alertmessage9DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.temperature1DCT != null)
          temperature1DCT = deviceFormValue.temperature1DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.temperature2DCT != null)
          temperature2DCT = deviceFormValue.temperature2DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.probetemperature1DCT != null)
          probetemperature1DCT = deviceFormValue.probetemperature1DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.probetemperature2DCT != null)
          probetemperature2DCT = deviceFormValue.probetemperature2DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.humiditytemperature1DCT != null)
          humiditytemperature1DCT = deviceFormValue.humiditytemperature1DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.humiditytemperature2DCT != null)
          humiditytemperature2DCT = deviceFormValue.humiditytemperature2DCT
            .replace(/\s+/g, " ")
            .trim();
            
        if (this.checkallinfo1 == true) input1_checkAllDay = 1;
        else input1_checkAllDay = 0;
    
        if (deviceFormValue.wr_start1 != undefined && deviceFormValue.wr_start1 != 0) {
          starttime1 = deviceFormValue.wr_start1.split(":");
          input1_StartHr = parseInt(starttime1[0]);
          input1_StartMin = starttime1[1].split(" ");
          input1_StartMin = parseInt(input1_StartMin[0]);
        }
        if (deviceFormValue.wr_end1 != undefined && deviceFormValue.wr_end1 != 0) {
          endtime1 = deviceFormValue.wr_end1.split(":");
          input1_EndHr = parseInt(endtime1[0]);
          input1_EndMin = endtime1[1].split(" ");
          input1_EndMin = parseInt(input1_EndMin[0]);
        }      
            
        if (this.checkallinfo2 == true) input2_checkAllDay = 1;
        else input2_checkAllDay = 0;
          
        if (deviceFormValue.wr_start2 != undefined && deviceFormValue.wr_start2 != 0) {
          starttime2 = deviceFormValue.wr_start2.split(":");
          input2_StartHr = parseInt(starttime2[0]);
          input2_StartMin = starttime2[1].split(" ");
          input2_StartMin = parseInt(input2_StartMin[0]);
        }
        if (deviceFormValue.wr_end2 != undefined && deviceFormValue.wr_end2 != 0) {
          endtime2 = deviceFormValue.wr_end2.split(":");
          input2_EndHr = parseInt(endtime2[0]);
          input2_EndMin = endtime2[1].split(" ");
          input2_EndMin = parseInt(input2_EndMin[0]);
        }
        
        if (this.checkallinfo3 == true) input3_checkAllDay = 1;
            else input3_checkAllDay = 0;
            
        if (deviceFormValue.wr_start3 != undefined && deviceFormValue.wr_start3 != 0) {
          starttime3 = deviceFormValue.wr_start3.split(":");
          input3_StartHr = parseInt(starttime3[0]);
          input3_StartMin = starttime3[1].split(" ");
          input3_StartMin = parseInt(input3_StartMin[0]);
        }
        if (deviceFormValue.wr_end3 != undefined && deviceFormValue.wr_end3 != 0) {
          endtime3 = deviceFormValue.wr_end3.split(":");
          input3_EndHr = parseInt(endtime3[0]);
          input3_EndMin = endtime3[1].split(" ");
          input3_EndMin = parseInt(input3_EndMin[0]);
        }
      }

      let _Description = "";
      if (deviceFormValue.description != "")
        _Description = deviceFormValue.description.replace(/\s+/g, " ").trim();
      else _Description = "";

      if (
        deviceFormValue.devicename == DeviceTypeDesc.DC ||
        deviceFormValue.devicename == DeviceTypeDesc.NewDC
      ) {
        if (deviceFormValue.roomname != "")
          _Description = deviceFormValue.roomname.replace(/\s+/g, " ").trim();
        else _Description = "";
      }
      if (strDeviceDualId == undefined) strDeviceDualId = 0;

      if (this.NewDCMacId == undefined) this.NewDCMacId = "";
      if (this.oldDcDualid == undefined) this.oldDcDualid = 0;
      if (this.strDeviceDualId == undefined) this.strDeviceDualId = 0;

      if (emitterId == undefined) emitterId = "";

      if (this.oldrexmacid == undefined) this.oldrexmacid = "";

      if (this.selectCategoryId == undefined) this.selectCategoryId = 0;

      let BuildingId = 0;
      if (this.new_data[0].BuildId == undefined) {
        BuildingId = parseInt(this.new_data[0].BuildingId);
      } else {
        BuildingId = parseInt(this.new_data[0].BuildId);
      }

      let edit_AlertState1 = this.edit_selectedAlertState1;
      if (edit_AlertState1 != 0) edit_AlertState1 = Number(edit_AlertState1);

      let edit_AlertState2 = this.edit_selectedAlertState2;
      if (edit_AlertState2 != 0) edit_AlertState2 = Number(edit_AlertState2);

      let edit_AlertState3 = this.edit_selectedAlertState3;
      if (edit_AlertState3 != 0) edit_AlertState3 = Number(edit_AlertState3);

      const editdevicedetails: EditDeviceforFloor = {
        authKey: this.authkey,
        campusId: parseInt(deviceFormValue.campusID),
        buildingId: BuildingId,
        floorId: parseInt(deviceFormValue.floorID),
        monitorId: parseInt(DeviceId),
        oldDeviceId: parseInt(this.olddeviceId),
        svgId: parseInt(deviceFormValue.svgId),
        svgDeviceType: parseInt(devicetype),
        updateMode: 2,
        location: _Description, //deviceFormValue.description.replace(/\s+/g, ' ').trim(),
        emitterID: emitterId.toString(),
        monitorType: deviceFormValue.devicename.toString(),
        vmDirection: Description.toString(),
        unitId: parseInt(deviceFormValue.unitID),
        deviceX: parseInt(deviceX),
        deviceY: parseInt(deviceY),
        polygonPoints: polypoints.toString(),
        oldEmiterId: this.oldrexmacid.toString(),
        dcDualId: parseInt(strDeviceDualId),
        oldDcDualId: parseInt(this.oldDcDualid),
        dcMacId: this.NewDCMacId.toString(),
        oldDCMacId: this.oldDCMacid.toString(),
        isDCTag: DCTag,
        isDCLotiering: DCLotiering,
        isDCUnauthorized: DCUnauthorizedEgress,
        ispcRemoveDetection: PCRemovalDetection,
        ispcButtonPress: PCButtonPress,
        isPullCord: PullCord,
        ispcMobilityMode: parseInt(this.checkPCMobilityMode),
        enableInput1: DCT_EnableInput1,
        alertState1: edit_AlertState1,
        alertMessage1: alertmessage1DCT,
        enableInput2: DCT_EnableInput2,
        alertState2: edit_AlertState2,
        alertMessage2: alertmessage2DCT,
        enableInput3: DCT_EnableInput3,
        alertState3: edit_AlertState3,
        alertMessage3: alertmessage3DCT,
        ambientOverTemp: DCT_AmbientOverTemp1,
        ambientOverDesc: temperature1DCT,
        ambientOverMessage: alertmessage4DCT,
        ambientUnderTemp: DCT_AmbientOverTemp2,
        ambientUnderDesc: temperature2DCT,
        ambientUnderMessage: alertmessage5DCT,
        probeOverTemp: DCT_ProbeOverTemp,
        probeOverDesc: probetemperature1DCT,
        probeOverMessage: alertmessage6DCT,
        probeUnderTemp: DCT_ProbeUnderTemp,
        probeUnderDesc: probetemperature2DCT,
        probeUnderMessage: alertmessage7DCT,
        humidityOverTemp: DCT_HumidityOverTemp,
        humidityOverDesc: humiditytemperature1DCT,
        humidityOverMessage: alertmessage8DCT,
        humidityUnderTemp: DCT_HumidityUnderTemp,
        humidityUnderDesc: humiditytemperature2DCT,
        humidityUnderMessage: alertmessage9DCT,
        resRoomCategory: parseInt(this.selectCategoryId),
        dataId: parseInt(deviceFormValue.dataId),
        isIndoor: this.IsIndoor,
        input1StartHr: input1_StartHr,
        input1StartMin: input1_StartMin,
        input1EndHr: input1_EndHr,
        input1EndMin: input1_EndMin,
        input1checkAllDay: input1_checkAllDay,        
        input2StartHr: input2_StartHr,
        input2StartMin: input2_StartMin,
        input2EndHr: input2_EndHr,
        input2EndMin: input2_EndMin,
        input2checkAllDay: input2_checkAllDay,        
        input3StartHr: input3_StartHr,
        input3StartMin: input3_StartMin,
        input3EndHr: input3_EndHr,
        input3EndMin: input3_EndMin,
        input3checkAllDay: input3_checkAllDay  
      };

      const emitterdetails: getEmitterDetsils = {
        authKey: this.authkey,
      };
      this.technologyService
        .getEmitterDetails(emitterdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((emitterdata: any[]) => {
          if (emitterdata != null && emitterdata != undefined) {
            for (let i = 0; i < emitterdata.length; i++) {
              const emidata = emitterdata[i].split(",");

              if (
                emidata[2] == DeviceTypeDesc.Emitter &&
                deviceFormValue.devicename == DeviceTypeDesc.Emitter
              ) {
                if (
                  emidata[0] == this.olddeviceId &&
                  emidata[1] == this.oldrexmacid
                )
                  continue;
                emitteridArr.push(emidata[0]);
                IridArr.push(emidata[1]);
              } else if (
                emidata[2] == DeviceTypeDesc.Rex &&
                deviceFormValue.devicename == DeviceTypeDesc.Rex
              ) {
                rex_macid = this.oldrexmacid; //deviceFormValue.devicetypeid.replace(/:/g, "_").replace(/-/g,"_");
                const pattern = /^([^0-9]*)$/;
                if (rex_macid > 0) {
                  if (!rex_macid.match(pattern)) rex_macid = rex_macid.toLowerCase();
                }
                if (
                  emidata[1] == this.olddeviceId &&
                  devicetype == DeviceType.Rex &&
                  emidata[0] == rex_macid
                ) {
                  continue;
                } else if (
                  emidata[1] == this.olddeviceId &&
                  emidata[0] == rex_macid
                ) {
                  continue;
                }

                if (!emidata[0].match(pattern))
                  macidArr.push(emidata[0].toLowerCase());
                else macidArr.push(emidata[0]);

                rexidArr.push(emidata[1]);
              } else if (
                emidata[2] == DeviceTypeDesc.LF &&
                deviceFormValue.devicename == DeviceTypeDesc.LF
              ) {
                if (
                  emidata[0] == this.olddeviceId &&
                  emidata[1] == this.oldrexmacid
                )
                  continue;
                e_LFidArr.push(emidata[0]);
                e_LFdevidArr.push(emidata[1]);
              } else if (
                (emidata[2] == DeviceTypeDesc.DC ||
                  emidata[2] == DeviceTypeDesc.NewDC) &&
                (deviceFormValue.devicename == DeviceTypeDesc.DC ||
                  deviceFormValue.devicename == DeviceTypeDesc.DC)
              ) {
                let dc_macid = this.oldDCMacid;
                dc_macid = dc_macid.toLowerCase();
                if (this.oldDcDualid != "" && this.oldDcDualid != null) {
                  if (
                    emidata[0] == this.olddeviceId &&
                    emidata[1] == this.oldrexmacid &&
                    emidata[3] == this.oldDcDualid &&
                    emidata[4] == dc_macid
                  )
                    continue;
                } else {
                  if (
                    emidata[0] == this.olddeviceId &&
                    emidata[1] == this.oldrexmacid &&
                    emidata[4] == dc_macid
                  )
                    continue;
                }
                e_DCidArr.push(emidata[0]);
                e_DCdevidArr.push(emidata[1]);
                e_DCdualidArr.push(emidata[3]);
                e_DCMacIdArr.push(emidata[4]);
              } else if (
                emidata[2] == DeviceTypeDesc.Monitor &&
                deviceFormValue.devicename == DeviceTypeDesc.Monitor
              ) {
                if (
                  emidata[0] == this.olddeviceId &&
                  emidata[1] == this.oldrexmacid
                )
                  continue;
                MonitoridArr.push(emidata[0]);
                MonitorIRidArr.push(emidata[1]);
              } else if (
                emidata[2] == DeviceTypeDesc.VW &&
                deviceFormValue.devicename == DeviceTypeDesc.VW
              ) {
                if (
                  emidata[0] == this.olddeviceId &&
                  emidata[1] == this.oldrexmacid
                )
                  continue;
                VWidArr.push(emidata[0]);
                VWIRidArr.push(emidata[1]);
              } else if (
                emidata[2] == DeviceTypeDesc.UM &&
                deviceFormValue.devicename == DeviceTypeDesc.UM
              ) {
                if (
                  emidata[0] == this.olddeviceId &&
                  emidata[1] == this.oldrexmacid
                )
                  continue;
                UMidArr.push(emidata[0]);
                UMIRidArr.push(emidata[1]);
              }
              if (emidata[2] == DeviceTypeDesc.HRex && deviceFormValue.devicename == DeviceTypeDesc.HRex) 
              {
                hrex_macid = this.oldrexmacid; //deviceFormValue.devicetypeid.replace(/:/g, "_").replace(/-/g,"_");
                const pattern = /^([^0-9]*)$/;
                if (hrex_macid > 0) {
                  if (!hrex_macid.match(pattern)) 
                    hrex_macid = hrex_macid.toLowerCase();
                }
                if (emidata[1] == this.olddeviceId && devicetype == DeviceType.HRex && emidata[0] == hrex_macid) 
                {
                  continue;
                } 
                else if (emidata[1] == this.olddeviceId && emidata[0] == hrex_macid) {
                  continue;
                }

                if (!emidata[0].match(pattern))
                  hrexmacidArr.push(emidata[0].toLowerCase());
                else hrexmacidArr.push(emidata[0]);

                hrexidArr.push(emidata[1]);
              }
            }
            let isCheckIrId = true;
            let isCheckEmitterId = true;
            let isCheckRexId = true;
            let isCheckRexMacId = true;
            let isCheckIfdevId = true;
            let isCheckLFId = true;
            let isCheckIfDCdevId = true;
            let isCheckDCId = true;
            let isCheckDCDualId = true;
            let isCheckDCMacId = true;
            let isCheckMonitorIRId = true;
            let isCheckMonitorId = true;
            let isCheckVWIRId = true;
            let isCheckVWId = true;
            let isCheckUMId = true;
            let isCheckUMIRId = true;

            if (
              e_IRID == deviceFormValue.deviceid ||
              deviceFormValue.deviceid === undefined ||
              deviceFormValue.deviceid === null
            ) {
              isCheckIrId = false;
            }

            if (
              e_EmitterId == deviceFormValue.devicetypeid ||
              deviceFormValue.devicetypeid === undefined ||
              deviceFormValue.devicetypeid === null
            ) {
              isCheckEmitterId = false;
            }

            if (
              rex_rexid == deviceFormValue.deviceid ||
              deviceFormValue.deviceid === undefined ||
              deviceFormValue.deviceid === null
            ) {
              isCheckRexId = false;
            }

            if (
              rex_macid == deviceFormValue.devicetypeid ||
              deviceFormValue.devicetypeid === undefined ||
              deviceFormValue.devicetypeid === null
            ) {
              isCheckRexMacId = false;
            }
            if (
              e_LFdevID == deviceFormValue.deviceid ||
              deviceFormValue.deviceid === undefined ||
              deviceFormValue.deviceid === null
            ) {
              isCheckIfdevId = false;
            }

            if (
              e_LFId == deviceFormValue.devicetypeid ||
              deviceFormValue.devicetypeid === undefined ||
              deviceFormValue.devicetypeid === null
            ) {
              isCheckLFId = false;
            }

            if (
              e_DCdevID == deviceFormValue.deviceid ||
              deviceFormValue.deviceid === undefined ||
              deviceFormValue.deviceid === null
            ) {
              isCheckIfDCdevId = false;
            }

            if (
              e_DCId == deviceFormValue.devicetypeid ||
              deviceFormValue.devicetypeid === undefined ||
              deviceFormValue.devicetypeid === null
            ) {
              isCheckDCId = false;
            }

            if (
              e_DCdualId == strDeviceDualId ||
              strDeviceDualId === undefined ||
              strDeviceDualId === null
            ) {
              isCheckDCDualId = false;
            }

            if (
              e_DCMacId == deviceFormValue.dcmacid ||
              deviceFormValue.dcmacid === undefined ||
              deviceFormValue.dcmacid === null
            ) {
              isCheckDCMacId = false;
            }

            if (
              e_MonitorIRID == deviceFormValue.deviceid ||
              deviceFormValue.deviceid === undefined ||
              deviceFormValue.deviceid === null
            ) {
              isCheckMonitorIRId = false;
            }

            if (
              e_MonitorId == deviceFormValue.devicetypeid ||
              deviceFormValue.devicetypeid === undefined ||
              deviceFormValue.devicetypeid === null
            ) {
              isCheckMonitorId = false;
            }

            if (
              e_VWIRID == deviceFormValue.deviceid ||
              deviceFormValue.deviceid === undefined ||
              deviceFormValue.deviceid === null
            ) {
              isCheckVWIRId = false;
            }

            if (
              e_VWId == deviceFormValue.devicetypeid ||
              deviceFormValue.devicetypeid === undefined ||
              deviceFormValue.devicetypeid === null
            ) {
              isCheckVWId = false;
            }

            if (
              e_UMIRID == deviceFormValue.deviceid ||
              deviceFormValue.deviceid === undefined ||
              deviceFormValue.deviceid === null
            ) {
              isCheckUMIRId = false;
            }

            if (
              e_UMId == deviceFormValue.devicetypeid ||
              deviceFormValue.devicetypeid === undefined ||
              deviceFormValue.devicetypeid === null
            ) {
              isCheckUMId = false;
            }

            if (deviceFormValue.devicename == DeviceTypeDesc.Emitter) {
              if (isCheckIrId) {
                if (IridArr.indexOf(deviceFormValue.deviceid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "TruLocator Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
              if (isCheckEmitterId) {
                if (emitteridArr.indexOf(deviceFormValue.devicetypeid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "IR Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
            } else if (deviceFormValue.devicename == DeviceTypeDesc.Rex) {
              if (isCheckRexId) {
                if (rexidArr.length > 0) {
                  if (rexidArr.indexOf(deviceFormValue.deviceid) > -1) {
                    generateAlertMessage(
                      this.dialog,
                      "Clear Repeater Id already exists, Please try with different Id"
                    );
                    this.enableSavebutton(Device_action.device_edit);
                    return;
                  }
                }
              }
              if (isCheckRexMacId) {
                let rexmacid = deviceFormValue.devicetypeid
                  .replace(/:/g, "_")
                  .replace(/-/g, "_");
                rexmacid = rexmacid.toLowerCase();
                if (macidArr.length > 0) {
                  if (macidArr.indexOf(rexmacid) > -1) {
                    generateAlertMessage(
                      this.dialog,
                      "Duplicate MAC Id found, Other Clear Repeater already has this MAC Id."
                    );
                    this.enableSavebutton(Device_action.device_edit);
                    return;
                  } 
                  // else if (rexmacid == this.getHRexMacId.toLowerCase()) {
                  //   generateAlertMessage(
                  //     this.dialog,
                  //     "Duplicate MAC Id found, Clear Router already has this MAC Id."
                  //   );
                  //   this.enableSavebutton(Device_action.device_edit);
                  //   return;
                  // } 
                  else if (hrexmacidArr.indexOf(rexmacid) > -1) {
                    this.deviceAddboolvalue = true;
                    this.enableSavebutton(Device_action.device_add);
                    generateAlertMessage(
                      this.dialog,
                      "Duplicate MAC Id found, Clear Router already has this MAC Id."
                    );
                    return;
                  } 
                  else if (rexmacid == this.getgatewaymacid.toLowerCase()) {
                    generateAlertMessage(
                      this.dialog,
                      "Duplicate MAC Id found, Clear Gateway already has this MAC Id."
                    );
                    this.enableSavebutton(Device_action.device_edit);
                    return;
                  }
                }
              }
            } else if (deviceFormValue.devicename == DeviceTypeDesc.LF) {
              if (isCheckIfdevId) {
                if (e_LFdevidArr.indexOf(deviceFormValue.deviceid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "LF Exciter Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
              if (isCheckLFId) {
                if (e_LFidArr.indexOf(deviceFormValue.devicetypeid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "LF Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
            } else if (
              deviceFormValue.devicename == DeviceTypeDesc.DC ||
              deviceFormValue.devicename == DeviceTypeDesc.NewDC
            ) {
              if (isCheckIfDCdevId) {
                if (e_DCdevidArr.indexOf(deviceFormValue.deviceid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "Security Controller Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
              if (isCheckDCDualId && strDeviceDualId) {
                if (e_DCdualidArr.indexOf(strDeviceDualId) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "UT Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
              if (isCheckDCId) {
                if (e_DCidArr.indexOf(deviceFormValue.devicetypeid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "LF Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
              if (isCheckDCMacId) {
                if (deviceFormValue.dcmacid.length > 0) {
                  if (e_DCMacIdArr.indexOf(deviceFormValue.dcmacid) > -1) {
                    generateAlertMessage(
                      this.dialog,
                      "Mac Id already exists, Please try with different Id"
                    );
                    this.enableSavebutton(Device_action.device_edit);
                    return;
                  }
                }
              }
            } else if (deviceFormValue.devicename == DeviceTypeDesc.Monitor) {
              if (isCheckMonitorIRId) {
                if (MonitorIRidArr.indexOf(deviceFormValue.deviceid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "Monitor Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
              if (isCheckMonitorId) {
                if (MonitoridArr.indexOf(deviceFormValue.devicetypeid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "IR Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
            } else if (deviceFormValue.devicename == DeviceTypeDesc.VW) {
              if (isCheckVWIRId) {
                if (VWIRidArr.indexOf(deviceFormValue.deviceid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "VW Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
              if (isCheckVWId) {
                if (VWidArr.indexOf(deviceFormValue.devicetypeid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "IR Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
            } else if (deviceFormValue.devicename == DeviceTypeDesc.UM) {
              if (isCheckUMIRId) {
                if (UMIRidArr.indexOf(deviceFormValue.deviceid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "Universal Monitor Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
              if (isCheckUMId) {
                if (UMidArr.indexOf(deviceFormValue.devicetypeid) > -1) {
                  generateAlertMessage(
                    this.dialog,
                    "IR Id already exists, Please try with different Id"
                  );
                  this.enableSavebutton(Device_action.device_edit);
                  return;
                }
              }
            }
          }

          this.editDeviceData(editdevicedetails);
        });
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - onSaveDeviceDialog() - " + err.message
      );
    }
  }

  editDeviceData(editdevicedetails) {
    try {
      this.technologyService
        .editDeviceData(editdevicedetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            if (
              editdevicedetails.monitorType == DeviceTypeDesc.Rex ||
              editdevicedetails.monitorType == DeviceTypeDesc.HRex
            ) {
              if (
                this.oldrexmacid != this.NewRexMacId ||
                this.olddeviceId != this.NewDeviceId
              ) {
                this.technologyService.reloadiniltc();
                this.technologyService.systemreset_ltc();
              }
            }
            if (editdevicedetails.monitorType == DeviceTypeDesc.Pullcord) {
              this.technologyService.reloadPullCordConfig_ltc();
            } else if (
              editdevicedetails.monitorType == DeviceTypeDesc.DC ||
              editdevicedetails.monitorType == DeviceTypeDesc.NewDC
            ) {
              this.apiService.reload_DC_Config();
            } else if (editdevicedetails.monitorType == DeviceTypeDesc.UT) {
              this.apiService.reloadDCTdevice();
            } else if (
              editdevicedetails.monitorType != DeviceTypeDesc.Rex &&
              editdevicedetails.monitorType != DeviceTypeDesc.HRex &&
              editdevicedetails.monitorType != DeviceTypeDesc.Gateway
            ) {
              this.apiService.reload_DrawRoom();
            }

            if (
              editdevicedetails.monitorType == DeviceTypeDesc.Rex ||
              editdevicedetails.monitorType == DeviceTypeDesc.HRex
            ) {
              if (
                this.oldrexmacid != this.NewRexMacId ||
                this.olddeviceId != this.NewDeviceId
              ) {
                generateAlertMessage(this.dialog, appsettings.SaveDeviceMac);
                this.thisDialogRef.close("flooreditclose");
              } else {
                generateAlertMessage(this.dialog, appsettings.SaveDevice);
                this.thisDialogRef.close("flooreditclose");
              }
            } else {
              generateAlertMessage(this.dialog, appsettings.SaveDevice);
              this.thisDialogRef.close("flooreditclose");
            }
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, apiresponse.toString());
            this.enableSavebutton(Device_action.device_edit);
            return;
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
            this.enableSavebutton(Device_action.device_edit);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - editDeviceData() - " + err.message
      );
    }
  }

  addDeviceData(adddeviceformvalue) {
    // const UnitId = 0;
    let emittermacid;
    const emitteridArr = [] as any[];
    const IridArr = [] as any[];
    const rexidArr = [] as any[];
    const LFidArr = [] as any[];
    const macidArr = [] as any[];
    const LFdevidArr = [] as any[];
    const e_EmitterId = 0;
    const e_IRID = 0;
    const rex_rexid = 0;
    const rex_macid = 0;
    const e_LFId = 0;
    const e_LFdevID = 0;
    let polyline = [];
    //  polyline = this.Polypoints.split(',');
    // let vertices;
    const DCidArr = [] as any[];
    const DCdevidArr = [] as any[];
    const DCdualidArr = [] as any[];
    const e_DCId = 0;
    const e_DCdevID = 0;
    const e_DCdualId = 0;
    let e_DCMacId;
    const DCMacIdArr = [] as any[];

    const MonitoridArr = [] as any[];
    const MonitorIRidArr = [] as any[];
    const e_MonitorId = 0;
    const e_MonitorIRID = 0;

    const VWidArr = [] as any[];
    const VWIRidArr = [] as any[];
    const e_VWId = 0;
    const e_VWIRID = 0;

    const UMidArr = [] as any[];
    const UMIRidArr = [] as any[];
    const e_UMId = 0;
    const e_UMIRID = 0;
    
    const hrexidArr = [] as any[];
    const hrexmacidArr = [] as any[];
    const hrex_hrexid = 0;
    const hrex_macid = 0;

    // let strdcMacId;
    this.strDeviceId = 0;
    this.selectedUnit = 5;
    this.showHrex = true;
    this.showGateway = true;
    this.strRoomName = "testdevicenewtech";
    const devicename = this.addDeviceName;
    const deviceX = this.Device_X;
    const deviceY = this.Device_Y;

    let devicetype;

    if (this.selectedUnit == 0) {
      this.deviceAddboolvalue = true;
      this.enableSavebutton(Device_action.device_add);
      generateAlertMessage(this.dialog, "Please select the Space location");
      return false;
    }
    if (!this.strRoomName.replace(/\s+/g, " ").trim()) {
      this.deviceAddboolvalue = true;
      this.enableSavebutton(Device_action.device_add);
      generateAlertMessage(this.dialog, "Please enter Location Name");
      return false;
    } else if (this.strRoomName.length > 30) {
      this.deviceAddboolvalue = true;
      this.enableSavebutton(Device_action.device_add);
      generateAlertMessage(this.dialog, "Please enter valid Location Name");
      return false;
    }

    if (!this.strDeviceId && !this.showHrex && !this.showGateway) {
      let DName = "";
      if (
        devicename == DeviceTypeDesc.Emitter ||
        devicename == DeviceTypeDesc.Monitor ||
        devicename == DeviceTypeDesc.VW ||
        devicename == DeviceTypeDesc.UM
      )
        DName = "IR ";
      else if (devicename == DeviceTypeDesc.HRex)
        DName = NewDeviceTypeDesc.HRex;
      else if (devicename == DeviceTypeDesc.Rex) DName = NewDeviceTypeDesc.Rex;
      else if (devicename == DeviceTypeDesc.LF) DName = " LF ";
      else if (devicename == DeviceTypeDesc.Pullcord)
        DName = NewDeviceTypeDesc.Pullcord;
      else if (
        devicename == DeviceTypeDesc.DC ||
        devicename == DeviceTypeDesc.NewDC
      )
        DName = " LF ";
      else if (devicename == DeviceTypeDesc.Gateway)
        DName = NewDeviceTypeDesc.Gateway;
      else if (devicename == DeviceTypeDesc.Intercom)
        DName = NewDeviceTypeDesc.Intercom;
      this.deviceAddboolvalue = true;
      this.enableSavebutton(Device_action.device_add);
      generateAlertMessage(this.dialog, "Please enter " + DName + "  id");
      return false;
    }

    if (devicename == DeviceTypeDesc.Emitter) {
      devicetype = DeviceType.Emitter;
      if (isNumeric(this.deviceaddForm.value.devicetypeid))
        this.strDeviceId = parseInt(this.deviceaddForm.value.devicetypeid);
      if (isNumeric(this.deviceaddForm.value.deviceid))
        this.strEmitterId = this.deviceaddForm.value.deviceid;
      if (this.strEmitterId < 0 || this.strEmitterId > 1073741823) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, TruLocator Id should be in between 1 and 1073741823"
        );
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }

      if (!this.strDeviceId) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(this.dialog, "Please enter IR Id");
        return false;
      }
      if (this.strDeviceId < 0 || this.strDeviceId > IRIdRange.maximum) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, IR Id should be in between 1 and " +
            IRIdRange.maximum.toString()
        );
        return false;
      } else {
        this.deviceaddForm.controls["devicetypeid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["devicetypeid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.enableSavebutton(Device_action.device_add);
          this.deviceAddboolvalue = true;
          return false;
        }
      }
    } else if (devicename == DeviceTypeDesc.HRex && (this.clearRouterDetails == undefined || this.checkRouterdata == 1)) {
      devicetype = DeviceType.HRex;
      this.strDeviceId = this.addRouterId;
      
      if(this.checkHRexvalue == 1)
      {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(this.dialog,"Already the Clear Router added");
        return false;
      }      
      else if (this.strDeviceId <= 0 || this.strDeviceId > 1) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Not allowed to change the Device Id of Clear Router,it should be 1"
        );
        return false;
      }
      if (this.getHRexMacId != this.HRexMacId) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Not allowed to change the Clear Router Mac Id"
        );
        return false;
      }
      this.strEmitterId = this.HRexMacId.replace(/:/g, "_");
    }
    else if (devicename == DeviceTypeDesc.HRex && this.clearRouterDetails != undefined && this.hrexCount > 1) {
      devicetype = DeviceType.HRex;
      this.strDeviceId = this.selectRouterId;
      
      if(this.routerCount == 0)
      {
        this.deviceAddboolvalue = true;
        generateAlertMessage(this.dialog,"Maximum number of Clear Router added");
        this.enableSavebutton(Device_action.device_add);
        return false;
      }
      
      if (this.strDeviceId == undefined) {
        this.deviceaddForm.controls["deviceid"].setValidators([Validators.required]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }          
      if (this.selectRouterMacId == undefined) {
        this.deviceaddForm.controls["hrexmacid"].setValidators([Validators.required]);
        this.deviceaddForm.controls["hrexmacid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }   
        
      this.selectRouterMacId = this.deviceaddForm.value.hrexmacid;
      this.strEmitterId = this.selectRouterMacId.replace(/:/g, "_");
    }
     else if (devicename == DeviceTypeDesc.Rex) {
      devicetype = DeviceType.Rex;
      this.strEmitterId = 0;
      this.strLFId = 0;
      this.strDeviceId = parseInt(this.deviceaddForm.value.deviceid);
      this.strMacId = this.deviceaddForm.value.devicetypeid;
      let rexMinId = parseInt(this.hrexMaxCount);
      if(rexMinId == 1)
        rexMinId = 5;
      if (this.strDeviceId <= rexMinId || this.strDeviceId > 200) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        if(this.hrexMaxCount == 0 && this.hrexMaxCount == undefined)
          generateAlertMessage(this.dialog,"Invalid Range, Clear Repeater Id should be in between 2 and 200");
        else
        {    
          //let rexmacId = parseInt(rexMinId.toString()) + 1      
          generateAlertMessage(this.dialog,"Invalid Range, Clear Repeater Id should be in between 6 and 200");
        }
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }

      this.deviceaddForm.controls["devicetypeid"].setValidators([
        Validators.required,
        Validators.pattern(
         appsettings.Mac_Pattern
        ),
      ]);
      this.deviceaddForm.controls["devicetypeid"].updateValueAndValidity();
      if (!this.deviceaddForm.valid) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        return false;
      }
    } 
    else if (devicename == DeviceTypeDesc.LF) {
      if(this.selectedLFDeviceType == 1)
        devicetype = DeviceType.LF;
      else if(this.selectedLFDeviceType == 2)
        devicetype = DeviceType.DripTray;
      this.strEmitterId = this.deviceaddForm.value.deviceid;
      this.strDeviceId = parseInt(this.deviceaddForm.value.devicetypeid);
      // this.strLFId = 0;
      if (this.strEmitterId <= 0 || this.strEmitterId > 9999999) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        if(this.selectedLFDeviceType == 1)
          generateAlertMessage(this.dialog, "Invalid Range, LF Exciter Id should be in between 1 and 9999999");
        else if(this.selectedLFDeviceType == 2)
          generateAlertMessage(this.dialog, "Invalid Range, Device Id should be in between 1 and 9999999");
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }

      if (!this.strDeviceId) {
        this.deviceAddboolvalue = true;
        generateAlertMessage(this.dialog, "Please enter LF Id");
        return false;
      }
      if (this.strDeviceId <= 0 || this.strDeviceId > LFIdRange.maximum) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, LF Id should be in between 1 and " +
            LFIdRange.maximum.toString()
        );
        return false;
      } else {
        this.deviceaddForm.controls["devicetypeid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["devicetypeid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
    } else if (devicename == DeviceTypeDesc.Pullcord) {
      devicetype = DeviceType.Pullcord;
      this.strDeviceId = parseInt(this.deviceaddForm.value.deviceid);
      if (this.strDeviceId <= 0 || this.strDeviceId > 1073741823) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, Pull Cord Id should be in between 1 and 1073741823"
        );
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
    } else if (
      devicename == DeviceTypeDesc.DC ||
      devicename == DeviceTypeDesc.NewDC
    ) {
      devicetype = DeviceType.DC;
      this.strDeviceId = parseInt(this.deviceaddForm.value.devicetypeid);
      this.strEmitterId = this.deviceaddForm.value.deviceid;
      this.strDeviceDualId = this.deviceaddForm.value.devicedualid;
      this.strDCMacId = this.deviceaddForm.value.dcmacid;
      this.strLocation = this.deviceaddForm.value.roomname;

      if (!this.strEmitterId) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Please enter Security Controller Id"
        );
        return false;
      }
      if (
        this.strEmitterId <= 0 ||
        this.strEmitterId > SecurityControllerIdRange.maximum
      ) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, Security Controller Id should be in between 1 and " +
            SecurityControllerIdRange.maximum.toString()
        );
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
      if (
        this.strDeviceId <= 0 ||
        (this.strDeviceId < SecurityController_LFIdRange.minimum || this.strDeviceId > SecurityController_LFIdRange.maximum)
      ) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, LF Id should be in between " + SecurityController_LFIdRange.minimum.toString() + " and " +
            SecurityController_LFIdRange.maximum.toString()
        );
        return false;
      } else {
        this.deviceaddForm.controls["devicetypeid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["devicetypeid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
      if (this.strDeviceDualId && this.strDeviceDualId.toString() != "") {
        if (this.strDeviceDualId <= 0 || this.strDeviceDualId > 1073741823) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Invalid Range, UT Id should be in between 1 and 1073741823"
          );
          return false;
        } else {
          this.deviceaddForm.controls["devicedualid"].setValidators([
            Validators.pattern(appsettings.Number_Pattern),
          ]);
          this.deviceaddForm.controls["devicedualid"].updateValueAndValidity();
          if (!this.deviceaddForm.valid) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            return false;
          }
        }
      } else if (this.strDeviceDualId.toString() == "") {
        this.strDeviceDualId = 0;
      }

      if (this.strDCMacId != "") {
        this.deviceaddForm.controls["dcmacid"].setValidators([
          Validators.required,
          Validators.pattern(
           appsettings.Mac_Pattern
          ),
        ]);
        this.deviceaddForm.controls["dcmacid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
      if (!this.strLocation) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Please enter Security Controller Loaction"
        );
        return false;
      } else if (this.strLocation != "") {
        this.deviceaddForm.controls["description"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Name_Pattern),
        ]);
        this.deviceaddForm.controls["description"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
    } else if (devicename == DeviceTypeDesc.Monitor) {
      devicetype = DeviceType.Monitor;
      this.strDeviceId = parseInt(this.deviceaddForm.value.devicetypeid);
      this.strEmitterId = this.deviceaddForm.value.deviceid;
      if (this.strEmitterId <= 0 || this.strEmitterId > 1073741823) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, Monitor Id should be in between 1 and 1073741823"
        );
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }

      if (!this.strDeviceId) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(this.dialog, "Please enter IR Id");
        return false;
      }
      if (this.strDeviceId <= 0 || this.strDeviceId > IRIdRange.maximum) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, IR Id should be in between 1 and " +
            IRIdRange.maximum.toString()
        );
        return false;
      } else {
        this.deviceaddForm.controls["devicetypeid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["devicetypeid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
    } else if (devicename == DeviceTypeDesc.VW) {
      devicetype = DeviceType.VW;
      this.strDeviceId = parseInt(this.deviceaddForm.value.devicetypeid);
      this.strEmitterId = this.deviceaddForm.value.deviceid;
      if (this.strEmitterId <= 0 || this.strEmitterId > 1073741823) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, VW Id should be in between 1 and 1073741823"
        );
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }

      if (!this.strDeviceId) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(this.dialog, "Please enter IR Id");
        return false;
      }
      if (this.strDeviceId <= 0 || this.strDeviceId > IRIdRange.maximum) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, IR Id should be in between 1 and " +
            IRIdRange.maximum.toString()
        );
        return false;
      } else {
        this.deviceaddForm.controls["devicetypeid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["devicetypeid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
    } else if (devicename == DeviceTypeDesc.Gateway) {
      devicetype = DeviceType.Gateway;
      this.strDeviceId = this.addGatewayId;
      
      if(this.checkgateway == 1)
      {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(this.dialog,"Already the Gateway added");
        return false;
      }      
      else if (this.strDeviceId != 0) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Not allowed to change the Device id of Clear Gateway,it should be 0"
        );
        return false;
      }
      this.strEmitterId = this.GatewayMacId;

      this.GatewayMacId = localStorage.getItem("applianceSN");
      this.strEmitterId = this.GatewayMacId;
    } else if (devicename == DeviceTypeDesc.UT) {
      devicetype = DeviceType.UT;
      this.strDeviceId = parseInt(this.deviceaddForm.value.deviceid);
      let overtempvalue;
      let undertempvalue;
      if (this.strDeviceId == null) {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
      if (this.strDeviceId <= 0 || this.strDeviceId > 1073741823) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, UT Id should be in between 1 and 1073741823"
        );
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }

      if (this.checkDCT_Tag1 == true) {
        if (this.selectedAlertState1 == 0) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please select Alert State for Input1"
          );
          return false;
        } else if (this.selectedAlertState1 != 0) {
          if (this.deviceaddForm.value.alertmessage1DCT == null) 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Input1");
            return false;
          } 
          else if (this.deviceaddForm.value.alertmessage1DCT.replace(/\s+/g, " ").trim() == "") 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Input1");
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage1DCT)
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Alert Message for Input1"
            );
            return false;
          }
        }
        if ((this.deviceaddForm.value.wr_start1 == "" || this.deviceaddForm.value.wr_start1 == undefined) && (this.deviceaddForm.value.wr_end1 == "" ||
            this.deviceaddForm.value.wr_end1 == undefined) && (this.checkallinfo1 == false || this.checkallinfo1 == undefined)) 
        {              
          generateAlertMessage(this.dialog,"Please select Input1 Time info or All");
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }

        if (this.deviceaddForm.value.wr_start1 != "" && this.deviceaddForm.value.wr_start1 != undefined) 
        {
          if (this.deviceaddForm.value.wr_end1 == "" || this.deviceaddForm.value.wr_end1 == undefined) 
          {                
            generateAlertMessage(this.dialog,"Please select Input1 End time");
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            return false;
          }
        }

        if (this.deviceaddForm.value.wr_end1 != "" && this.deviceaddForm.value.wr_end1 != undefined) 
        {
          if (this.deviceaddForm.value.wr_start1 == "" || this.deviceaddForm.value.wr_start1 == undefined) 
          {                
            generateAlertMessage(this.dialog,"Please select Input1 Start time");
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            return false;
          }
        }
        
      } else if (this.checkDCT_Tag1 == false) {
        if (
          this.selectedAlertState1 != 0 ||
          (this.deviceaddForm.value.alertmessage1DCT != "" &&
            this.deviceaddForm.value.alertmessage1DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog, "Please Enble the Input1");
          return false;
        }
      }

      if (this.checkDCT_Tag2 == true) {
        if (this.selectedAlertState2 == 0) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please select Alert State for Input2"
          );
          return false;
        } else if (this.selectedAlertState2 != 0) {
          if (this.deviceaddForm.value.alertmessage2DCT == null) 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Input2");
            return false;
          } 
          else if (this.deviceaddForm.value.alertmessage2DCT.replace(/\s+/g, " ").trim() == "") 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Input2");
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage2DCT)
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Alert Message for Input2"
            );
            return false;
          }
        }
        if ((this.deviceaddForm.value.wr_start2 == "" || this.deviceaddForm.value.wr_start2 == undefined) && (this.deviceaddForm.value.wr_end2 == "" ||
              this.deviceaddForm.value.wr_end2 == undefined) && (this.checkallinfo2 == false || this.checkallinfo2 == undefined)) 
        {              
          generateAlertMessage(this.dialog,"Please select Input2 Time info or All");
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }

        if (this.deviceaddForm.value.wr_start2 != "" && this.deviceaddForm.value.wr_start2 != undefined) 
        {
          if (this.deviceaddForm.value.wr_end2 == "" || this.deviceaddForm.value.wr_end2 == undefined) 
          {                
            generateAlertMessage(this.dialog,"Please select Input2 End time");
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            return false;
          }
        }

        if (this.deviceaddForm.value.wr_end2 != "" && this.deviceaddForm.value.wr_end2 != undefined) 
        {
          if (this.deviceaddForm.value.wr_start2 == "" || this.deviceaddForm.value.wr_start2 == undefined) 
          {                
            generateAlertMessage(this.dialog,"Please select Input2 Start time");
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            return false;
          }
        } 
        
      } else if (this.checkDCT_Tag2 == false) {
        if (
          this.selectedAlertState2 != 0 ||
          (this.deviceaddForm.value.alertmessage2DCT != "" &&
            this.deviceaddForm.value.alertmessage2DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog, "Please Enble the Input2");
          return false;
        }
      }

      if (this.checkDCT_Tag3 == true) {
        if (this.selectedAlertState3 == 0) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please select Alert State for Input3"
          );
          return false;
        } else if (this.selectedAlertState3 != 0) {
          if (this.deviceaddForm.value.alertmessage3DCT == null) 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Input3");
            return false;
          } 
          else if (this.deviceaddForm.value.alertmessage3DCT.replace(/\s+/g, " ").trim() == "") 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Input3");
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage3DCT)
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Alert Message for Input3"
            );
            return false;
          }
        }
        if ((this.deviceaddForm.value.wr_start3 == "" || this.deviceaddForm.value.wr_start3 == undefined) && (this.deviceaddForm.value.wr_end3 == "" ||
            this.deviceaddForm.value.wr_end3 == undefined) && (this.checkallinfo3 == false || this.checkallinfo3 == undefined)) 
        {
          // this.addResSpacebool = true;
          generateAlertMessage(this.dialog,"Please select Input3 Time info or All");
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }

        if (this.deviceaddForm.value.wr_start3 != "" && this.deviceaddForm.value.wr_start3 != undefined) 
        {
          if (this.deviceaddForm.value.wr_end3 == "" || this.deviceaddForm.value.wr_end3 == undefined) 
          {
            // this.addResSpacebool = true;
            generateAlertMessage(this.dialog,"Please select Input3 End time");
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            return false;
          }
        }

        if (this.deviceaddForm.value.wr_end3 != "" && this.deviceaddForm.value.wr_end3 != undefined) 
        {
          if (this.deviceaddForm.value.wr_start3 == "" || this.deviceaddForm.value.wr_start3 == undefined) 
          {
            // this.addResSpacebool = true;
            generateAlertMessage(this.dialog,"Please select Input3 Start time");
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            return false;
          }
        }
        
      } else if (this.checkDCT_Tag3 == false) {
        if (
          this.selectedAlertState3 != 0 ||
          (this.deviceaddForm.value.alertmessage3DCT != "" &&
            this.deviceaddForm.value.alertmessage3DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog, "Please Enble the Input3");
          return false;
        }
      }

      if (this.checkDCT_Ambient1 == true) {
        if (this.deviceaddForm.value.temperature1DCT == null) 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Over Temp");
          return false;
        } 
        else if (this.deviceaddForm.value.temperature1DCT.replace(/\s+/g, " ").trim() == "") 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Over Temp");
          return false;
        } 
        else if (
          !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
            this.deviceaddForm.value.temperature1DCT
          )
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enter valid Temperature for Over Temp"
          );
          return false;
        } else if (
          this.deviceaddForm.value.temperature1DCT < -45 ||
          this.deviceaddForm.value.temperature1DCT > 125
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Over Temperature should be in between -45 and 125"
          );
          return false;
        } else {
          overtempvalue = this.deviceaddForm.value.temperature1DCT;
          undertempvalue = this.deviceaddForm.value.temperature2DCT;
          if (
            parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
            this.overTempflag == 1
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Over Temp should be greater than the Under Temp"
            );
            return false;
          }
        }
        if (
          this.deviceaddForm.value.temperature1DCT
            .replace(/\s+/g, " ")
            .trim() != ""
        ) {
          overtempvalue = this.deviceaddForm.value.temperature1DCT;
          if (this.deviceaddForm.value.alertmessage4DCT == null) 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Over Temp");
            return false;
          } 
          else if (this.deviceaddForm.value.alertmessage4DCT.replace(/\s+/g, " ").trim() == "") 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Over Temp");
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage4DCT)
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Alert Message for Over Temp"
            );
            return false;
          }
        }
      } else if (this.checkDCT_Ambient1 == false) {
        if (
          (this.deviceaddForm.value.temperature1DCT != "" &&
            this.deviceaddForm.value.temperature1DCT != null) ||
          (this.deviceaddForm.value.alertmessage4DCT != "" &&
            this.deviceaddForm.value.alertmessage4DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog, "Please Enble the Over Temp");
          return false;
        }
      }

      if (this.checkDCT_Ambient2 == true) {
        if (this.deviceaddForm.value.temperature2DCT == null) 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Under Temp");
          return false;
        } 
        else if (this.deviceaddForm.value.temperature2DCT.replace(/\s+/g, " ").trim() == "") 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Under Temp");
          return false;
        } 
        else if (
          !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
            this.deviceaddForm.value.temperature2DCT
          ) &&
          this.overTempflag == 1
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enter valid Temperature for Under Temp"
          );
          return false;
        } else if (
          this.deviceaddForm.value.temperature2DCT < -45 ||
          this.deviceaddForm.value.temperature2DCT > 125
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Under Temperature should be in between -45 and 125"
          );
          return false;
        } else {
          undertempvalue = this.deviceaddForm.value.temperature2DCT;
          if (
            parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
            this.overTempflag == 2
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Under Temp should be lesser than the Over Temp"
            );
            return false;
          } else if (
            this.deviceaddForm.value.temperature2DCT
              .replace(/\s+/g, " ")
              .trim() != ""
          ) {
            undertempvalue = this.deviceaddForm.value.temperature2DCT;
            if (this.deviceaddForm.value.alertmessage5DCT == null) 
            {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Under Temp");
              return false;
            } 
            else if (this.deviceaddForm.value.alertmessage5DCT.replace(/\s+/g, " ").trim() == "") 
            {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Under Temp");
              return false;
            } 
            else if (
              !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage5DCT)
            ) {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(
                this.dialog,
                "Please Enter valid Alert Message for Under Temp"
              );
              return false;
            }
          }
        }
      } else if (this.checkDCT_Ambient2 == false) {
        if (
          (this.deviceaddForm.value.temperature2DCT != "" &&
            this.deviceaddForm.value.temperature2DCT != null) ||
          (this.deviceaddForm.value.alertmessage5DCT != "" &&
            this.deviceaddForm.value.alertmessage5DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog, "Please Enble the Under Temp");
          return false;
        }
      }

      if (this.checkDCT_Probe1 == true) {
        if (this.deviceaddForm.value.probetemperature1DCT == null) 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Over Temp");
          return false;
        } 
        else if (this.deviceaddForm.value.probetemperature1DCT.replace(/\s+/g, " ").trim() == "") 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Over Temp");
          return false;
        } 
        else if (
          !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
            this.deviceaddForm.value.probetemperature1DCT
          )
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enter valid Temperature for Probe Over Temp"
          );
          return false;
        } else if (
          this.deviceaddForm.value.probetemperature1DCT < -45 ||
          this.deviceaddForm.value.probetemperature1DCT > 125
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Probe Over Temperature should be in between -45 and 125"
          );
          return false;
        } else {
          overtempvalue = this.deviceaddForm.value.probetemperature1DCT;
          undertempvalue = this.deviceaddForm.value.probetemperature2DCT;
          if (
            parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
            this.probeoverTempflag == 1
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Probe Over Temp should be greater than the Probe Under Temp"
            );
            return false;
          }
        }
        if (
          this.deviceaddForm.value.probetemperature1DCT
            .replace(/\s+/g, " ")
            .trim() != ""
        ) {
          overtempvalue = this.deviceaddForm.value.probetemperature1DCT;
          if (this.deviceaddForm.value.alertmessage6DCT == null) 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Over Temp");
            return false;
          }
          else if (this.deviceaddForm.value.alertmessage6DCT.replace(/\s+/g, " ").trim() == "") 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Over Temp");
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage6DCT)
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Alert Message for Probe Over Temp"
            );
            return false;
          }
        }
      } else if (this.checkDCT_Probe1 == false) {
        if (
          (this.deviceaddForm.value.probetemperature1DCT != "" &&
            this.deviceaddForm.value.probetemperature1DCT != null) ||
          (this.deviceaddForm.value.alertmessage6DCT != "" &&
            this.deviceaddForm.value.alertmessage6DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog, "Please Enble the Probe Over Temp");
          return false;
        }
      }

      if (this.checkDCT_Probe2 == true) {
        if (this.deviceaddForm.value.probetemperature2DCT == null) 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Under Temp");
          return false;
        } 
        else if (this.deviceaddForm.value.probetemperature2DCT.replace(/\s+/g, " ").trim() == "") 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Under Temp");
          return false;
        } 
        else if (
          !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
            this.deviceaddForm.value.probetemperature2DCT
          ) &&
          this.probeoverTempflag == 1
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enter valid Temperature for Probe Under Temp"
          );
          return false;
        } else if (
          this.deviceaddForm.value.probetemperature2DCT < -45 ||
          this.deviceaddForm.value.probetemperature2DCT > 125
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Probe Over Temperature should be in between -45 and 125"
          );
          return false;
        } else {
          overtempvalue = this.deviceaddForm.value.probetemperature1DCT;
          undertempvalue = this.deviceaddForm.value.probetemperature2DCT;
          if (
            parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
            this.probeoverTempflag == 2
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Probe Under Temp should be lesser than the Probe Over Temp"
            );
            return false;
          } else if (
            this.deviceaddForm.value.probetemperature2DCT
              .replace(/\s+/g, " ")
              .trim() != ""
          ) {
            undertempvalue = this.deviceaddForm.value.probetemperature2DCT;
            if (this.deviceaddForm.value.alertmessage7DCT == null) 
            {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Under Temp");
              return false;
            } 
            else if (this.deviceaddForm.value.alertmessage7DCT.replace(/\s+/g, " ").trim() == "") 
            {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Under Temp");
              return false;
            } 
            else if (
              !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage7DCT)
            ) {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(
                this.dialog,
                "Please Enter valid Alert Message for Probe Under Temp"
              );
              return false;
            }
          }
        }
      } else if (this.checkDCT_Probe2 == false) {
        if (
          (this.deviceaddForm.value.probetemperature2DCT != "" &&
            this.deviceaddForm.value.probetemperature2DCT != null) ||
          (this.deviceaddForm.value.alertmessage7DCT != "" &&
            this.deviceaddForm.value.alertmessage7DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enble the Probe Under Temp"
          );
          return false;
        }
      }

      if (this.checkDCT_Humidity1 == true) {
        if (this.deviceaddForm.value.humiditytemperature1DCT == null) 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Humidity Over Temp");
          return false;
        } 
        else if (this.deviceaddForm.value.humiditytemperature1DCT.replace(/\s+/g, " ").trim() == "") 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Humidity Over Temp");
          return false;
        } 
        else if (
          !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
            this.deviceaddForm.value.humiditytemperature1DCT
          )
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enter valid Temperature for Humidity Over Temp"
          );
          return false;
        } else if (
          this.deviceaddForm.value.humiditytemperature1DCT < 0 ||
          this.deviceaddForm.value.humiditytemperature1DCT > 100
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Humidity Over Temperature should be in between 0 and 100"
          );
          return false;
        } else {
          overtempvalue = this.deviceaddForm.value.humiditytemperature1DCT;
          undertempvalue = this.deviceaddForm.value.Humiditytemperature2DCT;
          if (
            parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
            this.humidityoverTempflag == 1
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Humidity Over Temp should be greater than the Humidity Under Temp"
            );
            return false;
          }
        }
        if (
          this.deviceaddForm.value.humiditytemperature1DCT
            .replace(/\s+/g, " ")
            .trim() != ""
        ) {
          overtempvalue = this.deviceaddForm.value.humiditytemperature1DCT;
          if (this.deviceaddForm.value.alertmessage8DCT == null) 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Over Temp");
            return false;
          } 
          else if (this.deviceaddForm.value.alertmessage8DCT.replace(/\s+/g, " ").trim() == "") 
          {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Over Temp");
            return false;
          } 
          else if (
            !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage8DCT)
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Alert Message for Humidity Over Temp"
            );
            return false;
          }
        }
      } else if (this.checkDCT_Humidity1 == false) {
        if (
          (this.deviceaddForm.value.humiditytemperature1DCT != "" &&
            this.deviceaddForm.value.humiditytemperature1DCT != null) ||
          (this.deviceaddForm.value.alertmessage8DCT != "" &&
            this.deviceaddForm.value.alertmessage8DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enble the Humidity Over Temp"
          );
          return false;
        }
      }

      if (this.checkDCT_Humidity2 == true) {
        if (this.deviceaddForm.value.humiditytemperature2DCT == null) 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Humidity Under Temp");
          return false;
        } 
        else if (this.deviceaddForm.value.humiditytemperature2DCT.replace(/\s+/g, " ").trim() == "") 
        {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog,"Please Enter Temperature for Humidity Under Temp");
          return false;
        } 
        else if (
          !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
            this.deviceaddForm.value.humiditytemperature2DCT
          ) &&
          this.probeoverTempflag == 1
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enter valid Temperature for Humidity Under Temp"
          );
          return false;
        } else if (
          this.deviceaddForm.value.humiditytemperature2DCT < 0 ||
          this.deviceaddForm.value.humiditytemperature2DCT > 100
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Invalid Range, Humidity Over Temperature should be in between 0 and 100"
          );
          return false;
        } else {
          overtempvalue = this.deviceaddForm.value.humiditytemperature1DCT;
          undertempvalue = this.deviceaddForm.value.humiditytemperature2DCT;
          if (
            parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
            this.humidityoverTempflag == 2
          ) {
            this.deviceAddboolvalue = true;
            this.enableSavebutton(Device_action.device_add);
            generateAlertMessage(
              this.dialog,
              "Humidity Under Temp should be lesser than the Humidity Over Temp"
            );
            return false;
          } else if (
            this.deviceaddForm.value.humiditytemperature2DCT
              .replace(/\s+/g, " ")
              .trim() != ""
          ) {
            undertempvalue = this.deviceaddForm.value.humiditytemperature2DCT;
            if (this.deviceaddForm.value.alertmessage9DCT == null) 
            {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Under Temp");
              return false;
            } 
            else if (this.deviceaddForm.value.alertmessage9DCT.replace(/\s+/g, " ").trim() == "") 
            {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Under Temp");
              return false;
            } 
            else if (
              !/^[a-zA-Z0-9 ]+$/.test(this.deviceaddForm.value.alertmessage9DCT)
            ) {
              this.deviceAddboolvalue = true;
              this.enableSavebutton(Device_action.device_add);
              generateAlertMessage(
                this.dialog,
                "Please Enter valid Alert Message for Humidity Under Temp"
              );
              return false;
            }
          }
        }
      } else if (this.checkDCT_Humidity2 == false) {
        if (
          (this.deviceaddForm.value.humiditytemperature2DCT != "" &&
            this.deviceaddForm.value.humiditytemperature2DCT != null) ||
          (this.deviceaddForm.value.alertmessage9DCT != "" &&
            this.deviceaddForm.value.alertmessage9DCT != null)
        ) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(
            this.dialog,
            "Please Enble the Probe Humidity Temp"
          );
          return false;
        }
      }
    } else if (devicename == DeviceTypeDesc.UM) {
      devicetype = DeviceType.UM;
      this.strDeviceId = parseInt(this.deviceaddForm.value.devicetypeid);
      this.strEmitterId = this.deviceaddForm.value.deviceid;
      if (this.strEmitterId <= 0 || this.strEmitterId > 1073741823) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, Universal Monitor Id should be in between 1 and 1073741823"
        );
        return false;
      } else {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }

      if (!this.strDeviceId) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(this.dialog, "Please enter IR Id");
        return false;
      }
      if (this.strDeviceId <= 0 || this.strDeviceId > IRIdRange.maximum) {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(
          this.dialog,
          "Invalid Range, IR Id should be in between 1 and " +
            IRIdRange.maximum.toString()
        );
        return false;
      } else {
        this.deviceaddForm.controls["devicetypeid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["devicetypeid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
        }
      }
    } else if (devicename == DeviceTypeDesc.Intercom) {
      devicetype = DeviceType.IC;
      this.strDeviceId = parseInt(this.deviceaddForm.value.deviceid);
      if(this.strDeviceId < 100 || this.strDeviceId > 9999999)
      {
        this.deviceAddboolvalue = true;
        this.enableSavebutton(Device_action.device_add);
        generateAlertMessage(this.dialog, "Invalid Range, Intercom Id should be in between 100 and 9999999");
        return false;
      }
      else
      {
        this.deviceaddForm.controls["deviceid"].setValidators([
          Validators.required,
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.deviceaddForm.controls["deviceid"].updateValueAndValidity();
        if (!this.deviceaddForm.valid) {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          return false;
          }
      }
    } else if (devicename == DeviceTypeDesc.Room) devicetype = DeviceType.Room;
    else if (devicename == DeviceTypeDesc.Pin) devicetype = DeviceType.Pin;
    else if (devicename == DeviceTypeDesc.FloorPlanNotes)
      devicetype = DeviceType.FloorPlanNotes;

    if (this.strEmitterId != undefined && this.strEmitterId != 0)
      emittermacid = this.strEmitterId;
    else if (this.strLFId != undefined && this.strLFId != 0)
      emittermacid = this.strLFId;

    if (
      devicename == DeviceTypeDesc.Rex ||
      devicename == DeviceTypeDesc.DC ||
      devicename == DeviceTypeDesc.NewDC
    ) {
      if (
        this.strMacId != undefined ||
        (this.strDCMacId != undefined && this.strDCMacId != "")
      ) {
        if (this.strMacId != undefined) emittermacid = this.strMacId;
        else emittermacid = this.strDCMacId;

        emittermacid = emittermacid.replace(/:/g, "_").replace(/-/g, "_");
        if (emittermacid.includes("_")) {
          const emitter_Id = emittermacid.split("_");
          emittermacid = "";
          for (let i = 0; i < emitter_Id.length; i++) {
            if (emitter_Id[i].length == 2) {
              emittermacid += emitter_Id[i] + "_";
            } else {
              emittermacid += emitter_Id[i].replace(/(.{2})/g, "$1_");
            }
          }
        } else emittermacid = emittermacid.replace(/(.{2})/g, "$1_");

        emittermacid = emittermacid.slice(0, -1);

        if (emittermacid == "00_00_00_00_00_00") {
          this.deviceAddboolvalue = true;
          this.enableSavebutton(Device_action.device_add);
          generateAlertMessage(this.dialog, "Please enter valid Mac Id");
          this.enableSavebutton(Device_action.device_add);
          return false;
        }

        if (
          devicename == DeviceTypeDesc.DC ||
          devicename == DeviceTypeDesc.NewDC
        ) {
          this.strDCMacId = emittermacid;
          emittermacid = this.strEmitterId;
        }
      }
    }

    let Description = devicetype;
    if (
      (devicename == DeviceTypeDesc.DC || devicename == DeviceTypeDesc.NewDC) &&
      adddeviceformvalue.description != undefined
    )
      Description = adddeviceformvalue.description;

    this.disableSavebutton(Device_action.device_add);

    let DCTag = 0;
    if (this.checkDCTag == true) DCTag = 1;
    let DCLotiering = 0;
    if (this.checkDCLotiering == true) DCLotiering = 1;
    let DCUnauthorizedEgress = 0;
    if (this.checkDCUnauthorizedEgress == true) DCUnauthorizedEgress = 1;

    let PCRemovalDetection = 0;
    if (this.checkPCRemovalDetection == true) PCRemovalDetection = 1;

    let PCButtonPress = 0;
    if (this.checkPCButtonPress == true) PCButtonPress = 1;

    let PullCord = 0;
    if (this.checkPullCord == true) PullCord = 1;

    let DCT_EnableInput1 = 0;
    if (this.checkDCT_Tag1 == true) DCT_EnableInput1 = 1;

    let DCT_EnableInput2 = 0;
    if (this.checkDCT_Tag2 == true) DCT_EnableInput2 = 1;

    let DCT_EnableInput3 = 0;
    if (this.checkDCT_Tag3 == true) DCT_EnableInput3 = 1;

    let DCT_AmbientOverTemp1 = 0;
    if (this.checkDCT_Ambient1 == true) DCT_AmbientOverTemp1 = 1;

    let DCT_AmbientOverTemp2 = 0;
    if (this.checkDCT_Ambient2 == true) DCT_AmbientOverTemp2 = 1;

    let DCT_ProbeOverTemp = 0;
    if (this.checkDCT_Probe1 == true) DCT_ProbeOverTemp = 1;

    let DCT_ProbeUnderTemp = 0;
    if (this.checkDCT_Probe2 == true) DCT_ProbeUnderTemp = 1;

    let DCT_HumidityOverTemp = 0;
    if (this.checkDCT_Humidity1 == true) DCT_HumidityOverTemp = 1;

    let DCT_HumidityUnderTemp = 0;
    if (this.checkDCT_Humidity2 == true) DCT_HumidityUnderTemp = 1;

    let alertmessage1DCT = "";
    let alertmessage2DCT = "";
    let alertmessage3DCT = "";
    let alertmessage4DCT = "";
    let alertmessage5DCT = "";
    let alertmessage6DCT = "";
    let alertmessage7DCT = "";
    let alertmessage8DCT = "";
    let alertmessage9DCT = "";
    let temperature1DCT = "";
    let temperature2DCT = "";
    let probetemperature1DCT = "";
    let probetemperature2DCT = "";
    let humiditytemperature1DCT = "";
    let humiditytemperature2DCT = "";
    let input1_StartHr = 0;
    let input1_StartMin = 0;      
    let input1_EndHr = 0;
    let input1_EndMin = 0;      
    let input1_checkAllDay = 0;            
    let input2_StartHr = 0;
    let input2_StartMin = 0;      
    let input2_EndHr = 0;
    let input2_EndMin = 0;
    let input2_checkAllDay = 0;    
    let input3_StartHr = 0;
    let input3_StartMin = 0;
    let input3_EndHr = 0;
    let input3_EndMin = 0;
    let input3_checkAllDay = 0;      
    let starttime1 = 0;
    let endtime1 = 0;
    let starttime2 = 0;
    let endtime2 = 0;
    let starttime3 = 0;
    let endtime3 = 0;

    if (devicename == DeviceTypeDesc.UT) {
      if (this.deviceaddForm.value.alertmessage1DCT != null)
        alertmessage1DCT = this.deviceaddForm.value.alertmessage1DCT
          .replace(/\s+/g, " ")
          .trim();
      if (this.deviceaddForm.value.alertmessage2DCT != null)
        alertmessage2DCT = this.deviceaddForm.value.alertmessage2DCT
          .replace(/\s+/g, " ")
          .trim();
      if (this.deviceaddForm.value.alertmessage3DCT != null)
        alertmessage3DCT = this.deviceaddForm.value.alertmessage3DCT
          .replace(/\s+/g, " ")
          .trim();
      if (this.deviceaddForm.value.alertmessage4DCT != null)
        alertmessage4DCT = this.deviceaddForm.value.alertmessage4DCT
          .replace(/\s+/g, " ")
          .trim();
      if (this.deviceaddForm.value.alertmessage5DCT != null)
        alertmessage5DCT = this.deviceaddForm.value.alertmessage5DCT
          .replace(/\s+/g, " ")
          .trim();

      if (this.deviceaddForm.value.alertmessage6DCT != null)
        alertmessage6DCT = this.deviceaddForm.value.alertmessage6DCT
          .replace(/\s+/g, " ")
          .trim();

      if (this.deviceaddForm.value.alertmessage7DCT != null)
        alertmessage7DCT = this.deviceaddForm.value.alertmessage7DCT
          .replace(/\s+/g, " ")
          .trim();

      if (this.deviceaddForm.value.alertmessage8DCT != null)
        alertmessage8DCT = this.deviceaddForm.value.alertmessage8DCT
          .replace(/\s+/g, " ")
          .trim();

      if (this.deviceaddForm.value.alertmessage9DCT != null)
        alertmessage9DCT = this.deviceaddForm.value.alertmessage9DCT
          .replace(/\s+/g, " ")
          .trim();

      if (this.deviceaddForm.value.temperature1DCT != null)
        temperature1DCT = this.deviceaddForm.value.temperature1DCT
          .replace(/\s+/g, " ")
          .trim();
      if (this.deviceaddForm.value.temperature2DCT != null)
        temperature2DCT = this.deviceaddForm.value.temperature2DCT
          .replace(/\s+/g, " ")
          .trim();

      if (this.deviceaddForm.value.probetemperature1DCT != null)
        probetemperature1DCT = this.deviceaddForm.value.probetemperature1DCT
          .replace(/\s+/g, " ")
          .trim();
      if (this.deviceaddForm.value.probetemperature2DCT != null)
        probetemperature2DCT = this.deviceaddForm.value.probetemperature2DCT
          .replace(/\s+/g, " ")
          .trim();

      if (this.deviceaddForm.value.humiditytemperature1DCT != null)
        humiditytemperature1DCT =
          this.deviceaddForm.value.humiditytemperature1DCT
            .replace(/\s+/g, " ")
            .trim();
      if (this.deviceaddForm.value.humiditytemperature2DCT != null)
        humiditytemperature2DCT =
          this.deviceaddForm.value.humiditytemperature2DCT
            .replace(/\s+/g, " ")
            .trim();
    }

    if (devicename == DeviceTypeDesc.Gateway)
      emittermacid = this.getgatewaymacid;
    else if (devicename == DeviceTypeDesc.HRex && (this.clearRouterDetails == undefined || this.checkRouterdata == 1))
      emittermacid = this.getHRexMacId;
    else if (devicename == DeviceTypeDesc.HRex && this.clearRouterDetails != undefined && this.hrexCount > 1)
      emittermacid = this.selectRouterMacId;

    let _RoomName = "";
    if (adddeviceformvalue.description != "")
      _RoomName = adddeviceformvalue.description.replace(/\s+/g, " ").trim();
    else _RoomName = "";

    if (devicename == DeviceTypeDesc.DC || devicename == DeviceTypeDesc.NewDC) {
      if (adddeviceformvalue.roomname != "")
        _RoomName = adddeviceformvalue.roomname.replace(/\s+/g, " ").trim();
      else _RoomName = "";
    }

    if (adddeviceformvalue.floorID > 0) polyline = this.Polypoints.trim();
    else polyline = this.Polypoints;

    if (this.strDCMacId == undefined) this.strDCMacId = "";

    if (this.strDeviceDualId == undefined) this.strDeviceDualId = 0;

    if (this.addselectCategoryId == undefined) this.addselectCategoryId = 0;

    if (emittermacid == undefined) emittermacid = "";
    
    let _alertState1 = this.selectedAlertState1;
    if (_alertState1 != 0) _alertState1 = Number(_alertState1);
    
    let _alertState2 = this.selectedAlertState2;
    if (_alertState2 != 0) _alertState2 = Number(_alertState2);
          
    let _alertState3 = this.selectedAlertState3;
    if (_alertState3 != 0) _alertState3 = Number(_alertState3);     
    
    if (this.checkallinfo1 == true) input1_checkAllDay = 1;
    else input1_checkAllDay = 0;
                  
    if (adddeviceformvalue.wr_start1 != undefined && adddeviceformvalue.wr_start1 != 0) {
      starttime1 = adddeviceformvalue.wr_start1.split(":");
      input1_StartHr = parseInt(starttime1[0]);
      input1_StartMin = starttime1[1].split(" ");
      input1_StartMin = parseInt(input1_StartMin[0]);
    }
    if (adddeviceformvalue.wr_end1 != undefined && adddeviceformvalue.wr_end1 != 0) {
      endtime1 = adddeviceformvalue.wr_end1.split(":");
      input1_EndHr = parseInt(endtime1[0]);
      input1_EndMin = endtime1[1].split(" ");
      input1_EndMin = parseInt(input1_EndMin[0]);
    }            
    
    if (this.checkallinfo2 == true) input2_checkAllDay = 1;
        else input2_checkAllDay = 0;
        
    if (adddeviceformvalue.wr_start2 != undefined && adddeviceformvalue.wr_start2 != 0) {
      starttime2 = adddeviceformvalue.wr_start2.split(":");
      input2_StartHr = parseInt(starttime2[0]);
      input2_StartMin = starttime2[1].split(" ");
      input2_StartMin = parseInt(input2_StartMin[0]);
    }
    if (adddeviceformvalue.wr_end2 != undefined && adddeviceformvalue.wr_end2 != 0) {
      endtime2 = adddeviceformvalue.wr_end2.split(":");
      input2_EndHr = parseInt(endtime2[0]);
      input2_EndMin = endtime2[1].split(" ");
      input2_EndMin = parseInt(input2_EndMin[0]);
    }
    
    if (this.checkallinfo3 == true) input3_checkAllDay = 1;
        else input3_checkAllDay = 0;
        
    if (adddeviceformvalue.wr_start3 != undefined && adddeviceformvalue.wr_start3 != 0) {
      starttime3 = adddeviceformvalue.wr_start3.split(":");
      input3_StartHr = parseInt(starttime3[0]);
      input3_StartMin = starttime3[1].split(" ");
      input3_StartMin = parseInt(input3_StartMin[0]);
    }
    if (adddeviceformvalue.wr_end3 != undefined && adddeviceformvalue.wr_end3 != 0) {
      endtime3 = adddeviceformvalue.wr_end3.split(":");
      input3_EndHr = parseInt(endtime3[0]);
      input3_EndMin = endtime3[1].split(" ");
      input3_EndMin = parseInt(input3_EndMin[0]);      
    }
    
    if(this.clearRouterDetails == undefined || this.checkRouterdata == 1)
      this.addUpdateMode = 1;   

    const adddevicedetails: addDeviceforFloor = {
      authKey: this.authkey,
      campusId: parseInt(adddeviceformvalue.campusID),
      buildingId: parseInt(this.new_data[0].BuildId),
      floorId: parseInt(adddeviceformvalue.floorID),
      monitorId: this.strDeviceId,
      oldDeviceId: 0,
      svgDeviceType: parseInt(devicetype),
      updateMode: this.addUpdateMode,
      location: _RoomName,
      emitterID: emittermacid.toString(),
      monitorType: devicename.toString(),
      vmDirection: Description.toString(),
      unitId: parseInt(adddeviceformvalue.unitID),
      deviceX: parseInt(deviceX),
      deviceY: parseInt(deviceY),
      polygonPoints: polyline.toString(),
      oldEmiterId: 0,
      dcDualId: parseInt(this.strDeviceDualId),
      oldDcDualId: 0,
      dCMacId: this.strDCMacId.toString(),
      oldDCMacId: "",
      isDCTag: DCTag,
      isDCLotiering: DCLotiering,
      isDCUnauthorized: DCUnauthorizedEgress,
      ispcRemoveDetection: PCRemovalDetection,
      ispcButtonPress: PCButtonPress,
      isPullCord: PullCord,
      ispcMobilityMode: parseInt(this.checkPCMobilityMode),
      enableInput1: DCT_EnableInput1,
      alertState1: _alertState1,
      alertMessage1: alertmessage1DCT,
      enableInput2: DCT_EnableInput2,
      alertState2: _alertState2,
      alertMessage2: alertmessage2DCT,
      enableInput3: DCT_EnableInput3,
      alertState3: _alertState3,
      alertMessage3: alertmessage3DCT,
      ambientOverTemp: DCT_AmbientOverTemp1,
      ambientOverDesc: temperature1DCT,
      ambientOverMessage: alertmessage4DCT,
      ambientUnderTemp: DCT_AmbientOverTemp2,
      ambientUnderDesc: temperature2DCT,
      ambientUnderMessage: alertmessage5DCT,
      probeOverTemp: DCT_ProbeOverTemp,
      probeOverDesc: probetemperature1DCT,
      probeOverMessage: alertmessage6DCT,
      probeUnderTemp: DCT_ProbeUnderTemp,
      probeUnderDesc: probetemperature2DCT,
      probeUnderMessage: alertmessage7DCT,
      humidityOverTemp: DCT_HumidityOverTemp,
      humidityOverDesc: humiditytemperature1DCT,
      humidityOverMessage: alertmessage8DCT,
      humidityUnderTemp: DCT_HumidityUnderTemp,
      humidityUnderDesc: humiditytemperature2DCT,
      humidityUnderMessage: alertmessage9DCT,
      resRoomCategory: parseInt(this.addselectCategoryId),
      dataId: parseInt(this.addData_Id),
      isIndoor: this.IsIndoor,
      input1StartHr: input1_StartHr,
      input1StartMin: input1_StartMin,
      input1EndHr: input1_EndHr,
      input1EndMin: input1_EndMin,
      input1checkAllDay : input1_checkAllDay,
      input2StartHr: input2_StartHr,
      input2StartMin: input2_StartMin,
      input2EndHr: input2_EndHr,
      input2EndMin: input2_EndMin,
      input2checkAllDay : input2_checkAllDay,
      input3StartHr: input3_StartHr,
      input3StartMin: input3_StartMin,
      input3EndHr: input3_EndHr,
      input3EndMin: input3_EndMin,
      input3checkAllDay : input3_checkAllDay
    };

    const emitterdetails: getEmitterDetsils = {
      authKey: this.authkey,
    };
    this.technologyService
      .getEmitterDetails(emitterdetails)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((emitterdata: any[]) => {
        if (emitterdata != null && emitterdata != undefined) {
          for (let i = 0; i < emitterdata.length; i++) {
            const emidata = emitterdata[i].split(",");
            if (
              emidata[2] == DeviceTypeDesc.Emitter &&
              this.deviceTypeName == DeviceTypeDesc.Emitter
            ) {
              emitteridArr.push(emidata[1]);
              IridArr.push(emidata[0]);
            } else if (
              emidata[2] == DeviceTypeDesc.Rex &&
              this.deviceTypeName == DeviceTypeDesc.Rex
            ) {
              macidArr.push(emidata[0].toLowerCase());
              rexidArr.push(emidata[1]);
            } else if (
              emidata[2] == DeviceTypeDesc.LF &&
              this.deviceTypeName == DeviceTypeDesc.LF
            ) {
              LFidArr.push(emidata[1]);
              LFdevidArr.push(emidata[0]);
            } else if (
              (emidata[2] == DeviceTypeDesc.DC ||
                emidata[2] == DeviceTypeDesc.NewDC) &&
              (this.deviceTypeName == DeviceTypeDesc.DC ||
                this.deviceTypeName == DeviceTypeDesc.NewDC)
            ) {
              DCidArr.push(emidata[1]);
              DCdevidArr.push(emidata[0]);
              DCdualidArr.push(emidata[3]);
              DCMacIdArr.push(emidata[4]);
            } else if (
              emidata[2] == DeviceTypeDesc.Monitor &&
              this.deviceTypeName == DeviceTypeDesc.Monitor
            ) {
              MonitoridArr.push(emidata[1]);
              MonitorIRidArr.push(emidata[0]);
            } else if (
              emidata[2] == DeviceTypeDesc.VW &&
              this.deviceTypeName == DeviceTypeDesc.VW
            ) {
              VWidArr.push(emidata[1]);
              VWIRidArr.push(emidata[0]);
            } else if (
              emidata[2] == DeviceTypeDesc.UM &&
              this.deviceTypeName == DeviceTypeDesc.UM
            ) {
              UMidArr.push(emidata[1]);
              UMIRidArr.push(emidata[0]);
            }
            else if (emidata[2] == DeviceTypeDesc.HRex && this.deviceTypeName == DeviceTypeDesc.HRex) 
            {
              hrexmacidArr.push(emidata[0].toLowerCase());
              hrexidArr.push(emidata[1]);
            }
          }

          let isCheckIrId = true;
          let isCheckEmitterId = true;
          let isCheckRexId = true;
          let isCheckRexMacId = true;
          let isCheckIfdevId = true;
          let isCheckLFId = true;
          // let isCheckPullcorddevId = true;
          let isCheckIfDCdevId = true;
          let isCheckDCId = true;
          let isCheckDCDualId = true;
          let isCheckDCMacId = true;
          let isCheckMonitorIRId = true;
          let isCheckMonitorId = true;
          let isCheckVWIRId = true;
          let isCheckVWId = true;
          let isCheckUMIRId = true;
          let isCheckUMId = true;

          let emirrterid = 0;
          // let cDeviceId = 0;

          if (emittermacid === undefined || emittermacid === null) {
            emirrterid = 0;
          } else if (isNumeric(emittermacid)) {
            emirrterid = parseInt(emittermacid);
          }
          else  {
            emirrterid = emittermacid;
          }

          if (
            e_IRID == this.strDeviceId ||
            this.strDeviceId === undefined ||
            this.strDeviceId === null
          ) {
            isCheckIrId = false;
          }

          if (e_EmitterId == emirrterid) {
            isCheckEmitterId = false;
          }

          if (
            rex_rexid == this.strDeviceId ||
            this.strDeviceId === undefined ||
            this.strDeviceId === null
          ) {
            isCheckRexId = false;
          }

          if (rex_macid == emirrterid) {
            isCheckRexMacId = false;
          }
          
          if (
            hrex_hrexid == this.strDeviceId ||
            this.strDeviceId === undefined ||
            this.strDeviceId === null
          ) {
            isCheckRexId = false;
          }

          if (hrex_macid == emirrterid) {
            isCheckRexMacId = false;
          }

          if (
            e_LFdevID == this.strDeviceId ||
            this.strDeviceId === undefined ||
            this.strDeviceId === null
          ) {
            isCheckIfdevId = false;
          }

          if (e_LFId == emirrterid) {
            isCheckLFId = false;
          }

          if (
            e_DCdevID == this.strDeviceId ||
            this.strDeviceId === undefined ||
            this.strDeviceId === null
          ) {
            isCheckIfDCdevId = false;
          }

          if (e_DCId == emirrterid) {
            isCheckDCId = false;
          }

          if (
            e_DCdualId == this.strDeviceDualId ||
            this.strDeviceDualId === undefined ||
            this.strDeviceDualId === null
          ) {
            isCheckDCDualId = false;
          }

          if (
            e_DCMacId == this.strDCMacId ||
            this.strDCMacId === undefined ||
            this.strDCMacId === null
          ) {
            isCheckDCMacId = false;
          }

          if (
            e_MonitorIRID == this.strDeviceId ||
            this.strDeviceId === undefined ||
            this.strDeviceId === null
          ) {
            isCheckMonitorIRId = false;
          }

          if (e_MonitorId == emirrterid) {
            isCheckMonitorId = false;
          }

          if (
            e_VWIRID == this.strDeviceId ||
            this.strDeviceId === undefined ||
            this.strDeviceId === null
          ) {
            isCheckVWIRId = false;
          }

          if (e_VWId == emirrterid) {
            isCheckVWId = false;
          }

          if (
            e_UMIRID == this.strDeviceId ||
            this.strDeviceId === undefined ||
            this.strDeviceId === null
          ) {
            isCheckUMIRId = false;
          }

          if (e_UMId == emirrterid) {
            isCheckUMId = false;
          }

          if (this.deviceTypeName == DeviceTypeDesc.Emitter) {
            if (isCheckEmitterId) {
              if (emitteridArr.indexOf(emittermacid) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "TruLocator Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckIrId) {
              if (IridArr.indexOf(this.strDeviceId) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "IR Id already exists, Please try with different Id"
                );
                return;
              }
            }
          } else if (this.deviceTypeName == DeviceTypeDesc.Rex) {
            if (isCheckRexId) {
              if (rexidArr.indexOf(this.strDeviceId) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "Clear Repeater Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckRexMacId) {
              let rexmacid = emittermacid.replace(/:/g, "_").replace(/-/g, "_");
              rexmacid = rexmacid.toLowerCase();
              if (macidArr.indexOf(rexmacid) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "Duplicate MAC Id found, Other Clear Repeater already has this MAC Id."
                );
                return;
              } 
              // else if (rexmacid == this.getHRexMacId.toLowerCase()) {
              //   this.deviceAddboolvalue = true;
              //   this.enableSavebutton(Device_action.device_add);
              //   generateAlertMessage(
              //     this.dialog,
              //     "Duplicate MAC Id found, Clear Router already has this MAC Id."
              //   );
              //   return;
              // } 
              else if (hrexmacidArr.indexOf(rexmacid) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "Duplicate MAC Id found, Other Clear Router already has this MAC Id."
                );
                return;
              }
              else if (rexmacid == this.getgatewaymacid.toLowerCase()) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "Duplicate MAC Id found, Clear Gateway already has this MAC Id."
                );
                return;
              }
            }
          } else if (this.deviceTypeName == DeviceTypeDesc.LF) {
            if (isCheckLFId) {
              if (LFidArr.indexOf(emittermacid) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "LF Exciter Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckIfdevId) {
              if (LFdevidArr.indexOf(this.strDeviceId) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "LF Id already exists, Please try with different Id"
                );
                return;
              }
            }
          } else if (
            this.deviceTypeName == DeviceTypeDesc.DC ||
            this.deviceTypeName == DeviceTypeDesc.NewDC
          ) {
            if (isCheckDCId) {
              if (DCidArr.indexOf(emittermacid) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "Security Controller Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckDCDualId && this.strDeviceDualId) {
              if (DCdualidArr.indexOf(this.strDeviceDualId) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "UT Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckIfDCdevId) {
              if (DCdevidArr.indexOf(this.strDeviceId) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "LF Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckDCMacId) {
              if (this.strDCMacId.length > 0) {
                if (DCMacIdArr.indexOf(this.strDCMacId) > -1) {
                  this.deviceAddboolvalue = true;
                  this.enableSavebutton(Device_action.device_add);
                  generateAlertMessage(
                    this.dialog,
                    "Mac Id already exists, Please try with different Id"
                  );
                  return;
                }
              }
            }
          } else if (this.deviceTypeName == DeviceTypeDesc.Monitor) {
            if (isCheckMonitorId) {
              if (MonitoridArr.indexOf(emittermacid) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "Monitor Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckMonitorIRId) {
              if (MonitorIRidArr.indexOf(this.strDeviceId) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "IR Id already exists, Please try with different Id"
                );
                return;
              }
            }
          } else if (this.deviceTypeName == DeviceTypeDesc.VW) {
            if (isCheckVWId) {
              if (VWidArr.indexOf(emittermacid) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "VW Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckVWIRId) {
              if (VWIRidArr.indexOf(this.strDeviceId) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "IR Id already exists, Please try with different Id"
                );
                return;
              }
            }
          } else if (this.deviceTypeName == DeviceTypeDesc.UM) {
            if (isCheckUMId) {
              if (UMidArr.indexOf(emittermacid) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "Universal Monitor Id already exists, Please try with different Id"
                );
                return;
              }
            }
            if (isCheckUMIRId) {
              if (UMIRidArr.indexOf(this.strDeviceId) > -1) {
                this.deviceAddboolvalue = true;
                this.enableSavebutton(Device_action.device_add);
                generateAlertMessage(
                  this.dialog,
                  "IR Id already exists, Please try with different Id"
                );
                return;
              }
            }
          }
        }
        this.technologyService
          .addDeviceData(adddevicedetails)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
            this.deviceAddboolvalue = false;
            if (apiresponse["apiReturn"] == "Success") {
              if (
                devicename == DeviceTypeDesc.Rex ||
                devicename == DeviceTypeDesc.HRex
              ) {
                this.technologyService.reloadiniltc();
                this.technologyService.systemreset_ltc();
              }
              if (devicename == DeviceTypeDesc.Pullcord) {
                this.technologyService.reloadPullCordConfig_ltc();
              } else if (
                devicename == DeviceTypeDesc.DC ||
                devicename == DeviceTypeDesc.NewDC
              ) {
                this.apiService.reload_DC_Config();
              } else if (devicename == DeviceTypeDesc.UT) {
                this.apiService.reloadDCTdevice();
              } else if (
                devicename != DeviceTypeDesc.Rex &&
                devicename != DeviceTypeDesc.HRex &&
                devicename != DeviceTypeDesc.Gateway
              ) {
                this.apiService.reload_DrawRoom();
              }

              if (
                devicename == DeviceTypeDesc.Rex ||
                devicename == DeviceTypeDesc.HRex
              ) {
                generateAlertMessage(
                  this.dialog,
                  "Device details added successfully. <br> It will take 400 seconds for the settings to take effect."
                );
              } else
                generateAlertMessage(
                  this.dialog,
                  "Device details added successfully."
                );

              if (
                this.deviceTypeName == DeviceTypeDesc.HRex ||
                this.deviceTypeName == DeviceTypeDesc.UT ||
                this.deviceTypeName == DeviceTypeDesc.DC ||
                this.deviceTypeName == DeviceTypeDesc.NewDC ||
                this.deviceTypeName == DeviceTypeDesc.Pullcord
              ) {
                let CheckAlertData = {
                  IsAlertChanged: 0,
                  IsAlertClicked: 0,
                };
                this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
                CheckAlertData = {
                  IsAlertChanged: 1,
                  IsAlertClicked: 0,
                };
                this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
              }

              this.thisDialogRef.close("addDevice");
            } else if (apiresponse["apiReturn"] == "Fail") {
              this.deviceAddboolvalue = true;
              generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
              this.enableSavebutton(Device_action.device_add);
              return;
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }   
            else if(apiresponse["apiReturn"] == "Clear Router Id already exists")
            {
              this.deviceAddboolvalue = true;
              generateAlertMessage(this.dialog, "Clear Router Id already exists, Please try with different Id");
              this.enableSavebutton(Device_action.device_add);
              return;
            }
            else if(apiresponse["apiReturn"] == "Clear Repeater Id already exists")        
            {
              // this.routerAddConfirmation()
              this.deviceAddboolvalue = true;
              generateAlertMessage(this.dialog, "Clear Repeater Id already exists");
              this.enableSavebutton(Device_action.device_add);
              return;
            }
            else {
              this.deviceAddboolvalue = true;
              generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
              this.enableSavebutton(Device_action.device_add);
              return;
            }
          });
      });
  }  
  
  routerAddConfirmation()
  {
    const ConfirmMessageData = {
      ConfirmMessage:
        "Clear Repeater Id already exists,Do you want to update?",
    };
    const dialogRef = this.dialog.open(ConfirmMessageComponent, {
      disableClose: true,
      width: "auto",
      height: "200px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: ConfirmMessageData,
    });
    dialogRef.afterClosed().subscribe((result) => {      
      if (result == "Yes") {
        this.addUpdateMode = 0;
        this.addDeviceData(this.deviceaddForm.value);
      } else {
        dialogRef.close("Cancel");        
        this.enableSavebutton(Device_action.device_add);
        return;
      }
    });
  }

  drawRoom() {
    this.map.removeLayer(this.edit_DragVectorLayer);
    this.map.removeInteraction(this.edit_DragInteraction);

    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }

    this.drawRoomImgUrl = this.RoomImgUrl_active;
    this.undoRoomImgUrl = this.FloorPlanImgUrl_inactive;
    this.emitterImgUrl = this.emitterImgUrl_inactive;
    this.HREXImgUrl = this.HREXImgUrl_inactive;
    this.REXImgUrl = this.REXImgUrl_inactive;
    this.LFImgUrl = this.LFImgUrl_inactive;
    this.DCImgUrl = this.DCImgUrl_inactive;
    this.MonitorUrl = this.MonitorImgUrl_inactive;
    this.VWUrl = this.VWImgUrl_inactive;
    this.DCTImgUrl = this.DCTImgUrl_inactive;

    this.isRedrawRoom = true;
    this.isRedrawDevice = false;

    if (this.draw) this.map.removeInteraction(this.draw);

    this.draw = new Draw({
      source: this.source,
      type: "Polygon",
    });
    this.roomdraw = 1;
    this.draw.on("drawstart", onDrawStart);

    const drawRoomSource = this.source;
    const drawSource = this.tempVectorSource;

    function onDrawStart(event) {
      console.log(event);
      //remove everything drawn previously
      drawRoomSource.clear();
      drawSource.clear();
    }

    this.vector = new OlVectorLayer({
      source: this.source,
    });
    this.vector.set("extent", this.imageExtent);
    this.map.addInteraction(this.draw);

    this.map.addLayer(this.vector);

    // Get the array of features
    const features = this.vector.getSource().getFeatures();
    if (features) {
      // Go through this array and get coordinates of their geometry.
      features.forEach(function (feature) {
        console.log(feature);
      });
    }

    this.draw.on("drawend", function (e) {
      console.log(e);
    });
  }

  snapGrid() {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (this.isSnapGrid) {
        this.isSnapGrid = false;
        this.snapRoomImgUrl = this.SnapGridImgUrl_inactive;
        const layersToRemove = [] as any[];
        this.map.getLayers().forEach(function (layer) {
          if (
            layer.get("name") != undefined &&
            layer.get("name") === "snapGrid"
          ) {
            layersToRemove.push(layer);
          }
        });

        const len = layersToRemove.length;
        for (let nlayer = 0; nlayer < len; nlayer++) {
          this.map.removeLayer(layersToRemove[nlayer]);
          this.map.getSource().clear();
        }
      } else {
        this.snapRoomImgUrl = this.SnapGridImgUrl_active;
      }

      this.isSnapGrid = !this.isSnapGrid;

      const layerMarkers = AddMarkers(
        this.imageExtent[2],
        this.imageExtent[3],
        this.image_path
      );
      this.map.addLayer(layerMarkers);
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - snapGrid() - " + err.message
      );
    }
  }

  undoDrawRoom() {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      this.undoRoomImgUrl = this.FloorPlanImgUrl_active;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.source.clear();
      this.tempVectorSource.clear();
      this.isRedrawRoom = false;
      this.isPlaceDevice = false;
      if (this.isSnapGrid) {
        this.isSnapGrid = false;

        const layersToRemove = [] as any[];
        this.map.getLayers().forEach(function (layer) {
          if (
            layer.get("name") != undefined &&
            layer.get("name") === "snapGrid"
          ) {
            layersToRemove.push(layer);
          }
        });

        const len = layersToRemove.length;
        for (let nlayers = 0; nlayers < len; nlayers++) {
          this.map.removeLayer(layersToRemove[nlayers]);
        }
      }

      this.isSnapGrid = !this.isSnapGrid;

      this.new_data[0] = JSON.parse(JSON.stringify(this.old_data[0]));
      this.HRexMacId = this.new_data[0].MacId;
      this.editselectedunit = this.new_data[0].UnitId;
      this.setDeviceDetails_Edit();
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - undoDrawRoom() - " + err.message
      );
    }
  }

  DrawEmitter() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_active;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.Emitter;
      this.markDevices("Technology Overlay-Device-Emitter-Lime Green.png");
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - DrawEmitter() - " + err.message
      );
    }
  }

  DrawHREX() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_active;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.HRex;
      this.markDevices("Technology Overlay-Device-H-REX-Gray.png");
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - DrawHREX() - " + err.message
      );
    }
  }

  DrawREX() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_active;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.Rex;
      this.markDevices("Technology Overlay-Device-REX-Cyan.png");
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - DrawREX() - " + err.message
      );
    }
  }

  DrawLF() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_active;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.LF;
      this.markDevices("Technology Overlay-Device-LF-Orange.png");
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - DrawLF() - " + err.message
      );
    }
  }

  DrawPullcord() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.pullcordImgUrl = this.Pullcord_active;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.Pullcord;
      this.markDevices("Alerts-Pull Cord_Red.png");
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawEmitter() - " + err.message
      );
    }
  }

  DrawDC() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.pullcordImgUrl = this.Pullcord_inactive;
      this.DCImgUrl = this.DCImgUrl_active;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.NewDC;
      this.markDevices("Technology Overlay-Device-DC-Orange.png");
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - DrawDC() - " + err.message
      );
    }
  }

  DrawMonitor() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.pullcordImgUrl = this.Pullcord_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_active;
      this.VWUrl = this.VWImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.Monitor;
      this.markDevices("Monitor_Selected.png");
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawMonitor() - " + err.message
      );
    }
  }

  DrawVW() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.pullcordImgUrl = this.Pullcord_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_active;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.VW;
      this.markDevices("virtualWall.png");
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawVW() - " + err.message
      );
    }
  }
  DrawGateway() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.GatewayUrl = this.GatewayImgUrl_active;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.Gateway;
      this.markDevices("gateway.png");
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - DrawHREX() - " + err.message
      );
    }
  }

  DrawDCT() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.GatewayUrl = this.GatewayImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_active;
      this.UMImgUrl = this.UMImgUrl_inactive;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.UT;
      this.markDevices("Technology Overlay-Device-DCT-Orange.png");
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - DrawHREX() - " + err.message
      );
    }
  }

  DrawUM() {
    try {
      this.emitterImgUrl = this.emitterImgUrl_inactive;
      this.HREXImgUrl = this.HREXImgUrl_inactive;
      this.REXImgUrl = this.REXImgUrl_inactive;
      this.LFImgUrl = this.LFImgUrl_inactive;
      this.drawRoomImgUrl = this.RoomImgUrl_inactive;
      this.pullcordImgUrl = this.Pullcord_inactive;
      this.DCImgUrl = this.DCImgUrl_inactive;
      this.MonitorUrl = this.MonitorImgUrl_inactive;
      this.VWUrl = this.VWImgUrl_inactive;
      this.DCTImgUrl = this.DCTImgUrl_inactive;
      this.UMImgUrl = this.UMImgUrl_active;

      this.closeDrawFeature();
      this.deviceTypeName = DeviceTypeDesc.UM;
      this.markDevices("Monitor_Selected.png");
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - DrawMonitor() - " + err.message
      );
    }
  }

  closeDrawFeature() {
    try {
      this.map.removeInteraction(this.draw);
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - closeDrawFeature() - " + err.message
      );
    }
  }

  markDevices(deviceType) {
    console.log(deviceType);
    if (this.CheckRoomDrawn()) {
      this.undoDrawRoom();
      const features = this.vector.getSource().getFeatures();
      const DName = getDeviceTypeNew(this.olddevicename);
      if (
        features.length == 0 &&
        (this.deviceTypeName == DeviceTypeDesc.Emitter ||
          this.deviceTypeName == DeviceTypeDesc.Monitor ||
          this.deviceTypeName == DeviceTypeDesc.VW)
      ) {
        generateAlertMessage(
          this.dialog,
          "Please draw the room within the map extent for " + DName
        );
        this.drawRoom();
        return false;
      }
    }
    this.isPlaceDevice = true;
    
    // const oldDeviceName = this.olddevicename;
    this.selectdevice = 1;
    this.draw = new Draw({
      source: this.tempVectorSource,
      type: "Point",
    });
    this.checkpolygonpoints = 0;
    this.draw.on("drawstart", onDrawStart);

    let DeviceTypeNew = "";
    DeviceTypeNew = getDeviceTypeNew(this.deviceTypeName);

    const drawSource = this.tempVectorSource;
    
    function onDrawStart(event) {
      console.log(event);
      //remove everything drawn previously
      drawSource.clear();
      
    }

    this.map.addInteraction(this.draw);

    try {
      this.tempVectorLayer.set("extent", this.imageExtent);
      this.map.addLayer(this.tempVectorLayer);
    } catch (err) {
      console.log(err);
    }

    let vertices;
    let polyLine = [];

    // Get the array of features
    const features = this.vector.getSource().getFeatures();

    if (features) {
      // Go through this array and get coordinates of their geometry.
      features.forEach(function (feature) {
        vertices = feature.getGeometry().getCoordinates();
      });

      this.getpolypoints = 1;

      vertices[0].pop();
      polyLine = vertices[0];
      const _dialog = this.dialog;
      this.draw.on("drawend", function (e) {
        const polygon = polyLine;
        inside(e.feature.values_.geometry.flatCoordinates, polygon); // true

        function inside(point, vs) {
          const x = point[0],
            y = point[1];

          let inside = false;
          for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            const xi = vs[i][0],
              yi = vs[i][1];
            const xj = vs[j][0],
              yj = vs[j][1];

            const intersect =
              yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) inside = !inside;
          }

          //return inside;

          if (!inside) {
            drawSource.clear();
            e.feature.values_.geometry.flatCoordinates = "";
            generateAlertMessage(
              _dialog,
              "Please place a " + DeviceTypeNew + " in the room drawn"
            );
          }
        }
      });
    }
  }

  markSelectedDevices(DeviceName, DeviceId, MacId) {
    try {

      console.log(DeviceName);
      console.log(DeviceId);
      console.log(MacId);

      const imgheight = parseFloat(this.imageHeight);
      const arrdevicemarker = [] as any[];
      let arrpolyline = [] as any[];
      if (this.roomcoordinates != undefined) {
        arrpolyline = this.roomcoordinates.split(" ");
      }
      this.isRedrawDevice = true;

      this.map.on("pointermove", function (evt) {
        const hit = this.map.forEachFeatureAtPixel(
          evt.pixel,
          function (feature, layer) {
            console.log(feature);
            console.log(layer);
            return true;
          }
        );
        if (hit) {
          this.map.getViewport().style.cursor = "pointer";
        } else {
          this.map.getViewport().style.cursor = "";
        }
      });

      const markerpoint = [
        convertToSvgEcllipseXCoordinate(parseFloat(this.DeviceX), false),
        convertToSvgEcllipseYCoordinate(parseFloat(this.DeviceY), imgheight),
      ];
      const marker = new OlFeature({
        // Added fromLonLat
        geometry: new OlPoint(markerpoint),
      });

      marker.getx;

    
      arrdevicemarker.push(marker);

      ///Floor Marker///
      const vectorSource = get_VectorSource(arrdevicemarker, 0);

      const vectorLayer = new OlVectorLayer({
        source: vectorSource,
      });

      // Drag and drop feature
      const dragInteraction = new Modify({
        features: new OlCollection([marker]),
        style: new Style({}),
        pixelTolerance: 20,
      });

      let polyLine = [] as any[];

      for (let i = 0; i < arrpolyline.length; i++) {
        const arrpolypoint = arrpolyline[i].split(",");
        arrpolypoint[0] = parseFloat(arrpolypoint[0]);
        arrpolypoint[1] = getboxFromYpos(
          parseFloat(arrpolypoint[1]),
          imgheight
        );
        arrpolyline[i] = arrpolypoint;
      }

      polyLine = arrpolyline;
      if (polyLine.length == 0) this.isRedrawRoom = true;
      const _dialog = this.dialog;
      const drag_imageExtent = this.imageExtent;
      const redDrawDeviceXY = document.getElementById("spn_RedrawDeviceXY");
      // Add the event to the drag and drop feature
      dragInteraction.on(
        "modifyend",
        function (e) {
          const flatCoordinates =
            e.features.array_[0].values_.geometry.flatCoordinates;
          if (
            !(
              flatCoordinates[0] < 0 ||
              flatCoordinates[1] < 0 ||
              flatCoordinates[0] > drag_imageExtent[2] ||
              flatCoordinates[1] > drag_imageExtent[3]
            )
          ) {
            if (redDrawDeviceXY != null)
              redDrawDeviceXY.textContent =
                e.features.array_[0].values_.geometry.flatCoordinates;
            if (polyLine.length > 0) {
              const polygon = polyLine;
              inside(
                e.features.array_[0].values_.geometry.flatCoordinates,
                polygon
              );
            }
          }

          function inside(point, vs) {
            const x = point[0],
              y = point[1];
            let inside = false;
            for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
              const xi = vs[i][0],
                yi = vs[i][1];
              const xj = vs[j][0],
                yj = vs[j][1];

              const intersect =
                yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
              if (intersect) inside = !inside;
            }

            if (!inside) {
              //&& this.deviceTypeName == DeviceTypeDesc.Emitter
              if (redDrawDeviceXY != null) redDrawDeviceXY.textContent = "";
              generateAlertMessage(
                _dialog,
                "Please place a Device in the room "
              );
            }
          }
        },
        marker
      );

      this.edit_DragVectorLayer = vectorLayer;
      this.edit_DragInteraction = dragInteraction;
      this.map.addLayer(vectorLayer);
      this.map.addInteraction(dragInteraction);
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - markSelectedDevices() - " + err.message
      );
    }
  } // end

  onKeyPress(formType): void {
    try {
      let deviceDualId;
      if(formType == 1)      
        deviceDualId = this.deviceaddForm.value.devicedualid;
      else  
        deviceDualId = this.deviceeditForm.value.devicedualid;
        
      if (deviceDualId == undefined || deviceDualId == "") 
      {
        this.checkDCLotiering = false;
        this.checkDCUnauthorizedEgress = false;
        this.disableDCLotiering = true;
        this.disableDCUnauthorizedEgress = true;
      } 
      else {
        this.disableDCLotiering = false;
        this.disableDCUnauthorizedEgress = false;
      }
    } catch (err) {
      throw new Error(
        "accesscode.component.ts" + " - onKeyPress() - " + err.message
      );
    }
  }

  togglerDCTag(e) {
    if (e.checked == true) {
      this.checkDCTag = true;
    } else {
      this.checkDCTag = false;
    }
  }

  togglerDCLotiering(e) {
    if (e.checked == true) {
      this.checkDCLotiering = true;
    } else {
      this.checkDCLotiering = false;
    }
  }

  togglerDCUnauthorizedEgress(e) {
    if (e.checked == true) {
      this.checkDCUnauthorizedEgress = true;
    } else {
      this.checkDCUnauthorizedEgress = false;
    }
  }

  togglerPCJackRemovalDetection(e) {
    if (e.checked == true) {
      this.checkPCRemovalDetection = true;
    } else {
      this.checkPCRemovalDetection = false;
    }
  }

  togglerPCJackButtonPress(e) {
    if (e.checked == true) {
      this.checkPCButtonPress = true;
    } else {
      this.checkPCButtonPress = false;
    }
  }

  togglerPullCord(e) {
    if (e.checked == true) {
      this.checkPullCord = true;
    } else {
      this.checkPullCord = false;
    }
  }

  selectPCMode(event) {
    this.checkPCMobilityMode = event;
  }

  togglerDCT_Tag(id,e,type) {   
    if(type == 1)
    {
      if (e.checked == true && id == 1) {
        this.checkDCT_Tag1=true;           
        this._egressenablehourmin1 = true;               
      }   
      else if (e.checked == true && id == 2) {     
        this.checkDCT_Tag2=true;                           
        this._egressenablehourmin2 = true;  
      }    
      else if (e.checked == true && id == 3) {
        this.checkDCT_Tag3=true;             
        this._egressenablehourmin3 = true;               
      }
      else if(e.checked == false && id == 1){
        this.checkDCT_Tag1=false;    
        this.selectedAlertState1 = 0; 
        this.alertMessage_input1 = ""; 
        this._egressenablehourmin1 = false;  
        this.checkallinfo1 = false;
        this.data_startTime1 = 0;      
        this.data_endTime1 = 0;
      }
      else if(e.checked == false && id == 2){
        this.checkDCT_Tag2=false;    
        this.selectedAlertState2 = 0; 
        this.alertMessage_input2 = ""; 
        this._egressenablehourmin2 = false;  
        this.checkallinfo2 = false;
        this.data_startTime2 = 0;      
        this.data_endTime2 = 0;
      }
      else if(e.checked == false && id == 3){
        this.checkDCT_Tag3=false; 
        this.selectedAlertState3 = 0; 
        this.alertMessage_input3 = "";  
        this._egressenablehourmin3 = false;  
        this.checkallinfo3 = false;  
        this.data_startTime3 = 0;      
        this.data_endTime3 = 0;
      } 
    }
    if(type == 2)  
    { 
      if (e.checked == true && id == 1) {
        this.checkDCT_Tag1=true;    
        this.edit_selectedAlertState1 = this.oldedit_selectedAlertState1; 
        this.alertMessage_input1 =  this.oldalertMessage1;  
        this._egressenablehourmin1 = true;               
      }   
      else if (e.checked == true && id == 2) {     
        this.checkDCT_Tag2=true;     
        this.edit_selectedAlertState2 = this.oldedit_selectedAlertState2; 
        this.alertMessage_input2 =  this.oldalertMessage2;               
        this._egressenablehourmin2 = true;  
      }    
      else if (e.checked == true && id == 3) {
        this.checkDCT_Tag3=true;     
        this.edit_selectedAlertState3 = this.oldedit_selectedAlertState3; 
        this.alertMessage_input3 =  this.oldalertMessage3; 
        this._egressenablehourmin3 = true;               
      }
      else if(e.checked == false && id == 1){
        this.checkDCT_Tag1=false;    
        this.edit_selectedAlertState1 = 0; 
        this.alertMessage_input1 = ""; 
        this._egressenablehourmin1 = false;  
        this.checkallinfo1 = false;
        this.data_startTime1 = 0;      
        this.data_endTime1 = 0;
      }
      else if(e.checked == false && id == 2){
        this.checkDCT_Tag2=false;    
        this.edit_selectedAlertState2 = 0; 
        this.alertMessage_input2 = ""; 
        this._egressenablehourmin2 = false;  
        this.checkallinfo2 = false;
        this.data_startTime2 = 0;      
        this.data_endTime2 = 0;
      }
      else if(e.checked == false && id == 3){
        this.checkDCT_Tag3=false; 
        this.edit_selectedAlertState3 = 0; 
        this.alertMessage_input3 = "";  
        this._egressenablehourmin3 = false;  
        this.checkallinfo3 = false;  
        this.data_startTime3 = 0;      
        this.data_endTime3 = 0;
      }   
    } 
  }

  togglerDCT_Ambient(id, event) {
    if (event.checked == true && id == 1) {
      this.checkDCT_Ambient1 = true;
      this.ambientOverDesc = this.oldOverTemp;
      this.ambientOverMessage = this.oldambientOverMessage;
    } else if (event.checked == true && id == 2) {
      this.checkDCT_Ambient2 = true;
      this.ambientUnderDesc = this.oldUnderTemp;
      this.ambientUnderMessage = this.oldambientUnderMessage;
    } else if (event.checked == false && id == 1) {
      this.checkDCT_Ambient1 = false;
      this.ambientOverDesc = "";
      this.ambientOverMessage = "";
    } else if (event.checked == false && id == 2) {
      this.checkDCT_Ambient2 = false;
      this.ambientUnderDesc = "";
      this.ambientUnderMessage = "";
    }
  }

  selectDCTAlertState(ID, Event, Type) {
    if (Type == 1) {
      if (ID == 1) this.selectedAlertState1 = Event;
      else if (ID == 2) this.selectedAlertState2 = Event;
      else if (ID == 3) this.selectedAlertState3 = Event;
    } else if (Type == 2) {
      if (ID == 1) this.edit_selectedAlertState1 = Event;
      else if (ID == 2) this.edit_selectedAlertState2 = Event;
      else if (ID == 3) this.edit_selectedAlertState3 = Event;
    }
  }

  togglerDCT_Probe(id, event) {
    if (event.checked == true && id == 1) {
      this.checkDCT_Probe1 = true;
      this.probeOverDesc = this.oldProbeOverTemp;
      this.probeOverMessage = this.oldprobeOverMessage;
    } else if (event.checked == true && id == 2) {
      this.checkDCT_Probe2 = true;
      this.probeUnderDesc = this.oldProbeUnderTemp;
      this.probeUnderMessage = this.oldprobeUnderMessage;
    } else if (event.checked == false && id == 1) {
      this.checkDCT_Probe1 = false;
      this.probeOverDesc = "";
      this.probeOverMessage = "";
    } else if (event.checked == false && id == 2) {
      this.checkDCT_Probe2 = false;
      this.probeUnderDesc = "";
      this.probeUnderMessage = "";
    }
  }

  togglerDCT_Humidity(id, event) {
    if (event.checked == true && id == 1) {
      this.checkDCT_Humidity1 = true;
      this.humidityOverDesc = this.oldHumidityOverTemp;
      this.humidityOverMessage = this.oldhumidityOverMessage;
    } else if (event.checked == true && id == 2) {
      this.checkDCT_Humidity2 = true;
      this.humidityUnderDesc = this.oldHumidityUnderTemp;
      this.humidityUnderMessage = this.oldhumidityUnderMessage;
    } else if (event.checked == false && id == 1) {
      this.checkDCT_Humidity1 = false;
      this.humidityOverDesc = "";
      this.humidityOverMessage = "";
    } else if (event.checked == false && id == 2) {
      this.checkDCT_Humidity2 = false;
      this.humidityUnderDesc = "";
      this.humidityUnderMessage = "";
    }
  }

  DeleteTechDevice() {
    try {
      const _dialog = this.dialog;
      const mapsyncinfo: MapSyncProcess = {
        authKey: this.authkey,
      };
      this.apiService
        .checkMapSync(mapsyncinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((MapSyncinfo: any[]) => {
          this.MapSyncProcess = MapSyncinfo;
          if (this.MapSyncProcess == 1) {
            generateAlertMessage(_dialog, appsettings.MapSyncMsg);
            return false;
          } else {
            this.deleteconfirmation(this.new_data[0]);
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - DeleteTechDevice() - " + err.message
      );
    }
  }

  deleteconfirmation(Devicedata) {
    try {
      let deviceId_delete;
      if (Devicedata.DeviceName == "Gateway")
        deviceId_delete = Devicedata.MacId;
      else deviceId_delete = Devicedata.DeviceInfo;

      const ConfirmMessageData = {
        ConfirmMessage:
          "Are you sure you want to delete " +
          Devicedata.NewDeviceName +
          " [" +
          deviceId_delete +
          "]?",
      };
      this.deleteFlag = 0;

      if (this.deletedevicedialogopen == 0) {
        const dialogRef = this.dialog.open(ConfirmMessageComponent, {
          disableClose: true,
          width: "auto",
          height: "200px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: ConfirmMessageData,
        });
        this.deletedevicedialogopen = 1;
        dialogRef.afterClosed().subscribe((result) => {
          this.deletedevicedialogopen = 0;
          if (result == "Yes") {
            this.deleteDevicedetails(Devicedata);
          } else {
            dialogRef.close("Cancel");
            const devicelistelement =
              document.getElementById("divDeviceheader");
            if (devicelistelement != null)
              devicelistelement.className = "enable";
            const deviceaddelement = document.getElementById("divAddDevice");
            if (deviceaddelement != null) deviceaddelement.className = "enable";
            return;
          }
        });
      }
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - confirmation() - " + err.message
      );
    }
  }

  deleteDevicedetails(Data) {
    try {
      let devicetype = 0;
      let alert_deviceId;
      let DCdualutid = 0;
      let deviceTypeId = "";
      let unitNameNew = "";
      let buildingIdNew = 0;
      alert_deviceId = Data.DeviceId;
      if (Data.DeviceName == DeviceTypeDesc.Emitter) {
        devicetype = DeviceType.Emitter;
        alert_deviceId = Data.DeviceTypeId;
      } else if (Data.DeviceName == DeviceTypeDesc.HRex) {
        devicetype = DeviceType.HRex;
        alert_deviceId = Data.DeviceInfo;
      } else if (Data.DeviceName == DeviceTypeDesc.Rex)
        devicetype = DeviceType.Rex;
      else if (Data.DeviceName == DeviceTypeDesc.Room)
        devicetype = DeviceType.Room;
      else if (Data.DeviceName == DeviceTypeDesc.Pin)
        devicetype = DeviceType.Pin;
      else if (Data.DeviceName == DeviceTypeDesc.LF) 
      {
        if(this.selectedLFDeviceType == 1)
          devicetype = DeviceType.LF;
        else if(this.selectedLFDeviceType == 2)
          devicetype = DeviceType.DripTray;
      }
      else if (Data.DeviceName == DeviceTypeDesc.FloorPlanNotes)
        devicetype = DeviceType.FloorPlanNotes;
      else if (Data.DeviceName == DeviceTypeDesc.Pullcord)
        devicetype = DeviceType.Pullcord;
      else if (
        Data.DeviceName == DeviceTypeDesc.DC ||
        Data.DeviceName == DeviceTypeDesc.NewDC
      ) {
        devicetype = DeviceType.DC;
        DCdualutid = Data.dualutId;
      } else if (Data.DeviceName == DeviceTypeDesc.Monitor)
        devicetype = DeviceType.Monitor;
      else if (Data.DeviceName == DeviceTypeDesc.VW) devicetype = DeviceType.VW;
      else if (Data.DeviceName == DeviceTypeDesc.Gateway)
        devicetype = DeviceType.Gateway;
      else if (Data.DeviceName == DeviceTypeDesc.UT) devicetype = DeviceType.UT;
      else if (Data.DeviceName == DeviceTypeDesc.UM) devicetype = DeviceType.UM;
      else if (Data.DeviceName == DeviceTypeDesc.Intercom) devicetype = DeviceType.IC;

      if (this.selectCategoryId == undefined) this.selectCategoryId = 0;

      deviceTypeId = Data.DeviceTypeId;

      if (deviceTypeId != undefined) deviceTypeId = deviceTypeId.toString();
      else if(this.selectedLFDeviceType == 2)                     
        deviceTypeId=Data.DeviceInfo;
      else deviceTypeId = "";
      
      if(Data.UnitName == undefined)      
        unitNameNew = Data.UnitLocation.toString();      
      else
        unitNameNew = Data.UnitName.toString();
        
      if(Data.BuildId == undefined)      
        buildingIdNew = parseInt(Data.BuildingId); 
      else
        buildingIdNew = parseInt(Data.BuildId);
      

      const deletedevicedetails: DeleteDevice = {
        authKey: this.authkey,
        campusId: parseInt(Data.CampusId),
        buildingId: buildingIdNew,
        floorId: parseInt(Data.FloorId),
        monitorId: parseInt(Data.DeviceId),
        oldDeviceId: parseInt(Data.DeviceId),
        svgId: parseInt(Data.SvgId),
        svgDeviceType: devicetype,
        updateMode: 3,
        location: unitNameNew,
        emitterID: deviceTypeId,
        monitorType: Data.DeviceName.toString(),
        vmDirection: devicetype.toString(),
        unitId: parseInt(Data.UnitId),
        deviceX: -1,
        deviceY: -1,
        polygonPoints: "",
        oldEmiterId: "",
        dcDualId: 0,
        oldDcDualId: 0,
        dcMacId: "",
        oldDCMacId: "",
        isDCTag: 0,
        isDCLotiering: 0,
        isDCUnauthorized: 0,
        ispcRemoveDetection: 0,
        ispcButtonPress: 0,
        isPullCord: 0,
        ispcMobilityMode: 0,
        enableInput1: 0,
        alertState1: 0,
        alertMessage1: "",
        enableInput2: 0,
        alertState2: 0,
        alertMessage2: "",
        enableInput3: 0,
        alertState3: 0,
        alertMessage3: "",
        ambientOverTemp: 0,
        ambientOverDesc: "",
        ambientOverMessage: "",
        ambientUnderTemp: 0,
        ambientUnderDesc: "",
        ambientUnderMessage: "",
        probeOverTemp: 0,
        probeOverDesc: "",
        probeOverMessage: "",
        probeUnderTemp: 0,
        probeUnderDesc: "",
        probeUnderMessage: "",
        humidityOverTemp: 0,
        humidityOverDesc: "",
        humidityOverMessage: "",
        humidityUnderTemp: 0,
        humidityUnderDesc: "",
        humidityUnderMessage: "",
        resRoomCategory: parseInt(this.selectCategoryId),
        dataId: parseInt(Data.DataId),
        isIndoor: this.IsIndoor,
        input1StartHr: 0,
        input1StartMin: 0,
        input1EndHr: 0,
        input1EndMin: 0,
        input1checkAllDay : 0,
        input2StartHr: 0,
        input2StartMin: 0,
        input2EndHr: 0,
        input2EndMin: 0,
        input2checkAllDay : 0,
        input3StartHr: 0,
        input3StartMin: 0,
        input3EndHr: 0,
        input3EndMin: 0,
        input3checkAllDay : 0
      };
      if(Data.dualutId == "" || Data.dualutId == undefined)      
        DCdualutid = 0;      
      const deviceDetails: checkDeviceAlert = {
        authKey: this.authkey,
        deviceId: parseInt(alert_deviceId),
        deviceType: devicetype.toString(),
        dualFlag: DCdualutid,
        isDelete: 1,
      };
      this.technologyService
        .checkDeviceInAlert(deviceDetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "This device has open alerts, please complete or cancel those alerts and try again."
            );
            const devicelistelement =
              document.getElementById("divDeviceheader");
            if (devicelistelement != null)
              devicelistelement.className = "enable";
            const deviceaddelement = document.getElementById("divAddDevice");
            if (deviceaddelement != null) deviceaddelement.className = "enable";
            return;
          } else {
            const LocationDetails: checkLocationAlert = {
              authKey: this.authkey,
              irOrRFId: Data.DeviceId.toString(),
              deviceType: devicetype.toString(),
            };
            this.technologyService
              .checkLocationInAlert(LocationDetails)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse: any[]) => {
                if (apiresponse.toString() == "true") {
                  generateAlertMessage(
                    this.dialog,
                    "This Location has open alerts, please complete or cancel those alerts and try again."
                  );
                  const devicelistelement =
                    document.getElementById("divDeviceheader");
                  if (devicelistelement != null)
                    devicelistelement.className = "enable";
                  const deviceaddelement =
                    document.getElementById("divAddDevice");
                  if (deviceaddelement != null)
                    deviceaddelement.className = "enable";
                  return;
                } else {
                  this.technologyService
                    .deleteDevicesforFloor(deletedevicedetails)
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(
                      (apiresponse: TechnologyForCreationResponse[]) => {
                        if (apiresponse != null && apiresponse != undefined) {
                          if (apiresponse["apiReturn"] == "Success") {
                            if (
                              Data.DeviceName == DeviceTypeDesc.Rex ||
                              Data.DeviceName == DeviceTypeDesc.HRex
                            ) {
                              this.technologyService.reloadiniltc();
                              this.technologyService.systemreset_ltc();
                            }
                            if (Data.DeviceName == DeviceTypeDesc.Pullcord) {
                              this.technologyService.reloadPullCordConfig_ltc();
                            } else if (
                              Data.DeviceName == DeviceTypeDesc.DC ||
                              Data.DeviceName == DeviceTypeDesc.NewDC
                            ) {
                              this.apiService.reload_DC_Config();
                            } else if (Data.DeviceName == DeviceTypeDesc.UT) {
                              this.apiService.reloadDCTdevice();
                            } else if (
                              Data.DeviceName != DeviceTypeDesc.Rex &&
                              Data.DeviceName != DeviceTypeDesc.HRex &&
                              Data.DeviceName != DeviceTypeDesc.Gateway
                            ) {
                              this.apiService.reload_DrawRoom();
                            }

                            if (
                              Data.DeviceName == DeviceTypeDesc.Rex ||
                              Data.DeviceName == DeviceTypeDesc.HRex
                            ) {
                              generateAlertMessage(
                                this.dialog,
                                "Device details deleted successfully. <br> It will take 400 seconds for the settings to take effect."
                              );
                            } else {
                              generateAlertMessage(
                                this.dialog,
                                "Device details deleted successfully."
                              );
                            }

                            if (
                              Data.DeviceName == DeviceTypeDesc.HRex ||
                              Data.DeviceName == DeviceTypeDesc.UT ||
                              Data.DeviceName == DeviceTypeDesc.DC ||
                              Data.DeviceName == DeviceTypeDesc.Pullcord
                            ) {
                              let CheckAlertData = {
                                IsAlertChanged: 0,
                                IsAlertClicked: 0,
                              };
                              this.alertSharedService.changeSharedCheckAlert(
                                CheckAlertData
                              );
                              CheckAlertData = {
                                IsAlertChanged: 1,
                                IsAlertClicked: 0,
                              };
                              this.alertSharedService.changeSharedCheckAlert(
                                CheckAlertData
                              );
                            }
                            this.thisDialogRef.close("flooreditclose");
                          } else if (apiresponse["apiReturn"] == "Fail") {
                            generateAlertMessage(
                              this.dialog,
                              "Device details deleted failed."
                            );
                          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
                            this.thisDialogRef.close("close");
                            AuthFailedMessage(this.dialog, this.router);
                            return;
                          }
                        } else {
                          generateAlertMessage(
                            this.dialog,
                            "Device details deleted failed."
                          );
                        }
                        const devicelistelement =
                          document.getElementById("divDeviceheader");
                        if (devicelistelement != null)
                          devicelistelement.className = "enable";
                        const deviceaddelement =
                          document.getElementById("divAddDevice");
                        if (deviceaddelement != null)
                          deviceaddelement.className = "enable";
                      }
                    );
                }
              });
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - deleteDevicedetails() - " + err.message
      );
    }
  }

  public addDevicedetails = (deviceaddFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.deviceaddForm.valid && this.deviceAddboolvalue) {
        this.deviceAddboolvalue = false;
        this.disableSavebutton(Device_action.device_add);
        this.addDeviceData(deviceaddFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - addDevicedetails() - " + err.message
      );
    }
  };

  enableSavebutton(value) {
    if (value == Device_action.device_add) {
      const saveclick = document.getElementById("btnsaveclick");
      if (saveclick != null)
        saveclick.className = "clsdeviceaddbutton mat-raised-button";
    } else if (value == Device_action.device_edit) {
      const saveclick = this.obj_document.getElementById("btneditclick");
      saveclick.className = "clsdeviceeditbutton mat-raised-button";
    }
  }

  disableSavebutton(value) {
    if (value == Device_action.device_add) {
      const saveclick = document.getElementById("btnsaveclick");
      if (saveclick != null)
        saveclick.className = "clsdeviceaddbutton mat-raised-button disablebtn";
    } else if (value == Device_action.device_edit) {
      const saveclick = this.obj_document.getElementById("btneditclick");
      saveclick.className = "clsdeviceeditbutton mat-raised-button disablebtn";
    }
  }
  
  toggleinfoVisibility(event,enableType) {
    if(enableType == 1)
    {
      if (event.checked == true) 
      {
        this._egressenablehourmin1 = false;
        this.checkallinfo1 = true;   
        this.data_startTime1 = 0;      
        this.data_endTime1 = 0;     
      } else 
      {
        this._egressenablehourmin1 = true;        
        this.checkallinfo1 = false;
        this.data_startTime1 = 0;      
        this.data_endTime1 = 0;
      }
    }
    else if(enableType == 2)
    {
      if (event.checked == true) 
      {
        this._egressenablehourmin2 = false;
        this.checkallinfo2 = true;    
        this.data_startTime2 = 0;      
        this.data_endTime2 = 0;    
      } else 
      {
        this._egressenablehourmin2 = true;        
        this.checkallinfo2 = false;
        this.data_startTime2 = 0;      
        this.data_endTime2 = 0;
      }
    }
    else if(enableType == 3)
    {
      if (event.checked == true) 
      {
        this._egressenablehourmin3 = false;
        this.checkallinfo3 = true;  
        this.data_startTime3 = 0;      
        this.data_endTime3 = 0;      
      } else 
      {
        this._egressenablehourmin3 = true;        
        this.checkallinfo3 = false;
        this.data_startTime3 = 0;      
        this.data_endTime3 = 0;
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
