<app-technologyoverlay-header></app-technologyoverlay-header>
<mat-card class="technologyoverlay-mat-card">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-technologyoverlay-leftbar></app-technologyoverlay-leftbar>
    </div>

    <div fxLayout="column" fxFlex="100">
      <div class="clsdivcolumn">
        <!--<img class="clsmapeditimage" style= "cursor:pointer;" src="assets/images/Edit_Icon.png" (click)="openFloorEditdialog()"/> -->
        <label class="clslblheader"><b><span id="spnfloorName"></span></b> - {{Building_Name}} - {{Campus_Name}}</label>
        <span id="hdFloorName" style="display:none;"></span>
        <span id="mouseovertext" class="clsSpanmouseover"></span>
        <button class="btnuserguide" mat-raised-button>User Guide</button>
      </div>

      <div style='display: flex;' class="clsdivmainmap">
        <div id="map" class="map">
        </div>

        <div id="divCreateSpaceMenu" class="clsrightside_icon_popup"
          style="display:none; position: absolute; z-index: 9999999; background-color: #EFECEC; top: 260px; right: 8.4%; border: 1px solid #999999;">
          <div id="container" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor:pointer;"
            (click)="draw_rectangle()" (mouseenter)="mouseoverrightsidemenu(this.Tech_Rectangle_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Rectangle_Space)">
            <img class="clsmapimage" src="assets/images/RectangleSpace.png" />
            <p style="text-align: center;">Rectangle</p>
          </div>
          <div id="container" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor:pointer;"
            (click)="draw_ellipse()" (mouseenter)="mouseoverrightsidemenu(this.Ellipse_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.Ellipse_Space)">
            <img class="clsmapimage" src="assets/images/EllipseBuilding.png" />
            <p style="text-align: center;">Ellipse</p>
          </div>
          <div id="container" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor:pointer;"
            (click)="draw_freehand()" (mouseenter)="mouseoverrightsidemenu(this.FreeHand_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.FreeHand_Space)">
            <img class="clsmapimage" src="assets/images/FreehandBuilding.png" />
            <p style="text-align: center;">Freehand</p>
          </div>
        </div>
        <div id="divEditSpaceMenu" class="clsrightside_icon_popup"
          style="display:none; position: absolute; z-index: 9999999; background-color: #EFECEC; top: 350px; right: 8.4%; border: 1px solid #999999;">
          <div id="container" style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;"
            (click)="Edit_Points()" (mouseenter)="mouseoverrightsidemenu(this.Edit_Point)"
            (mouseleave)="mouseleaverightsidemenu(this.Edit_Point)">
            <img class="clsmapimage" src="assets/images/editpoints.png" />
            <p style="text-align: center;">Edit Points</p>
          </div>
          <div id="divEditRotate" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor:pointer;"
            (click)="Rotate_Scale_Points(0)" (mouseenter)="mouseoverrightsidemenu(this.Rotate_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.Rotate_Space)" class="disablebtn">
            <img class="clsmapimage" src="assets/images/RotateBuilding.png" />
            <p style="text-align: center;">Rotate</p>
          </div>
          <div id="divEditReflect" style="border:2px solid #d2d4d8;height: 100px;display:grid;cursor:pointer;"
            (click)="editspaceClick()" (mouseenter)="mouseoverrightsidemenu(this.Reflect_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.Reflect_Space)">
            <img class="clsmapimage" src="assets/images/Edit_Icon.png" />
            <p style="text-align: center;">Space Details</p>
          </div>
        </div>

        <!---------------------------DEVICE LIST------------------------------------>
        <div id="divDeviceList" class="clsrightside_device_popup" style="display:none;">
          <div id="container"
            style="border:2px solid #d2d4d8;height: 100px;display:flex;background-color: #EFECEC;align-items: center;">
            <div class="clsmatselect">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:75%;">
                <mat-select style="height:20px;" placeholder="Sort by"
                  (selectionChange)="selectDeviceSort($event.value)">
                  <mat-option *ngFor="let Sorting of DeviceSorting" [value]="Sorting.Value">
                    {{Sorting.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="clsdivshowcount">Total Devices : {{Total_DeviceInfo}}</div>
          </div>

          <perfect-scrollbar style="max-height: 612px;border-bottom: 1px solid #858383;">
            <div *ngFor="let deviceinfo of Device_Info;">
              <div id="container" class="deviceinfo_{{deviceinfo.DataId}}"
                style="border:2px solid #d2d4d8;height: 110px;display:grid;background-color: #ffffff;"
                *ngIf="deviceinfo.FilterDeviceType != 'Room'" (mouseenter)="mouseoverDevicedetails(deviceinfo)"
                (mouseleave)="mouseleaveDevicedetails(deviceinfo)">

                <div style="width: 100%;display: inline-flex;">
                  <div class="clsdivDevice">
                    <img src="assets/images/Edit_Icon.png" class="clsdeviceEditimg"
                      *ngIf="deviceinfo.FilterDeviceType != 'Zone' && bAddEdit==true"
                      (click)="editDevicedetails(deviceinfo.DataId)" />
                    <div *ngIf="deviceinfo.UnitLocation.length < 15"
                      [ngStyle]="{'margin-left': deviceinfo.FilterDeviceType != 'Zone' ? '1em' : '2.4em'}"
                      style="margin-left:1em;">Space: {{deviceinfo.UnitLocation}}</div>
                    <div *ngIf="deviceinfo.UnitLocation.length >= 15" style="margin-left:1em;"
                      matTooltipClass="devicetooltip" matTooltip={{deviceinfo.UnitLocation}} #tooltip="matTooltip"
                      matTooltipPosition="above">
                      Space: {{deviceinfo.UnitLocation.slice(0,8) + ".."}}</div>                                           
                  </div>
                  <div class="clsdivDevice">
                    <div style="margin-left:1em;">Device: <b>{{deviceinfo.NewDeviceName}}</b></div>
                  </div>
                </div>

                <div style="width: 100%;display: inline-flex;">
                  <div class="clsdivDevice">
                    <img *ngIf="deviceinfo.DeviceId > 0 && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Gateway"
                      src="assets/images/Device_Green.png" class="clsdeviceEditimg" (click)="editDevicedetails()" />
                    <img *ngIf="deviceinfo.DeviceId == 0 && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Gateway"
                      src="assets/images/Device_Yellow.png" class="clsdeviceEditimg" (click)="editDevicedetails()" />
                    <img *ngIf="deviceinfo.DeviceId == 0 && deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Gateway"
                      src="assets/images/Device_Green.png" class="clsdeviceEditimg" (click)="editDevicedetails()" />
                    <div style="margin-left:1em;"
                      *ngIf="deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Rex && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Pullcord && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.UT && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Gateway && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.HRex && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Intercom">
                      Device ID: <b>{{deviceinfo.DeviceTypeId}}</b></div>
                    <div style="margin-left:1em;"
                      *ngIf="deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Rex || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Pullcord || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.UT || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Gateway || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.HRex || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Intercom">
                      Device ID: <b>{{deviceinfo.DeviceId}}</b></div>
                  </div>
                  <div class="clsdivDevice">
                    <div style="margin-left:1em;"
                      *ngIf="deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Rex && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Pullcord && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.UT && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Gateway && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.HRex && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.Intercom">
                      IR ID: <b>{{deviceinfo.DeviceId}}</b></div>
                    <div style="margin-left:1em;"
                      *ngIf="(deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Rex || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Pullcord || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.UT || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Gateway || deviceinfo.NewDeviceName == _NewDeviceTypeDesc.Intercom) && deviceinfo.NewDeviceName != _NewDeviceTypeDesc.HRex">
                      IR ID: <b>{{deviceinfo.DeviceTypeId}}</b></div>
                    <div style="margin-left:1em;" *ngIf="deviceinfo.NewDeviceName == _NewDeviceTypeDesc.HRex">IR ID:
                      <b>{{deviceinfo.MacId}}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>

        </div>
        <!------------------------------END---------------------------------->

        <div id="divrightsidebar" class="clsrightside_sideicon">
          <div id="container_DeviceList" class="enablebtn"
            style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;" (click)="device_list()"
            (mouseenter)="mouseoverrightsidemenu(this.Tech_Devicelist)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Devicelist)">
            <img class="clsmapimage" src="assets/images/devicelist.png" />
            <p style="text-align: center;">Device List</p>
          </div>
          <div id="container_SelectMove" class="enablebtn"
            style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;" (click)="select_move(undefined)"
            (mouseenter)="mouseoverrightsidemenu(this.Tech_Move)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Move)">
            <img class="clsmapimage" src="assets/images/selectmove.png" />
            <p style="text-align: center;">Move</p>
          </div>
          <div id="container_CreateSpace" class="enablebtn"
            style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;" (click)="create_space()"
            (mouseenter)="mouseoverrightsidemenu(this.Tech_Create_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Create_Space)">
            <img class="clsmapimage" src="assets/images/createspace.png" />
            <p style="text-align: center;">Create Space</p>
          </div>
          <div id="container_EditSpace" class="enablebtn"
            style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;" (click)="edit_space()"
            (mouseenter)="mouseoverrightsidemenu(this.Tech_Edit_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Edit_Space)">
            <img class="clsmapimage" src="assets/images/editbuilding.png" />
            <p style="text-align: center;">Edit Space</p>
          </div>
          <div id="container_AddDevice" class="enablebtn"
            style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;"
            (click)="addNewDevice(addcampusid, Campus_Name)" (mouseenter)="mouseoverrightsidemenu(this.Tech_Add_Device)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Add_Device)">
            <img class="clsmapimage" src="assets/images/adddevices.png" />
            <p style="text-align: center;">Add Devices</p>
          </div>
          <div id="container_Duplicate" class="enablebtn"
            style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;" (click)="duplicate()"
            (mouseenter)="mouseoverrightsidemenu(this.Tech_Duplicate_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Duplicate_Space)">
            <img class="clsmapimage" src="assets/images/duplicate.png" />
            <p style="text-align: center;">Duplicate</p>
          </div>
          <div id="container_InsertTemplate" class="enablebtn"
            style="border:2px solid #d2d4d8;height: 85px;display:grid;cursor:pointer;" (click)="insert_template()"
            (mouseenter)="mouseoverrightsidemenu(this.Tech_Insert_Template)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Insert_Template)">
            <img class="clsmapimage" src="assets/images/inserttemplate.png" />
            <p style="text-align: center;">Insert Template</p>
          </div>
          <div id="container_DeleteSpace" class="enablebtn"
            style="border:2px solid #d2d4d8;height: 95px;display:grid;cursor:pointer;" (click)="deleteinfo()"
            (mouseenter)="mouseoverrightsidemenu(this.Tech_Delete_Space)"
            (mouseleave)="mouseleaverightsidemenu(this.Tech_Delete_Space)">
            <img class="clsmapimage" src="assets/images/deletebuilding.png" />
            <p style="text-align: center;">Delete</p>
          </div>
        </div>
      </div>
      <!--New added div-->
      <div id="info"></div>
      <div id="popup" class="ol-popup">
        <a href="#" id="popup-closer" class="ol-popup-closer"></a>
        <div id="popup-content"></div>
      </div>
      <div id="marker" style="display: none;">
        <div id="marker1" class="marker"></div>
        <div id="marker2" class="marker"></div>
        <div id="marker3" class="marker"></div>
        <div id="marker4" class="marker"></div>
        <div id="mapselected"></div>
        <button id="btn_MapDetailsChanged">Map Details Changed</button>
        <button id="btn_gotoCampus"></button>
      </div>
      <!--end-->


      <div id="divDeviceheader" style='height: 50px;'>
        <mat-card class="tech-mat-card">
          <mat-dialog-actions style="float:left;margin:5px;margin-top:0px;padding-top:2px;">
            <button color="primary" type="button" id="btnEditZone" class="clsmapfloortempbutton" mat-raised-button
              (click)="openFloorEditdialog()" *ngIf="bAddEdit == true">Edit Zone Details</button>
            <button color="primary" type="button" id="btnShowTemplate" class="clsmapfloortempbutton" mat-raised-button
              (click)="ShowTemplateDetails()" *ngIf="bAddEditTemplate == true">Save Zone as Template</button>
          </mat-dialog-actions>

          <div id="divAddDevice" fxLayout="row" fxLayout.sm="column" fxLayoutGap="0px"
            fxLayoutAlign="space-between left" style="float:right;">

            <div class="clsaligndiv">
              <button style="width: 100px;margin:0.5em;" class="clsbtnmapfloorcancel" mat-raised-button
                (click)="btnclkfloormapinfohide()">Exit</button>
              <button style="width: 100px;" id="btnsaveclickmapview" class="clsdeviceaddbutton" mat-raised-button
                (click)="onSaveCampusRoom() " *ngIf="bAddEdit==true">Save</button>

              <button id="btnEditSaveExists" color="primary" style="width: 100px;margin:0.5em;display:none;"
                mat-raised-button (click)="EditSaveExists()">Exit</button>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-card>
      </div>

    </div>

  </div>
</mat-card>