
<div id="alertdownload"> 
  <div class="alertdownload-title">
    <h3 class="alertdownloadHeader" style="margin: 0 0 10px 11px;">Alert Report
    <span><img class="clscloseAlertimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
    <mat-divider ></mat-divider> 
  </div>

 <div>   
        <form style="padding:24px;" [formGroup]="alertReportForm" autocomplete="off" novalidate >                         
          <mat-grid-list [cols]="columns" [rowHeight]="alertrowHeight">  
              <mat-grid-tile>
                  <label>Time</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:99%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                            <mat-select  placeholder="Select Time" formControlName="alertTime" (selectionChange)="selectTimes($event.value)">    
                                <mat-option value="AlertTime">Alert Time </mat-option>
                                <mat-option value="AckedTime">Accepted Time</mat-option>
                          </mat-select>
                          <mat-error>Please select Alert/Accepted Time</mat-error>
                        </mat-form-field>
                    </div>
              </mat-grid-tile>

              <mat-grid-tile>
                  <label>From</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:99%;height: 47px;">
                        <mat-form-field style="width:100%;height:36.5px;" appearance="outline" floatLabel="auto">
                            <input matInput [min]="startDate" [max]="todaydate" [matDatepicker]="picker1" placeholder="Select From Date" #alertpickerInput [formControl]="AlertFromdateFormControl" (dateInput)="addEvent('', $event)"  >
                            <mat-datepicker-toggle class="clsdatepicker" [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>            
                            <mat-error *ngIf="AlertFromdateFormControl.hasError('required')">{{getErrorMessage(alertpickerInput.value)}}</mat-error>
                            <mat-error *ngIf="AlertFromdateFormControl.hasError('matDatepickerMax') || AlertFromdateFormControl.hasError('matDatepickerMin')">Invalid date</mat-error>
                        </mat-form-field>
                    </div>
              </mat-grid-tile>                 

            <mat-grid-tile>
              <label>To</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
                <div class="text-inside" style="width:99%;height: 47px;">
                    <mat-form-field style="width:100%;height:36.5px;" appearance="outline" floatLabel="auto">
                        <input matInput [min]="startDate" [max]="todaydate" [matDatepicker]="picker2" placeholder="Select To Date" #alertpickerInput1 [formControl]="AlertTodateFormControl" (dateInput)="addEvent1('', $event)"  >
                        <mat-datepicker-toggle class="clsdatepicker" [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>           
                        <mat-error *ngIf="AlertTodateFormControl.hasError('required')">{{getErrorMessage(alertpickerInput1.value)}}</mat-error>
                        <mat-error *ngIf="AlertTodateFormControl.hasError('matDatepickerMax') || AlertTodateFormControl.hasError('matDatepickerMin')">Invalid date</mat-error>
                      </mat-form-field>
                </div>
          </mat-grid-tile>  
          
          <mat-grid-tile>
              <label>Alert Status</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
                <div class="text-inside" style="width:99%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                        <mat-select  placeholder="Select Status" formControlName="alertstatus" (selectionChange)="selectAcked($event.value)">    
                            <mat-option value="All">All</mat-option>
                            <mat-option value="5" *ngIf="time!='AckedTime'">{{EnumAlertStatus.Notify}}</mat-option>
                            <mat-option value="0" *ngIf="time!='AckedTime'">{{EnumAlertStatus.Notified}} </mat-option>                            
                            <mat-option value="1">{{EnumAlertStatus.Accepted}}</mat-option> 
                            <mat-option value="2">{{EnumAlertStatus.Presence}}</mat-option> 
                            <mat-option value="3">{{EnumAlertStatus.Complete}}</mat-option> 
                            <mat-option value="4">{{EnumAlertStatus.Canceled}}</mat-option> 
                      </mat-select> 
                      <mat-error>Please select Alert Status</mat-error>                   
                    </mat-form-field>
                </div>
          </mat-grid-tile>           

              </mat-grid-list>

              <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%;">       
                    <div class="loadalertreportspinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div> 

          <mat-dialog-actions class="clsalertbuttoncontrol">                    
              <button class="clsbtnalertreport" color="primary" mat-raised-button (click)="exportAlertReportExcel(data)" >Download CSV</button> 
              <button id="pdf1" class="clsbtnalertreport" color="primary" mat-raised-button (click)="exportAlertReportPdf(data)" >Download pdf</button>

          </mat-dialog-actions>
      </form>





 </div>


</div>