import {
  Component,
  EventEmitter,
  Inject,
  Output,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { SharedData } from "../../../api";
import { ApiService } from "../../../api.service";
import { Router } from "@angular/router";
import { UntypedFormControl } from "@angular/forms";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { HostListener } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TechnologyoverlayService } from "../technologyoverlay.service";
import { MatSidenav } from "@angular/material/sidenav";
import { AlertSharedService } from "../../../_services/index";
import { DOCUMENT } from "@angular/common";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";

@Component({
  selector: "app-technologyoverlay-header",
  templateUrl: "./technologyoverlay-header.component.html",
  styleUrls: ["./technologyoverlay-header.component.scss"],
})
export class TechnologyoverlayHeaderComponent implements OnInit, OnDestroy {
  CampusFulldetails: any[];
  Campusdetails = {};
  selectedBuildingdetails = {};
  selectedFloordetails = {};
  Campus_key = "Campusinfo";
  Building_key = "Buildinginfo";
  Floor_key = "Floorinfo";
  search: UntypedFormControl = new UntypedFormControl();
  SharedDetails: SharedData;
  selectedCampus = 0;
  selectedBuilding = 0;
  selectedFloor = 0;
  router_url;
  screenWidth;
  screenflag = true;
  public href = "";
  private _onDestroy: Subject<void> = new Subject<void>();
  public static isMenuClosed = true;
  getselectedmapdetails_header;
  UserName;

  showMapview = true;
  showListview = true;
  selectedIndex = 0;

  @ViewChild("searchdeviceInput") searchdeviceInput: ElementRef;

  @ViewChild("sidenav") public sideMenuNav: MatSidenav;
  constructor(
    private apiService: ApiService,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar,
    private technologyoverlayService: TechnologyoverlayService,
    private alertSharedService: AlertSharedService,
    @Inject(DOCUMENT) private document: any,
    public mainLayoutService: MainLayoutService
  ) {
    this.router_url = router.url;
    this.onResize();

    this.technologyoverlayService.toggleTechMenuNav.subscribe(() => {
      this.sideMenuNav.toggle();
      if (this.sideMenuNav._animationState == "void") {
        TechnologyoverlayHeaderComponent.isMenuClosed = true;
      } else if (this.sideMenuNav._animationState == "open") {
        TechnologyoverlayHeaderComponent.isMenuClosed = false;
      }
    });
  }

  changeLog: string[] = [];

  ngOnInit() {
    try {
      const userName = localStorage.getItem("username") ?? "";
      this.UserName = JSON.parse(userName);
      this.alertSharedService
        .currentShared()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedDetails: SharedData) => {
          this.SharedDetails = SharedDetails;
        });
    } catch (err) {
      throw new Error(
        "technologyoverlay-header.component.ts" +
          " - ngOnInit() - " +
          err.message
      );
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1324) this.screenflag = false;
    else this.screenflag = true;
  }

  clkMenusideTechnav() {
    this.technologyoverlayService.toggleTechMenuNav.emit();
  }

  @Output() onMapChanged = new EventEmitter<any>();
  onDeviceFilterdetails() {
    this.onMapChanged.emit();
    localStorage.setItem("ResetFilter", "spanresetFilter");
  }

  @Output() onSearchdevice = new EventEmitter<any>();
  searchDevice(value) {
    try {
      this.onSearchdevice.emit(value);
    } catch (err) {
      throw new Error(
        "technologyoverlay-header.component.ts" +
          " - searchDevice() - " +
          err.message
      );
    }
  }

  @Output() onresetSearchdevice = new EventEmitter<any>();
  imgresetfilterdata() {
    this.searchdeviceInput.nativeElement.value = "";
    const spanResetFilter = document.getElementById("spanresetFilter");
    if(spanResetFilter != null)
      spanResetFilter.style.color = "#837f7f";
    this.onresetSearchdevice.emit();
  }

  closeTechSidebarnav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  @Output() onOutdoorNavbarview = new EventEmitter<any>();
  outdoornavchange($event): void {
    if ($event.tab.textLabel == "Map View") {
      this.selectedIndex = $event.index;
    } else if (
      $event.tab.textLabel == "List View" &&
      this.showListview == true
    ) {
      this.selectedIndex = $event.index;
    }
    this.onOutdoorNavbarview.emit($event.tab.textLabel);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
