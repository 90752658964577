import { Component, OnInit, Inject } from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ReportService } from "../report.service";
import { getLocationType, updateColorConfig } from "../report";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-report-colorconfig",
  templateUrl: "./report-colorconfig.component.html",
  styleUrls: ["./report-colorconfig.component.scss"],
})
export class ReportColorconfigComponent implements OnInit {
  locationtype;
  public assetcolorCreationForm: UntypedFormGroup;
  public dataForm: UntypedFormGroup;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<ReportColorconfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ReportService: ReportService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.getLocationType();
  }
  getLocationType() {
    try {
      const locationtypeitem: getLocationType = {
        authKey: this.authkey,
      };
      this.ReportService.getLocationType(locationtypeitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((data: any[]) => {
          this.locationtype = data;
          console.log(
            "locationtype : " + JSON.stringify(this.locationtype),
            this.locationtype
          );
          localStorage.setItem("Clean", this.locationtype[0].TypeName);
          localStorage.setItem("InUse", this.locationtype[1].TypeName);
          localStorage.setItem("Dirty", this.locationtype[2].TypeName);
          localStorage.setItem("Unknown", this.locationtype[3].TypeName);
          this.dataForm = new UntypedFormGroup({
            key: new UntypedFormArray(
              this.locationtype.map(
                (data) =>
                  new UntypedFormControl(data.key, [Validators.required])
              )
            ),
          });
        });
    } catch (err) {
      throw new Error(
        "report-colorconfig.component.ts" +
          " - getLocationType() - " +
          err.message
      );
    }
  }

  updateColorConfigType() {
    (document.activeElement as HTMLTextAreaElement).blur();
    const data = this.dataForm.value.key;
    const typename = [] as any[];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] != null) {
          if (data[i].trim().length == 0) {
            let description = "";
            if (i == 0) {
              description = "Color Code green description should not be empty.";
            } else if (i == 1) {
              description =
                "Color Code yellow description should not be empty.";
            } else if (i == 2) {
              description = "Color Code red description should not be empty.";
            } else if (i == 3) {
              description = "Color Code grey description should not be empty.";
            }
            generateAlertMessage(this.dialog, description);
            return;
          }
          if (data[i].trim().length > 20) {
            let description = "";
            if (i == 0) {
              description =
                "Color Code green description should be less than or equal to 20.";
            } else if (i == 1) {
              description =
                "Color Code yellow description should be less than or equal to 20.";
            } else if (i == 2) {
              description =
                "Color Code red description should be less than or equal to 20.";
            } else if (i == 3) {
              description =
                "Color Code grey description should be less than or equal to 20.";
            }
            generateAlertMessage(this.dialog, description);
            return;
          }
          typename.push(
            data[i].replace(/\s+/g, " ").trim() + "_" + (i + 1) + ","
          );
        }
      }
    }
    const saveclick = document.getElementById("btnsaveclick");
    if (saveclick != null)
      saveclick.className =
        "clsbtnColorconfigSave mat-raised-button disablebtn";
    const assetdata: updateColorConfig = {
      authKey: this.authkey,
      typeName: typename.toString(),
    };
    this.ReportService.updateColorConfigType(assetdata)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: any[]) => {
        if (apiresponse != null && apiresponse != undefined) {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "Color Code edited successfully."
            );
            this.getLocationType();
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Color Code edited failed.");
          }
        } else {
          generateAlertMessage(this.dialog, "Color Code edited failed.");
        }
        this.thisDialogRef.close("Confirm");
      });
  }
  public onCloseAsset = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
}
