<perfect-scrollbar style="max-width: 650px; max-height: 610px;" >
  <div class="clsaddoutdoor">
      <div class="addoutdoor-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addoutdoorHeader" style="margin: 0 0 10px 11px;">Outdoor Space Information
        <span><img class="clsaddoutcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>
      <div>
        <form style="padding:24px;" [formGroup]="addoutdoorspaceForm" autocomplete="off" novalidate (ngSubmit)="newaddOutdoor(addoutdoorspaceForm.value)">                         
            <mat-grid-list [cols]="columns" rowHeight="72px">  
                <mat-grid-tile>
                    <label>Campus</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                            <input matInput placeholder="Campus Name" maxlength="32" formControlName="campus" [(ngModel)]="data.CampusName">
                            <input type="hidden" formControlName="campusId" [(ngModel)]="data.CampusId" required> 
                            <input type="hidden" formControlName="buildId" [(ngModel)]="data.BuildId" required> 
                            <input type="hidden" formControlName="floorId" [(ngModel)]="data.FloorId" required> 
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

                <mat-grid-tile>
                  <label>Approximate <br>Space Name</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:62%;">
                           <input matInput placeholder="Approximate Space Name" maxlength="32" formControlName="name" (keyup)="handleInput($event)">
                           <mat-error *ngIf="addoutdoorspaceForm.controls.name.hasError('required')">Please enter Approximate Name</mat-error>
                           <mat-error *ngIf="addoutdoorspaceForm.controls.name.hasError('pattern')">Please enter valid Approximate Name</mat-error>
                           <mat-error *ngIf="addoutdoorspaceForm.controls.name.hasError('maxlength')">Max length 32 characters only</mat-error>
                        </mat-form-field>
                        <label style="padding-left:2em;">Indoor</label>
                        <mat-slide-toggle [checked]="checkApproximate" [disabled]="checkCampusorZone == 0" style="padding-left:1em;" ></mat-slide-toggle>
                      </div>
                </mat-grid-tile>  
                
                <mat-grid-tile>
                  <label>Description</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="Description" maxlength="32" formControlName="description" (keyup)="handleInput($event)" [(ngModel)]="approxDescription">
                          <!-- <mat-error *ngIf="addoutdoorspaceForm.controls.description.hasError('required')">Please enter Description</mat-error> -->
                          <mat-error *ngIf="addoutdoorspaceForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                          <!-- <mat-error *ngIf="addoutdoorspaceForm.controls.description.hasError('maxlength')">Max length 32 characters only</mat-error> -->
                        </mat-form-field>
                      </div>
                </mat-grid-tile> 
                
                <mat-grid-tile>
                  <label>Space ID</label>
                  <div style="margin-left: 1.5em;" [matTooltip]="matTooltipSpaceId" matTooltipClass="tooltip" #tooltip="matTooltip" (click)="tooltip.show()">                
                    <mat-icon style="color: #8e8a8a;margin-top:0.4em;">info icon</mat-icon>                                                  
                  </div>  
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                      <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="Space Id" maxlength="10" formControlName="spaceId" [(ngModel)]="maxSpaceId" (keyup)="handleInput($event)">
                          <mat-error *ngIf="addoutdoorspaceForm.controls.spaceId.hasError('required')">Please enter Space ID</mat-error>
                          <mat-error *ngIf="addoutdoorspaceForm.controls.spaceId.hasError('pattern')">Please enter valid Space ID</mat-error>                          
                        </mat-form-field>
                      </div>
                </mat-grid-tile>
              
              <mat-grid-tile>
                <label>Location <br>Accuracy</label>
                <div style="margin-left: 1.5em;" [matTooltip]="matTooltipLocAccuracy" matTooltipClass="tooltip" #tooltip="matTooltip" (click)="tooltip.show()">                
                  <mat-icon style="color: #8e8a8a;margin-top:0.2em;">info icon</mat-icon>                                                  
                </div>  
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                        <input matInput placeholder="Location Accuracy" maxlength="10" formControlName="accuracy">  
                        <mat-error *ngIf="addoutdoorspaceForm.controls.accuracy.hasError('required')">Please enter Location Accuracy</mat-error>
                        <mat-error *ngIf="addoutdoorspaceForm.controls.accuracy.hasError('pattern')">Please enter valid Location Accuracy</mat-error>
                        <mat-error *ngIf="addoutdoorspaceForm.controls.accuracy.hasError('maxlength')">Max length 10 characters only</mat-error>
                      </mat-form-field>
                    </div>
              </mat-grid-tile> 
              <mat-grid-tile colspan="3">
              <label>Note: The Location Accuracy value shown above is automatically populated from the signature server. </label>
             </mat-grid-tile>
                </mat-grid-list>
                
            <mat-dialog-actions style="float:left;margin-top: 2em;">              
              <!-- <button type="button" class="clscancelbutton" mat-raised-button>Delete</button>  -->
            </mat-dialog-actions>

            <mat-dialog-actions class="clsaddoutbtncontrol">                                   
              <button type="button" class="clsaddoutcancelbutton" mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
              <button id="btnsaveoutdooraddclick" class="clsaddoutsavebutton" mat-raised-button >Save</button>
            </mat-dialog-actions>
            
        </form>
      </div>
  </div>
  </perfect-scrollbar>
