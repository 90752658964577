
    <div class="clseditFacility">       
                <div class="editFacility-title">
                  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editFicilityHeader" style="margin: 0 0 10px 11px;">Provider Information
                  <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onclickCancel()"></span></h3>
                  <mat-divider ></mat-divider> 
                </div>
          
                <form  [formGroup]="facilityditForm" autocomplete="off" novalidate (ngSubmit)="editfacilitydetails(facilityditForm.value)" style="height: 250px;">
                <div  class="content clsdivcontent" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill style="margin-top:25px;">
                      
                        <div fxFlex="62">                             
                            <mat-grid-list [cols]="firstcolumns" [rowHeight]="firstrowHeight">                               
                                  <mat-grid-tile>
                                      <label>Provider Name</label>
                                  </mat-grid-tile>
                                  <mat-grid-tile [colspan]="firstcolspan" fxLayout="row" fxFlexFill class="clseditfacility">
                                      <div fxFlex="90">
                                          <mat-form-field appearance="outline" floatLabel="auto" style="width: 85%;">
                                            <input matInput placeholder="Provider Name" maxlength="32" formControlName="facilityname" [(ngModel)]="facilityname" required>
                                             <mat-error *ngIf="facilityditForm.controls.facilityname.hasError('required')">
                                                Please enter Provider Name
                                              </mat-error>
                                              <mat-error *ngIf="facilityditForm.controls.facilityname.hasError('pattern')">
                                                Please enter valid Provider Name
                                             </mat-error>
                                             <mat-error *ngIf="facilityditForm.controls.facilityname.hasError('maxlength')">
                                                Max length 32 characters only
                                             </mat-error>
                                          </mat-form-field>
                                      </div>
                                  </mat-grid-tile>                                           
                             </mat-grid-list>                              
                        </div>
                        <div fxFlex="35" class="clsdivimage">           
                          <mat-grid-list cols="1">
                            <mat-grid-tile class="clscampusImg">
                              <div>
                                <div>
                                  <img class="clsFacilityDragplanimg" type="file" [src]="Imgurl + facilityimgname"
                                    (click)='imageInput.click()' draggable="false"/>
                                  <div draggable="true" ngClass="{{dragAreaClass}}">
                                    <div class="row">
                                      <div class="col-md-12 text-center">
                                        <a href="javascript:void(0)" (click)="imageInput.click()"></a>
                                        <input style="display:none;" type="file" placeholder="Photo"
                                          formControlName="facilityimage" #imageInput onclick="this.value = null"
                                          (change)="onChangeeditfacility($event.target.files)"
                                          accept=".png, .jpg, .bmp" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div><label>Click or Drag and Drop Image <br>Supported file types: bmp, jpg, png</label></div>
                              </div>
                            </mat-grid-tile>
                            
                          </mat-grid-list>
                      </div>                    
           </div>

           <mat-dialog-actions class="clsFacilitybuttoncontrol">    
              <button type="button" class="clsfacilityCancelbutton" mat-raised-button (click)="onclickCancel()">Cancel</button>
              <button id="btnsavefacilityeditclick" class="clsfacilitybutton" mat-raised-button [disabled]="!facilityditForm.valid" *ngIf="bAddEdit == true">Update</button>&nbsp;
          </mat-dialog-actions>

          </form>
      </div>
  