import { Component, OnInit, Inject,OnDestroy,ViewEncapsulation,ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,  MatDialog} from "@angular/material/dialog";
import { FormGroup, FormControl } from '@angular/forms';
import { RollbarService } from '../../../../rollbar';
import * as Rollbar from 'rollbar';
import { SessionCheck,generateAlertMessage} from '../../../../app-general';
import {Router} from '@angular/router';
import * as XLSX from 'xlsx';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TechnologyoverlayService } from '../../technologyoverlay.service';
import { saveEventDataTektone, saveBoardInfoDataTektone, saveBoardSetupDataTektone } from '../../technologyoverlay';
type AOA = any[][];

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./upload-dialog.component.scss']
})
export class TekToneUploadDialogComponent implements OnInit,OnDestroy {

  message = "";
  Type = "";
  eventData: AOA = [[1, 2], [3, 4]];
  boardInfoData: AOA = [[1, 2], [3, 4]];
  boardSetupData: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  sheetNames = ['Event','Board Info','Board Setup'];
  isInArray = false;
  uploadloading = false;
  uploadedFileName ="";
  public UploadConfigurationForm : FormGroup;
  Uploadfilesave = true;
  btnvalue;
  SiteId = "1";
  authkey;

  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(private router: Router,private technologyoverlayService: TechnologyoverlayService,public dialog: MatDialog,public thisDialogRef: MatDialogRef<TekToneUploadDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,@Inject(RollbarService) private rollbar: Rollbar) 
  {
    try
    {
      this.btnvalue = "Choose File";
      this.uploadedFileName ="";
    }
    catch (err) {
      throw new Error('upload-dialog.component.ts' + ' - constructor() - ' + err.message);
    }
  }

  ngOnInit() 
  {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.UploadConfigurationForm = new FormGroup({
      uploadConfig: new FormControl(''),
    });
  }

  @ViewChild('clkimageInput') clkimageInput: any;
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
   
    this.uploadedFileName = target.files[0].name;
    if(this.Uploadfilesave == true)
    {
      if(this.uploadedFileName != null) 
      {
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        
          if(this.validateSheetName(wb.SheetNames[0],wb.SheetNames[1],wb.SheetNames[2]) == true) 
          {
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
  
            this.eventData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
              
            const wsname1: string = wb.SheetNames[1];
            const ws1: XLSX.WorkSheet = wb.Sheets[wsname1];

            this.boardInfoData = <AOA>(XLSX.utils.sheet_to_json(ws1, { header: 1 }));

            const wsname2: string = wb.SheetNames[2];
            const ws2: XLSX.WorkSheet = wb.Sheets[wsname2];

            this.boardSetupData = <AOA>(XLSX.utils.sheet_to_json(ws2, { header: 1 }));
            this.btnvalue = "Upload";    
          }
          else
          {
            generateAlertMessage(this.dialog, 'Uploaded file is incorrect.');
            this.Uploadfilesave = false; 
            this.clkimageInput.nativeElement.value = ''; 
            this.uploadedFileName = '';    
            this.btnvalue = "Choose File";     
          }
        
        };
        reader.readAsBinaryString(target.files[0]);
      }
    }
  }
  saveUploadedConfigData()
  {
    if(this.boardInfoData.length >0 && this.boardSetupData.length > 0 && this.eventData.length > 0)
    {
      this.uploadloading = true;    
      this.Uploadfilesave = false;
      this.boardSetupData.sort(this.sortByRoomId);
      
      const saveEventData : saveEventDataTektone = {authKey: this.authkey, jsonData: JSON.stringify(this.eventData)};
      const saveBoardInfo : saveBoardInfoDataTektone = {  authKey: this.authkey, jsonData: JSON.stringify(this.boardInfoData),fileName: this.uploadedFileName};
      const saveBoardSetup : saveBoardSetupDataTektone = { authKey: this.authkey, jsonData: JSON.stringify(this.boardSetupData)};
      
      this.technologyoverlayService.saveEventDataTektone(saveEventData).pipe(takeUntil(this._onDestroy))
      .subscribe((res: any[]) => {
        console.log(res);
        this.technologyoverlayService.saveBoardInfoDataTektone(saveBoardInfo).pipe(takeUntil(this._onDestroy))
        .subscribe((res: any[]) => {
          console.log(res);
          this.technologyoverlayService.saveBoardSetupDataTektone(saveBoardSetup).pipe(takeUntil(this._onDestroy))
            .subscribe((res: any[]) => {
              console.log(res);
                  this.uploadloading = false;
                  this.thisDialogRef.close();
          });
        });
      });
    }
  }
  sortByRoomId(a,b)
  {
    return a[1] - b[1];
  }

  onUploadClicked(event)
  {
     if(this.uploadedFileName != null) 
     {
        try
        {
          if(!SessionCheck(this.router))
          {
            this.thisDialogRef.close('Cancel');
             return;
          }
          if(event.target.textContent != "Upload")
          { 
              this.Uploadfilesave = true;  
              const clkFileUpload = document.getElementById("clkfileupload");
              if(clkFileUpload != null)
                clkFileUpload.click();
          }
          else
          {            
            this.eventData = this.eventData.filter(row => row.join("") != "");
            this.boardInfoData = this.boardInfoData.filter(row => row.join("") != "");
            this.boardSetupData = this.boardSetupData.filter(row => row.join("") != "");
            this.validateExcelContent();
          }
        }
        catch (err) {
          throw new Error('upload-dialog.component.ts' + ' - onUploadClicked() - ' + err.message);
        }
    }
    else
    {
      generateAlertMessage(this.dialog, 'Please Choose the file to be upload'); 
      this.thisDialogRef.close();
    }
  }
  onCloseClicked = () => 
  {
      try
      {
        this.thisDialogRef.close('Cancel');
        if(!SessionCheck(this.router))
        {
            return;
        }  
      }
      catch (err) {
        throw new Error('upload-dialog.component.ts' + ' - onCloseClicked() - ' + err.message);
      }
  }
  ngOnDestroy()
  {
    console.log("upload-dialog ngOnDestroy");
  }
  validateSheetName(sheetName1,sheetName2,sheetName3) 
  {
    let res = true;
    this.isInArray = this.sheetNames.includes(sheetName1);
    if(this.isInArray)
    {
      this.isInArray = this.sheetNames.includes(sheetName2);
      if(this.isInArray)
      {
        this.isInArray = this.sheetNames.includes(sheetName3);
        if(!this.isInArray)
        {
          res = false;
        }
      }
      else
      {
        res = false;
      }
    }
    else
    {
      res = false;
    }
    return res;  
  }

  validateExcelContent()
  { 
    try
    {
     if(this.boardInfoData.length > 1 && this.boardSetupData.length > 1 && this.eventData.length > 1)
     {
       let isError = false;
       const nameList = [] as any[];
       const ipaddressList = [] as any[];
       const eventList = [] as any[];
       const descriptionList = [] as any[];
       const boardInfoDetails = this.boardInfoData;
       const boardSetupDetails = this.boardSetupData;
       const eventDetails = this.eventData;
    
       if(eventDetails != null && eventDetails.length > 1)
       {
         if(eventDetails[0].length != 2)
         {
            isError = true;
            generateAlertMessage(this.dialog,'Event sheet have invalid data');
            this.Uploadfilesave = false; 
            this.clkimageInput.nativeElement.value = '';    
            this.uploadedFileName = '';   
            this.btnvalue = "Choose File";   
            return; 
         }
         if(eventDetails[0].length == 2)
         {
           const eventHeaderList = ['Event Name','Event Description'];
           for(let idx=0;idx<eventDetails[0].length;idx++)
           {
              if(eventDetails[0][idx] != eventHeaderList[idx])
              {
                isError = true;
                generateAlertMessage(this.dialog,'Event sheet have invalid header');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
           }
           if(isError == true) return;
         }

         for(let i=1;i<eventDetails.length;i++)
         {
            if(eventDetails[i][0] === undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'Event Name should not be empty in Event');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break; 
            }
            if(eventDetails[i][1] === undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'Event Description should not be empty in Event');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break; 
            }
            if(i==1)
            {
              eventList.push(eventDetails[i][0]);
              descriptionList.push(eventDetails[i][1]);
            }
            else
            {
              const eventExist = eventList.includes(eventDetails[i][0]);
              if(eventExist)
              {
                isError = true;
                generateAlertMessage(this.dialog, 'Event Name should be unique in Event');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = ''; 
                this.uploadedFileName = '';      
                this.btnvalue = "Choose File";   
                break; 
              }
              else
              {
                eventList.push(eventDetails[i][0]);
              }
                       
              const descriptionExist = descriptionList.includes(eventDetails[i][1]);
              if(descriptionExist)
              {
                isError = true;
                generateAlertMessage(this.dialog, 'Event Description should be unique in Event');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
              else
              {
                descriptionList.push(eventDetails[i][1]);
              }
            }
         }
         if(isError == true) return;
       }     
       if(boardInfoDetails != null && boardInfoDetails.length > 1)
       {
          if(boardInfoDetails[0].length != 4)
          {
              isError = true;
              generateAlertMessage(this.dialog,'Board Info sheet have invalid data');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              return; 
          }

          if(boardInfoDetails[0].length == 4)
          {
            const boardinfoHeaderList = ['Board Name','IP Address','TCP Port','Points'];
            for(let idx=0;idx<boardInfoDetails[0].length;idx++)
            {
               if(boardInfoDetails[0][idx] != boardinfoHeaderList[idx])
               {
                 isError = true;
                 generateAlertMessage(this.dialog,'Board Info sheet have invalid header');
                 this.Uploadfilesave = false; 
                 this.clkimageInput.nativeElement.value = '';    
                 this.uploadedFileName = '';   
                 this.btnvalue = "Choose File";   
                 break; 
               }
            }
            if(isError == true) return;
          }
          
          if(boardInfoDetails.length > 2)
          {
              isError = true;
              generateAlertMessage(this.dialog,'Multiple Boards should not be configured');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
          }
          else
          {
          for(let i=1;i<boardInfoDetails.length;i++) 
          {
            if(boardInfoDetails[i][0] == undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'Board Name should not be empty in Board Info');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break;
            }

            if(boardInfoDetails[i][1] == undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'IP Address should not be empty in Board Info');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break;
            }

            if(boardInfoDetails[i][2] == undefined){
              isError = true;
              generateAlertMessage(this.dialog,'TCP Port should not be empty in Board Info');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break;
            }
            else
            {
              if(boardInfoDetails[i][2])
              {
                if(!Number.isInteger(parseInt(boardInfoDetails[i][2])))
                {
                  isError = true;
                  generateAlertMessage(this.dialog,'TCP Port should be integer in Board Info');
                  this.Uploadfilesave = false; 
                  this.clkimageInput.nativeElement.value = '';    
                  this.uploadedFileName = '';   
                  this.btnvalue = "Choose File";   
                  break; 
                }
              }
            }
            if(boardInfoDetails[i][3] == undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'Points should not be empty in Board Info');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break;
            }
            else
            {
              if(boardInfoDetails[i][3])
              {
                if(!Number.isInteger(parseInt(boardInfoDetails[i][3])))
                {
                  isError = true;
                  generateAlertMessage(this.dialog,'Points should be integer in Board Info');
                  this.Uploadfilesave = false; 
                  this.clkimageInput.nativeElement.value = '';    
                  this.uploadedFileName = '';   
                  this.btnvalue = "Choose File";   
                  break; 
                }
              }
            }
            if(boardInfoDetails[i][1] != undefined)
            {
              const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/; 
              if(!boardInfoDetails[i][1].toString().match(ipformat)) 
              {
                isError = true;
                generateAlertMessage(this.dialog,'IP Address is not valid');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break;
              }
            }

            if(i==1)
            {
              nameList.push(boardInfoDetails[i][0]);
              ipaddressList.push(boardInfoDetails[i][1]);
            }
            else
            {
              this.isInArray = nameList.includes(boardInfoDetails[i][0]);
              if(this.isInArray)
              {
                isError = true;
                generateAlertMessage(this.dialog, 'Board Name should be unique in Board Info');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = ''; 
                this.uploadedFileName = '';      
                this.btnvalue = "Choose File";   
                break; 
              }
              else
              {
                nameList.push(boardInfoDetails[i][0]);
              }                   
              const isdup = ipaddressList.includes(boardInfoDetails[i][1]);
              if(isdup)
              {
                isError = true;
                generateAlertMessage(this.dialog, 'IP Address should be unique in Board Info');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
              else
              {
                ipaddressList.push(boardInfoDetails[i][1]);
              }
            }
          }
        }
          if(isError == true) return;
       }
    
       if(boardSetupDetails != null && boardSetupDetails.length > 1)
       {
         if(boardSetupDetails[0].length != 9)
         {
            isError = true;
            generateAlertMessage(this.dialog,'Board setup sheet have invalid data');
            this.Uploadfilesave = false; 
            this.clkimageInput.nativeElement.value = '';    
            this.uploadedFileName = '';   
            this.btnvalue = "Choose File";   
            return; 
         }

         if(boardSetupDetails[0].length == 9)
         {
            const boardsetupHeaderList = ['Board Name','Room Id','Bed','Toilet','Shower','Cord Out','Spa','Space','Zones'];
            for(let idx=0;idx<boardSetupDetails[0].length;idx++)
            {
              if(boardSetupDetails[0][idx] != boardsetupHeaderList[idx])
              {
                isError = true;
                generateAlertMessage(this.dialog,'Board setup sheet have invalid header');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
            }
            if(isError == true) return;
          }

         for(let i=1;i<boardSetupDetails.length;i++) 
         {
            if(boardSetupDetails[i][0] === undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'Board Name should not be empty in Board setup');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break; 
            }
           
            if(boardSetupDetails[i][1] === undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'Room Id should not be empty in Board setup');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break; 
            }
            if(boardSetupDetails[i][2] === undefined || boardSetupDetails[i][3] === undefined || boardSetupDetails[i][4] === undefined || boardSetupDetails[i][5] === undefined || boardSetupDetails[i][6] === undefined)
            {
                isError = true;
                generateAlertMessage(this.dialog,'Alarm Types should not be empty in Board setup');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
            }
            if(boardSetupDetails[i][2] != undefined)
            {
              if(boardSetupDetails[i][2].toLowerCase() != 'yes' && boardSetupDetails[i][2].toLowerCase() != 'no')
              {
                isError = true;
                generateAlertMessage(this.dialog,'Alarm Types have Invalid data in Board setup');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
            }
            if(boardSetupDetails[i][3] != undefined)
            {           
              if(boardSetupDetails[i][3].toLowerCase() != 'yes' && boardSetupDetails[i][3].toLowerCase() != 'no')
              {
                isError = true;
                generateAlertMessage(this.dialog,'Alarm Types have Invalid data in Board setup');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
            }
            if(boardSetupDetails[i][4] != undefined)
            {
              if(boardSetupDetails[i][4].toLowerCase() != 'yes' && boardSetupDetails[i][4].toLowerCase() != 'no')
              {
                isError = true;
                generateAlertMessage(this.dialog,'Alarm Types have Invalid data in Board setup');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
            }
            if(boardSetupDetails[i][5] != undefined)
            {
              if(boardSetupDetails[i][5].toLowerCase() != 'yes' && boardSetupDetails[i][5].toLowerCase() != 'no')
              {
                isError = true;
                generateAlertMessage(this.dialog,'Alarm Types have Invalid data in Board setup');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
            }
            if(boardSetupDetails[i][6] != undefined)
            {
              if(boardSetupDetails[i][6].toLowerCase() != 'yes' && boardSetupDetails[i][6].toLowerCase() != 'no')
              {
                isError = true;
                generateAlertMessage(this.dialog,'Alarm Types have Invalid data in Board setup');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                break; 
              }
            }
            if(boardSetupDetails[i][7] === undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'Space should not be empty in Board setup');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break; 
            }        
            if(boardSetupDetails[i][8] === undefined)
            {
              isError = true;
              generateAlertMessage(this.dialog,'Zones should not be empty in Board setup');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break; 
            }
          }
          if(isError == true) return;
       }
       for(let idx=1; idx<boardSetupDetails.length; idx++)
       {
          if(boardInfoDetails[1][0] != boardSetupDetails[idx][0])
          {
              isError = true;
              generateAlertMessage(this.dialog,'Board setup should be contain only configured Broad Info');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              break; 
          }
       }
       for(let Inc=1; Inc<boardInfoDetails.length; Inc++)
       {
          const boardList = [] as any[];
          const roomList = [] as any[];
        
          for(let j=1; j<boardSetupDetails.length; j++)
          {
             if(boardInfoDetails[Inc][0] == boardSetupDetails[j][0])
             {
               boardList.push(boardSetupDetails[j][0]);
               roomList.push(boardSetupDetails[j][1]);
             }
          }
          if(boardList.length > 0)
          {
             if(boardList.length > boardInfoDetails[Inc][3])
             {
               isError = true;
               generateAlertMessage(this.dialog,'Room count should be less than or equal to Board points in Board setup');
               this.Uploadfilesave = false; 
               this.clkimageInput.nativeElement.value = '';    
               this.uploadedFileName = '';   
               this.btnvalue = "Choose File";   
               return; 
             }
          }
          if(roomList.length > 0)
          {
            if(!roomList.some(i => !Number.isInteger(i)))
            {
              const findDuplicates = roomList.filter((item, index) => roomList.indexOf(item) != index);
              if(findDuplicates.length > 0)
              {
                isError = true;
                generateAlertMessage(this.dialog,'Room Id should be unique for a board in Board setup');
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = '';    
                this.uploadedFileName = '';   
                this.btnvalue = "Choose File";   
                return; 
              }
            }
            else
            {
              isError = true;
              generateAlertMessage(this.dialog,'Room Id should be integer in Board setup');
              this.Uploadfilesave = false; 
              this.clkimageInput.nativeElement.value = '';    
              this.uploadedFileName = '';   
              this.btnvalue = "Choose File";   
              return; 
            }
          }             
       }
       if(isError == false)
       {
          this.saveUploadedConfigData();
       }     
     }
     else
     {
        generateAlertMessage(this.dialog,'Please upload the valid file');
        this.Uploadfilesave = false; 
        this.clkimageInput.nativeElement.value = '';    
        this.uploadedFileName = '';   
        this.btnvalue = "Choose File";   
        return; 
     }
    }
    catch(err)
    {
      throw new Error('upload-dialog.component.ts' + ' - onUploadClicked() - ' + err.message);
    }
  }
}

