import { Injectable, Inject } from '@angular/core';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';

@Injectable()
export class AssetMessageService {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }
  messages: string[] = [];

  add(message: string) {
    this.messages.push(message);
    throw new Error(message);
  }

  clear() {
    this.messages = [];
  }
}
