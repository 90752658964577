<perfect-scrollbar style="max-width: 1300px; max-height: 700px;" >
  <div class="clseditUnit">
     
              <div class="editUnit-title">
                <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editUnitHeader" style="margin: 0 0 10px 11px;">Space Information
                <span><img class="clseditunitcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
                <mat-divider ></mat-divider>
              </div>
        
              <form  style="padding:24px;"  [formGroup]="editUnitForm" autocomplete="off" novalidate (ngSubmit)="editUnitformdetails(editUnitForm.value)">     
          
                <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
                    
                  <div fxFlex="55" fxFlex.md="25"> 
                                          
                    <mat-grid-list [cols]="firstcolumns" [rowHeight]="floorrowHeight">                             
                        <mat-grid-tile>
                            <label>Campus</label>
                        </mat-grid-tile>                                              
                      <mat-grid-tile class="clseditunitmatgridtxt">
                        <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                            <input matInput placeholder="Campus Name" formControlName="campusname" maxlength="32" [(ngModel)]="data.CampusName" (keyup)="handleInput($event)"/>                                      
                            <input type="hidden" formControlName="campusID" [(ngModel)]="data.CampusId">   
                             <input type="hidden" formControlName="buildID" [(ngModel)]="data.BuildId">
                             <input type="hidden" formControlName="floorID" [(ngModel)]="data.FloorId">
                             <input type="hidden" formControlName="unitID" [(ngModel)]="data.UnitId">
                          </mat-form-field>
                      </div>                     
                      </mat-grid-tile>                                 
          
                      <mat-grid-tile>
                          <label>Structure </label>
                      </mat-grid-tile>
                      <mat-grid-tile class="clseditunitmatgridtxt">
                              <div class="text-inside" style="width:100%;">
                                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                                <input matInput placeholder="Building Name" formControlName="buildingname" maxlength="32" [(ngModel)]="data.BuildingName" (keyup)="handleInput($event)"/>                                 
                              </mat-form-field>
                            </div>
                      </mat-grid-tile>
                      
                      <mat-grid-tile>
                        <label>Zone </label>
                    </mat-grid-tile>
                    <mat-grid-tile class="clseditunitmatgridtxt">
                            <div class="text-inside" style="width:100%;">
                                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                              <input matInput placeholder="Zone Name" formControlName="zonename" maxlength="32" [(ngModel)]="data.FloorName" (keyup)="handleInput($event)"/>                                 
                            </mat-form-field>
                          </div>
                    </mat-grid-tile>                             
    
                          <mat-grid-tile>
                              <label>Space Name</label>
                          </mat-grid-tile>
                          <mat-grid-tile class="clseditunitmatgridtxt">
                                  <div class="text-inside" style="width:100%;">
                                      <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                                    <input matInput placeholder="Space Name" formControlName="unitname" maxlength="30" [(ngModel)]="data.UnitName" (keyup)="handleInput($event)" required/>
                                    <mat-error *ngIf="editUnitForm.controls.unitname.hasError('required')">Please enter Space Name</mat-error>
                                    <mat-error *ngIf="editUnitForm.controls.unitname.hasError('pattern')">Please enter valid Space Name</mat-error>
                                    <mat-error *ngIf="editUnitForm.controls.unitname.hasError('maxlength')">Max length 30 characters only</mat-error>
                                  </mat-form-field>
                                </div>
                          </mat-grid-tile>  
                          
                          <mat-grid-tile>
                            <label>Space Category</label>
                          </mat-grid-tile>
                          <mat-grid-tile class="clseditunitmatgridtxt">
                              <div class="text-inside" style="width:100%;">
                                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                                    <mat-select formControlName="roomcategory" placeholder="Select Space Category" [(ngModel)]="selectCategoryId">
                                      <mat-option *ngFor="let Space of Spacecategory_details" [value]="Space.SpaceCategoryId" (click)="selectSpaceCategory(Space.SpaceCategoryId)">
                                        {{Space.Description}}
                                      </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="editUnitForm.controls.roomcategory.hasError('required')">Please select Space Category</mat-error>                                    
                                  </mat-form-field>
                              </div>               
                          </mat-grid-tile> 
          
                        <mat-grid-tile>
                          <label>Description</label>
                      </mat-grid-tile>
                      <mat-grid-tile class="clseditunitmatgridtxt">
                              <div class="text-inside" style="width:100%;">
                                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                                <input matInput placeholder="Description" maxlength="30" formControlName="description" [(ngModel)]="data.Description" (keyup)="handleInput($event)"/>
                                <!-- <mat-error *ngIf="editUnitForm.controls.description.hasError('required')">Please enter Description</mat-error> -->
                                <mat-error *ngIf="editUnitForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                              </mat-form-field>
                            </div>
                      </mat-grid-tile>
                      
                      <mat-grid-tile>
                        <label>Resident Off<br>Limits/Schedule</label>
                    </mat-grid-tile>
                    <mat-grid-tile class="clseditunitmatgridtxt">
                            <div class="text-inside">
                              <mat-slide-toggle id="chkResOffLimits" (change)="toggler_ResOffLimits($event)" [checked]="editcheckResOffLimits"></mat-slide-toggle>
                          </div>
                          
                          <div style="margin-left:1.7em;width:180px;">                                  
                            <mat-form-field appearance="outline" floatLabel="auto" style="width:65%;">              
                              <mat-select #selWRStart placeholder="Start" formControlName="res_start" [(ngModel)]="data_RstartTime" [disabled]="!_roomenablehourmin">    
                                <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value" (click)="changeRoomStart(matOption)">
                                  {{data.Time}}
                              </mat-option>  
                              </mat-select>     
                              <mat-error>Please select Start Time</mat-error>             
                            </mat-form-field>                  
                            <label style="margin-left:1.5em">to</label>                
                          </div>
                          
                          <div>                  
                            <mat-form-field appearance="outline" floatLabel="auto" style="width:55%;">              
                              <mat-select #selWREnd placeholder="End" formControlName="res_end" [(ngModel)]="data_RendTime" [disabled]="!_roomenablehourmin">    
                                <mat-option #matOption *ngFor="let data of Curfew_data" [value]="data.Value" (click)="changeRoomEnd(matOption)">
                                  {{data.Time}}
                              </mat-option>  
                              </mat-select>   
                              <mat-error>Please select End Time</mat-error>
                            </mat-form-field>  
                            
                            <mat-checkbox class="clslivelocation clsenable" id="chkresidentday" (change)="toggleresdayinfoVisibility($event)" [disabled]="!_roomenableroomallday" [checked]="checkresroominfo" 
                              style="font-size: 14px;margin-left: 1em;"></mat-checkbox> 
                              <span style="margin:1em 0.5em 0px 0.5em;" class="Hide-Personal-Info">All Day</span>
                          </div>    
                          
                    </mat-grid-tile> 
      
                      </mat-grid-list>           
                  
                  </div>                  
                  
                  <div fxFlex="45" fxFlex.md="45" style="margin-top:1.5em;" class="clsdivactivespace">
                    
                    <mat-grid-list [cols]="columns" class="clsunitimgmatgridlist">
                      <div class="clsunitimagedivlist">
                        <div id="map1" class="clseditunitDragplanimg map" style="margin:0px; width:100%; height: 100%;" (click)='imgFloorMapView()' draggable="false">
                        </div>
                      </div>    

                    <button type="button" class="clsfloordltbutton" color="primary" style="width:8rem;margin:5px 0em 0em 4em;" mat-raised-button (click)="imgFloorMapView()">Edit Space</button> 
                    </mat-grid-list>
                   
                    <div style="margin-top:3em;">
                    <label>Devices in the Space:</label>
                      <mat-grid-list [cols]="columns" class="clsunitgridlist">
                          
                            <perfect-scrollbar style="max-width: 1200px; max-height: 230px;" >                        
                              <div style="margin:1em;">
                                <label style="font-size: medium;">Devices</label>
                              </div>  
                              
                              <div *ngFor="let Device of DeviceInfo;">                              
                              <div class="text-inside" style="margin:1em;" *ngIf="Device.FilterDeviceType != 'Room' && Device.FilterDeviceType != _NewDeviceTypeDesc.Approx">   
                                <div style="width: 100%;" class="clsdivDeviceinfo">                                                                       
                                    <img src="assets/images/Edit_Icon.png" class="clsuniteditimg" (click)="edit_Devicedetails(Device)" [ngStyle]="{'opacity': Device.FilterDeviceType == 'Zone' ? '0.5' : 'none','display': bAddEdit == false ? 'none' : 'block'}"/>
                                    <img src="assets/images/deletebuilding.png" style="margin:0em 1em;" class="clsuniteditimg" (click)="deleteSpaceDevicedetails(Device)" [ngStyle]="{'opacity': Device.FilterDeviceType == 'Zone' ? '0.5' : 'none','display': bAddEdit == false ? 'none' : 'block'}"/>                            
                                    <div class="clseditunitdivDevice" [ngStyle]="{'margin-left': bAddEdit == false ? '2em;' : '0em;'}">   
                                      <img *ngIf="Device.DeviceId > 0 && Device.NewDeviceName != _NewDeviceTypeDesc.Gateway" src="assets/images/Device_Green.png" class="clsUnitEditimg" (click)="editDevicedetails()"/>
                                      <img *ngIf="(Device.DeviceId == 0 || Device.DeviceId == null) && Device.NewDeviceName != _NewDeviceTypeDesc.Gateway" src="assets/images/Device_Yellow.png" class="clsUnitEditimg" (click)="editDevicedetails()"/>                             
                                      <img *ngIf="Device.DeviceId == 0 && Device.NewDeviceName == _NewDeviceTypeDesc.Gateway" src="assets/images/Device_Green.png" class="clsUnitEditimg" (click)="editDevicedetails()"/>
                                      <div *ngIf="Device.RoomName.length < 15" [ngStyle]="{'margin-left': bAddEdit == false ? '1em;' : '0em;'}">{{Device.RoomName}}</div>  
                                      <div *ngIf="Device.RoomName.length >= 15" [ngStyle]="{'margin-left': bAddEdit == false ? '1em;' : '0em;'}" matTooltipClass="devicetooltip" matTooltip={{Device.RoomName}} #tooltip="matTooltip" matTooltipPosition="above"> 
                                        {{Device.RoomName.slice(0,8) + ".."}}</div>                                      
                                    </div>  
                                    <div class="clseditunitdivDevice">               
                                      <div [ngStyle]="{'margin-left': bAddEdit == false ? '3.5em;' : '1.5em;'}">Device: <b>{{Device.NewDeviceName}}</b></div>  
                                    </div>                                                
                                </div>
                                <div style="width: 100%;display: inline-flex;">  
                                  <div [ngClass]="bAddEdit == true ? 'clseditunitdivDeviceid' : 'clseditunitdivDeviceidhide'" style="margin-top:0.5em;">          
                                                      
                                    <!-- <div [ngStyle]="{'margin-left': bAddEdit == false ? '3.5em;' : '1em;'}">Device ID: <b>{{Device.DeviceTypeId}}</b></div> -->
                                    
                                    <div [ngStyle]="{'margin-left': bAddEdit == false ? '3.5em;' : '1em;'}" *ngIf="Device.NewDeviceName != _NewDeviceTypeDesc.Rex && Device.NewDeviceName != _NewDeviceTypeDesc.Pullcord && Device.NewDeviceName != _NewDeviceTypeDesc.UT && Device.NewDeviceName != _NewDeviceTypeDesc.Gateway && Device.NewDeviceName != _NewDeviceTypeDesc.HRex && Device.NewDeviceName != _NewDeviceTypeDesc.Intercom">Device ID: <b>{{Device.DeviceTypeId}}</b></div>  
                                    <div [ngStyle]="{'margin-left': bAddEdit == false ? '3.5em;' : '1em;'}" *ngIf="Device.NewDeviceName == _NewDeviceTypeDesc.Rex || Device.NewDeviceName == _NewDeviceTypeDesc.Pullcord || Device.NewDeviceName == _NewDeviceTypeDesc.UT || Device.NewDeviceName == _NewDeviceTypeDesc.Gateway || Device.NewDeviceName == _NewDeviceTypeDesc.HRex || Device.NewDeviceName == _NewDeviceTypeDesc.Intercom">Device ID: <b>{{Device.DeviceId}}</b></div>  
                                    
                                  </div>  
                                  <div class="clseditunitdivDevice" style="margin-left:0.5em;margin-top:0.5em;">               
                                    <!-- <div [ngStyle]="{'margin-left': bAddEdit == false ? '3.7em;' : '1em;'}" *ngIf="Device.NewDeviceName != _NewDeviceTypeDesc.HRex">IR ID: <b>{{Device.DeviceId}}</b></div>  -->
                                    <div [ngStyle]="{'margin-left': bAddEdit == false ? '3.7em;' : '1em;'}" *ngIf="Device.NewDeviceName == _NewDeviceTypeDesc.HRex">IR ID: <b>{{Device.MacId}}</b></div>                                     
                                    <div [ngStyle]="{'margin-left': bAddEdit == false ? '3.7em;' : '1em;'}" *ngIf="Device.NewDeviceName != _NewDeviceTypeDesc.Rex && Device.NewDeviceName != _NewDeviceTypeDesc.Pullcord && Device.NewDeviceName != _NewDeviceTypeDesc.UT && Device.NewDeviceName != _NewDeviceTypeDesc.Gateway && Device.NewDeviceName != _NewDeviceTypeDesc.HRex && Device.NewDeviceName != _NewDeviceTypeDesc.Intercom">IR ID: <b>{{Device.DeviceId}}</b></div>  
                                    <div [ngStyle]="{'margin-left': bAddEdit == false ? '3.7em;' : '1em;'}" *ngIf="(Device.NewDeviceName == _NewDeviceTypeDesc.Rex || Device.NewDeviceName == _NewDeviceTypeDesc.Pullcord || Device.NewDeviceName == _NewDeviceTypeDesc.UT || Device.NewDeviceName == _NewDeviceTypeDesc.Gateway || Device.NewDeviceName == _NewDeviceTypeDesc.Intercom) && Device.NewDeviceName != _NewDeviceTypeDesc.HRex">IR ID: <b>{{Device.DeviceTypeId}}</b></div>
                                  </div>                    
                                </div>
                              </div>                                
                            </div>
                          
                        </perfect-scrollbar>
                        </mat-grid-list>
                      </div>
                  </div>
                  
                </div>
                <mat-dialog-actions style="float:left;">              
                  <button type="button" class="clseditunitdltbutton" mat-raised-button (click)="openDeletedialog()" *ngIf="bDelete==true">Delete</button> 
                  </mat-dialog-actions>
                  
                <mat-dialog-actions class="clseditunitbtncontrol">                 
                  <button type="button" class="clseditunitdltbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
                  <button id="btnsaveuniteditclick" class="clseditunitbutton" mat-raised-button [disabled]="!editUnitForm.valid" *ngIf="bAddEdit==true">Save</button>
                </mat-dialog-actions>
                  
              </form>
              
                                          
    </div>
</perfect-scrollbar>
