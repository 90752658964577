import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Observable, BehaviorSubject } from 'rxjs';
import { AlertSharedService } from './_services/index';
import { SharedData, SharedAlertBasedData, SharedBuildBasedData, SharedFloorBasedData, SharedAlertData, SharedFloorAlertData, SharedAlertLoadData, 
  SharedCheckAlertData, SharedCheckAlertReportData, SharedCheckIsAlertReportData, ShareInfraopenMsg, ShareRightSideNav, ShareUserdetails, ShareddialogcloseData, staffresidentdata } from './api';
import { UserIdleService } from 'angular-user-idle';
import { Router,NavigationStart  } from '@angular/router';
import { appsettings } from './app-settings';
import * as socketIo from 'socket.io-client';
import {MatDialog} from '@angular/material/dialog';
import { enumStaffRoll } from '../app/app-settings';
import { filter } from "rxjs/operators";
import { Event as NavigationEvent } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ltc-app';
  idleState = 'Not started.';
  public static sessiontimeout = 60*60;
  public static IsidleState = 1;
  public static messageshowtime=30;
  public static alertIPADDRESS = appsettings.SOCKET_IP; 
  public static changePort = (appsettings.httpMethod.toLowerCase() == 'http:' ? appsettings.Port3001:'');
  public static socketUrl=appsettings.httpMethod +'//' + AppComponent.alertIPADDRESS + AppComponent.changePort;
  public static socket = socketIo(AppComponent.socketUrl);
  public static mapsocket = socketIo(AppComponent.socketUrl);
  
  inactivityTime = function (userIdle) {
    window.onload = refreshTimer;    
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    
    function resetTimer() {
      const IsidleState = AppComponent.IsidleState;
      if (IsidleState == 0) {               
        userIdle.resetTimer();
      }
    }
    function refreshTimer() {
      AppComponent.IsidleState=0;          
      userIdle.startWatching();
      userIdle.resetTimer();
    }
  };

  public static StaffUserRoleView:[]=[];
  public static historyurl:Array<{id: number, url: string}> = []; 
  public static IsContinuous=0;
  public static AudioAlertTime=5;

  public static SharedAlertDetails:SharedAlertData[];
  public static SharedInfraDetails:SharedAlertData[];
  public static SharedAlertResidentDetails:SharedAlertData[];
  public static SharedAlertBasedDetails:SharedAlertBasedData[];
  public static SharedBuildBasedDetails:SharedBuildBasedData[];
  public static SharedFloorBasedDetails:SharedFloorBasedData[];
  public static ShareInfraopenDetails:ShareInfraopenMsg;
  public static ShareHTTPRequestDetails={"IsError":0};
  public static SharedHTTPRequestSource = new BehaviorSubject(AppComponent.ShareHTTPRequestDetails);

  public SharedAlertDetailsSubscription :Subscription;
  public SharedInfraDetailsSubscription :Subscription;
  public SharedAlertResidentDetailsSubscription :Subscription;
  public SharedAlertBasedDetailsSubscription :Subscription;
  public SharedBuildBasedDetailsSubscription :Subscription;
  public SharedFloorBasedDetailsSubscription :Subscription;    
  public ShareInfraopenDetailsSubscription:Subscription;
  public ShareHTTPRequestDetailsSubscription:Subscription;

  public static SharedDetails: SharedData;
  public static SharedResidentStaffBasedDetails: staffresidentdata[];
  public static SharedEventDetails: any[];
  public static SharedFloorAlertDetails: SharedFloorAlertData;
  public static SharedAlertLoadDetails: SharedAlertLoadData;
  public static SharedCheckAlertDetails: SharedCheckAlertData;
  public static SharedCheckAlertReportDetails: SharedCheckAlertReportData;
  public static SharedCheckIsAlertReportDetails: SharedCheckIsAlertReportData;
  public static SharedCheckIsLocationReportDetails: SharedCheckIsAlertReportData;
  public static ShareMapDetails: any;
  public static ShareMapVectorLayer: any;
  public static ShareMapVectorFloorLayer: any;
  public static ShareSideNavDetails: ShareRightSideNav;
  public static ShareUserdetails: ShareUserdetails;
  public static ShareddialogcloseDetails: ShareddialogcloseData;
  public static SharedResidentStaffDetails: any;
  public static SharedRoomBasedDetails: any;
  public static SharedLocationDetails: any;
  public static SharedStaffDetails: any;
  public static SharedInternetConnection: any;
  public static SharedHomeMapDetails:any;
  

  public SharedDetailsSubscription:Subscription;
  public SharedResidentStaffBasedDetailsSubscription:Subscription;
  public SharedRoomBasedDetailsSubscription:Subscription;
  public SharedEventDetailsSubscription:Subscription;
  public SharedFloorAlertDetailsSubscription:Subscription;
  public SharedAlertLoadDetailsSubscription:Subscription;
  public SharedCheckAlertDetailsSubscription:Subscription;
  public SharedCheckAlertReportDetailsSubscription:Subscription;
  public SharedCheckIsAlertReportDetailsSubscription:Subscription;
  public SharedCheckIsLocationReportDetailsSubscription:Subscription;
  public ShareMapDetailsSubscription:Subscription;
  public ShareMapVectorLayerSubscription:Subscription;
  public ShareMapVectorFloorLayerSubscription:Subscription;
  public ShareSideNavDetailsSubscription:Subscription;
  public ShareUserdetailsSubscription:Subscription;
  public ShareddialogcloseDetailsSubscription:Subscription;
  public SharedResidentStaffDetailsSubscription:Subscription;
  public SharedLocationDetailsSubscription:Subscription;
  public SharedStaffDetailsSubscription:Subscription;
  public SharedInternetConnectionSubscription:Subscription;  
  public SharedHomeMapDetailsSubscription:Subscription;

  constructor(private alertSharedService: AlertSharedService,private userIdle: UserIdleService,
    private router: Router,private dialogRef: MatDialog) {    
    this.SharedAlertDetailsSubscription = this.alertSharedService.currentSharedAlert().subscribe(SharedAlertDetails => {      
      AppComponent.SharedAlertDetails = SharedAlertDetails;
    });
    this.SharedInfraDetailsSubscription = this.alertSharedService.currentSharedInfraBased().subscribe(SharedInfraDetails => {      
      AppComponent.SharedInfraDetails = SharedInfraDetails;
    });
    this.SharedAlertResidentDetailsSubscription = this.alertSharedService.currentSharedAlertResidentBased().subscribe(SharedAlertResidentDetails => {      
      AppComponent.SharedAlertResidentDetails = SharedAlertResidentDetails;
    });
    this.SharedAlertBasedDetailsSubscription = this.alertSharedService.currentSharedAlertBased().subscribe(SharedAlertBasedDetails => {
       AppComponent.SharedAlertBasedDetails = SharedAlertBasedDetails;
    });
    this.SharedBuildBasedDetailsSubscription = this.alertSharedService.currentSharedBuildBased().subscribe(SharedBuildBasedDetails => {
       AppComponent.SharedBuildBasedDetails = SharedBuildBasedDetails;
    });
    this.SharedFloorBasedDetailsSubscription = this.alertSharedService.currentSharedFloorBased().subscribe(SharedFloorBasedDetails => {
       AppComponent.SharedFloorBasedDetails = SharedFloorBasedDetails;
    });
    this.ShareInfraopenDetailsSubscription = this.alertSharedService.currentsharedInfraAlert().subscribe(ShareInfraopenDetails => {
       AppComponent.ShareInfraopenDetails = ShareInfraopenDetails;
    });
    this.ShareHTTPRequestDetailsSubscription = currentsharedHTTPRequest().subscribe(ShareHTTPRequestDetails => {
      AppComponent.ShareHTTPRequestDetails = ShareHTTPRequestDetails;
    });

    this.SharedDetailsSubscription = this.alertSharedService.currentShared().subscribe(SharedDetails => {
      AppComponent.SharedDetails = SharedDetails;
   });

   this.SharedResidentStaffBasedDetailsSubscription = this.alertSharedService.currentSharedResidentStaffBased().subscribe(SharedResidentStaffBasedDetails => {
       AppComponent.SharedResidentStaffBasedDetails = SharedResidentStaffBasedDetails;
    });

    this.SharedRoomBasedDetailsSubscription = this.alertSharedService.currentSharedRoomDetailsBased().subscribe(SharedRoomBasedDetails => {
      AppComponent.SharedRoomBasedDetails = SharedRoomBasedDetails;
   });

    this.SharedEventDetailsSubscription = this.alertSharedService.currentSharedEvent().subscribe(SharedEventDetails => {
       AppComponent.SharedEventDetails = SharedEventDetails;
    });
    
    this.SharedFloorAlertDetailsSubscription = this.alertSharedService.currentsharedFloorAlert().subscribe(SharedFloorAlertDetails => {
      AppComponent.SharedFloorAlertDetails = SharedFloorAlertDetails;
   });
   
   this.SharedAlertLoadDetailsSubscription = this.alertSharedService.currentsharedAlertload().subscribe(SharedAlertLoadDetails => {
      AppComponent.SharedAlertLoadDetails = SharedAlertLoadDetails;
   });
   
   this.SharedCheckAlertDetailsSubscription = this.alertSharedService.currentSharedCheckAlert().subscribe(SharedCheckAlertDetails => {
      AppComponent.SharedCheckAlertDetails = SharedCheckAlertDetails;
   });

   this.SharedCheckAlertReportDetailsSubscription = this.alertSharedService.currentSharedCheckAlertReport().subscribe(SharedCheckAlertReportDetails => {
    AppComponent.SharedCheckAlertReportDetails = SharedCheckAlertReportDetails;
   });

   this.SharedCheckIsAlertReportDetailsSubscription = this.alertSharedService.currentSharedCheckIsAlertReport().subscribe(SharedCheckIsAlertReportDetails => {
    AppComponent.SharedCheckIsAlertReportDetails = SharedCheckIsAlertReportDetails;
   });

   this.SharedCheckIsLocationReportDetailsSubscription = this.alertSharedService.currentSharedCheckIsLocationReport().subscribe(SharedCheckIsLocationReportDetails => {
    AppComponent.SharedCheckIsLocationReportDetails = SharedCheckIsLocationReportDetails;
   });
   
   this.ShareMapDetailsSubscription = this.alertSharedService.currentsharedMapDetails().subscribe(ShareMapDetails => {
      AppComponent.ShareMapDetails = ShareMapDetails;
   });
   
   this.ShareMapVectorLayerSubscription = this.alertSharedService.currentsharedMapVectorLayer().subscribe(ShareMapVectorLayer => {
      AppComponent.ShareMapVectorLayer = ShareMapVectorLayer;
   });
   
   this.ShareMapVectorFloorLayerSubscription = this.alertSharedService.currentsharedMapVectorFloorLayer().subscribe(ShareMapVectorFloorLayer => {
      AppComponent.ShareMapVectorFloorLayer = ShareMapVectorFloorLayer;
   });
   
   this.ShareSideNavDetailsSubscription = this.alertSharedService.currentsharedsideNav().subscribe(ShareSideNavDetails => {
      AppComponent.ShareSideNavDetails = ShareSideNavDetails;
   });
   
   this.ShareUserdetailsSubscription = this.alertSharedService.currentsharedUserNav().subscribe(ShareUserdetails => {
      AppComponent.ShareUserdetails = ShareUserdetails;
   });
   
   this.ShareddialogcloseDetailsSubscription = this.alertSharedService.currentshareddialogclose().subscribe(ShareddialogcloseDetails => {
      AppComponent.ShareddialogcloseDetails = ShareddialogcloseDetails;
   });
   
   this.SharedResidentStaffDetailsSubscription = this.alertSharedService.currentsharedResidentStaff().subscribe(SharedResidentStaffDetails => {
      AppComponent.SharedResidentStaffDetails = SharedResidentStaffDetails;
   });
   
   this.SharedLocationDetailsSubscription = this.alertSharedService.currentSharedLocation().subscribe(SharedLocationDetails => {
      AppComponent.SharedLocationDetails = SharedLocationDetails;
   });
   
   this.SharedStaffDetailsSubscription = this.alertSharedService.currentsharedStaffData().subscribe(SharedStaffLoadDetails => {
      AppComponent.SharedStaffDetails = SharedStaffLoadDetails;
   });

   this.SharedInternetConnectionSubscription = this.alertSharedService.currentsharedInternetConnection().subscribe(SharedInternetConnection => {
    AppComponent.SharedInternetConnection = SharedInternetConnection;
   });
   
   this.SharedHomeMapDetailsSubscription = this.alertSharedService.currentSharedHomeMapdetails().subscribe(SharedHomeMapDetails => {
    AppComponent.SharedHomeMapDetails = SharedHomeMapDetails;
 });
  
    router.events.pipe(filter(( event: NavigationEvent ) => 
    {
						return( event instanceof NavigationStart );
    })).subscribe((event: NavigationStart) =>
     {
					if ( event.restoredState )
          {
						console.warn("restoring navigation id:",event.restoredState.navigationId);
          }
          console.groupEnd();
    });
}


ngOnInit() {    
  
    this.inactivityTime(this.userIdle);
    const idleSessionTimeout = localStorage.getItem("IdleSessionTimeout") ?? "";
    const divchildModal = document.getElementById('divchildModal');
      if (localStorage.getItem("IdleSessionTimeout") !== null) {
        AppComponent.sessiontimeout =parseInt(idleSessionTimeout);        
        AppComponent.IsidleState = 0;
        this.userIdle.stopWatching();        
        this.userIdle.setConfigValues({ idle: (AppComponent.sessiontimeout-AppComponent.messageshowtime) , timeout: AppComponent.messageshowtime, ping: 0 });
      }
      this.userIdle.onTimerStart().subscribe((count) => {
          if (this.router.url != "/login" && localStorage.getItem("role") != enumStaffRoll.Care_Giver.toString()) {            
            const _idleConfigValue = this.userIdle.getConfigValue();
            // const idle = _idleConfigValue.idle;
            const timeout = _idleConfigValue.timeout;            
            let _count = _idleConfigValue.timeout;
            if (count != null && timeout != undefined) {
              _count = timeout - count
              console.log("_count " + _count)
            }            
            if (count == 1) {
              AppComponent.IsidleState = 1;
              this.idleState = 'You\'ve gone idle!';
              if(divchildModal != null)
                divchildModal.style.display = "block";
            }
            else if (count > 1) {
              AppComponent.IsidleState = 1;              
              this.idleState = 'You will be timed out in ' + _count + ' seconds!';
            }
            else if (count == timeout) {
              AppComponent.IsidleState = 1;              
              this.idleState = 'You will be timed dout in ' + _count + ' seconds!';
            }
          }
          else {
            AppComponent.IsidleState = 0;
            this.userIdle.stopWatching();
            this.userIdle.startWatching();
          }
      });
      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {        
        AppComponent.IsidleState = 0;
        this.idleState = 'Timed out!';
        if(divchildModal != null)
          divchildModal.style.display = "none";
        this.logout();
      });
    

}


  hideChildModal(): void {
    this.stay();
  }

  stay() {
    AppComponent.IsidleState = 0;
    this.idleState = 'No longer idle.';
    const divchildModal = document.getElementById('divchildModal');
    if(divchildModal != null)
      divchildModal.style.display = "none";
    this.userIdle.resetTimer();
  }
  
  stop() {    
    this.userIdle.stopTimer();
  }

  stopWatching() {    
    this.userIdle.stopWatching();
  }

  startWatching() {    
   this.userIdle.startWatching();
  }

  restart() {    
   this.userIdle.resetTimer();
  }

    
  logout(): void {
    AppComponent.IsidleState = 0;
    this.dialogRef.closeAll();
    const divchildModal = document.getElementById('divchildModal');
    if(divchildModal != null)
      divchildModal.style.display = "none";
    const _ShareddialogcloseDetails = {
      "SharedreturncloseVal": 0
    };
    this.alertSharedService.changeclosedialog(_ShareddialogcloseDetails);
    
    localStorage.clear();
    closealertpushSocketConForUpdates();
    CloseLocationChangeSocket();
    this.router.navigate(['/login']);
  }


ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.SharedAlertDetailsSubscription.unsubscribe();
    this.SharedInfraDetailsSubscription.unsubscribe();
    this.SharedAlertResidentDetailsSubscription.unsubscribe();
    this.SharedAlertBasedDetailsSubscription.unsubscribe();
    this.SharedBuildBasedDetailsSubscription.unsubscribe();
    this.SharedFloorBasedDetailsSubscription.unsubscribe();
    this.ShareInfraopenDetailsSubscription.unsubscribe();
    this.ShareHTTPRequestDetailsSubscription.unsubscribe();
    
    this.SharedDetailsSubscription.unsubscribe();
    this.SharedResidentStaffBasedDetailsSubscription.unsubscribe();
    this.SharedEventDetailsSubscription.unsubscribe();
    this.SharedFloorAlertDetailsSubscription.unsubscribe();
    this.SharedAlertLoadDetailsSubscription.unsubscribe();
    this.SharedCheckAlertDetailsSubscription.unsubscribe();
    this.SharedCheckAlertReportDetailsSubscription.unsubscribe();
    this.SharedCheckIsAlertReportDetailsSubscription.unsubscribe();
    this.SharedCheckIsLocationReportDetailsSubscription.unsubscribe();
    this.ShareMapDetailsSubscription.unsubscribe();
    this.ShareMapVectorLayerSubscription.unsubscribe();
    this.ShareMapVectorFloorLayerSubscription.unsubscribe();
    this.ShareSideNavDetailsSubscription.unsubscribe();
    this.ShareUserdetailsSubscription.unsubscribe();
    this.ShareddialogcloseDetailsSubscription.unsubscribe();
    this.SharedResidentStaffDetailsSubscription.unsubscribe();
    this.SharedLocationDetailsSubscription.unsubscribe();

    this.SharedStaffDetailsSubscription.unsubscribe();
    this.SharedInternetConnectionSubscription.unsubscribe();
    this.SharedHomeMapDetailsSubscription.unsubscribe();
}
}
function changedHTTPRequest(Chk_ShareHTTPRequestDetail: { IsError: number; }) {
  AppComponent.SharedHTTPRequestSource.next(Chk_ShareHTTPRequestDetail);
}

function currentsharedHTTPRequest(): Observable<any> {
  return AppComponent.SharedHTTPRequestSource.asObservable();
}

 /** disconnect the socket */
 function closealertpushSocketConForUpdates() {
  try {
    if (AppComponent.socket) {
      AppComponent.socket.disconnect();
    }
  }
  catch (err) {
    throw new Error("Throw"+'app.component.ts' + ' - closealertpushSocketConForUpdates() - ' + err.message);
  }
}

function CloseLocationChangeSocket() {
  try {
    if (AppComponent.mapsocket) {
      AppComponent.mapsocket.disconnect();
    }
  }
  catch (err) {
    throw new Error('app.component.ts' + ' - CloseLocationChangeSocket() - ' + err.message);
  }
}

export { changedHTTPRequest, currentsharedHTTPRequest, closealertpushSocketConForUpdates, CloseLocationChangeSocket };
