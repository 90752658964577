<div id="LocationHistorydownload">
  <div class="add-title">
    <h3 class="LocationHistoryHeader" style="margin: 0 0 10px 11px;">Location History Report
      <span><img class="clscloseLocationimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span>
    </h3>
    <mat-divider></mat-divider>
  </div>
  <div>

    <button style="margin-top:28px;margin-left: 75px;margin-right:28px; " class="btnInfraExcel" color="primary"
      mat-raised-button (click)="exportLocationReportExcel()">Download CSV</button>
    <button id="pdf1" class="btnInfraPdf" color="primary" mat-raised-button (click)="exportLocationReportPdf()">Download
      pdf</button>

    <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%;">
      <div class="loadLocationreportspinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

  </div>
</div>