<mat-toolbar style="padding:0 0 0 16px">
  <!-- Design for Title icon-->
  <div fxShow.sm="true" fxShow.xs="true" fxShow="true" class="alert-main-div">
    <img style="width:25px;height:25px;margin-right:6px;" mat-line
      src="assets/images/Report-Icon-Blue@2x.png">
    <span fxShow.md="false" fxShow.sm="false" fxShow.xs="false" mat-line class="clsTechtitle">Reports</span>
  </div>
  <app-header [ngClass]="UserName.length <= 15 ? 'clsusernameappheader' : 'clsappheader'"></app-header>
</mat-toolbar>


