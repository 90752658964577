import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { MatInput } from "@angular/material/input";
import { Subject } from "rxjs";
import {
  appsettings,
  enumFileNamelength,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumResEgress_Type,
  enumSpaceCategory
} from "../../../app-settings";
import {
  ResidentForCreation,
  ResidentForCreationResponse,
  ResidentByID,
  AddEditRoaming,
  getCampusRestrictedSpace
} from "../resident";
import { ResGroup, getGroupData, getZone } from "../../../api";
import { ResidentService } from "../resident.service";
import {
  getCampus,
  getCampusdetails,
  getBuilding,
  getBuildingdetails,  
  getFloordetails,
  getUnit,
  getUnitdetails,
  TagIdfoundResponse,
  Facility,
  checkTagId,
  getFacility,
} from "../../../api";
import { ApiService } from "../../../api.service";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
  getFeatureNeedtoShow,
} from "../../../app-general";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { HostListener } from "@angular/core";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { enumTagType } from "../../../app-settings";
const URL = appsettings.URLinfo + "uploadImage_Resident";
import {TreeviewItem,DownlineTreeviewItem, TreeviewEventParser,TreeviewConfig,OrderDownlineTreeviewEventParser,TreeviewI18n,TreeviewI18nDefault, TreeviewHelper} from 'ngx-treeview';
import { isNil, reverse } from 'lodash';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
  selector: "app-add-resident",
  templateUrl: "./add-resident.component.html",
  providers: [ ResidentService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: ResidentService },
    { provide: TreeviewI18n , useValue: Object.assign(new TreeviewI18nDefault(), 
      {
        getFilterPlaceholder(): string {
          return 'Search';
      }
    
     }) }
   ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-resident.component.scss"],
})
export class AddResidentComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 8;
  public residentCreationForm: UntypedFormGroup;
  public restrictedSpaceForm: UntypedFormGroup;
  uploadimagename = "";
  Campusdetails: getCampusdetails[];
  Buildingdetails: getBuildingdetails[];
  Floordetails: getFloordetails[];
  Unitdetails: getUnitdetails[];
  Facility: Facility[];
  residentimageUrl;
  clkchecksave = 0;
  addmeddisabled;
  FacilityName;
  Groupdetails: ResGroup[];
  Placeholder = "Group(s)";
  gender = 0;
  startDate;
  CurrentDate;
  residentbool = true;
  selebuilding = true;
  selefloor = true;
  seleunit = true;
  addbuildingId = true;
  addfloorId = true;
  addunitId = true;
  oldcampusid = 0;
  oldbuildid = 0;
  oldfloorid = 0;
  private _onDestroy: Subject<void> = new Subject<void>();
  screenWidth;
  resrowHeight = "67px";
  getResidentLength;
  countrycode;
  addCheck_Invalue = false;
  showResident = false;
  showRoaming = false;
  selectedIndex = 0;

  getRoamingData;

  checkWrongRoom = false;
  checked_WrongRoom = false;
  checkAllEgress = false;
  checked_AllEgress = false;

  _roomenablehourmin = false;
  _egressenablehourmin = false;

  _roomenableroomallday = false;
  _roomenableEgressallday = false;

  checkresroominfo = false;
  checkresallinfo = false;
  data_wrongroom;
  data_wrstartTime;
  data_wrendTime;

  data_allegress;
  data_aestartTime;
  data_aeendTime;
  add_residentId;
  bWanderView = "0";
  bWanderAddEdit = "0";
  BothDisplay;
  addresident_name = "";
  addRestricted_Space = false;
  addRes_Info = true;
  public Curfew_data = [] as any[];
  addResSpacebool = true;
  authkey;
  matTooltipWrong;
  matTooltipAllEgress;
  addVoicevalue = false; 
  voiceLicense;
  selectedCampusId = 0;
  addResWandervalue = false;
  restrictionsHeader = "";
  items: TreeviewItem[];
  arrallCampusid = [] as any[];
  sel_CampusId=[] as any[];
  sel_BuildingId=[] as any[];
  sel_FloorId=[] as any[];
  sel_RoomId=[] as any[];
  wrongSel_RoomId=[] as any[];
  egressSel_RoomId=[] as any[];
  residentRoomSelect = "";
  egressRoomSelect = "";  
  matTooltipWrongRoomExpireTime;
  matTooltipAllEgressExpireTime; 
  residentRoomCount = 0;
  egressRoomCount = 0;
  
  matTooltip="WARNING: Checking this box will disable the duress button if the Geo worn by this resident is in Wander mode."; 
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[3]);
  
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 160,
  });
  
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddResidentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private residentService: ResidentService,
    private apiService: ApiService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.getResidentLength = data + 1;

    this.getCurfew_data();
    this.breakpoint$.subscribe(() => this.breakpointChanged());      

    // if (localStorage.getItem("wander_config") == "1") {
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Roaming,
          enumUIAction.Roaming_View
        ) == enumUIView.Show
      ) {
        this.bWanderView = "1";
        this.BothDisplay = 2;
      }
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Roaming,
          enumUIAction.Roaming_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bWanderAddEdit = "1";
      }
    // }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Resident,
        enumUIAction.Resident_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.showResident = true;
      this.BothDisplay = 1;
    } else {
      this.showResident = false;
      if (this.bWanderView == "1") {
        this.showRoaming = true;
      }
    }

    if (this.bWanderView == "1" && this.showResident == true) {
      this.BothDisplay = 3;
    }     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 8;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 3;
    }
  }
  
  @ViewChild("clkimageInput") clkimageInput: any;

  @ViewChild("pickerToInput", {
    read: MatInput,
  })
  pickerdobInput: MatInput;

  public onChange(fileList: FileList) {
    const file = fileList[0];
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(
      removedSpaces_filename
    );
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      this.residentimageUrl = "assets/images/admin-avatar.png";
      this.clkimageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File Name length is allowed maximum 128 character"
      );
      this.residentimageUrl = "assets/images/admin-avatar.png";
      this.clkimageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Resident Photo as image file."
      );
      this.residentimageUrl = "assets/images/admin-avatar.png";
      this.clkimageInput.nativeElement.value = "";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0]);
      else this.residentimageUrl = "assets/images/admin-avatar.png";

      this.clkimageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject + " reject");
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x + " x");
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 3) {
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 3 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Resident Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  uploadfiletoPath(data, file) {
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const FileName = this.getResidentLength + "+" + _filename;
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        this.uploadimagename = res.filename;
        this.residentimageUrl = appsettings.RESIDENT_FOLDER + res.filename;
      });
  }

  getCurfew_data() {
    let _curfewdata = {};
    const _curfew_data_arr = [] as any[];
    let hourtext = "";
    let _hour = "";
    let _min = "";
    let min_inc = 30;
    for (let hour = 0; hour <= 24; hour++) {
      if (hour < 12) {
        _hour = "0" + hour.toString();
        hourtext = " am";
      } else if (hour == 24) {
        _hour = (hour - 12).toString();
        hourtext = " am";
        min_inc = 60;
      } else if (hour > 12) {
        _hour = "0" + (hour - 12).toString();
        hourtext = " pm";
      } else if (hour == 12) {
        _hour = hour.toString();
        hourtext = " pm";
      }

      _hour = _hour.slice(-2);
      for (let min = 0; min < 60; min = min + min_inc) {
        _min = "0" + min.toString();
        _min = _min.slice(-2);
        _curfewdata = {
          Time: _hour + ":" + _min + hourtext,
          Value: hour + ":" + min,
        };
        _curfew_data_arr.push(_curfewdata);
      }
    }
    this.Curfew_data = _curfew_data_arr;
  }

  ngOnInit() {
    this.matTooltipWrong =
      "Checking this box will create an alert if a Resident is seen in a Resident room other than their own room for a considerable amount of time. Alert will auto-\nclear if Staff Geo is present." +
      "\n\nRules:\n" +
      ' \u2800\u2022\u2800 Rule 1: If Resident detected in a Resident "Space" that is not associated to this Resident Record for >180 Seconds, then a Roaming Alert will be posted.\n' +
      " \u2800\u2022\u2800 Rule 2: If Staff is in same space within this same time, the Alert will not post or will auto-clear\n" +
      " \u2800\u2022\u2800 Rule 3: If Resident is detected in different Space at any time within 180 seconds, do not post the alert\n" +
      " \u2800\u2022\u2800 Rule 4: If Resident is detected in different Space at any time after 180 seconds, auto-clear the alarm";

    this.matTooltipAllEgress =
      "Checking this box will create a Roaming Alert if a resident seen in any Egress Area. Alert will auto-clear if Staff Geo is present.\n Note - if this resident is wearing a Wander Geo, then this is an additional alert and unnecessary if you use the Loiter feature." +
      "\n\nRules:\n" +
      ' \u2800\u2022\u2800 Rule 1: If Resident is seen in any "Egress" Space Category ever [no delay or wait outside Rule #2], then post Roaming Alert\n' +
      " \u2800\u2022\u2800 Rule 2: If Staff is in same space within this same time, the Alert will not post OR will auto-clear\n " +
      " \u2800\u2022\u2800 Rule 3: If Resident is detected in a different Space at any time after 60 seconds, auto-clear the alarm";
    
    this.matTooltipWrongRoomExpireTime = "Enter non-zero # days for Wrong Room to stay Enabled. Upon expiration, Wrong Room will be disabled and Expiration Time reset to 0."
    this.matTooltipAllEgressExpireTime = "Enter non-zero # days for All Egress to stay Enabled. Upon expiration, All Egress will be disabled and Expiration Time reset to 0."
    
      this.authkey = localStorage.getItem("Authkey") ?? "";
    // if (localStorage.getItem("wander_config") == "1") {
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Roaming,
          enumUIAction.Roaming_View
        ) == enumUIView.Show
      ) {
        this.bWanderView = "1";
        this.BothDisplay = 2;
      }
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Roaming,
          enumUIAction.Roaming_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bWanderAddEdit = "1";
      }
    // }

    this.countrycode = localStorage.getItem("country_code");
    this.voiceLicense = localStorage.getItem("voice_license"); 
    this.onResize();
    this.getCampusdetails();
    this.getResidentGroup();
    this.residentimageUrl = "assets/images/admin-avatar.png";
    this.addmeddisabled = false;
    this.startDate = "1900-01-01T12:00:00.000Z";
    this.CurrentDate = new Date();
    

    if (this.showResident == true) {
      this.BothDisplay = 1;
      this.residentCreationForm = new UntypedFormGroup({
        firstname: new UntypedFormControl("", [Validators.required,Validators.maxLength(30),Validators.pattern(appsettings.Name_Pattern)]),
        lastname: new UntypedFormControl("", [Validators.required,Validators.maxLength(30),Validators.pattern(appsettings.Name_Pattern)]),
        spousename: new UntypedFormControl("", [Validators.maxLength(30),Validators.pattern(appsettings.Name_Pattern)]),
        dateofbirth: new UntypedFormControl(""),
        gender: new UntypedFormControl("", [Validators.required]),
        photo: new UntypedFormControl(""),
        tagid: new UntypedFormControl("", [Validators.required,Validators.maxLength(10),Validators.max(1073741823),Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]),
        facility: new UntypedFormControl("", [Validators.required]),
        campus: new UntypedFormControl("", [Validators.required]),
        building: new UntypedFormControl("", [Validators.required]),
        floor: new UntypedFormControl("", [Validators.required]),
        unit: new UntypedFormControl("", [Validators.required]),
        phone: new UntypedFormControl("", [Validators.maxLength(10),Validators.minLength(10),Validators.pattern(appsettings.Number_Pattern)]),
        email: new UntypedFormControl("", [Validators.email,Validators.pattern(appsettings.Email_Pattern)]),
        group: new UntypedFormControl(""),
        pager: new UntypedFormControl('', [Validators.pattern(appsettings.Number_Pattern)])
      });

      const resForm_Facility = this.residentCreationForm.get("facility");
      if (resForm_Facility != null) resForm_Facility.disable();
    }

    if (this.bWanderView == "1") {
      this.restrictedSpaceForm = new UntypedFormGroup({
        residentname: new UntypedFormControl(""),
        wrongroom: new UntypedFormControl(""),
        wr_start: new UntypedFormControl(""),
        wr_end: new UntypedFormControl(""),
        allegress: new UntypedFormControl(""),
        ae_start: new UntypedFormControl(""),
        ae_end: new UntypedFormControl(""),
        // wr_time: new UntypedFormControl(''), 
        // ae_time: new UntypedFormControl('') 
      });

      const resForm_ResidentName = this.restrictedSpaceForm.get("residentname");
      if (resForm_ResidentName != null) resForm_ResidentName.disable();
    }

    if (this.bWanderView == "1" && this.showResident == true) {
      this.BothDisplay = 3;
    }

    this.getFacilityInfo();
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.residentCreationForm.controls).forEach((field) => {
        // {1}
        const control = this.residentCreationForm.get(field); // {2}
        const resFormId = document.getElementById(event.target.id);
        if (resFormId != null) {
          if (field == resFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    this.screenWidth = window.innerWidth;
    if (isIEOrEdge == true) {
      this.resrowHeight = "72px";
    } else {
      this.resrowHeight = "67px";
    }
    if (isIEOrEdge == false && this.screenWidth < 400) {
      this.columns = 3;
      this.resrowHeight = "85px";
    } else if (isIEOrEdge == true && this.screenWidth < 400) {
      this.columns = 3;
      this.resrowHeight = "88px";
    } else if (this.screenWidth >= 1097 && isIEOrEdge == false) {
      this.columns = 8;
      this.resrowHeight = "67px";
    }
  }
  getFacilityInfo() {
    const facilitydata: getFacility = {
      authKey: this.authkey,
    };
    this.apiService
      .getFacilityInfo(facilitydata)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((facilityInfo: any[]) => {
        this.Facility = facilityInfo;
        this.FacilityName = this.Facility[0].FacilityName;
      });
  }

  getCampusdetails() {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - getCampusdetails() - " + err.message
      );
    }
  }

  getBuildingdetails(CampusId) {
    try {
      this.selectedCampusId = CampusId;
      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
      };
      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          this.Buildingdetails = BuildingDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - getBuildingdetails() - " + err.message
      );
    }
  }

  getFloorDetails(BuildingId) {
    try {
      const floorid: getZone = {        
        authKey: this.authkey,
        campusId:this.selectedCampusId,
        buildId : parseInt(BuildingId),
        floorId : 0
      }
      this.apiService
        .getZoneDetails(floorid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((FloorDetails: any[]) => {
          this.Floordetails = FloorDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - getFloorDetails() - " + err.message
      );
    }
  }

  getUnitDetails(FloorId) {
    try {
      const unitid: getUnit = {
        authKey: this.authkey,
        floorId: parseInt(FloorId),
        isIndoor: 1,
        isNeedIntercom :parseInt(this.voiceLicense),
        isSubZone : 0
      };
      this.apiService
        .getUnitDetails(unitid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((UnitDetails: any[]) => {
          this.Unitdetails = UnitDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - getUnitDetails() - " + err.message
      );
    }
  }

  changecampus(campusid) {
    if (campusid > 0 && this.oldcampusid != campusid) {
      this.selebuilding = false;
      this.Buildingdetails = [];
      this.Floordetails = [];
      this.Unitdetails = [];
      this.residentCreationForm.controls["building"].setValue("");
      this.residentCreationForm.controls["floor"].setValue("");
      this.residentCreationForm.controls["unit"].setValue("");

      this.oldcampusid = campusid;
    }
  }
  changebuild(buildid) {
    if (buildid > 0 && this.oldbuildid != buildid) {
      this.selebuilding = true;
      this.selefloor = false;
      this.Floordetails = [];
      this.Unitdetails = [];
      this.residentCreationForm.controls["floor"].setValue("");
      this.residentCreationForm.controls["unit"].setValue("");

      this.oldbuildid = buildid;
    }
  }
  changefloor(floorid) {
    if (floorid > 0 && this.oldfloorid != floorid) {
      this.selefloor = true;
      this.seleunit = false;
      this.Unitdetails = [];
      this.residentCreationForm.controls["unit"].setValue("");

      this.oldfloorid = floorid;
    }
  }
  changeunit(unitId) {
    if (unitId > 0) this.seleunit = true;
  }

  getResidentGroup() {
    try {
      const groupitem: getGroupData = {
        authKey: this.authkey,
      };
      this.apiService
        .getResidentGroup(groupitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Groupdetails: any[]) => {
          if (Groupdetails["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Groupdetails = Groupdetails["apiReturn"];
          if (this.Groupdetails.length > 0) this.Placeholder = "Group(s)";
          else this.Placeholder = "No Records";
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - getResidentGroup() - " + err.message
      );
    }
  }    

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public onCloseCancel_ResSpace = () => {
    this.thisDialogRef.close("Confirm");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.residentCreationForm.controls[controlName].hasError(errorName);
  };

  public createResident = (residentFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      this.gender = 1;
      (document.activeElement as HTMLTextAreaElement).blur();
      if (
        this.residentCreationForm.valid &&
        this.clkchecksave == 0 &&
        this.residentbool
      ) {
        this.residentbool = false;
        this.checkTagId(residentFormValue);
      }
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - createResident() - " + err.message
      );
    }
  };
  checkTagId(residentFormValue) {
    try {
      const tagidfoundItem: checkTagId = {
        authKey: this.authkey,
        newTagId: parseInt(residentFormValue.tagid),
        resStaffId: 0,
        tagType:enumTagType.Resident
      };
      if (this.residentCreationForm.valid) {
        this.apiService
          .checkTagId(tagidfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: TagIdfoundResponse[]) => {
            if (apiresponse.toString() == "not found") {
              this.addResidentData(residentFormValue);
            } else {
              this.residentbool = true;
              if (
                apiresponse[0].desc == "Resident" ||
                apiresponse[0].desc == "Staff" ||
                apiresponse[0].desc == "Asset"
              )
                generateAlertMessage(
                  this.dialog,
                  "Duplicate tagid found, the " +
                    apiresponse[0].desc +
                    "  '" +
                    apiresponse[0].name +
                    "'  already has this tagid.<br> Please try again with different tagid"
                );
              else
                generateAlertMessage(
                  this.dialog,
                  "Duplicate tagid found, the " +
                    apiresponse[0].name +
                    " already has this tagid on " +
                    apiresponse[0].desc +
                    ".<br> Please try again with different tagid"
                );
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - checkTagId() - " + err.message
      );
    }
  }
  private addResidentData = (residentFormValue) => {
    try {
      const dateofbirth = new DatePipe("en-US");
      let DateofBirth = dateofbirth.transform(
        residentFormValue.dateofbirth,
        "yyyy/MM/dd"
      );
      if (DateofBirth != null) DateofBirth = DateofBirth.toString();
     
      this.addbuildingId = true;
      this.addfloorId = true;
      this.addunitId = true;
      if (this.selebuilding == false) {
        this.addbuildingId = false;
        this.addfloorId = false;
        this.addunitId = false;
        this.Buildingdetails = [];
        this.Floordetails = [];
        this.Unitdetails = [];
      } else if (this.selefloor == false) {
        this.addfloorId = false;
        this.addunitId = false;
        this.Floordetails = [];
        this.Unitdetails = [];
      } else if (this.seleunit == false) {
        this.addunitId = false;
        this.Unitdetails = [];
      }

      if (this.addbuildingId == false) {
        this.residentCreationForm.controls["building"].setErrors({
          incorrect: true,
        });
      }
      if (this.addfloorId == false) {
        this.residentCreationForm.controls["floor"].setErrors({
          incorrect: true,
        });
      }

      if (this.addunitId == false) {
        this.residentCreationForm.controls["unit"].setErrors({
          incorrect: true,
        });
      }

      if (
        this.addbuildingId == false ||
        this.addfloorId == false ||
        this.addunitId == false
      ) {
        this.residentbool = true;
        return false;
      }

      let _phoneNumber = "";
      if (residentFormValue.phone != "")
        _phoneNumber = this.countrycode + residentFormValue.phone;
      let _addcheckinvalue = 0;
      if (this.addCheck_Invalue == true) _addcheckinvalue = 1;
      else if (this.addCheck_Invalue == false) _addcheckinvalue = 0;

      if (DateofBirth == null) DateofBirth = "";

      let groupdid = "";
      let _addvoicevalue = 0;
      if (residentFormValue.group != undefined) groupdid = residentFormValue.group.toString();	      
      if(this.addVoicevalue == true) _addvoicevalue = 1;
      else if(this.addVoicevalue == false) _addvoicevalue = 0;
      
      let resPager = "";    
      if(residentFormValue.pager != '' || residentFormValue.pager != undefined || residentFormValue.pager != null)            
        resPager = residentFormValue.pager;      
      else
        resPager = ""; 
        
      let addWandervalue = 0;      
      if(this.addResWandervalue == true)      
        addWandervalue = 1;
      else if(this.addResWandervalue == false)      
        addWandervalue = 0;
      
      if(this.voiceLicense == 0)
        _addvoicevalue = 0;
      const resident: ResidentForCreation = {
        authKey: this.authkey,
        firstName: residentFormValue.firstname.replace(/\s+/g, " ").trim(),
        lastName: residentFormValue.lastname.replace(/\s+/g, " ").trim(),
        spouseName: residentFormValue.spousename.replace(/\s+/g, " ").trim(),
        dateOfBirth: DateofBirth,
        gender: residentFormValue.gender.toString(),
        photo: this.uploadimagename,
        tagId: parseInt(residentFormValue.tagid),
        facility: this.FacilityName.replace(/\s+/g, " ").trim(),
        campusId: parseInt(residentFormValue.campus),
        buildingId: parseInt(residentFormValue.building),
        floorId: parseInt(residentFormValue.floor),
        unitId: parseInt(residentFormValue.unit),
        phone: _phoneNumber,
        pager : resPager,
        email: residentFormValue.email.toString(),
        groupId: groupdid,
        residentCheckIn: _addcheckinvalue,
        isNeedVoice : _addvoicevalue,
        isWanderMode : addWandervalue
      };
      this.residentService
        .addResidentData(resident)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: ResidentForCreationResponse[]) => {
          if (apiresponse["ResidentId"] > 0) {
            this.apiService.reloadactivetag();
            this.residentbool = false;
            generateAlertMessage(
              this.dialog,
              "Resident data added successfully."
            );
            this.add_residentId = apiresponse["ResidentId"];
            if (this.add_residentId != undefined)
              this.getResidentdetails(this.add_residentId);
          } 
          else if (apiresponse["apiReturn"] == "Fail") {
            this.residentbool = true;
            generateAlertMessage(this.dialog, "Resident data added failed.");
            this.thisDialogRef.close("Confirm");
          } 
          else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          else
          {
            this.residentbool = true;
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
            this.thisDialogRef.close("Confirm");
          }
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - addResidentData() - " + err.message
      );
    }
  };

  getResidentdetails(residentId) {
    let residentbyId;
    this.addRestricted_Space = true;
    this.addRes_Info = false;
    const residentbyid: ResidentByID = {
      authKey: this.authkey,
      residentId: parseInt(residentId),
    };

    this.residentService
      .getResidentDataById(residentbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((ResidentbyId: any[]) => {
        residentbyId = ResidentbyId["apiReturn"];
        this.addresident_name = residentbyId[0].FirstName + " " + residentbyId[0].LastName;
        this.selectedIndex = 1;
        this.showResident = false;
        this.showRoaming = true;
        this.getResidentUnsafeZones(enumSpaceCategory.ResidentRoom);
        this.getRoaming();
      });
  }

  radioChange(event) {
    console.log(event + " event");
    const resTag_Id = document.getElementById("restagid");
    if (resTag_Id != null) resTag_Id.focus();
  }
  getErrorMessage(pickerInput: string): string {
    if (!pickerInput || pickerInput === "") {
      return "Please select Date Of Birth";
    } else if (!moment(pickerInput).isValid()) {
      return "Please enter valid date format MM/DD/YYYY";
    } else {
      return "Please enter current date format MM/DD/YYYY";
    }
  }

  toggleCheckInVisibility(event) {
    if (event.checked == true) {
      this.addCheck_Invalue = true;
    } else {
      this.addCheck_Invalue = false;
    }
  }

  toggleVoiceVisibility(event)
  {
    if(event.checked == true)
    {
      this.addVoicevalue = true;       
    }
    else
    {      
      this.addVoicevalue = false;         
    }
  }
  
  toggleAddWanderVisibility(event)
  {
    if(event.checked == true)
    {
      this.addResWandervalue = true;       
    }
    else
    {      
      this.addResWandervalue = false;         
    }
  }
  
  addresident_roaming($event): void {
    this.selectedIndex = $event.index;

    if (this.selectedIndex == 0) {
      this.showResident = true;
      this.showRoaming = false;
      this.ngOnInit();
    } else if (this.selectedIndex == 1) {
      this.selectedIndex = $event.index;
      this.showResident = false;
      this.showRoaming = true;
    }
  }

  toggleWrongroom(e) {    
    if (e.checked == true) {
      this.checkWrongRoom=true;  
      this._roomenableroomallday = true; 
      this.checkresroominfo = true;
      if(this.checkresroominfo == true)
        this._roomenablehourmin = false;     
      else
        this._roomenablehourmin = true;    
        
        this.showSelectedRoom(1);
    }
    else{
      this.checkWrongRoom=false;   
      this._roomenableroomallday = false;
      this._roomenablehourmin = false;  
      this.data_wrstartTime = 0;
      this.data_wrendTime = 0;
      this.checkresroominfo = false;   
      const element = document.getElementById('txtaddwrExpireTime') as HTMLInputElement;   
        element.value = ""; 
      if(this.checkAllEgress == true)    
        this.showSelectedRoom(2);    
      else
        this.restrictionsHeader = "";     
    }  
    
  }
  
  toggleAllEgress(e) {  
    if (e.checked == true) {
      this.checkAllEgress=true;   
      this._roomenableEgressallday = true; 
      this.checkresallinfo = true;
      if(this.checkresallinfo == true)
        this._egressenablehourmin = false;
      else
        this._egressenablehourmin = true;   
        
        this.showSelectedRoom(2);
    }
    else{
      this.checkAllEgress=false;
      this._roomenableEgressallday = false;
      this._egressenablehourmin = false;     
      this.data_aestartTime = 0;
      this.data_aeendTime = 0;
      this.checkresallinfo = false;
      const element = document.getElementById('txtaddaeExpireTime') as HTMLInputElement;   
        element.value = ""; 
      if(this.checkWrongRoom == true)    
        this.showSelectedRoom(1);    
      else
        this.restrictionsHeader = ""; 
    }  
   
  }

  toggleresdayinfoVisibility(event) {
    if (event.checked == true) {
      this._roomenablehourmin = false;
      this.checkresroominfo = true;
      this.data_wrstartTime = 0;
      this.data_wrendTime = 0;
    } else {
      this._roomenablehourmin = true;
      this.data_wrstartTime = 0;
      this.data_wrendTime = 0;
      this.checkresroominfo = false;
    }
  }

  toggleresalldayinfoVisibility(event) {
    if (event.checked == true) {
      this._egressenablehourmin = false;
      this.checkresallinfo = true;
      this.data_aestartTime = 0;
      this.data_aeendTime = 0;
    } else {
      this._egressenablehourmin = true;
      this.data_aestartTime = 0;
      this.data_aeendTime = 0;
      this.checkresallinfo = false;
    }
  }
  
  showSelectedRoom(type)
  {
    if(type == 1)
    {
      this.restrictionsHeader = "Wrong Room Space Restrictions"  
      this.residentRoomSelect = enumResEgress_Type.ResidentRoom;
      this.getResidentUnsafeZones(enumSpaceCategory.ResidentRoom);
      this.onSelectRoomdetails(this.residentRoomSelect);
    }
    else if(type == 2)
    {
      this.restrictionsHeader = "All Egress Space Restrictions"
      this.egressRoomSelect = enumResEgress_Type.EgressRoom; 
      this.getResidentUnsafeZones(enumSpaceCategory.Egress);
      this.onSelectRoomdetails(this.egressRoomSelect);
    }
  }

onSelectRoomdetails(checkselectitem)
{      
  for(let ncampus = 0; ncampus < this.arrallCampusid.length; ncampus++)
  {        
    for(let nbuild = 0; nbuild < this.arrallCampusid[ncampus].children.length; nbuild++)
    {      
      for(let nfloor = 0; nfloor <  this.arrallCampusid[ncampus].children[nbuild].children.length; nfloor++)
      {     
        for(let nroom = 0; nroom < this.arrallCampusid[ncampus].children[nbuild].children[nfloor].children.length; nroom++)
        {       
          if(this.arrallCampusid[ncampus].children[nbuild].children[nfloor].children[nroom].Category == checkselectitem)
          {            
              TreeviewHelper.findItemInList(this.arrallCampusid, this.arrallCampusid[ncampus].children[nbuild].children[nfloor].children[nroom].value).checked = true;                        
          }         
        }
      }
      
    }
    
  }
  this.items = this.getProducts();
}
  
  getResidentUnsafeZones(spaceCategoryId) { 
    try
    {
      const campusid: getCampusRestrictedSpace = {        
        authKey: this.authkey,
        residentId :0,
        spaceCategory:spaceCategoryId
      }
      this.residentService.getCampusAllData(campusid).pipe(takeUntil(this._onDestroy))
      .subscribe((CampusDetails : any[])=>{
        this.Campusdetails = CampusDetails;
        if (CampusDetails != null && CampusDetails != undefined) {
          this.arrallCampusid = CampusDetails["Campusdetails"];
          this.items = this.getProducts();
        }
      });  
    }
    catch (err) {
      throw new Error('add-wanderconfig.component.ts' + ' - getResidentUnsafeZones() - ' + err.message);
    }
  }
  
  getRoaming()
  {
    const roamingdata: ResidentByID = {     
      authKey: this.authkey,
      residentId : this.add_residentId
    }
    this.residentService.getRoaming(roamingdata).pipe(takeUntil(this._onDestroy))
    .subscribe((RoamingDetails : any[])=>{
      this.getRoamingData = RoamingDetails;    
      if(this.getRoamingData.length > 0)
      {
        this.residentRoomCount =  this.getRoamingData[0].ResidentRoomCount;   
        this.egressRoomCount =  this.getRoamingData[0].EgressRoomCount;            
      }
      
    }) 

  }
  
  getProducts(): TreeviewItem[] {
    const Tree_arrallCampusid = new Array();
    try {
      for (const key in this.arrallCampusid) {
        const data = this.arrallCampusid[key];
        if (this.arrallCampusid[key].children.length > 0) {
          const TreeData = new TreeviewItem(data);
          Tree_arrallCampusid[Tree_arrallCampusid.length] = TreeData;
        }
      }
    }
    catch (err) {
      throw new Error('add-wanderconfig.component.ts' + ' - getProducts() - ' + err.message);
    }  
    return Tree_arrallCampusid;
  }
  
  onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
    try {
      this.sel_CampusId = [];        
      this.sel_BuildingId = [];
      this.sel_FloorId = [];
      this.sel_RoomId = [];    
      if(this.restrictionsHeader == "Wrong Room Space Restrictions")
        this.wrongSel_RoomId = [];    
      if(this.restrictionsHeader != "Wrong Room Space Restrictions") 
        this.egressSel_RoomId = []; 
      // var wrongRoomEnable = this.checkWrongRoom;
      // var egressEnable = this.checkAllEgress;
      downlineItems.forEach(downlineItem => {
          const item = downlineItem.item;
          const value = item.value;
          const texts = [] as any[];
          let parent = downlineItem.parent;
          while (!isNil(parent)) {
            texts.push(parent.item.value);//texts.push(parent.item.text+":"+parent.item.value);
            parent = parent.parent;
          }
          const reverseTexts = reverse(texts);
          const row = `${reverseTexts.join(':')}:${value}`;
          const arr_row = row.split(':')               
          
        if (arr_row.length > 0 && !this.sel_CampusId.includes(arr_row[0]))
          this.sel_CampusId[this.sel_CampusId.length] = arr_row[0];
  
        if (arr_row.length > 1 && !this.sel_BuildingId.includes(arr_row[1]))
          this.sel_BuildingId[this.sel_BuildingId.length] = arr_row[1];
  
        if (arr_row.length > 2 && !this.sel_FloorId.includes(arr_row[2]))
          this.sel_FloorId[this.sel_FloorId.length] = arr_row[2];
  
        if (arr_row.length > 3 && !this.sel_RoomId.includes(arr_row[3]))
          this.sel_RoomId[this.sel_RoomId.length] = arr_row[3];
          
        if (arr_row.length > 3 && !this.wrongSel_RoomId.includes(arr_row[3]) && this.restrictionsHeader != "" && this.restrictionsHeader == "Wrong Room Space Restrictions")
          this.wrongSel_RoomId[this.wrongSel_RoomId.length] = arr_row[3];
          
        if (arr_row.length > 3 && !this.egressSel_RoomId.includes(arr_row[3]) && this.restrictionsHeader != "" && this.restrictionsHeader != "Wrong Room Space Restrictions")
          this.egressSel_RoomId[this.egressSel_RoomId.length] = arr_row[3];
  
      });    
    }
    catch (err) {
      throw new Error('edit-resident.component.ts' + ' - onSelectedChange() - ' + err.message);
    }
  }

  public addRestrictedSpace = (addrestrictedSpaceFormValue) => {
    try {
      let roomid;
      let campusid;
      let buildingid;
      let floorid;
      let W_roomid;
      let A_roomid;
      let check_Room_AllDay = 0;
      let check_Egres_AllDay = 0;

      let W_Start_Hr = 0;
      let W_Start_Min = 0;
      let W_End_Hr = 0;
      let W_End_Min = 0;

      let A_Start_Hr = 0;
      let A_Start_Min = 0;
      let A_End_Hr = 0;
      let A_End_Min = 0;

      let wstarttime = 0;
      let wendtime = 0;
      let astarttime = 0;
      let aendtime = 0;
      let wrongRoom = "";
      let allEgress = "";

      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      if (this.addResSpacebool) {
        this.addResSpacebool = false;

        if (
          addrestrictedSpaceFormValue.wrongroom == true ||
          addrestrictedSpaceFormValue.wrongroom == "true"
        ) {
          if (
            (addrestrictedSpaceFormValue.wr_start == "" ||
              addrestrictedSpaceFormValue.wr_start == undefined) &&
            (addrestrictedSpaceFormValue.wr_end == "" ||
              addrestrictedSpaceFormValue.wr_end == undefined) &&
            (this.checkresroominfo == false ||
              this.checkresroominfo == undefined)
          ) {
            this.addResSpacebool = true;
            generateAlertMessage(
              this.dialog,
              "Please select Wrong Room Time info or All"
            );
            return false;
          }

          if (
            addrestrictedSpaceFormValue.wr_start != "" &&
            addrestrictedSpaceFormValue.wr_start != undefined
          ) {
            if (
              addrestrictedSpaceFormValue.wr_end == "" ||
              addrestrictedSpaceFormValue.wr_end == undefined
            ) {
              this.addResSpacebool = true;
              generateAlertMessage(
                this.dialog,
                "Please select Wrong Room End time"
              );
              return false;
            }
          }

          if (
            addrestrictedSpaceFormValue.wr_end != "" &&
            addrestrictedSpaceFormValue.wr_end != undefined
          ) {
            if (
              addrestrictedSpaceFormValue.wr_start == "" ||
              addrestrictedSpaceFormValue.wr_start == undefined
            ) {
              this.addResSpacebool = true;
              generateAlertMessage(
                this.dialog,
                "Please select Wrong Room Start time"
              );
              return false;
            }
          }
        }

        if (
          addrestrictedSpaceFormValue.allegress == true ||
          addrestrictedSpaceFormValue.allegress == "true"
        ) {
          if (
            (addrestrictedSpaceFormValue.ae_start == "" ||
              addrestrictedSpaceFormValue.ae_start == undefined) &&
            (addrestrictedSpaceFormValue.ae_end == "" ||
              addrestrictedSpaceFormValue.ae_end == undefined) &&
            (this.checkresallinfo == false || this.checkresallinfo == undefined)
          ) {
            this.addResSpacebool = true;
            generateAlertMessage(
              this.dialog,
              "Please select All Egress Time info or All"
            );
            return false;
          }

          if (
            addrestrictedSpaceFormValue.ae_start != "" &&
            addrestrictedSpaceFormValue.ae_start != undefined
          ) {
            if (
              addrestrictedSpaceFormValue.ae_end == "" ||
              addrestrictedSpaceFormValue.ae_end == undefined
            ) {
              this.addResSpacebool = true;
              generateAlertMessage(
                this.dialog,
                "Please select All Egress End time"
              );
              return false;
            }
          }

          if (
            addrestrictedSpaceFormValue.ae_end != "" &&
            addrestrictedSpaceFormValue.ae_end != undefined
          ) {
            if (
              addrestrictedSpaceFormValue.ae_start == "" ||
              addrestrictedSpaceFormValue.ae_start == undefined
            ) {
              this.addResSpacebool = true;
              generateAlertMessage(
                this.dialog,
                "Please select All Egress Start time"
              );
              return false;
            }
          }
        }
                
        if (
          addrestrictedSpaceFormValue.wr_start != undefined &&
          addrestrictedSpaceFormValue.wr_start != 0
        ) {
          wstarttime = addrestrictedSpaceFormValue.wr_start.split(":");
          W_Start_Hr = parseInt(wstarttime[0]);
          W_Start_Min = wstarttime[1].split(" ");
          W_Start_Min = parseInt(W_Start_Min[0]);
        }
        if (
          addrestrictedSpaceFormValue.wr_end != undefined &&
          addrestrictedSpaceFormValue.wr_end != 0
        ) {
          wendtime = addrestrictedSpaceFormValue.wr_end.split(":");
          W_End_Hr = parseInt(wendtime[0]);
          W_End_Min = wendtime[1].split(" ");
          W_End_Min = parseInt(W_End_Min[0]);
        }

        if (
          addrestrictedSpaceFormValue.ae_start != undefined &&
          addrestrictedSpaceFormValue.ae_start != ""
        ) {
          astarttime = addrestrictedSpaceFormValue.ae_start.split(":");
          A_Start_Hr = parseInt(astarttime[0]);
          A_Start_Min = astarttime[1].split(" ");
          A_Start_Min = parseInt(A_Start_Min[0]);
        }

        if (
          addrestrictedSpaceFormValue.ae_end != undefined &&
          addrestrictedSpaceFormValue.ae_end != ""
        ) {
          aendtime = addrestrictedSpaceFormValue.ae_end.split(":");
          A_End_Hr = parseInt(aendtime[0]);
          A_End_Min = aendtime[1].split(" ");
          A_End_Min = parseInt(A_End_Min[0]);
        }

        if (this.checkresroominfo == true) check_Room_AllDay = 1;
        else check_Room_AllDay = 0;

        if (this.checkresallinfo == true) check_Egres_AllDay = 1;
        else check_Egres_AllDay = 0;
        
        if(addrestrictedSpaceFormValue.wrongroom != undefined)        
          wrongRoom = addrestrictedSpaceFormValue.wrongroom.toString();        
        else
          wrongRoom = "";  
          
        if(addrestrictedSpaceFormValue.allegress != undefined)        
          allEgress = addrestrictedSpaceFormValue.allegress.toString();        
        else
          allEgress = "";
          
        if(wrongRoom == "true" && check_Room_AllDay == 0 && W_Start_Hr >= 0 && W_End_Hr <= W_Start_Hr && W_End_Hr < 12)  
        {
          if((W_Start_Hr == W_End_Hr && W_Start_Min >= W_End_Min) || (W_Start_Hr > W_End_Hr && W_Start_Min <= W_End_Min))
          {                       
            this.addResSpacebool = true;
            generateAlertMessage(this.dialog,"Wrong Room Start time should be lesser than the End time");
            return false;
          }
        }
        
        if(allEgress == "true" && check_Egres_AllDay == 0 && A_Start_Hr >= 0 && A_End_Hr <= A_Start_Hr && A_End_Hr < 12)  
        {
          if((A_Start_Hr == A_End_Hr && A_Start_Min >= A_End_Min) || (A_Start_Hr > A_End_Hr && A_Start_Min <= A_End_Min))
          {                       
            this.addResSpacebool = true;
            generateAlertMessage(this.dialog,"All Egress Start time should be lesser than the End time");
            return false;
          }
        }
        
        if(this.wrongSel_RoomId.length == 0 && addrestrictedSpaceFormValue.wrongroom == "true" && this.residentRoomCount > 0)
        {
          this.addResSpacebool = true;
          generateAlertMessage(this.dialog,"Must add at least one Space for Wrong Room");
          return false;
        }
        
        if(this.egressSel_RoomId.length == 0 && addrestrictedSpaceFormValue.allegress == "true" && this.egressRoomCount > 0)
        {
          this.addResSpacebool = true;
          generateAlertMessage(this.dialog,"Must add at least one Space for All Egress");
          return false;
        }
          
        campusid = this.sel_CampusId;    
        buildingid = this.sel_BuildingId;
        floorid = this.sel_FloorId;   
        roomid = this.sel_RoomId;  
        W_roomid = this.wrongSel_RoomId; 
        A_roomid =  this.egressSel_RoomId;
        console.log(campusid,floorid)
        const buildingIdInf=buildingid;        
        for (let nidx=0;nidx<buildingIdInf.length;nidx++)
        {
          if (buildingIdInf[nidx].toString().split("_").length>1)
          {
            roomid[roomid.length] =buildingIdInf[nidx].toString().split("_")[0];
            buildingid.slice(nidx,0);
            
            // W_roomid[W_roomid.length] =buildingIdInf[nidx].toString().split("_")[0];
            // buildingid.slice(nidx,0);
            
            // A_roomid[A_roomid.length] =buildingIdInf[nidx].toString().split("_")[0];
            // buildingid.slice(nidx,0);
          }
        }    
        
        const wr_ExpireTime = document.getElementById("txtaddwrExpireTime") as HTMLInputElement;  
        const ae_ExpireTime = document.getElementById("txtaddaeExpireTime") as HTMLInputElement;

        let wrongExpireTime = 0;
        let egressExpireTime = 0;
        
        if(parseInt(wr_ExpireTime.value) < 0)
        {
          this.addResSpacebool = true;
          generateAlertMessage(this.dialog,"Please Enter valid Expiration Time for Wrong Room");
          return false;
        }                 
        if(wr_ExpireTime.value != "" && !/^[0-9]+$/.test(wr_ExpireTime.value))
        {
          this.addResSpacebool = true;
          generateAlertMessage(this.dialog,"Please Enter valid Expiration Time for Wrong Room");
          return false;
        }   
        
        if(parseInt(ae_ExpireTime.value) < 0)
        {
          this.addResSpacebool = true;
          generateAlertMessage(this.dialog,"Please Enter valid Expiration Time for All Egress");
          return false;
        }
        if(ae_ExpireTime.value != "" && !/^[0-9]+$/.test(ae_ExpireTime.value))
        {
          this.addResSpacebool = true;
          generateAlertMessage(this.dialog,"Please Enter valid Expiration Time for All Egress");
          return false;
        }
    
        
        if(wr_ExpireTime.value != "")
          wrongExpireTime = parseInt(wr_ExpireTime.value);
          
        if(ae_ExpireTime.value != "")
          egressExpireTime = parseInt(ae_ExpireTime.value);
          
        const addresSpaceConfig: AddEditRoaming = {
          authKey: this.authkey,
          residentId: this.add_residentId,
          wrongRoom: wrongRoom,
          wStartHr: W_Start_Hr,
          wStartMi: W_Start_Min,
          wEndHr: W_End_Hr,
          wEndMi: W_End_Min,
          wchkAllday: check_Room_AllDay,
          allEgress: allEgress,
          aStartHr: A_Start_Hr,
          aStartMi: A_Start_Min,
          aEndHr: A_End_Hr,
          aEndMi: A_End_Min,
          // roomId: roomid,
          achkAllday : check_Egres_AllDay,
          wrongRoomId : W_roomid.toString(),
          egressRoomId : A_roomid.toString(),
          wrongExpireDate : wrongExpireTime,
          egressExpireDate : egressExpireTime, 
        };

        this.residentService
          .addRoaming(addresSpaceConfig)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse.toString() == "true") {
              this.addResSpacebool = false;
              this.apiService.reloadlive();
              generateAlertMessage(
                this.dialog,
                "Restricted Space added successfully."
              );
            } else if (apiresponse.toString() == "false") {
              this.addResSpacebool = true;
              generateAlertMessage(
                this.dialog,
                "Restricted Space added failed."
              );
            } else if (apiresponse["condition"] == "Authfalse") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            } else if (apiresponse.toString() == "found") {
              this.addResSpacebool = true;
              generateAlertMessage(
                this.dialog,
                "Already this resident have Restricted Space"
              );
              return;
            }
            this.thisDialogRef.close("Confirm");
          });
      }
    } catch (err) {
      this.addResSpacebool = true;
      throw new Error(
        "edit-resident.component.ts" + " - editroamingConfig() - " + err.message
      );
    }
  };

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
