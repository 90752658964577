<mat-sidenav #leftMenu position="start" class="mat-elevation-z0" [opened]="false" mode="side" class="clsInfraAlert"
  [disableClose]="true">
  <div>
    <div class="alert-title">
      <div style="margin-left:10px;">
        <div style="float:right">
          <mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true" id="mobilelogout"
            (click)="Logout()">exit_to_app</mat-icon>
        </div>
        <div style="float:right">
          <!-- <span><img class="clswrenchicon" src="assets/images/Alerts-Infrastructure-Wrench-Alert.png">
                <span class="imginftacnt">{{Infracount}}</span>
              </span> -->
          <mat-icon matBadge={{Infracount}} [ngClass]="(Infracount > 1000)?'mat-InfraCount mat-icon notranslate mat-badge
                              material-icons mat-icon-no-color mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium':
                              'mat-icon notranslate mat-badge material-icons mat-icon-no-color mat-badge-overlap mat-badge-above mat-badge-after
                              mat-badge-medium'">
            <img class="clswrenchicon" src="assets/images/Alerts-Infrastructure-Wrench-Alert.png">
          </mat-icon>
          <button mat-icon-button (click)="this.toggleInfrastructureMenu()">
            <mat-icon aria-hidden="true">keyboard_arrow_left</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <perfect-scrollbar>

      <div class="alert-list">
        <div *ngFor="let alert of alertinfo;trackBy: trackById">
          <div style="height:150px;"
            *ngIf="alert.AlertType == EnumAlertType.Infrastructure || alert.AlertType == EnumAlertType.systemAlarm ||  alert.SubTypeId == EnumAlertSubType.Environmental">
            <mat-list style="padding:5px 0px 0px 0px;">

              <mat-list-item class="alert-list-item" style="padding:7px 0px 10px 0px;">

                <img *ngIf="alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                    alert.TruViewEventType!=EnumAlertEventType.Appliance_UPS_IN_Battery && 
                    alert.TruViewEventType!=EnumAlertEventType.Appliance_UPS_OUT_Battery && 
                    alert.TruViewEventType!=EnumAlertEventType.Appliance_UPS_Unplug && 
                    alert.TruViewEventType!=EnumAlertEventType.Appliance_LostInternet" matListAvatar
                  [src]="EscLevelImgUrl + alert.subtypeImg" class="Alerts-Popup"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer; margin-top:5px;margin-left:-7px;">
                <img *ngIf="alert.DeviceType==EnumDeviceType.Pager_Communication " matListAvatar
                  [src]="EscLevelImgUrl + alert.subtypeImg" class="Alerts-Popup"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;margin-top:5px;margin-left:0px;">
                <img *ngIf="alert.TruViewEventType==EnumAlertEventType.Appliance_UPS_IN_Battery || 
                    alert.TruViewEventType==EnumAlertEventType.Appliance_UPS_OUT_Battery || 
                    alert.TruViewEventType==EnumAlertEventType.Appliance_UPS_Unplug || 
                    alert.TruViewEventType==EnumAlertEventType.Appliance_LostInternet " matListAvatar
                  [src]="EscLevelImgUrl + alert.subtypeImg" class="Alerts-Popup"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;margin-top:5px;margin-left:0px;">



                <!--<img *ngIf="bLocationAlert && alert.DeviceType != EnumDeviceType.Pager_Communication && alert.DeviceType != EnumDeviceType.Battery" matListAvatar src="assets/images/location.png" (click)="ackalertstatus == 0 ? onClickAlert(alert,1) : false" style="cursor:pointer; width:30px; height:30px; margin-top: -85px; margin-left: -35px;">
                   <img *ngIf="!alert.checklocationIcon" matListAvatar src="assets/images/location_white.png" style="width:20px; height:20px;margin-left:-20px;">                 -->


                <p matLine
                  *ngIf="alert.FirstName != '' && alert.FirstName != 'Security Controller' && alert.DeviceType!=EnumDeviceType.Pager_Communication "
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                  <span
                    *ngIf="alert.ResFirstFlag == 1 && alert.SubTypeId == EnumAlertSubType.Lost_Communication && alert.ResidentFirstFullName != 'Universal Transmitter'"
                    matSuffix matTooltip={{alert.ResidentFirstFullName}} [matTooltipPosition]="position.value"
                    style="cursor:pointer;" class="clslocation"> {{alert.FirstName}}</span>
                  <span
                    *ngIf="alert.ResFirstFlag == 0 || alert.SubTypeId != EnumAlertSubType.Lost_Communication || alert.ResidentFirstFullName == 'Universal Transmitter'"
                    class="clslocation"> {{alert.DeviceDetails}}</span>
                </p>

                <p matLine
                  *ngIf="alert.ResidentName != '' && alert.FirstName != 'Security Controller' && alert.DeviceType!=EnumDeviceType.Pager_Communication"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;margin-bottom:1em;">
                  <span
                    *ngIf="alert.ResLastFlag == 1 && alert.SubTypeId == EnumAlertSubType.Lost_Communication && alert.ResidentFirstFullName != 'Universal Transmitter'"
                    matSuffix matTooltip={{alert.ResidentLastFullName}} [matTooltipPosition]="position.value"
                    style="cursor:pointer;" class="clslocation"> {{alert._AlertSubName}}</span>
                  <span
                    *ngIf="alert.ResLastFlag == 0 || alert.SubTypeId != EnumAlertSubType.Lost_Communication || alert.ResidentFirstFullName == 'Universal Transmitter'"
                    class="clslocation"> {{alert._AlertSubName}}</span>
                </p>

                <p matLine *ngIf="alert.DeviceType==EnumDeviceType.Pager_Communication " style="margin-bottom:1em;"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;margin-bottom:1em;">
                  <span class="clslocation" style="width:130px;"> {{alert.DeviceDetails}} </span>
                  <span class="clslocation" style="width:130px;"> {{alert._AlertSubName}} </span>
                </p>

                <p matLine *ngIf="alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_IN_Battery || 
                                  alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_OUT_Battery ||
                                  alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_Unplug || 
                                  alert.TruViewEventType == EnumAlertEventType.Appliance_LostInternet"
                  style="margin-bottom:1em;" (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;margin-bottom:1em;">
                  <span class="clslocation" style="width:130px;"> {{alert.ResidentName}} </span>
                  <span class="clslocation" style="width:130px;"> {{alert._AlertSubName}} </span>
                </p>

                <p matLine
                  *ngIf="alert.FirstName == '' && alert.ResidentName == '' || alert.FirstName == 'Security Controller'"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;margin-bottom:1em;">
                  <span class="clslocation" style="width:130px;"> {{alert.DeviceDetails}} </span>
                  <span class="clslocation" style="width:130px;"> {{alert._AlertSubName}} </span>
                </p>


                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span *ngIf="alert.FloorFlag == 1 && alert.isOutdoor == 0 && alert.DeviceType!=EnumDeviceType.Pager_Communication &&
                         alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_IN_Battery && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_OUT_Battery &&
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_Unplug && 
                       alert.TruViewEventType != EnumAlertEventType.Appliance_LostInternet
                        " matSuffix matTooltip={{alert.FullFloorName}} [matTooltipPosition]="position.value"
                    class="clslocation"> {{alert.FloorName}}</span>
                  <span *ngIf="alert.FloorFlag == 0 && alert.isOutdoor == 0 && 
                        alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_IN_Battery && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_OUT_Battery &&
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_Unplug && 
                       alert.TruViewEventType != EnumAlertEventType.Appliance_LostInternet" class="clslocation">
                    {{alert.FloorName}}</span>

                  <span *ngIf="alert.FloorFlag == 1 && alert.isOutdoor == 1 && 
                        alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_IN_Battery && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_OUT_Battery &&
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_Unplug && 
                       alert.TruViewEventType != EnumAlertEventType.Appliance_LostInternet" matSuffix
                    matTooltip={{alert.FullCampusName}} [matTooltipPosition]="position.value" class="clslocation">
                    {{alert.AlertCampusName}}</span>
                  <span *ngIf="alert.FloorFlag == 0 && alert.isOutdoor == 1 && 
                      alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                      alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_IN_Battery && 
                      alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_OUT_Battery &&
                      alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_Unplug && 
                     alert.TruViewEventType != EnumAlertEventType.Appliance_LostInternet" class="clslocation">
                    {{alert.AlertCampusName}}</span>

                  <span *ngIf="alert.DeviceType == EnumDeviceType.Pager_Communication || 
                          alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_IN_Battery || 
                          alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_OUT_Battery || 
                          alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_Unplug || 
                          alert.TruViewEventType == EnumAlertEventType.Appliance_LostInternet" class="clslocation">
                    Zone: NA</span>
                </p>
                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;margin-bottom:2em;">
                  <span *ngIf="alert.unitFlag == 1 && alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_IN_Battery && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_OUT_Battery  && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_Unplug && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_LostInternet" matSuffix
                    matTooltip={{alert.FullUnitName}} [matTooltipPosition]="position.value" class="clslocation">
                    {{alert.UnitName}} </span>
                  <span *ngIf="alert.unitFlag == 0 && alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_IN_Battery && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_OUT_Battery && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_Unplug && 
                        alert.TruViewEventType != EnumAlertEventType.Appliance_LostInternet" class="clslocation">
                    {{alert.UnitName}} </span>
                  <span *ngIf="alert.DeviceType == EnumDeviceType.Pager_Communication || 
                      alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_IN_Battery || 
                      alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_OUT_Battery ||  
                      alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_Unplug ||   
                      alert.TruViewEventType == EnumAlertEventType.Appliance_LostInternet" class="clslocation"> Space:
                    NA</span>
                </p>

                <div class="alert-escalation" align="right" style="cursor:pointer;"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false">
                  <!--[ngStyle]="{'cursor': alert.DeviceType!=EnumDeviceType.Pager_Communication  ? 'pointer' : 'default'}"-->
                  <span matLine
                    *ngIf="alert.AlertSubName !='Backup Battery' && alert.AlertSubName !='Low Battery' && alert.DeviceType!=EnumDeviceType.Battery"
                    style="width:90px;white-space:pre-wrap;" [ngClass]=alert.Fontcolor>{{alert.AlertSubName}}</span>
                  <span matLine *ngIf="alert.DeviceType==EnumDeviceType.Battery"
                    style="width:90px;white-space:pre-wrap;margin-top:-25px;"
                    [ngClass]=alert.Fontcolor>{{alert.AlertDescript}}</span>
                  <span matLine *ngIf="alert.AlertSubName =='Backup Battery' || alert.AlertSubName =='Low Battery' "
                    style="width:50px;white-space:pre-wrap;" [ngClass]=alert.Fontcolor>{{alert.AlertSubName}}</span>
                  <p *ngIf="alert.AckStatus != EnumAlertAckStatus.Notified" [ngClass]=alert.Fontcolor>
                    {{alert.StatusDec}}</p>
                  <span matLine style="white-space:pre-line;width:90px;font-size:13px;"
                    [ngClass]=alert.Fontcolor>{{alert.AlertUpdatedTime}}</span>
                  <img *ngIf="alert.AlertType != EnumAlertType.Wander && alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                      alert.DeviceType!=EnumDeviceType.Battery && alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_IN_Battery && 
                      alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_OUT_Battery &&
                      alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_Unplug && 
                      alert.TruViewEventType != EnumAlertEventType.Appliance_LostInternet "
                    [src]="EscLevelImgUrl + alert.LevelPhoto" style="width:68px;height:20px;">
                </div>

                <p matLine>
                  <span *ngIf="alert.locationFlag == 0" style="color:white;min-width:80%;display:block;"></span>
                </p>

              </mat-list-item>
              <mat-list-item>
                <div class="clsdivalertlocation">
                  <span *ngIf="alert.locationFlag == 1 && alert.DeviceType!=EnumDeviceType.Pager_Communication "
                    class="clsinfraroom" style="cursor:pointer;display:block;" [ngStyle]="{'margin-top': '-40px'}"
                    matSuffix matTooltip={{alert.location}}
                    [matTooltipPosition]="position.value">{{alert.alerttilelocation}}</span>
                  <span *ngIf="alert.locationFlag == 0 && alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                      alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_IN_Battery && 
                      alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_OUT_Battery && 
                      alert.TruViewEventType != EnumAlertEventType.Appliance_UPS_Unplug && 
                      alert.TruViewEventType != EnumAlertEventType.Appliance_LostInternet" class="clsinfraroom"
                    [ngStyle]="{'margin-top': '-40px'}"
                    (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                    style="cursor:pointer;">{{alert.alerttilelocation}} </span>
                  <span *ngIf="alert.locationFlag == 0 && alert.DeviceType!=EnumDeviceType.Pager_Communication && 
                    (alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_IN_Battery || 
                    alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_OUT_Battery ||
                    alert.TruViewEventType == EnumAlertEventType.Appliance_UPS_Unplug || 
                    alert.TruViewEventType == EnumAlertEventType.Appliance_LostInternet)" class="clsinfraroom"
                    [ngStyle]="{'margin-top': '-40px'}"
                    (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                    style="cursor:pointer;">{{alert.alerttilelocation}} </span>
                  <span *ngIf="alert.DeviceType==EnumDeviceType.Pager_Communication" class="clsinfraroom"
                    [ngStyle]="{'margin-top': '-40px'}"
                    (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                    style="cursor:pointer;">Location: NA </span>
                </div>
              </mat-list-item>

            </mat-list>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</mat-sidenav>