import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");
@Component({
  selector: "app-download-liveview-report",
  templateUrl: "./download-liveview-report.component.html",
  styleUrls: ["./download-liveview-report.component.scss"],
})
export class DownloadLiveviewReportComponent implements OnInit {
  TodayDate;

  public isExcelDownload = false;
  public isPDFDownload = false;

  constructor(
    public thisDialogRef: MatDialogRef<DownloadLiveviewReportComponent>,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    const loaderelement = document.getElementsByClassName(
      "loadLiveviewreportspinner"
    );
    loaderelement[0]["style"].display = "none";
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  exportLiveviewReportExcel(Liveviewdata) {
    const loaderelement = document.getElementsByClassName(
      "loadLiveviewreportspinner"
    );
    loaderelement[0]["style"].display = "block";
    setTimeout(() => {
      this.exportLiveview_ReportExcel(Liveviewdata);
    }, 3);
  }

  exportLiveview_ReportExcel(exportData) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isExcelDownload) {
        this.isExcelDownload = true;
        this.TodayDate = new Date();
        this.TodayDate = this.datePipe.transform(this.TodayDate, "yyyy-MM-dd");
        const downloadLiveview = {
          Data: [] as any[],
        };
        if (exportData != undefined && exportData.length > 0) {
          for (let i = 0; i < exportData.length; i++) {
            const item = exportData[i];
            downloadLiveview.Data.push({
              "Asset Name": item.FullGeoName,
              "Tag Id": item.R_TagId,
              "Location Name": item.FullTruLocation,
              "Last Time Stamp ": item.Updatedon,
              "Current State": item.FullTypeName,
              "State Changed By": item.stateChangebyName,
            });
          }
          this.JSONToCSVConvertor(
            downloadLiveview.Data,
            this.TodayDate,
            "",
            true
          );
        } else {
          const loaderelement = document.getElementsByClassName(
            "loadLiveviewreportspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "No data found");
          this.isExcelDownload = false;
        }
      }
    } catch (err) {
      throw new Error(
        "download-liveview-report.component.ts" +
          " - exportLiveviewReportExcel() - " +
          err.message
      );
    }
  }

  JSONToCSVConvertor(JSONData, date, L_Type, ShowLabel) {
    try {
      const arrData =
        typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      let CSV = "";

      //This condition will generate the Label/Header
      if (ShowLabel) {
        let row = "";

        //This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);
        //append Label row with line break
        CSV += row + "\r\n";
      }
      if (arrData.length > 0) {
        //1st loop is to extract each row
        for (let i = 0; i < arrData.length; i++) {
          let row = "";

          //2nd loop will extract each column and convert it in string comma-seprated
          for (const index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }

          row.slice(0, row.length - 1);

          //add a line break after each row
          CSV += row + "\r\n";
        }

        if (CSV == "") {
          const loaderelement = document.getElementsByClassName(
            "loadLiveviewreportspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Invalid data");
          this.isExcelDownload = false;
          return;
        }

        //Generate a file name

        const fileName = "Live View Report Data";

        const myBlob = new Blob([CSV], { type: "text/html" });
        const url = window.URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        const link = document.body.appendChild(a);
        document.body.appendChild(link);
        link.href = url;
        link.download = fileName + "_" + date + ".csv";
        link.click();
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadLiveviewreportspinner"
        );
        loaderelement[0]["style"].display = "none";
        generateAlertMessage(this.dialog, "No data found");
        this.isExcelDownload = false;
      }
      const loaderelement = document.getElementsByClassName(
        "loadLiveviewreportspinner"
      );
      loaderelement[0]["style"].display = "none";
      this.isExcelDownload = false;
    } catch (err) {
      throw new Error(
        "download-liveview-report.component.ts" +
          " - JSONToCSVConvertor() - " +
          err.message
      );
    }
  }

  exportLiveviewReportPdf(Liveviewdata) {
    const loaderelement = document.getElementsByClassName(
      "loadLiveviewreportspinner"
    );
    loaderelement[0]["style"].display = "block";
    setTimeout(() => {
      this.exportLiveview_ReportPdf(Liveviewdata);
    }, 3);
  }

  exportLiveview_ReportPdf(exportdata) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isPDFDownload) {
        this.isPDFDownload = true;

        this.TodayDate = new Date();
        this.TodayDate = this.datePipe.transform(this.TodayDate, "yyyy-MM-dd");
        const infraData = [] as any[];
        let columns = [] as any[];
        if (exportdata.length > 0) {
          columns = [
            { title: "Asset Name", dataKey: "FullGeoName" },
            { title: "Tag Id", dataKey: "TagId" },
            { title: "Location Name", dataKey: "FullTruLocation" },
            { title: "Last Time Stamp", dataKey: "LastTimeStamp" },
            { title: "Current State", dataKey: "CurrentState" },
            { title: "State Changed By", dataKey: "StateChanged" },
          ];
        }

        if (exportdata != undefined && exportdata.length > 0) {
          for (let i = 0; i < exportdata.length; i++) {
            const GeoName = exportdata[i].FullGeoName;
            const TagId = exportdata[i].R_TagId;
            const TruLocation = exportdata[i].FullTruLocation;
            const LastTimeStamp = exportdata[i].Updatedon;
            const StateChanged = exportdata[i].stateChangebyName;
            const Currentstate = exportdata[i].FullTypeName;

            infraData.push({
              FullGeoName: GeoName,
              TagId,
              FullTruLocation: TruLocation,
              LastTimeStamp: LastTimeStamp,
              CurrentState: Currentstate,
              StateChanged: StateChanged,
            });
          }
          const pdf = new jsPDF("l"); // A4 size page of PDF
          let PdfFileName = "";

          const pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

          PdfFileName = "LiveView_Report_Data";
          pdf.text("Live View Report", pageWidth / 2, 10, "center");

          const options = {
            theme: "grid",
            bodyStyles: { lineColor: [0, 0, 0] },
            tableWidth: 'auto',
            columnWidth: 'wrap',
            showHeader: 'everyPage',
            headStyles: {
              lineWidth: 0.2,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
              fillColor: [169, 169, 169],
            },
            columnStyles: {
              FullGeoName: { cellWidth: 35, minCellHeight: 24 },
              TagId: { cellWidth: 20, minCellHeight: 24 },
              FullTruLocation: { cellWidth: 22, minCellHeight: 24 },
              LastTimeStamp: { cellWidth: 22, minCellHeight: 24 },
              CurrentState: { cellWidth: 22, minCellHeight: 24 },
              StateChanged: { cellWidth: 35, minCellHeight: 24 },
            },
            rowPageBreak: "avoid",
          };

          pdf.autoTable(columns, infraData, options);
          PdfFileName = PdfFileName + "_" + this.TodayDate + ".pdf";
          pdf.save(PdfFileName);
        } else {
          const loaderelement = document.getElementsByClassName(
            "loadLiveviewreportspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "No data found");
          this.isPDFDownload = false;
        }
        const loaderelement = document.getElementsByClassName(
          "loadLiveviewreportspinner"
        );
        loaderelement[0]["style"].display = "none";
        this.isPDFDownload = false;
      }
    } catch (err) {
      throw new Error(
        "download-liveview-report.component.ts" +
          " - exportLiveviewReportPdf() - " +
          err.message
      );
    }
  }
}
