<perfect-scrollbar style="max-width: 1200px; max-height: 650px;" >
  <div class="clsmedicalHistory"> 
      <div class="medical-title">
        <h3  class="medicalHeader" style="margin: 0 0 10px 11px;">Medical History
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="checkEMRFile()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>

      <form style="padding:24px" [formGroup]="medicalhistoryaddForm" autocomplete="off" novalidate >
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
            
          <div fxFlex="43" fxFlex.md="43" class="clstextarea">           
            <mat-grid-list cols="5" rowHeight="150px">
              <mat-grid-tile>
                <label>Notes</label>
            </mat-grid-tile>
          
            <mat-grid-tile colspan="4">
              <div class="text-inside" style="width:100%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;" class="clstextareacontent">
                      <textarea matInput placeholder="Notes" formControlName="notes" id="txtMedNotes"  [(ngModel)]="medhistorynotes" style="height:100px;resize: none;" (keyup)="handleInput($event)" required></textarea>                         
                      <mat-error *ngIf="medicalhistoryaddForm.controls.notes.hasError('required')">
                          Please enter Notes
                        </mat-error>
                    </mat-form-field>
                  </div>
            </mat-grid-tile> 

              <mat-grid-tile>
                  <label >Allergies</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="4">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;" class="clstextareacontent">
                        <textarea matInput placeholder="Allergies" formControlName="allergies" id="txtMedallergies" [(ngModel)]="medhistoryallergies" style="height:100px;resize: none;" (keyup)="handleInput($event)" required></textarea>                         
                        <mat-error *ngIf="medicalhistoryaddForm.controls.allergies.hasError('required')">
                            Please enter Allergies
                          </mat-error>
                      </mat-form-field>
                    </div>
              </mat-grid-tile>                 
              
            </mat-grid-list>
          </div>
          <div fxFlex="55" fxFlex.md="55">
           
              <mat-grid-list [cols]="columns" [rowHeight]="medrowHeight" style="margin-top:1em;">                 
              <mat-grid-tile>
                  <label>Emergency Contact Name</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                        <input matInput placeholder="Contact Name" formControlName="emrcontactname" maxlength="30" [(ngModel)]="medhistorycontname" (keyup)="handleInput($event)" required>
                        <mat-error *ngIf="medicalhistoryaddForm.controls.emrcontactname.hasError('required')">
                          Please enter Emergency Contact Name
                        </mat-error>
                        <mat-error *ngIf="medicalhistoryaddForm.controls.emrcontactname.hasError('pattern')">
                          Please enter valid Emergency Contact Name
                        </mat-error>
                        <mat-error *ngIf="medicalhistoryaddForm.controls.emrcontactname.hasError('maxlength')">
                          Max length 30 characters only
                        </mat-error>
                      </mat-form-field>
                    </div>
              </mat-grid-tile>  

              <mat-grid-tile>
                <label>Emergency Contact Number</label>
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                        <input matInput placeholder="Contact Number" formControlName="emrcontactnumber" maxlength="12" [(ngModel)]="medhistorycontnumber" (keyup)="handleInput($event)" required>
                        <mat-error *ngIf="medicalhistoryaddForm.controls.emrcontactnumber.hasError('required')">
                          Please enter Emergency Contact Number
                        </mat-error>
                        <mat-error *ngIf="medicalhistoryaddForm.controls.emrcontactnumber.hasError('pattern')">
                          Please enter valid Emergency Contact Number
                      </mat-error>
                        <mat-error *ngIf="medicalhistoryaddForm.controls.emrcontactnumber.hasError('maxlength') || medicalhistoryaddForm.controls.emrcontactnumber.hasError('minlength')">
                          Emergency Contact Number 11 to 12 digits
                        </mat-error>
                      </mat-form-field>
                    </div>
              </mat-grid-tile>  
            </mat-grid-list>

            <mat-grid-list [cols]="columns" [rowHeight]="medrowHeight" style="margin-top:1em;"> 
            <mat-grid-tile>
              <label>Emergency Contact Email</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <div class="text-inside" style="width:100%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="Contact Email" formControlName="emrcontactemail" maxlength="60" [(ngModel)]="medhistoryemail" (keyup)="handleInput($event)" required>
                      <mat-error *ngIf="medicalhistoryaddForm.controls.emrcontactemail.hasError('required')">
                        Please enter Emergency Contact Email
                      </mat-error>
                      <mat-error *ngIf="medicalhistoryaddForm.controls.emrcontactemail.hasError('email') || medicalhistoryaddForm.controls.emrcontactemail.hasError('pattern')">
                        Please enter valid Emergency Contact Email
                      </mat-error>
                    </mat-form-field>
                  </div>
            </mat-grid-tile>

            <mat-grid-tile>
              <label>Import EMR</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" [ngStyle]="{'width': bResidentAddEdit === true ? '50%' : '100%'}">
                      <input matInput placeholder="EMR" formControlName="emportemr" [(ngModel)]="medEMRfilename" />                                              
                    </mat-form-field>                                               
                      <button mat-raised-button color="primary" (click)="changebuttonvalue($event)" *ngIf="bResidentAddEdit==true" id="resaddmedbutton" class="clsresmedaddbutton" >{{btnvalue}}</button>
                      <input type="file" class="clschoosebutton text-inside-grid" placeholder="EMR File" id="clkfileupload" #clkimageInput (change)="onChangeEMR($event.target.files)" required accept=".pdf" />                           
                  </div>
              </mat-grid-tile>
           </mat-grid-list>
          </div>          
        
 </div>
 <div>
<perfect-scrollbar style="max-width: 1200px; max-height: 160px;margin-top:1em;">
  <div id="divAddDeviceheader" class="clstablecontent" [ngStyle]="{'display': uploademrfilevalue === 1 ? 'block' : 'none'}">
    <table mat-table [dataSource]="dataSource" sort>

      <ng-container matColumnDef="FileName">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> File Name </th>
        <td mat-cell *matCellDef="let element" style="width:200px;word-break: break-all;">{{element.fileName}}</td>
      </ng-container>

      <ng-container matColumnDef="ProcedureDate">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> Procedure Date </th>
        <td mat-cell *matCellDef="let element">
          <input style="width:100px;margin-bottom:0.5em;" id="Prodatepicker" [matDatepicker]="picker1" [min]="startDate" [max]="getToday()" (dateChange)="changeValue($event,0)">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker> {{element.procedureDate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Source">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> Source </th>
        <td mat-cell *matCellDef="let element" contenteditable="false">
          <input type="text" (input)="changeValue($event,1)" id="myInput" maxlength="30"> {{element.source}}                    
        </td>
      </ng-container>

      <ng-container matColumnDef="UploadDate">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> Upload Date </th>
        <td mat-cell *matCellDef="let element"> {{element.uploadDate}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
</perfect-scrollbar>
</div>
<div>
  <perfect-scrollbar style="max-width: 1200px; max-height: 155px;margin-top:1em;">
    <div id="divDeviceheader" class="clstablecontent" [ngStyle]="{'display': dataSource_ExistLength===0 ? 'none' : 'block'}">
      <table mat-table [dataSource]="dataSource_Exist" sort>

        <ng-container matColumnDef="FileName">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:400px;"> File Name </th>
          <td mat-cell *matCellDef="let element" style="word-break: break-all;padding-right:10px;"> {{element.FileName}} </td>
        </ng-container>

        <ng-container matColumnDef="ProcedureDate">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> Procedure Date </th>
          <td mat-cell *matCellDef="let element"> {{element.ProcedureDate}} </td>
        </ng-container>

        <ng-container matColumnDef="Source">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> Source </th>          
          <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.Source.length < 15">{{element.Source}}</span>
            <span *ngIf="element.Source.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.Source}} #tooltip="matTooltip" matTooltipPosition="above">
              {{element.Source.slice(0,15) + ".."}}
            </span>        
             </td>
        </ng-container>

        <ng-container matColumnDef="UploadDate">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> Updated Date </th>
          <td mat-cell *matCellDef="let element"> {{element.UploadDate}} </td>
        </ng-container>

        <ng-container matColumnDef="EMR">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> EMR </th>
          <ng-container *matCellDef="let element">
            <td mat-cell><img style="width:15px;" src="assets/images/Information-Medical History-EMR.png" (click)="onOpenEMRReport(element.FileName)"></td>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="Delete" >
          <th mat-header-cell *matHeaderCellDef [ngClass]="'headercell'" style="width:200px;"> Delete </th>
          <ng-container *matCellDef="let element" >
            <td mat-cell> <button mat-button *ngIf="bResidentAddEdit==true" (click)="deleteEMRFile(element.ReportId,element.ResidentId,element.FileName)">
                <mat-icon>remove</mat-icon>
              </button> </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="EMRreportdisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: EMRreportdisplayedColumns;"></tr>

      </table>
    </div>

    <ng-template #noRecord>
      <mat-card>
        <h3 style="text-align: center;"> No Record found </h3>
      </mat-card>
    </ng-template>
  </perfect-scrollbar>

</div>
 <mat-dialog-actions class="clsbuttoncontrol">      
  <button type="button" class="clsbtnmedCancel" mat-raised-button (click)="checkEMRFile()" [ngStyle]="{'margin-bottom': uploademrfilevalue === 0 && updateflag === 1 ? '1em' : '0em'}">Cancel</button>
  <button class="clsbtnmedSave" mat-raised-button [disabled]="!medsavebutton" (click)="newMedHistoryAdd(medicalhistoryaddForm.value)" *ngIf="bResidentAddEdit==true" [ngStyle]="{'margin-bottom': uploademrfilevalue === 0 && updateflag === 1 ? '1em' : '0em'}">Save</button>&nbsp;
</mat-dialog-actions>
</form>      
    </div>

</perfect-scrollbar>
