import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { Subject } from "rxjs";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { StaffService } from "../staff.service";
import {
  AuthorizeList,
  StaffForCreationResponse,
  DeleteStaff,
  getNotifyStaffRole,
  getAlertByStaffId,
} from "../staff";
import { appsettings } from "../../../app-settings";
import { HttpClient } from "@angular/common/http";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../app-general";
import { Router } from "@angular/router";
import { ApiService } from "../../../api.service";
import { takeUntil } from "rxjs/operators";
import { checkAlertForTag } from "../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-delete-staff",
  templateUrl: "./delete-staff.component.html",
  providers: [StaffService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./delete-staff.component.scss"],
})
export class DeleteStaffComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 3;
  public staffDeleteForm: UntypedFormGroup;
  private URL = appsettings.URLinfo + "getAuthorizer";
  public staffAuthorized: AuthorizeList[];
  currentDate;
  staffname;
  DesignationId = 0;
  authorizedName;
  staffTagid = 0;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
  constructor(
    private router: Router,    
    private staffService: StaffService,
    public thisDialogRef: MatDialogRef<DeleteStaffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    public dialog: MatDialog,
    private http: HttpClient,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged()); 
          
    this.staffname = data[0].FirstName + " " + data[0].LastName;
    if (data[0].DesignationId != "")
      this.DesignationId = parseInt(data[0].DesignationId);
    if (data[0].TagId != "") this.staffTagid = parseInt(data[0].TagId);
  }

  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }
  
  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.getAuthorizedList();
    this.currentDate = new Date().toJSON("yyyy/MM/dd");
    this.staffDeleteForm = new UntypedFormGroup({
      staffname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      staffId: new UntypedFormControl(""),
      date: new UntypedFormControl(""),
      staffreason: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      authorizedby: new UntypedFormControl("", [Validators.required]),
    });
    const staffDeleteForm_staffname = this.staffDeleteForm.get("staffname");
    if (staffDeleteForm_staffname != null) staffDeleteForm_staffname.disable();

    const staffDeleteForm_date = this.staffDeleteForm.get("date");
    if (staffDeleteForm_date != null) staffDeleteForm_date.disable();

    const staffDeleteForm_authorizedby =
      this.staffDeleteForm.get("authorizedby");
    if (staffDeleteForm_authorizedby != null)
      staffDeleteForm_authorizedby.disable();
    // this.staffDeleteForm.get('date').disable();
    // this.staffDeleteForm.get('authorizedby').disable();
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.staffDeleteForm.controls).forEach((field) => {
        // {1}
        const control = this.staffDeleteForm.get(field); // {2}
        const staffTag_Id = document.getElementById(event.target.id);
        if (staffTag_Id != null) {
          if (field == staffTag_Id.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "delete-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  getAuthorizedList() {
    try {
      const _userName = localStorage.getItem("username");
      if (_userName != null) this.authorizedName = JSON.parse(_userName);
    } catch (err) {
      throw new Error(
        "delete-staff.component.ts" + " - getAuthorizedList() - " + err.message
      );
    }
  }

  public deleteStaffbyId = (deleteFormValue) => {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    if (this.staffDeleteForm.valid) {
      const alerttagidfoundItem: checkAlertForTag = {
        authKey: this.authkey,
        oldTagId: this.staffTagid,
        isResStaff: 0,
      };
      this.apiService
        .checkAlertForTag(alerttagidfoundItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "not found") {
            this.getStaffRole(deleteFormValue);
          } else {
            generateAlertMessage(
              this.dialog,
              "The staff " +
                this.staffname +
                " has open alerts, please complete or cancel those alerts and try again."
            );
            this.thisDialogRef.close("Close");
            return;
          }
        });
    }
  };

  getStaffRole(deleteFormValue) {
    try {
      const RolefoundItem: getNotifyStaffRole = {
        authKey: this.authkey,
        role: this.DesignationId,
        staffId: parseInt(deleteFormValue.staffId),
      };
      this.staffService
        .getStaffRole(RolefoundItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiroleresponse: any[]) => {
          if (apiroleresponse != null && apiroleresponse != undefined) {
            if (apiroleresponse.toString() == "Success") {
              this.getAcceptAndPresenceAlertsByStaffId(deleteFormValue);
            } else {
              generateAlertMessage(
                this.dialog,
                "This " +
                  apiroleresponse[0].Designation +
                  " is in escalation level, so please remove this role from escalation and try again."
              );
            }
          } else {
            generateAlertMessage(this.dialog, "Staff creation failed !");
          }
        });
    } catch (err) {
      throw new Error(
        "delete-staff.component.ts" + " - getStaffRole() - " + err.message
      );
    }
  }

  private getAcceptAndPresenceAlertsByStaffId = (releaseFormValue) => {
    try {
      const staffid: getAlertByStaffId = {
        authKey: this.authkey,
        staffId: parseInt(releaseFormValue.staffId),
      };
      this.staffService
        .getAcceptAndPresenceAlertsByStaffId(staffid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((alertdata: any) => {
          if (alertdata.toString() == "not found") {
            this.deleteStaffData(releaseFormValue);
          } else {
            if (alertdata.toString() == "Staff in Alert") {
              generateAlertMessage(
                this.dialog,
                "This staff " +
                  this.staffname +
                  " has accepted alerts and not completed. Upon releasing, please <br> complete or cancel those alerts and try again.?"
              );
            } else if (alertdata.toString() == "Staff in Notification") {
              generateAlertMessage(
                this.dialog,
                "This " +
                  this.staffname +
                  " is in escalation level. so please remove this <br> staff from the escalation and try again."
              );
            }

            this.thisDialogRef.close("Close");
          }
        });
    } catch (err) {
      throw new Error(
        "delete-staff.component.ts" +
          " - getAcceptAndPresenceAlertsByStaffId() - " +
          err.message
      );
    }
  };

  deleteStaffData(releaseFormValue) {
    try {
      const userid = localStorage.getItem("userid") ?? "";
      const deleteStaff: DeleteStaff = {
        authKey: this.authkey,
        reason: releaseFormValue.staffreason.replace(/\s+/g, " ").trim(),
        authorizedBy: parseInt(userid),
        staffId: parseInt(releaseFormValue.staffId),
      };

      this.staffService
        .deleteStaffData(deleteStaff)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: StaffForCreationResponse[]) => {
          if (apiresponse != null && apiresponse != undefined) {
            if (apiresponse["apiReturn"] == "Success") {
              this.apiService.reloadStaff();
              generateAlertMessage(
                this.dialog,
                "Staff data deleted successfully."
              );
            } else if (apiresponse["apiReturn"] == "Fail") {
              generateAlertMessage(this.dialog, "Staff data deleted failed.");
            } else if (apiresponse["apiReturn"] == "Main") {
              generateAlertMessage(
                this.dialog,
                "Super user could not allowed to delete."
              );
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
          } else {
            generateAlertMessage(this.dialog, "Staff data deleted failed.");
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "delete-staff.component.ts" + " - deleteStaffData() - " + err.message
      );
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
