import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertSharedService } from '../../_services/index';
import { ShareInfraopenMsg } from '../../api';
import { MainLayoutService } from '../main-layout.service';
@Component({
  selector: 'app-mobile-bottombar',
  templateUrl: './mobile-bottombar.component.html',
  styleUrls: ['./mobile-bottombar.component.scss']
})
export class MobileBottombarComponent implements OnInit, AfterViewChecked {
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(private router: Router, private alertSharedService: AlertSharedService,
    public mainLayoutService: MainLayoutService, ) {
    const elementbottom = document.getElementsByClassName('mobilebottom-toolbar');
    if(elementbottom.length>1){ 
      elementbottom[1]["style"].width = "0%";
      elementbottom[1]["style"].padding = "0px";
    }
  }
  CheckClickedItem = 0;
  ViewChecked = 0;
  ShareInfraopenDetails : ShareInfraopenMsg;
  Isinfra = 0;
  
  ngOnInit() {
    this.alertSharedService.currentsharedInfraAlert().pipe(takeUntil(this._onDestroy)).subscribe((ShareInfraopenDetails: any) => {
      this.ShareInfraopenDetails = ShareInfraopenDetails;
      if (this.ShareInfraopenDetails != undefined) {
        this.Isinfra = this.ShareInfraopenDetails.IsfraOpen;
        const elementbottom = document.getElementsByClassName('mobilebottom-toolbar');
        if (elementbottom.length > 1) {
          if (this.Isinfra == 1) {
            elementbottom[1]["style"].width = "0%";
            elementbottom[1]["style"].padding = "0px";
          }
          else {
            elementbottom[1]["style"].width = "100%";
            elementbottom[1]["style"].padding = "";
          }
        }
      }
    });
  }

  ngAfterViewChecked() {
    const width=window.innerWidth;//Math.min(document.documentElement.clientWidth, window.innerWidth || 0);   
    if (width > 500) {
      const elementAlert = document.getElementsByClassName('card-container-left');
      if(elementAlert != undefined && elementAlert.length>0  )
      {
        elementAlert[0]["style"].display = "";
        elementAlert[0]["style"].width = "";
      }
      const elementhome = document.getElementsByClassName('card-container-right');
      if(elementAlert != undefined  && elementAlert.length>0)
      {
        elementhome[0]["style"].display = "";
        elementhome[0]["style"].width = "";
      }
      this.CheckClickedItem = 0;
      this.ViewChecked = 1;
    }
    else if (width <= 500 && this.ViewChecked == 0 && this.CheckClickedItem == 0) {
      this.ViewChecked = 1;
    }
    else if (this.ViewChecked == 1 && this.CheckClickedItem == 0) {
      this.CheckClickedItem = 1;
      this.CheckIconClicked();
    }
  }

  homeToggle(event) {
    if (event.target.id == "btnAlertIcon") {
      this.CheckClickedItem = 1;
      this.CheckIconClicked();
    }
    else if (event.target.id == "btnHomeIcon") {
      this.CheckClickedItem = 2;
      this.CheckIconClicked();
    } 
  }

  CheckIconClicked() {
    this.ViewChecked = 0;
    if (this.CheckClickedItem == 1) {
      const elementAlert = document.getElementsByClassName('card-container-left');
      const alertstyle = getComputedStyle(elementAlert[0]);
      if (alertstyle.display == "none") {
        elementAlert[0]["style"].display = "block";
        elementAlert[0]["style"].width = "100%";
      }

      const elementhome = document.getElementsByClassName('card-container-right');
      const style = getComputedStyle(elementhome[0]);
      if (this.router.url != "/login" && style.display == "block") {
        elementhome[0]["style"].display = "none";
        elementhome[0]["style"].width = "0%";
      }
    }
    else if (this.CheckClickedItem == 2) {
      const elementAlert = document.getElementsByClassName('card-container-left');
      const alertstyle = getComputedStyle(elementAlert[0]);
      if (alertstyle.display == "block") {
        elementAlert[0]["style"].display = "none";
        elementAlert[0]["style"].width = "0%";
      }

      const elementhome = document.getElementsByClassName('card-container-right');
      const style = getComputedStyle(elementhome[0]);
      if (style.display == "none") {
        elementhome[0]["style"].display = "block";
        elementhome[0]["style"].width = "100%";
      }
      this.router.navigate(['/main']);
      const el = document; // This can be your element on which to trigger the event
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', true, false);
      el.dispatchEvent(event);
    }
  }

}
