<app-report-header  [ReportType]="ReportType"></app-report-header>
<mat-card class="report-mat-card" (click)="CloseRightNav()">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-report-leftbar ></app-report-leftbar>
    </div>
      
    <div fxLayout="column" style="width:100%;">
    <!--Loction History Report-->
    <div class="table-container">     
        <div class="main-table"> 
        <div class="landing-title-bar">
          <div class="blocks" fxLayout="column" fxLayoutAlign="left">
            <mat-card-header>
              <mat-card-title style="font-size: 20px;margin-top:15px;">
                <span class="clsTitle">Location History Report (Past 7 Days)</span>
              </mat-card-title>
            </mat-card-header>
          </div>
        </div> 

        <perfect-scrollbar class="chartscrollbarLocHistory">
        <div fxLayout.md="column" fxLayout.sm="column">            
          <span class="clsspan">Type</span>                      
          <mat-form-field id="selectType"  appearance="outline" floatLabel="auto" class="clsresmatformfield">
            <mat-select placeholder="Select Type"  [(ngModel)]="selectedType" (selectionChange)="selectReportType($event.value)">
                <mat-option value="Resident">Resident</mat-option>                 
                <mat-option value="Staff">Staff</mat-option> 
            </mat-select>
          </mat-form-field> 
        
        <span class="clsspan">{{LocationSelectType}}</span>                      
        <mat-form-field id="selectLocationType"  appearance="outline" floatLabel="auto" class="clsresmatformfield">
          <mat-select placeholder="{{P_SelectType}}"  [(ngModel)]="selectedResident" (selectionChange)="selectReportResident($event.value)">
             <mat-option value="All">All</mat-option>
            <mat-option *ngFor="let Resident of resoptions" [value]="Resident.TagId" title="{{ Resident.Name.length >= 40 ? Resident.Name : ''}}">
              {{Resident.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>             
        <button class="clsLoctionDownloadbutton" color="primary" mat-raised-button (click)="locationHistoryReportdata()">Download</button>                           
      </div>       
    
        <div *ngIf="LocationdataSource  else noLocationRecord" style="margin-top:20px;">
          <table mat-table [dataSource]="LocationdataSource" matSort (matSortChange)="sortData($event)" #LocHistsort="matSort" matSortDisableClear=true>                 

              <!-- ResidentId Column -->
              <ng-container matColumnDef="ResidentId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{LocationTypeId}} </th>
                <td mat-cell *matCellDef="let element"> {{element.ResidentId}} </td>
              </ng-container>

              <!-- ResidentName Column -->
            <ng-container matColumnDef="ResidentName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{LocationTypeName}} </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.ResidentName.length < 30">{{element.ResidentName}}</span>
                <span *ngIf="element.ResidentName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.ResidentName}} #tooltip="matTooltip" matTooltipPosition="above">
                {{element.ResidentName.slice(0,20) + ".."}}</span>
              </td>
            </ng-container>
         
             <!-- RoomId Column -->
             <ng-container matColumnDef="RoomId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Room Id </th>
              <td mat-cell *matCellDef="let element"> {{element.RoomId}} </td>
            </ng-container>

            <!-- RoomName Column -->
            <ng-container matColumnDef="RoomName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Room Name </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.RoomName.length < 30">{{element.RoomName}}</span>
                <span *ngIf="element.RoomName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.RoomName}} #tooltip="matTooltip" matTooltipPosition="above">
                {{element.RoomName.slice(0,20) + ".."}}</span>
              </td>
            </ng-container>

              <!-- UpdatedOn Column -->
              <ng-container matColumnDef="UpdatedOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated On</th>
                <td mat-cell *matCellDef="let element"> {{element.UpdatedOn}} </td>
              </ng-container>                  
                        
            <tr mat-header-row *matHeaderRowDef="displayedLocationColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns:displayedLocationColumns;"></tr>
          </table>

          <h3 *ngIf="LocationdataSource.data.length == 0 && !locationloading" style="text-align: center;margin-top:1em;"> No Record found </h3>

        </div>

        <ng-template #noLocationRecord>           
            <!-- <h3 *ngIf="!locationloading " style="text-align: center;margin-top:1em;"> No Record found </h3> -->
              <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%" *ngIf="locationloading">       
                  <div class="loadspinner" *ngIf="locationloading">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                  </div>
              </div>            
        </ng-template>

        <mat-paginator   [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
        </perfect-scrollbar>
    </div>
    </div>
    </div>
    <!--End-->     



  </div>
</mat-card>