<perfect-scrollbar style="max-width: 1200px; max-height: 250px;" *ngIf="Tempflag == 0">
  <div class="clsaddCampus">
      <div class="addfloortemp-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addfloortempHeader" style="margin: 0 0 10px 11px;">Choose a Template
        <span><img class="clsaddtempcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="floorTempateForm" autocomplete="off" novalidate (ngSubmit)="selecttemplatefloorDetails(floorTempateForm.value)">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div>                   
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                    <mat-grid-tile class="clscampusmatgrid">
                      <label>Template</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clstemplatematgridtxt">
                          <div class="text-inside"> 
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsfloortempaddtxt">                              
                              <mat-select formControlName="template" placeholder="Select Template">    
                                <mat-option *ngFor="let Template of Templatedetails" [value]="Template.TemplateId" (click)="changeTemplate(Template.TemplateId);">
                                  {{Template.TemplateName}}-{{Template.Description}}
                                </mat-option>
                              </mat-select> 
                              <mat-error>Please select Template</mat-error>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>  
                  </mat-grid-list>
                
              </div>                                      
        
 </div>
 <mat-dialog-actions class="clsaddtempbuttoncontrol">      
  <button type="button" class="clstempcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnsavenextclick" class="clstempsavebutton" mat-raised-button>Next</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>


<perfect-scrollbar style="max-width: 1200px; max-height: 400px;" *ngIf="Tempflag == 1">
  <div class="clsaddCampus">
      <div class="addfloortemp-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addfloortempHeader" style="margin: 0 0 10px 11px;">Choose a Template
        <span><img class="clsaddtempcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="flooraddTempateForm" autocomplete="off" novalidate (ngSubmit)="addtemplatefloorDetails(flooraddTempateForm.value)">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >                          
              
              <div>
                <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                  <mat-grid-tile class="clscampusmatgrid">
                    <label>Campus</label>
                </mat-grid-tile>
                <mat-grid-tile class="clstemplatematgridtxt">
                        <div class="text-inside"> 
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsfloortempaddtxt">                              
                            <mat-select formControlName="campus" placeholder="Select Campus" [(ngModel)]="selectedCampusId" (selectionChange)="getBuildingDetails($event.value)">    
                              <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId" (click)="changecampus_Template(Campus.CampusId);">
                                {{Campus.CampusName}}
                              </mat-option>
                            </mat-select> 
                            <mat-error>Please select Campus</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>  
                
                <mat-grid-tile class="clscampusmatgrid">
                  <label>Building Location</label>
              </mat-grid-tile>
              <mat-grid-tile class="clstemplatematgridtxt">
                      <div class="text-inside"> 
                        <mat-form-field appearance="outline" floatLabel="auto" class="clsfloortempaddtxt">
                          <mat-select formControlName="building" placeholder="Select Building" [(ngModel)]="selectBuildId">    
                            <mat-option *ngFor="let Building of Buildingdetails" [value]="Building.BuildId" (click)="changebuilding_Template(Building.BuildId);">
                              {{Building.BuildingName}}
                            </mat-option>
                          </mat-select> 
                          <mat-error>Please select Building</mat-error>
                      </mat-form-field>
                    </div>
              </mat-grid-tile>  
              
              <mat-grid-tile class="clscampusmatgrid">
                <label>Selected Zone</label>
              </mat-grid-tile>
              <mat-grid-tile class="clstemplatematgridtxt">
                    <div class="text-inside">
                      <mat-form-field appearance="outline" floatLabel="auto" class="clsfloortempaddtxt">
                       <input matInput placeholder="Zone Name" formControlName="floorname" maxlength="32" (keyup)="handleInput($event)" required/>
                       <mat-error *ngIf="flooraddTempateForm.controls.floorname.hasError('required')">Please enter Zone</mat-error>  
                       <mat-error *ngIf="flooraddTempateForm.controls.floorname.hasError('pattern')">Please enter valid Zone Name</mat-error>
                      <mat-error *ngIf="flooraddTempateForm.controls.floorname.hasError('maxlength')">Max length 32 characters only</mat-error>                   
                    </mat-form-field>
                  </div>
              </mat-grid-tile>   
              
                </mat-grid-list>
              </div>
          
        
 </div>
 <mat-dialog-actions class="clsaddtempbuttoncontrol">      
  <button type="button" class="clstempcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnsavetemplateaddclick" class="clstempsavebutton" mat-raised-button>Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>