import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { ChartType } from "chart.js";
import { Label } from "ng2-charts";
import { ReportService } from "../report.service";
import {
  getLocationType,
  getCustomizedGeo,
  addLiveChangeState,
} from "../report";
import {
  report_leftmenu,
  NewDeviceTypeDesc,
  enumReportState,
} from "../../../app-settings";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheckwithStaff,
  generateAlertMessage,
} from "../../../app-general";
import { DownloadLiveviewReportComponent } from "../download-liveview-report/download-liveview-report.component";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { ReportHeaderComponent } from "../report-header/report-header.component";
import { AlertSharedService } from "../../../_services/index";
import { ApiService } from "../../../api.service";
import { getStaffData } from "../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

declare let Chart: any;

@Component({
  selector: "app-liveview",
  templateUrl: "./liveview.component.html",
  styleUrls: ["./liveview.component.scss"],
})
export class LiveviewComponent implements OnInit {
  ReportType = report_leftmenu.liveviewReport;
  private _onDestroy: Subject<void> = new Subject<void>();
  public EnumNewDeviceType = NewDeviceTypeDesc;
  public EnumReportState = enumReportState;
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  liveviewinterval;
  IsRefreshNeed = 1;
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    private reportService: ReportService,
    public dialog: MatDialog,
    public mainLayoutService: MainLayoutService,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {   
      this.breakpoint$.subscribe(() => this.breakpointChanged());     
}

breakpointChanged() {    
  if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
    this.setupliveFullTable();
  } 
  else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
    this.setupliveTableformidwidth();
  }     
  else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
    this.setupliveTableforsmallerwidth();
  } 
  else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
    this.setupliveTableforXtrasmallerwidth();
  }
}

  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  displayedLiveViewColumns: string[] = [
    "GeoName",
    "R_TagId",
    "TruLocation",
    "Updatedon",
    "TypeName",
    "stateChangebyName",
    "roomcolor",
  ];

  todaydate;
  Todaydate;
  liveviewloading = true;
  LiveViewReport;
  LiveViewSource;
  TotalLiveViewSource;
  cusloc_selectStateBy;
  selectedLocationType;
  downloadLiveviewdata;
  LiveviewData = [] as any[];
  TotalNumofLiveView;
  LiveViewReportCount;
  LocationType;
  Pop_LocationData: any[];
  chkLiveviewsearchvalue;
  CusLocfilteredArray = [] as any[];
  cusloc_selstatechange = [] as any[];
  IsLoaded = 0;
  IsFilter = 0;
  IsFilterData = "";
  authkey;
  @ViewChild("searchLiveViewInput") searchLiveViewInput: ElementRef;
  @ViewChild("LiveViewsort") LiveViewsort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  // multi Doughnut start
  public LiveViewdoughnutChartLabels1: Label[] = [
    "Alert 3",
    "Alert 2",
    "Alert 1",
  ];
  public LiveViewdonutColors1 = [] as any[];
  public LiveViewoptions1 = {};
  public LiveViewdatasets: Array<any> = [];
  public LiveViewdoughnutChartType1: ChartType = "doughnut";

  //LiveView Report///
  setupliveTableforXtrasmallerwidth() {
    this.displayedLiveViewColumns = ["GeoName", "R_TagId", "Updatedon"];
  }
  setupliveTableforsmallerwidth() {
    this.displayedLiveViewColumns = ["GeoName", "R_TagId", "Updatedon"];
  }
  setupliveTableformidwidth() {
    this.displayedLiveViewColumns = [
      "GeoName",
      "R_TagId",
      "TruLocation",
      "Updatedon",
      "TypeName",
    ];
  }
  setupliveFullTable() {
    this.displayedLiveViewColumns = [
      "GeoName",
      "R_TagId",
      "TruLocation",
      "Updatedon",
      "TypeName",
      "stateChangebyName",
      "roomcolor",
    ];
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.todaydate = new Date();
    this.Todaydate =
      this.monthNames[this.todaydate.getMonth()] +
      " " +
      ("0" + this.todaydate.getDate()).slice(-2) +
      ", " +
      this.todaydate.getFullYear();
    this.getLocationType();
    this.getCustomizedGeo("");

    this.liveviewinterval = setInterval(() => {
      this.todaydate = new Date();
      this.Todaydate =
        this.monthNames[this.todaydate.getMonth()] +
        " " +
        ("0" + this.todaydate.getDate()).slice(-2) +
        ", " +
        this.todaydate.getFullYear();
      if (this.IsFilter == 0) {
        this.getCustomizedGeo("");
      } else {
        this.getCustomizedGeo(this.IsFilterData);
      }
      this.getStaffData();
    }, 1000 * 60 * 1);
  }

  getLocationType() {
    try {
      this.liveviewloading = true;
      const locationtypeitem: getLocationType = {
        authKey: this.authkey,
      };
      this.reportService
        .getLocationType(locationtypeitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((LocationTypeReport: any[]) => {
          this.LocationType = LocationTypeReport;
          localStorage.setItem("Clean", this.LocationType[0].TypeName);
          localStorage.setItem("InUse", this.LocationType[1].TypeName);
          localStorage.setItem("Dirty", this.LocationType[2].TypeName);
          localStorage.setItem("Unknown", this.LocationType[3].TypeName);
        });
      this.getStaffData();
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getLocationType() - " + err.message
      );
    }
  }

  getStaffData() {
    try {
      const staffdata: getStaffData = { authKey: this.authkey };
      this.apiService
        .getStaffData(staffdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Staffdetails: any[]) => {
          this.cusloc_selstatechange = Staffdetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getStaffData() - " + err.message
      );
    }
  }

  getCustomizedGeo(filtervalue) {
    try {
      if (this.IsFilterData == "0") {
        this.cusloc_selectStateBy = 0;
        this.selectedLocationType = 0;
      }
      this.liveviewloading = true;
      const dateformat = localStorage.getItem("dateFormat") ?? "";
      const customgeoitem: getCustomizedGeo = {
        authKey: this.authkey,
        dateFormat: dateformat,
      };
      this.reportService
        .getCustomizedGeo(customgeoitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((LiveViewData: any[]) => {
          this.liveviewloading = false;
          this.LiveViewReport = LiveViewData;

          this.LiveViewSource = new MatTableDataSource(this.LiveViewReport);
          this.LiveViewSource = new MatTableDataSource(this.LiveViewReport);
          this.TotalLiveViewSource = new MatTableDataSource(
            this.LiveViewReport
          );
          this.downloadLiveviewdata = new MatTableDataSource(
            this.LiveViewReport
          );
          this.LiveViewSource.data = LiveViewData;
          this.LiveViewSource.data = LiveViewData;
          this.TotalLiveViewSource.data = LiveViewData;

          this.downloadLiveviewdata.data = this.LiveViewReport;
          this.LiveViewSource.paginator = this.paginator.toArray()[0];
          setTimeout(() => {
            this.LiveviewData = [];
            this.LiveViewSource.sort = this.LiveViewsort;
            this.sortingDatalist_LiveViewReport(this.LiveViewSource);
            this.downloadLiveviewdata.sort = this.LiveViewsort;
            this.sortingDatalist_LiveViewReport(this.downloadLiveviewdata);
            this.downloadLiveviewdata
              .connect()
              .subscribe((data) => (this.LiveviewData = data));
          }, 0);
          if (
            filtervalue != undefined &&
            filtervalue != null &&
            filtervalue != ""
          ) {
            // if filter value then we referesh new alert need to retain the filter.
            this.getfilterLiveViewdetails();
          }
          this.loadliveviewchart();
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getCustomizedGeo() - " + err.message
      );
    }
  }

  loadliveviewchart() {
    try {
      let liveviewData = [] as any[];
      let Avg_count1 = 0,
        Avg_count2 = 0,
        Avg_count3 = 0,
        Avg_count4 = 0,
        Avg_count5 = 0;
      liveviewData = this.TotalLiveViewSource.data;
      this.TotalNumofLiveView = liveviewData.length;
      if (liveviewData.length == 0) this.LiveViewReportCount = "";
      else if (liveviewData.length == 1)
        this.LiveViewReportCount = liveviewData.length + " TruLocator";
      else this.LiveViewReportCount = liveviewData.length + " TruLocators";

      for (let i = 0; i < liveviewData.length; i++) {
        if (liveviewData[i].roomcolor == enumReportState.Clean)
          //Green
          Avg_count1 = Avg_count1 + 1;
        else if (liveviewData[i].roomcolor == enumReportState.InUse)
          //Yellow
          Avg_count2 = Avg_count2 + 1;
        else if (liveviewData[i].roomcolor == enumReportState.Dirty)
          //Red
          Avg_count3 = Avg_count3 + 1;
        else if (liveviewData[i].roomcolor == enumReportState.Unknown)
          //Gray
          Avg_count4 = Avg_count4 + 1;
      }
      Avg_count5 = liveviewData.length;

      Chart.defaults.global.legend.labels.usePointStyle = true;

      const Alert1 = Avg_count1;
      const Alert2 = Avg_count2;
      const Alert3 = Avg_count3;
      const Alert4 = Avg_count4;
      const Alert5 = Avg_count5;

      let temp1,
        temp2,
        temp3,
        temp4 = 0;
      if (Alert4 == 0) temp1 = 0;
      else temp1 = Math.round((Alert4 / Alert5) * 100);

      if (Alert3 === 0) temp2 = 0;
      else temp2 = Math.round((Alert3 / Alert5) * 100);

      if (Alert2 === 0) temp3 = 0;
      else temp3 = Math.round((Alert2 / Alert5) * 100);

      if (Alert1 === 0) temp4 = 0;
      else temp4 = Math.round((Alert1 / Alert5) * 100);

      this.LiveViewdatasets = [
        [Alert4, Alert5 - Alert4], //Gray
        [Alert3, Alert5 - Alert3], //Red
        [Alert2, Alert5 - Alert2], //Yellow
        [Alert1, Alert5 - Alert1], //Green
      ];

      this.LiveViewdonutColors1 = [
        {
          backgroundColor: [
            "#797777", //Gray
            "#fafafa",
          ],
        },
        {
          backgroundColor: [
            "#FF0000", //Red
            "#fafafa",
          ],
        },
        {
          backgroundColor: [
            "#F7FE2E", //Yellow
            "#fafafa",
          ],
        },
        {
          backgroundColor: [
            "#3ADF00", //Green
            "#fafafa",
          ],
        },
      ];

      let Clean = localStorage.getItem("Clean") ?? "";
      let InUse = localStorage.getItem("InUse") ?? "";
      let Dirty = localStorage.getItem("Dirty") ?? "";
      let Unknown = localStorage.getItem("Unknown") ?? "";

      if (Clean != null) Clean = Clean.trim();

      if (InUse != null) InUse = InUse.trim();

      if (Dirty != null) Dirty = Dirty.trim();

      if (Unknown != null) Unknown = Unknown.trim();

      this.LiveViewoptions1 = {
        tooltips: {
          enabled: false,
        },

        rotation: 190,

        legend: {
          display: true,
          position: "right",

          labels: {
            width: "250px",
            generateLabels() {
              return [
                {
                  tooltips: "Clean",
                  text: temp4 + "% " + Clean,
                  fillStyle: "#70F519",
                  strokeStyle: "#fff",
                },
                {
                  tooltips: InUse,
                  text: temp3 + "% " + InUse,
                  fillStyle: "#EEF519",
                  strokeStyle: "#fff",
                },
                {
                  tooltips: Dirty,
                  text: temp2 + "% " + Dirty,
                  fillStyle: "#F93D1F",
                  strokeStyle: "#fff",
                },
                {
                  tooltips: Unknown,
                  text: temp1 + "% " + Unknown,
                  fillStyle: "#797777",
                  strokeStyle: "#fff",
                },
              ];
            },
          },
        },
      };
      // });
      this.LiveViewPopularLocation();
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - loadliveviewchart() - " + err.message
      );
    }
  }

  LiveViewPopularLocation() {
    try {
      const Loc_Value = [] as any[];
      const Loc_RoomName = [] as any[];
      let liveviewLocationData = [] as any[];
      liveviewLocationData = this.TotalLiveViewSource.data;
      let Percentage;
      let Loc_RoomNameCount = 0;
      const New_RoomName = [] as any[];
      const loadRoomName = [] as any[];
      let TruLocationName = 0;
      let PopularLocCount = 0;
      liveviewLocationData.forEach(function (e) {
        Loc_RoomName.push({
          Location: e.TruLocation,
          FullLocation: e.FullTruLocation,
        });
      });

      liveviewLocationData.sort(this.sortByRoomName);

      for (let i = 0; i < liveviewLocationData.length; i++) {
        Loc_RoomNameCount = 0;
        TruLocationName = 0;
        for (let j = 0; j < Loc_RoomName.length; j++) {
          if (liveviewLocationData[i].TruLocation == Loc_RoomName[j].Location) {
            Loc_RoomNameCount = Loc_RoomNameCount + 1;
          }
          if (
            Loc_RoomNameCount >= 1 &&
            !New_RoomName.includes(liveviewLocationData[i].TruLocation)
          ) {
            New_RoomName.push(Loc_RoomName[j].Location);
            TruLocationName = 1;
            loadRoomName.push({
              TruLocation: Loc_RoomName[j].Location,
              FullTruLocation: Loc_RoomName[j].FullLocation,
            });
          }
        }
        if (TruLocationName == 1) {
          loadRoomName.push({ Count: Loc_RoomNameCount });
        }
      }
      for (let k = 0; k < loadRoomName.length; k++) {
        if (k % 2 == 0 && k < 8) {
          const count = loadRoomName[k + 1].Count;
          Percentage =
            Math.round((count / liveviewLocationData.length) * 100) + "%";
          PopularLocCount += count;
          Loc_Value.push({
            Type: loadRoomName[k].TruLocation,
            FullType: loadRoomName[k].FullTruLocation,
            Value1: count,
            Value2: Percentage,
          });
        }
      }
      this.Pop_LocationData = Loc_Value;
      this.LiveViewReportCount = PopularLocCount;
      this.Pop_LocationData.sort(this.sortByLiveviewLocation);
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - LiveViewPopularLocation() - " + err.message
      );
    }
  }

  sortByLiveviewLocation(number1: any, number2: any) {
    if (number1.Value1 < number2.Value1) return 1;
    else if (number1.Value1 === number2.Value1) return 0;
    else return -1;
  }

  sortByRoomName(a: any, b: any) {
    if (a.TruLocation < b.TruLocation) return 1;
    else if (a.TruLocation === b.TruLocation) return 0;
    else return -1;
  }

  searchLiveViewReport(filterValue: string) {
    try {
      if (filterValue != null) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.chkLiveviewsearchvalue = filterValue;
        this.IsFilter = 1;
        this.IsFilterData = filterValue;
        this.getfilterLiveViewdetails();
      }
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - searchLiveViewReport() - " + err.message
      );
    }
  }

  cusloc_selectLocationType(selectLocationType) {
    try {
      this.selectedLocationType = selectLocationType;
      this.IsFilter = 1;
      this.IsFilterData = selectLocationType;
      this.getfilterLiveViewdetails();
    } catch (err) {
      throw new Error(
        "report.component.ts" +
          " - cusloc_selectLocationType() - " +
          err.message
      );
    }
  }

  CusLoc_selectStateby(selectdata) {
    try {
      this.cusloc_selectStateBy = selectdata;
      this.IsFilter = 1;
      this.IsFilterData = selectdata;
      this.getfilterLiveViewdetails();
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - CusLoc_selectStateby() - " + err.message
      );
    }
  }

  getfilterLiveViewdetails() {
    let _searchfilterData = "";
    let filtersearch;
    const _filterlocationtype = this.selectedLocationType;
    const _filterstatechangedby = this.cusloc_selectStateBy;
    let _filterLiveviewdata;
    const LiveviewfilteredArray = [] as any[];
    _filterLiveviewdata = this.LiveViewReport;
    let searchFilterLiveData;

    //////LocationTypefilter////////
    if (_filterlocationtype == "All") _filterLiveviewdata = this.LiveViewReport;
    else if (_filterlocationtype != undefined && _filterlocationtype != 0) {
      this.CusLocfilteredArray = [];
      const list = this.LiveViewReport.filter(
        (x) => x.roomcolor == _filterlocationtype
      );
      list.forEach((x) => {
        this.CusLocfilteredArray.push(x);
      });
      _filterLiveviewdata = this.CusLocfilteredArray;
    } else {
      _filterLiveviewdata = this.LiveViewReport;
    }

    //////StateChangedbyfilter////////

    if (_filterstatechangedby == "All") {
      searchFilterLiveData = _filterLiveviewdata;
      _filterLiveviewdata = searchFilterLiveData;
    } else if (
      _filterstatechangedby != undefined &&
      _filterstatechangedby != 0
    ) {
      const list = _filterLiveviewdata.filter(
        (x) => x.stateChangebyName == this.cusloc_selectStateBy
      );
      list.forEach((x) => {
        LiveviewfilteredArray.push(x);
      });
      _filterLiveviewdata = LiveviewfilteredArray;
    } else {
      searchFilterLiveData = _filterLiveviewdata;
      _filterLiveviewdata = searchFilterLiveData;
    }

    //////////Search///////////////////////
    if (this.chkLiveviewsearchvalue) {
      _searchfilterData = "";
      filtersearch = this.chkLiveviewsearchvalue;

      let search_statechangeby = "";
      let search_roomcolor = "";
      _searchfilterData = _filterLiveviewdata.filter(function (data) {
        if (data.stateChangebyName != null) {
          search_statechangeby = data.stateChangebyName
            .toLowerCase()
            .includes(filtersearch.toLowerCase());
        } else search_statechangeby = "";

        if (data.FullTypeName != null) {
          search_roomcolor = data.FullTypeName.toLowerCase().includes(
            filtersearch.toLowerCase()
          );
        } else search_roomcolor = "";

        return (
          data.FullGeoName.toLowerCase().includes(filtersearch.toLowerCase()) ||
          data.FullTruLocation.toLowerCase().includes(
            filtersearch.toLowerCase()
          ) ||
          search_statechangeby ||
          data.Updatedon.toLowerCase().includes(filtersearch.toLowerCase()) ||
          search_roomcolor ||
          data.R_TagId.toString().includes(filtersearch)
        );
      });
    } else {
      _searchfilterData = _filterLiveviewdata;
    }

    this.LiveViewSource.data = _searchfilterData;
    this.downloadLiveviewdata.data = _searchfilterData;
    setTimeout(() => {
      this.LiveviewData = [];
      this.LiveViewSource.sort = this.LiveViewsort;
      this.sortingDatalist_LiveViewReport(this.LiveViewSource);
      this.downloadLiveviewdata.sort = this.LiveViewsort;
      this.sortingDatalist_LiveViewReport(this.downloadLiveviewdata);
      this.downloadLiveviewdata
        .connect()
        .subscribe((data) => (this.LiveviewData = data));
    }, 0);
  }

  getliveviewdivclickelement(AssetId, RoomId, clickedId) {
    let customGeo_Data = [] as any[];
    customGeo_Data = this.LiveViewSource.data;
    let _data;
    let state_changed1;
    let state_changed2;
    customGeo_Data.forEach(function (data) {
      _data = data.AssetId;

      if (_data == AssetId) {
        // _data check Start

        const autostatecontent1 = document.getElementById(
          AssetId + "_" + clickedId
        );
        if (autostatecontent1 != null)
          autostatecontent1.className = "dotselectedlist";

        if (data.roomcolor == enumReportState.Clean) {
          if (clickedId == enumReportState.InUse) {
            state_changed1 = AssetId + "_" + enumReportState.Unknown;
          } else {
            state_changed1 = AssetId + "_" + enumReportState.InUse;
          }
          const autostatecontent2 = document.getElementById(state_changed1);
          if (autostatecontent2 != null)
            autostatecontent2.className = "dotlist";
        } else if (data.roomcolor == enumReportState.InUse) {
          if (clickedId == enumReportState.Dirty) {
            state_changed1 = AssetId + "_" + enumReportState.Unknown;
          } else {
            state_changed1 = AssetId + "_" + enumReportState.Dirty;
          }
          const autostatecontent2 = document.getElementById(state_changed1);
          if (autostatecontent2 != null)
            autostatecontent2.className = "dotlist";
        } else if (data.roomcolor == enumReportState.Dirty) {
          if (clickedId == enumReportState.Clean) {
            state_changed1 = AssetId + "_" + enumReportState.Unknown;
          } else {
            state_changed1 = AssetId + "_" + enumReportState.Clean;
          }
          const autostatecontent2 = document.getElementById(state_changed1);
          if (autostatecontent2 != null)
            autostatecontent2.className = "dotlist";
        } else if (data.roomcolor == enumReportState.Unknown) {
          if (clickedId == enumReportState.Clean) {
            state_changed1 = AssetId + "_" + enumReportState.InUse;
            state_changed2 = AssetId + "_" + enumReportState.Dirty;
          } else if (clickedId == enumReportState.InUse) {
            state_changed1 = AssetId + "_" + enumReportState.Clean;
            state_changed2 = AssetId + "_" + enumReportState.Dirty;
          } else if (clickedId == enumReportState.Dirty) {
            state_changed1 = AssetId + "_" + enumReportState.Clean;
            state_changed2 = AssetId + "_" + enumReportState.InUse;
          }
          const autostatecontent2 = document.getElementById(state_changed1);
          if (autostatecontent2 != null)
            autostatecontent2.className = "dotlist";

          const autostatecontent3 = document.getElementById(state_changed2);
          if (autostatecontent3 != null)
            autostatecontent3.className = "dotlist";
        }
      } // _data check end
    });

    const ConfirmMessageData = {
      ConfirmMessage: "Are you sure you want to modify the Current State?",
    };

    const dialogRef = this.dialog.open(ConfirmMessageComponent, {
      disableClose: true,
      width: "auto",
      height: "200px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: ConfirmMessageData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Yes") {
        this.addLiveChangeState(AssetId, RoomId, clickedId);
      } else {
        const autostatecontent1 = document.getElementById(
          AssetId + "_" + clickedId
        );
        if (autostatecontent1 != null) autostatecontent1.className = "dotlist";
        dialogRef.close("Cancel");
      }
    });
  }

  addLiveChangeState(AssetId, RoomId, clickedId) {
    try {
      const username = localStorage.getItem("userid") ?? "";
      const livechangestate: addLiveChangeState = {
        authKey: this.authkey,
        assetId: parseInt(AssetId),
        roomId: RoomId.toString(),
        clickedId: clickedId.toString(),
        stateChange: (this.cusloc_selectStateBy = JSON.parse(username)).toString(),
      };
      this.reportService
        .addLiveChangeState(livechangestate)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse_live: any[]) => {
          if (apiresponse_live.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "Current State modified successfully"
            );
            this.chkLiveviewsearchvalue = "";
            this.selectedLocationType = 0;
            this.cusloc_selectStateBy = 0;
            this.searchLiveViewInput.nativeElement.value = "";
            this.getCustomizedGeo("");
          } else if (apiresponse_live.toString() == "false") {
            generateAlertMessage(this.dialog, "Current State modified failed");
          }
        });
    } catch (err) {
      throw new Error(
        "liveview.component.ts" + " - addLiveChangeState() - " + err.message
      );
    }
  }

  exportLiveViewReportdata() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    if (this.LiveViewSource.filter != "") {
      this.downloadLiveviewdata.data = this.LiveViewSource.filteredData;
      setTimeout(() => {
        this.LiveViewSource.sort = this.LiveViewsort;
        this.sortingDatalist_LiveViewReport(this.LiveViewSource);
        this.LiveViewSource.connect().subscribe(
          (data) => (this.LiveviewData = data)
        );
      }, 0);
    }
    const dialogRef = this.dialog.open(DownloadLiveviewReportComponent, {
      disableClose: true,
      width: "388px",
      height: "165px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: this.LiveviewData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  sortingDatalist_LiveViewReport(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (
        sortHeaderId == "GeoName" ||
        sortHeaderId == "TruLocation" ||
        sortHeaderId == "TypeName" ||
        sortHeaderId == "stateChangebyName"
      ) {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }

  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
    if (ReportHeaderComponent.isReportMenuClosed == false) {
      this.reportService.toggleReportMenuNav.emit();
    }
  }

  ngOnDestroy() {
    clearInterval(this.liveviewinterval);
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
