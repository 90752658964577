import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  OnChanges,
  SimpleChange,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener
} from "@angular/core";
import {
  getCampusFullByCampusId,
  getCampus,
  getCampusFulldetail,
  SharedData,
  SharedFloorAlertData,
  SharedAlertData,
  SharedCheckAlertData,
  staffresidentdata,
  ShareRightSideNav,
  getResStaffCurrentLocation,
} from "../../../api";
import { Router } from "@angular/router";
import { ApiService } from "../../../api.service";
import { AlertSharedService } from "../../../_services/index";
import { UntypedFormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { HomeService } from "../home.service";
import { showLiveLocation, FoodNode } from "../home";
import {
  alert_raisedon,
  enumAlertType,
  enumCheckResidenStaff,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,  
} from "../../../app-settings";
import {
  groupBy,
  set_SharedDetails,
  set_SharedFloorDetails,
  set_alertChangeorClick,
  redirecttohome,
  SessionCheck,
  getFeatureNeedtoShow,
  
} from "../../../app-general";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { DOCUMENT } from "@angular/common";
import { ConnectionService } from "ng-connection-service";
import { MatSidenav } from "@angular/material/sidenav";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';


@Component({
  selector: "app-home-header",
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.scss"],
})
export class HomeHeaderComponent implements OnChanges, OnInit, OnDestroy {
  CampusFulldetails: getCampusFulldetail[];
  Campusdetails = {};
  selectedBuildingdetails = {};
  selectedFloordetails = {};
  selectedSubzonedetails = {};
  SharedDetails: SharedData;
  SharedFloorAlertDetails: SharedFloorAlertData;
  CheckAlertData: SharedCheckAlertData;
  SharedLocationDetails: any;
  ShareSideNavDetails: ShareRightSideNav;
  bLiveData = true;

  Campus_key = "Campusinfo";
  Building_key = "Buildinginfo";
  Floor_key = "Floorinfo";
  SubZone_key = 'SubZoneinfo';

  selectedCampus = 0;
  selectedBuilding;
  selectedFloor = 0;
  selectedSubZone = 0;
  selectedMapIds;
  alertinfo: any;
  myControl = new UntypedFormControl();
  options = [] as any[];
  filteredOptions: Observable<string[]>;
  public watcher: Observable<boolean>;
  columns = 4;
  public Alert_raisedon = alert_raisedon;
  public show = false;
  checktagid = 0;
  isMobile = false;
  checkresident = false;
  checkstaff = false;
  checkLiveView = false;
  checklivestaffdata = "0";
  checkliveresidentdata = "0";
  checkresidentsearch = "0";
  checkapproximatelocation = "0";
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
    
  private _onDestroy: Subject<void> = new Subject<void>();
  public static isHomeMenuClosed = true;  
  public static visible = false;
  public static checkmatselect = 0;
  public homeHeaderReference = HomeHeaderComponent;  
  selectMapdetails: string;
  treeControl = new NestedTreeControl<FoodNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  selectedMapName = "";
  getcampusfulldetails;  
  checkbtnclickReset = 0;
  floorResetTooltip = "Reset Location to Default Campus View and\n No Building(s)/Outdoor Space(s) Outlines and no live residents or staff";
  campusResetTooltip = "Reset Location to Default Campus View";  
  mapOptionInfoTooltip = "Checking the box for Resident and Staff will display the current location of residents and staff members in real time."; 
  ReadMore = true;
  floorGroups;
  checkbuilding = false;
  checkapproximate = false;
  @ViewChild("sideHomenav") public sideHomeMenuNav: MatSidenav;
  constructor(
    private router: Router,    
    private apiService: ApiService,
    @Inject(DOCUMENT) private document: any,
    private homeService: HomeService,
    private alertSharedService: AlertSharedService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private connectionService: ConnectionService,
    public mainLayoutService: MainLayoutService,
    private breakpointObserver: BreakpointObserver,
    private eRef: ElementRef
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged());      
    this.homeService.toggleHomeMenuNav.subscribe(() => {
      this.sideHomeMenuNav.toggle();
      if (this.sideHomeMenuNav._animationState == "void") {
        HomeHeaderComponent.isHomeMenuClosed = true;
      } else if (this.sideHomeMenuNav._animationState == "open") {
        HomeHeaderComponent.isHomeMenuClosed = false;
      }
    });
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 4;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 1;
    }
  }

  @Input() MCampusId: number;
  @Input() MBuildingId: string;
  @Input() MFloorId: number;
  @Input() MSubZoneId: number;
  
  changeLog: string[] = [];
  AlertId = "";
  ResidentId = "";
  AlertStatus = 0;
  Map_array_length = 5;
  Max_AlertLimit = 5;
  imgwidth = 0;
  selectedOption = [];
  //pollingData: any;
  cnt = 0;
  _staffresidentinfo;
  UserName;
  authkey;

  
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) { 
      if(event.target.offsetParent != null)     
      {
        if(event.target.id == "lblMapOptions" || event.target.id == "imgMapOptions" || event.target.offsetParent.id == 'chkresident' || event.target.offsetParent.id == 'chkstaff' ||
        event.target.offsetParent.id == 'chkbuilding' || event.target.offsetParent.id == 'chkapproximate' || event.target.offsetParent.id == 'divmapOptionsPopup')
        {
            HomeHeaderComponent.visible = true;
        }      
        else if(event.target.id == "txtmapid" || event.target.offsetParent.offsetParent.className == "clspopupMapdetails" || event.target.offsetParent.className == "mat-radio-container" ||
          event.target.className == "clspopupMapdetails" || event.target.className == "mat-form-field-flex" || event.target.id == "downmaticon" || 
          event.target.className == "mat-form-field-subscript-wrapper")
        {
          HomeHeaderComponent.checkmatselect = 1;
        }  
        else
        {
          if(HomeHeaderComponent.visible)
            HomeHeaderComponent.visible = false;
            
          if(HomeHeaderComponent.checkmatselect == 1)
          {
            HomeHeaderComponent.checkmatselect = 0;
            this.selectMapdetails = this.MCampusId + "_" + this.MBuildingId + "_" + this.MFloorId + "_" + this.MSubZoneId;
          }
        }
      } 
      else if(event.target.textContent == "Clear")   
      {
        HomeHeaderComponent.checkmatselect = 1;
        this.treeControl.collapseAll();
      }
      else
      {
        if(HomeHeaderComponent.visible)
          HomeHeaderComponent.visible = false;
          
        if(HomeHeaderComponent.checkmatselect == 1)
        {
          HomeHeaderComponent.checkmatselect = 0;
          this.selectMapdetails = this.MCampusId + "_" + this.MBuildingId + "_" + this.MFloorId + "_" + this.MSubZoneId;
        }
      }
    }
    else
    {
      const element =  this.document.getElementById("divliveviewloading").style.display;      
      if(HomeHeaderComponent.visible && element == 'none')
          HomeHeaderComponent.visible = false;
          
        if(HomeHeaderComponent.checkmatselect == 1)
        {
          HomeHeaderComponent.checkmatselect = 0;
          this.selectMapdetails = this.MCampusId + "_" + this.MBuildingId + "_" + this.MFloorId + "_" + this.MSubZoneId;
        }
    }
  }
  
  ngOnInit() {
    window.onresize = () => (this.isMobile = window.innerWidth <= 1375);
    const username = localStorage.getItem("username") ?? "";
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (username != null) this.UserName = JSON.parse(username);
    try {
      this.alertSharedService
        .currentSharedLocation()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedLocationDetails: any) => {
          this.SharedLocationDetails = SharedLocationDetails;
        });
      this.alertSharedService
        .currentsharedsideNav()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ShareSideNavDetails: ShareRightSideNav) => {
          this.ShareSideNavDetails = ShareSideNavDetails;
        });
      if (this.SharedLocationDetails != undefined) {
        this.document.getElementById("chkresident").checked =
          this.SharedLocationDetails.resident;
        this.document.getElementById("chkstaff").checked =
          this.SharedLocationDetails.staff;
        this.checkresident = this.SharedLocationDetails.resident;
        this.checkstaff = this.SharedLocationDetails.staff;
        

        if(this.document.getElementById("chkbuilding") != null)
        this.document.getElementById("chkbuilding").checked = this.SharedLocationDetails.buildingoutlet;
        
      if(this.document.getElementById("chkapproximate") != null)
        this.document.getElementById("chkapproximate").checked = this.SharedLocationDetails.approxoutlet;


        this.checkresident = this.SharedLocationDetails.resident;
        this.checkstaff = this.SharedLocationDetails.staff;
        this.checkbuilding = this.SharedLocationDetails.buildingoutlet;
        this.checkapproximate = this.SharedLocationDetails.approxoutlet;

        this.bLiveData = false;
        if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Location,
            enumUIAction.Location_RTLS_Staff
          ) == enumUIView.Show
        ) {
          
          this.document.getElementById("chkstaff").classList.remove('clsdisable'); 
          this.document.getElementById("chkstaff").classList.add('clsenable'); 
          this.checkresidentsearch = "1";
          this.checklivestaffdata = "1";
          
        } else {
          this.document.getElementById("chkstaff").classList.remove('clsenable'); 
          this.document.getElementById("chkstaff").classList.add('clsdisable'); 
          this.checklivestaffdata = '0' ;
        }
        if (
          getFeatureNeedtoShow(
            enumUIFeatureView.Location,
            enumUIAction.Location_RTLS_Resident
          ) == enumUIView.Show
        ) {
          
          this.document.getElementById("chkresident").classList.remove('clsdisable'); 
          this.document.getElementById("chkresident").classList.add('clsenable'); 
          this.checkresidentsearch = "1";
          this.checkliveresidentdata = "1";
        } else {

          this.document.getElementById("chkresident").classList.remove('clsenable'); 
          this.document.getElementById("chkresident").classList.add('clsdisable'); 
          this.checkliveresidentdata = '0';

        }
   
      

        if(this.checkbtnclickReset == 0)
        {
          if (this.checkresident == true && this.checkstaff == true) {
            this.getSearchOption(enumCheckResidenStaff.All);
          } else if (this.checkresident == true) {
            this.getSearchOption(enumCheckResidenStaff.Resident);
          } else if (this.checkstaff == true) {
            this.getSearchOption(enumCheckResidenStaff.Staff);
          }
          else if (this.checkbuilding == true) {
            this.getSearchOption(enumCheckResidenStaff.BuildingOutline);
          }
          else if (this.checkapproximate == true) {
            this.getSearchOption(enumCheckResidenStaff.ApproximateOutline);
          }
          else {
            this.getSearchOption(enumCheckResidenStaff.None);
          }
        }
        else if(this.checkbtnclickReset == 1)
        {
          this.getSearchOption(enumCheckResidenStaff.None);          
        }
       }  else {
        this.document.getElementById("chkresident").checked = false;
        this.document.getElementById("chkstaff").checked = false;
        if(this.document.getElementById("chkbuilding") != null)
          this.document.getElementById("chkbuilding").checked = false;
        if(this.document.getElementById("chkapproximate") != null)
          this.document.getElementById("chkapproximate").checked = false;
        this.checkresident = false;
        this.checkstaff = false;
        this.checklivestaffdata = "0";
        this.checkliveresidentdata = "0";
        this.checkresidentsearch = "0";
        this.checkapproximatelocation="0";
        this.bLiveData = true;
      }



      this.show = true;
      this.MCampusId = 0;
      this.MBuildingId = '0';
      this.MFloorId = 0;
      this.MSubZoneId = 0;
      this.alertSharedService
        .currentShared()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedDetails: SharedData) => {
          this.SharedDetails = SharedDetails;
        });
      this.alertSharedService
        .currentSharedAlert()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedAlertDetails: SharedAlertData[]) => {
          this.alertinfo = SharedAlertDetails;
        });
      this.alertSharedService
        .currentsharedFloorAlert()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedFloorAlertDetails: SharedFloorAlertData) => {
          this.SharedFloorAlertDetails = SharedFloorAlertDetails;
        });
      this.alertSharedService
        .currentSharedAlertResidentBased()
        .pipe(takeUntil(this._onDestroy))
        .subscribe();      

       

      let outdoordetailsinfo;
      const outdoorspace: any = {
        authKey: localStorage.getItem("Authkey")
      }
      this.homeService.getOutdoorSpaces(outdoorspace).pipe(takeUntil(this._onDestroy))
        .subscribe((outdoordetails: any[]) => {
          outdoordetailsinfo= outdoordetails;
          if (outdoordetailsinfo.length >0 && localStorage.getItem("outdoor_Locating") == '1' )
          {
            this.document.getElementById("chkapproximate").classList.remove('clsdisable'); 
            this.document.getElementById("chkapproximate").classList.add('clsenable'); 
            this.checkapproximatelocation="1";
          }
          else
          {
            this.document.getElementById("chkapproximate").classList.remove('clsenable'); 
            this.document.getElementById("chkapproximate").classList.add('clsdisable'); 
            this.checkapproximatelocation="0";
          }
      });

      if(this.SharedFloorAlertDetails != undefined && this.SharedFloorAlertDetails.SharedFloorCampusId != undefined)
      { 
        this.getCampusdetails(false);       
        this.MCampusId =parseInt(this.SharedFloorAlertDetails.SharedFloorCampusId.toString());
         if(this.SharedFloorAlertDetails.SharedFloorBuildingId != undefined)
          this.MBuildingId = this.SharedFloorAlertDetails.SharedFloorBuildingId.toString();
        else
          this.MBuildingId = "";               
        this.MFloorId = this.SharedFloorAlertDetails.SharedFloorFloorId;
        this.MSubZoneId = this.SharedFloorAlertDetails.SharedFloorSubZoneId;
        
        if(this.MCampusId == undefined)
          this.MCampusId = this.SharedDetails.SharedCampusId;
        if(this.MBuildingId == undefined || this.MBuildingId == "")
          this.MBuildingId = this.SharedDetails.SharedBuildingId.toString();
        if(this.MFloorId == undefined)
          this.MFloorId = this.SharedDetails.SharedFloorId;
        if(this.MSubZoneId == undefined)
          this.MSubZoneId = this.SharedDetails.SharedSubZoneId;
          

        
      }
      else if(this.SharedDetails != undefined && this.SharedDetails.SharedCampusId != undefined)
      {
        this.getCampusdetails(false);       
        this.MCampusId = this.SharedDetails.SharedCampusId
         if(this.SharedDetails.SharedBuildingId != undefined)
          this.MBuildingId = this.SharedDetails.SharedBuildingId.toString();
        else
          this.MBuildingId = "";               
        this.MFloorId = this.SharedDetails.SharedFloorId;
        this.MSubZoneId = this.SharedDetails.SharedSubZoneId;
        
        if(this.MCampusId == undefined)
          this.MCampusId = this.SharedDetails.SharedCampusId;
        if(this.MBuildingId == undefined || this.MBuildingId == "")
          this.MBuildingId = "0";
        if(this.MFloorId == undefined)
          this.MFloorId =0
        if(this.MSubZoneId == undefined)
          this.MSubZoneId = 0
      }
      else
      {        
        this.getCampusdetails(true);
      }

      
      if (this.SharedFloorAlertDetails != undefined || this.SharedDetails != undefined)
      {
        this.selectedCampus = this.MCampusId;
        this.selectedBuilding = this.MBuildingId;
        this.selectedFloor = this.MFloorId;
        this.selectedSubZone = this.MSubZoneId;
     
        const campusid: getCampusFullByCampusId = {            
            authKey: this.authkey,
            campusId: (this.selectedCampus)
          }
          this.apiService.getCampusbyCampusId(campusid).pipe(takeUntil(this._onDestroy))
            .subscribe((CampusFulldetails: any[]) => {
              this.CampusFulldetails = CampusFulldetails;  
              let BuildingId=0;
              if (this.MBuildingId.split('_').length != 2)
                    BuildingId=parseInt(this.MBuildingId);
              this.selectMapdetails = this.MCampusId + "_" + BuildingId + "_" + this.MFloorId + "_" + this.MSubZoneId;
              this.selectdropdownvalue(this.MCampusId,this.MBuildingId,this.MFloorId,this.MSubZoneId);   
              let AlertId="";
              if (this.SharedDetails !=undefined && this.SharedDetails.SharedAlertId !="" && this.SharedDetails.SharedAlertId !=undefined)
                  AlertId=this.SharedDetails.SharedAlertId;

              this.onMapselected(0,AlertId);          
            });
      }
      else
      {        
        this.getCampusdetails(true);
      }
      if (this.bLiveData == true) {
        const liveItem: showLiveLocation = {
          authKey: this.authkey,
        };
        this.showLiveLocation(liveItem);
      }
    } catch (err) {
      throw new Error("home-header.component.ts - ngOnInit() - " + err.message);
    }
  }

  getSearchOption(ChkType) {
    const FilterName = [] as any[];
    const _data: getResStaffCurrentLocation = {
      authKey: this.authkey,
    };
    this.apiService
      .getStaffResidentCurrentLocation(_data)
      .subscribe((staffresidentinfo: staffresidentdata[]) => {
        
        //for resident
        const elementr = <HTMLInputElement> document.getElementById("chkresident");
        let residenttick = 0;
        for (let nidx=0; nidx<=(elementr.classList.length-1); nidx++)  
        {
          if (elementr.classList[nidx] == 'mat-checkbox-checked')
            {
              residenttick=1;
              ChkType = enumCheckResidenStaff.Resident;
            }
        }
        //for staff
        const elements = <HTMLInputElement> document.getElementById("chkstaff");
        let stafftick = 0
        for (let nidx=0; nidx<=(elements.classList.length-1); nidx++)  
        {
          if (elements.classList[nidx] == 'mat-checkbox-checked')
          {
              stafftick=1;
              ChkType = enumCheckResidenStaff.Staff;
          }
        }
        //for both
        if (stafftick==1 && residenttick==1)
        {
          ChkType = enumCheckResidenStaff.All;
        }
        
        if (staffresidentinfo != undefined && staffresidentinfo.length > 0) {
          staffresidentinfo.forEach(function (e) {
            if (e.RoomId != "0" && e.RoomId !=  "0_0") {
              const ResidentfirstName = e.FirstName;
              let ResidentName = e.LastName;
              ResidentName = ResidentfirstName + " " + ResidentName;
              if (e.Type == ChkType || ChkType == enumCheckResidenStaff.All) {
                const fdata = {
                  ResidentName: ResidentName,
                  AlertId: e.RoomId,
                  RoomId: e.TagId + "_" + e.Type,
                  CampusId: e.CampusId,
                  BuildId: e.BuildId,
                  FloorId: e.FloorId,
                  ResidentId: e.ID,
                  SubZoneId:e.SubZoneId
                };
                FilterName.push(fdata);
              }
            }
          });
        }
        this.options = FilterName;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );

        this.onMapChanged.emit(ChkType);
      });
  }
  ////// for read live data///////
  showLiveLocation(LiveItem) {
    this.homeService
      .showLiveLocation(LiveItem)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiLiveresponse: any[]) => {
        console.log(apiLiveresponse);
        this.checklivestaffdata = "1"; //apiLiveresponse['show_live_location_staff'].toString();
        this.checkliveresidentdata = "1"; // apiLiveresponse['show_live_location_resident'].toString();
    
        this.checkresident = false;
        this.checkstaff = false;
        this.checkresidentsearch = "0";
        if (
          this.checklivestaffdata == "1" &&
          getFeatureNeedtoShow(
            enumUIFeatureView.Location,
            enumUIAction.Location_RTLS_Staff
          ) == enumUIView.Show
        ) {
          
          this.document.getElementById("chkstaff").classList.remove('clsdisable'); 
          this.document.getElementById("chkstaff").classList.add('clsenable'); 
          this.checkresidentsearch = "1";
          this.checklivestaffdata = "1";
        } else {
          
          this.document.getElementById("chkstaff").classList.remove('clsenable'); 
          this.document.getElementById("chkstaff").classList.add('clsdisable'); 
          this.checklivestaffdata = "0";
        }
        if (
          this.checkliveresidentdata == "1" &&
          getFeatureNeedtoShow(
            enumUIFeatureView.Location,
            enumUIAction.Location_RTLS_Resident
          ) == enumUIView.Show
        ) {
          
          this.document.getElementById("chkresident").classList.remove('clsdisable'); 
          this.document.getElementById("chkresident").classList.add('clsenable'); 
          this.checkresidentsearch = "1";
          this.checkliveresidentdata = "1";
        } else {
          //this.document.getElementById("chkresident").style.display = "none";
          this.document.getElementById("chkresident").classList.remove('clsenable'); 
          this.document.getElementById("chkresident").classList.add('clsdisable'); 
          this.checkliveresidentdata = "0";
        }
       
        this.livecheckfunction(enumCheckResidenStaff.None);
      });
  }
  ///// for search Functionality
  getsearchAlertData(): void {
    try {
      let arrTagIds = [] as any[];
      let alertsearchdata = [] as any[];
      let alertinfo = [] as any[];

      alertinfo = this.alertinfo;
      if (alertinfo != undefined && alertinfo.length > 0) {
        //alert("count")
        arrTagIds = [];
        alertsearchdata = [];
        alertinfo.forEach(function (e) {
          if (
            !arrTagIds.includes(e.TagId) &&
            e.RoomName != "Non Mapped Room" &&
            e.ResidentId != 0 &&
            e.AlertType != enumAlertType.Pull_Cord &&
            e.AlertType != enumAlertType.Infrastructure
          ) {
            arrTagIds.push(e.TagId);
            alertsearchdata.push(e);
          }
        });
      }
    } catch (err) {
      throw new Error(
        "home-header.component.ts - getsearchAlertData() - " + err.message
      );
    }
  }

  private _filter(value) {
    let filterValue = "";
    try {
      if (value.AlertId) {
        filterValue = value.ResidentName.toLowerCase();
      } else filterValue = value.toLowerCase();
    } catch (err) {
      throw new Error(
        "home-header.component.ts" + " - _filter() - " + err.message
      );
    }

    return this.options.filter(
      (option) => option.ResidentName.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFn(project): string {
    return project ? project.ResidentName : project;
  }

  selectoption($event) {
    try {
      let searchedName = $event.target.value.toLowerCase();
      searchedName = searchedName.trim();
      const selectedData = this.options.filter(
        (option) =>
          option.ResidentName.toLowerCase().indexOf(searchedName) === 0
      );
      this.selectSearch(selectedData[0]);
    } catch (err) {
      throw new Error(
        "home-header.component.ts" + " - selectoption() - " + err.message
      );
    }
  }
  //Floor select change

  selectFloordropdown(MapDataDetails) {
    try {
      const campusid: getCampusFullByCampusId = {
        authKey: this.authkey,
        campusId: parseInt(MapDataDetails.CampusId),
      };
      this.apiService
        .getCampusbyCampusId(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusFulldetails: any[]) => {
          this.CampusFulldetails = CampusFulldetails;

          const Campusinfo = groupBy(
            this.CampusFulldetails["Campusinfo"],
            "CampusId"
          );
          this.MCampusId = parseInt(MapDataDetails.CampusId);
          this.MBuildingId = (MapDataDetails.BuildId);

          const chk_Campusid = this.MCampusId;
          this.selectedBuildingdetails[this.Building_key] = [];
          const buildinginfo = groupBy(
            Campusinfo[chk_Campusid][0]["Campusdetail"],
            "buildingname"
          );
          for (const buildingkey in buildinginfo) {
            const arrBuildinginfo = {
              CampusId: chk_Campusid,
              BuildingId: buildinginfo[buildingkey][0].buildingid,
              BuildingName: buildinginfo[buildingkey][0].buildingname,
              PolyLine: buildinginfo[buildingkey][0].polyline,
            };
            this.selectedBuildingdetails[this.Building_key].push(
              arrBuildinginfo
            );
          }

          const buildinginfonew = groupBy(
            Campusinfo[this.selectedCampus][0]["Campusdetail"],
            "buildingid"
          );
          const floorinfo = groupBy(
            buildinginfonew[this.selectedBuilding][0]["floors"],
            "floorname"
          );
          const subzoneinfo = groupBy(buildinginfo[this.selectedBuilding][0]["floors"]["subzones"], 'subzonename');

          let nFloor = 0;
          nFloor = parseInt(MapDataDetails.FloorId);
          this.MFloorId = nFloor;
          this.selectedFloordetails[this.Floor_key] = [];
          this.selectedSubzonedetails[this.SubZone_key] = [];
          for (const key in floorinfo) {
            const arrFloorinfo = {
              CampusId: this.selectedCampus,
              BuildingId: this.selectedBuilding,
              FloorId: floorinfo[key][0].floorid,
              FloorName: floorinfo[key][0].floorname,
              FloorPlanImage: floorinfo[key][0].FloorPlanImage,
              AlertFloorcnt: floorinfo[key][0].AlertFloorcnt,
            };
            this.selectedFloordetails[this.Floor_key].push(arrFloorinfo);
          }
          
          for (const keysub in subzoneinfo) {
            const arrSubZoneinfo = { "CampusId": this.selectedCampus, "BuildingId": this.selectedBuilding, "FloorId": subzoneinfo[keysub][0].zoneid, "SubZoneId": subzoneinfo[keysub][0].subzoneid, "SubZoneName": subzoneinfo[keysub][0].subzonename, "FloorPlanImage": floorinfo[keysub][0].FloorPlanImage, "AlertFloorcnt": floorinfo[keysub][0].AlertFloorcnt };
            this.selectedSubzonedetails[this.SubZone_key].push(arrSubZoneinfo);
          }

          this.selectedCampus = this.MCampusId;
          this.selectedBuilding = this.MBuildingId;
          this.selectedFloor = this.MFloorId;
          this.onMapLoad();
        });
    } catch (err) {
      throw new Error(
        "home-header.component.ts" + " - selectFloordropdown() - " + err.message
      );
    }
  }

  selectSearch(MapDataDetails) {
    try {
      if (
        this.MCampusId != parseInt(MapDataDetails.CampusId) &&
        this.MBuildingId != (MapDataDetails.BuildId) &&
        this.MFloorId != parseInt(MapDataDetails.FloorId)
      ) {
        //this.selectFloordropdown(MapDataDetails);
        const campusid: getCampusFullByCampusId = {
          authKey: this.authkey,
          campusId: parseInt(MapDataDetails.CampusId),
        };
        this.apiService
          .getCampusbyCampusId(campusid)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((CampusFulldetails: any[]) => {
            this.CampusFulldetails = CampusFulldetails;
  
            const Campusinfo = groupBy(
              this.CampusFulldetails["Campusinfo"],
              "CampusId"
            );
            this.MCampusId = parseInt(MapDataDetails.CampusId);
            this.MBuildingId = (MapDataDetails.BuildId);
  
            const chk_Campusid = this.MCampusId;
            this.selectedBuildingdetails[this.Building_key] = [];
            const buildinginfo = groupBy(
              Campusinfo[chk_Campusid][0]["Campusdetail"],
              "buildingname"
            );
            for (const buildingkey in buildinginfo) {
              const arrBuildinginfo = {
                CampusId: chk_Campusid,
                BuildingId: buildinginfo[buildingkey][0].buildingid,
                BuildingName: buildinginfo[buildingkey][0].buildingname,
                PolyLine: buildinginfo[buildingkey][0].polyline,
              };
              this.selectedBuildingdetails[this.Building_key].push(
                arrBuildinginfo
              );
            }
  
            const buildinginfonew = groupBy(
              Campusinfo[this.selectedCampus][0]["Campusdetail"],
              "buildingid"
            );
            const floorinfo = groupBy(
              buildinginfonew[this.selectedBuilding][0]["floors"],
              "floorname"
            );
            //const subzoneinfo = groupBy(buildinginfo[this.selectedBuilding][0]["floors"]["subzones"], 'subzonename');
  
            let nFloor = 0;
            nFloor = parseInt(MapDataDetails.FloorId);
            this.MFloorId = nFloor;
            this.selectedFloordetails[this.Floor_key] = [];
            this.selectedSubzonedetails[this.SubZone_key] = [];
            for (const key in floorinfo) {
              const arrFloorinfo = {
                CampusId: this.selectedCampus,
                BuildingId: this.selectedBuilding,
                FloorId: floorinfo[key][0].floorid,
                FloorName: floorinfo[key][0].floorname,
                FloorPlanImage: floorinfo[key][0].FloorPlanImage,
                AlertFloorcnt: floorinfo[key][0].AlertFloorcnt,
              };
              this.selectedFloordetails[this.Floor_key].push(arrFloorinfo);
            }
            
          /*  for (const keysub in subzoneinfo) {
              var arrSubZoneinfo = { "CampusId": this.selectedCampus, "BuildingId": this.selectedBuilding, "FloorId": subzoneinfo[keysub][0].zoneid, "SubZoneId": subzoneinfo[keysub][0].subzoneid, "SubZoneName": subzoneinfo[keysub][0].subzonename, "FloorPlanImage": floorinfo[keysub][0].FloorPlanImage, "AlertFloorcnt": floorinfo[keysub][0].AlertFloorcnt };
              this.selectedSubzonedetails[this.SubZone_key].push(arrSubZoneinfo);
            }*/
            this.loadSearchData(MapDataDetails)
          })
          
      }
      else
        this.loadSearchData(MapDataDetails)
   
    } catch (err) {
      throw new Error(
        "home-header.component.ts" + " - selectSearch() - " + err.message
      );
    }
  }


  loadSearchData(MapDataDetails)
  {
      let nFloor = 0; //no floor info for this
      this.MCampusId = parseInt(MapDataDetails.CampusId);
      this.MBuildingId = (MapDataDetails.BuildId);
      nFloor = parseInt(MapDataDetails.FloorId);
      this.MFloorId = parseInt(MapDataDetails.FloorId);
      this.MSubZoneId = parseInt(MapDataDetails.SubZoneId);
      this.AlertId = MapDataDetails.AlertId;
      this.AlertStatus = this.Alert_raisedon.resident_search;
      this.ResidentId = MapDataDetails.ResidentId;
      this.selectedCampus = parseInt(MapDataDetails.CampusId);
      this.selectedBuilding = parseInt(MapDataDetails.BuildId);
      this.selectedFloor = parseInt(MapDataDetails.FloorId);
      this.selectedSubZone = parseInt(MapDataDetails.SubZoneId);
      let _SharedDetails = this.SharedDetails;

      if (_SharedDetails != undefined) {
        if (_SharedDetails.SharedAlertStatus != this.AlertStatus) {
          _SharedDetails.SharedAlertId = "";
          _SharedDetails.SharedResidentId = "";
        }
      }

      if (_SharedDetails != undefined) {
        if (
          parseInt(MapDataDetails.CampusId) == _SharedDetails.SharedCampusId &&
          _SharedDetails.SharedFloorId == 0
        ) {
          let arrResidentId;
          if(_SharedDetails.SharedResidentId != undefined)
            arrResidentId = _SharedDetails.SharedResidentId.toString().split(",");
          if (arrResidentId.length == this.Max_AlertLimit) {
            _SharedDetails.SharedResidentId = "";
            this.alertSharedService.changeShared(_SharedDetails);
            arrResidentId = [];
          }
          if (!arrResidentId.includes(MapDataDetails.ResidentId.toString())) {
            if (_SharedDetails.SharedResidentId == "") {
              _SharedDetails.SharedResidentId = MapDataDetails.ResidentId;
            } else {
              _SharedDetails.SharedResidentId =
                MapDataDetails.ResidentId +
                "," +
                this.SharedDetails.SharedResidentId;
            }
          }
          if (this.SharedDetails != undefined) {
            _SharedDetails = set_SharedDetails(
              parseInt(MapDataDetails.CampusId),
              parseInt(MapDataDetails.BuildId),
              nFloor,
              parseInt(MapDataDetails.SubZoneId),
              this.SharedDetails.SharedAlertId,
              this.Alert_raisedon.resident_search,
              this.SharedDetails.SharedResidentId,
              this.alertSharedService
            );
          } else {
            _SharedDetails = set_SharedDetails(
              parseInt(MapDataDetails.CampusId),
              parseInt(MapDataDetails.BuildId),
              nFloor,
              parseInt(MapDataDetails.SubZoneId),
              MapDataDetails.AlertId,
              this.Alert_raisedon.resident_search,
              MapDataDetails.SharedResidentId,
              this.alertSharedService
            );
          }
          if (nFloor == 0) {
            this.CheckAlertData = set_alertChangeorClick(
              0,
              2,
              this.alertSharedService
            );
          } else if (
            _SharedDetails.SharedAlertStatus ==
            this.Alert_raisedon.resident_search
          ) {
            const _SharedFloorAlertDetails = set_SharedFloorDetails(
              MapDataDetails,
              this.Alert_raisedon.resident_search,
              this.alertSharedService
            );
            if (_SharedFloorAlertDetails)
              {
                console.log("set the value");
              }
            this.onMapselected(1,"");
          } else {
            this.onMapselected(1,"");
          }
        } else {
          _SharedDetails.SharedAlertId = "";
          _SharedDetails.SharedResidentId = "";
          this.alertSharedService.changeShared(_SharedDetails);
          _SharedDetails.SharedResidentId = MapDataDetails.ResidentId;

          if (this.SharedDetails != undefined) {
            _SharedDetails = set_SharedDetails(
              parseInt(MapDataDetails.CampusId),
              parseInt(MapDataDetails.BuildId),
              nFloor,
              parseInt(MapDataDetails.SubZoneId),
              this.SharedDetails.SharedAlertId,
              this.Alert_raisedon.resident_search,
              this.SharedDetails.SharedResidentId,
              this.alertSharedService
            );
          } else {
            _SharedDetails = set_SharedDetails(
              parseInt(MapDataDetails.CampusId),
              parseInt(MapDataDetails.BuildId),
              nFloor,
              parseInt(MapDataDetails.SubZoneId),
              MapDataDetails.AlertId,
              this.Alert_raisedon.resident_search,
              MapDataDetails.SharedResidentId,
              this.alertSharedService
            );
          }
          if (
            _SharedDetails.SharedAlertStatus ==
            this.Alert_raisedon.resident_search
          ) {
            const _SharedFloorAlertDetails = set_SharedFloorDetails(
              MapDataDetails,
              this.Alert_raisedon.resident_search,
              this.alertSharedService
            );
            if (_SharedFloorAlertDetails)
              {
                console.log("set the value");
              }
            this.onMapselected(1,"");
          }
          this.onMapselected(1,"");
        }
      }
  }

  //////End Search///////////
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    try {
      const log: string[] = [];
      for (const propName in changes) {
        const changedProp = changes[propName];
        const to = JSON.stringify(changedProp.currentValue);
        if (changedProp.isFirstChange()) {
          log.push(`Initial value of ${propName} set to ${to}`);
        } else {
          const from = JSON.stringify(changedProp.previousValue);
          log.push(`${propName} changed from ${from} to ${to}`);
          const Campusinfo = groupBy(
            this.CampusFulldetails["Campusinfo"],
            "CampusId"
          );
          if (propName == "MCampusId" && this.MCampusId != 0) {
            ///If Campus changed

            this.selectedCampus = this.MCampusId;
            this.selectedBuildingdetails[this.Building_key] = [];
            this.selectedFloordetails[this.Floor_key] = [];
            const buildinginfo = groupBy(
              Campusinfo[this.selectedCampus][0]["Campusdetail"],
              "buildingname"
            );

            for (const key in buildinginfo) {
              const arrBuildinginfo = {
                CampusId: this.selectedCampus,
                BuildingId: buildinginfo[key][0].buildingid,
                BuildingName: buildinginfo[key][0].buildingname,
                PolyLine: buildinginfo[key][0].polyline,
                AlertBuildingcnt: buildinginfo[key][0].AlertBuildingcnt,
              };
              this.selectedBuildingdetails[this.Building_key].push(
                arrBuildinginfo
              );
            }
          }
          if (propName == "MBuildingId" && this.MBuildingId != '0') {
            ///If Building changed

            this.selectedBuilding = this.MBuildingId;
            this.selectedFloordetails[this.Floor_key] = [];
            this.selectedSubzonedetails[this.SubZone_key] = [];
            const buildinginfo = groupBy(
              Campusinfo[this.selectedCampus][0]["Campusdetail"],
              "buildingid"
            );
            const floorinfo = groupBy(
              buildinginfo[this.selectedBuilding][0]["floors"],
              "floorname"
            );
            let subzoneinfo = [] as any;
            
            for (const key in floorinfo) {
              if (floorinfo[key][0].floorid != null) {
                const arrFloorinfo = {
                  CampusId: this.selectedCampus,
                  BuildingId: this.selectedBuilding,
                  FloorId: floorinfo[key][0].floorid,
                  FloorName: floorinfo[key][0].floorname,
                  FloorPlanImage: floorinfo[key][0].FloorPlanImage,
                  AlertFloorcnt: floorinfo[key][0].AlertFloorcnt,
                };
                this.selectedFloordetails[this.Floor_key].push(arrFloorinfo);
                
                if(floorinfo[key][0].subzones.length > 0)              
                  subzoneinfo = groupBy(floorinfo[key][0]["subzones"], 'subzonename');
              }
            }
            
            for (const keysub in subzoneinfo) {
              const arrSubZoneinfo = { "CampusId": this.selectedCampus, "BuildingId": this.selectedBuilding, "FloorId": subzoneinfo[keysub][0].zoneid, "SubZoneId": subzoneinfo[keysub][0].subzoneid, "SubZoneName": subzoneinfo[keysub][0].subzonename, "FloorPlanImage": floorinfo[keysub][0].FloorPlanImage, "AlertFloorcnt": floorinfo[keysub][0].AlertFloorcnt };
              this.selectedSubzonedetails[this.SubZone_key].push(arrSubZoneinfo);
            }
          }

          if (propName == "MFloorId" && this.MFloorId != 0) {
            ///If Floor changed
            if (this.selectedFloor != this.MFloorId) {
              this.selectedBuilding = this.MBuildingId;
              this.selectedFloor = this.MFloorId;
              this.selectedSubZone = this.MSubZoneId;
              this.onMapLoad();
            }
          } else if (
            propName == "MFloorId" &&
            this.SharedFloorAlertDetails != undefined
          ) {
            this.SharedFloorAlertDetails = undefined;

            this.onMapLoad();
          }
          
          if (propName == "MSubZoneId" && this.MSubZoneId != 0) {
            ///If SubZone changed
            if (this.selectedSubZone != this.MSubZoneId) {
              this.selectedBuilding = this.MBuildingId;
              this.selectedFloor = this.MFloorId;
              this.selectedSubZone = this.MSubZoneId;
              this.onMapLoad();
            }
          }
          if(propName == "MSubZoneId" &&  this.SharedFloorAlertDetails != undefined) {
            this.SharedFloorAlertDetails = undefined;
            
            this.onMapLoad();
          }
          
        }
        this.changeLog.push(log.join(", "));
      }
      // this.checkCampusAlertNotifyData();
    } catch (err) {
      throw new Error(
        "home-header.component.ts" + " - ngOnChanges() - " + err.message
      );
    }
  }

  //Campus details load via api
  getCampusdetails(bNeedLoadMap: boolean) {
    let campusmapdetails;
    const reqCampusInfo: getCampus = {
      authKey: this.authkey,
    };
    
    this.homeService.getMapDetails(reqCampusInfo).pipe(takeUntil(this._onDestroy))
      .subscribe((CampusDetails: any[]) => {
        
        campusmapdetails = CampusDetails;
        let FirstCampusId = 0;        
        let TREE_DATA: FoodNode[];          
        const _floorinfo = JSON.parse(campusmapdetails[0]);             
        this.getcampusfulldetails = campusmapdetails[1];
        TREE_DATA = _floorinfo;
        
        this.floorGroups = TREE_DATA;
        FirstCampusId = parseInt(_floorinfo[0].id.split("_")[0]);          
        if (bNeedLoadMap)
        {
          this.selectMapData(FirstCampusId,0,0,0);
          this.selectMapdetails = FirstCampusId + "_" + 0 + "_" + 0 + "_" + 0;
        }
          
          this.dataSource.data = TREE_DATA;
      });
    
  }
  
  selectMapData(CampusId: number, BuildId: number, FloorId:number, SubZoneId:number) {
    try {            
          this.selectedCampus = CampusId;
          this.selectedBuilding =BuildId;
          this.selectedFloor = FloorId;   
          this.selectedSubZone = SubZoneId; 
      const campusid: getCampusFullByCampusId = {        
        authKey: this.authkey,
        campusId: CampusId
      }
      this.apiService.getCampusbyCampusId(campusid).pipe(takeUntil(this._onDestroy))
        .subscribe((CampusFulldetails: any[]) => {
          this.CampusFulldetails = CampusFulldetails;            
          this.selectdropdownvalue(CampusId,BuildId,FloorId,SubZoneId);                                      
            this.onMapselected(0,"");
        });
    }
    catch (err) {
      throw new Error('home-header.component.ts' + ' - selectMapData() - ' + err.message);
    }
  }
  
  selectdropdownvalue(CampusId,BuildId,FloorId,SubZoneId)
  {
    let mapSelectName = "";

    if (this.getcampusfulldetails!=undefined)
    {
     this.getcampusfulldetails.forEach(function (data) {
        if(SubZoneId > 0)
        {
          if(data.CampusId == CampusId && data.BuildId == BuildId && data.FloorId == FloorId && SubZoneId == data.SubZoneId)        
            mapSelectName = data.SubZoneDesc;        
        }
        else if(SubZoneId == 0 && FloorId >0)
        {
          if(data.CampusId == CampusId && data.BuildId == BuildId && data.FloorId == FloorId)        
            mapSelectName = data.FloorName;        
        }
        if(FloorId == 0 && data.CampusId == CampusId)
          mapSelectName = data.CampusName;
      })
    }
    else if (this.CampusFulldetails!=undefined)
    {
      const Campusinfo = groupBy(this.CampusFulldetails["Campusinfo"], 'CampusId');

      if (CampusId>0 && parseInt(BuildId)== 0 && FloorId==0 && SubZoneId==0)
      {
        mapSelectName=Campusinfo[CampusId][0].CampusName;
      }
      else if (CampusId>0 && parseInt(BuildId)>0 && FloorId>0 && SubZoneId==0)
      {
       
        for (let nidx=0; nidx<=(Campusinfo[CampusId][0].Campusdetail.length-1); nidx++)
        {
          for (let nfloor=0;nfloor<=(Campusinfo[CampusId][0].Campusdetail[nidx].floors.length-1);nfloor++)
          {
            if (Campusinfo[CampusId][0].Campusdetail[nidx].floors[nfloor].floorid==FloorId)
            {
              mapSelectName=Campusinfo[CampusId][0].Campusdetail[nidx].floors[nfloor].floorname;
            }
          }

        }

      }
      else if (CampusId>0 && parseInt(BuildId)>0 && FloorId>0 && SubZoneId>0)
      {
        for (let nidx=0; nidx<=(Campusinfo[CampusId][0].Campusdetail.length-1); nidx++)
        {
          for (let nfloor=0;nfloor<=(Campusinfo[CampusId][0].Campusdetail[nidx].floors.length-1);nfloor++)
          {
            if (Campusinfo[CampusId][0].Campusdetail[nidx].floors[nfloor].floorid==FloorId)
            {
              mapSelectName=Campusinfo[CampusId][0].Campusdetail[nidx].floors[nfloor].floorname;
            }
          }

        }
      }
      
    }

    this.selectedMapIds = mapSelectName;
  }


  home(Type): void {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }

      if (this.SharedDetails!=undefined)
      {
        const _SharedDetails =set_SharedDetails(this.SharedDetails.SharedCampusId,0,0,0,"",this.Alert_raisedon.leftside_alertselect,"",this.alertSharedService);
        console.log(_SharedDetails);
      }

      const SharedDetails = undefined;
      this.SharedFloorAlertDetails = undefined;
      this.alertSharedService.changeSharedHomeMapdetails(SharedDetails);
      this.alertSharedService.changesharedFloorAlert(this.SharedFloorAlertDetails);

      this.mainLayoutService.toggleSideNavRight.emit();

      if (this.ShareSideNavDetails.IscheckSideNav == 1) {
        this.mainLayoutService.toggleSideNavRight.emit();
      }
      
      if(Type == 2)
      {              
          this.document.getElementById("chkresident").checked = false;
          this.document.getElementById("chkstaff").checked = false;
          if(this.document.getElementById("chkbuilding") != null)
            this.document.getElementById("chkbuilding").checked = false;
          if(this.document.getElementById("chkapproximate") != null)
            this.document.getElementById("chkapproximate").checked = false;
            
            this.checkbuilding = false;
            this.checkresident = false;
            this.checkstaff = false;
            this.checkapproximate = false;  
            
          this.checkbtnclickReset = 1;
          this.current_enumcheckvalue = enumCheckResidenStaff.None;
          this.getSearchOption(enumCheckResidenStaff.None);
          this.ChangeLoactionFlag();
          this.onRefreshClicked();
          this.livecheckfunction(enumCheckResidenStaff.None);   
          
          if (this.SharedDetails!=undefined)
          {
            const _SharedDetails =set_SharedDetails(this.SharedDetails.SharedCampusId,0,0,0,"",this.Alert_raisedon.leftside_alertselect,"",this.alertSharedService);
            console.log(_SharedDetails)
          }
      }
            
      redirecttohome(this.router);
    } catch (err) {
      throw new Error("alerts.component.ts" + " - home() - " + err.message);
    }
  }
  //Floor select change
  selectunit() {
    try {
      if (HomeHeaderComponent.isHomeMenuClosed == false) {
        this.homeService.toggleHomeMenuNav.emit();
      }

      this.onMapselected(0,"");
    } catch (err) {
      throw new Error(
        "home-header.component.ts" + " - selectunit() - " + err.message
      );
    }
  }

  @Output() onMapChanged = new EventEmitter<any>();

  //Select Changed
  onMapselected(alert, alertId): void {
    try {
      this.MCampusId = this.selectedCampus;
      this.MBuildingId = this.selectedBuilding.toString();
      this.MFloorId = this.selectedFloor;
      this.MSubZoneId = this.selectedSubZone;
      
      if (alert == 0) {
        const _SharedDetails = set_SharedDetails(this.MCampusId, this.MBuildingId, this.MFloorId, this.MSubZoneId, alertId, this.Alert_raisedon.leftside_alertselect, this.ResidentId, this.alertSharedService);
        console.log(_SharedDetails);
        if (this.MFloorId == 0) {
          this.SharedFloorAlertDetails = undefined;
          this.alertSharedService.changesharedFloorAlert(
            this.SharedFloorAlertDetails
          );
        }
      }
      this.onMapLoad();
    } catch (err) {
      throw new Error(
        "home-header.component.ts" + " - onMapselected() - " + err.message
      );
    }
  }

  onMapLoad(): void {
    try {
      //Alert invoked
      if (this.SharedDetails != undefined) {
        if (this.SharedDetails.SharedCampusId != undefined)
          this.selectedCampus = this.SharedDetails.SharedCampusId;
        
         this.selectedBuilding = this.SharedDetails.SharedBuildingId;
        if (this.SharedDetails.SharedFloorId != undefined)
          this.selectedFloor = this.SharedDetails.SharedFloorId;

        if (this.SharedDetails.SharedSubZoneId != undefined)
        this.selectedSubZone = this.SharedDetails.SharedSubZoneId;

        if (this.SharedDetails.SharedAlertId != undefined)
          this.AlertId = this.SharedDetails.SharedAlertId;
        
        if (this.SharedDetails.SharedResidentId != undefined)          
        this.ResidentId = this.SharedDetails.SharedResidentId;
        
        if (this.SharedDetails.SharedAlertStatus != undefined)          
         this.AlertStatus = this.SharedDetails.SharedAlertStatus;
      }

      this.MCampusId = this.selectedCampus;
      this.MBuildingId = this.selectedBuilding;
      this.MFloorId = this.selectedFloor;
      this.MSubZoneId = this.selectedSubZone;
      
      const CampusId = this.selectedCampus;
      const BuildingById = this.selectedBuilding;
      const FloorId = this.selectedFloor;
      const SubZoneId = this.selectedSubZone;
      
      //To load map in main based on select change
      const mapdetail = [] as any[];
      mapdetail[0] = CampusId;
      mapdetail[1] = BuildingById;
      mapdetail[2] = FloorId;
      mapdetail[3] = this.AlertId;
      mapdetail[4] = this.AlertStatus;
      mapdetail[5] = this.ResidentId;
      this.AlertId = "";
      this.AlertStatus = 0;
      
      if (this.checkbuilding == true) { 
        mapdetail[6] = 1;
      }
      else
        mapdetail[6] = 0;
        
      if (this.checkapproximate == true) {
        mapdetail[7] = 1;
      }
      else
        mapdetail[7] = 0;
        
      mapdetail[8] = SubZoneId;
      
      this.CampusFulldetails["SelectedMapInfo"] = mapdetail;

      //Alert invoked
      if (this.SharedDetails != undefined) {
        this.SharedDetails = undefined;
      }
      
      if( this.MBuildingId.toString().indexOf("_") != -1)
      {                 
        this.selectMapdetails = this.MCampusId + "_0_0_0" ;
        this.selectdropdownvalue(this.MCampusId,0,0,0);
      }
      else
      {
        this.selectMapdetails = this.MCampusId + "_" + this.MBuildingId + "_" + this.MFloorId + "_" + this.MSubZoneId;
        if (this.MSubZoneId>0)
          this.treeExpand(this.MCampusId + "_" + this.MBuildingId + "_" + this.MFloorId + "_0");
        this.selectdropdownvalue(this.MCampusId,this.MBuildingId,this.MFloorId,this.MSubZoneId);   
      }
      
      this.onMapChanged.emit(this.CampusFulldetails);
    } catch (err) {
      throw new Error(
        "home-header.component.ts" + " - onMapLoad() - " + err.message
      );
    }
  }
  current_enumcheckvalue;
  onRefreshClicked() {
    if (this.checkstaff == false && this.checkresident == false) {
      return;
    }
    const _data: getResStaffCurrentLocation = {
      authKey: this.authkey,
    };
    this.apiService
      .getStaffResidentCurrentLocation(_data)
      .subscribe((staffresidentinfo: staffresidentdata[]) => {
        this.apiService
          .getAllRooms(_data)
          .subscribe((roomdetailsdatainfo: any[]) => {
            const RoomDetailsinfo = groupBy(roomdetailsdatainfo, "RoomId");
            this.alertSharedService.changeSharedRoomDetailsBased(
              RoomDetailsinfo
            );
            this.alertSharedService.changeSharedResidentStaffBased(
              staffresidentinfo
            );
            const ResidentsStaff = groupBy(staffresidentinfo, "RoomId");
            this.alertSharedService.changeResidentStaff(ResidentsStaff);
            this.alertSharedService
              .currentSharedResidentStaffBased()
              .subscribe((SharedResidentStaffBasedDetails) => {
                this._staffresidentinfo = SharedResidentStaffBasedDetails;
              });
            this.selectunit();
          });
      });
  }
  livecheckfunction(enumcheckvalue) {
    setTimeout(() => {
      this.current_enumcheckvalue = enumcheckvalue;
      this.getSearchOption(enumcheckvalue);
      this.ChangeLoactionFlag();
      this.onRefreshClicked();
    }, 5000);
  }

  toggleresVisibility(e) {
    this.document.getElementById("divliveviewloading").style.display = "table";
    this.document.getElementById("chkresident").classList.remove("clsenable");
    this.document.getElementById("chkstaff").classList.remove("clsenable");
    this.document.getElementById("chkresident").classList.add("clsdisable");
    this.document.getElementById("chkstaff").classList.add("clsdisable");
    if (e.checked == true) {
      this.checkresident = true;
      if (this.checkstaff == true) {
        this.livecheckfunction(enumCheckResidenStaff.All);
      } else {
        this.livecheckfunction(enumCheckResidenStaff.Resident);
      }
    } else if (e.checked == false) {
      this.checkresident = false;
      if (this.checkstaff == true) {
        this.livecheckfunction(enumCheckResidenStaff.Staff);
      } else {
        this.livecheckfunction(enumCheckResidenStaff.None);
      }
    }
  }

  togglestaffVisibility(event) {
    this.document.getElementById("divliveviewloading").style.display = "table";
    this.document.getElementById("chkresident").classList.remove("clsenable");
    this.document.getElementById("chkstaff").classList.remove("clsenable");
    this.document.getElementById("chkresident").classList.add("clsdisable");
    this.document.getElementById("chkstaff").classList.add("clsdisable");
    if (event.checked == true) {
      this.checkstaff = true;
      if (this.checkresident == true) {
        this.livecheckfunction(enumCheckResidenStaff.All);
      } else {
        this.livecheckfunction(enumCheckResidenStaff.Staff);
      }
    } else if (event.checked == false) {
      this.checkstaff = false;
      if (this.checkresident == true) {
        this.livecheckfunction(enumCheckResidenStaff.Resident);
      } else {
        this.livecheckfunction(enumCheckResidenStaff.None);
      }
    }
  }

  togglerLiveVisibility(event) {
    this.checkresident = false;
    this.checkstaff = false;

    if (event.checked == true) {
      if (this.checkliveresidentdata == "1") {
      
        this.document.getElementById("chkresident").classList.remove('clsdisable'); 
          this.document.getElementById("chkresident").classList.add('clsenable'); 
      }
        
      if (this.checklivestaffdata == "1") {
      
        this.document.getElementById("chkstaff").classList.remove('clsdisable'); 
        this.document.getElementById("chkstaff").classList.add('clsenable'); 
      }
    } else if (event.checked == false) {
      this.document.getElementById("chkresident").classList.remove('clsdisable'); 
      this.document.getElementById("chkresident").classList.add('clsenable'); 
      this.document.getElementById("chkstaff").classList.remove('clsdisable'); 
      this.document.getElementById("chkstaff").classList.add('clsenable'); 
      

    }

    this.livecheckfunction(enumCheckResidenStaff.None);
  }
  
  togglebuildingVisibility(event) {  
    this.document.getElementById("divliveviewloading").style.display = "table";  
    if(event.checked == true)
    {
      this.checkbuilding = true;
      if (this.checkapproximate == true) {        
        this.livecheckfunction(enumCheckResidenStaff.BothOutline);
      }
      else {
        this.livecheckfunction(enumCheckResidenStaff.BuildingOutline);
      }
    }
    else if (event.checked == false) {
      this.checkbuilding = false;
      if (this.checkapproximate == true) {
        this.livecheckfunction(enumCheckResidenStaff.ApproximateOutline);
      }
      else {
        this.livecheckfunction(enumCheckResidenStaff.None);
      }
    }    
   }

   toggleapproximateVisibility(event) {    
    this.document.getElementById("divliveviewloading").style.display = "table";
    if(event.checked == true)
    {
      this.checkapproximate = true;
      if (this.checkbuilding == true) {        
        this.livecheckfunction(enumCheckResidenStaff.BothOutline);
      }
      else {
        this.livecheckfunction(enumCheckResidenStaff.ApproximateOutline);
      }
    }
    else if (event.checked == false) {
      this.checkapproximate = false;
      if (this.checkbuilding == true) {
        this.livecheckfunction(enumCheckResidenStaff.BuildingOutline);
      }
      else {
        this.livecheckfunction(enumCheckResidenStaff.None);
      }
    }
}

  ChangeLoactionFlag() {
    const _SharedLocationDetails = {
      resident: this.checkresident,
      staff: this.checkstaff,
      livestaffdata: this.checklivestaffdata,
      liveresidentdata: this.checkliveresidentdata,
      buildingoutlet:this.checkbuilding,
      approxoutlet:this.checkapproximate
    };
    this.alertSharedService.changeSharedLocation(_SharedLocationDetails);
  }
  clkMenusideTechnav() {
    this.homeService.toggleHomeMenuNav.emit();
  }

  closeSidebarnav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
  }
  
  clkMapOptions()
  {            
    
    if(HomeHeaderComponent.checkmatselect == 1)
        HomeHeaderComponent.checkmatselect = 0;
      this.ReadMore = !this.ReadMore; //not equal to condition
      HomeHeaderComponent.visible = !HomeHeaderComponent.visible;     
  }
  
  clkmatselect()
  {
    HomeHeaderComponent.checkmatselect = 1;
    this.selectMapdetails = this.MCampusId + "_" + this.MBuildingId + "_" + this.MFloorId + "_" + this.MSubZoneId;
    if(HomeHeaderComponent.visible)
      HomeHeaderComponent.visible = false;
  }
  
  clkbtnMapDetailsDone()
  {
    this.selectedMapIds = this.selectedMapName;
    HomeHeaderComponent.checkmatselect = 0;          
    const campusId = this.selectMapdetails.split("_");    
    if (this.MCampusId >0 && parseInt(campusId[0]) ==0 )
        campusId[0]=this.MCampusId.toString() ;
        
    this.selectMapData(parseInt(campusId[0]),parseInt(campusId[1]),parseInt(campusId[2]),parseInt(campusId[3]));    
  }
  
  clickcollapse(clickvalue) {                   
    let result = [] as any;
    this.dataSource.data.forEach(node => {
      result = result.concat(this.treeControl.getDescendants(node).filter(x => x.id ).map(x => x));      
    });
    
    const treecontrol = this.treeControl;
    result.forEach(function (data)
    {
      if(data.id == clickvalue.id)
        treecontrol.isExpanded;
      else
        treecontrol.collapse(data)  
    })
    console.log(result);
  }
  
  treeExpand(treeid)
  {
    let result = [] as any;
    this.dataSource.data.forEach(node => {
      result = result.concat(this.treeControl.getDescendants(node).filter(x => x.id ).map(x => x));      
    });
    
    const treecontrol = this.treeControl;
    result.forEach(function (data)
    {
      if(data.id == treeid)
      {
        if (treecontrol.isExpanded(data) !=true) //if it could not expand then only we expand
            treecontrol.expandDescendants(data);
      }
      //else
       // treecontrol.collapse(data)  
    })
    console.log("treeExpand")
    console.log(result);
  }
  
  clkmatradiomapdata(event)  
  {
    console.log(event)
    this.selectedMapName = event.source._elementRef.nativeElement.innerText;
  }
  
  onCloseCancel()
  {
    if(HomeHeaderComponent.checkmatselect == 1)
      HomeHeaderComponent.checkmatselect = 0;
    this.selectMapdetails = 0 + "_" + 0 + "_" + 0 + "_" + 0;
    // this.getCampusdetails(true);    
    // this.treeControl.collapseAll();
  }
  
  hasChild = (_: number, node: FoodNode) =>
  !!node.children && node.children.length > 0;

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
