import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Location } from "@angular/common";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { TooltipPosition } from "@angular/material/tooltip";
import { Subject } from "rxjs";
import {
  appsettings,
  enumFileNamelength,
  enumEnableDisable,
  enumTagType
} from "../../../app-settings";
import {
  StaffDesignationList,
  StaffGroupList,
  StaffForCreation,
  StaffForCreationResponse,
  StaffShift,
  StaffGroup,
  getStaffRoleList,
  getStaffGroup,
  getPhoneNumberOtp,
  getEmailOtp,
  checkPagerNumber,
  checkEmailId,
  checkPhoneNumber,
  FloorGroup
} from "../staff";
import { StaffService } from "../staff.service";
import { TagIdfoundResponse, checkTagId, getCampus } from "../../../api";
import { ApiService } from "../../../api.service";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../app-general";
import { Router } from "@angular/router";
import { VerifyStaffComponent } from "../verify-staff/verify-staff.component";
import { HttpClient } from "@angular/common/http";
import { HostListener } from "@angular/core";
import { AppComponent } from "../../../app.component";
import { takeUntil } from "rxjs/operators";
import { AlertMessageComponent } from "../../../alert-message/alert-message.component";
const URL = appsettings.URLinfo + "uploadImage_Staff";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-add-staff",
  templateUrl: "./add-staff.component.html",
  providers: [StaffService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-staff.component.scss"],
})
export class AddStaffComponent implements OnInit, OnDestroy {
  staffDesignationList: StaffDesignationList[];
  staffGroupList: StaffGroupList[];
  public staffCreationForm: UntypedFormGroup;
  selecetdFile: File;
  uploadimagename = "";
  public watcher: Observable<boolean>;
  columns = 8;
  rowspan = 2;
  staffimageUrl;
  hide = true;
  visible: boolean;
  passwordIcon;
  tooltipDesc;
  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = new UntypedFormControl(this.positionOptions[3]);
  Groupdetails: StaffGroup[];
  index = 0;
  tabindex;
  checkstaffverify = 1;
  staffphonenumber = 0;
  private staffURL = appsettings.URLinfo + "request_OTP_Register";
  buttonvalue;
  staffverifyresult;
  updatestaffverify = 0;
  staffgender = 0;
  staffbooladd = true;
  screenWidth;
  staffEmailId = "";
  emailbuttonvalue;
  updatestaffemailverify = 0;
  checkstaffemailverify = 1;
  emailrandomnumber = 0;
  checkverifyPhoneclick = 0;
  checkverifyEmailclick = 0;
  boolstaffphoneverify = true;
  boolstaffemailverify = true;
  staffLoading = false;
  checknetwork;
  StaffLength;
  private _onDestroy: Subject<void> = new Subject<void>();
  staffrowHeight = "69px";
  checkPhoneclick = 0;
  checkEmailclick = 0;
  countrycode;
  addCheck_Invalue = false;
  authkey;
  addNotificationvalue = false;
  checkAddStaffPhone = 0;
  checkAddStaffEmail = 0;
  enableCheckbox = false;
  enablePhoneCheckbox = false;
  Campusdetails;
  selectedCampusId;
  addChkAlertvalue = false;
  addPhoneChkAlertvalue = false;
  floorGroups;
  roleEnableNotify = false;
  Breakpoints = Breakpoints;
  addCheckInReport = 0;
  addCheckAlert = 0;
  addPhoneCheckAlert = 0;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    private router: Router,
    private apiService: ApiService,
    private staffService: StaffService,
    private location: Location,    
    public thisDialogRef: MatDialogRef<AddStaffComponent>,
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private http: HttpClient,
    private breakpointObserver: BreakpointObserver
  ) {
    this.visible = false;
    this.passwordIcon = "visibility_off";
    this.tooltipDesc = "Show Password";
    this.StaffLength = data + 1;
    
    this.breakpoint$.subscribe(() => this.breakpointChanged());        
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 8;
      this.rowspan = 2;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
      this.rowspan = 1;
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 3;
      this.rowspan = 1;
    }
  }

  @ViewChild("clkimageInput") clkimageInput: any;
  public onChangestaff(fileList: FileList) {
    const file = fileList[0];
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(
      removedSpaces_filename
    );
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      this.staffimageUrl = "assets/images/admin-avatar.png";
      this.clkimageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      this.staffimageUrl = "assets/images/admin-avatar.png";
      this.clkimageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Staff Photo as image file."
      );
      this.staffimageUrl = "assets/images/admin-avatar.png";
      this.clkimageInput.nativeElement.value = "";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0]);
      else this.staffimageUrl = "assets/images/admin-avatar.png";

      this.clkimageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 3) {
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 3 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Staff Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  uploadfiletoPath(data, file) {
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const FileName = this.StaffLength + "+" + _filename;
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        this.uploadimagename = res.filename;
        this.staffimageUrl = appsettings.STAFF_FOLDER + res.filename;
      });
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";

    this.getStaffDesignationList();
    this.getStaffGroup();
    this.onResize();
    this.buttonvalue = "Enroll";
    this.emailbuttonvalue = "Enroll";
    this.staffimageUrl = "assets/images/admin-avatar.png";

    this.countrycode = localStorage.getItem("country_code");

    this.staffCreationForm = new UntypedFormGroup({
      firstname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      lastname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      gender: new UntypedFormControl("", [Validators.required]),
      tagid: new UntypedFormControl("", [
        Validators.maxLength(10),
        Validators.max(1073741823),
        Validators.min(1),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
      role: new UntypedFormControl("", [Validators.required]),
      pager: new UntypedFormControl("", [Validators.pattern(appsettings.Number_Pattern)]),
      extension: new UntypedFormControl("", [
        Validators.pattern(appsettings.Number_Pattern),
        Validators.maxLength(5),
      ]),
      photo: new UntypedFormControl(""),
      // address: new UntypedFormControl("", [Validators.maxLength(60)]),
      // city: new UntypedFormControl("", [
      //   Validators.maxLength(60),
      //   Validators.pattern(appsettings.Name_Pattern),
      // ]),
      // state: new UntypedFormControl("", [
      //   Validators.maxLength(60),
      //   Validators.pattern(appsettings.Name_Pattern),
      // ]),
      // zip: new UntypedFormControl("", [
      //   Validators.maxLength(6),
      //   Validators.pattern(appsettings.Number_Pattern),
      // ]),
      // country: new UntypedFormControl("", [
      //   Validators.maxLength(60),
      //   Validators.pattern(appsettings.Name_Pattern),
      // ]),
      phone: new UntypedFormControl("", [
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(60),
        Validators.email,
        Validators.pattern(
          appsettings.Email_Pattern
        ),
      ]),
      password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(20),
        Validators.pattern(appsettings.Password_Pattern),
      ]),
      notes: new UntypedFormControl(""),
      group: new UntypedFormControl(""),
      campus: new UntypedFormControl('',[Validators.required]),
    });
     this.getCampusFloordetails();
  }
  
  getCampusFloordetails() 
  { 
    try
    {
      const campusid: getCampus = {        
        authKey: this.authkey
      }
      this.staffService.getMapDetails(campusid).pipe(takeUntil(this._onDestroy))
      .subscribe((CampusDetails : any[])=>{  
        this.Campusdetails = CampusDetails; 
                
        let formfloorgroup : FloorGroup[];
        let floors = "";  
        let oldCampusId = 0;
        let oldBuildId = 0; 
        let campus = "";    
        for(let i = 0; i < CampusDetails.length; i++)
        {                          
          console.log("oldcampusid : " + oldCampusId,CampusDetails[i].CampusId,i)
          if(oldCampusId != CampusDetails[i].CampusId)
          {
            console.log("oldcampusid 1: " + oldCampusId,CampusDetails[i].CampusId,i)      
            if(i > 0 )    
            campus += "]";
            
            if(i > 0 ) 
            {
              if(i != CampusDetails.length - 1 || CampusDetails[i].FloorId == null || CampusDetails[i].CampusId != CampusDetails[i-1].CampusId)
              campus += "},";  
            else
              campus += "}";  
            }      
            
            campus += "{";
            campus += "\"value\": \""+CampusDetails[i].CampusId+"_0\",\"campus\": \""+CampusDetails[i].CampusName+"\","
            campus += "\"name\": \"\","
            campus += "\"floors\": ["; 
            
            oldCampusId = CampusDetails[i].CampusId
                             
          }
          
          if(oldBuildId != CampusDetails[i].BuildId)
          {
            if(CampusDetails[i].BuildId != null) 
            {
              floors = "{ \"value\": \""+CampusDetails[i].BuildId+"\",\"viewValue\": \""+CampusDetails[i].BuildingName+"\",\"isfloor\":\"0\"}"; 
              oldBuildId = CampusDetails[i].BuildId;
              if((i != CampusDetails.length - 1 && CampusDetails[i].CampusId == CampusDetails[i+1].CampusId) || CampusDetails[i].FloorId != null)
                floors += ",";  
              campus += floors; 
            }
          }
          
          if(CampusDetails[i].FloorId != null) 
          {
            floors = "{ \"value\": \""+CampusDetails[i].FloorId+"_1\",\"viewValue\": \""+CampusDetails[i].FloorName+"\",\"isfloor\":\"1\"}";                         
        
            if(i != CampusDetails.length - 1 && CampusDetails[i].CampusId == CampusDetails[i+1].CampusId)
                floors += ",";  
            
              campus += floors;  
          }                                       
                    
        }
        floors = "[ " + campus + "]}]";       
        const _floorsinfo = JSON.parse(floors);             
                 
        formfloorgroup = _floorsinfo;
                
        this.floorGroups = formfloorgroup;   
        
      });  
    }
    catch (err) {
      throw new Error('add-resident.component.ts' + ' - getCampusFloordetails() - ' + err.message);
    }
   
  }
  
  changecampus(campusid)
  {           
    this.selectedCampusId = campusid;   
  }
  
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.staffCreationForm.controls).forEach((field) => {
        // {1}
        const control = this.staffCreationForm.get(field); // {2}
        const staffForm_Id = document.getElementById(event.target.id);
        if (staffForm_Id != null) {
          if (field == staffForm_Id.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
      if(this.staffCreationForm.valid && this.staffCreationForm.controls.phone.value != "" && this.updatestaffverify == 0)      
        this.checkstaffverify = 0;      
      else
        this.checkstaffverify = 1;
        
      if(this.staffCreationForm.valid && this.staffCreationForm.controls.email.value != "" && this.updatestaffemailverify == 0)      
        this.checkstaffemailverify = 0;      
      else
        this.checkstaffemailverify = 1;
        
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }
  
  changeMapDetails()
  {    
    if(this.staffCreationForm.valid && this.staffCreationForm.controls.phone.value != "" && this.updatestaffverify == 0)      
      this.checkstaffverify = 0;      
    else
      this.checkstaffverify = 1;
      
    if(this.staffCreationForm.valid && this.staffCreationForm.controls.email.value != "" && this.updatestaffemailverify == 0)      
      this.checkstaffemailverify = 0;      
    else
      this.checkstaffemailverify = 1;
  }
  
  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    this.screenWidth = window.innerWidth;
    if (isIEOrEdge == true) {
      this.staffrowHeight = "72px";
    }
    if (isIEOrEdge == false && this.screenWidth < 599) {
      this.columns = 3;
      this.rowspan = 1;
      this.staffrowHeight = "87px";
    } else if (isIEOrEdge == true && this.screenWidth < 599) {
      this.columns = 3;
      this.rowspan = 1;
      this.staffrowHeight = "88px";
    } else if (this.screenWidth >= 1097 && isIEOrEdge == false) {
      this.columns = 8;
      this.rowspan = 2;
      this.staffrowHeight = "69px";
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.staffCreationForm.controls[controlName].hasError(errorName);
  };

  public onCloseCancel = () => {
    if (!SessionCheck(this.router)) {
      return;
    }

    if (this.checkPhoneclick == 1 || this.checkEmailclick == 1) {
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to discard the changes?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          this.thisDialogRef.close("Cancel");
        } else {
          return false;
        }
      });
    } else {
      this.thisDialogRef.close("Cancel");
    }
  };

  shift: StaffShift[] = [
    { Name: "6am-2pm", Value: "1" },
    { Name: "2pm-10pm", Value: "2" },
    { Name: "10pm-6am", Value: "3" },
  ];

  getStaffDesignationList(): void {
    try {
      const getRole = localStorage.getItem("role");
      const staffitem: getStaffRoleList = {
        authKey: this.authkey,
      };
      this.staffService
        .getStaffDesignationList(staffitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((staffDesignationList: any[]) => {
          if (
            staffDesignationList != null &&
            staffDesignationList != undefined
          ) {
            staffDesignationList = staffDesignationList.filter(function (e) {
              if (getRole != null) return e.DesignationId >= parseInt(getRole);
            });
            this.staffDesignationList = staffDesignationList;
          }
        });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" +
          " - getStaffDesignationList() - " +
          err.message
      );
    }
  }

  getStaffGroup() {
    try {
      const groupitem: getStaffGroup = {
        authKey: this.authkey,
      };
      this.staffService
        .getStaffGroup(groupitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Groupdetails: any[]) => {
          if (Groupdetails["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Groupdetails = Groupdetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - getStaffGroup() - " + err.message
      );
    }
  }

  public createStaff = (staffFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.boolstaffphoneverify || !this.boolstaffemailverify) {
        return;
      }
      this.staffgender = 1;
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.staffCreationForm.valid && this.staffbooladd) {
        this.staffbooladd = false;
        this.checkTagId(staffFormValue);
      }
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - createStaff() - " + err.message
      );
    }
  };

  checkTagId(staffFormValue) {
    try {
      if (this.staffCreationForm.valid) {
        if (
          staffFormValue.tagid == undefined ||
          staffFormValue.tagid.length == 0
        )
          staffFormValue.tagid = 0;
        const tagidfoundItem: checkTagId = {
          authKey: this.authkey,
          newTagId: parseInt(staffFormValue.tagid),
          resStaffId: 0,
          tagType:enumTagType.Staff
        };
        this.apiService
          .checkTagId(tagidfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: TagIdfoundResponse[]) => {
            if (apiresponse != null && apiresponse != undefined) {
              if (apiresponse.toString() == "not found") {
                this.staffbooladd = false;
                this.checkPagerNumber(staffFormValue);
              } else {
                this.staffbooladd = true;
                if (
                  apiresponse[0].desc == "Resident" ||
                  apiresponse[0].desc == "Staff" ||
                  apiresponse[0].desc == "Asset"
                )
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate tagid found, the " +
                      apiresponse[0].desc +
                      "  '" +
                      apiresponse[0].name +
                      "'  already has this tagid.<br> Please try again with different tagid"
                  );
                else
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate tagid found, the " +
                      apiresponse[0].name +
                      " already has this tagid on " +
                      apiresponse[0].desc +
                      ".<br> Please try again with different tagid"
                  );
                return;
              }
            } else {
              this.staffbooladd = true;
              generateAlertMessage(this.dialog, "Staff creation failed !");
            }
          });
      }
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - checkTagId() - " + err.message
      );
    }
  }

  checkPagerNumber(staffCreationForm) {
    try {
      if (this.staffCreationForm.valid) {
        //tagid found check start
        const pagerfoundItem: checkPagerNumber = {
          authKey: this.authkey,
          staffId: 0,
          newPagerNum: staffCreationForm.pager.toString(),
        };
        this.staffService
          .checkPagerNumber(pagerfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse.toString() == "not found") {
              this.checkPhoneNumber(staffCreationForm);
            } else {
              this.staffbooladd = true;
              generateAlertMessage(
                this.dialog,
                "Duplicate pager number found, the staff '" +
                  apiresponse[0].name +
                  "' already has this pager number.<br> Please try again with different pager number"
              );
              return;
            }
          });

        //tagid found check end
      }
    } catch (err) {
      console.log("err : " + err);
      throw new Error(
        "edit-staff.component.ts" + " - checkPagerNumber() - " + err.message
      );
    }
  }

  checkPhoneNumber(staffFormValue) {
    try {
      if (this.staffCreationForm.valid || this.checkverifyPhoneclick == 1) {
        const phonenumberfoundItem: checkPhoneNumber = {
          authKey: this.authkey,
          newPhoneNumber: staffFormValue.phone.toString(),
          resStaffId: 0,
          resStaffType: 1,
        };
        this.apiService
          .checkPhoneNumber(phonenumberfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiphoneresponse: TagIdfoundResponse[]) => {
            if (apiphoneresponse != null && apiphoneresponse != undefined) {
              if (
                apiphoneresponse.toString() == "not found" &&
                this.checkverifyPhoneclick == 0
              ) {
                this.checkEmailId(staffFormValue);
              } else if (
                apiphoneresponse.toString() == "not found" &&
                this.checkverifyPhoneclick == 1
              ) {
                this.staffbooladd = false;
                this.checkverifyPhoneclick = 0;
                this.registerPhoneNumberForOTP(staffFormValue);
              } else {
                this.staffbooladd = true;
                this.boolstaffphoneverify = true;
                this.checkverifyPhoneclick = 0;
                generateAlertMessage(
                  this.dialog,
                  "Duplicate phone number found, the " +
                    apiphoneresponse[0].desc +
                    " '" +
                    apiphoneresponse[0].name +
                    "' already has this phone number.<br>Please try again with different phone number"
                );
                return;
              }
            } else {
              this.staffbooladd = true;
              this.boolstaffphoneverify = true;
              generateAlertMessage(this.dialog, "Staff creation failed !");
            }
          });
      }
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - checkPhoneNumber() - " + err.message
      );
    }
  }

  checkEmailId(staffFormValue) {
    try {
      if (this.staffCreationForm.valid || this.checkverifyEmailclick == 1) {
        const mailidfoundItem: checkEmailId = {
          authKey: this.authkey,
          newMailId: staffFormValue.email.toString(),
          resStaffId: 0,
        };
        this.apiService
          .checkEmailId(mailidfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apimailresponse: TagIdfoundResponse[]) => {
            if (apimailresponse != null && apimailresponse != undefined) {
              if (
                apimailresponse.toString() == "not found" &&
                this.checkverifyEmailclick == 0
              ) {
                this.addStaffData(staffFormValue);
              } else if (
                apimailresponse.toString() == "not found" &&
                this.checkverifyEmailclick == 1
              ) {
                this.staffbooladd = false;
                this.checkverifyEmailclick = 0;
                this.registerEmailForOTP(staffFormValue);
              } else {
                this.staffbooladd = true;
                this.boolstaffemailverify = true;
                this.checkverifyEmailclick = 0;
                generateAlertMessage(
                  this.dialog,
                  "Duplicate emailid found, the " +
                    apimailresponse[0].desc +
                    " '" +
                    apimailresponse[0].name +
                    "' already has this emailid.<br>Please try again with different emailid"
                );
                return;
              }
            } else {
              this.staffbooladd = true;
              this.boolstaffemailverify = true;
              generateAlertMessage(this.dialog, "Staff creation failed !");
            }
          });
      }
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - checkEmailId() - " + err.message
      );
    }
  }

  private addStaffData = (staffFormValue) => {
    try {
      let _phoneNumber = "";
      let _tagId = 0;
      let _roleId = 0;
      let _pager = "";
      // let _address = "";
      // let _city = "";
      // let _state = "";
      // let _zip = "";
      // let _country = "";
      let _notes = "";
      let _groupId = "";

      if (staffFormValue.phone != "")
        _phoneNumber = this.countrycode + staffFormValue.phone;
      let _addCheckinvalue = 0;
      if (this.addCheck_Invalue == true) _addCheckinvalue = 1;
      else if (this.addCheck_Invalue == false) _addCheckinvalue = 0;
      
      let _addNotificationvalue = 0;
      if(this.addNotificationvalue == true)
        _addNotificationvalue = 1;
      else if(this.addNotificationvalue == false)
        _addNotificationvalue = 0;

      if (
        staffFormValue.tagid != "" ||
        staffFormValue.tagid != undefined ||
        staffFormValue.tagid != null
      )
        _tagId = parseInt(staffFormValue.tagid);
      else _tagId = 0;

      if (
        staffFormValue.role != "" ||
        staffFormValue.role != undefined ||
        staffFormValue.role != null
      )
        _roleId = staffFormValue.role;
      else _roleId = 0;

      if (
        staffFormValue.pager != "" ||
        staffFormValue.pager != undefined ||
        staffFormValue.pager != null
      )
        _pager = staffFormValue.pager;
      else _pager = "";

      // if (
      //   staffFormValue.address != "" ||
      //   staffFormValue.address != undefined ||
      //   staffFormValue.address != null
      // )
      //   _address = staffFormValue.address.replace(/\s+/g, " ").trim();
      // else _address = "";

      // if (
      //   staffFormValue.city != "" ||
      //   staffFormValue.city != undefined ||
      //   staffFormValue.city != null
      // )
      //   _city = staffFormValue.city.replace(/\s+/g, " ").trim();
      // else _city = "";

      // if (
      //   staffFormValue.state != "" ||
      //   staffFormValue.state != undefined ||
      //   staffFormValue.state != null
      // )
      //   _state = staffFormValue.state.replace(/\s+/g, " ").trim();
      // else _state = "";

      // if (
      //   staffFormValue.zip != "" ||
      //   staffFormValue.zip != undefined ||
      //   staffFormValue.zip != null
      // )
      //   _zip = staffFormValue.zip.replace(/\s+/g, " ").trim();
      // else _zip = "";

      // if (
      //   staffFormValue.country != "" ||
      //   staffFormValue.country != undefined ||
      //   staffFormValue.country != null
      // )
      //   _country = staffFormValue.country.replace(/\s+/g, " ").trim();
      // else _country = "";

      if (
        staffFormValue.notes != "" ||
        staffFormValue.notes != undefined ||
        staffFormValue.notes != null
      )
        _notes = staffFormValue.notes.replace(/\s+/g, " ").trim();
      else _notes = "";

      if (
        staffFormValue.group != "" ||
        staffFormValue.group != undefined ||
        staffFormValue.group != null
      )
        _groupId = staffFormValue.group.toString();
      else _groupId = '';
      
      let addisAlerts;  
      if(this.addChkAlertvalue == true)
        addisAlerts = 1;
      else if(this.addChkAlertvalue == false)
        addisAlerts = 0; 
        
      let addisPhoneAlerts;  
      if(this.addPhoneChkAlertvalue == true)
        addisPhoneAlerts = 1;
      else if(this.addPhoneChkAlertvalue == false)
        addisPhoneAlerts = 0;
              
      let selcampusfloorId = 0;
      let isFloor = 0;
      this.selectedCampusId = staffFormValue.campus;
      const campusfromvalue = staffFormValue.campus.split('_');     
      selcampusfloorId = parseInt(campusfromvalue[0]);
      isFloor = parseInt(campusfromvalue[1]);
                
      const staff: StaffForCreation = {
        authKey: this.authkey,
        firstName: staffFormValue.firstname.replace(/\s+/g, " ").trim(),
        lastName: staffFormValue.lastname.replace(/\s+/g, " ").trim(),
        gender: staffFormValue.gender,
        tagId: _tagId,
        roleId: _roleId,
        pager: _pager,
        photo: this.uploadimagename,       
        phone: _phoneNumber,
        email: staffFormValue.email.toString(),
        password: staffFormValue.password.toString(),
        notes: _notes,
        groupId: _groupId,
        verifyNumber: this.updatestaffverify,
        verifyEmail: this.updatestaffemailverify,
        emailOTP: this.emailrandomnumber,
        extension: staffFormValue.extension,
        staffCheckInValue: _addCheckinvalue,
        staffNotificationValue : _addNotificationvalue,
        isAlertsEmail : addisAlerts,
        campusfloorId : selcampusfloorId,
        isAlertPhone : addisPhoneAlerts,
        isfloor : isFloor
        
      };
      console.log("staff : " + JSON.stringify(staff), staff);
      this.staffService
        .addStaffData(staff)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: StaffForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            this.apiService.reloadStaff();
            this.staffbooladd = false;
            generateAlertMessage(this.dialog, "Staff data added successfully.");
          } else if (apiresponse["apiReturn"] == "Fail") {
            this.staffbooladd = true;
            generateAlertMessage(this.dialog, "Staff data added failed.");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          else
          {
            this.staffbooladd = true;
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - addStaffData() - " + err.message
      );
    }
  };

  /////////Show password////////
  clickpassword() {
    this.visible = !this.visible;
    if (this.visible) {
      this.passwordIcon = "visibility";
      this.tooltipDesc = "Hide Password";
    } else {
      this.passwordIcon = "visibility_off";
      this.tooltipDesc = "Show Password";
    }
  }

  radioChange(event) {
    console.log(event);
    const staffTagId = document.getElementById("stafftagid");
    if (staffTagId != null) staffTagId.focus();
    if(this.staffCreationForm.valid)
    {
      this.getstaffnumber(this.staffCreationForm.value.phone);
      this.getstaffEmail(this.staffCreationForm.value.email);
    }
  }

  getstaffnumber(value)
  {       
    this.checkstaffverify = 1; 
    if (value.length==0)
    {
      this.buttonvalue = "Enroll";
      this.updatestaffverify = 0;
      this.checkstaffverify = 1;
      this.addPhoneChkAlertvalue = false;
      this.enablePhoneCheckbox = false;
    } 
    if((value.length == 10) && (/^[0-9]*$/.test(value)) && this.staffCreationForm.valid )    
    {       
      if(value != null )
      {
        value = value.trim();
       if (this.staffphonenumber == 0)
       {
        this.buttonvalue = "Enroll";
        this.updatestaffverify = 0;
        this.checkstaffverify = 0;   
       }
       else if (this.staffphonenumber != 0 && this.checkAddStaffPhone == 1)
       {
          if(this.staffphonenumber != value)
          {
            this.buttonvalue = "Enroll";
            this.updatestaffverify = 0;
            this.checkstaffverify = 0;
          }
          else
          {          
            this.buttonvalue = "Enrolled";
            this.enablePhoneCheckbox = true;
            this.updatestaffverify = 1;
            this.checkstaffverify = 1;
            if(this.addPhoneCheckAlert == 1)
              this.addPhoneChkAlertvalue = true;
          }
        }  
        else if (this.staffphonenumber != 0 && this.checkAddStaffPhone == 0)
        {
          this.buttonvalue = "Enroll";
          this.updatestaffverify = 0;
          this.checkstaffverify = 0;
        }      
      }
    }
    else
    {
      this.buttonvalue = "Enroll";
      this.updatestaffverify = 0;
      this.checkstaffverify = 1;
      this.addPhoneChkAlertvalue = false;
      this.enablePhoneCheckbox = false;
    }
  }

  getstaffEmail(emailvalue)
  {     
    this.checkstaffemailverify = 1;    
    if (emailvalue.length==0 )
    {
     // alert("emailvalue : "+ emailvalue.length);  
      this.emailbuttonvalue = "Enroll";
      this.updatestaffemailverify = 0;
      this.checkstaffemailverify = 1;
      this.addCheck_Invalue = false;
      this.addChkAlertvalue = false;
      this.enableCheckbox = false;
    }
     if(emailvalue != null && this.staffCreationForm.controls.email.valid)
      {            
        const pattmatch= emailvalue.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');           
        emailvalue = emailvalue.trim();
        emailvalue = emailvalue.toLowerCase();        
        if (pattmatch)
        {
          this.emailbuttonvalue = "Enroll";
          this.updatestaffemailverify = 0;
          this.checkstaffemailverify = 0;
        }
        if (this.staffEmailId != '' && this.checkAddStaffEmail == 1)
        {
          if(this.staffEmailId == emailvalue)
          {
            this.emailbuttonvalue = "Enrolled";
            this.enableCheckbox = true;
            this.updatestaffemailverify = 1;
            this.checkstaffemailverify = 1;
            if(this.addCheckInReport == 1)            
              this.addCheck_Invalue = true;            
            if(this.addCheckAlert == 1)            
              this.addChkAlertvalue = true;
          }
          else if(this.staffEmailId != emailvalue)
          {
            this.emailbuttonvalue = "Enroll";
            this.updatestaffemailverify = 0;
            this.checkstaffemailverify = 0;
          }      
        }
        else if(this.staffEmailId != '' && this.checkAddStaffEmail == 0)
        {
          this.emailbuttonvalue = "Enroll";
          this.updatestaffemailverify = 0;
          this.checkstaffemailverify = 0;
        }  
      }
      else
      {
        this.emailbuttonvalue = "Enroll";
        this.updatestaffemailverify = 0;
        this.checkstaffemailverify = 1;
        this.addCheck_Invalue = false;
        this.addChkAlertvalue = false;
        this.enableCheckbox = false;
      }
  }

  onVerifyPhoneNumber(staffFormValue) {
    if (!this.staffCreationForm.valid) {      
      if(staffFormValue.gender == "")
        this.staffgender = 1;
      return;
    }
    if (this.boolstaffphoneverify) {
      this.boolstaffphoneverify = false;
      this.checkverifyPhoneclick = 1;
      if (staffFormValue.phone == "") {
        this.boolstaffphoneverify = true;
        generateAlertMessage(this.dialog, "Please enter the Phone Number");
        return false;
      } else if (staffFormValue.phone.length > 10) {
        this.boolstaffphoneverify = true;
        generateAlertMessage(this.dialog, "Phone Number should be 10 digits");
        return false;
      } else if (this.staffCreationForm.controls.phone.valid) {
        this.staffLoading = true;

        this.staffAddEnableDisable(enumEnableDisable.Disable);

        this.checknetwork = AppComponent.SharedInternetConnection;
        this.checkPhoneclick = 1;
        if (this.checknetwork != undefined) {
          if (this.checknetwork == 0) {
            this.checkPhoneclick = 0;
            generateAlertMessage(
              this.dialog,
              "Failed due to not having internet access."
            );
            this.staffLoading = false;
            this.staffAddEnableDisable(enumEnableDisable.Enable);
            this.boolstaffphoneverify = true;
            this.staffbooladd = true;
            return false;
          } else {
            this.staffLoading = false;
            this.staffAddEnableDisable(enumEnableDisable.Enable);
            this.checkPhoneNumber(staffFormValue);
          }
        } else {
          this.staffLoading = false;
          this.staffAddEnableDisable(enumEnableDisable.Enable);
          this.checkPhoneNumber(staffFormValue);
        }
      } else {
        this.boolstaffphoneverify = true;
        this.staffbooladd = true;
        return false;
      }
    }
  }

  registerPhoneNumberForOTP(staffFormValue) {
    if (!SessionCheck(this.router)) {
      return;
    }
    this.staffphonenumber = staffFormValue.phone;

    const staffnumber = staffFormValue.phone;
    if (staffnumber.length == 10 && /^[0-9]*$/.test(staffnumber)) {
      const newstaffnumber = staffnumber;

      const staffverifynumber: getPhoneNumberOtp = {
        authKey: this.authkey,
        phoneNumber: newstaffnumber.toString(),
        countryCode: this.countrycode.toString(),
      };
      this.staffService
        .registerPhoneNumberForOTP(staffverifynumber)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: StaffForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            this.staffLoading = false;
            this.staffAddEnableDisable(enumEnableDisable.Enable);

            const AlertMessageData = {
              AlertMessage:
                "Verification code successfully sent to " +
                staffFormValue.phone,
            };

            const dialogRef = this.dialog.open(AlertMessageComponent, {
              disableClose: true,
              width: "auto",
              height: "auto",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: AlertMessageData,
            });

            dialogRef.afterClosed().subscribe((result) => {
              console.log(result);
              const staffverifyData = {
                StaffVerify: staffFormValue.phone,
                StaffFlag: 0,
                StaffId: 0,
                OTPNumber: 0,
              };
              const dialogRef = this.dialog.open(VerifyStaffComponent, {
                disableClose: true,
                width: "450px",
                height: "350px",
                panelClass: "custom-modalbox",
                autoFocus: false,
                data: staffverifyData,
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result == 1) {
                  this.boolstaffphoneverify = true;
                  this.staffbooladd = true;
                  this.buttonvalue = "Enrolled";
                  this.enablePhoneCheckbox = true;
                  this.updatestaffverify = 1;
                  this.checkstaffverify = result;
                  this.checkAddStaffPhone = 1;  
                } else {
                  this.boolstaffphoneverify = true;
                  this.staffbooladd = true;
                  this.buttonvalue = "Enroll";
                  this.updatestaffverify = 0;
                  this.checkstaffverify = 0;
                  this.checkAddStaffPhone = 0;  
                }
              });
            });
          } else if (apiresponse.toString() == "false") {
            this.boolstaffphoneverify = true;
            this.staffbooladd = true;
            generateAlertMessage(
              this.dialog,
              "Phone Verification failed due to invalid phone number"
            );
            return false;
          }
        });
    }
  }

  onVerifyEmail(staffFormValue) {
    if (!this.staffCreationForm.valid) {
      if(staffFormValue.gender == "")
        this.staffgender = 1;
      return;      
    }
    if (this.boolstaffemailverify) {
      this.boolstaffemailverify = false;
      this.checkverifyEmailclick = 1;

      if (staffFormValue.email == "") {
        this.boolstaffemailverify = true;
        generateAlertMessage(this.dialog, "Please enter the Email Id");
        return false;
      } else if (this.staffCreationForm.controls.email.valid) {
        this.staffLoading = true;
        this.staffAddEnableDisable(enumEnableDisable.Disable);

        this.checknetwork = AppComponent.SharedInternetConnection;
        this.checkEmailclick = 1;
        if (this.checknetwork != undefined) {
          if (this.checknetwork == 0) {
            this.checkEmailclick = 0;
            generateAlertMessage(
              this.dialog,
              "Failed due to not having internet access."
            );
            this.staffLoading = false;
            this.staffAddEnableDisable(enumEnableDisable.Enable);
            this.boolstaffemailverify = true;
            this.staffbooladd = true;
            return false;
          } else {
            this.staffLoading = false;
            this.staffAddEnableDisable(enumEnableDisable.Enable);
            this.checkEmailId(staffFormValue);
          }
        } else {
          this.staffLoading = false;
          this.staffAddEnableDisable(enumEnableDisable.Enable);
          this.checkEmailId(staffFormValue);
        }
      } else {
        this.boolstaffphoneverify = true;
        this.staffbooladd = true;
        return false;
      }
    }
  }

  registerEmailForOTP(staffFormValue) {
    if (!SessionCheck(this.router)) {
      return;
    }

    this.staffEmailId = staffFormValue.email.toLowerCase();

    const checkemail = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/; //todo
    
    if (checkemail.test(this.staffEmailId)) {
      this.staffLoading = true;
      this.staffAddEnableDisable(enumEnableDisable.Disable);
      const staffverifymaildId: getEmailOtp = {
        authKey: this.authkey,
        emailId: this.staffEmailId,
        staffId: 0,
      };
      this.staffService
        .registerEmailForOTP(staffverifymaildId)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: StaffForCreationResponse[]) => {
          if (apiresponse["condition"] != 0) {
            this.staffLoading = false;
            this.staffAddEnableDisable(enumEnableDisable.Enable);
            this.emailrandomnumber = apiresponse["condition"];
            // generateAlertMessage(this.dialog, "Verification code successfully sent to "+staffFormValue.email);
            const AlertMessageData = {
              AlertMessage:
                "Verification code successfully sent to " +
                staffFormValue.email,
            };

            const dialogRef = this.dialog.open(AlertMessageComponent, {
              disableClose: true,
              width: "auto",
              height: "auto",
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: AlertMessageData,
            });

            dialogRef.afterClosed().subscribe((result) => {
              console.log(result);
              const staffverifyData = {
                StaffVerify: staffFormValue.email,
                StaffFlag: 1,
                StaffId: 0,
                OTPNumber: this.emailrandomnumber,
              };
              const dialogRef = this.dialog.open(VerifyStaffComponent, {
                disableClose: true,
                width: "450px",
                height: "300px",
                panelClass: "custom-modalbox",
                autoFocus: false,
                data: staffverifyData,
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result == 1) {
                  this.boolstaffemailverify = true;
                  this.staffbooladd = true;
                  this.emailbuttonvalue = "Enrolled";
                  this.enableCheckbox = true;
                  this.updatestaffemailverify = 1;
                  this.checkstaffemailverify = result;
                  this.checkAddStaffEmail = 1; 
                } else {
                  this.boolstaffemailverify = true;
                  this.staffbooladd = true;
                  this.emailbuttonvalue = "Enroll";
                  this.updatestaffemailverify = 0;
                  this.checkstaffemailverify = 0;
                  this.checkAddStaffEmail = 0; 
                }
              });
            });
          } else if (apiresponse["condition"] == 0) {
            this.boolstaffemailverify = true;
            this.staffbooladd = true;
            this.staffLoading = false;
            this.staffAddEnableDisable(enumEnableDisable.Enable);
            generateAlertMessage(this.dialog, "Email Verification failed");
            return false;
          }
        });
    }
  }

  toggleAddCheckInVisibility(event) {
    if (event.checked == true) {
      this.addCheckInReport = 1;
      this.addCheck_Invalue = true;
    } else {
      this.addCheckInReport = 0;
      this.addCheck_Invalue = false;
    }
  }
  
  toggleAddAlertVisibility(event)
  {
      if(event.checked == true)
      {
        this.addCheckAlert = 1;
        this.addChkAlertvalue = true;       
      }
      else
      {      
        this.addCheckAlert = 0;
        this.addChkAlertvalue = false;         
      }
  }
  
  toggleAddPhoneAlertVisibility(event)
  {
    if(event.checked == true)
    {
      this.addPhoneCheckAlert = 1;
      this.addPhoneChkAlertvalue = true;       
    }
    else
    {      
      this.addPhoneCheckAlert = 0;
      this.addPhoneChkAlertvalue = false;         
    }
  }
  
  toggleAddNotifyVisibility(event)
  {
      if(event.checked == true)
      {
        this.addNotificationvalue = true;       
      }
      else
      {      
        this.addNotificationvalue = false;         
      }
  } 
  
  changeDesignationList(RoleId)
  {
    if(RoleId == 1)
    {
      this.addNotificationvalue = false;
      this.roleEnableNotify = true;    
    }
    else
    {    
      this.roleEnableNotify = false;    
    }
  } 

  staffAddEnableDisable(checkflag) {
    const elementcontent = document.getElementById("staffadddialog");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "enablecontent";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "disablecontent";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
