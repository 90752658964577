import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  AfterViewChecked,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import {
  appsettings,
  enumAlertStatus,
  enumAlertAckStatus,
  alert_raisedon,
} from "../../../app-settings";
import { SharedAlertData, NearestStaffdata, cancelAlert } from "../../../api";
import { AlertSharedService } from "../../../_services/index";
import { SessionCheck } from "../../../app-general";
import { get_alert_data } from "../../../app-general";
import { AlertnotificationComponent } from "../alertnotification/alertnotification.component";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { HomeService } from "../home.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { StaffData } from "../home";
import { AppComponent } from "../../../app.component";
import { ApiService } from "../../../api.service";

@Component({
  selector: "app-mapnotification",
  templateUrl: "./mapnotification.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./mapnotification.component.scss"],
})
export class MapnotificationComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  alertinfo;
  notifiedinfo;
  CampusId;
  BuildingId;
  FloorId;
  RoomId;
  AlertId;
  AlertType;
  AlertResStaff;
  MapData;
  residentImgUrl: string = appsettings.RESIDENT_FOLDER;
  staffImgUrl: string = appsettings.STAFF_FOLDER;
  Staffdata: StaffData[];
  NearestStaffdata: NearestStaffdata[];

  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,
    private homeService: HomeService,
    public thisDialogRef: MatDialogRef<MapnotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    try {
      this.alertSharedService
        .currentSharedAlert()
        .pipe(takeUntil(this._onDestroy))
        .subscribe((SharedAlertDetails: SharedAlertData[]) => {
          this.alertinfo = SharedAlertDetails;
        });
      const AlertDetails = this.alertinfo.filter(function (e1) {
        return e1.AlertId == data.AlertId;
      });
      this.CampusId = data.CampusId;
      this.BuildingId = data.BuildingId;
      this.FloorId = data.FloorId;
      this.RoomId = data.RoomId;
      this.AlertId = data.AlertId;
      this.AlertType = data.AlertType;
      this.AlertResStaff = data.AlertResStaff;
      this.Staffdata = data.Staffdata;
      this.NearestStaffdata = data.NearestStaffdata;

      this.MapData = get_alert_data(
        AlertDetails,
        this.residentImgUrl,
        this.staffImgUrl
      );
      const width = window.innerWidth; //Math.min(document.documentElement.clientWidth, window.innerWidth || 0);
      if (width > 500) {
        this.thisDialogRef.close();
      }
    } catch (err) {
      throw new Error(
        "mapnotification.component.ts" + " - constructor() - " + err.message
      );
    }
  }

  ngOnInit() {
    this.document.getElementById("spn_mapnotification").innerHTML =
      '<img id="imgCloseAlertNotification" class="clscloseimagemap" src="assets/images/close.png">' +
      this.MapData;
  }
  ngAfterViewChecked() {
    const width = window.innerWidth; //Math.min(document.documentElement.clientWidth, window.innerWidth || 0);
    if (width > 500) {
      this.thisDialogRef.close();
    }
  }
  myClickFunction(event) {
    if (event.target.id == "btnalertNotifications") {
      this.showmapnotification();
    } else if (event.target.id == "btnalertCompleteNotifications") {
      this.showmapCompletenotification();
    } else if (event.target.id == "imgCloseAlertNotification") {
      this.thisDialogRef.close();
      let SharedDetails = AppComponent.SharedDetails;
      SharedDetails = {
        SharedCampusId: SharedDetails.SharedCampusId,
        SharedBuildingId: SharedDetails.SharedBuildingId,
        SharedFloorId: SharedDetails.SharedCampusId,
        SharedAlertId: "",
        SharedAlertStatus: alert_raisedon.leftside_alertselect,
        SharedResidentId: "",
      };
      this.alertSharedService.changeShared(SharedDetails);
    }
  }

  showmapnotification() {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }

      const sel_CampusId = this.CampusId;
      const sel_BuildingId = this.BuildingId;
      const sel_FloorId = this.FloorId;
      const sel_RoomId = this.RoomId;
      const sel_AlertId = this.AlertId;
      const sel_AlertType = this.AlertType;
      const sel_AlertResStaff = this.AlertResStaff;

      this.notifiedinfo = this.alertinfo.filter(function (e) {
        return e.AlertId == parseInt(sel_AlertId);
      });

      const NotifyData = {
        AlertData: this.notifiedinfo,
        CampusId: parseInt(sel_CampusId),
        BuildingId: parseInt(sel_BuildingId),
        FloorId: parseInt(sel_FloorId),
        RoomId: parseInt(sel_RoomId),
        AlertId: parseInt(sel_AlertId),
        AlertType: parseInt(sel_AlertType),
        AlertResStaff: parseInt(sel_AlertResStaff),
        Staffdata: this.Staffdata,
        NearestStaffdata: this.NearestStaffdata,
      };
      const dialogRef = this.dialog.open(AlertnotificationComponent, {
        disableClose: true,
        width: "450px",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: NotifyData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "CanceledAlert") {
          console.log(result);
        }
      });
    } catch (err) {
      throw new Error(
        "mapnotification.component.ts" +
          " - showalertnotification() - " +
          err.message
      );
    }
  }

  showmapCompletenotification() {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      const authkey = localStorage.getItem("Authkey") ?? "";
      const AcceptedAlertId = this.AlertId;
      const Staffid = localStorage.getItem("userid") ?? "";
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to Complete this Alert?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        const _alertinfo = this.alertinfo;
        const AcceptedAlertDetails = _alertinfo.filter(function (e1) {
          return (
            e1.AlertId == AcceptedAlertId &&
            (e1.AckStatus == enumAlertAckStatus.Accepted ||
              e1.AckStatus == enumAlertAckStatus.Presence ||
              e1.AckStatus == enumAlertAckStatus.Caregiver_Presence ||
              e1.AckStatus == enumAlertAckStatus.Caregiver_Alert_Presence)
          );
        });

        if (result == "Yes" && AcceptedAlertDetails.length > 0) {
          if (
            <HTMLInputElement>this.document.getElementById("complete") != null
          ) {
            (<HTMLInputElement>(
              this.document.getElementById("complete")
            )).disabled = true;
          }
          const cancelItem: cancelAlert = {
            authKey: authkey,
            staffId: parseInt(Staffid),
            alertId: parseInt(AcceptedAlertId),
            status: enumAlertStatus.Complete.toString(),
          };
          this.apiService
            .cancelAlert(cancelItem)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((canceledResponse: any[]) => {
              if (canceledResponse["apiReturn"] == "Success") {
                this.thisDialogRef.close();
              }
            });
        }
      });
    } catch (err) {
      throw new Error(
        "mapnotification.component.ts" +
          " - showmapCompletenotification() - " +
          err.message
      );
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
