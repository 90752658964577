import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA,MatDialog } from "@angular/material/dialog";
import {Router} from '@angular/router';
import "jspdf-autotable";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
// import { distinctUntilChanged, tap } from 'rxjs/operators';
import { SessionCheck, generateAlertMessage } from "../../../../app-general";
import { NewDeviceTypeDesc } from "../../../../app-settings";

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");


@Component({
  selector: 'app-download-device-report',
  templateUrl: './download-device-report.component.html',
  styleUrls: ['./download-device-report.component.scss']
})
export class DownloadDeviceReportComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,public thisDialogRef: MatDialogRef<DownloadDeviceReportComponent>,
  @Inject(RollbarService) private rollbar: Rollbar,private breakpointObserver: BreakpointObserver, public dialog: MatDialog,) { }
  
  columns = 2;
  CurrentTime: any;
  deviceitem;
  
  doc = new jsPDF();
  public isExcelDownload = false;
  public isPDFDownload = false;
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver;
  public _NewDeviceTypeDesc = NewDeviceTypeDesc;
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 1;
    }
  }
  
  ngOnInit(): void {
    const loaderelement = document.getElementsByClassName('loaddevicespinner'); 
        loaderelement[0]["style"].display = "none";
  }
  
  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  
  downloadDeviceExcel(devicedata) {
    const loaderelement = document.getElementsByClassName("loaddevicespinner");
    loaderelement[0]["style"].display = "block";
    setTimeout(() => {
      this.downloadDevice_Excel(devicedata);
    }, 3);
  }
  
  downloadDevice_Excel(devicedata) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close('Cancel');
        return;
      }
      if (!this.isExcelDownload) {
        this.isExcelDownload = true;
        const downloadDevice = {
          Data: [] as any
        };

      for (let i = 0; i < devicedata.length; i++) {

        let Status;
        let LforIr;
        this.deviceitem = devicedata[i]; 
        
        const NewDeviceName = this.deviceitem.NewDeviceName;
        const deviceStatus = this.deviceitem.Status;        
        
        if((NewDeviceName == this._NewDeviceTypeDesc.Rex || NewDeviceName == this._NewDeviceTypeDesc.Gateway ||  NewDeviceName == this._NewDeviceTypeDesc.HRex) && 
        (NewDeviceName != this._NewDeviceTypeDesc.DC))            
          LforIr = this.deviceitem.MacId;
        else if(NewDeviceName != this._NewDeviceTypeDesc.HRex && NewDeviceName != this._NewDeviceTypeDesc.Rex && NewDeviceName != this._NewDeviceTypeDesc.Gateway && 
          NewDeviceName != this._NewDeviceTypeDesc.DC)  
          LforIr = this.deviceitem.LforIr;
        else if(NewDeviceName == this._NewDeviceTypeDesc.DC)  
          LforIr = this.deviceitem.LforIr + "/" + this.deviceitem.DCMacId + "/" + this.deviceitem.dualutId;
        
        if(deviceStatus == 1)
          Status = "Online";
        else if(deviceStatus == 0)
          Status = "Offline";
        else if(deviceStatus == 2)  
          Status = "";          
      
        downloadDevice.Data.push({        
          'Device': this.deviceitem.NewDeviceName,
          'Device ID' : this.deviceitem.DeviceInfo,
          'IR/LF/MAC/UT ID' : LforIr,
          'Campus Name': (this.deviceitem.CampusName == null) ? '' : this.deviceitem.CampusName,
          'Building Name': (this.deviceitem.BuildingName == null) ? '' : this.deviceitem.BuildingName, 
          'Zone': (this.deviceitem.FloorName == null) ? '' : this.deviceitem.FloorName,
          'Space': (this.deviceitem.UnitLocation == null) ? '' : this.deviceitem.UnitLocation,   
          'Location' : this.deviceitem.Description,                      
          'Activity' : Status
        });
      }

        this.JSONToCSVConvertor(downloadDevice.Data, '', true);
      }
    }
    catch (err) {
      throw new Error('download-device.component.ts' + ' - downloadDeviceExcel() - ' + err.message);
    }
    
  }
  
  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    try {
      const arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
          
              let CSV = '';    
      
              if (ShowLabel) {
                  let row = "";
                  
              
                  for (const index in arrData[0]) {
                      
                  
                      row += index + ',';
                  }
          
                  row = row.slice(0, -1);
            
                  CSV += row + '\r\n';
              }
              
      if (arrData.length > 0) {

        for (let i = 0; i < arrData.length; i++) {
          let row = "";

          for (const index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }

          row.slice(0, row.length - 1);

          CSV += row + '\r\n';
        }

        if (CSV == '') {
          const loaderelement = document.getElementsByClassName('loaddevicespinner');
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Invalid data");
          this.isExcelDownload = false;
          return;
              }   
                                    
        
              let fileName = 'DeviceReport';
              fileName += ReportTitle.replace(/ /g, '_');
             
              const myBlob =  new Blob( [CSV] , {type:'text/html'});
              const url = window.URL.createObjectURL(myBlob);
              const a = document.createElement("a");
              const link = document.body.appendChild(a);
              document.body.appendChild(link);
              link.href = url;
              this.CurrentTime =  new Date().getFullYear() + ('0' + (new Date().getMonth() + 1)).slice(-2) + ('0' + (new Date().getDate())).slice(-2)  + '_' + new Date().getHours() + '_' + new Date().getMinutes() + '_'+  new Date().getSeconds();
              link.download = fileName + '_' + this.CurrentTime + '.csv';           
              link.click();
            }
      else {
        const loaderelement = document.getElementsByClassName('loaddevicespinner');
        loaderelement[0]["style"].display = "none";
        generateAlertMessage(this.dialog, "No data available");
        this.isExcelDownload = false;
      }
      const loaderelement = document.getElementsByClassName('loaddevicespinner');
      loaderelement[0]["style"].display = "none";
      this.isExcelDownload = false;
    }
    catch (err) {
      throw new Error('download-device.component.ts' + ' - JSONToCSVConvertor() - ' + err.message);
    }
  }
  
  downloadDevicePdf(devicedata) {
    const loaderelement = document.getElementsByClassName("loaddevicespinner");
    loaderelement[0]["style"].display = "block";
    setTimeout(() => {
      this.downloadDevice_Pdf(devicedata);
    }, 3);
  }

  downloadDevice_Pdf(deviceData) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isPDFDownload) {
        this.isPDFDownload = true;
        const resData = [] as any[];
        this.CurrentTime =
          new Date().getFullYear() +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          ("0" + new Date().getDate()).slice(-2) +
          "_" +
          new Date().getHours() +
          "_" +
          new Date().getMinutes() +
          "_" +
          new Date().getSeconds();

        const columns = [
          { title: "Device", dataKey: "NewDeviceName" },
          { title: "Device ID", dataKey: "DeviceInfo" },
          { title: "IR/LF/MAC/UT ID", dataKey: "LforIr" },
          { title: "Campus", dataKey: "CampusName" },
          { title: "Building", dataKey: "BuildingName" },
          { title: "Zone", dataKey: "FloorName" },
          { title: "Space", dataKey: "UnitLocation" },
          { title: "Location", dataKey: "Description" },
          { title: "Activity", dataKey: "Status" },
        ];

        if (deviceData.length > 0) {
          for (let i = 0; i < deviceData.length; i++) {
            
         
            const NewDeviceName = deviceData[i].NewDeviceName;
            const DeviceInfo = deviceData[i].DeviceInfo;                        
            const CampusName = deviceData[i].CampusName;
            const BuildingName = deviceData[i].BuildingName;
            const FloorName = deviceData[i].FloorName;
            const UnitLocation = deviceData[i].UnitLocation;
            const Description = deviceData[i].Description;
            const deviceStatus = deviceData[i].Status;
            let Status;
            let LforIr;
            
            if((NewDeviceName == this._NewDeviceTypeDesc.Rex || NewDeviceName == this._NewDeviceTypeDesc.Gateway ||  NewDeviceName == this._NewDeviceTypeDesc.HRex) && 
            (NewDeviceName != this._NewDeviceTypeDesc.DC))            
              LforIr = deviceData[i].MacId;
            else if(NewDeviceName != this._NewDeviceTypeDesc.HRex && NewDeviceName != this._NewDeviceTypeDesc.Rex && NewDeviceName != this._NewDeviceTypeDesc.Gateway && 
              NewDeviceName != this._NewDeviceTypeDesc.DC)  
              LforIr = deviceData[i].LforIr;
            else if(NewDeviceName == this._NewDeviceTypeDesc.DC)  
              LforIr = deviceData[i].LforIr + "/" + deviceData[i].DCMacId + "/" + deviceData[i].dualutId;
            
            if(deviceStatus == 1)
              Status = "Online";
            else if(deviceStatus == 0)
              Status = "Offline";
            else if(deviceStatus == 2)  
              Status = "";
           
            //CampusName, BuildingName, FloorName,

            resData.push({
              NewDeviceName,
              DeviceInfo,
              LforIr,
              CampusName,
              BuildingName,
              FloorName,
              UnitLocation,
              Description,
              Status          
            });
          }

          const pdf = new jsPDF("l"); // A4 size page of PDF
          let PdfFileName = "DeviceReport";

          const pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
          pdf.text("Device Report ", pageWidth / 2, 10, "center");

          pdf.setFontSize(10);         

          const options = {
            theme: "grid",
            bodyStyles: { lineColor: [0, 0, 0] },
            tableWidth: "auto",
            columnWidth: "wrap",
            showHeader: "everyPage",
            headStyles: {
              lineWidth: 0.2,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
              fillColor: [169, 169, 169],
              valign: "middle",
              halign: "center",
            },
            columnStyles: {
              NewDeviceName: { cellWidth: 30, minCellHeight: 24 },
              DeviceInfo: { cellWidth: 30, minCellHeight: 24 },
              LforIr: { cellWidth: 40, minCellHeight: 24 },
              CampusName: { cellWidth: 30, minCellHeight: 24 },
              BuildingName: { cellWidth: 30, minCellHeight: 24 },
              FloorName: { cellWidth: 30, minCellHeight: 24 },
              UnitLocation: { cellWidth: 30, minCellHeight: 24 },
              Description: { cellWidth: 30, minCellHeight: 24 },
              Status: { cellWidth: 20, minCellHeight: 24 },
            },
            rowPageBreak: "avoid",
          };

          pdf.autoTable(columns, resData, options);
          PdfFileName = PdfFileName + "_" + this.CurrentTime + ".pdf";
          pdf.save(PdfFileName);
          const loaderelement = document.getElementsByClassName(
            "loaddevicespinner"
          );
          loaderelement[0]["style"].display = "none";
          this.isPDFDownload = false;
        } else {
          const loaderelement = document.getElementsByClassName(
            "loaddevicespinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "No data available.");
          this.isPDFDownload = false;
        }
        const loaderelement = document.getElementsByClassName(
          "loaddevicespinner"
        );
        loaderelement[0]["style"].display = "none";
        this.isPDFDownload = false;
      }
    } catch (err) {
      throw new Error("download-device.component.ts" + " - downloadDevicePdf() - " + err.message);
    }
  }

}
