import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumFileNamelength,
  DeviceTypeDesc,
  NewDeviceTypeDesc,
  DeviceType,
  enumEnableDisable,
} from "../../../../../app-settings";

import {
  TechnologyForCreationResponse,
  EditZoneForm,
  ZoneByID,
  deleteZoneById,
  deleteUnitById,
  checkDeviceAlert,
  checkLocationAlert,
  FloorGroup,
  DeleteSubZone
} from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import {
  deleteNotifyByLocation,
  getDeviceDetailsReq,
  DeleteDevice,
} from "../../../technologyoverlay";
import { HostListener } from "@angular/core";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
  AuthFailedMessage,
} from "../../../../../app-general";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../../../../../api.service";
import {
  MapSyncProcess,
  UnitById,
  getDeviceById,
  backupPhoto,
  getUnit,
  getFloor,
  getZone,
  getSubZone
} from "../../../../../api";
import { takeUntil } from "rxjs/operators";
import { ConfirmMessageComponent } from "../../../../../confirm-message/confirm-message.component";
import { AlertSharedService } from "../../../../../_services/index";
import { GeneralMessageComponent } from "../../../../../general-message/general-message.component";
import { EditUnitComponent } from "../../unit/edit-unit/edit-unit.component";
import { EditDeviceComponent } from "../../../device/edit-device/edit-device.component";
import { ConfirmDialogComponent } from "../../../../technologyoverlay/device/confirm-dialog/confirm-dialog.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ManagesubZoneComponent } from '../../managesub-zone/managesub-zone.component';

const URL = appsettings.URLinfo + "uploadFloorImage";
@Component({
  selector: "app-edit-zone",
  templateUrl: "./edit-zone.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-zone.component.scss"],
})
export class EditZoneComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  public _NewDeviceTypeDesc = NewDeviceTypeDesc;
  columns = 4;
  firstcolumns = 2;
  downIcon = 0;
  updownArrowIcon = "keyboard_arrow_down";
  minheight = "230px";
  location;
  floorsImgUrl: string = appsettings.FLOOR_IMAGE_FOLDER;
  flooreditImgUrl;
  flooreditsvgUrl;
  floorimagename;
  oldimagename;
  zoomval = 0.5;
  imgWidth = 200;
  height = 184;
  top = 0;
  left = 0;
  rotationAmount = 0;
  E_FZoomLevel = 1;
  E_FRotateLevel = 0;
  E_svgFZoomLevel;
  E_svgFRotateLevel;
  E_pngFZoomLevel;
  E_pngFRotateLevel;
  update_fzoomlevel;
  update_frotatelevel;
  FloorSVGimage;
  deletefloorid;
  campusid;
  BuildId;
  old_flooreditImgUrl = appsettings.ClientImageFolder + "defaultimg.png";
  old_E_pngFZoomLevel = 1;
  old_E_pngFRotateLevel = 0;
  old_left_rightval = 0;
  old_top_bottomval = 0;

  flag = 0;
  resetfloorname: string;
  resetdescription: string;
  devicedetailsResponse = [];
  DeviceDetails = [];
  public flooreditForm: UntypedFormGroup;
  validimgurl = true;
  floorImg;
  private _onDestroy: Subject<void> = new Subject<void>();
  floorrowHeight = "69px";
  screenWidth;
  bAddEdit = false;
  bDelete = false;
  isundo = 0;
  zoominvalue = 0.5;
  left_rightval = 0;
  top_bottomval = 0;
  undooperation = true;
  undopane = 0;
  uploadfloorimage;
  getselectedmapdetails;
  UnitInfo;
  floorInfo;
  DeviceInfo = [] as any[];
  UnitById;
  uniteditdialogopen = 0;
  device_list_all = "3";
  MapSyncProcess;
  deviceEditdialogopen = 0;
  deleteFlag;
  deletedevice_dialogopen = 0;

  F_chkCampusvalue = true;
  F_chkCustomvalue = false;
  NotifydefaultValue = 0;
  Floordetails;
  selectedFloorId = 0;
  zoneImageflag;
  checkZoneisFloor = false;
  disableZoneisFloor = true;
  selectedZoneName;
  selectedCampusName;
  selectedBuildName;
  selectedFloorName;
  SubZonedetails;
  floorGroups;
  floorOriginalId = 0;
  editCheck_Invalue = false;
  authkey;
  Breakpoints = Breakpoints;
  bgImageWidth=0;
  bgImageHeight=0;

  readonly breakpoint$ = this.breakpointObserver
    .observe([
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      "(min-width: 500px)",
    ])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  @ViewChild("fileInput") imagefloorInput: any;

  constructor(
    public http: HttpClient,
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    private alertSharedService: AlertSharedService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.floorimagename = data.FloorPlanImage;
      this.floorImg = data.FloorPlanImage;
      this.deletefloorid = data.FloorId;
      this.campusid = data.CampusId;
      this.BuildId = data.BuildId;
      this.selectedCampusName = data.CampusName;
      this.selectedBuildName = data.BuildingName;
      this.selectedZoneName = data.FloorName;
      this.selectedFloorName = data.FloorName;
      this.FloorSVGimage = data.FloorPlansvgImage;
      this.floorInfo = data;
      this.flooreditImgUrl = appsettings.FLOOR_IMAGE_FOLDER + data.FloorPlanImage;
      if(data.OriginalFloorId > 0)
      {
        this.selectedFloorId = data.OriginalFloorId;                                                                    
        this.floorOriginalId = data.OriginalFloorId;  
      }
      this.zoneImageflag = 1; 

      if (data.Notifications == 0) {
        this.F_chkCampusvalue = true;
        this.F_chkCustomvalue = false;
      } else if (data.Notifications == 1) {
        this.F_chkCustomvalue = true;
        this.F_chkCampusvalue = false;
      }

      if (data.isResidentCheckin == 1) this.editCheck_Invalue = true;
      else if (data.isResidentCheckin == 0) this.editCheck_Invalue = false;
      
      if(data.isZoneFloor == 1)  
      {            
        this.checkZoneisFloor = true;                                     
      }
      else if(data.isZoneFloor == 0) 
      {
        this.checkZoneisFloor = false;                         
      }
      this.bgImageWidth=data.bgImageWidth;
      this.bgImageHeight=data.bgImageHeight;
      this.onResize();
      this.breakpoint$.subscribe(() => this.breakpointChanged());          
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - constructor() - " + err.message
      );
    }
  }
  
  breakpointChanged() {     
    if (getFeatureNeedtoShow(enumUIFeatureView.Technology,enumUIAction.Device_Map_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }  
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
    }   
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }     
  }

  @ViewChild("imageInput") imageInput: any;
  public onChangefloor_Edit(fileList: FileList) {
    const file = fileList[0];    
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(file.name);
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      this.imageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      this.imageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Zone Photo as image file."
      );
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 1);
      this.imageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function () {
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 2) {
              // 1024 is 1MB
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 2 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Zone Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }
  @ViewChild("imagesvgInput") imagesvgInput: any;
  public onChangefloorsvg_Edit(fileList: FileList) {
    const file = fileList[0];
    const itemType =
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
      file.name;
    const fileTypesAllowed = ["svg"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Zone Photo as svg file"
      );
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0], 2);
      this.imagesvgInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve) => {
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function () {
          self = fileReader.result;
          self = self.split("\n");
          if (self[0].includes("xml")) {
            checkvalidIMG = 1;
            resolve(checkvalidIMG);
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Zone Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  uploadfiletoPath(data, file, type) {
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const FileName = this.deletefloorid + "+" + _filename;
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        this.floorimagename = res.filename;
        this.validimgurl = true;
        if (type == 1) {
          this.flooreditImgUrl = appsettings.FLOOR_IMAGE_FOLDER + res.filename;
        } else if (type == 2) {
          this.flooreditsvgUrl = appsettings.FLOOR_IMAGE_FOLDER + res.filename;
        }
        this.zoneImageflag = 1;
      });
  }
  backupPhoto(filename) {
    try {
      const fileitem: backupPhoto = {
        authKey: this.authkey,
        fileName: filename.toString(),
        isPath: "Floors",
      };
      this.apiService
        .backupPhoto(fileitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Response: any[]) => {
          console.log(Response);
        });
    } catch (err) {
      throw new Error(
        "edit-facility.component.ts" + " - backupPhoto() - " + err.message
      );
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }
      this.oldimagename = this.floorimagename;
      this.flooreditForm = new UntypedFormGroup({
        floorimage: new UntypedFormControl(""),
        campusname: new UntypedFormControl(""),
        buildingname: new UntypedFormControl(""),
        floorname: new UntypedFormControl(""),
        name: new UntypedFormControl('', [Validators.maxLength(32), Validators.pattern(appsettings.Name_Pattern)]),
        zoneName: new UntypedFormControl("", [    
          Validators.required,     
          Validators.maxLength(32),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        description: new UntypedFormControl("", [
          Validators.pattern(appsettings.Name_Pattern),
        ]),
      });

      const floorEditCampusName = this.flooreditForm.get("campusname");
      const floorEditBuildingName = this.flooreditForm.get("buildingname");
      if (floorEditCampusName != null) floorEditCampusName.disable();
      if (floorEditBuildingName != null) floorEditBuildingName.disable();
      this.getZonedetails();
      this.getsubZoneDetails();
      this.getUnitDetails();
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }
  
  getZonedetails()
  {
    try {    
      if(this.selectedFloorId > 0)
        {
        const floorbyid: getZone = {          
          authKey: this.authkey,
          campusId:parseInt(this.campusid),
          buildId: this.BuildId,
          floorId: this.deletefloorid       
        };
        this.apiService.getZoneDetails(floorbyid).pipe(takeUntil(this._onDestroy))
        .subscribe((floorinfo: any[]) => {
            this.Floordetails = floorinfo["apiReturn"];                                                    
          });
        }
        else if(this.selectedFloorId == 0)
        {
          this.getFloorDetails(this.campusid,this.BuildId)
        }
        this.toggleZoneisFloor(this.checkZoneisFloor);  
    } 
    catch (err) {
      throw new Error("edit-floor.component.ts" + " - getZonedetails() - " + err.message);
    }
  }
  
  getFloorDetails(CampusId,BuildingId)
  {
    try {      
      const floorbyid: getFloor = {
        authKey: this.authkey,
        campusId:parseInt(CampusId),
        buildId: BuildingId,        
      };
      this.apiService
        .getFloorDetails(floorbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((floorinfo: any[]) => {
          this.Floordetails = floorinfo["apiReturn"];            
          
        // let formfloorgroup : FloorGroup[];
        let floors = "[";       
        for(let i = 0; i < this.Floordetails.length; i++)
        {        
          floors += "{ \"value\": \""+this.Floordetails[i].FloorId+"\",\"viewValue\": \""+this.Floordetails[i].FloorName+"\"}";  
          if(i != this.Floordetails.length - 1)
            floors += ",";             
        }
        floors += "]";       
        const _floorsinfo = JSON.parse(floors);             
        const formfloorgroup : FloorGroup [] = [
            {
              campus: this.Floordetails[0].CampusName,
              building: this.Floordetails[0].BuildingName,
              floors : _floorsinfo              
            },
          ]
        const _floorgroups = formfloorgroup;
                
        this.floorGroups = _floorgroups;
                         
        });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - getUnitDetails() - " + err.message
      );
    }
  }
  
  getsubZoneDetails()
  {
    try {    
      const subZonedata: getSubZone = {        
        authKey: this.authkey,
        zoneId: this.deletefloorid,       
      }
      this.technologyoverlayService.getSubZoneDetails(subZonedata).pipe(takeUntil(this._onDestroy))
          .subscribe((SubZonesinfo: any[]) => {  
          this.SubZonedetails = SubZonesinfo["apiReturn"];                                   
        }); 
    } 
    catch (err) {
      throw new Error("edit-floor.component.ts" + " - getSubZoneDetails() - " + err.message);
    }
  }

  getUnitDetails() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const buildbyid: getUnit = {
        authKey: this.authkey,
        floorId: this.deletefloorid,
        isIndoor: 1,
        isNeedIntercom: parseInt(voiceLicense),
        isSubZone: 0 
      };
      this.apiService
        .getUnitDetails(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((unitinfo: any[]) => {
          this.UnitInfo = unitinfo["apiReturn"];
          this.UnitInfo.forEach(function (data) {
            data["DeviceZoneCount"] = data.devicecount + data.Zonedevicecount;
          });

          this.getDeviceDetails();
        });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - getUnitDetails() - " + err.message
      );
    }
  }

  getDeviceDetails() {
    try {
      const DeviceDetails_info = [] as any[];
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const devicedata: getDeviceDetailsReq = {
        authKey: this.authkey,
        campusId:this.campusid,
        floorId: parseInt(this.deletefloorid),
        status: this.device_list_all,
        devicetype: 0,
        device: 0,
        spaceId: 0,
        isIndoor: 1,
        isNeedRoomonly: 0,
        isNeedIntercom: parseInt(voiceLicense)
      };

      this.apiService
        .getDeviceDetails(devicedata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((deviceinfo: any[]) => {
          deviceinfo["apiReturn"].forEach(function (data) {
            if (data.FilterDeviceTypeId != DeviceType.Room) {
              DeviceDetails_info.push(data);
            }
          });

          DeviceDetails_info.forEach(function (e) {
            if (e.DeviceName == DeviceTypeDesc.Emitter) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
              e["DeviceType"] = DeviceType.Emitter;
            } else if (e.DeviceName == DeviceTypeDesc.HRex) {
              e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
              e["DeviceType"] = DeviceType.HRex;
            } else if (e.DeviceName == DeviceTypeDesc.Rex) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
              e["DeviceType"] = DeviceType.Rex;
            } else if (e.DeviceName == DeviceTypeDesc.LF) {
              if (e.SubType==DeviceType.DripTray)
              {
                e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
              }
              else
              {
                e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
             }
              e["DeviceType"] = DeviceType.LF;
            } else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
              e["DeviceType"] = DeviceType.Pullcord;
            } else if (
              e.DeviceName == DeviceTypeDesc.DC ||
              e.DeviceName == DeviceTypeDesc.NewDC
            ) {
              e["NewDeviceName"] = NewDeviceTypeDesc.DC;
              e["DeviceType"] = DeviceType.DC;
            } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
              e["DeviceType"] = DeviceType.Monitor;
            } else if (e.DeviceName == DeviceTypeDesc.VW) {
              e["NewDeviceName"] = NewDeviceTypeDesc.VW;
              e["DeviceType"] = DeviceType.VW;
            } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
              e["DeviceType"] = DeviceType.Gateway;
            } else if (e.DeviceName == DeviceTypeDesc.UT) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UT;
              e["DeviceType"] = DeviceType.UT;
            } else if (e.DeviceName == DeviceTypeDesc.UM) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UM;
              e["DeviceType"] = DeviceType.UM;
            } 
            else if (e.DeviceName == DeviceTypeDesc.Intercom) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Intercom;
              e["DeviceType"] = DeviceType.IC;
            }
            else {
              e["NewDeviceName"] = NewDeviceTypeDesc.Zone;
              e["DeviceType"] = DeviceType.Zone;
            }
          });
          this.DeviceInfo = DeviceDetails_info;
        });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - getDeviceDetails() - " + err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.flooreditForm.controls).forEach((field) => {
        // {1}
        const control = this.flooreditForm.get(field); // {2}
        const getFormTargetId = document.getElementById(event.target.id);
        if (getFormTargetId != null) {
          if (field == getFormTargetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1280) {
      this.updownArrowIcon = "keyboard_arrow_down";
      this.downIcon = 0;
      this.thisDialogRef.updateSize("75em", "425px");
      this.minheight = "230px";
      this.columns = 4;
      this.firstcolumns = 2;
    }
    if (this.screenWidth <= 768) {
      this.floorrowHeight = "80px";
    } else if (this.screenWidth == 1097) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "230px";
      this.floorrowHeight = "69px";
    } else if (this.screenWidth == 1536) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "230px";
    } else {
      this.thisDialogRef.updateSize("75em", "920px");
    }
  }

  imgFloorUpload() {
    alert("upload");
  }

  public onCloseCancel = () => {
    try {
      this.thisDialogRef.close("Cancel");
      if (!SessionCheck(this.router)) {
        return;
      }
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - onCloseCancel() - " + err.message
      );
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    try {
      return this.flooreditForm.controls[controlName].hasError(errorName);
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - hasError() - " + err.message
      );
    }
  };

  //Edit zone window will help to open the Zone and do activity like add, edit and delete spaces
  public oneditFloordetails = (floorFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();

      if (this.flooreditForm.valid) {
        if(this.checkZoneisFloor == false)
        {
          this.flooreditForm.controls['floorname'].setValidators([Validators.required]);
          this.flooreditForm.controls["floorname"].updateValueAndValidity();
        }
        
        if (!this.flooreditForm.valid)
          {
            this.editFloorEnableDisable(enumEnableDisable.Enable);
            return;
          } 

        if (this.F_chkCampusvalue == false && this.F_chkCustomvalue == false) {
          generateAlertMessage(this.dialog, "Please Select Notifications");
          this.editFloorEnableDisable(enumEnableDisable.Enable);
          return;
        } else this.onEditSaveClicked(floorFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - oneditFloordetails() - " + err.message
      );
    }
  };

  private onEditSaveClicked = (floorFormValue) => {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();

      if (this.flooreditForm.valid) {
        this.editFloorEnableDisable(enumEnableDisable.Disable);
        if (this.floorimagename == "" || this.floorimagename == undefined)
          this.floorimagename = this.oldimagename;

        let _Description = "";
        if (floorFormValue.description != "")
          _Description = floorFormValue.description.replace(/\s+/g, " ").trim();
        else _Description = "";

        let notificationvalue = 0;
        if (this.F_chkCampusvalue == true) notificationvalue = 0;
        else notificationvalue = 1;

        let MessageDetail;        

        const message = 'By selecting "Custom", you must now configure Zones for this building. Truview will now build out the Zone Notifications for each alert type.';

        if (notificationvalue == 1) {
          MessageDetail = { Message: message, Type: 1, CustomType: 1 };

          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            width: "650px",
            height: "250px",
            panelClass: "myapp-no-padding-dialog",
            autoFocus: false,
            data: MessageDetail,
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result == "Confirm") {
              this.updateZone(floorFormValue, _Description, notificationvalue);
            } else {
              this.editFloorEnableDisable(enumEnableDisable.Enable);
              return;
            }
          });
        } else {
          this.updateZone(floorFormValue, _Description, notificationvalue);
        }
      }
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - onEditSaveClicked() - " + err.message
      );
    }
  };

  updateZone(floorFormValue, _Description, notificationvalue) {
    try {
      let _editCheckinvalue = 0;
      let ZoneName = "";
      if (this.editCheck_Invalue == true) _editCheckinvalue = 1;
      else if (this.editCheck_Invalue == false) _editCheckinvalue = 0;
      
      let isZoneFloor = 0;
      if(this.checkZoneisFloor == true)    
      {
        isZoneFloor = 1;
        ZoneName = floorFormValue.name.replace(/\s+/g, ' ').trim();
        this.selectedFloorId = 0;
      }
      else
      {
        isZoneFloor = 0;
        ZoneName = floorFormValue.zoneName.replace(/\s+/g, ' ').trim();
        this.selectedFloorId = parseInt(floorFormValue.floorname);
      }           

      const updatefloor: EditZoneForm = {
        authKey: this.authkey,
        campusId: parseInt(this.campusid),
        buildId: parseInt(this.BuildId),
        zoneId: parseInt(this.deletefloorid),       
        zoneName: ZoneName,
        description: _Description.toString(), //floorFormValue.description.replace(/\s+/g, ' ').trim(),
        zoneImage: this.floorimagename.toString(),
        widthft: "",
        lengthft: "",
        zoomLevel: this.E_FZoomLevel,
        rotateLevel: this.E_FRotateLevel,
        fTransformX: this.left_rightval,
        fTransformY: this.top_bottomval,
        notifications: parseInt(notificationvalue),
        zoneCheckInValue: _editCheckinvalue,
        floorOriginalId:this.selectedFloorId,
        imageflag : this.zoneImageflag,
        isZoneFloor : isZoneFloor
      };

      this.technologyoverlayService
        .updateZone(updatefloor)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            if (this.floorImg != this.floorimagename)
              this.backupPhoto(this.floorImg);
            generateAlertMessage(this.dialog, "Zone edited successfully.");
            if (this.NotifydefaultValue == 1) {
              const deleteNotify: deleteNotifyByLocation = {
                authKey: this.authkey,
                campusId: this.campusid,
                buildingId: this.BuildId,
                floorId: this.deletefloorid,
              };
              this.apiService
                .deleteNotificationByLocation(deleteNotify)
                .pipe(takeUntil(this._onDestroy))
                .subscribe((apiresponse: any[]) => {
                  console.log(apiresponse);
                });
            }
            this.thisDialogRef.close(this.deletefloorid);
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Zone edited failed.");
            this.editFloorEnableDisable(enumEnableDisable.Disable);
            this.thisDialogRef.close();
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else if (apiresponse["apiReturn"] == "Zone Image not exists") {
            generateAlertMessage(this.dialog, "Please upload Zone image.");
            this.editFloorEnableDisable(enumEnableDisable.Enable);
            return;
          } else if (apiresponse["apiReturn"] == "floorName") {
            generateAlertMessage(this.dialog, "Please enter Zone Name");
            this.editFloorEnableDisable(enumEnableDisable.Enable);
            return;
          } else {
            generateAlertMessage(
              this.dialog,
              "Zone name '" + apiresponse["FlooreditList"][0].FloorName + "' already exists."
            );
            this.editFloorEnableDisable(enumEnableDisable.Enable);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - updateFloor() - " + err.message
      );
    }
  }

  opendeletedialog(FloorInfo) {
    try {
      let Device_Count = "";
      let message = "";

      if (FloorInfo.devicecount >= 0) {
        message = "It contains ";
        Device_Count = FloorInfo.devicecount + " Devices.";
      }

      const GeneralMessageData = {
        General_header: "Delete Space ",
        GeneralMessage:
          "Are you sure you would like to delete this Space?<br> " +
          message +
          " <b>" +
          Device_Count +
          " </b>",
        General_first: "Yes ",
        General_second: "No",
      };
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.deleteUnit(FloorInfo.UnitId);
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - opendeletedialog() - " + err.message
      );
    }
  }

  deleteUnit(deleteUnitid: number) {
    try {
      const deleteunitById: deleteUnitById = {
        authKey: this.authkey,
        unitId: deleteUnitid,
      };

      this.technologyoverlayService
        .deleteUnit(deleteunitById)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            generateAlertMessage(this.dialog, "Space deleted successfully.");
            this.getUnitDetails();
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Space deleted failed ");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
            // this.thisDialogRef.close();
          }
        });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - deleteUnit() - " + err.message
      );
    }
  }

  opendeletefloordialog() {
    try {
      let Space_Count = "";
      let Device_Count = "";
      let devicecnt = 0;
      let message = "";
      if (this.UnitInfo.length >= 0) {
        message = "It contains ";
        Space_Count = this.UnitInfo.length + " Spaces and ";
        for (let ninc = 0; ninc < this.UnitInfo.length; ninc++) {
          if (this.UnitInfo[ninc].devicecount >= 0) {
            devicecnt += this.UnitInfo[ninc].devicecount;
          }
        }
        Device_Count = devicecnt + " Devices.";
      }

      const GeneralMessageData = {
        General_header: "Delete Zone ",
        GeneralMessage:
          "Are you sure you would like to delete this Zone?<br> " +
          message +
          " <b>" +
          Space_Count +
          Device_Count +
          " </b>",
        General_first: "Yes ",
        General_second: "No",
      };
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: GeneralMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "First") {
          this.deleteZone(this.deletefloorid);
        } else {
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - opendeletedialog() - " + err.message
      );
    }
  }

  deleteZone(deletefloorid: number) {
    try {
      const deleteZoneById: deleteZoneById = {
        authKey: this.authkey,
        zoneId: deletefloorid,
      };

      this.technologyoverlayService
        .deleteZone(deleteZoneById)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            //need check start
            this.alertSharedService
              .currentSharedHomeMapdetails()
              .pipe(takeUntil(this._onDestroy))
              .subscribe((ShareMapDetails) => {
                this.getselectedmapdetails = ShareMapDetails;
              });

            if (this.getselectedmapdetails != undefined) {
              if (
                this.campusid > 0 &&
                this.BuildId > 0 &&
                this.getselectedmapdetails.sel_CampusId == this.campusid &&
                this.getselectedmapdetails.sel_BuildingId == this.BuildId &&
                this.getselectedmapdetails.sel_FloorId == deletefloorid
              ) {
                const _SharedHomeMapDetails = {
                  sel_CampusId: this.campusid,
                  sel_BuildingId: this.BuildId,
                  sel_FloorId: 0,
                };
                this.alertSharedService.changeSharedHomeMapdetails(
                  _SharedHomeMapDetails
                );
              }
            }
            //need check send

            this.apiService.reloadmapconfig();
            generateAlertMessage(this.dialog, "Zone deleted successfully.");
          } else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(this.dialog, "Zone deleted failed.");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          } else {
            generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
          }
          this.thisDialogRef.close();
        });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - deleteZone() - " + err.message
      );
    }
  }

  editSpacedetails(Unitinfo) {
    try {
      const unitbyid: UnitById = {
        authKey: this.authkey,
        unitId: parseInt(Unitinfo.UnitId),
      };
      this.technologyoverlayService
        .getUnitById(unitbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((UnitByid: any[]) => {
          this.UnitById = UnitByid["apiReturn"][0];
          this.editunitopendialog(this.UnitById);
        });
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - editSpacedetails() - " + err.message
      );
    }
  }

  editunitopendialog(unitdetails) {
    if (this.uniteditdialogopen == 0) {
      const dialogRef = this.dialog.open(EditUnitComponent, {
        disableClose: true,
        width: "87em",
        height: "700px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: unitdetails,
      });
      this.uniteditdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.uniteditdialogopen = 0;
        if (result != "Cancel") this.getUnitDetails();
      });
    }
  }

  rotateImage(direction: string) {
    try {
      this.undooperation = true;
      this.isundo = 1;
      if (direction === "left") {
        const flag = this.imgWidth;
        this.imgWidth = this.height;
        this.height = flag;
        this.rotationAmount = this.rotationAmount + -90;
        if (this.rotationAmount == -360) {
          this.rotationAmount = 0;
        }

        if (this.rotationAmount === -180 || this.rotationAmount === 0) {
          this.top = 0;
          this.left = 0;
        } else {
          this.top = ((this.imgWidth - this.height) / 2) * -1;
          this.left = (this.imgWidth - this.height) / 2;
        }
      } else if (direction === "right") {
        this.rotationAmount = this.rotationAmount + 90;
        if (this.rotationAmount == 360) {
          this.rotationAmount = 0;
        }
      }

      if (this.downIcon == 1) this.E_svgFRotateLevel = this.rotationAmount;
      else this.E_pngFRotateLevel = this.rotationAmount;

      this.E_FRotateLevel = this.rotationAmount;
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - rotateImage() - " + err.message
      );
    }
  }

  zoomoutImage() {
    try {
      this.undooperation = true;
      this.isundo = 2;
      if (this.zoomval > 1) this.zoomval = this.zoomval - 0.5;

      if (this.downIcon == 1) this.E_svgFZoomLevel = this.zoomval;
      else this.E_pngFZoomLevel = this.zoomval;

      this.E_FZoomLevel = this.zoomval;
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - zoomoutImage() - " + err.message
      );
    }
  }

  zoomInImage() {
    try {
      this.undooperation = true;
      this.isundo = 3;
      if (this.zoomval < 5) this.zoomval = this.zoomval + 0.5;

      if (this.downIcon == 1) this.E_svgFZoomLevel = this.zoomval;
      else this.E_pngFZoomLevel = this.zoomval;

      this.zoominvalue = this.zoomval;
      this.E_FZoomLevel = this.zoomval;
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - zoomInImage() - " + err.message
      );
    }
  }

  leftImage() {
    if (this.E_pngFRotateLevel == "0") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "180") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.E_pngFRotateLevel == "90") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "270") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.isundo = 4;
    this.undopane = 1;
  }
  rightImage() {
    if (this.E_pngFRotateLevel == "0") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }
    if (this.E_pngFRotateLevel == "90") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "180") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "270") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    }

    this.undooperation = true;
    this.isundo = 5;
    this.undopane = 2;
  }
  bottomImage() {
    if (this.E_pngFRotateLevel == "0") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "90") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "180") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "270") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.isundo = 6;
    this.undopane = 3;
  }
  topImage() {
    if (this.E_pngFRotateLevel == "0") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "90") {
      if (!this.panAlign(panIsAdd.Add, panIsTopBottom.LeftRignt)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "180") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.TopBottom)) {
        return false;
      }
    } else if (this.E_pngFRotateLevel == "270") {
      if (!this.panAlign(panIsAdd.Sub, panIsTopBottom.LeftRignt)) {
        return false;
      }
    }

    this.undooperation = true;
    this.isundo = 7;
    this.undopane = 4;
  }

  panAlign(IsAdd, IsTopBottom) {
    let IsChk = false;
    if (IsAdd == panIsAdd.Add && IsTopBottom == panIsTopBottom.TopBottom) {
      if (this.top_bottomval < 70) {
        this.top_bottomval = this.top_bottomval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.TopBottom
    ) {
      if (this.top_bottomval > -70) {
        this.top_bottomval = this.top_bottomval - 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Add &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval < 70) {
        this.left_rightval = this.left_rightval + 10;
        IsChk = true;
      }
    } else if (
      IsAdd == panIsAdd.Sub &&
      IsTopBottom == panIsTopBottom.LeftRignt
    ) {
      if (this.left_rightval > -70) {
        this.left_rightval = this.left_rightval - 10;
        IsChk = true;
      }
    }
    return IsChk;
  }

  removeImage() {
    try {
      this.undooperation = true;
      this.isundo = 8;
      this.uploadfloorimage = this.flooreditImgUrl;
      this.flooreditImgUrl = "assets/images/defaultimg.png";
      this.flooreditsvgUrl = "assets/images/defaultsvg.png";
      this.validimgurl = false;
      this.rotationAmount = 0;
      this.E_pngFZoomLevel = 1;
      this.zoomval = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
      if (this.downIcon == 1) this.E_svgFRotateLevel = this.rotationAmount;
      else this.E_pngFRotateLevel = this.rotationAmount;
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - removeImage() - " + err.message
      );
    }
  }

  resetformtext() {
    try {
      this.undooperation = false;
      // this.rotationAmount = 0;
      if (this.downIcon == 1) this.E_svgFRotateLevel = 0;
      else this.E_pngFRotateLevel = 0;

      this.rotationAmount = 0;
      this.E_pngFZoomLevel = 1;
      this.E_FZoomLevel = 1;
      this.zoomval = 1;
      this.left_rightval = 0;
      this.top_bottomval = 0;
      this.E_pngFRotateLevel = 0;
      this.E_FRotateLevel = 0;
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - resetformtext() - " + err.message
      );
    }
  }

  undoimage() {
    try {
      if (this.undooperation == true) {
        if (this.isundo == 1) {
          if (this.E_pngFRotateLevel != 0)
            this.E_pngFRotateLevel = this.E_pngFRotateLevel - 90;
          else this.E_pngFRotateLevel = 270;

          this.rotationAmount = this.E_pngFRotateLevel;
          this.E_FRotateLevel = this.rotationAmount;
        } else if (this.isundo == 2) {
          if (this.E_pngFZoomLevel < this.zoominvalue)
            this.E_pngFZoomLevel = this.E_pngFZoomLevel + 0.5;
        } else if (this.isundo == 3) {
          if (this.E_pngFZoomLevel > 1)
            this.E_pngFZoomLevel = this.E_pngFZoomLevel - 0.5;
        } else if (this.isundo == 4) {
          if (this.undopane == 0) {
            if (this.left_rightval != 0)
              this.left_rightval = this.left_rightval - 10;
          } else {
            if (this.E_pngFRotateLevel == "90")
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.E_pngFRotateLevel == "180")
              this.left_rightval = this.left_rightval + 10;
            else if (this.E_pngFRotateLevel == "270")
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.E_pngFRotateLevel == "0")
              this.left_rightval = this.left_rightval - 10;
          }
        } else if (this.isundo == 5) {
          if (this.undopane == 0) {
            if (this.left_rightval != 0)
              this.left_rightval = this.left_rightval + 10;
          } else {
            if (this.E_pngFRotateLevel == "90")
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.E_pngFRotateLevel == "180")
              this.left_rightval = this.left_rightval - 10;
            else if (this.E_pngFRotateLevel == "270")
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.E_pngFRotateLevel == "0")
              this.left_rightval = this.left_rightval + 10;
          }
        } else if (this.isundo == 6) {
          if (this.undopane == 0) {
            if (this.top_bottomval != 0)
              this.top_bottomval = this.top_bottomval + 10;
          } else {
            if (this.E_pngFRotateLevel == "90")
              this.left_rightval = this.left_rightval + 10;
            else if (this.E_pngFRotateLevel == "180")
              this.top_bottomval = this.top_bottomval - 10;
            else if (this.E_pngFRotateLevel == "270")
              this.left_rightval = this.left_rightval - 10;
            else if (this.E_pngFRotateLevel == "0")
              this.top_bottomval = this.top_bottomval + 10;
          }
        } else if (this.isundo == 7) {
          if (this.undopane == 0) {
            if (this.top_bottomval != 0)
              this.top_bottomval = this.top_bottomval - 10;
          } else {
            if (this.E_pngFRotateLevel == "90")
              this.left_rightval = this.left_rightval - 10;
            else if (this.E_pngFRotateLevel == "180")
              this.top_bottomval = this.top_bottomval + 10;
            else if (this.E_pngFRotateLevel == "270")
              this.left_rightval = this.left_rightval + 10;
            else if (this.E_pngFRotateLevel == "0")
              this.top_bottomval = this.top_bottomval - 10;
          }
        } else if (this.isundo == 8) {
          this.flooreditImgUrl = this.uploadfloorimage;
          this.E_pngFRotateLevel = this.E_FRotateLevel;
          this.validimgurl = true;
        }
      }
      this.undooperation = false;
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - undoimage() - " + err.message
      );
    }
  }

  FloorMapView() {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(this.data.FloorId),
    };

    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.thisDialogRef.close(FloorByid[0]);
        this.router.navigate(["../../mapfloor"], { state: FloorByid["apiReturn"][0] });
      });
  }

  ReplaceMap() {
    const element: HTMLElement = document.getElementById(
      "imagefloorInput"
    ) as HTMLElement;
    element.click();
  }
  CropMap() {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(this.data.FloorId),
    };

    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.thisDialogRef.close(FloorByid[0]);
        this.router.navigate(["../../mapview-crop"], { state: FloorByid["apiReturn"][0] });
      });
  }

  editDevice_details(deviceinfo) {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      if (deviceinfo.FilterDeviceType != this._NewDeviceTypeDesc.Zone) {
        const _dialog = this.dialog;
        const mapsyncinfo: MapSyncProcess = {
          authKey: this.authkey,
        };
        this.apiService
          .checkMapSync(mapsyncinfo)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((MapSyncinfo: any[]) => {
            this.MapSyncProcess = MapSyncinfo;
            if (this.MapSyncProcess == 1) {
              generateAlertMessage(_dialog, appsettings.MapSyncMsg);
              return false;
            } else {
              if (deviceinfo.DeviceId > 0) {
                const getDeviceById: getDeviceById = {
                  authKey: this.authkey,
                  dataId: parseInt(deviceinfo.DataId),
                  deviceType: deviceinfo.DeviceName.toString(),
                  floorId: 0,
                  isIndoor: 1,
                };

                this.technologyoverlayService
                  .getDeviceDetailsbyId(getDeviceById)
                  .pipe(takeUntil(this._onDestroy))
                  .subscribe((Devicedetailsinfo: any[]) => {
                    if (
                      Devicedetailsinfo != null &&
                      Devicedetailsinfo != undefined
                    ) {
                      this.edit_Device_Details(Devicedetailsinfo["apiReturn"][0]);
                    }
                  });
              } else {
                this.edit_Device_Details(deviceinfo);
              }
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" + " - editDevice_details() - " + err.message
      );
    }
  }

  edit_Device_Details(deviceinfo) {
    let width = "37em";
    let height = "580px";

    if (deviceinfo.DeviceName == "UT") {
      width = "89rem";
      height = "740px";
    }
    const Devicedetails_info = { DeviceData: deviceinfo, AddDeviceflag: 0 };
    if (this.deviceEditdialogopen == 0) {
      const dialogRef = this.dialog.open(EditDeviceComponent, {
        disableClose: true,
        width: width,
        height: height,
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: Devicedetails_info,
      });
      this.deviceEditdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.deviceEditdialogopen = 0;
        if (result == "flooreditclose") this.getUnitDetails();
      });
    }
  }

  opendeletedevicedialog(Devicedata) {
    try {
      if (Devicedata.FilterDeviceType != this._NewDeviceTypeDesc.Zone) {
        const _dialog = this.dialog;
        const mapsyncinfo: MapSyncProcess = {
          authKey: this.authkey,
        };
        this.apiService
          .checkMapSync(mapsyncinfo)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((MapSyncinfo: any[]) => {
            this.MapSyncProcess = MapSyncinfo;
            if (this.MapSyncProcess == 1) {
              generateAlertMessage(_dialog, appsettings.MapSyncMsg);
              return false;
            } else {
              this.deleteconfirmation(Devicedata);
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-floor.component.ts" +
          " - opendeletedevicedialog() - " +
          err.message
      );
    }
  }

  deleteconfirmation(Devicedata) {
    try {      
      let deviceId_delete;
      if (Devicedata.DeviceName == "Gateway")
        deviceId_delete = Devicedata.MacId;
      else deviceId_delete = Devicedata.DeviceInfo;

      const ConfirmMessageData = {
        ConfirmMessage:
          "Are you sure you want to delete " +
          Devicedata.NewDeviceName +
          " [" +
          deviceId_delete +
          "]?",
      };
      this.deleteFlag = 0;
      if (this.deletedevice_dialogopen == 0) {
        const dialogRef = this.dialog.open(ConfirmMessageComponent, {
          disableClose: true,
          width: "auto",
          height: "200px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: ConfirmMessageData,
        });
        this.deletedevice_dialogopen = 1;
        dialogRef.afterClosed().subscribe((result) => {
          this.deletedevice_dialogopen = 0;
          if (result == "Yes") {
            this.deleteDevicedetails(Devicedata);
          } else {
            dialogRef.close("Cancel");
            this.deletedevice_dialogopen = 0;
            const devicelistelement = document.getElementById("divDeviceheader");
            if(devicelistelement != null)
              devicelistelement.className = "enable";
            const deviceaddelement = document.getElementById("divAddDevice");
            if(deviceaddelement != null)
              deviceaddelement.className = "enable";
            return;
          }
        });
      }
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - confirmation() - " + err.message
      );
    }
  }

  deleteDevicedetails(Data) {
    try {
      let devicetype;
      let alert_deviceId;
      let DCdualutid = 0;
      alert_deviceId = Data.DeviceId;
      if (Data.DeviceName == DeviceTypeDesc.Emitter) {
        devicetype = DeviceType.Emitter;
        alert_deviceId = Data.DeviceTypeId;
      } else if (Data.DeviceName == DeviceTypeDesc.HRex) {
        devicetype = DeviceType.HRex;
        alert_deviceId = Data.DeviceInfo;
      } else if (Data.DeviceName == DeviceTypeDesc.Rex)
        devicetype = DeviceType.Rex;
      else if (Data.DeviceName == DeviceTypeDesc.Room)
        devicetype = DeviceType.Room;
      else if (Data.DeviceName == DeviceTypeDesc.Pin)
        devicetype = DeviceType.Pin;
      else if (Data.DeviceName == DeviceTypeDesc.LF) devicetype = DeviceType.LF;
      else if (Data.DeviceName == DeviceTypeDesc.FloorPlanNotes)
        devicetype = DeviceType.FloorPlanNotes;
      else if (Data.DeviceName == DeviceTypeDesc.Pullcord)
        devicetype = DeviceType.Pullcord;
      else if (
        Data.DeviceName == DeviceTypeDesc.DC ||
        Data.DeviceName == DeviceTypeDesc.NewDC
      ) {
        devicetype = DeviceType.DC;
        DCdualutid = Data.dualutId;
      } else if (Data.DeviceName == DeviceTypeDesc.Monitor)
        devicetype = DeviceType.Monitor;
      else if (Data.DeviceName == DeviceTypeDesc.VW) devicetype = DeviceType.VW;
      else if (Data.DeviceName == DeviceTypeDesc.Gateway)
        devicetype = DeviceType.Gateway;
      else if (Data.DeviceName == DeviceTypeDesc.UT) devicetype = DeviceType.UT;
      else if (Data.DeviceName == DeviceTypeDesc.UM) devicetype = DeviceType.UM;
      else if (Data.DeviceName == DeviceTypeDesc.Intercom) devicetype = DeviceType.IC;

      const deletedevicedetails: DeleteDevice = {
        authKey: this.authkey,
        campusId: parseInt(Data.CampusId),
        buildingId: parseInt(Data.BuildingId),
        floorId: parseInt(Data.FloorId),
        monitorId: parseInt(Data.DeviceId),
        oldDeviceId: parseInt(Data.DeviceId),
        svgId: parseInt(Data.SvgId),
        svgDeviceType: parseInt(devicetype),
        updateMode: 3,
        location: Data.UnitLocation.toString(),
        emitterID: Data.DeviceTypeId.toString(),
        monitorType: Data.DeviceName.toString(),
        vmDirection: devicetype.toString(),
        unitId: parseInt(Data.UnitId),
        deviceX: -1,
        deviceY: -1,
        polygonPoints: "",
        oldEmiterId: "",
        dcDualId: 0,
        oldDcDualId: 0,
        dcMacId: "",
        oldDCMacId: "",
        isDCTag: 0,
        isDCLotiering: 0,
        isDCUnauthorized: 0,
        ispcRemoveDetection: 0,
        ispcButtonPress: 0,
        isPullCord: 0,
        ispcMobilityMode: 0,
        enableInput1: 0,
        alertState1: 0,
        alertMessage1: "",
        enableInput2: 0,
        alertState2: 0,
        alertMessage2: "",
        enableInput3: 0,
        alertState3: 0,
        alertMessage3: "",
        ambientOverTemp: 0,
        ambientOverDesc: "",
        ambientOverMessage: "",
        ambientUnderTemp: 0,
        ambientUnderDesc: "",
        ambientUnderMessage: "",
        probeOverTemp: 0,
        probeOverDesc: "",
        probeOverMessage: "",
        probeUnderTemp: 0,
        probeUnderDesc: "",
        probeUnderMessage: "",
        humidityOverTemp: 0,
        humidityOverDesc: "",
        humidityOverMessage: "",
        humidityUnderTemp: 0,
        humidityUnderDesc: "",
        humidityUnderMessage: "",
        resRoomCategory: 0,
        dataId: parseInt(Data.DataId),
        isIndoor: 1,
        input1StartHr: 0,
        input1StartMin: 0,
        input1EndHr: 0,
        input1EndMin: 0,
        input1checkAllDay : 0,
        input2StartHr: 0,
        input2StartMin: 0,
        input2EndHr: 0,
        input2EndMin: 0,
        input2checkAllDay : 0,
        input3StartHr: 0,
        input3StartMin: 0,
        input3EndHr: 0,
        input3EndMin: 0,
        input3checkAllDay : 0
      };
      const deviceDetails: checkDeviceAlert = {
        authKey: this.authkey,
        deviceId: parseInt(alert_deviceId),
        deviceType: devicetype.toString(),
        dualFlag: DCdualutid,
        isDelete: 1,
      };
      this.technologyoverlayService
        .checkDeviceInAlert(deviceDetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "This device has open alerts, please complete or cancel those alerts and try again."
            );
            const devicelistelement =
              document.getElementById("divDeviceheader");
            if (devicelistelement != null)
              devicelistelement.className = "enable";
            const deviceaddelement = document.getElementById("divAddDevice");
            if (deviceaddelement != null) deviceaddelement.className = "enable";
            return;
          } else {
            const LocationDetails: checkLocationAlert = {
              authKey: this.authkey,
              irOrRFId: Data.DeviceId.toString(),
              deviceType: devicetype.toString(),
            };
            this.technologyoverlayService
              .checkLocationInAlert(LocationDetails)
              .pipe(takeUntil(this._onDestroy))
              .subscribe((apiresponse: any[]) => {
                if (apiresponse.toString() == "true") {
                  generateAlertMessage(
                    this.dialog,
                    "This Location has open alerts, please complete or cancel those alerts and try again."
                  );
                  const devicelistelement =
                    document.getElementById("divDeviceheader");
                  if (devicelistelement != null)
                    devicelistelement.className = "enable";
                  const deviceaddelement =
                    document.getElementById("divAddDevice");
                  if (deviceaddelement != null)
                    deviceaddelement.className = "enable";
                  return;
                } else {
                  this.technologyoverlayService
                    .deleteDevicesforFloor(deletedevicedetails)
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(
                      (apiresponse: TechnologyForCreationResponse[]) => {
                        if (apiresponse != null && apiresponse != undefined) {
                          if (apiresponse["apiReturn"] == "Success") {
                            if (
                              Data.DeviceName == DeviceTypeDesc.Rex ||
                              Data.DeviceName == DeviceTypeDesc.HRex
                            ) {
                              this.technologyoverlayService.reloadiniltc();
                              this.technologyoverlayService.systemreset_ltc();
                            }
                            if (Data.DeviceName == DeviceTypeDesc.Pullcord) {
                              this.technologyoverlayService.reloadPullCordConfig_ltc();
                            } else if (
                              Data.DeviceName == DeviceTypeDesc.DC ||
                              Data.DeviceName == DeviceTypeDesc.NewDC
                            ) {
                              this.apiService.reload_DC_Config();
                            } else if (Data.DeviceName == DeviceTypeDesc.UT) {
                              this.apiService.reloadDCTdevice();
                            } else if (
                              Data.DeviceName != DeviceTypeDesc.Rex &&
                              Data.DeviceName != DeviceTypeDesc.HRex &&
                              Data.DeviceName != DeviceTypeDesc.Gateway
                            ) {
                              this.apiService.reload_DrawRoom();
                            }

                            if (
                              Data.DeviceName == DeviceTypeDesc.Rex ||
                              Data.DeviceName == DeviceTypeDesc.HRex
                            ) {
                              generateAlertMessage(
                                this.dialog,
                                "Device details deleted successfully. <br> It will take 400 seconds for the settings to take effect."
                              );
                            } else {
                              generateAlertMessage(
                                this.dialog,
                                "Device details deleted successfully."
                              );
                            }

                            if (
                              Data.DeviceName == DeviceTypeDesc.HRex ||
                              Data.DeviceName == DeviceTypeDesc.UT ||
                              Data.DeviceName == DeviceTypeDesc.DC ||
                              Data.DeviceName == DeviceTypeDesc.NewDC ||
                              Data.DeviceName == DeviceTypeDesc.Pullcord
                            ) {
                              let CheckAlertData = {
                                IsAlertChanged: 0,
                                IsAlertClicked: 0,
                              };
                              this.alertSharedService.changeSharedCheckAlert(
                                CheckAlertData
                              );
                              CheckAlertData = {
                                IsAlertChanged: 1,
                                IsAlertClicked: 0,
                              };
                              this.alertSharedService.changeSharedCheckAlert(
                                CheckAlertData
                              );
                            }
                            // this.thisDialogRef.close('flooreditclose');
                            this.getUnitDetails();
                          } else if (apiresponse["apiReturn"] == "Fail") {
                            generateAlertMessage(
                              this.dialog,
                              "Device details deleted failed."
                            );
                          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
                            this.thisDialogRef.close("close");
                            AuthFailedMessage(this.dialog, this.router);
                            return;
                          }
                        } else {
                          generateAlertMessage(
                            this.dialog,
                            "Device details deleted failed."
                          );
                        }
                      }
                    );
                }
              });
          }
        });
    } catch (err) {
      throw new Error(
        "device.component.ts" + " - deleteDevicedetails() - " + err.message
      );
    }
  }

  togglefloorCamVisibility(event) {
    if (event.checked == true) {
      this.F_chkCampusvalue = true;
      this.F_chkCustomvalue = false;
      this.NotifydefaultValue = 1;
    } else {
      this.NotifydefaultValue = 0;
      this.F_chkCustomvalue = false;
      this.F_chkCampusvalue = false;
    }
  }

  togglefloorCusVisibility(event) {
    this.NotifydefaultValue = 0;
    if (event.checked == true) {
      this.F_chkCustomvalue = true;
      this.F_chkCampusvalue = false;
    } else {
      this.F_chkCampusvalue = false;
      this.F_chkCustomvalue = false;
    }
  }

  toggleEditCheckInVisibility(event) {
    if (event.checked == true) {
      this.editCheck_Invalue = true;
    } else {
      this.editCheck_Invalue = false;
    }
  }
  
  changeFloor_Building(floorId)
  {
    this.selectedFloorId = floorId;        
  }
  
  zoneisFloorEvent(event)
  {
    this.toggleZoneisFloor(event.checked);
  }
  
  toggleZoneisFloor(checked)
  {
    if(checked == true)
    {
      this.checkZoneisFloor = true;
      this.selectedZoneName = "";
      const diveditFloortxt = document.getElementById('diveditFloortxt');
      const diveditFloorDropdown = document.getElementById('diveditFloorDropdown');
      const zoneName = this.flooreditForm.get('zoneName');
      const floorName = this.flooreditForm.get('floorname');
      
      if(diveditFloortxt != null)
        diveditFloortxt.style.display = "block";            
      if(diveditFloorDropdown != null)
        diveditFloorDropdown.style.display = "none";             
      if(zoneName != null)
        zoneName.disable();       
        
      if(floorName != null)
      {
        floorName.clearValidators();
        floorName.updateValueAndValidity();
      }
        
    }
    else
    {
      this.checkZoneisFloor = false;           
      const diveditFloortxt = document.getElementById('diveditFloortxt');
      const diveditFloorDropdown = document.getElementById('diveditFloorDropdown');
      const zoneName = this.flooreditForm.get('zoneName');
      
      if(diveditFloortxt != null)
        diveditFloortxt.style.display = "none";            
      if(diveditFloorDropdown != null)
        diveditFloorDropdown.style.display = "block";           
      if(zoneName != null)
        zoneName.enable();        
    }
  }
  
  addEditSubZoneDetails(clksubzoneData,subzoneflag)
  {
    try
    {    
      console.log(subzoneflag)
      let subZoneid = 0;
      let subZonename = "";        
      if(clksubzoneData != '')
      {
        subZoneid = clksubzoneData.SubZoneId;
        subZonename = clksubzoneData.SubZoneDesc;
      }
      const zonedata: any = {          
        buildName : this.selectedBuildName,
        campusName : this.selectedCampusName,
        subZonedata : this.floorInfo,                 
        subZoneId : subZoneid,
      subZoneName : subZonename,
      bgImageWidth:this.bgImageWidth,
      bgImageHeight:this.bgImageHeight,
      }  
      
      const dialogRef = this.dialog.open(ManagesubZoneComponent, {
        disableClose: true,
        width: '75em',
        height:'780px',
        panelClass: 'myapp-no-padding-dialog',
        autoFocus: false,
        data:zonedata
      });  
      dialogRef.afterClosed().subscribe(result => {     
        const floorplanslistelement = document.getElementById('divfloorplandetails');
        if(floorplanslistelement != null)
            floorplanslistelement.className = 'clsenable'; 
          if(result != "Cancel")
            this.getsubZoneDetails();
      })
    }
    catch(err)
    {
        throw new Error('edit-zone.component.ts' + ' - addEditSubZoneDetails() - ' + err.message);
    } 
  }
  
  editFloorEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsaveflooreditclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsflooreditbutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsflooreditbutton mat-raised-button disablebtn";
  }

  opensubzonedeletedialog(SubZoneData)
{
  try
    {   
      if(!SessionCheck(this.router))
      {
        this.thisDialogRef.close('Cancel');
          return;
      }        
      this.deleteSubZonedetails(SubZoneData)
    }
    catch (err) {
          throw new Error('edit-zone.component.ts' + ' - openSubZoneDeletedialog() - ' + err.message);
      }  
}


deleteSubZonedetails(deleteSubZone)
  {
    try
    {                
      let Space_Count="";
      let message="";      
          
      if(deleteSubZone.SpaceCount > 0)   
      {
          message = "It contains ";
          Space_Count = deleteSubZone.SpaceCount + " Spaces.";
      }
      
      const GeneralMessageData = {
        General_header:  "Delete Sub-Zone ",                
        GeneralMessage:  "Are you sure you want to delete this Sub-Zone?<br> " + message + " <b>" + Space_Count + " </b>",
        General_first:  "Yes ",
        General_second:  "No"
      }  
      
      const dialogRef = this.dialog.open(GeneralMessageComponent, {
        disableClose: true,
        width: 'auto',
        height: '220px',
        panelClass: 'myapp-no-padding-dialog',
        autoFocus: false,
        data: GeneralMessageData
      });     
      
      dialogRef.afterClosed().subscribe(result => {     
        if(result == "First")
        {                     
            this.DeleteSpacebyId(deleteSubZone.SubZoneId)         
        }
        else
        {                
          dialogRef.close('Cancel');          
          return;
        }
      });            
    }
    catch(err)
    {
        throw new Error('edit-zone.component.ts' + ' - deleteUnit() - ' + err.message);
    } 
  }
  
DeleteSpacebyId(subzoneid)
{
  const deleteunitById :DeleteSubZone = {
    authKey:  this.authkey,
    subZoneId: parseInt(subzoneid)
  }
  this.technologyoverlayService.deleteSubZone(deleteunitById).pipe(takeUntil(this._onDestroy))
  .subscribe((apiresponse : any[])=>{           
      if(apiresponse.toString() == "true")
      { 	
        generateAlertMessage(this.dialog, 'Sub-Zone deleted successfully.');   
        this.getsubZoneDetails();                       
      } 
      else if(apiresponse.toString() == "false")
      {
        generateAlertMessage(this.dialog, 'Sub-Zone deleted failed ');                    
      }
      else if(apiresponse["condition"] == "Authfalse")
      {    
          this.thisDialogRef.close('close');              
          AuthFailedMessage(this.dialog,this.router);  
          return;       
      }
      else
      {
        generateAlertMessage(this.dialog, apiresponse.toString());                       
      }              
    });
}

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}


enum panIsAdd {
  Add = 0,
  Sub = 1,
}
enum panIsTopBottom {
  TopBottom = 0,
  LeftRignt = 1,
}
