import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { ReportService } from "../report.service";
import { DatePipe } from "@angular/common";
import "jspdf-autotable";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { Router } from "@angular/router";
declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

@Component({
  selector: "app-download-locationhistory-report",
  templateUrl: "./download-locationhistory-report.component.html",
  styleUrls: ["./download-locationhistory-report.component.scss"],
})
export class DownloadLocationhistoryReportComponent implements OnInit {
  TodayDate;
  downloadData;
  FilterValue;
  FilterDownloadData = [] as any[];
  public isExcelDownload = false;
  public isPDFDownload = false;
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private reportService: ReportService,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<DownloadLocationhistoryReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.downloadData = data.Data;
    this.FilterValue = data.filterValue;
  }

  ngOnInit() {
    const loaderelement = document.getElementsByClassName(
      "loadLocationreportspinner"
    );
    loaderelement[0]["style"].display = "none";
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  exportLocationReportExcel() {
    const loaderelement = document.getElementsByClassName(
      "loadLocationreportspinner"
    );
    loaderelement[0]["style"].display = "block";
    let Filter_DownloadData = [] as any[];
    if (
      this.FilterValue != 0 &&
      this.FilterValue != "All" &&
      this.FilterValue != undefined
    ) {
      const list = this.downloadData.filter(
        (x) => x.ResidentId == this.FilterValue
      );
      list.forEach((x) => {
        Filter_DownloadData.push(x);
      });
    } else if (this.FilterValue == "All") {
      Filter_DownloadData = this.downloadData;
    } else {
      Filter_DownloadData = this.downloadData;
    }
    setTimeout(() => {
      this.exportLocation_ReportExcel(Filter_DownloadData);
    }, 3);
  }

  exportLocation_ReportExcel(LocationReportFilter) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isExcelDownload) {
        this.isExcelDownload = true;
        this.TodayDate = new Date();
        this.TodayDate = this.datePipe.transform(this.TodayDate, "yyyy-MM-dd");
        let locationType;
        const downloadLocation = {
          Data: [] as any[],
        };

        if (
          LocationReportFilter != undefined &&
          LocationReportFilter.length > 0
        ) {
          for (let i = 0; i < LocationReportFilter.length; i++) {
            const item = LocationReportFilter[i];
            locationType = item.IsResident;
            if (item.IsResident == 1) {
              downloadLocation.Data.push({
                "Resident Id": item.ResidentId,
                "Resident Name": item.ResidentName,
                "Room Id": item.RoomId,
                "Room Name": item.RoomName,
                "Updated On": item.UpdatedOn,
              });
            } else if (item.IsResident == 0) {
              downloadLocation.Data.push({
                "Staff Id": item.ResidentId,
                "Staff Name": item.ResidentName,
                "Room Id": item.RoomId,
                "Room Name": item.RoomName,
                "Updated On": item.UpdatedOn,
              });
            }
          }
          this.JSONToCSVConvertor(
            downloadLocation.Data,
            this.TodayDate,
            locationType,
            true
          );
        } else {
          const loaderelement = document.getElementsByClassName(
            "loadLocationreportspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "No data found");
          this.isExcelDownload = false;
        }
      }
    } catch (err) {
      throw new Error(
        "download-loctionhistory-report.component.ts" +
          " - exportLocationReportExcel() - " +
          err.message
      );
    }
  }

  JSONToCSVConvertor(JSONData, date, L_Type, ShowLabel) {
    try {
      const arrData =
        typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      let CSV = "";

      //This condition will generate the Label/Header
      if (ShowLabel) {
        let row = "";

        //This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);
        //append Label row with line break
        CSV += row + "\r\n";
      }
      if (arrData.length > 0) {
        //1st loop is to extract each row
        for (let i = 0; i < arrData.length; i++) {
          let row = "";

          //2nd loop will extract each column and convert it in string comma-seprated
          for (const index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }

          row.slice(0, row.length - 1);

          //add a line break after each row
          CSV += row + "\r\n";
        }

        if (CSV == "") {
          const loaderelement = document.getElementsByClassName(
            "loadLocationreportspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Invalid data");
          this.isExcelDownload = false;
          return;
        }

        //Generate a file name
        let fileName = "";
        if (L_Type == 1) fileName = "Location History Report Data for Resident";
        else if (L_Type == 0)
          fileName = "Location History Report Data for Staff";

        const myBlob = new Blob([CSV], { type: "text/html" });
        const url = window.URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        const link = document.body.appendChild(a);
        document.body.appendChild(link);
        link.href = url;
        link.download = fileName + "_" + date + ".csv";
        link.click();
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadLocationreportspinner"
        );
        loaderelement[0]["style"].display = "none";
        generateAlertMessage(this.dialog, "No data found");
        this.isExcelDownload = false;
      }
      const loaderelement = document.getElementsByClassName(
        "loadLocationreportspinner"
      );
      loaderelement[0]["style"].display = "none";
      this.isExcelDownload = false;
    } catch (err) {
      throw new Error(
        "download-loctionhistory-report.component.ts" +
          " - JSONToCSVConvertor() - " +
          err.message
      );
    }
  }

  exportLocationReportPdf() {
    const loaderelement = document.getElementsByClassName(
      "loadLocationreportspinner"
    );
    loaderelement[0]["style"].display = "block";
    let Filter_DownloadData = [] as any[];
    if (
      this.FilterValue != 0 &&
      this.FilterValue != "All" &&
      this.FilterValue != undefined
    ) {
      const list = this.downloadData.filter(
        (x) => x.ResidentId == this.FilterValue
      );
      list.forEach((x) => {
        Filter_DownloadData.push(x);
      });
    } else if (this.FilterValue == "All") {
      Filter_DownloadData = this.downloadData;
    } else {
      Filter_DownloadData = this.downloadData;
    }
    setTimeout(() => {
      this.exportLocation_ReportPdf(Filter_DownloadData);
    }, 3);
  }

  exportLocation_ReportPdf(LocaitonHistoryFilterdata) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }

      if (!this.isPDFDownload) {
        this.isPDFDownload = true;
        this.TodayDate = new Date();
        this.TodayDate = this.datePipe.transform(this.TodayDate, "yyyy-MM-dd");
        let LocationType;
        const infraData = [] as any[];
        let columns = [] as any[];
        if (
          LocaitonHistoryFilterdata.length > 0 &&
          LocaitonHistoryFilterdata[0].IsResident == 1
        ) {
          columns = [
            { title: "Resident Id", dataKey: "ResidentId" },
            { title: "Resident Name", dataKey: "ResidentName" },
            { title: "Room Id", dataKey: "RoomId" },
            { title: "Room Name", dataKey: "RoomName" },
            { title: "Updated On", dataKey: "UpdatedOn" },
          ];
        } else if (
          LocaitonHistoryFilterdata.length > 0 &&
          LocaitonHistoryFilterdata[0].IsResident == 0
        ) {
          columns = [
            { title: "Staff Id", dataKey: "ResidentId" },
            { title: "Staff Name", dataKey: "ResidentName" },
            { title: "Room Id", dataKey: "RoomId" },
            { title: "Room Name", dataKey: "RoomName" },
            { title: "Updated On", dataKey: "UpdatedOn" },
          ];
        }

        if (
          LocaitonHistoryFilterdata != undefined &&
          LocaitonHistoryFilterdata.length > 0
        ) {
          for (let i = 0; i < LocaitonHistoryFilterdata.length; i++) {
            const ResidentId = LocaitonHistoryFilterdata[i].ResidentId;
            const ResidentName = LocaitonHistoryFilterdata[i].ResidentName;
            const RoomId = LocaitonHistoryFilterdata[i].RoomId;
            const RoomName = LocaitonHistoryFilterdata[i].RoomName;
            const UpdatedOn = LocaitonHistoryFilterdata[i].UpdatedOn;
            LocationType = LocaitonHistoryFilterdata[i].IsResident;
            infraData.push({
              ResidentId: ResidentId,
              ResidentName: ResidentName,
              RoomId: RoomId,
              RoomName: RoomName,
              UpdatedOn: UpdatedOn,
            });
          }
          const pdf = new jsPDF("l"); // A4 size page of PDF
          let PdfFileName = "";
          const pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
          if (LocationType == 1) {
            PdfFileName = "LocationHistory_Report_Data for Resident";
            pdf.text(
              "Location History Report for Resident",
              pageWidth / 2,
              10,
              "center"
            );
          } else {
            PdfFileName = "LocationHistory_Report_Data for Staff";
            pdf.text(
              "Location History Report for Staff",
              pageWidth / 2,
              10,
              "center"
            );
          }

          const options = {
            theme: "grid",
            bodyStyles: { lineColor: [0, 0, 0] },
            tableWidth: 'auto',
            columnWidth: 'wrap',
            showHeader: 'everyPage',
            headStyles: {
              lineWidth: 0.2,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
              fillColor: [169, 169, 169],
            },
          };

          pdf.autoTable(columns, infraData, options);
          PdfFileName = PdfFileName + "_" + this.TodayDate + ".pdf";
          pdf.save(PdfFileName);
        } else {
          const loaderelement = document.getElementsByClassName(
            "loadLocationreportspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "No data found");
          this.isPDFDownload = false;
        }
        const loaderelement = document.getElementsByClassName(
          "loadLocationreportspinner"
        );
        loaderelement[0]["style"].display = "none";
        this.isPDFDownload = false;
      }
    } catch (err) {
      throw new Error(
        "download-locationhistory-report.component.ts" +
          " - exportInfrastructureReporttopdf() - " +
          err.message
      );
    }
  }
}
