<app-technologyoverlay-header ></app-technologyoverlay-header>

<div fxLayout="row">

  <div fxLayout="column">
    <app-technologyoverlay-leftbar (click)="CloseRightNav()"></app-technologyoverlay-leftbar>
  </div>  
  
  <div fxLayout="column" fxFlex="100">
    <div class="landing-title-bar" style="border-bottom:2px solid #d2d4d8;">
      <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutGap="5px" fxLayoutWrap style="display: inline-block;">
        <div fxFlex="25" fxFlex.md="25" class="clsdivflex" style="padding-top:5px;"> 
            <div class="clsconfigTitle"> <span style="font-size: 16px;font-weight: bolder;color:black;">Total Number of Hardwired Devices : </span><span style="font-size: 16px;color:#949596">{{dataSourcecnt}} </span></div>              
         </div>
         
         <div fxLayout="row" fxFlex="70" fxLayoutAlign="end start" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column">
         <div class="clsdivDeviceselect"> 
            <mat-form-field appearance="outline" floatLabel="auto" class="clsoutmatformfield">
              <mat-select placeholder="Select Alarm Clear Mode" [(ngModel)]="selectedAlarmClearMode" >    
              <mat-option *ngFor="let mode of alarmClearMode" [value]="mode.Value" (click)="changeAlarmClearMode(mode);">
                {{mode.modeType}}
              </mat-option>
              </mat-select>
            </mat-form-field>
          </div>   
          
          <div class="clsdivDeviceselect"> 
          <button color="primary" type="button" id = "btnApplyBoardConfiguarion" class="clsfloortempbutton" mat-raised-button (click)="applyBoardConfiguarion()">Apply Board Configuration</button>&nbsp;&nbsp;    
         </div>
         <div class="clsdivDeviceselect">
          <button color="primary" type="button" id = "btnDownloadTemplate" class="clsfloortempbutton" style= "background-color:#949596;" mat-raised-button (click)="downloadExcelTemplate()">Download Excel Template</button>&nbsp;&nbsp;           
       </div>
       <div class="clsdivDeviceselect"> 
        <button color="primary" type="button" id = "btnUploadConfig" class="clsfloortempbutton" mat-raised-button (click)="uploadNewConfiguration()"  *ngIf="bEditiconGroup==true">Upload New Configuration</button>   
        </div>
    </div>     
    </div>
    </div>
    <perfect-scrollbar>        
      <div class="clstablecontent">
            <table mat-table [dataSource]= "dataSource" matSort matSortDisableClear=true>                              
                 <ng-container matColumnDef="BoardName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class= "clsheader_Hardwired" [ngClass]="'headercell'"> Name </th> 
                      <td mat-cell *matCellDef="let element"  style="text-align:center;"> {{element.boardName}} </td>                      
                 </ng-container>

                 <ng-container matColumnDef="IPAddress">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class= "clsheader_Hardwired" [ngClass]="'headercell'"> IP Address </th>
                    <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.ipAddress}} </td>
                 </ng-container>

                 <ng-container matColumnDef="Port">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class= "clsheader_Hardwired" [ngClass]="'headercell'"> Port </th>                      
                      <td mat-cell *matCellDef="let element"  style="text-align: center;"> {{element.port}} </td>
                 </ng-container>  
                  
                 <!-- <ng-container matColumnDef="Campus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class= "clsheader_Hardwired" [ngClass]="'headercell'"> Campus </th>                      
                    <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.Campus}} </td>
                 </ng-container>
                  
                 <ng-container matColumnDef="Building">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class= "clsheader_Hardwired" [ngClass]="'headercell'"> Building </th>                      
                    <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.Building}} </td>
                 </ng-container>  -->

                 <ng-container matColumnDef="Edit" *ngIf="bEditiconGroup==true">
                    <th mat-header-cell *matHeaderCellDef class= "clsheader_Edit" [ngClass]="'headercell'" style="text-align: center;"> Edit </th>
                    <ng-container *matCellDef="let element" style="text-align: center;">                              
                        <td mat-cell ><button mat-button (click)="editConfigurationdetails(element)">
                          <mat-icon>edit</mat-icon></button> </td>
                 </ng-container>
                 </ng-container>                                  

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
      </div>
      </perfect-scrollbar>
        <!-- <ng-template #noRecord>  
             <mat-card>
               <h3 *ngIf="!resloading" style="text-align: center;"> No Record found </h3>   
               <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%;">
                <div class="loadspinner" *ngIf="resloading">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                </div>
              </div>        
             </mat-card>
        </ng-template> -->
      
  </div>
</div>
