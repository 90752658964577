import { Component, OnInit } from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { TechnologyoverlayService } from "../technologyoverlay.service";
import { Subject } from "rxjs";
import { ApiService } from "../../../api.service";
import { takeUntil } from "rxjs/operators";
import {
  getCampus,
  getCampusdetails,
  getBuilding,
  getBuildingdetails,  
  getFloordetails,
  getUnit,
  getUnitdetails,
  getZone
} from "../../../api";
import { SessionCheck } from "../../../app-general";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

@Component({
  selector: "app-device-advancefilter",
  templateUrl: "./device-advancefilter.component.html",
  styleUrls: ["./device-advancefilter.component.scss"],
})
export class DeviceAdvancefilterComponent implements OnInit {
  public deviceFilterForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  Campusdetails: getCampusdetails[];
  Buildingdetails: getBuildingdetails[];
  Floordetails: getFloordetails[];
  Unitdetails: getUnitdetails[];
  columns = 4;
  firstcolumns = 2;
  private _onDestroy: Subject<void> = new Subject<void>();
  devicefilterbool = true;
  selectedCampusId;
  Unitdetails_all = [] as any[];
  selectedBuildingId = 0;
  selectedFloorId = 0;
  selectedUnitId = 0;
  authkey;

  constructor(    
    private apiService: ApiService,
    private router: Router,
    public thisDialogRef: MatDialogRef<DeviceAdvancefilterComponent>,
    private technologyoverlayService: TechnologyoverlayService
  ) {      
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.deviceFilterForm = new UntypedFormGroup({
      campus: new UntypedFormControl("", [Validators.required]),
      building: new UntypedFormControl(""),
      floor: new UntypedFormControl(""),
      unit: new UntypedFormControl(""),
    });
    this.getCampusDetails();
  }

  getCampusDetails() {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - getCampusDetails() - " + err.message
      );
    }
  }

  getBuildingDetails(CampusId) {
    try {
      this.selectedCampusId = CampusId;
      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
      };
      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          this.Buildingdetails = BuildingDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "device-advancefilter.component.ts" +
          " - getBuildingDetails() - " +
          err.message
      );
    }
  }

  getFloorDetails(BuildingId) {
    try {
      this.selectedBuildingId = BuildingId;
      const floorid: getZone = {
        authKey: this.authkey,
        campusId:this.selectedCampusId,
        buildId : parseInt(BuildingId),
        floorId : 0
        
      };
      this.apiService
        .getZoneDetails(floorid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((FloorDetails: any[]) => {
          this.Floordetails = FloorDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - getFloorDetails() - " + err.message
      );
    }
  }

  getUnitDetails(FloorId) {
    try {
      let unitdata = [] as any[];
      this.selectedFloorId = FloorId;
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const unitid: getUnit = {
        authKey: this.authkey,
        floorId: FloorId,
        isIndoor: 1,
        isNeedIntercom: parseInt(voiceLicense),
        isSubZone : 0   
      };
      this.apiService
        .getUnitDetails(unitid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((UnitDetails: any[]) => {
          unitdata = UnitDetails["apiReturn"];
          this.Unitdetails_all = unitdata;
          this.selectUnits_outdoor();
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" + " - getUnitDetails() - " + err.message
      );
    }
  }

  selectUnits_outdoor() {
    try {
      const outdoorunitdata = [] as any[];
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      this.Unitdetails = [];
      const unitid: getUnit = {
        authKey: this.authkey,
        floorId: this.selectedCampusId,
        isIndoor: 0,
        isNeedIntercom: parseInt(voiceLicense),
        isSubZone : 0   
      };
      this.apiService
        .getUnitDetails(unitid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((outdoorUnitDetails: any[]) => {
          outdoorUnitDetails["apiReturn"].forEach(function (data) {
            if (data.devicecount > 0) {
              outdoorunitdata.push(data);
            }
          });

          this.Unitdetails = this.Unitdetails_all.concat(outdoorunitdata);
        });
    } catch (err) {
      throw new Error(
        "add-resident.component.ts" +
          " - selectUnits_outdoor() - " +
          err.message
      );
    }
  }

  changeunit(unitdata) {
    this.selectedBuildingId = unitdata.BuildId;
    this.selectedFloorId = unitdata.FloorId;
    this.selectedUnitId = unitdata.UnitId;
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.deviceFilterForm.controls[controlName].hasError(errorName);
  };

  public FilterDevicedetails = (deviceFilterForm) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.deviceFilterForm.valid && this.devicefilterbool) {
        this.devicefilterbool = false;
        this.loadDevicefilterdetials(deviceFilterForm);
      }
    } catch (err) {
      throw new Error(
        "device-advancedfilter.component.ts" +
          " - FilterDevicedetails() - " +
          err.message
      );
    }
  };

  loadDevicefilterdetials(devicefilterformvalue) {
    const filterdetails: any = {
      CampusId: devicefilterformvalue.campus,
      BuildingId: this.selectedBuildingId,
      FloorId: this.selectedFloorId,
      UnitId: this.selectedUnitId,
    };
    this.thisDialogRef.close(filterdetails);
  }
}
