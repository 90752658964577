<perfect-scrollbar style="max-width: 1200px; max-height: 790px;" >

  <div class="clseditstaff">
    <div class="add-title">
      <h3 class="editstaffHeader" style="margin: 0 0 10px 11px;">Edit Staff Details
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span>
      </h3>
      <mat-divider></mat-divider>
    </div>
    <div id="staffeditdialog">
      <form style="padding:24px" [formGroup]="staffeditForm" autocomplete="off" novalidate
        (ngSubmit)="createStaffbyId(staffeditForm.value)">
        <mat-grid-list [cols]="columns" [rowHeight]="staffrowHeight">
          <mat-grid-tile>
            <label>First Name</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="First Name" formControlName="firstname" maxlength="30" tabindex="1"
                  [(ngModel)]="data[0].FirstName" (keyup)="handleInput($event)" required>
                <input type="hidden" formControlName="staffId" [(ngModel)]="data[0].StaffID" required>
                <mat-error *ngIf="staffeditForm.controls.firstname.hasError('required')">
                  Please enter First Name
                </mat-error>
                <mat-error *ngIf="staffeditForm.controls.firstname.hasError('pattern')">
                  Please enter valid First Name
                </mat-error>
                <mat-error *ngIf="staffeditForm.controls.firstname.hasError('maxlength')">
                  Max length 30 characters only
                </mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>

              <mat-grid-tile [rowspan]="2" class="clsPhonegridheader">
                <label class="clstxtcontrol">Phone</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="3" [rowspan]="2" class="clsPhonegridtxt">
                <div class="staffedittext-inside" fxLayout="row" fxLayout.xs="column">
                  <div class="staffedittext-inside">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="Phone Number" formControlName="phone" [(ngModel)]="data[0].Phone" maxlength="10" tabindex="9" (input)="getstaffnumber($event.target.value)" (keyup)="handleInput($event)">
                      <mat-error *ngIf="staffeditForm.controls.phone.hasError('pattern')">
                          Please enter valid Phone Number
                      </mat-error>
                      <mat-error *ngIf="!staffeditForm.controls.phone.hasError('pattern') && (staffeditForm.controls.phone.hasError('maxlength') || staffeditForm.controls.phone.hasError('minlength'))">
                        Phone number should be 10 digits
                      </mat-error>
                      <mat-icon matSuffix>phone</mat-icon>
                    </mat-form-field>
                    
                    <div style="display: inline-flex;">                     
                      <label class="clslabelcheckin" style="margin-left: 18.5em;margin-top:1.5em;">Alerts</label>
                      <mat-checkbox id="staffphonechkAlert" [disabled]="!enablePhoneCheckbox" [checked]="editPhoneChkAlertvalue" tabindex="9" (change)="toggleEditPhoneAlertVisibility($event)" style="font-size: 14px;margin-left: .5em;margin-top: 1.5em;"></mat-checkbox>
                    </div>   
                  </div>
                    
                    <div class="staffeditbtn-inside">
                      <button type="button" class="clseditVerifybutton" mat-raised-button (click)="onVerifyPhoneNumber(staffeditForm.value)" [disabled]="checkstaffverify == 1">{{buttonvalue}}</button>  
                      <mat-icon style="color:#717070;margin-left:1.5em;" class="liveviewmaticon" matTooltip="Click if this staff member will receive alerts via text message.">error_outline</mat-icon>
                    </div>                
                  </div>                  
            </mat-grid-tile>                       

          <mat-grid-tile>
            <label>Last Name</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="Last Name" formControlName="lastname" maxlength="30" tabindex="2"
                  [(ngModel)]="data[0].LastName" (keyup)="handleInput($event)">
                <mat-error *ngIf="staffeditForm.controls.lastname.hasError('required')">
                  Please enter Last Name
                </mat-error>
                <mat-error *ngIf="staffeditForm.controls.lastname.hasError('pattern')">
                  Please enter valid Last Name
                </mat-error>
                <mat-error *ngIf="staffeditForm.controls.lastname.hasError('maxlength')">
                  Max length 30 characters only
                </mat-error>
              </mat-form-field>
            </div>
          </mat-grid-tile>            

          <mat-grid-tile>
            <label>Gender</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <div class="stafftext-inside" style="width:100%;">
              <mat-radio-group class="gender" formControlName="gender" [(ngModel)]="data[0].Gender" tabindex="3"
                (change)="radioChange($event)" required>
                <mat-radio-button style="margin:4px 4px 0px 15px;" value="Male">Male</mat-radio-button>
                <mat-radio-button style="margin:4px 0px 0px 24px;" value="Female">Female</mat-radio-button>
                  <mat-radio-button style="margin:4px 0px 0px 24px;" value="Other">Other</mat-radio-button>
                <mat-error *ngIf="staffeditForm.controls.gender.hasError('required') && staffgender == 1"
                  class="clsstaffmatgender">Please select Gender</mat-error>
              </mat-radio-group>
            </div>
          </mat-grid-tile>
          
          <mat-grid-tile [rowspan]="2" class="clsEmailgridheader">
            <label class="clstxtcontrol">Email</label>
        </mat-grid-tile>
        <mat-grid-tile colspan="3" [rowspan]="2" class="clsEmailgridtxt">
            <div class="staffedittext-inside" fxLayout="row" fxLayout.xs="column">                  
              <div class="staffedittext-inside">
                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                <input matInput placeholder="Email address" formControlName="email" [(ngModel)]="data[0].Email" maxlength="60" tabindex="10" (input)="getstaffemail($event.target.value)" (keyup)="handleInput($event)" required>                   
                <mat-error *ngIf="staffeditForm.controls.email.hasError('required')">
                  Please enter Email Id
                </mat-error>
                <mat-error *ngIf="staffeditForm.controls.email.hasError('email') || staffeditForm.controls.email.hasError('pattern')">
                  Please enter valid Email Id
                </mat-error>
                <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>
                
                <div style="display: inline-flex;">
                  <label class="clslabelcheckin" style="width: 130px;">Subscribe to<br>Check-In Report</label>
                  <mat-checkbox id="staffchkcheckin" [disabled]="!enableCheckbox" [checked]="editCheck_Invalue" tabindex="10" (change)="toggleEditCheckInVisibility($event)" style="font-size: 14px;margin-left: .5em;margin-top: 1.5em;"></mat-checkbox>                   
                  <label class="clslabelcheckin" style="margin-left: 7em;margin-top:1.5em;">Alerts</label>
                  <mat-checkbox id="staffchkAlert" [disabled]="!enableCheckbox" [checked]="editChkAlertvalue" tabindex="10" (change)="toggleEditAlertVisibility($event)" style="font-size: 14px;margin-left: .5em;margin-top: 1.5em;"></mat-checkbox>
                </div>                    
              </div>
              
              <div class="staffeditbtn-inside">
                <button type="button" class="clseditVerifybutton" mat-raised-button (click)="onVerifyEmail(staffeditForm.value)" [disabled]="checkstaffemailverify == 1">{{emailbuttonvalue}}</button>  
                <mat-icon style="color:#717070;margin-left:1.5em;" class="liveviewmaticon" matTooltip="Click if this staff member will receive alerts via email.">error_outline</mat-icon>
              </div>                        
            </div>                
        </mat-grid-tile>

              <mat-grid-tile>
                  <label>Tag Id</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="3">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                        <input matInput placeholder="Tag Id" id="stafftagid" formControlName="tagid" [(ngModel)]="EditstaffTagId" maxlength="10" tabindex="4" (keyup)="handleInput($event)">
                        <mat-error *ngIf="staffeditForm.controls.tagid.hasError('pattern')">
                          Please enter valid Tag Id
                        </mat-error>
                        <mat-error *ngIf="staffeditForm.controls.tagid.hasError('maxlength')">
                          Tag Id between 1 and 1073741823
                        </mat-error>
                        <mat-error *ngIf="staffeditForm.controls.tagid.hasError('max')">
                          Tag Id between 1 and 1073741823
 						            </mat-error>
                        <mat-error *ngIf="staffeditForm.controls.tagid.hasError('min')">
                          Tag Id between 1 and 1073741823
                        </mat-error>
                      </mat-form-field>
                    </div>
                </mat-grid-tile>                                
            
          <mat-grid-tile>
              <label>Role</label>
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <mat-label>Select Role</mat-label>
                      <mat-select formControlName="role" [(ngModel)]="data[0].DesignationId" tabindex="5">    
                      <mat-option *ngFor="let Role of staffDesignationList" [value]="Role.DesignationId" (click)="changeEditDesignationList(Role.DesignationId)">
                        {{Role.Designation}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select Role</mat-error>
                  </mat-form-field>
                </div>
        </mat-grid-tile>
        
        <mat-grid-tile>
          <label class="clstxtcontrol">Password</label>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
          <div class="text-inside" style="width:100%;">
              <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">                                     
                <input  [type]="show ? 'text' : 'password'" matInput placeholder="Password" formControlName="password"  maxlength="20" [(ngModel)]="data[0].Password" tabindex="11" (keyup)="handleInput($event)" required> 
                <mat-icon matSuffix [matTooltip]="tooltipDesc" [matTooltipPosition]="position.value"style="cursor:pointer" (click)="password()">{{passwordIcon}}</mat-icon>                                          
                <mat-error *ngIf="staffeditForm.controls.password.hasError('required')">
                  Please enter Password
                </mat-error>
                <mat-error *ngIf="staffeditForm.controls.password.hasError('minlength')">
                  Password should be minimum 4 characters
                </mat-error>
                <mat-error *ngIf="staffeditForm.controls.password.hasError('pattern')">
                  Please enter valid Password
                </mat-error>
                
              </mat-form-field>
            </div>
      </mat-grid-tile>  

         
        <mat-grid-tile>
            <label>Pager</label>
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
            <div class="text-inside" style="width:100%;">
                <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                  <input matInput placeholder="Pager" formControlName="pager" [(ngModel)]="data[0].PagerNo" maxlength="20" tabindex="6" (keyup)="handleInput($event)">                 
                  <mat-error *ngIf="staffeditForm.controls.pager.hasError('pattern')">
                    Please enter valid Pager Number
                  </mat-error>

                </mat-form-field>
              </div>
        </mat-grid-tile>
        
         <!-- Select Staff Group -->
         <mat-grid-tile class="clsstaffeditgroupname">
          <label class="clstxtcontrol">Group(s)</label>
        </mat-grid-tile>

        <mat-grid-tile colspan="3" class="clsstaffeditgrouptext">
          <div class="text-inside" style="width:100%;">
            <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
              <mat-select placeholder="Group(s)" formControlName="group" [(ngModel)]="selectedStaffGroupId"
                tabindex="12" multiple>
                <mat-option *ngFor="let Group of Groupdetails" [value]="Group.GroupId"
                  (click)="changestaffGroup(Group.GroupId)">
                  {{Group.GroupName}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>
        </mat-grid-tile>                      
                  
            <mat-grid-tile>
              <label>Photo</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="3">
                <div class="stafftext-inside" style="width:30%; padding:0px 0px 0px 15px;">
                    <img  class="clstaffimg" [src]= "staffeditimageUrl"  (click)='clkimageInput.click()'/>
                    <input type="file" placeholder="Photo" formControlName="photo"  #clkimageInput (change)="onChangeeditstaff($event.target.files)" accept=".png, .jpg, .bmp"/>                     
                    <img *ngIf="staffdefaultimage == 0" src="assets/images/deletered.png" style="margin-left:1em;cursor:pointer;margin-bottom:1.5em;" (click)="deletestaffimage()">
                </div>
                
                <div class="staffedittext-inside" style="width:50%;display: inline-flex;">
                  <label style="margin-top:0.8em;">Extension</label>
                <mat-form-field appearance="outline" floatLabel="auto" style="width:90%;margin-left:1em;">
                  <input matInput placeholder="Extension" formControlName="extension" maxlength="5" [(ngModel)]="data[0].Tap" tabindex="7" (keyup)="handleInput($event)">                 
                  <mat-error *ngIf="staffeditForm.controls.extension.hasError('pattern')">
                    Please enter valid Extension
                  </mat-error>
                  <mat-error *ngIf="staffeditForm.controls.extension.hasError('maxlength')">
                    Max length 5 digits only
                  </mat-error>
                </mat-form-field>
              </div>                
            </mat-grid-tile>  
            
            <mat-grid-tile  class="clsstaffeditgroupname">
              <label class="clstxtcontrol">Default Map View<br>(Campus or Zone)</label>
            </mat-grid-tile>         
            <mat-grid-tile colspan="3" class="clsstaffeditgrouptext">
                <div class="text-inside" style="width:100%;">                
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">                  
                  <mat-select formControlName="campus" placeholder="Select Campus or Zone" disableOptionCentering [(ngModel)]="selectedCampusId">                    
                    <mat-optgroup *ngFor="let group of floorGroups" [class.hideLabel]="group.name == ''" [label]="group.name">
                      <mat-option [value]="group.value" disabled="false">{{group.campus}}</mat-option>                  
                    <ng-container *ngFor="let floor of group.floors">
                      <mat-option  *ngIf="floor.isfloor == 0" [value]="floor.value" style="margin-left:1em;" disabled="true">{{floor.viewValue}}</mat-option>
                      <mat-option  *ngIf="floor.isfloor == 1" [value]="floor.value" style="margin-left:2em;">{{floor.viewValue}}</mat-option>
                    </ng-container>                    
                  </mat-optgroup>
                  </mat-select>                  
                  <mat-error>Please select Campus or Zone</mat-error>                   
                </mat-form-field>            
                </div>
          </mat-grid-tile>                                   

            <mat-grid-tile>
              <label>Notes</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="3" [rowspan]="rowspan">
              <div class="text-inside" style="width:100%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                    <textarea class="clstextarea" matInput placeholder="Notes" formControlName="notes" [(ngModel)]="data[0].Notes" tabindex="8" style="resize: none;"></textarea>                                        
                  </mat-form-field>
                </div>
          </mat-grid-tile>  
          
          <mat-grid-tile  class="clsstaffeditgroupname">
            <label class="clstxtcontrol">Restrict Notifications</label>
          </mat-grid-tile>         
          <mat-grid-tile colspan="3" class="clsstaffeditgrouptext">
            <div class="text-inside" style="width:100%;">
              <mat-checkbox id="staffchkNotification" [checked]="editNotificationvalue" tabindex="14"
                (change)="toggleEditNotifyVisibility($event)" style="font-size: 14px;margin-left: .5em;" [disabled]="editroleEnableNotify" >          
              </mat-checkbox>
            </div>
        </mat-grid-tile> 

        </mat-grid-list>

        <mat-dialog-actions class="clsbuttoncontrol" colspan="2">
          <button type="button" class="clsbtnstaffcancel" mat-raised-button (click)="onCloseCancel()"
            tabindex="20">Cancel</button>
          <button type="submit" class="clsbtnstaffsave" mat-raised-button tabindex="21">Save</button>&nbsp;
        </mat-dialog-actions>

      </form>
    </div>
  </div>
  <div class="spinner" *ngIf="emailLoading">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</perfect-scrollbar>