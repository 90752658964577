<perfect-scrollbar style="max-width: 1200px; max-height: 760px;" >
  <div class="clsaddCampus">
      <div class="addCampus-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addCampusHeader" style="margin: 0 0 10px 11px;">Insert Template
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:0px 24px 24px 24px;" [formGroup]="addTempateForm" autocomplete="off" novalidate  (ngSubmit)="addtemplateDetails(addTempateForm.value)">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
        
        <div fxFlex="55" fxFlex.md="25">                                   
                              
        <mat-grid-list [cols]="firstcolumns" [rowHeight]="floorrowHeight">                             
            <mat-grid-tile>
                <label>Campus</label>
            </mat-grid-tile>
            <mat-grid-tile class="clstempmatgridtxt">                      
              <div class="text-inside" style="width:100%;"> 
                <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">                              
                  <mat-select formControlName="campusname" placeholder="Select Campus" [(ngModel)]="selectedCampusId">    
                    <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId">
                      {{Campus.CampusName}}
                    </mat-option>
                  </mat-select>                                                  
                </mat-form-field>
              </div>
            </mat-grid-tile>                                

            <mat-grid-tile class="clstempmatgrid">
              <label>Building</label>
          </mat-grid-tile>                            
          <mat-grid-tile class="clstempmatgridtxt">                      
            <div class="text-inside" style="width:100%;"> 
              <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">                            
                <mat-select formControlName="buildingname" placeholder="Select Building" [(ngModel)]="selectBuildId">    
                  <mat-option *ngFor="let Building of Buildingdetails" [value]="Building.BuildId">
                    {{Building.BuildingName}}
                  </mat-option>
                </mat-select>                                                     
              </mat-form-field>
            </div>
          </mat-grid-tile> 
            
          <mat-grid-tile class="clstempmatgrid">
            <label>Zone</label>
        </mat-grid-tile>             
        <mat-grid-tile class="clstempmatgridtxt">                      
          <div class="text-inside" style="width:100%;"> 
            <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">                            
              <mat-select formControlName="zonename" placeholder="Select Zone" [(ngModel)]="selectFloorId">    
                <mat-option *ngFor="let Zone of Zonedetails" [value]="Zone.ZoneId">
                  {{Zone.ZoneName}}
                </mat-option>
              </mat-select>                                                     
            </mat-form-field>
            </div>
        </mat-grid-tile>

        <mat-grid-tile class="clstempmatgrid">
          <label>Available<br>Templates</label>
        </mat-grid-tile>
        <mat-grid-tile class="clstempmatgridtxt">
                <div class="text-inside">
                  <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                    <mat-select formControlName="templates" placeholder="Select Template">    
                      <mat-option *ngFor="let Template of Templatedetails" [value]="Template.TemplateId" (click)="selectTempCategory(Template.TemplateId)">
                        {{Template.TemplateName}}-{{Template.Description}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select Template</mat-error>
              </mat-form-field>
              </div>
        </mat-grid-tile> 
        
        <mat-grid-tile class="clstempmatgrid">
          <label>New Space<br>Name</label>
        </mat-grid-tile>
        <mat-grid-tile class="clstempmatgridtxt">
              <div class="text-inside">
                  <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                  <input matInput placeholder="Space Name" maxlength="32" formControlName="newtempname" (keyup)="handleInput($event)">
                  <mat-error *ngIf="addTempateForm.controls.newtempname.hasError('required')">Please enter New Space Name</mat-error>
                  <mat-error *ngIf="addTempateForm.controls.newtempname.hasError('pattern')">Please enter valid New Space Name</mat-error>
                  <mat-error *ngIf="addTempateForm.controls.newtempname.hasError('maxlength')">Max length 32 characters only</mat-error>
                </mat-form-field>
              </div>
        </mat-grid-tile>  
      
      <mat-grid-tile class="clstempmatgrid">
        <label>Space Category</label>
      </mat-grid-tile>
      <mat-grid-tile class="clstempmatgridtxt">
              <div class="text-inside">
                  <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                    <mat-select formControlName="category" placeholder="Select Space Category">    
                      <mat-option *ngFor="let Space of Spacecategory_details" [value]="Space.SpaceCategoryId" (click)="selectSpaceCategory(Space.SpaceCategoryId)">
                        {{Space.Description}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select Space Category</mat-error>
              </mat-form-field>
            </div>
      </mat-grid-tile> 
      
      <mat-grid-tile class="clstempmatgrid">
        <label>Description</label>
      </mat-grid-tile>
      <mat-grid-tile class="clstempmatgridtxt">
            <div class="text-inside">
                <mat-form-field appearance="outline" floatLabel="auto" class="clstempaddtxt">
                <input matInput placeholder="Description" maxlength="32" formControlName="description" (keyup)="handleInput($event)">                           
                <mat-error *ngIf="addTempateForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                <mat-error *ngIf="addTempateForm.controls.description.hasError('maxlength')">Max length 32 characters only</mat-error>
              </mat-form-field>
            </div>
      </mat-grid-tile>    
      
      <mat-grid-tile class="clstempmatgrid">
        <label>Resident Off<br>Limits</label>
      </mat-grid-tile>
      <mat-grid-tile class="clstempmatgridtxt">
              <div class="text-inside" style="width:100%;">
                <mat-slide-toggle id="chkResOffLimits" (change)="toggler_ResOffLimits($event)" [checked]="checkResOffLimits"></mat-slide-toggle>
            </div>
      </mat-grid-tile> 

            </mat-grid-list>           
        
        </div>
        
        
        <div fxFlex="55" fxFlex.md="45" style="margin-top:1em;" class="clsdivactivefloors">
          <label>Select the devices you'd like to save in this Space:</label>
            <mat-grid-list [cols]="columns" [rowHeight]="rowHeight" class="clstempmatgridlist">
                <!-- <mat-grid-tile> -->
                  <perfect-scrollbar style="max-width: 1200px; max-height: 480px;" >
                    <div class="text-inside" style="margin-top:1em;" *ngIf="selectTemplateId > 0">                                                                            
                      <mat-checkbox aria-label="Select All" 
                      class="clscheckbox clsenable" id="chkresident"
                      [checked]="isCheckedAll"  
                      (change)="$event ? toggleAll($event) : null" style="font-size: 14px;">Select All</mat-checkbox>
                    </div> 
                    <div style="margin:1em;">
                      <label style="font-size: medium;">Devices</label>
                    </div>  
                    <div class="text-inside" style="margin-top:1em;" *ngFor="let Device of DevicesInfo;">                                                                            
                      <mat-checkbox class="clscheckbox clsenable" *ngIf="Device.DeviceTypeId != EnumDeviceType.Room"
                      [checked]="exists(Device.DataId)" [id]="'chkdevice_'+Device.DataId" 
                      (click)="$event.stopPropagation()"
                      (change)="$event ? toggleUnitVisibility(Device.DataId,$event) : null" style="font-size: 14px;">
                      {{Device.NewDeviceName}} - {{Device.TempRoomCategory}} </mat-checkbox>
                      
                      <mat-checkbox class="clscheckbox clsenable" *ngIf="Device.DeviceTypeId == EnumDeviceType.Room"
                      [checked]="exists(Device.DataId)" [id]="'chkdevice_'+Device.DataId" 
                      (click)="$event.stopPropagation()"
                      (change)="$event ? toggleUnitVisibility(Device.DataId,$event) : null" style="font-size: 14px;">
                      {{Device.NewDeviceName}} - {{Device.UnitName}} </mat-checkbox>
                      
                      
                    </div>                    
              
              </perfect-scrollbar>
              </mat-grid-list>
        </div>                      
          
        
 </div>
 <mat-dialog-actions class="clsaddtempbuttoncontrol">      
  <button type="button" class="clstempcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnsavetempaddclick" class="clstempsavebutton" mat-raised-button >Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>