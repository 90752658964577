import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "../../../api.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { appsettings } from "../../../app-settings";
import { TechnologyoverlayService } from "../technologyoverlay.service";
import { getTemplateInfo } from "../technologyoverlay";
import { Router } from "@angular/router";
import { EditTemplatesComponent } from "../templates/edit-templates/edit-templates.component";
import { EditSpacestemplateComponent } from "../templates/edit-spacestemplate/edit-spacestemplate.component";
import { MapSyncProcess } from "../../../api";
import {
  mapTemplate_creation,
  get_geojsonObject_floor,
  fillcolor_style,
} from "../../../map-general";
import { enumRequestFrom, fillcolorPolygon } from "../../../app-settings";
import { Fill, Stroke, Style, Text } from "ol/style";
import {
  LoadTechnologySpaceSvg,
  map_widthandheight,
} from "../../../app-general";

@Component({
  selector: "app-templates",
  templateUrl: "./templates.component.html",
  styleUrls: ["./templates.component.scss"],
})
export class TemplatesComponent implements OnInit {
  Campus: any[];
  MapSyncProcess;
  ZoneTempCnt = 0;
  SpaceTempCnt = 0;
  arrowiconT = true;
  arrowiconF = false;
  arrowiconT_space = true;
  arrowiconF_space = false;
  preId = -1;
  collapsedIcon1 = "keyboard_arrow_down";
  collapsedIcon = "keyboard_arrow_down";
  collapsedIcon1space = "keyboard_arrow_down";
  collapsedIconspace = "keyboard_arrow_down";
  TempZoneInfo = [] as any[];
  TempSpaceInfo = [] as any[];
  TemplateInfo;
  checkicon;
  checkiconspace;
  FloorById;
  editfloortempdialogopen = 0;
  editspacetempdialogopen = 0;
  clseditimg;
  SpaceTempImgUrl: string = appsettings.TEMPLATE_IMAGE_FOLDER;
  DeviceInfo = [];
  FloorInfo;
  FloorImageURL = "";
  Map_image_path = "assets/images/";
  addfloorid;
  templateloading = false;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private technologyoverlayService: TechnologyoverlayService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.getTemplatesInfo(0);
    this.clseditimg = "clseditimgout";
  }

  over() {
    this.clseditimg = "clseditimgover";
  }

  out() {
    this.clseditimg = "clseditimgout";
  }
  getTemplatesInfo(IsCall) {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "0";
      const outdoorinfo: getTemplateInfo = {
        authKey: this.authkey,
        templateId: 0,
        templateCategory: 0,
        isNeedIntercom:parseInt(voiceLicense) 
      };
      this.technologyoverlayService
        .getTemplatesInfo(outdoorinfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((templatesinfo: any[]) => {
          this.TemplateInfo = templatesinfo["Templateinfo"];
          this.getTempZoneSpaceInfo(templatesinfo["Templateinfo"], IsCall);
        });
    } catch (err) {
      throw new Error(
        "templates.component.ts" + " - getTemplatesInfo() - " + err.message
      );
    }
  }

  getTempZoneSpaceInfo(templatesinfo, IsCall) {
    const spaceinfo = [] as any[];
    const zoneinfo = [] as any[];
    templatesinfo.forEach(function (data) {
      if (data.TemplateCategoryId == 1) {
        zoneinfo.push(data);
      } else {
        spaceinfo.push(data);
      }
    });

    this.TempZoneInfo = zoneinfo;
    this.TempSpaceInfo = spaceinfo;
    this.ZoneTempCnt = this.TempZoneInfo.length;
    this.SpaceTempCnt = this.TempSpaceInfo.length;
    if (IsCall == 1) {
      this.collapsedIcon1 = "keyboard_arrow_down";
      this.clickcollapse(0, [], 1);
    }
    if (IsCall == 2) {
      this.collapsedIcon1space = "keyboard_arrow_down";
      this.clickcollapse(0, [], 2);
    }
  }

  clickcollapse(val, data, Type) {
    try {
      this.templateloading = true;

      if (this.TempZoneInfo.length > 0) {
        const elementcontent = document.getElementById("Divmatcontent");
        if(elementcontent != null)
          elementcontent.className = "disablecontent";

        const elementcontent1 = document.getElementById("Divmatcontent");
        if(elementcontent1 != null)
          elementcontent1.style.border = "none";
      }
      if (this.TempSpaceInfo.length > 0) {
        const elementspacecontent = document.getElementById("Divmatcontentspace");
        if(elementspacecontent != null)
          elementspacecontent.className = "disablecontent";

        const elementcontent1 = document.getElementById("Divmatcontentspace");
        if(elementcontent1 != null)
          elementcontent1.style.border = "none";
      }
      let p1 = 0;
      this.checkicon = val;
      this.checkiconspace = val;

      if (Type == 1) {
        if (this.preId != -1 && this.preId != val) {
          p1++;
          if (this.collapsedIcon == "keyboard_arrow_down") {
            this.collapsedIcon1 = "keyboard_arrow_up";
            this.arrowiconT = true;
          }
        }
        if (this.checkicon == val) {
          if (this.collapsedIcon1 == "keyboard_arrow_down" && p1 == 0) {
            this.collapsedIcon1 = "keyboard_arrow_up";
            this.arrowiconT = true;

            this.collapsedIcon1space = "keyboard_arrow_down";
            this.arrowiconT_space = false;
          } else if (this.collapsedIcon1 == "keyboard_arrow_up" && p1 == 0) {
            this.collapsedIcon1 = "keyboard_arrow_down";
            this.arrowiconT = false;

            this.collapsedIcon1space = "keyboard_arrow_down";
            this.arrowiconT_space = false;
          }
          this.preId = val;
        }
      } else if (Type == 2) {
        if (this.preId != -1 && this.preId != val) {
          p1++;
          if (this.collapsedIconspace == "keyboard_arrow_down") {
            this.collapsedIcon1space = "keyboard_arrow_up";
            this.arrowiconT_space = true;
          }
        }
        if (this.checkicon == val) {
          if (this.collapsedIcon1space == "keyboard_arrow_down" && p1 == 0) {
            this.collapsedIcon1space = "keyboard_arrow_up";
            this.arrowiconT_space = true;

            this.collapsedIcon1 = "keyboard_arrow_down";
            this.arrowiconT = false;
          } else if (
            this.collapsedIcon1space == "keyboard_arrow_up" &&
            p1 == 0
          ) {
            this.collapsedIcon1space = "keyboard_arrow_down";
            this.arrowiconT_space = false;

            this.collapsedIcon1 = "keyboard_arrow_down";
            this.arrowiconT = false;
          }
          this.preId = val;
        }
      }
      if (this.TempZoneInfo.length > 0) {
        const element_content = document.getElementById("Divmatcontent");
        if(element_content != null)
          element_content.className = "enablecontent";
      }
      if (this.TempSpaceInfo.length > 0) {
        const element_content1 = document.getElementById("Divmatcontentspace");
        if(element_content1 != null)
          element_content1.className = "enablecontent";
      }
      if (this.arrowiconT == true || this.arrowiconT_space == true) {
        setTimeout(() => {
          this.MapDataCall();
        }, 1 * 500);
      } else {
        this.templateloading = false;
      }
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - clickcollapse() - " + err.message
      );
    }
  }

  MapDataCall() {
    const zoneinfo = this.TempZoneInfo;
    const spaceinfo = this.TempSpaceInfo;
    this.ZoneTempCnt = this.TempZoneInfo.length;
    this.SpaceTempCnt = this.TempSpaceInfo.length;

    const _this = eval('this');
    try {
      let zoneinfo_length = 0;
      const zoneinfo_tot_length = zoneinfo.length;
      zoneinfo.forEach(function (Templates) {
        const mapId = "mapZone_" + Templates.TemplateId;
        const element = document.getElementById(mapId);

        if (element)
          _this.DrawMapCall(
            _this,
            Templates,
            zoneinfo_length,
            zoneinfo_tot_length
          );
        zoneinfo_length++;
      });
      let spaceinfo_length = 0;
      const spaceinfo_tot_length = spaceinfo.length;
      spaceinfo.forEach(function (Templates) {
        const mapId = "mapTemplate_" + Templates.TemplateId;
        const element = document.getElementById(mapId);
        if (element)
          _this.DrawMapCall(
            _this,
            Templates,
            spaceinfo_length,
            spaceinfo_tot_length
          );
        spaceinfo_length++;
      });
    } catch (err) {
      throw new Error(
        "templates.component.ts" + " - getTempZoneSpaceInfo() - " + err.message
      );
    }
  }

  editFloorTemplatedetails(TempInfo) {
    if (!SessionCheck(this.router)) {
      return;
    }

    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.MapSyncProcess = MapSyncinfo;
        if (this.MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          return false;
        } else {
          this.editfloortempopendialog(TempInfo);
        }
      });
  }

  editfloortempopendialog(FloorTempdata) {
    if (this.editfloortempdialogopen == 0) {
      const dialogRef = this.dialog.open(EditTemplatesComponent, {
        disableClose: true,
        width: "75em",
        height: "600px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: FloorTempdata,
      });
      this.editfloortempdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        this.editfloortempdialogopen = 0;
        //if(result == "Delete")
        //{
        this.getTemplatesInfo(1);
        //}
      });
    }
  }

  editSpaceTempdetails(TempInfo) {
    if (!SessionCheck(this.router)) {
      return;
    }
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.MapSyncProcess = MapSyncinfo;
        if (this.MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          return false;
        } else {
          this.editspacetempopendialog(TempInfo);
        }
      });
  }

  editspacetempopendialog(SpaceTempdata) {
    if (this.editspacetempdialogopen == 0) {
      const dialogRef = this.dialog.open(EditSpacestemplateComponent, {
        disableClose: true,
        width: "75em",
        height: "600px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: SpaceTempdata,
      });
      this.editspacetempdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        this.editspacetempdialogopen = 0;
        //if(result == "Delete")
        //{
        this.getTemplatesInfo(2);
        //}
      });
    }
  }

  DrawMapCall(_this, Templates, _length, tot_length) {
    const imgwh = map_widthandheight(Templates.ImageWidth, Templates.ImageHeight);
    const imgwidth = imgwh.ImageWidth;
    const imgheight = imgwh.ImageHeight;
    const devicelabelStyle = new Style({
      text: new Text({
        font: "10px Poppins, sans-serif",
        overflow: true,
        fill: new Fill({
          color: "#000",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 5,
        }),
        offsetX: 0,
        offsetY: 25.5,
      }),
    });

    const deviceStyle = fillcolor_style(fillcolorPolygon.Completed);
    const fillStyle = [deviceStyle, devicelabelStyle];
    let mapId = "mapTemplate_" + Templates.TemplateId;

    if (Templates.TemplateCategoryId == 1)
      mapId = "mapZone_" + Templates.TemplateId;
    else if (Templates.TemplateCategoryId == 3)
      mapId = "mapEditZone_" + Templates.TemplateId;
    else if (Templates.TemplateCategoryId == 4)
      mapId = "mapEditTemplate_" + Templates.TemplateId;

    const map = mapTemplate_creation(
      _this.SpaceTempImgUrl + Templates.FloorPlanImage,
      imgwidth,
      imgheight,
      mapId,
      Templates.TemplateCategoryId
    );
    //////////Floor Devices Start////////////

    const arrdevicemarker = [];
    const arrdevicemarkernoPoly = [];
    const SpaceInfo = Templates.SpaceDetail;

    if (SpaceInfo != null) {
      /// Geo Feature Collection ///
      const geoFloorjsonObject = get_geojsonObject_floor(
        enumRequestFrom.floorplan_page
      );
      geoFloorjsonObject.type = "FeatureCollection";      
      geoFloorjsonObject.features.length = 0;

      /// marker array ///      
      if (SpaceInfo.length > 0) {
        const arrSpaceInfo = SpaceInfo;
        LoadTechnologySpaceSvg(
          map,
          arrSpaceInfo,
          imgheight,
          imgwidth,
          arrdevicemarker,
          geoFloorjsonObject,
          arrdevicemarkernoPoly,
          devicelabelStyle,
          fillStyle,
          _this.Map_image_path,
          Templates.TemplateId
        );
        _this.editimageheight = imgheight;
        _this.editimagewidth = imgwidth;
      } else if (_this.FloorImageURL == null) {
        const getMap_Id = document.getElementById("map");
        if(getMap_Id != null)
          getMap_Id.innerHTML =
          "<span style='margin-top: 15%; padding-left: 45%;display:block;font-size:15px;font-weight:bold;'> Map Not Found.</span>";
      }
    }
    //////////Floor Devices End////////////
    if (_length == tot_length - 1) this.templateloading = false;
  }
}
