import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Inject,
  OnDestroy,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import {
  ResidentByID,
  getResidentbyid,
  residentrelease,
  Residentreleasetype,
  ResidentMedicalHistorydetails,
} from "./resident";
import { ResidentService } from "./resident.service";
import { HomeService } from "../home/home.service";
import { appsettings } from "../../app-settings";
import { AddResidentComponent } from "./add-resident/add-resident.component";
import { EditResidentComponent } from "./edit-resident/edit-resident.component";
import { ReleaseResidentComponent } from "./release-resident/release-resident.component";
import { MedicalHistoryComponent } from "./medical-history/medical-history.component";
import { DownloadResidentComponent } from "./download-resident/download-resident.component";
import { showResidentGroupComponent } from "./show-resident-group/show-resident-group.component";
import { MainLayoutService } from "../../main-layout/main-layout.service";
import {
  Resident_Status,
  enumResidentStatus,
  enumgetResidentData,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumChronoLogical,
  enumEnableDisable,
} from "../../app-settings";
import { SidenavComponent } from "../../main-layout/sidenav/sidenav.component";
import { InfrastructureAlertsComponent } from "../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { ApiService } from "../../api.service";
import {
  SharedCheckIsAlertReportData,
  Resident,
  MapSyncProcess,
  getResidentRequest,
} from "../../api";
import { AlertSharedService } from "../../_services/index";
import { RollbarService } from "../../rollbar";
import * as Rollbar from "rollbar";
import { HttpClient } from "@angular/common/http";
import {
  SessionCheckwithStaff,
  checkStaffData,
  getFeatureNeedtoShow,
  getAddEditDeleteColumn,
  AuthFailedMessage,
  generateAlertMessage,
} from "../../app-general";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs";

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';


export interface ResidentStatus {
  Name: string;
  Value: string;
}

@Component({
  selector: "app-resident",
  templateUrl: "./resident.component.html",
  providers: [ResidentService],
  styleUrls: ["./resident.component.scss"],
})
export class ResidentComponent implements OnInit, OnDestroy {
  Residents: Resident[];
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  dialogResult = "";
  Residentbyid: getResidentbyid[];
  displayedColumns: string[] = ['PhotoID', 'Name', 'UnitName', 'TagID', 'RoomNumber','Status','isWanderMode','medicalhistory', 'edit', 'delete'];
  IsRefreshNeed = 1;
  public watcher: Observable<boolean>;
  dataSource;
  downloadResidentSource;
  dataSourcecount;
  Release: number = Residentreleasetype.Release;
  Reallocate: number = Residentreleasetype.Reallocate;
  getResidentbyID;
  bGroupView = false;
  bAddEdit = false;
  bDelete = false;
  bRoamingEdit = false;
  filteredArray: any[] = [];
  chksearchvalue;
  chkResidentStatus;
  public residentStatus = Resident_Status;
  public EnumResidentStatus = enumResidentStatus;
  ressortedData = [];
  residentstatus;
  resloading = true;
  residentList;
  statusdata = "Release";
  Residentlength;
  checkliveresidentdata;
  res_MapSyncProcess;
  interval_resident;
  authkey;
  @Input()
  residentImgUrl: string = appsettings.RESIDENT_FOLDER;
  private _onDestroy: Subject<void> = new Subject<void>();
  
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  
  constructor(
    private router: Router,
    private residentService: ResidentService,
    @Inject(RollbarService) private rollbar: Rollbar,    
    public dialog: MatDialog,
    public mainLayoutService: MainLayoutService,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    private http: HttpClient,
    private homeService: HomeService,
    private breakpointObserver: BreakpointObserver
    
  ) {
    
    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }
  
  private breakpointChanged() {
    
    if (getFeatureNeedtoShow(enumUIFeatureView.Resident,enumUIAction.Resident_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    if (getFeatureNeedtoShow(enumUIFeatureView.Roaming,enumUIAction.Roaming_View) == enumUIView.Show) 
    {
      this.bRoamingEdit = true;
    }  
    if (getFeatureNeedtoShow(enumUIFeatureView.Resident_Group,enumUIAction.Resident_Group_View) == enumUIView.Show) 
    {
      this.bGroupView = true;
    }    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      this.setupFullTable();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupFullTable();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {        
  
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Resident,
        enumUIAction.Resident_View
      ) != enumUIView.Show
    ) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Resident,
        enumUIAction.Resident_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Roaming,
        enumUIAction.Roaming_View
      ) == enumUIView.Show
    ) {
      this.bRoamingEdit = true;
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Resident_Group,
        enumUIAction.Resident_Group_View
      ) == enumUIView.Show
    ) {
      this.bGroupView = true;
    }

    if (this.IsRefreshNeed == 1) {
      let ChkIsAlertReportChanged = true;

      this.alertSharedService
        .currentSharedCheckIsAlertReport()
        .pipe(takeUntil(this._onDestroy))
        .subscribe(
          (SharedCheckIsAlertReportDetails: SharedCheckIsAlertReportData) => {
            const CheckIsAlertReportDataChanged =
              SharedCheckIsAlertReportDetails;
            if (CheckIsAlertReportDataChanged != undefined) {
              if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 1 &&
                ChkIsAlertReportChanged
              ) {
                ChkIsAlertReportChanged = false;

                setTimeout(() => {
                  this.readLiveData();
                  if (this.chkResidentStatus == undefined)
                    this.residentstatus = enumResidentStatus.Current;
                  else this.residentstatus = this.chkResidentStatus;
                  this.getResidentData();
                }, 1000 * 60 * 1);

                CheckIsAlertReportDataChanged.IsAlertReportChanged = 0;
                this.alertSharedService.changeSharedCheckIsAlertReport(
                  CheckIsAlertReportDataChanged
                );
              } else if (
                CheckIsAlertReportDataChanged.IsAlertReportChanged == 0
              ) {
                ChkIsAlertReportChanged = true;
              }
            }
          }
        );
    }

    this.readLiveData();
    this.residentstatus = enumResidentStatus.Current;
    this.getResidentData();

    this.getStaffById();
  }

  readLiveData() {
    this.viewRoomData();
  }

  viewRoomData() {
    const sReturn = getFeatureNeedtoShow(
      enumUIFeatureView.Location,
      enumUIAction.Location_RTLS_Resident
    );
    if (sReturn.toString() == enumUIView.Show.toString()) {
      this.checkliveresidentdata = "1";
    } else {
      this.checkliveresidentdata = "0";
    }

    const RoomIndex = this.displayedColumns.indexOf("RoomName");
    if (this.checkliveresidentdata != undefined) {
      if (this.checkliveresidentdata != "1") {
        if (RoomIndex >= 0) {
          this.displayedColumns.splice(RoomIndex, 1);
        }
      }
    }
  }

  getStaffById() {
    checkStaffData(this.apiService, this.alertSharedService);
  }

  setupTableforXtrasmallerwidth() {
    this.displayedColumns = ['Name', 'TagId', 'RoomName', 'Status', 'edit', 'delete'];
    this.viewRoomData();
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableforsmallerwidth() {
    this.displayedColumns = ['photo','Name', 'TagId', 'RoomName', 'Status', 'edit', 'delete'];
    this.viewRoomData();
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupFullTable() {
    this.displayedColumns = ['photo', 'Name','UnitName', 'TagId',  'RoomName', 'Status', 'isWanderMode','medicalhistory', 'edit', 'delete'];
    this.viewRoomData();
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  getResidentData(): void {
    try {
      this.resloading = true;
      const residentdata: getResidentRequest = {
        authKey: this.authkey,
        residentType: enumgetResidentData.AllData,
        isChronoLogical: enumChronoLogical.chronoLogical,
      };
      this.apiService
        .getResidentData(residentdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Residents: any[]) => {
          if (Residents["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.resloading = false;
          this.Residents = Residents["apiReturn"];
          this.Residentlength =0;
          if (this.Residents.length>0)
            this.Residentlength = Residents["apiReturn"][0].MaxId;

          this.dataSource = new MatTableDataSource(this.Residents);
          this.downloadResidentSource = new MatTableDataSource(this.Residents);
          this.downloadResidentSource.data = this.Residents;

          if (
            this.chksearchvalue == undefined &&
            this.chkResidentStatus == undefined
          ) {
            this.residentList = this.Residents.filter(function (e) {
              return e.Status == 1;
            });
            this.dataSource.data = this.residentList;
            this.downloadResidentSource.data = this.residentList;
            // this.dataSource.data = this.Residents;
          } else if (
            this.chksearchvalue != undefined &&
            this.chkResidentStatus == undefined
          ) {
            this.searchResident(this.chksearchvalue);
          } else if (
            this.chksearchvalue == undefined &&
            this.chkResidentStatus != undefined
          ) {
            this.selectresStatus(this.chkResidentStatus);
          } else if (
            this.chksearchvalue != undefined &&
            this.chkResidentStatus != undefined
          ) {
            this.searchResident(this.chksearchvalue);
            this.selectresStatus(this.chkResidentStatus);
          }
          setTimeout(() => {
            this.ressortedData = [];
            this.dataSource.sort = this.sort;
            this.sortingDatalist(this.dataSource);
            this.downloadResidentSource.sort = this.sort;
            this.sortingDatalist(this.downloadResidentSource);
            this.downloadResidentSource
              .connect()
              .subscribe((data) => (this.ressortedData = data));
          }, 0);

          this.dataSource.paginator = this.paginator;
          if (this.chksearchvalue == undefined)
            this.dataSourcecount = this.dataSource.data.length;
          else this.dataSourcecount = this.dataSource.filteredData.length;
        });
    } catch (err) {
      throw new Error(
        "resident.component.ts" + " - getResidentData() - " + err.message
      );
    }
  }

  Residentstatus: ResidentStatus[] = [
    { Name: "All", Value: "All" },
    { Name: "Current", Value: "Current" },
    { Name: "Deceased", Value: "Deceased" },
    { Name: "Released", Value: "Released" },
  ];

  searchResident(filterValue: string) {
    if (filterValue != null) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.chksearchvalue = filterValue;
      this.dataSource.filter = filterValue;

      this.selectresStatus(this.residentstatus);
      this.searchfilter();

      if (this.chkResidentStatus == "All") {
        this.dataSource.data = this.Residents;
        this.dataSourcecount = this.dataSource.filteredData.length;
      } else {
        this.dataSource.data = this.filteredArray;
        this.dataSourcecount = this.dataSource.filteredData.length;
      }
    }
  }
  searchfilter() {
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      const filtersearch = filter.toLowerCase();
      //var ResidentName= data.Name + data.TagId + data.SpouseName + data.GroupName + data.RoomName;
      //return ResidentName.toLowerCase().includes(filter);
      return (
        data.Name.toLowerCase().includes(filtersearch) ||
        data.SpouseName.toLowerCase().includes(filtersearch) ||
        data.TagId.toString().includes(filtersearch) ||
        data.RoomName.toLowerCase().includes(filtersearch) ||
        data.GroupName.toLowerCase().includes(filtersearch)
      );
    };
  }

  selectresStatus(ResidentStatus: string) {
    this.dataSource.data = this.Residents;
    this.filteredArray = [];
    let Residentstatus = "";
    if (ResidentStatus == enumResidentStatus.Current) {
      Residentstatus = "1";
      this.statusdata = "Release";
    } else if (ResidentStatus == enumResidentStatus.Deceased) {
      Residentstatus = "2";
      this.statusdata = "Restore";
    } else if (ResidentStatus == enumResidentStatus.Released) {
      Residentstatus = "3";
      this.statusdata = "Restore";
    }

    this.chkResidentStatus = ResidentStatus;

    const list = this.dataSource.data.filter((x) => x.Status == Residentstatus);
    list.forEach((x) => {
      this.filteredArray.push(x);
    });

    if (ResidentStatus == "All") {
      this.statusdata = "Release/Restore";
      this.dataSource.data = this.Residents;
      this.downloadResidentSource.data = this.dataSource.data;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.sortingDatalist(this.dataSource);
        this.downloadResidentSource.sort = this.sort;
        this.sortingDatalist(this.downloadResidentSource);
        this.downloadResidentSource
          .connect()
          .subscribe((data) => (this.ressortedData = data));
      }, 0);
      if (!this.chksearchvalue)
        this.dataSourcecount = this.dataSource.data.length;
      else this.dataSourcecount = this.dataSource.filteredData.length;
    } else {
      this.dataSource.data = this.filteredArray;
      this.downloadResidentSource.data = this.filteredArray;
      if (!this.chksearchvalue)
        this.dataSourcecount = this.dataSource.data.length;
      else this.dataSourcecount = this.dataSource.filteredData.length;

      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.sortingDatalist(this.dataSource);
        this.downloadResidentSource.sort = this.sort;
        this.sortingDatalist(this.downloadResidentSource);
        this.downloadResidentSource
          .connect()
          .subscribe((data) => (this.ressortedData = data));
      }, 0);
    }
  }

  /*********Opendialogwindow*******/
  addResidentdata() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    // let residentlistelement = document.getElementById('divresidentdetails');
    //     residentlistelement.className = 'clsdisable';
    this.residentEnableDisable(enumEnableDisable.Disable);
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.res_MapSyncProcess = MapSyncinfo;
        if (this.res_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          // let devicelistelement = document.getElementById('divresidentdetails');
          // devicelistelement.className = 'clsenable';
          this.residentEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          let resAddComponentHeight;
          const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
            window.navigator.userAgent
          );
          if (isIEOrEdge == true) {
            {
              resAddComponentHeight = "780px";
            }
          } else {
            resAddComponentHeight = "760px";
          }
          const dialogRef = this.dialog.open(AddResidentComponent, {
            disableClose: true,
            width: "1050px",
            height: resAddComponentHeight,
            panelClass: "myapp-no-padding-dialog",
            autoFocus: false,
            data: this.Residentlength,
          });

          dialogRef.afterClosed().subscribe((result) => {
            // let residentlistelement = document.getElementById('divresidentdetails');
            //     residentlistelement.className = 'clsenable';
            this.residentEnableDisable(enumEnableDisable.Enable);
            if (result == "Confirm") {
              const element = document.getElementById("btnresdata");
              if (element != null)
                element.className =
                  "clsresidentbutton mat-raised-button mat-primary ng-star-inserted";
              this.getResidentData();
            } else {
              const element = document.getElementById("btnresdata");
              if (element != null)
                element.className =
                  "clsresidentbutton mat-raised-button mat-primary ng-star-inserted";
            }
          });
        }
      });
  }

  editResidentdata(residentId: number) {
    // let residentlistelement = document.getElementById('divresidentdetails');
    //     residentlistelement.className = 'clsdisable';
    this.residentEnableDisable(enumEnableDisable.Disable);
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.res_MapSyncProcess = MapSyncinfo;
        if (this.res_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          // let devicelistelement = document.getElementById('divresidentdetails');
          //     devicelistelement.className = 'clsenable';
          this.residentEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          // let residentlistelement = document.getElementById('divresidentdetails');
          //     residentlistelement.className = 'clsdisable';
          this.residentEnableDisable(enumEnableDisable.Disable);
          if (!SessionCheckwithStaff(this.router)) {
            return;
          }

          const residentbyid: ResidentByID = {
            authKey: this.authkey,
            residentId: residentId,
          };

          this.residentService
            .getResidentDataById(residentbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ResidentbyId: any[]) => {
              this.Residentbyid = ResidentbyId["apiReturn"];
              this.editopendialog(this.Residentbyid);
            });
        }
      });
  }

  editopendialog(Residentbyid) {
    let resEditComponentHeight;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      resEditComponentHeight = "780px";
    } else {
      resEditComponentHeight = "760px";
    }
    const dialogRef = this.dialog.open(EditResidentComponent, {
      disableClose: true,
      width: "1050px",
      height: resEditComponentHeight,
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: Residentbyid,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // let residentlistelement = document.getElementById('divresidentdetails');
      //     residentlistelement.className = 'clsenable';
      this.residentEnableDisable(enumEnableDisable.Enable);
      if (result == "Confirm") {
        this.getResidentData();
      }
    });
  }

  releaseResidentdata(residentId: number, Type: number) {
    // let residentlistelement = document.getElementById('divresidentdetails');
    //     residentlistelement.className = 'clsdisable';
    this.residentEnableDisable(enumEnableDisable.Disable);
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.res_MapSyncProcess = MapSyncinfo;
        if (this.res_MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          // let devicelistelement = document.getElementById('divresidentdetails');
          //     devicelistelement.className = 'clsenable';
          this.residentEnableDisable(enumEnableDisable.Enable);
          return false;
        } else {
          const residentbyid: ResidentByID = {
            authKey: this.authkey,
            residentId: residentId,
          };

          this.residentService
            .getResidentDataById(residentbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((ResidentbyId: any[]) => {
              if (ResidentbyId["apiReturn"] == "Invalid Auth Key") {
                AuthFailedMessage(this.dialog, this.router);
                return;
              }
              this.Residentbyid = ResidentbyId["apiReturn"];
              if (Type == this.Release) {
                this.releaseopendialog(this.Residentbyid, this.Release);
              } else if (Type == this.Reallocate) {
                this.reallocateResidentdata(this.Residentbyid, this.Reallocate);
              }
            });
        }
      });
  }

  releaseopendialog(Residentbyid, Type) {
    const releaseResidentdata: residentrelease = {
      ResidentbyId: Residentbyid,
      Reltype: Type,
    };
    const dialogRef = this.dialog.open(ReleaseResidentComponent, {
      disableClose: true,
      width: "550px",
      height: "460px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: releaseResidentdata,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // let residentlistelement = document.getElementById('divresidentdetails');
      //     residentlistelement.className = 'clsenable';
      this.residentEnableDisable(enumEnableDisable.Enable);
      if (result == "Confirm") {
        this.getResidentData();
      }
    });
  }

  reallocateResidentdata(ResidentbyId, Type) {
    // let residentlistelement = document.getElementById('divresidentdetails');
    //     residentlistelement.className = 'clsdisable';
    this.residentEnableDisable(enumEnableDisable.Disable);
    const releaseResidentdata: residentrelease = {
      ResidentbyId: ResidentbyId,
      Reltype: Type,
    };
    const dialogRef = this.dialog.open(ReleaseResidentComponent, {
      disableClose: true,
      width: "500px",
      height: "300px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: releaseResidentdata,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // let residentlistelement = document.getElementById('divresidentdetails');
      //     residentlistelement.className = 'clsenable';
      this.residentEnableDisable(enumEnableDisable.Enable);
      if (result == "Confirm") {
        this.getResidentData();
      }
    });
  }

  getMedicalHistory(Residentbyid: number) {
    // let residentlistelement = document.getElementById('divresidentdetails');
    //     residentlistelement.className = 'clsdisable';
    this.residentEnableDisable(enumEnableDisable.Disable);
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }

    this.getResidentbyID = Residentbyid;
    const residentbyid: ResidentByID = {
      authKey: this.authkey,
      residentId: Residentbyid,
    };
    this.residentService
      .getMedicalHistory(residentbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((ResidentbyId: any[]) => {
        if (ResidentbyId["apiReturn"] == "Invalid Auth Key") {
          AuthFailedMessage(this.dialog, this.router);
          return;
        }
        this.Residentbyid = ResidentbyId["apiReturn"];
        this.medicalhistoryopendialog(this.Residentbyid);
      });
  }

  medicalhistoryopendialog(ResidentMedhistorybyid) {
    if (ResidentMedhistorybyid == "") {
      const residentmedhistory: ResidentMedicalHistorydetails = {
        ResidentId: this.getResidentbyID,
        AddType: 1,
      };
      ResidentMedhistorybyid = residentmedhistory;
    }
    const dialogRef = this.dialog.open(MedicalHistoryComponent, {
      disableClose: true,
      width: "980px",
      height: "640px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: ResidentMedhistorybyid,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // let residentlistelement = document.getElementById('divresidentdetails');
      //     residentlistelement.className = 'clsenable';
      this.residentEnableDisable(enumEnableDisable.Enable);
      if (result == "Confirm") {
        this.getResidentData();
      }
    });
  }

  downloadResidentdata() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    if(this.residentList != "" && this.residentList != undefined)
    {
      if (this.dataSource.filter != "") {
        this.downloadResidentSource.data = this.dataSource.filteredData;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          //this.downloadResidentSource.sort = this.sort;
          this.downloadResidentSource
            .connect()
            .subscribe((data) => (this.ressortedData = data));
        }, 0);
      }
    }

    const residentdata = this.ressortedData;
    const dialogRef = this.dialog.open(DownloadResidentComponent, {
      disableClose: true,
      width: "388px",
      height: "165px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: residentdata,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      const element1 = document.getElementById("downloadresdata");
      if (element1 != null)
        element1.className =
          "clsresidentbutton mat-raised-button mat-primary ng-star-inserted";
    });
  }

  /*********Open Resident group dialogwindow*******/
  ClickResidentGroup() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }

    const dialogRef = this.dialog.open(showResidentGroupComponent, {
      disableClose: true,
      width: "700px",
      height: "400px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Cancel") {
        const element2 = document.getElementById("btnresgroup");
        if (element2 != null)
          element2.className =
            "clsaddgroup mat-raised-button mat-primary ng-star-inserted";
      } else {
        const element2 = document.getElementById("btnresgroup");
        if (element2 != null)
          element2.className =
            "clsaddgroup mat-raised-button mat-primary ng-star-inserted";
        this.getResidentData();
      }
    });
  }
  sortingDatalist(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (sortHeaderId != "TagId") {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }
  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
  }

  residentEnableDisable(checkflag) {
    const elementcontent = document.getElementById("divresidentdetails");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsenable";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsdisable";
  }

  ngOnDestroy() {
    clearInterval(this.interval_resident);
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
