import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import { takeUntil } from "rxjs/operators";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
  getFeatureNeedtoShow,
  LoadTechnologySpaceSvg,
  map_widthandheight,
} from "../../../../app-general";
import {
  mapTemplate_creation,
  get_geojsonObject_floor,
  fillcolor_style,
} from "../../../../map-general";
import {
  appsettings,
  DeviceTypeDesc,
  DeviceType,
  NewDeviceTypeDesc,
  enumRequestFrom,
  fillcolorPolygon,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumEnableDisable,
} from "../../../../app-settings";
import { Router } from "@angular/router";
import { GeneralMessageComponent } from "../../../../general-message/general-message.component";
import {
  TechnologyForCreationResponse,
  getTemplateInfo,
  loadTemplateFloor,
  loadTemplateDevice,
  deleteTempSpace,
  deleteTemplate,
  deletTemplateDevice,
  updateTemplate,
} from "../../technologyoverlay";
import { EditFloordeviceComponent } from "../edit-floordevice/edit-floordevice.component";
import { ConfirmMessageComponent } from "../../../../confirm-message/confirm-message.component";
import { ApiService } from "../../../../api.service";
import { AlertSharedService } from "../../../../_services/index";
import { EditFloorspaceComponent } from "../edit-floorspace/edit-floorspace.component";
import { AlertMessageComponent } from "../../../../alert-message/alert-message.component";
import { Fill, Stroke, Style, Text } from "ol/style";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-templates",
  templateUrl: "./edit-templates.component.html",
  styleUrls: ["./edit-templates.component.scss"],
})
export class EditTemplatesComponent implements OnInit {
  bAddEdit = false;
  bDelete = false;
  public flooreditTemplateForm: UntypedFormGroup;
  selectedTempId;
  FloorInfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  SpaceInfo = [] as any[];
  public watcher: Observable<boolean>;
  columns = 3;
  firstcolumns = 2;
  minheight = "350px";
  rowHeight = "79px";
  checkfloorinfo = false;
  select_SpaceIds = [];
  Campusdetails;
  Buildingdetails;
  selectedCampusId;
  selectedBuildingId;
  selectTemplateName;
  DeviceInfo = [] as any[];
  floorImgUrl;
  TempFloordata = [];
  deletetempdevicedialogopen = 0;
  ZoneTempCount;
  floorImageName = "";
  Zoneimageurl = appsettings.TEMPLATE_IMAGE_FOLDER;
  authkey;
  selectTemplateId;
  Map_image_path = "assets/images/";
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      "(min-width: 500px)",
    ])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditTemplatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver  
  ) {
    this.selectedTempId = data.TemplateId;
    this.TempFloordata = data;
    this.selectTemplateName = data.TemplateName + "-" + data.Description;
    this.selectTemplateId = "mapEditZone_" + data.TemplateId;
    this.ZoneTempCount = data.FloorCnt;
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Templates_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    setTimeout(() => {
      this.DrawMapCall(this, data);
    }, 1 * 500);
    this.breakpoint$.subscribe(() => this.breakpointChanged());      
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.minheight = "350px";
    }    
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "350px";
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
      this.firstcolumns = 2;
      this.minheight = "auto";
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
      this.firstcolumns = 1;
      this.minheight = "auto";
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.flooreditTemplateForm = new UntypedFormGroup({
      tempname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(65),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      tempID: new UntypedFormControl(""),
    });
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Templates_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }

    this.loadTemplateForFloor();
    this.loadTemplatesForDevice();
  }

  loadTemplateForFloor() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const buildbyid: loadTemplateFloor = {
        authKey: this.authkey,
        templateId: parseInt(this.selectedTempId),
        isNeedIntercom: parseInt(voiceLicense)
      };
      this.technologyoverlayService
        .loadTemplateForFloor(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((spaceinfo: any[]) => {
          if (spaceinfo.length == 1 && spaceinfo[0].TemplateId == null) {
            this.SpaceInfo = [];
          } else {
            this.SpaceInfo = spaceinfo;
          }
          this.floorImgUrl = this.Zoneimageurl + spaceinfo[0].TempZoneImage;
          this.floorImageName = spaceinfo[0].TempZoneImage;
        });
    } catch (err) {
      throw new Error(
        "edit-templates.component.ts" +
          " - loadTemplateForFloor() - " +
          err.message
      );
    }
  }

  loadTemplatesForDevice() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const buildbyid: loadTemplateDevice = {
        authKey: this.authkey,
        templateId: parseInt(this.selectedTempId),
        templateCategory: 1,
        isNeedIntercom: parseInt(voiceLicense)
      };
      this.technologyoverlayService
        .loadTemplatesForDevice(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((deviceinfo: any[]) => {
          deviceinfo.forEach(function (e) {
            if (e.DeviceName == DeviceTypeDesc.Emitter) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
              e["DeviceType"] = DeviceType.Emitter;
            } else if (e.DeviceName == DeviceTypeDesc.HRex) {
              e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
              e["DeviceType"] = DeviceType.HRex;
            } else if (e.DeviceName == DeviceTypeDesc.Rex) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
              e["DeviceType"] = DeviceType.Rex;
            } 
            else if (e.DeviceName == DeviceTypeDesc.LF) {
              if (e.SubType==DeviceType.DripTray)
              {
                e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
              }
              else
              {
                e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
              }
              e['DeviceType'] = DeviceType.LF;
            } 
            else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
              e["DeviceType"] = DeviceType.Pullcord;
            } else if (
              e.DeviceName == DeviceTypeDesc.DC ||
              e.DeviceName == DeviceTypeDesc.NewDC
            ) {
              e["NewDeviceName"] = NewDeviceTypeDesc.DC;
              e["DeviceType"] = DeviceType.DC;
            } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
              e["DeviceType"] = DeviceType.Monitor;
            } else if (e.DeviceName == DeviceTypeDesc.VW) {
              e["NewDeviceName"] = NewDeviceTypeDesc.VW;
              e["DeviceType"] = DeviceType.VW;
            } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
              e["DeviceType"] = DeviceType.Gateway;
            } else if (e.DeviceName == DeviceTypeDesc.UT) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UT;
              e["DeviceType"] = DeviceType.UT;
            } else if (e.DeviceName == DeviceTypeDesc.UM) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UM;
              e["DeviceType"] = DeviceType.UM;
          }
          else if (e.DeviceName == DeviceTypeDesc.Intercom ) { 
            e['NewDeviceName'] =  NewDeviceTypeDesc.Intercom;
            e['DeviceType'] = DeviceType.IC;
          }
          else {
              e["NewDeviceName"] = NewDeviceTypeDesc.Zone;
              e["DeviceType"] = DeviceType.Zone;
            }
          });
          if (deviceinfo.length == 1 && deviceinfo[0].TemplateId == null) {
            this.DeviceInfo = [];
          } else {
            this.DeviceInfo = deviceinfo;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-templates.component.ts" +
          " - loadTemplatesForDevice() - " +
          err.message
      );
    }
  }

  getTemplatesInfo() {
    try {
      const voiceLicense = localStorage.getItem("voice_license") ?? "0";
      const buildbyid: getTemplateInfo = {
        authKey: this.authkey,
        templateId: parseInt(this.selectedTempId),
        templateCategory: 1,
        isNeedIntercom:parseInt(voiceLicense)
      };
      this.technologyoverlayService
        .getTemplatesInfo(buildbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((templatesinfo: any[]) => {
          const Templates = templatesinfo["Templateinfo"][0];
          this.DrawMapCall(this, Templates);
        });
    } catch (err) {
      throw new Error(
        "edit-templates.component.ts" + " - getTemplatesInfo() - " + err.message
      );
    }
  }

  deleteSpacedetails(Spacedata) {
    let Device_Count = "";
    let message = "";
    if (Spacedata.DeviceCount >= 0) {
      message = "It contains ";
      Device_Count = Spacedata.DeviceCount + " Devices.";
    }

    const GeneralMessageData = {
      General_header: "Delete Space ",
      GeneralMessage:
        "Are you sure you would like to delete this Space?<br> " +
        message +
        " <b>" +
        Device_Count +
        " </b>",
      General_first: "Yes ",
      General_second: "No",
    };
    const dialogRef = this.dialog.open(GeneralMessageComponent, {
      disableClose: true,
      width: "auto",
      height: "220px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: GeneralMessageData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "First") {
        this.deleteTemplateSpace(Spacedata);
      } else {
        dialogRef.close("Cancel");
        return;
      }
    });
  }

  deleteTemplateSpace(deletespacedata) {
    try {
      const deletespace: deleteTempSpace = {
        authKey: this.authkey,
        templateId: parseInt(deletespacedata.TemplateId),
        spaceId: parseInt(deletespacedata.TempUnitId),
      };
      this.technologyoverlayService
        .deleteTemplateSpace(deletespace)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(this.dialog, "Space deleted successfully.");
            this.loadTemplateForFloor();
            this.loadTemplatesForDevice();
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Space deleted failed.");
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-templates.component.ts" +
          " - deleteTemplateSpace() - " +
          err.message
      );
    }
  }

  clkdltFloorconfirmdialog(floordata) {
    const GeneralMessageData = {
      General_header: "Delete Template ",
      GeneralMessage: "Would you like to delete this template?</b>",
      General_first: "Yes ",
      General_second: "No",
    };
    const dialogRef = this.dialog.open(GeneralMessageComponent, {
      disableClose: true,
      width: "auto",
      height: "220px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: GeneralMessageData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "First") {
        this.deleteTemplate(floordata);
      } else {
        dialogRef.close("Cancel");
        return;
      }
    });
  }

  deleteTemplate(floorData) {
    try {
      const deletetempfloorbyid: deleteTemplate = {
        authKey: this.authkey,
        templateId: parseInt(floorData.TemplateId),
        templateCategoryId: parseInt(floorData.TemplateCategoryId),
      };
      this.technologyoverlayService
        .deleteTemplate(deletetempfloorbyid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(
              this.dialog,
              "Zone Template deleted successfully."
            );
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Zone Template  deleted failed.");
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.thisDialogRef.close("Delete");
        });
    } catch (err) {
      throw new Error(
        "edit-templates.component.ts" + " - deleteTemplate() - " + err.message
      );
    }
  }

  editDevicedetails(devicedata) {
    let width = "37em";
    let height = "580px";

    if (devicedata.DeviceName == "UT") {
      width = "87rem";
      height = "740px";
    }

    const dialogRef = this.dialog.open(EditFloordeviceComponent, {
      disableClose: true,
      width: width,
      height: height,
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: devicedata,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "flooreditclose") this.loadTemplatesForDevice();
      this.getTemplatesInfo();
    });
  }

  delete_Devicedetails(Devicedata) {
    try {      
      const ConfirmMessageData = {
        ConfirmMessage:
          "Are you sure you want to delete " + Devicedata.NewDeviceName + "?",
      };

      if (this.deletetempdevicedialogopen == 0) {
        const dialogRef = this.dialog.open(ConfirmMessageComponent, {
          disableClose: true,
          width: "auto",
          height: "200px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: ConfirmMessageData,
        });
        this.deletetempdevicedialogopen = 1;
        dialogRef.afterClosed().subscribe((result) => {
          this.deletetempdevicedialogopen = 0;
          if (result == "Yes") {
            this.deleteTemplateDeviceId(Devicedata);
          } else {
            dialogRef.close("Cancel");
            return;
          }
        });
      }
    } catch (err) {
      throw new Error(
        "edit-templates.component.ts" + " - confirmation() - " + err.message
      );
    }
  }

  deleteTemplateDeviceId(Data) {
    try {
      const deviceDetails: deletTemplateDevice = {
        authKey: this.authkey,
        templateId: parseInt(Data.TemplateId),
        dataId: parseInt(Data.DataId),
        deviceType:parseInt(Data.DeviceType)
      };
      this.technologyoverlayService
        .deleteTemplateDeviceId(deviceDetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          const AlertMessage = "Device details deleted successfully.";
          const AlertMessageData = {
            AlertMessage: AlertMessage,
          };
          const _height = "auto";
          if (apiresponse.toString() == "true") {
            const alertdialog = this.dialog.open(AlertMessageComponent, {
              disableClose: true,
              width: "auto",
              height: _height,
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: AlertMessageData,
            });

            alertdialog.afterClosed().subscribe((result) => {
              if (result == "Ok") {
                this.loadTemplateForFloor();
                this.loadTemplatesForDevice();
              }
            });
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Device details deleted failed.");
          }
        });
    } catch (err) {
      throw new Error(
        "edit-templates.component.ts" +
          " - deleteTemplateDeviceId() - " +
          err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  editfloorSpacedetails(floorspacedata) {
    const floorspace_data = JSON.stringify(floorspacedata);
    const dialogRef = this.dialog.open(EditFloorspaceComponent, {
      disableClose: true,
      width: "32em",
      height: "550px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: JSON.parse(floorspace_data),
    });

    dialogRef.afterClosed().subscribe((result) => {
      const floorplanslistelement = document.getElementById("divfloorplandetails");
      if(floorplanslistelement != null)
        floorplanslistelement.className = "clsenable";
      if (result != "Cancel") {
        this.loadTemplateForFloor();
        this.loadTemplatesForDevice();
      }
    });
  }

  public updateTemplateInfo = (zonetempFormValue) => {
    try {
      let TempDescriName;
      let TempName = " ";
      let TempDescription = " ";
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }      
      this.editTemplateEnableDisable(enumEnableDisable.Disable);
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.flooreditTemplateForm.valid) {
        if (zonetempFormValue.tempname.indexOf("-") == -1) {
          if (zonetempFormValue.tempname.length > 32) {
            generateAlertMessage(
              this.dialog,
              "Zone Template Name Max length 32 characters only"
            );            
            this.editTemplateEnableDisable(enumEnableDisable.Enable);
            return false;
          } else {
            TempName = zonetempFormValue.tempname.replace(/\s+/g, " ").trim();
            TempDescription = " ";
          }
        } else {
          TempDescriName = zonetempFormValue.tempname
            .replace(/\s+/g, " ")
            .trim();
          TempDescriName = TempDescriName.split("-");
          TempName = TempDescriName[0];
          TempDescription = TempDescriName[1];
        }

        const editzonetempdetails: updateTemplate = {
          authKey: this.authkey,
          templateId: parseInt(zonetempFormValue.tempID),
          templateName: TempName,
          description: TempDescription,
          templateCategoryId: 1,
        };

        this.technologyoverlayService
          .updateTemplateInfo(editzonetempdetails)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse.toString() == "true") {
              generateAlertMessage(
                this.dialog,
                "Zone Template edited successfully."
              );
              this.thisDialogRef.close("Delete");
            } else if (apiresponse.toString() == "false") {
              generateAlertMessage(this.dialog, "Zone Template edited failed.");
              this.thisDialogRef.close();
            } else if (apiresponse["condition"] == "Authfalse") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            } else if (apiresponse["condition"] == "tempName") {
              generateAlertMessage(
                this.dialog,
                "Zone Template name '" + TempName + "' already exists."
              );              
              this.editTemplateEnableDisable(enumEnableDisable.Enable);
              return;
            }
          });
      } else {        
        this.editTemplateEnableDisable(enumEnableDisable.Enable);
      }
    } catch (err) {
      throw new Error(
        "edit-templates.component.ts" +
          " - updateTemplateInfo() - " +
          err.message
      );
    }
  };

  DrawMapCall(_this, Templates) {
    const imgwh = map_widthandheight(Templates.ImageWidth, Templates.ImageHeight);
    const imgwidth = imgwh.ImageWidth;
    const imgheight = imgwh.ImageHeight;
    const devicelabelStyle = new Style({
      text: new Text({
        font: "10px Poppins, sans-serif",
        overflow: true,
        fill: new Fill({
          color: "#000",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 5,
        }),
        offsetX: 0,
        offsetY: 25.5,
      }),
    });

    const deviceStyle = fillcolor_style(fillcolorPolygon.Completed);

    const fillStyle = [deviceStyle, devicelabelStyle];

    const map = mapTemplate_creation(
      _this.Zoneimageurl + Templates.FloorPlanImage,
      imgwidth,
      imgheight,
      "mapEditZone_" + Templates.TemplateId,
      3
    );

    //////////Floor Devices Start////////////

    const arrdevicemarker = [];
    const arrdevicemarkernoPoly = [];
    const SpaceInfo = Templates.SpaceDetail;

    if (SpaceInfo != null) {
      /// Geo Feature Collection ///
      const geoFloorjsonObject = get_geojsonObject_floor(
        enumRequestFrom.floorplan_page
      );
      geoFloorjsonObject.type = "FeatureCollection";      
      geoFloorjsonObject.features.length = 0;
      /// marker array ///      
      if (SpaceInfo.length > 0) {
        const arrSpaceInfo = SpaceInfo;
        LoadTechnologySpaceSvg(
          map,
          arrSpaceInfo,
          imgheight,
          imgwidth,
          arrdevicemarker,
          geoFloorjsonObject,
          arrdevicemarkernoPoly,
          devicelabelStyle,
          fillStyle,
          _this.Map_image_path,
          Templates.TemplateId
        );
        _this.editimageheight = imgheight;
        _this.editimagewidth = imgwidth;
      } else if (_this.FloorImageURL == null) {
        const _map = document.getElementById("map");
        if (_map != null)
          _map.innerHTML =
            "<span style='margin-top: 15%; padding-left: 45%;display:block;font-size:15px;font-weight:bold;'> Map Not Found.</span>";
      }
    }

    //////////Floor Devices End////////////
  }

  editTemplateEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnzonetempsaveclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clseditTempsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clseditTempsavebutton mat-raised-button disablebtn";
  }
}
