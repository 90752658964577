
<perfect-scrollbar style="max-width: 1200px; max-height: 670px;" >
    <div class="clsnotification">
        <div class="notificationedit-title">
          <h3 class="notificationEditHeader" style="margin: 0 0 10px 11px;">Edit Notification
          <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseeditNotification()"></span></h3>
          <mat-divider ></mat-divider> 
        </div>
  
        <div style="margin-top:15px;">
          
          <span *ngIf="selectedBuildingName != '' && selectedBuildingName != null && selectedFloorName != '' && selectedFloorName != null && data.AlarmSubTypeId ==''" class="clseditdialogtitle">{{data.CampusName}} - {{selectedBuildingName}} - {{selectedFloorName}} - {{data.UDEventName}}</span>
          <span *ngIf="selectedBuildingName != '' && selectedBuildingName != null && selectedFloorName != '' && selectedFloorName != null && data.AlarmSubTypeId !=''" class="clseditdialogtitle">{{data.CampusName}} - {{selectedBuildingName}} - {{selectedFloorName}} - {{data.AlarmSubTypeName}}</span>
          
          <span *ngIf="selectedBuildingName != '' && (selectedFloorName == '' || selectedFloorName == null) && data.AlarmSubTypeId ==''" class="clseditdialogtitle">{{data.CampusName}} - {{selectedBuildingName}} - {{data.UDEventName}}</span>
          <span *ngIf="selectedBuildingName != '' && (selectedFloorName == '' || selectedFloorName == null) && data.AlarmSubTypeId !=''" class="clseditdialogtitle">{{data.CampusName}} - {{selectedBuildingName}} - {{data.AlarmSubTypeName}}</span>
          
          <span *ngIf="selectedBuildingName == '' && (selectedFloorName == '' || selectedFloorName == null) && data.AlarmSubTypeId ==''" class="clseditdialogtitle">{{data.CampusName}} - {{data.UDEventName}}</span>
          <span *ngIf="selectedBuildingName == '' && (selectedFloorName == '' || selectedFloorName == null) && data.AlarmSubTypeId !=''" class="clseditdialogtitle">{{data.CampusName}} - {{data.AlarmSubTypeName}}</span>
          
        </div>
  
        <div class="clseditcontent">
          <form  style="padding:24px;" [formGroup]="notificationeditCreationForm" autocomplete="off" novalidate (ngSubmit)="createEditNotification(notificationeditCreationForm.value)">
  
              <mat-grid-list [cols]="columns" rowHeight="80px">
  
                <!--show Escalation-->
                  <mat-grid-tile>
                    <div style="cursor: pointer;" [matTooltip]="NotificationLevelTooltip" matTooltipClass="tooltip" #tooltip="matTooltip" matTooltipPosition="above"
                  (click)="tooltip.show()"><img class="clsImgWarning" src="assets/images/Alerts-ErrorAlert Icon-Popup.png" *ngIf="showNotifyWarningImage == true"></div>
                      <label [ngStyle]="{'margin-left': showNotifyWarningImage == true ? '0em' : '2.8em'}" class="clslabelNotifyLevel">Notification Level</label>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="3" class="clsNotificationlevel">
                      <div class="text-inside" style="width:95%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">
                            <input matInput formControlName="escalation" [(ngModel)]="data.Level" fillColor="accent" readonly/>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile> 
                                             
                  <mat-grid-tile>
                    <label class="clstxtcontrol">Repeat Level</label>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="3">
                    <div class="text-inside clsdivnotification">                                       
                      <mat-slide-toggle [checked]="checkRepeatLevel" (change)="toggleRepeatLevel($event)" style="padding-left:2.5em;"></mat-slide-toggle>                
                    </div>
                  </mat-grid-tile>  
                  
                  <!--show Notify-->
              <mat-grid-tile>
                <label class="clsNotifylabel">Notify</label>
               </mat-grid-tile>
                <mat-grid-tile colspan="3" class="clsNotificationlevel">
                    <div class="text-inside" style="width:95%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">                             
                            <mat-select placeholder="Select who to Notify" formControlName="notify" [(ngModel)]="data.NotifyType" (ngModelChange)="onChangeNotify($event)">    
                                NotifyType
                                <mat-option *ngFor="let NType of NotifyType" [value]="NType.Notifytype">
                                    {{NType.Notifytype}}
                                </mat-option>
                            </mat-select>
                          <mat-error>Please Select Notification Type</mat-error>
                        </mat-form-field>
                    </div>
                </mat-grid-tile>
                
                <mat-grid-tile>                  
                  <label class="clstxtcontrol">Repeat Number <br> (# of times)</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsrepeatlevel" colspan="3">                  
                  <div class="text-inside" style="width:95%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">
                      <input matInput formControlName="RepeatLevelNumber" maxlength="2" [(ngModel)]="RepeatNumbervalue" (keyup)="handleInput($event)" required>
                      <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelNumber.hasError('required')">
                        Please Enter Repeat Number
                      </mat-error>
                      <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelNumber.hasError('max')">
                        Please Enter Repeat Number  between 1 and 30
                      </mat-error>
                      <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelNumber.hasError('min')">
                        Please Enter Repeat Number  between 1 and 30
                      </mat-error>   
                      <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelNumber.hasError('pattern')">
                        Please Enter valid Repeat Number
                      </mat-error>
                    </mat-form-field>
                  </div>
                </mat-grid-tile>   
                
                <div [ngStyle]="{'display': notifytype === '' ? 'none' : 'block'}">
                  <!--show Role-->
                     <mat-grid-tile>
                         <label class="clstxtcontrol">{{notifytype}}</label>
                     </mat-grid-tile>
                         <mat-grid-tile colspan="3" class="clsNotificationlevel">
                             <div  *ngIf="notifytype=='Role (Global)'" class="text-inside" style="width:95%;">
                                 <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">                                     
                                     <mat-select #editroleindivi placeholder="Select {{notifytype}}" formControlName="NotifyList" [(ngModel)]="selectedRoleId" multiple>    
                                             <mat-option *ngFor="let NRoleIndivi of RoleIndividualList" [value]="NRoleIndivi.RoleId" [disabled]="NRoleIndivi.RoleFlag == 0">
                                                     {{NRoleIndivi.RoleName}}
                                             </mat-option>   
                                     </mat-select>     
                                 <mat-error>Please Select {{notifytype}}</mat-error>                
                                 </mat-form-field>
                             </div>
                             <div  *ngIf="notifytype=='Individual'" class="text-inside" style="width:95%;">
                                     <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">                                         
                                         <mat-select #editroleindivi placeholder="Select {{notifytype}}" formControlName="NotifyList" [(ngModel)]="selectedRoleId" multiple>    
                                                 <mat-option *ngFor="let NRoleIndivi of RoleIndividualList" [value]="NRoleIndivi.StaffID" title="{{ NRoleIndivi.Name.length >= 40 ? NRoleIndivi.Name : ''}}">
                                                         {{NRoleIndivi.FirstName}} {{NRoleIndivi.LastName}}
                                                 </mat-option>   
                                         </mat-select>     
                                     <mat-error>Please Select {{notifytype}}</mat-error>                
                                     </mat-form-field>
                             </div>
                             <div  *ngIf="notifytype=='Staff Group'" class="text-inside" style="width:95%;">
                               <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">                                      
                                   <mat-select #roleindivi placeholder="Select {{notifytype}}" formControlName="NotifyList" [(ngModel)]="selectedRoleId" multiple>    
                                           <mat-option *ngFor="let NRoleIndivi of RoleIndividualList" [value]="NRoleIndivi.GroupId" title="{{ NRoleIndivi.GroupName.length >= 20 ? NRoleIndivi.Name : ''}}">
                                                   {{NRoleIndivi.GroupName}}
                                           </mat-option>   
                                   </mat-select>     
                               <mat-error>Please Select {{notifytype}}</mat-error>                
                               </mat-form-field>
                             </div>
                             <div  *ngIf="notifytype=='Resident Group'" class="text-inside" style="width:95%;">
                               <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">                                      
                                   <mat-select #roleindivi placeholder="Select {{notifytype}}" formControlName="NotifyList" [(ngModel)]="selectedRoleId" multiple>    
                                           <mat-option *ngFor="let NRoleIndivi of RoleIndividualList" [value]="NRoleIndivi.GroupId" title="{{ NRoleIndivi.GroupName.length >= 20 ? NRoleIndivi.Name : ''}}">
                                                   {{NRoleIndivi.GroupName}}
                                           </mat-option>   
                                   </mat-select>     
                               <mat-error>Please Select {{notifytype}}</mat-error>                
                               </mat-form-field>
                             </div>
                         </mat-grid-tile>
                  </div> 
                  
                  <div *ngIf="data.AlarmSubTypeName != 'Low Batteries' && data.AlarmSubTypeName != 'Lost Communication'">
                    <mat-grid-tile>                  
                      <label class="clstxtcontrol">Repeat Cycle <br> (minutes)</label>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="3" class="clsrepeatlevel">                  
                      <div class="text-inside" style="width:95%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">
                          <input matInput formControlName="RepeatLevelCycle" maxlength="2" [(ngModel)]="RepeatCyclevalue" (keyup)="handleInput($event)" required (mouseleave)="mouseleaveRepeatCycle()">
                          <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelCycle.hasError('required')">
                            Please Enter Repeat Cycle
                          </mat-error>
                          <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelCycle.hasError('max')">
                            Please Enter Repeat Cycle  between 1 and 60
                          </mat-error>
                          <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelCycle.hasError('min')">
                            Please Enter Repeat Cycle  between 1 and 60
                          </mat-error>   
                          <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelCycle.hasError('pattern')">
                            Please Enter valid Repeat Cycle
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </mat-grid-tile>
                  </div>
                    
                  <div *ngIf="data.AlarmSubTypeName == 'Low Batteries' || data.AlarmSubTypeName == 'Lost Communication'">
                      <mat-grid-tile>                  
                        <label class="clstxtcontrol">Repeat Cycle <br> (hours)</label>
                      </mat-grid-tile>
                      <mat-grid-tile colspan="3" class="clsrepeatlevel">                  
                        <div class="text-inside" style="width:95%;">
                          <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">
                            <input matInput formControlName="RepeatLevelCycle" maxlength="2" [(ngModel)]="RepeatCyclevalue" (keyup)="handleInput($event)" required (mouseleave)="mouseleaveRepeatCycle()">
                            <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelCycle.hasError('required')">
                              Please Enter Repeat Cycle 
                            </mat-error>
                            <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelCycle.hasError('max')">
                              Please Enter Repeat Cycle between 1 and 72
                            </mat-error>
                            <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelCycle.hasError('min')">
                              Please Enter Repeat Cycle between 1 and 72
                            </mat-error>   
                            <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.RepeatLevelCycle.hasError('pattern')">
                              Please Enter valid Repeat Cycle
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </mat-grid-tile>
                    </div> 
              
                  <mat-grid-tile>
                    <label></label>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="3">                      
                    </mat-grid-tile>
                    
                  <mat-grid-tile>
                    <label class="clstxtcontrol">Escalate</label>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="3">
                    <div class="text-inside clsdivnotification">                                       
                      <mat-slide-toggle [checked]="checkEscalate" (change)="toggleEscalate($event)"  style="padding-left:2.5em;"></mat-slide-toggle>                
                    </div>
                  </mat-grid-tile> 
                  
                  <mat-grid-tile>
                    <label></label>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="3">                      
                  </mat-grid-tile>                                 
              
              <div *ngIf="data.AlarmSubTypeName != 'Low Batteries' && data.AlarmSubTypeName != 'Lost Communication'">
              <mat-grid-tile>
                <mat-icon class="material-icons_notify"  matTooltipClass="tooltip" matTooltip="{{EscalateInfoTooltip}}" #tooltip="matTooltip" 
                matTooltipPosition="below">info</mat-icon>
                <label style="margin-left: 0.4em;">Time to Escalate (minutes)</label>                
              </mat-grid-tile>
              <mat-grid-tile colspan="3" class="clsrepeatlevel">
                <div class="text-inside" style="width:95%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">
                    <input matInput formControlName="TimeToEscalate" maxlength="2" [(ngModel)]="TimeEscalatevalue" (keyup)="handleInput($event)" required>
                    <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.TimeToEscalate.hasError('required')">
                      Please Enter Time To Escalate
                    </mat-error>
                    <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.TimeToEscalate.hasError('max')">
                      Please Enter Time To Escalation between 1 and 60
                    </mat-error>
                    <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.TimeToEscalate.hasError('min')">
                      Please Enter Time To Escalation between 1 and 60
                    </mat-error>   
                    <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.TimeToEscalate.hasError('pattern')">
                      Please Enter valid Time To Escalation
                    </mat-error>
                  </mat-form-field>
                </div>                
              </mat-grid-tile>
            </div>
            
            <div *ngIf="data.AlarmSubTypeName == 'Low Batteries' || data.AlarmSubTypeName == 'Lost Communication'">
              <mat-grid-tile>
                <mat-icon class="material-icons_notify"  matTooltipClass="tooltip" matTooltip="{{EscalateInfoTooltip}}" #tooltip="matTooltip" 
                matTooltipPosition="below">info</mat-icon>
                <label style="margin-left: 0.4em;">Time to Escalate (hours)</label>                
              </mat-grid-tile>
              <mat-grid-tile colspan="3" class="clsrepeatlevel">
                <div class="text-inside" style="width:95%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:92%;">
                    <input matInput formControlName="TimeToEscalate" maxlength="2" [(ngModel)]="TimeEscalatevalue" (keyup)="handleInput($event)" required>
                    <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.TimeToEscalate.hasError('required')">
                      Please Enter Time To Escalate
                    </mat-error>
                    <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.TimeToEscalate.hasError('max')">
                      Please Enter Time To Escalation between 1 and 72
                    </mat-error>
                    <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.TimeToEscalate.hasError('min')">
                      Please Enter Time To Escalation between 1 and 72
                    </mat-error>   
                    <mat-error style="font-size: 11px" *ngIf="notificationeditCreationForm.controls.TimeToEscalate.hasError('pattern')">
                      Please Enter valid Time To Escalation
                    </mat-error>
                  </mat-form-field>
                </div>                
              </mat-grid-tile>
            </div>                                                           
                  
              </mat-grid-list>
  
              <!-- show edit/cancel button -->
              <mat-dialog-actions class="clsnotifybuttoncontrol">                    
                <button type="button" class="clsbtnNotifyCancel" mat-raised-button (click)="onCloseeditNotification()">Cancel</button>
                <button class="clsbtnNotifySave" mat-raised-button>Update</button>&nbsp;
              </mat-dialog-actions>
  
          </form>
        </div>
     
    </div>
    
    </perfect-scrollbar>
    