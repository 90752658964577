import OlMap from "ol/Map";
import ImageLayer from "ol/layer/Image.js";
import Static from "ol/source/ImageStatic";
import OlView from "ol/View";
import OlPoint from "ol/geom/Point";
import MultiPoint from "ol/geom/MultiPoint";
import Projection from "ol/proj/Projection";
import {
  Circle as CircleStyle,
  Icon,
  Style,
  Fill,
  Stroke,
  Text,
} from "ol/style";
import { getCenter, getWidth } from "ol/extent.js";
import {
  enumRequestFrom,
  enumAlertSubType,
  enumAlertStatus,
  enumAlertType,
  enumAlertAckStatus,
  DeviceTypeDesc,
  NewDeviceTypeDesc,
  DeviceType,
  fillcolorPolygon,
} from "./app-settings";
import { Vector as VectorSource } from "ol/source";
import OlFeature from "ol/Feature";
import OlVectorLayer from "ol/layer/Vector";
import TileWMS from "ol/source/TileWMS";
import TileLayer from "ol/layer/Tile";
import { defaults as defaultControls, Attribution } from "ol/control.js";
import OlVectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import Popup from "ol-popup";

function get_geojsonObject_campus(requestfrom) {
  let geojsonObject;
  try {
    if (requestfrom == enumRequestFrom.home_page) {
      geojsonObject = {
        type: "",
        id: "",
        features: [
          {
            type: "",
            properties: {
              buildingid: "",
              buildingname: "",
              AlertBuildingcnt: "",
              floorid: "",
              floorname: "",
            },
            geometry: { type: "", coordinates: [] },
          },
        ],
      };
    } else {
      geojsonObject = {
        type: "",
        id: "",
        features: [
          {
            type: "",
            properties: {
              buildingid: "",
              buildingname: "",
              floorid: "",
              floorname: "",
            },
            geometry: { type: "", coordinates: [] },
          },
        ],
      };
    }
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - notification_line1() - " + err.message
    );
  }
  return geojsonObject;
}

function get_geojsonObject_floor(requestfrom) {
  let geojsonObject;
  try {
    if (requestfrom == enumRequestFrom.home_page) {
      geojsonObject = {
        type: "",
        features: [
          {
            type: "",
            properties: { DeviceName: "", DeviceId: "", Alertcnt: "" },
            geometry: { type: "", coordinates: [] },
          },
        ],
      };
    } else {
      geojsonObject = {
        type: "",
        features: [
          {
            type: "",
            properties: { DeviceName: "", DeviceId: "RoomName" },
            geometry: { type: "", coordinates: [] },
          },
        ],
      };
    }
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - get_geojsonObject_floor() - " + err.message
    );
  }
  return geojsonObject;
}

function get_VectorSource(geoJsonOrarray, bisJSON) {
  let vectorSource;
  try {
    if (bisJSON == 1) {
      vectorSource = new OlVectorSource({
        features: new GeoJSON().readFeatures(geoJsonOrarray),
      });
    } else {
      vectorSource = new OlVectorSource({
        features: geoJsonOrarray,
      });
    }
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - get_VectorSource() - " + err.message
    );
  }
  return vectorSource;
}

function alerticonstyle(resolution, Map_image_path) {
  let alerticonStyle;
  try {
    let scalable = 1 * (1 / resolution);
    if (resolution < 0.87890625) {
      scalable = 1;
    } else if (resolution == 0.87890625) {
      scalable = 0.87890625;
    }
    alerticonStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ {
          anchor: [25, 25],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          src: Map_image_path + "Alerts-Map-Pulse-Escalation-1.png",
          size: [100, 100],
          scale: 0.6 * scalable,
        }
      ),
    });
  } catch (err) {
    throw new Error("map-general.ts" + " - alerticonstyle() - " + err.message);
  }
  return alerticonStyle;
}

function alerticonred(resolution, Map_image_path, IMGName) {
  let iconStyle;
  try {
    let scalable = 1 * (1 / resolution);
    if (resolution < 0.87890625) {
      scalable = 1;
    } else if (resolution == 0.87890625) {
      scalable = 0.87890625;
    }
    iconStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ {
          anchor: [0, 0],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          src: Map_image_path + IMGName,
          size: [100, 100],
          scale: 0.5 * scalable,
        }
      ),
    });
  } catch (err) {
    throw new Error("map-general.ts" + " - alerticonred() - " + err.message);
  }
  return iconStyle;
}

function alerticonstaff(Map_image_path, Count, ChkType, resolution) {
  const _image = Map_image_path;
  // let _resolutionvsal = 0;
  // _resolutionvsal = Math.pow(resolution, 1);

  let scalable = 1 * (1 / resolution);
  if (resolution < 0.87890625) {
    scalable = 1;
  } else if (resolution == 0.87890625) {
    scalable = 0.87890625;
  }
  let text = "";
  text += Count;
  let iconStyle;
  const _offsetY = -30;
  let _offsetX = 1;
  let _anchorOrigin = "bottom-right";  
  if (ChkType == 1) {
    _offsetX = 30;
    _anchorOrigin = "bottom-left";
  }

  try {
    if (Count > 1) {
      iconStyle = new Style({
        image: new Icon(
          /** @type {module:ol/style/Icon~Options} */ {
            scale: scalable,
            anchor: [0, 0],
            anchorOrigin: _anchorOrigin,
            anchorXUnits: "pixels",
            anchorYUnits: "pixels",
            src: _image,
          }
        ),
        text: new Text({
          text: text,
          offsetY: _offsetY * scalable,
          offsetX: _offsetX * scalable,
          font: "bold 14px Poppins, sans-serif",
          scale: 1 * scalable,
          overflow: true,
          fill: new Fill({
            color: "#000",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 10,
          }),
        }),
      });
    } else {
      iconStyle = new Style({
        image: new Icon(
          /** @type {module:ol/style/Icon~Options} */ {
            scale: scalable,
            anchor: [0, 0],
            anchorOrigin: _anchorOrigin,
            anchorXUnits: "pixels",
            anchorYUnits: "pixels",
            src: _image,
          }
        ),
      });
    }
  } catch (err) {
    throw new Error("map-general.ts" + " - alerticonstaff() - " + err.message);
  }
  return iconStyle;
}

function Getalerticon(alertimgdata) {
  try {
    let Fire = 0;
    let Red = 0;
    let Infra = 0;
    let iconStyleCall = "";
    if (alertimgdata.length > 0) {
      if (alertimgdata.length == 1) {
        iconStyleCall = alertimgdata[0].iconStyleImg;
        if (
          alertimgdata[0].SubTypeName == "Tag Not Reporting" ||
          alertimgdata[0].SubTypeName == "Low Battery" || alertimgdata[0].SubTypeName == "AC Power Failure" ||
          alertimgdata[0].SubTypeName == "Nurse Call Alarm"
        )
          iconStyleCall = alertimgdata[0].subtypeImg;
      } else {
        const getAlertType = alertimgdata.filter(function (e1) {
          return (
            e1.AckStatus == enumAlertAckStatus.Notified &&
            e1.SubTypeId != 0 &&
            e1.SubTypeId != enumAlertSubType.Fire_Alarm
          );
        });
        for (let icon = 0; icon < alertimgdata.length; icon++) {
          if (alertimgdata[icon].SubTypeId == enumAlertSubType.Fire_Alarm) {
            iconStyleCall = alertimgdata[icon].iconStyleImg;
            Fire = 1;
          } else if (alertimgdata[icon].SubTypeId != 0 && Fire != 1) {
            if (getAlertType.length == 0) {
              iconStyleCall = alertimgdata[icon].iconStyleImg;
              Infra = 1;
            } else {
              iconStyleCall = getAlertType[0].iconStyleImg;
              if (
                getAlertType[0].SubTypeName == "Tag Not Reporting" ||
                getAlertType[0].SubTypeName == "Low Battery" || getAlertType[0].SubTypeName == "AC Power Failure" ||
                getAlertType[0].SubTypeName == "Nurse Call Alarm"
              )
                iconStyleCall = getAlertType[0].subtypeImg;
              Infra = 1;
            }
          } else if (
            (alertimgdata[icon].StatusDec == enumAlertStatus.Notified ||
              alertimgdata[icon].StatusDec == enumAlertStatus.Notify) &&
            alertimgdata[icon].AlertType != enumAlertType.Pull_Cord &&
            alertimgdata[icon].AlertType != enumAlertType.DC &&
            alertimgdata[icon].AlertType != enumAlertType.UT &&
            Fire != 1 &&
            Infra != 1
          ) {
            iconStyleCall = alertimgdata[icon].iconStyleImg;
            Red = 1;
          } else if (Fire == 0 && Red == 0 && Infra == 0) {
            iconStyleCall = alertimgdata[icon].iconStyleImg;
          }
        }
      }
      return iconStyleCall;
    }
  } catch (err) {
    throw new Error("map-general.ts" + " - Getalerticon() - " + err.message);
  }
}

///////////END///////////////////
function searchalerticonstyle(resolution, Map_image_path) {
  let searchalerticonStyledesc;
  try {
    const scalable = 1 * (1 / resolution);
    searchalerticonStyledesc = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ {
          anchor: [11.5, 35],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          src: Map_image_path + "Alerts-Map-Location-Icon with Pulse.png",
          size: [100, 100],
          scale: 0.6 * scalable,
        }
      ),
    });
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - searchalerticonstyle() - " + err.message
    );
  }
  return searchalerticonStyledesc;
}

function label_Polygon(IsNeed) {
  let labelStyle;
  let font = "18px Helvetica";
  if (IsNeed == 0) {
    font = "0px Helvetica";
  }
  try {
    labelStyle = new Style({
      geometry: function (feature) {
        let geometry = feature.getGeometry();
        if (geometry.getType() == "MultiPolygon") {
          const polygons = geometry.getPolygons();
          let widest = 0;
          for (let i = 0; i < polygons.length; ++i) {
            const polygon = polygons[i];
            const width = getWidth(polygon.getExtent());
            if (width > widest) {
              widest = width;
              geometry = polygon;
            }
          }
        }
        return geometry;
      },
      text: new Text({
        font: font,
        overflow: true,
        fill: new Fill({
          color: "#000",
        }),
        stroke: new Stroke({
          color: "",
          width: 0,
        }),
      }),
    });
  } catch (err) {
    throw new Error("map-general.ts" + " - label_Polygon() - " + err.message);
  }
  return labelStyle;
}

function fillcolor_style_Home(fill_color) {
  let fillcolorStyle; 
  let stroke_color = 'rgba(33, 162, 220,1)';
  let color = 'rgba(33, 162, 220,0.35)';
  if(fill_color == fillcolorPolygon.Completed)
  {
    stroke_color = 'rgba(95, 172, 69,1)';
    color = 'rgba(95, 172, 69,0.35)';
    
  }
  else if(fill_color == fillcolorPolygon.Duplicate)
  {
    stroke_color = 'rgba(251, 230, 69,1)';
    color = 'rgba(251, 230, 69,0.35)';
  }
  try {
    fillcolorStyle = new Style({
      stroke: new Stroke({
        color: stroke_color,
        lineDash: [6, 6],
        width: 4
      }),
      fillOpacity: 0.5
    });
  }
  catch (err) {
    throw new Error('map-general.ts' + ' - fillcolor_style_Home() - ' + err.message);
  }
  return fillcolorStyle;
}

function fillcolor_style_Home_Space(subzonecolor) {
  let fillcolorStyle; 
  let colorcode="204,227,168";
  const colordata:string[]=[];
  colordata[0]=("204,227,168");
  colordata[1]=("177,222,243");
  colordata[2]=("204,255,255");
  colordata[3]=("255,255,128");
  colordata[4]=("150,150,150");
  colordata[5]=("0,128,128");
  colordata[6]=("204,255,204");
  colordata[7]=("255,153,0");
  colordata[8]=("255,204,153");
  colordata[9]=("153,153,0");
  colordata[10]=("150,150,150");

  if (subzonecolor<=10)
      colorcode=colordata[subzonecolor];

  const stroke_color = 'rgba(' + colorcode +',1)';
  const color = 'rgba(' + colorcode +',0.35)';
  try {
    fillcolorStyle = new Style({
      stroke: new Stroke({
        color: stroke_color,
        width: 2
      }),
      fill: new Fill({
        color: color,
      }),
      fillOpacity: 0.5
    });
  }
  catch (err) {
    throw new Error('map-general.ts' + ' - fillcolor_style_Home_Space() - ' + err.message);
  }
  return fillcolorStyle;
}

function fillcolor_style(fill_color) {
  let fillcolorStyle;
  let stroke_color = "rgba(33, 162, 220,1)";
  let color = "rgba(33, 162, 220,0.35)";
  if (fill_color == fillcolorPolygon.Completed) {
    stroke_color = "rgba(95, 172, 69,1)";
    color = "rgba(95, 172, 69,0.35)";
  } else if (fill_color == fillcolorPolygon.Duplicate) {
    stroke_color = "rgba(251, 230, 69,1)";
    color = "rgba(251, 230, 69,0.35)";
  }
  try {
    fillcolorStyle = new Style({
      stroke: new Stroke({
        color: stroke_color,
        width: 4,
      }),
      fill: new Fill({
        color: color,
      }),
    });
  } catch (err) {
    throw new Error("map-general.ts" + " - fillcolor_style() - " + err.message);
  }
  return fillcolorStyle;
}

function fillcolor_styleSelect() {
  let fillcolorStyle;
  try {
    fillcolorStyle = [
      new Style({
        stroke: new Stroke({
          color: "rgba(122, 199, 234,1)",
          width: 4,
        }),
        fill: new Fill({
          color: "rgba(122, 199, 234,0.35)",
        }),
      }),
      new Style({
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(122, 199, 234,1)",
            width: 4,
          }),
          fill: new Fill({
            color: "#ffffff",
          }),
        }),
        geometry: function (feature) {
          const coordinates = feature.getGeometry().getCoordinates()[0];
          return new MultiPoint(coordinates);
        },
      }),
    ];
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - fillcolor_styleSelect() - " + err.message
    );
  }
  return fillcolorStyle;
}

function map_marker_iconstylewithtext(
  marker,
  imgfullpath,
  imgscale,
  deviceid,
  IsTemplate
): Style {
  try {
    console.log("map_marker_iconstylewithtext Inside " + deviceid);
    if (IsTemplate == 1) {
      const fillcolorStyle = new Style({
        image: new Icon(
          /** @type {module:ol/style/Icon~Options} */ {
            anchor: [15, 15],
            anchorXUnits: "pixels",
            anchorYUnits: "pixels",
            src: imgfullpath,
            size: [100, 100],
            scale: imgscale * 0.15,
          }
        ),
        text: new Text({
          text: "" + deviceid + "",
          font: "10px Poppins, sans-serif",
          scale: 0.15,
          overflow: true,
          fill: new Fill({
            color: "#000",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 5,
          }),
          offsetX: 0,
          offsetY: 25.5,
        }),
      });
      marker.setStyle(fillcolorStyle);
    } else {
      marker.setStyle(function (feature, resolution) {
        console.log("map_marker_iconstylewithtext else " + +deviceid);        
        let scalable = 1 * (1 / resolution);
        if (resolution < 0.87890625) {
          scalable = 1;
        } else if (resolution == 0.87890625) {
          scalable = 0.87890625;
        }

        const fillcolorStyle = new Style({
          image: new Icon(
            /** @type {module:ol/style/Icon~Options} */ {
              anchor: [15, 15],
              anchorXUnits: "pixels",
              anchorYUnits: "pixels",
              src: imgfullpath,
              size: [100, 100],
              scale: imgscale * scalable,
            }
          ),
          text: new Text({
            text: "" + deviceid + "",
            font: "10px Poppins, sans-serif",
            scale: scalable,
            overflow: true,
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 5,
            }),
            offsetX: 0,
            offsetY: 25.5,
          }),
        });

        return fillcolorStyle;
      });
    }
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - map_marker_iconstylewithtext() - " + err.message
    );
  }
}

function map_marker_iconstyle(marker, imgfullpath, imgscale): Style {
  try {
    marker.setStyle(function (feature, resolution) {      
      let scalable = 1 * (1 / resolution);
      if (resolution < 0.87890625) {
        scalable = 1;
      } else if (resolution == 0.87890625) {
        scalable = 0.87890625;
      }

      const fillcolorStyle = new Style({
        image: new Icon(
          /** @type {module:ol/style/Icon~Options} */ {
            anchor: [15, 15],
            anchorXUnits: "pixels",
            anchorYUnits: "pixels",
            src: imgfullpath,
            scale: imgscale * scalable,
          }
        ),
      });
      return fillcolorStyle;
    });
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - map_marker_iconstyle() - " + err.message
    );
  }
}

function map_creation(
  ImageUrl,
  imgwidth,
  imgheight,
  fromFloorPlan,
  BackgroundIMG,
  CZoomLevel,
  CRotateLevel,
  elementWidth,
  elementHeight,
  TranslateX,
  TranslateY,
  mapId,
  Check_MapCenter,
  IsZoomChanged
): OlMap {
  let map;
  let projection;
  try {
    //Extent
    const extent = [0, 0, imgwidth, imgheight];
    if (IsZoomChanged == 0) {
      if (CZoomLevel == null) CZoomLevel = 1;
      if (CRotateLevel == null) CRotateLevel = 0;

      const heightratio = elementHeight / imgheight;
      // let widthratio = elementWidth / imgwidth;
      // let heightwidthratio =
      //   (elementHeight * elementWidth) / (imgheight * imgwidth);
      CZoomLevel += heightratio;

      if (imgheight < 300 || imgwidth < 300) {
        CZoomLevel = 1;
      }
    }
    //Projection
    if (fromFloorPlan == true) {
      projection = new Projection({
        code: "xkcd-image",
        units: "pixels",
        extent: extent,
      });
    } else {
      projection = new Projection({
        code: "EPSG:4326",
        units: "pixels",
        extent: extent,
      });
    }

    let attributionImg = "";

    if (BackgroundIMG != undefined && BackgroundIMG != "") {
      if (BackgroundIMG == "Centrak") {
        attributionImg =
          "<img style='max-height: 12em; width: 12em; margin-top: -6em;' src='assets/images/CenTrak Logo - Map.png' alt=''>";
      } else {
        attributionImg =
          "<img onclick=document.getElementById('btn_gotoCampus').click() style='max-height: 12em; width: 12em; margin-top: -10em;' src='" +
          BackgroundIMG +
          "' alt=''>";
      }
    }

    const extentattributions = [420000, 30000, 900000, 350000];

    const attribution = new Attribution({
      collapsible: false,
    });

    if (CRotateLevel == -180) CRotateLevel = -47.13;
    else if (CRotateLevel == -90) CRotateLevel = -76.97;
    else if (CRotateLevel == -270) CRotateLevel = -262.33;
    else if (CRotateLevel == 180) CRotateLevel = -47.13;
    else if (CRotateLevel == 90) CRotateLevel = -262.33;
    else if (CRotateLevel == 270) CRotateLevel = -76.97;

    let _View = [];

    if (IsZoomChanged == 0) {
      _View = new OlView({
        projection: projection,
        center: getCenter(extent),
        zoom: CZoomLevel,
        maxZoom: 8,
        rotation: CRotateLevel,
      });
    } else {
      _View = new OlView({
        projection: projection,
        zoom: CZoomLevel,
        center: Check_MapCenter,
        maxZoom: 8,
        rotation: CRotateLevel,
      });
    }

    map = new OlMap({
      layers: [
        new ImageLayer({
          source: new Static({
            attributions: "",
            url: ImageUrl,
            projection: projection,
            imageExtent: extent,
            size: [imgwidth, imgheight],
          }),
        }),
        new TileLayer({
          extent: extentattributions,
          source: new TileWMS({
            attributions: attributionImg,
            serverType: "geoserver",
            crossOrigin: "anonymous",
            params: { LAYERS: "ne:ne", TILED: true },
          }),
        }),
      ],
      controls: defaultControls({ attribution: false }).extend([attribution]),
      target: mapId,
      view: _View,
    });
  } catch (err) {
    throw new Error("map-general.ts" + " - map_creation() - " + err.message);
  }
  return map;
}

function mapTemplate_creation(
  ImageUrl,
  imgwidth,
  imgheight,
  targetId,
  CategoryId
): OlMap {
  let map;
  // let elementWidth;
  let elementHeight;
  
  try {
    const mapId = targetId;
    const element = document.getElementById(mapId);
    if(element != null)
    {
      element.innerHTML = "";
      // elementWidth = element.offsetWidth;
      elementHeight = element.offsetHeight;
    }    

    let CZoomLevel = 14.7;
    if (CategoryId == 3 || CategoryId == 4) {
      CZoomLevel = 15.2;
    }
    const CRotateLevel = 0;
    //Extent
    const extent = [0, 0, imgwidth, imgheight];
    const heightratio = elementHeight / imgheight;
    // let widthratio = elementWidth / imgwidth;
    // let heightwidthratio =
    //   (elementHeight * elementWidth) / (imgheight * imgwidth);
    CZoomLevel += heightratio;

    const projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: extent,
    });

    const attributionImg = "";
    const extentattributions = [420000, 30000, 900000, 350000];
    const attribution = new Attribution({
      collapsible: false,
    });

    map = new OlMap({
      layers: [
        new ImageLayer({
          source: new Static({
            attributions: "",
            url: ImageUrl,
            projection: projection,
            imageExtent: extent,
            size: [imgwidth, imgheight],
            wrapX: false,
          }),
        }),
        new TileLayer({
          extent: extentattributions,
          source: new TileWMS({
            attributions: attributionImg,
            serverType: "geoserver",
            crossOrigin: "anonymous",
            params: { LAYERS: "ne:ne", TILED: true },
            wrapX: false,
          }),
        }),
      ],
      controls: defaultControls({
        attribution: false,
        zoom: false,
      }).extend([attribution]),
      interactions: defaultControls({
        doubleClickZoom: false,
        dragAndDrop: false,
        dragPan: false,
        keyboardPan: false,
        keyboardZoom: false,
        mouseWheelZoom: false,
        pointer: false,
        select: false,
        zoom: false,
      }),
      target: mapId,
      view: new OlView({
        center: getCenter(extent),
        zoom: CZoomLevel,
        maxZoom: CZoomLevel,
        minZoom: CZoomLevel,
        rotation: CRotateLevel,
      }),
    });
    let currZoom = map.getView().getZoom();
    map.on("moveend", function () {
      const newZoom = map.getView().getZoom();
      if (currZoom != newZoom) {
        currZoom = newZoom;
      }
    });
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - mapTemplate_creation() - " + err.message
    );
  }
  return map;
}

function mapBuildTemplate_creation(
  ImageUrl,
  imgwidth,
  imgheight,
  fromFloorPlan,
  BackgroundIMG,
  CZoomLevel,
  CRotateLevel,
  elementWidth,
  elementHeight,
  TranslateX,
  TranslateY,
  mapId
): OlMap {
  let map;
  try {
    if (CZoomLevel == null) CZoomLevel = 1;
    if (CRotateLevel == null) CRotateLevel = 0;

    const element = document.getElementById(mapId);
    if(element != null)
    {
      element.innerHTML = "";      
      elementWidth = element.offsetWidth;
      elementHeight = element.offsetHeight;
    }
    CZoomLevel = 15.2;
    CRotateLevel = 0;
    //Extent
    const extent = [0, 0, imgwidth, imgheight];
    const heightratio = elementHeight / imgheight;
    // let widthratio = elementWidth / imgwidth;
    // let heightwidthratio =
    //   (elementHeight * elementWidth) / (imgheight * imgwidth);
    CZoomLevel += heightratio;

    //Projection
    const projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: extent,
    });

    const attributionImg = "";
    const extentattributions = [420000, 30000, 900000, 350000];
    const attribution = new Attribution({
      collapsible: false,
    });

    if (CRotateLevel == -180) CRotateLevel = -47.13;
    else if (CRotateLevel == -90) CRotateLevel = -76.97;
    else if (CRotateLevel == -270) CRotateLevel = -262.33;
    else if (CRotateLevel == 180) CRotateLevel = -47.13;
    else if (CRotateLevel == 90) CRotateLevel = -262.33;
    else if (CRotateLevel == 270) CRotateLevel = -76.97;

    map = new OlMap({
      layers: [
        new ImageLayer({
          source: new Static({
            attributions: "",
            url: ImageUrl,
            projection: projection,
            imageExtent: extent,
            size: [imgwidth, imgheight],
            wrapX: false,
          }),
        }),
        new TileLayer({
          extent: extentattributions,
          source: new TileWMS({
            attributions: attributionImg,
            serverType: "geoserver",
            crossOrigin: "anonymous",
            params: { LAYERS: "ne:ne", TILED: true },
            wrapX: false,
          }),
        }),
      ],
      controls: defaultControls({
        attribution: false,
        zoom: false,
      }).extend([attribution]),
      interactions: defaultControls({
        doubleClickZoom: false,
        dragAndDrop: false,
        dragPan: false,
        keyboardPan: false,
        keyboardZoom: false,
        mouseWheelZoom: false,
        pointer: false,
        select: false,
        zoom: false,
      }),
      target: mapId,
      view: new OlView({
        center: getCenter(extent),
        zoom: CZoomLevel,
        maxZoom: CZoomLevel,
        minZoom: CZoomLevel,
        rotation: CRotateLevel,
      }),
    });
    let currZoom = map.getView().getZoom();
    map.on("moveend", function () {
      const newZoom = map.getView().getZoom();
      if (currZoom != newZoom) {
        currZoom = newZoom;
      }
    });
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - mapBuildTemplate_creation() - " + err.message
    );
  }
  return map;
}

function AddMarkers(imageWidth, imageHeight, image_path): OlVectorLayer {
  let vectorLayer;
  try {
    const imgWidth = imageWidth;
    const imgHeight = imageHeight;
    const imgpath = image_path;

    const vectorSource = new VectorSource({
      //create empty vector
    });

    const markers = [] as any[];
    let Xvalue = 90;
    let Yvalue = 0;
    let x = 0;
    let y = 0;

    Xvalue = Math.round(imgWidth) / 20.5;
    Yvalue = Math.round(imgHeight) / 29.5;
    Xvalue = Xvalue + 1;
    Yvalue = Yvalue + 1;

    for (let xpos = 0; xpos < Xvalue; xpos++) {
      for (let ypos = 0; ypos < Yvalue; ypos++) {
        x = Number(xpos * 20.5);
        y = Number(ypos * 29.5);
        const iconFeature = new OlFeature({
          geometry: new OlPoint([x, y]),
          name: "Marker " + ypos,
        });
        markers[ypos] = [x, y];
        vectorSource.addFeature(iconFeature);
      }
    }
    //create a bunch of icons and add to source vector
    //create the style
    const iconStyle = new Style({
      image: new Icon(
        /** @type {olx.style.IconOptions} */ {
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          opacity: 0.75,
          src: imgpath + "SnapGrid.png",
          scale: 0.2,
        }
      ),
    });

    //add the feature vector to the layer vector, and apply a style to whole layer
    vectorLayer = new OlVectorLayer({
      source: vectorSource,
      style: iconStyle,
    });
    vectorLayer.set("name", "snapGrid");
  } catch (err) {
    throw new Error("map-general.ts" + " - AddMarkers() - " + err.message);
  }
  return vectorLayer;
}

function load_floorpopup_content(
  arrFloor,
  arrFloorId,
  arrSubZone, 
  arrSubZoneId,
  map_length,
  image_path,
  floorbaseddatainfo
) {
  let content = "";
  try {
    for (let flo = 0; flo <= arrFloor.length - 1; flo++) {
      const arrMapDetails = arrFloorId[flo].split("_");
      let alertimage =
        "<span style='width:10px; height:10px;'>&nbsp;&nbsp;&nbsp;&nbsp;</span>";
      if (arrMapDetails.length == map_length - 1) {
        if (
          arrMapDetails[2] in floorbaseddatainfo &&
          floorbaseddatainfo[arrMapDetails[2]].length > 0
        ) {
          alertimage =
            "<img src='" +
            image_path +
            "Alerts-RedDot-SideBar.png' style='width:10px; height:10px;'></img> ";
        } else {
          alertimage =
            "<span style='width:10px; height:10px;'>&nbsp;&nbsp;&nbsp;&nbsp;</span>";
        }
      }
      content +=
        '<h4 style="white-space: nowrap; margin:0px; cursor:pointer;" data-action=' +
        arrFloorId[flo] +
        " > " +
        alertimage +
        arrFloor[flo] +
        " </h4>";
        
      for(let sub = 0; sub <= arrSubZone.length - 1; sub++)
      {
        const arrSubMapDetails = arrSubZoneId[sub].split('_');
        if(arrMapDetails[2] == arrSubMapDetails[2])
          content += '<h4 style="white-space: nowrap; margin:0px; cursor:pointer;margin-left:1em;" data-action=' + arrSubZoneId[sub] + ' > ' + alertimage + arrSubZone[sub] + ' </h4>';
      }
        
    }
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - load_floorpopup_content() - " + err.message
    );
  }
  return content;
}

function load_alertpopup_style(FloorName, content, bneedheight, popupwidth) {
  const el = document.createElement("div");
  el.style.backgroundColor = "transparent";
  el.style.cursor = "auto";
  let content_p;
  try {
    content_p = document.createElement("p");
    content_p.setAttribute("id", "div_popup");

    if (FloorName != undefined  && FloorName !="") content_p.style.padding = "10px";

    content_p.style.borderRadius = "10px";
    if (bneedheight == 1) {
      content_p.style.maxHeight = "400px"; // no height
      content_p.style.minWidth = "285px";
    } else if (bneedheight == 2) {
      content_p.style.minWidth = "150px";
      content_p.style.maxHeight = "310px"; // no height
    } else if (bneedheight == 3) {
      content_p.style.minWidth = "250px";
      content_p.style.maxHeight = "100px";
      content_p.style.minHeight = "100px"; // no height
    } else {
      content_p.style.minWidth = "282px";
      content_p.style.width = popupwidth;
    }
    content_p.style.overflowY = "auto";
    content_p.style.overflowX = "hidden";

    content_p.innerHTML = content;
    el.appendChild(content_p);
  } catch (err) {
    throw new Error(
      "map-general.ts" + " - load_alertpopup_style() - " + err.message
    );
  }
  return content_p;
}

function popup_overlay(popup, map, coord, el, bneedredner) {
  try {
    popup = new Popup({ insertFirst: false });
    popup.autoPan = true;
    popup.autoPanAnimation = { duration: 250 };
    map.addOverlay(popup);
    map.renderFrame_();
    popup.show(coord, el);
    if (bneedredner == 1) {
      map.render();
    }
  } catch (err) {
    throw new Error("map-general.ts" + " - popup_overlay() - " + err.message);
  }
  return popup;
}

function linestokestyle() {
  const styles = [
    // linestring
    new Style({
      stroke: new Stroke({
        color: "rgba(0,0,0,0.8)",
        lineDash: [10, 10],
        width: 3,
      }),
    }),
  ];
  return styles;
}

function getDeviceTypeNew(Devicetype) {
  let DeviceNewType = "";
  if (Devicetype == DeviceTypeDesc.Emitter)
    DeviceNewType = NewDeviceTypeDesc.Emitter;
  else if (Devicetype == DeviceTypeDesc.HRex)
    DeviceNewType = NewDeviceTypeDesc.HRex;
  else if (Devicetype == DeviceTypeDesc.Rex)
    DeviceNewType = NewDeviceTypeDesc.Rex;
  else if (Devicetype == DeviceTypeDesc.LF)
    DeviceNewType = NewDeviceTypeDesc.LF;
  else if (Devicetype == DeviceTypeDesc.Pullcord)
    DeviceNewType = NewDeviceTypeDesc.Pullcord;
  else if (Devicetype == DeviceTypeDesc.DC || Devicetype == DeviceTypeDesc.NewDC)
    DeviceNewType = NewDeviceTypeDesc.DC;
  else if (Devicetype == DeviceTypeDesc.Monitor)
    DeviceNewType = NewDeviceTypeDesc.Monitor;
  else if (Devicetype == DeviceTypeDesc.VW)
    DeviceNewType = NewDeviceTypeDesc.VW;
  else if (Devicetype == DeviceTypeDesc.Gateway)
    DeviceNewType = NewDeviceTypeDesc.Gateway;
  else if (Devicetype == DeviceTypeDesc.UT)
    DeviceNewType = NewDeviceTypeDesc.UT;
  else if (Devicetype == DeviceTypeDesc.UM)
    DeviceNewType = NewDeviceTypeDesc.UM;
  else if (Devicetype == DeviceTypeDesc.Intercom)
    DeviceNewType = NewDeviceTypeDesc.Intercom;
  else if (Devicetype == NewDeviceTypeDesc.DripTray)
    DeviceNewType = NewDeviceTypeDesc.DripTray;    
  return DeviceNewType;
}

function getDeviceTypeId(DevicetypeDesc) {
  let DeviceTypeId = 0;
  if (DevicetypeDesc == DeviceTypeDesc.Emitter)
    DeviceTypeId = DeviceType.Emitter;
  else if (DevicetypeDesc == DeviceTypeDesc.HRex)
    DeviceTypeId = DeviceType.HRex;
  else if (DevicetypeDesc == DeviceTypeDesc.Rex) DeviceTypeId = DeviceType.Rex;
  else if (DevicetypeDesc == DeviceTypeDesc.LF) DeviceTypeId = DeviceType.LF;
  else if (DevicetypeDesc == NewDeviceTypeDesc.DripTray) DeviceTypeId = DeviceType.DripTray;
  else if (DevicetypeDesc == DeviceTypeDesc.Pullcord)
    DeviceTypeId = DeviceType.Pullcord;
  else if (DevicetypeDesc == DeviceTypeDesc.DC || DevicetypeDesc == DeviceTypeDesc.NewDC)
    DeviceTypeId = DeviceType.DC;
  else if (DevicetypeDesc == DeviceTypeDesc.Monitor)
    DeviceTypeId = DeviceType.Monitor;
  else if (DevicetypeDesc == DeviceTypeDesc.VW) DeviceTypeId = DeviceType.VW;
  else if (DevicetypeDesc == DeviceTypeDesc.Gateway)
    DeviceTypeId = DeviceType.Gateway;
  else if (DevicetypeDesc == DeviceTypeDesc.UT) DeviceTypeId = DeviceType.UT;
  else if (DevicetypeDesc == DeviceTypeDesc.UM) DeviceTypeId = DeviceType.UM;

  return DeviceTypeId;
}
export {
  alerticonstyle,
  searchalerticonstyle,
  alerticonred,
  alerticonstaff,
  Getalerticon,
  label_Polygon,
  fillcolor_style,
  fillcolor_styleSelect,
  fillcolor_style_Home,
  fillcolor_style_Home_Space,
};
export {
  get_geojsonObject_campus,
  get_geojsonObject_floor,
  map_marker_iconstyle,
  map_marker_iconstylewithtext,
  getDeviceTypeNew,
};
export {
  map_creation,
  AddMarkers,
  load_floorpopup_content,
  load_alertpopup_style,
  get_VectorSource,
  popup_overlay,
  linestokestyle,
  getDeviceTypeId,
};
export { mapTemplate_creation, mapBuildTemplate_creation };
