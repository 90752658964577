import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import "jspdf-autotable";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../app-general";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

@Component({
  selector: "app-download-resident",
  templateUrl: "./download-resident.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./download-resident.component.scss"],
})
export class DownloadResidentComponent implements OnInit, OnDestroy {
  columns = 2;
  resitem;
  RoomName;
  Status;
  CurrentTime: any;

  doc = new jsPDF();

  public isExcelDownload = false;
  public isPDFDownload = false;
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<DownloadResidentComponent>,
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 1;
    }
  }

  ngOnInit() {
    const loaderelement = document.getElementsByClassName(
      "loadresidentspinner"
    );
    loaderelement[0]["style"].display = "none";
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  downloadResidentExcel(resdata) {
    const loaderelement = document.getElementsByClassName(
      "loadresidentspinner"
    );
    loaderelement[0]["style"].display = "block";
    setTimeout(() => {
      this.downloadResident_Excel(resdata);
    }, 3);
  }

  downloadResident_Excel(residentData) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isExcelDownload) {
        this.isExcelDownload = true;
        const downloadResident = {
          Data: [] as any[],
        };

        for (let i = 0; i < residentData.length; i++) {
          this.resitem = residentData[i];
          const residentname =
            this.resitem.FirstName + " " + this.resitem.LastName;
          if (this.resitem.RoomName) {
            this.RoomName = this.resitem.RoomName;
          } else {
            this.RoomName = " ";
          }
          this.Status = "";
          if (this.resitem.Status == 1) this.Status = "Current";
          else if (this.resitem.Status == 2) this.Status = "Deceased";
          else if (this.resitem.Status == 3) this.Status = "Released";

          downloadResident.Data.push({
            "Resident Name": residentname,
            "Spouse Name": this.resitem.SpouseName,
            Gender: this.resitem.Gender,
            "Date of Birth":
              this.resitem.DateofBirth == null ? "" : this.resitem.DateofBirth,
            Phone: this.resitem.Phone,
            Email: this.resitem.Email,
            TagId: this.resitem.TagId,
            "Campus Name":
              this.resitem.CampusName == null ? "" : this.resitem.CampusName,
            "Building Name":
              this.resitem.BuildingName == null
                ? ""
                : this.resitem.BuildingName,
            "Zone Name":
              this.resitem.FloorName == null ? "" : this.resitem.FloorName,
            "Space Name":
              this.resitem.UnitName == null ? "" : this.resitem.UnitName,
            "Room Name": this.RoomName,
            Status: this.Status,
          });
        }

        this.JSONToCSVConvertor(downloadResident.Data, "", true);
      }
    } catch (err) {
      throw new Error(
        "download-resident.component.ts" +
          " - downloadResidentExcel() - " +
          err.message
      );
    }
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    try {
      const arrData =
        typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      let CSV = "";

      if (ShowLabel) {
        let row = "";

        for (const index in arrData[0]) {
          row += index + ",";
        }

        row = row.slice(0, -1);

        CSV += row + "\r\n";
      }

      if (arrData.length > 0) {
        for (let i = 0; i < arrData.length; i++) {
          let row = "";

          for (const index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
          }

          row.slice(0, row.length - 1);

          CSV += row + "\r\n";
        }

        if (CSV == "") {
          const loaderelement = document.getElementsByClassName(
            "loadresidentspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "Invalid data");
          this.isExcelDownload = false;
          return;
        }

        let fileName = "ResidentReport";
        fileName += ReportTitle.replace(/ /g, "_");

        const myBlob = new Blob([CSV], { type: "text/html" });
        const url = window.URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        const link = document.body.appendChild(a);
        document.body.appendChild(link);
        link.href = url;
        this.CurrentTime =
          new Date().getFullYear() +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          ("0" + new Date().getDate()).slice(-2) +
          "_" +
          new Date().getHours() +
          "_" +
          new Date().getMinutes() +
          "_" +
          new Date().getSeconds();
        link.download = fileName + "_" + this.CurrentTime + ".csv";
        link.click();
      } else {
        const loaderelement = document.getElementsByClassName(
          "loadresidentspinner"
        );
        loaderelement[0]["style"].display = "none";
        generateAlertMessage(this.dialog, "No data available");
        this.isExcelDownload = false;
      }
      const loaderelement = document.getElementsByClassName(
        "loadresidentspinner"
      );
      loaderelement[0]["style"].display = "none";
      this.isExcelDownload = false;
    } catch (err) {
      throw new Error(
        "download-resident.component.ts" +
          " - JSONToCSVConvertor() - " +
          err.message
      );
    }
  }

  downloadResidentPdf(resdata) {
    const loaderelement = document.getElementsByClassName(
      "loadresidentspinner"
    );
    loaderelement[0]["style"].display = "block";
    setTimeout(() => {
      this.downloadResident_Pdf(resdata);
    }, 3);
  }

  downloadResident_Pdf(residentData) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.isPDFDownload) {
        this.isPDFDownload = true;
        const resData = [] as any[];
        this.CurrentTime =
          new Date().getFullYear() +
          ("0" + (new Date().getMonth() + 1)).slice(-2) +
          ("0" + new Date().getDate()).slice(-2) +
          "_" +
          new Date().getHours() +
          "_" +
          new Date().getMinutes() +
          "_" +
          new Date().getSeconds();

        const columns = [
          { title: "Resident Name", dataKey: "ResidentName" },
          { title: "Spouse Name", dataKey: "SpouseName" },
          { title: "Geographic info", dataKey: "Email" },
          { title: "Tag Id", dataKey: "TagId" },
          { title: "Location", dataKey: "UnitName" },
          { title: "Room Name", dataKey: "RoomName" },
          { title: "Status", dataKey: "resStatus" },
        ];

        if (residentData.length > 0) {
          for (let i = 0; i < residentData.length; i++) {
            let RoomName = "";

            const ResidentName =
              residentData[i].FirstName +
              " " +
              residentData[i].LastName +
              " (" +
              residentData[i].Gender.substring(0, 1) +
              ")";
            const SpouseName = residentData[i].SpouseName;
            const DateofBirth = residentData[i].DateofBirth;
            const Phone = residentData[i].Phone;

            const TagId = residentData[i].TagId;
            const CampusName = residentData[i].CampusName;
            const BuildingName = residentData[i].BuildingName;
            const FloorName = residentData[i].FloorName;

            let Location = "";
            if (CampusName != null) {
              Location += CampusName + "\n";
            }
            if (BuildingName != null) {
              Location += BuildingName + "\n";
            }
            if (FloorName != null) {
              Location += FloorName + "\n";
            }
            if (residentData[i].UnitName != null) {
              Location += residentData[i].UnitName;
            }
            const UnitName = Location;

            let Geo = "";
            if (DateofBirth != null) {
              Geo += DateofBirth + "\n";
            }
            if (Phone != null) {
              Geo += Phone + "\n";
            }
            if (residentData[i].Email != null) {
              Geo += residentData[i].Email;
            }
            const Email = Geo;

            if (residentData[i].RoomName) {
              RoomName = residentData[i].RoomName;
            }
            const Status = residentData[i].Status;
            this.Status = "";
            if (Status == 1) this.Status = "C";
            else if (Status == 2) this.Status = "D";
            else if (Status == 3) this.Status = "R";

            const resStatus = this.Status;
            //CampusName, BuildingName, FloorName,

            resData.push({
              ResidentName,
              SpouseName,
              Email,
              TagId,
              UnitName,
              RoomName,
              resStatus,
            });
          }

          const pdf = new jsPDF("l"); // A4 size page of PDF
          let PdfFileName = "ResidentReport";

          const pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
          pdf.text("Resident Report ", pageWidth / 2, 10, "center");

          pdf.setFontSize(10);
          pdf.text(
            "C-Current , R-Released , D-Deceased ",
            pageWidth - 50,
            10,
            "center"
          );

          const options = {
            theme: "grid",
            bodyStyles: { lineColor: [0, 0, 0] },
            tableWidth: "auto",
            columnWidth: "wrap",
            showHeader: "everyPage",
            headStyles: {
              lineWidth: 0.2,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
              fillColor: [169, 169, 169],
              valign: "middle",
              halign: "center",
            },
            columnStyles: {
              ResidentName: { cellWidth: 50, minCellHeight: 24 },
              SpouseName: { cellWidth: 35, minCellHeight: 24 },
              Email: { cellWidth: 40, minCellHeight: 24 },
              TagId: { cellWidth: 30, minCellHeight: 24 },
              UnitName: { cellWidth: 45, minCellHeight: 24 },
              RoomName: { cellWidth: 35, minCellHeight: 24 },
              resStatus: { cellWidth: 30, minCellHeight: 24 },
            },
            rowPageBreak: "avoid",
          };

          pdf.autoTable(columns, resData, options);
          PdfFileName = PdfFileName + "_" + this.CurrentTime + ".pdf";
          pdf.save(PdfFileName);
          const loaderelement = document.getElementsByClassName(
            "loadresidentspinner"
          );
          loaderelement[0]["style"].display = "none";
          this.isPDFDownload = false;
        } else {
          const loaderelement = document.getElementsByClassName(
            "loadresidentspinner"
          );
          loaderelement[0]["style"].display = "none";
          generateAlertMessage(this.dialog, "No data available.");
          this.isPDFDownload = false;
        }
        const loaderelement = document.getElementsByClassName(
          "loadresidentspinner"
        );
        loaderelement[0]["style"].display = "none";
        this.isPDFDownload = false;
      }
    } catch (err) {
      throw new Error(
        "download-resident.component.ts" +
          " - downloadResidentPdf() - " +
          err.message
      );
    }
  }
  ngOnDestroy() { console.log('Service destroy');}
}
