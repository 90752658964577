<perfect-scrollbar style="max-width: 1200px; max-height: 530px;" >
  <div class="clsaddFloorDuplicate">
      <div class="addfloordup-title">
        <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="addfloordupHeader" style="margin: 0 0 10px 11px;">Duplicate Zone
        <span><img class="clscloseimagefloordup" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>

      <form style="padding:24px;" [formGroup]="floorDuplicateForm" autocomplete="off" novalidate [style.height]="minheight" (ngSubmit)="duplicatefloorDetails(floorDuplicateForm.value)">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
            
              <div fxFlex="55" fxFlex.md="25">   
                
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                    
                    <mat-grid-tile class="clsfloordupmatgrid">
                      <label>Selected Zone</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsfloordupmatgridtxt">
                          <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsfloordupaddtxt">
                             <input matInput placeholder="Zone Name" formControlName="floorname" maxlength="32" [(ngModel)]="data.FloorName" (keyup)="handleInput($event)" required/>                            
                             <input type="hidden" formControlName="campusID" [(ngModel)]="data.CampusId">   
                             <input type="hidden" formControlName="buildID" [(ngModel)]="data.BuildId">
                             <input type="hidden" formControlName="floorID" [(ngModel)]="data.FloorId">
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>   

                    <mat-grid-tile class="clsfloordupmatgrid">
                      <label>Campus Location</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsfloordupmatgridtxt">
                          <div class="text-inside"> 
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsfloordupaddtxt">                              
                              <mat-select formControlName="campus" placeholder="Select Campus" (selectionChange)="getBuildingDetails($event.value)">    
                                <mat-option *ngFor="let Campus of Campusdetails" [value]="Campus.CampusId" (click)="changecampus_duplicate(Campus.CampusId);">
                                  {{Campus.CampusName}}
                                </mat-option>
                              </mat-select> 
                              <mat-error>Please select Campus</mat-error>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>  
                  
                  <mat-grid-tile class="clsfloordupmatgrid">
                    <label>Building Location</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsfloordupmatgridtxt">
                        <div class="text-inside"> 
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsfloordupaddtxt">
                            <mat-select formControlName="building" placeholder="Select Building">    
                              <mat-option *ngFor="let Building of Buildingdetails" [value]="Building.BuildId" (click)="changebuilding_duplicate(Building.BuildId);">
                                {{Building.BuildingName}}
                              </mat-option>
                            </mat-select> 
                            <mat-error>Please select Building</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>  

                    <mat-grid-tile class="clsfloordupmatgrid">
                      <label>New Zone Name</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsfloordupmatgridtxt">
                        <div class="text-inside">
                            <mat-form-field appearance="outline" floatLabel="auto" class="clsfloordupaddtxt">
                            <input matInput placeholder="Zone Name" maxlength="32" formControlName="newfloorname" (keyup)="handleInput($event)">
                            <mat-error *ngIf="floorDuplicateForm.controls.newfloorname.hasError('required')">Please enter New Zone Name</mat-error>
                            <mat-error *ngIf="floorDuplicateForm.controls.newfloorname.hasError('pattern')">Please enter valid New Zone Name</mat-error>
                            <mat-error *ngIf="floorDuplicateForm.controls.newfloorname.hasError('maxlength')">Max length 32 characters only</mat-error>
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>   
                  
                  <mat-grid-tile class="clsfloordupmatgrid">
                    <label>New Zone Description</label>
                </mat-grid-tile>
                <mat-grid-tile class="clsfloordupmatgridtxt">
                      <div class="text-inside">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsfloordupaddtxt">
                           <input matInput placeholder="Description" maxlength="32" formControlName="description" (keyup)="handleInput($event)">
                           <mat-error *ngIf="floorDuplicateForm.controls.description.hasError('required')">Please enter Description</mat-error>
                           <mat-error *ngIf="floorDuplicateForm.controls.description.hasError('pattern')">Please enter valid Description</mat-error>
                           <mat-error *ngIf="floorDuplicateForm.controls.description.hasError('maxlength')">Max length 32 characters only</mat-error>
                        </mat-form-field>
                      </div>
                </mat-grid-tile>                                                  

                   </mat-grid-list>                              
              </div>
         
              <div fxFlex="55" fxFlex.md="45" style="margin-top:2em;" class="clsdivactiveZonesduplicate">
                <label>Select the Zones you'd like to duplicate with this Building:</label>
                  <mat-grid-list [cols]="columns" class="clsfloordupmatgridlist">
                    
                        <perfect-scrollbar style="max-width: 1200px; max-height: 230px;" >
                          <div class="text-inside" style="margin-top:1em;" *ngIf="SpaceInfo.length > 0">                                                                            
                            <mat-checkbox aria-label="Select All" 
                              class="clsfloordupcheckbox clsenable" id="chkresident" 
                              [checked]="isCheckedAll" 
                              (change)="$event ? toggleAll($event) : null" style="font-size: 14px;">Select All</mat-checkbox>
                          </div> 
                          <div style="margin:1em;">
                            <label style="font-size: medium;">Spaces</label>
                          </div>  
                          <div *ngFor="let Space of SpaceInfo;">
                            <div class="text-inside" style="margin-top:1em;" *ngIf="Space.UnitName.length < 15">                                                                            
                              <mat-checkbox class="clsfloordupcheckbox clsenable" [checked]="exists(Space.UnitId)" [id]="'chkunit_'+Space.UnitId" 
                              (click)="$event.stopPropagation()" (change)="$event ? toggleresVisibility(Space.UnitId,$event) : null" style="font-size: 14px;">
                              {{Space.UnitName}}, {{Space.DeviceZoneCount}} Devices</mat-checkbox>
                            </div>  
                            <div class="text-inside" style="margin-top:1em;" *ngIf="Space.UnitName.length >= 15">                                                                            
                              <mat-checkbox class="clsfloordupcheckbox clsenable" [checked]="exists(Space.UnitId)" [id]="'chkunit_'+Space.UnitId" 
                              (click)="$event.stopPropagation()" (change)="$event ? toggleresVisibility(Space.UnitId,$event) : null" style="font-size: 14px;"
                              matTooltipClass="devicetooltip" matTooltip={{Space.UnitName}} #tooltip="matTooltip" matTooltipPosition="above">
                              {{Space.UnitName.slice(0,15) + ".."}}, {{Space.DeviceZoneCount}} Devices</mat-checkbox>
                            </div> 
                          </div>                  
                    
                    </perfect-scrollbar>
                    </mat-grid-list>
              </div>
          
        
 </div>
 <mat-dialog-actions class="clsfloordupbuttoncontrol">      
  <button type="button" class="clsfloordupcancelbutton" mat-dialog-close mat-raised-button (click)="onCloseCancel()">Cancel</button>&nbsp;
  <button id="btnsavefloorduplicateclick" class="clsfloordupsavebutton" mat-raised-button >Save</button>
</mat-dialog-actions>
</form>
      
    </div>
</perfect-scrollbar>