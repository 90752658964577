import {
  Component,
  OnInit,
  Inject
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";

import { TechnologyoverlayService } from "../../technologyoverlay.service";
import {
  TechnologyForCreationResponse,
  getSiteAuthKeyReuest,
  getRoomCategory,
  updateTemplateDevice,
} from "../../technologyoverlay";
import {
  DeviceTypeDesc,
  DeviceType,
  NewDeviceTypeDesc,
  appsettings,
} from "../../../../app-settings";
import { SessionCheck, generateAlertMessage } from "./../../../../app-general";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import { Router } from "@angular/router";

import { AlertSharedService } from "../../../../_services/index";
import { ShareddialogcloseData } from "../../../../api";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { ApiService } from "../../../../api.service";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-floordevice",
  templateUrl: "./edit-floordevice.component.html",
  styleUrls: ["./edit-floordevice.component.scss"],
})
export class EditFloordeviceComponent implements OnInit {
  public devicefloorTempeditForm: UntypedFormGroup;
  DeviceTypeName: string;
  olddeviceId;
  oldemiterId;
  selectdevice = 0;
  getpolypoints = 0;
  checkpolygonpoints = 1;
  roomdraw = 0;
  image_path = "assets/images/NewDevice/";
  isSnapGrid = false;
  firstcolumns = 2;
  public watcher: Observable<boolean>;
  columns = 4;
  DCTColums = 3;
  minheight = "600px";
  devicerowHeight = "64px";
  deviceinputrowHeight = "75px";
  screenWidth;
  ShareddialogcloseDetails: ShareddialogcloseData;
  oldLocationName;
  old_data = [] as any[];
  new_data = [] as any[];
  HRexMacId;
  selectresCategoryId;
  deviceLocation;
  editDeviceLocationName;
  oldrexmacid;
  DeviceX;
  DeviceY;
  oldDcDualid;
  oldDCMacid;
  olddevicename;
  oldSubType;
  oldUnitId;
  Roomname;
  select_DeviceType;
  DeviceImageURL;
  GatewayMacId;

  checkPCRemovalDetection = false;
  checkPCButtonPress = false;
  checkPullCord = false;
  checkPCMobilityMode = "0";

  oldcheckPCMobilityMode;

  oldcheckPCRemovalDetection;
  oldcheckPCButtonPress;
  oldcheckPullCord;

  checkDCT_Tag1 = false;
  checkDCT_Tag2 = false;
  checkDCT_Tag3 = false;

  oldcheckDCT_Tag1;
  oldcheckDCT_Tag2;
  oldcheckDCT_Tag3;

  edit_selectedAlertState1 = 0;
  edit_selectedAlertState2 = 0;
  edit_selectedAlertState3 = 0;

  oldedit_selectedAlertState1;
  oldedit_selectedAlertState2;
  oldedit_selectedAlertState3;

  checkDCT_Ambient1 = false;
  checkDCT_Ambient2 = false;

  oldcheckDCT_Ambient1;
  oldcheckDCT_Ambient2;

  selectdefaultDCTMode = "0";

  oldOverTemp;
  oldUnderTemp;

  oldalertMessage1;
  oldalertMessage2;
  oldalertMessage3;

  oldambientOverMessage;
  oldambientUnderMessage;

  checkDCT_Probe1 = false;
  checkDCT_Probe2 = false;

  oldcheckDCT_Probe1 = false;
  oldcheckDCT_Probe2 = false;

  checkDCT_Humidity1 = false;
  checkDCT_Humidity2 = false;

  oldcheckDCT_Humidity1 = false;
  oldcheckDCT_Humidity2 = false;

  oldProbeOverTemp;
  oldProbeUnderTemp;

  oldHumidityOverTemp;
  oldHumidityUnderTemp;

  oldprobeOverMessage;
  oldprobeUnderMessage;

  oldhumidityOverMessage;
  oldhumidityUnderMessage;

  DCT_Id = 0;

  UMimgDisabled;
  MapSyncProcess;
  checkDCTag = false;
  checkDCLotiering = false;
  checkDCUnauthorizedEgress = false;

  oldcheckDCTag;
  oldcheckDCLotiering;
  oldcheckDCUnauthorizedEgress;
  getgatewaymacid;
  disableDCLotiering = true;
  disableDCUnauthorizedEgress = true;
  getHRexMacId;
  RoomCategorydetails;
  NewDeviceId;
  NewRexMacId = 0;
  NewDCMacId;
  overTempflag = 0;
  probeoverTempflag = 0;
  humidityoverTempflag = 0;
  Template_Id;
  alertMessage_input1;
  alertMessage_input2;
  alertMessage_input3;  
  ambientOverDesc;
  ambientOverMessage;
  ambientUnderDesc;
  ambientUnderMessage;
  probeOverDesc;
  probeOverMessage;
  probeUnderDesc;
  probeUnderMessage;
  humidityOverDesc;
  humidityOverMessage;
  humidityUnderDesc;
  humidityUnderMessage;
  authkey;
  ambientTemperatureText = "";
  temperatureScale;
  _egressenablehourmin1 = false;
  _egressenablehourmin2 = false;
  _egressenablehourmin3 = false;
  checkallinfo1 = false;
  checkallinfo2 = false;
  checkallinfo3 = false;
  data_startTime1;
  data_endTime1;
  data_startTime2;
  data_endTime2;
  data_startTime3;
  data_endTime3;
  LFDeviceType: any[] = [{ Type: 'Exciter', Value: 1 }, { Type: 'Hand Washing Monitor - Drip Tray', Value: 2 }];
  selectedLFDeviceType = 1;
  checkLFDeviceType = false;
  public Curfew_data = [] as any[];
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    
    public thisDialogRef: MatDialogRef<EditFloordeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyService: TechnologyoverlayService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private obj_document: any,
    @Inject(RollbarService) private rollbar: Rollbar,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
    this.getCurfew_data();
    this.old_data[0] = data;
    this.new_data[0] = JSON.parse(JSON.stringify(data));
    this.HRexMacId = this.new_data[0].MacId;
    this.checkLFDeviceType = true;
    if (this.new_data[0].DeviceName == DeviceTypeDesc.Emitter) {
      this.new_data[0]["NewDeviceType"] = DeviceType.Emitter;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.HRex) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.HRex;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Rex) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Rex;
    }        
    else if (this.new_data[0].DeviceName == DeviceTypeDesc.LF) {
      if (this.new_data[0].SubType==DeviceType.DripTray)
      {
        this.new_data[0]['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
      }
      else
      {
        this.new_data[0]['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
     }
        this.new_data[0]['DeviceType'] = DeviceType.LF;
    }    
    else if (this.new_data[0].DeviceName == DeviceTypeDesc.Pullcord) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
    } else if (
      this.new_data[0].DeviceName == DeviceTypeDesc.DC ||
      this.new_data[0].DeviceName == DeviceTypeDesc.NewDC
    ) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.DC;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Monitor) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.VW) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.VW;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.Gateway) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.UT) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.UT;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.UM) {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.UM;
    } 
     else if (this.new_data[0].DeviceName == DeviceTypeDesc.Intercom ) { 
        this.new_data[0]['NewDeviceName'] =  NewDeviceTypeDesc.Intercom;
      }
      else {
      this.new_data[0]["NewDeviceName"] = NewDeviceTypeDesc.Zone;
    }
    if (this.new_data[0].residentRoomCategoryid != 0)
      this.selectresCategoryId = this.new_data[0].residentRoomCategoryid;
    this.deviceLocation = this.new_data[0].Description;
    // this.deviceLocation = this.new_data[0].UnitName;
    this.editDeviceLocationName =
      this.new_data[0].UnitName + "," + this.new_data[0].FloorName;

    this.oldrexmacid = this.new_data[0].DeviceTypeId;
    this.DeviceX = this.new_data[0].DeviceX;
    this.DeviceY = this.new_data[0].DeviceY;

    this.olddeviceId = this.new_data[0].DeviceId;
    this.oldDcDualid = this.new_data[0].dualutId;
    this.oldDCMacid = this.new_data[0].DCMacId;
    this.olddevicename = this.new_data[0].DeviceName;
    this.oldLocationName = this.new_data[0].Location;
    this.oldSubType = this.new_data[0].SubType;
    this.oldUnitId = this.new_data[0].UnitId;
    this.Template_Id = this.new_data[0].TemplateId;

    this.Roomname = this.new_data[0].Location;
    
    if(this.new_data[0].SubType == DeviceType.DripTray && this.new_data[0].DeviceName == DeviceTypeDesc.LF)
    {
      this.selectedLFDeviceType = 2;
    }

    if (this.new_data[0].DeviceName == "UT") {
      this.select_DeviceType = "Universal Transmitter";
      this.minheight = "600px";
    } else {
      this.select_DeviceType = this.new_data[0].NewDeviceName;
      this.minheight = "450px";
    }

    this.DeviceImageURL = this.getDeviceImageURL(this.new_data[0].DeviceName);
    if (this.new_data[0].DeviceTypeId !== "") {
      if (
        this.new_data[0].DeviceName == "Emitter" ||
        this.new_data[0].DeviceName == "Monitor" ||
        this.new_data[0].DeviceName == "VW" ||
        this.new_data[0].DeviceName == "Universal Monitor"
      ) {
        this.DeviceTypeName = "IR Id";
      } else if (this.new_data[0].DeviceName == "Rex") {
        this.DeviceTypeName = "Mac Id";
      } else if (this.new_data[0].DeviceName == "LF") {
        this.DeviceTypeName = "LF Id";
      } else if (this.new_data[0].DeviceName == "DC") {
        this.DeviceTypeName = "Security Controller Id";
      } else if (this.new_data[0].DeviceName == "Gateway") {
        this.GatewayMacId = this.new_data[0].MacId;
      }
    }
    if (this.new_data[0].DeviceName == "UT") {
      this.columns = 4;
    }
    if (
      this.new_data[0].DeviceName == DeviceTypeDesc.Pullcord ||
      this.new_data[0].DeviceName == DeviceTypeDesc.DC ||
      this.new_data[0].DeviceName == DeviceTypeDesc.NewDC ||
      this.new_data[0].DeviceName == DeviceTypeDesc.UT
    )
      this.setDeviceDetails_Edit();
  }
  
  breakpointChanged() {        
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.DCTColums = 3;
    }       
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
      this.firstcolumns = 1;
      this.DCTColums = 1;
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 4;
      this.firstcolumns = 1;
      this.DCTColums = 1;
    }     
  }  
  
  getCurfew_data() {
    let _curfewdata = {};
    const _curfew_data_arr = [] as any[];
    let hourtext = "";
    let _hour = "";
    let _min = "";
    let min_inc = 30;
    for (let hour = 0; hour <= 24; hour++) {
      if (hour < 12) {
        _hour = "0" + hour.toString();
        hourtext = " am";
      } else if (hour == 24) {
        _hour = (hour - 12).toString();
        hourtext = " am";
        min_inc = 60;
      } else if (hour > 12) {
        _hour = "0" + (hour - 12).toString();
        hourtext = " pm";
      } else if (hour == 12) {
        _hour = hour.toString();
        hourtext = " pm";
      }

      _hour = _hour.slice(-2);
      for (let min = 0; min < 60; min = min + min_inc) {
        _min = "0" + min.toString();
        _min = _min.slice(-2);
        _curfewdata = {
          Time: _hour + ":" + _min + hourtext,
          Value: hour + ":" + min,
        };
        _curfew_data_arr.push(_curfewdata);
      }
    }
    this.Curfew_data = _curfew_data_arr;
  }

  getDeviceImageURL(deviceType) {
    let ImageURL;

    if (deviceType == DeviceTypeDesc.Gateway)
      ImageURL = this.image_path + "GatewayEnclosure.png";
    else if (deviceType == DeviceTypeDesc.HRex)
      ImageURL = this.image_path + "ClearRouter.png";
    else if (deviceType == DeviceTypeDesc.Rex)
      ImageURL = this.image_path + "ClearRepeater.png";
    else if (
      deviceType == DeviceTypeDesc.DC ||
      deviceType == DeviceTypeDesc.NewDC
    )
      ImageURL = this.image_path + "SecurityController.png";
    else if (deviceType == DeviceTypeDesc.Emitter)
      ImageURL = this.image_path + "TruLocator.png";
    else if (deviceType == DeviceTypeDesc.Monitor)
      ImageURL = this.image_path + "CommonArea Locator.png";
    else if (deviceType == DeviceTypeDesc.VW)
      ImageURL = this.image_path + "VirtualWallLocator.png";
    else if (deviceType == DeviceTypeDesc.LF)
      ImageURL = this.image_path + "LFAntenna.png";
    else if (deviceType == DeviceTypeDesc.Pullcord)
      ImageURL = this.image_path + "PullCord.png";
    else if (deviceType == DeviceTypeDesc.UT)
      ImageURL = this.image_path + "UniversalTransmitter.png";
    else if (deviceType == DeviceTypeDesc.UM)
      ImageURL = this.image_path + "CommonArea Locator.png";
    else if (deviceType == DeviceTypeDesc.Intercom)
      ImageURL = this.image_path + "Intercom.png";

    return ImageURL;
  }

  setDeviceDetails_Edit() {
    if (this.new_data[0].DeviceName == DeviceTypeDesc.Pullcord) {
      if (this.new_data[0].isremovaldetection == 1)
        this.checkPCRemovalDetection = true;
      else if (this.new_data[0].isremovaldetection == 0)
        this.checkPCRemovalDetection = false;

      if (this.new_data[0].isbuttonpress == 1) this.checkPCButtonPress = true;
      else if (this.new_data[0].isbuttonpress == 0)
        this.checkPCButtonPress = false;

      if (this.new_data[0].ispullcord == 1) this.checkPullCord = true;
      else if (this.new_data[0].ispullcord == 0) this.checkPullCord = false;

      this.oldcheckPCRemovalDetection = this.checkPCRemovalDetection;
      this.oldcheckPCButtonPress = this.checkPCButtonPress;
      this.oldcheckPullCord = this.checkPullCord;

      this.checkPCMobilityMode = this.new_data[0].ismobilitymode.toString();

      this.oldcheckPCMobilityMode = this.checkPCMobilityMode;
    } else if (
      this.new_data[0].DeviceName == DeviceTypeDesc.DC ||
      this.new_data[0].DeviceName == DeviceTypeDesc.NewDC
    ) {
      if (this.new_data[0].dualutId == 0) {
        this.new_data[0].dualutId = "";
      }

      if (this.new_data[0].dualutId == "") {
        this.checkDCLotiering = false;
        this.checkDCUnauthorizedEgress = false;
        this.disableDCLotiering = true;
        this.disableDCUnauthorizedEgress = true;
      } else {
        this.disableDCLotiering = false;
        this.disableDCUnauthorizedEgress = false;
      }

      if (this.new_data[0].istaginfield == 1) {
        this.checkDCTag = true;
      } else this.checkDCTag = false;

      if (this.new_data[0].islotiering == 1) {
        this.checkDCLotiering = true;
      } else this.checkDCLotiering = false;

      if (this.new_data[0].isunauthorizedegerss == 1) {
        this.checkDCUnauthorizedEgress = true;
      } else this.checkDCUnauthorizedEgress = false;

      this.oldcheckDCTag = this.checkDCTag;
      this.oldcheckDCLotiering = this.checkDCLotiering;
      this.oldcheckDCUnauthorizedEgress = this.checkDCUnauthorizedEgress;
    } else if (this.new_data[0].DeviceName == DeviceTypeDesc.UT) {
      this.DCT_Id = parseInt(this.new_data[0].TemplateId);

      if (this.new_data[0].dinput1 == 1) 
      {
        this.checkDCT_Tag1 = true;
        this._egressenablehourmin1 = true;
      }
      else 
      {
        this.checkDCT_Tag1 = false;
        this._egressenablehourmin1 = false;
      }
      
      if (this.new_data[0].dinput2 == 1) 
      {
        this.checkDCT_Tag2 = true;
        this._egressenablehourmin2 = true;
      }
      else 
      {
        this.checkDCT_Tag2 = false;
        this._egressenablehourmin2 = false;
      }
      
      if (this.new_data[0].dinput3 == 1) 
      {
        this.checkDCT_Tag3 = true;
        this._egressenablehourmin3 = true;
      }
      else 
      {
        this.checkDCT_Tag3 = false;
        this._egressenablehourmin3 = false;
      }

      if (this.new_data[0].ambientOverTemp == 1) this.checkDCT_Ambient1 = true;
      else this.checkDCT_Ambient1 = false;

      if (this.new_data[0].ambientUnderTemp == 1) this.checkDCT_Ambient2 = true;
      else this.checkDCT_Ambient2 = false;

      if (this.new_data[0].probeOverTemp == 1) this.checkDCT_Probe1 = true;
      else this.checkDCT_Probe1 = false;

      if (this.new_data[0].probeUnderTemp == 1) this.checkDCT_Probe2 = true;
      else this.checkDCT_Probe2 = false;

      if (this.new_data[0].humidityOverTemp == 1)
        this.checkDCT_Humidity1 = true;
      else this.checkDCT_Humidity1 = false;

      if (this.new_data[0].humidityUnderTemp == 1)
        this.checkDCT_Humidity2 = true;
      else this.checkDCT_Humidity2 = false;

      if(this.new_data[0].alertState1 != null)
        this.edit_selectedAlertState1 = this.new_data[0].alertState1.toString();
      if(this.new_data[0].alertState2 != null)
        this.edit_selectedAlertState2 = this.new_data[0].alertState2.toString();
      if(this.new_data[0].alertState3 != null)
        this.edit_selectedAlertState3 = this.new_data[0].alertState3.toString();

      this.oldedit_selectedAlertState1 = this.edit_selectedAlertState1;
      this.oldedit_selectedAlertState2 = this.edit_selectedAlertState2;
      this.oldedit_selectedAlertState3 = this.edit_selectedAlertState3;

      this.oldcheckDCT_Tag1 = this.checkDCT_Tag1;
      this.oldcheckDCT_Tag2 = this.checkDCT_Tag2;
      this.oldcheckDCT_Tag3 = this.checkDCT_Tag3;

      this.oldalertMessage1 = this.new_data[0].alertMessage1;
      this.oldalertMessage2 = this.new_data[0].alertMessage2;
      this.oldalertMessage3 = this.new_data[0].alertMessage3;

      this.alertMessage_input1 = this.oldalertMessage1;
      this.alertMessage_input2 = this.oldalertMessage2;
      this.alertMessage_input3 = this.oldalertMessage3;
      
      /********************Input Active Alert Time***********************/       
      if (this.new_data[0].dinput1 == 1)
      {                    
        if (parseInt(this.new_data[0].Input1StartHr) >= 0)
          this.data_startTime1 = this.new_data[0].Input1StartHr.toString() + ":" + this.new_data[0].Input1StartMi.toString();
        else
          this.data_startTime1 = "";

        if (parseInt(this.new_data[0].Input1EndHr) >= 0)
          this.data_endTime1 = this.new_data[0].Input1EndHr.toString() + ":" + this.new_data[0].Input1EndMi.toString();
        else
          this.data_endTime1 = "";
          
        if(this.new_data[0].Input1chkAllday == 1)  
        {
          this.checkallinfo1 = true;
          this._egressenablehourmin1 = false;
          this.data_startTime1 = "";
          this.data_endTime1 = "";
        }        
      }
        
      if (this.new_data[0].dinput2 == 1)
      { 
        if (parseInt(this.new_data[0].Input2StartHr) >= 0)
          this.data_startTime2 = this.new_data[0].Input2StartHr.toString() + ":" + this.new_data[0].Input2StartMi.toString();
        else
          this.data_startTime2 = "";

        if (parseInt(this.new_data[0].Input2EndHr) >= 0)
          this.data_endTime2 = this.new_data[0].Input2EndHr.toString() + ":" + this.new_data[0].Input2EndMi.toString();
        else
          this.data_endTime2 = "";
          
        if(this.new_data[0].Input2chkAllday == 1)  
        {
          this.checkallinfo2 = true;
          this._egressenablehourmin2 = false;
          this.data_startTime2 = "";    
          this.data_endTime2 = "";      
        }        
      }
        
      if (this.new_data[0].dinput3 == 1)
      { 
        if (parseInt(this.new_data[0].Input3StartHr) >= 0)
          this.data_startTime3 = this.new_data[0].Input3StartHr.toString() + ":" + this.new_data[0].Input3StartMi.toString();
        else
          this.data_startTime3 = "";

        if (parseInt(this.new_data[0].Input3EndHr) >= 0)
          this.data_endTime3 = this.new_data[0].Input3EndHr.toString() + ":" + this.new_data[0].Input3EndMi.toString();
        else
          this.data_endTime3 = "";                             
        
        if(this.new_data[0].Input3chkAllday == 1)  
        {
          this.checkallinfo3 = true;
          this._egressenablehourmin3 = false;
          this.data_startTime3 = "";
          this.data_endTime3 = "";
        }        
      }
      
      this.oldambientOverMessage = this.new_data[0].ambientOverMessage;
      this.oldambientUnderMessage = this.new_data[0].ambientUnderMessage;
      
      this.ambientOverDesc = this.new_data[0].ambientOverDesc;
      this.ambientUnderDesc = this.new_data[0].ambientUnderDesc;
      
      this.ambientOverMessage = this.new_data[0].ambientOverMessage;
      this.ambientUnderMessage = this.new_data[0].ambientUnderMessage;
      
      this.oldOverTemp = this.new_data[0].ambientOverDesc;
      this.oldUnderTemp = this.new_data[0].ambientUnderDesc;
      
      this.oldcheckDCT_Ambient1 = this.checkDCT_Ambient1;
      this.oldcheckDCT_Ambient2 = this.checkDCT_Ambient2;
      
      this.oldcheckDCT_Probe1 = this.checkDCT_Probe1;
      this.oldcheckDCT_Probe2 = this.checkDCT_Probe2;
      
      this.oldprobeOverMessage = this.new_data[0].probeOverMessage;
      this.oldprobeUnderMessage = this.new_data[0].probeUnderMessage;
      
      this.oldProbeOverTemp = this.new_data[0].probeOverDesc;
      this.oldProbeUnderTemp = this.new_data[0].probeUnderDesc;
      
      this.probeOverDesc = this.new_data[0].probeOverDesc;
      this.probeUnderDesc = this.new_data[0].probeUnderDesc;
      
      this.probeOverMessage = this.new_data[0].probeOverMessage;
      this.probeUnderMessage = this.new_data[0].probeUnderMessage;
      
      this.oldcheckDCT_Humidity1 = this.checkDCT_Humidity1;
      this.oldcheckDCT_Humidity2 = this.checkDCT_Humidity2;
      
      this.oldhumidityOverMessage = this.new_data[0].humidityOverMessage;
      this.oldhumidityUnderMessage = this.new_data[0].humidityUnderMessage;
      
      this.oldHumidityOverTemp = this.new_data[0].humidityOverDesc;
      this.oldHumidityUnderTemp = this.new_data[0].humidityUnderDesc;
      
      this.humidityOverMessage = this.new_data[0].humidityOverMessage;
      this.humidityUnderMessage = this.new_data[0].humidityUnderMessage;
      
      this.humidityOverDesc = this.new_data[0].humidityOverDesc;
      this.humidityUnderDesc = this.new_data[0].humidityUnderDesc;
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.onResize();
      this.getResidentRoomCategory();
      
      this.devicefloorTempeditForm = new UntypedFormGroup({
        deviceimg: new UntypedFormControl(""),
        devicelocation: new UntypedFormControl(""),
        campusID: new UntypedFormControl(""),
        buildID: new UntypedFormControl(""),
        floorID: new UntypedFormControl(""),
        unitID: new UntypedFormControl(""),
        devicename: new UntypedFormControl(""),
        svgId: new UntypedFormControl(""),
        gatewaymacid: new UntypedFormControl(""),
        templateId: new UntypedFormControl(""),
        dataId: new UntypedFormControl(""),
        hrexmacid: new UntypedFormControl(""),
        zoneroomcategory: new UntypedFormControl(""),
        roomname: new UntypedFormControl("", [
          Validators.maxLength(30),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        description: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        deviceid: new UntypedFormControl("", [Validators.pattern(appsettings.Number_Pattern)]),
        devicetypeid: new UntypedFormControl(""),
        dcdeviceid: new UntypedFormControl(),
        lfdevicetype: new UntypedFormControl(),
        devicedualid: new UntypedFormControl("", [
          Validators.pattern(appsettings.Number_Pattern),
        ]),
        dcmacid: new UntypedFormControl(""),
        enableinput1: new UntypedFormControl(""),
        enableinput2: new UntypedFormControl(""),
        enableinput3: new UntypedFormControl(""),
        alertmessage1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage3DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage4DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage5DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        alertmessage6DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage7DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage8DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        alertmessage9DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        temperature1DCT: new UntypedFormControl("", [Validators.maxLength(30)]),
        temperature2DCT: new UntypedFormControl("", [Validators.maxLength(30)]),

        probetemperature1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        probetemperature2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),

        humiditytemperature1DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        humiditytemperature2DCT: new UntypedFormControl("", [
          Validators.maxLength(30),
        ]),
        wr_start1: new UntypedFormControl(""),
        wr_end1: new UntypedFormControl(""),            
        wr_start2: new UntypedFormControl(""),
        wr_end2: new UntypedFormControl(""),            
        wr_start3: new UntypedFormControl(""),
        wr_end3: new UntypedFormControl(""),
      });
      
      const zoneRoomCategory = this.devicefloorTempeditForm.get("zoneroomcategory");      
      const devicelocation = this.devicefloorTempeditForm.get("devicelocation");
      const devicetypeid = this.devicefloorTempeditForm.get("devicetypeid");
      const deviceid = this.devicefloorTempeditForm.get("deviceid");
      const dcmacid = this.devicefloorTempeditForm.get("dcmacid");
      const devicedualid = this.devicefloorTempeditForm.get("devicedualid");
      
      if (localStorage.getItem("ENABLE_UL2560") == "1") {
        if(zoneRoomCategory != null)
        {
          zoneRoomCategory.setValidators([Validators.required]);
          zoneRoomCategory.updateValueAndValidity();
        }
      }

      if(devicelocation != null) devicelocation.disable();
      if(devicetypeid != null) devicetypeid.disable();
      if(deviceid != null) deviceid.disable();
      if(dcmacid != null) dcmacid.disable();
      if(devicedualid != null) devicedualid.disable();
      this.getHRexdetails();
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - constructor() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    if (window.innerWidth > 960 && window.innerWidth <= 1280) {
      this.columns = 4;
      this.firstcolumns = 2;
      this.DCTColums = 3;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 960) {
      this.columns = 4;
      this.firstcolumns = 1;
      this.DCTColums = 1;
    }

    this.screenWidth = window.innerWidth;
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      this.devicerowHeight = "72.5px";
    }
    if (this.screenWidth <= 425) {
      this.devicerowHeight = "90px";
    } else if (isIEOrEdge == false) {
      this.devicerowHeight = "67px";
    }
  }  
  public onCloseDeviceDialog = () => {
    try {
      this.thisDialogRef.close("Cancel");      
      if (!SessionCheck(this.router)) {
        return;
      }
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" + " - onCloseDeviceDialog() - " + err.message
      );
    }
  };

  getResidentRoomCategory() {
    try {
      const roomitem: getRoomCategory = {
        authKey: this.authkey,
        categoryId: 0,
      };
      this.technologyService
        .getResidentRoomCategory(roomitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((roomdetails: any[]) => {
          this.RoomCategorydetails = roomdetails;
        });
    } catch (err) {
      throw new Error(
        "edit-floordevice.component.ts" +
          " - getResidentRoomCategory() - " +
          err.message
      );
    }
  }

  selectRoomCategory(categoryId) {
    this.selectresCategoryId = categoryId;
  }
  
selectTempLFDeviceType(typevalue)
{
  this.selectedLFDeviceType = typevalue;
}

  getHRexdetails() {
    try {
      const hrexdetails: getSiteAuthKeyReuest = {
        authKey: this.authkey,
      };
      this.technologyService
        .getHRexdetails(hrexdetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((HRexDetails: any[]) => {
          if (HRexDetails != null && HRexDetails != undefined) {
            this.HRexMacId =HRexDetails[0].MacId;
            //this.HRexMacId = HRexDetails;
            this.getHRexMacId = HRexDetails;
          }
        });
    } catch (err) {
      throw new Error(
        "add-device.component.ts" + " - getHRexdetails() - " + err.message
      );
    }
  }

  public editTempDevicedetails = (deviceFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();

      if (this.devicefloorTempeditForm.valid) {
        this.updateTemplateDeviceInfo(deviceFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-floordevice.component.ts" +
          " - editTempDevicedetails() - " +
          err.message
      );
    }
  };

  updateTemplateDeviceInfo(deviceFormValue) {
    try {
      let devicetype;
      let DeviceId;
      let emitterId;
    
      let deviceMacId = "";

      const saveclick = this.obj_document.getElementById("btneditclick");
      saveclick.className =
        "clsdevicetemp_editbutton mat-raised-button disablebtn";

      this.NewDeviceId = deviceFormValue.devicetypeid;

      if (deviceFormValue.devicename == DeviceTypeDesc.Emitter) {
        devicetype = DeviceType.Emitter;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
      } else if (deviceFormValue.devicename == DeviceTypeDesc.HRex) {
        devicetype = DeviceType.HRex;
        DeviceId = deviceFormValue.deviceid;

        deviceMacId = this.getHRexMacId.toString();
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Rex) {
        devicetype = DeviceType.Rex;
        DeviceId = deviceFormValue.deviceid;
        this.NewDeviceId = DeviceId;
        emitterId = deviceFormValue.devicetypeid;
        if (emitterId != undefined)
          emitterId = emitterId.replace(/:/g, "_").replace(/-/g, "_");
        deviceMacId = this.NewRexMacId.toString();
      } else if (deviceFormValue.devicename == DeviceTypeDesc.LF) {
        devicetype = DeviceType.LF;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Pullcord) {
        devicetype = DeviceType.Pullcord;
        DeviceId = deviceFormValue.deviceid;
      } else if (
        deviceFormValue.devicename == DeviceTypeDesc.DC ||
        deviceFormValue.devicename == DeviceTypeDesc.NewDC
      ) {
        devicetype = DeviceType.DC;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
        this.NewDCMacId = "";

        deviceMacId = this.NewDCMacId.toString();
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Monitor) {
        devicetype = DeviceType.Monitor;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
      } else if (deviceFormValue.devicename == DeviceTypeDesc.VW) {
        devicetype = DeviceType.VW;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
      } else if (deviceFormValue.devicename == DeviceTypeDesc.Gateway) {
        devicetype = DeviceType.Gateway;
        DeviceId = deviceFormValue.deviceid;
        emitterId = deviceFormValue.gatewaymacid;
        deviceMacId = emitterId.toString();
      } else if (deviceFormValue.devicename == DeviceTypeDesc.UT) {
        devicetype = DeviceType.UT;
        DeviceId = deviceFormValue.deviceid;
        let overtempvalue;
        let undertempvalue;
      
        if (this.checkDCT_Tag1 == true) {
          if (this.edit_selectedAlertState1 == 0) {
            generateAlertMessage(this.dialog,"Please select Alert State for Input1");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } 
          else if (this.edit_selectedAlertState1 != 0) {
            if (this.devicefloorTempeditForm.value.alertmessage1DCT == null) 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input1");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } 
            else if (this.devicefloorTempeditForm.value.alertmessage1DCT.replace(/\s+/g, " ").trim() == "") 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input1");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } 
            else if (!/^[a-zA-Z0-9 ]+$/.test(this.devicefloorTempeditForm.value.alertmessage1DCT)) {
              generateAlertMessage(this.dialog,"Please Enter valid Alert Message for Input1");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            }
          }
          if ((this.devicefloorTempeditForm.value.wr_start1 == "" || this.devicefloorTempeditForm.value.wr_start1 == undefined) && (this.devicefloorTempeditForm.value.wr_end1 == "" ||
                        this.devicefloorTempeditForm.value.wr_end1 == undefined) && (this.checkallinfo1 == false || this.checkallinfo1 == undefined)) 
          {              
            generateAlertMessage(this.dialog,"Please select Input1 Time info or All");
            saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
            return false;
          }

          if (this.devicefloorTempeditForm.value.wr_start1 != "" && this.devicefloorTempeditForm.value.wr_start1 != undefined) 
          {
            if (this.devicefloorTempeditForm.value.wr_end1 == "" || this.devicefloorTempeditForm.value.wr_end1 == undefined) 
            {                
              generateAlertMessage(this.dialog,"Please select Input1 End time");
              saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
              return false;
            }
          }

          if (this.devicefloorTempeditForm.value.wr_end1 != "" && this.devicefloorTempeditForm.value.wr_end1 != undefined) 
          {
            if (this.devicefloorTempeditForm.value.wr_start1 == "" || this.devicefloorTempeditForm.value.wr_start1 == undefined) 
            {                
              generateAlertMessage(this.dialog,"Please select Input1 Start time");
              saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
              return false;
            }
          } 
        }

        if (this.checkDCT_Tag2 == true) {
          if (this.edit_selectedAlertState2 == 0) {
            generateAlertMessage(this.dialog, "Please select Alert State for Input2");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } 
          else if (this.edit_selectedAlertState2 != 0) {
            if (this.devicefloorTempeditForm.value.alertmessage2DCT == null) 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input2");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } 
            else if (this.devicefloorTempeditForm.value.alertmessage2DCT.replace(/\s+/g, " ").trim() == "") 
            {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input2");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } 
            else if (!/^[a-zA-Z0-9 ]+$/.test(this.devicefloorTempeditForm.value.alertmessage2DCT)) {
              generateAlertMessage(this.dialog,"Please Enter valid Alert Message for Input2");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            }
          }
          if ((this.devicefloorTempeditForm.value.wr_start2 == "" || this.devicefloorTempeditForm.value.wr_start2 == undefined) && (this.devicefloorTempeditForm.value.wr_end2 == "" ||
              this.devicefloorTempeditForm.value.wr_end2 == undefined) && (this.checkallinfo2 == false || this.checkallinfo2 == undefined)) 
          {                  
            generateAlertMessage(this.dialog,"Please select Input2 Time info or All");
            saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
            return false;
          }

          if (this.devicefloorTempeditForm.value.wr_start2 != "" && this.devicefloorTempeditForm.value.wr_start2 != undefined) 
          {
            if (this.devicefloorTempeditForm.value.wr_end2 == "" || this.devicefloorTempeditForm.value.wr_end2 == undefined) 
            {                    
              generateAlertMessage(this.dialog,"Please select Input2 End time");
              saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
              return false;
            }
          }

          if (this.devicefloorTempeditForm.value.wr_end2 != "" && this.devicefloorTempeditForm.value.wr_end2 != undefined) 
          {
            if (this.devicefloorTempeditForm.value.wr_start2 == "" || this.devicefloorTempeditForm.value.wr_start2 == undefined) 
            {                    
              generateAlertMessage(this.dialog,"Please select Input2 Start time");
              saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
              return false;
            }
          } 
        }

        if (this.checkDCT_Tag3 == true) {
          if (this.edit_selectedAlertState3 == 0) {
            generateAlertMessage(this.dialog,"Please select Alert State for Input3");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } 
          else if (this.edit_selectedAlertState3 != 0) {
            if (this.devicefloorTempeditForm.value.alertmessage3DCT == null) {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input3");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } 
            else if (this.devicefloorTempeditForm.value.alertmessage3DCT.replace(/\s+/g, " ").trim() == "") {
              generateAlertMessage(this.dialog,"Please Enter Alert Message for Input3");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } 
            else if (!/^[a-zA-Z0-9 ]+$/.test(this.devicefloorTempeditForm.value.alertmessage3DCT)) {
              generateAlertMessage(this.dialog,"Please Enter valid Alert Message for Input3");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            }
          }
          if ((this.devicefloorTempeditForm.value.wr_start3 == "" || this.devicefloorTempeditForm.value.wr_start3 == undefined) && (this.devicefloorTempeditForm.value.wr_end3 == "" ||
              this.devicefloorTempeditForm.value.wr_end3 == undefined) && (this.checkallinfo3 == false || this.checkallinfo3 == undefined)) 
          {                  
            generateAlertMessage(this.dialog,"Please select Input3 Time info or All");
            saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
            return false;
          }

          if (this.devicefloorTempeditForm.value.wr_start3 != "" && this.devicefloorTempeditForm.value.wr_start3 != undefined) 
          {
            if (this.devicefloorTempeditForm.value.wr_end3 == "" || this.devicefloorTempeditForm.value.wr_end3 == undefined) 
            {                    
              generateAlertMessage(this.dialog,"Please select Input3 End time");
              saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
              return false;
            }
          }

          if (this.devicefloorTempeditForm.value.wr_end3 != "" && this.devicefloorTempeditForm.value.wr_end3 != undefined) 
          {
            if (this.devicefloorTempeditForm.value.wr_start3 == "" || this.devicefloorTempeditForm.value.wr_start3 == undefined) 
            {                    
              generateAlertMessage(this.dialog,"Please select Input3 Start time");
              saveclick.className = 'clsdevicetemp_editbutton mat-raised-button';
              return false;
            }
          } 
        }

        if (this.checkDCT_Ambient1 == true) {
          if (this.devicefloorTempeditForm.value.temperature1DCT == null) {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          }
          else if (this.devicefloorTempeditForm.value.temperature1DCT.replace(/\s+/g, " ").trim() == "") {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (!/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(this.devicefloorTempeditForm.value.temperature1DCT)) {
            generateAlertMessage(this.dialog,"Please Enter valid Temperature for Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (this.devicefloorTempeditForm.value.temperature1DCT < -45 ||
            this.devicefloorTempeditForm.value.temperature1DCT > 125) {
            generateAlertMessage(this.dialog,"Invalid Range, Over Temperature should be in between -45 and 125");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else {
            overtempvalue = this.devicefloorTempeditForm.value.temperature1DCT;
            undertempvalue = this.devicefloorTempeditForm.value.temperature2DCT;

            if (parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              (this.overTempflag == 1 || this.overTempflag == 0)
            ) {
              generateAlertMessage(this.dialog,"Over Temp should be greater than the Under Temp");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            }

            if (this.devicefloorTempeditForm.value.temperature1DCT.replace(/\s+/g, " ").trim() != "") {
              if (  this.devicefloorTempeditForm.value.alertmessage4DCT == null) 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Over Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              } 
              else if (  this.devicefloorTempeditForm.value.alertmessage4DCT.replace(/\s+/g, " ").trim() == "") 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Over Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              } 
              else if (!/^[a-zA-Z0-9 ]+$/.test(this.devicefloorTempeditForm.value.alertmessage4DCT)) {
                generateAlertMessage(this.dialog,"Please Enter valid Alert Message for Over Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Ambient2 == true) {
          if (this.devicefloorTempeditForm.value.temperature2DCT == null) {
            generateAlertMessage(this.dialog, "Please Enter Temperature for Under Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          }
          else if (this.devicefloorTempeditForm.value.temperature2DCT.replace(/\s+/g, " ").trim() == "") {
            generateAlertMessage(this.dialog, "Please Enter Temperature for Under Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (!/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(this.devicefloorTempeditForm.value.temperature2DCT)) {
            generateAlertMessage(this.dialog,"Please Enter valid Temperature for Under Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (this.devicefloorTempeditForm.value.temperature2DCT < -45 ||
            this.devicefloorTempeditForm.value.temperature2DCT > 125) {
            generateAlertMessage(this.dialog,"Invalid Range, Under Temperature should be in between -45 and 125");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else {
            overtempvalue = this.devicefloorTempeditForm.value.temperature1DCT;
            undertempvalue = this.devicefloorTempeditForm.value.temperature2DCT;
            this.oldUnderTemp =
              this.devicefloorTempeditForm.value.temperature2DCT;
            if (parseFloat(overtempvalue) <= parseFloat(undertempvalue) && this.overTempflag == 2) {
              generateAlertMessage(this.dialog, "Under Temp should be lesser than the Over Temp");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } else if (this.devicefloorTempeditForm.value.temperature2DCT != "") {
              if (this.devicefloorTempeditForm.value.alertmessage5DCT == null) {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Under Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
              else if (this.devicefloorTempeditForm.value.alertmessage5DCT.replace(/\s+/g, " ").trim() == "") {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Under Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              } else if (!/^[a-zA-Z0-9 ]+$/.test(this.devicefloorTempeditForm.value.alertmessage5DCT)) {
                generateAlertMessage(this.dialog,"Please Enter valid Alert Message for Under Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Probe1 == true) {
          if (this.devicefloorTempeditForm.value.probetemperature1DCT == null) {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          }
          else if (this.devicefloorTempeditForm.value.probetemperature1DCT.replace(/\s+/g, " ").trim() == "") {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (!/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(this.devicefloorTempeditForm.value.probetemperature1DCT)) {
            generateAlertMessage(this.dialog, "Please Enter valid Temperature for Probe Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (this.devicefloorTempeditForm.value.probetemperature1DCT < -45 || this.devicefloorTempeditForm.value.probetemperature1DCT > 125) {
            generateAlertMessage(this.dialog,"Invalid Range, Probe Over Temperature should be in between -45 and 125");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else {
            overtempvalue = this.devicefloorTempeditForm.value.probetemperature1DCT;
            undertempvalue = this.devicefloorTempeditForm.value.probetemperature2DCT;
            if (parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              (this.probeoverTempflag == 1 || this.overTempflag == 0)) {
              generateAlertMessage(this.dialog,"Probe Over Temp should be greater than the Probe Under Temp");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            }

            if (this.devicefloorTempeditForm.value.probetemperature1DCT.replace(/\s+/g, " ").trim() != "") {
              if (this.devicefloorTempeditForm.value.alertmessage6DCT == null) {
                generateAlertMessage(this.dialog, "Please Enter Alert Message for Probe Over Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
              else if (this.devicefloorTempeditForm.value.alertmessage6DCT.replace(/\s+/g, " ").trim() == "") {
                generateAlertMessage(this.dialog, "Please Enter Alert Message for Probe Over Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              } else if (!/^[a-zA-Z0-9 ]+$/.test(this.devicefloorTempeditForm.value.alertmessage6DCT)) {
                generateAlertMessage(this.dialog,"Please Enter valid Alert Message for Probe Over Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Probe2 == true) {
          if (this.devicefloorTempeditForm.value.probetemperature2DCT == null) {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Under Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          }
          else if (this.devicefloorTempeditForm.value.probetemperature2DCT.replace(/\s+/g, " ").trim() == "") {
            generateAlertMessage(this.dialog,"Please Enter Temperature for Probe Under Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (!/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(this.devicefloorTempeditForm.value.probetemperature2DCT)) {
            generateAlertMessage(this.dialog,"Please Enter valid Temperature for Probe Under Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (this.devicefloorTempeditForm.value.probetemperature2DCT < -45 ||
            this.devicefloorTempeditForm.value.probetemperature2DCT > 125) {
            generateAlertMessage(this.dialog,"Invalid Range, Probe Under Temperature should be in between -45 and 125");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else {
            overtempvalue = this.devicefloorTempeditForm.value.probetemperature1DCT;
            undertempvalue = this.devicefloorTempeditForm.value.probetemperature2DCT;
            this.oldUnderTemp = this.devicefloorTempeditForm.value.probetemperature2DCT;
            if (parseFloat(overtempvalue) <= parseFloat(undertempvalue) && this.probeoverTempflag == 2) {
              generateAlertMessage(this.dialog,"Probe Under Temp should be lesser than the Probe Over Temp");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } else if (this.devicefloorTempeditForm.value.probetemperature2DCT != "") {
              if (this.devicefloorTempeditForm.value.alertmessage7DCT == null) {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Under Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
              else if (this.devicefloorTempeditForm.value.alertmessage7DCT.replace(/\s+/g, " ").trim() == "") {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Probe Under Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              } else if (!/^[a-zA-Z0-9 ]+$/.test(this.devicefloorTempeditForm.value.alertmessage7DCT)) {
                generateAlertMessage(this.dialog,"Please Enter valid Alert Message for Probe Under Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Humidity1 == true) {
          if (this.devicefloorTempeditForm.value.humiditytemperature1DCT == null) {
            generateAlertMessage(this.dialog, "Please Enter Temperature for Humidity Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          }
          else if (this.devicefloorTempeditForm.value.humiditytemperature1DCT.replace(/\s+/g, " ").trim() == "") {
            generateAlertMessage(this.dialog, "Please Enter Temperature for Humidity Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (!/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(this.devicefloorTempeditForm.value.humiditytemperature1DCT)) {
            generateAlertMessage(this.dialog,"Please Enter valid Temperature for Humidity Over Temp");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (this.devicefloorTempeditForm.value.humiditytemperature1DCT < 0 ||
            this.devicefloorTempeditForm.value.humiditytemperature1DCT > 100) {
            generateAlertMessage(this.dialog,"Invalid Range, Humidity Over Temperature should be in between 0 and 100");
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else {
            overtempvalue = this.devicefloorTempeditForm.value.humiditytemperature1DCT;
            undertempvalue = this.devicefloorTempeditForm.value.humiditytemperature2DCT;
            if (parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              (this.humidityoverTempflag == 1 || this.overTempflag == 0)) {
              generateAlertMessage(this.dialog,"Humidity Over Temp should be greater than the Humidity Under Temp");
              saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
              return false;
            }

            if (this.devicefloorTempeditForm.value.humiditytemperature1DCT
                .replace(/\s+/g, " ")
                .trim() != "") {
              if (this.devicefloorTempeditForm.value.alertmessage8DCT == null) {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Over Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              } 
              else if (this.devicefloorTempeditForm.value.alertmessage8DCT.replace(/\s+/g, " ").trim() == "") {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Over Temp");
                saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
                return false;
              } 
              else if (!/^[a-zA-Z0-9 ]+$/.test(
                  this.devicefloorTempeditForm.value.alertmessage8DCT)) {
                generateAlertMessage(this.dialog,
                  "Please Enter valid Alert Message for Humidity Over Temp"
                );
                saveclick.className =
                  "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
            }
          }
        }

        if (this.checkDCT_Humidity2 == true) {
          if (
            this.devicefloorTempeditForm.value.humiditytemperature2DCT
              .replace(/\s+/g, " ")
              .trim() == ""
          ) {
            generateAlertMessage(
              this.dialog,
              "Please Enter Temperature for Humidity Under Temp"
            );
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (
            !/^[a-zA-Z0-9\\/*+;&%?#@!^()_="\-:~`|[\]{}\s]*$/i.test(
              this.devicefloorTempeditForm.value.humiditytemperature2DCT
            )
          ) {
            generateAlertMessage(
              this.dialog,
              "Please Enter valid Temperature for Humidity Under Temp"
            );
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else if (
            this.devicefloorTempeditForm.value.humiditytemperature2DCT < 0 ||
            this.devicefloorTempeditForm.value.humiditytemperature2DCT > 100
          ) {
            generateAlertMessage(
              this.dialog,
              "Invalid Range, Humidity Under Temperature should be in between 0 and 100"
            );
            saveclick.className = "clsdevicetemp_editbutton mat-raised-button";
            return false;
          } else {
            overtempvalue =
              this.devicefloorTempeditForm.value.humiditytemperature1DCT;
            undertempvalue =
              this.devicefloorTempeditForm.value.humiditytemperature2DCT;
            this.oldUnderTemp =
              this.devicefloorTempeditForm.value.humiditytemperature2DCT;
            if (
              overtempvalue != 0 &&
              parseFloat(overtempvalue) <= parseFloat(undertempvalue) &&
              this.humidityoverTempflag == 2
            ) {
              generateAlertMessage(
                this.dialog,
                "Humidity Under Temp should be lesser than the Humidity Over Temp"
              );
              saveclick.className =
                "clsdevicetemp_editbutton mat-raised-button";
              return false;
            } else if (
              this.devicefloorTempeditForm.value.humiditytemperature2DCT != ""
            ) {
              if (this.devicefloorTempeditForm.value.alertmessage9DCT == null) 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Under Temp");
                saveclick.className ="clsdevicetemp_editbutton mat-raised-button";
                return false;
              } 
              else if (this.devicefloorTempeditForm.value.alertmessage9DCT.replace(/\s+/g, " ").trim() == "") 
              {
                generateAlertMessage(this.dialog,"Please Enter Alert Message for Humidity Under Temp");
                saveclick.className ="clsdevicetemp_editbutton mat-raised-button";
                return false;
              } 
              else if (
                !/^[a-zA-Z0-9 ]+$/.test(
                  this.devicefloorTempeditForm.value.alertmessage9DCT
                )
              ) {
                generateAlertMessage(
                  this.dialog,
                  "Please Enter valid Alert Message for Humidity Under Temp"
                );
                saveclick.className =
                  "clsdevicetemp_editbutton mat-raised-button";
                return false;
              }
            }
          }
        }
      } else if (deviceFormValue.devicename == DeviceTypeDesc.UM) {
        devicetype = DeviceType.UM;
        DeviceId = deviceFormValue.devicetypeid;
        emitterId = deviceFormValue.deviceid;
          }
          
          else if (deviceFormValue.devicename == DeviceTypeDesc.Intercom)
          {
              devicetype = DeviceType.IC;
              DeviceId = deviceFormValue.deviceid;
                             
          }
          
          else if (deviceFormValue.devicename == DeviceTypeDesc.Room)
        devicetype = DeviceType.Room;
      else if (deviceFormValue.devicename == DeviceTypeDesc.Pin)
        devicetype = DeviceType.Pin;
      else if (deviceFormValue.devicename == DeviceTypeDesc.FloorPlanNotes)
        devicetype = DeviceType.FloorPlanNotes;

     
      
     
        
       
     
       

      let DCTag = 0;
      if (this.checkDCTag == true) DCTag = 1;
      let DCLotiering = 0;
      if (this.checkDCLotiering == true) DCLotiering = 1;
      let DCUnauthorizedEgress = 0;
      if (this.checkDCUnauthorizedEgress == true) DCUnauthorizedEgress = 1;

      let PCRemovalDetection = 0;
      if (this.checkPCRemovalDetection == true) PCRemovalDetection = 1;

      let PCButtonPress = 0;
      if (this.checkPCButtonPress == true) PCButtonPress = 1;

      let PullCord = 0;
      if (this.checkPullCord == true) PullCord = 1;

      let DCT_EnableInput1 = 0;
      if (this.checkDCT_Tag1 == true) DCT_EnableInput1 = 1;

      let DCT_EnableInput2 = 0;
      if (this.checkDCT_Tag2 == true) DCT_EnableInput2 = 1;

      let DCT_EnableInput3 = 0;
      if (this.checkDCT_Tag3 == true) DCT_EnableInput3 = 1;

      let DCT_AmbientOverTemp1 = 0;
      if (this.checkDCT_Ambient1 == true) DCT_AmbientOverTemp1 = 1;

      let DCT_AmbientOverTemp2 = 0;
      if (this.checkDCT_Ambient2 == true) DCT_AmbientOverTemp2 = 1;

      let DCT_ProbeOverTemp = 0;
      if (this.checkDCT_Probe1 == true) DCT_ProbeOverTemp = 1;

      let DCT_ProbeUnderTemp = 0;
      if (this.checkDCT_Probe2 == true) DCT_ProbeUnderTemp = 1;

      let DCT_HumidityOverTemp = 0;
      if (this.checkDCT_Humidity1 == true) DCT_HumidityOverTemp = 1;

      let DCT_HumidityUnderTemp = 0;
      if (this.checkDCT_Humidity2 == true) DCT_HumidityUnderTemp = 1;

      let alertmessage1DCT = "";
      let alertmessage2DCT = "";
      let alertmessage3DCT = "";
      let alertmessage4DCT = "";
      let alertmessage5DCT = "";
      let alertmessage6DCT = "";
      let alertmessage7DCT = "";
      let alertmessage8DCT = "";
      let alertmessage9DCT = "";
      let temperature1DCT = "";
      let temperature2DCT = "";
      let probetemperature1DCT = "";
      let probetemperature2DCT = "";
      let humiditytemperature1DCT = "";
      let humiditytemperature2DCT = "";
      let selectAlertState1;
      let selectAlertState2;
      let selectAlertState3;
      let input1_StartHr = 0;
      let input1_StartMin = 0;      
      let input1_EndHr = 0;
      let input1_EndMin = 0;      
      let input1_checkAllDay = 0;                      
      let input2_StartHr = 0;
      let input2_StartMin = 0;      
      let input2_EndHr = 0;
      let input2_EndMin = 0;
      let input2_checkAllDay = 0;                
      let input3_StartHr = 0;
      let input3_StartMin = 0;
      let input3_EndHr = 0;
      let input3_EndMin = 0;
      let input3_checkAllDay = 0;                
      let starttime1 = 0;
      let endtime1 = 0;
      let starttime2 = 0;
      let endtime2 = 0;
      let starttime3 = 0;
      let endtime3 = 0;

      if (deviceFormValue.devicename == DeviceTypeDesc.UT) {
        if (deviceFormValue.alertmessage1DCT != null)
          alertmessage1DCT = deviceFormValue.alertmessage1DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.alertmessage2DCT != null)
          alertmessage2DCT = deviceFormValue.alertmessage2DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.alertmessage3DCT != null)
          alertmessage3DCT = deviceFormValue.alertmessage3DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.alertmessage4DCT != null)
          alertmessage4DCT = deviceFormValue.alertmessage4DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.alertmessage5DCT != null)
          alertmessage5DCT = deviceFormValue.alertmessage5DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.alertmessage6DCT != null)
          alertmessage6DCT = deviceFormValue.alertmessage6DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.alertmessage7DCT != null)
          alertmessage7DCT = deviceFormValue.alertmessage7DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.alertmessage8DCT != null)
          alertmessage8DCT = deviceFormValue.alertmessage8DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.alertmessage9DCT != null)
          alertmessage9DCT = deviceFormValue.alertmessage9DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.temperature1DCT != null)
          temperature1DCT = deviceFormValue.temperature1DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.temperature2DCT != null)
          temperature2DCT = deviceFormValue.temperature2DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.probetemperature1DCT != null)
          probetemperature1DCT = deviceFormValue.probetemperature1DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.probetemperature2DCT != null)
          probetemperature2DCT = deviceFormValue.probetemperature2DCT
            .replace(/\s+/g, " ")
            .trim();

        if (deviceFormValue.humiditytemperature1DCT != null)
          humiditytemperature1DCT = deviceFormValue.humiditytemperature1DCT
            .replace(/\s+/g, " ")
            .trim();
        if (deviceFormValue.humiditytemperature2DCT != null)
          humiditytemperature2DCT = deviceFormValue.humiditytemperature2DCT
            .replace(/\s+/g, " ")
            .trim();
            
        if (this.checkallinfo1 == true) input1_checkAllDay = 1;
        else input1_checkAllDay = 0;
          
        if (deviceFormValue.wr_start1 != undefined && deviceFormValue.wr_start1 != 0) {
          starttime1 = deviceFormValue.wr_start1.split(":");
          input1_StartHr = parseInt(starttime1[0]);
          input1_StartMin = starttime1[1].split(" ");
          input1_StartMin = parseInt(input1_StartMin[0]);
        }
        if (deviceFormValue.wr_end1 != undefined && deviceFormValue.wr_end1 != 0) {
          endtime1 = deviceFormValue.wr_end1.split(":");
          input1_EndHr = parseInt(endtime1[0]);
          input1_EndMin = endtime1[1].split(" ");
          input1_EndMin = parseInt(input1_EndMin[0]);
        }            
        
        if (this.checkallinfo2 == true) input2_checkAllDay = 1;
            else input2_checkAllDay = 0;
            
        if (deviceFormValue.wr_start2 != undefined && deviceFormValue.wr_start2 != 0) {
          starttime2 = deviceFormValue.wr_start2.split(":");
          input2_StartHr = parseInt(starttime2[0]);
          input2_StartMin = starttime2[1].split(" ");
          input2_StartMin = parseInt(input2_StartMin[0]);
        }
        if (deviceFormValue.wr_end2 != undefined && deviceFormValue.wr_end2 != 0) {
          endtime2 = deviceFormValue.wr_end2.split(":");
          input2_EndHr = parseInt(endtime2[0]);
          input2_EndMin = endtime2[1].split(" ");
          input2_EndMin = parseInt(input2_EndMin[0]);
        }
        
        if (this.checkallinfo3 == true) input3_checkAllDay = 1;
            else input3_checkAllDay = 0;
            
        if (deviceFormValue.wr_start3 != undefined && deviceFormValue.wr_start3 != 0) {
          starttime3 = deviceFormValue.wr_start3.split(":");
          input3_StartHr = parseInt(starttime3[0]);
          input3_StartMin = starttime3[1].split(" ");
          input3_StartMin = parseInt(input3_StartMin[0]);
        }
        if (deviceFormValue.wr_end3 != undefined && deviceFormValue.wr_end3 != 0) {
          endtime3 = deviceFormValue.wr_end3.split(":");
          input3_EndHr = parseInt(endtime3[0]);
          input3_EndMin = endtime3[1].split(" ");
          input3_EndMin = parseInt(input3_EndMin[0]);
        }   
            
      }
      selectAlertState1 = this.edit_selectedAlertState1;
      selectAlertState2 = this.edit_selectedAlertState2;
      selectAlertState3 = this.edit_selectedAlertState3;
      // if (selectAlertState1 != 0)
      selectAlertState1 = parseInt(selectAlertState1);

      // if (selectAlertState2 != 0)
      selectAlertState2 = parseInt(selectAlertState2);

      // if (selectAlertState3 != 0)
      selectAlertState3 = parseInt(selectAlertState3);
      
      if(this.selectresCategoryId != undefined)
      {
        this.selectresCategoryId = this.selectresCategoryId.toString();
      }
      else
        this.selectresCategoryId = "";

      const editdevicedetails: updateTemplateDevice = {
        authKey: this.authkey,
        templateId: parseInt(this.Template_Id),
        deviceType: devicetype.toString(),
        dataId: parseInt(deviceFormValue.dataId),
        description: deviceFormValue.description.toString(),
        resRoomCategory: this.selectresCategoryId.toString(),
        macId: deviceMacId,
        isTagidfield: DCTag,
        isLotiering: DCLotiering,
        isAuthorized: DCUnauthorizedEgress,
        isRemovedetection: PCRemovalDetection,
        isButtonpress: PCButtonPress,
        isPullcord: PullCord,
        isMobilitymode: this.checkPCMobilityMode,
        enableInput1: DCT_EnableInput1,
        alertState1: selectAlertState1,
        alertMessage1: alertmessage1DCT,
        enableInput2: DCT_EnableInput2,
        alertState2: selectAlertState2,
        alertMessage2: alertmessage2DCT,
        enableInput3: DCT_EnableInput3,
        alertState3: selectAlertState3,
        alertMessage3: alertmessage3DCT,
        ambientOverTemp: DCT_AmbientOverTemp1,
        ambientOverDesc: temperature1DCT,
        ambientOverMessage: alertmessage4DCT,
        ambientUnderTemp: DCT_AmbientOverTemp2,
        ambientUnderDesc: temperature2DCT,
        ambientUnderMessage: alertmessage5DCT,
        probeOverTemp: DCT_ProbeOverTemp,
        probeOverDesc: probetemperature1DCT,
        probeOverMessage: alertmessage6DCT,
        probeUnderTemp: DCT_ProbeUnderTemp,
        probeUnderDesc: probetemperature2DCT,
        probeUnderMessage: alertmessage7DCT,
        humidityOverTemp: DCT_HumidityOverTemp,
        humidityOverDesc: humiditytemperature1DCT,
        humidityOverMessage: alertmessage8DCT,
        humidityUnderTemp: DCT_HumidityUnderTemp,
        humidityUnderDesc: humiditytemperature2DCT,
        humidityUnderMessage: alertmessage9DCT,
        dctId: this.DCT_Id,
        input1StartHr: input1_StartHr,
        input1StartMin: input1_StartMin,
        input1EndHr: input1_EndHr,
        input1EndMin: input1_EndMin,
        input1checkAllDay: input1_checkAllDay,        
        input2StartHr: input2_StartHr,
        input2StartMin: input2_StartMin,
        input2EndHr: input2_EndHr,
        input2EndMin: input2_EndMin,
        input2checkAllDay: input2_checkAllDay,        
        input3StartHr: input3_StartHr,
        input3StartMin: input3_StartMin,
        input3EndHr: input3_EndHr,
        input3EndMin: input3_EndMin,
        input3checkAllDay: input3_checkAllDay 
      };
      this.technologyService
        .updateTemplateDeviceInfo(editdevicedetails)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            generateAlertMessage(this.dialog, appsettings.SaveDevice);
            this.thisDialogRef.close("flooreditclose");
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Device details edited failed.");
            this.thisDialogRef.close("Cancel");
          }
        });
    } catch (err) {
      throw new Error(
        "edit-device.component.ts" +
          " - updateTemplateDeviceInfo() - " +
          err.message
      );
    }
  }

  togglerDCTag(e) {
    if (e.checked == true) {
      this.checkDCTag = true;
    } else {
      this.checkDCTag = false;
    }
  }

  togglerDCLotiering(e) {
    if (e.checked == true) {
      this.checkDCLotiering = true;
    } else {
      this.checkDCLotiering = false;
    }
  }

  togglerDCUnauthorizedEgress(e) {
    if (e.checked == true) {
      this.checkDCUnauthorizedEgress = true;
    } else {
      this.checkDCUnauthorizedEgress = false;
    }
  }

  togglerPCJackRemovalDetection(e) {
    if (e.checked == true) {
      this.checkPCRemovalDetection = true;
    } else {
      this.checkPCRemovalDetection = false;
    }
  }

  togglerPCJackButtonPress(e) {
    if (e.checked == true) {
      this.checkPCButtonPress = true;
    } else {
      this.checkPCButtonPress = false;
    }
  }

  togglerPullCord(e) {
    if (e.checked == true) {
      this.checkPullCord = true;
    } else {
      this.checkPullCord = false;
    }
  }

  selectPCMode(event) {
    this.checkPCMobilityMode = event;
  }

  togglerDCT_Tag(id,e) {    
    if (e.checked == true && id == 1) {
      this.checkDCT_Tag1=true;    
      this.edit_selectedAlertState1 = this.oldedit_selectedAlertState1; 
      this.alertMessage_input1 =  this.oldalertMessage1;  
      this._egressenablehourmin1 = true;               
    }   
    else if (e.checked == true && id == 2) {     
      this.checkDCT_Tag2=true;     
      this.edit_selectedAlertState2 = this.oldedit_selectedAlertState2; 
      this.alertMessage_input2 =  this.oldalertMessage2;               
      this._egressenablehourmin2 = true;  
    }    
    else if (e.checked == true && id == 3) {
      this.checkDCT_Tag3=true;     
      this.edit_selectedAlertState3 = this.oldedit_selectedAlertState3; 
      this.alertMessage_input3 =  this.oldalertMessage3; 
      this._egressenablehourmin3 = true;               
    }
    else if(e.checked == false && id == 1){
      this.checkDCT_Tag1=false;    
      this.edit_selectedAlertState1 = 0; 
      this.alertMessage_input1 = ""; 
      this._egressenablehourmin1 = false;  
      this.checkallinfo1 = false;
      this.data_startTime1 = 0;      
      this.data_endTime1 = 0;
    }
    else if(e.checked == false && id == 2){
      this.checkDCT_Tag2=false;    
      this.edit_selectedAlertState2 = 0; 
      this.alertMessage_input2 = ""; 
      this._egressenablehourmin2 = false;  
      this.checkallinfo2 = false;
      this.data_startTime2 = 0;      
      this.data_endTime2 = 0;
   }
    else if(e.checked == false && id == 3){
      this.checkDCT_Tag3=false; 
      this.edit_selectedAlertState3 = 0; 
      this.alertMessage_input3 = "";  
      this._egressenablehourmin3 = false;  
      this.checkallinfo3 = false;  
      this.data_startTime3 = 0;      
      this.data_endTime3 = 0;
    }    
  }

togglerDCT_Ambient(id,event) {    
  if (event.checked == true && id == 1) {
    this.checkDCT_Ambient1=true;   
    this.ambientOverDesc = this.oldOverTemp;
    this.ambientOverMessage = this.oldambientOverMessage;         
  }   
  else if (event.checked == true && id == 2) {     
    this.checkDCT_Ambient2=true;     
    this.ambientUnderDesc = this.oldUnderTemp;
    this.ambientUnderMessage = this.oldambientUnderMessage;
  }
  else if (event.checked == false && id == 1) {
    this.checkDCT_Ambient1=false;            
    this.ambientOverDesc = "";
    this.ambientOverMessage = "";
  }
  else if (event.checked == false && id == 2) {
    this.checkDCT_Ambient2=false;   
    this.ambientUnderDesc = "";
    this.ambientUnderMessage = "";
  
  } 
}

  selectDCTAlertState(ID, Event) {
    if (ID == 1) this.edit_selectedAlertState1 = Event;
    else if (ID == 2) this.edit_selectedAlertState2 = Event;
    else if (ID == 3) this.edit_selectedAlertState3 = Event;
  }

togglerDCT_Probe(id,event) {    
  if (event.checked == true && id == 1) {
    this.checkDCT_Probe1=true;            
    this.probeOverDesc = this.oldProbeOverTemp;
    this.probeOverMessage = this.oldprobeOverMessage;
  }   
  else if (event.checked == true && id == 2) {     
    this.checkDCT_Probe2=true;     
    this.probeUnderDesc = this.oldProbeUnderTemp;
    this.probeUnderMessage = this.oldprobeUnderMessage;
  }
  else if (event.checked == false && id == 1) {
    this.checkDCT_Probe1=false;  
    this.probeOverDesc = "";
    this.probeOverMessage = "";                
  }
  else if (event.checked == false && id == 2) {
    this.checkDCT_Probe2=false;         
    this.probeUnderDesc = "";
    this.probeUnderMessage = "";
  } 
}

togglerDCT_Humidity(id,event) {    
  if (event.checked == true && id == 1) {
    this.checkDCT_Humidity1=true;            
    this.humidityOverDesc = this.oldHumidityOverTemp;
    this.humidityOverMessage = this.oldhumidityOverMessage;
  }   
  else if (event.checked == true && id == 2) {     
    this.checkDCT_Humidity2=true;     
    this.humidityUnderDesc = this.oldHumidityUnderTemp;
    this.humidityUnderMessage = this.oldhumidityUnderMessage;
  }
  else if (event.checked == false && id == 1) {
    this.checkDCT_Humidity1=false; 
    this.humidityOverDesc = "";
    this.humidityOverMessage = "";                 
  }
  else if (event.checked == false && id == 2) {
    this.checkDCT_Humidity2=false;   
    this.humidityUnderDesc = "";
    this.humidityUnderMessage = "";      
  } 
}

toggleinfoVisibility(event,enableType) {
  if(enableType == 1)
  {
    if (event.checked == true) 
    {
      this._egressenablehourmin1 = false;
      this.checkallinfo1 = true;   
      this.data_startTime1 = 0;      
      this.data_endTime1 = 0;     
    } else 
    {
      this._egressenablehourmin1 = true;        
      this.checkallinfo1 = false;
      this.data_startTime1 = 0;      
      this.data_endTime1 = 0;
    }
  }
  else if(enableType == 2)
  {
    if (event.checked == true) 
    {
      this._egressenablehourmin2 = false;
      this.checkallinfo2 = true;    
      this.data_startTime2 = 0;      
      this.data_endTime2 = 0;    
    } else 
    {
      this._egressenablehourmin2 = true;        
      this.checkallinfo2 = false;
      this.data_startTime2 = 0;      
      this.data_endTime2 = 0;
    }
  }
  else if(enableType == 3)
  {
    if (event.checked == true) 
    {
      this._egressenablehourmin3 = false;
      this.checkallinfo3 = true;  
      this.data_startTime3 = 0;      
      this.data_endTime3 = 0;      
    } else 
    {
      this._egressenablehourmin3 = true;        
      this.checkallinfo3 = false;
      this.data_startTime3 = 0;      
      this.data_endTime3 = 0;
    }
  }
}

}
