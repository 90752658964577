
<perfect-scrollbar style="max-width: 550px; max-height: 400px;" >
  <div class="clsaddstaff">
      <div class="add-title">
        <h3 class="addstaffHeader" style="margin: 0 0 10px 11px;">OTP Verification
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider>
      </div>
      <div *ngIf="data.StaffFlag == 0; else staffEmailVerification">
        <form style="padding:24px" [formGroup]="staffVerificationForm" autocomplete="off" novalidate (ngSubmit)="VerifyStaffNumber(staffVerificationForm.value)">
  
            <mat-grid-list [cols]="columns" rowHeight="65px">  
                <mat-grid-tile>
                    <label>Country Code </label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="Country Code" formControlName="contrycode" [(ngModel)]="countrycode">                                                  
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

                <mat-grid-tile>
                    <label>Phone Number</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="Phone Number" formControlName="staffphonenumber" [(ngModel)]="staffphonenumber">
                        </mat-form-field>
                      </div>
                </mat-grid-tile>                

              <mat-grid-tile>
                <label>Enter OTP Number</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="OTP Number" formControlName="otpnumber"  maxlength="4">   
                      <mat-error *ngIf="staffVerificationForm.controls.otpnumber.hasError('required')">Please enter OTP number</mat-error>                 
                    </mat-form-field>
                  </div>
            </mat-grid-tile>                          

          </mat-grid-list>

            <mat-dialog-actions class="clsbuttoncontrol">    
              <button class="clsverifybutton" mat-raised-button [disabled]="!staffVerificationForm.valid">Submit</button>&nbsp;             
            </mat-dialog-actions>
        </form>
      </div>

      <ng-template #staffEmailVerification> 
      <div>
        <form style="padding:24px" [formGroup]="staffVerificationForm" autocomplete="off" novalidate (ngSubmit)="VerifyStaffNumber(staffVerificationForm.value)">
  
            <mat-grid-list [cols]="columns" rowHeight="65px">                  

                <mat-grid-tile>
                    <label>Email Address</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="Phone Number" formControlName="staffphonenumber" [(ngModel)]="staffphonenumber">
                        </mat-form-field>
                      </div>
                </mat-grid-tile>                

              <mat-grid-tile>
                <label>Enter OTP Number</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <div class="text-inside" style="width:100%;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;" maxlength="4">
                      <input matInput placeholder="OTP Number" formControlName="otpnumber">                    
                      <mat-error *ngIf="staffVerificationForm.controls.otpnumber.hasError('required')">Please enter OTP number</mat-error>
                    </mat-form-field>                    
                  </div>
            </mat-grid-tile>                          

          </mat-grid-list>

            <mat-dialog-actions class="clsbuttoncontrol">    
              <button class="clsverifybutton" mat-raised-button [disabled]="!staffVerificationForm.valid">Submit</button>&nbsp;             
            </mat-dialog-actions>
        </form>
      </div>
      </ng-template>

  </div>
</perfect-scrollbar>
