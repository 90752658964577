<meta name="format-detection" content="telephone=no">
<app-resident-header></app-resident-header>
<mat-card class="home-mat-card" (click)="CloseRightNav()">
<div class="mat-elevation-z0" style="margin-top:0px;" id="divresidentdetails">

<div class="landing-title-bar">
<div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutGap="5px" fxLayoutWrap >
  <div fxFlex="15" fxFlex.md="20" class="blocks" style="padding-top:5px;"> 
      <span class="clsTitle">Total Resident : </span>
      <span class="clsTitle1" id="residentcount">{{dataSourcecount}}</span>   
   </div>
   <div fxFlex="20"> 
      <mat-form-field  appearance="outline" floatLabel="auto">
          <mat-select style="height:20px;" placeholder="Select" (selectionChange)="selectresStatus($event.value)" [(ngModel)]="residentstatus">                                                 
              <mat-option *ngFor="let Status of Residentstatus" [value]="Status.Value">
                  {{Status.Name}}
              </mat-option>                      
        </mat-select>
    </mat-form-field>    
   </div>
   <div fxFlex="20"> 
      <mat-form-field appearance="outline" floatLabel="auto">
          <input style="height:20px;" matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchResident($event.target.value)" autocomplete="off" placeholder="Search">
          <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>    
   </div>
   <div fxFlex="55" class="blocks" fxLayout.md="row" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutWrap>      
      <button id="btnresgroup" class="clsaddgroup" color="primary" mat-raised-button (click)="ClickResidentGroup()" *ngIf="bGroupView == true">Resident Group</button>
      <button id="btnresdata" class="clsresidentbutton" color="primary" mat-raised-button (click)="addResidentdata()" *ngIf="bAddEdit == true">Add</button>
      <button id="downloadresdata" class="clsresidentbutton" color="primary"  mat-raised-button (click)="downloadResidentdata()">Download</button>
   </div>
</div>
</div>

<perfect-scrollbar class="scrollbar">
<div *ngIf="dataSourcecount; else noRecord">
<table mat-table [dataSource]="dataSource" matSort matSortDisableClear=true>
  <!-- Position Column -->
  <ng-container matColumnDef="photo">
      <th mat-header-cell *matHeaderCellDef > Photo </th>
      <td mat-cell *matCellDef="let element"> <img style="width:30px; height:30px; flex-shrink: 0; -o-object-fit: contain; object-fit: contain;" [src]= "residentImgUrl + element.photo"/></td>
    </ng-container>  
  
    <!-- Weight Column -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:220px;"> Resident Name </th>
      <td mat-cell *matCellDef="let element"> 
        <span *ngIf="element.Name.length < 30">{{element.Name}}</span>
        <span *ngIf="element.Name.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.Name}} #tooltip="matTooltip" matTooltipPosition="above">
           {{element.Name.slice(0,20) + ".."}}
        </span>       
      </td>
    </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="UnitName">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Assigned Room </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.UnitName.length < 15">{{element.UnitName}}</span>
          <span *ngIf="element.UnitName.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.UnitName}} #tooltip="matTooltip" matTooltipPosition="above">
            {{element.UnitName.slice(0,15) + ".."}}
          </span>        
           </td>
      </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="TagId">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Tag ID </th>
      <td mat-cell *matCellDef="let element"> {{element.TagId}} </td>
    </ng-container>
    
    <!-- Symbol Column -->
    <ng-container matColumnDef="RoomName" >
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> TruLocation</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.RoomName.length < 15"> {{element.RoomName}} </span>
        <span *ngIf="element.RoomName.length >= 15" matTooltipClass="devicetooltip" matTooltip={{element.RoomName}} #tooltip="matTooltip" matTooltipPosition="above">
          {{element.RoomName.slice(0,15) + ".."}}
        </span>
      </td>
    </ng-container>     
    
     <!-- Symbol Column -->
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Status </th>
      <ng-container *matCellDef="let element">
      <td mat-cell *ngIf="element.Status==residentStatus.Current" [ngStyle]="{'color':'#71B919'}">{{EnumResidentStatus.Current}}</td>
       <td mat-cell *ngIf="element.Status==residentStatus.Deceased" [ngStyle]="{'color':'#909290'}">{{EnumResidentStatus.Deceased}}</td>
      <td mat-cell *ngIf="element.Status==residentStatus.Released" [ngStyle]="{'color':'Red'}">{{EnumResidentStatus.Released}}</td>     
      <td mat-cell *ngIf="element.Status==null">-</td> 
      </ng-container>
      
    </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="isWanderMode" >
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Wander Enabled </th>
        <ng-container *matCellDef="let element">    
          <td mat-cell *ngIf="element.isWanderMode==1"> <mat-icon style="transform: scale(1.3);color:#5eaa46;margin-left:1.5em;">done</mat-icon> </td>        
          <td mat-cell *ngIf="element.isWanderMode==0"> <img src="assets/images/Release-Delete-Icon.png" style="margin-left:2.5em;"> </td>
        </ng-container>
      </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="medicalhistory">
      <th mat-header-cell *matHeaderCellDef  > Medical History </th>
      <ng-container *matCellDef="let element">      
      <td mat-cell *ngIf="element.Status==1"> <img src="assets/images/Information-Medical_History.png" style="margin-left:2em;cursor:pointer;" (click)="getMedicalHistory(element.ResidentID)"></td> <!----[routerLink]="['/editresident']"-->
      <td mat-cell *ngIf="element.Status!=1"><label style="margin-left:2.7em;">-</label></td> 
    </ng-container>
    </ng-container>
    
     <!-- Symbol Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef style="width:50px;"> Edit </th>
      <ng-container *matCellDef="let element">      
      <td mat-cell *ngIf="element.Status==1 || element.Status==null"><img src="assets/images/Edit_Icon.png" style="cursor:pointer;" (click)="editResidentdata(element.ResidentID)"></td> <!----[routerLink]="['/editresident']"-->
      <td mat-cell *ngIf="element.Status>1">-</td> 
    </ng-container>
    </ng-container>
    
     <!-- Symbol Column -->
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef style="width:30px;"> {{statusdata}} </th>     
      <ng-container *matCellDef="let element">    
      <td mat-cell *ngIf="element.Status==1"> <img src="assets/images/Release-Delete-Icon.png" style="margin-left:1em;cursor:pointer;" (click)="releaseResidentdata(element.ResidentID,Release)"> </td>
      <td mat-cell *ngIf="element.Status!=1"> <img src="assets/images/residentresote.png" style="margin-left:1em;cursor:pointer;" (click)="releaseResidentdata(element.ResidentID,Reallocate)"> </td>
    </ng-container></ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

</div>
</perfect-scrollbar>
<ng-template #noRecord>  
     <mat-card>
       <h3 *ngIf="!resloading" style="text-align: center;"> No Record found </h3>   
       <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%">
        <!-- <mat-spinner diameter="50" strokeWidth="5"></mat-spinner> -->
        <div class="loadspinner" *ngIf="resloading">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
      </div>        
     </mat-card>
</ng-template>

<mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
</div>
</mat-card>