<perfect-scrollbar style="max-width: 1200px; max-height: 700px;" >
  <div class="clseditconfig">
     
              <div class="editConfig-title">
                <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editConfigHeader" style="margin: 0 0 10px 11px;">Configuration Settings
                <span><img class="clseditconfigcloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
                <mat-divider ></mat-divider>
              </div>
                     
              <form  style="padding:24px;"  [formGroup]="configeditForm" autocomplete="off" novalidate (ngSubmit)="oneditConfigdetails(configeditForm.value)">     
          
                <div class="content" fxLayout="row" fxLayout.md="column" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
                    
                  <div fxFlex="65" fxFlex.md="25"> 
              
                  <mat-grid-list [cols]="firstcolumns" [rowHeight]="editConfigrowHeight">                             
      
                      <mat-grid-tile>
                          <label>Board Name </label>
                      </mat-grid-tile>
                      <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                              <div class="text-inside" style="width:100%;">
                                  <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                                 <input matInput placeholder="Board Name" formControlName="boardname" maxlength="32" [(ngModel)]="data.BoardName" (keyup)="handleInput($event)"/>                                 
                              </mat-form-field>
                            </div>
                      </mat-grid-tile>  
                      
                      <mat-grid-tile>
                        <label>IP Address </label>
                    </mat-grid-tile>
                    <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                            <div class="text-inside" style="width:100%;">
                               <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                               <input matInput placeholder="IP Address" formControlName="ipaddress" maxlength="32" [(ngModel)]="data.IPAddress" (keyup)="handleInput($event)" required/>
                               </mat-form-field>
                          </div>
                    </mat-grid-tile> 
                  <mat-grid-tile>
                      <label>Port</label>
                  </mat-grid-tile>
                  <mat-grid-tile class="clsmatgridtxtCtrl_Config">
                          <div class="text-inside" style="width:100%;">
                          <mat-form-field appearance="outline" floatLabel="auto" class="clsconfigmatfield">
                            <input matInput placeholder="Port" maxlength="32" formControlName="udpport" [(ngModel)]="data.Port" (keyup)="handleInput($event)" required/>      
                          </mat-form-field>
                        </div>
                  </mat-grid-tile>                           
                  </mat-grid-list> 
                  <input type="hidden" formControlName="hdnFileName" [(ngModel)]="data.FileName">   
                  <input type="hidden" formControlName="hdnBoardName" [(ngModel)]="data.BoardName">
                  <input type="hidden" formControlName="hdnIpAddress" [(ngModel)]="data.IPAddress">
                  <input type="hidden" formControlName="hdnUdpPort" [(ngModel)]="data.Port">          
                </div>
                                 
                <div fxFlex="45" fxFlex.md="45" style="margin-top:1em;">
                  <mat-grid-list [cols]="columns" class="clsmatconfiggridlist">
                      <perfect-scrollbar style="max-width: 800px; max-height: 500px;" >                        
                        <div class="text-inside" style=" margin-right:1em; display: flex;">  
                          <table mat-table [dataSource]="dataSource">   
                            <ng-container matColumnDef="RoomId">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [ngClass]="'headercell'" > Tektone RM# </th>
                              <td mat-cell *matCellDef="let element"> {{element.RoomId}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Event">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [ngClass]="'headercell'"> Event Name </th>
                              <td mat-cell *matCellDef="let element"> {{element.Event}} </td>
                            </ng-container>
                            <ng-container matColumnDef="SpaceName">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [ngClass]="'headercell'">TruView Room </th>
                              <td mat-cell *matCellDef="let element"> {{element.SpaceName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Index">
                              <th mat-header-cell *matHeaderCellDef class="clseditheader_Config" [style.display]="'none'" [ngClass]="'headercell'" > Index </th>
                              <td [style.display]="'none'" mat-cell *matCellDef="let element"> {{element.Index}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>                                                                          
                        </div>   
                      </perfect-scrollbar>
                  </mat-grid-list>
                </div>
                  
                </div>
                 <mat-dialog-actions style="float:left;">              
                  <button type="button" class="clsconfigdltbutton" mat-raised-button (click)="opendeleteconfigdialog()" tabindex="7">Delete</button> 
                </mat-dialog-actions>
              </form>
  </div>
</perfect-scrollbar>