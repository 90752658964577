<app-technologyoverlay-header></app-technologyoverlay-header>
  <!-- <mat-card> -->
  <div *ngIf="templateloading" style="display: table;
    position: absolute;
    height:100%; 
    width:100%;  
    z-index: 1000000;
    text-align: center;
">
<div style="display: table-cell;
    vertical-align: middle;">
    <div style="margin-left: calc(50% - 41px);
    
    color: White;">
       <div class="loadspinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
</div>
</div>
  <!-- </mat-card> -->
<mat-card class="technologyoverlay-mat-card">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-technologyoverlay-leftbar></app-technologyoverlay-leftbar>
    </div>
    
    
    <div fxLayout="column" fxFlex="100" id="divfloorplandetails">

    <perfect-scrollbar class="clsScrollbar">
    
    
    <div id="Divmatcontent" *ngFor="let Templates1 of TempZoneInfo; let i = index;" style="width:100%;min-width:85%;">
          
      <div [ngStyle]="{'border-bottom': i === 0 ? '2px solid #d5d4d4;' : 'none'}">
        
      <div fxLayout="row">
      
        <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">
          
          <mat-card-actions class="clsmatcardaction">
            <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">Zone Templates ({{ZoneTempCnt}})  
            
            <button mat-button style="float:right;" (click)="clickcollapse(i,$event,1)">
              <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1}}</mat-icon>
              <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon}}</mat-icon>
            </button>     
                        
            </mat-card-title>
          </mat-card-actions>
          
          <mat-card-content *ngIf="checkicon == i ? arrowiconT : arrowiconF">                        
            
            <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
             
              <mat-nav-list *ngFor="let Templates of TempZoneInfo;" class="clsimgnext">
                <mat-list-item>
                <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">                  
                   <div id="container_template">
                     <!-- <img class="clsimage" [src]="SpaceTempImgUrl + Templates.FloorPlanImage" /> -->
                     <div id="mapZone_{{Templates.TemplateId}}" class="clsimage map">
                     </div>
                     <div class="tempeditIcon">
                       <a href="#templates" style="display: inline-grid;">
                         <div style="display:flex;">
                           <img src="assets/images/Edit_Tech.png" class="clseditimg"
                             (click)="editFloorTemplatedetails(Templates)" />
                           <label class="clseditimg"
                             (click)="editFloorTemplatedetails(Templates)">Edit/<br>Delete</label>
                         </div>
                       </a>
                     </div>
                     <div class="tempedit"></div>
                   </div>
                   
                   <h4 class="clsheadertemp" *ngIf="Templates.TemplateName.length < 20 && Templates.Description.length < 20">{{Templates.TemplateName}}-{{Templates.Description}}</h4>                   
                   <h4 class="clsheadertemp" *ngIf="Templates.TemplateName.length >= 20 && Templates.Description.length < 20"><div class="floortooltip">{{Templates.TemplateName.slice(0,10) + ".."}}-{{Templates.Description}}<span class="floortooltiptext">{{Templates.TemplateName}}</span></div></h4>
                   <h4 class="clsheadertemp" *ngIf="Templates.TemplateName.length < 20 && Templates.Description.length >= 20"><div class="floortooltip">{{Templates.TemplateName}}-{{Templates.Description.slice(0,10) + ".."}}<span class="floortooltiptext">{{Templates.Description}}</span></div></h4>
                   <h4 class="clsheadertemp" *ngIf="Templates.TemplateName.length >= 20 && Templates.Description.length >= 20"><div class="floortooltip">{{Templates.TemplateName.slice(0,10) + ".."}}-{{Templates.Description.slice(0,10) + ".."}}<span class="floortooltiptext">{{Templates.TemplateName}}-{{Templates.Description}}</span></div></h4>
                </mat-icon>
              </mat-list-item>
              </mat-nav-list>
              
            </mat-list>                              
            
          </mat-card-content>
        </div>
        
      </div>
      
    </div>
      
    </div>
    
    <div *ngIf="TempZoneInfo.length == 0">
      <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;border-bottom: 2px solid #d5d4d4;">            
        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" >Zone Templates ({{ZoneTempCnt}}) 
          <button mat-button style="float:right;">
            <!-- <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1}}</mat-icon>
            <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon}}</mat-icon> -->
          </button>   
        </mat-card-title>
      </div>
    </div>
    
    
    <div id="Divmatcontentspace" *ngFor="let Templates1 of TempSpaceInfo; let i = index;" style="width:100%;min-width:85%;">
          
      <div [ngStyle]="{'border-bottom': i === 0 ? '2px solid #d5d4d4;' : 'none'}">
        
      <div fxLayout="row">
      
        <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;">
          
          <mat-card-actions class="clsmatcardaction">
            <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" *ngIf="i == 0">Space Templates ({{SpaceTempCnt}})  
            
            <button mat-button style="float:right;" (click)="clickcollapse(i,$event,2)">
              <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1space}}</mat-icon>
              <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIconspace}}</mat-icon>
            </button>     
                        
            </mat-card-title>
          </mat-card-actions>
          
          <mat-card-content *ngIf="checkicon == i ? arrowiconT_space : arrowiconF_space">                        
            
            <mat-list class="clsmatlist" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
             
              <mat-nav-list *ngFor="let Templates of TempSpaceInfo;" class="clsimgnext">
                <mat-list-item>
                <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" class="clsediticon">
                   <div id="container_template">
                    <!-- <img class="clsimage" [src]="SpaceTempImgUrl + Templates.FloorPlanImage" /> -->
                    <div id="mapTemplate_{{Templates.TemplateId}}" class="clsimage map">
                    </div>
                    <div class="tempeditIcon">
                      <a href="#templates" style="display: inline-grid;">
                        <div style="display:flex;">
                          <img src="assets/images/Edit_Tech.png" class="clseditimg"
                            (click)="editSpaceTempdetails(Templates)" />
                          <label class="clseditimg"
                            (click)="editSpaceTempdetails(Templates)">Edit/<br>Delete</label>
                        </div>
                      </a>
                    </div>
                    <div class="tempedit" (click)="getBuilding(Templates.TemplateId, Templates.TemplateName)"></div>
                  </div>                   
                   <h4 class="clsheadertemp" *ngIf="Templates.TemplateName.length < 20 && Templates.Description.length < 20">{{Templates.TemplateName}}-{{Templates.Description}}</h4>                   
                   <h4 class="clsheadertemp" *ngIf="Templates.TemplateName.length >= 20 && Templates.Description.length < 20"><div class="floortooltip">{{Templates.TemplateName.slice(0,10) + ".."}}-{{Templates.Description}}<span class="floortooltiptext">{{Templates.TemplateName}}</span></div></h4>
                   <h4 class="clsheadertemp" *ngIf="Templates.TemplateName.length < 20 && Templates.Description.length >= 20"><div class="floortooltip">{{Templates.TemplateName}}-{{Templates.Description.slice(0,10) + ".."}}<span class="floortooltiptext">{{Templates.Description}}</span></div></h4>
                   <h4 class="clsheadertemp" *ngIf="Templates.TemplateName.length >= 20 && Templates.Description.length >= 20"><div class="floortooltip">{{Templates.TemplateName.slice(0,10) + ".."}}-{{Templates.Description.slice(0,10) + ".."}}<span class="floortooltiptext">{{Templates.TemplateName}}-{{Templates.Description}}</span></div></h4>                   
                </mat-icon>
              </mat-list-item>
              </mat-nav-list>
              
            </mat-list>                              
            
          </mat-card-content>
        </div>
        
      </div>
      
    </div>
      
    </div>
    
    <div *ngIf="TempSpaceInfo.length == 0">
      <div fxLayout="column"  fxLayoutAlign="end" style="width: 100%;border-bottom: 2px solid #d5d4d4;">            
        <mat-card-title style="font-size: 20px;margin-top:15px; margin-left: 0.8em;" >Space Templates ({{SpaceTempCnt}}) 
          <button mat-button style="float:right;">
            <!-- <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1}}</mat-icon>
            <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon}}</mat-icon> -->
          </button>   
        </mat-card-title>
      </div>
    </div>
    
      </perfect-scrollbar>
      
      </div>      
    
  </div>
  
</mat-card>
