<mat-toolbar style="padding:0 0 0 16px">

    <div fxShow="true" fxHide.lt-lg="false" fxHide.gt-md="true" id="divmenu" class="clsmenu">
        <button mat-icon-button (click)="clkMenusideTechnav()" fxShow="true" fxHide.lt-lg="false" fxHide.gt-md="true">
      <mat-icon>menu</mat-icon>
    </button>
    </div>

    <div class="flex-container" fxLayout="row" fxLayoutGap="15px" fxShow="true" fxHide.md fxHide.sm fxHide.xs (click)="closeSidebarnav()">
        <mat-form-field [ngStyle]="{'display': checkresidentsearch == '0' ? 'none' : 'block'}" appearance="outline" floatLabel="auto" class="clsmatformfield1">
            <input type="text" placeholder="Search Individual" matInput [formControl]="myControl" maxlength="30" [matAutocomplete]="auto" (keyup.enter)="selectoption($event)">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="selectSearch(option)">
                    {{option.ResidentName}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div [ngStyle]="{'display': checkresidentsearch == '1' ? 'none' : 'block'}" mat-line class="clsmatformfield clsdivcheckResident">
        </div>

        <mat-form-field appearance="outline" floatLabel="auto" class="clsmatformfield" (click)="clkmatselect()">                       
            <input type="text" placeholder="Search Location" [(ngModel)]="selectedMapIds" matInput id="txtmapid">
            <mat-icon matSuffix id="downmaticon">arrow_drop_down</mat-icon>
        </mat-form-field>

        <div class="clspopupMapdetails" [ngStyle]="{'display': homeHeaderReference.checkmatselect == 0 ? 'none' : 'block'}"> 
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectMapdetails" (change)="clkmatradiomapdata($event)">
              <perfect-scrollbar style="max-width: 485px; max-height: 320px;" >
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">              
              <mat-nested-tree-node *matTreeNodeDef="let node;when: hasChild">
                <li>
                  <div class="mat-tree-node" *ngIf="node.isType != 2" [ngStyle]="{'margin-left': node.isType == 1 ? '0.5em' : '2em'}">
  
                        <mat-radio-button class="clsbtnmatradio" [value]="node.id" [ngStyle]="{'margin-left': node.isType == 4 ? '2.5em' : '0em'}">{{node.name}} </mat-radio-button>   
                        <button class="clsmaticonexpand" *ngIf="node.isType == 3 && node.isSubZone > 0" mat-icon-button matTreeNodeToggle 
                            (click)="clickcollapse(node)" [attr.aria-label]="'toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_less' : 'expand_more'}}
                      </mat-icon>
                    </button>
                       
  
                  </div>
                  <span *ngIf="node.isType == 2" class="clsdisable" disabled style="margin-left:2.2em;">{{node.name}}</span>
                        <ul style="padding: 0px;" [class.example-tree-invisible]="!treeControl.isExpanded(node) && node.isType == 3">
                    <label style="margin-left: 3.7em;" *ngIf="node.isType == 3">Sub-Zones(s)</label>
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </mat-tree>
          </perfect-scrollbar>
  
          <mat-dialog-actions class="clsmapdetailscontrol">
                    <button type="button" id="btnLocationClear" class="clsmapdetailsbutton" mat-raised-button (click)="onCloseCancel()">Clear</button>&nbsp;
                    <button type="button" class="clsmapdetailsbutton" mat-raised-button (click)="clkbtnMapDetailsDone()">Done</button>
        </mat-dialog-actions>
  
            </mat-radio-group>
  
  
      </div>
        
        <div  (click)="clkMapOptions()" >
            <img class="avatar clsmapoptions" id="imgMapOptions" src= "/assets/images/reflect.png"> 
            <label class="clslabelmaps" id="lblMapOptions" style="font-size: 14px;">Map Options</label>
        </div>
    
        <div matTooltipClass="tooltip" (click)="home(2)" matTooltip="{{selectedFloor == 0 ? campusResetTooltip :  floorResetTooltip}}" #tooltip="matTooltip" matTooltipPosition="above">
            <img class="avatar clsimgReset" src= "/assets/images/Reset.png"> 
            <label class="clslabelreset" id="lblMapReset" style="font-size: 14px;">Reset</label>
        </div>
        
        <ng-container *ngIf="homeHeaderReference.visible">
            <div class="clspopupMapOptions" id="divmapOptionsPopup">
                <div>
                    <h4 class="clsHeaderLive">Live View Options</h4>    
                    <mat-icon class="material-icons_home"  matTooltipClass="tooltip" matTooltip="{{mapOptionInfoTooltip}}" #tooltip="matTooltip" matTooltipPosition="below">info</mat-icon>            
                    <mat-checkbox class="clscheckboxLiveview clsenable" [disabled]="checkliveresidentdata =='0'" id="chkresident" #resident (change)="toggleresVisibility($event)" [checked]="checkresident">Resident</mat-checkbox>
                    <mat-checkbox class="clscheckboxLiveview clsenable" [disabled]="checklivestaffdata =='0'" id="chkstaff" #resident (change)="togglestaffVisibility($event)" [checked]="checkstaff">Staff</mat-checkbox>                
                </div>
            
                <div>
                    <h4 class="clsHeaderLive">View Outlines</h4>                                                   
                    <mat-checkbox class="clscheckboxOutlines clsenable" id="chkbuilding" #resident (change)="togglebuildingVisibility($event)" [checked]="checkbuilding">Buildings</mat-checkbox>
                    <mat-checkbox class="clschkStaffOutlines clsenable" [disabled]="checkapproximatelocation =='0'" id="chkapproximate" #resident (change)="toggleapproximateVisibility($event)" [checked]="checkapproximate">Outdoor Spaces</mat-checkbox>                
                </div>
            </div>
        </ng-container>
          
    </div>  

    <span></span>    
    <!-- <app-header></app-header> -->
    <app-header [ngClass]="UserName.length <= 15 ? 'clsusernameappheader' : 'clsappheader'"></app-header>
</mat-toolbar>




<!-- <mat-sidenav-container fxFlexFill> -->
<mat-sidenav #sideHomenav style="margin-top:4.6em;width:270px;" [autoFocus]="false" fxShow="true" fxHide.lt-lg="false" fxHide.gt-md="true">
    <div class="clssearch" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="15px">

        <mat-form-field appearance="outline" floatLabel="auto" class="clsmatformfield">
            <input type="text" placeholder="Search Individual" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="selectSearch(option)">
                    {{option.ResidentName}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        
        <!-- <mat-form-field appearance="outline" floatLabel="auto" class="clsmatformfield">

            <mat-select placeholder="Select Campus" [(ngModel)]="selectedCampus">
                <mat-option *ngFor="let Campus of Campusdetails['Campusinfo']" [value]="Campus.CampusId">
                    {{Campus.CampusName}}
                </mat-option>
            </mat-select>
        </mat-form-field>        -->
        
        
        <mat-form-field appearance="outline" floatLabel="auto" class="clsmatformfield" (click)="clkmatselect()">                       
            <input type="text" placeholder="Search Location" [(ngModel)]="selectedMapIds" matInput id="txtmapid">
            <mat-icon matSuffix id="downmaticon">arrow_drop_down</mat-icon>
        </mat-form-field>

        <div class="clspopupMapdetails" [ngStyle]="{'display': homeHeaderReference.checkmatselect == 0 ? 'none' : 'block'}"> 
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectMapdetails" (change)="clkmatradiomapdata($event)">
              <perfect-scrollbar style="max-width: 485px; max-height: 320px;" >
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">              
              <mat-nested-tree-node *matTreeNodeDef="let node;when: hasChild">
                <li>
                  <div class="mat-tree-node" *ngIf="node.isType != 2" [ngStyle]="{'margin-left': node.isType == 1 ? '0.5em' : '2em'}">
  
                        <mat-radio-button class="clsbtnmatradio" [value]="node.id" [ngStyle]="{'margin-left': node.isType == 4 ? '2.5em' : '0em'}">{{node.name}} </mat-radio-button>   
                        <button class="clsmaticonexpand" *ngIf="node.isType == 3 && node.isSubZone > 0" mat-icon-button matTreeNodeToggle 
                            (click)="clickcollapse(node)" [attr.aria-label]="'toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_less' : 'expand_more'}}
                      </mat-icon>
                    </button>
                       
  
                  </div>
                  <span *ngIf="node.isType == 2" class="clsdisable" disabled style="margin-left:2.2em;">{{node.name}}</span>
                        <ul style="padding: 0px;" [class.example-tree-invisible]="!treeControl.isExpanded(node) && node.isType == 3">
                    <label style="margin-left: 3.7em;" *ngIf="node.isType == 3">Sub-Zones(s)</label>
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </mat-tree>
          </perfect-scrollbar>
  
          <mat-dialog-actions class="clsmapdetailscontrol">
                    <button type="button" id="btnLocationClear" class="clsmapdetailsbutton" mat-raised-button (click)="onCloseCancel()">Clear</button>&nbsp;
                    <button type="button" class="clsmapdetailsbutton" mat-raised-button (click)="clkbtnMapDetailsDone()">Done</button>
        </mat-dialog-actions>
  
            </mat-radio-group>
  
  
      </div>

        <mat-checkbox id="chkLiveview" #NoLiveView (change)="togglerLiveVisibility($event)" [checked]="checkLiveView" style="font-size: 14px; display: none; padding-left:10px;">LiveView</mat-checkbox>
        <label class="clsmatcheckbox"  id="lblLiveview" style=" margin-left:1em;font-size: 14px;">Live location: </label>
        <mat-checkbox class="clsmatcheckbox" [disabled]="checkliveresidentdata =='0'"  id="chkresident" #resident (change)="toggleresVisibility($event)" [checked]="checkresident" style="margin-left:1em;font-size: 14px;">Resident</mat-checkbox>
        <mat-checkbox class="clsmatcheckbox" [disabled]="checklivestaffdata =='0'"  id="chkstaff" #staff (change)="togglestaffVisibility($event)" [checked]="checkstaff" style="margin-left:1em;font-size: 14px;">Staff</mat-checkbox>
        
        <mat-checkbox class="clsmatcheckbox" id="chkbuilding" #staff (change)="togglebuildingVisibility($event)" [checked]="checkbuilding" style="margin-left:1em;font-size: 14px;">Buildings</mat-checkbox>
        <mat-checkbox class="clsmatcheckbox" id="chkapproximate" #staff (change)="toggleapproximateVisibility($event)" [checked]="checkapproximate" style="margin-left:1em;font-size: 14px;">Outdoor Spaces</mat-checkbox>
        
        <div matTooltipClass="tooltip" matTooltip="{{selectedFloor == 0 ? campusResetTooltip :  floorResetTooltip}}" #tooltip="matTooltip" matTooltipPosition="above">
            <img class="avatar clsimgReset"   src= "/assets/images/Reset.png"> 
            <label class="clslabelreset" id="lblMapOptions" style="font-size: 14px;" (click)="home(2)">Reset</label>
        </div>
        
    </div>
</mat-sidenav>
