import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Subject } from "rxjs";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import {
  getSpaceCategory,
  getTemplates,
  loadTemplateDevice,
  addTemplateSpace,
} from "../../../technologyoverlay";
import { takeUntil } from "rxjs/operators";
import { SessionCheck, generateAlertMessage } from "../../../../../app-general";
import { Router } from "@angular/router";
import {
  NewDeviceTypeDesc,
  DeviceTypeDesc,
  DeviceType,
  enumEnableDisable,
  appsettings,
} from "../../../../../app-settings";
import { ApiService } from "../../../../../api.service";
import { getCampus, getBuilding, getZone } from "../../../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-insert-template",
  templateUrl: "./insert-template.component.html",
  styleUrls: ["./insert-template.component.scss"],
})
export class InsertTemplateComponent implements OnInit {
  public addTempateForm: UntypedFormGroup;
  public floorTempateForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  selectedTempId;
  FloorInfo;
  private _onDestroy: Subject<void> = new Subject<void>();
  SpaceInfo;
  firstcolumns = 2;
  columns = 4;
  rowHeight = "65px";
  checkfloorinfo = false;
  Templatedetails;
  Tempflag = 0;
  Campusdetails;
  Buildingdetails;
  Zonedetails;
  selectedCampusId;
  selectBuildId;
  selectFloorId;
  selTemp_Id;
  Spacecategory_details;
  selectCategoryId;
  DevicesInfo;
  selectedFloorid;
  select_DeviceIds = [] as any[];
  selectedmapdata;
  selectTemplateId = 0;
  floorrowHeight = "79px";
  checkResOffLimits = false;  
  authkey;

  public EnumDeviceType = DeviceType;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<InsertTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    private apiService: ApiService,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.selectedFloorid = data.FloorId;
    this.selectedmapdata = data;
    this.selectedCampusId = data.CampusId;
    this.selectBuildId = data.BuildId;
    this.selectFloorId = data.FloorId;
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.firstcolumns = 2;
      this.columns = 4;
    }    
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium) || this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.firstcolumns = 2;
      this.columns = 2;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.firstcolumns = 1;
      this.columns = 2;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.addTempateForm = new UntypedFormGroup({
      campusname: new UntypedFormControl(""),
      buildingname: new UntypedFormControl(""),
      zonename: new UntypedFormControl(""),
      templates: new UntypedFormControl("", [Validators.required]),
      newtempname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      category: new UntypedFormControl("", [Validators.required]),
      description: new UntypedFormControl("", [
        Validators.maxLength(32),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
    const tempFormCampusName = this.addTempateForm.get("campusname");
    const tempFormBuildName = this.addTempateForm.get("buildingname");
    const tempFormZoneName = this.addTempateForm.get("zonename");
    
    if(tempFormCampusName != null)
      tempFormCampusName.disable();
    if(tempFormBuildName != null)
      tempFormBuildName.disable();
    if(tempFormZoneName != null)
      tempFormZoneName.disable();

    this.getCampusDetails();
    this.getTemplates();
    this.getSpaceCategory();
  }

  getCampusDetails() {
    try {
      const reqCampusInfo: getCampus = {
        authKey: this.authkey,
      };
      this.apiService
        .getCampusDetails(reqCampusInfo)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails["apiReturn"];
          this.getBuildingdetails(this.selectedCampusId);
        });
    } catch (err) {
      throw new Error(
        "insert-template.component.ts" +
          " - getCampusDetails() - " +
          err.message
      );
    }
  }

  getBuildingdetails(CampusId) {
    try {
      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
      };
      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          this.Buildingdetails = BuildingDetails["apiReturn"];

          this.getZonedetails(this.selectBuildId);
        });
    } catch (err) {
      throw new Error(
        "insert-template.component.ts" +
          " - getBuildingdetails() - " +
          err.message
      );
    }
  }

  getZonedetails(BuildingId) {
    try {
      const floorid: getZone = {
        authKey: this.authkey,
        campusId:this.selectedCampusId,
        buildId: BuildingId,
        floorId: 0
      };
      this.apiService
        .getZoneDetails(floorid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ZoneDetails: any[]) => {
          this.Zonedetails = ZoneDetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "insert-template.component.ts" + " - getZonedetails() - " + err.message
      );
    }
  }

  getTemplates() {
    try {
      const template_data = [] as any[];
      const spaceitem: getTemplates = {
        authKey: this.authkey,
      };
      this.technologyoverlayService
        .getTemplates(spaceitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((TempDetails: any[]) => {
          TempDetails.forEach(function (e) {
            if (e.TemplateCategoryId == 2) {
              template_data.push(e);
            }
          });

          this.Templatedetails = template_data;
        });
    } catch (err) {
      throw new Error(
        "insert-template.component.ts" + " - getTemplates() - " + err.message
      );
    }
  }

  getSpaceCategory() {
    try {
      const spaceitem: getSpaceCategory = {
        authKey: this.authkey,
        categoryId: 0,
      };
      this.technologyoverlayService
        .getSpaceCategory(spaceitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((spacedetails: any[]) => {
          this.Spacecategory_details = spacedetails;
        });
    } catch (err) {
      throw new Error(
        "insert-template.component.ts" +
          " - getSpaceCategory() - " +
          err.message
      );
    }
  }

  loadTemplatesForDevice(TempId) {
    try {
      this.DevicesInfo = [];
      const voiceLicense = localStorage.getItem("voice_license") ?? "";
      const loadTempdevice: loadTemplateDevice = {
        authKey: this.authkey,
        templateId: parseInt(TempId),
        templateCategory: 2,
        isNeedIntercom: parseInt(voiceLicense)
      };
      this.technologyoverlayService
        .loadTemplatesForDevice(loadTempdevice)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((DeviceDetails: any[]) => {
          DeviceDetails.forEach(function (e) {
            if (e.DeviceName == DeviceTypeDesc.Emitter) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Emitter;
            } else if (e.DeviceName == DeviceTypeDesc.HRex) {
              e["NewDeviceName"] = NewDeviceTypeDesc.HRex;
            } else if (e.DeviceName == DeviceTypeDesc.Rex) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Rex;
            } 
            else if (e.DeviceName == DeviceTypeDesc.LF) {
              if (e.SubType==DeviceType.DripTray)
              {
                e['NewDeviceName'] = NewDeviceTypeDesc.DripTray;
              }
              else
              {
                e['NewDeviceName'] = NewDeviceTypeDesc.LFExiter;
              }
            }
            else if (e.DeviceName == DeviceTypeDesc.Pullcord) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Pullcord;
            } else if (
              e.DeviceName == DeviceTypeDesc.DC ||
              e.DeviceName == DeviceTypeDesc.NewDC
            ) {
              e["NewDeviceName"] = NewDeviceTypeDesc.DC;
            } else if (e.DeviceName == DeviceTypeDesc.Monitor) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Monitor;
            } else if (e.DeviceName == DeviceTypeDesc.VW) {
              e["NewDeviceName"] = NewDeviceTypeDesc.VW;
            } else if (e.DeviceName == DeviceTypeDesc.Gateway) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Gateway;
            } else if (e.DeviceName == DeviceTypeDesc.UT) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UT;
            } else if (e.DeviceName == DeviceTypeDesc.UM) {
              e["NewDeviceName"] = NewDeviceTypeDesc.UM;
            } else if (e.DeviceName == DeviceTypeDesc.Intercom) {
              e["NewDeviceName"] = NewDeviceTypeDesc.Intercom;
            } else if (e.DeviceTypeId == DeviceType.Room) {
              e["NewDeviceName"] = "Room";
            } else {
              //  e['NewDeviceName'] = NewDeviceTypeDesc.Zone;
            }
          });
          if (DeviceDetails[0].DataId != null) {
            this.DevicesInfo = DeviceDetails;
          } else {
            //dont show the select all check box if not devices then
            this.selectTemplateId = 0;
            this.DevicesInfo = [];
          }
        });
    } catch (err) {
      throw new Error(
        "insert-template.component.ts" +
          " - loadTemplatesForDevice() - " +
          err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.addTempateForm.controls).forEach((field) => {
        // {1}
        const control = this.addTempateForm.get(field); // {2}
        const tempFormId = document.getElementById(event.target.id); 
        if(tempFormId != null)
        {
          if (field == tempFormId.getAttribute("formControlName")) {
            if(control != null)
            {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-template.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  selectTempCategory(tempId) {
    this.selectTemplateId = tempId;
    this.loadTemplatesForDevice(tempId);
  }

  selectSpaceCategory(categoryId) {   
    this.selectCategoryId = categoryId;
  }

  isCheckedAll = false;
  toggleAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.select_DeviceIds = [];
      this.DevicesInfo.forEach((row) => {
        const checkedid = row.DataId;
        this.select_DeviceIds.push(checkedid);
      });
    } else {
      this.select_DeviceIds = [];
      this.select_DeviceIds.length = 0;
    }
    this.isCheckedAll = this.isChecked();
  }

  toggleUnitVisibility(checkedid, event: MatCheckboxChange) {
    if (event.checked) {
      const index = this.select_DeviceIds.indexOf(checkedid);
      if (index == -1) {
        this.select_DeviceIds.push(checkedid);
      }
    } else {
      const index = this.select_DeviceIds.indexOf(checkedid);
      if (index >= 0) {
        this.select_DeviceIds.splice(index, 1);
      }
    }
    this.isCheckedAll = this.isChecked();
  }
  exists(checkedid) {
    return this.select_DeviceIds.indexOf(checkedid) > -1;
  }

  isChecked() {
    return this.select_DeviceIds.length === this.DevicesInfo.length;
  }

  public addtemplateDetails = (addtemplateformvalue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.addTempateForm.valid) {        
         this.addTemplateSpace(addtemplateformvalue);
      }
    } catch (err) {
      throw new Error(
        "template-floor.component.ts" +
          " - templatefloorDetails() - " +
          err.message
      );
    }
  };

  addTemplateSpace(insertTempform) {
    try {
      let resofflimits = 0;      
      if (this.select_DeviceIds.length == 0 && this.DevicesInfo.length > 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Device to create as a new Space Template."
        );
        this.insertTemplateEnableDisable(enumEnableDisable.Enable);        
        return;
      }
      if (this.select_DeviceIds.length == 0 && this.DevicesInfo.length == 0) {
        generateAlertMessage(
          this.dialog,
          "Must add at least one Device to duplicate or create as a Template."
        );
        this.insertTemplateEnableDisable(enumEnableDisable.Enable);        
        return;
      }      
      this.insertTemplateEnableDisable(enumEnableDisable.Disable);

      let _Description = "";
      if (insertTempform.description != "")
        _Description = insertTempform.description.replace(/\s+/g, " ").trim();
      else _Description = "";

      if (this.checkResOffLimits == true) resofflimits = 1;
      else resofflimits = 0;

      const templateadd: addTemplateSpace = {
        authKey: this.authkey,
        templateId: this.selectTemplateId,
        campusId: parseInt(this.selectedmapdata.CampusId),
        buildingId: parseInt(this.selectedmapdata.BuildId),
        floorId: parseInt(this.selectedmapdata.FloorId),
        newSpaceName: insertTempform.newtempname.replace(/\s+/g, " ").trim(),
        newSpaceDescription: _Description, //insertTempform.description,
        newSpaceCategoryId: this.selectCategoryId.toString(),
        deviceIds: this.select_DeviceIds.toString(),
        residentOffLimits: resofflimits,
      };

      this.technologyoverlayService
        .addTemplateSpace(templateadd)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse["UnitId"] > 0) {
            generateAlertMessage(this.dialog, "Space added successfully.");
            this.thisDialogRef.close(apiresponse);
            this.select_DeviceIds = [];
          }
          if (apiresponse.length > 0) {
            generateAlertMessage(
              this.dialog,
              "Space name '" + apiresponse[0].UnitName + "' already exists."
            );            
            this.insertTemplateEnableDisable(enumEnableDisable.Enable);
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(this.dialog, "Space added failed.");
            this.thisDialogRef.close("close");
          }
        });
    } catch (err) {
      throw new Error(
        "insert-template.component.ts" +
          " - addTemplateSpace() - " +
          err.message
      );
    }
  }

  toggler_ResOffLimits(e) {
    if (e.checked == true) {
      this.checkResOffLimits = true;
    } else {
      this.checkResOffLimits = false;
    }
  }
  
  insertTemplateEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsavetempaddclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clstempsavebutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clstempsavebutton mat-raised-button disablebtn";
  }
  
}
