import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MediaObserver } from "@angular/flex-layout";
import { AlertSharedService } from "../_services/index";
import { SharedFloorAlertData } from "../api";
import { RollbarService } from "../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck } from "../app-general";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../api.service";
import { AppComponent } from "../app.component";
import { enumEnableDisable } from "../app-settings";
import { StaffByIDparam } from "../landing/staff/staff";

@Component({
  selector: "app-accesscode",
  templateUrl: "./accesscode.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./accesscode.component.scss"],
})
export class AccesscodeComponent implements OnInit, OnDestroy {
  SharedFloorAlertDetails: SharedFloorAlertData;
  S_AccessCodeType;
  S_CampusId;
  S_BuildingId;
  S_RoomId;
  S_FloorId;
  S_AlertId;
  txtaccessCodedisabled = false;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private alertSharedService: AlertSharedService,
    mediaObserver: MediaObserver,
    public thisDialogRef: MatDialogRef<AccesscodeComponent>,
    public apiService: ApiService,
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    try {
      this.S_AccessCodeType = data.AccessCodeType;
      if (this.S_AccessCodeType == "SerachResident") {
        this.S_CampusId = data.CampusId;
        this.S_BuildingId = data.BuildingId;
        this.S_RoomId = data.RoomId;
        this.S_FloorId = data.FloorId;
        this.S_AlertId = data.AlertId;
      }
    } catch (err) {
      throw new Error(
        "accesscode.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }
  password: string;
  counter = 0;
  lblattempt = "";
  lblaccessMsg = "";
  //lblaccessMsg = "Must enter access code before zooming in on individual's location."
  Max_pass_count = 3;
  Alert_Status = 2;
  ngOnInit() {
    this.alertSharedService
      .currentsharedFloorAlert()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((SharedFloorAlertDetails: SharedFloorAlertData) => {
        this.SharedFloorAlertDetails = SharedFloorAlertDetails;
      });
  }

  // close alert Notification Dialog
  public onCloseAccesscode = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  onKeyPress(): void {
    try {
      const element3 = document.getElementById("btnaccessenter");
      if (this.password == undefined || this.password == "") {
        if(element3 != null)
          // element3.className = "clsaccbutton_Enter mat-raised-button disable";
          this.accessCodeEnableDisable(enumEnableDisable.Disable);
      } else {
          if(element3 != null)
            // element3.className = "clsaccbutton_Cancel mat-raised-button";
            this.accessCodeEnableDisable(enumEnableDisable.Enable);
      }
    } catch (err) {
      throw new Error(
        "accesscode.component.ts" + " - onKeyPress() - " + err.message
      );
    }
  }

  onEnterClicked(): void {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      this.lblattempt = "";

      let staffdata;
      const authkey = localStorage.getItem("Authkey") ?? "";
      const userid = localStorage.getItem("userid") ?? "";
      const staffbyid: StaffByIDparam = {
        authKey: authkey,
        staffId: parseInt(userid),
      };

      this.apiService
        .getStaffDataById(staffbyid)
        .subscribe((staffinfo: any[]) => {
          this.alertSharedService.changesharedStaffData(staffinfo);
          if (AppComponent.SharedStaffDetails != undefined) {
            staffdata = AppComponent.SharedStaffDetails;

            if (this.password == staffdata[0]["Password"]) {
              // alert("AlertID:"+this.S_AlertId +" _"+ "RoomID:"+this.S_RoomId +" _"+ "FloorID:"+this.S_FloorId+" _"+ "BuildingId:"+this.S_BuildingId+" _"+ "CampusId:"+this.S_CampusId)
              if (this.S_AccessCodeType == "SerachResident") {
                const _SharedFloorAlertDetails = {
                  SharedFloorCampusId: this.S_CampusId,
                  SharedFloorBuildingId: this.S_BuildingId,
                  SharedFloorFloorId: this.S_FloorId, //this is campus view so no floor info
                  SharedFloorAlertId: this.S_AlertId,
                  SharedFloorRoomId: this.S_RoomId,
                  SharedFloorAlertStatus: this.Alert_Status,
                };

                this.alertSharedService.changesharedFloorAlert(
                  _SharedFloorAlertDetails
                );
              }
              this.thisDialogRef.close("Confirm");
            } else if (this.password == undefined || this.password == "") {
              // const element3 = document.getElementById("btnaccessenter");
              // element3.className = "clsaccbutton_Enter mat-raised-button disable";
                this.accessCodeEnableDisable(enumEnableDisable.Disable);
            } else {
              const element = document.getElementById("accessmsg");
              if(element != null)
                element.className = "clswrongaccesscode";
              this.lblaccessMsg =
                "Invalid access code. Please enter the correct code.";
              this.counter = this.counter + 1;
              if (this.counter == this.Max_pass_count) {
                const element4 = document.getElementById("btnaccessenter");
                if(element4 != null)
                  element4.className = "btnaccessenter";
                const element1 = document.getElementById("accessmsgcnt");
                if(element1 != null)
                  element1.className = "clsattemptfailed";
                const element2 = document.getElementById("actionDiv");
                if(element2 != null)
                  element2.className = "disable";
                this.txtaccessCodedisabled = true;
                // const element3 = document.getElementById("btnaccessenter");
                // if(element3 != null)
                //   element3.className = "clsaccbutton_Enter mat-raised-button disable";
                this.accessCodeEnableDisable(enumEnableDisable.Disable);
                this.lblattempt = "Incorrect Access Code ";
              } else if (this.counter < this.Max_pass_count) {
                this.lblattempt =
                  "You have " +
                  (this.Max_pass_count - this.counter) +
                  " attempts left";
              } else {
                this.lblattempt = "Incorrect Access Code ";
              }
            }
          }
        });
    } catch (err) {
      throw new Error(
        "accesscode.component.ts" + " - onEnterClicked() - " + err.message
      );
    }
  }
  
  accessCodeEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnaccessenter");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsaccbutton_Enter mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsaccbutton_Enter mat-raised-button disable";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
