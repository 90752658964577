import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { appsettings } from "../../app-settings";
import { Observable } from "rxjs";
import {
  WanterforCreationResponse,
  WanderConfig,
  getWanderConfigById,
} from "./wanderconfig";
import {
  WanderHttpErrorHandler,
  HandleError,
} from "./http-error-handler.service";

@Injectable()
export class WanderService {
  getSafeZoneUrl = appsettings.API_ENDPOINT + "safezones/getSafeZone";
  addSafeZoneUrl = appsettings.API_ENDPOINT + "safezones/addSafeZone";
  checkSafeZoneUrl = appsettings.API_ENDPOINT + "safezones/checkSafeZone"; // URL to web api
  updateSafeZoneUrl = appsettings.API_ENDPOINT + "safezones/updateSafeZone"; // URL to web api
  deleteSafeZoneUrl = appsettings.API_ENDPOINT + "safezones/deleteSafeZone"; // URL to web api

  getResidentUnsafeZonesUrl =
    appsettings.API_ENDPOINT + "safezones/getResidentUnsafeZones";
  checkSafeZoneConfigUrl =
    appsettings.API_ENDPOINT + "safezones/checkSafeZoneConfig";

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: WanderHttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError("WanderService");
  }

  /** GET Staffs from the server */
  getSafeZone(getSafeZoneParams): Observable<WanderConfig[]> {
    return this.http.post<WanderConfig[]>(
      this.getSafeZoneUrl,
      getSafeZoneParams
    );
  }

  addSafeZone(addSafeZoneParams): Observable<WanterforCreationResponse[]> {
    return this.http.post<WanterforCreationResponse[]>(
      this.addSafeZoneUrl,
      addSafeZoneParams
    );
  }

  updateSafeZone(
    updateSafeZoneParams
  ): Observable<WanterforCreationResponse[]> {
    return this.http.post<WanterforCreationResponse[]>(
      this.updateSafeZoneUrl,
      updateSafeZoneParams
    );
  }

  /** GET Staffs from the server */
  checkSafeZone(checkSafeZoneParams): Observable<getWanderConfigById[]> {
    return this.http.post<getWanderConfigById[]>(
      this.checkSafeZoneUrl,
      checkSafeZoneParams
    );
  }

  deleteSafeZone(
    deleteSafeZoneParams
  ): Observable<WanterforCreationResponse[]> {
    return this.http.post<WanterforCreationResponse[]>(
      this.deleteSafeZoneUrl,
      deleteSafeZoneParams
    );
  }

  getResidentUnsafeZones(safezone): Observable<any[]> {
    return this.http.post<any[]>(this.getResidentUnsafeZonesUrl, safezone);
  }

  checkSafeZoneConfig(checkSafeZoneConfigParams): Observable<any[]> {
    return this.http.post<any[]>(
      this.checkSafeZoneConfigUrl,
      checkSafeZoneConfigParams
    );
  }
}
