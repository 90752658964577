import { Component, OnInit, Inject } from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { AssetService } from "../assetservice";
import { AssetForCreationResponse, AssetForEditCreation } from "../asset";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage, AuthFailedMessage } from "../../../app-general";
import { enumEnableDisable,enumTagType } from "../../../app-settings";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { AlertSharedService } from "../../../_services/index";
import { ApiService } from "../../../api.service";
import { checkTagId } from "../../../api";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { appsettings } from '../../../app-settings';

@Component({
  selector: "app-edit-asset",
  templateUrl: "./edit-asset.component.html",
  providers: [AssetService],
  styleUrls: ["./edit-asset.component.scss"],
})
export class EditAssetComponent implements OnInit {
  public watcher: Observable<boolean>;
  columns = 8;
  assetid;
  oldTagId;
  public EditassetCreationForm: UntypedFormGroup;
  private _onDestroy: Subject<void> = new Subject<void>();
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditAssetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private AssetService: AssetService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.assetid = data.AssetId;
    this.oldTagId = data.TagId;
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 8;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 3;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.EditassetCreationForm = new UntypedFormGroup({
      tagid: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(10),
        Validators.max(1073741823),
        Validators.min(1),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
      assetname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      assetnamealias: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      assettype: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
  }
  public onCloseAsset = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.EditassetCreationForm.controls[controlName].hasError(errorName);
  };
  public EditcreateAsset = (assetCreationForm) => {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    if (this.EditassetCreationForm.valid) {
      this.checkTagId(assetCreationForm);
    }
  };

  checkTagId(assetCreationForm) {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();

      if (this.EditassetCreationForm.valid) {
        this.assetEditEnableDisable(enumEnableDisable.Disable);
        const tagidfoundItem: checkTagId = {
          authKey: this.authkey,
          newTagId: parseInt(assetCreationForm.tagid),
          resStaffId: parseInt(this.assetid),
          tagType:enumTagType.Asset
        };
        this.apiService
          .checkTagId(tagidfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse != null && apiresponse != undefined) {
              if (apiresponse.toString() == "not found") {
                this.updateAsset(assetCreationForm);
              } else {
                if (
                  apiresponse[0].desc == "Resident" ||
                  apiresponse[0].desc == "Staff"
                ) {
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate Tag Id found, the Asset " +
                      apiresponse[0].desc +
                      "  '" +
                      apiresponse[0].name +
                      "'  already has this tagid.<br> Please try again with different Tag Id"
                  );
                  this.assetEditEnableDisable(enumEnableDisable.Enable);
                  return;
                } else if (apiresponse[0].desc == "Asset") {
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate Tag Id found, the Asset '" +
                      apiresponse[0].name +
                      "'  already has this tagid.<br> Please try again with different Tag Id"
                  );
                  this.assetEditEnableDisable(enumEnableDisable.Enable);
                  return;
                } else {
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate Tag Id found, the Asset '" +
                      apiresponse[0].name +
                      "' already has this tagid on " +
                      apiresponse[0].desc +
                      ".<br> Please try again with different Tag Id"
                  );
                  this.assetEditEnableDisable(enumEnableDisable.Enable);
                  return;
                }
              }
            } else {
              generateAlertMessage(this.dialog, "Asset details edited failed.");
              this.assetEditEnableDisable(enumEnableDisable.Enable);
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-asset.component.ts" + " - checkTagId() - " + err.message
      );
    }
  }

  updateAsset(assetCreationForm) {
    try {
      this.assetEditEnableDisable(enumEnableDisable.Disable);
      const e_asset: AssetForEditCreation = {
        authKey: this.authkey,
        tagId: parseInt(assetCreationForm.tagid),
        assetName: assetCreationForm.assetname.replace(/\s+/g, " ").trim(),
        assetNameAlias: assetCreationForm.assetnamealias
          .replace(/\s+/g, " ")
          .trim(),
        assetType: assetCreationForm.assettype.replace(/\s+/g, " ").trim(),
        assetId: parseInt(this.assetid),
      };
      this.AssetService.updateAsset(e_asset)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: AssetForCreationResponse[]) => {
          if (apiresponse != null && apiresponse != undefined) {
            if (apiresponse["apiReturn"] == "Success") {
              generateAlertMessage(
                this.dialog,
                "Asset details edited successfully."
              );
            } 
            else if (apiresponse["apiReturn"] == "Fail") {
              generateAlertMessage(this.dialog, "Asset details edited failed.");
            }
            else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
          } 
          else {
            generateAlertMessage(this.dialog, "Asset details edited failed.");
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "edit-notification.component.ts" + " - updateAsset() - " + err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.EditassetCreationForm.controls).forEach((field) => {
        // {1}
        const control = this.EditassetCreationForm.get(field); // {2}
        const assetEditFormId = document.getElementById(event.target.id);
        if (assetEditFormId != null) {
          if (field == assetEditFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  assetEditEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsaveclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsbtnAsseteditSave mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsbtnAsseteditSave mat-raised-button disablebtn";
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
