import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { ResidentService } from "../../resident.service";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import {
  ResidentGroupForCreationResponse,
  ResidentEditGroupForCreation,
} from "../../resident";
import {
  GroupNamefoundResponse,
  getGroupData,
  checkGroupName,
  checkGroupNotifyLevel,
} from "../../../../api";
import { ApiService } from "../../../../api.service";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../app-general";

import { enumGroup_Type,appsettings } from "../../../../app-settings";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-resident-group",
  templateUrl: "./edit-resident-group.component.html",
  providers: [ResidentService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-resident-group.component.scss"],
})
export class EditresidentgroupComponent implements OnInit, OnDestroy {
  public residentGroupEditCreationForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 3;
  EditGroupId;
  groupdetails;
  oldgroupname;
  residenteditgroupbool = true;
  groupresrowHeight = "67px";
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditresidentgroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private residentService: ResidentService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiService: ApiService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());       
    this.EditGroupId = data.GroupId;
    this.oldgroupname = data.Groupname;
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.onResize();
    this.getResidentGroup();
    this.residentGroupEditCreationForm = new UntypedFormGroup({
      groupname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      description: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(150),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
    });
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.residentGroupEditCreationForm.controls).forEach(
        (field) => {
          // {1}
          const control = this.residentGroupEditCreationForm.get(field); // {2}
          const resGroupFromId = document.getElementById(event.target.id);
          if (resGroupFromId != null) {
            if (field == resGroupFromId.getAttribute("formControlName")) {
              if (control != null) {
                control.setValue(changedValue);
                control.markAsTouched({ onlySelf: true }); // {3}
              }
            }
          }
        }
      );
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge == true) {
      this.groupresrowHeight = "72.5px";
    }
  }
  getResidentGroup(): void {
    try {
      const resgroupitem: getGroupData = {
        authKey: this.authkey,
      };
      this.apiService
        .getResidentGroup(resgroupitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ResGroup: any[]) => {
          if (ResGroup["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.groupdetails = ResGroup["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "show-resident-group.component.ts" +
          " - getResidentGroup() - " +
          err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };
  public hasError = (controlName: string, errorName: string) => {
    return this.residentGroupEditCreationForm.controls[controlName].hasError(
      errorName
    );
  };
  public createResidentGroup = (ResidentgroupFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      (document.activeElement as HTMLTextAreaElement).blur();
      if (
        this.residentGroupEditCreationForm.valid &&
        this.residenteditgroupbool
      ) {
        this.residenteditgroupbool = false;
        const groupnamefoundItem: checkGroupName = {
          authKey: this.authkey,
          groupType: 1,
          newGroupName: ResidentgroupFormValue.groupname.toString(),
          groupId: parseInt(this.EditGroupId),
        };
        this.apiService
          .checkGroupName(groupnamefoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: GroupNamefoundResponse[]) => {
            if (apiresponse.toString() == "not found") {
              this.checkGroupNotificationlevel(ResidentgroupFormValue);
            } else {
              this.residenteditgroupbool = true;
              generateAlertMessage(
                this.dialog,
                "Resident Group name '" +
                  apiresponse[0].name +
                  "' already exists"
              );
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-resident-group.component.ts" +
          " - createResidentGroup() - " +
          err.message
      );
    }
  };

  checkGroupNotificationlevel(ResidentgroupFormValue) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (
        this.oldgroupname !=
        ResidentgroupFormValue.groupname.replace(/\s+/g, " ").trim()
      ) {
        const groupfoundItem: checkGroupNotifyLevel = {
          authKey: this.authkey,
          resStaffType: enumGroup_Type.Resident,
          resStaffGroupId: this.EditGroupId.toString(),
        };
        this.apiService
          .checkGroupNotificationlevel(groupfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse.length == 0) {
              this.updateResidentGroup(ResidentgroupFormValue);
            } else {
              this.residenteditgroupbool = true;
              generateAlertMessage(
                this.dialog,
                "Resident Group name '" +
                  apiresponse[0].GroupName +
                  "' is in escalation level,so please remove this group from escalation and try again."
              );
              return;
            }
          });
      } else {
        this.updateResidentGroup(ResidentgroupFormValue);
      }
    } catch (err) {
      throw new Error(
        "edit-staff-group.component.ts" +
          " - checkGroupNotificationlevel() - " +
          err.message
      );
    }
  }

  // edit Resident Group
  private updateResidentGroup = (ResidentgroupFormValue) => {
    try {
      const EditResidentgroup: ResidentEditGroupForCreation = {
        authKey: this.authkey,
        groupname: ResidentgroupFormValue.groupname
          .replace(/\s+/g, " ")
          .trim()
          .toString(),
        description: ResidentgroupFormValue.description
          .replace(/\s+/g, " ")
          .trim()
          .toString(),
        groupId: this.EditGroupId.toString(),
      };
      this.residentService
        .updateResidentGroup(EditResidentgroup)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: ResidentGroupForCreationResponse[]) => {
          if (apiresponse["apiReturn"] == "Success") {
            this.residenteditgroupbool = false;
            generateAlertMessage(
              this.dialog,
              "Resident group edited successfully."
            );
          } else if (apiresponse["apiReturn"] == "Fail") {
            this.residenteditgroupbool = true;
            generateAlertMessage(this.dialog, "Resident group edited failed.");
          } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "edit-resident-group.component.ts" +
          " - updateResidentGroup() - " +
          err.message
      );
    }
  };

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
