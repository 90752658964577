<div id="divGeneralMessage" class="clsGeneralMessage">
  <div class="GeneralMessage-title">
    <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="GeneralMessageHeader" style="margin: 0 0 10px 11px;">{{General_header}}
      <span><img class="clscloseimage" src="assets/images/close.png" (click)="onCloseCancel()"></span></h3>
    <mat-divider></mat-divider>
  </div>
  <div class="clsGeneralMessagecontent">
    <div [innerHtml]="GeneralMessage"></div>
    <mat-dialog-actions class="clsGeneralMessagebuttoncontrol">
      <button class="clsgenbutton_Cancel" id='btnaccessenter' mat-raised-button (click)="onClosefirst()">{{General_first}}</button>
      <button class="clsgenbutton_Cancel" id='btnsecondaccessenter' mat-raised-button (click)="onCloseSecond()">{{General_second}}</button>
    </mat-dialog-actions>
  </div>
</div>