import * as Rollbar from 'rollbar';

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';


const rollbarConfig = {
  accessToken: '9a6db812029c4f3a94e695a38c211bec',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: "Beta",

  payload: {
    server: {
      root: "webpack:///./", 
      branch: "master",
      host:localStorage.getItem("applianceSN")
    },
    client: {
      javascript: {
        code_version: "9bc0b2b35",  // Git SHA of your deployed code
        source_map_enabled: true,
        guess_uncaught_frames: true,
        
      }
    },
    transform: function(payload) {
      const trace = payload.body.trace;
      // Change 'yourdomainhere' to your domain.
      const locRegex = /^(http?):\/\/[a-zA-Z0-9._-]+\.ltc.centrak\.com(.*)/;
      if (trace && trace.frames) {
        for (let i = 0; i < trace.frames.length; i++) {
          const filename = trace.frames[i].filename;
          if (filename) {
            const m = filename.match(locRegex);
            // Be sure that the minified_url when uploading includes 'dynamichost'
            trace.frames[i].filename = m[1] + '://dynamichost' + m[2];            
          }
        }
      }
    }
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    console.log(err);
    if (localStorage.getItem("applianceSN")!=null && localStorage.getItem("applianceSN")!=undefined)
    {
      const applianceSN = localStorage.getItem("applianceSN") ?? "";
      if (applianceSN.length>0)
      {
      //   this.rollbar.error(err.originalError || err);  // Disable send notification to Rollbar Site
      }
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}