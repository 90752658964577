import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { Subject } from "rxjs";
import {
  appsettings,
  enumFileNamelength,
  enumGroup_Type,
  enumEnableDisable, enumStaffRoll,enumTagType
} from "../../../app-settings";
import {
  StaffDesignationList,
  StaffState,
  StaffShift,
  StaffForCreationResponse,
  StaffGroup,
  StaffEditForCreation,
  getStaffRoleList,
  getStaffGroup,
  getStaffGroupInfo,
  getNotifyStaffRole,
  getPhoneNumberOtp,
  getEmailOtp,
  checkPagerNumber,
  checkEmailId,
  checkPhoneNumber,
  FloorGroup
} from "../staff";
import { StaffService } from "../staff.service";
import {
  TagIdfoundResponse,
  ShareUserdetails,
  AlertfoundtagidResponse,
  checkTagId,
  checkAlertForTag,
  backupPhoto,
  checkGroupNotify,
  getCampus
} from "../../../api";
import { ApiService } from "../../../api.service";
import { TooltipPosition } from "@angular/material/tooltip";
import { AlertSharedService } from "../../../_services/index";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../app-general";
import { Router } from "@angular/router";
import { VerifyStaffComponent } from "../verify-staff/verify-staff.component";
import { HttpClient } from "@angular/common/http";
import { HostListener } from "@angular/core";
import { AppComponent } from "../../../app.component";
import { takeUntil } from "rxjs/operators";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { AlertMessageComponent } from "../../../alert-message/alert-message.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

const URL = appsettings.URLinfo + "uploadImage_Staff";

@Component({
  selector: "app-edit-staff",
  templateUrl: "./edit-staff.component.html",
  providers: [StaffService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-staff.component.scss"],
})
export class EditStaffComponent implements OnInit, OnDestroy {
  checknetwork;
  public watcher: Observable<boolean>;
  columns = 8;
  rowspan = 2;
  public staffeditForm: UntypedFormGroup;
  uploadStaffimage = "";
  staffDesignationList: StaffDesignationList[];
  imagename;
  staffimagename: any;
  staffeditimageUrl;
  OldstaffTagId = 0;
  EditstaffTagId;
  OldstaffMailId;
  show: boolean;
  passwordIcon;
  tooltipDesc;
  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = new UntypedFormControl(this.positionOptions[3]);
  Groupdetails: StaffGroup[];
  userinfo: any = [];
  username: string;
  userpassword: string;
  userimage;
  usertId;
  ShareUserDetails: ShareUserdetails;
  checkstaffverify = 0;
  checkstaffemailverify = 0;
  staffphonenumber = 0;
  oldphoneNumber;
  staffNumberverify = true;
  private staffURL = appsettings.URLinfo + "request_OTP_Register";
  buttonvalue;
  emailbuttonvalue;
  staffverifyresult;
  updatestaffverify = 0;
  updatestaffemailverify = 0;
  OldDesignationId = 0;
  staffgender = 0;
  staffbooledit = true;
  screenWidth;
  staffEmailId = "";
  checkverifyPhoneclick = 0;
  checkverifyEmailclick = 0;
  boolstaffphoneverify = true;
  boolstaffemailverify = true;
  isVerifiedOTP;
  isEmailVerified;
  checkPhoneclick = 0;
  checkEmailclick = 0;
  emailLoading = false;
  staffverifiedEmailid;
  staffverifiedPhone;
  staffrowHeight = "69px";
  staffdefaultimage = 0;
  deleteImageflag = 0;
  Zipinfo = "";
  S_Gender;
  editStaffId;
  checkstaffdefaultimg = 0;
  oldpagernumber;
  _authkey = "";
  selectGroupId = "";
  oldGroupId;
  selectedStaffGroupId;
  selectedGroup;
  countrycode;
  selected_GroupId = [] as any[];
  editCheck_Invalue = false;
  authkey;
  editNotificationvalue = false;
  oldeditNotificationvalue = false;
  editroleEnableNotify = false;
  enableCheckbox = false;
  enablePhoneCheckbox = false;
  Campusdetails;
  selectedCampusId;  
  editChkAlertvalue = false;
  editPhoneChkAlertvalue = false;
  floorGroups;
  editCheckInReport = 0;
  editCheckAlert = 0;
  editPhoneCheckAlert = 0;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private apiService: ApiService,    
    public thisDialogRef: MatDialogRef<EditStaffComponent>,
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private staffService: StaffService,
    public mainLayoutService: MainLayoutService,
    private alertSharedService: AlertSharedService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.show = false;
    this.passwordIcon = "visibility_off";
    this.tooltipDesc = "Show Password";
    this.staffimagename = data[0].photo;
    this.staffeditimageUrl = appsettings.STAFF_FOLDER + data[0].photo;
    
    if(data[0].GroupId != null)
      this.selectGroupId = data[0].GroupId;
    else
      this.selectGroupId = "";
    this.oldGroupId = data[0].GroupId;
    if (data[0].TagId == 0 || data[0].TagId == '' ) {
      this.EditstaffTagId = "";
    } else {
      this.EditstaffTagId = data[0].TagId;
      this.OldstaffTagId = data[0].TagId;
    }

    if (data[0].Zip == 0) {
      this.Zipinfo = "";
    } else {
      this.Zipinfo = data[0].Zip;
    }
    this.OldstaffMailId = data[0].Email;
    this.oldphoneNumber = data[0].Phone;
    this.isVerifiedOTP = data[0].isVerifiedOTP;
    this.isEmailVerified = data[0].isEmailVerified;
    this.editStaffId = data[0].StaffID;
    this.oldpagernumber = data[0].PagerNo;
    this.editCheck_Invalue = data[0].StaffCheckInvalue;
    this.editChkAlertvalue = data[0].StaffAlertValue;
    if(this.editCheck_Invalue == true)
      this.editCheckInReport = 1;
    if(this.editChkAlertvalue == true)
      this.editCheckAlert = 1;
    this.editPhoneChkAlertvalue = data[0].StaffPhoneAlertValue;
    if(this.editPhoneChkAlertvalue == true)
      this.editPhoneCheckAlert = 1;
    this.selectedCampusId = data[0].CampusorFloorId;
    if(data[0].DesignationId == enumStaffRoll.Admin)
    {
      this.editNotificationvalue = false;  
      this.editroleEnableNotify = true;
    }
    else
    {
      this.editNotificationvalue = data[0].StaffNotificationvalue;
      this.editroleEnableNotify = false;
    }
    this.oldeditNotificationvalue = data[0].StaffNotificationvalue;
    if (data[0].isVerifiedOTP == 1) {
      this.buttonvalue = "Enrolled";
      this.enablePhoneCheckbox = true;
      this.checkstaffverify = 1;
      this.updatestaffverify = 1;
    } else if (data[0].Phone != "" && data[0].isVerifiedOTP == 0) {
      this.buttonvalue = "Enroll";
      this.checkstaffverify = 0;
      this.updatestaffverify = 0;
    } else {
      this.buttonvalue = "Enroll";
      this.checkstaffverify = 1;
      this.updatestaffverify = 0;
    }
    if (data[0].isEmailVerified == 1) {
      this.emailbuttonvalue = "Enrolled";
      this.enableCheckbox = true; 
      this.checkstaffemailverify = 1;
      this.updatestaffemailverify = 1;
    } else {
      this.emailbuttonvalue = "Enroll";
      this.checkstaffemailverify = 0;
      this.updatestaffemailverify = 0;
    }

    if (
      this.staffimagename == "Default/staff-male.png" ||
      this.staffimagename == "Default/staff-Female.png"
    ) {
      this.staffdefaultimage = 1;
      this.checkstaffdefaultimg = 1;
    } else this.staffdefaultimage = 0;

    this.OldDesignationId = data[0].DesignationId;
    this.onResize();
    this.breakpoint$.subscribe(() => this.breakpointChanged());   
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 8;
      this.rowspan = 2;
    }        
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
      this.rowspan = 1;
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 3;
      this.rowspan = 1;
    }
  }

  @ViewChild("clkimageInput") clkimageInput: any;
  public onChangeeditstaff(fileList: FileList) {
    const file = fileList[0];
    const removedSpaces_filename = file.name.split(" ").join("");
    const checkFilename = this.apiService.checkfile_name(
      removedSpaces_filename
    );
    if (checkFilename == false) {
      generateAlertMessage(
        this.dialog,
        "File Name contains special characters"
      );
      if (this.staffdefaultimage == 1 && this.checkstaffdefaultimg == 0)
        this.staffeditimageUrl = "assets/images/admin-avatar.png";
      else this.staffeditimageUrl = appsettings.STAFF_FOLDER + this.imagename;

      this.clkimageInput.nativeElement.value = "";
      return;
    } else if (removedSpaces_filename.length > enumFileNamelength.length) {
      generateAlertMessage(
        this.dialog,
        "File name cannot exceed 128 characters in length"
      );
      if (this.staffdefaultimage == 1 && this.checkstaffdefaultimg == 0)
        this.staffeditimageUrl = "assets/images/admin-avatar.png";
      else this.staffeditimageUrl = appsettings.STAFF_FOLDER + this.imagename;

      this.clkimageInput.nativeElement.value = "";
      return;
    }
    const itemType =
      removedSpaces_filename.substring(
        removedSpaces_filename.lastIndexOf(".") + 1,
        removedSpaces_filename.length
      ) || removedSpaces_filename;
    const fileTypesAllowed = ["bmp", "jpg", "png"];
    if (!fileTypesAllowed.includes(itemType.toLowerCase())) {
      generateAlertMessage(
        this.dialog,
        "The file format is not supported, Please upload Staff Photo as image file."
      );
      if (this.staffdefaultimage == 1 && this.checkstaffdefaultimg == 0)
        this.staffeditimageUrl = "assets/images/admin-avatar.png";
      else this.staffeditimageUrl = appsettings.STAFF_FOLDER + this.imagename;

      this.clkimageInput.nativeElement.value = "";
      return;
    }
    pFileReader(fileList[0], this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, fileList[0]);
      else this.staffeditimageUrl = appsettings.STAFF_FOLDER + this.imagename;

      this.clkimageInput.nativeElement.value = "";
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve, reject) => {
        console.log(reject);
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function (x) {
          console.log(x);
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 3) {
              checkvalidIMG = 2;
              //generateAlertMessage(_dialog, "Please upload the image less than 100 KB");
              generateAlertMessage(
                _dialog,
                "The maximum file size allowed for upload 3 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "The Staff Photo file which you tried to upload is not valid image file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  uploadfiletoPath(data, file) {
    const formData = new FormData();
    const _filename = file.name.split(" ").join("");
    const FileName = this.editStaffId + "+" + _filename;
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        this.staffeditimageUrl = appsettings.STAFF_FOLDER + res.filename;
        this.uploadStaffimage = res.filename;
        this.imagename = res.filename;
      });
  }
  backupPhoto(filename) {
    try {
      const fileitem: backupPhoto = {
        authKey: this.authkey,
        fileName: filename.toString(),
        isPath: "Staff",
      };
      this.apiService
        .backupPhoto(fileitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Response: any[]) => {
          console.log(Response);
        });
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - backupPhoto() - " + err.message
      );
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";

    this.countrycode = localStorage.getItem("country_code");
    this.getGroupInfoByStaff(this.selectGroupId);
    this.getStaffDesignationList();
    this.getStaffGroup();
    this.imagename = this.staffimagename;

    this.staffeditForm = new UntypedFormGroup({
      firstname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      lastname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      staffId: new UntypedFormControl(""),
      gender: new UntypedFormControl("", [Validators.required]),
      tagid: new UntypedFormControl("", [
        Validators.maxLength(10),
        Validators.max(1073741823),
        Validators.min(1),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
      role: new UntypedFormControl("", [Validators.required]),
      pager: new UntypedFormControl("", [Validators.pattern(appsettings.Number_Pattern)]),
      extension: new UntypedFormControl("", [
        Validators.pattern(appsettings.Number_Pattern),
        Validators.maxLength(5),
      ]),
      photo: new UntypedFormControl(""),     
      phone: new UntypedFormControl("", [
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(60),
        Validators.email,
        Validators.pattern(
          appsettings.Email_Pattern
        ),
      ]),
      password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(20),
        Validators.pattern(appsettings.Password_Pattern),
      ]),
      notes: new UntypedFormControl(""),
      group: new UntypedFormControl(""),
      campus: new UntypedFormControl(""),
    });
    this.getCampusFloordetails();
  }

  getCampusFloordetails() 
  { 
    try
    {
      const campusid: getCampus = {        
        authKey: this.authkey
      }
      this.staffService.getMapDetails(campusid).pipe(takeUntil(this._onDestroy))
      .subscribe((CampusDetails : any[])=>{  
        this.Campusdetails = CampusDetails; 
                
        let formfloorgroup : FloorGroup[];
        let floors = "";  
        let oldCampusId = 0;
        let oldBuildId = 0; 
        let campus = "";    
        for(let i = 0; i < CampusDetails.length; i++)
        {                          
          console.log("oldcampusid : " + oldCampusId,CampusDetails[i].CampusId,i)
          if(oldCampusId != CampusDetails[i].CampusId)
          {
            console.log("oldcampusid 1: " + oldCampusId,CampusDetails[i].CampusId,i)      
            if(i > 0 )    
            campus += "]";
            
            if(i > 0 ) 
            {
              if(i != CampusDetails.length - 1 || CampusDetails[i].FloorId == null || CampusDetails[i].CampusId != CampusDetails[i-1].CampusId)
              campus += "},";  
            else
              campus += "}";  
            }      
            
            campus += "{";
            campus += "\"value\": \""+CampusDetails[i].CampusId+"_0\",\"campus\": \""+CampusDetails[i].CampusName+"\","
            campus += "\"name\": \"\","
            campus += "\"floors\": ["; 
            
            oldCampusId = CampusDetails[i].CampusId
                             
          }
          
          if(oldBuildId != CampusDetails[i].BuildId)
          {
            if(CampusDetails[i].BuildId != null) 
            {
              floors = "{ \"value\": \""+CampusDetails[i].BuildId+"\",\"viewValue\": \""+CampusDetails[i].BuildingName+"\",\"isfloor\":\"0\"}"; 
              oldBuildId = CampusDetails[i].BuildId;
              if((i != CampusDetails.length - 1 && CampusDetails[i].CampusId == CampusDetails[i+1].CampusId) || CampusDetails[i].FloorId != null)
                floors += ",";  
              campus += floors; 
            }
          }
          
          if(CampusDetails[i].FloorId != null) 
          {
            floors = "{ \"value\": \""+CampusDetails[i].FloorId+"_1\",\"viewValue\": \""+CampusDetails[i].FloorName+"\",\"isfloor\":\"1\"}";                         
        
            if(i != CampusDetails.length - 1 && CampusDetails[i].CampusId == CampusDetails[i+1].CampusId)
                floors += ",";  
            
              campus += floors;  
          }                                       
                    
        }
        floors = "[ " + campus + "]}]";       
        const _floorsinfo = JSON.parse(floors);             
                 
        formfloorgroup = _floorsinfo;
                
        this.floorGroups = formfloorgroup;   
        
      });  
    }
    catch (err) {
      throw new Error('edit-resident.component.ts' + ' - getCampusFloordetails() - ' + err.message);
    }
   
  }
  
  changecampus(campusid)
  {           
    this.selectedCampusId = campusid;   
  }
  
  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.staffeditForm.controls).forEach((field) => {
        // {1}
        const control = this.staffeditForm.get(field); // {2}
        const staffForm_Id = document.getElementById(event.target.id);
        if (staffForm_Id != null) {
          if (field == staffForm_Id.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
      if(this.staffeditForm.valid && this.staffeditForm.controls.phone.value != "" && this.updatestaffverify == 0)      
        this.checkstaffverify = 0;      
      else
        this.checkstaffverify = 1;
        
      if(this.staffeditForm.valid && this.staffeditForm.controls.email.value != "" && this.updatestaffemailverify == 0 )      
        this.checkstaffemailverify = 0;      
      else
        this.checkstaffemailverify = 1;
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    this.screenWidth = window.innerWidth;
    if (isIEOrEdge == true) {
      this.staffrowHeight = "72px";
    }
    if (isIEOrEdge == false && this.screenWidth < 599) {
      this.columns = 3;
      this.rowspan = 1;
      this.staffrowHeight = "87px";
    } else if (isIEOrEdge == true && this.screenWidth < 599) {
      this.columns = 3;
      this.rowspan = 1;
      this.staffrowHeight = "88px";
    } else if (this.screenWidth >= 1097 && isIEOrEdge == false) {
      this.columns = 8;
      this.rowspan = 2;
      this.staffrowHeight = "69px";
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.staffeditForm.controls[controlName].hasError(errorName);
  };

  public onCloseCancel = () => {
    if (!SessionCheck(this.router)) {
      return;
    }
    if (this.checkPhoneclick == 1 || this.checkEmailclick == 1) {
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to discard the changes?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          this.thisDialogRef.close("Cancel");
        } else {
          return false;
        }
      });
    } else {
      this.thisDialogRef.close("Cancel");
    }
  };

  state: StaffState[] = [
    { Name: "New Jersey", Value: "New Jersey" },
    { Name: "New York", Value: "New York" },
    { Name: "Virginia", Value: "Virginia" },
    { Name: "Washington", Value: "Washington" },
  ];

  shift: StaffShift[] = [
    { Name: "6am-2pm", Value: "1" },
    { Name: "2pm-10pm", Value: "2" },
    { Name: "10pm-6am", Value: "3" },
  ];

  getStaffDesignationList(): void {
    try {
      const getRole = localStorage.getItem("role");
      const staffitem: getStaffRoleList = {
        authKey: this.authkey,
      };
      this.staffService
        .getStaffDesignationList(staffitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((staffDesignationList: any[]) => {
          if (
            staffDesignationList != null &&
            staffDesignationList != undefined
          ) {
            staffDesignationList = staffDesignationList.filter(function (e) {
              if (getRole != null) return e.DesignationId >= parseInt(getRole);
            });
            this.staffDesignationList = staffDesignationList;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" +
          " - getStaffDesignationList() - " +
          err.message
      );
    }
  }

  changestaffGroup(data: any) {
    if (data.selected == false) {
      this.selected_GroupId.push(data.value);
    } else {
      this.selected_GroupId.forEach((element, index) => {
        if (element == data.value) this.selected_GroupId.splice(index, 1);
      });
    }
  }

  getStaffGroup() {
    try {
      const groupitem: getStaffGroup = {
        authKey: this.authkey,
      };
      this.staffService
        .getStaffGroup(groupitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Groupdetails: any[]) => {
          if (Groupdetails["apiReturn"] == "Invalid Auth Key") {
            this.thisDialogRef.close("Cancel");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Groupdetails = Groupdetails["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - getStaffGroup() - " + err.message
      );
    }
  }

  getGroupInfoByStaff(getselectedGroup) {
    try {
      const selectedgroupitem: getStaffGroupInfo = {
        authKey: this.authkey,
        selectedGroup: getselectedGroup.toString(),
      };
      this.staffService
        .getGroupInfoByStaff(selectedgroupitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Groupdetails: any[]) => {
          this.selectedGroup = Groupdetails;
          if (this.selectedGroup.length>0)
          this.selectedStaffGroupId = this.selectedGroup[0].GroupIdkeys;
        });
    } catch (err) {
      throw new Error(
        "edit-resident.component.ts" +
          " - getGroupInfoByStaff() - " +
          err.message
      );
    }
  }

  public createStaffbyId = (staffeditForm) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (!this.boolstaffphoneverify || !this.boolstaffemailverify) {
        return;
      }
      this.staffgender = 1;
      (document.activeElement as HTMLTextAreaElement).blur();
      if (this.staffeditForm.valid && this.staffbooledit) {
        this.staffbooledit = false;
          if(staffeditForm.campus == "0_0")
          {
            this.staffbooledit = true;  
            generateAlertMessage(this.dialog, "Please select Campus or Zone");            
            return false;
          }

        this.staffverifiedEmailid = staffeditForm.email.toLowerCase();
        if (
          this.OldstaffMailId.toLowerCase() == "admin@cenltc.com" &&
          this.staffverifiedEmailid != "admin@cenltc.com"
        ) {
          this.staffbooledit = true;
          generateAlertMessage(
            this.dialog,
            "email address admin@cenltc.com could not allow to edit."
          );
          return;
        }
        if (
          this.OldstaffMailId.toLowerCase() == "admin@cenltc.com" &&
          this.OldDesignationId != parseInt(staffeditForm.role)
        ) {
          this.staffbooledit = true;
          generateAlertMessage(
            this.dialog,
            "admin user Role could not allow to edit."
          );
          return;
        }

        const alerttagidfoundItem: checkAlertForTag = {
          authKey: this.authkey,
          oldTagId: this.OldstaffTagId,
          isResStaff: 0,
        };
        this.apiService
          .checkAlertForTag(alerttagidfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: AlertfoundtagidResponse[]) => {
            if (apiresponse.toString() == "not found") {
              this.checkTagId(staffeditForm);
            } else {
              this.staffbooledit = true;
              generateAlertMessage(
                this.dialog,
                "The tagid " +
                  this.OldstaffTagId +
                  " associated to staff " +
                  staffeditForm.firstname +
                  " " +
                  staffeditForm.lastname +
                  " has open alerts, please close all alerts and try to change the staff."
              );
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - createStaffbyId() - " + err.message
      );
    }
  };

  checkTagId(staffFormValue) {
    try {
      if (this.staffeditForm.valid) {
        //tagid found check start
        if (staffFormValue.tagid == undefined) staffFormValue.tagid = 0;
        if (this.OldstaffTagId != staffFormValue.tagid) {
          const tagidfoundItem: checkTagId = {
            authKey: this.authkey,
            newTagId: parseInt(staffFormValue.tagid),
            resStaffId: parseInt(staffFormValue.staffId),
            tagType:enumTagType.Staff
          };
          this.apiService
            .checkTagId(tagidfoundItem)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: TagIdfoundResponse[]) => {
              if (apiresponse.toString() == "not found") {
                if (parseInt(staffFormValue.role) != this.OldDesignationId)
                  this.getStaffRole(staffFormValue);
                else this.checkPagerNumber(staffFormValue);
              } else {
                this.staffbooledit = true;
                if (
                  apiresponse[0].desc == "Resident" ||
                  apiresponse[0].desc == "Staff" ||
                  apiresponse[0].desc == "Asset"
                )
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate tagid found, the " +
                      apiresponse[0].desc +
                      "  '" +
                      apiresponse[0].name +
                      "'  already has this tagid.<br> Please try again with different tagid"
                  );
                else
                  generateAlertMessage(
                    this.dialog,
                    "Duplicate tagid found, the " +
                      apiresponse[0].name +
                      " already has this tagid on " +
                      apiresponse[0].desc +
                      ".<br> Please try again with different tagid"
                  );
                return;
              }
            });
        } else {
          if (parseInt(staffFormValue.role) != this.OldDesignationId)
            this.getStaffRole(staffFormValue);
          else this.checkPagerNumber(staffFormValue);
        }
        //tagid found check end
      }
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - checkTagId() - " + err.message
      );
    }
  }

  checkPagerNumber(staffFormValue) {
    try {
      if (this.staffeditForm.valid) {
        //tagid found check start

        if (this.oldpagernumber != staffFormValue.pager) {
          const pagerfoundItem: checkPagerNumber = {
            authKey: this.authkey,
            staffId: parseInt(staffFormValue.staffId),
            newPagerNum: staffFormValue.pager.toString(),
          };
          this.staffService
            .checkPagerNumber(pagerfoundItem)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: any[]) => {
              if (apiresponse.toString() == "not found") {
                this.checkPhoneNumber(staffFormValue);
              } else {
                this.staffbooledit = true;
                generateAlertMessage(
                  this.dialog,
                  "Duplicate pager number found, the staff '" +
                    apiresponse[0].name +
                    "' already has this pager number.<br> Please try again with different pager number"
                );
                return;
              }
            });
        } else {
          this.checkPhoneNumber(staffFormValue);
        }
        //tagid found check end
      }
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - checkPagerNumber() - " + err.message
      );
    }
  }

  checkPhoneNumber(staffFormValue) {
    try {
      if (this.staffeditForm.valid || this.checkverifyPhoneclick == 1) {
        const phonenumberfoundItem: checkPhoneNumber = {
          authKey: this.authkey,
          newPhoneNumber: staffFormValue.phone.toString(),
          resStaffId: parseInt(staffFormValue.staffId),
          resStaffType: 1,
        };
        this.apiService
          .checkPhoneNumber(phonenumberfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiphoneresponse: TagIdfoundResponse[]) => {
            if (apiphoneresponse != null && apiphoneresponse != undefined) {
              if (
                apiphoneresponse.toString() == "not found" &&
                this.checkverifyPhoneclick == 0
              ) {
                this.checkEmailId(staffFormValue);
              } else if (
                apiphoneresponse.toString() == "not found" &&
                this.checkverifyPhoneclick == 1
              ) {
                this.staffbooledit = false;
                this.checkverifyPhoneclick = 0;
                this.registerPhoneNumberForOTP(staffFormValue);
              } else {
                this.staffbooledit = true;
                this.boolstaffphoneverify = true;
                this.checkverifyPhoneclick = 0;
                generateAlertMessage(
                  this.dialog,
                  "Duplicate phone number found, the " +
                    apiphoneresponse[0].desc +
                    " '" +
                    apiphoneresponse[0].name +
                    "' already has this phone number.<br>Please try again with different phone number"
                );
                return;
              }
            } else {
              this.staffbooledit = true;
              this.boolstaffphoneverify = true;
              generateAlertMessage(this.dialog, "Staff creation failed !");
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - checkPhoneNumber() - " + err.message
      );
    }
  }

  checkEmailId(staffFormValue) {
    try {
      if (this.staffeditForm.valid || this.checkverifyEmailclick == 1) {
        const mailidfoundItem: checkEmailId = {
          authKey: this.authkey,
          newMailId: staffFormValue.email.toString(),
          resStaffId: parseInt(staffFormValue.staffId),
        };
        this.apiService
          .checkEmailId(mailidfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apimailresponse: TagIdfoundResponse[]) => {
            if (apimailresponse != null && apimailresponse != undefined) {
              if (
                apimailresponse.toString() == "not found" &&
                this.checkverifyEmailclick == 0
              ) {
                this.checkGroupNotification(staffFormValue);
              } else if (
                apimailresponse.toString() == "not found" &&
                this.checkverifyEmailclick == 1
              ) {
                this.staffbooledit = false;
                this.checkverifyEmailclick = 0;
                this.registerEmailForOTP(staffFormValue);
              } else {
                this.staffbooledit = true;
                this.boolstaffemailverify = true;
                this.checkverifyEmailclick = 0;
                generateAlertMessage(
                  this.dialog,
                  "Duplicate emailid found, the " +
                    apimailresponse[0].desc +
                    " '" +
                    apimailresponse[0].name +
                    "' already has this emailid.<br>Please try again with different emailid"
                );
                return;
              }
            } else {
              this.staffbooledit = true;
              this.boolstaffemailverify = true;
              generateAlertMessage(this.dialog, "Staff creation failed !");
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - checkEmailId() - " + err.message
      );
    }
  }

  getStaffRole(staffFormValue) {
    try {
      if (this.staffeditForm.valid) {
        const RolefoundItem: getNotifyStaffRole = {
          authKey: this.authkey,
          role: this.OldDesignationId,
          staffId: parseInt(staffFormValue.staffId),
        };
        this.staffService
          .getStaffRole(RolefoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiroleresponse: any[]) => {
            if (apiroleresponse != null && apiroleresponse != undefined) {
              if (apiroleresponse.toString() == "Success") {
                this.checkGroupNotification(staffFormValue);
              } else {
                this.staffbooledit = true;
                generateAlertMessage(
                  this.dialog,
                  "This " +
                    apiroleresponse[0].Designation +
                    " is in escalation level, so please remove this role from escalation and try again."
                );
              }
            } else {
              this.staffbooledit = true;
              generateAlertMessage(this.dialog, "Staff creation failed !");
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - getStaffRole() - " + err.message
      );
    }
  }

  checkGroupNotification(residenteditFormValue) {
    if (
      this.selected_GroupId.indexOf(this.oldGroupId) > -1 &&
      this.oldGroupId.length > 0
    ) {
      const groupNotificationItem: checkGroupNotify = {
        authKey: this.authkey,
        resStaffType: parseInt(enumGroup_Type.Staff.toString()),
        resStaffGroupId: this.selected_GroupId.toString(),
      };
      this.apiService
        .checkGroupNotification(groupNotificationItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.length == 0) {
            this.updateStaffData(residenteditFormValue);
          } else if (apiresponse[0].GroupId > 0) {
            this.staffbooledit = true;
            generateAlertMessage(
              this.dialog,
              'Group "' +
                apiresponse[0].GroupName +
                '" has added in Notification, so please remove this group from notification and try again.'
            );
            return;
          }
        });
    } else {
      this.updateStaffData(residenteditFormValue);
    }
  }

  private updateStaffData = (staffeditForm) => {
    try {
      if (this.uploadStaffimage == "") {
        if (staffeditForm.gender == "Male") {
          if (
            this.imagename == "Default/staff-male.png" ||
            this.imagename == "Default/staff-Female.png"
          )
            this.uploadStaffimage = "Default/staff-male.png";
          else this.uploadStaffimage = this.imagename;
        } else if (staffeditForm.gender == "Female") {
          if (
            this.imagename == "Default/staff-Female.png" ||
            this.imagename == "Default/staff-male.png"
          )
            this.uploadStaffimage = "Default/staff-Female.png";
          else this.uploadStaffimage = this.imagename;
        }
      }

      // let Address, City, State, Zip, Country, Notes;
      let Notes;
      let _phoneNumber = "";
      let _editCheckinvalue;
      let _editNotificationvalue;

      // if (staffeditForm.address == null) Address = "";
      // else Address = staffeditForm.address.replace(/\s+/g, " ").trim();

      // if (staffeditForm.city == null) City = "";
      // else City = staffeditForm.city.replace(/\s+/g, " ").trim();

      // if (staffeditForm.state == null) State = "";
      // else State = staffeditForm.state.replace(/\s+/g, " ").trim();

      // if (staffeditForm.zip == null) Zip = 0;
      // else Zip = staffeditForm.zip;

      // if (staffeditForm.country == null) Country = "";
      // else Country = staffeditForm.country.replace(/\s+/g, " ").trim();

      if (staffeditForm.notes == null) Notes = "";
      else Notes = staffeditForm.notes.replace(/\s+/g, " ").trim();
      
      if (staffeditForm.phone != "")
        _phoneNumber = this.countrycode + staffeditForm.phone;
      
      if (this.editCheck_Invalue == true) _editCheckinvalue = 1;
      else if (this.editCheck_Invalue == false) _editCheckinvalue = 0;            
      
      if(this.editNotificationvalue == true)
        _editNotificationvalue = 1;
      else if(this.editNotificationvalue == false)
        _editNotificationvalue = 0;

      if (staffeditForm.tagid == "") staffeditForm.tagid = 0;

      if (staffeditForm.group == undefined) staffeditForm.group = "";
      
      let editisAlerts;  
      if(this.editChkAlertvalue == true)
        editisAlerts = 1;
      else if(this.editChkAlertvalue == false)
        editisAlerts = 0;  
        
      let editisPhoneAlerts;  
      if(this.editPhoneChkAlertvalue == true)
        editisPhoneAlerts = 1;  
      else if(this.editPhoneChkAlertvalue == false)
        editisPhoneAlerts = 0;    
        
      let selcampusfloorId = 0;
      let isFloor = 0;
      if(staffeditForm.campus.length != 2)
        this.selectedCampusId = staffeditForm.campus.split('_');  
      if(this.selectedCampusId[0] != "null")    
      {
        selcampusfloorId = parseInt(this.selectedCampusId[0]);
        isFloor = parseInt(this.selectedCampusId[1]);
      }      

      const staffedit: StaffEditForCreation = {
        authKey: this.authkey,
        firstName: staffeditForm.firstname.replace(/\s+/g, " ").trim(),
        lastName: staffeditForm.lastname.replace(/\s+/g, " ").trim(),
        gender: staffeditForm.gender.toString(),
        tagId: parseInt(staffeditForm.tagid),
        roleId: parseInt(staffeditForm.role),
        pager: staffeditForm.pager.toString(),
        photo: this.uploadStaffimage,       
        phone: _phoneNumber,
        email: staffeditForm.email.toString(),
        password: staffeditForm.password.toString(),
        notes: Notes.toString(),
        staffId: parseInt(staffeditForm.staffId),
        groupId: staffeditForm.group.toString(),
        verifyNumber: this.updatestaffverify,
        verifyEmail: this.updatestaffemailverify,
        deleteImage: this.deleteImageflag,
        extension: staffeditForm.extension.toString(),
        staffCheckInValue: parseInt(_editCheckinvalue),
        staffNotificationValue : parseInt(_editNotificationvalue),
        isAlertsEmail : editisAlerts,
        campusfloorId : selcampusfloorId,
        isAlertPhone : editisPhoneAlerts,
        isfloor : isFloor
      };

      this.staffService
        .updateStaffData(staffedit)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: StaffForCreationResponse[]) => {
          if (apiresponse != null && apiresponse != undefined) {
            if (apiresponse["apiReturn"] == "Success") {
              this.apiService.reloadStaff();
              this.staffbooledit = false;
              this._authkey = apiresponse["password"].toString();
              if (this.uploadStaffimage != this.staffimagename)
                this.backupPhoto(this.staffimagename);
              const _SharedAlertLoadDetails = {
                SharedreturnVal: 1,
                SharedNotifiedVal: 0,
              };
              this.alertSharedService.changecanceledAlert(
                _SharedAlertLoadDetails
              );
              generateAlertMessage(
                this.dialog,
                "Staff data edited successfully."
              );
            } else if (apiresponse["apiReturn"] == "Fail") {
              this.staffbooledit = true;
              generateAlertMessage(this.dialog, "Staff data edited failed.");
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            } else if (apiresponse["apiReturn"] == "Staff Image not exists") {
              this.staffbooledit = true;
              generateAlertMessage(this.dialog, "Please upload staff image.");
              return;
            }
            else {
              this.staffbooledit = true;
              generateAlertMessage(this.dialog, apiresponse["apiReturn"]);
              return;
            }
          } else {
            this.staffbooledit = true;
            generateAlertMessage(this.dialog, "Staff data edited failed.");
          }

          const getUserName = localStorage.getItem("username");
          if (getUserName != null) this.username = JSON.parse(getUserName);
          this.userimage = localStorage.getItem("Photo");
          this.usertId = localStorage.getItem("userid");
          if (this.usertId == staffeditForm.staffId) {
            this.getUserAuthentication(staffedit, this._authkey);
          }
          this.thisDialogRef.close(staffedit);
        });
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - updateStaffData() - " + err.message
      );
    }
  };

  /////////Show password////////
  password() {
    this.show = !this.show;
    if (this.show) {
      this.passwordIcon = "visibility";
      this.tooltipDesc = "Hide Password";
    } else {
      this.passwordIcon = "visibility_off";
      this.tooltipDesc = "Show Password";
    }
  }

  getUserAuthentication(Result, authpassword) {
    const StaffName = Result.firstName + " " + Result.lastName;
    localStorage.setItem("username", JSON.stringify(StaffName));
    localStorage.setItem("userid", Result.staffId);
    localStorage.setItem("Authkey", authpassword);
    if (
      Result.photo == "Default/staff-male.png" ||
      Result.photo == "Default/staff-Female.png"
    ) {
      if (Result.gender == "Male")
        localStorage.setItem("Photo", "Default/staff-male.png");
      else localStorage.setItem("Photo", "Default/staff-Female.png");
    } else localStorage.setItem("Photo", Result.photo);
    const _ShareUserDetails = {
      IssetUserInfo: 1,
    };
    this.alertSharedService.changedUserInfo(_ShareUserDetails);
  }
  radioChange(event) {
    console.log(event);
    const staffTagId = document.getElementById("stafftagid");
    if (staffTagId != null) staffTagId.focus();
    if(this.staffeditForm.valid)
    {
      this.getstaffnumber(this.staffeditForm.value.phone);
      this.getstaffemail(this.staffeditForm.value.email);
    }
  }

getstaffnumber(value)
{                  
    if (value.length==0)
    {      
      this.buttonvalue = "Enroll";
      this.updatestaffverify = 0;
      this.checkstaffverify = 1;     
      this.editPhoneChkAlertvalue = false;
      this.enablePhoneCheckbox = false;   
    }
    else if(this.staffeditForm.controls.phone.valid && this.staffeditForm.valid)
    {     
      if(value != null)
      {      
        value = value.trim();
        if (value.length > 10)
        {
          this.buttonvalue = "Enroll";
          this.updatestaffverify = 0;
          this.checkstaffverify = 1;             
        }
        else if(this.staffverifiedPhone == value)      
        {
          if(this.isVerifiedOTP == 1)// || (this.oldphoneNumber == value && this.oldphoneNumber != '')
          {
            this.buttonvalue = "Enrolled";
            this.enablePhoneCheckbox = true;
            this.updatestaffverify = 1;
            this.checkstaffverify = 1;        
          }
          else
          {
            this.buttonvalue = "Enroll";
            this.updatestaffverify = 0;
            this.checkstaffverify = 0;
          }
        }
        else if(this.staffverifiedPhone != value)
        {
          if(this.oldphoneNumber == value && this.oldphoneNumber != '' && this.isVerifiedOTP == 1)
          {
            this.buttonvalue = "Enrolled";
            this.enablePhoneCheckbox = true;
            this.updatestaffverify = 1;
            this.checkstaffverify = 1;   
            if(this.editPhoneCheckAlert == 1)            
              this.editPhoneChkAlertvalue = true;    
          }
          else
          {
            this.buttonvalue = "Enroll";
            this.updatestaffverify = 0;
            this.checkstaffverify = 0;
            this.editPhoneChkAlertvalue = false;
            this.enablePhoneCheckbox = false;
          }
        }
      }
    }
    else
    {
      this.buttonvalue = "Enroll";
      this.updatestaffverify = 0;
      this.checkstaffverify = 1;
      this.editPhoneChkAlertvalue = false;
      this.enablePhoneCheckbox = false;  
    }
}
 
  getstaffemail(emailvalue)
  {   
    console.log("editCheck_Invalue : " + this.editCheck_Invalue,this.editChkAlertvalue)
    if (emailvalue.length==0 )
    {    
      this.emailbuttonvalue = "Enroll";
      this.updatestaffemailverify = 0;
      this.checkstaffemailverify = 1;
      this.editCheck_Invalue = false;
      this.editChkAlertvalue = false;
      this.enableCheckbox = false;
    }
    else if(this.staffeditForm.controls.email.valid)
    {          
      if(emailvalue != null && this.OldstaffMailId != '')
      {      
        const pattmatch= emailvalue.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        emailvalue = emailvalue.trim();         
        emailvalue = emailvalue.toLowerCase();   

        if (!pattmatch)
        {
          this.emailbuttonvalue = "Enroll";
          this.updatestaffemailverify = 0;
          this.checkstaffemailverify = 1;
          this.editCheck_Invalue = false;
          this.editChkAlertvalue = false;
          this.enableCheckbox = false;
        }
        else if(this.staffverifiedEmailid == emailvalue)
        {        
          if(this.isEmailVerified == 1 ) //|| this.OldstaffMailId.toLowerCase() == emailvalue
          {
            this.emailbuttonvalue = "Enrolled";
            this.enableCheckbox = true;
            this.updatestaffemailverify = 1;
            this.checkstaffemailverify = 1;
          }
          else
          {
            this.emailbuttonvalue = "Enroll";
            this.updatestaffemailverify = 0;
            this.checkstaffemailverify = 0;
          }
        }
        else if(this.staffverifiedEmailid != emailvalue.toLowerCase())
        {
          if(this.isEmailVerified == 1 && this.OldstaffMailId.toLowerCase() == emailvalue)
          {
            this.emailbuttonvalue = "Enrolled";
            this.enableCheckbox = true; 
            this.updatestaffemailverify = 1;
            this.checkstaffemailverify = 1;
            if(this.editCheckInReport == 1)            
              this.editCheck_Invalue = true;            
            if(this.editCheckAlert == 1)            
              this.editChkAlertvalue = true;            
          }
          else
          {
            this.emailbuttonvalue = "Enroll";
            this.updatestaffemailverify = 0;
            this.checkstaffemailverify = 0;
            this.editCheck_Invalue = false;
            this.editChkAlertvalue = false;
            this.enableCheckbox = false;
          }
        }      
      }
    }
    else
    {
      this.emailbuttonvalue = "Enroll";
      this.updatestaffemailverify = 0;
      this.checkstaffemailverify = 1;
      this.editCheck_Invalue = false;
      this.editChkAlertvalue = false;
      this.enableCheckbox = false;
    }
  }

  onVerifyPhoneNumber(staffFormValue) {
    if (!this.staffeditForm.valid) {
      if(staffFormValue.gender == "")
        this.staffgender = 1;
      return;
    }
    if (this.boolstaffphoneverify) {
      if (staffFormValue.phone == "") {
        this.boolstaffphoneverify = true;
        generateAlertMessage(this.dialog, "Please enter the Phone Number");
        return false;
      } else if (staffFormValue.phone.length > 10) {
        this.boolstaffphoneverify = true;
        generateAlertMessage(this.dialog, "Phone Number should be 10 digits");
        return false;
      } else if (this.staffeditForm.controls.phone.valid) {
        this.checkPhoneclick = 1;
        this.checkverifyPhoneclick = 1;
        this.boolstaffphoneverify = false;
        this.emailLoading = true;

        this.staffEditEnableDisable(enumEnableDisable.Disable);
        this.checknetwork = AppComponent.SharedInternetConnection;
        if (this.checknetwork != undefined) {
          if (this.checknetwork == 0) {
            this.checkPhoneclick = 0;
            generateAlertMessage(
              this.dialog,
              "Failed due to not having internet access."
            );
            this.emailLoading = false;
            this.staffEditEnableDisable(enumEnableDisable.Enable);
            this.boolstaffphoneverify = true;
            this.staffbooledit = true;
            return false;
          } else {
            this.emailLoading = false;
            this.staffEditEnableDisable(enumEnableDisable.Enable);
            this.checkPhoneNumber(staffFormValue);
          }
        } else {
          this.emailLoading = false;
          this.staffEditEnableDisable(enumEnableDisable.Enable);
          this.checkPhoneNumber(staffFormValue);
        }
      } else {
        this.boolstaffphoneverify = true;
        this.staffbooledit = true;
        return false;
      }
    }
  }

  registerPhoneNumberForOTP(staffFormValue) {
    if (!SessionCheck(this.router)) {
      return;
    }
    this.staffphonenumber = staffFormValue.phone;

    this.staffeditForm.controls["phone"].setValidators([
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern(appsettings.Number_Pattern),
    ]);
    this.staffeditForm.controls["phone"].updateValueAndValidity();

    const staffnumber = this.staffphonenumber;

    const staffverifynumber: getPhoneNumberOtp = {
      authKey: this.authkey,
      phoneNumber: staffnumber.toString(),
      countryCode: this.countrycode.toString(),
    };
    this.staffService
      .registerPhoneNumberForOTP(staffverifynumber)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: StaffForCreationResponse[]) => {
        if (apiresponse.toString() == "true") {
          this.emailLoading = false;
          this.staffEditEnableDisable(enumEnableDisable.Enable);
          const AlertMessageData = {
            AlertMessage:
              "Verification code successfully sent to " + staffFormValue.phone,
          };

          const dialogRef = this.dialog.open(AlertMessageComponent, {
            disableClose: true,
            width: "auto",
            height: "auto",
            panelClass: "myapp-no-padding-dialog",
            autoFocus: false,
            data: AlertMessageData,
          });

          dialogRef.afterClosed().subscribe(() => {
            this.staffverifiedPhone = staffFormValue.phone;
            const staffverifyData = {
              StaffVerify: staffFormValue.phone.toString(),
              StaffFlag: 0,
              StaffId: parseInt(staffFormValue.staffId),
              OTPNumber: 0,
            };
            const dialogRef = this.dialog.open(VerifyStaffComponent, {
              disableClose: true,
              width: "450px",
              height: "350px",
              panelClass: "custom-modalbox",
              autoFocus: false,
              data: staffverifyData,
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result == 1) {
                this.boolstaffphoneverify = true;
                this.staffbooledit = true;
                this.buttonvalue = "Enrolled";
                this.enablePhoneCheckbox = true;
                this.isVerifiedOTP = 1;
                this.updatestaffverify = 1;
                this.checkstaffverify = result;
              } else {
                this.boolstaffphoneverify = true;
                this.staffbooledit = true;
                this.buttonvalue = "Enroll";
                this.updatestaffverify = 0;
                this.checkstaffverify = 0;
              }
            });
          });
        } else if (apiresponse.toString() == "false") {
          this.boolstaffphoneverify = true;
          this.staffbooledit = true;
          generateAlertMessage(
            this.dialog,
            "Phone Verification failed due to invalid phone number"
          );
          this.emailLoading = false;
          this.staffEditEnableDisable(enumEnableDisable.Enable);
          return false;
        }
      });
  }

  onVerifyEmail(staffFormValue) {
    if (!this.staffeditForm.valid) {
      if(staffFormValue.gender == "")
        this.staffgender = 1;
      return;
    }
    if (this.boolstaffemailverify) {
      if (staffFormValue.email == "") {
        this.staffbooledit = true;
        this.boolstaffemailverify = true;
        generateAlertMessage(this.dialog, "Please enter the Email Id");
        return false;
      } else {
        this.staffeditForm.controls["email"].setValidators([Validators.required,Validators.email,Validators.pattern(appsettings.Email_Pattern)]);
        this.staffeditForm.controls["email"].updateValueAndValidity();

        this.emailLoading = true;
        this.staffEditEnableDisable(enumEnableDisable.Disable);

        this.boolstaffemailverify = false;
        this.checkEmailclick = 1;
        this.checkverifyEmailclick = 1;
        this.checknetwork = AppComponent.SharedInternetConnection;
        if (this.checknetwork != undefined) {
          if (this.checknetwork == 0) {
            this.checkEmailclick == 0;
            generateAlertMessage(
              this.dialog,
              "Failed due to not having internet access."
            );
            this.emailLoading = false;
            this.staffEditEnableDisable(enumEnableDisable.Enable);
            this.boolstaffemailverify = true;
            this.staffbooledit = true;
            return false;
          } else {
            this.emailLoading = false;
            this.staffEditEnableDisable(enumEnableDisable.Enable);
            this.checkEmailId(staffFormValue);
          }
        } else {
          this.emailLoading = false;
          this.staffEditEnableDisable(enumEnableDisable.Enable);
          this.checkEmailId(staffFormValue);
        }
      }
    }
  }

  registerEmailForOTP(staffFormValue) {
    if (!SessionCheck(this.router)) {
      return;
    }
    this.staffEmailId = staffFormValue.email;
    const staffmailid = staffFormValue.email;

    this.emailLoading = true;
    this.staffEditEnableDisable(enumEnableDisable.Disable);

    const staffverifymaildId: getEmailOtp = {
      authKey: this.authkey,
      emailId: staffmailid.toString(),
      staffId: parseInt(staffFormValue.staffId),
    };
    this.staffService
      .registerEmailForOTP(staffverifymaildId)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: StaffForCreationResponse[]) => {
        if (apiresponse["condition"] == "true") {
          this.emailLoading = false;
          this.staffEditEnableDisable(enumEnableDisable.Enable);
          const AlertMessageData = {
            AlertMessage:
              "Verification code successfully sent to " + staffFormValue.email,
          };

          const dialogRef = this.dialog.open(AlertMessageComponent, {
            disableClose: true,
            width: "auto",
            height: "auto",
            panelClass: "myapp-no-padding-dialog",
            autoFocus: false,
            data: AlertMessageData,
          });

          dialogRef.afterClosed().subscribe(() => {
            this.staffverifiedEmailid = staffFormValue.email.toLowerCase();
            const staffverifyData = {
              StaffVerify: staffFormValue.email,
              StaffFlag: 1,
              StaffId: staffFormValue.staffId,
              OTPNumber: 0,
            };
            const dialogRef = this.dialog.open(VerifyStaffComponent, {
              disableClose: true,
              width: "450px",
              height: "300px",
              panelClass: "custom-modalbox",
              autoFocus: false,
              data: staffverifyData,
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result == 1) {
                this.boolstaffemailverify = true;
                this.staffbooledit = true;
                this.emailbuttonvalue = "Enrolled";
                this.enableCheckbox = true; 
                this.isEmailVerified = 1;
                this.updatestaffemailverify = 1;
                this.checkstaffemailverify = result;
              } else {
                this.boolstaffemailverify = true;
                this.staffbooledit = true;
                this.emailbuttonvalue = "Enroll";
                this.updatestaffemailverify = 0;
                this.checkstaffemailverify = 0;
              }
            });
          });
        } else if (apiresponse["condition"] == "false") {
          this.boolstaffemailverify = true;
          this.staffbooledit = true;
          generateAlertMessage(this.dialog, "Email Verification failed");
          this.emailLoading = false;
          this.staffEditEnableDisable(enumEnableDisable.Enable);
          return false;
        }
      });
  }

  deletestaffimage() {
    try {
      const ConfirmMessageData = {
        ConfirmMessage: "Are you sure you want to delete the staff photo?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "auto",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          try {
            this.staffdefaultimage = 1;
            this.deleteImageflag = 1;
            this.staffeditimageUrl = "assets/images/admin-avatar.png";
            this.uploadStaffimage = "Default/staff-Female.png";
          } catch (err) {
            throw new Error(
              "edit-staff.component.ts - onClickedYes() - " + err.message
            );
          }
        }
      });
    } catch (err) {
      throw new Error(
        "edit-staff.component.ts" + " - deletestaffimage() - " + err.message
      );
    }
  }

  toggleEditCheckInVisibility(event) {
    if (event.checked == true) {
      this.editCheckInReport = 1;
      this.editCheck_Invalue = true;
    } else {
      this.editCheckInReport = 0;
      this.editCheck_Invalue = false;
    }
  }
  
  toggleEditAlertVisibility(event)
  {
    if(event.checked == true)
    {
      this.editCheckAlert = 1;
      this.editChkAlertvalue = true;       
    }
    else
    {      
      this.editCheckAlert = 0;
      this.editChkAlertvalue = false;         
    }
  }
  
  toggleEditPhoneAlertVisibility(event)
  {
    if(event.checked == true)
    {
      this.editPhoneCheckAlert = 1;
      this.editPhoneChkAlertvalue = true;       
    }
    else
    {      
      this.editPhoneCheckAlert = 0;
      this.editPhoneChkAlertvalue = false;         
    }
  }
  
  toggleEditNotifyVisibility(event)
  {
      if(event.checked == true)
      {
        this.editNotificationvalue = true;       
      }
      else
      {      
        this.editNotificationvalue = false;         
      }
  } 
  
  changeEditDesignationList(RoleId)
  {
    if(RoleId == 1)
    {
      this.editNotificationvalue = false;
      this.editroleEnableNotify = true;    
    }
    else
    {    
      this.editroleEnableNotify = false; 
      this.editNotificationvalue = this.oldeditNotificationvalue;   
    }
  }

  staffEditEnableDisable(checkflag) {
    const elementcontent = document.getElementById("staffeditdialog");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "enablecontent";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "disablecontent";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
