import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  leftmenu,
  singleChartvalue,
  multiChartvalue,
  AlertCountValue,
  AlertFilterValue,
  AlertReportDetails,
} from "./report";
import { InfrastructureReportDetails } from "../../api";
import { appsettings } from "../../app-settings";
import {
  ReportHttpErrorHandler,
  HandleError,
} from "./http-error-handler.service";
import { Observable } from "rxjs";
import { getFeatureNeedtoShow } from "../../app-general";
import {
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../app-settings";

const defaultMenuCount = 8;

@Injectable({
  providedIn: "root",
})
export class ReportService {
  public toggleReportMenuNav: EventEmitter<any> = new EventEmitter();
  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: ReportHttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError("ResidentService");
  }

  generateAverageResponseTimeUrl =
    appsettings.API_ENDPOINT + "reports/generateAverageResponseTime";
  generateEscaltionChartUrl =
    appsettings.API_ENDPOINT + "reports/generateEscalationChart";
  generateAlertLocationReportUrl =
    appsettings.API_ENDPOINT + "reports/generateAlertLocationReport";
  generateAlertReportListByFilterUrl =
    appsettings.API_ENDPOINT + "reports/generateAlertReportListByFilter";
  getAlertReportUrl = appsettings.API_ENDPOINT + "reports/getAlertReport";
  getInfrastructureReportUrl =
    appsettings.API_ENDPOINT + "reports/getInfrastructureReport";
  getLocationHistoryListByIdUrl =
    appsettings.API_ENDPOINT + "reports/getLocationHistoryListById";
  getCustomizedLocatorsReportUrl =
    appsettings.API_ENDPOINT + "reports/getCustomizedLocatorsReport";
  saveCustomizeLocatorsReportUrl =
    appsettings.API_ENDPOINT + "reports/saveCustomizeLocatorsReport";
  getLocationTypeUrl = appsettings.API_ENDPOINT + "reports/getLocationType";
  getCustomizedGeoUrl = appsettings.API_ENDPOINT + "reports/getCustomizedGeo";
  updateColorConfigTypeUrl =
    appsettings.API_ENDPOINT + "reports/updateColorConfigType";
  addLiveChangeStateUrl =
    appsettings.API_ENDPOINT + "reports/addLiveChangeState";
  updateAssetNameUrl = appsettings.API_ENDPOINT + "reports/updateAssetName";
  getAlertTypesUrl = appsettings.API_ENDPOINT + "reports/getAlertTypes";
  getAlertLocationsUrl = appsettings.API_ENDPOINT + "reports/getAlertLocations";
  getAllLocationHistoryUrl =
    appsettings.API_ENDPOINT + "reports/getAllLocationHistory";

  getleftmenu() {
    let showAsset = false;
    const LEFTMENU: leftmenu[] = [];

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Main_Dashboard
      ) == enumUIView.Show
    ) {
      LEFTMENU.push(
        {
          id: 1,
          leftmenu: "campusdashboard",
          routerLink: "../campusdashboard",
          src: "today",
          description: "Campus Dashboard",
        },
        {
          id: 2,
          leftmenu: "residentassessment",
          routerLink: "../residentassessment",
          src: "view_week",
          description: "Resident Assessment",
        }
      );
    }
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Alert_Report
      ) == enumUIView.Show
    ) {
      LEFTMENU.push({
        id: 3,
        leftmenu: "alertReport",
        routerLink: "../alertreport",
        src: "history",
        description: "Alert Report",
      });
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Infrastructure_Report
      ) == enumUIView.Show
    ) {
      LEFTMENU.push({
        id: 4,
        leftmenu: "infrastructureReport",
        routerLink: "../infrastructurereport",
        src: "bug_report",
        description: "Infrastructure Report",
      });
    }

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Report,
        enumUIAction.Report_Location_History_Report
      ) == enumUIView.Show
    ) {
      LEFTMENU.push({
        id: 5,
        leftmenu: "locationhistroyReport",
        routerLink: "../locationhistory",
        src: "room",
        description: "Location History Report",
      });
    }

    if (
      getFeatureNeedtoShow(enumUIFeatureView.Asset, enumUIAction.Asset_View) ==
      enumUIView.Show
    ) {
      showAsset = true;
    }

    if (localStorage.getItem("asset_config") == "1" && showAsset == true) {
      if (LEFTMENU.length <= defaultMenuCount) {
        LEFTMENU.push(
          {
            id: 6,
            leftmenu: "customizelocatorsReport",
            routerLink: "../customizelocators",
            src: "person_pin_circle",
            description: "Customize Locators",
          },
          {
            id: 7,
            leftmenu: "liveviewReport",
            routerLink: "../liveview",
            src: "rss_feed",
            description: "Live View",
          },
          {
            id: 8,
            leftmenu: "customizegeoReport",
            routerLink: "../customizegeo",
            src: "vibration",
            description: "Customize Geo",
          }
        );
      }
    } else if (
      localStorage.getItem("asset_config") == "0" &&
      showAsset == false
    ) {
      if (LEFTMENU.length >= defaultMenuCount) {
        delete LEFTMENU[8];
        delete LEFTMENU[7];
        delete LEFTMENU[6];
      }
    }

    return LEFTMENU;
  }

  generateAverageResponseTime(chartvalues): Observable<singleChartvalue[]> {
    return this.http.post<singleChartvalue[]>(
      this.generateAverageResponseTimeUrl,
      chartvalues
    );
  }

  generateEscaltionChart(chartvalues): Observable<multiChartvalue[]> {
    return this.http.post<multiChartvalue[]>(
      this.generateEscaltionChartUrl,
      chartvalues
    );
  }

  generateAlertLocationReport(chartvalues): Observable<AlertCountValue[]> {
    return this.http.post<AlertCountValue[]>(
      this.generateAlertLocationReportUrl,
      chartvalues
    );
  }

  generateAlertReportListByFilter(chartvalues): Observable<AlertFilterValue[]> {
    return this.http.post<AlertFilterValue[]>(
      this.generateAlertReportListByFilterUrl,
      chartvalues
    );
  }

  getAlertReport(alertitem): Observable<AlertReportDetails[]> {
    return this.http.post<AlertReportDetails[]>(
      this.getAlertReportUrl,
      alertitem
    );
  }

  getInfrastructureReport(
    infraitem
  ): Observable<InfrastructureReportDetails[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.getInfrastructureReportUrl,
      infraitem
    );
  }

  getLocationHistoryListById(
    locationitem
  ): Observable<InfrastructureReportDetails[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.getLocationHistoryListByIdUrl,
      locationitem
    );
  }
  getCustomizedLocatorsReport(cusLocatorsdata): Observable<any[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.getCustomizedLocatorsReportUrl,
      cusLocatorsdata
    );
  }
  saveCustomizeLocatorsReport(cusLocatorsitem): Observable<any[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.saveCustomizeLocatorsReportUrl,
      cusLocatorsitem
    );
  }
  getLocationType(locationtype): Observable<any[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.getLocationTypeUrl,
      locationtype
    );
  }
  getCustomizedGeo(geodata): Observable<any[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.getCustomizedGeoUrl,
      geodata
    );
  }
  updateColorConfigType(data): Observable<any[]> {
    return this.http.post<any[]>(this.updateColorConfigTypeUrl, data);
  }

  addLiveChangeState(item): Observable<any[]> {
    return this.http.post<any[]>(this.addLiveChangeStateUrl, item);
  }
  updateAssetName(item): Observable<any[]> {
    return this.http.post<any[]>(this.updateAssetNameUrl, item);
  }
  getAlertTypes(item): Observable<any[]> {
    return this.http.post<any[]>(this.getAlertTypesUrl, item);
  }
  getAlertLocations(item): Observable<any[]> {
    return this.http.post<any[]>(this.getAlertLocationsUrl, item);
  }
  getAllLocationHistory(locationitem): Observable<any[]> {
    return this.http.post<InfrastructureReportDetails[]>(
      this.getAllLocationHistoryUrl,
      locationitem
    );
  }
}
