import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { ReportService } from "../report.service";
import { getLocationType, getCustomizedGeo } from "../report";
import {
  report_leftmenu,
  NewDeviceTypeDesc,
  enumReportState,
} from "../../../app-settings";
import { SessionCheckwithStaff } from "../../../app-general";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AddAssetComponent } from "../../asset/add-asset/add-asset.component";
import { ReportEditassetComponent } from "../report-editasset/report-editasset.component";
import { MainLayoutService } from "../../../main-layout/main-layout.service";
import { InfrastructureAlertsComponent } from "../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component";
import { SidenavComponent } from "../../../main-layout/sidenav/sidenav.component";
import { ReportHeaderComponent } from "../report-header/report-header.component";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-customizegeo",
  templateUrl: "./customizegeo.component.html",
  styleUrls: ["./customizegeo.component.scss"],
})
export class CustomizegeoComponent implements AfterViewInit {
  ReportType = report_leftmenu.customizegeoReport;
  private _onDestroy: Subject<void> = new Subject<void>();
  public EnumNewDeviceType = NewDeviceTypeDesc;
  public EnumReportState = enumReportState;
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private reportService: ReportService,    
    public dialog: MatDialog,
    private router: Router,
    public mainLayoutService: MainLayoutService,
    private breakpointObserver: BreakpointObserver
  ) {   
      this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.setupcusgeoFullTable();
    }  
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.setupcusgeoTableformidwidth();
    }    
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupcusgeoTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupcusgeoTableforXtrasmallerwidth();
    }
  }
  
  

  displayedCustomizeGeoColumns: string[] = [
    "G_GeoName",
    "G_Edit",
    "R_TagId",
    "G_TruLocation",
    "G_Updatedon",
    "G_roomcolor",
  ];
  todaydate;
  Todaydate;
  customizegeoloading = true;
  CustomizeGeoReport;
  CustomizeGeoSource;
  cusloc_selectStateBy;
  selectedLocationType;
  LocationType;
  chkCustomizeGeosearchvalue;
  CusLocfilteredArray = [] as any[];
  cusloc_selstatechange = [];
  authkey;
  @ViewChild("searchCusGeoInput") searchCusGeoInput: ElementRef;
  @ViewChild("CusGeosort") CusGeosort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  //CutomizeGeo Reports
  setupcusgeoTableforXtrasmallerwidth() {
    this.displayedCustomizeGeoColumns = ["G_GeoName", "R_TagId", "G_Updatedon"];
  }
  setupcusgeoTableforsmallerwidth() {
    this.displayedCustomizeGeoColumns = ["G_GeoName", "R_TagId", "G_Updatedon"];
  }
  setupcusgeoTableformidwidth() {
    this.displayedCustomizeGeoColumns = [
      "G_GeoName",
      "G_Edit",
      "R_TagId",
      "G_TruLocation",
      "G_Updatedon",
    ];
  }
  setupcusgeoFullTable() {
    this.displayedCustomizeGeoColumns = [
      "G_GeoName",
      "G_Edit",
      "R_TagId",
      "G_TruLocation",
      "G_Updatedon",
      "G_roomcolor",
    ];
  }

  ngAfterViewInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.getLocationType();
  }

  getLocationType() {
    try {
      this.customizegeoloading = true;
      const locationtypeitem: getLocationType = {
        authKey: this.authkey,
      };
      this.reportService
        .getLocationType(locationtypeitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((LocationTypeReport: any[]) => {
          this.LocationType = LocationTypeReport;
        });
      this.getCustomizedGeo();
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getLocationType() - " + err.message
      );
    }
  }

  getCustomizedGeo() {
    try {
      this.customizegeoloading = true;
      this.searchCusGeoInput.nativeElement.value = "";
      const dateformat = localStorage.getItem("dateFormat") ?? "";
      const customgeoitem: getCustomizedGeo = {
        authKey: this.authkey,
        dateFormat: dateformat,
      };
      this.reportService
        .getCustomizedGeo(customgeoitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CustomizeGeoData: any[]) => {
          this.customizegeoloading = false;
          this.cusloc_selectStateBy = 0;
          this.selectedLocationType = 0;
          this.CustomizeGeoReport = CustomizeGeoData;

          this.CustomizeGeoSource = new MatTableDataSource(
            this.CustomizeGeoReport
          );
          this.CustomizeGeoSource.data = CustomizeGeoData;
          this.CustomizeGeoSource.paginator = this.paginator.toArray()[0];
          setTimeout(() => {
            this.CustomizeGeoSource.sort = this.CusGeosort;
            this.sortingDatalist_CustomizeGeoReport(this.CustomizeGeoSource);
          }, 0);
        });
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - getCustomizedGeo() - " + err.message
      );
    }
  }

  searchCustomGeoReport(filterValue: string) {
    try {
      if (filterValue != null) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.CustomizeGeoSource.filter = filterValue;
        this.chkCustomizeGeosearchvalue = filterValue;
        this.getfilterCusGeodetails();
        // this.LiveviewData = this.dataSource.filteredData;
      }
    } catch (err) {
      throw new Error(
        "report.component.ts" + " - searchCustomGeoReport() - " + err.message
      );
    }
  }

  cusloc_selectLocationType(selectLocationType) {
    try {
      this.selectedLocationType = selectLocationType;
      this.getfilterCusGeodetails();
    } catch (err) {
      throw new Error(
        "report.component.ts" +
          " - cusloc_selectLocationType() - " +
          err.message
      );
    }
  }

  getfilterCusGeodetails() {
    let _searchfilterData = "";
    let filtersearch;
    const _filterlocationtype = this.selectedLocationType;
    let _filterLiveviewdata;
    _filterLiveviewdata = this.CustomizeGeoReport;
    let search_roomcolor = "";

    //////LocationTypefilter////////
    if (_filterlocationtype == "All")
      _filterLiveviewdata = this.CustomizeGeoReport;
    else if (_filterlocationtype != 0) {
      this.CusLocfilteredArray = [];
      const list = this.CustomizeGeoReport.filter(
        (x) => x.roomcolor == _filterlocationtype
      );
      list.forEach((x) => {
        this.CusLocfilteredArray.push(x);
      });
      _filterLiveviewdata = this.CusLocfilteredArray;
    } else {
      _filterLiveviewdata = this.CustomizeGeoReport;
    }

    //////Searchfilter////////
    if (this.chkCustomizeGeosearchvalue) {
      _searchfilterData = "";

      filtersearch = this.chkCustomizeGeosearchvalue;

      _searchfilterData = _filterLiveviewdata.filter(function (data) {
        if (data.FullTypeName != null) {
          search_roomcolor = data.FullTypeName.toLowerCase().includes(
            filtersearch.toLowerCase()
          );
        } else search_roomcolor = "";

        return (
          data.FullGeoName.toLowerCase().includes(filtersearch.toLowerCase()) ||
          data.FullTruLocation.toLowerCase().includes(
            filtersearch.toLowerCase()
          ) ||
          data.Updatedon.toLowerCase().includes(filtersearch.toLowerCase()) ||
          search_roomcolor ||
          data.R_TagId.toString().includes(filtersearch)
        );
      });
    } else {
      _searchfilterData = _filterLiveviewdata;
    }
    this.CustomizeGeoSource.data = _searchfilterData;
    setTimeout(() => {
      this.CustomizeGeoSource.sort = this.CusGeosort;
      this.sortingDatalist_CustomizeGeoReport(this.CustomizeGeoSource);
    }, 0);
  }

  addAssetdetails() {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    const assetData = { Data: this.LocationType, Assetflag: 1 };
    const dialogRef = this.dialog.open(AddAssetComponent, {
      disableClose: true,
      width: "900px",
      height: "350px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: assetData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  CusGeo_editAssetName(GeoData) {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }
    const dialogRef = this.dialog.open(ReportEditassetComponent, {
      disableClose: true,
      width: "500px",
      height: "250px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: GeoData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != "Cancel") this.getCustomizedGeo();
    });
  }

  sortingDatalist_CustomizeGeoReport(DataSource) {
    DataSource.sortingDataAccessor = function (data, sortHeaderId: string) {
      if (sortHeaderId == "G_GeoName" || sortHeaderId == "G_TruLocation") {
        return data[sortHeaderId].toString().toLocaleLowerCase().trim("");
      } else {
        return data[sortHeaderId];
      }
    };
  }
  CloseRightNav() {
    if (SidenavComponent.isClosed == false) {
      this.mainLayoutService.toggleSideNavRight.emit();
    }
    if (InfrastructureAlertsComponent.isinfraClosed == false) {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    }
    if (ReportHeaderComponent.isReportMenuClosed == false) {
      this.reportService.toggleReportMenuNav.emit();
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
