import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { ApiService } from "../../../api.service";
import {
  getSafeZoneID,
  getCampusdetails,
  ResGroup,
  Resident,
  Staff,
  getResidentRequest,
  getGroupData,
} from "../../../api";
import {
  WanterforCreationResponse,
  ResidentGroup,
  WanderConfig,
  getSafeZone,
  updateWanderConfig,
  checkWanderConfig,
} from "../wanderconfig";
import { WanderService } from "../wanderconfig.service";
import {
  enumgetResidentData,
  enumResEgress_Type,
  enumChronoLogical,
} from "../../../app-settings";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../app-general";
import { Router } from "@angular/router";
import {
  TreeviewItem,
  DownlineTreeviewItem,
  TreeviewEventParser,
  TreeviewConfig,
  OrderDownlineTreeviewEventParser,
  TreeviewI18n,
  TreeviewI18nDefault,
  TreeviewHelper,
} from "ngx-treeview";
import { isNil, reverse } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TooltipPosition } from "@angular/material/tooltip";
@Component({
  selector: "app-edit-wanderconfig",
  templateUrl: "./edit-wanderconfig.component.html",
  providers: [
    WanderService,
    {
      provide: TreeviewEventParser,
      useClass: OrderDownlineTreeviewEventParser,
    },
    { provide: TreeviewConfig, useClass: WanderService },
    {
      provide: TreeviewI18n,
      useValue: Object.assign(new TreeviewI18nDefault(), {
        getFilterPlaceholder(): string {
          return "Search";
        },
      }),
    },
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-wanderconfig.component.scss"],
})
export class EditWanderconfigComponent implements OnInit, OnDestroy {
  public editwanderConfigForm: UntypedFormGroup;
  editwanderbool = true;
  Residentdetails: Resident[];
  staffdetails: Staff[];
  Campusdetails: getCampusdetails[];
  Residentgroupdetails: ResGroup[];
  Staffgroupdetails: ResidentGroup[];
  checkeditrestype;
  checkeditstafftype;
  editselresident;
  editselgroup;
  editrestype;

  Residentgroups = [] as any[];
  selectedCampusvalue = false;
  selectedBuildingvalue = false;
  selectedFloorvalue = false;
  selectedRoomvalue = false;
  Wanderconfigdetails: WanderConfig[];
  checkwanderdata;
  oldresidentId;
  oldresidentgroup;
  getroomdetails = [];
  buildings;
  Residentindividual = [];

  CampusId;
  BuildingId;
  FloorId;
  RoomId;

  WrongRoom;
  WchkAllday;
  AllEgress;
  AchkAllday;

  arrallCampusid = [] as any[];
  campusidList = [] as any[];
  items: TreeviewItem[];

  sel_CampusId = [] as any[];
  sel_BuildingId = [] as any[];
  sel_FloorId = [] as any[];
  sel_RoomId = [] as any[];
  sel_SafeZoneID;

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 160,
  });
  WrongRoom_flag;
  private _onDestroy: Subject<void> = new Subject<void>();

  editresloading = true;
  residentRoomSelect = "";
  egressRoomSelect = "";
  checkWrongRoom = true;

  matTooltipAdvSettings =
    "Select/De-Select Zone(s)/Space(s). Selecting a Zone/Space indicates an unsafe zone/space for this resident.";
  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ];
  position = new UntypedFormControl(this.positionOptions[3]);
  authkey;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public thisDialogRef: MatDialogRef<EditWanderconfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private wanderService: WanderService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.sel_SafeZoneID = data[0].SafeZoneId;
    if (data[0].CampusId != null)
      this.sel_CampusId = data[0].CampusId.split(",");
    if (data[0].BuildId != null)
      this.sel_BuildingId = data[0].BuildId.split(",");
    if (data[0].FloorId != null) this.sel_FloorId = data[0].FloorId.split(",");
    if (data[0].SafeZone != null) this.sel_RoomId = data[0].SafeZone.split(",");

    this.editselresident = data[0].Resident_GroupId;
    this.oldresidentId = data[0].Resident_GroupId;
    this.editselgroup = data[0].Resident_GroupId;
    this.editrestype = data[0].ResidentType;
    this.oldresidentgroup = data[0].ResidentType;

    if (data[0].ResidentType == 0) {
      this.checkeditrestype = 0;
      this.getResidentData();
    } else {
      this.checkeditrestype = 1;
      this.getResidentGroup();
    }

    this.WrongRoom = data[0].WrongRoom;
    this.WchkAllday = data[0].WchkAllday;
    this.AllEgress = data[0].AllEgress;
    this.AchkAllday = data[0].AchkAllday;
  }

  ngOnInit() {
    this.getSafeZoneconfig();
    this.getResidentData();
    this.getResidentUnsafeZones();
    this.WrongRoom_flag = 0;
    this.editwanderConfigForm = new UntypedFormGroup({
      residenttype: new UntypedFormControl(""),
      safezoneId: new UntypedFormControl(""),
      resident: new UntypedFormControl(""),
    });
  }

  selectresident(SelectedResidentId) {
    //alert("test :"+ SelectedResidentId);
    let ResidentInfo = [] as any[];
    ResidentInfo = this.Residentdetails.filter(function (e1) {
      return e1.ResidentID == SelectedResidentId;
    });

    this.WrongRoom_flag = 0;
    if (
      ResidentInfo[0].WrongRoom == "true" &&
      ResidentInfo[0].AllEgress == "true"
    ) {
      this.WrongRoom_flag = 3;
    } else if (ResidentInfo[0].AllEgress == "true") {
      this.WrongRoom_flag = 2;
    } else if (ResidentInfo[0].WrongRoom == "true") {
      this.WrongRoom_flag = 1;
    }
    this.tickanduntickSpaces();
  }
  onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
    try {
      this.sel_CampusId = [];
      this.sel_BuildingId = [];
      this.sel_FloorId = [];
      this.sel_RoomId = [];

      downlineItems.forEach((downlineItem) => {
        const item = downlineItem.item;
        const value = item.value;
        const texts = [] as any[];
        let parent = downlineItem.parent;
        while (!isNil(parent)) {
          texts.push(parent.item.value); //texts.push(parent.item.text+":"+parent.item.value);
          parent = parent.parent;
        }
        const reverseTexts = reverse(texts);
        const row = `${reverseTexts.join(":")}:${value}`;
        const arr_row = row.split(":");

        if (arr_row.length > 0 && !this.sel_CampusId.includes(arr_row[0]))
          this.sel_CampusId[this.sel_CampusId.length] = arr_row[0];

        if (arr_row.length > 1 && !this.sel_BuildingId.includes(arr_row[1]))
          this.sel_BuildingId[this.sel_BuildingId.length] = arr_row[1];

        if (arr_row.length > 2 && !this.sel_FloorId.includes(arr_row[2]))
          this.sel_FloorId[this.sel_FloorId.length] = arr_row[2];

        if (
          arr_row.length > 3 &&
          !this.sel_RoomId.includes(arr_row[3]) &&
          item.disabled == false
        )
          this.sel_RoomId[this.sel_RoomId.length] = arr_row[3];
      });
    } catch (err) {
      throw new Error(
        "edit-wanderconfig.component.ts" +
          " - onSelectedChange() - " +
          err.message
      );
    }
  }

  getProducts(): TreeviewItem[] {
    const Tree_arrallCampusid = [];
    try {
      for (const key in this.arrallCampusid) {
        const data = this.arrallCampusid[key];
        if (this.arrallCampusid[key].children.length > 0) {
          const TreeData = new TreeviewItem(data);
          Tree_arrallCampusid[Tree_arrallCampusid.length] = TreeData;
        }
      }
    } catch (err) {
      throw new Error(
        "edit-wanderconfig.component.ts" + " - getProducts() - " + err.message
      );
    }
    this.editresloading = false;
    return Tree_arrallCampusid;
  }

  getSafeZoneconfig(): void {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      const wanderitem: getSafeZone = {
        authKey: this.authkey,
      };
      this.wanderService
        .getSafeZone(wanderitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((WanderConfig: any[]) => {
          if (WanderConfig["condition"] == "Authfalse") {
            this.thisDialogRef.close("close");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Wanderconfigdetails = WanderConfig;
          this.checkwanderdata = this.Wanderconfigdetails;
        });
    } catch (err) {
      throw new Error(
        "edit-wanderconfig.component.ts" +
          " - getSafeZoneconfig() - " +
          err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  oneditResidentChange(resvalue) {
    if (resvalue == 0) {
      this.WrongRoom_flag = 0;
      this.tickanduntickSpaces();
      this.editselresident = "Select Resident";
      this.editselgroup = "";
      this.checkeditrestype = resvalue;
      this.getResidentData();
    } else if (resvalue == 1) {
      this.WrongRoom_flag = 0;
      this.tickanduntickSpaces();
      this.editselgroup = "Select Group";
      this.editselresident = "";
      this.checkeditrestype = resvalue;
      this.getResidentGroup();
    }
  }

  onChange(mrChange) {
    this.oneditResidentChange(mrChange.value);
  }

  getResidentData(): void {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      const residentdata: getResidentRequest = {
        authKey: this.authkey,
        residentType: enumgetResidentData.ActiveData,
        isChronoLogical: enumChronoLogical.alphanumeric,
      };
      this.apiService
        .getResidentData(residentdata)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Residents: any[]) => {
          if (Residents["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Residentdetails = Residents["apiReturn"];
        });
    } catch (err) {
      throw new Error(
        "edit-wanderconfig.component.ts" +
          " - getResidentData() - " +
          err.message
      );
    }
  }
  getResidentGroup(): void {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      const grouptype: getGroupData = {
        authKey: this.authkey,
      };
      this.apiService
        .getResidentGroup(grouptype)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((ResidentGroups: any[]) => {
          if (ResidentGroups["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.Residentgroupdetails = ResidentGroups["apiReturn"];
          this.Residentgroups = [];
          for (let i = 0; i < this.Residentgroupdetails.length; i++) {
            if (this.Residentgroupdetails[i].ResidentName) {
              this.Residentgroups.push(this.Residentgroupdetails[i]);
            }
          }
        });
    } catch (err) {
      throw new Error(
        "edit-wanderconfig.component.ts" +
          " - getResidentGroup() - " +
          err.message
      );
    }
  }

  getResidentUnsafeZones() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.editresloading = true;
      const campusid: getSafeZoneID = {
        authKey: this.authkey,
        safeZoneId: parseInt(this.sel_SafeZoneID),
      };
      this.wanderService
        .getResidentUnsafeZones(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusDetails: any[]) => {
          this.Campusdetails = CampusDetails;
          if (CampusDetails != null && CampusDetails != undefined) {
            this.arrallCampusid = CampusDetails["Campusdetails"];
            localStorage.setItem(
              "RoamingRomeList",
              JSON.stringify(this.arrallCampusid)
            );
            this.items = this.getProducts();
          }

          //this.WrongRoom_flag =0;
          if (this.WrongRoom == "true" && this.AllEgress == "true") {
            this.WrongRoom_flag = 3;
            this.tickanduntickSpaces();
          } else if (this.AllEgress == "true") {
            this.WrongRoom_flag = 2;
            this.tickanduntickSpaces();
          } else if (this.WrongRoom == "true") {
            this.WrongRoom_flag = 1;
            this.tickanduntickSpaces();
          }
        });
    } catch (err) {
      throw new Error(
        "edit-wanderconfig.component.ts" +
          " - getResidentUnsafeZones() - " +
          err.message
      );
    }
  }

  tickanduntickSpaces() {
    if (this.WrongRoom_flag == 0) {
      this.onSelectRoomdetails(" ", this.checkWrongRoom);
    } else if (this.WrongRoom_flag == 1) {
      this.residentRoomSelect = enumResEgress_Type.ResidentRoom;
      this.onSelectRoomdetails(this.residentRoomSelect, this.checkWrongRoom);
    } else if (this.WrongRoom_flag == 2) {
      this.residentRoomSelect = enumResEgress_Type.EgressRoom;
      this.onSelectRoomdetails(this.residentRoomSelect, this.checkWrongRoom);
    } else if (this.WrongRoom_flag == 3) {
      this.onSelectBothRoomdetails(
        enumResEgress_Type.ResidentRoom,
        enumResEgress_Type.EgressRoom,
        this.checkWrongRoom
      );
    }
  }

  onSelectRoomdetails(checkselectitem, checkedvalue) {
    this.editresloading = true;
    const campusid_List = localStorage.getItem("RoamingRomeList");
    if (campusid_List != null) this.campusidList = JSON.parse(campusid_List);

    for (let ncampus = 0; ncampus < this.arrallCampusid.length; ncampus++) {
      for (
        let nbuild = 0;
        nbuild < this.arrallCampusid[ncampus].children.length;
        nbuild++
      ) {
        for (
          let nfloor = 0;
          nfloor <
          this.arrallCampusid[ncampus].children[nbuild].children.length;
          nfloor++
        ) {
          for (
            let nroom = 0;
            nroom <
            this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
              .children.length;
            nroom++
          ) {
            if (
              this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                .children[nroom].Category != "L"
            )
              TreeviewHelper.findItemInList(
                this.arrallCampusid,
                this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                  .children[nroom].value
              ).disabled = false;
            if (
              this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                .children[nroom].Category == checkselectitem
            ) {
              if (checkedvalue == true) {
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).checked = true;
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).disabled = true;
              } else if (checkedvalue == false) {
                if (
                  this.campusidList[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].checked == false
                )
                  TreeviewHelper.findItemInList(
                    this.arrallCampusid,
                    this.arrallCampusid[ncampus].children[nbuild].children[
                      nfloor
                    ].children[nroom].value
                  ).checked = false;
              }
            } else {
              if (
                this.campusidList[ncampus].children[nbuild].children[nfloor]
                  .children[nroom].checked == false
              )
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).checked = false;
            }
          }
        }
      }
    }
    this.items = this.getProducts();
  }

  onSelectBothRoomdetails(checkselectitem1, checkselectitem2, checkedvalue) {
    this.editresloading = true;
    const campusid_List = localStorage.getItem("RoamingRomeList");
    if (campusid_List != null) this.campusidList = JSON.parse(campusid_List);
    for (let ncampus = 0; ncampus < this.arrallCampusid.length; ncampus++) {
      for (
        let nbuild = 0;
        nbuild < this.arrallCampusid[ncampus].children.length;
        nbuild++
      ) {
        for (
          let nfloor = 0;
          nfloor <
          this.arrallCampusid[ncampus].children[nbuild].children.length;
          nfloor++
        ) {
          for (
            let nroom = 0;
            nroom <
            this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
              .children.length;
            nroom++
          ) {
            if (
              this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                .children[nroom].Category == checkselectitem1 ||
              this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                .children[nroom].Category == checkselectitem2
            ) {
              if (
                this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                  .children[nroom].Category != "L"
              )
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).disabled = false;
              if (checkedvalue == true) {
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).checked = true;
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).disabled = true;
              } else if (checkedvalue == false) {
                if (
                  this.campusidList[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].checked == false
                )
                  TreeviewHelper.findItemInList(
                    this.arrallCampusid,
                    this.arrallCampusid[ncampus].children[nbuild].children[
                      nfloor
                    ].children[nroom].value
                  ).checked = false;
              }
            } else {
              if (
                this.campusidList[ncampus].children[nbuild].children[nfloor]
                  .children[nroom].checked == false
              ) {
                TreeviewHelper.findItemInList(
                  this.arrallCampusid,
                  this.arrallCampusid[ncampus].children[nbuild].children[nfloor]
                    .children[nroom].value
                ).checked = false;
              }
            }
          }
        }
      }
    }
    this.items = this.getProducts();
  }

  public editWanderConfig = (editwanderconfigFormValue) => {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (this.editwanderbool) {
        this.editwanderbool = false;

        if (editwanderconfigFormValue.resident == "Select Resident") {
          generateAlertMessage(this.dialog, "Please select the resident");
          this.editwanderbool = true;
          return false;
        } else if (editwanderconfigFormValue.resident == "Select Group") {
          generateAlertMessage(this.dialog, "Please select the resident group");
          this.editwanderbool = true;
          return false;
        }

        if (this.sel_RoomId.length == 0) {
          generateAlertMessage(this.dialog, "Please select the location");
          this.editwanderbool = true;
          return false;
        }

        const residentType = editwanderconfigFormValue.residenttype;
        this.authkey = localStorage.getItem("Authkey") ?? "";
        const checkeWanderConfig: checkWanderConfig = {
          authKey: this.authkey,
          residentGroupId: parseInt(editwanderconfigFormValue.resident),
          residentType: parseInt(editwanderconfigFormValue.residenttype),
          safeZoneId: parseInt(editwanderconfigFormValue.safezoneId),
        };
        this.wanderService
          .checkSafeZoneConfig(checkeWanderConfig)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: WanterforCreationResponse[]) => {
            if (apiresponse.toString() == "true") {
              this.editwanderbool = true;
              this.updateSafeZone(editwanderconfigFormValue);
            } else if (apiresponse.toString() == "false") {
              if (residentType == 0) {
                generateAlertMessage(
                  this.dialog,
                  "Already this resident have Roaming Rule."
                );
                this.editwanderbool = true;
                return false;
              } else if (residentType == 1) {
                generateAlertMessage(
                  this.dialog,
                  "Already this resident group have Roaming Rule."
                );
                this.editwanderbool = true;
                return false;
              }
            } else if (apiresponse["condition"] == "Authfalse") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "edit-wanderconfig.component.ts" +
          " - editWanderConfig() - " +
          err.message
      );
    }
  };

  private updateSafeZone = (wanderConfigeditFormValue) => {
    try {
      let buildingid;
      let roomid;

      if (this.editwanderbool) {
        this.editwanderbool = false;
        buildingid = this.sel_BuildingId;
        roomid = this.sel_RoomId;
        const buildingIdInf = buildingid;
        for (let nidx = 0; nidx < buildingIdInf.length; nidx++) {
          if (buildingIdInf[nidx].toString().split("_").length > 1) {
            roomid[roomid.length] = buildingIdInf[nidx]
              .toString()
              .split("_")[0];
            buildingid.slice(nidx, 0);
          }
        }
        this.authkey = localStorage.getItem("Authkey") ?? "";
        const editwanderConfig: updateWanderConfig = {
          authKey: this.authkey,
          residentType: parseInt(wanderConfigeditFormValue.residenttype),
          residentId: parseInt(wanderConfigeditFormValue.resident),
          roomId: roomid.toString(),
          safeZoneId: parseInt(wanderConfigeditFormValue.safezoneId),
        };
        this.wanderService
          .updateSafeZone(editwanderConfig)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: WanterforCreationResponse[]) => {
            if (apiresponse["apiReturn"] == "Success") {
              this.editwanderbool = false;
              this.apiService.reloadlive();
              generateAlertMessage(
                this.dialog,
                "Roaming Rule edited successfully."
              );
            } else if (apiresponse["apiReturn"] == "Fail") {
              this.editwanderbool = true;
              generateAlertMessage(this.dialog, "Roaming Rule edited failed.");
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            this.thisDialogRef.close("Confirm");
          });
      }
    } catch (err) {
      throw new Error(
        "edit-wanderconfig.component.ts" +
          " - updateSafeZone() - " +
          err.message
      );
    }
  };

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
