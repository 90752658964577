<div style="height:100vh;margin:0px;">
    
<div class="card-container-left" *ngIf="router.url != '/login'">
    <app-alerts fxFlexFill>
    </app-alerts>
    <app-footer></app-footer>
</div>
<div class="card-container-right" >
    <div style="margin: 0px;">
            
         <mat-sidenav-container class="sidenav-container" >
            <router-outlet></router-outlet>
            <app-sidenav *ngIf="router.url != '/login'"></app-sidenav >
        <app-infrastructure-alerts *ngIf="router.url != '/login'"></app-infrastructure-alerts>
         </mat-sidenav-container>
     </div>
     <app-footer></app-footer>
</div>
</div>
