import { Injectable,Inject } from '@angular/core';
import { RollbarService } from '../rollbar';
import * as Rollbar from 'rollbar';

@Injectable()
export class MainMessageService {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}
  messages: string[] = [];

  add(message: string) {
    this.messages.push(message);
    console.log("Throw"+message);
  }

  clear() {
    this.messages = [];
  }
}
