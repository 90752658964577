import { Component, OnInit,OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import { enumUIFeatureView, enumUIAction, enumUIView } from '../../../app-settings';
import { getFeatureNeedtoShow } from '../../../app-general';
import { SidenavComponent } from '../../../main-layout/sidenav/sidenav.component';
import { MainLayoutService } from '../../../main-layout/main-layout.service';
import { InfrastructureAlertsComponent } from '../../../main-layout/alerts/infrastructure-alerts/infrastructure-alerts.component';

@Component({
  selector: 'app-resident-header',
  templateUrl: './resident-header.component.html',
  styleUrls: ['./resident-header.component.scss']
})
export class ResidentHeaderComponent implements OnInit,OnDestroy {

  navLinks = [
    {path: 'resident', label: 'Resident'},    
    {path: 'staff', label: 'Staff'},
    {path: 'wanderconfig', label: 'Roaming'},
  ];
  
  showResident = false;
  showStaff = false;
  showWander = false;
  showAsset = false;
  selectedIndex = 0;
  UserName;
  public static isMenuClosed = true;
   
  
  constructor(private router: Router,public mainLayoutService: MainLayoutService) {    
  }
 
  ngOnInit() {
    this.UserName = JSON.parse(localStorage.getItem("username"));
    if (getFeatureNeedtoShow(enumUIFeatureView.Resident, enumUIAction.Resident_View) == enumUIView.Show) {
      this.showResident = true;
      
    }  
    if (getFeatureNeedtoShow(enumUIFeatureView.Staff, enumUIAction.Staff_View) == enumUIView.Show) {
      this.showStaff = true;      
    }   
    if (localStorage.getItem("wander_config") == '1') {
      if (getFeatureNeedtoShow(enumUIFeatureView.Roaming, enumUIAction.Roaming_View) == enumUIView.Show) {
        this.showWander = true;
      }
    } 
    if(localStorage.getItem("asset_config") == "1")
    {
      if (getFeatureNeedtoShow(enumUIFeatureView.Asset, enumUIAction.Asset_View) == enumUIView.Show) {
        this.showAsset= true;     
      }
    } 
   
  }

  resident_staff($event) : void {  
   
    if ($event.tab.textLabel=="Resident")
    {
      this.selectedIndex = $event.index
      this.router.navigate(['../../resident']);  
      this.CloseRightSidenav();        
    }
    else if($event.tab.textLabel == 'Staff' &&  this.showStaff==true)
    {
      this.selectedIndex = $event.index
      this.router.navigate(['../../staff']);  
      this.CloseRightSidenav();    
    }
    else if($event.tab.textLabel == 'Roaming' &&  this.showWander==true)
    { 
      this.selectedIndex = $event.index     
      this.router.navigate(['../../wanderprevention']); 
      this.CloseRightSidenav();    
    }
    else if ($event.tab.textLabel == 'Asset' &&  this.showAsset==true)
    {
      this.selectedIndex = $event.index
      this.router.navigate(['../../asset']); 
      this.CloseRightSidenav();     
    }
}

CloseRightSidenav()
{
  if(SidenavComponent.isClosed == false)
  {
      this.mainLayoutService.toggleSideNavRight.emit();
  }
  if(InfrastructureAlertsComponent.isinfraClosed == false)
  {
      this.mainLayoutService.toggleinfrastructureLeft.emit("close");
  }
}

ngOnDestroy(){
  console.log("resident-header.component - ngOnDestroy");
}
}
