export enum technology_overlay_leftmenu {
  device_list = 1,
  device_overview = 2,
  floor_plan = 3,
}
export enum report_leftmenu {
  campusdashboard = 1,
  residentassessment = 2,
  alertReport = 3,
  infrastructureReport = 4,
  locationhistoryReport = 5,
  customizeLocatorsReport = 6,
  liveviewReport = 7,
  customizegeoReport = 8,
}

export enum DeviceTypeDesc {
  Emitter = "Emitter",
  HRex = "H-Rex",
  Rex = "Rex",
  Room = "Building",
  Pin = "Pin",
  LF = "LF",
  Pullcord = "Pullcord",
  FloorPlanNotes = "FloorPlanNotes",
  DC = "DC",
  Monitor = "Monitor",
  VW = "VW",
  Gateway = "Gateway",
  UT = "UT",
  UM = "Universal Monitor",
  NewDC = "Security Controller",
  Intercom = "Intercom"
}

export enum NewDeviceTypeDesc {
  Emitter = "TruLocator",
  HRex = "Clear Router",
  Rex = "Clear Repeater",
  Room = "Room",
  LF = "LF",
  Pullcord = "Pull Cord",
  Zone = "Zone",
  DC = "Security Controller",
  Monitor = "Monitor",
  VW = "VW",
  Gateway = "Clear Gateway",
  UT = "UT",
  UM = "Universal Monitor",
  NewDC = "Security Controller",
  Intercom = "Intercom",
  LFExiter="LF Exciter",
  DripTray="Drip Tray",
  Approx="Approximate Locating"
}

export enum DeviceType {
  Emitter = 1,
  HRex = 2,
  Rex = 3,
  Room = 4,
  Pin = 5,
  LF = 6,
  FloorPlanNotes = 8,
  Pullcord = 9,
  Zone = 10,
  DC = 11,
  Monitor = 12,
  VW = 13,
  Gateway = 14,
  UT = 15,
  UM = 16,
  IC = 17,
  DripTray=18
}

export enum alert_raisedon {
  leftside_alertselect = 1,
  resident_search = 2,
}

export enum enumAlertType {
  Pendant = 1,
  Infrastructure = 2,
  systemAlarm = 3,
  Wander = 4,
  Pull_Cord = 5,
  thirdparty = 6,
  DC = 7,
  UT = 8,
}

export enum enumAlertSubType {
  Low_Batteries = 1,
  Lost_Communication = 2,
  Failure = 3,
  Check_In_Report_Notification = 4,
  ADL_Alarm = 5,
  Fire_Alarm = 6,
  Nurse_Call_Alarm = 7,
  Environmental = 8,
  Tag_In_Field = 9,
  Unauthorized = 10,
  Lotiering = 11,
  Alert_State = 15,
  Ambient_Temperature = 16,
  Probe_Temperature=17,
  Ambient_Humidity=18,
  Resident_Geo=19,
  Staff_Geo=20,
}

export enum enumRequestFrom {
  home_page = 1,
  floorplan_page = 2,
}

export enum enumAlertStatus {
  Accepted = "Accepted",
  Presence = "Presence",
  Complete = "Completed",
  Canceled = "Cancelled",
  Notified = "Notified",
  Notify = "Notify",
}

export enum enumAlertCheckCancel {
  Disable = 0,
  Enable = 1,
}

export enum enumAlertAckStatus {
  Accepted = 1,
  Presence = 2,
  Caregiver_Alert_Presence = 2.1,
  Notified = 2.5,
  Complete = 3,
  Cancel = 4,
  Caregiver_Presence = 6,
}

export enum EnumAlerttype_report {
  buttonpress = "Button Press",
  infrafailure = "Infrastructure Failure",
  checkin = "Check-In",
  usafezone = "Unsafe Zone",
}
export enum Resident_Status {
  Current = 1,
  Deceased = 2,
  Released = 3,
}
export enum enumResidentStatus {
  Current = "Current",
  Deceased = "Deceased",
  Released = "Released",
}
export enum enumgetResidentData {
  AllData = 1,
  ActiveData = 2,
}
export enum enumChronoLogical {
  chronoLogical = 1,
  alphanumeric = 2,
}
export enum enumCheckResidenStaff {
  None = 0,
  Staff = 1,
  Resident = 2,
  All = 3,
  BuildingOutline=4,
  ApproximateOutline=5,
  BothOutline=6
}
export enum enumCheckViewOutlines
{
    None=0,
    BuildingOutline=1,
    ApproximateOutline=2,
    BothOutline=3
}
export enum enumAlertTypeName {
  Pendant = "Pendant",
  Pullcord = "Pullcord",
  DC = "DC",
  Infra = "Infrastructure",
  UT = "UT",
  NewDC = "SC",
}
export enum alert_deviceType {
  General = 0,
  Tag = 1,
  HREX = 2,
  REX = 3,
  DC = 4,
  Emitter = 5,
  Pager_Communication = 6,
  Battery = 7,
  Pull_Cord = 8,
  UT = 9,
  ADT_LFExciter=10,
  DripTray=11,
}
export enum enumUIFeatureView {
  Home = 1,
  Resident = 2,
  Resident_Group = 3,
  Staff = 4,
  Staff_Group = 5,
  Roaming = 6,
  Asset = 7,
  Notification = 8,
  Technology = 9,
  Report = 10,
  Location = 11,
  Engineering = 12,
}

export enum enumUIAction {
  Home_View = 1,
  Alert_Cancel = 2,
  Alert_CompleteOrPresence = 3,
  Alert_Assistance = 4,
  Alert_Renotify = 5,
  Alert_Accept = 6,
  Resident_View = 7,
  Resident_AddEditDelete = 8,
  Resident_Group_View = 9,
  Resident_Group_AddEditDelete = 10,
  Staff_View = 11,
  Staff_AddEditDelete = 12,
  Staff_Group_View = 13,
  Staff_Group_AddEditDelete = 14,
  Roaming_View = 15,
  Roaming_AddEditDelete = 16,
  Asset_View = 17,
  Notification_View = 18,
  Notification_AddEditDelete = 19,
  Device_Map_View = 20,
  Device_Map_AddEditDelete = 21,
  Templates_View = 22,
  Templates_AddEditDelete = 23,
  Device_List_View = 24,
  Device_List_AddEditDelete = 25,
  Outdoor_Locating_View = 26,
  Outdoor_Locating_AddEditDelete = 27,
  Hardwired_Configration_View = 28,
  Hardwired_Configration_AddEditDelete = 29,
  Report_Main_Dashboard = 30,
  Report_Alert_Report = 31,
  Report_Infrastructure_Report = 32,
  Report_Location_History_Report = 33,
  Location_RTLS_Staff = 34,
  Location_RTLS_Resident = 35,
  Engineering_View = 36,
}

export enum enumUIView {
  Show = 1,
  Hide = 0,
}

export enum enumStaffRoll {
  Admin = 1,
  Service_Manager = 2,
  Director_of_Nursing = 3,
  Front_Desk_Assistant = 4,
  Care_Giver = 5,
  Resident = 0,
}
//Staff_Nursing = 4,

export enum enumLiveCurrentState {
  InUse = "In-Use",
  Clean = "Clean",
  Dirty = "Dirty",
  Unknown = "Unknown",
}

export enum enumReportState {
  Clean = 1,
  InUse = 2,
  Dirty = 3,
  Unknown = 4,
}

export enum enumFileNamelength {
  length = 128,
}

export enum IRIdRange {
  maximum = 2047,
}

export enum LFIdRange {
  maximum = 3500,
}
export enum SecurityController_LFIdRange {
  minimum = 3501,
  maximum = 4095,
}
export enum SecurityControllerIdRange {
  maximum = 4095,
}
export enum fillcolorPolygon {
  Incomplete = 1,
  Duplicate = 2,
  Completed = 3,
}

export enum enumTech_rightmenu_Tool {
  DeviceList = 1,
  Move = 2,
  Create_Space = 3,
  Edit_Space = 4,
  Add_Device = 5,
  Duplicate_Space = 6,
  Insert_Template = 7,
  Delete_Space = 8,
  Rectangle_Space = 9,
  Ellipse_Space = 10,
  FreeHand_Space = 11,
  Edit_Point = 12,
  Rotate_Space = 13,
  Reflect_Space = 14,
}
export enum enumTech_Building_rightmenu {
  Move = 1,
  Create_Space = 2,
  Edit_Space = 3,
  Delete_Space = 4,
  Rectangle_Space = 5,
  Ellipse_Space = 6,
  FreeHand_Space = 7,
  Edit_Point = 8,
  Rotate_Space = 9,
  Reflect_Space = 10,
}
export enum enumTech_Outdoor_rightmenu {
  Move = 1,
  Create_Space = 2,
  Edit_Space = 3,
  Delete_Space = 4,
  Rectangle_Space = 5,
  Ellipse_Space = 6,
  FreeHand_Space = 7,
  Edit_Point = 8,
  Rotate_Space = 9,
  Reflect_Space = 10,
  Add_Device = 11,
}

export enum Alert_Event_Type {
  Router_Offline = 1,
  Repeater_Offline = 2,
  Repeater_LBI = 3,
  Repeater_AC_Power_Failure = 4,
  TruLocator_LBI = 5,
  Geo_Pendant_Button_Press = 6,
  Geo_Pendant_LBI = 7,
  Geo_Pendant_Offline = 8,
  Geo_Pendant_Wandering = 9,
  Staff_Pendant_Button_Press = 10,
  Staff_Pendant_LBI = 11,
  Staff_Pendant_Offline = 12,
  PullCord_Jack_Removal = 13,
  PullCord_JackButton_Press = 14,
  PullCord_Alarm = 15,
  PullCord_LBI = 16,
  PullCord_Offline = 17,
  Door_Controller_TagInField = 18,
  Door_Controller_Loitering = 19,
  Door_Controller_Wandering = 20,
  Door_Controller_LBI = 21,
  Door_Controller_Offline = 22,
  UT_AlertState1 = 23,
  UT_AlertState2 = 24,
  UT_AlertState3 = 25,
  UT_Ambient_Temperature_over = 26,
  UT_Ambient_Temperature_under = 27,
  UT_Probe_Temperature_over = 28,
  UT_Probe_Temperature_under = 29,
  UT_Ambient_Humidity_over = 30,
  UT_Ambient_Humidity_under = 31,
  UT_LBI = 32,
  UT_Offline = 33,
  Battery_Removal_Alert = 34,
  Pager_Offline = 35,
  NurseCall_BedA = 36,
  NurseCall_BedB = 37,
  NurseCall_Both = 38,
  NurseCall_Smoke_Alarm = 39,
  NurseCall_Staff_Emergency = 40,
  NurseCall_HiPriority = 41,
  Appliance_UPS_IN_Battery = 42,
  Appliance_UPS_OUT_Battery = 43,
  Appliance_UPS_Unplug = 44,
  Appliance_LostInternet = 45,
}

export enum enumGroup_Type {
  Resident = 1,
  Staff = 2,
}

export enum enumResEgress_Type {
  ResidentRoom = "R",
  EgressRoom = "E",
}

export enum Report_Type {
  Campus_Dashboard = 1,
  Resident_Assessment = 2,
}

export enum Device_action {
  device_add = 1,
  device_edit = 2,
}

export enum enumNotifyTypeItem {
  Role = 0,
  Individual = 1,
  StaffGroup = 2,
  ResidentGroup = 3,
}

export enum enumNotifyTypeText {
  Role = "Role (Global)",
  Individual = "Individual",
  StaffGroup = "Staff Group",
  ResidentGroup = "Resident Group",
}

export enum enumSpaceCategory {
  ResidentRoom = 1,
  StaffArea = 2,
  ActivityArea = 3,
  DiningArea = 4,
  CommonArea = 5,
  Egress = 6,
  Outdoor = 7,
}

export enum enumEnableDisable {
  Enable = 1,
  Disable = 2,
}

export enum enumTagType {
  Resident=1,
  Staff=2,
  Asset=3
}
export const isNumeric = (num: any) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number);
const httpmethod = window.location.href.split("/")[0]; //get http or https
const url = window.location.href.split("/")[2]; // ip address or portal url
const ip = url.split(":")[0]; // exact ip address to pic
const changePort = httpmethod.toLowerCase() == "http:" ? ":3000" : ""; //check the http or https if http then 3000 else no port to sent
//const ip="192.168.0.220";


const socketip = ip;

export class appsettings {
  static SOCKET_IP = socketip; //need to change the IP
  static httpMethod = httpmethod;
  static Port3001 = ":3001"; // user for redis port
  static URLinfo = httpmethod + "//" + ip + changePort + "/"; //need to change the IP
  static API_ENDPOINT = appsettings.URLinfo + "CoreAPI/";
  static IMAGE_FOLDER = appsettings.URLinfo + "Images/";

  static RESIDENT_MEDICALHISTORY = appsettings.URLinfo + "MedicalHistory/";
  static MAP_DETAILS_ROOT = appsettings.URLinfo + "MapDetail";
  static CAMPUS_IMAGE_FOLDER = appsettings.URLinfo + "MapDetail/Campus/";
  static FLOOR_IMAGE_FOLDER = appsettings.URLinfo + "MapDetail/Floors/";
  static FLOORINFO_IMAGE_FOLDER= appsettings.URLinfo + "MapDetail/FloorsInfo/";
  static TEMPLATE_IMAGE_FOLDER = appsettings.URLinfo + "MapDetail/Templates/";
  static Facility_IMAGE_FOLDER = appsettings.URLinfo + "MapDetail/Facility/";
  static CAMPUS_CSV_FOLDER =
    appsettings.URLinfo + "MapDetail/Campus/InfrastructureMetaInfocsv/";
  static FLOOR_CSV_FOLDER =
    appsettings.URLinfo + "MapDetail/Floors/InfrastructureMetaInfocsv/";

  static RESIDENT_FOLDER = appsettings.IMAGE_FOLDER + "Resident/";
  static STAFF_FOLDER = appsettings.IMAGE_FOLDER + "Staff/";
  static REPORT_DOWNLOAD_FOLDER = appsettings.IMAGE_FOLDER + "Staff/Default/";

  static AccessCode_Password = "Centrak123";
  static FacilityName = "Centrak";
  static MapSyncMsg =
    "Map sync process is running. please wait until complete.";

  static Tech_DeviceList = "Show the Devices information for this zone";
  static Tech_Move = "Select a space (or) device to Move";
  static Tech_Create_Space =
    "Choose the tool you’d like to use to draw the space.";
  static Tech_Edit_Space = "Select a space to edit its shape.";
  static Tech_Add_Device =
    "Use the 'Add Device' tool to add TruView devices to the spaces you’ve created.";
  static Tech_Duplicate_Space = "Select a space to Duplicate";
  static Tech_Insert_Template =
    "Insert selected Template space information to this zone";
  static Tech_Delete_Space = "Select a space to Delete";
  static Tech_Rectangle_Space =
    "Click and drag to draw your Rectangle space, then move it on the map.";
  static Tech_Ellipse_Space =
    "Click and drag to draw your Ellipse space, then move it on the map.";
  static Tech_FreeHand_Space =
    "Click and drag to draw your Free handed line drawing, then move it on the map.";
  static Tech_Edit_Point =
    "Select a space to edit its points and (Shift + Left mouse click) to remove the selected point.";
  static Tech_Rotate_Space =
    "Click on desired Space and hover over any endpoint until “+” appears. Click to rotate Space.";
  static Tech_Reflect_Space = "Select a space to Reflect its shape.";

  static Tech_Overlapping_Space = "Reposition Space to avoid overlapping.";

  static Tech_Building_Move = "Select a building outline to Move.";
  static Tech_Building_Create = "Create a building outline.";
  static Tech_Building_Edit = "Edit a building outline.";
  static Tech_Building_Delete = "Delete a building outline.";
  static Tech_Rectangle_Building =
    "Click and drag to draw your Rectangle building, then move it on the map.";
  static Tech_Ellipse_Building =
    "Click and drag to draw your Ellipse building, then move it on the map.";
  static Tech_FreeHand_Building =
    "Click and drag to draw your Free handed line drawing, then move it on the map.";
  static Tech_Edit_Building =
    "Select a building to edit its points and (Shift + Left mouse click) to remove the selected point.";
  static Tech_Rotate_Building =
    "Click on desired building and hover over any endpoint until “+” appears. Click to rotate building.";
  static Tech_Reflect_Building = "Select a building to Reflect its shape.";

  static Tech_Move_Outdoor = "Select a space (or) device to Move";
  static Tech_Create_Space_Outdoor =
    "Choose the tool you’d like to use to draw the space.";
  static Tech_Edit_Space_Outdoor = "Select a space to edit its shape.";
  static Tech_Add_Device_Outdoor =
    "Use the 'Add Device' tool to add TruView devices to the spaces you’ve created.";
  static Tech_Delete_Space_Outdoor = "Select a space to Delete";
  static Tech_Rectangle_Space_Outdoor =
    "Click and drag to draw your Rectangle space, then move it on the map.";
  static Tech_Ellipse_Space_Outdoor =
    "Click and drag to draw your Ellipse space, then move it on the map.";
  static Tech_FreeHand_Space_Outdoor =
    "Click and drag to draw your Free handed line drawing, then move it on the map.";
  static Tech_Edit_Point_Outdoor =
    "Select a space to edit its points and (Shift + Left mouse click) to remove the selected point.";
  static Tech_Rotate_Space_Outdoor =
    "Click on desired Space and hover over any endpoint until “+” appears. Click to rotate Space.";
  static Tech_Reflect_Space_Outdoor = "Select a space to Reflect its shape.";

  static CropImage = "Select the corner arrow to crop the image.";
  static CropPreview = "Preview the cropped image.";

  static DeleteSpace = "Please select the space to delete.";
  static SaveSpace = "Space details edited successfully.";
  static SaveDevice = "Device details edited successfully.";
  static SaveDeviceMac =
    "Device details edited successfully. <br> It will take 400 seconds for the settings to take effect.";
  static CampusRequire = "Please select the Campus.";

  static ClientImageFolder="assets/images/";
  static MapImageFolder=appsettings.ClientImageFolder + "map/";
  static HomeConnect=appsettings.ClientImageFolder + "connect.png";
  static HomeDisConnect=appsettings.ClientImageFolder + "disconnect.png";

  enumAlertCheckCancel = enumAlertCheckCancel;
  enumAlertAckStatus = enumAlertAckStatus;

   //static Name_Pattern='[a-zA-Z\s][a-zA-Z0-9 ]+$';
   static Name_Pattern='[a-zA-Z0-9\s][a-zA-Z0-9!-@#$%^&\_ ]*';
   static Email_Pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
   static Mac_Pattern='^[a-fA-F0-9]{2}(?:[:_-]?[a-fA-F0-9]{2}){5}(?:,[a-fA-F0-9]{2}(?:[:_-]?[a-fA-F0-9]{2}){5})*$';
   static Password_Pattern='[a-zA-Z0-9!@#$%^&*]{4,20}';
   static Number_Pattern='^[0-9]*$';
}
