import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from '../../../api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionCheckwithStaff,generateAlertMessage, getFeatureNeedtoShow} from '../../../app-general';
import { enumUIAction, enumUIFeatureView, enumUIView } from '../../../app-settings';
import { TechnologyoverlayService } from '../technologyoverlay.service';
import { DOCUMENT } from "@angular/common";
import { AlertSharedService } from './../../../_services/index';
import { Router } from '@angular/router';
import { getBoardInfoDataTektone, getBoardSetupDatatektone, getConfigSettings,setAlarmClearMode,getAlarmClearMode } from '../technologyoverlay';
import { TekToneUploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { TekToneEditConfigComponent } from './edit-config/edit-config.component';
import * as XLSX from 'xlsx';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ConfirmMessageComponent } from '../../../confirm-message/confirm-message.component';

type AOA = any[][];

@Component({
  selector: 'app-tektone',
  templateUrl: './tektone.component.html',
  styleUrls: ['./tektone.component.scss']
})
export class TekToneComponent implements OnInit {
 
  SiteId = "1";
  MapSyncProcess;
  getdata;
  displayedColumns: string[]= ["BoardName","IPAddress","Port","Edit"];
  dataSource;
  dataSourcecnt;
  hardwireddata;
  editconfigdialogopen = 0;
  fileName = 'Tektone.xlsx';
  smcurrentScreenWidth = 'sm';
  xscurrentScreenWidth = 'xs';
  mdcurrentScreenWidth = 'md';
  lgcurrentScreenWidth = 'lg';
  private _onDestroy: Subject<void> = new Subject<void>();
  
  bEditiconGroup = false;
  authkey;
  
  eventData: AOA = [['Event Name', 'Event Description']]; 
  boardInfoData: AOA = [['Board Name','IP Address','TCP Port','Points']];
  boardSetupData: AOA = [['Board Name','Room Id', 'Bed','Toilet','Shower','Cord Out','Spa','Space','Zones']];

  alarmClearMode: any[] = [{ modeType: 'Select', Value: 0 },{ modeType: 'Auto Clear', Value: 1 }, { modeType: 'Clear from Integration', Value: 2 }, { modeType: 'Clear on Presence', Value: 3 }];
  selectedAlarmClearMode = 0;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(public dialog: MatDialog,private apiService: ApiService,private technologyoverlayService: TechnologyoverlayService, @Inject(DOCUMENT) private document: any,
  private alertSharedService: AlertSharedService,private router: Router, public thisDialogRef: MatDialogRef<TekToneComponent>,private breakpointObserver: BreakpointObserver) {
    const routerCurrentNavigate = this.router.getCurrentNavigation();
    if(routerCurrentNavigate != null)
      this.getdata = routerCurrentNavigate.extras.state;
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
   
   }
   breakpointChanged() {    
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Hardwired_Configration_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bEditiconGroup = true;
      this.displayedColumns = [
        "BoardName","IPAddress","Port","Edit",
      ];
    } else {
      this.displayedColumns = [
        "BoardName",
        "IPAddress",
        "Port",
      ];
    }
  }
   //@ViewChild('configSort') configSort: MatSort; 
   @ViewChild(MatSort) sort: MatSort;
   ngOnInit() {
    this.authkey = localStorage.getItem("Authkey");
    this.getConfigurationSettingsTekTone();  
    this.getSelectedAlarmClearMode();
   }

   getConfigurationSettingsTekTone()
  {
    const apiParam : getConfigSettings = 
    { 
      authKey: this.authkey
    };
    this.technologyoverlayService.getConfigurationSettingsTekTone(apiParam).pipe(takeUntil(this._onDestroy))
    .subscribe((result: any[]) => {
      if(result)
      {
        this.hardwireddata = (result);
        this.dataSource = new MatTableDataSource(this.hardwireddata);
        this.dataSource.data = this.hardwireddata;
        this.dataSourcecnt = this.hardwireddata.length; 
        this.dataSource.sort = this.sort; 
        const sortState: Sort = {active: 'boardName', direction: 'asc'};
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }
    });
  }
  
  editConfigurationdetails(configInfo)
  {
    try
    {     
      if(!SessionCheckwithStaff(this.router))
      {
          return;
      }
      const configdetails: any={
        BoardName:configInfo.boardName,
        IPAddress:configInfo.ipAddress,
        Port:configInfo.port,
        setupDataRowCount:configInfo.rowCount,
        BoardId:configInfo.boardInfoId
      }
      if(this.editconfigdialogopen == 0)
      {
          const dialogRef = this.dialog.open(TekToneEditConfigComponent,{
            disableClose: true,
            width: '1100px',
            height:'700px',
            panelClass: 'myapp-no-padding-dialog',
            autoFocus: false,
            data:configdetails
          });

          this.editconfigdialogopen = 1;
          
          dialogRef.afterClosed().subscribe(result => {   
              console.log(result)
              this.editconfigdialogopen = 0; 
              this.getConfigurationSettingsTekTone();
          })
      }
    }
    catch(err)
    {
      console.log(err)
      throw new Error('tektone.component.ts' + ' - editConfigurationdetails() - ' + err.message);
    }
  }

  CloseRightNav(){  
    // if(SidenavComponent.isClosed == false)
    // {
    //     this.mainLayoutService.toggleSideNavRight.emit();
    // }
    // if(InfrastructureAlertsComponent.isinfraClosed == false)
    // {
    //     this.mainLayoutService.toggleinfrastructureLeft.emit("close");
    // }
  } 
  uploadNewConfiguration()
  {
    const dialogRef = this.dialog.open(TekToneUploadDialogComponent, {
      disableClose: true,
      width: '450px',
      height: '200px',
      panelClass: 'myapp-no-padding-dialog',
      autoFocus: false,
      data: ''
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.getConfigurationSettingsTekTone();
    })
  }
  
  downloadExcelTemplate()
  {
    console.log("downloadExcelTemplate");
    try
    {
      const apiParam : getBoardInfoDataTektone = 
      { 
        authKey: this.authkey
      };
      this.technologyoverlayService.getBoardInfoDataTekTone(apiParam).pipe(takeUntil(this._onDestroy))
      .subscribe((result: any[]) => {      
        if(result)
        {
          this.boardInfoData = [['Board Name','IP Address','TCP Port','Points']];
  
          if(result.length > 0)
          {
            for(let nIdx = 0; nIdx < result.length; nIdx ++)
            {
              const arr = [
                result[nIdx].BoardName,
                result[nIdx].IPAddress,
                result[nIdx].Port,
                result[nIdx].Points
              ];
           
              this.boardInfoData.push(arr);
            }
          }
          
        }
        const apiParam1 : getBoardSetupDatatektone = 
        { 
          authKey: this.authkey 
        };
         this.technologyoverlayService.getBoardSetupDetailTekTone(apiParam1).pipe(takeUntil(this._onDestroy))
        .subscribe((setupData: any[]) => {        
          if(setupData)
          {
            this.boardSetupData = [['Board Name','Room Id', 'Bed','Toilet','Shower','Cord Out','Spa','Space','Zones']];
            if(setupData.length > 0)
            {
              for(let nIdx = 0; nIdx < setupData.length; nIdx ++)
              {
                setupData[nIdx].Bed = setupData[nIdx].Bed.toUpperCase() === "NULL" ? "No" : setupData[nIdx].Bed;
                setupData[nIdx].Toilet = setupData[nIdx].Toilet.toUpperCase() === "NULL" ? "No" : setupData[nIdx].Toilet;
                setupData[nIdx].Shower = setupData[nIdx].Shower.toUpperCase() === "NULL" ? "No" : setupData[nIdx].Shower;
                setupData[nIdx].CordOut = setupData[nIdx].CordOut.toUpperCase() === "NULL" ? "No" : setupData[nIdx].CordOut;
                setupData[nIdx].Spa = setupData[nIdx].Spa.toUpperCase() === "NULL" ? "No" : setupData[nIdx].Spa;
              
                const arrSetup = [
                  setupData[nIdx].BoardName,
                  setupData[nIdx].RoomId,
                  setupData[nIdx].Bed,
                  setupData[nIdx].Toilet,
                  setupData[nIdx].Shower,
                  setupData[nIdx].CordOut,
                  setupData[nIdx].Spa,
                  setupData[nIdx].Space,
                  setupData[nIdx].Zone
                ];
                this.boardSetupData.push(arrSetup);
              }
            }
          }
  
          this.technologyoverlayService.getEventDataTekTone(apiParam).pipe(takeUntil(this._onDestroy))
          .subscribe((eventData: any[]) => {         
            if(eventData)
            {
                this.eventData = [['Event Name', 'Event Description']];
                if(eventData.length > 0)
                {
                  for(let nIdx = 0; nIdx < eventData.length; nIdx ++)
                  {
                    const arrEventData = [
                      eventData[nIdx].EventName,
                      eventData[nIdx].EventDescription
                    ];
                    this.eventData.push(arrEventData);
                  }
                }
            }
            this.downloadExcelFile(this.eventData,this.boardInfoData,this.boardSetupData);
          });
        });
      });
    }
   catch(err)
   {
     console.log("downloadExcelTemplate: " + err);
   }
  }
  applyBoardConfiguarion()
  {    
    const ConfirmMessageData = {
      ConfirmMessage:  "Existing Hardwired Configurations should be clear. Do you want to change it?"
    }     
    const dialogRef = this.dialog.open(ConfirmMessageComponent, {
      disableClose: true,
      width: 'auto',
      height: '220px',
      panelClass: 'myapp-no-padding-dialog',
      autoFocus: false,
      data: ConfirmMessageData
    });     
    dialogRef.afterClosed().subscribe(result => {     
      if(result == "Yes")
      {                     
        if(this.hardwireddata.length > 0)
        {
          this.apiService.reloadBoardConfiguration();
          generateAlertMessage(this.dialog, 'Apply Board Configuration successfully completed');
        }
        else
        {
          generateAlertMessage(this.dialog, 'Devices not available to apply configuration');
          return;
        }
      }
      else
      {        
          dialogRef.close('Cancel');          
          return;
      }
    });        
  }
  downloadExcelFile(eventData,boardInfoData,boardSetupData)
  {
    console.log("eventData" + eventData);
    console.log("boardInfoData" + boardInfoData);
    console.log("boardSetupData" + boardSetupData);

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(eventData);
    const ws1: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(boardInfoData);
    const ws2: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(boardSetupData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Event');
    XLSX.utils.book_append_sheet(wb, ws1, 'Board Info');
    XLSX.utils.book_append_sheet(wb, ws2, 'Board Setup');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  changeAlarmClearMode(selectedMode) 
  {
    this.selectedAlarmClearMode = selectedMode.Value;
      const alarmModeParam : setAlarmClearMode = 
      {
          authKey: this.authkey, 
          modeType: this.selectedAlarmClearMode
      };
      this.technologyoverlayService.setAlarmClearMode(alarmModeParam).pipe(takeUntil(this._onDestroy))
      .subscribe((result: any) => {
        if(result == true)
        {
          generateAlertMessage(this.dialog,'Alarm clear mode updated successfully.');
        }
      });
    
  }

  getSelectedAlarmClearMode()
  {
     const alarmModeParam : getAlarmClearMode = 
     {
      authKey: this.authkey
     };
     this.technologyoverlayService.getAlarmClearMode(alarmModeParam).pipe(takeUntil(this._onDestroy))
     .subscribe((result: any) => {
      if(result.length > 0)
      {
        this.selectedAlarmClearMode = parseInt(result);
      }
     });
  }
}
