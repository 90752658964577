import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import {
  Escalationdetails,
  NotificationForCreationResponse,
  NotificationDelete,
  getNotification,
  getNotificationById,
  checkAlertItem,
} from "./../notification";
import { notificationService } from "./../notification.service";
import { AddNotificationComponent } from "./../add-notification/add-notification.component";
import { EditNotificationComponent } from "./../edit-notification/edit-notification.component";
import {
  enumAlertSubType,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
  enumEnableDisable,
} from "../../../app-settings";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheckwithStaff,
  checkStaffData,
  generateAlertMessage,
  getFeatureNeedtoShow,
  getAddEditDeleteColumn,
  AuthFailedMessage,
} from "../../../app-general";
import { ApiService } from "../../../api.service";
import { AlertSharedService } from "../../../_services/index";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { SessionCheck } from "../../../app-general";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-view-notification",
  templateUrl: "./view-notification.component.html",
  providers: [notificationService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./view-notification.component.scss"],
})
export class ViewNotificationComponent implements OnInit, OnDestroy {
  bAddEdit = false;
  bDelete = false;
  checkicon;
  escalationData;
  arrowiconT = true;
  arrowiconF = false;
  preId = -1;
  collapsedIcon1 = "keyboard_arrow_down";
  collapsedIcon = "keyboard_arrow_down";
  Notificationdetails: any[];
  Escalationdetail: Escalationdetails[];
  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  mdcurrentScreenWidth = "md";
  displayedColumns: string[] = [
    "Level",
    "AlarmNameId",
    "TimetoEscalate",
    "RepeatCycle",
    "Notify",
    "CampusName",
    "BuildingName",
    "FloorName",
    "Edit",
    "Delete",
  ];
  dataSource;
  LevelImgURL = "assets/images/";
  escalation;
  AlarmId = 1;
  esclationcount = -1;
  esclationCampus = -1;
  esclationBuilding = -1;
  esclationFloor = -1;
  esclationSubZone = -1;
  esclationsubtypeid = -1;
  deleteMSG;
  notifyloading = false;
  Campus_Id;
  Campus_Name;
  Building_Id;
  Building_Name;
  Floor_Id;
  Floor_Name;
  SubZone_Id;
  SubZone_Name;
  AlarmSubTypeId_Show = "";
  Val_Show = 0;
  showNotifyWarningImage = true;
  checkNotification = true;
  checkNotificationCount = 0;
  NotificationLevelTooltip = "WARNING: Previous notification level(s) has/have not been setup to escalate.For this level to execute,edit previous level.";
  RepeatCycleTooltip = "Repeat Cycle(minutes)";
  EscalateTooltip = "Time to Escalate(minutes)";
  contiNotifyAlertId;
  private _onDestroy: Subject<void> = new Subject<void>();
  authkey;
  public watcher: Observable<boolean>;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<ViewNotificationComponent>,
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: notificationService,
    public dialog: MatDialog,
    private alertSharedService: AlertSharedService,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpoint$.subscribe(() => this.breakpointChanged());    
    this.Campus_Id = data.CampusId;
    this.Building_Id = data.BuildId;
    this.Floor_Id = data.FloorId;
    this.SubZone_Id = data.SubZoneId;
    this.AlarmId = data.AlarmId;
  }
  
  breakpointChanged() {    
    if (getFeatureNeedtoShow(enumUIFeatureView.Notification,enumUIAction.Notification_AddEditDelete) == enumUIView.Show) 
    {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
      this.setupFullTable();
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.Medium))
    {
      this.setupTableformdwidth();
    }
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.setupTableforsmallerwidth();
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.setupTableforXtrasmallerwidth();
    }
  }

  // set table width and columns
  setupTableforXtrasmallerwidth() {
    this.displayedColumns = [
      'AlarmNameId','TimetoEscalate','RepeatCycle','Repeats',
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableforsmallerwidth() {
    this.displayedColumns = [
      "Level",
      'AlarmNameId','TimetoEscalate','RepeatCycle','Repeats',
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupTableformdwidth() {
    this.displayedColumns = [
      "Level",
      'AlarmNameId',
      'TimetoEscalate','RepeatCycle','Repeats',
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  setupFullTable() {
    this.displayedColumns = [
      "Level",
      "AlarmNameId",
      "TimetoEscalate",
      "RepeatCycle",
      'Repeats',
      "Notify",      
      "Edit",
      "Delete",
    ];
    this.displayedColumns = getAddEditDeleteColumn(
      this.displayedColumns,
      this.bAddEdit,
      this.bDelete
    );
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Notification,
          enumUIAction.Notification_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }

      const notificationrequest: getNotification = {
        authKey: this.authkey,
        alertId: this.AlarmId,
      };
      this.getNotification(notificationrequest);
      this.getStaffById();
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - ngOnInit() - " + err.message
      );
    }
  }

  getStaffById() {
    checkStaffData(this.apiService, this.alertSharedService);
  }

  //get campus details
  getNotification(notificationrequest): void {
    try {
      let first_showData = [] as any[];
      let DataValue = [] as any[];
      let cnt = 0;
      const _this = eval('this');  //todo
      let click_val = 0;
      let notificationData;
      this.notificationService
        .getNotification(notificationrequest)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((Notificationdetails: any[]) => {
          if (Notificationdetails != null && Notificationdetails != undefined) {
            if (Notificationdetails["apiReturn"] == "Invalid Auth Key") {
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
            this.Notificationdetails = [];

            first_showData = [];
            DataValue = [];
            cnt = 0;
            notificationData = Notificationdetails["apiReturn"];
            notificationData.forEach(function (e) {
              if (e.AlarmSubTypeId == enumAlertSubType.Low_Batteries)
                e["SubName"] = "LB";
              else if (e.AlarmSubTypeId == enumAlertSubType.Lost_Communication)
                e["SubName"] = "LC";
              else if (e.AlarmSubTypeId == enumAlertSubType.Failure)
                e["SubName"] = "Failure";
              else if (
                e.AlarmSubTypeId ==
                enumAlertSubType.Check_In_Report_Notification
              )
                e["SubName"] = "CIN";
              else if (e.AlarmSubTypeId == enumAlertSubType.ADL_Alarm)
                e["SubName"] = "ADL";
              else if (e.AlarmSubTypeId == enumAlertSubType.Fire_Alarm)
                e["SubName"] = "Fire";
              else if (e.AlarmSubTypeId == enumAlertSubType.Nurse_Call_Alarm)
                e["SubName"] = "NurseCall";
              else if (e.AlarmSubTypeId == enumAlertSubType.Environmental)
                e["SubName"] = "EM";
              else if (e.AlarmSubTypeId == enumAlertSubType.Tag_In_Field)
                e["SubName"] = "TIF";
              else if (e.AlarmSubTypeId == enumAlertSubType.Unauthorized)
                e["SubName"] = "UE";
              else if (e.AlarmSubTypeId == enumAlertSubType.Lotiering)
                e["SubName"] = "Loitering";

              if (e.Buildingname != "") {
                e["NewBuildingName"] = e.Buildingname.trim();
              } else e["NewBuildingName"] = "";

              if (e.FloorName != "") {
                e["NewFloorName"] = e.FloorName.trim();
              } else e["NewFloorName"] = "";
              
              if(e.SubZoneDesc != '' && e.SubZoneDesc != undefined)
              {                  
                e['NewSubZoneName'] = e.SubZoneDesc.trim();
              }
              else
                e['NewSubZoneName'] = "";

              if(_this.SubZone_Id == undefined)  
                _this.SubZone_Id = 0
                
              if (
                _this.Floor_Id == 0 &&
                e.FloorId == 0 &&
                _this.Building_Id == 0 &&
                e.Buildid == 0 &&
                e.CampusId == _this.Campus_Id &&
                e.AlarmId == _this.AlarmId
              ) {
                _this.Notificationdetails[_this.Notificationdetails.length] = e;
                if (_this.AlarmSubTypeId_Show == e.AlarmSubTypeId) {
                  first_showData[first_showData.length] = e;
                  DataValue[DataValue.length] = cnt;
                } else if (
                  _this.AlarmSubTypeId_Show == "" &&
                  e.totcnt != null
                ) {
                  first_showData[first_showData.length] = e;
                  DataValue[DataValue.length] = cnt;
                }
                cnt++;
              } else if (
                _this.SubZone_Id == 0 && 
                _this.Floor_Id == 0 &&
                e.FloorId == 0 &&
                e.Buildid == _this.Building_Id &&
                e.CampusId == _this.Campus_Id &&
                e.AlarmId == _this.AlarmId
              ) {
                _this.Notificationdetails[_this.Notificationdetails.length] = e;
                if (_this.AlarmSubTypeId_Show == e.AlarmSubTypeId) {
                  first_showData[first_showData.length] = e;
                  DataValue[DataValue.length] = cnt;
                } else if (
                  _this.AlarmSubTypeId_Show == "" &&
                  e.totcnt != null
                ) {
                  first_showData[first_showData.length] = e;
                  DataValue[DataValue.length] = cnt;
                }
                cnt++;
              } else if (
                _this.SubZone_Id == 0 && e.SubZoneId == 0 && 
                _this.Floor_Id == e.FloorId &&
                e.Buildid == _this.Building_Id &&
                e.CampusId == _this.Campus_Id &&
                e.AlarmId == _this.AlarmId
              ) {
                _this.Notificationdetails[_this.Notificationdetails.length] = e;
                if (_this.AlarmSubTypeId_Show == e.AlarmSubTypeId) {
                  first_showData[first_showData.length] = e;
                  DataValue[DataValue.length] = cnt;
                } else if (
                  _this.AlarmSubTypeId_Show == "" &&
                  e.totcnt != null
                ) {
                  first_showData[first_showData.length] = e;
                  DataValue[DataValue.length] = cnt;
                }
                cnt++;
              }
              
              else if (_this.SubZone_Id == e.SubZoneId && _this.Floor_Id == e.FloorId && e.Buildid == _this.Building_Id && e.CampusId == _this.Campus_Id && e.AlarmId == _this.AlarmId) {
                _this.Notificationdetails[_this.Notificationdetails.length] = e;
                if (_this.AlarmSubTypeId_Show == e.AlarmSubTypeId) {
                  first_showData[first_showData.length] = e;
                  DataValue[DataValue.length] = cnt;
                }
                else if (_this.AlarmSubTypeId_Show == "" && e.totcnt != null) {
                  first_showData[first_showData.length] = e;
                  DataValue[DataValue.length] = cnt;
                }
                cnt++;
              }  
              
            });
          }
          if (_this.Notificationdetails.length > 0) {
            if (this.AlarmSubTypeId_Show == "" && DataValue.length > 0) {
              click_val = DataValue[0];
              this.AlarmSubTypeId_Show = first_showData[0].AlarmSubTypeId;
            } else if (DataValue.length > 0) {
              click_val = DataValue[0];
              this.AlarmSubTypeId_Show = first_showData[0].AlarmSubTypeId;
            } else {
              click_val = 0;
              this.AlarmSubTypeId_Show =
                _this.Notificationdetails[0].AlarmSubTypeId;
            }
            this.clickcollapse(
              click_val,
              "",
              this.Campus_Id,
              this.AlarmSubTypeId_Show,
              this.Building_Id,
              this.Floor_Id,
              this.SubZone_Id
            );
          } else {
            this.clickcollapse(
              click_val,
              "",
              this.Campus_Id,
              "",
              this.Building_Id,
              this.Floor_Id,
              this.SubZone_Id
            );
          }
        });
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - getNotification() - " + err.message
      );
    }
  }

  //show escalation details
  clickcollapse(val, data, CampusId, AlarmSubTypeId, BuildId, FloorId, SubZoneId) {
    try {
      this.AlarmSubTypeId_Show = AlarmSubTypeId;

      this.notifyloading = true;

      let p1 = 0;
      this.dataSource = "";
      this.checkicon = val;
      this.esclationcount = -1;
      this.esclationCampus = -1;
      this.esclationBuilding = -1;
      this.esclationFloor = -1;
    this.esclationSubZone = -1;
      this.esclationsubtypeid = -1;
      if (this.preId != -1 && this.preId != val) {
        p1++;
        if (this.collapsedIcon == "keyboard_arrow_down") {
          this.collapsedIcon1 = "keyboard_arrow_up";
          this.arrowiconT = true;
        }
      }
      if (this.checkicon == val) {
        if (this.collapsedIcon1 == "keyboard_arrow_down" && p1 == 0) {
          this.collapsedIcon1 = "keyboard_arrow_up";
          this.arrowiconT = true;
        } else if (this.collapsedIcon1 == "keyboard_arrow_up" && p1 == 0) {
          this.collapsedIcon1 = "keyboard_arrow_down";
          this.arrowiconT = false;
        }
        this.preId = val;
      }
      const notificationRequestById: getNotificationById = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
        alarmId: this.AlarmId,
        buildId: parseInt(BuildId),
        floorId: parseInt(FloorId),
        subZoneId:parseInt(SubZoneId),       
      };
      this.getNotificationRecipient(notificationRequestById, AlarmSubTypeId);
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - clickcollapse() - " + err.message
      );
    }
  }

  getNotificationRecipient(notificationRequestById, AlarmSubTypeId) {
    this.notificationService
      .getNotificationRecipient(notificationRequestById)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((Escalationdetail: any[]) => {
        if (
          Escalationdetail != null &&
          Escalationdetail != undefined &&
          Escalationdetail.length > 0
        ) {
          this.Escalationdetail = Escalationdetail;
          const NotifyLevel = this.Escalationdetail.filter(function (e1) {
            return e1.AlarmSubTypeId == AlarmSubTypeId;
          });

           
          for(let i = 0; i < NotifyLevel.length; i++)
          {
            if(NotifyLevel[i].Level != 1)
            {
              if((NotifyLevel[i-1].NeedRepeat == 1 && NotifyLevel[i-1].NeedEscalate == 0) || (NotifyLevel[i-1].NeedRepeat == 0 && NotifyLevel[i-1].NeedEscalate == 0))
                NotifyLevel[i].showNotifyWarningImage = 1;
            }
            else
              NotifyLevel[i].showNotifyWarningImage = 0;
          }

          this.esclationcount = NotifyLevel.length;
          this.checkNotificationCount = NotifyLevel.length;   
          this.esclationCampus = notificationRequestById.campusId;
          this.esclationsubtypeid = AlarmSubTypeId;
          this.Building_Id = Escalationdetail[0].BuildId;
          this.esclationBuilding = notificationRequestById.buildId;
          this.Building_Name = Escalationdetail[0].BuildingName;
          this.Floor_Id = Escalationdetail[0].FloorId;
          this.esclationFloor = notificationRequestById.floorId;
          this.Floor_Name = Escalationdetail[0].FloorName;
          this.esclationSubZone = notificationRequestById.subZoneId;
          this.SubZone_Id = Escalationdetail[0].SubZoneId;
          if(this.checkNotificationCount > 0)
          {
            if(NotifyLevel[0].ContiNotification == 1)
              this.checkNotification = true;
            else
              this.checkNotification = false; 
          }
          else
            this.checkNotification = true;  
          if(AlarmSubTypeId == enumAlertSubType.Staff_Geo && this.checkNotificationCount == 0)  
            this.checkNotification = false;

          this.dataSource = new MatTableDataSource(NotifyLevel);
          this.dataSource.data = NotifyLevel;
          if(NotifyLevel.length > 0)
            this.contiNotifyAlertId = NotifyLevel[0].AlertId;
          this.notifyloading = false;
          this.NotifyViewEnableDisable(enumEnableDisable.Enable);
        } else {
          this.notifyloading = false;
          this.esclationcount = Escalationdetail.length;
          this.NotifyViewEnableDisable(enumEnableDisable.Enable);
          this.checkNotification = true;
          if(AlarmSubTypeId == enumAlertSubType.Staff_Geo && this.checkNotificationCount == 0)  
            this.checkNotification = false;
        }
        
        if(AlarmSubTypeId == enumAlertSubType.Low_Batteries || AlarmSubTypeId == enumAlertSubType.Lost_Communication)
          this.RepeatCycleTooltip = "Repeat Cycle(hours)";
        else
          this.RepeatCycleTooltip = "Repeat Cycle(minutes)";
          
        if(AlarmSubTypeId == enumAlertSubType.Low_Batteries || AlarmSubTypeId == enumAlertSubType.Lost_Communication)  
          this.EscalateTooltip = "Time to Escalate(hours)";
        else
          this.EscalateTooltip = "Time to Escalate(minutes)";
        
        const _matTargetId = document.getElementById("matTargetId");
        if (_matTargetId != null) _matTargetId.setAttribute("id", "");

        const selectedrow = document.getElementsByClassName("mat-header-row");
        selectedrow[0].setAttribute("id", "targetId");
        document
          .getElementById("targetId")
          .parentElement.parentElement.parentElement.parentElement.setAttribute(
            "id",
            "matTargetId"
          );
        if (_matTargetId != null) _matTargetId.scrollIntoView();
      });
  }

  // close Notification Dialog
  public onCloseeditNotification = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  /*********Open add dialogwindow*******/
  addNotification(
    totcnt,
    CampusId,
    CampusName,
    Description,
    AlarmId,
    AlertId,
    AlarmSubTypeId,
    AlarmSubTypeName,
    AlarmNameId,
    BuildId,
    BuildingName,
    FloorId,
    FloorName,
    SubZoneId,SubZoneName
  ) {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }

    if (AlarmSubTypeId == "") {
      AlarmSubTypeId = 0;
    }
    const Escdetails: any = {
      CampusId: CampusId,
      CampusName: CampusName,
      EscalationLevel: totcnt + 1,
      UDEventName: Description,
      AlarmId: AlarmId,
      AlertId: AlertId,
      AlarmSubTypeId: AlarmSubTypeId,
      AlarmSubTypeName: AlarmSubTypeName,
      AlarmNameId: AlarmNameId,
      BuildId: BuildId,
      BuildingName: BuildingName,
      FloorId: FloorId,
      FloorName: FloorName,
      SubZoneId: SubZoneId,
      SubZoneName:SubZoneName
    };
    const dialogRef = this.dialog.open(AddNotificationComponent, {
      disableClose: true,
      width: "1200px",
      height: "670px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: Escdetails,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Confirm") {
        this.collapsedIcon1 = "keyboard_arrow_down";
        this.AlarmSubTypeId_Show = AlarmSubTypeId;
        this.getNotification({
          authKey: this.authkey,
          alertId: parseInt(AlarmId),
          isOldUI: "0",
        });
      }
    });
  }

  /*********Open edit dialogwindow*******/
  editNotificationdata(data) {
    if (!SessionCheckwithStaff(this.router)) {
      return;
    }

    let _BuildingName = "";
    let _FloorName = "";
    let subZone_Name = "";
    
    if (data.BuildingName == null) _BuildingName = "";
    else _BuildingName = data.BuildingName;

    if (data.FloorName == null) _FloorName = "";
    else _FloorName = data.FloorName;
    
    if(data.SubZoneName == null)
      subZone_Name = "";
    else
      subZone_Name = data.FloorName;
      
    const editdata: any = {
      AlarmNameId: data.AlarmNameId,
      AlarmSubTypeId: data.AlarmSubTypeId,
      AlarmSubTypeName: data.AlarmSubTypeName,
      AlertId: data.AlertId,
      Area: data.Area,
      CampusId: data.CampusId,
      CampusName: data.CampusName,
      EventId: data.EventId,
      EventName: data.EventName,
      Level: data.Level,
      LevelImg: data.LevelImg,
      Notify: data.Notify,
      NotifyType: data.NotifyType,
      RoleId: data.RoleId,
      StaffId: data.StaffId,
      TimetoEscalate: data.TimetoEscalate,
      UDEventName: data.UDEventName,
      GroupId: data.GroupId,
      GroupType: data.GroupType,
      BuildingId: data.BuildId,
      BuildingName: _BuildingName,
      FloorId: data.FloorId,
      FloorName: _FloorName,
      SubZoneId:data.SubZoneId,
      SubZoneName:subZone_Name,
      RepeatCycle: data.RepeatCycle,
      NeedEscalate: data.NeedEscalate,
      NeedRepeat:data.NeedRepeat,
      RepeatNumber:data.RepeatNumber
    };
    const dialogRef = this.dialog.open(EditNotificationComponent, {
      disableClose: true,
      width: "1200px",
      height: "670px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: editdata,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Confirm") {
        this.collapsedIcon1 = "keyboard_arrow_down";
        this.AlarmSubTypeId_Show = data.AlarmSubTypeId;
        this.getNotification({
          authKey: this.authkey,
          alertId: parseInt(data.EventId),
          isOldUI: "0",
        });
      }
    });
  }

  /*********Open Delete dialogwindow*******/
  deleteNotification(
    d_eventName,
    d_Campusname,
    d_AlertId,
    d_Level,
    d_AlarmId,
    CampusId,
    AlarmSubTypeId,
    BuildId,
    FloorId,
    SubZoneId
  ) {
    this.NotifyViewEnableDisable(enumEnableDisable.Disable);

    if (AlarmSubTypeId == "") AlarmSubTypeId = 0;

    if (!SessionCheckwithStaff(this.router)) {
      return;
    }

    const checkalertItem: checkAlertItem = {
      authKey: this.authkey,
      campusId: parseInt(CampusId),
      eventId: parseInt(d_AlarmId),
      subTypeId: parseInt(AlarmSubTypeId),
      buildId: parseInt(BuildId),
      floorId: parseInt(FloorId),
      subzoneId : parseInt(SubZoneId)
    };
    this.notificationService
      .checkAlertForCampus(checkalertItem)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: any[]) => {
        if (apiresponse.toString() == "true") {
          generateAlertMessage(
            this.dialog,
            "This notification  " +
              d_eventName +
              " Alarm - " +
              d_Campusname +
              " has open alerts, please complete or cancel those alerts and try again."
          );
          this.NotifyViewEnableDisable(enumEnableDisable.Enable);
          return;
        } else {
          this.deleteMSG =
            "Are you sure you want to delete " +
            d_Campusname +
            " " +
            d_eventName +
            " Escalation " +
            d_Level +
            "? ";
          this.confirmation(d_AlertId, d_Level, d_AlarmId, AlarmSubTypeId);
        }
      });
  }
  confirmation(AlertId, EscalationLevel, d_AlarmId, AlarmSubTypeId) {
    try {
      const ConfirmMessageData = {
        ConfirmMessage: this.deleteMSG,
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "220px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          this.deletenotification(
            AlertId,
            EscalationLevel,
            d_AlarmId,
            AlarmSubTypeId
          );
        } else {
          this.NotifyViewEnableDisable(enumEnableDisable.Enable);
          dialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "notification.component.ts" + " - confirmation() - " + err.message
      );
    }
  }

  deletenotification(AlertId, EscalationLevel, d_AlarmId, AlarmSubTypeId) {
    const notification: NotificationDelete = {
      authKey: this.authkey,
      alertId: AlertId,
      level: EscalationLevel,
    };

    this.notificationService
      .deleteNotification(notification)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: NotificationForCreationResponse[]) => {
        if (apiresponse != null && apiresponse != undefined) {
          // if (apiresponse["apiReturn"].Status == 1) {
          //   if (apiresponse["apiReturn"].countinfo == 3) {
          //     generateAlertMessage(
          //       this.dialog,
          //       "In order to remove Escalation 1, please remove Escalation 3,2 and try again."
          //     );
          //   } else {
          //     generateAlertMessage(
          //       this.dialog,
          //       "In order to remove Escalation 1, please remove Escalation 2 and try again."
          //     );
          //   }
          //   this.NotifyViewEnableDisable(enumEnableDisable.Enable);
          //   return;
          // } else if (apiresponse["apiReturn"].Status == 2) {
          //   generateAlertMessage(
          //     this.dialog,
          //     "In order to remove Escalation 2, please remove Escalation 3 and try again."
          //   );
          //   this.NotifyViewEnableDisable(enumEnableDisable.Enable);
          //   return;
          // } 
          if (apiresponse["apiReturn"] == "Success") {
            let CheckAlertData = {
              IsAlertChanged: 0,
              IsAlertClicked: 0,
            };
            this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
            CheckAlertData = {
              IsAlertChanged: 1,
              IsAlertClicked: 0,
            };
            this.apiService.reloadNotification();
            this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
            generateAlertMessage(
              this.dialog,
              "Escalation data deleted successfully."
            );
          } 
          else if (apiresponse["apiReturn"] == "Fail") {
            generateAlertMessage(
              this.dialog,
              "Escalation data deleted failed."
            );
          } 
          else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          else
          {
            generateAlertMessage(this.dialog,apiresponse["apiReturn"]);
            this.NotifyViewEnableDisable(enumEnableDisable.Enable);
            return;
          }
          this.NotifyViewEnableDisable(enumEnableDisable.Enable);
          this.collapsedIcon1 = "keyboard_arrow_down";
          this.AlarmSubTypeId_Show = AlarmSubTypeId;
          this.getNotification({
            authKey: this.authkey,
            alertId: parseInt(d_AlarmId),
            isOldUI: "0",
          });
        }
      });
  }

  NotifyViewEnableDisable(checkflag) {
    const elementcontent = document.getElementById("divmatnotifycontent");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "mat-content enablecontent";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "disablecontent";
  }

toggleNotifications(event)
{
  let continuousNotify = 0;
  if (event.checked == true) 
  {  
    this.checkNotification = true; 
    continuousNotify = 1; 
  }
  else
  {
    this.checkNotification = false;  
    continuousNotify = 0;
  }
    
    const continotification : any = {
      authKey: this.authkey,
      continuousNotify:continuousNotify,
      alertId: this.contiNotifyAlertId
     } 
   
    this.notificationService.updateContinuousNotification(continotification).pipe(takeUntil(this._onDestroy))
    .subscribe((apiresponse : NotificationForCreationResponse[])=>{     
        if (apiresponse !=null && apiresponse !=undefined)
        {  
           console.log(apiresponse)         
        }
    })
    
}
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
