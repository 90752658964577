import { Component, HostListener, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { MainLayoutService } from "./main-layout.service";
import { AppComponent } from "../app.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  closealertpushSocketConForUpdates,
  CloseLocationChangeSocket,
} from "../app.component";
@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  prevUrl: string;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    public router: Router,
    private mainLayoutService: MainLayoutService    
  ) {
    this.CheckButtonClick();
  }

  ngOnInit() {
    if (window.location.hash != "#/login" && window.location.hash != "") {
      window.focus();
      console.log("closealertpushSocketConForUpdates Main");
      closealertpushSocketConForUpdates();
      this.mainLayoutService.subscribeforalertpushevents();
      this.mainLayoutService.CheckInternetConnection();
      const authkey = localStorage.getItem("Authkey") ?? "";
      const roleId = localStorage.getItem("role") ?? "";
      const roledetails = {
        authKey: authkey,
        roleId: parseInt(roleId),
      };
      this.getUserRole(roledetails);
    }
  }

  getUserRole(data): void {
    this.mainLayoutService
      .getUserRole(data)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((StaffUserRoleView: []) => {
        AppComponent.StaffUserRoleView = StaffUserRoleView;
        localStorage.setItem(
          "StaffUserRoleView",
          JSON.stringify(AppComponent.StaffUserRoleView)
        );
      });
  }

  CheckButtonClick() {
    document.addEventListener("keydown", function (event) {
      const target = event.target;
      if ((target as HTMLTextAreaElement).tagName == "BUTTON") {
        (document.activeElement as HTMLTextAreaElement).blur();
      }
    });
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  @HostListener("window:beforeunload", ["$event"])
  public beforeunloadHandler($event) {
    //$event.returnValue = "" ;
    //removed all the local stored information
    console.log($event);
    if (!sessionStorage.getItem("userid")) {
      localStorage.clear();
    }
    closealertpushSocketConForUpdates();
    CloseLocationChangeSocket();
  }
}
