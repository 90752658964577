
<perfect-scrollbar style="max-width: 650px; max-height: 380px;" >
  <div class="clsdeletestaff">
      <div class="delete-title">
        <h3 class="deletestaffHeader" style="margin: 0 0 10px 11px;">Delete Staff
        <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
        <mat-divider ></mat-divider> 
      </div>
      <div>
        <form style="padding:24px" [formGroup]="staffDeleteForm" autocomplete="off" novalidate (ngSubmit)="deleteStaffbyId(staffDeleteForm.value)">
  
            <mat-grid-list [cols]="columns" rowHeight="60px">  
                <mat-grid-tile>
                    <label>Staff Name</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                          <input matInput placeholder="First Name" formControlName="staffname" [(ngModel)]="staffname">
                          <input type="hidden" formControlName="staffId" [(ngModel)]="data[0].StaffID">                        
                        </mat-form-field>
                      </div>
                </mat-grid-tile>

                <mat-grid-tile>
                    <label>Date</label>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">
                    <div class="text-inside" style="width:100%;">
                        <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                           <input matInput formControlName="date" [ngModel] ="currentDate | date:'yyyy-MM-dd'">
                        </mat-form-field>
                      </div>
                </mat-grid-tile>                

              <mat-grid-tile>
                <label>Reason</label>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <div class="text-inside" style="width:100%;margin-bottom: 0.5em;">
                    <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                      <input matInput placeholder="Reason" formControlName="staffreason" maxlength="30" (keyup)="handleInput($event)">    
                      <mat-error *ngIf="staffDeleteForm.controls.staffreason.hasError('required')">
                        Please enter Reason
                      </mat-error>
                      <mat-error *ngIf="staffDeleteForm.controls.staffreason.hasError('pattern')">
                        Please enter valid Reason
                      </mat-error>                   
                    </mat-form-field>
                  </div>
            </mat-grid-tile>   
            
            <mat-grid-tile>
              <label>Authorized By</label>
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
              <div class="text-inside" style="width:100%;">
                  <mat-form-field appearance="outline" floatLabel="auto" style="width:100%;">
                    <input matInput placeholder="Authorized By" formControlName="authorizedby" [(ngModel)]="authorizedName">                                       
                  </mat-form-field>
                </div>
          </mat-grid-tile>

                </mat-grid-list>

            <mat-dialog-actions class="clsbuttoncontrol">                  
              <button type="button" class="clsbtnStaffCancel" mat-raised-button (click)="onCloseCancel()">Cancel</button>
              <button class="clsbtnStaffDelete" mat-raised-button>Delete</button>&nbsp;
            </mat-dialog-actions>
        </form>
      </div>
  </div>
</perfect-scrollbar>