import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpRequest, 
    HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertSharedService } from '../_services';
import { changedHTTPRequest } from '../app.component';

@Injectable({
    providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
    constructor(private alertSharedService: AlertSharedService) { }
    handleError(error: HttpErrorResponse) {
        console.log("InterceptorService error :"+ error)
        if (error.message.indexOf("reload")<=0) //no need to check on get methods
        {
            console.log("HttpErrorResponse");
            console.log("error :" + error.message);
            const ShareHTTPRequestDetails = {
                "IsError": 1
            };
            changedHTTPRequest(ShareHTTPRequestDetails);
            return throwError(error);
        }
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clonedReq = req.clone({headers: req.headers.set('Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*')});
        if(req.body instanceof FormData) {
            return next.handle(req);
        }
        else
        {
            return next.handle(clonedReq).pipe(catchError(this.handleError));
        }
    }
}