import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { Subject } from "rxjs";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { ResidentForCreationResponse, getAlertByTagId } from "../resident";
import { ResidentService } from "../resident.service";
import {
  ReleAuthorize,
  ReleaseResident,
  Residentreleasetype,
} from "../resident";
import { appsettings, enumEnableDisable,enumTagType } from "../../../app-settings";
import { ApiService } from "../../../api.service";
import {
  TagIdfoundResponse,
  GetAlertsId,
  checkTagId,
  checkMapLocation,
} from "../../../api";
import { HttpClient } from "@angular/common/http";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../app-general";
import { AlertMessageComponent } from "../../../alert-message/alert-message.component";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-release-resident",
  templateUrl: "./release-resident.component.html",
  providers: [ResidentService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./release-resident.component.scss"],
})
export class ReleaseResidentComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 3;
  public residentreleaseForm: UntypedFormGroup;
  public residentreallocateForm: UntypedFormGroup;
  private URL = appsettings.URLinfo + "getAuthorizer";
  public ReleAuthorized: ReleAuthorize[];
  currentDate;
  selectedvalue;
  Release: number = Residentreleasetype.Release;
  Reallocate: number = Residentreleasetype.Reallocate;
  selectDisabled;
  residentTagId;
  residentname;
  cancelstatus;
  authorizedName;
  private _onDestroy: Subject<void> = new Subject<void>();
  ReleType;
  authkey;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  constructor(
    private router: Router,    
    private residentService: ResidentService,
    public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: Rollbar,
    public thisDialogRef: MatDialogRef<ReleaseResidentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.ReleType = data.Reltype;

    this.breakpoint$.subscribe(() => this.breakpointChanged());  
        
    this.residentTagId = data.ResidentbyId[0].TagId;
    this.residentname =
      data.ResidentbyId[0].FirstName + " " + data.ResidentbyId[0].LastName;
  }
  
  private breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 3;
    }         
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 2;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";

    this.getAuthorizedList();
    this.currentDate = new Date().toJSON("yyyy/MM/dd");
    this.residentreleaseForm = new UntypedFormGroup({
      residentname: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      residentId: new UntypedFormControl(""),
      date: new UntypedFormControl(""),
      residentstatus: new UntypedFormControl("", [Validators.required]),
      reason: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(appsettings.Name_Pattern),
      ]),
      authorizedby: new UntypedFormControl("", [Validators.required]),
    });

    const resForm_ResidentName = this.residentreleaseForm.get("residentname");
    const resForm_date = this.residentreleaseForm.get("date");
    const resForm_authorizedby = this.residentreleaseForm.get("authorizedby");
    if (resForm_ResidentName != null) resForm_ResidentName.disable();

    if (resForm_date != null) resForm_date.disable();

    if (resForm_authorizedby != null) resForm_authorizedby.disable();

    this.selectedvalue = "1";
    this.residentreallocateForm = new UntypedFormGroup({
      reallocatestatus: new UntypedFormControl("", [Validators.required]),
      residentId: new UntypedFormControl(""),
      tagid: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(10),
        Validators.max(1073741823),
        Validators.min(1),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
    });
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.residentreleaseForm.controls).forEach((field) => {
        // {1}
        const control = this.residentreleaseForm.get(field); // {2}
        const residentFormId = document.getElementById(event.target.id);
        if (residentFormId != null) {
          if (field == residentFormId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-staff.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  getAuthorizedList() {
    try {
      const _userName = localStorage.getItem("username");
      if (_userName != null) this.authorizedName = JSON.parse(_userName);
    } catch (err) {
      throw new Error(
        "release-resident.component.ts" +
          " - getAuthorizedList() - " +
          err.message
      );
    }
  }

  public releaseResidentbyId = (releaseFormValue) => {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    const saveclick = document.getElementById('btnresidentrelease');
    if(saveclick != null)
        saveclick.className = 'clsbtnResRelease mat-raised-button disablebtn';
    (document.activeElement as HTMLTextAreaElement).blur();
    if (this.residentreleaseForm.valid) {
      this.getAlertsByTagId(releaseFormValue, this.Release);
    } else {
      if(saveclick != null)
      saveclick.className = 'clsbtnResRelease mat-raised-button';
    }
  };

  public reallocateResidentbyId = (reallocateFormValue) => {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }
    this.resReallocateEnableDisable(enumEnableDisable.Disable);
    (document.activeElement as HTMLTextAreaElement).blur();
    if (this.residentreallocateForm.valid) {
      this.getAlertsByTagId(reallocateFormValue, this.Reallocate);
    } else {
      this.resReallocateEnableDisable(enumEnableDisable.Enable);
    }
  };

  private getAlertsByTagId = (releaseFormValue, type) => {
    try {
      if (type == this.Release) {
        const restagid: getAlertByTagId = {
          authKey: this.authkey,
          tagId: parseInt(this.residentTagId),
        };
        this.residentService
          .getAlertsByTagId(restagid)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((alertdata: GetAlertsId[]) => {
            if (alertdata != null && alertdata.length == 0) {
              this.releaseResident(releaseFormValue);
            } else {
              generateAlertMessage(
                this.dialog,
                "This resident " +
                  this.residentname +
                  " has open alerts, please complete or cancel those alerts and try again."
              );
              this.thisDialogRef.close("Close");
            }
          });
      } else if (type == this.Reallocate) {
        const tagidfoundItem: checkTagId = {
          authKey: this.authkey,
          newTagId: parseInt(releaseFormValue.tagid),
          resStaffId: parseInt(releaseFormValue.residentId),
          tagType:enumTagType.Resident
        };
        this.apiService
          .checkTagId(tagidfoundItem)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: TagIdfoundResponse[]) => {
            if (apiresponse.toString() == "not found") {
              this.checkMapLocation(releaseFormValue);
            } else {
              generateAlertMessage(
                this.dialog,
                "Duplicate tagid found, the " +
                  apiresponse[0].desc +
                  "  '" +
                  apiresponse[0].name +
                  "'  already has this tagid.<br> Please try again with different tagid"
              );
              if (this.ReleType == 2) {
                this.resReallocateEnableDisable(enumEnableDisable.Enable);
              }
              return;
            }
          });
      }
    } catch (err) {
      throw new Error(
        "release-resident.component.ts" +
          " - getAlertsByTagId() - " +
          err.message
      );
    }
  };

  checkMapLocation(releaseFormValue) {
    try {
      const campusfoundItem: checkMapLocation = {
        authKey: this.authkey,
        resStaffId: parseInt(releaseFormValue.residentId),
      };
      this.apiService
        .checkMapLocation(campusfoundItem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TagIdfoundResponse[]) => {
          if (apiresponse.toString() == "found") {
            this.onReallocateClicked(releaseFormValue);
          } else {
            if (this.ReleType == 2) {
              this.resReallocateEnableDisable(enumEnableDisable.Enable);
            }
            const AlertMessageData = {
              AlertMessage:
                "This resident " +
                this.residentname +
                " is not having " +
                apiresponse.toString() +
                " details, <br> Campus should be defined to send Geo Pendant Alerts to staffs.<br> Please fill-in Campus details for this resident.",
            };
            const br_count = AlertMessageData.AlertMessage.split("<br>");
            let _height;
            if (br_count.length > 1) {
              _height = (br_count.length - 1) * 18;
              _height = parseInt(180 + _height) + "px";
            } else {
              _height = "180px";
            }
            const dialogRef = this.dialog.open(AlertMessageComponent, {
              disableClose: true,
              width: "auto",
              height: _height,
              panelClass: "myapp-no-padding-dialog",
              autoFocus: false,
              data: AlertMessageData,
            });

            dialogRef.afterClosed().subscribe((result) => {
              console.log(result);
              this.onReallocateClicked(releaseFormValue);
            });
          }
        });
    } catch (err) {
      throw new Error(
        "release-resident.component.ts" +
          " - checkMapLocation() - " +
          err.message
      );
    }
  }
  releaseResident(releaseFormValue) {
    if (this.ReleType == 2) {
      this.resReallocateEnableDisable(enumEnableDisable.Enable);
    }
    const releaseResident: ReleaseResident = {
      authKey: this.authkey,
      residentId: parseInt(releaseFormValue.residentId),
      status: parseInt(releaseFormValue.residentstatus),
      reason: releaseFormValue.reason.replace(/\s+/g, " ").trim(),
      authorizedBy: this.authorizedName.toString(),
      tagId: 0,
    };
    this.residentService
      .releaseResident(releaseResident)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: ResidentForCreationResponse[]) => {
        if (apiresponse["apiReturn"] == "Success") {
          this.apiService.reloadactivetag();
          generateAlertMessage(
            this.dialog,
            "Resident data released successfully."
          );
        } else if (apiresponse["apiReturn"] == "Fail") {
          generateAlertMessage(this.dialog, "Resident data released failed.");
        } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
          this.thisDialogRef.close("Confirm");
          AuthFailedMessage(this.dialog, this.router);
          return;
        }
        this.thisDialogRef.close("Confirm");
      });
  }

  onReallocateClicked(releaseResidentdata) {
    try {
      if (this.ReleType == 2) {
        this.resReallocateEnableDisable(enumEnableDisable.Enable);
      }
      let res_Reason = "";
      let res_AuthorizedBy = "";
      if (releaseResidentdata.reason != undefined) {
        res_Reason = releaseResidentdata.reason.replace(/\s+/g, " ").trim();
      }
      if (releaseResidentdata.authorizedby != undefined) {
        res_AuthorizedBy = releaseResidentdata.authorizedby.toString();
      }
      const releaseResident: ReleaseResident = {
        authKey: this.authkey,
        residentId: parseInt(releaseResidentdata.residentId),
        status: parseInt(releaseResidentdata.reallocatestatus),
        reason: res_Reason,
        authorizedBy: res_AuthorizedBy,
        tagId: parseInt(releaseResidentdata.tagid),
      };
      this.residentService
        .releaseResident(releaseResident)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: ResidentForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {
            this.apiService.reloadactivetag();
            generateAlertMessage(
              this.dialog,
              "Resident data reallocated successfully."
            );
          } else if (apiresponse.toString() == "false") {
            generateAlertMessage(
              this.dialog,
              "Resident data reallocated failed."
            );
          } else if (apiresponse["condition"] == "Authfalse") {
            this.thisDialogRef.close("Confirm");
            AuthFailedMessage(this.dialog, this.router);
            return;
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "release-resident.component.ts" +
          " - onReallocateClicked() - " +
          err.message
      );
    }
  }

  resReallocateEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnresidentsave");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsbtnResRelease mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsbtnResRelease mat-raised-button disablebtn";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
