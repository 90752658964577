<perfect-scrollbar style="max-width: 980px; max-height: 600px; min-height: 600px;" >
    <div class="clsnotification">
        <div class="notificationedit-title" *ngFor="let campusdetails of Notificationdetails; let j = index">
          <h3 class="notificationEditHeader" style="margin: 0 0 10px 11px;" *ngIf="j == 0">Edit Notification Levels
          <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseeditNotification()"></span></h3>
          <mat-divider ></mat-divider> 
        </div>
        <perfect-scrollbar style="max-width: 980px; max-height: 450px; height: 450px;">
            <div id="divmatnotifycontent" *ngIf="Notificationdetails.length > 0; else noRecord" fxLayout="column" class="mat-content">
                <div fxLayout="row" *ngFor="let campusdetails of Notificationdetails; let i = index;">
                    <mat-card class="clsmatcard">
        
                        <div class="clsnotifymatcard" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                            fxLayoutAlign="space-between left">
                            <div fxLayout="column" fxLayoutAlign="left">
                                <mat-card-header *ngIf="campusdetails.AlarmSubTypeId == campusdetails.MinId">
                                    <mat-card-title fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true"
                                    *ngIf="campusdetails.AlarmSubTypeId !='' && campusdetails.CampusName.length>0 && campusdetails.Buildingname.length>0 
                                    && campusdetails.FloorName.length>0 && campusdetails.SubZoneDesc.length>0 " class="clsmatcardSubtitle">                                        
                                    {{campusdetails.CampusName}} - {{campusdetails.Buildingname}} - {{campusdetails.FloorName}} - {{campusdetails.SubZoneDesc}}</mat-card-title>

                                    <mat-card-title fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true"
                                    *ngIf="campusdetails.AlarmSubTypeId !='' && campusdetails.CampusName.length>0 && campusdetails.Buildingname.length>0 
                                    && campusdetails.FloorName.length>0 && campusdetails.SubZoneDesc.length == 0 " class="clsmatcardSubtitle"> 
                                    {{campusdetails.CampusName}} - {{campusdetails.Buildingname}} - {{campusdetails.FloorName}} </mat-card-title>
                                    
                                    <mat-card-title fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true"
                                    *ngIf="campusdetails.AlarmSubTypeId !='' && campusdetails.CampusName.length>0 && campusdetails.Buildingname.length>0 
                                    && campusdetails.FloorName.length == 0 && campusdetails.SubZoneDesc.length == 0 " class="clsmatcardSubtitle"> 
                                    {{campusdetails.CampusName}} - {{campusdetails.Buildingname}} </mat-card-title>

                                    <mat-card-title fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true"
                                    *ngIf="campusdetails.AlarmSubTypeId !='' && campusdetails.CampusName.length>0 && campusdetails.Buildingname.length == 0 
                                    && campusdetails.FloorName.length == 0 && campusdetails.SubZoneDesc.length == 0 " class="clsmatcardSubtitle"> 
                                    {{campusdetails.CampusName}}  </mat-card-title>                                    
                                </mat-card-header>
                                
                                <mat-card-header>
                                    <mat-card-title fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true"
                                        *ngIf="campusdetails.AlarmSubTypeId !=''" class="clsmatcardtitle">                                        
                                        {{campusdetails.AlarmSubTypeName }}</mat-card-title>
                                </mat-card-header>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="end" *ngIf="campusdetails.AlarmSubTypeId !=''">
                                <mat-card-actions class="clsmatcardaction">
                                    <button mat-button
                                        (click)="clickcollapse(i,$event,campusdetails.CampusId,campusdetails.AlarmSubTypeId,campusdetails.Buildid,campusdetails.FloorId,campusdetails.SubZoneId)">
                                        <mat-icon [id]="i" *ngIf="i == checkicon">{{collapsedIcon1}}</mat-icon>
                                        <mat-icon [id]="i" *ngIf="i != checkicon">{{collapsedIcon}}</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </div>
                        </div>
        
                        <mat-card-content *ngIf="checkicon == i ? arrowiconT : arrowiconF">

                            <mat-card-title class="clsmatcardNotify" fxShow.sm="false" fxShow.xs="false" style="border-bottom:1px solid #D4D5D6;">
                                <div style="padding-bottom: 1em;margin-left: 1.3em;">
                                    <label (click)="showSelectedRoom(2)" style="cursor: pointer;">Continuous Notifications</label>  
                                    <mat-slide-toggle [checked]="checkNotification" (change)="toggleNotifications($event)"
                                    style="padding-left:2.5em;"  [disabled]="checkNotificationCount == 0"></mat-slide-toggle>
                                </div>  
                            </mat-card-title>
                            
                            
                            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear=true style="margin-top:20px;">
        
                                <!-- Campus Column -->
                                <ng-container matColumnDef="Level">
                                    <th mat-header-cell *matHeaderCellDef style="width: 17%;padding-left: 17px;"> Notification Scheme </th>
                                    <td mat-cell *matCellDef="let element">
                                        <img class="clsImgWarning" src="assets/images/Alerts-ErrorAlert Icon-Popup.png" style="cursor: pointer;" [matTooltip]="NotificationLevelTooltip" matTooltipClass="viewnotifytooltip" #tooltip="matTooltip" matTooltipPosition="above"
                                            (click)="tooltip.show()" *ngIf="element.showNotifyWarningImage == 1 && element.Level != 1">
                                        <img class="clsnotifyImg" [src]="LevelImgURL + element.LevelImg" [ngStyle]="{'margin-left': element.showNotifyWarningImage == 1 && element.Level != 1 ? '0em' : '2.8em'}">
                                    </td>
                                </ng-container>
        
                                <!-- EventName Column -->
                                <ng-container matColumnDef="AlarmNameId">
                                    <th mat-header-cell *matHeaderCellDef style="width: 16%;">
                                        <img class="clsnotifyImg" matTooltipPosition="above" matTooltipClass="viewnotifytooltip" matTooltip="Notification Type" #tooltip="matTooltip" 
                                        (click)="tooltip.show()" src="assets/images/Notification/NotificationType.png">
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.AlarmNameId}} </td>
                                </ng-container>
        
                                <!-- TimetoEscalate Column -->
                                <ng-container matColumnDef="TimetoEscalate">
                                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">
                                        <img class="clsnotifyImg" matTooltipPosition="above" matTooltipClass="viewnotifytooltip" [matTooltip]="EscalateTooltip" #tooltip="matTooltip"
                                        (click)="tooltip.show()" src="assets/images/Notification/TimeEscalate.png">
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.TimetoEscalate}} </td>
                                </ng-container>
        
                                <!-- TimetoEscalate Column -->
                                <ng-container matColumnDef="RepeatCycle">
                                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">
                                        <img class="clsnotifyImg" matTooltipPosition="above" matTooltipClass="viewnotifytooltip" [matTooltip]="RepeatCycleTooltip" #tooltip="matTooltip"
                                        (click)="tooltip.show()" src="assets/images/Notification/RepeatCycle.png">
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.RepeatCycle}} </td>
                                </ng-container>
        
                                <!-- Repeats -->
                                <ng-container matColumnDef="Repeats">
                                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">
                                        <img class="clsnotifyImg" matTooltipPosition="above" matTooltipClass="viewnotifytooltip" matTooltip="# Repeats" #tooltip="matTooltip"
                                        (click)="tooltip.show()" src="assets/images/Notification/Repeats.png">
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.RepeatNumber}} </td>
                                </ng-container>
        
                                <!-- Notify Column -->
                                <ng-container matColumnDef="Notify">
                                    <th mat-header-cell *matHeaderCellDef style="width: 35%;">
                                        <img class="clsnotifyImg" matTooltipPosition="above" matTooltipClass="viewnotifytooltip" matTooltip="Recipients - Select the pencil icon to view/edit assigned recipients." #tooltip="matTooltip" 
                                        (click)="tooltip.show()" src="assets/images/Notification/Recepients.png">
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="element.Notify.length < 35">{{element.Notify}}</span>
                                        <span *ngIf="element.Notify.length >= 35" matTooltipClass="viewStaffnotifytooltip"
                                            matTooltip={{element.Notify}} #tooltip="matTooltip" matTooltipPosition="above">
                                            {{element.Notify.slice(0,35) + ".."}}
                                        </span>
                                    </td>
                                </ng-container>                                       
        
                                <!-- Edit Symbol Column -->
                                <ng-container matColumnDef="Edit">
                                    <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;width: 10%;"> Edit </th>
                                    <td mat-cell *matCellDef="let element"> <button mat-button
                                            (click)="editNotificationdata(element)">
                                            <mat-icon>edit</mat-icon>
                                        </button> </td>
                                </ng-container>
        
                                <!-- Delete Symbol Column -->
                                <ng-container matColumnDef="Delete">
                                    <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;width: 10%;"> Delete </th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-button
                                            (click)="deleteNotification(element.UDEventName,element.CampusName,element.AlertId,element.Level,element.EventId,element.CampusId,element.AlarmSubTypeId,element.BuildId,element.FloorId,element.SubZoneId)">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
        
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: false"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                            </table>
                            <div *ngIf="esclationcount >=0 && esclationcount < 3 && bAddEdit == true">
                                <mat-icon class="clsaddescalation">add</mat-icon>
                                <span *ngIf="esclationcount==0" class="clsaddescalationlbl"
                                    (click)="addNotification(esclationcount,campusdetails.CampusId,campusdetails.CampusName,campusdetails.AlarmName,campusdetails.AlarmId,campusdetails.AlertId,campusdetails.AlarmSubTypeId,campusdetails.AlarmSubTypeName,campusdetails.AlarmNameId,campusdetails.Buildid,campusdetails.NewBuildingName,campusdetails.FloorId,campusdetails.NewFloorName,campusdetails.SubZoneId,campusdetails.NewSubZoneName)">{{escalation}}
                                    Create {{this.esclationcount+1}}<sup>st</sup> Level</span>
                                <span *ngIf="esclationcount==1" class="clsaddescalationlbl"
                                    (click)="addNotification(esclationcount,campusdetails.CampusId,campusdetails.CampusName,campusdetails.AlarmName,campusdetails.AlarmId,campusdetails.AlertId,campusdetails.AlarmSubTypeId,campusdetails.AlarmSubTypeName,campusdetails.AlarmNameId,campusdetails.Buildid,campusdetails.NewBuildingName,campusdetails.FloorId,campusdetails.NewFloorName,campusdetails.SubZoneId,campusdetails.NewSubZoneName)">{{escalation}}
                                    Create {{this.esclationcount+1}}<sup>nd</sup> Level</span>
                                <span *ngIf="esclationcount==2" class="clsaddescalationlbl"
                                    (click)="addNotification(esclationcount,campusdetails.CampusId,campusdetails.CampusName,campusdetails.AlarmName,campusdetails.AlarmId,campusdetails.AlertId,campusdetails.AlarmSubTypeId,campusdetails.AlarmSubTypeName,campusdetails.AlarmNameId,campusdetails.Buildid,campusdetails.NewBuildingName,campusdetails.FloorId,campusdetails.NewFloorName,campusdetails.SubZoneId,campusdetails.NewSubZoneName)">{{escalation}}
                                    Create {{this.esclationcount+1}}<sup>rd</sup> Level</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
        
                </div>
        
        
            </div>
            <ng-template #noRecord>
                <mat-card>
                    <h3 style="text-align: center;margin-top:7em;">No campus added on the notification list </h3>
                </mat-card>
            </ng-template>
            <div class="loadspinner" *ngIf="notifyloading">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </perfect-scrollbar>
        <mat-dialog-actions class="clsnotifybuttoncontrol">                
            <button type="button" class="clsnotifyaddbutton" mat-raised-button (click)="onCloseeditNotification()">Close</button>
          </mat-dialog-actions>
 </div> 
  </perfect-scrollbar>
