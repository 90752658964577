import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import { ApiService } from "../../../../api.service";
import { AlertSharedService } from "../../../../_services/index";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumTech_Building_rightmenu,
  enumRequestFrom,
  fillcolorPolygon,
  enumUIView, enumEnableDisable
} from "../../../../app-settings";
import {
  TechnologyForCreationResponse,
  AddBuildingforCampus,
  BuildByID,
  CampusByID,
} from "../../technologyoverlay";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import { DOCUMENT } from "@angular/common";
import OlMap from "ol/Map";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
  map_widthandheight,
  Checkpolygon_Intersect,
  getCheckInExtent,
  LoadTechnologyBuildingSvg,
  calculateCenter,
} from "../../../../app-general";
import {
  map_creation,
  fillcolor_style,
  label_Polygon,
  get_geojsonObject_campus,
  fillcolor_styleSelect,
} from "../../../../map-general";
import { Circle as CircleStyle, Style, Fill, Stroke} from "ol/style";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmMessageComponent } from "../../../../confirm-message/confirm-message.component";
import { EditBuildingComponent } from "../building/edit-building/edit-building.component";
import { getBuilding } from "../../../../api";

import Draw, { createBox, createRegularPolygon } from "ol/interaction/Draw";
import { getCenter, getHeight, getWidth } from "ol/extent";
import {
  never,
  platformModifierKeyOnly,
  primaryAction,
  shiftKeyOnly,
  singleClick,
} from "ol/events/condition";
import { MultiPoint, Point } from "ol/geom";
import OlFeature from "ol/Feature";
import OlPoint from "ol/geom/Point";

import {
  Modify,
  Select,
  Translate
} from "ol/interaction";
import OlCollection from "ol/Collection";
import "ol-popup/src/ol-popup.css";
import OlVectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";
import { style } from "@angular/animations";
@Component({
  selector: "app-mapview",
  templateUrl: "./mapview.component.html",
  styleUrls: ["./mapview.component.scss"],
})
export class MapviewComponent implements OnInit {
  bAddEdit = false;
  bDelete = false;
  imageExtent;
  checkroomdrawend = false;

  buildingcampusname = "";
  buildingcampusfullname = "";
  floorbuildingname = "";
  floorbuildingfullname = "";
  unitfloorname = "";
  unitfloorfullname = "";
  roomunitname = "";
  roomunitfullname = "";

  selectedcampus = 0;
  selectedbuilding = 0;
  selectedfloor = 0;
  selectedunit = 0;

  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  dialogResult = "";
  displayedColumnsRoom: string[] = [
    "DeviceType",
    "DeviceId",
    "LforIr",
    "MacId",
    "DeviceName",
  ];
  dataSourceRoom;

  campusImgUrl: string = appsettings.CAMPUS_IMAGE_FOLDER;
  floorImgUrl: string = appsettings.FLOOR_IMAGE_FOLDER;

  watcher: Subscription;
  columns = 4;

  buildflag = 0;
  editflag = 0;
  formCampusId;
  addcampusid;
  addbuildingid;
  addfloorid;
  svgimageurl = 0;

  FacilityImgurl: string = appsettings.Facility_IMAGE_FOLDER;
  FacilityName;
  campuseditdialogopen = 0;
  buildeditdialogopen = 0;
  flooreditdialogopen = 0;
  uniteditdialogopen = 0;
  MapSyncProcess;
  editdialogfacilityopen = 0;

  campusimagename;
  campusaddImgUrl;
  validcampusimgurl;
  CampusCnt = 0;
  BuildingCnt = 0;
  FloorCnt = 0;
  UnitCnt = 0;
  Importflag = 0;
  importHeaders;
  getselectedmapdetails;

  imgSrc;
  clseditimg;

  getbuildingdata;
  MCampusId;
  MBuildingId;
  MFloorId;
  CampusFulldetails: any[];
  selectedCampus;
  selectedBuilding;
  selectedFloor;

  CampusImageURL = "";
  BuildingImageURL = "";
  FloorImageURL = "";
  SvgImageUrl = "";
  campusName;
  FloorInfo;
  buildDuplicatedialogopen = 0;
  ImageWidth = "";
  ImageHeight = "";
  addfloorimageheight = 0;
  ImageUrl = "";
  BackgroundIMG = "Centrak";
  Check_ZoomLevel;
  Check_MapCenter;
  CZoomLevel;
  CRotateLevel;
  TranslateX;
  TranslateY;
  SvgInfo = null;
  map = new OlMap();
  Building_Name;
  Campus_Name;

  selectedFeatures;
  collapsedIcon1 = "keyboard_arrow_down";
  collapsedIcon = "keyboard_arrow_down";

  collapsedIcon1_build = "keyboard_arrow_up";
  collapsedIcon_build = "keyboard_arrow_up";

  collapsedIcon1_floor = "keyboard_arrow_up";
  collapsedIcon_floor = "keyboard_arrow_up";

  collapsedIcon1_unit = "keyboard_arrow_up";
  collapsedIcon_unit = "keyboard_arrow_up";

  checkicon;
  preId = -1;
  arrowiconT = true;
  arrowiconF = false;

  build_arrowiconT = true;
  build_arrowiconF = false;

  floor_arrowiconT = true;
  floor_arrowiconF = false;

  unit_arrowiconT = true;
  unit_arrowiconF = false;

  camp_isbuttonshow = 0;
  build_isbuttonshow = 0;
  floor_isbuttonshow = 0;

  Floor_Name;

  selectedBuildingdetails = {};
  selectedFloordetails = {};

  Campus_key = "Campusinfo";
  Building_key = "Buildinginfo";
  Floor_key = "Floorinfo";
  chkbuildmapflag = 0;
  devicefiltervalue_mapdetail;
  Map_array_length = 3;
  device_list_all = 3;

  selected_floorId = 0;
  selected_buildId = 0;
  selected_campusId = 0;
  getMap_flag = 0;
  getpolypoints = "";
  authkey;
  _divCreateSpaceMenu;
  _divEditBuildingMenu;

  Tech_Building_Move = enumTech_Building_rightmenu.Move;
  Tech_Create_Building = enumTech_Building_rightmenu.Create_Space;
  Tech_Edit_Building = enumTech_Building_rightmenu.Edit_Space;
  Tech_Delete_Building = enumTech_Building_rightmenu.Delete_Space;

  Tech_Rectangle_Building = enumTech_Building_rightmenu.Rectangle_Space;
  Ellipse_Building = enumTech_Building_rightmenu.Ellipse_Space;
  FreeHand_Building = enumTech_Building_rightmenu.FreeHand_Space;

  Edit_Point_Building = enumTech_Building_rightmenu.Edit_Point;
  Rotate_Building = enumTech_Building_rightmenu.Rotate_Space;
  Reflect_Building = enumTech_Building_rightmenu.Reflect_Space;

  //Draw feature for Room
  vector = new OlVectorLayer();

  source = new VectorSource({ wrapX: false });

  vectorLayer = new OlVectorLayer({
    source: this.source,
  });
  draw = new Draw({
    source: this.source,
    type: "Polygon",
  });

  private _onDestroy: Subject<void> = new Subject<void>();
  buildingmapviewdialogopen = 0;
  checkCreateBuilding = 0;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    @Inject(DOCUMENT) private document: any,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<MapviewComponent>,
    private technologyoverlayService: TechnologyoverlayService
  ) {
      const getRouterNavigate = this.router.getCurrentNavigation();
      if(getRouterNavigate != null)
      {
        if (getRouterNavigate.extras.state != undefined)
          localStorage.setItem(
            "MapviewComponent",
            JSON.stringify(getRouterNavigate.extras.state)
          );
      }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    const mapviewComponent = localStorage.getItem("MapviewComponent") ?? "";
    this.getbuildingdata = JSON.parse(mapviewComponent);
    this.Campus_Name = this.getbuildingdata.CampusName;
    this.Building_Name = this.getbuildingdata.BuildingName;

    this.addcampusid = this.getbuildingdata.CampusId;
    this.addbuildingid = this.getbuildingdata.BuildId;
    this.getBuildingdetails();
    this.selectCampusFull();
  }

  selectCampusFull() {
    try {
      const campusid: CampusByID = {
        authKey: this.authkey,
        campusId: parseInt(this.getbuildingdata.CampusId),
      };

      let item;
      this.MCampusId = this.getbuildingdata.CampusId;
      this.apiService
        .getCampusbyCampusId(campusid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((CampusFulldetails: any[]) => {
          // need to latest campus from sysc need to show the global alert start
          let CheckAlertData = {
            IsAlertChanged: 0,
            IsAlertClicked: 0,
          };
          this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
          CheckAlertData = {
            IsAlertChanged: 1,
            IsAlertClicked: 0,
          };
          this.alertSharedService.changeSharedCheckAlert(CheckAlertData);
          // need to latest campus from sysc need to show the global alert end

          this.CampusFulldetails = CampusFulldetails;

          this.selectedCampus = this.getbuildingdata.CampusId;
          this.selectedBuilding = this.getbuildingdata.BuildId;
          this.selectedFloor = 0;

          const _SharedHomeMapDetails = {
            sel_CampusId: this.selectedCampus,
            sel_BuildingId: this.selectedBuilding,
            sel_FloorId: this.selectedFloor,
          };

          this.alertSharedService.changeSharedHomeMapdetails(
            _SharedHomeMapDetails
          );
          const BuildingById = 0;
          const FloorId = 0;
          const mapdetail = this.MCampusId + "_" + BuildingById + "_" + FloorId;
          this.CampusFulldetails["SelectedMapInfo"] = mapdetail;
          item = { MapDetails: this.CampusFulldetails };
          this.getPolygonpoints(CampusFulldetails);
          this.getMapDetails(item);
        });

      if (this.bAddEdit == false) {
        const container_EditBuilding = document.getElementById(
          "container_EditBuilding"
        );
        if (container_EditBuilding != null)
          container_EditBuilding.className = "clsdisable";
        const container_DeleteBuilding = document.getElementById(
          "container_DeleteBuilding"
        );
        if (container_DeleteBuilding != null)
          container_DeleteBuilding.className = "clsdisable";
        const container_SelectMove = document.getElementById(
          "container_SelectMove"
        );
        if (container_SelectMove != null)
          container_SelectMove.className = "clsdisable";
        const container_CreateSpace = document.getElementById(
          "container_CreateSpace"
        );
        if (container_CreateSpace != null)
          container_CreateSpace.className = "clsenable";
      }
    } catch (err) {
      throw new Error(
        "mapview.component.ts - selectCampusFull() - " + err.message
      );
    }
  }

  getPolygonpoints(CampusFulldetails) {
    let getcampusdetails = [] as any[];
    let poly_points = "";
    const selectedbuildingName = this.getbuildingdata.BuildingName;
    getcampusdetails = CampusFulldetails["Campusinfo"][0].Campusdetail;
    getcampusdetails.forEach(function (data) {
      if (data.buildingname == selectedbuildingName) {
        poly_points = data.polyline;
        return false;
      } else {
        return true;
      }
    });
    this.getpolypoints = poly_points;
    if (this.getpolypoints != "" && this.getpolypoints != null)
      this.checkdrawroomflag = 2;
    else this.checkdrawroomflag = 0;
  }

  getMapDetails(MapDetails): void {
    let arrMapDetails = "";
    
    if (MapDetails.MapDetails.SelectedMapInfo != "0_0_0") {
      arrMapDetails = MapDetails.MapDetails.SelectedMapInfo.split("_");
      this.CampusFulldetails = MapDetails.MapDetails;
      this.MCampusId = parseInt(arrMapDetails[0]);
      this.MBuildingId = parseInt(arrMapDetails[1]);
      this.MFloorId = parseInt(arrMapDetails[2]);

      this.CampusFulldetails = MapDetails.MapDetails;
      if (arrMapDetails[2] == "0") {
        const CampusId = arrMapDetails[0];
        const element = document.getElementById("divDeviceheader");
        if(element != null)
          element.className = "enable";
        for (
          let info = 0;
          info <= this.CampusFulldetails["Campusinfo"].length - 1;
          info++
        ) {
          if (
            this.CampusFulldetails["Campusinfo"][info]["CampusId"] == CampusId
          ) {
            this.CampusImageURL =
              this.CampusFulldetails["Campusinfo"][info]["FloorPlanImage"];
            this.ImageWidth =
              this.CampusFulldetails["Campusinfo"][info]["ImageWidth"];
            this.ImageHeight =
              this.CampusFulldetails["Campusinfo"][info]["ImageHeight"];
            this.SvgImageUrl =
              appsettings.CAMPUS_IMAGE_FOLDER +
              "/" +
              this.CampusFulldetails["Campusinfo"][info]["svgFile"];
            this.ImageUrl =
              appsettings.CAMPUS_IMAGE_FOLDER + "/" + this.CampusImageURL;
            this.SvgInfo =
              this.CampusFulldetails["Campusinfo"][info]["Campusdetail"];
            this.campusName =
              this.CampusFulldetails["Campusinfo"][info]["CampusName"];
            this.CZoomLevel =
              this.CampusFulldetails["Campusinfo"][info]["ZoomLevel"];
            this.CRotateLevel =
              this.CampusFulldetails["Campusinfo"][info]["RotateLevel"];
            this.TranslateX =
              this.CampusFulldetails["Campusinfo"][info]["TransformX"];
            this.TranslateY =
              this.CampusFulldetails["Campusinfo"][info]["TransformY"];
            this.FloorInfo = null;
            this.DrawMapCall();
            break;
          }
        }
        this.BackgroundIMG = "Centrak";
      }
    }
  }

  DrawMapCall() {
    try {
      //Map Empty
      const element = this.document.getElementById("map");
      let IsZoomChanged = 0;
      if (
        element.innerHTML != "" &&
        (this.Check_ZoomLevel || this.Check_ZoomLevel == 0)
      ) {
        this.CZoomLevel = this.Check_ZoomLevel;
        IsZoomChanged = 1;
      } else {
        this.Check_MapCenter = [];
      }
      element.innerHTML = "";
      const leftmenu = document.getElementById("leftmenu1");
      if(leftmenu != null)
        leftmenu.classList.add("active");
      ///Map width and height
      const imgwh = map_widthandheight(this.ImageWidth, this.ImageHeight);
      const imgwidth = imgwh.ImageWidth;
      const imgheight = imgwh.ImageHeight;
      this.addfloorimageheight = imgwh.ImageHeight;

      // Polygons style
      const labelStyle = label_Polygon(0);
      const fillcolorStyle = fillcolor_style(fillcolorPolygon.Incomplete);
      const style = [fillcolorStyle, labelStyle];

      this.map = map_creation(
        this.ImageUrl,
        imgwidth,
        imgheight,
        true,
        this.BackgroundIMG,
        this.CZoomLevel,
        this.CRotateLevel,
        element.offsetWidth,
        element.offsetHeight,
        this.TranslateX,
        this.TranslateY,
        "map",
        this.Check_MapCenter,
        IsZoomChanged
      );

      let translateX = this.TranslateX;
      let translateY = this.TranslateY;

      translateX = translateX / 122;
      translateY = translateY / 127;

      translateX = translateX * imgwidth;
      translateY = translateY * imgheight;

      const _map = this.map;
      const MapCenter = _map.getView().getCenter();

      MapCenter[0] -= translateX;
      MapCenter[1] += translateY;

      const _this = eval('this');
      let currZoom = _this.CZoomLevel;
      _map.on("moveend", function (e) {
        if (IsZoomChanged == 0)
          getCheckInExtent(e, _map, imgwidth, imgheight, MapCenter);
        const newZoom = _map.getView().getZoom();
        if (currZoom != newZoom) {
          console.log("zoom end, new zoom: " + newZoom);
          currZoom = newZoom;
          _this.CZoomLevel = newZoom;
          _this.Check_ZoomLevel = newZoom;
          _this.Check_MapCenter = _map.getView().getCenter();
        }
      });

      _map.on("pointermove", function () {
        _this.Check_MapCenter = _map.getView().getCenter();
      });

      const arrmarkerName = [];
      if (this.SvgInfo != null) {
        const geojsonObject = get_geojsonObject_campus(
          enumRequestFrom.floorplan_page
        );
        geojsonObject.type = "FeatureCollection";

        
        geojsonObject.features.length = 0;
        if (this.SvgInfo.length > 0) {
          const arrSvgInfo = this.SvgInfo;
          LoadTechnologyBuildingSvg(
            this.map,
            this.MCampusId,
            arrSvgInfo,
            imgheight,
            arrmarkerName,
            geojsonObject,
            fillcolorStyle,
            labelStyle,
            style,
            this.addbuildingid
          );

          for (
            let Build = 0;
            Build <= this.Buildingdetails.length - 1;
            Build++
          ) {
            if (
              this.Buildingdetails[Build].BuildId == this.addbuildingid &&
              this.Buildingdetails[Build].svgPoints != null
            ) {
              this.Buildingsvgid = this.Buildingdetails[Build].svgId;
              const container_CreateSpace = document.getElementById(
                "container_CreateSpace"
              );
              if(container_CreateSpace != null)
                container_CreateSpace.className = "clsdisable";
              if (this.bAddEdit == true) {
                // check if allow to Edit option
                const container_EditBuilding = document.getElementById(
                  "container_EditBuilding"
                );
                if(container_EditBuilding != null)
                  container_EditBuilding.className = "clsenable";
              }
            } else if (
              this.Buildingdetails[Build].BuildId == this.addbuildingid &&
              this.Buildingdetails[Build].svgPoints == null
            ) {
              this.Buildingsvgid = this.Buildingdetails[Build].svgId;
              const container_EditBuilding = document.getElementById(
                "container_EditBuilding"
              );
              if(container_EditBuilding != null)
                container_EditBuilding.className = "clsdisable";
              const container_DeleteBuilding = document.getElementById(
                "container_DeleteBuilding"
              );
              if(container_DeleteBuilding != null)
                container_DeleteBuilding.className = "clsdisable";
              const container_SelectMove = document.getElementById(
                "container_SelectMove"
              );
              if(container_SelectMove != null)
                container_SelectMove.className = "clsdisable";
              if (this.bAddEdit == true) {
                // check if allow to Edit option
                const container_CreateSpace = document.getElementById(
                  "container_CreateSpace"
                );
                if(container_CreateSpace != null)
                  container_CreateSpace.className = "clsenable";
              }
            }
          }
          if(this.checkCreateBuilding == 1)
            this.openBuildingEditdialog();
        }
      }
    } catch (err) {
      throw new Error("mapview.component.ts - DrawMapCall() - " + err.message);
    }
  }

  btnclickmapinfohide() {
    this.router.navigate(["../../floorplans"], { state: this.getbuildingdata });
  }

  create_space() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    this._divEditBuildingMenu = document.getElementById("divEditBuildingeMenu");
    this._divEditBuildingMenu.style.display = "none";
    if (this._divCreateSpaceMenu.style.display == "none") {
      this._divCreateSpaceMenu.style.display = "block";
      this.checkCreateBuilding = 1;
    } else {
      this._divCreateSpaceMenu.style.display = "none";
      this.checkCreateBuilding = 0;
    }
  }

  edit_building() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    this._divEditBuildingMenu = document.getElementById("divEditBuildingeMenu");
    this._divCreateSpaceMenu.style.display = "none";
    if (this._divEditBuildingMenu.style.display == "none") {
      this._divEditBuildingMenu.style.display = "block";
    } else {
      this._divEditBuildingMenu.style.display = "none";
    }
  }

  //Delete a selected building outline.
  delete_building() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    this._divEditBuildingMenu = document.getElementById("divEditBuildingeMenu");
    this._divCreateSpaceMenu.style.display = "none";
    this._divEditBuildingMenu.style.display = "none";

    const ConfirmMessageData = {
      ConfirmMessage:
        "Are you sure you want to delete this Building map drawn?",
    };
    const dialogRef = this.dialog.open(ConfirmMessageComponent, {
      disableClose: true,
      width: "auto",
      height: "auto",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: ConfirmMessageData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Yes") {
        try {
          if (!SessionCheck(this.router)) {
            this.thisDialogRef.close("Cancel");
            return;
          }

          const _dialog = this.dialog;

          const addbuildingforcampus: AddBuildingforCampus = {
            authKey: this.authkey,
            campusId: parseInt(this.addcampusid),
            polyline: "",
            buildId: parseInt(this.addbuildingid),
          };

          this.technologyoverlayService
            .updateBuildingPolyPoints(addbuildingforcampus)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
              if (apiresponse.toString() == "true") {
                generateAlertMessage(
                  _dialog,
                  "Building map drawn deleted successfully.",
                  this.router,
                  this.getbuildingdata
                );
                return false;
              } else {
                generateAlertMessage(_dialog, "Building deleted failed.");
                return false;
              }
            });
        } catch (err) {
          throw new Error(
            "mapview.component.ts - delete_building() - " + err.message
          );
        }
      } else {
        return false;
      }
    });
  }
  //Create a Building outline - Click and drag to draw your Rectangle space, then move it on the map.
  draw_rectangle() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    this._divCreateSpaceMenu.style.display = "none";
    const geometryFunction = createBox();
    this.DrawFeature("Circle", geometryFunction);
  }

  draw_ellipse() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    this._divCreateSpaceMenu.style.display = "none";
    const geometryFunction = createRegularPolygon(20);
    this.DrawFeature("Circle", geometryFunction);
  }

  draw_freehand() {
    this._divCreateSpaceMenu = document.getElementById("divCreateSpaceMenu");
    this._divCreateSpaceMenu.style.display = "none";
    this.DrawFeature("Polygon", null);
  }

  checkdrawroomflag = 0;
  drawroomselect;
  Buildingsvgid = 0;
  Buildingdetails: any[];

  select = null;
  modify = null;
  translate = null;
  select_Feature;
  selectName_Feature;
  selectedFeature;
  selectedNameFeature;
  //Draw feature for Devices
  tempVectorSource = new VectorSource();

  DrawFeature(DrawFeature_Type, geometryFunction) {
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;

    const fillcolorStyle = fillcolor_styleSelect();

    this.map.removeInteraction(this.draw);
    let drawRoomSource = this.source;
    function onDrawStart(event) {
      //remove everything drawn previously
      drawRoomSource.clear();
      event.feature.setStyle(fillcolorStyle);
    }

    if (geometryFunction != null) {
      this.draw = new Draw({
        source: this.source,
        type: DrawFeature_Type,
        geometryFunction: geometryFunction,
      });
    } else {
      this.draw = new Draw({
        source: this.source,
        type: DrawFeature_Type,
      });
    }
    this.draw.on("drawstart", onDrawStart);

    drawRoomSource = this.source;

    this.vector = new OlVectorLayer({
      source: this.source,
    });

    this.vector.setStyle(fillcolorStyle);
    this.vector.set("extent", this.imageExtent);

    this.map.addInteraction(this.draw);
    this.map.addLayer(this.vector);

    // Get the array of features
    const features = this.vector.getSource().getFeatures();
    if (features != undefined) {
      // Go through this array and get coordinates of their geometry.
      //features.forEach(function (feature) {});
    }

    let checkdraw;
    this.draw.on("drawend", function () {
      checkdraw = true;
    });
    this.checkroomdrawend = checkdraw;
  }

  //Edit a building outline - Select a building to edit its points and (Shift + Left mouse click) to remove the selected point.
  Edit_Points() {
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
    this._divEditBuildingMenu = document.getElementById("divEditBuildingeMenu");
    this._divEditBuildingMenu.style.display = "none";

    if (!SessionCheck(this.router)) {
      return;
    }

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;
    this.tempVectorSource.clear();

    this.ClearInteraction();

    const sel_BuildId = this.addbuildingid;
    let sel_feature;
    let selName_feature;
    const mapObj = this.map;    

    this.map.getLayers().forEach(function (Buildinglayer) {
      if (
        Buildinglayer.get("name") != undefined &&
        Buildinglayer.get("name") === "Building"
      ) {
        const arrFeature = Buildinglayer.getSource().getFeatures();
        if (arrFeature) {
          // Go through this array and get coordinates of their geometry.
          arrFeature.forEach(function (Buildingfeature) {
            const Buildingid = Buildingfeature.getId().split("-")[1];
            if (parseInt(Buildingid) == sel_BuildId) {
              sel_feature = Buildingfeature;
          
              return false;
            } else {
              return true;
            }
          });
        }
      }
    });

    if (sel_feature != undefined) {
      this.checkdrawroomflag = 2;
      this.select_Feature = sel_feature;
      this.selectedFeature = sel_feature.getGeometry().getCoordinates();
    }

    if (this.checkdrawroomflag == 2) {
      const fillcolorStyle = fillcolor_styleSelect();
      this.select = new Select({
        multi: true,
        style: fillcolorStyle,
        features: new OlCollection([sel_feature]),
      });

      this.map.on("pointermove", (evt) => {
        if (!evt.dragging) {
          const pixel = this.map.getEventPixel(evt.originalEvent);
          const hit = this.map.forEachFeatureAtPixel(
            pixel,
            function (feature, layer) {
              console.log(feature);
              console.log(layer);
              return true;
            }
          );
          if (hit) {
            let circlehit = false;
            const features = _select.getFeatures();
            features.forEach(function (feature) {
              const coords = feature.getGeometry().getCoordinates();
              for (let j = 0; j < coords.length; j++) {
                const coord = coords[j];
                for (let k = 0; k < coord.length; k++) {
                  const p0 = evt.pixel;
                  const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                  const dist = ref.pointsDist(p0, p1);
                  if (dist <= 8) {
                    circlehit = true;
                  }
                }
              }
            });
            if (circlehit) {
              this.map.getTargetElement().style.cursor = this.map.hasFeatureAtPixel(this.map.getEventPixel(evt.originalEvent)) ? 'crosshair' : '';
            } else {
              this.map.getTargetElement().style.cursor = "";
            }
          } else {
            this.map.getTargetElement().style.cursor = "";
          }
          
        }
      });
      const ref = eval('this');
      this.modify = new Modify({
        features: new OlCollection([sel_feature]),
        style: fillcolorStyle,
        deleteCondition: false,
      });
      let _select = this.select;
      let _modify = this.modify;

      const _imageExtent = this.imageExtent;
      const _dialog = this.dialog;

      this.map.getLayers().forEach(function (BuildingNamelayer) {
        if (
          BuildingNamelayer.get("name") != undefined &&
          BuildingNamelayer.get("name") === "BuildingName"
        ) {
          const arrFeature = BuildingNamelayer.getSource().getFeatures();
          if (arrFeature) {
            // Go through this array and get coordinates of their geometry.
            arrFeature.forEach(function (BuildingNamefeature) {
              const BuildingNameid = BuildingNamefeature.getId().split("-")[1];
              if (parseInt(BuildingNameid) == sel_BuildId) {
                _select = new Select({
                  multi: true,
                  style: fillcolorStyle,
                  features: new OlCollection([sel_feature]),
                });

                _modify = new Modify({
                  features: new OlCollection([sel_feature]),
                  style: fillcolorStyle,
                  deleteCondition: function (e) {
                    if (shiftKeyOnly(e) && singleClick(e)) {
                      const features = _select.getFeatures();
                      features.forEach(function (feature) {
                        const coords = feature.getGeometry().getCoordinates();
                        for (let j = 0; j < coords.length; j++) {
                          const coord = coords[j];
                          for (let k = 0; k < coord.length; k++) {
                            const p0 = e.pixel;
                            const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                            const dist = ref.pointsDist(p0, p1);
                            if (dist <= 8) {
                              coord.splice(k, 1);
                              ref.updateGeometry(feature, coord);
                              return;
                            }
                          }
                        }
                      });
                    } else {
                      return;
                    }
                  },
                });
                selName_feature = BuildingNamefeature;
                mapObj.getInteractions().extend([_select, _modify]);

                _select.on("select", function (e) {
                  let Buildingid;
                  if (e.selected)
                    Buildingid = e.selected[0].getId().split("-")[1];
                  if (parseInt(Buildingid) != sel_BuildId) {
                    _select.getFeatures().clear();
                  } else {
                    //mapObj.removeInteraction(_translate);
                    mapObj.removeInteraction(_modify);
                    _modify = new Modify({
                      features: new OlCollection([e.selected[0]]),
                      style: fillcolorStyle,
                      deleteCondition: function (e) {
                        if (shiftKeyOnly(e) && singleClick(e)) {
                          const features = _select.getFeatures();
                          features.forEach(function (feature) {
                            const coords = feature.getGeometry().getCoordinates();
                            for (let j = 0; j < coords.length; j++) {
                              const coord = coords[j];
                              for (let k = 0; k < coord.length; k++) {
                                const p0 = e.pixel;
                                const p1 = ref.map.getPixelFromCoordinate(
                                  coord[k]
                                );
                                const dist = ref.pointsDist(p0, p1);
                                if (dist <= 8) {
                                  coord.splice(k, 1);
                                  ref.updateGeometry(feature, coord);
                                  return;
                                }
                              }
                            }
                          });
                        } else {
                          return;
                        }
                      },
                    });
                    mapObj.getInteractions().extend([_modify]);
                  }
                });

                const _IsCheckRoomDrawn = function IsCheckRoomDrawn() {
                  let roomDrwan = true;
                  const vertices = sel_feature.getGeometry().getCoordinates();
                  let polyLine = [];
                  vertices[0].pop();
                  polyLine = vertices[0];
                  for (let i = 0; i < polyLine.length; i++) {
                    const xi = polyLine[i][0],
                      yi = polyLine[i][1];
                    if (
                      xi < 0 ||
                      yi < 0 ||
                      xi > _imageExtent[2] ||
                      yi > _imageExtent[3]
                    ) {
                      roomDrwan = false;
                      break;
                    }
                  }
                  if (roomDrwan == false) {
                    generateAlertMessage(
                      _dialog,
                      "Please place the room within the map extent"
                    );
                  }
                };

                _modify.on(
                  "modifyend",
                  function (e) {
                    console.log(e);
                    _IsCheckRoomDrawn();
                  },
                  sel_feature
                );
                return false;
              } else {
                return true;
              }
            });
          }
        }
      });

      this.select_Feature = sel_feature;
      this.selectName_Feature = selName_feature;
      this.selectedFeature = sel_feature.getGeometry().getCoordinates();
      this.selectedNameFeature = selName_feature.getGeometry().getCoordinates();
    }

    // let drawRoomSource;
    // function onDrawStart(event) {
    //   console.log(event);
    //   //remove everything drawn previously
    //   drawRoomSource.clear();
    // }
  }

  pointsDist(p0, p1) {
    const dx = p0[0] - p1[0];
    const dy = p0[1] - p1[1];
    return Math.sqrt(dx * dx + dy * dy);
  }

  updateGeometry(feature, coord) {
    feature.getGeometry().setCoordinates([coord]);
  }

  //Select a building outline to Move.
  select_move() {
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
    this._divEditBuildingMenu = document.getElementById("divEditBuildingeMenu");
    this._divEditBuildingMenu.style.display = "none";

    if (!SessionCheck(this.router)) {
      return;
    }

    this.checkdrawroomflag = 1;
    this.drawroomselect = 1;
    this.tempVectorSource.clear();

    this.ClearInteraction();

    const sel_BuildId = this.addbuildingid;
    let sel_feature;
    let selName_feature;
    const mapObj = this.map;

    this.map.getLayers().forEach(function (Buildinglayer) {
      if (
        Buildinglayer.get("name") != undefined &&
        Buildinglayer.get("name") === "Building"
      ) {
        const arrFeature = Buildinglayer.getSource().getFeatures();
        if (arrFeature) {
          // Go through this array and get coordinates of their geometry.
          arrFeature.forEach(function (Buildingfeature) {
            const Buildingid = Buildingfeature.getId().split("-")[1];
            if (parseInt(Buildingid) == sel_BuildId) {
              sel_feature = Buildingfeature;
              return false;
            } else {
              return true;
            }
          });
        }
      }
    });

    if (sel_feature != undefined) {
      this.checkdrawroomflag = 2;
      this.select_Feature = sel_feature;
      this.selectedFeature = sel_feature.getGeometry().getCoordinates();
    }

    if (this.checkdrawroomflag == 2) {
      const fillcolorStyle = fillcolor_styleSelect();
      this.select = new Select({
        multi: true,
        style: fillcolorStyle,
        features: new OlCollection([sel_feature]),
      });

      this.translate = new Translate({
        features: new OlCollection([sel_feature]),
        style: fillcolorStyle,
        deleteCondition: false,
      });

      let _select = this.select;
      let _translate = null;
      const _imageExtent = this.imageExtent;
      const _dialog = this.dialog;

      this.map.getLayers().forEach(function (BuildingNamelayer) {
        if (
          BuildingNamelayer.get("name") != undefined &&
          BuildingNamelayer.get("name") === "BuildingName"
        ) {
          const arrFeature = BuildingNamelayer.getSource().getFeatures();
          if (arrFeature) {
            // Go through this array and get coordinates of their geometry.
            arrFeature.forEach(function (BuildingNamefeature) {
              const BuildingNameid = BuildingNamefeature.getId().split("-")[1];
              if (parseInt(BuildingNameid) == sel_BuildId) {
                _select = new Select({
                  multi: true,
                  style: fillcolorStyle,
                  features: new OlCollection([sel_feature]),
                });
                selName_feature = BuildingNamefeature;
                _translate = new Translate({
                  style: fillcolorStyle,
                  features: new OlCollection([sel_feature, selName_feature]),
                });
                mapObj.getInteractions().extend([_select, _translate]); //_modify

                _select.on("select", function (e) {
                  let Buildingid;
                  if (e.selected)
                    Buildingid = e.selected[0].getId().split("-")[1];
                  if (parseInt(Buildingid) != sel_BuildId) {
                    _select.getFeatures().clear();
                  } else {
                    mapObj.removeInteraction(_translate);
                    _translate = new Translate({
                      style: fillcolorStyle,
                      features: new OlCollection([
                        e.selected[0],
                        selName_feature,
                      ]),
                    });
                    mapObj.getInteractions().extend([_translate]); //_modify
                  }
                });

                const _IsCheckRoomDrawn = function IsCheckRoomDrawn() {
                  let roomDrwan = true;
                  const vertices = sel_feature.getGeometry().getCoordinates();
                  let polyLine = [];
                  vertices[0].pop();
                  polyLine = vertices[0];
                  for (let i = 0; i < polyLine.length; i++) {
                    const xi = polyLine[i][0],
                      yi = polyLine[i][1];
                    if (
                      xi < 0 ||
                      yi < 0 ||
                      xi > _imageExtent[2] ||
                      yi > _imageExtent[3]
                    ) {
                      roomDrwan = false;
                      break;
                    }
                  }
                  if (roomDrwan == false) {
                    generateAlertMessage(
                      _dialog,
                      "Please place the room within the map extent"
                    );
                  }
                };
                _translate.on(
                  "translateend",
                  function (e) {
                    console.log(e);
                    _IsCheckRoomDrawn();
                  },
                  sel_feature
                );
                return false;
              } else {
                return true;
              }
            });
          }
        }
      });

      this.select_Feature = sel_feature;
      this.selectName_Feature = selName_feature;
      this.selectedFeature = sel_feature.getGeometry().getCoordinates();
      this.selectedNameFeature = selName_feature.getGeometry().getCoordinates();
    }

    // let drawRoomSource;
    // function onDrawStart(event) {
    //   console.log(event);
    //   //remove everything drawn previously
    //   drawRoomSource.clear();
    // }
  }

  Rotate_drawing() {
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
    this._divEditBuildingMenu = document.getElementById("divEditBuildingeMenu");
    this._divEditBuildingMenu.style.display = "none";
    try {
      if (!SessionCheck(this.router)) {
        return;
      }

      this.checkdrawroomflag = 1;
      this.drawroomselect = 1;
      this.tempVectorSource.clear();
      this.map.removeInteraction(this.draw);

      const sel_BuildId = this.addbuildingid;
      let sel_feature;
      let selName_feature;
      const mapObj = this.map;
      let selectedLayer;

      this.map.getLayers().forEach(function (Buildinglayer) {
        if (
          Buildinglayer.get("name") != undefined &&
          Buildinglayer.get("name") === "Building"
        ) {
          const arrFeature = Buildinglayer.getSource().getFeatures();
          if (arrFeature) {
            // Go through this array and get coordinates of their geometry.
            arrFeature.forEach(function (Buildingfeature) {
              const Buildingid = Buildingfeature.getId().split("-")[1];
              if (parseInt(Buildingid) == sel_BuildId) {
                sel_feature = Buildingfeature;
                selectedLayer = Buildinglayer;
                return false;
              } else {
                return true;
              }
            });
          }
        }
      });

      if (sel_feature != undefined) {
        this.checkdrawroomflag = 2;
        this.select_Feature = sel_feature;
        this.selectedFeature = sel_feature.getGeometry().getCoordinates();
      }

      if (this.checkdrawroomflag == 2) {
        const fillcolorStyle = fillcolor_styleSelect();
        this.Rotate_Source(selectedLayer, sel_feature);
        this.select = new Select({
          multi: true,
          style: fillcolorStyle,
        });

        const _MapviewComponent = eval('this');
        this.map.getLayers().forEach(function (BuildingNamelayer) {
          if (
            BuildingNamelayer.get("name") != undefined &&
            BuildingNamelayer.get("name") === "BuildingName"
          ) {
            const arrFeature = BuildingNamelayer.getSource().getFeatures();
            if (arrFeature) {
              // Go through this array and get coordinates of their geometry.
              arrFeature.forEach(function (BuildingNamefeature) {
                const BuildingNameid = BuildingNamefeature.getId().split("-")[1];
                if (parseInt(BuildingNameid) == sel_BuildId) {
                  selName_feature = BuildingNamefeature;
                  _MapviewComponent.Rotate_Source(selectedLayer, sel_feature);
                  mapObj.getInteractions().extend([_MapviewComponent.select]);
                  _MapviewComponent.select.on("select", function (e) {
                    let Buildingid;
                    if (e.selected)
                      Buildingid = e.selected[0].getId().split("-")[1];
                    if (parseInt(Buildingid) != sel_BuildId) {
                      _MapviewComponent.select.getFeatures().clear();
                    } else {
                      _MapviewComponent.Rotate_Source(
                        selectedLayer,
                        e.selected[0]
                      );
                    }
                  });                  
                  return false;
                } else {
                  return true;
                }
              });
            }
          }
        });

        this.select_Feature = sel_feature;
        this.selectName_Feature = selName_feature;
        this.selectedFeature = sel_feature.getGeometry().getCoordinates();
        this.selectedNameFeature = selName_feature
          .getGeometry()
          .getCoordinates();
      }
    } catch (err) {
      throw new Error("mapview.component.ts - DrawFeature() - " + err.message);
    }
  }

  Rotate_Source(Buildinglayer, sel_feature) {
    const source = new VectorSource();

    try {
      const marker = new OlFeature({
        geometry: new OlPoint(sel_feature.getGeometry().getCoordinates()),
        id: sel_feature.getId(),
      });
      marker.id_ = sel_feature.getId();
      source.addFeature(marker);
      this.Rotate_Feature(Buildinglayer, sel_feature, source);
    } catch (err) {
      throw new Error("mapview.component.ts - DrawFeature() - " + err.message);
    }
  }

  Rotate_Feature(Buildinglayer, sel_feature, source) {
    const mapObj = this.map;
    const _MapviewComponent = eval('this');
    try {
      const style = new Style({
        geometry: function (feature) {
          const modifyGeometry = feature.get("modifyGeometry");
          return modifyGeometry
            ? modifyGeometry.geometry
            : feature.getGeometry();
        },
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
      });

      const vector = new OlVectorLayer({
        source: source,
        style: function (feature) {
          const styles = [style];
          const modifyGeometry = feature.get("modifyGeometry");
          const geometry = modifyGeometry
            ? modifyGeometry.geometry
            : feature.getGeometry();
          const result = _MapviewComponent.calculateCenter(geometry);
          const center = result.center;
          if (center) {
            styles.push(
              new Style({
                geometry: new Point(center),
                image: new CircleStyle({
                  radius: 4,
                  fill: new Fill({
                    color: "#ff3333",
                  }),
                }),
              })
            );
            const coordinates = result.coordinates;
            if (coordinates) {
              const minRadius = result.minRadius;
              const sqDistances = result.sqDistances;
              const rsq = minRadius * minRadius;
              const points = coordinates.filter(function (coordinate, index) {
                return sqDistances[index] > rsq;
              });
              styles.push(
                new Style({
                  geometry: new MultiPoint(points),
                  image: new CircleStyle({
                    radius: 4,
                    fill: new Fill({
                      color: "#33cc33",
                    }),
                  }),
                })
              );
            }
          }
          return styles;
        },
      });

      mapObj.addLayer(vector);

      const defaultStyle = new Modify({ source: source }) //source: source
        .getOverlay()
        .getStyleFunction();

      const modify = new Modify({
        source: source,
        condition: function (event) {
          return primaryAction(event) && !platformModifierKeyOnly(event);
        },
        deleteCondition: never,
        insertVertexCondition: never,
        style: function (feature) {
          feature.get("features").forEach(function (modifyFeature) {
            const modifyGeometry = modifyFeature.get("modifyGeometry");
            if (modifyGeometry) {
              const point = feature.getGeometry().getCoordinates();
              let modifyPoint = modifyGeometry.point;
              if (!modifyPoint) {
                // save the initial geometry and vertex position
                modifyPoint = point;
                modifyGeometry.point = modifyPoint;
                modifyGeometry.geometry0 = modifyGeometry.geometry;
                // get anchor and minimum radius of vertices to be used
                const result = _MapviewComponent.calculateCenter(
                  modifyGeometry.geometry0
                );
                modifyGeometry.center = result.center;
                modifyGeometry.minRadius = result.minRadius;
              }

              const center = modifyGeometry.center;
              const minRadius = modifyGeometry.minRadius;
              let dx, dy;
              dx = modifyPoint[0] - center[0];
              dy = modifyPoint[1] - center[1];
              const initialRadius = Math.sqrt(dx * dx + dy * dy);
              if (initialRadius > minRadius) {
                const initialAngle = Math.atan2(dy, dx);
                dx = point[0] - center[0];
                dy = point[1] - center[1];
                const currentRadius = Math.sqrt(dx * dx + dy * dy);
                if (currentRadius > 0) {
                  const currentAngle = Math.atan2(dy, dx);
                  const geometry = modifyGeometry.geometry0.clone();
                  geometry.scale(
                    currentRadius / initialRadius,
                    undefined,
                    center
                  );
                  geometry.rotate(currentAngle - initialAngle, center);
                  modifyGeometry.geometry = geometry;
                }
              }
            }
          });
          return defaultStyle(feature);
        },
      });

      modify.on("modifystart", function (event) {
        event.features.forEach(function (feature) {
          feature.set(
            "modifyGeometry",
            { geometry: feature.getGeometry().clone() },
            true
          );
        });
      });

      modify.on("modifyend", function (event) {
        event.features.forEach(function (feature) {
          const modifyGeometry = feature.get("modifyGeometry");
          if (modifyGeometry) {
            feature.setGeometry(modifyGeometry.geometry);
            feature.unset("modifyGeometry", true);
          }
        });
      });

      mapObj.addInteraction(modify);
      mapObj.addInteraction(
        new Translate({
          condition: function (event) {
            return primaryAction(event) && platformModifierKeyOnly(event);
          },
          layers: [vector],
        })
      );
    } catch (err) {
      throw new Error("mapview.component.ts - DrawFeature() - " + err.message);
    }
  }

  calculateCenter(geometry) {
    try {
      let center, coordinates, minRadius;
      const type = geometry.getType();
      if (type === "Polygon") {
        let x = 0;
        let y = 0;
        let i = 0;
        coordinates = geometry.getCoordinates()[0].slice(1);
        coordinates.forEach(function (coordinate) {
          x += coordinate[0];
          y += coordinate[1];
          i++;
        });
        center = [x / i, y / i];
      } else if (type === "LineString") {
        center = geometry.getCoordinateAt(0.5);
        coordinates = geometry.getCoordinates();
      } else {
        center = getCenter(geometry.getExtent());
      }
      let sqDistances;
      if (coordinates) {
        sqDistances = coordinates.map(function (coordinate) {
          const dx = coordinate[0] - center[0];
          const dy = coordinate[1] - center[1];
          return dx * dx + dy * dy;
        });
        minRadius = Math.sqrt(Math.max(sqDistances)) / 3; //todo
      } else {
        minRadius =
          Math.max(
            getWidth(geometry.getExtent()),
            getHeight(geometry.getExtent())
          ) / 3;
      }
      return {
        center: center,
        coordinates: coordinates,
        minRadius: minRadius,
        sqDistances: sqDistances,
      };
    } catch (err) {
      throw new Error("mapview.component.ts - DrawFeature() - " + err.message);
    }
  }

  public onSaveCampusRoom = () => {
    try {
      if (!SessionCheck(this.router)) {
        return;
      }
      if (this.getbuildingdata != undefined) {
        (this.document.activeElement as HTMLTextAreaElement).blur();
        const _dialog = this.dialog;
        try {
          if (this.checkdrawroomflag == 0) {
            generateAlertMessage(_dialog, "Please draw the Building");
            return false;
          }

          // Get the array of features
          if (this.vector.getSource() == null && this.checkdrawroomflag != 2) {
            generateAlertMessage(_dialog, "Please draw the Building");
            return false;
          }
          const features = this.vector.getSource().getFeatures();
          if (features.length == 0 && this.checkdrawroomflag != 2) {
            generateAlertMessage(_dialog, "Please draw the Building");
            return false;
          }
        } 
        catch(err) {
          console.log("onSaveCampusRoom =>" + err);
        }

        let roomDrwan = true;
        if (this.CheckRoomDrawn()) {
          roomDrwan = false;
        } else {
          roomDrwan = true;
        }

        if (roomDrwan == false) {
          generateAlertMessage(
            _dialog,
            "Please place the room within the map extent"
          );
          return;
        } else {
          this.onSaveRoom();
          this.closeDrawFeature();
        }
      }
    } catch (err) {
      throw new Error(
        "mapview.component.ts - onSaveCampusRoom() - " + err.message
      );
    }
  };

  onSaveRoom() {
    try {
      const _dialog = this.dialog;
      let polyLine;
      let vertices;
      let polypoints;
      let verticesinfo = "";

      let sel_feature;
      if (this.checkdrawroomflag == 2) {
        const sel_BuildId = this.addbuildingid;
        const _imageHeight = this.addfloorimageheight;
        this.map.getLayers().forEach(function (Buildinglayer) {
          if (
            Buildinglayer.get("name") != undefined &&
            Buildinglayer.get("name") === "Building"
          ) {
            const arrFeature = Buildinglayer.getSource().getFeatures();
            if (arrFeature) {
              // Go through this array and get coordinates of their geometry.
              arrFeature.forEach(function (Buildingfeature) {
                const Buildingid = Buildingfeature.getId().split("-")[1];
                if (parseInt(Buildingid) == sel_BuildId) {
                  sel_feature = Buildingfeature;
                  polyLine = sel_feature.getGeometry().getCoordinates();
                  vertices = polyLine;
                  for (let i = 0; i < vertices.length; i++) {
                    for (let j = 0; j < vertices[0].length; j++) {
                      polypoints = _imageHeight - parseFloat(vertices[0][j][1]);
                      vertices[0][j][0] = Math.floor(vertices[0][j][0]);
                      vertices[0][j][1] = Math.floor(polypoints);
                      if (j == vertices[0].length - 1) {
                        verticesinfo +=
                          vertices[0][j][0] + "," + vertices[0][j][1];
                      } else {
                        verticesinfo +=
                          vertices[0][j][0] + "," + vertices[0][j][1] + " ";
                      }
                    }
                  }
                  return false;
                } else {
                  return true;
                }
              });
            }
          }
        });
      } else {
        if (this.vector) {
          const features = this.vector.getSource().getFeatures();
          if (features) {
            // Go through this array and get coordinates of their geometry.
            features.forEach(function (feature) {
              polyLine = feature.getGeometry().getCoordinates();
            });
          }
          const _imageHeight = this.addfloorimageheight;
          vertices = polyLine;
          for (let i = 0; i < vertices.length; i++) {
            vertices[0].pop();

            for (let j = 0; j < vertices[0].length; j++) {
              polypoints = _imageHeight - parseFloat(vertices[0][j][1]);
              vertices[0][j][0] = Math.floor(vertices[0][j][0]);
              vertices[0][j][1] = Math.floor(polypoints);
              if (j == vertices[0].length - 1) {
                verticesinfo += vertices[0][j][0] + "," + vertices[0][j][1];
              } else {
                verticesinfo +=
                  vertices[0][j][0] + "," + vertices[0][j][1] + " ";
              }
            }
          }
        } else {
          verticesinfo = this.getpolypoints;
        }
      }
     
      this.mapviewEnableDisable(enumEnableDisable.Disable);
      const addbuildingforcampus: AddBuildingforCampus = {
        authKey: this.authkey,
        campusId: parseInt(this.addcampusid),
        polyline: verticesinfo,
        buildId: parseInt(this.addbuildingid),
      };

      let sMessage = "Building added successfully.";
      let sErrorMessage = "Building added failed.";
      if (this.checkdrawroomflag == 2 && verticesinfo.length > 0) {
        sMessage = "Building edited successfully.";
        sErrorMessage = "Building edited failed.";
      }

      this.technologyoverlayService
        .updateBuildingPolyPoints(addbuildingforcampus)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: TechnologyForCreationResponse[]) => {
          if (apiresponse.toString() == "true") {

            this.ClearInteraction();
            if(this.checkdrawroomflag == 2  && verticesinfo.length>0)
            {
              generateAlertMessage(
                _dialog,
                sMessage,
                this.router,
                this.getbuildingdata
              );            
            }
            this.mapviewEnableDisable(enumEnableDisable.Enable);
            this.selectCampusFull();  
            // return false;
          } else {
            generateAlertMessage(_dialog, sErrorMessage);            
            this.mapviewEnableDisable(enumEnableDisable.Enable);
          }
        });
    } catch (err) {
      throw new Error("mapview.component.ts - onSaveRoom() - " + err.message);
    }
  }
  
  mapviewEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnbuildsaveclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsbtnfloorsave mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className = "clsbtnfloorsave mat-raised-button disablebtn";
  }

  CheckRoomDrawn() {
    let roomDrwan = false;
    if (this.vector.getSource() != null) {
      if (this.vector.getSource().getFeatures().length == 0) {
        roomDrwan = true;
      } else {
        // Get the array of features
        const features = this.vector.getSource().getFeatures();
        let vertices;
        let polyLine = [];

        if (features) {
          // Go through this array and get coordinates of their geometry.
          features.forEach(function (feature) {
            vertices = feature.getGeometry().getCoordinates();
          });
        }

        vertices[0].pop();
        polyLine = vertices[0];

        for (let i = 0; i < polyLine.length; i++) {
          const xi = polyLine[i][0],
            yi = polyLine[i][1];
          if (
            xi < 0 ||
            yi < 0 ||
            xi > this.imageExtent[2] ||
            yi > this.imageExtent[3]
          ) {
            roomDrwan = true;
            break;
          }
        }
      }
    }
    return roomDrwan;
  }

  closeDrawFeature() {
    try {
      this.map.removeInteraction(this.draw);
    } catch (err) {
      throw new Error(
        "mapview.component.ts - closeDrawFeature() - " + err.message
      );
    }
  }

  getBuildingdetails() {
    try {
      const CampusId = this.addcampusid;
      const buildingid: getBuilding = {
        authKey: this.authkey,
        campusId: parseInt(CampusId),
      };
      this.apiService
        .getBuildingDetails(buildingid)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((BuildingDetails: any[]) => {
          if (BuildingDetails != null && BuildingDetails != undefined) {
            this.Buildingdetails = BuildingDetails["apiReturn"];
            this.selectedBuilding = this.Buildingdetails[0].BuildId;
            this.Buildingsvgid = this.Buildingdetails[0].svgId;
          }
        });
    } catch (err) {
      throw new Error(
        "mapview.component.ts - getBuildingdetails() - " + err.message
      );
    }
  }

  openBuildingEditdialog() {
    if (this.buildeditdialogopen == 0 && this.getbuildingdata != undefined) {     
      this.getbuildingdata["isOpenBuildDisable"] = 1;
      this.checkCreateBuilding = 0;
      const dialogRef = this.dialog.open(EditBuildingComponent, {
        disableClose: true,
        width: "75em",
        height: "750px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: this.getbuildingdata,
      });
      this.buildeditdialogopen = 1;
      dialogRef.afterClosed().subscribe((result) => {
        this.buildeditdialogopen = 0;
        if (result != "Cancel") {
          this.BuildingMapView(result.BuildId);
        }
        else if(result == "Cancel")
          this.checkCreateBuilding = 0;
      });
    }
  }

  BuildingMapView(result) {
    const buildbyid: BuildByID = {
      authKey: this.authkey,
      buildId: parseInt(result),
    };
    this.technologyoverlayService
      .getBuildingById(buildbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((BuildingbyId: any[]) => {
        this.getbuildingdata = BuildingbyId["apiReturn"][0];
        this.ngOnInit();
      });
  }

  Rotate_Points(isScale) {
    console.log("Rotate_Points ");
    const arr_Layers = this.map.getLayers();
    this.imageExtent = arr_Layers.array_[0].getSource().getImageExtent();
    this._divEditBuildingMenu = document.getElementById("divEditBuildingeMenu");
    this._divEditBuildingMenu.style.display = "none";

    if (!SessionCheck(this.router)) {
      return;
    }

    this.tempVectorSource.clear();

    this.ClearInteraction();
    const fillcolorStyle = fillcolor_styleSelect();

    
    const sel_BuildId = this.addbuildingid;
    const arr_layer = [] as any[];
    this.map.getLayers().forEach(function (Buildinglayer) {
      if (
        Buildinglayer.get("name") != undefined &&
        Buildinglayer.get("name") === "Building"
      ) {
        const arrFeature = Buildinglayer.getSource().getFeatures();
        if (arrFeature) {
          // Go through this array and get coordinates of their geometry.
          arrFeature.forEach(function (Buildingfeature) {
            const Buildingid = Buildingfeature.getId().split("-")[1];
            if (parseInt(Buildingid) == sel_BuildId) {
              arr_layer[arr_layer.length] = Buildingfeature;
              return false;
            } else {
              return true;
            }
          });
        }
      }
    });

    const pstyle = new Style({
      geometry: function (feature) {
        const modifyGeometry = feature.get("modifyGeometry");
        return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
      },
      fill: new Fill({
        color: "rgba(122, 199, 234,0.35)",
      }),
      stroke: new Stroke({
        color: "rgba(122, 199, 234,1)",
        width: 4,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: "rgba(122, 199, 234,1)",
          width: 4,
        }),
        fill: new Fill({
          color: "#ffffff",
        }),
      }),
    });
    this.select = new Select({
      multi: true,
      style: function (feature) {
        const styles = [pstyle];
        const modifyGeometry = feature.get("modifyGeometry");
        const geometry = modifyGeometry
          ? modifyGeometry.geometry
          : feature.getGeometry();
        const result = calculateCenter(geometry);
        const center = result.center;
        if (center) {
          styles.push(
            new Style({
              geometry: new Point(center),
              image: new CircleStyle({
                radius: 1,
                fill: new Fill({
                  color: "rgba(247, 53, 53, 0.1)",
                }),
              }),
            })
          );
          const coordinates = result.coordinates;
          if (coordinates) {
            const minRadius = result.minRadius;
            const sqDistances = result.sqDistances;
            const rsq = minRadius * minRadius;
            const points = coordinates.filter(function (coordinate, index) {
              return sqDistances[index] > rsq;
            });
            styles.push(
              new Style({
                geometry: new MultiPoint(points),
                image: new CircleStyle({
                  radius: 5,
                  stroke: new Stroke({
                    color: "rgba(122, 199, 234,1)",
                    width: 4,
                  }),
                  fill: new Fill({
                    color: "#ffffff",
                  }),
                }),
              })
            );
          }
        }
        return styles;
      },
    });
    //this.map.addInteraction(this.select);

    const _select = this.select;
       
    this.selectedFeatures = this.select.getFeatures();

    this.map.on("pointermove", (evt) => {
      if (!evt.dragging) {
        const pixel = this.map.getEventPixel(evt.originalEvent);
        const hit = this.map.forEachFeatureAtPixel(
          pixel,
          function (feature, layer) {
            console.log(feature);
            console.log(layer);
            return true;
          }
        );
        if (hit) {
          let circlehit = false;
          const features = _select.getFeatures();
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = evt.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  circlehit = true;
                }
              }
            }
          });
          if (circlehit) {
            this.map.getTargetElement().style.cursor = "crosshair";
          } else {
            this.map.getTargetElement().style.cursor = "";
          }
        } else {
          this.map.getTargetElement().style.cursor = "";
        }
      }
    });

    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        this.map.removeInteraction(interaction);
      }
      if (interaction instanceof Select) {
        interaction.getFeatures().clear();
      }
    });

    _select.on("select", function (e) {
      console.log("selected on triggered");
      if (e.selected) {
        const _DataId = e.selected[0].getProperties().DataId;
        const _MultiDataId = e.selected[0].getProperties().MultiDataId;
        const geometry = e.selected[0].getProperties().geometry;

        if (_DataId) {
          _select.getFeatures().clear();
          // _modify.features_.array_ = []
          _select.multi_ = true;
          _select.style_ = fillcolorStyle;
          for (let indx = 0; indx < arr_layer.length; indx++) {
            const features_device = arr_layer[indx].getSource().getFeatures();
            if (features_device) {
              // Go through this array and get coordinates of their geometry.
              features_device.forEach(function (feature) {
                // if (
                //   parseInt(feature.getProperties().DataId) ==
                //     parseInt(_DataId) &&
                //   geometry.flatCoordinates.length == 2 &&
                //   geometry.flatCoordinates.length ==
                //     feature.getProperties().geometry.flatCoordinates.length &&
                //   parseInt(feature.getProperties().DeviceType) != 4
                // ) {
                // } else 
                if (_MultiDataId.toString().split(",").indexOf(parseInt(feature.getProperties().DataId).toString()) != -1 &&
                    geometry.flatCoordinates.length != 2) {
                     
                    _select.getFeatures().push(feature);
                }
              });
            }
          }
        }
      }
    });

    const _addfloorimageheight = this.addfloorimageheight;
    let _sel_feature = null;
    let _sel_polygon = null;
    const _objMapviewFloorComponent = eval('this');
    const ref = eval('this');
    this.modify = new Modify({
      features: ref.selectedFeatures,
      condition: function (event) {
        return primaryAction(event) && !platformModifierKeyOnly(event);
      },
      deleteCondition: function (e) {
        if (shiftKeyOnly(e) && singleClick(e)) {
          const features = ref.selectedFeatures;
          features.forEach(function (feature) {
            const coords = feature.getGeometry().getCoordinates();
            for (let j = 0; j < coords.length; j++) {
              const coord = coords[j];
              for (let k = 0; k < coord.length; k++) {
                const p0 = e.pixel;
                const p1 = ref.map.getPixelFromCoordinate(coord[k]);
                const dist = ref.pointsDist(p0, p1);
                if (dist <= 8) {
                  coord.splice(k, 1);
                  ref.updateGeometry(feature, coord);
                  return;
                }
              }
            }
          });
        } else {
          return;
        }
      },
      insertVertexCondition: never,
      style: function (feature) {
        //feature.get('features').forEach(function (modifyFeature) {
        const features = ref.selectedFeatures;
        features.forEach(function (modifyFeature) {
          const modifyGeometry = modifyFeature.get("modifyGeometry");
          console.log("modifyGeometry style " + modifyGeometry);
          if (modifyGeometry) {
            const point = feature.getGeometry().getCoordinates();
            let modifyPoint = modifyGeometry.point;
            console.log("modifyGeometry modifyPoint " + modifyPoint);
            if (!modifyPoint) {
              // save the initial geometry and vertex position
              modifyPoint = point;
              modifyGeometry.point = modifyPoint;
              modifyGeometry.geometry0 = modifyGeometry.geometry;
              // get anchor and minimum radius of vertices to be used
              const result = calculateCenter(modifyGeometry.geometry0);
              modifyGeometry.center = result.center;
              modifyGeometry.minRadius = result.minRadius;
            }

            const center = modifyGeometry.center;
            const minRadius = modifyGeometry.minRadius;
            let dx, dy;
            dx = modifyPoint[0] - center[0];
            dy = modifyPoint[1] - center[1];
            const initialRadius = Math.sqrt(dx * dx + dy * dy);
            if (initialRadius > minRadius) {
              const initialAngle = Math.atan2(dy, dx);
              dx = point[0] - center[0];
              dy = point[1] - center[1];
              const currentRadius = Math.sqrt(dx * dx + dy * dy);
              if (currentRadius > 0) {
                const currentAngle = Math.atan2(dy, dx);
                const geometry = modifyGeometry.geometry0.clone();
                if (isScale == 1) {
                  geometry.scale(
                    currentRadius / initialRadius,
                    undefined,
                    center
                  );
                } else {
                  geometry.rotate(currentAngle - initialAngle, center);
                }

                modifyGeometry.geometry = geometry;
              }
            }
          }
        });
        return style(feature);
      },
    });

    this.modify.on("modifystart", function (event) {
      console.log("modifystart");
      event.features.forEach(function (feature) {
        console.log(" modifystart feature each");
        feature.set(
          "modifyGeometry",
          { geometry: feature.getGeometry().clone() },
          true
        );
      });
    });

    this.modify.on("modifyend", function (e) {
      console.log("modifyend");
      e.features.forEach(function (feature) {
        const modifyGeometry = feature.get("modifyGeometry");
        if (modifyGeometry) {
          feature.setGeometry(modifyGeometry.geometry);
          feature.unset("modifyGeometry", true);
        }
      });

      _sel_feature = e.features;
      for (let indx = 0; indx < e.features.getArray().length; indx++) {
        const vertices = e.features.getArray()[indx].getGeometry()
          .flatCoordinates.length;
        if (vertices != 2) {
          _sel_polygon = e.features.getArray()[indx];
          Checkpolygon_Intersect(
            _objMapviewFloorComponent.map,
            _objMapviewFloorComponent.dialog,
            _sel_feature,
            _sel_polygon,
            sel_BuildId,
            _addfloorimageheight
          );
        }
      }
    });

    this.map.getInteractions().extend([this.select, this.modify]);
    console.log("rotate end");
  }

  ClearInteraction() {
    const arr_Interactions = [] as any[];
    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Translate) {
        arr_Interactions[arr_Interactions.length] = interaction;
      }
      if (interaction instanceof Modify) {
        arr_Interactions[arr_Interactions.length] = interaction;
      }
      if (interaction instanceof Select) {
        arr_Interactions[arr_Interactions.length] = interaction;
      }
      if (interaction instanceof Draw) {
        arr_Interactions[arr_Interactions.length] = interaction;
      }
    });

    for (let idx_int = 0; idx_int < arr_Interactions.length; idx_int++) {
      this.map.removeInteraction(arr_Interactions[idx_int]);
    }
  }

  mouseoverrightsidemenu(rightmenu) {
    const buildMouseOverText = document.getElementById("buildingmouseovertext");
    if (buildMouseOverText != null) {
      if (rightmenu == enumTech_Building_rightmenu.Move)
        buildMouseOverText.innerHTML = appsettings.Tech_Building_Move;
      else if (rightmenu == enumTech_Building_rightmenu.Create_Space)
        buildMouseOverText.innerHTML = appsettings.Tech_Building_Create;
      else if (rightmenu == enumTech_Building_rightmenu.Edit_Space)
        buildMouseOverText.innerHTML = appsettings.Tech_Building_Edit;
      else if (rightmenu == enumTech_Building_rightmenu.Delete_Space)
        buildMouseOverText.innerHTML = appsettings.Tech_Building_Delete;
      else if (rightmenu == enumTech_Building_rightmenu.Rectangle_Space)
        buildMouseOverText.innerHTML = appsettings.Tech_Rectangle_Building;
      else if (rightmenu == enumTech_Building_rightmenu.Ellipse_Space)
        buildMouseOverText.innerHTML = appsettings.Tech_Ellipse_Building;
      else if (rightmenu == enumTech_Building_rightmenu.FreeHand_Space)
        buildMouseOverText.innerHTML = appsettings.Tech_FreeHand_Building;
      else if (rightmenu == enumTech_Building_rightmenu.Edit_Point)
        buildMouseOverText.innerHTML = appsettings.Tech_Edit_Building;
      else if (rightmenu == enumTech_Building_rightmenu.Rotate_Space)
        buildMouseOverText.innerHTML = appsettings.Tech_Rotate_Building;
      else if (rightmenu == enumTech_Building_rightmenu.Reflect_Space)
        buildMouseOverText.innerHTML = appsettings.Tech_Reflect_Building;
    }
  }

  mouseleaverightsidemenu(value) {
    console.log(value);
    const buildMouseOverText = document.getElementById("buildingmouseovertext");
    if (buildMouseOverText != null) buildMouseOverText.innerHTML = " ";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
