<app-asset-header></app-asset-header>
<mat-card class="home-mat-card" (click)="CloseRightNav()">
<div class="mat-elevation-z0" style="margin-top:0px;" id="divassetdetails">
 
<div  class="landing-title-bar">
   <!-- <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutGap="5px" fxLayoutWrap >
      <div fxFlex="15" fxFlex.md="20" class="blocks" style="padding-top:5px;"> 
        <span class="clsTitle">Total Assets : </span>
        <span class="clsTitle1" id="residentcount">{{dataSourcecount}}</span>   
    </div>    
    <div fxFlex="30" fxFlex.xs="20">
          <mat-form-field appearance="outline" floatLabel="auto">
              <input style="height:20px;" matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchAsset($event.target.value)" autocomplete="off" placeholder="Search">
              <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>    
      </div>
      <div fxLayout="column" fxLayoutGap="0px" fxLayout.gt-sm="row" fxLayoutAlign="space-around center" class="assetcontent" fxLayoutWrap>
            <div class="blocks">  
                 <button id="downloadAssetbtn" color="primary" class="clsassetaddbutton" mat-raised-button (click)="downloadAssetdata()">Download</button>                          
                 <button  id="btnaddasset" color="primary" class="clsassetaddbutton" (click)="addAssetdata()" mat-raised-button>Add</button>                 
            </div>
      </div> 
   </div>       -->
   <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutGap="5px" fxLayoutWrap >
    <div fxFlex="15" fxFlex.md="20" class="blocks" style="padding-top:5px;"> 
        <span class="clsTitle">Total Asset : </span>
        <span class="clsTitle1" id="staffcount">{{dataSourcecount}}</span>   
     </div>      
     <div fxFlex="30" fxFlex.xs="20"> 
        <mat-form-field appearance="outline" floatLabel="auto">
            <input style="height:20px;" matInput class="form-control" type="text" name="search" maxlength="30" (input)="searchAsset($event.target.value)" autocomplete="off" placeholder="Search">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
     </div>
     <div fxFlex="55" class="blocks" fxLayout.md="row" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutWrap>                            
        <button id="downloadAssetbtn" color="primary" class="clsassetaddbutton" mat-raised-button (click)="downloadAssetdata()">Download</button>                          
        <button  id="btnaddasset" color="primary" class="clsassetaddbutton" (click)="addAssetdata()" mat-raised-button>Add</button>  
     </div>
  </div>
</div> 

<perfect-scrollbar class="scrollbar">
<div *ngIf="dataSourcecount; else noRecord">
  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear=true>    
    
      <!-- Weight Column -->
      <ng-container matColumnDef="TagId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag ID </th>
        <td mat-cell *matCellDef="let element"> {{element.TagId}} </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="AssetName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Asset Name </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.AssetName.length < 30">{{element.AssetName}}</span>
          <span *ngIf="element.AssetName.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.AssetName}} #tooltip="matTooltip" matTooltipPosition="above">          
            {{element.AssetName.slice(0,20) + ".."}}
          </span>
            </td>
      </ng-container>
           
       <!-- Symbol Column -->
      <ng-container matColumnDef="AssetNameAlias">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Asset Name Alias </th>
        <td mat-cell *matCellDef="let element">           
          <span *ngIf="element.AssetNameAlias.length < 30">{{element.AssetNameAlias}}</span>
          <span *ngIf="element.AssetNameAlias.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.AssetNameAlias}} #tooltip="matTooltip" matTooltipPosition="above">          
            {{element.AssetNameAlias.slice(0,20) + ".."}}
          </span>
        </td>
      </ng-container>

       <!-- Symbol Column -->
      <ng-container matColumnDef="AssetType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Asset Type </th>  
        <!-- <ng-container *matCellDef="let element">  -->
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.AssetType.length < 30">{{element.AssetType}}</span>
          <span *ngIf="element.AssetType.length >= 30" matTooltipClass="devicetooltip" matTooltip={{element.AssetType}} #tooltip="matTooltip" matTooltipPosition="above">          
            {{element.AssetType.slice(0,20) + ".."}}
          </span>
            </td>           
        <!-- </ng-container> -->
     </ng-container>
      
      <!-- Symbol Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef style="width:50px;"> Edit </th>
        <ng-container *matCellDef="let element">      
        <td mat-cell><img src="assets/images/Edit_Icon.png" (click)="editAssetdata(element)" style="cursor:pointer;"></td> 
      </ng-container>
      </ng-container>
    
     <!-- Symbol Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef  style="width:30px;"> Delete </th>
        <ng-container *matCellDef="let element">    
        <td mat-cell> <img src="assets/images/Release-Delete-Icon.png" (click)="deleteAssetdata(element)" style="margin-left:1em;cursor:pointer;"> </td>       
      </ng-container></ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
</perfect-scrollbar>
<ng-template #noRecord>  
  <mat-card>
      <h3 *ngIf="!assetloading" style="text-align: center;"> No Record found </h3> 
      <div fxLayout="row" fxLayoutAlign="space-around center" style="height:100%; margin-top:10%">       
          <div class="loadspinner" *ngIf="assetloading">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
          </div>
      </div>
  </mat-card>
</ng-template>

<mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
</div>
</mat-card>