
<div id="canceldownload">
  <div class="alert-title">
    <h2 class="alertHeader" style="margin: 0 0 10px 11px;">Notifications
    <span><img class="clscloseimage" src="assets/images/close.png" (click)="onCloseAlert()"></span></h2>
    <mat-divider ></mat-divider> 
  </div>
  <div class="clscontent">
    <span  class="clsMsg">Are you sure you want to Cancel Alert ?</span>
    <!-- show Delete/cancel button -->
    <mat-dialog-actions class="clsbuttoncontrol">    
      <button class="clsbutton_Enter" id="btn_YesCancel"  mat-raised-button (click)="onClickedYes()">Yes</button>
      <button type="button" id="btn_Cancel" class="clsbutton_Cancel" mat-raised-button (click)="onCloseAlert()">No</button>&nbsp;      
    </mat-dialog-actions>
  </div>
</div>
