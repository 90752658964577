import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { appsettings } from "../../app-settings";

import { Observable } from "rxjs";
import {
  ResidentForCreationResponse,
  ResidentByID,
  ResidentGroupForCreationResponse,
} from "./resident";
import { GetAlertsId, ResGroup } from "../../api";
import {
  ResidentHttpErrorHandler,
  HandleError,
} from "./http-error-handler.service";

@Injectable()
export class ResidentService {
  //Resident
  getResidentDataByIdUrl =
    appsettings.API_ENDPOINT + "residents/getResidentDataById";
  addResidentDataUrl = appsettings.API_ENDPOINT + "residents/addResidentData";
  updateResidentDataUrl =
    appsettings.API_ENDPOINT + "residents/updateResidentData";
  releaseResidentUrl = appsettings.API_ENDPOINT + "residents/releaseResident";

  //EMR
  addMedicalHistoryUrl =
    appsettings.API_ENDPOINT + "residents/addMedicalHistory";
  getMedicalHistoryUrl =
    appsettings.API_ENDPOINT + "residents/getMedicalHistory";
  updateMedicalHistoryUrl =
    appsettings.API_ENDPOINT + "residents/updateMedicalHistory";
  addEMRReportUrl = appsettings.API_ENDPOINT + "residents/addEMRReport";
  getEMRReportUrl = appsettings.API_ENDPOINT + "residents/getEMRReport";
  deleteEMRReportUrl = appsettings.API_ENDPOINT + "residents/deleteEMRReport";
  checkEMRFileUrl = appsettings.API_ENDPOINT + "residents/checkEMRFile";
  deleteEMRFileUrl = appsettings.API_ENDPOINT + "residents/deleteEMRFile";

  //Resident Group
  getGroupInfoByResidentUrl =
    appsettings.API_ENDPOINT + "residents/getGroupInfoByResident";
  addResidentGroupUrl = appsettings.API_ENDPOINT + "residents/addResidentGroup";
  updateResidentGroupUrl =
    appsettings.API_ENDPOINT + "residents/updateResidentGroup";
  deleteResidentGroupUrl =
    appsettings.API_ENDPOINT + "residents/deleteResidentGroup";

  getAlertsByTagIdUrl = appsettings.API_ENDPOINT + "maps/getAlertsByTagId";

  getRoamingUrl = appsettings.API_ENDPOINT + "roamings/getRoaming";
  addRoamingUrl = appsettings.API_ENDPOINT + "roamings/addRoaming";
  updateRoamingUrl = appsettings.API_ENDPOINT + "roamings/updateRoaming";
  getCampusAllDataUrl = appsettings.API_ENDPOINT+'roamings/getResidentRoaming';  

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: ResidentHttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError("ResidentService");
  }

  /** GET Staffs from the server */
  getResidentDataById(ResidentId): Observable<ResidentByID[]> {
    return this.http.post<ResidentByID[]>(
      this.getResidentDataByIdUrl,
      ResidentId
    );
  }

  addResidentData(
    residentForCreation
  ): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.addResidentDataUrl,
      residentForCreation
    );
  }

  updateResidentData(
    residentForCreation
  ): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.updateResidentDataUrl,
      residentForCreation
    );
  }

  releaseResident(
    residentForRelease
  ): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.releaseResidentUrl,
      residentForRelease
    );
  }

  addMedicalHistory(
    resMedHistoryForCreation
  ): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.addMedicalHistoryUrl,
      resMedHistoryForCreation
    );
  }

  getMedicalHistory(ResidentId): Observable<ResidentByID[]> {
    return this.http.post<ResidentByID[]>(
      this.getMedicalHistoryUrl,
      ResidentId
    );
  }

  updateMedicalHistory(
    resmedhistoryForUpdation
  ): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.updateMedicalHistoryUrl,
      resmedhistoryForUpdation
    );
  }

  addEMRReport(
    resAddEMRForCreation
  ): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.addEMRReportUrl,
      resAddEMRForCreation
    );
  }

  getEMRReport(ResidentId): Observable<ResidentByID[]> {
    return this.http.post<ResidentByID[]>(this.getEMRReportUrl, ResidentId);
  }

  deleteEMRReport(
    resdeleteEMRForCreation
  ): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.deleteEMRReportUrl,
      resdeleteEMRForCreation
    );
  }

  deleteEMRFile(selectFilename): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.deleteEMRFileUrl,
      selectFilename
    );
  }

  checkEMRFile(ResidentId): Observable<ResidentForCreationResponse[]> {
    return this.http.post<ResidentForCreationResponse[]>(
      this.checkEMRFileUrl,
      ResidentId
    );
  }

  addResidentGroup(
    residentGroupdata
  ): Observable<ResidentGroupForCreationResponse[]> {
    return this.http.post<ResidentGroupForCreationResponse[]>(
      this.addResidentGroupUrl,
      residentGroupdata
    );
  }

  updateResidentGroup(
    EditresidentGroup
  ): Observable<ResidentGroupForCreationResponse[]> {
    return this.http.post<ResidentGroupForCreationResponse[]>(
      this.updateResidentGroupUrl,
      EditresidentGroup
    );
  }

  deleteResidentGroup(groupid): Observable<ResidentGroupForCreationResponse[]> {
    return this.http.post<ResidentGroupForCreationResponse[]>(
      this.deleteResidentGroupUrl,
      groupid
    );
  }

  getGroupInfoByResident(selectedGroupId): Observable<ResGroup[]> {
    return this.http.post<ResGroup[]>(
      this.getGroupInfoByResidentUrl,
      selectedGroupId
    );
  }

  getAlertsByTagId(selectedTagId): Observable<GetAlertsId[]> {
    return this.http.post<GetAlertsId[]>(
      this.getAlertsByTagIdUrl,
      selectedTagId
    );
  }

  getRoaming(roamingdata): Observable<any[]> {
    return this.http.post<any[]>(this.getRoamingUrl, roamingdata);
  }

  addRoaming(addroamdata): Observable<any[]> {
    return this.http.post<any[]>(this.addRoamingUrl, addroamdata);
  }

  updateRoaming(editroamdata): Observable<any[]> {
    return this.http.post<any[]>(this.updateRoamingUrl, editroamdata);
  }
  
  getCampusAllData (siteId): Observable<any[]> {	
    return this.http.post<any[]>(this.getCampusAllDataUrl,siteId)    
  }
}
