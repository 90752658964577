import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import {
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { RollbarService } from "../../../../rollbar";
import * as Rollbar from "rollbar";
import { SessionCheck, generateAlertMessage } from "../../../../app-general";
import { Router } from "@angular/router";
import * as XLSX from "xlsx";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import {
  saveStateSymbolsData,
  saveBoardInfoData,
  saveBoardSetupData,
} from "../../technologyoverlay";

type AOA = any[][];

@Component({
  selector: "app-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./upload-dialog.component.scss"],
})
export class UploadDialogComponent implements OnInit, OnDestroy {
  message = "";
  Type = "";
  stateSymbolsData: AOA = [
    [1, 2],
    [3, 4],
  ];
  boardInfoData: AOA = [
    [1, 2],
    [3, 4],
  ];
  boardSetupData: AOA = [
    [1, 2],
    [3, 4],
  ];
  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  //fileName: string = 'NurseCallIntegration.xlsx';
  sheetNames = ["State Symbols", "Board Info", "Board Setup"];
  isInArray = false;
  uploadloading = false;
  uploadedFileName = "";
  public UploadConfigurationForm: UntypedFormGroup;
  Uploadfilesave = true;
  btnvalue;
  authkey;
  private _onDestroy: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private technologyoverlayService: TechnologyoverlayService,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    try {
      this.btnvalue = "Choose File";
      this.uploadedFileName = "";
    } catch (err) {
      throw new Error(
        "upload-dialog.component.ts" + " - constructor() - " + err.message
      );
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    this.UploadConfigurationForm = new UntypedFormGroup({
      uploadConfig: new UntypedFormControl(""),
    });
  }

  @ViewChild("clkimageInput") clkimageInput: any;
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");

    this.uploadedFileName = target.files[0].name;
    if (this.Uploadfilesave == true) {
      if (this.uploadedFileName != null) {
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

          if (
            this.validateSheetName(
              wb.SheetNames[0],
              wb.SheetNames[1],
              wb.SheetNames[2]
            ) == true
          ) {
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            this.stateSymbolsData = <AOA>(
              XLSX.utils.sheet_to_json(ws, { header: 1 })
            );

            const wsname1: string = wb.SheetNames[1];
            const ws1: XLSX.WorkSheet = wb.Sheets[wsname1];

            this.boardInfoData = <AOA>(
              XLSX.utils.sheet_to_json(ws1, { header: 1 })
            );

            const wsname2: string = wb.SheetNames[2];
            const ws2: XLSX.WorkSheet = wb.Sheets[wsname2];

            this.boardSetupData = <AOA>(
              XLSX.utils.sheet_to_json(ws2, { header: 1 })
            );
            this.btnvalue = "Upload";
          } else {
            generateAlertMessage(this.dialog, "Uploaded file is incorrect.");
            this.Uploadfilesave = false;
            this.clkimageInput.nativeElement.value = "";
            this.uploadedFileName = "";
            this.btnvalue = "Choose File";
            //this.thisDialogRef.close();
          }
        };
        reader.readAsBinaryString(target.files[0]);
      }
    }
  }

  saveUploadedConfigData() {
    if (
      this.stateSymbolsData.length > 0 &&
      this.boardInfoData.length > 0 &&
      this.boardSetupData.length > 0
    ) {
      this.uploadloading = true;
      this.Uploadfilesave = false;
      this.boardSetupData.sort(this.sortFunction);

      const saveStateSymbols: saveStateSymbolsData = {
        authKey: this.authkey,
        jsonData: JSON.stringify(this.stateSymbolsData),
      };
      const saveBoardInfo: saveBoardInfoData = {
        authKey: this.authkey,
        jsonData: JSON.stringify(this.boardInfoData),
        fileName: this.uploadedFileName,
      };
      const saveBoardSetup: saveBoardSetupData = {
        authKey: this.authkey,
        jsonData: JSON.stringify(this.boardSetupData),
      };

      this.technologyoverlayService
        .saveStateSymbolsData(saveStateSymbols)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((result: any[]) => {
          console.log(result);
          this.technologyoverlayService
            .saveBoardInfoData(saveBoardInfo)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((res: any[]) => {
              console.log(res);
              this.technologyoverlayService
                .saveBoardSetupData(saveBoardSetup)
                .pipe(takeUntil(this._onDestroy))
                .subscribe((res: any[]) => {
                  console.log(res);
                  this.uploadloading = false;
                  this.thisDialogRef.close();
                });
            });
        });
    }
  }

  sortFunction(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else {
      return a[0] < b[0] ? -1 : 1;
    }
  }

  onUploadClicked(event) {
    if (this.uploadedFileName != null) {
      try {
        const clkFileUpload = document.getElementById("clkfileupload");
        if (!SessionCheck(this.router)) {
          this.thisDialogRef.close("Cancel");
          return;
        }
        if (event.target.textContent != "Upload") {
          this.Uploadfilesave = true;
          if(clkFileUpload != null)
            clkFileUpload.click();
        } else {
          this.stateSymbolsData = this.stateSymbolsData.filter(
            (row) => row.join("") != ""
          );
          this.boardInfoData = this.boardInfoData.filter(
            (row) => row.join("") != ""
          );
          this.boardSetupData = this.boardSetupData.filter(
            (row) => row.join("") != ""
          );

          this.validateExcelContent();
        }
      } catch (err) {
        throw new Error(
          "upload-dialog.component.ts" + " - onUploadClicked() - " + err.message
        );
      }
    } else {
      generateAlertMessage(this.dialog, "Please Choose the file to be upload");
      this.thisDialogRef.close();
    }
  }

  onCloseClicked = () => {
    try {
      this.thisDialogRef.close("Cancel");
      if (!SessionCheck(this.router)) {
        return;
      }
    } catch (err) {
      throw new Error(
        "upload-dialog.component.ts" + " - onCloseClicked() - " + err.message
      );
    }
  };
  ngOnDestroy() { console.log('Service destroy');}
  validateSheetName(sheetName1, sheetName2, sheetName3) {
    let res = true;
    this.isInArray = this.sheetNames.includes(sheetName1);
    if (this.isInArray) {
      this.isInArray = this.sheetNames.includes(sheetName2);
      if (this.isInArray) {
        this.isInArray = this.sheetNames.includes(sheetName3);
        if (!this.isInArray) {
          res = false;
        }
      } else {
        res = false;
      }
    } else {
      res = false;
    }
    return res;
  }

  validateExcelContent() {
    try {
      if (
        this.boardInfoData.length > 1 &&
        this.boardSetupData.length > 1 &&
        this.stateSymbolsData.length > 1
      ) {
        let isError = false;
        const nameList = [] as any[];
        const ipaddressList = [] as any[];
        const symbolList = [] as any[];
        const assignmentList = [] as any[];
        const boardInfoDetails = this.boardInfoData;
        const boardSetupDetails = this.boardSetupData;
        const stateSymbolsDetails = this.stateSymbolsData;

        if (stateSymbolsDetails != null && stateSymbolsDetails.length > 1) {
          if (stateSymbolsDetails[0].length != 2) {
            isError = true;
            generateAlertMessage(
              this.dialog,
              "State symbols sheet have invalid data"
            );
            this.Uploadfilesave = false;
            this.clkimageInput.nativeElement.value = "";
            this.uploadedFileName = "";
            this.btnvalue = "Choose File";
            return;
          }
          if (stateSymbolsDetails[0].length == 2) {
            const stateHeaderList = ["State Symbol", "State Assignment"];
            for (let idx = 0; idx < stateSymbolsDetails[0].length; idx++) {
              if (stateSymbolsDetails[0][idx] != stateHeaderList[idx]) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "State symbols sheet have invalid header"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }
            if (isError == true) return;
          }

          for (let i = 1; i < stateSymbolsDetails.length; i++) {
            if (stateSymbolsDetails[i][0] === undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "State Symbol should not be empty in state symbols"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            }
            if (stateSymbolsDetails[i][1] === undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "State Assignment should not be empty in state symbols"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            }
            if (i == 1) {
              symbolList.push(stateSymbolsDetails[i][0]);
              assignmentList.push(stateSymbolsDetails[i][1]);
            } else {
              const symbolExist = symbolList.includes(stateSymbolsDetails[i][0]);
              if (symbolExist) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "State Symbol should be unique in State symbols"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              } else {
                symbolList.push(stateSymbolsDetails[i][0]);
              }

              const assignmentExist = assignmentList.includes(
                stateSymbolsDetails[i][1]
              );
              if (assignmentExist) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "State Assignment should be unique in State symbols"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              } else {
                assignmentList.push(stateSymbolsDetails[i][1]);
              }
            }
          }
          if (isError == true) return;
        }

        if (boardInfoDetails != null && boardInfoDetails.length > 1) {
          if (boardInfoDetails[0].length != 4) {
            isError = true;
            generateAlertMessage(
              this.dialog,
              "Board Info sheet have invalid data"
            );
            this.Uploadfilesave = false;
            this.clkimageInput.nativeElement.value = "";
            this.uploadedFileName = "";
            this.btnvalue = "Choose File";
            return;
          }

          if (boardInfoDetails[0].length == 4) {
            const boardinfoHeaderList = [
              "Board Name",
              "IP Address",
              "UDP Port",
              "Points",
            ];
            for (let idx = 0; idx < boardInfoDetails[0].length; idx++) {
              if (boardInfoDetails[0][idx] != boardinfoHeaderList[idx]) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Board Info sheet have invalid header"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }
            if (isError == true) return;
          }

          for (let i = 1; i < boardInfoDetails.length; i++) {
            if (boardInfoDetails[i][0] == undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "Board Name should not be empty in Board Info"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            }

            if (boardInfoDetails[i][1] == undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "IP Address should not be empty in Board Info"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            }

            if (boardInfoDetails[i][2] == undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "UDP Port should not be empty in Board Info"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            } else {
              if (boardInfoDetails[i][2]) {
                if (!Number.isInteger(parseInt(boardInfoDetails[i][2]))) {
                  isError = true;
                  generateAlertMessage(
                    this.dialog,
                    "UDP Port should be integer in Board Info"
                  );
                  this.Uploadfilesave = false;
                  this.clkimageInput.nativeElement.value = "";
                  this.uploadedFileName = "";
                  this.btnvalue = "Choose File";
                  break;
                }
              }
            }
            if (boardInfoDetails[i][3] == undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "Points should not be empty in Board Info"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            } else {
              if (boardInfoDetails[i][3]) {
                if (!Number.isInteger(parseInt(boardInfoDetails[i][3]))) {
                  isError = true;
                  generateAlertMessage(
                    this.dialog,
                    "Points should be integer in Board Info"
                  );
                  this.Uploadfilesave = false;
                  this.clkimageInput.nativeElement.value = "";
                  this.uploadedFileName = "";
                  this.btnvalue = "Choose File";
                  break;
                }
              }
            }
            if (boardInfoDetails[i][1] != undefined) {
              const ipformat =
                /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
              if (!boardInfoDetails[i][1].toString().match(ipformat)) {
                isError = true;
                generateAlertMessage(this.dialog, "IP Address is not valid");
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }

            if (i == 1) {
              nameList.push(boardInfoDetails[i][0]);
              ipaddressList.push(boardInfoDetails[i][1]);
            } else {
              this.isInArray = nameList.includes(boardInfoDetails[i][0]);
              if (this.isInArray) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Board Name should be unique in Board Info"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              } else {
                nameList.push(boardInfoDetails[i][0]);
              }

              const isdup = ipaddressList.includes(boardInfoDetails[i][1]);
              if (isdup) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "IP Address should be unique in Board Info"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              } else {
                ipaddressList.push(boardInfoDetails[i][1]);
              }
            }
          }
          if (isError == true) return;
        }

        if (boardSetupDetails != null && boardSetupDetails.length > 1) {
          if (boardSetupDetails[0].length != 9) {
            isError = true;
            generateAlertMessage(
              this.dialog,
              "Board setup sheet have invalid data"
            );
            this.Uploadfilesave = false;
            this.clkimageInput.nativeElement.value = "";
            this.uploadedFileName = "";
            this.btnvalue = "Choose File";
            return;
          }

          if (boardSetupDetails[0].length == 9) {
            const boardsetupHeaderList = [
              "Board Name",
              "Relay",
              "BedA",
              "BedB",
              "Bath",
              "Smoke Alarm",
              "Staff Emergency",
              "HiPriority",
              "Point Name",
            ];
            for (let idx = 0; idx < boardSetupDetails[0].length; idx++) {
              if (boardSetupDetails[0][idx] != boardsetupHeaderList[idx]) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Board setup sheet have invalid header"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }
            if (isError == true) return;
          }

          for (let i = 1; i < boardSetupDetails.length; i++) {
            if (boardSetupDetails[i][0] === undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "Board Name should not be empty in Board setup"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            }

            if (boardSetupDetails[i][1] === undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "Relay should not be empty in Board setup"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            }
            if(boardSetupDetails[i][2] === undefined || boardSetupDetails[i][3] === undefined || boardSetupDetails[i][4] === undefined || boardSetupDetails[i][5] === undefined || boardSetupDetails[i][6] === undefined || boardSetupDetails[i][7] === undefined)
            {
                isError = true;
                generateAlertMessage(this.dialog,"Alarm Types should not be empty in Board setup");
                this.Uploadfilesave = false; 
                this.clkimageInput.nativeElement.value = "";    
                this.uploadedFileName = "";   
                this.btnvalue = "Choose File";   
                break; 
            }
            if (boardSetupDetails[i][2] != undefined) {
              if (
                boardSetupDetails[i][2].toLowerCase() != "yes" &&
                boardSetupDetails[i][2].toLowerCase() != "no"
              ) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Alarm Types have Invalid data in Board setup"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }
            if (boardSetupDetails[i][3] != undefined) {
              if (
                boardSetupDetails[i][3].toLowerCase() != "yes" &&
                boardSetupDetails[i][3].toLowerCase() != "no"
              ) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Alarm Types have Invalid data in Board setup"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }
            if (boardSetupDetails[i][4] != undefined) {
              if (
                boardSetupDetails[i][4].toLowerCase() != "yes" &&
                boardSetupDetails[i][4].toLowerCase() != "no"
              ) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Alarm Types have Invalid data in Board setup"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }
            if (boardSetupDetails[i][5] != undefined) {
              if (
                boardSetupDetails[i][5].toLowerCase() != "yes" &&
                boardSetupDetails[i][5].toLowerCase() != "no"
              ) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Alarm Types have Invalid data in Board setup"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }
            if (boardSetupDetails[i][6] != undefined) {
              if (
                boardSetupDetails[i][6].toLowerCase() != "yes" &&
                boardSetupDetails[i][6].toLowerCase() != "no"
              ) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Alarm Types have Invalid data in Board setup"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }
            if (boardSetupDetails[i][7] != undefined) {
              if (
                boardSetupDetails[i][7].toLowerCase() != "yes" &&
                boardSetupDetails[i][7].toLowerCase() != "no"
              ) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Alarm Types have Invalid data in Board setup"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                break;
              }
            }

            if (boardSetupDetails[i][8] === undefined) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "Point Name should not be empty in Board setup"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              break;
            }
          }
          if (isError == true) return;
        }

        for (let Inc = 1; Inc < boardInfoDetails.length; Inc++) {
          const boardList = [] as any[];
          const relayList = [] as any[];
          const pointNameList = [] as any[];

          for (let j = 1; j < boardSetupDetails.length; j++) {
            if (boardInfoDetails[Inc][0] == boardSetupDetails[j][0]) {
              boardList.push(boardSetupDetails[j][0]);
              relayList.push(boardSetupDetails[j][1]);
              pointNameList.push(boardSetupDetails[j][8]);
            }
          }
          if (boardList.length > 0) {
            if (boardList.length > boardInfoDetails[Inc][3]) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "Relay count should be less than or equal to Board points in Board setup"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              return;
            }
          }
          if (relayList.length > 0) {
            if (!relayList.some((i) => !Number.isInteger(i))) {
              const findDuplicates = relayList.filter(
                (item, index) => relayList.indexOf(item) != index
              );
              if (findDuplicates.length > 0) {
                isError = true;
                generateAlertMessage(
                  this.dialog,
                  "Relay should be unique for a board in Board setup"
                );
                this.Uploadfilesave = false;
                this.clkimageInput.nativeElement.value = "";
                this.uploadedFileName = "";
                this.btnvalue = "Choose File";
                return;
              }
            } else {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "Relay should be integer in Board setup"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              return;
            }
          }
          if (pointNameList.length > 0) {
            const IsDuplicate = pointNameList.filter(
              (item, index) => pointNameList.indexOf(item) != index
            );
            if (IsDuplicate.length > 0) {
              isError = true;
              generateAlertMessage(
                this.dialog,
                "Point Name should be unique for a board in Board setup"
              );
              this.Uploadfilesave = false;
              this.clkimageInput.nativeElement.value = "";
              this.uploadedFileName = "";
              this.btnvalue = "Choose File";
              return;
            }
          }
        }

        if (isError == false) {
          this.saveUploadedConfigData();
        }
      } else {
        generateAlertMessage(this.dialog, "Please upload the valid file");
        this.Uploadfilesave = false;
        this.clkimageInput.nativeElement.value = "";
        this.uploadedFileName = "";
        this.btnvalue = "Choose File";
        return;
      }
    } catch (err) {
      throw new Error(
        "upload-dialog.component.ts" + " - onUploadClicked() - " + err.message
      );
    }
  }

  getIndexOfK(arr, k) {
    for (let i = 0; i < arr.length; i++) {
      const index = arr[i].indexOf(k);
      if (index > -1) {
        return i;
      }
    }
  }
}
