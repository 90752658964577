import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Subject } from "rxjs";
import { AddNewUnitForm, getSpaceCategory } from "../../../technologyoverlay";
import { TechnologyoverlayService } from "../../../technologyoverlay.service";
import { RollbarService } from "../../../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../../../app-general";
import {
  enumEnableDisable,appsettings,
} from "../../../../../app-settings";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-add-unit",
  templateUrl: "./add-unit.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./add-unit.component.scss"],
})
export class AddUnitComponent implements OnInit, OnDestroy {
  public addUnitForm: UntypedFormGroup;
  public watcher: Observable<boolean>;
  columns = 2;
  addunitbool = true;
  Space_details;
  private _onDestroy: Subject<void> = new Subject<void>();
  selectCategoryId;
  UnitData;
  space_polyPoints;
  checkResOffLimits = false;
  checkSpaceCategory = 0;
  authkey;
  _roomenablehourmin = false;
  _roomenableroomallday = false;
  checkresroominfo = false;
  public Curfew_data = [] as any[];
  data_RstartTime;
  data_RendTime;
  Breakpoints = Breakpoints;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<AddUnitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private technologyoverlayService: TechnologyoverlayService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public dialog: MatDialog,    
    private breakpointObserver: BreakpointObserver
  ) {
    this.UnitData = data.FloorData;
    this.space_polyPoints = data.Polypoints;
    this.getCurfew_data();
    this.breakpoint$.subscribe(() => this.breakpointChanged());    
  }
  
  getCurfew_data()
  {
    let _curfewdata={};
    const _curfew_data_arr = [] as any[];
    let hourtext = "";
    let _hour = "";
    let _min="";
    let min_inc=30;
    for(let hour = 0; hour <= 24; hour++) 
    {  
      if(hour < 12)
      {
        _hour = "0" + hour.toString();
        hourtext = " am";
      }
      else if(hour == 24)
      {
          _hour =  (hour-12).toString();
          hourtext = " am";
          min_inc=60;
      }   
      else if(hour > 12)
      {
        _hour = "0" + (hour-12).toString();
          hourtext = " pm";
      }    
      else if(hour == 12)
      {
          _hour = hour.toString();
          hourtext = " pm";
      }   
      
      _hour= _hour.slice(-2);
      for(let min = 0; min < 60; min=min+min_inc)
      {     
        _min = "0" + min.toString();  
        _min= _min.slice(-2);               
        _curfewdata = {Time:_hour + ":" + _min + hourtext, Value: hour + ":" + min }         
        _curfew_data_arr.push(_curfewdata);         
      }
      
    }   
        this.Curfew_data=_curfew_data_arr;     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium) ||
    this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 2;
    }            
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 1;
    }
  }

  ngOnInit() {
    try {
      this.authkey = localStorage.getItem("Authkey") ?? "";
      this.addUnitForm = new UntypedFormGroup({
        campusname: new UntypedFormControl(""),
        buildingname: new UntypedFormControl(""),
        zonename: new UntypedFormControl(""),
        campusID: new UntypedFormControl(""),
        buildID: new UntypedFormControl(""),
        floorID: new UntypedFormControl(""),
        unitname: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        category: new UntypedFormControl("", [Validators.required]),
        description: new UntypedFormControl("", [
          Validators.pattern(appsettings.Name_Pattern),
        ]),
        res_start: new UntypedFormControl(''),
        res_end: new UntypedFormControl(''), 
      });
      const formCampusName = this.addUnitForm.get("campusname");
      const formbuildingname = this.addUnitForm.get("buildingname");
      const formzonename = this.addUnitForm.get("zonename");
      if (formCampusName != null) formCampusName.disable();
      if (formbuildingname != null) formbuildingname.disable();
      if (formzonename != null) formzonename.disable();

      this.getSpaceCategory();
    } catch (err) {
      throw new Error(
        "add-unit.component.ts" + " - ondelteUnit() - " + err.message
      );
    }
  }

  getSpaceCategory() {
    try {
      const spaceitem: getSpaceCategory = {
        authKey: this.authkey,
        categoryId: 0,
      };
      this.technologyoverlayService
        .getSpaceCategory(spaceitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((spacedetails: any[]) => {
          this.Space_details = spacedetails;
        });
    } catch (err) {
      throw new Error(
        "add-unit.component.ts" + " - getSpaceCategory() - " + err.message
      );
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.addUnitForm.controls).forEach((field) => {
        // {1}
        const control = this.addUnitForm.get(field); // {2}
        const formUnitTagetId = document.getElementById(event.target.id);
        if (formUnitTagetId != null) {
          if (field == formUnitTagetId.getAttribute("formControlName")) {
            if (control != null) {
              control.setValue(changedValue);
              control.markAsTouched({ onlySelf: true }); // {3}
            }
          }
        }
      });
    } catch (err) {
      throw new Error(
        "add-unit.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  public onCloseCancel = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.addUnitForm.controls[controlName].hasError(errorName);
  };

  selectSpaceCategory(categoryId) {   
    this.selectCategoryId = categoryId;
  }

  newaddUnit(addUnitFormValue) {
    try {
      if (!SessionCheck(this.router)) {
        this.thisDialogRef.close("Cancel");
        return;
      }
      if (this.addUnitForm.valid) {        
         this.addSpaceWithRoom(addUnitFormValue);
      }
    } catch (err) {
      throw new Error(
        "add-unit.component.ts" + " - newaddUnit() - " + err.message
      );
    }
  }

  private addSpaceWithRoom = (addUnitFormValue) => {
    try {
      (document.activeElement as HTMLTextAreaElement).blur();
      
      let check_Room_AllDay;        
      let resstarttime, resendtime;        
      let res_Start_Hr = 0, res_Start_Min = 0;
      let res_End_Hr = 0, res_End_Min = 0;   
      
      if (this.addUnitForm.valid && this.addunitbool) {       
        this.addUnitEnableDisable(enumEnableDisable.Disable);
        this.addunitbool = false;
        let _Description = "";
        if (addUnitFormValue.description != "")
          _Description = addUnitFormValue.description
            .replace(/\s+/g, " ")
            .trim();
        else _Description = "";

        let resofflimits = 0;

        if (this.checkResOffLimits == true) resofflimits = 1;
        else resofflimits = 0;
        
        if(addUnitFormValue.res_start != undefined && addUnitFormValue.res_start != 0)
          {
            resstarttime = addUnitFormValue.res_start.split(":");
            res_Start_Hr = parseInt(resstarttime[0]);        
            res_Start_Min = resstarttime[1].split(" ");        
            res_Start_Min =  parseInt(res_Start_Min[0]);            
          }
          if(addUnitFormValue.res_end != undefined && addUnitFormValue.res_end != 0)
          {
            resendtime = addUnitFormValue.res_end.split(":");        
            res_End_Hr =  parseInt(resendtime[0]);
            res_End_Min = resendtime[1].split(" ");        
            res_End_Min =  parseInt(res_End_Min[0]);            
          }
              
          if(this.checkresroominfo == true)        
            check_Room_AllDay = 1;
          else
            check_Room_AllDay = 0;
          
          if(resofflimits == 1)  
          {
            if ((addUnitFormValue.res_start == "" || addUnitFormValue.res_start == undefined) && (addUnitFormValue.res_end == "" ||
            addUnitFormValue.res_end == undefined) && (this.checkresroominfo == false || this.checkresroominfo == undefined)) 
            {              
              generateAlertMessage(this.dialog,"Please select Resident Time info or All");
              this.addUnitEnableDisable(enumEnableDisable.Enable); 
              this.addunitbool = true; 
              return false;
            }

            if (addUnitFormValue.res_start != "" && addUnitFormValue.res_start != undefined) 
            {
              if (addUnitFormValue.res_end == "" || addUnitFormValue.res_end == undefined) 
              {                
                generateAlertMessage(this.dialog,"Please select Resident End time");
                this.addUnitEnableDisable(enumEnableDisable.Enable);   
                this.addunitbool = true;
                return false;
              }
            }

            if (addUnitFormValue.res_end != "" && addUnitFormValue.res_end != undefined) 
            {
              if (addUnitFormValue.res_start == "" || addUnitFormValue.res_start == undefined) 
              {                
                generateAlertMessage(this.dialog,"Please select Resident Start time");
                this.addUnitEnableDisable(enumEnableDisable.Enable);  
                this.addunitbool = true;
                return false;
              }
            }
          }
          
          if(resofflimits == 1 && check_Room_AllDay == 0 && res_Start_Hr >= 0 && res_End_Hr <= res_Start_Hr && res_End_Hr < 12)  
          {
            if((res_Start_Hr == res_End_Hr && res_Start_Min >= res_End_Min) || (res_Start_Hr > res_End_Hr && res_Start_Min <= res_End_Min))
            {
              generateAlertMessage(this.dialog,"Start time should be lesser than the End time");
              this.addUnitEnableDisable(enumEnableDisable.Enable);   
              this.addunitbool = true;
              return false;
            }
          }                    

        const unitdetails: AddNewUnitForm = {
          authKey: this.authkey,
          campusId: parseInt(addUnitFormValue.campusID),
          buildId: parseInt(addUnitFormValue.buildID),
          floorId: parseInt(addUnitFormValue.floorID),
          unitName: addUnitFormValue.unitname.replace(/\s+/g, " ").trim(),
          description: _Description, //addUnitFormValue.description.replace(/\s+/g, ' ').trim(),
          spaceCategoryId: parseInt(this.selectCategoryId),
          svgpoints: this.space_polyPoints.toString(),
          isIndoor: 1,
          accuracy: "",
          resOffLimits: resofflimits,
          spaceId:0,
          chkAllday : check_Room_AllDay,
          resStartHr : res_Start_Hr,
          resStartMi : res_Start_Min,
          resEndHr : res_End_Hr,
          resEndMi : res_End_Min,
          roomX:0,
          roomY:0, 
        };

        this.technologyoverlayService
          .addSpaceWithRoom(unitdetails)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((apiresponse: any[]) => {
            if (apiresponse["apiReturn"] == "Success") {
              generateAlertMessage(this.dialog, "Space added successfully.");
              this.thisDialogRef.close("Confirm");
            } else if (apiresponse["apiReturn"] == "Fail") {
              this.addunitbool = true;
              generateAlertMessage(this.dialog, "Space added failed.");
              this.thisDialogRef.close("Confirm");
            } else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            } else if (apiresponse["apiReturn"] == "unitName") {
              this.addunitbool = true;
              generateAlertMessage(this.dialog, "Please enter Space Name");              
              this.addUnitEnableDisable(enumEnableDisable.Enable);
              return;
            } else {
              this.addunitbool = true;
              generateAlertMessage(
                this.dialog,
                "Space name '" + apiresponse["UnitList"][0].UnitName + "' already exists."
              );              
              this.addUnitEnableDisable(enumEnableDisable.Enable);
              return;
            }
            
            this.addUnitEnableContainer("container_DeviceList");
            this.addUnitEnableContainer("container_SelectMove");
            this.addUnitEnableContainer("container_EditSpace");
            this.addUnitEnableContainer("container_AddDevice");
            this.addUnitEnableContainer("container_Duplicate");
            this.addUnitEnableContainer("container_InsertTemplate");
            this.addUnitEnableContainer("container_DeleteSpace");
           
            const saveBtn = document.getElementById("btnsaveclickmapview");
            if (saveBtn != null && saveBtn.innerHTML == "Done")
              saveBtn.innerHTML = "Save";
          });
      }
    } catch (err) {
      throw new Error(
        "add-unit.component.ts" + " - addSpaceWithRoom() - " + err.message
      );
    }
  };

  toggler_ResOffLimits(e) {  
    if (e.checked == true) {
      this.checkResOffLimits=true;  
      this._roomenableroomallday = true; 
      this.checkresroominfo = true;
      if(this.checkresroominfo == true)
        this._roomenablehourmin = false;     
      else
        this._roomenablehourmin = true;     
    }
    else
    {
      this.checkResOffLimits=false;   
      this._roomenableroomallday = false;
      this._roomenablehourmin = false;  
      this.data_RstartTime = 0;
      this.data_RendTime = 0;
      this.checkresroominfo = false;
    }  
    
  }
  
  toggleresdayinfoVisibility(event)
  {
    if (event.checked == true) {
      this._roomenablehourmin = false;     
      this.checkresroominfo = true;
      this.data_RstartTime = "";
      this.data_RendTime = "";
    }
    else{
      this._roomenablehourmin = true;            
      this.data_RstartTime = 0;
      this.data_RendTime = 0;
      this.checkresroominfo = false;
    }
  }

  addUnitEnableDisable(checkflag) {
    const elementcontent = document.getElementById("btnsaveunitaddclick");
    if (elementcontent != null && checkflag == enumEnableDisable.Enable)
      elementcontent.className = "clsaddunitbutton mat-raised-button";
    else if (elementcontent != null && checkflag == enumEnableDisable.Disable)
      elementcontent.className =
        "clsaddunitbutton mat-raised-button disablebtn";
  }

  addUnitEnableContainer(containerText) {
    const elementcontent = document.getElementById('"' + containerText + '"');
    if (elementcontent != null) elementcontent.className = "enablebtn";
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
