import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { enumUIFeatureView,enumUIAction,enumUIView,appsettings } from '../../app-settings';
import { Observable } from "rxjs";
import {
  getCampusById,
  getBuildingById,
  getFloorById,
  TechnologyForCreationResponse,
  getUnitById,leftmenu,
} from "./technologyoverlay";
import {
  TechnologyoverlayHttpErrorHandler,
  HandleError,
} from "./http-error-handler.service";
import { DeviceDetails } from "../../api";
import { getFeatureNeedtoShow} from '../../app-general';


@Injectable({
  providedIn: "root",
})
export class TechnologyoverlayService {
  public toggleTechMenuNav: EventEmitter<any> = new EventEmitter();
  getleftmenu() {

    const LEFTMENU: leftmenu[] = [];
    LEFTMENU.push( 
      {id:'1',routerLink: '../floorplans', src: 'assets/images/Technology Overlay-Floorplans.png',description:'Device Maps',subitem:'Add/Edit/Delete'},
      {id:'2',routerLink: '../templates', src: 'assets/images/Templates.png',description:'Templates',subitem:'Edit/Delete'},
      {id:'3',routerLink: '../device', src: 'Technology Overlay-Device Overview.png',description:'Device List',subitem:'View/Edit/'},
      {id:'4',routerLink: '../approximatelocating', src: 'assets/images/Notifications-Campus-Icon-Blue.png',description:'Approximate Locating',subitem:'Add/Edit/Delete'}
      );

      if (getFeatureNeedtoShow(enumUIFeatureView.Technology, enumUIAction.Hardwired_Configration_View) == enumUIView.Show) {
        if (localStorage.getItem("hardwired_configuration") =="1")
        {
          LEFTMENU.push(
              {id:'5',routerLink: '../hardwiredconfiguration', src: 'assets/images/Notifications-Campus-Icon-Blue.png',description:'Hardwired Configuration',subitem:'Add/Edit/Delete'}
            );
        }
        else if (localStorage.getItem("hardwired_configuration") =="2")   
        {
          LEFTMENU.push(
            {id:'6',routerLink: '../tektone', src: 'assets/images/Notifications-Campus-Icon-Blue.png',description:'TekTone',subitem:'Add/Edit/Delete'});
        }
      }
      return LEFTMENU;
  }
  public reloadiniltc_result;
  private URL = appsettings.URLinfo + "reloadiniltc";
  private sys_URL = appsettings.URLinfo + "systemresetltc";
  private PullCord_URL = appsettings.URLinfo + "reloadPullCordConfig";

  //Faclilty Api
  updateFacilityInfoUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/updateFacilityInfo";

  //Campus Api
  addCampusUrl = appsettings.API_ENDPOINT + "technologyoverlays/addCampus";
  updateCampusUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/updateCampus";
  deleteCampusUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/deleteCampus";
  getCampusByIdUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getCampusById";

  //Building Api
  addBuildingUrl = appsettings.API_ENDPOINT + "technologyoverlays/addBuilding";
  updateBuildingUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/updateBuilding";
  deleteBuildingUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/deleteBuilding";
  getBuildingByIdUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getBuildingById";
  updateBuildingPolyPointsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/updateBuildingPolyPoints";

  //Floor Api
  addFloorUrl = appsettings.API_ENDPOINT + "technologyoverlays/addFloor";
  //updateFloorUrl = appsettings.API_ENDPOINT + "technologyoverlays/updateFloor";
  //deleteFloorUrl = appsettings.API_ENDPOINT + "technologyoverlays/deleteFloor";
  //getFloorByIdUrl =
    //appsettings.API_ENDPOINT + "technologyoverlays/getFloorById";
  addDeviceDataUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/addDeviceData";
  editDeviceDataUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/editDeviceData";
  updatePolylineFloorUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/updateSpacePolyPoints";
    
  //Zone Api
  addZoneUrl = appsettings.API_ENDPOINT + "technologyoverlays/addZone";
  updateZoneUrl = appsettings.API_ENDPOINT + "technologyoverlays/updateZone";
  deleteZoneUrl = appsettings.API_ENDPOINT + "technologyoverlays/deleteZone";
  getZoneByIdUrl = appsettings.API_ENDPOINT + "technologyoverlays/getZoneById";
  
  //Sub-Zone Api
  addSubZoneUrl = appsettings.API_ENDPOINT + "technologyoverlays/addSubZone";
  getSubZoneUrl = appsettings.API_ENDPOINT + "technologyoverlays/getSubZone";
  getSubZoneByIdUrl = appsettings.API_ENDPOINT + "technologyoverlays/getSubZoneById";
  deleteSubZoneUrl = appsettings.API_ENDPOINT + "technologyoverlays/deleteSubZone";

  //Unit Api
  updateunitUrl = appsettings.API_ENDPOINT + "technologyoverlays/updateUnit";
  deleteunitUrl = appsettings.API_ENDPOINT + "technologyoverlays/deleteUnit";
  getUnitByIdUrl = appsettings.API_ENDPOINT + "technologyoverlays/getUnitById";
  addSpaceWithRoomUrl = appsettings.API_ENDPOINT + "technologyoverlays/addRoom";

  //Device Api
  getEmitterDetailsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getINIDeviceList";
  getDeviceDetailsbyIdUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getDeviceDetailsById";
  getHRexdetailsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getRouterDetails";
  checkDeviceInAlertUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/checkDeviceInAlert";
  checkLocationInAlertUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/checkLocationInAlert";
  getRexdevicedetailsUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getRepeaterDetails";
  getTechViewUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getAllLocationCount";
  getTechCountUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getLocationCountById";

  //Master
  getSpaceCategoryUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getSpaceCategory";
  getResidentRoomCategoryUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getResidentRoomCategory";

  //Templates
  getTemplatesUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getAllTemplates";
  getTemplatesInfoUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getTemplateInfoById";
  saveAsTemplateForFloorUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/saveAsTemplateForFloor";
  saveAsTemplateForSpaceUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/saveAsTemplateForSpace";
  loadTemplateForFloorUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getTemplateForFloor";
  loadTemplatesForDeviceUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getTemplateForDevice";
  deleteTemplateSpaceUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/deleteSpaceTemplateById";
  deleteTemplateUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/deleteTemplateById";
  addTemplateZoneUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/addTemplateZone";
  deleteTemplateDeviceIdUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/deleteTemplateByDeviceId";
  addTemplateSpaceUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/addTemplateSpace";
  updateTemplateDeviceInfoUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/updateTemplateByDeviceId";
  updateTemplateInfoUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/updateTemplateById";
  updateTemplateSpaceInfoUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/updateTemplateBySpaceId";

  //Duplicate
  duplicateBuildingUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/duplicateBuilding";
  duplicateZoneUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/duplicateZone";
  duplicateSpaceUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/duplicateSpace";

  //Nurse Call Api
  saveBoardInfoDataUrl =
    appsettings.API_ENDPOINT + "nursecalls/saveBoardInfoData";
  saveBoardSetupDataUrl =
    appsettings.API_ENDPOINT + "nursecalls/saveBoardSetupData";
  saveStateSymbolsDataUrl =
    appsettings.API_ENDPOINT + "nursecalls/saveStateSymbolsData";
  getBoardInfoDataUrl =
    appsettings.API_ENDPOINT + "nursecalls/getBoardInfoData";
  getBoardSetupDetailUrl =
    appsettings.API_ENDPOINT + "nursecalls/getBoardSetupDetail";
  getStateSymbolsDataUrl =
    appsettings.API_ENDPOINT + "nursecalls/getStateSymbolsData";
  saveConfigurationSettingsUrl =
    appsettings.API_ENDPOINT + "nursecalls/saveConfigurationSettings";
  saveConfigurationBoardUrl =
    appsettings.API_ENDPOINT + "nursecalls/saveConfigurationBoard";
  deleteConfigurationSettingsUrl =
    appsettings.API_ENDPOINT + "nursecalls/deleteConfigurationSettings";
  getConfigurationSettingsUrl =
    appsettings.API_ENDPOINT + "nursecalls/getConfigurationSettings";
  getSpaceDetailsWithBoardSetupDataUrl =
    appsettings.API_ENDPOINT + "nursecalls/getSpaceDetailsWithBoardSetupData";
  getAlertForConfiguredBoardUrl =
    appsettings.API_ENDPOINT + "nursecalls/getAlertForConfiguredBoard";
  getAlertForConfiguredSpaceUrl =
    appsettings.API_ENDPOINT + "nursecalls/getAlertForConfiguredSpace";

  getCampusdetailsByIdUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/getCampusDetailsById";
  getRoamingDataByIdUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/checkRoamingDataBySpaceId";
  getOutdoorSpaceIdUrl = appsettings.API_ENDPOINT + "technologyoverlays/getOutdoorSpaceId";
  saveReOrderDataUrl = appsettings.API_ENDPOINT + "technologyoverlays/saveReorderedDropData"; 
  
  setAlarmClearModeUrl = appsettings.API_ENDPOINT + 'nursecalls/setAlarmClearMode';
  getAlarmClearModeUrl = appsettings.API_ENDPOINT + 'nursecalls/getAlarmClearMode';

  saveEventDataTektoneUrl = appsettings.API_ENDPOINT + 'nursecalls/saveEventData';
  saveBoardInfoDataTektoneUrl = appsettings.API_ENDPOINT + 'nursecalls/saveBoardInfoDataTekTone';
  saveBoardSetupDataTektoneUrl = appsettings.API_ENDPOINT + 'nursecalls/saveBoardSetupDataTekTone';
  getConfigurationSettingsTekToneUrl = appsettings.API_ENDPOINT + 'nursecalls/getConfigurationSettingsTekTone';
  getSpaceDetailsWithBoardSetupDataTekToneUrl = appsettings.API_ENDPOINT + 'nursecalls/getSpaceDetailsWithBoardSetupDataTekTone';
  saveBoardAssociationTekToneUrl = appsettings.API_ENDPOINT + 'nursecalls/SaveAssociation';  
  deleteConfigurationSettingsTekToneUrl = appsettings.API_ENDPOINT + 'nursecalls/deleteConfigurationSettingsTekTone';
  getBoardInfoDataTekToneUrl = appsettings.API_ENDPOINT + 'nursecalls/getBoardInfoDataTekTone'; 
  getBoardSetupDetailTekToneUrl = appsettings.API_ENDPOINT + 'nursecalls/getBoardSetupDetailTekTone';  
  getEventDataTekToneUrl = appsettings.API_ENDPOINT + 'nursecalls/getEventDataTekTone';
  //Other
  checkHREXfoundUrl =
    appsettings.API_ENDPOINT + "technologyoverlays/checkRouter";

  private handleError: HandleError;
  public reloadini_result;
  public sysreloadini_result;
  public pullcordreloadini_result;

  constructor(
    private http: HttpClient,
    httpErrorHandler: TechnologyoverlayHttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      "TechnologyoverlayService"
    );
  }

  updateFacilityInfo(
    facilityForUpdate
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updateFacilityInfoUrl,
      facilityForUpdate
    );
  }

  /** Campus Info */
  addCampus(campusforadd): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.addCampusUrl,
      campusforadd
    );
  }

  updateCampus(campusForUpdate): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updateCampusUrl,
      campusForUpdate
    );
  }

  deleteCampus(campusfordelete): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.deleteCampusUrl,
      campusfordelete
    );
  }

  getCampusById(CampusId): Observable<getCampusById[]> {
    return this.http.post<getCampusById[]>(this.getCampusByIdUrl, CampusId);
  }

  /** Building Info */
  addBuilding(buildingforadd): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.addBuildingUrl,
      buildingforadd
    );
  }

  updateBuilding(
    buildingForUpdate
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updateBuildingUrl,
      buildingForUpdate
    );
  }

  deleteBuilding(
    buildingfordelete
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.deleteBuildingUrl,
      buildingfordelete
    );
  }

  getBuildingById(BuildingId): Observable<getBuildingById[]> {
    return this.http.post<getBuildingById[]>(
      this.getBuildingByIdUrl,
      BuildingId
    );
  }

  updateBuildingPolyPoints(
    addbuildingforcampus
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updateBuildingPolyPointsUrl,
      addbuildingforcampus
    );
  }

  /** Floor Info */
  addFloor(floorforadd): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.addFloorUrl,
      floorforadd
    );
  }

  // updateFloor(floorForUpdate): Observable<TechnologyForCreationResponse[]> {
  //   return this.http.post<TechnologyForCreationResponse[]>(
  //     this.updateFloorUrl,
  //     floorForUpdate
  //   );
  // }

  // deleteFloor(floorfordelete): Observable<TechnologyForCreationResponse[]> {
  //   return this.http.post<TechnologyForCreationResponse[]>(
  //     this.deleteZoneUrl,
  //     floorfordelete
  //   );
  // }

  getFloorById(FloorId): Observable<getFloorById[]> {
    return this.http.post<getFloorById[]>(this.getZoneByIdUrl, FloorId);
  }
  
  /** Zone Info */
  addZone(zoneforadd): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(this.addZoneUrl,zoneforadd) 
  }

  updateZone(zoneForUpdate): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(this.updateZoneUrl,zoneForUpdate) 
  }

  deleteZone(zonefordelete): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(this.deleteZoneUrl,zonefordelete)
  }  
  
  getZoneById(ZoneId): Observable<getFloorById[]> {
    return this.http.post<getFloorById[]>(this.getZoneByIdUrl, ZoneId) 
  }
  
  getSubZoneDetails(subzone_details): Observable<TechnologyForCreationResponse[]> {     
    return this.http.post<TechnologyForCreationResponse[]>(this.getSubZoneUrl,subzone_details)     
  }
  
  getSubZoneDetailsById(subzone_details): Observable<TechnologyForCreationResponse[]> {     
    return this.http.post<TechnologyForCreationResponse[]>(this.getSubZoneByIdUrl,subzone_details)     
  }
  
  addSubZoneDetails(subzonedata): Observable<TechnologyForCreationResponse[]> {     
    return this.http.post<TechnologyForCreationResponse[]>(this.addSubZoneUrl,subzonedata)     
  }

  deleteSubZone(dltsubzonedata): Observable<TechnologyForCreationResponse[]> {     
    return this.http.post<TechnologyForCreationResponse[]>(this.deleteSubZoneUrl,dltsubzonedata)     
  }

  getUnitById(UnitId): Observable<getUnitById[]> {
    return this.http.post<getUnitById[]>(this.getUnitByIdUrl, UnitId);
  }

  updateUnit(unitForUpdate): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updateunitUrl,
      unitForUpdate
    );
  }

  deleteUnit(unitfordelete): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.deleteunitUrl,
      unitfordelete
    );
  }

  addSpaceWithRoom(unitforadd): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.addSpaceWithRoomUrl,
      unitforadd
    );
  }

  addDeviceData(
    adddeviceforfloor
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.addDeviceDataUrl,
      adddeviceforfloor
    );
  }

  editDeviceData(
    editdeviceforfloor
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.editDeviceDataUrl,
      editdeviceforfloor
    );
  }

  updatePolylineFloor(
    updateSVGdeviceforfloor
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updatePolylineFloorUrl,
      updateSVGdeviceforfloor
    );
  }

  deleteDevicesforFloor(
    deletedeviceforfloor
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.editDeviceDataUrl,
      deletedeviceforfloor
    );
  }

  getEmitterDetails(emitterdetails): Observable<DeviceDetails[]> {
    return this.http.post<DeviceDetails[]>(
      this.getEmitterDetailsUrl,
      emitterdetails
    );
  }

  getDeviceDetailsbyId(devicedetails): Observable<DeviceDetails[]> {
    return this.http.post<DeviceDetails[]>(
      this.getDeviceDetailsbyIdUrl,
      devicedetails
    );
  }

  checkHREXfound(item): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.checkHREXfoundUrl,
      item
    );
  }

  getHRexdetails(hrexdetails): Observable<any[]> {
    return this.http.post<any[]>(this.getHRexdetailsUrl, hrexdetails);
  }

  checkDeviceInAlert(
    devicedetails
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.checkDeviceInAlertUrl,
      devicedetails
    );
  }

  checkLocationInAlert(
    devicedetails
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.checkLocationInAlertUrl,
      devicedetails
    );
  }
  getRexdevicedetails(
    devicedetails
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getRexdevicedetailsUrl,
      devicedetails
    );
  }

  getTechView(countdetails): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getTechViewUrl,
      countdetails
    );
  }
  getTechCount(count_details): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getTechCountUrl,
      count_details
    );
  }

  getTemplates(templatesdetails): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getTemplatesUrl,
      templatesdetails
    );
  }

  getTemplatesInfo(
    templatesdetails
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getTemplatesInfoUrl,
      templatesdetails
    );
  }

  getSpaceCategory(spacedetails): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getSpaceCategoryUrl,
      spacedetails
    );
  }

  getResidentRoomCategory(
    roomdetails
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getResidentRoomCategoryUrl,
      roomdetails
    );
  }

  duplicateBuilding(
    duplicatebuild
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.duplicateBuildingUrl,
      duplicatebuild
    );
  }

  duplicateZone(duplicatefloor): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.duplicateZoneUrl,
      duplicatefloor
    );
  }

  saveAsTemplateForFloor(
    templatefloor
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.saveAsTemplateForFloorUrl,
      templatefloor
    );
  }

  saveAsTemplateForSpace(
    templateunit
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.saveAsTemplateForSpaceUrl,
      templateunit
    );
  }

  duplicateSpace(duplicateunit): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.duplicateSpaceUrl,
      duplicateunit
    );
  }

  loadTemplateForFloor(
    tempdetails
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.loadTemplateForFloorUrl,
      tempdetails
    );
  }

  loadTemplatesForDevice(
    tempdevicedata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.loadTemplatesForDeviceUrl,
      tempdevicedata
    );
  }

  deleteTemplateSpace(
    tempspacedata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.deleteTemplateSpaceUrl,
      tempspacedata
    );
  }

  deleteTemplate(tempfloordata): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.deleteTemplateUrl,
      tempfloordata
    );
  }

  addTemplateZone(tempdata): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.addTemplateZoneUrl,
      tempdata
    );
  }

  deleteTemplateDeviceId(
    tempdevicedata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.deleteTemplateDeviceIdUrl,
      tempdevicedata
    );
  }

  addTemplateSpace(addtempdata): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.addTemplateSpaceUrl,
      addtempdata
    );
  }

  updateTemplateDeviceInfo(
    devicetemp
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updateTemplateDeviceInfoUrl,
      devicetemp
    );
  }

  updateTemplateInfo(tempdetails): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updateTemplateInfoUrl,
      tempdetails
    );
  }

  updateTemplateSpaceInfo(
    tempspacedetails
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.updateTemplateSpaceInfoUrl,
      tempspacedetails
    );
  }
  // NurseCall API's call
  saveBoardInfoData(boardInfo): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.saveBoardInfoDataUrl,
      boardInfo
    );
  }
  saveBoardSetupData(boardSetup): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.saveBoardSetupDataUrl,
      boardSetup
    );
  }
  saveStateSymbolsData(
    stateSymbolsData
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.saveStateSymbolsDataUrl,
      stateSymbolsData
    );
  }
  getBoardInfoData(Boarddata): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getBoardInfoDataUrl,
      Boarddata
    );
  }
  getBoardSetupDetail(Boarddata): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getBoardSetupDetailUrl,
      Boarddata
    );
  }
  getStateSymbolsData(Boarddata): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getStateSymbolsDataUrl,
      Boarddata
    );
  }
  saveConfigurationSettings(
    Boarddata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.saveConfigurationSettingsUrl,
      Boarddata
    );
  }
  saveConfigurationBoard(
    Boarddata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.saveConfigurationBoardUrl,
      Boarddata
    );
  }
  deleteConfigurationSettings(
    Configdata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.deleteConfigurationSettingsUrl,
      Configdata
    );
  }
  getConfigurationSettings(
    Boarddata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getConfigurationSettingsUrl,
      Boarddata
    );
  }
  getSpaceDetailsWithBoardSetupData(
    Boarddata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getSpaceDetailsWithBoardSetupDataUrl,
      Boarddata
    );
  }

  getAlertForConfiguredBoard(
    Boarddata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getAlertForConfiguredBoardUrl,
      Boarddata
    );
  }

  getCampusdetailsById(
    Campusdata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getCampusdetailsByIdUrl,
      Campusdata
    );
  }
  getAlertForConfiguredSpace(
    Boarddata
  ): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getAlertForConfiguredSpaceUrl,
      Boarddata
    );
  }
  getRoamingDataById(Roomdata): Observable<TechnologyForCreationResponse[]> {
    return this.http.post<TechnologyForCreationResponse[]>(
      this.getRoamingDataByIdUrl,
      Roomdata
    );
  }
setAlarmClearMode(alarmMode): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.setAlarmClearModeUrl,alarmMode)     
}
getAlarmClearMode(alarmMode): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.getAlarmClearModeUrl,alarmMode)     
}
saveEventDataTektone(boardInfo): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.saveEventDataTektoneUrl,boardInfo)     
}
saveBoardInfoDataTektone(boardInfo): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.saveBoardInfoDataTektoneUrl,boardInfo)     
}
saveBoardSetupDataTektone(boardSetup): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.saveBoardSetupDataTektoneUrl,boardSetup)     
}
getConfigurationSettingsTekTone(boarddata): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.getConfigurationSettingsTekToneUrl,boarddata)     
}
getSpaceDetailsWithBoardSetupDataTekTone(boarddata): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.getSpaceDetailsWithBoardSetupDataTekToneUrl,boarddata)     
}
saveBoardAssociationTekTone(boarddata): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.saveBoardAssociationTekToneUrl,boarddata)     
}
deleteConfigurationSettingsTekTone(Configdata): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.deleteConfigurationSettingsTekToneUrl,Configdata)     
}
getBoardInfoDataTekTone(data): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.getBoardInfoDataTekToneUrl,data)     
}
getBoardSetupDetailTekTone(data): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.getBoardSetupDetailTekToneUrl,data)     
}
getEventDataTekTone(data): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.getEventDataTekToneUrl,data)     
}

getSpaceId(data): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.getOutdoorSpaceIdUrl,data)     
}

saveReorderDropData(data): Observable<TechnologyForCreationResponse[]> {     
  return this.http.post<TechnologyForCreationResponse[]>(this.saveReOrderDataUrl,data)     
}
  

  /*************Reload INI***************/
  reloadiniltc() {
    try {
      this.http.get(this.URL).subscribe((result) => {
        this.reloadini_result = result;
      });
    } catch (err) {
      throw new Error(
        "technologyoverlay.service.ts" + " - reloadiniltc() - " + err.message
      );
    }
  }

  /*************systemresetltc***************/
  systemreset_ltc() {
    try {
      this.http.get(this.sys_URL).subscribe((result) => {
        this.sysreloadini_result = result;
      });
    } catch (err) {
      throw new Error(
        "technologyoverlay.service.ts" + " - systemreset_ltc() - " + err.message
      );
    }
  }

  /*************reloadPullCordConfig***************/
  reloadPullCordConfig_ltc() {
    try {
      this.http.get(this.PullCord_URL).subscribe((result) => {
        this.pullcordreloadini_result = result;
      });
    } catch (err) {
      throw new Error(
        "technologyoverlay.service.ts" +
          " - reloadPullCordConfig_ltc() - " +
          err.message
      );
    }
  }
}
