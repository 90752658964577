<perfect-scrollbar style="max-width: 1200px; max-height: 420px;" >
    <div class="clseditCampus" id="editdialog">
       
                <div class="editCampus-title">
                  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="editCampusHeader" style="margin: 0 0 10px 11px;">Campus Information
                  <span><img class="clscloseimage" src="assets/images/Close_1.png" (click)="onCloseCancel()"></span></h3>
                  <mat-divider ></mat-divider>
                </div>
          
                <form style="padding:24px;" [formGroup]="campuseditForm" autocomplete="off" novalidate (ngSubmit)="editCampusdetails(campuseditForm.value)" [style.height]="minheight">
                <div class="content" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlexFill >
                      
                        <div fxFlex="55" fxFlex.md="25">   
                          
                            <mat-grid-list [cols]="firstcolumns" [rowHeight]="rowHeight">
                                <mat-grid-tile class="clscampusmatgrid">
                                    <label>Provider</label>
                                </mat-grid-tile>
                                <mat-grid-tile class="clscampusmatgridtxt">
                                    <div class="text-inside">
                                        <mat-form-field appearance="outline" floatLabel="auto" class="clscampusedittxt">
                                          <input matInput placeholder="UnitLocation Name" formControlName="location" [(ngModel)]="locationName">  
                                          <input type="hidden" formControlName="campusId" [(ngModel)]="data.CampusId" required>                    
                                        </mat-form-field>
                                    </div>               
                                  </mat-grid-tile> 
          
                                  <mat-grid-tile class="clscampusmatgrid">
                                      <label>Campus</label>
                                  </mat-grid-tile>
                                  <mat-grid-tile class="clscampusmatgridtxt">
                                      <div class="text-inside">
                                          <mat-form-field appearance="outline" floatLabel="auto" class="clscampusedittxt">
                                            <input matInput placeholder="Campus" maxlength="32" formControlName="campusname" [(ngModel)]="resetcampusname" (keyup)="handleInput($event)" required>        
                                             <mat-error *ngIf="campuseditForm.controls.campusname.hasError('required')">
                                                Please enter Campus 
                                              </mat-error>
                                              <mat-error *ngIf="campuseditForm.controls.campusname.hasError('pattern')">
                                                Please enter valid Campus 
                                             </mat-error>
                                             <mat-error *ngIf="campuseditForm.controls.campusname.hasError('maxlength')">
                                                Max length 32 characters only
                                             </mat-error>
                                          </mat-form-field>
                                        </div>
                                  </mat-grid-tile>
          
                                  <mat-grid-tile class="clscampusmatgrid">
                                      <label>Description</label>
                                  </mat-grid-tile>
                                  <mat-grid-tile class="clscampusmatgridtxt">
                                      <div class="text-inside">
                                          <mat-form-field appearance="outline" floatLabel="auto" class="clscampusedittxt">
                                            <input matInput placeholder="Description" maxlength="32" formControlName="description" [(ngModel)]="resetdescription" (keyup)="handleInput($event)">
                                            <!-- <mat-error *ngIf="campuseditForm.controls.description.hasError('required')">
                                              Please enter Description
                                            </mat-error> -->
                                            <mat-error *ngIf="campuseditForm.controls.description.hasError('pattern')">
                                                Please enter valid Description
                                            </mat-error>                                           
                                          </mat-form-field>
                                        </div>
                                  </mat-grid-tile>    
                                
                             </mat-grid-list>                              
                        </div>
                    <div fxFlex="23" fxFlex.md="30" class="clsdivimage">           
                      <mat-grid-list cols="1">
                        <mat-grid-tile class="clscampusImg">
                          <div>
                            <div id="container2" class="text-inside">
                              <img class="clseditDragplanimg" type="file" [src]="campusaddImgUrl"
                                [ngStyle]="{'transform': 'scale('+E_pngZoomLevel+') rotate('+ E_pngRotateLevel +'deg) translateX('+left_rightval+'px) translateY('+top_bottomval+'px)'}"
                                (click)='imageInput.click()' draggable="false"/>
                              <div draggable="true" ngClass="{{dragAreaClass}}">
                                <div class="row">
                                  <div class="col-md-12 text-center">
                                    <a href="javascript:void(0)" (click)="imageInput.click()"></a>
                                    <input type="file" placeholder="Photo" formControlName="campusimage" #imageInput onclick="this.value = null"
                                      (change)="onChangecampus_Edit($event.target.files)"
                                      accept=".png, .jpg, .bmp" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div><label>Click or Drag and Drop Map Image <br>Supported file types: bmp, jpg, png</label></div>
                          </div>
                        </mat-grid-tile>                        
                      </mat-grid-list>
                    </div>
                    
                    <div fxFlex="55" fxFlex.md="45" class="clsdivEditmapview">
                      <label>Edit Map View</label>
                        <mat-grid-list [cols]="columns" rowHeight="70.5px" style="margin-top:1em;">
                          <mat-grid-tile >
                            <div class="text-inside">                   
                                <!-- <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="rotateImage('right')" style="cursor:default;">autorenew</mat-icon>   -->
                                <img class="campusundo" fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="rotateImage('right')" src="assets/images/TechnologyOverlay_Rotate.png" style="margin-left:0.6em;">
                                <h4>Rotate</h4>                              
                            </div>                      
                        </mat-grid-tile>
                        
                        <mat-grid-tile>
                          <div class="text-inside">                   
                              <!-- <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="resetformtext()" style="cursor:default;">flare</mat-icon>  -->
                              <img class="campusundo" fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="resetformtext()" src="assets/images/TechnologyOverlay_reset.png" style="margin-left:0.45em;">
                              <h4>Reset</h4>                                         
                            </div>
                      </mat-grid-tile>
                      <mat-grid-tile> 
                          <div class="text-inside">                   
                              <!-- <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="undoimage()" style="cursor:default;">undo</mat-icon>  -->                                    
                              <img class="campusundo" fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="undoimage()" src="assets/images/Technology Overlay-Device-Floorplans-Undo-Gray.png" style="margin-left:0.3em;">
                              <h4>Undo</h4>                                                          
                            </div>
                      </mat-grid-tile> 
                      <mat-grid-tile> 
                          <div class="text-inside">                   
                              <!-- <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="removeImage()" style="cursor:default;">close</mat-icon>  -->
                              <img class="campusundo" fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="removeImage()" src="assets/images/deletebuilding.png" style="margin-left: 1em;">
                              <h4>Remove</h4>                                         
                            </div>
                      </mat-grid-tile>
                      
                       <!-- <mat-grid-tile >
                                <div class="text-inside">                   
                                    <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="zoomoutImage()" style="cursor:default;">remove_circle</mat-icon>
                                    <img class="campuszoomin" fxShow.sm="false" fxShow.xs="false" fxShow.md="true" fxShow="true" src="assets/images/Technology Overlay-Floorplans-Zoom Bar.png">
                                </div>
                            </mat-grid-tile>  -->
                            <mat-grid-tile [colspan]="clscolspan"> 
                                <div class="text-inside">    
                                    <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="zoomoutImage()" style="cursor:default;">remove_circle</mat-icon>
                                    <img class="campuszoomin" fxShow.sm="false" fxShow.xs="false" fxShow.md="true" fxShow="true" src="assets/images/Technology Overlay-Floorplans-Zoom Bar.png">                                                                                           
                                                   
                                    <img class="campuszoomout" fxShow.sm="false" fxShow.xs="false" fxShow.md="true" fxShow="true" src="assets/images/Technology Overlay-Floorplans-Zoom Bar.png">  
                                    <mat-icon mat-list-avatar fxShow.sm="true" fxShow.xs="true" fxShow.md="true" fxShow="true" (click)="zoomInImage()" style="cursor:default;">add_circle</mat-icon>                                                                                                                                              
                                  </div>
                            </mat-grid-tile> 
                            <!-- <mat-grid-tile> 
                                <div class="text-inside">                                                       
                                  </div>
                            </mat-grid-tile>  -->
                            <mat-grid-tile> 
                                <!-- <div class="text-inside">                    -->                                  
                                    <div class="text-inside" style="height: 68px;">
                                        <div style="text-align: center;height: 21px;"><img src="assets/images/Technology Overlay-Floorplans-Placement Controls_Top.png" (click)="topImage()"></div>
                                        <div style="height: 21px;">
                                            <div style="float:left;"><img src="assets/images/Technology Overlay-Floorplans-Placement Controls_Left.png" (click)="leftImage()" 
                                                    style="margin-left: 1.3px;"></div>
                                            <div style="float:right;padding-left:-1px;"><img src="assets/images/Technology Overlay-Floorplans-Placement Controls_Right.png" (click)="rightImage()"
                                                    style="margin-left: 9px;"></div>
                                        </div>
                                        <div style="text-align: center;height: 21px;"><img src="assets/images/Technology Overlay-Floorplans-Placement Controls_Bottom.png" (click)="bottomImage()"
                                                style="margin-top: -10px;">
                                        </div>
                                        <!-- <img class="campuszoom" fxShow.sm="false" fxShow.xs="false" fxShow.md="true" fxShow="true" src="assets/images/Technology Overlay-Floorplans-Placement Controls.png"> -->
                                    </div>
                                                                                                                      
                                <!-- </div> -->
                            </mat-grid-tile>
                      
                        </mat-grid-list>                      
                    </div>
                  
           </div>
           <mat-dialog-actions style="float:left;" class="clsbtnmatdelete">              
           <button type="button" class="clscampusdltbutton" mat-raised-button (click)="opendeletedialog()" *ngIf="bDelete==true">Delete</button> 
           </mat-dialog-actions>
           
           <mat-dialog-actions class="clsCampusbuttoncontrol">              
            <button type="button" class="clscampusdltbutton" mat-raised-button (click)="onCloseCancel()" >Cancel</button>&nbsp;             
            <button id="btnsavecampuseditclick" class="clscampuseditbutton" mat-raised-button [disabled]="!campuseditForm.valid || !validcampusimgurl" *ngIf="bAddEdit == true">Save</button>            
          </mat-dialog-actions>

          </form>
      </div>
  </perfect-scrollbar>