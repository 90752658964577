<mat-toolbar style="padding:0 0 0 10px">
    <div fxShow.sm="true" fxShow.xs="true" fxShow="true"  class="alert-main-div">
          <img style="width:25px;height:25px;margin-right:6px;" mat-line src="assets/images/Information-Icon-Blue.png">
          <span fxShow.md="false" fxShow.sm="false" fxShow.xs="false" mat-line>Information</span>
    </div>
    <mat-tab-group mat-align-tabs="center" (selectedTabChange)="resident_staff($event)" [selectedIndex]="selectedIndex">
      <mat-tab label="Resident" *ngIf="showResident" id="tabResident" matRipple matRippleDisabled="disabled"></mat-tab>
      <mat-tab label="Staff" *ngIf="showStaff" id="tabStaff" matRipple matRippleDisabled="disabled"></mat-tab>
      <mat-tab label="Roaming" *ngIf="showWander" id="tabWander" matRipple matRippleDisabled="disabled"></mat-tab>
      <mat-tab label="Asset" *ngIf="showAsset" id="tabAsset" matRipple matRippleDisabled="disabled"></mat-tab>
    </mat-tab-group>   

  <span></span>
  <app-header [ngClass]="UserName.length <= 15 ? 'clsusernameappheader' : 'clsappheader'"></app-header>
</mat-toolbar>
  
