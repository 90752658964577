<app-report-header ></app-report-header>
<mat-card class="report-mat-card">
  <div fxLayout="row">
    <div fxLayout="column">
      <app-report-leftbar></app-report-leftbar>
    </div>
      
    <div fxLayout="column" style="width:100%;">
    <!--Infrastructure Report-->
    <div class="table-container">     
        <div class="main-table">

          <div class="landing-title-bar">
              <div fxLayout="row" fxLayoutAlign="space-around left" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayoutGap="5px" fxLayoutWrap>          
                  <div fxFlex="70" fxFlex.md="40" fxFlex.sm="30" fxFlex.xs="20"> 
                  <mat-card-header>
                    <mat-card-title style="font-size: 20px;margin-top:15px;margin-left:1em;" fxFlex.md="100" fxFlex.sm="50" fxFlex.xs="20">                
                      <span class="clsTitle"></span>
                      <div style="font-size:15px;margin-top:5px;">
                        <span class="clsTitle"></span>
                      </div>                
                    </mat-card-title>
                  </mat-card-header>  
                </div>
                     
              </div>
          </div>  
              
        </div>
    </div>   
    </div>

  </div>
</mat-card> 
