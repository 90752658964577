<div class="alert-main-div" (click)="ClickalertDiv($event)">
  <div class="alert-logo-container" id="imglogo">
    <img src="assets/images/TruView.png" (click)="home()">
  </div>
  <div class="version-container">
    <span class="versionspan">{{VersionNumber}}</span>
  </div>
  <div class="alert-title">
    <div style="margin-left:10px;">Alerts ({{Alertcount}})
      <div style="float:right">
        <mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true" id="mobilelogout"
          (click)="Logout()">exit_to_app</mat-icon>
      </div>
      <div *ngIf="Isinfra == 0 && Infracount > 0 && arrowicon != ''" style="float:right;width:25.8%;">
        <mat-icon matBadge={{Infracount}} [ngClass]="(Infracount > 1000)?'mat-InfraCount mat-icon notranslate mat-badge
                    material-icons mat-icon-no-color mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium':
                    'mat-icon notranslate mat-badge material-icons mat-icon-no-color mat-badge-overlap mat-badge-above mat-badge-after
                    mat-badge-medium'">
          <img class="clswrenchicon" src="assets/images/Alerts-Infrastructure-Wrench-Alert.png">
        </mat-icon>
        <button mat-icon-button (click)="this.toggleInfrastructureMenu()">
          <mat-icon aria-hidden="true">{{arrowicon}}</mat-icon>
        </button>
      </div>
      <div class="clserroricon" mat-line style="float:right; width:25.8%;">
        <div id="chkerrormsg" style="display: none;cursor: pointer;" [matTooltip]="matTooltip" matTooltipClass="tooltip"
          style="width:27px; height:35px;" #tooltip="matTooltip" (click)="tooltip.show()"><img style="width:27px;"
            src="assets/images/Alerts-ErrorAlert Icon-Popup.gif"></div>
      </div>
    </div>
  </div>

  <!-- end -->
  <perfect-scrollbar style="background-color:#efeeee">
    <!--fire Alert-->
    <div class="infraalert-list" style="margin-top:7px;">
      <div *ngFor="let infraAlert of alertinfo;trackBy: trackById">
        <div
          *ngIf="infraAlert.AlertType == EnumAlertType.thirdparty && infraAlert.SubTypeId == EnumAlertSubType.Fire_Alarm">
          <mat-list style="padding:5px 0px 30px 0px;">
            <mat-list-item class="alert-list-item" style="padding:0px 0px 10px 0px;">

              <!--<img *ngIf="bLocationAlert" matListAvatar src="assets/images/location.png" (click)="ackalertstatus == 0 ? onClickAlert(infraAlert,1) : false" style="cursor:pointer; width:20px; height:20px;">-->
              <!--<img *ngIf="infraAlert.checklocationIcon" matListAvatar src="assets/images/location.png" (click)="ackalertstatus == 0 ? onClickAlert(infraAlert,1) : false" style="cursor:pointer; width:20px; height:20px;">-->
              <!--<img *ngIf="!infraAlert.checklocationIcon" matListAvatar src="assets/images/location_white.png"  style="width:20px; height:20px;">-->
              <img matListAvatar [src]="EscLevelImgUrl + infraAlert.subtypeImg"
                (click)="ackalertstatus == 0 ? onClickAlert(infraAlert,bLocationAlert) : false"
                style="cursor:pointer; margin-left:10px;">
              <span matLine (click)="ackalertstatus == 0 ? onClickAlert(infraAlert,bLocationAlert) : false"
                style="cursor:pointer;"> {{infraAlert.AlertSubName}} </span>

              <p matLine (click)="ackalertstatus == 0 ? onClickAlert(infraAlert,bLocationAlert) : false"
                style="cursor:pointer;">
                <span *ngIf="infraAlert.FloorFlag == 1 && infraAlert.isOutdoor == 0" matSuffix
                  matTooltip={{infraAlert.FullFloorName}} [matTooltipPosition]="position.value" class="clslocation">
                  {{infraAlert.FloorName}}</span>
                <span *ngIf="infraAlert.FloorFlag == 0 && infraAlert.isOutdoor == 0" class="clslocation">
                  {{infraAlert.FloorName}}</span>

                <span *ngIf="infraAlert.FloorFlag == 1 && infraAlert.isOutdoor == 1" matSuffix
                  matTooltip={{infraAlert.FullCampusName}} [matTooltipPosition]="position.value" class="clslocation">
                  {{infraAlert.AlertCampusName}}</span>
                <span *ngIf="infraAlert.FloorFlag == 0 && infraAlert.isOutdoor == 1" class="clslocation">
                  {{infraAlert.AlertCampusName}}</span>
              </p>

              <!-- <p matLine (click)="ackalertstatus == 0 ? onClickAlert(infraAlert,bLocationAlert) : false" style="cursor:pointer;">                
                <span *ngIf="infraAlert.FloorFlag == 1 && infraAlert.isOutdoor == 1" matSuffix matTooltip={{infraAlert.FullCampusName}} [matTooltipPosition]="position.value" class="clslocation"> {{infraAlert.AlertCampusName}}</span>
                <span *ngIf="infraAlert.FloorFlag == 0 && infraAlert.isOutdoor == 1" class="clslocation"> {{infraAlert.AlertCampusName}}</span>
              </p> -->

              <p matLine (click)="ackalertstatus == 0 ? onClickAlert(infraAlert,bLocationAlert) : false"
                style="cursor:pointer;">
                <span *ngIf="infraAlert.unitFlag == 1" matSuffix matTooltip={{infraAlert.FullUnitName}}
                  [matTooltipPosition]="position.value" class="clslocation"> {{infraAlert.UnitName}} </span>
                <span *ngIf="infraAlert.unitFlag == 0" class="clslocation"> {{infraAlert.UnitName}} </span>
              </p>
              <p matLine>
                <span *ngIf="infraAlert.locationFlag == 1"
                  style="color:white;min-width:80%;cursor:pointer;display:block;" matSuffix
                  matTooltip={{infraAlert.location}} [matTooltipPosition]="position.value">-</span>
              </p>


              <div class="alert-escalation" align="right"
                (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                <span matLine style="height:10px;"></span>
                <span matLine style="min-width:75px;"
                  [ngClass]=alert.Fontcolor>{{getalertnotifytime[infraAlert.AlertId]}}</span>
                <img [src]="EscLevelImgUrl + alert.LevelPhoto" style="width:68px;height:20px;">
              </div>


            </mat-list-item>
            <span class="clsroom" [ngStyle]="{'margin-top': infraAlert.RoomName.length > 15 ? '-50px' : '-40px'}"
              (click)="ackalertstatus == 0 ? onClickAlert(infraAlert,bLocationAlert) : false" style="cursor:pointer;">
              {{infraAlert.alerttilelocation}} </span>
          </mat-list>
        </div>
      </div>
    </div>
    <div class="alert-list">
      <div *ngFor="let alert of alertinfowithoutDup;trackBy: trackById">
        <div
          *ngIf="alert.AlertType == EnumAlertType.Pendant || alert.AlertType == EnumAlertType.Wander || alert.AlertType == EnumAlertType.Pull_Cord || alert.AlertType == EnumAlertType.DC || alert.AlertType == EnumAlertType.UT || alert.AlertType == EnumAlertType.thirdparty">
          <div *ngIf="alert.Alertresstaff != 0">

            <mat-list style="padding:5px 0px 25px 0px;">

              <mat-list-item class="alert-list-item"
                style="padding:0px 0px 10px 0px;background-position: center;background-repeat: no-repeat;"
                [ngStyle]="{'background-image': alert.AlertType == EnumAlertType.Wander || (alert.AlertType == EnumAlertType.DC && alert.SubTypeId == EnumAlertSubType.Unauthorized) ? 'url(assets/images/wander_gif.gif)' : ''}">

                <img
                  *ngIf="alert.AlertType == EnumAlertType.Pendant || alert.AlertType == EnumAlertType.Wander || (alert.AlertType == EnumAlertType.DC && alert.SubTypeId != EnumAlertSubType.Tag_In_Field && alert.ResidentId != 0) "
                  matListAvatar [src]="residentImgUrl + alert.Photo" alt="..." class="clsresImg"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                <img *ngIf="alert.AlertType == EnumAlertType.Pull_Cord" matListAvatar
                  [src]="EscLevelImgUrl + alert.AlertPullCordImg" alt="..." class="clspullcordImg"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                <img *ngIf="alert.AlertType == EnumAlertType.DC && alert.ResidentId == 0" matListAvatar
                  [src]="EscLevelImgUrl + alert.DCImage"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                <img *ngIf="alert.AlertType == EnumAlertType.DC && alert.SubTypeId == EnumAlertSubType.Tag_In_Field"
                  matListAvatar [src]="residentImgUrl + alert.Photo"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                <img *ngIf="alert.AlertType == EnumAlertType.UT && alert.ResidentId == 0" matListAvatar
                  [src]="EscLevelImgUrl + alert.AlertDCTImage"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                <img *ngIf="alert.SubTypeId == EnumAlertSubType.Nurse_Call_Alarm" matListAvatar
                  [src]="EscLevelImgUrl + alert.NurseCallImage"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">


                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span *ngIf="alert.ResFirstFlag == 1 && alert.SubTypeId == EnumAlertSubType.Nurse_Call_Alarm"
                    matSuffix matTooltip={{alert.ResidentFirstFullName}} [matTooltipPosition]="position.value"
                    class="clslocation" style="font-size: 16px;"> {{alert.Alert}}</span>
                  <span *ngIf="alert.ResFirstFlag == 0 && alert.SubTypeId == EnumAlertSubType.Nurse_Call_Alarm"
                    class="clslocation" style="font-size: 16px;"> {{alert.Alert}}</span>
                </p>

                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span *ngIf="alert.ResFirstFlag == 1 && alert.SubTypeId != EnumAlertSubType.Nurse_Call_Alarm"
                    matSuffix matTooltip={{alert.ResidentFirstFullName}} [matTooltipPosition]="position.value"
                    class="clslocation" style="font-size: 16px;"> {{alert.FirstName}}</span>
                  <span *ngIf="alert.ResFirstFlag == 0 && alert.SubTypeId != EnumAlertSubType.Nurse_Call_Alarm"
                    class="clslocation" style="font-size: 16px;"> {{alert.FirstName}}</span>
                </p>


                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span
                    *ngIf="alert.ResLastFlag == 1 && alert.AlertType != EnumAlertType.UT && alert.SubTypeId != EnumAlertSubType.Nurse_Call_Alarm"
                    matSuffix matTooltip={{alert.ResidentLastFullName}} [matTooltipPosition]="position.value"
                    class="clslocation" style="font-size: 14px;"> {{alert.ResidentName}}</span>
                  <span *ngIf="alert.AlertType == EnumAlertType.UT" matSuffix class="clslocation"
                    style="font-size: 14px;"> {{alert.TagId}}</span>
                  <span
                    *ngIf="alert.ResLastFlag == 0 && alert.AlertType != EnumAlertType.UT && alert.SubTypeId != EnumAlertSubType.Nurse_Call_Alarm"
                    class="clslocation" style="font-size: 14px;"> {{alert.ResidentName}}</span>
                </p>

                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span *ngIf="alert.FloorFlag == 1 && alert.isOutdoor == 0" matSuffix
                    matTooltip={{alert.FullFloorName}} [matTooltipPosition]="position.value" class="clslocation">
                    {{alert.FloorName}}</span>
                  <span *ngIf="alert.FloorFlag == 0 && alert.isOutdoor == 0" class="clslocation">
                    {{alert.FloorName}}</span>

                  <span *ngIf="alert.FloorFlag == 1 && alert.isOutdoor == 1" matSuffix
                    matTooltip={{alert.FullCampusName}} [matTooltipPosition]="position.value" class="clslocation">
                    {{alert.AlertCampusName}}</span>
                  <span *ngIf="alert.FloorFlag == 0 && alert.isOutdoor == 1" class="clslocation">
                    {{alert.AlertCampusName}}</span>
                </p>
                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span *ngIf="alert.unitFlag == 1" matSuffix matTooltip={{alert.FullUnitName}}
                    [matTooltipPosition]="position.value" style="padding-bottom:10px;" class="clslocation">
                    {{alert.UnitName}} </span>
                  <span *ngIf="alert.unitFlag == 0" style="padding-bottom:25px;" class="clslocation">
                    {{alert.UnitName}} </span>
                </p>
                <p matLine>
                  <span *ngIf="alert.locationFlag == 1" style="color:white;min-width:80%;cursor:pointer;display:block;"
                    matSuffix matTooltip={{alert.location}} [matTooltipPosition]="position.value">-</span>
                </p>

                <div class="alert-escalation" align="right"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                  <p *ngIf="alert.AckStatus == EnumAlertAckStatus.Notified && alert.AlerttypeName.length <= 15 && alert.SubTypeId != EnumAlertSubType.Nurse_Call_Alarm"
                    [ngClass]=alert.Fontcolor style="word-break: keep-all;">{{alert.AlerttypeName}}</p>
                  <p *ngIf="alert.AckStatus == EnumAlertAckStatus.Notified && alert.AlerttypeName.length <= 15 && alert.SubTypeId == EnumAlertSubType.Nurse_Call_Alarm"
                    [ngClass]=alert.Fontcolor style="word-break: keep-all;">Alarm</p>

                  <p *ngIf="alert.AckStatus == EnumAlertAckStatus.Notified && alert.AlerttypeName.length > 15"
                    [ngClass]=alert.Fontcolor>{{alert.AlerttypeName}}</p>
                  <p *ngIf="alert.SubTypeId == EnumAlertSubType.Tag_In_Field" [ngClass]=alert.Fontcolor>
                    ({{alert.TagId}})</p>
                  <p *ngIf="alert.AckStatus != EnumAlertAckStatus.Notified && (alert.AlerttypeName == 'Wandering' || alert.AlerttypeName== 'Loitering')"
                    [ngClass]=alert.Fontcolor>{{alert.AlerttypeName}}</p>
                  <p *ngIf="alert.AckStatus != EnumAlertAckStatus.Notified" [ngClass]=alert.Fontcolor>
                    {{alert.StatusDec}}</p>
                  <span matLine style="height:10px;"></span>
                  <span matLine style="min-width:75px;"
                    [ngClass]=alert.Fontcolor>{{getalertnotifytime[alert.AlertId]}}</span>
                  <img [src]="EscLevelImgUrl + alert.LevelPhoto" style="width:68px;height:20px;padding-bottom:10px;">
                </div>


              </mat-list-item>
              <!-- <span class="clsroom" *ngIf="alert.AlertType != EnumAlertType.Pull_Cord" [ngStyle]="{'margin-top': alert.RoomName.length > 15 ? '-40px' : '-30px'}" (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;"> {{alert.alerttilelocation}} </span>                 
              <span class="clsroom" *ngIf="alert.AlertType == EnumAlertType.Pull_Cord" [ngStyle]="{'margin-top': alert.alerttilelocation.length > 15 ? '-40px' : '-30px'}" (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;"> {{alert.alerttilelocation}} </span>                  -->

              <mat-list-item>
                <div class="clsdivalertlocation">
                  <span class="clsroom" *ngIf="alert.AlertType != EnumAlertType.Pull_Cord"
                    [ngStyle]="{'margin-top': alert.RoomName.length > 15 ? '-40px' : '-30px'}"
                    (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                    {{alert.alerttilelocation}} </span>
                  <span class="clsroom" *ngIf="alert.AlertType == EnumAlertType.Pull_Cord"
                    [ngStyle]="{'margin-top': alert.alerttilelocation.length > 15 ? '-40px' : '-30px'}"
                    (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                    {{alert.alerttilelocation}} </span>
                </div>
              </mat-list-item>


              <!--<div class="clsdivapproxloc" *ngIf="alert.AlertType == EnumAlertType.Pendant && alert.Approxalertlocation != ''">
                <img matListAvatar src="assets/images/clock24.png"  class="clsimgapproxroom">
                <span class="clsapproxroom" (click)="ackalertstatus == 0 ? onClickAlert(alert,0) : false" style="cursor:pointer;"> {{alert.Approxalertlocation}} </span>
              </div>-->

            </mat-list>
          </div>
          <div *ngIf="alert.Alertresstaff == 0">
            <mat-list style="padding:5px 0px 25px 0px;">
              <mat-list-item class="alert-list-item"
                style="padding:0px 0px 10px 0px;background-position: center;background-repeat: no-repeat;"
                [ngStyle]="{'background-image': alert.AlertType == EnumAlertType.DC && alert.SubTypeId == EnumAlertSubType.Unauthorized && alert.DeviceType == EnumAlertDeviceType.Tag ? 'url(assets/images/Alerts-GrayCross-Pic-SideBar.png),url(assets/images/wander_gif.gif)' : 'url(assets/images/Alerts-GrayCross-Pic-SideBar.png)'}">

                <!--<img *ngIf="bLocationAlert" matListAvatar src="assets/images/location.png" (click)="ackalertstatus == 0 ? onClickAlert(alert,1) : false" style="cursor:pointer; width:20px; height:20px;">-->
                <!--<img *ngIf="alert.checklocationIcon && checklivestaffdata == 1" matListAvatar src="assets/images/location.png" (click)="ackalertstatus == 0 ? onClickAlert(alert,1) : false" style="cursor:pointer; width:20px; height:20px;">-->
                <!-- <img *ngIf="!alert.checklocationIcon" matListAvatar src="assets/images/location_white.png"  style="width:20px; height:20px;">-->
                <img matListAvatar [src]="staffurl + alert.Photo" alt="..." class="clsresImg"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                <!-- <span matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,0) : false" style="cursor:pointer;"> {{alert.FirstName}} </span> -->

                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span *ngIf="alert.ResFirstFlag == 1" matSuffix matTooltip={{alert.ResidentFirstFullName}}
                    [matTooltipPosition]="position.value" class="clslocation" style="font-size: 16px;">
                    {{alert.FirstName}}</span>
                  <span *ngIf="alert.ResFirstFlag == 0" class="clslocation" style="font-size: 16px;">
                    {{alert.FirstName}}</span>
                </p>
                <!-- <span matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,0) : false" style="cursor:pointer;"> {{alert.ResidentName}} </span> -->

                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span *ngIf="alert.ResLastFlag == 1" matSuffix matTooltip={{alert.ResidentLastFullName}}
                    [matTooltipPosition]="position.value" class="clslocation" style="font-size: 14px;">
                    {{alert.ResidentName}}</span>
                  <span *ngIf="alert.ResLastFlag == 0" class="clslocation" style="font-size: 14px;">
                    {{alert.ResidentName}}</span>
                </p>

                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">

                  <span *ngIf="alert.FloorFlag == 1 && alert.isOutdoor == 0" matSuffix
                    matTooltip={{alert.FullFloorName}} [matTooltipPosition]="position.value" class="clslocation">
                    {{alert.FloorName}}</span>
                  <span *ngIf="alert.FloorFlag == 0 && alert.isOutdoor == 0" class="clslocation">
                    {{alert.FloorName}}</span>

                  <span *ngIf="alert.FloorFlag == 1 && alert.isOutdoor == 1" matSuffix
                    matTooltip={{alert.FullCampusName}} [matTooltipPosition]="position.value" class="clslocation">
                    {{alert.AlertCampusName}}</span>
                  <span *ngIf="alert.FloorFlag == 0 && alert.isOutdoor == 1" class="clslocation">
                    {{alert.AlertCampusName}}</span>

                </p>

                <p matLine (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false"
                  style="cursor:pointer;">
                  <span *ngIf="alert.unitFlag == 1" matSuffix matTooltip={{alert.FullUnitName}}
                    [matTooltipPosition]="position.value" class="clslocation"> {{alert.UnitName}} </span>
                  <span *ngIf="alert.unitFlag == 0" style="padding-bottom:25px;" class="clslocation"> {{alert.UnitName}}
                  </span>
                </p>
                <p matLine>
                  <span *ngIf="alert.locationFlag == 1" style="color:white;min-width:80%;cursor:pointer;display:block;"
                    matSuffix matTooltip={{alert.location}} [matTooltipPosition]="position.value">-</span>
                </p>

                <div class="alert-escalation" align="right"
                  (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                  <p *ngIf="alert.AckStatus == EnumAlertAckStatus.Notified" [ngClass]=alert.Fontcolor>
                    {{alert.AlerttypeName}}</p>
                  <p *ngIf="alert.SubTypeId == EnumAlertSubType.Tag_In_Field" [ngClass]=alert.Fontcolor>
                    ({{alert.TagId}})</p>
                  <p *ngIf="alert.AckStatus != EnumAlertAckStatus.Notified && (alert.AlerttypeName == 'Wandering' || alert.AlerttypeName== 'Loitering')"
                    [ngClass]=alert.Fontcolor>{{alert.AlerttypeName}}</p>
                  <p *ngIf="alert.AckStatus != EnumAlertAckStatus.Notified" [ngClass]=alert.Fontcolor>
                    {{alert.StatusDec}}</p>
                  <span matLine style="height:10px;"></span>
                  <span matLine style="min-width:75px;"
                    [ngClass]=alert.Fontcolor>{{getalertnotifytime[alert.AlertId]}}</span>
                  <img [src]="EscLevelImgUrl + alert.LevelPhoto" style="width:68px;height:20px;">
                </div>
              </mat-list-item>
              <mat-list-item>
                <div class="clsdivalertlocation">
                  <span class="clsroom" [ngStyle]="{'margin-top': alert.RoomName.length > 15 ? '-50px' : '-40px'}"
                    (click)="ackalertstatus == 0 ? onClickAlert(alert,bLocationAlert) : false" style="cursor:pointer;">
                    {{alert.alerttilelocation}} </span>
                </div>
              </mat-list-item>

            </mat-list>
          </div>
        </div>
      </div>
    </div>

  </perfect-scrollbar>
</div>