<app-main-layout></app-main-layout>
<div class="cdk-overlay-container" id="divchildModal" style="display: none; z-index: 1100;">
  <div class=" cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
  <div class="cdk-global-overlay-wrapper" dir="ltr" style="justify-content: center; align-items: center;">
    <div class="cdk-overlay-pane myapp-no-padding-dialog"
      style="max-width: 80vw; pointer-events: auto; width: auto; height: 180px; position: static;">
      <div tabindex="0" class="cdk-visually-hidden cdk-focus-trap-anchor" aria-hidden="true"></div>
      <div aria-modal="true"
        class="mat-dialog-container ng-tns-c29-26 ng-trigger ng-trigger-dialogContainer ng-star-inserted" tabindex="-1"
        role="dialog" style="transform: none;">
        <div class="clsIdleAlertMessage">
          <div class="clsIdleAlertMessage-title">
            <h3 class="clsIdleAlertMessageHeader" style="margin: 0 0 10px 11px;">You Have Been Idle! <span><img
                  class="clsIdlecloseimage" src="assets/images/close.png" (click)="hideChildModal()"></span></h3>
            <mat-divider class="mat-divider mat-divider-horizontal" role="separator" aria-orientation="horizontal">
            </mat-divider>
          </div>
          <div class="clsIdleAlertMessagecontent">
            <div>{{idleState}}</div>
            <div class="clsIdleAlertMessagebuttoncontrol mat-dialog-actions">
              <button class="clsIdleaccbutton_Cancel" id='btnIdleStay' mat-raised-button (click)="stay()">Stay</button>
              <button class="clsIdleaccbutton_Cancel" id='btnIdleLogout' mat-raised-button
                (click)="logout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
      <div tabindex="0" class="cdk-visually-hidden cdk-focus-trap-anchor" aria-hidden="true"></div>
    </div>
  </div>
</div>
