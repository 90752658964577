import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import { ApiService } from "../../../../api.service";
import { MapSyncProcess } from "../../../../api";
import { AlertSharedService } from "../../../../_services/index";
import {
  appsettings,
  enumUIFeatureView,
  enumUIAction,
  enumUIView,
} from "../../../../app-settings";
import { ZoneByID, EditZoneForm } from "../../technologyoverlay";
import { TechnologyoverlayService } from "../../technologyoverlay.service";
import { DOCUMENT } from "@angular/common";
import OlMap from "ol/Map";
import {
  SessionCheck,
  generateAlertMessage,
  getFeatureNeedtoShow,
} from "../../../../app-general";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmMessageComponent } from "../../../../confirm-message/confirm-message.component";
import { EditZoneComponent } from '../zone/edit-zone/edit-zone.component';
import { ImageCroppedEvent } from "ngx-image-cropper";
import { HttpClient } from "@angular/common/http";
const URL = appsettings.URLinfo + "uploadFloorImage";
import Draw from "ol/interaction/Draw";

import "ol-popup/src/ol-popup.css";
import OlVectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";

@Component({
  selector: "app-mapview-crop",
  templateUrl: "./mapview-crop.component.html",
  styleUrls: ["./mapview-crop.component.scss"],
})
export class MapviewCropComponent implements OnInit {
  bAddEdit = false;
  bDelete = false;
  imageExtent;
  checkroomdrawend = false;

  buildingcampusname = "";
  buildingcampusfullname = "";
  floorbuildingname = "";
  floorbuildingfullname = "";
  unitfloorname = "";
  unitfloorfullname = "";
  roomunitname = "";
  roomunitfullname = "";

  selectedcampus = 0;
  selectedbuilding = 0;
  selectedfloor = 0;
  selectedunit = 0;

  smcurrentScreenWidth = "sm";
  xscurrentScreenWidth = "xs";
  dialogResult = "";
  displayedColumnsRoom: string[] = [
    "DeviceType",
    "DeviceId",
    "LforIr",
    "MacId",
    "DeviceName",
  ];
  dataSourceRoom;

  campusImgUrl: string = appsettings.CAMPUS_IMAGE_FOLDER;
  floorImgUrl: string = appsettings.FLOOR_IMAGE_FOLDER;

  watcher: Subscription;
  columns = 4;

  buildflag = 0;
  editflag = 0;
  formCampusId;
  addcampusid;
  addbuildingid;
  addfloorid;
  svgimageurl = 0;
  deletefloorid;
  E_FZoomLevel = 1;
  E_FRotateLevel = 0;
  left_rightval = 0;
  top_bottomval = 0;

  FacilityImgurl: string = appsettings.Facility_IMAGE_FOLDER;
  FacilityName;
  campuseditdialogopen = 0;
  buildeditdialogopen = 0;
  flooreditdialogopen = 0;
  uniteditdialogopen = 0;
  IsDuplicateAdded = 0;
  MapSyncProcess;
  editdialogfacilityopen = 0;
  campusimagename;
  campusaddImgUrl;
  validcampusimgurl;
  CampusCnt = 0;
  BuildingCnt = 0;
  FloorCnt = 0;
  UnitCnt = 0;
  Importflag = 0;
  importHeaders;
  getselectedmapdetails;

  imgSrc;
  clseditimg;

  getfloordata;
  MCampusId;
  MBuildingId;
  MFloorId;
  CampusFulldetails: any[];
  selectedCampus;
  selectedBuilding;
  selectedFloor;

  CampusImageURL = "";
  BuildingImageURL = "";
  FloorImageURL = "";
  SvgImageUrl = "";
  campusName;
  FloorInfo;
  buildDuplicatedialogopen = 0;
  ImageWidth = "";
  ImageHeight = "";
  addfloorimageheight = 0;
  ImageUrl = "";
  BackgroundIMG = "Centrak";
  CZoomLevel;
  CRotateLevel;
  TranslateX;
  TranslateY;
  SvgInfo = null;
  map = new OlMap();
  Floor_Name;
  Building_Name;
  Campus_Name;
  floor_image_name;
  UnitById;
  buttontext;
  checkIsSpace = false;
  CheckIsAdd = false;
  checkIsEdit = false;
  checkIsEditOpen = false;

  collapsedIcon1 = "keyboard_arrow_down";
  collapsedIcon = "keyboard_arrow_down";

  collapsedIcon1_build = "keyboard_arrow_up";
  collapsedIcon_build = "keyboard_arrow_up";

  collapsedIcon1_floor = "keyboard_arrow_up";
  collapsedIcon_floor = "keyboard_arrow_up";

  collapsedIcon1_unit = "keyboard_arrow_up";
  collapsedIcon_unit = "keyboard_arrow_up";

  checkicon;
  preId = -1;
  arrowiconT = true;
  arrowiconF = false;

  build_arrowiconT = true;
  build_arrowiconF = false;

  floor_arrowiconT = true;
  floor_arrowiconF = false;

  unit_arrowiconT = true;
  unit_arrowiconF = false;

  camp_isbuttonshow = 0;
  build_isbuttonshow = 0;
  floor_isbuttonshow = 0;

  selectedBuildingdetails = {};
  selectedFloordetails = {};

  Campus_key = "Campusinfo";
  Building_key = "Buildinginfo";
  Floor_key = "Floorinfo";
  chkbuildmapflag = 0;
  devicefiltervalue = "";
  devicefiltervalue_mapdetail;
  Map_array_length = 3;
  device_list_all = 3;

  selected_floorId = 0;
  selected_buildId = 0;
  selected_campusId = 0;
  getMap_flag = 0;

  editimageheight;
  editimagewidth;
  Map_image_path = "assets/images/";
  filterdevicedetails;

  Device_Info;
  Total_DeviceInfo;
  Selected_DeviceTypename;
  Selected_ImageDeviceTypename;
  SelDeviceInfo;
  ImageUrlTag;
  Edit_FloorById;
  authkey;

  //Draw feature for Room
  vector = new OlVectorLayer();
  source = new VectorSource({ wrapX: false });
  vectorLayer = new OlVectorLayer({
    source: this.source,
  });

  draw = new Draw({
    source: this.source,
    type: "Polygon",
  });
  getDeviceList_info;
  checksortflag = 0;
  Device_Details;
  selectedsortType = 0;
  isPreviewShown = true;
  isCropperShown = false;
  notifications = 0;
  editCheck_Invalue = 0;
  floorOriginalId=0;
  imageflag=0;
  isZoneFloor=0;
  private _onDestroy: Subject<void> = new Subject<void>();

  constructor(
    public http: HttpClient,
    private router: Router,
    private apiService: ApiService,
    private alertSharedService: AlertSharedService,
    @Inject(DOCUMENT) private document: any,
    public dialog: MatDialog,
    public thisDialogRef: MatDialogRef<MapviewCropComponent>,
    private technologyoverlayService: TechnologyoverlayService,
    private dialogRef: MatDialog
  ) {
    const getCurrentNavigate = this.router.getCurrentNavigation();
    if (getCurrentNavigate != null) {
      if (getCurrentNavigate.extras.state != undefined)
        localStorage.setItem(
          "MapviewCropComponent",
          JSON.stringify(getCurrentNavigate.extras.state)
        );

      this.getfloordata = getCurrentNavigate.extras.state;
      //this.ClearStoredData();

      if (
        getFeatureNeedtoShow(
          enumUIFeatureView.Technology,
          enumUIAction.Device_Map_AddEditDelete
        ) == enumUIView.Show
      ) {
        this.bAddEdit = true;
        this.bDelete = true;
      }
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    const mapviewCropComponent =
      localStorage.getItem("MapviewCropComponent") ?? "";
    this.dialogRef.closeAll();
    this.getfloordata = JSON.parse(mapviewCropComponent);
    this.Campus_Name = this.getfloordata.CampusName;
    this.Building_Name = this.getfloordata.BuildingName;
    this.addcampusid = this.getfloordata.CampusId;
    this.addbuildingid = this.getfloordata.BuildId;
    this.Floor_Name = this.getfloordata.FloorName;
    this.addcampusid = this.getfloordata.CampusId;
    this.addbuildingid = this.getfloordata.BuildId;
    this.addfloorid = this.getfloordata.FloorId;
    this.floor_image_name = this.getfloordata.FloorPlanImage;
    this.ImageUrlTag =
      appsettings.FLOOR_IMAGE_FOLDER + this.getfloordata.FloorPlanImage;
    this.notifications = this.getfloordata.Notifications;
    this.editCheck_Invalue = this.getfloordata.isResidentCheckin;

    if (
      getFeatureNeedtoShow(
        enumUIFeatureView.Technology,
        enumUIAction.Device_Map_AddEditDelete
      ) == enumUIView.Show
    ) {
      this.bAddEdit = true;
      this.bDelete = true;
    }
    const ref = eval('this');
    this.toDataURL(
      appsettings.FLOOR_IMAGE_FOLDER + this.getfloordata.FloorPlanImage,
      function (dataUrl) {
        ref.imageBase64 = dataUrl;
        ref.croppedImage = dataUrl;
      }
    );
    setTimeout(() => {
      const leftmenu = document.getElementById("leftmenu1");
      if (leftmenu != null) leftmenu.classList.add("active");
    }, 0);
  }

  imageChangedEvent: any = "";
  croppedImage: any = "";
  imageBase64 = "";
  isRoundCrop = "false";

  //Select crop to resize the zone plan.
  edit_crop() {
    this.isPreviewShown = false;
    this.isCropperShown = true;
  }

  //Preview crop will show the selected area of the floor plan.
  preview_crop() {
    this.isPreviewShown = true;
    this.isCropperShown = false;
  }

  CropTypeChange(crpType) {
    this.isRoundCrop = "false";
    if (crpType == 1) {
      this.isRoundCrop = "true";
    }
  }

  CropMap() {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(this.addfloorid),
    };

    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.thisDialogRef.close(FloorByid["apiReturn"][0]);
        this.router.navigate(["../../mapview-crop"], { state: FloorByid["apiReturn"][0] });
      });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed(e) {
    console.log("image failed ot load" + e);
  }

  toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  saveFiles(file) {
    pFileReader(file, this.dialog).then((data) => {
      if (data == 1) this.uploadfiletoPath(data, file, 1);
    });
    let self;
    function pFileReader(file, _dialog: MatDialog) {
      let checkvalidIMG = 0;
      return new Promise((resolve) => {
        const fileReader: FileReader = new FileReader();
        fileReader.onload = function () {
          self = fileReader.result;
          self = self.split("\n");
          if (
            self[0].includes("PNG") ||
            self[0].includes("XICC_PROFILE") ||
            self[0].includes("II") ||
            self[0].includes("JFIF") ||
            self[0].includes("BM")
          ) {
            const kb = 1024;
            const size = +(file.size / kb).toFixed(2);
            if (size > kb * 2) {
              // 1024 is 1MB
              checkvalidIMG = 2;
              generateAlertMessage(
                _dialog,
                "The cropped zone image is greater than 2 MB"
              );
              resolve(checkvalidIMG);
            } else {
              checkvalidIMG = 1;
              resolve(checkvalidIMG);
            }
          } else {
            checkvalidIMG = 2;
            generateAlertMessage(
              _dialog,
              "file which you tried to crop is not valid file"
            );
            resolve(checkvalidIMG);
          }
        };

        fileReader.readAsText(file);
      });
    }
  }

  uploadfiletoPath(data, file, type) {
    const formData = new FormData();
    const filefullexistingname = this.floor_image_name;
    const filefullnamesplitlst = filefullexistingname.split("_");
    const FileName =
      this.addfloorid +
      "+" +
      filefullnamesplitlst[filefullnamesplitlst.length - 1];
    formData.append("file", file, FileName);
    this.http
      .post(URL, formData)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        //let resSTR = JSON.stringify(res);
        //let resJSON = JSON.parse(resSTR);
        if (type == 1) {
          this.getfloordata.floorimagename = res.filename;
          this.updateZone();
        } else {
          generateAlertMessage(this.dialog, "Failed to upload crop image.");
        }
      });
  }

  btnclkfloormapinfohide() {
    //this.NavigatetoFloorPlanView()
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(this.getfloordata.FloorId),
    };

    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.router.navigate(["../../../floorplans"], { state: FloorByid["apiReturn"][0] });
      });
  }
  onUploadFloorImage() {
    if (this.isCropperShown == true) {
      this.isCropperShown = false;
      this.isPreviewShown = true;
    }
    const ConfirmMessageData = {
      ConfirmMessage: "Are you sure you want to save?",
    };
    const dialogRef = this.dialog.open(ConfirmMessageComponent, {
      disableClose: true,
      width: "auto",
      height: "220px",
      panelClass: "myapp-no-padding-dialog",
      autoFocus: false,
      data: ConfirmMessageData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "Yes") {
        //Call to start Crop image upload process
        const file = this.DataURIToBlob(this.croppedImage);
        this.saveFiles(file);
      } else {
        dialogRef.close("Cancel");
      }
    });
  }

  updateZone() {
    try {
      if (
        this.getfloordata.Description == null ||
        this.getfloordata.Description == undefined
      ) {
        this.getfloordata.Description = "";
      } else
        this.getfloordata.Description = this.getfloordata.Description.replace(
          /\s+/g,
          " "
        ).trim();

      const updateZone: EditZoneForm = {
        authKey: this.authkey,
        campusId: parseInt(this.addcampusid),
        buildId: parseInt(this.addbuildingid),
        zoneId: parseInt(this.getfloordata.FloorId),
        zoneName: this.getfloordata.FloorName.replace(/\s+/g, " ").trim(),
        description: this.getfloordata.Description.toString(),
        zoneImage: this.getfloordata.floorimagename.toString(),
        widthft: "",
        lengthft: "",
        zoomLevel: this.E_FZoomLevel,
        rotateLevel: this.E_FRotateLevel,
        fTransformX: this.left_rightval,
        fTransformY: this.top_bottomval,
        notifications: this.notifications,
        zoneCheckInValue: this.editCheck_Invalue,
        floorOriginalId:0,
        imageflag:0,
        isZoneFloor:0
      };
      this.technologyoverlayService
        .updateZone(updateZone)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: any[]) => {
          if (apiresponse.toString() == "true") {
            this.NavigatetoFloorPlanView();
          }
        });
    } catch (err) {
      throw new Error(
        "mapview-crop.component.ts" + " - updateZone() - " + err.message
      );
    }
  }

  NavigatetoFloorPlanView() {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(this.getfloordata.FloorId),
    };
    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.router.navigate(["../../../floorplans"], { state: FloorByid["apiReturn"][0] });
      });
  }

  openFloor_Editdialog() {
    if (!SessionCheck(this.router)) {
      return;
    }
    const _dialog = this.dialog;
    const mapsyncinfo: MapSyncProcess = {
      authKey: this.authkey,
    };
    this.apiService
      .checkMapSync(mapsyncinfo)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((MapSyncinfo: any[]) => {
        this.MapSyncProcess = MapSyncinfo;
        if (this.MapSyncProcess == 1) {
          generateAlertMessage(_dialog, appsettings.MapSyncMsg);
          const floorplanslistelement = document.getElementById(
            "divfloorplandetails"
          );
          if (floorplanslistelement != null)
            floorplanslistelement.className = "clsenable";
          return false;
        } else {
          this.formCampusId = this.getfloordata.FloorId;
          const floorbyid: ZoneByID = {
            authKey: this.authkey,
            zoneId: parseInt(this.getfloordata.FloorId),
          };

          this.technologyoverlayService
            .getZoneById(floorbyid)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((FloorByid: any[]) => {
              this.Edit_FloorById = FloorByid;
              this.editflooropendialog(this.Edit_FloorById["apiReturn"]);
            });
        }
      });
  }

  editflooropendialog(FloorById) {
    try {
      const floorimage = FloorById[0].FloorPlanImage.split(".");
      let _floorsvgImage;
      let _floorimage;
      if (floorimage[1] == "svg" || floorimage[1] == "csv") {
        _floorsvgImage = FloorById[0].FloorPlanImage;
        _floorimage = "";
      } else {
        _floorimage = FloorById[0].FloorPlanImage;
        _floorsvgImage = "";
      }
      const floordetails: any = {
        CampusId: FloorById[0].CampusId,
        CampusName: FloorById[0].CampusName,
        BuildingName: FloorById[0].BuildingName,
        BuildId: FloorById[0].BuildId,
        FloorPlanImage: _floorimage,
        FloorPlansvgImage: _floorsvgImage,
        Description: FloorById[0].Description,
        FloorId: FloorById[0].FloorId,
        FloorName: FloorById[0].FloorName,
        FZoomLevel: FloorById[0].FZoomLevel,
        FRotateLevel: FloorById[0].FRotateLevel,
        FTransformX: FloorById[0].FTransformX,
        FTransformY: FloorById[0].FTransformY,
        Notifications: FloorById[0].Notifications,
        isResidentCheckin: FloorById[0].isResidentCheckin,
        bgImageWidth: FloorById[0].bgImageWidth,
        bgImageHeight: FloorById[0].bgImageHeight,
        OriginalFloorId : FloorById[0].OriginalFloorId,
        isZoneFloor : FloorById[0].isZoneFloor 
      };
      if (this.flooreditdialogopen == 0) {
        const dialogRefFloor = this.dialog.open(EditZoneComponent, {
          disableClose: true,
          width: "75em",
          height: "850px",
          panelClass: "myapp-no-padding-dialog",
          autoFocus: false,
          data: floordetails,
        });
        this.flooreditdialogopen = 1;
        dialogRefFloor.afterClosed().subscribe((result) => {
          this.flooreditdialogopen = 0;

          if (result != "Cancel") this.FloorMapViewCrop(result.FloorId);
          // this.ngOnInit();
        });
      }
    } catch (err) {
      throw new Error(
        "mapview-floor.component.ts" +
          " - editflooropendialog() - " +
          err.message
      );
    }
  }

  FloorMapViewCrop(FloorId) {
    const floorbyid: ZoneByID = {
      authKey: this.authkey,
      zoneId: parseInt(FloorId),
    };
    this.technologyoverlayService
      .getZoneById(floorbyid)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((FloorByid: any[]) => {
        this.getfloordata = FloorByid["apiReturn"][0];
        this.ngOnInit();
      });
  }

  mouseoverrightsidemenu(rightmenu) {
    const floorCropOverText = document.getElementById("FloorCropovertext");
    if (floorCropOverText != null) {
      if (rightmenu == 1) floorCropOverText.innerHTML = appsettings.CropImage;
      else if (rightmenu == 2)
        floorCropOverText.innerHTML = appsettings.CropPreview;
    }
  }

  mouseleaverightsidemenu(value) {
    console.log(value);
    const floorCropOverText = document.getElementById("FloorCropovertext");
    if (floorCropOverText != null) floorCropOverText.innerHTML = " ";
  }
}
