import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { appsettings } from "../../app-settings";
import { Observable } from "rxjs";
import {
  AssetHttpErrorHandler,
  HandleError,
} from "./http-error-handler.service";

@Injectable()
export class AssetService {
  getAssetUrl = appsettings.API_ENDPOINT + "assets/getAsset"; // URL to web api
  addAssetUrl = appsettings.API_ENDPOINT + "assets/addAsset";
  updateAssetUrl = appsettings.API_ENDPOINT + "assets/updateAsset";
  deleteAssetUrl = appsettings.API_ENDPOINT + "assets/deleteAsset";

  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: AssetHttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError("AssetService");
  }

  /** GET Residents from the server */
  getAsset(getAssetParams): Observable<any[]> {
    return this.http.post<any[]>(this.getAssetUrl, getAssetParams);
  }

  /** Add Asset**/
  addAsset(addAssetParams): Observable<any[]> {
    return this.http.post<any[]>(this.addAssetUrl, addAssetParams);
  }
  /** update Asset**/
  updateAsset(updateAssetParams): Observable<any[]> {
    return this.http.post<any[]>(this.updateAssetUrl, updateAssetParams);
  }
  deleteAsset(deleteAssetParams): Observable<any[]> {
    return this.http.post<any[]>(this.deleteAssetUrl, deleteAssetParams);
  }
}
