import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { Subject } from "rxjs";
import {
  enumAlertType,
  enumNotifyTypeItem,
  enumNotifyTypeText,
  appsettings,
} from "../../../app-settings";
import { notificationService } from "../notification.service";
import {
  NotificationForCreationResponse,
  NotifyType,
  getRoleIndividualList,
  selectedEscalations,
  UpdateNotification,
  notifyTypeItem,
  getNotificationLevels,
  checkAlertItem,
} from "../notification";
import { RollbarService } from "../../../rollbar";
import * as Rollbar from "rollbar";
import {
  SessionCheck,
  generateAlertMessage,
  AuthFailedMessage,
} from "../../../app-general";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ConfirmMessageComponent } from "../../../confirm-message/confirm-message.component";
import { ApiService } from "../../../api.service";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: "app-edit-notification",
  templateUrl: "./edit-notification.component.html",
  providers: [notificationService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./edit-notification.component.scss"],
})
export class EditNotificationComponent implements OnInit, OnDestroy {
  public watcher: Observable<boolean>;
  columns = 8;
  notifytype = "";
  notifytypeItem;
  CampusId = 0;
  AlarmId;
  AlarmSubTypeId = 0;
  AlertId;
  Escalationlevel;
  ErrorMsg: string;
  public notificationeditCreationForm: UntypedFormGroup;
  RoleIndividualList: getRoleIndividualList[];
  NotifyType: NotifyType[] = [
    { Notifytype: "Individual", NotifytypeId: 1 },
    { Notifytype: "Role (Global)", NotifytypeId: 0 },
    { Notifytype: "Staff Group", NotifytypeId: 2 },
    { Notifytype: "Resident Group", NotifytypeId: 3 },
  ];
  selectedEscalations: selectedEscalations[];
  selectedRoleId;
  Notifytype;
  RoleId = "0";
  individualId = "0";
  GroupId = "0";
  campusName;
  eventName;
  TimetoEscalate;
  GroupType;
  updateNotifyflag = true;
  selectedBuildingName;
  selectedFloorName;
  BuildingId = 0;
  FloorId = 0;
  SubZoneId = 0;
  selectedSubZoneName;
  checkEscalate = false;
  checkRepeatLevel = false;
  Escalatevalue;
  RepeatLevelvalue;
  TimeEscalatevalue;
  RepeatEscalatevalue;
  AlarmSubType_Name;
  AlaramType;
  RepeatCyclevalue;
  RepeatNumbervalue;
  public EnumAlertType = enumAlertType;
  authkey;
  Breakpoints = Breakpoints;
  notify_RepeatLevelNumber;
  notify_RepeatLevelCycle;
  notify_TimeToEscalate;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 500px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );
  private _onDestroy: Subject<void> = new Subject<void>();
  EscalateInfoTooltip = "When Repeat Level Disabled, the Time to Escalate value can be manually entered once Escalate is enabled.\n\n When Repeat Level Enabled, " +
    "Time to Escalate is auto-calculated with the value equal to (Repeat Number * Repeat Cycle) + Repeat Cycle, once Escalate is enabled. ";
  NotificationLevelTooltip = "WARNING: Previous notification level(s) has/have not been setup to escalate.For this level to execute,edit previous level.";
  showNotifyWarningImage = false;
  constructor(
    private router: Router,    
    public thisDialogRef: MatDialogRef<EditNotificationComponent>,
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: notificationService,
    public dialog: MatDialog,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.RoleId = data.RoleId;
    this.individualId = data.StaffId;
    this.Notifytype = data.NotifyType;
    if (data.CampusId > 0) this.CampusId = data.CampusId;
    this.AlarmId = data.EventId;
    this.Escalationlevel = data.Level;
    if (data.AlarmSubTypeId > 0) this.AlarmSubTypeId = data.AlarmSubTypeId;
    this.AlertId = data.AlertId;
    this.campusName = data.CampusName;
    this.eventName = data.AlarmNameId;
    this.TimetoEscalate = data.TimetoEscalate;
    this.GroupId = data.GroupId;
    this.GroupType = data.GroupType;
    if (data.BuildingId > 0) this.BuildingId = data.BuildingId;
    if (data.FloorId > 0) this.FloorId = data.FloorId;
    if(data.SubZoneId > 0)  
    this.SubZoneId = data.SubZoneId;
    
    this.Escalatevalue = data.NeedEscalate;
    this.AlarmSubType_Name = data.AlarmSubTypeName;
    if(this.Escalatevalue == 1)
    {     
      this.checkEscalate = true;
      this.TimeEscalatevalue = data.TimetoEscalate;      
    }
    else if(this.Escalatevalue == 0)  
    {
      this.checkEscalate = false; 
      this.TimeEscalatevalue = "";        
    }
    this.RepeatLevelvalue = data.NeedRepeat;
    if(this.RepeatLevelvalue == 1)
    {
      this.checkRepeatLevel = true;
      this.RepeatCyclevalue = data.RepeatCycle;
      this.RepeatNumbervalue = data.RepeatNumber;
    }
    else
    {
      this.checkRepeatLevel = false;
    }
    if (this.BuildingId > 0 || this.BuildingId != null)
      this.selectedBuildingName = data.BuildingName;
    else this.selectedBuildingName = "";

    if (this.FloorId > 0 || this.FloorId != null)
      this.selectedFloorName = data.FloorName;
    else this.selectedFloorName = "";
    
    if(this.SubZoneId > 0 || this.SubZoneId != null)
      this.selectedSubZoneName = data.SubZoneName;
    else
      this.selectedSubZoneName = ""; 
    
    this.breakpoint$.subscribe(() => this.breakpointChanged());     
  }
  
  breakpointChanged() {    
    if(this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 8;
    }     
    else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 4;
    } 
    else if(this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.columns = 3;
    }
  }

  ngOnInit() {
    this.authkey = localStorage.getItem("Authkey") ?? "";
    const notifyitem: getNotificationLevels = {
      authKey: this.authkey,
      notifyType: this.Notifytype.toString(),
      role: this.RoleId.toString(),
      individual: this.individualId.toString(),
      groupId: this.GroupId.toString(),
    };
    this.getNotificationLevels(notifyitem);

    this.notificationeditCreationForm = new UntypedFormGroup({
      escalation: new UntypedFormControl("", []),
      // campus: new UntypedFormControl("", []),
      // building: new UntypedFormControl("", []),
      // floor: new UntypedFormControl("", []),
      // subzone: new UntypedFormControl('', []),
      // EventName: new UntypedFormControl("", []),
      TimeToEscalate: new UntypedFormControl("", [
        Validators.required,
        Validators.min(1),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
      RepeatLevelNumber: new UntypedFormControl('', [Validators.required,Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]), 
      RepeatLevelCycle: new UntypedFormControl("", [
        Validators.required,
        Validators.min(1),
        Validators.pattern(appsettings.Number_Pattern),
      ]),
      notify: new UntypedFormControl("", [Validators.required]),
      NotifyList: new UntypedFormControl("", [Validators.required]),
    });
    if (!this.checkRepeatLevel) {
      this.notify_RepeatLevelCycle = this.notificationeditCreationForm.get("RepeatLevelCycle");
      this.notify_RepeatLevelNumber = this.notificationeditCreationForm.get("RepeatLevelNumber");
      this.notify_TimeToEscalate = this.notificationeditCreationForm.get("TimeToEscalate");
      if (this.notify_RepeatLevelCycle != null)
        this.notify_RepeatLevelCycle.disable();
        
      if (this.notify_RepeatLevelNumber != null)
        this.notify_RepeatLevelNumber.disable();
        
       if(!this.checkEscalate) 
       {
          if (this.notify_TimeToEscalate != null) 
            this.notify_TimeToEscalate.disable();
       }
    } 
    else if(!this.checkEscalate || (this.checkEscalate == true && this.checkRepeatLevel == true)){
      this.notify_TimeToEscalate = this.notificationeditCreationForm.get("TimeToEscalate");
      if (this.notify_TimeToEscalate != null) this.notify_TimeToEscalate.disable();
    }
  }

  handleInput(event) {
    try {
      const n = event.target.value.lastIndexOf(" ");
      const l = event.target.value.length;
      let changedValue = event.target.value;
      if (event.which == 32 && changedValue.trim().length == 0) {
        changedValue = changedValue.trim();
      } else if (event.which == 32) {
        if (n + 1 == l) {
          changedValue = changedValue.trim() + " ";
        } else {
          changedValue = changedValue.trim();
        }
      }
      Object.keys(this.notificationeditCreationForm.controls).forEach(
        (field) => {
          // {1}
          const control = this.notificationeditCreationForm.get(field); // {2}
          const notifyEditFormId = document.getElementById(event.target.id);
          if (notifyEditFormId != null) {
            if (field == notifyEditFormId.getAttribute("formControlName")) {
              if (control != null) {
                control.setValue(changedValue);
                control.markAsTouched({ onlySelf: true }); // {3}
              }
            }
          }
        }
      );
    } catch (err) {
      throw new Error(
        "edit-notification.component.ts" + " - handleInput() - " + err.message
      );
    }
  }

  // On change Notify
  @ViewChild("editroleindivi") SelEditRoleIndivi: MatSelect;
  onChangeNotify(event) {
    try {
      this.selectedRoleId = "";
      this.notifytype = event;

      let notifyType = 0;
      if (this.notifytype == enumNotifyTypeText.Individual)
        notifyType = enumNotifyTypeItem.Individual;
      else if (this.notifytype == enumNotifyTypeText.Role)
        notifyType = enumNotifyTypeItem.Role;
      else if (this.notifytype == enumNotifyTypeText.StaffGroup)
        notifyType = enumNotifyTypeItem.StaffGroup;
      else if (this.notifytype == enumNotifyTypeText.ResidentGroup)
        notifyType = enumNotifyTypeItem.ResidentGroup;

      const notifyType_Item: notifyTypeItem = {
        authKey: this.authkey,
        nType: notifyType,
      };

      this.getRoleOrIndividualList(notifyType_Item);
      //   this.SelEditRoleIndivi.options.forEach( (item : MatRadioButton) => {item.deselect()});
    } catch (err) {
      throw new Error(
        "edit-notification.component.ts" +
          " - onChangeNotify() - " +
          err.message
      );
    }
  }

  // get Role/Invidual Data
  getRoleOrIndividualList(notifytype): void {
    try {
      this.RoleIndividualList = [];
      this.notificationService
        .getRoleOrIndividualList(notifytype)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((getRoleIndividualList: any[]) => {
          this.RoleIndividualList = getRoleIndividualList;
        });
    } catch (err) {
      throw new Error(
        "edit-notification.component.ts" +
          " - getRoleOrIndividualList() - " +
          err.message
      );
    }
  }

  //Selected escalation details
  getNotificationLevels(notifyitem): void {
    try {
      this.notificationService
        .getNotificationLevels(notifyitem)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((selectedEscalations: any[]) => {
          if (selectedEscalations != null && selectedEscalations != undefined) {
            this.selectedEscalations = selectedEscalations;
            this.selectedRoleId =
              this.selectedEscalations[0].selectedNotifytype;
          }
        });
    } catch (err) {
      throw new Error(
        "edit-notification.component.ts" +
          " - getNotificationLevels() - " +
          err.message
      );
    }
  }
  // close Notification Dialog
  public onCloseeditNotification = () => {
    this.thisDialogRef.close("Cancel");
    if (!SessionCheck(this.router)) {
      return;
    }
  };

  public hasError = (controlName: string, errorName: string) => {
    return this.notificationeditCreationForm.controls[controlName].hasError(
      errorName
    );
  };

  public createEditNotification = (e_notificationFormValue) => {
    if (!SessionCheck(this.router)) {
      this.thisDialogRef.close("Cancel");
      return;
    }

    if (this.notificationeditCreationForm.valid && this.updateNotifyflag) {
      this.updateNotifyflag = false;

      if (this.AlarmSubType_Name == "Low Batteries" || this.AlarmSubType_Name == "Lost Communication") {
        // "Maintenance"
        this.notificationeditCreationForm.controls[
          "RepeatLevelCycle"
        ].setValidators([
          Validators.required,
          Validators.max(72),
          Validators.min(1),
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.notificationeditCreationForm.controls[
          "RepeatLevelCycle"
        ].updateValueAndValidity();
        this.updateNotifyflag = true;
        if (!this.notificationeditCreationForm.valid) return false;
        
        this.notificationeditCreationForm.controls['RepeatLevelNumber'].setValidators([Validators.required,Validators.max(30),Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]);
        this.notificationeditCreationForm.controls["RepeatLevelNumber"].updateValueAndValidity();
        this.updateNotifyflag = true;
        if(!this.notificationeditCreationForm.valid)
            return false; 
        
      } 
      else if (this.AlarmSubType_Name != "Low Batteries" && this.AlarmSubType_Name != "Lost Communication") {
        this.notificationeditCreationForm.controls[
          "RepeatLevelCycle"
        ].setValidators([
          Validators.required,
          Validators.max(60),
          Validators.min(1),
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.notificationeditCreationForm.controls[
          "RepeatLevelCycle"
        ].updateValueAndValidity();
        this.updateNotifyflag = true;
        if (!this.notificationeditCreationForm.valid) return false;
        
        this.notificationeditCreationForm.controls['RepeatLevelNumber'].setValidators([Validators.required,Validators.max(30),Validators.min(1),Validators.pattern(appsettings.Number_Pattern)]);
        this.notificationeditCreationForm.controls["RepeatLevelNumber"].updateValueAndValidity();
        this.updateNotifyflag = true;
        if(!this.notificationeditCreationForm.valid)
            return false; 
      }
      
      if(this.AlarmSubType_Name == "Low Batteries" || this.AlarmSubType_Name == "Lost Communication")
       {
        this.notificationeditCreationForm.controls[
          "TimeToEscalate"
        ].setValidators([
          Validators.required,
          Validators.max(72),
          Validators.min(1),
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.notificationeditCreationForm.controls[
          "TimeToEscalate"
        ].updateValueAndValidity();
        this.updateNotifyflag = true;
        if (!this.notificationeditCreationForm.valid) return false;
      }
      else if(this.AlarmSubType_Name != "Low Batteries" && this.AlarmSubType_Name != "Lost Communication")
       {
        this.notificationeditCreationForm.controls[
          "TimeToEscalate"
        ].setValidators([
          Validators.required,
          Validators.max(60),
          Validators.min(1),
          Validators.pattern(appsettings.Number_Pattern),
        ]);
        this.notificationeditCreationForm.controls[
          "TimeToEscalate"
        ].updateValueAndValidity();
        this.updateNotifyflag = true;
        if (!this.notificationeditCreationForm.valid) return false;
      }


        if (e_notificationFormValue.notify == "Role (Global)") {
          if (
            e_notificationFormValue.TimeToEscalate != this.TimetoEscalate ||
            e_notificationFormValue.NotifyList != this.RoleId
          )
            this.checkAlertForCampus(e_notificationFormValue);
          else this.onEditNotifyClicked(e_notificationFormValue);
        } else if (
          e_notificationFormValue.notify == "Staff Group" ||
          e_notificationFormValue.notify == "Resident Group"
        ) {
          if (
            e_notificationFormValue.TimeToEscalate != this.TimetoEscalate ||
            e_notificationFormValue.NotifyList != this.GroupId
          )
            this.checkAlertForCampus(e_notificationFormValue);
          else this.onEditNotifyClicked(e_notificationFormValue);
        } else {
          if (
            e_notificationFormValue.TimeToEscalate != this.TimetoEscalate ||
            e_notificationFormValue.NotifyList != this.individualId
          )
            this.checkAlertForCampus(e_notificationFormValue);
          else this.onEditNotifyClicked(e_notificationFormValue);
        }
      
    }
  };

  checkAlertForCampus(e_notificationFormValue) {
    this.updateNotifyflag = false;
    const checkalertItem: checkAlertItem = {
      authKey: this.authkey,
      campusId: this.CampusId,
      eventId: parseInt(this.AlarmId),
      subTypeId: this.AlarmSubTypeId,
      buildId: this.BuildingId,
      floorId: this.FloorId,
      subzoneId:this.SubZoneId
    };
    this.notificationService
      .checkAlertForCampus(checkalertItem)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((apiresponse: any[]) => {
        if (apiresponse.toString() == "true") {
          this.confirmation(e_notificationFormValue);
        } else {
          this.onEditNotifyClicked(e_notificationFormValue);
        }
      });
  }
  confirmation(e_notificationFormValue) {
    try {
      const ConfirmMessageData = {
        ConfirmMessage:
          "Changes will impact only in future alerts, there will no effects in already raised alerts.<br> Do you want to continue ?",
      };
      const dialogRef = this.dialog.open(ConfirmMessageComponent, {
        disableClose: true,
        width: "auto",
        height: "210px",
        panelClass: "myapp-no-padding-dialog",
        autoFocus: false,
        data: ConfirmMessageData,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "Yes") {
          this.onEditNotifyClicked(e_notificationFormValue);
        } else {
          this.updateNotifyflag = true;
          this.thisDialogRef.close("Cancel");
          return;
        }
      });
    } catch (err) {
      throw new Error(
        "edit-notification.component.ts" + " - confirmation() - " + err.message
      );
    }
  }
  /////////////////Select All BUilding/////////

  private onEditNotifyClicked = (e_notificationFormValue) => {
    try {
      let Escalatevalue;
      let TimetoEscalatevalue = 0;
      let RepeatCyclevalue = 0;
      this.updateNotifyflag = false;
      let RepeatLevelvalue = 0;
      let RepeatNumbervalue = 0;
      
      if(this.checkEscalate == true)
      {
        Escalatevalue = 1;
        if (e_notificationFormValue.TimeToEscalate!=undefined)
          TimetoEscalatevalue = parseInt(e_notificationFormValue.TimeToEscalate);
      }
      else
        Escalatevalue = 0;
        
      if(this.checkRepeatLevel == true)  
      {
        RepeatLevelvalue = 1;
        RepeatCyclevalue = parseInt(e_notificationFormValue.RepeatLevelCycle);
        RepeatNumbervalue = parseInt(e_notificationFormValue.RepeatLevelNumber);
        if (this.TimeEscalatevalue.length>0)
          TimetoEscalatevalue = parseInt(this.TimeEscalatevalue);
      }
      else
        RepeatLevelvalue = 0;
        
      // if(this.AlarmSubType_Name == "Low Batteries" || this.AlarmSubType_Name == "Lost Communication")
      //   {
      //     if(TimetoEscalatevalue < 1 || TimetoEscalatevalue > 72 && this.checkEscalate == true && this.checkRepeatLevel == false)
      //     {
      //       generateAlertMessage(this.dialog, "Please Enter Time To Escalation between 1 and 72");
      //       this.updateNotifyflag = true;
      //       return false;
      //     }
      //   }
      // else if(this.AlarmSubType_Name != "Low Batteries" && this.AlarmSubType_Name != "Lost Communication")
      // {
      //   if(TimetoEscalatevalue < 1 || TimetoEscalatevalue > 60 && this.checkEscalate == true && this.checkRepeatLevel == false)
      //   {
      //     generateAlertMessage(this.dialog, "Please Enter Time To Escalation between 1 and 60");
      //     this.updateNotifyflag = true;
      //     return false;
      //   }
      // } 
            
      const e_notification: UpdateNotification = {
        authKey: this.authkey,
        alertId: parseInt(this.AlertId),
        escalation: parseInt(this.Escalationlevel),
        timeToEscalate: TimetoEscalatevalue,
        notify: e_notificationFormValue.notify.toString(),
        notifyList: e_notificationFormValue.NotifyList.toString(),
        eventName: this.eventName.toString(),
        campusId: this.CampusId,
        campusName: this.campusName.toString(),
        buildingId: this.BuildingId,
        floorId: this.FloorId,
        subzoneId: this.SubZoneId,
        needEscalate: parseInt(Escalatevalue),
        needRepeat: RepeatLevelvalue,
        repeatCycle: RepeatCyclevalue,
        repeatNumber: RepeatNumbervalue,
        eventType: parseInt(this.AlarmId),
        subtypeId: this.AlarmSubTypeId,
      };

      this.notificationService
        .updateNotification(e_notification)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((apiresponse: NotificationForCreationResponse[]) => {
          if (apiresponse != null && apiresponse != undefined) {
            if (apiresponse["apiReturn"] == "Success") {
              this.apiService.reloadNotification();
              generateAlertMessage(
                this.dialog,
                "Escalation data edited successfully."
              );
            } 
            else if (apiresponse["apiReturn"] == "Fail") {
              this.updateNotifyflag = true;
              generateAlertMessage(
                this.dialog,
                "Escalation data edited failed."
              );
            } 
            else if (apiresponse["apiReturn"] == "Invalid Auth Key") {
              this.thisDialogRef.close("close");
              AuthFailedMessage(this.dialog, this.router);
              return;
            }
          } 
          else {
            this.updateNotifyflag = true;
            generateAlertMessage(this.dialog, "Escalation data edited failed.");
          }
          this.thisDialogRef.close("Confirm");
        });
    } catch (err) {
      throw new Error(
        "edit-notification.component.ts" +
          " - onEditNotifyClicked() - " +
          err.message
      );
    }
  };

  toggleRepeatLevel(event)
{
  this.notify_RepeatLevelNumber = this.notificationeditCreationForm.get("RepeatLevelNumber");
  this.notify_RepeatLevelCycle = this.notificationeditCreationForm.get("RepeatLevelCycle");
  this.notify_TimeToEscalate = this.notificationeditCreationForm.get("TimeToEscalate");
  if(event.checked == true)
  {
    this.checkRepeatLevel = true;   
    
    if (this.notify_RepeatLevelNumber != null)
      this.notify_RepeatLevelNumber.enable();
      
    if (this.notify_RepeatLevelCycle != null)
      this.notify_RepeatLevelCycle.enable();   
      
      if(this.checkEscalate == true)
      {
        if(this.notify_TimeToEscalate != null)
          this.notify_TimeToEscalate.disable();
      }
  }
  else if(event.checked == false)
  {
    this.checkRepeatLevel = false;
    this.RepeatCyclevalue = "";
    this.RepeatNumbervalue = "";
    if (this.notify_RepeatLevelNumber != null)
      this.notify_RepeatLevelNumber.disable();
      
    if (this.notify_RepeatLevelCycle != null)
      this.notify_RepeatLevelCycle.disable();    
      
      if(this.checkEscalate == true)
      {
        if(this.notify_TimeToEscalate != null)
          this.notify_TimeToEscalate.enable();
      }
  }
}

toggleEscalate(event)
{
  this.notify_TimeToEscalate = this.notificationeditCreationForm.get("TimeToEscalate");
  if(event.checked == true)
  {
    this.checkEscalate = true;
    if (this.notify_TimeToEscalate != null)
      this.notify_TimeToEscalate.enable();  
    
    if(this.checkRepeatLevel == true)
    {
      if (this.notify_TimeToEscalate != null)
        this.notify_TimeToEscalate.disable();
      this.calculateTimeEscalate();
    }
  }
  else if(event.checked == false)
  {
    this.checkEscalate = false;
    this.TimeEscalatevalue = "";
    if (this.notify_TimeToEscalate != null)
      this.notify_TimeToEscalate.disable();      
  }
}
  
  mouseleaveRepeatCycle()
  {
    if(this.checkEscalate == true && this.checkRepeatLevel == true)
      this.calculateTimeEscalate();
  }
  
  calculateTimeEscalate()
  {
    const repeatNumbervalue = this.notificationeditCreationForm.value.RepeatLevelNumber;
    const repeatCyclevalue = this.notificationeditCreationForm.value.RepeatLevelCycle;
    if(repeatNumbervalue != "" && repeatCyclevalue != "" && /^[0-9]*$/.test(repeatCyclevalue) && /^[0-9]*$/.test(repeatNumbervalue))
      this.TimeEscalatevalue = (repeatNumbervalue * repeatCyclevalue) + parseInt(repeatCyclevalue); 
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
